import {MouseEventHandler, PropsWithChildren, useMemo} from 'react';
// eslint-disable-next-line no-restricted-imports
import {Link as RouterLink} from 'react-router-dom';

import {sharedSearchParameters} from '../../shared-search-parameters';
import {objectToUrlParameters} from '../../util/url';
import {useUrl} from '../../util/url-hook/url-hook';

type PropsType = PropsWithChildren<{
    className?: string;
    to: string;
    isSaveQuery?: boolean;
    title?: string;
    id?: string;
    onClick?: MouseEventHandler;
    allowedSearchParameters?: Array<string>;
    isTargetBlank?: boolean;
}>;

export function NavigationLink(props: PropsType): JSX.Element {
    const {
        className,
        to,
        children,
        isSaveQuery = false,
        title,
        id,
        onClick,
        allowedSearchParameters,
        isTargetBlank,
    } = props;

    const {queries} = useUrl();

    const newPageObject = isTargetBlank
        ? {
              rel: 'noopener noreferrer',
              target: '_blank',
          }
        : {};

    const destination = useMemo(() => {
        const url = new URL(window.location.origin + to);
        const pathname = url.pathname;

        const currentParameterEntries = Object.entries(queries);
        const allowedParameterEntries = isSaveQuery
            ? currentParameterEntries
            : currentParameterEntries.filter(
                  ([queryKey]) =>
                      sharedSearchParameters.includes(queryKey) || allowedSearchParameters?.includes(queryKey)
              );
        const additionalParameters = url.searchParams;

        const searchString = objectToUrlParameters({
            ...Object.fromEntries(allowedParameterEntries),
            ...Object.fromEntries(additionalParameters.entries()),
        });

        return searchString ? `${pathname}?${searchString}` : pathname;
    }, [isSaveQuery, queries, to]);

    return (
        // onMouseUp handles middle click too
        // eslint-disable-next-line react/jsx-props-no-spreading
        <RouterLink className={className} id={id} onMouseUp={onClick} title={title} to={destination} {...newPageObject}>
            {children}
        </RouterLink>
    );
}
