import {Tooltip} from 'antd';
import {PropsWithChildren} from 'react';

import {Locale} from '../../../../provider/locale/localization';
import {useFormat} from '../../../../util/format-hook/format-hook';

type PropsType = PropsWithChildren<{
    createdInRd: string;
}>;

export function ReviewCreatedInRdDate(props: PropsType): JSX.Element {
    const {children, createdInRd} = props;
    const {getFormattedDateTime} = useFormat();

    return (
        <Tooltip
            placement="bottom"
            title={
                <Locale
                    stringKey="REVIEWS__REVIEWS_PARSED_AT"
                    valueMap={{
                        date: getFormattedDateTime(new Date(createdInRd), {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }),
                    }}
                />
            }
        >
            {children}
        </Tooltip>
    );
}
