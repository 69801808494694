import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchReviewRadarStats} from './review-radar-api';
import {ReviewAnalysisRadarType, ReviewsAnalysisRadarQueryType} from './review-radar-type';

export function useReviewAnalysisRadarStats(
    options: ReviewsAnalysisRadarQueryType,
    mainFilterKey: string
): UseHookType<Array<ReviewAnalysisRadarType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<ReviewAnalysisRadarType>>();

    const {
        mode,
        brandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
    } = options;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewRadarStats(
            {
                mode,
                brandIds, // this brandId is for competitor brands. This one is needed even with new main-filter
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                userTopics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        setIsInProgress,
        setProcessError,
        setResult,
        mainFilterKey,
        mode,
        brandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
    ]);

    return {isInProgress, processError, result, reset};
}
