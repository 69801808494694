import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchUnansweredReviewsCount} from './unanswered-reviews-count-api';
import {UnansweredReviewsCountType} from './unanswered-reviews-count-type';

export function useUnansweredReviewsCount(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<UnansweredReviewsCountType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['unansweredReviewsCount', filter, mainFilterKey],
        queryFn: async () => {
            return fetchUnansweredReviewsCount(filter, mainFilterKey);
        },
    });
}
