import {Tag} from 'antd';

import {useTechnicalSupportFields} from '../../../../../../service/technical-support/technical-support-hook';
import {
    TechnicalSupportFieldsEnum,
    TicketStatusEnum,
} from '../../../../../../service/technical-support/technical-support-type';
import {classNames} from '../../../../../../util/css';

import * as styles from './technical-support-field-value-tag.scss';

type PropsType = {
    value: boolean | string | number;
    field: TechnicalSupportFieldsEnum;
};

export function TechnicalSupportFieldValueTag(props: PropsType): JSX.Element | null {
    const {value, field} = props;
    const {getNameByValue} = useTechnicalSupportFields();

    const valueString = getNameByValue(field, value);

    if (!valueString) {
        return null;
    }

    if (field === TechnicalSupportFieldsEnum.Status) {
        const tagClasses = classNames(styles.MappingColumn_tag, {
            [styles.MappingColumn_tag__process]: value === TicketStatusEnum.inProgress,
            [styles.MappingColumn_tag__success]: value === TicketStatusEnum.open,
            [styles.MappingColumn_tag__warning]: value === TicketStatusEnum.onCheck,
            [styles.MappingColumn_tag__purple]: value === TicketStatusEnum.inWaiting,
        });

        return (
            <Tag bordered={false} className={tagClasses}>
                {valueString}
            </Tag>
        );
    }

    return <Tag className={styles.MappingColumn_tag}>{valueString}</Tag>;
}
