import {Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {useMemo} from 'react';

import {PageCard} from '../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {OnlinePresenceFilterPdfDataType, OnlinePresenceFilterPdfRowEnum} from './online-presence-filter-pdf-type';

type PropsType = {
    mainPeriod: [Date, Date];
    comparePeriod: [Date | null, Date | null];
};

export function OnlinePresenceFilterPdf(props: PropsType): JSX.Element {
    const {mainPeriod, comparePeriod} = props;

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    const datasource = useMemo(() => {
        const [comparePeriodStart, comparePeriodEnd] = comparePeriod;

        const comparePeriodRows =
            comparePeriodStart && comparePeriodEnd
                ? [
                      {
                          type: OnlinePresenceFilterPdfRowEnum.Compare,
                          start: comparePeriodStart,
                          end: comparePeriodEnd,
                      },
                  ]
                : [];

        return [
            {
                type: OnlinePresenceFilterPdfRowEnum.Main,
                start: mainPeriod[0],
                end: mainPeriod[1],
            },
            ...comparePeriodRows,
        ];
    }, [comparePeriod, mainPeriod]);

    const columns: ColumnsType<OnlinePresenceFilterPdfDataType> = [
        {
            title: '',
            key: 'type',
            render: (item: OnlinePresenceFilterPdfDataType) => {
                if (item.type === OnlinePresenceFilterPdfRowEnum.Main) {
                    return getLocalizedString('ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__MAIN');
                }

                return getLocalizedString('ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__COMPARE');
            },
        },
        {
            title: <Locale stringKey="ONLINE_PRESENCE__START_DATE" />,
            key: 'start',
            render: (item: OnlinePresenceFilterPdfDataType) => {
                return getFormattedDateTime(item.start);
            },
        },
        {
            title: <Locale stringKey="ONLINE_PRESENCE__END_DATE" />,
            key: 'end',
            render: (item: OnlinePresenceFilterPdfDataType) => {
                return getFormattedDateTime(item.end);
            },
        },
    ];

    return (
        <PageCard title={<Locale stringKey="ONLINE_PRESENCE__FILTER" />}>
            <Table<OnlinePresenceFilterPdfDataType> columns={columns} dataSource={datasource} pagination={false} />
        </PageCard>
    );
}
