import * as cssVariablesStyles from '../../css/var-export.scss';
import {LangKeyType} from '../../provider/locale/translation/type';
import {
    YandexShortStoryType,
    YandexStoryStatusEnum,
    YandexStoryTypeEnum,
} from '../../service/yandex-stories/yandex-stories-type';
import {NeverError} from '../../util/error';

export function getYandexStoryStatusConfig(status: YandexStoryStatusEnum): {
    langKey: LangKeyType;
    tooltipLangKey: LangKeyType;
    badgeColor: string;
    tagColor: string;
} {
    switch (status) {
        case YandexStoryStatusEnum.Published:
        case YandexStoryStatusEnum.Sent:
            return {
                tooltipLangKey: 'YANDEX_STORY__TOOLTIP__PUBLISHED',
                langKey: 'YANDEX_STORY__STATUS__PUBLISHED',
                badgeColor: cssVariablesStyles.primary50,
                tagColor: 'blue',
            };
        case YandexStoryStatusEnum.InProgress:
        case YandexStoryStatusEnum.Draft:
        case YandexStoryStatusEnum.Moderation:
            return {
                tooltipLangKey: 'YANDEX_STORY__TOOLTIP__ON_MODERATION',
                langKey: 'YANDEX_STORY__STATUS__MODERATION',
                badgeColor: cssVariablesStyles.orange50,
                tagColor: 'orange',
            };
        case YandexStoryStatusEnum.Rejected:
            return {
                tooltipLangKey: 'YANDEX_STORY__TOOLTIP__REJECTED',
                langKey: 'YANDEX_STORY__STATUS__REJECTED',
                badgeColor: cssVariablesStyles.danger50,
                tagColor: 'red',
            };
        case YandexStoryStatusEnum.Deleted:
            return {
                tooltipLangKey: 'YANDEX_STORY__TOOLTIP__DELETED',
                langKey: 'YANDEX_STORY__STATUS__DELETED',
                badgeColor: cssVariablesStyles.danger50,
                tagColor: 'red',
            };
        default: {
            throw new NeverError(status);
        }
    }
}

const activeStoryStatuses: Set<YandexStoryStatusEnum> = new Set([
    YandexStoryStatusEnum.Sent,
    YandexStoryStatusEnum.InProgress,
    YandexStoryStatusEnum.Moderation,
    YandexStoryStatusEnum.Published,
]);

function sortByUpdatedAtAndStatus(array: Array<YandexShortStoryType>): Array<YandexShortStoryType> {
    return array.sort((storyA, storyB) => {
        const updatedAtA = storyA.updatedAt || storyA.createdAt;
        const updatedAtB = storyB.updatedAt || storyB.createdAt;

        switch (true) {
            case storyA.status !== YandexStoryStatusEnum.Deleted && storyB.status === YandexStoryStatusEnum.Deleted:
                return -1;
            case storyA.status === YandexStoryStatusEnum.Deleted && storyB.status !== YandexStoryStatusEnum.Deleted:
                return 1;
            case updatedAtA > updatedAtB:
                return -1;
            case updatedAtA < updatedAtB:
                return 1;
            default:
                return 0;
        }
    });
}

export function getSortedStoriesList(
    stories: Array<YandexShortStoryType>,
    isNavigation?: boolean
): Array<YandexShortStoryType> {
    if (isNavigation) {
        return stories
            .filter((story) => story.type === YandexStoryTypeEnum.Navigation && activeStoryStatuses.has(story.status))
            .reverse();
    }

    const filteredStories = stories.filter(
        (story) =>
            story.type !== YandexStoryTypeEnum.Navigation ||
            !(story.type === YandexStoryTypeEnum.Navigation && activeStoryStatuses.has(story.status))
    );

    return sortByUpdatedAtAndStatus(filteredStories);
}
