import {
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {ProvidersEnum} from '../../../../../../../util/type';
import {PostContentWrapper} from '../../../post-content-wrapper/post-content-wrapper';
import {YANDEX_POST_SETTINGS} from '../content-step-helper';
import {SourcePostPropsType} from '../content-step-type';
import {ImagesItem} from '../items/media/image/images-item';
import {PostTextItem} from '../items/text/post-text-item';
import {PostPreview} from '../post-preview/post-preview';
import {PostRequirements} from '../post-requirements/post-requirements';

export function YandexCatalogData(props: SourcePostPropsType): JSX.Element {
    const {formInstance, suggestSettings, sourceIndex, formMode, companiesSelector} = props;
    const disabled = formMode === PostFormModeEnum.View;

    const fieldName = [PostFormFieldsEnum.Posts, sourceIndex];

    return (
        <PostContentWrapper>
            <PostContentWrapper.Left>
                <PostTextItem
                    disabled={disabled}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Text]}
                    formInstance={formInstance}
                    maxLength={YANDEX_POST_SETTINGS.maxTextLength}
                    suggestSettings={suggestSettings}
                />

                <ImagesItem
                    disabled={disabled}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Images]}
                    formInstance={formInstance}
                    settings={YANDEX_POST_SETTINGS.images}
                />
            </PostContentWrapper.Left>
            <PostContentWrapper.Right>
                <PostPreview
                    catalog={PostSourceEnum.yandex}
                    formInstance={formInstance}
                    sources={{selectorId: companiesSelector?.selectorId}}
                />
                <PostRequirements options={YANDEX_POST_SETTINGS} provider={ProvidersEnum.yandex} />
            </PostContentWrapper.Right>
        </PostContentWrapper>
    );
}
