import {useCallback, useMemo} from 'react';

import {BarChart} from '../../../../../../layout/chart/bar-chart/bar-chart';
import {BarDataSetType, BarDataType} from '../../../../../../layout/chart/bar-chart/bar-chart-type';
import {getStripesPattern} from '../../../../../../layout/chart/patterns/stripes';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    ActionDynamicsEnum,
    OnlinePresenceActionDynamicsDataType,
    OnlinePresenceDashboardDataCommonType,
} from '../../../../../../service/online-presence/online-presence-type';
import {DATASET_STACK__COMPARE} from '../../../../reviews-analysis/pages/dashboard/widgets/sentiment-by-time/sentiment-by-time-chart/sentiment-by-time-chart-helper';
import {
    getTargetActionDynamicsChartOptions,
    sortActionDynamicsValues,
    targetActionsColorMap,
    targetActionsLangKeyMap,
} from '../../target-actions-dynamics-helper';

import * as styles from './target-actions-chart.scss';

type PropsType = {
    labels: Array<string>;
    comparedLabels: Array<string>;
    data: OnlinePresenceDashboardDataCommonType['actionsDynamic'];
    isCompareMode: boolean;
    legendFilter: Array<ActionDynamicsEnum>;
    isCompareLegendActive: boolean;
    staticChart?: boolean;
};

export function TargetActionsChart(props: PropsType): JSX.Element {
    const {data, isCompareMode, legendFilter, labels, isCompareLegendActive, comparedLabels, staticChart} = props;

    const {getLocalizedString} = useLocale();

    const getTargetActionsDynamicsDatasets = useCallback(
        (periodData?: Array<OnlinePresenceActionDynamicsDataType>, isCompare = false): Array<BarDataSetType> => {
            if (!periodData) {
                return [];
            }

            return periodData
                .filter((dataItem) => {
                    return legendFilter.includes(dataItem.label);
                })
                .sort(sortActionDynamicsValues)
                .map((dataItem) => {
                    return {
                        label: getLocalizedString(targetActionsLangKeyMap[dataItem.label]),
                        values: dataItem.values,
                        ...(isCompare
                            ? {
                                  stack: DATASET_STACK__COMPARE,
                                  color: getStripesPattern(targetActionsColorMap[dataItem.label]),
                              }
                            : {
                                  color: targetActionsColorMap[dataItem.label],
                              }),
                    };
                });
        },
        [getLocalizedString, legendFilter]
    );

    const targetActionsDynamicsData: BarDataType = useMemo(
        () => ({
            labels,
            datasets: [
                ...getTargetActionsDynamicsDatasets(data.bar.current),
                ...(isCompareLegendActive ? getTargetActionsDynamicsDatasets(data.bar.compared, true) : []),
            ],
        }),
        [data.bar, getTargetActionsDynamicsDatasets, isCompareLegendActive, labels]
    );

    return (
        <div className={styles.TargetActionsChart}>
            <BarChart
                data={targetActionsDynamicsData}
                options={getTargetActionDynamicsChartOptions(
                    {
                        data: {
                            datasets: getTargetActionsDynamicsDatasets(data.bar.current),
                            labels: targetActionsDynamicsData.labels,
                        },
                        ...(isCompareMode
                            ? {
                                  compareData: {
                                      datasets: getTargetActionsDynamicsDatasets(data.bar.compared),
                                      labels: comparedLabels,
                                  },
                              }
                            : {}),
                        showSummary: true,
                        showCompareValuesDynamics: isCompareMode,
                    },
                    !staticChart
                )}
            />
        </div>
    );
}
