import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {OnboardingStepNameEnum, onboardingUrl} from '../../../../provider/onboarding/onboarding-hook';
import {fetchAndDeserialize, getUrlParameters, postAndDeserialize} from '../../../../util/api-adapter';

import {RecommendationsStepNameEnum} from './recommendations-const';

const recommendationsSchema = r.array(
    r.object({
        recommendationName: r.nativeEnum(RecommendationsStepNameEnum),
        needAction: r.boolean(),
        availableCatalogs: r.array(r.string()),
        trialAvailable: r.boolean(),
    })
);

const markRecommendationSchema = r.object({recommendationName: r.nativeEnum(RecommendationsStepNameEnum)});

type MarkRecommendationType = r.infer<typeof markRecommendationSchema>;

type RecommendationsType = r.infer<typeof recommendationsSchema>;

function getRecommendationsUrl(stepName: OnboardingStepNameEnum, isDemoOnboarding: boolean) {
    // eslint-disable-next-line no-undefined
    return `${onboardingUrl}recommendations/${getUrlParameters({stepName, demo: isDemoOnboarding || undefined})}`;
}

function fetchRecommendations(stepName: OnboardingStepNameEnum, isDemoOnboarding: boolean) {
    return fetchAndDeserialize(getRecommendationsUrl(stepName, isDemoOnboarding), recommendationsSchema);
}

export function useRecommendations(
    onboardingStep: OnboardingStepNameEnum,
    isDemoOnboarding: boolean
): UseQueryResult<RecommendationsType> {
    return useQuery(
        [getRecommendationsUrl(onboardingStep, isDemoOnboarding)],
        () => fetchRecommendations(onboardingStep, isDemoOnboarding),
        {
            cacheTime: 0,
        }
    );
}

function fetchMarkCompletionRecommendation(
    recommendationName: RecommendationsStepNameEnum | string
): Promise<MarkRecommendationType> {
    return postAndDeserialize(
        `${onboardingUrl}pass_recomendation/`,
        r.object({recommendationName: r.nativeEnum(RecommendationsStepNameEnum)}),
        {recommendationName}
    );
}

export function useCompletionRecommendation(): UseMutationResult<
    MarkRecommendationType,
    unknown,
    RecommendationsStepNameEnum
> {
    return useMutation({
        mutationFn: (recommendationName: RecommendationsStepNameEnum) =>
            fetchMarkCompletionRecommendation(
                recommendationName === RecommendationsStepNameEnum.ReviewsGenerator ||
                    recommendationName === RecommendationsStepNameEnum.ReviewsGeneratorQr
                    ? 'reviews_generator'
                    : recommendationName
            ),
    });
}
