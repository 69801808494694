import {Divider} from 'antd';
import dayjs from 'dayjs';
import {ReactNode, useMemo, useState} from 'react';

import {requestLicense} from '../../provider/license/license-api';
import {LicenseType} from '../../provider/license/license-provider-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {LicenseStatusEnum} from '../../service/local-pack/local-pack-type';

import {LicenseCardInformation} from './components/license-card-information/license-card-information';
import {LicenseCardProduct} from './components/license-card-product/license-card-product';
import {getLicenseConfig, LICENSE_REQUEST_ACTIVE_DAYS, licenseActivationRequestStorageKey} from './license-card-helper';
import * as styles from './license-card.scss';

type PropsType = {
    icon: ReactNode;
    productName: string;
    license: LicenseType;
};

export function LicenseCard(props: PropsType): JSX.Element {
    const {icon, productName, license} = props;

    const [licenseStatus, setLicenseStatus] = useState<LicenseStatusEnum>(() => {
        const licenseRequestData: string | null = license.tariffId
            ? localStorage.getItem(licenseActivationRequestStorageKey(license.tariffId, license.code))
            : null;

        try {
            if (
                licenseRequestData &&
                dayjs().subtract(LICENSE_REQUEST_ACTIVE_DAYS, 'days').isSameOrBefore(Number(licenseRequestData))
            ) {
                return LicenseStatusEnum.requested;
            }
        } catch {
            return license?.isActive ? LicenseStatusEnum.active : LicenseStatusEnum.expired;
        }

        return license?.isActive ? LicenseStatusEnum.active : LicenseStatusEnum.expired;
    });

    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();

    const licenseConfig = useMemo(() => {
        return getLicenseConfig({
            licenseStatus,
            license,
        });
    }, [license, licenseStatus]);

    function handleRequestLicense() {
        const tariffId = license.tariffId;

        if (tariffId) {
            requestLicense(tariffId, license.code)
                .then(() => {
                    localStorage.setItem(
                        licenseActivationRequestStorageKey(tariffId, license.code),
                        Date.now().toString()
                    );
                    setLicenseStatus(LicenseStatusEnum.requested);
                    snackbar.success({
                        message: getLocalizedString('LICENSE_CARD__SNACKBAR__SUCCESS_MESSAGE'),
                        description: getLocalizedString('LICENSE_CARD__SNACKBAR__SUCCESS_DESCRIPTION'),
                    });
                })
                .catch(() =>
                    snackbar.error({
                        message: getLocalizedString('LICENSE_CARD__SNACKBAR__ERROR_MESSAGE'),
                        description: getLocalizedString('LICENSE_CARD__SNACKBAR__ERROR_DESCRIPTION'),
                    })
                );
        }
    }

    return (
        <div className={styles.LicenseCard}>
            <LicenseCardProduct icon={icon} license={license} product={productName} />
            <Divider className={styles.LicenseCard_divider} type="vertical" />
            <LicenseCardInformation
                config={licenseConfig}
                onRequestLicense={handleRequestLicense}
                showAction={Boolean(license?.tariffId) && licenseStatus !== LicenseStatusEnum.requested}
            />
        </div>
    );
}
