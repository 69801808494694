import {WordCloudDataType, WordCloudWordType} from '../../../../../../../../layout/chart/word-cloud/word-cloud-type';
import {ReviewsAnalysisTagBySentimentType} from '../../../../../../../../service/reivew-analysis/review-analytics-tags/review-analytics-tags-type';
import {getSentimentColorBySentiment} from '../../../../../reviews-analysis-helper';

export function castToWordCloudData(tagCloudData: Array<ReviewsAnalysisTagBySentimentType>): WordCloudDataType {
    return tagCloudData.map((tagCloudItem: ReviewsAnalysisTagBySentimentType): WordCloudWordType => {
        return {
            name: tagCloudItem.tag,
            value: tagCloudItem.total,
            color: getSentimentColorBySentiment(tagCloudItem.sentiment),
        };
    });
}
