import {Rule} from 'antd/lib/form';
import {NamePath} from 'antd/lib/form/interface';
import {useCallback, useMemo} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {CompanyMultiSelectV2} from '../../../../../../../../layout/company-multi-select-v2/company-multi-select-v2';
import {CompaniesSelectorPostsAdditionalDataColumn} from '../../../../../../../../layout/company-popup-v2/items/posts/companies-selector-posts-additional-data-column';
import {LocaleWithCatalogName} from '../../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../../../service/feature-companies/feature-companies-type';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {Form, FormInstance} from '../../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../../util/type';
import {PostFormItemPropsType} from '../../../../post-form-type';

type PropsType = {
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
    required: boolean;
    extraRules: Array<Rule>;
    dependencies?: Array<NamePath>;
    formInstance: FormInstance<PostFormType>;
} & PostFormItemPropsType;

export function CompaniesSelectItem(props: PropsType): JSX.Element {
    const {companiesSelector, dependencies, required, extraRules, formInstance, disabled} = props;

    const validateField = useCallback(() => {
        formInstance.validateFields([PostFormFieldsEnum.SelectorId]);

        dependencies?.forEach((dependencyItem) => {
            formInstance.validateFields([dependencyItem]);
        });
        formInstance.validateFields([PostFormFieldsEnum.FbPagesIds]);
    }, [dependencies, formInstance]);

    const {getCompaniesMultiselectValidationRule} = useFormRules();

    const fullRules = useMemo(
        () =>
            !disabled
                ? [
                      ...(required ? [getCompaniesMultiselectValidationRule(companiesSelector.selectedCount)] : []),
                      ...extraRules,
                  ]
                : [],
        [companiesSelector.selectedCount, extraRules, getCompaniesMultiselectValidationRule, disabled, required]
    );

    return (
        <Form.Item<PostFormType>
            dependencies={dependencies}
            extra={
                !required ? (
                    <Text secondary>
                        <LocaleWithCatalogName
                            catalogId={ProvidersIdsEnum.facebook}
                            namePlaceholder="FB"
                            stringKey="POSTS_FORM__LOCATIONS__INFORMATION"
                        />
                    </Text>
                ) : null
            }
            label={<Locale stringKey="POSTS_FORM__LOCATIONS__LABEL" />}
            name={PostFormFieldsEnum.SelectorId}
            required={required}
            rules={fullRules}
            validateFirst={false}
        >
            <CompanyMultiSelectV2<PostSelectorCompanyType>
                companiesSelector={companiesSelector}
                isDisabled={disabled}
                onOk={validateField}
                renderItem={CompaniesSelectorPostsAdditionalDataColumn}
            />
        </Form.Item>
    );
}
