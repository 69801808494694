import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode} from 'react';

import * as styles from './labelled-progress-bar.scss';

export function formatProgressValue(value?: number): ReactNode {
    if (!value) {
        return '0%';
    }

    if (value === 100) {
        return <FontAwesomeIcon color={styles.successIconColor} icon={faCheckCircle} />;
    }

    return `${value}%`;
}
