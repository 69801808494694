import {faChevronLeft, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space} from 'antd';

import {getParametersFromUrl} from '../../../util/url';

import {CursorControlsPayloadType} from './cursor-controls-type';

type PropsType = {
    onChange: (cursor: string | null) => void;
    payload: CursorControlsPayloadType | null;
    className?: string;
};

export function CursorControls(props: PropsType): JSX.Element {
    const {payload, onChange, className} = props;

    const nextCursorUrl: string | null = payload?.next || null;
    const previousCursorUrl: string | null = payload?.previous || null;

    function onPageChange(cursorUrl: string | null): void {
        const newCursor = cursorUrl ? getParametersFromUrl(cursorUrl).cursor : null;

        // eslint-disable-next-line no-undefined
        if (newCursor !== undefined) {
            onChange(newCursor);
        }
    }

    return (
        <Space className={className}>
            <Button disabled={!previousCursorUrl} onClick={() => onPageChange(previousCursorUrl)}>
                <FontAwesomeIcon fixedWidth icon={faChevronLeft} />
            </Button>

            <Button disabled={!nextCursorUrl} onClick={() => onPageChange(nextCursorUrl)}>
                <FontAwesomeIcon fixedWidth icon={faChevronRight} />
            </Button>
        </Space>
    );
}
