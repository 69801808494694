export const enReviewsAndAnswersStatisticsTranslations = {
    REVIEW_AND_ANSWER_STATISTICS__BREADCRUMBS: 'Statistics',
    REVIEW_AND_ANSWER_STATISTICS__REVIEW_BY_CATALOG__TITLE: 'Reviews by catalog',
    REVIEW_AND_ANSWER_STATISTICS__REVIEW_BY_CATALOG__TOTAL: 'Total reviews',
    REVIEW_AND_ANSWER_STATISTICS__RATING_STATISTICS__TITLE: 'Ratings',
    REVIEW_AND_ANSWER_STATISTICS__RATING_STATISTICS__ZERO_RATING_TOOLTIP:
        'Reviews with a rating of 0 and without a rating',
    REVIEW_AND_ANSWER_STATISTICS__RATING_TONE__TITLE: 'Tone',
    REVIEW_AND_ANSWER_STATISTICS__RATING_TONE__AVERAGE_TONE: 'Overall',
    REVIEW_AND_ANSWER_STATISTICS__RATING_TONE__POSITIVE: 'Positive',
    REVIEW_AND_ANSWER_STATISTICS__RATING_TONE__NEGATIVE: 'Negative',
    REVIEW_AND_ANSWER_STATISTICS__RATING_TONE__UNSPECIFIED: 'Unspecified',
    REVIEW_AND_ANSWER_STATISTICS__RATING_TONE__NEUTRAL: 'Neutral',
    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITHOUT_ANSWERS__TITLE: 'Reviews without responses',
    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITHOUT_ANSWERS__TOOLTIP_TITLE: 'Ratio of reviews without answers',
    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITHOUT_ANSWERS__TOOLTIP_DESCRIPTION:
        'Excludes reviews that cannot be answered',
    REVIEW_AND_ANSWER_STATISTICS__USED_AUTO_ANSWERS__TITLE: 'Used auto-replies',
    REVIEW_AND_ANSWER_STATISTICS__USED_GPT_ANSWERS__TITLE: 'Used AI-based answers',

    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TITLE: 'Average response time',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__IN_CUSTOMER_PROFILE: 'In the dashboard',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__FOR_CLIENTS: 'For clients',
    REVIEW_AND_ANSWER_STATISTICS__EMPLOYEE_AVERAGE_RESPONSE_TIME__TITLE: 'Average response time of employees',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__EMPLOYEE: 'Employee',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__TAGS_COUNT: 'Number of tags',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__CATALOG: 'Catalog',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__ANSWERS: 'Answers',
    REVIEW_AND_ANSWER_STATISTICS__CATALOG_AVERAGE_RESPONSE_TIME__TITLE: 'Average response time by catalog',

    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__REVIEWS: 'Reviews',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__ABUSES: 'Complaints',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELETED_REVIEWS: 'Deleted reviews',
    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA: 'Delta',

    REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__PREVIOUS_PERIOD: 'Previous period',
    REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__PREVIOUS_YEAR: 'Previous year',
    REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__RESET: 'Reset comparison',
    REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__COMPARE: 'Compare periods',

    REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_DAY: 'Days',
    REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_HOUR: 'Hours',
    REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_MINUTE: 'Minutes',

    REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_DAY__SHORT: 'd',
    REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_HOUR__SHORT: 'h',
    REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_MINUTE__SHORT: 'm',

    REVIEW_AND_ANSWER_STATISTICS__DOWNLOAD_REPORT_PDF: 'Download data',
    REVIEW_AND_ANSWER_STATISTICS__DOWNLOAD_REPORT_XLSX: 'Download data xlsx',

    REVIEW_AND_ANSWER_STATISTICS__REPORT__SENT_BY_EMAIL__TITLE: 'Request sent',
    REVIEW_AND_ANSWER_STATISTICS__REPORT__SENT_BY_EMAIL__DESCRIPTION:
        'The report for the selected dates will be sent to the email address provided in the profile',
    REVIEW_AND_ANSWER_STATISTICS__REPORT__SENT_BY_EMAIL__ERROR_DESCRIPTION:
        'Error sending the report, please contact support',

    REVIEW_AND_ANSWER_STATISTICS__PDF_FILTER__COLUMN_PERIOD: 'Period',
    REVIEW_AND_ANSWER_STATISTICS__PDF_FILTER__COLUMN_START: 'Period start',
    REVIEW_AND_ANSWER_STATISTICS__PDF_FILTER__COLUMN_END: 'Period end',
    REVIEW_AND_ANSWER_STATISTICS__PERIOD_A: 'Period A',
    REVIEW_AND_ANSWER_STATISTICS__PERIOD_B: 'Period B',

    REVIEW_AND_ANSWER_STATISTICS__COMPLAINTS__TITLE: 'Sent complaints',
    REVIEW_AND_ANSWER_STATISTICS__DELETED_REVIEWS__TITLE: 'Deleted reviews',

    REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RATING: 'Average rating',

    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITH_RATING: 'New reviews with rating',
    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_RATINGS: 'Review ratings',
    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITH_RATING__COUNT: 'Number of reviews',
    REVIEW_AND_ANSWER_STATISTICS__REVIEWS_RATING_BY_TIME: 'Review ratings by day',

    REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__TABLE_TITLE: 'Popular tags',
    REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__NAME_COLUMN: 'Tag',
    REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__USAGES_COLUMN: 'Number of uses',
};
