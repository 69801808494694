import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {List, ModalFuncProps} from 'antd';

import {PageHeaderSub} from '../../../../layout/page-header/page-header-sub';
import {LocalePlural} from '../../../../provider/locale/locale-plural';
import {Locale} from '../../../../provider/locale/localization';
import {BulkEditFieldType} from '../bulk-edit-companies-type';
import {BulkEditFieldsFormType} from '../fields-list/fields-list-type';
import {TagMode} from '../tag-mode/tag-mode';

import {CONFIRM_MODAL_WIDTH, TAG_TEXT_BY_MODE} from './confirm-fields-modal-const';
import * as styles from './confirm-fields-modal.scss';

type PropsType = {
    fields: Array<BulkEditFieldType>;
    companiesCount: number;
    onOk: (fields: BulkEditFieldsFormType) => void;
};

export function getConfirmFieldsModalConfig(props: PropsType): ModalFuncProps {
    const {fields, companiesCount, onOk} = props;

    function renderField(field: BulkEditFieldType): JSX.Element {
        const text = TAG_TEXT_BY_MODE[field.mode];

        return (
            <List.Item className={styles.fields_list__item} key={field.name}>
                <span>
                    <Locale stringKey={field.label} />
                </span>

                <TagMode className={styles.fields_list__tag} mode={field.mode}>
                    {text}
                </TagMode>
            </List.Item>
        );
    }

    return {
        width: CONFIRM_MODAL_WIDTH,
        icon: <FontAwesomeIcon className="anticon" icon={faCircleExclamation} />,
        title: <Locale stringKey="BULK_EDIT_COMPANIES__CONFIRM_FIELDS_HEADER" />,
        cancelText: (
            <span>
                <Locale stringKey="BULK_EDIT_COMPANIES__CONFIRM_FIELDS__BUTTON_CANCEL" />
            </span>
        ),
        okText: (
            <span>
                <Locale stringKey="BULK_EDIT_COMPANIES__CONFIRM_FIELDS__BUTTON_CONFIRM" />
            </span>
        ),
        onOk,
        content: (
            <>
                <div>
                    <PageHeaderSub className={styles.fields_list__info_sub_header}>
                        <LocalePlural
                            count={companiesCount}
                            fewKey="BULK_EDIT_COMPANIES__CONFIRM_FIELDS_SUB_HEADER__FEW"
                            manyKey="BULK_EDIT_COMPANIES__CONFIRM_FIELDS_SUB_HEADER__MANY"
                            singularKey="BULK_EDIT_COMPANIES__CONFIRM_FIELDS_SUB_HEADER__SINGULAR"
                            valueMap={{
                                count: companiesCount,
                            }}
                        />
                    </PageHeaderSub>
                </div>
                <div className={styles.fields_list__container}>
                    <List dataSource={fields} renderItem={renderField} size="small" />
                </div>
            </>
        ),
    };
}
