import {Form} from 'antd';

import {ReviewDataType} from '../../../../service/reviews/reviews-type';

import {ReviewFormModalCreate} from './modal/modal-create';
import {ReviewFormModalEdit} from './modal/modal-edit';
import {ReviewFormFieldsType, ReviewFormModeEnum} from './review-form-type';

type PropsType = {
    isOpen: boolean;
    onCancel: () => void;
    mode: ReviewFormModeEnum;
    review?: ReviewDataType;
    onSubmitFinish: () => void;
};

export function ReviewFormModal(props: PropsType): JSX.Element | null {
    const {isOpen, onCancel, mode, review, onSubmitFinish} = props;

    const [form] = Form.useForm<ReviewFormFieldsType>();

    if (!isOpen) {
        return null;
    }

    if (mode === ReviewFormModeEnum.edit && review) {
        return (
            <ReviewFormModalEdit
                form={form}
                isOpen={isOpen}
                onCancel={onCancel}
                onSubmitFinish={onSubmitFinish}
                review={review}
            />
        );
    }

    return <ReviewFormModalCreate form={form} isOpen={isOpen} onCancel={onCancel} onSubmitFinish={onSubmitFinish} />;
}
