export enum UsetifulNameProductEnum {
    MAILINGS = 'Рассылки',
    CLIENTS = 'Клиенты',
    NEGATIVE_REVIEWS = 'Плохие отзывы',
    NPS = 'NPS',
    QRCODE = 'QR-коды',
    PROMO_CODES = 'Список промокодов',
    QUESTIONNAIRE = 'Опросы',
    DASHBOARD = 'Обзор',
    MY_COMPANIES = 'Мои компании',
    SOURCES = 'Источники',
    QUESTIONS_N_ANSWERS = 'Вопросы и ответы',
    PHOTO_TOOL = 'Фото и видео',
    STORE_LOCATOR = 'Сторлокатор',
    OMNICHANNEL__SETTINGS = 'Настройки коммуникации',
    DUBS_AND_FAKES = 'Дубли и фейки',
    PRODUCTS = 'Товары и цены',
    TARIFFS = 'Тарифы и лимиты',
    POSTS = 'Публикации',
    LOCAL_PACK = 'Трекер позиций',
    REVIEWS = 'Отзывы',
    RESPONSE_TEMPLATES = 'Шаблоны ответов',
    TAGS = 'Теги',
    AUTO_REPLIES = 'Автоответы',
    NEURAL_NETWORK = 'Нейросеть',
    REVIEWS_ANALYSIS__DASHBOARD = 'Панель управления',
    REVIEWS_ANALYSIS__CONCORDANCE = 'Поиск по отзывам',
    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS = 'Ключевые слова и темы',
    REVIEWS_ANALYSIS__COMPANIES = 'Компании',
    REVIEWS_ANALYSIS__EMPLOYEES = 'Сотрудники',
    REVIEWS_ANALYSIS__CITIES = 'Города',
    REVIEWS_ANALYSIS__CARD_RATING = 'Рейтинг карточек',
    ONLINE_PRESENCE__DASHBOARD = 'Общие показатели',
    ONLINE_PRESENCE__SYNCHRONIZATION = 'Синхронизация на площадках',
    ONLINE_PRESENCE__GOOGLE = 'Google Business Profile',
    ONLINE_PRESENCE__YANDEX = 'Яндекс.Бизнес',
    ONLINE_PRESENCE__WGIS = '2ГИС',
    ONLINE_PRESENCE__FACEBOOK = 'facebook',
    ONLINE_PRESENCE__PHRASES = 'Поисковые фразы',
    ONLINE_PRESENCE__LOCATIONS = 'Аналитика по локациям',
    SOURCES_SETTINGS = 'Настройка источников',
    GROUPS_OF_COMPANIES = 'Группы компаний',
    USERS_MANAGEMENT = 'Управление пользователями',
    REVIEWS_STATISTIC = 'Статистика по опросам',
}

export const usetifulProductNameAttribute = 'usetiful-product-button';
