export enum LocalPackRulesTableFieldsEnum {
    Name = 'name',
    Frequency = 'frequency',
    BoxSize = 'boxSize',
    DistanceBetweenPoints = 'distanceBeetweenPoints',
    Status = 'status',
    Actions = 'actions',
}

export type LocalPackRulesTableItemType = Partial<Record<LocalPackRulesTableFieldsEnum, JSX.Element | string>> & {
    id: string;
    children?: Array<LocalPackRulesTableItemType>;
};
