import {Space} from 'antd';
import React from 'react';

import {Text} from '../../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../provider/locale/localization';

import * as styles from './data-type.scss';

export function DataType(): React.JSX.Element {
    const documentationLinks = useDocumentationLinks();

    return (
        <Space direction="vertical">
            <div className={styles.DataType_wrapper}>
                <Text bolder secondary small stringKey="FEED__FORM__FORMAT__LABEL" />
                <Text stringKey="FEED__EXPORT__COMPANY_DATA_TYPE__CSV" />
            </div>
            {documentationLinks.company.feedExport && (
                <Text
                    stringKey="FEED__EXPORT__COMPANY_DATA_TYPE__HELP"
                    valueMap={{
                        link: (
                            <a href={documentationLinks.company.feedExport} rel="noreferrer" target="_blank">
                                <Locale stringKey="FEED__TEMPLATE_LINKS__HELP_LINK" />
                            </a>
                        ),
                    }}
                />
            )}
        </Space>
    );
}
