import {useCallback, useEffect, useState} from 'react';

import {CompaniesSelectorTable} from '../../../../../layout/company-popup-v2/companies-table/companies-selector-table';
import {checkHasSelectorRowBrandId} from '../../../../../layout/company-popup-v2/companies-table/companies-selector-table-helper';
import {CompanySelectHeader} from '../../../../../layout/company-popup-v2/company-select-header';
import {CompanySelectModalFooter} from '../../../../../layout/company-popup-v2/company-select-modal-footer';
import {useBrands} from '../../../../../provider/brands/brand-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {UseCompaniesSelectorHookType} from '../../../../../service/feature-companies/feature-companies-type';
import {fetchYandexStoryAvailableCompanies} from '../../../../../service/yandex-stories/yandex-stories-api';
import {
    StoriesCompanySelectorCompanyType,
    YandexSelectorCatalogStatusEnum,
    YandexStoryFormType,
} from '../../../../../service/yandex-stories/yandex-stories-type';
import {FormInstance} from '../../../../../typings/antd';
import {useRefreshId} from '../../../../../util/hook';
import {YANDEX_SELECT_COMPANIES_STEP_WIDTH} from '../../yandex-story-form-helper';
import {YandexStoryFormStep} from '../step/yandex-story-form-step';

import {YandexStoriesCompaniesSelectorAdditionalContent} from './yandex-stories-companies-selector-additional-content';
import * as styles from './yandex-stories-select-companies-step.scss';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    onCancel: VoidFunction;
    onStepFinished: VoidFunction;
    companiesSelector: UseCompaniesSelectorHookType<StoriesCompanySelectorCompanyType>;
    disabled?: boolean;
    onStepActivated: (width: number) => void;
    selectedBrandId: number | null;
    setSelectedBrandId: ((selectedBrandId: number | null) => void) | null;
};

export function YandexStoriesSelectCompaniesStep(props: PropsType): JSX.Element {
    const {
        onCancel,
        onStepFinished,
        companiesSelector,
        disabled,
        onStepActivated,
        selectedBrandId,
        setSelectedBrandId,
    } = props;

    const {getLocalizedString} = useLocale();

    useEffect(() => {
        onStepActivated(YANDEX_SELECT_COMPANIES_STEP_WIDTH);
    }, [onStepActivated]);

    const {
        selectorId,
        isInProgress,
        companies,
        selectCompany,
        deselectCompany,
        loadMoreCompanies,
        selectedCount,
        deselectAll,
        companiesSelectorFilter,
        clearFilter,
    } = companiesSelector;

    const {brands} = useBrands();
    const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(true);

    useEffect(() => {
        const firstBrandId = brands[0]?.id;

        if (brands.length === 1 && firstBrandId) {
            setIsSelectAllDisabled(false);
            return;
        }

        setIsSelectAllDisabled(true);
    }, [brands, setSelectedBrandId]);

    const {refresh: refreshTableScrollTrigger, refreshId: tableScrollTrigger} = useRefreshId();

    const {modal} = useModal();
    const {snackbar} = useSnackbar();

    const [isFetchingAvailableCompanies, setIsFetchingAvailableCompanies] = useState(false);

    const handleStepFinished = useCallback(() => {
        setIsFetchingAvailableCompanies(true);
        fetchYandexStoryAvailableCompanies(selectorId)
            .then((response) => {
                if (response.availableForYandexCount === response.totalCount) {
                    onStepFinished();
                } else {
                    modal.confirm({
                        title: (
                            <Locale
                                stringKey="YANDEX_STORY__SELECTED_COMPANIES__CONFIRM_COMPANIES__TITLE"
                                valueMap={{
                                    availableCount: response.availableForYandexCount,
                                    totalCount: response.totalCount,
                                }}
                            />
                        ),
                        content: (
                            <Locale
                                stringKey="YANDEX_STORY__SELECTED_COMPANIES__CONFIRM_COMPANIES__CONTENT"
                                valueMap={{
                                    count: response.totalCount - response.availableForYandexCount,
                                }}
                            />
                        ),
                        okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
                        cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                        okType: 'danger',
                        type: 'warning',
                        onOk: () => {
                            onStepFinished();
                        },
                    });
                }
            })
            .finally(() => {
                setIsFetchingAvailableCompanies(false);
            })
            .catch(() => {
                snackbar.error(getLocalizedString('ERROR__SOMETHING_WENT_WRONG'));
            });
    }, [getLocalizedString, modal, onStepFinished, selectorId, snackbar]);

    useEffect(() => {
        refreshTableScrollTrigger();
    }, [companiesSelectorFilter, refreshTableScrollTrigger]);

    useEffect(() => {
        return () => clearFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isTableRowDisabled = useCallback(
        (record: StoriesCompanySelectorCompanyType) => {
            const isCatalogDisabled = record.catalogs.every(
                (catalog) => catalog.status !== YandexSelectorCatalogStatusEnum.Active
            );

            if (isCatalogDisabled) {
                return true;
            }

            if (checkHasSelectorRowBrandId(record)) {
                return Boolean(selectedBrandId && record.brandId !== selectedBrandId);
            }

            return false;
        },
        [selectedBrandId]
    );

    return (
        <YandexStoryFormStep
            footer={
                <CompanySelectModalFooter
                    className={styles.YandexStoriesSelectCompaniesStep_footer}
                    disabled={disabled}
                    hideSubmitButton
                    key="footer"
                    onClear={deselectAll}
                    selectedCount={selectedCount}
                    selectorId={selectorId}
                />
            }
            leftFooterButtonConfig={{
                langKeyType: 'BUTTON__CANCEL',
                onClick: onCancel,
            }}
            rightFooterButtonConfig={{
                langKeyType: 'BUTTON__NEXT',
                onClick: handleStepFinished,
                disabled: selectedCount === 0 || isFetchingAvailableCompanies,
                loading: isFetchingAvailableCompanies,
            }}
        >
            <CompanySelectHeader
                beforeSelectFromMainFilter={refreshTableScrollTrigger}
                companiesSelector={companiesSelector}
                disabled={disabled}
                isDisableSelectAll={isSelectAllDisabled}
                onSelectFiltered={(brandId: number) => setSelectedBrandId?.(brandId)}
                selectedBrandId={selectedBrandId}
            />

            <CompaniesSelectorTable<StoriesCompanySelectorCompanyType>
                companies={companies}
                disabled={disabled}
                isInProgress={isInProgress}
                isRowDisabled={isTableRowDisabled}
                loadMoreCompanies={loadMoreCompanies}
                onDeselectCompany={deselectCompany}
                onRowCallback={(record) => setSelectedBrandId?.(record.brandId)}
                onSelectCompany={selectCompany}
                renderAdditionalContent={YandexStoriesCompaniesSelectorAdditionalContent}
                scrollToTopDependency={tableScrollTrigger}
                selectedBrandId={selectedBrandId}
            />
        </YandexStoryFormStep>
    );
}
