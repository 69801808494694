export const selector = {
    appWrapper: '.js-app-wrapper',
};
export const defaultApiDomain = 'api.rocketdata.io';
export const defaultApiProtocol = 'https://';
export const firebaseConfig = {
    apiKey: 'AIzaSyCzuBIi-7Iiw48ePo4HSys5zpoeM9EcDLA',
    authDomain: 'webpush-f6190.firebaseapp.com',
    projectId: 'webpush-f6190',
    storageBucket: 'webpush-f6190.appspot.com',
    messagingSenderId: '219571815919',
    appId: '1:219571815919:web:0a5be47104bd4b2466e21a',
    measurementId: 'G-WVG7NLN7ZE',
};
export const wGisCountryCodeHeaderName = 'X-2GIS-COUNTRY';

export const ALL_DAYS_ID = 0;

export const FIXED_COLUMN_THRESHOLD = 870;
