import {z as r} from 'zod';

import {companyAddressSchema} from '../company-v2/types/company-address-type';

const searchQuerySchema = r.object({
    id: r.number(),
    query: r.string(),
});

export const searchServiceConfigsSchema = r.array(
    r.object({
        id: r.number(),
        provider: r.string(),
        service: r.string(),
        queries: r.array(searchQuerySchema),
    })
);

const localPackPositionDistributionResultValueSchema = r.object({
    provider: r.string(),
    service: r.string(),
    positions: r.array(r.number()),
});

const localPackPositionDistributionResultSchema = r.object({
    key: r.string(),
    values: r.array(localPackPositionDistributionResultValueSchema),
});

export enum LocalPackPositionDistributionLabelsEnum {
    High = '1-5',
    Middle = '6-9',
    Low = '10-20',
    Lowest = '21+',
    Wait = 'wait',
    NoData = 'no_data',
}

export const localPackPositionDistributionSchema = r.object({
    labels: r.array(r.nativeEnum(LocalPackPositionDistributionLabelsEnum)).nullable(),
    result: r.array(localPackPositionDistributionResultSchema).nullable(),
});

export type LocalPackPositionDistributionResponseType = r.infer<typeof localPackPositionDistributionSchema>;
export type LocalPackPositionDistributionType = {
    [key in keyof LocalPackPositionDistributionResponseType]: NonNullable<
        LocalPackPositionDistributionResponseType[key]
    >;
};

export const localPackSearchResultsItemSchema = r.object({
    position: r.number().nullable(),
    isAd: r.boolean().nullable(),
    name: r.string(),
    address: r.string().nullable(),
    distance: r.number().nullable(),
    category: r.string().nullable(),
    rating: r.number().nullable(),
    reviewsCount: r.number(),
    mapsUrl: r.string(),
});

export const localPackSearchResultsSchema = r.array(localPackSearchResultsItemSchema);

export const localPackCompanyInfoSchema = r.object({
    ...companyAddressSchema,
    name: r.string(),
});

export type LocalPackCompanyInfoType = r.infer<typeof localPackCompanyInfoSchema>;
