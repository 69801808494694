import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faFolderGrid} from '@fortawesome/pro-regular-svg-icons';
import {faEnvelope, faQrcode} from '@fortawesome/pro-solid-svg-icons';
import DOMPurify from 'dompurify';
import {isNull} from 'lodash';
import {ReactNode} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {SnackbarArgsType, SnackbarType} from '../../../../../../provider/snackbar/snackbar-type';
import {NegativeReviewSourceTypeEnum} from '../../../../../../service/negative-review/negative-review-type';
import {ReviewsReplyError} from '../../../../../../service/reviews/reviews-reply';
import {HttpError} from '../../../../../../util/error';
import {isRecord} from '../../../../../../util/object';
import {URL_REGEXP} from '../../../../../../util/regexp';
import {toTrimmedString} from '../../../../../../util/string';

import {ReviewLazyImage} from './lazy-image/review-lazy-image';

// we get the string with two <img> tags here, first with the base64 src and second with the http link to source image
function renderLazyImage(imageTags: string) {
    const [base64, http] = new DOMParser().parseFromString(imageTags, 'text/html').querySelectorAll('img');
    const image = http || base64;

    if (!image) {
        return null;
    }

    return <ReviewLazyImage alt={image.alt} src={image.src} />;
}

export function constructComment(text: string, withLinks?: boolean): Array<JSX.Element> {
    const trimmedText = toTrimmedString(text);

    if (trimmedText === '') {
        return [];
    }

    // server processes all the comment text on its side, so that
    // we can safely split by \n and map over each line
    return trimmedText.split('\n').map((part: string, index: number): JSX.Element => {
        let cleanedText = part === '' ? '<br/>' : DOMPurify.sanitize(part);
        const hasImage = cleanedText.includes('<img');

        if (withLinks) {
            cleanedText = cleanedText
                .split(' ')
                .map((substring) => substring.replace(URL_REGEXP, (url) => `<a href="${url}">${url}</a>`))
                .join(' ');
        }

        cleanedText = cleanedText.replaceAll('<a', '<a target="_blank"');

        if (hasImage) {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={String(index)}>{renderLazyImage(cleanedText)}</div>
            );
        }

        return (
            // eslint-disable-next-line react/no-danger, react/no-array-index-key
            <div dangerouslySetInnerHTML={{__html: cleanedText.trim()}} key={String(index)} />
        );
    });
}

function constructCommentDocument(text: string): Document {
    const trimmedText = toTrimmedString(text);
    const cleanedText = DOMPurify.sanitize(trimmedText);

    return new DOMParser().parseFromString(cleanedText, 'text/html');
}

function getImagesFromCommentDocument(commentDocument: Document): NodeListOf<HTMLImageElement> {
    return commentDocument.querySelectorAll('img');
}

export function getImageCountFromComment(text: string): number {
    const commentDocument = constructCommentDocument(text);
    const images = commentDocument.querySelectorAll('img');

    return images.length;
}

export function extractReviewCommentContent(comment: string | null): {
    images: Array<HTMLImageElement>;
    textWithImages: Array<React.JSX.Element>;
} | null {
    if (!comment) {
        return null;
    }

    const commentDocument = constructCommentDocument(comment);

    const textWithImages = constructComment(comment);
    const images = [...getImagesFromCommentDocument(commentDocument)];

    return {images, textWithImages};
}

export function constructCommentString(text: string): string {
    const commentDocument = constructCommentDocument(text);
    const initialText: string = commentDocument.documentElement.textContent || '';

    return initialText.replace(/(\n){3,}/g, '\n\n'); // two \n (new lines) in arrow is maximum
}

export function constructCommentPreview(text: string, limit = 1000): string {
    const commentString: string = constructCommentString(text);

    if (commentString.length > limit) {
        return `${commentString.slice(0, limit)}…`;
    }

    return commentString;
}

export function handleReviewReplyExceptions(
    error: unknown,
    snackbar: SnackbarType,
    snackbarArgs?: SnackbarArgsType,
    handle423Error?: () => void
): void {
    if (error instanceof ReviewsReplyError) {
        if (error.statusCode === 423 && handle423Error) {
            handle423Error();
        }

        // eslint-disable-next-line react/jsx-no-useless-fragment
        snackbar.error(<>{error.jsonData.error || error.jsonData.detail}</>);
        return;
    }

    snackbar.error(
        snackbarArgs ?? {
            description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
        }
    );

    if (!(error instanceof HttpError)) {
        // This is not HttpError|ApiError. This error is in js code. A developer should see it in a console
        console.error(error);
    }
}

export const SourceTypeIconMap: Record<NegativeReviewSourceTypeEnum, IconProp> = {
    [NegativeReviewSourceTypeEnum.mailingList]: faEnvelope,
    [NegativeReviewSourceTypeEnum.qrCode]: faQrcode,
    [NegativeReviewSourceTypeEnum.qrSet]: faFolderGrid,
};

export function handleEnter(
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    setStateHandler: (value: React.SetStateAction<string>) => void,
    submitHandler: (() => Promise<void>) | null
): void {
    if (event.ctrlKey || event.metaKey) {
        setStateHandler((text: string) => text + '\n');
    } else if (!event.shiftKey && !event.metaKey) {
        submitHandler?.();
    }
}

export function checkHasJsonData(error: unknown): error is {jsonData: ReactNode} {
    return isRecord(error) && Reflect.has(error, 'jsonData');
}

const emojiRegex =
    /^(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F|\p{Emoji_Modifier_Base}(?:\p{Emoji_Modifier})?|\p{Emoji_Component})+$/u;

export function isOnlyEmojis(text: string | null): boolean {
    if (isNull(text)) {
        return false;
    }

    return emojiRegex.test(text);
}
