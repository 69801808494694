import {faStopwatch} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, Descriptions, Space} from 'antd';
import {isArray} from 'lodash';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {Text} from '../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useUser} from '../../../../../provider/user/user-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useBuyTariffLicense, useBuyTariffTrialLicense} from '../../../../../service/tariffs/tariffs-buy-license';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {
    AdditionalLicenseKeyEnum,
    additionalLicenseKeyMap,
    LicensesCodeEnum,
    ProductTypeEnum,
    productTypeKeyMap,
} from '../../licenses-block/license/license-const';
import {LicenseDateTableType} from '../../licenses-block/license/license-helper';
import {LicensesBlockEnum} from '../../licenses-block/licenses-block-type';
import {TariffModalWrapper} from '../tariffs-modal-wrapper/tariff-modal-wrapper';

import {next14thDay} from './license-increase-modal-const';
import * as styles from './license-increase-modal.scss';

type PropsType = {
    licenseName: string;
    licenseCode: string;
    licenseOptions: LicenseDateTableType;
    licensesRefetch: () => void;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    tariffId: number;
    type: LicensesBlockEnum;
    tags: ReactElement | boolean;
};

export function LicenseIncreaseModal(props: PropsType): ReactElement {
    const {licenseOptions, licenseCode, setIsOpen, tariffId, isOpen, licenseName, type, licensesRefetch, tags} = props;
    const {mutate} = useBuyTariffLicense();
    const {mutate: buyTrialHandler} = useBuyTariffTrialLicense();
    const {getFormattedDateTime} = useFormat();
    const documentationLinks = useDocumentationLinks();
    const {getLocalizedString} = useLocale();

    const {refetchUser} = useUser();

    const optionsList = licenseOptions && (Object.keys(licenseOptions) as Array<AdditionalLicenseKeyEnum>);

    const isTest = type === LicensesBlockEnum.TestAvailable;

    function sendLicenseAnalytics(typeLicense: LicensesBlockEnum): void {
        switch (typeLicense) {
            case LicensesBlockEnum.TestAvailable:
                return track(AnalyticsTarget.Tariffs.ActivateTrial, licenseName);
            case LicensesBlockEnum.AdditionalLicenses:
            case LicensesBlockEnum.TestActive:
                return track(AnalyticsTarget.Tariffs.SendApplicationLicense, licenseName);
            default:
                return track(AnalyticsTarget.Tariffs.SendApplicationLimit, licenseName);
        }
    }

    function successHandler() {
        licensesRefetch();
        refetchUser();
        sendLicenseAnalytics(type);
        setIsOpen(false);
    }

    function onOk(message: string) {
        if (type === LicensesBlockEnum.TestAvailable) {
            buyTrialHandler({message, licenseCode, tariffId}, {onSuccess: () => successHandler()});
        } else {
            mutate({message, licenseCode, tariffId}, {onSuccess: () => successHandler()});
        }
    }

    function renderOptions(key: AdditionalLicenseKeyEnum, licenseOptionsKey: LicenseDateTableType) {
        const value = licenseOptionsKey[key];

        if (isArray(value) && licenseCode === LicensesCodeEnum.CatalogsSynchronizeLicence) {
            if (value[0] === 'all') {
                return <Locale stringKey="TAGS__TAG_TYPE__ALL" />;
            }

            return value.join(', ');
        }

        if (isArray(value)) {
            return value.map((variant) => getLocalizedString(productTypeKeyMap[variant as ProductTypeEnum])).join(', ');
        }

        if (key === AdditionalLicenseKeyEnum.ProductType) {
            return getLocalizedString(productTypeKeyMap[value as ProductTypeEnum]);
        }

        return licenseOptionsKey[key];
    }

    return (
        <TariffModalWrapper
            buttonTitle={isTest ? 'BUTTON__CONNECT' : 'BUTTON__SEND_APPLICATION'}
            isOpen={isOpen}
            link={documentationLinks.productsLink}
            onOk={onOk}
            setIsOpen={setIsOpen}
            showMessage={!isTest}
            title={isTest ? 'TARIFFS__MODAL__TEST__TITLE' : 'TARIFFS__MODAL__TITLE__LIMITS'}
        >
            <div className={styles.LicenseIncreaseModal}>
                <div className={styles.LicenseIncreaseModal_cardWrapper}>
                    <Text
                        bolder
                        lighter
                        stringKey={
                            isTest ? 'TARIFFS__MODAL__TEST__PRODUCTS_AND_LIMITS' : 'TARIFFS__MODAL__ACTIVE_PRODUCT'
                        }
                    />
                    <Card
                        size="small"
                        title={
                            <Space size="small">
                                <Text bolder className={styles.LicenseIncreaseModal_licenseName}>
                                    {licenseName}
                                    {isTest ? <FontAwesomeIcon icon={faStopwatch} /> : null}
                                </Text>
                            </Space>
                        }
                    >
                        {optionsList &&
                            optionsList
                                .filter((key) => {
                                    const value = licenseOptions[key];

                                    if (licenseCode === LicensesCodeEnum.NotificationsLicence) {
                                        return false;
                                    }

                                    if (key === AdditionalLicenseKeyEnum.ProductType && value) {
                                        return (value as Array<string>).length > 0;
                                    }

                                    return key;
                                })
                                .map((key) => {
                                    return (
                                        <Card.Grid
                                            className={styles.LicenseIncreaseModal_card}
                                            hoverable={false}
                                            key={key}
                                        >
                                            <Text
                                                block
                                                bolder
                                                key={key}
                                                secondary
                                                small
                                                stringKey={
                                                    key === AdditionalLicenseKeyEnum.ProductType
                                                        ? additionalLicenseKeyMap.variant
                                                        : additionalLicenseKeyMap[key]
                                                }
                                            />
                                            <Text>{renderOptions(key, licenseOptions)}</Text>
                                        </Card.Grid>
                                    );
                                })}
                        {tags && (
                            <Card.Grid className={styles.LicenseIncreaseModal_longCard} hoverable={false}>
                                <Text bolder lighter small stringKey="TARIFFS__MODAL__ADDITIONAL_FEATURES" />
                                <div className={styles.LicenseIncreaseModal_tags}>{tags}</div>
                            </Card.Grid>
                        )}
                    </Card>
                    {isTest && (
                        <Descriptions bordered className={styles.LicenseIncreaseModal_descriptions} size="small">
                            <Descriptions.Item label={<Text stringKey="TARIFFS__MODAL__TEST__DURATION" />}>
                                <Locale
                                    stringKey="TARIFFS__MODAL__TEST__DURATION__DESCRIPTION"
                                    valueMap={{date: <Text bolder>{getFormattedDateTime(next14thDay)}</Text>}}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                </div>
            </div>
        </TariffModalWrapper>
    );
}
