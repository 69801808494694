import {Table, Tooltip} from 'antd';

import {Text} from '../../../../component/text/text';
import {Trend} from '../../../../layout/trend/trend';
import {Locale} from '../../../../provider/locale/locale';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {FormattedPeriodsType} from '../../../../service/online-presence/online-presence-type';
import {useFormat} from '../../../../util/format-hook/format-hook';

import {CompareRowType} from './compare-table-type';
import * as styles from './compare-table.scss';

type PropsType = {
    rows: Array<CompareRowType>;
    metricsNameHeadKey: LangKeyType;
    formattedPeriods?: FormattedPeriodsType;
};

export function CompareTable(props: PropsType): JSX.Element {
    const {rows, metricsNameHeadKey, formattedPeriods} = props;

    const {getFormattedNumber} = useFormat();

    return (
        <Table<CompareRowType>
            className={styles.CompareTable}
            dataSource={rows}
            pagination={false}
            rowKey={(row) => row.titleKey}
        >
            <Table.Column<CompareRowType>
                align="left"
                dataIndex="title"
                key="prevText"
                render={(_value: unknown, rowData: CompareRowType): JSX.Element => (
                    <Locale stringKey={rowData.titleKey} />
                )}
                title={<Locale stringKey={metricsNameHeadKey} />}
            />
            <Table.Column<CompareRowType>
                align="right"
                dataIndex="value"
                key="prevText"
                render={(_value: unknown, rowData: CompareRowType): JSX.Element => (
                    <Text>{getFormattedNumber(rowData.currentValue)}</Text>
                )}
                title={
                    <Tooltip title={formattedPeriods?.currentPeriod || ''}>
                        <Text bolder stringKey="ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__MAIN" />
                    </Tooltip>
                }
            />
            <Table.Column<CompareRowType>
                align="center"
                dataIndex="value"
                key="prevText"
                render={(_value: unknown, rowData: CompareRowType): JSX.Element => {
                    if (rowData.currentValue === 0 || rowData.comparePeriodValue === 0) {
                        return <span />;
                    }

                    return <Trend delta={rowData.delta} />;
                }}
                title=""
            />
            <Table.Column<CompareRowType>
                align="right"
                dataIndex="value"
                key="prevText"
                render={(_value: unknown, rowData: CompareRowType): JSX.Element => (
                    <Text lighter>{getFormattedNumber(rowData.comparePeriodValue)}</Text>
                )}
                title={
                    <Tooltip title={formattedPeriods?.comparedPeriod}>
                        <Text bolder stringKey="ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__COMPARE" />
                    </Tooltip>
                }
            />
        </Table>
    );
}
