import {Badge, Empty, theme} from 'antd';
import {useCallback, useLayoutEffect, useRef} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useTechnicalSupportMessages} from '../../../../../../../service/technical-support/technical-support-hook';
import {classNames} from '../../../../../../../util/css';
import {debounce} from '../../../../../../../util/function';
import {onScrollInfiniteScrollContainerToTop} from '../../../../../../../util/infinite-scroll';

import {TechnicalSupportConversationMessage} from './technical-support-conversation-message';
import {TechnicalSupportNewMessageForm} from './technical-support-new-message-form';
import * as styles from './technical-support-conversation.scss';

type PropsType = {
    helpRequestId: number;
    newMessages: number;
    canCreateNewMessage: boolean;
    refreshTicketData: VoidFunction;
};

export function TechnicalSupportConversation(props: PropsType): JSX.Element {
    const {helpRequestId, newMessages, canCreateNewMessage, refreshTicketData} = props;

    const {token} = theme.useToken();
    const messagesContainerRef = useRef<HTMLDivElement | null>(null);
    const messageContainerScrollHeight = useRef<number | null>(null);
    const {
        messages,
        pagination,
        isLoading: isLoadingMessages,
        hasMoreItems,
        createNewMessage,
        isCreatingMessage,
    } = useTechnicalSupportMessages(helpRequestId, refreshTicketData);

    const onContainerScroll = useCallback(
        debounce((event: Event) => {
            if (!isLoadingMessages && hasMoreItems) {
                onScrollInfiniteScrollContainerToTop(event, () => {
                    if (event.target instanceof HTMLDivElement) {
                        messageContainerScrollHeight.current = event.target.scrollHeight;
                    }

                    pagination.onChange(pagination.page + 1);
                });
            }
        }, 200),
        [hasMoreItems, isLoadingMessages, pagination]
    );

    const scrollContainerToBottom = useCallback(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, []);

    useLayoutEffect(() => {
        if (pagination.page === 1) {
            scrollContainerToBottom();
            return;
        }

        if (messagesContainerRef.current && messageContainerScrollHeight.current) {
            messagesContainerRef.current.scrollTop =
                messagesContainerRef.current.scrollHeight - messageContainerScrollHeight.current;
        }
    }, [messages, pagination.page, scrollContainerToBottom]);

    useLayoutEffect(() => {
        messagesContainerRef.current?.addEventListener('scroll', onContainerScroll);

        return () => {
            messagesContainerRef.current?.removeEventListener('scroll', onContainerScroll);
        };
    }, [onContainerScroll]);

    return (
        <div>
            <div className={styles.TechnicalSupportConversation_label}>
                <Text stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__COMMENTS_LABEL" />{' '}
                {Boolean(newMessages) && <Badge color={token.colorPrimary} count={`+${newMessages}`} />}
            </div>
            <div
                className={classNames(styles.TechnicalSupportConversation_messagesContainer, {
                    [styles.TechnicalSupportConversation_messagesContainer__empty]: messages?.length === 0,
                })}
                ref={messagesContainerRef}
            >
                {messages?.map((message) => (
                    <TechnicalSupportConversationMessage
                        isAnswer={message.isAnswer}
                        key={message.id}
                        message={message}
                    />
                ))}

                {messages?.length === 0 && (
                    <Empty description={<Locale stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__COMMENTS_EMPTY" />} />
                )}
            </div>
            {canCreateNewMessage && (
                <TechnicalSupportNewMessageForm
                    createNewMessage={createNewMessage}
                    isCreatingMessage={isCreatingMessage}
                />
            )}
        </div>
    );
}
