import {Typography} from 'antd';
import Text from 'antd/lib/typography/Text';

import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';

import {CatalogCategories} from './catalog-categories';
import {SelectCategoriesFormItemWithoutCatalog} from './select-categories-form-item/select-categories-form-item-without-catalog';
import * as styles from './categories-widget.scss';

function getNonEmptyArrayRule(errorMessage: string) {
    return {
        validator: (_rule: unknown, value: Array<string>): Promise<void> => {
            if (value.length > 0) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(errorMessage));
        },
    };
}

type PropsType = {
    name: string;
    isPaidYandexPlacement: boolean;
};

export function CategoriesWidget(props: PropsType): JSX.Element {
    const {name, isPaidYandexPlacement} = props;

    const documentationLinks = useDocumentationLinks();
    const {getLocalizedString} = useLocale();

    return (
        <>
            <Text className={styles.categories_widget__description}>
                <Locale stringKey="COMPANY_FORM__CATEGORIES__ADD_ONE_MAIN_CATEGORY_AND_THE_OTHERS_IN_DECREASING_RELEVANCE" />
                &nbsp;
                {documentationLinks.company?.categories && (
                    <a href={documentationLinks.company?.categories} rel="noreferrer" target="_blank">
                        <Locale stringKey="TEXT__LEARN_MORE" />
                    </a>
                )}
            </Text>

            <Form.Item<CompanyFormType>
                name={CompanyKeyEnum.RocketdataCategories}
                rules={[getNonEmptyArrayRule(getLocalizedString('COMPANY_FORM__CATEGORIES__VALIDATION_ERROR'))]}
            >
                <SelectCategoriesFormItemWithoutCatalog>
                    <Form.Item<CompanyFormType> dependencies={[CompanyKeyEnum.RocketdataCategories]} noStyle>
                        {({getFieldValue}) =>
                            getFieldValue(CompanyKeyEnum.RocketdataCategories).length > 0 && (
                                <Typography.Text className={styles.categories_widget__label} type="secondary">
                                    <Locale stringKey="COMPANY_FORM__CATEGORIES__SEARCH_CATEGORIES__DESCRIPTION" />
                                </Typography.Text>
                            )
                        }
                    </Form.Item>
                </SelectCategoriesFormItemWithoutCatalog>
            </Form.Item>

            <CatalogCategories isPaidYandexPlacement={isPaidYandexPlacement} name={name} />
        </>
    );
}
