import {Alert, Button} from 'antd';
import {useEffect, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Locale} from '../../../../provider/locale/localization';
import {fetchGoogleProcessingKey} from '../../../../service/google/processing/google-processing-api';
import {logError} from '../../../../util/error';
import {useUrl} from '../../../../util/url-hook/url-hook';

type PropsType = {
    className?: string;
};

export function SourcesAlert(props: PropsType): JSX.Element | null {
    const {className} = props;

    const [processingKey, setProcessingKey] = useState<string | null>(null);

    const {pushUrl} = useUrl();

    useEffect(() => {
        fetchGoogleProcessingKey()
            .then(({key}) => setProcessingKey(key))
            .catch(logError);
    }, []);

    if (!processingKey) {
        return null;
    }

    return (
        <Alert
            action={
                <Button onClick={() => pushUrl(appRoute.sourcesAccountSyncGoogle.path)} size="small">
                    <Locale stringKey="SOURCES__ACTIVE_PROCESSING_ALERT__BUTTON" />
                </Button>
            }
            className={className}
            description={<Locale stringKey="SOURCES__ACTIVE_PROCESSING_ALERT__DESCRIPTION" />}
            message={<Locale stringKey="SOURCES__ACTIVE_PROCESSING_ALERT__MESSAGE" />}
            showIcon
            type="warning"
        />
    );
}
