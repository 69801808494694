import {FormattedNumber} from '../../../provider/locale/formatted-number';
import {useFormat} from '../../../util/format-hook/format-hook';

import * as styles from './value-percent-cell.scss';

type PropsType = {
    percent: number;
    value: number;
    valueClassName?: string;
};

export function ValuePercentCell(props: PropsType): JSX.Element {
    const {valueClassName = '', value, percent} = props;

    const {getFormattedNumber} = useFormat();

    return (
        <>
            <span className={valueClassName}>{getFormattedNumber(value)} </span>

            {percent ? (
                <span className={styles.value_percent_cell__percentage}>
                    (<FormattedNumber options={{style: 'percent'}} value={percent} />)
                </span>
            ) : null}
        </>
    );
}
