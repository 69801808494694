import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {ReviewNoteUserType} from '../../../../../service/reviews/reviews-notes';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {LogActionsEnum} from '../review-log-type';

import * as styles from './note-log.scss';

type PropsType = {
    action: string;
    createdAt: string;
    user: ReviewNoteUserType;
};

export function NoteLog(props: PropsType): JSX.Element | null {
    const {action, createdAt, user} = props;

    const {companyName} = useDomainConfig();
    const {getFormattedDateTime} = useFormat();

    const formattedTime = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;
    const formattedDate = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          })
        : null;

    const userName = (() => {
        if (user) {
            const {firstName, lastName} = user;

            if (firstName || lastName) {
                return `${firstName || ''} ${lastName || ''}`;
            }
        }

        return companyName;
    })();

    const localeString = (() => {
        switch (action) {
            case LogActionsEnum.Created:
                return 'REVIEWS__REVIEW_NOTE_CREATED_BY';
            case LogActionsEnum.Deleted:
                return 'REVIEWS__REVIEW_NOTE_DELETED_BY';
            default:
                return 'REVIEWS__REVIEW_NOTE_UPDATED_BY';
        }
    })();

    return (
        <div className={styles.NoteLog}>
            <span className={styles.NoteLog_data}>
                <Locale
                    stringKey={localeString}
                    valueMap={{
                        user: <span className={styles.NoteLog_user}>{userName}</span>,
                    }}
                />
            </span>
            <span className={styles.NoteLog_date}>
                {formattedTime} {formattedDate}
            </span>
        </div>
    );
}
