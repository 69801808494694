import {Col, Row} from 'antd';

import {LandingBlurredCard} from '../../../../../component/landing/landing-blurred-card/landing-blurred-card';
import {LandingCallToAction} from '../../../../../component/landing/landing-call-to-action/landing-call-to-action';
import {LandingCard} from '../../../../../component/landing/landing-card/landing-card';
import {LandingPageCard} from '../../../../../component/landing/landing-page-card/landing-page-card';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {DEFAULT_TRIAL_PERIOD_DAYS} from '../../../../../provider/license/license-const';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useSystem} from '../../../../../provider/system/system-hook';
import {
    useOnlinePresenceFormattedLabels,
    useOnlinePresenceTrialDashboard,
} from '../../../../../service/online-presence/online-presence-hook';
import {ImpressionsDynamic} from '../../impressions-dynamic/impressions-dynamic';
import * as onlinePresenceStyles from '../../online-presence.scss';
import {PrimaryIndicators} from '../../primary-indicators/primary-indicators';
import {TargetActionsDynamics} from '../../target-actions-dynamics/target-actions-dynamics';

import {getOnlinePresenceLandingImage} from './online-presence-landing-helper';
import {DESKTOP_BLUR_HEIGHT, MOBILE_BLUR_HEIGHT} from './online-presense-landing-const';

type PropsType = {
    onTryFeatureClick: VoidFunction;
};

export function OnlinePresenceLanding(props: PropsType): JSX.Element {
    const {onTryFeatureClick} = props;
    const {shortLocaleName} = useLocale();
    const {companyName, domainName} = useDomainConfig();

    const {data, isLoading} = useOnlinePresenceTrialDashboard();

    const {screen} = useSystem();
    const {isMobile} = screen;

    const {labels, comparedLabels} = useOnlinePresenceFormattedLabels(data?.labels, data?.comparedLabels);

    return (
        <LandingPageCard>
            {isLoading && <Spinner />}
            {data && data.isValid && (
                <LandingBlurredCard
                    disablePointerEvents
                    maxHeight={isMobile ? MOBILE_BLUR_HEIGHT : DESKTOP_BLUR_HEIGHT}
                >
                    <Row gutter={[24, 24]}>
                        <Col className={onlinePresenceStyles.OnlinePresence_column} xl={12} xs={24}>
                            <PrimaryIndicators bordered data={data.base} isCompareMode={false} />

                            <ImpressionsDynamic
                                bordered
                                comparePeriodData={data.impressionsDynamic.compared}
                                comparedLabels={comparedLabels}
                                isCompareMode={false}
                                labels={labels}
                                mainPeriodData={data.impressionsDynamic.current}
                                staticChart
                            />
                        </Col>
                        <Col className={onlinePresenceStyles.OnlinePresence_column} xl={12} xs={24}>
                            <TargetActionsDynamics
                                bordered
                                comparedLabels={comparedLabels}
                                data={data.actionsDynamic}
                                isCompareMode={false}
                                labels={labels}
                                staticChart
                            />
                        </Col>
                    </Row>
                </LandingBlurredCard>
            )}
            <LandingCard
                buttonLangKey="BUTTON__TRY_FOR_FREE"
                imageSrc={getOnlinePresenceLandingImage(shortLocaleName, 1, domainName)}
                mainItem
                onClick={onTryFeatureClick}
                primaryDescriptionItems={[
                    {stringKey: 'ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_1__TEXT', valueMap: {companyName}},
                ]}
                textFirst
                titleLangKey="ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_1__TITLE"
            />
            <LandingCard
                imageSrc={getOnlinePresenceLandingImage(shortLocaleName, 2, domainName)}
                primaryDescriptionItems={['ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_2__TEXT']}
                titleLangKey="ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_2__TITLE"
                titleValueMap={{companyName}}
            />
            <LandingCallToAction
                colorful
                descriptionLangKey="ONLINE_PRESENCE_ANALYTICS__LANDING__CALL_TO_ACTION_1__DESCRIPTION"
                descriptionValueMap={{duration: DEFAULT_TRIAL_PERIOD_DAYS}}
                duration={DEFAULT_TRIAL_PERIOD_DAYS}
                onClick={onTryFeatureClick}
            />
        </LandingPageCard>
    );
}
