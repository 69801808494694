import {faImage} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useRef} from 'react';

import {LandingBlurredCard} from '../../../../../../component/landing/landing-blurred-card/landing-blurred-card';
import {LANDING_BLOCK_MOBILE_WIDTH_THRESHOLD} from '../../../../../../component/landing/landing-helper';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {PhotoToolCatalogStatsType, PhotoType} from '../../../../../../service/phototool/phototool-type';
import {classNames} from '../../../../../../util/css';
import {useDomElementRect} from '../../../../../../util/dom-hook/dom-hook';
import {PhotoItem} from '../../../pages/photo-tool-list/photo-item/photo-item';
import {
    DEFAULT_PHOTO_TOOL_PHOTO_WIDTH,
    minPhotoHeight,
    photoGap,
} from '../../../pages/photo-tool-list/photo-tool-list-const';
import {PinterestLayout} from '../../../pages/photo-tool-list/pinterest-layout/pinterest-layout';

import {PhotoToolLandingStatsItem} from './photo-tool-landing-stats-item';
import * as styles from './photo-tool-landing-stats.scss';

type PropsType = {
    catalogStats: PhotoToolCatalogStatsType;
    photos: Array<PhotoType>;
};

export function PhotoToolLandingStats(props: PropsType): JSX.Element {
    const {catalogStats, photos} = props;
    const {catalogs, totalCount} = catalogStats;
    const {getLocalizedString} = useLocale();

    const containerRef = useRef<HTMLDivElement | null>(null);
    const containerRect = useDomElementRect(containerRef);

    const directionColumn = Boolean(
        containerRect?.width && containerRect?.width < LANDING_BLOCK_MOBILE_WIDTH_THRESHOLD
    );

    return (
        <div className={styles.PhotoToolLandingStats} ref={containerRef}>
            <div
                className={classNames(styles.PhotoToolLandingStats_catalogStats, {
                    [styles.PhotoToolLandingStats_catalogStats__directionColumn]: directionColumn,
                })}
            >
                <PhotoToolLandingStatsItem
                    logo={<FontAwesomeIcon className={styles.PhotoToolLandingStats_catalogStatsIcon} icon={faImage} />}
                    name={getLocalizedString('PHOTO_TOOL__LANDING__TOTAL__PHOTOS')}
                    photosCount={totalCount}
                />
                {catalogs.map((catalogData) => (
                    <PhotoToolLandingStatsItem
                        key={catalogData.id}
                        logo={
                            <img
                                alt={catalogData.name}
                                className={styles.PhotoToolLandingStats_catalogStatsIcon}
                                src={catalogData.logo}
                            />
                        }
                        name={catalogData.name}
                        photosCount={catalogData.photosCount}
                    />
                ))}
            </div>

            <LandingBlurredCard disablePointerEvents maxHeight={600}>
                <PinterestLayout<PhotoType>
                    columnGap={photoGap}
                    columnWidth={DEFAULT_PHOTO_TOOL_PHOTO_WIDTH}
                    list={photos}
                    minColumns={2}
                    minHeight={minPhotoHeight}
                    renderItem={(photo: PhotoType, width: number): JSX.Element => {
                        return (
                            <div key={photo.id} style={{marginBottom: photoGap}}>
                                <PhotoItem
                                    isSelectMode={false}
                                    isSelected={false}
                                    minHeight={minPhotoHeight}
                                    photo={photo}
                                    showStatus={false}
                                    width={width}
                                />
                            </div>
                        );
                    }}
                />
            </LandingBlurredCard>
        </div>
    );
}
