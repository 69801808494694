import {faArrowsRotate, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from 'antd';

import {AccountCard} from '../../../../../component/account/account-card';
import {AccountLinkingCardTypeEnum} from '../../../../../component/account/account-card-type';
import {AddAccountCard} from '../../../../../component/account/add-account-card';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {CatalogType} from '../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../provider/locale/localization';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {FacebookAccountType, GoogleAccountType} from '../../../../../service/accounts/accounts-type';

import {getAccountCardType} from './account-selector-helper';
import * as styles from './account-selector.scss';

type PropsType = {
    isInProgress: boolean;
    hasError: boolean;
    accounts: Array<FacebookAccountType | GoogleAccountType> | null;
    catalogInfo?: CatalogType | null;
    linkingSrc: string;
    reloadAccounts: () => void;
    onSelectAccount: (accountId: number) => void;
};

export function AccountSelector(props: PropsType): JSX.Element {
    const {accounts, catalogInfo, linkingSrc, reloadAccounts, onSelectAccount, isInProgress, hasError} = props;

    const {modal} = useModal();

    function selectAccount(account: FacebookAccountType | GoogleAccountType) {
        if (getAccountCardType(account) === AccountLinkingCardTypeEnum.DEFAULT) {
            onSelectAccount(account.id);
            return;
        }

        modal.error({
            title: account.name,
            content: <Locale stringKey="ACCOUNT_SELECTOR__GRANTS_INVALID" />,
        });
    }

    return (
        <div className={styles.accounts_selector__content_wrapper}>
            {hasError && <Alert message={<Locale stringKey="ACCOUNT_SELECTOR__FETCH_ERROR" />} type="error" />}

            {isInProgress && <Spinner />}

            {catalogInfo && accounts && (
                <AddAccountCard
                    buttonContent={
                        <>
                            <FontAwesomeIcon icon={faPlus} /> / <FontAwesomeIcon icon={faArrowsRotate} />
                        </>
                    }
                    linkingSrc={linkingSrc}
                    onClose={reloadAccounts}
                />
            )}

            {catalogInfo &&
                accounts?.map((account: FacebookAccountType | GoogleAccountType) => {
                    return (
                        <AccountCard
                            button={
                                <Button onClick={() => selectAccount(account)}>
                                    <Locale stringKey="ACCOUNT_SELECTOR__SELECT_ACCOUNT" />
                                </Button>
                            }
                            catalogName={catalogInfo.name}
                            iconSrc={catalogInfo.logo}
                            info={account.name}
                            key={account.id}
                            type={getAccountCardType(account)}
                        />
                    );
                })}
        </div>
    );
}
