import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {reviewsCountByCatalogSchema, ReviewsCountByCatalogType} from './reviews-count-by-catalog-type';

export function fetchReviewsCountByCatalog(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<ReviewsCountByCatalogType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/by_catalog_count/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        reviewsCountByCatalogSchema
    );
}
