export enum PostsFilterStateUrlQueryNameEnum {
    createdAtAfter = 'created_at_after',
    createdAtBefore = 'created_at_before',
    statuses = 'status',
    catalogs = 'catalogs_ids',
}

export type PostsFilterQueriesType = Readonly<{
    [PostsFilterStateUrlQueryNameEnum.createdAtAfter]: string;
    [PostsFilterStateUrlQueryNameEnum.createdAtBefore]: string;
    [PostsFilterStateUrlQueryNameEnum.statuses]: string;
    [PostsFilterStateUrlQueryNameEnum.catalogs]: string;
}>;
