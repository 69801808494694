import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchCatalogsByGroup, fetchCatalogsForAccounts, suggestCatalog} from './catalogs-api';
import {CatalogGroupType, SuggestedCatalogType} from './catalogs-type';

export function useCatalogsByGroup(groupId?: number): UseHookType<Array<CatalogGroupType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CatalogGroupType>>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchCatalogsByGroup(groupId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult, groupId]);

    return {isInProgress, processError, result, reset};
}

export function useCatalogsByAccount(catalogId?: number): UseHookType<Array<CatalogGroupType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CatalogGroupType>>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchCatalogsForAccounts(catalogId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult, catalogId]);

    return {isInProgress, processError, result, reset};
}

export function useSuggestedCatalog(): UseHookType<SuggestedCatalogType> & {
    create: (name: string) => Promise<void | SuggestedCatalogType>;
} {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<SuggestedCatalogType>();

    function create(name: string): Promise<void | SuggestedCatalogType> {
        setIsInProgress(true);
        setProcessError(null);

        const createPromise = suggestCatalog(name);

        createPromise
            .then((data) => {
                if (data) {
                    setResult(data);
                }
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);

        return createPromise;
    }

    return {isInProgress, processError, result, reset, create};
}
