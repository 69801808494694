import {Button} from 'antd';
import {ReactNode} from 'react';

import {Logo} from '../../../../layout/logo/logo';
import {useSystem} from '../../../../provider/system/system-hook';
import {classNames} from '../../../../util/css';
import {HeaderButtons} from '../header-buttons/header-buttons';
import {HeaderButtonEnum} from '../header-buttons/header-buttons-hook';
import {HeaderFilter} from '../header-filter/header-filter';

import * as styles from './header.scss';

type PropsType = {
    isFilterHidden: boolean;
    isToggleButtonHidden?: boolean;
    isShortLogo?: boolean;
    menuIcon?: ReactNode;
    onMenuToggle?: () => void;
    logoClassName?: string;
    buttons?: Array<HeaderButtonEnum> | null;
};

export function Header(props: PropsType): JSX.Element {
    const {isFilterHidden, isToggleButtonHidden, isShortLogo, menuIcon, onMenuToggle, logoClassName, buttons} = props;

    const {screen} = useSystem();
    const {isDesktop, isMobile} = screen;

    return (
        <div className={classNames(styles.header, {[styles.header__mobile]: !isDesktop})} role="banner">
            <div
                className={classNames(styles.icons, {
                    [styles.icons__mobile]: !isDesktop,
                    [styles.icons__no_toggle_button]: isToggleButtonHidden,
                })}
            >
                <Logo
                    className={styles.logoLink_image}
                    isShort={isShortLogo}
                    linkClassName={classNames(styles.logoLink, logoClassName, {
                        [styles.logoLink__mobile]: !isDesktop,
                    })}
                />

                {!isToggleButtonHidden && menuIcon && (
                    <Button className={styles.toggle_button} icon={menuIcon} onClick={onMenuToggle} type="text" />
                )}
            </div>

            {!isFilterHidden && (
                <HeaderFilter className={classNames(styles.filter, {[styles.filter__mobile]: !isDesktop})} />
            )}

            <HeaderButtons
                buttons={buttons}
                className={classNames(styles.buttons, {[styles.buttons__mobile]: isMobile})}
            />
        </div>
    );
}
