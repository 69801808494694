import {appRoute} from '../../../app-route';
import {BreadCrumbItemType} from '../../../layout/bread-crumbs/bread-crumbs-type';
import {LocaleContextType} from '../../../provider/locale/locale-context-type';
import {FILE_SIZE_BYTES} from '../../../service/company/company-const';
import {
    BulkEditFieldNameEnum,
    BulkUpdateCompaniesFieldsType,
    CompanyImageKindEnum,
    CompanyMediaFileType,
    FieldModeEnum,
} from '../../../service/company/company-type';
import {splitCommaSeparatedString} from '../../../util/string';
import {TupleType} from '../../../util/type';

import {
    isInputStringFalsy,
    isTemporaryWorkDayEmpty,
    isWorkDayEmpty,
    serializeBulkUpdateWebsite,
    serializePaymentMethodsV2,
    serializePhones,
    serializeTemporaryWorkDays,
    serializeWorkDays,
} from './bulk-edit-companies-serialize-helper';
import {BulkEditFieldType, BulkUpdateCompaniesErrorsType, GalleryValidatorPropsType} from './bulk-edit-companies-type';
import {BulkEditFieldsFormType} from './fields-list/fields-list-type';
import {WEBSITE_PROTOCOL} from './form-items/company-form-items/websites-form-item/websites-form-item-const';

export const DEFAULT_MEDIA_FILE: CompanyMediaFileType = {
    id: 0,
    file_s3: '',
    file_type: CompanyImageKindEnum.logo,
    temp_id: '',
};

export const BULK_EDIT_COMPANIES_BREADCRUMB: Array<BreadCrumbItemType> = [
    {
        path: appRoute.myCompanies.path,
        titleLangKey: 'CATEGORY_NAME__MY_COMPANIES',
    },
    {
        path: appRoute.bulkEditCompanies.path,
        titleLangKey: 'CATEGORY_NAME__EDITING_COMPANIES',
    },
];

export const BULK_EDIT_FIELDS: TupleType<BulkEditFieldType, 18> = [
    {
        name: BulkEditFieldNameEnum.names,
        label: 'BULK_EDIT_COMPANIES__FIELD_LABEL__NAME',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.alternativeNames,
        label: 'BULK_EDIT_COMPANIES__FIELD_LABEL__ALTERNATIVE_NAME',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.legalForm,
        label: 'GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.legalName,
        label: 'GENERAL_INFO__LABEL__LEGAL_NAME',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.categories,
        label: 'BULK_EDIT_COMPANIES__FIELD_LABEL__CATEGORIES',
        isAddingAvailable: true,
        mode: FieldModeEnum.addition,
    },
    {
        name: BulkEditFieldNameEnum.phones,
        label: 'CONTACTS__LABEL__PHONE_NUMBERS',
        isAddingAvailable: true,
        mode: FieldModeEnum.addition,
    },
    {
        name: BulkEditFieldNameEnum.email,
        label: 'BULK_EDIT_COMPANIES__FIELD_LABEL__EMAIL',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.websites,
        label: 'BULK_EDIT_COMPANIES__LABEL_WEBSITE',
        isAddingAvailable: true,
        mode: FieldModeEnum.addition,
    },
    {
        name: BulkEditFieldNameEnum.socials,
        label: 'CONTACTS__LABEL__SOCIAL_NETWORKS',
        isAddingAvailable: true,
        mode: FieldModeEnum.addition,
    },
    {
        name: BulkEditFieldNameEnum.workingDays,
        label: 'ADDITIONAL_INFO__WORKING_HOURS',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.temporaryWorkingDays,
        label: 'ADDITIONAL_INFO__TEMPORARY_WORKING_HOURS',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.isTemporaryClosed,
        label: 'BULK_EDIT_COMPANIES__FIELD_LABEL__STATUS_TEMPORARILY_CLOSED',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.paymentMethods,
        label: 'ADDITIONAL_INFO__PAYMENT_METHODS',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.shortDescription,
        label: 'ADDITIONAL_INFO__SHORT_DESCRIPTION',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.fullDescription,
        label: 'ADDITIONAL_INFO__FULL_DESCRIPTION',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.logo,
        label: 'IMAGES__YOUR_COMPANY_LOGO',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.cover,
        label: 'IMAGES__COMPANY_CARD_COVER',
        isAddingAvailable: false,
        mode: FieldModeEnum.replacement,
    },
    {
        name: BulkEditFieldNameEnum.gallery,
        label: 'IMAGES__PHOTO_GALLERY',
        isAddingAvailable: true,
        mode: FieldModeEnum.replacement,
    },
];

export function serializeFieldByFieldName(
    fieldValues: BulkEditFieldsFormType,
    field: BulkEditFieldType
): BulkUpdateCompaniesFieldsType {
    const names = fieldValues[BulkEditFieldNameEnum.names];
    const alternativeNames = fieldValues[BulkEditFieldNameEnum.alternativeNames];
    const legalForm = fieldValues[BulkEditFieldNameEnum.legalForm];
    const legalName = fieldValues[BulkEditFieldNameEnum.legalName];
    const categories = fieldValues[BulkEditFieldNameEnum.categories];
    const phones = fieldValues[BulkEditFieldNameEnum.phones];
    const email = fieldValues[BulkEditFieldNameEnum.email];
    const websites = fieldValues[BulkEditFieldNameEnum.websites];
    const socials = fieldValues[BulkEditFieldNameEnum.socials];
    const workingDays = fieldValues[BulkEditFieldNameEnum.workingDays];
    const temporaryWorkingDays = fieldValues[BulkEditFieldNameEnum.temporaryWorkingDays];
    const isTemporaryClosed = fieldValues[BulkEditFieldNameEnum.isTemporaryClosed];
    const paymentMethods = fieldValues[BulkEditFieldNameEnum.paymentMethods];
    const shortDescription = fieldValues[BulkEditFieldNameEnum.shortDescription];
    const fullDescription = fieldValues[BulkEditFieldNameEnum.fullDescription];
    const logo = fieldValues[BulkEditFieldNameEnum.logo];
    const cover = fieldValues[BulkEditFieldNameEnum.cover];
    const gallery = fieldValues[BulkEditFieldNameEnum.gallery];

    return {
        [BulkEditFieldNameEnum.names]: names[0]?.text,
        [BulkEditFieldNameEnum.alternativeNames]: {
            value: splitCommaSeparatedString(alternativeNames),
            method: field.mode,
        },
        [BulkEditFieldNameEnum.legalForm]: legalForm ? String(legalForm.value) : '',
        [BulkEditFieldNameEnum.legalName]: legalName,
        [BulkEditFieldNameEnum.categories]: {
            value: categories,
            method: field.mode,
        },
        [BulkEditFieldNameEnum.phones]: {
            value: serializePhones(phones),
            method: field.mode,
        },
        [BulkEditFieldNameEnum.email]: email,
        [BulkEditFieldNameEnum.websites]: {
            value: serializeBulkUpdateWebsite(websites),
            method: field.mode,
        },
        [BulkEditFieldNameEnum.socials]: {
            value: socials.filter(isInputStringFalsy).map((social) => WEBSITE_PROTOCOL + social.value),
            method: field.mode,
        },
        [BulkEditFieldNameEnum.workingDays]: serializeWorkDays(workingDays),
        [BulkEditFieldNameEnum.temporaryWorkingDays]: {
            value: serializeTemporaryWorkDays(temporaryWorkingDays),
            method: field.mode,
        },
        [BulkEditFieldNameEnum.isTemporaryClosed]: isTemporaryClosed,
        [BulkEditFieldNameEnum.paymentMethods]: {
            value: serializePaymentMethodsV2(paymentMethods),
            method: field.mode,
        },
        [BulkEditFieldNameEnum.shortDescription]: shortDescription,
        [BulkEditFieldNameEnum.fullDescription]: fullDescription,
        [BulkEditFieldNameEnum.logo]: {
            value: logo.temp_id,
            method: field.mode,
        },
        [BulkEditFieldNameEnum.cover]: {
            value: cover.temp_id,
            method: field.mode,
        },
        [BulkEditFieldNameEnum.gallery]: {
            value: gallery.map((media) => media.temp_id),
            method: field.mode,
        },
    };
}

function validateGallery(
    props: GalleryValidatorPropsType,
    getLocalizedString: LocaleContextType['getLocalizedString']
): Array<string> | null {
    const {gallery, errorOnEmptyField} = props;

    if (gallery.length === 0) {
        return errorOnEmptyField;
    }

    if (gallery.some((image) => image.hasError)) {
        return [getLocalizedString('BULK_EDIT_COMPANIES__ERROR_GALLERY')];
    }

    return null;
}

// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
export function validateFieldByFieldName(
    fieldValues: BulkEditFieldsFormType,
    getLocalizedString: LocaleContextType['getLocalizedString']
): BulkUpdateCompaniesErrorsType {
    const errorFieldIsRequired = [getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED')];

    const names = fieldValues[BulkEditFieldNameEnum.names];
    const alternativeNames = fieldValues[BulkEditFieldNameEnum.alternativeNames];
    const legalForm = fieldValues[BulkEditFieldNameEnum.legalForm];
    const legalName = fieldValues[BulkEditFieldNameEnum.legalName];
    const categories = fieldValues[BulkEditFieldNameEnum.categories];
    const phones = fieldValues[BulkEditFieldNameEnum.phones];
    const email = fieldValues[BulkEditFieldNameEnum.email];
    const websites = fieldValues[BulkEditFieldNameEnum.websites];
    const socials = fieldValues[BulkEditFieldNameEnum.socials];
    const workingDays = fieldValues[BulkEditFieldNameEnum.workingDays];
    const temporaryWorkingDays = fieldValues[BulkEditFieldNameEnum.temporaryWorkingDays];
    const shortDescription = fieldValues[BulkEditFieldNameEnum.shortDescription];
    const fullDescription = fieldValues[BulkEditFieldNameEnum.fullDescription];
    const logo = fieldValues[BulkEditFieldNameEnum.logo];
    const cover = fieldValues[BulkEditFieldNameEnum.cover];
    const gallery = fieldValues[BulkEditFieldNameEnum.gallery];

    function validateAltNames() {
        if (!alternativeNames) {
            return errorFieldIsRequired;
        }

        if (splitCommaSeparatedString(alternativeNames).length > 5) {
            return [
                getLocalizedString('VALIDATION__ERROR__FIELD_COMMA_SEPARATED_LENGTH', {
                    length: '5',
                }),
            ];
        }

        return null;
    }

    function validateImageSize(file: CompanyMediaFileType) {
        return file?.size && file.size > FILE_SIZE_BYTES
            ? [getLocalizedString('COMPANY_FORM__GALLERY__ERROR__MAX_SIZE')]
            : null;
    }

    return {
        [BulkEditFieldNameEnum.names]: !names[0]?.text ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.alternativeNames]: validateAltNames(),
        [BulkEditFieldNameEnum.legalForm]: legalForm === null ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.legalName]: !legalName ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.categories]: categories.length === 0 ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.phones]: phones.length === 0 || !phones[0]?.value ? [errorFieldIsRequired] : null,
        [BulkEditFieldNameEnum.email]: !email ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.websites]: websites.length === 0 || !websites[0]?.value ? [errorFieldIsRequired] : null,
        [BulkEditFieldNameEnum.socials]: socials.length === 0 || !socials[0]?.value ? [errorFieldIsRequired] : null,
        [BulkEditFieldNameEnum.workingDays]: workingDays.map((workday) =>
            isWorkDayEmpty(workday) ? errorFieldIsRequired : null
        ),
        [BulkEditFieldNameEnum.temporaryWorkingDays]:
            temporaryWorkingDays.length === 0 ||
            (temporaryWorkingDays[0] && isTemporaryWorkDayEmpty(temporaryWorkingDays[0]))
                ? errorFieldIsRequired
                : null,
        [BulkEditFieldNameEnum.isTemporaryClosed]: [],
        [BulkEditFieldNameEnum.paymentMethods]: [],
        [BulkEditFieldNameEnum.shortDescription]: !shortDescription ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.fullDescription]: !fullDescription ? errorFieldIsRequired : null,
        [BulkEditFieldNameEnum.logo]: validateImageSize(logo) || (!logo.temp_id ? errorFieldIsRequired : null),
        [BulkEditFieldNameEnum.cover]: validateImageSize(logo) || (!cover.temp_id ? errorFieldIsRequired : null),
        [BulkEditFieldNameEnum.gallery]: validateGallery(
            {
                gallery,
                errorOnEmptyField: errorFieldIsRequired,
            },
            getLocalizedString
        ),
    };
}
