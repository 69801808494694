import {gray7, green60, primary60} from '../../../../../../css/var-export.scss';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {CclCatalogStatusEnum, CclStateStatusEnum} from '../../../../../../util/type';

export const STATUS_LINK_TEXT_BY_CATALOG_STATUS: Record<
    CclCatalogStatusEnum.notVerified | CclCatalogStatusEnum.waitVerify | string,
    LangKeyType
> = {
    [CclCatalogStatusEnum.notVerified]: 'SOURCES__ACTION_LINK__CONFIRM',
    [CclCatalogStatusEnum.waitVerify]: 'SOURCES__ACTION_LINK__ENTER_CODE',
};

// keys was taken from these files:
// https://gitlab.com/rocketdata.io/rocketdata/-/blob/main/backend/app/company/modules/catalog/constants.py
// https://gitlab.com/rocketdata.io/rocketdata/-/blob/main/frontend/src/assets/languages/en.json#L792-804
export const STATUS_TEXT_BY_CCL_STATUS: Record<string, LangKeyType> = {
    sync: 'SOURCES__STATUS__SYNCED',
    synced: 'SOURCES__STATUS__SYNCED',
    sended: 'SOURCES__STATUS__SENT',
    send: 'SOURCES__STATUS__SENT',
    sent: 'SOURCES__STATUS__SENT',
    ready: 'SOURCES__STATUS__SENT',
    fill_require: 'SOURCES__STATUS__FILL_REQUIRE',
    no_payment_plan: 'SOURCES__STATUS__NO_PAYMENT_PLAN',
    not_payed: 'SOURCES__STATUS__NOT_PAYED',
    waiting_for_push: 'SOURCES__STATUS__WAITING_FOR_PUSH',
    on_moderation: 'SOURCES__STATUS__ON_MODERATION',
    is_suspended: 'SOURCES__STATUS__IS_SUSPENDED',
    unsupported_region: 'SOURCES__STATUS__UNSUPPORTED_REGION',
};

export const BADGE_COLOR_BY_CCL_STATUS: Record<string, string> = {
    [CclStateStatusEnum.send]: primary60,
    [CclStateStatusEnum.sync]: green60,
    [CclStateStatusEnum.unsupportedRegion]: gray7,
};
