import {Tag} from 'antd';

import {Locale} from '../../../../../provider/locale/locale';
import {YandexStorySelectedCompanyType} from '../../../../../service/yandex-stories/yandex-stories-type';
import {getYandexStoryStatusConfig} from '../../../stories-helper';

type PropsType = {
    record: YandexStorySelectedCompanyType;
};

export function YandexSelectedCompaniesStatusCell(props: PropsType): JSX.Element {
    const {record} = props;

    const storyStatusTagConfig = getYandexStoryStatusConfig(record.status);

    return (
        <Tag color={storyStatusTagConfig.tagColor}>
            <Locale stringKey={storyStatusTagConfig.langKey} />
        </Tag>
    );
}
