import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useEffect, useMemo} from 'react';
import {z as r} from 'zod';

import {FeedsTableStatusEnum} from '../../page/main/my-companies/feed/management/table/feeds-table-type';
import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../util/url';
import {generateResponseSchema} from '../api/api-type';

import {companiesImportUrl} from './companies-import-const';
import {companiesImportResultSchema} from './companies-import-result';
import {CompaniesImportTypeEnum} from './companies-import-type';

const companiesImportBrandSchema = r.object({
    id: r.number(),
    name: r.string(),
});

const importResultInfoOptionalSchema = companiesImportResultSchema.partial();

const companiesImportSchema = r.object({
    id: r.number(),
    link: r.string().nullable().optional(),
    importType: r.nativeEnum(CompaniesImportTypeEnum),
    periodicUpdate: r.boolean(),
    updateDate: r.string().nullable(),
    brands: r.array(companiesImportBrandSchema),
    importResultInfo: companiesImportResultSchema.partial(),
    cpStatus: r.nativeEnum(FeedsTableStatusEnum),
});

const companiesImportListSchema = generateResponseSchema(companiesImportSchema);

export type CompaniesImportListType = r.infer<typeof companiesImportListSchema>;
export type ImportResultInfoType = r.infer<typeof importResultInfoOptionalSchema>;
export type CompaniesImportType = r.infer<typeof companiesImportSchema>;

type FeedInfoType = r.infer<typeof companiesImportSchema>;

type FeedManagementOptionsType = {
    page: number;
    count: number;
    statuses?: Array<string>;
    brandIds?: Array<string>;
    name?: string;
};

export const companiesImportListUrl = `${companiesImportUrl}/import_url/`;

function getFeedInfoQueryUrl(feedId: number) {
    return `${companiesImportListUrl}${feedId}/`;
}

function getFeedInfo(feedId: number): Promise<FeedInfoType> {
    return fetchAndDeserialize(getFeedInfoQueryUrl(feedId), companiesImportSchema);
}

function getFeedManagementQueryUrl(options: FeedManagementOptionsType) {
    return `${companiesImportListUrl}?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

function fetchFeedManagementList(options: FeedManagementOptionsType): Promise<CompaniesImportListType> {
    return fetchAndDeserialize(getFeedManagementQueryUrl(options), companiesImportListSchema);
}

export function useFeedManagementList(
    statuses?: Array<string>,
    brandIds?: Array<string>
): PaginationType & UseQueryResult<CompaniesImportListType> {
    const pagination = usePagination({dependencies: null, initialPageSize: 10});

    const {page, pageSize, onDataLoaded} = pagination;

    const queryParameters = useMemo(
        () => ({page, count: pageSize, statuses, brandIds}),
        [page, pageSize, statuses, brandIds]
    );

    const queryKey = getFeedManagementQueryUrl({page, count: pageSize, statuses, brandIds});

    const query = useQuery([queryKey], () => fetchFeedManagementList(queryParameters));

    useEffect(() => {
        if (query.data) {
            onDataLoaded(query.data);
        }
    }, [onDataLoaded, query.data]);

    return {...query, pagination};
}

export function useFeedInfo(feedId: number): UseQueryResult<FeedInfoType> {
    const queryKey = getFeedInfoQueryUrl(feedId);

    return useQuery([queryKey], () => getFeedInfo(feedId));
}
