import {Radio} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {Form} from '../../../../../../../typings/antd';
import {AttributePropsType, BooleanAttributeType} from '../attributes-type';

import {booleanAttributeFalse, booleanAttributeTrue, booleanAttributeUnknown} from './boolean-attribute-const';

export function BooleanAttribute({value, onChange}: AttributePropsType<BooleanAttributeType>): JSX.Element | null {
    const {displayName: label, values} = value;

    const firstAndOnlyValue = values[0];

    if (!firstAndOnlyValue) {
        return null;
    }

    return (
        <Form.Item label={label}>
            <Radio.Group
                onChange={(event) =>
                    onChange?.({...value, values: [{...firstAndOnlyValue, value: event.target.value}]})
                }
                value={firstAndOnlyValue.value}
            >
                <Radio.Button value={booleanAttributeTrue}>
                    <Locale stringKey="COMPANY_FORM__ATTRIBUTES__BOOLEAN__TRUE" />
                </Radio.Button>

                <Radio.Button value={booleanAttributeFalse}>
                    <Locale stringKey="COMPANY_FORM__ATTRIBUTES__BOOLEAN__FALSE" />
                </Radio.Button>

                <Radio.Button value={booleanAttributeUnknown}>
                    <Locale stringKey="COMPANY_FORM__ATTRIBUTES__BOOLEAN__UNKNOWN" />
                </Radio.Button>
            </Radio.Group>
        </Form.Item>
    );
}
