import dayjs from 'dayjs';

import {ReviewRatingTypeEnum, ReviewSortingTypeEnum} from '../../../../service/reviews/reviews-type';
import {getEnumValueEnsure} from '../../../../util/enum';
import {toTrimmedString} from '../../../../util/string';
import {QueryMapType} from '../../../../util/type';
import {getReviewsFilterDataFromUrlQuery} from '../review-list-page-content/reviews-filter/reviews-filter-helper';
import {
    ReviewsFilterDataAsObjectUrlType,
    ReviewsFilterDataType,
} from '../review-list-page-content/reviews-filter/reviews-filter-type';

import {defaultReviewsState} from './reviews-state-const';
import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from './reviews-state-type';

export function reviewsFilterStateToUrlObject(filterState: ReviewsFilterDataType): ReviewsFilterDataAsObjectUrlType {
    const cleanFilterState: ReviewsFilterDataAsObjectUrlType = {};

    return Object.keys(filterState).reduce((accum: ReviewsFilterDataAsObjectUrlType, key: string) => {
        const value = filterState[key as keyof ReviewsFilterDataType];

        if (typeof value === 'number' && value !== null) {
            return {...accum, [key]: value};
        }

        if (typeof value === 'string' && value.trim() !== '') {
            return {...accum, [key]: value.trim()};
        }

        if (value instanceof Date) {
            return {...accum, [key]: dayjs(value).format('YYYY-MM-DD')};
        }

        if (Array.isArray(value) && value.length > 0) {
            return {...accum, [key]: value.join(',')};
        }

        return {...accum, [key]: ''};
    }, cleanFilterState);
}

const DEFAULT_REVIEWS_FILTER_ID = '';

export function getReviewStateFromUrlQuery(urlQueries: QueryMapType): ReviewsStateType {
    const ratingType: ReviewRatingTypeEnum = getEnumValueEnsure<ReviewRatingTypeEnum>(
        ReviewRatingTypeEnum,
        urlQueries[ReviewsStateUrlQueryNameEnum.ratingType],
        ReviewRatingTypeEnum.allRating
    );

    const ids: string =
        toTrimmedString(urlQueries[ReviewsStateUrlQueryNameEnum.ids]) ||
        defaultReviewsState[ReviewsStateUrlQueryNameEnum.ids];

    const companyIds: string =
        toTrimmedString(urlQueries[ReviewsStateUrlQueryNameEnum.companyIds]) ||
        defaultReviewsState[ReviewsStateUrlQueryNameEnum.companyIds];

    const filterId: string =
        toTrimmedString(urlQueries[ReviewsStateUrlQueryNameEnum.filterId]) || DEFAULT_REVIEWS_FILTER_ID;

    const orderingType: ReviewSortingTypeEnum =
        (toTrimmedString(urlQueries[ReviewsStateUrlQueryNameEnum.sorting]) as ReviewSortingTypeEnum) ||
        ReviewSortingTypeEnum.acs;

    return {
        [ReviewsStateUrlQueryNameEnum.ratingType]: ratingType,
        [ReviewsStateUrlQueryNameEnum.ids]: ids,
        [ReviewsStateUrlQueryNameEnum.companyIds]: companyIds,
        [ReviewsStateUrlQueryNameEnum.sorting]: orderingType,
        filterState: getReviewsFilterDataFromUrlQuery(urlQueries),
        filterId,
    };
}
