import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faBuilding, faCloudArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {Tag} from 'antd';
import {ReactElement} from 'react';

import {appRoute} from '../../../../app-route';
import {Locale} from '../../../../provider/locale/locale';
import {LangKeyType} from '../../../../provider/locale/translation/type';

import * as styles from './create-companies-onboarding-popup.scss';

type CreateCompanyOnboardingButtonType = {
    label: LangKeyType;
    description: LangKeyType;
    link?: string;
    tag?: ReactElement;
    icon: IconProp;
    canHaveErrors?: boolean;
    isAdditionalModal?: boolean;
};

export const CREATE_COMPANY_ONBOARDING_LINKS: Array<CreateCompanyOnboardingButtonType> = [
    {
        label: 'ONBOARDING__CREATE_COMPANY_POPUP__IMPORT__TITLE',
        description: 'ONBOARDING__CREATE_COMPANY_POPUP__IMPORT__DESCRIPTION',
        link: appRoute.companyImport.path,
        tag: (
            <Tag className={styles.CreateCompaniesOnboardingPopup_tag}>
                <Locale stringKey="ONBOARDING__CREATE_COMPANY_POPUP__IMPORT__TAG" />
            </Tag>
        ),
        icon: faCloudArrowUp,
    },
    {
        label: 'ONBOARDING__CREATE_COMPANY_POPUP__ADD_COMPANY__TITLE',
        description: 'ONBOARDING__CREATE_COMPANY_POPUP__ADD_COMPANY__DESCRIPTION',
        link: appRoute.createCompanySelectBrand.path,
        icon: faBuilding,
    },
];
