export enum ScreenWidthNameEnum {
    'desktop' = 'desktop',
    'mobile' = 'mobile',
    'tablet' = 'tablet',
}

export type SystemContextScreenType = {
    devicePixelRatio: number;
    height: number;
    isDesktop: boolean;
    isLandscape: boolean;
    isMobile: boolean;
    isPortrait: boolean;
    isTablet: boolean;
    littleThenList: Array<ScreenWidthNameEnum>;
    name: ScreenWidthNameEnum;
    width: number;
};

type SystemContextBrowsersType = {
    isFirefox: boolean;
    isSamsungBrowser: boolean;
    isOpera: boolean;
    isEdgeLegacy: boolean;
    isEdge: boolean;
    isChrome: boolean;
    isSafari: boolean;
};

export type SystemContextType = {
    isAndroid: boolean;
    isScriptLoaded: boolean;
    isWindowLoaded: boolean;
    screen: SystemContextScreenType;
    browsers: SystemContextBrowsersType;
};
