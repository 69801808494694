/* eslint-disable no-undefined */
import {SelectProps} from 'antd';
import dayjs, {Dayjs} from 'dayjs';

import {Locale} from '../../../../../../../provider/locale/localization';

export function dayJsInSelectedTimezone(timezone: string | undefined | null, day?: Dayjs): Dayjs {
    return (day || dayjs()).tz(timezone || undefined).tz(undefined, true);
}

export function getFrequencyOptions(): SelectProps['options'] {
    return [
        {
            label: <Locale stringKey="POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__WEEKLY" />,
            value: 7,
        },
        {
            label: <Locale stringKey="POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__HALF_MONTH" />,
            value: 14,
        },
        {
            label: <Locale stringKey="POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__MONTH" />,
            value: 30,
        },
    ];
}

export const AUTO_REFRESH_COUNT_MAX = 100;
export const AUTO_REFRESH_COUNT_MIN = 1;
