import {Select} from 'antd';
import Text from 'antd/lib/typography/Text';
import {BaseSelectRef} from 'rc-select';
import {
    Dispatch,
    Key,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    SortableContainer as createSortableContainer,
    SortableElement as createSortableElement,
    SortEvent,
    SortEventWithTag,
} from 'react-sortable-hoc';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {CommonCategoryApiType} from '../../../../../../../service/api/api-type';
import {FieldModeEnum} from '../../../../../../../service/company/company-type';
import {
    CompanyCatalogCategoryType,
    CompanyCategoryType,
} from '../../../../../../../service/company-v2/types/company-categories-type';
import {Form} from '../../../../../../../typings/antd';
import {arrayMove} from '../../../../../../../util/array';
import {classNames} from '../../../../../../../util/css';
import {debounce} from '../../../../../../../util/function';
import {useRefreshId} from '../../../../../../../util/hook';

import {CategoryListItemPropsType} from './select-categories-form-item-type';
import {SelectedCompanyCategoryItem} from './selected-category-item/selected-category-item';
import * as styles from './select-categories-form-item.scss';

const ANIMATION_DURATION_MS = 800 + 400 + 100; // 800 css delay + 400 css animation + 100 additional delay for react

export type CommonCategoryType = CompanyCategoryType | CompanyCatalogCategoryType;

type PropsType<
    TCompanyCategoryType extends CommonCategoryType,
    TCompanyCategoryApiType extends CommonCategoryApiType
> = PropsWithChildren<{
    value?: Array<TCompanyCategoryType>;
    onChange?: (value: Array<TCompanyCategoryType>) => void;

    companyCategoryList: Array<TCompanyCategoryApiType>;
    isInProgress: boolean;

    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;

    renderOption: (category: TCompanyCategoryApiType) => JSX.Element;
    createCategory: (category: TCompanyCategoryApiType, isMain: boolean) => TCompanyCategoryType;
    getId: (category: TCompanyCategoryType) => Key;
    getApiId: (category: TCompanyCategoryApiType) => Key;

    disableDragging?: boolean;
    required?: boolean;
    locked?: boolean;
    catalogId?: number;
    maxAmount?: number;
    hasError?: boolean;
    className?: string;
    mode?: FieldModeEnum;
}>;

// eslint-disable-next-line max-statements
export function SelectCategoriesFormItem<
    TCompanyCategoryType extends CommonCategoryType,
    TCompanyCategoryApiType extends CommonCategoryApiType
>(props: PropsType<TCompanyCategoryType, TCompanyCategoryApiType>): JSX.Element {
    const {
        children,
        value: selectedCompanyCategoryList = [],
        onChange: onChangeCategories = () => null,
        companyCategoryList,
        isInProgress,
        searchQuery,
        setSearchQuery,
        renderOption,
        createCategory,
        getId,
        getApiId,

        disableDragging,
        required,
        locked,
        className,
        mode = FieldModeEnum.replacement,
        maxAmount,
    } = props;

    const fullClassName = classNames(styles.select_company_category_list, className);

    const {getLocalizedString} = useLocale();
    const {refresh: refreshSelect, refreshId: refreshSelectId} = useRefreshId();

    const [innerCategories, setInnerCategories] = useState<Array<TCompanyCategoryType>>([]);
    const [animatedId, setAnimatedId] = useState<Key | null>(null);
    const [isEditingPrimaryCategory, setIsEditingPrimaryCategory] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const selectRef = useRef<BaseSelectRef | null>(null);

    const isAddition = mode === FieldModeEnum.addition;

    const categories = useMemo(
        () =>
            isAddition
                ? innerCategories
                : [
                      ...selectedCompanyCategoryList.filter(({isMain}) => isMain),
                      ...selectedCompanyCategoryList.filter(({isMain}) => !isMain),
                  ],
        [innerCategories, isAddition, selectedCompanyCategoryList]
    );
    const [firstCategory, ...restCategories] = categories;

    useEffect(() => {
        if (isEditingPrimaryCategory && selectRef.current) {
            selectRef.current.focus();
        }
    }, [isEditingPrimaryCategory]);

    useEffect(() => {
        const timeoutId = setTimeout(() => setAnimatedId(null), ANIMATION_DURATION_MS);

        return () => clearTimeout(timeoutId);
    }, [animatedId]);

    const removeFromSelectedCompanyCategory = useCallback(
        (item: TCompanyCategoryType) => {
            const newSelectedCompanyCategoryList = [...categories];

            newSelectedCompanyCategoryList.splice(newSelectedCompanyCategoryList.indexOf(item), 1);

            if (item.isMain && newSelectedCompanyCategoryList[0]) {
                newSelectedCompanyCategoryList[0].isMain = true;
            }

            onChangeCategories(newSelectedCompanyCategoryList);

            if (isAddition) {
                setInnerCategories(newSelectedCompanyCategoryList);
            }
        },
        [categories, onChangeCategories, isAddition]
    );

    const setPrimaryCompanyCategory = useCallback(
        (item: TCompanyCategoryType) => {
            const newSelectedCompanyCategoryList = categories
                .map((category) => (category.isMain ? item : category))
                .filter((category) => (getId(category) === getId(item) ? category.isMain : true));

            onChangeCategories(newSelectedCompanyCategoryList);
        },
        [categories, getId, onChangeCategories]
    );

    const handleSortEnd = useCallback(
        (sortData: {oldIndex: number; newIndex: number}) => {
            const {newIndex, oldIndex} = sortData;
            const shiftIndex = 1; // prevent moving first item
            const newSelectedCompanyCategoryList = arrayMove(
                [...categories],
                oldIndex + shiftIndex,
                newIndex + shiftIndex
            );

            onChangeCategories(newSelectedCompanyCategoryList);

            if (isAddition) {
                setInnerCategories(newSelectedCompanyCategoryList);
            }
        },
        [categories, onChangeCategories, isAddition]
    );

    const SortableItem = createSortableElement<CategoryListItemPropsType<TCompanyCategoryType>>(
        (sortableItemProps: CategoryListItemPropsType<TCompanyCategoryType>): JSX.Element => {
            const {value, isMain, mode: fieldMode, isDisabled} = sortableItemProps;

            return (
                <SelectedCompanyCategoryItem
                    className={classNames(getId(value) === animatedId ? styles.selected_category_item_animated : '')}
                    companyCategoryItem={value}
                    disableDragging={isDisabled}
                    isEditingFirst={isEditingPrimaryCategory}
                    isFirst={isMain}
                    isMarkPrimaryAvailable={fieldMode === FieldModeEnum.replacement}
                    locked={locked}
                    removeCompanyCategory={removeFromSelectedCompanyCategory}
                />
            );
        }
    );

    const SortableList = createSortableContainer<{items: Array<TCompanyCategoryType>}>(
        (sortableContainerProps: {items: Array<TCompanyCategoryType>}) => {
            const {items} = sortableContainerProps;
            const isDisabledSortableList = items.length === 1 || disableDragging;

            return (
                <ul className={styles.selected_category_list}>
                    {items.map((item: TCompanyCategoryType, index: number): JSX.Element | null => {
                        /* for some reason 'disabled' is always undefined in SortableItem, added 'isDisabled' to fix */
                        return (
                            <SortableItem
                                disabled={isDisabledSortableList || isEditingPrimaryCategory}
                                index={index}
                                isDisabled={isDisabledSortableList}
                                isMain={false}
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={`${getId(item)}-${index}`}
                                mode={mode}
                                value={item}
                            />
                        );
                    })}
                </ul>
            );
        }
    );

    const pushToSelectedCompanyCategoryList = useCallback(
        (newItem: TCompanyCategoryType) => {
            const existedItem = categories.find(
                (itemInList: TCompanyCategoryType): boolean => getId(newItem) === getId(itemInList)
            );

            if (existedItem) {
                setAnimatedId(getId(newItem));
                return;
            }

            onChangeCategories([...categories, newItem]);

            if (isAddition) {
                setInnerCategories([...categories, newItem]);
            }
        },
        [categories, onChangeCategories, isAddition, getId]
    );

    function onChange(companyCategoryId: string | number) {
        const selectedCategory = companyCategoryList.find((companyCategory): boolean => {
            return companyCategoryId === getApiId(companyCategory);
        });

        if (!selectedCategory) {
            return;
        }

        if (isEditingPrimaryCategory) {
            const generalCategory = createCategory(selectedCategory, true);

            setPrimaryCompanyCategory(generalCategory);
            setIsEditingPrimaryCategory(false);
            setAnimatedId(getId(generalCategory));
        } else {
            const isMain: boolean = isEditingPrimaryCategory || selectedCompanyCategoryList.length === 0;
            const generalCategory = createCategory(selectedCategory, isMain);

            pushToSelectedCompanyCategoryList(generalCategory);
            setAnimatedId(getId(generalCategory));
        }

        setSearchQuery('');
        refreshSelect();
    }

    const shouldCancelStart = useCallback(
        (event: SortEvent | SortEventWithTag) => {
            const disabledElements = ['button', 'svg', 'path'];
            const element = event.target as HTMLElement;

            return Boolean(animatedId) || disabledElements.includes(element.tagName.toLowerCase());
        },
        [animatedId]
    );

    return (
        <div className={fullClassName}>
            <Form.Item
                hidden={locked}
                label={<Locale stringKey="COMPANY_FORM__CATEGORIES__SEARCH_CATEGORIES__LABEL" />}
                name={'category-' + refreshSelectId}
                required={required}
            >
                <Select<number>
                    className={styles.select_company_category_list__select}
                    disabled={categories?.length === maxAmount && !isEditingPrimaryCategory}
                    filterOption={() => true}
                    key={refreshSelectId}
                    loading={isInProgress}
                    // show 'No data' message only if user entered some text, otherwise we don't show anything
                    // eslint-disable-next-line no-undefined
                    notFoundContent={searchQuery === '' ? null : undefined}
                    onBlur={() => {
                        setIsFocused(false);
                        setIsEditingPrimaryCategory(false);
                    }}
                    onChange={onChange}
                    onFocus={() => setIsFocused(true)}
                    onSearch={debounce<[string]>(setSearchQuery, 300)}
                    placeholder={getLocalizedString('TEXT__START_TYPING')}
                    ref={selectRef}
                    showSearch
                    size="large"
                >
                    {companyCategoryList.map(renderOption)}
                </Select>
            </Form.Item>

            <div className={styles.select_company_category_list__categories_wrapper}>
                <div
                    className={classNames({
                        [styles.select_company_category_list__categories_locked]: locked,
                    })}
                >
                    {firstCategory || isFocused ? (
                        <div
                            className={classNames({
                                [styles.select_company_category_list__wrapper_empty]: restCategories.length === 0,
                            })}
                        >
                            <SelectedCompanyCategoryItem
                                className={classNames({
                                    [styles.selected_category_item_animated]:
                                        firstCategory && getId(firstCategory) === animatedId,
                                })}
                                companyCategoryItem={firstCategory || ({categoryName: '...'} as TCompanyCategoryType)}
                                disableDragging={disableDragging}
                                finishEditingPrimaryCompanyCategory={() => setIsEditingPrimaryCategory(false)}
                                isEditingFirst={isEditingPrimaryCategory}
                                isFirst
                                isMarkPrimaryAvailable
                                locked={locked}
                                removeCompanyCategory={removeFromSelectedCompanyCategory}
                                startEditingPrimaryCompanyCategory={() => setIsEditingPrimaryCategory(true)}
                            />
                        </div>
                    ) : null}
                    <SortableList
                        items={restCategories.filter(Boolean)}
                        lockAxis="y"
                        onSortEnd={handleSortEnd}
                        shouldCancelStart={shouldCancelStart}
                        useWindowAsScrollContainer
                    />
                    <div className={styles.select_company_category_list__total}>
                        {categories?.length === 0 ? (
                            <Locale stringKey="COMPANY_FORM__CATEGORIES__CATEGORIES_EMPTY" />
                        ) : (
                            <>
                                <Locale
                                    stringKey="COMPANY_FORM__CATEGORIES__CATEGORIES_COUNT"
                                    valueMap={{count: categories?.length}}
                                />
                                &nbsp;
                                {categories?.length === maxAmount ? (
                                    <Text type="secondary">
                                        <Locale stringKey="COMPANY_FORM__CATEGORIES__CATEGORIES_MAX" />
                                    </Text>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                </div>

                {children}
            </div>
        </div>
    );
}
