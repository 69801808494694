import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';

import localPackLandingImage1EnSrc from './images/local-pack-landing-image-1-en.png';
import localPackLandingImage1RuSrc from './images/local-pack-landing-image-1-ru.png';
import localPackLandingImage2EnSrc from './images/local-pack-landing-image-2-en.png';
import localPackLandingImage2RuSrc from './images/local-pack-landing-image-2-ru.png';
import localPackLandingImage3EnSrc from './images/local-pack-landing-image-3-en.png';
import localPackLandingImage3RuSrc from './images/local-pack-landing-image-3-ru.png';
import localPackLandingImage4EnSrc from './images/local-pack-landing-image-4-en.png';
import localPackLandingImage4RuSrc from './images/local-pack-landing-image-4-ru.png';
import localPackLandingImage5EnSrc from './images/local-pack-landing-image-5-en.png';
import localPackLandingImage5RuSrc from './images/local-pack-landing-image-5-ru.png';
import localPackLandingImage6EnSrc from './images/local-pack-landing-image-6-en.png';
import localPackLandingImage6RuSrc from './images/local-pack-landing-image-6-ru.png';

export function getLocalPackLandingImage(
    shortLocaleName: ShortLocaleNameEnum,
    cardIndex: 1 | 2 | 3 | 4 | 5 | 6
): string {
    const ruImages = {
        1: localPackLandingImage1RuSrc,
        2: localPackLandingImage2RuSrc,
        3: localPackLandingImage3RuSrc,
        4: localPackLandingImage4RuSrc,
        5: localPackLandingImage5RuSrc,
        6: localPackLandingImage6RuSrc,
    };

    const enImages = {
        1: localPackLandingImage1EnSrc,
        2: localPackLandingImage2EnSrc,
        3: localPackLandingImage3EnSrc,
        4: localPackLandingImage4EnSrc,
        5: localPackLandingImage5EnSrc,
        6: localPackLandingImage6EnSrc,
    };

    return [ShortLocaleNameEnum.ru, ShortLocaleNameEnum.kk].includes(shortLocaleName)
        ? ruImages[cardIndex]
        : enImages[cardIndex];
}
