import {AddButton} from '../../../../../../../layout/add-button/add-button';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyKeyEnum, ProtocolEnum} from '../../../../../../../service/company-v2/company-const';
import {useRegularSocialsApi} from '../../../../../../../service/company-v2/company-hook';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../../typings/antd';
import {ErrorList} from '../../../error-list/error-list';

import {LinksFormItemButtons} from './links-form-item-buttons';
import {getRequiredLinkRule} from './links-form-item-helper';
import {UrlInput} from './url-input/url-input';
import * as styles from './links-form-item.scss';

type PropsType = {
    name:
        | CompanyKeyEnum.Websites
        | CompanyKeyEnum.SocialNetworks
        | [CompanyKeyEnum.ExtraWebsites | CompanyKeyEnum.ExtraSocialNetworks, number, 'websites' | 'socialNetworks'];
    required?: boolean;
    httpsOnly?: boolean;
    hasInitialLinks: boolean;
    label: string;
    placeholder: string;
};

export function LinksFormItem(props: PropsType): JSX.Element {
    const {name, required, httpsOnly, hasInitialLinks, label, placeholder} = props;
    const {showFormStatus} = useFormRules();
    const {data} = useRegularSocialsApi();
    // eslint-disable-next-line no-undefined
    const initialValue = hasInitialLinks ? undefined : [{value: '', protocol: ProtocolEnum.HTTPS, isMain: true}];

    const {getLocalizedString} = useLocale();

    return (
        <Form.List initialValue={initialValue} name={Array.isArray(name) ? name.slice(1) : name}>
            {(links, {add, remove}) => (
                <>
                    <div className={styles.links}>
                        {links.map(({key, name: fieldName, fieldKey}, index) => {
                            return (
                                <div className={styles.link} key={key}>
                                    <Form.Item<CompanyFormType>
                                        fieldKey={fieldKey}
                                        label={index === 0 ? label : null}
                                        name={fieldName}
                                        required={required}
                                        rules={
                                            required
                                                ? [
                                                      getRequiredLinkRule(getLocalizedString),
                                                      showFormStatus(
                                                          data,
                                                          name === CompanyKeyEnum.Websites ||
                                                              name[0] === CompanyKeyEnum.ExtraWebsites
                                                      ),
                                                  ]
                                                : [
                                                      showFormStatus(
                                                          data,
                                                          name === CompanyKeyEnum.Websites ||
                                                              name[0] === CompanyKeyEnum.ExtraWebsites
                                                      ),
                                                  ]
                                        }
                                    >
                                        <UrlInput httpsOnly={httpsOnly} index={index} placeholder={placeholder} />
                                    </Form.Item>

                                    <LinksFormItemButtons
                                        hideAdditionalInfo={httpsOnly}
                                        index={index}
                                        onRemove={() => remove(fieldName)}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <ErrorList name={name} />

                    <AddButton
                        className={styles.links__add}
                        onClick={() => add({value: '', protocol: ProtocolEnum.HTTPS, isMain: false})}
                        size="small"
                    >
                        <Locale stringKey="BUTTON__ADD" />
                    </AddButton>
                </>
            )}
        </Form.List>
    );
}
