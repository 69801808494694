import {appRoute} from '../../../app-route';
import {ActivationPage} from '../../../layout/activation-page/activation-page';
import {useDocumentationLinks} from '../../../provider/help-links/help-links-hook';
import {Locale} from '../../../provider/locale/locale';
import {FeaturesEnum} from '../../../service/user/user-type';

export function AutoRepliesActivation(): JSX.Element {
    const {featureActivation} = useDocumentationLinks();

    return (
        <ActivationPage
            breadCrumbList={[
                {
                    path: appRoute.reviewsManagementAutoReplies.path,
                    titleLangKey: 'CATEGORY_NAME__AUTO_REPLIES',
                },
            ]}
            link={featureActivation[FeaturesEnum.reviewSuggests]}
            title={<Locale stringKey="AUTO_REPLIES__PAGE_HEADER" />}
        />
    );
}
