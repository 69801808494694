import {Locale} from '../../../../../../provider/locale/localization';
import {ManageSourceLinkError} from '../../../../../../service/source-settings/source-links/source-links-type';
import {isErrorHasStatusMessage} from '../../../../../../util/error';
import {isRecord} from '../../../../../../util/object';

function isErrorHasLinkMessage(error: unknown): error is ManageSourceLinkError {
    return (
        isRecord(error) &&
        isRecord(error.jsonData) &&
        Boolean(error.jsonData.link) &&
        Array.isArray(error.jsonData.link) &&
        error.jsonData.link.length > 0
    );
}

export function getErrorDescriptionText(error: unknown): JSX.Element {
    if (isErrorHasStatusMessage<ManageSourceLinkError>(error)) {
        return <span>{error.jsonData.status}</span>;
    }

    if (isErrorHasLinkMessage(error)) {
        return <span>{error.jsonData.link && error.jsonData.link[0]}</span>;
    }

    return <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />;
}
