import {Select, SelectProps} from 'antd';

import {useBrands} from '../../provider/brands/brand-hook';
import {useLocale} from '../../provider/locale/locale-hook';
import {IdNameType} from '../../service/api/api-type';

type PropsType = {
    onChange?: (newBrandId: number) => void;
    value?: number | null;
} & Omit<SelectProps<number>, 'onPopupScroll' | 'dropdownRender'>;

export function InfiniteBrandSelector(props: PropsType): JSX.Element {
    const {value, onChange, ...selectProps} = props;
    const {brands, isLoading} = useBrands();
    const {getLocalizedString} = useLocale();

    return (
        <Select<number>
            loading={isLoading}
            onChange={onChange}
            options={brands.map((brandItem: IdNameType) => {
                return {
                    label: brandItem.name,
                    value: brandItem.id,
                };
            })}
            placeholder={getLocalizedString('REVIEW_GENERATOR__QUESTIONNAIRE__BRAND__PLACEHOLDER')}
            value={value}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...selectProps}
        />
    );
}
