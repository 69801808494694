import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../../util/url';
import {PaginatedResponseType, RequestOptionsType} from '../../api/api-type';

import {
    ReviewsAnalysisTopicsQueryType,
    reviewTopicSearchSchema,
    reviewTopicsResponseSchema,
    ReviewTopicType,
    ReviewUserTopicType,
    reviewUserTopicTypeSchema,
} from './review-topics-type';

export async function fetchReviewTopicList(
    options: ReviewsAnalysisTopicsQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<PaginatedResponseType<ReviewTopicType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisTopicsQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/topics/?${searchParameters}`;

    return fetchAndDeserialize<PaginatedResponseType<ReviewTopicType>>(url, reviewTopicsResponseSchema);
}

export async function fetchReviewUserTopic(id: number): Promise<ReviewUserTopicType> {
    const url = `/v2/reviews-analytics/stats/user-topics/${id}/`;

    return fetchAndDeserialize<ReviewUserTopicType>(url, reviewUserTopicTypeSchema);
}

export async function fetchReviewTopicSearch(
    options: RequestOptionsType,
    mainFilterKey: string
): Promise<Array<string>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<RequestOptionsType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/topics/search/?${searchParameters}`;

    return fetchAndDeserialize<Array<string>>(url, reviewTopicSearchSchema);
}
