import {Badge, Button, Card} from 'antd';
import {useState} from 'react';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../layout/spinner/spinner';
import {LocalePlural} from '../../../../provider/locale/locale-plural';
import {Locale} from '../../../../provider/locale/localization';
import {useDashboardRecommendations} from '../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-hook';
import {DashboardRecommendationItemType} from '../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-type';
import {classNames} from '../../../../util/css';

import {DashboardRecommendationsCard} from './dashboard-recommendation-card/dashboard-recommendations-card';
import * as styles from './dashboard-recommendations.scss';

type PropsType = {
    companiesCount?: number;
};

export function DashboardRecommendations(props: PropsType): JSX.Element {
    const {companiesCount} = props;
    const {result, processError, isInProgress, onPostponeChange} = useDashboardRecommendations();
    const [isCollapsed, setIsCollapsed] = useState(true);

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    const activeRecommendationsCount = result.filter((item) => item.count && !item.postponed).length;

    return (
        <div className={styles.cards__wrapper}>
            <Card size="small">
                <div className={styles.header__wrapper}>
                    <span className={styles.header__title}>
                        <LocalePlural
                            count={companiesCount ?? 1}
                            fewKey="DASHBOARD_PAGE__RECOMMENDATION__TITLE__FEW"
                            manyKey="DASHBOARD_PAGE__RECOMMENDATION__TITLE__MANY"
                            singularKey="DASHBOARD_PAGE__RECOMMENDATION__TITLE__SINGULAR"
                        />
                    </span>
                    <Badge
                        className={classNames(styles.header__counter, {
                            [styles.header__counter__disabled]: !activeRecommendationsCount,
                        })}
                        count={activeRecommendationsCount}
                        showZero
                    />
                </div>
            </Card>
            {result
                .filter((item: DashboardRecommendationItemType) => {
                    if (isCollapsed) {
                        return item.count && !item.postponed;
                    }

                    return true;
                })
                .sort((item1: DashboardRecommendationItemType, item2: DashboardRecommendationItemType) => {
                    return (item1.postponed || 0) - (item2.postponed || 0);
                })
                .map(({key, postponed, count}: DashboardRecommendationItemType) => {
                    return (
                        <DashboardRecommendationsCard
                            count={count}
                            key={key}
                            onPostponeChange={onPostponeChange}
                            postponedDate={postponed ? new Date(postponed * 1000) : null}
                            recommendationKey={key}
                        />
                    );
                })}
            {result.length > activeRecommendationsCount && (
                <Button
                    className={styles.cards__collapse_button}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    type="text"
                >
                    {isCollapsed ? (
                        <Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__SHOW_POSTPONED" />
                    ) : (
                        <Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__HIDE_POSTPONED" />
                    )}
                </Button>
            )}
        </div>
    );
}
