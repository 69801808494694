import {useMemo} from 'react';

import {useUrl} from '../../../util/url-hook/url-hook';

import {getKnowledgeFilterFromUrlQuery} from './knowledge-page-helper';
import {KnowledgeFilterEnum, KnowledgeFilterType, UseKnowledgeFilterType} from './knowledge-page-type';

export function useKnowledgeFilter(): UseKnowledgeFilterType {
    const {setQuery, queries} = useUrl<KnowledgeFilterType>();

    const filter: KnowledgeFilterType = useMemo<KnowledgeFilterType>(
        () => getKnowledgeFilterFromUrlQuery(queries),
        [queries]
    );

    const isFilterEmpty: boolean = useMemo<boolean>(
        (): boolean =>
            Object.values<KnowledgeFilterEnum>(KnowledgeFilterEnum)
                .map((fieldName: KnowledgeFilterEnum): string | void => queries[fieldName])
                .every((queryValue: string | void) => !queryValue),
        [queries]
    );

    return {filter, setFilter: setQuery, isFilterEmpty};
}
