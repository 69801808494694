import {Tag} from 'antd';
import {PropsWithChildren, ReactNode} from 'react';

import {PageHeader} from '../../../layout/page-header/page-header';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {classNames} from '../../../util/css';
import {PageHeaderTitle} from '../../page-header-title/page-header-title';
import {Text} from '../../text/text';
import {UsetifulNameProductEnum} from '../../usetiful/usetiful-const';

import * as styles from './header-with-license-tag.scss';

type PropsType = {
    className?: string;
    licenseActive?: boolean;
    trialLicense?: boolean;
    headerValueMap?: Record<string, ReactNode>;
    productName?: UsetifulNameProductEnum;
} & ({headerLangKey?: never; headerComponent: ReactNode} | {headerLangKey: LangKeyType; headerComponent?: never});

export function PageHeaderWithLicenseTag(props: PropsWithChildren<PropsType>): JSX.Element {
    const {
        className,
        licenseActive,
        trialLicense,
        headerLangKey,
        children,
        headerComponent,
        headerValueMap,
        productName,
    } = props;

    return (
        <PageHeader className={className}>
            <div className={styles.HeaderWithLicenseTag_titleContainer}>
                {headerComponent ? <div>{headerComponent}</div> : null}
                {headerLangKey ? (
                    <PageHeaderTitle
                        isShowProductButton={!trialLicense && licenseActive}
                        productName={productName}
                        ratingButtonClassName={styles.HeaderWithLicenseTag_usetifulButton}
                        title={headerLangKey}
                        titleValueMap={headerValueMap}
                    />
                ) : null}

                <div className={styles.HeaderWithLicenseTag_tagContainer}>
                    {licenseActive && trialLicense && (
                        <Tag
                            bordered={false}
                            className={classNames(
                                styles.HeaderWithLicenseTag_headerTag,
                                styles.HeaderWithLicenseTag_headerTag__trialPeriod
                            )}
                        >
                            <Text inheritColor lighter stringKey="TEXT__TRIAL_PERIOD" />
                        </Tag>
                    )}

                    {!licenseActive && (
                        <Tag bordered={false} className={styles.HeaderWithLicenseTag_headerTag}>
                            <Text lighter stringKey="TEXT__SERVICE_NOT_CONNECTED" />
                        </Tag>
                    )}
                </div>
            </div>

            {children}
        </PageHeader>
    );
}
