import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../../component/text/text';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {usePosts} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-hook';
import {PostsEmpty} from '../../../dashboard/posts/empty/posts-empty';
import * as companyActivityAndSummaryStyles from '../../company-activity-and-summary.scss';

import * as styles from './posts.scss';

export function Posts(): JSX.Element {
    const {companyId} = useParams<ExtractRouteParams<typeof appRoute.companyActivityAndSummary.path, string>>();

    const {result: posts, processError} = usePosts(Number(companyId));

    if (processError) {
        return <AlertFallback />;
    }

    if (posts) {
        return (
            <div>
                {posts.published > 0 ? (
                    <div className={styles.CompanyActivityPosts_statistics}>
                        <div>
                            <Text block bolder stringKey="DASHBOARD_PAGE__POSTS__SENT_PUBLICATIONS" />
                            <Text block lighter small stringKey="COMPANY_ACTIVITY_AND_SUMMARY__POSTS__LAST_PERIOD" />
                        </div>
                        <Text block bolder className={styles.CompanyActivityPosts_statistics__count}>
                            {posts.published}
                        </Text>
                    </div>
                ) : (
                    <PostsEmpty />
                )}
            </div>
        );
    }

    return (
        <div className={companyActivityAndSummaryStyles.CompanyActivityAndSummary_chart}>
            <Spinner />
        </div>
    );
}
