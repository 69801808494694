import {PropsWithChildren} from 'react';

import * as styles from './mobile-overlay.scss';

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function MobileOverlay(props: PropsType): JSX.Element {
    const {children} = props;

    return (
        <div className={styles.mobile_overlay}>
            <div className={styles.mobile_overlay_content}>{children}</div>
        </div>
    );
}
