export const enOnlinePresenceTranslations = {
    ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__PREVIOUS_PERIOD: 'Previous period',
    ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__PREVIOUS_YEAR: 'Previous year',
    ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__CUSTOM: 'Custom',
    ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__RESET: 'Reset comparation',
    ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__COMPARE: 'Compare',

    ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__MAIN: 'Period A',
    ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__MAIN_NO_COMPARE: 'Number of impressions',
    ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__COMPARE: 'Period B',

    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__PROVIDER: 'Analytics by {catalogName}',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__SEARCH_PHRASES: 'Search phrases',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__SYNC_STATISTIC: 'Synchronization statistics',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__LOCATIONS: 'Analytics by location',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__MODAL__TITLE: 'Export analytics',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__MODAL__OK_BUTTON: 'Export',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__SUCCESS__TITLE: 'Application accepted',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__SUCCESS__MESSAGE:
        'The requested report will be sent to you by email specified in your profile.',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__ALERT__MESSAGE:
        'When downloading analytics, the selected filters will be applied',

    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_PDF: 'Download pdf',
    ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_XLSX: 'Download xlsx',
    ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__DASHBOARD_INFO_TEXT: 'Available period',
    ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__DASHBOARD_INFO_TITLE:
        'Online presence data in catalogs becomes available after 5 days.',

    ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__SEARCH_PHRASES_INFO_TEXT: 'Available period',
    ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__SEARCH_PHRASES_INFO_TITLE:
        'Search phrases data becomes available at the end of a full calendar month.',

    ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS: 'Overview',

    ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__IMPRESSIONS: 'Impressions',
    ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__TARGET_ACTIONS: 'Interactions',
    ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__CONVERSION: 'Conversion rate',
    ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__ACTION: 'Action type',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION: 'Source of transition',
    ONLINE_PRESENCE_ANALYTICS__MARKER: 'Metric',
    ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__COUNT: 'Count',

    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__OTHER: 'Others',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_SEARCH: 'Search • Desktop devices',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_SEARCH: 'Search • Mobile devices',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_MAP: 'Maps • Desktop devices',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_MAP: 'Maps • Mobile devices',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DISCOVERY: 'Discovery',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__RECOVERY: 'Recovery',

    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_SEARCH__SHORT: 'Search • Desktop',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_SEARCH__SHORT: 'Search • Mobile',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_MAP__SHORT: 'Maps • Desktop',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_MAP__SHORT: 'Maps • Mobile',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__TITLE: 'Sources of conversions to {catalogName} cards',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__TITLE: 'Dynamics and distribution of target actions',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__EMPTY:
        'Insufficient data on the dynamics and distribution of target actions',
    ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__EMPTY:
        'Insufficient data on the sources of transitions to the {catalogName} cards',

    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__CALLS: 'Calls',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__SITE: 'Website',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__ROUTES: 'Routes',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__OTHER: 'Others',

    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__CALLS: 'Calls from cards',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__SITE: 'Website clicks',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__ROUTES: 'Routes built',
    ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__OTHER: 'Others, total',

    ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__TITLE: 'Impressions',
    ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS: 'Card Impressions',
    ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS__COMPARE_B: 'Impressions period B',
    ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__EMPTY: 'Insufficient impression data',

    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__TITLE: 'Search phrases impressions',

    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__TOTAL_RESULTS: 'Number of search terms: {count}',
    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__PHRASE: 'Phrase',
    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__TREND: 'Dynamics for the year',
    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__DELTA: 'Delta',
    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__POPOVER_TITLE: 'Dynamics for the year',
    ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__POPOVER_TEXT:
        'Dynamics of impressions of the search phrase for the last year by months. Does not depend on the selected filters.',

    ONLINE_PRESENCE_ANALYTICS__DATA_COMPLETENESS: 'Complete profiles',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__AVERAGE_SCORE: 'Average sync percentage across all services',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__CATEGORIES: 'More than 3 categories',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__PHONE_NUMBERS: 'More than 2 phone numbers',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__PAYMENT_METHODS: 'Payment Methods',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__LOGO: 'Logos',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__COVER: 'Cover',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__DESCRIPTION: 'Description',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__SOCIAL_LINKS: 'Social Links',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__GALLERY: 'Gallery',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__PRICES: 'Prices',
    ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__POSTS: 'Publications',

    ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS: 'Online presence',
    ONLINE_PRESENCE_ANALYTICS__TITLE: 'Online analytics - all catalogs',
    ONLINE_PRESENCE_ANALYTICS__CATALOG_TITLE: 'Online presence in {catalogName}',
    ONLINE_PRESENCE_ANALYTICS__DATE_POPOVER_TITLE: 'Available period',
    ONLINE_PRESENCE_ANALYTICS__DATE_POPOVER_TEXT: 'Synchronization statistics becomes available the next day.',

    ONLINE_PRESENCE_ANALYTICS__LOCATIONS__PAGE_TITLE: 'Analytics of online presence by location',
    ONLINE_PRESENCE_ANALYTICS__LOCATIONS__TABLE__NAME: 'Name',
    ONLINE_PRESENCE_ANALYTICS__LOCATIONS__TABLE__ADDRESS: 'Address',

    ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_1__TITLE: 'Advanced online presence analytics service',
    ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_1__TEXT:
        "Connect advanced analytics {companyName} for a comprehensive assessment of your company's online presence",
    ONLINE_PRESENCE_ANALYTICS__LANDING__CALL_TO_ACTION_1__DESCRIPTION:
        'Sign up for a free trial period and use all the benefits of advanced online presence analytics for free for {duration} days',

    ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_2__TITLE: 'What is online presence analytics from {companyName}',
    ONLINE_PRESENCE_ANALYTICS__LANDING__CARD_2__TEXT:
        'This is a service that allows you to evaluate the status of your cards in geoservices and the dynamics of the main local metrics: card impressions, built routes, calls and other targeted actions from cards.',

    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP1__TITLE: 'Welcome to online presence analytics! 👋',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP1__DESCRIPTION:
        'In the analytics you will see the level of overall synchronization and completeness of card data for each site. This data will allow you to evaluate the condition of cards at different sites and understand what can be improved.',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP2__TITLE: 'Track the dynamics of impressions! 📊',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP2__DESCRIPTION:
        'You will be able to track the dynamics of brand and category impressions of your cards broken down by platform: Google and another',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP3__TITLE: 'Study the dynamics of target actions in cards 🔎',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP3__DESCRIPTION:
        'Receive data on targeted actions (routes, calls, website visits, etc.) performed by users in your Google cards and another services',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP4__TITLE: 'Everything is ready to go!',
    ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP4__DESCRIPTION:
        'Go to the main page of advanced online presence analytics to assess the status of your cards in geoservices and the dynamics of key local metrics.',
    ONLINE_PRESENCE__SYNCHRONIZED_NAVIGATORS: 'Synchronized navigators',

    ONLINE_PRESENCE__SYNC__AVERAGE_RATING__CARD_NAME: '(average synchronization rating {percentValue})',
    ONLINE_PRESENCE__SYNC__FINAL_RATING__CARD_NAME: 'Final optimization rating',

    ONLINE_PRESENCE__CATALOG__GOOGLE: 'Google',
    ONLINE_PRESENCE__CATALOG__YANDEX: 'Yandex',
    ONLINE_PRESENCE__CATALOG__DOUBLE_GIS: '2GIS',

    ONLINE_PRESENCE__FILTER: 'Filter options',
    ONLINE_PRESENCE__START_DATE: 'Start',
    ONLINE_PRESENCE__END_DATE: 'Finish',

    ONLINE_PRESENCE_ANALYTICS__PROBLEMS_WGIS:
        'Attention! Technical work is underway to update data collection from {wGis}. The displayed data may contain errors. Correct data will be available after March 28.',
};
