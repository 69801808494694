import {FeedFormKeyEnum, FeedFormType} from './feed-form-type';

export const initialValues: Omit<FeedFormType, FeedFormKeyEnum.Format | FeedFormKeyEnum.AllowedBrands> = {
    sourceUrl: '',
};

export enum FileExtensionEnum {
    Csv = 'csv',
    Xml = 'xml',
    Json = 'json',
    Xlsx = 'xlsx',
}

export enum MimeTypeEnum {
    ApplicationJson = 'application/json',
    TextCsv = 'text/csv',
    TextXml = 'text/xml',
    ApplicationXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
