import {useCallback, useEffect, useState} from 'react';

import {LandingCongratulationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-congratulation-step';
import {LandingOnboardingPageCard} from '../../../../../component/landing/landing-onboarding-steps/landing-onboarding-page-card';
import {LandingPresentationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-presentation-step';
import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';
import {CompaniesSelectorFeatureEnum} from '../../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../../service/feature-companies/feature-companies-hook';

import presentationStep1EnSrc from './images/local-pack-activation-presentation-step1-en.png';
import presentationStep1RuSrc from './images/local-pack-activation-presentation-step1-ru.png';
import presentationStep2EnSrc from './images/local-pack-activation-presentation-step2-en.png';
import presentationStep2RuSrc from './images/local-pack-activation-presentation-step2-ru.png';
import {LocalPackActivationCompaniesStep} from './steps/companies/local-pack-activation-companies-step';
import {LAST_LOCAL_PACK_ACTIVATION_FORM_STEP_INDEX} from './steps/local-pack-activation-steps-helper';
import {LocalPackActivationRuleStep} from './steps/rules/local-pack-activation-rule-step';
import * as styles from './local-pack-activation-steps.scss';

type PropsType = {
    onLicenseActivated: VoidFunction;
};

export function LocalPackActivationSteps(props: PropsType): JSX.Element {
    const {onLicenseActivated} = props;

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const companiesSelector = useCompaniesSelector({
        feature: CompaniesSelectorFeatureEnum.LocalPack,
        resourceId: null,
    });

    const {initializeSelector} = companiesSelector;

    const onStepFinished = useCallback(() => {
        setCurrentStepIndex((previousActiveKeyIndex) => previousActiveKeyIndex + 1);
    }, []);

    useEffect(() => {
        initializeSelector();
    }, [initializeSelector]);

    const steps = [
        <LocalPackActivationCompaniesStep
            companiesSelector={companiesSelector}
            key="step1"
            onStepFinish={onStepFinished}
        />,
        <LocalPackActivationRuleStep
            companiesSelector={companiesSelector}
            key="step2"
            onStepFinish={() => {
                onLicenseActivated();
                onStepFinished();
            }}
        />,
        <LandingPresentationStep
            descriptionLangKeyType="LOCAL_PACK__ACTIVATION__STEP_3__TEXT"
            images={{
                [ShortLocaleNameEnum.ru]: presentationStep1RuSrc,
                [ShortLocaleNameEnum.en]: presentationStep1EnSrc,
            }}
            key="step3"
            onStepFinish={onStepFinished}
            titleLangKeyType="LOCAL_PACK__ACTIVATION__STEP_3__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="LOCAL_PACK__ACTIVATION__STEP_4__TEXT"
            imageClassName={styles.LocalPackActivationSteps_image__withLeftMargin}
            images={{
                [ShortLocaleNameEnum.ru]: presentationStep2RuSrc,
                [ShortLocaleNameEnum.en]: presentationStep2EnSrc,
            }}
            key="step4"
            onStepFinish={onStepFinished}
            titleLangKeyType="LOCAL_PACK__ACTIVATION__STEP_4__TITLE"
        />,
        <LandingCongratulationStep
            descriptionLangKeyType="LOCAL_PACK__ACTIVATION__STEP_5__TEXT"
            key="step5"
            titleLangKeyType="LOCAL_PACK__ACTIVATION__STEP_5__TITLE"
        />,
    ];

    return (
        <LandingOnboardingPageCard
            current={currentStepIndex}
            onChange={setCurrentStepIndex}
            stepDisabled={(stepIndex: number) => {
                return (
                    currentStepIndex < stepIndex ||
                    (currentStepIndex > LAST_LOCAL_PACK_ACTIVATION_FORM_STEP_INDEX &&
                        stepIndex <= LAST_LOCAL_PACK_ACTIVATION_FORM_STEP_INDEX)
                );
            }}
            steps={steps}
        />
    );
}
