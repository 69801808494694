import {useEffect, useMemo} from 'react';

import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {PaginationOptionsType, PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {PaginatedResponseType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchReviewEmployees} from './review-employees-api';
import {ReviewAnalyticsEmployeeType, ReviewsAnalysisEmployeesQueryType} from './review-employees-type';

export function useReviewEmployees(
    options: ReviewsAnalysisEmployeesQueryType,
    mainFilterKey: string,
    paginationOption?: PaginationOptionsType
): UseHookType<PaginatedResponseType<ReviewAnalyticsEmployeeType>> & PaginationType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<ReviewAnalyticsEmployeeType>>();

    const {
        brandIds: compareBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
    } = options;

    const paginationDependencies = useMemo(() => {
        return {
            compareBrandIds,
            startDate,
            endDate,
            sentiments,
            sources,
            tags,
            phrases,
            topics,
            rating,
            withAnswers,
            withText,
            mainFilterKey,
        };
    }, [
        compareBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
        mainFilterKey,
    ]);

    const pagination = usePagination({
        dependencies: paginationDependencies,
        ...paginationOption,
    });

    const {page, pageSize, onDataLoadFailed, onDataLoaded, refreshId} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewEmployees(
            {
                page,
                pageSize,
                brandIds: compareBrandIds,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, setIsInProgress, setProcessError, setResult, startDate, onDataLoaded, onDataLoadFailed]);

    return {isInProgress, processError, result, reset, pagination};
}
