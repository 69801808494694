import {MultiSourceDataType} from '../multisource-chart/multi-source-chart-type';

export function getDefaultChartLegendEnabledState(data: MultiSourceDataType): Record<string, boolean> {
    return data.datasets.reduce((accumulator, dataset) => {
        return {
            ...accumulator,
            [dataset.label]: true,
        };
    }, {} as Record<string, boolean>);
}
