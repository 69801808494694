import {faBadgeDollar, faCirclePlay, faStopwatch, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tag, theme} from 'antd';
import {LabeledValue} from 'antd/lib/select';
import dayjs from 'dayjs';
import {CustomTagProps} from 'rc-select/lib/BaseSelect';
import {Dispatch, ReactElement, SetStateAction, useCallback} from 'react';

import {SelectInfiniteScroll} from '../../../../../component/select-infinite-scroll/select-infinite-scroll';
import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/localization';
import {TariffType} from '../../../../../service/tariffs/tariffs-user-tariff';
import {TariffFromListType} from '../../../../../service/tariffs/tariffs-user-tariffs';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {ExpiredColumnQueryNameEnum} from '../../../my-companies/companies-table/columns/expired-column-type';

import * as styles from './tariffs-select.scss';

type PropsType = {
    tariffs: Array<TariffFromListType>;
    tariff: TariffType;
    setTariffId: Dispatch<SetStateAction<number | null>>;
    isFetching: boolean;
    fetchNextPage: () => void;
};

const today = dayjs();

export function TariffsSelect(props: PropsType): ReactElement | null {
    const {tariffs, tariff, setTariffId, fetchNextPage, isFetching} = props;
    const {token} = theme.useToken();
    const {setQuery} = useUrl();
    const {getFormattedDateTime} = useFormat();

    function handleChangeSelect(value: number) {
        setTariffId(value);
        setQuery({[ExpiredColumnQueryNameEnum.tariffId]: value});
    }

    const options: Array<LabeledValue> = tariffs.map((tariffInfo) => ({
        value: tariffInfo.id,
        label: (
            <div className={styles.TariffsSelect_selectOption}>
                <div className={styles.TariffsSelect_iconWrapper}>
                    <FontAwesomeIcon className={styles.TariffsSelect_selectIcon} icon={faBadgeDollar} />
                </div>
                <div className={styles.TariffsSelect_selectDescriptionWrapper}>
                    <span className={styles.TariffsSelect_nameWrapper}>
                        <Text bolder>
                            <span className={styles.TariffsSelect_labelText}>{tariffInfo.name}</span>
                        </Text>
                        {dayjs(tariffInfo.finishAt).isSameOrBefore(today, 'day') && (
                            <FontAwesomeIcon
                                className={styles.TariffsSelect_label__expired}
                                icon={faTriangleExclamation}
                            />
                        )}
                        {tariffInfo.isTest && (
                            <FontAwesomeIcon className={styles.TariffsSelect_label__test} icon={faStopwatch} />
                        )}
                        {tariffInfo.isDemo && (
                            <FontAwesomeIcon className={styles.TariffsSelect_label__demo} icon={faCirclePlay} />
                        )}
                    </span>
                    <Text bolder secondary small>
                        <Locale
                            stringKey="FEED__MANAGEMENT__DRAWER__TABLE__BRANDS_COUNT"
                            valueMap={{count: tariffInfo.brandCount}}
                        />{' '}
                        •
                        <Locale
                            stringKey="COMPANY__MULTISELECT__POPUP__COMPANIES_COUNT"
                            valueMap={{count: tariffInfo.companyCount}}
                        />{' '}
                        •{getFormattedDateTime(new Date(tariffInfo.finishAt))}
                    </Text>
                </div>
            </div>
        ),
        children: (
            <div className={styles.TariffsSelect_label}>
                <FontAwesomeIcon color={token.colorInfoHover} icon={faBadgeDollar} />
                <span className={styles.TariffsSelect_labelText}>{tariffInfo?.name}</span>
                {tariffInfo?.isTest && (
                    <Text bolder className={styles.TariffsSelect_label__test} small stringKey="TARIFFS__TEST_TARIFF" />
                )}
                {tariffInfo?.isDemo && (
                    <Text bolder className={styles.TariffsSelect_label__demo} small stringKey="TARIFFS__DEMO_TARIFF" />
                )}
            </div>
        ),
    }));

    const renderTag = useCallback((tagProps: CustomTagProps): ReactElement => {
        const {label} = tagProps;

        return (
            <Tag className={styles.TariffsSelect_label} closable={false}>
                {label}
            </Tag>
        );
    }, []);

    if (!tariff || tariffs.length === 0) {
        return null;
    }

    return (
        <SelectInfiniteScroll
            className={styles.TariffsSelect}
            defaultActiveFirstOption={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            loading={isFetching}
            mode="multiple"
            onLoadMore={() => fetchNextPage()}
            onSelect={handleChangeSelect}
            optionLabelProp="children"
            options={options}
            popupClassName={styles.TariffsSelect_selectPopup}
            showSearch={false}
            tagRender={renderTag}
            value={tariff.id}
            virtual={false}
        />
    );
}
