import {faClose, faFileChartColumn, faRotate} from '@fortawesome/pro-regular-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Progress, Space, Spin, Tag, Tooltip} from 'antd';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {
    CompaniesReportType,
    useCancelCreateReportMutation,
    useMarkAsOpenReport,
    useRegenerateReport,
} from '../../../../service/company/company-reports';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';

import {CompaniesReportStepEnum, LOCALSTORAGE_REPORT_UUID_KEY, reportSteps} from './companies-report-const';
import {CompaniesReportDownloadButton} from './download-button/download-button';
import * as styles from './companies-report.scss';

type PropsType = {
    report: CompaniesReportType;
    setShowReport: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line complexity
export function CompaniesReport(props: PropsType): ReactElement {
    const {report, setShowReport} = props;

    const currentReport = report[0];
    const reportStatus = currentReport?.status.status;
    const {mutate} = useCancelCreateReportMutation(reportStatus);
    const {mutate: regenerate} = useRegenerateReport();
    const {mutate: markAsOpened} = useMarkAsOpenReport();
    const {getFormattedDateTime} = useFormat();
    const {destroy} = useSnackbar();

    const isReady = reportStatus === CompaniesReportStepEnum.Done;
    const isFailed = reportStatus === CompaniesReportStepEnum.Failed;
    const uuid = currentReport?.uuid || '';
    const reportDate = new Date(currentReport ? currentReport.createdAt : '');
    const percent = currentReport ? (currentReport?.status?.processed / currentReport?.status?.all) * 90 : 0;

    function getPercent() {
        if (reportStatus === CompaniesReportStepEnum.Pending) {
            return 0;
        }

        return 10 + percent;
    }

    function closeReportHandler() {
        if (currentReport) {
            setShowReport(false);
            // todo remove this after realize right function on the backend
            localStorage.setItem(LOCALSTORAGE_REPORT_UUID_KEY, currentReport.uuid);
        }
    }

    const tagClassNames = classNames(styles.CompaniesReport_tag__inProgress, {
        [styles.CompaniesReport_tag__ready]: isReady,
        [styles.CompaniesReport_tag__failed]: isFailed,
    });

    const tagText = isReady
        ? 'MY_COMPANIES__REPORT__PROGRESS_BLOCK__TAG__READY'
        : isFailed
        ? 'MY_COMPANIES__REPORT__PROGRESS_BLOCK__TAG__ERROR'
        : 'MY_COMPANIES__REPORT__PROGRESS_BLOCK__TAG__IN_PROGRESS';

    return (
        <PageCard id="companiesReport">
            <Space className={styles.CompaniesReport} direction="vertical">
                <Space className={styles.CompaniesReport_header}>
                    {(isFailed || (currentReport && currentReport.isOpened)) && (
                        <Button
                            className={styles.CompaniesReport_closeButton}
                            icon={<FontAwesomeIcon icon={faClose} />}
                            onClick={closeReportHandler}
                            type="text"
                        />
                    )}
                    <Text bolder large stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__TITLE" />
                    <Tag className={tagClassNames}>
                        <Locale stringKey={tagText} />
                    </Tag>
                </Space>
                {isFailed && (
                    <Alert
                        action={
                            <Button icon={<FontAwesomeIcon icon={faRotate} />} onClick={() => regenerate(uuid)}>
                                <span>
                                    <Locale stringKey="MY_COMPANIES__REPORT__ERROR_BLOCK__BUTTON__TRY_AGAIN" />
                                </span>
                            </Button>
                        }
                        className={styles.CompaniesReport_failedAlert}
                        description={reportSteps[CompaniesReportStepEnum.Failed].description}
                        showIcon
                        type="error"
                    />
                )}
                {!isFailed && (
                    <div className={styles.CompaniesReport_progressWrapper}>
                        {!isReady && reportStatus && (
                            <>
                                <div className={styles.CompaniesReport_stepDescription}>
                                    <Text
                                        stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEPS"
                                        valueMap={{step: reportSteps[reportStatus].step}}
                                    />
                                    <Space align="center">
                                        <Text stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__DELAY__DESCRIPTION" />
                                        <Spin indicator={<FontAwesomeIcon icon={faSpinner} spin />} />
                                    </Space>
                                </div>
                                <Progress percent={getPercent()} showInfo={false} />
                                {reportStatus && (
                                    <div className={styles.CompaniesReport_stepDescription}>
                                        {reportSteps[reportStatus].description}
                                        <Button
                                            onClick={() => {
                                                mutate(uuid);
                                            }}
                                        >
                                            <Locale stringKey="BUTTON__CANCEL" />
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                        {isReady && (
                            <Space className={styles.CompaniesReport_readyReport} wrap>
                                <Space>
                                    <FontAwesomeIcon icon={faFileChartColumn} />
                                    <Text
                                        stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__DATE"
                                        valueMap={{date: getFormattedDateTime(reportDate)}}
                                    />
                                </Space>
                                <Space>
                                    {currentReport?.canRegenerate && (
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <Locale stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__TOOLTIP__REGENERATE" />
                                            }
                                        >
                                            <Button
                                                icon={<FontAwesomeIcon icon={faRotate} />}
                                                onClick={() => {
                                                    regenerate(uuid);
                                                    destroy();
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                    {currentReport && (
                                        <CompaniesReportDownloadButton
                                            createdAt={currentReport.createdAt}
                                            onClick={() => {
                                                markAsOpened(currentReport.uuid);
                                                destroy();
                                            }}
                                            uuid={currentReport.uuid}
                                        />
                                    )}
                                    {currentReport?.link && (
                                        <a href={currentReport.link} rel="noreferrer" target="_blank">
                                            <Button
                                                onClick={() => {
                                                    markAsOpened(currentReport.uuid);
                                                    destroy();
                                                }}
                                                type="primary"
                                            >
                                                <Locale stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__BUTTON__WATCH_REPORT" />
                                            </Button>
                                        </a>
                                    )}
                                </Space>
                            </Space>
                        )}
                    </div>
                )}
            </Space>
        </PageCard>
    );
}
