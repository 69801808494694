import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ButtonProps} from 'antd';

import {classNames} from '../../util/css';

import * as styles from './add-button.scss';

type PropsType = ButtonProps & {
    small?: boolean;
};

export function AddButton(props: PropsType): JSX.Element {
    const {className, children, small, ...restProps} = props;

    return (
        <Button
            className={classNames(styles.AddButton, className, {
                [styles.AddButton__small]: small,
            })}
            {...restProps} // eslint-disable-line react/jsx-props-no-spreading
        >
            <FontAwesomeIcon className={styles.AddButton_icon} icon={faPlus} />
            {children}
        </Button>
    );
}
