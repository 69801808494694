import dayjs from 'dayjs';
import * as XLSX from 'xlsx-js-style';

import {DEFAULT_DATE_FORMAT} from '../../util/date';

import {defaultXlsxFileName} from './request-report-base-const';

export function dateRangeToWorksheetName(options: {rawStartDate: string | null; rawEndDate: string | null}): string {
    const {rawStartDate, rawEndDate} = options;

    const startDate = rawStartDate ? new Date(rawStartDate) : null;
    const endDate = rawEndDate ? new Date(rawEndDate) : null;

    if (startDate && endDate) {
        return `${dayjs(startDate).format(DEFAULT_DATE_FORMAT)} – ${dayjs(endDate).format(DEFAULT_DATE_FORMAT)}`;
    }

    if (startDate) {
        return `${dayjs(startDate).format(DEFAULT_DATE_FORMAT)} – ${dayjs().format(DEFAULT_DATE_FORMAT)}`;
    }

    return dayjs().format(DEFAULT_DATE_FORMAT);
}

export function saveAsExcel(options: {data: Array<unknown>; fileName?: string; worksheetName?: string}): void {
    const {data, fileName, worksheetName} = options;

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName || 'Worksheet1');
    XLSX.writeFile(workbook, fileName ? `${fileName}.xlsx` : defaultXlsxFileName);
}
