import {faFilter} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableColumnType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {TupleType} from '../../../../util/type';

import {ResponseTemplatesTableRowDataType} from './response-templates-table-type';

export const responseTemplateColumnList: TupleType<TableColumnType<ResponseTemplatesTableRowDataType>, 4> = [
    {
        title: <Locale stringKey="RESPONSE_TEMPLATES__TEMPLATE" />,
        dataIndex: 'responseTemplateName',
    },
    {
        title: <Locale stringKey="RESPONSE_TEMPLATES__GROUP" />,
        dataIndex: 'responseTemplateGroup',
        filterIcon: <FontAwesomeIcon icon={faFilter} size="lg" />,
    },
    {
        title: <Locale stringKey="RESPONSE_TEMPLATES__TYPE" />,
        dataIndex: 'responseTemplateType',
        filterIcon: <FontAwesomeIcon icon={faFilter} size="lg" />,
        width: 136,
    },
    {
        title: <Locale stringKey="RESPONSE_TEMPLATES__GROUP__ACTIONS" />,
        dataIndex: 'templateActions',
        width: 104,
    },
];

export const withoutGroupItemId = '-1';

// see ResponseTemplateDataType.is_complex
// is_complex === true => command
// is_complex === false => private
export const responseTemplateTypePrivate = 'private';
export const responseTemplateTypeCommand = 'command';
