import {Tooltip} from 'antd';

import {FormattedDateTime} from '../../../provider/locale/formatted-date-time';
import {useLocale} from '../../../provider/locale/locale-hook';
import {YandexShortStoryType, YandexStoryStatusEnum} from '../../../service/yandex-stories/yandex-stories-type';
import {classNames} from '../../../util/css';
import {Text} from '../../text/text';
import {getYandexStoryStatusConfig} from '../stories-helper';
import {YandexStoryIllustration} from '../yandex-story-illustration/yandex-story-illustration';

import * as styles from './yandex-story-card.scss';

type PropsType = {
    data: YandexShortStoryType;
    onClick: VoidFunction;
    variant?: 'horizontal';
};

export function YandexStoryCard(props: PropsType): JSX.Element {
    const {data, onClick, variant} = props;
    const {createdAt, name, cover} = data;
    const isHorizontal = variant === 'horizontal';
    const {getLocalizedString} = useLocale();

    return (
        <Tooltip title={getLocalizedString(getYandexStoryStatusConfig(data.status).tooltipLangKey)}>
            <button
                className={classNames(styles.YandexStoryCard_wrapper, {
                    [styles.YandexStoryCard_wrapper__horizontal]: isHorizontal,
                })}
                onClick={onClick}
                type="button"
            >
                <YandexStoryIllustration
                    className={classNames(styles.YandexStoryCard, {
                        [styles.YandexStoryCard__onModeration]:
                            data.status === YandexStoryStatusEnum.Moderation ||
                            data.status === YandexStoryStatusEnum.InProgress ||
                            data.status === YandexStoryStatusEnum.Draft,
                        [styles.YandexStoryCard__rejected]:
                            data.status === YandexStoryStatusEnum.Rejected ||
                            data.status === YandexStoryStatusEnum.Deleted,
                        [styles.YandexStoryCard__horizontal]: isHorizontal,
                    })}
                    image={cover?.url || ''}
                />

                <div
                    className={classNames(styles.YandexStoryCard_storyInfo, {
                        [styles.YandexStoryCard_storyInfo__horizontal]: isHorizontal,
                    })}
                >
                    <Text block bolder ellipsis inheritColor>
                        {name}
                    </Text>
                    <Text block className={styles.YandexStoryCard_storyDate} secondary>
                        <FormattedDateTime value={new Date(createdAt)} />
                    </Text>
                </div>
            </button>
        </Tooltip>
    );
}
