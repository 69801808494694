import {Button, Space, Typography} from 'antd';
import {difference, groupBy, partition, sortBy, uniqBy} from 'lodash';
import {MutableRefObject, useEffect, useRef} from 'react';

import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {LocalePlural} from '../../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../../provider/locale/localization';
import {fetchRelatedCompanyCatalogCategoriesList} from '../../../../../../service/company-v2/company-api';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabIndex} from '../../catalog-list/catalog-list-helper';
import {CatalogTab} from '../../catalog-list/catalog-tab';

import {catalogCategoryLimits} from './categories-widget-const';
import {SelectCategoriesFormItemWithCatalog} from './select-categories-form-item/select-categories-form-item-with-catalog';
import * as styles from './categories-widget.scss';

type PropsType = {
    name: string;
    isPaidYandexPlacement: boolean;
};

export function CatalogCategories(props: PropsType): JSX.Element {
    const {name, isPaidYandexPlacement} = props;

    const form = Form.useFormInstance<CompanyFormType>();
    const rocketdataCategories = Form.useWatch(CompanyKeyEnum.RocketdataCategories, form);
    const catalogCategories = Form.useWatch(CompanyKeyEnum.CatalogCategories, {form, preserve: true});
    const mappedCatalogCategories = form.getFieldValue(CompanyKeyEnum.MappedCatalogCategories);

    const {getLocalizedString} = useLocale();
    const {getCatalogName} = useCatalogInfo();

    // can skip first load if we have mappedCatalogCategories from initial form data
    const skipLoadingRef: MutableRefObject<boolean> = useRef<boolean>(mappedCatalogCategories?.length > 0);

    useEffect(() => {
        async function load() {
            const autofillCategories = await Promise.all(
                rocketdataCategories.map((category) => fetchRelatedCompanyCatalogCategoriesList(category.categoryId))
            );

            const newMappedCatalogCategories = Object.entries(
                groupBy(autofillCategories.flat(), ({catalogId}) => catalogId)
            ).map(([catalogId, categories]) => ({
                catalogId: Number(catalogId),
                categories: uniqBy(
                    categories.map(({categoryName, categoryOriginId}, index) => ({
                        originId: categoryOriginId,
                        categoryName,
                        isMain: index === 0,
                    })),
                    'originId'
                ).slice(0, catalogCategoryLimits(isPaidYandexPlacement)[catalogId] ?? undefined), // eslint-disable-line no-undefined
            }));
            const newCatalogCategories = catalogCategories.map((category) => {
                const {catalogId, edited} = category;

                if (edited) {
                    return category;
                }

                return {
                    ...category,
                    categories:
                        newMappedCatalogCategories.find((mappedCatalog) => mappedCatalog.catalogId === catalogId)
                            ?.categories ?? [],
                };
            });

            form.setFieldsValue({
                mappedCatalogCategories: newMappedCatalogCategories,
                catalogCategories: newCatalogCategories,
            });
        }

        if (!skipLoadingRef.current && rocketdataCategories && catalogCategories) {
            load();
        }

        skipLoadingRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rocketdataCategories, catalogCategories]);

    function handleCountryAvailabilityChange(availableCatalogIds: Array<number>) {
        const [availableCatalogs, unavailableCatalogs] = partition(catalogCategories, ({catalogId}) =>
            availableCatalogIds.includes(catalogId)
        );

        const newCatalogIds = difference(
            availableCatalogIds,
            catalogCategories?.map(({catalogId}) => catalogId)
        );

        const newCatalogs =
            mappedCatalogCategories?.filter((catalog) => newCatalogIds.includes(catalog.catalogId)) ?? [];

        form.setFieldsValue({
            catalogCategories: [
                // if we choose a country where catalog is removed, it loses its position in ui (see comment below)
                // but if we change the country back, we need to restore the position by sorting
                ...sortBy(
                    [
                        ...availableCatalogs.map(({reset, ...catalog}) => catalog),
                        ...newCatalogs.map((catalog) => ({...catalog, edited: false})),
                    ],
                    (catalog) => availableCatalogIds.indexOf(catalog.catalogId)
                ),
                // moving these catalogs to the end of the array ensures that ant won't use them in tabs mapping that works with simple array indexes
                ...unavailableCatalogs.map((catalog) => ({...catalog, reset: true, edited: false})),
            ],
        });
    }

    function handleCatalogRemove(index: number) {
        form.setFieldsValue({
            catalogCategories: catalogCategories.map((catalogWithId, catalogIndex) => {
                if (index !== catalogIndex) {
                    return catalogWithId;
                }

                const mappedCatalog = mappedCatalogCategories.find(
                    ({catalogId}) => catalogId === catalogWithId.catalogId
                );

                if (!mappedCatalog) {
                    return catalogWithId;
                }

                return {
                    ...catalogWithId,
                    categories: mappedCatalog?.categories ?? [],
                    edited: false,
                    reset: true,
                };
            }),
        });
    }

    function handleCatalogEdit(catalogId: number) {
        form.setFieldsValue({
            catalogCategories: catalogCategories.map((catalogWithId) =>
                catalogWithId.catalogId === catalogId
                    ? {
                          ...catalogWithId,
                          edited: true,
                          reset: false,
                      }
                    : catalogWithId
            ),
        });
    }

    return (
        <CatalogList
            activeTabIndex={getErrorTabIndex(form.getFieldsError, CompanyKeyEnum.CatalogCategories)}
            isCatalogVisible={({canRewriteCategories}) => canRewriteCategories}
            onCatalogAvailabilityChange={handleCountryAvailabilityChange}
            renderCatalog={(catalog: CatalogConfigWithIndexType) => {
                const newCatalog = catalogCategories?.find(({catalogId}) => catalogId === catalog.catalogId);

                return (
                    <Form.List name={CompanyKeyEnum.CatalogCategories}>
                        {() => (
                            <CatalogTab
                                catalog={catalog}
                                footerButtonText={
                                    <Locale stringKey="COMPANY_FORM__CATEGORIES__CATALOG_LIST__FOOTER__BUTTON" />
                                }
                                footerButtonVisible={Boolean(newCatalog?.edited)}
                                modalContent={
                                    <Locale
                                        stringKey="COMPANY_FORM__CATEGORIES__CATALOG_LIST__MODAL__CONTENT"
                                        valueMap={{
                                            catalogName: catalog.label,
                                        }}
                                    />
                                }
                                modalFieldName={name}
                                modalTitle={<Locale stringKey="COMPANY_FORM__CATEGORIES__CATALOG_LIST__MODAL__TITLE" />}
                                onRemove={handleCatalogRemove}
                            >
                                <Form.Item name={[catalog.index, 'categories']}>
                                    <SelectCategoriesFormItemWithCatalog
                                        catalogId={catalog.catalogId}
                                        isPaidYandexPlacement={isPaidYandexPlacement}
                                        locked={!newCatalog?.edited}
                                    >
                                        <Space
                                            className={classNames(styles.categories_widget__label)}
                                            direction="vertical"
                                            size="middle"
                                        >
                                            {catalogCategoryLimits(isPaidYandexPlacement)[catalog.catalogId] && (
                                                <Typography.Text type="secondary">
                                                    <LocalePlural
                                                        count={
                                                            catalogCategoryLimits(isPaidYandexPlacement)[
                                                                catalog.catalogId
                                                            ] ?? 0
                                                        }
                                                        fewKey="COMPANY_FORM__CATEGORIES__LIMIT"
                                                        manyKey="COMPANY_FORM__CATEGORIES__LIMIT"
                                                        singularKey="COMPANY_FORM__CATEGORIES__LIMIT_SINGULAR"
                                                        valueMap={{
                                                            catalogName: catalog.label,
                                                            limit: catalogCategoryLimits(isPaidYandexPlacement)[
                                                                catalog.catalogId
                                                            ],
                                                        }}
                                                    />
                                                </Typography.Text>
                                            )}

                                            {!newCatalog?.edited && (
                                                <Button block onClick={() => handleCatalogEdit(catalog.catalogId)}>
                                                    <Locale stringKey="TEXT__EDIT" />
                                                </Button>
                                            )}
                                        </Space>
                                    </SelectCategoriesFormItemWithCatalog>
                                </Form.Item>
                            </CatalogTab>
                        )}
                    </Form.List>
                );
            }}
            showWithoutConfiguration
            title={getLocalizedString('COMPANY_FORM__CATEGORIES__CATALOGS_HEADER')}
            transformCatalogTitle={(catalogId: number, catalogTitle: string) => {
                const catalogName = getCatalogName(catalogId) || catalogTitle;

                return catalogCategories?.find((catalogWithId) => catalogWithId?.catalogId === catalogId)?.edited
                    ? `${catalogName} •`
                    : catalogName;
            }}
        />
    );
}
