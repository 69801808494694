export enum LogActionsEnum {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted',
}

export enum LogTypesEnum {
    Note = 'note',
    ResponsibleUser = 'responsible_user',
    Status = 'process_status',
    Created = 'created',
    Abuse = 'abuse',
}
