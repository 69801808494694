import {DomainNameEnum} from '../../../../../provider/domain-config/domain-config-type';
import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';

import onlinePresenceLandingImage1Bw from './images/online-presence-landing-image-1-bw.png';
import onlinePresenceLandingImage1En from './images/online-presence-landing-image-1-en.png';
import onlinePresenceLandingImage1Ru from './images/online-presence-landing-image-1-ru.png';
import onlinePresenceLandingImage2En from './images/online-presence-landing-image-2-en.png';
import onlinePresenceLandingImage2Ru from './images/online-presence-landing-image-2-ru.png';

export function getOnlinePresenceLandingImage(
    shortLocaleName: ShortLocaleNameEnum,
    cardIndex: 1 | 2,
    domainName: string
): string {
    const ruImages = {
        1: onlinePresenceLandingImage1Ru,
        2: onlinePresenceLandingImage2Ru,
    };

    const enImages = {
        1: onlinePresenceLandingImage1En,
        2: onlinePresenceLandingImage2En,
    };

    const bwImages = {
        1: onlinePresenceLandingImage1Bw,
        2: onlinePresenceLandingImage2En,
    };

    if (domainName === DomainNameEnum.brandWizard) {
        return bwImages[cardIndex];
    }

    return [ShortLocaleNameEnum.ru, ShortLocaleNameEnum.kk].includes(shortLocaleName)
        ? ruImages[cardIndex]
        : enImages[cardIndex];
}
