import {Checkbox, Form, FormInstance, Input} from 'antd';
import {useEffect} from 'react';

import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../provider/user/user-hook';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {CatalogByGroupType} from '../../../../../../service/source-settings/catalogs/catalogs-type';
import {classNames} from '../../../../../../util/css';

import {LinkBrandLabel} from './link-brand-label/link-brand-label';
import {LinkBrandSelect} from './link-brand-select/link-brand-select';
import {LinkCompanyLabel} from './link-company-label/link-company-label';
import {LinkCompanySelect} from './link-company-select/link-company-select';
import {ManageLinkFormEnum, ManageLinkFormServerErrorType, ManageLinkFormType} from './manage-link-form-type';
import * as styles from './manage-link-form.scss';

type CreatePropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    formId: string;
    initialValues?: undefined;
    isEditMode: false;
    onSubmit: (values: ManageLinkFormType) => void;
    serverErrors: ManageLinkFormServerErrorType;
};

type EditPropsType = Omit<CreatePropsType, 'isEditMode' | 'initialValues'> & {
    isEditMode: true;
    initialValues: Omit<ManageLinkFormType, ManageLinkFormEnum.deleteReviews>;
};

export function ManageLinkForm(props: CreatePropsType | EditPropsType): JSX.Element {
    const {catalog, catalogGroupId, initialValues, isEditMode, formId, onSubmit, serverErrors} = props;

    const {getLocalizedString} = useLocale();
    const documentationLinks = useDocumentationLinks();

    const [form]: [FormInstance<ManageLinkFormType>] = Form.useForm();
    const {requiredFieldRule, validUrlRule} = useFormRules();
    const {user} = useUser();

    const {companyName} = useDomainConfig();

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    useEffect(() => {
        form.setFields(
            Object.entries(serverErrors).map(([name, errors]) => ({
                name,
                errors,
            }))
        );
    }, [form, serverErrors]);

    function onBrandChange(): void {
        form.setFieldsValue({[ManageLinkFormEnum.company]: null});
    }

    return (
        <>
            <p className={styles.link__catalog}>
                <img alt="logo" src={getCatalogLogo(catalog.id)} />
                {getCatalogName(catalog.id)}
            </p>
            <p className={styles.link__help}>
                <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__HELP" />
            </p>

            <Form<ManageLinkFormType>
                disabled={user?.isTestUser}
                form={form}
                id={formId}
                initialValues={{...initialValues, [ManageLinkFormEnum.deleteReviews]: false}}
                layout="vertical"
                onFinish={onSubmit}
            >
                <Form.Item<ManageLinkFormType>
                    className={classNames(styles.link__value_form_item, styles.link__form_item)}
                    label={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__LABEL" />}
                    name={ManageLinkFormEnum.link}
                    rules={[requiredFieldRule, validUrlRule()]}
                >
                    <Input
                        placeholder={getLocalizedString(
                            'SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__PLACEHOLDER'
                        )}
                    />
                </Form.Item>
                <Form.Item<ManageLinkFormType> dependencies={[ManageLinkFormEnum.link]} noStyle>
                    {({getFieldError}) =>
                        getFieldError(ManageLinkFormEnum.link).length === 0 ? null : (
                            <p className={classNames(styles.link__value_help, styles.link__value_help__error)}>
                                <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__SERVER_ERROR_ADDON" />
                            </p>
                        )
                    }
                </Form.Item>
                {documentationLinks.reviewsLinkFormats && (
                    <p className={styles.link__value_help}>
                        <Locale
                            stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__HELP"
                            valueMap={{
                                here: (
                                    <a
                                        href={documentationLinks.reviewsLinkFormats}
                                        key="here"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__HELP_HERE" />
                                    </a>
                                ),
                            }}
                        />
                    </p>
                )}

                {isEditMode && (
                    <Form.Item
                        className={styles.link__form_item}
                        name={ManageLinkFormEnum.deleteReviews}
                        valuePropName="checked"
                    >
                        <Checkbox>
                            <span className={styles.link__checkbox_label}>
                                <Locale
                                    stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE_REVIEWS__CHECKBOX__LABEL"
                                    valueMap={{companyName}}
                                />
                            </span>
                        </Checkbox>
                    </Form.Item>
                )}

                <Form.Item
                    className={styles.link__form_item}
                    label={<LinkBrandLabel />}
                    name={ManageLinkFormEnum.brandId}
                    rules={[requiredFieldRule]}
                >
                    <LinkBrandSelect catalogGroupId={catalogGroupId} isDisabled={isEditMode} onChange={onBrandChange} />
                </Form.Item>

                <Form.Item<ManageLinkFormType> dependencies={[ManageLinkFormEnum.brandId]} noStyle>
                    {({getFieldValue}) => {
                        const fieldValue: unknown = getFieldValue(ManageLinkFormEnum.brandId);
                        const brandId: number | null = typeof fieldValue === 'number' ? fieldValue : null;

                        return (
                            <Form.Item<ManageLinkFormType>
                                className={styles.link__form_item}
                                key={brandId}
                                label={<LinkCompanyLabel />}
                                name={ManageLinkFormEnum.company}
                            >
                                <LinkCompanySelect brandId={brandId} isDisabled={isEditMode} />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            </Form>
        </>
    );
}
