import {StoreLocatorConfigFullType} from '../../../../../service/store-locator-config/store-locator-config-short-type';

export const enum LocatorPreviewEventEnum {
    Ready = 'locatorReady',
    Update = 'locatorConfig',
}

export class LocatorPreviewReadyEvent {
    eventName = LocatorPreviewEventEnum.Ready;
}

export class LocatorPreviewConfigEvent {
    eventName = LocatorPreviewEventEnum.Update;
    locator: StoreLocatorConfigFullType;
    companyId: number | null = null;

    constructor(locator: StoreLocatorConfigFullType, companyId: number | null) {
        this.locator = locator;
        this.companyId = companyId;
    }
}
