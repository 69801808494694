import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Descriptions, Statistic} from 'antd';
import {Dayjs} from 'dayjs';

import {useFooterText} from '../../../../../component/footer/footer-hook';
import {Text} from '../../../../../component/text/text';
import {Logo} from '../../../../../layout/logo/logo';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useFakesStatistics} from '../../../../../service/fakes/fakes-statistics';
import {useFakesSubscriptions} from '../../../../../service/fakes/fakes-subscriptions';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {fakesReportIcons} from '../fakes-report-const';

import {domainEmails} from './fakes-report-summary-const';
import laptopSrc from './images/laptop.png';
import * as styles from './fakes-report-summary.scss';

type PropsType = {
    dateRange: [Dayjs, Dayjs] | null;
    brandIds?: Array<number>;
};

export function FakesReportSummary(props: PropsType): JSX.Element | null {
    const {dateRange, brandIds} = props;

    const {data: subscriptions} = useFakesSubscriptions();
    const {data: statistics} = useFakesStatistics({
        ...(dateRange && {
            fromDate: dateRange[0].format('YYYY-MM-DD'),
            toDate: dateRange[1].format('YYYY-MM-DD'),
        }),
        brandIds,
    });

    const {domainName} = useDomainConfig();
    const footerText = useFooterText();
    const {getFormattedDateTime} = useFormat();

    if (!statistics || !subscriptions) {
        return null;
    }

    return (
        <>
            <header className={styles.FakesReportSummary_header}>
                <Logo className={styles.FakesReportSummary_logo} />
                <Text secondary>{getFormattedDateTime(new Date())}</Text>
            </header>

            <h1 className={styles.FakesReportSummary_title}>
                <Locale stringKey="FAKES__REPORT__SUMMARY__TITLE" />
            </h1>

            <Descriptions className={styles.FakesReportSummary_descriptions} colon={false} column={1}>
                {dateRange && (
                    <Descriptions.Item
                        label={
                            <Text
                                className={styles.FakesReportSummary_descriptionsLabel}
                                secondary
                                stringKey="FAKES__REPORT__SUMMARY__PERIOD"
                            />
                        }
                    >
                        {getFormattedDateTime(dateRange[0].toDate())} - {getFormattedDateTime(dateRange[1].toDate())}
                    </Descriptions.Item>
                )}

                <Descriptions.Item
                    label={
                        <Text
                            className={styles.FakesReportSummary_descriptionsLabel}
                            secondary
                            stringKey="FAKES__REPORT__SUMMARY__BRANDS"
                        />
                    }
                >
                    <div>
                        {subscriptions
                            .filter(({brandId}) => brandIds?.includes(brandId) ?? true)
                            .map((subscription) => (
                                <div key={subscription.id}>{subscription.brandName}</div>
                            ))}
                    </div>
                </Descriptions.Item>
            </Descriptions>

            <div className={styles.FakesReportSummary_statistics}>
                <Statistic
                    title={<Locale stringKey="FAKES__RESULTS__HEADER__TOTAL_SEARCHES" />}
                    value={statistics.totalSearchCount}
                />

                <Statistic
                    prefix={
                        <FontAwesomeIcon
                            className={styles.FakesReportSummary_icon__green}
                            icon={fakesReportIcons.approved}
                        />
                    }
                    title={<Locale stringKey="FAKES__REPORT__SUMMARY__STATISTICS__FAKES" />}
                    value={statistics.fakesClaimAccepted}
                />

                <Statistic
                    prefix={
                        <FontAwesomeIcon
                            className={styles.FakesReportSummary_icon__green}
                            icon={fakesReportIcons.approved}
                        />
                    }
                    title={<Locale stringKey="FAKES__REPORT__SUMMARY__STATISTICS__DUPLICATES" />}
                    value={statistics.duplicatesClaimAccepted}
                />

                <Statistic
                    title={<Locale stringKey="FAKES__RESULTS__HEADER__FILTERED_COMPANIES" />}
                    value={statistics.filtered}
                />

                <Statistic
                    prefix={
                        <FontAwesomeIcon
                            className={styles.FakesReportSummary_icon__orange}
                            icon={fakesReportIcons.rejected}
                        />
                    }
                    title={<Locale stringKey="FAKES__REPORT__SUMMARY__STATISTICS__REJECTED" />}
                    value={statistics.claimRejected}
                />

                <Statistic
                    prefix={
                        <FontAwesomeIcon
                            className={styles.FakesReportSummary_icon__gray}
                            icon={fakesReportIcons.hidden}
                        />
                    }
                    title={<Locale stringKey="FAKES__REPORT__SUMMARY__STATISTICS__HIDDEN" />}
                    value={statistics.hidden}
                />
                <Statistic
                    title={<Locale stringKey="FAKES__REPORT__SUMMARY__STATISTICS__ALL" />}
                    value={statistics.all}
                />
            </div>

            <footer className={styles.FakesReportSummary_footer}>
                <div>
                    <Text block lighter>
                        {footerText}
                    </Text>

                    {domainEmails[domainName] && (
                        <Text block secondary>
                            {domainEmails[domainName]}
                        </Text>
                    )}
                </div>

                <img alt="" src={laptopSrc} />
            </footer>
        </>
    );
}
