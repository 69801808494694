import {Rule} from 'antd/lib/form';
import {useState} from 'react';

import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {LocaleContextType} from '../../../../../../provider/locale/locale-context-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {FILE_SIZE_BYTES} from '../../../../../../service/company/company-const';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {CompanyGalleryImageType} from '../../../../../../service/company-v2/types/company-gallery-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabIndex} from '../../catalog-list/catalog-list-helper';
import {CatalogTab} from '../../catalog-list/catalog-tab';

import {GalleryFormItem} from './gallery-form-item/gallery-form-item';
import * as styles from './gallery-widget.scss';

function getMaxImageSizeRule(getLocalizedString: LocaleContextType['getLocalizedString']): Rule {
    return {
        validator: (_rule: unknown, images: Array<CompanyGalleryImageType>): Promise<void> => {
            if (images.some((image) => image?.size && image.size > FILE_SIZE_BYTES)) {
                return Promise.reject(new Error(getLocalizedString('COMPANY_FORM__GALLERY__ERROR__MAX_SIZE')));
            }

            return Promise.resolve();
        },
    };
}

type PropsType = {
    name: string;
    initialCatalogs: Array<number>;
};

export function GalleryWidget(props: PropsType): JSX.Element {
    const {name, initialCatalogs} = props;

    const [galleryError, setGalleryError] = useState<Array<string> | null>(null);
    const [extraGalleryError, setExtraGalleryError] = useState<Array<string> | null>(null);

    const {getLocalizedString} = useLocale();

    return (
        <>
            <Form.Item<CompanyFormType>
                help={galleryError ? <div className={styles.error}>{galleryError}</div> : null}
                name={CompanyKeyEnum.Gallery}
                rules={[getMaxImageSizeRule(getLocalizedString)]}
                validateStatus={galleryError ? 'error' : undefined} // eslint-disable-line no-undefined
            >
                <GalleryFormItem setGalleryError={setGalleryError} />
            </Form.Item>

            <Form.Item<CompanyFormType> dependencies={[CompanyKeyEnum.Gallery]} noStyle>
                {({getFieldValue, setFieldsValue, getFieldsError}) => (
                    <CatalogList
                        activeTabIndex={getErrorTabIndex(getFieldsError, CompanyKeyEnum.ExtraGallery)}
                        disabled={getFieldValue(CompanyKeyEnum.Gallery).length === 0}
                        initialCatalogs={initialCatalogs}
                        isCatalogVisible={({canRewriteGallery}) => canRewriteGallery}
                        onAddCatalogs={(catalogs: Array<CatalogConfigType>) => {
                            setFieldsValue({
                                extraGallery: [
                                    ...getFieldValue(CompanyKeyEnum.ExtraGallery),
                                    ...catalogs.map(({catalogId}) => ({
                                        catalogId,
                                        gallery: getFieldValue(CompanyKeyEnum.Gallery),
                                    })),
                                ],
                            });
                        }}
                        onCatalogAvailabilityChange={(availableCatalogIds: Array<number>) => {
                            setFieldsValue({
                                extraGallery: getFieldValue(CompanyKeyEnum.ExtraGallery).filter(({catalogId}) =>
                                    availableCatalogIds.includes(catalogId)
                                ),
                            });
                        }}
                        renderCatalog={(catalog: CatalogConfigWithIndexType, {handleRemoveCatalog}) => (
                            <Form.List name={CompanyKeyEnum.ExtraGallery}>
                                {(_fields, {remove}) => (
                                    <CatalogTab
                                        catalog={catalog}
                                        modalFieldName={name}
                                        onRemove={(index: number) => {
                                            remove(index);
                                            handleRemoveCatalog(catalog.catalogId);
                                        }}
                                    >
                                        <Form.Item
                                            help={
                                                extraGalleryError ? (
                                                    <div className={styles.error}>{extraGalleryError}</div>
                                                ) : null
                                            }
                                            name={[catalog.index, 'gallery']}
                                            rules={[getMaxImageSizeRule(getLocalizedString)]}
                                            validateStatus={extraGalleryError ? 'error' : undefined} // eslint-disable-line no-undefined
                                        >
                                            <GalleryFormItem
                                                catalogId={catalog.catalogId}
                                                setGalleryError={setExtraGalleryError}
                                            />
                                        </Form.Item>
                                    </CatalogTab>
                                )}
                            </Form.List>
                        )}
                        title={getLocalizedString('COMPANY_FORM__GALLERY__CATALOGS_HEADER')}
                    />
                )}
            </Form.Item>
        </>
    );
}
