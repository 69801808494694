import {NamePath} from 'antd/lib/form/interface';
import {isObject, mapKeys, mapValues} from 'lodash';
import {FieldData} from 'rc-field-form/lib/interface';

import {CompanyKeyEnum} from '../../../../service/company-v2/company-const';
import {
    CompanyErrorType,
    CompanyFormErrorType,
    CompanyFormType,
    CompanyGeneralErrorType,
    CompanyListErrorType,
} from '../../../../service/company-v2/company-type';
import {FormInstance} from '../../../../typings/antd';

import {convertErrorToFormError} from './error-helper';

type ExtraFieldsType =
    | CompanyKeyEnum.ExtraNames
    | CompanyKeyEnum.ExtraPhones
    | CompanyKeyEnum.ExtraEmails
    | CompanyKeyEnum.ExtraWebsites
    | CompanyKeyEnum.ExtraSocialNetworks
    | CompanyKeyEnum.ExtraDescriptions;

type ErrorValueType = CompanyListErrorType<CompanyGeneralErrorType> | CompanyGeneralErrorType; // == Exclude<CompanyErrorType[T], CompanyGeneralErrorType>

// { "extraEmails": { "11": ["Ошибка google"]}, "50": ["Ошибка apple"]} } => { "extraEmails": { "0": ["Ошибка google"]}, "1": ["Ошибка apple"]} }
function transformCatalogIdsToIndexes<T extends ExtraFieldsType>(
    form: FormInstance<CompanyFormType>,
    errorMessage: CompanyErrorType,
    name: T,
    valueTransformer?: (value: CompanyErrorType[T]) => CompanyFormErrorType[T]
): CompanyFormErrorType[T] {
    const error = errorMessage[name];

    if (!isObject(error)) {
        return [];
    }

    if (Array.isArray(error)) {
        return error;
    }

    const entries = Object.entries(error)
        .map(([catalogId, value]: [string, ErrorValueType]) => {
            const fieldValue = form.getFieldValue(name as ExtraFieldsType);

            if (!Array.isArray(fieldValue)) {
                return null;
            }

            const index = fieldValue.findIndex((catalog) => catalog.catalogId.toString() === catalogId);

            if (index < 0) {
                return null;
            }

            const transformedValue = valueTransformer ? valueTransformer(value) : value;

            return [index, transformedValue];
        })
        .filter((value): value is [number, ErrorValueType] => value !== null);

    return Object.fromEntries(entries) as CompanyFormErrorType[T];
}

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity, max-statements
export function getFormErrors(
    form: FormInstance<CompanyFormType>,
    errorMessage: CompanyErrorType
): Array<FieldData> | null {
    const fixedErrorMessage = {...errorMessage} as unknown as CompanyFormErrorType;

    if (fixedErrorMessage.extraNames && !Array.isArray(fixedErrorMessage.extraNames)) {
        fixedErrorMessage.extraNames = transformCatalogIdsToIndexes(
            form,
            errorMessage,
            CompanyKeyEnum.ExtraNames,
            (value) => ({names: value})
        );
    }

    if (fixedErrorMessage.searchNames && !Array.isArray(fixedErrorMessage.searchNames)) {
        fixedErrorMessage.searchNames = [...new Set(Object.values(fixedErrorMessage.searchNames).flat())];
    }

    if (fixedErrorMessage.address && !Array.isArray(fixedErrorMessage.address)) {
        fixedErrorMessage.address = {
            ...errorMessage.address,
            country: errorMessage.address?.countryId,
            region: errorMessage.address?.regionId,
            city: errorMessage.address?.cityId,
            latLng: [...new Set([...(errorMessage.address?.lat || []), ...(errorMessage.address?.lon || [])])],
        };
    }

    if (fixedErrorMessage.legal && !Array.isArray(fixedErrorMessage.legal) && errorMessage.legal?.formId) {
        fixedErrorMessage.legal.form = errorMessage.legal?.formId;
    }

    if (fixedErrorMessage.rocketdataCategories && !Array.isArray(fixedErrorMessage.rocketdataCategories)) {
        fixedErrorMessage.rocketdataCategories = [
            ...new Set(Object.values(fixedErrorMessage.rocketdataCategories).flat()),
        ];
    }

    if (fixedErrorMessage.phones && !Array.isArray(errorMessage.phones)) {
        fixedErrorMessage.phones = mapValues(errorMessage.phones, (error) => ({phone: error}));
    }

    if (fixedErrorMessage.extraPhones && !Array.isArray(fixedErrorMessage.extraPhones)) {
        fixedErrorMessage.extraPhones = transformCatalogIdsToIndexes(
            form,
            errorMessage,
            CompanyKeyEnum.ExtraPhones,
            (phones) => ({phones: Array.isArray(phones) ? phones : mapValues(phones, (error) => ({phone: error}))})
        );
    }

    if (fixedErrorMessage.extraEmails && !Array.isArray(fixedErrorMessage.extraEmails)) {
        fixedErrorMessage.extraEmails = transformCatalogIdsToIndexes(
            form,
            errorMessage,
            CompanyKeyEnum.ExtraEmails,
            (value) => ({email: value})
        );
    }

    if (fixedErrorMessage.extraWebsites && !Array.isArray(fixedErrorMessage.extraWebsites)) {
        fixedErrorMessage.extraWebsites = transformCatalogIdsToIndexes(
            form,
            errorMessage,
            CompanyKeyEnum.ExtraWebsites,
            (value) => ({websites: value})
        );
    }

    if (fixedErrorMessage.extraSocialNetworks && !Array.isArray(fixedErrorMessage.extraSocialNetworks)) {
        fixedErrorMessage.extraSocialNetworks = transformCatalogIdsToIndexes(
            form,
            errorMessage,
            CompanyKeyEnum.ExtraSocialNetworks,
            (value) => ({socialNetworks: value})
        );
    }

    if (fixedErrorMessage.workingHours && !Array.isArray(fixedErrorMessage.workingHours)) {
        fixedErrorMessage.workingHours = undefined; // eslint-disable-line no-undefined
    }

    if (fixedErrorMessage.specialHours && !Array.isArray(fixedErrorMessage.specialHours)) {
        fixedErrorMessage.specialHours = undefined; // eslint-disable-line no-undefined
    }

    if (fixedErrorMessage.extraWorkingHours && !Array.isArray(fixedErrorMessage.extraWorkingHours)) {
        fixedErrorMessage.extraWorkingHours = undefined; // eslint-disable-line no-undefined
    }

    if (fixedErrorMessage.extraDescriptions && !Array.isArray(fixedErrorMessage.extraDescriptions)) {
        fixedErrorMessage.extraDescriptions = transformCatalogIdsToIndexes(
            form,
            errorMessage,
            CompanyKeyEnum.ExtraDescriptions
        );
    }

    if (fixedErrorMessage.attributesGoogle) {
        fixedErrorMessage.attributes = {
            ...errorMessage.attributes,
            attributesGoogle: errorMessage?.attributesGoogle,
        };
    }

    if (fixedErrorMessage.attributesYandex) {
        fixedErrorMessage.attributes = {
            ...errorMessage.attributes,
            attributesYandex: errorMessage?.attributesYandex,
        };
    }

    if (fixedErrorMessage.attributes && !Array.isArray(fixedErrorMessage.attributes)) {
        const attributes = form.getFieldValue(CompanyKeyEnum.Attributes);

        fixedErrorMessage.attributes = {
            ...fixedErrorMessage.attributes,
            attributesGoogle:
                fixedErrorMessage?.attributesGoogle ??
                (Array.isArray(fixedErrorMessage.attributes?.attributesGoogle)
                    ? fixedErrorMessage.attributes?.attributesGoogle
                    : mapKeys(fixedErrorMessage.attributes?.attributesGoogle, (_value, key) =>
                          attributes.attributesGoogle
                              .flatMap((attribute) => ('items' in attribute ? attribute.items : []))
                              .findIndex((attribute) =>
                                  attribute.values.some(({templateId}) => templateId === Number(key))
                              )
                      )),
            attributesYandex: Array.isArray(fixedErrorMessage.attributes?.attributesYandex)
                ? fixedErrorMessage.attributes?.attributesYandex
                : mapKeys(fixedErrorMessage.attributes?.attributesYandex, (_value, key) =>
                      attributes.attributesYandex.findIndex((attribute) =>
                          attribute.values.some(({templateId}) => templateId === Number(key))
                      )
                  ),
        };
    }

    return convertErrorToFormError(fixedErrorMessage);
}

export function removeErrorIfExists(name: NamePath, form: FormInstance<CompanyFormType>): void {
    const error = form.getFieldError(name);

    if (!Array.isArray(error)) {
        return;
    }

    if (error.length > 0) {
        form.setFields([{name, errors: []}]);
    }

    if (!Array.isArray(name)) {
        return;
    }

    // We might have an error set on widget root. For example: {workingHours: ['common error']}
    // and user changed some deep property inside workingHours like a break time
    const possibleNames = name.map((_path, index, array) => array.slice(0, index + 1));

    possibleNames.forEach((possibleName) => {
        const possibleError = form.getFieldError(possibleName);

        if (Array.isArray(possibleError) && possibleError.length > 0) {
            form.setFields([{name: possibleName, errors: []}]);
        }
    });
}
