import {NotificationTabKeyEnum} from '../../../../page/main/notification/notification-type';
import {CompaniesImportTypeEnum} from '../../../../service/companies-import/companies-import-type';
import {StoreLocatorLanguageEnum} from '../../../../service/store-locator-config/store-locator-config-short-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {ProvidersIdsEnum} from '../../../../util/type';
import {LocaleNameEnum} from '../../../locale/locale-context-type';
import {DomainConfigType, DomainNameEnum, HeaderVisibilityOptionsEnum} from '../../domain-config-type';

import {ReactComponent as LogoBreadcrumbs} from './component/logo-bread-crumbs/image/logo-bread-crumbs.svg';
import logoFullSrc from './component/logo-full/image/logo-full.svg';
import {LogoFull} from './component/logo-full/logo-full';
import logoShortSrc from './component/logo-short/image/logo-short.svg';
import {LogoShort} from './component/logo-short/logo-short';
import rdFaviconSrc from './favicon.png';
import {onEnter} from './script/on-enter';
import {onLoad} from './script/on-load';

const feedTypeLabels = {
    [CompaniesImportTypeEnum.RocketdataJson]: 'RocketData JSON',
    [CompaniesImportTypeEnum.RocketdataCsv]: 'RocketData CSV',
    [CompaniesImportTypeEnum.YandexXml]: 'Yandex XML',
    [CompaniesImportTypeEnum.RocketdataXlsx]: 'RocketData EXCEL',
};

const feedTemplateLinks = [
    {
        name: `${feedTypeLabels[CompaniesImportTypeEnum.RocketdataJson]} Schema`,
        link: '/static/feed-templates/rocketdata-schema.json',
    },
    {
        name: feedTypeLabels[CompaniesImportTypeEnum.RocketdataJson],
        link: '/static/feed-templates/rocketdata-example.json',
    },
    {
        name: feedTypeLabels[CompaniesImportTypeEnum.RocketdataCsv],
        link: '/static/feed-templates/rocketdata-example.csv',
    },
    {
        name: feedTypeLabels[CompaniesImportTypeEnum.RocketdataXlsx],
        link: '/static/feed-templates/rocketdata-example-xlsx.xlsx',
    },
    {
        name: feedTypeLabels[CompaniesImportTypeEnum.YandexXml],
        link: '/static/feed-templates/yandex-example.xml',
    },
];

export const domainConfig: DomainConfigType = {
    basename: '/',
    companyName: 'RocketData',
    domainName: DomainNameEnum.rocketData,
    LogoFull,
    logoFullSrc,
    LogoShort,
    logoShortSrc,
    faviconSrc: rdFaviconSrc,
    LogoBreadcrumbs,
    onLoad,
    onEnter,
    isFreshChatShow: true,
    isNotificationShow: true,
    isLogoutShow: true,
    isUserProfileShow: true,
    isIdeaButtonShow: true,
    isReportCompaniesShow: true,
    headerVisibility: HeaderVisibilityOptionsEnum.VISIBLE,
    isFooterShow: true,
    canCreateCompany: true,
    isDomainFeatureEnabled: {
        [FeaturesEnum.photoTool]: true,
        [FeaturesEnum.reviewAnalyticsBase]: true,
        [FeaturesEnum.reviewAnalyticsFull]: true,
        [FeaturesEnum.reviewAnalyticsCompetitor]: true,
        [FeaturesEnum.duplicatesAndFakes]: true,
        [FeaturesEnum.posts]: true,
        [FeaturesEnum.reviewsGenerator]: true,
        [FeaturesEnum.reviewSuggests]: true,
        [FeaturesEnum.storeLocator]: true,
        [FeaturesEnum.onlinePresenceAnalytics]: true,
        [FeaturesEnum.integrations]: true,
        [FeaturesEnum.dashboard]: true,
        [FeaturesEnum.localPack]: true,
        [FeaturesEnum.mlReplies]: true,
        [FeaturesEnum.companyGroups]: true,
        [FeaturesEnum.usedeskIntegration]: true,
        [FeaturesEnum.prices]: true,
        [FeaturesEnum.analytics]: true,
        [FeaturesEnum.userManagement]: true,
        [FeaturesEnum.omnichannel]: true,
        [FeaturesEnum.publicApi]: true,
        [FeaturesEnum.reviewGptReplySuggestions]: true,
        [FeaturesEnum.mailingListLicence]: true,
        [FeaturesEnum.tariffs]: true,
        [FeaturesEnum.companiesExport]: true,
        [FeaturesEnum.knowledge]: true,
        [FeaturesEnum.neuralNetwork]: true,
        [FeaturesEnum.sources]: true,
        [FeaturesEnum.reviews]: true,
        [FeaturesEnum.settings]: true,
        [FeaturesEnum.questionsAndAnswers]: true,
        [FeaturesEnum.myCompanies]: true,
    },
    hiddenCompanyFields: {},
    canFoldDesktopMenu: true,
    isPageWrapperHasPaddings: true,
    isLanguageSelectorVisible: true,
    isDynamicHelpLinksVisible: true,
    isSingleFeature: false,
    isWhiteLabel: false,
    availableLocales: Object.values(LocaleNameEnum),
    defaultLocale: LocaleNameEnum.enUs,
    isSourceSettingsShown: true,
    notificationTabs: [NotificationTabKeyEnum.email, NotificationTabKeyEnum.browser, NotificationTabKeyEnum.telegram],
    feedTypeLabels,
    feedTemplateLinks,
    storeLocatorLanguages: Object.values(StoreLocatorLanguageEnum),
    mainCatalogs: [ProvidersIdsEnum.google, ProvidersIdsEnum.yandex, ProvidersIdsEnum.doubleGis],
    isShowUsetifulButton: true,
    isShowYandexTransferAccountBanner: true,
    isShowTrialNeurlalinkInfo: true,
    isShowReviewSourcesBanner: true,
};
