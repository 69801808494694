import {Modal} from 'antd';
import dayjs from 'dayjs';
import {useCallback} from 'react';

import {FormattedDateTime} from '../../../provider/locale/formatted-date-time';
import {LocalePlural} from '../../../provider/locale/locale-plural';
import {Locale} from '../../../provider/locale/localization';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {Text} from '../../text/text';

import {DEFAULT_TRIAL_PERIOD_DURATION_DAYS} from './trial-activation-modal-helper';
import * as styles from './trial-activation-modal.scss';

type PropsType = {
    activateTrial: () => Promise<unknown>;
    onTrialPeriodActivated?: VoidFunction;
    onCancel: VoidFunction;
    open: boolean;
    productNameLangKey: LangKeyType;
    trialPeriodDuration?: number;
    isActivatingTrial: boolean;
};

export function TrialActivationModal(props: PropsType): JSX.Element {
    const {
        activateTrial,
        onCancel,
        onTrialPeriodActivated,
        open,
        productNameLangKey,
        isActivatingTrial,
        trialPeriodDuration = DEFAULT_TRIAL_PERIOD_DURATION_DAYS,
    } = props;

    const trialExpirationDate = dayjs().add(trialPeriodDuration, 'day');

    const {snackbar} = useSnackbar();

    const handleOk = useCallback(() => {
        activateTrial()
            .then(() => {
                onTrialPeriodActivated?.();
                snackbar.success({
                    message: <Locale stringKey="TRIAL_PERIOD_MODAL__SUCCESS__MESSAGE" />,
                    description: (
                        <Locale
                            stringKey="TRIAL_PERIOD_MODAL__SUCCESS__DESCRIPTION"
                            valueMap={{
                                endDate: (
                                    <FormattedDateTime
                                        key="trial-activation-message-end-date"
                                        options={{
                                            day: '2-digit',
                                            year: 'numeric',
                                            month: '2-digit',
                                        }}
                                        value={trialExpirationDate.toDate()}
                                    />
                                ),
                            }}
                        />
                    ),
                });
            })
            .catch(() => {
                snackbar.error({
                    message: <Locale stringKey="TRIAL_PERIOD_MODAL__ERROR__MESSAGE" />,
                    description: <Locale stringKey="TRIAL_PERIOD_MODAL__ERROR__DESCRIPTION" />,
                });
            });
    }, [activateTrial, onTrialPeriodActivated, snackbar, trialExpirationDate]);

    return (
        <Modal
            cancelButtonProps={{disabled: isActivatingTrial}}
            className={styles.TrialActivationModal}
            closable
            okButtonProps={{disabled: isActivatingTrial, loading: isActivatingTrial}}
            okText={<Locale stringKey="TRIAL_PERIOD_MODAL__TEST_PERIOD__ACTIVATE" />}
            onCancel={onCancel}
            onOk={handleOk}
            open={open}
            title={<Locale stringKey="TRIAL_PERIOD_MODAL__TITLE" />}
        >
            <div className={styles.TrialActivationModal_productWrapper}>
                <Text block bolder lighter stringKey="TRIAL_PERIOD_MODAL__SERVICE" />
                <div className={styles.TrialActivationModal_productCard}>
                    <Text stringKey={productNameLangKey} />
                </div>
            </div>

            <div className={styles.TrialActivationModal_testPeriodWrapper}>
                <Text
                    block
                    className={styles.TrialActivationModal_testPeriodLabel}
                    stringKey="TRIAL_PERIOD_MODAL__TEST_PERIOD"
                />
                <Text block className={styles.TrialActivationModal_testPeriodExpirationDate}>
                    <LocalePlural
                        count={trialPeriodDuration}
                        fewKey="TRIAL_PERIOD_MODAL__TEST_PERIOD__DURATION__FEW"
                        manyKey="TRIAL_PERIOD_MODAL__TEST_PERIOD__DURATION__PLURAL"
                        singularKey="TRIAL_PERIOD_MODAL__TEST_PERIOD__DURATION__SINGLE"
                        valueMap={{
                            trialDuration: trialPeriodDuration,
                            endDate: (
                                <Text bolder key="trial-end-date">
                                    <FormattedDateTime
                                        options={{
                                            day: '2-digit',
                                            year: 'numeric',
                                            month: '2-digit',
                                        }}
                                        value={trialExpirationDate.toDate()}
                                    />
                                </Text>
                            ),
                        }}
                    />
                </Text>
            </div>
        </Modal>
    );
}
