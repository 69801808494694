export const enFeedTranslations = {
    FEED__FILE: 'File',
    FEED__LINK: 'Link',
    FEED__ABORT: 'Abort',
    FEED__ERROR__SINGLE: '{count} error',
    FEED__ERROR__FEW: '{count} errors',
    FEED__ERROR__MANY: '{count} errors',
    FEED__IMPORT_AND_EXPORT: 'Import and export',
    FEED__IMPORT__TITLE: 'Validation and import of companies',
    FEED__IMPORT__CONNECTED_FEEDS__BUTTON: 'Connected feeds',
    FEED__IMPORT__FORM__SUBMIT: 'Validate and import',
    FEED__IMPORT__BEFORE_PROGRESS__TITLE: 'Verification will start soon, please wait...',
    FEED__IMPORT__PROGRESS__TITLE: 'Data verification in progress...',
    FEED__IMPORT__PROGRESS__IMPORT__TITLE: 'Importing data...',
    FEED__IMPORT__ABORT__BUTTON: 'Abort import',
    FEED__IMPORT__ABORT_MODAL__TITLE: 'Abort validation and import?',
    FEED__IMPORT__ABORT_MODAL__DESCRIPTION: 'The data will not be imported. The validation progress will be lost.',
    FEED__IMPORT__SUCCESS__TITLE: 'Processing completed',
    FEED__IMPORT__SUCCESS__DESCRIPTION:
        'Confirm data import. Statistics and error descriptions are in the result block.',
    FEED__IMPORT__WILL_BE_IMPORTED: 'Companies to import',
    FEED__IMPORT__WILL_NOT_BE_IMPORTED: 'Will not be imported',
    FEED__IMPORT__CHANGES__TITLE: 'Company changes after import',
    FEED__IMPORT__CHANGES__NEW: 'New companies',
    FEED__IMPORT__CHANGES__CHANGED: 'Updated companies',
    FEED__IMPORT__CHANGES__UNCHANGED: 'Companies unchanged',
    FEED__IMPORT__CONFIRM__TITLE: 'Data is ready to be imported',
    FEED__IMPORT__CONFIRM__DESCRIPTION__SINGLE: '{count} company will be imported.',
    FEED__IMPORT__CONFIRM__DESCRIPTION__FEW: '{count} companies will be imported.',
    FEED__IMPORT__CONFIRM__DESCRIPTION__MANY: '{count} companies will be imported.',
    FEED__IMPORT__CONFIRM__UPDATE: 'Update automatically',
    FEED__IMPORT__CONFIRM__UPDATE__TITLE: 'Auto-update',
    FEED__IMPORT__CONFIRM__UPDATE__DESCRIPTION:
        'We will update the feed daily and import companies data into your account.',
    FEED__IMPORT__CONFIRM__CONFIRM: 'Confirm import',
    FEED__IMPORT__COMPLETE__TITLE: 'Import completed successfully!',
    FEED__IMPORT__COMPLETE__DESCRIPTION__SINGLE: '{count} company imported.',
    FEED__IMPORT__COMPLETE__DESCRIPTION__FEW: 'Imported {count} companies.',
    FEED__IMPORT__COMPLETE__DESCRIPTION__MANY: 'Imported {count} companies.',
    FEED__IMPORT__CANCEL__TITLE: 'Import cancelled',
    FEED__IMPORT__CANCEL__DESCRIPTION: 'You have canceled the import.',
    FEED__IMPORT__ERROR__TITLE: 'Import not possible',
    FEED__IMPORT__ERROR__DESCRIPTION: 'No valid companies to import.',
    FEED__VALIDATION__TITLE: 'Companies validation',
    FEED__VALIDATION__TITLE__NOT_AUTHORIZED: 'Validation',
    FEED__VALIDATION__HEADER__NOT_AUTHORIZED: 'Validation of company data feeds',
    FEED__VALIDATION__FORM__SUBMIT: 'Start validation',
    FEED__VALIDATION__ABORT__BUTTON: 'Abort validation',
    FEED__VALIDATION__ABORT_MODAL__TITLE: 'Abort data validation?',
    FEED__VALIDATION__ABORT_MODAL__DESCRIPTION: 'Data validation will be cancelled. Progress will be lost.',
    FEED__VALIDATION__PROGRESS__TITLE: 'Validating data...',
    FEED__VALIDATION__SUCCESS__TITLE: 'Verification completed',
    FEED__VALIDATION__SUCCESS__DESCRIPTION:
        'Verification of {format} completed. Statistics and error descriptions are in the result section.',
    FEED__ROUTES_MODAL__TITLE: 'Import and export of company data',
    FEED__ROUTES_MODAL__VALIDATION__LABEL: 'Data validation',
    FEED__ROUTES_MODAL__VALIDATION__DESCRIPTION: 'Check your feed or data file.',
    FEED__ROUTES_MODAL__IMPORT__LABEL: 'Companies import',
    FEED__ROUTES_MODAL__IMPORT__DESCRIPTION: 'Import company data into {companyName}.',
    FEED__ROUTES_MODAL__EXPORT__LABEL: 'Companies export',
    FEED__ROUTES_MODAL__EXPORT__DESCRIPTION: 'Export companies from {companyName} to a file.',
    FEED__TEMPLATE_LINKS: 'Examples and templates',
    FEED__TEMPLATE_LINKS__HELP: 'You can read more about importing and validating company data in {link}',
    FEED__TEMPLATE_LINKS__HELP_LINK: 'Knowledge base',
    FEED__FORM__FROM_LINK: 'From link',
    FEED__FORM__FROM_FILE: 'From file',
    FEED__FORM__LINK__LABEL: 'Feed URL',
    FEED__FORM__FILE__TITLE: 'Click to upload file',
    FEED__FORM__FILE__DESCRIPTION: 'Or drag the file to this area. {extensions} files up to 5 MB.',
    FEED__FORM__FORMAT__LABEL: 'Data format',
    FEED__FORM__BRANDS__LABEL: 'Brands',
    FEED__FORM__UPDATE__LABEL: 'Update',
    FEED__FORM__STATUS__LABEL: 'Status',
    FEED__FORM__BRANDS__INFO_FROM_MULTIPLE_CHOICE:
        'When adding multiple brands, specify the corresponding code in the feed.',
    FEED__FORM__BRANDS__INFO_BRAND_CODE_IN_FEED: 'Brand code in the feed',
    FEED__FORM__BRANDS__INFO_DESCRIPTION:
        'The brand code in the feed should be specified in the {field} field. Without this field, validation and import of multiple brands are not possible. {link}',
    FEED__FORM__BRANDS__INFO__LINK_HELP: 'Learn more',
    FEED__FORM__BRANDS__BUTTON__ADD_BRAND: 'Add brand',
    FEED__FORM__BRANDS__PLACEHOLDER: 'Select brand',
    FEED__FORM__BRANDS__DELETE_TOOLTIP: 'Minimum one brand',
    FEED__FORM__BRANDS__COLUMN_TITLE__BRAND: 'Brand',
    FEED__FORM__BRANDS__COLUMN_TITLE__BRAND_CODE: 'Brand code',
    FEED__FORM__BRANDS__COLUMN_TITLE__ACTION: 'Action',
    FEED__FORM__PROGRESS__REMAINING: '{time} remaining',
    FEED__FORM__LOADING_ERROR__TITLE: 'Feed link not available',
    FEED__FORM__LOADING_ERROR__DESCRIPTION: 'Check that the link is correct and the feed is available.',
    FEED__FORM__PARSING_ERROR__TITLE: 'Failed to read {source} with data',
    FEED__FORM__PARSING_ERROR__TITLE__FEED: 'feed',
    FEED__FORM__PARSING_ERROR__TITLE__FILE: 'file',
    FEED__FORM__PARSING_ERROR__DESCRIPTION:
        'Data does not match the selected format. Try changing the format to test it or download sample data.',
    FEED__RESULTS__TITLE: 'Data validation results',
    FEED__RESULTS__COMPANIES_WITHOUT_ERRORS: 'Companies without errors',
    FEED__RESULTS__COMPANIES_WITH_ERRORS: 'Companies with errors',
    FEED__RESULTS__DUPLICATES: 'Duplicate companies',
    FEED__RESULTS__CRITICAL_ERRORS: 'With critical errors',
    FEED__RESULTS__EXPORT: 'Export errors to CSV',
    FEED__RESULTS__EXPORT__BUTTON: 'Export errors',
    FEED__RESULTS__EXPORT__SUCCESS: 'Errors in csv format have been successfully sent to your email address',
    FEED__RESULTS__NO_ERRORS: 'No errors',
    FEED__RESULTS__SOURCE__LABEL: 'Data source',
    FEED__RESULTS__ERROR_DESCRIPTIONS: 'Error descriptions',
    FEED__RESULTS__CRITICAL_ERROR: 'Critical error',
    FEED__RESULTS__CRITICAL_ERROR__DESCRIPTION: 'Companies with this error will NOT be imported',
    FEED__RESULTS__NON_CRITICAL_ERROR__DESCRIPTION: 'Fields with errors will be completely cleared on import',
    FEED__RESULTS__TABLE__COMPANY: 'Company name and address',
    FEED__RESULTS__TABLE__CATALOG: 'Catalog',
    FEED__RESULTS__TABLE__FIELD: 'Field with error',
    FEED__RESULTS__FEED_LINE_NUMBER__LINK: 'Object number in the feed',
    FEED__RESULTS__FEED_LINE_NUMBER__FILE: 'File line number',
    FEED__MANAGEMENT__TITLE: 'Feeds management',
    FEED__MANAGEMENT__HEADER__IMPORT_BUTTON: 'Import',
    FEED__MANAGEMENT__TABLE__COLUMN__FEED_WITH_DATA: 'Feed with data',
    FEED__MANAGEMENT__TABLE__COLUMN__BRANDS: 'Brands',
    FEED__MANAGEMENT__TABLE__COLUMN__BRANDS__INPUT_PLACEHOLDER: 'Search by brands',
    FEED__MANAGEMENT__TABLE__COLUMN__TYPE: 'Type',
    FEED__MANAGEMENT__TABLE__COLUMN__UPDATED: 'Update date',
    FEED__MANAGEMENT__TABLE__COLUMN__STATUS: 'Status',
    FEED__MANAGEMENT__TABLE__COLUMN__ERRORS: 'Errors',
    FEED__MANAGEMENT__TABLE__COLUMN__SOURCE: 'Source',
    FEED__MANAGEMENT__TABLE__STATUS__LOADING: 'Loading',
    FEED__MANAGEMENT__TABLE__STATUS__ACTIVE: 'Active',
    FEED__MANAGEMENT__TABLE__STATUS__NO_DATA: 'N/A',
    FEED__MANAGEMENT__TABLE__STATUS__ERROR: 'Error',
    FEED__MANAGEMENT__TABLE__STATUS__NOT_DEFINED: 'Not defined',
    FEED__MANAGEMENT__TABLE__ERROR__HAS_CRITICAL: 'There are critical errors',
    FEED__MANAGEMENT__TABLE__ERROR__HAS_ERRORS: 'Non-critical errors',
    FEED__MANAGEMENT__TABLE__ERROR__NO_ERRORS: 'No errors',
    FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_ON: 'Turn on feed update',
    FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_OFF: 'Turn off feed update',
    FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_OFF_DESCRIPTION:
        'Data from this feed will not be automatically updated until you turn on updates again.',
    FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_ON_BUTTON: 'Turn On',
    FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_OFF_BUTTON: 'Turn Off',
    FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_OFF__TITLE: 'Auto-update disabled',
    FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_ON__TITLE: 'Auto-update enabled',
    FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_OFF__DESCRIPTION: 'Data from the feed will not be updated.',
    FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_ON__DESCRIPTION:
        'The feed will be updated daily. Next update on {date}.',
    FEED__MANAGEMENT__LIST_OF_COMPANIES: 'Total feeds: {count}',
    FEED__MANAGEMENT__EMPTY_PAGE__TITLE: 'No linked feeds',
    FEED__MANAGEMENT__EMPTY_PAGE__DESCRIPTION: 'Import data and link a feed on the import page.',
    FEED__MANAGEMENT__DRAWER__FEED_INFO: 'Feed information',
    FEED__MANAGEMENT__DRAWER__OFF_AUTOUPDATE: 'Off',
    FEED__MANAGEMENT__DRAWER__ON_AUTOUPDATE: 'On',
    FEED__MANAGEMENT__DRAWER__TABLE_LABEL__DATE: 'Date and time',
    FEED__MANAGEMENT__DRAWER__TABLE_LABEL__COMPANIES_IMPORT_COUNT: 'Imported companies',
    FEED__MANAGEMENT__DRAWER__TABLE_LABEL__COMPANIES_UPDATED_COUNT: 'Updated companies',
    FEED__MANAGEMENT__DRAWER__TABLE_LABEL__COMPANIES_NO_CHANGED_COUNT: 'Unchanged companies',
    FEED__MANAGEMENT__DRAWER__TABLE_LABEL__LAST_IMPORT: 'Last import',
    FEED__MANAGEMENT__DRAWER__TABLE__BRANDS_COUNT: 'Brands: {count}',
    FEED__MANAGEMENT__DRAWER__BUTTON__DELETE_FEED: 'Delete feed',
    FEED__MANAGEMENT__DRAWER__MODAL__DELETE_FEED: 'Delete feed?',
    FEED__MANAGEMENT__DRAWER__MODAL__DELETE_FEED__CONTENT:
        'The feed will be unlinked and deleted. The company data in the dashboard will be preserved in its current state.',
    FEED__MANAGEMENT__DRAWER__CONTENT__DELETE_FEED__SUCCESS: 'Feed successfully deleted',
    FEED__MANAGEMENT__DRAWER__CONTENT__DELETE_FEED__SUCCESS__DESCRIPTION: 'The data from the feed will not be updated',
    FEED__EXPORT__SELECTOR__TITLE: 'Companies for export',
    FEED__EXPORT__SUBMIT_BUTTON: 'Export companies ({count})',
    FEED__EXPORT__EMAILS__SEND_TO_EMAIL: 'Send by email',
    FEED__EXPORT__COMPANY_DATA_TYPE__HELP: 'For more information about company data types, you can read {link}',
    FEED__EXPORT__COMPANY_DATA_TYPE__CSV: 'CSV File',
    FEED__EXPORT__ERROR_MESSAGE__DESCRIPTION: 'Select companies for export',
    FEED__EXPORT__MODAL__EMAIL_SUCCESS__TITLE: 'File will be sent to Email',
    FEED__EXPORT__MODAL__EMAIL_SUCCESS__CONTENT:
        'You can continue working in your personal account. Exporting a large number of companies may take some time.',
    FEED__EXPORT__MODAL__FILE_SUCCESS__TITLE: 'File will be downloaded automatically',
    FEED__EXPORT__MODAL__FILE_SUCCESS__CONTENT:
        'Please do not close the personal account tab during the export. With a large number of companies, the export process may take some time.',
    FEED__IMPORT__ALERT__SUCCESS__SINGULAR:
        '{count} company has been successfully added, and its data has been sent to the directories.',
    FEED__IMPORT__ALERT__SUCCESS__FEW:
        '{count} companies have been successfully added, and their data has been sent to the directories.',
    FEED__IMPORT__ALERT__SUCCESS__MANY:
        '{count} companies have been successfully added, and their data has been sent to the directories.',
    FEED__IMPORT__ALERT__ERROR__SINGULAR:
        'There is not enough license quota for {count} company — purchase additional licenses to enable data submission.',
    FEED__IMPORT__ALERT__ERROR__MANY:
        'There is not enough license quota for {count} companies — purchase additional licenses to enable data submission.',
    FEED__IMPORT__ALERT__MESSAGE: 'Import completed, but there is not enough license quota.',
};
