import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {FilterValue} from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {Locale} from '../../../provider/locale/localization';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../provider/user/user-hook';
import {DatePeriodEnum} from '../../../util/date';
import {getEnumValue, getEnumValueEnsure} from '../../../util/enum';
import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {PaginationHookType} from '../../../util/pagination-hook/pagination-hook-type';
import {ProvidersEnum} from '../../../util/type';
import {useUrl} from '../../../util/url-hook/url-hook';

import {
    fetchRatingDynamicsChartsData,
    fetchReviewsRatingsCompaniesStats,
    requestRatingDynamicsExport,
} from './reviews-analytics-ratings-dynamic-api';
import {
    getCatalogsFilterState,
    getDatePeriodRangeValues,
    getReviewsRatingsCompanyChartDataUrl,
    getReviewsRatingsDynamicsRequestExportUrl,
    getReviewsRatingsDynamicsUrl,
} from './reviews-analytics-ratings-dynamic-helper';
import {
    RatingDynamicsChartsDataType,
    RatingDynamicsCompaniesStatsDataType,
    RatingsDynamicsCatalogFilterValuesEnum,
    RatingsDynamicsCatalogSortingValuesEnum,
    RatingsDynamicsFilterQueryParametersEnum,
    RatingsDynamicsFilterType,
    RatingsDynamicsQueryFilterType,
    RatingsDynamicsYandexBaseStatusValuesEnum,
    RatingsDynamicsYandexTargetStatusValuesEnum,
} from './reviews-analytics-ratings-dynamic-type';

export function useRatingDynamicsChartData(
    companyId: string,
    filter: RatingsDynamicsFilterType
): UseQueryResult<RatingDynamicsChartsDataType> {
    const {user} = useUser();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery(
        [
            getReviewsRatingsCompanyChartDataUrl({
                companyId,
                filter,
                regionAvailableCatalogs: user?.regionAvailableCatalogs || null,
                mainFilterKey,
            }),
        ],
        () =>
            fetchRatingDynamicsChartsData({
                companyId,
                filter,
                regionAvailableCatalogs: user?.regionAvailableCatalogs || null,
                mainFilterKey,
            })
    );
}

type RatingDynamicsStatisticsFilterHookType = {
    filter: RatingsDynamicsFilterType;
    updateFilter: (
        filterData: Record<string, FilterValue | null>,
        sorterResult: {
            provider: string;
            sortValue: RatingsDynamicsCatalogSortingValuesEnum;
        } | null
    ) => void;
    updateSelectedDates: (dates: [Date, Date]) => void;
    updateDatesPreset: (datesPreset: DatePeriodEnum) => void;
    query: UseQueryResult<RatingDynamicsCompaniesStatsDataType>;
    pagination: PaginationHookType;
};

export function useCompanyRatingDynamics(): RatingDynamicsStatisticsFilterHookType {
    const {queries, setQuery} = useUrl<RatingsDynamicsQueryFilterType>();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    // eslint-disable-next-line complexity
    const [filterState, setFilterState] = useState<RatingsDynamicsFilterType>(() => {
        const datePeriod = getEnumValueEnsure(DatePeriodEnum, queries.date_preset, DatePeriodEnum.Month);
        let dateRangeFromPeriod = getDatePeriodRangeValues(datePeriod, dayjs().subtract(1, 'day').toDate());

        if (!dateRangeFromPeriod) {
            const dateStart = queries.base_date ? new Date(queries.base_date) : null;
            const dateEnd = queries.target_date ? new Date(queries.target_date) : null;

            if (dateStart && dateEnd) {
                dateRangeFromPeriod = {
                    start: dateStart,
                    end: dateEnd,
                };
            }
        }

        return {
            ...(dateRangeFromPeriod?.start
                ? {
                      [RatingsDynamicsFilterQueryParametersEnum.BaseDate]: dateRangeFromPeriod.start,
                  }
                : {
                      [RatingsDynamicsFilterQueryParametersEnum.BaseDate]: dayjs().subtract(30, 'days').toDate(),
                  }),
            ...(dateRangeFromPeriod?.end
                ? {
                      [RatingsDynamicsFilterQueryParametersEnum.TargetDate]: dateRangeFromPeriod.end,
                  }
                : {
                      [RatingsDynamicsFilterQueryParametersEnum.TargetDate]: dayjs().subtract(1, 'day').toDate(),
                  }),
            [RatingsDynamicsFilterQueryParametersEnum.DatePreset]: datePeriod,
            [RatingsDynamicsFilterQueryParametersEnum.Address]:
                queries[RatingsDynamicsFilterQueryParametersEnum.Address] || '',
            [RatingsDynamicsFilterQueryParametersEnum.Code]:
                queries[RatingsDynamicsFilterQueryParametersEnum.Code] || '',
            [RatingsDynamicsFilterQueryParametersEnum.Name]:
                queries[RatingsDynamicsFilterQueryParametersEnum.Name] || '',
            ...getCatalogsFilterState(queries),
        };
    });

    useEffect(() => {
        setQuery({
            [RatingsDynamicsFilterQueryParametersEnum.BaseDate]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.BaseDate].toISOString(),
            [RatingsDynamicsFilterQueryParametersEnum.TargetDate]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.TargetDate].toISOString(),
            [RatingsDynamicsFilterQueryParametersEnum.DatePreset]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.DatePreset],
            [RatingsDynamicsFilterQueryParametersEnum.Address]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.Address],
            [RatingsDynamicsFilterQueryParametersEnum.Name]: filterState[RatingsDynamicsFilterQueryParametersEnum.Name],
            [RatingsDynamicsFilterQueryParametersEnum.Code]: filterState[RatingsDynamicsFilterQueryParametersEnum.Code],
            [RatingsDynamicsFilterQueryParametersEnum.GoogleFilter]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.GoogleFilter].join(','),
            [RatingsDynamicsFilterQueryParametersEnum.YandexFilter]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.YandexFilter].join(','),
            [RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter].join(','),
            [RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter]:
                filterState[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter].join(','),
        });
    }, [setQuery, filterState]);

    const paginationDependencies = useMemo(() => {
        return {
            filterState,
            mainFilterKey,
        };
    }, [filterState, mainFilterKey]);

    const pagination = usePagination({dependencies: paginationDependencies, initialPageSize: 10});
    const {onDataLoaded} = pagination;

    const updateFilter = useCallback(
        (
            filterData: Record<string, FilterValue | null>,
            sorterResult: {
                provider: string;
                sortValue: RatingsDynamicsCatalogSortingValuesEnum;
            } | null
        ) => {
            setFilterState((previousFilterState) => {
                let newFilterState = previousFilterState;

                const availableFilterProviders = [
                    ProvidersEnum.doubleGis,
                    ProvidersEnum.google,
                    ProvidersEnum.yandex,
                ] as const;

                availableFilterProviders.forEach((provider) => {
                    newFilterState = {
                        ...newFilterState,
                        [provider]: [
                            ...(sorterResult?.provider === provider ? [sorterResult.sortValue] : []),
                            ...Object.values(RatingsDynamicsCatalogFilterValuesEnum).filter((valueItem) =>
                                filterData[provider]?.includes(valueItem)
                            ),
                        ],
                    };
                });

                const {
                    [`${ProvidersEnum.yandex}_baseRating`]: yandexBaseStatusFilter,
                    [`${ProvidersEnum.yandex}_targetRating`]: yandexTargetStatusFilter,
                } = filterData;

                newFilterState[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter] = [
                    ...(yandexBaseStatusFilter ?? []).flatMap((rawValue) => {
                        const enumValue = getEnumValue(RatingsDynamicsYandexBaseStatusValuesEnum, rawValue);

                        if (enumValue) {
                            return [enumValue];
                        }

                        return [];
                    }),
                    ...(yandexTargetStatusFilter ?? []).flatMap((rawValue) => {
                        const enumValue = getEnumValue(RatingsDynamicsYandexTargetStatusValuesEnum, rawValue);

                        if (enumValue) {
                            return [enumValue];
                        }

                        return [];
                    }),
                ];

                newFilterState[RatingsDynamicsFilterQueryParametersEnum.Address] = String(
                    filterData[RatingsDynamicsFilterQueryParametersEnum.Address]?.[0] || ''
                );
                newFilterState[RatingsDynamicsFilterQueryParametersEnum.Name] = String(
                    filterData[RatingsDynamicsFilterQueryParametersEnum.Name]?.[0] || ''
                );
                newFilterState[RatingsDynamicsFilterQueryParametersEnum.Code] = String(
                    filterData[RatingsDynamicsFilterQueryParametersEnum.Code]?.[0] || ''
                );

                return newFilterState;
            });
        },
        []
    );

    const updateSelectedDates = useCallback((dates: [Date, Date]) => {
        setFilterState((previousFilterState) => {
            return {
                ...previousFilterState,
                [RatingsDynamicsFilterQueryParametersEnum.BaseDate]: dates[0],
                [RatingsDynamicsFilterQueryParametersEnum.TargetDate]: dates[1],
                [RatingsDynamicsFilterQueryParametersEnum.DatePreset]: DatePeriodEnum.Custom,
            };
        });
    }, []);

    const updateDatesPreset = useCallback((datesPreset: DatePeriodEnum) => {
        setFilterState((previousState) => {
            const datesRange = getDatePeriodRangeValues(datesPreset, dayjs().subtract(1, 'day').toDate()) || {
                start: previousState[RatingsDynamicsFilterQueryParametersEnum.BaseDate],
                end: previousState[RatingsDynamicsFilterQueryParametersEnum.TargetDate],
            };

            return {
                ...previousState,
                [RatingsDynamicsFilterQueryParametersEnum.BaseDate]: datesRange.start,
                [RatingsDynamicsFilterQueryParametersEnum.TargetDate]: datesRange.end,
                [RatingsDynamicsFilterQueryParametersEnum.DatePreset]: datesPreset,
            };
        });
    }, []);

    const companyRatingsQuery = useQuery(
        [getReviewsRatingsDynamicsUrl(filterState, mainFilterKey, pagination)],
        () => fetchReviewsRatingsCompaniesStats(filterState, mainFilterKey, pagination),
        {
            onSuccess: (data) => onDataLoaded(data),
        }
    );

    return {
        filter: filterState,
        updateFilter,
        updateSelectedDates,
        updateDatesPreset,
        query: companyRatingsQuery,
        pagination,
    };
}

export function useRequestRatingsDynamicsExport(
    filter: RatingsDynamicsFilterType
): UseMutationResult<void, unknown, void> {
    const {snackbar} = useSnackbar();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {user} = useUser();

    return useMutation(
        [getReviewsRatingsDynamicsRequestExportUrl(filter, mainFilterKey, user?.regionAvailableCatalogs || null)],
        () => {
            return requestRatingDynamicsExport(filter, mainFilterKey, user?.regionAvailableCatalogs || null);
        },
        {
            onSuccess: () => {
                snackbar.success({
                    message: <Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__SENT_BY_EMAIL__TITLE" />,
                    description: (
                        <Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__SENT_BY_EMAIL__DESCRIPTION" />
                    ),
                });
            },
            onError: () => {
                snackbar.error({
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                    description: (
                        <Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__SENT_BY_EMAIL__ERROR_DESCRIPTION" />
                    ),
                });
            },
        }
    );
}
