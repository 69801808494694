import {MutableRefObject, useCallback, useEffect, useMemo, useState} from 'react';

import {logError} from '../../util/error';
import {useIsMounted} from '../../util/is-mounted';
import {
    fetchCityList,
    fetchCountryV2List,
    fetchLegalOptionDataList,
    fetchLegalOptionDataV2List,
    fetchRegionList,
    fetchSearchCompanyCatalogCategoryList,
    fetchSearchCompanyCategoryList,
    fetchSuggesterList,
    fetchTimezoneList,
} from '../api/api';
import {
    AddressType,
    CityV2Type,
    CompanyCatalogCategoryType,
    CompanyCategoryType,
    CountryV2Type,
    IdNameType,
    LegalOptionDataType,
    LegalOptionDataV2Type,
    PaginatedResponseType,
    TimezoneType,
} from '../api/api-type';

import {UseHookType} from './api-hook-type';

export type StateHooksType<DataType, ErrorType> = {
    isMounted: MutableRefObject<boolean>;
    isInProgress: boolean;
    setIsInProgress: (isInProgress: boolean) => void;
    processError: ErrorType | null;
    setProcessError: (error: ErrorType | null) => void;
    result: DataType | null;
    setResult: (result: DataType | null) => void;
    reset: () => void;
    parseError: (error: unknown) => void;
};

export function useApiHooks<DateType, ErrorType = Error>(): StateHooksType<DateType, ErrorType> {
    const isMounted = useIsMounted();

    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [processError, setProcessError] = useState<ErrorType | null>(null);
    const [result, setResult] = useState<DateType | null>(null);

    const reset = useCallback(() => {
        if (!isMounted.current) {
            return;
        }

        setProcessError(null);
        setIsInProgress(false);
        setResult(null);
    }, [isMounted, setProcessError, setIsInProgress, setResult]);

    const parseError = useCallback(
        (error: unknown) => {
            if (!isMounted.current) {
                return;
            }

            if (error instanceof Error) {
                try {
                    setProcessError(JSON.parse(error.message) as ErrorType);
                } catch {
                    logError(new Error(`Couldn't parse error message: ${error}`));
                    setProcessError(null);
                }
            }
        },
        [isMounted]
    );

    const handleSetIsInProgress = useCallback(
        (inProgress: boolean) => {
            if (isMounted.current) {
                setIsInProgress(inProgress);
            }
        },
        [isMounted]
    );

    const handleSetProcessError = useCallback(
        (error: ErrorType | null) => {
            if (isMounted.current) {
                setProcessError(error);
            }
        },
        [isMounted]
    );

    const handleSetResult = useCallback(
        (data: DateType | null) => {
            if (isMounted.current) {
                setResult(data);
            }
        },
        [isMounted]
    );

    return useMemo(() => {
        return {
            isMounted,
            isInProgress,
            setIsInProgress: handleSetIsInProgress,
            processError,
            setProcessError: handleSetProcessError,
            result,
            setResult: handleSetResult,
            reset,
            parseError,
        };
    }, [
        isMounted,
        isInProgress,
        handleSetIsInProgress,
        processError,
        handleSetProcessError,
        result,
        handleSetResult,
        reset,
        parseError,
    ]);
}

type ApiHooksWithFetcherType<TResult, TResponse = TResult> = {
    fetcher: () => (() => Promise<TResponse>) | null;
    mapper?: (response: TResponse) => TResult;
};

export function useApiHooksWithFetcher<TResult, TResponse = TResult>({
    fetcher,
    mapper,
}: ApiHooksWithFetcherType<TResult, TResponse>): UseHookType<TResult> {
    const {
        isInProgress,
        setIsInProgress,
        processError,
        parseError,
        result,
        setResult,
        reset,
        setProcessError,
        isMounted,
    } = useApiHooks<TResult>();

    useEffect(() => {
        async function load() {
            const initializedFetcher = fetcher();

            if (!isMounted.current || !initializedFetcher) {
                return;
            }

            setIsInProgress(true);
            setProcessError(null);

            try {
                const response = await initializedFetcher();

                setResult(mapper ? mapper(response) : (response as unknown as TResult));
            } catch (error) {
                parseError(error);
            } finally {
                setIsInProgress(false);
            }
        }

        load();
    }, [setIsInProgress, parseError, setResult, fetcher, setProcessError, isMounted, mapper]);

    return {isInProgress, processError, result, reset};
}

export function useLegalOptionDataList(shortLocaleName: string): UseHookType<Array<LegalOptionDataType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<LegalOptionDataType>>();

    useEffect(() => {
        setIsInProgress(true);

        fetchLegalOptionDataList(shortLocaleName)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [shortLocaleName, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useLegalOptionDataV2List(): UseHookType<Array<LegalOptionDataV2Type>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<LegalOptionDataV2Type>>();

    useEffect(() => {
        setIsInProgress(true);

        fetchLegalOptionDataV2List()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useSearchCompanyCategoryList(
    searchQuery: string | null
): UseHookType<PaginatedResponseType<CompanyCategoryType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<CompanyCategoryType>>();

    useEffect(() => {
        if (searchQuery === null) {
            reset();
            return;
        }

        setIsInProgress(true);

        fetchSearchCompanyCategoryList(searchQuery)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [searchQuery, setIsInProgress, setProcessError, setResult, reset]);

    return {isInProgress, processError, result, reset};
}

export function useSearchCompanyCatalogCategoryList(
    searchQuery: string | null,
    catalogId: number
): UseHookType<Array<CompanyCatalogCategoryType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CompanyCatalogCategoryType>>();

    useEffect(() => {
        if (searchQuery === null) {
            reset();
            return;
        }

        setIsInProgress(true);

        fetchSearchCompanyCatalogCategoryList(searchQuery, catalogId)
            .then((data) => setResult(data))
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [searchQuery, setIsInProgress, setProcessError, setResult, reset, catalogId]);

    return {isInProgress, processError, result, reset};
}

export function useSearchCountryV2List(searchQuery?: string | null): UseHookType<PaginatedResponseType<CountryV2Type>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<CountryV2Type>>();

    useEffect(() => {
        if (!searchQuery) {
            reset();
            return;
        }

        setIsInProgress(true);

        fetchCountryV2List(searchQuery)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [searchQuery, setIsInProgress, setProcessError, setResult, reset]);

    return {isInProgress, processError, result, reset};
}

export function useSearchRegionList(
    searchQuery: string | null,
    countryId?: number,
    language?: string
): UseHookType<PaginatedResponseType<IdNameType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<IdNameType>>();

    useEffect(() => {
        if (searchQuery === null || !countryId || !language) {
            reset();
            return;
        }

        setIsInProgress(true);

        fetchRegionList(searchQuery, countryId, language)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [searchQuery, setIsInProgress, setProcessError, setResult, reset, countryId, language]);

    return {isInProgress, processError, result, reset};
}

export function useSearchCityList(
    searchQuery: string | null,
    regionId?: number,
    language?: string
): UseHookType<PaginatedResponseType<CityV2Type>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<CityV2Type>>();

    useEffect(() => {
        if (searchQuery === null || !regionId || !language) {
            reset();
            return;
        }

        setIsInProgress(true);

        fetchCityList(searchQuery, regionId, language)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [searchQuery, setIsInProgress, setProcessError, setResult, reset, regionId, language]);

    return {isInProgress, processError, result, reset};
}

export function useSuggesterList(
    searchQuery: string | null,
    countryCode?: string,
    language?: string
): UseHookType<Array<AddressType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<AddressType>>();

    useEffect(() => {
        if (searchQuery === null || !countryCode || !language) {
            reset();
            return;
        }

        setIsInProgress(true);

        fetchSuggesterList(searchQuery, countryCode, language)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [searchQuery, setIsInProgress, setProcessError, setResult, reset, countryCode, language]);

    return {isInProgress, processError, result, reset};
}

export function useTimezoneLibrary(): UseHookType<Array<TimezoneType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<TimezoneType>>();

    useEffect(() => {
        setIsInProgress(true);

        fetchTimezoneList()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
