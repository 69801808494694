import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {mapValues} from 'lodash';
import {z as r} from 'zod';

import {Locale} from '../../provider/locale/locale';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {fetchAndDeserialize, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {companiesImportUrl} from './companies-import-const';
import {companiesImportResultCommonSchema} from './companies-import-result';

const DELAY_SECONDS = 3;

const companiesImportValidationResultSchema = r.object({
    id: r.number().optional(),
    importResultInfo: r.object({
        ...mapValues(companiesImportResultCommonSchema.shape, (value) => value.nullable()),
        newCompanies: r.number().nullable(),
        updatedCompanies: r.number().nullable(),
        companiesUnchanged: r.number().nullable(),
    }),
});

type CompaniesImportValidationResultType = r.infer<typeof companiesImportValidationResultSchema>;

function getCompaniesImportResultUrl(id: number) {
    return `${companiesImportUrl}/import_url/${id}/import_result/`;
}

function fetchCompaniesImportResult(id: number): Promise<CompaniesImportValidationResultType> {
    return fetchAndDeserialize(
        `${getCompaniesImportResultUrl(id)}?${objectToUrlParameters({timestamp: Date.now()})}`,
        companiesImportValidationResultSchema
    );
}

function fetchFeedImportInfo(id: number): Promise<CompaniesImportValidationResultType> {
    return fetchAndDeserialize(`${getCompaniesImportResultUrl(id)}`, companiesImportValidationResultSchema);
}

export function useCompaniesImportResult(
    id: number,
    enabled: boolean,
    allCompaniesCount: number
): UseQueryResult<CompaniesImportValidationResultType> {
    return useQuery([getCompaniesImportResultUrl(id)], () => fetchCompaniesImportResult(id), {
        enabled,
        refetchInterval: (data) =>
            !data?.id || allCompaniesCount !== data.importResultInfo.processedCount ? DELAY_SECONDS * 1000 : false,
    });
}

export function useFeedImportInfo(id: number): UseQueryResult<CompaniesImportValidationResultType> {
    return useQuery([getCompaniesImportResultUrl(id)], () => fetchFeedImportInfo(id));
}

function getDeleteUserUrl(id: number) {
    return `${rootApiUrl}${companiesImportUrl}/import_url/${id}/`;
}

function deleteFeed(id: number): Promise<void> {
    return fetchNoContent(getDeleteUserUrl(id), {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize({id})),
    });
}

export function useDeleteFeedMutation(
    refetch: () => void,
    setIsOpen: (isOpen: boolean) => void
): UseMutationResult<void | Array<void>, unknown, number, unknown> {
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    function mutate(id: number): Promise<void | Array<void>> {
        return new Promise((resolve) =>
            modal.confirm({
                title: <Locale stringKey="FEED__MANAGEMENT__DRAWER__MODAL__DELETE_FEED" />,
                content: <Locale stringKey="FEED__MANAGEMENT__DRAWER__MODAL__DELETE_FEED__CONTENT" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                okText: <Locale stringKey="BUTTON__DELETE" />,
                okType: 'danger',
                onOk: () => {
                    resolve(deleteFeed(id));
                },
            })
        );
    }

    function onSuccess() {
        setIsOpen(false);
        refetch();
        snackbar.success({
            message: <Locale stringKey="FEED__MANAGEMENT__DRAWER__CONTENT__DELETE_FEED__SUCCESS" />,
            description: <Locale stringKey="FEED__MANAGEMENT__DRAWER__CONTENT__DELETE_FEED__SUCCESS__DESCRIPTION" />,
        });
    }

    function onError() {
        refetch();
        snackbar.error({
            message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
        });
    }

    return useMutation(mutate, {onSuccess, onError});
}
