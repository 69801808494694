import {Table} from 'antd';
import {ColumnFilterItem} from 'antd/es/table/interface';
import {useCallback, useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {TableFilterDropdown} from '../../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {Trend} from '../../../../../layout/trend/trend';
import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {AverageResponseTimeByCatalogType} from '../../../../../service/reviews-and-answers-statistics/average-response-time-by-catalog/average-response-time-by-catalog-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {ReviewsCountByCatalogType} from '../../../../../service/reviews-and-answers-statistics/reviews-count-by-catalog/reviews-count-by-catalog-type';
import {useAverageResponseTableSort} from '../average-response-table-sort-hook/average-response-table-sort-hook';
import {AverageResponseTimeTable} from '../average-response-time-table/average-response-time-table';

import * as styles from './catalogs-stats-table.scss';

type AverageResponseTimeByCatalogTableItemType = AverageResponseTimeByCatalogType & {
    reviewsCount: {
        value: number | null;
        comparedValue: number | null;
        delta: number | null;
    };
};

type PropsType = {
    pdfPreview?: boolean;
    averageResponseTimeByCatalog: Array<AverageResponseTimeByCatalogType>;
    reviewsCountByCatalog: ReviewsCountByCatalogType | null;
    isLoading?: boolean;
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;
    isCompareMode: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity,complexity
export function CatalogsStatsTable(props: PropsType): JSX.Element {
    const {pdfPreview, averageResponseTimeByCatalog, isLoading, timeUnit, isCompareMode, reviewsCountByCatalog} = props;

    const getRowKey = useCallback((record: AverageResponseTimeByCatalogType) => String(record.catalog.pk), []);

    const {getCatalogName} = useCatalogInfo();

    const catalogFilters: Array<ColumnFilterItem> = useMemo(() => {
        return averageResponseTimeByCatalog.map((item) => {
            return {
                text: getCatalogName(item.catalog.pk),
                value: getRowKey(item),
            };
        });
    }, [averageResponseTimeByCatalog, getCatalogName, getRowKey]);

    const dataSource = useMemo(() => {
        return (
            averageResponseTimeByCatalog?.map((item) => {
                const catalogReviewsCount = reviewsCountByCatalog?.byCatalogs.find(
                    (catalogStats) => catalogStats.pk === item.catalog.pk
                );

                return {
                    ...item,
                    reviewsCount: {
                        value: catalogReviewsCount?.count?.value || null,
                        comparedValue: catalogReviewsCount?.count?.comparedValue || null,
                        delta: catalogReviewsCount?.count?.delta || null,
                    },
                };
            }) || []
        );
    }, [averageResponseTimeByCatalog, reviewsCountByCatalog]);

    const {getSortOrder, setSorterOrder} = useAverageResponseTableSort();

    return (
        <AverageResponseTimeTable<AverageResponseTimeByCatalogTableItemType>
            dataSource={dataSource}
            getSortOrder={getSortOrder}
            isCompareMode={isCompareMode}
            isLoading={isLoading}
            pdfPreview={pdfPreview}
            rowKey={getRowKey}
            setSorterState={setSorterOrder}
            timeUnit={timeUnit}
            width={800}
        >
            <Table.Column<AverageResponseTimeByCatalogType>
                // eslint-disable-next-line no-undefined
                filterDropdown={pdfPreview ? undefined : TableFilterDropdown}
                filters={pdfPreview ? [] : catalogFilters}
                onFilter={(recordKey, record) => getRowKey(record) === recordKey}
                render={(_value, record) => {
                    return <div>{getCatalogName(record.catalog.pk)}</div>;
                }}
                title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__CATALOG" />}
            />

            {isCompareMode ? (
                <>
                    <Table.ColumnGroup
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__REVIEWS" />}
                    >
                        <Table.Column<AverageResponseTimeByCatalogTableItemType>
                            align="right"
                            className={styles.CatalogStatsTable_column}
                            dataIndex="reviewsCountValue"
                            render={(_value, record) => {
                                return <Text block>{record.reviewsCount.value}</Text>;
                            }}
                            sortOrder={getSortOrder('reviewsCountValue')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.reviewsCount.value || 0) - (record2.reviewsCount.value || 0)
                            }
                            title="A"
                        />

                        <Table.Column<AverageResponseTimeByCatalogTableItemType>
                            align="center"
                            dataIndex="reviewsCountDelta"
                            render={(_value, record) => {
                                return <Trend delta={record.reviewsCount.delta || 0} />;
                            }}
                            sortOrder={getSortOrder('reviewsCountDelta')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.reviewsCount.delta || 0) - (record2.reviewsCount.delta || 0)
                            }
                            title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                        />

                        <Table.Column<AverageResponseTimeByCatalogTableItemType>
                            className={styles.CatalogStatsTable_column}
                            dataIndex="reviewsCountComparedValue"
                            render={(_value, record) => {
                                return <Text block>{record.reviewsCount.comparedValue}</Text>;
                            }}
                            sortOrder={getSortOrder('reviewsCountComparedValue')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.reviewsCount.comparedValue ?? 0) -
                                          (record2.reviewsCount.comparedValue ?? 0)
                            }
                            title="B"
                        />
                    </Table.ColumnGroup>
                    <Table.ColumnGroup
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__ABUSES" />}
                    >
                        <Table.Column<AverageResponseTimeByCatalogType>
                            align="right"
                            dataIndex="abusesCountValue"
                            render={(_value, record) => {
                                return <Text block>{record.abusesCount.value}</Text>;
                            }}
                            sortOrder={getSortOrder('abusesCountValue')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.abusesCount.value || 0) - (record2.abusesCount.value || 0)
                            }
                            title="A"
                        />

                        <Table.Column<AverageResponseTimeByCatalogType>
                            align="center"
                            dataIndex="abusesCountDelta"
                            render={(_value, record) => {
                                return <Trend delta={record.abusesCount.delta || 0} />;
                            }}
                            sortOrder={getSortOrder('abusesCountDelta')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.abusesCount.delta || 0) - (record2.abusesCount.delta || 0)
                            }
                            title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                        />

                        <Table.Column<AverageResponseTimeByCatalogType>
                            dataIndex="abusesCountComparedValue"
                            render={(_value, record) => {
                                return <Text block>{record.abusesCount.comparedValue}</Text>;
                            }}
                            sortOrder={getSortOrder('abusesCountComparedValue')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.abusesCount.comparedValue ?? 0) -
                                          (record2.abusesCount.comparedValue ?? 0)
                            }
                            title="B"
                        />
                    </Table.ColumnGroup>

                    <Table.ColumnGroup
                        title={
                            <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELETED_REVIEWS" />
                        }
                    >
                        <Table.Column<AverageResponseTimeByCatalogType>
                            align="right"
                            dataIndex="deletedReviewsCountValue"
                            render={(_value, record) => {
                                return <Text block>{record.deletedReviewsCount.value}</Text>;
                            }}
                            sortOrder={getSortOrder('deletedReviewsCountValue')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.deletedReviewsCount.value || 0) -
                                          (record2.deletedReviewsCount.value || 0)
                            }
                            title="A"
                        />

                        <Table.Column<AverageResponseTimeByCatalogType>
                            align="center"
                            dataIndex="deletedReviewsCountDelta"
                            render={(_value, record) => {
                                return <Trend delta={record.deletedReviewsCount.delta || 0} />;
                            }}
                            sortOrder={getSortOrder('deletedReviewsCountDelta')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.deletedReviewsCount.delta || 0) -
                                          (record2.deletedReviewsCount.delta || 0)
                            }
                            title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                        />

                        <Table.Column<AverageResponseTimeByCatalogType>
                            dataIndex="deletedReviewsCountComparedValue"
                            render={(_value, record) => {
                                return <Text block>{record.deletedReviewsCount.comparedValue}</Text>;
                            }}
                            sortOrder={getSortOrder('deletedReviewsCountComparedValue')}
                            sorter={
                                pdfPreview
                                    ? false
                                    : (record1, record2) =>
                                          (record1.deletedReviewsCount.comparedValue ?? 0) -
                                          (record2.deletedReviewsCount.comparedValue ?? 0)
                            }
                            title="B"
                        />
                    </Table.ColumnGroup>
                </>
            ) : (
                <>
                    <Table.Column<AverageResponseTimeByCatalogTableItemType>
                        align="center"
                        render={(_value, record) => {
                            return <Text block>{record.reviewsCount.value}</Text>;
                        }}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.reviewsCount.value || 0) - (record2.reviewsCount.value || 0)
                        }
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__REVIEWS" />}
                    />

                    <Table.Column<AverageResponseTimeByCatalogType>
                        align="center"
                        render={(_value, record) => {
                            return <Text block>{record.abusesCount.value}</Text>;
                        }}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.abusesCount.value || 0) - (record2.abusesCount.value || 0)
                        }
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__ABUSES" />}
                    />

                    <Table.Column<AverageResponseTimeByCatalogType>
                        align="center"
                        render={(_value, record) => {
                            return <Text block>{record.deletedReviewsCount.value}</Text>;
                        }}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.deletedReviewsCount.value || 0) -
                                      (record2.deletedReviewsCount.value || 0)
                        }
                        title={
                            <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELETED_REVIEWS" />
                        }
                    />
                </>
            )}
        </AverageResponseTimeTable>
    );
}
