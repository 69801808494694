import {faPenToSquare, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faStickyNote} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useState} from 'react';

import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {fetchNotesDelete, ReviewNoteUserType} from '../../../../../service/reviews/reviews-notes';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {NoteForm} from '../note-form/note-form';

import * as styles from './note.scss';

type PropsType = {
    id: number;
    reviewId: number;
    text: string;
    createdAt: string;
    updatedAt?: string | null;
    user: ReviewNoteUserType;
    onNoteEdit?: () => void;
    isLogsMode?: boolean;
};

export function Note(props: PropsType): JSX.Element | null {
    const {id, reviewId, text, createdAt, updatedAt, user, onNoteEdit, isLogsMode} = props;
    const {getFormattedDateTime} = useFormat();
    const {modal} = useModal();

    const {companyName} = useDomainConfig();

    const formattedTime = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;
    const formattedDate = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          })
        : null;

    const {snackbar} = useSnackbar();

    const [isNoteEditing, setIsNoteEditing] = useState(false);

    function handleEditClick() {
        setIsNoteEditing((previous) => !previous);
    }

    async function deleteNote() {
        try {
            await fetchNotesDelete(reviewId, id);

            if (onNoteEdit) {
                await onNoteEdit();
            }

            snackbar.success({
                description: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_SUCCESS_DESCRIPTION" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_SUCCESS_TITLE" />,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.DeleteNote);
        } catch {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_FAIL" />,
            });
        }
    }

    function handleDeleteClick() {
        modal.confirm({
            className: styles.Note_modal,
            title: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_TITLE" />,
            content: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_QUESTION" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            okText: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_BUTTON" />,
            okButtonProps: {
                danger: true,
                type: 'primary',
            },
            onOk: deleteNote,
        });
    }

    function onEditSuccess() {
        setIsNoteEditing(false);

        if (onNoteEdit) {
            onNoteEdit();
        }
    }

    function onEditCancel() {
        setIsNoteEditing(false);
    }

    return (
        <div className={styles.Note}>
            <header className={styles.Note_header}>
                <span className={styles.Note_title}>
                    <FontAwesomeIcon className={styles.Note_icon} icon={faStickyNote} />
                    <Locale stringKey="REVIEWS__ENTER_NOTE_TITLE" />
                    {isNoteEditing && (
                        <span className={styles.Note_status}>
                            <Locale stringKey="REVIEWS__ENTER_NOTE_EDITING" />
                        </span>
                    )}
                    {updatedAt && !isNoteEditing && (
                        <span className={styles.Note_status}>
                            <Locale stringKey="REVIEWS__ENTER_NOTE_EDITED" />
                        </span>
                    )}
                </span>
                <span className={styles.Note_date}>
                    {formattedTime} {formattedDate}
                </span>
            </header>
            <p className={styles.Note_text}>{text}</p>
            <footer className={styles.Note_footer}>
                <span className={styles.Note_user}>
                    <Locale
                        stringKey="REVIEWS__RESPONSE_EMPLOYEE"
                        valueMap={{
                            name: <span className={styles.Note_email}>{user?.email || companyName}</span>,
                        }}
                    />
                </span>
                {!isLogsMode && (
                    <div className={styles.Note_buttonWrapper}>
                        <Button
                            className={styles.Note_button}
                            icon={<FontAwesomeIcon icon={faPenToSquare} />}
                            onClick={handleEditClick}
                            type="link"
                        />
                        <Button
                            className={styles.Note_button}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={handleDeleteClick}
                            type="link"
                        />
                    </div>
                )}
            </footer>
            {isNoteEditing && !isLogsMode && (
                <div className={styles.Note_editForm}>
                    <NoteForm
                        editableNoteId={id}
                        editableNoteText={text}
                        onCancel={onEditCancel}
                        onSave={onEditSuccess}
                        reviewId={reviewId}
                    />
                </div>
            )}
        </div>
    );
}
