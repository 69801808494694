import {isEqual} from 'lodash';

import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyErrorType, CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {CompanyWorkingTimeType} from '../../../../../../service/company-v2/types/company-working-time-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabWorkingTimeIndex} from '../../catalog-list/catalog-list-helper';
import {CatalogTab} from '../../catalog-list/catalog-tab';
import {initialCompanyValues} from '../../create/create-company-form-const';
import {ErrorList} from '../../error-list/error-list';

import {WorkdaysFormItem} from './workdays-form-item/workdays-form-item';
import {WorkingTimeFormItem} from './working-time-form-item/working-time-form-item';

type PropsType = {
    name: string;
    initialCatalogs: Array<number>;
    errorMessage: CompanyErrorType | null;
    isDoubleGisCompany: boolean;
};

export function WorkingTimeWidget(props: PropsType): JSX.Element {
    const {name, initialCatalogs, errorMessage, isDoubleGisCompany} = props;

    const {getLocalizedString} = useLocale();

    return (
        <>
            <WorkingTimeFormItem errorMessage={errorMessage} isDoubleGisCompany={isDoubleGisCompany} />

            <Form.Item<CompanyFormType> dependencies={[CompanyKeyEnum.WorkingHours]} noStyle>
                {({getFieldValue, setFieldsValue}) => (
                    <CatalogList
                        activeTabIndex={getErrorTabWorkingTimeIndex(
                            errorMessage,
                            getFieldValue(CompanyKeyEnum.ExtraWorkingHours) &&
                                Object.values(getFieldValue(CompanyKeyEnum.ExtraWorkingHours)).map(
                                    (mappedItem) => mappedItem.catalogId
                                )
                        )}
                        disabled={Object.values(getFieldValue(CompanyKeyEnum.WorkingHours) || {}).every(
                            (value) => !value
                        )}
                        initialCatalogs={initialCatalogs}
                        isCatalogVisible={({canRewriteWorkingHours}) => canRewriteWorkingHours}
                        onAddCatalogs={(catalogs: Array<CatalogConfigType>) => {
                            const catalogIds = new Set(catalogs.map(({catalogId}) => catalogId));

                            function sortExtraWorkingHours(
                                extraWorkingHours: Array<CompanyWorkingTimeType['extraWorkingHours'][number]>
                            ) {
                                return extraWorkingHours.sort((firstCatalog, secondCatalog) => {
                                    if (isEqual(firstCatalog.workingHours, initialCompanyValues.workingHours)) {
                                        return 1;
                                    } else if (isEqual(secondCatalog.workingHours, initialCompanyValues.workingHours)) {
                                        return -1;
                                    }

                                    return 0;
                                });
                            }

                            setFieldsValue({
                                extraWorkingHours: sortExtraWorkingHours([
                                    ...(getFieldValue(CompanyKeyEnum.ExtraWorkingHours) || []).filter(
                                        ({catalogId}) => !catalogIds.has(catalogId)
                                    ),
                                    ...catalogs.map(({catalogId}) => ({
                                        catalogId,
                                        workingHours: getFieldValue(CompanyKeyEnum.WorkingHours),
                                    })),
                                ]),
                            });
                        }}
                        onCatalogAvailabilityChange={(availableCatalogIds: Array<number>) => {
                            setFieldsValue({
                                extraWorkingHours: getFieldValue(CompanyKeyEnum.ExtraWorkingHours).filter(
                                    ({catalogId}) => availableCatalogIds.includes(catalogId)
                                ),
                            });
                        }}
                        renderCatalog={(catalog: CatalogConfigWithIndexType, {handleRemoveCatalog}) => {
                            return (
                                <Form.List name={CompanyKeyEnum.ExtraWorkingHours}>
                                    {(_fields, {remove}) => (
                                        <CatalogTab
                                            catalog={catalog}
                                            modalFieldName={name}
                                            onRemove={(index: number) => {
                                                remove(index);
                                                handleRemoveCatalog(catalog.catalogId);
                                            }}
                                        >
                                            <Form.Item name={[catalog.index, 'workingHours']}>
                                                <WorkdaysFormItem
                                                    catalogId={catalog.catalogId}
                                                    errorMessage={
                                                        Array.isArray(errorMessage?.extraWorkingHours)
                                                            ? null
                                                            : errorMessage?.extraWorkingHours?.[catalog.catalogId]
                                                    }
                                                    isDoubleGisCompany={isDoubleGisCompany}
                                                />
                                            </Form.Item>
                                        </CatalogTab>
                                    )}
                                </Form.List>
                            );
                        }}
                        title={getLocalizedString('COMPANY_FORM__WORKING_TIME__CATALOGS_HEADER')}
                    />
                )}
            </Form.Item>

            <ErrorList name={CompanyKeyEnum.ExtraWorkingHours} />
        </>
    );
}
