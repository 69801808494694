import {Divider, Empty} from 'antd';
import {useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useAllCPVisibleCatalogs} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLastReviews} from '../../../../service/dashboard/dashboard-hook';
import {ReviewsBasePaginationType} from '../../../../service/dashboard/dashboard-type';
import {ReviewDataType} from '../../../../service/reviews/reviews-type';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {DashboardReviewsItem} from './reviews-item/reviews-item';
import {DashboardReviewStatsCard} from './stat-card/stat-card';
import {numberOfDays} from './stat-card/stat-card-consts';
import * as styles from './reviews.scss';

export function DashboardReviews(): JSX.Element {
    const dateGte = useMemo(() => {
        return new Date();
    }, []);

    dateGte.setDate(dateGte.getDate() - numberOfDays);

    const paginationData: ReviewsBasePaginationType = {pageSize: 5, dateGte};
    const {result: reviewsResult, processError, isInProgress} = useLastReviews(paginationData);

    const {result: reviewsCatalogList} = useAllCPVisibleCatalogs();

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !reviewsResult) {
        return <Spinner />;
    }

    const resultsLength = reviewsResult.results.length;

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__REVIEWS_TITLE" />}
                    widget={DashboardWidgetEnum.LastReviews}
                />
            }
        >
            <DashboardReviewStatsCard />
            {resultsLength > 0 ? (
                <ul className={styles.review_list}>
                    {reviewsResult.results.map((dataEntry: ReviewDataType, index) => {
                        const isLast = resultsLength === index + 1;

                        return (
                            <li className={styles.review_list_item} key={dataEntry.id}>
                                <DashboardReviewsItem
                                    catalogData={reviewsCatalogList?.get(dataEntry.catalogId)}
                                    reviewData={dataEntry}
                                />
                                {!isLast && <Divider className={styles.reviews__with_answers__divider} />}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <Empty />
            )}
            <p className={styles.review__footer__text}>
                <Locale stringKey="DASHBOARD_PAGE__REVIEWS__FOOTER_TEXT" />
                <NavigationLink className={styles.review__link} to={appRoute.reviewsManagementReviews.path}>
                    <Locale stringKey="DASHBOARD_PAGE__REVIEWS__FOOTER_LINK_TEXT" />
                </NavigationLink>
            </p>
        </PageCard>
    );
}
