import {faCaretDown, faCaretUp, faCircleSmall} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {classNames} from '../../util/css';
import {useFormat} from '../../util/format-hook/format-hook';

import * as styles from './trend.scss';

type PropsType = {
    delta: number;
    unit?: string;
    reverse?: boolean;
};

export function Trend(props: PropsType): JSX.Element {
    const {delta, reverse = false, unit = '%'} = props;

    const {getFormattedNumber} = useFormat();

    switch (true) {
        case delta === 0: {
            return (
                <div className={classNames(styles.Trend__unchanged, styles.Trend)}>
                    <div className={styles.Trend_icon}>
                        <FontAwesomeIcon icon={faCircleSmall} />
                    </div>
                    <div>0{unit}</div>
                </div>
            );
        }
        case delta > 0: {
            return (
                <div
                    className={classNames(styles.Trend, {
                        [styles.Trend__positive]: !reverse,
                        [styles.Trend__negative]: reverse,
                    })}
                >
                    <FontAwesomeIcon icon={faCaretUp} />

                    {`${getFormattedNumber(delta, {
                        maximumFractionDigits: 1,
                    })}${unit}`}
                </div>
            );
        }
        default: {
            return (
                <div
                    className={classNames(styles.Trend, {
                        [styles.Trend__negative]: !reverse,
                        [styles.Trend__positive]: reverse,
                    })}
                >
                    <FontAwesomeIcon icon={faCaretDown} />

                    {`${getFormattedNumber(Math.abs(delta), {
                        maximumFractionDigits: 1,
                    })}${unit}`}
                </div>
            );
        }
    }
}
