import {QueryObserverBaseResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {PaginationType} from '../../../util/pagination-hook/pagination-hook-type';

export enum GoogleAccountTypeEnum {
    Personal = 'PERSONAL',
    LocationGroup = 'LOCATION_GROUP',
    UserGroup = 'USER_GROUP',
    Organization = 'ORGANIZATION',
    Unspecified = 'ACCOUNT_TYPE_UNSPECIFIED',
}

export enum GoogleAccountRoleEnum {
    PrimaryOwner = 'PRIMARY_OWNER',
    Owner = 'OWNER',
    Manager = 'MANAGER',
    SiteManager = 'SITE_MANAGER',
    Unspecified = 'ACCOUNT_ROLE_UNSPECIFIED',
}

export enum GoogleVerificationStateEnum {
    Verified = 'VERIFIED',
    NotVerified = 'UNVERIFIED',
    VerificationRequested = 'VERIFICATION_REQUESTED',
}

export const googleAccountSchema = r.object({
    id: r.number(),
    name: r.string(),
    accountType: r.nativeEnum(GoogleAccountTypeEnum).nullable(),
    role: r.nativeEnum(GoogleAccountRoleEnum).nullable(),
    verificationState: r.nativeEnum(GoogleVerificationStateEnum).nullable(),
    hasProblem: r.boolean(),
});

export type GoogleAccountType = r.infer<typeof googleAccountSchema>;

export const googleAccountsSchema = r.object({
    offset: r.number(),
    total: r.number(),
    accounts: r.array(googleAccountSchema),
});

export type GoogleAccountsType = r.infer<typeof googleAccountsSchema>;

export type GoogleAccountsOptionsType = {
    offset?: number;
    limit?: number;
    brandId?: number;
    parentId?: number;
    accountType?: GoogleAccountTypeEnum;
    search?: string;
};

export type GooglePersonalAccountsHookReturnType = {
    personalAccounts: Partial<GoogleAccountsType> &
        Pick<QueryObserverBaseResult<GoogleAccountsType>, 'refetch' | 'isInitialLoading'> & {total: number};
};

export type GoogleAccountsHookOptionsType = {
    personalAccountId: number | null;
    accountType?: GoogleAccountTypeEnum;
    search?: string;
};

export type GoogleAccountsHookReturnType = GooglePersonalAccountsHookReturnType & {
    selectedPersonalAccount?: GoogleAccountType;
    accounts: Partial<GoogleAccountsType> &
        PaginationType &
        Pick<QueryObserverBaseResult<GoogleAccountsType>, 'isInitialLoading'>;
};
