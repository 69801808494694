import {ReactNode} from 'react';

import {getPlural} from '../../util/plural';

import {useLocale} from './locale-hook';
import {Locale} from './localization';
import {LangKeyType} from './translation/type';

type PropsType = {
    count: number;
    singularKey: LangKeyType;
    fewKey?: LangKeyType;
    manyKey: LangKeyType;
    valueMap?: Record<string, ReactNode>;
};

export function LocalePlural(props: PropsType): JSX.Element {
    const {count, singularKey, fewKey, manyKey, valueMap} = props;

    const {localeName} = useLocale();

    return getPlural<JSX.Element>({
        count,
        localeName,
        singular: <Locale stringKey={singularKey} valueMap={valueMap} />,
        pluralFew: <Locale stringKey={fewKey || manyKey} valueMap={valueMap} />,
        pluralMany: <Locale stringKey={manyKey} valueMap={valueMap} />,
    });
}
