import {castArray} from 'lodash';

import {deserializeV2, fetchAndDeserialize, getUrlParameters, serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {generateResponseSchema, PaginatedResponseType, RequestOptionsType} from '../api/api-type';
import {FeaturesEnum} from '../user/user-type';

import {
    allCompaniesSchema,
    AllCompanyType,
    AvailableForUpdateCompaniesCountType,
    BulkUpdateCompaniesFilterType,
    BulkUpdateCompaniesParametersType,
    BulkUpdateCompaniesQueryType,
    CompanyAdditionalInfoType,
    CompanyContactsInfoType,
    CompanyGeneralInfoType,
    CompanyMediaFileType,
    companyShortSchema,
    CompanyShortType,
    CompanyType,
    CompanyUploadMediaFileParamteresType,
} from './company-type';

export function getCompaniesUrl(
    option: RequestOptionsType & {ordering?: string},
    filterKey: string,
    optionalParameters: {
        isActive?: boolean;
        newCompanies?: boolean;
        yandexActualizationHasError?: string | null;
        accessTransferRequired?: string | null;
    },
    catalogFilters?: Array<{[p: string]: string}>
): string {
    return `/v2/companies/list_with_statistic/?${objectToUrlParameters(
        serializeToURLParameters({
            ...option,
            filterKey,
            ...optionalParameters,
            ...catalogFilters?.reduce((accumulator, current) => ({...accumulator, ...current}), {}),
        })
    )}`;
}

export async function getCompanies(
    option: RequestOptionsType & {ordering?: string},
    mainFilterKey: string,
    optionalParameters: {
        isActive?: boolean;
        newCompanies?: boolean;
        yandexActualizationHasError?: string | null;
        accessTransferRequired?: string | null;
    },
    catalogFilters?: Array<{[p: string]: string}>
): Promise<PaginatedResponseType<CompanyShortType>> {
    return fetchAndDeserialize(
        getCompaniesUrl(option, mainFilterKey, optionalParameters, catalogFilters),
        generateResponseSchema(companyShortSchema)
    );
}

export async function getAllCompanies(
    isActive?: boolean,
    featuresToCheck?: FeaturesEnum | Array<FeaturesEnum>
): Promise<Array<AllCompanyType>> {
    const url =
        rootApiUrl +
        '/v1/companies/?' +
        objectToUrlParameters({
            all: true,
            fast: true,
            is_active: isActive,
            features: castArray(featuresToCheck),
        });
    const response = await fetchX(url);

    return deserializeV2<Array<AllCompanyType>>(url, allCompaniesSchema, response);
}

export function getCompany(id: number): Promise<CompanyType> {
    return fetchX<CompanyType>(rootApiUrl + `/v1/companies/${id}/`);
}

// we create company only one first step of the form, then we always update  it
export function createCompany(generalInfo: CompanyGeneralInfoType): Promise<CompanyType> {
    return fetchX<CompanyType>(rootApiUrl + '/v1/companies/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(generalInfo),
    });
}

export function updateCompany(
    id: number,
    info: CompanyGeneralInfoType | CompanyContactsInfoType | CompanyAdditionalInfoType
): Promise<CompanyType> {
    return fetchX<CompanyType>(rootApiUrl + `/v1/companies/${id}/`, {
        method: FetchMethodEnum.patch,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(info),
    });
}

export function bulkUpdateCompanies(
    parameters: BulkUpdateCompaniesParametersType,
    options: BulkUpdateCompaniesQueryType
): Promise<void> {
    return fetchNoContent(
        rootApiUrl +
            `/v2/companies/batch_update/${getUrlParameters({...options, newCompanies: options.newCompanies || null})}`,
        {
            method: FetchMethodEnum.patch,
            headers: {...mainApiHeaders, ...getCsrfHeaders()},
            body: JSON.stringify(parameters),
        }
    );
}

export function getAvailableForUpdateCompaniesCount(
    parameters: BulkUpdateCompaniesFilterType,
    options: BulkUpdateCompaniesQueryType
): Promise<AvailableForUpdateCompaniesCountType> {
    return fetchX(rootApiUrl + `/v2/companies/filter_batch_update/${getUrlParameters(options)}`, {
        method: FetchMethodEnum.patch,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(parameters),
    });
}

export function uploadCompanyMediaFile(options: CompanyUploadMediaFileParamteresType): Promise<CompanyMediaFileType> {
    const formData = new FormData();

    formData.append('file_type', options.file_type);
    formData.append('file_s3', options.file);

    if (options.catalogId) {
        formData.append('catalog_id', String(options.catalogId));
    }

    return fetchX<CompanyMediaFileType>(rootApiUrl + '/v2/companies/media_file/', {
        method: FetchMethodEnum.post,
        headers: getCsrfHeaders(),
        body: formData,
    });
}
