import {LangKeyType} from '../../provider/locale/translation/type';

export enum CclActionThumbStateNameEnum {
    synchronized = 'synchronized',
    sent = 'sent',
    actionRequired = 'action-required',
    confirmation = 'confirmation',
}

export type CclActionThumbUiDataType = {
    image: JSX.Element;
    className: string;
    langKey: LangKeyType;
};
