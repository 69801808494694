import {CheckboxOptionType} from 'antd';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';

import {Locale} from '../../../../provider/locale/localization';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {NotificationTextReviewEnum} from '../../../../service/notifications-settings/notifications-settings-type';

import {UsedeskFieldNamesEnum, UsedeskFieldsType, UsedeskSettingsFormOptionsEnum} from './integration-usedesk-type';

export const USEDESK_SETTINGS_FORM_OPTIONS: Array<CheckboxOptionType> = [
    {
        value: UsedeskSettingsFormOptionsEnum.general,
        label: <Locale stringKey="INTEGRATION__USEDESK__SETTINGS_OPTION__GENERAL" />,
    },
    {
        value: UsedeskSettingsFormOptionsEnum.notifications,
        label: <Locale stringKey="INTEGRATION__USEDESK__SETTINGS_OPTION__NOTIFICATIONS" />,
    },
];

export const initialValues: UsedeskFieldsType = {
    [UsedeskFieldNamesEnum.secretKey]: '',
    [UsedeskFieldNamesEnum.applicationId]: '',
    [UsedeskFieldNamesEnum.chatId]: '',
    [UsedeskFieldNamesEnum.webhook]: '',
    [UsedeskFieldNamesEnum.catalog]: [],
    [UsedeskFieldNamesEnum.ratings]: [],
    [UsedeskFieldNamesEnum.type]: NotificationTextReviewEnum.all,
};

export const ERRORS_CHECKER_BY_TAB = {
    [UsedeskSettingsFormOptionsEnum.general]: (
        errors: ValidateErrorEntity<UsedeskFieldsType>['errorFields']
    ): boolean => {
        const fields = new Set([
            UsedeskFieldNamesEnum.catalog,
            UsedeskFieldNamesEnum.ratings,
            UsedeskFieldNamesEnum.type,
        ]);

        return errors.some((error) => fields.has(String(error.name) as UsedeskFieldNamesEnum));
    },
    [UsedeskSettingsFormOptionsEnum.notifications]: (
        errors: ValidateErrorEntity<UsedeskFieldsType>['errorFields']
    ): boolean => {
        const fields = new Set([
            UsedeskFieldNamesEnum.secretKey,
            UsedeskFieldNamesEnum.applicationId,
            UsedeskFieldNamesEnum.chatId,
        ]);

        return errors.some((error) => fields.has(String(error.name) as UsedeskFieldNamesEnum));
    },
};

export const SNACKBAR_MESSAGE_BY_TAB: Record<UsedeskSettingsFormOptionsEnum, LangKeyType> = {
    [UsedeskSettingsFormOptionsEnum.general]: 'INTEGRATIONS__USEDESK__VALIDATION_ERROR__NOTIFICATIONS',
    [UsedeskSettingsFormOptionsEnum.notifications]: 'INTEGRATIONS__USEDESK__VALIDATION_ERROR__GENERAL',
};
