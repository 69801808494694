import {Button} from 'antd';
import {useCallback, useEffect, useState} from 'react';

import {localeNameToShortLocaleNameMap} from '../../provider/locale/locale-context-const';
import {setIsAuthLanguageFromSessionStorage} from '../../provider/locale/locale-context-helper';
import {LocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useUser} from '../../provider/user/user-hook';
import {AnalyticsTarget, track} from '../../service/analytics/analytics';

import * as styles from './change-language-hook.scss';

export function useChangeLanguage(): {onChangeLanguage: (newLocaleName: LocaleNameEnum) => void} {
    const [hasSnackbar, setHasSnackbar] = useState(false);

    const {snackbar} = useSnackbar();
    const {getLocalizedString, shortLocaleName, isFetchingLocaleData, localeName} = useLocale();
    const {user, setLocaleNameByUser} = useUser();

    const onChangeLanguage = useCallback(
        (isOpen: boolean) => {
            setHasSnackbar(isOpen);
        },
        [setHasSnackbar]
    );

    function handleClick(newLocaleName: LocaleNameEnum) {
        if (!user) {
            setIsAuthLanguageFromSessionStorage(true);
        }

        if (newLocaleName !== localeName) {
            setLocaleNameByUser(newLocaleName);
            onChangeLanguage(true);
            track(AnalyticsTarget.PersonalCabinet.Language, localeNameToShortLocaleNameMap[newLocaleName]);
        }
    }

    useEffect(() => {
        if (!isFetchingLocaleData && hasSnackbar) {
            snackbar.success({
                message: getLocalizedString('SNACKBAR__LANGUAGE'),
                description: (
                    <div className={styles.ChangeLanguageSnackbar_content}>
                        {getLocalizedString('SNACKBAR__LANGUAGE__DESCRIPTION')}
                        <Button onClick={() => window.location.reload()} size="small" type="primary">
                            {getLocalizedString('BUTTON__REFRESH')}
                        </Button>
                    </div>
                ),
                key: shortLocaleName,
                className: styles.ChangeLanguageSnackbar,
            });
        }
    }, [shortLocaleName, isFetchingLocaleData, hasSnackbar, snackbar, getLocalizedString]);

    return {
        onChangeLanguage: handleClick,
    };
}
