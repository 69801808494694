import {LangKeyType} from '../../../../provider/locale/translation/type';

export enum PostStepEnum {
    Target = 'target',
    CommonContent = 'commonContent',
    AdaptiveContent = 'adaptiveContent',
    SendParameters = 'sendParameters',
}

export type PostFormStepType = {
    titleKey: LangKeyType;
    stepName: PostStepEnum;
};

export type PostFormItemPropsType = {
    disabled: boolean;
};
