import {lazy, Suspense} from 'react';

import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {Spinner} from '../../../layout/spinner/spinner';

const ReviewsGeneratorRouter = lazy(() => import('./reviews-generator-router'));

export function ReviewsGeneratorIndex(): JSX.Element {
    return (
        <Suspense
            fallback={
                <Page
                    isTopFilterHidden
                    renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}
                >
                    <Spinner position="absolute" />
                </Page>
            }
        >
            <ReviewsGeneratorRouter />
        </Suspense>
    );
}
