import {Context} from 'chartjs-plugin-datalabels';

import {ChartTooltip} from '../../../../../../../../component/chart-tooltip/chart-tooltip';
import {ChartTooltipDivider} from '../../../../../../../../component/chart-tooltip/chart-tooltip-divider';
import {getCompareColor} from '../../../../../../../../component/chart-tooltip/chart-tooltip-helper';
import {ChartTooltipRow} from '../../../../../../../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../../../../../../../component/chart-tooltip/chart-tooltip-title';
import {Text} from '../../../../../../../../component/text/text';
import {
    barChartDataLabelsOptions,
    barChartOptions,
} from '../../../../../../../../layout/chart/bar-chart/bar-chart-helper';
import {BarDataType, ChartJsBarOptionsType} from '../../../../../../../../layout/chart/bar-chart/bar-chart-type';
import {customReactChartTooltip} from '../../../../../../../../layout/chart/utils/chart-helper';
import {getPercentagesDistribution} from '../../../../../../../../util/number';

export const DATASET_STACK__COMPARE = 'compare';

export function getSentimentByTimeChartOptions(
    data: BarDataType | null,
    isDataLabelEnabled?: boolean
): ChartJsBarOptionsType {
    return {
        ...barChartOptions,
        plugins: {
            ...barChartOptions.plugins,
            datalabels: isDataLabelEnabled ? barChartDataLabelsOptions : {display: false},
            legend: {
                display: false,
            },
            ...(data
                ? {
                      tooltip: {
                          enabled: false,
                          yAlign: 'top',
                          xAlign: 'center',
                          position: 'nearest',
                          external: (context: Context) => {
                              const mainPeriodDatasets =
                                  data?.datasets.filter((dataset) => {
                                      return dataset.stack !== DATASET_STACK__COMPARE;
                                  }) || [];

                              const comparePeriodDatasets =
                                  data?.datasets.filter((dataset) => {
                                      return dataset.stack === DATASET_STACK__COMPARE;
                                  }) || [];

                              return customReactChartTooltip({
                                  context,
                                  componentRenderer: (currentIndex) => {
                                      const mainPeriodDatasetsDistribution = getPercentagesDistribution(
                                          mainPeriodDatasets.reduce((accumulator, datasetItem) => {
                                              return {
                                                  ...accumulator,
                                                  [datasetItem.label]: datasetItem.values[currentIndex] || 0,
                                              };
                                          }, {} as Record<string, number>)
                                      );

                                      const compareDatasetsDistribution = getPercentagesDistribution(
                                          comparePeriodDatasets.reduce((accumulator, datasetItem) => {
                                              return {
                                                  ...accumulator,
                                                  [datasetItem.label]: datasetItem.values[currentIndex] || 0,
                                              };
                                          }, {} as Record<string, number>)
                                      );

                                      return (
                                          <ChartTooltip>
                                              <ChartTooltipTitle title={data?.labels[currentIndex] || ''} />

                                              {mainPeriodDatasets.map((dataset) => {
                                                  const value = dataset.values[currentIndex] || 0;

                                                  return (
                                                      <ChartTooltipRow
                                                          color={String(dataset.color)}
                                                          key={dataset.label}
                                                          label={dataset.label}
                                                          value={
                                                              <>
                                                                  <Text>{value}</Text>(
                                                                  {mainPeriodDatasetsDistribution[dataset.label]}%)
                                                              </>
                                                          }
                                                      />
                                                  );
                                              })}

                                              {comparePeriodDatasets.length > 0 && (
                                                  <>
                                                      <ChartTooltipDivider />

                                                      {comparePeriodDatasets.map((dataset, datasetIndex) => {
                                                          const value = dataset.values[currentIndex] || 0;

                                                          return (
                                                              <ChartTooltipRow
                                                                  color={getCompareColor(
                                                                      String(mainPeriodDatasets?.[datasetIndex]?.color)
                                                                  )}
                                                                  key={dataset.label}
                                                                  label={dataset.label}
                                                                  value={
                                                                      <>
                                                                          <Text>{value}</Text>(
                                                                          {compareDatasetsDistribution[dataset.label]}
                                                                          %)
                                                                      </>
                                                                  }
                                                              />
                                                          );
                                                      })}
                                                  </>
                                              )}
                                          </ChartTooltip>
                                      );
                                  },
                              });
                          },
                      },
                  }
                : {}),
        },
    };
}
