export function getStripesPattern(color: string, strokeColor = '#fff'): CanvasPattern | string {
    const patternCanvas: HTMLCanvasElement = document.createElement('canvas');
    const patternContext: CanvasRenderingContext2D | null = patternCanvas.getContext('2d');

    if (!patternContext) {
        return color;
    }

    patternCanvas.width = 4;
    patternCanvas.height = 4;

    patternContext.fillStyle = color;
    patternContext.strokeStyle = strokeColor;
    patternContext.lineWidth = 1;
    patternContext.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
    patternContext.beginPath();

    patternContext.moveTo(0, patternCanvas.height);
    patternContext.lineTo(patternCanvas.width, 0);

    patternContext.moveTo(-patternCanvas.width, patternCanvas.height);
    patternContext.lineTo(patternCanvas.width, -patternCanvas.height);

    patternContext.moveTo(0, 2 * patternCanvas.height);
    patternContext.lineTo(2 * patternCanvas.width, 0);

    patternContext.stroke();

    return patternContext.createPattern(patternCanvas, 'repeat') || color;
}
