import {Button, Checkbox, Input} from 'antd';

import {appRoute} from '../../../app-route';
import {Text} from '../../../component/text/text';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {LoginFormKeyEnum, LoginFormType, useLoginMutation} from '../../../service/user/user-login';
import {Form} from '../../../typings/antd';
import {AuthFormFooter} from '../common/form-footer/auth-form-footer';
import {AuthFormHeader} from '../common/form-header/auth-form-header';

import {initialValues} from './login-const';
import * as styles from './login.scss';

export function Login(): JSX.Element {
    const [form] = Form.useForm<LoginFormType>();
    const email = Form.useWatch(LoginFormKeyEnum.Email, form);
    const password = Form.useWatch(LoginFormKeyEnum.Password, form);
    const {requiredFieldRule, emailFieldRule, minLengthFieldRule} = useFormRules();

    const {mutate, isLoading} = useLoginMutation();
    const {getLocalizedString} = useLocale();

    const disabled = !email || !password || isLoading;

    return (
        <Page.CenteredCard titleLangKey="PAGE__LOGIN__LOGIN_FORM__HEADER">
            <AuthFormHeader
                subtitle={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__SUB_HEADER" />}
                title={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__HEADER" />}
            />

            <Form<LoginFormType> form={form} initialValues={initialValues} layout="vertical" onFinish={mutate}>
                <Form.Item<LoginFormType>
                    label={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__LABEL" />}
                    name={LoginFormKeyEnum.Email}
                    rules={[requiredFieldRule, emailFieldRule]}
                >
                    <Input
                        onInvalid={(event) => event.preventDefault()}
                        placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__PLACEHOLDER')}
                        size="large"
                        type="email"
                    />
                </Form.Item>

                <Form.Item<LoginFormType>
                    label={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__LABEL" />}
                    name={LoginFormKeyEnum.Password}
                    rules={[requiredFieldRule, minLengthFieldRule(6)]}
                >
                    <Input.Password
                        placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__PLACEHOLDER')}
                        size="large"
                    />
                </Form.Item>

                <div className={styles.Login_rememberMe}>
                    <Form.Item<LoginFormType> name={LoginFormKeyEnum.RememberMe} valuePropName="checked">
                        <Checkbox>
                            <Locale stringKey="PAGE__LOGIN__LOGIN_FORM__INPUT__REMEMBER_ME" />
                        </Checkbox>
                    </Form.Item>

                    <NavigationLink to={appRoute.forgotPassword.path}>
                        <Locale stringKey="PAGE__AUTH__LINK_TO__FORGOT_PASSWORD" />
                    </NavigationLink>
                </div>

                <Button
                    block
                    disabled={disabled}
                    htmlType="submit"
                    id="authButton"
                    loading={isLoading}
                    size="large"
                    type="primary"
                >
                    <Locale stringKey="PAGE__LOGIN__LOGIN_FORM__BUTTON__SIGN_IN" />
                </Button>
            </Form>

            <AuthFormFooter>
                <Text lighter stringKey="PAGE__LOGIN__LOGIN_FORM__NO_ACCOUNT" />

                <NavigationLink to={appRoute.register.path}>
                    <Locale stringKey="PAGE__AUTH__LINK_TO__REGISTER" />
                </NavigationLink>
            </AuthFormFooter>
        </Page.CenteredCard>
    );
}
