import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Drawer, Form, Select} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {FormItemRadio} from '../../../../../component/form-item-radio/form-item-radio';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useCompetitors} from '../../../../../service/reivew-analysis/competitors/competitors-hook';
import {SentimentEnum} from '../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {useDrawerWidthHack} from '../../../../../util/antd/drawer-hook';
import {isReviewPhrasesFeatureEnabled} from '../../../../../util/feature-toggle/feature-toggle';
import {getCompareModeState, rangeDisabledDate} from '../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';
import {ReviewsAnalysisFilterEnum, ReviewsAnalysisFilterType, ReviewsCompareEnum} from '../../reviews-analysis-type';
import {PhraseSelect} from '../phrase-select/phrase-select';
import {TagSelect} from '../tag-select/tag-select';
import {TopicSelect} from '../topic-select/topic-select';

import {CatalogSelect} from './catalog-select/catalog-select';
import {CompareFormField} from './compare-form-field/compare-form-field';
import {CompetitorsSelect} from './competitor-select/competitors-select';
import {
    ABLE_TO_REPLIE_OPTIONS,
    emptyDrawerFormFilter,
    filterFormId,
    REPLIES_TO_REVIEWS_OPTIONS,
    REVIEWS_WITH_TEXT_OPTIONS,
} from './drawer-filter-const';
import {
    compareSelectOptions,
    renderRateOptions,
    renderSentimentOptions,
    sendDrawerFilterAnalytics,
} from './drawer-filter-helper';
import {SelectOptionType} from './drawer-filter-type';
import {FilterFooter} from './filter-footer/filter-footer';
import {UtcRangePicker} from './utc-range-picker/utc-range-picker';
import * as styles from './drawer-filter.scss';

type PropsType = {
    isCompareRangeAllowed?: boolean;
    isCompetitorAllowed?: boolean;
    isTopicsAllowed?: boolean;
    isWithTextOnly?: boolean;
    onSubmit?: () => void;
};

export function DrawerFilter(props: PropsType): JSX.Element {
    const {
        isCompareRangeAllowed = false,
        isCompetitorAllowed = false,
        isWithTextOnly = false,
        isTopicsAllowed = true,
        onSubmit,
    } = props;

    const {getLocalizedString, shortLocaleName} = useLocale();

    const drawerWidth: string = useDrawerWidthHack();

    const [form] = Form.useForm<ReviewsAnalysisFilterType>();
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

    const {filter, setFilter} = useReviewsAnalysisFilter();

    const [compareMode, setCompareMode] = useState<ReviewsCompareEnum>(() => {
        return getCompareModeState(filter);
    });

    const onFormOpen = useCallback((): void => {
        form.resetFields();
        setCompareMode(getCompareModeState(filter));
        setIsFormOpen(true);
    }, [form, setIsFormOpen, filter]);

    const onFormClose = useCallback((): void => {
        form.resetFields();
        setCompareMode(getCompareModeState(filter));
        setIsFormOpen(false);
    }, [form, setIsFormOpen, filter]);

    const onResetForm = useCallback((): void => {
        form.setFieldsValue(emptyDrawerFormFilter);
        setCompareMode(ReviewsCompareEnum.OFF);
    }, [form]);

    const {result: competitorsResult, isInProgress: isCompetitorsInProgress} = useCompetitors();
    const competitorOptions: Array<SelectOptionType<number>> = useMemo<Array<SelectOptionType<number>>>(() => {
        return (competitorsResult || []).sort(compareSelectOptions<number>(shortLocaleName));
    }, [competitorsResult, shortLocaleName]);

    const handleSubmit = useCallback(
        (values: ReviewsAnalysisFilterType): void => {
            const serializedValues: ReviewsAnalysisFilterType = {
                ...emptyDrawerFormFilter,
                ...values,
            };

            setFilter(serializedValues);
            setIsFormOpen(false);

            if (onSubmit) {
                onSubmit();
            }

            sendDrawerFilterAnalytics(values, competitorOptions);
        },
        [competitorOptions, setFilter, onSubmit]
    );

    function handleWithMessageChange(value: boolean) {
        form.setFieldsValue({[ReviewsAnalysisFilterEnum.WithMessage]: value});
    }

    function handleWithReplyChange(value: boolean) {
        form.setFieldsValue({[ReviewsAnalysisFilterEnum.WithReply]: value});
    }

    function handleAbleToReplyChange(value: boolean) {
        form.setFieldsValue({[ReviewsAnalysisFilterEnum.AbleToReply]: value});
    }

    return (
        <>
            <Button icon={<FontAwesomeIcon icon={faFilter} />} onClick={onFormOpen} />

            <Drawer
                footer={<FilterFooter onReset={onResetForm} />}
                onClose={onFormClose}
                open={isFormOpen}
                placement="right"
                title={<Locale stringKey="REVIEWS_ANALYSIS__FILTER_FORM__TITLE" />}
                width={drawerWidth}
            >
                <Form<ReviewsAnalysisFilterType>
                    form={form}
                    id={filterFormId}
                    initialValues={filter}
                    layout="vertical"
                    onFinish={handleSubmit}
                    preserve={false}
                >
                    <div className={styles.drawer_filter__compare_block}>
                        <Form.Item<ReviewsAnalysisFilterType>
                            className={styles.drawer_filter__compare_field}
                            label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__PERIOD__LABEL" />}
                            name={ReviewsAnalysisFilterEnum.Range}
                        >
                            <UtcRangePicker defaultMode disabledDate={rangeDisabledDate} />
                        </Form.Item>

                        <CompareFormField
                            className={styles.drawer_filter__compare_field}
                            isActive={compareMode === ReviewsCompareEnum.PERIOD}
                            isHidden={!isCompareRangeAllowed}
                            name={ReviewsAnalysisFilterEnum.CompareRange}
                            onToggle={(isActive: boolean): void => {
                                return isActive
                                    ? setCompareMode(ReviewsCompareEnum.PERIOD)
                                    : setCompareMode(ReviewsCompareEnum.OFF);
                            }}
                            stringKey="REVIEWS_ANALYSIS__FORM__INPUT__COMPARE_PERIOD__LABEL"
                        >
                            <UtcRangePicker defaultMode />
                        </CompareFormField>

                        <CompareFormField
                            className={styles.drawer_filter__compare_field}
                            isActive={compareMode === ReviewsCompareEnum.COMPETITOR}
                            isHidden={!isCompetitorAllowed}
                            name={ReviewsAnalysisFilterEnum.Competitor}
                            onToggle={(isActive: boolean): void => {
                                return isActive
                                    ? setCompareMode(ReviewsCompareEnum.COMPETITOR)
                                    : setCompareMode(ReviewsCompareEnum.OFF);
                            }}
                            stringKey="REVIEWS_ANALYSIS__FORM__INPUT__COMPETITOR__LABEL"
                        >
                            <CompetitorsSelect
                                competitorOptions={competitorOptions}
                                isInProgress={isCompetitorsInProgress}
                            />
                        </CompareFormField>
                    </div>

                    <Form.Item<ReviewsAnalysisFilterType>
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__SENTIMENT__LABEL" />}
                        name={ReviewsAnalysisFilterEnum.Sentiments}
                    >
                        <Select<SentimentEnum>
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__SENTIMENT__PLACEHOLDER" />}
                        >
                            {renderSentimentOptions(getLocalizedString)}
                        </Select>
                    </Form.Item>

                    <Form.Item<ReviewsAnalysisFilterType>
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__CATALOG__LABEL" />}
                        name={ReviewsAnalysisFilterEnum.Catalogs}
                    >
                        <CatalogSelect />
                    </Form.Item>

                    <Form.Item<ReviewsAnalysisFilterType>
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__KEYWORDS__LABEL" />}
                        name={ReviewsAnalysisFilterEnum.Tags}
                    >
                        <TagSelect />
                    </Form.Item>

                    {isReviewPhrasesFeatureEnabled() && (
                        <Form.Item<ReviewsAnalysisFilterType>
                            label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__PHRASES__LABEL" />}
                            name={ReviewsAnalysisFilterEnum.Phrases}
                        >
                            <PhraseSelect />
                        </Form.Item>
                    )}

                    {isTopicsAllowed && (
                        <Form.Item<ReviewsAnalysisFilterType>
                            label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__TOPICS__LABEL" />}
                            name={ReviewsAnalysisFilterEnum.Topics}
                        >
                            <TopicSelect />
                        </Form.Item>
                    )}

                    <Form.Item<ReviewsAnalysisFilterType>
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__RATE__LABEL" />}
                        name={ReviewsAnalysisFilterEnum.Rates}
                    >
                        <Select<Array<string>>
                            mode="multiple"
                            optionLabelProp="label"
                            placeholder={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__RATE__PLACEHOLDER" />}
                            showSearch={false}
                        >
                            {renderRateOptions()}
                        </Select>
                    </Form.Item>

                    {isWithTextOnly ? null : (
                        <FormItemRadio<boolean>
                            fieldStateValue={Boolean(filter[ReviewsAnalysisFilterEnum.WithMessage])}
                            form={form}
                            label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__ALL_MSG_LABEL" />}
                            name={ReviewsAnalysisFilterEnum.WithMessage}
                            onClick={handleWithMessageChange}
                            options={REVIEWS_WITH_TEXT_OPTIONS}
                        />
                    )}

                    <FormItemRadio<boolean>
                        fieldStateValue={Boolean(filter[ReviewsAnalysisFilterEnum.WithReply])}
                        form={form}
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__ALL_REPLY_LABEL" />}
                        name={ReviewsAnalysisFilterEnum.WithReply}
                        onClick={handleWithReplyChange}
                        options={REPLIES_TO_REVIEWS_OPTIONS}
                    />

                    <FormItemRadio<boolean>
                        fieldStateValue={Boolean(filter[ReviewsAnalysisFilterEnum.AbleToReply])}
                        form={form}
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__ALL_ABLE_TO_REPLY_LABEL" />}
                        name={ReviewsAnalysisFilterEnum.AbleToReply}
                        onClick={handleAbleToReplyChange}
                        options={ABLE_TO_REPLIE_OPTIONS}
                    />
                </Form>
            </Drawer>
        </>
    );
}
