import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {helpLinkSchema, HelpLinksOptionsType, HelpLinkType} from './help-links-type';

export async function fetchHelpLinks(options: HelpLinksOptionsType): Promise<Array<HelpLinkType>> {
    const url = `/cp/help_links/?${objectToUrlParameters({
        domain: options.domainName,
    })}`;

    return fetchAndDeserialize(url, helpLinkSchema.array());
}
