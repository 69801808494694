import {useQuery} from '@tanstack/react-query';
import {groupBy, map, mapValues, pick} from 'lodash';
import {z as r} from 'zod';

import {
    AdditionalLicenseKeyEnum,
    OmnichannelOptionsEnum,
} from '../../page/main/tariffs-and-limits/licenses-block/license/license-const';
import {fetchAndDeserialize} from '../../util/api-adapter';

import {tariffUrl} from './tariffs-const';

const licenseFromTariffSchema = r.object({
    licenceName: r.string(),
    licenceCode: r.string(),
    payment: r.boolean(),
    startAt: r.string(),
    finishAt: r.string(),
    isUpsell: r.boolean(),
    isBonus: r.boolean(),
    isTrial: r.boolean(),
    types: r.array(r.string()).optional(),
    conversationsLimit: r.number().optional(),
    usersLimit: r.number().optional(),
    sourcesLimit: r.number().optional(),
    hasTags: r.boolean().optional(),
    providerCodes: r.array(r.string()).optional(),
    hasResponseTemplates: r.boolean().optional(),
    hasSentiments: r.boolean().optional(),
    hasAnalytics: r.boolean().optional(),
    type: r.string().optional(),
    limit: r.number().optional(),
    requests: r.number().optional(),
});

const licenseListFromTariffSchema = r.array(licenseFromTariffSchema);
const availableLicenseSchema = r.object({
    licenceName: r.string(),
    licenceCode: r.string(),
});

const licenseRequestSchema = r.object({
    activeTrialLicences: licenseListFromTariffSchema,
    availableTrialLicences: r.array(availableLicenseSchema),
    paidLicences: licenseListFromTariffSchema,
    noPaidNoTrialLicences: r.array(availableLicenseSchema),
    autoReviewLicenceBalance: r.number(),
    companyLicenceBalance: r.number(),
    roleLicenceBalance: r.number(),
});

type LicensesType = r.infer<typeof licenseRequestSchema>;

type LicenseListType = r.infer<typeof licenseListFromTariffSchema>;

type LicenseHookType = {
    licenseData: {
        activeTrialLicences: Array<UniqueLicenseType>;
        availableTrialLicences: Array<{licenceCode: string; licenceName: string}>;
        licenseWithLimits: Array<UniqueLicenseType>;
        licenseWithoutLimits: Array<{licenceCode: string; licenceName: string; isPaid?: boolean}> | undefined;
    };
    licenseBalance: {
        autoReviewLicenceBalance?: number;
        companyLicenceBalance?: number;
        roleLicenceBalance?: number;
    };
    refetch: () => void;
};

export type UniqueLicenseDataType = Array<{
    startAt: string;
    finishAt: string;
    providerCodes: Array<string>;
    types: Array<string>;
    omnichannelOptions: Array<OmnichannelOptionsEnum | string>;
    additionalLicenseKeys: {
        conversationsLimit?: number;
        usersLimit?: number;
        sourcesLimit?: number;
        hasTags?: boolean;
        hasResponseTemplates?: boolean;
        hasSentiments?: boolean;
        hasAnalytics?: boolean;
        limit?: number;
        type?: string;
        requests?: number;
    };
}>;

type UniqueLicenseType = {
    licenceName: string;
    licenceCode: string;
    data: UniqueLicenseDataType;
    isBonus: boolean;
};

function getLicenseUrl(tariffId: number | null) {
    return `${tariffUrl}/${tariffId}/license/`;
}

function fetchTariffLicenses(tariffId: number | null): Promise<LicensesType> {
    return fetchAndDeserialize(getLicenseUrl(tariffId), licenseFromTariffSchema);
}

function uniqueLicenseByGroup(licenses: LicenseListType): Array<UniqueLicenseType> {
    const groupedData = groupBy(licenses, 'licenceCode');

    const uniqueLicenses = mapValues(groupedData, (licensesList) => ({
        licenceName: licensesList[0] ? licensesList[0].licenceName : '',
        licenceCode: licensesList[0] ? licensesList[0].licenceCode : '',
        isBonus: licensesList[0] ? licensesList[0].isBonus : false,
        data: map(licensesList, (license) => {
            const types = [...(license.types ?? []), ...(license.type ? [license.type] : [])];

            return {
                ...pick(license, ['startAt', 'finishAt']),
                types,
                providerCodes: license.providerCodes ?? [],
                omnichannelOptions: [
                    license.hasTags ? OmnichannelOptionsEnum.HasTags : '',
                    license.hasAnalytics ? OmnichannelOptionsEnum.HasAnalytics : '',
                    license.hasSentiments ? OmnichannelOptionsEnum.HasSentiments : '',
                    license.hasResponseTemplates ? OmnichannelOptionsEnum.HasResponseTemplate : '',
                ],
                additionalLicenseKeys: {
                    ...pick(license, [
                        AdditionalLicenseKeyEnum.Requests,
                        AdditionalLicenseKeyEnum.SourcesLimit,
                        AdditionalLicenseKeyEnum.UsersLimit,
                        AdditionalLicenseKeyEnum.Limit,
                        AdditionalLicenseKeyEnum.ConversationsLimit,
                        AdditionalLicenseKeyEnum.ProductType,
                    ]),
                },
            };
        }),
    }));

    return Object.values(uniqueLicenses);
}

function hasAdditionalLicenseData(license: UniqueLicenseType): boolean {
    return Boolean(
        (license.data[0] && Object.keys(license.data[0].additionalLicenseKeys).length > 0) ||
            (license.data[0] && license.data[0].providerCodes?.length > 0) ||
            (license.data[0] && license.data[0].types.length > 0)
    );
}

export function useTariffLicenses(tariffId: number | null): LicenseHookType {
    const {data, refetch} = useQuery([getLicenseUrl(tariffId)], () => fetchTariffLicenses(tariffId), {
        enabled: tariffId !== null,
    });

    const licenseWithLimits = uniqueLicenseByGroup(data?.paidLicences ?? []).filter((license) =>
        hasAdditionalLicenseData(license)
    );

    const licenseWithoutLimits = [
        ...uniqueLicenseByGroup(data?.paidLicences ?? []).filter((license) => !hasAdditionalLicenseData(license)),
        ...(data?.noPaidNoTrialLicences.map((item) => {
            return {...item, isPaid: false};
        }) ?? []),
    ];

    return {
        licenseData: {
            activeTrialLicences: uniqueLicenseByGroup(data?.activeTrialLicences ?? []),
            availableTrialLicences: data?.availableTrialLicences ?? [],
            licenseWithLimits,
            licenseWithoutLimits,
        },
        licenseBalance: {
            autoReviewLicenceBalance: data?.autoReviewLicenceBalance,
            companyLicenceBalance: data?.companyLicenceBalance,
            roleLicenceBalance: data?.roleLicenceBalance,
        },
        refetch: () => refetch(),
    };
}
