import {faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from 'antd';
import {useCallback} from 'react';

import {useActionRequiresLicense} from '../../../../../provider/license/license-hook';
import {LocaleWithCatalogName} from '../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../provider/locale/localization';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {useHardRemovePostMutation} from '../../../../../service/posts/posts-hooks';
import {FeaturesEnum} from '../../../../../service/user/user-type';
import {ProvidersIdsEnum} from '../../../../../util/type';
import * as productGroupsTableStyles from '../../../price/components/product-groups-table/product-groups-table.scss';

import * as styles from './post-actions.scss';

type PropsType = {
    postId: number;
    onActionCompleted: () => void;
    setIsTableInProgress: (isInProgress: boolean) => void;
    showInstagramWarning: boolean;
    isDisabled: boolean;
};

export function PostActionHardRemove(props: PropsType): JSX.Element {
    const {postId, setIsTableInProgress, onActionCompleted, showInstagramWarning, isDisabled} = props;
    const {modal} = useModal();

    const {mutateAsync: removePost} = useHardRemovePostMutation();

    const handlePostRemove = useActionRequiresLicense(async () => {
        try {
            await removePost(postId);
        } finally {
            setIsTableInProgress(false);
        }
    }, FeaturesEnum.posts);

    const onRemovePostClick = useCallback(() => {
        setIsTableInProgress(true);
        modal.confirm({
            title: <Locale stringKey="POSTS_FORM__MESSAGE__HARD_REMOVE_POST_MODAL__TITLE" />,
            content: (
                <div className={styles.PostActions_confirmModalContent}>
                    {showInstagramWarning && (
                        <Alert
                            message={
                                <LocaleWithCatalogName
                                    catalogId={ProvidersIdsEnum.instagram}
                                    stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__UNSUPPORTED_REMOVE_ALERT"
                                />
                            }
                            type="warning"
                        />
                    )}
                    <Locale stringKey="POSTS_FORM__MESSAGE__HARD_REMOVE_POST_MODAL__CONTENT" />
                </div>
            ),
            okText: <Locale stringKey="BUTTON__DELETE" />,
            okType: 'danger',
            className: productGroupsTableStyles.ProductGroupsTable_removeWarning,
            cancelText: <Locale stringKey="BUTTON__CANCEL" />,
            onOk: () => {
                handlePostRemove();
                onActionCompleted();
            },
            onCancel: () => {
                setIsTableInProgress(false);
            },
        });
    }, [setIsTableInProgress, modal, showInstagramWarning, handlePostRemove, onActionCompleted]);

    return (
        <Button danger disabled={isDisabled} onClick={onRemovePostClick} size="small" type="link">
            <FontAwesomeIcon className={styles.PostActions_icon} icon={faTrashCan} />
        </Button>
    );
}
