import {faFilter} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableColumnType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {TupleType} from '../../../../util/type';

import {TagsTableRowDataType} from './tags-table-type';

export const tagColumnList: TupleType<TableColumnType<TagsTableRowDataType>, 3> = [
    {
        title: <Locale stringKey="TAGS__TAG" />,
        dataIndex: 'tagName',
    },
    {
        title: <Locale stringKey="TAGS__GROUP" />,
        dataIndex: 'tagGroup',
        filterIcon: <FontAwesomeIcon icon={faFilter} size="lg" />,
    },
    {
        title: <Locale stringKey="TAGS__ACTIONS" />,
        dataIndex: 'tagActions',
        width: 104,
    },
];

export const withoutGroupItemId = '-1';

// see TagDataType.is_complex
// is_complex === true => command
// is_complex === false => private
export const tagTypeCommand = 'public';
