import {useCatalogInfo} from '../../provider/catalogs/catalogs-hook';
import {classNames} from '../../util/css';
import {ProvidersIdsEnum} from '../../util/type';

import * as styles from './catalog-logo.scss';

type PropsType = {
    catalogId: ProvidersIdsEnum;
    className?: string;
    circle?: boolean;
};

export function CatalogLogo(props: PropsType): JSX.Element {
    const {catalogId, className, circle} = props;

    const {getCatalogLogo, getCatalogName} = useCatalogInfo();

    return (
        <img
            alt={getCatalogName(catalogId)}
            className={classNames(styles.CatalogLogo, className, {[styles.CatalogLogo_circle]: circle})}
            src={getCatalogLogo(catalogId)}
        />
    );
}
