import {Text} from '../../../../component/text/text';

export enum CompaniesReportStepEnum {
    Pending = 'PENDING',
    Performing = 'PERFORMING',
    Aggregating = 'AGGREGATING',
    Done = 'DONE',
    Failed = 'FAILED',
}

export const LOCALSTORAGE_REPORT_UUID_KEY = 'isClosedUuidReport';

export const reportSteps = {
    [CompaniesReportStepEnum.Pending]: {
        step: 1,
        description: <Text stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEP_PENDING" />,
    },
    [CompaniesReportStepEnum.Performing]: {
        step: 2,
        description: <Text stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEP_PERFORMING" />,
    },
    [CompaniesReportStepEnum.Aggregating]: {
        step: 3,
        description: <Text stringKey="MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEP_AGGREGATING" />,
    },
    [CompaniesReportStepEnum.Done]: {
        step: 4,
        description: '',
    },
    [CompaniesReportStepEnum.Failed]: {
        step: 5,
        description: <Text stringKey="MY_COMPANIES__REPORT__ERROR_BLOCK__STEP__FAILED" />,
    },
};
