import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {
    usedNeuralNetworkSuggestionsCountSchema,
    UsedNeuralNetworkSuggestionsCountType,
} from './used-neural-network-suggestions-count-type';

export function fetchUsedNeuralNetworkSuggestionsCount(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<UsedNeuralNetworkSuggestionsCountType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/gpt_reply_suggestions/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        usedNeuralNetworkSuggestionsCountSchema
    );
}
