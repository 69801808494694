import {useEffect, useMemo} from 'react';

import {useRefreshId} from '../../../util/hook';
import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {PaginationOptionsType, PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {PaginatedResponseType, RequestOptionsType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType, UseRefreshApiHookType} from '../../api-hook/api-hook-type';

import {fetchReviewTopicList, fetchReviewTopicSearch, fetchReviewUserTopic} from './review-topics-api';
import {ReviewsAnalysisTopicsQueryType, ReviewTopicType, ReviewUserTopicType} from './review-topics-type';

export function useReviewTopics(
    options: ReviewsAnalysisTopicsQueryType,
    mainFilterKey: string,
    paginationOption?: PaginationOptionsType
): UseHookType<PaginatedResponseType<ReviewTopicType>> & UseRefreshApiHookType & PaginationType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<ReviewTopicType>>();

    const {
        mode,
        brandIds: compareBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
        ableToReply,
        topicType,
    } = options;

    const {refreshId, refresh} = useRefreshId();

    const paginationDependencies = useMemo(() => {
        return {
            mode,
            compareBrandIds,
            startDate,
            endDate,
            sentiments,
            sources,
            tags,
            phrases,
            topics,
            rating,
            withAnswers,
            withText,
            ableToReply,
            topicType,
            refreshId,
        };
    }, [
        mode,
        compareBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
        ableToReply,
        topicType,
        refreshId,
    ]);

    const pagination = usePagination({
        ...paginationOption,
        dependencies: paginationDependencies,
        shouldSaveState: false,
    });

    const {page, pageSize, onDataLoadFailed, onDataLoaded, refreshId: paginationRefreshId} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewTopicList(
            {
                page,
                pageSize,
                mode,
                brandIds: compareBrandIds,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
                withText,
                ableToReply,
                topicType,
            },
            mainFilterKey
        )
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationRefreshId, setIsInProgress, setProcessError, setResult, startDate, onDataLoaded, onDataLoadFailed]);

    return {isInProgress, processError, result, reset, refreshId, refresh, pagination};
}

export function useReviewTopic(id: number): UseHookType<ReviewUserTopicType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, reset, setResult} =
        useApiHooks<ReviewUserTopicType>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewUserTopic(id)
            .then((response) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useReviewTopicSearch(options: RequestOptionsType, mainFilterKey: string): UseHookType<Array<string>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<string>>();

    const {q, count, page} = options;

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewTopicSearch(
            {
                q,
                page,
                count,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [page, count, q, setIsInProgress, setProcessError, setResult, mainFilterKey]);

    return {isInProgress, processError, result, reset};
}
