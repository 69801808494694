import {useEffect} from 'react';

import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {fetchAddresses} from './address-api';
import {AddressType, GetAddressArgumentType} from './address-type';

export function useAddresses(
    addressArgument: GetAddressArgumentType,
    option: RequestOptionsType
): UseHookType<PaginatedResponseType<AddressType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<AddressType>>();

    const {q, page, count} = option;
    const {companyIdList} = addressArgument;

    useEffect(() => {
        if (count === 0) {
            return;
        }

        setIsInProgress(true);

        fetchAddresses({companyIdList}, {q, page, count})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyIdList, q, page, count, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
