import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

const companyNameSchema = r.object({
    name: r.string(),
    lang: r.string().nullable(),
    pushedName: r.string().nullable(),
});

const companyExtraNameSchema = r.object({
    catalogId: r.number(),
    names: r.array(companyNameSchema),
});

export const companyNamesSchema = {
    [CompanyKeyEnum.Names]: r.array(companyNameSchema),
    [CompanyKeyEnum.ExtraNames]: r.array(companyExtraNameSchema),
    [CompanyKeyEnum.SearchNames]: r.array(r.string()),
};

const companyNamesSchemaObject = r.object(companyNamesSchema);

export type CompanyNamesType = r.infer<typeof companyNamesSchemaObject>;

export type CompanyNamesServerType = Omit<CompanyNamesType, 'names' | 'extraNames'> & {
    names: Array<Omit<CompanyNamesType['names'][0], 'pushedName'>>;
    extraNames: Array<{
        catalogId: CompanyNamesType['extraNames'][0]['catalogId'];
        names: Array<Omit<CompanyNamesType['extraNames'][0]['names'][0], 'pushedName'>>;
    }>;
};
