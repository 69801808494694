import {faCheckCircle, faTimesCircle} from '@fortawesome/pro-regular-svg-icons';
import {faInfoCircle, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {App, ModalFuncProps} from 'antd';
import {useCallback, useMemo} from 'react';

import {classNames} from '../../util/css';

import {ModalHookType} from './modal-type';
import * as styles from './modal-hook.scss';

export function useModal(): ModalHookType {
    const {modal} = App.useApp();

    const {success: antSuccess, error: antError, info: antInfo, confirm: antConfirm} = modal;

    const success = useCallback(
        (args: ModalFuncProps) => {
            return antSuccess({
                icon: <FontAwesomeIcon className="anticon" icon={faCheckCircle} />,
                ...args,
            });
        },
        [antSuccess]
    );

    const error = useCallback(
        (args: ModalFuncProps) => {
            return antError({
                icon: <FontAwesomeIcon className="anticon" icon={faTimesCircle} />,
                ...args,
            });
        },
        [antError]
    );

    const info = useCallback(
        (args: ModalFuncProps) => {
            return antInfo({
                icon: <FontAwesomeIcon className="anticon" icon={faInfoCircle} />,
                ...args,
            });
        },
        [antInfo]
    );

    const confirm = useCallback(
        (args: ModalFuncProps) => {
            const isDanger = args.okButtonProps?.danger || args.okType === 'danger';

            return antConfirm({
                icon: (
                    <FontAwesomeIcon
                        className={classNames('anticon', {[styles.Modal_icon__danger]: isDanger})}
                        icon={faTriangleExclamation}
                    />
                ),
                ...args,
            });
        },
        [antConfirm]
    );

    return useMemo(
        () => ({
            modal: {error, success, info, confirm},
        }),
        [confirm, error, info, success]
    );
}
