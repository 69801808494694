import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {deserializeV2} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {rootApiUrl} from '../api/api-const';

import {ReviewDataType} from './reviews-type';

const reviewInfoDataSchema = r.object({
    id: r.number(),
    notesCount: r.number(),
    canManageReview: r.boolean(),
});

const fetchReviewsInfoSchema = r.object({
    results: r.array(reviewInfoDataSchema),
});

export type FetchReviewsInfoDataType = r.infer<typeof fetchReviewsInfoSchema>;
export type ReviewInfoDataType = r.infer<typeof reviewInfoDataSchema>;

function getReviewsInfoUrl(reviewIds: Array<number>) {
    return `${rootApiUrl}/v4/reviews/info/?${objectToUrlParameters({review_ids: reviewIds})}`;
}

async function fetchReviewsInfo(reviewIds: Array<number>): Promise<FetchReviewsInfoDataType> {
    const url = getReviewsInfoUrl(reviewIds);
    const response = await fetchX<ReviewDataType>(url);

    return deserializeV2<FetchReviewsInfoDataType>(url, fetchReviewsInfoSchema, response);
}

export function useReviewsInfo(reviewIds: Array<number>): UseQueryResult<FetchReviewsInfoDataType> {
    return useQuery([getReviewsInfoUrl(reviewIds)], () => fetchReviewsInfo(reviewIds), {
        enabled: reviewIds.length > 0,
        refetchOnWindowFocus: false,
    });
}
