import {z as r} from 'zod';

export const tonalityDistributionSchema = r.object({
    positive: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    neutral: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    negative: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    unspecified: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    tonalityIndex: r.object({
        value: r.number(), // в процентах до 1 цифры после запятой
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
});

export type TonalityDistributionType = r.infer<typeof tonalityDistributionSchema>;
