import {createContext, PropsWithChildren, useEffect, useState} from 'react';

import {Spinner} from '../../layout/spinner/spinner';
import {useLocale} from '../locale/locale-hook';

import {initializeDomainConfig} from './domain-config-helper';
import {DomainConfigType} from './domain-config-type';
import {domainConfig} from './domain-data/rocketdata/domain-config-data';
import * as styles from './domain-config.scss';

export const DomainConfigContext = createContext<DomainConfigType>(domainConfig);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function DomainConfigProvider(props: PropsType): JSX.Element | null {
    const {children} = props;
    const {localeName, setLocaleName} = useLocale();

    const [config, setConfig] = useState<DomainConfigType | null>(null);

    useEffect(() => {
        initializeDomainConfig()
            .then(setConfig)
            .catch(() => {
                console.error('An error occured while trying ot initialize domain config');
            });
    }, []);

    useEffect(() => {
        if (config && !config.availableLocales.includes(localeName)) {
            setLocaleName(config.defaultLocale);
        }
    }, [config, localeName, setLocaleName]);

    if (config) {
        return <DomainConfigContext.Provider value={config}>{children}</DomainConfigContext.Provider>;
    }

    return (
        <div className={styles.container}>
            <Spinner />
        </div>
    );
}
