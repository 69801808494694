import {ReactNode} from 'react';

import {ActivationBanner} from '../../../../layout/activation-page/activation-banner';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useNotificationsLicenses} from '../../../../service/notifications-settings/notifications-licenses';
import {useTariffs} from '../../../../service/tariffs/tariffs-user-tariffs';

type PropsType = {
    children: ReactNode;
    type: string;
};

export function NotificationTabWrapper(props: PropsType): JSX.Element {
    const {children, type} = props;

    const {data: tariffs} = useTariffs();

    const tariffId = tariffs?.results?.[0]?.id;

    const {data: notificationLicenses, isFetching} = useNotificationsLicenses(tariffId);

    const hasLicense =
        notificationLicenses?.activeTrialLicences?.find((license) => license.types?.find((item) => item === type)) ||
        notificationLicenses?.paidLicences?.find((license) => license.types?.find((item) => item === type));

    if (isFetching) {
        return <Spinner wrapperHeight={400} />;
    }

    if (!hasLicense) {
        return <ActivationBanner />;
    }

    return <div>{children}</div>;
}
