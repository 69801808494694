import {faLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import * as styles from '../review.scss';

type PropsType = {
    reviewId: number;
};

export function CopyLink(props: PropsType): JSX.Element {
    const {reviewId} = props;
    const {snackbar} = useSnackbar();

    async function onCopy() {
        try {
            const url = location.origin + generatePath(appRoute.reviewsManagementReviewItem.path, {id: reviewId});

            await navigator.clipboard.writeText(url);
            snackbar.success(<Locale stringKey="REVIEWS__REVIEW_URL_COPY__SUCCESS" />);
        } catch (error: unknown) {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            });

            throw error;
        }
    }

    return (
        <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_URL_COPY__HINT" />}>
            <button className={styles.Review_iconButton} onClick={onCopy} type="button">
                <FontAwesomeIcon icon={faLink} />
            </button>
        </Tooltip>
    );
}
