import {faFileChartColumn, faPenToSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Button, Popover} from 'antd';
import {Dispatch, SetStateAction} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../provider/locale/localization';
import {useCreateReport} from '../../../../../service/company/company-reports';
import {CompanyShortType, PermissionNameEnum} from '../../../../../service/company/company-type';
import {companyWidgetToNamesMap} from '../../../company-form/company-form-const';

import * as styles from './row-actions.scss';

type PropsType = {
    company: CompanyShortType;
    isDisableReportButton: boolean;
    setShowReport: Dispatch<SetStateAction<boolean>>;
    isReportEnabled: boolean;
};

const MAX_NUMBER_LIST_ITEM = 5;

export function RowAction(props: PropsType): JSX.Element | null {
    const {company, isReportEnabled, isDisableReportButton, setShowReport} = props;
    const {mutate} = useCreateReport(() => setShowReport(true));

    if (company.closed) {
        return (
            <span className={styles.RowActions__closed}>
                <Locale stringKey="COMPANY__CLOSED" />
            </span>
        );
    }

    const countUnfilledInfo = company.unfilledInfo.length;

    if (company.permissions.length > 0) {
        return (
            <div className={styles.RowActions}>
                {company.permissions.includes(PermissionNameEnum.update) && countUnfilledInfo > 0 ? (
                    <Popover
                        content={
                            <div>
                                <ul className={styles.Popover_list}>
                                    {company.unfilledInfo.map(
                                        (item, index) =>
                                            index <= MAX_NUMBER_LIST_ITEM - 1 && (
                                                <li key={item}>
                                                    <Locale stringKey={companyWidgetToNamesMap[item]} />
                                                </li>
                                            )
                                    )}
                                </ul>
                                {countUnfilledInfo > MAX_NUMBER_LIST_ITEM && (
                                    <span className={styles.Popover_info}>
                                        <LocalePlural
                                            count={countUnfilledInfo - MAX_NUMBER_LIST_ITEM}
                                            fewKey="COMPANY_FORM__POPOVER__FIELD__FEW"
                                            manyKey="COMPANY_FORM__POPOVER__FIELD__MANY"
                                            singularKey="COMPANY_FORM__POPOVER__FIELD__SINGULAR"
                                            valueMap={{count: countUnfilledInfo - MAX_NUMBER_LIST_ITEM}}
                                        />
                                    </span>
                                )}
                            </div>
                        }
                        title={
                            <div className={styles.Popover_header}>
                                <Locale stringKey="COMPANY_FORM__FIELDS_SECTION" />
                                <span>{countUnfilledInfo}</span>
                            </div>
                        }
                    >
                        <Badge count={countUnfilledInfo} offset={[-2, 2]} size="small">
                            <NavigationLink to={generatePath(appRoute.editCompany.path, {companyId: company.id})}>
                                <Button aria-label="edit" icon={<FontAwesomeIcon icon={faPenToSquare} />} type="text" />
                            </NavigationLink>
                        </Badge>
                    </Popover>
                ) : (
                    <NavigationLink to={generatePath(appRoute.editCompany.path, {companyId: company.id})}>
                        <Button aria-label="edit" icon={<FontAwesomeIcon icon={faPenToSquare} />} type="text" />
                    </NavigationLink>
                )}

                {isReportEnabled && (
                    <Button
                        disabled={isDisableReportButton}
                        icon={<FontAwesomeIcon icon={faFileChartColumn} />}
                        onClick={() => {
                            mutate({selectedCompaniesIds: [company.id], selectedAll: false, brandName: company.name});
                        }}
                        type="text"
                    />
                )}
            </div>
        );
    }

    return null;
}
