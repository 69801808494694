import {ReviewSuggestionType} from '../../../../service/reviews/reviews-suggestions';
import {ReviewDataType} from '../../../../service/reviews/reviews-type';
import {classNames} from '../../../../util/css';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';
import {ReplyForm} from '../review-list-page-content/review-list/review/reply-form/reply-form';
import {SuggestionItem} from '../review-list-page-content/review-list/review/suggestion-item/suggestion-item';
import {isReviewReplyAllowed} from '../reviews-helper';

import {AnswersBunch} from './answers-bunch/answers-bunch';
import * as styles from './review-answers.scss';

type PropsType = {
    review: ReviewDataType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    refreshSuggestions: () => Promise<unknown>;
    suggestion?: ReviewSuggestionType;
    isCollapsedAnswers?: boolean;
    isGptSuggestSupported?: boolean;
};

export function ReviewAnswers(props: PropsType): JSX.Element {
    const {review, refreshReview, isCollapsedAnswers, suggestion, refreshSuggestions, isGptSuggestSupported} = props;
    const {children, id: reviewId, deletedAt, brand, createdInCatalog, replyTimeInCatalog, replyTimeInRd} = review;
    const isDeleted = Boolean(deletedAt);

    const {replyAreaRef, isNotesVisible, isRepliesVisible, isHistoryVisible, isRepliesActive} = useReviewActivityHook();
    const isNotesAndLogsHidden = !isNotesVisible && !isHistoryVisible;

    return (
        <>
            {isRepliesVisible && isReviewReplyAllowed(review) && (
                <ReplyForm
                    isGptSuggestSupported={isGptSuggestSupported}
                    refreshReview={refreshReview}
                    replyAreaRef={replyAreaRef}
                    reviewData={review}
                    shouldCollapseOnClose={isCollapsedAnswers}
                />
            )}

            {suggestion && isNotesAndLogsHidden && (
                <div className={styles.review__answer_list_item}>
                    <SuggestionItem
                        refreshReview={refreshReview}
                        refreshSuggestions={refreshSuggestions}
                        reviewId={reviewId}
                        suggestion={suggestion}
                    />
                </div>
            )}

            {(isRepliesActive || isCollapsedAnswers) && isNotesAndLogsHidden && (
                <ul className={classNames(styles.review__answer_list, {[styles.disabled]: isDeleted})}>
                    <AnswersBunch
                        answerList={children}
                        createdInCatalog={createdInCatalog}
                        isFirst
                        isOnlyLastAnswer={isCollapsedAnswers && !isRepliesActive}
                        isTestBrand={brand.isTest}
                        refreshReview={refreshReview}
                        replyTimeInCatalog={replyTimeInCatalog}
                        replyTimeInRd={replyTimeInRd}
                        reviewId={reviewId}
                    />
                </ul>
            )}
        </>
    );
}
