import {faDesktop, faMobileAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/locale';
import {
    PostFormFieldsEnum,
    PostFormType,
    PostPreviewOptionsType,
    PostSourceEnum,
} from '../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../typings/antd';

import {CatalogPostPreview} from './components/catalog-post-preview/catalog-post-preview';
import {PostPreviewMobileOverlay} from './components/post-preview-mobile-overlay/post-preview-mobile-overlay';
import {PostPreviewOverlay} from './components/post-preview-overlay/post-preview-overlay';
import * as styles from './post-preview.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    catalog: PostSourceEnum;
    sources?: PostPreviewOptionsType;
};

export function PostPreview(props: PropsType): JSX.Element {
    const {catalog, formInstance, sources} = props;

    const postData = (formInstance.getFieldValue(PostFormFieldsEnum.Posts) || []).find(
        (post) => post.catalog === catalog
    );

    const excludedMobileCatalog = new Set([
        PostSourceEnum.yandex,
        PostSourceEnum.google,
        PostSourceEnum.vkontakte,
        PostSourceEnum.facebook,
    ]); // todo: remove after implementation all catalog's mobile preview

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenMobile, setIsOpenMobile] = useState<boolean>(false);

    return (
        <div className={styles.PostPreview}>
            <div className={styles.PostPreview_content}>
                <Text large secondary stringKey="POSTS_FORM__PREVIEW__TITLE" />
                <div className={styles.PostPreview_actions}>
                    <Button className={styles.PostPreview_action} onClick={() => setIsOpen(true)} size="small">
                        <FontAwesomeIcon icon={faDesktop} />
                        <span>
                            <Locale stringKey="POSTS_FORM__PREVIEW__DESKTOP" />
                        </span>
                    </Button>
                    <Button
                        className={styles.PostPreview_action}
                        disabled={excludedMobileCatalog.has(catalog)}
                        onClick={() => setIsOpenMobile(true)}
                        size="small"
                    >
                        <FontAwesomeIcon icon={faMobileAlt} />
                        <span>
                            <Locale stringKey="POSTS_FORM__PREVIEW__MOBILE" />
                        </span>
                    </Button>
                </div>
            </div>
            <Text secondary stringKey="POSTS_FORM__PREVIEW__INFORMATION" />
            {isOpen && (
                <PostPreviewOverlay onClose={() => setIsOpen(false)}>
                    <CatalogPostPreview catalog={catalog} postData={postData} sources={sources} />
                </PostPreviewOverlay>
            )}
            {isOpenMobile && !excludedMobileCatalog.has(catalog) && (
                <PostPreviewMobileOverlay onClose={() => setIsOpenMobile(false)}>
                    <CatalogPostPreview catalog={catalog} postData={postData} sources={sources} />
                </PostPreviewMobileOverlay>
            )}
        </div>
    );
}
