import {Button, List} from 'antd';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {useGoogleProcessingCompanies} from '../../../../../../service/google/google-hook';
import {GoogleCclStatusEnum} from '../../../../../../service/google/google-type';
import {googleProcessingLocationsLoadingStatuses} from '../../../../../../service/google/processing/google-processing-const';
import {GoogleProcessingStatusEnum} from '../../../../../../service/google/processing/google-processing-type';
import {classNames} from '../../../../../../util/css';
import * as stepStyles from '../../common/step-styles/step-styles.scss';
import {GoogleAccountSyncContext} from '../../google-account-sync-context';

import {ProcessingStepHeader} from './header/processing-step-header';
import {ProcessingStepListHeader} from './list-header/processing-step-list-header';
import {ProcessingStepListItem} from './list-item/processing-step-list-item';
import {ProcessingStepLocationModal} from './location-modal/processing-step-location-modal';
import {processingStepCancelModalConfig} from './processing-step-const';
import {getProcessingStepTips} from './processing-step-helper';
import {ManualBindingType} from './processing-step-type';
import {ProcessingStepSkeleton} from './skeleton/processing-step-skeleton';
import * as styles from './processing-step.scss';

type PropsType = {
    onFinish: () => void;
};

export function ProcessingStep(props: PropsType): JSX.Element {
    const {onFinish} = props;

    const {
        status: processingStatus,
        setHeaderButtons,
        setTips,
        deleteKeyAndGoToSources,
    } = useContext(GoogleAccountSyncContext);

    const [locationPopupCompany, setLocationPopupCompany] = useState<number | null>(null);
    const [manualBinding, setManualBinding] = useState<ManualBindingType>(new Map());

    const {companyName} = useDomainConfig();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    const {
        locationsLoadingProgress,
        companiesBindingProgress,
        actionRequiredCompaniesLength,
        pagination: {page: current, pageSize, total, onChange},
        onlyNeedAction,
        setOnlyNeedAction,
    } = useGoogleProcessingCompanies();

    const companies = useMemo(
        () =>
            companiesBindingProgress.data?.companies.map((company) => ({
                ...company,
                status: manualBinding.get(company.id)?.status ?? company.status,
            })) ?? [],
        [companiesBindingProgress.data?.companies, manualBinding]
    );

    const handleCancel = useCallback(() => {
        modal.confirm({
            ...processingStepCancelModalConfig,
            onOk: () => deleteKeyAndGoToSources(),
        });
    }, [deleteKeyAndGoToSources, modal]);

    useEffect(() => {
        if (processingStatus === GoogleProcessingStatusEnum.LocationsLoadingFailed) {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                duration: null,
            });
        }
    }, [processingStatus, snackbar]);

    useEffect(() => {
        setHeaderButtons(() =>
            processingStatus === GoogleProcessingStatusEnum.ProcessingFinished ? (
                <>
                    <Button onClick={() => handleCancel()}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>

                    <Button
                        disabled={manualBinding.size < actionRequiredCompaniesLength}
                        onClick={() => onFinish()}
                        type="primary"
                    >
                        <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__FINISH" />
                    </Button>
                </>
            ) : (
                <Button className={styles.ProcessingStep_cancelButton} onClick={() => handleCancel()}>
                    <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__CANCEL" />
                </Button>
            )
        );
    }, [actionRequiredCompaniesLength, handleCancel, manualBinding.size, onFinish, processingStatus, setHeaderButtons]);

    useEffect(() => {
        setTips(getProcessingStepTips(companies, companyName));
    }, [companies, setTips, companyName]);

    function getProgress() {
        if (processingStatus && googleProcessingLocationsLoadingStatuses.includes(processingStatus)) {
            return {
                completed: locationsLoadingProgress.data?.processed ?? 0,
            };
        }

        return {
            completed: companiesBindingProgress.data?.processed ?? 0,
            total: companiesBindingProgress.data?.total ?? 0,
        };
    }

    function handleManualBinding(companyId: number, status: GoogleCclStatusEnum, locationId?: string) {
        setManualBinding(new Map(manualBinding.set(companyId, {status, locationId})));
        setLocationPopupCompany(null);

        if (actionRequiredCompaniesLength === 1) {
            setOnlyNeedAction(false);
        }
    }

    const progress = getProgress();
    const popupCompany = companies.find((company) => company.id === locationPopupCompany);

    return (
        <>
            {popupCompany && (
                <ProcessingStepLocationModal
                    company={popupCompany}
                    locationId={manualBinding.get(popupCompany.id)?.locationId}
                    onCancel={() => setLocationPopupCompany(null)}
                    onOk={(locationId, status) => handleManualBinding(popupCompany.id, status, locationId)}
                    onSkip={() => handleManualBinding(popupCompany.id, GoogleCclStatusEnum.Skipped)}
                    open={Boolean(locationPopupCompany)}
                />
            )}

            <ProcessingStepHeader
                completed={progress.completed}
                noActionsLeft={actionRequiredCompaniesLength === 0}
                total={progress.total}
            />

            {processingStatus && !googleProcessingLocationsLoadingStatuses.includes(processingStatus) && (
                <List
                    className={classNames(stepStyles.List, {
                        [styles.ProcessingStep_list__notEmpty]: companies.length > 0,
                    })}
                    dataSource={
                        processingStatus === GoogleProcessingStatusEnum.ProcessingActive
                            ? [...companies, {skeleton: true}]
                            : companies
                    }
                    header={
                        processingStatus === GoogleProcessingStatusEnum.ProcessingFinished ? (
                            <ProcessingStepListHeader
                                actionRequiredCompaniesLength={actionRequiredCompaniesLength}
                                companiesLength={companiesBindingProgress.data?.total ?? 0}
                                setShowOnlyActionRequired={setOnlyNeedAction}
                                showOnlyActionRequired={onlyNeedAction}
                            />
                        ) : null
                    }
                    pagination={{hideOnSinglePage: true, current, pageSize, total, onChange}}
                    renderItem={(company) =>
                        'skeleton' in company ? (
                            <ProcessingStepSkeleton />
                        ) : (
                            <ProcessingStepListItem
                                company={company}
                                editBinding={(companyId) => setLocationPopupCompany(companyId)}
                                manualBinding={manualBinding}
                                status={processingStatus}
                            />
                        )
                    }
                    size="small"
                />
            )}

            {processingStatus === GoogleProcessingStatusEnum.ProcessingFinished && (
                <div className={stepStyles.Navigation}>
                    <Button
                        disabled={manualBinding.size < actionRequiredCompaniesLength}
                        onClick={() => onFinish()}
                        type="primary"
                    >
                        <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__FINISH" />
                    </Button>
                </div>
            )}
        </>
    );
}
