import {classNames} from '../../../../../../../util/css';
import {findString} from '../../../../../../../util/string';

import * as styles from './select-categories-form-item.scss';

export function highlightOption(input: string, searchQuery: string): Array<JSX.Element> {
    return findString(input, searchQuery).map((part: string, index: number): JSX.Element => {
        const partClassName = classNames({
            [styles.selected_category_list__highlighted_part]: part.toLowerCase() === searchQuery.toLowerCase(),
        });

        return (
            <span className={partClassName} key={String(index) + part}>
                {part}
            </span>
        );
    });
}
