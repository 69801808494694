import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {ReviewCatalogType} from '../../../../service/reviews/reviews-catalogs';

import * as styles from './review-catalog-logo.scss';

type PropsType = {
    catalog: ReviewCatalogType | null;
    isCatalogLoading: boolean;
};

export function ReviewCatalogLogo(props: PropsType): JSX.Element | null {
    const {catalog, isCatalogLoading} = props;

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    if (isCatalogLoading) {
        return (
            <div className={styles.review_catalog_logo__loading}>
                <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
        );
    }

    if (!catalog) {
        return null;
    }

    return (
        <img
            alt=""
            className={styles.review_catalog_logo}
            src={getCatalogLogo(catalog.id)}
            title={getCatalogName(catalog.id)}
        />
    );
}
