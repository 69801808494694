import {useCallback, useEffect} from 'react';

import {UserType} from '../../service/user/user-type';
import {ShortLocaleNameEnum} from '../locale/locale-context-type';
import {useLocale} from '../locale/locale-hook';

declare global {
    interface Window {
        usetifulTags: Record<string, unknown>;
        USETIFUL: Record<string, unknown>;
    }
}

enum UsetifulRoleEnum {
    Test = 'Test',
    DoubleGis = '2gis',
    RD = 'RD',
    Staff = 'Staff',
}

type UsetifulHookType = {
    setRole: (user: UserType) => void;
    setTag: (tag: Record<string, unknown>) => void;
    removeTag: (tagName: string) => void;
};

export function useUsetiful(): UsetifulHookType {
    const {shortLocaleName} = useLocale();

    const setTag = useCallback((tag: Record<string, unknown>) => {
        window.usetifulTags = {
            ...window.usetifulTags,
            ...tag,
        };
    }, []);

    const removeTag = useCallback((tagName: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.USETIFUL?.user.removeTag(tagName);
    }, []);

    const getRole = useCallback((user: UserType) => {
        if (user.isTestUser) {
            return UsetifulRoleEnum.Test;
        }

        if (user.isDoubleGisUser) {
            return UsetifulRoleEnum.DoubleGis;
        }

        if (user.isStaff) {
            return UsetifulRoleEnum.Staff;
        }

        if (user.isRocketdataUser) {
            return UsetifulRoleEnum.RD;
        }

        return null;
    }, []);

    const setRole = useCallback(
        (user: UserType) => {
            window.usetifulTags = {
                ...window.usetifulTags,
                Role: getRole(user),
                userId: user.id,
                url: window?.location?.href,
                canCreateMailingListsLicence: String(user.mailingListsLicenceSettings.canCreateMailingListsLicence),
                mailingListLicencesConnected: String(user.mailingListsLicenceSettings.mailingListLicencesConnected),
            };
        },
        [getRole]
    );

    const setLang = useCallback((lang: ShortLocaleNameEnum) => {
        window.usetifulTags = {
            ...window.usetifulTags,
            Lang: lang.toUpperCase(),
        };
    }, []);

    useEffect(() => {
        setLang(shortLocaleName);
    }, [setLang, shortLocaleName]);

    return {setRole, setTag, removeTag};
}
