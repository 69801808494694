import {List} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {LangKeyType} from '../../../../../../../provider/locale/translation/type';

import {StatisticListItem} from './statistic-list-item/statistic-list-item';
import {StatisticItemType} from './statistic-list-type';
import * as styles from './statistic-list.scss';

type PropsType = {
    dataSource: Array<StatisticItemType>;
    header: LangKeyType;
    boldHeader?: boolean;
    count: number;
};

export function StatisticList(props: PropsType): JSX.Element | null {
    const {dataSource, header, boldHeader, count} = props;

    const filteredDataSource = dataSource.filter(({value}) => value);

    if (filteredDataSource.length === 0) {
        return null;
    }

    return (
        <List
            bordered
            dataSource={dataSource.filter(({value}) => value)}
            header={
                <div className={styles.StatisticList_header}>
                    <Text bold={boldHeader} bolder={!boldHeader} stringKey={header} />

                    <Text className={styles.StatisticList_badgeWrapper} secondary>
                        {count}
                    </Text>
                </div>
            }
            renderItem={StatisticListItem}
        />
    );
}
