export const enGoogleSyncTranslations = {
    GOOGLE_SYNC__TITLE: 'Google Business Profile linking',
    GOOGLE_SYNC__STEPS__ACCOUNTS: 'Accounts',
    GOOGLE_SYNC__STEPS__ACCOUNTS__DESCRIPTION: 'Account selection',
    GOOGLE_SYNC__STEPS__COMPANIES: 'Companies',
    GOOGLE_SYNC__STEPS__COMPANIES__DESCRIPTION: 'Select from {companyName} account',
    GOOGLE_SYNC__STEPS__PROCESSING: 'Processing',
    GOOGLE_SYNC__STEPS__PROCESSING__DESCRIPTION: 'Linking and sync',
    GOOGLE_SYNC__STEPS__FINISH: 'Finishing',
    GOOGLE_SYNC__STEPS__FINISH__DESCRIPTION: 'Results and confirmation',
    GOOGLE_SYNC__ACCOUNTS_STEP__TITLE: 'Account for linking companies',
    GOOGLE_SYNC__ACCOUNTS_STEP__ACCOUNT_HEADER: 'Account for authorization in Google Business Profile',
    GOOGLE_SYNC__ACCOUNTS_STEP__LINK: 'Link',
    GOOGLE_SYNC__ACCOUNTS_STEP__CHANGE: 'Change',
    GOOGLE_SYNC__ACCOUNTS_STEP__FOUND: 'Found: {total}',
    GOOGLE_SYNC__ACCOUNTS_STEP__ROLE: 'Role: {role}',
    GOOGLE_SYNC__ACCOUNTS_STEP__LINK_FIRST_ACCOUNT: 'Link your Google Business Profile account',
    GOOGLE_SYNC__ACCOUNTS_STEP__LINK_ANOTHER_ACCOUNT: 'Link a new account',
    GOOGLE_SYNC__ACCOUNTS_STEP__ERROR__NOT_SELECTED: 'Please select an account to link',
    GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__TITLE: 'Choose a Google Business Profile account',
    GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__SEARCH_FOOTER: 'Showing {searchAccounts} of {total} choices',
    GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__SEARCH_PLACEHOLDER: 'Search by account name...',
    GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__RESET_FILTER: 'Reset filter',
    GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__SELECT_GROUP:
        'Select a group in your Google Business Profile account to which the locations will be linked.',
    GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__ONLY_PERSONAL: 'We recommend that you create a new address group in GBP.',
    GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__PROBLEM: 'Login information is invalid',
    GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__PROBLEM__MESSAGE:
        'Before continuing to link locations, reconnect your Google Business Profile account or use a different account',
    GOOGLE_SYNC__ACCOUNTS_STEP__FILTER__ALL: 'All groups/accounts',
    GOOGLE_SYNC__ACCOUNTS_STEP__FILTER__PERSONAL: 'Personal account',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__PERSONAL: 'Personal',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__LOCATION_GROUP: 'Group',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__USER_GROUP: 'User group',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__ORGANIZATION: 'Organization',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__UNSPECIFIED: 'Unspecified',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__TOOLTIP__VERIFIED_GROUP: 'Verified group',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__TOOLTIP__NOT_VERIFIED_GROUP: 'Not verified group',
    GOOGLE_SYNC__ACCOUNTS_STEP__TAG__TOOLTIP__VERIFICATION_REQUESTED_GROUP: 'Group with requested confirmation',
    GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__PRIMARY_OWNER: 'Administrator',
    GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__OWNER: 'Owner',
    GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__MANAGER: 'Manager',
    GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__SITE_MANAGER: 'Site manager',
    GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__UNSPECIFIED: 'Unspecified',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__NO_ACCOUNT__TITLE: 'Account linking',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__NO_ACCOUNT__TEXT:
        'Please sign in to one or more Google accounts to link locations.',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__TITLE: 'Address group',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__TEXT: 'If you have 10 or more locations, use a group of addresses.',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__LINK: 'How to create a group',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__MANAGER__TITLE: 'Address group manager',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__MANAGER__TEXT:
        'The role of the manager is limited. We recommend getting more rights, or using a different group.',
    GOOGLE_SYNC__ACCOUNTS_STEP__TIP__MORE: 'Learn more',
    GOOGLE_SYNC__COMPANIES_STEP__TITLE: 'Companies to link',
    GOOGLE_SYNC__COMPANIES_STEP__ALERT__SELECT_COMPANIES:
        'Select companies from {companyName} to sync with your Google Business Profile. Use the filters to find the companies you need.',
    GOOGLE_SYNC__COMPANIES_STEP__START_SYNC: 'Start linking',
    GOOGLE_SYNC__COMPANIES_STEP__FILTER__COMPANIES__LABEL: 'Search companies',
    GOOGLE_SYNC__COMPANIES_STEP__FILTER__COMPANIES__PLACEHOLDER: 'Code, address, phone...',
    GOOGLE_SYNC__COMPANIES_STEP__FILTER__BRANDS__LABEL: 'Brands',
    GOOGLE_SYNC__COMPANIES_STEP__FILTER__BRANDS__PLACEHOLDER: 'All brands',
    GOOGLE_SYNC__COMPANIES_STEP__FILTER__EMPTY: 'There is nothing for the given filters.',
    GOOGLE_SYNC__COMPANIES_STEP__FOUND: 'Found companies: {total}',
    GOOGLE_SYNC__COMPANIES_STEP__SELECTED: 'Selected: {selected} / {total}',
    GOOGLE_SYNC__COMPANIES_STEP__DESELECT_ALL: 'Deselect',
    GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__TITLE: 'Start linking companies?',
    GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__CONTENT:
        'Once you start linking, you will not be able to go back to selecting companies or accounts.',
    GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__NOTE:
        'The process of linking and synchronizing with Google Business Profile can take up to 30 minutes.',
    GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__COMPANIES: 'Companies',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__TITLE: 'Linking 10 or more locations',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__SUBHEADER: 'Use the location group!',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__NOT_SUPPORTED:
        'Mass confirmation of locations in a personal account is not supported. Please select a location group or create a new one in your Google Business Profile.',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__MASS_MATCHING:
        'You can read more about mass confirmation of locations {link}.',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__MOVE_TO_GROUP:
        'You can read about transferring locations to the created group of locations {link}.',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__LINK: 'here',
    GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__CHOOSE_A_GROUP: 'Select group',
    GOOGLE_SYNC__PROCESSING_STEP__TITLE__IN_PROGRESS: 'Wait for processing to complete',
    GOOGLE_SYNC__PROCESSING_STEP__TITLE__FINISHED: 'Processing completed',
    GOOGLE_SYNC__PROCESSING_STEP__ALERT__IN_PROGRESS:
        'Wait for the end of automatic processing and, if necessary, link companies to locations manually.',
    GOOGLE_SYNC__PROCESSING_STEP__ALERT__FINISHED:
        'Some companies require you to link to the appropriate locations manually. After that, you can move on to the results and confirmation.',
    GOOGLE_SYNC__PROCESSING_STEP__ALERT__NO_ACTIONS:
        'All companies are successfully linked. You can double-check the links and move on to the results and confirmation.',
    GOOGLE_SYNC__PROCESSING_STEP__PROGRESSBAR__TITLE: 'Step {step} of {steps}',
    GOOGLE_SYNC__PROCESSING_STEP__PROGRESSBAR__STEP1: 'Downloading locations from your account',
    GOOGLE_SYNC__PROCESSING_STEP__PROGRESSBAR__STEP2: 'Linking companies: {completed} / {total}',
    GOOGLE_SYNC__PROCESSING_STEP__LIST_HEADER__ACTION_REQUIRED: 'Action required: {actionRequired} / {total}',
    GOOGLE_SYNC__PROCESSING_STEP__SHOW_ONLY_ACTION_REQUIRED: 'Show only requiring an action',
    GOOGLE_SYNC__PROCESSING_STEP__CHOOSE_FROM_ACCOUNT: 'Select the desired location in your Google Business Profile.',
    GOOGLE_SYNC__PROCESSING_STEP__CHOOSE_FROM_MAPS: 'Select the desired location from the existing ones on the maps.',
    GOOGLE_SYNC__PROCESSING_STEP__CREATE_NEW: 'A new location will be created',
    GOOGLE_SYNC__PROCESSING_STEP__CANCEL: 'Stop linking',
    GOOGLE_SYNC__PROCESSING_STEP__CANCEL_MODAL__TITLE: 'Stop linking?',
    GOOGLE_SYNC__PROCESSING_STEP__CANCEL_MODAL__CONTENT:
        'It will only affect companies that have not yet been bound. Companies that have already been processed will not be affected, the linking will remain in place.',
    GOOGLE_SYNC__PROCESSING_STEP__CANCEL_MODAL__CONFIRM: 'Stop',
    GOOGLE_SYNC__PROCESSING_STEP__FINISH: 'Finish',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__HAS_OWNER: 'Has owner',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__NO_OWNER: 'No owner',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__CONFIRMED: 'Confirmed',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__NOT_CONFIRMED: 'Not confirmed',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__SKIPPED: 'Not Linked',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__SKIPPED__DESCRIPTION: 'You canceled the company link',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED: 'Linked',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED__DESCRIPTION:
        'The corresponding location was found in the source and successfully linked to the {companyName} cabinet.',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__READY_TO_SEND: 'Ready to send',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__READY_TO_SEND__DESCRIPTION:
        'The location will be created and added to the source after linking is complete.',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__SENT: 'Sent',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__SENT__DESCRIPTION:
        'The location has been created and added to the source. Synchronization will happen later.',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__ACTION_REQUIRED: 'Action required',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__ACTION_REQUIRED__DESCRIPTION:
        'Could not find a direct match of locations. Choose what you want to do.',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_RIGHTS: 'Waiting for management rights',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_RIGHTS__DESCRIPTION:
        'You have requested management rights for a location that already has an owner assigned. Synchronization will happen later.',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_CONFIRMATION: 'Awaiting confirmation',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_CONFIRMATION__DESCRIPTION:
        'You can confirm ownership of the location in the catalog after the linking process.',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__WARNING: 'Warning',
    GOOGLE_SYNC__PROCESSING_STEP__STATUS__WARNING__DESCRIPTION:
        'In some cases, changing your company name or address will require an additional confirmation in Google Business Profile.',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__TITLE: 'Select Location for linking',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__SKIP: 'Do not link',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__HEADER: 'Choose the appropriate location',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__DESCRIPTION__MAPS:
        'We found several similar locations already placed on the maps. If the location already has an owner assigned on the maps, you will need to request management rights.',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__DESCRIPTION__ACCOUNT:
        'We found several locations in your account. If the location is not confirmed, it can be confirmed later.',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__COMPANY: 'Company in {companyName} and location in the catalog',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__CHOOSE: 'Select a location from the list on the right',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__LIST_HEADER__MAPS: 'Locations on maps ({count})',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__LIST_HEADER__ACCOUNT: 'Locations in the account ({count})',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__FILTER: 'Filter by address...',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__NEW__TITLE: 'Create new',
    GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__NEW__DESCRIPTION: 'A new location will be created at this address.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__TITLE: 'Request permission to manage a location',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__SUBHEADER: 'This location already has a verified owner.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__DESCRIPTION_1:
        'You can request location management rights on the corresponding page in your Google Business Profile.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__DESCRIPTION_2:
        'Before sending the request, make sure you are signed in with the same {workAccount} used for linking.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__WORK_ACCOUNT: 'work Google account',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__CLAIM: 'Request rights in Google Business Profile',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__MORE__TITLE: 'Learn more',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__MORE__DESCRIPTION_1:
        'Response timeout is {responseTime}. If the owner does not respond within the specified period, then you will have the opportunity to confirm the location in Google Business Profile.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__MORE__DESCRIPTION_2: 'You can read more about obtaining rights {link}.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__RESPONSE_TIME: '3-7 days',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__NOT_SURE:
        'If you are not sure about the correct choice, you can create a new location.',
    GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__CANCEL: 'Change choice',
    GOOGLE_SYNC__FINISH_STEP__TITLE__CONFIRMED: 'Complete! Companies have been linked and verified',
    GOOGLE_SYNC__FINISH_STEP__TITLE__ACTION_REQUIRED: 'Linking completed! Confirmation required',
    GOOGLE_SYNC__FINISH_STEP__ALERT__CONFIRMED: 'You have completed the linking and syncing process.',
    GOOGLE_SYNC__FINISH_STEP__ALERT__ACTION_REQUIRED:
        'You have completed the linking and syncing process. Some companies may require proof of ownership or request for rights.',
    GOOGLE_SYNC__FINISH_STEP__BUTTON__FINISH: 'Back to sources',
    GOOGLE_SYNC__FINISH_STEP__BUTTON__CONFIRM: 'Go to confirmation',
    GOOGLE_SYNC__FINISH_STEP__STATICSICS__LINKED: 'Linked',
    GOOGLE_SYNC__FINISH_STEP__STATICSICS__CONFIRMED: 'Confirmed',
    GOOGLE_SYNC__FINISH_STEP__STATICSICS__NOT_CONFIRMED: 'NOT confirmed',
    GOOGLE_SYNC__FINISH_STEP__CONFIRMED_LOCATIONS: 'Confirmed locations',
    GOOGLE_SYNC__FINISH_STEP__NOT_CONFIRMED_LOCATIONS: 'Unconfirmed locations',
    GOOGLE_SYNC__FINISH_STEP__TOOLTIP_WAITING: 'Waiting for more information from client',
    GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION: 'Application for mass confirmation',
    GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION__DESCRIPTION:
        'You do not need to request a confirmation code, we are already working on mass confirmation of locations. We will contact you if necessary.',
    GOOGLE_SYNC__FINISH_STEP__STATUS__ACTION_REQUIRED: 'Confirmation code',
    GOOGLE_SYNC__FINISH_STEP__STATUS__ACTION_REQUIRED__DESCRIPTION:
        'To confirm that you are the owner of the location, request and enter the code received from the source service.',
    GOOGLE_SYNC__FINISH_STEP__STATUS__ACTION_REQUIRED__BUTTON: 'Manual confirmation with code',
    GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS: 'Management rights request',
    GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS__DESCRIPTION:
        'You need to get the rights to manage the location from the current owner in the catalog.',
    GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS__BUTTON: 'Management rights required',
    GOOGLE_SYNC__FINISH_STEP__STATUS__NEED_INFO: 'Waiting for more information',
    GOOGLE_SYNC__FINISH_STEP__STATUS__NEED_INFO__DESCRIPTION:
        'We need additional information about your business in order to complete the application. We will contact you.',
    GOOGLE_SYNC__CONFIRMATIONS__TITLE: 'Unverified companies',
    GOOGLE_SYNC__CONFIRMATIONS__SINGLE__TITLE: 'Single confirmation ({count})',
    GOOGLE_SYNC__CONFIRMATIONS__REQUEST__TITLE: 'Confirmation requests ({count})',
    GOOGLE_SYNC__CONFIRMATIONS__MASS_REQUESTS: 'Confirmation requests',
    GOOGLE_SYNC__CONFIRMATIONS__REQUESTS: 'Requests: {count}',
    GOOGLE_SYNC__CONFIRMATIONS__REQUEST: 'Request № {request}',
    GOOGLE_SYNC__CONFIRMATIONS__SHOW_COMPLETED: 'Show processed',
    GOOGLE_SYNC__CONFIRMATIONS__COMPANIES: 'Companies: {count}',
    GOOGLE_SYNC__CONFIRMATIONS__METHOD__TYPE: 'Type: {method}',
    GOOGLE_SYNC__CONFIRMATIONS__METHOD__MASS: 'Mass confirmation',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__CREATED: 'Created',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__IN_PROGRESS: 'In progress',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__WAITING: 'Waiting',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__COMPLETE: 'Processed',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__CODE_REQUESTED: 'Confirmation code',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__CODE_REQUESTED__DESCRIPTION:
        'To confirm that you are the owner of the location, request and enter the code received from the source service.',
    GOOGLE_SYNC__CONFIRMATIONS__STATUS__AWAITING_VERIFICATION: 'Code requested',
    GOOGLE_SYNC__CONFIRMATIONS__BUTTON__BACK: 'Go to sources',
    GOOGLE_SYNC__CONFIRMATIONS__BUTTON__REQUEST_RIGHTS: 'Request rights',
    GOOGLE_SYNC__CONFIRMATIONS__BUTTON__REQUEST_CODE: 'Request code',
    GOOGLE_SYNC__CONFIRMATIONS__BUTTON__REQUEST_CODE_AGAIN: 'Request code again',
    GOOGLE_SYNC__CONFIRMATIONS__BUTTON__ENTER_CODE: 'Enter code',
    GOOGLE_SYNC__RIGHTS_REQUEST__ALERT: 'Do not request rights again if the previous request has not timed out.',
    GOOGLE_SYNC__CODE_REQUEST__TITLE: 'Location verification code request',
    GOOGLE_SYNC__CODE_REQUEST__SUCCESS__TITLE: 'Request has been sent',
    GOOGLE_SYNC__CODE_REQUEST__SUBTITLE: 'Confirm that you are the owner of the company.',
    GOOGLE_SYNC__CODE_REQUEST__DESCRIPTION: 'Choose how you want to receive the verification code. {link}',
    GOOGLE_SYNC__CODE_REQUEST__DESCRIPTION__LINK: 'More',
    GOOGLE_SYNC__CODE_REQUEST__TYPE__PHONE_CALL: 'Phone',
    GOOGLE_SYNC__CODE_REQUEST__TYPE__SMS: 'SMS',
    GOOGLE_SYNC__CODE_REQUEST__TYPE__EMAIL: 'Email',
    GOOGLE_SYNC__CODE_REQUEST__TYPE__ADDRESS: 'Letter',
    GOOGLE_SYNC__CODE_REQUEST__PHONE_CALL__LABEL: 'Phone number',
    GOOGLE_SYNC__CODE_REQUEST__PHONE_CALL__DESCRIPTION:
        'The robot will call within 10 minutes. If the robot does not get through, then a Google employee will call within a few days.',
    GOOGLE_SYNC__CODE_REQUEST__PHONE_CALL__SUCCESS: 'Expect a call with a confirmation code.',
    GOOGLE_SYNC__CODE_REQUEST__SMS__DESCRIPTION: 'SMS will arrive within 10 minutes.',
    GOOGLE_SYNC__CODE_REQUEST__SMS__SUCCESS: 'Wait for an SMS with a confirmation code.',
    GOOGLE_SYNC__CODE_REQUEST__EMAIL__LABEL: 'Email address',
    GOOGLE_SYNC__CODE_REQUEST__EMAIL__PLACEHOLDER: 'manager@company.com',
    GOOGLE_SYNC__CODE_REQUEST__EMAIL__DESCRIPTION: 'The letter will arrive within 10 minutes.',
    GOOGLE_SYNC__CODE_REQUEST__EMAIL__SUCCESS: 'Wait for an email with a confirmation code.',
    GOOGLE_SYNC__CODE_REQUEST__ADDRESS__LABEL: 'Address',
    GOOGLE_SYNC__CODE_REQUEST__ADDRESS__DESCRIPTION: 'The letter will arrive within 14 days.',
    GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TITLE: 'Code request sent',
    GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TEXT__1: 'The received code can be entered on the {link} page.',
    GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TEXT__2: 'The code is unique for each location.',
    GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TEXT__NEXT_LINK: 'Awaiting confirmation',
    GOOGLE_SYNC__CODE_REQUEST__ALERT: 'Before re-requesting, make sure that the previous request has timed out.',
    GOOGLE_SYNC__CODE_ENTER__TITLE: 'Location confirmation',
    GOOGLE_SYNC__CODE_ENTER__SUBTITLE: 'Enter the verification code for this location',
    GOOGLE_SYNC__CODE_ENTER__DESCRIPTION:
        'Codes are unique for each location. Please enter the code that matches the request below.',
    GOOGLE_SYNC__CODE_ENTER__METHOD: 'Code request method',
    GOOGLE_SYNC__CODE_ENTER__TYPE__PHONE_CALL: 'Phone call',
    GOOGLE_SYNC__CODE_ENTER__TYPE__ADDRESS: 'Post mail',
    GOOGLE_SYNC__CODE_ENTER__DATE: 'Date and time of code request',
    GOOGLE_SYNC__CODE_ENTER__CODE__LABEL: 'Code from the service:',
    GOOGLE_SYNC__CODE_ENTER__CODE__LENGTH_ERROR: 'Invalid code entered.',
    GOOGLE_SYNC__CODE_ENTER__ALERT:
        'If you have received multiple codes, enter the last code you received for that particular location.',
    GOOGLE_SYNC__CODE_ENTER__SUCCESS__TITLE: 'Location confirmed',
    GOOGLE_SYNC__CODE_ENTER__SUCCESS__MESSAGE:
        'Location "{name}" at {address} has been successfully verified and will be linked.',
};
