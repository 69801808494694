import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Table, TablePaginationConfig} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import {useContext, useEffect, useState} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {AlertFallback} from '../../../../../../../component/alert-fallback/alert-fallback';
import {PaginationControls} from '../../../../../../../layout/pagination-controls/pagination-controls';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {MainFilterContext} from '../../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../../provider/main-filter/main-filter-type';
import {useSourcesAccounts} from '../../../../../../../service/source-settings/accounts/accounts-hook';
import {SourcesAccountType} from '../../../../../../../service/source-settings/accounts/accounts-type';
import {CatalogByGroupType} from '../../../../../../../service/source-settings/catalogs/catalogs-type';
import {SourceLinkStatusEnum} from '../../../../../../../service/source-settings/source-links/source-links-type';
import {useFormat} from '../../../../../../../util/format-hook/format-hook';
import {defaultPageSize} from '../../../../../../../util/pagination-hook/pagination-const';
import {EmptyTable} from '../empty-table/empty-table';

import {CATALOGS_WITHOUT_MANUAL_MANAGEMENT, statusFilterKey} from './accounts-table-const';
import {convertToLinkStatusList, getAccountsTableColumns} from './accounts-table-helper';
import {SourceAccountsTableType} from './accounts-table-type';
import {AddAccount} from './add-account/add-account';
import {EditAccount} from './edit-account/edit-account';
import {RemoveAccount} from './remove-account/remove-account';
import * as styles from './accounts-table.scss';

type PropsType = {
    catalog: CatalogByGroupType;
};

// eslint-disable-next-line complexity
export function AccountsTable(props: PropsType): JSX.Element {
    const {catalog} = props;
    const {id, groupId} = useParams<
        ExtractRouteParams<typeof appRoute.settingsSourceSettingsAccount.path, string>
    >() || {
        id: '',
        groupId: 1,
    };
    const {getFormattedDateTime} = useFormat();
    const {companyName} = useDomainConfig();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const [cursor, setCursor] = useState<string | null>(null);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [statusFilter, setStatusFilter] = useState<Array<SourceLinkStatusEnum>>([]);

    const {
        result: accounts,
        isInProgress,
        processError,
        refresh,
    } = useSourcesAccounts({
        cursor,
        catalogId: Number(id),
        filterKey: mainFilterKey,
        pageSize,
        status: statusFilter,
    });

    const isStatusFilterEmpty = statusFilter.length === 0;
    const isEmptyPlaceholderVisible = accounts && accounts.count === 0 && isStatusFilterEmpty;
    const isAddButtonVisible = !CATALOGS_WITHOUT_MANUAL_MANAGEMENT.includes(Number(id));

    useEffect(() => {
        setCursor(null);
        setStatusFilter([]);
    }, [mainFilterKey, pageSize]);

    function onTableChange(_pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>): void {
        const newStatusFilter: Array<SourceLinkStatusEnum> = convertToLinkStatusList(filters[statusFilterKey] || []);

        setCursor(null);
        setStatusFilter(newStatusFilter);
    }

    return (
        <>
            {!isEmptyPlaceholderVisible && (
                <div className={styles.AccountsTable_controls}>
                    <span>
                        <Locale stringKey="TEXT__TOTAL" valueMap={{total: accounts?.count || 0}} />
                    </span>

                    {isAddButtonVisible && (
                        <AddAccount catalog={catalog} catalogGroupId={Number(groupId)} isPrimary onSuccess={refresh} />
                    )}
                    {!isAddButtonVisible && (
                        <span className={styles.AccountsTable_disabledButtonLabel}>
                            <FontAwesomeIcon
                                className={styles.AccountsTable_disabledButtonLabelIcon}
                                icon={faInfoCircle}
                            />
                            <Locale
                                stringKey="SOURCE_SETTINGS__ACCOUNTS__DISABLED_MANUAL_MANAGEMENT"
                                valueMap={{companyName}}
                            />
                        </span>
                    )}
                </div>
            )}

            <div className={styles.AccountsTable_content}>
                <Spinner isShow={isInProgress} position="absolute" />

                {processError && <AlertFallback />}

                {isEmptyPlaceholderVisible && (
                    <EmptyTable
                        subtitle={
                            <Locale
                                stringKey="SOURCE_SETTINGS__ACCOUNTS__DISABLED_MANUAL_MANAGEMENT"
                                valueMap={{companyName}}
                            />
                        }
                        title={<Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__EMPTY__TITLE" />}
                    >
                        {isAddButtonVisible && (
                            <AddAccount
                                catalog={catalog}
                                catalogGroupId={Number(groupId)}
                                isPrimary
                                onSuccess={refresh}
                            />
                        )}
                    </EmptyTable>
                )}

                {accounts && !isEmptyPlaceholderVisible && !processError && (
                    <>
                        <Table<SourceAccountsTableType>
                            columns={getAccountsTableColumns(getFormattedDateTime)}
                            dataSource={accounts.results.map((account: SourcesAccountType) => ({
                                ...account,
                                key: account.id,
                                actions: (
                                    <>
                                        {account.editAbility && (
                                            <EditAccount
                                                account={account}
                                                catalog={catalog}
                                                catalogGroupId={Number(groupId)}
                                                onSuccess={refresh}
                                            />
                                        )}
                                        <RemoveAccount account={account} onSuccess={refresh} />
                                    </>
                                ),
                            }))}
                            onChange={onTableChange}
                            pagination={false}
                            scroll={{x: true}}
                            size="middle"
                        />
                        <PaginationControls
                            onCursorChange={setCursor}
                            onPageSizeChange={setPageSize}
                            pageSize={pageSize}
                            payload={accounts}
                        />
                    </>
                )}
            </div>
        </>
    );
}
