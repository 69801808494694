import {Radio} from 'antd';
import {useState} from 'react';

import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Form} from '../../../../../../../typings/antd';
import {AttributePropsType, EnumAttributeType} from '../attributes-type';

import {limit} from './enum-attribute-const';
import {EnumAttributeToggleButton} from './enum-attribute-toggle-button';
import * as styles from './enum-attribute.scss';

type AttributeValueType = EnumAttributeType['values'][0];

export function EnumSingleAttribute({value, onChange}: AttributePropsType<EnumAttributeType>): JSX.Element {
    const {displayName: label, values: defaultValues} = value;

    const {getLocalizedString} = useLocale();

    const values: Array<Omit<AttributeValueType, 'templateId'> & {templateId?: string | number}> = [
        ...defaultValues,
        // eslint-disable-next-line no-undefined
        {templateId: undefined, displayValue: getLocalizedString('COMPANY_FORM__ATTRIBUTES__BOOLEAN__UNKNOWN')},
    ];

    const [showAll, setShowAll] = useState(values.length <= limit);

    return (
        <Form.Item label={label}>
            <Radio.Group
                onChange={({target}) =>
                    onChange?.({
                        ...value,
                        values: values
                            .filter((maybeValue): maybeValue is AttributeValueType => Boolean(maybeValue.templateId))
                            .map((item) => ({...item, selected: target.value === item.templateId})),
                    })
                }
                value={values.find((item) => item.selected)?.templateId}
            >
                <div className={styles.enum_attribute}>
                    {(showAll ? values : values.slice(0, limit)).map((option) => (
                        <Radio key={String(option.templateId)} value={option.templateId}>
                            {option.displayValue}
                        </Radio>
                    ))}
                </div>

                {values.length > limit && <EnumAttributeToggleButton setShowAll={setShowAll} showAll={showAll} />}
            </Radio.Group>
        </Form.Item>
    );
}
