import {Typography} from 'antd';

import {appRoute} from '../../../app-route';
import {SimpleHeader} from '../../../component/header/simple-header/simple-header';
import {Meta} from '../../../component/meta/meta';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../provider/locale/locale';
import * as errorStyles from '../error.scss';

const {Text} = Typography;

export function ErrorPage404(): JSX.Element {
    return (
        <div className={errorStyles.error_wrapper}>
            <Meta title="[ERROR]: Page 404" />

            <SimpleHeader />

            <Text className={errorStyles.error_header} code>
                [ERROR]: Page&nbsp;404
            </Text>

            <p className={errorStyles.text}>
                <NavigationLink className={errorStyles.to_home_link} to={appRoute.root.path}>
                    <Locale stringKey="PAGE__ERROR__LINK_TO_HOME" />
                </NavigationLink>
            </p>
        </div>
    );
}
