import {faPaperPlane} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useState} from 'react';

import {ShortCatalogType} from '../../../../../provider/catalogs/catalogs-type';

import {PostPublishSummary} from './components/post-publish-summary/post-publish-summary';
import * as styles from './post-actions.scss';

type PropsType = {
    postId: number;
    postSources: Array<ShortCatalogType>;
    onActionCompleted: () => void;
    isDisabled: boolean;
};

export function PostActionPublish(props: PropsType): JSX.Element {
    const {postId, postSources, onActionCompleted, isDisabled} = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button disabled={isDisabled} onClick={() => setIsOpen(true)} size="small" type="link">
                <FontAwesomeIcon className={styles.PostActions_icon} icon={faPaperPlane} />
            </Button>
            {isOpen && (
                <PostPublishSummary
                    isOpen={isOpen}
                    onActionCompleted={onActionCompleted}
                    onClose={() => setIsOpen(false)}
                    postId={postId}
                    postSources={postSources}
                />
            )}
        </>
    );
}
