import dayjs from 'dayjs';

import {
    DateRangeType,
    getCloserFullMonth,
    getCloserFullQuarter,
    getCloserFullWeekRange,
} from '../../../../../../util/date';

import {ANALYTICS_DELAY_DAYS} from './keywords-and-topics-const';
import {KeywordsAndTopicsFormTypePeriodEnum} from './keywords-and-topics-type';

const AnalyticsKeywordsAndTopicsFormMap: Record<KeywordsAndTopicsFormTypePeriodEnum, string> = {
    [KeywordsAndTopicsFormTypePeriodEnum.Custom]: 'Custom',
    [KeywordsAndTopicsFormTypePeriodEnum.Day]: 'Day',
    [KeywordsAndTopicsFormTypePeriodEnum.Month]: '30 Days',
    [KeywordsAndTopicsFormTypePeriodEnum.PreviousFullMonth]: 'Month',
    [KeywordsAndTopicsFormTypePeriodEnum.Quarter]: 'Quater',
    [KeywordsAndTopicsFormTypePeriodEnum.Week]: 'Week',
};

export function getAnalyticsRangeByPeriodAndDate(period: KeywordsAndTopicsFormTypePeriodEnum): DateRangeType {
    const lastDay = dayjs().subtract(ANALYTICS_DELAY_DAYS, 'day').minute(0).second(0).millisecond(0).utc(true).toDate();

    switch (period) {
        case KeywordsAndTopicsFormTypePeriodEnum.Week: {
            return getCloserFullWeekRange(lastDay);
        }
        case KeywordsAndTopicsFormTypePeriodEnum.Month: {
            const lastDayDayjs = dayjs(lastDay);

            return {
                start: lastDayDayjs.subtract(29, 'days').toDate(),
                end: lastDay,
            };
        }
        case KeywordsAndTopicsFormTypePeriodEnum.PreviousFullMonth: {
            return getCloserFullMonth(lastDay);
        }
        case KeywordsAndTopicsFormTypePeriodEnum.Quarter: {
            return getCloserFullQuarter(lastDay);
        }
        default: {
            return {
                start: lastDay,
                end: lastDay,
            };
        }
    }
}

export function getDateAndPeriodForAnalysis(
    range: [Date | null, Date | null],
    periodOption: KeywordsAndTopicsFormTypePeriodEnum
): [string, string] {
    return [`${range[0]} - ${range[1]}`, AnalyticsKeywordsAndTopicsFormMap[periodOption]];
}
