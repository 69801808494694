import {z as r} from 'zod';

import {LicensesCodeEnum} from '../../page/main/tariffs-and-limits/licenses-block/license/license-const';
import {FeaturesEnum} from '../../service/user/user-type';

export const licenseSchema = r.object({
    tariffId: r.number().optional(),
    code: r.nativeEnum(LicensesCodeEnum),
    isActive: r.boolean(),
    isTrial: r.boolean(),
    startAt: r.string().nullable(),
    finishAt: r.string().nullable(),
    canActivateTrial: r.boolean(),
});

export type LicenseType = r.infer<typeof licenseSchema>;

export type LicenseProviderType = {
    licenses: Partial<
        Record<
            Exclude<
                FeaturesEnum,
                | FeaturesEnum.localPack
                | FeaturesEnum.photoTool
                | FeaturesEnum.posts
                | FeaturesEnum.onlinePresenceAnalytics
                | FeaturesEnum.reviewGptReplySuggestions
            >,
            Pick<LicenseType, 'isActive'>
        > & {
            [FeaturesEnum.localPack]?: LicenseType;
            [FeaturesEnum.photoTool]?: LicenseType;
            [FeaturesEnum.onlinePresenceAnalytics]?: LicenseType;
            [FeaturesEnum.posts]?: LicenseType;
            [FeaturesEnum.reviewGptReplySuggestions]?: LicenseType;
        }
    >;
    availableFeatures: Record<FeaturesEnum, boolean>;
    refetch: VoidFunction;
    isLoading: boolean;
};

export type TrialActivationOptionsType = {
    tariffId: number;
    licenseCode: LicensesCodeEnum;
};
