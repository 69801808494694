import {fetchAndDeserialize} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';

import {handleSuggestCatalogError} from './catalogs-helper';
import {catalogByGroupResponseSchema, CatalogGroupType, SuggestedCatalogType} from './catalogs-type';

export async function fetchCatalogsByGroup(groupId?: number): Promise<Array<CatalogGroupType>> {
    const url = `/v1/source_settings/catalogs/${groupId ? '?catalog_group_id=' + groupId : ''}`;

    return fetchAndDeserialize(url, catalogByGroupResponseSchema);
}

export async function fetchCatalogsForAccounts(catalogId?: number): Promise<Array<CatalogGroupType>> {
    const url = `/v1/source_settings/catalogs_for_accounts/${catalogId ? '?catalog_id=' + catalogId : ''}`;

    return fetchAndDeserialize(url, catalogByGroupResponseSchema);
}

export async function suggestCatalog(name: string): Promise<SuggestedCatalogType | void> {
    const url = `${rootApiUrl}/v1/source_settings/suggest_catalog/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body: JSON.stringify({name}),
    }).catch(handleSuggestCatalogError);
}
