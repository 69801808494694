import {useEffect, useRef} from 'react';

import {isAbortError} from '../../../util/error';
import {noop} from '../../../util/function';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchLinkCompanies} from './link-companies-api';
import {LinkCompaniesRequestType, LinkCompaniesResponseType} from './link-companies-type';

function ignoreAbortError(error: unknown) {
    if (isAbortError(error)) {
        return;
    }

    throw error;
}

export function useLinkCompaniesInfinite(
    options: Omit<LinkCompaniesRequestType, 'token' | 'brandId'> & {brandId: number | null}
): UseHookType<LinkCompaniesResponseType> & {
    loadMore: () => void;
} {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<LinkCompaniesResponseType>();
    const controllerRef = useRef<AbortController | null>(null);

    const {brandId, q, count} = options;
    const hasMore = result?.nextPageToken !== null;

    useEffect(() => {
        if (!brandId) {
            return () => noop;
        }

        controllerRef.current?.abort();
        controllerRef.current = new AbortController();
        setIsInProgress(true);
        setProcessError(null);

        fetchLinkCompanies({brandId, q, count, token: null}, controllerRef.current.signal)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(ignoreAbortError)
            .catch(setProcessError);

        return () => {
            controllerRef.current?.abort();
        };
    }, [setIsInProgress, setProcessError, setResult, brandId, q, count]);

    function loadMore(): void {
        if (!brandId || !result || isInProgress || processError || !hasMore) {
            return;
        }

        controllerRef.current?.abort();
        controllerRef.current = new AbortController();
        setIsInProgress(true);

        fetchLinkCompanies({brandId, q, count, token: result.nextPageToken}, controllerRef.current?.signal)
            .then((more) => setResult({...more, companies: [...result.companies, ...more.companies]}))
            .finally(() => setIsInProgress(false))
            .catch(ignoreAbortError)
            .catch(setProcessError);
    }

    return {isInProgress, processError, result, reset, loadMore};
}
