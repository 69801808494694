import {noop} from 'lodash';
import {Context, createContext, PropsWithChildren, useMemo, useState} from 'react';

type KeywordsAndTopicsPageContextType = {
    isFormInProgress: boolean;
    onIsFormInProgressChange: (value: boolean) => void;
};

export const KeywordsAndTopicsPageContext: Context<KeywordsAndTopicsPageContextType> =
    createContext<KeywordsAndTopicsPageContextType>({
        isFormInProgress: false,
        onIsFormInProgressChange: () => noop,
    });

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function KeywordsAndTopicsPageProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const [isFormInProgress, setIsFormInProgress] = useState(false);

    const providerData: KeywordsAndTopicsPageContextType = useMemo(
        () => ({
            isFormInProgress,
            onIsFormInProgressChange: (value) => {
                setIsFormInProgress(value);
            },
        }),
        [isFormInProgress, setIsFormInProgress]
    );

    return (
        <KeywordsAndTopicsPageContext.Provider value={providerData}>{children}</KeywordsAndTopicsPageContext.Provider>
    );
}
