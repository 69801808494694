import {Input, Select, Typography} from 'antd';
import {LabeledValue} from 'antd/lib/select';

import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CountryV2Type, LegalOptionV2Type} from '../../../../../../../service/api/api-type';
import {useLegalOptionDataV2List} from '../../../../../../../service/api-hook/api-hook';

const {Text} = Typography;
const {Option} = Select;

type PropsType = {
    value?: LabeledValue | null;
    onChange?: (value: LabeledValue | null) => void;
    country: CountryV2Type | null;
};

export function OrganizationalFormItem(props: PropsType): JSX.Element {
    const {value, onChange, country} = props;
    const {getLocalizedString} = useLocale();

    const {result, isInProgress: isLegalOptionsInProgress} = useLegalOptionDataV2List();
    const legalOptionDataList = result?.find(({alpha2}) => alpha2 === country?.countryCode)?.legal_forms;

    if (isLegalOptionsInProgress) {
        return <Input size="large" value={getLocalizedString('LOADING_THREE_POINTS')} />;
    }

    function getDisplayedValue(): LabeledValue | undefined {
        if (!value || !legalOptionDataList) {
            return;
        }

        const foundOption = legalOptionDataList.find((option) => option.form_id === value.value);

        if (!foundOption) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return {value: foundOption.form_id, label: foundOption.form_name};
    }

    return (
        <Select<LabeledValue | undefined>
            disabled={!legalOptionDataList || legalOptionDataList.length === 0}
            labelInValue
            onChange={(selected: LabeledValue | undefined) => {
                if (!selected) {
                    return;
                }

                onChange?.(selected);
            }}
            placeholder={<Locale stringKey="COMPANY_FORM__LEGAL__ORGANIZATIONAL_AND_LEGAL_FORM__PLACEHOLDER" />}
            size="large"
            value={getDisplayedValue()}
        >
            <Option key="no-select-data" value="">
                <Text disabled>
                    <Locale stringKey="COMPANY_FORM__LEGAL__ORGANIZATIONAL_AND_LEGAL_FORM__PLACEHOLDER" />
                </Text>
            </Option>

            {legalOptionDataList?.map((legalOptionData: LegalOptionV2Type): JSX.Element => {
                const {form_id, form_name} = legalOptionData;

                return (
                    <Option key={form_id} value={form_id}>
                        {form_name}
                    </Option>
                );
            })}
        </Select>
    );
}
