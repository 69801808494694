import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover, PopoverProps} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';
import {PropsWithChildren, useMemo} from 'react';

import {classNames} from '../../util/css';

import * as styles from './additional-info.scss';

type PropsType = PropsWithChildren<{
    title?: JSX.Element | string;
    className?: string;
    leftNode?: JSX.Element | string;
    rightNode?: JSX.Element | string;
    icon?: JSX.Element;
    placement?: TooltipPlacement;
    align?: PopoverProps['align'];
    leftWrapperClassName?: string;
}>;

export function AdditionalInfo(props: PropsType): JSX.Element {
    const {children, className, leftNode, rightNode, title, icon, placement, align, leftWrapperClassName} = props;

    const fullClassName = classNames(styles.AdditionalInfo, className);

    const leftTextWrapperFullClassName = classNames(styles.TextWrapper_left, leftWrapperClassName);

    const content = useMemo(() => {
        const image = icon || <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />;

        if (!children) {
            return image;
        }

        const titleNode = title ? <div className={styles.AdditionalInfo_title}>{title}</div> : null;

        return (
            <Popover
                align={align}
                content={<div className={styles.popover_content}>{children}</div>}
                placement={placement}
                title={titleNode}
                trigger="hover"
            >
                {image}
            </Popover>
        );
    }, [icon, children, title, align, placement]);

    return (
        <div className={fullClassName}>
            {Boolean(leftNode) && <div className={leftTextWrapperFullClassName}>{leftNode}</div>}

            {content}

            {Boolean(rightNode) && <div className={styles.TextWrapper_right}>{rightNode}</div>}
        </div>
    );
}
