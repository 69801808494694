import {Empty} from '../../../../layout/empty/empty';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {
    FormattedPeriodsType,
    OnlinePresenceSourcesType,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';

import {SourceOfTransitionCompare} from './components/source-of-transition-compare/source-of-transition-compare';
import {SourceOfTransitionSingle} from './components/source-of-transition-single/source-of-transition-single';
import * as styles from './source-of-transition.scss';

type PropsType = {
    filter: OnlinePresenceV2FilterStateType;
    isCompareMode: boolean;
    data: OnlinePresenceSourcesType;
    catalogName: JSX.Element;
    formattedPeriods?: FormattedPeriodsType;
};

export function SourceOfTransition(props: PropsType): JSX.Element {
    const {isCompareMode, filter, data, catalogName, formattedPeriods} = props;

    const isDataEmpty = data.every((item) => !item.current && !item.compared);

    return (
        <PageCard
            title={
                <div>
                    <Locale
                        stringKey="ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__TITLE"
                        valueMap={{
                            catalogName,
                        }}
                    />
                </div>
            }
        >
            {!isDataEmpty ? (
                <div className={styles.SourceOfTransition}>
                    {!isCompareMode ? (
                        <SourceOfTransitionSingle data={data} filter={filter} />
                    ) : (
                        <SourceOfTransitionCompare data={data} filter={filter} formattedPeriods={formattedPeriods} />
                    )}
                </div>
            ) : (
                <Empty
                    secondaryText="ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__EMPTY"
                    secondaryTextValueMap={{catalogName}}
                />
            )}
        </PageCard>
    );
}
