import {PropsWithChildren, ReactNode, useContext} from 'react';

import {Footer} from '../../component/footer/footer';
import {DesktopHeader} from '../../component/header/desktop-header/desktop-header';
import {MobileHeader} from '../../component/header/mobile-header/mobile-header';
import {Main} from '../../component/main/main';
import {Navigation} from '../../component/navigation/navigation';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {useSystem} from '../../provider/system/system-hook';
import {UiContext} from '../../provider/ui/ui-context';
import {UiContextType} from '../../provider/ui/ui-context-type';
import {useHeaderOptions} from '../../service/header/header-hook';
import {classNames} from '../../util/css';
import {MobileOverlay} from '../mobile-overlay/mobile-overlay';

import {CenteredCardPage} from './centered-card/centered-card-page';
import {ErrorPage} from './error/error-page';
import {LoadingPage} from './loading/loading-page';
import * as styles from './page.scss';

type PropsType = PropsWithChildren<{
    className?: string;
    mainClassName?: string;
    useHardReload?: boolean;
    renderContainer?: (children: ReactNode) => JSX.Element;
    isTopFilterHidden?: boolean;
    footerHidden?: boolean;
}>;

// eslint-disable-next-line complexity
export function Page(props: PropsType): JSX.Element {
    const {
        children,
        className,
        mainClassName,
        useHardReload,
        renderContainer,
        isTopFilterHidden = false,
        footerHidden = false,
    } = props;
    const systemContext = useSystem();
    const uiContext = useContext<UiContextType>(UiContext);
    const {isMobileNavigationOpen, isLeftSideOpen} = uiContext;
    const {isHeaderShown, isMainFilterHidden: isMainFilterHiddenByCompanies} = useHeaderOptions(isTopFilterHidden);
    const {isFooterShow: isFooterAvailableOnDomain, isPageWrapperHasPaddings, isSingleFeature} = useDomainConfig();
    const fullPageClassName = classNames(className, styles.page, {
        [styles.page__without_padding]: !isHeaderShown,
    });

    const isFooterShow = isFooterAvailableOnDomain && !footerHidden;

    const main = renderContainer ? (
        renderContainer(children)
    ) : (
        <Main
            key="main"
            mainClassName={mainClassName}
            wrapperClassName={classNames({
                [styles.page_wrapper__without_padding]: !isPageWrapperHasPaddings,
            })}
        >
            {children}
        </Main>
    );
    const navigation = <Navigation isHeaderShown={isHeaderShown} key="navigation" useHardReload={useHardReload} />;

    if (isSingleFeature) {
        return (
            <Main key="main" wrapperClassName={styles.page_wrapper__without_padding}>
                {children}
            </Main>
        );
    }

    if (systemContext.screen.isDesktop) {
        return (
            <div className={fullPageClassName}>
                {isHeaderShown && (
                    <DesktopHeader isTopFilterHidden={isMainFilterHiddenByCompanies || isTopFilterHidden} />
                )}

                {navigation}

                <div
                    className={classNames(styles.content_wrapper, {
                        [styles.content_wrapper__left_side_open]: isLeftSideOpen,
                        [styles.content_wrapper__without_header]: !isHeaderShown,
                    })}
                    key="main-wrapper"
                >
                    {main}

                    {isFooterShow && <Footer />}
                </div>
            </div>
        );
    }

    return (
        <div className={fullPageClassName}>
            <MobileHeader isTopFilterHidden={isMainFilterHiddenByCompanies || isTopFilterHidden} />
            {isMobileNavigationOpen && (
                <div className={styles.mobile_hidden_content}>
                    <MobileOverlay>{navigation}</MobileOverlay>
                </div>
            )}
            <div className={styles.content_wrapper__mobile} key="main-wrapper">
                {main}

                {isFooterShow && <Footer />}
            </div>
            {isMobileNavigationOpen ? <style>{'body {overflow: hidden;}'}</style> : null}
        </div>
    );
}

Page.CenteredCard = CenteredCardPage;

Page.Loading = LoadingPage;

Page.Error = ErrorPage;
