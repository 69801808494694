import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {boolean, z as r} from 'zod';

import {deserializeV2, fetchAndDeserialize, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

export const QUIZ_STORAGE_KEY = 'passed_quiz';

const questionnaireSchema = r.array(
    r.object({
        id: r.number(),
        multichoice: r.boolean(),
        translatedText: r.string(),
        translatedAnswersOptions: r.array(r.string()),
    })
);

type QuestionnaireType = r.infer<typeof questionnaireSchema>;

type QuestionnaireCreateType = {
    questionId: number;
    text: string;
};

const initialQuestionnaireUrl = `/v1/user/quiz/questions/`;

async function fetchQuestionnaireOptions(): Promise<QuestionnaireType> {
    return fetchAndDeserialize<QuestionnaireType>(initialQuestionnaireUrl, questionnaireSchema);
}

export function useQuestionnaireOptions(): UseQueryResult<QuestionnaireType> {
    return useQuery([fetchQuestionnaireOptions], () => fetchQuestionnaireOptions(), {
        refetchOnWindowFocus: false,
    });
}

export async function fetchQuestionnaireSave(data: QuestionnaireCreateType): Promise<QuestionnaireType> {
    const url = `${rootApiUrl}/v1/user/quiz/answer/`;

    const response = await fetchX<QuestionnaireType>(url, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<QuestionnaireType>(url, questionnaireSchema, response);
}

const checkQuizUrl = `/v1/user/quiz/show_quiz/`;

const showQuizSchema = r.object({
    showQuiz: boolean(),
});

type ShowQuizType = r.infer<typeof showQuizSchema>;

export async function fetchCheckQuiz(): Promise<ShowQuizType> {
    return fetchAndDeserialize<ShowQuizType>(checkQuizUrl, showQuizSchema);
}
