import {LangKeyType} from '../../../../../../../../provider/locale/translation/type';

export const abuseFormId = 'new-abuse-form';

export enum AbuseFormItemEnum {
    Choices = 'choices',
    Description = 'description',
}

export const abuseReasons: Array<{title: LangKeyType; id: number}> = [
    {title: 'REVIEWS__COMPLAINT_POPUP__OFFENSIVE_CONTENT', id: 1},
    {title: 'REVIEWS__COMPLAINT_POPUP__MENTION_COMPETITOR', id: 2},
    {title: 'REVIEWS__COMPLAINT_POPUP__NOT_APPLY_THIS_COMPANY', id: 3},
    {title: 'REVIEWS__COMPLAINT_POPUP__PRIVACY_VIOLATION', id: 4},
];
