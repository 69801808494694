import {z as r} from 'zod';

import {shortCatalogSchema} from '../../provider/catalogs/catalogs-type';
import {cursorPaginationResponseSchemaFactory} from '../api/api-type';
import {TimelineActionTypeEnum} from '../timeline-activity/timeline-activity-type';

export const syncStatisticsResponseSchema = r.object({
    catalog: shortCatalogSchema,
    isVerified: r.boolean(),
    stateStatus: r.string(),
});

export type SyncStatisticsType = r.infer<typeof syncStatisticsResponseSchema>;

export const companyRatingSchema = r.object({
    catalog: shortCatalogSchema,
    rating: r.number(),
});

export type CompanyRatingType = r.infer<typeof companyRatingSchema>;

export const reviewsDashboardStatisticSchema = r.object({
    withAnswer: r.number(),
    withoutAnswer: r.number(),
});

export type ReviewsDashboardStatisticDataType = r.infer<typeof reviewsDashboardStatisticSchema>;

const navigatorsResponseSchema = r.object({
    id: r.number(),
    name: r.string(),
    logo: r.string(),
});

const catalogStatsResponseSchema = r.object({
    id: r.number(),
    fieldName: r.string(),
    value: r.number(),
});

export const catalogsSyncStatisticsResponseSchema = r.object({
    catalogStats: r.object({
        data: catalogStatsResponseSchema.array(),
        score: r.number(),
    }),
    navigators: navigatorsResponseSchema.array(),
});

export type CatalogsSyncCatalogStatsType = r.infer<typeof catalogStatsResponseSchema>;

export type CatalogsSyncNavigatorApiType = r.infer<typeof navigatorsResponseSchema>;

export type CatalogsSyncResponseType = r.infer<typeof catalogsSyncStatisticsResponseSchema>;

const balanceSchema = r.object({
    balance: r.number(),
    email: r.string(),
    userId: r.number(),
});

export const autoRepliesSummaryResponseSchema = r.object({
    autoRepliesAvailable: r.number(),
    spentAutoRepliesCount: r.number(),
    balance: balanceSchema.array(),
});

export type AutoRepliesSummaryType = r.infer<typeof autoRepliesSummaryResponseSchema>;

export const reviewsRatingDistributionListResponseSchema = r.object({
    rating: r.number(),
    count: r.number(),
});

export type ReviewsRatingDistributionType = r.infer<typeof reviewsRatingDistributionListResponseSchema>;

const timelineAggregatedActionSchema = r.object({
    action: r.nativeEnum(TimelineActionTypeEnum),
    count: r.number(),
    catalog: r.optional(shortCatalogSchema),
});

const timelineAggregatedActivityItemSchema = r.object({
    timestamp: r.object({
        start: r.string(),
        end: r.string(),
    }),
    actions: r.array(timelineAggregatedActionSchema),
});

export const timelineAggregatedActivityResponseSchema = cursorPaginationResponseSchemaFactory(
    timelineAggregatedActivityItemSchema
);

export type AggregatedActionType = r.infer<typeof timelineAggregatedActionSchema>;

export type AggregatedActionsType = r.infer<typeof timelineAggregatedActivityItemSchema>;

export type FetchTimelineAggregatedResultType = r.infer<typeof timelineAggregatedActivityResponseSchema>;

const onlinePresenceBaseStatsItemSchema = r.object({
    current: r.number(),
    compared: r.number(),
    delta: r.number(),
});

export enum OnlinePresenceMetricsEnum {
    Impressions = 'impressions',
    DirectionRequests = 'direction_requests',
    WebsiteClicks = 'website_clicks',
    CallClicks = 'call_clicks',
}

const onlinePresenceDashboardItemSchema = r.object({
    name: r.nativeEnum(OnlinePresenceMetricsEnum),
    values: r.array(r.number()),
});

const onlinePresenceMetricsDynamicSchema = r.object({
    labels: r.array(r.string()),
    data: r.array(onlinePresenceDashboardItemSchema),
});

export const onlinePresenceSchema = r.object({
    results: r.array(
        r.object({
            catalog: shortCatalogSchema.omit({
                logo: true,
            }),
            startDate: r.string(),
            endDate: r.string(),
            baseMetrics: r.object({
                impressions: onlinePresenceBaseStatsItemSchema,
                interactions: onlinePresenceBaseStatsItemSchema,
                conversion: onlinePresenceBaseStatsItemSchema,
            }),
            dynamicMetrics: onlinePresenceMetricsDynamicSchema,
        })
    ),
});

export type OnlinePresenceBaseMetricType = r.infer<typeof onlinePresenceBaseStatsItemSchema>;

export type OnlinePresenceMetricsDynamicsType = r.infer<typeof onlinePresenceMetricsDynamicSchema>;

export type OnlinePresenceResponseType = r.infer<typeof onlinePresenceSchema>;

export type ReviewsBasePaginationType = {
    pageSize: number;
    dateGte: Date;
};

export type ReviewsDashboardStatisticsType = {
    dateGte: Date;
};

export type CatalogStatisticsType = {
    dateFrom: Date;
    dateTo: Date;
};

export type AutoRepliesSummaryDatesDataType = {
    startDate: string;
    endDate: string;
};

export type ReviewsRatingDistributionDatesDataType = {
    startDate: string;
    endDate: string;
};

export type AdditionalTimelineAggregatedResultType = {
    loadMore: () => void;
    canLoadMore: boolean;
    refresh: () => void;
};

export type OnlinePresencePropsType = {
    dateFrom: Date;
    dateTo: Date;
};

export enum CompaniesMapStatsEnum {
    impressions = 'impressions',
    actions = 'actions',
    conversion = 'conversion',
}

export type LoadTimelineDataOptionsType = Partial<{cursor: string | null; shouldReplaceContent: boolean}>;

export const companySummarySchema = r.object({
    closed: r.number(),
    temporaryClosed: r.number(),
    active: r.number(),
});

export type CompanySummaryType = r.infer<typeof companySummarySchema>;

export const companyStatsSchema = r.object({
    id: r.number(),
    name: r.string(),
    address: r.object({
        country: r.string(),
        region: r.string().nullable(),
        city: r.string().nullable(),
        street: r.string().nullable(),
        housenumber: r.string().nullable(),
    }),
    rating: r.string().nullable(),
    reviewsCount: r.number(),
    expired: r.string(),
    created: r.string(),
    code: r.string(),
    closed: r.boolean().optional(),
    temporaryClosed: r.boolean(),
});

export type CompanyStatsType = r.infer<typeof companyStatsSchema>;

export enum CompanyInfoEmptyFieldsEnum {
    Categories = 'categories',
    Phones = 'phones',
    PaymentMethods = 'payment_methods',
    HasLogo = 'has_logo',
    HasCover = 'has_cover',
    HasDescription = 'has_description',
    HasGallery = 'has_gallery',
    HasVkLink = 'has_vk_link',
    HasFacebookLink = 'has_facebook_link',
    HasInstagramLink = 'has_instagram_link',
    HasViberLink = 'has_viber_link',
    HasWhatsAppLink = 'has_whatsapp_link',
    HasYandexZenLink = 'has_yandex_zen_link',
    HasTelegramLink = 'has_telegram_link',
    HasLinkedInLink = 'has_linked_in_link',
    HasOkRuLink = 'has_ok_ru_link',
    HasYoutubeLink = 'has_youtube_link',
    HasTwitterLink = 'has_twitter_link',
}

export const companyInfoSchema = r.object({
    percent: r.number(),
    emptyFields: r.array(r.nativeEnum(CompanyInfoEmptyFieldsEnum)),
});

export type CompanyInfoType = r.infer<typeof companyInfoSchema>;

const companyMapCatalogStatsSchema = r.object({
    id: r.number(),
    name: r.string(),
    website: r.string(),
    [CompaniesMapStatsEnum.impressions]: r.number(),
    [CompaniesMapStatsEnum.actions]: r.number(),
    [CompaniesMapStatsEnum.conversion]: r.number(),
});

const companyMapCompanyStatsSchema = r.object({
    id: r.number(),
    name: r.string(),
    rating: r.string().nullable(),
    reviewsCount: r.number(),
    expired: r.string(),
    phoneNumber: r.string(),
    address: r.object({
        city: r.string().nullable(),
        companyId: r.number().optional(),
        country: r.string().nullable(),
        countryCode: r.string().nullable(),
        housenumber: r.string().nullable(),
        lat: r.number().nullable(),
        lon: r.number().nullable(),
        postcode: r.string(),
        region: r.string().nullable(),
        street: r.string().nullable(),
    }),
});

export const companyMapResponseSchema = r.object({
    company: companyMapCompanyStatsSchema,
    catalogStats: companyMapCatalogStatsSchema.array(),
});

export type CompanyStatType = r.infer<typeof companyMapCatalogStatsSchema>;

export type CompanyMapType = r.infer<typeof companyMapResponseSchema>;

export const dashboardSearchPhrasesSchema = r.object({
    searchPhrases: r.array(
        r.object({
            searchPhrase: r.string(),
            value: r.number(),
        })
    ),
    hasEmptyValue: r.boolean(),
});

export type DashboardSearchPhrasesType = r.infer<typeof dashboardSearchPhrasesSchema>;

export const dashboardManyCompaniesGoodsSchema = r.object({
    catalog: shortCatalogSchema,
    count: r.number(),
});

export type DashboardManyCompaniesGoodsStatsType = r.infer<typeof dashboardManyCompaniesGoodsSchema>;

export const giftInfoSchema = r.object({
    display: r.boolean(),
    message: r.string(),
});

export type GiftInfoType = r.infer<typeof giftInfoSchema>;
