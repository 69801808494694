/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from 'antd';
import {forwardRef, HTMLAttributes, PropsWithChildren, ReactNode, useState} from 'react';

import {classNames} from '../../util/css';
import {PageHeader} from '../page-header/page-header';

import * as styles from './page-card.scss';

type PropsType = Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
    PropsWithChildren<{
        title?: ReactNode;
        bodyClassName?: string;
        collapsible?: boolean;
        headerClassName?: string;
        bordered?: boolean;
        overflowAuto?: boolean;
        hideDivider?: boolean;
    }>;

export const PageCard = forwardRef<HTMLDivElement, PropsWithChildren<PropsType>>((props, ref) => {
    const {
        children,
        className,
        title,
        collapsible,
        bodyClassName,
        headerClassName,
        bordered,
        overflowAuto,
        hideDivider,
        ...divProps
    } = props;

    const [isCollapsed, setIsCollapsed] = useState(false);

    if (!title && !children) {
        return null;
    }

    function handleHeaderClick() {
        if (collapsible) {
            setIsCollapsed((previous) => !previous);
        }
    }

    return (
        <div
            className={classNames(styles.PageCard, className, {
                [styles.PageCard__collapsible]: collapsible,
                [styles.PageCard__collapsed]: collapsible && isCollapsed,
                [styles.PageCard__bordered]: bordered,
                [styles.PageCard__overflowAuto]: overflowAuto,
            })}
            ref={ref}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...divProps}
        >
            {title && (
                <>
                    <div
                        className={classNames(collapsible ? styles.PageCard_collapsableHeaderWrapper : '')}
                        onClick={handleHeaderClick}
                    >
                        <PageHeader className={classNames(styles.PageCard_header, headerClassName)}>{title}</PageHeader>
                        {collapsible && <FontAwesomeIcon className={styles.PageCard_headerArrow} icon={faAngleUp} />}
                    </div>
                    {!hideDivider && <Divider className={styles.PageCard_headerDivider} />}
                </>
            )}
            <div className={classNames(styles.PageCard_body, bodyClassName)}>{children}</div>
        </div>
    );
});

PageCard.displayName = 'PageCard';
