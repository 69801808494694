import {z as r} from 'zod';

export enum SourcesTourNameEnum {
    Sources = 'Источники',
}

export enum SourcesTourStateEnum {
    Closed = 'closed',
}

export const sourcesTourSchema = r.array(
    r.object({
        id: r.number(),
        state: r.nativeEnum(SourcesTourStateEnum),
        name: r.nativeEnum(SourcesTourNameEnum),
        currentStep: r.number(),
        updatedAt: r.string(),
    })
);

export type SourcesTourType = r.infer<typeof sourcesTourSchema>;
export const onboardingLocalstorageSchema = r.boolean();
