import {Select} from 'antd';
import {useEffect, useMemo} from 'react';

import {Locale} from '../../../../../../../provider/locale/localization';
import {TimezoneType} from '../../../../../../../service/api/api-type';
import {useTimezoneLibrary} from '../../../../../../../service/api-hook/api-hook';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../service/posts/posts-types';
import {useProfile} from '../../../../../../../service/profile/profile-hook';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../post-form-type';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    required?: boolean;
} & PostFormItemPropsType;

export function TimezoneItem(props: PropsType): JSX.Element {
    const {formInstance, disabled, required} = props;

    const {result: profileResult} = useProfile();
    const {requiredFieldRule} = useFormRules();

    const timezoneLibrary = useTimezoneLibrary();
    const timezoneList = useMemo(() => {
        return timezoneLibrary.result || [];
    }, [timezoneLibrary]);

    useEffect(() => {
        const timezone = formInstance.getFieldValue(PostFormFieldsEnum.Timezone);

        if (profileResult && !timezone) {
            formInstance.setFieldValue(PostFormFieldsEnum.Timezone, profileResult.profile.timezone);
        }
    }, [formInstance, profileResult]);

    return (
        <Form.Item<PostFormType>
            label={<Locale stringKey="POSTS_FORM__SCHEDULE_POST__TIMEZONE__LABEL" />}
            name={PostFormFieldsEnum.Timezone}
            required={required}
            rules={required ? [requiredFieldRule] : []}
        >
            <Select<string> disabled={disabled} filterOption={() => true} loading={timezoneLibrary.isInProgress}>
                {timezoneList.map(
                    ({name_for_display: nameForDisplay, name}: TimezoneType): JSX.Element => (
                        <Select.Option key={name} value={name}>
                            {nameForDisplay}
                        </Select.Option>
                    )
                )}
            </Select>
        </Form.Item>
    );
}
