export enum LegalAgreementFormKeyEnum {
    Agreement = 'agreement',
    RulesConfirmation = 'rulesConfirmation',
}

export enum LegalAgreementTypeEnum {
    Personal = 'personal',
    Public = 'public',
}

export type LegalAgreementFormType = {
    [LegalAgreementFormKeyEnum.Agreement]: LegalAgreementTypeEnum;
    [LegalAgreementFormKeyEnum.RulesConfirmation]: boolean;
};
