import {z as r} from 'zod';

import {ProvidersEnum} from '../../util/type';
import {cursorPaginationResponseSchemaFactory} from '../api/api-type';

export type ConfirmationSuggestsApiOptionsType = {
    brandId: number;
    companyId: number;
};

export enum CatalogTypeEnum {
    Company = 'company',
    Brand = 'brand',
}

type YandexCompanySuggestType = {
    permanentId: number;
    name: string;
    phones: Array<string>;
    address: string;
    categories: Array<string>;
};

type YandexChainSuggestType = {
    permanentId: number;
    name: string;
    phones: Array<string>;
    categories: Array<string>;
    site: string;
    extra: Record<string, unknown>;
};

type YandexChainsSuggestType = {
    brand: number;
    chains: Array<YandexChainSuggestType>;
};

type YandexCompaniesSuggestType = {
    brand: number;
    companies: Array<YandexCompanySuggestType>;
};

export type YandexConfirmationSuggestType = {
    id: number;
    name: string;
    cclId: number;
    yandexChainSuggest: YandexChainsSuggestType;
    yandexCompanySuggest: YandexCompaniesSuggestType;
};

export enum ConfirmationSourcesMethodEnum {
    Sms = 'sms',
    Link = 'link',
    Address = 'address',
    PhoneCall = 'phone_call',
    Email = 'email',
    Phone = 'phone',
    VettedPartner = 'vetted_partner',
    Auto = 'auto',
    RightsUrl = 'rights_url',
}

export type GoogleGetCodeOptionsType = {
    brand: number;
    company: number;
    method: ConfirmationSourcesMethodEnum;
    catalogId: string | null;
    permanentId: string;
    value: string;
};

export type YandexGetCodeRequestOptionsType = {
    catalogId?: number;
    brand: number;
    catalogType: 'brand' | 'company';
    method: ConfirmationSourcesMethodEnum;
    phone: string;
    extensionPhoneCode?: string;
    contactPerson?: string;
    company?: number;
    extra?: Record<string, unknown>;
};

export type ConfirmationRequestType = {
    code: string;
    hash: string;
    provider: ProvidersEnum;
};

export const enum ConfirmationSourcesStatusEnum {
    Created = 'created',
    InProgress = 'progress',
    Sent = 'sent',
    ErrorOnSending = 'send_error',
    Received = 'received',
    ErrorOnConfirm = 'confirm_error',
    Confirm = 'confirm',
    RequestRightsSent = 'request_rights_sent',
    RequestRightsExpired = 'request_rights_expired',
}

export enum ConfirmationErrorMessageEnum {
    IsNotACompanyPhone = 'is_not_a_company_phone',
    IsNotAChainPhone = 'is_not_a_chain_phone',
    IsNotMobile = 'is_not_mobile',
    AttemptsLimitExceeded = 'attempts_limit_exceeded',
    Common = 'common_error',
}

export const yandexConfirmationSuggestSchema = r.object({
    cclId: r.number(),
    id: r.number(),
    name: r.string(),
    yandexChainSuggest: r.object({
        brand: r.number(),
        chains: r.array(
            r.object({
                categories: r.array(r.string()),
                extra: r.unknown(),
                name: r.string(),
                permanentId: r.number(),
                phones: r.array(r.string()),
                site: r.string(),
            })
        ),
    }),
    yandexCompanySuggest: r.object({
        brand: r.number(),
        companies: r.array(
            r.object({
                categories: r.array(r.string()),
                name: r.string(),
                permanentId: r.number(),
                phones: r.array(r.string()),
            })
        ),
    }),
});

const confirmationSourceSchema = r.object({
    name: r.string(),
    catalogIcon: r.string().optional(),
    catalog: r.string(),
    catalogType: r.nativeEnum(CatalogTypeEnum),
    createdAt: r.string(),
    canMakeConfirmation: r.boolean(),
    canMakeCodeRequest: r.boolean(),
    status: r.string(),
    address: r.string(),
    categories: r.array(r.string()),
    statusText: r.string(),
    phone: r.string(),
    phones: r.array(r.string()),
    method: r.string(),
    hash: r.string(),
    cclBrand: r.array(r.number()),
    cclCompany: r.number(),
    permanentId: r.union([r.number(), r.string()]),
    verificationStatus: r.string().optional(),
    confirmationErrorMessage: r.nativeEnum(ConfirmationErrorMessageEnum).nullable().optional(),
});

export type ConfirmationsSourcesType = {
    name: string;
    catalogIcon: string;
    catalog: ProvidersEnum;
    createdAt: string;
    canMakeConfirmation: boolean;
    canMakeCodeRequest: boolean;
    status: ConfirmationSourcesStatusEnum;
    address?: string;
    categories: Array<string>;
    statusText: string;
    confirmationErrorMessage: ConfirmationErrorMessageEnum;
    phone: string; // the phone number to which the confirmation request was sent.
    phones: Array<string>; // additional phone numbers of the company to which you can send confirmation again
    method: ConfirmationSourcesMethodEnum;
    hash: string;
    cclBrand: [number];
    cclCompany: number;
    permanentId: string;
    catalogType: CatalogTypeEnum;
};

export const confirmationsListSchema = cursorPaginationResponseSchemaFactory(r.array(confirmationSourceSchema));

export const googleConfirmationMethodSchema = r.object({
    detail: r.string().nullable(),
    method: r.nativeEnum(ConfirmationSourcesMethodEnum),
});

export type GoogleConfirmationMethodType = r.infer<typeof googleConfirmationMethodSchema>;

export const googleVerificationMethodSchema = r.object({
    detail: r.string().nullable(),
    method: r.nativeEnum(ConfirmationSourcesMethodEnum),
});

export type GoogleVerificationMethodType = r.infer<typeof googleVerificationMethodSchema>;

export type YandexConfiarmationSuggestHookType = {
    suggest: YandexConfirmationSuggestType | null;
    isInProgress: boolean;
    loadSuggest: (option: ConfirmationSuggestsApiOptionsType) => void;
};

export type GoogleConfirmationMethodsHookType = {
    result: Array<GoogleConfirmationMethodType> | null;
    isInProgress: boolean;
    loadVerificationMethods: (options: GoogleVerificationMethodsApiOptionsType) => void;
    loadConfirmationMethodsByHash: (hash: string) => void;
    processError: Error | null;
};

export type GoogleVerificationMethodsApiOptionsType = {
    companyId: number;
    brandId: number;
    placeId: number;
};
