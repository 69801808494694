import {CatalogType} from '../../../../../provider/catalogs/catalogs-type';
import {ReviewDataType} from '../../../../../service/reviews/reviews-type';

import {DashboardReviewsItemAddress} from './reviews-item-address/reviews-item-address';
import {DashboardReviewsItemComment} from './reviews-item-comment/reviews-item-comment';
import {DashboardReviewsItemHeader} from './reviews-item-header/reviews-item-header';
import * as styles from './reviews-item.scss';

type PropsType = {
    reviewData: ReviewDataType;
    catalogData?: CatalogType;
};

export function DashboardReviewsItem(props: PropsType): JSX.Element {
    const {reviewData, catalogData} = props;

    return (
        <div className={styles.review__main}>
            <DashboardReviewsItemHeader reviewData={reviewData} />
            <DashboardReviewsItemAddress catalogData={catalogData} reviewData={reviewData} />
            <DashboardReviewsItemComment reviewData={reviewData} />
        </div>
    );
}
