import {faFilePdf, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {faChartColumn} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, DatePicker, Popover} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useMemo, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import {appRoute} from '../../../app-route';
import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {PageHeaderWithLicenseTag} from '../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {LicensePageCard} from '../../../component/license-page-card/license-page-card';
import {Meta} from '../../../component/meta/meta';
import {PageCardDivider} from '../../../component/page-card-divider/page-card-divider';
import {Text} from '../../../component/text/text';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {Spinner} from '../../../layout/spinner/spinner';
import {PopoverTextWrapper} from '../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {useLicenses} from '../../../provider/license/license-hook';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {useOnlinePresenceSyncStatistic} from '../../../service/online-presence/online-presence-hook';
import {FeaturesEnum} from '../../../service/user/user-type';

import {OnlinePresenceSynchronizationPdfContent} from './pdf-content/online-presence-synchronization-pdf-content';
import {CardsOccupancy} from './synchronization-stats/cards-occupancy';
import {DataCompleteness} from './synchronization-stats/data-completeness';
import {SynchronizedNavigators} from './synchronization-stats/synchronized-navigators';
import * as styles from './online-presence.scss';

export function OnlinePresenceSynchronization(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const [syncDayjs, setSyncDayjs] = useState<Dayjs>(dayjs());

    const syncDate = useMemo(() => {
        return syncDayjs.toDate();
    }, [syncDayjs]);

    const {result, isInProgress, processError} = useOnlinePresenceSyncStatistic(syncDate);

    const pdfContentRef = useRef<HTMLDivElement | null>(null);

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.onlinePresenceAnalytics];

    const isLicenseExpired = !license?.isActive;

    const handlePrintClick = useReactToPrint({
        content: () => pdfContentRef.current,
    });

    return (
        <Page renderContainer={(pageContent) => <MainPageContainer>{pageContent}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.onlinePresenceDashboard.path,
                            titleLangKey: 'ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS',
                        },
                    ]}
                />

                <PageHeaderWithLicenseTag
                    className={styles.OnlinePresence_header}
                    headerLangKey="CATEGORY_NAME__ONLINE_PRESENCE__SYNCHRONIZATION"
                    licenseActive={license?.isActive}
                    productName={UsetifulNameProductEnum.ONLINE_PRESENCE__SYNCHRONIZATION}
                    trialLicense={license?.isTrial}
                >
                    <Button
                        disabled={!result || isInProgress || isLicenseExpired}
                        icon={<FontAwesomeIcon icon={faFilePdf} />}
                        onClick={handlePrintClick}
                    >
                        <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_PDF" />
                    </Button>
                </PageHeaderWithLicenseTag>

                <PageCardDivider />
                <div className={styles.OnlinePresence_datePickerRow}>
                    <DatePicker
                        disabled={isLicenseExpired}
                        disabledDate={(pickerDate) => {
                            return dayjs().isSameOrBefore(pickerDate);
                        }}
                        format="DD.MM.YYYY"
                        onChange={(value) => setSyncDayjs(value ?? dayjs())}
                        value={syncDayjs}
                    />
                    <Popover
                        content={
                            <PopoverTextWrapper>
                                <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DATE_POPOVER_TEXT" />
                            </PopoverTextWrapper>
                        }
                        placement="bottomLeft"
                        title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DATE_POPOVER_TITLE" />}
                        trigger="hover"
                    >
                        <Text large secondary>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </Text>
                    </Popover>
                </div>
            </PageCard>

            {isInProgress && <Spinner />}
            {processError && <AlertFallback />}

            <LicensePageCard
                icon={<FontAwesomeIcon icon={faChartColumn} />}
                license={license}
                productLandKey="CATEGORY_NAME__ONLINE_PRESENCE"
            />

            {!isLicenseExpired && (
                <>
                    {result && (
                        <>
                            <CardsOccupancy
                                averageSynchronization={result.syncStatus.averageSynchronization}
                                data={result.syncStatus.catalogs}
                            />
                            <DataCompleteness data={result.dataCompleteness} />
                            <SynchronizedNavigators data={result.syncStatus.navigators} />
                        </>
                    )}

                    {result && (
                        <div className={styles.OnlinePresence_pdfContent}>
                            <OnlinePresenceSynchronizationPdfContent
                                ref={pdfContentRef}
                                result={result}
                                syncDate={syncDate}
                            />
                        </div>
                    )}
                </>
            )}
        </Page>
    );
}
