import {ExtractRouteParams} from 'react-router';
import {generatePath, useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {
    useLocalPackRule,
    useLocalPackUpdateRuleMutation,
} from '../../../../service/local-pack/rules/local-pack-rule-hook';
import {
    LocalPackRuleFormFieldsEnum,
    LocalPackRuleFormType,
} from '../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {LocalPackRuleForm} from '../local-pack-rule-form/local-pack-rule-form';

export function LocalPackEditRulePage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {ruleId} = useParams<ExtractRouteParams<typeof appRoute.localPackRuleEdit.path, string>>();

    const {data, isLoading} = useLocalPackRule(Number(ruleId));
    const {pushUrl} = useUrl();

    const {mutateAsync} = useLocalPackUpdateRuleMutation();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK__MANAGE_RULES')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                        {
                            path: appRoute.localPackRules.path,
                            titleLangKey: 'LOCAL_PACK__MANAGE_RULES',
                        },
                        {
                            path: generatePath(appRoute.localPackRuleEdit.path, {ruleId}),
                            titleLangKey: 'LOCAL_PACK__EDIT_RULE_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <Locale stringKey="LOCAL_PACK__EDIT_RULE_PAGE__TITLE" />
                </PageHeader>
            </PageCard>

            <PageCard>
                {isLoading && <Spinner />}
                {data && (
                    <LocalPackRuleForm
                        initialValues={{
                            [LocalPackRuleFormFieldsEnum.Name]: data.name,
                            [LocalPackRuleFormFieldsEnum.Brand]: data.brand,
                            [LocalPackRuleFormFieldsEnum.SearchPhrases]: data.queries.map(({query}) => query),
                            [LocalPackRuleFormFieldsEnum.Services]: data.services,
                            [LocalPackRuleFormFieldsEnum.Frequency]: data.periodicity,
                            [LocalPackRuleFormFieldsEnum.SquareSize]: data.gridSize,
                            [LocalPackRuleFormFieldsEnum.PointDistance]: data.gridStep,
                        }}
                        mode="edit"
                        onFinish={async (formValues: LocalPackRuleFormType) => {
                            await mutateAsync({
                                ruleId: Number(ruleId),
                                data: {
                                    [LocalPackRuleFormFieldsEnum.Name]: formValues[LocalPackRuleFormFieldsEnum.Name],
                                    [LocalPackRuleFormFieldsEnum.Services]:
                                        formValues[LocalPackRuleFormFieldsEnum.Services],
                                    [LocalPackRuleFormFieldsEnum.Frequency]:
                                        formValues[LocalPackRuleFormFieldsEnum.Frequency],
                                    [LocalPackRuleFormFieldsEnum.SquareSize]:
                                        formValues[LocalPackRuleFormFieldsEnum.SquareSize],
                                    [LocalPackRuleFormFieldsEnum.PointDistance]:
                                        formValues[LocalPackRuleFormFieldsEnum.PointDistance],
                                    isActive: false,
                                },
                            });
                            pushUrl(appRoute.localPackRules.path);
                        }}
                    />
                )}
            </PageCard>
        </Page>
    );
}
