import {PublicationContext} from 'centrifuge';
import {useCallback, useEffect} from 'react';

import {useCentrifugeSubscription} from '../../../provider/centrifuge/centrifuge-hook';

import {CENTRIFUGE_PUBLIC_CHANNEL, isCentrifugoDeployNotification} from './centrifuge-deploy-notifications-helper';
import {CentrifugoDeployNotificationType, DeployServiceEnum} from './centrifuge-deploy-notifications-type';

type PropsType = {
    onDeployActionReceived: VoidFunction;
};

export function CentrifugeDeployNotifications(props: PropsType): null {
    const {onDeployActionReceived} = props;
    const {getSubscription} = useCentrifugeSubscription();

    const onPublication = useCallback(
        (payload: CentrifugoDeployNotificationType) => {
            if (payload.deploy === DeployServiceEnum.Frontend) {
                onDeployActionReceived();
            }
        },
        [onDeployActionReceived]
    );

    useEffect(() => {
        function onActionReceived({data}: PublicationContext) {
            if (isCentrifugoDeployNotification(data)) {
                onPublication(data);
            }
        }

        const subscription = getSubscription(CENTRIFUGE_PUBLIC_CHANNEL);

        subscription?.on('publication', onActionReceived);

        return () => {
            subscription?.removeListener('publication', onActionReceived);
        };
    }, [getSubscription, onPublication]);

    return null;
}
