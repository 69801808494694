import {useQuery} from '@tanstack/react-query';
import {useContext, useState} from 'react';

import {LocalPackFilterType} from '../../../page/main/local-pack/local-pack-filter/local-pack-filter-type';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {TableSorterType} from '../../../util/table';

import {fetchLocalPackSearchPhrasesStatistics} from './local-pack-search-phrases-statistics-api';
import {LOCAL_PACK_SEARCH_PHRASE_ANALYTICS_KEY} from './local-pack-search-phrases-statistics-const';
import {
    LocalPackSearchPhrasesStatisticsHookType,
    LocalPackSearchPhrasesStatisticsType,
} from './local-pack-search-phrases-statistics-type';

export function useLocalPackSearchPhrasesStatistics(
    filter: LocalPackFilterType
): LocalPackSearchPhrasesStatisticsHookType {
    const pagination = usePagination({
        dependencies: null,
    });

    const {refreshId, page, pageSize, onDataLoadFailed, onDataLoaded} = pagination;
    const [sorter, setSorter] = useState<TableSorterType | null>(null);

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const query = useQuery<LocalPackSearchPhrasesStatisticsType>(
        [LOCAL_PACK_SEARCH_PHRASE_ANALYTICS_KEY, refreshId, sorter, mainFilterKey],
        () =>
            fetchLocalPackSearchPhrasesStatistics({
                page,
                pageSize,
                mainFilterKey,
                filter,
                ...sorter,
            }),
        {
            refetchOnMount: true,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            onSuccess: onDataLoaded,
            onError: onDataLoadFailed,
        }
    );

    return {
        ...query,
        pagination,
        updateSorter: setSorter,
    };
}
