import {
    GoogleButtonTypeEnum,
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostSourceEnum,
    PostTypeEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {Form} from '../../../../../../../typings/antd';
import {ProvidersEnum} from '../../../../../../../util/type';
import {PostContentWrapper} from '../../../post-content-wrapper/post-content-wrapper';
import {GOOGLE_POST_SETTINGS} from '../content-step-helper';
import {SourcePostPropsType} from '../content-step-type';
import {ButtonLinkItem} from '../items/button-link/button-link-item';
import {ButtonTypeItem} from '../items/button-type/button-type-item';
import {EventItem} from '../items/event/event-item';
import {ImagesItem} from '../items/media/image/images-item';
import {OfferItem} from '../items/offer/offer-item';
import {PostTypeItem} from '../items/post-type/post-type-item';
import {PostTextItem} from '../items/text/post-text-item';
import {PostPreview} from '../post-preview/post-preview';
import {PostRequirements} from '../post-requirements/post-requirements';

import {isButtonLinkFieldVisible} from './google-catalog-data-helper';

export function GoogleCatalogData(props: SourcePostPropsType): JSX.Element {
    const {formInstance, suggestSettings, sourceIndex, formMode, companiesSelector} = props;

    const fieldName = [PostFormFieldsEnum.Posts, sourceIndex];

    const type: PostTypeEnum | null = Form.useWatch([...fieldName, SourcePostFormFieldsEnum.TopicType], formInstance);
    const buttonType: GoogleButtonTypeEnum | null = Form.useWatch(
        [...fieldName, SourcePostFormFieldsEnum.ButtonType],
        formInstance
    );

    const isViewMode = formMode === PostFormModeEnum.View;

    return (
        <PostContentWrapper>
            <PostContentWrapper.Left>
                <PostTextItem
                    disabled={isViewMode}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Text]}
                    formInstance={formInstance}
                    maxLength={GOOGLE_POST_SETTINGS.maxTextLength}
                    suggestSettings={suggestSettings}
                />

                <ImagesItem
                    disabled={isViewMode}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Images]}
                    formInstance={formInstance}
                    settings={GOOGLE_POST_SETTINGS.images}
                />

                <PostTypeItem
                    disabled={formMode === PostFormModeEnum.EditExisted || formMode === PostFormModeEnum.View}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.TopicType]}
                    showEditHelper={formMode === PostFormModeEnum.EditExisted}
                />

                {type === PostTypeEnum.standard && (
                    <>
                        <ButtonTypeItem
                            disabled={isViewMode}
                            fieldName={[...fieldName, SourcePostFormFieldsEnum.ButtonType]}
                        />
                        {isButtonLinkFieldVisible(buttonType) && (
                            <ButtonLinkItem
                                disabled={isViewMode}
                                fieldName={[...fieldName, SourcePostFormFieldsEnum.ButtonLink]}
                                required
                            />
                        )}
                    </>
                )}

                {type === PostTypeEnum.googleEvent && (
                    <>
                        <ButtonTypeItem
                            disabled={isViewMode}
                            fieldName={[...fieldName, SourcePostFormFieldsEnum.ButtonType]}
                        />
                        {isButtonLinkFieldVisible(buttonType) && (
                            <ButtonLinkItem
                                disabled={isViewMode}
                                fieldName={[...fieldName, SourcePostFormFieldsEnum.ButtonLink]}
                                required
                            />
                        )}
                        <EventItem
                            disabled={isViewMode}
                            fieldName={[...fieldName, SourcePostFormFieldsEnum.Event]}
                            formInstance={formInstance}
                        />
                    </>
                )}

                {type === PostTypeEnum.googleOffer && (
                    <>
                        <OfferItem
                            disabled={isViewMode}
                            fieldName={[...fieldName, SourcePostFormFieldsEnum.Offer]}
                            formInstance={formInstance}
                        />
                        <EventItem
                            disabled={isViewMode}
                            fieldName={[...fieldName, SourcePostFormFieldsEnum.Event]}
                            formInstance={formInstance}
                        />
                    </>
                )}
            </PostContentWrapper.Left>
            <PostContentWrapper.Right>
                <PostPreview
                    catalog={PostSourceEnum.google}
                    formInstance={formInstance}
                    sources={{
                        selectorId: companiesSelector?.selectorId,
                    }}
                />
                <PostRequirements options={GOOGLE_POST_SETTINGS} provider={ProvidersEnum.google} />
            </PostContentWrapper.Right>
        </PostContentWrapper>
    );
}
