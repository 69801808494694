import {ReactElement, ReactNode} from 'react';

import {LangKeyType} from '../../provider/locale/translation/type';
import {classNames} from '../../util/css';
import {BetaSup} from '../sup/beta-sup';
import {Text} from '../text/text';
import {UsetifulNameProductEnum} from '../usetiful/usetiful-const';
import {UsetifulProductRatingButton} from '../usetiful/usetiful-product-rating-button';

import * as styles from './page-header-title.scss';

type PropsType = {
    wrapperClassName?: string;
    textClassName?: string;
    titleValueMap?: Record<string, ReactNode>;
    ratingButtonClassName?: string;
    productName?: UsetifulNameProductEnum;
    isShowProductButton?: boolean;
    id?: string;
    title: LangKeyType;
    titleNode?: ReactNode;
    beta?: boolean;
};

export function PageHeaderTitle(props: PropsType): ReactElement {
    const {
        wrapperClassName,
        textClassName,
        productName,
        id,
        title,
        ratingButtonClassName,
        isShowProductButton = true,
        titleValueMap,
        titleNode,
        beta,
    } = props;

    const wrapperClassNames = classNames(styles.PageHeaderTitle, wrapperClassName);
    const textClassNames = classNames(styles.PageHeaderTitle_sectionName, textClassName);

    return (
        <div className={wrapperClassNames}>
            {titleNode ?? (
                <>
                    <Text className={textClassNames} id={id} stringKey={title} valueMap={titleValueMap} />
                    {beta && <BetaSup />}
                </>
            )}

            {productName && isShowProductButton && (
                <UsetifulProductRatingButton className={ratingButtonClassName} productName={productName} />
            )}
        </div>
    );
}
