import {faCalendar} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useContext, useMemo} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-container';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {useReviewAnalysisRadarStats} from '../../../../../service/reivew-analysis/review-radar/review-radar-hook';
import {getFormattedDateTime} from '../../../../../util/format';
import {getDateTimeDifferenceHumanSize} from '../../../../../util/time';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';
import {KeywordsAndTopicsForm} from '../keywords-and-topics-page/keywords-and-topics-form/keywords-and-topics-form';

import {TopicDistributionChart} from './topic-distribution-chart/topic-distribution-chart';
import {TopicRatingChart} from './topic-rating-chart/topic-rating-chart';
import {TopicReviewsCountChart} from './topic-reviews-count-chart/topic-reviews-count-chart';
import {TopicSentimentChart} from './topic-sentiment-chart/topic-sentiment-chart';
import {TopicTags} from './topic-tags/topic-tags';
import * as styles from './topic-page.scss';

export function TopicPage(): JSX.Element {
    const {
        reviewsAnalysisDashboard: dashboardRoute,
        reviewsAnalysisKeywordsAndTopics: tagsRoute,
        reviewsAnalysisTopic: topicRoute,
    } = appRoute;

    const {goBack, hasBack, pushUrl} = useUrl();

    const {getLocalizedString, localeName} = useLocale();
    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();
    const {singleFilter} = useReviewsAnalysisFilter();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const radarStatsOptions = useMemo(() => ({...singleFilter, userTopics: [id]}), [id, singleFilter]);
    const {result: radarStatsResult} = useReviewAnalysisRadarStats(radarStatsOptions, mainFilterKey);

    const {startDate, endDate} = singleFilter;

    const stats = useMemo(() => {
        const defaultStats = {
            avgRating: 0,
            avgResponseSpeed: 0,
            replied: 0,
            total: 0,
        };

        if (!radarStatsResult) {
            return defaultStats;
        }

        const summarizedStats = radarStatsResult?.reduce(
            (accumulator, brand) => ({
                avgRating: accumulator.avgRating + Number(brand.avgRating),
                avgResponseSpeed: accumulator.avgResponseSpeed + Number(brand.avgResponseSpeed),
                replied: accumulator.replied + Number(brand.replied),
                total: accumulator.total + Number(brand.total),
            }),
            defaultStats
        );

        return {
            avgRating: summarizedStats.avgRating / radarStatsResult.length,
            avgResponseSpeed: summarizedStats.avgResponseSpeed / radarStatsResult.length,
            replied: summarizedStats.replied,
            total: summarizedStats.total,
        };
    }, [radarStatsResult]);

    function navigateBack() {
        if (hasBack) {
            goBack();
        } else {
            pushUrl(tagsRoute.path);
        }
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS')} />
                <BreadCrumbs
                    list={[
                        {
                            path: dashboardRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                        },
                        {
                            path: tagsRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS',
                        },
                        {
                            path: topicRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC',
                        },
                    ]}
                />
                <PageHeader className={styles.TopicPageTitle}>
                    <Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC" />

                    <Button onClick={navigateBack}>
                        <Locale stringKey="BUTTON__BACK" />
                    </Button>
                </PageHeader>
            </PageCard>
            <PageCard>
                <KeywordsAndTopicsForm withAggregation />
            </PageCard>

            <div className={styles.TopicPage}>
                <TopicTags onDeleteSuccess={() => pushUrl(tagsRoute.path)} />

                <div className={styles.TopicPage_stat}>
                    <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__STATS__TOTAL_COUNT" />
                    <span className={styles.TopicPage_statValue}>{stats.total}</span>
                </div>
                <div className={styles.TopicPage_stat}>
                    <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_RATING" />
                    <span className={styles.TopicPage_statValue}>{stats.avgRating || '—'}</span>
                </div>
                <div className={styles.TopicPage_stat}>
                    <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__STATS__REVIEWS_WITH_ANSWER" />
                    <span className={styles.TopicPage_statValue}>
                        {stats.replied}
                        {stats.replied > 0 && (
                            <span className={styles.TopicPage_statValuePercent}>
                                ({((stats.replied * 100) / stats.total).toFixed(1)}%)
                            </span>
                        )}
                    </span>
                </div>
                <div className={styles.TopicPage_stat}>
                    <span className={styles.TopicPage_statTitle}>
                        <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_REPLY_TIME" />
                        <AdditionalInfo
                            placement="right"
                            title={<Locale stringKey="REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_REPLY_TIME" />}
                        >
                            <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_REPLY_TIME__DESCRIPTION" />
                        </AdditionalInfo>
                    </span>
                    <span className={styles.TopicPage_statValue}>
                        {stats.avgResponseSpeed
                            ? getDateTimeDifferenceHumanSize({
                                  milliseconds: stats.avgResponseSpeed * 1000,
                                  sliceSize: 2,
                                  localeName,
                                  formatOption: {
                                      unitDisplay: 'short',
                                  },
                              })
                            : '—'}
                    </span>
                </div>

                <div className={styles.TopicPage_doughnutChartDistribution}>
                    <PageCard
                        collapsible
                        headerClassName={styles.TopicPage_cardHeader}
                        title={
                            <span className={styles.TopicPage_cardTitle}>
                                <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__KEYWORDS_DISTRIBUTION__TITLE" />
                            </span>
                        }
                    >
                        <TopicDistributionChart />
                    </PageCard>
                </div>
                <div className={styles.TopicPage_doughnutChartSegment}>
                    <PageCard
                        collapsible
                        headerClassName={styles.TopicPage_cardHeader}
                        title={
                            <span className={styles.TopicPage_cardTitle}>
                                <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__REVIEWS_SENTIMENT__TITLE" />
                            </span>
                        }
                    >
                        <TopicSentimentChart />
                    </PageCard>
                </div>

                <div className={styles.TopicPage_ratingChart}>
                    <PageCard
                        collapsible
                        headerClassName={styles.TopicPage_cardHeader}
                        title={
                            <>
                                <span className={styles.TopicPage_cardTitle}>
                                    <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__AVERAGE_RATING__TITLE" />
                                </span>
                                <span className={styles.TopicPage_cardDate}>
                                    <FontAwesomeIcon icon={faCalendar} />
                                    {startDate &&
                                        endDate &&
                                        `${getFormattedDateTime(
                                            localeName,
                                            new Date(startDate)
                                        )} - ${getFormattedDateTime(localeName, new Date(endDate))}`}
                                </span>
                            </>
                        }
                    >
                        <TopicRatingChart />
                    </PageCard>
                </div>

                <div className={styles.TopicPage_reviewsCountChart}>
                    <PageCard
                        collapsible
                        headerClassName={styles.TopicPage_cardHeader}
                        title={
                            <>
                                <span className={styles.TopicPage_cardTitle}>
                                    <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__REVIEWS_COUNT__TITLE" />
                                </span>
                                <span className={styles.TopicPage_cardDate}>
                                    <FontAwesomeIcon icon={faCalendar} />
                                    {startDate &&
                                        endDate &&
                                        `${getFormattedDateTime(
                                            localeName,
                                            new Date(startDate)
                                        )} - ${getFormattedDateTime(localeName, new Date(endDate))}`}
                                </span>
                            </>
                        }
                    >
                        <TopicReviewsCountChart />
                    </PageCard>
                </div>
            </div>
        </Page>
    );
}
