import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space, Tag, Typography} from 'antd';
import {useMemo} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useCompetitors} from '../../../../../service/reivew-analysis/competitors/competitors-hook';
import {CompetitorType} from '../../../../../service/reivew-analysis/competitors/competitors-type';
import {getRangeFilterTagFormat} from '../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';
import {ReviewsAnalysisFilterEnum} from '../../reviews-analysis-type';

import {isPrimaryFilterStateEmpty} from './active-filter-helper';
import {PrimaryFilter} from './primary-filter/primary-filter';
import * as styles from './active-filter.scss';

type PropsType = {
    isCompareRangeAllowed: boolean;
    isCompetitorAllowed: boolean;
    isWithTextOnly: boolean;
    isPreview: boolean;
    isPeriodHidden?: boolean;
    isTopicsAllowed?: boolean;
};

export function ActiveFilter(props: PropsType): JSX.Element {
    const {isCompareRangeAllowed, isCompetitorAllowed, isWithTextOnly, isPreview, isPeriodHidden, isTopicsAllowed} =
        props;

    const {localeName} = useLocale();

    const {filter, setFilter, queries} = useReviewsAnalysisFilter();

    const {result: competitorsResult, isInProgress: isCompetitorsInProgress} = useCompetitors();
    const activeCompetitorId: number | null = filter[ReviewsAnalysisFilterEnum.Competitor];
    const activeCompetitor: CompetitorType | null = useMemo<CompetitorType | null>((): CompetitorType | null => {
        return (
            (competitorsResult || []).find((competitor: CompetitorType) => competitor.id === activeCompetitorId) || null
        );
    }, [competitorsResult, activeCompetitorId]);

    const filteredQueries = useMemo(() => {
        if (isPeriodHidden) {
            return {
                ...queries,
                [ReviewsAnalysisFilterEnum.Range]: '',
            };
        }

        return {...queries};
    }, [queries, isPeriodHidden]);

    const isPrimaryFilterEmpty: boolean = isPrimaryFilterStateEmpty(filteredQueries);
    const isCompareRangeEmpty: boolean = filter[ReviewsAnalysisFilterEnum.CompareRange].every(
        (date: Date | null): boolean => !date
    );

    return (
        <>
            {!isPrimaryFilterEmpty && (
                <div>
                    <Space className={styles.ActiveFilter} size="small">
                        <Typography.Text className={styles.ActiveFilter_title}>
                            <Locale stringKey="REVIEWS_ANALYSIS__FILTER__ACTIVE__LABEL" />:
                        </Typography.Text>

                        <PrimaryFilter
                            filter={filter}
                            isPeriodHidden={isPeriodHidden}
                            isPreview={isPreview}
                            isTopicsAllowed={isTopicsAllowed}
                            isWithTextOnly={isWithTextOnly}
                            setFilter={setFilter}
                        />
                    </Space>
                </div>
            )}

            {isCompareRangeAllowed && !isCompareRangeEmpty && (
                <Space className={styles.ActiveFilter}>
                    <Typography.Text className={styles.ActiveFilter_title}>
                        <Locale stringKey="REVIEWS_ANALYSIS__FILTER__ACTIVE__COMPARE__LABEL" />:
                    </Typography.Text>

                    <div className={styles.ActiveFilter_tags}>
                        <Tag
                            closable={!isPreview}
                            onClose={() => setFilter({[ReviewsAnalysisFilterEnum.CompareRange]: [null, null]})}
                        >
                            {getRangeFilterTagFormat(localeName, filter[ReviewsAnalysisFilterEnum.CompareRange])}
                        </Tag>
                    </div>
                </Space>
            )}

            {isCompetitorAllowed && Boolean(activeCompetitor) && (
                <Space className={styles.ActiveFilter}>
                    <Typography.Text className={styles.ActiveFilter_title}>
                        <Locale stringKey="REVIEWS_ANALYSIS__FILTER__ACTIVE__COMPARE__LABEL" />:
                    </Typography.Text>

                    <div className={styles.ActiveFilter_tags}>
                        <Tag
                            closable={!isPreview}
                            icon={isCompetitorsInProgress && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            onClose={() => setFilter({[ReviewsAnalysisFilterEnum.Competitor]: null})}
                        >
                            {activeCompetitor?.name}
                        </Tag>
                    </div>
                </Space>
            )}
        </>
    );
}
