import {FetchMethodEnum, fetchNoContent, fetchX} from '../../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';

import {
    DashboardPostponeRecommendationPayloadApiType,
    DashboardPostponeRecommendationResponseApiType,
} from './dashboard-recommendations-api-type';
import {DashboardRecommendationItemType, DashboardRecommendationTypeEnum} from './dashboard-recommendations-type';

export function loadRecommendations(): Promise<Array<DashboardRecommendationItemType>> {
    return fetchX<Array<DashboardRecommendationItemType>>(`${rootApiUrl}/v1/recommendations/`, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.get,
    });
}

export function cancelRecommendationPostponeApi(recommendationKey: DashboardRecommendationTypeEnum): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/recommendations/ignore/${recommendationKey}/`, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.delete,
    });
}

export function recommendationPostponeApi(
    recommendationKey: DashboardRecommendationTypeEnum,
    payload: DashboardPostponeRecommendationPayloadApiType
): Promise<DashboardPostponeRecommendationResponseApiType> {
    return fetchX<DashboardPostponeRecommendationResponseApiType>(
        `${rootApiUrl}/v1/recommendations/ignore/${recommendationKey}/`,
        {
            headers: {...mainApiHeaders, ...getCsrfHeaders()},
            method: FetchMethodEnum.post,
            body: JSON.stringify(payload),
        }
    );
}
