import {LangKeyType} from '../../../../provider/locale/translation/type';
import {CatalogPostStatusEnum, PostStatusEnum} from '../../../../service/posts/posts-types';

type PostStatusOptionType = {
    color: string;
    name: LangKeyType;
};

export const MAIN_POST_STATUS_OPTIONS: {[key in PostStatusEnum]: PostStatusOptionType} = {
    [PostStatusEnum.Planned]: {
        color: 'gold',
        name: 'POSTS__STATUS__PLANNED',
    },
    [PostStatusEnum.Sent]: {
        color: 'geekblue',
        name: 'POSTS__STATUS__SENT',
    },
    [PostStatusEnum.Deleted]: {
        color: 'magenta',
        name: 'POSTS__STATUS__DELETED',
    },
    [PostStatusEnum.Active]: {
        color: 'cyan',
        name: 'POSTS__STATUS__ACTIVE',
    },
    [PostStatusEnum.Draft]: {
        color: '',
        name: 'POSTS__STATUS__DRAFT',
    },
    [PostStatusEnum.Sending]: {
        color: 'geekblue',
        name: 'POSTS__STATUS__IN_PROGRESS',
    },
};

export const CATALOG_POST_STATUS_OPTIONS: {[key in CatalogPostStatusEnum]: PostStatusOptionType} = {
    [CatalogPostStatusEnum.Created]: {
        color: 'blue',
        name: 'POSTS__STATUS__CREATED',
    },
    [CatalogPostStatusEnum.Updated]: {
        color: 'blue',
        name: 'POSTS__STATUS__UPDATED',
    },
    [CatalogPostStatusEnum.Deleted]: {
        color: 'magenta',
        name: 'POSTS__STATUS__DELETED',
    },
    [CatalogPostStatusEnum.Error]: {
        color: 'red',
        name: 'POSTS__STATUS__ERROR',
    },
    [CatalogPostStatusEnum.OnModeration]: {
        color: '',
        name: 'POSTS__STATUS__ON_MODERATION',
    },
    [CatalogPostStatusEnum.Rejected]: {
        color: 'red',
        name: 'POSTS__STATUS__REJECTED',
    },
};
