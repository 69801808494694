import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
    faEyeSlash,
    faGavel,
    faHashtag,
    faMapMarkerTimes,
    faPencil,
    faShieldAlt,
} from '@fortawesome/pro-solid-svg-icons';

import {PhotoReportTypeEnum, PhotoStatusKeyEnum} from '../../../service/phototool/phototool-type';

import {PhotoStatusType} from './photo-tool-type';

export const photoStatusDisplayMap: Partial<Record<PhotoStatusKeyEnum, PhotoStatusType>> = {
    [PhotoStatusKeyEnum.New]: {
        key: PhotoStatusKeyEnum.New,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__NEW',
        color: 'blue',
    },
    [PhotoStatusKeyEnum.Viewed]: {
        key: PhotoStatusKeyEnum.Viewed,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__VIEWED',
        color: '',
    },
    [PhotoStatusKeyEnum.Reported]: {
        key: PhotoStatusKeyEnum.Reported,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__REPORT',
        color: 'volcano',
    },
    [PhotoStatusKeyEnum.ReportRejected]: {
        key: PhotoStatusKeyEnum.ReportRejected,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__REPORT_REJECTED',
        color: 'volcano',
    },
    [PhotoStatusKeyEnum.RemoveRequest]: {
        key: PhotoStatusKeyEnum.RemoveRequest,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__REMOVE_REQUEST',
        color: 'volcano',
    },
    [PhotoStatusKeyEnum.Removed]: {
        key: PhotoStatusKeyEnum.Removed,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__REMOVED',
        color: 'geekblue',
    },
    [PhotoStatusKeyEnum.ErrorRemoving]: {
        key: PhotoStatusKeyEnum.ErrorRemoving,
        localeKey: 'PHOTO_TOOL__PHOTO_META__STATUS__ERROR_REMOVING',
        color: 'volcano',
    },
};

export const photoStatusDisplayList: Array<PhotoStatusType> = Object.values(photoStatusDisplayMap);

export const PHOTO_REPORT_TYPE_ICON_MAP: Record<PhotoReportTypeEnum, IconProp> = {
    [PhotoReportTypeEnum.OffensiveContent]: faHashtag,
    [PhotoReportTypeEnum.LawViolation]: faGavel,
    [PhotoReportTypeEnum.PrivacyViolation]: faShieldAlt,
    [PhotoReportTypeEnum.PoorQuality]: faEyeSlash,
    [PhotoReportTypeEnum.LocationMatchingError]: faMapMarkerTimes,
    [PhotoReportTypeEnum.OrganizationMatchingError]: faMapMarkerTimes,
    [PhotoReportTypeEnum.Other]: faPencil,
};
