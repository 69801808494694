import {Component, PropsWithChildren, ReactNode} from 'react';

import {logError} from '../../util/error';

type PropsType = PropsWithChildren<{
    errorFallBack: JSX.Element;
    appHasNewVersion: boolean;
}>;

type StateType = {
    hasError: boolean;
};

export class ErrorBoundary extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);

        this.state = {hasError: false};
    }

    componentDidCatch(error: Error): void {
        const {props} = this;

        logError(error);

        if (props.appHasNewVersion) {
            location.reload();
        }

        this.setState({hasError: true});
    }

    render(): ReactNode {
        const {state, props} = this;
        const {hasError} = state;
        const {children, errorFallBack} = props;

        return hasError ? errorFallBack : children;
    }
}
