import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

const brandValidationSettingsSchema = r.object({
    makeWebsiteOptionalField: r.boolean(),
    disableDoubleGisAttributes: r.boolean(),
});

type BrandValidationSettingsType = r.infer<typeof brandValidationSettingsSchema>;

function getBrandValidationSettingsUrl(brandId?: number | null) {
    return `/v2/brands/${brandId}/brand_validation_settings/`;
}

async function fetchBrandValidationSettings(brandId?: number | null): Promise<BrandValidationSettingsType | null> {
    if (!brandId) {
        return null;
    }

    return fetchAndDeserialize<BrandValidationSettingsType>(
        getBrandValidationSettingsUrl(brandId),
        brandValidationSettingsSchema
    );
}

export function useBrandValidationSettings(brandId?: number | null): UseQueryResult<BrandValidationSettingsType> {
    return useQuery([getBrandValidationSettingsUrl(brandId)], () => fetchBrandValidationSettings(brandId), {
        enabled: Boolean(brandId),
    });
}
