import {
    CompanyGroupFieldsType,
    CompanyGroupType,
    PartialCompanyGroupType,
} from '../../../../service/company-group/company-group-type';

export type EditCompanyTransferType = {
    name: string;
    companyId: string;
    address: string;
    brandId: string;
    key: string;
    code: string;
};

export type CompanyGroupFormPropsType = {
    companyGroup: PartialCompanyGroupType;
    companiesGroup: Array<CompanyGroupType>;
    onChange: (fields: CompanyGroupFieldsType) => void;
    className?: string;
};

export enum TransferDirectionEnum {
    left = 'left',
    right = 'right',
}

export enum VirtualTableDirectionEnum {
    left = 'ltr',
    right = 'rtl',
}
