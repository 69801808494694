import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {objectToUrlParameters} from '../../../../../util/url';

import {ExpiredColumnQueryNameEnum} from './expired-column-type';

type PropsType = {
    expired: string;
    tariffId?: number;
};

export function ExpiredColumn(props: PropsType): JSX.Element {
    const {expired, tariffId} = props;

    const parameters = objectToUrlParameters({
        [ExpiredColumnQueryNameEnum.tariffId]: tariffId,
    });

    return <NavigationLink to={`${appRoute.tariffsAndLimits.path}?${parameters}`}>{expired}</NavigationLink>;
}
