import {Checkbox, Radio} from 'antd';

import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {Locale} from '../../provider/locale/localization';
import {useFormRules} from '../../service/form/form-rules-hook';
import {Form} from '../../typings/antd';
import {classNames} from '../../util/css';
import {AdditionalInfo} from '../additional-info/additional-info';

import {useAgreementAcceptanceText} from './legal-agreement-hook';
import {LegalAgreementFormKeyEnum, LegalAgreementFormType, LegalAgreementTypeEnum} from './legal-agreement-type';
import * as styles from './legal-agreement.scss';

type PropsType = {
    className?: string;
    disabled?: boolean;
};

export function LegalAgreement(props: PropsType): JSX.Element {
    const {className, disabled} = props;

    const form = Form.useFormInstance<LegalAgreementFormType>();
    const legalAgreementType = Form.useWatch(LegalAgreementFormKeyEnum.Agreement, form);
    const {getRequiredCheckBoxRule} = useFormRules();
    const agreementAcceptanceText = useAgreementAcceptanceText(legalAgreementType);

    const {companyName} = useDomainConfig();

    function handleRadioGroupChange() {
        form.setFieldValue(LegalAgreementFormKeyEnum.RulesConfirmation, false);
    }

    return (
        <section className={classNames(styles.LegalAgreement_wrapper, className)}>
            <Form.Item<LegalAgreementFormType> name={LegalAgreementFormKeyEnum.Agreement} noStyle>
                <Radio.Group
                    className={styles.LegalAgreement_radioGroup}
                    disabled={disabled}
                    // needs a 'name' for better browser ux https://ant.design/components/radio/#components-radio-demo-radiogroup-with-name
                    name={LegalAgreementFormKeyEnum.Agreement}
                    onChange={handleRadioGroupChange}
                >
                    <div className={styles.LegalAgreement_additionalInfoWrapper}>
                        <Radio value={LegalAgreementTypeEnum.Personal}>
                            <Locale stringKey="LEGAL_AGREEMENT__PERSONAL" />
                        </Radio>

                        <AdditionalInfo
                            className={styles.LegalAgreement_additionalInfoIcon}
                            title={<Locale stringKey="LEGAL_AGREEMENT__PERSONAL" />}
                        >
                            <Locale stringKey="LEGAL_AGREEMENT__PERSONAL__ADDITIONAL_INFO" valueMap={{companyName}} />
                        </AdditionalInfo>
                    </div>

                    <Radio value={LegalAgreementTypeEnum.Public}>
                        <Locale stringKey="LEGAL_AGREEMENT__PUBLIC" />
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item<LegalAgreementFormType>
                className={styles.LegalAgreement_checkboxFormItem}
                name={LegalAgreementFormKeyEnum.RulesConfirmation}
                rules={[getRequiredCheckBoxRule()]}
                valuePropName="checked"
            >
                <Checkbox disabled={disabled}>{agreementAcceptanceText}</Checkbox>
            </Form.Item>
        </section>
    );
}
