import {LangKeyType} from '../../../../../../provider/locale/translation/type';

export enum BigRadioButtonNameEnum {
    averageRating = 'average-rating',
}

export type BigRadioButtonDataType = {
    titleKey: LangKeyType;
    icon: JSX.Element;
    className: string;
};
