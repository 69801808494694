import {Col, Row} from 'antd';
import {useMemo} from 'react';

import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useReviewsDashboardStatistic} from '../../../../../service/dashboard/dashboard-hook';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {numberOfDays} from './stat-card-consts';
import * as styles from './stat-card.scss';

export function DashboardReviewStatsCard(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();
    const dateGte = useMemo(() => {
        return new Date();
    }, []);

    dateGte.setDate(dateGte.getDate() - numberOfDays);

    const {result, isInProgress} = useReviewsDashboardStatistic({
        dateGte,
    });

    if (isInProgress || !result) {
        return <Spinner />;
    }

    return (
        <Row align="middle" className={styles.StatsCard}>
            <Col xl={10} xs={24}>
                <p className={styles.StatsCard_title}>
                    <Locale stringKey="DASHBOARD_PAGE__REVIEWS__STATS_HEADER" />
                </p>
                <p className={styles.StatsCard_description}>
                    {getLocalizedString('DASHBOARD_PAGE__REVIEWS__STATS_DAYS', {
                        numberOfDays: String(numberOfDays),
                    })}
                </p>
            </Col>
            <Col xl={7} xs={24}>
                <p className={styles.StatsCard_label}>
                    <Locale stringKey="DASHBOARD_PAGE__REVIEWS__STATS_ALL" />
                </p>
                <p className={styles.StatsCard_value}>{getFormattedNumber(result.withoutAnswer + result.withAnswer)}</p>
            </Col>
            <Col className={styles.StatsCard_lastChild} xl={7} xs={24}>
                <p className={styles.StatsCard_label}>
                    <Locale stringKey="DASHBOARD_PAGE__REVIEWS__STATS_WITHOUT_ANSWER" />
                </p>
                <p className={styles.StatsCard_value}>{getFormattedNumber(result.withoutAnswer)}</p>
            </Col>
        </Row>
    );
}
