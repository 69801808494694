import {Button, Space} from 'antd';
import {ReactNode} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {GoogleCclStatusEnum} from '../../../../../../../../../service/google/google-type';

import * as styles from './confirmations-list-item-footer.scss';

type PropsType = {
    status: GoogleCclStatusEnum;
    text: ReactNode;
    onCodeRequest: (options: {repeat: boolean}) => void;
    onCodeEnter: () => void;
    onRightsRequest: () => void;
};

export function ConfirmationsListItemFooter(props: PropsType): JSX.Element {
    const {status, text, onCodeRequest, onCodeEnter, onRightsRequest} = props;

    return (
        <footer className={styles.ConfirmationsListItemFooter}>
            <div className={styles.ConfirmationsListItemFooter_text}>
                <Text small>{text}</Text>
            </div>

            {status === GoogleCclStatusEnum.AwaitingVerification ? (
                <Space>
                    <Button onClick={() => onCodeRequest({repeat: true})}>
                        <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__BUTTON__REQUEST_CODE_AGAIN" />
                    </Button>

                    <Button onClick={onCodeEnter} type="primary">
                        <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__BUTTON__ENTER_CODE" />
                    </Button>
                </Space>
            ) : status === GoogleCclStatusEnum.AwaitingConfirmation ? (
                <Button onClick={() => onCodeRequest({repeat: false})}>
                    <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__BUTTON__REQUEST_CODE" />
                </Button>
            ) : status === GoogleCclStatusEnum.AwaitingRights ? (
                <Button onClick={onRightsRequest}>
                    <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__BUTTON__REQUEST_RIGHTS" />
                </Button>
            ) : null}
        </footer>
    );
}
