import {ZodType} from 'zod';

import {deserializeV2, fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {getIsoYyyyMmDdString} from '../../util/date';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {PaginationDataType} from '../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    ExportExcelType,
    LocationsAnalyticsResponseType,
    locationsAnalyticsSchema,
    LocationsParametersType,
    OnlinePresenceDashboardDataCommonType,
    OnlinePresencePhrasesHookDataType,
    onlinePresencePhrasesResponseSchema,
    onlinePresenceSynchronizationSchema,
    OnlinePresenceSynchronizationType,
    onlinePresenceTrialDashboardSchema,
    OnlinePresenceTrialDashboardType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from './online-presence-type';

export async function getOnlinePresencePhrases(
    filter: OnlinePresenceV2FilterStateType,
    mainFilterKey: string,
    pagination: PaginationDataType
): Promise<OnlinePresencePhrasesHookDataType> {
    const compareDateStart = filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart];
    const compareDateEnd = filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd];

    const url =
        `/cp/online_presence/search_phrases/?` +
        objectToUrlParameters({
            start_date: getIsoYyyyMmDdString(filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart]),
            end_date: getIsoYyyyMmDdString(filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd]),
            ...(compareDateStart
                ? {
                      compared_start_date: getIsoYyyyMmDdString(compareDateStart),
                  }
                : {}),
            ...(compareDateEnd ? {compared_end_date: getIsoYyyyMmDdString(compareDateEnd)} : {}),
            aggregate: filter[OnlinePresenceV2FilterQueriesEnum.Aggregation],
            ...serializeToURLParameters<PaginationDataType>(pagination),
            filter_key: mainFilterKey,
        });

    return fetchAndDeserialize<OnlinePresencePhrasesHookDataType>(url, onlinePresencePhrasesResponseSchema);
}

export async function fetchOnlinePresenceDashboardData<
    DataType extends OnlinePresenceDashboardDataCommonType = OnlinePresenceDashboardDataCommonType
>(parameters: {
    filter: OnlinePresenceV2FilterStateType;
    apiPath: string;
    schema: ZodType;
    mainFilterKey: string;
}): Promise<DataType> {
    const {filter, apiPath, schema, mainFilterKey} = parameters;

    const compareDateStart = filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart];
    const compareDateEnd = filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd];

    const url =
        `${rootApiUrl}/${apiPath}/?` +
        objectToUrlParameters({
            start_date: getIsoYyyyMmDdString(filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart]),
            end_date: getIsoYyyyMmDdString(filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd]),
            ...(compareDateStart
                ? {
                      compared_start_date: getIsoYyyyMmDdString(compareDateStart),
                  }
                : {}),
            ...(compareDateEnd ? {compared_end_date: getIsoYyyyMmDdString(compareDateEnd)} : {}),
            aggregate: filter[OnlinePresenceV2FilterQueriesEnum.Aggregation],
            filter_key: mainFilterKey,
        });

    const result = await fetchX(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.get,
    });

    return deserializeV2<DataType>(url, schema, result);
}

export async function fetchOnlinePresenceTrialDashboardData(): Promise<OnlinePresenceTrialDashboardType> {
    const url = '/cp/online_presence/main_metrics_trial/';

    return fetchAndDeserialize<OnlinePresenceTrialDashboardType>(url, onlinePresenceTrialDashboardSchema);
}

export async function fetchOnlinePresenceSyncStatistic(
    date: Date,
    mainFilterKey: string
): Promise<OnlinePresenceSynchronizationType> {
    const url =
        `${rootApiUrl}/cp/online_presence/sync_statistics/?` +
        objectToUrlParameters({
            start_date: getIsoYyyyMmDdString(date),
            end_date: getIsoYyyyMmDdString(date),
            filter_key: mainFilterKey,
        });

    const result = await fetchX(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.get,
    });

    return deserializeV2<OnlinePresenceSynchronizationType>(url, onlinePresenceSynchronizationSchema, result);
}

export function fetchOnlinePresenceLocations(
    parameters: LocationsParametersType
): Promise<LocationsAnalyticsResponseType> {
    const {filter, mainFilterKey, pagination} = parameters;

    const parametersString = objectToUrlParameters({
        start_date: getIsoYyyyMmDdString(filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart]),
        end_date: getIsoYyyyMmDdString(filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd]),
        ...serializeToURLParameters<PaginationDataType>(pagination),
        filter_key: mainFilterKey,
    });

    const url = `/cp/online_presence/companies/?${parametersString}`;

    return fetchAndDeserialize<LocationsAnalyticsResponseType>(url, locationsAnalyticsSchema);
}

export async function fetchExportAnalytics(parameters: ExportExcelType): Promise<void> {
    const url = `${rootApiUrl}/cp/online_presence/export_xlsx/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(parameters),
    });
}
