import {Form, Select} from 'antd';
import {useCallback, useState} from 'react';
import {
    SortableContainer as createSortableContainer,
    SortableElement as createSortableElement,
    SortEvent,
    SortEventWithTag,
} from 'react-sortable-hoc';

import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {CompanyCategoryType} from '../../../../../../service/api/api-type';
import {useSearchCompanyCategoryList} from '../../../../../../service/api-hook/api-hook';
import {
    BulkEditFieldNameEnum,
    CompanyGeneralInfoCategoryType,
    FieldModeEnum,
} from '../../../../../../service/company/company-type';
import {arrayMove} from '../../../../../../util/array';
import {classNames} from '../../../../../../util/css';
import {debounce} from '../../../../../../util/function';
import {useRefreshId} from '../../../../../../util/hook';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import {highlightOption} from './select-categories-form-item-helper';
import {CategoryListItemPropsType} from './select-categories-form-item-type';
import {SelectedCompanyCategoryItem} from './selected-category-item/selected-category-item';
import * as styles from './select-categories-form-item.scss';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.categories, string> & {
    className?: string;
    mode?: FieldModeEnum;
    helpText?: JSX.Element;
};

// eslint-disable-next-line complexity, max-statements
export function SelectCategoriesFormItem(props: PropsType): JSX.Element {
    const {
        className,
        value: selectedCompanyCategoryList,
        onChange: onChangeCategories,
        errorMessage,
        mode = FieldModeEnum.replacement,
        helpText,
    } = props;
    const fullClassName = classNames(styles.select_company_category_list, className);

    const {getLocalizedString} = useLocale();
    const {refresh: refreshSelect, refreshId: refreshSelectId} = useRefreshId();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [innerCategories, setInnerCategories] = useState<Array<CompanyGeneralInfoCategoryType>>([]);

    const {result: companyCategoryResult, isInProgress} = useSearchCompanyCategoryList(searchQuery || null);
    const companyCategoryList: Array<CompanyCategoryType> = companyCategoryResult ? companyCategoryResult.results : [];

    const isReplacement = mode === FieldModeEnum.replacement;
    const isAddition = mode === FieldModeEnum.addition;

    const categories = isAddition ? innerCategories : selectedCompanyCategoryList;
    const [firstCategory, ...restCategories] = categories;

    const removeFromSelectedCompanyCategory = useCallback(
        (item: CompanyGeneralInfoCategoryType) => {
            const newSelectedCompanyCategoryList = [...categories];

            newSelectedCompanyCategoryList.splice(newSelectedCompanyCategoryList.indexOf(item), 1);

            if (item.is_main && newSelectedCompanyCategoryList[0]) {
                newSelectedCompanyCategoryList[0].is_main = true;
            }

            onChangeCategories(newSelectedCompanyCategoryList);

            if (isAddition) {
                setInnerCategories(newSelectedCompanyCategoryList);
            }
        },
        [categories, onChangeCategories, isAddition]
    );

    const makePrimaryCompanyCategory = useCallback(
        (item: CompanyGeneralInfoCategoryType) => {
            const newSelectedCompanyCategoryList = categories
                .map((category) => ({
                    ...category,
                    is_main: category.id === item.id,
                }))
                .sort((itemA) => (itemA.is_main ? -1 : 1));

            onChangeCategories(newSelectedCompanyCategoryList);
        },
        [categories, onChangeCategories]
    );

    const handleSortEnd = useCallback(
        (sortData: {oldIndex: number; newIndex: number}) => {
            const {newIndex, oldIndex} = sortData;
            const shiftIndex = 1; // prevent moving first item
            const newSelectedCompanyCategoryList = arrayMove(
                [...categories],
                oldIndex + shiftIndex,
                newIndex + shiftIndex
            );

            onChangeCategories(newSelectedCompanyCategoryList);

            if (isAddition) {
                setInnerCategories(newSelectedCompanyCategoryList);
            }
        },
        [categories, onChangeCategories, isAddition]
    );

    const SortableItem = createSortableElement<CategoryListItemPropsType>(
        (sortableItemProps: CategoryListItemPropsType): JSX.Element => {
            const {value, isMain, mode: fieldMode} = sortableItemProps;

            return (
                <SelectedCompanyCategoryItem
                    companyCategoryItem={value}
                    isFirst={isMain}
                    isMarkPrimaryAvailable={fieldMode === FieldModeEnum.replacement}
                    makePrimaryCompanyCategory={makePrimaryCompanyCategory}
                    removeFromSelectedCompanyCategory={removeFromSelectedCompanyCategory}
                />
            );
        }
    );

    const SortableList = createSortableContainer<{items: Array<CompanyGeneralInfoCategoryType>}>(
        (sortableContainerProps: {items: Array<CompanyGeneralInfoCategoryType>}) => {
            const {items} = sortableContainerProps;
            const isDisabledSortableList = items.length === 1;

            return (
                <ul className={styles.selected_category_list}>
                    {items.map((item: CompanyGeneralInfoCategoryType, index: number): JSX.Element | null => {
                        return (
                            <SortableItem
                                disabled={isDisabledSortableList}
                                index={index}
                                isMain={false}
                                key={item.id}
                                mode={mode}
                                value={item}
                            />
                        );
                    })}
                </ul>
            );
        }
    );

    const pushToSelectedCompanyCategoryList = useCallback(
        (newItem: CompanyGeneralInfoCategoryType) => {
            const existedItem = categories.find((itemInList: CompanyGeneralInfoCategoryType): boolean => {
                return newItem.id === itemInList.id;
            });

            if (existedItem) {
                return;
            }

            onChangeCategories([...categories, newItem]);

            if (isAddition) {
                setInnerCategories([...categories, newItem]);
            }
        },
        [categories, onChangeCategories, isAddition]
    );

    function onChange(companyCategoryId: number) {
        const selectedCategory = companyCategoryList.find((companyCategory: CompanyCategoryType): boolean => {
            return companyCategoryId === companyCategory.id;
        });

        if (!selectedCategory) {
            return;
        }

        const isMain: boolean = selectedCompanyCategoryList.length === 0;

        const generalInfoCategory: CompanyGeneralInfoCategoryType = {
            id: selectedCategory.id,
            name: selectedCategory.translate,
            is_main: isMain,
        };

        pushToSelectedCompanyCategoryList(generalInfoCategory);
        setSearchQuery('');
        refreshSelect();
    }

    const renderOption = useCallback(
        (companyCategory: CompanyCategoryType): JSX.Element => {
            const {id, path, translate} = companyCategory;

            return (
                <Select.Option key={id} title={translate} value={id}>
                    {highlightOption(path, searchQuery)}
                </Select.Option>
            );
        },
        [searchQuery]
    );

    const shouldCancelStart = useCallback((event: SortEvent | SortEventWithTag) => {
        const disabledElements = ['button', 'svg', 'path'];
        const element = event.target as HTMLElement;

        return disabledElements.includes(element.tagName.toLowerCase());
    }, []);

    const additionalInfoText = helpText || <Locale stringKey="GENERAL_INFO__ADDITIONAL_INFO__MAIN_CATEGORY__HEADER" />;

    return (
        <div className={fullClassName}>
            <Form.Item
                className={styles.select_company_category_list__select}
                help={errorMessage ?? ''}
                label={<Locale stringKey="GENERAL_INFO__LABEL__SEARCH_CATEGORIES" />}
                name={'category-' + refreshSelectId}
                required
                validateStatus={errorMessage ? 'error' : ''}
            >
                <Select<number>
                    filterOption={() => true}
                    key={refreshSelectId}
                    loading={isInProgress}
                    // eslint-disable-next-line no-undefined
                    notFoundContent={searchQuery === '' ? null : undefined}
                    onChange={onChange}
                    onSearch={debounce<[string]>(setSearchQuery, 300)}
                    placeholder={getLocalizedString('TEXT__START_TYPING')}
                    showSearch
                    size="large"
                >
                    {companyCategoryList.map(renderOption)}
                </Select>
            </Form.Item>

            <div className={styles.select_company_category_list__wrapper}>
                <div className={styles.select_company_category_list__block}>
                    {firstCategory ? (
                        <SelectedCompanyCategoryItem
                            companyCategoryItem={firstCategory}
                            isFirst
                            isMarkPrimaryAvailable
                            makePrimaryCompanyCategory={makePrimaryCompanyCategory}
                            removeFromSelectedCompanyCategory={removeFromSelectedCompanyCategory}
                        />
                    ) : null}
                </div>

                {restCategories.length > 0 && isReplacement && (
                    <AdditionalInfo
                        className={styles.selected_category_list__additional_info}
                        rightNode={additionalInfoText}
                        title={<Locale stringKey="GENERAL_INFO__ADDITIONAL_INFO__MAIN_CATEGORY__HEADER" />}
                    >
                        <Locale stringKey="GENERAL_INFO__ADDITIONAL_INFO__MAIN_CATEGORY__TEXT" />
                    </AdditionalInfo>
                )}
            </div>

            <div className={styles.select_company_category_list__wrapper}>
                <SortableList
                    items={restCategories.filter(Boolean)}
                    lockAxis="y"
                    onSortEnd={handleSortEnd}
                    shouldCancelStart={shouldCancelStart}
                    useWindowAsScrollContainer
                />
            </div>
        </div>
    );
}
