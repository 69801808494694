export const enTagsTranslations = {
    TAGS__TAGS_FOR_REVIEWS: 'Tags for reviews',
    TAGS__ADD_TAG: 'Add tag',
    TAGS__LIST_OF_TAGS: 'Tag list',
    TAGS__TAG: 'Tag',
    TAGS__GROUP: 'Group',
    TAGS__TYPE: 'Type',
    TAGS__ACTIONS: 'Actions',
    TAGS__TAG_TYPE__ALL: 'All',
    TAGS__TAG_TYPE__PRIVATE: 'Private',
    TAGS__TAG_TYPE__COMMAND: 'Command',
    TAGS__REMOVING_A_TAG__HEADER: 'Tag removing',
    TAGS__REMOVING_A_TAG__CONTENT: 'The {tagTitle} tag(s) will be removed from all tagged reviews. Confirm?',
    TAGS__REMOVING_A_TAG_GROUP__HEADER: 'Deleting a group of tags',
    TAGS__REMOVING_A_TAG_GROUP__CONTENT:
        'The {tagGroupTitle} tag group(s) will be removed. Tags will remain without a group. Confirm?',

    TAGS__TAG_FORM_TITLE: 'Name',
    TAGS__TAG_FORM__ENTER_A_NAME_FOR_THE_TAG: 'Enter the tag',
    TAGS__TAG_FORM__PUBLIC: 'Available to the team',
    TAGS__TAG_FORM__COMMAND_TAG: 'Command tag',
    TAGS__TAG_FORM__TEAM_TAG_CAN_BE_USED_BY_YOUR_COLLEAGUES: 'Team tag can be used by your colleagues',

    TAGS__CREATING: 'Tag creation',
    TAGS__UPDATING: 'Tag editing',
    TAGS__CREATING_ERROR: 'Failed to create tag',
    TAGS__UPDATING_SUCCESS: 'Tag updated successfully',
    TAGS__UPDATING_ERROR: 'Failed to update tag',
    TAGS__DELETING_SUCCESS: 'Tag deleted successfully',
    TAGS__DELETING_ERROR: 'Failed to delete tag',
    TAGS__FORM__THE_TAG_WAS_SAVED_SUCCESSFULLY: 'Tag {tagTitle} saved successfully',

    TAGS__GROUP__ACTIONS: 'Actions',

    TAGS__GROUP__CREATING_SUCCESS: 'Group created successfully',
    TAGS__GROUP__CREATING_ERROR: 'Failed to create group',
    TAGS__GROUP__UPDATING_SUCCESS: 'Group updated successfully',
    TAGS__GROUP__UPDATING_ERROR: 'Failed to update group',
    TAGS__GROUP__DELETING_SUCCESS: 'Group deleted successfully',
    TAGS__GROUP__DELETING_ERROR: 'Failed to delete group',
    TAGS__GROUP__ENTER_TAG_GROUP_NAME: 'Enter a group name',
    TAGS__EMPTY_ERROR: 'Fill in the title field',
    TAG_GROUPS__TAG_GROUPS: 'Tag groups',
};
