import dayjs, {Dayjs} from 'dayjs';

import {endOfUtcDay, shiftToCurrentTimezone, startOfUtcDay} from '../../../../../../util/date';

export function dayjsRangeToCustomRange(dayjsRange: [Dayjs | null, Dayjs | null] | null): [Date | null, Date | null] {
    const [fromDayjs, toDayjs] = dayjsRange || [];

    if (!fromDayjs || !toDayjs) {
        return [null, null];
    }

    const fromDate = startOfUtcDay(fromDayjs.toDate());
    const toDate = endOfUtcDay(toDayjs.toDate());

    return [fromDate, toDate];
}

export function customRangeToDayjsRange(range: [Date | null, Date | null]): [Dayjs | null, Dayjs | null] | null {
    const [start, end] = range;

    if (!start || !end) {
        return null;
    }

    return [dayjs(shiftToCurrentTimezone(start)), dayjs(shiftToCurrentTimezone(end))];
}
