import {useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {Header} from '../../../../../component/header/common/header/header';
import {HeaderButtonEnum} from '../../../../../component/header/common/header-buttons/header-buttons-hook';
import {Main} from '../../../../../component/main/main';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import * as pageStyles from '../../../../../layout/page/page.scss';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useCompaniesImportValidation} from '../../../../../service/companies-import/companies-import';
import {CompaniesImportTypeEnum} from '../../../../../service/companies-import/companies-import-type';
import {classNames} from '../../../../../util/css';
import {FeedForm} from '../common/form/feed-form';
import * as feedFormPageStyles from '../common/form-page/feed-form-page.scss';
import {FeedResults} from '../common/results/feed-results';
import {FeedResultsTypeEnum} from '../common/results/feed-results-const';
import {TemplateLinks} from '../common/template-links/template-links';

import * as styles from './companies-validation-not-authorized.scss';

export function CompaniesValidationNotAuthorized(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const importValidation = useCompaniesImportValidation(FeedResultsTypeEnum.Validation);

    const {mutate, reset} = importValidation;

    const [feedFormat, setFeedFormat] = useState<CompaniesImportTypeEnum | null>(null);

    return (
        <div className={classNames(pageStyles.page, styles.CompaniesValidation_page)}>
            <Meta title={getLocalizedString('FEED__VALIDATION__TITLE__NOT_AUTHORIZED')} />

            <Header
                buttons={[HeaderButtonEnum.Language]}
                isFilterHidden
                isToggleButtonHidden
                logoClassName={styles.CompaniesValidation_headerLogo}
            />

            <Main
                mainClassName={feedFormPageStyles.FeedFormPage_main}
                wrapperClassName={feedFormPageStyles.FeedFormPage_mainWrapper}
            >
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.companyValidate.path,
                            titleLangKey: 'FEED__VALIDATION__TITLE__NOT_AUTHORIZED',
                        },
                    ]}
                />

                <PageHeader>
                    <Locale stringKey="FEED__VALIDATION__HEADER__NOT_AUTHORIZED" />
                </PageHeader>

                <div className={feedFormPageStyles.FeedFormPage_content}>
                    <div className={feedFormPageStyles.FeedFormPage_left}>
                        <FeedForm
                            importValidation={importValidation}
                            onAbort={() => reset()}
                            onSubmit={(options) => {
                                mutate(options);
                                setFeedFormat(options.importType);
                            }}
                            translations={{
                                beforeProgressTitle: 'FEED__VALIDATION__ABORT__BUTTON',
                                submitButtonLabel: 'FEED__VALIDATION__FORM__SUBMIT',
                                abortButtonLabel: 'FEED__VALIDATION__ABORT__BUTTON',
                                abortModalTitle: 'FEED__VALIDATION__ABORT_MODAL__TITLE',
                                abortModalDescription: 'FEED__VALIDATION__ABORT_MODAL__DESCRIPTION',
                                progressTitle: 'FEED__VALIDATION__PROGRESS__TITLE',
                            }}
                            type={FeedResultsTypeEnum.Validation}
                        />
                    </div>

                    <aside className={feedFormPageStyles.FeedFormPage_right}>
                        <TemplateLinks type={FeedResultsTypeEnum.Validation} />
                    </aside>
                </div>
            </Main>

            <FeedResults
                feedFormat={feedFormat}
                importValidation={importValidation}
                type={FeedResultsTypeEnum.Validation}
            />
        </div>
    );
}
