import {Select} from 'antd';
import {Dispatch, SetStateAction} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FilterIcon, ListHeader} from '../../../common/list-header/list-header';
import {googleAccountFilters} from '../accounts-step-const';
import {AccountFilterEnum} from '../accounts-step-type';

type PropsType = {
    total: number;
    filter?: AccountFilterEnum;
    setFilter: Dispatch<SetStateAction<AccountFilterEnum>>;
};

export function AccountsListHeader(props: PropsType): JSX.Element {
    const {total, filter, setFilter} = props;

    return (
        <ListHeader
            left={
                <>
                    {filter !== AccountFilterEnum.All && <FilterIcon />}

                    <span>
                        <Locale
                            stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__FOUND"
                            valueMap={{
                                total: <Text bolder>{total}</Text>,
                            }}
                        />
                    </span>
                </>
            }
            right={
                <Select
                    onChange={setFilter}
                    options={googleAccountFilters}
                    popupMatchSelectWidth={false}
                    size="small"
                    value={filter}
                />
            }
        />
    );
}
