import {DateTimeFormatOptionsType} from '../../util/format';
import {useFormat} from '../../util/format-hook/format-hook';

type PropsType = {
    value: Date | number;
    options?: DateTimeFormatOptionsType;
};

export function FormattedDateTime(props: PropsType): JSX.Element {
    const {value, options} = props;

    const {getFormattedDateTime} = useFormat();

    return <>{getFormattedDateTime(value, options)}</>;
}
