import {NotificationResultType} from './notification-type';

export const defaultNotificationResult: NotificationResultType = {
    results: [],
    count: 0,
    page: 0,
    pages: 0,
    next: null,
    previous: null,
    page_size: 0,

    unread_count: 0,
};
