import {useState} from 'react';

import {appRoute} from '../../../../app-route';
import {PageHeaderWithLicenseTag} from '../../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {TrialActivationModal} from '../../../../component/landing/trial-activation-modal/trial-activation-modal';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {DEFAULT_TRIAL_PERIOD_DAYS} from '../../../../provider/license/license-const';
import {useTrialActivation} from '../../../../provider/license/license-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {LicensesCodeEnum} from '../../tariffs-and-limits/licenses-block/license/license-const';

import {PostActivationLanding} from './components/post-activation-landing/post-activation-landing';
import {PostsOnboarding} from './onboarding/posts-onboarding';

export function PostActivation(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {mutateAsync, isLoading} = useTrialActivation();
    const [isActivationModalOpen, setIsActivationModalOpen] = useState<boolean>(false);
    const [isLicenseActivated, setIsLicenseActivated] = useState<boolean>(false);

    function handleTrialPeriodActivate() {
        setIsActivationModalOpen(false);
        setIsLicenseActivated(true);
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__POSTS')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'CATEGORY_NAME__POSTS',
                        },
                    ]}
                />
                <PageHeaderWithLicenseTag
                    headerLangKey="POSTS__HEADER"
                    licenseActive={isLicenseActivated}
                    trialLicense
                />
            </PageCard>

            {!isLicenseActivated ? (
                <PostActivationLanding onTryFeatureClick={() => setIsActivationModalOpen(true)} />
            ) : (
                <PostsOnboarding />
            )}

            <TrialActivationModal
                activateTrial={() => mutateAsync(LicensesCodeEnum.PostsLicence)}
                isActivatingTrial={isLoading}
                onCancel={() => setIsActivationModalOpen(false)}
                onTrialPeriodActivated={handleTrialPeriodActivate}
                open={isActivationModalOpen}
                productNameLangKey="CATEGORY_NAME__POSTS"
                trialPeriodDuration={DEFAULT_TRIAL_PERIOD_DAYS}
            />
        </Page>
    );
}
