import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';

import {
    reviewAnalysisRadarItemsSchema,
    ReviewAnalysisRadarType,
    ReviewsAnalysisRadarQueryType,
} from './review-radar-type';

export async function fetchReviewRadarStats(
    options: ReviewsAnalysisRadarQueryType,
    mainFilterKey: string
): Promise<Array<ReviewAnalysisRadarType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisRadarQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/brands/?${searchParameters}`;

    return fetchAndDeserialize<Array<ReviewAnalysisRadarType>>(url, reviewAnalysisRadarItemsSchema);
}
