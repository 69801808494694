import {faGrid2Plus} from '@fortawesome/pro-regular-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, List, Modal, Popover} from 'antd';
import {useEffect, useState} from 'react';

import {PageHeaderSub} from '../../../../layout/page-header/page-header-sub';
import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../provider/locale/locale';
import {BULK_EDIT_FIELDS} from '../bulk-edit-companies-const';
import {BulkEditFieldType} from '../bulk-edit-companies-type';

import * as styles from './choose-fields-modal.scss';

type PropsType = {
    fields: Array<BulkEditFieldType>;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    onOk: (fields: Array<BulkEditFieldType>) => void;
};

export function ChooseFieldsModal(props: PropsType): JSX.Element {
    const {fields, isOpen, setIsOpen, onOk} = props;

    const [selectedFields, setSelectedFields] = useState<Array<BulkEditFieldType>>([]);

    const hasSelected = selectedFields.length > 0;

    function handleCancelModal(): void {
        setIsOpen(false);
    }

    function handleOkModal(): void {
        onOk(selectedFields);

        setIsOpen(false);
    }

    function handleClickField(field: BulkEditFieldType): void {
        const isFieldSelected = selectedFields.some((selectedField) => selectedField.name === field.name);

        if (isFieldSelected) {
            setSelectedFields(selectedFields.filter((selectedField) => selectedField.name !== field.name));
            return;
        }

        // sort fields to keep the same order with the rendered list
        const newSelectedFields = [...selectedFields, field];
        const sortedFields: Array<BulkEditFieldType> = [];

        BULK_EDIT_FIELDS.forEach((staticField) => {
            const selectedField = newSelectedFields.find((selected) => selected.name === staticField.name);

            if (selectedField) {
                sortedFields.push(selectedField);
            }
        });

        setSelectedFields(sortedFields);
    }

    useEffect(() => {
        setSelectedFields(fields);
    }, [fields]);

    function renderField(field: BulkEditFieldType): JSX.Element {
        const isSelected = selectedFields.some((selectedField) => selectedField.name === field.name);

        return (
            <List.Item
                className={styles.choose_fields__list_item}
                key={field.name}
                onClick={() => handleClickField(field)}
            >
                <span>
                    {isSelected && (
                        <FontAwesomeIcon className={styles.choose_fields__list_item_selected_icon} icon={faCheck} />
                    )}

                    <Locale stringKey={field.label} />
                </span>

                {field.isAddingAvailable && (
                    <Popover
                        content={
                            <PopoverTextWrapper>
                                <Locale stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADDING_ALLOWED_DESCRIPTION" />
                            </PopoverTextWrapper>
                        }
                        title={
                            <PopoverTextWrapper>
                                <Locale stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADDING_ALLOWED" />
                            </PopoverTextWrapper>
                        }
                    >
                        <span className={styles.choose_fields__list_item_adding_available_icon}>
                            <FontAwesomeIcon icon={faGrid2Plus} />
                        </span>
                    </Popover>
                )}
            </List.Item>
        );
    }

    return (
        <Modal
            footer={[
                <Button key="cancel" onClick={handleCancelModal}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button disabled={!hasSelected} key="ok" onClick={handleOkModal} type="primary">
                    <Locale stringKey="TEXT__APPLY" />
                </Button>,
            ]}
            onCancel={handleCancelModal}
            onOk={handleOkModal}
            open={isOpen}
            title={<Locale stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__MODAL__TITLE" />}
        >
            <div className={styles.choose_fields__info_container}>
                <PageSubHeader className={styles.choose_fields__info_header}>
                    <Locale stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__MODAL__HEADER" />
                </PageSubHeader>

                <PageHeaderSub className={styles.choose_fields__info_sub_header}>
                    <Locale stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__MODAL__SUB_HEADER" />
                </PageHeaderSub>
            </div>

            <div className={styles.choose_fields__list_container}>
                <List bordered dataSource={BULK_EDIT_FIELDS} renderItem={renderField} />
            </div>
        </Modal>
    );
}
