import {useState} from 'react';

import {Empty} from '../../../../layout/empty/empty';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/localization';
import {
    ActionDynamicsEnum,
    FormattedPeriodsType,
    OnlinePresenceDashboardDataCommonType,
} from '../../../../service/online-presence/online-presence-type';
import {checkIsEmptyChartData} from '../../../../util/chart';

import {TargetActionsChart} from './components/target-actions-chart/target-actions-chart';
import {TargetActionsLegend} from './components/target-actions-legend/target-actions-legend';
import {TargetActionsTable} from './components/target-actions-table/target-actions-table';
import * as styles from './target-actions-dynamics.scss';

type PropsType = {
    data: OnlinePresenceDashboardDataCommonType['actionsDynamic'];
    labels: Array<string>;
    comparedLabels: Array<string>;
    isCompareMode: boolean;
    formattedPeriods?: FormattedPeriodsType;
    bordered?: boolean;
    staticChart?: boolean;
};

export function TargetActionsDynamics(props: PropsType): JSX.Element {
    const {data, labels, isCompareMode, comparedLabels, formattedPeriods, bordered, staticChart} = props;

    const isEmpty: boolean = checkIsEmptyChartData(data.bar.current) && checkIsEmptyChartData(data.bar.compared);

    const [legendFilter, setLegendFilter] = useState<Array<ActionDynamicsEnum>>(Object.values(ActionDynamicsEnum));
    const [isCompareLegendActive, setIsCompareLegendActive] = useState<boolean>(true);

    return (
        <PageCard
            bordered={bordered}
            title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__TITLE" />}
        >
            <div className={styles.TargetActionDynamics}>
                {!isEmpty ? (
                    <>
                        <div>
                            <TargetActionsLegend
                                data={data}
                                isCompareLegendActive={isCompareLegendActive}
                                isCompareMode={isCompareMode}
                                legendFilter={legendFilter}
                                setIsCompareLegendActive={setIsCompareLegendActive}
                                setLegendFilter={setLegendFilter}
                                staticChart={staticChart}
                            />
                            <TargetActionsChart
                                comparedLabels={comparedLabels}
                                data={data}
                                isCompareLegendActive={isCompareLegendActive}
                                isCompareMode={isCompareMode}
                                labels={labels}
                                legendFilter={legendFilter}
                                staticChart={staticChart}
                            />
                        </div>
                        <TargetActionsTable
                            data={data}
                            formattedPeriods={formattedPeriods}
                            isCompareMode={isCompareMode}
                        />
                    </>
                ) : (
                    <Empty secondaryText="ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__EMPTY" />
                )}
            </div>
        </PageCard>
    );
}
