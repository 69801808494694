import {faFile, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useEffect, useState} from 'react';

import {danger50, gray7} from '../../../../css/var-export.scss';
import {Spinner} from '../../../../layout/spinner/spinner';
import {CustomFileType} from '../../../../service/file-upload/file-upload-type';
import {classNames} from '../../../../util/css';
import {Text} from '../../../text/text';

import * as styles from './upload-file-list-item.scss';

type PropsType = {
    file: CustomFileType;
    allowDownload?: boolean;
    onRemove?: (fileUid: string) => void;
    imageClassname?: string;
    contentClassName?: string;
    buttonClassName?: string;
};

export function UploadFileListItem(props: PropsType): JSX.Element {
    const {file, allowDownload, onRemove, imageClassname, contentClassName, buttonClassName} = props;

    const [showImage, setShowImage] = useState(false);

    const isUploading = file.status === 'uploading';

    useEffect(() => {
        const newImage = new Image();

        newImage.src = file.url;
        newImage.addEventListener('load', () => setShowImage(true), false);
        newImage.addEventListener('error', () => setShowImage(false), false);
    }, [file]);

    return (
        <div
            className={classNames(styles.UploadFileListItem, {
                [styles.UploadFileListItem__error]: file.status === 'error',
            })}
        >
            {!isUploading ? (
                <div className={classNames(styles.UploadFileListItem_content, contentClassName)}>
                    {showImage ? (
                        <img
                            alt=""
                            className={classNames(styles.UploadFileListItem_image, imageClassname)}
                            src={file.url}
                        />
                    ) : (
                        <div className={styles.UploadFileListItem_iconContainer}>
                            <FontAwesomeIcon className={styles.UploadFileListItem_icon} icon={faFile} />
                        </div>
                    )}

                    <Text ellipsis>{file.name}</Text>
                </div>
            ) : (
                <div className={styles.UploadFileListItem_spinner}>
                    <Spinner size={44} spinSize="small" wrapperHeight={44} />
                </div>
            )}

            {onRemove && (
                <Button
                    className={buttonClassName}
                    disabled={isUploading}
                    icon={<FontAwesomeIcon color={file.status !== 'error' ? gray7 : danger50} icon={faTrash} />}
                    onClick={() => onRemove(file.uid)}
                    type="text"
                />
            )}

            {allowDownload && (
                <Button
                    download={file.name}
                    href={file.url}
                    icon={<FontAwesomeIcon icon={faDownload} />}
                    target="_blank"
                    type="link"
                />
            )}
        </div>
    );
}
