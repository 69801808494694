import {Empty} from 'antd';
import {useMemo} from 'react';

import {classNames} from '../../util/css';
import {useFormat} from '../../util/format-hook/format-hook';
import {DoughnutChart} from '../chart/doughnut-chart/doughnut-chart';
import {ChartJsDoughnutOptionsType, DoughnutDataType} from '../chart/doughnut-chart/doughnut-chart-type';
import {DoughnutStatisticPropsType} from '../doughnut-statistic/doughnut-statistic-type';

import * as styles from './doughnut-chart-with-center-data.scss';

type PropsType = {
    centerContent?: JSX.Element | null;
    chartOptions?: ChartJsDoughnutOptionsType;
    className?: string;
    chartWrapperClassName?: string;
    statisticsRenderer?: (dataEntry: DoughnutStatisticPropsType) => JSX.Element;
    data: Array<DoughnutDataType>;
    statisticsClassName?: string;
    hideOnEmptyData?: boolean;
};

export function DoughnutChartWithCenterData(props: PropsType): JSX.Element {
    const {
        centerContent,
        data,
        className,
        chartWrapperClassName,
        chartOptions,
        statisticsRenderer,
        statisticsClassName,
        hideOnEmptyData = false,
    } = props;

    const {getFormattedNumber} = useFormat();

    const totalCountInData: number = useMemo(() => {
        return data.reduce<number>((accum: number, item: DoughnutDataType): number => accum + item.value, 0);
    }, [data]);

    if (hideOnEmptyData && !totalCountInData) {
        return <Empty />;
    }

    return (
        <div className={classNames(styles.DoughnutWithCenterData, chartWrapperClassName)}>
            <div className={styles.DoughnutWithCenterData_chartWrapper}>
                <div className={classNames(styles.DoughnutWithCenterData_chartCenter)}>{centerContent}</div>

                <div className={classNames(styles.DoughnutWithCenterData_chartContainer, className)}>
                    <DoughnutChart chartOptions={chartOptions} data={data} />
                </div>
            </div>

            {statisticsRenderer && (
                <div className={classNames(styles.DoughnutWithCenterData_statistics, statisticsClassName)}>
                    {data.map((dataEntry: DoughnutDataType) => {
                        return statisticsRenderer({
                            color: dataEntry.color,
                            label: dataEntry.label,
                            value: `${getFormattedNumber(
                                totalCountInData ? (dataEntry.value / totalCountInData) * 100 : 0,
                                {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                }
                            )}%`,
                        });
                    })}
                </div>
            )}
        </div>
    );
}
