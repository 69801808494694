import {useCallback, useMemo, useState} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../../component/text/text';
import {BarChart} from '../../../../../layout/chart/bar-chart/bar-chart';
import {getDefaultReactDoughnutTooltipOptions} from '../../../../../layout/chart/chart-const';
import {LegendBox} from '../../../../../layout/chart/legend-box/legend-box';
import {LegendWrapper} from '../../../../../layout/chart/legend-wrapper/legend-wrapper';
import {PieChart} from '../../../../../layout/chart/pie-chart/pie-chart';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useReviewsSummary} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-hook';
import {getFormattedDateTime} from '../../../../../util/format';
import {WidgetFooterText} from '../../../dashboard/components/widget-footer-text/widget-footer-text';
import * as companyActivityAndSummaryStyles from '../../company-activity-and-summary.scss';

import {getDashboardReviewAnalysisChartOptions} from './reviews-summary-const';
import {castToBarChartData, castToPieChartData, getEdgeDates, getReviewsSummaryOptions} from './reviews-summary-helper';
import {ReviewsSummaryMainMetrics} from './reviews-summary-main-metrics/reviews-summary-main-metrics';
import * as styles from './reviews-summary.scss';

export function ReviewsSummary(): JSX.Element {
    const {companyId} = useParams<ExtractRouteParams<typeof appRoute.companyActivityAndSummary.path, string>>();

    const {localeName, getLocalizedString} = useLocale();

    const {start, end} = useMemo(() => getEdgeDates(), []);
    const formattedStart = getFormattedDateTime(localeName, start);
    const formattedEnd = getFormattedDateTime(localeName, end);
    const reviewsSummaryOptions = getReviewsSummaryOptions(Number(companyId), start, end);
    const {result, processError} = useReviewsSummary(reviewsSummaryOptions);

    const [sentimentChartLegendFilter, setSentimentChartLegendFilter] = useState<Array<string>>([]);

    const onSentimentLegendElementClick = useCallback((datasetLabel: string) => {
        setSentimentChartLegendFilter((previousValues) => {
            if (previousValues.includes(datasetLabel)) {
                return previousValues.filter((item) => item !== datasetLabel);
            }

            return [...previousValues, datasetLabel];
        });
    }, []);

    const [reviewsDistributionLegendFilter, setReviewsDistributionLegendFilter] = useState<Array<string>>([]);

    const onReviewsDistributionLegendItemElementClick = useCallback((datasetLabel: string) => {
        setReviewsDistributionLegendFilter((previousValues) => {
            if (previousValues.includes(datasetLabel)) {
                return previousValues.filter((item) => item !== datasetLabel);
            }

            return [...previousValues, datasetLabel];
        });
    }, []);

    if (processError) {
        return <AlertFallback />;
    }

    if (result) {
        const barChartData = castToBarChartData(result, getLocalizedString);
        const pieChartData = castToPieChartData(result, getLocalizedString);
        const pieChartOptions = {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: getDefaultReactDoughnutTooltipOptions({
                    data: pieChartData,
                    title: '',
                }),
            },
        };

        return (
            <div className={styles.DashboardReviewsSummary}>
                <div>
                    <Text block lighter>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS__SUBHEADER" />
                    </Text>

                    <Text block className={styles.DashboardReviewsSummary_subHeaderPeriod} secondary>
                        <Locale
                            stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS__PERIOD"
                            valueMap={{
                                start: formattedStart,
                                end: formattedEnd,
                            }}
                        />
                    </Text>
                    <ReviewsSummaryMainMetrics data={result} />
                </div>

                <div>
                    <Text block lighter>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_HEADER" />
                    </Text>
                    <Text block className={styles.DashboardReviewsSummary_subHeaderPeriod} secondary>
                        <Locale
                            stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS__PERIOD"
                            valueMap={{
                                start: formattedStart,
                                end: formattedEnd,
                            }}
                        />
                    </Text>
                    <div className={styles.DashboardReviewsSummary_barChartContainer}>
                        <LegendWrapper>
                            {barChartData.datasets.map((dataset) => (
                                <LegendBox
                                    checked={!sentimentChartLegendFilter.includes(dataset.label)}
                                    color={String(dataset.color) || ''}
                                    key={dataset.label}
                                    label={
                                        <Locale
                                            stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_LEGEND_ITEM"
                                            valueMap={{
                                                tone: dataset.label,
                                                count: dataset.values.reduce(
                                                    (accumulator, value) => accumulator + value,
                                                    0
                                                ),
                                            }}
                                        />
                                    }
                                    onChange={() => {
                                        if (
                                            barChartData.datasets
                                                .filter((item) => item !== dataset)
                                                .some((item) => !reviewsDistributionLegendFilter.includes(item.label))
                                        ) {
                                            onSentimentLegendElementClick(dataset.label);
                                        }
                                    }}
                                />
                            ))}
                        </LegendWrapper>
                        <div className={companyActivityAndSummaryStyles.CompanyActivityAndSummary_chart}>
                            <BarChart
                                data={{
                                    ...barChartData,
                                    datasets: barChartData.datasets.filter(
                                        (dataset) => !sentimentChartLegendFilter.includes(dataset.label)
                                    ),
                                }}
                                options={getDashboardReviewAnalysisChartOptions(barChartData)}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <Text block lighter>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_HEADER" />
                    </Text>
                    <Text block className={styles.DashboardReviewsSummary_subHeaderPeriod} secondary>
                        <Locale
                            stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS__PERIOD"
                            valueMap={{
                                start: formattedStart,
                                end: formattedEnd,
                            }}
                        />
                    </Text>

                    <div className={styles.DashboardReviewsSummary_pieChartContainer}>
                        <div className={styles.DashboardReviewsSummary_pieChart}>
                            <PieChart
                                data={pieChartData.filter(
                                    (dataset) => !reviewsDistributionLegendFilter.includes(dataset.label)
                                )}
                                options={pieChartOptions}
                            />
                        </div>
                        <LegendWrapper className={styles.DashboardReviewsSummary_pieChartLegend}>
                            {pieChartData.map((dataset) => {
                                return (
                                    <LegendBox
                                        checked={!reviewsDistributionLegendFilter.includes(dataset.label)}
                                        color={dataset.color}
                                        key={dataset.label}
                                        label={dataset.label}
                                        onChange={() => {
                                            if (
                                                pieChartData
                                                    .filter((item) => item !== dataset)
                                                    .some(
                                                        (item) => !reviewsDistributionLegendFilter.includes(item.label)
                                                    )
                                            ) {
                                                onReviewsDistributionLegendItemElementClick(dataset.label);
                                            }
                                        }}
                                    />
                                );
                            })}
                        </LegendWrapper>
                    </div>
                </div>

                <WidgetFooterText
                    linkHref={appRoute.reviewsAnalysis.path}
                    linkLangKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_LINK_TEXT"
                    textLangKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_FOOTER_TEXT"
                />
            </div>
        );
    }

    return (
        <div className={companyActivityAndSummaryStyles.CompanyActivityAndSummary_spinner}>
            <Spinner />
        </div>
    );
}
