import {Dayjs} from 'dayjs';
import {DisabledTimes} from 'rc-picker/lib/interface';
import {useCallback, useMemo} from 'react';

import {DateTimeDisablerHookPropsType, DateTimeDisablerHookType} from './datetime-picker-hook-type';

export function useDateTimePickerDisabler(props: DateTimeDisablerHookPropsType): DateTimeDisablerHookType {
    const {before, after} = props;

    const firstAvailableDayjs: Dayjs | null = useMemo(() => {
        return after || null;
    }, [after]);

    const lastAvailableDayjs: Dayjs | null = useMemo(() => {
        return before || null;
    }, [before]);

    function dayStart(date: Dayjs) {
        return date.clone().hour(0).minute(0).second(0).millisecond(0);
    }

    function hourStart(date: Dayjs, hour: number | null = null) {
        if (hour !== null) {
            return date.clone().hour(hour).minute(0).second(0).millisecond(0);
        }

        return date.clone().minute(0).second(0).millisecond(0);
    }

    function minuteStart(date: Dayjs, minute: number | null = null) {
        if (minute !== null) {
            return date.clone().minute(minute).second(0).millisecond(0);
        }

        return date.clone().second(0).millisecond(0);
    }

    const dateDisabled = useCallback(
        (date: Dayjs) => {
            let isValidByBeforeRule = true;
            let isValidByAfterRule = true;

            if (firstAvailableDayjs) {
                isValidByBeforeRule = dayStart(date).isSameOrAfter(dayStart(firstAvailableDayjs));
            }

            if (lastAvailableDayjs) {
                isValidByAfterRule = dayStart(date).isSameOrBefore(dayStart(lastAvailableDayjs));
            }

            return !isValidByBeforeRule || !isValidByAfterRule;
        },
        [lastAvailableDayjs, firstAvailableDayjs]
    );

    const timeDisabled = useCallback(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (date: Dayjs | null): DisabledTimes => {
            function disabledHours() {
                return Array.from({length: 24})
                    .map((_value: unknown, hour: number) => hour)
                    .filter((hour: number) => {
                        let isValidByBeforeRule = true;
                        let isValidByAfterRule = true;

                        if (date && firstAvailableDayjs) {
                            isValidByBeforeRule = hourStart(date, hour).isSameOrAfter(hourStart(firstAvailableDayjs));
                        }

                        if (date && lastAvailableDayjs) {
                            isValidByAfterRule = hourStart(date, hour).isSameOrBefore(hourStart(lastAvailableDayjs));
                        }

                        return !isValidByAfterRule || !isValidByBeforeRule;
                    });
            }

            function disabledMinutes(hour: number) {
                return Array.from({length: 60})
                    .map((_value: unknown, minute: number) => minute)
                    .filter((minute: number) => {
                        let isValidByBeforeRule = true;
                        let isValidByAfterRule = true;

                        if (date && firstAvailableDayjs) {
                            isValidByAfterRule = minuteStart(hourStart(date, hour), minute).isAfter(
                                minuteStart(firstAvailableDayjs)
                            );
                        }

                        if (date && lastAvailableDayjs) {
                            isValidByBeforeRule = minuteStart(hourStart(date, hour), minute).isBefore(
                                minuteStart(lastAvailableDayjs)
                            );
                        }

                        return !isValidByAfterRule || !isValidByBeforeRule;
                    });
            }

            return {
                disabledHours,
                disabledMinutes,
            };
        },
        [lastAvailableDayjs, firstAvailableDayjs]
    );

    return {dateDisabled, timeDisabled};
}
