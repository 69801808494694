import {Button, Input} from 'antd';
import {SyntheticEvent, useCallback, useEffect, useState} from 'react';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {fetchNotesCreate, fetchNotesUpdate} from '../../../../../service/reviews/reviews-notes';
import {MAX_ROWS_TEXTAREA, MIN_ROWS_TEXTAREA} from '../../reviews-const';

import {handleNoteError} from './note-form-helper';
import * as styles from './note-form.scss';

type PropsType = {
    reviewId: number;
    onCancel: () => void;
    onSave: () => void;
    editableNoteId?: number;
    editableNoteText?: string;
};

export function NoteForm(props: PropsType): JSX.Element | null {
    const {onSave, reviewId, editableNoteId, editableNoteText, onCancel} = props;
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const [isNoteSavingInProgress, setIsNoteSavingInProgress] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState(false);

    const [noteText, setNoteText] = useState<string>('');
    const [isTextChanged, setIsTextChanged] = useState(false);

    useEffect(() => {
        if (editableNoteText && editableNoteText !== '') {
            setNoteText(editableNoteText);
        }
    }, [editableNoteText]);

    async function handleSaveNote() {
        try {
            setIsNoteSavingInProgress(true);

            await fetchNotesCreate(reviewId, {
                text: noteText.trim(),
            });

            setNoteText('');
            await onSave();

            snackbar.success({
                description: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_SUCCESS_DESCRIPTION" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_SUCCESS_TITLE" />,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.CreateNote);
        } catch (error: unknown) {
            const message = handleNoteError(error);

            if (message) {
                snackbar.error(message);
                return;
            }

            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_FAIL" />,
            });
        } finally {
            setIsNoteSavingInProgress(false);
        }
    }

    async function handleEditNote() {
        if (!editableNoteId) {
            return;
        }

        try {
            setIsNoteSavingInProgress(true);

            await fetchNotesUpdate(reviewId, editableNoteId, {
                text: noteText.trim(),
            });

            setNoteText('');
            await onSave();

            snackbar.success({
                description: <Locale stringKey="REVIEWS__REVIEW_NOTE_CHANGE_SUCCESS_DESCRIPTION" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_CHANGE_SUCCESS_TITLE" />,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.EditNote);
        } catch (error: unknown) {
            const message = handleNoteError(error);

            if (message) {
                snackbar.error(message);
                return;
            }

            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_CHANGE_FAIL" />,
            });
        } finally {
            setIsNoteSavingInProgress(false);
        }
    }

    function handleClickCancel() {
        setNoteText('');

        setIsFocus(false);

        onCancel();
    }

    function handleFocus() {
        setIsFocus(true);
    }

    const handleInput = useCallback((event: SyntheticEvent<HTMLTextAreaElement>) => {
        setIsTextChanged(true);
        setNoteText(event.currentTarget.value);
    }, []);

    return (
        <>
            <Input.TextArea
                className={styles.NoteForm_textarea}
                onFocus={handleFocus}
                onInput={handleInput}
                placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_NOTE_TEXT')}
                rows={isFocus ? MAX_ROWS_TEXTAREA : MIN_ROWS_TEXTAREA}
                size="large"
                value={noteText}
            />

            {isFocus && (
                <div className={styles.NoteForm_buttonWrapper}>
                    <Button
                        disabled={noteText.length === 0 || !isTextChanged}
                        loading={isNoteSavingInProgress}
                        onClick={editableNoteId ? handleEditNote : handleSaveNote}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>

                    <Button onClick={handleClickCancel}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>
                </div>
            )}
        </>
    );
}
