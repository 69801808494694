import {fetchAndDeserialize, postAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {
    ArticleIsUsefulType,
    knowledgeDataArticleSchema,
    KnowledgeDataArticleType,
    KnowledgeOptionsType,
    knowledgeResponseSchema,
    KnowledgeResponseType,
} from './knowledge-type';

export async function fetchKnowledgeArcticleList(
    options: KnowledgeOptionsType,
    mainFilterKey: string
): Promise<KnowledgeResponseType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<KnowledgeOptionsType>(options),
        filter_key: mainFilterKey,
    });

    return fetchAndDeserialize(`/cp/helpdeskeddy/knowledgebase/?${searchParameters}`, knowledgeResponseSchema);
}

export async function fetchKnowledgeArcticle(id: number): Promise<KnowledgeDataArticleType> {
    return fetchAndDeserialize(`/cp/helpdeskeddy/article/${id}/`, knowledgeDataArticleSchema);
}

export async function fetchArticleFeedback(id: number, data: ArticleIsUsefulType): Promise<KnowledgeDataArticleType> {
    return postAndDeserialize<KnowledgeDataArticleType>(
        `/cp/helpdeskeddy/article/${id}/`,
        knowledgeDataArticleSchema,
        data
    ).catch((error) => {
        throw error;
    });
}
