import {DatePicker, Form, FormInstance, Input, Select} from 'antd';
import dayjs from 'dayjs';
import {useCallback, useEffect, useState} from 'react';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {FormFieldset} from '../../../../layout/form/form-fieldset/form-fieldset';
import {selectRatingContentStarsWithNoRating} from '../../../../layout/select/select-helper';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {ReviewFormErrorType} from '../../../../service/reviews/custom-reviews';
import {useReviewCustomBrands} from '../../../../service/reviews/reviews-custom-brands';
import {ReviewCustomCompanyType} from '../../../../service/reviews/reviews-custom-compamies';
import {ReviewFormSourcesType, useReviewFormSources} from '../../../../service/reviews/reviews-form-sources';

import {CompanySelect} from './company-select/company-select';
import {extractSource, getDisabledDate} from './review-form-helper';
import {ReviewFormFieldNamesEnum, ReviewFormFieldsType} from './review-form-type';
import * as styles from './review-form.scss';

type PropsType = {
    form: FormInstance<ReviewFormFieldsType>;
    serverError: ReviewFormErrorType | null;
    initialCompany?: ReviewCustomCompanyType | null;
    initialValues?: ReviewFormFieldsType;
    authorInfo?: string | null;
};

export function ReviewForm(props: PropsType): JSX.Element {
    const {initialCompany, form, serverError, initialValues, authorInfo} = props;

    const {getLocalizedString} = useLocale();
    const {getPhoneNumberRule, requiredFieldRule, emailFieldRule, validUrlRule} = useFormRules();
    const {data: sources, isFetching: isSourcesInProgress} = useReviewFormSources();
    const {data: brands, isFetching: isBrandsInProgress} = useReviewCustomBrands();

    const [selectedSource, setSelectedSource] = useState<ReviewFormSourcesType | null>(null);

    const [selectedBrand, setSelectedBrand] = useState<number | null>(null);

    const handleSourceChange = useCallback(
        (sourceId: ReviewFormSourcesType['id']) => {
            if (sources) {
                setSelectedSource(extractSource(sourceId, sources));
            }
        },
        [sources]
    );

    useEffect(() => {
        const hasInitialSource = initialValues && initialValues[ReviewFormFieldNamesEnum.source];

        if (!hasInitialSource && sources && sources[0]) {
            const [source] = sources;

            setSelectedSource(source);

            form.setFieldsValue({[ReviewFormFieldNamesEnum.source]: source.id});
        }
    }, [form, initialValues, sources, authorInfo]);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    return (
        <Form form={form} initialValues={initialValues} layout="vertical">
            <FormFieldset>
                <div className={styles.ReviewForm_source}>
                    <Form.Item
                        label={<Locale stringKey="REVIEW__FORM__FIELD_SOURCE" />}
                        name={ReviewFormFieldNamesEnum.source}
                        required
                        rules={[requiredFieldRule]}
                    >
                        <Select<number>
                            disabled={isSourcesInProgress}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                            loading={isSourcesInProgress}
                            onChange={handleSourceChange}
                        >
                            {sources?.map((source) => (
                                <Select.Option key={source.id} value={source.id}>
                                    {source.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {selectedSource && (
                        <img
                            alt=""
                            className={styles.ReviewForm_sourceLogo}
                            src={selectedSource.logo}
                            title={selectedSource.name}
                        />
                    )}
                </div>
                <Form.Item
                    label={<Locale stringKey="REVIEW__FORM__FIELD_BRAND" />}
                    name={ReviewFormFieldNamesEnum.brand}
                    required
                    rules={[requiredFieldRule]}
                >
                    <Select<number>
                        disabled={isBrandsInProgress}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                        loading={isBrandsInProgress}
                        onChange={setSelectedBrand}
                        placeholder={<Locale stringKey="REVIEW__FORM__FIELD_BRAND__PLACEHOLDER" />}
                    >
                        {brands?.map((brand) => (
                            <Select.Option key={brand.id} value={brand.id}>
                                {brand.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<Locale stringKey="REVIEW__FORM__FIELD_COMPANY" />}
                    name={ReviewFormFieldNamesEnum.company}
                >
                    <CompanySelect brandId={selectedBrand} initialCompany={initialCompany} />
                </Form.Item>
                <Form.Item
                    label={<Locale stringKey="REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__REVIEW_LINK" />}
                    name={ReviewFormFieldNamesEnum.url}
                    rules={[validUrlRule]}
                >
                    <Input placeholder="http://www.example.com" />
                </Form.Item>
                <Form.Item
                    initialValue={dayjs()}
                    label={<Locale stringKey="REVIEW__FORM__FIELD_CREATED_DATE" />}
                    name={ReviewFormFieldNamesEnum.createdAt}
                >
                    <DatePicker
                        allowClear={false}
                        className={styles.ReviewForm_datePicker}
                        disabledDate={getDisabledDate}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                    />
                </Form.Item>
            </FormFieldset>

            <FormFieldset>
                <Form.Item
                    label={<Locale stringKey="REVIEW__FORM__FIELD_AUTHOR" />}
                    name={ReviewFormFieldNamesEnum.author}
                >
                    <Input placeholder={getLocalizedString('REVIEW__FORM__FIELD_AUTHOR__PLACEHOLDER')} />
                </Form.Item>
                <Form.Item
                    label={<Locale stringKey="REVIEW__FORM__FIELD_PHONE" />}
                    name={ReviewFormFieldNamesEnum.phone}
                    rules={[getPhoneNumberRule(false)]}
                >
                    <Input placeholder={getLocalizedString('REVIEW__FORM__FIELD_PHONE__PLACEHOLDER')} />
                </Form.Item>
                <Form.Item
                    label={<Locale stringKey="REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__EMAIL__TITLE" />}
                    name={ReviewFormFieldNamesEnum.email}
                    rules={[emailFieldRule]}
                >
                    <Input
                        placeholder={getLocalizedString(
                            'REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__EMAIL__PLACEHOLDER'
                        )}
                    />
                </Form.Item>
            </FormFieldset>

            <FormFieldset>
                <Form.Item
                    className={styles.ReviewForm_rating}
                    initialValue={0}
                    label={<Locale stringKey="REVIEW__FORM__FIELD_RATING" />}
                    name={ReviewFormFieldNamesEnum.rating}
                >
                    <Select getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}>
                        {selectRatingContentStarsWithNoRating}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={<Locale stringKey="REVIEW__FORM__FIELD_CONTENT" />}
                    name={ReviewFormFieldNamesEnum.content}
                >
                    <Input.TextArea placeholder={getLocalizedString('REVIEW__FORM__FIELD_CONTENT__PLACEHOLDER')} />
                </Form.Item>
            </FormFieldset>

            {serverError && <AlertFallback message={serverError?.non_field_errors} />}
        </Form>
    );
}
