import {Meta} from '../../../component/meta/meta';
import {Page} from '../../../layout/page/page';
import {Locale} from '../../../provider/locale/locale';

import * as styles from './react-catch-error.scss';

export function ReactCatchError(): JSX.Element {
    return (
        <Page useHardReload>
            <Meta title="(╯ ° □ °) ╯ (┻━┻)" />

            <div className={styles.react_catch_error}>
                <div className={styles.react_catch_error__smile}>¯\_(ツ)_/¯</div>

                <p className={styles.react_catch_error__text}>
                    <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />
                </p>
            </div>
        </Page>
    );
}
