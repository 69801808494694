import {Rule} from 'antd/lib/form';

import {LocaleContextType} from '../../../../../../../provider/locale/locale-context-type';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {FILE_SIZE_BYTES} from '../../../../../../../service/company/company-const';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {CompanyImageFormType} from '../../../../../../../service/company-v2/types/company-logo-type';
import {Form} from '../../../../../../../typings/antd';
import {CoverFormItem} from '../cover-form-item/cover-form-item';
import {LogoFormItem} from '../logo-form-item/logo-form-item';

import * as styles from './logo-cover-form-item.scss';

function getMaxImageSizeRule(getLocalizedString: LocaleContextType['getLocalizedString']): Rule {
    return {
        validator: (_rule: unknown, value?: CompanyImageFormType): Promise<void> => {
            if (value?.size && value.size > FILE_SIZE_BYTES) {
                return Promise.reject(new Error(getLocalizedString('COMPANY_FORM__GALLERY__ERROR__MAX_SIZE')));
            }

            return Promise.resolve();
        },
    };
}

export function LogoCoverFormItem(): JSX.Element {
    const {getLocalizedString} = useLocale();

    return (
        <div className={styles.logo_widget__list}>
            <Form.Item<CompanyFormType>
                className={styles.logo_widget__logo_wrapper}
                name={CompanyKeyEnum.Logo}
                rules={[getMaxImageSizeRule(getLocalizedString)]}
            >
                <LogoFormItem className={styles.logo_widget__logo} />
            </Form.Item>

            <Form.Item<CompanyFormType> name={CompanyKeyEnum.Cover} rules={[getMaxImageSizeRule(getLocalizedString)]}>
                <CoverFormItem className={styles.logo_widget__cover} />
            </Form.Item>
        </div>
    );
}
