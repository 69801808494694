import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {isNumber, isString} from 'lodash';

import {Text} from '../../../../../../component/text/text';
import {AddButton} from '../../../../../../layout/add-button/add-button';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';

import * as styles from './emails-form-item.scss';

type PropsType = {
    name: string;
    hasInitialEmails: boolean;
    formInputTitle: LangKeyType;
    licenseCount?: number;
    isRequired: boolean;
};

export function EmailsFormItem(props: PropsType): JSX.Element {
    const {name, formInputTitle, hasInitialEmails, licenseCount, isRequired} = props;
    const {emailFieldRule, requiredFieldRule, includesOnList} = useFormRules();

    // eslint-disable-next-line no-undefined
    const initialValue = hasInitialEmails ? undefined : [''];

    const {getLocalizedString} = useLocale();

    return (
        <Form.List initialValue={initialValue} name={name}>
            {(emails, {add, remove}, {errors}) => (
                <>
                    <div className={styles.EmailsFormItem}>
                        {emails.map(({key, name: fieldName}, index) => {
                            return (
                                <div
                                    className={classNames(styles.EmailsFormItem_userEmail, {
                                        [styles.EmailsFormItem_formItem_secondEmail]: index !== 0,
                                    })}
                                    key={key}
                                >
                                    <Form.Item
                                        className={styles.EmailsFormItem_formItem}
                                        label={index === 0 ? <Text stringKey={formInputTitle} /> : null}
                                        name={fieldName}
                                        required={isRequired}
                                        rules={[
                                            emailFieldRule,
                                            requiredFieldRule,
                                            includesOnList(
                                                errors.filter(isString),
                                                getLocalizedString(
                                                    'USERS__FORM__EMAILS_FORM__ERROR__USER_ALREADY_EXIST'
                                                )
                                            ),
                                        ]}
                                    >
                                        <Input
                                            placeholder={getLocalizedString(
                                                'COMPANY_FORM__CONTACT_INFO__EMAIL__PLACEHOLDER'
                                            )}
                                            size="large"
                                        />
                                    </Form.Item>
                                    {index > 0 && (
                                        <Button onClick={() => remove(fieldName)} size="small" type="text">
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.EmailsFormItem_buttonBlock}>
                        <AddButton
                            className={styles.EmailsFormItem_addEmail}
                            isDisabled={isNumber(licenseCount) ? emails.length >= licenseCount : false}
                            onClick={() => add('')}
                            size="small"
                        >
                            <Locale stringKey="USERS__FORM__EMAILS_FORM__ADD_BUTTON" />
                        </AddButton>
                        {isNumber(licenseCount) && (
                            <Text
                                secondary
                                stringKey="USERS__FORM__EMAILS_FORM__LICENSE_COUNT"
                                valueMap={{count: licenseCount}}
                            />
                        )}
                    </div>
                </>
            )}
        </Form.List>
    );
}
