import {Form, FormInstance} from 'antd';

import {Text} from '../../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostAiSuggestHookType, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {useRefreshId} from '../../../../../../../../util/hook';
import {PostFormItemPropsType} from '../../../../post-form-type';

import {PostAiSuggest} from './ai-suggest/post-ai-suggest';
import {PostTextField} from './post-text-field';
import {MIN_POST_TEXT_LENGTH} from './post-text-helper';
import * as styles from './post-text-item.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    suggestSettings: PostAiSuggestHookType;
    maxLength: number;
    fieldName: Array<string | number>;
} & PostFormItemPropsType;

export function PostTextItem(props: PropsType): JSX.Element {
    const {formInstance, suggestSettings, maxLength, fieldName, disabled} = props;

    const {minLengthFieldRule, maxLengthFieldRule, requiredFieldRule} = useFormRules();
    const {refresh: refreshFormItem, refreshId: formItemKey} = useRefreshId();

    return (
        <div>
            <Form.Item
                className={styles.PostTextItem}
                initialValue=""
                key={formItemKey}
                label={
                    <Text block className={styles.PostTextItem_label}>
                        <Locale stringKey="POSTS_FORM__CONTENT__LABEL" />
                        &nbsp;
                        <AdditionalInfo title={<Locale stringKey="POSTS_FORM__CONTENT__LABEL" />}>
                            <Locale stringKey="POSTS_FORM__CONTENT__TOOLTIP" />
                        </AdditionalInfo>
                    </Text>
                }
                name={fieldName}
                required
                rules={
                    !disabled
                        ? [requiredFieldRule, minLengthFieldRule(MIN_POST_TEXT_LENGTH), maxLengthFieldRule(maxLength)]
                        : []
                }
            >
                <PostTextField disabled={disabled} maxLength={maxLength} />
            </Form.Item>

            {!disabled && (
                <PostAiSuggest
                    onSelectSuggest={(text) => {
                        formInstance.setFieldValue(fieldName, text);
                        refreshFormItem();
                    }}
                    suggestSettings={suggestSettings}
                />
            )}
        </div>
    );
}
