import {Form, FormInstance} from 'antd';
import {useCallback, useMemo} from 'react';

import {
    AttributeGetConfigType,
    AttributeTypeEnum,
} from '../../../page/main/company-form/page-container/widgets/attributes/attributes-type';
import {useApiHooksWithFetcher} from '../../../service/api-hook/api-hook';
import {UseHookType} from '../../../service/api-hook/api-hook-type';
import {CompanyKeyEnum} from '../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../service/company-v2/company-type';
import {ProvidersIdsEnum} from '../../../util/type';
import {ShortLocaleNameEnum} from '../../locale/locale-context-type';
import {useLocale} from '../../locale/locale-hook';

import {fetchDoubleGisAttributes, fetchGoogleAttributes, fetchYandexAttributes} from './attributes-api';
import {DoubleGisAttributeType, GoogleAttributeType} from './attributes-type';

export function useGoogleAttributes(): UseHookType<Array<GoogleAttributeType>> {
    const country = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'country'>([
        CompanyKeyEnum.Address,
        'country',
    ]);
    const catalogCategories = Form.useWatch<CompanyKeyEnum.CatalogCategories, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.CatalogCategories
    );

    const categoryId = useMemo(
        () =>
            catalogCategories?.find(({catalogId}) => catalogId === ProvidersIdsEnum.google)?.categories?.[0]
                ?.originId ?? null,
        [catalogCategories]
    );

    const fetcher = useCallback(() => {
        if (!categoryId || !country) {
            return null;
        }

        return async () => fetchGoogleAttributes(categoryId, country.countryCode);
    }, [categoryId, country]);

    return useApiHooksWithFetcher({fetcher});
}

export function useYandexAttributes(): UseHookType<Array<AttributeGetConfigType>> {
    const {shortLocaleName} = useLocale();
    const catalogCategories = Form.useWatch<CompanyKeyEnum.CatalogCategories, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.CatalogCategories
    );

    const categoriesIds = useMemo(
        () =>
            catalogCategories
                ?.find(({catalogId}) => catalogId === ProvidersIdsEnum.yandex)
                ?.categories?.map(({originId}) => originId) ?? null,
        [catalogCategories]
    );

    const fetcher = useCallback(() => {
        if (!categoriesIds || categoriesIds.length === 0) {
            return null;
        }

        if (shortLocaleName !== ShortLocaleNameEnum.ru) {
            return async () => [];
        }

        return async () => fetchYandexAttributes(categoriesIds);
    }, [categoriesIds, shortLocaleName]);

    const mapper = useCallback(
        (data: Array<AttributeGetConfigType>) =>
            data.filter(({featureType}) => Object.values(AttributeTypeEnum).includes(featureType)),
        []
    );

    return useApiHooksWithFetcher({fetcher, mapper});
}

export function useDoubleGisAttributes(): UseHookType<Array<DoubleGisAttributeType>> {
    const {shortLocaleName} = useLocale();
    const catalogCategories = Form.useWatch<CompanyKeyEnum.CatalogCategories, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.CatalogCategories
    );

    const categoriesIds = useMemo(
        () =>
            catalogCategories
                ?.find(({catalogId}) => catalogId === ProvidersIdsEnum.doubleGis)
                ?.categories?.map(({originId}) => originId) ?? null,
        [catalogCategories]
    );

    const fetcher = useCallback(() => {
        if (!categoriesIds || categoriesIds.length === 0) {
            return null;
        }

        if (shortLocaleName !== ShortLocaleNameEnum.ru) {
            return async () => [];
        }

        return async () => fetchDoubleGisAttributes(categoriesIds);
    }, [categoriesIds, shortLocaleName]);

    return useApiHooksWithFetcher({fetcher});
}
