import {ReviewCatalogType} from '../../../../service/reviews/reviews-catalogs';
import {FetchReviewsInfoDataType} from '../../../../service/reviews/reviews-info';
import {ReviewsScrapyCatalogsType} from '../../../../service/reviews/reviews-scrapy-catalogs';
import {
    FetchReviewsResultType,
    ReviewDataChildType,
    ReviewRatingTypeEnum,
    ReviewSortingTypeEnum,
} from '../../../../service/reviews/reviews-type';
import {MainFilterQueryKeyNameEnum} from '../../../../shared-search-parameters';
import {CursorPaginationHookType} from '../../../../util/cursor-pagination/cursor-pagination-type';
import {UseUrlHookOptionsType} from '../../../../util/url-hook/url-hook-type';
import {
    ReviewsFilterDataAsObjectUrlType,
    ReviewsFilterDataType,
} from '../review-list-page-content/reviews-filter/reviews-filter-type';
import {SuggestionMapType} from '../reviews-type';

export enum ReviewsStateUrlQueryNameEnum {
    ratingType = 'rating-type',
    ids = 'ids',
    companyIds = 'company_ids',
    filterId = 'filter-id',
    sorting = 'ordering',
    rate = 'rate',
}

export type ReviewsStateType = Readonly<{
    [ReviewsStateUrlQueryNameEnum.ratingType]: ReviewRatingTypeEnum;
    [ReviewsStateUrlQueryNameEnum.ids]: string;
    [ReviewsStateUrlQueryNameEnum.companyIds]: string;
    [ReviewsStateUrlQueryNameEnum.sorting]: ReviewSortingTypeEnum;
    filterState: ReviewsFilterDataType;
    filterId: string;
}>;

export type ReviewsStateContextType = {
    reviewsState: ReviewsStateType;
    setReviewsStateUrlQuery: (
        queryMap: Partial<
            | ReviewsFilterDataAsObjectUrlType
            | Record<MainFilterQueryKeyNameEnum, string>
            | Record<ReviewsStateUrlQueryNameEnum, string>
        >,
        options?: UseUrlHookOptionsType
    ) => void;
    reviewsCatalogList: Array<ReviewCatalogType> | null;
    reviewsCatalogListIsInProgress: boolean;
    reviewsScrapyCatalogsList?: ReviewsScrapyCatalogsType | null;
    refreshReview: (reviewId: number) => Promise<unknown>;
    refreshReviewWithAnswer: (review: ReviewDataChildType, reviewId: number) => void;
    refreshReviews: () => Promise<unknown>;
    reviews: FetchReviewsResultType | null;
    isReviewsInProgress: boolean;
    cursorPagination: CursorPaginationHookType;
    refreshCatalogs: () => void;
    suggestionMap: SuggestionMapType;
    refreshSuggestions: () => Promise<unknown>;
    reviewsInfo: FetchReviewsInfoDataType | null;
    refreshReviewsInfo: () => void;
    isNewLayout: boolean;
    handleNewReviewsLayout: (value: boolean) => void;
    reviewIds: Array<number>;
    googleMapsReviewIds: Array<number>;
};
