import {uniq} from 'lodash';

import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {UniqueLicenseDataType} from '../../../../../service/tariffs/tariffs-licenses';
import {getRandomString} from '../../../../../util/string';
import {LicensesBlockEnum} from '../licenses-block-type';

import {AdditionalLicenseKeyEnum, keyColumnName, ProductTypeEnum} from './license-const';

export type LicenseDateTableType = {
    [AdditionalLicenseKeyEnum.Dates]: [string, string];
    [AdditionalLicenseKeyEnum.ProductType]?: Array<string>;
    [AdditionalLicenseKeyEnum.Variant]?: ProductTypeEnum | string;
    [AdditionalLicenseKeyEnum.Limit]?: number;
    [AdditionalLicenseKeyEnum.SourcesLimit]?: number;
    [AdditionalLicenseKeyEnum.UsersLimit]?: number;
    [AdditionalLicenseKeyEnum.ConversationsLimit]?: number;
    [AdditionalLicenseKeyEnum.Requests]?: number;
    [AdditionalLicenseKeyEnum.ProviderCodes]?: Array<string>;
};

export function uniqueLicenseKeys(keysList: UniqueLicenseDataType): {
    keysArray: Array<AdditionalLicenseKeyEnum>;
    transformedList: Array<LicenseDateTableType>;
} {
    const transformedArray = keysList.map((item) => {
        const {startAt, finishAt, ...restItem} = item;

        function variantData() {
            if (restItem.additionalLicenseKeys.type) {
                return [restItem.additionalLicenseKeys.type];
            }

            return restItem.types;
        }

        const variantKey = variantData().length > 0 ? {variant: variantData().join(', ') as ProductTypeEnum} : {};
        const providerCodesKey = restItem.providerCodes.length > 0 ? {providerCodes: restItem.providerCodes} : {};

        return {
            dates: [startAt, finishAt] as [string, string],
            key: getRandomString(),
            ...variantKey,
            ...providerCodesKey,
            ...restItem.additionalLicenseKeys,
        };
    });

    const keysArray = transformedArray.reduce((keys: Array<AdditionalLicenseKeyEnum>, item) => {
        const itemKeys = Object.keys(item).filter(
            (columnName) => columnName !== keyColumnName
        ) as Array<AdditionalLicenseKeyEnum>;

        return [...keys, ...itemKeys];
    }, []);

    return {keysArray: uniq(keysArray), transformedList: transformedArray};
}

export function compareTypes(allTypes: Array<string>, licenseTypes: Array<string>): Array<string> {
    return allTypes.filter((element) => !licenseTypes.includes(element));
}

export function expiredTagLangKey(type: LicensesBlockEnum): LangKeyType {
    if (type === LicensesBlockEnum.TestActive) {
        return 'TARIFFS__LICENSE_BLOCK__TAG_TITLE__TEST';
    }

    return 'TARIFFS__LICENSE_BLOCK__TAG_TITLE__NOT_ACTIVE';
}
