import {z as r} from 'zod';

import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const reviewAnalyticsEmployeesSchema = r.object({
    userId: r.number(),
    userName: r.string().nullable(),
    userEmail: r.string().nullable(),
    total: r.number(),
    abuses: r.number(),
    repliedPercent: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
});

export type ReviewAnalyticsEmployeeType = r.infer<typeof reviewAnalyticsEmployeesSchema>;

export const reviewAnalyticsEmployeesResponseSchema = r.object({
    count: r.number(),
    page: r.number(),
    pages: r.number(),
    next: r.string().optional(),
    previous: r.string().optional(),
    pageSize: r.number(),
    results: r.array(reviewAnalyticsEmployeesSchema),
});

export type ReviewsAnalysisEmployeesQueryType = {
    brandIds?: Array<number>;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};
