import {useCallback, useEffect, useState} from 'react';

import {rootApiUrl} from '../api/api-const';
import {useApiHooks} from '../api-hook/api-hook';

import {loadFacebookAccountsApi, loadGoogleAccountsApi, loadVkAccountsApi, removeVkAccountApi} from './accounts-api';
import {
    AccountsLinkingHookType,
    FacebookAccountsResponseType,
    FacebookAccountType,
    GoogleAccountsResponseType,
    GoogleAccountType,
    VkAccountType,
} from './accounts-type';

export function useVkAccounts(): AccountsLinkingHookType<VkAccountType> {
    const {
        result,
        setResult,
        processError,
        setProcessError,
        setIsInProgress,
        isInProgress: vkAccountIsInProgress,
        isMounted,
    } = useApiHooks<Array<VkAccountType>>();

    const loadVkAccounts = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        loadVkAccountsApi()
            .then((response: Array<VkAccountType>) => {
                if (!isMounted.current) {
                    return;
                }

                setResult(response);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });
    }, [isMounted, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadVkAccounts();
    }, [loadVkAccounts]);

    return {
        processError,
        reset: loadVkAccounts,
        result,
        removeAccount: removeVkAccountApi,
        isInProgress: vkAccountIsInProgress,
        linkingSrc: rootApiUrl + '/v1/vk/auth/',
    };
}

export function useGoogleAccounts(
    options: {
        brandId?: number;
        skip?: boolean;
    } = {}
): AccountsLinkingHookType<GoogleAccountType> {
    const {result, setResult, processError, setProcessError, setIsInProgress, isInProgress, isMounted} =
        useApiHooks<Array<GoogleAccountType>>();

    const [linkingSrc, setLinkingSrc] = useState<string>('');

    const loadGoogleAccounts = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        loadGoogleAccountsApi({brandId: options.brandId})
            .then((response: GoogleAccountsResponseType) => {
                if (!isMounted.current) {
                    return;
                }

                setLinkingSrc(response.catalog.oauthLink);
                setResult(response.results);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });
    }, [options.brandId, isMounted, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        if (!options.skip) {
            loadGoogleAccounts();
        }
    }, [loadGoogleAccounts, options.skip]);

    return {
        processError,
        reset: loadGoogleAccounts,
        result,
        removeAccount: removeVkAccountApi,
        isInProgress,
        linkingSrc,
    };
}

export function useFacebookAccounts(): AccountsLinkingHookType<FacebookAccountType> {
    const {result, setResult, processError, setProcessError, setIsInProgress, isInProgress, isMounted} =
        useApiHooks<Array<FacebookAccountType>>();

    const [linkingSrc, setLinkingSrc] = useState<string>('');

    const loadFacebookAccounts = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        loadFacebookAccountsApi()
            .then((response: FacebookAccountsResponseType) => {
                if (!isMounted.current) {
                    return;
                }

                setLinkingSrc(response.catalog.oauthLink);
                setResult(response.results);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });
    }, [isMounted, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadFacebookAccounts();
    }, [loadFacebookAccounts]);

    return {
        processError,
        reset: loadFacebookAccounts,
        result,
        removeAccount: removeVkAccountApi,
        isInProgress,
        linkingSrc,
    };
}
