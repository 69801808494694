import {faClock} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tag} from 'antd';

import {Locale} from '../../../../../provider/locale/localization';
import {UserStatusEnum} from '../../../../../service/user-management/user-management-type';
import {classNames} from '../../../../../util/css';

import * as styles from './users-table.scss';

export const USER_STATUS_TO_LOCALE = {
    [UserStatusEnum.Active]: <Locale stringKey="USERS__TABLE__STATUS__ACTIVE" />,
    [UserStatusEnum.Pending]: <Locale stringKey="USERS__TABLE__STATUS__PENDING" />,
    [UserStatusEnum.Blocked]: <Locale stringKey="USERS__TABLE__STATUS__BLOCKED" />,
};

export const USER_STATUS_TO_TAG = {
    [UserStatusEnum.Active]: (
        <Tag className={classNames(styles.UsersTable_status, styles.Status__blue)}>
            {USER_STATUS_TO_LOCALE[UserStatusEnum.Active]}
        </Tag>
    ),
    [UserStatusEnum.Pending]: (
        <Tag className={styles.UsersTable_status}>
            {USER_STATUS_TO_LOCALE[UserStatusEnum.Pending]}
            <FontAwesomeIcon icon={faClock} />
        </Tag>
    ),
    [UserStatusEnum.Blocked]: (
        <Tag className={styles.UsersTable_status}>{USER_STATUS_TO_LOCALE[UserStatusEnum.Blocked]}</Tag>
    ),
};
