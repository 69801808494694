import {TableColumnGroupType, TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {
    LocalPackCatalogsEnum,
    LocalPackDataTableResultFullValueType,
    LocalPackDataTableResultPositionType,
    LocalPackSearchServiceType,
} from '../../../../service/local-pack/local-pack-type';
import {
    LOCALPACK_CATALOG_LOCALIZATION_MAP,
    LOCALPACK_SERVICE_LOCALIZATION_MAP,
    localPackNullPositionValue,
} from '../local-pack-const';

export function getLocalPackAllServiceResult(
    searchServices: Array<LocalPackSearchServiceType>,
    values: Array<LocalPackDataTableResultFullValueType>
): LocalPackDataTableResultPositionType {
    const allActiveValues: Array<LocalPackDataTableResultFullValueType | undefined> = searchServices
        .filter((service) => service.isActive)
        .map((service) => {
            return values.find((value) => value.provider === service.provider && value.service === service.service);
        });

    const currentValues: Array<number | undefined | null> = allActiveValues
        .filter((value) => value?.currentPosition)
        .map((value) => {
            return value?.currentPosition || localPackNullPositionValue;
        });
    const previousValues: Array<number | undefined | null> = allActiveValues
        .filter((value) => value?.hasPrev)
        .map((value) => {
            return value?.previousPosition || localPackNullPositionValue;
        });

    const sumCurrentValue = currentValues.reduce((result, currentValue) => {
        if (!currentValue) {
            return result;
        }

        return (result || 0) + currentValue;
    }, 0);

    const averageCurrentValue =
        sumCurrentValue && currentValues.length > 0 ? sumCurrentValue / currentValues.length : null;

    const sumPreviousValue = previousValues.reduce((result, previousValue) => {
        if (!previousValue) {
            return result;
        }

        return (result || 0) + previousValue;
    }, 0);

    const averagePreviousValue =
        sumPreviousValue && previousValues.length > 0 ? sumPreviousValue / previousValues.length : null;

    return {
        currentPosition: averageCurrentValue,
        previousPosition: averagePreviousValue,
        hasPrev: previousValues.length > 0,
    };
}

export function getLocalPackResultsColumnList(
    searchServices: Array<LocalPackSearchServiceType>
): TableColumnsType<Record<string, unknown>> {
    const localPackResultsColumns: TableColumnsType<Record<string, unknown>> = [
        {
            title: <Locale stringKey="LOCAL_PACK__RESULT_TABLE__DATE" />,
            dataIndex: 'date',
        },
        {
            title: <Locale stringKey="LOCAL_PACK__RESULT_TABLE__COMPANY_ADDRESS" />,
            dataIndex: 'companyAddress',
        },
    ];

    const catalogColumns: Array<TableColumnGroupType<Record<string, unknown>>> = [];

    Object.values(LocalPackCatalogsEnum).forEach((catalogKey: LocalPackCatalogsEnum) => {
        const catalogServices = searchServices.filter((value) => value.isActive && value.provider === catalogKey);

        if (catalogServices.length > 0) {
            catalogColumns.push({
                title: <Locale stringKey={LOCALPACK_CATALOG_LOCALIZATION_MAP[catalogKey]} />,
                children: catalogServices.map((value) => {
                    return {
                        title: <Locale stringKey={LOCALPACK_SERVICE_LOCALIZATION_MAP[value.service]} />,
                        dataIndex: `${value.provider}_${value.service}`,
                        key: `${value.provider}_${value.service}`,
                        align: 'center',
                    };
                }),
            });
        }
    });

    localPackResultsColumns.push(...catalogColumns);

    if (catalogColumns.reduce((accumulator, column) => accumulator + column.children.length, 0) > 1) {
        localPackResultsColumns.push({
            title: <Locale stringKey="LOCAL_PACK__RESULT_TABLE__ALL_SERVICES" />,
            dataIndex: 'allServices',
            align: 'center',
        });
    }

    return localPackResultsColumns;
}
