import {useEffect} from 'react';

import {useRefreshId} from '../../../util/hook';
import {PaginatedResponseType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType, UseRefreshApiHookType} from '../../api-hook/api-hook-type';

import {
    createBrandLink,
    createCompanyLink,
    deleteBrandLink,
    deleteCompanyLink,
    fetchBrandLinkList,
    fetchCompanyLinkList,
    updateBrandLink,
    updateCompanyLink,
} from './source-links-api';
import {
    BrandLinkType,
    CreateSourceLinkType,
    DeleteSourceLinkType,
    SourceLinkListRequestType,
    SourceLinkType,
    SourceLinkTypeEnum,
    UpdateSourceLinkType,
} from './source-links-type';

export function useSourceLinkList(
    linkType: SourceLinkTypeEnum,
    options: SourceLinkListRequestType
): UseHookType<PaginatedResponseType<SourceLinkType>> & UseRefreshApiHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<BrandLinkType>>();
    const {refreshId, refresh} = useRefreshId();

    const fetchMethod = linkType === SourceLinkTypeEnum.Brand ? fetchBrandLinkList : fetchCompanyLinkList;
    const {catalogId, filterKey, status, cursor, pageSize} = options;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchMethod({cursor, catalogId, filterKey, status, pageSize})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        fetchMethod,
        setResult,
        setIsInProgress,
        setProcessError,
        catalogId,
        filterKey,
        status,
        pageSize,
        cursor,
        refreshId,
    ]);

    return {isInProgress, processError, result, reset, refreshId, refresh};
}

export function useCreateSourceLink(): UseHookType<SourceLinkType> & {
    create: (payload: CreateSourceLinkType) => Promise<SourceLinkType>;
} {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<SourceLinkType>();

    function create(payload: CreateSourceLinkType): Promise<SourceLinkType> {
        setIsInProgress(true);
        setProcessError(null);

        const {link, catalogId, brandId, companyId} = payload;
        const createPromise = companyId
            ? createCompanyLink({link, catalogId, companyId})
            : createBrandLink({link, catalogId, brandId});

        createPromise
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);

        return createPromise;
    }

    return {isInProgress, processError, result, reset, create};
}

export function useUpdateSourceLink(): UseHookType<SourceLinkType> & {
    update: (id: number, payload: UpdateSourceLinkType) => Promise<SourceLinkType>;
} {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<SourceLinkType>();

    function update(id: number, payload: UpdateSourceLinkType): Promise<SourceLinkType> {
        setIsInProgress(true);
        setProcessError(null);

        const {link, deleteReviews, companyId} = payload;
        const createPromise = companyId
            ? updateCompanyLink(id, {link, deleteReviews})
            : updateBrandLink(id, {link, deleteReviews});

        createPromise
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);

        return createPromise;
    }

    return {isInProgress, processError, result, reset, update};
}

export function useDeleteSourceLink(): UseHookType<null> & {
    deleteLink: (id: number, payload: DeleteSourceLinkType) => Promise<void>;
} {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, reset} = useApiHooks<null>();

    function deleteLink(id: number, payload: DeleteSourceLinkType): Promise<void> {
        setIsInProgress(true);
        setProcessError(null);

        const {deleteReviews, companyId} = payload;
        const createPromise = companyId ? deleteCompanyLink(id, {deleteReviews}) : deleteBrandLink(id, {deleteReviews});

        createPromise.finally(() => setIsInProgress(false)).catch(setProcessError);

        return createPromise;
    }

    return {isInProgress, processError, result, reset, deleteLink};
}
