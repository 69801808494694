import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {ProductGroupTypeEnum} from '../../../../../service/products/products-type';
import {getEnumValue} from '../../../../../util/enum';
import {ProductGroupForm} from '../../components/product-group-form/product-group-form';

import {createProductGroupHeaderMap} from './products-group-page-const';

export function CreateProductGroupPage(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {type: stringType} = useParams<ExtractRouteParams<typeof appRoute.createProductGroup.path, string>>();

    const type = getEnumValue<ProductGroupTypeEnum>(ProductGroupTypeEnum, stringType);

    if (!type || type === ProductGroupTypeEnum.Manual) {
        throw new Error(getLocalizedString('PRODUCTS__CREATE_GROUP__UNSUPPORTED_MODE'));
    }

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__PRODUCTS')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.products.path,
                        titleLangKey: 'CATEGORY_NAME__PRODUCTS',
                    },
                    {
                        path: appRoute.createProductGroup.path,
                        titleLangKey: createProductGroupHeaderMap[type],
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey={createProductGroupHeaderMap[type]} />
            </PageHeader>

            <ProductGroupForm productGroupSourceType={type} />
        </Page>
    );
}
