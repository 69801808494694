import {Divider, Timeline} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {
    TimelineDataType,
    TimelineSubActionsDataType,
    TimelineYandexActionTypeEnum,
    TimelineYandexEventTypeEnum,
} from '../../../../../../service/timeline-activity/timeline-activity-type';

import {
    timelineYandexActionLocalizationMap,
    timelineYandexEventLocalizationMap,
} from './timeline-card-yandex-content-const';
import * as styles from './timeline-card-yandex-content.scss';

type PropsType = {
    timelineData: TimelineDataType;
    showActions: boolean;
};

export function TimelineCardYandexContent(props: PropsType): JSX.Element {
    const {timelineData, showActions} = props;

    return (
        <>
            <p className={styles.timeline__item__comment__content__header}>
                {timelineData.extra.type &&
                Object.values(TimelineYandexEventTypeEnum).includes(timelineData.extra.type) ? (
                    <Locale stringKey={timelineYandexEventLocalizationMap[timelineData.extra.type]} />
                ) : (
                    <Locale stringKey="DASHBOARD_PAGE__TIMELINE__EVENT__UNKNOWN" />
                )}
            </p>
            {timelineData.extra.value && (
                <p className={styles.timeline__item__comment__content__company}>{timelineData.extra.value}</p>
            )}
            {showActions && (
                <>
                    <Divider className={styles.timeline__item__comment__content__divider} type="horizontal" />
                    <Timeline>
                        {timelineData.subActions.map((subAction: TimelineSubActionsDataType): JSX.Element => {
                            return (
                                <Timeline.Item
                                    className={styles.timeline__item__comment__content__actions}
                                    key={subAction.action}
                                >
                                    <p className={styles.timeline__item__comment__content__actions__text}>
                                        <Locale stringKey="DASHBOARD_PAGE__TIMELINE__ACTION" />
                                    </p>
                                    <p className={styles.timeline__item__comment__content__actions__value}>
                                        {Object.values(TimelineYandexActionTypeEnum).includes(subAction.action) ? (
                                            <Locale stringKey={timelineYandexActionLocalizationMap[subAction.action]} />
                                        ) : (
                                            <Locale stringKey="DASHBOARD_PAGE__TIMELINE__ACTION__UNKNOWN" />
                                        )}
                                    </p>
                                </Timeline.Item>
                            );
                        })}
                    </Timeline>
                </>
            )}
        </>
    );
}
