import {ExtractRouteParams} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {postCompanySelectorCompanySchema} from '../../../../service/feature-companies/feature-companies-api-type';
import {CompaniesSelectorFeatureEnum} from '../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../service/feature-companies/feature-companies-hook';
import {PostSelectorCompanyType} from '../../../../service/feature-companies/feature-companies-type';
import {usePost} from '../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormModeEnum} from '../../../../service/posts/posts-types';
import {PostForm} from '../post-form/post-form';
import {getPostFormInitialValues} from '../post-form/posts-form-helper';

export function ViewPost(): JSX.Element {
    const {postsManagementViewPost, postsManagement} = appRoute;

    const {postId} = useParams<ExtractRouteParams<typeof postsManagementViewPost.path, string>>();

    const {data: post, isLoadingError, isLoading} = usePost(Number(postId));

    const {getLocalizedString} = useLocale();

    const companiesSelector = useCompaniesSelector<PostSelectorCompanyType>({
        feature: CompaniesSelectorFeatureEnum.Posts,
        resourceId: postId,
        companySchema: postCompanySelectorCompanySchema,
        selectorId: post?.[PostFormFieldsEnum.SelectorId],
    });

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__VIEW_POSTS')} />

            <BreadCrumbs
                list={[
                    {path: postsManagement.path, titleLangKey: 'CATEGORY_NAME__POSTS'},
                    {path: '', titleLangKey: 'CATEGORY_NAME__VIEW_POSTS'},
                ]}
            />

            <PageHeader>
                <Locale stringKey="POSTS__VIEW_POST__HEADER" />
            </PageHeader>

            {isLoadingError && <AlertFallback />}

            {isLoading && <Spinner position="absolute" />}

            {post && (
                <PostForm
                    companiesSelector={companiesSelector}
                    formMode={PostFormModeEnum.View}
                    initialValues={{
                        ...getPostFormInitialValues(post),
                        [PostFormFieldsEnum.Id]: Number(postId),
                    }}
                />
            )}
        </Page>
    );
}
