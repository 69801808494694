import {LicenseCardSmallType} from '../../../../service/local-pack/local-pack-type';
import {Text} from '../../../text/text';

import * as styles from './license-card-small.scss';

export function LicenseCardSmall(props: LicenseCardSmallType): JSX.Element {
    const {icon, title, description} = props;

    return (
        <div className={styles.LicenseCardSmall}>
            <div className={styles.LicenseCardSmall_icon}>{icon}</div>
            <div>
                <Text block bolder>
                    {title}
                </Text>
                <Text lighter small>
                    {description}
                </Text>
            </div>
        </div>
    );
}
