import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {App as AntApp, ConfigProvider} from 'antd';
import React, {PropsWithChildren} from 'react';

import {DomainConfigProvider} from '../../provider/domain-config/domain-config-context';
import {LocaleProvider} from '../../provider/locale/locale-context';

import {AppProviders} from './app-providers';
import {APP_THEME} from './app-theme';
import {VitePreloadErrorHandler} from './vite-preload-error-handler';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'always',
        },
        mutations: {
            networkMode: 'always',
        },
    },
});

type PropsType = {
    DomainProvider?: React.FunctionComponent<PropsWithChildren<Record<string, unknown>>>;
};

export function App(props: PropsType): JSX.Element {
    const {DomainProvider = DomainConfigProvider} = props;

    return (
        <VitePreloadErrorHandler>
            <QueryClientProvider client={queryClient}>
                <LocaleProvider>
                    <ConfigProvider theme={APP_THEME}>
                        <AntApp>
                            <AppProviders DomainProvider={DomainProvider} />
                        </AntApp>
                    </ConfigProvider>
                </LocaleProvider>
            </QueryClientProvider>
        </VitePreloadErrorHandler>
    );
}
