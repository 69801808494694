import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';

import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../provider/locale/locale';

import * as styles from './report-name-hint.scss';

export function ReportNameHint(): JSX.Element {
    return (
        <span className={styles.ReportNameHint}>
            <Locale stringKey="REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__NAME_FIELD" />
            <Popover
                content={
                    <PopoverTextWrapper>
                        <Locale stringKey="REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__NAME_FIELD__TOOLTIP_TEXT" />
                    </PopoverTextWrapper>
                }
                placement="right"
                title={<Locale stringKey="REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__NAME_FIELD__TOOLTIP_TITLE" />}
                trigger="hover"
            >
                <FontAwesomeIcon className={styles.ReportNameHint_icon} icon={faInfoCircle} />
            </Popover>
        </span>
    );
}
