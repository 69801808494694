import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';

import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {CursorPaginationType} from '../../util/cursor-pagination/cursor-pagination-type';
import {PaginatedResponseType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';
import {CatalogNameType, CclStatusEnum} from '../ccl-statistic/ccl-statistic-type';
import {useDebounce} from '../debounce-hook/debounce-hook';

import {fetchCclData, fetchCompanyCatalogSyncStatistic} from './ccl-api';
import {getFetchCclUrl} from './ccl-api-helper';
import {CclCompanyStatsType, CclDataType, CclFetchParametersType} from './ccl-type';

// eslint-disable-next-line max-params,sonarjs/cognitive-complexity,complexity
export function useCclData(
    mainFilterKey: string,
    catalogType: CatalogNameType,
    statusType: CclStatusEnum | null,
    isNewCompanies: boolean,
    sourceIdList: Array<string>,
    companyIdList?: Array<string>,
    q?: string,
    accessTransferRequired?: boolean
): UseHookType<PaginatedResponseType<CclDataType>> &
    CursorPaginationType & {setCollectionUrl: Dispatch<SetStateAction<Array<string>>>} {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<CclDataType>>();

    const paginationDependencies = useMemo(() => {
        return {
            q,
            mainFilterKey,
            catalogType,
            statusType,
            sourceIdList,
            companyIdList,
            isNewCompanies,
            accessTransferRequired,
        };
    }, [
        catalogType,
        companyIdList,
        mainFilterKey,
        q,
        sourceIdList,
        statusType,
        isNewCompanies,
        accessTransferRequired,
    ]);

    const [collectionUrl, setCollectionUrl] = useState<Array<string>>([]);

    const pagination = useCursorPagination({
        dependencies: paginationDependencies,
        shouldSaveState: false,
    });

    const {pageSize, onDataLoaded, onDataLoadFailed, refresh, page, nextPage, previousPage} = pagination;

    const parameters: CclFetchParametersType = {
        catalogType,
        status: statusType || (accessTransferRequired && 'access_transfer_required') || null,
        catalogIds: sourceIdList.join(','),
        companyIds: companyIdList || [],
        newCompanies: isNewCompanies,
        filterKey: companyIdList?.length ? null : mainFilterKey,
        count: pageSize,
        page,
        q,
    };

    const lastPage = result?.results?.length && Math.ceil(result?.results?.length / pageSize);

    const shouldUseCpEndpoint =
        (companyIdList || []).length === 0 &&
        !statusType &&
        !accessTransferRequired &&
        sourceIdList.length === 0 &&
        catalogType === 'map';

    useEffect(() => {
        setCollectionUrl([]);
        refresh();
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pageSize,
        mainFilterKey,
        shouldUseCpEndpoint,
        catalogType,
        sourceIdList,
        companyIdList,
        isNewCompanies,
        accessTransferRequired,
        statusType,
    ]);
    const fetchCclDataCallback = useCallback(() => {
        if (previousPage < nextPage && !collectionUrl.includes(getFetchCclUrl(shouldUseCpEndpoint, parameters))) {
            fetchCclData(getFetchCclUrl(shouldUseCpEndpoint, parameters))
                .then((response) => {
                    onDataLoaded(response);
                    setCollectionUrl((previousUrl) => [
                        ...previousUrl,
                        getFetchCclUrl(shouldUseCpEndpoint, parameters),
                    ]);
                    setResult({
                        ...response,
                        results: result?.results ? [...result.results, ...response.results] : [...response.results],
                    });
                })
                .finally(() => setIsInProgress(false))
                .catch((error: Error) => {
                    console.error(error);
                    setProcessError(error);
                    onDataLoadFailed();
                });
        } else {
            setIsInProgress(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        page,
        setResult,
        onDataLoaded,
        setIsInProgress,
        collectionUrl,
        mainFilterKey,
        setProcessError,
        isNewCompanies,
        accessTransferRequired,
        onDataLoadFailed,
    ]);

    const beforeFetchCclData = useCallback(() => {
        if (previousPage < nextPage && !collectionUrl.includes(getFetchCclUrl(shouldUseCpEndpoint, parameters))) {
            setIsInProgress(true);
        }

        setProcessError(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsInProgress, previousPage, nextPage, collectionUrl, setProcessError]);

    useDebounce(beforeFetchCclData, fetchCclDataCallback);

    return {isInProgress, processError, result, reset, pagination, lastPage, setCollectionUrl};
}

export function useCclCompanyStats(companyId: number): Omit<UseHookType<Array<CclCompanyStatsType>>, 'reset'> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult} =
        useApiHooks<Array<CclCompanyStatsType>>();

    const loadCclCompanyStats = useCallback(() => {
        fetchCompanyCatalogSyncStatistic(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadCclCompanyStats();
    }, [loadCclCompanyStats]);

    return {isInProgress, processError, result};
}
