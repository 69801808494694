import {useEffect, useRef, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {classNames} from '../../../../../util/css';
import {useDomElementRect} from '../../../../../util/dom-hook/dom-hook';

import {minWidthToShowRatingSentimentLabel, ReviewSentimentRatingEnum} from './reviews-rating-sentiments-helper';
import * as styles from './reviews-rating-sentiments.scss';

type PropsType = {
    color: string;
    previousColor: string | null;
    percentage: number;
    rating: ReviewSentimentRatingEnum;
    reviewsCount: number;
    labelPosition: 'top' | 'bottom';
};

export function ReviewRatingBucket(props: PropsType): JSX.Element {
    const {color, percentage, previousColor, rating, labelPosition} = props;

    const bucketRef = useRef<HTMLDivElement | null>(null);
    const [showLabel, setShowLabel] = useState<boolean>(false);

    const domElementRect = useDomElementRect(bucketRef);

    useEffect(() => {
        setShowLabel(Boolean(domElementRect?.width && domElementRect?.width > minWidthToShowRatingSentimentLabel));
    }, [domElementRect]);

    return (
        <div
            className={styles.ReviewsRatingSentiments_distributionChartBucket}
            ref={bucketRef}
            style={{
                flexGrow: percentage,
                background: `linear-gradient(to right, ${previousColor ?? color}, ${color} ${
                    percentage > 10 ? '15%' : '50%'
                })`,
                position: 'relative',
            }}
        >
            {showLabel && (
                <div
                    className={classNames(styles.ReviewsRatingSentiments_distributionChartBucketLabel, {
                        [styles.ReviewsRatingSentiments_distributionChartBucketLabel__top]: labelPosition === 'top',
                    })}
                >
                    <div className={styles.ReviewsRatingSentiments_tooltipLabel}>
                        <Text block>{rating}</Text> ⭐
                    </div>
                    <Text bolder>{percentage}%</Text>
                </div>
            )}
        </div>
    );
}
