import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {userManagementUrl} from './user-management-const';

const userManagementRegistrationDataSchema = r.object({
    email: r.string(),
});

type UserManagementRegistrationDataType = r.infer<typeof userManagementRegistrationDataSchema>;

function getUserManagementRegistrationDataUrl(jwtToken: string) {
    return `${userManagementUrl}/register/?${objectToUrlParameters(serializeToURLParameters({jwtToken}))}`;
}

function fetchUserManagementRegistrationData(jwtToken: string): Promise<UserManagementRegistrationDataType> {
    return fetchAndDeserialize(getUserManagementRegistrationDataUrl(jwtToken), userManagementRegistrationDataSchema);
}

export function useUserManagementRegistrationUrlQuery(): {jwtToken: string | null} {
    const search = new URLSearchParams(location.search);

    const jwtToken = search.get('jwt_token');

    return {jwtToken};
}

export function useUserManagementRegistrationData(): UseQueryResult<UserManagementRegistrationDataType, unknown> & {
    enabled: boolean;
} {
    const {jwtToken} = useUserManagementRegistrationUrlQuery();

    const enabled = Boolean(jwtToken);

    const query = useQuery(
        [getUserManagementRegistrationDataUrl(jwtToken ?? '')],
        () => fetchUserManagementRegistrationData(jwtToken ?? ''),
        {enabled}
    );

    return {...query, enabled};
}
