import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo} from 'react';

import {useLocale} from '../../provider/locale/locale-hook';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {catchError} from '../../util/promise';
import {PROVIDER_ID_TO_ITS_NAME_MAP, ProvidersIdsEnum} from '../../util/type';
import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';
import {CclStatusEnum} from '../ccl-statistic/ccl-statistic-type';
import {FeaturesEnum} from '../user/user-type';

import {defaultCompaniesCountPerPage} from './companies-const';
import {
    bulkUpdateCompanies as fetchBulkUpdateCompanies,
    createCompany as fetchCreateCompany,
    getAllCompanies,
    getAvailableForUpdateCompaniesCount as fetchGetAvailableForUpdateCompaniesCount,
    getCompanies as getCompaniesFetch,
    getCompaniesUrl,
    getCompany as fetchGetCompany,
    updateCompany as fetchUpdateCompany,
    uploadCompanyMediaFile as fetchUploadCompanyMediaFile,
} from './company-api';
import {FILE_SIZE_BYTES} from './company-const';
import {
    AllCompanyType,
    AvailableForUpdateCompaniesCountType,
    BulkUpdateCompaniesFieldsErrorsType,
    BulkUpdateCompaniesFilterType,
    BulkUpdateCompaniesParametersType,
    BulkUpdateCompaniesQueryType,
    CompanyAdditionalInfoType,
    CompanyContactsInfoType,
    CompanyErrorsType,
    CompanyGeneralInfoType,
    CompanyMediaFileType,
    CompanyShortType,
    CompanyType,
    CompanyUploadMediaFileErrorType,
    CompanyUploadMediaFileParamteresType,
} from './company-type';

type UseCompanyHookAdditionalType = {
    createCompany: (generalInfo: CompanyGeneralInfoType) => Promise<CompanyType | void>;
    updateCompany: (
        id: number,
        info: CompanyGeneralInfoType | CompanyContactsInfoType | CompanyAdditionalInfoType
    ) => Promise<CompanyType | void>;
    refreshCompany: (companyId?: number) => void;
};

type UseBulkUpdateCompaniesType = {
    bulkUpdateCompanies: (parameters: BulkUpdateCompaniesParametersType) => Promise<void>;
};

type UseAvailableForUpdateCompaniesCountType = {
    getAvailableForUpdateCompaniesCount: (parameters: BulkUpdateCompaniesFilterType) => Promise<unknown>;
};

type UseCompanyMediaFileType = {
    uploadCompanyMediaFile: (parameters: CompanyUploadMediaFileParamteresType) => Promise<CompanyMediaFileType>;
};

type UseCompaniesOptionsType = {
    ordering?: string | null;
    mainFilterKey?: string;
    isActive?: boolean;
    skip?: boolean;
    isNewCompanies?: boolean;
    yandexNeedActualization?: string | null;
    catalogsFiltersQuery?: Array<{[p: number]: Array<CclStatusEnum>}>;
    accessTransferRequired?: string | null;
};

type UseAllCompaniesOptionsType = {
    isActive?: boolean;
    featuresToCheck?: FeaturesEnum | Array<FeaturesEnum>;
};

export function useCompanies(
    options: UseCompaniesOptionsType
): UseQueryResult<PaginatedResponseType<CompanyShortType>> & PaginationType {
    const {
        ordering,
        mainFilterKey = '',
        isActive,
        skip,
        isNewCompanies,
        yandexNeedActualization,
        catalogsFiltersQuery = [],
        accessTransferRequired,
    } = options;

    const cursorPaginationDependencies = useMemo(() => {
        return {mainFilterKey};
    }, [mainFilterKey]);

    const catalogFiltersToServerKeyMap = catalogsFiltersQuery.map((catalog) => {
        return Object.entries(catalog).reduce((accumulator, [providerId, statusArray]) => {
            const providerName = PROVIDER_ID_TO_ITS_NAME_MAP[Number(providerId) as ProvidersIdsEnum];
            const statusStringArray = statusArray.map((status) => status.toString()).join(',');

            return {...accumulator, [providerName]: statusStringArray};
        }, {});
    });

    const pagination = usePagination({
        dependencies: cursorPaginationDependencies,
        initialPageSize: defaultCompaniesCountPerPage,
    });

    const {page, pageSize, onDataLoaded} = pagination;

    const paginationOptions: RequestOptionsType = {
        page,
        count: pageSize,
    };
    const query = useQuery(
        [
            getCompaniesUrl(
                ordering ? {...paginationOptions, ordering} : paginationOptions,
                mainFilterKey,
                {
                    isActive,
                    newCompanies: isNewCompanies,
                    accessTransferRequired,
                    yandexActualizationHasError: yandexNeedActualization,
                },
                catalogFiltersToServerKeyMap
            ),
        ],
        () =>
            getCompaniesFetch(
                ordering ? {...paginationOptions, ordering} : paginationOptions,
                mainFilterKey,
                {
                    isActive,
                    newCompanies: isNewCompanies,
                    accessTransferRequired,
                    yandexActualizationHasError: yandexNeedActualization,
                },
                catalogFiltersToServerKeyMap
            ),
        {
            onSuccess: (data) => onDataLoaded(data),
            enabled: !skip,
        }
    );

    return {...query, pagination};
}

export function useAllCompanies(options: UseAllCompaniesOptionsType = {}): UseHookType<Array<AllCompanyType>> {
    const {isActive, featuresToCheck} = options;
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<AllCompanyType>>();

    useEffect(() => {
        setIsInProgress(true);

        getAllCompanies(isActive, featuresToCheck)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [isActive, featuresToCheck, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCompany(
    id: number | null
): UseHookType<CompanyType, CompanyErrorsType> & UseCompanyHookAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset, parseError} =
        useApiHooks<CompanyType, CompanyErrorsType>();

    const refreshCompany = useCallback(
        (companyId?: number) => {
            const currentId = id || companyId;

            if (!currentId) {
                return;
            }

            setIsInProgress(true);
            fetchGetCompany(currentId)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch((error) => {
                    try {
                        parseError(error);
                    } catch {
                        console.log(error);
                    }
                });
        },
        [id, setIsInProgress, setResult, parseError]
    );

    useEffect(() => {
        refreshCompany();
    }, [id, refreshCompany]);

    const createCompany = useCallback(
        (generalInfo: CompanyGeneralInfoType): Promise<CompanyType | void> => {
            setIsInProgress(true);

            return fetchCreateCompany(generalInfo)
                .then((response) => {
                    setResult(response);
                    setProcessError(null);
                })
                .finally(() => setIsInProgress(false))
                .catch(parseError);
        },
        [parseError, setIsInProgress, setProcessError, setResult]
    );

    const updateCompany = useCallback(
        (
            companyId: number,
            info: CompanyGeneralInfoType | CompanyContactsInfoType | CompanyAdditionalInfoType
        ): Promise<CompanyType | void> => {
            setIsInProgress(true);

            return fetchUpdateCompany(companyId, info)
                .then((response) => {
                    setResult(response);
                    setProcessError(null);
                })
                .finally(() => setIsInProgress(false))
                .catch(parseError);
        },
        [parseError, setIsInProgress, setProcessError, setResult]
    );

    return {isInProgress, processError, result, reset, createCompany, updateCompany, refreshCompany};
}

export function useBulkUpdateCompanies(
    options: BulkUpdateCompaniesQueryType
): UseHookType<void, BulkUpdateCompaniesFieldsErrorsType> & UseBulkUpdateCompaniesType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, reset} = useApiHooks<
        void,
        BulkUpdateCompaniesFieldsErrorsType
    >();

    const bulkUpdateCompanies = useCallback(
        (parameters: BulkUpdateCompaniesParametersType) => {
            setIsInProgress(true);

            return fetchBulkUpdateCompanies(parameters, options)
                .finally(() => setIsInProgress(false))
                .catch((error: unknown) => {
                    if (error instanceof Error) {
                        setProcessError(JSON.parse(error.message) as BulkUpdateCompaniesFieldsErrorsType);
                    }

                    throw error;
                });
        },
        [setIsInProgress, setProcessError]
    );

    return {isInProgress, processError, result, reset, bulkUpdateCompanies};
}

export function useAvailableForUpdateCompaniesCount(
    queries: BulkUpdateCompaniesQueryType
): UseHookType<AvailableForUpdateCompaniesCountType> & UseAvailableForUpdateCompaniesCountType {
    const {isInProgress, setIsInProgress, processError, setResult, result, reset} =
        useApiHooks<AvailableForUpdateCompaniesCountType>();

    const getAvailableForUpdateCompaniesCount = useCallback(
        (parameters: BulkUpdateCompaniesFilterType) => {
            setIsInProgress(true);

            return fetchGetAvailableForUpdateCompaniesCount(parameters, queries)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(catchError);
        },
        [setIsInProgress, setResult, queries.newCompanies, queries.yandexActualizationHasError]
    );

    return {isInProgress, processError, result, reset, getAvailableForUpdateCompaniesCount};
}

export function useCompanyMediaFile(): UseHookType<CompanyMediaFileType, CompanyUploadMediaFileErrorType> &
    UseCompanyMediaFileType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} = useApiHooks<
        CompanyMediaFileType,
        CompanyUploadMediaFileErrorType
    >();
    const {getLocalizedString} = useLocale();

    const uploadCompanyMediaFile = useCallback(
        (parameters: CompanyUploadMediaFileParamteresType) => {
            setIsInProgress(true);

            if (parameters.file.size > FILE_SIZE_BYTES) {
                const maxSizeError = {
                    file_s3: [getLocalizedString('COMPANY_FORM__GALLERY__ERROR__MAX_SIZE')],
                };

                setIsInProgress(false);

                // don't set in setProcessError because it should be handled in form validator in every usage instead
                throw new Error(JSON.stringify(maxSizeError));
            }

            return fetchUploadCompanyMediaFile(parameters)
                .then((response: CompanyMediaFileType) => {
                    setResult(response);

                    return response;
                })
                .finally(() => setIsInProgress(false))
                .catch((error: unknown) => {
                    if (error instanceof Error) {
                        setProcessError(JSON.parse(error.message) as CompanyUploadMediaFileErrorType);
                    }

                    throw error;
                });
        },
        [getLocalizedString, setIsInProgress, setProcessError, setResult]
    );

    return {isInProgress, processError, result, reset, uploadCompanyMediaFile};
}
