import {CalendarPostsResponseType, CalendarPostStatusEnum} from '../../../../service/posts/posts-types';

import {PostInsideDayCardType, PostInsideMonthCardType} from './posts-calendar-type';

export function extractPostsByIndexForMonthView(
    calendarPosts: CalendarPostsResponseType | null,
    index: number
): Array<PostInsideDayCardType> | null {
    if (!calendarPosts) {
        return null;
    }

    const calendarPostsByStatuses = calendarPosts[index];

    if (!calendarPostsByStatuses) {
        return null;
    }

    return Object.keys(calendarPostsByStatuses).reduce<Array<PostInsideDayCardType>>((accumulator, status) => {
        const posts =
            calendarPostsByStatuses[status as keyof typeof calendarPostsByStatuses]?.map((post) => ({
                ...post,
                status: status as keyof typeof calendarPostsByStatuses,
            })) ?? [];

        return [...accumulator, ...posts];
    }, []);
}

export function extractPostsByIndexForYearView(
    calendarPosts: CalendarPostsResponseType | null,
    index: number
): Array<PostInsideMonthCardType> | null {
    if (!calendarPosts) {
        return null;
    }

    const calendarPostsByStatuses = calendarPosts[index];

    if (!calendarPostsByStatuses) {
        return null;
    }

    return Object.keys(calendarPostsByStatuses)
        .filter((status) => (calendarPostsByStatuses[status as keyof typeof calendarPostsByStatuses]?.length ?? 0) > 0)
        .map<PostInsideMonthCardType>((status) => ({
            status: status as CalendarPostStatusEnum,
            count: calendarPostsByStatuses[status as keyof typeof calendarPostsByStatuses]?.length ?? 0,
        }));
}
