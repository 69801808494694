import {Button, ConfigProvider, Image, Skeleton, Space, Typography} from 'antd';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {Locale} from '../../../../../../../provider/locale/locale';
import {constructCommentPreview, extractReviewCommentContent} from '../review-helper';

import * as styles from './new-review-comment.scss';

type PropsType = {
    comment: string | null;
    reviewId: number;
};

export function NewReviewComment(props: PropsType): JSX.Element {
    const {comment = '', reviewId} = props;
    const [expanded, setExpanded] = useState(false);
    const [uniqueKey, setUniqueKey] = useState(0);
    const [isInView, setIsInView] = useState(false);
    const observerRef = useRef<HTMLDivElement>(null);

    const commentData = useMemo(() => extractReviewCommentContent(comment), [comment]);
    const commentPreview = useMemo(() => constructCommentPreview(comment || ''), [comment]);

    function onExpandHandler(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        setExpanded(true);
    }

    function onCommentClickHandler(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        window.open(location.origin + generatePath(appRoute.reviewsManagementReviewItem.path, {id: reviewId}));
    }

    function onCloseExpand() {
        setUniqueKey((previousState) => previousState + 1);
        setExpanded(false);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => (entry?.isIntersecting ? setIsInView(entry.isIntersecting) : null),
            {
                threshold: 0.1,
            }
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.Review_comment} ref={observerRef}>
            <div className={styles.Review_commentText}>
                <div>
                    {comment ? (
                        <Typography.Paragraph
                            className={styles.Review_text}
                            ellipsis={{
                                rows: 6,
                                expandable: true,
                                symbol: <Locale stringKey="REVIEWS__REVIEW_ABUSE__SHOW_TEXT" />,
                                onExpand: onExpandHandler,
                            }}
                            key={`${reviewId}-${uniqueKey}`}
                            onClick={onCommentClickHandler}
                        >
                            {commentPreview}
                        </Typography.Paragraph>
                    ) : (
                        <span className={styles.Review_isEmptyReview}>
                            <Locale stringKey="REVIEWS__REVIEW__NO_TEXT" />
                        </span>
                    )}
                    {expanded && (
                        <Button className={styles.Review_expandButton} onClick={onCloseExpand} type="link">
                            <Locale stringKey="REVIEWS__REVIEW_ABUSE__HIDE_TEXT" />
                        </Button>
                    )}
                </div>
            </div>
            {isInView ? (
                <ConfigProvider
                    locale={{
                        locale: '',
                        Image: {preview: ''},
                    }}
                >
                    <Image.PreviewGroup>
                        <Space className={styles.ImageGallery_list} direction="horizontal">
                            {commentData?.images.map((image) => (
                                <Image
                                    alt={image.alt}
                                    className={styles.ImageGallery_image}
                                    key={image.src}
                                    preview={{maskClassName: styles.ImageGallery_mask}}
                                    src={image.src}
                                    wrapperClassName={styles.ImageGallery_wrapper}
                                />
                            ))}
                        </Space>
                    </Image.PreviewGroup>
                </ConfigProvider>
            ) : (
                <Skeleton active className={styles.ImageGallery_imageSkeleton} />
            )}
        </div>
    );
}
