import {faStar} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useUser} from '../../provider/user/user-hook';
import {useUsetiful} from '../../provider/user/usetiful-hook';
import {classNames} from '../../util/css';
import {Text} from '../text/text';

import {UsetifulNameProductEnum, usetifulProductNameAttribute} from './usetiful-const';
import * as styles from './usetiful-product-rating-button.scss';

type PropsType = {
    productName: UsetifulNameProductEnum;
    className?: string;
};

export function UsetifulProductRatingButton(props: PropsType): JSX.Element {
    const {productName, className} = props;
    const {setTag, removeTag} = useUsetiful();
    const {pathname} = useLocation();
    const {isShowUsetifulButton} = useDomainConfig();
    const {user} = useUser();

    useEffect(() => {
        setTag({productName, url: pathname});

        return () => {
            removeTag('productName');
            removeTag('ratingProduct');
            removeTag('feedbackProduct');
            removeTag('url');
        };
    }, [pathname, productName, removeTag, setTag]);

    if (!isShowUsetifulButton || user?.language !== ShortLocaleNameEnum.ru) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    return (
        <Button
            className={classNames(styles.UsetifulProductRatingButton, className)}
            data-value={usetifulProductNameAttribute}
            icon={<FontAwesomeIcon icon={faStar} />}
            size="small"
            type="text"
        >
            <span>
                <Text bolder inheritColor stringKey="BUTTON__RATING" />
            </span>
        </Button>
    );
}
