/* eslint-disable sonarjs/cognitive-complexity */
import {
    faAt,
    faBadgeDollar,
    faBuilding,
    faCircleQuestion,
    faClone,
    faCommentDollar,
    faComments,
    faHouse,
    faImage,
    faMapLocationDot,
    faSignOut,
    faTableList,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ItemType} from 'antd/es/menu/interface';
import {useContext} from 'react';
import {RouteProps} from 'react-router';
import {useLocation} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {ChangeEmail} from '../../../../page/auth/change-email/change-email';
import {InitialQuestionnaire} from '../../../../page/auth/initial-questionnaire/initial-questionnaire';
import {ErrorPage404} from '../../../../page/error/page-404/page-404';
import {GoogleAccountSyncPage} from '../../../../page/main/account-sync/google/google-account-sync-page';
import {GoogleConfirmations} from '../../../../page/main/account-sync/google/steps/finish/confirmations/google-confirmations';
import {AccountsMatching} from '../../../../page/main/accounts-matching/accounts-matchings';
import {BulkEditCompanies} from '../../../../page/main/bulk-edit-companies/bulk-edit-companies';
import {CompanyActivityAndSummary} from '../../../../page/main/company-activity-and-summary/company-activity-and-summary';
import {CreateCompanySelectBrand} from '../../../../page/main/company-form/create-company-select-brand/create-company-select-brand';
import {CreateCompanyPageGuard} from '../../../../page/main/company-form/page-guard/create/page-guard';
import {EditCompanyPageGuard} from '../../../../page/main/company-form/page-guard/edit/page-guard';
import {Dashboard} from '../../../../page/main/dashboard/dashboard';
import {FakesBadge} from '../../../../page/main/duplicates-and-fakes/common/fakes-badge/fakes-badge';
import {FakesActivation} from '../../../../page/main/duplicates-and-fakes/fakes-activation';
import {FakesPage} from '../../../../page/main/duplicates-and-fakes/fakes-page';
import {FakesSettingsAndStatistics} from '../../../../page/main/duplicates-and-fakes/settings-and-statistics/fakes-settings-and-statistics';
import {TechnicalSupport} from '../../../../page/main/help/technical-support/technical-support';
import {IntegrationIntraService} from '../../../../page/main/integrations/integration-intra-service/integration-intra-service';
import {IntegrationUsedesk} from '../../../../page/main/integrations/integration-usedesk/integration-usedesk';
import {KnowledgeArticlePage} from '../../../../page/main/knowledge/knowledge-article-page/knowledge-article-page';
import {KnowledgePage} from '../../../../page/main/knowledge/knowledge-page';
import {CompaniesExport} from '../../../../page/main/my-companies/feed/export/feed-export-page';
import {CompaniesImport} from '../../../../page/main/my-companies/feed/import/companies-import';
import {FeedManagement} from '../../../../page/main/my-companies/feed/management/feed-management';
import {CompaniesValidation} from '../../../../page/main/my-companies/feed/validation/companies-validation';
import {MyCompanies} from '../../../../page/main/my-companies/my-companies';
import {Omnichannel} from '../../../../page/main/omnichannel/omnichannel';
import {Onboarding} from '../../../../page/main/onboarding/onboarding';
import {PhotoToolActivation} from '../../../../page/main/photo-tool/activation/photo-tool-activation';
import {PhotoToolBadge} from '../../../../page/main/photo-tool/components/phototool-badge/phototool-badge';
import {PhotoToolIndex} from '../../../../page/main/photo-tool/photo-tool-index';
import {CreateProductGroupPage} from '../../../../page/main/price/pages/products-group-form/create-product-group-page';
import {EditProductGroupPage} from '../../../../page/main/price/pages/products-group-form/edit-product-group-page';
import {Products} from '../../../../page/main/price/products';
import {QuestionsAndAnswers} from '../../../../page/main/questions-and-answers/questions-and-answers';
import {SourcesRouting} from '../../../../page/main/sources/sources-routing';
import {StoreLocatorIndex} from '../../../../page/main/store-locator/store-locator-index';
import {Tariffs} from '../../../../page/main/tariffs-and-limits/tariffs';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../provider/domain-config/domain-config-type';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {Locale} from '../../../../provider/locale/localization';
import {OnboardingContext} from '../../../../provider/onboarding/onboarding-context';
import {UiContext} from '../../../../provider/ui/ui-context';
import {useUser} from '../../../../provider/user/user-hook';
import {useFakesStatuses} from '../../../../service/fakes/fakes-statuses';
import {useTariffs} from '../../../../service/tariffs/tariffs-user-tariffs';
import {useLogoutMutation} from '../../../../service/user/user-logout';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {HeaderButtonEnum, useVisibleHeaderButtons} from '../../../header/common/header-buttons/header-buttons-hook';
import {useDoubleGisReportMenuItem} from '../../../navigation/double-gis-report/double-gis-report-menu-item';
import {getFeatureOrLandingPage, isEnabledForDomainAndDoubleGisWhiteLabel} from '../../../navigation/navigation-helper';
import {getAvailableItems, getPreparedMenuItems, getPreparedRoutes} from '../helpers/common';
import {CustomRouteItemType, NavigationItemConfigType} from '../routing-type';

import {getIsUserAvailable} from './app-navigation-helper';
import {useLocalPackNavigation} from './use-local-pack-navigation';
import {useOnlinePresenceNavigation} from './use-online-presence-navigation';
import {usePostManagementNavigation} from './use-post-management-navigation';
import {useReviewsAnalysisNavigation} from './use-reviews-analysis-navigation';
import {useReviewsGeneratorNavigation} from './use-reviews-generator-navigation';
import {useReviewsManagementNavigation} from './use-reviews-management-navigation';
import {useSettingsNavigation} from './use-settings-navigation';

// eslint-disable-next-line complexity, max-statements
function useNavigationConfig(): Array<NavigationItemConfigType> {
    const {isMobileNavigationOpen} = useContext(UiContext);
    const {showOnboarding} = useContext(OnboardingContext);
    const {isUserProfileShow, domainName, LogoBreadcrumbs, canCreateCompany} = useDomainConfig();
    const documentationLinks = useDocumentationLinks();

    const {user, isReputationManager, isTechnicalSupportUser} = useUser();
    const {licenses, availableFeatures} = useLicenses();
    const {data: fakesStatuses} = useFakesStatuses();
    const {data: tariffs} = useTariffs();
    const {isStaff, isDoubleGisTrusted, isDemoUser, isTestUser} = user || {};
    const {search} = useLocation();

    const reviewsManagementRoutes = useReviewsManagementNavigation();
    const reviewsAnalysisRoutes = useReviewsAnalysisNavigation();
    const reviewsGeneratorRoutes = useReviewsGeneratorNavigation();
    const onlinePresenceRoutes = useOnlinePresenceNavigation();
    const settingsRoutes = useSettingsNavigation();
    const postManagementRoutes = usePostManagementNavigation();
    const localPackRoutes = useLocalPackNavigation();

    const showDoubleGisReportMenuItem = isStaff && isDoubleGisTrusted;
    const showOmnichannelSettings = !(isDemoUser || isTestUser);

    const doubleGisReportMenuItem = useDoubleGisReportMenuItem({
        skip: !showDoubleGisReportMenuItem,
    }) as unknown as NavigationItemConfigType; // todo: change type in parent hook??
    const visibleHeaderButtons = useVisibleHeaderButtons();
    const {mutate: logout} = useLogoutMutation();

    const mobileLogoutButtonVisible =
        isMobileNavigationOpen && isUserProfileShow && !visibleHeaderButtons.includes(HeaderButtonEnum.Profile);

    // IMPORTANT! Order routes as in ui menu
    const routeConfig: Array<NavigationItemConfigType> = [
        {
            isAvailable: showOnboarding,
            isOnlyRoute: true,
            path: appRoute.onboarding.path,
            exact: true,
            component: Onboarding,
        },
        {
            isAvailable: availableFeatures[FeaturesEnum.dashboard],
            path: appRoute.dashboard.path,
            icon: LogoBreadcrumbs ? <LogoBreadcrumbs /> : <FontAwesomeIcon icon={faHouse} />,
            labelLangKey: 'CATEGORY_NAME__DASHBOARD',
            exact: false,
            component: Dashboard,
            search,
        },
        {
            isAvailable: availableFeatures[FeaturesEnum.myCompanies],
            path: appRoute.myCompanies.path,
            icon: <FontAwesomeIcon icon={faBuilding} />,
            labelLangKey: 'CATEGORY_NAME__MY_COMPANIES',
            component: MyCompanies,
            exact: true,
            search,
        },
        {
            isOnlyRoute: true,
            path: appRoute.sourcesAccountSyncGoogle.path,
            exact: true,
            component: GoogleAccountSyncPage,
        },
        {
            isOnlyRoute: true,
            path: appRoute.sourcesAccountSyncGoogleConfirmations.path,
            exact: true,
            component: GoogleConfirmations,
        },
        {
            isAvailable: availableFeatures[FeaturesEnum.sources],
            path: appRoute.sources.path,
            icon: <FontAwesomeIcon icon={faTableList} />,
            labelLangKey: 'CATEGORY_NAME__SOURCES',
            component: SourcesRouting,
            exact: false,
        },
        // todo: нужен ли тут роутинг или оставлять как есть
        {
            ...doubleGisReportMenuItem,
            key: doubleGisReportMenuItem?.key ?? 'double-gis-report',
            isAvailable: Boolean(showDoubleGisReportMenuItem),
            isCustom: true,
        },
        ...reviewsManagementRoutes,
        ...reviewsAnalysisRoutes,
        ...reviewsGeneratorRoutes,
        {
            isAvailable: availableFeatures[FeaturesEnum.questionsAndAnswers],
            path: appRoute.questionsAndAnswers.path,
            icon: <FontAwesomeIcon icon={faComments} />,
            labelLangKey: 'CATEGORY_NAME__QUESTIONS_N_ANSWERS',
            exact: true,
            component: QuestionsAndAnswers,
        },
        {
            isAvailable: availableFeatures[FeaturesEnum.photoTool],
            isActivated: licenses[FeaturesEnum.photoTool]?.isActive,
            path: appRoute.photoToolIndex.path,
            icon: <FontAwesomeIcon icon={faImage} />,
            label: <PhotoToolBadge />,
            labelLangKey: 'CATEGORY_NAME__PHOTO_TOOL',
            exact: false,
            component: getFeatureOrLandingPage({
                license: licenses[FeaturesEnum.photoTool],
                activationComponent: PhotoToolActivation,
                pageComponent: PhotoToolIndex,
            }),
        },
        ...onlinePresenceRoutes,
        {
            // todo: проверка доступности отличается от роутинга только isDomainFeatureEnabled[FeaturesEnum.storeLocator]
            isAvailable: isEnabledForDomainAndDoubleGisWhiteLabel({
                isDomainFeatureEnabled: availableFeatures[FeaturesEnum.storeLocator],
                isFeatureEnabled: Boolean(licenses[FeaturesEnum.storeLocator]?.isActive),
                domainName,
            }),
            isActivated: licenses[FeaturesEnum.storeLocator]?.isActive,
            path: appRoute.storeLocator.path,
            icon: <FontAwesomeIcon icon={faMapLocationDot} />,
            labelLangKey: 'CATEGORY_NAME__STORE_LOCATOR',
            exact: false,
            component: StoreLocatorIndex,
        },
        {
            isAvailable: licenses[FeaturesEnum.omnichannel]?.isActive && availableFeatures[FeaturesEnum.omnichannel],
            path: appRoute.omnichannel.path,
            icon: <FontAwesomeIcon icon={faAt} />,
            labelLangKey: 'CATEGORY_NAME__OMNICHANNEL',
            exact: false,
            component: Omnichannel,
            // todo: остальные роуты находятся в родительском компоненте Omnichannel
            children: [
                {
                    path: appRoute.omnichannelChats.path,
                    labelLangKey: 'CATEGORY_NAME__OMNICHANNEL__COMMUNICATION',
                },
                {
                    isAvailable: showOmnichannelSettings,
                    path: appRoute.omnichannelSettings.path,
                    labelLangKey: 'CATEGORY_NAME__OMNICHANNEL__SETTINGS',
                },
            ],
        },
        ...postManagementRoutes,
        {
            isAvailable:
                availableFeatures[FeaturesEnum.duplicatesAndFakes] &&
                getIsUserAvailable(user?.id || 0) &&
                !isReputationManager,
            isActivated: licenses[FeaturesEnum.duplicatesAndFakes]?.isActive,
            path: appRoute.duplicatesAndFakes.path,
            icon: <FontAwesomeIcon icon={faClone} />,
            label: <FakesBadge />,
            labelLangKey: 'CATEGORY_NAME__DUBS_AND_FAKES',
            exact: true,
            // todo is a temporary measure until the landing page is adapted for brandWizard. For 2gis, the backend does not create trial licenses, so its case is not processed
            component: licenses[FeaturesEnum.duplicatesAndFakes]?.isActive
                ? domainName === DomainNameEnum.brandWizard && fakesStatuses?.isTrial
                    ? FakesActivation
                    : FakesPage
                : FakesActivation,
        },
        {
            isOnlyRoute: true,
            isAvailable: availableFeatures[FeaturesEnum.duplicatesAndFakes] && !isReputationManager,
            isActivated: licenses[FeaturesEnum.duplicatesAndFakes]?.isActive,
            path: appRoute.fakesSettings.path,
            exact: true,
            component: licenses[FeaturesEnum.duplicatesAndFakes]?.isActive
                ? domainName === DomainNameEnum.brandWizard && fakesStatuses?.isTrial
                    ? FakesActivation
                    : FakesSettingsAndStatistics
                : FakesActivation,
        },
        ...localPackRoutes,
        {
            path: appRoute.products.path,
            icon: <FontAwesomeIcon icon={faCommentDollar} />,
            labelLangKey: 'CATEGORY_NAME__PRODUCTS',
            exact: true,
            component: Products,
            isAvailable:
                getIsUserAvailable(user?.id || 0) && !isReputationManager && availableFeatures[FeaturesEnum.prices],
        },
        {
            path: appRoute.tariffsAndLimits.path,
            isAvailable:
                tariffs &&
                tariffs.results.length > 0 &&
                !isReputationManager &&
                availableFeatures[FeaturesEnum.tariffs],
            icon: <FontAwesomeIcon icon={faBadgeDollar} />,
            labelLangKey: 'CATEGORY_NAME__TARIFFS',
            exact: true,
            component: Tariffs,
        },
        {
            isOnlyRoute: true,
            path: appRoute.createProductGroup.path,
            exact: true,
            component: CreateProductGroupPage,
        },
        {
            isOnlyRoute: true,
            path: appRoute.editProductsGroup.path,
            exact: true,
            component: EditProductGroupPage,
        },
        ...settingsRoutes,
        // todo: роуты которые остались в route-authorized и не в меню
        {
            isOnlyRoute: true,
            path: appRoute.changeEmail.path,
            exact: true,
            component: ChangeEmail,
        },
        {
            isOnlyRoute: true,
            path: appRoute.accountsMatching.path,
            exact: true,
            component: AccountsMatching,
        },
        {
            isOnlyRoute: true,
            path: appRoute.editCompany.path,
            exact: true,
            component: EditCompanyPageGuard,
        },
        {
            isOnlyRoute: true,
            path: appRoute.bulkEditCompanies.path,
            exact: true,
            component: BulkEditCompanies,
        },
        {
            isOnlyRoute: true,
            path: appRoute.settingsIntegrationIntraService.path,
            exact: true,
            component: IntegrationIntraService,
        },
        {
            isAvailable: canCreateCompany,
            isOnlyRoute: true,
            path: appRoute.createCompanySelectBrand.path,
            exact: true,
            component: CreateCompanySelectBrand,
        },
        {
            isAvailable: canCreateCompany,
            isOnlyRoute: true,
            path: appRoute.createCompany.path,
            exact: true,
            component: CreateCompanyPageGuard,
        },
        {
            isAvailable: availableFeatures[FeaturesEnum.usedeskIntegration],
            isOnlyRoute: true,
            path: appRoute.settingsIntegrationUsedesk.path,
            exact: true,
            component: IntegrationUsedesk,
            deniedPath: [appRoute.settingsIntegrationUsedesk.oldPath],
        },
        {
            isAvailable: true,
            isOnlyRoute: true,
            path: appRoute.companyActivityAndSummary.path,
            exact: true,
            component: CompanyActivityAndSummary,
        },
        {
            isOnlyRoute: true,
            path: appRoute.companyImport.path,
            exact: true,
            component: CompaniesImport,
        },
        {
            isOnlyRoute: true,
            path: appRoute.companyValidate.path,
            exact: true,
            component: CompaniesValidation,
        },
        {
            isOnlyRoute: true,
            path: appRoute.companyFeedManagement.path,
            exact: true,
            component: FeedManagement,
        },
        {
            isOnlyRoute: true,
            path: appRoute.companyExport.path,
            exact: true,
            component: CompaniesExport,
        },
        {
            isAvailable: availableFeatures[FeaturesEnum.knowledge],
            exact: false,
            path: appRoute.help.path,
            icon: <FontAwesomeIcon icon={faCircleQuestion} />,
            labelLangKey: 'CATEGORY_NAME__HELP',
            deniedPath: [appRoute.help.path],
            redirectPath: appRoute.helpTechnicalSupport.path,
            children: [
                {
                    isAvailable: availableFeatures[FeaturesEnum.knowledge] && isTechnicalSupportUser,
                    exact: true,
                    component: TechnicalSupport,
                    labelLangKey: 'CATEGORY_NAME__HELP__TECHNICAL_SUPPORT',
                    path: appRoute.helpTechnicalSupport.path,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.knowledge] && isTechnicalSupportUser,
                    isOnlyRoute: true,
                    component: TechnicalSupport,
                    path: appRoute.helpTechnicalSupportTicket.path,
                },
                {
                    isAvailable: Boolean(documentationLinks.help),
                    exact: availableFeatures[FeaturesEnum.knowledge],
                    isCustom: !availableFeatures[FeaturesEnum.knowledge],
                    label: availableFeatures[FeaturesEnum.knowledge] ? (
                        <Locale stringKey="CATEGORY_NAME__KNOWLEDGE" />
                    ) : (
                        <a href={documentationLinks.help} rel="noreferrer" target="_blank">
                            <Locale stringKey="CATEGORY_NAME__KNOWLEDGE" />
                        </a>
                    ),
                    path: appRoute.knowledge.path,
                    component: KnowledgePage,
                },
                {
                    isAvailable: Boolean(documentationLinks.help) && availableFeatures[FeaturesEnum.knowledge],
                    isOnlyRoute: true,
                    path: appRoute.knowledgeArticle.path,
                    exact: true,
                    component: KnowledgeArticlePage,
                },
            ],
        },
        {
            isAvailable: mobileLogoutButtonVisible,
            isCustom: true,
            key: 'logout',
            icon: <FontAwesomeIcon icon={faSignOut} />,
            label: <Locale stringKey="HEADER__USER__TEXT__LOGOUT" />,
            onClick: () => logout(),
        },
        {
            isOnlyRoute: true,
            path: appRoute.questionnaire.path,
            exact: true,
            component: InitialQuestionnaire,
        },
        // always should be last, for correct work
        {
            isOnlyRoute: true,
            path: '*',
            exact: false,
            component: ErrorPage404,
        },
    ];

    const availableItems = getAvailableItems(routeConfig);

    const firstAvailableRouteConfig = availableItems.find(
        (item) =>
            !item.isOnlyRoute ||
            item.path === appRoute.onboarding.path ||
            item.path === appRoute.settingsIntegrationUsedesk.path
    );

    const rootRoute: NavigationItemConfigType = firstAvailableRouteConfig
        ? {
              ...firstAvailableRouteConfig,
              redirectPath: firstAvailableRouteConfig.redirectPath || firstAvailableRouteConfig.path,
              deniedPath: [
                  ...(firstAvailableRouteConfig.deniedPath || []),
                  appRoute.root.path,
                  appRoute.login.path,
                  appRoute.register.path,
                  appRoute.forgotPassword.path,
              ],
          }
        : {};

    const updatedRouteConfig = [rootRoute, ...routeConfig.filter((route) => rootRoute?.path !== route.path)];

    return getAvailableItems(updatedRouteConfig);
}

export function useMenuNavigation(): Array<ItemType> {
    const items = useNavigationConfig();

    return getPreparedMenuItems(items) as Array<ItemType>;
}

export function useAppNavigation(): Array<RouteProps & CustomRouteItemType> {
    const routes = useNavigationConfig();

    return getPreparedRoutes(routes);
}
