import {useUser} from '../../../../provider/user/user-hook';
import {ProvidersIdsEnum} from '../../../../util/type';

import {YandexStoriesWidget} from './yandex-stories-widget';

export function YandexStories(): JSX.Element | null {
    const {user} = useUser();
    const {regionAvailableCatalogs} = user || {};

    if (regionAvailableCatalogs?.[ProvidersIdsEnum.yandex]) {
        return <YandexStoriesWidget />;
    }

    return null;
}
