import {Alert, Button, Space} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useState} from 'react';

import {Text} from '../../../../component/text/text';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/localization';
import {ProvidersIdsEnum} from '../../../../util/type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {SourcesSearchParametersEnum} from '../../sources/sources-type';
import {YandexAccountTransferModal} from '../yandex-account-transfer-modal/yandex-account-transfer-modal';

import {useShowYandexTransferAccountBanner} from './yandex-account-transfer-hook';
import * as styles from './yandex-account-transfer-alert.scss';

type PropsType = {
    className?: string;
    setCollectionUrl?: Dispatch<SetStateAction<Array<string>>>;
};

export function YandexAccountTransferAlert(props: PropsType): ReactElement | null {
    const {className, setCollectionUrl} = props;
    const {yandex} = useDocumentationLinks();
    const {isShowYandexTransferAccountBanner} = useDomainConfig();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const {data, isLoading} = useShowYandexTransferAccountBanner();
    const {setQuery, getQuery, deleteQuery} = useUrl();

    const isShowYandexAccountTransferQuery = getQuery('accessTransferRequired') === 'true';

    if (!data || isLoading || !data.showAccessTransferBanner || !isShowYandexTransferAccountBanner) {
        return null;
    }

    function setYandexAccountTransferQuery() {
        if (setCollectionUrl) {
            setCollectionUrl([]);
        }

        setQuery({
            accessTransferRequired: true,
            [SourcesSearchParametersEnum.SourceIdList]: ProvidersIdsEnum.yandex,
        });
    }

    function setDeleteAccountTransferQuery() {
        if (setCollectionUrl) {
            setCollectionUrl([]);
        }

        deleteQuery(['accessTransferRequired', 'source-ids']);
    }

    return (
        <>
            <Alert
                className={className}
                description={
                    <Space direction="vertical">
                        <div>
                            <Text
                                className={styles.YandexAccountTransferAlert_text}
                                stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT_ALERT__DESCRIPTION"
                            />
                            {yandex.transferAccountsBanner && (
                                <a href={yandex.transferAccountsBanner} rel="noreferrer" target="_blank">
                                    <Locale stringKey="GOOGLE_SYNC__CODE_REQUEST__DESCRIPTION__LINK" />
                                </a>
                            )}
                        </div>
                        <Space wrap>
                            <Button onClick={() => setOpenModal(true)} type="primary">
                                <Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__GET_ACCESS" />
                            </Button>
                            <Button
                                onClick={() =>
                                    isShowYandexAccountTransferQuery
                                        ? setDeleteAccountTransferQuery()
                                        : setYandexAccountTransferQuery()
                                }
                            >
                                <Locale
                                    stringKey={
                                        isShowYandexAccountTransferQuery
                                            ? 'MY_COMPANIES__YANDEX_VERIFICATION__ALERT__CLEAR_FILTER'
                                            : 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__SHOW_COMPANIES'
                                    }
                                />
                            </Button>
                        </Space>
                    </Space>
                }
                message={
                    <Space>
                        <Text bolder large stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT_ALERT__TITLE" />
                    </Space>
                }
                showIcon
                type="warning"
            />
            {openModal && <YandexAccountTransferModal isCheckAll setOpenModal={setOpenModal} />}
        </>
    );
}
