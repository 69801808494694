import {fetchAndDeserialize, serializeToURLParameters, serializeValue} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../../util/url';

import {
    CompanyResponseType,
    ReviewsAnalysisCompaniesQueryType,
    reviewsAnalysisCompanyResponseSchema,
    ReviewsAnalysisCompanyType,
} from './review-companies-type';

export async function fetchReviewCompanies(
    options: ReviewsAnalysisCompaniesQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<CompanyResponseType<ReviewsAnalysisCompanyType>> {
    const {sort, ...restOptions} = options;
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<Omit<ReviewsAnalysisCompaniesQueryType, 'sort'>>(restOptions),
        sort: serializeValue(sort || ''),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/companies/?${searchParameters}`;

    return fetchAndDeserialize<CompanyResponseType<ReviewsAnalysisCompanyType>>(
        url,
        reviewsAnalysisCompanyResponseSchema
    );
}
