export const defaultCompaniesCountPerPage = 30;

export enum CompaniesSelectorFeatureEnum {
    UserManagement = 'user_management',
    ReviewSuggests = 'review_suggests',
    ReviewsGenerator = 'reviews_generator',
    Posts = 'posts',
    Prices = 'prices',
    Omnichannel = 'omnichannel',
    CompaniesExport = 'companies_export',
    LocalPack = 'local_pack',
    YandexStories = 'stories',
    SourceSettingsAccounts = 'source_settings_accounts',
}
