import {Space, Tag} from 'antd';
import {useCallback} from 'react';

import {Text} from '../../../../../component/text/text';
import {AsciiSpinner} from '../../../../../layout/spinner/ascii-spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useLocalPackSearchQueries} from '../../../../../service/local-pack/local-pack-hook';
import {LocalPackFilterEnum, LocalPackFilterType} from '../local-pack-filter-type';

import {getRangeFilterTagFormat} from './local-pack-filter-active-helper';
import * as styles from './local-pack-filter-active.scss';

type PropsType = {
    filter: LocalPackFilterType;
    setFilter: (filter: Partial<LocalPackFilterType>) => void;
};

export function LocalPackFilterActive(props: PropsType): JSX.Element {
    const {localeName} = useLocale();
    const {filter, setFilter} = props;
    const {data: searchQueries, isLoading: isLoadingSearchQueries} = useLocalPackSearchQueries();

    const {
        [LocalPackFilterEnum.timeRange]: timeRangeFilter,
        [LocalPackFilterEnum.searchQueries]: selectedSearchQueries,
    } = filter;
    const [startPeriod, endPeriod] = timeRangeFilter;

    const isEmpty = !startPeriod && !endPeriod && selectedSearchQueries.length === 0;

    const onTimeRangeRemove = useCallback((): void => {
        setFilter({
            ...filter,
            [LocalPackFilterEnum.timeRange]: [null, null],
        });
    }, [filter, setFilter]);

    const onSearchQueryRemove = useCallback(
        (searchQueryIdToRemove: number) => {
            setFilter({
                ...filter,
                [LocalPackFilterEnum.searchQueries]: filter.searchQueries.filter(
                    (filterSearchQueryId) => filterSearchQueryId !== searchQueryIdToRemove
                ),
            });
        },
        [filter, setFilter]
    );

    if (isEmpty) {
        return <Text secondary stringKey="LOCAL_PACK__FILTER__EMPTY_TEXT" />;
    }

    return (
        <Space>
            <Text className={styles.LocalPackFilterHeaderLabel} stringKey="LOCAL_PACK__FILTER__LABEL" />

            <div>
                {startPeriod && endPeriod ? (
                    <Tag closable onClose={onTimeRangeRemove}>
                        {getRangeFilterTagFormat(localeName, startPeriod, endPeriod)}
                    </Tag>
                ) : null}

                {selectedSearchQueries.map((searchQueryId) => {
                    return (
                        <Tag closable key={searchQueryId} onClose={() => onSearchQueryRemove(searchQueryId)}>
                            {isLoadingSearchQueries ? (
                                <AsciiSpinner />
                            ) : (
                                searchQueries?.find(({id}) => searchQueryId === id)?.query
                            )}
                        </Tag>
                    );
                })}
            </div>
        </Space>
    );
}
