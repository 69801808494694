import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';

import {
    RequestSentimentByTimeQueryType,
    reviewAnalysisSentimentByTimeSchema,
    ReviewAnalysisSentimentResponseType,
    reviewAnalysisSentimentSchema,
    ReviewsAnalysisSentimentQueryType,
    SentimentByTimeResponseType,
} from './review-sentiment-type';

export async function fetchReviewSentimentStats(
    options: ReviewsAnalysisSentimentQueryType,
    mainFilterKey: string
): Promise<ReviewAnalysisSentimentResponseType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisSentimentQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/sentiment/?${searchParameters}`;

    return fetchAndDeserialize<ReviewAnalysisSentimentResponseType>(url, reviewAnalysisSentimentSchema);
}

export async function fetchReviewSentimentByTime(
    options: RequestSentimentByTimeQueryType,
    mainFilterKey: string
): Promise<SentimentByTimeResponseType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<RequestSentimentByTimeQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/sentiment-by-time/?${searchParameters}`;

    return fetchAndDeserialize<SentimentByTimeResponseType>(url, reviewAnalysisSentimentByTimeSchema);
}
