import {useMemo} from 'react';

import {Text} from '../../../../component/text/text';
import {primary40, primary95} from '../../../../css/var-export.scss';
import {LineChart} from '../../../../layout/chart/line-chart/line-chart';
import {LineDataType} from '../../../../layout/chart/line-chart/line-chart-type';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {getTrendChartOptions} from '../../../../layout/table/trend-cell/trend-cell-helper';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {CompareModeDelta} from '../compare-mode-delta/compare-mode-delta';

import * as styles from './statistics-metric.scss';

type PropsType = {
    titleLangKeyType: LangKeyType;
    isLoading?: boolean;
    mainData: {
        value: number;
        values: Array<{
            count: number;
            date: string;
        }>;
    };
    compareData: {
        value: number;
        delta: number;
    } | null;
    isCompareMode: boolean;
    pdfPreview?: boolean;
};

export function StatisticsMetric(props: PropsType): JSX.Element {
    const {titleLangKeyType, mainData, compareData, isLoading, pdfPreview, isCompareMode} = props;

    const {getLocalizedString} = useLocale();

    const chartData: LineDataType = useMemo(() => {
        return {
            labels: mainData.values.map((item) => item.date),
            datasets: [
                {
                    values: mainData.values.map((item) => item.count),
                    color: primary95,
                    borderColor: primary40,
                    fill: true,
                    label: getLocalizedString(titleLangKeyType),
                    tension: 0,
                },
            ],
        };
    }, [mainData, getLocalizedString, titleLangKeyType]);

    if (isLoading) {
        return (
            <PageCard title={<Text block bolder large stringKey={titleLangKeyType} />}>
                <Spinner />
            </PageCard>
        );
    }

    return (
        <PageCard title={<Text block bolder large stringKey={titleLangKeyType} />}>
            {isCompareMode ? (
                <CompareModeDelta
                    compareValue={compareData?.value || 0}
                    delta={compareData?.delta || 0}
                    value={mainData.value}
                />
            ) : (
                <div className={styles.StatisticsMetric}>
                    <Text block bolder className={styles.StatisticsMetric_mainPeriodValue}>
                        {mainData.value}
                    </Text>

                    {chartData && (
                        <div className={styles.StatisticsMetric_chartWrapper}>
                            <LineChart data={chartData} options={getTrendChartOptions(chartData, pdfPreview)} />
                        </div>
                    )}
                </div>
            )}
        </PageCard>
    );
}
