import {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';

export enum FakesCreateReportFormEnum {
    DateRange = 'dateRange',
    BrandIds = 'brandIds',
}

export type FakesCreateReportFormType = {
    [FakesCreateReportFormEnum.DateRange]: NoUndefinedRangeValueType<Dayjs>;
    [FakesCreateReportFormEnum.BrandIds]: Array<number>;
};
