import {Trend} from '../../../../../layout/trend/trend';
import {Locale} from '../../../../../provider/locale/locale';
import {OnlinePresenceBaseMetricType} from '../../../../../service/dashboard/dashboard-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './dashboard-online-presence-catalog-stats.scss';

type PropsType = {
    impressions: OnlinePresenceBaseMetricType;
    interactions: OnlinePresenceBaseMetricType;
    conversion: OnlinePresenceBaseMetricType;
};

export function DashboardOnlinePresenceBaseMetrics(props: PropsType): JSX.Element {
    const {interactions, conversion, impressions} = props;
    const {getFormattedNumber} = useFormat();

    return (
        <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsContainer}>
            <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsItem}>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsTitle}>
                    <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__IMPRESSIONS" />
                </div>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsValue}>
                    {getFormattedNumber(impressions.current)}
                </div>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsTrendContainer}>
                    <Trend delta={impressions.delta} unit="" />
                    <div>
                        <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__PERIOD" />
                    </div>
                </div>
            </div>

            <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsItem}>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsTitle}>
                    <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__TARGET_ACTIONS" />
                </div>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsValue}>
                    {getFormattedNumber(interactions.current)}
                </div>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsTrendContainer}>
                    <Trend delta={interactions.delta} unit="" />
                    <div>
                        <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__PERIOD" />
                    </div>
                </div>
            </div>

            <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsItem}>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsTitle}>
                    <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__CONVERSION" />
                </div>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsValue}>
                    {getFormattedNumber(conversion.current, {
                        maximumFractionDigits: 1,
                    })}
                    %
                </div>
                <div className={styles.DashboardOnlinePresenceCatalogStats_baseMetricsTrendContainer}>
                    <Trend delta={conversion.delta} />
                    <div>
                        <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__PERIOD" />
                    </div>
                </div>
            </div>
        </div>
    );
}
