import {PublicationContext} from 'centrifuge';
import {useEffect} from 'react';

import {useCentrifugeSubscription} from '../../../provider/centrifuge/centrifuge-hook';
import {useUser} from '../../../provider/user/user-hook';

import {
    getCentrifugeOmnichannelSubscriptionName,
    isOmnichannelCommunicationActionDataType,
} from './omnichannel-actions-helper';
import {OmnichannelCommunicationActionDataType} from './omnichannel-actions-type';

export function useOmnichannelAction(onPublication: (data: OmnichannelCommunicationActionDataType) => void): null {
    const {getSubscription} = useCentrifugeSubscription();

    const {user} = useUser();

    useEffect(() => {
        function onActionReceived({data}: PublicationContext) {
            if (isOmnichannelCommunicationActionDataType(data)) {
                onPublication(data);
            }
        }

        const subscription = getSubscription(getCentrifugeOmnichannelSubscriptionName(user));

        subscription?.on('publication', onActionReceived);

        return () => {
            subscription?.removeListener('publication', onActionReceived);
        };
    }, [getSubscription, onPublication, user]);

    return null;
}
