import {useContext} from 'react';

import {useLocale} from '../locale/locale-hook';

import {HelpLinksContext} from './help-links-context';
import {isMatchingHelpLink} from './help-links-context-helper';
import {HelpLinksContextType, HelpLinkType} from './help-links-type';

function useHelpLinks(): Array<HelpLinkType> {
    const helpLinksContext = useContext<HelpLinksContextType>(HelpLinksContext);

    if (!helpLinksContext) {
        throw new Error('useHelpLinks should be only be used inside HelpLinksContext');
    }

    return helpLinksContext.helpLinks;
}

export function useHelpLink(linkPath: string | null): HelpLinkType | null {
    const helpLinks = useHelpLinks();
    const {shortLocaleName} = useLocale();

    if (!linkPath) {
        return null;
    }

    return helpLinks?.find((resultLink) => isMatchingHelpLink(resultLink, linkPath, shortLocaleName)) || null;
}

export function useDocumentationLinks(): HelpLinksContextType['documentationLinks'] {
    const helpLinksContext = useContext<HelpLinksContextType>(HelpLinksContext);

    if (!helpLinksContext) {
        throw new Error('useHelpLinks should be only be used inside HelpLinksContext');
    }

    return helpLinksContext.documentationLinks;
}

export function useVideoLinks(): HelpLinksContextType['videoLinks'] {
    const helpLinksContext = useContext<HelpLinksContextType>(HelpLinksContext);

    if (!helpLinksContext) {
        throw new Error('useVideoLinks should be only be used inside HelpLinksContext');
    }

    return helpLinksContext.videoLinks;
}
