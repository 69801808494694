import {faChartColumn} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../app-route';
import {PageHeaderWithLicenseTag} from '../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {LicensePageCard} from '../../../component/license-page-card/license-page-card';
import {Meta} from '../../../component/meta/meta';
import {PageCardDivider} from '../../../component/page-card-divider/page-card-divider';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLicenses} from '../../../provider/license/license-hook';
import {useLocale} from '../../../provider/locale/locale-hook';
import {
    useOnlinePresenceLocations,
    useOnlinePresenceV2Filter,
} from '../../../service/online-presence/online-presence-hook';
import {FeaturesEnum} from '../../../service/user/user-type';

import {LocationsTable} from './locations-table/locations-table';
import {OnlinePresenceCompanyStatsFilter} from './online-presence-filter-v2/online-presence-company-stats-filter';
import * as styles from './online-presence.scss';

export function OnlinePresenceLocations(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {filter, dispatchFilter} = useOnlinePresenceV2Filter();

    const {data, isLoading, pagination, availableCatalogs} = useOnlinePresenceLocations(filter);

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.onlinePresenceAnalytics];

    const isLicenseExpired = !license?.isActive;

    return (
        <Page renderContainer={(pageContent) => <MainPageContainer>{pageContent}</MainPageContainer>}>
            <Meta title={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS')} />
            <PageCard>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.onlinePresenceDashboard.path,
                            titleLangKey: 'ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS',
                        },
                    ]}
                />

                <PageHeaderWithLicenseTag
                    className={styles.OnlinePresence_header}
                    headerLangKey="ONLINE_PRESENCE_ANALYTICS__LOCATIONS__PAGE_TITLE"
                    licenseActive={license?.isActive}
                    productName={UsetifulNameProductEnum.ONLINE_PRESENCE__LOCATIONS}
                    trialLicense={license?.isTrial}
                />

                <PageCardDivider />
                <OnlinePresenceCompanyStatsFilter
                    dispatchFilter={dispatchFilter}
                    filter={filter}
                    isDisabled={isLoading || isLicenseExpired}
                />
            </PageCard>

            {isLoading && <Spinner />}

            <LicensePageCard
                icon={<FontAwesomeIcon icon={faChartColumn} />}
                license={license}
                productLandKey="CATEGORY_NAME__ONLINE_PRESENCE"
            />

            {!isLicenseExpired && !isLoading && (
                <LocationsTable availableCatalogs={availableCatalogs} data={data} pagination={pagination} />
            )}
        </Page>
    );
}
