import {Button, Tooltip} from 'antd';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../provider/locale/locale';
import {useUser} from '../../../../../provider/user/user-hook';
import {StatisticCclsForCompanyType} from '../../../../../service/company/company-type';
import {CclStateStatusEnum, ProvidersIdsEnum} from '../../../../../util/type';
import {StatusColumnPopover} from '../../../sources/table/columns/status/popover/status-column-popover';
import {STATUS_TEXT_BY_CCL_STATUS} from '../../../sources/table/columns/status/status-column-const';
import {
    buildProviderConfirmationRoute,
    buildProviderConfirmationRouteV2,
    buildSourcesNeedActionRoute,
    buildSourcesNeedActionRouteV2,
    getStatusByCclAndUser,
} from '../../../sources/table/columns/status/status-column-helper';

import {getStatus} from './ccl-status-column-helper';
import * as styles from './ccl-status-column.scss';

type PropsType = {
    cclData?: StatisticCclsForCompanyType;
    accessTransferRequired?: boolean;
    brandId: number;
    companyId: number;
    setCompanyInfo: () => void;
    setOpenYandexModal?: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line complexity
export function CatalogStatusColumn(props: PropsType): ReactElement | null {
    const {cclData, brandId, companyId, accessTransferRequired, setCompanyInfo, setOpenYandexModal} = props;
    const {user} = useUser();

    if (!cclData) {
        return null;
    }

    const isAccessTransferRequired = accessTransferRequired && cclData.catalogId === ProvidersIdsEnum.yandex;

    const status = getStatusByCclAndUser(cclData, user, isAccessTransferRequired);

    const isConfirmation = Boolean(cclData.verificationStatus) && status !== STATUS_TEXT_BY_CCL_STATUS.on_moderation;
    const isLinking = cclData.needAction && !isConfirmation && status !== STATUS_TEXT_BY_CCL_STATUS.on_moderation;
    const bindingRouteV2 = buildSourcesNeedActionRouteV2(cclData.catalogId, companyId, brandId);
    const confirmationRouteV2 = buildProviderConfirmationRouteV2(cclData.catalogId, companyId, false);

    // eslint-disable-next-line complexity
    function goToSourcesData() {
        if (isAccessTransferRequired && setOpenYandexModal) {
            return (
                <Button
                    className={styles.OpenModalButton}
                    onClick={() => {
                        setCompanyInfo();
                        setOpenYandexModal(true);
                    }}
                    type="link"
                >
                    <Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__GET_ACCESS" />
                </Button>
            );
        }

        if (isLinking) {
            return (
                cclData && (
                    <NavigationLink
                        to={bindingRouteV2 || buildSourcesNeedActionRoute(cclData.catalogId, companyId, brandId)}
                    >
                        <Locale stringKey="TEXT__GO" />
                    </NavigationLink>
                )
            );
        }

        if (isConfirmation) {
            return (
                cclData && (
                    <NavigationLink
                        to={
                            confirmationRouteV2 ||
                            buildProviderConfirmationRoute(
                                cclData.catalogId,
                                brandId,
                                companyId,
                                cclData.verificationStatus
                            )
                        }
                    >
                        <Locale stringKey="TEXT__GO" />
                    </NavigationLink>
                )
            );
        }

        return (
            cclData && (
                <NavigationLink
                    to={bindingRouteV2 || buildSourcesNeedActionRoute(cclData.catalogId, companyId, brandId)}
                >
                    <Locale stringKey="TEXT__GO" />
                </NavigationLink>
            )
        );
    }

    if (isLinking || isConfirmation) {
        const href = isLinking
            ? bindingRouteV2 || buildSourcesNeedActionRoute(cclData.catalogId, companyId, brandId)
            : confirmationRouteV2 ||
              buildProviderConfirmationRoute(cclData.catalogId, brandId, companyId, cclData.verificationStatus);

        return (
            <a href={href} rel="noreferrer" target="_blank">
                <StatusColumnPopover
                    cclData={cclData}
                    iconFromCompaniesPage={getStatus(cclData, isLinking, isAccessTransferRequired)}
                    isConfirmation={Boolean(isConfirmation)}
                    isLinking={Boolean(isLinking)}
                    syncButton={goToSourcesData()}
                />
            </a>
        );
    }

    if (cclData.stateStatus === CclStateStatusEnum.noLicense) {
        return (
            <Tooltip title={<Locale stringKey="SOURCES__STATUS__NO_LICENSE" />}>
                {getStatus(cclData, isLinking, isAccessTransferRequired)}
            </Tooltip>
        );
    }

    return (
        <StatusColumnPopover
            cclData={cclData}
            iconFromCompaniesPage={getStatus(cclData, isLinking, isAccessTransferRequired)}
            isConfirmation={Boolean(isConfirmation)}
            isLinking={Boolean(isLinking)}
            needYandexTransferAccount={isAccessTransferRequired}
            syncButton={goToSourcesData()}
        />
    );
}
