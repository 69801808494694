import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space, Tooltip} from 'antd';

import {Locale} from '../../../../../../../../provider/locale/localization';

import * as styles from './time-delete-button.scss';

type PropsType = {
    onClick: () => void;
};

export function TimeDeleteButton(props: PropsType): JSX.Element {
    const {onClick} = props;

    return (
        <Tooltip placement="bottom" title={<Locale stringKey="COMPANY_FORM__WORKING_TIME__REMOVE_DAYS" />}>
            <Button className={styles.TimeDeleteButton} onClick={onClick} type="link">
                <Space>
                    <FontAwesomeIcon className={styles.TimeDeleteButton_icon} icon={faTrash} />
                    <Locale stringKey="BUTTON__DELETE" />
                </Space>
            </Button>
        </Tooltip>
    );
}
