import {z as r} from 'zod';

import {CclCatalogStatusEnum, CclErrorEnum, CclStateStatusEnum} from '../../util/type';
import {CatalogNameType, CclStatusEnum} from '../ccl-statistic/ccl-statistic-type';
import {YandexSourcesStoriesResponseType} from '../yandex-stories/yandex-stories-type';

const cclDataBrandFromListSchema = r.object({
    id: r.number(),
    isTest: r.boolean(),
    name: r.string(),
});

const cclDataCatalogSchema = r.object({
    icon: r.string(),
    id: r.number(),
    isOauth: r.boolean(),
    logo: r.string(),
    name: r.string(),
    parentId: r.number().nullable(),
    type: r.literal('map').or(r.literal('navigator')),
});

const cclDataCompanyAddressSchema = r.object({
    city: r.string().nullable(),
    country: r.string().nullable(),
    countryCode: r.string().nullable(),
    description: r.string().nullable(),
    housenumber: r.string().nullable(),
    lat: r.number().nullable(),
    lon: r.number().nullable(),
    postcode: r.string().nullable(),
    region: r.string().nullable(),
    street: r.string().nullable(),
});

const cclDataCompanyCategorySchema = r.object({
    id: r.number(),
    isMain: r.boolean(),
    name: r.string(),
});

const cclDataCompanySchema = r.object({
    address: cclDataCompanyAddressSchema,
    categories: r.array(cclDataCompanyCategorySchema).optional(),
    code: r.string(),
    id: r.number(),
    name: r.string(),
});

export const cclDataSchema = r.object({
    brand: cclDataBrandFromListSchema,
    catalog: cclDataCatalogSchema,
    company: cclDataCompanySchema,
    controlPanelStatus: r.literal('sent').or(r.literal('synced')),
    id: r.number(),
    needAction: r.boolean(),
    originUrl: r.string().nullable(),
    rating: r.number().nullable(),
    stateStatus: r.nativeEnum(CclStateStatusEnum),
    verificationStatus: r.nativeEnum(CclCatalogStatusEnum).nullable(),
    catalogStatusDate: r.string().nullable(),
    errors: r.array(r.nativeEnum(CclErrorEnum)),
    isVerified: r.boolean(),
    transferRequired: r.boolean(),
    transferSend: r.boolean(),
    hiddenOnYandexMaps: r.boolean(),
});

export const companyCatalogSyncStatisticSchema = cclDataSchema.omit({
    brand: true,
    company: true,
    id: true,
    rating: true,
    verificationStatus: true,
});

export type CclDataType = r.infer<typeof cclDataSchema>;

export type CclTableDataType = CclDataType & {stories?: YandexSourcesStoriesResponseType};

export type CclCompanyStatsType = r.infer<typeof companyCatalogSyncStatisticSchema>;

export type CclFetchParametersType = {
    catalogType: CatalogNameType;
    status: CclStatusEnum | string | null;
    catalogIds: string;
    companyIds: Array<string>;
    filterKey: string | null;
    newCompanies: boolean;
    count: number;
    page: number;
    q?: string;
    accessTransferRequired?: boolean;
};
