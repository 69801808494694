import {Meta} from '../../component/meta/meta';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {Locale} from '../../provider/locale/locale';
import {useLocale} from '../../provider/locale/locale-hook';
import {LangKeyType} from '../../provider/locale/translation/type';
import {classNames} from '../../util/css';
import {BreadCrumbs} from '../bread-crumbs/bread-crumbs';
import {BreadCrumbItemType} from '../bread-crumbs/bread-crumbs-type';
import {Page} from '../page/page';
import {PageHeader} from '../page-header/page-header';

import {ActivationBanner} from './activation-banner';
import * as styles from './activation-page.scss';

type PropsType = {
    breadCrumbList: Array<BreadCrumbItemType>;
    title: string | JSX.Element;
    link?: string;
    className?: string;
    interestedMessageLangKey?: LangKeyType;
    hideMessageHeader?: boolean;
};

export function ActivationPage(props: PropsType): JSX.Element {
    const {className, breadCrumbList, title, link, interestedMessageLangKey, hideMessageHeader} = props;
    const {getLocalizedString} = useLocale();

    const {companyName} = useDomainConfig();

    return (
        <Page className={classNames(styles.activation_page, className)}>
            <Meta
                title={getLocalizedString('ACTIVATION_PAGE__EXPAND_THE_CAPABILITIES_OF_THE_ACCOUNT', {
                    companyName,
                })}
            />

            <BreadCrumbs list={breadCrumbList} />

            <PageHeader>
                {title}

                <span className={styles.activation_page__the_service_is_inactive}>
                    <Locale stringKey="ACTIVATION_PAGE__THE_SERVICE_IS_INACTIVE" />
                </span>
            </PageHeader>

            <ActivationBanner
                hideMessageHeader={hideMessageHeader}
                interestedMessageLangKey={interestedMessageLangKey}
                link={link}
            />
        </Page>
    );
}
