import {Fragment} from 'react';

import {ReviewDataChildType} from '../../../../../service/reviews/reviews-type';
import {NewAnswerItem} from '../../review-list-page-content/review-list/review/new-answer-item/new-answer-item';
import * as reviewAnswersStyles from '../review-answers.scss';

import {getAnswers} from './new-answers-bunch-helper';

type PropsType = {
    answerList: Array<ReviewDataChildType>;
    reviewId: number;
    createdInCatalog: string;
    isTestBrand: boolean;
    refreshReview: (reviewId: number) => Promise<unknown>;
    isFirst: boolean;
    replyTimeInCatalog: number;
    replyTimeInRd: number;
    isOnlyLastAnswer?: boolean;
    lastChangesData: string | null;
};

export function NewAnswersBunch(props: PropsType): JSX.Element | null {
    const {
        answerList,
        isTestBrand,
        reviewId,
        createdInCatalog,
        refreshReview,
        isFirst,
        isOnlyLastAnswer,
        replyTimeInCatalog,
        replyTimeInRd,
        lastChangesData,
    } = props;

    if (answerList.length > 0 && isOnlyLastAnswer) {
        const officialAnswers: Array<ReviewDataChildType> = getAnswers(answerList);

        const officialAnswersWithDate = officialAnswers.filter((answer) => answer.createdInCatalog);

        if (officialAnswersWithDate.length > 0) {
            officialAnswersWithDate.sort(
                (itemA: ReviewDataChildType, itemB: ReviewDataChildType) =>
                    new Date(itemB.createdInCatalog || '').getTime() - new Date(itemA.createdInCatalog || '').getTime()
            );

            const lastAnswer = officialAnswers[0];

            return lastAnswer ? (
                <li className={reviewAnswersStyles.ReviewAnswers_listItem}>
                    <NewAnswerItem
                        answer={lastAnswer}
                        isDialog={!isFirst}
                        isTestBrand={isTestBrand}
                        lastChangesData={lastChangesData}
                        refreshReview={refreshReview}
                        reviewId={reviewId}
                    />
                </li>
            ) : null;
        }

        if (officialAnswers[0]) {
            return (
                <li className={reviewAnswersStyles.ReviewAnswers_listItem}>
                    <NewAnswerItem
                        answer={officialAnswers[0]}
                        isDialog={!isFirst}
                        isTestBrand={isTestBrand}
                        lastChangesData={lastChangesData}
                        refreshReview={refreshReview}
                        reviewId={reviewId}
                    />
                </li>
            );
        }

        return null;
    }

    return (
        <>
            {answerList.map((answerData: ReviewDataChildType) => {
                return (
                    <Fragment key={`${answerData.replyId}-${answerData.commandId}`}>
                        <li className={reviewAnswersStyles.ReviewAnswers_listItem}>
                            <NewAnswerItem
                                answer={answerData}
                                isDialog={!isFirst}
                                isTestBrand={isTestBrand}
                                lastChangesData={lastChangesData}
                                refreshReview={refreshReview}
                                reviewId={reviewId}
                            />
                            {answerData.children.length > 0 && (
                                <ul className={reviewAnswersStyles.ReviewAnswers_nested}>
                                    <NewAnswersBunch
                                        answerList={answerData.children}
                                        createdInCatalog={createdInCatalog}
                                        isFirst={false}
                                        isTestBrand={isTestBrand}
                                        lastChangesData={lastChangesData}
                                        refreshReview={refreshReview}
                                        replyTimeInCatalog={replyTimeInCatalog}
                                        replyTimeInRd={replyTimeInRd}
                                        reviewId={reviewId}
                                    />
                                </ul>
                            )}
                        </li>
                    </Fragment>
                );
            })}
        </>
    );
}
