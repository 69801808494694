import {faComment} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Locale} from '../../../../../provider/locale/locale';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './created-log.scss';

type PropsType = {
    createdAt: string;
    author: string | null;
};

export function CreatedLog(props: PropsType): JSX.Element | null {
    const {author, createdAt} = props;

    const {getFormattedDateTime} = useFormat();

    const formattedDate = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              [TimeSizeEnum.hour]: 'numeric',
              [TimeSizeEnum.minute]: 'numeric',
          })
        : null;

    return (
        <div className={styles.CreatedLog}>
            <div className={styles.CreatedLog_data}>
                <FontAwesomeIcon className={styles.CreatedLog_icon} icon={faComment} />
                {author ? (
                    <Locale
                        stringKey="REVIEW_ITEM_PAGE__ACTIVITY__LOGS__CREATED__BY_USER"
                        valueMap={{user: <span className={styles.CreatedLog_user}>{author}</span>}}
                    />
                ) : (
                    <Locale stringKey="REVIEW_ITEM_PAGE__ACTIVITY__LOGS__CREATED" />
                )}
            </div>
            <span className={styles.CreatedLog_date}>{formattedDate}</span>
        </div>
    );
}
