export const enUsersTranslations = {
    USERS__TITLE: 'User management',
    USERS__BREADCRUMB: 'Users',
    USERS__HEADER__YOU: '(This is you.)',
    USERS__HEADER__BUTTON__ADD: 'Add users',
    USERS__HEADER__STATISTIC__CREATED_BY_YOU: 'Created by you',
    USERS__HEADER__STATISTIC__VISIBLE: 'Available / total',
    USERS__HEADER__LIMITS__TITLE: 'User limits',
    USERS__HEADER__LIMITS__DESCRIPTION: 'How many more users can be created and how many were available in total.',
    USERS__HEADER__LIMITS__DESCRIPTION__OWNER:
        'The number of available licenses per user and licenses purchased initially.',
    USERS__TABLE__TITLE: 'Users created by you',
    USERS__TABLE__TITLE__OWNER: 'All users',
    USERS__TABLE__SEARCH__PLACEHOLDER: 'Search by name or email...',
    USERS__TABLE__SELECTION: 'Selected users: {selected} / {total}',
    USERS__TABLE__BRANDS__TITLE: 'Available brands',
    USERS__TABLE__BRANDS__MORE: 'More {count} brands...',
    USERS__TABLE__COLUMN__EMAIL: 'Email',
    USERS__TABLE__COLUMN__ROLE: 'Role',
    USERS__TABLE__COLUMN__COMPANIES: 'Companies',
    USERS__TABLE__COLUMN__BRANDS: 'Brands',
    USERS__TABLE__COLUMN__GROUPS: 'Company groups',
    USERS__TABLE__COLUMN__STATUS: 'Status',
    USERS__TABLE__COLUMN__CREATED_AT: 'Added',
    USERS__TABLE__COLUMN__CREATOR: 'Added by',
    USERS__TABLE__COLUMN__LAST_UPDATE: 'Last activity',
    USERS__TABLE__COLUMN__ACTIONS: 'Actions',
    USERS__TABLE__STATUS__ACTIVE: 'Active',
    USERS__TABLE__STATUS__PENDING: 'Waiting',
    USERS__TABLE__STATUS__BLOCKED: 'Blocked',
    USERS__TABLE__DELETE_MODAL__TITLE__SINGULAR: 'Remove accesses for user?',
    USERS__TABLE__DELETE_MODAL__TITLE__MANY: 'Remove users?',
    USERS__TABLE__DELETE_MODAL__DESCRIPTION__SINGULAR:
        'All accesses granted to {companyName} for the user will be revoked: {email}',
    USERS__TABLE__DELETE_MODAL__DESCRIPTION__MANY:
        'All accesses granted to {companyName} for {count} users will be revoked.',
    USERS__TABLE__DELETE_SNACKBAR__TITLE__SINGULAR: 'User deleted',
    USERS__TABLE__DELETE_SNACKBAR__TITLE__MANY: 'Users deleted',
    USERS__TABLE__DELETE_SNACKBAR__DESCRIPTION__SINGULAR: 'You have removed accesses for the user {email}',
    USERS__TABLE__DELETE_SNACKBAR__DESCRIPTION__MANY: 'You have removed accesses for {count} users.',
    USERS__FORM__CREATE_USER: 'Create users?',
    USERS__FORM__ADD_USERS: 'Adding users',
    USERS__FORM__EDIT_USER: 'Edit user',
    USERS__FORM__SELECT_ROLE__LABEL: 'Role',
    USERS__FORM__SELECT_ROLE__PLACEHOLDER: 'Select a role',
    USERS__FORM__OWNER_ALERT__DESCRIPTION:
        'The owner role gives the user access to all companies and all sections, including the ability to manage other users.',
    USERS__FORM__OWNER_ALERT__MESSAGE: 'The owner has full access to {companyName}',
    USERS__FORM__EMAIL_LINK_ALERT:
        'Your colleagues at the specified addresses will receive messages with a registration link.',
    USERS__FORM__ALERT__DOCS_LINK: 'User role descriptions are available in {link}',
    USERS__FORM__ALERT__DOCS_LINK_NAME: 'Knowledge base',
    USERS__FORM__SAVE_USERS: 'Save users',
    USERS__FORM__CONFIRM_MODAL__CONTENT__FEW: '{count} invitations will be sent to the role: "{role}"',
    USERS__FORM__CONFIRM_MODAL__CONTENT__SINGLE: '{count} invitation will be sent to the role: "{role}"',
    USERS__FORM__CONFIRM_MODAL__CONTENT__MANY: '{count} invitations will be sent to the role: "{role}"',
    USERS__FORM__SNACKBAR__SUCCESS_MESSAGE__SINGLE: 'Invitation sent',
    USERS__FORM__SNACKBAR__SUCCESS_MESSAGE__MANY: 'Invitations sent',
    USERS__FORM__SNACKBAR__SUCCESS_DESCRIPTION__SINGLE:
        'Your colleague will be able to get started on the link in the letter.',
    USERS__FORM__SNACKBAR__SUCCESS_DESCRIPTION__MANY:
        'Your colleagues will be able to get started on the link in the letter.',
    USERS__FORM__SNACKBAR__SUCCESS_EDIT_DESCRIPTION: 'You changed accesses for the user {email}',
    USERS__FORM__EMAILS_FORM__LABEL: 'E-mail address',
    USERS__FORM__EMAILS_FORM__ADD_BUTTON: 'Add Address',
    USERS__FORM__EMAILS_FORM__LICENSE_COUNT: 'Available licenses: {count}',
    USERS__FORM__EMAILS_FORM__ERROR__USER_ALREADY_EXIST: 'A user with this email already exists',
    USERS__FORM__TAB__ACCESS_RULES: 'Access rules',
    USERS__FORM__TAB__USER_INFO__CREATE: 'Created by:',
    USERS__FORM__TAB__USER_INFO__UPDATE: 'Edited:',
    USERS__FORM__TAB__ACCESS_RULES__BRANDS__LABEL: 'Access to brands',
    USERS__FORM__TAB__ACCESS_RULES__GROUPS__LABEL: 'Access to company groups',
    USERS__FORM__TAB__COMPANIES: 'Individual companies',
    USERS__FORM__SOURCES__LABEL: 'Review sources',
    USERS__FORM__SOURCES__MAX_TAG_PLACEHOLDER: 'All sources',
    USERS__FORM__SOURCES__TOOLTIP:
        'Specify which review sources users will have access to. If "All sources" is selected, users will be able to view and interact with reviews from all available sources. You can restrict access by selecting only the necessary sources.',
    USERS__TABLE__DISABLE_ACTION__TOOLTIP:
        'Insufficient permissions to perform actions. You can only edit and delete users you have created.',
};
