import {Select} from 'antd';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {LocalPackSearchServiceType} from '../../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {IdNumberType} from '../../../../../util/type';
import {LOCALPACK_CATALOG_LOCALIZATION_MAP, LOCALPACK_SERVICE_LOCALIZATION_MAP} from '../../local-pack-const';

type PropsType = {
    value?: Array<IdNumberType>;
    onChange?: (newValue: Array<IdNumberType>) => void;
    availableCatalogs?: Array<LocalPackSearchServiceType> | null;
    loading: boolean;
    placeholder?: string;
    catalogOptionDisabled?: (service: LocalPackSearchServiceType) => boolean;
};

export function SelectServicesField(props: PropsType): JSX.Element {
    const {value, onChange, availableCatalogs = [], loading, placeholder, catalogOptionDisabled = () => false} = props;

    const {getLocalizedString} = useLocale();

    function getOptionLabel(service: LocalPackSearchServiceType) {
        const catalog = getLocalizedString(LOCALPACK_CATALOG_LOCALIZATION_MAP[service.provider]);
        const serviceType = getLocalizedString(LOCALPACK_SERVICE_LOCALIZATION_MAP[service.service]);

        return `${catalog} (${serviceType})`;
    }

    return (
        <Select
            loading={loading}
            mode="multiple"
            onChange={(selectedCatalogIds: Array<number>) => {
                onChange?.(
                    selectedCatalogIds.map((catalogId) => ({
                        id: catalogId,
                    }))
                );
            }}
            options={availableCatalogs?.map(
                (catalog) =>
                    ({
                        label: getOptionLabel(catalog),
                        value: catalog.id,
                        disabled: catalogOptionDisabled(catalog),
                    } || [])
            )}
            placeholder={placeholder}
            value={value?.map((valueItem) => valueItem.id)}
        />
    );
}
