import {DefaultDoughnutChartTooltipPropsType} from '../../../../../../component/chart-tooltip/default-doughnut-chart-tooltip';
import {getDefaultReactDoughnutTooltipOptions} from '../../../../../../layout/chart/chart-const';
import {doughnutChartOptions} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart-const';
import {ChartJsDoughnutOptionsType} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';

export function getConcordanceChartOptions(
    options: Partial<ChartJsDoughnutOptionsType>,
    tooltipOptions: Omit<DefaultDoughnutChartTooltipPropsType, 'currentIndex'>
): ChartJsDoughnutOptionsType {
    return {
        ...doughnutChartOptions,
        ...options,
        plugins: {
            ...doughnutChartOptions.plugins,
            tooltip: getDefaultReactDoughnutTooltipOptions(tooltipOptions),
        },
    };
}
