import {ShortLocaleNameEnum} from '../../../../provider/locale/locale-context-type';

import phoneImage1En from './images/post-landing-phone-img-1-en.png';
import phoneImage1Ru from './images/post-landing-phone-img-1-ru.png';
import phoneImage2En from './images/post-landing-phone-img-2-en.png';
import phoneImage2Ru from './images/post-landing-phone-img-2-ru.png';
import phoneImage3Ru from './images/post-landing-phone-img-3-ru.png';
import postsLandingImage1En from './images/posts-landing-image-1-en.png';
import postsLandingImage1Ru from './images/posts-landing-image-1-ru.png';
import postsLandingImage3En from './images/posts-landing-image-3-en.png';
import postsLandingImage3Ru from './images/posts-landing-image-3-ru.png';

export function getCarouselImages(shortLocaleName: ShortLocaleNameEnum): [string, ...Array<string>] {
    if (shortLocaleName === ShortLocaleNameEnum.ru || shortLocaleName === ShortLocaleNameEnum.kk) {
        return [phoneImage1Ru, phoneImage2Ru, phoneImage3Ru];
    }

    return [phoneImage1En, phoneImage2En];
}

export function getPostsLandingImage(shortLocaleName: ShortLocaleNameEnum, cardIndex: 1 | 3): string {
    const ruImages = {
        1: postsLandingImage1Ru,
        3: postsLandingImage3Ru,
    };

    const enImages = {
        1: postsLandingImage1En,
        3: postsLandingImage3En,
    };

    return [ShortLocaleNameEnum.ru, ShortLocaleNameEnum.kk].includes(shortLocaleName)
        ? ruImages[cardIndex]
        : enImages[cardIndex];
}
