import {Tag} from 'antd';

import {Locale} from '../../../../provider/locale/localization';
import {PostStatusEnum} from '../../../../service/posts/posts-types';

import {MAIN_POST_STATUS_OPTIONS} from './post-status-const';

type PropsType = {
    status: PostStatusEnum | null;
};

export function MainPostStatus(props: PropsType): JSX.Element {
    const {status} = props;

    if (!status) {
        return <span>—</span>;
    }

    const postStatusOption = MAIN_POST_STATUS_OPTIONS[status];

    return (
        <Tag color={postStatusOption.color}>
            <Locale stringKey={postStatusOption.name} />
        </Tag>
    );
}
