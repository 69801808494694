import {TextAreaRef} from 'antd/lib/input/TextArea';
import {isNull} from 'lodash';
import {Dispatch, RefObject, SetStateAction, useState} from 'react';

import {useUser} from '../../../../../../../provider/user/user-hook';
import {ResponseTemplateDataType} from '../../../../../../../service/reviews/reviews-response-templates';
import {ReviewDataType} from '../../../../../../../service/reviews/reviews-type';
import {IdNumberType} from '../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';

import {NewAiReviewGeneratorButton} from './ai-review-generator/new-ai-review-generator-button';
import {ReplyTypeEnum} from './reply-form-const';

type PropsType = {
    reviewData: ReviewDataType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    replyAreaRef: RefObject<TextAreaRef>;
    shouldCollapseOnClose?: boolean;
    rows?: number;
    onFocus?: () => void;
    isFocus?: boolean;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isGptSuggestSupported?: boolean;
};

export function NewReplyForm(props: PropsType): JSX.Element | null {
    const {reviewData, refreshReview, replyAreaRef, shouldCollapseOnClose, isGptSuggestSupported, isOpen, setIsOpen} =
        props;
    const {brand, id: reviewId, ableToReply, children, comment} = reviewData;

    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );

    const [answerText, setAnswerText] = useState<string>('');
    const [answerType, setAnswerType] = useState<string>(ReplyTypeEnum.ManualReply);

    const {user} = useUser();

    function isShowGenerateButton() {
        if (isNull(comment) || comment?.length < 1 || !isGptSuggestSupported) {
            return false;
        }

        if (user?.isTestUser || user?.isDemoUser) {
            return !children || children?.length === 0;
        }

        return ableToReply && (!children || children?.length === 0);
    }

    return (
        <>
            {isOpen && (
                <NewAiReviewGeneratorButton
                    answerText={answerText}
                    answerType={answerType}
                    brand={brand}
                    comment={comment}
                    isShowGenerateButton={isShowGenerateButton()}
                    refreshReview={refreshReview}
                    replyAreaRef={replyAreaRef}
                    reviewId={reviewId}
                    setAnswerText={setAnswerText}
                    setAnswerType={setAnswerType}
                    setIsOpen={setIsOpen}
                    setIsSelectTemplatePopupOpen={setIsSelectTemplatePopupOpen}
                    shouldCollapseOnClose={shouldCollapseOnClose}
                />
            )}
            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        setIsSelectTemplatePopupOpen(false);

                        const [selectedAutoReply] = selectedResponseTemplateList;

                        if (!selectedAutoReply) {
                            return;
                        }

                        const {message} = selectedAutoReply;

                        setAnswerText(message);
                        setAnswerType(ReplyTypeEnum.TemplateReply);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </>
    );
}
