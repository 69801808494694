import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from 'antd';

import {useDocumentationLinks} from '../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {
    GoogleAccountType,
    GoogleAccountTypeEnum,
} from '../../../../../../../service/google/accounts/google-accounts-type';

import * as styles from './only-personal-alert.scss';

type PropsType = {
    accounts: Array<GoogleAccountType>;
};

export function OnlyPersonalAlert(props: PropsType): JSX.Element | null {
    const {accounts} = props;

    const documentationLinks = useDocumentationLinks();

    if (accounts.length === 0) {
        return null;
    }

    if (!accounts.every(({accountType}) => accountType === GoogleAccountTypeEnum.Personal)) {
        return null;
    }

    return (
        <Alert
            className={styles.OnlyPersonalAlert}
            message={
                <div className={styles.OnlyPersonalAlert_message}>
                    <span>
                        <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__ONLY_PERSONAL" />
                    </span>

                    {documentationLinks.google?.addressGroup && (
                        <a href={documentationLinks.google?.addressGroup} rel="noreferrer" target="_blank">
                            <Button icon={<FontAwesomeIcon icon={faExternalLink} />} size="small" type="link">
                                <span>
                                    <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__LINK" />
                                </span>
                            </Button>
                        </a>
                    )}
                </div>
            }
            type="info"
        />
    );
}
