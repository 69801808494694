import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {fakesUrl} from './fakes-const';

export enum FakesStatisticsEnum {
    All = 'all',
    ClaimRejected = 'claimRejected',
    DuplicatesClaimAccepted = 'duplicatesClaimAccepted',
    FakesClaimAccepted = 'fakesClaimAccepted',
    Hidden = 'hidden',
    TotalSearches = 'totalSearchCount',
    FilteredCompanies = 'filtered',
}

const fakesStatisticsSchema = r.record(r.nativeEnum(FakesStatisticsEnum), r.number());

type FakesStatisticsType = r.infer<typeof fakesStatisticsSchema>;

type FakesStatisticsOptionsType = {
    fromDate?: string;
    toDate?: string;
    brandIds?: Array<number>;
};

function getFakesStatisticsUrl(options: FakesStatisticsOptionsType) {
    return `${fakesUrl}/statistics/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

function fetchFakesStatistics(options: FakesStatisticsOptionsType): Promise<FakesStatisticsType> {
    return fetchAndDeserialize(getFakesStatisticsUrl(options), fakesStatisticsSchema);
}

export function useFakesStatistics(options: FakesStatisticsOptionsType = {}): UseQueryResult<FakesStatisticsType> {
    return useQuery([getFakesStatisticsUrl(options)], () => fetchFakesStatistics(options), {cacheTime: 0});
}
