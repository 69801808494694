import {useEffect} from 'react';

import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';

import {setMetaTitle} from './meta-helper';

type PropsType = {
    title?: string;
};

export function Meta(props: PropsType): null {
    const {title} = props;

    const {logoShortSrc} = useDomainConfig();

    useEffect(() => {
        const faviconElement: HTMLLinkElement | null = document.querySelector('#favicon');

        if (faviconElement) {
            faviconElement.setAttribute('href', logoShortSrc);
        }
    }, [logoShortSrc]);

    useEffect(() => {
        if (typeof title === 'string') {
            setMetaTitle(title);
        }
    }, [title]);

    return null;
}
