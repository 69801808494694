import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {SyntheticEvent, useCallback, useState} from 'react';

import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {useQuestionsReply} from '../../../../../service/questions/questions-hook';
import {QuestionDataAnswerType, QuestionDataType} from '../../../../../service/questions/questions-type';
import {classNames} from '../../../../../util/css';
import {ApiError} from '../../../../../util/error';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {toTrimmedString} from '../../../../../util/string';

import {AnswerItem} from './answer-item/answer-item';
import * as styles from './questions-and-answers-list-item.scss';

const {TextArea} = Input;

type PropsType = {
    question: QuestionDataType;
    onAnswerAdded: () => void;
    className?: string;
};

export function QuestionsAndAnswersListItem(props: PropsType): JSX.Element {
    const {className, question, onAnswerAdded} = props;
    const {
        id,
        brand,
        company,
        answers,
        date_created: dateCreated,
        url,
        author,
        text,
        catalog,
        can_reply: canReply,
    } = question;
    const {address: companyAddress} = company;
    const [isAnswerActive, setIsAnswerActive] = useState<boolean>(false);
    const [answerText, setAnswerText] = useState<string>('');
    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const {sendReply, isInProgress} = useQuestionsReply();
    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    function handleSubmitReply() {
        sendReply(id, answerText)
            .then(() => {
                setIsAnswerActive(false);
                onAnswerAdded();
            })
            .catch((error: unknown) => {
                if (error instanceof ApiError && Boolean(error.message)) {
                    snackbar.error(error.message);
                } else {
                    snackbar.error(<Locale stringKey="QUESTIONS_N_ANSWERS__REPLY_ERROR" />);
                }

                console.error(error);
            });
    }

    const headerText: string = [
        brand.name,
        companyAddress.region,
        companyAddress.city,
        companyAddress.street,
        companyAddress.housenumber,
    ]
        .map(toTrimmedString)
        .filter(Boolean)
        .join(', ');

    const canSumbitReply = answerText.trim().length > 0;

    const renderAnswerItem = useCallback((answerData: QuestionDataAnswerType): JSX.Element => {
        return (
            <li className={styles.questions_and_answers_list_item__answer_list_item} key={answerData.id}>
                <AnswerItem answer={answerData} />
            </li>
        );
    }, []);

    return (
        <div className={classNames(styles.questions_and_answers_list_item, className)}>
            <div className={styles.questions_and_answers_list_item__header}>
                <img
                    alt={getCatalogName(catalog.id)}
                    className={styles.questions_and_answers_list_item__header_icon}
                    src={getCatalogLogo(catalog.id)}
                    title={getCatalogName(catalog.id)}
                />

                <p className={styles.questions_and_answers_list_item__header_text}>{headerText}</p>
            </div>

            <div className={styles.questions_and_answers_list_item__short_data}>
                <p className={styles.questions_and_answers_list_item__short_data__name}>{author}</p>

                <a
                    className={styles.questions_and_answers_list_item__short_data__link}
                    href={url}
                    rel="noreferrer"
                    target="_blank"
                >
                    <FontAwesomeIcon
                        className={styles.questions_and_answers_list_item__short_data__link_image}
                        icon={faExternalLink}
                    />
                </a>

                <p className={styles.questions_and_answers_list_item__short_data__date}>
                    {getFormattedDateTime(new Date(dateCreated), {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}
                </p>
            </div>

            <p className={styles.questions_and_answers_list_item__question_text}>{text}</p>

            <div className={styles.questions_and_answers_list_item__answer}>
                {isAnswerActive && (
                    <>
                        <TextArea
                            className={styles.questions_and_answers_list_item__textarea}
                            defaultValue={answerText}
                            onInput={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                                setAnswerText(event.currentTarget.value);
                            }}
                            placeholder={getLocalizedString('QUESTIONS_N_ANSWERS__ENTER_YOUR_REPLY_TEXT')}
                            rows={3}
                            size="large"
                        />

                        <div className={styles.questions_and_answers_list_item__button_wrapper}>
                            <Button
                                disabled={!canSumbitReply}
                                loading={isInProgress}
                                onClick={handleSubmitReply}
                                type="primary"
                            >
                                <Locale stringKey="QUESTIONS_N_ANSWERS__REPLY" />
                            </Button>

                            <Button onClick={() => setIsAnswerActive(false)}>
                                <Locale stringKey="BUTTON__CANCEL" />
                            </Button>
                        </div>
                    </>
                )}

                {!isAnswerActive && canReply && (
                    <div className={styles.questions_and_answers_list_item__button_wrapper}>
                        <Button onClick={() => setIsAnswerActive(true)}>
                            <Locale stringKey="QUESTIONS_N_ANSWERS__REPLY" />
                        </Button>
                    </div>
                )}
            </div>

            {answers.length > 0 && (
                <ul className={styles.questions_and_answers_list_item__answer_list}>{answers.map(renderAnswerItem)}</ul>
            )}
        </div>
    );
}
