import {formatAddress} from '../../../../../../service/address/address-helper';
import {ReviewCustomCompanyType} from '../../../../../../service/reviews/reviews-custom-compamies';

import * as styles from './company-select-active-option.scss';

type PropsType = {
    company: ReviewCustomCompanyType;
};

export function CompanySelectActiveOption(props: PropsType): JSX.Element {
    const {company} = props;

    return (
        <>
            {company.name}{' '}
            <span className={styles.company_select__active_address}>{formatAddress(company.address)}</span>
        </>
    );
}
