import {deserializeV2, fetchAndDeserialize} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';
import {generateResponseSchema, PaginatedResponseType} from '../api/api-type';

import {CclCompanyStatsType, cclDataSchema, CclDataType, companyCatalogSyncStatisticSchema} from './ccl-type';

export async function fetchCclData(url: string): Promise<PaginatedResponseType<CclDataType>> {
    return fetchAndDeserialize<PaginatedResponseType<CclDataType>>(url, generateResponseSchema(cclDataSchema), {
        fetchOptions: {skipCache: true},
    });
}

export async function fetchCompanyCatalogSyncStatistic(companyId: number): Promise<Array<CclCompanyStatsType>> {
    const url = `${rootApiUrl}/v2/ccl/catalog_sync_statistic/${companyId}/`;
    const result = await fetchX(url);

    return deserializeV2<Array<CclCompanyStatsType>>(url, companyCatalogSyncStatisticSchema.array(), result);
}
