import {Input} from 'antd';
import {castArray} from 'lodash';
import {SyntheticEvent, useMemo} from 'react';

import {AddButton} from '../../../../../../layout/add-button/add-button';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum, CompanyErrorsType} from '../../../../../../service/company/company-type';
import {getRandomString} from '../../../../../../util/string';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';
import {InputString} from '../phones-form-item/input-string/input-string';
import {InputStringType} from '../phones-form-item/input-string/input-string-type';
import {WEBSITE_PROTOCOL} from '../websites-form-item/websites-form-item-const';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.socials> & {
    containerClassName?: string;
    addButtonClassName?: string;
    inputClassName?: string;
    serverError?: CompanyErrorsType['socials'];
};

export function SocialsFormItem(props: PropsType): JSX.Element {
    const {
        value: socials,
        onChange,
        containerClassName,
        addButtonClassName,
        inputClassName,
        serverError,
        errorMessage,
    } = props;

    const {getLocalizedString} = useLocale();

    const isDisabledAddButton = useMemo(() => {
        return socials[0]?.value.length === 0;
    }, [socials]);

    function handleInput(social: InputStringType, index: number) {
        return function input(event: SyntheticEvent<HTMLInputElement>) {
            const enteredText = event.currentTarget.value;

            const newValue: InputStringType = {
                ...social,
                value: enteredText.startsWith(WEBSITE_PROTOCOL)
                    ? enteredText.replace(WEBSITE_PROTOCOL, '')
                    : enteredText,
            };

            const newItemList = [...socials];

            newItemList[index] = newValue;

            onChange(newItemList);
        };
    }

    // TODO: after new design and refactoring there should be only one 'errorMessage' prop
    function getErrorMessages(index: number): Array<string> {
        const serverMessage = serverError?.[index];
        const clientMessage = errorMessage?.[index];

        if (serverMessage) {
            return serverMessage.url;
        }

        if (clientMessage) {
            return castArray(clientMessage);
        }

        return [];
    }

    return (
        <div className={containerClassName}>
            {socials.map((social: InputStringType, index: number): JSX.Element => {
                const errorMessages = getErrorMessages(index);

                return (
                    <InputString
                        additionalInfo={{
                            content: <Locale stringKey="CONTACTS__INFO__MAIN_SOCIAL_NETWORK" />,
                        }}
                        addonBefore={WEBSITE_PROTOCOL}
                        className={inputClassName}
                        errorMessages={errorMessages}
                        hasError={errorMessages.length > 0}
                        hasMainItem={false}
                        inputComponent={
                            <Input
                                addonBefore={WEBSITE_PROTOCOL}
                                onInput={handleInput(social, index)}
                                placeholder={getLocalizedString('CONTACTS__PLACEHOLDER__SOCIAL_NETWORKS')}
                                size="large"
                                value={social.value}
                            />
                        }
                        item={social}
                        itemList={socials}
                        key={social.id}
                        renderRemovedItemText={(value: string): JSX.Element => (
                            <Locale stringKey="CONTACTS__INFO__REMOVED_LINK" valueMap={{value}} />
                        )}
                        setItemList={onChange}
                    />
                );
            })}

            <AddButton
                className={addButtonClassName}
                isDisabled={isDisabledAddButton}
                onClick={() => onChange([...socials, {value: '', id: getRandomString()}])}
                type="link"
            >
                <Locale stringKey="BUTTON__ADD" />
            </AddButton>
        </div>
    );
}
