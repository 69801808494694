import {getIsoYyyyMmDdString} from '../../util/date';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {ObjectToUrlParametersType} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';

import {
    FetchQuestionsOptionType,
    QuestionDataType,
    QuestionReplyType,
    QuestionsStatisticType,
    ReplierDataType,
} from './questions-type';

// https://cp.rocketdata.io/op/api/v1/questions/?count=10&filter=%7B%7D&group=%7B%7D&page=1&sorting=%7B%7D
export function fetchQuestions(
    option: RequestOptionsType,
    parameters: FetchQuestionsOptionType,
    mainFilterKey: string
): Promise<PaginatedResponseType<QuestionDataType>> {
    const {hasOwnerAnswer, employerId, dateAfter, dateBefore} = parameters;

    const parametersObject: ObjectToUrlParametersType = {
        ...option,
        filter_key: mainFilterKey,
        has_owner_answer: hasOwnerAnswer,
        replier_id: employerId,
        // WARNING: we have some problems into backend, 'date_after' should de before 'date_before'
        date_after: dateBefore && getIsoYyyyMmDdString(dateBefore),
        date_before: dateAfter && getIsoYyyyMmDdString(dateAfter),
    };

    return fetchX<PaginatedResponseType<QuestionDataType>>(
        // filter: {}, group: {}, sorting: {}
        `${rootApiUrl}/v1/questions/?${objectToUrlParameters(parametersObject)}`
    );
}

export function fetchQuestionRepliers(): Promise<Array<ReplierDataType>> {
    return fetchX<Array<ReplierDataType>>(`${rootApiUrl}/v1/questions/repliers/`);
}

export function fetchQuestionsStatistic(
    parameters: FetchQuestionsOptionType,
    mainFilterKey: string
): Promise<QuestionsStatisticType> {
    const {hasOwnerAnswer, employerId, dateAfter, dateBefore} = parameters;

    const parametersObject: ObjectToUrlParametersType = {
        filter_key: mainFilterKey,
        has_owner_answer: hasOwnerAnswer,
        replier_id: employerId,
        // WARNING: we have some problems into backend, 'date_after' should de before 'date_before'
        date_after: dateBefore && getIsoYyyyMmDdString(dateBefore),
        date_before: dateAfter && getIsoYyyyMmDdString(dateAfter),
    };

    return fetchX<QuestionsStatisticType>(
        `${rootApiUrl}/v1/questions/statistic/?${objectToUrlParameters(parametersObject)}`
    );
}

export function sendReply(id: number, reply: string): Promise<QuestionReplyType> {
    return fetchX<QuestionReplyType>(rootApiUrl + `/v1/questions/${id}/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({text: reply}),
    });
}
