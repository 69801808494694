import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableColumnsType} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {SourceLinkStatusEnum} from '../../../../../../../service/source-settings/source-links/source-links-type';
import {getEnumValue} from '../../../../../../../util/enum';
import {ActionsTableCell} from '../../actions-table-cell/actions-table-cell';
import {BrandsOrCompaniesCell} from '../../brands-or-companies-cell/brands-or-companies-cell';
import {StatusCell} from '../../status-table-cell/status-cell';

import {statusFilterKey} from './accounts-table-const';
import {SourceAccountsTableType} from './accounts-table-type';
import * as styles from './accounts-table.scss';

export function convertToLinkStatusList(list: Array<unknown>): Array<SourceLinkStatusEnum> {
    return list.filter((value): value is SourceLinkStatusEnum =>
        Boolean(getEnumValue<SourceLinkStatusEnum>(SourceLinkStatusEnum, value))
    );
}

export function getAccountsTableColumns(
    getFormattedDateTime: (date: Date | number) => string
): TableColumnsType<SourceAccountsTableType> {
    return [
        {
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__LOGIN" />,
            dataIndex: 'account',
            key: 'account',
            render: (_value: unknown, {login}: SourceAccountsTableType) => (
                <>
                    <FontAwesomeIcon className={styles.AccountsTable_userIcon} icon={faUser} />
                    <Text bolder>{login}</Text>
                </>
            ),
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__BRANDS_COMPANIES" />,
            key: 'brand',
            render: (
                _value: unknown,
                {brandsCount, companiesCount, limitedCompaniesNames, limitedBrandsNames}: SourceAccountsTableType
            ) => (
                <BrandsOrCompaniesCell
                    brandsCount={brandsCount}
                    companiesCount={companiesCount}
                    limitedBrandsNames={limitedBrandsNames}
                    limitedCompaniesNames={limitedCompaniesNames}
                />
            ),
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__STATUS" />,
            key: statusFilterKey,
            filters: [
                {
                    text: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCED" />,
                    value: SourceLinkStatusEnum.Synced,
                },
                {
                    text: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCHRONIZATION" />,
                    value: SourceLinkStatusEnum.Synchronization,
                },
                {
                    text: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__FAIL" />,
                    value: SourceLinkStatusEnum.Fail,
                },
            ],
            render: (_value: unknown, {status, errorText}: SourceAccountsTableType) => (
                <StatusCell errorText={errorText} status={status} />
            ),
        },
        {
            align: 'center',
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__DATE" />,
            key: 'date',
            render: (_value: unknown, {updatedAt}: SourceAccountsTableType) =>
                getFormattedDateTime(new Date(updatedAt || '')),
        },
        {
            align: 'center',
            title: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__ACTIONS" />,
            key: 'actions',
            render: (_value: unknown, entity: SourceAccountsTableType) => (
                <ActionsTableCell>{entity.actions}</ActionsTableCell>
            ),
        },
    ];
}
