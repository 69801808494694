import {z as r} from 'zod';

import {
    CreateProductGroupFormFieldEnum,
    ProductGroupFormType,
    ProductsServiceTypeEnum,
} from '../../page/main/price/components/product-group-form/product-group-form-type';
import {shortCatalogSchema, ShortCatalogType} from '../../provider/catalogs/catalogs-type';
import {CursorPaginationHookType} from '../../util/cursor-pagination/cursor-pagination-type';
import {cursorPaginationResponseSchemaFactory} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';

export enum ProductGroupTypeEnum {
    Manual = 'manual_input',
    File = 'csv_xls_upload_on_site',
    Feed = 'yml_feed',
    Iiko = 'iiko',
}

export enum ProductGroupStatusEnum {
    New = 'new',
    Processing = 'processing',
    Failed = 'error',
    Ok = 'finished',
    UploadError = 'upload_error',
}

const productGroupListItemSchema = r.object({
    id: r.number(),
    source: r.nativeEnum(ProductGroupTypeEnum),
    name: r.string(),
    productsCount: r.number().nullable().optional(),
    lastImportDate: r.string().nullable(),
    catalogs: r.array(shortCatalogSchema.omit({website: true})),
    status: r.nativeEnum(ProductGroupStatusEnum),
    hasCatalogValidationErrors: r.boolean(),
    editable: r.boolean(),
});

export type ProductGroupListItemType = r.infer<typeof productGroupListItemSchema>;

export type ProductGroupsHookType = UseHookType<Array<ProductGroupListItemType>> & {
    remove: (productGroupId: number) => Promise<void>;
    refreshId: string;
    cursorPagination: CursorPaginationHookType;
};

export type ProductAvailableCatalogsHookType = Omit<UseHookType<Array<ShortCatalogType>>, 'reset'>;

export const googleServiceCategorySchema = r.object({
    id: r.string(),
    name: r.string(),
});

export type GoogleServiceCategoryType = r.infer<typeof googleServiceCategorySchema>;

export type GoogleServiceCategoriesHookType = Omit<UseHookType<Array<GoogleServiceCategoryType>>, 'reset'> & {
    loadServiceCategories: (companySelectorId: string) => Promise<Array<GoogleServiceCategoryType>>;
    isInitialized: boolean;
};

export const productsFileSchema = r.object({
    id: r.number(),
    sourceFile: r.string(),
});

export type ProductsFileType = r.infer<typeof productsFileSchema>;

export const productsFeedSchema = r.object({
    id: r.number(),
    sourceFile: r.string(),
});

export enum ProductsFeedValidationStatusEnum {
    Available = 'available',
    Forbidden = 'forbidden',
    Unauthorized = 'unauthorized',
    NotFound = 'not_found',
    Unexpected = 'unexpected',
    InvalidFormat = 'invalid_format',
}

const productsFeedType = r.object({
    sourceFileId: r.number(),
    status: r.nativeEnum(ProductsFeedValidationStatusEnum),
});

export type ProductsFeedType = r.infer<typeof productsFeedType>;

export type CreateProductGroupPayloadType = Omit<ProductGroupFormType, CreateProductGroupFormFieldEnum.FeedUrl>;

export const productGroupSchema = r.object({
    id: r.number(),
    catalogs: r.array(shortCatalogSchema.omit({website: true})),
    companiesCount: r.number(),
    serviceType: r.nativeEnum(ProductsServiceTypeEnum).nullable(),
    name: r.string(),
    hasCatalogValidationErrors: r.boolean(),
    status: r.nativeEnum(ProductGroupStatusEnum),
    source: r.object({
        id: r.number(),
        url: r.string(),
    }),
    sourceType: r.nativeEnum(ProductGroupTypeEnum),
    googleCategory: r.string().nullable(),
    iikoApiLogin: r.string(),
    iikoMenuIds: r.string().array(),
});

export type ProductGroupType = r.infer<typeof productGroupSchema>;

export type ProductGroupHookType = Omit<UseHookType<ProductGroupType>, 'reset'>;

export const productGroupsListResponseSchema = cursorPaginationResponseSchemaFactory(productGroupListItemSchema);
