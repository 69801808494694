import {faCircleQuestion, faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Button, Col, Popover, Row, Typography} from 'antd';
import {useEffect} from 'react';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {CatalogType} from '../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../provider/locale/locale';
import {useYandexConfirmationSuggests} from '../../../../../service/confirmation/confirmation-hook';
import {
    ConfirmationSourcesMethodEnum,
    ConfirmationSourcesStatusEnum,
    ConfirmationsSourcesType,
} from '../../../../../service/confirmation/confirmation-type';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {getConfirmationMethodTranslationKey} from '../confirmations-const';
import {EnterCode} from '../enter-code/enter-code';
import {GetCode} from '../get-code/get-code';

import {statusBadgeStatusMap} from './confirmation-item-const';
import {getConfirmationMessage} from './confirmation-item-helper';
import * as styles from './confirmation-item.scss';

type PropsType = {
    confirmationData: ConfirmationsSourcesType;
    catalogData: CatalogType | null;
    onActionSuccess: () => void;
};

// eslint-disable-next-line complexity
export function ConfirmationItem(props: PropsType): JSX.Element {
    const {confirmationData, catalogData, onActionSuccess} = props;

    const {getFormattedDateTime} = useFormat();

    const translationKey = getConfirmationMethodTranslationKey(confirmationData.method);

    const {suggest, loadSuggest} = useYandexConfirmationSuggests();

    useEffect(() => {
        loadSuggest({companyId: confirmationData.cclCompany, brandId: confirmationData.cclBrand[0]});
    }, [confirmationData.cclBrand, confirmationData.cclCompany, loadSuggest]);

    return (
        <Row className={styles.confirmation_item__wrapper} justify="space-between">
            <Col className={styles.confirmation_item__base_info}>
                <Row>
                    <Col>
                        {getFormattedDateTime(new Date(confirmationData.createdAt), {
                            [TimeSizeEnum.hour]: '2-digit',
                            [TimeSizeEnum.minute]: '2-digit',
                            [TimeSizeEnum.year]: 'numeric',
                            [TimeSizeEnum.month]: '2-digit',
                            [TimeSizeEnum.day]: '2-digit',
                        })}
                    </Col>
                </Row>

                <Row gutter={4} justify="start">
                    {catalogData && (
                        <Col>
                            <img
                                alt={catalogData?.name}
                                className={styles.confirmation_item__catalog_icon}
                                src={catalogData?.logo}
                            />
                        </Col>
                    )}
                    <Col>
                        <Typography.Text className={styles.confirmation_item__confirmation_name} strong>
                            {confirmationData.name}
                        </Typography.Text>
                    </Col>
                    {(confirmationData.canMakeConfirmation ||
                        confirmationData.status === ConfirmationSourcesStatusEnum.ErrorOnSending) && (
                        <Col>
                            <Popover content={<Locale stringKey="SOURCES_CONFIRMATION__INCORRECT_TITLE" />}>
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                            </Popover>
                        </Col>
                    )}
                </Row>
                {confirmationData.address && <Row>{confirmationData.address}</Row>}
                {confirmationData.categories.length > 0 && (
                    <Row>
                        <Typography.Text italic>
                            {confirmationData.categories.map((category, index) => {
                                const isLast = confirmationData.categories.length === index + 1;

                                return (
                                    <>
                                        <br />
                                        {category}
                                        {isLast ? '.' : ','}
                                    </>
                                );
                            })}
                        </Typography.Text>
                    </Row>
                )}
            </Col>
            <div className={styles.confirmation_item__status}>
                <div className={styles.confirmation_item__status_description}>
                    <Badge status={statusBadgeStatusMap[confirmationData.status]} />
                    <div>
                        {confirmationData.statusText}
                        <Popover
                            className={styles.confirmation_item__status_description__status_popover}
                            content={
                                <Locale
                                    stringKey={getConfirmationMessage(
                                        confirmationData.status,
                                        confirmationData.confirmationErrorMessage
                                    )}
                                />
                            }
                        >
                            <FontAwesomeIcon
                                className={styles.confirmation_item__status_description_popover}
                                icon={faCircleQuestion}
                            />
                        </Popover>
                    </div>
                </div>
                <div className={styles.confirmation_item__status__method_description}>
                    <p>
                        <Typography.Text>
                            <Locale stringKey="SOURCES_CONFIRMATION__METHOD" />
                        </Typography.Text>
                        <Typography.Text strong>
                            {translationKey ? <Locale stringKey={translationKey} /> : confirmationData.method}
                        </Typography.Text>
                    </p>

                    {confirmationData.method === ConfirmationSourcesMethodEnum.Link ? (
                        <a href={confirmationData.phone} rel="noreferrer" target="_blank">
                            {confirmationData.phone}
                        </a>
                    ) : (
                        <Typography>{confirmationData.phone}</Typography>
                    )}
                </div>
            </div>

            <Col className={styles.confirmation_item__actions}>
                {confirmationData.status !== ConfirmationSourcesStatusEnum.Confirm &&
                confirmationData.method !== ConfirmationSourcesMethodEnum.VettedPartner ? (
                    <GetCode confirmationData={confirmationData} onCodeSent={onActionSuccess} suggest={suggest} />
                ) : (
                    <Button type="primary">
                        <NavigationLink
                            to={generatePath(appRoute.sourcesYandexConfirmation.path, {
                                companyId: confirmationData.cclCompany,
                                brandId: confirmationData.cclBrand[0],
                            })}
                        >
                            <Locale stringKey="SOURCES__STATUS__CONFIRM" />
                        </NavigationLink>
                    </Button>
                )}

                {confirmationData.canMakeConfirmation && (
                    <EnterCode confirmationData={confirmationData} onCodeEntered={onActionSuccess} />
                )}
            </Col>
        </Row>
    );
}
