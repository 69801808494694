/* eslint-disable complexity,max-statements */
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Col, Input, Row, Select} from 'antd';
import {omit} from 'lodash';
import {useEffect} from 'react';

import {appRoute} from '../../../../../app-route';
import {Text} from '../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {CompanyMultiSelectV2} from '../../../../../layout/company-multi-select-v2/company-multi-select-v2';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {useHelpLink} from '../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {enUs} from '../../../../../provider/locale/translation/en-us/data';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {CompaniesSelectorFeatureEnum} from '../../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../../service/feature-companies/feature-companies-hook';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {
    createProductsGroupApi,
    createProductSourceFromFeedApi,
    editProductsGroupApi,
} from '../../../../../service/products/products-api';
import {useGoogleServiceCategories, useProductAvailableCatalogs} from '../../../../../service/products/products-hook';
import {ProductGroupTypeEnum, ProductsFeedValidationStatusEnum} from '../../../../../service/products/products-type';
import {Form, FormInstance} from '../../../../../typings/antd';
import {getEnumValue} from '../../../../../util/enum';
import {ApiError} from '../../../../../util/error';
import {getRandomString} from '../../../../../util/string';
import {ProvidersIdsEnum} from '../../../../../util/type';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {InfoLabel} from '../../../store-locator/components/info-label/info-label';
import {productGroupTitleMap} from '../select-create-product-group-type-modal/select-new-product-group-type-modal-const';

import {ProductGroupFeedFields} from './feed/product-group-feed-fields';
import {ProductGroupFileFields} from './file/product-group-file-fields';
import {defaultInitialValues, googleServiceTypeTranslationMap} from './product-group-form-const';
import {isProductGroupCreationErrorType} from './product-group-form-helper';
import {
    CreateProductGroupFormFieldEnum,
    ProductGroupFormType,
    ProductsServiceTypeEnum,
} from './product-group-form-type';
import * as styles from './product-group-form.scss';

type PropsType = {
    initialValues?: ProductGroupFormType;
    initialCompaniesCount?: number;
    productGroupId?: number;
    productGroupSourceType: ProductGroupTypeEnum;
};

export function ProductGroupForm(props: PropsType): JSX.Element {
    const {productGroupSourceType, initialCompaniesCount, productGroupId, initialValues} = props;

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {pushUrl, pathname} = useUrl();
    const helpLink = useHelpLink(pathname);

    const [form] = Form.useForm<ProductGroupFormType>();
    const {getCompaniesMultiselectValidationRule, maxLengthFieldRule, requiredFieldRule} = useFormRules();

    const googleCategory = Form.useWatch<
        CreateProductGroupFormFieldEnum.GoogleCategory,
        FormInstance<ProductGroupFormType>
    >(CreateProductGroupFormFieldEnum.GoogleCategory, form);
    const services = Form.useWatch<CreateProductGroupFormFieldEnum.Services, FormInstance<ProductGroupFormType>>(
        CreateProductGroupFormFieldEnum.Services,
        form
    );

    const serviceType = Form.useWatch<CreateProductGroupFormFieldEnum.ServiceType, FormInstance<ProductGroupFormType>>(
        CreateProductGroupFormFieldEnum.ServiceType,
        form
    );

    const {result: availableCatalogs, isInProgress: isAvailableCatalogsIsInProgress} = useProductAvailableCatalogs();

    const companiesSelector = useCompaniesSelector({
        feature: CompaniesSelectorFeatureEnum.Prices,
        resourceId: productGroupId ? String(productGroupId) : null,
        initialCompaniesCount: initialCompaniesCount || 0,
    });

    const {
        result: availableServiceCategories,
        isInProgress: isLoadingAvailableServiceCategories,
        loadServiceCategories,
        isInitialized: isGoogleCategoriesInitialized,
    } = useGoogleServiceCategories();

    const {getCatalogName} = useCatalogInfo();

    const {selectedCount, selectorId, initializeSelector} = companiesSelector;

    useEffect(() => {
        initializeSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectorId) {
            loadServiceCategories(selectorId).catch(() => {
                snackbar.error(<Locale stringKey="PRODUCTS__GROUP_FORM__COMPANY_IDS__ERROR" />);
            });
        }
    }, [selectorId, loadServiceCategories, selectedCount, services, serviceType, snackbar, getLocalizedString]);

    useEffect(() => {
        if (
            googleCategory &&
            !availableServiceCategories?.some((category) => category.id === googleCategory) &&
            isGoogleCategoriesInitialized
        ) {
            form.setFieldsValue({
                [CreateProductGroupFormFieldEnum.GoogleCategory]: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableServiceCategories]);

    useEffect(() => {
        form.setFieldsValue({[CreateProductGroupFormFieldEnum.CompanySelector]: selectorId});
    }, [form, selectorId]);

    async function onSubmit(values: ProductGroupFormType) {
        try {
            const feedUrl = values[CreateProductGroupFormFieldEnum.FeedUrl];
            const iikoMenuIds = values[CreateProductGroupFormFieldEnum.IikoMenuIds];

            let apiPayload = values;

            if (feedUrl) {
                const feedResult = await createProductSourceFromFeedApi(feedUrl);

                if (feedResult.status !== ProductsFeedValidationStatusEnum.Available) {
                    form.setFields([
                        {
                            name: [CreateProductGroupFormFieldEnum.FeedUrl],
                            errors: [getLocalizedString('PRODUCTS__GROUP_FORM__FEED__INVALID_FEED')],
                        },
                    ]);
                    return;
                }

                apiPayload = {
                    ...omit(values, CreateProductGroupFormFieldEnum.FeedUrl),
                    [CreateProductGroupFormFieldEnum.SourceId]: feedResult.sourceFileId,
                };
            }

            if (iikoMenuIds && !Array.isArray(iikoMenuIds)) {
                apiPayload = {
                    ...omit(values, CreateProductGroupFormFieldEnum.FeedUrl),
                    [CreateProductGroupFormFieldEnum.IikoMenuIds]: iikoMenuIds.split(',').map((id) => id.trim()),
                };
            }

            await (productGroupId
                ? editProductsGroupApi(productGroupId, apiPayload)
                : createProductsGroupApi(apiPayload));

            snackbar.success(
                <Locale
                    stringKey={
                        productGroupId ? 'PRODUCTS__GROUP_FORM__EDIT_SUCCESS' : 'PRODUCTS__GROUP_FORM__CREATION_SUCCESS'
                    }
                />
            );

            track(AnalyticsTarget.Products.Create, enUs[productGroupTitleMap[productGroupSourceType]]);
            pushUrl(appRoute.products.path);
        } catch (error: unknown) {
            if (error instanceof ApiError && isProductGroupCreationErrorType(error.jsonData)) {
                const errors = error.jsonData;

                form.setFields(
                    Object.keys(errors).map((fieldName) => {
                        const enumValue = getEnumValue<CreateProductGroupFormFieldEnum>(
                            CreateProductGroupFormFieldEnum,
                            fieldName
                        );

                        return {
                            name: fieldName,
                            errors: (enumValue && errors[enumValue]) || [],
                        };
                    })
                );
                return;
            }

            snackbar.error(
                <Locale
                    stringKey={
                        productGroupId ? 'PRODUCTS__GROUP_FORM__EDIT_ERROR' : 'PRODUCTS__GROUP_FORM__CREATION_ERROR'
                    }
                />
            );
        }
    }

    return (
        <div className={styles.ProductGroupForm}>
            <div className={styles.ProductGroupForm_formWrapper}>
                <Form<ProductGroupFormType>
                    form={form}
                    initialValues={{
                        ...defaultInitialValues,
                        ...initialValues,
                    }}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item<ProductGroupFormType>
                        label={<Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__NAME__TITLE" />}
                        name={CreateProductGroupFormFieldEnum.Name}
                        rules={[requiredFieldRule, maxLengthFieldRule(255)]}
                    >
                        <Input placeholder={getLocalizedString('PRODUCTS__GROUP_FORM__FIELD__NAME__PLACEHOLDER')} />
                    </Form.Item>

                    {productGroupSourceType === ProductGroupTypeEnum.Iiko && (
                        <div className={styles.ProductGroupForm_iikoInputsContainer}>
                            <Form.Item<ProductGroupFormType>
                                label={<Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__IIKO__KEY" />}
                                name={CreateProductGroupFormFieldEnum.IikoApiLogin}
                                rules={[requiredFieldRule, maxLengthFieldRule(60)]}
                            >
                                <Input
                                    placeholder={getLocalizedString(
                                        'PRODUCTS__GROUP_FORM__FIELD__IIKO__KEY__PLACEHODLER'
                                    )}
                                />
                            </Form.Item>
                            <Form.Item<ProductGroupFormType>
                                label={
                                    <AdditionalInfo
                                        leftNode={<Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__IIKO__MENU" />}
                                    >
                                        <Text>
                                            <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__IIKO__MENU__ADDITIONAL_INFO" />
                                        </Text>
                                    </AdditionalInfo>
                                }
                                name={CreateProductGroupFormFieldEnum.IikoMenuIds}
                                rules={[requiredFieldRule, maxLengthFieldRule(255)]}
                            >
                                <Input
                                    placeholder={getLocalizedString(
                                        'PRODUCTS__GROUP_FORM__FIELD__IIKO__MENU__PLACEHODLER'
                                    )}
                                />
                            </Form.Item>
                        </div>
                    )}

                    <Form.Item<ProductGroupFormType>
                        label={<Locale stringKey="PRODUCTS__GROUP_FORM__COMPANY_IDS__TITLE" />}
                        name={CreateProductGroupFormFieldEnum.CompanySelector}
                        required
                        rules={[getCompaniesMultiselectValidationRule(selectedCount)]}
                        validateFirst={false}
                        validateTrigger="onSubmit"
                    >
                        <CompanyMultiSelectV2 companiesSelector={companiesSelector} />
                    </Form.Item>

                    <Form.Item<ProductGroupFormType>
                        label={<Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__SERVICES__TITLE" />}
                        name={CreateProductGroupFormFieldEnum.Services}
                        rules={[requiredFieldRule]}
                    >
                        <Select
                            disabled={isAvailableCatalogsIsInProgress}
                            loading={isAvailableCatalogsIsInProgress}
                            mode="multiple"
                            placeholder={<Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__SERVICES__PLACEHOLDER" />}
                        >
                            {availableCatalogs?.map((catalog) => {
                                const catalogName = getCatalogName(catalog.id);

                                return (
                                    <Select.Option key={catalogName} value={catalog.id}>
                                        {catalogName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    {services?.includes(ProvidersIdsEnum.google) && (
                        <Form.Item<ProductGroupFormType>
                            label={
                                <InfoLabel
                                    hintContent={
                                        <Locale stringKey="PRODUCTS__GROUP_FORM__APPOINTMENT__POPOVER__CONTENT" />
                                    }
                                    hintTitle={<Locale stringKey="PRODUCTS__GROUP_FORM__APPOINTMENT__POPOVER__TITLE" />}
                                    label={<Locale stringKey="PRODUCTS__GROUP_FORM__APPOINTMENT__TITLE" />}
                                />
                            }
                            name={CreateProductGroupFormFieldEnum.ServiceType}
                            rules={[requiredFieldRule]}
                        >
                            <Select
                                placeholder={
                                    <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__APPOINTMENT__PLACEHOLDER" />
                                }
                            >
                                {Object.values(ProductsServiceTypeEnum).map((appointment) => {
                                    return (
                                        <Select.Option key={appointment} value={appointment}>
                                            <Locale
                                                stringKey={googleServiceTypeTranslationMap[appointment] || appointment}
                                            />
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    )}

                    {services?.includes(ProvidersIdsEnum.google) &&
                        serviceType === ProductsServiceTypeEnum.Services && (
                            <>
                                {isGoogleCategoriesInitialized &&
                                    availableServiceCategories?.length === 0 &&
                                    selectedCount > 0 && (
                                        <Alert
                                            className={styles.ProductGroupForm_googleCategoryAlert}
                                            description={
                                                <Locale
                                                    stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE__WARNING__DESCRIPTION"
                                                    valueMap={{
                                                        googleBusinessProfile: getCatalogName(ProvidersIdsEnum.google),
                                                    }}
                                                />
                                            }
                                            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
                                            message={
                                                <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE__WARNING__MESSAGE" />
                                            }
                                            showIcon
                                            type="error"
                                        />
                                    )}
                                <Form.Item<ProductGroupFormType>
                                    label={
                                        <InfoLabel
                                            hintContent={
                                                <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__TITLE__POPOVER__CONTENT" />
                                            }
                                            hintTitle={
                                                <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__TITLE__POPOVER__TITLE" />
                                            }
                                            label={
                                                <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__TITLE" />
                                            }
                                        />
                                    }
                                    name={CreateProductGroupFormFieldEnum.GoogleCategory}
                                    rules={[requiredFieldRule]}
                                >
                                    <Select
                                        disabled={
                                            isLoadingAvailableServiceCategories ||
                                            !isGoogleCategoriesInitialized ||
                                            availableServiceCategories?.length === 0 ||
                                            selectedCount === 0
                                        }
                                        loading={isLoadingAvailableServiceCategories}
                                        placeholder={
                                            <Locale
                                                stringKey={
                                                    selectorId && selectedCount === 0
                                                        ? 'PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__PLACEHOLDER__EMPTY_COMPANIES'
                                                        : 'PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__PLACEHOLDER'
                                                }
                                            />
                                        }
                                    >
                                        {availableServiceCategories?.map((source) => {
                                            return (
                                                <Select.Option key={source.id} value={source.id}>
                                                    {source.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </>
                        )}

                    <div className={styles.ProductGroupForm_typeSpecificFieldsWrapper}>
                        {productGroupSourceType === ProductGroupTypeEnum.File && (
                            <Form.Item<ProductGroupFormType>
                                name={CreateProductGroupFormFieldEnum.SourceId}
                                rules={[requiredFieldRule]}
                            >
                                <ProductGroupFileFields productGroupId={productGroupId} />
                            </Form.Item>
                        )}
                        {productGroupSourceType === ProductGroupTypeEnum.Feed && (
                            <ProductGroupFeedFields showWarning={Boolean(productGroupId)} />
                        )}
                    </div>

                    {helpLink && helpLink.documentationLink ? (
                        <a href={helpLink.documentationLink} rel="noreferrer" target="_blank">
                            <Locale stringKey="PRODUCTS__GROUP_FORM__PRODUCTS__HELP_LINK" />
                        </a>
                    ) : null}

                    <div className={styles.ProductGroupForm_validationTimeWrapper}>
                        <Text lighter small stringKey="PRODUCTS__GROUP_FORM__VALIDATION_TIME" />
                    </div>

                    <Row className={styles.ProductGroupForm_buttonsWrapper}>
                        <Col>
                            <Button htmlType="submit" type="primary">
                                <Locale stringKey="BUTTON__SAVE" />
                            </Button>
                        </Col>
                        <Col>
                            <NavigationLink to={appRoute.products.path}>
                                <Button>
                                    <Locale stringKey="BUTTON__CANCEL" />
                                </Button>
                            </NavigationLink>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className={styles.ProductGroupForm_infoWrapper}>
                {services?.includes(ProvidersIdsEnum.google) && (
                    <div className={styles.ProductGroupForm_infoCard}>
                        <div>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </div>
                        <div className={styles.ProductGroupForm_infoCardTextWrapper}>
                            <div>
                                <Locale
                                    key={getRandomString()}
                                    stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE__INFO__AVAILABLE_SERVICES"
                                />
                            </div>
                            <div>
                                <Locale
                                    stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE__INFO__SERVICE_LIMITS"
                                    valueMap={{
                                        servicesCount: (
                                            <span
                                                className={styles.ProductGroupForm_infoCardTextBold}
                                                key="google_products__services_limits__count"
                                            >
                                                <Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__GOOGLE__INFO__SERVICE_LIMITS__SERVICES_COUNT" />
                                            </span>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
