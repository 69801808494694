import {faChartPie} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocation} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {RatingHistoryPage} from '../../../../page/main/reviews-analysis/pages/rating-history/rating-history-page';
import {
    ReviewsAnalysisActivationBasePage,
    ReviewsAnalysisActivationFullPage,
    ReviewsAnalysisCitiesPage,
    ReviewsAnalysisConcordancePage,
    ReviewsAnalysisDashboard,
    ReviewsAnalysisEmployeesPage,
    ReviewsAnalysisKeywordsAndTopicsPage,
    ReviewsAnalysisLocationsPage,
    ReviewsAnalysisPhrasesPage,
    ReviewsAnalysisTopicCreatePage,
    ReviewsAnalysisTopicPage,
    ReviewsAnalysisTopicUpdatePage,
} from '../../../../page/main/reviews-analysis/reviews-analysis-index';
import {useLicenses} from '../../../../provider/license/license-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {isReviewPhrasesFeatureEnabled} from '../../../../util/feature-toggle/feature-toggle';
import {NavigationItemConfigType} from '../routing-type';

// eslint-disable-next-line complexity
export function useReviewsAnalysisNavigation(): Array<NavigationItemConfigType> {
    const {licenses, availableFeatures} = useLicenses();

    const {search} = useLocation();

    return [
        {
            isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsBase],
            isActivated: true,
            path: appRoute.reviewsAnalysis.path,
            icon: <FontAwesomeIcon icon={faChartPie} />,
            labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
            redirectPath: appRoute.reviewsAnalysisDashboard.path,
            deniedPath: [appRoute.reviewsAnalysis.path],
            search,
            children: [
                {
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsBase],
                    path: appRoute.reviewsAnalysisDashboard.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__DASHBOARD',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsBase]?.isActive
                        ? ReviewsAnalysisDashboard
                        : ReviewsAnalysisActivationBasePage,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisConcordance.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__CONCORDANCE',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisConcordancePage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisKeywordsAndTopics.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisKeywordsAndTopicsPage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    // todo: различия доступности в меню и в роутинге
                    isAvailable: isReviewPhrasesFeatureEnabled(),
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisPhrases.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__PHRASES',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisPhrasesPage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisCompanies.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisLocationsPage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisCities.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisCitiesPage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisEmployees.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES',
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisEmployeesPage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isOnlyRoute: true,
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisTopic.path,
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisTopicPage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isOnlyRoute: true,
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisTopicCreate.path,
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisTopicCreatePage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isOnlyRoute: true,
                    isAvailable: availableFeatures[FeaturesEnum.reviewAnalyticsFull],
                    isActivated: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive,
                    path: appRoute.reviewsAnalysisTopicUpdate.path,
                    exact: true,
                    component: licenses[FeaturesEnum.reviewAnalyticsFull]?.isActive
                        ? ReviewsAnalysisTopicUpdatePage
                        : ReviewsAnalysisActivationFullPage,
                },
                {
                    isAvailable: true,
                    isActivated: true,
                    showNew: true,
                    path: appRoute.reviewAnalysisGeneratorRatings.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__RATINGS',
                    exact: true,
                    component: RatingHistoryPage,
                },
            ],
        },
    ];
}
