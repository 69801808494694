import {useQuery} from '@tanstack/react-query';
import {useContext} from 'react';

import {fetchGoogleAccount, getGoogleAccountUrl} from '../../../../service/google/accounts/google-accounts-api';
import {GoogleAccountType} from '../../../../service/google/accounts/google-accounts-type';

import {GoogleAccountSyncContext} from './google-account-sync-context';

export function useGoogleAccount(): GoogleAccountType | null {
    const {accountId} = useContext(GoogleAccountSyncContext);

    const {data: account} = useQuery([getGoogleAccountUrl(accountId ?? 0)], () => fetchGoogleAccount(accountId ?? 0), {
        enabled: Boolean(accountId),
    });

    return account ?? null;
}
