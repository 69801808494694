import {faCirclePlus, faFilterSlash, faMagnifyingGlass, faXmarkLarge} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, Input, Select} from 'antd';
import {SyntheticEvent, useCallback, useMemo} from 'react';

import {Text} from '../../component/text/text';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useCompanyGroup} from '../../service/company-group/company-group-hook';
import {CompanyGroupType} from '../../service/company-group/company-group-type';
import {useCompaniesSelectorBrands} from '../../service/feature-companies/feature-companies-hook';
import {
    SelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../service/feature-companies/feature-companies-type';
import {classNames} from '../../util/css';

import {useMagnitForceBrandSelect} from './company-popup-v2-hook';
import * as styles from './company-popup-v2.scss';

const {Option} = Select;

type PropsType<CompanyType extends SelectorCompanyType = SelectorCompanyType> = {
    companiesSelector: UseCompaniesSelectorHookType<CompanyType>;
    beforeSelectFromMainFilter: () => void;
    afterSelectFromMainFilter?: () => void;
    renderFilter?: JSX.Element;
    renderAdditionalControl?: JSX.Element;
    disabled?: boolean;
    isDisableSelectAll?: boolean;
    selectedBrandId?: number | null;
    onSelectFiltered?: ((brandId: number) => void) | null;
};

// eslint-disable-next-line complexity
export function CompanySelectHeader<CompanyType extends SelectorCompanyType = SelectorCompanyType>(
    props: PropsType<CompanyType>
): JSX.Element {
    const {
        companiesSelector,
        beforeSelectFromMainFilter,
        afterSelectFromMainFilter,
        renderFilter,
        disabled,
        isDisableSelectAll,
        renderAdditionalControl,
        selectedBrandId,
        onSelectFiltered,
    } = props;
    const {
        selectAllFromTopFilter,
        deselectFiltered,
        isInProgress,
        feature,
        filteredCount,
        selectorId,
        selectFiltered,
        selectedCount,
        isAllSelected,
        companiesSelectorFilter,
        setCompaniesSelectorFilter,
        mainFilterCompaniesCount,
        clearFilter,
    } = companiesSelector;
    const {getLocalizedString} = useLocale();
    const {data: brands} = useCompaniesSelectorBrands({
        feature,
        selectorId,
    });
    const {result: companyGroups, isInProgress: isLoadingCompanyGroups} = useCompanyGroup({
        count: 100,
        page: 1,
    });

    const toggleShowSelectedOnly = useCallback(() => {
        setCompaniesSelectorFilter((previousState) => {
            return {
                ...previousState,
                selectedOnly: !previousState.selectedOnly,
            };
        });
    }, [setCompaniesSelectorFilter]);

    const selectFilteredWrapped = useCallback(() => {
        selectFiltered();

        const brandId = companiesSelectorFilter.brandId || brands?.length === 1 ? brands?.[0]?.id : null;

        if (brandId) {
            onSelectFiltered?.(brandId);
        }
    }, [brands, companiesSelectorFilter.brandId, onSelectFiltered, selectFiltered]);

    const onBrandChange = useCallback(
        (brandId: number | null) => {
            setCompaniesSelectorFilter((previousFilter) => ({
                ...previousFilter,
                brandId,
            }));
        },
        [setCompaniesSelectorFilter]
    );

    useMagnitForceBrandSelect(brands, onBrandChange);

    const onGroupChange = useCallback(
        (groupId: number | null) => {
            setCompaniesSelectorFilter((previousFilter) => ({
                ...previousFilter,
                companyGroupId: groupId,
            }));
        },
        [setCompaniesSelectorFilter]
    );

    const handleSearchStringChange = useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            setCompaniesSelectorFilter((previousFilter) => {
                if (event.currentTarget) {
                    return {
                        ...previousFilter,
                        q: event.currentTarget.value,
                    };
                }

                return previousFilter;
            });
        },
        [setCompaniesSelectorFilter]
    );

    const handleSelectFromMainFilter = useCallback(async () => {
        beforeSelectFromMainFilter();
        await selectAllFromTopFilter();
        afterSelectFromMainFilter?.();
    }, [afterSelectFromMainFilter, beforeSelectFromMainFilter, selectAllFromTopFilter]);

    const isFilterEmpty = useMemo(() => {
        return !Object.values(companiesSelectorFilter).some(Boolean);
    }, [companiesSelectorFilter]);

    const hasDifferentFilterBrandId = Boolean(
        companiesSelectorFilter.brandId && selectedBrandId && companiesSelectorFilter.brandId !== selectedBrandId
    );

    const isNotSelectedFilterBrandId =
        !(companiesSelectorFilter.brandId && !selectedBrandId) && !companiesSelectorFilter.brandId;

    const isDisableByBrandId = isDisableSelectAll && (hasDifferentFilterBrandId || isNotSelectedFilterBrandId);

    return (
        <div>
            <div
                className={classNames(styles.CompaniesSelectModal_filter, {
                    [styles.CompaniesSelectModal_filter__custom]: Boolean(renderFilter),
                })}
            >
                <Input
                    allowClear={{
                        clearIcon: <FontAwesomeIcon icon={faXmarkLarge} size="sm" />,
                    }}
                    onChange={handleSearchStringChange}
                    placeholder={getLocalizedString('COMPANY__MULTISELECT__POPUP__SEARCH_FOR_COMPANIES')}
                    suffix={<FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />}
                    width="100%"
                />

                <Select<number>
                    filterOption={() => true}
                    onChange={onBrandChange}
                    placeholder={getLocalizedString('COMPANY__MULTISELECT__POPUP__ALL_BRANDS')}
                    value={companiesSelectorFilter.brandId || 0}
                >
                    <Option key="all-brands" value={0}>
                        <Locale stringKey="COMPANY__MULTISELECT__POPUP__ALL_BRANDS" />
                    </Option>

                    {brands?.map(
                        ({name, id}): JSX.Element => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        )
                    )}
                </Select>

                <Select<number>
                    filterOption={() => true}
                    loading={isLoadingCompanyGroups}
                    onChange={onGroupChange}
                    placeholder={<Locale stringKey="COMPANY__MULTISELECT__POPUP__ALL_GROUPS" />}
                    value={companiesSelectorFilter.companyGroupId || 0}
                >
                    <Option key="all-brands" value={0}>
                        <Locale stringKey="COMPANY__MULTISELECT__POPUP__ALL_GROUPS" />
                    </Option>

                    {companyGroups?.results?.map(
                        ({title: name, id}: CompanyGroupType): JSX.Element => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        )
                    )}
                </Select>

                {renderFilter}

                <Button
                    className={styles.CompaniesSelectModal_clearFilterButton}
                    disabled={disabled || isFilterEmpty}
                    onClick={clearFilter}
                >
                    <Text inheritColor>
                        <FontAwesomeIcon icon={faFilterSlash} />
                    </Text>
                </Button>
            </div>

            <div className={styles.CompaniesSelectModal_controls}>
                <div className={styles.CompaniesSelectModal_controls_leftColumn}>
                    <Text lighter>
                        <Locale
                            stringKey="COMPANY__MULTISELECT__POPUP__FILTERED_COUNT"
                            valueMap={{
                                count: (
                                    <Text bolder key="selectedItemListLength">
                                        {filteredCount}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>

                    <Button
                        disabled={
                            disabled ||
                            isInProgress ||
                            isAllSelected ||
                            isDisableByBrandId ||
                            companiesSelectorFilter.selectedOnly
                        }
                        icon={<FontAwesomeIcon icon={faCirclePlus} />}
                        onClick={selectFilteredWrapped}
                        type="link"
                    >
                        <Text inheritColor>
                            <Locale stringKey="TEXT__SELECT_ALL" />
                        </Text>
                    </Button>
                    <Button
                        disabled={disabled || isInProgress || selectedCount === 0}
                        icon={
                            <FontAwesomeIcon
                                className={styles.CompaniesSelectModal_controls__deselectFilteredIcon}
                                icon={faXmarkLarge}
                            />
                        }
                        onClick={deselectFiltered}
                        type="link"
                    >
                        <Text inheritColor>
                            <Locale stringKey="COMPANY__MULTISELECT__DESELECT_FILTERED" />
                        </Text>
                    </Button>
                </div>

                {Boolean(mainFilterCompaniesCount) && (
                    <Button
                        disabled={disabled || isInProgress}
                        icon={<FontAwesomeIcon icon={faCirclePlus} />}
                        onClick={handleSelectFromMainFilter}
                        type="link"
                    >
                        <Text inheritColor>
                            <Locale
                                stringKey="COMPANY__MULTISELECT__SELECT_FROM_MAIN_FILTER"
                                valueMap={{
                                    count: mainFilterCompaniesCount,
                                }}
                            />
                        </Text>
                    </Button>
                )}

                {renderAdditionalControl}
            </div>

            {!disabled && (
                <Checkbox
                    checked={companiesSelectorFilter.selectedOnly}
                    className={styles.CompaniesSelectModal_showSelectedOnlyCheckbox}
                    onChange={toggleShowSelectedOnly}
                >
                    <Locale stringKey="COMPANY__MULTISELECT__SHOW_SELECTED_ONLY" />
                </Checkbox>
            )}
        </div>
    );
}
