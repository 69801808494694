import {faArrowsRotate, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AccountCard} from '../../../../component/account/account-card';
import {AddAccountCard} from '../../../../component/account/add-account-card';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../layout/spinner/spinner';
import {CatalogType} from '../../../../provider/catalogs/catalogs-type';
import {useFacebookAccounts} from '../../../../service/accounts/accounts-hook';
import {FacebookAccountType} from '../../../../service/accounts/accounts-type';
import * as accountsLinkingStyles from '../accounts-linking.scss';

type PropsType = {
    catalogInfo: CatalogType;
};

export function AccountLinkingFacebook(props: PropsType): JSX.Element {
    const {catalogInfo} = props;

    const {
        result,
        reset,
        processError: accountsProcessError,
        linkingSrc,
        isInProgress: isAccountInProgress,
    } = useFacebookAccounts();

    if (isAccountInProgress || !result) {
        return <Spinner isShow position="absolute" />;
    }

    if (accountsProcessError) {
        return <AlertFallback />;
    }

    return (
        <div className={accountsLinkingStyles.accounts_linking_content__wrapper}>
            <AddAccountCard
                buttonContent={
                    <>
                        <FontAwesomeIcon icon={faPlus} /> / <FontAwesomeIcon icon={faArrowsRotate} />
                    </>
                }
                catalogName={catalogInfo.name}
                linkingSrc={linkingSrc}
                onClose={reset}
            />

            {result.map((facebookAccount: FacebookAccountType) => {
                return (
                    <AccountCard
                        catalogName={catalogInfo.name}
                        iconSrc={catalogInfo.logo}
                        info={facebookAccount.name}
                        key={facebookAccount.id}
                    />
                );
            })}
        </div>
    );
}
