import {Button, Empty, Space, Table, TablePaginationConfig} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import {ReactElement, useMemo, useState} from 'react';

import {appRoute} from '../../../../../../app-route';
import {Text} from '../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {AsciiSpinner} from '../../../../../../layout/spinner/ascii-spinner';
import {Locale} from '../../../../../../provider/locale/localization';
import {
    CompaniesImportType,
    useFeedManagementList,
} from '../../../../../../service/companies-import/companies-import-list';
import {useChangeFeedAutoUpdateMutation} from '../../../../../../service/companies-import/companies-import-list-edit';
import {toTrimmedString} from '../../../../../../util/string';
import {useUrl} from '../../../../../../util/url-hook/url-hook';
import {FeedDrawer} from '../drawer/feed-drawer';

import {useFeedsColumns} from './feeds-table-const';
import * as styles from './feeds-table.scss';

export function FeedsTable(): ReactElement {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);

    const {getQuery, setQuery} = useUrl();
    const statusesUrl = getQuery('statuses') || '';
    const brandIdsUrl = getQuery('brandIds') || '';

    const brandIds = useMemo(() => brandIdsUrl.split(',').map(toTrimmedString).filter(Boolean), [brandIdsUrl]);
    const statuses = useMemo(() => statusesUrl.split(',').map(toTrimmedString).filter(Boolean), [statusesUrl]);

    const {
        data: feeds,
        isInitialLoading,
        refetch,
        pagination: {page, pageSize, total, onChange},
    } = useFeedManagementList(statuses, brandIds);
    const {mutate, isLoading} = useChangeFeedAutoUpdateMutation(refetch);

    function setIdAndIsOpen(feedId: number | null) {
        setId(feedId);
        setIsOpen(true);
    }

    const columns = useFeedsColumns(mutate, setIdAndIsOpen, statuses, brandIds);

    function onTableChange(newPagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) {
        onChange(newPagination.current ?? 1, newPagination.pageSize);
        setQuery({
            brandIds: [...(filters.brands || [])].map(toTrimmedString).join(','),
            statuses: [...(filters.status || [])].map(toTrimmedString).join(','),
        });
    }

    return feeds?.results.length === 0 && !statusesUrl ? (
        <Empty
            className={styles.Empty}
            description={
                <div className={styles.Empty_description}>
                    <Space direction="vertical" size="small">
                        <Text bolder className={styles.Empty_title} stringKey="FEED__MANAGEMENT__EMPTY_PAGE__TITLE" />
                        <Text block lighter stringKey="FEED__MANAGEMENT__EMPTY_PAGE__DESCRIPTION" />
                    </Space>
                    <NavigationLink to={appRoute.companyImport.path}>
                        <Button className={styles.Empty_button} type="primary">
                            <span>
                                <Locale stringKey="FEED__IMPORT__TITLE" />
                            </span>
                        </Button>
                    </NavigationLink>
                </div>
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{height: 'auto'}}
        />
    ) : (
        <div className={styles.FeedsTable_wrapper}>
            <Text
                stringKey="FEED__MANAGEMENT__LIST_OF_COMPANIES"
                valueMap={{
                    count: <Text bolder>{feeds?.count ?? <AsciiSpinner />}</Text>,
                }}
            />
            {id && <FeedDrawer importUrlId={id} isOpen={isOpen} listRefetch={refetch} setIsOpen={setIsOpen} />}
            <Table<CompaniesImportType>
                columns={columns}
                dataSource={feeds?.results}
                loading={isInitialLoading || isLoading}
                onChange={(newPagination, filters) => {
                    onTableChange(newPagination, filters);
                }}
                pagination={{current: page, pageSize, total, showSizeChanger: true}}
                rowKey={(item) => item.id}
                scroll={{
                    y: '100%',
                    x: 1016,
                }}
            />
        </div>
    );
}
