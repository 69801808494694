import {useCallback, useState} from 'react';

import {LegendBox} from '../../../../../layout/chart/legend-box/legend-box';
import {LegendWrapper} from '../../../../../layout/chart/legend-wrapper/legend-wrapper';
import {MultiSourceChart} from '../../../../../layout/chart/multisource-chart/multi-source-chart';
import {
    MultiSourceDataSetType,
    MultiSourceDataType,
} from '../../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {Trend} from '../../../../../layout/trend/trend';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {
    OnlinePresenceSummaryItemType,
    OnlinePresenceSummaryMetricsEnum,
} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-type';
import {classNames} from '../../../../../util/css';
import {getMonthNameByItsIndex} from '../../../../../util/date';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {
    getCompanyActivityAndSummaryChartOptions,
    onlinePresenceSummaryColorMap,
    onlinePresenceSummaryTranslationMap,
} from './online-presence-summary-helper';
import * as styles from './online-presence-summary.scss';

type PropsType = {
    data: OnlinePresenceSummaryItemType;
};

export function OnlinePresenceSummaryCatalog(props: PropsType): JSX.Element {
    const {data} = props;
    const {startDate, endDate} = data;

    const {getLocalizedString} = useLocale();
    const {getFormattedDateTime, getFormattedNumber} = useFormat();

    const {
        baseMetrics: {conversion, interactions, impressions},
        dynamicMetrics,
    } = data;

    const chartTickFormatter = useCallback(
        (dateString: string) => {
            const monthIndex = new Date(dateString).getMonth();

            return getMonthNameByItsIndex(getLocalizedString)[monthIndex] ?? '';
        },
        [getLocalizedString]
    );

    const [onlinePresenceChartFilter, setOnlinePresenceChartFilter] = useState<Array<string>>([]);

    const onOnlinePresenceChartFilterClick = useCallback((datasetLabel: string) => {
        setOnlinePresenceChartFilter((previousValues) => {
            if (previousValues.includes(datasetLabel)) {
                return previousValues.filter((item) => item !== datasetLabel);
            }

            return [...previousValues, datasetLabel];
        });
    }, []);

    const chartData: MultiSourceDataType = {
        labels: dynamicMetrics.labels,
        datasets: dynamicMetrics.data.map((item) => {
            return {
                ...(item.name === OnlinePresenceSummaryMetricsEnum.Impressions
                    ? {
                          type: 'line',
                          yAxisID: 'y2',
                          borderColor: onlinePresenceSummaryColorMap[item.name],
                          // pointHitRadius: 20,
                      }
                    : {}),
                ...(item.name !== OnlinePresenceSummaryMetricsEnum.Impressions
                    ? {
                          type: 'bar',
                          yAxisID: 'y',
                      }
                    : {}),
                label: getLocalizedString(onlinePresenceSummaryTranslationMap[item.name]),
                values: item.values,
                color: onlinePresenceSummaryColorMap[item.name],
            };
        }),
    };

    function renderLegendElement(dataset: MultiSourceDataSetType) {
        return (
            <LegendBox
                checked={!onlinePresenceChartFilter.includes(dataset.label)}
                color={String(dataset.color) || ''}
                key={dataset.label}
                label={dataset.label}
                onChange={() => {
                    if (
                        chartData.datasets
                            .filter((item) => item !== dataset)
                            .some((item) => !onlinePresenceChartFilter.includes(item.label))
                    ) {
                        onOnlinePresenceChartFilterClick(dataset.label);
                    }
                }}
            />
        );
    }

    return (
        <div className={styles.OnlinePresenceSummary_catalogDataContainer}>
            <div className={styles.OnlinePresenceSummary_metricsPeriodTitle}>
                <Locale
                    stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__BASE_METRICS_PERIOD"
                    valueMap={{
                        start: getFormattedDateTime(new Date(startDate)),
                        end: getFormattedDateTime(new Date(endDate)),
                    }}
                />
            </div>
            <div className={styles.OnlinePresenceSummary_baseMetricsContainer}>
                <div className={styles.OnlinePresenceSummary_baseMetricsItem}>
                    <div className={styles.OnlinePresenceSummary_baseMetricsTitle}>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__IMPRESSIONS" />
                    </div>
                    <div className={styles.OnlinePresenceSummary_baseMetricsValue}>
                        {getFormattedNumber(impressions.current)}
                    </div>
                    <div className={styles.OnlinePresenceSummary_baseMetricsTrendContainer}>
                        <Trend delta={impressions.delta} unit="" />
                        <div>
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__PERIOD" />
                        </div>
                    </div>
                </div>

                <div className={styles.OnlinePresenceSummary_baseMetricsItem}>
                    <div className={styles.OnlinePresenceSummary_baseMetricsTitle}>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__TARGET_ACTIONS" />
                    </div>
                    <div className={styles.OnlinePresenceSummary_baseMetricsValue}>
                        {getFormattedNumber(interactions.current)}
                    </div>
                    <div className={styles.OnlinePresenceSummary_baseMetricsTrendContainer}>
                        <Trend delta={interactions.delta} unit="" />
                        <div>
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__PERIOD" />
                        </div>
                    </div>
                </div>

                <div className={styles.OnlinePresenceSummary_baseMetricsItem}>
                    <div className={styles.OnlinePresenceSummary_baseMetricsTitle}>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CONVERSION" />
                    </div>
                    <div className={styles.OnlinePresenceSummary_baseMetricsValue}>
                        {getFormattedNumber(conversion.current, {
                            maximumFractionDigits: 1,
                        })}
                        %
                    </div>
                    <div className={styles.OnlinePresenceSummary_baseMetricsTrendContainer}>
                        <Trend delta={conversion.delta} />
                        <div>
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__PERIOD" />
                        </div>
                    </div>
                </div>
            </div>

            {chartData.datasets.every((dataset) => dataset.values.length > 0) && (
                <div className={styles.OnlinePresenceSummary_chartWrapper}>
                    <div
                        className={classNames(
                            styles.OnlinePresenceSummary_axisTitle,
                            styles.OnlinePresenceSummary_axisTitle__left
                        )}
                    >
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CHART__IMPRESSIONS" />
                    </div>
                    <div
                        className={classNames(
                            styles.OnlinePresenceSummary_axisTitle,
                            styles.OnlinePresenceSummary_axisTitle__right
                        )}
                    >
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CHART__TARGET_ACTIONS" />
                    </div>
                    <LegendWrapper className={styles.OnlinePresenceSummary_legendWrapper}>
                        {chartData.datasets.map(renderLegendElement)}
                    </LegendWrapper>
                    <div className={styles.OnlinePresenceSummary_chartContainer}>
                        <MultiSourceChart
                            data={{
                                ...chartData,
                                datasets: chartData.datasets.filter(
                                    (dataset) => !onlinePresenceChartFilter.includes(dataset.label)
                                ),
                            }}
                            options={getCompanyActivityAndSummaryChartOptions({
                                data: chartData,
                                tickFormatter: chartTickFormatter,
                            })}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
