import {Radio} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';

import {DateRangePicker} from '../../../../../../component/date-range-picker/date-range-picker';
import {Locale} from '../../../../../../provider/locale/localization';
import {
    RatingsDynamicsFilterQueryParametersEnum,
    RatingsDynamicsFilterType,
} from '../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';
import {DatePeriodEnum, datePeriodTranslations, DEFAULT_DATE_FORMAT} from '../../../../../../util/date';

import {ratingHistoryAllowedDateAggregations} from './rating-history-filter-helper';
import * as styles from './rating-history-filter.scss';

type PropsType = {
    filter: RatingsDynamicsFilterType;
    updateSelectedDates: (dates: [Date, Date]) => void;
    updateDatesPreset: (datesPreset: DatePeriodEnum) => void;
};

export function RatingHistoryFilter(props: PropsType): JSX.Element {
    const {filter, updateDatesPreset, updateSelectedDates} = props;

    function handleDataRangeChange(date: NoUndefinedRangeValueType<Dayjs> | null) {
        const [start, end] = date || [null, null];

        if (start && end) {
            updateSelectedDates([start.toDate(), end.toDate()]);
        }
    }

    return (
        <div className={styles.RatingHistoryFilter}>
            <Radio.Group optionType="button" value={filter[RatingsDynamicsFilterQueryParametersEnum.DatePreset]}>
                {ratingHistoryAllowedDateAggregations.map((item: DatePeriodEnum) => {
                    return (
                        <Radio
                            key={item}
                            onChange={() => {
                                if (item !== DatePeriodEnum.Custom) {
                                    updateDatesPreset(item);
                                }
                            }}
                            value={item}
                        >
                            <Locale stringKey={datePeriodTranslations[item]} />
                        </Radio>
                    );
                })}
            </Radio.Group>
            <DateRangePicker
                allowClear={false}
                disabledDate={(date: Dayjs) =>
                    date.isAfter(dayjs().subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0))
                }
                format={DEFAULT_DATE_FORMAT}
                onChange={handleDataRangeChange}
                value={[
                    dayjs(filter[RatingsDynamicsFilterQueryParametersEnum.BaseDate]),
                    dayjs(filter[RatingsDynamicsFilterQueryParametersEnum.TargetDate]),
                ]}
            />
        </div>
    );
}
