import {appRoute} from '../../../../../app-route';
import {CclActionThumb} from '../../../../../layout/ccl-action-thumb/ccl-action-thumb';
import {CclActionThumbStateNameEnum} from '../../../../../layout/ccl-action-thumb/ccl-action-thumb-type';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {defaultCclStatisticData} from '../../../../../service/ccl-statistic/ccl-statistic-const';
import {useAllCclStatistic} from '../../../../../service/ccl-statistic/ccl-statistic-hook';
import {CclStatisticType, CclStatusEnum} from '../../../../../service/ccl-statistic/ccl-statistic-type';
import {objectToUrlParameters} from '../../../../../util/url';
import {SourcesSearchParametersEnum} from '../../../sources/sources-type';

import * as styles from './ccl-statistics.scss';

export function CclStatistics(): JSX.Element {
    const {isInProgress: cclStatisticIsInProgress, result: cclStatisticResult} = useAllCclStatistic();

    if (cclStatisticIsInProgress) {
        return <Spinner />;
    }

    const {sources: sourcesRoute, sourcesConfirmations: sourcesConfirmationsRoute} = appRoute;

    function buildSourcesRoute(status: CclStatusEnum): string {
        const parameters = objectToUrlParameters({[SourcesSearchParametersEnum.CclStatus]: status});

        return sourcesRoute.path + '?' + parameters;
    }

    const cclStatistic: CclStatisticType = cclStatisticResult || defaultCclStatisticData;

    return (
        <div className={styles.CclStatistics}>
            <div className={styles.CclStatistics_row}>
                <CclActionThumb
                    href={buildSourcesRoute(CclStatusEnum.synced)}
                    type={CclActionThumbStateNameEnum.synchronized}
                    value={cclStatistic.syncCount}
                />
                <CclActionThumb
                    href={buildSourcesRoute(CclStatusEnum.sent)}
                    type={CclActionThumbStateNameEnum.sent}
                    value={cclStatistic.sendedCount}
                />
            </div>
            <div className={styles.CclStatistics_row}>
                <CclActionThumb
                    href={buildSourcesRoute(CclStatusEnum.actionNeeded)}
                    type={CclActionThumbStateNameEnum.actionRequired}
                    value={cclStatistic.actionNeededCount}
                />
                <CclActionThumb
                    href={sourcesConfirmationsRoute.path}
                    type={CclActionThumbStateNameEnum.confirmation}
                    value={cclStatistic.confirmationCount}
                />
            </div>
        </div>
    );
}
