import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faFileArrowUp, faImage} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'antd';
import {SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactImageCrop, {Crop} from 'react-image-crop';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {classNames} from '../../../../../../../util/css';
import {useRefreshId} from '../../../../../../../util/hook';
import {getCropImage, getImageFromUrl, RealSizeType} from '../../../../../../../util/image';
import {catchError} from '../../../../../../../util/promise';

import {DEFAULT_INPUT_IMAGE} from './images-const';
import {InputImageType} from './images-type';
import * as styles from './input-image.scss';

type PropsType = {
    className?: string;
    minWidth: number;
    minHeight: number;
    inputImage: InputImageType;
    setInputImage: (inputImage: InputImageType) => void;
    onUpload: (image: File) => void;
    onRemove: () => void;
};

// eslint-disable-next-line max-statements
export function InputImage(props: PropsType): JSX.Element {
    const {className, minWidth, minHeight, inputImage, setInputImage, onUpload, onRemove} = props;
    const {objectUrl, httpUrl} = inputImage;
    const targetRef = useRef<HTMLDivElement>(null);
    const drawImageUrl = httpUrl || objectUrl;
    const fullClassName = classNames(styles.input_image, className);
    const {refreshId, refresh} = useRefreshId();
    const {modal} = useModal();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>({x: 0, y: 0, width: minWidth, height: minHeight, unit: 'px'});
    const [relativeRealSize, setRelativeRealSize] = useState<RealSizeType>({width: 1, height: 1});
    const [minLocalSize, setMinLocalSize] = useState<RealSizeType>({width: minWidth, height: minHeight});

    const [innerInputImage, setInnerInputImage] = useState<File | null>(null);

    const isLogo = useMemo(() => minWidth === minHeight, [minWidth, minHeight]);

    useEffect(() => {
        const x = crop.x < 0 ? 0 : crop.x;
        const y = crop.y < 0 ? 0 : crop.y;

        setCrop((previousState: Crop) => {
            return {
                ...previousState,
                x,
                y,
            };
        });
    }, [crop.x, crop.y]);

    const resetImage = useCallback(() => {
        URL.revokeObjectURL(objectUrl);

        setInputImage(DEFAULT_INPUT_IMAGE);

        refresh();

        onRemove();
    }, [refresh, objectUrl, setInputImage, onRemove]);

    const getUploadImageError = useCallback(() => {
        resetImage();
        modal.error({
            title: <Locale stringKey="IMAGES__ERROR__INVALID_IMAGE" />,
            content: <Locale stringKey="IMAGES__ERROR__INVALID_IMAGE_DESCRIPTION" />,
        });
    }, [modal, resetImage]);

    const changeSizeParameters = useCallback(
        (newObjectUrl: string) => {
            if (newObjectUrl) {
                getImageFromUrl(newObjectUrl)
                    .then((image: HTMLImageElement) => {
                        const currentWidth = targetRef?.current?.children[0]?.clientWidth || minWidth;
                        const currentHeight = targetRef?.current?.children[0]?.clientHeight || minHeight;
                        const {naturalWidth, naturalHeight} = image;
                        const ratioOfRealToMinWidth = naturalWidth > minWidth ? naturalWidth / minWidth : 1;
                        const ratioOfRealToMinHeight = naturalHeight > minHeight ? naturalHeight / minHeight : 1;

                        if (isLogo) {
                            setRelativeRealSize({
                                width: naturalWidth / currentWidth,
                                height: naturalWidth / currentWidth,
                            });
                        } else {
                            setRelativeRealSize({
                                width: naturalWidth / currentWidth,
                                height: naturalHeight / currentHeight,
                            });
                        }

                        setMinLocalSize({
                            width: currentWidth / ratioOfRealToMinWidth,
                            height: currentHeight / ratioOfRealToMinHeight,
                        });
                        setCrop((previousState: Crop) => {
                            return {
                                ...previousState,
                                x: 0,
                                y: 0,
                                width: currentWidth / ratioOfRealToMinWidth,
                                height: currentHeight / ratioOfRealToMinHeight,
                            };
                        });
                    })
                    .catch(() => getUploadImageError());
            }
        },
        [getUploadImageError, isLogo, minHeight, minWidth]
    );

    const resizeWindow = useCallback(() => {
        changeSizeParameters(objectUrl);
    }, [objectUrl, changeSizeParameters]);

    useEffect(() => {
        window.addEventListener('resize', resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, [resizeWindow]);

    const handleSetCrop = useCallback(
        (newCrop: Crop) => {
            let width = newCrop.width < minLocalSize.width ? minLocalSize.width : newCrop.width;
            let height = newCrop.height < minLocalSize.height ? minLocalSize.height : newCrop.height;

            if (isLogo) {
                const size = newCrop.width > newCrop.height ? newCrop.width : newCrop.height;

                width = size;
                height = size;
            } else {
                height = Math.ceil((width * 9) / 16);
            }

            setCrop({
                ...newCrop,
                width,
                height,
            });
        },
        [isLogo, minLocalSize.width, minLocalSize.height]
    );

    const applyChanges = useCallback(() => {
        getCropImage(drawImageUrl, crop, relativeRealSize)
            .then((blob: Blob) => {
                URL.revokeObjectURL(objectUrl);

                const filename: string = innerInputImage?.name || JSON.stringify(crop);

                const croppedFile = new File([blob], filename);

                setInputImage({
                    id: null,
                    objectUrl: URL.createObjectURL(croppedFile),
                    httpUrl: '',
                    file: croppedFile,
                });

                setIsModalOpen(false);

                onUpload(croppedFile);
            })
            .catch(catchError);
    }, [drawImageUrl, crop, objectUrl, innerInputImage?.name, setInputImage, onUpload, relativeRealSize]);

    const handleOnChange = useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            const {files} = event.currentTarget;
            const newFile: File | null = files && files[0] ? files[0] : null;

            URL.revokeObjectURL(objectUrl);

            setInputImage({
                id: null,
                httpUrl: '',
                objectUrl: newFile ? URL.createObjectURL(newFile) : '',
                file: newFile,
            });

            refresh();
        },
        [refresh, setInputImage, objectUrl]
    );

    useEffect(() => {
        if (!objectUrl) {
            return;
        }

        getImageFromUrl(objectUrl)
            .then((image: HTMLImageElement) => {
                const {naturalWidth, naturalHeight} = image;
                const isValid = naturalWidth >= minWidth && naturalHeight >= minHeight;

                if (!isValid) {
                    getUploadImageError();
                }
            })
            .catch(() => getUploadImageError());
    }, [objectUrl, getUploadImageError, relativeRealSize.width, relativeRealSize.height, minWidth, minHeight]);

    const handleModalCancel = useCallback(() => {
        resetImage();
        setIsModalOpen(false);
    }, [resetImage]);

    useEffect(() => {
        const {file} = inputImage;

        if (file) {
            setInnerInputImage(file);
            changeSizeParameters(objectUrl || URL.createObjectURL(file));
        }
    }, [changeSizeParameters, inputImage, objectUrl]);

    return (
        <div className={fullClassName}>
            {!drawImageUrl && (
                <>
                    <FontAwesomeIcon className={styles.image_placeholder} icon={faImage} />

                    <Button className={styles.upload_button} onClick={() => setIsModalOpen(true)}>
                        <Locale stringKey="BUTTON__UPLOAD_IMAGE" />
                    </Button>
                </>
            )}

            {Boolean(drawImageUrl) && (
                <>
                    <img alt="" className={styles.rendered_image} src={drawImageUrl} />

                    <button className={styles.button_remove} onClick={resetImage} type="button">
                        <FontAwesomeIcon className={styles.button_remove__image} icon={faTrash} />
                    </button>
                </>
            )}

            <Modal
                cancelText={<Locale stringKey="POPUP__BUTTON__CANCEL" />}
                centered
                destroyOnClose
                maskClosable={false}
                okText={<Locale stringKey="BUTTON__UPLOAD" />}
                onCancel={handleModalCancel}
                onOk={applyChanges}
                open={isModalOpen}
                title={<Locale stringKey="IMAGES__HEADER__CROP_PHOTO" />}
                width={1000}
            >
                <div className={styles.drop_photo_place} ref={targetRef}>
                    {Boolean(drawImageUrl) && (
                        <ReactImageCrop
                            crop={crop}
                            minHeight={minLocalSize.height}
                            minWidth={minLocalSize.width}
                            onChange={setCrop}
                            onComplete={handleSetCrop}
                            src={drawImageUrl}
                        />
                    )}
                    {!drawImageUrl && (
                        <>
                            <input
                                accept="image/jpeg, image/jpg, image/png, image/gif"
                                className={styles.drop_photo_place__input}
                                key={refreshId}
                                onChange={handleOnChange}
                                type="file"
                            />

                            <div className={styles.drop_photo_place__button}>
                                <FontAwesomeIcon
                                    className={styles.drop_photo_place__button_icon}
                                    icon={faFileArrowUp}
                                />

                                <Locale stringKey="BUTTON__SELECT" />
                            </div>

                            <p className={styles.drop_photo_place__text}>
                                <Locale stringKey="IMAGES__TEXT__FORMATS" />
                                &nbsp;
                                <span className={styles.drop_photo_place__text_bolder}>Jpg, Png, Gif</span>
                            </p>

                            <p className={styles.drop_photo_place__text}>
                                <Locale stringKey="IMAGES__TEXT__MINIMUM_RESOLUTION" />
                                &nbsp;
                                <span
                                    className={styles.drop_photo_place__text_bolder}
                                >{`${minWidth}x${minHeight}`}</span>
                            </p>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
}
