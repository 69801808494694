import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space, TableColumnsType, Tooltip} from 'antd';
import {ReactNode} from 'react';

import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {TableFilterDropdown} from '../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {useCatalogConfig, useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/locale';
import {formatAddress} from '../../../../service/address/address-helper';
import {AddressType} from '../../../../service/address/address-type';
import {CclStatusEnum} from '../../../../service/ccl-statistic/ccl-statistic-type';
import {CompanyShortType} from '../../../../service/company/company-type';
import {SavedSortStateType} from '../../../../util/save-sort-table-state/save-sort-table-type';
import {objectToUrlParameters} from '../../../../util/url';
import {SyncStatisticsEnum} from '../../dashboard/sync-statistics/sync-statistics-const';
import {SourcesSearchParametersEnum} from '../../sources/sources-type';

import {MyCompaniesTableRowDataType} from './companies-table-type';
import * as styles from './companies-table.scss';

export function useCompaniesColumnList(
    catalogFilters: Array<Record<number, Array<CclStatusEnum>>>
): TableColumnsType<MyCompaniesTableRowDataType> {
    const {mainCatalogs} = useDomainConfig();
    const {result} = useCatalogConfig();
    const {getCatalogName} = useCatalogInfo();

    const availableCatalogs = result?.filter((catalog) => mainCatalogs.includes(catalog.catalogId));

    const filtersState = [
        {text: <Locale stringKey="SOURCES__STATUS__ACTION_REQUIRED" />, value: CclStatusEnum.actionNeeded},
        {text: <Locale stringKey="SOURCES__STATUS__SYNCED" />, value: CclStatusEnum.synced},
        {text: <Locale stringKey="SOURCES__STATUS__SENT" />, value: SyncStatisticsEnum.sent},
    ];

    const mappedCatalogsColumn =
        (availableCatalogs?.map((catalog) => {
            const filteredCatalog = catalogFilters?.find(
                (filter) => Object.keys(filter)[0] === catalog.catalogId.toString()
            );
            const filteredValue = Object.values(filteredCatalog || {})[0] || [];

            return {
                title: getCatalogName(catalog.catalogId).split(' ')[0],
                align: 'center',
                dataIndex: catalog.catalogId.toString(),
                filters: filtersState.map((filter) => ({text: filter.text, value: filter.value})),
                filteredValue,
                filterDropdown: TableFilterDropdown,
            };
        }) as TableColumnsType<MyCompaniesTableRowDataType>) || [];

    return [
        {
            title: <Locale stringKey="TABLE__HEADER__TITLE" />,
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: <Locale stringKey="TABLE__HEADER__ADDRESS" />,
            dataIndex: 'address',
        },
        {
            title: <Locale stringKey="TABLE__HEADER__CODE" />,
            dataIndex: 'code',
            width: 88,
            sorter: true,
        },
        {
            title: <Locale stringKey="TABLE__HEADER__RATING" />,
            align: 'center',
            dataIndex: 'rating',
            sorter: true,
        },
        ...mappedCatalogsColumn,
        {
            title: <Locale stringKey="TABLE__HEADER__ACTIVE_UNTIL" />,
            align: 'center',
            dataIndex: 'expired',
            ellipsis: true,
            sorter: true,
        },
        {
            title: <Locale stringKey="TABLE__HEADER__ACTION" />,
            align: 'center',
            dataIndex: 'action',
            width: 104,
        },
    ];
}

export function getCompanyNameNode(name: string, id: number, yandexNeedActualization: boolean): ReactNode {
    const parameters = objectToUrlParameters({[SourcesSearchParametersEnum.CompanyIdList]: id});
    const route = appRoute.sources.path + '?' + parameters;

    return (
        <NavigationLink to={route}>
            <Space className={styles.CompaniesTable_companyName}>
                {name}
                {yandexNeedActualization && (
                    <Tooltip
                        placement="bottom"
                        title={<Locale stringKey="MY_COMPANIES__YANDEX_VERIFICATION__TOOLTIP" />}
                    >
                        <FontAwesomeIcon
                            className={styles.CompaniesTable_yandexVerificateIcon}
                            icon={faCircleExclamation}
                        />
                    </Tooltip>
                )}
            </Space>
        </NavigationLink>
    );
}

export function getCompanyAddressNode(address: AddressType, company: CompanyShortType): ReactNode {
    if (!company.closed && !company.temporaryClosed) {
        if (company.useRawAddress) {
            return address.rawAddress || '';
        }

        return formatAddress(address);
    }

    return (
        <Tooltip
            placement="top"
            title={
                <Locale
                    stringKey={company.closed ? 'COMPANY__CLOSED__TOOLTIP' : 'COMPANY__TEMPORARILY_CLOSED__TOOLTIP'}
                />
            }
        >
            <span
                className={
                    company.closed
                        ? styles.CompaniesTable_companyName__closed
                        : styles.CompaniesTable_companyName__temporarilyClosed
                }
            >
                {company.useRawAddress ? address.rawAddress : formatAddress(address)}
            </span>
        </Tooltip>
    );
}

export function addDefaultSortOrder(
    columns: TableColumnsType<MyCompaniesTableRowDataType>,
    defaultSortColumn: string | null
): TableColumnsType<MyCompaniesTableRowDataType> {
    if (!defaultSortColumn) {
        return columns;
    }

    const defaultSortOrder = defaultSortColumn.startsWith('-') ? 'descend' : 'ascend';
    const dataIndex = defaultSortColumn.replace('-', '');

    return columns.map((column) => {
        if ('dataIndex' in column && column.dataIndex === dataIndex) {
            return {
                ...column,
                defaultSortOrder,
                sorter: true,
            };
        }

        return column;
    });
}

export function formatSortState(sortData: SavedSortStateType | null): string | null {
    if (sortData?.order === null || !sortData) {
        return null;
    }

    const direction = sortData.order === 'descend' ? '-' : '';

    return `${direction}${String(sortData.field)}`;
}
