import {faExternalLink} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {HelpLinkFeaturesType} from '../../../../provider/help-links/help-links-type';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {defaultPhotosPageSize} from '../photos/photos-const';

import {DisabledImageIconsMap, DisabledMessagesMap, DisabledTitlesMap} from './feature-disabled-const';
import * as styles from './feature-disabled.scss';

type FeatureDisabledPropsType = {
    feature: HelpLinkFeaturesType;
};

export function FeatureDisabled(props: FeatureDisabledPropsType): JSX.Element {
    const {feature} = props;
    const {featureActivation} = useDocumentationLinks();
    const {getLocalizedString} = useLocale();
    const titleKey = DisabledTitlesMap[feature];
    const messageKey = DisabledMessagesMap[feature];
    const {companyName} = useDomainConfig();

    return (
        <PageCard
            bodyClassName={styles.FeatureDisabled}
            title={
                titleKey
                    ? getLocalizedString(titleKey, {
                          count: String(defaultPhotosPageSize),
                      })
                    : ''
            }
        >
            <img
                alt={DisabledTitlesMap[feature]}
                className={styles.FeatureDisabled_icon}
                src={DisabledImageIconsMap[feature]}
            />

            {messageKey && (
                <Text
                    className={styles.FeatureDisabled_message}
                    secondary
                    stringKey={messageKey}
                    valueMap={{companyName}}
                />
            )}

            {featureActivation[feature] && (
                <a
                    className={styles.FeatureDisabled_link}
                    href={featureActivation[feature]}
                    rel="noreferrer"
                    target="_blank"
                >
                    <Button icon={<FontAwesomeIcon icon={faExternalLink} />}>
                        <Locale stringKey="DASHBOARD_PAGE__FEATURE_DISABLED__LEARN_MORE" />
                    </Button>
                </a>
            )}
        </PageCard>
    );
}
