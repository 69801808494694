export async function getQrCodeDataUrl(data: string): Promise<string> {
    const QRCode = await import('qrcode');

    return QRCode.toDataURL(data, {
        errorCorrectionLevel: 'M',
        type: 'image/png',
        margin: 0,
        width: 256,
        rendererOpts: {quality: 1},
    });
}
