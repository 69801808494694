import {PropsWithChildren} from 'react';

import * as styles from './actions-table-cell.scss';

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function ActionsTableCell(props: PropsType): JSX.Element {
    const {children} = props;

    return <div className={styles.ActionsTableCell}>{children}</div>;
}
