import {Empty} from 'antd';

import {Locale} from '../../../../../../provider/locale/localization';

import * as styles from './notifications-menu-empty.scss';

export function NotificationsMenuEmpty(): JSX.Element {
    return (
        <Empty
            className={styles.empty}
            description={
                <>
                    <div className={styles.empty_title}>
                        <Locale stringKey="NOTIFICATION__TEXT__NO_NOTIFICATIONS" />
                    </div>
                    <div className={styles.empty_description}>
                        <Locale stringKey="NOTIFICATION__TEXT__NO_NOTIFICATIONS__DESCRIPTION" />
                    </div>
                </>
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{height: 117}}
        />
    );
}
