import {useEffect, useRef} from 'react';

import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../../provider/domain-config/domain-config-type';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {serialize} from '../../../../../util/api-adapter';
import {isLocatorPreviewConfigEvent} from '../store-locator-management-page/store-locator-management-page-helper';
import {LocatorPreviewReadyEvent} from '../store-locator-management-page/store-locator-management-page-type';

import * as styles from './locator-preview-page.scss';

declare global {
    interface Window {
        PREVIEW_CONFIG: unknown;
        PREVIEW_COMPANY_ID: unknown;
        PREVIEW_DEV: unknown;
        PREVIEW_LOCATOR_API_URL: unknown;
    }
}

export function LocatorPreviewPage(): JSX.Element {
    const locatorRef = useRef<HTMLDivElement>(null);

    const documentationLinks = useDocumentationLinks();
    const {domainName} = useDomainConfig();

    function getStorelocatorDomain(): string {
        if (domainName === DomainNameEnum.rocketData) {
            return (process.env.STORELOCATOR_API_URL ?? window.origin).replace(/go/i, 'storelocator');
        }

        return '';
    }

    useEffect(() => {
        const locatorElement: HTMLDivElement | null = locatorRef.current || null;

        function onMessage({data}: MessageEvent) {
            if (isLocatorPreviewConfigEvent(data) && locatorElement) {
                window.PREVIEW_CONFIG = serialize(data.locator);
                window.PREVIEW_COMPANY_ID = data.companyId;
                window.PREVIEW_LOCATOR_API_URL = getStorelocatorDomain();

                // eslint-disable-next-line unicorn/prefer-dom-node-dataset
                locatorElement.setAttribute('data-rd-locator', data.locator.token);
                const script = document.createElement('script');

                script.src = process.env.STORE_LOCATOR_WIDGET_URL || documentationLinks.storeLocatorWidgetUrl || '';
                script.async = true;
                document.body.append(script);
            }
        }

        window.addEventListener('message', onMessage);
        window.opener.postMessage(new LocatorPreviewReadyEvent());

        return () => window.removeEventListener('message', onMessage);
    }, []);

    return (
        <div>
            <div className={styles.locator_preview_page__site_content}>
                <h1>
                    <Locale stringKey="STORE_LOCATOR_PREVIEW_PAGE__TITLE" />
                </h1>
                <p>
                    <Locale stringKey="STORE_LOCATOR_PREVIEW_PAGE__DESCRIPTION" />
                </p>
            </div>
            <div ref={locatorRef} />
        </div>
    );
}
