import {fetchAndDeserialize, postAndDeserialize} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';
import {bulkBindingGoogleUrl} from '../google-api';

import {
    googleProcessingKeySchema,
    GoogleProcessingKeyType,
    GoogleProcessingStartOptionsType,
} from './google-processing-type';

export function fetchGoogleProcessingKey(): Promise<GoogleProcessingKeyType> {
    const url = `${bulkBindingGoogleUrl}?${objectToUrlParameters({
        timestamp: Date.now(),
    })}`;

    return fetchAndDeserialize(url, googleProcessingKeySchema);
}

export async function deleteGoogleProcessingKey(processingKey: string): Promise<void> {
    const url = `${rootApiUrl}${bulkBindingGoogleUrl}${processingKey}/`;

    await fetchNoContent(url, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export async function postGoogleProcessingStart({
    accountId,
    companiesIds,
    bindAll,
    toExclude,
    brandsIds,
    search,
}: GoogleProcessingStartOptionsType): Promise<GoogleProcessingKeyType> {
    return postAndDeserialize(bulkBindingGoogleUrl, googleProcessingKeySchema, {
        tokenId: accountId,
        ...(companiesIds.length > 0 ? {companiesIds} : null),
        ...(toExclude && toExclude.length > 0 ? {toExclude} : null),
        ...(bindAll ? {bindAll} : null),
        ...(brandsIds.length > 0 ? {brandsIds} : null),
        ...(search ? {search} : null),
    });
}
