import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';
import {z as r} from 'zod';

import {deserializeV2, fetchAndDeserialize, serialize, serializeToURLParameters} from '../../util/api-adapter';
import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {CursorPaginationDataType, CursorPaginationHookType} from '../../util/cursor-pagination/cursor-pagination-type';
import {createRaceFetchX, FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {IdNameType, idNameTypeSchema, PaginatedResponseType} from '../api/api-type';
import {NpsGroupEnum} from '../mailing-clients/mailing-clients-type';

import {ReviewGeneratorRatingScaleTypeEnum} from './questionnaire/review-generator-questionnaire-type';

export enum ReviewGeneratorMailingEnum {
    name = 'name',
    brandId = 'brandId',
    blueprint = 'blueprint',
    targetName = 'targetName',
    sender = 'sender',
    status = 'status',
    isRounding = 'isRounding',
    datetime = 'mailingDatetime',
    timezone = 'mailingTimezone',
    questionnaireId = 'questionnaire',
}

export type ReviewGeneratorMailingClientSelectionType = r.infer<typeof reviewGeneratorMailingClientSchema>;

const reviewGeneratorMailingClientSchema = r.object({
    ids: r.array(r.number()),
    excludeIds: r.array(r.number()),
    npsGroups: r.array(r.nativeEnum(NpsGroupEnum)),
    mailingListIds: r.array(r.number()),
    startDate: r.string().nullable(),
    endDate: r.string().nullable(),
    unsubscribed: r.boolean().nullable().optional(),
});

type ReviewGeneratorMailingBlueprintType = r.infer<typeof reviewGeneratorMailingBlueprintSchema>;

const reviewGeneratorMailingBlueprintSchema = r.object({
    id: r.number(),
    name: r.string(),
    text: r.string(),
});

const reviewGeneratorMailingPayloadProperties = r.object({
    [ReviewGeneratorMailingEnum.name]: r.string(),
    [ReviewGeneratorMailingEnum.brandId]: r.number(),
    [ReviewGeneratorMailingEnum.datetime]: r.string().nullable(),
    [ReviewGeneratorMailingEnum.timezone]: r.string().nullable(),
    [ReviewGeneratorMailingEnum.questionnaireId]: r.number().nullable(),
    clientsFilter: reviewGeneratorMailingClientSchema,
});

export type ReviewGeneratorMailingPayloadType = r.infer<typeof reviewGeneratorMailingPayloadProperties> & {
    [ReviewGeneratorMailingEnum.targetName]: ReviewsGeneratorMailingTargetNameEnum | null;
    [ReviewGeneratorMailingEnum.status]: ReviewGeneratorMailingStatusEnum;
    blueprintId: number | null;
    blueprint: ReviewGeneratorMailingBlueprintType | null;
};

export enum ReviewGeneratorMailingStatusEnum {
    draft = 'draft', // Open for editing
    released = 'released', // Configured, locked, ready to work.
    delayed = 'delayed', // Delayed mailing, goes to DRAFT when editing
    finished = 'finished', // Mailing list specific: mailing success
    error = 'error', // Mailing list specific: mailing error
    sent = 'sent',
}

export enum ReviewsGeneratorMailingTargetNameEnum {
    sms = 'SMS',
    whatsApp = 'WHATSAPP',
}

export type ReviewGeneratorMailingFullType = ReviewGeneratorMailingPayloadType & {
    id: number;
    name: string;
    brandId: number;
    questionnaireId: number | null;
    delivered?: number | null;
    deliveredPercent?: number | null;
    transitionsCount?: number | null;
    badReviewsCount: number | null;
    clientsSelectedCount: number;
    clientsTotalCount: number;
    sender: string;
    status: ReviewGeneratorMailingStatusEnum;
    targetName: string | null;
};

export type ReviewGeneratorMailingType = r.infer<typeof reviewGeneratorMailingSchema>;

const reviewGeneratorMailingSchema = r.object({
    id: r.number(),
    [ReviewGeneratorMailingEnum.name]: r.string(),
    [ReviewGeneratorMailingEnum.targetName]: r.nativeEnum(ReviewsGeneratorMailingTargetNameEnum).nullable(),
    releaseAt: r.string().nullable(),
    startedAt: r.string().nullable(),
    finishedAt: r.string().nullable(),
    createdAt: r.string().nullable(),
    releaseAtTimezone: r.string().nullable(),
    status: r.nativeEnum(ReviewGeneratorMailingStatusEnum),
    catalogs: r.array(r.number()),
    changeLink: r.string().nullable(),
    stats: r.object({
        clientsSelectedCount: r.number().optional(),
        deliveredCount: r.number().nullable().optional(),
        deliveredPercent: r.number().nullable().optional(),
        clicksCount: r.number().nullable().optional(),
        clicksPercent: r.number().nullable().optional(),
        badReviewsCount: r.number().nullable().optional(),
        badReviewsPercent: r.number().nullable().optional(),
        avgRating5: r.number().nullable().optional(),
    }),
});

const reviewGeneratorMailingFullSchema = reviewGeneratorMailingPayloadProperties.extend({
    id: r.number(),
    clientsTotalCount: r.number(),
    clientsSelectedCount: r.number(),
    sender: r.string(),
    blueprint: reviewGeneratorMailingBlueprintSchema.nullable(),
    targetName: r.string().nullable(),
});

export type ReviewGeneratorConfirmMailingType = r.infer<typeof reviewGeneratorConfirmMailingSchema>;

const reviewGeneratorConfirmMailingSchema = r.object({
    messagesCounts: r.array(
        r.object({
            countryAlpha2: r.string(),
            clientsCount: r.number(),
            messagesCount: r.number(),
        })
    ),
});

const reviewGeneratorMailingResponseSchema = r.object({
    count: r.number(),
    next: r.string().nullable(),
    previous: r.string().nullable(),
    pageSize: r.number(),
    results: r.array(reviewGeneratorMailingSchema),
});

export type ReviewGeneratorMailingListRequestType = {
    startDate: string | null;
    endDate: string | null;
    status: Array<string>;
    name: string;
};

export type ReviewGeneratorTemplateListRequestType = {
    nameStartswith: string | null;
    textStartswith: string | null;
    targetNames: string | null;
};

const reviewGeneratorMailingTemplateSchema = r.object({
    id: r.number(),
    name: r.string(),
    text: r.string(),
    targetNames: r.array(r.nativeEnum(ReviewsGeneratorMailingTargetNameEnum)),
});

export type ReviewGeneratorMailingTemplateType = r.infer<typeof reviewGeneratorMailingTemplateSchema>;

const reviewGeneratorMailingLicenseSchema = r.object({
    pk: r.number(),
    startAt: r.string(),
    finishAt: r.string(),
    isUpsell: r.boolean().optional(),
    isTrial: r.boolean(),
});

const reviewGeneratorMailingLicensesResponseSchema = r.array(reviewGeneratorMailingLicenseSchema);

type ReviewGeneratorMailingLicensesResponseType = r.infer<typeof reviewGeneratorMailingLicensesResponseSchema>;

const reviewGeneratorMessagesCountSchema = r.record(
    r.string(),
    r.record(
        r.nativeEnum(ReviewsGeneratorMailingTargetNameEnum),
        r.array(
            r.object({
                countryAlpha2: r.string(),
                messagesCount: r.number(),
            })
        )
    )
);

export type ReviewGeneratorMessagesCountType = r.infer<typeof reviewGeneratorMessagesCountSchema>;

const reviewGeneratorMailingBrandTemplateSettingsSchema = r.object({
    color: r.string(),
    id: r.number(),
    logo: r.string().nullable(),
    logoHeight: r.number(),
});

export type ReviewGeneratorMailingBrandTemplateSettingsType = r.infer<
    typeof reviewGeneratorMailingBrandTemplateSettingsSchema
>;

const reviewGeneratorMailingDetailsSchema = r.object({
    id: r.number(),
    questionnaireId: r.number().nullable().optional(),
    [ReviewGeneratorMailingEnum.name]: r.string(),
    [ReviewGeneratorMailingEnum.targetName]: r.nativeEnum(ReviewsGeneratorMailingTargetNameEnum).nullable(),
    releaseAt: r.string().nullable(),
    startedAt: r.string().nullable(),
    finishedAt: r.string().nullable(),
    createdAt: r.string().nullable(),
    releaseAtTimezone: r.string().nullable(),
    [ReviewGeneratorMailingEnum.status]: r.nativeEnum(ReviewGeneratorMailingStatusEnum),
    changeLink: r.string().nullable(),
    messageText: r.string().nullable(),
    ratingScaleType: r.nativeEnum(ReviewGeneratorRatingScaleTypeEnum),
    stats: r.object({
        avgRating5: r.number().gte(1).lte(5).nullable().optional(),
        sentCount: r.number().nullable().optional(),
        sentPercent: r.number().nullable().optional(),
        deliveredCount: r.number(),
        deliveredPercent: r.number(),
        clicksCount: r.number().nullable().optional(),
        clicksPercent: r.number().nullable().optional(),
        positiveMarkCount: r.number().nullable().optional(),
        positiveMarkPercent: r.number().nullable().optional(),
        negativeMarkCount: r.number().nullable().optional(),
        negativeMarkPercent: r.number().nullable().optional(),
        goCatalogCount: r.number().optional(),
        goCatalogPercent: r.number().optional(),
        go2gisCount: r.number().optional(),
        goYandexCount: r.number().optional(),
        goGoogleCount: r.number().optional(),
        goCustomLinkCount: r.number().optional(),
        badReviewsCount: r.number().nullable().optional(),
        badReviewsPercent: r.number().nullable().optional(),
        unsubscribeCount: r.number().nullable().optional(),
        unsubscribePercent: r.number().nullable().optional(),
    }),
});

export type ReviewGeneratorMailingDetailsType = r.infer<typeof reviewGeneratorMailingDetailsSchema>;

type ReviewGeneratorMailingListPropsType = {
    mainFilterKey: string;
    filterOptions: ReviewGeneratorMailingListRequestType;
    defaultPageSize: number;
};

export enum SorterDirectionEnum {
    ascend = 'ascend',
    descend = 'descend',
}

export enum SorterDirectionRequestEnum {
    ascend = 'rating_asc',
    descend = 'rating_desc',
}

export type ReviewGeneratorMailingClientsRequestType = {
    name: string;
    email: string;
    phone: string;
    ordering?: SorterDirectionRequestEnum | null;
    rating: Array<number | 'null'>;
};

type ReviewGeneratorMailingClientsPropsType = {
    id: number;
    filterOptions: ReviewGeneratorMailingClientsRequestType;
    defaultPageSize: number;
};

const ReviewGeneratorMailingDetailsClientsSchema = r.object({
    id: r.number(),
    name: r.string(),
    phone: r.string(),
    email: r.string(),
    rating: r.number().nullable(),
    text: r.string().nullable(),
});

export type ReviewGeneratorMailingDetailsClientsType = r.infer<typeof ReviewGeneratorMailingDetailsClientsSchema>;

const reviewGeneratorMailingListRaceFetchX = createRaceFetchX();

export async function fetchReviewGeneratorConfirmMailing(id: number): Promise<ReviewGeneratorConfirmMailingType> {
    const url = `${rootApiUrl}/v2/reviews-generator/mailing-list-budget/messages_counts/?template_id=${id}`;

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        method: FetchMethodEnum.get,
    });

    return deserializeV2<ReviewGeneratorConfirmMailingType>(url, reviewGeneratorConfirmMailingSchema, response);
}

export async function createReviewGeneratorMailing(
    payload: ReviewGeneratorMailingPayloadType
): Promise<ReviewGeneratorMailingFullType> {
    const url = `${rootApiUrl}/v2/reviews-generator/mailing-lists/`;
    const body = JSON.stringify(serialize<ReviewGeneratorMailingPayloadType>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });

    return deserializeV2<ReviewGeneratorMailingFullType>(url, reviewGeneratorMailingFullSchema, response);
}

export async function updateStatusDraftReviewGeneratorMailing(id: number): Promise<void> {
    const url = `${rootApiUrl}/v2/reviews-generator/mailing-lists/${id}/move_to_draft/`;

    await fetchNoContent(url, {
        method: FetchMethodEnum.put,
    });
}

export async function deleteReviewGeneratorMailing(id: number): Promise<void> {
    const url = `${rootApiUrl}/v2/reviews-generator/mailing-lists/${id}/`;

    await fetchNoContent(url, {
        method: FetchMethodEnum.delete,
    });
}

export async function submitReviewGeneratorMailing(id: number): Promise<void> {
    const url = `${rootApiUrl}/v2/reviews-generator/mailing-lists/${id}/submit/`;

    await fetchX<Record<string, unknown>>(url, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: '',
    });
}

function getReviewGeneratorMailingListUrl(
    options: ReviewGeneratorMailingListRequestType,
    mainFilterKey: string,
    paginationData: CursorPaginationDataType
) {
    const {pageSize, ...restPaginationData} = paginationData;
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewGeneratorMailingListRequestType>(options),
        filter_key: mainFilterKey,
        page_size: pageSize,
        ...restPaginationData,
    });

    return `${rootApiUrl}/v2/reviews-generator/mailing-lists/?${searchParameters}`;
}

async function fetchReviewGeneratorMailingList(
    options: ReviewGeneratorMailingListRequestType,
    mainFilterKey: string,
    paginationData: CursorPaginationDataType
): Promise<PaginatedResponseType<ReviewGeneratorMailingType>> {
    const url = getReviewGeneratorMailingListUrl(options, mainFilterKey, paginationData);
    const response: Record<string, never> = await reviewGeneratorMailingListRaceFetchX<Record<string, never>>(url);

    return deserializeV2<PaginatedResponseType<ReviewGeneratorMailingType>>(
        url,
        reviewGeneratorMailingResponseSchema,
        response
    );
}

export function useReviewGeneratorMailingList(props: ReviewGeneratorMailingListPropsType): UseQueryResult<
    PaginatedResponseType<ReviewGeneratorMailingType>
> & {
    cursorPagination: CursorPaginationHookType;
} {
    const {mainFilterKey, filterOptions, defaultPageSize} = props;

    const paginationDependencies = useMemo(() => {
        return {mainFilterKey, filterOptions};
    }, [mainFilterKey, filterOptions]);

    const cursorPagination = useCursorPagination({
        dependencies: paginationDependencies,
        defaultPageSize,
    });

    const {pageSize, cursor, onDataLoaded} = cursorPagination;

    return {
        ...useQuery(
            [getReviewGeneratorMailingListUrl(filterOptions, mainFilterKey, {cursor, pageSize})],
            () => {
                const {startDate, endDate, status, name} = filterOptions;

                return fetchReviewGeneratorMailingList(
                    {
                        startDate,
                        endDate,
                        status,
                        name,
                    },
                    mainFilterKey,
                    {cursor, pageSize}
                );
            },
            {
                refetchOnWindowFocus: false,
                onSuccess: (result) => onDataLoaded(result),
            }
        ),
        cursorPagination,
    };
}

const reviewGeneratorMailingSearchUrl = '/v2/reviews-generator/mailing-lists/autocomplete/';

async function fetchReviewGeneratorMailingSearch(): Promise<Array<IdNameType>> {
    return fetchAndDeserialize<Array<IdNameType>>(reviewGeneratorMailingSearchUrl, idNameTypeSchema.array());
}

export function useReviewGeneratorMailingSearch(): UseQueryResult<Array<IdNameType>> {
    return useQuery([reviewGeneratorMailingSearchUrl], () => fetchReviewGeneratorMailingSearch(), {
        refetchOnWindowFocus: false,
    });
}

function getReviewGeneratorMailingUrl(id: number) {
    return `/v2/reviews-generator/mailing-lists/${id}/`;
}

async function fetchReviewGeneratorMailing(id: number): Promise<ReviewGeneratorMailingFullType> {
    return fetchAndDeserialize<ReviewGeneratorMailingFullType>(
        getReviewGeneratorMailingUrl(id),
        reviewGeneratorMailingFullSchema,
        {
            fetchOptions: {
                skipCache: true,
            },
        }
    );
}

export function useReviewGeneratorMailingItem(id: number): UseQueryResult<ReviewGeneratorMailingFullType | null> {
    return useQuery([getReviewGeneratorMailingUrl(id)], () => fetchReviewGeneratorMailing(id), {
        refetchOnWindowFocus: true,
        cacheTime: 0,
        refetchInterval: 0,
    });
}

function getUpdateReviewGeneratorMailingUrl(id: number) {
    return `${rootApiUrl}/v2/reviews-generator/mailing-lists/${id}/`;
}

export async function updateReviewGeneratorMailing(options: {
    id: number;
    payload: Partial<ReviewGeneratorMailingPayloadType>;
}): Promise<ReviewGeneratorMailingFullType> {
    const {id, payload} = options;
    const url = getUpdateReviewGeneratorMailingUrl(id);
    const body = JSON.stringify(serialize<Partial<ReviewGeneratorMailingPayloadType>>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.patch,
    });

    return deserializeV2<ReviewGeneratorMailingFullType>(url, reviewGeneratorMailingFullSchema, response);
}

export function useUpdateReviewGeneratorMailingItem(): UseMutationResult<
    ReviewGeneratorMailingFullType | null,
    unknown,
    {
        id: number;
        payload: Partial<ReviewGeneratorMailingFullType>;
    }
> {
    return useMutation(['update-mailing'], updateReviewGeneratorMailing);
}

function getReviewGeneratorMailingTemplatesListUrl(options: ReviewGeneratorTemplateListRequestType) {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewGeneratorTemplateListRequestType>(options),
    });

    return `/v2/reviews-generator/blueprints/?${searchParameters}`;
}

async function fetchReviewGeneratorMailingTemplatesList(
    options: ReviewGeneratorTemplateListRequestType
): Promise<Array<ReviewGeneratorMailingTemplateType>> {
    return fetchAndDeserialize<Array<ReviewGeneratorMailingTemplateType>>(
        getReviewGeneratorMailingTemplatesListUrl(options),
        reviewGeneratorMailingTemplateSchema.array()
    );
}

export function useReviewGeneratorMailingTemplateList(props: {
    filterOptions: ReviewGeneratorTemplateListRequestType;
}): UseQueryResult<Array<ReviewGeneratorMailingTemplateType>> {
    const {filterOptions} = props;

    return useQuery(
        [getReviewGeneratorMailingTemplatesListUrl(filterOptions)],
        () => fetchReviewGeneratorMailingTemplatesList(filterOptions),
        {
            refetchOnWindowFocus: false,
        }
    );
}

const reviewGeneratorMailingLicensesUrl = '/cp/mailing_lists/licences/';

async function fetchReviewGeneratorMailingLicenses(): Promise<ReviewGeneratorMailingLicensesResponseType> {
    return fetchAndDeserialize<ReviewGeneratorMailingLicensesResponseType>(
        reviewGeneratorMailingLicensesUrl,
        reviewGeneratorMailingLicensesResponseSchema
    );
}

export function useReviewGeneratorMailingLicensesList(): UseQueryResult<ReviewGeneratorMailingLicensesResponseType> {
    return useQuery([reviewGeneratorMailingLicensesUrl], fetchReviewGeneratorMailingLicenses, {
        refetchOnWindowFocus: false,
    });
}

const reviewGeneratorMessagesCountUrl = '/v2/reviews-generator/balance/messages_count/';

async function fetchReviewGeneratorMessagesCount(): Promise<ReviewGeneratorMessagesCountType> {
    return fetchAndDeserialize(reviewGeneratorMessagesCountUrl, reviewGeneratorMessagesCountSchema, {
        deserializeOptions: {
            ignoreKeys: [/(SMS|VIBER|WHATSAPP)/],
        },
    });
}

export function useReviewGeneratorMessagesCount(): UseQueryResult<ReviewGeneratorMessagesCountType> {
    return useQuery([reviewGeneratorMessagesCountUrl], fetchReviewGeneratorMessagesCount, {
        refetchOnWindowFocus: false,
    });
}

function getReviewGeneratorMailingDetailsUrl(id: number) {
    return `/v2/reviews-generator/mailing-lists/${id}/stats/`;
}

async function fetchReviewGeneratorMailingDetails(id: number): Promise<ReviewGeneratorMailingDetailsType> {
    return fetchAndDeserialize<ReviewGeneratorMailingDetailsType>(
        getReviewGeneratorMailingDetailsUrl(id),
        reviewGeneratorMailingDetailsSchema
    );
}

export function useReviewGeneratorMailingDetails(id: number): UseQueryResult<ReviewGeneratorMailingDetailsType> {
    return useQuery([getReviewGeneratorMailingDetailsUrl(id)], () => fetchReviewGeneratorMailingDetails(id), {
        refetchOnWindowFocus: false,
    });
}

function getReviewGeneratorMailingDetailsClientsUrl(
    id: number,
    options: ReviewGeneratorMailingClientsRequestType & CursorPaginationDataType
) {
    const {pageSize, cursor, ...restPaginationData} = options;

    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewGeneratorMailingClientsRequestType>(restPaginationData),
        page_size: pageSize,
        cursor,
        template_id: id,
    });

    return `/v2/reviews-generator/mailing-lists/clients_stats/?${searchParameters}`;
}

export async function fetchReviewGeneratorMailingDetailsClients(
    id: number,
    options: ReviewGeneratorMailingClientsRequestType & CursorPaginationDataType
): Promise<PaginatedResponseType<ReviewGeneratorMailingDetailsClientsType>> {
    return fetchAndDeserialize<PaginatedResponseType<ReviewGeneratorMailingDetailsClientsType>>(
        getReviewGeneratorMailingDetailsClientsUrl(id, options),
        ReviewGeneratorMailingDetailsClientsSchema
    );
}

export function useReviewGeneratorMailingDetailsClients(props: ReviewGeneratorMailingClientsPropsType): UseQueryResult<
    PaginatedResponseType<ReviewGeneratorMailingDetailsClientsType>
> & {
    cursorPagination: CursorPaginationHookType;
} {
    const {id, filterOptions, defaultPageSize} = props;

    const paginationDependencies = useMemo(() => ({filterOptions, id}), [filterOptions, id]);

    const cursorPagination = useCursorPagination({
        dependencies: paginationDependencies,
        defaultPageSize,
    });
    const {pageSize, cursor, onDataLoaded} = cursorPagination;

    return {
        ...useQuery(
            [getReviewGeneratorMailingDetailsClientsUrl(id, {cursor, pageSize, ...filterOptions})],
            () => fetchReviewGeneratorMailingDetailsClients(id, {cursor, pageSize, ...filterOptions}),
            {
                refetchOnWindowFocus: false,
                onSuccess: (result) => onDataLoaded(result),
            }
        ),
        cursorPagination,
    };
}
