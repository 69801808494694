export const enReviewGeneratorTranslations = {
    REVIEWS_GENERATOR__NPS_UNSPECIFIED: 'Unspecified',
    REVIEWS_GENERATOR__NPS_PROMOTER: 'Promoter',
    REVIEWS_GENERATOR__NPS_NEUTRAL: 'Neutral',
    REVIEWS_GENERATOR__NPS_DETRACTOR: 'Detractor',

    REVIEWS_GENERATOR__MAILINGS__BUTTON__CREATE: 'Create notification campaign',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__TEMPLATES: 'Templates',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__ROCKETMAIL: 'Rocketmail',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__SUBMIT_UPLOAD: 'Save and go to confirmation',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__SAVE_AS_DRAFT: 'Save as draft',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__CANCEL_EDIT: 'Cancel editing',
    REVIEWS_GENEGATOR__MAILINGS__BUTTON__CANCEL_CREATE: 'Cancel creating',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__SELECT: 'Select',
    REVIEWS_GENERATOR__MAILINGS__BUTTON__CHANGE: 'Change',

    REVIEWS_GENERATOR__MAILINGS_TOTAL: 'Total mailings: {total}',
    REVIEWS_GENERATOR__MAILINGS_SEARCH_INPUT__PLACEHOLDER: 'Search by name...',

    REVIEWS_GENERATOR__INTERGATION: 'Activate the service',
    REVIEWS_GENERATOR__MODAL__TITLE: 'Request for activation of the Sending Package',
    REVIEWS_GENERATOR__MODAL__PRODUCT: 'Product',

    REVIEWS_GENERATOR__MAILING__TABLE__EMPTY_TEXT: 'There are no mailings yet. Create the first one!',
    REVIEWS_GENERATOR__FILTER__TABLE__EMPTY_TEXT: 'There is nothing for the specified filters.',

    REVIEWS_GENERATOR__MAILING__LICENSE__SECTION_TITLE: 'License',
    REVIEWS_GENERATOR__MAILING__LICENSE__TITLE: 'Newsletters',
    REVIEWS_GENERATOR__MAILING__LICENSE__TRIAL: 'Trial period',
    REVIEWS_GENERATOR__MAILING__LICENSE__DURATION_TITLE: 'Enabled',
    REVIEWS_GENERATOR__MAILING__LICENSE__DURATION: 'Until {date}',
    REVIEWS_GENERATOR__MAILING__LICENSE__COST_TITLE: 'Price',
    REVIEWS_GENERATOR__MAILING__LICENSE__COST: 'For free',
    REVIEWS_GENERATOR__MAILING__LICENSE__VALID_UNTIL: 'Valid until {date}',
    REVIEWS_GENERATOR__MAILING__LICENSE__IS_OVER: 'License expired',
    REVIEWS_GENERATOR__MAILING__LICENSE__TRIAL_IS_OVER: 'Trial period is over',
    REVIEWS_GENERATOR__MAILING__LICENSE__TRIAL_IS_OVER__TIME: 'Expired',
    REVIEWS_GENERATOR__MAILING__LICENSE__PURCHASED_FORM_MANAGER: 'Purchased from your personal manager',
    REVIEWS_GENERATOR__MAILING__LICENSE__PURCHASE: 'Purchase a license',
    REVIEWS_GENERATOR__MAILING__LICENSE__REQUESTED: 'Connection request sent',

    REVIEWS_GENERATOR__MAILING__LICENSE__REQUEST__ERROR__TITLE: 'Error',
    REVIEWS_GENERATOR__MAILING__LICENSE__REQUEST__ERROR__DETAILS:
        'Failed to send connection request. Try again later or contact the manager.',

    REVIEWS_GENERATOR__MAILING__INFORMATION_STEP__TITLE: 'Basic Information',
    REVIEWS_GENERATOR__MAILING__CONTENT_STEP__TITLE: 'Content',
    REVIEWS_GENERATOR__MAILING__DESIGN_STEP__TITLE: 'Design',
    REVIEWS_GENERATOR__MAILING__DESIGN_STEP__ALERT:
        'You have selected a ready-made template. Disable the template for individual customization.',
    REVIEWS_GENERATOR__MAILING__SENDING_STEP__TITLE: 'Sending parameters',

    REVIEWS_GENERATOR__TEMPLATE_SETTINGS__MODAL__TITLE: 'Deleting a saved setting',
    REVIEWS_GENERATOR__TEMPLATE_SETTINGS__MODAL__DESCRIPTION: 'The appearance setting will be deleted.',
    REVIEWS_GENERATOR__TEMPLATE_SETTINGS__SNACKBAR_SUCCESS: 'The setting was successfully deleted',
    REVIEWS_GENERATOR__TEMPLATE_SETTINGS__SNACKBAR__TITLE_FAILED: 'The setting could not be deleted',
    REVIEWS_GENERATOR__TEMPLATE_SETTINGS__SNACKBAR__DESCRIPTION_FAILED:
        'Please try again later, or contact technical support.',

    REVIEWS_GENERATOR__MAILINGS__TABLE__MAILING: 'Mailing',
    REVIEWS_GENERATOR__MAILINGS__TABLE__DATE_SENDING: 'Sending date',
    REVIEWS_GENERATOR__MAILINGS__TABLE__STATUS: 'Status',
    REVIEWS_GENERATOR__MAILINGS__TABLE__CHANNEL_ACTIVATION: 'Channel activation',
    REVIEWS_GENERATOR__MAILINGS__TABLE__CLIENTS: 'Clients',
    REVIEWS_GENERATOR__MAILINGS__TABLE__DELIVERED: 'Delivered',
    REVIEWS_GENERATOR__MAILINGS__TABLE__TRANSITIONS: 'Transitions',
    REVIEWS_GENERATOR__MAILINGS__TABLE__BAD_REVIEWS: 'Negative reviews',
    REVIEWS_GENERATOR__MAILINGS__TABLE__ACTIONS: 'Actions',

    REVIEWS_GENERATOR__MAILINGS__TABLE__POPOVER__CREATE: 'Creation',
    REVIEWS_GENERATOR__MAILINGS__TABLE__POPOVER__START: 'Start of sending',
    REVIEWS_GENERATOR__MAILINGS__TABLE__POPOVER__FINISH: 'Finishing sending',
    REVIEWS_GENERATOR__MAILINGS__TABLE__POPOVER__RELEASE: 'Scheduled delivery',

    REVIEWS_GENERATOR__MAILINGS__STATUS__DRAFT: 'Draft',
    REVIEWS_GENERATOR__MAILINGS__STATUS__ERROR: 'Error',
    REVIEWS_GENERATOR__MAILINGS__STATUS__DEPLOYED: 'Planned',
    REVIEWS_GENERATOR__MAILINGS__STATUS__FINISHED: 'Finished',
    REVIEWS_GENERATOR__MAILINGS__STATUS__RELEASED: 'Released',
    REVIEWS_GENERATOR__MAILINGS__STATUS__SENT: 'Sent',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MAIN__TITLE: 'Boost your reviews and strengthen customer trust',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MAIN__SUBTITLE:
        'Use SMS and Viber blasts to maintain your online reputation and attract customers to your stores.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MAIN__BUTTON: 'Try for free',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__TITLE: 'Benefits of sending a blast',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__TEXT_1: 'Boost in rankings',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__DESCRIPTION_1:
        'A higher number of positive reviews elevates your company`s standing on location-based services, making you a top choice for customers over your competitors.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__TEXT_2: 'Strengthened company credibility',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__DESCRIPTION_2:
        'Proactively managing feedback helps accumulate positive reviews and swiftly resolve negative ones, bolstering your company’s credibility.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__TEXT_3: 'Increased conversions',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ADVANTAGES__DESCRIPTION_3:
        'Fresh positive reviews catch the attention of search engines, resulting in higher search rankings. This visibility prompts more customer interactions and conversions, such as route mapping.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__GUIDE__TITLE: 'How to nail it?',
    REVIEWS_GENERATOR__MAILINGS_LANDING__GUIDE__DESCRIPTION:
        'Customers receive a clickable message to rate the company and review a specific location. ',
    REVIEWS_GENERATOR__MAILINGS_LANDING__GUIDE__TEXT_1: 'You can pre-screen negative feedback',
    REVIEWS_GENERATOR__MAILINGS_LANDING__GUIDE__TEXT_2:
        'Your {companyName} dashboard provides concise statistics on the email sequences performance. It includes key metrics such as delivery rates, submitted reviews, and click-through rates.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__REVIEWS__TITLE: 'Viber blasts',
    REVIEWS_GENERATOR__MAILINGS_LANDING__REVIEWS__TEXT_1:
        'In Viber, messages are sent with accompanying text and a clickable button. Pressing the button opens a feedback form, and you have the option to customize the button text. There are pre-approved text templates ready for immediate use, ensuring compliance with social network standards.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__REVIEWS__TEXT_2:
        'Text templates are available for mailing, which are already agreed with the messenger and are available for instant launch',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__TITLE: 'Activation channels',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__TEXT_1: 'SMS',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__DESCRIPTION_1:
        'Customers are sent an SMS containing a link, directing them to rate the company and provide their feedback.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__TEXT_2: 'Viber',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__DESCRIPTION_2:
        'Customers receive a messenger message with a link to rate the company and leave feedback.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__TEXT_3: 'Cascade campaigns',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__DESCRIPTION_3:
        'Enhance the cost-effectiveness of generating reviews with cascade campaigns, systematically sending messages to customers one after the other.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__CHANNEL_ACTIVATION__FOOTER:
        'To enable and use your preferred channel, please contact your account manager.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__TRIAL__TITLE: 'Free trial period',
    REVIEWS_GENERATOR__MAILINGS_LANDING__TRIAL__SUBTITLE:
        'Activate a free trial for review generation using blasts and boost your company`s rating now.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__TRIAL__TEXT: 'The test period is 31 days',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__TITLE: 'How to set up a blast',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_TITLE_1: 'Import your customer database into your dashboard.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_TITLE_2: 'Set up blast parameters',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_DESCRIPTION_2:
        'Rating threshold, response to negative ratings, company locations, platforms for reviews, and send date/time.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_TITLE_3: 'Choose the desired blast type',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_DESCRIPTION_3:
        'Please contact your account manager to activate the preferred blast channel.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_TITLE_4: 'Evaluate the  effectiveness of your blasts',
    REVIEWS_GENERATOR__MAILINGS_LANDING__PROCESS__STEP_DESCRIPTION_4:
        'Explore comprehensive statistics in your dashboard, including metrics on messages delivered, viewed, reviews submitted, and link click-through rates. All negative reviews can also be conveniently found in this section.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ALGORITHM__TITLE: 'Algorithm for distinguishing positive and negative reviews',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ALGORITHM__DESCRIPTION:
        'When a user gives your company a high rating, they are encouraged to post their positive feedback on a review platform of your choice, which you can set along with the location link during the email sequence setup.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ALGORITHM__DESCRIPTION_NEGATIVE:
        'If a user provides a low rating, they are prompted to leave detailed feedback. Once submitted, this feedback is sent directly to the company`s dashboard for review and is not made public on external platforms.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ALGORITHM__TYPE_POSITIVE: 'Positive feedback',
    REVIEWS_GENERATOR__MAILINGS_LANDING__ALGORITHM__TYPE_NEGATIVE: 'Negative feedback',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MODAL__TITLE: 'Enabling the test period',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MODAL__MALINGS: 'Mailings',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MODAL__TEXT: 'Free shipments will be available to you for testing.',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MODAL__TRIAL: 'The duration of the test period',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MODAL__TRIAL_DATE: '31 days before {date}',
    REVIEWS_GENERATOR__MAILINGS_LANDING__MODAL__SUBMIT: 'Connect',

    REVIEWS_GENERATOR__MAILINGS_LANDING__REQUEST__SUCCESS__TITLE: 'Trial period enabled',
    REVIEWS_GENERATOR__MAILINGS_LANDING__REQUEST__SUCCESS__DETAILS: 'The service will be available until {date}',

    REVIEWS_GENERATOR__POPUP_HEADER__DELETE_MAILINGS: 'Delete notification campaign',
    REVIEWS_GENERATOR__POPUP_HEADER__DELETE_DRAFT_MAILINGS: 'Draft deleting',
    REVIEWS_GENERATOR__POPUP_HEADER__EDIT_MAILINGS: 'Editing the mailing list',
    REVIEWS_GENERATOR__POPUP_HEADER__CANCEL_EDIT_MAILING: 'Cancel mailing list editing?',
    REVIEWS_GENERATOR__POPUP_HEADER__CANCEL_CREATE_MAILING: 'Cancel mailing list creation?',
    REVIEWS_GENERATOR__POPUP__TEXT__CANCEL_EDIT_MAILING:
        'The changes will not be saved. The mailing list will remain in the Draft status.',
    REVIEWS_GENERATOR__POPUP__TEXT__CANCEL_CREATE_MAILING: 'The changes will not be saved.',
    REVIEWS_GENERATOR__POPUP__TEXT__THE_MAILINGS_WILL_BE_REMOVED_ARE_YOU_SURE:
        'The mailing list "{mailingName}" scheduled for {date} will be deleted.',
    REVIEWS_GENERATOR__POPUP__TEXT__THE_DRAFT_MAILINGS_WILL_BE_REMOVED_ARE_YOU_SURE:
        'The draft “{mailingName}” will be deleted.',
    REVIEWS_GENERATOR__POPUP__TEXT__THE_MAILINGS_WILL_BE_EDIT_ARE_YOU_SURE:
        '"{mailingName}" will be transferred to the draft status, the planned funds will be unfrozen. After editing, you will need to confirm the mailing again.',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__MAILING_DELETED: 'The mailing list has been successfully deleted',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__MAILING_DELETED__DRAFT: 'The draft was successfully deleted',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__MAILING_DELETE_ERROR: 'The mailing list could not be deleted',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__MAILING_DELETE_ERROR__DRAFT: 'The draft could not be deleted',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__MAILING_DELETE_ERROR__DESCRIPTION:
        'Please try again later, or contact technical support.',

    REVIEWS_GENERATOR__MAILING_DETAILS__STATS: 'Statistics',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW: 'Review',
    REVIEWS_GENERATOR__MAILING_DETAILS__CLIENTS: 'Clients',
    REVIEWS_GENERATOR__MAILING_DETAILS__AVG_RATING: 'Average Rating',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__SENT: 'Posted',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__DELIVERED: 'Delivered',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__CLICKED_LINKS: 'Clicks on links',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__POSITIVE_RATINGS: 'Positive ratings',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__CATALOG_TRANSITIONS: 'Catalog transitions',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__NEGATIVE_RATINGS: 'Negative ratings',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__NEGATIVE_FEEDBACK: 'Negative feedback',
    REVIEWS_GENERATOR__MAILING_DETAILS__CHART__UNSUBSCRIBE: 'Unsubscribe',
    REVIEWS_GENERATOR__MAILING_DETAILS__TREE__LINKS: 'Your link',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__SUMMARY: 'Summary',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__ACTION: 'Action',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__PERCENT: 'Percent',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__SOURCE: 'Transitions at the source',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__CATALOGS_TRANSITIONS: 'Transitions to catalogs',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__CATALOG: 'Catalog',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__CLICKS: 'Number of transitions',
    REVIEWS_GENERATOR__MAILING_DETAILS__REVIEW__SEND_MESSAGE: 'Sent message',
    REVIEWS_GENERATOR__MAILING_DETAILS__TABLE__CLIENT: 'Client',
    REVIEWS_GENERATOR__MAILING_DETAILS__TABLE__EMAIL: 'E-mail',
    REVIEWS_GENERATOR__MAILING_DETAILS__TABLE__PHONE: 'Phone',
    REVIEWS_GENERATOR__MAILING_DETAILS__TABLE__RATING: 'Rating',
    REVIEWS_GENERATOR__MAILING_DETAILS__TABLE__REVIEW: 'Review',
    REVIEWS_GENERATOR__MAILING_DETAILS__LIKE_TYPE: 'Like',
    REVIEWS_GENERATOR__MAILING_DETAILS__DISLIKE_TYPE: 'Dislike',
    REVIEWS_GENERATOR__MAILING_DETAILS__NOT_RATING_TYPE: 'Not rated',
    REVIEWS_GENERATOR__MAILING_DETAILS__SEARCH_BY_NAME: 'Search by customer name',
    REVIEWS_GENERATOR__MAILING_DETAILS__SEARCH_BY_EMAIL: 'Search by E-mail address',
    REVIEWS_GENERATOR__MAILING_DETAILS__SEARCH_BY_PHONE: 'Phone Search',

    REVIEWS_GENERATOR__CLIENTS__BUTTON__IMPORT: 'Import database',
    REVIEWS_GENERATOR__CLIENTS__BUTTON__CREATE: 'Add client',

    REVIEWS_GENERATOR__CLIENTS__TABLE__EMPTY_TEXT: 'Your client base is empty.',
    REVIEWS_GENERATOR__CLIENTS__TABLE__BUTTON__IMPORT: 'Import a database',

    REVIEWS_GENERATOR__CLIENTS__TOTAL: 'Total clients: {total}',
    REVIEWS_GENERATOR__CLIENTS__SEARCH_INPUT__PLACEHOLDER: 'Search by phone...',
    REVIEWS_GENERATOR__CLIENTS__SELECTED_COUNT: 'Clients selected:',
    REVIEWS_GENERATOR__CLIENTS__SELECT_ALL: 'Select all',
    REVIEWS_GENERATOR__CLIENTS__ACTIONS__REMOVE: 'Remove selected',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__CLIENT: 'Client',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__COMPANY: 'Company',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__BRANCH: 'Branch code',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__PHONE: 'Phone',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__NPS: 'NPS',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__ADDED: 'Added',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__SUBSCRIBED: 'Subscribed',
    REVIEWS_GENERATOR__CLIENTS__TABLE__COLUMNS__ACTIONS: 'Actions',

    REVIEWS_GENERATOR__POPUP_HEADER__DELETE_CLIENT: 'Delete a client?',
    REVIEWS_GENERATOR__POPUP_HEADER__DELETE_CLIENT_LIST: 'Delete clients?',
    REVIEWS_GENERATOR__POPUP__TEXT__THE_CLIENT_WILL_BE_REMOVED_ARE_YOU_SURE:
        'The client record will be deleted from the database.',
    REVIEWS_GENERATOR__POPUP__TEXT__THE_CLIENT_LIST_WILL_BE_REMOVED_ARE_YOU_SURE:
        'Customer records will be deleted from the database.',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_DELETED: 'The client has been deleted',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_DELETED_DESCRIPTION: 'You have deleted the client',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_LIST_DELETED: 'Clients deleted',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_LIST_DELETED_DESCRIPTION: 'You have deleted {count} customer records',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_DELETED__ERROR_ACTIVE_CLIENT__MANY:
        'Unfortunately, it is not possible to delete clients due to the following active mailings:',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_DELETED__ERROR_ACTIVE_CLIENT__SINGULAR:
        'Unfortunately, it is not possible to delete the client due to the following active mailings:',
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_DELETE_ERROR:
        "Couldn't delete the client, some error occurred. Please try again later or connect with support team",
    REVIEWS_GENERATOR__SNACKBAR__TEXT__CLIENT_LIST_DELETE_ERROR:
        "Couldn't delete the clients, some error occurred. Please try again later or connect with support team",

    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__GENERAL__TITLE: 'Basic information',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__BINDING__TITLE: 'Binding',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__NAME__TITLE: 'Name',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__NAME__PLACEHOLDER: 'Name',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__PHONE__TITLE: 'Phone number',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__PHONE__PLACEHOLDER:
        'Phone number with country and operator codes',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__EMAIL__TITLE: 'E-mail',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__EMAIL__PLACEHOLDER: 'user@example.com',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__COMPANY__TITLE: 'Company',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__COMPANY__PLACEHOLDER: 'Start typing the address',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__NPS__TITLE: 'NPS group',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__NPS__PLACEHOLDER: 'All NPS group',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__SUBSCRIBE__PLACEHOLDER: 'Subcribe',

    REVIEWS_GENERATOR__CLIENTS__SUBSCRIBE: 'Customers with a subscription',
    REVIEWS_GENERATOR__CLIENTS__UNSUBSCRIBE: 'Customers without a subscription',

    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__SAVE_BUTTON: 'Save',
    REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__DELETE_BUTTON: 'Remove',

    REVIEWS_GENERATOR__FILTER__EMPTY_TEXT: 'No filters applied',

    REVIEWS_GENERATOR__FILTER__FORM__INPUT__MAILING__PLACEHOLDER: 'All mailing lists',

    REVIEWS_GENERATOR__CLIENTS__IMPORT__STATISTICS__DATABASE_SIZE: 'Current base volume',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__STATISTICS__LAST_UPDATE: 'Last Update',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__STATISTICS__ERROR: 'Error. Please try again later or connect with support team',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__TITLE: 'Base requirements',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__TOOLTIP: 'You can read more detailed instructions',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__1: 'File-table .xlsx, size up to 500KB',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__2: 'The first line of the document contains the column headings,',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__3: 'Each line is customer information according to the template,',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__3_LINK: 'instructions for the template',
    REVIEWS_GENERATOR__CLIENTS__IMPORT__REQUIREMENTS__4:
        'Locations are indicated by the branch code and must be available in the Personal Account',

    REVIEWS_GENERATOR__CLIENTS__DOWNLOAD_DATABASE__BUTTON: 'Download current database',
    REVIEWS_GENERATOR__CLIENTS__DOWNLOAD_TEMPLATE__BUTTON: 'Download template',
    REVIEWS_GENERATOR__CLIENTS__CLEAR_DATABASE__BUTTON: 'Clear database',

    REVIEW_GENERATOR__POPUP_HEADER__DELETE_DATABASE: 'Clear Database?',
    REVIEW_GENERATOR__POPUP__TEXT__THE_DATABASE_WILL_BE_REMOVED_ARE_YOU_SURE:
        'Client database will be removed. Are you sure?',
    REVIEW_GENERATOR__SNACKBAR__TEXT__DATABASE_DELETED: 'The client base has been cleared',
    REVIEW_GENERATOR__SNACKBAR__TEXT__DATABASE_DELETED_DESCRIPTION: 'You have deleted all customer records.',
    REVIEW_GENERATOR__SNACKBAR__TEXT__DATABASE_DELETE_ERROR:
        "Couldn't removed the database, some error occurred. Please try again later or connect with support team",

    REVIEW_GENERATOR__CLIENTS__IMPORT__DND_AREA__HEADER: 'Download',
    REVIEW_GENERATOR__CLIENTS__IMPORT__DND_AREA__CONTENT: 'Select a file or drag it with the mouse to this area.',

    REVIEW_GENERATOR__CLIENTS__IMPORT__DATABASE_EXTENSION_ERROR__HEADER: 'Invalid file format',
    REVIEW_GENERATOR__CLIENTS__IMPORT__DATABASE_EXTENSION_ERROR__CONTENT:
        'Unable to read data. The file may not match the format. Please use the Excel file template.',

    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TITLE__UPLOADING: 'Uploading...',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TITLE__UPLOADED: 'Uploaded',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TITLE__UPLOADED_INVALID_DATA: 'Uploaded. There is invalid data',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TITLE__ERROR: 'Error',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TEXT_ERROR:
        'Unable to upload data. Please try again later or connect with support team.',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TITLE__VALIDATION_ERROR: 'Invalid file format',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__TEXT_VALIDATION_ERROR:
        'Unable to read data. The file may not match the format. Please use the Excel file template.',
    REVIEW_GENERATOR__CLIENTS__IMPORT__SUBMITTED__TITTLE: 'Client base updated',
    REVIEW_GENERATOR__CLIENTS__IMPORT__SUBMITTED__TEXT:
        'The file has been uploaded successfully. New customer records added.',
    REVIEW_GENERATOR__CLIENTS__IMPORT__UNABLE_SUBMIT__ERROR:
        'Unable to submit uploaded file. Please try again later or connect with support team.',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__RESPONSE__NEW: 'New records:',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__RESPONSE__INTERSECTION: 'Intersections:',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__RESPONSE__NPS_OVERRIDDEN: 'NPS overridden:',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__RESPONSE__TITLE_FAILED: 'Lines with error ({count}) will not be loaded',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__RESPONSE__DESCRIPTION_FAILED:
        'You can download a list of erroneous strings, fix them and upload them again',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__RESPONSE__UPLOAD_ERRORS: 'Download the list',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__BUTTON__SUBMIT: 'Submit',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__BUTTON__CANCEL: 'Cancel',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__BUTTON__CANCEL_UPLOAD: 'Cancel upload',
    REVIEW_GENERATOR__CLIENTS__IMPORT__POPUP__BUTTON__CLOSE: 'Close',

    REVIEWS_GENERATOR__TEMPLATES__INFO_ALERT: 'Creating your own template is done through a personal manager',
    REVIEWS_GENERATOR__TEMPLATES__TABLE__COLUMNS__TEMPLATES: 'Templates',
    REVIEWS_GENERATOR__TEMPLATES__TABLE__COLUMNS__MESSAGE: 'Message text',
    REVIEWS_GENERATOR__TEMPLATES__SEARCH_INPUT__PLACEHOLDER__NAME: 'Search by name',
    REVIEWS_GENERATOR__TEMPLATES__SEARCH_INPUT__PLACEHOLDER__TEXT: 'Search by message text',

    REVIEW_GENERATOR__MAIL__TITLE: 'Possible Rocketmail shipments',
    REVIEW_GENERATOR__MAIL__SUBTITLE: 'The quantity depends on the brand and the recipient`s country',
    REVIEW_GENERATOR__MAIL__DELIVERIES: 'Number of deliveries',
    REVIEW_GENERATOR__MAIL__CHANNEL_ACTIVATION: 'Activation Channel',
    REVIEW_GENERATOR__MAIL__SMS__TITLE: 'SMS',
    REVIEW_GENERATOR__MAIL__SMS__SUBTITLE: 'Short Message Service',
    REVIEW_GENERATOR__MAIL__WHATSAPP__TITLE: 'WhatsApp',
    REVIEW_GENERATOR__MAIL__WHATSAPP__SUBTITLE: 'Internet Messenger',
    REVIEW_GENERATOR__MAIL__LABEL__BRAND: 'Brand',
    REVIEW_GENERATOR__MAIL__LABEL__BRAND_PLACEHOLDER: 'Select a brand',
    REVIEW_GENERATOR__MAIL__LABEL__COUNTRY: 'Recipient`s country',
    REVIEW_GENERATOR__MAIL__LABEL__COUNTRY_PLACEHOLDER: 'Select a country',
    REVIEW_GENERATOR__CHANNEL_ACTIVATION__ERROR__FIELD_IS_REQUIRED: 'Select the activation channel',

    REVIEW_GENERATOR__QR_CODE_LIST__REQUEST_QR_CODE_LISTS: 'Download selected',
    REVIEW_GENERATOR__QR_CODE_LIST__SELECTED_COUNT: 'Selected QR codes: ',
    REVIEW_GENERATOR__QR_CODE_LIST__FOUND: 'Found: ',
    REVIEW_GENERATOR__QR_CODE_LIST__COUNT__SINGULAR: '{count} entry',
    REVIEW_GENERATOR__QR_CODE_LIST__COUNT__FEW: '{count} entries',
    REVIEW_GENERATOR__QR_CODE_LIST__COUNT__MANY: '{count} entries',
    REVIEW_GENERATOR__QR_CODE_LIST__EMPTY_TEXT: 'There are no QR sets yet. Create the first one!',
    REVIEW_GENERATOR__QR_CODE_LIST__EMPTY_BUTTON: 'Create a QR code set',
    REVIEW_GENERATOR__QR_CODE_LIST__PAGE__TITLE: 'Generated sets of QR codes',
    REVIEW_GENERATOR__QR_CODE_LIST__BUTTON__CREATE_NEW: 'Create QR Set',
    REVIEW_GENERATOR__QR_CODE_LIST__SEARCH_INPUT__LABEL: 'Search for sets',
    REVIEW_GENERATOR__QR_CODE_LIST__SEARCH_INPUT__PLACEHOLDER: 'Enter the title',
    REVIEW_GENERATOR__QR_CODE_LIST__QR_SET_LABEL: 'QR-set',
    REVIEW_GENERATOR__QR_CODE_LIST__QR_ITEM_LABEL: 'QR-code',
    REVIEW_GENERATOR__QR_CODE_LIST__QR_ITEM__CHANGE_LINK__HINT: 'Uses its own link',
    REVIEW_GENERATOR__QR_CODE_LIST__COMPANIES__SINGULAR: '{count} company',
    REVIEW_GENERATOR__QR_CODE_LIST__COMPANIES__FEW: '{count} companies',
    REVIEW_GENERATOR__QR_CODE_LIST__COMPANIES__MANY: '{count} companies',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__LINKS__SINGULAR: 'Click',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__LINKS__FEW: 'Clicks',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__LINKS__MANY: 'Clicks',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__POSITIVE__SINGULAR: 'Positive rating',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__POSITIVE__FEW: 'Positive ratings',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__POSITIVE__MANY: 'Positive ratings',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__NEGATIVE__SINGULAR: 'Negative rating',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__NEGATIVE__FEW: 'Negative ratings',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__NEGATIVE__MANY: 'Negative ratings',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__NEGATIVE_REVIEWS__SINGULAR: 'Negative review',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__NEGATIVE_REVIEWS__FEW: 'Negative reviews',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__NEGATIVE_REVIEWS__MANY: 'Negative reviews',
    REVIEW_GENERATOR__QR_CODE_LIST__STATS__AVG_RATING: 'Average rating',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETE_CONFIRM_HEADER__SINGLE: 'Deleting a QR code?',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETE_CONFIRM_HEADER__FEW: 'Deleting a set of QR codes?',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETE_CONFIRM_HEADER__MANY: 'Deleting a set of QR codes?',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETE_CONFIRM_TEXT__SINGLE:
        'The generated QR code “{name}“ will be deleted and will become invalid.',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETE_CONFIRM_TEXT__FEW:
        'The “{name}” set will be removed. The generated QR codes in this set will be invalid.',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETE_CONFIRM_TEXT__MANY:
        'The “{name}” set will be removed. The generated QR codes in this set will be invalid.',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETED_TITLE: 'Deleted',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETED__SINGLE: 'QR code deleted',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETED__FEW: 'QR code set deleted',
    REVIEW_GENERATOR__QR_CODE_LIST__DELETED__MANY: 'QR code set deleted',
    REVIEW_GENERATOR__QR_CODE_LIST__SENT_BY_EMAIL_TITLE: 'Sent',
    REVIEW_GENERATOR__QR_CODE_LIST__SENT_BY_EMAIL: 'QR code set sent by email',
    REVIEW_GENERATOR__QR_CODE_LIST__SENT_BY_EMAIL__ERROR__TITLE: 'Error sending QR code set',

    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__TITLE__SINGLE: 'QR-code «{name}»',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__TITLE__FEW: 'Set of QR-code set «{name}»',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__TITLE__MANY: 'Set of QR-code set «{name}»',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__BREAD_CRUMBS_TITLE__SINGLE: 'Created QR code',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__BREAD_CRUMBS_TITLE__FEW: 'Created set of QR codes',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__BREAD_CRUMBS_TITLE__MANY: 'Created set of QR codes',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__WEB_LINK: 'Web-link',

    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__SEARCH_INPUT__LABEL: 'Search QR codes',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__SEARCH_INPUT__PLACEHOLDER: 'Enter address or code',

    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__TOTAL_CLICKS__TOOLTIP: 'Total number of conversions',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__POSITIVE__TOOLTIP: 'Total number of positive ratings',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__NEGATIVE__TOOLTIP: 'Total number of negative ratings',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__NEGATIVE_REVIEWS__TOOLTIP: 'Total number of negative reviews',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__MULTI_QR_COMPANY_REDIRECTS__TOOLTIP:
        'Total number of redirects to the survey by company',
    REVIEW_GENERATOR__QR_CODE_DETAILS__PAGE__AVG_RATING__TOOLTIP: 'Average Rating',
    REVIEW_GENERATOR__QR_CODE_DETAILS__MULTI_SURVEY_TITLE: 'Universal QR Code',

    REVIEW_GENERATOR__EDIT_QR_CODE__PAGE__TITLE: 'Edit QR code set',
    REVIEW_GENERATOR__CREATE_QR_CODE__PAGE__TITLE: 'Create QR code set',
    REVIEW_GENERATOR__QR_CODE__PAGE__INFORMATION_STEP__TITLE: 'Basic Information',
    REVIEW_GENERATOR__QR_CODE__PAGE__CONTENT_STEP__TITLE: 'Content',
    REVIEW_GENERATOR__QR_CODE__PAGE__DESIGN_STEP__TITLE: 'Design',
    REVIEW_GENERATOR__QR_CODE__PAGE__BREAD_CRUMB: 'QR Code Set',

    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_REPORT_POPUP__HEADER: 'Uploading a report',
    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_REPORT_POPUP__INFO:
        'When the report is uploaded, the selected filters will be applied',
    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_REPORT_POPUP__TITLE: 'Full unloading of QR sets and QR codes',
    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_REPORT_POPUP__SUBTITLE:
        'Uploading with full statistics on QR sets with a QR code',
    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_REPORT_POPUP__EMPTY:
        'The list of QR sets is empty. Create a set or try to change the filters.',

    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_CLIENTS: 'Customer data',
    REVIEW_GENERATOR__QR_CODE__DOWNLOAD_REPORT: 'Report',

    REVIEW_GENERATOR__FORM_QR__TAB_LABEL__MAIN_INFORMATION: 'Main information',
    REVIEW_GENERATOR__FORM_QR__TAB_LABEL__DESIGN_SETTINGS: 'Design settings',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__QR_STYLE: 'QR-code styles',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__GRADIENT_TYPE: 'Gradient type',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__GRADIENT_ROTATION: 'Gradient rotation',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__FIRST_COLOR: 'First color {color}',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__SECOND_COLOR: 'Second color {color}',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__LOGO: 'Logo (optional)',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__LOGO_SIZE: 'Logo size',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__QR_STYLE__OPTION_SQUARE: 'Square',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__QR_STYLE__OPTION_CIRCLE: 'Circle',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__QR_STYLE__OPTION_SVG_PATH_SQUARE_DRAWER:
        'SvgPathSquareDrawer',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__QR_STYLE__OPTION_SVG_PATH_CIRCLE_DRAWER:
        'SvgPathCircleDrawer',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__QR_STYLE__OPTION: 'Style {count}',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__GRADIENT_TYPE__OPTION_LINEAR: 'Linear',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__INPUT_LABEL__GRADIENT_TYPE__OPTION_RADIAL: 'Radial',
    REVIEW_GENERATOR__FORM_QR_CUSTOMIZATION__NEXT_BUTTON: 'Continue',

    REVIEW_GENERATOR__FORM__INPUT__BRAND__LABEL: 'Select the brand from which the notification campaign will be made:',
    REVIEW_GENERATOR__FORM__NAME__SECTION__TITLE: 'Name',
    REVIEW_GENERATOR__FORM__INPUT__NAME__QR__LABEL: 'QR code set name',
    REVIEW_GENERATOR__FORM__INPUT__NAME__MAILING__LABEL: 'Name',
    REVIEW_GENERATOR__FORM__INPUT__NAME__QR__PLACEHOLDER: 'Type a name',
    REVIEW_GENERATOR__FORM__SKIP_QUESTIONNAIRE_RATINGS__LABEL: 'Do not collect ratings in the survey',
    REVIEW_GENERATOR__FORM__INPUT__BRANDNAME__LABEL: 'Brand name {% brand_name %}',
    REVIEW_GENERATOR__FORM__INPUT__BRANDNAME__HELP:
        'You can change the name of your brand or company. The {% brand_name %} tag can be used in the message text template and in the client.',
    REVIEW_GENERATOR__FORM__INPUT__NAME__MAILING__PLACEHOLDER: 'Type a name',
    REVIEW_GENERATOR__FORM__INPUT__NAME__DEFAULT: 'New notification campaign',
    REVIEW_GENERATOR__FORM__CLIENTS__SECTION__TITLE: 'Client selection',
    REVIEW_GENERATOR__FORM__INPUT__CLIENTS__LABEL: 'Selected',
    REVIEW_GENERATOR__FORM__INPUT__CLIENTS__PLACEHOLDER: 'Clients: {count} / {total}',
    REVIEW_GENERATOR__FORM__CONTENT__SECTION__TITLE: 'Content customization',
    REVIEW_GENERATOR__FORM__INPUT__MESSAGE__DEFAULT:
        'Dear customer, rate our company and help us become better: {%brand_name%}',

    REVIEW_GENERATOR__FORM_INPUT_LINK_HINT: 'You can insert a link in the format: [link name](url)',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__LABEL: 'Question text',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__TITLE: 'Formatting',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__TITLE_TEMPLATES: 'Replacement templates',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__LINEBREAK: 'Line break',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__LINEBREAK_DEF:
        '- is used both for ending a heading and for moving to a new line in regular text.',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__USERNAME: '{% username %}',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__USERNAME_DEF: '- client name.',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__UNSUBSCRIBE: 'Tag {% unsubscribe %}',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__UNSUBSCRIBE_DEF: '- link to the unsubscribe page',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__BRANDNAME_2: '{% brand_name %} tag',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__POPOVER__BRANDNAME_2_DEF:
        '- the display name of the brand, which can be changed above',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__QR_DEFAULT:
        'How do you rate your visit to our company?\nDear client, will you recommend our company to your friends and acquaintances?',
    REVIEW_GENERATOR__FORM__INPUT__QUESTION__MAILING_DEFAULT:
        '{% username %} hello!\nThank you for choosing company {% brand_name %}! Could you leave a review about your visit, this will help us become better.\n',
    REVIEW_GENERATOR__FORM__INPUT__RATING_TEXT__LABEL: 'Rating scale',
    REVIEW_GENERATOR__FORM__INPUT__RATING_TEXT__DEFAULT:
        "Rate on a scale of 1-10.\n1 - definitely won't\n10 - definitely will",
    REVIEW_GENERATOR__FORM__INPUT__RATING_BUTTON__LABEL: 'Button text',
    REVIEW_GENERATOR__FORM__INPUT__RATING_BUTTON__DEFAULT: 'Estimate',
    REVIEW_GENERATOR__FORM__INPUT__BUTTON_POSITIVE_REACTION__LABEL: 'The positive rating button',
    REVIEW_GENERATOR__FORM__INPUT__BUTTON_POSITIVE_REACTION__DEFAULT: 'Like',
    REVIEW_GENERATOR__FORM__INPUT__BUTTON_NEGATIVE_REACTION__LABEL: 'Negative rating button',
    REVIEW_GENERATOR__FORM__INPUT__BUTTON_NEGATIVE_REACTION__DEFAULT: 'Dislike',
    REVIEW_GENERATOR__FORM__INPUT__POSITIVE_REACTION__LABEL: 'Reaction to a positive assessment',
    REVIEW_GENERATOR__FORM__INPUT__POSITIVE_REACTION__LABEL__NEUTRAL: 'Reaction to feedback',
    REVIEW_GENERATOR__FORM__INPUT__POSITIVE_REACTION__DEFAULT:
        'Thank you for your rating!\nCould you leave a review about your visit? It will help us improve.',
    REVIEW_GENERATOR__FORM__INPUT__COMPANIES__LABEL: 'Companies',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__LABEL: 'Platforms for positive feedback',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__TOOLTIP_TITLE: 'Adding your own link is available',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__TOOLTIP_CONTENT:
        'You can insert a link to any of your own pages or websites so that your customers can leave reviews there.{break}{break}Please note that your own pages may reduce the conversion rate of leaving a review and will not improve the ratings of your locations on maps.',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__LABEL__NEUTRAL: 'Platforms for feedback',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__ERROR: 'The platforms for positive feedback have not been selected',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__ALERT__TITLE: 'There are unattached companies',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__ALERT__TEXT:
        'Some of the companies you have selected are not tied to the selected directories. Users will NOT be able to leave positive feedback on the card for these companies.',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__INFO__TEXT:
        'For some companies, not all catalogs will be available if you leave a positive review. The buttons for switching to the catalog will become available after the companies are synchronized.',
    REVIEW_GENERATOR__FORM__INPUT__CATALOGS__ALERT__SHOW_BUTTON: 'Show',
    REVIEW_GENERATOR__FORM__INPUT__CATALOG__CHECKBOX_LABEL: 'In catalogs',
    REVIEW_GENERATOR__FORM__INPUT__CATALOG_REPLACE__CHECKBOX_LABEL: 'By custom link',
    REVIEW_GENERATOR__FORM__INPUT__CATALOG_REPLACE__CHECKBOX_HELP:
        'Insert a link to any of your pages or websites so that your customers leave reviews there. \n\nKeep in mind that your own pages may decrease the conversion rate of leaving a review, and will not improve the ranking of your locations on maps.',
    REVIEW_GENERATOR__FORM__INPUT__NEGATIVE_REACTION__LABEL: 'Reaction to negative assessment',
    REVIEW_GENERATOR__FORM__INPUT__NEGATIVE_REACTION__QR_DEFAULT:
        'We are very sorry for your negative experience!\nCould you tell us about it, it will help us become better.',
    REVIEW_GENERATOR__FORM__INPUT__NEGATIVE_REACTION__MAILING_DEFAULT:
        '{% username %}, we are very sorry for your negative experience!\nCould you tell us about it, it will help us become better.',
    REVIEW_GENERATOR__FORM__INPUT__THANK_POSITIVE__LABEL: 'Thank you for the positive feedback',
    REVIEW_GENERATOR__FORM__INPUT__THANK_NEUTRAL__LABEL: 'Thank you for the feedback',
    REVIEW_GENERATOR__FORM__INPUT__THANK_NEGATIVE__LABEL: 'Thank you for the negative feedback',
    REVIEW_GENERATOR__FORM__INPUT__THANK__DEFAULT:
        'Thank you for your feedback!\nWe will definitely consider your review and become better!',
    REVIEW_GENERATOR__FORM__DESIGN__SECTION__TITLE: 'Design',
    REVIEW_GENERATOR__FORM__DESIGN__SAVED_SETTINGS: 'Saved Settings',
    REVIEW_GENERATOR__FORM__INPUT__LOGO__LABEL: 'Company logo',
    REVIEW_GENERATOR__FORM__INPUT__LOGO__FORMAT: '• Recommended format .PNG',
    REVIEW_GENERATOR__FORM__INPUT__LOGO__MAX_SIZE: '• Maximum size 1 MB',
    REVIEW_GENERATOR__FORM__INPUT__COLOR_INTERFACE__LABEL: 'Accent color',
    REVIEW_GENERATOR__FORM__INPUT__LOGO_HEIGHT__LABEL: 'Logo height (px)',
    REVIEW_GENERATOR__FORM__CHECKBOX__LABEL: 'Save setting for future questionnaires',
    REVIEW_GENERATOR__FORM__ADDITIONAL__SECTION__TITLE: 'Feedback Form Settings',
    REVIEW_GENERATOR__FORM__ADDITIONAL__SECTION__MAILING__TITLE: 'Additional Mailing Settings',
    REVIEW_GENERATOR__FORM__REQUIRED: 'Required to fill in',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__LABEL__TITLE: 'Label: Your name',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__LABEL__SWITCH: 'Display the field «Your name» ',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__LABEL__PLACEHOLDER: 'Your name',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__LABEL__DEFAULT: 'Your name',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__PLACEHOLDER__TITLE: 'Placeholder: Your name',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__PLACEHOLDER__PLACEHOLDER: 'Full name',
    REVIEW_GENERATOR__FORM__INPUT__NAME_INPUT__PLACEHOLDER__DEFAULT: 'Full name',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_INPUT__LABEL__TITLE: 'Label: Your email',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_INPUT__LABEL__SWITCH: 'Display the field «Your email» ',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL__INPUT__LABEL__TOOLTIP_TITLE:
        'When the bonus for a review is active, the "Email Address" field is required for the client to fill in.',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_INPUT__LABEL__PLACEHOLDER: 'Your email',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_INPUT__LABEL__DEFAULT: 'Your email',
    REVIEW_GENERATOR__FORM__INPUT__PHONE_INPUT__LABEL__TITLE: 'Label: Phone number',
    REVIEW_GENERATOR__FORM__INPUT__PHONE_INPUT__LABEL__SWITCH: 'Display the field «Phone number» ',
    REVIEW_GENERATOR__FORM__INPUT__PHONE_INPUT__LABEL__PLACEHOLDER: 'Phone number',
    REVIEW_GENERATOR__FORM__INPUT__PHONE_INPUT__LABEL__DEFAULT: 'Phone number',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__LABEL__TITLE: 'Label: Review text',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__LABEL__PLACEHOLDER: 'Review text',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__LABEL__DEFAULT: 'Review text',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__PLACEHOLDER__TITLE: 'Placeholder: Review text',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__PLACEHOLDER__PLACEHOLDER: 'Enter the text of your feedback here…',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__PLACEHOLDER__DEFAULT: 'Enter the text of your feedback here…',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__SUBMIT__TITLE: 'Button: Send',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__SUBMIT__PLACEHOLDER: 'Send',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__SUBMIT__DEFAULT: 'Send',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__SKIP_BUTTON__TITLE: 'Button: Skip',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__SKIP_BUTTON__PLACEHOLDER: 'Skip',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__SKIP_BUTTON__DEFAULT: 'Skip',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__REVIEW_BUTTON__TITLE: 'Button: Leave a review',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__REVIEW_BUTTON__PLACEHOLDER: 'Leave a review',
    REVIEW_GENERATOR__FORM__INPUT__REVIEW_INPUT__REVIEW_BUTTON__DEFAULT: 'Leave a review',
    REVIEW_GENERATOR__FORM__HANDLE_REVIEWS__SECTION__TITLE: 'Processing reviews',
    REVIEW_GENERATOR__FORM__HANDLE_REVIEWS__RATING_SCALE_TYPE__TEN: '10-point',
    REVIEW_GENERATOR__FORM__HANDLE_REVIEWS__RATING_SCALE_TYPE__FIVE: '5-point',
    REVIEW_GENERATOR__FORM__HANDLE_REVIEWS__RATING_SCALE_TYPE__TWO: 'Like / Dislike',
    REVIEW_GENERATOR__FORM__TERMS_AND_CONDITIONS__TITLE: 'Privacy policy',
    REVIEW_GENERATOR__FORM__INPUT__TERMS_AND_CONDITIONS__LABEL: 'Content language',
    REVIEW_GENERATOR__FORM__INPUT__POSITIVE_THRESHOLD__LABEL: 'Positive assessment threshold',
    REVIEW_GENERATOR__FORM__INPUT__POSITIVE_THRESHOLD__OPTION: '{threshold} and more',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_FOR_NEGATIVE_REVIEW__ENABLE: 'Send bad reviews to email',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_FOR_NEGATIVE_REVIEW__LABEL: 'E-mail address',
    REVIEW_GENERATOR__FORM__INPUT__TELEGRAM_FOR_NEGATIVE_REVIEW__ENABLE: 'Send bad reviews to telegram',
    REVIEW_GENERATOR__FORM__INPUT__TELEGRAM_FOR_NEGATIVE_REVIEW__LABEL: 'Telegram chat token',
    REVIEW_GENERATOR__FORM__INPUT__TELEGRAM_FOR_NEGATIVE_REVIEW__PLACEHOLDER: 'Telegram chat token',
    REVIEW_GENERATOR__FORM__INPUT__AUTO_TAG__SWITCH: 'Automatically tag reviews',
    REVIEW_GENERATOR__FORM__INPUT__EMAIL_FOR_NEGATIVE_REVIEW__PLACEHOLDER: 'E-mail address',
    REVIEW_GENERATOR__FORM__BALANCE__SECTION__TITLE: 'Mailing Parameters',
    REVIEW_GENERATOR__FORM__INPUT__MAILING_TYPE__LABEL: 'Activation Channel',
    REVIEW_GENERATOR__FORM__INPUT__MESSAGE_TEMPLATE__LABEL: 'Message Template',
    REVIEW_GENERATOR__FORM__INPUT__MAILING_TYPE__PLACEHOLDER: 'Not selected',
    REVIEW_GENERATOR__FORM__SCHEDULE__SECTION__TITLE: 'Mailing time',
    REVIEW_GENERATOR__FORM__INPUT__MAILING_SCHEDULE__LABEL: 'Sending date and time',
    REVIEW_GENERATOR__FORM__CHECKBOX__MAILING_SCHEDULE__LABEL: 'Delayed mailing',
    REVIEW_GENERATOR__FORM__INPUT__MAILING_SCHEDULE_TIMEZONE__LABEL: 'Timezone',
    REVIEW_GENERATOR__FORM__INPUT__MAILING_SCHEDULE_TIMEZONE__PLACEHOLDER: 'Timezone',
    REVIEW_GENERATOR__FORM__SUBMIT_VALIDATION: 'Fill in all the fields with correct values',
    REVIEW_GENERATOR__FORM__TARGET_NAME_VALIDATION: 'Activation channel not selected',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__HEADER: 'Mailing confirmation',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__CLIENTS_SELECTED__SINGULAR: 'Selected {selected} client',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__CLIENTS_SELECTED__FEW: 'Selected {selected} clients',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__CLIENTS_SELECTED__MANY: 'Selected {selected} clients',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__MAILING_TARGET: '{target} mailing',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__COUNTRY: 'Country',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__MAILINGS_COUNT: 'Mailings count',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__CLIENTS_COUNT: 'Clients count',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__FEW_MESSAGES:
        'The number of messages has been increased because the text exceeds the allowed number of characters in one SMS. {count} messages will be sent to clients.',
    REVIEW_GENERATOR__FORM__SUBMIT__MAILING__CONFIRM__FOOTER:
        'By clicking the "{link}" button, you guarantee that you have received all the necessary permissions and consents to send mail to Subjects.',

    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__RATING_PAGE_TEXT__LABEL: 'Text: Reason for mailing',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__RATING_PAGE_UNSUBSCRIBE_LINK_TEXT__LABEL: 'Button: Unsubscribe',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_TITLE__LABEL: 'Header: Subscription renewed',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_TEXT__LABEL: 'Text: Subscription renewed',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__RETURN_TO_SURVEY_BUTTON_TEXT__LABEL: 'Button: Return to survey',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_AGAIN_TEXT__LABEL: 'Text: Renew subscription',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_AGAIN_BUTTON_TEXT__LABEL: 'Button: Subscribe again',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__UNSUBSCRIBE_TITLE__LABEL: 'Header: Subscription canceled',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__UNSUBSCRIBE_TEXT__LABEL: 'Text: Subscription canceled',

    REVIEW_GENERATOR__FORM__PLACEHOLDER__TEXT: 'Enter text',
    REVIEW_GENERATOR__FORM__PLACEHOLDER__BUTTON_TEXT: 'Enter button text',
    REVIEW_GENERATOR__FORM__PLACEHOLDER__LINK_TEXT: 'Enter button text',
    REVIEW_GENERATOR__FORM__PLACEHOLDER__TITLE_TEXT: 'Enter title text',

    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__RATING_PAGE_TEXT__DEFAULT_VALUE:
        'You received this email because you are a customer of {% brand_name %}\nIf you no longer wish to receive mailings, you can {% unsubscribe %}',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__RATING_PAGE_UNSUBSCRIBE_LINK_TEXT__DEFAULT_VALUE: 'Unsubscribe',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_TITLE__DEFAULT_VALUE: 'We are glad to see you again!',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_TEXT__DEFAULT_VALUE:
        'You are now subscribed to {% brand_name %} again and will receive mailings.',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__RETURN_TO_SURVEY_BUTTON_TEXT__DEFAULT_VALUE: 'Return to survey',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_AGAIN_TEXT__DEFAULT_VALUE: 'Unsubscribed by accident?',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__SUBSCRIBE_AGAIN_BUTTON_TEXT__DEFAULT_VALUE: 'Subscribe again',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__UNSUBSCRIBE_TITLE__DEFAULT_VALUE: 'Subscription canceled',
    REVIEW_GENERATOR__FORM__MAILING_SETTINGS__UNSUBSCRIBE_TEXT__DEFAULT_VALUE:
        'You have been unsubscribed from {% brand_name %} and will no longer receive mailings.',

    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__TITLE: 'Settings for the company selection page',

    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__TITLE__LABEL: 'Title: Company selection',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__SEARCH_PLACEHOLDER__LABEL: 'Text: Company search',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__BUTTON__LABEL: 'Button: Continue',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__COMPANY_NAME: 'Company display style in the list',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__ADDRESS: 'Address',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__NAME_OPTION: 'Name',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__NAME_AND_ADDRESS_OPTION: 'Name with address',

    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__TITLE__PLACEHOLDER: 'Enter the title text',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__SEARCH_PLACEHOLDER__PLACEHOLDER:
        'Enter the text for the search field',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__BUTTON__PLACEHOLDER: 'Enter the text for the continue button',

    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__TITLE__DEFAULT_VALUE: 'Company selection',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__SEARCH_PLACEHOLDER__DEFAULT_VALUE: 'Enter address for search',
    REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__BUTTON__DEFAULT_VALUE: 'Continue',

    REVIEW_GENERATOR_FORM__MAILING_SETTINGS__BRAND_NAME_NOT_IN_TEMPLATE:
        'The template {template} must be used in the text',

    REVIEW_GENERATOR__FORM__INPUT__LOCKED_TEMPLATE__TEXT:
        'QR codes for selected locations and catalogs have already been created. You can edit only those fields that appear on this page.',

    REVIEW_GENERATOR__FORM__UNSYNCHRONISED_COMPANIES_MODAL__TITLE: 'Unsynchronized companies',
    REVIEW_GENERATOR__FORM__UNSYNCHRONISED_COMPANIES_MODAL__HEADER:
        '{count} out of {total} companies are not linked to cards in selected catalogs',
    REVIEW_GENERATOR__FORM__UNSYNCHRONISED_COMPANIES_MODAL__SUB_HEADER:
        'You can exclude these companies from the set, then QR codes will not be generated for them. Or create QR codes for them anyway and wait for synchronization.',
    REVIEW_GENERATOR__FORM__UNSYNCHRONISED_COMPANIES_MODAL__INFO:
        'Important! As long as the company is not linked to any of the selected catalogs, the user will have no button to leave a positive review.',
    REVIEW_GENERATOR__FORM__UNSYNCHRONISED_COMPANIES__EXCLUDE: 'Exclude from set:',
    REVIEW_GENERATOR__FORM__UNSYNCHRONISED_COMPANIES__TOTAL: 'Total companies in the set:',
    REVIEW_GENERATOR__FORM__UNSYNC_COMPANIES__EXCLUDE_ALL_BUTTON: "Don't create for everyone",
    REVIEW_GENERATOR__FORM__UNSYNC_COMPANIES__INCLUDE_ALL_BUTTON: 'Create for everyone',
    REVIEW_GENERATOR__FORM__UNSYNC_COMPANIES__EXCLUDE_ONE_BUTTON: "Don't create",

    REVIEW_GENERATOR__FORM__UPDATE_ERROR: 'Failed to save data. Please try again later or contact the support team',
    REVIEW_GENERATOR__FORM__INPUT__PROMO_CODE__TEXT__DEFAULT:
        'For providing a review, you will receive a reward in the form of a promo code 🎉',
    REVIEW_GENERATOR__FORM__INPUT__PROMO_CODE__TEXT_THANKS__DEFAULT: 'The promo code will be sent to you shortly.',

    REVIEW_GENERATOR__TEMPLATE__PREVIEW__LABEL: 'Preview on the phone',

    REVIEW_GENERATOR__MAILING__FORM__START_CREATING_BUTTON: 'Start creating',
    REVIEW_GENERATOR__MAILING__FORM__TABLE__BRAND__COLUMN: 'Brand',

    REVIEW_GENERATOR__QR_CODE__FORM__DOWNLOAD_BUTTON: 'Save and download the set',
    REVIEW_GENERATOR__QR_CODE__FORM__DOWNLOAD_ERROR:
        'Failed to load data. Please try again later or contact the support team.',
    REVIEW_GENERATOR__QR_CODE__FORM__PREVIEW_BUTTON: 'Preview and links',

    REVIEW_GENERATOR__QR_CODE__FORM__PREVIEW__TITLE: 'Preview',
    REVIEW_GENERATOR__QR_CODE__COPY_LINK: 'Copy link',
    REVIEW_GENERATOR__QR_CODE__LINK_COPIED: 'Link is copied',
    REVIEW_GENERATOR__QR_CODE__OPEN_SURVEY: 'Go to survey',

    REVIEWS_GENERATOR__NEGATIVE_REVIEWS__TOTAL: 'Total: {total}',

    REVIEWS_GENERATOR__NEGATIVE_REVIEW__FILTER__FORM__INPUT__RANGE__TITLE: 'Date of creation',
    REVIEWS_GENERATOR__NEGATIVE_REVIEW__FILTER__FORM__INPUT__MAILING__TITLE: 'Notification campaigns',

    REVIEWS_GENERATOR__NPS__TITLE: 'Net promoter score(NPS)',
    REVIEWS_GENERATOR__NPS__SUB_TITLE: 'Dynamics of NPS change',
    REVIEWS_GENERATOR__NPS__CHART_LABEL: 'NPS',
    REVIEWS_GENERATOR__NPS__CHART_PROMOTERS_LABEL: 'Promoters',
    REVIEWS_GENERATOR__NPS__CHART_NEUTRALS_LABEL: 'Neutrals',
    REVIEWS_GENERATOR__NPS__CHART_DETRACTORS_LABEL: 'Detractors',
    REVIEWS_GENERATOR__NPS__CHART__OPTION__1MONTH: '1 month',
    REVIEWS_GENERATOR__NPS__CHART__OPTION__3MONTH: '3 months',
    REVIEWS_GENERATOR__NPS__CHART__OPTION__6MONTH: '6 months',
    REVIEWS_GENERATOR__NPS__CHART__OPTION__1YEAR: '1 year',

    REVIEWS_GENERATOR__CREATE_QUESTIONNAIRE: 'Create a questionnaire',
    REVIEWS_GENERATOR__EDIT_QUESTIONNAIRE: 'Edit survey',
    REVIEWS_GENERATOR__PREVIEW_TITLE: 'Preview',
    REVIEWS_GENERATOR__MAIN_SETTINGS_TITLE: 'Main settings',
    REVIEWS_GENERATOR__RATING_SCALE_TITLE: 'Rating scale',
    REVIEWS_GENERATOR__PROCESSING_REVIEWS_TITLE: 'Processing reviews',
    REVIEW_GENERATOR__QUESTIONNAIRE__NAME__TITLE: 'Title',
    REVIEW_GENERATOR__QUESTIONNAIRE__NAME__PLACEHOLDER: 'Enter a title',
    REVIEW_GENERATOR__QUESTIONNAIRE__BRAND__TITLE: 'Brand',
    REVIEW_GENERATOR__QUESTIONNAIRE__BRAND__PLACEHOLDER: 'Select a brand',

    REVIEW_GENERATOR__QUESTIONNAIRE__CREATE__SUCCESS: 'Questionnaire created successfully',
    REVIEW_GENERATOR__QUESTIONNAIRE__UPDATE__SUCCESS: 'Questionnaire updated successfully',

    REVIEW_GENERATOR__QUESTIONNAIRE_SELECT__LABEL: 'Survey',
    REVIEW_GENERATOR__QUESTIONNAIRE_SELECT__LABEL__ADDITIONAL_INFO:
        'Preview is not displayed when selecting a survey with quick access to the company.',
    REVIEW_GENERATOR__QUESTIONNAIRE_SELECT__PLACEHOLDER: 'Select a survey',
    REVIEW_GENERATOR__QUESTIONNAIRE_SELECT__CREATE_QUESTIONNAIRE: 'Go to create a new survey',
    REVIEW_GENERATOR__QUESTIONNAIRE_SELECT__UPDATE_QUESTIONNAIRE: 'Go to edit the questionnaire',

    REVIEW_GENERATOR__QUESTIONNAIRE_PREVIEW__QR_CODE: 'From QR Code',
    REVIEW_GENERATOR__QUESTIONNAIRE_PREVIEW__MAILING: 'From Mailing',

    REVIEW_GENERATOR__QUESTIONNAIRE__STATUS__READY: 'Ready',
    REVIEW_GENERATOR__QUESTIONNAIRE__STATUS__READY__TOOLTIP:
        'The questionnaire is ready for use in QR codes and mailings',
    REVIEW_GENERATOR__QUESTIONNAIRE__STATUS__RELEASED: 'In Use',
    REVIEW_GENERATOR__QUESTIONNAIRE__STATUS__RELEASED__TOOLTIP: 'The questionnaire is used in mailings and/or QR codes',

    REVIEW_GENERATOR__QUESTIONNAIRE__DELETE__TITLE: 'Delete questionnaire',
    REVIEW_GENERATOR__QUESTIONNAIRE__DELETE__SUCCESS: 'Questionnaire successfully deleted',
    REVIEW_GENERATOR__QUESTIONNAIRE__DELETE__MESSAGE: 'The questionnaire "{name}" will be deleted. Continue?',

    REVIEW_GENERATOR__QUESTIONNAIRE__FORM__DIRECT_REDIRECT_CHECKBOX_LABEL: 'Quick access to company profile',
    REVIEW_GENERATOR__QUESTIONNAIRE__FORM__DIRECT_REDIRECT_CHECKBOX_DESCRIPTION:
        'If this option is used, customers who scan the QR code or open the link from the mailing will be immediately redirected to the company profile in the selected directory',
    REVIEW_GENERATOR__QUESTIONNAIRE__FORM__SHOW_ADDRESS_LABEL:
        'Display the address of the assessed company in the questionnaire',
    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__TITLE: 'Texts',
    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__MAIN_TEXTS__TITLE: 'Main texts',
    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__PROMOCODE__TITLE: 'Promo code',
    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__MAIN_TEXTS__MULTISURVEY_SWITCH: 'Multi-page survey',
    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__MAIN_TEXTS__MULTISURVEY_SWITCH_ADDITIONAL_INFO:
        'A survey with multiple pages of questions. The average score after completing the survey will be used to direct the user to a platform or feedback form.',

    REVIEW_GENERATOR__QUESTIONNAIRE__LEAVE_CREATION_PAGE__TITLE: 'Cancel survey creation?',
    REVIEW_GENERATOR__QUESTIONNAIRE__LEAVE_CREATION_PAGE__DESCRIPTION: 'The survey will not be saved.',
    REVIEW_GENERATOR__QUESTIONNAIRE__LEAVE_UPDATE_PAGE__TITLE: 'Cancel survey editing?',
    REVIEW_GENERATOR__QUESTIONNAIRE__LEAVE_UPDATE_PAGE__DESCRIPTION: 'Changes will not be saved.',

    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__FEEDBACK_FORM__TITLE: 'Feedback form',
    REVIEW_GENERATOR__QUESTIONNAIRE__TEXTS__ADDITIONAL_LINKS__TITLE: 'Additional email texts',

    REVIEWS_GENERATOR_STATISTICS__POSITIVE_REVIEWS: 'Positive Reviews',
    REVIEWS_GENERATOR_STATISTICS__NEGATIVE_REVIEWS: 'Negative Reviews',
    REVIEWS_GENERATOR_STATISTICS__SENT_PROMO_CODES: 'Sent Promo Codes',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__TITLE: 'User Actions in Surveys',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__CHART__REVIEWS: 'Reviews',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__CHART__RATINGS: 'Ratings',

    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__OPEN_QUESTIONNAIRE: 'Transitions',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__POSITIVE_REVIEW: 'Positive reviews',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__NEGATIVE_REVIEW: 'Negative Reviews',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__POSITIVE_RATING: 'Positive Ratings',
    REVIEWS_GENERATOR_STATISTICS__USER_ACTIONS__NEGATIVE_RATING: 'Negative Ratings',

    REVIEWS_GENERATOR_STATISTICS__TITLE: 'Survey statistics',
    REVIEWS_GENERATOR_STATISTICS__TRANSITIONS__TITLE: 'Transitions to Directories',
    REVIEWS_GENERATOR_STATISTICS__TRANSITIONS__GOOGLE_BUSINESS: 'Google Business Profile',
    REVIEWS_GENERATOR_STATISTICS__TRANSITIONS__YANDEX_MAPS: 'Yandex Maps',
    REVIEWS_GENERATOR_STATISTICS__TRANSITIONS__W_GIS: '2GIS',
    REVIEWS_GENERATOR_STATISTICS__TRANSITIONS__CUSTOM_LINK: 'Custom Link',
    REVIEWS_GENERATOR_STATISTICS__DOWNLOAD_REPORT: 'Download data',
    REVIEWS_GENERATOR_STATISTICS__SENT_BY_EMAIL__TITLE: 'Request sent',
    REVIEWS_GENERATOR_STATISTICS__SENT_BY_EMAIL__DESCRIPTION:
        'The requested report will be sent to the email address provided in your profile.',
    REVIEWS_GENERATOR_STATISTICS__SENT_BY_EMAIL__ERROR_DESCRIPTION:
        'Error sending the report, please contact the support team',

    REVIEWS_GENERATOR_STATISTICS__TRANSITIONS__TOTAL_TRANSITIONS: 'Total',

    REVIEW_GENERATOR_STATISTICS__FILTER__SOURCE_ALL: 'All methods',
    REVIEW_GENERATOR_STATISTICS__FILTER__SOURCE_MAILINGS: 'Mailings',
    REVIEW_GENERATOR_STATISTICS__FILTER__SOURCE_QR_CODES: 'QR codes',

    REVIEW_GENERATOR_STATISTICS__FILTER__QR_CODE_SELECTOR__PLACEHOLDER: 'All QR codes',
    REVIEW_GENERATOR_STATISTICS__FILTER__MAILING_SELECT__PLACEHOLDER: 'All mailings',

    REVIEW_GENERATOR__FORM__CATALOGS__NEGATIVE_SOURCES_TITLE: 'Platforms for negative reviews',
    REVIEW_GENERATOR__FORM__CATALOGS__NEGATIVE_FORM_LABEL: 'Feedback form',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__NEGATIVE_LABEL: 'Links for negative reviews',
    REVIEW_GENERATOR__FORM__CATALOGS_OR_CHANGE_LINKS__NEGATIVE_LABEL: 'Catalogs or custom links for negative reviews',
    REVIEW_GENERATOR__FORM__SOURCES__ADD_SOURCE: 'Add a platform',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__ADD_LINK: 'Add link',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__TEXT__PLACEHOLDER: 'Button name',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__URL__PLACEHOLDER: 'URL link',

    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__LABEL: 'Logo',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__ERROR__IMAGE_UPLOAD: 'Maximum image size {maxSize} MB',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__ERROR__IMAGE__TITLE: 'Image upload error',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__ERROR__IMAGE_SIZE: 'Failed to upload the image.',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__FROM_CATALOG: 'Selected automatically',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__UPLOADED: 'Uploaded logo',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__IN_PROGRESS_ERROR: 'Wait for the upload to finish',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__NON_UNIQUE_LINK_NAME: 'Button name is already used',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__NON_UNIQUE_LINK_URL: 'URL link is already used',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__TOOLTIP: 'Upload logo',
    REVIEW_GENERATOR__FORM__CHANGE_LINKS__LOGO__ERROR: 'Logo upload error',
};
