import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCloudUploadAlt, faDownload, faFileCheck} from '@fortawesome/pro-solid-svg-icons';

import {appRoute} from '../../../../../../app-route';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';

type FeedRouteButtonType = {
    label: LangKeyType;
    description: LangKeyType;
    link?: string;
    icon: IconProp;
    canHaveErrors?: boolean;
};

export const LINKS: Array<FeedRouteButtonType> = [
    {
        label: 'FEED__ROUTES_MODAL__VALIDATION__LABEL',
        description: 'FEED__ROUTES_MODAL__VALIDATION__DESCRIPTION',
        link: appRoute.companyImport.path,
        icon: faFileCheck,
    },
    {
        label: 'FEED__ROUTES_MODAL__IMPORT__LABEL',
        description: 'FEED__ROUTES_MODAL__IMPORT__DESCRIPTION',
        link: appRoute.companyImport.path,
        icon: faCloudUploadAlt,
    },
    {
        label: 'FEED__ROUTES_MODAL__EXPORT__LABEL',
        description: 'FEED__ROUTES_MODAL__EXPORT__DESCRIPTION',
        link: appRoute.companyExport.path,
        icon: faDownload,
    },
];
