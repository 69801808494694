import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Space} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FakesChangeMutateType} from '../../../../../../../service/fakes/fakes-change';
import {FakeInternalStatusEnum} from '../../../../../../../service/fakes/fakes-type';
import * as styles from '../actions-column.scss';

type PropsType = {
    mutate: FakesChangeMutateType;
};

export function ActionsColumnModeration(props: PropsType): JSX.Element {
    const {mutate} = props;

    const menuItems = [
        {
            key: 'client',
            label: <Locale stringKey="FAKES__RESULTS__BUTTON__MY_LOCATION" />,
            onClick: () => mutate(FakeInternalStatusEnum.ClientLocation),
        },
        {
            key: 'not-client',
            label: <Locale stringKey="FAKES__RESULTS__BUTTON__NOT_MY_LOCATION" />,
            onClick: () => mutate(FakeInternalStatusEnum.NotClientLocation),
        },
    ];

    return (
        <Space className={styles.ActionColumn} direction="vertical" size={12}>
            <AdditionalInfo
                leftNode={<Text lighter stringKey="FAKES__STATUS__MODERATION__DESCRIPTION" />}
                placement="bottom"
                title={<Locale stringKey="FAKES__RESULTS__MODERATION__SUBTITLE" />}
            >
                <Locale stringKey="FAKES__RESULTS__MODERATION__DESCRIPTION" />

                <Text block secondary stringKey="FAKES__RESULTS__MODERATION__TIME" />
            </AdditionalInfo>

            <Dropdown menu={{items: menuItems}}>
                <Button>
                    <Space>
                        <Locale stringKey="FAKES__RESULTS__MODERATION__BUTTON" />
                        <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    </Space>
                </Button>
            </Dropdown>
        </Space>
    );
}
