import {LangKeyType} from '../../../../../../provider/locale/translation/type';

import * as styles from './stories-column.scss';

type GetStoriesParametersType = {
    isOnModeration: boolean | undefined;
    isRejected: boolean | undefined;
    isPublished: boolean | undefined;
    canAddStories: boolean | undefined;
    isEmpty: boolean;
};

type GetStoriesReturnType = {
    tooltipLangKey: LangKeyType;
    modifierClassName: string;
};

export function getStoriesInformation(parameters: GetStoriesParametersType): GetStoriesReturnType {
    const {isOnModeration, isPublished, isRejected, canAddStories, isEmpty} = parameters;

    let tooltipLangKey: LangKeyType = 'YANDEX_STORY__SOURCES__TOOLTIP__NEW';
    let modifierClassName = styles.StoriesColumn_container__new;

    if (!canAddStories) {
        tooltipLangKey = 'YANDEX_STORY__SOURCES__TOOLTIP__FORBIDDEN';
        modifierClassName = '';
    }

    if (isEmpty) {
        return {tooltipLangKey, modifierClassName};
    }

    if (isOnModeration) {
        tooltipLangKey = 'YANDEX_STORY__SOURCES__TOOLTIP__ON_MODERATION';
        modifierClassName = styles.StoriesColumn_container__onModeration;
    }

    if (isRejected) {
        tooltipLangKey = 'YANDEX_STORY__SOURCES__TOOLTIP__REJECTED';
        modifierClassName = styles.StoriesColumn_container__rejected;
    }

    if (isPublished) {
        tooltipLangKey = 'YANDEX_STORY__SOURCES__MODAL__TITLE';
        modifierClassName = styles.StoriesColumn_container__published;
    }

    return {
        tooltipLangKey,
        modifierClassName,
    };
}
