import {faFileArrowDown} from '@fortawesome/pro-regular-svg-icons';
import {faFilePlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Row, Upload, UploadFile} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {file2Obj} from 'antd/lib/upload/utils';
import {JSX, useState} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {uploadProductsFileApi} from '../../../../../../service/products/products-api';
import {ProductsFileType} from '../../../../../../service/products/products-type';
import {classNames} from '../../../../../../util/css';

import {getOriginalFileUrl, getRenderUploadIcon, getSampleFileUrl} from './product-group-file-fields-helper';
import * as styles from './product-group-file-fields.scss';

type PropsType = {
    onChange?: (newValue: number | null) => void;
    productGroupId?: number;
};

export function ProductGroupFileFields(props: PropsType): JSX.Element {
    const {onChange, productGroupId} = props;

    const {shortLocaleName, getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const [fileList, setFileList] = useState<Array<UploadFile<RcFile>>>([]);

    const showWarning = Boolean(productGroupId);

    function customRequest(options: {file: RcFile; filename?: string}) {
        uploadProductsFileApi(options.file)
            .then((result: ProductsFileType) => {
                if (options.filename) {
                    onChange?.(result.id);
                }
            })
            .finally(() => {
                if (options.file instanceof File) {
                    setFileList([options.file]);
                }
            })
            .catch(() => {
                setFileList([{...file2Obj(options.file), status: 'error'}]);
                snackbar.error(<Locale stringKey="PRODUCTS__GROUP_FORM__UPLOAD_FILE__ERROR" />);
            });
    }

    return (
        <div className={styles.ProductGroupFileField}>
            {showWarning && (
                <Row>
                    <Alert
                        className={styles.ProductGroupFileField_warning}
                        message={getLocalizedString('PRODUCTS__EDIT_PRODUCT_GROUP__FILE_WARNING_MESSAGE')}
                        showIcon
                        type="warning"
                    />
                </Row>
            )}
            <Row className={styles.ProductGroupFileField_header}>
                <p className={styles.ProductGroupFileField_headerText}>
                    <Locale stringKey="PRODUCTS__GROUP_FORM__UPLOAD_FILE__UPLOAD_PRODUCTS_BASE" />
                </p>

                <div className={styles.ProductGroupFileField_headerButtons}>
                    {productGroupId && (
                        <Button
                            className={styles.ProductGroupFileField_headerButton}
                            download
                            href={getOriginalFileUrl(productGroupId)}
                            icon={<FontAwesomeIcon icon={faFileArrowDown} />}
                            size="small"
                        >
                            <Locale stringKey="PRODUCTS__GROUP_FORM__UPLOAD_FILE__DOWNLOAD_ORIGINAL_FILE" />
                        </Button>
                    )}

                    <Button
                        className={styles.ProductGroupFileField_headerButton}
                        href={getSampleFileUrl(shortLocaleName)}
                        icon={<FontAwesomeIcon icon={faFileArrowDown} />}
                        size="small"
                    >
                        <Locale stringKey="PRODUCTS__GROUP_FORM__UPLOAD_FILE__DOWNLOAD_TEMPLATE" />
                    </Button>
                </div>
            </Row>

            <Upload
                accept=".csv,.xls,.xlsx"
                beforeUpload={(file) => {
                    setFileList([{...file2Obj(file), status: 'uploading'}]);
                    customRequest({file, filename: file.name});
                }}
                className={classNames(styles.ProductGroupFileField_upload, {
                    [styles.ProductGroupFileField_upload_uploaded]: fileList.length > 0,
                })}
                fileList={fileList}
                iconRender={getRenderUploadIcon}
                listType={fileList.length > 0 ? 'text' : 'picture-card'}
                maxCount={1}
                multiple={false}
                onRemove={() => {
                    onChange?.(null);
                    setFileList([]);
                }}
                progress={{showInfo: false}}
            >
                {fileList.length === 0 && (
                    <div className={styles.ProductGroupFileField_uploadArea}>
                        <FontAwesomeIcon className={styles.ProductGroupFileField_uploadAreaIcon} icon={faFilePlus} />

                        <p className={styles.ProductGroupFileField_uploadAreaText}>
                            <Locale stringKey="PRODUCTS__GROUP_FORM__UPLOAD_FILE__UPLOAD" />
                        </p>
                    </div>
                )}
            </Upload>
        </div>
    );
}
