import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';
import {FakesCompanyInfoType} from '../../../../../../service/fakes/fakes-companies-info';
import {FakesResultType} from '../../../../../../service/fakes/fakes-results';
import {FakesCatalogImage} from '../../../common/catalog-image/fakes-catalog-image';

type PropsType = Pick<FakesResultType, 'logoUrl' | 'name' | 'address' | 'phone' | 'category'> &
    Pick<FakesCompanyInfoType, 'url'> & {
        isCompany?: boolean;
    };

export function FoundLocationColumn(props: PropsType): JSX.Element {
    const {isCompany, logoUrl, name, address, phone, category, url} = props;

    return (
        <Space align="start" size="middle">
            <FakesCatalogImage isCompany={isCompany} logoUrl={logoUrl} name={name} url={url} />

            <Space direction="vertical" size={4}>
                <div>
                    <Text block bolder>
                        {name}
                    </Text>

                    <Text block lighter>
                        {address}
                    </Text>
                </div>

                {(phone || category) && (
                    <div>
                        {phone && (
                            <Text block secondary>
                                {phone}
                            </Text>
                        )}

                        {category && (
                            <Text block secondary>
                                {category}
                            </Text>
                        )}
                    </div>
                )}

                {url && (
                    <a href={url} rel="noreferrer" target="_blank">
                        <Space>
                            <FontAwesomeIcon icon={faExternalLink} />

                            <Locale stringKey="FAKES__RESULTS__SOURCE" />
                        </Space>
                    </a>
                )}
            </Space>
        </Space>
    );
}
