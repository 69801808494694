import {TableColumnsType} from 'antd';

import {FormattedDateTime} from '../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../provider/locale/locale';
import {LastPostsType} from '../../../../service/posts/posts-types';

export const LASTS_POSTS_LIMIT = 10;

export const lastPostsTableColumnList: TableColumnsType<LastPostsType> = [
    {
        title: <Locale stringKey="DASHBOARD_PAGE__POSTS__TABLE__TITLE" />,
        render: (_text: string, record: LastPostsType): JSX.Element => <div>{record.name}</div>,
    },
    {
        align: 'right',
        title: <Locale stringKey="DASHBOARD_PAGE__POSTS__TABLE__CATALOGS_COUNT" />,
        width: '80px',
        render: (_text: string, record: LastPostsType): JSX.Element => <div>{record.catalogsCount}</div>,
    },
    {
        align: 'right',
        title: <Locale stringKey="DASHBOARD_PAGE__POSTS__TABLE__DATE" />,
        width: '150px',
        render: (_text: string, record: LastPostsType): JSX.Element => (
            <div>{record.createdAt && <FormattedDateTime value={new Date(record.createdAt)} />}</div>
        ),
    },
];
