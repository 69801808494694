import {isEmpty, isObject, orderBy, partition, pick, sortBy, unionBy, uniq} from 'lodash';

import {CatalogConfigType} from '../../../../provider/catalogs/catalogs-type';
import {WeekDaysEnum} from '../../../../provider/locale/locale-context-const';
import {ShortLocaleNameEnum} from '../../../../provider/locale/locale-context-type';
import {CompanyKeyEnum, ProtocolEnum} from '../../../../service/company-v2/company-const';
import {CompanyFormType, CompanyServerType, CompanyType} from '../../../../service/company-v2/company-type';
import {CompanyImageFormType} from '../../../../service/company-v2/types/company-logo-type';
import {WorkingDaysType, WorkingDayType} from '../../../../service/company-v2/types/company-working-time-type';
import {CompanyWidgetKeyEnum} from '../company-form-const';
import {CompanyFieldFillType} from '../company-form-type';

import {isCatalogAvailableInCountry} from './catalog-list/catalog-list-helper';
import {initialCompanyValues} from './create/create-company-form-const';
import {
    convertAttributesToFormFormat,
    convertAttributesToServerFormat,
} from './widgets/attributes/attributes-form-helper';
import {catalogCategoryLimits} from './widgets/categories/categories-widget-const';

export function getWorkingDaysObject(workingDaysEntries: Array<[string, WorkingDayType | null]>): WorkingDaysType {
    return Object.fromEntries(workingDaysEntries) as WorkingDaysType;
}

function camelizeWorkingHours(workingHours: WorkingDaysType): WorkingDaysType {
    return getWorkingDaysObject(
        Object.entries(workingHours)
            .filter(([, dayWorkingHours]) => dayWorkingHours)
            .map(([dayName, dayWorkingHours]) => [dayName.replace('day', 'day_'), dayWorkingHours])
    );
}

function getCityId(city: CompanyFormType['address']['city']) {
    if (typeof city === 'string') {
        return null;
    }

    return city?.id;
}

function getCityName(city: CompanyFormType['address']['city']) {
    if (typeof city === 'string') {
        return city.length > 0 ? city : null;
    }

    return city?.name;
}

function getActiveCatalogIds(initialValues: Partial<CompanyType>, catalogList: Array<CatalogConfigType>) {
    const countryId = initialValues.address?.countryId;

    return new Set(
        catalogList
            .filter(({canRewriteCategories}) => canRewriteCategories)
            .filter((catalog) => (countryId ? isCatalogAvailableInCountry(catalog, countryId) : true))
            .map(({catalogId}) => catalogId)
    );
}

function assignMainIfDoesntExist<T extends {isMain?: boolean}>(collection?: Array<T>) {
    if (!collection) {
        return [];
    }

    return orderBy(collection, ({isMain}) => isMain, ['desc']).map((item, index) =>
        index === 0 && !item.isMain ? {...item, isMain: true} : item
    );
}

export function extractProtocol(link: string): {value: string; protocol: ProtocolEnum} | null {
    if (link.startsWith(ProtocolEnum.HTTP)) {
        return {value: link.replace(ProtocolEnum.HTTP, ''), protocol: ProtocolEnum.HTTP};
    }

    if (link.startsWith(ProtocolEnum.HTTPS)) {
        return {value: link.replace(ProtocolEnum.HTTPS, ''), protocol: ProtocolEnum.HTTPS};
    }

    return null;
}

function extractProtocolWithFallback(link: string) {
    return extractProtocol(link) || {value: link.replace(ProtocolEnum.HTTPS, ''), protocol: ProtocolEnum.HTTPS};
}

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function toFormFormat(
    initialValues: Partial<CompanyType>,
    catalogList: Array<CatalogConfigType>
): Partial<CompanyFormType> {
    const catalogIds = catalogList.map(({catalogId}) => catalogId);
    const activeCatalogIds = getActiveCatalogIds(initialValues, catalogList);
    const catalogsForLinks = uniq([
        ...(initialValues.extraWebsites?.map(({catalogId}) => catalogId) ?? []),
        ...(initialValues.extraSocialNetworks?.map(({catalogId}) => catalogId) ?? []),
    ]);

    const formValues: Partial<CompanyFormType> = {
        ...initialValues,
        email: initialValues.email ?? '',
        legal: {
            name: initialValues.legal?.name || null,
            form: {
                value: initialValues.legal?.formId ?? null,
                label: initialValues.legal?.formName ?? null,
            },
        },
        address: {
            lang: initialValues.address?.lang ?? null,
            country:
                initialValues.address?.countryId && initialValues.address?.countryName
                    ? {
                          id: initialValues.address?.countryId,
                          lang: initialValues.address?.lang ?? ShortLocaleNameEnum.ru,
                          name: initialValues.address?.countryName,
                          countryCode: initialValues.address?.countryCode ?? '',
                      }
                    : null,
            region:
                initialValues.address?.regionId && initialValues.address?.regionName
                    ? {
                          id: initialValues.address?.regionId,
                          name: initialValues.address?.regionName,
                      }
                    : null,
            city:
                initialValues.address?.cityId && initialValues.address?.cityName
                    ? {
                          id: initialValues.address?.cityId,
                          name: initialValues.address?.cityName,
                      }
                    : null,
            street: initialValues.address?.street ?? null,
            houseNumber: initialValues.address?.houseNumber ?? null,
            postalCode: initialValues.address?.postalCode ?? null,
            description: initialValues.address?.description ?? null,
            latLng:
                initialValues.address?.lat && initialValues.address?.lon
                    ? ([initialValues.address?.lat, initialValues.address?.lon] as [number, number])
                    : null,
        },
        rocketdataCategories: assignMainIfDoesntExist(initialValues.rocketdataCategories),
        mappedCatalogCategories: initialValues.mappedCatalogCategories?.map((catalogCategories) => ({
            ...catalogCategories,
            categories: assignMainIfDoesntExist(catalogCategories.categories),
        })),
        catalogCategories: initialValues.mappedCatalogCategories?.length
            ? initialValues.mappedCatalogCategories
                  ?.filter(({catalogId}) => activeCatalogIds.has(catalogId))
                  .map((catalogCategories) => {
                      const {catalogId} = catalogCategories;
                      const limit = catalogCategoryLimits(Boolean(initialValues.isPaidYandexPlacement))[catalogId];
                      const changedCatalog = initialValues.catalogCategories?.find(
                          (catalog) => catalog.catalogId === catalogId
                      );

                      const newCatalog = {
                          ...(changedCatalog || catalogCategories),
                          categories: assignMainIfDoesntExist((changedCatalog || catalogCategories || {}).categories),
                          edited: Boolean(changedCatalog),
                      };

                      if (!limit) {
                          return newCatalog;
                      }

                      return {
                          ...newCatalog,
                          categories: newCatalog.categories.slice(0, limit),
                      };
                  }) ?? []
            : [...activeCatalogIds].map((id) => ({catalogId: id, edited: false, categories: []})),
        phones: initialValues.phones?.length
            ? assignMainIfDoesntExist(initialValues.phones)
            : initialCompanyValues.phones,
        websites: initialValues.websites?.length
            ? assignMainIfDoesntExist(
                  initialValues.websites?.map(({website, isMain}) => ({
                      ...extractProtocolWithFallback(website),
                      isMain,
                  }))
              )
            : initialCompanyValues.websites,
        extraWebsites: initialValues.extraWebsites?.map(({catalogId, websites}) => ({
            catalogId,
            websites: websites?.map(({website, isMain, isUseInInfoTag}) => ({
                ...extractProtocolWithFallback(website),
                isMain,
                isUseInInfoTag,
            })),
        })),
        extraPhones: initialValues.extraPhones?.map((extraPhones) => ({
            ...extraPhones,
            phones: sortBy(extraPhones.phones, (phone) => !phone.isMain),
        })),
        socialNetworks: initialValues.socialNetworks?.length
            ? initialValues.socialNetworks?.map((socialNetwork) => extractProtocolWithFallback(socialNetwork))
            : initialCompanyValues.socialNetworks,
        extraSocialNetworks: catalogsForLinks.map((catalogId) => {
            const extraField = initialValues.extraSocialNetworks?.find((item) => item.catalogId === catalogId);
            const source = extraField ? extraField.socialNetworks : initialValues.socialNetworks;

            return {
                catalogId,
                socialNetworks: source?.length
                    ? source?.map((socialNetwork) => extractProtocolWithFallback(socialNetwork))
                    : undefined, // eslint-disable-line no-undefined
            };
        }),
        gallery: initialValues.gallery?.map(({hash, url}) => ({
            hash,
            url,
        })),
        extraGallery: initialValues.extraGallery?.map(({catalogId, gallery}) => ({
            catalogId,
            gallery: gallery.map(({hash, url}) => ({
                hash,
                url,
            })),
        })),
        attributes: convertAttributesToFormFormat(initialValues.attributes),
    };

    // these modifications are necessary for tabs that show several lists inside them.
    // they basically add empty initial values for cases when first and second list have different
    // lengths/values, thus making them have the same structure
    formValues.extraPhones = sortBy(
        unionBy(formValues.extraPhones, initialValues.extraEmails, ({catalogId}) => catalogId),
        ({catalogId}) => catalogIds.indexOf(catalogId)
    ).map(
        ({catalogId}) =>
            formValues.extraPhones?.find((item) => item.catalogId === catalogId) ?? {
                catalogId,
                phones: undefined, // eslint-disable-line no-undefined
            }
    );

    formValues.extraEmails = sortBy(
        unionBy(initialValues.extraPhones, formValues.extraEmails, ({catalogId}) => catalogId),
        ({catalogId}) => catalogIds.indexOf(catalogId)
    ).map(
        ({catalogId}) =>
            formValues.extraEmails?.find((item) => item.catalogId === catalogId) ?? {
                catalogId,
                email: null,
            }
    );

    formValues.extraWebsites = sortBy(
        unionBy(formValues.extraWebsites, initialValues.extraSocialNetworks, ({catalogId}) => catalogId),
        ({catalogId}) => catalogIds.indexOf(catalogId)
    ).map(
        ({catalogId}) =>
            formValues.extraWebsites?.find((item) => item.catalogId === catalogId) ?? {
                catalogId,
                websites: undefined, // eslint-disable-line no-undefined
            }
    );

    formValues.extraSocialNetworks = sortBy(
        unionBy<{catalogId: number}>(
            initialValues.extraWebsites,
            formValues.extraSocialNetworks,
            ({catalogId}) => catalogId
        ),
        ({catalogId}) => catalogIds.indexOf(catalogId)
    ).map(
        ({catalogId}) =>
            formValues.extraSocialNetworks?.find((item) => item.catalogId === catalogId) ?? {
                catalogId,
                socialNetworks: undefined, // eslint-disable-line no-undefined
            }
    );

    return formValues;
}

function getRemovedCatalogs(
    serverFormatArray: Array<{catalogId: number}>,
    initialValuesArray?: Array<{catalogId: number}>
) {
    const formCatalogs = new Set(serverFormatArray.map(({catalogId}) => catalogId));
    const initialCatalogs = initialValuesArray?.map(({catalogId}) => catalogId) ?? [];

    return initialCatalogs.filter((catalog) => !formCatalogs.has(catalog));
}

function addEmptyArraysForRemovedCatalogs(serverFormat: CompanyServerType, initialValues: Partial<CompanyFormType>) {
    serverFormat.extraNames.push(
        ...getRemovedCatalogs(serverFormat.extraNames, initialValues?.extraNames).map((catalogId) => ({
            catalogId,
            names: [],
        }))
    );

    serverFormat.extraPhones.push(
        ...getRemovedCatalogs(serverFormat.extraPhones, initialValues?.extraPhones).map((catalogId) => ({
            catalogId,
            phones: [],
        }))
    );

    serverFormat.extraEmails.push(
        ...getRemovedCatalogs(serverFormat.extraEmails, initialValues?.extraEmails).map((catalogId) => ({
            catalogId,
            email: null,
        }))
    );

    serverFormat.extraWebsites.push(
        ...getRemovedCatalogs(serverFormat.extraWebsites, initialValues?.extraWebsites).map((catalogId) => ({
            catalogId,
            websites: [],
        }))
    );

    serverFormat.extraSocialNetworks.push(
        ...getRemovedCatalogs(serverFormat.extraSocialNetworks, initialValues?.extraSocialNetworks).map(
            (catalogId) => ({
                catalogId,
                socialNetworks: [],
            })
        )
    );

    serverFormat.extraWorkingHours.push(
        ...getRemovedCatalogs(serverFormat.extraWorkingHours, initialValues?.extraWorkingHours).map((catalogId) => ({
            catalogId,
            workingHours: getWorkingDaysObject(Object.values(WeekDaysEnum).map((day) => [`day${day}`, null])),
        }))
    );

    serverFormat.extraDescriptions.push(
        ...getRemovedCatalogs(serverFormat.extraDescriptions, initialValues?.extraDescriptions).map((catalogId) => ({
            catalogId,
            short: null,
            long: null,
        }))
    );

    serverFormat.extraGallery.push(
        ...getRemovedCatalogs(serverFormat.extraGallery, initialValues?.extraGallery).map((catalogId) => ({
            catalogId,
            gallery: [],
        }))
    );
}

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function toServerFormat(formData: CompanyFormType, initialValues: Partial<CompanyFormType>): CompanyServerType {
    const [resetCategories, activeCategories] = partition(formData.catalogCategories, (category) => category.reset);

    const resetCatalogCategories = resetCategories.map(({catalogId}) => ({catalogId, categories: []}));

    const changedCatalogCategories = activeCategories
        .filter(({edited}) => edited)
        .map(({catalogId, categories}) => ({
            catalogId,
            categories: categories.map(({originId, isMain}) => ({originId, isMain})),
        }));

    const serverFormat: CompanyServerType = {
        ...initialCompanyValues,
        ...formData,
        names: formData.names.map(({name, lang}) => ({name, lang})),
        extraNames:
            formData.extraNames?.map(({catalogId, names}) => ({
                catalogId,
                names: names.map(({name, lang}) => ({name, lang})),
            })) ?? initialCompanyValues.extraNames,
        address: {
            lang: formData.address.country?.lang ?? null,
            countryId: formData.address.country?.id ?? null,
            regionId: formData.address.region?.id ?? null,
            regionName: formData.address.region?.name ?? null,
            cityId: getCityId(formData.address.city) ?? null,
            cityName: getCityName(formData.address.city) ?? null,
            street: formData.address.street ?? '',
            houseNumber: formData.address.houseNumber || null,
            postalCode: formData.address.postalCode,
            description: formData.address.description ?? '',
            lat: formData.address.latLng?.[0] ?? 0,
            lon: formData.address.latLng?.[1] ?? 0,
        },
        legal: {
            name: formData.legal.name || null,
            formId: formData.legal.form.value || null,
        },
        catalogCategories: [...resetCatalogCategories, ...changedCatalogCategories],
        extraPhones:
            formData.extraPhones?.filter(
                ({phones}) => phones?.length === 0 || phones?.some(({phone}) => phone.length) // in one catalog tab we can change email, but leave phone as an empty string
            ) ?? initialCompanyValues.extraPhones,
        extraEmails:
            formData.extraEmails
                ?.filter((emails) => emails.email !== undefined) // eslint-disable-line no-undefined
                .map((emails) => ({...emails, email: emails.email || null})) ?? initialCompanyValues.extraEmails,
        websites: formData.websites
            .filter(({value}) => value)
            .map(({value, protocol, isMain}) => ({website: protocol + value, isMain})),
        extraWebsites:
            formData.extraWebsites?.map(({catalogId, websites}) => ({
                catalogId,
                websites: websites
                    ?.filter(({value}) => value)
                    .map(({value, protocol, isMain, isUseInInfoTag}) => ({
                        website: protocol + value,
                        isMain,
                        isUseInInfoTag,
                    })),
            })) ?? [],
        socialNetworks: formData.socialNetworks.filter(({value}) => value).map(({value, protocol}) => protocol + value),
        extraSocialNetworks:
            formData.extraSocialNetworks?.map(({catalogId, socialNetworks}) => ({
                catalogId,
                socialNetworks: socialNetworks
                    ? socialNetworks.filter(({value}) => value).map(({value, protocol}) => protocol + value)
                    : null,
            })) ?? [],
        descriptions: {
            short: formData.descriptions.short || null,
            long: formData.descriptions.long || null,
        },
        extraDescriptions:
            formData.extraDescriptions?.map(({catalogId, short, long}) => ({
                catalogId,
                short: short || null,
                long: long || null,
            })) ?? initialCompanyValues.extraDescriptions,
        logo: formData.logo
            ? pick<CompanyImageFormType, keyof CompanyImageFormType>(formData.logo, ['tempId', 'hash'])
            : null,
        cover: formData.cover
            ? pick<CompanyImageFormType, keyof CompanyImageFormType>(formData.cover, ['tempId', 'hash'])
            : null,
        gallery: formData.gallery?.filter(({tempId, hash}) => tempId || hash).map(({tempId, hash}) => ({tempId, hash})),
        extraGallery:
            formData.extraGallery?.map(({catalogId, gallery}) => ({
                catalogId,
                gallery:
                    gallery?.filter(({tempId, hash}) => tempId || hash).map(({tempId, hash}) => ({tempId, hash})) ?? [],
            })) ?? [],
        attributes: convertAttributesToServerFormat(formData.attributes, initialValues.attributes),
    };

    addEmptyArraysForRemovedCatalogs(serverFormat, initialValues);

    if (formData.workingHours) {
        serverFormat.workingHours = camelizeWorkingHours(formData.workingHours);
    }

    if (formData.extraWorkingHours) {
        serverFormat.extraWorkingHours = formData.extraWorkingHours.map(({catalogId, workingHours}) => ({
            catalogId,
            workingHours: workingHours ? camelizeWorkingHours(workingHours) : workingHours,
        }));
    }

    return serverFormat;
}

function checkWorkingHours(form: CompanyFormType): boolean {
    const fillDays = Object.values(form.workingHours).filter((item) => item !== null);

    return (
        fillDays.length > 0 && (fillDays.every((day) => !isEmpty(day?.timeRange)) || fillDays.some((day) => day?.daily))
    );
}

export function createNewFormFields(form: CompanyFormType, values: Partial<CompanyFormType>): CompanyFormType {
    const companyKey = Object.keys(values)[0] as keyof CompanyFormType;

    switch (companyKey) {
        case CompanyKeyEnum.Descriptions:
            return {...form, descriptions: {...form.descriptions, ...values[companyKey]}};
        case CompanyKeyEnum.Address:
            return {...form, address: {...form.address, ...values[companyKey]}};
        default:
            return {...form, ...values};
    }
}

export function getCompanyCondition(form: CompanyFormType, isDisableLegal: boolean): CompanyFieldFillType {
    return {
        [CompanyWidgetKeyEnum.SearchNames]: form.searchNames.length > 0,
        [CompanyWidgetKeyEnum.Code]: Boolean(form.code),
        [CompanyWidgetKeyEnum.BrandName]: Boolean(form.brandName),
        [CompanyWidgetKeyEnum.PostalCode]: Boolean(form.address.postalCode),
        [CompanyWidgetKeyEnum.City]: isObject(form.address.city)
            ? Boolean(form.address.city.name)
            : Boolean(form.address.city),
        [CompanyWidgetKeyEnum.Street]: Boolean(form.address.street),
        [CompanyWidgetKeyEnum.HouseNumber]: Boolean(form.address.houseNumber),
        [CompanyWidgetKeyEnum.LegalName]: isDisableLegal || Boolean(form.legal.name),
        [CompanyWidgetKeyEnum.LegalForm]: isDisableLegal || Boolean(form.legal.form.value),
        [CompanyWidgetKeyEnum.Email]: Boolean(form.email),
        [CompanyWidgetKeyEnum.SocialNetworks]:
            Boolean(form.socialNetworks) && form.socialNetworks.length > 0 && Boolean(form.socialNetworks[0]?.value),
        [CompanyWidgetKeyEnum.WorkingHours]: checkWorkingHours(form),
        [CompanyWidgetKeyEnum.Payments]: form.payments.length > 0,
        [CompanyWidgetKeyEnum.DescriptionsLong]: Boolean(form.descriptions.long),
        [CompanyWidgetKeyEnum.DescriptionsShort]: Boolean(form.descriptions.short),
        [CompanyWidgetKeyEnum.Logo]: form.logo !== null && !isEmpty(form.logo),
        [CompanyWidgetKeyEnum.Cover]: form.cover !== null && !isEmpty(form.cover),
        [CompanyWidgetKeyEnum.Gallery]: form.gallery.length >= 3,
        [CompanyWidgetKeyEnum.Names]: form.names?.every((item) => item.name),
        [CompanyWidgetKeyEnum.Country]: Boolean(form.address.country?.name),
        [CompanyWidgetKeyEnum.Region]: Boolean(form.address.region?.name),
        [CompanyWidgetKeyEnum.Phones]: form.phones?.every((item) => item.phone),
        [CompanyWidgetKeyEnum.RocketdataCategories]:
            form.rocketdataCategories.length > 0 && form.rocketdataCategories?.every((item) => item.categoryName),
        [CompanyWidgetKeyEnum.Website]: form.websites?.every((item) => item.value),
        categories: form.rocketdataCategories.length > 1,
    };
}
