import {Locale} from '../../../../../provider/locale/locale';
import {ReviewsAnalysisFilterEnum, ReviewsAnalysisFilterType} from '../../reviews-analysis-type';

export const filterFormId = 'reviews-analysis-filter-form-id';

export const reviewAnalysisAutocompleteLimit = 50;

export const emptyDrawerFormFilter: ReviewsAnalysisFilterType = {
    [ReviewsAnalysisFilterEnum.Range]: [null, null],
    [ReviewsAnalysisFilterEnum.CompareRange]: [null, null],
    [ReviewsAnalysisFilterEnum.Competitor]: null,
    [ReviewsAnalysisFilterEnum.Sentiments]: [],
    [ReviewsAnalysisFilterEnum.Catalogs]: [],
    [ReviewsAnalysisFilterEnum.Tags]: [],
    [ReviewsAnalysisFilterEnum.Phrases]: [],
    [ReviewsAnalysisFilterEnum.Topics]: [],
    [ReviewsAnalysisFilterEnum.Rates]: [],
    [ReviewsAnalysisFilterEnum.WithMessage]: null,
    [ReviewsAnalysisFilterEnum.WithReply]: null,
    [ReviewsAnalysisFilterEnum.AbleToReply]: null,
};

export const REVIEWS_WITH_TEXT_OPTIONS = [
    {label: <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITH_MSG_LABEL" />, value: true},
    {label: <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_MSG_LABEL" />, value: false},
];

export const REPLIES_TO_REVIEWS_OPTIONS = [
    {label: <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITH_REPLY_LABEL" />, value: true},
    {label: <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_REPLY_LABEL" />, value: false},
];

export const ABLE_TO_REPLIE_OPTIONS = [
    {label: <Locale stringKey="TEXT__YES" />, value: true},
    {label: <Locale stringKey="TEXT__NO" />, value: false},
];
