import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeApiError, fetchAndDeserialize, getUrlParameters, postAndDeserialize} from '../../util/api-adapter';
import {useUrl} from '../../util/url-hook/url-hook';

import {userUrl} from './user-const';

const newPasswordSchema = r.object({
    result: r.literal('ok').or(r.literal('error')),
});

type NewPasswordType = r.infer<typeof newPasswordSchema>;

const newPasswordErrorSchema = r.object({
    error: r.string().optional(),
});

type NewPasswordErrorType = r.infer<typeof newPasswordErrorSchema>;

const twoGisResetPasswordSchema = r.object({
    isCurrentUserExists: r.boolean(),
    email: r.string().optional(),
    orgIds: r.array(r.string()).optional(),
    lang: r.string().optional(),
});

type TwoGisResetPasswordType = r.infer<typeof twoGisResetPasswordSchema>;

const setAcl2GisUserSchema = r.object({
    status: r.string(),
});

type SetAcl2GisUserType = r.infer<typeof setAcl2GisUserSchema>;

export enum NewPasswordFormEnum {
    Password = 'password',
    PasswordConfirm = 'passwordConfirm',
}

export type NewPasswordFormType = {
    [NewPasswordFormEnum.Password]: string;
    [NewPasswordFormEnum.PasswordConfirm]: string;
};

type QueryType = {
    token?: string | null;
    uidb64?: string | null;
    jwtToken?: string | null;
};

type NewPasswordOptionsType = NewPasswordFormType & QueryType;

const url = `${userUrl}/reset_password/done/`;

function newPassword(options: NewPasswordOptionsType, shortLocaleName: ShortLocaleNameEnum): Promise<NewPasswordType> {
    return postAndDeserialize(url, newPasswordSchema, options, {unauthorized: true, shortLocaleName});
}

function newPassword2Gis(
    options: NewPasswordOptionsType,
    shortLocaleName: ShortLocaleNameEnum
): Promise<NewPasswordType> {
    const twoGisUrl = `${userUrl}/auth/2gis_reset_password/`;

    const twoGisOptions = {
        password: options.password,
        repeatPassword: options.passwordConfirm,
        jwtToken: options.jwtToken,
    };

    return postAndDeserialize(twoGisUrl, newPasswordSchema, twoGisOptions, {unauthorized: true, shortLocaleName});
}

export function useNewPasswordUrlQuery(): QueryType & {isCorrectUrl: boolean} {
    const search = new URLSearchParams(location.search);

    const token = search.get('token');
    const uidb64 = search.get('uidb64');
    const jwtToken = search.get('jwt-token');

    const isCorrectUrl = Boolean(jwtToken || (token && uidb64));

    return {token, uidb64, jwtToken, isCorrectUrl};
}

export function useNewPasswordMutation(): UseMutationResult<NewPasswordType, unknown, NewPasswordFormType> {
    const {token, uidb64, jwtToken, isCorrectUrl} = useNewPasswordUrlQuery();

    const {replaceUrl} = useUrl();
    const {snackbar} = useSnackbar();
    const {shortLocaleName} = useLocale();

    function mutate(options: NewPasswordFormType) {
        if (!isCorrectUrl) {
            return Promise.reject('Missing url params');
        }

        if (options.password !== options.passwordConfirm) {
            snackbar.error(<Locale stringKey="PAGE__NEW_PASSWORD__PASSWORDS_DONT_MATCH" />);

            return Promise.reject("Passwords don't match");
        }

        if (jwtToken) {
            return newPassword2Gis({...options, jwtToken}, shortLocaleName);
        }

        return newPassword({...options, token, uidb64}, shortLocaleName);
    }

    function onSuccess(data: NewPasswordType) {
        if (data.result !== 'ok' && !jwtToken) {
            throw new Error('AuthorizationResult is not "ok"');
        }

        snackbar.success(<Locale stringKey="PAGE__NEW_PASSWORD__SUCCESS" />);

        replaceUrl(appRoute.login.path);
    }

    function onError(error: unknown) {
        const apiError = deserializeApiError<NewPasswordErrorType>(url, newPasswordErrorSchema, error);

        snackbar.error(apiError?.error || <Locale stringKey="PAGE__NEW_PASSWORD__SERVER_ERROR" />);
    }

    return useMutation(mutate, {onSuccess, onError});
}

export function use2GisResetPassword(): UseQueryResult<TwoGisResetPasswordType> {
    const {jwtToken} = useNewPasswordUrlQuery();

    const twoGisResetPasswordUrl = `${userUrl}/auth/2gis_reset_password/is_user_exists/${getUrlParameters({jwtToken})}`;

    return useQuery(
        [twoGisResetPasswordUrl],
        () => fetchAndDeserialize(twoGisResetPasswordUrl, twoGisResetPasswordSchema),
        {
            enabled: Boolean(jwtToken),
        }
    );
}

export function setAclPermissionsFor2GisUser(jwtToken: string): Promise<SetAcl2GisUserType> {
    return fetchAndDeserialize(
        `${userUrl}/auth/2gis_reset_password/set_permissions/${getUrlParameters({jwtToken})}`,
        setAcl2GisUserSchema
    );
}
