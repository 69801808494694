import {DatePicker} from 'antd';
import {Dayjs} from 'dayjs';
import {useCallback, useMemo} from 'react';

import {DateRangePicker} from '../../../../../../component/date-range-picker/date-range-picker';
import {noop} from '../../../../../../util/function';

import {customRangeToDayjsRange, dayjsRangeToCustomRange} from './utc-range-picker-helper';
import {CustomRangeType, DayjsRangeType} from './utc-range-picker-type';

type PropsType = {
    defaultMode?: boolean; // should be used in drawer filter because of overflow
    value?: CustomRangeType;
    onChange?: (value: CustomRangeType) => void;
    disabled?: boolean;
    disabledDate?: (date: Dayjs) => boolean;
};

export function UtcRangePicker(props: PropsType): JSX.Element {
    const {value = [null, null], onChange = () => noop, disabledDate, disabled, defaultMode} = props;

    const dayjsRange: DayjsRangeType = useMemo<DayjsRangeType>(
        (): DayjsRangeType => customRangeToDayjsRange(value),
        [value]
    );

    const onChangeWrapper = useCallback(
        (dayjsRangeInner: DayjsRangeType) => onChange(dayjsRangeToCustomRange(dayjsRangeInner)),
        [onChange]
    );

    if (defaultMode) {
        return (
            <DatePicker.RangePicker
                disabled={disabled}
                disabledDate={disabledDate}
                onChange={onChangeWrapper}
                value={dayjsRange}
            />
        );
    }

    return (
        <DateRangePicker
            disabled={disabled}
            disabledDate={disabledDate}
            onChange={onChangeWrapper}
            value={dayjsRange}
        />
    );
}
