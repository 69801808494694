import {FilterDropdownProps} from 'antd/es/table/interface';
import {useCallback} from 'react';

import {TableFilterDropdown} from '../../../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {RatingsDynamicsCatalogFilterValuesEnum} from '../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';

export function RatingHistoryCatalogFilterDropdown(props: FilterDropdownProps): JSX.Element {
    const {setSelectedKeys, ...restProps} = props;

    const setSelectedKeysWrapped = useCallback(
        (selectedKeys: Array<React.Key>) => {
            const everyOptionSelected = Object.values(RatingsDynamicsCatalogFilterValuesEnum).every(
                (ratingFilterItem) => selectedKeys.includes(ratingFilterItem)
            );

            if (everyOptionSelected) {
                setSelectedKeys([]);
                return;
            }

            setSelectedKeys(selectedKeys);
        },
        [setSelectedKeys]
    );

    return (
        <TableFilterDropdown
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
            setSelectedKeys={setSelectedKeysWrapped}
            showClear={false}
        />
    );
}
