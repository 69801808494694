import {faEye, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import {useState} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {VideoPopup} from '../../../../../../../../../component/video-popup/video-popup';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {PostVideoType} from '../../../../../../../../../service/posts/posts-types';
import {useFormat} from '../../../../../../../../../util/format-hook/format-hook';
import {getFormattedVideoDuration} from '../../../../../../../../../util/time';
import {BYTES_IN_MB} from '../../../../../posts-form-helper';
import * as styles from '../media.scss';
import {MediaCard} from '../media-card';

type PropsType = {
    video: PostVideoType;
    onRemove: () => void;
    showRemoveControl: boolean;
};

export function VideoCard(props: PropsType): JSX.Element {
    const {video, onRemove, showRemoveControl} = props;

    const {getFormattedNumber} = useFormat();

    const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState<boolean>(false);

    const {name, size, duration, previewImageUrl, url} = video;

    return (
        <MediaCard previewSrc={previewImageUrl}>
            {isPreviewPopupOpen && <VideoPopup onClose={() => setIsPreviewPopupOpen(false)} url={url} />}

            <div className={styles.PostMedia_fileInformationWrapper}>
                <Paragraph className={styles.PostMedia_fileInformationName} ellipsis={{rows: 1}}>
                    <FontAwesomeIcon icon={faPlay} />
                    &nbsp;
                    {name}
                </Paragraph>
                <div className={styles.PostMedia_fileInformationSize}>
                    <div>{getFormattedVideoDuration(duration * 1000)}</div>
                    <div>
                        {getFormattedNumber(size / BYTES_IN_MB, {
                            maximumFractionDigits: 1,
                        })}
                        <Locale stringKey="TEXT__SIZE__MB" />
                    </div>
                </div>
            </div>

            <div className={styles.PostMedia_cardOverlay}>
                <div className={styles.PostMedia_actions}>
                    {showRemoveControl && (
                        <Button className={styles.PostMedia_actionButton} onClick={onRemove} type="text">
                            <Text inheritColor>
                                <FontAwesomeIcon icon={faTrashCan} />
                            </Text>
                        </Button>
                    )}

                    <Button
                        className={styles.PostMedia_actionButton}
                        onClick={() => setIsPreviewPopupOpen(true)}
                        type="text"
                    >
                        <Text inheritColor>
                            <FontAwesomeIcon icon={faEye} />
                        </Text>
                    </Button>
                </div>
            </div>
        </MediaCard>
    );
}
