import {Children, PropsWithChildren} from 'react';

import {classNames} from '../../../util/css';
import {PageWrapper, PageWrapperOptionalPropsType} from '../wrapper/page-wrapper';

import * as styles from './error-page.scss';

type PropsType = PropsWithChildren<{className?: string} & PageWrapperOptionalPropsType>;

export function ErrorPage(props: PropsType): JSX.Element {
    const {children, titleLangKey, title, className} = props;

    return (
        <PageWrapper title={title} titleLangKey={titleLangKey}>
            {Children.map(children, (child) => (
                <p className={classNames(styles.ErrorPage_error, className)}>{child}</p>
            ))}
        </PageWrapper>
    );
}
