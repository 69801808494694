import {ShortLocaleNameEnum} from '../locale/locale-context-type';

import {HelpLinkType} from './help-links-type';

export function isMatchingHelpLink(
    {urlPattern, language}: HelpLinkType,
    key: string,
    shortLocaleName: ShortLocaleNameEnum
): boolean {
    if (
        language === shortLocaleName ||
        (language === ShortLocaleNameEnum.en && shortLocaleName !== ShortLocaleNameEnum.ru)
    ) {
        const keyWithoutTrailingSlash = key.replace(/\/+$/, '');

        return new RegExp(urlPattern).test(keyWithoutTrailingSlash);
    }

    return false;
}

export const DOCUMENTATION_LINK_KEY_PREFIX = 'cp-documentation-link-';
export const DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX = 'dashboard-';
