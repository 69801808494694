import {Button} from 'antd';

import {Text} from '../../../../component/text/text';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../provider/locale/locale';
import {LangKeyType} from '../../../../provider/locale/translation/type';

import * as styles from './dashboard-empty.scss';

type PropsType =
    | {
          icon: JSX.Element;
          titleLangKey?: LangKeyType;
          descriptionLangKey: LangKeyType;
          buttonTextLangKey: LangKeyType;
          navigationPath: string;
          onClick?: never;
      }
    | {
          icon: JSX.Element;
          titleLangKey?: LangKeyType;
          descriptionLangKey: LangKeyType;
          buttonTextLangKey: LangKeyType;
          onClick: VoidFunction;
          navigationPath?: never;
      };

export function DashboardEmpty(props: PropsType): JSX.Element {
    const {icon, titleLangKey, descriptionLangKey, navigationPath, buttonTextLangKey, onClick} = props;

    return (
        <div className={styles.DashboardEmpty}>
            <div className={styles.DashboardEmpty_imageContainer}>{icon}</div>

            <div>
                {titleLangKey && <Text block stringKey={titleLangKey} />}
                <Text block secondary small stringKey={descriptionLangKey} />
            </div>

            {navigationPath && (
                <NavigationLink to={navigationPath}>
                    <Button>
                        <Locale stringKey={buttonTextLangKey} />
                    </Button>
                </NavigationLink>
            )}
            {onClick && (
                <Button onClick={onClick}>
                    <Locale stringKey={buttonTextLangKey} />
                </Button>
            )}
        </div>
    );
}
