import {
    YandexStoryFileUploaderType,
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
    YandexStoryType,
} from '../../../service/yandex-stories/yandex-stories-type';

export const YANDEX_STORY_MIN_IMAGE_WIDTH = 100;
export const YANDEX_STORY_MIN_IMAGE_HEIGHT = 68;

export const YANDEX_STORY_MAX_IMAGE_WIDTH = 5000;
export const YANDEX_STORY_MAX_IMAGE_HEIGHT = 5000;

export const YANDEX_STORY__MAX_SLIDES = 10;

export function getYandexStoryFormInitialValues(
    storyData: YandexStoryType
): Omit<YandexStoryFormType, YandexStoryFormFieldsEnum.CompaniesSelector> {
    const formSlides = storyData.slides.reduce(
        (accumulator, slide) => ({
            ...accumulator,
            [slide.image.id]: {
                id: slide.image.id,
                url: slide.image.url,
                order: slide.order,
                buttonConfig: slide.buttonConfig,
                name: '',
                uid: slide.image.id,
                status: 'done' as const,
            },
        }),
        {} as YandexStoryFileUploaderType
    );

    const firstSlideButtonConfig = storyData.slides?.[0]?.buttonConfig;

    const isSameButtonOnEachSlide = Boolean(
        firstSlideButtonConfig &&
            storyData.slides.every((slide) => {
                return (
                    slide.buttonConfig?.link === firstSlideButtonConfig.link &&
                    slide.buttonConfig?.text === firstSlideButtonConfig.text
                );
            })
    );

    return {
        [YandexStoryFormFieldsEnum.Name]: storyData.name,
        [YandexStoryFormFieldsEnum.Slides]: formSlides,
        [YandexStoryFormFieldsEnum.Type]: storyData.type,
        [YandexStoryFormFieldsEnum.SameButtonOnEachSlide]: isSameButtonOnEachSlide,
        [YandexStoryFormFieldsEnum.Cover]: storyData.cover,
    };
}

export const YANDEX_SELECT_COMPANIES_STEP_WIDTH = 864;
export const YANDEX_CONTENT_STEP_WIDTH = 704;
export const YANDEX_CONFIRMATION_STEP_WIDTH = 704;
