import {LocaleContextType} from '../../../../../../provider/locale/locale-context-type';
import {toTrimmedString} from '../../../../../../util/string';

import {TextType} from './input-text-list/input-text-type';

export function getSendingCompanyNames(
    getLocalizedString: LocaleContextType['getLocalizedString'],
    currentCompanyNames: Array<TextType>
): Array<TextType> {
    return currentCompanyNames
        ? currentCompanyNames.map((name, index) => {
              const isWaitingForPush = toTrimmedString(name.text).length === 0;

              return {
                  ...name,
                  id: name.id + index,
                  text: isWaitingForPush
                      ? getLocalizedString('GENERAL_INFO__HINT__SENDING_COMPANY_NAME__WAITING_FOR_MODERATION')
                      : name.text,
                  isWaitingForPush,
              };
          })
        : [];
}
