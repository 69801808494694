import * as cssVariableStyles from '../../../../../css/var-export.scss';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {SentimentEnum} from '../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';

export const SENTIMENT_COLOR_MAP: Record<SentimentEnum, string> = {
    [SentimentEnum.Positive]: cssVariableStyles.sentiment_positive,
    [SentimentEnum.Neutral]: cssVariableStyles.sentiment_neutral,
    [SentimentEnum.Unspecified]: cssVariableStyles.sentiment_unspecified,
    [SentimentEnum.Negative]: cssVariableStyles.sentiment_negative,
};

export const SENTIMENT_TRANSLATION_MAP: Record<SentimentEnum, LangKeyType> = {
    [SentimentEnum.Positive]: 'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE',
    [SentimentEnum.Neutral]: 'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL',
    [SentimentEnum.Unspecified]: 'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED',
    [SentimentEnum.Negative]: 'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE',
};
