import {useMutation} from '@tanstack/react-query';
import {Button, FormInstance, Modal} from 'antd';
import {useCallback, useMemo} from 'react';

import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {
    CustomReviewError,
    EditReviewFormPaylaodType,
    fetchEditReview,
    handleCustomReviewError,
    ReviewFormErrorType,
    ReviewPayloadType,
} from '../../../../../service/reviews/custom-reviews';
import {ReviewCustomCompanyType} from '../../../../../service/reviews/reviews-custom-compamies';
import {ReviewDataType} from '../../../../../service/reviews/reviews-type';
import {ReviewForm} from '../review-form';
import {deserializeReview, serializeEditReviewForm} from '../review-form-helper';
import {ReviewFormFieldsType} from '../review-form-type';

type PropsType = {
    isOpen: boolean;
    onCancel: () => void;
    form: FormInstance<ReviewFormFieldsType>;
    review: ReviewDataType;
    onSubmitFinish: () => void;
};

export function ReviewFormModalEdit(props: PropsType): JSX.Element | null {
    const {isOpen, onCancel, form, review, onSubmitFinish} = props;

    const {snackbar} = useSnackbar();

    const handleCancel = useCallback(() => {
        form.resetFields();

        onCancel();
    }, [form, onCancel]);

    const {
        mutate: editReview,
        isLoading: isReviewFormInProgress,
        error: serverError,
    } = useMutation<ReviewPayloadType, ReviewFormErrorType, EditReviewFormPaylaodType>({
        mutationFn: (fields: EditReviewFormPaylaodType) => fetchEditReview(fields),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="REVIEW__FORM__CREATED__SUCCESS_HEADER" />,
                description: <Locale stringKey="REVIEW__FORM__EDITED__SUCCESS_BODY" />,
            });

            handleCancel();
            onSubmitFinish();
        },
        onError: (error) => {
            if (error instanceof CustomReviewError) {
                snackbar.error((error as Error).message);
            } else {
                snackbar.error({
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                    description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                });
            }

            handleCustomReviewError(error);
        },
    });

    const initialCompany = useMemo(
        () =>
            review.company
                ? ({
                      id: review.company.id,
                      name: review.company.name,
                      address: review.company.address,
                  } as unknown as ReviewCustomCompanyType)
                : null,
        [review.company]
    );

    const handleSubmit = useCallback(async () => {
        await form
            .validateFields()
            .then((values) => editReview(serializeEditReviewForm(values, review.id)))
            .catch(console.log);
    }, [editReview, form, review.id]);

    return (
        <Modal
            closable={false}
            footer={
                <>
                    <Button
                        disabled={isReviewFormInProgress}
                        loading={isReviewFormInProgress}
                        onClick={handleSubmit}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>
                    <Button onClick={handleCancel}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>
                </>
            }
            open={isOpen}
            title={<Locale stringKey="REVIEW__FORM__MODAL__HEADER__EDITING" />}
        >
            <ReviewForm
                authorInfo={review.author}
                form={form}
                initialCompany={initialCompany}
                initialValues={deserializeReview(review)}
                serverError={serverError}
            />
        </Modal>
    );
}
