import {z as r} from 'zod';

import {LANGUAGE_CODE_TO_COUNTRY_CODE, LanguageCodeType} from './language-const';

function getEnumValues<T extends string>(values: Array<T>): [T, ...Array<T>] {
    if (values.length === 0 || !values[0]) {
        throw new Error('values is empty, can not create array of enum values');
    }

    return [values[0], ...values.slice(1)];
}

export const languageSchema = r.object({
    name: r.string(),
    alpha2: r.enum(getEnumValues([...Object.keys(LANGUAGE_CODE_TO_COUNTRY_CODE), 'Fr', 'sh', 'mo'])),
});

export type LanguageType = {
    name: string;
    alpha2: LanguageCodeType;
};
