import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';

import * as styles from './source-column.scss';

type PropsType = {
    catalogId: number;
};

export function SourceColumn(props: PropsType): JSX.Element {
    const {catalogId} = props;

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    return (
        <div className={styles.SourceColumn}>
            <img alt={getCatalogName(catalogId)} className={styles.SourceColumn_icon} src={getCatalogLogo(catalogId)} />

            <span>{getCatalogName(catalogId)}</span>
        </div>
    );
}
