import {sum} from 'lodash';

import {BarDataSetType} from '../../layout/chart/bar-chart/bar-chart-type';
import {LineDataSetType} from '../../layout/chart/line-chart/line-chart-type';
import {Trend} from '../../layout/trend/trend';
import {getTrendDelta} from '../../layout/trend/trend-helper';
import {Locale} from '../../provider/locale/locale';

import {ChartTooltip} from './chart-tooltip';
import {ChartTooltipDivider} from './chart-tooltip-divider';
import {getCompareColor} from './chart-tooltip-helper';
import {ChartTooltipRow} from './chart-tooltip-row';
import {ChartTooltipTitle} from './chart-tooltip-title';
import * as styles from './chart-tooltip.scss';

export type DefaultTooltipStaticPropsType = {
    data: {
        labels: Array<string>;
        datasets: Array<BarDataSetType | LineDataSetType>;
    };
    compareData?: {
        labels?: Array<string>;
        datasets: Array<BarDataSetType | LineDataSetType>;
    };

    className?: string;
    formatLabel?: (label: string, isCompare?: boolean) => string;
    showSummary?: boolean;
    formatValue?: (value: string | number) => string;
    showCompareValuesDynamics?: boolean;
};

export function DefaultChartTooltip(
    props: DefaultTooltipStaticPropsType & {
        currentIndex: number;
    }
): JSX.Element {
    const {
        data,
        compareData,
        currentIndex,
        formatLabel,
        showSummary,
        formatValue = (value: number) => value,
        showCompareValuesDynamics = false,
    } = props;
    const {labels, datasets} = data;

    const label = labels?.[currentIndex] || '';
    const compareLabel = compareData?.labels?.[currentIndex] || '';

    const formattedTitle = formatLabel ? formatLabel(label) : label;
    const formattedCompareTitle = formatLabel ? formatLabel(compareLabel) : compareLabel;

    function getSummaryValue(sumDatasets?: Array<BarDataSetType | LineDataSetType>): number {
        return sum(sumDatasets?.map((dataset) => dataset.values[currentIndex] || 0));
    }

    return (
        <ChartTooltip>
            <ChartTooltipTitle title={formattedTitle} />

            {datasets.map((dataset, datasetIndex) => {
                const value = dataset.values[currentIndex] || 0;
                const compareValue = compareData?.datasets?.[datasetIndex]?.values[currentIndex] || 0;

                return (
                    <ChartTooltipRow
                        color={String(dataset.color)}
                        key={dataset.label}
                        label={dataset.label}
                        value={
                            <>
                                {showCompareValuesDynamics && compareData && (
                                    <>
                                        <Trend
                                            delta={getTrendDelta({
                                                current: value,
                                                previous: compareValue,
                                            })}
                                        />
                                        &nbsp;
                                    </>
                                )}
                                {formatValue(value)}
                            </>
                        }
                    />
                );
            })}
            {showSummary &&
                (() => {
                    const currentValue = getSummaryValue(datasets);
                    const compareValue = (compareData && getSummaryValue(compareData.datasets)) || 0;

                    return (
                        <ChartTooltipRow
                            className={styles.ChartTooltip_contentLabelContainer__summary}
                            label={<Locale stringKey="TOOLTIP_SUMMARY" />}
                            value={
                                <div style={{display: 'flex', gap: '8px'}}>
                                    {showCompareValuesDynamics && compareData && (
                                        <>
                                            <Trend
                                                delta={getTrendDelta({
                                                    current: currentValue,
                                                    previous: compareValue,
                                                })}
                                            />
                                            &nbsp;
                                        </>
                                    )}
                                    {formatValue(currentValue)}
                                </div>
                            }
                        />
                    );
                })()}

            {compareData && (
                <>
                    <ChartTooltipDivider />
                    {formattedCompareTitle && <ChartTooltipTitle title={formattedCompareTitle} />}

                    {compareData.datasets?.map((dataset, index) => {
                        const value = dataset.values[currentIndex] || 0;
                        const formattedValue = formatValue ? formatValue(value) : value;

                        return (
                            <ChartTooltipRow
                                color={getCompareColor(String(datasets?.[index]?.color || ''))}
                                key={dataset.label}
                                label={dataset.label}
                                value={formattedValue}
                            />
                        );
                    })}

                    {showSummary && (
                        <ChartTooltipRow
                            className={styles.ChartTooltip_contentLabelContainer__summary}
                            label={<Locale stringKey="TOOLTIP_SUMMARY" />}
                            value={getSummaryValue(compareData.datasets)}
                        />
                    )}
                </>
            )}
        </ChartTooltip>
    );
}
