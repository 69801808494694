import {CustomFileType} from '../../../../service/file-upload/file-upload-type';
import {UploadFileListItem} from '../upload-file-list-item/upload-file-list-item';

import * as styles from './upload-file-list.scss';

type PropsType = {
    fileList: Array<CustomFileType>;
    allowDownload?: boolean;
    onRemove?: (fileUid: string) => void;
};

export function UploadFileList(props: PropsType): JSX.Element {
    const {fileList, allowDownload, onRemove} = props;

    return (
        <div className={styles.UploadFileList}>
            {fileList.map((file) => {
                return (
                    <UploadFileListItem allowDownload={allowDownload} file={file} key={file.uid} onRemove={onRemove} />
                );
            })}
        </div>
    );
}
