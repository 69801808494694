// TODO: remove this file/workaround after release
import {ShortLocaleNameEnum} from '../../../provider/locale/locale-context-type';

export const LANGUAGE_MENU_KEY = 'language';

export const ruHelpDeskChatLocales = new Set([ShortLocaleNameEnum.ru, ShortLocaleNameEnum.kk]);

export const localStorageLastRoutePathKey = 'lastRoutePath';

export const EXCLUDE_USER_IDS = [1_342_522];
