import {isString} from 'lodash';

import {Text} from '../../../../../component/text/text';
import {CompanyKeyEnum} from '../../../../../service/company-v2/company-const';
import {Form} from '../../../../../typings/antd';

type PropsType = {
    name: CompanyKeyEnum | Array<CompanyKeyEnum | string | number>;
};

export function ErrorList(props: PropsType): JSX.Element {
    const {name} = props;

    return (
        <Form.Item dependencies={[name]} noStyle>
            {({getFieldError}) => {
                const errors = getFieldError(name);

                if (Array.isArray(errors) && errors.every(isString)) {
                    return (
                        <Form.ErrorList
                            errors={errors.map((error) => {
                                return (
                                    <Text key={error} type="danger">
                                        {error}
                                    </Text>
                                );
                            })}
                        />
                    );
                }

                return null;
            }}
        </Form.Item>
    );
}
