import {faUpload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Upload, UploadProps} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {UploadRequestOption} from 'rc-upload/lib/interface';
import {Dispatch, PropsWithChildren, SetStateAction, useCallback, useRef} from 'react';

import {checkIsFile} from '../../../page/main/help/technical-support/components/technical-support-create-form/technical-support-create-form-helper';
import {Locale} from '../../../provider/locale/locale';
import {CustomFileType, UploadFileResponseType} from '../../../service/file-upload/file-upload-type';
import {useFormRules} from '../../../service/form/form-rules-hook';

import * as styles from './file-form-input.scss';

type PropsType<FileType extends UploadFileResponseType> = {
    upload: (file: RcFile) => Promise<FileType>;
    setFileMap: Dispatch<SetStateAction<Record<string, CustomFileType & FileType>>>;
    maxFileSize?: number;
    validationRules?: Array<(file: RcFile) => Promise<void>>;
} & UploadProps<CustomFileType & FileType>;

export function FileUpload<FileType extends UploadFileResponseType>(
    props: PropsWithChildren<PropsType<FileType>>
): JSX.Element {
    const {
        upload,
        setFileMap,
        children,
        maxCount,
        maxFileSize = 100,
        fileList,
        validationRules = [],
        ...restProps
    } = props;

    const {validateFileSize} = useFormRules();

    const fileCounterRef = useRef<number>(0);

    const uploadCustomRequest = useCallback(
        async (options: UploadRequestOption) => {
            try {
                fileCounterRef.current = 0;
                const file = options?.file;

                if (!checkIsFile(file)) {
                    return;
                }

                setFileMap((previous) => {
                    return {
                        ...previous,
                        [file.uid]: {
                            name: file?.name || '',
                            uid: file.uid,
                            url: URL.createObjectURL(file),
                            status: 'uploading',
                        } as CustomFileType & FileType,
                    };
                });

                const response = await upload(file);

                setFileMap((previous) => {
                    if (previous[file.uid]) {
                        Reflect.deleteProperty(previous, file.uid);
                        return {
                            ...previous,
                            [response.id]: {
                                ...response,
                                name: file?.name || '',
                                uid: response.id,
                                url: response.url,
                                status: 'done',
                            },
                        };
                    }

                    return previous;
                });
            } catch {
                const file = options?.file;

                if (!checkIsFile(file)) {
                    return;
                }

                setFileMap((previous) => {
                    if (previous[file.uid]) {
                        return {
                            ...previous,
                            [file.uid]: {
                                name: file?.name || '',
                                uid: file.uid,
                                url: URL.createObjectURL(file),
                                status: 'error',
                            } as CustomFileType & FileType,
                        };
                    }

                    return previous;
                });
            }
        },
        [upload, setFileMap]
    );

    const beforeUpload = useCallback(
        async (file: RcFile) => {
            fileCounterRef.current += 1;

            if (maxCount && fileCounterRef.current > maxCount) {
                return false;
            }

            await validateFileSize(file, maxFileSize);
            await Promise.all(validationRules.map(async (rule) => rule(file)));
            return true;
        },
        [maxCount, maxFileSize, validateFileSize, validationRules]
    );

    return (
        <Upload<CustomFileType & FileType>
            beforeUpload={beforeUpload}
            customRequest={uploadCustomRequest}
            fileList={fileList}
            listType="picture"
            maxCount={maxCount}
            multiple
            showUploadList={false}
            {...restProps} // eslint-disable-line react/jsx-props-no-spreading
        >
            {maxCount && (fileList?.length || 0) >= maxCount
                ? null
                : children || (
                      <Button
                          className={styles.TechnicalSupportFileUploadButton}
                          icon={<FontAwesomeIcon icon={faUpload} />}
                      >
                          <Locale stringKey="BUTTON__UPLOAD" />
                      </Button>
                  )}
        </Upload>
    );
}
