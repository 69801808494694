import {FormRule} from 'antd';
import {FormInstance, Rule} from 'antd/lib/form';
import {RcFile} from 'antd/lib/upload';
import dayjs, {Dayjs} from 'dayjs';
import {parsePhoneNumber} from 'libphonenumber-js';

import {checkSocialsNetworkRegex} from '../../page/main/company-form/page-container/widgets/links/links-form-item/links-form-item-helper';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {LangKeyType} from '../../provider/locale/translation/type';
import {useModal} from '../../provider/modal/modal-hook';
import {convertFileToImage} from '../../util/image';
import {EMAIL_REGEXP} from '../../util/regexp';
import {splitCommaSeparatedString} from '../../util/string';
import {IdNumberType} from '../../util/type';
import {isValidDomain, isValidUrl} from '../../util/url';
import {SocialApiRegularType} from '../company-v2/company-type';

import {isCode128PartiallyValid} from './form-rules-hook-helper';
import {FormRulesHookType, FormRuleType} from './form-rules-hook-type';

const minPasswordLength = 10;

// eslint-disable-next-line max-statements
export function useFormRules(): FormRulesHookType {
    const {getLocalizedString} = useLocale();

    const {modal} = useModal();

    const requiredFieldRule: Rule = {
        required: true,
        message: <Locale stringKey="VALIDATION__ERROR__FIELD_IS_REQUIRED" />,
    };

    const emailFieldRule: Rule = {
        type: 'email',
        message: <Locale stringKey="VALIDATION__ERROR__ENTER_A_VALID_EMAIL_ADDRESS" />,
    };

    const whitespaceFieldRule: Rule = {
        whitespace: true,
        message: <Locale stringKey="VALIDATION__ERROR__FIELD_IS_REQUIRED" />,
    };

    function requiredFieldRuleWithText(messageKey: LangKeyType): Rule {
        return {
            required: true,
            message: getLocalizedString(messageKey),
        };
    }

    function requiredFieldTrimmedTextRule(): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (value && value.trim().length > 0) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED')));
            },
        };
    }

    function minLengthFieldRule(
        minLength: number,
        messageKey: LangKeyType = 'VALIDATION__ERROR__FIELD_MIN_LENGTH_INCORRECT'
    ): FormRuleType<string | Array<unknown>> {
        return {
            validator: (_rule: unknown, value: string | Array<unknown>): Promise<void> => {
                const isValidationError = Array.isArray(value)
                    ? value.length < minLength
                    : value.trim().length < minLength;

                if (isValidationError) {
                    return Promise.reject(
                        new Error(
                            getLocalizedString(messageKey, {
                                length: minLength.toString(),
                            })
                        )
                    );
                }

                return Promise.resolve();
            },
        };
    }

    function maxLengthFieldRule(maxLength: number): FormRule {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (value?.trim().length > maxLength) {
                    return Promise.reject(
                        new Error(
                            getLocalizedString('VALIDATION__ERROR__FIELD_MAX_LENGTH_INCORRECT', {
                                length: maxLength.toString(),
                            })
                        )
                    );
                }

                return Promise.resolve();
            },
        };
    }

    function maxNonEmptyRowsFieldRule(maxRows: number): FormRule {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                const rowsCount =
                    value.trim().length === 0
                        ? 0
                        : value
                              .split('\n')
                              .map((rowValue) => rowValue.trim())
                              .filter(Boolean).length;

                if (rowsCount > maxRows) {
                    return Promise.reject(
                        new Error(
                            getLocalizedString('VALIDATION__ERROR__FIELD_MAX_ROWS_INCORRECT', {
                                maxRows: maxRows.toString(),
                            })
                        )
                    );
                }

                return Promise.resolve();
            },
        };
    }

    function getRequiredIdNumberListRule(): FormRuleType<Array<IdNumberType>> {
        return {
            validator: (_rule: unknown, value: Array<IdNumberType>): Promise<void> => {
                if (value.length > 0) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error(getLocalizedString('COMPANY__MULTISELECT__ERROR')));
            },
        };
    }

    function getRequiredCheckBoxRule(): FormRuleType<boolean> {
        return {
            validator: (_rule: unknown, value: boolean): Promise<void> => {
                if (value) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED')));
            },
        };
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function validatePhoneNumber(phoneNumber: string): boolean {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

        // last character must be number because 'libphonenumber-js' doesn't check it for unknown reasons
        const lastDigit = Number(phoneNumber[phoneNumber.length - 1]);

        return parsedPhoneNumber.isValid() && typeof lastDigit === 'number';
    }

    function getPhoneNumberRule(required = true): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (value) {
                    try {
                        const isValid = validatePhoneNumber(value);

                        if (isValid) {
                            return Promise.resolve();
                        }
                    } catch {
                        return Promise.reject(
                            new Error(getLocalizedString('PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_PHONE_NUMBER'))
                        );
                    }
                }

                if (required) {
                    return Promise.reject(
                        new Error(getLocalizedString('PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_PHONE_NUMBER'))
                    );
                }

                return Promise.resolve();
            },
        };
    }

    function lengthFieldRule(fieldLength: number, considerSpaces = true): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (!considerSpaces) {
                    const valueWithoutSpaces = value.split(' ').join('');

                    if (valueWithoutSpaces.length === fieldLength) {
                        return Promise.resolve();
                    }
                }

                if (considerSpaces && value.length === fieldLength) {
                    return Promise.resolve();
                }

                return Promise.reject(
                    new Error(
                        getLocalizedString('VALIDATION__ERROR__FIELD_LENGTH_INCORRECT', {
                            length: fieldLength.toString(),
                        })
                    )
                );
            },
        };
    }

    function commaSeparatedEmailFieldRule(): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                const emails = splitCommaSeparatedString(value);

                const invalidEmails = emails.filter((email) => !EMAIL_REGEXP.test(email));

                if (invalidEmails.length === 0) {
                    return Promise.resolve();
                }

                return Promise.reject(
                    new Error(
                        getLocalizedString('VALIDATION__ERROR__FIELD_COMMA_SEPARATED_EMAILS_INCORRECT', {
                            emails: invalidEmails.join(', '),
                        })
                    )
                );
            },
        };
    }

    function validUrlRule(): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (value && !isValidUrl(value)) {
                    return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__ENTER_A_VALID_URL')));
                }

                return Promise.resolve();
            },
        };
    }

    function validCode128Rule(): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (value && !isCode128PartiallyValid(value)) {
                    return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__CODE128')));
                }

                return Promise.resolve();
            },
        };
    }

    function validDomainRule(): FormRuleType<string> {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (value && !isValidDomain(value)) {
                    return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__ENTER_A_VALID_DOMAIN')));
                }

                return Promise.resolve();
            },
        };
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function validateDayjsAfterRule(localizedMessage: string, date: Date): FormRuleType<Dayjs> {
        return {
            validator: (_rule: unknown, value: Dayjs): Promise<void> => {
                if (value && value.isBefore(date)) {
                    return Promise.reject(new Error(localizedMessage));
                }

                return Promise.resolve();
            },
        };
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function validateDayjsBeforeRule(localizedMessage: string, date: Date): FormRuleType<Dayjs> {
        return {
            validator: (_rule: unknown, value: Dayjs): Promise<void> => {
                if (value && value.isAfter(date)) {
                    return Promise.reject(new Error(localizedMessage));
                }

                return Promise.resolve();
            },
        };
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function validateDayjsInTheFutureRule(localizedMessage: string): FormRuleType<Dayjs> {
        return {
            validator: (_rule: unknown, value: Dayjs): Promise<void> => {
                if (value && value.isBefore(dayjs().second(0).millisecond(0))) {
                    return Promise.reject(new Error(localizedMessage));
                }

                return Promise.resolve();
            },
        };
    }

    function validatePasswordRules(): Array<Rule> {
        return [
            {
                min: minPasswordLength,
                message: (
                    <Locale
                        stringKey="VALIDATION__ERROR__FIELD_MIN_LENGTH_INCORRECT"
                        valueMap={{length: minPasswordLength.toString()}}
                    />
                ),
            },
            {
                pattern: /\d/,
                message: <Locale stringKey="VALIDATION__ERROR__PASSWORD_MUST_CONTAIN_NUMBER" />,
            },
            {
                pattern: /[a-z]/,
                message: <Locale stringKey="VALIDATION__ERROR__PASSWORD_MUST_CONTAIN_LOWER_LATIN" />,
            },
            {
                pattern: /[A-Z]/,
                message: <Locale stringKey="VALIDATION__ERROR__PASSWORD_MUST_CONTAIN_UPPER_LATIN" />,
            },
        ];
    }

    function getCompaniesMultiselectValidationRule(selectedCount: number): Rule {
        return {
            validator: (): Promise<void> => {
                if (selectedCount > 0) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error(getLocalizedString('COMPANY__MULTISELECT__ERROR')));
            },
        };
    }

    function validateFileSize(file: File, maxSizeMb = 10): Promise<void> {
        if (file.size > maxSizeMb * 1024 * 1024) {
            modal.error({
                content: <Locale stringKey="VALIDATION__ERROR__TOO_BIG_FILE_SIZE" valueMap={{maxSize: maxSizeMb}} />,
                title: <Locale stringKey="VALIDATION__ERROR__FILE_UPLOAD__ERROR_TITLE" />,
            });
            return Promise.reject();
        }

        return Promise.resolve();
    }

    async function validateImageDimension(
        file: RcFile,
        dimensionConfig: {
            min?: {
                width: number;
                height: number;
            };
            max?: {
                width: number;
                height: number;
            };
        }
    ): Promise<void> {
        const {min, max} = dimensionConfig;

        const imageToUpload: HTMLImageElement | Error = await convertFileToImage(file).catch((error: Error) => error);

        if (imageToUpload instanceof Error) {
            modal.error({
                content: <Locale stringKey="VALIDATION__ERROR__IMAGE_UPLOAD__CONTENT" />,
                title: <Locale stringKey="VALIDATION__ERROR__IMAGE_UPLOAD__TITLE" />,
            });
            return Promise.reject();
        }

        if (
            (min && (imageToUpload.width < min.width || imageToUpload.height < min.height)) ||
            (max && (imageToUpload.width > max.width || imageToUpload.height > max.height))
        ) {
            modal.error({
                content: (
                    <Locale
                        stringKey="VALIDATION__ERROR__DIMENSION"
                        valueMap={{
                            min: min ? (
                                <Locale
                                    stringKey="VALIDATION__ERROR__MIN_IMAGE_DIMENSION"
                                    valueMap={{
                                        width: min.width,
                                        height: min.height,
                                    }}
                                />
                            ) : (
                                ''
                            ),
                            max: max ? (
                                <Locale
                                    stringKey="VALIDATION__ERROR__MAX_IMAGE_DIMENSION"
                                    valueMap={{
                                        width: max.width,
                                        height: max.height,
                                    }}
                                />
                            ) : (
                                ''
                            ),
                        }}
                    />
                ),
                title: <Locale stringKey="VALIDATION__ERROR__DIMENSION__TITLE" />,
            });
            return Promise.reject();
        }

        return Promise.resolve();
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function isErrorInForm(form: FormInstance): boolean {
        return form.getFieldsError().some(({errors}) => errors.length > 0);
    }

    function includesOnList(list: Array<string>, localizedMessage: string): Rule {
        return {
            validator: (_rule: unknown, value: string): Promise<void> => {
                if (!list.includes(value)) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error(localizedMessage));
            },
        };
    }

    function showFormStatus(socialsData?: SocialApiRegularType, checkSocialNetwork?: boolean): Rule {
        return {
            validator: (_rule: unknown, value: {value: string}) => {
                if (checkSocialNetwork && checkSocialsNetworkRegex(socialsData || [], value.value)) {
                    return Promise.reject();
                }

                return Promise.resolve();
            },
            warningOnly: true,
        };
    }

    return {
        requiredFieldRule,
        emailFieldRule,
        whitespaceFieldRule,
        showFormStatus,
        requiredFieldRuleWithText,
        requiredFieldTrimmedTextRule,
        minLengthFieldRule,
        maxLengthFieldRule,
        maxRowsFieldRule: maxNonEmptyRowsFieldRule,
        getRequiredIdNumberListRule,
        getRequiredCheckBoxRule,
        getPhoneNumberRule,
        lengthFieldRule,
        commaSeparatedEmailFieldRule,
        validUrlRule,
        validDomainRule,
        validateDayjsAfterRule,
        validateDayjsBeforeRule,
        validateDayjsInTheFutureRule,
        validatePasswordRules,
        getCompaniesMultiselectValidationRule,
        validatePhoneNumber,
        isErrorInForm,
        includesOnList,
        validateFileSize,
        validateImageDimension,
        validCode128Rule,
    };
}
