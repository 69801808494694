import {Table} from 'antd';
import {useState} from 'react';

import {PostListItemType} from '../../../../service/posts/posts-types';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {MainPostStatus} from '../post-status/main-post-status';

import {PostActions} from './post-actions/post-actions';
import {PostCatalogs} from './post-catalogs/post-catalogs';
import {PostName} from './post-name/post-name';
import {PostsTableRenderingType} from './post-table-type';
import {postsTableColumnList} from './posts-table-const';

type PropsType = {
    datasource: Array<PostListItemType>;
    onActionCompleted: () => void;
    isLoadingData: boolean;
};

export function PostsTable(props: PropsType): JSX.Element {
    const {datasource, onActionCompleted, isLoadingData} = props;
    const {getFormattedDateTime} = useFormat();
    const [isTableInProgress, setIsTableInProgress] = useState<boolean>(false);

    function renderPostRow(dataRow: PostListItemType): PostsTableRenderingType {
        const date = dataRow.createdAt ? getFormattedDateTime(Date.parse(dataRow.createdAt)) : '—';

        return {
            actions: (
                <PostActions
                    canBeRefreshed={dataRow.canBeRefreshed}
                    catalogs={dataRow.catalogs.map(({id}) => id)}
                    onActionCompleted={onActionCompleted}
                    postId={dataRow.id}
                    postSources={dataRow.catalogs}
                    setIsTableInProgress={setIsTableInProgress}
                    status={dataRow.status}
                />
            ),
            catalogs: <PostCatalogs catalogs={dataRow.catalogs} />,
            date,
            status: <MainPostStatus status={dataRow.status} />,
            title: <PostName data={dataRow} />,
            key: String(dataRow.id),
        };
    }

    const tableRenderedData: Array<PostsTableRenderingType> = datasource.map((dataEntry) => {
        return renderPostRow(dataEntry);
    });

    return (
        <Table<PostsTableRenderingType>
            columns={postsTableColumnList}
            dataSource={tableRenderedData}
            loading={isLoadingData || isTableInProgress}
            pagination={false}
            scroll={{x: true}}
            size="middle"
        />
    );
}
