import {faInfoCircle, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Form, Select, Space} from 'antd';
import {useEffect, useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {Text} from '../../../../../component/text/text';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {CompaniesSelectorFeatureEnum} from '../../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../../service/feature-companies/feature-companies-hook';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {EditUserType} from '../../../../../service/user-management/user-management-edit-user';
import {useRoles} from '../../../../../service/user-management/user-management-roles';
import {useDeleteUserMutation} from '../../../../../service/user-management/user-management-user';
import {UsersFormKeyEnum, UsersFormType} from '../../../../../service/user-management/user-management-users';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {convertErrorToFormError} from '../../../company-form/page-container/error-helper';

import {UserSourcesSelect} from './sources-select/user-sources-select';
import {UsersFormTabs} from './tabs/users-form-tabs';
import {UsersFormTabEnum} from './tabs/users-form-tabs-type';
import * as styles from './users-form.scss';

type PropsType = {
    onFinish: (values: UsersFormType, activeTab: UsersFormTabEnum) => void;
    pageHeaderTitle: string;
    emailFormItem?: JSX.Element;
    submitButtonText: JSX.Element;
    cancelButtonText: JSX.Element;
    user?: EditUserType;
    userId?: number;
    emailsError?: Array<string> | null;
};

// eslint-disable-next-line complexity,max-statements
export function UsersForm(props: PropsType): JSX.Element {
    const {onFinish, pageHeaderTitle, emailFormItem, submitButtonText, emailsError, cancelButtonText, user, userId} =
        props;

    const {pushUrl} = useUrl();
    const documentationLinks = useDocumentationLinks();
    const [activeTab, setActiveTab] = useState<UsersFormTabEnum>(UsersFormTabEnum.AccessRules);

    const [form] = Form.useForm<UsersFormType>();
    const {requiredFieldRule} = useFormRules();

    const companiesSelector = useCompaniesSelector({
        feature: CompaniesSelectorFeatureEnum.UserManagement,
        resourceId: userId ? userId.toString() : null,
        initialCompaniesCount: user?.availableCompaniesCount ?? 0,
    });

    const {mutate} = useDeleteUserMutation({
        ids: userId ? [userId] : [],
        email: user?.email,
        onSuccess: () => pushUrl(appRoute.users.path),
    });
    const {data: roles, isLoading} = useRoles();
    const roleId = Form.useWatch(UsersFormKeyEnum.RoleId, form);

    const {getFormattedDateTime} = useFormat();
    const {companyName} = useDomainConfig();
    const {getLocalizedString} = useLocale();

    const isOwner = roles?.find((role) => role.pk === roleId)?.isOwner;
    const {initializeSelector, selectorId, selectFiltered} = companiesSelector;

    const updatedDate = new Date(user ? user.updatedAt : '');
    const createdDate = new Date(user ? user.createdAt : '');

    useEffect(() => {
        if (!emailsError) {
            return;
        }

        const errors = convertErrorToFormError({emails: emailsError});

        form.setFields(errors);
        form.validateFields();
    }, [emailsError, form]);

    useEffect(() => {
        if (user?.role.pk) {
            form.setFieldValue(UsersFormKeyEnum.RoleId, user.role.pk);
        }

        if (user?.brands && user.brands.length > 0) {
            form.setFieldValue(UsersFormKeyEnum.Brands, user?.brands);
        }

        if (user?.catalogs && user.catalogs.length > 0) {
            form.setFieldValue(UsersFormKeyEnum.Sources, user?.catalogs);
        }

        if (user?.companyGroups && user.companyGroups.length > 0) {
            form.setFieldValue(UsersFormKeyEnum.Groups, user?.companyGroups);
        }
    }, [user?.role.pk, form, user?.brands, user?.companyGroups, user?.catalogs]);

    useEffect(() => {
        initializeSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isOwner && selectorId && !userId) {
            selectFiltered();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOwner, selectorId, userId]);

    useEffect(() => {
        if (user?.companyGroups && user?.companyGroups?.length > 0) {
            setActiveTab(UsersFormTabEnum.CompaniesGroup);
        }

        if (selectorId && user?.companyGroups.length === 0 && user?.brands.length === 0) {
            setActiveTab(UsersFormTabEnum.IndividualCompanies);
        }
    }, [selectorId, user?.brands.length, user?.companyGroups, user?.companyGroups.length]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('USERS__TITLE')} />
            <PageCard>
                <BreadCrumbs
                    list={[
                        {path: appRoute.users.path, titleLangKey: 'USERS__BREADCRUMB'},
                        {
                            path: appRoute.createUser.path,
                            titleLangKey: 'USERS__FORM__ADD_USERS',
                        },
                    ]}
                />

                <PageHeader className={styles.UsersForm_pageHeader}>
                    {pageHeaderTitle}
                    <NavigationLink to={appRoute.users.path}>
                        <Button>
                            <Text stringKey="BUTTON__BACK" />
                        </Button>
                    </NavigationLink>
                </PageHeader>
            </PageCard>
            <PageCard bodyClassName={styles.UsersForm_wrapper}>
                <Form
                    className={styles.UsersForm_form}
                    form={form}
                    initialValues={{roleId: user?.role.pk}}
                    layout="vertical"
                    onFinish={(values) => {
                        onFinish({...values, companiesSelectorUid: selectorId}, activeTab);
                    }}
                >
                    <Form.Item
                        label={<Text stringKey="USERS__FORM__SELECT_ROLE__LABEL" />}
                        name={UsersFormKeyEnum.RoleId}
                        required
                        rules={[requiredFieldRule]}
                    >
                        {roles && (
                            <Select<number>
                                options={[
                                    ...(user?.role.isOwner && !roles.some((role) => role.pk === user.role.pk)
                                        ? [{label: user.role.name, value: user.role.pk}]
                                        : []),
                                    ...roles.map((accessRole) => ({
                                        label: accessRole.name,
                                        value: accessRole.pk,
                                    })),
                                ]}
                                placeholder={getLocalizedString('USERS__FORM__SELECT_ROLE__PLACEHOLDER')}
                                size="large"
                            />
                        )}
                    </Form.Item>

                    {emailFormItem}

                    <UserSourcesSelect form={form} user={user} />

                    <div className={styles.UsersForm_alert}>
                        <UsersFormTabs
                            activeTab={activeTab}
                            companiesSelector={companiesSelector}
                            form={form}
                            isOwner={isOwner}
                            setActiveTab={setActiveTab}
                            user={user}
                            userId={userId}
                        />
                        {isOwner && (
                            <Alert
                                description={<Text stringKey="USERS__FORM__OWNER_ALERT__DESCRIPTION" />}
                                message={
                                    <Text stringKey="USERS__FORM__OWNER_ALERT__MESSAGE" valueMap={{companyName}} />
                                }
                                showIcon
                                type="warning"
                            />
                        )}
                        {!user && (
                            <Alert
                                closable
                                description={<Text stringKey="USERS__FORM__EMAIL_LINK_ALERT" />}
                                showIcon
                                type="info"
                            />
                        )}
                    </div>

                    {user && userId && (
                        <div className={styles.UsersForm_editUserInfo}>
                            <div className={styles.UsersForm_updateInfo}>
                                <Space>
                                    <Text secondary stringKey="USERS__FORM__TAB__USER_INFO__CREATE" />
                                    <Text small>
                                        {user.creatorEmail} · {getFormattedDateTime(createdDate)}
                                    </Text>
                                </Space>
                                {user.updatedAt && user.updaterEmail && (
                                    <Space>
                                        <Text secondary stringKey="USERS__FORM__TAB__USER_INFO__UPDATE" />
                                        <Text small>
                                            {user.updaterEmail} · {getFormattedDateTime(updatedDate)}
                                        </Text>
                                    </Space>
                                )}
                            </div>
                            <Button
                                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                onClick={() => mutate(userId)}
                                size="large"
                                type="text"
                            >
                                <span>
                                    <Locale stringKey="BUTTON__DELETE" />
                                </span>
                            </Button>
                        </div>
                    )}

                    <Space wrap>
                        <Button htmlType="submit" size="large" type="primary">
                            {submitButtonText}
                        </Button>
                        <NavigationLink to={appRoute.users.path}>
                            <Button size="large">{cancelButtonText}</Button>
                        </NavigationLink>
                    </Space>
                </Form>
                {documentationLinks.users.roleDescription && (
                    <Alert
                        className={styles.UsersForm_docsAlert}
                        description={
                            <Text
                                lighter
                                stringKey="USERS__FORM__ALERT__DOCS_LINK"
                                valueMap={{
                                    link: (
                                        <a
                                            href={documentationLinks.users.roleDescription}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <Locale stringKey="USERS__FORM__ALERT__DOCS_LINK_NAME" />
                                        </a>
                                    ),
                                }}
                            />
                        }
                        icon={<FontAwesomeIcon className={styles.UsersForm_alertIcon} icon={faInfoCircle} />}
                        showIcon
                        type="info"
                    />
                )}
            </PageCard>
        </Page>
    );
}
