export enum CompanyKeyEnum {
    Names = 'names',
    ExtraNames = 'extraNames',
    SearchNames = 'searchNames',
    Code = 'code',
    BrandId = 'brandId',
    BrandName = 'brandName',
    Address = 'address',
    Legal = 'legal',
    RocketdataCategories = 'rocketdataCategories',
    CatalogCategories = 'catalogCategories',
    MappedCatalogCategories = 'mappedCatalogCategories',
    Phones = 'phones',
    ExtraPhones = 'extraPhones',
    Email = 'email',
    ExtraEmails = 'extraEmails',
    Websites = 'websites',
    ExtraWebsites = 'extraWebsites',
    SocialNetworks = 'socialNetworks',
    ExtraSocialNetworks = 'extraSocialNetworks',
    TemporaryClosed = 'temporaryClosed',
    WorkingHours = 'workingHours',
    ExtraWorkingHours = 'extraWorkingHours',
    SpecialHours = 'specialHours',
    Payments = 'payments',
    Permissions = 'permissions',
    Descriptions = 'descriptions',
    ExtraDescriptions = 'extraDescriptions',
    Logo = 'logo',
    Cover = 'cover',
    ExtraLogo = 'extraLogo',
    ExtraCover = 'extraCover',
    Gallery = 'gallery',
    ExtraGallery = 'extraGallery',
    NeedModerate = 'needModerate',
    Attributes = 'attributes',
    IsDoubleGis = 'isDoubleGis',
    IsPaidYandexPlacement = 'isPaidYandexPlacement',
    SubmitTimestamp = 'submitTimestamp',
    YandexNeedActualization = 'yandexActualizationHasError',
}

export const enum ProtocolEnum {
    HTTP = 'http://',
    HTTPS = 'https://',
}

export const lastCompanyKeys = [
    CompanyKeyEnum.Names,
    CompanyKeyEnum.ExtraNames,
    CompanyKeyEnum.RocketdataCategories,
    CompanyKeyEnum.CatalogCategories,
    CompanyKeyEnum.MappedCatalogCategories,
    CompanyKeyEnum.Phones,
    CompanyKeyEnum.ExtraPhones,
    CompanyKeyEnum.Email,
    CompanyKeyEnum.ExtraEmails,
    CompanyKeyEnum.Websites,
    CompanyKeyEnum.ExtraWebsites,
    CompanyKeyEnum.SocialNetworks,
    CompanyKeyEnum.ExtraSocialNetworks,
    CompanyKeyEnum.Payments,
    CompanyKeyEnum.Descriptions,
    CompanyKeyEnum.ExtraDescriptions,
    CompanyKeyEnum.Logo,
    CompanyKeyEnum.Cover,
] as const;
