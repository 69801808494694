import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

import {CompanyImageFormType} from './company-logo-type';

const gallerySchema = r.object({
    hash: r.string(),
    url: r.string(),
});

const companyExtraGallerySchema = r.object({
    catalogId: r.number(),
    gallery: r.array(gallerySchema),
});

export const companyGallerySchema = {
    [CompanyKeyEnum.Gallery]: r.array(gallerySchema),
    [CompanyKeyEnum.ExtraGallery]: r.array(companyExtraGallerySchema),
};

const companyGallerySchemaObject = r.object(companyGallerySchema);

export type CompanyGalleryType = r.infer<typeof companyGallerySchemaObject>;

export type CompanyGalleryFormType = {
    [CompanyKeyEnum.Gallery]: Array<CompanyGalleryImageType>;
    [CompanyKeyEnum.ExtraGallery]: Array<{catalogId: number; gallery: Array<CompanyGalleryImageType>}>;
};

export type CompanyGalleryServerType = {
    [CompanyKeyEnum.Gallery]: Array<{hash?: string; tempId?: string | null}>;
    [CompanyKeyEnum.ExtraGallery]: Array<{catalogId: number; gallery: Array<{hash?: string; tempId?: string | null}>}>;
};

export type CompanyGalleryImageType = CompanyImageFormType;
