import {faCaretDown, faCaretUp, faCircleSmall} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/localization';
import {
    LocalPackPositionsType,
    LocalPackQueryResultFullValueResponseType,
    LocalPackQueryResultValueResponseType,
    LocalPackSearchServiceType,
    LocalPackServicesEnum,
} from '../../../../service/local-pack/local-pack-type';
import {classNames} from '../../../../util/css';
import {localPackNullPositionValue} from '../local-pack-const';

import {localPackQueryTableLocalizationMap} from './query-result-table-const';
import {LocalPackQueryResultsTableValueRow} from './query-result-table-type';
import * as styles from './query-results-table.scss';

function getDeltaIcon(delta: number | null): JSX.Element {
    const hasDelta = delta !== null;

    switch (true) {
        case hasDelta && delta > 0: {
            return <FontAwesomeIcon icon={faCaretUp} />;
        }
        case hasDelta && delta < 0: {
            return <FontAwesomeIcon icon={faCaretDown} />;
        }
        default:
            return <FontAwesomeIcon icon={faCircleSmall} size="2xs" />;
    }
}

function getPreviousResultClassName(delta: number | null): string {
    const hasDelta = delta !== null;
    const previousClassName: string = (() => {
        switch (true) {
            case hasDelta && delta > 0: {
                return styles.position_up;
            }
            case hasDelta && delta < 0: {
                return styles.position_down;
            }
            default:
                return styles.position_no_changed;
        }
    })();

    return classNames(styles.previous_position, previousClassName);
}

export function getLocalPackQueryResultsTableColumns(
    searchServices: Array<LocalPackSearchServiceType>
): TableColumnsType<Record<string, unknown>> {
    const localPackQueryResultsColumns: TableColumnsType<Record<string, unknown>> = [
        {
            title: <Locale stringKey="LOCAL_PACK__QUERY_RESULTS_TABLE__KEY" />,
            dataIndex: 'keyColumn',
            width: '40%',
        },
        {
            title: <Locale stringKey="LOCAL_PACK__QUERY_RESULTS_TABLE__DATE" />,
            dataIndex: 'date',
        },
    ];

    const servicesColumns: TableColumnsType<Record<string, unknown>> = Object.keys(LocalPackServicesEnum)
        .filter((serviceKey) => searchServices.some((value) => value.isActive && value.service === serviceKey))
        .map((serviceKey) => {
            return {
                title: (
                    <Locale
                        stringKey={localPackQueryTableLocalizationMap[serviceKey as keyof typeof LocalPackServicesEnum]}
                    />
                ),
                dataIndex: serviceKey,
                key: serviceKey,
                align: 'center',
            };
        });

    localPackQueryResultsColumns.push(...servicesColumns);

    if (servicesColumns.length > 1) {
        localPackQueryResultsColumns.push({
            title: <Locale stringKey="LOCAL_PACK__QUERY_RESULTS_TABLE__ALL" />,
            dataIndex: 'allServices',
            key: 'allServices',
            align: 'center',
        });
    }

    return localPackQueryResultsColumns;
}

export function getLocalPackQueryResultsTableValueRow({
    positions,
    getFormattedNumber,
}: LocalPackQueryResultsTableValueRow): JSX.Element | string {
    const currentPosition: number = positions.currentPosition || localPackNullPositionValue;
    const previousPosition: number | null = positions.hasPrev
        ? positions.previousPosition || localPackNullPositionValue
        : null;

    const delta: number | null = previousPosition ? previousPosition - currentPosition : null;

    const icon: JSX.Element = getDeltaIcon(delta);

    const previousResultClassName = getPreviousResultClassName(delta);

    return (
        <div className={styles.local_pack_query_table__value_row}>
            <span className={styles.current_position}>
                {currentPosition !== localPackNullPositionValue
                    ? getFormattedNumber(currentPosition, {maximumFractionDigits: 2})
                    : `${getFormattedNumber(currentPosition)}+`}
            </span>
            <span className={previousResultClassName}>
                {delta !== null && (
                    <>
                        {icon}
                        <span>{getFormattedNumber(Math.abs(delta), {maximumFractionDigits: 2})}</span>
                    </>
                )}
            </span>
        </div>
    );
}

export function getLocalPackAllServiceResult(
    searchServices: Array<LocalPackSearchServiceType>,
    values: Array<LocalPackQueryResultFullValueResponseType>
): LocalPackPositionsType {
    const allActiveValues: Array<LocalPackQueryResultValueResponseType | undefined> = searchServices
        .filter((service) => service.isActive)
        .map((service) => values.find((value) => value.service === service.service));

    const currentValues: Array<number | undefined | null> = allActiveValues
        .filter((value) => value?.currentPosition)
        .map((value) => {
            return value?.currentPosition || localPackNullPositionValue;
        });
    const previousValues: Array<number | undefined | null> = allActiveValues
        .filter((value) => value?.hasPrev)
        .map((value) => {
            return value?.previousPosition || localPackNullPositionValue;
        });

    const sumCurrentValue = currentValues.reduce((result, currentValue) => {
        if (!currentValue) {
            return result;
        }

        return (result || 0) + currentValue;
    }, 0);

    const averageCurrentValue =
        sumCurrentValue && currentValues.length > 0 ? sumCurrentValue / currentValues.length : null;

    const sumPreviousValue = previousValues.reduce((result, previousValue) => {
        if (!previousValue) {
            return result;
        }

        return (result || 0) + previousValue;
    }, 0);

    const averagePreviousValue =
        sumPreviousValue && previousValues.length > 0 ? sumPreviousValue / previousValues.length : null;

    return {
        currentPosition: averageCurrentValue,
        previousPosition: averagePreviousValue,
        hasPrev: previousValues.length > 0,
    };
}
