import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';
import {RcFile} from 'antd/lib/upload';
import {useCallback, useState} from 'react';

import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {UploadFileResponseType} from '../file-upload/file-upload-type';

import {
    createYandexStory,
    deleteYandexStory,
    fetchDashboardYandexStories,
    fetchYandexSourcesStories,
    fetchYandexStoryById,
    fetchYandexStorySelectedCompanies,
    updateYandexStory,
    uploadStoryImage,
} from './yandex-stories-api';
import {prepareCreateYandexStoryRequestData} from './yandex-stories-helper';
import {
    UseYandexPreviewHookType,
    YandexShortStoryType,
    YandexSourcesStoriesResponseType,
    YandexStoriesResponseType,
    YandexStoryFormType,
    YandexStorySelectedCompaniesType,
    YandexStoryType,
} from './yandex-stories-type';

export function useYandexStories(): UseInfiniteQueryResult<YandexStoriesResponseType> {
    return useInfiniteQuery(['yandex-stories'], async ({pageParam: page = 1}) => fetchDashboardYandexStories(page), {
        getPreviousPageParam: (firstPage) => (firstPage.previous && firstPage.page ? firstPage.page - 1 : 1),
        getNextPageParam: (lastPage) => (lastPage.next && lastPage.page ? lastPage.page + 1 : null),
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchInterval: 0,
        retry: 0,
    });
}

export function useYandexStorySelectedCompanies(options: {
    storyId: number;
    enabled: boolean;
}): UseInfiniteQueryResult<YandexStorySelectedCompaniesType> {
    const {storyId, enabled} = options;

    return useInfiniteQuery(
        ['yandex-stories-selected-companies', storyId],
        async ({pageParam: page = 1}) => fetchYandexStorySelectedCompanies(storyId, page),
        {
            getPreviousPageParam: (firstPage) => (firstPage.previous && firstPage.page ? firstPage.page - 1 : 1),
            getNextPageParam: (lastPage) => (lastPage.next && lastPage.page ? lastPage.page + 1 : null),
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            refetchInterval: 0,
            retry: 0,
            enabled,
        }
    );
}

export function useYandexSourcesStories(
    sourceIds: Array<number>
): UseQueryResult<Map<number, YandexSourcesStoriesResponseType>> {
    return useQuery(['yandex-sources-stories', sourceIds], () => fetchYandexSourcesStories(sourceIds), {
        enabled: sourceIds && sourceIds.length > 0,
        retry: 0,
        select(data: Array<YandexSourcesStoriesResponseType>) {
            const newValues = new Map();

            data.forEach((source) => {
                newValues.set(Number(source.companyId), source);
            });
            return newValues;
        },
    });
}

export function useYandexStory(storyId: number | null, enabled: boolean): UseQueryResult<YandexStoryType> {
    return useQuery(['single-story', storyId], () => fetchYandexStoryById(storyId || 0), {
        enabled: enabled && Boolean(storyId),
        retry: 0,
        refetchOnMount: false, // don't set true, because maximum update depth exceeded will be thrown
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useYandexPreview(
    stories: Array<YandexShortStoryType>,
    fetchNextPage?: VoidFunction
): UseYandexPreviewHookType {
    const [activeStoryId, setActiveStoryId] = useState<number | null>(null);
    const [nextStoryId, setNextStoryId] = useState<number | null>(null);
    const [previousStoryId, setPreviousStoryId] = useState<number | null>(null);

    const onChangeStoryId = useCallback(
        (storyId: number | null) => {
            if (!storyId) {
                setActiveStoryId(null);
                setNextStoryId(null);
                setPreviousStoryId(null);
                return;
            }

            const index = stories.findIndex((story) => story.id === storyId);
            const previousStory = stories[index - 1];
            const nextStory = stories[index + 1];
            const isPreLastStory = index === stories.length - 2;

            if (isPreLastStory) {
                fetchNextPage?.();
            }

            if (previousStory) {
                setPreviousStoryId(previousStory.id);
            } else {
                setPreviousStoryId(null);
            }

            if (nextStory) {
                setNextStoryId(nextStory.id);
            } else {
                setNextStoryId(null);
            }

            setActiveStoryId(storyId);
        },
        [fetchNextPage, stories]
    );

    return {activeStoryId, previousStoryId, nextStoryId, onChangeStoryId};
}

export function useDeleteYandexStory(storyName: string): UseMutationResult<unknown, unknown, number> {
    const queryClient = useQueryClient();

    const {snackbar} = useSnackbar();

    return useMutation(['delete-story'], (storyId) => deleteYandexStory(storyId), {
        onSuccess() {
            snackbar.success({
                message: <Locale stringKey="YANDEX_STORY__SNACKBAR__DELETE_STORY__SUCCESS__MESSAGE" />,
                description: (
                    <Locale
                        stringKey="YANDEX_STORY__SNACKBAR__DELETE_STORY__SUCCESS__DESCRIPTION"
                        valueMap={{storyName}}
                    />
                ),
            });
            queryClient.invalidateQueries(['yandex-sources-stories']);
        },

        onError() {
            snackbar.error({
                message: <Locale stringKey="YANDEX_STORY__SNACKBAR__DELETE_STORY__ERROR__MESSAGE" />,
                description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
            });
        },
    });
}

export function useUploadStoryImage(): UseMutationResult<UploadFileResponseType, unknown, RcFile> {
    return useMutation(['upload-story-image'], async (file: RcFile) => {
        const response = await uploadStoryImage(file);

        return {
            url: response.url,
            id: response.id,
        };
    });
}

export function useCreateYandexStory(): UseMutationResult<void, unknown, YandexStoryFormType> {
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();

    return useMutation(
        ['create-yandex-story'],
        async (formData: YandexStoryFormType) => {
            return createYandexStory(prepareCreateYandexStoryRequestData(formData));
        },
        {
            onSuccess: () => {
                snackbar.success({
                    message: getLocalizedString('YANDEX_STORY__CREATE_SUCCESS'),
                    description: getLocalizedString('YANDEX_STORY__CREATE_SUCCESS__DESCRIPTION'),
                });
            },
            onError: () => {
                snackbar.error({
                    message: getLocalizedString('YANDEX_STORY__CREATE_ERROR'),
                    description: getLocalizedString('ERROR__SOMETHING_WENT_WRONG_DETAILS'),
                });
            },
        }
    );
}

export function useUpdateYandexStory(storyId: number): UseMutationResult<void, unknown, YandexStoryFormType> {
    const {snackbar} = useSnackbar();
    const queryClient = useQueryClient();

    const {getLocalizedString} = useLocale();

    return useMutation(
        ['update-yandex-story'],
        async (story: YandexStoryFormType) => {
            return updateYandexStory(storyId, prepareCreateYandexStoryRequestData(story));
        },
        {
            onSuccess: () => {
                snackbar.success(getLocalizedString('YANDEX_STORY__UPDATE_SUCCESS'));
                queryClient.invalidateQueries(['single-story', storyId]);
            },
            onError: () => {
                snackbar.error(getLocalizedString('YANDEX_STORY__UPDATE_ERROR'));
            },
        }
    );
}
