import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useState} from 'react';

import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useUser} from '../../../../provider/user/user-hook';
import {classNames} from '../../../../util/css';

import {DisableTwoFactor} from './disable-two-factor/disable-two-factor';
import {EnableTwoFactor} from './enable-two-factor/enable-two-factor';
import * as styles from './two-factor-authentication.scss';

type PropsType = {
    className?: string;
};

export function TwoFactorAuthentication(props: PropsType): JSX.Element {
    const {className} = props;
    const fullClassName = classNames(styles.two_factor_authentication, className);

    const {user, getUser, isInGettingUser} = useUser();

    const [disableTwoFa, setDisableTwoFa] = useState<boolean>(false);

    function handleDisableTwoFa() {
        setDisableTwoFa(true);
    }

    function handleFinishSubmit() {
        getUser();
    }

    if (isInGettingUser) {
        return <Spinner />;
    }

    if (!user?.is2faEnabled) {
        return <EnableTwoFactor className={fullClassName} onFinishSubmit={handleFinishSubmit} />;
    }

    if (user?.is2faEnabled && disableTwoFa) {
        return <DisableTwoFactor className={fullClassName} onFinishSubmit={handleFinishSubmit} />;
    }

    return (
        <div className={fullClassName}>
            <div className={styles.google_authenticator_connected}>
                <FontAwesomeIcon className={styles.google_authenticator_connected__icon} icon={faCircleCheck} />

                <h3 className={styles.google_authenticator_connected__header}>
                    <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__GOOGLE_AUTHENTICATOR_CONNECTED" />
                </h3>

                <Button danger onClick={handleDisableTwoFa}>
                    <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__BUTTON__DISABLE_GOOGLE_AUTHENTICATOR" />
                </Button>
            </div>
        </div>
    );
}
