import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tag, Tooltip} from 'antd';

import {GoogleAccountType} from '../../../../../../service/google/accounts/google-accounts-type';
import {classNames} from '../../../../../../util/css';

import {googleAccountTags} from './account-tag-const';
import * as styles from './account-tag.scss';

type PropsType = {
    account: GoogleAccountType;
};

export function AccountTag(props: PropsType): JSX.Element | null {
    const {account} = props;

    const accountTag = googleAccountTags.find((tag) => tag.isShown(account));

    if (!accountTag) {
        return null;
    }

    return (
        <Tooltip placement="bottom" title={accountTag.tooltip || accountTag.label}>
            <Tag className={classNames(styles.AccountTag, accountTag.className)}>
                <FontAwesomeIcon icon={accountTag.icon} />

                {accountTag.label}
            </Tag>
        </Tooltip>
    );
}
