import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ButtonProps} from 'antd';

import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {useModal} from '../../../provider/modal/modal-hook';
import {useDeleteYandexStory} from '../../../service/yandex-stories/yandex-stories-hook';

import * as styles from './yandex-stories-sources.scss';

type PropsType = {
    storyId: number;
    storyName: string;
    iconClassName?: string;
    className?: string;
    isDisabled?: boolean;
    onClick?: VoidFunction;
    type?: ButtonProps['type'];
    onDeleteSuccess?: VoidFunction;
};

export function YandexDeleteButton(props: PropsType): JSX.Element {
    const {storyId, storyName, type, onClick, isDisabled, iconClassName, className, onDeleteSuccess} = props;
    const {mutateAsync} = useDeleteYandexStory(storyName);
    const {modal} = useModal();
    const {getLocalizedString} = useLocale();

    function handleDeleteConfirm() {
        modal.confirm({
            maskClosable: true,
            title: getLocalizedString('YANDEX_STORY__CONFIRM__DELETE_STORY__TITLE'),
            content: getLocalizedString('YANDEX_STORY__CONFIRM__DELETE_STORY__DESCRIPTION'),
            okText: <Locale stringKey="BUTTON__DELETE" />,
            okButtonProps: {type: 'primary', rootClassName: styles.YandexStoriesSources_confirmDelete, danger: true},
            cancelText: <Locale stringKey="BUTTON__CANCEL" />,
            onOk: async () => {
                await mutateAsync(storyId);

                onDeleteSuccess?.();
                onClick?.();
            },
        });
    }

    return (
        <Button
            className={className}
            disabled={isDisabled}
            icon={<FontAwesomeIcon className={iconClassName} icon={faTrash} />}
            onClick={handleDeleteConfirm}
            type={type}
        />
    );
}
