import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {defaultTagData} from '../../../../service/reviews/reviews-tags';
import {TagForm} from '../tag-form/tag-form';

export function TagCreate(): JSX.Element {
    const {
        reviewsManagementTags: reviewsManagementTagsRoute,
        reviewsManagementTagCreate: reviewsManagementTagCreateRoute,
    } = appRoute;

    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('TAGS__CREATING')} />

            <BreadCrumbs
                list={[
                    {
                        path: reviewsManagementTagsRoute.path,
                        titleLangKey: 'CATEGORY_NAME__TAGS',
                    },
                    {
                        path: reviewsManagementTagCreateRoute.path,
                        titleLangKey: 'TAGS__CREATING',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="TAGS__CREATING" />
            </PageHeader>

            <TagForm mode="create" tagData={defaultTagData} />
        </Page>
    );
}
