import dayjs from 'dayjs';

import {isDateValid} from '../../../../../../../util/date';

export function isLastChangeDateAfter(lastChangesDate: string, anotherDate: string | null): boolean {
    if (!lastChangesDate && !anotherDate) {
        return false;
    }

    if (!isDateValid(new Date(lastChangesDate)) && !isDateValid(new Date(anotherDate || ''))) {
        return false;
    }

    return dayjs(anotherDate).isAfter(dayjs(lastChangesDate));
}
