import {Text} from '../../../../../component/text/text';
import {PostLinkType} from '../../../../../service/posts/posts-types';

type PropsType = {
    data: PostLinkType;
};

export function SourceNameColumn(props: PropsType): JSX.Element {
    const {data} = props;

    return (
        <div>
            <div>
                <Text bolder>{data.sourceName}</Text>{' '}
                <Text lightest small>
                    {data.companyCode}
                </Text>
            </div>
            <Text lighter small>
                {data.address ?? data.pageLink}
            </Text>
        </div>
    );
}
