import {Badge} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {gray6} from '../../../../../../css/var-export.scss';
import {CclTableDataType} from '../../../../../../service/ccl/ccl-type';
import {ProvidersIdsEnum} from '../../../../../../util/type';

import yandexApprovedLocationMarker from './images/yandex-approved-location-marker.svg';
import * as styles from './name-and-address-column.scss';

type PropsType = {
    ccl: CclTableDataType;
};

export function NameAndAddressColumn(props: PropsType): JSX.Element {
    const {ccl} = props;
    const {company, catalog, isVerified} = ccl;
    const {name, address} = company;

    return (
        <div className={styles.NameAndAddressColumn}>
            <Text className={styles.NameAndAddressColumn_companyName}>
                {name}

                {catalog.id === ProvidersIdsEnum.yandex && isVerified && (
                    <>
                        <Badge color={gray6} />
                        <img alt="approved" src={yandexApprovedLocationMarker} />
                    </>
                )}
            </Text>

            <Text lighter small>
                {[address.country, address.region, address.city, address.street, address.housenumber]
                    .filter(Boolean)
                    .join(', ')}
            </Text>
        </div>
    );
}
