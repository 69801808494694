import {Form, Space} from 'antd';
import dayjs from 'dayjs';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {Text} from '../../../../component/text/text';
import {Logo} from '../../../../layout/logo/logo';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {TariffType} from '../../../../service/tariffs/tariffs-user-tariff';
import {TariffFromListType} from '../../../../service/tariffs/tariffs-user-tariffs';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';

import {TariffsBrandsList} from './tariffs-brands-list/tariffs-brands-list';
import {TariffsLinkWrapper} from './tariffs-link-wrapper/tariffs-link-wrapper';
import {TariffsSelect} from './tariffs-select/tariffs-select';
import * as styles from './tariffs-block.scss';

type PropsType = {
    tariffs: Array<TariffFromListType>;
    tariff: TariffType;
    setTariffId: Dispatch<SetStateAction<number | null>>;
    isFetching: boolean;
    fetchNextPage: () => void;
};

const today = dayjs();

export function TariffsInfo(props: PropsType): ReactElement {
    const {tariff, tariffs, setTariffId, fetchNextPage, isFetching} = props;

    const {getFormattedDateTime} = useFormat();

    const documentationLinks = useDocumentationLinks();

    const isExpired = dayjs(tariff.finishAt).isSameOrBefore(today, 'day');

    return (
        <PageCard className={styles.TariffBlock}>
            <Space className={styles.TariffBlock_wrapper} direction="vertical" size={32}>
                <Logo className={styles.TariffBlock_logo} />

                <Space className={styles.TariffBlock_wrapper} direction="vertical">
                    {tariffs.length > 1 && (
                        <Form.Item
                            className={styles.TariffBlock_selectWrapper}
                            colon={false}
                            label={<Text secondary stringKey="TARIFFS__CURRENT" />}
                        >
                            <TariffsSelect
                                fetchNextPage={fetchNextPage}
                                isFetching={isFetching}
                                setTariffId={setTariffId}
                                tariff={tariff}
                                tariffs={tariffs || []}
                            />
                        </Form.Item>
                    )}
                    {tariffs.length === 1 && (
                        <>
                            {tariff?.isTest && (
                                <Text
                                    bolder
                                    className={styles.TariffBlock_name__testLabel}
                                    small
                                    stringKey="TARIFFS__TEST_TARIFF"
                                />
                            )}
                            {tariff?.isDemo && (
                                <Text
                                    bolder
                                    className={styles.TariffBlock_name__demoLabel}
                                    small
                                    stringKey="TARIFFS__DEMO_TARIFF"
                                />
                            )}
                            <Text bolder className={styles.TariffBlock_name}>
                                {tariff?.name}
                            </Text>
                        </>
                    )}
                    <Space direction="vertical" size={0}>
                        <Text secondary stringKey="TARIFFS__PAID_PERIOD" />
                        {tariff && (
                            <Text
                                className={classNames(styles.TariffBlock_licenseDate, {
                                    [styles.TariffBlock_licenseDate__expired]: isExpired,
                                })}
                            >
                                {getFormattedDateTime(new Date(tariff.startAt))}—
                                {getFormattedDateTime(new Date(tariff.finishAt))}
                            </Text>
                        )}
                    </Space>
                </Space>
                {tariff.isViewTariffsListEnabled && (
                    <TariffsLinkWrapper
                        link={documentationLinks.ratesLink}
                        title="TARIFFS__LINK_TITLE__SHOW_ALL_TARIFFS"
                    />
                )}
                <TariffsBrandsList count={tariff.brandCount} list={tariff.brands} />
            </Space>
        </PageCard>
    );
}
