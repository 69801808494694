import {Key} from 'react';

import {ReviewConcordanceItemType} from '../../../../../service/reivew-analysis/review-concordance/review-concordance-type';

export type ConcordanceDataType = ReviewConcordanceItemType & {
    key: Key;
};

export enum ConcordanceSentimentTooltipEnum {
    positive = 'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE',
    negative = 'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE',
    unspecified = 'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED',
    neutral = 'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL',
}
