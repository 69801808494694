import {lazy, Suspense} from 'react';

import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {Page} from '../../../layout/page/page';
import {useSourceSettingsAccess} from '../../../service/source-settings/access/access-hook';

import {AccessDeniedPage} from './pages/access-denied-page/access-denied-page';
import {renderFallback} from './source-settings-index-helper';

const SourceListPage = lazy(() => import('./pages/source-links-page/source-links-page'));

export function SourceSettingsIndex(): JSX.Element {
    const {result, isInProgress, processError} = useSourceSettingsAccess();

    if (isInProgress) {
        return renderFallback();
    }

    if (processError) {
        return (
            <Page isTopFilterHidden>
                <AlertFallback />
            </Page>
        );
    }

    if (result?.hasAccess) {
        return (
            <Suspense fallback={renderFallback()}>
                <SourceListPage />
            </Suspense>
        );
    }

    return <AccessDeniedPage />;
}
