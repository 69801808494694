import {Button, Radio, RadioChangeEvent, Typography} from 'antd';
import {useMemo, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useBrands} from '../../../../provider/brands/brand-hook';
import {BrandType} from '../../../../provider/brands/brand-type';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {urlQueryMap} from '../../../../shared-search-parameters';
import {objectToUrlParameters} from '../../../../util/url';

import * as styles from './create-company-select-brand.scss';

const {Text} = Typography;

export function CreateCompanySelectBrand(): JSX.Element {
    const {createCompany: createCompanyRoute, myCompanies: myCompaniesRoute} = appRoute;
    const {path: createCompanyRoutePath} = createCompanyRoute;
    const [selectedBrandId, setSelectedBrandId] = useState<string>('');
    const {brands, isLoading} = useBrands();
    const {getLocalizedString} = useLocale();

    const newBrandId = 'new-brand-id';

    function getQueryParameters(): string {
        if (selectedBrandId === newBrandId) {
            return objectToUrlParameters({
                step: '',
            });
        }

        return objectToUrlParameters({
            [urlQueryMap.brandId]: selectedBrandId,
            step: '',
        });
    }

    const radioButtonList = useMemo((): JSX.Element | Array<JSX.Element> => {
        if (isLoading) {
            return <Spinner isShow position="absolute" />;
        }

        return brands
            .filter((filteredBrand) => filteredBrand.isPossibleToCreateCompanyForUser)
            .map((brand: BrandType): JSX.Element => {
                return (
                    <Radio className={styles.select_brand_radio_button} key={brand.id} value={brand.id}>
                        {brand.name}
                    </Radio>
                );
            });
    }, [brands, isLoading]);

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__CREATION_OF_THE_COMPANY')} />

            <BreadCrumbs
                list={[
                    {path: myCompaniesRoute.path, titleLangKey: 'CATEGORY_NAME__MY_COMPANIES'},
                    {
                        path: createCompanyRoutePath,
                        titleLangKey: 'CATEGORY_NAME__CREATION_OF_THE_COMPANY',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__CREATION_OF_THE_COMPANY" />
            </PageHeader>

            <Text className={styles.select_brand_radio_group_header}>
                <Locale stringKey="TEXT__SELECT_THE_BRAND_THE_COMPANY_BELONGS_TO" />
            </Text>

            <Radio.Group
                className={styles.select_brand_radio_group}
                name="brand"
                onChange={(event: RadioChangeEvent): void => setSelectedBrandId(String(event.target.value))}
            >
                {radioButtonList}

                <Radio className={styles.select_brand_radio_button} value={newBrandId}>
                    <Locale stringKey="TEXT__NEW_BRAND" />
                </Radio>
            </Radio.Group>

            <Button disabled={!selectedBrandId} type="primary">
                <NavigationLink to={createCompanyRoutePath + '?' + getQueryParameters()}>
                    <Locale stringKey="TEXT__START_CREATING" />
                </NavigationLink>
            </Button>
        </Page>
    );
}
