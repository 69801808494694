import {Button} from 'antd';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useCatalogsByAccount, useCatalogsByGroup} from '../../../../../service/source-settings/catalogs/catalogs-hook';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {AccountsTable} from '../source-links-page/source-content/accounts-table/accounts-table';

import * as styles from './source-accounts-page.scss';

export function SourceAccountsPage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {id, groupId} = useParams<
        ExtractRouteParams<typeof appRoute.settingsSourceSettingsAccount.path, string>
    >() || {
        id: '',
        groupId: '',
    };
    const {goBack} = useUrl();

    const {result: catalogs, isInProgress, processError} = useCatalogsByGroup(Number(groupId));
    const {
        result: accountCatalogs,
        isInProgress: isAccountProgress,
        processError: accountProcessError,
    } = useCatalogsByAccount(Number(id));

    const activeCatalog =
        catalogs
            ?.find((group) => String(group.id) === groupId)
            ?.catalogs.find((catalog) => catalog.id === Number(id)) ||
        accountCatalogs
            ?.find((group) => String(group.id) === groupId)
            ?.catalogs.find((catalog) => catalog.id === Number(id));

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta
                title={getLocalizedString('SOURCE_SETTINGS__ACCOUNTS__PAGE_TITLE', {name: activeCatalog?.name || ''})}
            />

            <PageCard>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.settingsSourceSettings.path,
                            titleLangKey: 'CATEGORY_NAME__SOURCE_SETTINGS',
                        },
                        {
                            path: appRoute.settingsSourceSettings.path,
                            title: activeCatalog?.name,
                        },
                    ]}
                />

                <PageHeader className={styles.SourceCatalogPage_header}>
                    <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__PAGE_TITLE" valueMap={{name: activeCatalog?.name}} />

                    <Button className={styles.SourceCatalogPage_button} onClick={goBack}>
                        <Locale stringKey="BUTTON__BACK" />
                    </Button>
                </PageHeader>
            </PageCard>

            <PageCard>
                <Spinner isShow={isInProgress || isAccountProgress} position="absolute" />

                {(processError || accountProcessError) && <AlertFallback />}

                {activeCatalog && <AccountsTable catalog={activeCatalog} key={activeCatalog.id} />}
            </PageCard>
        </Page>
    );
}
