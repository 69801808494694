import {DefinedUseQueryResult, useMutation, UseMutationResult, useQuery, useQueryClient} from '@tanstack/react-query';
import {useMemo} from 'react';
import {z as r} from 'zod';

import {useLocale} from '../../provider/locale/locale-hook';
import {fetchAndDeserialize, postAndDeserialize} from '../../util/api-adapter';
import {sortCompare} from '../../util/string';

import {getLocalizedTagName} from './phototool-tags-helper';

const photoTagTranslationSchema = r.object({
    languageCode: r.string(),
    translation: r.string(),
});

export const photoTagSchema = r.object({
    id: r.number(),
    name: r.string(),
    translations: r.array(photoTagTranslationSchema),
});

export type PhotoTagType = r.infer<typeof photoTagSchema>;

const photoTagsSchema = r.array(photoTagSchema);

type PhotoTagOptionsType = {
    name: string;
};

const photoTagsUrl = `/phototool/v1/tags/`;
const videoTagsUrl = `/phototool/v1/video_tags/`;

async function fetchMediaTags(url: string) {
    return fetchAndDeserialize<Array<PhotoTagType>>(url, photoTagsSchema);
}

async function createMediaTag(options: PhotoTagOptionsType, isVideoMode?: boolean) {
    return postAndDeserialize<PhotoTagType>(
        `${isVideoMode ? videoTagsUrl : photoTagsUrl}get_or_create/`,
        photoTagSchema,
        options
    );
}

export function useMediaTags(options: {
    mode?: 'video' | 'photo';
    enabled?: boolean;
}): DefinedUseQueryResult<Array<PhotoTagType>> {
    const {mode = 'photo', enabled = true} = options;

    const {shortLocaleName} = useLocale();

    const url = useMemo(() => {
        return mode === 'video' ? videoTagsUrl : photoTagsUrl;
    }, [mode]);

    return useQuery([url], () => fetchMediaTags(url), {
        initialData: [],
        enabled,
        select: (tags) =>
            tags
                .map((tag) => ({
                    ...tag,
                    name: getLocalizedTagName(shortLocaleName, tag),
                }))
                .sort((first, second) => sortCompare(shortLocaleName, first.name, second.name)),
    });
}

export function useMediaTagMutation(
    isVideoMode?: boolean
): UseMutationResult<PhotoTagType, unknown, PhotoTagOptionsType> {
    const queryClient = useQueryClient();

    return useMutation((options) => createMediaTag(options, isVideoMode), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: [isVideoMode ? videoTagsUrl : photoTagsUrl]}),
    });
}
