import {Form, Radio, RadioChangeEvent, Select, Space} from 'antd';
import {useCallback, useContext, useEffect, useState} from 'react';

import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';
import {classNames} from '../../../../../../util/css';
import {CatalogSelect} from '../../../components/drawer-filter/catalog-select/catalog-select';
import {DrawerFilter} from '../../../components/drawer-filter/drawer-filter';
import {UtcRangePicker} from '../../../components/drawer-filter/utc-range-picker/utc-range-picker';
import {CustomRangeType} from '../../../components/drawer-filter/utc-range-picker/utc-range-picker-type';
import {getAggregationFromUrl, rangeDisabledDate} from '../../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {ReviewsAnalysisFilterEnum, ReviewsAnalysisFilterType} from '../../../reviews-analysis-type';
import {KeywordsAndTopicsPageContext} from '../keywords-and-topics-page-context';

import {PERIOD_OPTIONS} from './keywords-and-topics-const';
import {getAnalyticsRangeByPeriodAndDate, getDateAndPeriodForAnalysis} from './keywords-and-topics-form-helper';
import {
    KeywordsAndTopicsFormType,
    KeywordsAndTopicsFormTypeAggregationEnum,
    KeywordsAndTopicsFormTypePeriodEnum,
} from './keywords-and-topics-type';
import * as styles from './keywords-and-topics-form.scss';

type PropsType = {
    withAggregation?: boolean;
    activeTab?: string;
};

export function KeywordsAndTopicsForm(props: PropsType): JSX.Element {
    const {withAggregation, activeTab} = props;

    const [form] = Form.useForm<KeywordsAndTopicsFormType>();
    const {filter, setFilter} = useReviewsAnalysisFilter();
    const {getLocalizedString} = useLocale();

    const {isFormInProgress} = useContext(KeywordsAndTopicsPageContext);

    const [periodOption, setPeriodOption] = useState(
        filter[ReviewsAnalysisFilterEnum.Range][0]
            ? KeywordsAndTopicsFormTypePeriodEnum.Custom
            : KeywordsAndTopicsFormTypePeriodEnum.Month
    );

    const [aggregationOption, setAggregationOption] = useState(
        getAggregationFromUrl(filter[ReviewsAnalysisFilterEnum.Aggregation]) ||
            KeywordsAndTopicsFormTypeAggregationEnum.Day
    );

    useEffect(() => {
        if (!filter[ReviewsAnalysisFilterEnum.Range][0]) {
            const defaultRange = getAnalyticsRangeByPeriodAndDate(KeywordsAndTopicsFormTypePeriodEnum.Month);

            setFilter({
                [ReviewsAnalysisFilterEnum.Range]: [defaultRange.start, defaultRange.end],
            });
        }
    }, [filter, setFilter]);

    const handlePeriodOptionsChange = useCallback(
        (event: RadioChangeEvent) => {
            const newOption = PERIOD_OPTIONS.find((option) => option.value === event.target.value);

            if (newOption?.value && newOption.value !== KeywordsAndTopicsFormTypePeriodEnum.Custom) {
                setPeriodOption(newOption?.value);

                const newDate = getAnalyticsRangeByPeriodAndDate(newOption.value);

                setFilter({
                    [ReviewsAnalysisFilterEnum.Range]: [newDate.start, newDate.end],
                });

                track(
                    AnalyticsTarget.ReviewsAnalysis.AnalyzePeriod,
                    getDateAndPeriodForAnalysis([newDate.start, newDate.end], newOption.value)
                );
            }
        },
        [setFilter]
    );

    const handleCatalogChange = useCallback(
        (value: Array<number>) => {
            setFilter({
                [ReviewsAnalysisFilterEnum.Catalogs]: value,
            });
        },
        [setFilter]
    );

    const handlePeriodChange = useCallback(
        (value: CustomRangeType) => {
            setFilter({
                [ReviewsAnalysisFilterEnum.Range]: value,
            });

            setPeriodOption((previous) => {
                const newOption = PERIOD_OPTIONS.find(
                    (option) => option.value === KeywordsAndTopicsFormTypePeriodEnum.Custom
                );

                const newPeriod = newOption ? newOption.value : previous;

                if (value[0] && value[1]) {
                    track(AnalyticsTarget.ReviewsAnalysis.AnalyzePeriod, getDateAndPeriodForAnalysis(value, newPeriod));
                }

                return newPeriod;
            });
        },
        [setFilter]
    );

    const handleAggregationChange = useCallback(
        (value: KeywordsAndTopicsFormTypeAggregationEnum) => {
            setFilter({
                [ReviewsAnalysisFilterEnum.Aggregation]: value,
            });
            setAggregationOption(value);
        },
        [setFilter]
    );

    useEffect(() => {
        form.resetFields();
    }, [filter, form]);

    return (
        <Form<KeywordsAndTopicsFormType> form={form} initialValues={filter}>
            <div className={styles.KeywordsAndTopicsForm_filters}>
                <div
                    className={classNames(
                        styles.KeywordsAndTopicsForm_filterRow,
                        styles.KeywordsAndTopicsForm_filterRow__spaceBetween
                    )}
                >
                    <div className={styles.KeywordsAndTopicsForm_period}>
                        <Form.Item className={styles.KeywordsAndTopicsForm_periodInput}>
                            <Radio.Group
                                disabled={isFormInProgress}
                                onChange={handlePeriodOptionsChange}
                                optionType="button"
                                value={periodOption}
                            >
                                {PERIOD_OPTIONS.map((option) => (
                                    <Radio key={option.value} value={option.value}>
                                        {option.label}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        <AdditionalInfo title={<Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD_INFO_TITLE" />}>
                            <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD_INFO_TEXT" />
                        </AdditionalInfo>

                        {withAggregation && (
                            <Form.Item className={styles.KeywordsAndTopicsForm_periodInput}>
                                <Select
                                    onChange={handleAggregationChange}
                                    optionLabelProp="title"
                                    options={[
                                        {
                                            title: getLocalizedString('REVIEWS_ANALYSIS__FILTER__GROUP_BY__DAY'),
                                            value: KeywordsAndTopicsFormTypeAggregationEnum.Day,
                                            label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__DAY" />,
                                        },
                                        {
                                            title: getLocalizedString('REVIEWS_ANALYSIS__FILTER__GROUP_BY__WEEK'),
                                            value: KeywordsAndTopicsFormTypeAggregationEnum.Week,
                                            label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__WEEK" />,
                                        },
                                        {
                                            title: getLocalizedString('REVIEWS_ANALYSIS__FILTER__GROUP_BY__MONTH'),
                                            value: KeywordsAndTopicsFormTypeAggregationEnum.Month,
                                            label: (
                                                <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__PREVIOUS_FULL_MONTH" />
                                            ),
                                        },
                                    ]}
                                    value={aggregationOption}
                                />
                            </Form.Item>
                        )}
                    </div>

                    <div className={styles.KeywordsAndTopicsForm_drawerButton}>
                        <DrawerFilter
                            isTopicsAllowed={activeTab === 'topics'}
                            isWithTextOnly
                            onSubmit={() => setPeriodOption(KeywordsAndTopicsFormTypePeriodEnum.Custom)}
                        />
                    </div>
                </div>

                <Space className={styles.KeywordsAndTopicsForm_filterRow} wrap>
                    <Form.Item<ReviewsAnalysisFilterType>
                        className={classNames(
                            styles.KeywordsAndTopicsForm_input,
                            styles.KeywordsAndTopicsForm_input__period
                        )}
                        name={ReviewsAnalysisFilterEnum.Range}
                    >
                        <UtcRangePicker
                            defaultMode
                            disabled={isFormInProgress}
                            disabledDate={rangeDisabledDate}
                            onChange={handlePeriodChange}
                        />
                    </Form.Item>
                    <Form.Item<ReviewsAnalysisFilterType>
                        className={styles.KeywordsAndTopicsForm_input}
                        name={ReviewsAnalysisFilterEnum.Catalogs}
                    >
                        <CatalogSelect
                            disabled={isFormInProgress}
                            onChange={handleCatalogChange}
                            placeholder={<Locale stringKey="REVIEWS__FILTER__ALL__SOURCES" />}
                        />
                    </Form.Item>
                </Space>
            </div>
        </Form>
    );
}
