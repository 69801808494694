import {DateAggregationEnum, DatePeriodEnum} from '../../util/date';

export const ANALYTICS_DELAY_DAYS = 5;
export const SEARCH_PHRASES_DELAY_DAYS = 3;

export const initialAggregation = DateAggregationEnum.Day;

export const onlinePresencePhrasesFilterPeriodValues = [
    DatePeriodEnum.PreviousFullMonth,
    DatePeriodEnum.Quarter,
    DatePeriodEnum.Custom,
];
