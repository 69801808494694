import {forwardRef} from 'react';

import {
    OnlinePresenceDashboardDataCommonType,
    OnlinePresenceSourcesType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';
import {ImpressionsDynamic} from '../impressions-dynamic/impressions-dynamic';
import {PrimaryIndicators} from '../primary-indicators/primary-indicators';
import {SourceOfTransition} from '../sources-of-transition/source-of-transition';
import {TargetActionsDynamics} from '../target-actions-dynamics/target-actions-dynamics';

import {OnlinePresenceFilterPdf} from './filter/online-presence-filter-pdf';
import * as styles from './online-presence-pdf-content.scss';

type PropsType = {
    result: OnlinePresenceDashboardDataCommonType & {
        sources?: OnlinePresenceSourcesType;
    };
    filter: OnlinePresenceV2FilterStateType;
    isCompareMode: boolean;
    catalogName?: JSX.Element;
};

export const OnlinePresenceDashboardPdfContent = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const {result, isCompareMode, filter, catalogName} = props;

    return (
        <div className={styles.PdfContainer} ref={ref}>
            <OnlinePresenceFilterPdf
                comparePeriod={[
                    filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart],
                    filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd],
                ]}
                mainPeriod={[
                    filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart],
                    filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd],
                ]}
            />

            <PrimaryIndicators data={result.base} isCompareMode={isCompareMode} />

            <ImpressionsDynamic
                comparePeriodData={result.impressionsDynamic.compared}
                comparedLabels={result.comparedLabels}
                isCompareMode={isCompareMode}
                labels={result.labels}
                mainPeriodData={result.impressionsDynamic.current}
            />

            {catalogName && result.sources && (
                <SourceOfTransition
                    catalogName={catalogName}
                    data={result.sources}
                    filter={filter}
                    isCompareMode={isCompareMode}
                />
            )}

            <TargetActionsDynamics
                comparedLabels={result.comparedLabels}
                data={result.actionsDynamic}
                isCompareMode={isCompareMode}
                labels={result.labels}
            />
        </div>
    );
});

OnlinePresenceDashboardPdfContent.displayName = 'OnlinePresenceDashboardPdfContent';
