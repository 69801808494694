import {ProtocolEnum} from './url-input-type';

export function extractProtocol(
    initialWebsite: string,
    currentProtocol?: ProtocolEnum
): {value: string; protocol: ProtocolEnum} {
    const website = initialWebsite || '';

    if (website.startsWith(ProtocolEnum.HTTP)) {
        return {value: website.replace(ProtocolEnum.HTTP, ''), protocol: ProtocolEnum.HTTP};
    }

    if (website.startsWith(ProtocolEnum.HTTPS) || !currentProtocol) {
        return {value: website.replace(ProtocolEnum.HTTPS, ''), protocol: ProtocolEnum.HTTPS};
    }

    return {value: website, protocol: currentProtocol};
}
