import {useMutation} from '@tanstack/react-query';
import {Button, Modal} from 'antd';
import {isString} from 'lodash';
import {useContext, useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {postGoogleResolveCandidate} from '../../../../../../../service/google/google-api';
import {
    GoogleCclStatusEnum,
    GoogleCompanyWithCandidatesType,
    GoogleLocationSourceEnum,
} from '../../../../../../../service/google/google-type';
import {GoogleAccountSyncContext} from '../../../google-account-sync-context';
import {NEW_LOCATION_OPTION} from '../processing-step-const';

import {getGoogleResolveCandidateErrors} from './processing-step-location-modal-helper';
import {ProcessingStepRightsModal} from './rights-modal/processing-step-rights-modal';
import {LocationPopupSidebar} from './sidebar/location-popup-sidebar';
import {LocationModalTabs} from './tabs/location-modal-tabs';
import * as styles from './processing-step-location-modal.scss';

type PropsType = {
    company: GoogleCompanyWithCandidatesType;
    open: boolean;
    locationId?: string;
    onCancel: () => void;
    onSkip: () => void;
    onOk: (locationId: string, status: GoogleCclStatusEnum) => void;
};

export function ProcessingStepLocationModal(props: PropsType): JSX.Element | null {
    const {company, open, locationId, onCancel, onSkip, onOk} = props;

    const {accountId} = useContext(GoogleAccountSyncContext);
    const [selectedLocationId, setSelectedLocationId] = useState<string | null>(locationId ?? null);
    const [rightsOpen, setRightsOpen] = useState(false);

    const {snackbar} = useSnackbar();

    const isNewLocation = selectedLocationId === NEW_LOCATION_OPTION;
    const selectedLocation =
        company.candidates?.data?.find(({originId}) => originId === selectedLocationId) ??
        (isNewLocation ? NEW_LOCATION_OPTION : null);

    function getLocationStatus() {
        if (rightsOpen && !isString(selectedLocation) && selectedLocation?.adminRightsUrl) {
            return GoogleCclStatusEnum.AwaitingRights;
        }

        if (!rightsOpen && !isString(selectedLocation) && !selectedLocation?.isVerified) {
            return GoogleCclStatusEnum.AwaitingConfirmation;
        }

        return GoogleCclStatusEnum.ReadyToSend;
    }

    const {mutate} = useMutation({
        mutationFn: () =>
            postGoogleResolveCandidate({
                source: company.candidates?.source ?? GoogleLocationSourceEnum.Maps,
                originId: isNewLocation ? null : selectedLocationId,
                companyId: company.id,
                tokenId: isNewLocation ? accountId : null,
            }),
        onSettled: (_data, error) => {
            const errors = getGoogleResolveCandidateErrors(error);

            if (selectedLocationId && errors.length === 0) {
                onOk(selectedLocationId, getLocationStatus());
            }
        },
        onError: (error) => {
            const errors = getGoogleResolveCandidateErrors(error);

            if (errors.length > 0) {
                snackbar.error({
                    message: (
                        <>
                            <Text>{company.name} </Text>
                            <Text secondary small>
                                {company.code}
                            </Text>
                        </>
                    ),
                    description: errors.map((message) => <div key={message}>{message}</div>),
                    duration: null,
                });
            }
        },
    });

    async function handleOk() {
        if (!selectedLocationId) {
            return;
        }

        if (!isString(selectedLocation) && selectedLocation?.adminRightsUrl) {
            setRightsOpen(true);
            return;
        }

        await mutate();
    }

    async function handleRightsOk() {
        if (!selectedLocationId) {
            return;
        }

        await mutate();

        setRightsOpen(false);
    }

    return (
        <>
            {!isString(selectedLocation) && selectedLocation && (
                <ProcessingStepRightsModal
                    adminRightsUrl={selectedLocation?.adminRightsUrl}
                    footer={<Text lighter stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__NOT_SURE" />}
                    location={selectedLocation}
                    onCancel={() => setRightsOpen(false)}
                    onOk={handleRightsOk}
                    open={rightsOpen}
                />
            )}

            <Modal
                destroyOnClose
                footer={
                    <footer className={styles.Modal_footer}>
                        <Button onClick={onSkip}>
                            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__SKIP" />
                        </Button>

                        <div>
                            <Button onClick={onCancel}>
                                <Locale stringKey="BUTTON__CANCEL" />
                            </Button>

                            <Button disabled={selectedLocationId === null} onClick={() => handleOk()} type="primary">
                                <Locale
                                    stringKey={
                                        !isString(selectedLocation) && selectedLocation?.adminRightsUrl
                                            ? 'TEXT__NEXT'
                                            : 'BUTTON__SELECT'
                                    }
                                />
                            </Button>
                        </div>
                    </footer>
                }
                onCancel={onCancel}
                open={open}
                title={<Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__TITLE" />}
                width={968}
            >
                <div className={styles.Modal}>
                    <LocationPopupSidebar company={company} selectedLocation={selectedLocation} />

                    <LocationModalTabs
                        locations={company.candidates}
                        selectedLocationId={selectedLocationId}
                        setSelectedLocationId={setSelectedLocationId}
                    />
                </div>
            </Modal>
        </>
    );
}
