import {faInfoCircle, faPlus, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Divider, Form} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useModal} from '../../../../provider/modal/modal-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {BulkEditFieldNameEnum, BulkUpdateCompaniesFieldsErrorsType} from '../../../../service/company/company-type';
import {replaceInList} from '../../../../util/array';
import {getPlural} from '../../../../util/plural';
import {UnionToIntersectionType} from '../../../../util/type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {validateBulkEditCompaniesFields} from '../bulk-edit-companies-helper';
import {BulkEditFieldType, BulkUpdateCompaniesErrorsType} from '../bulk-edit-companies-type';
import {getConfirmCancelModalConfig} from '../confirm-fields-modal/confirm-cancel-modal';
import {getConfirmFieldsModalConfig} from '../confirm-fields-modal/confirm-fields-modal';
import {FieldModeTag} from '../field-mode-tag/field-mode-tag';

import {COMPONENT_FIELD_BY_ITS_NAME, getDefaultBulkFieldsValues} from './fields-list-const';
import {extractErrorMessage, hasErrors, hasNonEmptyValue} from './fields-list-helper';
import {BulkEditFieldsFormType, BulkEditFieldsFormValuesType} from './fields-list-type';
import * as styles from './fields-list.scss';

type PropsType = {
    fields: Array<BulkEditFieldType>;
    companiesCount: number;
    onChangeFields: (fields: Array<BulkEditFieldType>) => void;
    setIsModalOpen: (open: boolean) => void;
    onSubmit: (fields: BulkEditFieldsFormType) => void;
    isInProgress: boolean;
    serverError: BulkUpdateCompaniesFieldsErrorsType | null;
};

export function FieldsList(props: PropsType): JSX.Element {
    const {fields, onChangeFields, setIsModalOpen, onSubmit, companiesCount, isInProgress, serverError} = props;
    const {getLocalizedString, localeName} = useLocale();
    const {modal} = useModal();
    const {pushUrl} = useUrl();
    const [fieldsValues, setFieldsValues] = useState<BulkEditFieldsFormType>(() => getDefaultBulkFieldsValues());
    const [fieldsErrors, setFieldsErrors] = useState<Partial<BulkUpdateCompaniesErrorsType>>({});

    const selectedCompaniesText: JSX.Element = getPlural<JSX.Element>({
        count: companiesCount,
        localeName,
        singular: <Locale stringKey="BULK_EDIT_COMPANIES__SELECTED__SINGULAR" valueMap={{count: companiesCount}} />,
        pluralFew: <Locale stringKey="BULK_EDIT_COMPANIES__SELECTED__FEW" valueMap={{count: companiesCount}} />,
        pluralMany: <Locale stringKey="BULK_EDIT_COMPANIES__SELECTED__MANY" valueMap={{count: companiesCount}} />,
    });

    const updateFieldError = useCallback(
        (field: BulkEditFieldNameEnum, fieldValue: BulkEditFieldsFormValuesType) => {
            // pick only changed field to not validate all untouched fields
            const changedFields = fields.filter((field_) => field_.name === field);

            const newErrors = validateBulkEditCompaniesFields(
                {...fieldsValues, [field]: fieldValue},
                changedFields,
                getLocalizedString
            );

            setFieldsErrors({...fieldsErrors, ...newErrors});
        },
        [fields, fieldsErrors, fieldsValues, getLocalizedString]
    );

    const handleFieldValueChange = useCallback(
        (field: BulkEditFieldNameEnum) => (newValue: BulkEditFieldsFormValuesType) => {
            setFieldsValues({...fieldsValues, [field]: newValue});

            updateFieldError(field, newValue);
        },
        [fieldsValues, updateFieldError]
    );

    const handleSubmit = useCallback(() => {
        onSubmit(fieldsValues);
    }, [onSubmit, fieldsValues]);

    const handleOpenConfirmModal = useCallback(() => {
        const newErrors = validateBulkEditCompaniesFields(fieldsValues, fields, getLocalizedString);

        setFieldsErrors(newErrors);

        if (hasErrors(newErrors)) {
            return;
        }

        track(
            AnalyticsTarget.MyCompanies.EditSelected,
            fields.map((field) => field.name)
        );
        modal.confirm(getConfirmFieldsModalConfig({fields, companiesCount, onOk: handleSubmit}));
    }, [fieldsValues, fields, modal, companiesCount, handleSubmit, getLocalizedString]);

    function handleOpenCancelConfirmModal() {
        const newErrors = validateBulkEditCompaniesFields(fieldsValues, fields, getLocalizedString);

        if (!hasNonEmptyValue(newErrors)) {
            return;
        }

        modal.confirm(getConfirmCancelModalConfig({onOk: () => pushUrl(appRoute.myCompanies.path)}));
    }

    function handleOpenModal(): void {
        setIsModalOpen(true);
    }

    const handleRemoveField = useCallback(
        (fieldToRemove: BulkEditFieldType): void => {
            onChangeFields(fields.filter((field) => field.name !== fieldToRemove.name));
        },
        [fields, onChangeFields]
    );

    const handleChangeField = useCallback(
        (newField: BulkEditFieldType, index: number): void => {
            const oldField = fields[index];

            if (oldField) {
                onChangeFields(replaceInList(fields, oldField, newField));
            }
        },
        [fields, onChangeFields]
    );

    const renderField = useCallback(
        (field: BulkEditFieldType, index: number) => {
            const FormItemComponent = COMPONENT_FIELD_BY_ITS_NAME[field.name] as UnionToIntersectionType<
                (typeof COMPONENT_FIELD_BY_ITS_NAME)[typeof field.name]
            >;
            const localeErrorMessage = fieldsErrors[field.name] as UnionToIntersectionType<
                NonNullable<(typeof fieldsErrors)[typeof field.name]>
            >;
            const serverErrorMessage =
                serverError !== null ? extractErrorMessage(serverError.field_set[field.name]) : null;
            const value = fieldsValues[field.name] as UnionToIntersectionType<
                NonNullable<Exclude<(typeof fieldsValues)[typeof field.name], boolean>> // exclude boolean because of this https://stackoverflow.com/a/50375286
            >;

            return (
                <li aria-label={field.name} className={styles.field_wrapper__container} key={field.name}>
                    <header className={styles.field_wrapper__header}>
                        <h3 className={styles.field_wrapper__header_text}>
                            <Locale stringKey={field.label} />
                        </h3>

                        <span className={styles.field_wrapper__dot_separator}>•</span>

                        <FieldModeTag
                            isChangable={field.isAddingAvailable}
                            onChange={(mode) => handleChangeField({...field, mode}, index)}
                            value={field.mode}
                        />

                        <Button
                            className={styles.field_wrapper__bucket_icon}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={() => handleRemoveField(field)}
                            size="small"
                            type="link"
                        />
                    </header>

                    <div className={styles.field_wrapper__body}>
                        <FormItemComponent
                            commonErrorMessage={localeErrorMessage}
                            errorMessage={serverErrorMessage}
                            mode={field.mode}
                            onChange={handleFieldValueChange(field.name)}
                            value={value}
                        />
                    </div>
                </li>
            );
        },
        [fieldsErrors, fieldsValues, handleChangeField, handleFieldValueChange, handleRemoveField, serverError]
    );

    const renderedFields = useMemo(() => {
        return fields.map(renderField);
    }, [fields, renderField]);

    return (
        <Form className={styles.form__container} layout="vertical">
            <ul className={styles.fields_list__container}>{renderedFields}</ul>

            <Divider>
                <Button ghost icon={<FontAwesomeIcon icon={faPlus} />} onClick={handleOpenModal} type="primary">
                    {getLocalizedString('BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADD_FIELD_BUTTON')}
                </Button>
            </Divider>

            <footer className={styles.fields_list__footer}>
                <Button
                    className={styles.fields_list__footer__button}
                    htmlType="submit"
                    loading={isInProgress}
                    onClick={handleOpenConfirmModal}
                    type="primary"
                >
                    <Locale stringKey="BULK_EDIT_COMPANIES__BUTTON__UPDATE" />
                </Button>
                <Button
                    className={styles.fields_list__footer__button}
                    onClick={handleOpenCancelConfirmModal}
                    type="default"
                >
                    <Locale stringKey="BULK_EDIT_COMPANIES__BUTTON__CANCEL" />
                </Button>
                <span className={styles.fields_list__selected_companies_info}>
                    <FontAwesomeIcon
                        className={styles.fields_list__selected_companies_info__icon}
                        icon={faInfoCircle}
                    />

                    {selectedCompaniesText}
                </span>
            </footer>
        </Form>
    );
}
