import {captureException} from '@sentry/react';
import {isArray} from 'lodash';
import {ReactElement} from 'react';

import {getFlatObjectArray, isRecord} from './object';

export class NeverError extends Error {
    // if it comes to calling a constructor with a parameter, typescript throws an error
    constructor(value: never) {
        super(`Unreachable statement: ${value}`);
    }
}

export class HttpError extends Error {
    name = 'HttpError';
    statusCode: number;

    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}

export class ApiError extends HttpError {
    name = 'ApiError';
    jsonData: unknown;

    constructor(message: string, statusCode: number, jsonData: unknown) {
        super(message, statusCode);
        this.jsonData = jsonData;
    }
}

export function logError(error: Error): void {
    captureException(error);
}

export function isAbortError(error: unknown): boolean {
    return error instanceof Error && error.name === 'AbortError';
}

export function getErrorMessages(error: unknown): Array<string> {
    if (isRecord(error)) {
        return getFlatObjectArray(error).filter(Boolean).map(String);
    }

    if (error) {
        return [String(error)];
    }

    return [];
}

export type FormErrorType = {name: Array<string | number>; errors: Array<string>};

export function getFormErrors(
    node: unknown,
    parentPath: Array<string | number> = []
): Array<{name: Array<string | number>; errors: Array<string>}> {
    const result: Array<{name: Array<string | number>; errors: Array<string>}> = [];

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function getKey(key: string): string | number {
        return Number.isInteger(Number(key)) ? Number(key) : key;
    }

    if (isRecord(node)) {
        Object.entries(node).map(([key, value]) => {
            if (typeof value === 'string') {
                result.push({
                    name: [...parentPath, getKey(key)],
                    errors: [value],
                });
            } else if (Array.isArray(value) && value[0] && typeof value[0] === 'string') {
                result.push({
                    name: [...parentPath, getKey(key)],
                    errors: value,
                });
            } else {
                result.push(...getFormErrors(value, [...parentPath, getKey(key)]));
            }
        });
    }

    return result;
}

export function isErrorHasStatusMessage<ErrorType>(error: unknown): error is ErrorType {
    return isRecord(error) && isRecord(error.jsonData) && Boolean(error.jsonData.status);
}

export function getMessageFromStatusError(error: unknown): string {
    if (isRecord(error) && isRecord(error.jsonData) && typeof error.jsonData.status === 'string') {
        return error.jsonData.status;
    }

    return '';
}

export function getMessageFromReasonError(error: unknown): Array<ReactElement> {
    if (isRecord(error) && isRecord(error.jsonData) && isArray(error.jsonData.reason)) {
        return error.jsonData.reason;
    }

    return [];
}

type ErrorWithStatusDataType = {
    status: string;
};

export type ErrorWithStatusType = {
    jsonData: ErrorWithStatusDataType;
};

export class ErrorWithStatus extends ApiError {
    name = 'ErrorWithStatus';
    jsonData: ErrorWithStatusDataType;

    constructor(message: string, statusCode: number, jsonData: ErrorWithStatusDataType) {
        super(message, statusCode, jsonData);
        const {status = ''} = jsonData;

        this.jsonData = {status};
    }
}
