import {useCallback, useMemo, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Locale} from '../../../provider/locale/locale';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {useCompanyGroup} from '../../../service/company-group/company-group-hook';
import {CompanyGroupFieldsType, PartialCompanyGroupType} from '../../../service/company-group/company-group-type';
import {toTruncatedString} from '../../../util/string';
import {useUrl} from '../../../util/url-hook/url-hook';
import {CompaniesGroupManagement} from '../companies-group-management/companies-group-management';
import {CompanyGroupForm} from '../companies-group-management/company-group-form/company-group-form';
import {COMPANY_GROUP_TITLE_VALIDATION} from '../companies-group-management/company-group-form/company-group-form-const';

export function CompaniesGroupCreate(): JSX.Element {
    const companyGroupHook = useCompanyGroup({page: 1, count: 100});
    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();

    const [fields, setFields] = useState<CompanyGroupFieldsType | null>(null);

    const handleSave = useCallback(async () => {
        if (fields !== null) {
            const companies = fields.companies.map(Number);
            const title = fields.title;

            const newCompanyGroup: PartialCompanyGroupType = {title, companies};

            const result = await companyGroupHook.createCompanyGroup(newCompanyGroup);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="GROUP_OF_COMPANIES__CREATE__ERROR_CREATE" />);
                return;
            }

            snackbar.success({
                message: (
                    <Locale
                        stringKey="GROUP_OF_COMPANIES__CREATE__SUCCESSFULLY_CREATED"
                        valueMap={{title: toTruncatedString(title, 30)}}
                    />
                ),
                description: (
                    <Locale
                        stringKey="GROUP_OF_COMPANIES__CREATE__SUCCESSFULLY_CREATED_DESCRIPTION"
                        valueMap={{title: toTruncatedString(title, 30)}}
                    />
                ),
            });

            track(AnalyticsTarget.Settings.GroupOfCompanies.Create);
            pushUrl(appRoute.settingsCompaniesGroup.path);
        }
    }, [fields, companyGroupHook, snackbar, pushUrl]);

    const isSaveButtonDisabled = useMemo(() => {
        if (fields === null || !fields.title || fields.companies.length === 0) {
            return true;
        }

        return (
            fields.title.length < COMPANY_GROUP_TITLE_VALIDATION.min ||
            fields.title.length > COMPANY_GROUP_TITLE_VALIDATION.max
        );
    }, [fields]);

    const companiesGroup = useMemo(() => companyGroupHook.result?.results || [], [companyGroupHook]);

    const initialCompanyGroup: PartialCompanyGroupType = useMemo(() => ({companies: [], title: ''}), []);

    return (
        <CompaniesGroupManagement
            breadcrubmsList={[
                {
                    path: appRoute.settingsCompaniesGroup.path,
                    titleLangKey: 'CATEGORY_NAME__GROUPS_OF_COMPANIES',
                },
                {path: '', titleLangKey: 'GROUP_OF_COMPANIES__CREATE__GROUP_MANAGEMENT'},
            ]}
            header={<Locale stringKey="GROUP_OF_COMPANIES__CREATE__HEADER" />}
            onSave={handleSave}
            saveButtonProps={{disabled: isSaveButtonDisabled, loading: companyGroupHook.isInProgress}}
        >
            <CompanyGroupForm companiesGroup={companiesGroup} companyGroup={initialCompanyGroup} onChange={setFields} />
        </CompaniesGroupManagement>
    );
}
