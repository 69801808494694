import {Select} from 'antd';

import {AlertFallback} from '../../../../../../../../../component/alert-fallback/alert-fallback';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {useLinkBrands} from '../../../../../../../../../service/source-settings/link-brands/link-brands-hook';
import {LinkBrandType} from '../../../../../../../../../service/source-settings/link-brands/link-brands-type';
import {handleFilterOption} from '../../../../../../../../../util/antd/select-helper';

type PropsType = {
    catalogGroupId: number;
    value?: Array<number>;
    onChange?: (value: Array<number>) => void;
};

export function AccountBrandSelect(props: PropsType): JSX.Element {
    const {value, catalogGroupId, onChange} = props;
    const {result, isInProgress, processError} = useLinkBrands(catalogGroupId);
    const options: Array<LinkBrandType> = result || [];

    return (
        <Select<Array<number>>
            filterOption={handleFilterOption}
            loading={isInProgress}
            mode="multiple"
            // eslint-disable-next-line no-undefined
            notFoundContent={processError ? <AlertFallback /> : undefined}
            onChange={onChange}
            placeholder={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__PLACEHOLDER" />}
            showSearch
            value={value}
        >
            {!processError &&
                options.map(
                    (option: LinkBrandType): JSX.Element => (
                        <Select.Option key={option.id} value={option.id}>
                            {option.name}
                        </Select.Option>
                    )
                )}
        </Select>
    );
}
