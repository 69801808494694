import {Input} from 'antd';
import {isEqual} from 'lodash';
import {useState} from 'react';

import {AntdErrorMessage} from '../../../../../../component/antd-error-message/antd-error-message';
import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {IdNameType} from '../../../../../../service/api/api-type';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyErrorType, CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../typings/antd';

import {buildFullSearchQuery} from './build-query-helper';
import {CoordinateModeSwitch} from './coordinate-mode-switch/coordinate-mode-switch';
import {LatLngInput} from './lat-lng-input/lat-lng-input';
import {MapModal} from './map-modal/map-modal';
import {SelectCity} from './select-city';
import {SelectCountry} from './select-country/select-country';
import {SelectRegion} from './select-region';
import {SelectText} from './select-text';
import * as styles from './address-widget.scss';

const fieldsWithCatalogs = [
    CompanyKeyEnum.ExtraNames,
    CompanyKeyEnum.CatalogCategories,
    CompanyKeyEnum.ExtraPhones,
    CompanyKeyEnum.ExtraEmails,
    CompanyKeyEnum.ExtraWorkingHours,
    CompanyKeyEnum.ExtraDescriptions,
    CompanyKeyEnum.ExtraGallery,
];

type PropsType = {
    errorMessage: CompanyErrorType | null;
};

export function AddressWidget(props: PropsType): JSX.Element {
    const {errorMessage} = props;

    const {getLocalizedString} = useLocale();
    const [showLatLng, setShowLatLng] = useState<boolean>(false);
    const form = Form.useFormInstance<CompanyFormType>();
    const {requiredFieldRule} = useFormRules();

    function handleCountryChange() {
        form.setFieldsValue({
            address: {
                region: null,
                city: null,
                postalCode: null,
                street: null,
                houseNumber: null,
                latLng: null,
            },
        });
    }

    function handleRegionChange() {
        form.setFieldsValue({
            address: {
                city: null,
                postalCode: null,
                street: null,
                houseNumber: null,
                latLng: null,
            },
        });
    }

    function handleCityChange(newCity?: IdNameType | string | null) {
        const city = typeof newCity === 'string' ? newCity : newCity?.name;

        if (city) {
            return;
        }

        form.setFieldsValue({
            address: {
                postalCode: null,
                street: null,
                houseNumber: null,
                latLng: null,
            },
        });
    }

    return (
        <>
            <div className={styles.address_widget}>
                <div className={styles.address_widget__location}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(previous: CompanyFormType, current: CompanyFormType) => {
                            return fieldsWithCatalogs.some((field) => !isEqual(previous[field], current[field]));
                        }}
                    >
                        {({getFieldsValue}) => (
                            <Form.Item name={[CompanyKeyEnum.Address, 'country']} rules={[requiredFieldRule]}>
                                <SelectCountry
                                    onAfterChange={handleCountryChange}
                                    usedCatalogs={Object.values(
                                        getFieldsValue(fieldsWithCatalogs) as Record<
                                            string,
                                            Array<{catalogId: number; edited?: boolean}>
                                        >
                                    ).flatMap((catalogs) =>
                                        catalogs?.length
                                            ? catalogs
                                                  .filter((catalog) => catalog && catalog.edited !== false)
                                                  .map(({catalogId}) => catalogId)
                                            : []
                                    )}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>

                    <Form.Item<CompanyFormType>
                        dependencies={[
                            [CompanyKeyEnum.Address, 'country'],
                            [CompanyKeyEnum.Address, 'region'],
                            [CompanyKeyEnum.Address, 'city'],
                            [CompanyKeyEnum.Address, 'street'],
                        ]}
                        noStyle
                    >
                        {({getFieldValue}) => {
                            const country = getFieldValue([CompanyKeyEnum.Address, 'country']);
                            const region = getFieldValue([CompanyKeyEnum.Address, 'region']);
                            const city = getFieldValue([CompanyKeyEnum.Address, 'city']);
                            const street = getFieldValue([CompanyKeyEnum.Address, 'street']);

                            return (
                                <>
                                    <Form.Item name={[CompanyKeyEnum.Address, 'region']} rules={[requiredFieldRule]}>
                                        <SelectRegion country={country} onAfterChange={handleRegionChange} />
                                    </Form.Item>

                                    <Form.Item name={[CompanyKeyEnum.Address, 'city']}>
                                        <SelectCity
                                            country={country}
                                            onAfterChange={handleCityChange}
                                            region={region}
                                        />
                                    </Form.Item>

                                    <Form.Item name={[CompanyKeyEnum.Address, 'postalCode']}>
                                        <SelectText
                                            addressKey="postal_code"
                                            country={country}
                                            isDisabled={!city}
                                            label={<Locale stringKey="COMPANY_FORM__ADDRESS__POSTCODE__LABEL" />}
                                            placeholder={getLocalizedString(
                                                'COMPANY_FORM__ADDRESS__POSTCODE__PLACEHOLDER'
                                            )}
                                            searchQueryList={buildFullSearchQuery(country, region, city)}
                                        />
                                    </Form.Item>

                                    <Form.Item name={[CompanyKeyEnum.Address, 'street']}>
                                        <SelectText
                                            addressKey="street"
                                            country={country}
                                            isDisabled={!city}
                                            label={<Locale stringKey="COMPANY_FORM__ADDRESS__STREET__LABEL" />}
                                            placeholder={getLocalizedString(
                                                'COMPANY_FORM__ADDRESS__STREET__PLACEHOLDER'
                                            )}
                                            searchQueryList={buildFullSearchQuery(country, region, city)}
                                        />
                                    </Form.Item>

                                    <Form.Item name={[CompanyKeyEnum.Address, 'houseNumber']}>
                                        <SelectText
                                            addressKey="house"
                                            country={country}
                                            isDisabled={!city}
                                            label={<Locale stringKey="COMPANY_FORM__ADDRESS__HOUSE_NUMBER__LABEL" />}
                                            placeholder={getLocalizedString(
                                                'COMPANY_FORM__ADDRESS__HOUSE_NUMBER__PLACEHOLDER'
                                            )}
                                            searchQueryList={buildFullSearchQuery(country, region, city, street)}
                                        />
                                    </Form.Item>

                                    {errorMessage && Array.isArray(errorMessage.address) && (
                                        <AntdErrorMessage>{errorMessage.address}</AntdErrorMessage>
                                    )}
                                </>
                            );
                        }}
                    </Form.Item>
                </div>

                <Form.Item
                    label={
                        <AdditionalInfo
                            leftNode={<Locale stringKey="COMPANY_FORM__ADDRESS__COMMENT__LABEL" />}
                            title={<Locale stringKey="COMPANY_FORM__ADDRESS__COMMENT__LABEL" />}
                        >
                            <Locale stringKey="COMPANY_FORM__ADDRESS__COMMENT__HINT" />
                        </AdditionalInfo>
                    }
                    name={[CompanyKeyEnum.Address, 'description']}
                >
                    <Input
                        placeholder={getLocalizedString('COMPANY_FORM__ADDRESS__COMMENT__PLACEHOLDER')}
                        size="large"
                    />
                </Form.Item>

                <CoordinateModeSwitch form={form} setShowLatLng={setShowLatLng} showLatLng={showLatLng} />

                <Form.Item<CompanyFormType>
                    dependencies={[[CompanyKeyEnum.Address, 'region']]}
                    hidden={!showLatLng}
                    noStyle
                >
                    {({getFieldValue}) => (
                        <Form.Item name={[CompanyKeyEnum.Address, 'latLng']}>
                            <LatLngInput disabled={!getFieldValue([CompanyKeyEnum.Address, 'region'])} />
                        </Form.Item>
                    )}
                </Form.Item>
            </div>

            <Form.Item<CompanyFormType>
                noStyle
                shouldUpdate={(previous, current) => previous?.address?.latLng !== current.address.latLng}
            >
                {({getFieldValue, setFieldsValue}) => {
                    const country = getFieldValue([CompanyKeyEnum.Address, 'country']);
                    const region = getFieldValue([CompanyKeyEnum.Address, 'region']);
                    const latLng = getFieldValue([CompanyKeyEnum.Address, 'latLng']);

                    return (
                        <MapModal
                            country={country}
                            latLng={latLng}
                            region={region}
                            setLatLng={(newLatLng: [number, number]) => setFieldsValue({address: {latLng: newLatLng}})}
                        />
                    );
                }}
            </Form.Item>
        </>
    );
}
