import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';

import {CursorPaginationResponseType} from '../../service/api/api-type';
import {useRefreshId} from '../hook';
import {getPageSizeInitialState, savePageSizeState} from '../pagination-hook/pagination-helper';
import {isValidUrl} from '../url';

import {defaultPageSize as DEFAULT_PAGE_SIZE} from './cursor-pagination-const';
import {getPaginationInitialState, saveNewCursorPaginationState} from './cursor-pagination-helper';
import {CursorPaginationHookOptionsType, CursorPaginationHookType} from './cursor-pagination-type';

// eslint-disable-next-line max-statements
export function useCursorPagination(options: CursorPaginationHookOptionsType): CursorPaginationHookType {
    const {defaultPageSize, dependencies, shouldSaveState = true} = options;

    const routerHistory = useHistory<Location>();
    const {location: routerLocation} = routerHistory;
    const {search, pathname} = routerLocation;

    const storageKey = pathname + search;

    const {refresh, refreshId} = useRefreshId();

    const {cursor: initialCursor} = getPaginationInitialState({
        ...options,
        storageKey,
        shouldSaveState,
    });

    const {pageSize: initialPageSize} = getPageSizeInitialState({
        initialPageSize: defaultPageSize,
        storageKey,
        shouldSaveState,
    });

    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const [previousPageCursor, setPreviousPageCursor] = useState<string>('');
    const [nextPageCursor, setNextPageCursor] = useState<string>('');
    const [cursor, setCursor] = useState<string>(initialCursor);
    const [previousPage, setPreviousPage] = useState<number>(0);
    const [nextPage, setNextPage] = useState<number>(1);
    const [page, setPage] = useState<number>(1);
    const [resultPage, setResultPage] = useState<number>(1);

    const isInitializedRef = useRef<boolean>(false);

    const onDataLoaded = useCallback((result: CursorPaginationResponseType<unknown>) => {
        const [cursorPreviousPage, cursorNextPage] = [result.previous, result.next].map(
            (cursorResponse: string | null | void): string => {
                if (!cursorResponse) {
                    return '';
                }

                if (isValidUrl(cursorResponse)) {
                    return new URL(cursorResponse).searchParams.get('cursor') || '';
                }

                return cursorResponse;
            }
        );

        setNextPageCursor(cursorNextPage || '');
        setPreviousPageCursor(cursorPreviousPage || '');

        const [previousPagePagination, nextPagePagination] = [result.previous, result.next].map(
            (pageResponse: string | null | void): number | string => {
                if (!pageResponse) {
                    return 0;
                }

                if (isValidUrl(pageResponse)) {
                    return Number(new URL(pageResponse).searchParams.get('page')) || 0;
                }

                return pageResponse;
            }
        );

        setNextPage(Number(nextPagePagination) ?? 2);
        setPreviousPage(Number(previousPagePagination) ?? 0);
    }, []);

    const updatePageSize = useCallback(
        (newPageSize: number) => {
            setPageSize(newPageSize || defaultPageSize || DEFAULT_PAGE_SIZE);
            setCursor('');
            setPage(1);
            setResultPage(1);
            setNextPage(2);
            setPreviousPage(0);
        },
        [defaultPageSize]
    );

    const onDataLoadFailed = useCallback(() => {
        setCursor('');
        setNextPageCursor('');
        setPreviousPageCursor('');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [cursor, page, pageSize, previousPageCursor, resultPage]);

    useEffect(() => {
        if (isInitializedRef.current) {
            setCursor('');
            setPage(1);
            setResultPage(1);
            setNextPage(2);
            setPreviousPage(0);
            refresh();
        } else {
            isInitializedRef.current = true;
        }
    }, [dependencies, refresh]);

    useLayoutEffect(() => {
        if (shouldSaveState) {
            saveNewCursorPaginationState({
                cursor,
                storageKey,
            });

            savePageSizeState(storageKey, {pageSize});
        }

        if (isInitializedRef.current) {
            refresh();
        }
    }, [storageKey, page, cursor, pageSize, refresh, shouldSaveState]);

    return {
        pageSize,
        previousPageCursor,
        nextPageCursor,
        previousPage,
        resultPage,
        nextPage,
        cursor,
        onDataLoaded,
        onDataLoadFailed,
        updatePageSize,
        changePage: setCursor,
        changePagePagination: setPage,
        changeResultPage: setResultPage,
        page,
        refreshId,
        refresh,
    };
}
