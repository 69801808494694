import {Text} from '../../../../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../../../../provider/locale/locale-hook';
import {PostPreviewDetailsType} from '../../../../../../../../../../../service/posts/posts-types';
import {TimeSizeEnum} from '../../../../../../../../../../../util/format';
import {useFormat} from '../../../../../../../../../../../util/format-hook/format-hook';
import {CompanyAvatar} from '../../../company-avatar/company-avatar';

import * as styles from './preview-header.scss';

type PropsType = {
    companyInfo?: PostPreviewDetailsType;
};

export function PreviewHeader(props: PropsType): JSX.Element {
    const {companyInfo} = props;

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    const currentDate = Date.now();

    const date = getFormattedDateTime(currentDate, {
        [TimeSizeEnum.month]: 'long',
        day: '2-digit',
        year: 'numeric',
    });

    const time = getFormattedDateTime(currentDate, {
        minute: '2-digit',
        hour: '2-digit',
    });

    return (
        <div className={styles.PreviewHeader}>
            <CompanyAvatar className={styles.PreviewHeader_avatar} logo={companyInfo?.logo} />
            <div className={styles.PreviewHeader_text}>
                <Text bold className={styles.PreviewHeader_name} ellipsis>
                    {companyInfo?.name || getLocalizedString('POSTS_FORM__PREVIEW__YANDEX__COMPANY_NAME')}
                </Text>
                <Text className={styles.PreviewHeader_date}>
                    {date}, {time}
                </Text>
            </div>
        </div>
    );
}
