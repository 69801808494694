import {faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space} from 'antd';

import {Text} from '../../../../../../../../../../component/text/text';
import {classNames} from '../../../../../../../../../../util/css';

import * as styles from './new-location.scss';

type PropsType = {
    markerClassName?: string;
};

export function NewLocation(props: PropsType): JSX.Element {
    const {markerClassName} = props;

    return (
        <div className={styles.NewLocation}>
            <div>
                <Space direction="vertical" size={2}>
                    <Text stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__NEW__TITLE" />

                    <Text secondary stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__NEW__DESCRIPTION" />
                </Space>
            </div>

            <FontAwesomeIcon className={classNames(styles.NewLocation_marker, markerClassName)} icon={faMapMarkerAlt} />
        </div>
    );
}
