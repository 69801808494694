import {DefaultOptionType} from 'antd/lib/select';

import {ReviewResponsibleUserErrorType} from '../../../../../../../service/reviews/reviews-responsible-users';
import {isRecord} from '../../../../../../../util/object';
import {includesDeburredString} from '../../../../../../../util/string';

import {UsersListItemType} from './responsible-user';

export function isReviewResponsibleUserError(error: unknown): error is ReviewResponsibleUserErrorType {
    return isRecord(error) && isRecord(error.jsonData) && Boolean(error.jsonData.status);
}

export function handleFilterOption(input: string, list: Array<UsersListItemType>, option?: DefaultOptionType): boolean {
    if (!option) {
        return false;
    }

    if ('value' in option && typeof option?.value === 'string') {
        const user = list.find((item: UsersListItemType) => item.key === option.value);
        const nameAndEmail = user ? `${user.label}${user.email}` : '';

        return includesDeburredString(nameAndEmail, input);
    }

    return false;
}
