import {AttributeGetConfigType} from '../../../page/main/company-form/page-container/widgets/attributes/attributes-type';
import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';

import {
    doubleGisAttributesSchema,
    DoubleGisAttributeType,
    googleAttributesSchema,
    GoogleAttributeType,
    yandexAttributesSchema,
} from './attributes-type';

export async function fetchGoogleAttributes(
    categoryId: string,
    countryId: string
): Promise<Array<GoogleAttributeType>> {
    return fetchAndDeserialize(
        `/cp/attributes/google/?${objectToUrlParameters({
            gmb_category_id: categoryId,
            country_code: countryId,
        })}`,
        googleAttributesSchema
    );
}

export async function fetchDoubleGisAttributes(categoriesIds: Array<string>): Promise<Array<DoubleGisAttributeType>> {
    return fetchAndDeserialize(
        `/cp/attributes/double_gis/?${objectToUrlParameters({
            origin_ids: categoriesIds,
        })}`,
        doubleGisAttributesSchema
    );
}

export function fetchYandexAttributes(categoriesIds: Array<string>): Promise<Array<AttributeGetConfigType>> {
    return fetchAndDeserialize(
        `/cp/attributes/yandex/?${objectToUrlParameters({
            origin_ids: categoriesIds,
        })}`,
        yandexAttributesSchema
    );
}
