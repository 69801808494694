import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {ShortCatalogType} from '../../../../../provider/catalogs/catalogs-type';
import {classNames} from '../../../../../util/css';

import * as styles from './post-catalogs.scss';

type PropsType = {
    catalogs: Array<
        ShortCatalogType & {
            isHasPost: boolean;
        }
    >;
};

export function PostCatalogs(props: PropsType): JSX.Element {
    const {catalogs} = props;

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    return (
        <div className={styles.PostCatalogs}>
            {catalogs.map((catalog) => {
                const catalogName = getCatalogName(catalog.id);
                const catalogLogo = getCatalogLogo(catalog.id);

                return (
                    <img
                        alt={catalogName}
                        className={classNames(styles.PostCatalogs_icon, {
                            [styles.PostCatalogs_icon__notPublished]: !catalog.isHasPost,
                        })}
                        key={catalogName}
                        src={catalogLogo}
                    />
                );
            })}
            {catalogs.length === 1 && catalogs[0] ? (
                <p className={styles.PostCatalogs_name}>{getCatalogName(catalogs[0].id)}</p>
            ) : (
                ''
            )}
        </div>
    );
}
