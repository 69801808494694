import {List, Pagination} from 'antd';

import {Text} from '../../../../../../../../../component/text/text';
import {useGoogleRequestCompanies} from '../../../../../../../../../service/google/confirmation/google-confirmation-hook';
import {GoogleConfirmationRequestType} from '../../../../../../../../../service/google/confirmation/google-confirmation-type';
import {CompanyListItem} from '../../../../../common/company-list-item/company-list-item';

import * as styles from './request-companies.scss';

type PropsType = {
    request: GoogleConfirmationRequestType;
};

export function RequestCompanies(props: PropsType): JSX.Element {
    const {request} = props;

    const {
        data: companies,
        pagination: {page: current, pageSize, total, onChange},
    } = useGoogleRequestCompanies(request.pk);

    return (
        <List
            className={styles.RequestCompanies}
            dataSource={companies?.results.map((company) => ({
                ...company,
                id: company.pk,
                address: company.formattedAddress,
            }))}
            header={
                <header className={styles.RequestCompanies_header}>
                    <Text
                        stringKey="GOOGLE_SYNC__CONFIRMATIONS__COMPANIES"
                        valueMap={{count: <Text bolder>{total}</Text>}}
                    />

                    <Pagination
                        current={current}
                        hideOnSinglePage
                        onChange={onChange}
                        pageSize={pageSize}
                        showSizeChanger={false}
                        size="small"
                        total={total}
                    />
                </header>
            }
            renderItem={(company) => (
                <CompanyListItem
                    className={styles.RequestCompanies_companyListItem}
                    company={company}
                    showContacts={false}
                />
            )}
            size="small"
        />
    );
}
