import {useState} from 'react';

import {useLegalOptionDataV2List} from '../../../service/api-hook/api-hook';
import {CompanyFormType} from '../../../service/company-v2/company-type';
import {debounce} from '../../../util/function';

import {initCompanyFieldFill} from './company-form-const';
import {CompanyFieldFillType, UseFillWidgetType} from './company-form-type';
import {createNewFormFields, getCompanyCondition} from './page-container/company-form-helper';

export function useFillWidget(form: CompanyFormType): UseFillWidgetType {
    const [companyFieldFill, setCompanyFieldFill] = useState<CompanyFieldFillType>(initCompanyFieldFill);
    const {result} = useLegalOptionDataV2List();

    const legalOptionDataList =
        result?.find(({alpha2}) => alpha2 === form?.address?.country?.countryCode)?.legal_forms ?? null;
    const isDisableLegal = !legalOptionDataList || legalOptionDataList.length === 0;

    function checkCompanyField(values: Partial<CompanyFormType>) {
        if (Object.keys(form).length === 0) {
            return;
        }

        const newForm: CompanyFormType = createNewFormFields(form, values);

        const companyFieldFillMap = getCompanyCondition(newForm, isDisableLegal);

        setCompanyFieldFill(companyFieldFillMap);
    }

    return {
        companyFieldFill,
        checkCompanyField: debounce(checkCompanyField, 500),
        setCompanyFieldFill,
    };
}
