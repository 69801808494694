import {faChevronLeft, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'antd';

import {Spinner} from '../../../layout/spinner/spinner';
import {useYandexStory} from '../../../service/yandex-stories/yandex-stories-hook';
import {classNames} from '../../../util/css';

import {YandexStoriesCarousel} from './carousel/yandex-stories-carousel';
import {YandexStoryInfo} from './info/yandex-story-info';
import * as styles from './yandex-stories-preview.scss';

type PropsType = {
    activeStoryId: number | null;
    onChangeStoryId: (storyId: number | null) => void;
    nextStoryId: number | null;
    previousStoryId: number | null;
};

export function YandexStoriesPreview(props: PropsType): JSX.Element | null {
    const {activeStoryId, nextStoryId, previousStoryId, onChangeStoryId} = props;

    const {data: activeStory, isFetching} = useYandexStory(activeStoryId, true);

    if (!activeStoryId || !activeStory) {
        return null;
    }

    const sortedStoriesByOrder = activeStory.slides.sort((slideA, slideB) => slideA.order - slideB.order);

    function handleChangeStoryId(): void {
        if (nextStoryId) {
            onChangeStoryId(nextStoryId);
            return;
        }

        if (previousStoryId) {
            onChangeStoryId(previousStoryId);
            return;
        }

        onChangeStoryId(null);
    }

    return (
        <Modal
            centered
            className={styles.YandexStoryPreview_modal}
            closable={false}
            destroyOnClose
            footer={null}
            onCancel={() => onChangeStoryId(null)}
            open
            width={880}
        >
            <div className={styles.YandexStoryPreview}>
                {previousStoryId ? (
                    <Button
                        className={styles.YandexStoryPreview_navigationButton}
                        icon={<FontAwesomeIcon icon={faChevronLeft} />}
                        onClick={() => onChangeStoryId(previousStoryId)}
                    />
                ) : (
                    <div />
                )}
                <div className={styles.YandexStoryPreview_previewContent}>
                    <div className={styles.YandexStoryPreview_previewColumn}>
                        {!isFetching && activeStory ? (
                            <YandexStoriesCarousel slides={sortedStoriesByOrder} storyName={activeStory.name} />
                        ) : (
                            <Spinner
                                className={classNames(
                                    styles.YandexStoryPreview_spinner,
                                    styles.YandexStoryPreview_spinner__dark
                                )}
                            />
                        )}
                    </div>
                    <div className={styles.YandexStoryPreview_previewColumn}>
                        {!isFetching && activeStory ? (
                            <YandexStoryInfo
                                onClose={() => onChangeStoryId(null)}
                                onDelete={handleChangeStoryId}
                                story={activeStory}
                            />
                        ) : (
                            <Spinner className={styles.YandexStoryPreview_spinner} />
                        )}
                    </div>
                </div>
                {nextStoryId ? (
                    <Button
                        className={styles.YandexStoryPreview_navigationButton}
                        icon={<FontAwesomeIcon icon={faChevronRight} />}
                        onClick={() => onChangeStoryId(nextStoryId)}
                    />
                ) : (
                    <div />
                )}
            </div>
        </Modal>
    );
}
