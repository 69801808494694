import {GoogleCclStatusEnum} from '../../../../../../../service/google/google-type';
import * as stepStyles from '../finish-step.scss';

export const STATISTIC_BADGE_OVERFLOW = 999;

export const badgeForStatus: Record<string, string> = {
    [GoogleCclStatusEnum.Synchronize]: stepStyles.Badge__green,
    [GoogleCclStatusEnum.Send]: stepStyles.Badge__gray,
    [GoogleCclStatusEnum.AwaitingRights]: stepStyles.Badge__purple,
};
