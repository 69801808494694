import {useRef} from 'react';

import {useDomElementRect} from '../../../util/dom-hook/dom-hook';
import {LANDING_BLOCK_MOBILE_WIDTH_THRESHOLD} from '../landing-helper';

import {LandingCardDesktop} from './landing-card-desktop/landing-card-desktop';
import {LandingCardMobile} from './landing-card-mobile/landing-card-mobile';
import {LandingCardPropsType} from './landing-card-type';

export function LandingCard(props: LandingCardPropsType): JSX.Element {
    const landingCardWrapperRef = useRef<HTMLDivElement | null>(null);
    const landingCardWrapperRect = useDomElementRect(landingCardWrapperRef);

    const isMobile = Boolean(
        landingCardWrapperRect && landingCardWrapperRect?.width < LANDING_BLOCK_MOBILE_WIDTH_THRESHOLD
    );

    return (
        <div ref={landingCardWrapperRef}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {isMobile && <LandingCardMobile {...props} />}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {!isMobile && <LandingCardDesktop {...props} />}
        </div>
    );
}
