import {appRoute} from '../../../../app-route';
import {ActionThumb} from '../../../../layout/action-thumb/action-thumb';
import {getActionThumbUiData} from '../../../../layout/action-thumb/action-thumb-helper';
import {ActionThumbStateNameEnum} from '../../../../layout/action-thumb/action-thumb-type';
import {enUs} from '../../../../provider/locale/translation/en-us/data';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useCclStatistic} from '../../../../service/ccl-statistic/ccl-statistic-hook';
import {CclStatusEnum} from '../../../../service/ccl-statistic/ccl-statistic-type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {useSourcesUrlQuery} from '../sources-hook';
import {SourcesSearchParametersEnum} from '../sources-type';

const placeholder = '...';

type PropsType = {
    className?: string;
};

export function SourcesStatistics(props: PropsType): JSX.Element {
    const {className} = props;

    const {sourceIds, companyIds, catalogType, cclStatus} = useSourcesUrlQuery();
    const {setQuery, getQuery} = useUrl();

    const isNewCompanies = getQuery(SourcesSearchParametersEnum.isNewCompanies) === 'true';
    const accessTransferRequired = getQuery('accessTransferRequired') === 'true';

    const {result: cclStatistic} = useCclStatistic(
        catalogType,
        isNewCompanies,
        sourceIds,
        companyIds,
        accessTransferRequired
    );

    function handleFilterClick(newCclStatus: CclStatusEnum, type: ActionThumbStateNameEnum) {
        setQuery({
            [SourcesSearchParametersEnum.CclStatus]: cclStatus === newCclStatus ? '' : newCclStatus,
        });

        track(AnalyticsTarget.Sources.StatusFilter, enUs[getActionThumbUiData(type)?.langKey]);
    }

    function handleConfirmationsClick() {
        track(
            AnalyticsTarget.Sources.StatusFilter,
            enUs[getActionThumbUiData(ActionThumbStateNameEnum.confirmation)?.langKey]
        );
    }

    return (
        <div className={className}>
            <ActionThumb
                isActive={cclStatus === CclStatusEnum.synced}
                onClick={() => handleFilterClick(CclStatusEnum.synced, ActionThumbStateNameEnum.synchronized)}
                type={ActionThumbStateNameEnum.synchronized}
                value={cclStatistic?.syncCount ?? placeholder}
            />

            <ActionThumb
                isActive={cclStatus === CclStatusEnum.sent}
                onClick={() => handleFilterClick(CclStatusEnum.sent, ActionThumbStateNameEnum.sent)}
                type={ActionThumbStateNameEnum.sent}
                value={cclStatistic?.sendedCount ?? placeholder}
            />

            <ActionThumb
                isActive={cclStatus === CclStatusEnum.actionNeeded}
                onClick={() => handleFilterClick(CclStatusEnum.actionNeeded, ActionThumbStateNameEnum.actionRequired)}
                type={ActionThumbStateNameEnum.actionRequired}
                value={cclStatistic?.actionNeededCount ?? placeholder}
            />

            <ActionThumb
                href={appRoute.sourcesConfirmations.path}
                onClick={() => handleConfirmationsClick()}
                type={ActionThumbStateNameEnum.confirmation}
                value={cclStatistic?.confirmationCount ?? placeholder}
            />
        </div>
    );
}
