import {Switch} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {classNames} from '../../../../../../util/css';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import * as styles from './temporary-closed-form-item.scss';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.isTemporaryClosed> & {
    className?: string;
};

export function TemporaryClosedFormItem(props: PropsType): JSX.Element {
    const {value, onChange, className} = props;

    return (
        <div className={classNames(styles.container, className)}>
            <Switch checked={value} onChange={() => onChange(!value)} />

            <p className={styles.label}>
                <Locale stringKey="ADDITIONAL_INFO__THE_POINT_IS_TEMPORARILY_CLOSED" />
            </p>
        </div>
    );
}
