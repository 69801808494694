import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {fetchReviewsCommandRemove} from '../../../../../../../../service/reviews/reviews-command';
import {fetchReviewsReplyRemove} from '../../../../../../../../service/reviews/reviews-reply';
import {ReviewDataChildType, ReviewReplyTypeAuthorEnum} from '../../../../../../../../service/reviews/reviews-type';

type PropsType = {
    answer: ReviewDataChildType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewId: number;
    descriprion?: string;
};

export function DeleteReply(props: PropsType): JSX.Element {
    const {answer, refreshReview, reviewId, descriprion} = props;

    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    function onDeletePress() {
        modal.confirm({
            maskClosable: true,
            title: <Locale stringKey="REVIEWS__DELETE_ANSWER__TITLE" />,
            content: <Locale stringKey="REVIEWS__DELETE_ANSWER__DESCRIPTION" />,
            okText: <Locale stringKey="BUTTON__DELETE" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            cancelButtonProps: {type: 'default'},
            okButtonProps: {type: 'primary', danger: true},
            onOk: async () => {
                try {
                    await (answer.type === ReviewReplyTypeAuthorEnum.command
                        ? fetchReviewsCommandRemove(reviewId, answer.commandId)
                        : fetchReviewsReplyRemove(reviewId, answer.replyId));
                    await refreshReview(reviewId);
                    snackbar.success({
                        key: 'sent-successfully',
                        description: <Locale stringKey="REVIEWS__REVIEW_ANSWER_REMOVE_SUCCESS__TITLE" />,
                        message: <Locale stringKey="REVIEWS__REVIEW_ANSWER_REMOVE_SUCCESS__DESCRIPTION" />,
                    });
                } catch {
                    snackbar.error({
                        description: <Locale stringKey="REVIEWS__REVIEW_ANSWER_REMOVE_FAIL__TITLE" />,
                        message: <Locale stringKey="REVIEWS__REVIEW_ANSWER_REMOVE_FAIL__DESCRIPTION" />,
                    });
                }
            },
        });
    }

    return (
        <Button icon={<FontAwesomeIcon icon={faTrash} />} onClick={onDeletePress} size="small" type="link">
            {descriprion}
        </Button>
    );
}
