import {InputNumber, InputNumberProps, Select, Space} from 'antd';
import {omit} from 'lodash';
import {useCallback, useState} from 'react';

import {Form} from '../../../../../../../typings/antd';
import {classNames} from '../../../../../../../util/css';
import * as inUnitsAttributeStyles from '../attribute-in-units/in-units-attribute.scss';
import {
    AttributePropsType,
    AttributeValueType,
    RangeInUnitsSingleAttributeType,
    RangeInUnitsSingleAttributeValueType,
} from '../attributes-type';
import {RangePicker} from '../range-picker/range-picker';

import * as styles from './range-in-units-attribute.scss';

export function RangeInUnitsSingleAttribute({
    value,
    onChange,
}: AttributePropsType<RangeInUnitsSingleAttributeType>): JSX.Element | null {
    const {displayName: label, values} = value;

    const {status} = Form.Item.useStatus();
    const [selectedValue, setSelectedValue] = useState(
        values.find((item) => item.valueFrom || item.valueTo) || values[0]
    );

    const fromInput = useCallback(
        (className: InputNumberProps['className'], type: InputNumberProps['type']) => {
            if (!selectedValue) {
                return null;
            }

            return (
                <InputNumber
                    className={className}
                    onChange={(newValue) => {
                        if (newValue === null) {
                            return;
                        }

                        onChange?.({
                            ...value,
                            values: values.map((item) =>
                                item.templateId === selectedValue.templateId ? {...item, valueFrom: newValue} : item
                            ),
                        });

                        setSelectedValue({...selectedValue, valueFrom: newValue});
                    }}
                    status={status === 'error' ? status : undefined} // eslint-disable-line no-undefined
                    type={type}
                    value={selectedValue.valueFrom}
                />
            );
        },
        [onChange, selectedValue, status, value, values]
    );

    const toInput = useCallback(
        (className: InputNumberProps['className'], type: InputNumberProps['type']) => {
            if (!selectedValue) {
                return null;
            }

            return (
                <InputNumber
                    className={classNames(className, styles.attribute__range_in_units__input)}
                    onChange={(newValue) => {
                        if (newValue === null) {
                            return;
                        }

                        onChange?.({
                            ...value,
                            values: values.map((item) =>
                                item.templateId === selectedValue.templateId ? {...item, valueTo: newValue} : item
                            ),
                        });

                        setSelectedValue({...selectedValue, valueTo: newValue});
                    }}
                    status={status === 'error' ? status : undefined} // eslint-disable-line no-undefined
                    type={type}
                    value={selectedValue.valueTo}
                />
            );
        },
        [onChange, selectedValue, status, value, values]
    );

    function handleOnSelectChange(option: string | number) {
        let newSelectedValue: AttributeValueType<RangeInUnitsSingleAttributeValueType> | null = null;

        onChange?.({
            ...value,
            values: values.map((item) => {
                if (item.templateId === option) {
                    newSelectedValue = {
                        ...item,
                        selected: true,
                        valueFrom: selectedValue?.valueFrom,
                        valueTo: selectedValue?.valueTo,
                    };

                    return newSelectedValue;
                }

                return item.templateId === selectedValue?.templateId
                    ? (omit(item, [
                          'selected',
                          'valueFrom',
                          'valueTo',
                      ]) as AttributeValueType<RangeInUnitsSingleAttributeValueType>)
                    : item;
            }),
        });

        if (newSelectedValue) {
            setSelectedValue(newSelectedValue);
        }
    }

    if (!selectedValue) {
        return null;
    }

    return (
        <Form.Item label={label}>
            <Space size={0}>
                <RangePicker from={fromInput} to={toInput} />
                <Select
                    className={inUnitsAttributeStyles.attribute__in_units__select}
                    onChange={handleOnSelectChange}
                    popupMatchSelectWidth={false}
                    value={selectedValue.templateId}
                >
                    {values.map((option) => (
                        <Select.Option key={option.templateId} value={option.templateId}>
                            {option.unitValue}
                        </Select.Option>
                    ))}
                </Select>
            </Space>
        </Form.Item>
    );
}
