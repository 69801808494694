import {WeekDaysEnum} from '../../../../../../../../provider/locale/locale-context-const';
import {LangKeyType} from '../../../../../../../../provider/locale/translation/type';

export enum WeekdayGroupEnum {
    Workday = '8',
    Weekend = '9',
}

export const weekDayShortNameValuesMap: Record<WeekDaysEnum | WeekdayGroupEnum, LangKeyType> = {
    [WeekDaysEnum.monday]: 'WEEK_DAY__SHORT__MONDAY',
    [WeekDaysEnum.tuesday]: 'WEEK_DAY__SHORT__TUESDAY',
    [WeekDaysEnum.wednesday]: 'WEEK_DAY__SHORT__WEDNESDAY',
    [WeekDaysEnum.thursday]: 'WEEK_DAY__SHORT__THURSDAY',
    [WeekDaysEnum.friday]: 'WEEK_DAY__SHORT__FRIDAY',
    [WeekDaysEnum.saturday]: 'WEEK_DAY__SHORT__SATURDAY',
    [WeekDaysEnum.sunday]: 'WEEK_DAY__SHORT__SUNDAY',
    [WeekdayGroupEnum.Workday]: 'WEEK_DAY__ON_WEEKDAYS',
    [WeekdayGroupEnum.Weekend]: 'WEEK_DAY__WEEKEND',
};

export const workdays = [
    WeekDaysEnum.monday,
    WeekDaysEnum.tuesday,
    WeekDaysEnum.wednesday,
    WeekDaysEnum.thursday,
    WeekDaysEnum.friday,
];

export const weekends = [WeekDaysEnum.saturday, WeekDaysEnum.sunday];

export const weekdays = [...workdays, ...weekends];
