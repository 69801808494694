import {Button} from 'antd';
import {ReactElement} from 'react';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks, useVideoLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {ConfirmCompaniesType} from '../../../../../provider/onboarding/onboarding-hook';
import {ProvidersEnum} from '../../../../../util/type';
import * as styles from '../../onboarding.scss';
import {OnboardingCatalogsNameEnum, OnboardingStepEnum, onboardingSuffix} from '../../onboarding-const';
import {MappedDataType} from '../../onboarding-type';
import {OnboardingStep} from '../step';

import {
    getGoogleConfirmCompaniesDescription,
    getYandexConfirmCompaniesDescription,
} from './confirm-companies-step-helper';

type PropsType = {
    title: ReactElement;
    isOpenStep: boolean;
    stepIndex: OnboardingStepEnum;
    confirmCompanies: ConfirmCompaniesType;
    active?: boolean;
};

export function ConfirmCompaniesStep(props: PropsType): ReactElement {
    const {isOpenStep, stepIndex, title, active, confirmCompanies} = props;
    const {onboarding} = useDocumentationLinks();
    const {onboarding: onboardingVideos} = useVideoLinks();
    const {companyName} = useDomainConfig();

    const availableConfirmCompaniesCatalogs = Object.keys(confirmCompanies || {});

    const addCompaniesMappedData: Array<MappedDataType> = [
        {
            providerName: ProvidersEnum.google,
            blockTitle: OnboardingCatalogsNameEnum.Google,
            catalogStatus: confirmCompanies?.google?.status,
            completed: confirmCompanies?.google?.confirmed,
            description: getGoogleConfirmCompaniesDescription(confirmCompanies?.google?.status),
            button: (
                <NavigationLink
                    className={styles.Onboarding_successButton}
                    to={appRoute.sourcesAccountSyncGoogleConfirmations.path + onboardingSuffix}
                >
                    <Button className={styles.Onboarding_successButton} size="middle" type="primary">
                        <Locale stringKey="ONBOARDING__FOURTH_STEP__SUCCESS_BUTTON" />
                    </Button>
                </NavigationLink>
            ),
            documentationLink: onboarding.confirmationGoogleCompanies,
            videoLink: onboardingVideos.confirmationGoogleCompanies,
        },
        {
            providerName: ProvidersEnum.yandex,
            description: getYandexConfirmCompaniesDescription(confirmCompanies?.yandex?.status),
            valueMap: companyName,
            blockTitle: OnboardingCatalogsNameEnum.Yandex,
            completed: confirmCompanies?.yandex?.confirmed,
            catalogStatus: confirmCompanies?.yandex?.status,
            button: (
                <NavigationLink
                    className={styles.Onboarding_successButton}
                    to={appRoute.sourcesConfirmations.path + onboardingSuffix}
                >
                    <Button className={styles.Onboarding_successButton} size="middle" type="primary">
                        <Locale stringKey="ONBOARDING__FOURTH_STEP__SUCCESS_BUTTON" />
                    </Button>
                </NavigationLink>
            ),
            documentationLink: onboarding.confirmationYandexCompanies,
            videoLink: onboardingVideos.confirmationYandexCompanies,
        },
    ].filter((catalogStep) => availableConfirmCompaniesCatalogs.includes(catalogStep.providerName));

    return (
        <OnboardingStep
            active={active}
            isOpenStep={isOpenStep}
            key={stepIndex}
            mappedData={addCompaniesMappedData}
            stepIndex={stepIndex}
            title={title}
        />
    );
}
