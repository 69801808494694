import {faExternalLink, faFile, faGlobe} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip, Typography} from 'antd';

import {Locale} from '../../../../../../../../../provider/locale/localization';
import {CompaniesValidationOptionsType} from '../../../../../../../../../service/companies-import/companies-import';

import * as styles from './feed-results-source-link.scss';

type PropsType = {
    source: CompaniesValidationOptionsType;
};

export function FeedResultsSourceLink(props: PropsType): JSX.Element {
    const {source} = props;

    return (
        <Button
            className={styles.FeedResultsSourceLink}
            icon={
                <Tooltip title={<Locale stringKey={source.link ? 'FEED__LINK' : 'FEED__FILE'} />}>
                    <FontAwesomeIcon
                        className={styles.FeedResultsSourceLink_secondaryIcon}
                        icon={source.link ? faGlobe : faFile}
                    />
                </Tooltip>
            }
            size="small"
            type="link"
        >
            <Tooltip title={source.link || source.feedFileName}>
                <Typography.Text className={styles.FeedResultsSourceLink_sourceText} ellipsis>
                    {source.link || source.feedFileName}
                </Typography.Text>
            </Tooltip>

            {source.link && <FontAwesomeIcon className={styles.FeedResultsSourceLink_linkIcon} icon={faExternalLink} />}
        </Button>
    );
}
