import {Switch, Table, TablePaginationConfig, Typography} from 'antd';
import {Key, useCallback, useEffect, useMemo} from 'react';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {renderCellActions} from '../../../../layout/actions-cell/actions-cell';
import {Locale} from '../../../../provider/locale/locale';
import {AutoReplyToReviewDataType} from '../../../../service/reviews/reviews-response-templates';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {ValidityColumn} from '../validity-column/validity-column';

import {autoRepliesTableColumnList} from './auto-replies-table-const';
import {AutoReplyToReviewTableRowDataType} from './auto-replies-table-type';
import * as styles from './auto-replies-table.scss';

const {Text} = Typography;

type PropsType = {
    autoRepliesToReviewsResult: Array<AutoReplyToReviewDataType>;
    count: number;
    setAutoReplyToReviewDataList: (autoReplyToReviewDataList: Array<AutoReplyToReviewDataType>) => void;
    setSelectedIdList: (selectedIdList: Array<string>) => void;
    pagination: TablePaginationConfig;
    setPagination: (pagination: TablePaginationConfig) => void;
    autoRepliesToReviewsIsInProgress: boolean;
    onDelete: (autoReplyToReviewData: AutoReplyToReviewDataType) => void;
    changeCondition: (id: number, value: boolean) => void;
};

export function AutoRepliesTable(props: PropsType): JSX.Element {
    const {
        setSelectedIdList,
        setAutoReplyToReviewDataList,
        autoRepliesToReviewsResult,
        count,
        pagination,
        setPagination,
        changeCondition,
        autoRepliesToReviewsIsInProgress,
        onDelete,
    } = props;
    const {pushUrl} = useUrl();

    const {reviewsManagementAutoReplyEdit: reviewsManagementAutoReplyEditRoute} = appRoute;

    const convertCompanyTableRow = useCallback(
        (autoReplyToReviewData: AutoReplyToReviewDataType): AutoReplyToReviewTableRowDataType => {
            const {name, description, id, mode, enabled, enabledFrom, enabledTo} = autoReplyToReviewData;

            function onChangeClick() {
                pushUrl(generatePath(reviewsManagementAutoReplyEditRoute.path, {autoReplyId: id}));
            }

            return {
                title: (
                    <>
                        {name}
                        {description && (
                            <Text className={styles.auto_replies_table__description} disabled key="description">
                                {description}
                            </Text>
                        )}
                    </>
                ),
                type: (
                    <Locale
                        stringKey={
                            mode === 'manual'
                                ? 'AUTO_REPLIES__REPLY_TYPE__TEMPORARY'
                                : 'AUTO_REPLIES__REPLY_TYPE__PERMANENT'
                        }
                    />
                ),
                condition: (
                    <Switch
                        checked={enabled}
                        onChange={(value: boolean) => {
                            changeCondition(id, value);
                        }}
                    />
                ),
                validity: <ValidityColumn dateFrom={enabledFrom} dateTo={enabledTo} />,
                key: String(id),
                actions: renderCellActions(onChangeClick, () => onDelete(autoReplyToReviewData)),
            };
        },
        [pushUrl, reviewsManagementAutoReplyEditRoute, changeCondition, onDelete]
    );

    const dataList = useMemo(() => {
        return autoRepliesToReviewsResult || [];
    }, [autoRepliesToReviewsResult]);

    const tableDataList = useMemo(() => {
        return dataList.map(convertCompanyTableRow);
    }, [dataList, convertCompanyTableRow]);

    useEffect(() => {
        setAutoReplyToReviewDataList(dataList);
    }, [dataList, setAutoReplyToReviewDataList]);

    function handleRowSelection(_selectedRowKeys: Array<Key>, rowDataList: Array<AutoReplyToReviewTableRowDataType>) {
        setSelectedIdList(rowDataList.map((rowData: AutoReplyToReviewTableRowDataType): string => rowData.key));
    }

    return (
        <Table<AutoReplyToReviewTableRowDataType>
            columns={autoRepliesTableColumnList}
            dataSource={tableDataList}
            loading={autoRepliesToReviewsIsInProgress}
            onChange={setPagination}
            pagination={{
                size: 'default',
                showSizeChanger: true,
                hideOnSinglePage: false,
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: count,
            }}
            rowSelection={{columnWidth: 47, onChange: handleRowSelection}}
            scroll={{x: true}}
            size="middle"
        />
    );
}
