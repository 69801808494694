import {useCatalog} from '../../../provider/catalogs/catalogs-hook';
import {classNames} from '../../../util/css';

import * as styles from './catalog-cell.scss';

type PropsType = {
    catalogId: number;
    className?: string;
};

export function CatalogCell(props: PropsType): JSX.Element {
    const {catalogId, className} = props;

    const {catalog} = useCatalog(catalogId);

    return (
        <div className={classNames(styles.CatalogCell, className)}>
            <img alt={catalog?.name} className={styles.CatalogCell_logo} src={catalog?.logo} />
            <div>{catalog?.name}</div>
        </div>
    );
}
