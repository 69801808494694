import {ProvidersIdsEnum} from '../../../../util/type';

import {FacebookAccountsMatching} from './facebook/facebook-accounts-matching';
import {GoogleAccountsMatching} from './google/google-accounts-matching';

type PropsType = {
    companyId: number;
    providerId: number;
    brandId: number;
};

export function SingleAccountMatchingContent(props: PropsType): JSX.Element {
    const {providerId, companyId, brandId} = props;

    switch (providerId) {
        case ProvidersIdsEnum.google: {
            return <GoogleAccountsMatching brandId={brandId} companyId={companyId} />;
        }
        case ProvidersIdsEnum.facebook: {
            return <FacebookAccountsMatching brandId={brandId} companyId={companyId} />;
        }
        default: {
            throw new Error('unsupported provider');
        }
    }
}
