import {createBrowserHistory} from 'history';
import {FC, useEffect, useState} from 'react';
import {Router} from 'react-router-dom';

import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {ROUTING_BY_DOMAIN} from '../app-const';

import {browserHistory, initHistory} from './helpers/history';
import {Routing as DefaultRouting} from './routing';

export function RoutingByDomain(): JSX.Element | null {
    const {domainName, basename, onLoad} = useDomainConfig();
    const [isHistoryInitialized, setIsHistoryInitialized] = useState(false);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        initHistory(basename);
        setIsHistoryInitialized(true);
    }, [basename]);

    if (!isHistoryInitialized) {
        return null;
    }

    if (!browserHistory) {
        // don't throw an error, app might still be usable
        console.error("History did't initialize");
    }

    const Routing: FC = ROUTING_BY_DOMAIN[domainName] || DefaultRouting;

    return (
        <Router history={browserHistory || createBrowserHistory()}>
            <Routing />
        </Router>
    );
}
