import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tag} from 'antd';
import {useCallback} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {useReviewAnalysisSources} from '../../../../../../service/reivew-analysis/review-analysis-sources/review-analysis-sources-hook';
import {ReviewAnalysisSourceType} from '../../../../../../service/reivew-analysis/review-analysis-sources/review-analysis-sources-type';
import {ReviewRateEnum} from '../../../../../../service/reivew-analysis/review-rate/review-rate-type';
import {SentimentEnum} from '../../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {getRangeFilterTagFormat, getSentimentStringKeyBySentiment} from '../../../reviews-analysis-helper';
import {ReviewsAnalysisFilterEnum, ReviewsAnalysisFilterType} from '../../../reviews-analysis-type';
import {RateStar} from '../../rate-star/rate-star';

import {MultiSelectFilterType} from './primary-filter-type';
import * as styles from './primary-filter.scss';

type PropsType = {
    filter: ReviewsAnalysisFilterType;
    isWithTextOnly: boolean;
    isPreview?: boolean;
    isPeriodHidden?: boolean;
    isTopicsAllowed?: boolean;
    setFilter: (filter: Partial<ReviewsAnalysisFilterType>) => void;
};

// eslint-disable-next-line complexity
export function PrimaryFilter(props: PropsType): JSX.Element {
    const {
        filter,
        isPreview = false,
        isPeriodHidden = false,
        isWithTextOnly,
        setFilter,
        isTopicsAllowed = true,
    } = props;

    const {localeName} = useLocale();

    const {result: catalogsResult, isInProgress: isCatalogsInProgress} = useReviewAnalysisSources();

    const onMultiselectItemRemove = useCallback(
        (filterField: MultiSelectFilterType, removeItem: unknown): void => {
            const partial: Array<unknown> = filter[filterField];

            setFilter({[filterField]: partial.filter((itemInner: unknown): boolean => itemInner !== removeItem)});
        },
        [filter, setFilter]
    );

    const isRangeEmpty: boolean = filter[ReviewsAnalysisFilterEnum.Range].every((date: Date | null): boolean => !date);

    return (
        <div className={styles.PrimaryFilter}>
            {!isRangeEmpty && !isPeriodHidden && (
                <Tag closable={!isPreview} onClose={() => setFilter({[ReviewsAnalysisFilterEnum.Range]: [null, null]})}>
                    {getRangeFilterTagFormat(localeName, filter[ReviewsAnalysisFilterEnum.Range])}
                </Tag>
            )}

            {filter[ReviewsAnalysisFilterEnum.Sentiments].map<JSX.Element>((sentiment: SentimentEnum): JSX.Element => {
                const localeKey: LangKeyType = getSentimentStringKeyBySentiment(sentiment);

                return (
                    <Tag
                        closable={!isPreview}
                        key={sentiment}
                        onClose={() => onMultiselectItemRemove(ReviewsAnalysisFilterEnum.Sentiments, sentiment)}
                    >
                        <Locale stringKey={localeKey} />
                    </Tag>
                );
            })}

            {filter[ReviewsAnalysisFilterEnum.Catalogs].map<JSX.Element>((id: number): JSX.Element => {
                const catalog = catalogsResult?.find(
                    (catalogInner: ReviewAnalysisSourceType): boolean => catalogInner.id === id
                );

                return (
                    <Tag
                        closable={!isPreview}
                        icon={isCatalogsInProgress && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                        key={id}
                        onClose={() => onMultiselectItemRemove(ReviewsAnalysisFilterEnum.Catalogs, id)}
                    >
                        {catalog?.name || ''}
                    </Tag>
                );
            })}

            {filter[ReviewsAnalysisFilterEnum.Tags].map<JSX.Element>((tag: string): JSX.Element => {
                return (
                    <Tag
                        closable={!isPreview}
                        key={tag}
                        onClose={() => onMultiselectItemRemove(ReviewsAnalysisFilterEnum.Tags, tag)}
                    >
                        {tag}
                    </Tag>
                );
            })}

            {filter[ReviewsAnalysisFilterEnum.Phrases].map<JSX.Element>((phrase: string): JSX.Element => {
                return (
                    <Tag
                        closable={!isPreview}
                        key={phrase}
                        onClose={() => onMultiselectItemRemove(ReviewsAnalysisFilterEnum.Phrases, phrase)}
                    >
                        {phrase}
                    </Tag>
                );
            })}

            {isTopicsAllowed &&
                filter[ReviewsAnalysisFilterEnum.Topics].map<JSX.Element>((topic: string): JSX.Element => {
                    return (
                        <Tag
                            closable={!isPreview}
                            key={topic}
                            onClose={() => onMultiselectItemRemove(ReviewsAnalysisFilterEnum.Topics, topic)}
                        >
                            {topic}
                        </Tag>
                    );
                })}

            {filter[ReviewsAnalysisFilterEnum.Rates].map<JSX.Element>((rate: ReviewRateEnum): JSX.Element => {
                return (
                    <Tag
                        closable={!isPreview}
                        key={rate}
                        onClose={() => onMultiselectItemRemove(ReviewsAnalysisFilterEnum.Rates, rate)}
                    >
                        {rate}

                        <RateStar className={styles.RateStarTag} />
                    </Tag>
                );
            })}

            {!isWithTextOnly && filter[ReviewsAnalysisFilterEnum.WithMessage] !== null && (
                <Tag
                    closable={!isPreview}
                    key={ReviewsAnalysisFilterEnum.WithMessage}
                    onClose={() => setFilter({[ReviewsAnalysisFilterEnum.WithMessage]: null})}
                >
                    {filter[ReviewsAnalysisFilterEnum.WithMessage] ? (
                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITH_MSG_LABEL" />
                    ) : (
                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_MSG_LABEL" />
                    )}
                </Tag>
            )}

            {filter[ReviewsAnalysisFilterEnum.WithReply] !== null && (
                <Tag
                    closable={!isPreview}
                    key={ReviewsAnalysisFilterEnum.WithReply}
                    onClose={() => setFilter({[ReviewsAnalysisFilterEnum.WithReply]: null})}
                >
                    {filter[ReviewsAnalysisFilterEnum.WithReply] ? (
                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITH_REPLY_LABEL" />
                    ) : (
                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_REPLY_LABEL" />
                    )}
                </Tag>
            )}

            {filter[ReviewsAnalysisFilterEnum.AbleToReply] !== null && (
                <Tag
                    closable={!isPreview}
                    key={ReviewsAnalysisFilterEnum.AbleToReply}
                    onClose={() => setFilter({[ReviewsAnalysisFilterEnum.AbleToReply]: null})}
                >
                    {filter[ReviewsAnalysisFilterEnum.AbleToReply] ? (
                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITH_ABLE_TO_REPLY_LABEL" />
                    ) : (
                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_ABLE_TO_REPLY_LABEL" />
                    )}
                </Tag>
            )}
        </div>
    );
}
