import {Form as DefaultForm, FormInstance as DefaultFormInstance, FormItemProps, FormProps} from 'antd';
import useFormItemStatus from 'antd/lib/form/hooks/useFormItemStatus';
import {NamePath} from 'antd/lib/form/interface';
import React from 'react';

// https://github.com/microsoft/TypeScript/issues/30680#issuecomment-752725353
type CastType<A, B> = A extends B ? A : B;

type NarrowableType = string | number | bigint | boolean;

type NarrowType<A> = CastType<A, [] | (A extends NarrowableType ? A : never) | {[K in keyof A]: NarrowType<A[K]>}>;

type RecursivePartialType<T> = T extends object
    ? {
          [P in keyof T]?: T[P] extends Array<infer U>
              ? Array<RecursivePartialType<U>>
              : T[P] extends object
              ? RecursivePartialType<T[P]>
              : T[P];
      }
    : any; // eslint-disable-line @typescript-eslint/no-explicit-any

/* eslint-disable @typescript-eslint/naming-convention */
type TypedFormInstance<T> = Omit<DefaultFormInstance<T>, 'getFieldValue'> & {
    getFieldValue: <K extends NamePath>(
        name: NarrowType<K>
    ) => K extends keyof T
        ? T[K]
        : K extends [keyof T, string | number, string | number]
        ? K extends [keyof T, keyof T[K[0]], string | number]
            ? K extends [keyof T, keyof T[K[0]], keyof T[K[0]][K[1]]]
                ? T[K[0]][K[1]][K[2]]
                : T[K[0]][K[1]]
            : unknown
        : K extends [keyof T, string | number]
        ? K extends [keyof T, keyof T[K[0]]]
            ? T[K[0]][K[1]]
            : T[K[0]]
        : K extends [keyof T]
        ? T[K[0]]
        : unknown;
    setFieldsValue: (values: RecursivePartialType<T>) => void; // https://github.com/ant-design/ant-design/issues/43305
};

type TypedRenderChildren<Values = unknown> = (form: TypedFormInstance<Values>) => React.ReactNode;

type TypedChildrenType<Values = unknown> = TypedRenderChildren<Values> | React.ReactNode;

interface TypedFormItemProps<Values> extends Omit<FormItemProps<Values>, 'name' | 'children'> {
    name?: keyof Values | number | Array<string | number>;
    children?: TypedChildrenType<Values>;
}

type FormItemType = <Values = unknown>(props: TypedFormItemProps<Values>) => React.ReactElement;

interface FormItemInterface extends FormItemType {
    useStatus: typeof useFormItemStatus;
}

interface TypedFormInterface extends Omit<typeof DefaultForm, 'Item' | 'useForm' | 'useFormInstance'> {
    <Values>(
        props: FormProps<Values> & {children?: React.ReactNode} & {
            ref?: React.Ref<TypedFormInstance<Values>> | undefined;
        }
    ): React.ReactElement; // copy-paste because omit removes constructor
    Item: FormItemInterface;
    useForm: <Values = unknown>(form?: TypedFormInstance<Values>) => [TypedFormInstance<Values>];
    useFormInstance: <Values = unknown>() => TypedFormInstance<Values>;
}

export const Form = DefaultForm as TypedFormInterface;

export type FormInstance<Values> = TypedFormInstance<Values>;
/* eslint-enable @typescript-eslint/naming-convention */
