import {Select} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';

import {contextValueOptions} from './concordance-form-const';

export const contextOptionElements: Array<JSX.Element> = contextValueOptions.map((value: number): JSX.Element => {
    return (
        <Select.Option key={value} value={value}>
            <Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__CONTEXT__OPTION" valueMap={{value}} />
        </Select.Option>
    );
});
