import {Button} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {classNames} from '../../../../util/css';
import {Text} from '../../../text/text';
import {getLandingCardStyles, getLocaleItems} from '../landing-card-helper';
import {LandingCardList} from '../landing-card-list/landing-card-list';
import {LandingCardPropsType} from '../landing-card-type';

export function LandingCardDesktop(props: LandingCardPropsType): JSX.Element {
    const {
        textFirst,
        primaryDescriptionItems,
        buttonLangKey,
        onClick,
        imageSrc,
        mainItem = false,
        titleLangKey,
        imageClassName,
        titleValueMap,
        listDescriptionItems,
        imageComponent,
    } = props;

    const styles = getLandingCardStyles(mainItem);

    const image = imageComponent || (
        <div className={styles.LandingCard_column}>
            <img alt="" className={classNames(styles.LandingCard_image, imageClassName)} src={imageSrc} />
        </div>
    );

    const hasDescriptionList = Boolean(listDescriptionItems && listDescriptionItems.length > 0);

    return (
        <div className={classNames(styles.LandingCard)}>
            {!textFirst && image}
            <div className={styles.LandingCard_column}>
                <Text className={styles.LandingCard_title} stringKey={titleLangKey} valueMap={titleValueMap} />
                <div className={styles.LandingCard_description}>
                    {primaryDescriptionItems.map((item) => {
                        const {stringKey, valueMap} = getLocaleItems(item);

                        return (
                            <Text
                                bolder={hasDescriptionList}
                                key={stringKey}
                                large={mainItem || hasDescriptionList}
                                lighter={!mainItem && !hasDescriptionList}
                                stringKey={stringKey}
                                valueMap={valueMap}
                            />
                        );
                    })}
                </div>
                <LandingCardList listItems={listDescriptionItems} />
                {onClick && buttonLangKey && (
                    <Button className={styles.LandingCard_button} onClick={onClick} type="primary">
                        <Locale stringKey={buttonLangKey} />
                    </Button>
                )}
            </div>
            {textFirst && image}
        </div>
    );
}
