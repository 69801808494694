import Paragraph from 'antd/lib/typography/Paragraph';

import {LocalePlural} from '../../../../../../provider/locale/locale-plural';
import {ReviewDataType} from '../../../../../../service/reviews/reviews-type';
import {cleanImages, getNumberOfImages} from '../../../../../../util/string';

import * as styles from './reviews-item-comment.scss';

type PropsType = {
    reviewData: ReviewDataType;
};

export function DashboardReviewsItemComment(props: PropsType): JSX.Element {
    const {reviewData} = props;
    const {comment, children} = reviewData;
    const numberOfImages = getNumberOfImages(comment || '');
    const cleanedComment = cleanImages(comment || '');

    return (
        <div>
            <Paragraph className={styles.review__comment} ellipsis={{rows: 5}}>
                {cleanedComment}
            </Paragraph>
            {numberOfImages > 0 && (
                <div className={styles.review__comment}>
                    <span className={styles.review__comment_images_counter}>
                        <LocalePlural
                            count={numberOfImages}
                            fewKey="DASHBOARD_PAGE__REVIEWS__ATTACHMENTS__FEW"
                            manyKey="DASHBOARD_PAGE__REVIEWS__ATTACHMENTS__MANY"
                            singularKey="DASHBOARD_PAGE__REVIEWS__ATTACHMENTS__SINGULAR"
                            valueMap={{
                                count: numberOfImages,
                            }}
                        />
                    </span>
                </div>
            )}
            <div className={styles.reviews__comment_answers}>
                <span
                    className={
                        children.length > 0
                            ? styles.reviews__with_answers_wrapper
                            : styles.reviews__without_answers_wrapper
                    }
                >
                    <LocalePlural
                        count={children.length}
                        fewKey="DASHBOARD_PAGE__REVIEWS__STATS_ANSWERS__FEW"
                        manyKey="DASHBOARD_PAGE__REVIEWS__STATS_ANSWERS__MANY"
                        singularKey="DASHBOARD_PAGE__REVIEWS__STATS_ANSWERS__SINGULAR"
                        valueMap={{
                            count: children.length,
                        }}
                    />
                </span>
            </div>
        </div>
    );
}
