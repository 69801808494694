import {Modal} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FunctionComponent, useEffect} from 'react';

import {Locale} from '../../provider/locale/locale';
import {
    SelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../service/feature-companies/feature-companies-type';
import {useRefreshId} from '../../util/hook';

import {CompaniesSelectorTable} from './companies-table/companies-selector-table';
import {CompanySelectHeader} from './company-select-header';
import {CompanySelectModalFooter} from './company-select-modal-footer';
import {CompanySelectAdditionalDataColumnPropsType} from './items/company-select-item-type';

type PropsType<CompanyType extends SelectorCompanyType = SelectorCompanyType> = {
    onOk: () => void;
    isOpen: boolean;
    className?: string;
    disabled?: boolean;
    companiesSelector: UseCompaniesSelectorHookType<CompanyType>;
    renderItem?: FunctionComponent<CompanySelectAdditionalDataColumnPropsType<CompanyType>>;
    renderFilter?: JSX.Element;
    renderAdditionalControl?: JSX.Element;
    columns?: ColumnsType<CompanyType>;
    onCloseModal: () => void;
};

export function CompanyPopupV2<CompanyType extends SelectorCompanyType = SelectorCompanyType>(
    props: PropsType<CompanyType>
): JSX.Element {
    const {
        className,
        isOpen,
        onOk,
        disabled,
        companiesSelector,
        renderItem,
        renderFilter,
        renderAdditionalControl,
        columns,
        onCloseModal,
    } = props;

    const {
        selectorId,
        isInProgress,
        companies,
        selectCompany,
        deselectCompany,
        loadMoreCompanies,
        selectedCount,
        deselectAll,
        companiesSelectorFilter,
        clearFilter,
    } = companiesSelector;

    const {refresh: refreshTableScrollTrigger, refreshId: tableScrollTrigger} = useRefreshId();

    useEffect(() => {
        refreshTableScrollTrigger();
    }, [companiesSelectorFilter, refreshTableScrollTrigger]);

    return (
        <Modal
            afterClose={clearFilter}
            centered
            className={className}
            closable
            destroyOnClose
            footer={[
                <CompanySelectModalFooter
                    disabled={disabled}
                    key="footer"
                    onClear={deselectAll}
                    onReady={onOk}
                    selectedCount={selectedCount}
                    selectorId={selectorId}
                />,
            ]}
            onCancel={() => onCloseModal()}
            open={isOpen}
            title={<Locale stringKey="COMPANY__MULTISELECT__POPUP__SELECTION_OF_COMPANIES" />}
            width={862}
        >
            <CompanySelectHeader
                beforeSelectFromMainFilter={refreshTableScrollTrigger}
                companiesSelector={companiesSelector}
                disabled={disabled}
                renderAdditionalControl={renderAdditionalControl}
                renderFilter={renderFilter}
            />

            <CompaniesSelectorTable<CompanyType>
                columns={columns}
                companies={companies}
                disabled={disabled}
                isInProgress={isInProgress}
                loadMoreCompanies={loadMoreCompanies}
                onDeselectCompany={deselectCompany}
                onSelectCompany={selectCompany}
                renderAdditionalContent={renderItem}
                scrollToTopDependency={tableScrollTrigger}
            />
        </Modal>
    );
}
