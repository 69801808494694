import {LangKeyType} from '../../../../provider/locale/translation/type';
import {ExportReportModeEnum} from '../../../../service/reviews/reviews-report';

export type ReviewsDownloadReportPopupItemType = {
    mode: ExportReportModeEnum;
    header: LangKeyType;
    description: LangKeyType;
    placeholder: LangKeyType;
};

export const enum ReviewsReportFormEnum {
    Mode = 'mode',
    Name = 'name',
    WithReviewsSummary = 'withReviewsSummary',
    WithTagsStat = 'withTagsStat',
}

export type ReviewsReportFormType = {
    [ReviewsReportFormEnum.Name]: string;
    [ReviewsReportFormEnum.WithReviewsSummary]: boolean;
    [ReviewsReportFormEnum.WithTagsStat]: boolean;
};
