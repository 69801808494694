import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';

import {useLocale} from '../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {useCursorPagination} from '../../../util/cursor-pagination/cursor-pagination-hook';
import {CursorPaginationType} from '../../../util/cursor-pagination/cursor-pagination-type';
import {LocalPackSearchServiceType} from '../local-pack-type';

import {
    activateLocalPackTrialPeriod,
    createLocalpackRule,
    fetchLocalpackAvailableServices,
    fetchLocalpackBrands,
    fetchLocalpackRule,
    fetchLocalpackRules,
    fetchLocalPackTrialSearchServices,
    updateLocalpackRule,
} from './local-pack-rule-api';
import {
    LOCAL_PACK_AVAILABLE_SERVICES_KEY,
    LOCAL_PACK_BRANDS_KEY,
    LOCAL_PACK_RULE_KEY,
    LOCAL_PACK_RULES_KEY,
    LOCAL_PACK_TRIAl_AVAILABLE_SERVICES_KEY,
} from './local-pack-rule-const';
import {
    LocalPackActivationRuleFormType,
    LocalPackBrandsResponseType,
    LocalPackRuleFormFieldsEnum,
    LocalPackRuleFormType,
    LocalPackRulesType,
    LocalPackRuleType,
    UpdateLocalPackRuleOptionsType,
} from './local-pack-rule-list-item-type';

export function useLocalPackSearchServices(): UseQueryResult<Array<LocalPackSearchServiceType>> {
    return useQuery<Array<LocalPackSearchServiceType>>(
        [LOCAL_PACK_AVAILABLE_SERVICES_KEY],
        fetchLocalpackAvailableServices,
        {
            refetchOnMount: true,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
}

export function useLocalPackTrialSearchServices(): UseQueryResult<Array<LocalPackSearchServiceType>> {
    return useQuery<Array<LocalPackSearchServiceType>>(
        [LOCAL_PACK_TRIAl_AVAILABLE_SERVICES_KEY],
        fetchLocalPackTrialSearchServices,
        {
            refetchOnMount: true,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
}

export function useLocalPackBrands(): UseQueryResult<LocalPackBrandsResponseType> {
    return useQuery<LocalPackBrandsResponseType>([LOCAL_PACK_BRANDS_KEY], fetchLocalpackBrands, {
        refetchOnMount: true,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
}

export function useLocalPackRule(ruleId: number): UseQueryResult<LocalPackRuleType> {
    return useQuery<LocalPackRuleType>(
        [LOCAL_PACK_RULE_KEY],
        () => {
            return fetchLocalpackRule(ruleId);
        },
        {
            refetchOnMount: true,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
}

export function useLocalPackRules(): UseQueryResult<LocalPackRulesType> & CursorPaginationType {
    const cursorPagination = useCursorPagination({
        dependencies: null,
    });

    const {cursor, pageSize, refreshId, onDataLoaded, onDataLoadFailed} = cursorPagination;

    const query = useQuery<LocalPackRulesType>(
        [LOCAL_PACK_RULES_KEY, refreshId],
        () =>
            fetchLocalpackRules({
                cursor,
                pageSize,
            }),
        {
            refetchOnMount: true,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            onSuccess: onDataLoaded,
            onError: onDataLoadFailed,
        }
    );

    return {
        ...query,
        pagination: cursorPagination,
    };
}

export function useLocalPackUpdateRuleMutation(): UseMutationResult<void, unknown, UpdateLocalPackRuleOptionsType> {
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();

    function hasUpdatedDataFields(variables: UpdateLocalPackRuleOptionsType) {
        return Object.values(LocalPackRuleFormFieldsEnum).some((fieldName) => {
            return Boolean(variables.data[fieldName]);
        });
    }

    function onSuccess(_error: void, variables: UpdateLocalPackRuleOptionsType) {
        if (hasUpdatedDataFields(variables)) {
            snackbar.success(getLocalizedString('LOCAL_PACK__RULE__UPDATE_SUCCESS'));
        }
    }

    function onError(_data: void, variables: UpdateLocalPackRuleOptionsType) {
        if (hasUpdatedDataFields(variables)) {
            snackbar.error(getLocalizedString('LOCAL_PACK__RULE__UPDATE_ERROR'));
        }
    }

    return useMutation(updateLocalpackRule, {onSuccess, onError});
}

export function useLocalPackCreateRuleMutation(): UseMutationResult<void, unknown, LocalPackRuleFormType> {
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();

    function onSuccess() {
        snackbar.success(getLocalizedString('LOCAL_PACK__RULE__CREATE_SUCCESS'));
    }

    function onError() {
        snackbar.error(getLocalizedString('LOCAL_PACK__RULE__CREATE_ERROR'));
    }

    return useMutation(createLocalpackRule, {onSuccess, onError});
}

export function useLocalPackTrialLicenseActivationMutation(): UseMutationResult<
    void,
    unknown,
    LocalPackActivationRuleFormType
> {
    return useMutation(activateLocalPackTrialPeriod);
}
