import {noop} from 'lodash';
import {Dispatch, SetStateAction} from 'react';

import {LegendBox} from '../../../../layout/chart/legend-box/legend-box';
import {LegendWrapper} from '../../../../layout/chart/legend-wrapper/legend-wrapper';
import {MultiSourceDataSetType} from '../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {Locale} from '../../../../provider/locale/localization';

type PropsType = {
    isCompareMode: boolean;
    mainPeriodDatasets: Array<Omit<MultiSourceDataSetType, 'color'> & {color: string}>;
    enabledDatasetsMap: Record<string, boolean>;
    setEnabledDatasetsMap: Dispatch<SetStateAction<Record<string, boolean>>>;
};

export function RatingDistributionByTimeLegend(props: PropsType): JSX.Element {
    const {isCompareMode, mainPeriodDatasets, enabledDatasetsMap, setEnabledDatasetsMap} = props;

    return (
        <LegendWrapper>
            {mainPeriodDatasets.map((mainPeriodDataset) => {
                return (
                    <LegendBox
                        checked={Boolean(enabledDatasetsMap[mainPeriodDataset.label])}
                        color={mainPeriodDataset.color as string}
                        key={mainPeriodDataset.label}
                        label={mainPeriodDataset.label}
                        onChange={(enabled) => {
                            setEnabledDatasetsMap((previousState) => {
                                return {
                                    ...previousState,
                                    [mainPeriodDataset.label]: enabled,
                                };
                            });
                        }}
                    />
                );
            })}
            {isCompareMode && (
                <LegendBox
                    checked
                    key="compare"
                    label={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__PERIOD_B" />}
                    onChange={noop}
                    stripes
                />
            )}
        </LegendWrapper>
    );
}
