import {NotificationTabKeyEnum} from '../../../../page/main/notification/notification-type';
import {StoreLocatorLanguageEnum} from '../../../../service/store-locator-config/store-locator-config-short-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {ProvidersIdsEnum} from '../../../../util/type';
import {LocaleNameEnum} from '../../../locale/locale-context-type';
import {DomainConfigType, DomainNameEnum, HeaderVisibilityOptionsEnum} from '../../domain-config-type';

import {ReactComponent as LogoBreadcrumbs} from './component/logo-bread-crumbs/image/logo-bread-crumbs.svg';
import twoGisLogoFullSrc from './component/logo-full/image/two-gis-logo-full.png';
import {LogoFull} from './component/logo-full/logo-full';
import twoGisLogoShortSrc from './component/logo-short/image/two-gis-logo-short.png';
import {LogoShort} from './component/logo-short/logo-short';
import {onEnter} from './script/on-enter';
import {onLoad} from './script/on-load';

function getBaseName(): string {
    const pathname = typeof location !== 'undefined' ? location.pathname : '';

    return pathname.startsWith('/online_presence') ? '/online_presence/' : '/';
}

export const domainConfig: DomainConfigType = {
    basename: getBaseName(),
    companyName: '2GIS',
    domainName: DomainNameEnum.twoGis,
    LogoFull,
    logoFullSrc: twoGisLogoFullSrc,
    LogoShort,
    logoShortSrc: twoGisLogoShortSrc,
    faviconSrc: twoGisLogoShortSrc,
    LogoBreadcrumbs,
    onLoad,
    onEnter,
    isFreshChatShow: false,
    isNotificationShow: false,
    isIdeaButtonShow: false,
    isLogoutShow: false,
    isReportCompaniesShow: false,
    isUserProfileShow: false,
    headerVisibility: HeaderVisibilityOptionsEnum.HIDDEN_FOR_SINGLE_COMPANY,
    isFooterShow: false,
    canCreateCompany: false,
    isDomainFeatureEnabled: {
        [FeaturesEnum.photoTool]: false,
        [FeaturesEnum.reviewAnalyticsBase]: false,
        [FeaturesEnum.reviewAnalyticsFull]: false,
        [FeaturesEnum.reviewAnalyticsCompetitor]: false,
        [FeaturesEnum.duplicatesAndFakes]: false,
        [FeaturesEnum.posts]: false,
        [FeaturesEnum.reviewsGenerator]: false,
        [FeaturesEnum.reviewSuggests]: true,
        [FeaturesEnum.storeLocator]: false,
        [FeaturesEnum.onlinePresenceAnalytics]: false,
        [FeaturesEnum.dashboard]: false,
        [FeaturesEnum.localPack]: false,
        [FeaturesEnum.mlReplies]: false,
        [FeaturesEnum.companyGroups]: true,
        [FeaturesEnum.usedeskIntegration]: false,
        [FeaturesEnum.prices]: true,
        [FeaturesEnum.analytics]: false,
        [FeaturesEnum.userManagement]: false,
        [FeaturesEnum.omnichannel]: false,
        [FeaturesEnum.publicApi]: false,
        [FeaturesEnum.reviewGptReplySuggestions]: false,
        [FeaturesEnum.mailingListLicence]: false,
        [FeaturesEnum.tariffs]: false,
        [FeaturesEnum.companiesExport]: false,
        [FeaturesEnum.knowledge]: false,
        integrations: false,
        [FeaturesEnum.neuralNetwork]: true,
        [FeaturesEnum.sources]: true,
        [FeaturesEnum.reviews]: true,
        [FeaturesEnum.settings]: true,
        [FeaturesEnum.questionsAndAnswers]: true,
        [FeaturesEnum.myCompanies]: true,
    },
    hiddenCompanyFields: {branchCode: true},
    canFoldDesktopMenu: false,
    isPageWrapperHasPaddings: false,
    isLanguageSelectorVisible: false,
    isDynamicHelpLinksVisible: false,
    isSingleFeature: false,
    isWhiteLabel: false,
    availableLocales: Object.values(LocaleNameEnum),
    defaultLocale: LocaleNameEnum.enUs,
    isSourceSettingsShown: true,
    notificationTabs: [NotificationTabKeyEnum.email, NotificationTabKeyEnum.browser, NotificationTabKeyEnum.telegram],
    feedTypeLabels: null,
    feedTemplateLinks: [],
    storeLocatorLanguages: Object.values(StoreLocatorLanguageEnum),
    mainCatalogs: [ProvidersIdsEnum.google, ProvidersIdsEnum.yandex],
    isShowUsetifulButton: false,
    isShowYandexTransferAccountBanner: true,
    isShowTrialNeurlalinkInfo: false,
    isShowReviewSourcesBanner: false,
};
