import {Popover, Tag} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {EmptyCell} from '../../../../../../layout/table/empty-cell/empty-cell';
import {LocalePlural} from '../../../../../../provider/locale/locale-plural';

import {
    MAX_NUMBER_LIST_ITEM,
    TextPluralEnum,
    textPluralMap,
    textPluralPopoverMap,
} from './brands-or-companies-cell-const';
import * as styles from './brands-or-companies-cell.scss';

type PropsType = {
    brandsCount: number;
    companiesCount: number;
    limitedCompaniesNames: Array<string>;
    limitedBrandsNames: Array<{id: number; name: string}>;
};

export function BrandsOrCompaniesCell(props: PropsType): JSX.Element {
    const {brandsCount, companiesCount, limitedCompaniesNames, limitedBrandsNames} = props;

    if (!brandsCount && !companiesCount) {
        return <EmptyCell />;
    }

    const hasBrands = Boolean(brandsCount);
    const localePluralValue = hasBrands ? TextPluralEnum.brands : TextPluralEnum.companies;
    const count = hasBrands ? brandsCount : companiesCount;

    return (
        <Popover
            content={
                <>
                    <ul className={styles.BrandsOrCompaniesCell_list}>
                        {(hasBrands ? limitedBrandsNames : limitedCompaniesNames).map((item, index) =>
                            index <= MAX_NUMBER_LIST_ITEM - 1 ? (
                                typeof item === 'string' ? (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <li key={`${item}-${index}`}>{item}</li>
                                ) : (
                                    <li key={item.id}>{item.name}</li>
                                )
                            ) : null
                        )}
                    </ul>
                    {count > MAX_NUMBER_LIST_ITEM && (
                        <span className={styles.BrandsOrCompaniesCell_info}>
                            <LocalePlural
                                count={count - MAX_NUMBER_LIST_ITEM}
                                fewKey={textPluralPopoverMap[localePluralValue].fewKey}
                                manyKey={textPluralPopoverMap[localePluralValue].manyKey}
                                singularKey={textPluralPopoverMap[localePluralValue].singularKey}
                                valueMap={{count: count - MAX_NUMBER_LIST_ITEM}}
                            />
                        </span>
                    )}
                </>
            }
            placement="bottom"
            title={
                <>
                    <Text bolder capitalize stringKey={textPluralMap[localePluralValue].manyKey} />: {count}
                </>
            }
        >
            <Tag>
                {count}{' '}
                <LocalePlural
                    count={count}
                    fewKey={textPluralMap[localePluralValue].fewKey}
                    manyKey={textPluralMap[localePluralValue].manyKey}
                    singularKey={textPluralMap[localePluralValue].singularKey}
                />
            </Tag>
        </Popover>
    );
}
