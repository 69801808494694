import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

const notificationsLicenseSchema = r.object({
    payment: r.number(),
    startAt: r.string(),
    finishAt: r.string(),
    isUpsell: r.boolean(),
    isTrial: r.boolean(),
    isBonus: r.boolean(),
    types: r.array(r.string()),
    licenceName: r.string(),
    licenceCode: r.string(),
});

const notificationsLicensesSchema = r.object({
    activeTrialLicences: r.array(notificationsLicenseSchema),
    availableTrialLicences: r.array(notificationsLicenseSchema),
    paidLicences: r.array(notificationsLicenseSchema),
    noPaidNoTrialLicences: r.array(notificationsLicenseSchema),
    autoReviewLicenceBalance: r.number(),
    companyLicenceBalance: r.number(),
    roleLicenceBalance: r.number(),
});

type MotificationsLicensesType = r.infer<typeof notificationsLicensesSchema>;

function getNotificationsLicensesUrl(tariffId?: number) {
    return `/cp/tariff/${tariffId}/license/?licence_code=notifications_licence`;
}

function fetchNotificationsLicenses(tariffId?: number): Promise<MotificationsLicensesType> {
    return fetchAndDeserialize(getNotificationsLicensesUrl(tariffId), notificationsLicensesSchema);
}

export function useNotificationsLicenses(tariffId?: number): UseQueryResult<MotificationsLicensesType> {
    return useQuery([getNotificationsLicensesUrl(tariffId)], () => fetchNotificationsLicenses(tariffId), {
        enabled: Boolean(tariffId),
    });
}
