import {Button} from 'antd';
import {ReactElement, useMemo, useState} from 'react';

import {AddAccountButton} from '../../../../../component/add-account-button/add-account-button';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks, useVideoLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {AccountConnectionType} from '../../../../../provider/onboarding/onboarding-hook';
import {useFacebookAccounts, useGoogleAccounts} from '../../../../../service/accounts/accounts-hook';
import {ProvidersEnum} from '../../../../../util/type';
import * as styles from '../../onboarding.scss';
import {OnboardingCatalogsNameEnum, OnboardingStepEnum} from '../../onboarding-const';
import {MappedDataType} from '../../onboarding-type';
import {SyncDoubleGisAccountPopup} from '../../sync-doublegis-account-popup/sync-doublegis-account-popup';
import {OnboardingStep} from '../step';

type PropsType = {
    title: ReactElement;
    isOpenStep: boolean;
    stepIndex: OnboardingStepEnum;
    accountsConnection: AccountConnectionType;
    refetch: () => void;
    active?: boolean;
};

export function AddAccountsStep(props: PropsType): ReactElement {
    const {isOpenStep, stepIndex, title, active, accountsConnection, refetch} = props;
    const {onboarding} = useDocumentationLinks();
    const {onboarding: onboardingVideos} = useVideoLinks();
    const {companyName} = useDomainConfig();

    const {reset, linkingSrc} = useGoogleAccounts();

    const [openSyncTwoGisPopup, setOpenSyncTwoGisPopup] = useState<boolean>(false);

    const {reset: facebookReset, linkingSrc: facebookLinkOauth} = useFacebookAccounts();

    const availableAccountConnectionCatalogs = Object.keys(accountsConnection || {});

    function onCloseGoogleModalHandler() {
        reset();
        refetch();
    }

    function onCloseFacebookModalHandler() {
        facebookReset();
        refetch();
    }

    function onClose2GisModalHandler() {
        setOpenSyncTwoGisPopup(false);
        refetch();
    }

    const addAccountStepData: Array<MappedDataType> = useMemo(
        () =>
            [
                {
                    providerName: ProvidersEnum.google,
                    blockTitle: OnboardingCatalogsNameEnum.Google,
                    completed: accountsConnection.google,
                    description: 'ONBOARDING__SECOND_STEP__GOOGLE__DESCRIPTION' as LangKeyType,
                    valueMap: companyName,
                    button: (
                        <AddAccountButton
                            buttonContent={<Locale stringKey="ONBOARDING__ACCOUNT_CONNECT_BUTTON" />}
                            className={styles.Onboarding_successButton}
                            linkingSrc={linkingSrc}
                            onClose={onCloseGoogleModalHandler}
                            size="middle"
                            type="primary"
                        />
                    ),
                    documentationLink: onboarding.connectGoogleAccount,
                    videoLink: onboardingVideos.connectGoogleAccount,
                },
                {
                    providerName: ProvidersEnum.doubleGis,
                    description: 'ONBOARDING__SECOND_STEP__DOUBLEGIS__DESCRIPTION' as LangKeyType,
                    valueMap: companyName,
                    blockTitle: OnboardingCatalogsNameEnum.DoubleGis,
                    completed: accountsConnection['2gis.ru'],
                    button: (
                        <Button
                            className={styles.Onboarding_successButton}
                            onClick={() => setOpenSyncTwoGisPopup(true)}
                            size="middle"
                            type="primary"
                        >
                            <Locale stringKey="ONBOARDING__ACCOUNT_CONNECT_BUTTON" />
                        </Button>
                    ),

                    documentationLink: onboarding.connectDoubleGisAccount,
                    videoLink: onboardingVideos.connectDoubleGisAccount,
                },
                {
                    providerName: ProvidersEnum.facebook,
                    description: 'ONBOARDING__SECOND_STEP__FACEBOOK__DESCRIPTION' as LangKeyType,
                    valueMap: companyName,
                    blockTitle: OnboardingCatalogsNameEnum.Facebook,
                    completed: accountsConnection.facebook,
                    button: (
                        <AddAccountButton
                            buttonContent={<Locale stringKey="ONBOARDING__ACCOUNT_CONNECT_BUTTON" />}
                            className={styles.Onboarding_successButton}
                            linkingSrc={facebookLinkOauth}
                            onClose={onCloseFacebookModalHandler}
                            size="middle"
                            type="primary"
                        />
                    ),
                    documentationLink: onboarding.connectFacebookAccount,
                    videoLink: onboardingVideos.connectFacebookAccount,
                },
            ].filter((catalogStep) => availableAccountConnectionCatalogs.includes(catalogStep.providerName)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accountsConnection, availableAccountConnectionCatalogs, companyName, facebookLinkOauth]
    );

    return (
        <>
            <OnboardingStep
                active={active}
                isOpenStep={isOpenStep}
                key={stepIndex}
                mappedData={addAccountStepData}
                stepIndex={stepIndex}
                title={title}
            />
            {openSyncTwoGisPopup && <SyncDoubleGisAccountPopup onClose={onClose2GisModalHandler} />}
        </>
    );
}
