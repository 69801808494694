import {max, min} from 'lodash';

import {PostSourceEnum} from '../../../../../../../service/posts/posts-types';
import {POSTS_VALIDATION_SETTINGS} from '../content-step-helper';
import {ContentValidationSettingsType} from '../content-step-type';

export function getCommonFieldsValidation(providers: Array<PostSourceEnum>): ContentValidationSettingsType {
    const selectedProvidersValidationSettings = providers.map((provider) => POSTS_VALIDATION_SETTINGS[provider]);

    return {
        maxTextLength: min(selectedProvidersValidationSettings.map((settings) => settings.maxTextLength)) || 0,
        images: {
            maxCount: min(selectedProvidersValidationSettings.map((settings) => settings.images.maxCount)) || 0,
            maxHeight: min(selectedProvidersValidationSettings.map((settings) => settings.images.maxHeight)) || 0,
            maxWidth: min(selectedProvidersValidationSettings.map((settings) => settings.images.maxWidth)) || 0,
            minHeight: max(selectedProvidersValidationSettings.map((settings) => settings.images.minHeight)) || 0,
            minWidth: max(selectedProvidersValidationSettings.map((settings) => settings.images.minWidth)) || 0,
            imageMime: selectedProvidersValidationSettings
                .map((settings) => settings.images.imageMime)
                .reduce((accumulator, current) => accumulator.filter((element) => current.includes(element))),
        },
        extra: {
            text: Object.fromEntries(
                Object.entries(selectedProvidersValidationSettings).flatMap(([key, settings]) => {
                    return Object.entries(settings?.extra?.text || {}).map(
                        ([extraSettingKey, itemExtraSettingsItem]) => {
                            return [`${key}_${extraSettingKey}`, itemExtraSettingsItem];
                        }
                    );
                })
            ),
        },
        catalogRequirementsLink: selectedProvidersValidationSettings
            .map((settings) => settings.catalogRequirementsLink)
            .join(','),
    };
}
