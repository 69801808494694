import {Empty} from 'antd';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {KnowledgeDataChildType} from '../../../../service/knowledge/knowledge-type';
import {classNames} from '../../../../util/css';
import {KnowledgeCategoryItem} from '../knowledge-category-item/knowledge-category-item';
import * as styles from '../knowledge-page.scss';
import {countArticles} from '../knowledge-page-helper';

type PropsType = {
    processError: Error | null;
    isInProgress: boolean | null;
    result: Array<KnowledgeDataChildType>;
};

export function KnowledgeContentPage(props: PropsType): JSX.Element {
    const {processError, isInProgress, result} = props;

    const isEmpty = !countArticles(result || []);

    switch (true) {
        case processError instanceof Error:
            return <AlertFallback />;
        case isInProgress:
            return (
                <PageCard
                    className={classNames(
                        styles.KnowledgePage_pageCard__stretch,
                        styles.KnowledgePage_pageCard__center
                    )}
                >
                    <Spinner spinSize="large" />
                </PageCard>
            );
        case isEmpty:
            return (
                <PageCard
                    className={classNames(
                        styles.KnowledgePage_pageCard__stretch,
                        styles.KnowledgePage_pageCard__center
                    )}
                >
                    <Empty
                        className={styles.KnowledgePage_empty}
                        description={<Locale stringKey="KNOWLEDGE__EMPTY" />}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        imageStyle={{height: 117}}
                    />
                </PageCard>
            );
        default:
            return (
                <div className={styles.KnowledgePage_content}>
                    {result.map(
                        ({content, categoryId, title}) =>
                            content.length > 0 && (
                                <PageCard key={categoryId} title={title}>
                                    {content.map((articles) => (
                                        <KnowledgeCategoryItem item={articles} key={articles.title} />
                                    ))}
                                </PageCard>
                            )
                    )}
                </div>
            );
    }
}
