import {Badge, theme} from 'antd';
import {ReactNode} from 'react';

import {Locale} from '../../provider/locale/localization';
import {LangKeyType} from '../../provider/locale/translation/type';

import * as styles from './menu-navigation-badge.scss';

type PropsType = {
    showCounter?: boolean;
    count?: number;
    color?: string;
    labelLangKey: LangKeyType;
    labelValueMap?: Record<string, ReactNode>;
};

export function MenuNavigationBadge(props: PropsType): JSX.Element {
    const {showCounter, count, color, labelValueMap, labelLangKey} = props;

    const {token} = theme.useToken();

    return (
        <div className={styles.MenuNavigationBadge}>
            <span className={styles.MenuNavigationBadge_text}>
                <Locale stringKey={labelLangKey} valueMap={labelValueMap} />
            </span>

            {showCounter && count ? <Badge color={color || token.colorPrimary} count={count} /> : null}
        </div>
    );
}
