import {Locale} from '../../provider/locale/localization';
import {LangKeyType} from '../../provider/locale/translation/type';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';

export function useCopy(infoText: LangKeyType): (text: string) => Promise<void> {
    const {snackbar} = useSnackbar();

    return async function onCopy(text: string) {
        if (typeof navigator?.clipboard?.writeText !== 'function') {
            snackbar.error(<Locale stringKey="REVIEWS__REVIEW_COPY__BROWSER_ERROR" />);
            return;
        }

        try {
            await navigator.clipboard.writeText(text);
            snackbar.success(<Locale stringKey={infoText} />);
        } catch (error: unknown) {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            });

            throw error;
        }
    };
}
