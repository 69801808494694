import {getFromLocalStorage, saveToLocalStorage} from '../../util/local-storage';

import {defaultUiContext, uiProviderLocalStorageKey} from './ui-context-const';
import {uiContextSavedDataSchema, UiContextSavedDataType, UiContextType} from './ui-context-type';

export function getDefaultUiContext(): UiContextType {
    const savedData = getFromLocalStorage<UiContextSavedDataType>(uiProviderLocalStorageKey, uiContextSavedDataSchema, {
        isLeftSideOpen: defaultUiContext.isLeftSideOpen,
    });

    return {
        ...defaultUiContext,
        ...savedData,
    };
}

export function checkUiThemeSettigs(): void {
    if (window.location.search.includes('theme')) {
        const theme = window.location.search.includes('theme=dark') ? 'dark' : 'light';

        window.document.body.dataset.theme = theme;
        saveToLocalStorage(theme, 'ui-theme');

        return;
    }

    const savedTheme = window.localStorage.getItem('ui-theme');

    if (savedTheme) {
        window.document.body.dataset.theme = JSON.parse(savedTheme);
    }
}
