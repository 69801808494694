import {Alert, Button} from 'antd';
import {useCallback, useMemo} from 'react';

import {appRoute} from '../../../../../../../app-route';
import {NavigationLink} from '../../../../../../../layout/navigation-link/navigation-link';
import {useActionRequiresLicense} from '../../../../../../../provider/license/license-hook';
import {LocaleWithCatalogName} from '../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {useRemovePostMutation} from '../../../../../../../service/posts/posts-hooks';
import {
    PostFormFieldsEnum,
    PostFormType,
    PostSourceEnum,
    PostStatusEnum,
} from '../../../../../../../service/posts/posts-types';
import {FeaturesEnum} from '../../../../../../../service/user/user-type';
import {FormInstance} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {useUrl} from '../../../../../../../util/url-hook/url-hook';

import * as styles from './post-submit-actions.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    isDisabled: boolean;
};

export function ViewPostActions(props: PropsType): JSX.Element {
    const {formInstance, isDisabled} = props;

    const status = formInstance.getFieldValue([PostFormFieldsEnum.Status]);
    const {modal} = useModal();

    const {mutateAsync: removePost, isLoading: isRemoving} = useRemovePostMutation();
    const {pushUrl} = useUrl();

    const removeButtonVisible = useMemo(() => {
        return (
            status !== PostStatusEnum.Deleted &&
            formInstance
                .getFieldValue([PostFormFieldsEnum.Posts])
                .some(({catalog}) => catalog !== PostSourceEnum.instagram)
        );
    }, [formInstance, status]);

    const hasInstagram = useMemo(() => {
        return formInstance
            .getFieldValue([PostFormFieldsEnum.Posts])
            .some(({catalog}) => catalog === PostSourceEnum.instagram);
    }, [formInstance]);

    const handleRemoveClick = useActionRequiresLicense(
        useCallback(async () => {
            modal.confirm({
                title: <Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__TITLE" />,
                content: (
                    <>
                        {hasInstagram && (
                            <Alert
                                message={
                                    <LocaleWithCatalogName
                                        catalogId={ProvidersIdsEnum.instagram}
                                        stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__UNSUPPORTED_REMOVE_ALERT"
                                    />
                                }
                                type="warning"
                            />
                        )}
                        <Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__CONTENT" />
                    </>
                ),
                okText: <Locale stringKey="BUTTON__DELETE" />,
                cancelText: <Locale stringKey="BUTTON__CANCEL" />,
                onOk: async () => {
                    const postId = formInstance.getFieldValue([PostFormFieldsEnum.Id]);

                    if (!postId) {
                        return;
                    }

                    await removePost(postId);
                    pushUrl(appRoute.postsManagement.path);
                },
            });
        }, [formInstance, hasInstagram, modal, pushUrl, removePost]),
        FeaturesEnum.posts
    );

    return (
        <div className={styles.PostSubmitButtons}>
            <NavigationLink to={appRoute.postsManagement.path}>
                <Button className={styles.PostSubmitButtons_button} disabled={isRemoving} type="primary">
                    <Locale stringKey="POSTS_FORM__BUTTON__RETURN_TO_LIST" />
                </Button>
            </NavigationLink>
            {removeButtonVisible && (
                <Button
                    className={styles.PostSubmitButtons_button}
                    danger
                    disabled={isDisabled || isRemoving}
                    loading={isRemoving}
                    onClick={handleRemoveClick}
                >
                    <Locale stringKey="POSTS_FORM__BUTTON__REMOVE_POST" />
                </Button>
            )}
        </div>
    );
}
