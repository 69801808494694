import {MutableRefObject, useCallback, useMemo, useRef} from 'react';
import {VariableSizeGrid} from 'react-window';

type UseGridRefType<DataType> = {
    gridRef: MutableRefObject<VariableSizeGrid<DataType> | null>;
    resetColumnGrid: (index?: number) => void;
    resetRowGrid: (index?: number) => void;
    connectObject: unknown;
};

// source of the base logic - https://ant.design/components/table/#components-table-demo-virtual-list
export function useGridRef<DataType>(): UseGridRefType<DataType> {
    const gridRef = useRef<VariableSizeGrid<DataType> | null>(null);

    const connectObject = useMemo(() => {
        const object = {};

        Reflect.defineProperty(object, 'scrollLeft', {
            get: () => null,
            set: (scrollLeft: number) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({scrollLeft});
                }
            },
        });

        return object;
    }, []);

    const resetColumnGrid = useCallback((rowIndex?: number) => {
        if (gridRef.current) {
            gridRef.current.resetAfterColumnIndex(rowIndex || 0, true);
        }
    }, []);

    const resetRowGrid = useCallback((rowIndex?: number) => {
        if (gridRef.current) {
            gridRef.current.resetAfterRowIndex(rowIndex || 0, true);
        }
    }, []);

    return {gridRef, connectObject, resetRowGrid, resetColumnGrid};
}
