import {Tabs} from 'antd';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';

import {Language} from './language/language';
import {PasswordChange} from './password-change/password-change';
import {PersonalInformation} from './personal-information/personal-information';
import {TwoFactorAuthentication} from './two-factor-authentication/two-factor-authentication';

export function Profile(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const tabs = [
        {
            key: 'personal-information',
            label: <Locale stringKey="PROFILE__PERSONAL_INFORMATION__TAB_NAME" />,
            children: <PersonalInformation />,
        },
        {
            key: 'password-change',
            label: <Locale stringKey="PROFILE__PASSWORD_CHANGE__TAB_NAME" />,
            children: <PasswordChange />,
        },
        {
            key: 'two-factor-authentication',
            label: <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__TAB_NAME" />,
            children: <TwoFactorAuthentication />,
        },
        {
            key: 'language',
            label: <Locale stringKey="PROFILE__LANGUAGE__TAB_NAME" />,
            children: <Language />,
        },
    ];

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__PROFILE')} />

            <BreadCrumbs list={[{path: appRoute.settingsProfile.path, titleLangKey: 'CATEGORY_NAME__PROFILE'}]} />

            <Tabs defaultActiveKey="personal-information" items={tabs} />
        </Page>
    );
}
