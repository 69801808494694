import {Flex} from 'antd';
import {useMemo} from 'react';

import {Text} from '../../../../component/text/text';
import {spacer_l} from '../../../../css/var-export.scss';
import {DoughnutChart} from '../../../../layout/chart/doughnut-chart/doughnut-chart';
import {DoughnutDataType} from '../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {DoughnutChartWithCenterData} from '../../../../layout/doughnut-char-with-center-data/doughnut-chart-with-center-data';
import {DoughnutStatistic} from '../../../../layout/doughnut-statistic/doughnut-statistic';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/localization';
import {
    ReviewsAndAnswersFilterQueriesEnum,
    ReviewsAndAnswersFilterStateType,
} from '../../../../service/reviews-and-answers-statistics/filter/reviews-and-answers-statistics-filter-type';
import {ReviewsCountByCatalogType} from '../../../../service/reviews-and-answers-statistics/reviews-count-by-catalog/reviews-count-by-catalog-type';
import {getDatasetColor} from '../../../../util/chart-colors/chart-colors';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {CompareModeDelta} from '../compare-mode-delta/compare-mode-delta';

import {getReviewsByCountChartOptions, getReviewsByCountCompareChartOptions} from './reviews-count-by-catalog-helper';
import * as styles from './reviews-count-by-catalog.scss';

type PropsType = {
    isCompareMode: boolean;
    isLoading?: boolean;
    pdfPreview?: boolean;
    reviewsCountByCatalog: ReviewsCountByCatalogType | null;
    filter: ReviewsAndAnswersFilterStateType;
};

export function ReviewsCountByCatalog(props: PropsType): JSX.Element {
    const {isLoading, reviewsCountByCatalog, pdfPreview, isCompareMode, filter} = props;

    const {getCatalogName} = useCatalogInfo();

    const chartData: Array<
        DoughnutDataType & {
            compareValue: number | null;
            delta: number | null;
        }
    > = useMemo(() => {
        return (
            reviewsCountByCatalog?.byCatalogs?.map((catalogStats, index) => ({
                label: getCatalogName(catalogStats.pk) || catalogStats.name,
                color: getDatasetColor(index, catalogStats.name),
                value: catalogStats.count.value,
                compareValue: catalogStats.count.comparedValue,
                delta: catalogStats.count.delta,
            })) || []
        );
    }, [getCatalogName, reviewsCountByCatalog?.byCatalogs]);

    const compareChartData: Array<DoughnutDataType> = useMemo(() => {
        return chartData
            .map((item) => ({
                label: item.label,
                color: item.color,
                value: item.compareValue || 0,
            }))
            .reverse();
    }, [chartData]);

    const {getFormattedDateTime} = useFormat();

    const mainPeriodTitle = useMemo(() => {
        const formattedPeriodStart = getFormattedDateTime(filter[ReviewsAndAnswersFilterQueriesEnum.PeriodStart], {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        const formattedPeriodEnd = getFormattedDateTime(filter[ReviewsAndAnswersFilterQueriesEnum.PeriodEnd], {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        return `${formattedPeriodStart} - ${formattedPeriodEnd}`;
    }, [filter, getFormattedDateTime]);

    const comparePeriodTitle = useMemo(() => {
        if (
            filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart] &&
            filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]
        ) {
            const formattedPeriodStart = getFormattedDateTime(
                filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart],
                {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                }
            );

            const formattedPeriodEnd = getFormattedDateTime(
                filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd],
                {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                }
            );

            return `${formattedPeriodStart} - ${formattedPeriodEnd}`;
        }

        return '';
    }, [filter, getFormattedDateTime]);

    if (isLoading) {
        return (
            <PageCard
                className={styles.ReviewByCatalog_card}
                title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEW_BY_CATALOG__TITLE" />}
            >
                <Spinner />
            </PageCard>
        );
    }

    return (
        <PageCard
            bodyClassName={classNames(styles.ReviewByCatalog, {
                [styles.ReviewByCatalog__compare]: isCompareMode,
                [styles.ReviewByCatalog__empty]: !reviewsCountByCatalog?.count.value,
            })}
            className={styles.ReviewByCatalog_card}
            title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEW_BY_CATALOG__TITLE" />}
        >
            {!isCompareMode ? (
                <>
                    <DoughnutChartWithCenterData
                        centerContent={
                            reviewsCountByCatalog?.count ? (
                                <Flex align="center" vertical>
                                    <Text
                                        block
                                        secondary
                                        stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEW_BY_CATALOG__TOTAL"
                                    />
                                    <Text bolder className={styles.ReviewByCatalog_totalReviewsCount} large>
                                        {reviewsCountByCatalog?.count.value}
                                    </Text>
                                </Flex>
                            ) : null
                        }
                        chartOptions={getReviewsByCountChartOptions(chartData, mainPeriodTitle, pdfPreview)}
                        chartWrapperClassName={styles.ReviewByCatalog_chartWrapper}
                        className={styles.ReviewByCatalog_chart}
                        data={chartData}
                        hideOnEmptyData
                    />
                    {chartData.map((legendItem) => {
                        return (
                            <DoughnutStatistic
                                className={styles.ReviewByCatalog_statisticsItem}
                                color={legendItem.color}
                                key={legendItem.label}
                                label={legendItem.label}
                                value={String(legendItem.value)}
                            />
                        );
                    })}
                </>
            ) : (
                <>
                    <Flex
                        align="center"
                        className={classNames(
                            styles.ReviewByCatalog_chartWrapper,
                            styles.ReviewByCatalog_chartWrapper__compare
                        )}
                        gap={spacer_l}
                        justify="center"
                        vertical
                    >
                        <CompareModeDelta
                            className={styles.ReviewByCatalog_compareDelta}
                            compareValue={reviewsCountByCatalog?.count.comparedValue || 0}
                            delta={reviewsCountByCatalog?.count.delta || 0}
                            value={reviewsCountByCatalog?.count.value || 0}
                        />
                        <div className={styles.ReviewByCatalog_compareCharts_wrapper}>
                            <div className={styles.ReviewByCatalog_compareCharts_chartContainer}>
                                <div className={styles.ReviewByCatalog_compareCharts_canvasWrapper}>
                                    <DoughnutChart
                                        chartOptions={{
                                            ...getReviewsByCountCompareChartOptions({
                                                data: chartData,
                                                mainPeriodTitle,
                                                comparePeriodTitle,
                                                pdfPreview,
                                            }),
                                            rotation: 180,
                                        }}
                                        data={chartData}
                                    />
                                </div>
                            </div>
                            <div className={styles.ReviewByCatalog_compareCharts_chartContainer}>
                                <div className={styles.ReviewByCatalog_compareCharts_canvasWrapper}>
                                    <DoughnutChart
                                        chartOptions={getReviewsByCountCompareChartOptions({
                                            data: [...chartData].reverse(),
                                            mainPeriodTitle,
                                            comparePeriodTitle,
                                            pdfPreview,
                                        })}
                                        data={compareChartData}
                                        normalized
                                    />
                                </div>
                            </div>
                        </div>
                    </Flex>
                    {chartData.map((legendItem) => {
                        return (
                            <Flex
                                className={styles.ReviewByCatalog_compareStatisticsItem}
                                key={legendItem.label}
                                vertical
                            >
                                <DoughnutStatistic color={legendItem.color} label={legendItem.label} value="" />
                                <CompareModeDelta
                                    compareValue={legendItem.compareValue || 0}
                                    delta={legendItem.delta || 0}
                                    value={legendItem.value || 0}
                                />
                            </Flex>
                        );
                    })}
                </>
            )}
        </PageCard>
    );
}
