export const enCompanyActivityAndSummaryTranslations = {
    COMPANY_ACTIVITY_AND_SUMMARY__BREADCRUMB_TEXT: 'Activity and summary',
    COMPANY_ACTIVITY_AND_SUMMARY__PAGE_HEADER: 'Activity in card and summary of company',
    COMPANY_ACTIVITY_AND_SUMMARY__PAGE_HEADER_LINK: 'Back to list',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_HEADER: 'Prices information',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_FOOTER_TEXT:
        'You can upload or update products information in catalogs on page {link}.',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_FOOTER_TEXT_PLACEHOLDER: 'Prices',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__POSTS_HEADER: 'Posts',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__COMPANY_RATING_HEADER: 'Company card rating on maps',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__HEADER: 'Synchronization with main sources',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__FOOTER:
        'More information can be found on the {sourcesLink} page.',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__ACTION_NEEDED: 'Requires binding or confirmation',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__SENT: 'The data will appear in the catalog soon.',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__DETAILS__SYNCHRONIZATION_DATE: 'Check date:',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__DETAILS__SYNCHRONIZATION_ERRORS: 'Discrepancies:',
    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__DETAILS__SYNCHRONIZATION_ERRORS__TITLE:
        'Discrepancies with the catalog',

    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__TITLE: 'Online presence Summary',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__POPOVER_HEADER: 'Analytics summary',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__POPOVER_DESCRIPTION:
        'Main catalogs information shown, if the company synced with the card in catalog.',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__BASE_METRICS_PERIOD: 'Basic metrics for the period: {start} - {end}',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__IMPRESSIONS: 'Impressions',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__TARGET_ACTIONS: 'Target Actions',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CONVERSION: 'Conversion',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__ROUTES: 'Routes',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__SITE: 'Site',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CALLS: 'Calls',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__PERIOD: 'per period',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CHART__IMPRESSIONS: 'Impressions',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CHART__TARGET_ACTIONS: 'TA',
    COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CHART__TOTAL_TARGET_ACTIONS: 'Total TA',

    COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__REVIEWS_SUMMARY_HEADER: 'Reviews summary of a company',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__TOTAL_REVIEWS: 'Total',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__DAY_AVERAGE: 'Day average (Max.)',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__SOURCES: 'Sources',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__WITHOUT_ANSWER: 'No answer',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__NPS: 'NPS',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__NPS_POPOVER_CONTENT:
        'Difference between positive (≥4.5) and negative (≤3) percentages.',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__POSITIVITY: 'Positivity',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__POSITIVITY_POPOVER_TITLE: 'Positivity Index',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__POSITIVITY_POPOVER_CONTENT:
        'Proportion of positive reviews of the total number of reviews.',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_HEADER: 'Reviews distibuting on the catalogs',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__OTHER_CATALOGS: 'Other catalogs',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_FOOTER_TEXT: 'You can find full analytic on the {link} page',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_LINK_TEXT: 'Reviews analysis',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_POSITIVE: 'Positive',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_NEUTRAL: 'Neutral',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_NEGATIVE: 'Negative',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_UNSPACIFIED: 'Unspesified',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_HEADER:
        'Distribution of reviews by sentiment',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS__SUBHEADER: 'Main factors',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS__PERIOD: 'For the period: {start} - {end} ',
    COMPANY_ACTIVITY_AND_SUMMARY__POSTS__LAST_PERIOD: 'for this company in the last 30 days',
    COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_LEGEND_ITEM: '{tone} ({count} pcs)',
};
