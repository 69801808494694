import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {IntraServiceType} from './intra-service-type';

export function fetchIntraService(): Promise<IntraServiceType> {
    return fetchX<IntraServiceType>(rootApiUrl + '/v1/user/notification_settings/intra_service/');
}

export function updateIntraService(intraService: IntraServiceType): Promise<IntraServiceType> {
    return fetchX<IntraServiceType>(rootApiUrl + '/v1/user/notification_settings/intra_service/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(intraService),
    });
}
