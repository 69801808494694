import {Redirect, Route, Switch} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {Spinner} from '../../../layout/spinner/spinner';
import {useUser} from '../../../provider/user/user-hook';
import {ProvidersIdsEnum} from '../../../util/type';

import {OnlinePresenceDashboard} from './online-presence-dashboard';
import {OnlinePresenceFacebook} from './online-presence-facebook';
import {OnlinePresenceGoogle} from './online-presence-google';
import {OnlinePresenceLocations} from './online-presence-locations';
import {OnlinePresencePhrases} from './online-presence-phrases';
import {OnlinePresenceSynchronization} from './online-presence-synchronization';
import {OnlinePresenceWgis} from './online-presence-wgis';
import {OnlinePresenceYandex} from './online-presence-yandex';

export function OnlinePresenceRouting(): JSX.Element {
    const {user} = useUser();

    if (!user) {
        return <Spinner />;
    }

    const {regionAvailableCatalogs} = user;

    return (
        <Switch>
            <Route exact path={[appRoute.onlinePresence.path]}>
                <Redirect
                    to={{
                        pathname: appRoute.onlinePresenceDashboard.path,
                    }}
                />
            </Route>

            <Route component={OnlinePresenceDashboard} exact={false} path={appRoute.onlinePresenceDashboard.path} />

            {regionAvailableCatalogs[ProvidersIdsEnum.google] && (
                <Route component={OnlinePresenceGoogle} exact={false} path={appRoute.onlinePresenceGoogle.path} />
            )}

            {regionAvailableCatalogs[ProvidersIdsEnum.doubleGis] && (
                <Route component={OnlinePresenceWgis} exact path={appRoute.onlinePresenceWgis.path} />
            )}
            {regionAvailableCatalogs[ProvidersIdsEnum.facebook] && (
                <Route component={OnlinePresenceFacebook} exact path={appRoute.onlinePresenceFacebook.path} />
            )}
            {regionAvailableCatalogs[ProvidersIdsEnum.yandex] && (
                <Route component={OnlinePresenceYandex} exact path={appRoute.onlinePresenceYandex.path} />
            )}
            <Route component={OnlinePresenceSynchronization} exact path={appRoute.onlinePresenceSynchronization.path} />
            <Route component={OnlinePresencePhrases} exact path={appRoute.onlinePresencePhrases.path} />
            <Route component={OnlinePresenceLocations} exact path={appRoute.onlinePresenceLocations.path} />
        </Switch>
    );
}
