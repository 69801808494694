import {List} from 'antd';

import {Text} from '../../../../../component/text/text';
import {CompanyErrorType} from '../../../../../service/company-v2/company-type';

import * as styles from './error-modal.scss';

type PropsType = {
    error: CompanyErrorType['nonFieldErrors'];
};

export function CompanyFormErrorModalContent(props: PropsType): JSX.Element | null {
    const {error} = props;

    if (error?.needModerate?.length === 1) {
        return <Text>{error.needModerate[0]}</Text>;
    }

    if (error?.needModerate && error.needModerate.length > 1) {
        return (
            <List
                bordered
                className={styles.CompanyFormErrorModalContent_list}
                dataSource={error.needModerate}
                renderItem={(data) => {
                    return <List.Item>{data}</List.Item>;
                }}
            />
        );
    }

    return null;
}
