import {merge} from 'lodash';
import {useMemo} from 'react';

import {ChartRenderer} from '../chart-renderer/chart-renderer';

import {pieChartOptions} from './pie-chart-helper';
import {ChartJsPieDataType, ChartJsPieOptionsType, PieDataType} from './pie-chart-type';

type PropsType = {
    data: Array<PieDataType>;
    options?: ChartJsPieOptionsType;
};

export function PieChart(props: PropsType): JSX.Element {
    const {data, options} = props;

    const chartJsData: ChartJsPieDataType = useMemo<ChartJsPieDataType>(
        (): ChartJsPieDataType => ({
            labels: data.map(({label}: PieDataType): string => label),
            datasets: [
                {
                    data: data.map(({value}: PieDataType): number => value),
                    backgroundColor: data.map(({color}: PieDataType): string => color),
                },
            ],
        }),
        [data]
    );

    return (
        <ChartRenderer<'pie', Array<number>, string>
            data={chartJsData}
            options={merge({...pieChartOptions}, options)}
            type="pie"
        />
    );
}
