import {useMemo} from 'react';

import {LocalePlural} from '../../../../provider/locale/locale-plural';
import {Locale} from '../../../../provider/locale/localization';
import {ReviewDataChildType} from '../../../../service/reviews/reviews-type';
import {classNames} from '../../../../util/css';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';
import {getReplyCount} from '../review-item-page-content/activity-area/activity-area-help';

import * as styles from './review-activity-selector.scss';

type PropsType = {
    reviewChildren: Array<ReviewDataChildType>;
    notesCount?: number;
    isAnswerAvailable?: boolean;
};

export function ReviewActivitySelector(props: PropsType): JSX.Element {
    const {reviewChildren, notesCount = 0, isAnswerAvailable = true} = props;

    const replyCount = useMemo(() => getReplyCount(reviewChildren), [reviewChildren]);

    const {
        isNotesVisible,
        isRepliesActive,
        isHistoryVisible,
        onRepliesActivityPress,
        onNotesActivityPress,
        onHistoryActivityPress,
    } = useReviewActivityHook();

    return (
        <div className={styles.activity_selector}>
            <button
                className={classNames(styles.activity_button, {
                    [styles.activity_button__active]: isRepliesActive && isAnswerAvailable,
                })}
                onClick={onRepliesActivityPress}
                type="button"
            >
                <LocalePlural
                    count={replyCount}
                    fewKey="REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__FEW"
                    manyKey="REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__MANY"
                    singularKey="REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__SINGLE"
                    valueMap={{count: replyCount}}
                />
            </button>
            <button
                className={classNames(styles.activity_button, {
                    [styles.activity_button__active]: isNotesVisible,
                })}
                onClick={onNotesActivityPress}
                type="button"
            >
                <LocalePlural
                    count={notesCount}
                    fewKey="REVIEW_ITEM_PAGE__ACTIVITY__NOTES__FEW"
                    manyKey="REVIEW_ITEM_PAGE__ACTIVITY__NOTES__MANY"
                    singularKey="REVIEW_ITEM_PAGE__ACTIVITY__NOTES__SINGLE"
                    valueMap={{count: notesCount}}
                />
            </button>
            <button
                className={classNames(styles.activity_button, {
                    [styles.activity_button__active]: isHistoryVisible,
                })}
                onClick={onHistoryActivityPress}
                type="button"
            >
                <Locale stringKey="REVIEW_ITEM_PAGE__ACTIVITY__LOGS" />
            </button>
        </div>
    );
}
