import {Radio, Tabs} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';

import {Locale} from '../../../../../provider/locale/locale';
import {SearchQueryType, SearchServiceQueriesType} from '../../../../../service/local-pack/local-pack-type';
import {classNames} from '../../../../../util/css';
import {LOCALPACK_CATALOG_LOCALIZATION_MAP, LOCALPACK_SERVICE_LOCALIZATION_MAP} from '../../local-pack-const';

import * as styles from './services-list.scss';

type LocalPackResultPageServicesPropsType = {
    searchServiceId: number;
    setSearchServiceId: (searchServiceId: number) => void;
    searchQueryId: number;
    setSearchQueryId: (searchQueryId: number) => void;
    searchServiceQueries: Array<SearchServiceQueriesType>;
    currentSearchServiceQueries: SearchServiceQueriesType;
};

export function LocalPackResultPageServices(props: LocalPackResultPageServicesPropsType): JSX.Element {
    const {
        searchServiceId,
        setSearchServiceId,
        searchQueryId,
        setSearchQueryId,
        searchServiceQueries,
        currentSearchServiceQueries,
    } = props;
    const isSingleService = searchServiceQueries.length === 1;
    const servicesClass = isSingleService
        ? classNames(styles.ServicesList, styles.ServicesList__isSingle)
        : styles.ServicesList;

    return (
        <>
            <div className={servicesClass}>
                <span className={styles.ServicesList_title}>
                    <Locale stringKey="LOCAL_PACK__RESULT_PAGE__PLATFORM" />
                </span>
                {isSingleService && searchServiceQueries[0] ? (
                    <span className={styles.ServicesList_single}>
                        <Locale stringKey={LOCALPACK_SERVICE_LOCALIZATION_MAP[searchServiceQueries[0].service]} />{' '}
                        <Locale stringKey={LOCALPACK_CATALOG_LOCALIZATION_MAP[searchServiceQueries[0].provider]} />
                    </span>
                ) : (
                    <Radio.Group
                        buttonStyle="solid"
                        onChange={(event: RadioChangeEvent): void => setSearchServiceId(event.target.value)}
                        optionType="button"
                        size="small"
                        value={searchServiceId}
                    >
                        {searchServiceQueries.map((searchServiceConfig: SearchServiceQueriesType) => {
                            return (
                                <Radio.Button key={searchServiceConfig.id} value={searchServiceConfig.id}>
                                    <span>
                                        <Locale
                                            stringKey={LOCALPACK_SERVICE_LOCALIZATION_MAP[searchServiceConfig.service]}
                                        />{' '}
                                        <Locale
                                            stringKey={LOCALPACK_CATALOG_LOCALIZATION_MAP[searchServiceConfig.provider]}
                                        />
                                    </span>
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                )}
            </div>
            <div>
                {currentSearchServiceQueries && (
                    <Tabs
                        className={styles.ServicesList_tabs}
                        defaultActiveKey={searchQueryId.toString()}
                        items={currentSearchServiceQueries.queries.map((searchQuery: SearchQueryType) => ({
                            key: searchQuery.id.toString(),
                            label: searchQuery.query,
                        }))}
                        onChange={(activeKey: string): void => setSearchQueryId(Number(activeKey))}
                        size="small"
                    />
                )}
            </div>
        </>
    );
}
