import {faBadgeDollar, faPowerOff} from '@fortawesome/pro-regular-svg-icons';
import {faStopwatch, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Descriptions, Space, Tag, Tooltip} from 'antd';
import dayjs from 'dayjs';
import {isArray, mergeWith} from 'lodash';
import {ReactElement, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../provider/locale/localization';
import {UniqueLicenseDataType} from '../../../../../service/tariffs/tariffs-licenses';
import {useLicensesDescription} from '../../../../../service/tariffs/tariffs-licenses-description';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {LicenseIncreaseModal} from '../../tariffs-modals/license-increase-modal/license-increase-modal';
import {LicensesBlockEnum} from '../licenses-block-type';

import {
    AdditionalLicenseKeyEnum,
    additionalLicenseKeyMap,
    buyButtonKeyMap,
    LicensesCodeEnum,
    ProductTypeEnum,
    productTypeKeyMap,
    tagTextKeyMap,
} from './license-const';
import {LicenseDatesModal} from './license-dates-modal/license-dates-modal';
import {expiredTagLangKey, LicenseDateTableType} from './license-helper';
import {LicenseTags} from './license-tags/license-tags';
import * as styles from './license.scss';

type PropsType = {
    data: UniqueLicenseDataType | null;
    licenseCode: string;
    tariffId: number;
    licenseName: string;
    licenseBalance: number | null;
    isPaid?: boolean;
    isBonus?: boolean;
    type: LicensesBlockEnum;
    licensesRefetch: () => void;
};

const today = dayjs();

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export function License(props: PropsType): ReactElement {
    const {data, licenseName, isPaid, type, tariffId, licenseBalance, licenseCode, licensesRefetch, isBonus} = props;

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const {data: licensesDescription} = useLicensesDescription();

    const {getLocalizedString} = useLocale();
    const {getFormattedDateTime} = useFormat();

    const mergedKeys = (data &&
        mergeWith(
            {},
            ...data.map((item) => {
                return {...item.additionalLicenseKeys, types: item.types};
            }),
            (objectValue: string | number | Array<string>, srcValue: string | number | Array<string>) => {
                if (typeof objectValue === 'number' && typeof srcValue === 'number') {
                    return objectValue + srcValue;
                } else if (typeof objectValue === 'string' && typeof srcValue === 'string') {
                    return objectValue === srcValue ? objectValue : [objectValue, srcValue].join(', ');
                } else if (isArray(objectValue) && isArray(srcValue) && typeof objectValue[0] === 'string') {
                    return [...new Set([...objectValue, ...srcValue])];
                }

                return objectValue;
            }
        )) as LicenseDateTableType;

    const groupedProviderCodesTypes =
        licenseCode === LicensesCodeEnum.CatalogsSynchronizeLicence && data
            ? new Set(
                  data.flatMap((item) => {
                      return item.providerCodes[0] === 'all'
                          ? licensesDescription
                                ?.find(
                                    (licenseDescription) =>
                                        licenseDescription.licenseCode === LicensesCodeEnum.CatalogsSynchronizeLicence
                                )
                                ?.providerCodes?.map((code) => code.label)
                          : item.providerCodes;
                  })
              )
            : false;

    const hasData = data && data[0];

    const isExpired =
        Boolean(hasData) &&
        data?.every((element) => {
            return dayjs(element.finishAt).isSameOrBefore(today, 'day') || dayjs(element.startAt).isAfter(today, 'day');
        });

    const tagClassNames = classNames(styles.License_tag, {
        [styles.License_tag__test]: type === LicensesBlockEnum.TestActive || type === LicensesBlockEnum.TestAvailable,
        [styles.License_tag__notActive]: !isPaid || isExpired,
    });

    const showButton = type !== LicensesBlockEnum.AdditionalLicenses || !isPaid;

    const showTagsInModal =
        licenseCode === LicensesCodeEnum.NotificationsLicence || licenseCode === 'omnichannel_licence';

    return (
        <div className={styles.License_wrapper}>
            <div className={styles.License_header}>
                <Text
                    block
                    className={styles.License_title}
                    ellipsis
                    large
                    secondary={type === LicensesBlockEnum.TestAvailable || !isPaid || isExpired}
                >
                    {licenseName}
                </Text>

                <Space className={styles.License_headerButtonWrapper} size={16}>
                    {mergedKeys && mergedKeys[AdditionalLicenseKeyEnum.Limit] ? (
                        <div className={styles.License_limits}>
                            <span>
                                <Text bolder large>
                                    {licenseBalance}
                                </Text>
                                /
                                <Text bolder large secondary>
                                    {mergedKeys[AdditionalLicenseKeyEnum.Limit]}
                                </Text>
                            </span>
                            {licenseBalance === 0 && (
                                <Tooltip
                                    placement="bottom"
                                    title={<Locale stringKey="TARIFFS__LICENSE_BLOCK__LIMIT_IS_OVER" />}
                                >
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        !groupedProviderCodesTypes &&
                        isPaid &&
                        !isExpired &&
                        (isBonus ? (
                            <Tag bordered={false} className={styles.License_tag__bonus}>
                                <Locale stringKey="TARIFFS__LICENSE_BLOCK__TAG_TITLE__BONUS" />
                            </Tag>
                        ) : (
                            <Tag
                                bordered={false}
                                className={tagClassNames}
                                icon={
                                    (type === LicensesBlockEnum.TestActive ||
                                        type === LicensesBlockEnum.TestAvailable) && (
                                        <FontAwesomeIcon icon={faStopwatch} />
                                    )
                                }
                            >
                                <Locale stringKey={tagTextKeyMap[type]} />
                            </Tag>
                        ))
                    )}
                    {(!isPaid || isExpired) &&
                        (isBonus ? (
                            <Tag bordered={false} className={styles.License_tag__bonus}>
                                <Locale stringKey="TARIFFS__LICENSE_BLOCK__TAG_TITLE__BONUS" />
                            </Tag>
                        ) : (
                            <Tag
                                bordered={false}
                                className={tagClassNames}
                                icon={type === LicensesBlockEnum.TestActive && <FontAwesomeIcon icon={faStopwatch} />}
                            >
                                <Locale stringKey={expiredTagLangKey(type)} />
                            </Tag>
                        ))}
                    {groupedProviderCodesTypes && (
                        <Text className={styles.License_catalogLimits} large>
                            <LocalePlural
                                count={groupedProviderCodesTypes.size}
                                fewKey="TARIFFS__LICENSE_BLOCK__CATALOG_LIMIT__FEW"
                                manyKey="TARIFFS__LICENSE_BLOCK__CATALOG_LIMIT__MANY"
                                singularKey="TARIFFS__LICENSE_BLOCK__CATALOG_LIMIT__SINGULAR"
                                valueMap={{
                                    count: groupedProviderCodesTypes.size,
                                }}
                            />
                        </Text>
                    )}
                    {showButton && (
                        <Button
                            className={styles.License_buyButton}
                            icon={
                                <FontAwesomeIcon
                                    icon={type === LicensesBlockEnum.TestAvailable ? faPowerOff : faBadgeDollar}
                                />
                            }
                            onClick={() => setIsOpenModal(true)}
                        >
                            <Text className={styles.License_secondaryButtonContent} stringKey={buyButtonKeyMap[type]} />
                        </Button>
                    )}
                </Space>
            </div>
            {hasData && (
                <Descriptions className={styles.License_additionalBlock} column={1} size="small">
                    <Descriptions.Item label={<Locale stringKey="TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__DATES" />}>
                        {data?.length === 1 ? (
                            <>
                                {getFormattedDateTime(new Date(data[0]?.startAt || 0))}-
                                {getFormattedDateTime(new Date(data[0]?.finishAt || 0))}
                            </>
                        ) : (
                            <LicenseDatesModal
                                descriptions={data}
                                licenseCode={licenseCode}
                                licenseName={licenseName}
                            />
                        )}
                    </Descriptions.Item>

                    {mergedKeys[AdditionalLicenseKeyEnum.UsersLimit] && (
                        <Descriptions.Item
                            label={<Locale stringKey={additionalLicenseKeyMap[AdditionalLicenseKeyEnum.UsersLimit]} />}
                        >
                            {mergedKeys[AdditionalLicenseKeyEnum.UsersLimit]}
                        </Descriptions.Item>
                    )}
                    {mergedKeys[AdditionalLicenseKeyEnum.ConversationsLimit] && (
                        <Descriptions.Item
                            label={
                                <Locale
                                    stringKey={additionalLicenseKeyMap[AdditionalLicenseKeyEnum.ConversationsLimit]}
                                />
                            }
                        >
                            {mergedKeys[AdditionalLicenseKeyEnum.ConversationsLimit]}
                        </Descriptions.Item>
                    )}
                    {mergedKeys[AdditionalLicenseKeyEnum.SourcesLimit] && (
                        <Descriptions.Item
                            label={
                                <Locale stringKey={additionalLicenseKeyMap[AdditionalLicenseKeyEnum.SourcesLimit]} />
                            }
                        >
                            {mergedKeys[AdditionalLicenseKeyEnum.SourcesLimit]}
                        </Descriptions.Item>
                    )}
                    {mergedKeys[AdditionalLicenseKeyEnum.ProductType] &&
                        mergedKeys[AdditionalLicenseKeyEnum.ProductType].length > 0 &&
                        licenseCode !== LicensesCodeEnum.NotificationsLicence && (
                            <Descriptions.Item
                                label={
                                    <Locale stringKey={additionalLicenseKeyMap[AdditionalLicenseKeyEnum.ProductType]} />
                                }
                            >
                                {mergedKeys[AdditionalLicenseKeyEnum.ProductType]
                                    .map((typeLicense) =>
                                        getLocalizedString(productTypeKeyMap[typeLicense as ProductTypeEnum])
                                    )
                                    .join(', ')}
                            </Descriptions.Item>
                        )}
                    <Descriptions.Item>
                        <LicenseTags data={data} licenseCode={licenseCode} />
                    </Descriptions.Item>
                </Descriptions>
            )}
            <LicenseIncreaseModal
                isOpen={isOpenModal}
                licenseCode={licenseCode}
                licenseName={licenseName}
                licenseOptions={{
                    ...mergedKeys,
                    ...(licenseCode === LicensesCodeEnum.CatalogsSynchronizeLicence
                        ? {
                              [AdditionalLicenseKeyEnum.ProviderCodes]: data?.find(
                                  (item) => item.providerCodes.length > 0
                              )?.providerCodes,
                          }
                        : {}),
                }}
                licensesRefetch={licensesRefetch}
                setIsOpen={setIsOpenModal}
                tags={showTagsInModal && <LicenseTags data={data} licenseCode={licenseCode} />}
                tariffId={tariffId}
                type={type}
            />
        </div>
    );
}
