import {ChangePasswordType, PersonalInformationType, TwoFactorAuthenticationType} from './profile-type';

export const defaultPersonalInformation: PersonalInformationType = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    timezone: '',
    citizen: 0,
};

export const defaultChangePassword: ChangePasswordType = {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
};

export const defaultTwoFactorAuthentication: TwoFactorAuthenticationType = {
    authorizationCode: '',
};
