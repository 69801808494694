import {PropsWithChildren} from 'react';

import {useSystem} from '../../provider/system/system-hook';
import {classNames} from '../../util/css';

import * as styles from './main.scss';

type PropsType = PropsWithChildren<{
    mainClassName?: string;
    wrapperClassName?: string;
}>;

export function Main(props: PropsType): JSX.Element {
    const {children, mainClassName, wrapperClassName: wrapperClassNameFromProps} = props;

    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isDesktop} = screen;

    const wrapperClassName = classNames(wrapperClassNameFromProps, isDesktop ? styles.main : styles.main__mobile);
    const containerClassName = classNames(
        styles.main__inner_container,
        mainClassName,
        isDesktop ? styles.scroll_content : styles.scroll_content__mobile
    );

    return (
        <div className={wrapperClassName}>
            <main className={containerClassName}>{children}</main>
        </div>
    );
}
