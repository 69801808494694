import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ButtonProps} from 'antd';
import {PropsWithChildren} from 'react';

import {classNames} from '../../util/css';

import * as styles from './add-button.scss';

type PropsType = Pick<ButtonProps, 'type' | 'size'> &
    PropsWithChildren<{
        onClick?: () => unknown;
        className?: string;
        isDisabled?: boolean;
    }>;

/** @deprecated use {@link https://gitlab.com/rocketdata.io/antcp/-/tree/main/www/component/add-button/add-button.tsx} instead */
export function AddButton(props: PropsType): JSX.Element {
    const {className, children, onClick, type, size, isDisabled} = props;

    const fullClassName = classNames(styles.add_button, className);

    return (
        <Button
            className={fullClassName}
            disabled={isDisabled}
            icon={<FontAwesomeIcon className={styles.add_button__image} icon={faPlus} />}
            onClick={onClick}
            size={size}
            type={type}
        >
            <div className={styles.add_button__text}>{children}</div>
        </Button>
    );
}
