import {sum} from 'lodash';

import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {BarDataSetType} from '../../bar-chart/bar-chart-type';
import {LineDataSetType} from '../../line-chart/line-chart-type';

import * as styles from './chart-legend.scss';

type PropsType = {
    data: LineDataSetType | BarDataSetType;
    className?: string;
};

export function ChartLegendItem(props: PropsType): JSX.Element {
    const {data, className} = props;
    const {getFormattedNumber} = useFormat();

    const value = sum(data.values);
    const formattedValue = getFormattedNumber(value, {
        // eslint-disable-next-line no-undefined
        notation: value > 1_000_000 ? 'compact' : undefined,
    });

    return (
        <div className={classNames(styles.ChartLegend_item, className)}>
            <div className={styles.ChartLegend_labelContainer}>
                <div
                    className={styles.ChartLegend_box}
                    style={{
                        backgroundColor: String(data.color),
                    }}
                />
                <div>{data.label}</div>
            </div>
            <div className={styles.ChartLegend_value}>{formattedValue}</div>
        </div>
    );
}
