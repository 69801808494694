import {Context} from 'chartjs-plugin-datalabels';
import {Options} from 'chartjs-plugin-datalabels/types/options';

import {defaultTooltipOptions, gridColor, labelColor} from '../chart-const';

import {ChartJsBarOptionsType} from './bar-chart-type';

export const barChartOptions: ChartJsBarOptionsType = {
    maintainAspectRatio: false,
    scales: {
        x: {
            type: 'category',
            stacked: true,
            grid: {
                color: gridColor,
                display: false,
                drawTicks: false,
            },
            ticks: {
                color: labelColor,
                padding: 10,
            },
        },
        y: {
            stacked: true,
            border: {
                display: false,
            },
            grid: {
                color: gridColor,
                drawTicks: false,
            },
            ticks: {
                color: labelColor,
                padding: 10,
            },
        },
    },
    plugins: {
        legend: {
            display: true,
            align: 'start',
            reverse: true,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                usePointStyle: true,
                pointStyle: 'circle',
                font: {
                    size: 14,
                },
            },
        },
        tooltip: defaultTooltipOptions,
        datalabels: {
            display: false,
        },
    },
};

export const barChartDataLabelsOptions: Options = {
    color: 'white',
    display: (context: Context): boolean => {
        return Number(context.dataset.data[context.dataIndex]) > 1;
    },
    font: {
        weight: 'bold',
    },
    formatter: Math.round,
};
