import {Modal} from 'antd';
import {useEffect, useState} from 'react';

import {AlertFallback} from '../../../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {useCatalogInfo} from '../../../../../../../provider/catalogs/catalogs-hook';
import {ShortCatalogType} from '../../../../../../../provider/catalogs/catalogs-type';
import {useActionRequiresLicense} from '../../../../../../../provider/license/license-hook';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../../service/analytics/analytics';
import {postCompanySelectorCompanySchema} from '../../../../../../../service/feature-companies/feature-companies-api-type';
import {CompaniesSelectorFeatureEnum} from '../../../../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../../../../service/feature-companies/feature-companies-hook';
import {PostSelectorCompanyType} from '../../../../../../../service/feature-companies/feature-companies-type';
import {publishPostApi} from '../../../../../../../service/posts/posts-api';
import {usePost} from '../../../../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../service/posts/posts-types';
import {FeaturesEnum} from '../../../../../../../service/user/user-type';
import {Form} from '../../../../../../../typings/antd';
import {getPostFormInitialValues} from '../../../../post-form/posts-form-helper';
import {PostSummary} from '../../../../post-form/steps/send-parameters/post-summary/post-summary';

import * as styles from './post-publish-summary.scss';

type PropsType = {
    postId: number;
    isOpen: boolean;
    onClose: () => void;
    postSources: Array<ShortCatalogType>;
    onActionCompleted: () => void;
};

export function PostPublishSummary(props: PropsType): JSX.Element {
    const {postId, isOpen, onClose, postSources, onActionCompleted} = props;
    const {data: post, isLoadingError, isLoading} = usePost(postId);
    const [showSummary, setShowSummary] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const {getCatalogName} = useCatalogInfo();

    const companiesSelector = useCompaniesSelector<PostSelectorCompanyType>({
        feature: CompaniesSelectorFeatureEnum.Posts,
        resourceId: String(postId),
        companySchema: postCompanySelectorCompanySchema,
        selectorId: post?.[PostFormFieldsEnum.SelectorId],
    });

    const [formInstance] = Form.useForm<PostFormType>();

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    useEffect(() => {
        if (post) {
            const initialValues: Record<string, unknown> = {
                ...getPostFormInitialValues(post),
                [PostFormFieldsEnum.Id]: Number(postId),
            };

            Object.keys(initialValues).forEach((key) => {
                formInstance.setFieldValue(key, initialValues[key]);
            });

            setShowSummary(true);
        }
    }, [post, formInstance, isLoading, postId]);

    const handleConfirm = useActionRequiresLicense(async () => {
        setPublishing(true);
        return publishPostApi(postId)
            .then(() => {
                snackbar.success(<Locale stringKey="POSTS_FORM__MESSAGE__PUBLISH_POST_SUCCESS" />);
                track(
                    AnalyticsTarget.Posts.Publish,
                    postSources.map((source) => getCatalogName(source.id))
                );
                onClose();
                onActionCompleted();
            })
            .finally(() => {
                setPublishing(false);
            })
            .catch(() => {
                snackbar.error(<Locale stringKey="POSTS_FORM__MESSAGE__PUBLISH_POST_ERROR" />);
            });
    }, FeaturesEnum.posts);

    if (isLoading || !showSummary) {
        return (
            <Modal footer={null} open title={getLocalizedString('POSTS_SUBMIT_INFORMATION__HEADING')}>
                <Spinner />
            </Modal>
        );
    }

    return (
        <Modal
            cancelText={getLocalizedString('POPUP__BUTTON__CANCEL')}
            confirmLoading={publishing}
            destroyOnClose
            okButtonProps={{disabled: isDisabled || isLoading}}
            okText={getLocalizedString('POPUP__BUTTON__CONFIRM')}
            onCancel={onClose}
            onOk={!isLoadingError ? handleConfirm : onClose}
            open={isOpen}
            title={getLocalizedString('POSTS_SUBMIT_INFORMATION__HEADING')}
        >
            {!isLoadingError ? (
                <PostSummary
                    className={styles.PostPublishSummary}
                    companiesSelector={companiesSelector}
                    formInstance={formInstance}
                    onSetIsDisabled={setIsDisabled}
                />
            ) : (
                <AlertFallback />
            )}
        </Modal>
    );
}
