import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

enum ReviewSuggestionActionEnum {
    apply = 'apply',
    dismiss = 'dismiss',
}

const reviewSuggestionSchema = r.object({
    id: r.number(),
    ruleId: r.number(),
    templateid: r.number(),
    reviewId: r.number(),
    comment: r.string(),
});

export type ReviewSuggestionType = r.infer<typeof reviewSuggestionSchema>;

type ReviewSuggestionArrayType = {
    results: Array<ReviewSuggestionType>;
};

const reviewGptSuggestionSchema = r.object({
    id: r.number(),
    text: r.string(),
    completionId: r.string(),
});

export type ReviewGptSuggestionType = r.infer<typeof reviewGptSuggestionSchema>;

function getReviewsSuggestionsUrl(reviewIds: Array<number>) {
    return `/v4/reviews/suggestions/?${objectToUrlParameters({reviews_ids: reviewIds})}`;
}

function fetchReviewsSuggestions(reviewIds: Array<number>): Promise<ReviewSuggestionArrayType> {
    return fetchAndDeserialize<ReviewSuggestionArrayType>(getReviewsSuggestionsUrl(reviewIds), reviewSuggestionSchema);
}

export function useReviewsSuggestions(reviewIds: Array<number>): UseQueryResult<ReviewSuggestionArrayType> {
    return useQuery([getReviewsSuggestionsUrl(reviewIds)], () => fetchReviewsSuggestions(reviewIds), {
        refetchOnWindowFocus: false,
    });
}

function fetchReviewSuggestionEdit(suggestionId: number, action: ReviewSuggestionActionEnum) {
    const payload = {action};

    return fetchX<void>(`${rootApiUrl}/v1/reviews/suggestions/${suggestionId}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(payload),
    });
}

export function fetchReviewSuggestionApply(suggestionId: number): Promise<void> {
    return fetchReviewSuggestionEdit(suggestionId, ReviewSuggestionActionEnum.apply);
}

export function fetchReviewSuggestionDismiss(suggestionId: number): Promise<void> {
    return fetchReviewSuggestionEdit(suggestionId, ReviewSuggestionActionEnum.dismiss);
}
