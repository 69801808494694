export type LanguageCodeType = keyof typeof LANGUAGE_CODE_TO_COUNTRY_CODE;

export type CountryCodeType = Exclude<(typeof LANGUAGE_CODE_TO_COUNTRY_CODE)[LanguageCodeType], null>;

// ISO 639-1 => ISO 3166
/* eslint-disable id-length */
/* eslint sort-keys: 'error' */
export const LANGUAGE_CODE_TO_COUNTRY_CODE = {
    aa: null,
    ab: null,
    ae: null,
    af: null,
    ak: null,
    am: null,
    an: null,
    ar: 'sa', // Arabic => Saudi Arabia
    as: null,
    av: null,
    ay: null,
    az: 'az', // Azerbaijani => Azerbaijan
    ba: null,
    be: 'by', // Belarusian => Belarus
    bg: 'bg', // Bulgarian => Bulgaria
    bh: null,
    bi: null,
    bm: null,
    bn: 'bd', // Bengali => Bangladesh
    bo: null,
    br: null,
    bs: 'ba', // Bosnian => Bosnia and Herzegovina
    ca: null,
    ce: null,
    ch: null,
    co: null,
    cr: null,
    cs: 'cz', // Czech => Czechia
    cu: null,
    cv: null,
    cy: null,
    da: 'dk', // Danish => Denmark
    de: 'de', // German => Germany
    dv: null,
    dz: null,
    ee: null,
    el: 'gr', // Greek => Greece
    en: 'us', // English => USA
    eo: null,
    es: 'es', // Spanish => Spain
    et: 'ee', // Estonian => Estonia
    eu: null,
    fa: 'ir', // Persian => Iran
    ff: null,
    fi: 'fi', // Finnish => Finland
    fj: null,
    fo: null,
    fr: 'fr', // French => France
    fy: null,
    ga: 'ie', // Irish => Ireland
    gd: null,
    gl: null,
    gn: null,
    gr: null,
    gu: null,
    gv: null,
    ha: null,
    he: 'il', // Hebrew => Israel
    hi: 'in', // Hindi => India
    ho: null,
    hr: 'hr', // Croatian => Croatia
    ht: null,
    hu: 'hu', // Hungarian => Hungary
    hy: 'am', // Armenian => Armenia
    hz: null,
    ia: null,
    id: 'id', // Indonesian => Indonesia
    ie: null,
    ig: null,
    ii: null,
    ik: null,
    io: null,
    is: 'is', // Icelandic => Iceland
    it: 'it', // Italian => Italy
    iu: null,
    ja: 'jp', // Japanese => Japan
    jv: null,
    ka: 'ge', // Georgian => Georgia
    kg: null,
    ki: null,
    kj: null,
    kk: 'kz', // Kazakh => Kazakhstan
    kl: null,
    km: null,
    kn: null,
    ko: 'kr', // Korean => Korea
    kr: null,
    ks: null,
    ku: null,
    kv: null,
    kw: null,
    ky: 'kg', // Kyrgyz => Kyrgyzstan
    la: null,
    lb: 'lu', // Luxembourgish => Luxembourg
    lg: null,
    li: null,
    ln: null,
    lo: null,
    lt: 'lt', // Latvian => Latvia
    lu: null,
    lv: 'lv', // Lithuanian => Lithuania
    ma: null,
    md: 'md', // Moldavian => Moldova
    mg: null,
    mh: null,
    mi: null,
    mk: 'mk', // Macedonian => North Macedonia
    ml: null,
    mn: 'mn', // Mongolian => Mongolia
    mr: null,
    ms: null,
    mt: 'mt', // Maltese => Malta
    my: null,
    na: null,
    nb: null,
    nd: null,
    ne: null,
    ng: null,
    nl: 'nl', // Dutch => Netherlands
    nn: null,
    no: 'no', // Norwegian => Norway
    nr: null,
    nv: null,
    ny: null,
    oc: null,
    oj: null,
    om: null,
    or: null,
    os: null,
    pa: null,
    pi: null,
    pl: 'pl', // Polish => Poland
    ps: null,
    pt: 'pt', // Portuguese => Portugal
    qu: null,
    rm: null,
    rn: null,
    ro: 'ro', // Romanian => Romania
    ru: 'ru', // Russian => Russia
    rw: null,
    sa: null,
    sc: null,
    sd: null,
    se: null,
    sg: null,
    si: null,
    sk: 'sk', // Slovak => Slovakia
    sl: 'si', // Slovenian => Slovenia
    sm: null,
    sn: null,
    so: null,
    sq: 'al', // Albanian => Albania
    sr: 'rs', // Serbian => Serbia
    ss: null,
    st: null,
    su: null,
    sv: 'se', // Swedish => Sweden
    sw: null,
    ta: null,
    te: null,
    tg: 'tj', // Tajik => Tajikistan
    th: 'th', // Thai => Thailand
    ti: null,
    tk: 'tm', // Turkmen =>  Turkmenistan
    tl: 'ph', // Tagalog => Philippines
    tn: null,
    to: null,
    tr: 'tr', // Turkish => Turkey
    ts: null,
    tt: null,
    tw: null,
    ty: null,
    ug: null,
    uk: 'ua', // Ukrainian => Ukraine
    ur: 'pk', // Urdu => Pakistan
    uz: 'uz', // Uzbek => Uzbekistan
    ve: null,
    vi: 'vn', // Vietnamese => Vietnam
    vo: null,
    wa: null,
    wo: null,
    xh: null,
    yi: null,
    yo: null,
    za: null,
    zh: 'cn', // Chinese => China
    zu: null,
} as const;
/* eslint-enable id-length */
