import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

export type ReviewReplierType = {
    email: string;
    first_name: string | null;
    full_name: string | null;
    id: number;
    last_name: string | null;
};

type ReviewReplierArrayType = {
    results: Array<ReviewReplierType>;
};

const reviewsReviewsReplierListUrl = `${rootApiUrl}/v4/reviews/repliers/`;

function fetchReviewsReplierList(): Promise<ReviewReplierArrayType> {
    return fetchX<ReviewReplierArrayType>(reviewsReviewsReplierListUrl);
}

export function useReviewsReplierList(): UseQueryResult<ReviewReplierArrayType> {
    return useQuery([reviewsReviewsReplierListUrl], fetchReviewsReplierList, {
        refetchOnWindowFocus: false,
    });
}
