import {Text} from '../../../../../component/text/text';

import * as styles from './dashboard-last-posts-statistics.scss';

type PropsType = {
    count: number;
};

export function DashboardLastPostsStatistics(props: PropsType): JSX.Element {
    const {count} = props;

    return (
        <div className={styles.DashboardLastPostsStatistics}>
            <div>
                <Text block bolder stringKey="DASHBOARD_PAGE__POSTS__SENT_PUBLICATIONS" />
                <Text block lighter small stringKey="DASHBOARD_PAGE__POSTS__LAST_PERIOD" />
            </div>
            <Text block bolder className={styles.DashboardLastPostsStatistics_count}>
                {count}
            </Text>
        </div>
    );
}
