import {ReviewRatingTypeEnum, ReviewSortingTypeEnum} from '../../../../service/reviews/reviews-type';
import {
    ComplaintWasLeftEnum,
    ContentOfReviewsEnum,
    DeletedReviewsEnum,
    ModeratedEnum,
    PossibilityOfAnAnswerEnum,
    RepliesToReviewsEnum,
    ReviewChangedEnum,
    ReviewNameEnum,
    ReviewsFilterFieldNameEnum,
    WaitingForSecondAnswerEnum,
    WithoutTagEnum,
} from '../review-list-page-content/reviews-filter/reviews-filter-type';

import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from './reviews-state-type';

export const defaultReviewsState: ReviewsStateType = {
    [ReviewsStateUrlQueryNameEnum.ratingType]: ReviewRatingTypeEnum.allRating,
    [ReviewsStateUrlQueryNameEnum.ids]: '',
    [ReviewsStateUrlQueryNameEnum.companyIds]: '',
    filterState: {
        [ReviewsFilterFieldNameEnum.brandIds]: null,
        [ReviewsFilterFieldNameEnum.sourceList]: [],
        [ReviewsFilterFieldNameEnum.processStatus]: [],
        [ReviewsFilterFieldNameEnum.ratingList]: [],
        [ReviewsFilterFieldNameEnum.employeesResponding]: [],
        [ReviewsFilterFieldNameEnum.employeesResponsible]: [],
        [ReviewsFilterFieldNameEnum.doctorNames]: [],
        [ReviewsFilterFieldNameEnum.tagList]: [],
        [ReviewsFilterFieldNameEnum.withoutTag]: WithoutTagEnum.noSelected,

        [ReviewsFilterFieldNameEnum.autoReplay]: [],
        [ReviewsFilterFieldNameEnum.contentOfReviews]: ContentOfReviewsEnum.noSelected,
        [ReviewsFilterFieldNameEnum.deletedReviews]: DeletedReviewsEnum.noSelected,
        [ReviewsFilterFieldNameEnum.repliesToReviews]: RepliesToReviewsEnum.noSelected,
        [ReviewsFilterFieldNameEnum.possibilityOfAnAnswer]: PossibilityOfAnAnswerEnum.noSelected,
        [ReviewsFilterFieldNameEnum.reviewType]: ReviewNameEnum.noSelected,
        [ReviewsFilterFieldNameEnum.complaintWasLeft]: ComplaintWasLeftEnum.noSelected,
        [ReviewsFilterFieldNameEnum.reviewChanged]: ReviewChangedEnum.noSelected,
        [ReviewsFilterFieldNameEnum.waitingForSecondAnswer]: WaitingForSecondAnswerEnum.noSelected,
        [ReviewsFilterFieldNameEnum.reviewModerated]: ModeratedEnum.noSelected,

        [ReviewsFilterFieldNameEnum.dateFrom]: null,
        [ReviewsFilterFieldNameEnum.dateTo]: null,
    },
    filterId: '',
    [ReviewsStateUrlQueryNameEnum.sorting]: ReviewSortingTypeEnum.acs,
};
