import {useCallback, useEffect, useMemo, useRef} from 'react';

import {InfiniteBrandSelector} from '../../../../../../../../component/brand-select/infinite-brand-selector';
import {useBrands} from '../../../../../../../../provider/brands/brand-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {Form, FormInstance} from '../../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../../post-form-type';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    required: boolean;
};

export function BrandsItem(props: PropsType & PostFormItemPropsType): JSX.Element | null {
    const {formInstance, required, disabled} = props;

    const {requiredFieldRule} = useFormRules();

    const {brands} = useBrands();

    const lastSavedValue = useRef<Array<number>>(formInstance.getFieldValue(PostFormFieldsEnum.Brands));

    const isBrandsSelectVisible = useMemo(() => required && brands.length > 1, [brands, required]);

    const clearBrands = useCallback(() => formInstance.setFieldValue(PostFormFieldsEnum.Brands, []), [formInstance]);

    const selectSingleBrandAutomatically = useCallback(() => {
        const firstBrand = brands[0];

        if (brands && brands.length === 1 && firstBrand) {
            formInstance.setFieldValue(PostFormFieldsEnum.Brands, [firstBrand.id]);
        }
    }, [brands, formInstance]);

    useEffect(() => {
        if (required) {
            selectSingleBrandAutomatically();

            if (lastSavedValue && lastSavedValue.current && lastSavedValue.current.length > 0) {
                formInstance.setFieldValue(PostFormFieldsEnum.Brands, lastSavedValue.current);
            }
        }

        if (!required && !disabled) {
            clearBrands();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [required]);

    if (!isBrandsSelectVisible) {
        return null;
    }

    return (
        <Form.Item<PostFormType>
            label={<Locale stringKey="POSTS_FORM__BRANDS__LABEL" />}
            name={PostFormFieldsEnum.Brands}
            rules={[requiredFieldRule]}
        >
            <InfiniteBrandSelector
                disabled={disabled}
                mode="multiple"
                optionFilterProp="children"
                placeholder={<Locale stringKey="POSTS_FORM__BRANDS__PLACEHOLDER" />}
            />
        </Form.Item>
    );
}
