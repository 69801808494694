import {barChartOptions} from '../../../../layout/chart/bar-chart/bar-chart-helper';
import {BarDataType, ChartJsBarOptionsType} from '../../../../layout/chart/bar-chart/bar-chart-type';
import {gridColor, labelColor} from '../../../../layout/chart/chart-const';
import {WeekDaysEnum} from '../../../../provider/locale/locale-context-const';
import {WorkloadByHoursAndWeekDaysType} from '../../../../service/analytics-workload/analytics-workload-type';

import * as styles from './analytics-workload.scss';

function formatHours(hour: string) {
    return `${hour.padStart(2, '0')}:00`;
}

function getBarColorByValue(value: number, maxValue: number): string {
    const delta = (value * 100) / maxValue;

    if (delta < 25) {
        return styles.bar_color_1;
    }

    if (delta < 50) {
        return styles.bar_color_2;
    }

    if (delta < 75) {
        return styles.bar_color_3;
    }

    return styles.bar_color_4;
}

export function deserializeDataForChartByWeekDay(
    rawChartData: WorkloadByHoursAndWeekDaysType,
    weekDay: WeekDaysEnum
): BarDataType {
    const workloadByWeekDay = rawChartData[weekDay];

    if (!workloadByWeekDay) {
        return {
            labels: [],
            datasets: [],
        };
    }

    const labels: Array<string> = Object.keys(workloadByWeekDay).map(formatHours);

    const maxValue = Math.max(...Object.values(workloadByWeekDay));
    const datasets = [
        {
            label: '',
            values: Object.values(workloadByWeekDay),
            color: Object.values(workloadByWeekDay).map((value) => getBarColorByValue(value, maxValue)),
            borderRadius: 4,
        },
    ];

    return {
        labels,
        datasets,
    };
}

export function getWorkloadChartOptions(): ChartJsBarOptionsType {
    return {
        ...barChartOptions,
        scales: {
            x: {
                type: 'category',
                stacked: true,
                grid: {
                    color: gridColor,
                    display: false,
                    drawTicks: false,
                },
                ticks: {
                    color: labelColor,
                    padding: 10,
                    maxRotation: 0,
                    maxTicksLimit: 8,
                },
            },
            y: {
                stacked: true,
                border: {
                    display: false,
                },
                grid: {
                    color: gridColor,
                    drawTicks: false,
                },
                ticks: {
                    color: labelColor,
                    padding: 10,
                    maxTicksLimit: 10,
                    callback(value) {
                        return Math.floor(Number(value));
                    },
                },
                suggestedMax: 100,
                suggestedMin: 0,
            },
        },
        plugins: {
            ...barChartOptions.plugins,
            legend: {
                display: false,
            },
        },
    };
}
