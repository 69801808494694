import {DomainNameEnum} from '../../../../../../../provider/domain-config/domain-config-type';
import {ShortLocaleNameEnum} from '../../../../../../../provider/locale/locale-context-type';
import {LangKeyType} from '../../../../../../../provider/locale/translation/type';
import {YandexCompanyStatusEnum} from '../../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';

export const yandexActualizationStatusMarkPopoverTitleLangKey: Record<YandexCompanyStatusEnum, LangKeyType> = {
    [YandexCompanyStatusEnum.Actualized]:
        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_TITLE__ACTUALIZED',
    [YandexCompanyStatusEnum.NotActualized]:
        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_TITLE__NOT_ACTUALIZED',
    [YandexCompanyStatusEnum.NeedActualize]:
        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_TITLE__NEED_ACTUALIZATION',
};

export const yandexActualizationStatusMarkPopoverContentLangKey: Record<YandexCompanyStatusEnum, LangKeyType> = {
    [YandexCompanyStatusEnum.Actualized]:
        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_CONTENT__ACTUALIZED',
    [YandexCompanyStatusEnum.NotActualized]:
        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_CONTENT__NOT_ACTUALIZED',
    [YandexCompanyStatusEnum.NeedActualize]:
        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_CONTENT__NEED_ACTUALIZATION',
};

export function getDocumentationLink(locale: ShortLocaleNameEnum, domainName: string): string {
    if (domainName === DomainNameEnum.brandWizard) {
        return 'https://help.brandwizard.io/knowledge/how-to-get-and-keep-the-blue-checkmark-in-yandex';
    }

    if (locale === ShortLocaleNameEnum.ru || locale === ShortLocaleNameEnum.kk) {
        return 'https://yandex.ru/support/business-priority/manage/verified-owner.html';
    }

    return 'https://roketdata.helpdeskeddy.com/en/knowledge_base/art/403/cat/38';
}
