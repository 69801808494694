import {Button, Checkbox, List, Modal, Tooltip, Typography} from 'antd';
import {useCallback, useState} from 'react';

import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {TWO_GIS_DOMAIN} from '../../../../../../provider/domain-config/domain-config-helper';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../../../provider/domain-config/domain-config-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {classNames} from '../../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../../util/type';

import * as styles from './catalog-popup.scss';

type PropsType = {
    catalogs: Array<CatalogConfigType>;
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    onApply: (catalogIds: Array<number>) => void;
};

export function CatalogPopup(props: PropsType): JSX.Element {
    const {catalogs, className, isOpen, onClose, onApply} = props;

    const [selectedCatalogs, setSelectedCatalogs] = useState<Array<number>>([]);
    const {getLocalizedString} = useLocale();
    const {domainName} = useDomainConfig();

    const availableCatalogs = catalogs.filter(({catalogId}) =>
        domainName === DomainNameEnum.twoGis || domainName.includes(TWO_GIS_DOMAIN)
            ? catalogId !== ProvidersIdsEnum.doubleGis
            : true
    );

    function handleClose() {
        setSelectedCatalogs([]);
        onClose();
    }

    function handleApply() {
        onApply(selectedCatalogs);
        setSelectedCatalogs([]);
    }

    const handleCheckboxChange = useCallback(
        (catalog: CatalogConfigType, checked: boolean) => {
            setSelectedCatalogs(
                checked
                    ? selectedCatalogs.filter((selectedId) => selectedId !== catalog.catalogId)
                    : [...selectedCatalogs, catalog.catalogId]
            );
        },
        [selectedCatalogs]
    );

    const renderCatalog = useCallback(
        (catalog: CatalogConfigType) => {
            const checked = selectedCatalogs.includes(catalog.catalogId);

            const listItem = (
                <List.Item
                    className={classNames(
                        styles.catalog_popup__item,
                        catalog.disabled ? styles.catalog_popup__item_disabled : ''
                    )}
                    key={catalog.catalogId}
                >
                    <Checkbox
                        checked={checked}
                        className={classNames(
                            styles.catalog_popup__checkbox,
                            checked ? styles.catalog_popup__checkbox_checked : ''
                        )}
                        disabled={catalog.disabled}
                        onChange={() => handleCheckboxChange(catalog, checked)}
                    >
                        <img alt="" className={styles.catalog_popup__icon} src={catalog.logoUrl} />
                        {catalog.label}
                    </Checkbox>
                </List.Item>
            );

            return catalog.disabled ? (
                <Tooltip
                    placement="bottom"
                    title={getLocalizedString('COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_UNAVAILABLE_IN_COUNTRY')}
                >
                    {listItem}
                </Tooltip>
            ) : (
                listItem
            );
        },
        [getLocalizedString, handleCheckboxChange, selectedCatalogs]
    );

    return (
        <Modal
            className={className}
            footer={[
                <Button key="cancel" onClick={handleClose}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button key="apply" onClick={handleApply} type="primary">
                    <Locale stringKey="BUTTON__ADD" />
                </Button>,
            ]}
            onCancel={handleClose}
            open={isOpen}
            title={<Locale stringKey="COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_TITLE" />}
        >
            <Typography.Text className={styles.catalog_popup__subtitle}>
                <Locale stringKey="COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_SUBTITLE" />
            </Typography.Text>

            <Typography.Paragraph className={styles.catalog_popup__description} type="secondary">
                <Locale stringKey="COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_DESCRIPTION" />
            </Typography.Paragraph>

            <div className={styles.catalog_popup__scroll_area}>
                <List dataSource={availableCatalogs} renderItem={renderCatalog} />
            </div>
        </Modal>
    );
}
