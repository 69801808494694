import {PropsWithChildren} from 'react';

import {classNames} from '../../util/css';

import * as styles from './page-card.scss';

type PropsType = PropsWithChildren<{
    className?: string;
}>;

export function MainPageContainer(props: PropsType): JSX.Element {
    const {children, className} = props;

    return <div className={classNames(styles.main_page__container, className)}>{children}</div>;
}
