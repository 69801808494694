import {Form, Select} from 'antd';

import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {availablePostTypeTranslationMap} from '../../../../../../../../service/posts/posts-hook-const';
import {PostTypeEnum} from '../../../../../../../../service/posts/posts-types';
import {PostFormItemPropsType} from '../../../../post-form-type';

import {PostTypeItemLabelWrapper} from './post-type-item.scss';

type PropsType = {
    fieldName: Array<string | number>;
    showEditHelper: boolean;
} & PostFormItemPropsType;

export function PostTypeItem(props: PropsType): JSX.Element {
    const {fieldName, showEditHelper, disabled} = props;

    return (
        <Form.Item
            initialValue={PostTypeEnum.standard}
            label={
                <div className={PostTypeItemLabelWrapper}>
                    <Locale stringKey="POSTS_FORM__TYPE__LABEL" />
                    {showEditHelper && (
                        <AdditionalInfo title={<Locale stringKey="POSTS_FORM__TYPE__LABEL" />}>
                            <Locale stringKey="POSTS_EDIT__TOPIC_TYPE__DESCRIPTION" />
                        </AdditionalInfo>
                    )}
                </div>
            }
            name={fieldName}
            required
        >
            <Select<PostTypeEnum> disabled={disabled} filterOption={() => true}>
                {Object.values(PostTypeEnum).map((postTypeItem: PostTypeEnum) => (
                    <Select.Option key={postTypeItem} value={postTypeItem}>
                        <Locale stringKey={availablePostTypeTranslationMap[postTypeItem]} />
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
