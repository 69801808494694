import {z as r} from 'zod';

import {ReviewsAnalysisModeEnum} from '../reivew-analysis-const';
import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const reviewAnalysisRadarSchema = r.object({
    brandId: r.number(),
    total: r.number(),
    avgRating: r.number(),
    companiesTotal: r.number(),
    replied: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
});

export type ReviewAnalysisRadarType = r.infer<typeof reviewAnalysisRadarSchema>;

export const reviewAnalysisRadarItemsSchema = r.array(reviewAnalysisRadarSchema);

export type ReviewsAnalysisRadarQueryType = {
    mode?: ReviewsAnalysisModeEnum;
    brandIds?: Array<number>;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    userTopics?: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};
