import {Text} from '../../../../component/text/text';
import {Logo} from '../../../../layout/logo/logo';
import {classNames} from '../../../../util/css';

import * as styles from './auth-form-header.scss';

type PropsType = {
    title?: JSX.Element;
    subtitle?: JSX.Element;
    className?: string;
};

export function AuthFormHeader(props: PropsType): JSX.Element {
    const {title, subtitle, className} = props;

    return (
        <header className={classNames(styles.AuthFormHeader, className)}>
            <Logo className={styles.AuthFormHeader_logo} />

            {(title || subtitle) && (
                <hgroup className={styles.AuthFormHeader_hgroup}>
                    {title && <h3 className={styles.AuthFormHeader_title}>{title}</h3>}

                    {subtitle && <Text lighter>{subtitle}</Text>}
                </hgroup>
            )}
        </header>
    );
}
