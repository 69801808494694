import {faBuilding} from '@fortawesome/pro-solid-svg-icons';
import {useMutation} from '@tanstack/react-query';
import {Button, Checkbox, ConfigProvider, Empty, List} from 'antd';
import DOMPurify from 'dompurify';
import {difference, uniq} from 'lodash';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {GoogleAccountTypeEnum} from '../../../../../../service/google/accounts/google-accounts-type';
import {useGoogleCompanies} from '../../../../../../service/google/google-hook';
import {postGoogleProcessingStart} from '../../../../../../service/google/processing/google-processing-api';
import {Form} from '../../../../../../typings/antd';
import {useIsMounted} from '../../../../../../util/is-mounted';
import {useUrl, useUrlQueryNumberArray} from '../../../../../../util/url-hook/url-hook';
import {StepHeader} from '../../common/step-header/step-header';
import * as stepStyles from '../../common/step-styles/step-styles.scss';
import {GoogleAccountSyncContext} from '../../google-account-sync-context';
import {useGoogleAccount} from '../../google-account-sync-context-hook';
import {GoogleAccountSyncUrlParameterEnum, GoogleAccountSyncUrlType} from '../../google-account-sync-type';

import {CompaniesListHeader} from './companies-list-header/companies-list-header';
import {FilterFormEnum, initialValues, PERSONAL_ACCOUNT_COMPANIES_LIMIT} from './companies-step-const';
import {getConfirmationModalConfig, getGoogleProcessingStartErrors} from './companies-step-helper';
import {FilterFormType} from './companies-step-type';
import {CompanyListRadioItem} from './company-list-radio-item/company-list-radio-item';
import {Filters} from './filters/filters';
import {GroupRequiredModal} from './group-required-modal/group-required-modal';
import * as styles from './companies-step.scss';

type PropsType = {
    onFinish: () => void;
    onGoBack: () => void;
};

// eslint-disable-next-line max-statements
export function CompaniesStep(props: PropsType): JSX.Element {
    const {onFinish, onGoBack} = props;

    const {getQuery, replaceQuery} = useUrl<GoogleAccountSyncUrlType>();
    const brandsIdsQuery = useUrlQueryNumberArray<GoogleAccountSyncUrlType>(
        GoogleAccountSyncUrlParameterEnum.BrandsIds
    );

    const {accountId, setStatus, setProcessingKey, setHeaderButtons} = useContext(GoogleAccountSyncContext);
    const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<Array<number>>([]);
    const [checkboxesDisabled, setCheckboxesDisabled] = useState(false);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const account = useGoogleAccount();
    const isMounted = useIsMounted();
    const {modal} = useModal();
    const {snackbar} = useSnackbar();
    const {companyName} = useDomainConfig();

    const filtersValues = useMemo(
        () => ({
            ...initialValues,
            [FilterFormEnum.Search]: DOMPurify.sanitize(getQuery(GoogleAccountSyncUrlParameterEnum.Search) ?? ''),
            [FilterFormEnum.BrandsIds]: brandsIdsQuery,
        }),
        [brandsIdsQuery, getQuery]
    );

    const [form] = Form.useForm<FilterFormType>();
    const search = Form.useWatch(FilterFormEnum.Search, form) ?? filtersValues[FilterFormEnum.Search];
    const brandsIds = Form.useWatch(FilterFormEnum.BrandsIds, form) ?? filtersValues[FilterFormEnum.BrandsIds];

    const {
        isInitialLoading,
        results: companies,
        pagination: {page, pageSize, total, onChange},
    } = useGoogleCompanies({
        search,
        brandsIds,
        onSuccess: (data, companyId) => {
            if (!isMounted.current) {
                return;
            }

            if (companyId && data.results[0]?.id === companyId && selectedCompaniesIds.length === 0 && !isSelectAll) {
                setSelectedCompaniesIds([companyId]);
            }
        },
    });

    const {mutate} = useMutation({
        mutationFn: () =>
            postGoogleProcessingStart({
                accountId,
                companiesIds: selectedCompaniesIds,
                bindAll: isSelectAll,
                toExclude: isSelectAll ? selectedCompaniesIds : [],
                brandsIds,
                search,
            }),
        onSuccess: (data) => {
            setProcessingKey(data.key ?? null);
            setStatus(data.status);
            onFinish();
        },
        onError: (error) => {
            const errors = getGoogleProcessingStartErrors(error);

            if (errors.length > 0) {
                snackbar.error({
                    message: errors.map((message) => <div key={message}>{message}</div>),
                    duration: null,
                });
            }
        },
    });

    const selectedCheckboxes = useMemo(() => {
        if (!isSelectAll) {
            return selectedCompaniesIds;
        }

        return difference(
            (companies ?? []).map(({id}) => id),
            selectedCompaniesIds
        );
    }, [companies, isSelectAll, selectedCompaniesIds]);

    const selectedTotal = useMemo(
        () => (isSelectAll ? total - selectedCompaniesIds.length : selectedCompaniesIds.length),
        [isSelectAll, selectedCompaniesIds.length, total]
    );

    const handleFinish = useCallback(() => {
        if (selectedTotal === 0) {
            return;
        }

        modal.confirm(getConfirmationModalConfig(selectedTotal, () => mutate()));
    }, [modal, mutate, selectedTotal]);

    useEffect(() => {
        replaceQuery({[GoogleAccountSyncUrlParameterEnum.Search]: search});
    }, [search, replaceQuery]);

    useEffect(() => {
        replaceQuery({[GoogleAccountSyncUrlParameterEnum.BrandsIds]: brandsIds.join(',')});
    }, [brandsIds, replaceQuery]);

    useEffect(() => {
        setHeaderButtons(() => (
            <>
                <Button onClick={onGoBack}>
                    <Locale stringKey="BUTTON__BACK" />
                </Button>

                <Button disabled={selectedTotal === 0} onClick={() => handleFinish()} type="primary">
                    <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__START_SYNC" />
                </Button>
            </>
        ));
    }, [handleFinish, onGoBack, selectedTotal, setHeaderButtons]);

    function handleCheckboxGroupChange(checkedValues: Array<number>) {
        const shownCompaniesIds = (companies ?? []).map(({id}) => id);

        const selectedIds = uniq([
            ...difference(selectedCompaniesIds, shownCompaniesIds),
            ...checkedValues.map(Number),
        ]);

        if (
            account?.accountType === GoogleAccountTypeEnum.Personal &&
            selectedIds.length === PERSONAL_ACCOUNT_COMPANIES_LIMIT
        ) {
            setCheckboxesDisabled(true);
        } else if (checkboxesDisabled) {
            setCheckboxesDisabled(false);
        }

        setSelectedCompaniesIds(isSelectAll ? difference(shownCompaniesIds, selectedIds) : selectedIds);
    }

    function selectAll() {
        setIsSelectAll(true);
        setSelectedCompaniesIds([]);
    }

    function deselectAll() {
        setIsSelectAll(false);
        setSelectedCompaniesIds([]);
    }

    function handleGoBack() {
        setIsModalOpen(false);
        onGoBack();
    }

    return (
        <>
            <GroupRequiredModal onCancel={() => setIsModalOpen(false)} onOk={handleGoBack} open={isModalOpen} />

            <StepHeader
                alertIcon={faBuilding}
                alertMessage="GOOGLE_SYNC__COMPANIES_STEP__ALERT__SELECT_COMPANIES"
                alertMessageValueMap={{companyName}}
                title="GOOGLE_SYNC__COMPANIES_STEP__TITLE"
            />

            <Filters form={form} initialValues={filtersValues} />

            <Checkbox.Group
                className={stepStyles.ItemGroup}
                onChange={handleCheckboxGroupChange}
                value={selectedCheckboxes}
            >
                <ConfigProvider
                    renderEmpty={() => (
                        <Empty
                            className={styles.Empty}
                            description={<Locale stringKey="EMPTY__DESCRIPTION" />}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            imageStyle={{height: 117}}
                        />
                    )}
                >
                    <List
                        className={stepStyles.List}
                        dataSource={companies ?? []}
                        header={
                            <CompaniesListHeader
                                deselectAll={deselectAll}
                                hasFilters={search?.length > 0 || brandsIds?.length > 0}
                                hideSelectAll={account?.accountType === GoogleAccountTypeEnum.Personal}
                                selectAll={selectAll}
                                selected={selectedTotal}
                                total={isInitialLoading ? 0 : total}
                            />
                        }
                        loading={isInitialLoading}
                        pagination={{current: page, pageSize, total, hideOnSinglePage: true, onChange}}
                        renderItem={(company) => (
                            <CompanyListRadioItem
                                company={company}
                                disabled={checkboxesDisabled && !selectedCompaniesIds.includes(company.id)}
                                onDisabledClick={() => setIsModalOpen(true)}
                            />
                        )}
                        size="small"
                    />
                </ConfigProvider>
            </Checkbox.Group>

            <div className={stepStyles.Navigation}>
                <Button disabled={selectedTotal === 0} onClick={handleFinish} type="primary">
                    <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__START_SYNC" />
                </Button>
            </div>
        </>
    );
}
