import {DoughnutChart} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart';
import {DoughnutDataType} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    ConcordanceSentimentEnum,
    ReviewConcordanceSentimentType,
} from '../../../../../../service/reivew-analysis/review-concordance/review-concordance-type';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {getRangeFilterTagFormat} from '../../../reviews-analysis-helper';
import {ConcordanceSentimentTooltipEnum} from '../concordance-page-type';

import {getConcordanceChartOptions} from './concordance-chart-helper';
import * as styles from './concordance-chart.scss';

type PropsType = {
    data: ReviewConcordanceSentimentType;
    period: [Date | null, Date | null];
};

const concordanceSentimentEnumValues = Object.values(ConcordanceSentimentEnum);

export function ConcordanceChart(props: PropsType): JSX.Element {
    const {data, period} = props;

    const {getLocalizedString, localeName} = useLocale();
    const {getFormattedNumber} = useFormat();

    const formattedPeriod = getRangeFilterTagFormat(localeName, period);

    const count = Object.values(data).reduce((accumulator, sentiment) => accumulator + sentiment, 0);

    const concordanceChartData: Array<DoughnutDataType> = concordanceSentimentEnumValues.map((sentiment) => ({
        color: styles[`ChartColor__${sentiment}`],
        label: getLocalizedString(ConcordanceSentimentTooltipEnum[sentiment]),
        value: data[sentiment],
    }));

    return (
        <div>
            <span className={styles.ConcordanceChart_title}>
                <Locale
                    stringKey="REVIEWS_ANALYSIS__CONCORDANCE__SENTIMENT_CHART__PERIOD"
                    valueMap={{period: formattedPeriod}}
                />{' '}
                <Locale
                    stringKey="REVIEWS_ANALYSIS__CONCORDANCE__SENTIMENT_CHART__COUNT"
                    valueMap={{
                        count: getFormattedNumber(count),
                    }}
                />
            </span>
            <div className={styles.ConcordanceChart_data}>
                <div className={styles.ConcordanceChart_chart}>
                    <DoughnutChart
                        chartOptions={getConcordanceChartOptions(
                            {
                                circumference: 180,
                                rotation: -90,
                                cutout: 0,
                            },
                            {
                                data: concordanceChartData,
                                title: formattedPeriod,
                            }
                        )}
                        data={concordanceSentimentEnumValues.map((sentiment) => ({
                            color: styles[`ChartColor__${sentiment}`],
                            label: getLocalizedString(ConcordanceSentimentTooltipEnum[sentiment]),
                            value: data[sentiment],
                        }))}
                        normalized
                    />
                </div>

                {concordanceSentimentEnumValues
                    .filter((sentiment) => data[sentiment])
                    .map((sentiment) => {
                        return (
                            <div key={sentiment}>
                                <span className={styles.ConcordanceChart_statisticsTitle}>
                                    <Locale stringKey={ConcordanceSentimentTooltipEnum[sentiment]} />
                                </span>
                                <div
                                    className={classNames(
                                        styles.ConcordanceChart_statisticsMarker,
                                        styles[`ConcordanceChart_statisticsMarker__${sentiment}`]
                                    )}
                                />
                                <span className={styles.ConcordanceChart_statisticsCount}>
                                    <span className={styles.ConcordanceChart_statisticsValue}>{data[sentiment]}</span>
                                    &nbsp; ({((data[sentiment] / count) * 100).toFixed(2)}%)
                                </span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
