import {Button} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {CatalogsSyncCatalogStatsType} from '../../../../../service/dashboard/dashboard-type';

import {CatalogSyncProgressBar} from './catalog-sync-progress-bar';
import {viewSyncCardNumber} from './sync-cards-consts';
import * as styles from './sync-cards.scss';

type PropsType = {
    cardsData: Array<CatalogsSyncCatalogStatsType>;
};

export function SyncCards(props: PropsType): JSX.Element {
    const {cardsData} = props;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const collapseButtonText = isCollapsed
        ? 'DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__SHOW_TEXT'
        : 'DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__HIDE_TEXT';

    const slicedCardsData = isCollapsed ? cardsData.slice(0, viewSyncCardNumber) : cardsData;

    return (
        <>
            <div className={styles.sync_card__wrapper}>
                {slicedCardsData.map((dataEntry: CatalogsSyncCatalogStatsType) => {
                    return (
                        <CatalogSyncProgressBar
                            catalogId={dataEntry.id}
                            key={dataEntry.fieldName}
                            percent={Math.floor(dataEntry.value)}
                        />
                    );
                })}
            </div>
            <Button block className={styles.collapsed_button} onClick={() => setIsCollapsed(!isCollapsed)} type="text">
                <span className={styles.collapsed_button_text}>
                    <Locale stringKey={collapseButtonText} valueMap={{count: String(cardsData.length)}} />
                </span>
            </Button>
        </>
    );
}
