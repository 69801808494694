import {ConfirmationSourcesMethodEnum} from '../../../../../service/confirmation/confirmation-type';

export const enum GetCodeFormFieldEnum {
    Method = 'method',
    Phone = 'phone',
    AdditionalNumber = 'additional number',
    ContactPerson = 'contact person',
}

export type YandexConfirmationCodeFormFieldType = {
    [GetCodeFormFieldEnum.Method]: ConfirmationSourcesMethodEnum;
    [GetCodeFormFieldEnum.Phone]: string;
    [GetCodeFormFieldEnum.AdditionalNumber]?: string;
    [GetCodeFormFieldEnum.ContactPerson]?: string;
};
