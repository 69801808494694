import {ExtractRouteParams} from 'react-router';
import {generatePath, useParams} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {
    defaultAutoReplyToReviewData,
    useAutoReplyToReviewById,
} from '../../../service/reviews/reviews-response-templates';
import {AutoReplyForm} from '../auto-reply-form/auto-reply-form';
import {AutoReplyFormSubHeader} from '../auto-reply-form/auto-reply-form-sub-header';

export function AutoReplyEdit(): JSX.Element {
    const {
        reviewsManagementAutoReplies: reviewsManagementAutoRepliesRoute,
        reviewsManagementAutoReplyEdit: reviewsManagementAutoReplyEditRoute,
    } = appRoute;

    const {autoReplyId} = useParams<ExtractRouteParams<typeof reviewsManagementAutoReplyEditRoute.path, string>>();
    const {data: autoReplyResult, isFetching: autoReplyIsInProgress} = useAutoReplyToReviewById(autoReplyId);
    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('AUTO_REPLIES__HEADER__EDITING_A_RULE')} />

            <BreadCrumbs
                list={[
                    {
                        path: reviewsManagementAutoRepliesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__AUTO_REPLIES',
                    },
                    {
                        path: generatePath(reviewsManagementAutoReplyEditRoute.path, {autoReplyId}),
                        titleLangKey: 'AUTO_REPLIES__HEADER__EDITING_A_RULE',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="AUTO_REPLIES__HEADER__EDITING_A_RULE" />
            </PageHeader>

            <AutoReplyFormSubHeader isEdit />

            <AutoReplyForm
                autoReplyData={autoReplyResult || defaultAutoReplyToReviewData}
                autoReplyId={autoReplyId}
                mode="edit"
            />

            <Spinner isShow={autoReplyIsInProgress} position="absolute" />
        </Page>
    );
}
