import {DefinedUseQueryResult, useQuery} from '@tanstack/react-query';
import {z as r} from 'zod';

import {useLocale} from '../../provider/locale/locale-hook';
import {fetchAndDeserialize} from '../../util/api-adapter';
import {sortCompare} from '../../util/string';

import {PhotoSourceNameEnum} from './phototool-sources-helper';

export const photoSourceSchema = r.object({
    id: r.number(),
    name: r.nativeEnum(PhotoSourceNameEnum),
});

export type PhotoSourceType = r.infer<typeof photoSourceSchema>;

const photoSourcesUrl = `/phototool/v1/sources/`;

function fetchPhotoSources() {
    return fetchAndDeserialize<Array<PhotoSourceType>>(photoSourcesUrl, r.array(photoSourceSchema));
}

export function usePhotoSources(): DefinedUseQueryResult<Array<PhotoSourceType>> {
    const {shortLocaleName} = useLocale();

    return useQuery([photoSourcesUrl], () => fetchPhotoSources(), {
        initialData: [],
        select: (sources) =>
            sources
                .map((source) => ({
                    ...source,
                }))
                .sort((first, second) => sortCompare(shortLocaleName, first.name, second.name)),
    });
}
