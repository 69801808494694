import {Checkbox, Form} from 'antd';
import {PropsWithChildren} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {ReviewsAnalysisFilterEnum} from '../../../reviews-analysis-type';

import * as styles from './compare-form-field.scss';

type PropsType = PropsWithChildren<{
    id?: string;
    className: string;
    isActive: boolean;
    isHidden?: boolean;
    name: ReviewsAnalysisFilterEnum;
    stringKey: LangKeyType;
    onToggle: (isActive: boolean) => void;
}>;

export function CompareFormField(props: PropsType): JSX.Element | null {
    const {children, className, id, isActive, isHidden, name, stringKey, onToggle} = props;

    if (isHidden) {
        return null;
    }

    return (
        <>
            {!isActive && (
                <div className={styles.compare_form_field__checkbox} id={id}>
                    <Checkbox checked={false} id={id} onClick={() => onToggle(!isActive)}>
                        <Locale stringKey={stringKey} />
                    </Checkbox>
                </div>
            )}

            {isActive && (
                <Form.Item
                    className={className}
                    id={id}
                    label={
                        <Checkbox checked onClick={() => onToggle(!isActive)}>
                            <Locale stringKey={stringKey} />
                        </Checkbox>
                    }
                    name={name}
                >
                    {children}
                </Form.Item>
            )}
        </>
    );
}
