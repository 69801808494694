import {classNames} from '../../../../../../util/css';

import {ReactComponent as LogoShortSvg} from './image/logo-short.svg';
import * as styles from './logo-short.scss';

type PropsType = {
    className?: string;
};

export function LogoShort(props: PropsType): JSX.Element {
    const {className} = props;

    const fullClassName = classNames(styles.logo_short, className);

    return <LogoShortSvg className={fullClassName} />;
}
