import {Radio, RadioChangeEvent} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {Form} from '../../../../../../../typings/antd';
import {AttributePropsType, BooleanAttributeType} from '../attributes-type';

import {booleanAttributeFalse, booleanAttributeTrue, booleanAttributeUnknown} from './boolean-attribute-const';

export function BooleanGoogleAttribute({
    value,
    onChange,
}: AttributePropsType<BooleanAttributeType>): JSX.Element | null {
    const {displayName: label, values} = value;

    function handleOnChange(event: RadioChangeEvent) {
        onChange?.({
            ...value,
            values: values.map((innerValue) => ({
                ...innerValue,
                selected: innerValue.templateId === event.target.value,
            })),
        });
    }

    return (
        <Form.Item label={label}>
            <Radio.Group onChange={handleOnChange} value={values.find((item) => item.selected)?.templateId}>
                {values.some(({templateId}) => templateId === booleanAttributeTrue) && (
                    <Radio.Button value={booleanAttributeTrue}>
                        <Locale stringKey="TEXT__YES" />
                    </Radio.Button>
                )}

                {values.some(({templateId}) => templateId === booleanAttributeFalse) && (
                    <Radio.Button value={booleanAttributeFalse}>
                        <Locale stringKey="TEXT__NO" />
                    </Radio.Button>
                )}

                <Radio.Button value={booleanAttributeUnknown}>
                    <Locale stringKey="COMPANY_FORM__ATTRIBUTES__BOOLEAN__UNKNOWN" />
                </Radio.Button>
            </Radio.Group>
        </Form.Item>
    );
}
