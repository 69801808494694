export const enDateTimeTranslations = {
    WEEK_DAY__MONDAY: 'Monday',
    WEEK_DAY__TUESDAY: 'Tuesday',
    WEEK_DAY__WEDNESDAY: 'Wednesday',
    WEEK_DAY__THURSDAY: 'Thursday',
    WEEK_DAY__FRIDAY: 'Friday',
    WEEK_DAY__SATURDAY: 'Saturday',
    WEEK_DAY__SUNDAY: 'Sunday',
    WEEK_DAY__ALL_DAYS: 'All days',
    WEEK_DAY__EVERYDAY: 'Daily',
    WEEK_DAY__ON_WEEKDAYS: 'Weekdays',
    WEEK_DAY__WEEKEND: 'Weekends',

    WEEK_DAY__SHORT__MONDAY: 'Mon',
    WEEK_DAY__SHORT__TUESDAY: 'Tue',
    WEEK_DAY__SHORT__WEDNESDAY: 'Wed',
    WEEK_DAY__SHORT__THURSDAY: 'Thu',
    WEEK_DAY__SHORT__FRIDAY: 'Fri',
    WEEK_DAY__SHORT__SATURDAY: 'Sat',
    WEEK_DAY__SHORT__SUNDAY: 'Sun',

    MONTH_NAME__SHORT__JANUARY: 'Jan',
    MONTH_NAME__SHORT__FEBRUARY: 'Feb',
    MONTH_NAME__SHORT__MARCH: 'Mar',
    MONTH_NAME__SHORT__APRIL: 'Apr',
    MONTH_NAME__SHORT__MAY: 'May',
    MONTH_NAME__SHORT__JUNE: 'Jun',
    MONTH_NAME__SHORT__JULE: 'Jul',
    MONTH_NAME__SHORT__AUGUST: 'Aug',
    MONTH_NAME__SHORT__SEPTEMBER: 'Sep',
    MONTH_NAME__SHORT__OCTOBER: 'Oct',
    MONTH_NAME__SHORT__NOVEMBER: 'Nov',
    MONTH_NAME__SHORT__DECEMBER: 'Dec',
};
