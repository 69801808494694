import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {PageCardDivider} from '../../../../../component/page-card-divider/page-card-divider';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {
    useCompanyRatingDynamics,
    useRequestRatingsDynamicsExport,
} from '../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-hook';

import {RatingHistoryFilter} from './rating-history-filter/rating-history-filter';
import {RatingHistoryTable} from './rating-history-table/rating-history-table';
import * as styles from './rating-history-page.scss';

export function RatingHistoryPage(): JSX.Element {
    const {filter, updateFilter, updateSelectedDates, updateDatesPreset, query, pagination} =
        useCompanyRatingDynamics();

    const {mutateAsync, isLoading: isRequestingReport} = useRequestRatingsDynamicsExport(filter);

    const {data, isLoading} = query || {};
    const {getLocalizedString} = useLocale();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__RATINGS')} />

                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.reviewsGeneratorQrCode.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                        },
                        {
                            path: appRoute.reviewAnalysisGeneratorRatings.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__RATINGS',
                        },
                    ]}
                />

                <PageHeader className={styles.RatingHistoryPage_header}>
                    <PageHeaderTitle
                        productName={UsetifulNameProductEnum.REVIEWS_ANALYSIS__CARD_RATING}
                        title="CATEGORY_NAME__REVIEWS_ANALYSIS__RATINGS"
                    />
                    <Button
                        className={styles.RatingHistoryPage_downloadButton}
                        disabled={isRequestingReport}
                        icon={<FontAwesomeIcon icon={faDownload} />}
                        loading={isRequestingReport}
                        onClick={() => mutateAsync()}
                        target="_blank"
                    >
                        <Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__DOWNLOAD_REPORT" />
                    </Button>
                </PageHeader>

                <PageCardDivider />

                <RatingHistoryFilter
                    filter={filter}
                    updateDatesPreset={updateDatesPreset}
                    updateSelectedDates={updateSelectedDates}
                />
            </PageCard>

            <PageCard>
                <RatingHistoryTable
                    data={data?.results || []}
                    filter={filter}
                    isFetchingData={isLoading}
                    pagination={pagination}
                    updateFilter={updateFilter}
                />
            </PageCard>
        </Page>
    );
}
