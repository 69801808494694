import {CursorControls} from './cursor-controls/cursor-controls';
import {CursorControlsPayloadType} from './cursor-controls/cursor-controls-type';
import {PageSizeSelect} from './page-size/page-size-select';
import * as styles from './pagination-controls.scss';

type PropsType = {
    onCursorChange: (cursor: string | null) => void;
    onPageSizeChange: (pageSize: number) => void;
    pageSize: number;
    payload: CursorControlsPayloadType | null;
};

export function PaginationControls(props: PropsType): JSX.Element {
    const {onCursorChange, onPageSizeChange, payload, pageSize} = props;

    return (
        <div className={styles.PaginationControls}>
            <CursorControls
                className={styles.PaginationControls_controls}
                onChange={onCursorChange}
                payload={payload}
            />
            <PageSizeSelect onChange={onPageSizeChange} pageSize={pageSize} />
        </div>
    );
}
