import {faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import {faChartColumn} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Row} from 'antd';
import {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';

import {appRoute} from '../../../app-route';
import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {PageHeaderWithLicenseTag} from '../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {LicensePageCard} from '../../../component/license-page-card/license-page-card';
import {Meta} from '../../../component/meta/meta';
import {PageCardDivider} from '../../../component/page-card-divider/page-card-divider';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLicenses} from '../../../provider/license/license-hook';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {
    useOnlinePresenceFormattedLabels,
    useOnlinePresenceV2Dashboard,
    useOnlinePresenceV2Filter,
} from '../../../service/online-presence/online-presence-hook';
import {FeaturesEnum} from '../../../service/user/user-type';

import {ExportExcelAnalytics} from './components/export-excel-analytics/export-excel-analytics';
import {ImpressionsDynamic} from './impressions-dynamic/impressions-dynamic';
import {OnlinePresenceFilterV2} from './online-presence-filter-v2/online-presence-filter-v2';
import {OnlinePresenceAnalyticsTemporaryProblems} from './online-presence-warning/online-presence-ananytics-wgis-problems';
import {OnlinePresenceDashboardPdfContent} from './pdf-content/online-presence-dashboard-pdf-content';
import {PrimaryIndicators} from './primary-indicators/primary-indicators';
import {TargetActionsDynamics} from './target-actions-dynamics/target-actions-dynamics';
import * as styles from './online-presence.scss';

export function OnlinePresenceDashboard(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {filter, isCompareMode, dispatchFilter, formattedPeriods} = useOnlinePresenceV2Filter();
    const {result, isInProgress, processError} = useOnlinePresenceV2Dashboard(filter);
    const {labels, comparedLabels} = useOnlinePresenceFormattedLabels(result?.labels, result?.comparedLabels);

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.onlinePresenceAnalytics];

    const isLicenseExpired = !license?.isActive;

    const pdfContentRef = useRef<HTMLDivElement | null>(null);

    const handlePrintClick = useReactToPrint({
        content: () => pdfContentRef.current,
    });

    return (
        <Page renderContainer={(pageContent) => <MainPageContainer>{pageContent}</MainPageContainer>}>
            <Meta title={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS')} />
            <PageCard>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.onlinePresenceDashboard.path,
                            titleLangKey: 'ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS',
                        },
                    ]}
                />

                <PageHeaderWithLicenseTag
                    className={styles.OnlinePresence_header}
                    headerLangKey="ONLINE_PRESENCE_ANALYTICS__TITLE"
                    licenseActive={license?.isActive}
                    productName={UsetifulNameProductEnum.ONLINE_PRESENCE__DASHBOARD}
                    trialLicense={license?.isTrial}
                >
                    <Row gutter={[12, 12]}>
                        <Col>
                            <Button
                                disabled={!result || isInProgress || isLicenseExpired}
                                icon={<FontAwesomeIcon icon={faFilePdf} />}
                                onClick={() => {
                                    handlePrintClick();
                                    track(AnalyticsTarget.OnlinePresence.DownloadReport, 'pdf');
                                }}
                            >
                                <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_PDF" />
                            </Button>
                        </Col>
                        {!isCompareMode && (
                            <Col>
                                <ExportExcelAnalytics filter={filter} isDisable={isLicenseExpired} />
                            </Col>
                        )}
                    </Row>
                </PageHeaderWithLicenseTag>

                <PageCardDivider />
                <OnlinePresenceFilterV2
                    dispatchFilter={dispatchFilter}
                    filter={filter}
                    isDisabled={isInProgress || isLicenseExpired}
                />

                <OnlinePresenceAnalyticsTemporaryProblems />
            </PageCard>

            {processError && <AlertFallback />}

            {isInProgress && <Spinner />}

            <LicensePageCard
                icon={<FontAwesomeIcon icon={faChartColumn} />}
                license={license}
                productLandKey="CATEGORY_NAME__ONLINE_PRESENCE"
            />

            {!isLicenseExpired && (
                <>
                    {result && (
                        <Row gutter={[24, 24]}>
                            <Col className={styles.OnlinePresence_column} xl={12} xs={24}>
                                <PrimaryIndicators
                                    data={result.base}
                                    formattedPeriods={formattedPeriods}
                                    isCompareMode={isCompareMode}
                                />

                                <ImpressionsDynamic
                                    comparePeriodData={result.impressionsDynamic.compared}
                                    comparedLabels={comparedLabels}
                                    isCompareMode={isCompareMode}
                                    labels={labels}
                                    mainPeriodData={result.impressionsDynamic.current}
                                />
                            </Col>
                            <Col className={styles.OnlinePresence_column} xl={12} xs={24}>
                                <TargetActionsDynamics
                                    comparedLabels={comparedLabels}
                                    data={result.actionsDynamic}
                                    formattedPeriods={formattedPeriods}
                                    isCompareMode={isCompareMode}
                                    labels={labels}
                                />
                            </Col>
                        </Row>
                    )}

                    {result && (
                        <div className={styles.OnlinePresence_pdfContent}>
                            <OnlinePresenceDashboardPdfContent
                                filter={filter}
                                isCompareMode={isCompareMode}
                                ref={pdfContentRef}
                                result={result}
                            />
                        </div>
                    )}
                </>
            )}
        </Page>
    );
}
