import {Form, Select} from 'antd';

import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FeedFormKeyEnum, FeedFormType} from '../feed-form-type';

export function FormatFormItem(): JSX.Element | null {
    const {feedTypeLabels} = useDomainConfig();

    if (!feedTypeLabels) {
        return null;
    }

    return (
        <Form.Item<FeedFormType>
            initialValue={Object.keys(feedTypeLabels)[0]}
            label={<Locale stringKey="FEED__FORM__FORMAT__LABEL" />}
            name={FeedFormKeyEnum.Format}
        >
            <Select options={Object.entries(feedTypeLabels).map(([value, label]) => ({value, label}))} size="large" />
        </Form.Item>
    );
}
