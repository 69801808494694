import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Flex, Space, Typography} from 'antd';
import {ReactElement, useMemo, useState} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {gray7} from '../../../../../../../../css/var-export.scss';
import {Locale} from '../../../../../../../../provider/locale/locale';

type PropsType = {
    action: string;
    formattedDate: string | null;
    formattedTime: string | null;
    userName: string;
    value: string;
};

export function AbuseLog(props: PropsType): ReactElement {
    const {formattedDate, formattedTime, action, userName, value} = props;

    const [expanded, setExpanded] = useState(false);

    const abuseLocaleString = useMemo(() => {
        if (action === 'Created') {
            return 'REVIEWS__REVIEW_ABUSE_CREATED_BY';
        }

        if (action === 'Deleted') {
            return 'REVIEWS__REVIEW_ABUSE_DELETED_BY';
        }

        return 'REVIEWS__REVIEW_ABUSE_UPDATED_BY';
    }, [action]);

    return (
        <div>
            <Text secondary>
                {formattedDate} {formattedTime}
            </Text>
            <Flex vertical>
                <Space>
                    <FontAwesomeIcon color={gray7} fontSize={16} icon={faTriangleExclamation} />
                    <Text lighter stringKey={abuseLocaleString} valueMap={{user: <Text bolder>{userName}</Text>}} />
                </Space>
                <Typography.Paragraph
                    ellipsis={{
                        rows: 1,
                        expandable: 'collapsible',
                        expanded,
                        onExpand: (_data, info) => setExpanded(info.expanded),
                        symbol: (
                            <Locale
                                stringKey={
                                    expanded ? 'REVIEWS__REVIEW_ABUSE__HIDE_TEXT' : 'REVIEWS__REVIEW_ABUSE__SHOW_TEXT'
                                }
                            />
                        ),
                    }}
                    type="secondary"
                >
                    {String(value)}
                </Typography.Paragraph>
            </Flex>
        </div>
    );
}
