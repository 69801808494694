import {Button} from 'antd';

import {LocalePlural} from '../../../../../../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../../../../../../provider/locale/localization';

import * as styles from './filter-summary.scss';

type PropsType = {
    count: number;
    total: number;
    resetFilter: () => void;
};

export function FilterSummary(props: PropsType): JSX.Element {
    const {count, total, resetFilter} = props;

    return (
        <div className={styles.FilterSummary}>
            {count === 0 ? (
                <Locale stringKey="COMPANY_MATCHING__FILTER__NOT_FOUND" />
            ) : (
                <LocalePlural
                    count={count}
                    fewKey="COMPANY_MATCHING__FILTER__FILTERED__FEW"
                    manyKey="COMPANY_MATCHING__FILTER__FILTERED__MANY"
                    singularKey="COMPANY_MATCHING__FILTER__FILTERED__SINGULAR"
                    valueMap={{count, total}}
                />
            )}

            <Button onClick={resetFilter} size="small" type="link">
                <Locale stringKey="COMPANY_MATCHING__FILTER__CLEAR" />
            </Button>
        </div>
    );
}
