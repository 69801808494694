import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../../../../../util/api-adapter';

const userSourcesSchema = r.array(
    r.object({
        id: r.number(),
        name: r.string(),
        isAvailable: r.boolean().optional(),
    })
);

type UserSourcesType = r.infer<typeof userSourcesSchema>;

export function useUserSourcesHook(): UseQueryResult<UserSourcesType> {
    return useQuery(['user-management-sources'], () =>
        fetchAndDeserialize('/cp/user_management/catalogs', userSourcesSchema)
    );
}
