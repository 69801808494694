import {ShortLocaleNameEnum} from '../../locale/locale-context-type';
import {HelpDeskChatOptionsType} from '../domain-config-type';

import {initialChatMessage} from './init-scripts-const';

/* eslint-disable no-param-reassign */
export function setDynamicScriptAttributes(element: HTMLScriptElement, options: HelpDeskChatOptionsType): void {
    element.dataset.lang = options.locale || 'ru';

    if (options.firstName) {
        element.dataset.userName = options.firstName;
    }

    if (options.email) {
        element.dataset.userEmail = options.email;
    }

    if (options.brandIds) {
        element.dataset.comment = `Brand ids: ${options.brandIds?.join(', ')}`;
    }

    window?.HDE?.setInitialChatMessage(initialChatMessage[options?.locale || ShortLocaleNameEnum.ru]);
}
/* eslint-enable no-param-reassign */
