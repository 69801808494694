import {faCommentDollar} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../app-route';
import {DashboardEmpty} from '../empty/dashboard-empty';

export function PricesEmpty(): JSX.Element {
    return (
        <DashboardEmpty
            buttonTextLangKey="DASHBOARD_PAGE__GOODS__EMPTY__BUTTON"
            descriptionLangKey="DASHBOARD_PAGE__GOODS__EMPTY__DESCRIPTION"
            icon={<FontAwesomeIcon icon={faCommentDollar} />}
            navigationPath={appRoute.products.path}
            titleLangKey="DASHBOARD_PAGE__GOODS__EMPTY__TITLE"
        />
    );
}
