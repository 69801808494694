import {LegendBox} from '../../../../../../layout/chart/legend-box/legend-box';
import {LegendWrapper} from '../../../../../../layout/chart/legend-wrapper/legend-wrapper';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    ActionDynamicsEnum,
    OnlinePresenceDashboardDataCommonType,
} from '../../../../../../service/online-presence/online-presence-type';
import {noop} from '../../../../../../util/function';
import {renderTargetActionDynamicsSentiments} from '../../target-actions-dynamics-helper';

type PropsType = {
    data: OnlinePresenceDashboardDataCommonType['actionsDynamic'];
    isCompareMode: boolean;
    legendFilter: Array<ActionDynamicsEnum>;
    setLegendFilter: (value: Array<ActionDynamicsEnum>) => void;
    isCompareLegendActive: boolean;
    setIsCompareLegendActive: (value: boolean) => void;
    staticChart?: boolean;
};

export function TargetActionsLegend(props: PropsType): JSX.Element {
    const {
        isCompareMode,
        data,
        legendFilter,
        setLegendFilter,
        isCompareLegendActive,
        setIsCompareLegendActive,
        staticChart,
    } = props;

    const {getLocalizedString} = useLocale();

    return (
        <LegendWrapper>
            {renderTargetActionDynamicsSentiments(legendFilter, setLegendFilter, data.table, staticChart)}
            {isCompareMode && (
                <LegendBox
                    checked={isCompareLegendActive}
                    label={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__COMPARE')}
                    onChange={staticChart ? noop : () => setIsCompareLegendActive(!isCompareLegendActive)}
                    stripes
                />
            )}
        </LegendWrapper>
    );
}
