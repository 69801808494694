import 'antd/dist/reset.css';
import 'leaflet/dist/leaflet.css';
import 'react-image-crop/dist/ReactCrop.css';
import './css/root.scss';
import './assets/font/inter.scss';

import {createRoot} from 'react-dom/client';

import {App} from './component/app/app';
import {selector} from './const';
import {googleTranslateWorkaround} from './google-translate-workaround';
import {initialize, sayHi} from './initialize';

async function startApp() {
    initialize();
    sayHi();

    const nodeWrapper = document.querySelector(selector.appWrapper);

    if (nodeWrapper !== null) {
        const root = createRoot(nodeWrapper);

        root.render(<App />);
    } else {
        console.error('Can not find nodeWrapper');
    }
}

googleTranslateWorkaround();

startApp();
