import {LocaleWithCatalogName} from '../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../provider/locale/localization';
import {CatalogConfigItemType, OptionsEnum} from '../../../../../service/online-presence/online-presence-type';
import {ProvidersIdsEnum} from '../../../../../util/type';

export const catalogsOptionsDefault: Array<CatalogConfigItemType> = [
    {
        catalogId: ProvidersIdsEnum.google,
        optionKey: OptionsEnum.google,
        label: (
            <Locale
                stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__PROVIDER"
                valueMap={{
                    catalogName: <Locale stringKey="ONLINE_PRESENCE__CATALOG__GOOGLE" />,
                }}
            />
        ),
    },
    {
        catalogId: ProvidersIdsEnum.yandex,
        optionKey: OptionsEnum.yandex,
        label: (
            <Locale
                stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__PROVIDER"
                valueMap={{
                    catalogName: <Locale stringKey="ONLINE_PRESENCE__CATALOG__YANDEX" />,
                }}
            />
        ),
    },
    {
        catalogId: ProvidersIdsEnum.doubleGis,
        optionKey: OptionsEnum.doubleGis,
        label: (
            <Locale
                stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__PROVIDER"
                valueMap={{
                    catalogName: <Locale stringKey="ONLINE_PRESENCE__CATALOG__DOUBLE_GIS" />,
                }}
            />
        ),
    },
    {
        catalogId: ProvidersIdsEnum.facebook,
        optionKey: OptionsEnum.facebook,
        label: (
            <LocaleWithCatalogName
                catalogId={ProvidersIdsEnum.facebook}
                namePlaceholder="FB"
                stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__PROVIDER"
            />
        ),
    },
];

export const otherOptionsDefault: Array<CatalogConfigItemType> = [
    {
        optionKey: OptionsEnum.listing,
        label: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__LOCATIONS" />,
    },
    {
        optionKey: OptionsEnum.searchPhrases,
        label: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__SEARCH_PHRASES" />,
    },
    {
        optionKey: OptionsEnum.syncStatistics,
        label: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__LABEL__SYNC_STATISTIC" />,
    },
];
