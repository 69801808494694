import {LangKeyType} from './translation/type';

export enum LocaleNameEnum {
    enUs = 'en-US',
    ruRu = 'ru-RU',
    plPl = 'pl-PL',
    kkKz = 'kk-KZ',
    esEs = 'es-ES',
    bgBg = 'bg-BG',
}

export enum ShortLocaleNameEnum {
    en = 'en',
    ru = 'ru',
    pl = 'pl',
    kk = 'kk',
    es = 'es',
    bg = 'bg',
}

export type LocaleContextType = {
    localeName: LocaleNameEnum;
    shortLocaleName: ShortLocaleNameEnum;
    isFetchingLocaleData: boolean;
    setLocaleName: (localeName: LocaleNameEnum) => void;
    getLocalizedString: (stringKey: LangKeyType, valueMap?: Record<string, string>) => string;
};

export type LocaleConstType = {
    defaults: {
        localeName: LocaleNameEnum;
        shortLocaleName: ShortLocaleNameEnum;
    };
    key: {
        localStorage: {
            localeName: string;
        };
    };
};
