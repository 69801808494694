export const enButtonTranslations = {
    BUTTON__UPLOAD: 'Upload',
    BUTTON__SELECT: 'Select',
    BUTTON__ADD: 'Add',
    BUTTON__UPLOAD_IMAGE: 'Upload image',
    BUTTON__UPDATE_INFO: 'Update info',
    BUTTON__SAVE_INFORMATION: 'Save information',
    BUTTON__CHANGE_PASSWORD: 'Change password',
    BUTTON__CANCEL: 'Cancel',
    BUTTON__BACK: 'Back',
    BUTTON__NEXT: 'Next',
    BUTTON__SAVE: 'Save',
    BUTTON__FINISH: 'Finish',
    BUTTON__PUBLISH: 'Publish',
    BUTTON__SAVE_SETTINGS: 'Save settings',
    BUTTON__DELETE: 'Delete',
    BUTTON__DELETE_SELECTED: 'Delete selected',
    BUTTON__ACTIONS: 'Actions',
    BUTTON__SET_UP_GROUPS: 'Set up groups',
    BUTTON__MOVE_SELECTED: 'Move selected',
    BUTTON__ASSIGN_TYPE: 'Assign type',
    BUTTON__ADD_GROUP: 'Add group',
    BUTTON__RESET_CHANGES: 'Reset changes',
    BUTTON__TURN_ON_SELECTED: 'Activate selected ones',
    BUTTON__TURN_OFF_SELECTED: 'Deactivate selected ones',
    BUTTON__UPDATE: 'Update',
    BUTTON__REFRESH: 'Refresh',
    BUTTON__RELOAD_PAGE: 'Reload page',
    BUTTON__EDIT: 'Edit',
    BUTTON__CREATE: 'Create',
    BUTTON__SEND_APPLICATION: 'Send application',
    BUTTON__FILTER: 'Filter',
    BUTTON__CONNECT: 'Connect',
    BUTTON__ACTIVATE: 'Activate',
    BUTTON__TRY_FOR_FREE: 'Try for free',
    BUTTON__NAVIGATE_TO_SERVICE: 'Go to section',
    BUTTON__DOWNLOAD: 'Download',
    POPUP__BUTTON__CANCEL: 'Cancel',
    POPUP__BUTTON__CLOSE: 'Close',
    POPUP__BUTTON__SEND: 'Send',
    POPUP__BUTTON__OK: 'OK',
    POPUP__BUTTON__CONFIRM: 'Confirm',
    BUTTON__RATING: 'Estimate',
};
