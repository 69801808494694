import {faClose} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQueryClient} from '@tanstack/react-query';
import {Alert, Badge, Button} from 'antd';

import {FormattedDateTime} from '../../../../provider/locale/formatted-date-time';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {YandexStoryStatusEnum, YandexStoryType} from '../../../../service/yandex-stories/yandex-stories-type';
import {Text} from '../../../text/text';
import {getYandexStoryStatusConfig} from '../../stories-helper';
import {YandexDeleteButton} from '../../yandex-stories-sources/yandex-delete-button';
import {YandexStoryEdit} from '../../yandex-story-edit/yandex-story-edit';
import {YandexStoryIllustration} from '../../yandex-story-illustration/yandex-story-illustration';
import {YandexStorySelectedCompanies} from '../selected-companies/yandex-story-selected-companies';

import * as styles from './yandex-story-info.scss';

type PropsType = {
    story: YandexStoryType;
    onClose: VoidFunction;
    onDelete?: VoidFunction;
};

export function YandexStoryInfo(props: PropsType): JSX.Element {
    const {story, onClose, onDelete} = props;

    const {getLocalizedString} = useLocale();

    const storyStatusBadgeConfig = getYandexStoryStatusConfig(story.status);
    const queryClient = useQueryClient();

    function handleRevalidation() {
        queryClient.invalidateQueries(['yandex-stories']);
    }

    const isActionDisabled = !story.hasPermissionToEdit || story.status === YandexStoryStatusEnum.Deleted;

    return (
        <div className={styles.YandexStoryInfo}>
            <Button
                className={styles.YandexStoryInfo_closeButton}
                icon={<FontAwesomeIcon icon={faClose} />}
                onClick={onClose}
                type="text"
            />

            <div className={styles.YandexStoryInfo_header}>
                <YandexStoryIllustration image={story.cover.url} />

                <div className={styles.YandexStoryInfo_headerInfoWrapper}>
                    <div>
                        <Text block>{story.name}</Text>
                        <Text block secondary>
                            <FormattedDateTime
                                options={{
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                }}
                                value={new Date(story.createdAt)}
                            />
                        </Text>
                    </div>
                    <div className={styles.YandexStoryInfo_buttonsWrapper}>
                        <YandexStoryEdit
                            disabled={isActionDisabled}
                            onEditSuccess={handleRevalidation}
                            storyData={story}
                            storyId={story.id}
                            useLocalData
                        />
                        <YandexDeleteButton
                            className={styles.YandexStoryInfo_deleteButton}
                            isDisabled={isActionDisabled}
                            onClick={onDelete}
                            onDeleteSuccess={handleRevalidation}
                            storyId={story.id}
                            storyName={story.name}
                            type={story.status === YandexStoryStatusEnum.Rejected ? 'primary' : 'default'}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.YandexStoryInfo_table}>
                <div className={styles.YandexStoryInfo_tableRow}>
                    <div>
                        <Text block stringKey="YANDEX_STORY__COMPANIES" />
                    </div>
                    <div>
                        <YandexStorySelectedCompanies
                            companiesCount={story.companiesCount}
                            key={story.id}
                            storyId={story.id}
                        />
                    </div>
                </div>

                <div className={styles.YandexStoryInfo_tableRow}>
                    <div>
                        <Text block stringKey="YANDEX_STORY__STATUS" />
                    </div>
                    <div>
                        <Text block>
                            <Badge
                                color={storyStatusBadgeConfig.badgeColor}
                                text={getLocalizedString(storyStatusBadgeConfig.langKey)}
                            />
                        </Text>
                    </div>
                </div>
            </div>

            {story.status === YandexStoryStatusEnum.Rejected && (
                <Alert message={getLocalizedString('YANDEX_STORY__ALERT__REJECTED')} showIcon type="error" />
            )}

            {story.status === YandexStoryStatusEnum.Moderation && (
                <Alert
                    className={styles.YandexStoryInfo_moderationAlert}
                    message={getLocalizedString('YANDEX_STORY__ALERT__MODERATION')}
                    showIcon
                    type="info"
                />
            )}
        </div>
    );
}
