import {useEffect, useState} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';

import {
    cancelRecommendationPostponeApi,
    loadRecommendations,
    recommendationPostponeApi,
} from './dashboard-recommendations-api';
import {DashboardPostponeRecommendationPayloadApiType} from './dashboard-recommendations-api-type';
import {
    DashboardRecommendationHookType,
    DashboardRecommendationItemType,
    DashboardRecommendationsHookType,
    DashboardRecommendationTypeEnum,
} from './dashboard-recommendations-type';

export function useDashboardRecommendations(): DashboardRecommendationsHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<DashboardRecommendationItemType>>();

    useEffect(() => {
        setProcessError(null);
        setIsInProgress(true);
        loadRecommendations()
            .then((apiResponse: Array<DashboardRecommendationItemType>) => {
                setResult(apiResponse);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });
    }, [setIsInProgress, setProcessError, setResult]);

    function onPostponeChange(recommendationKey: DashboardRecommendationTypeEnum, postponed: number | null) {
        if (result) {
            const newResult = result.map((item) => (item.key !== recommendationKey ? item : {...item, postponed}));

            setResult(newResult);
        }
    }

    return {
        isInProgress,
        processError,
        reset,
        result,
        onPostponeChange,
    };
}

export function useDashboardRecommendation(): DashboardRecommendationHookType {
    const [isInProgress, setIsInProgress] = useState<boolean>(false);

    function postponeRecommendation(
        recommendationKey: DashboardRecommendationTypeEnum,
        payload: DashboardPostponeRecommendationPayloadApiType
    ) {
        setIsInProgress(true);
        return recommendationPostponeApi(recommendationKey, payload).finally(() => setIsInProgress(false));
    }

    function cancelRecommendationPostpone(recommendationKey: DashboardRecommendationTypeEnum) {
        setIsInProgress(true);
        return cancelRecommendationPostponeApi(recommendationKey).finally(() => setIsInProgress(false));
    }

    return {
        isInProgress,
        postponeRecommendation,
        cancelRecommendationPostpone,
    };
}
