import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {ExtractRouteParams, useParams} from 'react-router';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {localStorageLastRoutePathKey} from '../../component/app/routing/routing-const';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useMessage} from '../../provider/message/message-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useUser} from '../../provider/user/user-hook';
import {postAndDeserialize} from '../../util/api-adapter';
import {carrotQuestAuthenticateUser} from '../../util/carrot-quest/carrot-quest';
import {getFromLocalStorage} from '../../util/local-storage';
import {useUrl} from '../../util/url-hook/url-hook';
import {AnalyticsTarget, track} from '../analytics/analytics';

import {fetchCheckQuiz, QUIZ_STORAGE_KEY} from './initial-questionnaire';
import {userUrl} from './user-const';
import {lastRoutePathSchema} from './user-type';

const verifyCodeSchema = r.object({
    result: r.literal('ok').or(r.literal('error')),
});

type VerifyCodeType = r.infer<typeof verifyCodeSchema>;

export enum VerifyCodeFormEnum {
    Code = 'code',
}

export type VerifyCodeFormType = {
    [VerifyCodeFormEnum.Code]: string;
};

type VerifyCodeOptionsType = VerifyCodeFormType & {
    authId: string;
};

function verifyCode(options: VerifyCodeOptionsType, shortLocaleName: ShortLocaleNameEnum): Promise<VerifyCodeType> {
    const url = `${userUrl}/login/verify_code/`;

    return postAndDeserialize(url, verifyCodeSchema, options, {unauthorized: true, shortLocaleName});
}

export function useVerifyCodeMutation(): UseMutationResult<VerifyCodeType, unknown, VerifyCodeFormType> {
    const {authId} = useParams<ExtractRouteParams<typeof appRoute.twoFactorAuthentication.path, string>>();

    const {getUser} = useUser();
    const {pushUrl} = useUrl();
    const {message} = useMessage();
    const {snackbar} = useSnackbar();
    const {shortLocaleName} = useLocale();
    const lastRoutePath = getFromLocalStorage<string>(
        localStorageLastRoutePathKey,
        lastRoutePathSchema,
        appRoute.root.path
    );

    function mutate(options: VerifyCodeFormType) {
        if (!authId) {
            return Promise.reject('Missing authId url param');
        }

        return verifyCode({authId, code: options.code.trim()}, shortLocaleName);
    }

    async function onSuccess(data: VerifyCodeType) {
        if (data.result !== 'ok') {
            throw new Error('AuthorizationResult is not "ok"');
        }

        const user = await getUser();

        if (user instanceof Error) {
            snackbar.error({
                message: <Locale stringKey="SNACKBAR__ERROR__LOGIN" />,
                description: <Locale stringKey="SNACKBAR__ERROR__LOGIN_DESCRIPTION" />,
            });

            return;
        }

        message.info(<Locale stringKey="SNACKBAR__SUCCESS__LOGIN" />);

        track(AnalyticsTarget.PersonalCabinet.Login);

        carrotQuestAuthenticateUser(user.id.toString());

        if (localStorage.getItem(QUIZ_STORAGE_KEY)) {
            pushUrl(lastRoutePath);
        } else {
            try {
                const responce = await fetchCheckQuiz();

                if (responce.showQuiz) {
                    pushUrl(appRoute.questionnaire.path);
                } else {
                    pushUrl(lastRoutePath);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    function onError() {
        snackbar.error({
            message: <Locale stringKey="SNACKBAR__ERROR__LOGIN" />,
            description: <Locale stringKey="SNACKBAR__ERROR__LOGIN_AUTHENTICATION" />,
        });
    }

    return useMutation(mutate, {onSuccess, onError});
}
