import {fetchAndDeserialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    LastFilterStateType,
    lastStateSchema,
    LoadSuggestionsBodyType,
    MainFilterCompileResultApiType,
    MainFilterFilterValuesApiType,
    MainFilterGetFilteredCountResultApiType,
    MainFilterSelectedValuesApiType,
} from './main-filter-api-type';
import {FilterSuggestionType} from './main-filter-type';

export function getFilteredCompaniesCountApi(
    props: MainFilterSelectedValuesApiType
): Promise<MainFilterGetFilteredCountResultApiType> {
    return fetchX<MainFilterGetFilteredCountResultApiType>(`${rootApiUrl}/v1/topfilter/companies_count/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(props),
    });
}

export function compileMainFilterApi(props: MainFilterSelectedValuesApiType): Promise<MainFilterCompileResultApiType> {
    return fetchX<MainFilterCompileResultApiType>(`${rootApiUrl}/v1/topfilter/compile/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(props),
    });
}

export function getFilterValuesApi(filterId: string): Promise<MainFilterFilterValuesApiType> {
    return fetchX<MainFilterFilterValuesApiType>(`${rootApiUrl}/v1/topfilter/${filterId}/`, {
        method: FetchMethodEnum.get,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function getSuggestionsApi(props: LoadSuggestionsBodyType): Promise<Array<FilterSuggestionType>> {
    return fetchX<Array<FilterSuggestionType>>(`${rootApiUrl}/v1/topfilter/suggest/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(props),
    });
}

export function getLastFilterKey(): Promise<LastFilterStateType> {
    return fetchAndDeserialize('/v1/topfilter/last_state/', lastStateSchema);
}
