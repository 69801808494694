import {noop} from 'lodash';
import {MutableRefObject, useEffect, useState} from 'react';

export function useIsVisibleOnce(ref: MutableRefObject<HTMLElement | null>, ...deps: Array<unknown>): boolean {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        if (!('IntersectionObserver' in window) || isIntersecting) {
            return noop;
        }

        const observer = new IntersectionObserver(([entry]) => {
            if (entry?.isIntersecting) {
                setIsIntersecting(true);
                observer.disconnect();
            }
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isIntersecting, ref, deps]);

    return isIntersecting;
}
