import {faEye, faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {Locale} from '../../../../../../provider/locale/localization';

type PropsType = {
    disabled?: boolean;
    isOpen?: boolean;
    className?: string;
    onClick?: () => void;
};

export function AttributesWidgetOpenButton(props: PropsType): JSX.Element {
    const {disabled, isOpen, className, onClick} = props;

    return (
        <Button
            className={className}
            disabled={disabled}
            icon={<FontAwesomeIcon icon={isOpen ? faEyeSlash : faEye} size="sm" />}
            onClick={onClick}
            size={isOpen ? 'small' : 'large'}
        >
            <span>
                <Locale stringKey={isOpen ? 'COMPANY_FORM__HIDE' : 'COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW'} />
            </span>
        </Button>
    );
}
