import {Tabs} from 'antd';
import {useMemo} from 'react';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../component/text/text';
import {BarChart} from '../../../../layout/chart/bar-chart/bar-chart';
import {Spinner} from '../../../../layout/spinner/spinner';
import {WeekDaysEnum} from '../../../../provider/locale/locale-context-const';
import {useWorkload} from '../../../../service/analytics-workload/analytics-workload-hook';
import {getEnumValueEnsure} from '../../../../util/enum';
import {ProvidersIdsEnum} from '../../../../util/type';

import {WEEK_DAY_TO_LOCALIZED_STRING} from './analytics-workload-const';
import {AnalyticsWorkloadEmpty} from './analytics-workload-empty/analytics-workload-empty';
import {deserializeDataForChartByWeekDay, getWorkloadChartOptions} from './analytics-workload-helper';
import * as styles from './analytics-workload.scss';

type PropsType = {
    companyId: number;
};

export function AnalyticsWorkload(props: PropsType): JSX.Element {
    const {companyId} = props;

    const {result: data, processError, isInProgress, isDataEmpty} = useWorkload(companyId, ProvidersIdsEnum.google);

    const tabs = useMemo(
        () =>
            Object.entries(WEEK_DAY_TO_LOCALIZED_STRING).map(([key, weekDay]: [unknown, JSX.Element]) => {
                const dayData = deserializeDataForChartByWeekDay(
                    data || {},
                    getEnumValueEnsure<WeekDaysEnum>(WeekDaysEnum, key, WeekDaysEnum.monday)
                );

                return {
                    key: String(key),
                    label: weekDay,
                    children: dayData.datasets.some((dataset) => dataset.values.some(Boolean)) ? (
                        <div className={styles.AnalyticsWorkload_barChartContainer}>
                            <BarChart data={dayData} options={getWorkloadChartOptions()} />
                        </div>
                    ) : (
                        <AnalyticsWorkloadEmpty />
                    ),
                };
            }),
        [data]
    );

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress) {
        return (
            <div className={styles.AnalyticsWorkload_loadingContainer}>
                <Spinner />
            </div>
        );
    }

    if (isDataEmpty) {
        return <AnalyticsWorkloadEmpty />;
    }

    return (
        <div className={styles.AnalyticsWorkload}>
            <div className={styles.AnalyticsWorkload_chartContainer}>
                <Tabs items={tabs} />
            </div>
            <Text block secondary small stringKey="DASHBOARD_PAGE__WORKLOAD__FOOTER" />
        </div>
    );
}
