import {isBoolean} from 'lodash';
import {Context, createContext, PropsWithChildren, useEffect, useMemo, useState} from 'react';

import {mainFilterFieldLabelKeyMap} from '../../component/header/common/header-filter/header-filter-const';
import {AnalyticsTarget, track} from '../../service/analytics/analytics';
import {getFilteredCompaniesCountApi, getFilterValuesApi} from '../../service/main-filter/main-filter-api';
import {
    MainFilterFilterValuesApiType,
    MainFilterGetFilteredCountResultApiType,
} from '../../service/main-filter/main-filter-api-type';
import {useInitialFilter} from '../../service/main-filter/main-filter-hook';
import {
    FilterSuggestionType,
    IsActiveFieldNameEnum,
    MainFilterFieldNameEnum,
    MainFilterOpenCloseSuggestionIdEnum,
    MainFiltersStateType,
} from '../../service/main-filter/main-filter-type';
import {MainFilterQueryKeyNameEnum} from '../../shared-search-parameters';
import {useUrl} from '../../util/url-hook/url-hook';
import {useLocale} from '../locale/locale-hook';
import {enUs} from '../locale/translation/en-us/data';

import {defaultMainFilterContext, filterActualizingIntervalMs} from './main-filter-const';
import {serializeStateFormCompaniesCountRequest} from './main-filter-helper';
import {MainFilterContextType} from './main-filter-type';

export const MainFilterContext: Context<MainFilterContextType> = createContext(defaultMainFilterContext);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function MainFilterProvider(props: PropsType): JSX.Element {
    const {children} = props;
    const {setQuery, queries} = useUrl<Record<MainFilterQueryKeyNameEnum, string>>();
    const {
        initialFilterKey: initialFilter,
        isLoading: isLoadingInitialFilter,
        setIsLoading: setIsLoadingInitialFilter,
    } = useInitialFilter();
    const {getLocalizedString} = useLocale();

    const [mainFilterKey, setMainFilterKey] = useState<string>('');
    const [filteredCount, setFilteredCount] = useState<number>(0);

    const [state, setState] = useState<MainFiltersStateType>({
        [MainFilterFieldNameEnum.BRANDS]: [],
        [MainFilterFieldNameEnum.CITIES]: [],
        [MainFilterFieldNameEnum.CODES]: [],
        [MainFilterFieldNameEnum.STREETS]: [],
        [MainFilterFieldNameEnum.REGIONS]: [],
        [MainFilterFieldNameEnum.NAMES]: [],
        [MainFilterFieldNameEnum.COUNTRIES]: [],
        [MainFilterFieldNameEnum.GROUPS]: [],
        [MainFilterFieldNameEnum.CLOSED]: [],
        [MainFilterFieldNameEnum.ACTIVE]: [],
    });

    function closeFieldValue(isClosed: boolean | null, isTemporaryClosed: boolean | null): Array<FilterSuggestionType> {
        if (isClosed && isTemporaryClosed === null) {
            return [
                {
                    id: MainFilterOpenCloseSuggestionIdEnum.IS_CLOSE,
                    label: getLocalizedString('MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_CLOSE'),
                },
            ];
        }

        if (isClosed === null && isTemporaryClosed) {
            return [
                {
                    id: MainFilterOpenCloseSuggestionIdEnum.IS_TEMPORARY_CLOSE,
                    label: getLocalizedString('MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_TEMPORARY_CLOSE'),
                },
            ];
        }

        if (isTemporaryClosed === false && isClosed === false) {
            return [
                {
                    id: MainFilterOpenCloseSuggestionIdEnum.OPEN,
                    label: getLocalizedString('MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_OPEN'),
                },
            ];
        }

        return [];
    }

    function acctiveSuggestionValue(isCompanyActive?: boolean) {
        if (isBoolean(isCompanyActive) && !isCompanyActive) {
            return [
                {
                    id: IsActiveFieldNameEnum.INACTIVE,
                    label: getLocalizedString('MAIN_FILTER__ACTIVE_FILTER__INACTIVE_COMPANY'),
                },
            ];
        }

        if (isBoolean(isCompanyActive) && isCompanyActive) {
            return [
                {
                    id: IsActiveFieldNameEnum.ACTIVE,
                    label: getLocalizedString('MAIN_FILTER__ACTIVE_FILTER__ACTIVE_COMPANY'),
                },
            ];
        }

        return [];
    }

    useEffect(() => {
        setMainFilterKey(initialFilter);
    }, [initialFilter]);

    useEffect(() => {
        const filterQuery = queries[MainFilterQueryKeyNameEnum.filterId] || '';

        if (filterQuery !== mainFilterKey) {
            setQuery(
                {
                    [MainFilterQueryKeyNameEnum.filterId]: mainFilterKey,
                },
                {isSaveHash: true}
            );
            setIsLoadingInitialFilter(false);
        }
    }, [setQuery, mainFilterKey, queries, setIsLoadingInitialFilter]);

    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState<boolean>(false);

    function updateFilter(newState: MainFiltersStateType, newFilteredCount: number, newFilterKey: string): void {
        setState(newState);
        setMainFilterKey(newFilterKey);
        setFilteredCount(newFilteredCount);

        const trackedFields = Object.entries(newState)
            .filter(([, _values]) => _values.length > 0)
            .map(([key, _values]) => enUs[mainFilterFieldLabelKeyMap[key as MainFilterFieldNameEnum]]);

        if (trackedFields.length > 0) {
            track(AnalyticsTarget.PersonalCabinet.Filter, trackedFields);
        }
    }

    useEffect(() => {
        if (mainFilterKey && !isInitialDataLoaded) {
            getFilterValuesApi(mainFilterKey)
                // eslint-disable-next-line complexity
                .then((filterState: MainFilterFilterValuesApiType) => {
                    const closeField = closeFieldValue(
                        filterState.current_filter.is_company_closed,
                        filterState.current_filter.is_company_temporary_closed
                    );

                    const activeField = acctiveSuggestionValue(filterState.current_filter.is_company_active);

                    setIsInitialDataLoaded(true);

                    updateFilter(
                        {
                            [MainFilterFieldNameEnum.BRANDS]:
                                filterState.current_filter[MainFilterFieldNameEnum.BRANDS] || [],
                            [MainFilterFieldNameEnum.CITIES]:
                                filterState.current_filter[MainFilterFieldNameEnum.CITIES] || [],
                            [MainFilterFieldNameEnum.CODES]:
                                filterState.current_filter[MainFilterFieldNameEnum.CODES] || [],
                            [MainFilterFieldNameEnum.STREETS]:
                                filterState.current_filter[MainFilterFieldNameEnum.STREETS] || [],
                            [MainFilterFieldNameEnum.REGIONS]:
                                filterState.current_filter[MainFilterFieldNameEnum.REGIONS] || [],
                            [MainFilterFieldNameEnum.NAMES]:
                                filterState.current_filter[MainFilterFieldNameEnum.NAMES] || [],
                            [MainFilterFieldNameEnum.COUNTRIES]:
                                filterState.current_filter[MainFilterFieldNameEnum.COUNTRIES] || [],
                            [MainFilterFieldNameEnum.GROUPS]:
                                filterState.current_filter[MainFilterFieldNameEnum.GROUPS] || [],
                            [MainFilterFieldNameEnum.CLOSED]: closeField || [],
                            [MainFilterFieldNameEnum.ACTIVE]: activeField || [],
                        },
                        filterState.companies_count,
                        mainFilterKey
                    );
                })
                .catch((error: Error) => {
                    throw error;
                });
        }
    }, [isInitialDataLoaded, mainFilterKey, state]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isInitialDataLoaded && mainFilterKey) {
                const options = serializeStateFormCompaniesCountRequest(state);

                getFilteredCompaniesCountApi(options)
                    .then((apiFilterCountResult: MainFilterGetFilteredCountResultApiType) => {
                        setFilteredCount(apiFilterCountResult.count);
                    })
                    .catch((error: Error) => {
                        throw error;
                    });
            }
        }, filterActualizingIntervalMs);

        return () => clearInterval(interval);
    }, [isInitialDataLoaded, mainFilterKey, state]);

    const providerData: MainFilterContextType = useMemo((): MainFilterContextType => {
        return {
            update: updateFilter,
            state,
            filteredCount,
            mainFilterKey,
            isLoadingInitialFilter,
        };
    }, [isLoadingInitialFilter, filteredCount, mainFilterKey, state]);

    return <MainFilterContext.Provider value={providerData}>{children}</MainFilterContext.Provider>;
}
