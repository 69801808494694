import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';

import {AutoReplyToReviewTableRowDataType} from './auto-replies-table-type';

export const autoRepliesTableColumnList: TableColumnsType<AutoReplyToReviewTableRowDataType> = [
    {
        title: <Locale stringKey="AUTO_REPLIES__TABLE__TITLE" />,
        dataIndex: 'title',
    },
    {
        title: <Locale stringKey="AUTO_REPLIES__TABLE__TYPE" />,
        dataIndex: 'type',
        width: 128,
    },
    {
        title: <Locale stringKey="AUTO_REPLIES__TABLE__VALIDITY" />,
        dataIndex: 'validity',
        width: 128,
    },
    {
        title: <Locale stringKey="AUTO_REPLIES__TABLE__CONDITION" />,
        dataIndex: 'condition',
        align: 'center',
        width: 104,
    },
    {
        title: <Locale stringKey="AUTO_REPLIES__TABLE__ACTIONS" />,
        dataIndex: 'actions',
        width: 104,
    },
];
