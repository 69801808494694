import {Skeleton} from 'antd';

import {classNames} from '../../../../../../util/css';

import * as styles from './review-skeleton.scss';

export function ReviewSkeleton(): JSX.Element {
    return (
        <div className={styles.ReviewSkeleton}>
            <div className={styles.ReviewSkeleton_header}>
                <div className={styles.ReviewSkeleton_icon} />
                <div className={styles.ReviewSkeleton_row} />
                <div className={classNames(styles.ReviewSkeleton_row, styles.ReviewSkeleton_row__indent)} />
            </div>
            <div className={styles.ReviewSkeleton_content}>
                <div className={styles.ReviewSkeleton_avatars}>
                    <div className={styles.ReviewSkeleton_avatar} />
                    <div className={styles.ReviewSkeleton_avatar} />
                    <div className={styles.ReviewSkeleton_avatar} />
                </div>
                <Skeleton active paragraph={{rows: 2}} />
            </div>
        </div>
    );
}
