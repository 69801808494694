import {Button, Progress} from 'antd';
import {ModalFunc} from 'antd/lib/modal/confirm';
import {useEffect, useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../../provider/locale/translation/type';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {classNames} from '../../../../../../../util/css';

import * as styles from './feed-progress.scss';

export type ProgressTranslationType =
    | 'abortButtonLabel'
    | 'abortModalTitle'
    | 'abortModalDescription'
    | 'progressTitle'
    | 'beforeProgressTitle';

type PropsType = {
    percent?: number;
    remainingRelativeTime?: string;
    translations: Record<ProgressTranslationType, LangKeyType>;
    onAbort?: () => void;
    size?: number;
    className?: string;
    isResultAvailable?: boolean;
};

export function FeedProgress(props: PropsType): JSX.Element | null {
    const {percent, remainingRelativeTime, className, translations, onAbort, size, isResultAvailable = true} = props;

    const [abortModal, setAbortModal] = useState<ReturnType<ModalFunc> | null | undefined>(null);
    const {modal} = useModal();

    function handleAbort() {
        setAbortModal(
            modal.confirm({
                title: <Locale stringKey={translations.abortModalTitle} />,
                content: <Locale stringKey={translations.abortModalDescription} />,
                okText: <Locale stringKey="FEED__ABORT" />,
                okButtonProps: {type: 'default'},
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                onOk: onAbort,
            })
        );
    }

    useEffect(() => {
        if (abortModal && percent === 100) {
            abortModal.destroy();
        }
    }, [abortModal, percent]);

    if (percent === 100) {
        return null;
    }

    return (
        <div className={classNames(styles.FeedProgress, className)}>
            <Progress percent={percent} size={size} type="circle" />

            <div className={styles.FeedProgress__right}>
                <Text
                    bolder
                    stringKey={isResultAvailable ? translations.progressTitle : translations.beforeProgressTitle}
                />

                {remainingRelativeTime && (
                    <Text
                        className={styles.FeedProgress__remaining}
                        secondary
                        small
                        stringKey="FEED__FORM__PROGRESS__REMAINING"
                        valueMap={{
                            time: (
                                <strong className={styles.FeedProgress__remainingNumber}>
                                    {remainingRelativeTime}
                                </strong>
                            ),
                        }}
                    />
                )}

                <Button className={styles.FeedProgress__button} disabled={!onAbort} onClick={handleAbort}>
                    <Locale stringKey={translations.abortButtonLabel} />
                </Button>
            </div>
        </div>
    );
}
