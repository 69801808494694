import {
    MainFilterSelectedValuesAdditionalType,
    MainFilterSelectedValuesIdsAdditionalKeyEnum,
} from './main-filter-api-type';
import {FilterSuggestionType} from './main-filter-type';

export function getSuggestionIds(values: Array<FilterSuggestionType> | boolean | null): Array<string> | null | boolean {
    return typeof values !== 'boolean' && values !== null ? values?.map((value) => value.id) : values;
}

// eslint-disable-next-line complexity
export function caseForOpen(closedHookValue: Array<FilterSuggestionType>): MainFilterSelectedValuesAdditionalType {
    const initialState = {
        isClosed: false,
        isTemporaryClosed: false,
        opened: false,
    };

    const responseObject = Object.fromEntries(
        closedHookValue?.map((suggestion) => suggestion.id)?.map((option) => [option, true])
    );

    const assignedObject = Object.assign(initialState, responseObject);

    if (!assignedObject.isClosed && !assignedObject.isTemporaryClosed && !assignedObject.opened) {
        return {};
    }

    if (!assignedObject.isClosed && !assignedObject.isTemporaryClosed && assignedObject.opened) {
        return {
            [MainFilterSelectedValuesIdsAdditionalKeyEnum.CLOSED]: getSuggestionIds(false),
            [MainFilterSelectedValuesIdsAdditionalKeyEnum.TEMP_CLOSED]: getSuggestionIds(false),
        };
    }

    if (assignedObject.isClosed && !assignedObject.isTemporaryClosed && !assignedObject.opened) {
        return {
            [MainFilterSelectedValuesIdsAdditionalKeyEnum.CLOSED]: getSuggestionIds(true),
        };
    }

    if (!assignedObject.isClosed && assignedObject.isTemporaryClosed && !assignedObject.opened) {
        return {
            [MainFilterSelectedValuesIdsAdditionalKeyEnum.TEMP_CLOSED]: getSuggestionIds(true),
        };
    }

    return {};
}

export function caseForActive(closedHookValue: Array<FilterSuggestionType>): MainFilterSelectedValuesAdditionalType {
    const initialState = {
        active: false,
        inactive: false,
    };

    const responseObject = Object.fromEntries(
        closedHookValue?.map((suggestion) => suggestion.id)?.map((option) => [option, true])
    );

    const assignedObject = Object.assign(initialState, responseObject);

    if (assignedObject.active && !assignedObject.inactive) {
        return {
            [MainFilterSelectedValuesIdsAdditionalKeyEnum.ACTIVE]: getSuggestionIds(true),
        };
    }

    if (!assignedObject.active && assignedObject.inactive) {
        return {
            [MainFilterSelectedValuesIdsAdditionalKeyEnum.ACTIVE]: getSuggestionIds(false),
        };
    }

    return {};
}
