import {googleCompaniesResolveUrl} from '../../../../../../../service/google/google-api';
import {
    googleResolveCandidateSchema,
    GoogleResolveCandidateType,
} from '../../../../../../../service/google/google-type';
import {deserializeV2} from '../../../../../../../util/api-adapter';
import {isRecord} from '../../../../../../../util/object';

export function getGoogleResolveCandidateErrors(error: unknown): Array<string> {
    if (!isRecord(error) || !isRecord(error.jsonData)) {
        return [];
    }

    const errorData: GoogleResolveCandidateType = deserializeV2(
        googleCompaniesResolveUrl,
        googleResolveCandidateSchema,
        error.jsonData
    );

    return [errorData.error, ...(errorData.nonFieldErrors || [])].flat().filter(Boolean);
}
