import {Locale} from '../../../../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../../../../provider/locale/translation/type';
import {ContentValidationSettingsType, ExtraContentValidationSettingsType} from '../../../content-step-type';

type PropsType = {
    maxTextLength: ContentValidationSettingsType['maxTextLength'];
    extraRequirements?: ExtraContentValidationSettingsType;
};

export function TextRequirements(props: PropsType): JSX.Element {
    const {maxTextLength, extraRequirements} = props;

    return (
        <>
            <Locale stringKey="POSTS_REQUIREMENTS__TEXT" />
            <ul>
                <li>
                    <Locale stringKey="POSTS_REQUIREMENTS__TEXT__RULE_1" valueMap={{maxLength: maxTextLength}} />
                </li>
                {Object.values(extraRequirements?.text || {}).map((extra: LangKeyType) => {
                    return (
                        <li key={extra}>
                            <Locale stringKey={extra} />
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
