import {Table} from 'antd';
import {useCallback, useContext, useEffect, useMemo} from 'react';

import {appRoute} from '../../../../../../app-route';
import {AddButton} from '../../../../../../component/add-button/add-button';
import {AlertFallback} from '../../../../../../component/alert-fallback/alert-fallback';
import {dateRangeToWorksheetName} from '../../../../../../component/request-report-base/request-report-base-helper';
import {Text} from '../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../provider/main-filter/main-filter-type';
import {fetchReviewTopicList} from '../../../../../../service/reivew-analysis/review-topics/review-topics-api';
import {useReviewTopics} from '../../../../../../service/reivew-analysis/review-topics/review-topics-hook';
import {
    ReviewAnalysisTopicTypeEnum,
    ReviewsAnalysisTopicsQueryType,
    ReviewTopicType,
} from '../../../../../../service/reivew-analysis/review-topics/review-topics-type';
import {TimeSizeEnum} from '../../../../../../util/format';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {ActiveFilter} from '../../../components/active-filter/active-filter';
import {RequestReport} from '../../../components/request-report/request-report';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {keywordsAndTopicsDefaultPageSize, keywordsAndTopicsPageSizeOptions} from '../keywords-and-topics-page-const';
import {KeywordsAndTopicsPageContext} from '../keywords-and-topics-page-context';
import {topicsPageTableColumns} from '../keywords-and-topics-page-helper';
import {TopicTableDataType} from '../keywords-and-topics-page-type';
import {TopicActions} from '../topic-actions/topic-actions';

import * as styles from './topics-tab.scss';

export function TopicsTab(): JSX.Element {
    const {reviewsAnalysisTopicCreate: topicCreateRoute} = appRoute;

    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {singleFilter} = useReviewsAnalysisFilter();

    const {onIsFormInProgressChange} = useContext(KeywordsAndTopicsPageContext);

    const {result, isInProgress, processError, refresh, pagination} = useReviewTopics(
        {
            ...singleFilter,
            topicType: ReviewAnalysisTopicTypeEnum.all,
        },
        mainFilterKey,
        {initialPageSize: keywordsAndTopicsDefaultPageSize}
    );

    useEffect(() => {
        onIsFormInProgressChange(isInProgress);
    }, [isInProgress, onIsFormInProgressChange]);

    const {total, pageSize, page, onChange: onPaginationChange} = pagination;

    const getDataSource = useCallback(
        (array: Array<ReviewTopicType>, isForExport?: boolean) => {
            return array.map<TopicTableDataType>(
                (topicItem: ReviewTopicType, index): TopicTableDataType => ({
                    key: `${topicItem.topic} + ${index} + ${isForExport && 'export'}`,
                    ...topicItem,
                    action: <TopicActions onDeleteSuccess={refresh} topic={topicItem} />,
                })
            );
        },
        [refresh]
    );

    const dataSource: Array<TopicTableDataType> = useMemo<Array<TopicTableDataType>>(
        (): Array<TopicTableDataType> => getDataSource(result?.results || []),
        [getDataSource, result?.results]
    );

    const reportTableColumns = topicsPageTableColumns.slice(0, -1);

    function formatDataForXls(data: Array<ReviewTopicType>) {
        return data.map((topic) => ({
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__TOPIC')]: topic.topic,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__RATE')]: topic.avgRating.toFixed(1),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEW_TOTAL')]: topic.total,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEWS_REPLIED')]: topic.replied,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE')]: getFormattedNumber(
                topic.repliedPercent,
                {style: 'percent'}
            ),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REPLY_DELAY')]: topic.avgResponseSpeed
                ? getFormattedNumber(topic.avgResponseSpeed, {style: 'unit', unit: TimeSizeEnum.minute})
                : '-',
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE'
            )}`]: topic.sentiment.positive,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL'
            )}`]: topic.sentiment.neutral,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE'
            )}`]: topic.sentiment.negative,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED'
            )}`]: topic.sentiment.unspecified,
        }));
    }

    return (
        <PageCard>
            <ActiveFilter
                isCompareRangeAllowed={false}
                isCompetitorAllowed={false}
                isPeriodHidden
                isPreview={false}
                isWithTextOnly={false}
            />

            <div className={styles.TopicsTab_buttons}>
                <NavigationLink to={topicCreateRoute.path}>
                    <AddButton>
                        <Locale stringKey="REVIEWS_ANALYSIS__TOPICS_PAGE__CREATE_TOPIC__BUTTON" />
                    </AddButton>
                </NavigationLink>

                <RequestReport<TopicTableDataType, ReviewTopicType, ReviewsAnalysisTopicsQueryType>
                    apiHandler={fetchReviewTopicList}
                    columns={reportTableColumns}
                    defaultFilename={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__TOPICS')}
                    filters={{...singleFilter, topicType: ReviewAnalysisTopicTypeEnum.all}}
                    formatDataForXls={formatDataForXls}
                    getDataSource={getDataSource}
                    title={<Locale stringKey="REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS_TAB_TOPICS" />}
                    xlsxWorksheetName={dateRangeToWorksheetName({
                        rawStartDate: singleFilter.startDate,
                        rawEndDate: singleFilter.endDate,
                    })}
                />
            </div>
            {processError && <AlertFallback description={processError?.message} message={processError?.name} />}
            {!processError && (
                <>
                    <Text
                        block
                        className={styles.TopicsTab_count}
                        stringKey="REVIEWS_ANALYSIS__TABLE__TOTAL_TOPICS_COUNT"
                        valueMap={{total}}
                    />

                    <Table<TopicTableDataType>
                        columns={topicsPageTableColumns}
                        dataSource={dataSource}
                        loading={isInProgress}
                        pagination={{
                            total,
                            pageSize,
                            defaultPageSize: keywordsAndTopicsDefaultPageSize,
                            pageSizeOptions: keywordsAndTopicsPageSizeOptions,
                            current: page,
                            size: 'default',
                            showSizeChanger: true,
                            onChange: onPaginationChange,
                        }}
                        scroll={{x: true}}
                        size="middle"
                    />
                </>
            )}
        </PageCard>
    );
}
