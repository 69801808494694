import {faCirclePlus, faDiamondTurnRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';

import {YandexStoryTypeEnum} from '../../../service/yandex-stories/yandex-stories-type';
import {classNames} from '../../../util/css';
import {Text} from '../../text/text';
import {CreateYandexStoryFormModal} from '../yandex-story-form/create-yandex-story-form-modal';

import * as styles from './yandex-story-card.scss';

type PropsType = {
    type: YandexStoryTypeEnum;
    variant?: 'horizontal';
    companyId?: number;
    onCreateSuccess?: VoidFunction;
    brandId?: number;
};

export function NewYandexStoryCard(props: PropsType): JSX.Element {
    const {type, variant, companyId, onCreateSuccess, brandId} = props;

    const [isCreateStoryModalOpen, setIsCreateStoryModalOpen] = useState<boolean>(false);
    const isHorizontal = variant === 'horizontal';

    return (
        <>
            <button
                className={classNames(styles.YandexStoryCard_wrapper, {
                    [styles.YandexStoryCard_wrapper__horizontal]: variant === 'horizontal',
                })}
                onClick={() => setIsCreateStoryModalOpen(true)}
                type="button"
            >
                <div
                    className={classNames(styles.YandexStoryCard, styles.YandexStoryCard__newStoryCard, {
                        [styles.YandexStoryCard__horizontal]: variant === 'horizontal',
                    })}
                >
                    <div>
                        <FontAwesomeIcon icon={type === 'story' ? faCirclePlus : faDiamondTurnRight} />
                    </div>
                </div>
                <div
                    className={classNames(styles.YandexStoryCard_storyInfo, {
                        [styles.YandexStoryCard_storyInfo__horizontal]: isHorizontal,
                    })}
                >
                    <Text
                        block
                        bolder
                        inheritColor
                        stringKey={
                            type === YandexStoryTypeEnum.Story
                                ? 'DASHBOARD_YANDEX_STORIES__ADD_STORY'
                                : 'DASHBOARD_YANDEX_STORIES__ADD_HOW_TO_FIND'
                        }
                    />
                    {isHorizontal && type === YandexStoryTypeEnum.Navigation && (
                        <Text secondary small stringKey="DASHBOARD_YANDEX_STORIES__ADD_HOW_TO_FIND__DESCRIPTION" />
                    )}
                </div>
            </button>
            {isCreateStoryModalOpen && (
                <CreateYandexStoryFormModal
                    brandId={brandId}
                    companyId={companyId}
                    onCreateSuccess={onCreateSuccess}
                    setIsOpen={setIsCreateStoryModalOpen}
                    type={type}
                />
            )}
        </>
    );
}
