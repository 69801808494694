import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../../util/url';
import {PaginatedResponseType, RequestOptionsType} from '../../api/api-type';

import {
    reviewsAnalysisTagBySentimentResponseSchema,
    ReviewsAnalysisTagBySentimentType,
    reviewsAnalysisTagResponseSchema,
    reviewsAnalysisTagSearchSchema,
    ReviewsAnalysisTagsQueryType,
    ReviewsAnalysisTagType,
} from './review-analytics-tags-type';

export async function fetchReviewsAnalysisTagList(
    options: ReviewsAnalysisTagsQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<PaginatedResponseType<ReviewsAnalysisTagType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisTagsQueryType>(options),
        filter_key: mainFilterKey,
        mode: options.brandIds?.length ? 'competitors' : null,
    });

    const url = `/v2/reviews-analytics/stats/tags/?${searchParameters}`;

    return fetchAndDeserialize<PaginatedResponseType<ReviewsAnalysisTagType>>(url, reviewsAnalysisTagResponseSchema);
}

export async function fetchReviewsAnalysisTagSearch(
    options: RequestOptionsType,
    mainFilterKey: string
): Promise<Array<string>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<RequestOptionsType>(options),
        filter_key: mainFilterKey,
        timestamp: Date.now(),
    });

    const url = `/v2/reviews-analytics/stats/tags/search/?${searchParameters}`;

    return fetchAndDeserialize<Array<string>>(url, reviewsAnalysisTagSearchSchema);
}

export async function fetchReviewsAnalysisTagBySentiment(
    options: ReviewsAnalysisTagsQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<PaginatedResponseType<ReviewsAnalysisTagBySentimentType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisTagsQueryType>(options),
        filter_key: mainFilterKey,
        timestamp: Date.now(),
    });

    const url = `/v2/reviews-analytics/stats/tags-cloud/?${searchParameters}`;

    return fetchAndDeserialize<PaginatedResponseType<ReviewsAnalysisTagBySentimentType>>(
        url,
        reviewsAnalysisTagBySentimentResponseSchema
    );
}
