import {defaultTooltipOptions} from '../chart-const';

import {ChartJsDoughnutOptionsType} from './doughnut-chart-type';

export const defaultChartBorderRadius = 0;
export const defaultChartBorderWidth = 0;

export const doughnutChartOptions: ChartJsDoughnutOptionsType = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: '80%',
    borderWidth: 2,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: defaultTooltipOptions,
        datalabels: {
            display: false,
        },
    },
};
