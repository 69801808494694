import {Button, TablePaginationConfig} from 'antd';
import {useCallback, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {
    AutoReplyToReviewDataType,
    fetchAutoReplayChangeCondition,
    fetchAutoReplayChangeEnabledType,
    fetchAutoReplaysDelete,
    useAutoRepliesToReviews,
} from '../../../service/reviews/reviews-response-templates';
import {findInArray} from '../../../util/array';
import {ErrorWithStatus, isErrorHasStatusMessage} from '../../../util/error';
import {defaultPaginationState} from '../../../util/hook';
import {toTrimmedString} from '../../../util/string';

import {AutoRepliesLeft} from './auto-replies-left/auto-replies-left';
import {AutoRepliesTable} from './auto-replies-table/auto-replies-table';
import {ListOfRulesActionSelect} from './list-of-rules-action-select/list-of-rules-action-select';
import * as styles from './auto-replies.scss';

export function AutoReplies(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const [selectedIdList, setSelectedIdList] = useState<Array<string>>([]);
    const [autoReplyToReviewDataList, setAutoReplyToReviewDataList] = useState<Array<AutoReplyToReviewDataType>>([]);
    const hasSelectedCompanies = selectedIdList.length > 0;
    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPaginationState);

    const {
        data: autoRepliesToReviewsResult,
        isFetching: autoRepliesToReviewsIsInProgress,
        refetch: refreshAutoReplyList,
    } = useAutoRepliesToReviews({
        count: Number(pagination.pageSize) || 0,
        page: Number(pagination.current) || 0,
    });

    const {
        reviewsManagementAutoReplies: reviewsManagementAutoRepliesRoute,
        reviewsManagementAutoReplyCreate: reviewsManagementAutoReplyCreateRoute,
    } = appRoute;

    function handlePaginationChange(newPagination: TablePaginationConfig) {
        setPagination(newPagination);

        if (pagination.current !== newPagination.current) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, newPagination.current ?? 1);
        }
    }

    const handleChangeCondition = useCallback(
        async (id: number, isEnabled: boolean) => {
            const data = {
                enabled: isEnabled,
            };

            try {
                await fetchAutoReplayChangeCondition(id.toString(), data);

                snackbar.success(<Locale stringKey="AUTO_REPLIES__NOTIFICATION__SUCCESS_UPDATE" />);
                refreshAutoReplyList();
            } catch (error) {
                snackbar.error(
                    isErrorHasStatusMessage<ErrorWithStatus>(error) ? (
                        error.jsonData.status
                    ) : (
                        <Locale stringKey="AUTO_REPLIES__NOTIFICATION__ERROR_UPDATE" />
                    )
                );
            }
        },
        [refreshAutoReplyList, snackbar]
    );

    const handleDelete = useCallback(
        (autoReplyReviewData?: AutoReplyToReviewDataType) => {
            const titleList: string = autoReplyReviewData
                ? autoReplyReviewData.name
                : selectedIdList
                      .map((selectedId: string): string => {
                          const autoReplyToReviewData = findInArray<AutoReplyToReviewDataType>(
                              autoReplyToReviewDataList,
                              {
                                  id: Number(selectedId),
                              }
                          );

                          return toTrimmedString(autoReplyToReviewData?.name || '');
                      })
                      .filter(Boolean)
                      .map((dataTitle: string): string => `"${dataTitle}"`)
                      .join(', ');

            modal.confirm({
                maskClosable: true,
                title: <Locale stringKey="AUTO_REPLIES__REMOVING_A_RULE" />,
                content: (
                    <Locale
                        stringKey="AUTO_REPLIES__THE_RULE_RULE_NAME_WILL_BE_REMOVED_CONFIRM"
                        valueMap={{
                            ruleNames: titleList,
                        }}
                    />
                ),
                okText: <Locale stringKey="TEXT__APPLY" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                cancelButtonProps: {type: 'primary'},
                okButtonProps: {type: 'default', danger: true},
                onOk: async () => {
                    const result = await fetchAutoReplaysDelete({
                        ids: autoReplyReviewData ? autoReplyReviewData.id : selectedIdList,
                    });

                    if (result instanceof Error) {
                        snackbar.error(
                            isErrorHasStatusMessage<ErrorWithStatus>(result) ? (
                                result.jsonData.status
                            ) : (
                                <Locale stringKey="AUTO_REPLIES__NOTIFICATION__ERROR_DELETE" />
                            )
                        );
                    } else {
                        snackbar.success(<Locale stringKey="AUTO_REPLIES__NOTIFICATION__SUCCESS_DELETE" />);
                        refreshAutoReplyList();
                    }
                },
            });
        },
        [selectedIdList, modal, autoReplyToReviewDataList, snackbar, refreshAutoReplyList]
    );

    const handleActivateRequest = useCallback(
        async (enabled: boolean) => {
            const data = {
                ids: selectedIdList,
                enabled,
            };

            const result = await fetchAutoReplayChangeEnabledType(data);

            if (result instanceof Error) {
                snackbar.error(
                    isErrorHasStatusMessage<ErrorWithStatus>(result) ? (
                        result.jsonData.status
                    ) : (
                        <Locale stringKey="AUTO_REPLIES__NOTIFICATION__ERROR_UPDATE" />
                    )
                );
            } else {
                snackbar.success(<Locale stringKey="AUTO_REPLIES__NOTIFICATION__SUCCESS_UPDATE" />);
                refreshAutoReplyList();
            }
        },
        [selectedIdList, snackbar, refreshAutoReplyList]
    );

    return (
        <Page className={styles.auto_replies}>
            <Meta title={getLocalizedString('AUTO_REPLIES__PAGE_HEADER')} />

            <BreadCrumbs
                list={[
                    {
                        path: reviewsManagementAutoRepliesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__AUTO_REPLIES',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle productName={UsetifulNameProductEnum.AUTO_REPLIES} title="AUTO_REPLIES__PAGE_HEADER" />

                <Button type="primary">
                    <NavigationLink to={reviewsManagementAutoReplyCreateRoute.path}>
                        <Locale stringKey="AUTO_REPLIES__ADD_RULE" />
                    </NavigationLink>
                </Button>
            </PageHeader>

            <AutoRepliesLeft className={styles.auto_replies__auto_replies_left} />

            <PageSubHeader>
                <Locale stringKey="AUTO_REPLIES__LIST_OF_RULES" />

                <ListOfRulesActionSelect
                    isActive={hasSelectedCompanies}
                    onActivateRequest={handleActivateRequest}
                    onDelete={handleDelete}
                />
            </PageSubHeader>

            <AutoRepliesTable
                autoRepliesToReviewsIsInProgress={autoRepliesToReviewsIsInProgress}
                autoRepliesToReviewsResult={autoRepliesToReviewsResult?.results || []}
                changeCondition={handleChangeCondition}
                count={autoRepliesToReviewsResult?.count || 0}
                onDelete={handleDelete}
                pagination={pagination}
                setAutoReplyToReviewDataList={setAutoReplyToReviewDataList}
                setPagination={handlePaginationChange}
                setSelectedIdList={setSelectedIdList}
            />
        </Page>
    );
}
