import {Col, Row} from 'antd';

import {LocalPackFilterActive} from './local-pack-filter-active/local-pack-filter-active';
import {LocalPackFilterForm} from './local-pack-filter-form/local-pack-filter-form';
import {LocalPackFilterType} from './local-pack-filter-type';

type PropsType = {
    filter: LocalPackFilterType;
    setFilter: (filter: Partial<LocalPackFilterType>) => void;
};

export function LocalPackFilter(props: PropsType): JSX.Element {
    const {filter, setFilter} = props;

    return (
        <Row align="middle" justify="space-between" wrap={false}>
            <Col>
                <LocalPackFilterActive filter={filter} setFilter={setFilter} />
            </Col>
            <Col>
                <LocalPackFilterForm filter={filter} setFilter={setFilter} />
            </Col>
        </Row>
    );
}
