import {ReviewDataChildType} from '../../../../../service/reviews/reviews-type';

import {ReviewDataWithCatalogType} from './review-list-type';

export function checkLastComment(review: ReviewDataWithCatalogType | ReviewDataChildType): boolean {
    if ((!review.children || review.children.length === 0) && 'isCompanyComment' in review) {
        return !review.isCompanyComment && review.ableToReply;
    }

    // eslint-disable-next-line no-loops/no-loops, no-plusplus
    for (let index = 0; index < review.children.length; index++) {
        if (checkLastComment(review.children[index] as ReviewDataChildType)) {
            return true;
        }
    }

    return false;
}
