import {PropsWithChildren} from 'react';

import {classNames} from '../../util/css';

import * as styles from './page-header.scss';

type PropsType = PropsWithChildren<{
    className?: string;
}>;

export function PageHeaderSub(props: PropsType): JSX.Element {
    const {className, children} = props;

    return <sub className={classNames(styles.PageHeader_headerSub, className)}>{children}</sub>;
}
