import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';

export const averageResponseTimeTimeUnitShortTranslationMap: Record<
    ReviewsAndAnswersStatisticsUnitMeasurementEnum,
    LangKeyType
> = {
    [ReviewsAndAnswersStatisticsUnitMeasurementEnum.Days]: 'REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_DAY__SHORT',
    [ReviewsAndAnswersStatisticsUnitMeasurementEnum.Hours]: 'REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_HOUR__SHORT',
    [ReviewsAndAnswersStatisticsUnitMeasurementEnum.Minutes]: 'REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_MINUTE__SHORT',
};

export const averageResponseTimeTimeUnitTranslationMap: Record<
    ReviewsAndAnswersStatisticsUnitMeasurementEnum,
    LangKeyType
> = {
    [ReviewsAndAnswersStatisticsUnitMeasurementEnum.Days]: 'REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_DAY',
    [ReviewsAndAnswersStatisticsUnitMeasurementEnum.Hours]: 'REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_HOUR',
    [ReviewsAndAnswersStatisticsUnitMeasurementEnum.Minutes]: 'REVIEW_AND_ANSWER_STATISTICS__TIME_UNIT_MINUTE',
};
