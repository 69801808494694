import {faPlus, faSpinnerThird, faUser} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space, Tag, Tooltip} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../provider/locale/locale';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {fetchReviewsReplyTagsSend} from '../../../../service/reviews/reviews';
import {useResponsibleUserSelect} from '../../../../service/reviews/reviews-responsible-users';
import {TagDataType} from '../../../../service/reviews/reviews-tags';
import {ReviewDataTagType, ReviewResponsibleUserType} from '../../../../service/reviews/reviews-type';
import {classNames} from '../../../../util/css';
import {ApiError} from '../../../../util/error';
import {TagsPopup} from '../tags-popup/tags-popup';

import * as styles from './tag-list.scss';

type PropsType = {
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewId: number;
    deletedAt: string | null;
    tags: Array<ReviewDataTagType> | null;
    responsibleUser?: ReviewResponsibleUserType | null;
    isNewLayout?: boolean;
};

export function TagList(props: PropsType): JSX.Element | null {
    const {refreshReview, reviewId, tags, deletedAt, responsibleUser, isNewLayout} = props;
    const tagList = tags || [];

    const {snackbar} = useSnackbar();
    const [isTagsPopupOpen, setIsTagsPopupOpen] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [removingTagIds, setRemovingTagIds] = useState<Array<number>>([]);
    const [isResponsibleUserLoading, setIsResponsibleUserLoading] = useState<boolean>(false);

    const {mutateAsync} = useResponsibleUserSelect(reviewId);

    async function sendTags(repliersTagsIds: Array<number>) {
        const data = {
            tag_ids: repliersTagsIds,
        };

        try {
            await fetchReviewsReplyTagsSend(reviewId, data);
            await refreshReview(reviewId);
            setIsTagsPopupOpen(false);
            snackbar.success(<Locale stringKey="REVIEWS__REVIEW_ANSWER_CHANGE_TAG_SUCCESS" />);
        } catch (error: unknown) {
            snackbar.error(<Locale stringKey="REVIEWS__REVIEW_ANSWER_CHANGE_TAG_FAIL" />);

            if (!(error instanceof ApiError)) {
                throw error;
            }
        }
    }

    async function handleRemoveTag(id: number) {
        const newRemovingTagIds = [...removingTagIds, id];
        const newTagIdsList = tagList
            .map((tag: ReviewDataTagType) => tag.id)
            .filter((tagId: number) => !newRemovingTagIds.includes(tagId));

        setRemovingTagIds(newRemovingTagIds);
        await sendTags([...new Set(newTagIdsList)]);
        setRemovingTagIds((previousRemovingTagIds) => previousRemovingTagIds.filter((innerId) => innerId !== id));
    }

    async function handleApplyTags(newSelectedTagList: Array<TagDataType>) {
        const newTagIdsList = [
            ...tagList.map((tag: ReviewDataTagType) => tag.id),
            ...newSelectedTagList.map((tag) => tag.id),
        ];

        setIsCreating(true);
        await sendTags([...new Set(newTagIdsList)]);
        setIsCreating(false);
    }

    function onAddTagClick() {
        setIsTagsPopupOpen(true);
    }

    async function handleCloseResponsibleUserTag() {
        setIsResponsibleUserLoading(true);
        await mutateAsync(null);
        await refreshReview(reviewId);
        await setIsResponsibleUserLoading(false);
    }

    return (
        <ul className={classNames(styles.TagList)}>
            {responsibleUser && isNewLayout && (
                <li className={styles.TagList_item}>
                    <Tag
                        className={classNames(
                            styles.TagList_itemTag,
                            styles.TagList_itemAutotag,
                            styles.TagList_responsibleUser
                        )}
                        closable={!isResponsibleUserLoading}
                        onClose={handleCloseResponsibleUserTag}
                    >
                        <FontAwesomeIcon icon={faUser} />
                        &nbsp;
                        <Locale stringKey="REVIEWS__RESPONSIBLE_USER__PLACEHOLDER" />
                        :&nbsp;{responsibleUser.fullName}
                        {isResponsibleUserLoading && (
                            <>
                                {' '}
                                <FontAwesomeIcon icon={faSpinnerThird} spin />
                            </>
                        )}
                    </Tag>
                </li>
            )}
            {tagList.map((tagData: ReviewDataTagType): JSX.Element | null => {
                const {id, title, isAspect} = tagData;
                const isTagRemoving = removingTagIds.includes(id);

                const tagClassNames = classNames(styles.TagList_itemTag, {
                    [styles.TagList_itemAutotag]: isAspect,
                    [styles.TagList_disabled]: Boolean(deletedAt),
                });

                if (isAspect && !isNewLayout) {
                    return null;
                }

                return (
                    <li className={styles.TagList_item} key={id}>
                        <Tooltip title={isAspect ? <Locale stringKey="REVIEW__AUTO_TAG__TITLE" /> : ''}>
                            <Tag
                                className={tagClassNames}
                                closable={!isTagRemoving}
                                key={id}
                                onClose={() => handleRemoveTag(id)}
                            >
                                {title}
                                {isTagRemoving && (
                                    <>
                                        {' '}
                                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                                    </>
                                )}
                            </Tag>
                        </Tooltip>
                    </li>
                );
            })}

            <li className={styles.TagList_item} key="new-tag-button">
                <Tag
                    className={classNames(styles.TagList_addTagButton, {[styles.TagList_disabled]: Boolean(deletedAt)})}
                    onClick={onAddTagClick}
                >
                    <Space size={4}>
                        <FontAwesomeIcon icon={faPlus} />
                        <Locale stringKey="REVIEWS__REVIEW__ADD_TAG" />
                    </Space>
                </Tag>

                <TagsPopup
                    isCreating={isCreating}
                    isOpen={isTagsPopupOpen}
                    onApply={handleApplyTags}
                    onClose={() => setIsTagsPopupOpen(false)}
                />
            </li>
        </ul>
    );
}
