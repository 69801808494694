import {Button} from 'antd';

import {appRoute} from '../../app-route';
import {Locale} from '../../provider/locale/localization';

import * as styles from './access-denied-banner.scss';

export function AccessDeniedBanner(): JSX.Element {
    return (
        <div className={styles.AccessDeniedBanner}>
            <svg fill="none" height="236" viewBox="0 0 266 236" width="266" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M133 235.801C206.29 235.801 265.703 222.901 265.703 206.988C265.703 191.075 206.29 178.175 133 178.175C59.7103 178.175 0.29718 191.075 0.29718 206.988C0.29718 222.901 59.7103 235.801 133 235.801Z"
                    fill="#F5F5F5"
                />
                <mask fill="white" id="path-2-inside-1_15423_96877">
                    <path
                        clipRule="evenodd"
                        d="M149 0.198975H117C93.804 0.198975 75 19.003 75 42.199V75.0001H54C46.8203 75.0001 41 80.8203 41 88V202.369C41 209.549 46.8203 215.369 54 215.369H211.209C218.388 215.369 224.209 209.549 224.209 202.369V88.0001C224.209 80.8204 218.389 75.0001 211.209 75.0001H191V42.199C191 19.003 172.196 0.198975 149 0.198975ZM163 75.0001V42.199C163 34.467 156.732 28.199 149 28.199H117C109.268 28.199 103 34.467 103 42.199V75.0001H163ZM127 137C127 133.686 129.686 131 133 131C136.314 131 139 133.686 139 137V155C139 158.314 136.314 161 133 161C129.686 161 127 158.314 127 155V137Z"
                        fillRule="evenodd"
                    />
                </mask>
                <path
                    clipRule="evenodd"
                    d="M149 0.198975H117C93.804 0.198975 75 19.003 75 42.199V75.0001H54C46.8203 75.0001 41 80.8203 41 88V202.369C41 209.549 46.8203 215.369 54 215.369H211.209C218.388 215.369 224.209 209.549 224.209 202.369V88.0001C224.209 80.8204 218.389 75.0001 211.209 75.0001H191V42.199C191 19.003 172.196 0.198975 149 0.198975ZM163 75.0001V42.199C163 34.467 156.732 28.199 149 28.199H117C109.268 28.199 103 34.467 103 42.199V75.0001H163ZM127 137C127 133.686 129.686 131 133 131C136.314 131 139 133.686 139 137V155C139 158.314 136.314 161 133 161C129.686 161 127 158.314 127 155V137Z"
                    fill="#F0F0F0"
                    fillRule="evenodd"
                />
                <path
                    d="M75 75.0001V83.0001H83V75.0001H75ZM191 75.0001H183V83.0001H191V75.0001ZM163 75.0001V83.0001H171V75.0001H163ZM103 75.0001H95V83.0001H103V75.0001ZM117 8.19897H149V-7.80103H117V8.19897ZM83 42.199C83 23.4213 98.2223 8.19897 117 8.19897V-7.80103C89.3858 -7.80103 67 14.5847 67 42.199H83ZM83 75.0001V42.199H67V75.0001H83ZM75 67.0001H54V83.0001H75V67.0001ZM54 67.0001C42.402 67.0001 33 76.402 33 88H49C49 85.2386 51.2386 83.0001 54 83.0001V67.0001ZM33 88V202.369H49V88H33ZM33 202.369C33 213.967 42.402 223.369 54 223.369V207.369C51.2386 207.369 49 205.13 49 202.369H33ZM54 223.369H211.209V207.369H54V223.369ZM211.209 223.369C222.807 223.369 232.209 213.967 232.209 202.369H216.209C216.209 205.13 213.97 207.369 211.209 207.369V223.369ZM232.209 202.369V88.0001H216.209V202.369H232.209ZM232.209 88.0001C232.209 76.4021 222.807 67.0001 211.209 67.0001V83.0001C213.97 83.0001 216.209 85.2386 216.209 88.0001H232.209ZM211.209 67.0001H191V83.0001H211.209V67.0001ZM183 42.199V75.0001H199V42.199H183ZM149 8.19897C167.778 8.19897 183 23.4213 183 42.199H199C199 14.5847 176.614 -7.80103 149 -7.80103V8.19897ZM171 75.0001V42.199H155V75.0001H171ZM171 42.199C171 30.0487 161.15 20.199 149 20.199V36.199C152.314 36.199 155 38.8853 155 42.199H171ZM149 20.199H117V36.199H149V20.199ZM117 20.199C104.85 20.199 95 30.0487 95 42.199H111C111 38.8853 113.686 36.199 117 36.199V20.199ZM95 42.199V75.0001H111V42.199H95ZM163 67.0001H103V83.0001H163V67.0001ZM133 123C125.268 123 119 129.268 119 137H135C135 138.105 134.105 139 133 139V123ZM147 137C147 129.268 140.732 123 133 123V139C131.895 139 131 138.105 131 137H147ZM147 155V137H131V155H147ZM133 169C140.732 169 147 162.732 147 155H131C131 153.895 131.895 153 133 153V169ZM119 155C119 162.732 125.268 169 133 169V153C134.105 153 135 153.895 135 155H119ZM119 137V155H135V137H119Z"
                    fill="#D9D9D9"
                    mask="url(#path-2-inside-1_15423_96877)"
                />
            </svg>

            <h3 className={styles.AccessDeniedBanner_title}>
                <Locale stringKey="ERROR__ACCESS_DENIED_BANNER__TITLE" />
            </h3>
            <p className={styles.AccessDeniedBanner_subtitle}>
                <Locale stringKey="ERROR__ACCESS_DENIED_BANNER__SUB_TITLE" />
            </p>
            <Button href={appRoute.root.path} type="primary">
                <Locale stringKey="PAGE__ERROR__LINK_TO_HOME" />
            </Button>
        </div>
    );
}
