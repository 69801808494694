import {ItemType} from 'antd/es/menu/interface';

import {UserType} from '../user/user-type';

import {AnalyticsDataType, AnalyticsTarget} from './analytics';

export enum AnalyticsEventEnum {
    RocketdataEvent = 'RocketdataEvent',
    RocketdataAction = 'RocketdataAction',
}

export type RecursiveValueOfType<T> = T extends object
    ? RecursiveValueOfType<T[keyof T]>
    : T extends string
    ? T
    : never;

export type UseAnalyticsType = {
    initialize: (user: UserType) => void;
    track: <TKey extends RecursiveValueOfType<typeof AnalyticsTarget>>(
        ...args: TKey extends keyof AnalyticsDataType ? [key: TKey, data: AnalyticsDataType[TKey]] : [key: TKey]
    ) => void;
    trackNavigation: (pathname: string, previousPathname: string, menuItems: Array<ItemType>) => void;
};
