import {Form, Input, Modal} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {useRemoveProfileMutation} from '../../../../../service/profile/profile-hook';
import {DeleteProfileType} from '../../profile-type';
import {DeleteProfileFormFieldsEnum} from '../personal-information-const';

type PropsType = {
    isOpenDeleteAccountModal: boolean;
    setIsOpenDeleteAccountModal: Dispatch<SetStateAction<boolean>>;
};

export function DeleteAccountModal(props: PropsType): ReactElement {
    const {setIsOpenDeleteAccountModal, isOpenDeleteAccountModal} = props;

    const {getLocalizedString} = useLocale();
    const {requiredFieldRule} = useFormRules();
    const [form] = Form.useForm<DeleteProfileType>();

    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const [errorText, setErrorText] = useState('');

    const {mutateAsync: removeProfile, isLoading} = useRemoveProfileMutation(setErrorText);

    async function handleRemoveProfile() {
        const passwordField = form.getFieldValue(DeleteProfileFormFieldsEnum.password);

        if (!passwordField) {
            setErrorText(getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED'));
            return;
        }

        await removeProfile(passwordField);
    }

    function onFieldsChange() {
        setErrorText('');
    }

    return (
        <Modal
            confirmLoading={isLoading}
            onCancel={() => setIsOpenDeleteAccountModal(false)}
            onOk={handleRemoveProfile}
            open={isOpenDeleteAccountModal}
            title={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__TITLE" />}
            width={472}
        >
            <Form<DeleteProfileType> form={form} layout="vertical" onFieldsChange={onFieldsChange}>
                <Text stringKey="PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__DESCRIPTION" />

                <Form.Item
                    help={errorText}
                    label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__PASSWORD__LABEL" />}
                    name={DeleteProfileFormFieldsEnum.password}
                    rules={[requiredFieldRule]}
                    validateStatus={errorText ? 'error' : ''}
                >
                    <Input.Password
                        placeholder={getLocalizedString(
                            'PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__PASSWORD__PLACEHOLDER'
                        )}
                        size="large"
                        visibilityToggle={{
                            visible: isShowPassword,
                            onVisibleChange: setIsShowPassword,
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
