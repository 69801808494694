import {faHourglassHalf, faShop, faShopSlash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useCompanySummary} from '../../../../service/dashboard/dashboard-hook';
import {useFormat} from '../../../../util/format-hook/format-hook';

import * as styles from './company-summary.scss';

export function CompanySummary(): JSX.Element {
    const {result, isInProgress, processError} = useCompanySummary();
    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    return (
        <div className={styles.company_summary__container}>
            <div className={styles.company_summary__title}>
                {getLocalizedString('DASHBOARD_PAGE__COMPANY_SUMMARY__HEADER')}
            </div>
            <Tooltip
                className={styles.company_summary__tooltip__text}
                placement="bottom"
                title={getLocalizedString('DASHBOARD_PAGE__COMPANY_SUMMARY__ACTIVE')}
            >
                <div className={styles.company_summary__active__wrapper}>
                    <div className={styles.company_summary__status__container}>
                        <FontAwesomeIcon icon={faShop} />
                        <div className={styles.company_summary__status__text}>{getFormattedNumber(result.active)}</div>
                    </div>
                </div>
            </Tooltip>

            <Tooltip
                className={styles.company_summary__tooltip__text}
                placement="bottom"
                title={getLocalizedString('DASHBOARD_PAGE__COMPANY_SUMMARY__TEMPORARY_CLOSED')}
            >
                <div className={styles.company_summary__temp_closed__wrapper}>
                    <div className={styles.company_summary__status__container}>
                        <FontAwesomeIcon icon={faHourglassHalf} />
                        <div className={styles.company_summary__status__text}>
                            {getFormattedNumber(result.temporaryClosed)}
                        </div>
                    </div>
                </div>
            </Tooltip>

            <Tooltip
                className={styles.company_summary__tooltip__text}
                placement="bottom"
                title={getLocalizedString('DASHBOARD_PAGE__COMPANY_SUMMARY__CLOSED')}
            >
                <div className={styles.company_summary__closed__wrapper}>
                    <div className={styles.company_summary__status__container}>
                        <FontAwesomeIcon icon={faShopSlash} />
                        <div className={styles.company_summary__status__text}>{getFormattedNumber(result.closed)}</div>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}
