import {getShortLocaleName} from './locale-context-helper';
import {LocaleContextType} from './locale-context-type';
import {useLocalizationHook} from './localization';

export function useLocale(): LocaleContextType {
    const {isFetchingLocaleData, localeName, setLocaleName, getLocalizedString} = useLocalizationHook();

    return {
        isFetchingLocaleData,
        localeName,
        setLocaleName,
        getLocalizedString,
        shortLocaleName: getShortLocaleName(localeName),
    };
}
