import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../service/feature-companies/feature-companies-type';
import {
    PostAiSuggestHookType,
    PostFormModeEnum,
    PostFormType,
    PostPreviewDetailsType,
    SourcePostType,
} from '../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../typings/antd';

export enum ImageMimeEnum {
    JPG = 'JPG',
    PNG = 'PNG',
    JPEG = 'JPEG',
}

export type ImageContentValidationSettingsType = {
    maxCount: number;
    maxHeight: number;
    maxWidth: number;
    minHeight: number;
    minWidth: number;
    imageMime: Array<ImageMimeEnum>;
};

export type ExtraItemContentValidationSettingsType = {
    [key in string]: LangKeyType;
};

export type ExtraContentValidationSettingsType = {
    images?: ExtraItemContentValidationSettingsType;
    text?: ExtraItemContentValidationSettingsType;
    action?: ExtraItemContentValidationSettingsType;
    offer?: ExtraItemContentValidationSettingsType;
};

export type ContentValidationSettingsType = {
    maxTextLength: number;
    images: ImageContentValidationSettingsType;
    extra?: ExtraContentValidationSettingsType;
    video?: ExtraItemContentValidationSettingsType;
    catalogRequirementsLink?: string;
};

export type SourcePostPropsType = {
    formInstance: FormInstance<PostFormType>;
    suggestSettings: PostAiSuggestHookType;
    sourceIndex: number;
    formMode: PostFormModeEnum;
    companiesSelector?: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
};

export type PostPreviewPropsType = {
    postData?: SourcePostType;
    companyInfo?: PostPreviewDetailsType;
};
