import {Badge, Button, Space} from 'antd';
import {camelize} from 'humps';
import {ReactElement, useState} from 'react';

import {Text} from '../../../../component/text/text';
import {VideoPopup} from '../../../../component/video-popup/video-popup';
import {orange50, primary50} from '../../../../css/var-export.scss';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {useVideoLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {OnboardingStepNameEnum} from '../../../../provider/onboarding/onboarding-hook';
import {useSystem} from '../../../../provider/system/system-hook';

import {
    recommendationButtonKeyMap,
    recommendationDescriptionKeyMap,
    recommendationImageBackgroundKeyMap,
    recommendationNavigationLinkKeyMap,
    recommendationTitleKeyMap,
} from './recommendations-const';
import {useCompletionRecommendation, useRecommendations} from './recommendations-hook';
import {RecommendationsVideoBlock} from './video-block/video-block';
import * as styles from './recommendations.scss';

type PropsType = {
    stepName: OnboardingStepNameEnum;
    isDemoOnboarding: boolean;
};

export function Recommendations(props: PropsType): ReactElement | null {
    const {stepName, isDemoOnboarding} = props;
    const {data} = useRecommendations(stepName, isDemoOnboarding);
    const {getLocalizedString} = useLocale();
    const [targetVideo, setTargetVideo] = useState<string | null>(null);
    const [isLoadImage, setIsLoadImage] = useState<boolean>(false);
    const {recommendations} = useVideoLinks();
    const {mutate} = useCompletionRecommendation();
    const {screen} = useSystem();

    if (!data?.[0]) {
        return null;
    }

    const {recommendationName, availableCatalogs, trialAvailable} = data[0];

    const recommendationsKey = camelize(recommendationName) as keyof typeof recommendations;
    const recommendationsVideoLink = recommendations[recommendationsKey] ?? null;

    return (
        <div className={styles.Recommendations}>
            {targetVideo && <VideoPopup onClose={() => setTargetVideo(null)} url={targetVideo} />}
            <Badge.Ribbon
                color={trialAvailable ? orange50 : primary50}
                text={
                    availableCatalogs.length > 0 && availableCatalogs[0] !== 'all' ? (
                        <Locale
                            stringKey="ONBOARDING__RECOMMENDATION__TAG__IS_AVAILABLE_FOR"
                            valueMap={{
                                catalog: availableCatalogs.join(
                                    ` ${getLocalizedString('ONBOARDING__RECOMMENDATION__TAG__AND_TEXT')} `
                                ),
                            }}
                        />
                    ) : trialAvailable ? (
                        <Locale stringKey="ONBOARDING__RECOMMENDATION__TAG__IS_TRIAL" />
                    ) : (
                        <Locale stringKey="ONBOARDING__RECOMMENDATION__TAG__IS_AVAILABLE" />
                    )
                }
            >
                <div className={styles.Recommendations_wrapper}>
                    <div className={styles.Recommendations_imageBlock}>
                        <img
                            alt=""
                            className={styles.Recommendations_image}
                            onLoad={() => setIsLoadImage(true)}
                            src={recommendationImageBackgroundKeyMap[recommendationName]}
                        />

                        {recommendationsVideoLink && (
                            <RecommendationsVideoBlock
                                isLoadImage={isLoadImage}
                                recommendationsVideoLink={recommendationsVideoLink}
                                setTargetVideo={setTargetVideo}
                            />
                        )}
                    </div>
                    <Space className={styles.Recommendations_descriptionBlock} direction="vertical">
                        <Text bolder large stringKey={recommendationTitleKeyMap[recommendationName]} />
                        <Text lighter stringKey={recommendationDescriptionKeyMap[recommendationName]} />
                        <Space
                            className={styles.Recommendations_buttonWrapper}
                            direction={screen.isMobile ? 'vertical' : 'horizontal'}
                            size={16}
                        >
                            <NavigationLink isTargetBlank to={recommendationNavigationLinkKeyMap[recommendationName]}>
                                <Button
                                    className={styles.Recommendations_button}
                                    onClick={() => mutate(recommendationName)}
                                    type="primary"
                                >
                                    <Locale stringKey={recommendationButtonKeyMap[recommendationName]} />
                                </Button>
                            </NavigationLink>
                        </Space>
                    </Space>
                </div>
            </Badge.Ribbon>
        </div>
    );
}
