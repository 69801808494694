import {faNewspaper} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../../app-route';
import {DashboardEmpty} from '../../empty/dashboard-empty';

export function PostsEmpty(): JSX.Element {
    return (
        <DashboardEmpty
            buttonTextLangKey="DASHBOARD_PAGE__POSTS__EMPTY__BUTTON"
            descriptionLangKey="DASHBOARD_PAGE__POSTS__EMPTY__DESCRIPTION"
            icon={<FontAwesomeIcon icon={faNewspaper} />}
            navigationPath={appRoute.postsManagement.path}
            titleLangKey="DASHBOARD_PAGE__POSTS__EMPTY__TITLE"
        />
    );
}
