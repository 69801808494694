import {Context} from 'chartjs-plugin-datalabels';
import {renderToString} from 'react-dom/server';

import * as cssVariableStyles from '../../../css/var-export.scss';

type CustomReactChartTooltipArgumentType = {
    context: Context;
    indentLeft?: number;
    indentTop?: number;
    componentRenderer: (index: number) => JSX.Element;
};

// eslint-disable-next-line max-statements
export function customReactChartTooltip({
    context,
    componentRenderer,
    indentLeft,
    indentTop,
}: CustomReactChartTooltipArgumentType): void {
    const existedElement = document.querySelector('#chartjs-tooltip');

    existedElement?.remove();
    const tooltipElement = document.createElement('div');

    tooltipElement.id = 'chartjs-tooltip';
    document.body.append(tooltipElement);

    const tooltipModel = context.tooltip;

    if (!tooltipModel) {
        return;
    }

    if (tooltipModel.opacity === 0) {
        tooltipElement.style.opacity = '0';
        return;
    }

    tooltipElement.classList.remove('above', 'below', 'no-transform');

    if (tooltipModel.yAlign) {
        tooltipElement.classList.add(tooltipModel.yAlign);
    } else {
        tooltipElement.classList.add('no-transform');
    }

    const activeElement = context.tooltip.getActiveElements()[0];

    if (activeElement) {
        const position = context.chart.canvas.getBoundingClientRect();
        const tooltipPositionLeft = indentLeft ?? position.left + window.scrollX + tooltipModel.caretX;
        const tooltipPositionTop = indentTop ?? position.top + window.scrollY + tooltipModel.caretY;

        tooltipElement.setAttribute(
            'style',
            `
                background: rgba(255, 255, 255, 1);
                pointerEvents: none;
                borderRadius: 3px;
                color: ${cssVariableStyles.gray10};
                border: 1px solid ${cssVariableStyles.gray4};
                opacity: 1;
                position: absolute;
                backgroundColor: ${cssVariableStyles.gray1};
                left: ${tooltipPositionLeft + 'px'};
                top: ${tooltipPositionTop + 'px'};
                width: 0;
                height: 0;
                transition: all .1s ease;
                `
        );

        // Display, position, and set styles for font
        tooltipElement.style.pointerEvents = 'none';
        tooltipElement.style.opacity = '1';
        tooltipElement.style.position = 'absolute';
        tooltipElement.style.backgroundColor = '#fff';
        tooltipElement.style.left = tooltipPositionLeft + 'px';
        tooltipElement.style.top = tooltipPositionTop - 8 + 'px';
        tooltipElement.style.width = '0';
        tooltipElement.style.height = '0';

        tooltipElement.innerHTML = renderToString(componentRenderer(activeElement.index));
    }
}
