import {Text} from '../../../../../../../../../component/text/text';
import {PostPreviewPropsType} from '../../../content-step-type';
import {ExpandableTextPreview} from '../expandable-text-preview/expandable-text-preview';

import {VkontaktePreviewFooter} from './components/vkontakte-preview-footer/vkontakte-preview-footer';
import {VkontaktePreviewHeader} from './components/vkontakte-preview-header/vkontakte-preview-header';
import {VkontaktePreviewMedia} from './components/vkontakte-preview-media/vkontakte-preview-media';
import * as styles from './vkontakte-preview.scss';

export function VkontaktePreview(props: PostPreviewPropsType): JSX.Element | null {
    const {postData, companyInfo} = props;

    if (!postData) {
        return null;
    }

    const {images, video, text} = postData;

    return (
        <div className={styles.VkontaktePreview}>
            <VkontaktePreviewHeader companyInfo={companyInfo} />
            <ExpandableTextPreview
                expandButton={
                    <Text
                        className={styles.VkontaktePreview_expand}
                        stringKey="POSTS_FORM__PREVIEW__VKONTAKTE__READ_MORE"
                    />
                }
                text={text}
            />
            <VkontaktePreviewMedia images={images} video={video} />
            <VkontaktePreviewFooter />
        </div>
    );
}
