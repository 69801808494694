import {InputNumber} from 'antd';

import {Form} from '../../../../../../../typings/antd';
import {AttributePropsType, NumericSingleAttributeType} from '../attributes-type';

export function NumericAttribute({
    value,
    onChange,
}: AttributePropsType<NumericSingleAttributeType>): JSX.Element | null {
    const {displayName: label, values} = value;

    const firstAndOnlyValue = values[0];

    const {status} = Form.Item.useStatus();

    if (!firstAndOnlyValue) {
        return null;
    }

    return (
        <Form.Item label={label}>
            <InputNumber
                onChange={(newNumber) =>
                    newNumber !== null && onChange?.({...value, values: [{...firstAndOnlyValue, value: newNumber}]})
                }
                status={status === 'error' ? status : undefined} // eslint-disable-line no-undefined
                type="number"
                value={values.find((item) => item.value)?.value}
            />
        </Form.Item>
    );
}
