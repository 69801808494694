import {Tag} from 'antd';

import {Locale} from '../../../../provider/locale/localization';
import {CatalogPostStatusEnum} from '../../../../service/posts/posts-types';

import {CATALOG_POST_STATUS_OPTIONS} from './post-status-const';

type PropsType = {
    status: CatalogPostStatusEnum;
};

export function CatalogPostStatus(props: PropsType): JSX.Element {
    const {status} = props;

    const postStatusOption = CATALOG_POST_STATUS_OPTIONS[status];

    return (
        <Tag color={postStatusOption.color}>
            <Locale stringKey={postStatusOption.name} />
        </Tag>
    );
}
