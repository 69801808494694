import {RatingStarLine} from '../../../../../../layout/rating-star-line/rating-star-line';
import {ReviewDataType} from '../../../../../../service/reviews/reviews-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import * as styles from './reviews-item-header.scss';

type PropsType = {
    reviewData: ReviewDataType;
};

export function DashboardReviewsItemHeader(props: PropsType): JSX.Element {
    const {reviewData} = props;
    const {author, createdInCatalog, rating, createdInRd} = reviewData;
    const {getFormattedDateTime} = useFormat();
    const dateCreated = (createdInCatalog || createdInRd).replace('Z', '');

    return (
        <div className={styles.review__header__wrapper}>
            <div className={styles.review__header__author_info}>{author}</div>
            {typeof rating === 'number' && (
                <RatingStarLine
                    className={styles.review__header__rating_starts}
                    color={styles.rating_star_color}
                    rating={rating}
                />
            )}
            <div className={styles.review__header__date}>
                {getFormattedDateTime(new Date(dateCreated), {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })}
            </div>
        </div>
    );
}
