import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Popover, Select} from 'antd';
import {useState} from 'react';

import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../provider/locale/locale';
import {FieldModeEnum} from '../../../../service/company/company-type';
import {TagMode} from '../tag-mode/tag-mode';

import {POPOVER_TEXT_BY_MODE, TAG_TEXT_BY_MODE} from './field-mode-tag-const';

type PropsType = {
    value: FieldModeEnum;
    onChange: (newValue: FieldModeEnum) => void;
    isChangable: boolean;
};

export function FieldModeTag(props: PropsType): JSX.Element {
    const {value, isChangable, onChange} = props;

    const [isOpen, setIsOpen] = useState(false);

    const text = TAG_TEXT_BY_MODE[value];
    const {title, content} = POPOVER_TEXT_BY_MODE[value];

    function handleChange(newMode: FieldModeEnum) {
        onChange(newMode);
        setIsOpen(false);
    }

    if (isOpen) {
        return (
            <Select<FieldModeEnum> autoFocus defaultOpen onChange={handleChange} size="small" value={value}>
                <Select.Option value={FieldModeEnum.addition}>
                    <Locale stringKey="BULK_EDIT_COMPANIES__FIELD_MODE__ADDITION" />
                </Select.Option>
                <Select.Option value={FieldModeEnum.replacement}>
                    <Locale stringKey="BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT" />
                </Select.Option>
            </Select>
        );
    }

    if (isChangable) {
        return (
            <>
                <Popover
                    content={
                        <PopoverTextWrapper>
                            <Locale stringKey={content} />
                        </PopoverTextWrapper>
                    }
                    title={
                        <PopoverTextWrapper>
                            <Locale stringKey={title} />
                        </PopoverTextWrapper>
                    }
                >
                    <span>
                        <TagMode mode={value}>{text}</TagMode>
                    </span>
                </Popover>

                <Button
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={() => setIsOpen(true)}
                    size="small"
                    type="link"
                />
            </>
        );
    }

    return (
        <Popover
            content={
                <PopoverTextWrapper>
                    <Locale stringKey={content} />
                </PopoverTextWrapper>
            }
            title={
                <PopoverTextWrapper>
                    <Locale stringKey={title} />
                </PopoverTextWrapper>
            }
        >
            <span>
                <TagMode mode={value}>{text}</TagMode>
            </span>
        </Popover>
    );
}
