import {Form, Input} from 'antd';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.email> & {
    className?: string;
};

export function EmailFormItem(props: PropsType): JSX.Element {
    const {value, onChange, className, errorMessage} = props;

    const {getLocalizedString} = useLocale();

    return (
        <div className={className}>
            <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : ''}>
                <Input
                    onInput={(event) => onChange(event.currentTarget.value)}
                    placeholder={getLocalizedString('CONTACTS__PLACEHOLDER__EMAIL_ADDRESS')}
                    size="large"
                    value={value}
                />
            </Form.Item>
        </div>
    );
}
