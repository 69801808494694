import {Select} from 'antd';

import {AddressType, IdNameType} from '../../../../../../../service/api/api-type';

const {Option} = Select;

export function getUniqueCleanValueList(addressList: Array<AddressType>, addressKey: keyof AddressType): Array<string> {
    return [...new Set(addressList.map((addressInList: AddressType): unknown => addressInList[addressKey]))]
        .filter(Boolean)
        .map(String);
}

export function getAddressByAddressKey(
    addressList: Array<AddressType>,
    addressKey: keyof AddressType,
    addressValue: string
): AddressType | null {
    return (
        addressList.find((address: AddressType): boolean => {
            return address[addressKey] === addressValue;
        }) || null
    );
}

export function renderOption(value: IdNameType | string): JSX.Element {
    return (
        <Option key={typeof value === 'string' ? value : value.id} value={typeof value === 'string' ? value : value.id}>
            {typeof value === 'string' ? value : value.name}
        </Option>
    );
}
