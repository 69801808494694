import {useMutation, UseMutationResult} from '@tanstack/react-query';

import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {fakesUrl} from './fakes-const';

const fetchBuyFakesLicenseUrl = `${rootApiUrl}${fakesUrl}/licence/full/`;

type BuyLicenseOptionsType = {
    message: string;
};

function fetchBuyFakesLicense(options: BuyLicenseOptionsType): Promise<void> {
    return fetchNoContent(fetchBuyFakesLicenseUrl, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize(options)),
    });
}

export function useBuyFakesLicenseMutation(): UseMutationResult<void, unknown, BuyLicenseOptionsType> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (licenseOptions: BuyLicenseOptionsType) => fetchBuyFakesLicense(licenseOptions),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="SNACKBAR__SUCCESS__SEND_APPLICATION__TITLE" />,
                description: <Locale stringKey="SNACKBAR__SUCCESS__SEND_APPLICATION__DESCRIPTION" />,
            });
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}
