import {
    LocalPackPointDistanceEnum,
    LocalPackSquareSizeEnum,
} from '../../../../../../../service/local-pack/local-pack-type';
import {
    LocalPackActivationRuleFormType,
    LocalPackRuleFrequencyEnum,
    LocalPackTrialPeriodActivationFormFieldEnum,
} from '../../../../../../../service/local-pack/rules/local-pack-rule-list-item-type';

export function getLocalPackTrialActivationFormInitialValues(
    companiesSelectorId: string
): LocalPackActivationRuleFormType {
    return {
        [LocalPackTrialPeriodActivationFormFieldEnum.CompaniesSelector]: companiesSelectorId,
        [LocalPackTrialPeriodActivationFormFieldEnum.SearchPhrase]: '',
        [LocalPackTrialPeriodActivationFormFieldEnum.Services]: [],
        [LocalPackTrialPeriodActivationFormFieldEnum.Frequency]: LocalPackRuleFrequencyEnum.Once,
        [LocalPackTrialPeriodActivationFormFieldEnum.SquareSize]: LocalPackSquareSizeEnum.Small,
        [LocalPackTrialPeriodActivationFormFieldEnum.PointDistance]: LocalPackPointDistanceEnum.Low,
    };
}

export const LOCAL_PACK_TRIAL_PERIOD_DAYS = 14;
