import {faComment, faStar} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tabs, Tooltip} from 'antd';

import {AlertFallback} from '../../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {FormattedNumber} from '../../../../../../provider/locale/formatted-number';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useCompanyMapInfo} from '../../../../../../service/dashboard/dashboard-hook';
import {CompaniesMapStatsEnum, CompanyStatType} from '../../../../../../service/dashboard/dashboard-type';
import {NumberFormatOptionsType} from '../../../../../../util/format';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {toTrimmedString} from '../../../../../../util/string';
import {numberOfDays} from '../../dashboard-companies-map-const';

import {onlinePresenceValueLocalizationMap} from './company-info-const';
import * as styles from './company-info.scss';

type PropsType = {
    companyId: number;
};

export function CompanyInfo(props: PropsType): JSX.Element {
    const {companyId} = props;
    const {result, isInProgress, processError} = useCompanyMapInfo(companyId);
    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();
    const percentageFormat: NumberFormatOptionsType = {
        unit: 'percent',
        style: 'percent',
        maximumFractionDigits: 0,
    };

    if (isInProgress || !result) {
        return <Spinner size={276} />;
    }

    if (processError) {
        return <AlertFallback />;
    }

    const addressText: string = [
        result.company.address.city,
        result.company.address.street,
        result.company.address.housenumber,
    ]
        .map(toTrimmedString)
        .filter(Boolean)
        .join(', ');

    return (
        <div>
            <div className={styles.company_info__title}>
                <span className={styles.company_info__title__company_name}>{result.company.name}</span>
                <span className={styles.company_info__title__company_code}>{result.company.id}</span>
            </div>
            <div className={styles.company_info__address}>{addressText}</div>

            <div className={styles.company_info__stats_container}>
                <Tooltip
                    placement="bottom"
                    title={getLocalizedString('DASHBOARD_PAGE__COMPANIES_MAP__RATING__TOOLTIP')}
                >
                    <div className={styles.company_info__stats_container__values}>
                        <FontAwesomeIcon
                            className={styles.company_info__stats_container__values__rating_star_icon}
                            icon={faStar}
                        />
                        <span className={styles.company_info__stats_container__values__numbers}>
                            <FormattedNumber value={Number(result.company.rating) || 0} />
                        </span>
                    </div>
                </Tooltip>
                <Tooltip
                    placement="bottom"
                    title={getLocalizedString('DASHBOARD_PAGE__COMPANIES_MAP__REVIEWS__TOOLTIP')}
                >
                    <div className={styles.company_info__stats_container__values}>
                        <FontAwesomeIcon
                            className={styles.company_info__stats_container__values__reviews_icon}
                            icon={faComment}
                        />
                        <span className={styles.company_info__stats_container__values__numbers}>
                            {getFormattedNumber(result.company.reviewsCount)}
                        </span>
                    </div>
                </Tooltip>
            </div>

            <div className={styles.company_info__stats_block}>
                <p className={styles.company_info__stats_block__title}>
                    <Locale stringKey="DASHBOARD_PAGE__COMPANIES_MAP__STATS__HEADER" valueMap={{count: numberOfDays}} />
                </p>
                <Tabs
                    items={result.catalogStats.map((companyStat: CompanyStatType) => {
                        return {
                            key: companyStat.id.toString(),
                            label: companyStat.name,
                            children: (
                                <div className={styles.company_info__stats_block__stats}>
                                    {Object.values(CompaniesMapStatsEnum).map((statName: CompaniesMapStatsEnum) => {
                                        return (
                                            <div
                                                className={styles.company_info__stats_block__stats__stat_info}
                                                key={statName}
                                            >
                                                <div className={styles.company_info__stats_block__stats__name}>
                                                    {getLocalizedString(
                                                        onlinePresenceValueLocalizationMap[
                                                            statName as CompaniesMapStatsEnum
                                                        ]
                                                    )}
                                                </div>
                                                <div className={styles.company_info__stats_block__stats__counter}>
                                                    <FormattedNumber
                                                        options={
                                                            statName === CompaniesMapStatsEnum.conversion
                                                                ? percentageFormat
                                                                : {}
                                                        }
                                                        value={companyStat[statName]}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
        </div>
    );
}
