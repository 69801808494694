import {OnboardingStepEnum} from '../../page/main/onboarding/onboarding-const';

import {ImportStatusEnum} from './onboarding-hook';
import {OnboardingProviderType} from './onboarding-type';

export const ONBOARDING_INITIAL_VALUE: OnboardingProviderType = {
    openStep: OnboardingStepEnum.first,
    onboardingPercent: 0,
    onboardingStepValue: [],
    isOnboardingCompleted: false,
    isDemoOnboarding: false,
    setDemoOnboardingStep: function setOpen() {
        return;
    },
    setIsDemoOnboarding: function setOpen() {
        return;
    },
    demoOnboardingStep: OnboardingStepEnum.first,
    openAutoSyncPopup: false,
    setOpenAutoSyncPopup: function setOpen() {
        return;
    },
    refetch: () => {
        return;
    },
    showOnboarding: false,
    onboardingData: {
        accountsConnectionStep: {accountsConnection: {}},
        syncCompaniesStep: {syncCompanies: {}},
        confirmCompaniesStep: {confirmCompanies: {}},
        addCompaniesStep: {passed: false, importStatus: ImportStatusEnum.InProgress},
        id: 1,
        counterparty: 2,
    },
    hasWarningStatus: false,
};
