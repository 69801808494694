import {Locale} from '../../../../provider/locale/locale';
import {WeekDaysEnum} from '../../../../provider/locale/locale-context-const';

export const WEEK_DAY_TO_LOCALIZED_STRING = {
    [WeekDaysEnum.monday]: <Locale stringKey="WEEK_DAY__SHORT__MONDAY" />,
    [WeekDaysEnum.tuesday]: <Locale stringKey="WEEK_DAY__SHORT__TUESDAY" />,
    [WeekDaysEnum.wednesday]: <Locale stringKey="WEEK_DAY__SHORT__WEDNESDAY" />,
    [WeekDaysEnum.thursday]: <Locale stringKey="WEEK_DAY__SHORT__THURSDAY" />,
    [WeekDaysEnum.friday]: <Locale stringKey="WEEK_DAY__SHORT__FRIDAY" />,
    [WeekDaysEnum.saturday]: <Locale stringKey="WEEK_DAY__SHORT__SATURDAY" />,
    [WeekDaysEnum.sunday]: <Locale stringKey="WEEK_DAY__SHORT__SUNDAY" />,
} as const;
