import {useState} from 'react';

import {AntdErrorMessage} from '../../../../../../../component/antd-error-message/antd-error-message';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useCompanyMediaFile} from '../../../../../../../service/company/company-hook';
import {CompanyImageKindEnum} from '../../../../../../../service/company/company-type';
import {CompanyImageFormType} from '../../../../../../../service/company-v2/types/company-logo-type';
import {classNames} from '../../../../../../../util/css';
import {InfoForImage} from '../../../info-for-image/info-for-image';
import {InputImage} from '../input-image/input-image';

import {LOGO_SIZE} from './logo-form-item-const';
import * as styles from './logo-form-item.scss';

type PropsType = {
    value?: CompanyImageFormType;
    onChange?: (value: CompanyImageFormType | null) => void;
    className?: string;
};

export function LogoFormItem(props: PropsType): JSX.Element {
    const {value, onChange, className} = props;

    const [inputImage, setInputImage] = useState<CompanyImageFormType>(value ?? {});

    const {isInProgress, processError, reset, uploadCompanyMediaFile} = useCompanyMediaFile();

    async function handleUploadImage(image: File, type: CompanyImageKindEnum): Promise<void> {
        try {
            const loadedMediaFile = await uploadCompanyMediaFile({file: image, file_type: type});

            onChange?.({tempId: loadedMediaFile.temp_id});
        } catch {
            onChange?.({objectUrl: URL.createObjectURL(image), size: image.size});
        }
    }

    function handleRemove() {
        onChange?.(null);

        reset();
    }

    function extractError() {
        if (processError) {
            return processError.file_s3;
        }

        return null;
    }

    const error = extractError();

    return (
        <div className={styles.logo_form_item__container}>
            {isInProgress && (
                <div className={styles.spinner__container}>
                    <Spinner />
                </div>
            )}
            <InfoForImage
                fileFormats="COMPANY_FORM__LOGO__HINT__FILE_FORMATS"
                label="COMPANY_FORM__LOGO__YOUR_COMPANY_LOGO"
                maxResolution="COMPANY_FORM__LOGO__HINT__MAX_RESOLUTION"
                maxSize="COMPANY_FORM__LOGO__HINT__MAX_SIZE"
                minResolution="COMPANY_FORM__LOGO__HINT__MIN_RESOLUTION"
                minSize="COMPANY_FORM__GALLERY__HINT__MIN_SIZE"
                title="COMPANY_FORM__LOGO__YOUR_COMPANY_LOGO"
            />

            <InputImage
                className={classNames(className, error ? styles.image__error : '')}
                inputImage={inputImage}
                minHeight={LOGO_SIZE.minHeight}
                minWidth={LOGO_SIZE.minWidth}
                modalHeader={<Locale stringKey="COMPANY_FORM__LOGO__UPLOAD_LOGO" />}
                noImageText={<Locale stringKey="COMPANY_FORM__LOGO__NO_LOGO" />}
                onRemove={handleRemove}
                onUpload={(image) => handleUploadImage(image, CompanyImageKindEnum.logo)}
                setInputImage={setInputImage}
            />

            {error && <AntdErrorMessage>{error}</AntdErrorMessage>}
        </div>
    );
}
