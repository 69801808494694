import {z as r} from 'zod';

import {DashboardWidgetEnum} from '../../page/main/dashboard/dashboard-type';
import {FeaturesEnum} from '../../service/user/user-type';

export const helpLinkSchema = r.object({
    urlPattern: r.string(),
    documentationLink: r.string().nullable(),
    videoLink: r.string().nullable(),
    language: r.string(),
});

export type HelpLinksOptionsType = {
    domainName: string;
};

export type HelpLinkType = r.infer<typeof helpLinkSchema>;

export type HelpLinkFeaturesType = Exclude<FeaturesEnum, FeaturesEnum.localPack>;

export type DocumentationLinksType = {
    company: {
        sentName: string;
        categories: string;
        feedImportAndValidation: string;
        feedImport: string;
        feedExport: string;
        contactsFromCatalogs: string;
        yandexVerification: string;
    };
    sources: {
        status: string;
    };
    autoReplies: {
        create: string;
        edit: string;
    };
    facebook: {
        locationCreationPermissions: string;
        newVersionToCreateLocations: string;
    };
    google: {
        massConfirmation: string;
        confirmationTypes: string;
        requestRights: string;
        addressGroup: string;
        addressGroupAdmin: string;
    };
    yandex: {
        sourcesHide: string;
        transferAccountsBanner: string;
    };
    users: {
        roleDescription: string;
    };
    storelocator: {
        getMapboxKey: string;
    };
    usedeskIntegration: string;
    reviewsLinkFormats: string;
    clientsImportTemplate: string;
    productsLink: string;
    ratesLink: string;
    partnersLink: string;
    featureActivation: Partial<Record<HelpLinkFeaturesType, string>>;
    dashboard: Partial<Record<DashboardWidgetEnum, string>>;
    storeLocatorWidgetUrl: string;
    telegramNotificationBotLink: string;
    ideaLink: string;
    help: string;
    onboarding: {
        addCompany: string;
        connectGoogleAccount: string;
        connectFacebookAccount: string;
        connectDoubleGisAccount: string;
        syncGoogleCompanies: string;
        syncFacebookCompanies: string;
        confirmationGoogleCompanies: string;
        confirmationYandexCompanies: string;
    };
    reviews: {
        trialLicenseLink: string;
    };
};

type VideoLinksType = {
    onboarding: {
        addCompany: string;
        connectGoogleAccount: string;
        connectFacebookAccount: string;
        connectDoubleGisAccount: string;
        syncGoogleCompanies: string;
        syncFacebookCompanies: string;
        confirmationGoogleCompanies: string;
        confirmationYandexCompanies: string;
    };
    recommendations: {
        autoReviewTemplate: string;
        tagsForReview: string;
        productsAndPrices: string;
        onlinePresence: string;
        photoAndVideoManager: string;
        publications: string;
        reviewsGenerator: string;
        reviewsGeneratorQr: string;
        repliesToReviews: string;
    };
};

export type HelpLinksContextType = {
    helpLinks: Array<HelpLinkType>;
    documentationLinks: DocumentationLinksType;
    videoLinks: VideoLinksType;
};

export const HELP_LINKS_CONTEXT_INITIAL_DATA: HelpLinksContextType = {
    helpLinks: [],
    documentationLinks: {
        company: {
            sentName: '',
            categories: '',
            feedImportAndValidation: '',
            feedImport: '',
            feedExport: '',
            contactsFromCatalogs: '',
            yandexVerification: '',
        },
        sources: {
            status: '',
        },
        autoReplies: {
            create: '',
            edit: '',
        },
        facebook: {
            locationCreationPermissions: '',
            newVersionToCreateLocations: '',
        },
        google: {
            massConfirmation: '',
            confirmationTypes: '',
            requestRights: '',
            addressGroup: '',
            addressGroupAdmin: '',
        },
        yandex: {
            sourcesHide: '',
            transferAccountsBanner: '',
        },
        users: {
            roleDescription: '',
        },
        storelocator: {
            getMapboxKey: '',
        },
        usedeskIntegration: '',
        reviewsLinkFormats: '',
        clientsImportTemplate: '',
        productsLink: '',
        ratesLink: '',
        partnersLink: '',
        featureActivation: {
            [FeaturesEnum.reviewSuggests]: '',
            [FeaturesEnum.reviewAnalyticsBase]: '',
            [FeaturesEnum.reviewAnalyticsFull]: '',
            [FeaturesEnum.reviewAnalyticsCompetitor]: '',
            [FeaturesEnum.duplicatesAndFakes]: '',
            [FeaturesEnum.photoTool]: '',
            [FeaturesEnum.reviewsGenerator]: '',
            [FeaturesEnum.storeLocator]: '',
            [FeaturesEnum.posts]: '',
            [FeaturesEnum.onlinePresenceAnalytics]: '',
        },
        dashboard: {
            [DashboardWidgetEnum.PhotoTool]: '',
            [DashboardWidgetEnum.ReviewsSummary]: '',
            [DashboardWidgetEnum.PricesSingle]: '',
            [DashboardWidgetEnum.PricesMultiple]: '',
            [DashboardWidgetEnum.AutoReplies]: '',
            [DashboardWidgetEnum.Posts]: '',
            [DashboardWidgetEnum.SynchronizationSingle]: '',
            [DashboardWidgetEnum.SynchronizationMultiple]: '',
            [DashboardWidgetEnum.Workload]: '',
            [DashboardWidgetEnum.Activity]: '',
            [DashboardWidgetEnum.SearchPhrases]: '',
            [DashboardWidgetEnum.LastReviews]: '',
            [DashboardWidgetEnum.Rating]: '',
            [DashboardWidgetEnum.OnlinePresence]: '',
            [DashboardWidgetEnum.RatingDistribution]: '',
        },

        storeLocatorWidgetUrl: '',
        telegramNotificationBotLink: '',
        ideaLink: '',
        help: '',
        onboarding: {
            addCompany: '',
            connectGoogleAccount: '',
            connectFacebookAccount: '',
            connectDoubleGisAccount: '',
            syncGoogleCompanies: '',
            syncFacebookCompanies: '',
            confirmationGoogleCompanies: '',
            confirmationYandexCompanies: '',
        },
        reviews: {
            trialLicenseLink: '',
        },
    },
    videoLinks: {
        onboarding: {
            addCompany: '',
            connectDoubleGisAccount: '',
            connectFacebookAccount: '',
            connectGoogleAccount: '',
            syncGoogleCompanies: '',
            syncFacebookCompanies: '',
            confirmationGoogleCompanies: '',
            confirmationYandexCompanies: '',
        },
        recommendations: {
            autoReviewTemplate: '',
            tagsForReview: '',
            productsAndPrices: '',
            onlinePresence: '',
            photoAndVideoManager: '',
            publications: '',
            reviewsGenerator: '',
            reviewsGeneratorQr: '',
            repliesToReviews: '',
        },
    },
};
