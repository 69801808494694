import {LangKeyType} from '../../provider/locale/translation/type';

export const navigationDataLangKey = 'data-label-lang-key';

declare module 'rc-menu/lib/interface' {
    interface MenuItemType {
        [navigationDataLangKey]?: LangKeyType;
    }

    interface SubMenuType {
        [navigationDataLangKey]?: LangKeyType;
    }

    interface MenuItemGroupType {
        [navigationDataLangKey]?: LangKeyType;
    }

    interface MenuDividerType {
        [navigationDataLangKey]?: LangKeyType;
    }
}
