export const enMyCompaniesTranslations = {
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__TITLE: 'Generating comparative company report',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__TAG__IN_PROGRESS: 'In progress',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__TAG__READY: 'Ready',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__TAG__ERROR: 'Error',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__DELAY__DESCRIPTION: 'Report generation may take more than 10 minutes.',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEPS: 'Step {step} of 3',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEP_PENDING: 'Preparing for report generation',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEP_PERFORMING:
        'Collecting data from sources and comparing with company data',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__STEP_AGGREGATING: 'Aggregating',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__DATE: 'Comparative report as of {date}',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__TOOLTIP__REGENERATE: 'Refresh report',
    MY_COMPANIES__REPORT__PROGRESS_BLOCK__BUTTON__WATCH_REPORT: 'View report',
    MY_COMPANIES__REPORT__ERROR_BLOCK__BUTTON__TRY_AGAIN: 'Try again',
    MY_COMPANIES__REPORT__ERROR_BLOCK__STEP__FAILED: 'Failed to generate the report',
    MY_COMPANIES__REPORT__TABLE__BUTTON__SELECTED_REPORT: 'Report for selected',
    MY_COMPANIES__REPORT__MODAL__CANCEL__TITLE: 'Cancel Report Generation',
    MY_COMPANIES__REPORT__MODAL__CANCEL__CONTENT:
        'Are you sure you want to cancel the report generation? All data will be lost.',
    MY_COMPANIES__NEW_COMPANY_CHECKBOX__TOOLTIP: 'Show companies added in the last 30 days',
    MY_COMPANIES__NEW_COMPANY_CHECKBOX__TITLE: 'Only new companies',
    MY_COMPANIES__YANDEX_VERIFICATION__ALERT:
        'Update information in your cards to avoid losing the owner status (blue checkmark) on Yandex Maps. \nIf the current data on the card is accurate, verification will happen automatically within 3 business days. \n{link} Yandex for verification.',
    MY_COMPANIES__YANDEX_VERIFICATION__TOOLTIP:
        'Update information in your card to avoid losing the blue checkmark on Yandex Maps.',
    MY_COMPANIES__YANDEX_VERIFICATION__ALERT__LINK_BUTTON: 'Requirements',
    MY_COMPANIES__YANDEX_VERIFICATION__ALERT__CLEAR_FILTER: 'Clear filter',
    MY_COMPANIES__YANDEX_VERIFICATION__ALERT__SHOW_COMPANIES: 'View companies',
    MY_COMPANIES__REPORT__SNACKBAR__DONE__MESSAGE: 'Report is Ready',
    MY_COMPANIES__REPORT__SNACKBAR__DONE__DESCRIPTION:
        'The generation of the comparative report on companies has been successfully completed. The report has been sent to the email address specified in your profile.',
    MY_COMPANIES__REPORT__SNACKBAR__DONE__DESCRIPTION__BUTTON: 'Open report',
    MY_COMPANIES__REPORT__SNACKBAR__DOWNLOAD__TITLE: 'Downloading report',
    MY_COMPANIES__REPORT__SNACKBAR__DOWNLOAD__DESCRIPTION:
        'The file will be generated and sent to the email address provided in your profile.',
    MY_COMPANIES__REPORT__DOWNLOAD__BUTTON: 'Download report',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT_ALERT__TITLE: 'Grant access to Yandex Business',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT_ALERT__DESCRIPTION:
        'Due to internal changes in Yandex related to business accounts, it is required to transfer access to a new Yandex Business account. If you do not complete this transfer, data synchronization will be disrupted.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__GET_ACCESS: 'Grant access',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__SHOW_COMPANIES: 'Show companies',

    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__TITLE: 'Granting access in Yandex Business',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__COPY_BUTTON: 'Copy',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__CHAIN: 'Company network {companyName}',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__DESCRIPTION:
        'To grant access to the company, you need to follow these steps:',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__DESCRIPTION__CHAIN:
        'To grant access to the company network, you need to follow these steps:',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST:
        'Log into your Yandex Business account with Owner rights and go to the {pages} page.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST__COMPANY: 'Companies',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST__MY_COMPANIES: 'My companies',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST__CHAIN: 'Company networks',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__TWO:
        'Select the required company or network from the list and click the link in the name.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND:
        'In the left menu, click on {access}, and on the page that opens, click {add}.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__ACCESS: 'Access',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__ADD: 'Add',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__THIRD:
        'Copy the email below {domainName}, enter it, select the "Representative" role, and then click {add}.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FOURTH:
        'After completing all the above steps, confirm the granted access.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIVE:
        'Once you have granted access to all your companies or company network, confirm the access.',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__SUCCESS: 'Data sent for verification',
    MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__PENDING: 'Awaiting access grant',
};
