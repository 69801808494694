import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, Empty, Input} from 'antd';
import {FilterDropdownProps} from 'antd/lib/table/interface';
import {mapValues, sortBy} from 'lodash';
import {UIEvent, useEffect, useState} from 'react';

import {gray4} from '../../css/var-export.scss';
import {Locale} from '../../provider/locale/localization';
import {onScrollInfiniteScrollContainer} from '../../util/infinite-scroll';
import {Spinner} from '../spinner/spinner';

import {isStringArray} from './table-filter-dropdown-helper';
import {FilterDropdownListType} from './table-filter-dropdown-type';
import * as styles from './table-filter-dropdown.scss';

type PropsType = FilterDropdownProps & {
    trackConfirm?: (selectedIds: Array<string>) => void;
    inputValue?: string;
    setInputValue?: (value: string) => void;
    fetchNextPage?: () => void;
    infiniteLoading?: boolean;
    inputPlaceholder?: string;
    className?: string;
};

export function TableFilterDropdownWithInfiniteScroll(props: PropsType): JSX.Element {
    const {
        clearFilters,
        confirm,
        filters: filterList,
        selectedKeys,
        setSelectedKeys,
        trackConfirm,
        inputValue,
        setInputValue,
        fetchNextPage,
        infiniteLoading,
        inputPlaceholder,
        className,
    } = props;

    const [filters, setFilters] = useState<FilterDropdownListType>({});
    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

    const filtersValues = Object.values(filters);

    useEffect(() => {
        const filterEntries = (filterList || []).map((filter) => [
            filter.value,
            {
                ...filter,
                selected:
                    selectedKeys.includes(filter.value.toString()) || selectedItems.includes(filter.value.toString()),
            },
        ]);

        setFilters(Object.fromEntries(filterEntries));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterList, selectedKeys]);

    function handleChange(isChecked: boolean, id: string) {
        setFilters((previousFilters) => {
            const previousFilter = previousFilters[id];

            if (previousFilter) {
                return {...previousFilters, [id]: {...previousFilter, selected: isChecked}};
            }

            return previousFilters;
        });

        if (isChecked) {
            setSelectedItems([...selectedItems, id]);
        } else {
            setSelectedItems(selectedItems.filter((filterId) => filterId !== id));
        }
    }

    function onContainerScroll(event: UIEvent) {
        if (fetchNextPage) {
            onScrollInfiniteScrollContainer(event, () => fetchNextPage(), 90);
        }
    }

    function handleConfirm() {
        const selectedIds = Object.keys(filters).filter((item) => filters[item]?.selected);

        setSelectedKeys(selectedIds);
        confirm();

        if (setInputValue) {
            setInputValue('');
        }

        const trackedIds = selectedIds.map((id) => filters[id]?.text?.toString()).filter(Boolean);

        if (trackedIds.length > 0) {
            trackConfirm?.(trackedIds);
        }
    }

    function selectAll() {
        setFilters(mapValues(filters, (filter) => ({...filter, selected: true})));
    }

    function resetFilter() {
        if (clearFilters) {
            clearFilters();
        }

        if (setInputValue) {
            setInputValue('');
        }

        setSelectedItems([]);
    }

    return (
        <div className={className}>
            <header className={styles.TableFilterDropdown_header}>
                {setInputValue ? (
                    <Input
                        onChange={(event) => setInputValue(event.target.value)}
                        placeholder={inputPlaceholder}
                        prefix={<FontAwesomeIcon color={gray4} icon={faSearch} />}
                        value={inputValue}
                    />
                ) : (
                    <Button onClick={selectAll} size="small" type="link">
                        <Locale stringKey="TEXT__SELECT_ALL" />
                    </Button>
                )}
            </header>

            <ul className={styles.TableFilterDropdown_list} onScroll={onContainerScroll}>
                {(isStringArray(filtersValues)
                    ? sortBy(filtersValues, (value) => value.text.toLowerCase())
                    : filtersValues
                ).map((filter) => (
                    <li className={styles.TableFilterDropdown_item} key={filter.value.toString()}>
                        <Checkbox
                            checked={filter.selected}
                            className={styles.TableFilterDropdown_checkboxText}
                            onChange={(event) => {
                                handleChange(event.target.checked, filter.value.toString());
                            }}
                        >
                            {filter.text}
                        </Checkbox>
                    </li>
                ))}
                {infiniteLoading && <Spinner size="small" />}
                {!infiniteLoading && filtersValues.length === 0 && (
                    <Empty
                        description={<Locale stringKey="EMPTY__DESCRIPTION" />}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            </ul>
            <footer className={styles.TableFilterDropdown_footer}>
                <Button onClick={resetFilter} size="small" type="text">
                    <Locale stringKey="TEXT__RESET" />
                </Button>

                <Button onClick={handleConfirm} size="small" type="primary">
                    <Locale stringKey="TEXT__OK" />
                </Button>
            </footer>
        </div>
    );
}
