import {Button, Col, Form, FormInstance, Input, Row, Select} from 'antd';
import {useCallback, useEffect} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';
import {
    ReviewConcordanceRequestFieldEnum,
    ReviewConcordanceRequestType,
} from '../../../../../../service/reivew-analysis/review-concordance/review-concordance-type';
import {ActiveFilter} from '../../../components/active-filter/active-filter';
import {DrawerFilter} from '../../../components/drawer-filter/drawer-filter';
import {UtcRangePicker} from '../../../components/drawer-filter/utc-range-picker/utc-range-picker';

import {contextOptionElements} from './concordance-form-helper';
import {ConcordanceFormType} from './concordance-form-type';
import * as styles from './concordance-form.scss';

type PropsType = {
    values: ConcordanceFormType;
    onSubmit: (values: ConcordanceFormType) => void;
};

export function ConcordanceForm(props: PropsType): JSX.Element {
    const {values, onSubmit} = props;

    const {getLocalizedString} = useLocale();

    const [form]: [FormInstance<ConcordanceFormType>] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form, values]);

    const onSubmitWrapper = useCallback(
        (valuesInner: ConcordanceFormType): void => {
            onSubmit(valuesInner);

            track(AnalyticsTarget.ReviewsAnalysis.SearchByReviews.SearchByReviews, valuesInner.q);
        },
        [onSubmit]
    );

    return (
        <div>
            <Form<ConcordanceFormType> form={form} initialValues={values} layout="vertical" onFinish={onSubmitWrapper}>
                <div className={styles.ConcordanceForm_filters}>
                    <Form.Item
                        className={styles.ConcordanceForm_range}
                        label={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__PERIOD__LABEL" />}
                        name={ReviewConcordanceRequestFieldEnum.Range}
                    >
                        <UtcRangePicker />
                    </Form.Item>

                    <DrawerFilter isWithTextOnly />
                </div>

                <div className={styles.ConcordanceForm_activeFilters}>
                    <ActiveFilter
                        isCompareRangeAllowed={false}
                        isCompetitorAllowed={false}
                        isPeriodHidden
                        isPreview={false}
                        isWithTextOnly
                    />
                </div>

                <Row align="bottom" gutter={16}>
                    <Col flex="auto">
                        <Form.Item<ReviewConcordanceRequestType>
                            colon={false}
                            label={getLocalizedString('REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__QUERY__TITLE')}
                            name={ReviewConcordanceRequestFieldEnum.Query}
                            rules={[
                                {required: true, message: <Locale stringKey="VALIDATION__ERROR__FIELD_IS_REQUIRED" />},
                            ]}
                        >
                            <Input
                                placeholder={getLocalizedString(
                                    'REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__QUERY__PLACEHOLDER'
                                )}
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item<ReviewConcordanceRequestType>
                            colon={false}
                            label={getLocalizedString('REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__CONTEXT__TITLE')}
                            name={ReviewConcordanceRequestFieldEnum.ContextSize}
                        >
                            <Select optionFilterProp="children">{contextOptionElements}</Select>
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                <Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__SUBMIT__TITLE" />
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
