import {faFile} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Flex, Input} from 'antd';
import {SyntheticEvent, useCallback, useState} from 'react';

import {Spinner} from '../../../../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {useSystem} from '../../../../../../../../provider/system/system-hook';
import {AnalyticsTarget, track} from '../../../../../../../../service/analytics/analytics';
import {fetchReviewsCommandEdit} from '../../../../../../../../service/reviews/reviews-command';
import {fetchReviewsReplyEdit} from '../../../../../../../../service/reviews/reviews-reply';
import {ResponseTemplateDataType} from '../../../../../../../../service/reviews/reviews-response-templates';
import {ReviewDataChildType, ReviewReplyTypeAuthorEnum} from '../../../../../../../../service/reviews/reviews-type';
import {IdNumberType} from '../../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';
import {handleEnter, handleReviewReplyExceptions} from '../../review-helper';

import * as styles from './new-edit-form.scss';

type PropsType = {
    answer: ReviewDataChildType;
    isTestBrand: boolean;
    onClose: () => void;
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewId: number;
};

export function NewEditForm(props: PropsType): JSX.Element {
    const {answer, isTestBrand, onClose, refreshReview, reviewId} = props;

    const systemContext = useSystem();
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const [comment, setComment] = useState<string>(answer.comment || '');
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );

    const isButtonDisabled = comment.length === 0 || isTestBrand;

    function handleSelectTemplateClick() {
        track(AnalyticsTarget.ReviewsManagement.Reviews.ClickResponseTemplates);

        setIsSelectTemplatePopupOpen(true);
    }

    const onSubmit = useCallback(async () => {
        if (isButtonDisabled || isInProgress) {
            return;
        }

        setIsInProgress(true);

        try {
            await (answer.type === ReviewReplyTypeAuthorEnum.command
                ? fetchReviewsCommandEdit(reviewId, answer.commandId, {comment: comment.trim()})
                : fetchReviewsReplyEdit(reviewId, answer.replyId, {comment: comment.trim()}));
            await refreshReview(reviewId);
            onClose();
        } catch (error: unknown) {
            handleReviewReplyExceptions(error, snackbar);
        } finally {
            setIsInProgress(false);
        }
    }, [
        answer.commandId,
        answer.replyId,
        answer.type,
        comment,
        isButtonDisabled,
        isInProgress,
        onClose,
        refreshReview,
        reviewId,
        snackbar,
    ]);

    function handlePressEnter(event: React.KeyboardEvent<HTMLTextAreaElement>): void {
        handleEnter(event, setComment, systemContext.screen.isDesktop ? onSubmit : null);
    }

    return (
        <form className={styles.review__edit_form}>
            <Spinner isShow={isInProgress} position="absolute" />
            <Flex align="start" gap={12}>
                <Input.TextArea
                    autoSize
                    className={styles.EditForm_textArea}
                    onInput={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                        setComment(event.currentTarget.value);
                    }}
                    onPressEnter={handlePressEnter}
                    placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_REPLY_TEXT')}
                    size="large"
                    value={comment}
                />
                <Button className={styles.EditForm_templateButton} onClick={handleSelectTemplateClick}>
                    <FontAwesomeIcon height={32} icon={faFile} width={32} />
                </Button>
            </Flex>
            <div className={styles.review__button_wrapper}>
                <Button disabled={isButtonDisabled} loading={isInProgress} onClick={onSubmit} type="primary">
                    <Locale stringKey="REVIEW__SAVE_ANSWER" />
                </Button>

                <Button onClick={onClose}>
                    <Locale stringKey="BUTTON__CANCEL" />
                </Button>
            </div>

            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        const [selectedAutoReply] = selectedResponseTemplateList;
                        const message = selectedAutoReply?.message;

                        if (message) {
                            setComment(message);
                        }

                        setIsSelectTemplatePopupOpen(false);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </form>
    );
}
