export enum ReviewsReportFormEnum {
    Mode = 'mode',
    Name = 'name',
    WithReviewsSummary = 'withReviewsSummary',
    WithTagsStat = 'withTagsStat',
}

export type ReviewsReportFormType = {
    [ReviewsReportFormEnum.Name]: string;
    [ReviewsReportFormEnum.WithReviewsSummary]: boolean;
    [ReviewsReportFormEnum.WithTagsStat]: boolean;
};
