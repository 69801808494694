import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {faPeriod} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, DatePicker, Form, Select} from 'antd';
import {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';
import {useEffect, useMemo, useRef, useState} from 'react';

import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSystem} from '../../../../provider/system/system-hook';
import {useQuestionRepliers} from '../../../../service/questions/questions-hook';
import {ReplierDataType} from '../../../../service/questions/questions-type';
import {classNames} from '../../../../util/css';

import {defaultEmployeeResponding} from './questions-and-answers-filter-const';
import * as styles from './questions-and-answers-filter.scss';

type PropsType = {
    className?: string;
    setDateBefore: (data: Date | null) => void;
    setDateAfter: (data: Date | null) => void;
    setEmployerId: (id: number | null) => void;
};

type FiltersStateType = {
    hasPeriod: boolean;
    hasEmployee: boolean;
};

const {Option} = Select;

export function QuestionsAndAnswersFilter(props: PropsType): JSX.Element {
    const {className, setDateBefore, setDateAfter, setEmployerId} = props;

    const [isFilterOpened, setIsFilterOpened] = useState(true);
    const [filterState, setFilterState] = useState<FiltersStateType>({hasPeriod: false, hasEmployee: false});

    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isMobile} = screen;

    useEffect(() => {
        setIsFilterOpened(!isMobile);
    }, [isMobile]);

    const dateContainerRef = useRef<HTMLDivElement | null>(null);

    const {result: repliersResult, isInProgress: isRepliersResultInProgress} = useQuestionRepliers();

    const replierList: Array<ReplierDataType> = useMemo(() => {
        return repliersResult || [];
    }, [repliersResult]);

    const {getLocalizedString} = useLocale();
    const [form] = Form.useForm();

    function handleChangePeriod(period: NoUndefinedRangeValueType<Dayjs> | null) {
        setFilterState((previousState: FiltersStateType) => {
            return {...previousState, hasPeriod: Boolean(period)};
        });

        if (!period) {
            setDateBefore(null);
            setDateAfter(null);
            return;
        }

        const dateBefore = period[0] ? new Date(period[0].toISOString()) : null;
        const dateAfter = period[1] ? new Date(period[1].toISOString()) : null;

        setDateBefore(dateBefore);
        setDateAfter(dateAfter);
    }

    function handleChangeEmployee(id: number) {
        setFilterState((previousState: FiltersStateType) => {
            return {...previousState, hasEmployee: id !== defaultEmployeeResponding};
        });

        if (id === defaultEmployeeResponding) {
            setEmployerId(null);
            return;
        }

        setEmployerId(id);
    }

    function handleFormReset() {
        setDateBefore(null);
        setDateAfter(null);
        setEmployerId(null);
        setFilterState({hasPeriod: false, hasEmployee: false});
    }

    return (
        <Form
            className={classNames(styles.QuestionsAndAnswersFilter, className)}
            form={form}
            layout="vertical"
            name="question-and-answer-filter"
            onReset={handleFormReset}
        >
            {isMobile && (
                <Button
                    block
                    className={styles.QuestionsAndAnswersFilter_filterButton}
                    icon={<FontAwesomeIcon icon={faFilter} />}
                    onClick={() => setIsFilterOpened((state) => !state)}
                    type="default"
                >
                    <Locale stringKey="BUTTON__FILTER" />
                </Button>
            )}

            <div className={styles.QuestionsAndAnswersFilter_content} hidden={!isFilterOpened}>
                <div className={styles.QuestionsAndAnswersFilter_header}>
                    <p className={styles.QuestionsAndAnswersFilter_title}>
                        <Locale stringKey="QUESTIONS_N_ANSWERS__FILTER__FILTERS" />
                    </p>

                    <Button className={styles.QuestionsAndAnswersFilter_headerButton} htmlType="reset" type="link">
                        <Locale stringKey="QUESTIONS_N_ANSWERS__FILTER__RESET" />
                    </Button>
                </div>
                <div>
                    <div className={styles.QuestionsAndAnswersFilter_selectFilter} ref={dateContainerRef}>
                        {filterState.hasPeriod && (
                            <FontAwesomeIcon
                                className={styles.QuestionsAndAnswersFilter_activeFilter}
                                icon={faPeriod}
                            />
                        )}
                        <Form.Item
                            initialValue={[null, null]}
                            label={<Locale stringKey="QUESTIONS_N_ANSWERS__FILTER__PERIOD_OF_TIME" />}
                            name="date-range"
                        >
                            <DatePicker.RangePicker
                                className={styles.QuestionsAndAnswersFilter_dataPicker}
                                format="YYYY-MM-DD"
                                getPopupContainer={() => dateContainerRef.current || document.body}
                                onChange={handleChangePeriod}
                                placeholder={[
                                    getLocalizedString('TEXT__DATE_FROM'),
                                    getLocalizedString('TEXT__DATE_TO'),
                                ]}
                            />
                        </Form.Item>
                    </div>
                    <div className={styles.QuestionsAndAnswersFilter_selectFilter}>
                        {filterState.hasEmployee && (
                            <FontAwesomeIcon
                                className={styles.QuestionsAndAnswersFilter_activeFilter}
                                icon={faPeriod}
                            />
                        )}
                        <Form.Item
                            initialValue={defaultEmployeeResponding}
                            label={<Locale stringKey="QUESTIONS_N_ANSWERS__FILTER__EMPLOYEE_RESPONDING" />}
                            name="employee-responding-type"
                        >
                            <Select<number>
                                disabled={isRepliersResultInProgress}
                                loading={isRepliersResultInProgress}
                                onChange={handleChangeEmployee}
                                placeholder={<Locale stringKey="QUESTIONS_N_ANSWERS__FILTER__EMPLOYEE_RESPONDING" />}
                            >
                                <Option value={defaultEmployeeResponding}>
                                    <Locale stringKey="QUESTIONS_N_ANSWERS__FILTER__ANY_EMPLOYEE" />
                                </Option>
                                {replierList?.map((replier: ReplierDataType): JSX.Element => {
                                    const {id, full_name} = replier;

                                    return (
                                        <Option key={id} value={id}>
                                            {full_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Form>
    );
}
