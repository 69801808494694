import {Text} from '../../../../../component/text/text';
import {FormattedNumber} from '../../../../../provider/locale/formatted-number';
import {AutoRepliesSummaryType} from '../../../../../service/dashboard/dashboard-type';

import * as styles from './auto-replies-users.scss';

type PropsType = {
    balance: AutoRepliesSummaryType['balance'];
};

export function AutoRepliesUsers(props: PropsType): JSX.Element {
    const {balance} = props;

    return (
        <div className={styles.AutoRepliesUsers}>
            <Text block bolder secondary small stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__DETAILED" />
            <div className={styles.AutoRepliesUsers_usersTable}>
                {balance.map((balanceItem) => (
                    <div className={styles.AutoRepliesUsers_usersTableRow} key={balanceItem.userId}>
                        <Text block lighter>
                            {balanceItem.email}
                        </Text>
                        <Text block lighter>
                            <FormattedNumber value={balanceItem.balance} />
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    );
}
