import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useCatalog} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {PhotoSourceNameToProviderIdEnum} from '../../../../../service/phototool/phototool-sources-helper';
import {PhotoType} from '../../../../../service/phototool/phototool-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './photo-image.scss';

type PropsType = {
    photo: PhotoType;
};

export function PhotoImage(props: PropsType): JSX.Element {
    const {photo} = props;

    const {catalog} = useCatalog(PhotoSourceNameToProviderIdEnum[photo.source.name]);

    const {getFormattedDateTime} = useFormat();

    const createdDate = new Date(photo.createdAt);
    const path = generatePath(appRoute.photoToolDetails.path, {id: photo.id});

    return (
        <div className={styles.PhotoImage}>
            <NavigationLink to={path}>
                <img alt="" className={styles.PhotoImage_image} src={photo.originalImageUrl} />
            </NavigationLink>
            <div className={styles.PhotoImage_content}>
                <div className={styles.PhotoImage_description}>
                    <div>{catalog?.name}</div>
                    <div>{getFormattedDateTime(createdDate)}</div>
                </div>
                <NavigationLink className={styles.PhotoImage_link} to={path}>
                    <div className={styles.PhotoImage_description}>
                        <div>
                            <Locale stringKey="DASHBOARD_PAGE__PHOTOS__OPEN_IN_SOURCE" />
                        </div>
                        <div>→</div>
                    </div>
                </NavigationLink>
            </div>
        </div>
    );
}
