import {faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, DatePicker, Select, Space} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';

import {Text} from '../../../../../../component/text/text';
import {AsciiSpinner} from '../../../../../../layout/spinner/ascii-spinner';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useFakesStatistics} from '../../../../../../service/fakes/fakes-statistics';
import {useFakesSubscriptions} from '../../../../../../service/fakes/fakes-subscriptions';
import {Form} from '../../../../../../typings/antd';
import {FakesReport} from '../../../report/fakes-report';

import {initialValues} from './fakes-create-report-const';
import {FakesCreateReportFormEnum, FakesCreateReportFormType} from './fakes-create-report-type';
import * as styles from './fakes-create-report.scss';

// eslint-disable-next-line complexity
export function FakesCreateReport(): JSX.Element {
    const [form] = Form.useForm<FakesCreateReportFormType>();
    const formDateRange = Form.useWatch(FakesCreateReportFormEnum.DateRange, form);
    const brandIds = Form.useWatch(FakesCreateReportFormEnum.BrandIds, form);

    const dateRange: [Dayjs, Dayjs] | null =
        formDateRange && formDateRange[0] && formDateRange[1] ? [formDateRange[0], formDateRange[1]] : null;

    const statisticsOptions = {
        ...(dateRange && {
            fromDate: dateRange[0].format('YYYY-MM-DD'),
            toDate: dateRange[1].format('YYYY-MM-DD'),
        }),
        brandIds,
    };

    const {data: subscriptions, isLoading: isSubscriptionsLoading} = useFakesSubscriptions();
    const {data: statistics, isLoading: isStatisticsLoading} = useFakesStatistics(statisticsOptions);

    const {getLocalizedString} = useLocale();
    const pdfTitle = getLocalizedString('CATEGORY_NAME__DUBS_AND_FAKES');
    const pdfContentRef = useRef<HTMLDivElement | null>(null);
    const handlePrintClick = useReactToPrint({
        content: () => pdfContentRef.current,
        documentTitle: dateRange ? `${pdfTitle} ${statisticsOptions.fromDate} - ${statisticsOptions.toDate}` : pdfTitle,
    });

    return (
        <>
            <Text
                block
                bolder
                className={styles.FakesCreateReport_title}
                large
                stringKey="FAKES__SETTINGS__CREATE_REPORT__TITLE"
            />

            <Text
                block
                className={styles.FakesCreateReport_description}
                lighter
                stringKey="FAKES__SETTINGS__CREATE_REPORT__DESCRIPTION"
            />

            <Form className={styles.FakesCreateReport_form} form={form} initialValues={initialValues} layout="vertical">
                <Form.Item<FakesCreateReportFormType>
                    label={<Locale stringKey="FAKES__REPORT__SUMMARY__PERIOD" />}
                    name={FakesCreateReportFormEnum.DateRange}
                >
                    <DatePicker.RangePicker
                        allowClear={false}
                        className={styles.FakesCreateReport_picker}
                        disabledDate={(current) => current && current > dayjs().endOf('day')}
                        size="large"
                    />
                </Form.Item>

                <Form.Item<FakesCreateReportFormType>
                    label={<Locale stringKey="FAKES__REPORT__SUMMARY__BRANDS" />}
                    name={FakesCreateReportFormEnum.BrandIds}
                >
                    <Select
                        mode="multiple"
                        options={(subscriptions ?? []).map(({brandId, brandName}) => ({
                            value: brandId,
                            label: brandName,
                        }))}
                        placeholder={getLocalizedString('COMPANY__MULTISELECT__POPUP__ALL_BRANDS')}
                        size="large"
                    />
                </Form.Item>

                <Space size="middle">
                    <Button
                        disabled={statistics?.all === 0}
                        icon={<FontAwesomeIcon icon={faFilePdf} />}
                        loading={isSubscriptionsLoading || isStatisticsLoading}
                        onClick={handlePrintClick}
                        size="large"
                    >
                        <span>
                            <Locale stringKey="FAKES__SETTINGS__CREATE_REPORT__DOWNLOAD" />
                        </span>
                    </Button>

                    <Text
                        secondary
                        stringKey="FAKES__SETTINGS__CREATE_REPORT__LOCATIONS"
                        valueMap={{
                            count: isSubscriptionsLoading || isStatisticsLoading ? <AsciiSpinner /> : statistics?.all,
                        }}
                    />
                </Space>
            </Form>

            {!isSubscriptionsLoading && !isStatisticsLoading && (
                <div hidden>
                    <FakesReport brandIds={brandIds} dateRange={dateRange} ref={pdfContentRef} />
                </div>
            )}
        </>
    );
}
