import {z as r} from 'zod';

import {
    CursorPaginationHookOptionsType,
    CursorPaginationHookType,
} from '../../util/cursor-pagination/cursor-pagination-type';
import {cursorPaginationResponseSchemaFactory, CursorPaginationResponseType} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';

import {photoSourceSchema} from './phototool-sources';
import {photoTagSchema} from './phototool-tags';

export enum PhotoStatusKeyEnum {
    New = 'new',
    Viewed = 'viewed',
    Reported = 'complained',
    ReportRejected = 'complaint_rejected',
    RemoveRequest = 'await_removing',
    Removed = 'removed',
    ErrorRemoving = 'error_removing',
}

const commonMediaSchema = r.object({
    id: r.number(),
    originalCardUrl: r.string(),
    createdAt: r.string(),
    source: photoSourceSchema,
    status: r.nativeEnum(PhotoStatusKeyEnum),
    tags: r.array(photoTagSchema),
    own: r.boolean(),
});

export const photoSchema = commonMediaSchema.merge(
    r.object({
        originalImageUrl: r.string(),
        imageWidth: r.number(),
        imageHeight: r.number(),
        reasonNotAbleToDelete: r.string().nullish(),
    })
);

const mediaItemSchema = photoSchema.merge(
    r.object({
        duration: r.number().optional(),
        contentType: r.string().optional(),
        previewUrl: r.string().optional(),
        reasonNotAbleToDelete: r.string().nullish(),
    })
);

export const videoSchema = commonMediaSchema.merge(
    r.object({
        originalVideoUrl: r.string(),
        videoWidth: r.number(),
        videoHeight: r.number(),
        duration: r.number(),
        previewUrl: r.string(),
        contentType: r.string().optional(),
        reasonNotAbleToDelete: r.string().nullish(),
    })
);

export type PhotoType = r.infer<typeof photoSchema>;
export type VideoType = r.infer<typeof videoSchema>;
export type MediaItemType = r.infer<typeof mediaItemSchema>;

export const photosSchema = cursorPaginationResponseSchemaFactory(photoSchema);
export const videosSchema = cursorPaginationResponseSchemaFactory(videoSchema);
const mediaSchema = cursorPaginationResponseSchemaFactory(mediaItemSchema);

export type PhotosType = r.infer<typeof photosSchema>;
export type VideosType = r.infer<typeof videosSchema>;
export type MediaType = r.infer<typeof mediaSchema>;

export type PhotoFilterOptionsType = {
    status: string | null;
    source: string | null;
    tags: string | null;
    startDate: string | null;
    endDate: string | null;
    own: boolean | null;
};

export type PhotoHookOptionsType = {
    filter?: PhotoFilterOptionsType;
    mainFilterKey?: string;
    paginationOptions?: Partial<Omit<CursorPaginationHookOptionsType, 'dependencies'>>;
    isVideoMode?: boolean;
};

export type PhotosOptionsType = PhotoFilterOptionsType & {
    cursor: string | null;
    pageSize: number | null;
};

export type PhotosSetStatusRequestType = {
    photoIds: Array<number>;
    status: PhotoStatusKeyEnum;
};

export type PhotoItemSetStatusType = (status: PhotoStatusKeyEnum) => Promise<void>;

export type VideosSetStatusRequestType = {
    videoIds: Array<number>;
    status: PhotoStatusKeyEnum;
};

export enum PhotoReportTypeEnum {
    OffensiveContent = 'OFFENSIVE',
    LawViolation = 'LEGAL',
    PrivacyViolation = 'PRIVATE',
    PoorQuality = 'IMAGE_QUALITY',
    LocationMatchingError = 'IRRELEVANT',
    OrganizationMatchingError = 'WRONG_ORGANIZATION',
    Other = 'OTHER',
}

export type PhotosReportDataType = {
    message: string;
    type: PhotoReportTypeEnum;
};

export type PhotosReportRequestType = PhotosReportDataType & {
    photoIds: Array<number>;
};

export type VideosReportRequestType = PhotosReportDataType & {
    videoIds: Array<number>;
};

export type PhotosRemoveRequestType = {
    photoIds: Array<number>;
};

export type VideosRemoveRequestType = {
    videoIds: Array<number>;
};

export type PhotoUpdateRequestType = {
    tags: Array<number>;
};

export type PhotoItemReportType = (reportData: PhotosReportDataType) => Promise<void>;

export type PhotoItemUpdateTagsType = (tagIds: Array<number>) => Promise<void>;

export type PhotoItemHookType = UseHookType<MediaItemType> & {
    setStatus: PhotoItemSetStatusType;
    report: PhotoItemReportType;
    updateTags: PhotoItemUpdateTagsType;
};

export type VideoItemHookType = UseHookType<MediaItemType> & {
    setStatus: PhotoItemSetStatusType;
    report: PhotoItemReportType;
    updateTags: PhotoItemUpdateTagsType;
};

export type PhotoListSetViewedType = (photoIds: Array<MediaItemType>) => Promise<void>;

export type PhotoListReportType = (photos: Array<PhotoType>, reportData: PhotosReportDataType) => Promise<void>;

export type PhotoListHookType = UseHookType<CursorPaginationResponseType<MediaItemType>> & {
    setViewed: PhotoListSetViewedType;
    report: PhotoListReportType;
    cursorPagination: CursorPaginationHookType;
    refresh: VoidFunction;
};

export const photoToolCounterSchema = r.object({
    count: r.number(),
});

export type PhotoToolCounterType = r.infer<typeof photoToolCounterSchema>;
export const photoToolStatsSchema = r.object({
    totalCount: r.number(),
    catalogs: r.array(
        r.object({
            id: r.number(),
            logo: r.string(),
            name: r.string(),
            photosCount: r.number(),
        })
    ),
});

export type PhotoToolCatalogStatsType = r.infer<typeof photoToolStatsSchema>;
