import {Alert} from 'antd';
import {ReactElement} from 'react';

import {Text} from '../../../../component/text/text';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../provider/domain-config/domain-config-type';
import {useGiftInfo} from '../../../../service/dashboard/dashboard-hook';

import {ReactComponent as GiftIcon} from './icon/gift.svg';
import * as styles from './gift-alert.scss';

export function BwGiftInfo(): ReactElement | null {
    const {domainName} = useDomainConfig();

    const {data: giftInfo} = useGiftInfo(domainName);

    if (domainName !== DomainNameEnum.brandWizard || giftInfo?.display === false) {
        return null;
    }

    return (
        <Alert
            message={
                <div className={styles.AlertMessage}>
                    <Text className={styles.AlertMessage_messageText}>{giftInfo?.message}</Text>
                    <span className={styles.AlertMessage_icon}>
                        <GiftIcon />
                    </span>
                </div>
            }
        />
    );
}
