import {useCatalog} from '../../../../provider/catalogs/catalogs-hook';
import {classNames} from '../../../../util/css';

import * as styles from './companies-selector-posts-additional-data-column.scss';

type PropsType = {
    catalogId: number;
    disabled: boolean;
};

export function CompaniesSelectorPostsCatalogIcon(props: PropsType): JSX.Element | null {
    const {catalogId, disabled} = props;

    const {catalog} = useCatalog(catalogId);

    if (!catalog) {
        return null;
    }

    return (
        <img
            alt=""
            className={classNames(styles.PostsSelectorItem_catalogIcon, {
                [styles.PostsSelectorItem_disabledCatalog]: disabled,
            })}
            src={catalog.logo}
        />
    );
}
