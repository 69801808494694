import {LabelledProgressBar} from '../../../../layout/labeled-progress-bar/labelled-progress-bar';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {
    CardOccupancyEnum,
    OnlinePresenceSynchronizationType,
} from '../../../../service/online-presence/online-presence-type';

import {dataCompletenessTranslationMap} from './synchronization-stats-const';
import * as styles from './synchronization-stats.scss';

type PropsType = {
    data: OnlinePresenceSynchronizationType['dataCompleteness'];
};

export function DataCompleteness(props: PropsType): JSX.Element | null {
    const {data} = props;
    const {getLocalizedString} = useLocale();

    return (
        <PageCard title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DATA_COMPLETENESS" />}>
            <div className={styles.SyncCard_wrapper}>
                {Object.values(CardOccupancyEnum)
                    .filter((key: CardOccupancyEnum) => {
                        return key !== CardOccupancyEnum.AverageScore;
                    })
                    .map((key) => {
                        return (
                            <LabelledProgressBar
                                className={styles.SyncCard}
                                key={key}
                                percent={Math.floor(data[key])}
                                title={getLocalizedString(dataCompletenessTranslationMap[key])}
                            />
                        );
                    })}
            </div>
        </PageCard>
    );
}
