import {getDefaultReactTooltipOptions} from '../../chart/chart-const';
import {defaultLineChartOptions} from '../../chart/line-chart/line-chart-helper';
import {ChartJsLineOptionsType, LineDataType} from '../../chart/line-chart/line-chart-type';

export const trendStrokeColor = '#7270D6';
export const trendFillColor = '#E0E0F6';

export function getTrendChartOptions(data: LineDataType, pdfPreview = false): ChartJsLineOptionsType {
    const isTooltipVisible =
        data?.datasets?.every((dataset) => {
            return Boolean(dataset.label);
        }) || false;

    return {
        ...defaultLineChartOptions,
        ...(!pdfPreview
            ? {
                  animation: false,
              }
            : {}),
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                hitRadius: isTooltipVisible ? 8 : 0,
                radius: 0,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                ...(isTooltipVisible
                    ? getDefaultReactTooltipOptions({
                          data: {
                              labels: data.labels || [],
                              datasets: data.datasets,
                          },
                      })
                    : {}),
            },
            datalabels: {
                display: false,
            },
        },
    };
}
