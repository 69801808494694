import {Button} from 'antd';

import {Locale} from '../../../../../../../../../../../provider/locale/locale';

import {ReactComponent as DislikeIcon} from './images/dislike-icon.svg';
import {ReactComponent as LikeIcon} from './images/like-icon.svg';
import * as styles from './preview-footer.scss';

export function PreviewFooter(): JSX.Element {
    return (
        <div className={styles.PreviewFooter}>
            <Button className={styles.PreviewFooter_action} ghost>
                <LikeIcon className={styles.PreviewFooter_icon} />
                <Locale stringKey="POSTS_FORM__PREVIEW__YANDEX__LIKE_TEXT" />
            </Button>
            <Button className={styles.PreviewFooter_action} ghost>
                <DislikeIcon className={styles.PreviewFooter_icon} />
            </Button>
        </div>
    );
}
