import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';
import {useCallback} from 'react';

import {TimeRangePicker} from '../../../../../layout/time-range-picker/time-range-picker';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {classNames} from '../../../../../util/css';
import {WorkDayTimeRangeType} from '../../workdays-form-item-type';

import {getDisabledHours, getDisabledMinutes} from './break-time-item-helper';
import * as styles from './break-time-item.scss';

type PropsType = {
    className?: string;
    updateBreakTime: (oldBreakTime: WorkDayTimeRangeType, newBreakTime: WorkDayTimeRangeType) => void;
    deleteBreakTime: (breakTime: WorkDayTimeRangeType) => void;
    breakTime: WorkDayTimeRangeType;
    prevBreakTime: WorkDayTimeRangeType | null;
    disabled?: boolean;
};

export function BreakTimeItem(props: PropsType): JSX.Element {
    const {className, updateBreakTime, breakTime, deleteBreakTime, prevBreakTime, disabled} = props;
    const {timeFrom, timeTo} = breakTime;

    const fullClassName = classNames(styles.BreakTime, className);
    const {getLocalizedString} = useLocale();

    const handleTimeChange = useCallback(
        (values: NoUndefinedRangeValueType<Dayjs> | null) => {
            if (!values) {
                updateBreakTime(breakTime, {timeFrom: null, timeTo: null});
                return;
            }

            const [dayjsFrom, dayjsTo] = values;

            updateBreakTime(breakTime, {
                timeFrom: dayjsFrom ? {hour: dayjsFrom.hour(), minute: dayjsFrom.minute()} : null,
                timeTo: dayjsTo ? {hour: dayjsTo.hour(), minute: dayjsTo.minute()} : null,
            });
        },
        [breakTime, updateBreakTime]
    );

    return (
        <div className={fullClassName}>
            <TimeRangePicker
                className={styles.BreakTime_timeRange}
                disabledTime={() => ({
                    disabledHours: getDisabledHours(prevBreakTime),
                    disabledMinutes: getDisabledMinutes(prevBreakTime),
                })}
                onChange={handleTimeChange}
                order={false}
                placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                size="small"
                value={[
                    timeFrom && dayjs().hour(timeFrom.hour).minute(timeFrom.minute),
                    timeTo && dayjs().hour(timeTo.hour).minute(timeTo.minute),
                ]}
            />

            <Button
                className={styles.BreakTime_remove}
                disabled={disabled}
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => deleteBreakTime(breakTime)}
                type="link"
            />
        </div>
    );
}
