import {Col, Row} from 'antd';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {useLicenses} from '../../../provider/license/license-hook';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {FeaturesEnum} from '../../../service/user/user-type';
import {ReviewsSummary} from '../company-activity-and-summary/widgets/reviews-summary/reviews-summary';

import {AutoRepliesSummary} from './auto-replies-summary/auto-replies-summary';
import {CatalogsSyncStatisticsMultipleCompanies} from './catalogs-sync-statistics/catalogs-sync-statistics-multiple-companies';
import {CompanySummary} from './company-summary/company-summary';
import {DashboardCompaniesMap} from './dashboard-companies-map/dashboard-companies-map';
import {DashboardRecommendations} from './dashboard-recomendations/dashboard-recommendations';
import {DashboardWidgetEnum} from './dashboard-type';
import {DashboardWidgetHeader} from './dashboard-widget-header/dashboard-widget-header';
import {FeatureDisabled} from './feature-disabled/feature-disabled';
import {OnlinePresenceSummary} from './online-presence-summary/online-presence-summary';
import {PhotosList} from './photos/photos';
import {DashboardPosts} from './posts/dashboard-posts';
import {ManyCompaniesPrices} from './prices/many-companies-prices';
import {DashboardReviews} from './reviews/reviews';
import {ReviewsRatingDistribution} from './reviews-rating-distribution/reviews-rating-distribution';
import {SearchPhrases} from './search-phrases/search-phrases';
import {TimelineActivityAggregated} from './timeline-activity/timeline-activity-aggregated';
import {YandexStories} from './yandex-stories/yandex-stories';
import * as styles from './dashboard.scss';

type DashboardPropsType = {
    companiesCount: number;
};

export function DashboardFewCompanies(props: DashboardPropsType): JSX.Element {
    const {companiesCount} = props;
    const {getLocalizedString} = useLocale();
    const {licenses} = useLicenses();

    return (
        <>
            <PageCard>
                <Meta title={getLocalizedString('DASHBOARD_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.dashboard.path,
                            titleLangKey: 'DASHBOARD_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <PageHeaderTitle
                        productName={UsetifulNameProductEnum.DASHBOARD}
                        title="DASHBOARD_PAGE__SUB_TITLE"
                    />
                </PageHeader>
                <MainPageCardDivider />
                <div className={styles.dashboard_many_companies__header_wrapper}>
                    <CompanySummary />
                    <DashboardCompaniesMap />
                    <YandexStories />
                </div>
            </PageCard>
            <Row gutter={[24, 24]}>
                <Col className={styles.page_cards} xl={12} xs={24}>
                    <CatalogsSyncStatisticsMultipleCompanies showCatalogSyncStats />
                    <DashboardReviews />
                    <ReviewsRatingDistribution />
                    <PageCard
                        title={
                            <DashboardWidgetHeader
                                title={<Locale stringKey="DASHBOARD_PAGE__REVIEWS_SUMMARY__HEADER" />}
                                widget={DashboardWidgetEnum.ReviewsSummary}
                            />
                        }
                    >
                        <ReviewsSummary />
                    </PageCard>
                    <OnlinePresenceSummary />
                    <SearchPhrases />
                </Col>
                <Col className={styles.page_cards} xl={12} xs={24}>
                    <DashboardRecommendations companiesCount={companiesCount} />
                    <ManyCompaniesPrices />
                    {licenses[FeaturesEnum.posts]?.isActive ? (
                        <PageCard
                            title={
                                <DashboardWidgetHeader
                                    title={<Locale stringKey="DASHBOARD_PAGE__POSTS__TITLE" />}
                                    widget={DashboardWidgetEnum.Posts}
                                />
                            }
                        >
                            <DashboardPosts />
                        </PageCard>
                    ) : (
                        <FeatureDisabled feature={FeaturesEnum.posts} />
                    )}
                    {licenses[FeaturesEnum.photoTool]?.isActive ? (
                        <PhotosList />
                    ) : (
                        <FeatureDisabled feature={FeaturesEnum.photoTool} />
                    )}
                    <AutoRepliesSummary />
                    <TimelineActivityAggregated />
                </Col>
            </Row>
        </>
    );
}
