import {Select} from 'antd';

import {useLocale} from '../../provider/locale/locale-hook';
import {RatingType} from '../../service/reviews/reviews-rating';
import {selectRatingContentWithNoRating} from '../select/select-helper';

import {SelectRatingType} from './rating-multi-select-type';

type PropsType = {
    disabled: boolean;
    value?: Array<RatingType>;
    onChange?: (value: Array<RatingType>) => void;
};

export function RatingMultiSelect(props: PropsType): JSX.Element {
    const {disabled, onChange, value} = props;

    const {getLocalizedString} = useLocale();

    const selectValue: Array<SelectRatingType> =
        value?.map<SelectRatingType>((rating: RatingType) => rating || 0) || [];

    function onSelectChange(selectedValue: Array<SelectRatingType>): void {
        onChange?.(selectedValue.map<RatingType>((selectRating: SelectRatingType) => selectRating || null));
    }

    return (
        <Select<Array<SelectRatingType>>
            disabled={disabled}
            mode="multiple"
            onChange={onSelectChange}
            placeholder={getLocalizedString('NOTIFICATIONS__REVIEW_FILTER__PLACEHOLDER__RATING')}
            size="large"
            value={selectValue}
        >
            {selectRatingContentWithNoRating}
        </Select>
    );
}
