import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {ComplaintsCountType} from '../../../../service/reviews-and-answers-statistics/complaints-count/complaints-count-type';
import {CompareModeDelta} from '../compare-mode-delta/compare-mode-delta';

import * as styles from './complaints-count.scss';

type PropsType = {
    isLoading?: boolean;
    complaintsCount: ComplaintsCountType | null;
    isCompareMode?: boolean;
};

export function ComplaintsCount(props: PropsType): JSX.Element {
    const {isLoading, complaintsCount, isCompareMode} = props;

    if (isLoading) {
        return <PageCard title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__COMPLAINTS__TITLE" />} />;
    }

    return (
        <PageCard title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__COMPLAINTS__TITLE" />}>
            {isCompareMode ? (
                <CompareModeDelta
                    compareValue={complaintsCount?.comparedValue || 0}
                    delta={complaintsCount?.delta || 0}
                    value={complaintsCount?.value || 0}
                />
            ) : (
                <Text block bolder className={styles.ComplaintsCount_mainPeriodValue}>
                    {complaintsCount?.value}
                </Text>
            )}
        </PageCard>
    );
}
