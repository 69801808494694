import {faCopy, faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useMessage} from '../../../../../provider/message/message-hook';
import {PostLinkType} from '../../../../../service/posts/posts-types';

import * as styles from './catalog-post-column.scss';

type PropsType = {
    data: PostLinkType;
};

export function PostLinkColumn(props: PropsType): JSX.Element {
    const {data} = props;
    const {message} = useMessage();
    const {getLocalizedString} = useLocale();

    const postLink = data.catalogLink || data.pageLink || '';

    async function handleCopyClick() {
        await navigator.clipboard.writeText(postLink);
        message.info(getLocalizedString('REVIEW_GENERATOR__QR_CODE__LINK_COPIED'));
    }

    return (
        <div className={styles.CatalogPostLink}>
            {postLink ? (
                <>
                    <Button
                        href={postLink}
                        icon={<FontAwesomeIcon icon={faExternalLink} />}
                        size="small"
                        target="_blank"
                        type="link"
                    />
                    <Button
                        disabled={!postLink}
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={handleCopyClick}
                        size="small"
                        type="link"
                    />
                </>
            ) : (
                <div>—</div>
            )}
        </div>
    );
}
