import {faBookOpen} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {KnowledgeArticleType} from '../../../../service/knowledge/knowledge-type';

import * as styles from './knowledge-article-link.scss';

type PropsType = {
    article: KnowledgeArticleType;
    isEllipsis?: boolean;
};

export function KnowledgeArticleLink(props: PropsType): JSX.Element {
    const {article, isEllipsis} = props;

    return (
        <NavigationLink
            className={styles.KnowledgeArticleLink}
            to={generatePath(appRoute.knowledgeArticle.path, {id: article.articleId})}
        >
            <FontAwesomeIcon icon={faBookOpen} />

            {isEllipsis ? (
                <Tooltip placement="bottom" title={article.title}>
                    <span className={styles.KnowledgeArticleLink_ellipsedText}>{article.title}</span>
                </Tooltip>
            ) : (
                <span>{article.title}</span>
            )}
        </NavigationLink>
    );
}
