import dayjs from 'dayjs';

import {classNames} from '../../../../util/css';

import * as styles from './validity-column.scss';

type PropsType = {
    dateFrom: string;
    dateTo: string;
};

export function ValidityColumn(props: PropsType): JSX.Element {
    const {dateFrom, dateTo} = props;

    if (dateFrom && !dateTo) {
        return <span>∞</span>;
    }

    if (!dateTo) {
        return <span>—</span>;
    }

    const isOutdated = dayjs(dateTo).isBefore(dayjs(), 'day');

    return <span className={classNames(isOutdated ? styles.outdated : '')}>{dateTo}</span>;
}
