import {TablePaginationConfig} from 'antd';
import {useEffect, useState} from 'react';

import {useApiHooks} from '../api-hook/api-hook';
import {getCompany} from '../company/company-api';
import {CompanyType} from '../company/company-type';

import {
    loadFacebookBrandPages,
    loadFacebookPages,
    loadMatchingLocations,
    loadNeedActionCompanies,
} from './matching-api';
import {matchingPaginationState} from './matching-const';
import {
    AccountMatchingCompaniesHookPropsType,
    AccountMatchingCompaniesHookType,
    BrandPageType,
    FacebookBrandPageHookType,
    FacebookPagesHookType,
    MatchingCompanyType,
    MatchingFacebookLocationType,
    MatchingLocationType,
} from './matching-types';

export function useFacebookPages(oauthAccountId: number): FacebookPagesHookType {
    const {isInProgress, setIsInProgress, result, setResult, setProcessError} =
        useApiHooks<Array<MatchingFacebookLocationType>>();

    useEffect(() => {
        setIsInProgress(true);

        loadFacebookPages(oauthAccountId)
            .then((response) => {
                setResult(response);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error) => setProcessError(error));
    }, [setIsInProgress, oauthAccountId, setProcessError, setResult]);

    return {
        pages: result || [],
        isInProgress,
    };
}

export function useFacebookBrandPage(brandId: number): FacebookBrandPageHookType {
    const {isInProgress, setIsInProgress, result, setResult, setProcessError} = useApiHooks<BrandPageType>();

    useEffect(() => {
        setIsInProgress(true);

        loadFacebookBrandPages(brandId)
            .then((response: Array<BrandPageType>) => {
                if (response.length === 1 && response[0]) {
                    setResult(response[0]);
                }
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error) => setProcessError(error));
    }, [setIsInProgress, setProcessError, setResult, brandId]);

    return {
        brandPage: result || null,
        isInProgress,
    };
}

export function useAccountMatchingCompanies(
    props: AccountMatchingCompaniesHookPropsType
): AccountMatchingCompaniesHookType {
    const {oauthAccountId, brandId, brandPageId, providerId, companyId, skip} = props;

    const [pagination, setPagination] = useState<TablePaginationConfig>(matchingPaginationState);
    const [total, setTotal] = useState(0);
    const [locations, setLocations] = useState<Array<MatchingLocationType>>([]);

    const {isInProgress, setIsInProgress, result, setResult, setProcessError, processError, parseError, isMounted} =
        useApiHooks<Array<MatchingCompanyType>>();

    useEffect(() => {
        async function getCompanies(): Promise<{count: number; companies: Array<CompanyType | MatchingCompanyType>}> {
            if (companyId) {
                const companyResponse = await getCompany(companyId);

                return {
                    count: 1,
                    companies: [companyResponse],
                };
            }

            const companiesResponse = await loadNeedActionCompanies({brandId, providerId, pagination});

            return {
                count: companiesResponse.count,
                companies: companiesResponse.results.map((item) => item.company),
            };
        }

        async function load() {
            if (!isMounted || skip) {
                return;
            }

            setIsInProgress(true);

            const {count, companies} = await getCompanies();

            setResult(companies);
            setTotal(count);

            if (!oauthAccountId || !isMounted) {
                return;
            }

            const locationsResponse = await loadMatchingLocations({
                providerId,
                oauthAccountId,
                brandId,
                brandPageId,
                companies: companies.map(({id, address}) => ({id, lat: Number(address.lat), lon: Number(address.lon)})),
            });

            setLocations(locationsResponse.locations);
        }

        load()
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error) => {
                setProcessError({
                    name: error.jsonData.key,
                    message: error.jsonData.error,
                });
            });
    }, [
        brandId,
        brandPageId,
        companyId,
        isMounted,
        oauthAccountId,
        pagination,
        providerId,
        skip,
        setIsInProgress,
        setProcessError,
        setResult,
        parseError,
    ]);

    return {
        companies: result || [],
        locations,
        isInProgress,
        processError,
        pagination: {...pagination, total},
        setPagination,
    };
}
