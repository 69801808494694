import {serialize} from '../../util/api-adapter';
import {ApiError} from '../../util/error';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {handleReplyError} from './reviews-helper';
import {FetchReviewsResultType, ReviewDataChildType} from './reviews-type';

export type ReviewsReplyType = {
    comment: string;
    replySourceType?: string;
};

export type ReviewsReplyErrorPayloadType = {
    error?: string;
    detail?: string;
};

export class ReviewsReplyError extends ApiError {
    name = 'ReviewsReplyError';
    jsonData: ReviewsReplyErrorPayloadType;

    constructor(message: string, statusCode: number, jsonData: ReviewsReplyErrorPayloadType) {
        super(message, statusCode, jsonData);
        this.jsonData = jsonData;
    }
}

export function fetchReviewsReply(reviewOrReplyId: number, data: ReviewsReplyType): Promise<ReviewDataChildType> {
    return fetchX<ReviewDataChildType>(`${rootApiUrl}/v4/reviews/${reviewOrReplyId}/reply/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize(data)),
    }).catch(handleReplyError);
}

export function fetchReviewsReplyEdit(
    reviewId: number,
    replyId: number,
    data: ReviewsReplyType
): Promise<ReviewsReplyType> {
    return fetchX<ReviewsReplyType>(`${rootApiUrl}/v4/reviews/${reviewId}/replies/${replyId}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(data),
    }).catch(handleReplyError);
}

export function fetchReviewsReplyRemove(reviewId: number, replyId: number): Promise<FetchReviewsResultType> {
    return fetchX<FetchReviewsResultType>(`${rootApiUrl}/v4/reviews/${reviewId}/replies/${replyId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
