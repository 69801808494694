import {PropsWithChildren} from 'react';

import {PageCard} from '../../../layout/page-card/page-card';
import {classNames} from '../../../util/css';

import * as styles from './landing-page-card.scss';

export function LandingPageCard(
    props: PropsWithChildren<{
        bodyClassName?: string;
    }>
): JSX.Element {
    const {children, bodyClassName} = props;

    return (
        <PageCard
            bodyClassName={classNames(styles.LandingPageCard_pageCardBody, bodyClassName)}
            className={styles.LandingPageCard}
        >
            {children}
        </PageCard>
    );
}
