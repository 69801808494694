import {Centrifuge} from 'centrifuge';
import {createContext, PropsWithChildren, useEffect, useState} from 'react';

import {useUser} from '../user/user-hook';

import {fetchCentrifugeToken, fetchCentrifugeUrl} from './centrifuge-api';

export const CentrifugeContext = createContext<Centrifuge | null>(null);

export function CentrifugeProvider(props: PropsWithChildren<Record<string, unknown>>): JSX.Element {
    const {children} = props;

    const [centrifuge, setCentrifuge] = useState<Centrifuge | null>(null);

    const {user} = useUser();

    useEffect(() => {
        let centrifugeConnection: Centrifuge | null = null;

        (async () => {
            const centrifugeUrl = await fetchCentrifugeUrl();

            centrifugeConnection = new Centrifuge(`${centrifugeUrl}/connection/websocket`, {
                getToken: fetchCentrifugeToken,
            });

            setCentrifuge(centrifugeConnection);
            centrifugeConnection.connect();
        })();

        return () => centrifugeConnection?.disconnect();
    }, [user?.id]);

    return <CentrifugeContext.Provider value={centrifuge}>{children}</CentrifugeContext.Provider>;
}
