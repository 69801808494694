import {PropsWithChildren} from 'react';

import {classNames} from '../../../util/css';

import * as styles from './legend-wrapper.scss';

type PropsType = PropsWithChildren<Record<string, unknown>> & {
    className?: string;
};

export function LegendWrapper(props: PropsType): JSX.Element {
    const {children, className} = props;

    return <fieldset className={classNames(styles.LegendWrapper, className)}>{children}</fieldset>;
}
