import {useState} from 'react';

import {classNames} from '../../util/css';
import {Text} from '../text/text';

import * as styles from './catalog-info.scss';

type PropsType = {
    logoUrl: string;
    catalogName: string;
    hideName?: boolean;
    iconClassName?: string;
    className?: string;
};

export function CatalogInfoRenderer(props: PropsType): JSX.Element {
    const {logoUrl, catalogName, hideName, iconClassName, className} = props;
    const [hasLogoError, setHasLogoError] = useState<boolean>(false);

    return (
        <div className={classNames(styles.CatalogInfo, className)}>
            {logoUrl && !hasLogoError && (
                <img
                    alt=""
                    className={classNames(styles.CatalogInfo_logo, iconClassName)}
                    onError={() => setHasLogoError(true)}
                    src={logoUrl}
                />
            )}
            {!hideName && <Text>{catalogName}</Text>}
        </div>
    );
}
