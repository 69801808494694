import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Collapse, Space, Tag, Tooltip} from 'antd';
import {isBoolean, sortBy} from 'lodash';

import {Locale} from '../../../../../../../provider/locale/localization';
import {CompaniesImportErrorCodeType} from '../../../../../../../service/companies-import/companies-import-result-error-codes';
import {CompaniesImportTypeEnum} from '../../../../../../../service/companies-import/companies-import-type';

import {FeedResultsErrors} from './errors/feed-results-errors';
import * as styles from './feed-results-error-groups.scss';

type PropsType = {
    importValidationId: string;
    errors: Array<CompaniesImportErrorCodeType>;
    isOpenAll: boolean | null;
    feedFormat: CompaniesImportTypeEnum | null;
};

export function FeedResultsErrorGroups(props: PropsType): JSX.Element {
    const {importValidationId, errors, isOpenAll, feedFormat} = props;

    const activeKey = !isOpenAll ? [] : errors.map(({code}) => code);
    const sortedErrors = sortBy(errors, [({isCritical}) => !isCritical, ({msg}) => msg]);

    return (
        <Collapse
            {...(isBoolean(isOpenAll) ? {activeKey} : {})} // eslint-disable-line react/jsx-props-no-spreading
            items={sortedErrors.map((errorGroup) => ({
                forceRender: true, // scroll jumps to the top without it
                key: errorGroup.code,
                label: (
                    <div className={styles.FeedResultsErrorGroups_collapsePanelHeader}>
                        <span>{errorGroup.msg}</span>

                        {errorGroup.isCritical && (
                            <Tooltip
                                placement="bottom"
                                title={<Locale stringKey="FEED__RESULTS__CRITICAL_ERROR__DESCRIPTION" />}
                            >
                                <Tag className={styles.FeedResultsErrorGroups_tag__criticalError}>
                                    <Space>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />
                                        <Locale stringKey="FEED__RESULTS__CRITICAL_ERROR" />
                                    </Space>
                                </Tag>
                            </Tooltip>
                        )}
                    </div>
                ),
                children: (
                    <FeedResultsErrors
                        code={errorGroup.code}
                        feedFormat={feedFormat}
                        importValidationId={importValidationId}
                        isCritical={errorGroup.isCritical}
                        key={errorGroup.code}
                    />
                ),
            }))}
        />
    );
}
