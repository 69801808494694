import {useMutation, UseMutationResult} from '@tanstack/react-query';

import {appRoute} from '../../app-route';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {fetchNoContent} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {useUrl} from '../../util/url-hook/url-hook';
import {mainApiHeaders, rootApiUrl} from '../api/api-const';

type QueryParametersType = {
    emailChangeCode: string | null;
    token: string | null;
    userId: string | null;
};

function changeEmail(userId: string, token: string, email: string) {
    const parameters = objectToUrlParameters({email});
    const url = `${rootApiUrl}/user/change_email/${userId}/${token}/?${parameters}`;

    return fetchNoContent(url, {headers: mainApiHeaders});
}

export function useChangeEmailUrlQuery(): QueryParametersType & {isCorrectUrl: boolean} {
    const search = new URLSearchParams(location.search);

    const emailChangeCode = search.get('email_change_code');
    const token = search.get('token');
    const userId = search.get('uid');

    const isCorrectUrl = Boolean(emailChangeCode && token && userId);

    return {emailChangeCode, token, userId, isCorrectUrl};
}

export function useChangeEmailMutation(): UseMutationResult<void, unknown, void> {
    const {userId, token, emailChangeCode} = useChangeEmailUrlQuery();

    const {replaceUrl} = useUrl();
    const {snackbar} = useSnackbar();

    function mutate() {
        if (!userId || !token || !emailChangeCode) {
            return Promise.reject('Missing url params');
        }

        return changeEmail(userId, token, emailChangeCode);
    }

    function onSuccess() {
        replaceUrl(appRoute.root.path);

        snackbar.success(<Locale stringKey="CHANGE_EMAIL__SUCCESS" />);
    }

    return useMutation(mutate, {onSuccess});
}
