import {Form, Select} from 'antd';
import {NamePath} from 'antd/lib/form/interface';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {availablePostButtonTypeTranslationMap} from '../../../../../../../../service/posts/posts-hook-const';
import {GoogleButtonTypeEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {PostFormItemPropsType} from '../../../../post-form-type';

type PropsType = {
    fieldName: NamePath;
} & PostFormItemPropsType;

export function ButtonTypeItem(props: PropsType): JSX.Element {
    const {fieldName, disabled} = props;

    return (
        <Form.Item<PostFormType>
            initialValue={GoogleButtonTypeEnum.Unspecified}
            label={<Locale stringKey="POSTS_FORM__BUTTON_TYPE__LABEL" />}
            name={fieldName}
        >
            <Select<GoogleButtonTypeEnum> disabled={disabled} filterOption={() => true}>
                {Object.values(GoogleButtonTypeEnum).map((typeInList: GoogleButtonTypeEnum): JSX.Element => {
                    return (
                        <Select.Option key={typeInList} value={typeInList}>
                            <Locale stringKey={availablePostButtonTypeTranslationMap[typeInList]} />
                        </Select.Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
}
