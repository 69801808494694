import {ItemType} from 'antd/es/menu/interface';

import {navigationDataLangKey} from '../../component/navigation/navigation-type';
import {enUs} from '../../provider/locale/translation/en-us/data';
import {UserType} from '../user/user-type';

import {AnalyticsEventEnum} from './analytics-type';

function findMenuItemByPathname(item: ItemType, pathname: string): boolean {
    return Boolean(item?.key && pathname.startsWith(item.key.toString()));
}

function getActiveLeftMenuText(pathname: string, menuItems: Array<ItemType>): string {
    const menuItem = menuItems.find((item) => findMenuItemByPathname(item, pathname));

    if (!menuItem) {
        return '';
    }

    const langKey = menuItem[navigationDataLangKey];
    const childrenMenuItem =
        'children' in menuItem && menuItem.children?.find((item) => findMenuItemByPathname(item, pathname));

    if (childrenMenuItem) {
        const childrenLangKey = childrenMenuItem[navigationDataLangKey];

        return langKey && childrenLangKey ? `${enUs[langKey]} - ${enUs[childrenLangKey]}` : '';
    }

    return langKey ? enUs[langKey] : '';
}

export function pushToDataLayer(data: Record<string, unknown>): void {
    if (!Array.isArray(window.dataLayer)) {
        return;
    }

    window.dataLayer.push(data);
}

export function trackNavigationGoogleAnalytics(
    pathname: string,
    previousPathname: string,
    menuItems: Array<ItemType>
): void {
    pushToDataLayer({
        previousSectionComb: getActiveLeftMenuText(previousPathname, menuItems),
        sectionComb: getActiveLeftMenuText(pathname, menuItems),
    });
}

export function initializeGoogleAnalytics(user: UserType): void {
    // using allowlist to make sure that no personal fields are sent (email, profile)
    pushToDataLayer({
        event: AnalyticsEventEnum.RocketdataEvent,
        eventCategory: 'User Data',
        eventAction: 'User Pageview',
        eventLabel: '',
        id: user.id,
        language: user.language,
        features: user.features,
        is_staff: user.isStaff,
        is_rocketdata: user.isRocketdata,
        is_test_user: user.isTestUser,
        is_demo_user: user.isDemoUser,
        is_double_gis_trusted: user.isDoubleGisTrusted,
    });
}
