import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';

import {classNames} from '../../../../../util/css';

import * as styles from './info-label.scss';

type PropsType = {
    rootClassName?: string;
    iconClassName?: string;
    hintContent: JSX.Element;
    hintContentClassName?: string;
    hintTitle: JSX.Element;
    label: JSX.Element;
};

export function InfoLabel(props: PropsType): JSX.Element {
    const {label, hintContent, hintContentClassName, hintTitle, rootClassName, iconClassName} = props;

    return (
        <div className={styles.InfoLabel}>
            {label}
            <Popover
                content={
                    <div className={classNames(styles.InfoLabel_content, hintContentClassName)}>{hintContent}</div>
                }
                rootClassName={rootClassName}
                title={hintTitle}
                trigger="hover"
            >
                <FontAwesomeIcon className={classNames(styles.InfoLabel_icon, iconClassName)} icon={faInfoCircle} />
            </Popover>
        </div>
    );
}
