import {useCallback, useEffect} from 'react';

import {useApiHooks} from '../api-hook/api-hook';

import {fetchKnowledgeArcticle, fetchKnowledgeArcticleList} from './knowledge-api';
import {
    KnowledgeDataArticleType,
    KnowledgeOptionsType,
    KnowledgeResponseType,
    UseKnowledgeArticleType,
    UseKnowledgeType,
} from './knowledge-type';

type KnowledgeHookPropsType = {
    mainFilterKey: string;
    filterOptions: KnowledgeOptionsType;
};

export function useKnowledgeList(props: KnowledgeHookPropsType): UseKnowledgeType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<KnowledgeResponseType>();

    const {mainFilterKey, filterOptions} = props;

    const load = useCallback(
        (options: KnowledgeOptionsType) =>
            fetchKnowledgeArcticleList(
                {
                    q: options.q,
                },
                mainFilterKey
            )
                .then((fetchResult): void => {
                    setResult(fetchResult);
                    setProcessError(null);
                })
                .finally(() => setIsInProgress(false))
                .catch(setProcessError),
        [mainFilterKey, setIsInProgress, setProcessError, setResult]
    );

    useEffect(() => {
        setIsInProgress(true);
        load(filterOptions);
    }, [filterOptions, load, setIsInProgress, setProcessError, setResult]);

    return {load, isInProgress, processError, result, reset};
}

export function useKnowledgeArticle(id: number): UseKnowledgeArticleType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<KnowledgeDataArticleType>();

    const load = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);

        return fetchKnowledgeArcticle(id)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [id, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        load();
    }, [load]);

    return {isInProgress, processError, result, reset, load};
}
