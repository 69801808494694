import {Tag} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/localization';
import {UsersBrandsType} from '../../../../../../../service/user-management/user-management-users';
import {classNames} from '../../../../../../../util/css';

import * as styles from './brands-column.scss';

type PropsType = {
    brand: UsersBrandsType['brands'];
};

export function BrandsColumn(props: PropsType): JSX.Element {
    const {brand} = props;

    const moreBrandsCount = brand.count - brand.names.length;

    if (brand.count === 0) {
        return <Tag className={styles.BrandsColumn_tag}>{brand.count}</Tag>;
    }

    return (
        <AdditionalInfo
            className={styles.BrandsColumn_additionalInfo}
            icon={<Tag className={styles.BrandsColumn_tag}>{brand.count}</Tag>}
            placement="bottom"
            title={
                <div className={styles.BrandsColumn_title}>
                    <Locale stringKey="USERS__TABLE__BRANDS__TITLE" />

                    <span>{brand.count}</span>
                </div>
            }
        >
            <ul
                className={classNames(styles.BrandsColumn_list, {
                    [styles.BrandsColumn_list__noTextBelow]: moreBrandsCount === 0,
                })}
            >
                {brand.names.map((name) => (
                    <li key={name}>{name}</li>
                ))}
            </ul>

            {moreBrandsCount > 0 ? (
                <Text secondary stringKey="USERS__TABLE__BRANDS__MORE" valueMap={{count: moreBrandsCount}} />
            ) : null}
        </AdditionalInfo>
    );
}
