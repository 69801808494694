import {useEffect, useState} from 'react';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {CountryV2Type, IdNameType} from '../../../../../../service/api/api-type';
import {useSearchRegionList} from '../../../../../../service/api-hook/api-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../typings/antd';

import {buildFullSearchQuery} from './build-query-helper';
import {SelectLocation} from './select-location/select-location';

type PropsType = {
    value?: IdNameType | null;
    onChange?: (value?: IdNameType | null) => void;
    country: CountryV2Type | null;
    onAfterChange: () => void;
};

export function SelectRegion(props: PropsType): JSX.Element {
    const {value, onChange, country, onAfterChange} = props;

    const {getLocalizedString} = useLocale();
    const [searchQuery, setSearchQuery] = useState('');
    const {isInProgress, result} = useSearchRegionList(searchQuery || null, country?.id, country?.lang);
    const formRegion = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'region'>([
        CompanyKeyEnum.Address,
        'region',
    ]);

    const results: Array<IdNameType> = result?.results || [];

    useEffect(() => {
        if (!formRegion) {
            setSearchQuery('');
        }
    }, [formRegion]);

    function handleChange(newValue?: IdNameType | null) {
        onChange?.(newValue);
        onAfterChange();
    }

    return (
        <SelectLocation
            addressKey="province"
            country={country}
            isDisabled={!country}
            isInProgress={isInProgress}
            isRequired
            isSelectRequired
            label={<Locale stringKey="COMPANY_FORM__ADDRESS__REGION__LABEL" />}
            onChange={handleChange}
            placeholder={getLocalizedString('COMPANY_FORM__ADDRESS__REGION__PLACEHOLDER')}
            searchQuery={searchQuery}
            searchQueryList={buildFullSearchQuery(country)}
            selectItems={results}
            setSearchQuery={setSearchQuery}
            value={value}
        />
    );
}
