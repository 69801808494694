import {PropsWithChildren, useLayoutEffect, useState} from 'react';

export function VitePreloadErrorHandler(props: PropsWithChildren<Record<string, unknown>>): JSX.Element | null {
    const {children} = props;

    const [hasPreloadError, setHasPreloadError] = useState<boolean>(false);

    useLayoutEffect(() => {
        window.addEventListener('vite:preloadError', () => {
            setHasPreloadError(true);
            window.location.reload();
        });
    }, []);

    if (hasPreloadError) {
        return null;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
