import {deserializeV2} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../../util/fetch';
import {rootApiUrl} from '../../api/api-const';

import {linkBrandsResponseSchema, LinkBrandType} from './link-brands-type';

export async function fetchLinkBrands(groupId: number): Promise<Array<LinkBrandType>> {
    const url = `${rootApiUrl}/v1/source_settings/brands/?catalog_group_id=${groupId}`;
    const response = await fetchX(url, {method: FetchMethodEnum.get});

    return deserializeV2<Array<LinkBrandType>>(url, linkBrandsResponseSchema, response);
}
