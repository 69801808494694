import {FC} from 'react';

import {classNames} from '../../../../../util/css';

import * as styles from './wl-logo-full.scss';

type PropsType = {
    className?: string;
};

export function getWlFullLogo(src: string): FC<PropsType> {
    return function LogoFull(props: PropsType): JSX.Element {
        const {className} = props;

        const fullClassName = classNames(styles.LogoFull, className);

        return <img alt="" className={fullClassName} src={src} />;
    };
}
