import {Button, Modal, Space} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useRef} from 'react';

import {Text} from '../../../../component/text/text';
import {Logo} from '../../../../layout/logo/logo';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/locale';
import {ShortLocaleNameEnum} from '../../../../provider/locale/locale-context-type';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {useMessage} from '../../../../provider/message/message-hook';
import {useIsVisibleOnce} from '../../../../util/intersection-observer-hook/intersection-observer-hook';
import {
    useYandexTransferAccountSuccess,
    useYandexTransferModalData,
} from '../yandex-account-transfer-alert/yandex-account-transfer-hook';

import yandexAccountImage from './image/modal-image.png';
import * as styles from './yandex-account-transfer-modal.scss';

type PropsType = {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    cclId?: number;
    companyId?: number;
    companyInfo?: {
        name: string;
        address: string;
    } | null;
    isCheckAll: boolean;
};

// eslint-disable-next-line complexity
export function YandexAccountTransferModal(props: PropsType): ReactElement {
    const {setOpenModal, cclId, companyInfo, isCheckAll, companyId} = props;
    const {getLocalizedString, shortLocaleName} = useLocale();
    const {message} = useMessage();
    const {yandex} = useDocumentationLinks();
    const {companyName} = useDomainConfig();

    const {data, isLoading} = useYandexTransferModalData({cclId, companyId});
    const {mutate} = useYandexTransferAccountSuccess();
    const ref = useRef(null);

    const isVisible = useIsVisibleOnce(ref, isLoading);

    async function handleCopyClick() {
        if (data?.newAccountEmail) {
            await navigator.clipboard.writeText(data.newAccountEmail);
            message.info(getLocalizedString('TEXT__COPIED'));
        }
    }

    function getCompanyDescription(): {link: string; translate: LangKeyType} {
        if (data?.chainLink) {
            return {
                link: data.chainLink,
                translate: 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST__CHAIN',
            };
        }

        if (data?.companyLink) {
            return {
                link: data.companyLink,
                translate: 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST__COMPANY',
            };
        }

        return {
            link: data ? data.organizationsLink : '',
            translate: 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST__MY_COMPANIES',
        };
    }

    function onOkHandler() {
        if (data?.companyChainPk) {
            mutate({companyChainPk: data.companyChainPk, isCheckAll});
        }

        if (data?.companySpravPk) {
            mutate({companySpravPk: data.companySpravPk, isCheckAll});
        } else {
            mutate({isCheckAll});
        }

        setOpenModal(false);
    }

    return (
        <Modal
            // eslint-disable-next-line react/no-unstable-nested-components
            footer={(_data, {OkBtn, CancelBtn}) => (
                <Space className={styles.YandexAccountTransferModal_footer}>
                    {yandex.transferAccountsBanner ? (
                        <Button>
                            <a href={yandex.transferAccountsBanner} rel="noreferrer" target="_blank">
                                <Locale stringKey="TEXT__MORE_DETAILS" />
                            </a>
                        </Button>
                    ) : (
                        <div />
                    )}
                    <Space>
                        <CancelBtn />
                        <OkBtn />
                    </Space>
                </Space>
            )}
            okButtonProps={{disabled: !isVisible}}
            okText={<Locale stringKey="POPUP__BUTTON__CONFIRM" />}
            onCancel={() => setOpenModal(false)}
            onOk={onOkHandler}
            open
            title={<Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__TITLE" />}
            zIndex={1031}
        >
            {!isLoading ? (
                <div className={styles.YandexAccountTransferModal_wrapper}>
                    {companyInfo && (
                        <div className={styles.YandexAccountTransferModal_companyInfoWrapper}>
                            {data?.chainLink ? (
                                <Text
                                    block
                                    stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__CHAIN"
                                    valueMap={{companyName: companyInfo.name}}
                                />
                            ) : (
                                <Text block>{companyInfo.name}</Text>
                            )}
                            <Text block lighter>
                                {companyInfo.address}
                            </Text>
                        </div>
                    )}
                    <Text
                        bolder
                        stringKey={
                            data?.chainLink
                                ? 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__DESCRIPTION__CHAIN'
                                : 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__DESCRIPTION'
                        }
                    />
                    <ol className={styles.YandexAccountTransferModal_descriptionWrapper}>
                        <li>
                            <Text
                                stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIRST"
                                valueMap={{
                                    pages: (
                                        <a href={getCompanyDescription().link} rel="noreferrer" target="_blank">
                                            <Locale stringKey={getCompanyDescription().translate} />
                                        </a>
                                    ),
                                }}
                            />
                        </li>
                        {!data?.chainLink && !data?.companyLink && (
                            <li>
                                <Text stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__TWO" />
                            </li>
                        )}
                        <li>
                            <Text
                                stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND"
                                valueMap={{
                                    access: (
                                        <Text
                                            bolder
                                            stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__ACCESS"
                                        />
                                    ),
                                    add: (
                                        <Text
                                            bolder
                                            stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__ADD"
                                        />
                                    ),
                                }}
                            />
                        </li>
                        <li>
                            <Text
                                stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__THIRD"
                                valueMap={{
                                    domainName: companyName,
                                    add: (
                                        <Text
                                            bolder
                                            stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__SECOND__ADD"
                                        />
                                    ),
                                }}
                            />
                        </li>
                        <div className={styles.YandexAccountTransferModal_copyLinkWrapper}>
                            <Space>
                                <Logo className={styles.YandexAccountTransferModal_logo} isShort />
                                {data?.newAccountEmail}
                            </Space>
                            <Button onClick={handleCopyClick} size="small">
                                <Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__COPY_BUTTON" />
                            </Button>
                        </div>
                        {(shortLocaleName === ShortLocaleNameEnum.ru || shortLocaleName === ShortLocaleNameEnum.kk) && (
                            <img alt="" className={styles.YandexAccountTransferModal_image} src={yandexAccountImage} />
                        )}
                        <li ref={ref}>
                            <Text
                                stringKey={
                                    !data?.chainLink && !data?.companyLink
                                        ? 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FIVE'
                                        : 'MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__STEP__FOURTH'
                                }
                            />
                        </li>
                    </ol>
                </div>
            ) : (
                <Spinner />
            )}
        </Modal>
    );
}
