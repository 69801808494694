import {TableColumnsType} from 'antd';

import {
    getAvgResponseDelayColumn,
    getComplaintColumn,
    getEmployeeColumn,
    getEmployeeRepliedColumn,
    getRepliedPercentage,
    getSentimentColumn,
} from '../../components/table/reviews-analysis-table-helper';
import {EmployeeTableDataType} from '../../reviews-analysis-type';

export const employeesPageColumns: TableColumnsType<EmployeeTableDataType> = [
    getEmployeeColumn<EmployeeTableDataType>(),
    getEmployeeRepliedColumn<EmployeeTableDataType>(),
    getComplaintColumn<EmployeeTableDataType>(),
    getRepliedPercentage<EmployeeTableDataType>(),
    getAvgResponseDelayColumn<EmployeeTableDataType>(),
    getSentimentColumn<EmployeeTableDataType>(),
];
