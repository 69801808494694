import {faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space} from 'antd';
import {useEffect} from 'react';

import {AntdErrorMessage} from '../../../../../../../../component/antd-error-message/antd-error-message';
import {AddButton} from '../../../../../../../../layout/add-button/add-button';
import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {LocaleWithCatalogName} from '../../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../../../provider/user/user-hook';
import {CompanyKeyEnum} from '../../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../../service/company-v2/company-type';
import {TimeRangeType} from '../../../../../../../../service/company-v2/types/company-working-time-type';
import {Form, FormInstance} from '../../../../../../../../typings/antd';
import {classNames} from '../../../../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../../../../util/type';
import {Unsupported2GisFacebookCountryEnum} from '../../../../../company-form-const';

import {BreakTimeItem} from './break-time-item/break-time-item';
import * as styles from './break-time-list.scss';

type PropsType = {
    className?: string;
    breakTimeList: Array<TimeRangeType>;
    setBreakTimeList: (breakTimeList: Array<TimeRangeType>) => void;
    errorMessage?: Record<number, TimeRangeType>;
    catalogId?: number;
    isDoubleGisCompany: boolean;
};

// eslint-disable-next-line complexity
export function BreakTimeList(props: PropsType): JSX.Element {
    const {className, breakTimeList, setBreakTimeList, errorMessage, catalogId, isDoubleGisCompany} = props;

    const fullClassName = classNames(styles.BreakTimeList, className);
    const isBreakTimeListEmpty = breakTimeList.length === 0;

    const facebookExtrafieldExists = Form.useWatch<CompanyKeyEnum.ExtraWorkingHours, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.ExtraWorkingHours
    )?.some((catalog) => catalog.catalogId === ProvidersIdsEnum.facebook);

    const address = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>>(CompanyKeyEnum.Address);

    const {user} = useUser();

    const isShowFacebookInfo =
        user?.regionAvailableCatalogs[ProvidersIdsEnum.facebook] &&
        breakTimeList.length > 1 &&
        !catalogId &&
        !facebookExtrafieldExists &&
        (isDoubleGisCompany ? address?.country?.id !== Unsupported2GisFacebookCountryEnum.RussianFederation : true);

    function handleAddBreakTime() {
        setBreakTimeList([...breakTimeList, {}]);
    }

    function updateBreakTime(oldBreakTime: TimeRangeType, newBreakTime: TimeRangeType) {
        const newBreakTimeList = [...breakTimeList];

        newBreakTimeList[breakTimeList.indexOf(oldBreakTime)] = newBreakTime;

        setBreakTimeList(newBreakTimeList);
    }

    function deleteBreakTime(breakTime: TimeRangeType) {
        const newBreakTimeList = [...breakTimeList];

        newBreakTimeList.splice(newBreakTimeList.indexOf(breakTime), 1);

        setBreakTimeList(newBreakTimeList);
    }

    useEffect(() => {
        if (catalogId === ProvidersIdsEnum.facebook && breakTimeList && breakTimeList[0]) {
            setBreakTimeList([breakTimeList[0]]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogId]);

    return (
        <div className={fullClassName}>
            {breakTimeList.map((breakTime: TimeRangeType, index: number) => {
                return (
                    <BreakTimeItem
                        breakTime={breakTime}
                        deleteBreakTime={deleteBreakTime}
                        errorMessage={errorMessage?.[index]}
                        key={`${String(index)}-${JSON.stringify(breakTime)}`}
                        prevBreakTime={breakTimeList[index - 1] || null}
                        updateBreakTime={updateBreakTime}
                    />
                );
            })}
            <Space>
                {(catalogId !== ProvidersIdsEnum.facebook || breakTimeList.length === 0) && (
                    <AddButton
                        className={styles.BreakTimeList_breakTime__addBreak}
                        key={String(isBreakTimeListEmpty)}
                        onClick={handleAddBreakTime}
                        type="link"
                    >
                        <Locale stringKey="COMPANY_FORM__WORKING_TIME__BUTTON__ADD_BREAK" />
                    </AddButton>
                )}
                {isShowFacebookInfo && (
                    <AdditionalInfo
                        icon={
                            <FontAwesomeIcon
                                className={styles.BreakTimeList_infoIcon}
                                color="#FC9221"
                                icon={faTriangleExclamation}
                            />
                        }
                        placement="bottom"
                        title={
                            <LocaleWithCatalogName
                                catalogId={ProvidersIdsEnum.facebook}
                                namePlaceholder="FB"
                                stringKey="COMPANY_FORM__WORKING_TIME__FACEBOOK_BREAKS__TITLE"
                            />
                        }
                    >
                        <LocaleWithCatalogName
                            catalogId={ProvidersIdsEnum.facebook}
                            namePlaceholder="FB"
                            stringKey="COMPANY_FORM__WORKING_TIME__FACEBOOK_BREAKS__DESCRIPTION"
                        />
                    </AdditionalInfo>
                )}
            </Space>

            {errorMessage && Array.isArray(errorMessage) && <AntdErrorMessage>{errorMessage}</AntdErrorMessage>}
        </div>
    );
}
