import {Locale} from '../../../../../../../../../provider/locale/localization';

import {determineProvider} from './catalog-requirements-lint-helper';

type PropsType = {
    requirements?: string;
};

export function CatalogRequirementsLink(props: PropsType): JSX.Element | null {
    const {requirements} = props;

    const requirementLinks =
        requirements
            ?.split(',')
            .map((requirementLink) => requirementLink.trim())
            .filter(Boolean) || [];

    if (requirementLinks.length === 0) {
        return null;
    }

    return (
        <>
            <Locale stringKey="POSTS__CATALOG_REQUIREMENTS" />
            <ul>
                {requirementLinks.map((link: string) => {
                    return (
                        <li key={link}>
                            <Locale
                                stringKey="POSTS__CATALOG_REQUIREMENTS__ITEM_TEXT"
                                valueMap={{
                                    catalog: (
                                        <a href={link} rel="noreferrer" target="_blank">
                                            {determineProvider(link)}
                                        </a>
                                    ),
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
