export const enSourcesConfirmationTranslations = {
    SOURCES_CONFIRMATION__BREADCRUMBS: 'Confirmations',
    SOURCES_CONFIRMATION__EMPTY_LIST: 'There are no applications for confirmation',

    SOURCES_CONFIRMATION__ERROR_MESSAGE__IS_NOT_A_COMPANY_PHONE:
        'The phone number used is not a phone of the company you are trying to confirm.',
    SOURCES_CONFIRMATION__ERROR_MESSAGE__IS_NOT_A_CHAIN_PHONE:
        'The phone number used is not a phone of the chain you are trying to confirm.',
    SOURCES_CONFIRMATION__ERROR_MESSAGE__IS_NOT_MOBILE:
        'A code request via SMS was sent to a landline number. Please request the code again',
    SOURCES_CONFIRMATION__ERROR_MESSAGE__ATTEMPTS_LIMIT_EXCEEDED:
        'The number of attempts for the confirmation was exceeded. Your should try new confirmation.',
    SOURCES_CONFIRMATION__ERROR_MESSAGE__COMMON:
        'Unknown error has occurred. Please contact your manager for clarification.',

    SOURCES_CONFIRMATION__INCORRECT_TITLE: 'Wrong in the request?',

    SOURCES_CONFIRMATION__STATUS__CREATED: 'A request for a pin code has been created on the service side',
    SOURCES_CONFIRMATION__STATUS__IN_PROGRESS: 'Processing of the PIN code request has begun on the service side',
    SOURCES_CONFIRMATION__STATUS__SENT:
        'The code was successfully requested. If you haven’t received the code, request it again',
    SOURCES_CONFIRMATION__STATUS__ERROR_ON_SENDING:
        'An error occurred while sending a request to the partner site. You must re-request the PIN code',
    SOURCES_CONFIRMATION__STATUS__RECEIVED: 'The sending of PIN code from service to the site partner has begun',
    SOURCES_CONFIRMATION__STATUS__ERROR_ON_CONFIRM: 'Wrong PIN code entered. Re-request the PIN code',
    SOURCES_CONFIRMATION__STATUS__CONFIRM: 'Confirmation of rights was successful',
    SOURCES_CONFIRMATION__STATUS__REQUEST_RIGHTS_SENT:
        'If the current owner of the rights to manage the location confirms the request, then the rights to manage the location will be transferred to you',
    SOURCES_CONFIRMATION__STATUS__REQUEST_RIGHTS_EXPIRED: 'The request for management is not confirmed.',

    SOURCES_CONFIRMATION__METHOD: 'Method: ',
    SOURCES_CONFIRMATION__METHOD__SMS: 'Sms',
    SOURCES_CONFIRMATION__METHOD__LINK: 'Link',
    SOURCES_CONFIRMATION__METHOD__ADDRESS: 'Address',
    SOURCES_CONFIRMATION__METHOD__PHONE_CALL: 'Phone call',
    SOURCES_CONFIRMATION__METHOD__EMAIL: 'Email',
    SOURCES_CONFIRMATION__METHOD__PHONE: 'Phone call',
    SOURCES_CONFIRMATION__METHOD__VETTED_PARTNER: 'Automatic',
    SOURCES_CONFIRMATION__METHOD__RIGHTS_URL: 'Request rights',

    SOURCES_CONFIRMATION__BUTTON__GET_CODE: 'Get the code',
    SOURCES_CONFIRMATION__GOOGLE__GET_CONFIRM_CODE_TITLE: 'Confirmation of rights to manage a company in Google',
    SOURCES_CONFIRMATION__YANDEX__GET_CONFIRM_CODE_TITLE: 'Confirmation of rights to manage a company in Yandex',

    SOURCES_CONFIRMATION__GET_CODE__DESCRIPTION:
        'To be able to respond to reviews and quickly update data, you must confirm that you are a company representative.',
    SOURCES_CONFIRMATION__GET_CODE__SELECT_METHOD: 'Select a method for obtaining a verification code.',

    SOURCES_CONFIRMATION__GET_CODE__ADDRESS__SEND_TO_CURRENT_ADDRESS: 'Send a confirmation code to this mail address',
    SOURCES_CONFIRMATION__GET_CODE__ADDRESS__DELIVERY_TIME: 'Delivery may take up to 26 days.',
    SOURCES_CONFIRMATION__GET_CODE__YANDEX__TIME_TO_CALL:
        'A Yandex robot will call you back within 10 minutes. If he does not get through, then a Yandex employee will call in a few days.',

    SOURCES_CONFIRMATION__GET_CODE__PHONE: 'Phone number',
    SOURCES_CONFIRMATION__GET_CODE__ADDITIONAL_NUMBER: 'Additional number',
    SOURCES_CONFIRMATION__GET_CODE__CONTACT_PERSON: 'Contact person',

    SOURCES_CONFIRMATION__ENTER_CODE__BUTTON: 'Enter code',
    SOURCES_CONFIRMATION__ENTER_CODE__TITLE: 'Enter confirmation code',
    SOURCES_CONFIRMATION__ENTER_CODE__CODE_PLACEHOLDER: 'Confirmation code',

    SOURCES_CONFIRMATION__COMPANY_INFORMATION: 'Information about my company:',
    SOURCES_CONFIRMATION__SUGGESTED_OPTIONS: 'Suggested options:',
    SOURCES_CONFIRMATION__FOUNDED_COMPANIES__TITLE: 'Found companies',
    SOURCES_CONFIRMATION__FOUNDED_COMPANIES__DESCRIPTION:
        'It looks like your business is listed on Google Maps. Select a company from the list below to confirm.',
    SOURCES_CONFIRMATION__CREATE_NEW__DESCRIPTION:
        'If there is no suitable company in the list, select “My company is not in the list. Create and Verify New ”',
    SOURCES_CONFIRMATION__COMPANY_NOT_IN_LIST: 'My company is not listed',
    SOURCES_CONFIRMATION__CREATE_NEW_AND_CONFIRM__BUTTON: 'Create and confirm',
    SOURCES_CONFIRMATION__CONFIRM__BUTTON: 'Confirm company',
    SOURCES_CONFIRMATION__CONFIRM_CHAIN__BUTTON: 'Confirm group',

    SOURCES_CONFIRMATION__SEND_CODE__SUCCESS: 'Request to receive company confirmation code sent',

    SOURCES_CONFIRMATION__ENTER_CODE__SUCCESS: 'Confirmation code successfully sent to service',

    SOURCES_CONFIRMATION__COMPANY_CREATE_CONFIRM__SUCCESS: 'Request to create and confirm a company successfully sent',
    SOURCES_CONFIRMATION__COMPANY_CREATE_CONFIRM__ERROR: 'Could not send request to create and confirm company',
};
