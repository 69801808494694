import {faCirclePlay} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Text from 'antd/lib/typography/Text';
import {useMemo} from 'react';

import {PostImageType, PostVideoType} from '../../../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../../../util/css';
import {getFormattedVideoDuration} from '../../../../../../../../../../../util/time';

import * as styles from './vkontakte-preview-media.scss';

type PropsType = {
    images?: Array<PostImageType>;
    video?: PostVideoType | null;
    isOnlyVideo?: boolean;
};

export function VkontaktePreviewMedia(props: PropsType): JSX.Element | null {
    const {images, video} = props;

    const renderImages: Array<PostVideoType | PostImageType> = useMemo(() => {
        const resultContent = images || [];

        if (video && images) {
            return [video, ...resultContent];
        }

        return resultContent;
    }, [images, video]);

    if (renderImages.length === 0) {
        return null;
    }

    const isAllItems = renderImages.length === 5;

    const containerClasses = classNames(styles.VkontaktePreviewMedia, {
        [styles.VkontaktePreviewMedia__two]: renderImages.length === 2,
        [styles.VkontaktePreviewMedia__three]: renderImages.length === 3,
        [styles.VkontaktePreviewMedia__four]: renderImages.length === 4,
        [styles.VkontaktePreviewMedia__five]: renderImages.length === 5,
    });

    function getMainImageClassName(isMain: boolean, isAll: boolean, isSecondary: boolean): string {
        return classNames(styles.VkontaktePreviewMedia_image, {
            [styles.VkontaktePreviewMedia_image_main__three]: renderImages.length === 3 && isMain,
            [styles.VkontaktePreviewMedia_image_five__primary]: isAll && !isSecondary,
            [styles.VkontaktePreviewMedia_image_main__three]: isAll && isSecondary,
        });
    }

    return (
        <div className={containerClasses}>
            {renderImages.map((image, index) => {
                if ('previewImageUrl' in image) {
                    return (
                        <div
                            className={classNames(
                                styles.VkontaktePreviewMedia_videoContainer,
                                getMainImageClassName(true, isAllItems, index > 1)
                            )}
                            key={image.previewImageUrl}
                        >
                            <img
                                alt=""
                                className={styles.VkontaktePreviewMedia_image}
                                key={image.url}
                                src={image.previewImageUrl}
                            />
                            <FontAwesomeIcon className={styles.VkontaktePreviewMedia_playIcon} icon={faCirclePlay} />
                            <Text className={styles.VkontaktePreviewMedia_duration}>
                                {getFormattedVideoDuration(image.duration * 1000)}
                            </Text>
                        </div>
                    );
                }

                return (
                    <img
                        alt=""
                        className={getMainImageClassName(!video ? image.isMain : false, isAllItems, index > 1)}
                        key={image.url}
                        src={image.url}
                    />
                );
            })}
        </div>
    );
}
