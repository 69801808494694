import {Col, Row} from 'antd';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {CompanyRating} from '../../../layout/company-rating/company-rating';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {useLicenses} from '../../../provider/license/license-hook';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {PermissionNameEnum} from '../../../service/company/company-type';
import {FeaturesEnum} from '../../../service/user/user-type';
import {ReviewsSummary} from '../company-activity-and-summary/widgets/reviews-summary/reviews-summary';

import {AnalyticsWorkLoadPageCard} from './analytics-workload/analytics-workload-page';
import {AutoRepliesSummary} from './auto-replies-summary/auto-replies-summary';
import {CompanyInfoCompleteness} from './company-info-completeness/company-info-completeness';
import {DashboardRecommendations} from './dashboard-recomendations/dashboard-recommendations';
import {DashboardWidgetEnum} from './dashboard-type';
import {DashboardWidgetHeader} from './dashboard-widget-header/dashboard-widget-header';
import {FeatureDisabled} from './feature-disabled/feature-disabled';
import {OnlinePresenceSummary} from './online-presence-summary/online-presence-summary';
import {PhotosList} from './photos/photos';
import {DashboardPosts} from './posts/dashboard-posts';
import {SingleCompanyPrices} from './prices/single-company-prices';
import {DashboardReviews} from './reviews/reviews';
import {ReviewsRatingDistribution} from './reviews-rating-distribution/reviews-rating-distribution';
import {SearchPhrases} from './search-phrases/search-phrases';
import {SyncStatisticsSingleCompany} from './sync-statistics/sync-statistics-single-company';
import {TimelineActivitySingleCompany} from './timeline-activity/timeline-activity-single-company';
import {YandexStories} from './yandex-stories/yandex-stories';
import * as styles from './dashboard.scss';

type DashboardPropsType = {
    companyId: number;
    permissions: Array<PermissionNameEnum>;
};

export function DashboardSingleCompany(props: DashboardPropsType): JSX.Element {
    const {companyId, permissions} = props;
    const {getLocalizedString} = useLocale();
    const {licenses} = useLicenses();

    return (
        <>
            <PageCard>
                <Meta title={getLocalizedString('DASHBOARD_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.dashboard.path,
                            titleLangKey: 'DASHBOARD_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <PageHeaderTitle
                        productName={UsetifulNameProductEnum.DASHBOARD}
                        title="DASHBOARD_PAGE__SUB_TITLE"
                    />
                </PageHeader>
                <MainPageCardDivider />
                <CompanyInfoCompleteness companyId={companyId} permissions={permissions} />
                <YandexStories />
            </PageCard>
            <Row gutter={[24, 24]}>
                <Col className={styles.page_cards} xl={12} xs={24}>
                    <SyncStatisticsSingleCompany companyId={companyId} />
                    <PageCard
                        title={
                            <DashboardWidgetHeader
                                title={
                                    <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__COMPANY_RATING_HEADER" />
                                }
                                widget={DashboardWidgetEnum.Rating}
                            />
                        }
                    >
                        <CompanyRating companyId={companyId} />
                    </PageCard>
                    <DashboardReviews />
                    <ReviewsRatingDistribution />
                    <PageCard
                        title={
                            <DashboardWidgetHeader
                                title={<Locale stringKey="DASHBOARD_PAGE__REVIEWS_SUMMARY__HEADER" />}
                                widget={DashboardWidgetEnum.ReviewsSummary}
                            />
                        }
                    >
                        <ReviewsSummary />
                    </PageCard>
                    <OnlinePresenceSummary />
                    <SearchPhrases />
                </Col>
                <Col className={styles.page_cards} xl={12} xs={24}>
                    <DashboardRecommendations companiesCount={1} />
                    <SingleCompanyPrices companyId={companyId} isDashboardWidget />
                    {licenses[FeaturesEnum.posts]?.isActive ? (
                        <PageCard
                            title={
                                <DashboardWidgetHeader
                                    title={<Locale stringKey="DASHBOARD_PAGE__POSTS__TITLE" />}
                                    widget={DashboardWidgetEnum.Posts}
                                />
                            }
                        >
                            <DashboardPosts />
                        </PageCard>
                    ) : (
                        <FeatureDisabled feature={FeaturesEnum.posts} />
                    )}
                    {licenses[FeaturesEnum.photoTool]?.isActive ? (
                        <PhotosList />
                    ) : (
                        <FeatureDisabled feature={FeaturesEnum.photoTool} />
                    )}
                    <AutoRepliesSummary />
                    <AnalyticsWorkLoadPageCard companyId={companyId} isDashboardWidget />
                    <TimelineActivitySingleCompany companyId={companyId} isDashboardWidget />
                </Col>
            </Row>
        </>
    );
}
