import {UploadFile} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {JSX} from 'react';

import {ShortLocaleNameEnum} from '../../../../../../provider/locale/locale-context-type';
import {rootApiUrl} from '../../../../../../service/api/api-const';

import {FileUploadIcon} from './file-upload-icon/file-upload-icon';

export function getSampleFileUrl(locale: ShortLocaleNameEnum): string {
    if (locale === ShortLocaleNameEnum.ru) {
        return 'https://go.rocketdata.io/static/prices/products_sample_ru.xlsx';
    }

    return 'https://go.rocketdata.io/static/prices/products_sample_en.xlsx';
}

export function getOriginalFileUrl(groupId: number): string {
    return `${rootApiUrl}/cp/prices/last_source_file/${groupId}/`;
}

export function getRenderUploadIcon(file: UploadFile<RcFile>): JSX.Element {
    return <FileUploadIcon file={file} />;
}
