import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';

import {PopoverTextWrapper} from '../../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../../provider/locale/locale';

import * as styles from './reviews-report-name-hint.scss';

export function ReviewsReportNameHint(): JSX.Element {
    return (
        <>
            <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__NAME__LABEL__TITLE" />
            <Popover
                content={
                    <PopoverTextWrapper>
                        <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__NAME__HINT__TEXT" />
                    </PopoverTextWrapper>
                }
                placement="right"
                title={<Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__NAME__HINT__TITLE" />}
                trigger="hover"
            >
                <FontAwesomeIcon className={styles.reviews_report_name_hint__icon} icon={faInfoCircle} />
            </Popover>
        </>
    );
}
