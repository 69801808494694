import {faMapPin} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Row} from 'antd';

import {appRoute} from '../../../app-route';
import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {PageHeaderWithLicenseTag} from '../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {LicensePageCard} from '../../../component/license-page-card/license-page-card';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLicenses} from '../../../provider/license/license-hook';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useSystem} from '../../../provider/system/system-hook';
import {useUser} from '../../../provider/user/user-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {useLocalPackFilter} from '../../../service/local-pack/local-pack-hook';
import {useLocalPackSearchServices} from '../../../service/local-pack/rules/local-pack-rule-hook';
import {FeaturesEnum} from '../../../service/user/user-type';
import {generatePathWithQueryParameters} from '../../../util/url';
import {magicMobileMinWidth} from '../photo-tool/pages/photo-tool-list/photo-tool-controls/photo-tool-controls-const';

import {LocalPackFilter} from './local-pack-filter/local-pack-filter';
import {LocalPackResultsTable} from './local-pack-results-table/local-pack-results-table';
import {LocalPackPositionDistribution} from './position-distribution/position-distribution';
import {LocalPackQueryResultsTable} from './query-results-table/query-results-table';
import * as styles from './local-pack.scss';

export function LocalPack(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {data: searchServicesResult, isLoading: isInProgress, error: processError} = useLocalPackSearchServices();
    const {filter, setFilter, filterQueries} = useLocalPackFilter();
    const {user} = useUser();

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.localPack];

    const systemContext = useSystem();
    const {screen} = systemContext;
    const {width} = screen;

    if (processError) {
        return (
            <Page>
                <AlertFallback />
            </Page>
        );
    }

    if (isInProgress || !searchServicesResult) {
        return (
            <Page>
                <Spinner />
            </Page>
        );
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                    ]}
                />

                <PageHeaderWithLicenseTag
                    headerLangKey="LOCAL_PACK_PAGE__SUB_TITLE"
                    licenseActive={license?.isActive}
                    productName={UsetifulNameProductEnum.LOCAL_PACK}
                    trialLicense={license?.isTrial}
                >
                    <div className={styles.LocalPack_headerButtons}>
                        <NavigationLink
                            to={generatePathWithQueryParameters(appRoute.localPackSearchPhrasesStatistics.path, {
                                parametersURL: filterQueries,
                            })}
                        >
                            <Button>
                                <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS" />
                            </Button>
                        </NavigationLink>
                        <NavigationLink
                            to={generatePathWithQueryParameters(appRoute.localPackLeaderBoard.path, {
                                parametersURL: filterQueries,
                            })}
                        >
                            <Button onClick={() => track(AnalyticsTarget.LocalPack.ClickLeaderboard)}>
                                <Locale stringKey="LOCAL_PACK__LEADERBOARD" />
                            </Button>
                        </NavigationLink>
                        {user?.isStaff && (
                            <NavigationLink to={appRoute.localPackRules.path}>
                                <Button type="primary">
                                    <Locale stringKey="LOCAL_PACK__MANAGE_RULES" />
                                </Button>
                            </NavigationLink>
                        )}
                    </div>
                </PageHeaderWithLicenseTag>
                <MainPageCardDivider />
                <Row align="middle" gutter={[16, 16]} justify="space-between" wrap={width <= magicMobileMinWidth}>
                    <Col flex="auto">
                        <LocalPackFilter filter={filter} setFilter={setFilter} />
                    </Col>
                </Row>
            </PageCard>
            <LicensePageCard
                icon={<FontAwesomeIcon icon={faMapPin} />}
                license={license}
                productLandKey="LICENSE_CARD__PRODUCT__LOCAL_PACK"
            />
            <LocalPackPositionDistribution
                filter={filter}
                searchServices={searchServicesResult.filter((service) => service.isActive)}
            />
            <LocalPackResultsTable filter={filter} searchServices={searchServicesResult} />
            <LocalPackQueryResultsTable filter={filter} searchServices={searchServicesResult} />
        </Page>
    );
}
