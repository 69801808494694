import {lazy, Suspense, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {appRoute} from '../../../app-route';
import {Page} from '../../../layout/page/page';
import {Login} from '../../../page/auth/login/login';
import {ReactCatchError} from '../../../page/error/react-catch-error/react-catch-error';
import {BrandFeaturesProvider} from '../../../provider/brand-features/brand-features-context';
import {BrandsContextProvider} from '../../../provider/brands/brands-context';
import {CatalogsContextProvider} from '../../../provider/catalogs/catalogs-context';
import {MainFilterProvider} from '../../../provider/main-filter/main-filter';
import {OnboardingProvider} from '../../../provider/onboarding/onboarding-provider';
import {useUser} from '../../../provider/user/user-hook';
import {saveToLocalStorage} from '../../../util/local-storage';
import {useUrl} from '../../../util/url-hook/url-hook';
import {Analytics} from '../../analytics/analytics';
import {CentrifugeListenersAuthorized} from '../../centrifuge-actions/centrifuge-listeners-authorized';
import {CentrifugeListenersUnauthorized} from '../../centrifuge-actions/centrifuge-listeners-unauthorized';
import {ErrorBoundary} from '../../error-boundary/error-boundary';
import {HelpDeskChatWidget} from '../../help-desk-chat/help-desk-chat-widget';
import {SentryTags} from '../../sentry-tags/sentry-tags';

import {isForceLogoutLocationState} from './helpers/force-logout';
import {useNotAuthorizedNavigation} from './hooks/use-not-authorized-navigation';
import {localStorageLastRoutePathKey} from './routing-const';

const RoutingNoAuthorized = lazy(() => import(/* webpackChunkName: 'no-authorized' */ './routing-no-authorized'));
const RoutingAuthorized = lazy(() => import(/* webpackChunkName: 'authorized' */ './routing-authorized'));

export function Routing(): JSX.Element {
    const {state} = useLocation();
    const {user, isInGettingUser, resetUser} = useUser();
    const {pushUrl, pathname} = useUrl();
    const routes = useNotAuthorizedNavigation();

    const [newVersionDeployed, setNewVersionDeployed] = useState<boolean>(false);

    const onNewVersionDeployed = useCallback(() => {
        setNewVersionDeployed(true);
    }, []);

    const checkRoutes = new Set(
        [...routes.map((element) => element.path), ...routes.map((element) => `${element.path}/`)].filter(
            (element) =>
                element !== '*' &&
                element !== '*/' &&
                element !== appRoute.login.path &&
                element !== `${appRoute.login.path}/`
        )
    );

    useEffect(() => {
        if (isForceLogoutLocationState(state)) {
            resetUser();
        }
    }, [resetUser, state]);

    useEffect(() => {
        if (!user && !isInGettingUser && !checkRoutes.has(pathname)) {
            pushUrl(appRoute.login.path);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isInGettingUser, pushUrl]);

    useEffect(() => {
        if (user) {
            saveToLocalStorage(pathname, localStorageLastRoutePathKey);
        }
    }, [user, pathname]);

    const fallback = <Page.Loading titleLangKey="LOADING_THREE_POINTS" />;

    if (isInGettingUser) {
        return fallback;
    }

    if (user && !isForceLogoutLocationState(state)) {
        return (
            <Suspense fallback={fallback}>
                <SentryTags />
                <CentrifugeListenersAuthorized onDeployActionReceived={onNewVersionDeployed} />
                <ErrorBoundary appHasNewVersion={newVersionDeployed} errorFallBack={<ReactCatchError />}>
                    <OnboardingProvider>
                        <MainFilterProvider>
                            <BrandsContextProvider>
                                <BrandFeaturesProvider>
                                    <CatalogsContextProvider>
                                        <RoutingAuthorized />
                                        <HelpDeskChatWidget
                                            userEmail={user.email}
                                            userFirstName={user.profile.firstName}
                                            userId={user.id}
                                        />
                                    </CatalogsContextProvider>
                                </BrandFeaturesProvider>
                            </BrandsContextProvider>
                        </MainFilterProvider>
                    </OnboardingProvider>
                </ErrorBoundary>
                <Analytics />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={fallback}>
            <CentrifugeListenersUnauthorized onDeployActionReceived={onNewVersionDeployed} />
            <ErrorBoundary appHasNewVersion={newVersionDeployed} errorFallBack={<Login />}>
                <RoutingNoAuthorized />
            </ErrorBoundary>
        </Suspense>
    );
}
