import {Locale} from '../../../../provider/locale/locale';
import {QuestionDataType} from '../../../../service/questions/questions-type';
import {classNames} from '../../../../util/css';

import {QuestionsAndAnswersListItem} from './questions-and-answers-list-item/questions-and-answers-list-item';
import * as styles from './questions-and-answers-list.scss';

type PropsType = {
    className?: string;
    questionList: Array<QuestionDataType>;
    isLoading?: boolean;
    onAnswerAdded: () => void;
};

export function QuestionsAndAnswersList(props: PropsType): JSX.Element {
    const {className, questionList, isLoading = false, onAnswerAdded} = props;

    const fullClassName = classNames(styles.QuestionsAndAnswersList, className);

    if (isLoading) {
        return <div className={fullClassName} />;
    }

    if (questionList.length === 0) {
        return (
            <div className={fullClassName}>
                <p className={styles.QuestionsAndAnswersList_empty}>
                    <Locale stringKey="TEXT__NO_DATA" />
                </p>
            </div>
        );
    }

    return (
        <ul className={fullClassName}>
            {questionList.map((question: QuestionDataType) => {
                return (
                    <li className={styles.QuestionsAndAnswersList_item} key={question.id}>
                        <QuestionsAndAnswersListItem onAnswerAdded={onAnswerAdded} question={question} />
                    </li>
                );
            })}
        </ul>
    );
}
