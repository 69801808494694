import {faListUl} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, Space} from 'antd';
import {ReactElement, useEffect} from 'react';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../../component/page-card-divider/main-page-card-divider';
import {Text} from '../../../../../component/text/text';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {CompanyMultiSelectV2} from '../../../../../layout/company-multi-select-v2/company-multi-select-v2';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useMessage} from '../../../../../provider/message/message-hook';
import {SendExportFormType, useSendExportMutation} from '../../../../../service/companies-export/companies-export';
import {CompaniesSelectorFeatureEnum} from '../../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../../service/feature-companies/feature-companies-hook';

import {DataType} from './data-type/data-type';
import {EmailsForExport} from './emails-for-export/emails-for-export';
import * as styles from './feed-export-page.scss';

export function CompaniesExport(): ReactElement {
    const [form] = Form.useForm<SendExportFormType>();

    const {mutate} = useSendExportMutation();

    const companiesSelector = useCompaniesSelector({
        feature: CompaniesSelectorFeatureEnum.CompaniesExport,
        resourceId: null,
    });

    const {getLocalizedString} = useLocale();
    const {message} = useMessage();

    const {initializeSelector, selectedCount, selectorId, selectFiltered} = companiesSelector;

    function errorMessage() {
        message.error(<Locale stringKey="FEED__EXPORT__ERROR_MESSAGE__DESCRIPTION" />);
    }

    function onFinish(values: SendExportFormType) {
        if (selectedCount === 0) {
            errorMessage();
        } else {
            mutate({emailsForExport: values.emailsForExport, companiesSelectorUid: selectorId});
        }
    }

    useEffect(() => {
        initializeSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectorId) {
            selectFiltered();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorId]);

    return (
        <Page>
            <Meta title={getLocalizedString('FEED__ROUTES_MODAL__EXPORT__LABEL')} />
            <BreadCrumbs
                list={[
                    {path: appRoute.myCompanies.path, titleLangKey: 'CATEGORY_NAME__MY_COMPANIES'},
                    {path: appRoute.companyExport.path, titleLangKey: 'FEED__ROUTES_MODAL__EXPORT__LABEL'},
                ]}
            />

            <PageHeader>
                <Locale stringKey="FEED__ROUTES_MODAL__EXPORT__LABEL" />
                <NavigationLink to={appRoute.companyFeedManagement.path}>
                    <Button icon={<FontAwesomeIcon icon={faListUl} size="sm" />}>
                        <span>
                            <Locale stringKey="FEED__IMPORT__CONNECTED_FEEDS__BUTTON" />
                        </span>
                    </Button>
                </NavigationLink>
            </PageHeader>

            <MainPageCardDivider />
            <div className={styles.FeedExportPage_content}>
                <Form className={styles.FeedExportPage_left} form={form} layout="vertical" onFinish={onFinish}>
                    <Space className={styles.FeedExportPage_space} direction="vertical" size="large">
                        <div>
                            <Text lighter stringKey="FEED__EXPORT__SELECTOR__TITLE" />
                            <CompanyMultiSelectV2
                                className={styles.FeedExportPage_companySelector}
                                companiesSelector={companiesSelector}
                                isDisabled={false}
                                selectedCountLangKey="COMPANY__MULTISELECT__SELECTED_COMPANY_COUNT"
                            />
                        </div>
                        <EmailsForExport />
                        <Button className={styles.FeedExportPage_submitButton} htmlType="submit" type="primary">
                            <Locale stringKey="FEED__EXPORT__SUBMIT_BUTTON" valueMap={{count: selectedCount}} />
                        </Button>
                    </Space>
                </Form>
                <aside className={styles.FeedExportPage_right}>
                    <DataType />
                </aside>
            </div>
        </Page>
    );
}
