import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {PropsWithChildren} from 'react';

import {Locale} from '../../provider/locale/locale';

import * as styles from './request-report-base.scss';

type PropsType = {
    onChangeModal: (isOpen: boolean) => void;
    disabled?: boolean;
    loading?: boolean;
};

export function RequestReportBase(props: PropsWithChildren<PropsType>): JSX.Element {
    const {children, onChangeModal, disabled, loading} = props;

    return (
        <>
            <Button
                disabled={disabled}
                icon={<FontAwesomeIcon className={styles.RequestReportBase_icon} icon={faDownload} />}
                loading={loading}
                onClick={() => onChangeModal(true)}
            >
                <Locale stringKey="REVIEWS_ANALYSIS__DOWNLOAD_REPORT" />
            </Button>

            {children}
        </>
    );
}
