export enum MainFilterQueryKeyNameEnum {
    filterId = 'filter-id',
}

export enum TwoGisQueryKeyNameEnum {
    JwtToken = 'jwt_token',
    Lang = 'lang',
}

export enum CatalogTypeSearchParameterEnum {
    catalogType = 'catalog-type',
}

// FIXME: this is legacy from old main filter. It would be nice to get rid of it and make routes to handle their own parameter by themselves
export const urlQueryMap = {
    brandId: 'brand-id',
    companyId: 'company-id',
};

export const sharedSearchParameters: Array<string> = [
    ...Object.values(MainFilterQueryKeyNameEnum),
    ...Object.values(TwoGisQueryKeyNameEnum),
    ...Object.values(CatalogTypeSearchParameterEnum),
    ...Object.values(urlQueryMap),
];
