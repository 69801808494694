import {faUserTie} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {ResponsibleUserType} from '../../../../../service/reviews/reviews-responsible-users';
import {ReviewLogUserDataType} from '../../../../../service/reviews/reviews-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './responsible-user-log.scss';

type PropsType = {
    loggedAt: string;
    value: ResponsibleUserType;
    user: ReviewLogUserDataType;
};

export function ResponsibleUserLog(props: PropsType): JSX.Element | null {
    const {loggedAt, value, user} = props;
    const {companyName} = useDomainConfig();

    const userName = (() => {
        if (user) {
            const {firstName, lastName, fullName, email} = user;

            return fullName || (firstName && lastName ? `${firstName} ${lastName}` : email);
        }

        return companyName.replace('presence.2gis.com', '2GIS');
    })();

    const newUserName = value ? (
        value.fullName || (value.firstName && value.lastName ? `${value.firstName} ${value.lastName}` : value.email)
    ) : (
        <Locale stringKey="REVIEWS__RESPONSIBLE_USER__NOT_SELECTED" />
    );

    const {getFormattedDateTime} = useFormat();
    const formattedTime = loggedAt
        ? getFormattedDateTime(new Date(loggedAt), {
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;
    const formattedDate = loggedAt
        ? getFormattedDateTime(new Date(loggedAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          })
        : null;

    return (
        <div className={styles.ResponsibleUserLog}>
            <span className={styles.ResponsibleUserLog_title}>
                <FontAwesomeIcon className={styles.ResponsibleUserLog_icon} icon={faUserTie} />
                <span>
                    <span className={styles.ResponsibleUserLog_userName}>{userName}</span>{' '}
                    <Locale stringKey="REVIEWS__REVIEW_LOG__NEW_RESPONSIBLE_USER" />{' '}
                    <span className={styles.ResponsibleUserLog_userName}>{newUserName}</span>
                </span>
            </span>
            <span className={styles.ResponsibleUserLog_date}>
                {formattedTime} {formattedDate}
            </span>
        </div>
    );
}
