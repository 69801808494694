import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../../../../../../component/text/text';
import {GoogleCompanyType, GoogleLocationType} from '../../../../../../../service/google/google-type';
import * as styles from '../company-list-item.scss';

type PropsType = {
    company: GoogleCompanyType | GoogleLocationType;
    showContacts?: boolean;
};

// eslint-disable-next-line complexity
export function CompanyListItemContacts(props: PropsType): JSX.Element {
    const {company, showContacts = true} = props;

    const mapsUrl = 'mapsUrl' in company && company.mapsUrl && (
        <a className={styles.Company_mapsUrl} href={company.mapsUrl} rel="noreferrer" target="_blank">
            <FontAwesomeIcon icon={faExternalLink} />
        </a>
    );

    if (!showContacts) {
        return (
            <div className={styles.Company_mapsUrlWrapper}>
                <Text block lighter>
                    {company.address}
                </Text>

                {mapsUrl}
            </div>
        );
    }

    return (
        <>
            <Text block lighter>
                {company.address}
            </Text>

            <div className={styles.Company_mapsUrlWrapper}>
                <div>
                    {'website' in company && company.website && (
                        <Text className={styles.Company_websites} secondary small>
                            {company.website}
                        </Text>
                    )}

                    {'websites' in company && company.websites && company.websites.length > 0 && (
                        <Text className={styles.Company_websites} secondary small>
                            {company.websites.join(', ')}
                        </Text>
                    )}

                    {company.phones && company.phones.length > 0 && (
                        <Text secondary small>
                            {company.phones.join(', ')}
                        </Text>
                    )}
                </div>

                {mapsUrl}
            </div>
        </>
    );
}
