import {ExportExcelOptionsType, OptionsEnum} from '../../../../../service/online-presence/online-presence-type';

export function getExportAnalyticsInitialState(): ExportExcelOptionsType {
    return {
        [OptionsEnum.google]: false,
        [OptionsEnum.yandex]: false,
        [OptionsEnum.doubleGis]: false,
        [OptionsEnum.facebook]: false,
        [OptionsEnum.listing]: false,
        [OptionsEnum.searchPhrases]: false,
        [OptionsEnum.syncStatistics]: false,
    };
}
