import {ReactNode} from 'react';

import {GoogleAccountTypeEnum} from '../../../../../../service/google/accounts/google-accounts-type';

export type AccountFilterType = {
    value: AccountFilterEnum;
    label: ReactNode;
    accountType?: GoogleAccountTypeEnum;
};

export enum AccountFilterEnum {
    All,
    Group,
    Personal,
}
