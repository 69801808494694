import {z as r} from 'zod';

import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {PaginatedResponseType} from '../../api/api-type';
import {UseHookType} from '../../api-hook/api-hook-type';
import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const reviewsAnalysisCompanySchema = r.object({
    id: r.number(),
    brandName: r.string(),
    companyName: r.string(),
    address: r.string(),
    total: r.number(),
    companyCode: r.string().nullable(),
    avgRating: r.number(),
    replied: r.number(),
    repliedPercent: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
    companyGroups: r.array(r.string()),
    trendByThreeMonths: r.array(
        r.object({
            date: r.string(),
            value: r.number(),
        })
    ),
});

export type ReviewsAnalysisCompanyType = r.infer<typeof reviewsAnalysisCompanySchema>;

export const reviewsAnalysisCompanyResponseSchema = r.object({
    count: r.number(),
    page: r.number(),
    pages: r.number(),
    next: r.number().nullable().optional(),
    previous: r.number().nullable().optional(),
    pageSize: r.number(),
    results: r.array(reviewsAnalysisCompanySchema),
});
export const enum ReviewAnalysisCompaniesSortValueEnum {
    Asc = 'asc',
    Desc = 'desc',
}
export type ReviewAnalysisCompaniesSortKeyType = keyof Pick<ReviewsAnalysisCompanyType, 'total' | 'avgRating'>;
export type ReviewAnalysisCompaniesSortType =
    `${ReviewAnalysisCompaniesSortKeyType}.${ReviewAnalysisCompaniesSortValueEnum}`;

export type ReviewsAnalysisCompaniesQueryType = {
    sort: ReviewAnalysisCompaniesSortType | null;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};

export type CompanyResponseType<PayloadType> = PaginatedResponseType<PayloadType> & {pageSize: number};

export type ReviewAnalysisCompaniesHookType = UseHookType<CompanyResponseType<ReviewsAnalysisCompanyType>> & {
    fetch: (options: ReviewsAnalysisCompaniesQueryType & PaginationDataType, mainFilterKey: string) => void;
};
