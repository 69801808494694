import {faArrowsMaximize, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faImage, faUpload} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Image} from 'antd';
import Text from 'antd/lib/typography/Text';
import {useState} from 'react';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {classNames} from '../../../../../../../../util/css';

import * as styles from './input-image-content.scss';

type PropsType = {
    className?: string;
    drawImageUrl?: string | null;
    noImageText: JSX.Element;
    setIsModalOpen: (value: boolean) => void;
    resetImage: () => void;
};

export function InputImageContent(props: PropsType): JSX.Element {
    const {className, drawImageUrl, noImageText, setIsModalOpen, resetImage} = props;

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    return (
        <>
            {!drawImageUrl && (
                <div className={classNames(styles.input_image_upload, className)}>
                    <FontAwesomeIcon className={styles.image_placeholder} icon={faImage} />

                    <Text className={styles.no_image_text}>{noImageText}</Text>

                    <Button
                        className={styles.upload_button}
                        icon={<FontAwesomeIcon icon={faUpload} />}
                        onClick={() => setIsModalOpen(true)}
                    >
                        <Text>
                            <Locale stringKey="BUTTON__UPLOAD" />
                        </Text>
                    </Button>
                </div>
            )}

            {drawImageUrl && (
                <div className={classNames(styles.input_image, className)}>
                    <Image
                        className={styles.rendered_image}
                        preview={{
                            visible: isPreviewOpen,
                            onVisibleChange: (value) => setIsPreviewOpen(value),
                        }}
                        src={drawImageUrl}
                    />

                    <div className={styles.rendered_image_controls}>
                        <Button
                            className={styles.rendered_image_controls__button}
                            icon={<FontAwesomeIcon icon={faArrowsMaximize} />}
                            onClick={() => setIsPreviewOpen(true)}
                            type="text"
                        />
                        <Button
                            className={styles.rendered_image_controls__button}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={resetImage}
                            type="text"
                        />
                    </div>
                </div>
            )}
        </>
    );
}
