import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useReviewsStatistic} from '../../../../../service/reviews/reviews-statistic';
import {ReviewRatingTypeEnum} from '../../../../../service/reviews/reviews-type';
import {noop} from '../../../../../util/function';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from '../../reviews-state/reviews-state-type';
import {ReviewsFilterDataType} from '../reviews-filter/reviews-filter-type';

import {BigRadioButton} from './big-radio-button/big-radio-button';
import {BigRadioButtonNameEnum} from './big-radio-button/big-radio-button-type';

type PropsType = {
    reviewsState: ReviewsStateType;
};

export function ReviewsStatistic(props: PropsType): JSX.Element {
    const {reviewsState} = props;
    const ratingType = reviewsState[ReviewsStateUrlQueryNameEnum.ratingType];

    const {setQuery: setReviewsStateUrlQuery} = useUrl<Omit<ReviewsStateType, 'filterState'> & ReviewsFilterDataType>();

    const {data: reviewsStatisticResult, isFetching: reviewsStatisticIsInProgress} = useReviewsStatistic(reviewsState);

    function handleRatingTypeRadioClick(type: ReviewRatingTypeEnum) {
        setReviewsStateUrlQuery({
            [ReviewsStateUrlQueryNameEnum.ratingType]: type,
        });

        const ReviewRatingAnalyticsType = {
            [ReviewRatingTypeEnum.allRating]: 'Total',
            [ReviewRatingTypeEnum.positive]: 'Positive',
            [ReviewRatingTypeEnum.negative]: 'Negative',
            [ReviewRatingTypeEnum.notRated]: 'Not rated',
        };

        track(AnalyticsTarget.ReviewsManagement.Reviews.ClickReviewsType, ReviewRatingAnalyticsType[type]);
    }

    return (
        <>
            <BigRadioButton
                isActive={ratingType === ReviewRatingTypeEnum.allRating}
                isLoading={reviewsStatisticIsInProgress}
                onClick={() => handleRatingTypeRadioClick(ReviewRatingTypeEnum.allRating)}
                type={ReviewRatingTypeEnum.allRating}
                value={reviewsStatisticResult?.count || 0}
            />

            <BigRadioButton
                isActive={ratingType === ReviewRatingTypeEnum.positive}
                isLoading={reviewsStatisticIsInProgress}
                onClick={() => handleRatingTypeRadioClick(ReviewRatingTypeEnum.positive)}
                type={ReviewRatingTypeEnum.positive}
                value={reviewsStatisticResult?.positive || 0}
            />

            <BigRadioButton
                isActive={ratingType === ReviewRatingTypeEnum.negative}
                isLoading={reviewsStatisticIsInProgress}
                onClick={() => handleRatingTypeRadioClick(ReviewRatingTypeEnum.negative)}
                type={ReviewRatingTypeEnum.negative}
                value={reviewsStatisticResult?.negative || 0}
            />

            <BigRadioButton
                isActive={ratingType === ReviewRatingTypeEnum.notRated}
                isLoading={reviewsStatisticIsInProgress}
                onClick={() => handleRatingTypeRadioClick(ReviewRatingTypeEnum.notRated)}
                type={ReviewRatingTypeEnum.notRated}
                value={reviewsStatisticResult?.neutral || 0}
            />

            <BigRadioButton
                isActive={false}
                isLoading={reviewsStatisticIsInProgress}
                onClick={noop}
                type={BigRadioButtonNameEnum.averageRating}
                value={reviewsStatisticResult?.rating_avg || 0}
            />
        </>
    );
}
