import {faBuilding} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableColumnType} from 'antd';

import {Text} from '../../../../component/text/text';
import {CatalogCell} from '../../../../layout/table/catalog-cell/catalog-cell';
import {Locale} from '../../../../provider/locale/locale';
import {CompanyPricesType} from '../../../../service/company-activity-and-summary/company-activity-and-summary-type';
import {DashboardManyCompaniesGoodsStatsType} from '../../../../service/dashboard/dashboard-type';

import * as styles from './prices.scss';

export const SINGLE_COMPANY_PRICES_COLUMNS: Array<TableColumnType<CompanyPricesType>> = [
    {
        title: <Locale stringKey="DASHBOARD_PAGE__GOODS__CATALOG__COLUMN" />,
        render: (_value: unknown, record: CompanyPricesType) => (
            <CatalogCell catalogId={record.catalog.id} className={styles.PricesTable_catalog} />
        ),
    },
    {
        align: 'right',
        title: <Locale stringKey="DASHBOARD_PAGE__GOODS__PRICES_COUNT_COLUMN" />,
        render: (_value: unknown, record: CompanyPricesType) => record.product_count,
    },
];

export const MANY_COMPANIES_PRICES_COLUMNS: Array<TableColumnType<DashboardManyCompaniesGoodsStatsType>> = [
    {
        title: <Locale stringKey="DASHBOARD_PAGE__GOODS__CATALOG__COLUMN" />,
        render: (_value: unknown, record: DashboardManyCompaniesGoodsStatsType) => (
            <CatalogCell catalogId={record.catalog.id} className={styles.PricesTable_catalog} />
        ),
    },
    {
        align: 'right',
        title: <Locale stringKey="DASHBOARD_PAGE__GOODS__COMPANIES_COUNT_COLUMN" />,
        render: (_value: unknown, record: DashboardManyCompaniesGoodsStatsType) => (
            <div className={styles.PricesTable_manyCompaniesCount}>
                <Text block>{record.count}</Text>
                <FontAwesomeIcon icon={faBuilding} />
            </div>
        ),
    },
];
