import {Table} from 'antd';

import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useSystem} from '../../../../../provider/system/system-hook';
import {useLocalPackSearchResults} from '../../../../../service/local-pack/local-pack-hook';
import {LocalPackGridItemType} from '../../../../../service/local-pack/local-pack-type';
import {classNames} from '../../../../../util/css';

import {positionsTableColumnsList} from './positions-table-const';
import {getPositionsTableData} from './positions-table-helper';
import {PositionsTableDataType} from './positions-table-types';
import * as styles from './positions-table.scss';

type PositionsTablePropsType = {
    searchQueryId: number;
    currentPoint: LocalPackGridItemType;
};

export function LocalPackPositionsTable(props: PositionsTablePropsType): JSX.Element {
    const {searchQueryId, currentPoint} = props;
    const {result, isInProgress, processError} = useLocalPackSearchResults(searchQueryId, currentPoint.id);
    const {screen} = useSystem();
    const tableHeight = screen.height * 0.6;

    if (isInProgress) {
        return <Spinner />;
    }

    if (processError || !result) {
        return <AlertFallback />;
    }

    const firstElement =
        (currentPoint.position !== 1 &&
            result.every((searchResultItem) => !searchResultItem.isAd) &&
            result.find((searchResultItem) => searchResultItem.position === currentPoint.position)) ||
        null;

    const tableData: Array<PositionsTableDataType> = getPositionsTableData(result, firstElement);

    return (
        <div>
            <Table<PositionsTableDataType>
                className={classNames(styles.PositionTables, {
                    [styles.PositionTables__extended]: Boolean(firstElement),
                })}
                columns={positionsTableColumnsList}
                dataSource={tableData}
                loading={isInProgress}
                pagination={false}
                rowClassName={(record, index) =>
                    record.position === currentPoint.position && index !== 0 ? styles.PositionTables_row : ''
                }
                scroll={{y: tableHeight}}
                size="middle"
            />
        </div>
    );
}
