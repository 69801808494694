import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {faLink, faUnlink} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {Text} from '../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    GoogleCclStatusEnum,
    GoogleCompanyWithCandidatesType,
    GoogleLocationSourceEnum,
} from '../../../../../../../../service/google/google-type';
import {classNames} from '../../../../../../../../util/css';
import {NEW_LOCATION_OPTION} from '../../processing-step-const';
import {ManualBindingType} from '../../processing-step-type';

import {ACTIONABLE_STATUSES} from './company-list-item-footer-const';
import * as styles from './company-list-item-footer.scss';

type PropsType = {
    company: GoogleCompanyWithCandidatesType;
    manualBinding: ManualBindingType;
    editBinding: (companyId: number) => void;
};

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function CompanyListItemFooter(props: PropsType): JSX.Element {
    const {company, manualBinding, editBinding} = props;

    const canAddBinding = company.candidates || company.status === GoogleCclStatusEnum.ActionRequired;

    if (!manualBinding.has(company.id) && canAddBinding) {
        return (
            <footer className={classNames(styles.CompanyFooter, styles.CompanyFooter__notLinked)}>
                <div className={styles.CompanyFooter_action}>
                    <Text
                        small
                        stringKey={
                            company.candidates?.source === GoogleLocationSourceEnum.Account
                                ? 'GOOGLE_SYNC__PROCESSING_STEP__CHOOSE_FROM_ACCOUNT'
                                : 'GOOGLE_SYNC__PROCESSING_STEP__CHOOSE_FROM_MAPS'
                        }
                    />
                </div>

                <Button className={styles.CompanyFooter_selectButton} onClick={() => editBinding(company.id)}>
                    <Locale stringKey="BUTTON__SELECT" />
                </Button>
            </footer>
        );
    }

    const binding = manualBinding.get(company.id);
    const isActionable = binding?.status && ACTIONABLE_STATUSES.includes(binding.status);
    const location = company.candidates?.data.find(({originId}) => originId === binding?.locationId);

    return (
        <footer
            className={classNames(styles.CompanyFooter, styles.CompanyFooter__linked, {
                [styles.CompanyFooter__actionable]: isActionable,
            })}
        >
            {binding?.status === GoogleCclStatusEnum.Skipped ? (
                <>
                    <FontAwesomeIcon className={styles.CompanyFooter_linkIcon} icon={faUnlink} />

                    <Text
                        className={styles.CompanyFooter_description}
                        secondary
                        small
                        stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SKIPPED__DESCRIPTION"
                    />
                </>
            ) : (
                <>
                    <FontAwesomeIcon className={styles.CompanyFooter_linkIcon} icon={faLink} />

                    {binding?.locationId === NEW_LOCATION_OPTION ? (
                        <Text
                            className={styles.CompanyFooter_description}
                            small
                            stringKey="GOOGLE_SYNC__PROCESSING_STEP__CREATE_NEW"
                        />
                    ) : (
                        <div className={styles.CompanyFooter_description}>
                            <Text className={styles.CompanyFooter_name} small>
                                {isActionable ? location?.name : company.name}
                            </Text>

                            <Text lighter small>
                                {isActionable ? location?.address : company.locationAddress || company.address}
                            </Text>
                        </div>
                    )}
                </>
            )}

            {isActionable && (
                <Button
                    className={styles.CompanyFooter_edit}
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={() => editBinding(company.id)}
                    type="text"
                />
            )}
        </footer>
    );
}
