import {z as r} from 'zod';

import {ReviewsAnalysisModeEnum} from '../reivew-analysis-const';
import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const listResponseProperties = r.object({
    count: r.number(),
    page: r.number(),
    pages: r.number(),
    next: r.string().nullable().optional(),
    previous: r.string().nullable().optional(),
    pageSize: r.number(),
});

const reviewsAnalysisTagSchema = r.object({
    tag: r.string(),
    avgRating: r.number(),
    total: r.number(),
    replied: r.number(),
    repliedPercent: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
    trendByThreeMonths: r.array(
        r.object({
            date: r.string(),
            value: r.number(),
        })
    ),
});

export type ReviewsAnalysisTagType = r.infer<typeof reviewsAnalysisTagSchema>;

export const reviewsAnalysisTagResponseSchema = listResponseProperties.merge(
    r.object({
        results: r.array(reviewsAnalysisTagSchema),
    })
);
export const reviewsAnalysisTagSearchSchema = r.array(r.string());

const reviewsAnalysisTagBySentimentSchema = r.object({
    tag: r.string(),
    total: r.number(),
    sentiment: r.nativeEnum(SentimentEnum),
});

export type ReviewsAnalysisTagBySentimentType = r.infer<typeof reviewsAnalysisTagBySentimentSchema>;

export const reviewsAnalysisTagBySentimentResponseSchema = listResponseProperties.merge(
    r.object({
        results: r.array(reviewsAnalysisTagBySentimentSchema),
    })
);

export type ReviewsAnalysisTagsQueryType = {
    mode?: ReviewsAnalysisModeEnum;
    brandIds?: Array<number>;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    userTopics?: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
    ableToReply: boolean | null;
    maxTagsCount?: number;
};
