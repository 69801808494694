import {faEllipsisVertical} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../../../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../../../../provider/locale/locale-hook';
import {PostPreviewDetailsType} from '../../../../../../../../../../../service/posts/posts-types';
import {TimeSizeEnum} from '../../../../../../../../../../../util/format';
import {useFormat} from '../../../../../../../../../../../util/format-hook/format-hook';
import {CompanyAvatar} from '../../../company-avatar/company-avatar';

import {ReactComponent as VerifiedIcon} from './images/shield-check.svg';
import * as styles from './google-preview-header.scss';

type PropsType = {
    companyInfo?: PostPreviewDetailsType;
};

export function GooglePreviewHeader(props: PropsType): JSX.Element {
    const {companyInfo} = props;

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    const date = getFormattedDateTime(Date.now(), {
        [TimeSizeEnum.month]: 'short',
        day: '2-digit',
        year: 'numeric',
    });

    return (
        <div className={styles.GooglePreviewHeader}>
            <div className={styles.GooglePreviewHeader_authorContainer}>
                <div className={styles.GooglePreviewHeader_avatarContainer}>
                    <CompanyAvatar logo={companyInfo?.logo} />
                    <VerifiedIcon className={styles.GooglePreviewHeader_verifiedIcon} />
                </div>
                <div className={styles.GooglePreviewHeader_text}>
                    <Text className={styles.GooglePreviewHeader_name}>
                        {companyInfo?.name || getLocalizedString('POSTS_FORM__PREVIEW__YANDEX__COMPANY_NAME')}
                    </Text>
                    <Text className={styles.GooglePreviewHeader_date}>{date}</Text>
                </div>
            </div>
            <FontAwesomeIcon className={styles.GooglePreviewHeader_action} icon={faEllipsisVertical} />
        </div>
    );
}
