import {LangKeyType} from '../../../../../../../provider/locale/translation/type';
import {AbuseReasonStatusEnum} from '../../../../../../../service/reviews/reviews-abuse-hook';

export enum ReplyTypeEnum {
    ManualReply = 'Manual reply',
    AutoReply = 'Auto reply',
    TemplateReply = 'Template reply',
}

export const reviewAbuseReasonTitleToLocaleMap: Record<AbuseReasonStatusEnum, LangKeyType> = {
    [AbuseReasonStatusEnum.AutoSent]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT__TITLE',
    [AbuseReasonStatusEnum.WillBeCheckedByAi]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__CHECKED__TITLE',
    [AbuseReasonStatusEnum.Sent]: 'REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__HEADER',
    [AbuseReasonStatusEnum.Accepted]: 'REVIEW__ABUSE_STATUS__ACCEPTED',
    [AbuseReasonStatusEnum.Declined]: 'REVIEW__ABUSE_STATUS__DECLINED',
    [AbuseReasonStatusEnum.Default]: 'REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__HEADER',
};

export const reviewAbuseReasonDescriptionToLocaleMap: Record<AbuseReasonStatusEnum, LangKeyType> = {
    [AbuseReasonStatusEnum.AutoSent]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT__DESCRIPTION',
    [AbuseReasonStatusEnum.WillBeCheckedByAi]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__CHECKED__DESCRIPTION',
    [AbuseReasonStatusEnum.Sent]: 'REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__TEXT',
    [AbuseReasonStatusEnum.Accepted]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__ACCEPTED',
    [AbuseReasonStatusEnum.Declined]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__DECLINED',
    [AbuseReasonStatusEnum.Default]: 'REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__TEXT',
};

export const reviewAbuseReasonToLocaleMap: Record<AbuseReasonStatusEnum, LangKeyType> = {
    [AbuseReasonStatusEnum.AutoSent]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT',
    [AbuseReasonStatusEnum.WillBeCheckedByAi]: 'REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT',
    [AbuseReasonStatusEnum.Sent]: 'REVIEW__ABUSE_STATUS__SENT',
    [AbuseReasonStatusEnum.Accepted]: 'REVIEW__ABUSE_STATUS__ACCEPTED',
    [AbuseReasonStatusEnum.Declined]: 'REVIEW__ABUSE_STATUS__DECLINED',
    [AbuseReasonStatusEnum.Default]: 'REVIEW__ABUSE_STATUS__SENT',
};
