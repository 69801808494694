import {Table} from 'antd';
import {Key, useCallback, useEffect, useMemo, useState} from 'react';

import {renderCellActions} from '../../../../layout/actions-cell/actions-cell';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {
    fetchTemplateGroupCreate,
    fetchTemplateGroupUpdate,
    ResponseTemplateGroupType,
} from '../../../../service/reviews/reviews-response-templates';

import {responseTemplateColumnList} from './response-templates-edit-group-const';
import {ResponseTemplatesEditTableRowDataType} from './response-templates-edit-group-type';
import {renderGroupName} from './response-templates-table-helper';

type PropsType = {
    setResponseTemplateGroupIdList: (selectedIdList: Array<string>) => void;
    responseTemplateGroupListResult: Array<ResponseTemplateGroupType>;
    responseTemplateGroupListIsInProgress: boolean;
    onDelete: (data: ResponseTemplateGroupType) => void;
    newGroup: Array<ResponseTemplateGroupType>;
    refreshGroupList: () => void;
    setNewGroup: (group: Array<ResponseTemplateGroupType>) => void;
};

export function ResponseTemplatesEditGroupTable(props: PropsType): JSX.Element {
    const {
        setResponseTemplateGroupIdList,
        responseTemplateGroupListResult,
        responseTemplateGroupListIsInProgress,
        onDelete,
        newGroup,
        refreshGroupList,
        setNewGroup,
    } = props;

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const [editableId, setEditableId] = useState<number>(-1);

    function handleRowSelection(
        _selectedRowKeys: Array<Key>,
        rowDataList: Array<ResponseTemplatesEditTableRowDataType>
    ) {
        setResponseTemplateGroupIdList(
            rowDataList.map((rowData: ResponseTemplatesEditTableRowDataType): string => rowData.key)
        );
    }

    const groupDataList: Array<ResponseTemplateGroupType> = useMemo((): Array<ResponseTemplateGroupType> => {
        const realGroupDataList = responseTemplateGroupListResult || [];

        return [...newGroup, ...realGroupDataList];
    }, [responseTemplateGroupListResult, newGroup]);

    const updateGroup = useCallback(
        async (data: ResponseTemplateGroupType, id: number) => {
            const result = await fetchTemplateGroupUpdate(data, id);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__GROUP__UPDATING_ERROR" />);
            } else {
                setEditableId(-1);
                snackbar.success(<Locale stringKey="TAGS__GROUP__UPDATING_SUCCESS" />);

                refreshGroupList();
            }
        },
        [snackbar, refreshGroupList]
    );

    const onSave = useCallback(
        async (text: string, responseTemplateGroupData: ResponseTemplateGroupType) => {
            if (text.length === 0) {
                snackbar.error(<Locale stringKey="TAGS__EMPTY_ERROR" />);
                return;
            }

            if (responseTemplateGroupData.title.length > 0) {
                const data = {
                    ...responseTemplateGroupData,
                    title: text,
                };

                updateGroup(data, responseTemplateGroupData.id);
                return;
            }

            const result = await fetchTemplateGroupCreate({title: text});

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__GROUP__CREATING_ERROR" />);
                return;
            }

            snackbar.success(<Locale stringKey="TAGS__GROUP__CREATING_SUCCESS" />);

            setNewGroup([]);
            refreshGroupList();
        },
        [snackbar, setNewGroup, refreshGroupList, updateGroup]
    );

    const convertResponseTemplateTableRow = useCallback(
        (
            responseTemplateGroupData: ResponseTemplateGroupType,
            index: number
        ): ResponseTemplatesEditTableRowDataType => {
            return {
                responseTemplateName: renderGroupName(
                    responseTemplateGroupData,
                    getLocalizedString,
                    (text: string) => onSave(text, responseTemplateGroupData),
                    editableId
                ),
                key: String(responseTemplateGroupData.id),
                index,
                templateGroupActions: renderCellActions(
                    () => setEditableId(responseTemplateGroupData.id),
                    () => onDelete(responseTemplateGroupData)
                ),
            };
        },
        [getLocalizedString, onSave, editableId, onDelete]
    );

    const [dataList, setDataList] = useState<Array<ResponseTemplatesEditTableRowDataType>>([]);

    useEffect(() => {
        if (groupDataList) {
            setDataList(groupDataList.map(convertResponseTemplateTableRow));
        }
    }, [responseTemplateGroupListResult, convertResponseTemplateTableRow, groupDataList]);

    return (
        <Table<ResponseTemplatesEditTableRowDataType>
            bordered
            columns={responseTemplateColumnList}
            dataSource={dataList}
            loading={responseTemplateGroupListIsInProgress}
            pagination={false}
            rowKey="key"
            rowSelection={{columnWidth: 47, onChange: handleRowSelection}}
            scroll={{x: true}}
            size="middle"
        />
    );
}
