import {deserializeV2} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../../util/fetch';
import {rootApiUrl} from '../../api/api-const';

import {sourceSettingsAccessSchema, SourceSettingsAccessType} from './access-type';

export async function fetchSourceSettingsAccess(): Promise<SourceSettingsAccessType> {
    const url = `${rootApiUrl}/v1/source_settings/access/`;
    const response = await fetchX(url, {method: FetchMethodEnum.get});

    return deserializeV2<SourceSettingsAccessType>(url, sourceSettingsAccessSchema, response);
}
