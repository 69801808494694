import {faPenToSquare, faStar, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faBars, faPeriod, faRotateLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Row, Typography} from 'antd';
import {useMemo} from 'react';

import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {classNames} from '../../../../../../../../util/css';
import {CommonCategoryType} from '../select-categories-form-item';

import * as styles from './selected-category-item.scss';

const {Text} = Typography;

type PropsType<TCompanyCategoryType extends CommonCategoryType> = {
    disableDragging?: boolean;
    locked?: boolean;
    className?: string;
    isFirst: boolean;
    isEditingFirst: boolean;
    isMarkPrimaryAvailable: boolean;
    companyCategoryItem: TCompanyCategoryType;
    removeCompanyCategory: (item: TCompanyCategoryType) => void;
    startEditingPrimaryCompanyCategory?: () => void;
    finishEditingPrimaryCompanyCategory?: () => void;
};

export function SelectedCompanyCategoryItem<TCompanyCategoryType extends CommonCategoryType>(
    props: PropsType<TCompanyCategoryType>
): JSX.Element {
    const {
        disableDragging,
        locked,
        isFirst,
        isEditingFirst,
        companyCategoryItem,
        className,
        isMarkPrimaryAvailable,
        removeCompanyCategory,
        startEditingPrimaryCompanyCategory,
        finishEditingPrimaryCompanyCategory,
    } = props;

    const fullClassName = classNames(
        styles.selected_company_category_item,
        disableDragging ? styles.selected_company_category_item_disabled : '',
        locked ? styles.selected_company_category_item_locked : '',
        isFirst && isEditingFirst ? styles.selected_company_category_item_primary_editing : '',
        isFirst && isMarkPrimaryAvailable ? styles.selected_company_category_item_primary : '',
        className
    );

    const {categoryName} = companyCategoryItem;

    const {getLocalizedString} = useLocale();

    const icon = useMemo((): JSX.Element => {
        if (isFirst) {
            return (
                <FontAwesomeIcon
                    className={classNames(
                        styles.selected_category_item__icon,
                        styles.selected_category_item__icon_primary
                    )}
                    fixedWidth
                    icon={faStar}
                />
            );
        }

        if (disableDragging) {
            return (
                <FontAwesomeIcon
                    className={classNames(
                        styles.selected_category_item__icon,
                        styles.selected_category_item__icon_small,
                        isEditingFirst || locked ? styles.selected_category_item__icon_editing : ''
                    )}
                    fixedWidth
                    icon={faPeriod}
                />
            );
        }

        return (
            <FontAwesomeIcon
                className={classNames(
                    styles.selected_category_item__icon,
                    isEditingFirst ? styles.selected_category_item__icon_editing : ''
                )}
                fixedWidth
                icon={faBars}
            />
        );
    }, [disableDragging, isEditingFirst, isFirst, locked]);

    const button = useMemo((): JSX.Element | null => {
        const buttonClassName = classNames(styles.selected_category_item__button);

        if (locked) {
            return null;
        }

        if (!isFirst) {
            return (
                <Button
                    className={buttonClassName}
                    disabled={isEditingFirst}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => removeCompanyCategory(companyCategoryItem)}
                    type="text"
                />
            );
        }

        return isEditingFirst ? (
            <Button
                className={buttonClassName}
                icon={<FontAwesomeIcon icon={faRotateLeft} />}
                onClick={() => finishEditingPrimaryCompanyCategory?.()}
                type="text"
            />
        ) : (
            <Button
                className={buttonClassName}
                icon={<FontAwesomeIcon icon={faPenToSquare} />}
                onClick={() => startEditingPrimaryCompanyCategory?.()}
                title={getLocalizedString('TEXT__EDIT')}
                type="text"
            />
        );
    }, [
        locked,
        isFirst,
        isEditingFirst,
        getLocalizedString,
        removeCompanyCategory,
        companyCategoryItem,
        finishEditingPrimaryCompanyCategory,
        startEditingPrimaryCompanyCategory,
    ]);

    return (
        <li className={fullClassName}>
            <Row>{icon}</Row>
            <Row className={styles.selected_category_item__row}>
                <Text>{categoryName}</Text>
            </Row>
            <Row>{button}</Row>
        </li>
    );
}
