import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../../util/url';
import {PaginatedResponseType, RequestOptionsType} from '../../api/api-type';

import {
    ReviewPhraseListQueryType,
    reviewPhraseListResponse,
    ReviewPhraseType,
    reviewsPhraseSearchSchema,
} from './review-phrases-type';

export async function fetchReviewPhraseList(
    options: ReviewPhraseListQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<PaginatedResponseType<ReviewPhraseType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewPhraseListQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/phrases/?${searchParameters}`;

    return fetchAndDeserialize<PaginatedResponseType<ReviewPhraseType>>(url, reviewPhraseListResponse);
}

export async function fetchReviewPhraseSearch(
    options: RequestOptionsType,
    mainFilterKey: string
): Promise<Array<string>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<RequestOptionsType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/phrases/search/?${searchParameters}`;

    return fetchAndDeserialize<Array<string>>(url, reviewsPhraseSearchSchema);
}
