/* eslint-disable unicorn/prefer-query-selector, max-statements*/
import {faArrowUp, faThumbsDown, faThumbsUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Divider, FloatButton} from 'antd';
import DOMPurify from 'dompurify';
import {useEffect, useRef, useState} from 'react';
import {ExtractRouteParams, generatePath, useHistory, useLocation, useParams} from 'react-router';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../component/meta/meta';
import {Text} from '../../../../component/text/text';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useMessage} from '../../../../provider/message/message-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {fetchArticleFeedback} from '../../../../service/knowledge/knowledge-api';
import {useKnowledgeArticle} from '../../../../service/knowledge/knowledge-hook';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {KnowledgeArticleLink} from '../knowledge-article-link/knowledge-article-link';

import * as styles from './knowledge-article-page.scss';

export function KnowledgeArticlePage(): JSX.Element {
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const [dislikesCount, setDislikesCount] = useState(0);
    const [isUseful, setIsUseful] = useState<boolean | null>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    const location = useLocation();

    const {message} = useMessage();
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const {goBack, hash, pushUrl} = useUrl();
    const history = useHistory();

    const {id} = useParams<ExtractRouteParams<typeof appRoute.knowledgeArticle.path, string>>();
    const articleId = Number(id);

    const {result, isInProgress, processError} = useKnowledgeArticle(articleId);
    const isNavigatingBackRef = useRef(false);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerHTML = DOMPurify.sanitize(result?.body || '');
        }
    }, [result]);

    useEffect(() => {
        if (result !== null) {
            setIsUseful(result.isUseful);
            setLikesCount(result.likesCount);
            setDislikesCount(result.dislikesCount);
        }
    }, [result]);

    useEffect(() => {
        const pathHistory = JSON.parse(window.sessionStorage.getItem('pathHistory') || '[]');

        const currentUrl = window.location.href;
        const isCurrentUrlInHistory = pathHistory[pathHistory.length - 1] !== currentUrl;

        if (!isNavigatingBackRef.current && (pathHistory.length === 0 || isCurrentUrlInHistory)) {
            window.sessionStorage.setItem('pathHistory', JSON.stringify([...pathHistory, currentUrl]));
        }

        isNavigatingBackRef.current = false;

        return () => {
            if (!history.location.pathname.startsWith(appRoute.knowledge.path)) {
                window.sessionStorage.removeItem('pathHistory');
            }
        };
    }, [history.location.pathname]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const anchorItemObserver = document.getElementById(location.hash.slice(1));

            if (anchorItemObserver) {
                anchorItemObserver.scrollIntoView({behavior: 'smooth', block: 'start'});
                observer.disconnect();
            }
        });

        observer.observe(document, {subtree: true, childList: true});

        return () => observer.disconnect();
    }, []);

    function handleBackTo() {
        const pathHistory = JSON.parse(window.sessionStorage.getItem('pathHistory') || '[]');

        if (hash) {
            history.go(-2);
        } else if (pathHistory.length > 1) {
            pathHistory.pop();

            window.sessionStorage.setItem('pathHistory', JSON.stringify(pathHistory));
            isNavigatingBackRef.current = true;
            goBack();
        } else {
            pushUrl(appRoute.knowledge.path);
        }
    }

    async function handleFeedbackClick(estimation: boolean) {
        setIsLoadingFeedback(true);

        try {
            const {
                isUseful: isUsefulResponse,
                likesCount: likesCountResponse,
                dislikesCount: dislikesCountResponse,
            } = await fetchArticleFeedback(articleId, {is_useful: isUseful === estimation ? null : estimation});

            setIsUseful(isUsefulResponse);
            setDislikesCount(dislikesCountResponse);
            setLikesCount(likesCountResponse);

            if (isUseful !== estimation) {
                message.success(<Locale stringKey="KNOWLEDGE__MESSAGE" />);
            }

            setIsLoadingFeedback(false);
        } catch {
            setIsLoadingFeedback(false);

            snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
        }
    }

    return (
        <>
            <Meta title={getLocalizedString('CATEGORY_NAME__KNOWLEDGE')} />

            <Page
                footerHidden={isInProgress}
                isTopFilterHidden
                renderContainer={(children) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{children}</>
                )}
            >
                {isInProgress && (
                    <MainPageContainer>
                        <PageCard className={styles.KnowledgeArticlePage_pageCard}>
                            <Spinner />
                        </PageCard>
                    </MainPageContainer>
                )}

                <MainPageContainer className={isInProgress ? styles.KnowledgeArticlePage__hidden : ''}>
                    <PageCard>
                        <BreadCrumbs
                            list={[
                                {
                                    path: appRoute.knowledge.path,
                                    titleLangKey: 'KNOWLEDGE__TITLE',
                                },
                                {
                                    path: generatePath(appRoute.knowledgeArticle.path, {id}),
                                    title: result?.categoryTitle || '',
                                },
                            ]}
                        />

                        <PageHeader className={styles.KnowledgeArticlePage_pageHeader}>
                            {result?.title || ''}

                            <Button onClick={handleBackTo}>
                                <Locale stringKey="BUTTON__BACK" />
                            </Button>
                        </PageHeader>
                    </PageCard>

                    <div className={styles.KnowledgeArticlePage_contentWrapper}>
                        {processError ? (
                            <AlertFallback />
                        ) : (
                            <div className={styles.KnowledgeArticlePage_content}>
                                <div className={styles.KnowledgeArticlePage_article}>
                                    <div className={styles.KnowledgeArticlePage_articleContent}>
                                        <span ref={spanRef} />

                                        <div className={styles.KnowledgeArticlePage_footer}>
                                            <Text stringKey="KNOWLEDGE__FEEDBACK" />

                                            <Button
                                                className={styles.KnowledgeArticlePage_button}
                                                ghost={isUseful === true}
                                                icon={<FontAwesomeIcon icon={faThumbsUp} />}
                                                loading={isLoadingFeedback}
                                                onClick={() => handleFeedbackClick(true)}
                                                type={isUseful === true ? 'primary' : 'default'}
                                            >
                                                <Text stringKey="TEXT__YES" />

                                                <Text secondary>({likesCount})</Text>
                                            </Button>

                                            <Button
                                                className={styles.KnowledgeArticlePage_button}
                                                ghost={isUseful === false}
                                                icon={<FontAwesomeIcon icon={faThumbsDown} />}
                                                loading={isLoadingFeedback}
                                                onClick={() => handleFeedbackClick(false)}
                                                type={isUseful === false ? 'primary' : 'default'}
                                            >
                                                <Text stringKey="TEXT__NO" />

                                                <Text secondary>({dislikesCount})</Text>
                                            </Button>
                                        </div>
                                    </div>

                                    <div className={styles.KnowledgeArticlePage_scrollButtonWrapper}>
                                        <FloatButton.BackTop
                                            className={styles.KnowledgeArticlePage_scrollButton}
                                            icon={<FontAwesomeIcon icon={faArrowUp} />}
                                        />
                                    </div>
                                </div>

                                <div className={styles.KnowledgeArticlePage_aside}>
                                    <div className={styles.KnowledgeArticlePage_asideContent}>
                                        <Text large>{result?.categoryTitle || ''}</Text>

                                        <Divider className={styles.KnowledgeArticlePage_headerDivider} />

                                        {result?.categoryContent.reduce<Array<JSX.Element>>(
                                            (accumulator, article) =>
                                                article.articleId !== articleId
                                                    ? [
                                                          ...accumulator,
                                                          <KnowledgeArticleLink
                                                              article={article}
                                                              isEllipsis
                                                              key={article.articleId}
                                                          />,
                                                      ]
                                                    : accumulator,
                                            []
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </MainPageContainer>
            </Page>
        </>
    );
}
