import {faClock} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar} from 'antd';

import {Empty} from '../../../../layout/empty/empty';

import * as styles from './search-phrases.scss';

type PropsType = {
    hasEmptyValue: boolean;
};

export function SearchPhrasesEmpty(props: PropsType): JSX.Element {
    const {hasEmptyValue} = props;
    const emptyText = hasEmptyValue
        ? 'DASHBOARD_PAGE__SEARCH_PHRASES__HAS_EMPTY_PHRASE'
        : 'DASHBOARD_PAGE__SEARCH_PHRASES__WAIT_COLLECT_PHRASES';

    return (
        <Empty
            image={
                hasEmptyValue ? null : (
                    <Avatar
                        className={styles.SearchPhrases_avatar}
                        icon={<FontAwesomeIcon icon={faClock} />}
                        size={72}
                    />
                )
            }
            secondaryText={emptyText}
            secondaryTextClassName={styles.SearchPhrases_emptyText}
        />
    );
}
