import {createContext, PropsWithChildren, useCallback, useEffect, useState} from 'react';

import {DashboardWidgetEnum} from '../../page/main/dashboard/dashboard-type';
import {FeaturesEnum} from '../../service/user/user-type';
import {useDomainConfig} from '../domain-config/domain-config-hook';
import {useLocale} from '../locale/locale-hook';

import {fetchHelpLinks} from './help-links-api';
import {
    DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX,
    DOCUMENTATION_LINK_KEY_PREFIX,
    isMatchingHelpLink,
} from './help-links-context-helper';
import {HELP_LINKS_CONTEXT_INITIAL_DATA, HelpLinksContextType, HelpLinkType} from './help-links-type';

export const HelpLinksContext = createContext<HelpLinksContextType>(HELP_LINKS_CONTEXT_INITIAL_DATA);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function HelpLinksProvider(props: PropsType): JSX.Element | null {
    const {children} = props;
    const {domainName} = useDomainConfig();

    const [apiHelplinks, setApiHelplinks] = useState<Array<HelpLinkType>>([]);

    const [config, setConfig] = useState<HelpLinksContextType>(HELP_LINKS_CONTEXT_INITIAL_DATA);
    const {shortLocaleName} = useLocale();

    const getDocumentationLink = useCallback(
        (linkPath: string, prefix: string = DOCUMENTATION_LINK_KEY_PREFIX): string =>
            apiHelplinks?.find((resultLink) => isMatchingHelpLink(resultLink, prefix + linkPath, shortLocaleName))
                ?.documentationLink || '',
        [apiHelplinks, shortLocaleName]
    );

    const getVideoLink = useCallback(
        (linkPath: string, prefix: string = DOCUMENTATION_LINK_KEY_PREFIX): string =>
            apiHelplinks?.find((resultLink) => isMatchingHelpLink(resultLink, prefix + linkPath, shortLocaleName))
                ?.videoLink || '',
        [apiHelplinks, shortLocaleName]
    );

    useEffect(() => {
        fetchHelpLinks({
            domainName,
        })
            .then(setApiHelplinks)
            .catch(() => {
                console.error('Error loading help links');
            });
    }, [domainName, shortLocaleName]);

    useEffect(() => {
        const reviewAnalyticsLink = getDocumentationLink('featureLink-reviewAnalytics');

        setConfig({
            helpLinks: apiHelplinks,
            documentationLinks: {
                company: {
                    sentName: getDocumentationLink('company-sentName'),
                    categories: getDocumentationLink('company-categories'),
                    feedImportAndValidation: getDocumentationLink('company-feedImportAndValidation'),
                    feedImport: getDocumentationLink('company-feedImport'),
                    feedExport: getDocumentationLink('company-feedExport'),
                    contactsFromCatalogs: getDocumentationLink('contacts-from-catalogs'),
                    yandexVerification: getDocumentationLink('yandex-verification-rules'),
                },
                sources: {
                    status: getDocumentationLink('sources-status'),
                },
                autoReplies: {
                    create: getDocumentationLink('autoReplies-create'),
                    edit: getDocumentationLink('autoReplies-edit'),
                },
                yandex: {
                    sourcesHide: getDocumentationLink('yandex-hide-source'),
                    transferAccountsBanner: getDocumentationLink('yandex-transfer-accounts-banner'),
                },
                facebook: {
                    locationCreationPermissions: getDocumentationLink('facebook-locationCreationPermissions'),
                    newVersionToCreateLocations: getDocumentationLink('facebook-newVersionToCreateLocations'),
                },
                google: {
                    massConfirmation: getDocumentationLink('google-massConfirmation'),
                    confirmationTypes: getDocumentationLink('google-confirmationTypes'),
                    requestRights: getDocumentationLink('google-requestRights'),
                    addressGroup: getDocumentationLink('google-addressGroup'),
                    addressGroupAdmin: getDocumentationLink('google-addressGroupAdmin'),
                },
                users: {
                    roleDescription: getDocumentationLink('users-roleDescription'),
                },

                storelocator: {
                    getMapboxKey: getDocumentationLink('storelocator-mapbox-key'),
                },
                usedeskIntegration: getDocumentationLink('usedeskIntegration'),
                reviewsLinkFormats: getDocumentationLink('reviewsLinkFormats'),
                clientsImportTemplate: getDocumentationLink('clientsImportTemplate'),
                productsLink: getDocumentationLink('productsLink'),
                ratesLink: getDocumentationLink('ratesLink'),
                partnersLink: getDocumentationLink('partnersLink'),
                reviews: {
                    trialLicenseLink: getDocumentationLink('reviews-neural-link-trial'),
                },
                featureActivation: {
                    [FeaturesEnum.reviewSuggests]: getDocumentationLink('featureLink-reviewSuggests'),
                    [FeaturesEnum.reviewAnalyticsBase]: reviewAnalyticsLink,
                    [FeaturesEnum.reviewAnalyticsFull]: reviewAnalyticsLink,
                    [FeaturesEnum.reviewAnalyticsCompetitor]: reviewAnalyticsLink,
                    [FeaturesEnum.duplicatesAndFakes]: getDocumentationLink('featureLink-duplicatesAndFakes'),
                    [FeaturesEnum.photoTool]: getDocumentationLink('featureLink-photoTool'),
                    [FeaturesEnum.reviewsGenerator]: getDocumentationLink('featureLink-reviewsGenerator'),
                    [FeaturesEnum.storeLocator]: getDocumentationLink('featureLink-storeLocator'),
                    [FeaturesEnum.posts]: getDocumentationLink('featureLink-posts'),
                    [FeaturesEnum.onlinePresenceAnalytics]: getDocumentationLink('featureLink-onlinePresenceAnalytics'),
                },
                dashboard: {
                    [DashboardWidgetEnum.PhotoTool]: getDocumentationLink(
                        'widget-phototool',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.ReviewsSummary]: getDocumentationLink(
                        'widget-reviews-summary',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.PricesSingle]: getDocumentationLink(
                        'widget-prices',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.PricesMultiple]: getDocumentationLink(
                        'widget-prices',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.AutoReplies]: getDocumentationLink(
                        'widget-auto-replies',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.Posts]: getDocumentationLink(
                        'widget-posts',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.SynchronizationSingle]: getDocumentationLink(
                        'widget-synchronization-single',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.SynchronizationMultiple]: getDocumentationLink(
                        'widget-synchronization-multiple',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.Workload]: getDocumentationLink(
                        'widget-workload',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.Activity]: getDocumentationLink(
                        'widget-activity',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.SearchPhrases]: getDocumentationLink(
                        'widget-search-phrases',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.LastReviews]: getDocumentationLink(
                        'widget-last-reviews',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.Rating]: getDocumentationLink(
                        'widget-rating',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.OnlinePresence]: getDocumentationLink(
                        'widget-online-presence',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                    [DashboardWidgetEnum.RatingDistribution]: getDocumentationLink(
                        'widget-rating-distribution',
                        DASHBOARD_DOCUMENTATION_LINK_KEY_PREFIX
                    ),
                },
                storeLocatorWidgetUrl: getDocumentationLink('storeLocatorWidgetUrl'),
                telegramNotificationBotLink: getDocumentationLink('telegramNotificationBotLink'),
                ideaLink: getDocumentationLink('ideaLink'),
                help: getDocumentationLink('help'),
                onboarding: {
                    addCompany: getDocumentationLink('onboarding-add-сompany'),
                    connectGoogleAccount: getDocumentationLink('onboarding-connect-google-account'),
                    connectFacebookAccount: getDocumentationLink('onboarding-connect-facebook-account'),
                    connectDoubleGisAccount: getDocumentationLink('onboarding-connect-doubleGis-account'),
                    syncGoogleCompanies: getDocumentationLink('onboarding-sync-google-companies'),
                    syncFacebookCompanies: getDocumentationLink('onboarding-sync-facebook-companies'),
                    confirmationGoogleCompanies: getDocumentationLink('onboarding-confirmation-google-companies'),
                    confirmationYandexCompanies: getDocumentationLink('onboarding-confirmation-yandex-companies'),
                },
            },
            videoLinks: {
                onboarding: {
                    addCompany: getVideoLink('onboarding-add-сompany'),
                    connectGoogleAccount: getVideoLink('onboarding-connect-google-account'),
                    connectFacebookAccount: getVideoLink('onboarding-connect-facebook-account'),
                    connectDoubleGisAccount: getVideoLink('onboarding-connect-doubleGis-account'),
                    syncGoogleCompanies: getVideoLink('onboarding-sync-google-companies'),
                    syncFacebookCompanies: getVideoLink('onboarding-sync-facebook-companies'),
                    confirmationGoogleCompanies: getVideoLink('onboarding-confirmation-google-companies'),
                    confirmationYandexCompanies: getVideoLink('onboarding-confirmation-yandex-companies'),
                },
                recommendations: {
                    autoReviewTemplate: getVideoLink('recommendation-auto-review-template'),
                    tagsForReview: getVideoLink('recommendation-tags-for-review'),
                    productsAndPrices: getVideoLink('recommendation-products-and-prices'),
                    onlinePresence: getVideoLink('recommendation-online-presence'),
                    photoAndVideoManager: getVideoLink('recommendation-photo-and-video-manager'),
                    publications: getVideoLink('recommendation-publications'),
                    reviewsGenerator: getVideoLink('recommendation-reviews-generator'),
                    reviewsGeneratorQr: getVideoLink('recommendation-reviews-generator-qr'),
                    repliesToReviews: getVideoLink('recommendation-replies-to-reviews'),
                },
            },
        });
    }, [apiHelplinks, getDocumentationLink, getVideoLink]);

    return <HelpLinksContext.Provider value={config}>{children}</HelpLinksContext.Provider>;
}
