import {LangKeyType} from '../../../../../provider/locale/translation/type';

import {
    CreateProductGroupFormFieldEnum,
    ProductGroupFormType,
    ProductsServiceTypeEnum,
} from './product-group-form-type';

export const googleServiceTypeTranslationMap: Record<ProductsServiceTypeEnum, LangKeyType> = {
    [ProductsServiceTypeEnum.Menu]: 'PRODUCTS__GROUP_FORM__APPOINTMENT__MENU',
    [ProductsServiceTypeEnum.Services]: 'PRODUCTS__GROUP_FORM__SERVICES',
};

export const defaultInitialValues: Partial<ProductGroupFormType> = {
    [CreateProductGroupFormFieldEnum.Name]: '',
    [CreateProductGroupFormFieldEnum.Services]: [],
    [CreateProductGroupFormFieldEnum.ServiceType]: null,
    [CreateProductGroupFormFieldEnum.SourceId]: null,
    [CreateProductGroupFormFieldEnum.FeedUrl]: '',
    [CreateProductGroupFormFieldEnum.GoogleCategory]: null,
};
