import {useMemo} from 'react';

import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {HeaderVisibilityOptionsEnum} from '../../provider/domain-config/domain-config-type';
import {useSystem} from '../../provider/system/system-hook';
import {useCompanies} from '../company/company-hook';

import {HeaderHookType} from './header-hook-type';

export function useHeaderOptions(isFilterHidden: boolean): HeaderHookType {
    const {headerVisibility} = useDomainConfig();
    const {data: companiesResult} = useCompanies({});
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isDesktop} = screen;

    const userHasOnlyOneCompany = useMemo(() => {
        if (companiesResult) {
            return Boolean(companiesResult.count && companiesResult.count === 1);
        }

        return true;
    }, [companiesResult]);

    const isHeaderShown = useMemo(() => {
        if (!isDesktop || headerVisibility === HeaderVisibilityOptionsEnum.VISIBLE) {
            return true;
        }

        if (headerVisibility === HeaderVisibilityOptionsEnum.HIDDEN_FOR_SINGLE_COMPANY) {
            return !userHasOnlyOneCompany && !isFilterHidden;
        }

        return false;
    }, [headerVisibility, isDesktop, isFilterHidden, userHasOnlyOneCompany]);

    return {
        isHeaderShown,
        isMainFilterHidden: userHasOnlyOneCompany,
    };
}
