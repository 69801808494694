import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Divider, Space, Tag, Tooltip} from 'antd';
import {identity, isEmpty, omit} from 'lodash';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';
import {useEffect, useMemo, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {Text} from '../../../../component/text/text';
import {gray7} from '../../../../css/var-export.scss';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {BreadCrumbItemType} from '../../../../layout/bread-crumbs/bread-crumbs-type';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-container';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useCatalogConfig} from '../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useSystem} from '../../../../provider/system/system-hook';
import {formatAddress} from '../../../../service/address/address-helper';
import {CompanyKeyEnum} from '../../../../service/company-v2/company-const';
import {
    CompanyErrorType,
    CompanyFormType,
    CompanyServerType,
    CompanyType,
} from '../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../typings/antd';
import {getUrlParameters} from '../../../../util/api-adapter';
import {classNames} from '../../../../util/css';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {
    companyKeyToWidgetKeyMap,
    CompanyWidgetKeyEnum,
    companyWidgetKeyToWidgetKeyMap,
    companyWidgetToNamesMap,
    DATA_WIDGET_KEY_ATTR,
    getWidgetNames,
    WidgetKeysEnum,
} from '../company-form-const';
import {useFillWidget} from '../company-form-hook';
import {WidgetConfigType, WidgetNamesType} from '../company-form-type';
import {Navigation} from '../navigation/navigation';
import {extractCurrentHash, scrollIntoWidget} from '../navigation/navigation-helper';
import {useScroller} from '../navigation/use-scroller';

import {getFormErrors, removeErrorIfExists} from './company-error-helper';
import {getCompanyCondition, toFormFormat, toServerFormat} from './company-form-helper';
import {CompanyProgress} from './company-progress/company-progress';
import {convertErrorToFormUnsafeError} from './error-helper';
import {AddressWidget} from './widgets/address/address-widget';
import {AttributesWidget} from './widgets/attributes/attributes-widget';
import {BrandWidget} from './widgets/brand/brand-widget';
import {CategoriesWidget} from './widgets/categories/categories-widget';
import {ContactInfoWidget} from './widgets/contact-info/contact-info-widget';
import {DescriptionsWidget} from './widgets/descriptions/descriptions-widget';
import {GalleryWidget} from './widgets/gallery/gallery-widget';
import {LegalWidget} from './widgets/legal/legal-widget';
import {LinksWidget} from './widgets/links/links-widget';
import {LogoWidget} from './widgets/logo/logo-widget';
import {NamesWidget} from './widgets/names/names-widget';
import {PaymentMethodsWidget} from './widgets/payment-methods/payment-methods-widget';
import {TemporaryWorkingTimeWidget} from './widgets/working-time/temporary-working-time-widget';
import {WorkingTimeWidget} from './widgets/working-time/working-time-widget';
import * as styles from './company-form.scss';

type PropsType = {
    breadcrumbsList: Array<BreadCrumbItemType>;
    meta: string;
    pageTitle: string;
    submitButtonText: string;
    initialValues: Partial<CompanyType>;
    serverError: CompanyErrorType | null;
    submitDisabled?: boolean;
    stickyButtons?: boolean;
    companyId?: number;
    onFinish: (values: CompanyServerType, form: FormInstance<CompanyFormType>) => void;
    getWidgets?: (widgets: Array<WidgetConfigType>, widgetNames: WidgetNamesType) => Array<WidgetConfigType>;
    isShowEmptyFields?: boolean;
    isEdit?: boolean;
};

// eslint-disable-next-line max-statements, complexity
export function CompanyForm(props: PropsType): JSX.Element | null {
    const {
        meta,
        breadcrumbsList,
        pageTitle,
        submitButtonText,
        initialValues: rawInitialValues,
        serverError: errorMessage,
        submitDisabled,
        stickyButtons,
        onFinish,
        companyId,
        getWidgets = identity,
        isShowEmptyFields,
        isEdit = false,
    } = props;

    const {getLocalizedString} = useLocale();
    const {screen} = useSystem();
    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();
    const [form] = Form.useForm<CompanyFormType>();
    const {mainCatalogs} = useDomainConfig();
    const catalogConfigHook = useCatalogConfig(rawInitialValues.address?.regionId, companyId);
    const initialValues = useMemo(
        () => toFormFormat(rawInitialValues, catalogConfigHook?.result ?? []),
        [catalogConfigHook?.result, rawInitialValues]
    );
    const [errorMessageForManualDisplay, setErrorMessageForManualDisplay] = useState(errorMessage);
    const [areAttributesOpened, setAreAttributesOpened] = useState(true);
    const [selected, setSelected] = useState(extractCurrentHash);

    const syncedMainCatalogs =
        catalogConfigHook.result?.filter(
            (catalog) => mainCatalogs.includes(catalog.catalogId) && catalog.synced === true
        ) || [];
    const widgetNames = useMemo(() => getWidgetNames(getLocalizedString), [getLocalizedString]);

    const {companyFieldFill, checkCompanyField, setCompanyFieldFill} = useFillWidget(form.getFieldsValue(true));
    const {changeSelected} = useScroller(Boolean(selected), setSelected);

    const companyFieldFillKeys = Object.keys(companyFieldFill);
    const companyFieldFillKeysFiltered = companyFieldFillKeys.filter(
        (companyKey) => companyFieldFill[companyKey as CompanyWidgetKeyEnum]
    );
    const percentProgressbar = companyFieldFillKeysFiltered.length / companyFieldFillKeys.length;

    const postalCode = Form.useWatch([CompanyKeyEnum.Address, 'postalCode'], form);

    const COMMON_WIDGETS: Array<WidgetConfigType> = useMemo(
        () => [
            {
                id: WidgetKeysEnum.names,
                name: widgetNames[WidgetKeysEnum.names].name,
                component: (
                    <NamesWidget
                        hasInitialNames={Boolean(initialValues.names?.length)}
                        initialCatalogs={initialValues.extraNames?.map(({catalogId}) => catalogId) ?? []}
                        name={widgetNames[WidgetKeysEnum.names].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.brand,
                name: widgetNames[WidgetKeysEnum.brand].name,
                component: <BrandWidget initialBrandName={initialValues.brandName} />,
            },
            {
                id: WidgetKeysEnum.address,
                name: widgetNames[WidgetKeysEnum.address].name,
                component: <AddressWidget errorMessage={errorMessageForManualDisplay} />,
            },
            {
                id: WidgetKeysEnum.legal,
                name: widgetNames[WidgetKeysEnum.legal].name,
                component: <LegalWidget />,
            },
            {
                id: WidgetKeysEnum.categories,
                name: widgetNames[WidgetKeysEnum.categories].name,
                component: (
                    <CategoriesWidget
                        isPaidYandexPlacement={Boolean(initialValues.isPaidYandexPlacement)}
                        name={widgetNames[WidgetKeysEnum.categories].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.contactInfo,
                name: widgetNames[WidgetKeysEnum.contactInfo].name,
                component: (
                    <ContactInfoWidget
                        hasInitialPhones={Boolean(initialValues.phones?.length)}
                        initialCatalogs={[
                            ...new Set([
                                ...(initialValues.extraPhones?.map(({catalogId}) => catalogId) ?? []),
                                ...(initialValues.extraEmails?.map(({catalogId}) => catalogId) ?? []),
                            ]),
                        ]}
                        name={widgetNames[WidgetKeysEnum.contactInfo].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.links,
                name: widgetNames[WidgetKeysEnum.links].name,
                component: (
                    <LinksWidget
                        brandId={rawInitialValues.brandId}
                        hasInitialSocialNetworks={Boolean(initialValues.socialNetworks?.length)}
                        hasInitialWebsites={Boolean(initialValues.websites?.length)}
                        initialCatalogs={[
                            ...new Set([
                                ...(initialValues.extraWebsites?.map(({catalogId}) => catalogId) ?? []),
                                ...(initialValues.extraSocialNetworks?.map(({catalogId}) => catalogId) ?? []),
                            ]),
                        ]}
                        name={widgetNames[WidgetKeysEnum.links].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.workingTime,
                name: widgetNames[WidgetKeysEnum.workingTime].name,
                component: (
                    <WorkingTimeWidget
                        errorMessage={errorMessageForManualDisplay}
                        initialCatalogs={initialValues.extraWorkingHours?.map(({catalogId}) => catalogId) ?? []}
                        isDoubleGisCompany={Boolean(initialValues.isDoubleGis)}
                        name={widgetNames[WidgetKeysEnum.workingTime].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.temporaryWorkingTime,
                name: widgetNames[WidgetKeysEnum.temporaryWorkingTime].name,
                component: (
                    <TemporaryWorkingTimeWidget
                        errorMessage={errorMessageForManualDisplay}
                        isDoubleGisCompany={Boolean(initialValues.isDoubleGis)}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.paymentMethod,
                name: widgetNames[WidgetKeysEnum.paymentMethod].name,
                component: <PaymentMethodsWidget />,
            },
            {
                id: WidgetKeysEnum.description,
                name: widgetNames[WidgetKeysEnum.description].name,
                component: (
                    <DescriptionsWidget
                        initialCatalogs={initialValues.extraDescriptions?.map(({catalogId}) => catalogId) ?? []}
                        isDoubleGisCompany={Boolean(initialValues.isDoubleGis)}
                        name={widgetNames[WidgetKeysEnum.description].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.logo,
                name: widgetNames[WidgetKeysEnum.logo].name,
                component: <LogoWidget />,
            },
            {
                id: WidgetKeysEnum.gallery,
                name: widgetNames[WidgetKeysEnum.gallery].name,
                component: (
                    <GalleryWidget
                        initialCatalogs={initialValues.extraGallery?.map(({catalogId}) => catalogId) ?? []}
                        name={widgetNames[WidgetKeysEnum.gallery].name}
                    />
                ),
            },
            {
                id: WidgetKeysEnum.attributes,
                name: widgetNames[WidgetKeysEnum.attributes].name,
                componentWithCustomCard: (
                    <AttributesWidget
                        areAttributesOpened={areAttributesOpened}
                        brandId={rawInitialValues.brandId}
                        key={WidgetKeysEnum.attributes}
                        name={widgetNames[WidgetKeysEnum.attributes].name}
                        setAreAttributesOpened={setAreAttributesOpened}
                    />
                ),
            },
        ],
        [
            widgetNames,
            initialValues.names?.length,
            initialValues.extraNames,
            initialValues.brandName,
            initialValues.phones?.length,
            initialValues.extraPhones,
            initialValues.extraEmails,
            initialValues.socialNetworks?.length,
            initialValues.websites?.length,
            initialValues.extraWebsites,
            initialValues.extraSocialNetworks,
            initialValues.extraWorkingHours,
            initialValues.extraDescriptions,
            initialValues.extraGallery,
            initialValues.isDoubleGis,
            errorMessageForManualDisplay,
            rawInitialValues.brandId,
            areAttributesOpened,
        ]
    );

    const WIDGETS = useMemo(
        () => getWidgets(COMMON_WIDGETS, widgetNames) || [],
        [COMMON_WIDGETS, getWidgets, widgetNames]
    );

    useEffect(() => {
        const fieldsValue = form.getFieldsValue(true);

        if (Object.keys(initialValues).length === 0 && Object.keys(fieldsValue).length === 0) {
            return;
        }

        setCompanyFieldFill(getCompanyCondition({...fieldsValue, ...initialValues}, true));
    }, [form, initialValues, setCompanyFieldFill]);

    useEffect(() => {
        const formAddressField = form.getFieldsValue([CompanyKeyEnum.Address]);
        const postCodeObject: Partial<CompanyFormType> = {
            address: {...formAddressField[CompanyKeyEnum.Address], postalCode},
        };

        checkCompanyField(postCodeObject);
    }, [postalCode]);

    useEffect(() => {
        async function scrollToFirstError() {
            if (!errorMessage) {
                return;
            }

            const firstErrorKey = Object.keys(errorMessage)[0];

            if (!firstErrorKey) {
                return;
            }

            try {
                const widget = companyKeyToWidgetKeyMap[firstErrorKey];

                if (widget) {
                    await scrollIntoWidget(widget, true);

                    if (firstErrorKey === WidgetKeysEnum.attributes) {
                        setAreAttributesOpened(true);
                    }
                }
            } catch {
                // form might not be initialized yet if you speedrun the submit, seems fine to ignore
            }
        }

        setErrorMessageForManualDisplay(errorMessage);

        if (!errorMessage) {
            return;
        }

        const formErrors = getFormErrors(form, errorMessage);

        if (!formErrors) {
            return;
        }

        form.setFields(formErrors);

        scrollToFirstError();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage]);

    function handleValuesChange(values: CompanyFormType) {
        convertErrorToFormUnsafeError(values).forEach(({name}) => removeErrorIfExists(name, form));

        if (typeof values?.address?.postalCode !== 'string') {
            checkCompanyField(values);
        }

        if (errorMessageForManualDisplay) {
            const keys = Object.keys(values);

            if (Object.keys(errorMessageForManualDisplay).some((key) => keys.includes(key))) {
                const newErrors = omit(errorMessageForManualDisplay, keys);

                // remove changed errors for widgets that use errors manually (not through ant)
                setErrorMessageForManualDisplay(isEmpty(newErrors) ? null : newErrors);
            }
        }
    }

    async function handleFinish() {
        try {
            await form.validateFields();
        } catch {
            // ant calls handleFinishFailed for us
        }

        // values from onFinish don't contain values from unmounted form items, form.getFieldsValue(true) does
        await onFinish(toServerFormat(form.getFieldsValue(true), initialValues), form);
        form.setFieldValue(CompanyKeyEnum.SubmitTimestamp, Date.now());
    }

    function handleFinishFailed({errorFields}: ValidateErrorEntity) {
        form.setFieldValue(CompanyKeyEnum.SubmitTimestamp, Date.now());
        const firstNamePath = errorFields?.[0]?.name;

        if (firstNamePath) {
            const firstName = Array.isArray(firstNamePath) ? firstNamePath[0] : firstNamePath;
            const widget = firstName && companyKeyToWidgetKeyMap[firstName];

            if (widget) {
                scrollIntoWidget(widget, true);
            }
        }

        snackbar.error({
            message: <Locale stringKey="COMPANY_FORM__SNACKBAR__VALIDATION_ERROR__HEADER" />,
            description: <Locale stringKey="COMPANY_FORM__SNACKBAR__VALIDATION_ERROR__BODY" />,
        });
    }

    function handleWidgetScroll(company: CompanyWidgetKeyEnum) {
        scrollIntoWidget(companyWidgetKeyToWidgetKeyMap[company], false);
        setSelected(companyWidgetKeyToWidgetKeyMap[company]);
    }

    if (!catalogConfigHook?.result) {
        return (
            <Page>
                <Spinner />
            </Page>
        );
    }

    return (
        <Page
            renderContainer={(children) => (
                <MainPageContainer className={styles.MainPageContainer}>{children}</MainPageContainer>
            )}
        >
            <Meta title={meta} />

            <div className={classNames(styles.ContentContainer, styles.SeparationContainer)}>
                <PageCard>
                    <BreadCrumbs list={breadcrumbsList} />

                    <header>
                        <Space>
                            <h1 className={styles.ContentContainer_title}>{pageTitle}</h1>
                            {initialValues.yandexActualizationHasError && (
                                <Tooltip
                                    placement="bottom"
                                    title={<Locale stringKey="MY_COMPANIES__YANDEX_VERIFICATION__TOOLTIP" />}
                                >
                                    <FontAwesomeIcon
                                        className={styles.ContentContainer_yandexVerificationIcon}
                                        icon={faCircleExclamation}
                                    />
                                </Tooltip>
                            )}
                        </Space>

                        {!isEdit && (
                            <p className={styles.ContentContainer_description}>
                                <Locale stringKey="COMPANY_FORM__DESCRIPTION" />
                            </p>
                        )}

                        {initialValues.address?.country && (
                            <div className={styles.ContentContainer_address}>
                                {formatAddress({
                                    country: initialValues.address?.country?.name || null,
                                    region: initialValues.address?.region?.name || null,
                                    city:
                                        (typeof initialValues.address?.city === 'string'
                                            ? initialValues.address?.city
                                            : initialValues.address?.city?.name) || null,
                                    street: initialValues.address?.street || null,
                                    housenumber: initialValues.address?.houseNumber || null,
                                })}
                            </div>
                        )}
                    </header>

                    <Divider className={styles.ContentContainer_divider} />

                    <CompanyProgress percent={percentProgressbar} />

                    {isShowEmptyFields &&
                        !companyFieldFillKeys.every(
                            (companyKey) => companyFieldFill[companyKey as CompanyWidgetKeyEnum]
                        ) && (
                            <div className={styles.CompanyFields}>
                                <Text bolder large stringKey="COMPANY_FORM__FIELDS_SECTION" />

                                <div className={styles.CompanyFields_tags}>
                                    {companyFieldFillKeys
                                        .filter((companyKey) => !companyFieldFill[companyKey as CompanyWidgetKeyEnum])
                                        .map((company) => (
                                            <Tag
                                                className={styles.CompanyFields_tag}
                                                color="red"
                                                key={company}
                                                onClick={() => handleWidgetScroll(company as CompanyWidgetKeyEnum)}
                                            >
                                                <Locale
                                                    stringKey={companyWidgetToNamesMap[company as CompanyWidgetKeyEnum]}
                                                />
                                            </Tag>
                                        ))}
                                </div>
                            </div>
                        )}
                </PageCard>

                <Form<CompanyFormType>
                    className={styles.SeparationContainer}
                    form={form}
                    initialValues={initialValues}
                    layout="vertical"
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    onValuesChange={handleValuesChange}
                >
                    <Form.Item hidden name={[CompanyKeyEnum.SubmitTimestamp]}>
                        <div />
                    </Form.Item>
                    {WIDGETS.map(({id, name, component, componentWithCustomCard}) =>
                        component ? (
                            <PageCard
                                bodyClassName={styles.SeparationContainer_formCardBody}
                                key={id}
                                title={name}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...{[DATA_WIDGET_KEY_ATTR]: id}}
                            >
                                {component}
                            </PageCard>
                        ) : (
                            componentWithCustomCard
                        )
                    )}

                    <PageCard
                        bodyClassName={styles.SubmitCardBody}
                        className={classNames(styles.SeparationContainer_submitCard, {
                            [styles.SeparationContainer_stickyButtonsCard]: stickyButtons,
                        })}
                    >
                        <Space wrap={screen.width < 600}>
                            <Button
                                data-testid="create-company"
                                disabled={submitDisabled}
                                htmlType="submit"
                                type="primary"
                            >
                                {submitButtonText}
                            </Button>

                            <Button htmlType="reset" onClick={() => pushUrl(appRoute.myCompanies.path)}>
                                <Locale stringKey="COMPANY_FORM__CANCEL" />
                            </Button>
                        </Space>
                        {syncedMainCatalogs.length > 0 ? (
                            <Space className={styles.SeparationContainer_catalogsContainer} wrap>
                                <Text small stringKey="COMPANY_FORM__SUBMIT_CARD__SEND_TO" />
                                {syncedMainCatalogs.map((mappedCatalog) => (
                                    <Tag
                                        className={styles.Tag}
                                        icon={<img alt="" className={styles.Tag__icon} src={mappedCatalog.logoUrl} />}
                                        key={mappedCatalog.catalogId}
                                    >
                                        <Text small>{mappedCatalog.label.split(' ')[0]}</Text>
                                    </Tag>
                                ))}
                            </Space>
                        ) : (
                            <Space align="start">
                                <FontAwesomeIcon color={gray7} icon={faCircleInfo} />
                                <Text
                                    small
                                    stringKey="COMPANY_FORM__SUBMIT_CARD__EMPTY_MAIN_CATALOGS_LIST"
                                    valueMap={{
                                        page: (
                                            <NavigationLink
                                                to={
                                                    appRoute.sources.path +
                                                    getUrlParameters({
                                                        'source-ids': mainCatalogs.toString(),
                                                    })
                                                }
                                            >
                                                <Locale stringKey="COMPANY_FORM__SUBMIT_CARD__EMPTY_MAIN_CATALOGS_LIST__SOURCES" />
                                            </NavigationLink>
                                        ),
                                    }}
                                />
                            </Space>
                        )}
                    </PageCard>
                </Form>
            </div>

            <PageCard className={styles.MainPageContainer_navigationCard}>
                <Navigation changeSelected={changeSelected} selected={selected} widgetIds={WIDGETS.map(({id}) => id)} />
            </PageCard>
        </Page>
    );
}
