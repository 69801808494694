import {useContext, useMemo} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {WidgetError} from '../../../../../../component/widget-error/widget-error';
import {LineDataType} from '../../../../../../layout/chart/line-chart/line-chart-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../provider/main-filter/main-filter-type';
import {useReviewStatsDynamic} from '../../../../../../service/reivew-analysis/review-stats-dynamic/review-stats-dynamic-hook';
import {useUrl} from '../../../../../../util/url-hook/url-hook';
import {getAggregationFromUrl} from '../../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {ReviewsAnalysisFilterEnum} from '../../../reviews-analysis-type';
import {TopicLineChart} from '../topic-line-chart/topic-line-chart';

export function TopicReviewsCountChart(): JSX.Element {
    const {singleFilter} = useReviewsAnalysisFilter();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();
    const {queries} = useUrl();

    const ratingOptions = useMemo(
        () => ({
            ...singleFilter,
            userTopics: [id],
            groupBy: getAggregationFromUrl(queries[ReviewsAnalysisFilterEnum.Aggregation]),
        }),
        [id, queries, singleFilter]
    );

    const {result, isInProgress, processError} = useReviewStatsDynamic(ratingOptions, mainFilterKey);
    const {getLocalizedString} = useLocale();

    const lineChartData: LineDataType = useMemo(() => {
        if (!result) {
            return {
                labels: [],
                datasets: [],
            };
        }

        return {
            labels: result.map((item) => item.date),
            datasets: [
                {
                    values: result.map((item) => item.total || 0),
                    color: 'hsla(265, 32%, 73%, 1)',
                    label: getLocalizedString('REVIEWS_ANALYSIS__TOPIC__STATS__TOTAL_COUNT'),
                    fill: true,
                    borderWidth: 2,
                    backgroundColor: 'hsla(265, 32%, 73%, 0.3)',
                    tension: 0.5,
                },
            ],
        };
    }, [getLocalizedString, result]);

    if (processError) {
        return <WidgetError />;
    }

    return <TopicLineChart data={lineChartData} isInProgress={isInProgress} />;
}
