import {faListUl} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../provider/locale/localization';
import {FeedFormPage} from '../common/form-page/feed-form-page';
import {FeedResultsTypeEnum} from '../common/results/feed-results-const';

import {CentrifugeCompaniesImportNotification} from './companies-import-centrifuge-hook';

export function CompaniesImport(): JSX.Element {
    return (
        <>
            <FeedFormPage
                headerButton={
                    <NavigationLink to={appRoute.companyFeedManagement.path}>
                        <Button icon={<FontAwesomeIcon icon={faListUl} />}>
                            <span>
                                <Locale stringKey="FEED__IMPORT__CONNECTED_FEEDS__BUTTON" />
                            </span>
                        </Button>
                    </NavigationLink>
                }
                route={appRoute.companyImport.path}
                translations={{
                    breadcrumbTitle: 'FEED__IMPORT__TITLE',
                    header: 'FEED__IMPORT__TITLE',
                    pageTitle: 'FEED__IMPORT__TITLE',
                    submitButtonLabel: 'FEED__IMPORT__FORM__SUBMIT',
                    abortButtonLabel: 'FEED__VALIDATION__ABORT__BUTTON',
                    abortModalTitle: 'FEED__IMPORT__ABORT_MODAL__TITLE',
                    abortModalDescription: 'FEED__IMPORT__ABORT_MODAL__DESCRIPTION',
                    beforeProgressTitle: 'FEED__IMPORT__BEFORE_PROGRESS__TITLE',
                    progressTitle: 'FEED__IMPORT__PROGRESS__TITLE',
                }}
                type={FeedResultsTypeEnum.Import}
            />
            <CentrifugeCompaniesImportNotification />
        </>
    );
}
