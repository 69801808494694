import {SorterResult} from 'antd/es/table/interface';
import {useCallback, useState} from 'react';

/* eslint-disable no-undefined */
export function useAverageResponseTableSort(): {
    getSortOrder: (columnKey: string) => 'ascend' | 'descend' | undefined | null;
    setSorterOrder: <RecordType>(sorter: SorterResult<RecordType> | Array<SorterResult<RecordType>>) => void;
} {
    const [sorterState, setSorterState] = useState<null | {
        columnKey: string;
        sortOrder: 'ascend' | 'descend' | null;
    }>(null);

    const getSortOrder = useCallback(
        (columnKey: string): 'ascend' | 'descend' | undefined | null => {
            if (!sorterState) {
                return undefined;
            }

            return sorterState.columnKey === columnKey ? sorterState.sortOrder : undefined;
        },
        [sorterState]
    );

    const setSorterOrder = useCallback(
        <RecordType,>(sorter: SorterResult<RecordType> | Array<SorterResult<RecordType>>) => {
            if (!Array.isArray(sorter)) {
                setSorterState({
                    columnKey: sorter.column?.dataIndex as string,
                    sortOrder: sorter.order ?? null,
                });
            }
        },
        []
    );

    return {
        getSortOrder,
        setSorterOrder,
    };
}
