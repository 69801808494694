import {useCallback, useEffect, useRef, useState} from 'react';

import * as styles from '../../phone-illustration.scss';

type PropsType = {
    images: [string, ...Array<string>];
    autoplaySpeed?: number;
};

export function SafariImagesCarousel(props: PropsType): JSX.Element {
    const {images, autoplaySpeed = 2000} = props;

    const [index, setIndex] = useState<number>(0);

    const timerRef = useRef<number | null>(null);

    const handleCarousel = useCallback(() => {
        if (!timerRef.current) {
            timerRef.current = setInterval(() => {
                setIndex((previousIndex) => {
                    if (previousIndex === images.length - 1) {
                        return 0;
                    }

                    return previousIndex + 1;
                });
            }, autoplaySpeed);
        }
    }, [autoplaySpeed, images.length]);

    useEffect(() => {
        handleCarousel();

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        };
    }, [handleCarousel]);

    return <img alt="" className={styles.PhoneIllustration_image} src={images[index]} />;
}
