import {appRoute} from '../../../../../app-route';
import {ActivationPage} from '../../../../../layout/activation-page/activation-page';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {FeaturesEnum} from '../../../../../service/user/user-type';

export function ActivationBasePage(): JSX.Element {
    const {featureActivation} = useDocumentationLinks();

    return (
        <ActivationPage
            breadCrumbList={[
                {
                    path: appRoute.reviewsAnalysisDashboard.path,
                    titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                },
            ]}
            link={featureActivation[FeaturesEnum.reviewAnalyticsBase]}
            title={<Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS" />}
        />
    );
}
