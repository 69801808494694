import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {MutableRefObject, useEffect} from 'react';

import {gray7} from '../../../../../../css/var-export.scss';
import {CompanyInfo} from '../company-info/company-info';

import * as styles from './map-popup.scss';

type PropsType = {
    open: boolean;
    companyId: number | null;
    onClose: () => void;
    popupRef: MutableRefObject<HTMLDivElement | null>;
};

export function MapPopup(props: PropsType): JSX.Element | null {
    const {open, companyId, onClose, popupRef} = props;

    useEffect(() => {
        function handleEsc(event: KeyboardEvent): void {
            if (event.key === 'Escape') {
                onClose();
            }
        }

        document.addEventListener('keydown', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [open, onClose]);

    if (!(open && companyId)) {
        return null;
    }

    return (
        <div className={styles.MapPopup} ref={popupRef}>
            <Button className={styles.MapPopup_action} color={gray7} onClick={onClose} type="text">
                <FontAwesomeIcon icon={faTimes} />
            </Button>
            <CompanyInfo companyId={companyId} />
        </div>
    );
}
