import {faCircleExclamation, faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Progress, Tooltip} from 'antd';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {PermissionNameEnum} from '../../../../service/company/company-type';
import {useCompanyInfo} from '../../../../service/dashboard/dashboard-hook';
import {classNames} from '../../../../util/css';

import {CompanyInfoEmptyFieldsMap} from './data-completeness-helper';
import * as styles from './data-completeness.scss';

type PropsType = {
    companyId: number;
    permissions: Array<PermissionNameEnum>;
};

export function DataCompleteness(props: PropsType): JSX.Element {
    const {companyId, permissions} = props;
    const {
        result: companyInfoResult,
        isInProgress: companyInfoIsInProgress,
        processError: companyInfoProcessError,
    } = useCompanyInfo(companyId);

    const {getLocalizedString} = useLocale();

    if (companyInfoProcessError) {
        return <AlertFallback />;
    }

    if (companyInfoIsInProgress || !companyInfoResult) {
        return <Spinner />;
    }

    const hasEmptyFields: boolean = companyInfoResult.emptyFields.length > 0;

    return (
        <div
            className={classNames(styles.data_completeness__container, {
                [styles.data_completeness__has_empty_fields]: !hasEmptyFields,
            })}
        >
            {hasEmptyFields && (
                <div className={styles.data_completeness__base_wrapper}>
                    <div className={styles.data_completeness__header__wrapper}>
                        <span className={styles.data_completeness__error_header__icon}>
                            <FontAwesomeIcon icon={faCircleExclamation} />
                        </span>
                        <div className={styles.data_completeness__error_header__title}>
                            {getLocalizedString('TABLE__HEADER__ERROR_TITLE')}
                        </div>
                    </div>
                    <div className={styles.data_completeness__blocks__container}>
                        {companyInfoResult.emptyFields.map((field) => (
                            <div className={styles.data_completeness__blocks__block} key={field}>
                                <div className={styles.data_completeness__blocks__text}>
                                    {getLocalizedString(CompanyInfoEmptyFieldsMap[field])}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div
                className={classNames(styles.data_completeness__percents, {
                    [styles.data_completeness__has_empty_fields]: !hasEmptyFields,
                })}
            >
                {hasEmptyFields && (
                    <Tooltip placement="bottom" title={getLocalizedString('TABLE__HEADER__CARD_COMPLETENESS')}>
                        <div>
                            <Progress percent={companyInfoResult.percent} steps={8} />
                        </div>
                    </Tooltip>
                )}

                {permissions?.includes(PermissionNameEnum.update) && (
                    <Button
                        className={styles.data_completeness__edit__link}
                        icon={<FontAwesomeIcon className={styles.data_completeness__edit__link__icon} icon={faEdit} />}
                        type="link"
                    >
                        <NavigationLink to={generatePath(appRoute.editCompany.path, {companyId})}>
                            <Locale stringKey="TABLE__HEADER__COMPANY_EDIT" />
                        </NavigationLink>
                    </Button>
                )}
            </div>
        </div>
    );
}
