import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {throttle} from 'lodash';
import {useEffect, useState} from 'react';

import {classNames} from '../../../../../util/css';

import * as styles from './scroll-button.scss';

const scrollElement = document.documentElement;

function scrollTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
}

function isTopPosition() {
    return scrollElement.scrollTop === 0;
}

function scrollBottom() {
    const {scrollHeight, clientHeight} = scrollElement;

    window.scroll({top: scrollHeight - clientHeight, left: 0, behavior: 'smooth'});
}

function isBottomPosition() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
    return Math.abs(scrollElement.scrollHeight - scrollElement.clientHeight - scrollElement.scrollTop) < 1;
}

export function ScrollButton(): JSX.Element {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isScrollUp, setIsScrollUp] = useState<boolean>(false);

    useEffect(() => {
        let timer: number | null = null;
        let previousPosition = scrollElement.scrollTop;

        const scrollHandler = throttle(function scrollHandler() {
            if (timer) {
                clearTimeout(timer);
            }

            const currentPosition = scrollElement.scrollTop;

            setIsScrollUp(currentPosition < previousPosition);
            previousPosition = currentPosition;

            if (isTopPosition()) {
                setIsVisible(false);
                return;
            }

            if (isBottomPosition()) {
                setIsScrollUp(true);
            }

            setIsVisible(true);
            timer = setTimeout(() => {
                if (timer) {
                    clearTimeout(timer);
                }

                setIsVisible(false);
            }, 3000);
        }, 300);

        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    return (
        <div className={styles.scroll_button__container}>
            <button
                className={classNames(styles.scroll_button, {
                    [styles.scroll_button__hidden]: !isVisible,
                })}
                onClick={isScrollUp ? scrollTop : scrollBottom}
                type="button"
            >
                <FontAwesomeIcon
                    className={classNames(styles.scroll_button__icon, {
                        [styles.scroll_button__icon_up]: isScrollUp,
                    })}
                    icon={faArrowDown}
                />
            </button>
        </div>
    );
}
