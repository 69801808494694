import {Badge, BadgeProps, Button, Dropdown, MenuProps} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {isFunction} from 'lodash';
import React, {Dispatch, SetStateAction, useState} from 'react';

import * as styles from './header-buttons.scss';

type ExternalVisibilityControlPropsType = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

type PropsType = Omit<ButtonProps, 'icon'> & {
    menu: MenuProps | ((props: ExternalVisibilityControlPropsType) => MenuProps);
    icon: JSX.Element;
    text?: JSX.Element | null;
    badge?: {
        active: boolean;
        offset?: BadgeProps['offset'];
        count?: number;
        size?: BadgeProps['size'];
        overflowCount?: BadgeProps['overflowCount'];
    };
    onOpen?: () => void;
    destroyPopupOnHide?: boolean;
};

export function HeaderDropdownButton(props: PropsType): JSX.Element {
    const {menu, icon, text, badge, onOpen, destroyPopupOnHide, ...buttonProps} = props;

    const [open, setOpen] = useState(false);

    return (
        <Dropdown
            arrow={{pointAtCenter: !text}}
            destroyPopupOnHide={destroyPopupOnHide}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            menu={isFunction(menu) ? menu({open, setOpen}) : menu}
            onOpenChange={isFunction(menu) ? setOpen : undefined} // eslint-disable-line no-undefined
            open={isFunction(menu) ? open : undefined} // eslint-disable-line no-undefined
            placement="bottomRight"
            trigger={['click']}
        >
            <Button
                {...buttonProps} /* eslint-disable-line react/jsx-props-no-spreading */
                icon={
                    badge?.active ? (
                        <Badge
                            className={styles.badge}
                            count={badge?.count}
                            dot={badge?.count === undefined} // eslint-disable-line no-undefined
                            offset={badge?.offset}
                            overflowCount={badge.overflowCount}
                            size={badge.size}
                        >
                            {icon}
                        </Badge>
                    ) : (
                        icon
                    )
                }
                onClick={(event) => {
                    buttonProps.onClick?.(event as React.MouseEvent<HTMLButtonElement, MouseEvent>);

                    if (!open) {
                        onOpen?.();
                    }
                }}
            >
                {text}
            </Button>
        </Dropdown>
    );
}
