import {useMemo} from 'react';

import {DoughnutChart} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart';
import {DoughnutStatistic} from '../../../../../../layout/doughnut-statistic/doughnut-statistic';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    OnlinePresenceSourcesType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../../../service/online-presence/online-presence-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {SinglePeriodStatsTable} from '../../../single-period-stats/single-period-stats-table';
import {
    getSourceOfTransitionsChartOptions,
    SOURCE_OF_TRANSITION_COLOR_MAP,
    SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP,
    SOURCE_OF_TRANSITION_ORDER,
    SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP,
} from '../../source-of-transition-helper';
import {MainPeriodType} from '../../source-of-transition-type';

import * as styles from './source-of-transition-single.scss';

type PropsType = {
    data: OnlinePresenceSourcesType;
    filter: OnlinePresenceV2FilterStateType;
};

export function SourceOfTransitionSingle(props: PropsType): JSX.Element {
    const {data, filter} = props;

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    const {
        [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: periodStart,
        [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: periodEnd,
    } = filter;

    const mainPeriodData: Array<MainPeriodType> = useMemo(
        () =>
            data
                .sort(
                    (item1, item2) => SOURCE_OF_TRANSITION_ORDER[item1.label] - SOURCE_OF_TRANSITION_ORDER[item2.label]
                )
                .map(({label, current}) => ({
                    titleKey: SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP[label],
                    shortTitleKey: SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP[label],
                    color: SOURCE_OF_TRANSITION_COLOR_MAP[label],
                    label: getLocalizedString(SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP[label]),
                    value: current,
                })),
        [data, getLocalizedString]
    );

    return (
        <>
            <div className={styles.SourceOfTransitionSingle}>
                <div className={styles.SourceOfTransitionSingle_chart}>
                    <DoughnutChart
                        chartOptions={getSourceOfTransitionsChartOptions(
                            {
                                cutout: '30%',
                            },
                            {
                                data: mainPeriodData,
                                title: `${getFormattedDateTime(periodStart)} - ${getFormattedDateTime(periodEnd)}`,
                            }
                        )}
                        data={mainPeriodData}
                        normalized
                    />
                </div>
                <div className={styles.SourceOfTransitionSingle_legend}>
                    {mainPeriodData.map((dataEntry) => (
                        <DoughnutStatistic
                            color={dataEntry.color}
                            key={dataEntry.label}
                            label={getLocalizedString(dataEntry.shortTitleKey)}
                            value=""
                        />
                    ))}
                </div>
            </div>
            <SinglePeriodStatsTable
                metricsNameHeadKey="ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION"
                rows={mainPeriodData.map((entry: MainPeriodType) => ({
                    ...entry,
                    titleKey: entry.titleKey,
                }))}
            />
        </>
    );
}
