import {useMemo} from 'react';

import {ChartRenderer} from '../chart-renderer/chart-renderer';
import {legendMarginPlugin} from '../chart-renderer/chart-renderer-helper';
import {getStripesPattern} from '../patterns/stripes';

import {multiSourceChartOptions} from './multi-source-chart-helper';
import {
    ChartJsMultiSourceDataSetType,
    ChartJsMultiSourceDataType,
    ChartJsMultiSourceOptionsType,
    MultiSourceDataSetType,
    MultiSourceDataType,
} from './multi-source-chart-type';

type PropsType = {
    data: MultiSourceDataType | null;
    options?: ChartJsMultiSourceOptionsType;
};

export function MultiSourceChart(props: PropsType): JSX.Element {
    const {data, options} = props;

    const chartJsData: ChartJsMultiSourceDataType = useMemo<ChartJsMultiSourceDataType>(
        (): ChartJsMultiSourceDataType => ({
            labels: data?.labels || [],
            datasets:
                data?.datasets.map(
                    (dataset: MultiSourceDataSetType): ChartJsMultiSourceDataSetType => ({
                        label: dataset.label,
                        data: dataset.values,
                        backgroundColor:
                            dataset.stripes && dataset.color ? getStripesPattern(dataset.color) : dataset.color,
                        borderColor: dataset.borderColor,
                        hoverBackgroundColor:
                            dataset.stripes && dataset.color ? getStripesPattern(dataset.color) : dataset.color,
                        stack: dataset.stack,
                        borderRadius: dataset.borderRadius,
                        type: dataset.type || 'bar',
                        yAxisID: dataset.yAxisID,
                        fill: dataset.fill,
                        pointRadius: dataset.pointRadius,
                        tension: dataset.tension,
                        pointHitRadius: dataset.pointHitRadius,
                        pointBackgroundColor: dataset.pointBackgroundColor,
                        order: dataset.order,
                        borderDash: dataset.dashed ? [5, 2] : [],
                    })
                ) || [],
        }),
        [data]
    );

    return (
        <ChartRenderer<'bar', Array<number>, string>
            data={chartJsData}
            options={options || multiSourceChartOptions}
            plugins={[legendMarginPlugin]}
            type="bar"
        />
    );
}
