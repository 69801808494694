import {DefaultOptionType} from 'antd/es/select';

import {Locale} from '../../../../../../../../provider/locale/localization';

export enum CompanyNameStyleEnum {
    Address = 'address',
    NameAndAddress = 'name_and_address',
    Name = 'name',
}

export const CompanyNameStyleOptions: Array<DefaultOptionType> = [
    {
        value: CompanyNameStyleEnum.Address,
        label: <Locale stringKey="REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__ADDRESS" />,
    },
    {
        value: CompanyNameStyleEnum.Name,
        label: <Locale stringKey="REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__NAME_OPTION" />,
    },
    {
        value: CompanyNameStyleEnum.NameAndAddress,
        label: <Locale stringKey="REVIEW_GENERATOR__FORM__MULTI_SURVEY_SETTINGS__NAME_AND_ADDRESS_OPTION" />,
    },
];
