import {TableColumnsType} from 'antd';
import {SortOrder} from 'antd/lib/table/interface';

import {
    ReviewAnalysisCompaniesSortKeyType,
    ReviewAnalysisCompaniesSortType,
    ReviewAnalysisCompaniesSortValueEnum,
} from '../../../../../service/reivew-analysis/review-companies/review-companies-type';
import {SavedSortStateType} from '../../../../../util/save-sort-table-state/save-sort-table-type';
import {
    getAvgResponseDelayColumn,
    getCompanyCodeColumn,
    getCompanyColumn,
    getCompanyGroupsColumn,
    getRateColumn,
    getRepliedWithPercentageColumn,
    getSentimentColumn,
    getTotalColumn,
    getTrendColumn,
} from '../../components/table/reviews-analysis-table-helper';

import {ReviewCompaniesTableDataType} from './companies-page-type';

export const companiesPageTableColumn: TableColumnsType<ReviewCompaniesTableDataType> = [
    getCompanyColumn<ReviewCompaniesTableDataType>(),
    {
        ...getRateColumn<ReviewCompaniesTableDataType>(),
        sorter: true,
    },
    getCompanyCodeColumn<ReviewCompaniesTableDataType>(),
    getCompanyGroupsColumn<ReviewCompaniesTableDataType>(),
    getTotalColumn<ReviewCompaniesTableDataType>(),
    getRepliedWithPercentageColumn<ReviewCompaniesTableDataType>(),
    getAvgResponseDelayColumn<ReviewCompaniesTableDataType>(),
    getSentimentColumn<ReviewCompaniesTableDataType>(),
    getTrendColumn<ReviewCompaniesTableDataType>(),
];

export function serializeSortOrder(order?: SortOrder): ReviewAnalysisCompaniesSortValueEnum | null {
    if (order === 'ascend') {
        return ReviewAnalysisCompaniesSortValueEnum.Asc;
    }

    if (order === 'descend') {
        return ReviewAnalysisCompaniesSortValueEnum.Desc;
    }

    return null;
}

export function formatInitialStateSort(sortData: SavedSortStateType | null): ReviewAnalysisCompaniesSortType | null {
    const serializedOrder = serializeSortOrder(sortData?.order as SortOrder);

    if (!sortData || serializedOrder === null || sortData.field === null) {
        return null;
    }

    const sortKey = String(sortData.field);

    return `${sortKey as ReviewAnalysisCompaniesSortKeyType}.${serializedOrder}`;
}
