import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Button, Modal, Space, Tag} from 'antd';
import {sum} from 'lodash';

import {Text} from '../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {LocalePlural} from '../../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../../provider/locale/localization';
import {useFeedManagementList} from '../../../../../../service/companies-import/companies-import-list';
import {classNames} from '../../../../../../util/css';

import {LINKS} from './feed-routes-popup-const';
import * as styles from './feed-routes-popup.scss';

type PropsType = {
    onClose: () => void;
};

export function FeedRoutesPopup(props: PropsType): JSX.Element {
    const {onClose} = props;

    const {data} = useFeedManagementList();
    const {companyName} = useDomainConfig();

    const errorsCount = sum(data?.results.map(({importResultInfo}) => importResultInfo.validationError));
    const hasCriticalErrors = Boolean(
        data?.results.some(({importResultInfo}) => importResultInfo.criticalError && importResultInfo.criticalError > 0)
    );

    return (
        <Modal
            footer={
                <Button onClick={onClose}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>
            }
            onCancel={onClose}
            open
            title={<Locale stringKey="FEED__ROUTES_MODAL__TITLE" />}
        >
            <Space className={styles.FeedRoutesPopup_space} direction="vertical" size={12}>
                {LINKS.map(({label, description, link, icon, canHaveErrors}) => (
                    <NavigationLink className={styles.FeedRouteLink} key={label} to={link ?? '/'}>
                        <Avatar className={styles.FeedRouteLink_icon} icon={<FontAwesomeIcon icon={icon} />} />

                        <Space className={styles.FeedRouteLink_content} direction="vertical" size={2}>
                            <strong className={styles.FeedRouteLink_label}>
                                <Locale stringKey={label} />

                                {canHaveErrors && (
                                    <Tag
                                        className={classNames(styles.FeedRouteLink_tag, {
                                            [styles.FeedRouteLink_tag__critical]: hasCriticalErrors,
                                        })}
                                    >
                                        <LocalePlural
                                            count={errorsCount}
                                            fewKey="FEED__ERROR__FEW"
                                            manyKey="FEED__ERROR__MANY"
                                            singularKey="FEED__ERROR__SINGLE"
                                            valueMap={{count: errorsCount}}
                                        />
                                    </Tag>
                                )}
                            </strong>

                            <Text secondary stringKey={description} valueMap={{companyName}} />
                        </Space>

                        <FontAwesomeIcon className={styles.FeedRouteLink_arrowIcon} icon={faArrowRight} />
                    </NavigationLink>
                ))}
            </Space>
        </Modal>
    );
}
