import {useEffect, useState} from 'react';

import {DEFAULT_DEBOUNCE_WAIT_MS} from './debouce-hook-const';

export function useDebounce(beforeCallback: () => void, callback: () => void): void {
    const [callbackTimeout, setCallbackTimeout] = useState<number | null>(null);

    useEffect(() => {
        beforeCallback();

        if (callbackTimeout !== null) {
            clearTimeout(callbackTimeout);
        }

        setCallbackTimeout(setTimeout(callback, DEFAULT_DEBOUNCE_WAIT_MS));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, beforeCallback]);
}

export function useDebouncedValue<TValue, TDefaultValue extends TValue>(
    value: TValue,
    defaultValue?: TDefaultValue,
    debounceWaitMS: number = DEFAULT_DEBOUNCE_WAIT_MS
): TValue | TDefaultValue {
    const [debouncedValue, setDebouncedValue] = useState<TValue | TDefaultValue>(defaultValue || value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), debounceWaitMS);

        return () => clearTimeout(handler);
    }, [value]);

    return debouncedValue;
}
