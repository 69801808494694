import {faFile} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Modal, Tooltip} from 'antd';
import {SyntheticEvent, useState} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../../../../service/analytics/analytics';
import {ResponseTemplateDataType} from '../../../../../../../service/reviews/reviews-response-templates';
import {classNames} from '../../../../../../../util/css';
import {IdNumberType} from '../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';

import * as styles from './review-complaint-popup.scss';

const {TextArea} = Input;

type PropsType = {
    brandIsTest: boolean;
    className?: string;
    isOpen: boolean;
    onCancel: () => void;
    onOk: () => void;
    complaintText: string;
    reviewId: number;
    setComplaintText: (complaintText: string) => void;
    isLoading?: boolean;
};

export function ReviewComplaintPopup(props: PropsType): JSX.Element {
    const {className, complaintText, setComplaintText, onCancel, onOk, isOpen, brandIsTest, reviewId, isLoading} =
        props;
    const {getLocalizedString} = useLocale();

    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );

    function handleSubmit() {
        track(AnalyticsTarget.ReviewsManagement.Reviews.CreateComplaint);

        onOk();
    }

    return (
        <>
            <Modal
                className={classNames(styles.ReviewComplaintPopup, className)}
                footer={
                    <div className={styles.ReviewComplaintPopup_footer}>
                        <Button className={styles.ReviewComplaintPopup_footerButton} key="close" onClick={onCancel}>
                            <Locale stringKey="POPUP__BUTTON__CLOSE" />
                        </Button>
                        <Button
                            className={styles.ReviewComplaintPopup_footerButton}
                            disabled={complaintText.trim().length === 0 || brandIsTest}
                            key="send"
                            loading={isLoading}
                            onClick={handleSubmit}
                            type="primary"
                        >
                            <Locale stringKey="POPUP__BUTTON__SEND" />
                        </Button>
                    </div>
                }
                onCancel={onCancel}
                open={isOpen}
                title={<Locale stringKey="REVIEWS__COMPLAINT_POPUP__SUBMITTING_A_COMPLAINT" />}
            >
                <TextArea
                    className={styles.ReviewComplaintPopup_textarea}
                    onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                        setComplaintText(event.currentTarget.value);
                    }}
                    placeholder={getLocalizedString('REVIEWS__COMPLAINT_POPUP__ENTER_YOUR_COMPLAINT_HERE')}
                    rows={5}
                    size="large"
                    value={complaintText}
                />
                <div className={styles.ReviewComplaintPopup_controls}>
                    <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_ANSWER__TEMPLATE__HINT" />}>
                        <button
                            className={styles.ReviewComplaintPopup_autoReplyButton}
                            onClick={() => setIsSelectTemplatePopupOpen(true)}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faFile} />
                        </button>
                    </Tooltip>
                </div>
            </Modal>
            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        setIsSelectTemplatePopupOpen(false);

                        const [selectedAutoReply] = selectedResponseTemplateList;

                        if (!selectedAutoReply) {
                            return;
                        }

                        const {message} = selectedAutoReply;

                        setComplaintText(message);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </>
    );
}
