import {Button, Checkbox, DatePicker, DatePickerProps, Form, Input, Row, Select, Switch} from 'antd';
import dayjs from 'dayjs';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {appRoute} from '../../../app-route';
import {MultipleDatePicker} from '../../../component/multiple-date-picker/multiple-date-picker';
import {ALL_DAYS_ID} from '../../../const';
import {AdditionalInfo} from '../../../layout/additional-info/additional-info';
import {CompanyMultiSelectV2} from '../../../layout/company-multi-select-v2/company-multi-select-v2';
import {FormFieldset} from '../../../layout/form/form-fieldset/form-fieldset';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {RatingMultiSelect} from '../../../layout/rating-multi-select/rating-multi-select';
import {SelectCatalogOption} from '../../../layout/select/select-catalog-option';
import {selectDayOfWeekContent} from '../../../layout/select/select-helper';
import {TimeRangePicker} from '../../../layout/time-range-picker/time-range-picker';
import {useCatalogInfo, useCatalogsRules} from '../../../provider/catalogs/catalogs-hook';
import {CatalogRulesType} from '../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {TimezoneType} from '../../../service/api/api-type';
import {useTimezoneLibrary} from '../../../service/api-hook/api-hook';
import {CompaniesSelectorFeatureEnum} from '../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../service/feature-companies/feature-companies-hook';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {RatingType} from '../../../service/reviews/reviews-rating';
import {
    AutoReplyDataErrorType,
    AutoReplyErrorEnum,
    AutoReplyToReviewDataType,
    defaultAutoReplyInfoErrors,
    defaultAutoReplyToReviewData,
    fetchAutoReplayCreate,
    fetchAutoReplayDelete,
    fetchAutoReplayUpdate,
} from '../../../service/reviews/reviews-response-templates';
import {classNames} from '../../../util/css';
import {ErrorWithStatus, isErrorHasStatusMessage} from '../../../util/error';
import {noop} from '../../../util/function';
import {getMapFromObject} from '../../../util/object';
import {IdNumberType} from '../../../util/type';
import {useUrl} from '../../../util/url-hook/url-hook';

import {AutoReplyTemplatePopup} from './auto-reply-form-popup/auto-reply-template-popup';
import * as styles from './auto-reply-form.scss';

const {TextArea} = Input;
const {Option} = Select;

type PropsType = {
    mode: 'edit' | 'create';
    className?: string;
    autoReplyData: AutoReplyToReviewDataType;
    autoReplyId?: string;
};

type ReviewsTimeBucketType = {
    start: string;
    end: string;
};

const minimumSelectedTemplatesForOrderButtonEnable = 2;

// eslint-disable-next-line complexity, max-statements, sonarjs/cognitive-complexity
export function AutoReplyForm(props: PropsType): JSX.Element {
    const {className, mode, autoReplyData, autoReplyId} = props;

    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState(false);

    const [form] = Form.useForm();
    const isEdit = mode === 'edit';
    const isCreate = mode === 'create';
    const {getLocalizedString} = useLocale();
    const {modal} = useModal();
    const timezoneLibrary = useTimezoneLibrary();
    const timezoneList: Array<TimezoneType> = timezoneLibrary.result || [];
    const [temporaryDate, setTemporaryDate] = useState<boolean>(autoReplyData.mode === 'manual');

    const {getCompaniesMultiselectValidationRule, maxLengthFieldRule, minLengthFieldRule, requiredFieldRule} =
        useFormRules();

    const [infiniteDate, setInfiniteDate] = useState<boolean>(isCreate);

    const {result: catalogsResult, isInProgress: catalogsIsInProgress} = useCatalogsRules({autoReplySupported: true});
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>(
        autoReplyData.responseTemplates
    );
    const [defaultSelectedTemplateList, setDefaultSelectedTemplateList] = useState<Array<IdNumberType>>(
        autoReplyData.responseTemplates
    );
    const [enabledForDates, setEnabledForDates] = useState<Array<string>>(autoReplyData.enabledForDates || []);
    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();

    const catalogsHookResult: Array<CatalogRulesType> = useMemo(
        (): Array<CatalogRulesType> => catalogsResult || [],
        [catalogsResult]
    );

    useEffect(() => {
        form.resetFields([
            'name',
            'description',
            'catalogs',
            'reviewsRatings',
            'reviewsWithBody',
            'datePeriod',
            'dateStart',
            'reviewsTimeBucket',
            'templateTimeZone',
            'templateSelectionStrategy',
            'is-test',
            'reviewsWeekdaysIndexes',
            'enabled',
        ]);
    }, [autoReplyData, form]);

    useEffect(() => {
        if (isEdit) {
            setInfiniteDate(Boolean(autoReplyData.enabledFrom && !autoReplyData.enabledTo));
        }

        setSelectedTemplateList(autoReplyData.responseTemplates);
        setDefaultSelectedTemplateList(autoReplyData.responseTemplates);
        setTemporaryDate(autoReplyData.mode === 'manual');
        setEnabledForDates(autoReplyData.enabledForDates || []);
    }, [autoReplyData, isEdit]);

    const companiesSelector = useCompaniesSelector({
        feature: CompaniesSelectorFeatureEnum.ReviewSuggests,
        resourceId: autoReplyId ? String(autoReplyId) : null,
        initialCompaniesCount: autoReplyData.companies?.length || 0,
    });

    const {selectedCount, selectorId, initializeSelector} = companiesSelector;
    const {getCatalogName} = useCatalogInfo();

    useEffect(() => {
        initializeSelector();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        form.setFieldsValue({companies: selectorId});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorId]);

    const handleDelete = useCallback(() => {
        modal.confirm({
            maskClosable: true,
            title: <Locale stringKey="AUTO_REPLIES__REMOVING_A_RULE" />,
            content: (
                <Locale
                    stringKey="AUTO_REPLIES__THE_RULE_RULE_NAME_WILL_BE_REMOVED_CONFIRM"
                    valueMap={{
                        ruleNames: autoReplyData.name,
                    }}
                />
            ),
            okText: <Locale stringKey="TEXT__APPLY" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            cancelButtonProps: {type: 'primary'},
            okButtonProps: {type: 'default', danger: true},
            onOk: async () => {
                const result = await fetchAutoReplayDelete(autoReplyId ?? '');

                if (result instanceof Error) {
                    snackbar.error(
                        isErrorHasStatusMessage<ErrorWithStatus>(result) ? (
                            <>{String(result.jsonData.status)}</>
                        ) : (
                            <Locale stringKey="AUTO_REPLIES__NOTIFICATION__ERROR_DELETE" />
                        )
                    );
                } else {
                    snackbar.success(<Locale stringKey="AUTO_REPLIES__NOTIFICATION__SUCCESS_DELETE" />);
                    pushUrl(appRoute.reviewsManagementAutoReplies.path);
                }
            },
        });
    }, [modal, autoReplyData.name, autoReplyId, snackbar, pushUrl]);

    const getReviewsTimeBucket = useCallback((): ReviewsTimeBucketType | null => {
        const checkTime = form.getFieldValue('reviewsTimeBucket') ? form.getFieldValue('reviewsTimeBucket')[0] : null;

        if (checkTime) {
            return {
                start: dayjs(checkTime).format('HH:mm'),
                end: dayjs(form.getFieldValue('reviewsTimeBucket')[1]).format('HH:mm'),
            };
        }

        return null;
    }, [form]);

    const [errors, setErrors] = useState<AutoReplyDataErrorType>(defaultAutoReplyInfoErrors);

    const validateFields = useCallback((): boolean => {
        const datePeriod = form.getFieldValue('datePeriod');
        const dateStart = form.getFieldValue('dateStart');

        const enabledFrom = datePeriod && form.getFieldValue('datePeriod')[0];
        const enabledTo = datePeriod && form.getFieldValue('datePeriod')[1];

        const newErrors: AutoReplyDataErrorType = {
            selectTemplates: selectedTemplateList.length === 0,
            datePeriod: !temporaryDate && !infiniteDate && !(enabledFrom && enabledTo),
            dateStart: !temporaryDate && infiniteDate && !dateStart,
        };

        setErrors(newErrors);

        return Object.values(newErrors).some(Boolean);
    }, [selectedTemplateList, temporaryDate, infiniteDate, form]);

    function updateErrors(key: AutoReplyErrorEnum, value: boolean): void {
        setErrors({...errors, [key]: value});
    }

    // eslint-disable-next-line complexity, sonarjs/cognitive-complexity, max-statements
    const onFinishSuccess = useCallback(async () => {
        if (!validateFields()) {
            const {
                name,
                description,
                isDeleted,
                catalogs,
                templateTimeZone,
                templateSelectionStrategy,
                reviewsWeekdaysIndexes,
                reviewsRatings,
            } = getMapFromObject<AutoReplyToReviewDataType>(form.getFieldsValue(), defaultAutoReplyToReviewData);

            const catalogIds = new Set(catalogs.map((catalog) => catalog.id || catalog));

            track(
                AnalyticsTarget.ReviewsManagement.AutoReplies.CreateRules,
                catalogsHookResult
                    .filter((catalog: CatalogRulesType) => catalogIds.has(catalog.id))
                    .map((catalog: CatalogRulesType) => getCatalogName(catalog.id))
            );

            const datePeriod = form.getFieldValue('datePeriod');
            const dateStart = form.getFieldValue('dateStart');

            const enabledFrom = infiniteDate ? dateStart : datePeriod && form.getFieldValue('datePeriod')[0];
            const enabledTo = infiniteDate ? null : datePeriod && form.getFieldValue('datePeriod')[1];

            const reviewsTimeBucket = getReviewsTimeBucket();

            const weekdaysIndexes = reviewsWeekdaysIndexes[0] === 0 ? [1, 2, 3, 4, 5, 6, 7] : reviewsWeekdaysIndexes;
            const reviewsWithBody = form.getFieldValue('reviewsWithBody');

            const data = {
                id: autoReplyData.id,
                isDeleted,
                name,
                description,
                catalogs,
                reviewsWithBody: reviewsWithBody === null ? null : JSON.parse(`${reviewsWithBody}`),
                reviewsTimeBucket,
                templateTimeZone,
                responseTemplates: selectedTemplateList.map(({id}) => id),
                templateSelectionStrategy: templateSelectionStrategy || 'random',
                enabledFrom: !temporaryDate && enabledFrom ? dayjs(enabledFrom).format('YYYY-MM-DD') : null,
                enabledTo: !temporaryDate && enabledTo ? dayjs(enabledTo).format('YYYY-MM-DD') : null,
                enabledForDates: temporaryDate && enabledForDates.length > 0 ? enabledForDates : null,
                isTest: form.getFieldValue('is-test'),
                reviewsWeekdaysIndexes: weekdaysIndexes.length > 0 ? weekdaysIndexes : null,
                mode: temporaryDate ? 'manual' : 'regular',
                enabled: form.getFieldValue('enabled'),
                reviewsRatings,
                uuid: selectorId,
            };

            setIsSaving(true);

            try {
                await (isCreate ? fetchAutoReplayCreate(data) : fetchAutoReplayUpdate(data, autoReplyId ?? ''));

                snackbar.success(
                    isCreate ? (
                        <Locale stringKey="AUTO_REPLIES__NOTIFICATION__SUCCESS_CREATE" />
                    ) : (
                        <Locale stringKey="AUTO_REPLIES__NOTIFICATION__SUCCESS_UPDATE" />
                    )
                );
                pushUrl(appRoute.reviewsManagementAutoReplies.path);
            } catch (error) {
                if (isErrorHasStatusMessage<ErrorWithStatus>(error)) {
                    snackbar.error(error.jsonData.status);
                } else {
                    snackbar.error(
                        isCreate ? (
                            <Locale stringKey="AUTO_REPLIES__NOTIFICATION__ERROR_CREATE" />
                        ) : (
                            <Locale stringKey="AUTO_REPLIES__NOTIFICATION__ERROR_UPDATE" />
                        )
                    );
                }

                setIsSaving(false);
            }
        } else {
            setIsSaving(false);
            snackbar.error(<Locale stringKey="AUTO_REPLIES__FORM__ERROR" />);
        }
    }, [
        validateFields,
        form,
        catalogsHookResult,
        infiniteDate,
        getReviewsTimeBucket,
        autoReplyData.id,
        selectedTemplateList,
        temporaryDate,
        enabledForDates,
        selectorId,
        isCreate,
        autoReplyId,
        snackbar,
        pushUrl,
    ]);

    function onFinishFailed() {
        validateFields();
        snackbar.error(<Locale stringKey="AUTO_REPLIES__FORM__ERROR" />);
    }

    function handleDaysOfWeekChange(days: Array<number>) {
        const lastSelected = days[days.length - 1];

        if (lastSelected === ALL_DAYS_ID) {
            form.setFieldsValue({reviews_weekdays_indexes: [ALL_DAYS_ID]});
        } else {
            form.setFieldsValue({
                reviews_weekdays_indexes: days.filter((day) => day !== ALL_DAYS_ID),
            });
        }
    }

    useEffect(() => {
        if (catalogsHookResult.length > 0 && autoReplyData.catalogs.length > 0) {
            const selectedCatalogs = catalogsHookResult
                .map(({id: fetchedCatalogId}): number => fetchedCatalogId)
                .filter((catalogId: number) => {
                    return autoReplyData.catalogs
                        .map(({id: savedCatalogId}): number => savedCatalogId)
                        .includes(catalogId);
                });

            form.setFieldsValue({catalogs: selectedCatalogs});
        }
    }, [autoReplyData, catalogsHookResult, form]);

    const isSelectionStrategyDisabled = selectedTemplateList.length < minimumSelectedTemplatesForOrderButtonEnable;

    // eslint-disable-next-line func-style
    const disabledDate: DatePickerProps['disabledDate'] = (current) => {
        return current && current <= dayjs().subtract(1, 'years');
    };

    return (
        <Form
            className={classNames(styles.AutoReplyForm, className)}
            form={form}
            layout="vertical"
            name="auto-replay"
            onFinish={onFinishSuccess}
            onFinishFailed={onFinishFailed}
        >
            <FormFieldset legend={<Locale stringKey="AUTO_REPLIES__FORM__BASIC_INFORMATION" />}>
                <Form.Item
                    initialValue={autoReplyData.name}
                    label={<Locale stringKey="AUTO_REPLIES__FORM__TITLE" />}
                    name="name"
                    rules={[requiredFieldRule, minLengthFieldRule(2), maxLengthFieldRule(50)]}
                >
                    <Input
                        placeholder={getLocalizedString('AUTO_REPLIES__FORM__ENTER_A_NAME_FOR_THE_RULE')}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    initialValue={autoReplyData.description || ''}
                    label={<Locale stringKey="AUTO_REPLIES__FORM__DESCRIPTION" />}
                    name="description"
                >
                    <TextArea
                        placeholder={getLocalizedString('AUTO_REPLIES__FORM__ENTER_A_DESCRIPTION')}
                        size="large"
                    />
                </Form.Item>
            </FormFieldset>

            <FormFieldset legend={<Locale stringKey="AUTO_REPLIES__FORM__FILTERS" />}>
                <Form.Item
                    label={
                        <div className={styles.AutoReplyForm_requiredAsterisk}>
                            <Locale stringKey="AUTO_REPLIES__FORM__COMPANIES" />
                        </div>
                    }
                    name="companies"
                    rules={[getCompaniesMultiselectValidationRule(selectedCount)]}
                    validateFirst={false}
                    validateTrigger="onSubmit"
                >
                    <CompanyMultiSelectV2 companiesSelector={companiesSelector} isDisabled={false} />
                </Form.Item>

                <Form.Item
                    label={<Locale stringKey="AUTO_REPLIES__FORM__FEEDBACK_SOURCE" />}
                    name="catalogs"
                    rules={[requiredFieldRule]}
                >
                    <Select<number>
                        loading={catalogsIsInProgress}
                        mode="multiple"
                        placeholder={getLocalizedString('AUTO_REPLIES__FORM__SELECT_SOURCES')}
                        showSearch={false}
                        size="large"
                    >
                        {catalogsHookResult.map(SelectCatalogOption)}
                    </Select>
                </Form.Item>

                <Form.Item<RatingType>
                    initialValue={autoReplyData.reviewsRatings}
                    label={<Locale stringKey="AUTO_REPLIES__FORM__RATING" />}
                    name="reviewsRatings"
                    rules={[requiredFieldRule]}
                >
                    <RatingMultiSelect disabled={false} />
                </Form.Item>

                <Form.Item
                    initialValue={autoReplyData.reviewsWithBody === null ? null : String(autoReplyData.reviewsWithBody)}
                    label={<Locale stringKey="AUTO_REPLIES__FORM__CONTENT_OF_REVIEWS" />}
                    name="reviewsWithBody"
                >
                    <Select<string | null> size="large">
                        <Option key="all-reviews" value={null}>
                            <Locale stringKey="AUTO_REPLIES__FORM__ALL_REVIEWS" />
                        </Option>

                        <Option key="reviews-with-text" value="true">
                            <Locale stringKey="AUTO_REPLIES__FORM__REVIEWS_WITH_TEXT" />
                        </Option>

                        <Option key="reviews-without-text" value="false">
                            <Locale stringKey="AUTO_REPLIES__FORM__REVIEWS_WITHOUT_TEXT" />
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    initialValue={
                        autoReplyData.reviewsTimeBucket
                            ? [
                                  dayjs(autoReplyData.reviewsTimeBucket.start, 'HH:mm'),
                                  dayjs(autoReplyData.reviewsTimeBucket.end, 'HH:mm'),
                              ]
                            : [null, null]
                    }
                    label={<Locale stringKey="AUTO_REPLIES__FORM__TIME_TO_RECEIVE_FEEDBACK" />}
                    name="reviewsTimeBucket"
                >
                    <TimeRangePicker
                        className={styles.AutoReplyForm_wideInput}
                        order={false}
                        placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    initialValue={autoReplyData.templateTimeZone || 'Europe/Minsk'}
                    label={<Locale stringKey="AUTO_REPLIES__FORM__TIMEZONE" />}
                    name="templateTimeZone"
                    rules={[requiredFieldRule]}
                >
                    <Select<string>
                        disabled={timezoneLibrary.isInProgress}
                        filterOption={() => true}
                        loading={timezoneLibrary.isInProgress}
                        placeholder={getLocalizedString('AUTO_REPLIES__FORM__TIMEZONE')}
                        showSearch
                        size="large"
                    >
                        {timezoneList.map((timezoneInList: TimezoneType): JSX.Element => {
                            const {name_for_display: nameForDisplay, name} = timezoneInList;

                            return (
                                <Option key={nameForDisplay} value={name}>
                                    {nameForDisplay}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </FormFieldset>

            <FormFieldset legend={<Locale stringKey="AUTO_REPLIES__FORM__RESPONSE_TEMPLATES" />}>
                <Form.Item
                    label={
                        <div className={styles.AutoReplyForm_requiredAsterisk}>
                            <Locale stringKey="AUTO_REPLIES__FORM__RESPONSE_TEMPLATES" />
                        </div>
                    }
                >
                    <div className={styles.AutoReplyForm_buttonCounter}>
                        <Button
                            className={classNames(errors.selectTemplates ? styles.AutoReplyForm_buttonError : '')}
                            onClick={() => setIsSelectTemplatePopupOpen(true)}
                        >
                            <Locale stringKey="AUTO_REPLIES__FORM__SELECT_TEMPLATES" />
                        </Button>

                        <span>
                            <Locale
                                stringKey="AUTO_REPLIES__FORM__SELECTED"
                                valueMap={{count: selectedTemplateList.length}}
                            />
                        </span>
                    </div>
                </Form.Item>

                <Form.Item
                    initialValue={autoReplyData.templateSelectionStrategy}
                    label={<Locale stringKey="AUTO_REPLIES__FORM__THE_ORDER_OF_CHOOSING_A_TEMPLATE" />}
                    name="templateSelectionStrategy"
                    rules={isSelectionStrategyDisabled ? [] : [requiredFieldRule]}
                >
                    <Select<string> disabled={isSelectionStrategyDisabled} filterOption={() => true} size="large">
                        <Option key="order-random" value="random">
                            <Locale stringKey="AUTO_REPLIES__FORM__RANDOM" />
                        </Option>

                        <Option key="order-consistently" value="successively">
                            <Locale stringKey="AUTO_REPLIES__FORM__CONSISTENTLY" />
                        </Option>
                    </Select>
                </Form.Item>
            </FormFieldset>

            <FormFieldset legend={<Locale stringKey="AUTO_REPLIES__FORM__SCHEDULE" />}>
                {/* TODO: define all properties */}

                <Checkbox
                    checked={temporaryDate}
                    className={styles.AutoReplyForm_checkbox}
                    onChange={() => {
                        setTemporaryDate(!temporaryDate);
                    }}
                >
                    <Locale stringKey="AUTO_REPLIES__FORM__TEMPORARY" />
                </Checkbox>

                {!temporaryDate && (
                    <>
                        <Form.Item
                            initialValue={
                                autoReplyData.reviewsWeekdaysIndexes &&
                                autoReplyData.reviewsWeekdaysIndexes.length === 7
                                    ? [0]
                                    : autoReplyData.reviewsWeekdaysIndexes
                            }
                            label={<Locale stringKey="AUTO_REPLIES__FORM__DAYS_OF_THE_WEEK" />}
                            name="reviewsWeekdaysIndexes"
                            rules={[requiredFieldRule]}
                        >
                            <Select<Array<number>>
                                mode="multiple"
                                onChange={handleDaysOfWeekChange}
                                placeholder={getLocalizedString('AUTO_REPLIES__FORM__SELECT_THE_DAYS_OF_THE_WEEK')}
                                showSearch={false}
                                size="large"
                            >
                                {selectDayOfWeekContent}
                            </Select>
                        </Form.Item>

                        <Checkbox
                            checked={infiniteDate}
                            className={styles.AutoReplyForm_checkbox}
                            onChange={() => {
                                setInfiniteDate(!infiniteDate);
                            }}
                        >
                            <Locale stringKey="AUTO_REPLIES__FORM__INFINITE_RANGE" />
                        </Checkbox>

                        {infiniteDate ? (
                            <Form.Item
                                help={
                                    errors.datePeriod ? getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED') : ''
                                }
                                initialValue={autoReplyData.enabledFrom ? dayjs(autoReplyData.enabledFrom) : null}
                                label={<Locale stringKey="AUTO_REPLIES__FORM__DATE_PERIOD" />}
                                name="dateStart"
                                rules={[requiredFieldRule]}
                                validateStatus={errors.dateStart ? 'error' : ''}
                            >
                                <DatePicker
                                    disabledDate={disabledDate}
                                    format="YYYY-MM-DD"
                                    onChange={(value) => {
                                        updateErrors(AutoReplyErrorEnum.datePeriod, !value);
                                    }}
                                    placeholder={getLocalizedString('TEXT__DATE_FROM')}
                                    size="large"
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                help={
                                    errors.datePeriod ? getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED') : ''
                                }
                                initialValue={[
                                    autoReplyData.enabledFrom ? dayjs(autoReplyData.enabledFrom) : null,
                                    autoReplyData.enabledTo ? dayjs(autoReplyData.enabledTo) : null,
                                ]}
                                label={<Locale stringKey="AUTO_REPLIES__FORM__DATE_PERIOD" />}
                                name="datePeriod"
                                rules={[requiredFieldRule]}
                                validateStatus={errors.datePeriod ? 'error' : ''}
                            >
                                <DatePicker.RangePicker
                                    className={styles.AutoReplyForm_wideInput}
                                    disabledDate={disabledDate}
                                    format="YYYY-MM-DD"
                                    onChange={(value) => {
                                        updateErrors(AutoReplyErrorEnum.datePeriod, !value);
                                    }}
                                    placeholder={[
                                        getLocalizedString('TEXT__DATE_FROM'),
                                        getLocalizedString('TEXT__DATE_TO'),
                                    ]}
                                    size="large"
                                />
                            </Form.Item>
                        )}
                    </>
                )}

                {temporaryDate && (
                    <Form.Item
                        initialValue={enabledForDates}
                        label={<Locale stringKey="AUTO_REPLIES__FORM__FOR_DATES" />}
                        name="select-dates"
                        rules={[requiredFieldRule]}
                    >
                        <MultipleDatePicker
                            onChange={(dates) => {
                                setEnabledForDates(dates);
                            }}
                            selectedDate={enabledForDates}
                        />
                    </Form.Item>
                )}
            </FormFieldset>

            <FormFieldset legend={<Locale stringKey="AUTO_REPLIES__FORM__CONTROL" />}>
                <div className={styles.AutoReplyForm_switch}>
                    <Form.Item initialValue={autoReplyData.enabled} name="enabled" valuePropName="checked">
                        <Switch defaultChecked={autoReplyData.enabled} key={String(autoReplyData.enabled)} />
                    </Form.Item>

                    <Locale stringKey="AUTO_REPLIES__FORM__ENABLE_THE_RULE" />
                </div>

                <Form.Item initialValue={autoReplyData.isTest} name="is-test" valuePropName="checked">
                    <Checkbox>
                        <div className={styles.AutoReplyForm_checkboxText}>
                            <Locale stringKey="AUTO_REPLIES__FORM__TEST_MODE" />

                            <AdditionalInfo title={<Locale stringKey="AUTO_REPLIES__FORM__TEST_MODE" />}>
                                <Locale stringKey="AUTO_REPLIES__FORM__TEST_MODE__POPOVER_TEXT" />
                            </AdditionalInfo>
                        </div>
                    </Checkbox>
                </Form.Item>
            </FormFieldset>

            <div className={styles.AutoReplyForm_buttonList}>
                <Button htmlType="submit" loading={isSaving} type="primary">
                    <Locale stringKey="BUTTON__SAVE" />
                </Button>

                <Row>
                    <Button htmlType="reset">
                        <NavigationLink to={appRoute.reviewsManagementAutoReplies.path}>
                            <Locale stringKey="BUTTON__RESET_CHANGES" />
                        </NavigationLink>
                    </Button>

                    {isEdit && (
                        <Button danger onClick={handleDelete} type="link">
                            <Locale stringKey="AUTO_REPLIES__FORM__DELETE_RULE" />
                        </Button>
                    )}
                </Row>
            </div>

            <AutoReplyTemplatePopup
                isOpen={isSelectTemplatePopupOpen}
                isPrivateTemplatesDisabled
                onCancel={() => {
                    setIsSelectTemplatePopupOpen(false);
                    setDefaultSelectedTemplateList(defaultSelectedTemplateList);
                }}
                onOk={() => {
                    updateErrors(AutoReplyErrorEnum.selectTemplates, selectedTemplateList.length === 0);
                    setIsSelectTemplatePopupOpen(false);
                }}
                selectedItemList={selectedTemplateList}
                selectedResponseTemplateList={[]}
                setSelectedItemList={setSelectedTemplateList}
                setSelectedResponseTemplateList={noop}
            />
        </Form>
    );
}
