export const enSourcesTranslations = {
    SOURCES__TABLE__SOURCE: 'Source',
    SOURCES__TABLE__NAME_AND_ADDRESS: 'Name and address',
    SOURCES__TABLE__RATING: 'Rating',
    SOURCES__TABLE__STATUS: 'Status',
    SOURCES__TABLE__LINK: 'Link',
    SOURCES__STATUS__CONFIRM: 'Confirm',
    SOURCES__STATUS__ACTION_REQUIRED: 'Action required',
    SOURCES__STATUS__WAITING_FOR_PUSH: 'Waiting to be sent',
    SOURCES__STATUS__NOT_DEFINED: 'Not defined',
    SOURCES__STATUS__SYNCED: 'Synchronized',
    SOURCES__STATUS__SENT: 'Data sent',
    SOURCES__STATUS__FILL_REQUIRE: 'Filling Required',
    SOURCES__STATUS__NO_PAYMENT_PLAN: 'Payment plan not selected',
    SOURCES__STATUS__NOT_PAYED: 'Waiting for payment',
    SOURCES__STATUS__ON_MODERATION: 'On moderation',
    SOURCES__STATUS__NO_LICENSE: 'There is no license for the company',
    SOURCES__STATUS__IS_SUSPENDED: 'Suspended',
    SOURCES__STATUS__UNSUPPORTED_REGION: 'Unsupported region',
    SOURCES__STATUS__POPOVER__SYNCHRONIZED: 'Synchronized using data:',
    SOURCES__STATUS__POPOVER__SYNCHRONIZED__DATE: 'Checked: {date}',
    SOURCES__STATUS__POPOVER__ACTION_REQUIRED: 'The company is not linked.',
    SOURCES__STATUS__POPOVER__ACTION_REQUIRED__DATE: 'Linked: {date}',
    SOURCES__STATUS__POPOVER__ACTION_REQUIRED__CONFIRMATION: 'Awaiting confirmation.',
    SOURCES__STATUS__POPOVER__ERRORS: 'Data discrepancies found:',
    SOURCES__STATUS__ERROR__NAME: 'name',
    SOURCES__STATUS__ERROR__ADDRESS: 'address',
    SOURCES__STATUS__ERROR__WEBSITE: 'website',
    SOURCES__STATUS__ERROR__WORKING_HOURS: 'working hours',
    SOURCES__STATUS__ERROR__PHONES: 'phone',
    SOURCES__ACTION_LINK__CONFIRM: 'Confirm',
    SOURCES__ACTION_LINK__ENTER_CODE: 'Enter code',
    SOURCES__ACTIVE_PROCESSING_ALERT__MESSAGE: 'Active process of linking Google Business Profile locations',
    SOURCES__ACTIVE_PROCESSING_ALERT__DESCRIPTION:
        'You have not finished the last process of linking Google Business Profile locations. Sending and synchronizing company data may be incomplete. Complete the active process before starting the next one.',
    SOURCES__ACTIVE_PROCESSING_ALERT__BUTTON: 'Go to linking',
    SOURCES__STATUS__HIDE: 'Hidden',
    SOURCES__STATUS__HIDE__DETAILS:
        'The location is hidden on Yandex Maps. To make it visible again, follow the steps outlined in the documentation. {link}',
    SOURCES__ONBOARDING__BUTTON__SUBMIT: 'Understood',
    SOURCES__ONBOARDING__BUTTON__LINK: 'Learn More',
    SOURCES__ONBOARDING__POPOVER__TITLE: 'Status "Synchronized"',
    SOURCES__ONBOARDING__POPOVER__DESCRIPTION: 'The following data are compared to determine the status:',
    SOURCES__ONBOARDING__POPOVER__STATUS_DATA__OPEN: 'open/closed status',
    SOURCES__ONBOARDING__POPOVER__STATUS_DATA__NAME: 'name',
    SOURCES__ONBOARDING__POPOVER__STATUS_DATA__ADDRESS: 'address',
    SOURCES__ONBOARDING__POPOVER__STATUS_DATA__PHONE: 'phone',
    SOURCES__ONBOARDING__POPOVER__STATUS_DATA__WORKING_HOURS: 'working hours',
    SOURCES__ONBOARDING__POPOVER__STATUS: 'How is the status set',
};
