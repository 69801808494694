import {PostsSelectorCatalogStatusEnum} from '../../../../service/feature-companies/feature-companies-api-type';
import {PostSelectorCompanyType} from '../../../../service/feature-companies/feature-companies-type';
import {CompanySelectAdditionalDataColumnPropsType} from '../company-select-item-type';

import {CompaniesSelectorPostsCatalogIcon} from './companies-selector-posts-catalog-icon';
import * as styles from './companies-selector-posts-additional-data-column.scss';

export function CompaniesSelectorPostsAdditionalDataColumn(
    props: CompanySelectAdditionalDataColumnPropsType<PostSelectorCompanyType>
): JSX.Element {
    const {company} = props;
    const {catalogs} = company;

    return (
        <div className={styles.PostsSelectorItem_catalogsWrapper}>
            {catalogs
                .filter(({status: catalogStatus}) => {
                    return catalogStatus !== PostsSelectorCatalogStatusEnum.Inactive;
                })
                .map(({id: catalogId, status: catalogStatus}) => (
                    <CompaniesSelectorPostsCatalogIcon
                        catalogId={catalogId}
                        disabled={catalogStatus === PostsSelectorCatalogStatusEnum.NeedAccount}
                        key={catalogId}
                    />
                ))}
        </div>
    );
}
