import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType} from '../../catalog-list/catalog-list';
import {CatalogTab} from '../../catalog-list/catalog-tab';

import {CATALOG_DESCRIPTION_LIMITS} from './descriptions-widget-const';
import {LongDescriptionFormItem} from './long-description-form-item/long-description-form-item';
import {ShortDescriptionFormItem} from './short-description-form-item/short-description-form-item';
import * as styles from './descriptions-widget.scss';

type PropsType = {
    name: string;
    catalog: CatalogConfigWithIndexType;
    handleRemoveCatalog: (catalogId: number) => void;
};

export function CatalogDescriptions(props: PropsType): JSX.Element {
    const {name, catalog, handleRemoveCatalog} = props;

    return (
        <Form.List name={CompanyKeyEnum.ExtraDescriptions}>
            {(_fields, {remove}) => (
                <CatalogTab
                    catalog={catalog}
                    modalFieldName={name}
                    onRemove={(index: number) => {
                        remove(index);
                        handleRemoveCatalog(catalog.catalogId);
                    }}
                >
                    <div className={styles.descriptions_widget__wrapper}>
                        <div className={styles.descriptions_widget__inputs}>
                            <ShortDescriptionFormItem
                                maxLength={CATALOG_DESCRIPTION_LIMITS.short[catalog.catalogId]}
                                name={[catalog.index, 'short']}
                            />
                            <LongDescriptionFormItem
                                maxLength={CATALOG_DESCRIPTION_LIMITS.long[catalog.catalogId]}
                                name={[catalog.index, 'long']}
                            />
                        </div>
                    </div>
                </CatalogTab>
            )}
        </Form.List>
    );
}
