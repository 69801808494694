import {faLink} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Radio} from 'antd';
import {useContext} from 'react';

import {appRoute} from '../../../app-route';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {AccessDeniedBanner} from '../../../layout/access-denied-banner/access-denied-banner';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {Locale} from '../../../provider/locale/locale';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {useCclData} from '../../../service/ccl/ccl-hook';
import {CATALOG_TYPES} from '../../../service/ccl-statistic/ccl-statistic-const';
import {CatalogTypeSearchParameterEnum} from '../../../shared-search-parameters';
import {useUrl} from '../../../util/url-hook/url-hook';
import {YandexAccountTransferAlert} from '../my-companies/yandex-account-transfer-alert/yandex-account-transfer-alert';

import {SourcesAlert} from './alert/sources-alert';
import {useSourcesUrlQuery} from './sources-hook';
import {SourcesSearchParametersEnum} from './sources-type';
import {SourcesStatistics} from './statistics/sources-statistics';
import {SourcesTable} from './table/sources-table';
import * as styles from './sources.scss';

export function Sources(): JSX.Element {
    const {sourceIds, companyIds, catalogType, cclStatus} = useSourcesUrlQuery();
    const {getQuery, setQuery} = useUrl();

    const {mainFilterKey} = useContext(MainFilterContext);

    const isNewCompanies = getQuery(SourcesSearchParametersEnum.isNewCompanies) === 'true';
    const accessTransferRequired = getQuery('accessTransferRequired') === 'true';

    const cclData = useCclData(
        mainFilterKey,
        catalogType,
        cclStatus,
        isNewCompanies,
        sourceIds,
        companyIds,
        '',
        accessTransferRequired
    );

    if (cclData.isInProgress) {
        return (
            <Page>
                <Spinner position="absolute" />
            </Page>
        );
    }

    if (cclData.processError?.statusCode === 403) {
        return (
            <Page mainClassName={styles.Sources_errorWrapper}>
                <AccessDeniedBanner />
            </Page>
        );
    }

    return (
        <Page>
            <BreadCrumbs list={[{path: appRoute.sources.path, titleLangKey: 'CATEGORY_NAME__SOURCES'}]} />

            <PageHeader>
                <PageHeaderTitle productName={UsetifulNameProductEnum.SOURCES} title="CATEGORY_NAME__SOURCES" />

                <NavigationLink to={appRoute.accountLinkingSettings.path}>
                    <Button icon={<FontAwesomeIcon icon={faLink} />} type="primary">
                        <span>
                            <Locale stringKey="CATEGORY_NAME__ACCOUNTS_LINKING" />
                        </span>
                    </Button>
                </NavigationLink>
            </PageHeader>

            <Radio.Group
                className={styles.Sources_radioGroup}
                onChange={(event) => setQuery({[CatalogTypeSearchParameterEnum.catalogType]: event.target.value})}
                optionType="button"
                options={[
                    {value: CATALOG_TYPES[0], label: <Locale stringKey="TEXT__MAPS_SOCIAL_NETWORKS_CATALOGS" />},
                    {value: CATALOG_TYPES[1], label: <Locale stringKey="TEXT__NAVIGATORS" />},
                ]}
                value={catalogType}
            />

            <SourcesStatistics className={styles.Sources_statistics} />

            <SourcesAlert className={styles.Sources_alert} />
            <YandexAccountTransferAlert
                className={styles.Sources_yandexTransferAccountAlert}
                setCollectionUrl={cclData.setCollectionUrl}
            />
            <SourcesTable cclData={cclData} key={catalogType + cclStatus} sourceIds={sourceIds} />
        </Page>
    );
}
