import {useSystem} from '../../provider/system/system-hook';
import {isValidUrl} from '../../util/url';
import {Overlay} from '../overlay/overlay';

import {VIDEO_HEIGHT, VIDEO_WIDTH} from './video-popup-const';
import {getEmbedLink} from './video-popup-helper';

type PropsType = {
    url: string;
    onClose: () => void;
};

export function VideoPopup(props: PropsType): JSX.Element | null {
    const {url, onClose} = props;
    const {screen} = useSystem();

    if (!isValidUrl(url)) {
        return null;
    }

    const videoLink = getEmbedLink(url) || url;

    return (
        <Overlay label="Video player" onClose={onClose}>
            {/* eslint-disable-next-line react/iframe-missing-sandbox */}
            <iframe
                allow="autoplay; encrypted-media; picture-in-picture"
                allowFullScreen
                frameBorder="0"
                height={screen.height > VIDEO_HEIGHT ? VIDEO_HEIGHT : 'auto'}
                src={videoLink}
                title="Video player"
                width={screen.width > VIDEO_WIDTH ? VIDEO_WIDTH : screen.width - 20}
            />
        </Overlay>
    );
}
