import {faFileCertificate, faStopwatch} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useMemo, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Empty} from '../../../layout/empty/empty';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useTariffLicenses} from '../../../service/tariffs/tariffs-licenses';
import {useTariff} from '../../../service/tariffs/tariffs-user-tariff';
import {useTariffsInfinite} from '../../../service/tariffs/tariffs-user-tariffs';
import {classNames} from '../../../util/css';
import {useUrl} from '../../../util/url-hook/url-hook';
import {ExpiredColumnQueryNameEnum} from '../my-companies/companies-table/columns/expired-column-type';

import {LicensesBlock} from './licenses-block/licenses-block';
import {LicensesBlockEnum} from './licenses-block/licenses-block-type';
import {TariffsInfo} from './tariffs-block/tariffs-info';
import * as styles from './tariffs.scss';

export function Tariffs(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {getQuery} = useUrl();
    const initialTariff = Number(getQuery(ExpiredColumnQueryNameEnum.tariffId)) || null;
    const {data: tariffs, isFetching, fetchNextPage} = useTariffsInfinite(initialTariff);

    const [tariffId, setTariffId] = useState<number | null>(null);

    const {data: tariff, isLoading} = useTariff(tariffId);

    const {licenseData} = useTariffLicenses(tariffId);

    useEffect(() => {
        if (tariffs && tariffs?.pages[0]?.results[0] && initialTariff === null) {
            setTariffId(tariffs.pages[0]?.results[0].id);
        }

        if (initialTariff) {
            setTariffId(initialTariff);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tariffs?.pages[0]?.results, initialTariff]);

    const tariffsList = useMemo(
        () => tariffs?.pages.map((page) => page.results).flatMap((array) => [...array]) || [],
        [tariffs?.pages]
    );

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__TARIFFS')} />

                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.tariffsAndLimits.path,
                            titleLangKey: 'CATEGORY_NAME__TARIFFS',
                        },
                    ]}
                />

                <PageHeader>
                    <PageHeaderTitle productName={UsetifulNameProductEnum.TARIFFS} title="CATEGORY_NAME__TARIFFS" />
                </PageHeader>
            </PageCard>

            {isLoading && <Spinner />}

            {!isLoading && !tariff && <Empty />}

            {!isLoading && tariff && (
                <div className={styles.Tariffs}>
                    <TariffsInfo
                        fetchNextPage={fetchNextPage}
                        isFetching={isFetching}
                        setTariffId={setTariffId}
                        tariff={tariff}
                        tariffs={tariffsList}
                    />

                    <div className={styles.Tariffs_section}>
                        {licenseData[LicensesBlockEnum.TestActive].length > 0 && (
                            <LicensesBlock
                                description="TARIFFS__LICENSE_BLOCK__ACTIVE_TEST__DESCRIPTION"
                                icon={
                                    <div className={styles.Tariffs_activeTestIconWrapper}>
                                        <FontAwesomeIcon className={styles.Tariffs_icon} icon={faStopwatch} />
                                    </div>
                                }
                                name="TARIFFS__LICENSE_BLOCK__ACTIVE_TEST__NAME"
                                tariffId={tariff.id}
                                type={LicensesBlockEnum.TestActive}
                            />
                        )}
                        {licenseData[LicensesBlockEnum.TestAvailable].length > 0 && (
                            <LicensesBlock
                                description="TARIFFS__LICENSE_BLOCK__AVAILABLE_LICENSES__DESCRIPTION"
                                icon={
                                    <div className={styles.Tariffs_activeTestIconWrapper}>
                                        <FontAwesomeIcon className={styles.Tariffs_icon} icon={faStopwatch} />
                                    </div>
                                }
                                name="TARIFFS__LICENSE_BLOCK__AVAILABLE_LICENSES__NAME"
                                tariffId={tariff.id}
                                type={LicensesBlockEnum.TestAvailable}
                            />
                        )}
                        <LicensesBlock
                            description="TARIFFS__LICENSE_BLOCK__LIMIT_LICENSES__DESCRIPTION"
                            icon={
                                <div
                                    className={classNames(
                                        styles.Tariffs_activeTestIconWrapper,
                                        styles.Tariffs_activeTestIconWrapper__blue
                                    )}
                                >
                                    <FontAwesomeIcon className={styles.Tariffs_icon} icon={faFileCertificate} />
                                </div>
                            }
                            name="TARIFFS__LICENSE_BLOCK__LIMIT_LICENSES__NAME"
                            tariffId={tariff.id}
                            type={LicensesBlockEnum.LicensesWithLimits}
                        />
                        <LicensesBlock
                            icon={
                                <div
                                    className={classNames(
                                        styles.Tariffs_activeTestIconWrapper,
                                        styles.Tariffs_activeTestIconWrapper__blue
                                    )}
                                >
                                    <FontAwesomeIcon className={styles.Tariffs_icon} icon={faFileCertificate} />
                                </div>
                            }
                            name="TARIFFS__LICENSE_BLOCK__ADDITIONAL_LICENSES__NAME"
                            tariffId={tariff.id}
                            type={LicensesBlockEnum.AdditionalLicenses}
                        />
                    </div>
                </div>
            )}
        </Page>
    );
}
