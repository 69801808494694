import {PropsWithChildren} from 'react';

import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/localization';

import * as styles from './auth-form-footer.scss';

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function AuthFormFooter(props: PropsType): JSX.Element | null {
    const {children} = props;

    const {isWhiteLabel} = useDomainConfig();

    if (isWhiteLabel) {
        return null;
    }

    return (
        <footer className={styles.AuthFormFooter}>
            {children || (
                <NavigationLink to={appRoute.login.path}>
                    <Locale stringKey="PAGE__AUTH__LINK_TO__LOGIN" />
                </NavigationLink>
            )}
        </footer>
    );
}
