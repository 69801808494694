import {useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/localization';
import {useDashboardSearchPhrases} from '../../../../service/dashboard/dashboard-hook';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {DASHBOARD_TOP_SEARCH_PHRASES_COUNT_TO_SHOW} from './search-phrases-const';
import {SearchPhrasesEmpty} from './search-phrases-empty';
import {SearchPhrasesItem} from './search-phrases-item';
import * as styles from './search-phrases.scss';

export function SearchPhrases(): JSX.Element {
    const {result, isInProgress, processError} = useDashboardSearchPhrases();

    const mostPopularSearchPhrases = useMemo(
        () => result?.searchPhrases.slice(0, DASHBOARD_TOP_SEARCH_PHRASES_COUNT_TO_SHOW) || [],
        [result]
    );

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__SEARCH_PHRASES__TITLE" />}
                    widget={DashboardWidgetEnum.SearchPhrases}
                />
            }
        >
            {isInProgress && <Spinner />}

            {processError && <AlertFallback />}

            {result && result.searchPhrases.length === 0 && <SearchPhrasesEmpty hasEmptyValue={result.hasEmptyValue} />}

            {result && result.searchPhrases.length > 0 && (
                <>
                    <div className={styles.SearchPhrases_cardDescription}>
                        <Locale
                            stringKey="DASHBOARD_PAGE__SEARCH_PHRASES__DESCRIPTION"
                            valueMap={{
                                count: DASHBOARD_TOP_SEARCH_PHRASES_COUNT_TO_SHOW,
                            }}
                        />
                    </div>

                    <div className={styles.SearchPhrases}>
                        {mostPopularSearchPhrases.map((resultItem) => (
                            <SearchPhrasesItem
                                key={resultItem.searchPhrase}
                                text={resultItem.searchPhrase}
                                value={resultItem.value}
                            />
                        ))}
                    </div>

                    <div className={styles.SearchPhrases_analyticsLink}>
                        <Locale
                            stringKey="DASHBOARD_PAGE__SEARCH_PHRASES__FULL_INFO_DESCRIPTION"
                            valueMap={{
                                link: (
                                    <NavigationLink
                                        key="DASHBOARD_PAGE__SEARCH_PHRASES__LINK"
                                        to={appRoute.onlinePresencePhrases.path}
                                    >
                                        <Locale stringKey="DASHBOARD_PAGE__SEARCH_PHRASES__LINK" />
                                    </NavigationLink>
                                ),
                            }}
                        />
                    </div>
                </>
            )}
        </PageCard>
    );
}
