import dayjs from 'dayjs';

import {TimeRangeType} from '../../../../../../../../../service/company-v2/types/company-working-time-type';
import {timeFormat} from '../../../working-time-const';

export function getDisabledHours(previousBreakTime: TimeRangeType | null): () => Array<number> {
    return function disabledHours() {
        if (!previousBreakTime || !('finish' in previousBreakTime)) {
            return [];
        }

        const formattedFinish = dayjs(previousBreakTime.finish, timeFormat);

        const previousHour = formattedFinish.hour();
        const previousMinute = formattedFinish.minute();
        const lastPossibleMinute = 59;

        const hours = Array.from({length: 24}, (_item, index) => index);

        return hours.filter(
            (hour) => hour < previousHour || (hour === previousHour && previousMinute === lastPossibleMinute)
        );
    };
}

export function getDisabledMinutes(previousBreakTime: TimeRangeType | null): (hour: number) => Array<number> {
    return function disabledMinutes(hour: number): Array<number> {
        if (!previousBreakTime || !('start' in previousBreakTime)) {
            return [];
        }

        const formattedFinish = dayjs(previousBreakTime.finish, timeFormat);

        const previousHour = formattedFinish.hour();
        const previousMinute = formattedFinish.minute();

        const minutes = Array.from({length: 60}, (_item, index) => index);

        return minutes.filter((minute) => (hour === previousHour && minute <= previousMinute) || hour < previousHour);
    };
}
