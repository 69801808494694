import {Button} from 'antd';
import {useMemo} from 'react';
import {ExtractRouteParams, generatePath, useParams} from 'react-router';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../component/meta/meta';
import {Text} from '../../../../component/text/text';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-container';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {TWO_GIS_DOMAIN} from '../../../../provider/domain-config/domain-config-helper';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../provider/domain-config/domain-config-type';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useReviewItem} from '../../../../service/reviews/reviews';
import {useReviewsCatalogList} from '../../../../service/reviews/reviews-catalogs';
import {
    ReviewsGmbCclStatusEnum,
    useReviewsGmbCclStatuses,
} from '../../../../service/reviews/reviews-gmb-confirmation-hook';
import {useReviewLogsList} from '../../../../service/reviews/reviews-logs';
import {useNotes} from '../../../../service/reviews/reviews-notes';
import {useResponsibleUsers} from '../../../../service/reviews/reviews-responsible-users';
import {useReviewsScrapyCatalogsInfo} from '../../../../service/reviews/reviews-scrapy-catalogs';
import {useReviewsSuggestions} from '../../../../service/reviews/reviews-suggestions';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {ReplyAliasButton} from '../reply-alias-button/reply-alias-button';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';
import {ReviewCatalogLogo} from '../review-catalog-logo/review-catalog-logo';
import {ReviewCreatedInRdDate} from '../review-created-in-rd-date/review-created-in-rd-date';
import {ResponsibleUser} from '../review-list-page-content/review-list/review/responsible-user/responsible-user';
import {ReviewCondition} from '../review-list-page-content/review-list/review/review-condition/review-condition';
import {constructComment} from '../review-list-page-content/review-list/review/review-helper';
import {ReviewLogs} from '../review-logs/review-logs';
import {ReviewNotes} from '../review-notes/review-notes';
import {getReviewCreationDate, isReviewReplyAllowed} from '../reviews-helper';
import {useReviewStateHook} from '../reviews-state/reviews-state-provider';
import {TagList} from '../tag-list/tag-list';

import {ActivityArea} from './activity-area/activity-area';
import {ReviewMeta} from './review-meta/review-meta';
import {ScrollButton} from './scroll-button/scroll-button';
import * as styles from './review-item-page-content.scss';

// eslint-disable-next-line complexity, max-statements
export function ReviewItemPageContent(): JSX.Element {
    const {data: reviewsCatalogList, isFetching: reviewsCatalogListIsInProgress} = useReviewsCatalogList();

    const {getLocalizedString} = useLocale();
    const {goBack, hasBack, pushUrl} = useUrl();
    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();
    const reviewId = Number(id);
    const {getFormattedDateTime} = useFormat();
    const {domainName} = useDomainConfig();

    const {isHistoryVisible} = useReviewActivityHook();
    const {googleMapsReviewIds} = useReviewStateHook();
    const {refetch: loadLogs} = useReviewLogsList(Number(id), isHistoryVisible);

    const {data: review, isLoading, error, refetch: refreshReviewItem} = useReviewItem(reviewId);
    const {data: responsibleUsers} = useResponsibleUsers(review || null);

    const catalog = reviewsCatalogList?.find((item) => item.id === review?.catalogId);

    const isTwoGisDomainWithEmptyComment =
        (domainName === DomainNameEnum.twoGis || domainName.includes(TWO_GIS_DOMAIN)) && !review?.comment;

    const isAnswerUnavailable = Boolean(review && !isReviewReplyAllowed(review) && isTwoGisDomainWithEmptyComment);

    const suggestionReviewIds = useMemo(() => [reviewId], [reviewId]);

    const {data: resultSuggestions, refetch: refreshItemSuggestion} = useReviewsSuggestions(suggestionReviewIds);
    const suggestion = resultSuggestions?.results?.[0];

    const {data: reviewsScrapyCatalogsList} = useReviewsScrapyCatalogsInfo();
    const scrapyCatalog = reviewsScrapyCatalogsList?.find((item) => item.id === catalog?.id);

    const {data: notesList, refetch: refreshNotes} = useNotes(reviewId, true);
    const {data: reviewGmbStatuses} = useReviewsGmbCclStatuses(googleMapsReviewIds);

    const isNeedConfirmationStatus =
        reviewGmbStatuses &&
        (reviewGmbStatuses[reviewId] === ReviewsGmbCclStatusEnum.WaitVerify ||
            reviewGmbStatuses[reviewId] === ReviewsGmbCclStatusEnum.NotVerified);

    function refreshReview() {
        return Promise.all([refreshReviewItem()]);
    }

    function refreshSuggestionsInner() {
        return Promise.all([refreshItemSuggestion(), refreshItemSuggestion()]);
    }

    function handleBackClick() {
        if (hasBack) {
            goBack();
        } else {
            pushUrl(appRoute.reviewsManagementReviews.path);
        }
    }

    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('REVIEW_ITEM_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.reviewsManagementReviews.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS',
                        },
                        {
                            path: generatePath(appRoute.reviewsManagementReviewItem.path, {id}),
                            titleLangKey: 'REVIEW_ITEM_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader className={styles.ReviewPage_pageHeader}>
                    <Locale stringKey="REVIEW_ITEM_PAGE__TITLE" />
                    <Button onClick={handleBackClick}>
                        <Locale stringKey="BUTTON__BACK" />
                    </Button>
                </PageHeader>
            </PageCard>

            <div className={styles.ReviewPage_contentWrapper}>
                <Spinner isShow={isLoading} position="absolute" />

                {Boolean(error) && <AlertFallback />}

                {review && !error && (
                    <div className={styles.ReviewPage_content}>
                        <div className={styles.ReviewPage_review}>
                            <div className={styles.ReviewPage_reviewContent}>
                                {review.comment ? (
                                    <div className={styles.ReviewPage_message}>
                                        {constructComment(review.comment, true)}
                                    </div>
                                ) : (
                                    <div className={styles.ReviewPage_noText}>
                                        <Locale stringKey="REVIEW_ITEM_PAGE__NO_TEXT_TITLE" />
                                    </div>
                                )}

                                <div className={styles.ReviewPage_activityControls}>
                                    <ActivityArea
                                        catalog={catalog}
                                        isTwoGisDomainWithEmptyComment={isTwoGisDomainWithEmptyComment}
                                        notesCount={notesList?.results.length || 0}
                                        refreshReview={refreshReview}
                                        refreshSuggestions={refreshSuggestionsInner}
                                        reviewData={review}
                                        suggestion={suggestion}
                                    />
                                    <ReviewNotes onRefresh={refreshNotes} reviewId={reviewId} />
                                    <ReviewLogs
                                        reviewAuthor={review.originUrl ? null : review.author}
                                        reviewCreatedInCatalog={review.createdInCatalog}
                                        reviewId={reviewId}
                                    />
                                </div>
                            </div>
                            <div className={styles.ReviewPage_scrollButtonWrapper}>
                                <ScrollButton />
                            </div>
                        </div>

                        <div className={styles.ReviewPage_aside}>
                            <div className={styles.ReviewPage_asideContent}>
                                <div className={styles.ReviewPage_catalogLine}>
                                    <div className={styles.ReviewPage_catalog}>
                                        <div className={styles.ReviewPage_catalogLogo}>
                                            <ReviewCatalogLogo
                                                catalog={catalog || null}
                                                isCatalogLoading={reviewsCatalogListIsInProgress}
                                            />
                                        </div>

                                        <Text className={styles.ReviewPage_catalogName}>{catalog?.name || ''}</Text>
                                    </div>

                                    <ReviewCreatedInRdDate createdInRd={review.createdInRd}>
                                        <div className={styles.ReviewPage_date}>
                                            {getFormattedDateTime(new Date(getReviewCreationDate(review)), {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })}
                                        </div>
                                    </ReviewCreatedInRdDate>
                                </div>

                                <div className={styles.ReviewPage_contentMetaWrapper}>
                                    <ReviewMeta
                                        closePage={handleBackClick}
                                        refreshLogs={loadLogs}
                                        refreshReview={refreshReview}
                                        review={review}
                                        reviewsCatalogList={reviewsCatalogList || null}
                                    />

                                    {review.ableToChangeResponsibleUser && (
                                        <ResponsibleUser
                                            classNameButton={styles.ReviewPage_responsibleUser}
                                            onChange={loadLogs}
                                            responsibleUser={review.responsibleUser}
                                            responsibleUsersList={responsibleUsers?.results || []}
                                            reviewId={reviewId}
                                        />
                                    )}

                                    <TagList
                                        deletedAt={review.deletedAt}
                                        refreshReview={refreshReview}
                                        reviewId={reviewId}
                                        tags={review.tags}
                                    />

                                    {!isTwoGisDomainWithEmptyComment && <ReplyAliasButton review={review} wide />}

                                    <ReviewCondition
                                        hasCompanyComment={
                                            review.children && review.children.some((child) => child.isCompanyComment)
                                        }
                                        isAnswerUnavailable={isAnswerUnavailable}
                                        isNeedConfirmationStatus={Boolean(isNeedConfirmationStatus)}
                                        isReviewDeleted={Boolean(review.deletedAt)}
                                        moderated={review.moderated}
                                        scrapyCatalogInfo={scrapyCatalog}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Page>
    );
}
