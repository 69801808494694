import {useMemo} from 'react';

import {FormattedNumber} from '../../../../../../../provider/locale/formatted-number';

import * as styles from './dashboard-company-marker.scss';

type PropsType = {
    rating: string | null;
};

export function DashboardCompanyMarker(props: PropsType): JSX.Element {
    const {rating} = props;

    const ratingNumber = Number(rating);

    const markerColor = useMemo(() => {
        if (rating === null) {
            return styles.company_rating_1_color;
        } else if (ratingNumber === 5) {
            return styles.company_rating_5_color;
        } else if (ratingNumber >= 4) {
            return styles.company_rating_4_color;
        } else if (ratingNumber >= 3) {
            return styles.company_rating_3_color;
        } else if (ratingNumber >= 2) {
            return styles.company_rating_2_color;
        }

        return styles.company_rating_1_color;
    }, [rating, ratingNumber]);

    return (
        <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 30C16 30 4 18.5 4 11C4 5.5 8.5 1 16 1C23.5 1 28 5.5 28 11C28 18.5 16 30 16 30Z"
                fill={markerColor}
                opacity={0.7}
                strokeWidth="1"
            />
            {rating && (
                <text
                    dominantBaseline="middle"
                    fill="white"
                    fontSize="12"
                    fontWeight="bold"
                    textAnchor="middle"
                    x="50%"
                    y="45%"
                >
                    <FormattedNumber
                        options={{
                            maximumFractionDigits: 1,
                        }}
                        value={ratingNumber}
                    />
                </text>
            )}
        </svg>
    );
}
