import {RcFile} from 'antd/lib/upload';

import {
    deserializeV2,
    fetchAndDeserialize,
    postAndDeserialize,
    serialize,
    serializeToURLParameters,
} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {PaginationDataType} from '../../util/pagination-hook/pagination-hook-type';
import {idNumberSchema, IdNumberType} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {getTechnicalSupportFilterQueries} from './technical-support-helper';
import {
    CreateTicketMutationDataType,
    ParametersSupportTicketsType,
    technicalSupportAnswersSchema,
    TechnicalSupportFilterType,
    TechnicalSupportMessagesResponseType,
    TechnicalSupportNewMessageType,
    technicalSupportResponseSchema,
    technicalSupportTicketFieldsSchema,
    TechnicalSupportTicketFieldsType,
    TechnicalSupportTicketResponseType,
    technicalSupportTicketsResponseSchema,
    TechnicalSupportTicketsResponseType,
    ticketFileSchema,
    TicketFileType,
} from './technical-support-type';

export function fetchSupportTicketFields(): Promise<TechnicalSupportTicketFieldsType> {
    const url = '/cp/helpdeskeddy/ticket_fields/';

    return fetchAndDeserialize<TechnicalSupportTicketFieldsType>(url, technicalSupportTicketFieldsSchema);
}

export function fetchSupportTickets(
    parameters: ParametersSupportTicketsType,
    filter: TechnicalSupportFilterType
): Promise<TechnicalSupportTicketsResponseType> {
    const parametersString = objectToUrlParameters({
        ...serializeToURLParameters<PaginationDataType>(parameters.pagination),
        ...serializeToURLParameters(getTechnicalSupportFilterQueries(filter)),
        q: filter.q,
    });

    const url = `/cp/helpdeskeddy/tickets/?${parametersString}`;

    return fetchAndDeserialize<TechnicalSupportTicketsResponseType>(url, technicalSupportTicketsResponseSchema, {
        fetchOptions: {
            skipCache: true,
        },
    });
}

export function createSupportTicket(body: CreateTicketMutationDataType): Promise<void> {
    const url = `${rootApiUrl}/cp/helpdeskeddy/tickets/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({
            ...body,
            files: body.files.map((file) => file.uid),
        }),
    });
}

export function fetchTechnicalSupportTicket(id: number): Promise<TechnicalSupportTicketResponseType> {
    return fetchAndDeserialize(`/cp/helpdeskeddy/tickets/${id}/`, technicalSupportResponseSchema, {
        fetchOptions: {
            skipCache: true,
        },
    });
}

export function reopenTechnicalSupportTicket(id: number): Promise<IdNumberType> {
    return postAndDeserialize(`/cp/helpdeskeddy/tickets/${id}/reopen/`, idNumberSchema);
}

export function fetchTechnicalSupportTicketAnswers(
    ticketId: number,
    paginationData: PaginationDataType
): Promise<TechnicalSupportMessagesResponseType> {
    const parametersString = objectToUrlParameters({
        ...serializeToURLParameters({
            page: paginationData.page,
            pageSize: paginationData.pageSize,
        }),
    });

    return fetchAndDeserialize(
        `/cp/helpdeskeddy/tickets/${ticketId}/posts/?${parametersString}`,
        technicalSupportAnswersSchema,
        {
            fetchOptions: {
                skipCache: true,
            },
        }
    );
}

export function createNewTechnicalSupportTicketAnswer(
    ticketId: number,
    data: TechnicalSupportNewMessageType
): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/cp/helpdeskeddy/tickets/${ticketId}/posts/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(
            serialize({
                text: data.text,
                files: data.files.map((file) => file.uid),
            })
        ),
    });
}

export async function changeTechnicalSupportTicketSubscription(options: {
    helpRequestId: number;
    isSubscribed: boolean;
}): Promise<void> {
    const {helpRequestId, isSubscribed} = options;

    const url = `${rootApiUrl}/cp/helpdeskeddy/tickets/${helpRequestId}/subscribe/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.post,
        body: JSON.stringify({
            is_subscribed: isSubscribed,
        }),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export async function uploadTicketFile(file: RcFile): Promise<TicketFileType> {
    const url = `${rootApiUrl}/cp/helpdeskeddy/files/`;

    const formData = new FormData();

    formData.append('file_s3', file);

    const response = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {...getCsrfHeaders()},
        body: formData,
    });

    return deserializeV2<TicketFileType>(url, ticketFileSchema, response);
}

export function deleteUploadedFile(temporaryId: string): Promise<void> {
    const url = `${rootApiUrl}/cp/helpdeskeddy/files/${temporaryId}/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
