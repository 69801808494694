import {Button} from 'antd';
import {ReactNode} from 'react';

import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {classNames} from '../../../util/css';
import {Text} from '../../text/text';
import {DEFAULT_TRIAL_PERIOD_DURATION_DAYS} from '../trial-activation-modal/trial-activation-modal-helper';

import * as styles from './landing-call-to-action.scss';

type PropsType = {
    colorful?: boolean;
    descriptionLangKey: LangKeyType;
    descriptionValueMap?: Record<string, ReactNode>;
    onClick: VoidFunction;
    duration?: number;
};

export function LandingCallToAction(props: PropsType): JSX.Element {
    const {
        descriptionLangKey,
        colorful,
        onClick,
        duration = DEFAULT_TRIAL_PERIOD_DURATION_DAYS,
        descriptionValueMap,
    } = props;

    const {logoShortSrc} = useDomainConfig();

    return (
        <div
            className={classNames(styles.LandingCallToAction, {
                [styles.LandingCallToAction__colorful]: colorful,
            })}
        >
            <div className={styles.LandingCallToAction_header}>
                <img alt="" className={styles.LandingCallToAction_logo} src={logoShortSrc} />
                <Text
                    secondary
                    small
                    stringKey="LANDING__TEST_PERIOD"
                    valueMap={{
                        duration,
                    }}
                />
            </div>
            <Text className={styles.LandingCallToAction_title} stringKey="LANDING__TEST_PERIOD__TITLE" />

            <Text
                className={styles.LandingCallToAction_description}
                lighter
                stringKey={descriptionLangKey}
                valueMap={{
                    ...descriptionValueMap,
                    duration,
                }}
            />

            <Button className={styles.LandingCallToAction_button} onClick={onClick} type="primary">
                <Text inheritColor stringKey="BUTTON__TRY_FOR_FREE" />
            </Button>
        </div>
    );
}
