import {DatePeriodEnum} from '../../../util/date';

import {
    ReviewsAndAnswersFilterQueriesEnum,
    ReviewsAndAnswersFilterStateType,
} from './reviews-and-answers-statistics-filter-type';

export const defaultFilterState: ReviewsAndAnswersFilterStateType = {
    [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: new Date(),
    [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: new Date(),
    [ReviewsAndAnswersFilterQueriesEnum.Period]: DatePeriodEnum.Month,
    [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: null,
    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: null,
    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: null,
};
