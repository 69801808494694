import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

const licensesDescriptionsSchema = r.array(
    r.object({
        licenseCode: r.string(),
        name: r.string(),
        description: r.string(),
        isTrialConnection: r.boolean(),
        types: r.array(r.object({label: r.string()})).nullable(),
        providerCodes: r.array(r.object({label: r.string()})).nullable(),
    })
);

type LicensesDescriptionType = r.infer<typeof licensesDescriptionsSchema>;

function getLicenseDescriptionUrl() {
    return `/cp/licence/`;
}

function fetchLicensesDescription(): Promise<LicensesDescriptionType> {
    return fetchAndDeserialize(getLicenseDescriptionUrl(), licensesDescriptionsSchema);
}

export function useLicensesDescription(): UseQueryResult<LicensesDescriptionType> {
    return useQuery([getLicenseDescriptionUrl()], () => fetchLicensesDescription());
}
