import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {unansweredReviewsCountSchema, UnansweredReviewsCountType} from './unanswered-reviews-count-type';

export function fetchUnansweredReviewsCount(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<UnansweredReviewsCountType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/without_answer/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        unansweredReviewsCountSchema
    );
}
