import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

export type ReviewHistoryDataType = {
    date: string;
    field: string;
    new_value: string;
    old_value: string;
    review_id: number;
};

function getReviewHistoryListUrl(reviewId: number) {
    return `${rootApiUrl}/v4/reviews/${reviewId}/history/`;
}

function fetchReviewHistoryList(reviewId: number): Promise<Array<ReviewHistoryDataType>> {
    return fetchX<Array<ReviewHistoryDataType>>(getReviewHistoryListUrl(reviewId));
}

export function useReviewHistoryList(reviewId: number, enabled = true): UseQueryResult<Array<ReviewHistoryDataType>> {
    return useQuery([getReviewHistoryListUrl(reviewId)], () => fetchReviewHistoryList(reviewId), {
        enabled,
        refetchOnWindowFocus: false,
    });
}
