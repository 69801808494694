import {faUserCog} from '@fortawesome/pro-regular-svg-icons';
import {faLink, faUserTie} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, List, Radio, RadioChangeEvent} from 'antd';
import {useCallback, useContext, useEffect, useState} from 'react';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useMessage} from '../../../../../../provider/message/message-hook';
import {useGoogleAccounts} from '../../../../../../service/google/accounts/google-accounts-hook';
import {StepHeader} from '../../common/step-header/step-header';
import * as stepStyles from '../../common/step-styles/step-styles.scss';
import {GoogleAccountSyncContext} from '../../google-account-sync-context';

import {AccountListItem} from './account-list-item/account-list-item';
import {AccountsListHeader} from './accounts-list-header/accounts-list-header';
import {AccountsPopup} from './accounts-popup/accounts-popup';
import {googleAccountFilters} from './accounts-step-const';
import {AccountFilterEnum} from './accounts-step-type';
import {ActionCard} from './action-card/action-card';
import {OnlyPersonalAlert} from './only-personal-alert/only-personal-alert';
import {ProblemAlert} from './problem-alert/problem-alert';
import * as styles from './accounts-step.scss';

type PropsType = {
    onFinish: () => void;
};

export function AccountsStep(props: PropsType): JSX.Element {
    const {onFinish} = props;

    const {personalAccountId, accountId, setPersonalAccountId, setAccountId, setHeaderButtons, goToSources} =
        useContext(GoogleAccountSyncContext);

    const [filter, setFilter] = useState(googleAccountFilters[0].value);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [search, setSearch] = useState('');

    const {message} = useMessage();
    const {getLocalizedString} = useLocale();

    const {
        selectedPersonalAccount,
        personalAccounts,
        accounts: {
            accounts,
            isInitialLoading,
            pagination: {page: current, pageSize, total, onChange},
        },
    } = useGoogleAccounts({
        personalAccountId,
        accountType: googleAccountFilters.find(({value}) => filter === value)?.accountType,
        search,
    });

    const personalAccount = personalAccounts.accounts?.find((account) => account.id === personalAccountId);

    const handleFinish = useCallback(
        (newAccountId?: number) => {
            if (accountId || newAccountId) {
                onFinish();
            } else {
                message.error(getLocalizedString('GOOGLE_SYNC__ACCOUNTS_STEP__ERROR__NOT_SELECTED'));
            }
        },
        [accountId, getLocalizedString, message, onFinish]
    );

    useEffect(() => {
        setHeaderButtons(() => (
            <>
                <Button onClick={() => goToSources()}>
                    <Locale stringKey="BUTTON__CANCEL" />
                </Button>

                <Button onClick={() => handleFinish()} type="primary">
                    <Locale stringKey="TEXT__NEXT" />
                </Button>
            </>
        ));
    }, [goToSources, handleFinish, setHeaderButtons]);

    function handleAccountChange() {
        setIsPopupOpen(true);
    }

    function handleAccountPopupCancel() {
        setIsPopupOpen(false);
    }

    function handleAccountPopupOk(newAccountId: number) {
        setPersonalAccountId(newAccountId);
        setAccountId(null);
        handleAccountPopupCancel();
    }

    function handleRadioGroupChange(event: RadioChangeEvent) {
        setAccountId(event.target.value);

        if (event.nativeEvent.shiftKey) {
            handleFinish(event.target.value);
        }
    }

    return (
        <>
            {personalAccounts && (
                <AccountsPopup
                    data={personalAccounts}
                    onCancel={handleAccountPopupCancel}
                    onOk={handleAccountPopupOk}
                    open={isPopupOpen}
                    search={search}
                    setSearch={setSearch}
                />
            )}

            <StepHeader
                alertIcon={faUserTie}
                alertMessage="GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__SELECT_GROUP"
                title="GOOGLE_SYNC__ACCOUNTS_STEP__TITLE"
            />

            {!personalAccountId ? (
                <ActionCard
                    action={
                        <Button icon={<FontAwesomeIcon icon={faLink} />} onClick={handleAccountChange} type="link">
                            <span>
                                <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__LINK" />
                            </span>
                        </Button>
                    }
                    className={styles.ActionCard__linkFirst}
                    title={<Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__LINK_FIRST_ACCOUNT" />}
                />
            ) : (
                <>
                    <div className={styles.ActionCard_header}>
                        <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__ACCOUNT_HEADER" />
                    </div>

                    <ActionCard
                        action={
                            <Button
                                icon={<FontAwesomeIcon icon={faUserCog} />}
                                onClick={handleAccountChange}
                                type="text"
                            >
                                <span>
                                    <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__CHANGE" />
                                </span>
                            </Button>
                        }
                        className={styles.ActionCard__found}
                        title={selectedPersonalAccount?.name ?? ''}
                    />

                    <Radio.Group className={stepStyles.ItemGroup} onChange={handleRadioGroupChange} value={accountId}>
                        <List
                            className={stepStyles.List}
                            dataSource={accounts}
                            header={<AccountsListHeader filter={filter} setFilter={setFilter} total={total ?? 0} />}
                            loading={isInitialLoading}
                            pagination={{hideOnSinglePage: true, current, pageSize, total, onChange}}
                            renderItem={(account) => <AccountListItem account={account} />}
                            size="small"
                        />
                    </Radio.Group>

                    <OnlyPersonalAlert accounts={filter === AccountFilterEnum.All ? accounts ?? [] : []} />

                    {personalAccount && personalAccount.hasProblem && <ProblemAlert onClick={handleAccountChange} />}

                    <div className={stepStyles.Navigation}>
                        <Button onClick={() => handleFinish()} type="primary">
                            <Locale stringKey="TEXT__NEXT" />
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}
