import {camelizeKeys, decamelize, decamelizeKeys} from 'humps';
import {isEmpty, some} from 'lodash';
import {ZodType} from 'zod';

import {ShortLocaleNameEnum} from '../provider/locale/locale-context-type';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../service/api/api-const';

import {ApiError} from './error';
import {FetchMethodEnum, FetchOptionsType, fetchX} from './fetch';
import {camelizeKeysPartial, isRecord} from './object';
import {ObjectToUrlParametersType} from './type';
import {objectToUrlParameters} from './url';

export type UnknownType = Record<string, unknown> | Array<unknown>;

export function serialize<T extends UnknownType>(payload: T): UnknownType {
    return decamelizeKeys(payload) as UnknownType;
}

export function serializeToURLParameters<T extends ObjectToUrlParametersType>(payload: T): ObjectToUrlParametersType {
    return decamelizeKeys(payload) as ObjectToUrlParametersType;
}

export function serializeValue(value: string): string {
    return decamelize(value);
}

function parse<T extends UnknownType>(url: string, zodSchema: ZodType, payload: T): T {
    try {
        return zodSchema.parse(payload);
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.warn('API validation error:', url, error.message);
        }
    }

    return payload;
}

export function deserializeV2<T extends UnknownType>(
    url: string,
    zodSchema: ZodType,
    payload: UnknownType,
    deserializeOptions?: {
        ignorePaths?: Array<RegExp>;
        ignoreKeys?: Array<RegExp>;
    }
): T {
    return parse(
        url,
        zodSchema,
        (deserializeOptions
            ? camelizeKeysPartial({
                  object: payload,
                  ignorePaths: deserializeOptions.ignorePaths,
                  ignoreKeys: deserializeOptions.ignoreKeys,
              })
            : camelizeKeys(payload)) as T
    );
}

export function deserializeApiError<T extends UnknownType>(
    url: string,
    zodSchema: ZodType,
    error: unknown
): Partial<T> | null {
    if (!(error instanceof ApiError) || !isRecord(error.jsonData)) {
        return null;
    }

    return parse(url, zodSchema, camelizeKeys(error.jsonData) as T);
}

export async function fetchAndDeserialize<T extends UnknownType>(
    pathname: string,
    schema: ZodType,
    options: {
        deserializeOptions?: {
            ignorePaths?: Array<RegExp>;
            ignoreKeys?: Array<RegExp>;
        };
        fetchOptions?: FetchOptionsType;
    } = {}
): Promise<T> {
    const {deserializeOptions, fetchOptions} = options;
    const url = `${rootApiUrl}${pathname}`; // TODO: url-join would be nice
    const response: Array<unknown> = await fetchX<Array<unknown>>(url, fetchOptions);

    return deserializeV2<T>(url, schema, response, deserializeOptions);
}

export async function postAndDeserialize<T extends UnknownType>(
    pathname: string,
    schema: ZodType,
    body?: Record<string, unknown>,
    options?: {unauthorized: true; shortLocaleName: ShortLocaleNameEnum}
): Promise<T> {
    const url = `${rootApiUrl}${pathname}`;
    const response: Array<unknown> = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {
            ...mainApiHeaders,
            ...(options?.unauthorized ? {'Accept-Language': options.shortLocaleName} : getCsrfHeaders()),
        },
        body: body ? JSON.stringify(serialize(body)) : body,
    });

    return deserializeV2<T>(url, schema, response);
}

export function getUrlParameters(payload: ObjectToUrlParametersType): string {
    // eslint-disable-next-line no-undefined
    const hasDefinedValues = some(payload, (value) => value !== undefined);

    if (isEmpty(payload) && hasDefinedValues) {
        return '';
    }

    return `?${objectToUrlParameters(serializeToURLParameters(payload))}`;
}
