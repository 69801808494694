import {faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {faCheck, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Row, Tag} from 'antd';
import {useCallback} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../../../../provider/locale/translation/type';
import {getIsRemoveAllowed, getIsReportAllowed} from '../../../../../../../service/phototool/phototool-helper';
import {getLocalizedTagName} from '../../../../../../../service/phototool/phototool-tags-helper';
import {MediaItemType, PhotosReportDataType} from '../../../../../../../service/phototool/phototool-type';
import {PhotoReport} from '../../../../components/photo-report-modal/photo-report';

import * as styles from './photo-item-drawer.scss';

type PropsType = {
    isSelectMode: boolean;
    photo: MediaItemType;
    onReport?: (photos: Array<MediaItemType>, reportData: PhotosReportDataType) => Promise<void>;
    onSelect: () => void;
};

export function PhotoItemDrawer(props: PropsType): JSX.Element {
    const {isSelectMode, photo, onReport, onSelect} = props;

    const {shortLocaleName} = useLocale();

    const tags = photo.tags || [];

    const isRemoveAllowed = getIsRemoveAllowed(photo);
    const isReportAllowed = !isRemoveAllowed && getIsReportAllowed(photo);

    const ownerLocaleKey: LangKeyType = photo.own
        ? 'PHOTO_TOOL__PHOTO_META__OWNER__TRUE'
        : 'PHOTO_TOOL__PHOTO_META__OWNER__FALSE';

    const onReportWrapper = useCallback(
        (reportData: PhotosReportDataType) => onReport?.([photo], reportData) || Promise.resolve(),
        [photo, onReport]
    );

    return (
        <>
            <Row align="middle" className={styles.controls} gutter={4} justify="space-between" wrap={false}>
                <div className={styles.owner_label}>
                    <Locale stringKey="PHOTO_TOOL__PHOTO_META__OWNER__LABEL" />:
                </div>

                <div className={styles.owner_value}>
                    <Locale stringKey={ownerLocaleKey} />
                </div>

                <PhotoReport
                    onReport={onReportWrapper}
                    photos={[photo]}
                    renderControl={(showModal: VoidFunction, removeSelected: VoidFunction): JSX.Element | null => {
                        if (isSelectMode) {
                            return null;
                        }

                        return (
                            <Col>
                                <Row wrap={false}>
                                    <Button
                                        icon={<FontAwesomeIcon icon={faCheck} />}
                                        onClick={onSelect}
                                        size="middle"
                                        type="link"
                                    />

                                    {isRemoveAllowed && (
                                        <Button
                                            danger
                                            icon={<FontAwesomeIcon icon={faTrashCan} />}
                                            onClick={removeSelected}
                                            size="middle"
                                            type="link"
                                        />
                                    )}

                                    {isReportAllowed && (
                                        <Button
                                            danger
                                            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
                                            onClick={showModal}
                                            size="middle"
                                            type="link"
                                        />
                                    )}
                                </Row>
                            </Col>
                        );
                    }}
                />
            </Row>

            {tags.length > 0 && !isSelectMode && (
                <div className={styles.tags}>
                    {tags.slice(0, 4).map((tag) => {
                        return <Tag key={tag.id}>{getLocalizedTagName(shortLocaleName, tag)}</Tag>;
                    })}
                </div>
            )}
        </>
    );
}
