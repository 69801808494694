import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    MarkAllNotificationAsReadResultType,
    MarkNotificationAsReadResultType,
    NotificationResultType,
} from './notification-type';

export function getNotification(): Promise<NotificationResultType> {
    return fetchX<NotificationResultType>(rootApiUrl + '/v1/notifications/');
}

export function markAllNotificationAsRead(): Promise<MarkAllNotificationAsReadResultType> {
    return fetchX<MarkAllNotificationAsReadResultType>(rootApiUrl + '/v1/notifications/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({read: 'true'}),
    });
}

export function markNotificationAsRead(notificationId: string): Promise<MarkNotificationAsReadResultType> {
    return fetchX<MarkNotificationAsReadResultType>(rootApiUrl + `/v1/notifications/${notificationId}/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({notification_id: notificationId, read: 'true'}),
    });
}
