import {Button, Select} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {DashboardPostponeRecommendationPayloadApiType} from '../../../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-api-type';

import {
    RecommendationPostponeEnum,
    recommendationPostponeOptionPayload,
    recommendationPostponeOptionText,
} from './dashboard-recommendation-postpone-form-const';
import * as styles from './dashboard-recommendation-postpone-form.scss';

const {Option} = Select;

type PropsType = {
    postponeCard: (payload: DashboardPostponeRecommendationPayloadApiType) => void;
    isInProgress: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export function DashboardRecommendationsPostponeForm(props: PropsType): JSX.Element {
    const {postponeCard, isInProgress, setIsOpen} = props;
    const [postpone, setPostpone] = useState(RecommendationPostponeEnum.oneWeek);
    const {getLocalizedString} = useLocale();

    function handleChange(value: RecommendationPostponeEnum) {
        setPostpone(value);
    }

    function onSubmitConfirm() {
        postponeCard(recommendationPostponeOptionPayload[postpone]);
    }

    function onSubmitCancel() {
        setIsOpen(false);
    }

    return (
        <div className={styles.postpone_popover_content_wrapper}>
            <Select defaultValue={postpone} onChange={handleChange} size="small">
                {Object.values(RecommendationPostponeEnum).map((key: RecommendationPostponeEnum) => {
                    return (
                        <Option key={key} value={key}>
                            {getLocalizedString(recommendationPostponeOptionText[key])}
                        </Option>
                    );
                })}
            </Select>
            <div className={styles.postpone_popover_buttons_wrapper}>
                <Button disabled={isInProgress} onClick={onSubmitCancel} size="small" type="text">
                    <Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__CANCEL_BUTTON" />
                </Button>
                <Button disabled={isInProgress} onClick={onSubmitConfirm} size="small" type="primary">
                    <Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_BUTTON" />
                </Button>
            </div>
        </div>
    );
}
