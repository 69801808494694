import {faNewspaper} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FC, useCallback} from 'react';

import {appRoute} from '../../../../app-route';
import {PostsCalendar} from '../../../../page/main/posts/calendar/posts-calendar';
import {CreatePost} from '../../../../page/main/posts/create-post/create-post';
import {EditPost} from '../../../../page/main/posts/edit-post/edit-post';
import {PostActivation} from '../../../../page/main/posts/post-activation/post-activation';
import {PostDetailsPage} from '../../../../page/main/posts/post-details-page/post-details-page';
import {Posts} from '../../../../page/main/posts/posts';
import {ViewPost} from '../../../../page/main/posts/view-post/view-post';
import {useLicenses} from '../../../../provider/license/license-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {getFeatureOrLandingPage} from '../../../navigation/navigation-helper';
import {NavigationItemConfigType} from '../routing-type';

import {getIsUserAvailable} from './app-navigation-helper';

export function usePostManagementNavigation(): Array<NavigationItemConfigType> {
    const {licenses, availableFeatures} = useLicenses();
    const {user, isReputationManager} = useUser();

    const getPostsFeatureOrLandingPage = useCallback(
        (pageComponent: FC<Record<string, unknown>>) => {
            return getFeatureOrLandingPage({
                license: licenses[FeaturesEnum.posts],
                activationComponent: PostActivation,
                pageComponent,
            });
        },
        [licenses]
    );

    const license = licenses[FeaturesEnum.posts];

    const isLicenseExpired = !license?.isActive;

    const expiredLicenseRedirect = isLicenseExpired
        ? {
              deniedPath: [
                  appRoute.postsManagementCreatePost.path,
                  appRoute.postsManagementCalendar.path,
                  appRoute.postsManagementViewPost.path,
                  appRoute.postsManagementEditPost.path,
                  appRoute.postsManagementPostDetails.path,
              ],
              redirectPath: appRoute.postsManagement.path,
          }
        : {};

    return [
        {
            isAvailable:
                getIsUserAvailable(user?.id || 0) && !isReputationManager && availableFeatures[FeaturesEnum.posts],
            isActivated: licenses[FeaturesEnum.posts]?.isActive,
            path: appRoute.postsManagement.path,
            icon: <FontAwesomeIcon icon={faNewspaper} />,
            labelLangKey: 'CATEGORY_NAME__POSTS',
            exact: true,
            component: getPostsFeatureOrLandingPage(Posts),
            ...expiredLicenseRedirect,
        },
        // todo: размещения в children выше дает саб-меню, подумать над этим
        {
            isOnlyRoute: true,
            isAvailable: !isReputationManager && availableFeatures[FeaturesEnum.posts],
            path: appRoute.postsManagementPostDetails.path,
            exact: true,
            component: getPostsFeatureOrLandingPage(PostDetailsPage),
        },
        {
            isOnlyRoute: true,
            isAvailable: !isReputationManager && availableFeatures[FeaturesEnum.posts],
            path: appRoute.postsManagementEditPost.path,
            exact: true,
            component: getPostsFeatureOrLandingPage(EditPost),
        },
        {
            isOnlyRoute: true,
            isAvailable: !isReputationManager && availableFeatures[FeaturesEnum.posts],
            path: appRoute.postsManagementViewPost.path,
            exact: true,
            component: getPostsFeatureOrLandingPage(ViewPost),
        },
        {
            isOnlyRoute: true,
            isAvailable: !isReputationManager && availableFeatures[FeaturesEnum.posts],
            path: appRoute.postsManagementCreatePost.path,
            exact: true,
            component: getPostsFeatureOrLandingPage(CreatePost),
        },
        {
            isOnlyRoute: true,
            isAvailable: !isReputationManager && availableFeatures[FeaturesEnum.posts],
            path: appRoute.postsManagementCalendar.path,
            exact: true,
            component: getPostsFeatureOrLandingPage(PostsCalendar),
        },
    ];
}
