import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../../../app-route';
import {Text} from '../../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {checkIsVideo, getPreviewSrc} from '../../../../../../../service/phototool/phototool-helper';
import {MediaItemType} from '../../../../../../../service/phototool/phototool-type';
import * as styles from '../photo-item.scss';

interface PropsType {
    photo: MediaItemType;
    width: number;
    height: number;
}

export function PhotoLazyLoadImage({photo, width, height}: PropsType): JSX.Element {
    const [hasError, setHasError] = useState(false);

    const isVideo = checkIsVideo(photo);
    const {domainName} = useDomainConfig();

    const previewSrc = getPreviewSrc({
        sourceSrc: isVideo ? photo.previewUrl ?? '' : photo.originalImageUrl,
        imageProxyParameters: {width},
        domainName,
    });

    return hasError ? (
        <NavigationLink
            to={generatePath(isVideo ? appRoute.photoToolVideoDetails.path : appRoute.photoToolDetails.path, {
                id: photo.id,
            })}
        >
            <div
                className={styles.PhotoItem_fallbackContainer}
                style={{
                    width,
                    height,
                }}
            >
                <Text lighter>
                    <FontAwesomeIcon icon={faTrash} />
                </Text>
            </div>
        </NavigationLink>
    ) : (
        <NavigationLink
            to={generatePath(isVideo ? appRoute.photoToolVideoDetails.path : appRoute.photoToolDetails.path, {
                id: photo.id,
            })}
        >
            <LazyLoadImage
                alt={String(photo.id)}
                className={styles.PhotoItem_img}
                onError={() => setHasError(true)}
                src={previewSrc}
            />
        </NavigationLink>
    );
}
