import {LangKeyType} from '../../../provider/locale/translation/type';

import {LandingListDescriptionItemType} from './landing-card-type';
// eslint-disable-next-line no-restricted-syntax
import * as commonLandingCardStyles from './landing-card.scss';
// eslint-disable-next-line no-restricted-syntax
import * as mainLandingCardStyles from './main-landing-card.scss';

export function getLandingCardStyles(isMainCard: boolean): typeof commonLandingCardStyles {
    return isMainCard ? mainLandingCardStyles : commonLandingCardStyles;
}

export function getLocaleItems(item: LangKeyType | LandingListDescriptionItemType): LandingListDescriptionItemType {
    if (typeof item === 'object') {
        return item;
    }

    return {stringKey: item};
}
