import {z as r} from 'zod';

import {FeaturesEnum} from '../../service/user/user-type';

export const brandSchema = r.object({
    id: r.number(),
    name: r.string(),
    isPossibleToCreateCompanyForUser: r.boolean().optional(),
    features: r.record(r.nativeEnum(FeaturesEnum), r.record(r.string(), r.boolean()).optional()).optional(),
    counterpartyId: r.number().optional(),
    companyLicenceBalance: r.number().optional(),
});

export type BrandType = r.infer<typeof brandSchema>;
