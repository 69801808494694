import {PhotoReportTypeEnum} from '../../../../../service/phototool/phototool-type';

export enum ReportFormFieldEnum {
    type = 'type',
    message = 'message',
}

export type ReportFormType = {
    [ReportFormFieldEnum.message]: string;
    [ReportFormFieldEnum.type]: PhotoReportTypeEnum | null;
};
