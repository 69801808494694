import {faFile} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {SyntheticEvent, useState} from 'react';

import {Spinner} from '../../../../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../../../service/analytics/analytics';
import {fetchReviewsReply} from '../../../../../../../../service/reviews/reviews-reply';
import {ResponseTemplateDataType} from '../../../../../../../../service/reviews/reviews-response-templates';
import {ReviewDataChildType} from '../../../../../../../../service/reviews/reviews-type';
import {IdNumberType} from '../../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';
import {handleReviewReplyExceptions} from '../../review-helper';
import {ReviewIconButton} from '../../review-icon-button/review-icon-button';

import * as styles from './reply-on-reply-form.scss';

type PropsType = {
    answer: ReviewDataChildType;
    isTestBrand: boolean;
    onClose: () => void;
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewId: number;
};

export function ReplyOnReplyForm(props: PropsType): JSX.Element {
    const {answer, isTestBrand, onClose, refreshReview, reviewId} = props;

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const [reply, setReply] = useState<string>('');
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );

    async function onSubmit() {
        if (!answer.replyId) {
            return;
        }

        setIsInProgress(true);

        try {
            await fetchReviewsReply(answer.replyId, {comment: reply.trim()});
            await refreshReview(reviewId);
            onClose();
        } catch (error: unknown) {
            handleReviewReplyExceptions(error, snackbar);
        } finally {
            setIsInProgress(false);
        }
    }

    function handleSelectTemplateClick() {
        track(AnalyticsTarget.ReviewsManagement.Reviews.ClickResponseTemplates);

        setIsSelectTemplatePopupOpen(true);
    }

    return (
        <form className={styles.review__edit_form}>
            <Spinner isShow={isInProgress} position="absolute" />

            <Input.TextArea
                autoSize={{minRows: 1, maxRows: 10}}
                className={styles.review__textarea}
                onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                    setReply(event.currentTarget.value);
                }}
                placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_REPLY_TEXT')}
                size="large"
                value={reply}
            />

            <div className={styles.review__button_wrapper}>
                <Button
                    disabled={reply.length === 0 || isTestBrand}
                    loading={isInProgress}
                    onClick={onSubmit}
                    type="primary"
                >
                    <Locale stringKey="REVIEWS__REPLY" />
                </Button>

                <Button onClick={onClose}>
                    <Locale stringKey="BUTTON__CANCEL" />
                </Button>

                <div className={styles.review__auto_reply_button_wrapper}>
                    <ReviewIconButton onClick={handleSelectTemplateClick}>
                        <FontAwesomeIcon icon={faFile} />
                    </ReviewIconButton>
                </div>
            </div>

            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        const [selectedAutoReply] = selectedResponseTemplateList;
                        const message = selectedAutoReply?.message;

                        if (message) {
                            setReply(message);
                        }

                        setIsSelectTemplatePopupOpen(false);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </form>
    );
}
