import {faEyeSlash, faRotateLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Popover, Tag} from 'antd';
import {useState} from 'react';

import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {LocaleWithCatalogName} from '../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../provider/locale/locale';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {enUs} from '../../../../../provider/locale/translation/en-us/data';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {track} from '../../../../../service/analytics/analytics';
import {DashboardPostponeRecommendationPayloadApiType} from '../../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-api-type';
import {useDashboardRecommendation} from '../../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-hook';
import {DashboardRecommendationTypeEnum} from '../../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-type';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {ProvidersIdsEnum} from '../../../../../util/type';

import {
    recommendationDetails,
    recommendationIcons,
    recommendationLinkAnalyticsMap,
    recommendationLinkText,
    recommendationLinkUrl,
    recommendationSubtitles,
    recommendationTagCounterName,
    recommendationTitles,
} from './dashboard-recommendation-card-const';
import {DashboardRecommendationsPostponeForm} from './dashboard-recommendation-postpone-form/dashboard-recommendation-postpone-form';
import * as styles from './dashboard-recommendation-card.scss';

type PropsType = {
    count: number;
    recommendationKey: DashboardRecommendationTypeEnum;
    postponedDate?: Date | null;
    onPostponeChange: (recommendationKey: DashboardRecommendationTypeEnum, postpone: number | null) => void;
};

export function DashboardRecommendationsCard(props: PropsType): JSX.Element {
    const {recommendationKey, postponedDate, onPostponeChange, count} = props;

    const {getFormattedNumber} = useFormat();

    const {snackbar} = useSnackbar();
    const {cancelRecommendationPostpone, postponeRecommendation, isInProgress} = useDashboardRecommendation();

    function onLinkClick() {
        track(
            recommendationLinkAnalyticsMap[recommendationKey],
            enUs[recommendationTitles[recommendationKey]].replace('{catalogName}', 'Facebook')
        );
    }

    function processCancelPostpone() {
        cancelRecommendationPostpone(recommendationKey)
            .then(() => {
                onPostponeChange(recommendationKey, null);
            })
            .catch(() => {
                snackbar.error(
                    <Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ERROR_CANCELING_POSTPONE" />
                );
            });
    }

    function processPostpone(payload: DashboardPostponeRecommendationPayloadApiType) {
        postponeRecommendation(recommendationKey, payload)
            .then((response) => {
                onPostponeChange(recommendationKey, response.postponed);
            })
            .catch(() => {
                snackbar.error(<Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ERROR_POSTPONE" />);
            });
    }

    const {getFormattedDateTime} = useFormat();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Card className={styles.main_card_wrapper}>
            <div className={styles.card__wrapper}>
                <div
                    className={classNames(styles.card__icon__wrapper, styles.card__icon, {
                        [styles.card__icon__postponed]: Boolean(postponedDate),
                    })}
                >
                    {recommendationIcons[recommendationKey]}
                </div>
                <div className={styles.card_content__wrapper}>
                    <div className={styles.card__text_and_hide_button_wrapper}>
                        <div>
                            <div className={styles.card_head__wrapper}>
                                <p className={styles.card__name}>
                                    <LocaleWithCatalogName
                                        catalogId={ProvidersIdsEnum.facebook}
                                        namePlaceholder="FB"
                                        stringKey={recommendationTitles[recommendationKey]}
                                    />
                                </p>
                                <p className={styles.card__name__secondary}>
                                    <LocaleWithCatalogName
                                        catalogId={ProvidersIdsEnum.facebook}
                                        namePlaceholder="FB"
                                        stringKey={recommendationSubtitles[recommendationKey]}
                                    />
                                </p>
                            </div>
                            <p className={styles.card__description}>
                                <LocaleWithCatalogName
                                    catalogId={ProvidersIdsEnum.facebook}
                                    namePlaceholder="FB"
                                    stringKey={recommendationDetails[recommendationKey]}
                                />
                            </p>
                        </div>

                        {postponedDate ? (
                            <Button
                                className={styles.card__action_button}
                                icon={<FontAwesomeIcon icon={faRotateLeft} />}
                                loading={isInProgress}
                                onClick={processCancelPostpone}
                                size="middle"
                                type="text"
                            />
                        ) : (
                            <Popover
                                content={
                                    <DashboardRecommendationsPostponeForm
                                        isInProgress={isInProgress}
                                        postponeCard={processPostpone}
                                        setIsOpen={setIsOpen}
                                    />
                                }
                                open={isOpen}
                                placement="bottomRight"
                                title={<Locale stringKey="DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_TITLE" />}
                                trigger="click"
                            >
                                <Button
                                    className={styles.card__action_button}
                                    icon={<FontAwesomeIcon icon={faEyeSlash} />}
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                    size="middle"
                                    type="text"
                                />
                            </Popover>
                        )}
                    </div>

                    <div className={styles.card__action_wrapper}>
                        <Tag
                            className={classNames(styles.card_counter, {
                                [styles.card_counter__disabled]: Boolean(postponedDate),
                            })}
                        >
                            <LocalePlural
                                count={count}
                                fewKey={recommendationTagCounterName[recommendationKey].few}
                                manyKey={recommendationTagCounterName[recommendationKey].many}
                                singularKey={recommendationTagCounterName[recommendationKey].singular}
                                valueMap={{
                                    count: getFormattedNumber(count),
                                }}
                            />
                        </Tag>
                        {postponedDate ? (
                            <span className={styles.card__postponed_date}>
                                <Locale
                                    stringKey="DASHBOARD_PAGE__RECOMMENDATION__POSTPONED_TO"
                                    valueMap={{
                                        date: getFormattedDateTime(postponedDate),
                                    }}
                                />
                            </span>
                        ) : (
                            <NavigationLink onClick={onLinkClick} to={recommendationLinkUrl[recommendationKey]}>
                                <Locale stringKey={recommendationLinkText[recommendationKey]} />
                            </NavigationLink>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
}
