import {Skeleton, Space, Statistic} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../service/user-management/user-management-user';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import * as styles from './user-statistics.scss';

export function UserStatistics(): JSX.Element | null {
    const {data: user} = useUser();
    const {getFormattedNumber} = useFormat();

    if (!user) {
        return (
            <Space className={styles.UserStatistics_skeleton}>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </Space>
        );
    }

    return (
        <>
            <Statistic
                className={styles.UserStatistics_statistic}
                title={<Text secondary stringKey="USERS__HEADER__STATISTIC__CREATED_BY_YOU" />}
                value={user.usersCreatedByMeCount}
            />

            <Statistic
                className={styles.UserStatistics_statistic}
                suffix={
                    <Text className={styles.UserStatistics_suffix} secondary>
                        {' / '}
                        {getFormattedNumber(user.licenceTotal)}
                    </Text>
                }
                title={
                    <AdditionalInfo
                        className={styles.UserStatistics_additionalInfo}
                        leftNode={<Text secondary stringKey="USERS__HEADER__STATISTIC__VISIBLE" />}
                        placement="topRight"
                        title={<Locale stringKey="USERS__HEADER__LIMITS__TITLE" />}
                    >
                        <Locale
                            stringKey={
                                user.role.isOwner
                                    ? 'USERS__HEADER__LIMITS__DESCRIPTION__OWNER'
                                    : 'USERS__HEADER__LIMITS__DESCRIPTION'
                            }
                        />
                    </AdditionalInfo>
                }
                value={getFormattedNumber(user.licenceAvailable)}
            />
        </>
    );
}
