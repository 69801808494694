import {Row} from 'antd';

import {AdditionalInfo} from '../../../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../../../provider/locale/localization';

type PropsType = {
    isEditMode: boolean;
};

export function AccountLoginLabel(props: PropsType): JSX.Element {
    const {isEditMode} = props;

    return (
        <Row>
            {isEditMode ? (
                <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__EDIT__LOGIN_INPUT" />
            ) : (
                <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_INPUT" />
            )}
            &nbsp;
            <AdditionalInfo title={<Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_INPUT" />}>
                <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_HINT" />
            </AdditionalInfo>
        </Row>
    );
}
