import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

export type ReviewCatalogType = {
    can_abuse_review: boolean;
    icon: string;
    id: number;
    logo: string;
    name: string;
    slug: null;
    is_external_reviews: boolean;
    is_medicine: boolean;
    gpt_suggest_supported: boolean;
};

const reviewsCatalogListUrl = `${rootApiUrl}/v4/reviews/catalogs/`;

function fetchReviewsCatalogList(): Promise<Array<ReviewCatalogType>> {
    return fetchX<Array<ReviewCatalogType>>(reviewsCatalogListUrl);
}

export function useReviewsCatalogList(): UseQueryResult<Array<ReviewCatalogType>> {
    return useQuery([reviewsCatalogListUrl], fetchReviewsCatalogList, {
        refetchOnWindowFocus: false,
    });
}
