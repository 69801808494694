import {LangKeyType} from '../../../../provider/locale/translation/type';
import {FakeRejectReasonEnum} from '../../../../service/fakes/fakes-type';

export const fakeRejectReasonLabels: Record<FakeRejectReasonEnum, LangKeyType> = {
    [FakeRejectReasonEnum.ExistedBefore]: 'FAKES__RESULTS__REJECTED_POPUP__STATUS__PRE_EXISTING',
    [FakeRejectReasonEnum.DifferentCategories]: 'FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_CATEGORY',
    [FakeRejectReasonEnum.DifferentNames]: 'FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_NAMES',
    [FakeRejectReasonEnum.DifferentAddresses]: 'FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_ADDRESS',
    [FakeRejectReasonEnum.DifferentNetworks]: 'FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_NETWORK',
};
