import {Empty} from '../../../../../layout/empty/empty';
import * as styles from '../analytics-workload.scss';

export function AnalyticsWorkloadEmpty(): JSX.Element {
    return (
        <Empty
            secondaryText="DASHBOARD_PAGE__WORKLOAD__EMPTY"
            secondaryTextClassName={styles.AnalyticsWorkload_emptyText}
        />
    );
}
