import {MutableRefObject, useEffect, useState} from 'react';

import {noop} from '../function';

export function useDomElementRect<T extends Element>(ref: MutableRefObject<T | null>): DOMRectReadOnly | null {
    const [elementRect, setElementRect] = useState<DOMRectReadOnly | null>(null);

    useEffect(() => {
        const elementInRef = ref.current;

        if (!elementInRef) {
            return () => noop;
        }

        setElementRect(elementInRef.getBoundingClientRect());

        let observerStarted = true;
        let resizeObserverEntries: Array<ResizeObserverEntry> = [];

        const resizeObserver = new ResizeObserver((entries: Array<ResizeObserverEntry>) => {
            resizeObserverEntries = entries;

            if (observerStarted) {
                observerStarted = false;
                return;
            }

            resizeObserver.disconnect();

            const entry = entries[0];

            if (entry) {
                setElementRect(entry.contentRect); // no throttle; we suggest users resize window rarely
            }

            observerStarted = true;
            requestAnimationFrame(() => {
                if (entry) {
                    resizeObserver.observe(elementInRef);
                }
            });
        });

        resizeObserver.observe(elementInRef);

        return () => {
            resizeObserverEntries.forEach((entry) => entry.target.remove());
            resizeObserver.unobserve(elementInRef);
        };
    }, [ref]);

    return elementRect;
}
