import {z as r, ZodType} from 'zod';

import {UseHookType} from '../api-hook/api-hook-type';

const knowledgeArticleSchema = r.object({
    articleId: r.number(),
    title: r.string(),
});

export const knowledgeDataArticleSchema = r.object({
    id: r.number(),
    title: r.string(),
    body: r.string(),
    isUseful: r.boolean().nullable(),
    likesCount: r.number(),
    dislikesCount: r.number(),
    categoryId: r.number(),
    categoryTitle: r.string(),
    categoryContent: r.array(knowledgeArticleSchema),
});

export type KnowledgeDataArticleType = r.infer<typeof knowledgeDataArticleSchema>;

export type KnowledgeArticleType = r.infer<typeof knowledgeArticleSchema>;

export type KnowledgeDataChildType = {
    categoryId: number;
    title: string;
    content: Array<KnowledgeArticleType | KnowledgeDataChildType>;
};

const knowledgeCategoryArticleSchema: ZodType<KnowledgeDataChildType> = r.object({
    categoryId: r.number(),
    title: r.string(),
    content: r.lazy(() => r.array(knowledgeCategoryArticleSchema.or(knowledgeArticleSchema))),
});

export const knowledgeResponseSchema = r.object({
    content: r.array(knowledgeCategoryArticleSchema),
});

export type KnowledgeOptionsType = {
    q: string | null;
};

export type ArticleIsUsefulType = {
    is_useful: boolean | null;
};

export type KnowledgeResponseType = r.infer<typeof knowledgeResponseSchema>;

export type UseKnowledgeType = UseHookType<KnowledgeResponseType> & {
    load: (options: KnowledgeOptionsType) => void;
};

export type UseKnowledgeArticleType = UseHookType<KnowledgeDataArticleType> & {
    load: () => Promise<void>;
};
