import {Text} from '../../../../../../component/text/text';
import {FakesResultType} from '../../../../../../service/fakes/fakes-results';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import {FAKE_CLIENT_STATUS_TO_TAG_MAPPING} from './status-column-const';

type PropsType = Pick<FakesResultType, 'clientStatus' | 'statusChangeTime'>;

export function StatusColumn(props: PropsType): JSX.Element {
    const {clientStatus, statusChangeTime} = props;
    const date = new Date(statusChangeTime);

    const {getFormattedDateTime} = useFormat();

    return (
        <>
            {FAKE_CLIENT_STATUS_TO_TAG_MAPPING[clientStatus]}

            {!Number.isNaN(date.getTime()) && (
                <Text block secondary>
                    {getFormattedDateTime(date)}
                </Text>
            )}
        </>
    );
}
