import {Spin} from 'antd';
import {Context, createContext, PropsWithChildren, useEffect, useMemo, useState} from 'react';

import {LogoAnimated} from '../../component/logo-animated/logo-animated';
import {saveToLocalStorage} from '../../util/local-storage';
import {useDomainConfig} from '../domain-config/domain-config-hook';
import {DomainNameEnum} from '../domain-config/domain-config-type';
import {useUser} from '../user/user-hook';

import {uiProviderLocalStorageKey} from './ui-context-const';
import {checkUiThemeSettigs, getDefaultUiContext} from './ui-context-helper';
import {UiContextSavedDataType, UiContextType} from './ui-context-type';

const defaultUiContext = getDefaultUiContext();

export const UiContext: Context<UiContextType> = createContext<UiContextType>(defaultUiContext);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function UiProvider(props: PropsType): JSX.Element {
    const {children} = props;
    const [isLeftSideOpen, setIsLeftSideOpen] = useState<boolean>(defaultUiContext.isLeftSideOpen);
    const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState<boolean>(
        defaultUiContext.isMobileNavigationOpen
    );

    const {user} = useUser();

    useEffect(() => {
        if (user?.isStaff) {
            checkUiThemeSettigs();
        }
    }, [user]);

    useEffect(() => {
        saveToLocalStorage<UiContextSavedDataType>({isLeftSideOpen}, uiProviderLocalStorageKey);
    }, [isLeftSideOpen]);

    const providerData: UiContextType = useMemo((): UiContextType => {
        return {
            isLeftSideOpen,
            setIsLeftSideOpen,
            isMobileNavigationOpen,
            setIsMobileNavigationOpen,
        };
    }, [isLeftSideOpen, setIsLeftSideOpen, isMobileNavigationOpen, setIsMobileNavigationOpen]);

    const {domainName} = useDomainConfig();

    if (domainName === DomainNameEnum.rocketData) {
        Spin.setDefaultIndicator(<LogoAnimated size={36} withPulsation />);
    }

    return <UiContext.Provider value={providerData}>{children}</UiContext.Provider>;
}
