import {Text} from '../../../../../../component/text/text';
import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyErrorType, CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../util/type';

import {TemporaryWorkdaysFormItem} from './temporary-workdays-form-item/temporary-workdays-form-item';
import * as styles from './temporary-working-time-widget.scss';

type PropsType = {
    errorMessage: CompanyErrorType | null;
    isDoubleGisCompany: boolean;
};

export function TemporaryWorkingTimeWidget(props: PropsType): JSX.Element {
    const {errorMessage, isDoubleGisCompany} = props;

    const extraWorkingHours = Form.useWatch<CompanyKeyEnum.ExtraWorkingHours, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.ExtraWorkingHours
    );

    const {getCatalogName} = useCatalogInfo();

    function getCatalogMessage() {
        const hasYandex = extraWorkingHours?.some(({catalogId}) => catalogId === ProvidersIdsEnum.yandex);
        const hasGoogle = extraWorkingHours?.some(({catalogId}) => catalogId === ProvidersIdsEnum.google);

        if (hasYandex && hasGoogle) {
            return (
                <Locale
                    stringKey="COMPANY_FORM__WORKING_TIME__SEND_TEMPORARY_WORK_TWO_CATALOG"
                    valueMap={{
                        firstCatalog: getCatalogName(ProvidersIdsEnum.yandex),
                        secondCatalog: getCatalogName(ProvidersIdsEnum.googleMaps),
                    }}
                />
            );
        }

        if (hasYandex) {
            return (
                <Locale
                    stringKey="COMPANY_FORM__WORKING_TIME__SEND_TEMPORARY_WORK"
                    valueMap={{catalog: getCatalogName(ProvidersIdsEnum.yandex)}}
                />
            );
        }

        if (hasGoogle) {
            return (
                <Locale
                    stringKey="COMPANY_FORM__WORKING_TIME__SEND_TEMPORARY_WORK"
                    valueMap={{catalog: getCatalogName(ProvidersIdsEnum.googleMaps)}}
                />
            );
        }

        return null;
    }

    return (
        <>
            <Form.Item<CompanyFormType> name={CompanyKeyEnum.SpecialHours}>
                <TemporaryWorkdaysFormItem
                    errorMessage={errorMessage?.specialHours}
                    isDoubleGisCompany={isDoubleGisCompany}
                />
            </Form.Item>

            {getCatalogMessage() !== null && (
                <Text block className={styles.TemporaryWorkingTimeWidget_catalogMessage} secondary>
                    {getCatalogMessage()}
                </Text>
            )}
        </>
    );
}
