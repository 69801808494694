import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {faPenToSquare} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useState} from 'react';

import {useYandexStory} from '../../../service/yandex-stories/yandex-stories-hook';
import {YandexStoryStatusEnum, YandexStoryType} from '../../../service/yandex-stories/yandex-stories-type';
import {Text} from '../../text/text';
import {EditYandexStoryFormModal} from '../yandex-story-form/edit-yandex-story-form-modal';

type PropsType = {
    storyId: number;
    disabled?: boolean;
    iconOnly?: boolean;
    storyData?: YandexStoryType;
    useLocalData?: boolean;
    onEditSuccess?: VoidFunction;
};

export function YandexStoryEdit(props: PropsType): JSX.Element | null {
    const {storyId, disabled, iconOnly, storyData, useLocalData, onEditSuccess} = props;

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const {data: story} = useYandexStory(storyId, !useLocalData && !storyData && isEditModalOpen);

    const storyValue = useLocalData ? storyData : story;

    function handleClick(): void {
        setIsEditModalOpen(true);
    }

    return (
        <>
            {iconOnly ? (
                <Button
                    disabled={disabled}
                    icon={<FontAwesomeIcon icon={faPenToSquare} />}
                    onClick={handleClick}
                    type="text"
                />
            ) : (
                <Button
                    disabled={disabled}
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={handleClick}
                    type={storyValue?.status === YandexStoryStatusEnum.Rejected ? 'primary' : 'default'}
                >
                    <Text inheritColor stringKey="BUTTON__EDIT" />
                </Button>
            )}
            {isEditModalOpen && storyValue && (
                <EditYandexStoryFormModal
                    close={() => {
                        setIsEditModalOpen(false);
                    }}
                    onEditSuccess={onEditSuccess}
                    storyData={storyValue}
                    type={storyValue.type}
                />
            )}
        </>
    );
}
