import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {localeNameToLanguageNameMap} from '../../../../../provider/locale/locale-context-const';
import {LocaleNameEnum} from '../../../../../provider/locale/locale-context-type';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useChangeLanguage} from '../../../../../util/change-language-hook/change-language-hook';
import {HeaderDropdownButton} from '../header-dropdown-button';

type PropsType = {
    icon: JSX.Element;
    text?: JSX.Element | null;
    className?: string;
};

export function LanguageMenu(props: PropsType): JSX.Element {
    const {icon, text, className} = props;

    const {onChangeLanguage} = useChangeLanguage();

    const {availableLocales} = useDomainConfig();
    const {localeName} = useLocale();

    return (
        <HeaderDropdownButton
            className={className}
            icon={icon}
            menu={{
                items: availableLocales.map((availableLocale) => {
                    return {
                        key: availableLocale,
                        label: localeNameToLanguageNameMap[availableLocale],
                    };
                }),
                onClick: ({key}) => onChangeLanguage(key as LocaleNameEnum),
                selectedKeys: [localeName],
            }}
            text={text}
        />
    );
}
