import {StepDataType} from './onboarding-hook';

export function calculateStepCompletionPercent(stepData: StepDataType | undefined): number {
    if (!stepData) {
        return 0;
    }

    const totalCatalogs = Object.keys(stepData).length;
    const completedCatalogs = Object.values(stepData).filter((catalog) =>
        typeof catalog === 'object' ? catalog?.confirmed : catalog
    ).length;

    return (completedCatalogs / totalCatalogs) * 100;
}
