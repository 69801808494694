import {
    faArrowsRotate,
    faCircleCheck,
    faCircleExclamation,
    faCircleXmark,
    faClock,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {StatisticCclsForCompanyType} from '../../../../../service/company/company-type';
import {CclStateStatusEnum} from '../../../../../util/type';

import * as styles from './ccl-status-column.scss';

export function getStatus(
    cclData?: StatisticCclsForCompanyType,
    isLinking?: boolean,
    isAccessTransferRequired?: boolean
): JSX.Element | string {
    if (isAccessTransferRequired) {
        return <FontAwesomeIcon className={styles.StatusIcon_needAction} fontSize={16} icon={faCircleExclamation} />;
    }

    if (cclData?.stateStatus === CclStateStatusEnum.unsupportedRegion) {
        return <FontAwesomeIcon className={styles.StatusIcon__disabled} fontSize={16} icon={faArrowsRotate} />;
    }

    if (cclData?.needAction || isLinking) {
        return <FontAwesomeIcon className={styles.StatusIcon_needAction} fontSize={16} icon={faCircleExclamation} />;
    }

    if (cclData?.stateStatus === CclStateStatusEnum.send) {
        return <FontAwesomeIcon className={styles.StatusIcon_sent} fontSize={16} icon={faArrowsRotate} />;
    }

    if (cclData?.stateStatus === CclStateStatusEnum.sync) {
        return <FontAwesomeIcon className={styles.StatusIcon_sync} fontSize={16} icon={faCircleCheck} />;
    }

    if (cclData?.stateStatus === CclStateStatusEnum.onModeration) {
        return <FontAwesomeIcon className={styles.StatusIcon__disabled} fontSize={16} icon={faClock} />;
    }

    if (cclData?.stateStatus === CclStateStatusEnum.noLicense) {
        return <FontAwesomeIcon className={styles.StatusIcon__disabled} fontSize={16} icon={faCircleXmark} />;
    }

    return '-';
}
