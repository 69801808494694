import {z as r} from 'zod';

import {ProvidersIdsEnum} from '../../util/type';

type CompanyPricesCatalogType = {
    id: ProvidersIdsEnum;
    name: string;
    logo: string;
    website: string;
};

enum CompanyPricesStatusEnum {
    sent = 'sent',
    deleted = 'deleted',
    error = 'error',
}

export type CompanyPricesType = {
    catalog: CompanyPricesCatalogType;
    product_count: number;
    status: CompanyPricesStatusEnum;
};

export type CompanyPostsType = {
    published: number;
    for_deleting: number;
    for_sending: number;
    start_date: string;
    end_date: string;
};

export enum ReviewsSentimentsEnum {
    negative = 'negative',
    positive = 'positive',
    neutral = 'neutral',
}

type ReviewsDynamicType = {
    month: string;
    total: number;
    date: string;
    sentiments: {
        [ReviewsSentimentsEnum.negative]: number;
        [ReviewsSentimentsEnum.positive]: number;
        [ReviewsSentimentsEnum.neutral]: number;
    };
};

export type ReviewsCatalogStatisticType = {
    name: string;
    count: number;
};

export type CompanyReviewsSummaryType = {
    date_start: string;
    date_end: string;
    count: number;
    no_answer_count: number;
    day_count_max: number;
    day_count_avg: number;
    positive_percent: number;
    nps: number;
    dynamic: Array<ReviewsDynamicType>;
    catalogs_statistic: Array<ReviewsCatalogStatisticType>;
};

export type ReviewsSummaryOptionsType = {
    companyId?: number;
    startDate: string;
    endDate: string;
};

export type UsePricesHookAdditionalType = {
    fetchPricesErrorCode: number | null;
};

const onlinePresenceBaseMetricsItem = r.object({
    current: r.number(),
    compared: r.number(),
    delta: r.number(),
});

export enum OnlinePresenceSummaryMetricsEnum {
    Impressions = 'impressions',
    DirectionRequests = 'direction_requests',
    WebsiteClicks = 'website_clicks',
    CallClicks = 'call_clicks',
}

const dynamicMetricsDataSchema = r.array(
    r.object({
        name: r.nativeEnum(OnlinePresenceSummaryMetricsEnum),
        values: r.array(r.number()),
    })
);

const onlinePresenceSummaryItemSchema = r.object({
    catalog: r.object({
        id: r.number(),
        name: r.string(),
        website: r.string(),
    }),
    startDate: r.string(),
    endDate: r.string(),
    baseMetrics: r.object({
        impressions: onlinePresenceBaseMetricsItem,
        interactions: onlinePresenceBaseMetricsItem,
        conversion: onlinePresenceBaseMetricsItem,
    }),
    dynamicMetrics: r.object({
        labels: r.array(r.string()),
        data: dynamicMetricsDataSchema,
    }),
});

const onlinePresenceSummarySchema = r.object({
    results: r.array(onlinePresenceSummaryItemSchema),
});

export type OnlinePresenceSummaryItemType = r.infer<typeof onlinePresenceSummaryItemSchema>;

export type OnlinePresenceSummaryType = r.infer<typeof onlinePresenceSummarySchema>;
