import {faUpload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {ReactElement} from 'react';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../../component/page-card-divider/main-page-card-divider';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';

import {FeedsTable} from './table/feeds-table';

export function FeedManagement(): ReactElement {
    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('FEED__MANAGEMENT__TITLE')} />
            <BreadCrumbs
                list={[
                    {path: appRoute.myCompanies.path, titleLangKey: 'CATEGORY_NAME__MY_COMPANIES'},
                    {path: appRoute.companyFeedManagement.path, titleLangKey: 'FEED__MANAGEMENT__TITLE'},
                ]}
            />

            <PageHeader>
                <Locale stringKey="FEED__MANAGEMENT__TITLE" />
                <NavigationLink to={appRoute.companyImport.path}>
                    <Button icon={<FontAwesomeIcon icon={faUpload} size="sm" />}>
                        <span>
                            <Locale stringKey="FEED__MANAGEMENT__HEADER__IMPORT_BUTTON" />
                        </span>
                    </Button>
                </NavigationLink>
            </PageHeader>

            <MainPageCardDivider />
            <FeedsTable />
        </Page>
    );
}
