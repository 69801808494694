import {faSpinnerThird, faUser} from '@fortawesome/pro-regular-svg-icons';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Select} from 'antd';
import {BaseSelectRef} from 'rc-select';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../../service/analytics/analytics';
import {
    ResponsibleUserType,
    updateResponsibleUser,
} from '../../../../../../../service/reviews/reviews-responsible-users';
import {classNames} from '../../../../../../../util/css';

import {handleFilterOption, isReviewResponsibleUserError} from './responsible-user-helper';
import * as styles from './responsible-user.scss';

type PropsType = {
    isLoading?: boolean;
    reviewId: number;
    responsibleUser: ResponsibleUserType | null;
    responsibleUsersList: Array<ResponsibleUserType> | null;
    onOpen?: () => void;
    onChange?: () => void;
    classNameButton?: string;
};

export type UsersListItemType = {
    key: string | null;
    label: string | null;
    email?: string;
};

const {Option} = Select;

export function ResponsibleUser(props: PropsType): JSX.Element {
    const {isLoading, responsibleUser, responsibleUsersList, reviewId, onOpen, onChange, classNameButton} = props;
    const {snackbar} = useSnackbar();

    const [selectedUser, setSelectedUser] = useState(responsibleUser || null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const selectRef = useRef<BaseSelectRef | null>(null);

    const {getLocalizedString} = useLocale();

    function handleDropdownOpen() {
        if (!onOpen || responsibleUsersList) {
            return;
        }

        onOpen();
    }

    function handleOpenSelect() {
        setIsFocused(true);
        selectRef.current?.focus();
        handleDropdownOpen();
    }

    useEffect(() => {
        function handleClick({target}: MouseEvent) {
            if (!containerRef.current?.contains(target as HTMLElement) && isFocused) {
                setIsFocused(false);
            }
        }

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, [isFocused]);

    useEffect(() => {
        setSelectedUser(responsibleUser);
    }, [responsibleUser]);

    const handleResponsibleUserSelect = useCallback(
        async (newUser: string) => {
            try {
                setIsUpdating(true);
                const {newResponsibleUserId} = await updateResponsibleUser(reviewId, {
                    responsible_user: newUser ? Number(newUser) : null,
                });
                const newSelectedUser =
                    responsibleUsersList && responsibleUsersList.find((user) => user.id === newResponsibleUserId);

                setSelectedUser(newSelectedUser || null);

                track(AnalyticsTarget.ReviewsManagement.Reviews.AddResponsibleUser);

                if (onChange) {
                    onChange();
                }
            } catch (error: unknown) {
                const description = isReviewResponsibleUserError(error) ? (
                    <span>{error.jsonData.status}</span>
                ) : (
                    <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />
                );

                snackbar.error({
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                    description,
                });
            } finally {
                setIsUpdating(false);
                setIsFocused(false);
            }
        },
        [reviewId, responsibleUsersList, onChange, snackbar]
    );

    const userNotSelectedLabel = getLocalizedString('REVIEWS__RESPONSIBLE_USER__NOT_SELECTED');

    const usersList: Array<UsersListItemType> = useMemo(() => {
        const users =
            selectedUser !== null
                ? [
                      {
                          key: 'null',
                          label: userNotSelectedLabel,
                      },
                  ]
                : [];

        return [
            ...users,
            ...(responsibleUsersList || [])
                .filter((user) => user.id !== selectedUser?.id)
                .map((user) => ({
                    key: String(user.id),
                    label: user.fullName,
                    email: user.email,
                })),
        ];
    }, [responsibleUsersList, selectedUser, userNotSelectedLabel]);

    return (
        <div
            className={classNames(styles.ResponsibleUser, isUpdating ? styles.ResponsibleUser__disabled : '')}
            ref={containerRef}
        >
            <div
                className={classNames(styles.ResponsibleUser_button, classNameButton)}
                onClick={handleOpenSelect}
                onKeyDown={handleOpenSelect}
                role="button"
                tabIndex={0}
            >
                <FontAwesomeIcon className={styles.ResponsibleUser_icon} icon={faUser} />
                <span className={styles.ResponsibleUser_text}>
                    {selectedUser ? (
                        selectedUser.fullName || selectedUser.email
                    ) : (
                        <Locale stringKey="REVIEWS__RESPONSIBLE_USER__NOT_SELECTED" />
                    )}
                </span>
                {isUpdating || isLoading ? (
                    <FontAwesomeIcon icon={faSpinnerThird} spin />
                ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                )}
            </div>
            <div className={styles.ResponsibleUser_selectWrapper}>
                <FontAwesomeIcon
                    className={classNames(styles.ResponsibleUser_icon, styles.ResponsibleUser_selectIcon)}
                    icon={faUser}
                />

                <Select<string>
                    className={classNames(
                        styles.ResponsibleUser_select,
                        isFocused ? styles.ResponsibleUser_select__opened : ''
                    )}
                    filterOption={(input, option) => handleFilterOption(input, usersList, option)}
                    loading={isLoading}
                    onChange={handleResponsibleUserSelect}
                    onDropdownVisibleChange={(visible) => visible && handleDropdownOpen()}
                    open={isFocused}
                    placeholder={<Locale stringKey="REVIEWS__RESPONSIBLE_USER__PLACEHOLDER" />}
                    ref={selectRef}
                    showSearch
                    value={selectedUser ? selectedUser.fullName || selectedUser.email : userNotSelectedLabel}
                >
                    {usersList.map((user) => (
                        <Option key={user.key} value={user.key}>
                            <span className={styles.ResponsibleUser_name}>{user.label}</span>
                            {user.email && (
                                <span className={styles.ResponsibleUser_email} title={user.email}>
                                    {user.email}
                                </span>
                            )}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
}
