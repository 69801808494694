/* eslint sort-keys: 'error' */

import {ThemeConfig} from 'antd';

import {
    danger60,
    gray7,
    gray8,
    gray9,
    green60,
    orange60,
    primary40,
    primary50,
    primary60,
} from '../../css/var-export.scss';

import {getThemeColor as getColor} from './app-theme-helper';

export const APP_THEME: ThemeConfig = {
    components: {
        Form: {
            colorTextHeading: gray8,
        },
        Slider: {
            handleSize: 12,
            railSize: 8,
        },
        Table: {
            borderRadius: 6,
            borderRadiusLG: 6,
            borderRadiusOuter: 6,
            borderRadiusSM: 6,
            borderRadiusXS: 6,
        },
    },
    token: {
        borderRadius: 6,
        borderRadiusLG: 8,
        borderRadiusOuter: 6,
        borderRadiusSM: 4,
        borderRadiusXS: 4,
        colorError: getColor(danger60),
        colorInfo: getColor(primary50),
        colorInfoActive: getColor(primary40),
        colorInfoHover: getColor(primary60),
        colorPrimary: getColor(primary50),
        colorSuccess: getColor(green60),
        colorText: getColor(gray9),
        colorTextSecondary: getColor(gray8),
        colorTextTertiary: getColor(gray7),
        colorWarning: getColor(orange60),
        fontFamily: 'Inter, sans-serif',
        fontWeightStrong: 500,
    },
};
