import {Alert} from 'antd';
import {FormListProps} from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import {useState} from 'react';

import {LocaleWithCatalogName} from '../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {CompanyCatalogDescriptionsType} from '../../../../../../../service/company-v2/types/company-descriptions-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {Unsupported2GisFacebookCountryEnum} from '../../../../company-form-const';
import * as descriptionsWidgetStyles from '../descriptions-widget.scss';
import {CATALOG_DESCRIPTION_LIMITS} from '../descriptions-widget-const';

type PropsType = {
    name: FormListProps['name'];
    maxLength?: number;
    isDoubleGisCompany: boolean;
};

function facebookDescriptionsExist(extraDescriptions: Array<CompanyCatalogDescriptionsType>): boolean {
    return extraDescriptions.some((extraDescription) => extraDescription.catalogId === ProvidersIdsEnum.facebook);
}

export function ShortDescriptionWithFacebookFormItem(props: PropsType): JSX.Element {
    const {name, maxLength, isDoubleGisCompany} = props;

    const {getLocalizedString} = useLocale();

    const address = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>>(CompanyKeyEnum.Address);

    const [isValidForFacebook, setIsValidForFacebook] = useState<boolean>(true);
    const [isFacebookDescriptionAdded, setIsFacebookDescriptionAdded] = useState<boolean>(false);
    const facebookMaxLength = CATALOG_DESCRIPTION_LIMITS.short[ProvidersIdsEnum.facebook];
    const isShowFacebookAlert =
        !isValidForFacebook &&
        !isFacebookDescriptionAdded &&
        (isDoubleGisCompany ? address?.country?.id !== Unsupported2GisFacebookCountryEnum.RussianFederation : true);

    return (
        <div className={descriptionsWidgetStyles.descriptions_widget__input}>
            <Form.Item<CompanyFormType>
                noStyle
                shouldUpdate={(previous, current) => {
                    if (previous?.extraDescriptions === current?.extraDescriptions) {
                        return false;
                    }

                    setIsFacebookDescriptionAdded(facebookDescriptionsExist(current.extraDescriptions));
                    return true;
                }}
            >
                {() => (
                    <Form.Item
                        label={<Locale stringKey="COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__LABEL" />}
                        name={name}
                    >
                        <TextArea
                            maxLength={maxLength}
                            onChange={(event) =>
                                setIsValidForFacebook(event.target.value.trim().length <= facebookMaxLength)
                            }
                            placeholder={getLocalizedString(
                                'COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__PLACEHOLDER'
                            )}
                            rows={2}
                            showCount={Boolean(maxLength)}
                            size="large"
                        />
                    </Form.Item>
                )}
            </Form.Item>
            {isShowFacebookAlert && (
                <Alert
                    className={descriptionsWidgetStyles.descriptions_widget__alert}
                    closable
                    message={
                        <LocaleWithCatalogName
                            catalogId={ProvidersIdsEnum.facebook}
                            namePlaceholder="FB"
                            stringKey="COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__FB_WARNING"
                            valueMap={{
                                maxLength: String(facebookMaxLength),
                            }}
                        />
                    }
                    type="warning"
                />
            )}
        </div>
    );
}
