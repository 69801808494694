import {faCircleQuestion} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, List} from 'antd';
import {isBoolean, isObjectLike} from 'lodash';
import {useState} from 'react';

import {Meta} from '../../../../../../../component/meta/meta';
import {Text} from '../../../../../../../component/text/text';
import {Page} from '../../../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../../../../layout/page-card/page-card';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {
    useGoogleConfirmationCompanies,
    useGoogleConfirmationRequests,
} from '../../../../../../../service/google/confirmation/google-confirmation-hook';
import {
    GoogleVerificationMethodEnum,
    GoogleVerificationOptionResponseType,
} from '../../../../../../../service/google/confirmation/google-confirmation-type';
import {GoogleCclStatusEnum} from '../../../../../../../service/google/google-type';
import {classNames} from '../../../../../../../util/css';
import {getEnumValue} from '../../../../../../../util/enum';
import {useUrl} from '../../../../../../../util/url-hook/url-hook';
import * as stepStyles from '../../../common/step-styles/step-styles.scss';
import {GoogleAccountSyncProvider} from '../../../google-account-sync-context';
import {Tips} from '../../../tips/tips';
import {ProcessingStepRightsModal} from '../../processing/location-modal/rights-modal/processing-step-rights-modal';

import {CodeEnterModal} from './code-enter-modal/code-enter-modal';
import {CodeRequestModal} from './code-request-modal/code-request-modal';
import {ConfirmationsHeader} from './confirmations-header/confirmations-header';
import {ConfirmationsListItem} from './confirmations-list-item/confirmations-list-item';
import {GoogleConfirmationsUrlQueryType, GoogleConfirmationTabEnum as TabEnum} from './google-confirmations-type';
import {RequestsListItem} from './requests-list-item/requests-list-item';
import * as styles from './google-confirmations.scss';

// eslint-disable-next-line complexity
export function GoogleConfirmations(): JSX.Element {
    const {queries} = useUrl<GoogleConfirmationsUrlQueryType>();
    const {getLocalizedString} = useLocale();

    const {status, isMassConfirmation} = queries;
    const urlStatus = getEnumValue(GoogleCclStatusEnum, status);

    const [activeTab, setActiveTab] = useState(isMassConfirmation === 'true' ? TabEnum.Request : TabEnum.Single);
    const [statusFilter, setStatusFilter] = useState<Array<GoogleCclStatusEnum>>(urlStatus ? [urlStatus] : []);
    const [showCompletedRequests, setShowCompletedRequests] = useState(false);

    const [codeRequestCompanyId, setCodeRequestCompanyId] = useState<{id: number; repeat: boolean} | null>(null);
    const [codeEnterCompanyId, setCodeEnterCompanyId] = useState<{id: number; repeat: boolean} | null>(null);
    const [rightsCompanyId, setRightsCompanyId] = useState<number | null>(null);
    const [requestedConfirmations, setRequestedConfirmations] = useState<
        Record<string, GoogleVerificationOptionResponseType | true> // true === code sent, company synchronized
    >({});

    const {
        data: confirmations,
        pagination: confirmationsPagination,
        isLoading: isConfirmationsLoading,
        tips: confirmationsTips,
    } = useGoogleConfirmationCompanies(statusFilter);

    const {
        data: requests,
        pagination: requestsPagination,
        isLoading: isRequestsLoading,
        tips: requestsTips,
    } = useGoogleConfirmationRequests(showCompletedRequests);

    const codeRequestCompany = confirmations?.results.find(({company}) => company.pk === codeRequestCompanyId?.id);
    const codeEnterCompany = confirmations?.results.find(({company}) => company.pk === codeEnterCompanyId?.id);
    const rightsCompany = confirmations?.results.find(({company}) => company.pk === rightsCompanyId);
    const requestedConfirmation = codeEnterCompanyId ? requestedConfirmations[codeEnterCompanyId.id] : null;

    function handleCodeRequestModal(confirmationId: number, response: GoogleVerificationOptionResponseType) {
        setRequestedConfirmations((codeConfirmations) => ({...codeConfirmations, [confirmationId]: response}));
        setCodeRequestCompanyId(null);

        if (response.method !== GoogleVerificationMethodEnum.Address) {
            setCodeEnterCompanyId({id: confirmationId, repeat: false});
        }
    }

    function handleCodeEnterModal(confirmationId: number) {
        setRequestedConfirmations((codeConfirmations) => ({...codeConfirmations, [confirmationId]: true}));
        setCodeEnterCompanyId(null);
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('GOOGLE_SYNC__CONFIRMATIONS__TITLE')} />

            <GoogleAccountSyncProvider>
                {codeRequestCompanyId !== null && codeRequestCompany && (
                    <CodeRequestModal
                        confirmation={codeRequestCompany}
                        onCancel={() => setCodeRequestCompanyId(null)}
                        onOk={(method) => handleCodeRequestModal(codeRequestCompanyId.id, method)}
                        open
                        repeat={codeRequestCompanyId.repeat}
                    />
                )}

                {codeEnterCompanyId !== null && codeEnterCompany && (
                    <CodeEnterModal
                        confirmation={{
                            ...codeEnterCompany,
                            confirmation:
                                requestedConfirmation && !isBoolean(requestedConfirmation)
                                    ? requestedConfirmation
                                    : codeEnterCompany.confirmation,
                        }}
                        onCancel={() => setCodeEnterCompanyId(null)}
                        onOk={() => handleCodeEnterModal(codeEnterCompanyId.id)}
                        open
                        repeat={codeEnterCompanyId.repeat}
                    />
                )}

                {rightsCompany && rightsCompany.location && (
                    <ProcessingStepRightsModal
                        adminRightsUrl={rightsCompany.adminRightsUrl}
                        footer={
                            <Alert
                                className={styles.Alert}
                                icon={<FontAwesomeIcon icon={faCircleQuestion} />}
                                message={<Text stringKey="GOOGLE_SYNC__RIGHTS_REQUEST__ALERT" />}
                                showIcon
                                type="info"
                            />
                        }
                        location={rightsCompany.location.locationId ? rightsCompany.location : rightsCompany.company}
                        onCancel={() => setRightsCompanyId(null)}
                        onOk={() => setRightsCompanyId(null)}
                        open
                    />
                )}

                <ConfirmationsHeader
                    activeTab={activeTab}
                    confirmationsLength={confirmations?.count}
                    requestsLength={requests?.count}
                    setActiveTab={setActiveTab}
                    setShowCompletedRequests={setShowCompletedRequests}
                    setStatusFilter={setStatusFilter}
                    showCompletedRequests={showCompletedRequests}
                    statusFilter={statusFilter}
                />

                <PageCard bodyClassName={styles.PageCard}>
                    {activeTab === TabEnum.Single && (
                        <>
                            <List
                                className={classNames(stepStyles.List, styles.ConfirmationsList)}
                                dataSource={confirmations?.results}
                                loading={isConfirmationsLoading}
                                pagination={{
                                    hideOnSinglePage: true,
                                    current: confirmationsPagination.page,
                                    pageSize: confirmationsPagination.pageSize,
                                    total: confirmationsPagination.total,
                                    onChange: confirmationsPagination.onChange,
                                }}
                                renderItem={(confirmation) => (
                                    <ConfirmationsListItem
                                        confirmation={{
                                            ...confirmation,
                                            status: isObjectLike(requestedConfirmations[confirmation.company.pk])
                                                ? GoogleCclStatusEnum.AwaitingVerification
                                                : requestedConfirmations[confirmation.company.pk] === true
                                                ? GoogleCclStatusEnum.Synchronize
                                                : confirmation.status,
                                        }}
                                        onCodeEnter={() =>
                                            setCodeEnterCompanyId({id: confirmation.company.pk, repeat: true})
                                        }
                                        onCodeRequest={({repeat}) =>
                                            setCodeRequestCompanyId({
                                                id: confirmation.company.pk,
                                                repeat: Boolean(repeat),
                                            })
                                        }
                                        onRightsRequest={() => setRightsCompanyId(confirmation.company.pk)}
                                    />
                                )}
                                size="small"
                            />

                            {confirmationsTips.length > 0 && (
                                <aside className={styles.RequestsTips}>
                                    <Tips tips={confirmationsTips} />
                                </aside>
                            )}
                        </>
                    )}

                    {activeTab === TabEnum.Request && (
                        <>
                            <List
                                className={styles.ConfirmationsList}
                                dataSource={requests?.results}
                                loading={isRequestsLoading}
                                pagination={{
                                    hideOnSinglePage: true,
                                    current: requestsPagination.page,
                                    pageSize: requestsPagination.pageSize,
                                    total: requestsPagination.total,
                                    onChange: requestsPagination.onChange,
                                }}
                                renderItem={(request) => <RequestsListItem request={request} />}
                                size="small"
                            />

                            {requestsTips.length > 0 && (
                                <aside className={styles.RequestsTips}>
                                    <Tips tips={requestsTips} />
                                </aside>
                            )}
                        </>
                    )}
                </PageCard>
            </GoogleAccountSyncProvider>
        </Page>
    );
}
