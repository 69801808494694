export const EMAIL_REGEXP =
    // eslint-disable-next-line no-useless-escape
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/;

export const URL_REGEXP =
    /^(http|https):\/\/(?:[^\s/:@]+(?::[^\s/:@]*)?@)?(?:(?:0|25[0-5]|2[0-4]\d|1\d?\d?|[1-9]\d?)(?:\.(?:0|25[0-5]|2[0-4]\d|1\d?\d?|[1-9]\d?)){3}|\[[\d.:a-f]+]|([\da-z¡-\uFFFF](?:[\da-z¡-\uFFFF-]{0,61}[\da-z¡-\uFFFF])?(?:\.(?!-)[\da-z¡-\uFFFF-]{1,63})*\.(?!-)(?:[a-z¡-\uFFFF-]{2,63}|xn--[\da-z]{1,59})\.?|localhost))(?::\d{2,5})?(?:[#/?]\S*)?$/;

// Domains can be formed using only use letters and numbers from the ASCII set of characters e.g.
// (a-z) (A-Z) (а-я) (А-Я) (ЁІЎёіў) (0-9). Hyphens and apostrophe are also allowed but must be surrounded by characters,
// therefore not at the beginning or end of the domain. You're not allowed to use special characters (like '#*%')
// and spaces cannot be used.
export const DOMAIN_REGEXP = /^((?![Ўў’-])[\dA-Za-zЁІЎА-яёіў’-]{1,63}\.)+[A-Za-zА-я]{2,7}$/;
