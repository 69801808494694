import {useQuery} from '@tanstack/react-query';
import {useContext, useState} from 'react';

import {LocalPackFilterType} from '../../../page/main/local-pack/local-pack-filter/local-pack-filter-type';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {TableSorterType} from '../../../util/table';

import {LocalPackLeaderboardHookType, LocalPackLeaderboardType} from './local-pack-laderboard-type';
import {fetchLocalPackLeaderboard} from './local-pack-leaderboard-api';
import {LOCAL_PACK_LEADERBOARD_KEY} from './local-pack-leaderboard-const';

export function useLocalPackLeaderboard(filter: LocalPackFilterType): LocalPackLeaderboardHookType {
    const pagination = usePagination({
        dependencies: null,
    });

    const {refreshId, page, pageSize, onDataLoadFailed, onDataLoaded} = pagination;
    const [sorter, setSorter] = useState<TableSorterType | null>(null);

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const query = useQuery<LocalPackLeaderboardType>(
        [LOCAL_PACK_LEADERBOARD_KEY, refreshId, sorter, mainFilterKey],
        () =>
            fetchLocalPackLeaderboard({
                page,
                pageSize,
                mainFilterKey,
                filter,
                ...sorter,
            }),
        {
            refetchOnMount: true,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            onSuccess: onDataLoaded,
            onError: onDataLoadFailed,
        }
    );

    return {
        ...query,
        pagination,
        updateSorter: setSorter,
    };
}
