import {Button} from 'antd';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useUser} from '../../../../provider/user/user-hook';

import {LocalPackRulesTable} from './local-pack-rules-table/local-pack-rules-table';

export function LocalPackRulesPage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {user} = useUser();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK__MANAGE_RULES')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                        {
                            path: appRoute.localPackRules.path,
                            titleLangKey: 'LOCAL_PACK__MANAGE_RULES',
                        },
                    ]}
                />
                <PageHeader>
                    <Locale stringKey="LOCAL_PACK__MANAGE_RULES" />
                    {user?.isStaff && (
                        <NavigationLink to={appRoute.localPackRuleCreate.path}>
                            <Button type="primary">
                                <Locale stringKey="BUTTON__CREATE" />
                            </Button>
                        </NavigationLink>
                    )}
                </PageHeader>
            </PageCard>

            <PageCard title={<Locale stringKey="LOCAL_PACK__RULES__TITLE" />}>
                <LocalPackRulesTable />
            </PageCard>
        </Page>
    );
}
