import {useEffect} from 'react';

import {BIG_BRAND_IDS} from '../../page/main/reviews/review-list-page-content/reviews-filter/reviews-filter-const';
import {SelectorBrandType} from '../../service/feature-companies/feature-companies-type';

export function useMagnitForceBrandSelect(
    brands: Array<SelectorBrandType> | undefined,
    brandChangeFunction: (brandId: number) => void
): void {
    useEffect(() => {
        if (brands && brands.some((brand) => BIG_BRAND_IDS.includes(brand.id)) && brands[0]) {
            brandChangeFunction(brands[0].id);
        }
    }, [brandChangeFunction, brands]);
}
