import {noop} from 'lodash';

import {UserType} from '../../service/user/user-type';

import {UserContextType} from './user-context-type';

export const defaultUserContextData: UserContextType = {
    getUser: (): Promise<UserType | Error> => Promise.resolve(new Error('getUser: Override me')),
    refetchUser: noop,
    resetUser: noop,
    setLocaleNameByUser: noop,
    user: null,
    isInGettingUser: false,
    gettingUserError: null,
    isReputationManager: false,
    isTechnicalSupportUser: false,
};

export const technicalSupportTestUserIds: Set<number> = new Set([
    51_309, 941_805, 1_023_851, 1_291_021, 839_161, 1_291_339, 43_348, 28_011, 47_609, 943_255, 1742, 59_226,
]);

export const REPUTATION_MANAGER_ID = 7;

export const DEMO_CABINET_URL_KEY = 'demo-cabinet-url';
