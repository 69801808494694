import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';

import {TagsEditTableRowDataType} from './tags-edit-table-type';

export const tagColumnList: TableColumnsType<TagsEditTableRowDataType> = [
    {
        title: <Locale stringKey="TAGS__GROUP" />,
        dataIndex: 'tagName',
    },
    {
        title: <Locale stringKey="TAGS__GROUP__ACTIONS" />,
        dataIndex: 'groupActions',
        width: 104,
    },
];
