import {faMapPin} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FC, useCallback, useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {LocalPack} from '../../../../page/main/local-pack/local-pack';
import {LocalPackActivation} from '../../../../page/main/local-pack/local-pack-activation/local-pack-activation';
import {LocalPackCreateRulePage} from '../../../../page/main/local-pack/local-pack-create-rule-page/local-pack-create-rule-page';
import {LocalPackEditRulePage} from '../../../../page/main/local-pack/local-pack-edit-page/local-pack-edit-rule-page';
import {LocalPackLeaderBoardPage} from '../../../../page/main/local-pack/local-pack-leaderboard-page/local-pack-leader-board-page';
import {LocalPackResultPage} from '../../../../page/main/local-pack/local-pack-result-page/local-pack-result-page';
import {LocalPackRulesPage} from '../../../../page/main/local-pack/local-pack-rules-page/local-pack-rules-page';
import {LocalPackSearchPhrasesStatisticsPage} from '../../../../page/main/local-pack/local-pack-search-phrases-statistics-page/local-pack-search-phrases-statistics-page';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {getFeatureOrLandingPage, isEnabledForDomainAndDoubleGisWhiteLabel} from '../../../navigation/navigation-helper';
import {NavigationItemConfigType} from '../routing-type';

export function useLocalPackNavigation(): Array<NavigationItemConfigType> {
    const {domainName} = useDomainConfig();
    const {user, isReputationManager} = useUser();
    const {licenses, availableFeatures} = useLicenses();

    const getLocalPackFeatureOrLandingPage = useCallback(
        (pageComponent: FC<Record<string, unknown>>) => {
            return getFeatureOrLandingPage({
                license: licenses[FeaturesEnum.localPack],
                activationComponent: LocalPackActivation,
                pageComponent,
            });
        },
        [licenses]
    );

    const isLocalPackAvailable = useMemo(() => {
        return (
            isEnabledForDomainAndDoubleGisWhiteLabel({
                isDomainFeatureEnabled: availableFeatures[FeaturesEnum.localPack],
                isFeatureEnabled: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
                domainName,
            }) && !isReputationManager
        );
    }, [domainName, isReputationManager, licenses, availableFeatures]);

    return [
        {
            isAvailable: isLocalPackAvailable,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPack.path,
            icon: <FontAwesomeIcon icon={faMapPin} />,
            labelLangKey: 'CATEGORY_NAME__LOCAL_PACK',
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPack),
        },
        {
            isOnlyRoute: true,
            isAvailable: isLocalPackAvailable,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPackResult.path,
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPackResultPage),
        },
        {
            isOnlyRoute: true,
            isAvailable: isLocalPackAvailable && user?.isStaff,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPackRules.path,
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPackRulesPage),
        },
        {
            isOnlyRoute: true,
            isAvailable: isLocalPackAvailable && user?.isStaff,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPackRuleCreate.path,
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPackCreateRulePage),
        },
        {
            isOnlyRoute: true,
            isAvailable: isLocalPackAvailable && user?.isStaff,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPackRuleEdit.path,
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPackEditRulePage),
        },
        {
            isOnlyRoute: true,
            isAvailable: isLocalPackAvailable,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPackLeaderBoard.path,
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPackLeaderBoardPage),
        },
        {
            isOnlyRoute: true,
            isAvailable: isLocalPackAvailable,
            isActivated: Boolean(licenses[FeaturesEnum.localPack]?.isActive),
            path: appRoute.localPackSearchPhrasesStatistics.path,
            exact: true,
            component: getLocalPackFeatureOrLandingPage(LocalPackSearchPhrasesStatisticsPage),
        },
    ];
}
