import {StoreLocatorLanguageEnum} from '../../../service/store-locator-config/store-locator-config-short-type';
import {FeaturesEnum} from '../../../service/user/user-type';
import {ProvidersIdsEnum} from '../../../util/type';
import {LocaleNameEnum} from '../../locale/locale-context-type';
import {DomainConfigType, HeaderVisibilityOptionsEnum} from '../domain-config-type';

import {getWlFullLogo} from './white-label/wl-logo-full/wl-logo-full';
import {getShortLogo} from './white-label/wl-logo-short/wl-logo-short';

export const emptyDomainConfigData: DomainConfigType = {
    basename: '/',
    companyName: '',
    domainName: '',
    LogoFull: getWlFullLogo(''),
    logoFullSrc: '',
    LogoShort: getShortLogo(''),
    logoShortSrc: '',
    faviconSrc: '',
    LogoBreadcrumbs: null,
    onLoad: () => Promise.resolve(),
    onEnter: () => Promise.resolve(),
    isNotificationShow: false,
    isIdeaButtonShow: false,
    isFreshChatShow: false,
    isLogoutShow: false,
    isUserProfileShow: false,
    isReportCompaniesShow: false,
    headerVisibility: HeaderVisibilityOptionsEnum.VISIBLE,
    isFooterShow: false,
    canCreateCompany: false,
    isDomainFeatureEnabled: {
        [FeaturesEnum.photoTool]: false,
        [FeaturesEnum.reviewAnalyticsBase]: false,
        [FeaturesEnum.reviewAnalyticsFull]: false,
        [FeaturesEnum.reviewAnalyticsCompetitor]: false,
        [FeaturesEnum.duplicatesAndFakes]: false,
        [FeaturesEnum.posts]: false,
        [FeaturesEnum.reviewsGenerator]: false,
        [FeaturesEnum.reviewSuggests]: false,
        [FeaturesEnum.storeLocator]: false,
        [FeaturesEnum.onlinePresenceAnalytics]: false,
        [FeaturesEnum.dashboard]: false,
        [FeaturesEnum.localPack]: false,
        [FeaturesEnum.mlReplies]: false,
        [FeaturesEnum.companyGroups]: false,
        [FeaturesEnum.usedeskIntegration]: false,
        [FeaturesEnum.prices]: false,
        [FeaturesEnum.analytics]: false,
        [FeaturesEnum.userManagement]: false,
        [FeaturesEnum.omnichannel]: false,
        [FeaturesEnum.tariffs]: false,
        [FeaturesEnum.publicApi]: false,
        [FeaturesEnum.reviewGptReplySuggestions]: false,
        [FeaturesEnum.mailingListLicence]: false,
        [FeaturesEnum.companiesExport]: false,
        [FeaturesEnum.knowledge]: false,
        integrations: false,
        [FeaturesEnum.neuralNetwork]: true,
        [FeaturesEnum.sources]: true,
        [FeaturesEnum.reviews]: true,
        [FeaturesEnum.settings]: true,
        [FeaturesEnum.questionsAndAnswers]: true,
        [FeaturesEnum.myCompanies]: true,
    },
    hiddenCompanyFields: {branchCode: true},
    canFoldDesktopMenu: true,
    isPageWrapperHasPaddings: true,
    isLanguageSelectorVisible: true,
    isDynamicHelpLinksVisible: false,
    isSingleFeature: true,
    isWhiteLabel: true,
    availableLocales: Object.values(LocaleNameEnum),
    defaultLocale: LocaleNameEnum.enUs,
    isSourceSettingsShown: false,
    notificationTabs: [],
    feedTypeLabels: null,
    feedTemplateLinks: [],
    storeLocatorLanguages: Object.values(StoreLocatorLanguageEnum),
    mainCatalogs: [ProvidersIdsEnum.google, ProvidersIdsEnum.facebook],
    isShowUsetifulButton: false,
    isShowYandexTransferAccountBanner: false,
    isShowTrialNeurlalinkInfo: true,
    isShowReviewSourcesBanner: false,
};
