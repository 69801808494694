import {TableColumnsType} from 'antd';

import {Text} from '../../../../component/text/text';
import {AdditionalInfo} from '../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../provider/locale/localization';

import {FakesTableType} from './fakes-table-type';

export const fakesTableColumns: TableColumnsType<FakesTableType> = [
    {
        dataIndex: 'catalogIcon',
        width: 56,
        align: 'center',
    },
    {
        title: (
            <AdditionalInfo
                leftNode={<Text stringKey="FAKES__RESULTS__COLUMN__SEARCH_DATE" />}
                placement="bottom"
                title={<Locale stringKey="FAKES__RESULTS__COLUMN__SEARCH_DATE__SUBTITLE" />}
            >
                <Locale stringKey="FAKES__RESULTS__COLUMN__SEARCH_DATE__DESCRIPTION" />
            </AdditionalInfo>
        ),
        dataIndex: 'date',
        width: 120,
    },
    {
        title: <Locale stringKey="FAKES__RESULTS__COLUMN__FOUND_LOCATION" />,
        dataIndex: 'foundLocation',
    },
    {
        title: <Locale stringKey="FAKES__RESULTS__COLUMN__MAIN_LOCATION" />,
        dataIndex: 'mainLocation',
        width: 260,
    },
    {
        title: (
            <AdditionalInfo
                leftNode={<Text stringKey="FAKES__RESULTS__COLUMN__STATUS" />}
                placement="bottom"
                title={<Locale stringKey="FAKES__RESULTS__COLUMN__STATUS__SUBTITLE" />}
            >
                <Locale stringKey="FAKES__RESULTS__COLUMN__STATUS__DESCRIPTION" />
            </AdditionalInfo>
        ),
        align: 'center',
        dataIndex: 'status',
        width: 128,
    },
    {
        dataIndex: 'actions',
        width: 216,
    },
];
