export const enQuestionsAndAnswersTranslations = {
    QUESTIONS_N_ANSWERS__PROCESSING_QUESTIONS: 'Processing questions',
    QUESTIONS_N_ANSWERS__TOTAL_QUESTIONS: 'Total questions',
    QUESTIONS_N_ANSWERS__WITH_AN_OFFICIAL_ANSWER: 'With official response',
    QUESTIONS_N_ANSWERS__NO_OFFICIAL_RESPONSE: 'No official response',
    QUESTIONS_N_ANSWERS__REPLY: 'Reply',
    QUESTIONS_N_ANSWERS__REPLY_ERROR: 'An error occurred while sending your response',
    QUESTIONS_N_ANSWERS__ENTER_YOUR_REPLY_TEXT: 'Enter your reply text...',
    QUESTIONS_N_ANSWERS__OWNER: 'Owner',

    QUESTIONS_N_ANSWERS__FILTER__FILTERS: 'Filters',
    QUESTIONS_N_ANSWERS__FILTER__RESET: 'Reset',
    QUESTIONS_N_ANSWERS__FILTER__PERIOD_OF_TIME: 'Period of time',
    QUESTIONS_N_ANSWERS__FILTER__EMPLOYEE_RESPONDING: 'Responding employee',
    QUESTIONS_N_ANSWERS__FILTER__ANY_EMPLOYEE: 'Any employee',
};
