import {Locale} from '../../../../../../../../../../../provider/locale/locale';
import {SourcePostType} from '../../../../../../../../../../../service/posts/posts-types';
import {ExpandableTextPreview} from '../../../expandable-text-preview/expandable-text-preview';
import {PreviewFooter} from '../preview-footer/preview-footer';
import {PreviewImages} from '../preview-images/preview-images';

import * as styles from './preview-body.scss';

type PropsType = {
    post: SourcePostType;
};

export function PreviewBody(props: PropsType): JSX.Element {
    const {post} = props;

    const {text, images} = post;

    return (
        <div className={styles.PreviewBody}>
            <ExpandableTextPreview
                expandButton={
                    <span className={styles.PreviewBody_more}>
                        <Locale stringKey="POSTS_FORM__PREVIEW__YANDEX__READ_MORE" />
                    </span>
                }
                text={text}
            />
            <PreviewImages images={images} />
            <PreviewFooter />
        </div>
    );
}
