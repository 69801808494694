import {Space} from 'antd';

import {AntdErrorMessage} from '../../../../../../../../component/antd-error-message/antd-error-message';
import {SelectWithCheckboxes} from '../../../../../../../../component/select-with-checkboxes/select-with-checkboxes';
import {Text} from '../../../../../../../../component/text/text';
import {WeekDaysEnum} from '../../../../../../../../provider/locale/locale-context-const';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {CompanyErrorType} from '../../../../../../../../service/company-v2/company-type';
import {
    TimeRangeType,
    WeekdayType,
    WorkingDayType,
} from '../../../../../../../../service/company-v2/types/company-working-time-type';
import {BreakTimeList} from '../../common/break-time-list/break-time-list';
import {TimeDeleteButton} from '../../common/time-delete-button/time-delete-button';
import {WorkTime} from '../../common/work-time/work-time';
import {weekDayAllNameValuesMap} from '../../working-time-const';

import {useDaysGroupPlaceholder} from './days-group-hook';
import * as styles from './days-group.scss';

type PropsType = {
    days: WeekdayType;
    availableDays: Array<WeekDaysEnum>;
    updateDays: (weekdays: Array<WeekDaysEnum>) => void;
    onChange: (change: Partial<WorkingDayType>) => void;
    catalogId?: number;
    errorMessage?: CompanyErrorType['workingHours'] | CompanyErrorType['extraWorkingHours'] | null;
    isDoubleGisCompany: boolean;
};

export function DaysGroup(props: PropsType): JSX.Element {
    const {days, availableDays, updateDays, onChange, catalogId, errorMessage, isDoubleGisCompany} = props;

    const placeholder = useDaysGroupPlaceholder(days.daysOfWeek);
    const {getLocalizedString} = useLocale();

    return (
        <div className={styles.DaysGroup_timeBlock}>
            <div className={styles.DaysGroup_weekDayBlock}>
                <Text
                    className={styles.DaysGroup_text}
                    lighter
                    stringKey="COMPANY_FORM__WORKING_TIME__DAYS_OF_THE_WEEK"
                />
                <Space>
                    <SelectWithCheckboxes<WeekDaysEnum>
                        hideFooter
                        initialValue={days.daysOfWeek}
                        key={days.daysOfWeek[0]}
                        onConfirm={(weekdays) => updateDays(weekdays)}
                        options={Object.entries(weekDayAllNameValuesMap).map(([dayName, translate]) => ({
                            value: dayName as WeekDaysEnum,
                            label: getLocalizedString(translate),
                            disabled:
                                !days.daysOfWeek.includes(dayName as WeekDaysEnum) &&
                                !availableDays.includes(dayName as WeekDaysEnum),
                        }))}
                        selectedPlaceholder={placeholder}
                        withoutIndeterminate
                        wrapperClassName={styles.DaysGroup_customSelect}
                    />
                    <TimeDeleteButton onClick={() => updateDays([])} />
                </Space>
            </div>
            <div className={styles.DaysGroup_workTimeBlock}>
                <Text className={styles.DaysGroup_text} lighter stringKey="COMPANY__FORM__WIDGET_NAME__WORKING_TIME" />
                <WorkTime
                    className={styles.DaysGroup_time}
                    daily={days.daily}
                    timeRange={days.timeRange}
                    updateTime={(newTime) => onChange(newTime)}
                />
                <BreakTimeList
                    breakTimeList={days.breaks}
                    catalogId={catalogId}
                    className={styles.DaysGroup_breakTime}
                    errorMessage={(errorMessage as Record<string, TimeRangeType>)?.breaks}
                    isDoubleGisCompany={isDoubleGisCompany}
                    setBreakTimeList={(breaks) => onChange({breaks})}
                />
            </div>
            {Array.isArray(errorMessage) && errorMessage.length > 0 && (
                <AntdErrorMessage>{errorMessage[0]}</AntdErrorMessage>
            )}
        </div>
    );
}
