import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../../../../../util/api-adapter';

export enum BannerStateEnum {
    Permanent = 'permanent',
    Closable = 'closable',
    Hidden = 'hidden',
}

const bannerSchema = r.object({
    state: r.nativeEnum(BannerStateEnum),
    info: r.object({
        catalogs: r.array(
            r.object({
                website: r.string(),
                logo: r.string().url(),
            })
        ),
    }),
});

type BannerType = r.infer<typeof bannerSchema>;

const bannerUrl = '/cp/banner/?banner_type=review_sources';

function fetchBannerState(): Promise<BannerType> {
    return fetchAndDeserialize(bannerUrl, bannerSchema);
}

export function useReviewSourcesBanner(enabled: boolean): UseQueryResult<BannerType> {
    return useQuery([bannerUrl], fetchBannerState, {enabled});
}
