import {getDefaultReactDoughnutTooltipOptions} from '../../../../layout/chart/chart-const';
import {doughnutChartOptions} from '../../../../layout/chart/doughnut-chart/doughnut-chart-const';
import {DoughnutDataType} from '../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {LangKeyType} from '../../../../provider/locale/translation/type';

import * as styles from './reviews-rating-distribution.scss';

enum RatingValueEnum {
    FIVE = 5,
    FOUR = 4,
    THREE = 3,
    TWO = 2,
    ONE = 1,
}

export const ratingValueColorMap: Record<RatingValueEnum | string, string> = {
    [RatingValueEnum.FIVE]: styles.fiveStars,
    [RatingValueEnum.FOUR]: styles.fourStars,
    [RatingValueEnum.THREE]: styles.threeStars,
    [RatingValueEnum.TWO]: styles.twoStars,
    [RatingValueEnum.ONE]: styles.oneStar,
};

export const ratingValueLocalizationMap: Record<RatingValueEnum | string, LangKeyType> = {
    [RatingValueEnum.FIVE]: 'DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__FIVE_STARS',
    [RatingValueEnum.FOUR]: 'DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__FOUR_STARS',
    [RatingValueEnum.THREE]: 'DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__THREE_STARS',
    [RatingValueEnum.TWO]: 'DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__TWO_STARS',
    [RatingValueEnum.ONE]: 'DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__ONE_STAR',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getReviewsRatingDistributionChartOptions(options: {data: Array<DoughnutDataType>}) {
    const {data} = options;

    return {
        ...doughnutChartOptions,
        cutout: '55%',
        borderWidth: 2,
        plugins: {
            ...doughnutChartOptions.plugins,
            tooltip: getDefaultReactDoughnutTooltipOptions({
                data,
                title: '',
            }),
        },
    };
}
