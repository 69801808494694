import {TimePicker, TimeRangePickerProps} from 'antd';
import {noop} from 'lodash';
import {RangePickerRef} from 'rc-picker/lib/interface';
import {RefAttributes, useCallback, useEffect, useRef, useState} from 'react';

import {TIME_FORMAT} from './time-range-picker-const';
import {autoformatTime} from './time-range-picker-format-helper';
import * as styles from './time-range-picker.scss';

export function TimeRangePicker(
    props: Omit<TimeRangePickerProps, 'getPopupContainer' | 'format'> & RefAttributes<unknown>
): JSX.Element {
    const {onChange, onOpenChange, disabled, ...restProps} = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const pickerRef = useRef<RangePickerRef>(null);

    function handleIsOpen(newIsOpen: boolean) {
        setIsOpen(newIsOpen);
        onOpenChange?.(newIsOpen);
    }

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (!isOpen) {
                return;
            }

            if (
                containerRef.current &&
                event.target instanceof Node &&
                !containerRef.current.querySelector('.ant-picker-panel-container')?.contains(event.target)
            ) {
                // 'OK' button doesn't have any additional identifiable info except for css classes
                const button = containerRef.current.querySelector('button');

                // if user didn't select anything, let ant close the popup as usual
                if (!button || button.disabled) {
                    return;
                }

                event.stopPropagation();
                button.click();
            }
        },
        [isOpen]
    );

    const handleInput = useCallback(
        (event: Event) =>
            autoformatTime(event, (values, formatString) => {
                onChange?.(values, formatString);
                pickerRef.current?.blur();
            }),
        [onChange]
    );

    useEffect(() => {
        if (!isOpen) {
            return noop;
        }

        document.addEventListener('mousedown', handleClickOutside, true);
        containerRef.current?.addEventListener('input', handleInput);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            containerRef.current?.removeEventListener('input', handleInput);
        };
    }, [handleClickOutside, handleInput, isOpen]);

    return (
        <div className={styles.wrapper} ref={containerRef}>
            <TimePicker.RangePicker
                disabled={disabled}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restProps}
                format={TIME_FORMAT}
                getPopupContainer={() => containerRef.current || document.documentElement}
                onChange={onChange}
                onOpenChange={handleIsOpen}
                ref={pickerRef}
            />
        </div>
    );
}
