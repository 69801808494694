import {
    faClockRotateLeft,
    faCopy,
    faExternalLink,
    faLink,
    faNoteSticky,
    faPenToSquare,
    faTrash,
    faUser,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MenuProps} from 'antd';
import {Dispatch, SetStateAction} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {CustomReviewError, fetchDeleteReview} from '../../../../../../../service/reviews/custom-reviews';
import {constructCommentString} from '../review-helper';

type ReviewMenuOptionsType = {
    text: string;
    reviewId: number;
    isShowExternalOptions: boolean;
    setIsOpenNoteModal: Dispatch<SetStateAction<boolean>>;
    setIsOpenHistoryModal: Dispatch<SetStateAction<boolean>>;
    setIsOpenResponsibleUserModal: Dispatch<SetStateAction<boolean>>;
    setIsOpenReviewEditModal: Dispatch<SetStateAction<boolean>>;
    ableToChangeResponsibleUser: boolean;
    onSubmitFinish: () => void;
};

export function useReviewMenu(options: ReviewMenuOptionsType): MenuProps['items'] {
    const {
        setIsOpenHistoryModal,
        ableToChangeResponsibleUser,
        setIsOpenNoteModal,
        reviewId,
        text,
        isShowExternalOptions,
        setIsOpenResponsibleUserModal,
        setIsOpenReviewEditModal,
        onSubmitFinish,
    } = options;

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    function handleDeleteClick() {
        modal.confirm({
            title: <Locale stringKey="CUSTOM_REIVEW__DELETE_MODAL_TITLE" />,
            content: <Locale stringKey="CUSTOM_REIVEW__DELETE_MODAL_CONTENT" />,
            onOk: (closeModal) =>
                fetchDeleteReview(reviewId)
                    .then(() => {
                        closeModal();
                        onSubmitFinish();

                        snackbar.success({
                            message: <Locale stringKey="CUSTOM_REIVEW__DELETE__SUCCESS__TITLE" />,
                            description: <Locale stringKey="CUSTOM_REIVEW__DELETE__SUCCESS__DESCRIPTION" />,
                        });
                    })
                    .catch((error: unknown) => {
                        if (error instanceof CustomReviewError) {
                            snackbar.error((error as Error).message);
                        } else {
                            snackbar.error({
                                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                            });
                        }
                    }),
            okButtonProps: {danger: true, type: 'primary'},
            okText: getLocalizedString('POPUP__BUTTON__CONFIRM'),
            cancelText: getLocalizedString('POPUP__BUTTON__CANCEL'),
        });
    }

    async function onCopy() {
        const commentString = constructCommentString(text);

        if (typeof navigator?.clipboard?.writeText !== 'function') {
            snackbar.error(<Locale stringKey="REVIEWS__REVIEW_COPY__BROWSER_ERROR" />);
            return;
        }

        try {
            await navigator.clipboard.writeText(commentString);
            snackbar.success(<Locale stringKey="REVIEWS__REVIEW_COPY__SUCCESS" />);
        } catch (error: unknown) {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            });

            throw error;
        }
    }

    async function onCopyLink() {
        try {
            const url = location.origin + generatePath(appRoute.reviewsManagementReviewItem.path, {id: reviewId});

            await navigator.clipboard.writeText(url);
            snackbar.success(<Locale stringKey="REVIEWS__REVIEW_URL_COPY__SUCCESS" />);
        } catch (error: unknown) {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            });

            throw error;
        }
    }

    const additionalMenuItem = isShowExternalOptions
        ? [
              {
                  key: 'edit',
                  icon: <FontAwesomeIcon icon={faPenToSquare} />,
                  label: getLocalizedString('REVIEWS__REVIEW_EDIT__HINT'),
                  onClick: () => setIsOpenReviewEditModal(true),
              },
              {
                  key: 'delete',
                  icon: <FontAwesomeIcon icon={faTrash} />,
                  label: getLocalizedString('REVIEWS__REVIEW_DELETE__HINT'),
                  onClick: handleDeleteClick,
              },
          ]
        : [];

    const responsibleUserItem = ableToChangeResponsibleUser
        ? [
              {
                  key: 'responsible',
                  icon: <FontAwesomeIcon icon={faUser} />,
                  label: getLocalizedString('REVIEW__DROPDOWN_MENU__APPOINT_RESPONSIBLE'),
                  onClick: () => setIsOpenResponsibleUserModal(true),
              },
          ]
        : [];

    return [
        {
            key: 'open-link',
            icon: <FontAwesomeIcon icon={faExternalLink} />,
            label: (
                <a
                    href={location.origin + generatePath(appRoute.reviewsManagementReviewItem.path, {id: reviewId})}
                    rel="noreferrer"
                    target="_blank"
                >
                    {getLocalizedString('REVIEW__DROPDOWN_MENU__OPEN_LINK')}
                </a>
            ),
        },
        {
            key: 'copy',
            icon: <FontAwesomeIcon icon={faCopy} />,
            label: getLocalizedString('REVIEWS__REVIEW_COPY__HINT'),
            onClick: onCopy,
        },
        {
            key: 'link',
            icon: <FontAwesomeIcon icon={faLink} />,
            label: getLocalizedString('REVIEWS__REVIEW_URL_COPY__HINT'),
            onClick: onCopyLink,
        },
        {
            key: 'note',
            icon: <FontAwesomeIcon icon={faNoteSticky} />,
            label: getLocalizedString('REVIEW__DROPDOWN_MENU__ADD_NOTE'),
            onClick: () => setIsOpenNoteModal(true),
        },
        ...responsibleUserItem,
        {
            key: 'history',
            icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
            label: getLocalizedString('REVIEW_ITEM_PAGE__ACTIVITY__LOGS'),
            onClick: () => setIsOpenHistoryModal(true),
        },
        ...additionalMenuItem,
    ];
}
