import {Table, TableColumnType, TablePaginationConfig} from 'antd';
import {ColumnFilterItem} from 'antd/lib/table/interface';
import {Key, useCallback, useEffect, useMemo, useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../app-route';
import {renderCellActions} from '../../../../layout/actions-cell/actions-cell';
import {renderTagType} from '../../../../layout/types-cell/types-cell';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {
    ResponseTemplateDataType,
    ResponseTemplateGroupType,
    useResponseTemplateGroupList,
} from '../../../../service/reviews/reviews-response-templates';
import {toTrimmedString} from '../../../../util/string';
import {useUrl} from '../../../../util/url-hook/url-hook';

import {
    responseTemplateColumnList,
    responseTemplateTypeCommand,
    responseTemplateTypePrivate,
    withoutGroupItemId,
} from './response-templates-table-const';
import {ResponseTemplatesTableRowDataType} from './response-templates-table-type';

type PropsType = {
    setResponseTemplateIdList: (responseTemplateIdList: Array<string>) => void;
    setResponseTemplateDataList: (responseTemplateDataList: Array<ResponseTemplateDataType>) => void;
    responseTemplatesHookResult?: Array<ResponseTemplateDataType>;
    pagination: TablePaginationConfig;
    setPagination: (pagination: TablePaginationConfig) => void;
    responseTemplatesHookIsInProgress: boolean;
    countTemplates: number;
    responseTemplateGroupIdFilterList: Array<string>;
    setResponseTemplateGroupIdFilterList: (responseTemplateGroupIdFilterList: Array<string>) => void;
    onDelete: (responseTemplateData: ResponseTemplateDataType) => void;
    setResponseTemplateTypeFilter: (value: boolean | null) => void;
    groupFilterInitialState: boolean | null;
};

export function ResponseTemplatesTable(props: PropsType): JSX.Element {
    const {
        setResponseTemplateIdList,
        setResponseTemplateDataList,
        responseTemplatesHookResult,
        responseTemplatesHookIsInProgress,
        pagination,
        onDelete,
        countTemplates,
        setPagination,
        setResponseTemplateGroupIdFilterList,
        responseTemplateGroupIdFilterList,
        setResponseTemplateTypeFilter,
        groupFilterInitialState,
    } = props;

    const {getLocalizedString} = useLocale();

    const [responseTemplateTypeIdFilterList, setResponseTemplateTypeIdFilterList] = useState<Array<string>>(
        groupFilterInitialState
            ? [responseTemplateTypePrivate]
            : groupFilterInitialState === false
            ? [responseTemplateTypeCommand]
            : []
    );
    const [withoutFolder, setWithoutFolder] = useState<boolean | null>(null);

    const {pushUrl, setQuery} = useUrl();
    const {reviewsManagementResponseTemplatesEditTemplate: templatesEditTemplateRoute} = appRoute;

    const responseTemplateDataList: Array<ResponseTemplateDataType> = useMemo((): Array<ResponseTemplateDataType> => {
        return responseTemplatesHookResult || [];
    }, [responseTemplatesHookResult]);

    useEffect(() => {
        setResponseTemplateDataList(responseTemplateDataList);
    }, [responseTemplateDataList, setResponseTemplateDataList]);

    const {data: responseTemplateGroupListResult} = useResponseTemplateGroupList();

    const availableResponseTemplateGroupList: Array<ResponseTemplateGroupType> =
        useMemo((): Array<ResponseTemplateGroupType> => {
            return responseTemplateGroupListResult || [];
        }, [responseTemplateGroupListResult]);

    function handleRowSelection(_selectedRowKeys: Array<Key>, rowDataList: Array<ResponseTemplatesTableRowDataType>) {
        setResponseTemplateIdList(rowDataList.map((rowData: ResponseTemplatesTableRowDataType): string => rowData.key));
    }

    const convertResponseTemplateTableRow = useCallback(
        (responseTemplateData: ResponseTemplateDataType, index: number): ResponseTemplatesTableRowDataType => {
            return {
                responseTemplateName: responseTemplateData.title,
                responseTemplateGroup: responseTemplateData.folder ? responseTemplateData.folder.title : '—',
                responseTemplateType: renderTagType(responseTemplateData.isPrivate),
                key: String(responseTemplateData.id),
                index,
                templateActions: renderCellActions(
                    () => pushUrl(generatePath(templatesEditTemplateRoute.path, {templateId: responseTemplateData.id})),
                    () => onDelete(responseTemplateData)
                ),
            };
        },
        [pushUrl, onDelete, templatesEditTemplateRoute.path]
    );

    const [dataList, setDataList] = useState<Array<ResponseTemplatesTableRowDataType>>([]);

    useEffect(() => {
        setDataList(responseTemplateDataList.map(convertResponseTemplateTableRow));
    }, [responseTemplateDataList, convertResponseTemplateTableRow]);

    const [
        responseTemplateNameColumn,
        responseTemplateGroupColumn,
        responseTemplateTypeColumn,
        responseTemplateActionsColumn,
    ] = responseTemplateColumnList;

    function handleTableChange(newPagination: TablePaginationConfig, filters: Record<string, Array<unknown> | null>) {
        const tagGroupList = filters.responseTemplateGroup || [];
        const groupFilters = tagGroupList.map((group) => (group === '-1' ? null : group));

        setWithoutFolder(tagGroupList.includes('-1') || null);
        setPagination(newPagination);

        if (filters.responseTemplateType && filters.responseTemplateType.length === 1) {
            setResponseTemplateTypeFilter(filters.responseTemplateType[0] === responseTemplateTypePrivate);
        } else {
            setResponseTemplateTypeFilter(null);
        }

        setQuery({
            filterType:
                filters.responseTemplateType && filters.responseTemplateType.length === 1
                    ? filters.responseTemplateType[0] === responseTemplateTypePrivate
                    : null,
            groupIds: [...groupFilters].map((value) => String(value).trim()),
        });
        setResponseTemplateGroupIdFilterList([...groupFilters].map((value) => String(value).trim()));
        setResponseTemplateTypeIdFilterList([...(filters.responseTemplateType || [])].map(toTrimmedString));
    }

    const groupColumnWithFilter = useMemo((): TableColumnType<ResponseTemplatesTableRowDataType> => {
        const filters: Array<ColumnFilterItem> = availableResponseTemplateGroupList.map(
            (responseTemplateGroup: ResponseTemplateGroupType): ColumnFilterItem => {
                return {text: responseTemplateGroup.title, value: String(responseTemplateGroup.id)};
            }
        );

        filters.push({text: getLocalizedString('TEXT__WITHOUT_GROUP'), value: withoutGroupItemId});

        return {
            ...responseTemplateGroupColumn,
            filters,
            filteredValue: withoutFolder
                ? [...responseTemplateGroupIdFilterList, '-1']
                : responseTemplateGroupIdFilterList,
        };
    }, [
        availableResponseTemplateGroupList,
        getLocalizedString,
        responseTemplateGroupColumn,
        withoutFolder,
        responseTemplateGroupIdFilterList,
    ]);

    const responseTemplateTypeColumnWithFilter = useMemo((): TableColumnType<ResponseTemplatesTableRowDataType> => {
        const filters: Array<ColumnFilterItem> = [
            {
                text: getLocalizedString('RESPONSE_TEMPLATES__TEMPLATE_TYPE__PRIVATE'),
                value: responseTemplateTypePrivate,
            },
            {
                text: getLocalizedString('RESPONSE_TEMPLATES__TEMPLATE_TYPE__COMMAND'),
                value: responseTemplateTypeCommand,
            },
        ];

        return {
            ...responseTemplateTypeColumn,
            filters,
            filteredValue: responseTemplateTypeIdFilterList,
        };
    }, [responseTemplateTypeIdFilterList, responseTemplateTypeColumn, getLocalizedString]);

    return (
        <Table<ResponseTemplatesTableRowDataType>
            columns={[
                responseTemplateNameColumn,
                groupColumnWithFilter, // groupColumn,
                responseTemplateTypeColumnWithFilter, // typeColumn
                responseTemplateActionsColumn,
            ]}
            dataSource={dataList}
            loading={responseTemplatesHookIsInProgress}
            onChange={handleTableChange}
            pagination={{
                size: 'default',
                showSizeChanger: true,
                hideOnSinglePage: false,
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: countTemplates,
            }}
            rowKey="key"
            rowSelection={{columnWidth: 47, onChange: handleRowSelection}}
            scroll={{x: true}}
            size="middle"
        />
    );
}
