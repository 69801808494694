import {LangKeyType} from '../../../provider/locale/translation/type';
import {PostStatusEnum} from '../../../service/posts/posts-types';

export const postStatusOptions: Array<PostStatusEnum> = [
    PostStatusEnum.Draft,
    PostStatusEnum.Planned,
    PostStatusEnum.Sent,
    PostStatusEnum.Deleted,
];

export const postStatusTranslationMap: Record<PostStatusEnum, LangKeyType> = {
    [PostStatusEnum.Draft]: 'POSTS__STATUS__DRAFT',
    [PostStatusEnum.Planned]: 'POSTS__STATUS__PLANNED',
    [PostStatusEnum.Sent]: 'POSTS__STATUS__SENT',
    [PostStatusEnum.Active]: 'POSTS__STATUS__ACTIVE',
    [PostStatusEnum.Deleted]: 'POSTS__STATUS__DELETED',
    [PostStatusEnum.Sending]: 'POSTS__STATUS__IN_PROGRESS',
};
