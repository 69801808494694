import {useCallback, useRef} from 'react';

import {DEFAULT_ROW_HEIGHT} from './virtualized-table-const';

type UseRowSizeType = {
    getSize: (index: number) => number;
    setSize: (index: number, size: number) => void;
};

export function useRowSize(): UseRowSizeType {
    const sizeMap = useRef<Record<number, number>>({});

    const getSize = useCallback((index: number) => {
        return sizeMap.current[index] || DEFAULT_ROW_HEIGHT;
    }, []);

    const setSize = useCallback((index: number, size: number) => {
        const currentIndexHeight: number | undefined = sizeMap.current[index];

        if (!currentIndexHeight || (currentIndexHeight && size > currentIndexHeight)) {
            const newSize = size < DEFAULT_ROW_HEIGHT ? DEFAULT_ROW_HEIGHT : size;

            sizeMap.current = {...sizeMap.current, [index]: newSize};
        }
    }, []);

    return {getSize, setSize};
}
