import {TableColumnsType} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {SourceLinkStatusEnum} from '../../../../../../../service/source-settings/source-links/source-links-type';
import {getEnumValue} from '../../../../../../../util/enum';
import {ActionsTableCell} from '../../actions-table-cell/actions-table-cell';
import {StatusCell} from '../../status-table-cell/status-cell';

import {CompanyCell} from './company-cell/company-cell';
import {statusFilterKey} from './links-table-const';
import {SourceLinksTableType} from './links-table-type';

function cutLimitString(value: string, limit = 40): string {
    if (value.length <= limit) {
        return value;
    }

    return `${value.slice(0, limit)}...`;
}

export function convertToLinkStatusList(list: Array<unknown>): Array<SourceLinkStatusEnum> {
    return list.filter((value): value is SourceLinkStatusEnum =>
        Boolean(getEnumValue<SourceLinkStatusEnum>(SourceLinkStatusEnum, value))
    );
}

export function getLinksTableColumns(isCompany: boolean): TableColumnsType<SourceLinksTableType> {
    return [
        {
            title: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__LINK" />,
            dataIndex: 'link',
            key: 'link',
            render: (_value: unknown, {link}: SourceLinksTableType): JSX.Element => (
                <a href={link} rel="noreferrer" target="_blank" title={link}>
                    {cutLimitString(link)}
                </a>
            ),
        },
        {
            title: isCompany ? (
                <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__COMPANY" />
            ) : (
                <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__BRAND" />
            ),
            key: 'brandOrCompany',
            render: (_value: unknown, {company, brand}: SourceLinksTableType) =>
                company ? <CompanyCell address={company.address} name={company.name} /> : brand.name,
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__STATUS" />,
            key: statusFilterKey,
            filters: [
                {
                    text: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCED" />,
                    value: SourceLinkStatusEnum.Synced,
                },
                {
                    text: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCHRONIZATION" />,
                    value: SourceLinkStatusEnum.Synchronization,
                },
                {
                    text: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__FAIL" />,
                    value: SourceLinkStatusEnum.Fail,
                },
            ],
            render: (_value: unknown, {status}: SourceLinksTableType) => <StatusCell status={status} />,
        },
        {
            align: 'center',
            title: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__ACTIONS" />,
            key: 'actions',
            render: (_value: unknown, entity: SourceLinksTableType) => (
                <ActionsTableCell>{entity.actions}</ActionsTableCell>
            ),
        },
    ];
}
