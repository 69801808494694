import {faPeriod} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import * as styles from './form-active-mark.scss';

type PropsType = {
    isVisible: boolean;
};

export function FormActiveMark(props: PropsType): JSX.Element | null {
    const {isVisible} = props;

    if (!isVisible) {
        return null;
    }

    return <FontAwesomeIcon className={styles.Mark} icon={faPeriod} />;
}
