import {useMemo} from 'react';

import {FakesFiltersType} from '../../../service/fakes/fakes-results';
import {useFakesStatuses} from '../../../service/fakes/fakes-statuses';
import {FakeClientStatusEnum} from '../../../service/fakes/fakes-type';

export function useFakesDefaultFilters(): FakesFiltersType | null {
    const {data: statuses} = useFakesStatuses();

    const clientStatuses = useMemo(() => {
        if (!statuses) {
            return [];
        }

        const clientStatusesForNewData = [
            ...(statuses.newFakes + statuses.newDuplicates > 0 ? [FakeClientStatusEnum.New] : []),
            ...(statuses.needAction > 0 ? [FakeClientStatusEnum.ActionRequired] : []),
        ];

        if (clientStatusesForNewData.length > 0) {
            return clientStatusesForNewData;
        }

        const clientStatusesForModeratedData = [
            ...(statuses.moderation > 0 ? [FakeClientStatusEnum.Moderation] : []),
            ...(statuses.claimSent > 0 ? [FakeClientStatusEnum.ClaimSent] : []),
        ];

        if (clientStatusesForModeratedData.length > 0) {
            return clientStatusesForModeratedData;
        }

        return [FakeClientStatusEnum.ClaimApproved, FakeClientStatusEnum.ClaimRejected, FakeClientStatusEnum.Hidden];
    }, [statuses]);

    if (!statuses) {
        return null;
    }

    return {
        type: null,
        catalogIds: [],
        clientStatuses,
        brandIds: [],
    };
}
