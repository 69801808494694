import {Form, FormInstance, Input} from 'antd';
import {ChangeEvent} from 'react';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import * as integrationsStyles from '../../integrations.scss';
import {getWebHookUrl} from '../integration-usedesk-helper';
import {UsedeskFieldNamesEnum, UsedeskFieldsType} from '../integration-usedesk-type';

import {WEBHOOK_FIELD_NAME} from './settings-controls-const';

type PropsType = {
    form: FormInstance<UsedeskFieldsType>;
};

export function UsedeskSettingsControls(props: PropsType): JSX.Element {
    const {form} = props;
    const {getLocalizedString} = useLocale();
    const {requiredFieldRule} = useFormRules();

    function handleApplicationIdChange(event: ChangeEvent<HTMLInputElement>) {
        form.setFieldsValue({[UsedeskFieldNamesEnum.webhook]: getWebHookUrl(event.target.value)});
    }

    return (
        <>
            <Form.Item
                className={integrationsStyles.integration__item}
                label={<Locale stringKey="INTEGRATION__USEDESK__LABEL__SECRET_KEY" />}
                name={UsedeskFieldNamesEnum.secretKey}
                required
                rules={[requiredFieldRule]}
            >
                <Input placeholder={getLocalizedString('INTEGRATION__USEDESK__PLACEHOLDER__SECRET_KEY')} size="large" />
            </Form.Item>

            <Form.Item
                className={integrationsStyles.integration__item}
                label={<Locale stringKey="INTEGRATION__USEDESK__LABEL__APPLICATION_ID" />}
                name={UsedeskFieldNamesEnum.applicationId}
                required
                rules={[requiredFieldRule]}
            >
                <Input
                    onChange={handleApplicationIdChange}
                    placeholder={getLocalizedString('INTEGRATION__USEDESK__PLACEHOLDER__APPLICATION_ID')}
                    size="large"
                />
            </Form.Item>

            <Form.Item
                className={integrationsStyles.integration__item}
                label={<Locale stringKey="INTEGRATION__USEDESK__LABEL__CHAT_ID" />}
                name={UsedeskFieldNamesEnum.chatId}
            >
                <Input placeholder={getLocalizedString('INTEGRATION__USEDESK__PLACEHOLDER__CHAT_ID')} size="large" />
            </Form.Item>

            <Form.Item
                className={integrationsStyles.integration__item}
                label={<Locale stringKey="INTEGRATION__USEDESK__LABEL__WEBHOOK_LINK" />}
                name={WEBHOOK_FIELD_NAME}
            >
                <Input
                    disabled
                    placeholder={getLocalizedString('INTEGRATION__USEDESK__PLACEHOLDER__WEBHOOK_LINK')}
                    size="large"
                />
            </Form.Item>
        </>
    );
}
