import {TableColumnsType} from 'antd';

import {Locale} from '../../../../../provider/locale/localization';
import {LocalPackRuleListItemType} from '../../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {getRandomString} from '../../../../../util/string';
import {LOCALPACK_FREQUENCY_LOCALIZATION_MAP} from '../../local-pack-const';

import {LocalPackRuleAction} from './actions/local-pack-rule-action';
import {LocalPackRulesTableFieldsEnum, LocalPackRulesTableItemType} from './local-pack-rules-table-type';
import {LocalPackRuleStatus} from './status/local-pack-rule-status';

function renderRuleSearchPhrase(searchPhrase: string): LocalPackRulesTableItemType {
    return {
        [LocalPackRulesTableFieldsEnum.Name]: searchPhrase,
        id: getRandomString(),
    };
}

export function renderRuleTableItem(
    rule: LocalPackRuleListItemType,
    refreshRules: () => void
): LocalPackRulesTableItemType {
    return {
        [LocalPackRulesTableFieldsEnum.Name]: rule.name,
        [LocalPackRulesTableFieldsEnum.Frequency]: (
            <Locale stringKey={LOCALPACK_FREQUENCY_LOCALIZATION_MAP[rule.periodicity]} />
        ),
        [LocalPackRulesTableFieldsEnum.BoxSize]: rule.gridSize,
        [LocalPackRulesTableFieldsEnum.DistanceBetweenPoints]: (
            <Locale
                stringKey="LOCAL_PACK__DISTANCE"
                valueMap={{
                    distance: rule.gridStep,
                }}
            />
        ),
        [LocalPackRulesTableFieldsEnum.Status]: <LocalPackRuleStatus rule={rule} />,
        [LocalPackRulesTableFieldsEnum.Actions]: (
            <LocalPackRuleAction onActionFinish={refreshRules} ruleId={rule.id} ruleStatus={rule.status} />
        ),
        id: String(rule.id),
        children: rule.queries.map((searchQuery) => searchQuery.query).map(renderRuleSearchPhrase),
    };
}

export const localPackRulesTableColumns: TableColumnsType<LocalPackRulesTableItemType> = [
    {
        title: <Locale stringKey="LOCAL_PACK__RULES_TABLE__KEY" />,
        dataIndex: LocalPackRulesTableFieldsEnum.Name,
    },
    {
        title: <Locale stringKey="LOCAL_PACK__RULES_TABLE__FREQUENCY" />,
        dataIndex: LocalPackRulesTableFieldsEnum.Frequency,
        width: 120,
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__RULES_TABLE__GRID_SIZE" />,
        dataIndex: LocalPackRulesTableFieldsEnum.BoxSize,
        width: 120,
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__RULES_TABLE__GRID_DISTANCE" />,
        dataIndex: LocalPackRulesTableFieldsEnum.DistanceBetweenPoints,
        width: 120,
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__RULES_TABLE__STATUS" />,
        dataIndex: LocalPackRulesTableFieldsEnum.Status,
        width: 120,
        align: 'center',
    },
    {
        align: 'center',
        title: <Locale stringKey="LOCAL_PACK__RULES_TABLE__ACTIONS" />,
        dataIndex: LocalPackRulesTableFieldsEnum.Actions,
        width: 120,
    },
];
