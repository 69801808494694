import {Form} from 'antd';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {
    PostAiSuggestHookType,
    PostFormFieldsEnum,
    PostFormType,
} from '../../../../../../../../service/posts/posts-types';

import {PostAiSuggest} from './ai-suggest/post-ai-suggest';
import {PostTextField} from './post-text-field';

type PropsType = {
    onChange: (newCommonText: string) => void;
    value: string;
    maxLength: number;
    suggestSettings: PostAiSuggestHookType;
};

export function CommonTextItem(props: PropsType): JSX.Element {
    const {maxLength, onChange, value, suggestSettings} = props;

    const {maxLengthFieldRule} = useFormRules();

    return (
        <Form.Item<PostFormType>
            dependencies={[PostFormFieldsEnum.Posts]}
            label={<Locale stringKey="POSTS_FORM__CONTENT__LABEL" />}
            rules={[maxLengthFieldRule(maxLength)]}
        >
            {() => (
                <>
                    <PostTextField disabled={false} maxLength={maxLength} onChange={onChange} value={value} />
                    <PostAiSuggest onSelectSuggest={onChange} suggestSettings={suggestSettings} />
                </>
            )}
        </Form.Item>
    );
}
