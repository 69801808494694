import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {deletedReviewsCountSchema, DeletedReviewsCountType} from './deleted-reviews-count-type';

export function fetchDeletedReviewsCount(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<DeletedReviewsCountType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/deleted_reviews/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        deletedReviewsCountSchema
    );
}
