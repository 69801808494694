import {TablePaginationConfig} from 'antd';
import {z as r} from 'zod';

import {defaultPaginationState} from '../../util/hook';
import {generateResponseSchema} from '../api/api-type';
import {companyAddressSchema} from '../company/company-type';

export const matchingPaginationState: TablePaginationConfig = {
    ...defaultPaginationState,
    pageSize: 20,
};

export const locationSchema = r.object({
    id: r.string(),
    name: r.string(),
    phone: r.array(r.string()).optional(),
    adds: r.string().nullable().optional(),
    formattedAddress: r.string().nullable().optional(),
    website: r.string().nullable().optional(),
    url: r.string().nullable(),
    point: r.object({
        lat: r.number().nullable(),
        lon: r.number().nullable(),
    }),
});

export const matchingLocationSchema = locationSchema.extend({
    verified: r.boolean().optional(),
    categories: r.array(r.string()).optional(),
    companiesInRadius: r.array(r.number()),
});

export const matchingLocationsSchema = r.object({
    locations: r.array(matchingLocationSchema),
});

export const facebookPagesSchema = r.array(locationSchema);

export const facebookBrandPageSchema = r.object({
    id: r.number(),
    originId: r.string().optional(),
    brand: r.object({
        id: r.number(),
        name: r.string(),
    }),
});

export const facebookBrandPagesSchema = generateResponseSchema(facebookBrandPageSchema);

export const needActionCompanySchema = r.object({
    id: r.number(),
    name: r.string(),
    code: r.string(),
    address: companyAddressSchema,
    categories: r.array(
        r.object({
            id: r.number(),
            name: r.string(),
            isMain: r.boolean().optional(),
        })
    ),
});

export const needActionCompaniesSchema = generateResponseSchema(
    r.object({
        id: r.number(),
        company: needActionCompanySchema,
    })
);
