import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Flex, Space} from 'antd';
import {ReactElement} from 'react';

import {StatusBadge} from '../../../../../../../../component/status-badge/status-badge';
import {Text} from '../../../../../../../../component/text/text';
import {gray7} from '../../../../../../../../css/var-export.scss';

type PropsType = {
    formattedDate: string | null;
    formattedTime: string | null;
    responsibleUserName: string;
    oldValue: string;
    newValue: string;
};

export function StatusLog(props: PropsType): ReactElement {
    const {formattedDate, formattedTime, responsibleUserName, oldValue, newValue} = props;

    return (
        <div>
            <Text secondary>
                {formattedDate} {formattedTime}
            </Text>
            <Flex align="center" gap={4} wrap>
                <Text
                    lighter
                    stringKey="REVIEWS__REVIEW_STATUS__CHANGE"
                    valueMap={{user: <Text bolder>{responsibleUserName}</Text>}}
                />
                <Space>
                    <StatusBadge status={String(oldValue)} />
                    <FontAwesomeIcon color={gray7} fontSize={16} icon={faArrowRight} />
                    <StatusBadge status={String(newValue)} />
                </Space>
            </Flex>
        </div>
    );
}
