import {faAt, faExternalLink, faPhone} from '@fortawesome/pro-regular-svg-icons';
import {faEllipsisVertical} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Flex, Space, Tooltip} from 'antd';
import {ReactElement, useCallback, useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {RatingStarLine} from '../../../../../../../layout/rating-star-line/rating-star-line';
import {Locale} from '../../../../../../../provider/locale/localization';
import {ReviewInfoDataType} from '../../../../../../../service/reviews/reviews-info';
import {useReviewLogsList} from '../../../../../../../service/reviews/reviews-logs';
import {NoteFormType, useNotes, useNotesMutation} from '../../../../../../../service/reviews/reviews-notes';
import {useCopy} from '../../../../../../../util/copy-hook/copy-hook';
import {useFormat} from '../../../../../../../util/format-hook/format-hook';
import {useReviewActivityHook} from '../../../../review-activity-provider/review-activity-provider';
import {ReviewCatalogLogo} from '../../../../review-catalog-logo/review-catalog-logo';
import {ReviewFormModal} from '../../../../review-form/review-form-modal';
import {ReviewFormModeEnum} from '../../../../review-form/review-form-type';
import {ReviewStatus} from '../../../../review-status/review-status';
import {getReviewCreationDate} from '../../../../reviews-helper';
import {useReviewStateHook} from '../../../../reviews-state/reviews-state-provider';
import {ReviewDataWithCatalogType} from '../../review-list-type';
import {useReviewMenu} from '../review-menu-hook/review-menu-hook';

import {ReviewHistoryModal} from './history-modal/history-modal';
import {NoteModal} from './note-modal/note-modal';
import {ResponsibleUserModal} from './responsible-user-modal/responsible-user-modal';
import * as styles from './review-header.scss';

type PropsType = {
    reviewData: ReviewDataWithCatalogType;
    isMobileWidthReview: boolean;
    isReviewDeleted: boolean;
    info: ReviewInfoDataType | null;
};

// eslint-disable-next-line complexity
export function ReviewHeader(props: PropsType): ReactElement {
    const {reviewData, isReviewDeleted, isMobileWidthReview, info} = props;

    const {
        id,
        catalog,
        author,
        ableToChangeResponsibleUser,
        originUrl,
        rating,
        comment,
        createdInCatalog,
        processStatus,
        userFields,
        responsibleUser,
        replyTimeInRd,
        replyTimeInCatalog,
        createdInRd,
        deletedAt,
    } = reviewData;

    const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);
    const [isOpenHistoryModal, setIsOpenHistoryModal] = useState<boolean>(false);
    const [isOpenResponsibleUserModal, setIsOpenResponsibleUserModal] = useState<boolean>(false);
    const [isOpenReviewEditModal, setIsOpenReviewEditModal] = useState<boolean>(false);

    const {getFormattedDateTime} = useFormat();
    const onCopy = useCopy('TEXT__COPIED');

    const {refreshReview, refreshReviewsInfo, refreshCatalogs, reviewsCatalogListIsInProgress} = useReviewStateHook();
    const {isHistoryVisible} = useReviewActivityHook();
    const {refetch: loadLogs} = useReviewLogsList(id, isHistoryVisible);
    const {mutateAsync, isLoading} = useNotesMutation();

    const {refetch: refreshNotes} = useNotes(id, Number(info?.notesCount) > 0 || false);

    async function refreshNotesFromModal() {
        await refreshNotes();
    }

    async function onSubmit(formValues: NoteFormType) {
        return mutateAsync(
            {text: (formValues.text ?? '').trim(), reviewId: id},
            {
                onSuccess: async () => {
                    if (!(Number(info?.notesCount) > 0)) {
                        await refreshReviewsInfo();
                    }

                    setIsOpenNoteModal(false);
                    await refreshNotesFromModal();
                },
            }
        );
    }

    const handleSubmitFinishReviewForm = useCallback(() => {
        if (refreshReview) {
            refreshReview(id);
        }

        if (refreshCatalogs) {
            refreshCatalogs();
        }
    }, [id, refreshCatalogs, refreshReview]);

    const isShowExternalOptions = Boolean(catalog?.is_external_reviews) && !deletedAt;

    const menuItems = useReviewMenu({
        reviewId: id,
        isShowExternalOptions,
        text: comment || '',
        setIsOpenNoteModal,
        setIsOpenHistoryModal,
        setIsOpenResponsibleUserModal,
        ableToChangeResponsibleUser,
        setIsOpenReviewEditModal,
        onSubmitFinish: handleSubmitFinishReviewForm,
    });

    return (
        <div>
            <Flex className={styles.ReviewHeader_infoWrapper}>
                <Flex vertical>
                    <Space size={12}>
                        {author && (
                            <Text bolder large>
                                {author}
                            </Text>
                        )}
                        {typeof rating === 'number' && <RatingStarLine rating={rating} />}
                    </Space>
                    <Space wrap>
                        <Tooltip
                            placement="bottomLeft"
                            rootClassName={styles.ReviewHeader_parseDateTooltip}
                            title={
                                <Flex vertical>
                                    <Flex className={styles.ReviewHeader_parseDate}>
                                        <Locale
                                            stringKey="REVIEWS__REVIEWS_PARSED_AT"
                                            valueMap={{
                                                date: (
                                                    <span>
                                                        {getFormattedDateTime(new Date(createdInRd), {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                    </span>
                                                ),
                                            }}
                                        />
                                    </Flex>

                                    {createdInCatalog && (
                                        <Flex className={styles.ReviewHeader_parseDate}>
                                            <Locale
                                                stringKey="REVIEWS__REVIEWS_IN_CATALOG"
                                                valueMap={{
                                                    date: getFormattedDateTime(new Date(createdInCatalog), {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    }),
                                                }}
                                            />
                                        </Flex>
                                    )}
                                </Flex>
                            }
                        >
                            <Text secondary>
                                {getFormattedDateTime(new Date(getReviewCreationDate(reviewData)), {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                })}
                            </Text>
                        </Tooltip>
                        <Space>
                            <div className={styles.ReviewHeader_catalogLogo}>
                                <ReviewCatalogLogo
                                    catalog={catalog}
                                    isCatalogLoading={reviewsCatalogListIsInProgress}
                                />
                            </div>
                            {!isReviewDeleted &&
                                (originUrl ? (
                                    <a
                                        className={styles.ReviewHeader_externalLink}
                                        href={originUrl}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        {catalog?.name || ''}
                                        <FontAwesomeIcon icon={faExternalLink} />
                                    </a>
                                ) : (
                                    <Text secondary>{catalog?.name || ''}</Text>
                                ))}
                        </Space>
                    </Space>
                </Flex>

                <div className={styles.ReviewHeader_statusWrapper}>
                    {!isMobileWidthReview && (
                        <ReviewStatus
                            className={styles.ReviewHeader_status}
                            onChange={loadLogs}
                            processStatus={processStatus}
                            refreshReview={refreshReview}
                            reviewId={id}
                        />
                    )}
                    <Dropdown autoAdjustOverflow menu={{items: menuItems}} placement="bottomRight" trigger={['click']}>
                        <Button className={styles.ReviewHeader_dropdownButton} type="text">
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Button>
                    </Dropdown>
                </div>
            </Flex>

            {(userFields.phone || userFields.email) && (
                <Space wrap>
                    {userFields?.phone && (
                        <Space
                            className={styles.ReviewHeader_userEmail}
                            onClick={() => onCopy(userFields.phone as string)}
                            size={4}
                        >
                            <FontAwesomeIcon icon={faPhone} />
                            <Text inheritColor>{userFields.phone}</Text>
                        </Space>
                    )}
                    {userFields?.email && (
                        <Space
                            className={styles.ReviewHeader_userEmail}
                            onClick={() => onCopy(userFields.email as string)}
                            size={4}
                        >
                            <FontAwesomeIcon icon={faAt} />
                            <Text inheritColor>{userFields.email}</Text>
                        </Space>
                    )}
                </Space>
            )}

            {isOpenReviewEditModal && (
                <ReviewFormModal
                    isOpen={isOpenReviewEditModal}
                    mode={ReviewFormModeEnum.edit}
                    onCancel={() => setIsOpenReviewEditModal(false)}
                    onSubmitFinish={handleSubmitFinishReviewForm}
                    review={reviewData}
                />
            )}

            {isOpenNoteModal && (
                <NoteModal
                    isLoading={isLoading}
                    onCancel={setIsOpenNoteModal}
                    onSubmit={onSubmit}
                    reviewId={id}
                    title={<Locale stringKey="REVIEWS__NOTE_MODAL__TITLE" />}
                />
            )}
            {isOpenHistoryModal && (
                <ReviewHistoryModal
                    onCancel={() => setIsOpenHistoryModal(false)}
                    replyTimeInCatalog={replyTimeInCatalog}
                    replyTimeInRd={replyTimeInRd}
                    reviewAuthor={originUrl ? null : author}
                    reviewCreatedInCatalog={createdInCatalog}
                    reviewId={id}
                />
            )}

            {isOpenResponsibleUserModal && (
                <ResponsibleUserModal
                    initialSelectedUserId={responsibleUser?.id}
                    onCancel={() => setIsOpenResponsibleUserModal(false)}
                    refreshReview={refreshReview}
                    reviewData={reviewData}
                    reviewId={id}
                />
            )}
        </div>
    );
}
