import {Button, Col, Row, Space} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {filterFormId} from '../drawer-filter-const';

type PropsType = {
    onReset: () => void;
};

export function FilterFooter(props: PropsType): JSX.Element {
    const {onReset} = props;

    return (
        <Row justify="end">
            <Col>
                <Space>
                    <Button onClick={onReset}>
                        <Locale stringKey="TEXT__RESET" />
                    </Button>

                    <Button form={filterFormId} htmlType="submit" type="primary">
                        <Locale stringKey="TEXT__APPLY" />
                    </Button>
                </Space>
            </Col>
        </Row>
    );
}
