import {ReviewCatalogType} from '../../../../../service/reviews/reviews-catalogs';
import {ReviewSuggestionType} from '../../../../../service/reviews/reviews-suggestions';
import {ReviewDataType} from '../../../../../service/reviews/reviews-type';
import {ReviewActivitySelector} from '../../review-activity-selector/review-activity-selector';
import {ReviewAnswers} from '../../review-answers/review-answers';

import * as styles from './activity-area.scss';

type PropsType = {
    reviewData: ReviewDataType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    refreshSuggestions: () => Promise<unknown>;
    suggestion?: ReviewSuggestionType;
    notesCount: number;
    isTwoGisDomainWithEmptyComment: boolean;
    catalog: ReviewCatalogType | undefined;
};

export function ActivityArea(props: PropsType): JSX.Element {
    const {
        notesCount,
        reviewData,
        refreshReview,
        refreshSuggestions,
        suggestion,
        isTwoGisDomainWithEmptyComment,
        catalog,
    } = props;

    const isGptSuggestSupported = catalog?.gpt_suggest_supported;

    return (
        <>
            <div className={styles.activity_selector}>
                <ReviewActivitySelector notesCount={notesCount} reviewChildren={reviewData.children} />
            </div>

            {!isTwoGisDomainWithEmptyComment && (
                <ReviewAnswers
                    isGptSuggestSupported={isGptSuggestSupported}
                    refreshReview={refreshReview}
                    refreshSuggestions={refreshSuggestions}
                    review={reviewData}
                    suggestion={suggestion}
                />
            )}
        </>
    );
}
