import {appRoute} from '../../../app-route';
import {ActivationPage} from '../../../layout/activation-page/activation-page';
import {Locale} from '../../../provider/locale/locale';

export function CompaniesGroupActivation(): JSX.Element {
    return (
        <ActivationPage
            breadCrumbList={[
                {
                    path: appRoute.settingsCompaniesGroup.path,
                    titleLangKey: 'CATEGORY_NAME__GROUPS_OF_COMPANIES',
                },
            ]}
            hideMessageHeader
            interestedMessageLangKey="ACTIVATION_PAGE__TARIFF_DOES_NOT_SUPPORT_THIS_FEATURE"
            title={<Locale stringKey="CATEGORY_NAME__GROUPS_OF_COMPANIES" />}
        />
    );
}
