import {faPenToSquare, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import * as styles from './actions-cell.scss';

export function renderCellActions(edit: () => void, remove: (() => void) | null): JSX.Element {
    return (
        <div className={styles.Actions}>
            <Button aria-label="edit" icon={<FontAwesomeIcon icon={faPenToSquare} />} onClick={edit} type="link" />

            {remove !== null ? (
                <Button
                    aria-label="remove"
                    icon={<FontAwesomeIcon className={styles.Actions_removeIcon} icon={faTrashCan} />}
                    onClick={remove}
                    type="link"
                />
            ) : null}
        </div>
    );
}
