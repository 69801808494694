import {faCircleChevronRight, faPlay} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMemo} from 'react';

import {PostImageType, PostVideoType} from '../../../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../../../util/css';

import * as styles from './instagram-preview-images.scss';

type PropsType = {
    images?: Array<PostImageType>;
    video?: PostVideoType | null;
    isOnlyVideo?: boolean;
};

export function InstagramPreviewImages(props: PropsType): JSX.Element {
    const {images, video, isOnlyVideo} = props;

    const showSliderActions = (images && images.length > 1) || (images && images.length === 1 && video);

    const renderImages: Array<PostVideoType | PostImageType> = useMemo(() => {
        const resultContent = images || [];

        if (video && images) {
            return [...resultContent, video];
        }

        return resultContent;
    }, [images, video]);

    return (
        <div
            className={classNames(styles.InstagramPreviewImages, {
                [styles.InstagramPreviewImages_video]: isOnlyVideo,
            })}
        >
            {images && images[0] ? (
                <img alt="" className={styles.InstagramPreviewImages_image} src={images[0].url} />
            ) : null}
            {isOnlyVideo && (
                <>
                    <img alt="" className={styles.InstagramPreviewImages_image} src={video?.previewImageUrl} />
                    <div className={styles.InstagramPreviewImages_videoOverlay}>
                        <FontAwesomeIcon className={styles.InstagramPreviewImages_videoAction} icon={faPlay} />
                    </div>
                </>
            )}
            {showSliderActions && (
                <div className={styles.InstagramPreviewImages_actionContainer}>
                    <FontAwesomeIcon className={styles.InstagramPreviewImages_arrow} icon={faCircleChevronRight} />
                    <div className={styles.InstagramPreviewImages_dotContainer}>
                        {renderImages.map((image, index) => (
                            <span
                                className={classNames(styles.InstagramPreviewImages_dot, {
                                    [styles.InstagramPreviewImages_dot__active]: index === 0,
                                })}
                                key={'id' in image ? image.id : image.url}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
