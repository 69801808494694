import {Pagination, TablePaginationConfig} from 'antd';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLocale} from '../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {useQuestions, useQuestionsStatistic} from '../../../service/questions/questions-hook';
import {QuestionDataType} from '../../../service/questions/questions-type';
import {defaultPaginationState} from '../../../util/hook';

import {BigRadioButton} from './big-radio-button/big-radio-button';
import {BigRadioButtonNameEnum} from './big-radio-button/big-radio-button-const';
import {QuestionsAndAnswersFilter} from './questions-and-answers-filter/questions-and-answers-filter';
import {QuestionsAndAnswersList} from './questions-and-answers-list/questions-and-answers-list';
import * as styles from './questions-and-answers.scss';

export function QuestionsAndAnswers(): JSX.Element {
    const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPaginationState);

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {questionsAndAnswers: questionsAndAnswersRoute} = appRoute;
    const [activeRadioButtonName, setActiveRadioButtonName] = useState<BigRadioButtonNameEnum>(
        BigRadioButtonNameEnum.all
    );
    const {getLocalizedString} = useLocale();
    const [hasOwnerAnswer, setHasOwnerAnswer] = useState<boolean | null>(null);
    const [dateBefore, setDateBefore] = useState<Date | null>(null);
    const [dateAfter, setDateAfter] = useState<Date | null>(null);
    const [employerId, setEmployerId] = useState<number | null>(null);

    useEffect(() => {
        setPagination({
            current: 1,
            pageSize: pagination.pageSize,
        });
    }, [setPagination, mainFilterKey, pagination.pageSize]);

    const handlePaginationChange = useCallback(
        (pageNumber: number, pageSize?: number) => {
            setPagination({
                current: pageNumber,
                pageSize: Number(pageSize) || 0,
            });

            if (pagination.current !== pageNumber) {
                track(AnalyticsTarget.PersonalCabinet.Pagination, pageNumber);
            }
        },
        [pagination]
    );

    const {
        result: questionsResult,
        isInProgress: questionsIsInProgress,
        loadQuestions,
    } = useQuestions(
        {
            count: Number(pagination.pageSize) || 0,
            page: Number(pagination.current) || 0,
        },
        {hasOwnerAnswer, dateBefore, dateAfter, employerId},
        mainFilterKey
    );

    const {result: questionsStatisticResult, loadQuestionsStatistic} = useQuestionsStatistic(
        {
            hasOwnerAnswer,
            employerId,
            dateBefore,
            dateAfter,
        },
        mainFilterKey
    );

    function handleAnswerAdded() {
        return Promise.all([loadQuestions(), loadQuestionsStatistic()]);
    }

    const questionList: Array<QuestionDataType> = useMemo(() => {
        return questionsResult?.results || [];
    }, [questionsResult?.results]);

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__QUESTIONS_N_ANSWERS')} />

            <BreadCrumbs
                list={[
                    {
                        path: questionsAndAnswersRoute.path,
                        titleLangKey: 'CATEGORY_NAME__QUESTIONS_N_ANSWERS',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle
                    productName={UsetifulNameProductEnum.QUESTIONS_N_ANSWERS}
                    title="QUESTIONS_N_ANSWERS__PROCESSING_QUESTIONS"
                />
            </PageHeader>

            <div className={styles.QuestionsAndAnswers_buttons}>
                <BigRadioButton
                    isActive={activeRadioButtonName === BigRadioButtonNameEnum.all}
                    onClick={() => {
                        setActiveRadioButtonName(BigRadioButtonNameEnum.all);
                        setHasOwnerAnswer(null);
                    }}
                    type={BigRadioButtonNameEnum.all}
                    value={questionsStatisticResult?.all || 0}
                />

                <BigRadioButton
                    isActive={activeRadioButtonName === BigRadioButtonNameEnum.withAnswer}
                    onClick={() => {
                        setActiveRadioButtonName(BigRadioButtonNameEnum.withAnswer);
                        setHasOwnerAnswer(true);
                    }}
                    type={BigRadioButtonNameEnum.withAnswer}
                    value={questionsStatisticResult?.has_answers || 0}
                />

                <BigRadioButton
                    isActive={activeRadioButtonName === BigRadioButtonNameEnum.withoutAnswer}
                    onClick={() => {
                        setActiveRadioButtonName(BigRadioButtonNameEnum.withoutAnswer);
                        setHasOwnerAnswer(false);
                    }}
                    type={BigRadioButtonNameEnum.withoutAnswer}
                    value={questionsStatisticResult?.has_no_answers || 0}
                />
            </div>

            <div className={styles.QuestionsAndAnswers_content}>
                <QuestionsAndAnswersList
                    isLoading={questionsIsInProgress}
                    onAnswerAdded={handleAnswerAdded}
                    questionList={questionList}
                />

                <QuestionsAndAnswersFilter
                    setDateAfter={setDateAfter}
                    setDateBefore={setDateBefore}
                    setEmployerId={setEmployerId}
                />
            </div>

            <div className={styles.QuestionsAndAnswers_pagination}>
                {Boolean(questionsResult?.count && questionsResult?.count > 0) && (
                    <Pagination
                        current={pagination.current}
                        hideOnSinglePage={false}
                        onChange={handlePaginationChange}
                        pageSize={pagination.pageSize}
                        showSizeChanger
                        size="default"
                        total={questionsResult?.count}
                    />
                )}
            </div>

            <Spinner isShow={questionsIsInProgress} position="absolute" />
        </Page>
    );
}
