import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {
    ConfirmationErrorMessageEnum,
    ConfirmationSourcesStatusEnum,
} from '../../../../../service/confirmation/confirmation-type';

import {confirmationErrorMessageTranslationMap, confirmationItemStatusTranslationMap} from './confirmation-item-const';

export function getConfirmationMessage(
    status: ConfirmationSourcesStatusEnum,
    errorMessage: ConfirmationErrorMessageEnum
): LangKeyType {
    return (status === ConfirmationSourcesStatusEnum.ErrorOnSending ||
        status === ConfirmationSourcesStatusEnum.ErrorOnConfirm) &&
        errorMessage !== null
        ? confirmationErrorMessageTranslationMap[errorMessage]
        : confirmationItemStatusTranslationMap[status];
}
