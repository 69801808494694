import 'dayjs/locale/es';
import 'dayjs/locale/kk';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/bg';

import antLocaleBgBg from 'antd/lib/locale/bg_BG';
import antLocaleEnUs from 'antd/lib/locale/en_US';
import antLocaleEsEs from 'antd/lib/locale/es_ES';
import antLocaleKkKz from 'antd/lib/locale/kk_KZ';
import antLocalePlPl from 'antd/lib/locale/pl_PL';
import antLocaleRuRu from 'antd/lib/locale/ru_RU';
import {invert} from 'lodash';

import {LocaleConstType, LocaleNameEnum, ShortLocaleNameEnum} from './locale-context-type';
import {LangKeyType} from './translation/type';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// WARNING: tests use the mocked version which doesn't use async loading, see setup-jest.ts
// Can't use async in tests because it breaks testing of 'loading in progress' component states
export function getLocaleNameToTranslationsObjectMap() {
    return {
        [LocaleNameEnum.enUs]: async () => {
            const {enUs} = await import(/* webpackChunkName: 'language-en-us' */ './translation/en-us/data');

            return enUs;
        },
        [LocaleNameEnum.ruRu]: async () => {
            const {ruRu} = await import(/* webpackChunkName: 'language-ru-ru' */ './translation/ru-ru/data');

            return ruRu;
        },
        [LocaleNameEnum.plPl]: async () => {
            const {plPl} = await import(/* webpackChunkName: 'language-pl-pl' */ './translation/pl-pl/data');

            return plPl;
        },
        [LocaleNameEnum.kkKz]: async () => {
            const {kkKz} = await import(/* webpackChunkName: 'language-kk-kz' */ './translation/kk-kz/data');

            return kkKz;
        },
        [LocaleNameEnum.esEs]: async () => {
            const {esEs} = await import(/* webpackChunkName: 'language-es-es' */ './translation/es-es/data');

            return esEs;
        },
        [LocaleNameEnum.bgBg]: async () => {
            const {bgBg} = await import(/* webpackChunkName: 'language-es-es' */ './translation/bg-bg/data');

            return bgBg;
        },
    };
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

// copies of META__LANGUAGE_NAME, can't import directly because of chunks
export const localeNameToLanguageNameMap: Record<LocaleNameEnum, string> = {
    [LocaleNameEnum.enUs]: 'English',
    [LocaleNameEnum.ruRu]: 'Русский',
    [LocaleNameEnum.plPl]: 'Polski',
    [LocaleNameEnum.kkKz]: 'Қазақша',
    [LocaleNameEnum.esEs]: 'Español',
    [LocaleNameEnum.bgBg]: 'Български',
};

export const localeNameToAntLocaleMap = {
    [LocaleNameEnum.enUs]: antLocaleEnUs,
    [LocaleNameEnum.ruRu]: antLocaleRuRu,
    [LocaleNameEnum.plPl]: antLocalePlPl,
    [LocaleNameEnum.kkKz]: antLocaleKkKz,
    [LocaleNameEnum.esEs]: antLocaleEsEs,
    [LocaleNameEnum.bgBg]: antLocaleBgBg,
};

export const shortLocaleNameToLocaleNameMap: Record<ShortLocaleNameEnum, LocaleNameEnum> = {
    [ShortLocaleNameEnum.en]: LocaleNameEnum.enUs,
    [ShortLocaleNameEnum.ru]: LocaleNameEnum.ruRu,
    [ShortLocaleNameEnum.pl]: LocaleNameEnum.plPl,
    [ShortLocaleNameEnum.kk]: LocaleNameEnum.kkKz,
    [ShortLocaleNameEnum.es]: LocaleNameEnum.esEs,
    [ShortLocaleNameEnum.bg]: LocaleNameEnum.bgBg,
};

export const localeNameToShortLocaleNameMap = invert(shortLocaleNameToLocaleNameMap) as Record<
    LocaleNameEnum,
    ShortLocaleNameEnum
>;

export enum WeekDaysEnum {
    monday = '1',
    tuesday = '2',
    wednesday = '3',
    thursday = '4',
    friday = '5', // (https://www.youtube.com/watch?v=1AnG04qnLqI)
    saturday = '6',
    sunday = '7',
}

export const WEEK_DAY_BY_ID: Record<WeekDaysEnum, LangKeyType> & Record<string, LangKeyType> = {
    [WeekDaysEnum.monday]: 'WEEK_DAY__MONDAY',
    [WeekDaysEnum.tuesday]: 'WEEK_DAY__TUESDAY',
    [WeekDaysEnum.wednesday]: 'WEEK_DAY__WEDNESDAY',
    [WeekDaysEnum.thursday]: 'WEEK_DAY__THURSDAY',
    [WeekDaysEnum.friday]: 'WEEK_DAY__FRIDAY',
    [WeekDaysEnum.saturday]: 'WEEK_DAY__SATURDAY',
    [WeekDaysEnum.sunday]: 'WEEK_DAY__SUNDAY',
};

export const localeConst: LocaleConstType = {
    defaults: {
        localeName: LocaleNameEnum.enUs,
        shortLocaleName: ShortLocaleNameEnum.en,
    },
    key: {
        localStorage: {
            localeName: 'my-locale-name-v.1.0', // PROJECT_ID + 'my-locale-name-v.1.0'
        },
    },
};

export const SESSION_STORAGE_IS_AUTH_LANGUAGE_KEY = 'isAuthLanguage';
