import {faInfoSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Locale} from '../../../../../../provider/locale/localization';
import {GoogleCclStatusEnum, GoogleCompanyType} from '../../../../../../service/google/google-type';
import {TipType} from '../../tips/tips-type';

import * as styles from './processing-step.scss';

export function getProcessingStepTips(companies: Array<GoogleCompanyType>, companyName?: string): Array<TipType> {
    const states = new Set(companies.map(({status}) => status));
    const tips: Array<TipType> = [];

    if (states.has(GoogleCclStatusEnum.ActionRequired)) {
        tips.push({
            title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__ACTION_REQUIRED' as const,
            text: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__ACTION_REQUIRED__DESCRIPTION" />,
        });
    }

    if (states.has(GoogleCclStatusEnum.AwaitingRights)) {
        tips.push({
            title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_RIGHTS' as const,
            text: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_RIGHTS__DESCRIPTION" />,
        });
    }

    if (states.has(GoogleCclStatusEnum.AwaitingConfirmation)) {
        tips.push({
            title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_CONFIRMATION' as const,
            text: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_CONFIRMATION__DESCRIPTION" />,
        });
    }

    if (states.has(GoogleCclStatusEnum.Send)) {
        tips.push({
            title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__READY_TO_SEND' as const,
            text: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__READY_TO_SEND__DESCRIPTION" />,
        });
    }

    if (states.has(GoogleCclStatusEnum.Synchronize)) {
        tips.push({
            title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED' as const,
            text: (
                <Locale
                    stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED__DESCRIPTION"
                    valueMap={{companyName}}
                />
            ),
        });
    }

    tips.push({
        title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__WARNING',
        text: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__WARNING__DESCRIPTION" />,
        icon: <FontAwesomeIcon className={styles.ProcessingStep_infoIcon} icon={faInfoSquare} />,
    });

    return tips;
}
