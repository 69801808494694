import {useEffect, useMemo} from 'react';

import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {PaginationOptionsType, PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {PaginatedResponseType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchReviewCityList} from './review-cities-api';
import {ReviewCityListQueryType, ReviewCityType} from './review-cities-type';

export function useReviewCityList(
    options: ReviewCityListQueryType,
    mainFilterKey: string,
    paginationOption?: PaginationOptionsType
): UseHookType<PaginatedResponseType<ReviewCityType>> & PaginationType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<ReviewCityType>>();

    const {startDate, endDate, sentiments, sources, tags, phrases, topics, rating, withAnswers, withText} = options;

    const paginationDependencies = useMemo(() => {
        return {
            startDate,
            endDate,
            sentiments,
            sources,
            tags,
            phrases,
            topics,
            rating,
            withAnswers,
            withText,
            mainFilterKey,
        };
    }, [startDate, endDate, sentiments, sources, tags, phrases, topics, rating, withAnswers, withText, mainFilterKey]);

    const pagination = usePagination({...paginationOption, dependencies: paginationDependencies});

    const {page, pageSize, onDataLoadFailed, onDataLoaded, refreshId} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewCityList(
            {
                page,
                pageSize,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, setIsInProgress, setProcessError, setResult, onDataLoaded, onDataLoadFailed]);

    return {isInProgress, processError, result, reset, pagination};
}
