import {generatePath} from 'react-router';

import {getIsoYyyyMmDdString} from './date';
import {DOMAIN_REGEXP, URL_REGEXP} from './regexp';
import {
    GeneratePathWithQueryParametersType,
    ObjectToUrlParametersType,
    QueryMapType,
    QuerySimpleValueType,
    QueryValueType,
} from './type';
import {UseUrlHookOptionsType} from './url-hook/url-hook-type';

export function isValidUrl(url: string): boolean {
    // The REGEXP /^(http|https):\/\/(?:[^\s/:@]+(?::[^\s/:@]*)?@)?(?:(?:0|25[0-5]|2[0-4]\d|1\d?\d?|[1-9]\d?)(?:\.(?:0|25[0-5]|2[0-4]\d|1\d?\d?|[1-9]\d?)){3}|\[[\d.:a-f]+]|([\da-z¡-\uFFFF](?:[\da-z¡-\uFFFF-]{0,61}[\da-z¡-\uFFFF])?(?:\.(?!-)[\da-z¡-\uFFFF-]{1,63}(?<!-))*\.(?!-)(?:[a-z¡-\uFFFF-]{2,63}|xn--[\da-z]{1,59})(?<!-)\.?|localhost))(?::\d{2,5})?(?:[#/?]\S*)?$/;
    // includes regexp Negative Lookahead match (?<!-)\. and (?<!-))
    // However Safari doesn't support Negative Lookahead
    // Thus we excluded Negative Lookahead from URL_REGEXP. But we still need to cover `-.` and `-)` case
    if (url.includes('-.') || url.includes('-)')) {
        return false;
    }

    return Boolean(URL_REGEXP.test(url));
}

export function isValidDomain(url: string): boolean {
    // The REGEXP /^((?!-)[\dA-Za-z-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/
    // includes regexp Negative Lookahead match (?<!-)\. and (?<!-))
    // However Safari doesn't support Negative Lookahead
    // Thus we excluded Negative Lookahead from URL_REGEXP. But we still need to cover `-.` and `-)` case
    if (url.includes('-.') || url.includes('-)')) {
        return false;
    }

    return Boolean(DOMAIN_REGEXP.test(url));
}

function stringifyUrlParameterSimpleValue(
    value: QuerySimpleValueType,
    parameters?: UseUrlHookOptionsType
): string | null {
    // QuerySimpleValueType

    // void | null
    // eslint-disable-next-line no-undefined
    if (value === undefined || value === null) {
        return null;
    }

    // empty string
    if (typeof value === 'string') {
        return value.trim() || null;
    }

    // Date, `Number.isNaN(value.getTime())` - check for valid Date
    if (value instanceof Date) {
        if (Number.isNaN(value.getTime())) {
            return null;
        }

        if (parameters?.isOnlyDate) {
            return getIsoYyyyMmDdString(value);
        }

        return value.toISOString();
    }

    if (typeof value === 'number') {
        // check for Infinity and NaN
        return JSON.parse(JSON.stringify(value));
    }

    // if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
    // }

    // return null;
}

export function objectToUrlParameters(options: ObjectToUrlParametersType, parameters?: UseUrlHookOptionsType): string {
    const parameterList: Array<string> = [];

    Object.keys(options).forEach((key: string) => {
        const value: QueryValueType = options[key];

        if (Array.isArray(value) && value.length === 0) {
            return;
        }

        if (Array.isArray(value)) {
            const stringList: Array<string> = value
                .map<string | null>((simpleValue: QuerySimpleValueType): string | null =>
                    stringifyUrlParameterSimpleValue(simpleValue, {isOnlyDate: parameters?.isOnlyDate})
                )
                .filter(Boolean);

            if (stringList.length > 0) {
                parameterList.push(encodeURIComponent(key) + '=' + encodeURIComponent(stringList.join(',')));
            }

            return;
        }

        const stringValue: string | null = stringifyUrlParameterSimpleValue(value, {
            isOnlyDate: parameters?.isOnlyDate,
        });

        if (stringValue) {
            parameterList.push(encodeURIComponent(key) + '=' + encodeURIComponent(stringValue));
        }
    });

    return parameterList.join('&');
}

export function getParametersFromUrl(fullUrlString: string): QueryMapType {
    const url: URL = new URL(fullUrlString);

    return Object.fromEntries(url.searchParams.entries());
}

export function generatePathWithQueryParameters<S extends string>(
    url: S,
    parameters: GeneratePathWithQueryParametersType<S>
): string {
    const {query, parametersURL} = parameters;

    return `${generatePath(url, query)}?${objectToUrlParameters(parametersURL)}`;
}
