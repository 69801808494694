import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useCallback, useEffect, useState} from 'react';

import {CompanyFormErrorModalContent} from '../../page/main/company-form/page-container/error-modal/error-modal';
import {Locale} from '../../provider/locale/localization';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeV2, fetchAndDeserialize} from '../../util/api-adapter';
import {ApiError} from '../../util/error';
import {rootApiUrl} from '../api/api-const';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    createCompany as fetchCreateCompany,
    fetchLastCompany,
    getCompany as fetchGetCompany,
    updateCompany as fetchUpdateCompany,
} from './company-api';
import {
    CompanyErrorType,
    companySchema,
    CompanyServerType,
    CompanyType,
    LastCompanyType,
    socialApiRegularSchema,
    SocialApiRegularType,
} from './company-type';

type UseCompanyHookAdditionalType = {
    createCompany: (values: CompanyServerType) => Promise<CompanyType | void>;
    updateCompany: (id: number, values: CompanyServerType) => Promise<CompanyType | void>;
    refreshCompany: (companyId?: number) => void;
    fetchCompanyErrorCode: number | null;
};

export function useCompany(
    id: number | null
): UseHookType<CompanyType, CompanyErrorType> & UseCompanyHookAdditionalType {
    const {isInProgress, setIsInProgress, processError, result, setResult, reset, setProcessError} = useApiHooks<
        CompanyType,
        CompanyErrorType
    >();
    const {snackbar} = useSnackbar();

    const {modal} = useModal();

    const [fetchCompanyErrorCode, setFetchCompanyErrorCode] = useState<number | null>(null);

    const refreshCompany = useCallback(
        (companyId?: number) => {
            const currentId = id || companyId;

            if (!currentId) {
                return;
            }

            setIsInProgress(true);
            fetchGetCompany(currentId)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch((error: unknown) => {
                    if (error instanceof ApiError) {
                        setProcessError(
                            deserializeV2<CompanyErrorType>(
                                '',
                                companySchema,
                                error.jsonData as Record<string, unknown>
                            ) as CompanyErrorType
                        );
                        setFetchCompanyErrorCode(error.statusCode);
                    }
                });
        },
        [id, setIsInProgress, setResult, setProcessError]
    );

    const handleCreateCompanyResult = useCallback(
        (newResult: CompanyType) => {
            setResult(newResult);
            setProcessError(null);

            return newResult;
        },
        [setProcessError, setResult]
    );

    useEffect(() => {
        refreshCompany();
    }, [refreshCompany]);

    const handleCreateOrUpdateCompanyError = useCallback(
        (error: unknown) => {
            if (error instanceof ApiError) {
                const deserializeError = deserializeV2<CompanyErrorType>(
                    '',
                    companySchema,
                    error.jsonData as Record<string, unknown>
                ) as CompanyErrorType;

                setProcessError(deserializeError);

                if (deserializeError?.nonFieldErrors?.needModerate) {
                    modal.error({
                        width: 520,
                        centered: true,
                        title: <Locale stringKey="COMPANY_FORM__ERROR_MODAL__TITLE" />,
                        content: <CompanyFormErrorModalContent error={deserializeError.nonFieldErrors} />,
                    });
                } else {
                    snackbar.error({
                        message: <Locale stringKey="COMPANY_FORM__SNACKBAR__VALIDATION_ERROR__HEADER" />,
                        description: <Locale stringKey="COMPANY_FORM__SNACKBAR__VALIDATION_ERROR__BODY" />,
                    });
                }
            } else {
                snackbar.error({
                    message: <Locale stringKey="COMPANY_FORM__SNACKBAR__SAVE_ERROR__HEADER" />,
                    description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
                });
            }
        },
        [modal, setProcessError, snackbar]
    );

    const createCompany = useCallback(
        (values: CompanyServerType): Promise<CompanyType | void> => {
            setIsInProgress(true);

            return fetchCreateCompany(values)
                .then(handleCreateCompanyResult)
                .catch(handleCreateOrUpdateCompanyError)
                .finally(() => setIsInProgress(false));
        },
        [handleCreateOrUpdateCompanyError, handleCreateCompanyResult, setIsInProgress]
    );

    const updateCompany = useCallback(
        (companyId: number, values: CompanyServerType): Promise<CompanyType | void> => {
            setIsInProgress(true);

            return fetchUpdateCompany(companyId, values)
                .then(handleCreateCompanyResult)
                .catch(handleCreateOrUpdateCompanyError)
                .finally(() => setIsInProgress(false));
        },
        [handleCreateOrUpdateCompanyError, handleCreateCompanyResult, setIsInProgress]
    );

    return {
        isInProgress,
        processError,
        result,
        reset,
        createCompany,
        updateCompany,
        refreshCompany,
        fetchCompanyErrorCode,
    };
}

export function useLastCompany(brandId: number | null): UseHookType<LastCompanyType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<LastCompanyType>();

    useEffect(() => {
        if (brandId === null) {
            reset();
            return;
        }

        setIsInProgress(true);
        setProcessError(null);

        fetchLastCompany(brandId)
            .then((data) => setResult(data))
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult, reset, brandId]);

    return {isInProgress, processError, result, reset};
}

export function useRegularSocialsApi(): UseQueryResult<SocialApiRegularType> {
    return useQuery([`${rootApiUrl}/cp/social_media/`], () =>
        fetchAndDeserialize('/cp/social_media/', socialApiRegularSchema)
    );
}
