import {LangKeyType} from '../../../../provider/locale/translation/type';
import {LocalPackPointDistanceEnum, LocalPackSquareSizeEnum} from '../../../../service/local-pack/local-pack-type';
import {
    LocalPackRuleFormFieldsEnum,
    LocalPackRuleFormType,
    LocalPackRuleFrequencyEnum,
} from '../../../../service/local-pack/rules/local-pack-rule-list-item-type';

export const LOCAL_PACK_RULE_FORM_INITIAL_VALUES: LocalPackRuleFormType = {
    [LocalPackRuleFormFieldsEnum.Name]: '',
    [LocalPackRuleFormFieldsEnum.Brand]: null,
    [LocalPackRuleFormFieldsEnum.SearchPhrases]: [],
    [LocalPackRuleFormFieldsEnum.Services]: [],
    [LocalPackRuleFormFieldsEnum.Frequency]: LocalPackRuleFrequencyEnum.Monthly,
    [LocalPackRuleFormFieldsEnum.SquareSize]: LocalPackSquareSizeEnum.Small,
    [LocalPackRuleFormFieldsEnum.PointDistance]: LocalPackPointDistanceEnum.Low,
};

export function getLocalPackRuleFormLangKeys(mode: 'create' | 'edit'): {
    submitLangKey: LangKeyType;
    footerInfoLangKey: LangKeyType;
    cancelModalTitleLangKey: LangKeyType;
} {
    if (mode === 'create') {
        return {
            submitLangKey: 'BUTTON__CREATE',
            footerInfoLangKey: 'LOCAL_PACK__RULE_FORM__CREATE__FOOTER',
            cancelModalTitleLangKey: 'LOCAL_PACK__RULE_FORM__CANCEL_CREATE__TITLE',
        };
    }

    return {
        submitLangKey: 'BUTTON__EDIT',
        footerInfoLangKey: 'LOCAL_PACK__RULE_FORM__EDIT__FOOTER',
        cancelModalTitleLangKey: 'LOCAL_PACK__RULE_FORM__CANCEL_EDIT__TITLE',
    };
}
