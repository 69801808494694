import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {PublicationContext} from 'centrifuge';
import {camelizeKeys} from 'humps';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import {CalendarPanelType} from '../../page/main/posts/calendar/posts-calendar-type';
import {PostStepEnum} from '../../page/main/posts/post-form/post-form-type';
import {
    PostsFilterQueriesType,
    PostsFilterStateUrlQueryNameEnum,
} from '../../page/main/posts/posts-filter/posts-filter-type';
import {ShortCatalogType} from '../../provider/catalogs/catalogs-type';
import {useCentrifugeSubscription} from '../../provider/centrifuge/centrifuge-hook';
import {Locale} from '../../provider/locale/localization';
import {MainFilterContext} from '../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../provider/main-filter/main-filter-type';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useUser} from '../../provider/user/user-hook';
import {FormInstance} from '../../typings/antd';
import {isArraysEqual} from '../../util/array';
import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {getIsoYyyyMmDdString} from '../../util/date';
import {getEnumValue} from '../../util/enum';
import {ApiError, FormErrorType, getFormErrors} from '../../util/error';
import {useRefreshId} from '../../util/hook';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {IdNumberType, ProvidersIdsEnum} from '../../util/type';
import {useUrl} from '../../util/url-hook/url-hook';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    createPostApi,
    fetchAvailableFbPages,
    fetchAvailableInstagramPages,
    fetchAvailableVkGroups,
    fetchCompanyPreviewData,
    getLastPostsApi,
    getPostApi,
    getPostAvailableCatalogsApi,
    getPostCatalogsApi,
    getPostLinks,
    getPostsCalendar,
    getPostsListApi,
    getPostSubmitInformation,
    hardRemovePostApi,
    refreshPost,
    removePostApi,
    requestPostSuggest,
    updatePostApi,
} from './posts-api';
import {
    FORM_STEP_FIELDS,
    getCentrifugePostSuggestChannel,
    getInitialPostsFilter,
    isOmnichannelSuggestAction,
} from './posts-helper';
import {
    CalendarPostsRequestParametersType,
    CalendarPostsResponseType,
    CatalogLinksHookType,
    FetchPostType,
    LastPostsHookType,
    LastPostsType,
    PostAiSuggestHookType,
    PostAiSuggestItemType,
    PostFbPageType,
    PostFormFieldsEnum,
    PostFormType,
    PostInstagramPageType,
    PostLinksFilterType,
    PostListItemType,
    PostPreviewDetailsType,
    PostPreviewOptionsType,
    PostsAvailableCatalogsHookType,
    PostsFilterKeyEnum,
    PostsFilterStateType,
    PostsHookPropsType,
    PostsHookType,
    PostSourceEnum,
    PostStatusEnum,
    PostSubmitInformationParametersType,
    PostSubmitInformationType,
    PostVkGroupType,
    SetPostFilterType,
    UsePostFilterHookType,
} from './posts-types';

// use react query
export function usePosts(props: PostsHookPropsType): PostsHookType {
    const {filter, mainFilterKey} = props;
    const {isInProgress, setIsInProgress, result, setResult, processError, setProcessError, reset} =
        useApiHooks<Array<PostListItemType>>();
    const {refreshId, refresh} = useRefreshId();

    const paginationDependencies = useMemo(() => {
        return {
            mainFilterKey,
            ...filter,
            refreshId,
        };
    }, [filter, mainFilterKey, refreshId]);

    const cursorPagination = useCursorPagination({
        dependencies: paginationDependencies,
    });

    const {pageSize, cursor, onDataLoaded, onDataLoadFailed, refreshId: paginationRefreshId} = cursorPagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        getPostsListApi(filter, {pageSize, cursor}, mainFilterKey)
            .then((postsResponse) => {
                setResult(postsResponse.results);
                onDataLoaded(postsResponse);
                setProcessError(null);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
                onDataLoadFailed();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationRefreshId, setIsInProgress, setResult, onDataLoaded, setProcessError, onDataLoadFailed]);

    return {
        isInProgress,
        processError,
        result,
        reset,
        cursorPagination,
        refresh,
    };
}

export function usePost(postId: number): UseQueryResult<FetchPostType> {
    return useQuery(['post', postId], () => getPostApi(postId), {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function usePostsFilter(): UsePostFilterHookType {
    const {queries: postsFilterQueries, setQuery: setPostQuery} = useUrl<PostsFilterQueriesType>();
    const initialPostsFilter = getInitialPostsFilter(postsFilterQueries);
    const [filter, setFilter] = useState<PostsFilterStateType>(initialPostsFilter);
    const setFilterWrapped = useCallback<SetPostFilterType>(
        (filterPartial: Partial<PostsFilterStateType>) => {
            const resultFilter: PostsFilterStateType = {...filter, ...filterPartial};
            const [dateFrom, dateTo] = resultFilter[PostsFilterKeyEnum.timeRange];

            setFilter(resultFilter);
            const dateFromQueryValue = dateFrom !== null ? getIsoYyyyMmDdString(dateFrom) : '';
            const dateToQueryValue = dateTo !== null ? getIsoYyyyMmDdString(dateTo) : '';

            setPostQuery({
                [PostsFilterStateUrlQueryNameEnum.createdAtAfter]: dateFromQueryValue,
                [PostsFilterStateUrlQueryNameEnum.createdAtBefore]: dateToQueryValue,
                [PostsFilterStateUrlQueryNameEnum.statuses]: resultFilter[PostsFilterKeyEnum.statuses].join(','),
                [PostsFilterStateUrlQueryNameEnum.catalogs]: resultFilter[PostsFilterKeyEnum.sources].join(','),
            });
        },

        [filter, setFilter, setPostQuery]
    );

    return {filter, setFilter: setFilterWrapped};
}

export function usePostsAvailableCatalogs(): PostsAvailableCatalogsHookType {
    const [availableCatalogs, setAvailableCatalogs] = useState<Array<ShortCatalogType>>([]);
    const [processError, setProcessError] = useState<Error | null>(null);
    const [isInProgress, setIsInProgress] = useState<boolean>(false);

    const loadData = useCallback(async () => {
        try {
            setIsInProgress(true);
            const fetchedCatalogs = await getPostAvailableCatalogsApi();

            setAvailableCatalogs(fetchedCatalogs);
        } catch (error: unknown) {
            if (error instanceof Error) {
                setProcessError(error);
            }
        } finally {
            setIsInProgress(false);
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData, setIsInProgress, setProcessError]);

    const catalogsInfo = useMemo(() => {
        return {
            [PostSourceEnum.google]:
                availableCatalogs.find((catalog) => catalog.id === ProvidersIdsEnum.google) || null,
            [PostSourceEnum.yandex]:
                availableCatalogs.find((catalog) => catalog.id === ProvidersIdsEnum.yandex) || null,
            [PostSourceEnum.facebook]:
                availableCatalogs.find((catalog) => catalog.id === ProvidersIdsEnum.facebook) || null,
            [PostSourceEnum.vkontakte]:
                availableCatalogs.find((catalog) => catalog.id === ProvidersIdsEnum.vkontakte) || null,
            [PostSourceEnum.instagram]:
                availableCatalogs.find((catalog) => catalog.id === ProvidersIdsEnum.instagram) || null,
        };
    }, [availableCatalogs]);

    return {
        isInProgress,
        processError,
        availableCatalogs,
        catalogsInfo,
    };
}

export function usePostsCatalogs(): Omit<UseHookType<Array<ShortCatalogType>>, 'reset'> {
    const {isInProgress, setIsInProgress, result, setResult, processError, setProcessError} =
        useApiHooks<Array<ShortCatalogType>>();

    useEffect(() => {
        setIsInProgress(true);
        getPostCatalogsApi()
            .then((response) => setResult(response.results))
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setResult, setProcessError]);

    return {
        isInProgress,
        processError,
        result,
    };
}

export function usePostsCalendar(panel: CalendarPanelType): UseHookType<CalendarPostsResponseType> {
    const {isInProgress, setIsInProgress, result, setResult, processError, setProcessError, reset} =
        useApiHooks<CalendarPostsResponseType>();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {user} = useUser();

    useEffect(() => {
        setIsInProgress(true);

        const parameters: CalendarPostsRequestParametersType = {
            year: panel.date.year(),
            month: panel.mode === 'month' ? panel.date.month() + 1 : null,
        };

        getPostsCalendar(parameters, user?.isDemoUser ? '' : mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setResult, setProcessError, panel, user, mainFilterKey]);

    return {
        isInProgress,
        processError,
        result,
        reset,
    };
}

export function useLastPosts(): LastPostsHookType {
    const {isInProgress, setIsInProgress, result, setResult, processError, setProcessError, reset} =
        useApiHooks<Array<LastPostsType>>();

    const getLastPosts = useCallback(() => {
        setIsInProgress(true);

        const lastPostsPromise = getLastPostsApi();

        lastPostsPromise
            .then((postsResponse) => {
                setResult(postsResponse);
                setProcessError(null);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });

        return lastPostsPromise;
    }, [setIsInProgress, setResult, setProcessError]);

    return {
        getLastPosts,
        isInProgress,
        processError,
        result,
        reset,
    };
}

export function useCatalogPostLinks(postId: number): CatalogLinksHookType {
    const [filter, setFilter] = useState<PostLinksFilterType>({
        statuses: [],
        providers: [],
    });

    const changeFilter = useCallback(
        (newFilter: PostLinksFilterType) => {
            const isStatusesChanged = !isArraysEqual(newFilter.statuses, filter.statuses);
            const isProvidersChanged = !isArraysEqual(newFilter.providers, filter.providers);

            if (isStatusesChanged || isProvidersChanged) {
                setFilter(newFilter);
            }
        },
        [filter.providers, filter.statuses]
    );

    const pagination = usePagination({
        dependencies: filter,
        initialPageSize: 50,
        shouldSaveState: false,
    });

    const {page, pageSize, onDataLoaded} = pagination;

    const queryOptions = useMemo(
        () => ({
            page,
            pageSize,
            postId,
            ...filter,
        }),
        [page, pageSize, postId, filter]
    );

    const query = useQuery(['post-links', postId, queryOptions], () => getPostLinks(queryOptions));

    useEffect(() => {
        if (query.data) {
            onDataLoaded(query.data);
        }
    }, [onDataLoaded, query.data]);

    return {...query, pagination, setFilter: changeFilter};
}

export function usePostSubmitInformation(
    options: PostSubmitInformationParametersType
): UseQueryResult<PostSubmitInformationType> {
    return useQuery<PostSubmitInformationType>(
        ['post-submit-information', options.selectorId, options.providerCodes],
        () => getPostSubmitInformation(options),
        {
            enabled: options.providerCodes.length > 0,
        }
    );
}

export function usePostAiSuggests(selectorId: string): PostAiSuggestHookType {
    const [suggests, setSuggests] = useState<Array<PostAiSuggestItemType>>([]);
    const [isGeneratingSuggest, setIsGeneratingSuggest] = useState<boolean>(false);
    const {getSubscription} = useCentrifugeSubscription();

    const {user} = useUser();

    const channelId = useMemo(() => uuidv4(), []);
    const {snackbar} = useSnackbar();

    const handleSuggestError = useCallback(() => {
        setIsGeneratingSuggest(false);
        snackbar.error({
            message: <Locale stringKey="POSTS_SUGGESTS_ERROR__TITLE" />,
            description: <Locale stringKey="POSTS_SUGGESTS_ERROR__CONTENT" />,
        });
    }, [snackbar]);

    useEffect(() => {
        const timeoutId = isGeneratingSuggest ? setTimeout(handleSuggestError, 120_000) : null;

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [handleSuggestError, isGeneratingSuggest]);

    useEffect(() => {
        function onActionReceived({data}: PublicationContext) {
            const camelcaseData = camelizeKeys(data);

            if (isOmnichannelSuggestAction(camelcaseData)) {
                setIsGeneratingSuggest(false);
                setSuggests((previousSuggest) => [
                    ...previousSuggest,
                    ...camelcaseData.postSuggestions.map((text) => ({text})),
                ]);
            }
        }

        const subscription = getSubscription(
            getCentrifugePostSuggestChannel({
                userId: String(user?.id),
                channelId,
            })
        );

        subscription?.on('publication', onActionReceived);

        return () => {
            subscription?.removeListener('publication', onActionReceived);
        };
    }, [channelId, getSubscription, user]);

    const requestSuggest = useCallback(() => {
        setIsGeneratingSuggest(true);
        const requestId = uuidv4();

        requestPostSuggest({
            selectorId,
            channelId,
            requestId,
        }).catch(handleSuggestError);
    }, [channelId, handleSuggestError, selectorId]);

    return {
        suggests,
        requestSuggest,
        isGeneratingSuggest,
    };
}

export function usePostsFacebookPages(): UseQueryResult<Array<PostFbPageType>> {
    return useQuery(['posts-facebook-pages'], fetchAvailableFbPages);
}

export function usePostsVkGroups(): UseQueryResult<Array<PostVkGroupType>> {
    return useQuery(['posts-vk-groups'], fetchAvailableVkGroups);
}

export function usePostsInstagramPages(): UseQueryResult<Array<PostInstagramPageType>> {
    return useQuery(['posts-instagram-pages'], fetchAvailableInstagramPages);
}

export function useCreatePostMutation(status: PostStatusEnum): UseMutationResult<IdNumberType, unknown, PostFormType> {
    return useMutation({
        mutationFn: (body: PostFormType) => createPostApi(body, status),
    });
}

export function useUpdatePostMutation(status: PostStatusEnum): UseMutationResult<void, unknown, PostFormType> {
    return useMutation({
        mutationFn: (body: PostFormType) => updatePostApi(body, status),
    });
}

export function useRemovePostMutation(): UseMutationResult<void, unknown, number> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (postId: number) => removePostApi(postId),
        onSuccess: () => {
            snackbar.success(<Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_SUCCESS" />);
        },
        onError: () => {
            snackbar.error(<Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_ERROR" />);
        },
    });
}

export function useHardRemovePostMutation(): UseMutationResult<void, unknown, number> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (postId: number) => hardRemovePostApi(postId),
        onSuccess: () => {
            snackbar.success(<Locale stringKey="POSTS_FORM__MESSAGE__HARD_REMOVE__POST_SUCCESS" />);
        },
        onError: () => {
            snackbar.error(<Locale stringKey="POSTS_FORM__MESSAGE__HARD_REMOVE__POST_ERROR" />);
        },
    });
}

export function usePostFormSubmitErrors(
    formInstance: FormInstance<PostFormType>,
    currentStep: PostStepEnum
): {
    stepsWithErrors: Array<PostStepEnum>;
    handleSubmitError: (error: unknown) => void;
} {
    const [errors, setErrors] = useState<Array<FormErrorType>>([]);

    const stepsWithErrors = useMemo(
        () =>
            Object.values(PostStepEnum).filter((postStep) =>
                errors.some((error) => {
                    const errorFieldName = getEnumValue(PostFormFieldsEnum, String(error?.name));

                    return errorFieldName && FORM_STEP_FIELDS[postStep].includes(errorFieldName);
                })
            ),
        [errors]
    );

    const setCurrentStepErrors = useCallback(() => {
        setErrors((previousErrors) => {
            formInstance.setFields(previousErrors);
            return previousErrors.filter((error) => {
                const errorFieldName = getEnumValue(PostFormFieldsEnum, String(error?.name[0]));

                return errorFieldName && FORM_STEP_FIELDS[currentStep].includes(errorFieldName);
            });
        });
    }, [currentStep, formInstance]);

    useEffect(() => {
        setCurrentStepErrors();
    }, [setCurrentStepErrors]);

    const handleSubmitError = useCallback((error: unknown) => {
        if (error instanceof ApiError) {
            setErrors(getFormErrors(error.jsonData));
        }
    }, []);

    return {
        stepsWithErrors,
        handleSubmitError,
    };
}

export function useCatalogPreviewData(
    catalog: PostSourceEnum,
    options: PostPreviewOptionsType
): UseQueryResult<PostPreviewDetailsType> {
    return useQuery(['company-preview-data', catalog], () => fetchCompanyPreviewData(catalog, options), {
        retry: 0,
    });
}

export function usePostRefresh(): UseMutationResult<unknown, unknown, number> {
    return useMutation(['post-refresh'], (postId) => refreshPost(postId));
}
