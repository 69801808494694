import {fetchAndDeserialize, postAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {
    GoogleCompaniesBindingProgressOptionsType,
    googleCompaniesBindingProgressSchema,
    GoogleCompaniesBindingProgressType,
    GoogleCompaniesOptionsType,
    googleCompaniesSchema,
    GoogleCompaniesType,
    googleLocationsLoadingProgressSchema,
    GoogleLocationsLoadingProgressType,
    GoogleResolveCandidateOptionsType,
    googleResolveCandidateSchema,
    GoogleResolveCandidateType,
    googleStatisticsSchema,
    GoogleStatisticsType,
} from './google-type';

const bulkBindingUrl = '/cp/bulk_binding';

export const bulkBindingGoogleUrl = `${bulkBindingUrl}/google/`;

export const googleCompaniesUrl = `${bulkBindingUrl}/companies/google/`;

export function fetchGoogleCompanies({
    page,
    pageSize: count,
    search,
    brandsIds,
    companyId,
}: GoogleCompaniesOptionsType): Promise<GoogleCompaniesType> {
    const url = `${googleCompaniesUrl}?${objectToUrlParameters(
        serializeToURLParameters({
            page,
            count,
            ...(search.length > 0 ? {search} : null),
            ...(brandsIds.length > 0 ? {brandsIds: brandsIds.join(',')} : null),
            ...(companyId ? {companyId} : null),
        })
    )}`;

    return fetchAndDeserialize(url, googleCompaniesSchema);
}

export function getGoogleLocationsLoadingProgressUrl(processingKey: string | null): string {
    return `${bulkBindingGoogleUrl}${processingKey}/locations_loading/`;
}

export function fetchGoogleLocationsLoadingProgress(
    processingKey: string | null
): Promise<GoogleLocationsLoadingProgressType> {
    if (!processingKey) {
        throw new Error('Trying to fetch locations loading progress without a processing key');
    }

    return fetchAndDeserialize(
        getGoogleLocationsLoadingProgressUrl(processingKey),
        googleLocationsLoadingProgressSchema
    );
}

export function getGoogleCompaniesBindingProgressUrl({
    offset,
    limit,
    refreshId,
    processingKey,
    onlyNeedAction,
}: GoogleCompaniesBindingProgressOptionsType): string {
    return `${bulkBindingGoogleUrl}${processingKey}/?${objectToUrlParameters(
        serializeToURLParameters({
            offset,
            limit,
            refreshId,
            ...(onlyNeedAction ? {onlyNeedAction} : null),
        })
    )}`;
}

export function fetchGoogleCompaniesBindingProgress(
    options: GoogleCompaniesBindingProgressOptionsType
): Promise<GoogleCompaniesBindingProgressType> {
    if (!options.processingKey) {
        throw new Error('Trying to fetch companies binding progress without a processing key');
    }

    return fetchAndDeserialize(getGoogleCompaniesBindingProgressUrl(options), googleCompaniesBindingProgressSchema);
}

export const googleCompaniesResolveUrl = '/cp/candidates/google/resolve_candidates/';

export function postGoogleResolveCandidate({
    source,
    originId,
    companyId,
    tokenId,
}: GoogleResolveCandidateOptionsType): Promise<GoogleResolveCandidateType> {
    if (!source) {
        throw new Error('Trying to resolve candidate without a source');
    }

    return postAndDeserialize(googleCompaniesResolveUrl, googleResolveCandidateSchema, {
        source,
        originId,
        companyId,
        ...(tokenId ? {tokenId} : null),
    });
}

export function getGoogleStatisticsUrl(processingKey: string | null): string {
    return `${bulkBindingGoogleUrl}${processingKey}/complete/`;
}

export function postGoogleStatistics(processingKey: string | null): Promise<GoogleStatisticsType> {
    if (!processingKey) {
        throw new Error('Trying to fetch companies binding progress without a processing key');
    }

    return postAndDeserialize(getGoogleStatisticsUrl(processingKey), googleStatisticsSchema);
}
