import {z as r} from 'zod';

import {DatePeriodEnum} from '../../../util/date';
import {ProvidersEnum, ProvidersIdsEnum} from '../../../util/type';

export const ratingDynamicsChartsDataSchema = r.object({
    labels: r.array(r.string()),
    ratingDynamics: r.array(
        r.object({
            catalogId: r.nativeEnum(ProvidersIdsEnum),
            values: r.array(r.number()),
        })
    ),
    questionnaireTooltips: r.array(
        r.object({
            date: r.string(),
            catalogId: r.number(),
            sourceType: r.union([r.literal('mailing_list'), r.literal('qr_codes')]),
            title: r.string(),
        })
    ),
});

export type RatingDynamicsChartsDataType = r.infer<typeof ratingDynamicsChartsDataSchema>;

export enum YandexCompanyStatusEnum {
    Actualized = 'actualized',
    NotActualized = 'not_actualized',
    NeedActualize = 'need_actualize',
}

const ratingDynamicsCompaniesStatsItemTypeSchema = r.object({
    companyId: r.string(),
    name: r.string(),
    address: r.string(),
    code: r.string(),
    ratings: r.array(
        r.object({
            catalog: r.nativeEnum(ProvidersIdsEnum),
            base: r.number(),
            target: r.number(),
            delta: r.number(),
            baseActualizationStatus: r.nativeEnum(YandexCompanyStatusEnum).optional(),
            targetActualizationStatus: r.nativeEnum(YandexCompanyStatusEnum).optional(),
        })
    ),
});

export type RatingDynamicsCompaniesStatsItemDataType = r.infer<typeof ratingDynamicsCompaniesStatsItemTypeSchema>;

export const ratingDynamicsCompaniesStatsDataType = r.object({
    results: r.array(ratingDynamicsCompaniesStatsItemTypeSchema),
    count: r.number(),
});

export type RatingDynamicsCompaniesStatsDataType = r.infer<typeof ratingDynamicsCompaniesStatsDataType>;

export enum RatingsDynamicsFilterQueryParametersEnum {
    BaseDate = 'base_date',
    TargetDate = 'target_date',
    DatePreset = 'date_preset',
    Address = 'address',
    Name = 'name',
    Code = 'code',
    GoogleFilter = ProvidersEnum.google,
    YandexFilter = ProvidersEnum.yandex,
    DoubleGisFilter = ProvidersEnum.doubleGis,
    YandexMarksFilter = `${ProvidersEnum.yandex}_marks`,
}

export type RatingsDynamicsQueryFilterType = Readonly<Record<RatingsDynamicsFilterQueryParametersEnum, string>>;

export enum RatingsDynamicsCatalogFilterValuesEnum {
    RatingDrop = 'rating_drop',
    RatingIncreased = 'rating_increased',
    RatingUnchanged = 'rating_unchanged',
}

export enum RatingsDynamicsYandexBaseStatusValuesEnum {
    Actualized = 'base_status_actualized',
    NotActualized = 'base_status_not_actualized',
    NeedActualize = 'base_status_need_actualize',
}

export enum RatingsDynamicsYandexTargetStatusValuesEnum {
    Actualized = 'target_status_actualized',
    NotActualized = 'target_status_not_actualized',
    NeedActualize = 'target_status_need_actualize',
}

export enum RatingsDynamicsCatalogSortingValuesEnum {
    BaseRatingAsc = 'base_rating_asc',
    BaseRatingDesc = 'base_rating_desc',
    TargetRatingAsc = 'target_rating_asc',
    TargetRatingDesc = 'target_rating_desc',
}

export type RatingsDynamicsFilterType = {
    [RatingsDynamicsFilterQueryParametersEnum.BaseDate]: Date;
    [RatingsDynamicsFilterQueryParametersEnum.TargetDate]: Date;
    [RatingsDynamicsFilterQueryParametersEnum.DatePreset]: DatePeriodEnum;
    [RatingsDynamicsFilterQueryParametersEnum.Address]: string;
    [RatingsDynamicsFilterQueryParametersEnum.Name]: string;
    [RatingsDynamicsFilterQueryParametersEnum.Code]: string;
    [RatingsDynamicsFilterQueryParametersEnum.GoogleFilter]: Array<
        RatingsDynamicsCatalogFilterValuesEnum | RatingsDynamicsCatalogSortingValuesEnum
    >;
    [RatingsDynamicsFilterQueryParametersEnum.YandexFilter]: Array<
        RatingsDynamicsCatalogFilterValuesEnum | RatingsDynamicsCatalogSortingValuesEnum
    >;
    [RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter]: Array<
        RatingsDynamicsCatalogFilterValuesEnum | RatingsDynamicsCatalogSortingValuesEnum
    >;
    [RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter]: Array<
        RatingsDynamicsYandexBaseStatusValuesEnum | RatingsDynamicsYandexTargetStatusValuesEnum
    >;
};
