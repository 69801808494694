import {
    MainFilterSelectedValuesApiType,
    MainFilterSelectedValuesIdsKeyEnum,
} from '../../service/main-filter/main-filter-api-type';
import {caseForActive, caseForOpen, getSuggestionIds} from '../../service/main-filter/main-filter-helper';
import {MainFilterFieldNameEnum, MainFiltersStateType} from '../../service/main-filter/main-filter-type';

export function serializeStateFormCompaniesCountRequest(state: MainFiltersStateType): MainFilterSelectedValuesApiType {
    const suggestIdFromSelectedValues = caseForOpen(state[MainFilterFieldNameEnum.CLOSED]);
    const suggestIdFromActiveValues = caseForActive(state[MainFilterFieldNameEnum.ACTIVE]);

    return {
        [MainFilterSelectedValuesIdsKeyEnum.BRANDS]: getSuggestionIds(state[MainFilterFieldNameEnum.BRANDS]),
        [MainFilterSelectedValuesIdsKeyEnum.CITIES]: getSuggestionIds(state[MainFilterFieldNameEnum.CITIES]),
        [MainFilterSelectedValuesIdsKeyEnum.CODES]: getSuggestionIds(state[MainFilterFieldNameEnum.CODES]),
        [MainFilterSelectedValuesIdsKeyEnum.COUNTRIES]: getSuggestionIds(state[MainFilterFieldNameEnum.COUNTRIES]),
        [MainFilterSelectedValuesIdsKeyEnum.GROUPS]: getSuggestionIds(state[MainFilterFieldNameEnum.GROUPS]),
        [MainFilterSelectedValuesIdsKeyEnum.NAMES]: getSuggestionIds(state[MainFilterFieldNameEnum.NAMES]),
        [MainFilterSelectedValuesIdsKeyEnum.REGIONS]: getSuggestionIds(state[MainFilterFieldNameEnum.REGIONS]),
        [MainFilterSelectedValuesIdsKeyEnum.STREETS]: getSuggestionIds(state[MainFilterFieldNameEnum.STREETS]),
        ...suggestIdFromSelectedValues,
        ...suggestIdFromActiveValues,
    };
}
