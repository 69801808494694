import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useCallback} from 'react';

import {AccountCard} from '../../../../component/account/account-card';
import {AddAccountCard} from '../../../../component/account/add-account-card';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../layout/spinner/spinner';
import {CatalogType} from '../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../provider/locale/localization';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {removeVkAccountApi} from '../../../../service/accounts/accounts-api';
import {getVkAccountInfo} from '../../../../service/accounts/accounts-helper';
import {useVkAccounts} from '../../../../service/accounts/accounts-hook';
import {VkAccountType} from '../../../../service/accounts/accounts-type';
import * as accountsLinkingStyles from '../accounts-linking.scss';

type PropsType = {
    catalogInfo: CatalogType;
};

export function AccountLinkingVk(props: PropsType): JSX.Element {
    const {catalogInfo} = props;

    const {
        result,
        reset,
        processError: accountsProcessError,
        linkingSrc,
        isInProgress: isAccountInProgress,
    } = useVkAccounts();
    const {snackbar} = useSnackbar();

    const onRemoveAccount = useCallback(
        (accountId: number) => {
            removeVkAccountApi(accountId)
                .then(() => {
                    snackbar.success(<Locale stringKey="ACCOUNTS__REMOVE__SUCCESS" />);
                    reset();
                })
                .catch(() => {
                    snackbar.error(<Locale stringKey="ACCOUNTS__REMOVE__ERROR" />);
                });
        },
        [reset, snackbar]
    );

    if (isAccountInProgress || !result) {
        return <Spinner isShow position="absolute" />;
    }

    if (accountsProcessError) {
        return <AlertFallback />;
    }

    return (
        <div className={accountsLinkingStyles.accounts_linking_content__wrapper}>
            {result.map((vkAccount: VkAccountType) => {
                return (
                    <AccountCard
                        button={
                            <Button onClick={() => onRemoveAccount(vkAccount.id)}>
                                <Locale stringKey="ACCOUNTS__REMOVE_ACCOUNT" />
                            </Button>
                        }
                        catalogName={catalogInfo.name}
                        iconSrc={catalogInfo.logo}
                        info={getVkAccountInfo(vkAccount)}
                        key={vkAccount.id}
                    />
                );
            })}

            <AddAccountCard
                buttonContent={<FontAwesomeIcon icon={faPlus} />}
                catalogName={catalogInfo.name}
                linkingSrc={linkingSrc}
                onClose={reset}
            />
        </div>
    );
}
