import {faArrowLeftToLine, faArrowRightToLine} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useContext, useEffect} from 'react';

import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {useSystem} from '../../../provider/system/system-hook';
import {UiContext} from '../../../provider/ui/ui-context';
import {classNames} from '../../../util/css';
import {Header} from '../common/header/header';

import * as styles from './desktop-header.scss';

type PropsType = {
    isTopFilterHidden: boolean;
};

export function DesktopHeader(props: PropsType): JSX.Element {
    const {isTopFilterHidden} = props;

    const {isLeftSideOpen, setIsLeftSideOpen} = useContext(UiContext);
    const {canFoldDesktopMenu} = useDomainConfig();
    const {screen} = useSystem();
    const {isDesktop} = screen;

    useEffect(() => {
        if (isDesktop && !canFoldDesktopMenu) {
            setIsLeftSideOpen(false);
        }
    }, [canFoldDesktopMenu, isDesktop, setIsLeftSideOpen]);

    return (
        <Header
            isFilterHidden={isTopFilterHidden}
            isShortLogo={!isLeftSideOpen}
            isToggleButtonHidden={!canFoldDesktopMenu}
            logoClassName={classNames({[styles.logo__left_side_hidden]: !isLeftSideOpen})}
            menuIcon={<FontAwesomeIcon icon={isLeftSideOpen ? faArrowLeftToLine : faArrowRightToLine} />}
            onMenuToggle={() => setIsLeftSideOpen(!isLeftSideOpen)}
        />
    );
}
