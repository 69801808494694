import {LatLngBoundsLiteral} from 'leaflet';
import {useMemo} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import {MapContainerProps} from 'react-leaflet/types/MapContainer';

import {classNames} from '../../util/css';

import {
    EUROPE_BOUNDS,
    LEAFLET_TILE_SIZE,
    LEAFLET_ZOOM_CONFIG,
    LEAFLET_ZOOM_OFFSET,
    TILE_LAYER_CONFIG,
} from './leaflet-map-const';
import * as styles from './leaflet-map.scss';

type PropsType = Omit<MapContainerProps, 'center' | 'bounds'> &
    (
        | {
              center: MapContainerProps['center'];
              bounds?: never;
          }
        | {
              center?: never;
              bounds: LatLngBoundsLiteral;
          }
    );

export function LeafletMap(props: PropsType): JSX.Element {
    const {children, className, center, bounds, ...restProps} = props;

    const mapPosition: {
        center?: MapContainerProps['center'];
        bounds?: LatLngBoundsLiteral;
    } = useMemo(() => {
        if (center) {
            return {
                center,
            };
        }

        switch (true) {
            case bounds && bounds?.length === 1: {
                return {
                    center: bounds?.[0],
                };
            }
            case bounds && bounds?.length >= 2: {
                return {
                    bounds,
                };
            }
            default: {
                return {
                    bounds: EUROPE_BOUNDS,
                };
            }
        }
    }, [bounds, center]);

    return (
        <MapContainer
            bounds={mapPosition.bounds}
            center={mapPosition.center}
            className={classNames(styles.LeafletMap, className)}
            dragging
            maxZoom={LEAFLET_ZOOM_CONFIG.maxMapZoom}
            minZoom={LEAFLET_ZOOM_CONFIG.minMapZoom}
            scrollWheelZoom={false}
            tileSize={LEAFLET_TILE_SIZE}
            zoom={LEAFLET_ZOOM_CONFIG.defaultMapZoom}
            zoomOffset={LEAFLET_ZOOM_OFFSET}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <TileLayer {...TILE_LAYER_CONFIG} />
            {children}
        </MapContainer>
    );
}
