import {Modal} from 'antd';
import {PropsWithChildren} from 'react';

import {ReactComponent as PhoneFrame} from './images/phone-frame.svg';
import * as styles from './post-preview-mobile-overlay.scss';

type PropsType = {
    onClose: () => void;
};

export function PostPreviewMobileOverlay(props: PropsWithChildren<PropsType>): JSX.Element {
    const {children, onClose} = props;

    return (
        <Modal
            centered
            className={styles.PostPreviewMobileOverlay}
            closable
            footer={null}
            onCancel={onClose}
            open
            width={800}
        >
            <div className={styles.PostPreviewMobileOverlay_phoneContainer}>
                <div className={styles.PostPreviewMobileOverlay_phoneContent}>{children}</div>
                <PhoneFrame className={styles.PostPreviewMobileOverlay_phoneFrame} />
            </div>
        </Modal>
    );
}
