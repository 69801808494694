import {MainFilterSelectedValuesIdsKeyEnum} from '../../service/main-filter/main-filter-api-type';
import {MainFilterFieldNameEnum} from '../../service/main-filter/main-filter-type';
import {noop} from '../../util/function';

import {MainFilterContextType} from './main-filter-type';

export const defaultMainFilterContext: MainFilterContextType = {
    update: noop,
    state: {
        [MainFilterFieldNameEnum.NAMES]: [],
        [MainFilterFieldNameEnum.COUNTRIES]: [],
        [MainFilterFieldNameEnum.CODES]: [],
        [MainFilterFieldNameEnum.CITIES]: [],
        [MainFilterFieldNameEnum.STREETS]: [],
        [MainFilterFieldNameEnum.REGIONS]: [],
        [MainFilterFieldNameEnum.BRANDS]: [],
        [MainFilterFieldNameEnum.GROUPS]: [],
        [MainFilterFieldNameEnum.CLOSED]: [],
        [MainFilterFieldNameEnum.ACTIVE]: [],
    },
    filteredCount: 0,
    isLoadingInitialFilter: false,
    mainFilterKey: '',
};

export const filterActualizingIntervalMs = 12e4;

export const defaultEmptyFilterState = {
    [MainFilterSelectedValuesIdsKeyEnum.BRANDS]: [],
    [MainFilterSelectedValuesIdsKeyEnum.CITIES]: [],
    [MainFilterSelectedValuesIdsKeyEnum.STREETS]: [],
    [MainFilterSelectedValuesIdsKeyEnum.CODES]: [],
    [MainFilterSelectedValuesIdsKeyEnum.NAMES]: [],
    [MainFilterSelectedValuesIdsKeyEnum.COUNTRIES]: [],
    [MainFilterSelectedValuesIdsKeyEnum.REGIONS]: [],
    [MainFilterSelectedValuesIdsKeyEnum.GROUPS]: [],
};
