import {PermissionNameEnum} from '../../../../service/company/company-type';
import {CompanyStats} from '../company-stats/company-stats';
import {DataCompleteness} from '../data-completeness/data-completeness';

import * as styles from './company-info-completeness.scss';

type PropsType = {
    companyId: number;
    permissions: Array<PermissionNameEnum>;
};

export function CompanyInfoCompleteness(props: PropsType): JSX.Element {
    const {companyId, permissions} = props;

    return (
        <div className={styles.company_info_completeness__container}>
            <CompanyStats companyId={companyId} />
            <DataCompleteness companyId={companyId} permissions={permissions} />
        </div>
    );
}
