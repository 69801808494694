import {Text} from '../../../../../component/text/text';
import {toTrimmedString} from '../../../../../util/string';

import * as styles from './local-pack-name-address-cell.scss';

type PropsType = {
    name: string;
    address: {
        country: string;
        city: string | null;
        street: string;
        housenumber: string;
    };
};

export function LocalPackNameAddressCell(props: PropsType): JSX.Element {
    const {name, address} = props;

    const addressText: string = [address.country, address.city, address.street, address.housenumber]
        .map(toTrimmedString)
        .filter(Boolean)
        .join(', ');

    return (
        <div className={styles.LocalPackNameAddressCell}>
            <Text block>{name}</Text>
            <Text block lighter>
                {addressText}
            </Text>
        </div>
    );
}
