import {faPenToSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Button, Skeleton, Space, Typography} from 'antd';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {Text} from '../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {useUser} from '../../../../../../service/user-management/user-management-user';
import {classNames} from '../../../../../../util/css';

import * as styles from './user-details.scss';

export function UserDetails(): JSX.Element | null {
    const {data: user} = useUser();

    if (!user) {
        return (
            <>
                <Skeleton.Avatar active size={48} />

                <Skeleton
                    active
                    className={classNames(styles.UserDetails_skeleton, styles.UserDetails_wrapper)}
                    paragraph={{rows: 1, width: '30%'}}
                    title={{width: '20%'}}
                />
            </>
        );
    }

    return (
        <>
            <Avatar
                className={classNames(styles.UserDetails_avatar, {
                    [styles.UserDetails_avatar__owner]: user?.role.isOwner,
                })}
                size={48}
            >
                {user.fullName ? user.fullName[0] : user.email[0]}
            </Avatar>

            <div className={styles.UserDetails_wrapper}>
                <Space size={6}>
                    <Typography.Text className={styles.UserDetails_title} ellipsis title={user.fullName || user.email}>
                        <Text bolder>{user.fullName || user.email}</Text>
                    </Typography.Text>

                    <NavigationLink
                        className={styles.UserDetails_editLink}
                        to={generatePath(appRoute.editUser.path, {userId: user.pk})}
                    >
                        <Button
                            className={styles.UserDetails_editButton}
                            icon={<FontAwesomeIcon icon={faPenToSquare} />}
                            size="small"
                            type="link"
                        />
                    </NavigationLink>
                </Space>

                <Space size={6}>
                    {user.fullName && (
                        <Typography.Text className={styles.UserDetails_email} ellipsis title={user.email}>
                            <Text secondary>{user.email}</Text>
                        </Typography.Text>
                    )}

                    <Text secondary>•</Text>

                    <Text secondary>{user.role.name}</Text>

                    <Text className={styles.UserDetails_you} lightest stringKey="USERS__HEADER__YOU" />
                </Space>
            </div>
        </>
    );
}
