import {useQuery} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {fakesUrl} from './fakes-const';

const fakesCompanyInfoSchema = r.object({
    name: r.string(),
    address: r.string(),
    id: r.number(),
    logoUrl: r.string().nullable(),
    phone: r.string().nullable(),
    category: r.string().nullable(),
    url: r.string().nullable(),
});

export type FakesCompanyInfoType = r.infer<typeof fakesCompanyInfoSchema>;

const fakesCompaniesInfoSchema = r.record(r.array(fakesCompanyInfoSchema));

type FakesCompaniesInfoType = r.infer<typeof fakesCompaniesInfoSchema>;

type FakesCompaniesInfoOptionsType = {
    fakeIds: Array<number>;
};

function getFakesCompaniesInfoUrl(options: FakesCompaniesInfoOptionsType): string {
    return `${fakesUrl}/companies_info/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

function fetchFakesCompaniesInfo(options: FakesCompaniesInfoOptionsType): Promise<FakesCompaniesInfoType> {
    return fetchAndDeserialize(getFakesCompaniesInfoUrl(options), fakesCompaniesInfoSchema);
}

export function useFakesCompaniesInfo(fakeIds: Array<number> = []): FakesCompaniesInfoType | undefined {
    const {data} = useQuery([getFakesCompaniesInfoUrl({fakeIds})], () => fetchFakesCompaniesInfo({fakeIds}), {
        enabled: Boolean(fakeIds?.length),
    });

    return data;
}
