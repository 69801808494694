import {useQuery} from '@tanstack/react-query';
import {useEffect, useMemo, useState} from 'react';

import {
    GoogleAccountSyncUrlParameterEnum,
    GoogleAccountSyncUrlType,
} from '../../../page/main/account-sync/google/google-account-sync-type';
import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {useUrlQueryNumber} from '../../../util/url-hook/url-hook';
import {GOOGLE_PAGE_SIZE} from '../google-const';

import {fetchGoogleAccounts, googleAccountsUrl} from './google-accounts-api';
import {
    GoogleAccountsHookOptionsType,
    GoogleAccountsHookReturnType,
    GooglePersonalAccountsHookReturnType,
} from './google-accounts-type';

function usePersonalGoogleAccounts(search?: string): GooglePersonalAccountsHookReturnType {
    const brandId = useUrlQueryNumber<GoogleAccountSyncUrlType>(GoogleAccountSyncUrlParameterEnum.BrandId);

    const [total, setTotal] = useState<number>(0);

    const {
        data: personalAccounts,
        refetch,
        isInitialLoading,
    } = useQuery([googleAccountsUrl, search], () =>
        fetchGoogleAccounts({
            search,
            ...(brandId ? {brandId} : {}),
        })
    );

    useEffect(() => {
        if (personalAccounts && !search) {
            setTotal(personalAccounts.accounts.length);
        }
    }, [personalAccounts, search]);

    return {personalAccounts: {...personalAccounts, refetch, isInitialLoading, total}};
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useGoogleAccounts({
    personalAccountId,
    accountType,
    search,
}: GoogleAccountsHookOptionsType): GoogleAccountsHookReturnType {
    const {personalAccounts} = usePersonalGoogleAccounts(search);
    const brandId = useUrlQueryNumber<GoogleAccountSyncUrlType>(GoogleAccountSyncUrlParameterEnum.BrandId);

    const selectedPersonalAccount = useMemo(
        () => personalAccounts?.accounts?.find(({id}) => id === personalAccountId),
        [personalAccountId, personalAccounts?.accounts]
    );

    const selectedPersonalHasSameType = accountType ? selectedPersonalAccount?.accountType === accountType : true;

    const dependencies = useMemo(() => {
        return {accountType, personalAccountId};
    }, [accountType, personalAccountId]);

    const pagination = usePagination({
        dependencies,
        shouldSaveState: false,
        initialPageSize: GOOGLE_PAGE_SIZE,
    });

    const {page, pageSize, onDataLoaded} = pagination;

    const {data: accounts, isInitialLoading} = useQuery(
        [googleAccountsUrl, page, pageSize, accountType, personalAccountId],
        () =>
            fetchGoogleAccounts({
                accountType,
                parentId: personalAccountId ?? 0,
                limit: page === 1 ? pageSize - 1 : pageSize,
                offset: page > 1 ? pageSize * (page - 1) - 1 : 0, // additional -1 because one personal account is always shown on the first page
                ...(brandId ? {brandId} : {}),
            }),
        {enabled: Boolean(personalAccountId)}
    );

    useEffect(() => {
        if (accounts) {
            onDataLoaded({count: selectedPersonalHasSameType ? accounts.total + 1 : accounts.total});
        }
    }, [accounts, onDataLoaded, selectedPersonalHasSameType]);

    const accountsWithPersonalAccount =
        selectedPersonalAccount && page === 1 && selectedPersonalHasSameType
            ? [selectedPersonalAccount, ...(accounts?.accounts ?? [])]
            : accounts?.accounts;

    return {
        selectedPersonalAccount,
        personalAccounts,
        accounts: {...accounts, pagination, isInitialLoading, accounts: accountsWithPersonalAccount ?? []},
    };
}
