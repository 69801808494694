import {appRoute} from '../../../../../app-route';
import {BreadCrumbItemType} from '../../../../../layout/bread-crumbs/bread-crumbs-type';
import {CompanyType} from '../../../../../service/company-v2/company-type';
import {WorkingDaysType} from '../../../../../service/company-v2/types/company-working-time-type';

export const BREADCRUMBS_ITEMS: Array<BreadCrumbItemType> = [
    {
        path: appRoute.myCompanies.path,
        titleLangKey: 'CATEGORY_NAME__MY_COMPANIES',
    },
    {
        path: appRoute.createCompany.path,
        titleLangKey: 'COMPANY_FORM__CREATING_BREADCRUMB',
    },
];

export const initialCompanyValues = {
    extraNames: [],
    searchNames: [],
    code: null,
    address: {
        lang: null,
        countryId: null,
        countryName: '',
        countryCode: null,
        regionId: null,
        regionName: '',
        cityId: null,
        cityName: null,
        street: '',
        houseNumber: null,
        postalCode: null,
        description: '',
        lat: 0,
        lon: 0,
    },
    rocketdataCategories: [],
    // eslint-disable-next-line no-undefined
    phones: undefined,
    extraPhones: [],
    email: '',
    extraEmails: [],
    // eslint-disable-next-line no-undefined
    websites: undefined, // ant can't set new initialValues for already populated fields (needed for first item in list) and server returns [] when editing
    extraWebsites: [],
    // eslint-disable-next-line no-undefined
    socialNetworks: undefined,
    extraSocialNetworks: [],
    temporaryClosed: false,
    workingHours: Object.fromEntries([1, 2, 3, 4, 5, 6, 7].map((day) => [`day${day}`, null])) as WorkingDaysType,
    extraWorkingHours: [],
    specialHours: [],
    payments: [],
    descriptions: {
        short: null,
        long: null,
    },
    extraDescriptions: [],
    extraLogo: [],
    extraCover: [],
    gallery: [],
    extraGallery: [],
    attributes: {
        attributesGoogle: [],
        attributesYandex: [],
        attributesDoubleGis: [],
    },
    // ts-prune-ignore-next https://github.com/nadeesha/ts-prune/issues/218
} satisfies Partial<CompanyType>;
