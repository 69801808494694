export const enPostsTranslations = {
    POSTS__HEADER: 'Post list',
    POSTS__CREATE_POST__HEADER: 'Create post',
    POSTS__EDIT_POST__HEADER: 'Update post',
    POSTS__VIEW_POST__HEADER: 'View post',
    POSTS__ACCOUNTS__HEADER: 'Accounts linking',
    POSTS__CREATE_POST: 'Create post',
    POSTS__ACCOUNTS_SETTINGS: 'Accounts settings',
    POSTS__TABLE_PAGE: 'Table',
    POSTS__TABLE__TITLE: 'Title',
    POSTS__TABLE__CATALOGS: 'Catalogs',
    POSTS__TABLE__DATE: 'Date',
    POSTS__TABLE__STATUS: 'Status',
    POSTS__TABLE__ACTIONS: 'Action',
    POSTS__TABLE__AUTO_REFRESH_TAG: 'Auto-updating publication',

    POSTS__CALENDAR_PAGE: 'Calendar',
    POSTS__CALENDAR_HEADER: 'Posts calendar',

    POSTS__STATUS__DRAFT: 'Draft',
    POSTS__STATUS__PLANNED: 'Planned',
    POSTS__STATUS__SENT: 'Sent',
    POSTS__STATUS__DELETED: 'Deleted',
    POSTS__STATUS__ACTIVE: 'Active',
    POSTS__STATUS__IN_PROGRESS: 'Sending',
    POSTS__STATUS__CREATED: 'Created',
    POSTS__STATUS__UPDATED: 'Updated',
    POSTS__STATUS__ERROR: 'Error',
    POSTS__STATUS__ON_MODERATION: 'On moderation',
    POSTS__STATUS__REJECTED: 'Rejected',

    POSTS__CALENDAR_BADGE__PUBLISH_PLANNED: '- Planned to publish',
    POSTS__CALENDAR_BADGE__REFRESH_PLANNED: '- Auto-updating',
    POSTS__CALENDAR_BADGE__SENT: '- Sent post',
    POSTS__CALENDAR_BADGE__DELETE_PLANNED: '- Planned to delete',

    POSTS__CALENDAR_BADGE__YEAR_CARD__PUBLISH_PLANNED: 'To publish - {count}',
    POSTS__CALENDAR_BADGE__YEAR_CARD__REFRESH_PLANNED: 'Auto-updating - {count}',
    POSTS__CALENDAR_BADGE__YEAR_CARD__SENT: 'Sent - {count}',
    POSTS__CALENDAR_BADGE__YEAR_CARD__DELETE_PLANNED: 'To delete - {count}',

    POSTS__FILTER_FORM__INPUT__STATUS__LABEL: 'Statuses',
    POSTS__FILTER_FORM__INPUT__STATUS__PLACEHOLDER: 'Select statuses',
    POSTS__FILTER_FORM__INPUT__TIME_PERIOD__LABEL: 'Time period',
    POSTS__FILTER_FORM__TITLE: 'Filters',
    POSTS__FILTER_FORM__INPUT__SOURCE__LABEL: 'Sources',
    POSTS__FILTER_FORM__INPUT__SOURCE__PLACEHOLDER: 'Select sources',
    POSTS_ACTIVE__FILTER__EMPTY_TEXT: 'Filters are not applied',
    POSTS_ACTIVE__FILTER__LABEL: 'Filters',

    POSTS_FORM__LOCATIONS__LABEL: 'Locations',
    POSTS_FORM__TARGET_STEP: 'Send Options',
    POSTS_FORM__COMMON_CONTENT_STEP: 'General content settings',
    POSTS_FORM__ADAPTIVE_CONTENT_STEP: 'Responsive content customization',
    POSTS_FORM__POST_CONFIRMATION_STEP: 'Submitting a publication',
    POSTS_FORM__LOCATIONS__INFORMATION: 'When selecting {catalogName} pages, locations are optional',
    POSTS_FORM__NAME__LABEL: 'Post name',
    POSTS_FORM__NAME__PLACEHOLDER: 'name',
    POSTS_FORM__SOURCES__LABEL: 'Sources',
    POSTS_FORM__BRANDS__LABEL: 'Brands',
    POSTS_FORM__VK_GROUPS__LABEL: 'VK groups',
    POSTS_FORM__SOURCES__PLACEHOLDER: 'Choose services for publication',
    POSTS_FORM__BRANDS__PLACEHOLDER: 'Select brands',
    POSTS_FORM__VK_GROUPS__PLACEHOLDER: 'Select VK groups for post',
    POSTS_FORM__IMAGE: 'Image',
    POSTS_FORM__IMAGES: 'Images',
    POSTS_FORM__VIDEO: 'Video',
    POSTS_FORM__MEDIA: 'Media files',
    POSTS_FORM__MEDIA__TOOLTIP__POPOVER__BODY: 'Supports uploading up to 4 images or 1 video',
    POSTS_FORM__TYPE__LABEL: 'Post type',
    POSTS_FORM__FB_PAGES__LABEL: 'Facebook pages',
    POSTS_FORM__FB_PAGES__PLACEHOLDER: 'Select Facebook pages to post',

    POSTS_FORM__INSTAGRAM_PAGES__LABEL: 'Instagram pages',
    POSTS_FORM__INSTAGRAM_PAGES__PLACEHOLDER: 'Select Instagram pages to post',

    POSTS_FORM__TYPE_VALUE__NEWS: 'News',
    POSTS_FORM__TYPE_VALUE__EVENT: 'Event',
    POSTS_FORM__TYPE_VALUE__OFFER: 'Offer',

    POSTS_FORM__BUTTON_TYPE__VALUE__BOOK: 'Book',
    POSTS_FORM__BUTTON_TYPE__VALUE__CALL: 'Call now',
    POSTS_FORM__BUTTON_TYPE__VALUE__LEARN_MORE: 'Learn more',
    POSTS_FORM__BUTTON_TYPE__VALUE__ORDER: 'Order online',
    POSTS_FORM__BUTTON_TYPE__VALUE__SHOP: 'Buy',
    POSTS_FORM__BUTTON_TYPE__VALUE__SIGN_UP: 'Sign up',
    POSTS_FORM__BUTTON_TYPE__VALUE__UNSPECIFIED: 'Unspecified',

    POSTS_FORM__TYPE__EVENT_START__LABEL: 'Event start',
    POSTS_FORM__TYPE__EVENT_END__LABEL: 'Event end',

    POSTS_FORM__TYPE__EVENT_DATE_START__EMPTY: 'Event start date and time is required',
    POSTS_FORM__TYPE__EVENT_DATE_END__EMPTY: 'Select the event end time',
    POSTS_FORM__TYPE__OFFER__SELECT_AT_LEAST_SOMETHING: 'At least one of the promotion fields must be filled',
    POSTS_VALIDATION_ERROR__REMOVE_DATE_AFTER_CREATION: 'Choose date and time later than the creation date and time',
    POSTS_VALIDATION_ERROR__DATE_NOT_IN_FUTURE: 'Select moment in the future',
    POSTS_VALIDATION_ERROR__SELECT_COMPANY_OR_PAGE: 'Select companies or pages to post',
    POSTS_VALIDATION_ERROR__LICENSE_WILL_EXPIRE_ON_DATE: 'There is no active license for the selected date',

    POSTS__EMPTY_CREATION_DATE__HELPER: 'The post will be sent immediately if the field is empty.',

    POSTS__CREATION_DATE__HELPER:
        'The post will be sent immediately if the field is empty. Yandex Maps uses the UTC+0 time zone to display the publication time.',

    POSTS_FORM__TYPE__COUPON__LABEL: 'Coupon',
    POSTS_FORM__TYPE__COUPON__PLACEHOLDER: 'Coupon',
    POSTS_FORM__TYPE__EVENT_NAME__LABEL: 'Event title',
    POSTS_FORM__TYPE__EVENT_NAME__PLACEHOLDER: 'Event title',
    POSTS_FORM__TYPE__REDEEM_URL__LABEL: 'Promotion URL',
    POSTS_FORM__TYPE__REDEEM_URL__PLACEHOLDER: 'https://{domainName}',
    POSTS_FORM__TYPE__TERMS_AND_CONDITIONS__LABEL: 'Terms and conditions',
    POSTS_FORM__TYPE__TERMS_AND_CONDITIONS__PLACEHOLDER: 'Terms and conditions',
    POSTS_FORM__BUTTON_TYPE__LABEL: 'Button type',
    POSTS_FORM__TYPE__EVENT_NAME__EMPTY: 'Event name is not entered',
    POSTS_FORM__CONTENT__LABEL: 'Post body',
    POSTS_FORM__CONTENT__PLACEHOLDER: 'Main text',
    POSTS_FORM__CONTENT__TOOLTIP: 'The recommended length is 150-300 characters.',
    POSTS_FORM__SCHEDULE_POST__CREATION_DATE_TIME__LABEL: 'Date and time of dispatch',
    POSTS_FORM__SCHEDULE_POST__DELETE_DATE_TIME__LABEL: 'Date and time of removing',
    POSTS_FORM__SCHEDULE_POST__TIMEZONE__LABEL: 'Timezone',
    POSTS_FORM__BUTTON_LINK__LABEL: 'Button link',
    POSTS_FORM__BUTTON_LINK__PLACEHOLDER: 'Enter button link',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_COUNT__LABEL: 'Number of repetitions',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_COUNT__PLACEHOLDER: 'Enter number of repetitions',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_COUNT__TOOLTIP: 'Maximum number of repetitions is 100, minimum is 1',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__LABEL: 'Refresh frequency',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__PLACEHOLDER: 'Select refresh frequency',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__WEEKLY: 'every 7 days',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__HALF_MONTH: 'every 14 days',
    POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__MONTH: 'every 30 days',

    POSTS_REQUIREMENTS__TITLE: 'Service requirements for publications',
    POSTS_REQUIREMENTS__COMMON_TITLE: 'General service requirements for publications',

    POSTS_REQUIREMENTS__IMAGES: 'Images',
    POSTS_REQUIREMENTS__IMAGES__RULE_1_SINGULAR: 'Only {imageCount} image of good quality',
    POSTS_REQUIREMENTS__IMAGES__RULE_1_FEW: 'Up to {imageCount} good quality images',
    POSTS_REQUIREMENTS__IMAGES__RULE_1_MANY: 'Up to {imageCount} good quality images',
    POSTS_REQUIREMENTS__IMAGES__RULE_2: 'Min. {minWidth} × {minHeight} pixels',
    POSTS_REQUIREMENTS__IMAGES__RULE_3: 'Max. {maxWidth} × {maxHeight} pixels',
    POSTS_REQUIREMENTS__IMAGES__RULE_4: 'Maximum size {maxSize} MB',
    POSTS_REQUIREMENTS__IMAGES__RULE_5: 'Format: {imageMime}',

    POSTS_REQUIREMENTS__IMAGES__GOOGLE__RULE_1: 'Aspect ratio for preview 4:3',
    POSTS_REQUIREMENTS__IMAGES__YANDEX__RULE_1:
        'Aspect ratio 5:2 for correct display in the publication (helps to avoid cropping)',

    POSTS_REQUIREMENTS__VIDEO: 'Video',
    POSTS_REQUIREMENTS__VIDEO__RULE_1: 'Duration up to 60 seconds',
    POSTS_REQUIREMENTS__VIDEO__RULE_2: 'Resolution up to 1080p',
    POSTS_REQUIREMENTS__VIDEO__RULE_3: 'File size up to 100MB',
    POSTS_REQUIREMENTS__VIDEO__FACEBOOK__RULE: "Can't update a video in an active post",
    POSTS_REQUIREMENTS__VIDEO__VKONTAKTE__FORMATS:
        'Format: MP4, AVI, 3GP, MPEG, MOV, FLV, WMV, MKV, M4V, RMVB, OGV, TS, MPG, M2TS, MTS.',
    POSTS_REQUIREMENTS__VIDEO__FACEBOOK__FORMATS:
        'Format: MP4, MOV, 3G2, 3GP, 3GPP, ASF, AVI, DAT, DIVX, DV, F4V, FLV, GIF, M2TS, M4V, MKV, MOD, MPE, MPEG, MPEG4, MPG, MTS, NSV, OGM, OGV, QT, TOD, TS, VOB, WMV',
    POSTS_REQUIREMENTS__VIDEO__INSTAGRAM__FORMATS: 'Format: MP4, MOV',

    POSTS__CATALOG_REQUIREMENTS: 'All requirements',

    POSTS__CATALOG_REQUIREMENTS__ITEM_TEXT: 'All {catalog} requirements for posts',

    POSTS_REQUIREMENTS__TEXT: 'Text',
    POSTS_REQUIREMENTS__TEXT__RULE_1: 'Body text up to {maxLength} characters',

    POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_1: 'No headline',
    POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_2: 'There is no tragic themes, incitement to cruelty or violence',
    POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_3: 'No words s p e l l e d out',
    POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_4:
        'There are no words written entirely in CAPITAL LETTERS (other than common abbreviations)',
    POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_5: 'No shortened links or links to external sites',

    POSTS_REQUIREMENTS__TEXT__VKONTAKTE__RULE_1: 'No headline',

    POSTS_REQUIREMENTS__TEXT__GOOGLE__RULE_1: 'The use of phone numbers in the text is not allowed',

    POSTS_REQUIREMENTS__CALL_TO_ACTION: 'Call to action',
    POSTS_REQUIREMENTS__CALL_TO_ACTION__GOOGLE__RULE_1: 'Text displayed on the button',
    POSTS_REQUIREMENTS__CALL_TO_ACTION__GOOGLE__RULE_2: 'Link.',

    POSTS_REQUIREMENTS__OFFER_POST: 'Promotional posts',
    POSTS_REQUIREMENTS__OFFER_POST__GOOGLE__RULE1:
        'Hotels can’t create “offer” posts, or any post that mentions or includes links to deals, promotions, special offers, or discounts',

    POSTS_FORM__BUTTON__REMOVE_DRAFT: 'Remove draft',
    POSTS_FORM__BUTTON__REMOVE_POST: 'Remove post',
    POSTS_FORM__BUTTON__SAVE_DRAFT: 'Save draft',
    POSTS_FORM__BUTTON__UPDATE_DRAFT: 'Update draft',
    POSTS_FORM__BUTTON__SEND: 'Send',
    POSTS_FORM__BUTTON__RETURN_TO_LIST: 'Return to list of publications',

    POSTS_FORM__ERROR__IMAGE_PROPORTIONS:
        'The image must be at least {minWidth} × {minHeight} pixels and no more than {maxWidth} × {maxHeight}. Image upload size {width} × {height} pixels.',
    POSTS_FORM__ERROR__IMAGE_SIZE: 'Max image size is {maxSize}MB',
    POSTS_FORM__ERROR__IMAGE_UPLOAD: 'Image upload error',
    POSTS_FORM__ERROR__IMAGE__TITLE: 'Failed to load image.',
    POSTS_FORM__ERROR__VIDEO_UPLOAD: 'Video upload error',
    POSTS_FORM__ERROR__VIDEO_TITLE: 'Failed to load video',

    POSTS_FORM__ERROR__NO_SOURCES: 'No catalogs selected!',
    POSTS_FORM__ERROR__EMPTY_POST_NAME: 'Enter the name of your post!',
    POSTS_FORM__ERROR__SHORT_POST_NAME: 'The name must be at least {length} characters long',
    POSTS_FORM__ERROR__CREATE_POST: 'Post creation error',
    POSTS_FORM__ERROR__UPDATE_POST: 'Post update error',

    POSTS_FORM__MEDIA_IN_PROGRESS_ERROR: 'Wait for the download to finish',
    POSTS_FORM__AT_LEAST_ONE_MEDIA_ERROR: 'Upload at least 1 photo or video',

    POSTS_SUGGESTS__TITLE: 'Neural Network Suggestions',
    POSTS_SUGGESTS__SUGGEST: 'Suggest {current}/{total}',
    POSTS_SUGGESTS__GENERATE: 'Generate idea',
    POSTS_SUGGESTS__GENERATE_MORE: 'Generate more ideas',
    POSTS_SUGGESTS_ERROR__TITLE: 'Failed to come up with an idea to post',
    POSTS_SUGGESTS_ERROR__CONTENT: 'Try to repeat the operation',

    POSTS_FORM__MESSAGE__CREATE_SUCCESS: 'The publication has been successfully created',
    POSTS_FORM__MESSAGE__UPDATE_SUCCESS: 'The post has been successfully updated',
    POSTS_FORM__MESSAGE__SAVE_DRAFT_SUCCESS: 'Draft successfully saved',
    POSTS_FORM__MESSAGE__UPDATE_DRAFT_SUCCESS: 'Draft successfully updated',
    POSTS_FORM__MESSAGE__PUBLISH_POST_ERROR: 'Error while posting',
    POSTS_FORM__MESSAGE__PUBLISH_POST_SUCCESS: 'Post successfully published',
    POSTS_FORM__MESSAGE__REMOVE_POST_ERROR: 'Post deletion error',
    POSTS_FORM__MESSAGE__HARD_REMOVE__POST_ERROR: 'Failed to remove the post. Please try again later.',
    POSTS_FORM__MESSAGE__REMOVE_POST_SUCCESS: 'Post successfully deleted',
    POSTS_FORM__MESSAGE__HARD_REMOVE__POST_SUCCESS: 'The post will be removed, this may take some time',
    POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__TITLE: 'Remove post',
    POSTS_FORM__MESSAGE__HARD_REMOVE_POST_MODAL__TITLE: 'Complete post removal',
    POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__UNSUPPORTED_REMOVE_ALERT:
        'Deleting a publication in {catalogName} is not supported',
    POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__CONTENT: 'The publication will be removed from the services',
    POSTS_FORM__MESSAGE__HARD_REMOVE_POST_MODAL__CONTENT:
        'The post will be completely removed. This action is irreversible. Are you sure you want to proceed?',
    POSTS_FORM__MESSAGE__REMOVE_POST_DRAFT_MODAL__TITLE: 'Remove Draft Post?',
    POSTS_FORM__MESSAGE__REMOVE_POST_DRAFT_MODAL__CONTENT: 'Draft post will be removed',
    POSTS_FORM__MESSAGE__CREATE_POST_MODAL__CONTENT: 'The post will be sent to the selected services',

    POSTS_FORM__PREVIEW__DESKTOP: 'Desktop',
    POSTS_FORM__PREVIEW__MOBILE: 'Mobile',
    POSTS_FORM__PREVIEW__TITLE: 'Preview',
    POSTS_FORM__PREVIEW__INFORMATION: 'The actual appearance of the post may differ from the preview',

    POSTS_FORM__PREVIEW__YANDEX__COMPANY_NAME: 'Company name',
    POSTS_FORM__PREVIEW__YANDEX__READ_MORE: 'Read more',
    POSTS_FORM__PREVIEW__YANDEX__LIKE_TEXT: 'Like',

    POSTS_FORM__PREVIEW__INSTAGRAM__LIKE_PLACEHOLDER: '100 likes',
    POSTS_FORM__PREVIEW__INSTAGRAM__READ_MORE: 'more',
    POSTS_FORM__PREVIEW__INSTAGRAM__VIEW_COMMENTS: 'View all comments (10)',
    POSTS_FORM__PREVIEW__INSTAGRAM__COMPANY_NICKNAME: 'company_name',

    POSTS_FORM__PREVIEW__GOOGLE__READ_MORE: 'Expand',

    POSTS_FORM__PREVIEW__VKONTAKTE__READ_MORE: 'More',

    POSTS_FORM__PREVIEW__FACEBOOK__COMPANY_NAME: 'Company name',
    POSTS_FORM__PREVIEW__FACEBOOK__LIKE: 'Like',
    POSTS_FORM__PREVIEW__FACEBOOK__COMMENT: 'comment',
    POSTS_FORM__PREVIEW__FACEBOOK__SHARE: 'share',

    POSTS__UPLOAD_IMAGE: 'Upload photo',
    POSTS__UPLOAD_VIDEO: 'Upload video',

    POST__DETAILS__BREADCRUMBS: 'Links to the publication',
    POST__DETAILS__HEADER: 'Links to the publication on the site',
    POST__DETAILS__POST_NAME: 'Publication "{postName}"',
    POST__DETAILS_TABLE__SOURCE: 'Catalog',
    POST__DETAILS_TABLE__NAME: 'Company or social media page networks',
    POST__DETAILS_TABLE__STATUS: 'Status',
    POST__DETAILS_TABLE__LINK: 'Link',
    POSTS_SUBMIT_INFORMATION__HEADING: 'Publication submission confirmation',
    POSTS_SUBMIT_INFORMATION__COMPANIES_COUNT_SINGULAR: '{count} company selected',
    POSTS_SUBMIT_INFORMATION__COMPANIES_COUNT_FEW: '{count} companies selected',
    POSTS_SUBMIT_INFORMATION__COMPANIES_COUNT_MANY: '{count} companies selected',
    POSTS_SUBMIT_INFORMATION__PUBLICATION__TITLE: 'Number of publications',
    POSTS_SUBMIT_INFORMATION__PUBLICATION__COUNT_SINGULAR: '{count} publication',
    POSTS_SUBMIT_INFORMATION__PUBLICATION__COUNT_FEW: '{count} publications',
    POSTS_SUBMIT_INFORMATION__PUBLICATION__COUNT_MANY: '{count} publications',
    POSTS_SUBMIT_INFORMATION__PUBLICATION__TOOLTIP_TEXT:
        '{countCompanies} of the {totalCompanies} selected companies do not have permission to post',
    POSTS_SUBMIT_INFORMATION__PUBLICATION__FB_PAGES: 'Facebook pages',

    POSTS_EDIT__NOT_SUPPORTED: '{catalogName} does not support editing a submitted post',
    POSTS_EDIT__TOPIC_TYPE__DESCRIPTION: 'Changing the type of a posted post is not possible',

    POSTS_ACTIVATION__LANDING__CARD__CALL__ACTION_DESCRIPTION:
        'Sign up for a free trial period and use all the benefits of the post creation service for free for {duration} days',
    POSTS_ACTIVATION__LANDING__CARD_1__TITLE: 'Service “Creating posts” in Google geoservices and social networks',
    POSTS_ACTIVATION__LANDING__CARD_1__DESCRIPTION:
        'Effectively manage publications from a single {companyName} account, post them on accessible platforms and plan posting dates to attract more customers to your points of sale',
    POSTS_ACTIVATION__LANDING__CARD_2__TITLE: 'What is a post (publication) in cards?',
    POSTS_ACTIVATION__LANDING__CARD_2__DESCRIPTION:
        'This is a text and graphic block in your company’s Google cards and on group pages on FB',
    POSTS_ACTIVATION__LANDING__CARD_2__DESCRIPTION__LIST_ITEM_1:
        'Posts inform about upcoming promotions, news and events of your brand',
    POSTS_ACTIVATION__LANDING__CARD_2__DESCRIPTION__LIST_ITEM_2:
        'They also help highlight new, seasonal and promotional products.',
    POSTS_ACTIVATION__LANDING__CARD_3__TITLE: 'Generating post text using ChatGPT',
    POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION: 'Now creating and publishing posts will take even less time',
    POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION__LIST_ITEM_1:
        'In one click you generate publication text relevant to your business',
    POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION__LIST_ITEM_2:
        'The neural network provides you with text options so you can choose the most suitable one',
    POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION__LIST_ITEM_3:
        'The text proposed by the neural network can be edited before publication, i.e. the decision about what the post will be is up to you',

    POSTS__ONBOARDING__STEP1__TITLE: 'Welcome to the post creation service! 👋',
    POSTS__ONBOARDING__STEP1__DESCRIPTION:
        'To create a post, select a brand and in which cards to publish the post —{break} in all cards at once or for specific locations',
    POSTS__ONBOARDING__STEP2__TITLE: 'Selection of services ✅',
    POSTS__ONBOARDING__STEP2__DESCRIPTION: 'Choose which platforms to publish your post on —{break} Google or FB',
    POSTS__ONBOARDING__STEP3__TITLE: 'Filling with content ✏️',
    POSTS__ONBOARDING__STEP3__DESCRIPTION:
        'Fill out all fields of the publication separately for each{break}website in “adaptive mode”',
    POSTS__ONBOARDING__STEP4__TITLE: 'Publish text using ChatGPT 🤖',
    POSTS__ONBOARDING__STEP4__DESCRIPTION:
        'Generate publication text using a neural network{break}and choose the option that suits you',
    POSTS__ONBOARDING__STEP5__TITLE: 'Dates sent and deleted 📆',
    POSTS__ONBOARDING__STEP5__DESCRIPTION: 'Set up a publishing schedule{break}using a convenient planner',
    POSTS__ONBOARDING__STEP6__TITLE: 'Service is ready!',
    POSTS__ONBOARDING__STEP6__DESCRIPTION:
        'Go to the main page of the post creation service to{break}effectively manage publications from a single account.',
};
