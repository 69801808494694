import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {defaultResponseTemplateData} from '../../../service/reviews/reviews-response-templates';
import {ResponseTemplatesForm} from '../response-templates-form/response-templates-form';

export function ResponseTemplatesCreateTemplate(): JSX.Element {
    const {
        reviewsManagementResponseTemplates: templatesRoute,
        reviewsManagementResponseTemplatesCreateTemplate: templatesCreateTemplateRoute,
    } = appRoute;

    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('RESPONSE_TEMPLATES__CREATING_A_TEMPLATE')} />

            <BreadCrumbs
                list={[
                    {path: templatesRoute.path, titleLangKey: 'CATEGORY_NAME__RESPONSE_TEMPLATES'},
                    {
                        path: templatesCreateTemplateRoute.path,
                        titleLangKey: 'RESPONSE_TEMPLATES__CREATING_A_TEMPLATE',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="RESPONSE_TEMPLATES__CREATING_A_TEMPLATE" />
            </PageHeader>

            <ResponseTemplatesForm mode="create" responseTemplate={defaultResponseTemplateData} />
        </Page>
    );
}
