import {WeekDaysEnum} from '../../../provider/locale/locale-context-const';

import {WorkDayDataType} from './workdays-form-item-type';

const defaultWorkDayData: Omit<WorkDayDataType, 'day'> = {
    timeFrom: null,
    timeTo: null,
    isOpen: true,
    isAroundTheClock: false,
    breakTimeList: [],
};

export const DEFAULT_WORK_DAY_DATA_LIST: Array<WorkDayDataType> = [
    {day: WeekDaysEnum.monday, ...defaultWorkDayData},
    {day: WeekDaysEnum.tuesday, ...defaultWorkDayData},
    {day: WeekDaysEnum.wednesday, ...defaultWorkDayData},
    {day: WeekDaysEnum.thursday, ...defaultWorkDayData},
    {day: WeekDaysEnum.friday, ...defaultWorkDayData},
    {day: WeekDaysEnum.saturday, ...defaultWorkDayData},
    {day: WeekDaysEnum.sunday, ...defaultWorkDayData},
];
