import {Text} from '../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {useFakesStatuses} from '../../../../../service/fakes/fakes-statuses';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './fakes-next-search.scss';

type PropsType = {
    langKey?: LangKeyType;
    className?: string;
};

export function FakesNextSearch(props: PropsType): JSX.Element {
    const {langKey, className} = props;

    const {data: statuses} = useFakesStatuses();
    const {getFormattedDateTime} = useFormat();

    const nextSearchData = statuses?.minNextProcessingDate && new Date(statuses?.minNextProcessingDate);
    const additionalInfoClassNames = classNames(styles.FakesNextSearch_additionalInfo, className);

    if (statuses?.isSearching) {
        return (
            <AdditionalInfo
                className={additionalInfoClassNames}
                leftNode={
                    <Text
                        className={styles.FakesNextSearch_isSearching}
                        stringKey={langKey || 'FAKES__RESULTS__HEADER__IS_SEARCHING'}
                    />
                }
                placement="bottom"
                title={<Locale stringKey="FAKES__EMPTY__IS_SEARCHING__HEADER" />}
            >
                <Locale stringKey="FAKES__EMPTY__IS_SEARCHING__DESCRIPTION" />
            </AdditionalInfo>
        );
    }

    return (
        <AdditionalInfo
            className={additionalInfoClassNames}
            leftNode={
                <Text
                    secondary
                    stringKey={langKey || 'FAKES__RESULTS__HEADER__NEXT_SEARCH'}
                    valueMap={{
                        date: nextSearchData ? (
                            <Text>
                                {getFormattedDateTime(nextSearchData, {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                })}
                            </Text>
                        ) : (
                            <Text>—</Text>
                        ),
                    }}
                />
            }
            placement="bottom"
            title={<Locale stringKey="FAKES__RESULTS__HEADER__NEXT_SEARCH__SUBTITLE" />}
        >
            <Locale stringKey="FAKES__RESULTS__HEADER__NEXT_SEARCH__DESCRIPTION" />
        </AdditionalInfo>
    );
}
