import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';

import {ResponseTemplatesEditTableRowDataType} from './response-templates-edit-group-type';

export const responseTemplateColumnList: TableColumnsType<ResponseTemplatesEditTableRowDataType> = [
    {
        title: <Locale stringKey="RESPONSE_TEMPLATES__GROUP" />,
        dataIndex: 'responseTemplateName',
    },
    {
        title: <Locale stringKey="TAGS__GROUP__ACTIONS" />,
        dataIndex: 'templateGroupActions',
        width: 104,
    },
];
