import {LangKeyType} from '../../../../provider/locale/translation/type';
import {Text} from '../../../text/text';
import {getLocaleItems} from '../landing-card-helper';
import {LandingListDescriptionItemType} from '../landing-card-type';

import * as styles from './landing-card-list.scss';

type PropsType = {
    listItems?: Array<LangKeyType | LandingListDescriptionItemType>;
};

export function LandingCardList(props: PropsType): JSX.Element | null {
    const {listItems} = props;

    if (!listItems || listItems.length === 0) {
        return null;
    }

    return (
        <ul className={styles.LandingCardList}>
            {listItems.map((item) => {
                const {stringKey, valueMap} = getLocaleItems(item);

                return (
                    <li className={styles.LandingCardList_item} key={stringKey}>
                        <Text lighter stringKey={stringKey} valueMap={valueMap} />
                    </li>
                );
            })}
        </ul>
    );
}
