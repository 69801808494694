import {useMemo} from 'react';

import {ChartRenderer} from '../chart-renderer/chart-renderer';
import {legendMarginPlugin} from '../chart-renderer/chart-renderer-helper';

import {barChartOptions} from './bar-chart-helper';
import {
    BarDataSetType,
    BarDataType,
    ChartJsBarDataSetType,
    ChartJsBarDataType,
    ChartJsBarOptionsType,
} from './bar-chart-type';

type PropsType<T> = {
    data: BarDataType<T> | null;
    options?: ChartJsBarOptionsType;
};

export function BarChart<T = number>(props: PropsType<T>): JSX.Element {
    const {data, options} = props;

    const chartJsData: ChartJsBarDataType<T> = useMemo<ChartJsBarDataType<T>>(
        (): ChartJsBarDataType<T> => ({
            labels: data?.labels || [],
            datasets:
                data?.datasets.map(
                    (dataset: BarDataSetType<T>): ChartJsBarDataSetType<T> => ({
                        label: dataset.label,
                        data: dataset.values,
                        backgroundColor: dataset.color,
                        hoverBackgroundColor: dataset.hoverBackgroundColor || dataset.color,
                        stack: dataset.stack,
                        borderRadius: dataset.borderRadius,
                        barPercentage: dataset.barPercentage,
                        categoryPercentage: dataset.categoryPercentage,
                        parsing: dataset.parsing,
                    })
                ) || [],
        }),
        [data]
    );

    return (
        <ChartRenderer<'bar', Array<T>, string>
            data={chartJsData}
            options={options || barChartOptions}
            plugins={[legendMarginPlugin]}
            type="bar"
        />
    );
}
