import {ExtractRouteParams} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../../../app-route';
import {AlertFallback} from '../../../../../../component/alert-fallback/alert-fallback';
import {Page} from '../../../../../../layout/page/page';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {useEditUser} from '../../../../../../service/user-management/user-management-edit-user';

import {EditUser} from './edit-user';

export function EditUserPageGuard(): JSX.Element {
    const {userId} = useParams<ExtractRouteParams<typeof appRoute.editUser.path, string>>();

    if (!userId) {
        throw new Error('In "edit" mode "userId" always should be specified');
    }

    const {data: user, error, isLoading} = useEditUser(Number(userId));

    if (isLoading) {
        return <Spinner position="fixed" />;
    }

    if (error) {
        return (
            <Page>
                <AlertFallback />
            </Page>
        );
    }

    if (!user) {
        return (
            <Page>
                <AlertFallback />
            </Page>
        );
    }

    return <EditUser user={user} userId={Number(userId)} />;
}
