import {faBuilding, faCheck, faSpinner, faUser} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Steps} from 'antd';
import {inRange} from 'lodash';
import {useContext, useEffect, useMemo, useState} from 'react';

import {breakpoint_laptop, breakpoint_tablet} from '../../../../css/var-export.scss';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSystem} from '../../../../provider/system/system-hook';
import {fetchGoogleProcessingKey} from '../../../../service/google/processing/google-processing-api';
import {logError} from '../../../../util/error';
import {useUrlQueryNumber} from '../../../../util/url-hook/url-hook';

import {PageHeader} from './common/page-header/page-header';
import {GoogleAccountSyncContext} from './google-account-sync-context';
import {
    GoogleAccountSyncStepEnum as StepEnum,
    GoogleAccountSyncUrlParameterEnum,
    GoogleAccountSyncUrlType,
    StepType,
} from './google-account-sync-type';
import {AccountsStep} from './steps/accounts/accounts-step';
import {getPersonalAccountTips, noPersonalAccountTips} from './steps/accounts/accounts-step-const';
import {CompaniesStep} from './steps/companies/companies-step';
import {FinishStep} from './steps/finish/finish-step';
import {ProcessingStep} from './steps/processing/processing-step';
import {Tips} from './tips/tips';
import * as styles from './google-account-sync.scss';

export function GoogleAccountSync(): JSX.Element {
    const accountIdQuery = useUrlQueryNumber<GoogleAccountSyncUrlType>(GoogleAccountSyncUrlParameterEnum.AccountId);

    const {personalAccountId, setStatus, setProcessingKey, setAccountId, setTips} =
        useContext(GoogleAccountSyncContext);

    const [currentStep, setCurrentStep] = useState(accountIdQuery ? StepEnum.Companies : StepEnum.Accounts);
    const [isCompaniesStepVisited, setIsCompaniesStepVisited] = useState(Boolean(accountIdQuery));

    const {screen} = useSystem();
    const {companyName} = useDomainConfig();
    const documentationLinks = useDocumentationLinks();

    const isSmallWidth = screen.width < Number(breakpoint_tablet);
    const isMediumWidth = inRange(screen.width, Number(breakpoint_tablet), Number(breakpoint_laptop));

    const steps: Array<StepType> = useMemo(
        () => [
            {
                step: StepEnum.Accounts,
                icon: faUser,
                title: 'GOOGLE_SYNC__STEPS__ACCOUNTS',
                description: 'GOOGLE_SYNC__STEPS__ACCOUNTS__DESCRIPTION',
                component: <AccountsStep onFinish={() => setCurrentStep(StepEnum.Companies)} />,
            },
            {
                step: StepEnum.Companies,
                icon: faBuilding,
                title: 'GOOGLE_SYNC__STEPS__COMPANIES',
                description: 'GOOGLE_SYNC__STEPS__COMPANIES__DESCRIPTION',
                valueMapFromDescription: {companyName},
                component: (
                    <CompaniesStep
                        onFinish={() => setCurrentStep(StepEnum.Processing)}
                        onGoBack={() => setCurrentStep(StepEnum.Accounts)}
                    />
                ),
            },
            {
                step: StepEnum.Processing,
                icon: faSpinner,
                title: 'GOOGLE_SYNC__STEPS__PROCESSING',
                description: 'GOOGLE_SYNC__STEPS__PROCESSING__DESCRIPTION',
                component: <ProcessingStep onFinish={() => setCurrentStep(StepEnum.Finish)} />,
            },
            {
                step: StepEnum.Finish,
                icon: faCheck,
                title: 'GOOGLE_SYNC__STEPS__FINISH',
                description: 'GOOGLE_SYNC__STEPS__FINISH__DESCRIPTION',
                component: <FinishStep />,
            },
        ],
        [companyName]
    );

    useEffect(() => {
        fetchGoogleProcessingKey()
            .then(({key, tokenId, status}) => {
                if (!key || !tokenId) {
                    return;
                }

                setProcessingKey(key);
                setAccountId(tokenId);
                setStatus(status);
                setCurrentStep(StepEnum.Processing);
            })
            .catch(logError);
    }, [setAccountId, setProcessingKey, setStatus]);

    useEffect(() => {
        setTips(personalAccountId ? getPersonalAccountTips(documentationLinks) : noPersonalAccountTips);
    }, [documentationLinks, personalAccountId, setTips]);

    useEffect(() => {
        if (currentStep === StepEnum.Companies) {
            setIsCompaniesStepVisited(true);
        }
    }, [currentStep]);

    function isStepDisabled(step: StepEnum) {
        const isLockedStep = [StepEnum.Processing, StepEnum.Finish].includes(step);

        if (currentStep === StepEnum.Accounts) {
            // don't allow to go to companies step unless you've already visited it
            return isCompaniesStepVisited ? isLockedStep : step !== StepEnum.Accounts;
        }

        if (currentStep === StepEnum.Companies) {
            return isLockedStep;
        }

        return true;
    }

    return (
        <>
            <PageHeader titleLangKey="GOOGLE_SYNC__TITLE" />

            <Divider className={styles.GoogleAccountSyncPage_divider} />

            <div className={styles.GoogleAccountSyncPage}>
                <aside className={styles.Steps}>
                    <Steps
                        current={currentStep}
                        direction={isSmallWidth ? 'horizontal' : 'vertical'}
                        onChange={setCurrentStep}
                        responsive={false}
                        size={isSmallWidth ? 'small' : 'default'}
                    >
                        {steps.map(({step, icon, title, description, valueMapFromDescription}) => (
                            <Steps.Step
                                description={
                                    isSmallWidth || isMediumWidth ? null : (
                                        <Locale stringKey={description} valueMap={valueMapFromDescription} />
                                    )
                                }
                                disabled={isStepDisabled(step)}
                                icon={isSmallWidth ? <FontAwesomeIcon icon={icon} /> : null}
                                key={title}
                                title={isSmallWidth ? null : <Locale stringKey={title} />}
                            />
                        ))}
                    </Steps>

                    {isMediumWidth && (
                        <>
                            <Divider className={styles.Steps_divider} />
                            <Tips />
                        </>
                    )}
                </aside>

                <div className={styles.Content}>{steps.find(({step}) => currentStep === step)?.component}</div>

                {!isMediumWidth && (
                    <aside className={styles.Tips}>
                        <Tips />
                    </aside>
                )}
            </div>
        </>
    );
}
