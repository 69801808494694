import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Statistic, Tooltip} from 'antd';

import {Locale} from '../../../../provider/locale/localization';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';

import {NUMERIC_CHANGE_VALUE} from './fakes-header-const';
import * as styles from './fakes-header.scss';

type PropsType = {
    title: LangKeyType;
    count: number;
    icon: IconDefinition;
    className: string;
};

export function FakesHeaderStatisticValue(props: PropsType): JSX.Element {
    const {icon, title, count, className} = props;

    const {getFormattedNumber} = useFormat();

    return count > NUMERIC_CHANGE_VALUE ? (
        <Tooltip key={title} placement="bottomLeft" title={count}>
            <Statistic
                className={styles.FakesHeader_statistic}
                prefix={
                    <FontAwesomeIcon
                        className={classNames(styles.FakesHeader_icon, {
                            [className]: count > 0,
                        })}
                        icon={icon}
                    />
                }
                title={<Locale stringKey={title} />}
                value={getFormattedNumber(count, {
                    notation: 'compact',
                })}
            />
        </Tooltip>
    ) : (
        <Statistic
            className={styles.FakesHeader_statistic}
            prefix={
                <FontAwesomeIcon
                    className={classNames(styles.FakesHeader_icon, {
                        [className]: count > 0,
                    })}
                    icon={icon}
                />
            }
            title={<Locale stringKey={title} />}
            value={getFormattedNumber(count, {
                notation: 'compact',
            })}
        />
    );
}
