import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {facebookAccountsSchema, googleAccountsSchema, vkAccountsSchema} from './accounts-const';
import {FacebookAccountsResponseType, GoogleAccountsResponseType, VkAccountType} from './accounts-type';

export async function loadVkAccountsApi(): Promise<Array<VkAccountType>> {
    const parameters = objectToUrlParameters({timestamp: Date.now()});

    return fetchAndDeserialize(`/v1/vk/accounts/?${parameters}`, vkAccountsSchema);
}

export async function loadGoogleAccountsApi({brandId}: {brandId?: number}): Promise<GoogleAccountsResponseType> {
    const parameters = objectToUrlParameters(
        serializeToURLParameters({
            onlyParent: false,
            timestamp: Date.now(),
            ...(brandId ? {brandId} : null),
        })
    );

    return fetchAndDeserialize(`/v1/accounts/11/?${parameters}`, googleAccountsSchema);
}

export async function loadFacebookAccountsApi(): Promise<FacebookAccountsResponseType> {
    const parameters = objectToUrlParameters({only_parent: true, timestamp: Date.now()});

    return fetchAndDeserialize(`/v1/accounts/35/?${parameters}`, facebookAccountsSchema);
}

export function removeVkAccountApi(accountId: number): Promise<void> {
    return fetchNoContent(rootApiUrl + `/v1/vk/accounts/${accountId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
