import {LangKeyType} from '../../../../provider/locale/translation/type';
import {ConfirmationSourcesMethodEnum} from '../../../../service/confirmation/confirmation-type';

const confirmationMethodTranslationMap: Record<ConfirmationSourcesMethodEnum | string, LangKeyType> = {
    [ConfirmationSourcesMethodEnum.Sms]: 'SOURCES_CONFIRMATION__METHOD__SMS',
    [ConfirmationSourcesMethodEnum.Link]: 'SOURCES_CONFIRMATION__METHOD__LINK',
    [ConfirmationSourcesMethodEnum.Address]: 'SOURCES_CONFIRMATION__METHOD__ADDRESS',
    [ConfirmationSourcesMethodEnum.PhoneCall]: 'SOURCES_CONFIRMATION__METHOD__PHONE_CALL',
    [ConfirmationSourcesMethodEnum.Email]: 'SOURCES_CONFIRMATION__METHOD__EMAIL',
    [ConfirmationSourcesMethodEnum.Phone]: 'SOURCES_CONFIRMATION__METHOD__PHONE',
    [ConfirmationSourcesMethodEnum.VettedPartner]: 'SOURCES_CONFIRMATION__METHOD__VETTED_PARTNER',
    [ConfirmationSourcesMethodEnum.Auto]: 'SOURCES_CONFIRMATION__METHOD__VETTED_PARTNER',
    [ConfirmationSourcesMethodEnum.RightsUrl]: 'SOURCES_CONFIRMATION__METHOD__RIGHTS_URL',
};

// backend enums for confirmation api are quite chaotic, we can't guarantee what case the method is in
export function getConfirmationMethodTranslationKey(method: string): LangKeyType | undefined {
    return confirmationMethodTranslationMap[method.toLowerCase()];
}
