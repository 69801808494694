import {ReactComponent as IconBookmark} from './images/icon-bookmark.svg';
import {ReactComponent as IconComment} from './images/icon-comment.svg';
import {ReactComponent as IconLike} from './images/icon-like.svg';
import {ReactComponent as IconSend} from './images/icon-send.svg';
import * as styles from './instagram-preview-actions.scss';

export function InstagramPreviewActions(): JSX.Element {
    return (
        <div className={styles.PreviewActions}>
            <div className={styles.PreviewActions_box}>
                <IconLike />
                <IconComment />
                <IconSend />
            </div>
            <IconBookmark />
        </div>
    );
}
