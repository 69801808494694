import {appRoute} from '../../../../app-route';
import {LangKeyType} from '../../../../provider/locale/translation/type';

import neuralNetworkSrc from './common/image/neural-network-background.png';
import onlinePresenceSrc from './common/image/online-presence-background.png';
import photoAndVideoSrc from './common/image/photo-and-video-background.png';
import positionTrackerSrc from './common/image/position-tracker-background.png';
import productAndPricesSrc from './common/image/product-and-prices-background.png';
import publicationsSrc from './common/image/publications-background.png';
import repliesToReviewsSrc from './common/image/replies-to-reviews-background.png';
import reviewsAnalyticsSrc from './common/image/reviews-analytics-background.png';
import reviewsGeneratorSrc from './common/image/reviews-generator-background.png';
import sourcesSrc from './common/image/sources-background.png';
import tagsSrc from './common/image/tags-background.png';
import templatesSrc from './common/image/templates-background.png';

export enum RecommendationsStepNameEnum {
    AutoReviewTemplate = 'auto_review_template',
    TagsForReview = 'tags_for_review',

    SettingSources = 'source_settings',
    ProductsAndPrices = 'products_and_prices',
    OnlinePresence = 'online_presence',
    ReviewsAnalytics = 'reviews_analytics',

    PositionTracker = 'position_tracker',
    PhotoAndVideoManager = 'photo_and_video_manager',
    Publications = 'publications',

    ReviewsGenerator = 'reviews_generator__mailings',
    ReviewsGeneratorQr = 'reviews_generator__qr',
    RepliesToReviews = 'replies_to_reviews',
    NeuralNetwork = 'neural_network',
}
export const recommendationTitleKeyMap: Record<RecommendationsStepNameEnum, LangKeyType> = {
    [RecommendationsStepNameEnum.AutoReviewTemplate]: 'ONBOARDING__RECOMMENDATION__AUTO_REVIEW_TEMPLATE__TITLE',
    [RecommendationsStepNameEnum.TagsForReview]: 'ONBOARDING__RECOMMENDATION__TAGS_FOR_REVIEW__TITLE',
    [RecommendationsStepNameEnum.SettingSources]: 'ONBOARDING__RECOMMENDATION__SETTING_SOURCES__TITLE',
    [RecommendationsStepNameEnum.ProductsAndPrices]: 'ONBOARDING__RECOMMENDATION__PRODUCTS_AND_PRICES__TITLE',
    [RecommendationsStepNameEnum.OnlinePresence]: 'ONBOARDING__RECOMMENDATION__ONLINE_PRESENCE__TITLE',
    [RecommendationsStepNameEnum.ReviewsAnalytics]: 'ONBOARDING__RECOMMENDATION__REVIEWS_ANALYTICS__TITLE',
    [RecommendationsStepNameEnum.PositionTracker]: 'ONBOARDING__RECOMMENDATION__POSITION_TRACKER__TITLE',
    [RecommendationsStepNameEnum.PhotoAndVideoManager]: 'ONBOARDING__RECOMMENDATION__PHOTO_AND_VIDEO_MANAGER__TITLE',
    [RecommendationsStepNameEnum.Publications]: 'ONBOARDING__RECOMMENDATION__PUBLICATIONS__TITLE',
    [RecommendationsStepNameEnum.ReviewsGenerator]: 'ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__TITLE',
    [RecommendationsStepNameEnum.ReviewsGeneratorQr]: 'ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__TITLE',
    [RecommendationsStepNameEnum.RepliesToReviews]: 'ONBOARDING__RECOMMENDATION__REPLIES_TO_REVIEWS__TITLE',
    [RecommendationsStepNameEnum.NeuralNetwork]: 'ONBOARDING__RECOMMENDATION__NEURAL_NETWORK__TITLE',
};

export const recommendationDescriptionKeyMap: Record<RecommendationsStepNameEnum, LangKeyType> = {
    [RecommendationsStepNameEnum.AutoReviewTemplate]: 'ONBOARDING__RECOMMENDATION__AUTO_REVIEW_TEMPLATE__DESCRIPTION',
    [RecommendationsStepNameEnum.TagsForReview]: 'ONBOARDING__RECOMMENDATION__TAGS_FOR_REVIEW__DESCRIPTION',
    [RecommendationsStepNameEnum.SettingSources]: 'ONBOARDING__RECOMMENDATION__SETTING_SOURCES__DESCRIPTION',
    [RecommendationsStepNameEnum.ProductsAndPrices]: 'ONBOARDING__RECOMMENDATION__PRODUCTS_AND_PRICES__DESCRIPTION',
    [RecommendationsStepNameEnum.OnlinePresence]: 'ONBOARDING__RECOMMENDATION__ONLINE_PRESENCE__DESCRIPTION',
    [RecommendationsStepNameEnum.ReviewsAnalytics]: 'ONBOARDING__RECOMMENDATION__REVIEWS_ANALYTICS__DESCRIPTION',
    [RecommendationsStepNameEnum.PositionTracker]: 'ONBOARDING__RECOMMENDATION__POSITION_TRACKER__DESCRIPTION',
    [RecommendationsStepNameEnum.PhotoAndVideoManager]:
        'ONBOARDING__RECOMMENDATION__PHOTO_AND_VIDEO_MANAGER__DESCRIPTION',
    [RecommendationsStepNameEnum.Publications]: 'ONBOARDING__RECOMMENDATION__PUBLICATIONS__DESCRIPTION',
    [RecommendationsStepNameEnum.ReviewsGenerator]: 'ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__DESCRIPTION',
    [RecommendationsStepNameEnum.ReviewsGeneratorQr]: 'ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR_QR__DESCRIPTION',
    [RecommendationsStepNameEnum.RepliesToReviews]: 'ONBOARDING__RECOMMENDATION__REPLIES_TO_REVIEWS__DESCRIPTION',
    [RecommendationsStepNameEnum.NeuralNetwork]: 'ONBOARDING__RECOMMENDATION__NEURAL_NETWORK__DESCRIPTION',
};

export const recommendationButtonKeyMap: Record<RecommendationsStepNameEnum, LangKeyType> = {
    [RecommendationsStepNameEnum.AutoReviewTemplate]: 'ONBOARDING__RECOMMENDATION__AUTO_REVIEW_TEMPLATE__BUTTON',
    [RecommendationsStepNameEnum.TagsForReview]: 'ONBOARDING__RECOMMENDATION__TAGS_FOR_REVIEW__BUTTON',
    [RecommendationsStepNameEnum.SettingSources]: 'ONBOARDING__RECOMMENDATION__SETTING_SOURCES__BUTTON',
    [RecommendationsStepNameEnum.ProductsAndPrices]: 'ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON',
    [RecommendationsStepNameEnum.OnlinePresence]: 'ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON',
    [RecommendationsStepNameEnum.ReviewsAnalytics]: 'ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON',
    [RecommendationsStepNameEnum.PositionTracker]: 'ONBOARDING__RECOMMENDATION__POSITION_TRACKER__BUTTON',
    [RecommendationsStepNameEnum.PhotoAndVideoManager]: 'ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON',
    [RecommendationsStepNameEnum.Publications]: 'ONBOARDING__RECOMMENDATION__PUBLICATIONS__BUTTON',
    [RecommendationsStepNameEnum.ReviewsGenerator]: 'ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__BUTTON',
    [RecommendationsStepNameEnum.ReviewsGeneratorQr]: 'ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR_QR__BUTTON',
    [RecommendationsStepNameEnum.RepliesToReviews]: 'ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON',
    [RecommendationsStepNameEnum.NeuralNetwork]: 'ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON',
};

export const recommendationImageBackgroundKeyMap: Record<RecommendationsStepNameEnum, string> = {
    [RecommendationsStepNameEnum.AutoReviewTemplate]: templatesSrc,
    [RecommendationsStepNameEnum.TagsForReview]: tagsSrc,
    [RecommendationsStepNameEnum.SettingSources]: sourcesSrc,
    [RecommendationsStepNameEnum.ProductsAndPrices]: productAndPricesSrc,
    [RecommendationsStepNameEnum.OnlinePresence]: onlinePresenceSrc,
    [RecommendationsStepNameEnum.ReviewsAnalytics]: reviewsAnalyticsSrc,
    [RecommendationsStepNameEnum.PositionTracker]: positionTrackerSrc,
    [RecommendationsStepNameEnum.PhotoAndVideoManager]: photoAndVideoSrc,
    [RecommendationsStepNameEnum.Publications]: publicationsSrc,
    [RecommendationsStepNameEnum.ReviewsGenerator]: reviewsGeneratorSrc,
    [RecommendationsStepNameEnum.ReviewsGeneratorQr]: reviewsGeneratorSrc,
    [RecommendationsStepNameEnum.RepliesToReviews]: repliesToReviewsSrc,
    [RecommendationsStepNameEnum.NeuralNetwork]: neuralNetworkSrc,
};

export const recommendationNavigationLinkKeyMap: Record<RecommendationsStepNameEnum, string> = {
    [RecommendationsStepNameEnum.AutoReviewTemplate]: appRoute.reviewsManagementResponseTemplatesCreateTemplate.path,
    [RecommendationsStepNameEnum.TagsForReview]: appRoute.reviewsManagementTagCreate.path,
    [RecommendationsStepNameEnum.SettingSources]: appRoute.settingsSourceSettings.path,
    [RecommendationsStepNameEnum.ProductsAndPrices]: appRoute.products.path,
    [RecommendationsStepNameEnum.OnlinePresence]: appRoute.onlinePresenceDashboard.path,
    [RecommendationsStepNameEnum.ReviewsAnalytics]: appRoute.reviewsAnalysisDashboard.path,
    [RecommendationsStepNameEnum.PositionTracker]: appRoute.localPackRuleCreate.path,
    [RecommendationsStepNameEnum.PhotoAndVideoManager]: appRoute.photoToolIndex.path,
    [RecommendationsStepNameEnum.Publications]: appRoute.postsManagementCreatePost.path,
    [RecommendationsStepNameEnum.ReviewsGenerator]: appRoute.reviewsGeneratorMailingCreate.path,
    [RecommendationsStepNameEnum.ReviewsGeneratorQr]: appRoute.reviewsGeneratorQrCodeCreate.path,
    [RecommendationsStepNameEnum.RepliesToReviews]: appRoute.reviewsManagementReviews.path,
    [RecommendationsStepNameEnum.NeuralNetwork]: appRoute.reviewsManagementReviews.path,
};
