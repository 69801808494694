import {useCallback, useEffect} from 'react';

import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    deleteCompanyGroupById,
    getCompanyGroup,
    getCompanyGroupById,
    postCompanyGroup,
    putCompanyGroupById,
} from './company-group-api';
import {CompanyGroupType, PartialCompanyGroupType} from './company-group-type';

type CompanyGroupAdditionalType = {
    requestCompanyGroup: () => void;
    createCompanyGroup: (companyGroup: PartialCompanyGroupType) => Promise<void | CompanyGroupType>;
    putCompanyGroup: (companyGroup: CompanyGroupType) => Promise<void | CompanyGroupType>;
    deleteCompanyGroup: (companyGroupId: number) => Promise<void | CompanyGroupType>;
};

export function useCompanyGroup(
    option: RequestOptionsType,
    mainFilterKey = ''
): UseHookType<PaginatedResponseType<CompanyGroupType>> & CompanyGroupAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<CompanyGroupType>>();

    const {q, page, count} = option;

    const requestCompanyGroup = useCallback(() => {
        setIsInProgress(true);

        getCompanyGroup({q, page, count}, mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [q, page, count, mainFilterKey, setIsInProgress, setProcessError, setResult]);

    const createCompanyGroup = useCallback(
        (companyGroup: PartialCompanyGroupType) => {
            setIsInProgress(true);

            return postCompanyGroup(companyGroup)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError]
    );

    const putCompanyGroup = useCallback(
        (companyGroup: CompanyGroupType) => {
            setIsInProgress(true);

            return putCompanyGroupById(companyGroup.id, companyGroup)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError]
    );

    const deleteCompanyGroup = useCallback(
        (companyGroupId: number) => {
            setIsInProgress(true);

            return deleteCompanyGroupById(companyGroupId)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError]
    );

    useEffect(() => {
        requestCompanyGroup();
    }, [requestCompanyGroup]);

    return {
        isInProgress,
        processError,
        result,
        reset,
        requestCompanyGroup,
        createCompanyGroup,
        putCompanyGroup,
        deleteCompanyGroup,
    };
}

export function useCompanyGroupById(companyGroupId: string | number): UseHookType<CompanyGroupType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyGroupType>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanyGroupById(companyGroupId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyGroupId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
