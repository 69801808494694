import {Empty} from 'antd';
import {useMemo} from 'react';

import {LineChart} from '../../../../../../layout/chart/line-chart/line-chart';
import {defaultLineChartOptions} from '../../../../../../layout/chart/line-chart/line-chart-helper';
import {ChartJsLineOptionsType, LineDataType} from '../../../../../../layout/chart/line-chart/line-chart-type';
import {Spinner} from '../../../../../../layout/spinner/spinner';

import * as styles from './topic-line-chart.scss';

type PropsType = {
    data: LineDataType | null;
    isInProgress: boolean;
};

export function TopicLineChart(props: PropsType): JSX.Element {
    const {data, isInProgress} = props;

    const chartOptions: ChartJsLineOptionsType = useMemo<ChartJsLineOptionsType>(
        (): ChartJsLineOptionsType => ({
            ...defaultLineChartOptions,
            plugins: {
                ...defaultLineChartOptions.plugins,
                legend: {
                    display: false,
                },
            },
        }),
        []
    );

    if (data === null) {
        return (
            <div className={styles.TopicLineChart}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
        );
    }

    return (
        <div className={styles.TopicLineChart}>
            <Spinner isShow={isInProgress} position="absolute" />
            <LineChart data={data} options={chartOptions} />
        </div>
    );
}
