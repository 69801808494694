import {useQuery} from '@tanstack/react-query';

import {TipType} from '../../../page/main/account-sync/google/tips/tips-type';
import {useDocumentationLinks} from '../../../provider/help-links/help-links-hook';
import {Locale} from '../../../provider/locale/localization';
import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {GOOGLE_PAGE_SIZE} from '../google-const';
import {GoogleCclStatusEnum} from '../google-type';

import {
    fetchGoogleConfirmationCompanies,
    fetchGoogleConfirmationRequests,
    fetchGoogleRequestCompanies,
    getGoogleConfirmationCompaniesUrl,
    getGoogleConfirmationRequestsUrl,
    getGoogleRequestCompaniesUrl,
} from './google-confirmation-api';
import {
    GoogleConfirmationCompaniesHookType,
    GoogleConfirmationCompanyType,
    GoogleConfirmationRequestsHookType,
    GoogleConfirmationRequestStatusEnum,
    GoogleConfirmationRequestType,
    GoogleRequestCompaniesHookType,
} from './google-confirmation-type';

function useGoogleConfirmationRequestTips(requests: Array<GoogleConfirmationRequestType>): Array<TipType> {
    const documentationLinks = useDocumentationLinks();

    const tips: Array<TipType> = [
        {
            title: 'GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION' as const,
            text: <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION__DESCRIPTION" />,
            link: {href: documentationLinks.google.massConfirmation},
        },
    ];

    if (requests.some(({status}) => status === GoogleConfirmationRequestStatusEnum.Pending)) {
        tips.push({
            title: 'GOOGLE_SYNC__FINISH_STEP__STATUS__NEED_INFO' as const,
            text: <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__NEED_INFO__DESCRIPTION" />,
        });
    }

    return tips;
}

function useGoogleConfirmationCompaniesTips(confirmations: Array<GoogleConfirmationCompanyType>): Array<TipType> {
    const documentationLinks = useDocumentationLinks();

    const tips: Array<TipType> = [];

    if (confirmations.some((item) => item.status === GoogleCclStatusEnum.AwaitingConfirmation)) {
        tips.push({
            title: 'GOOGLE_SYNC__CONFIRMATIONS__STATUS__CODE_REQUESTED' as const,
            text: <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__CODE_REQUESTED__DESCRIPTION" />,
            link: {href: documentationLinks.google.confirmationTypes},
        });
    }

    if (confirmations.some((item) => item.status === GoogleCclStatusEnum.AwaitingRights)) {
        tips.push({
            title: 'GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS' as const,
            text: <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS__DESCRIPTION" />,
            link: {href: documentationLinks.google.requestRights},
        });
    }

    return tips;
}

export function useGoogleConfirmationCompanies(
    status: Array<GoogleCclStatusEnum>
): GoogleConfirmationCompaniesHookType {
    const pagination = usePagination({
        dependencies: null,
        shouldSaveState: false,
        initialPageSize: GOOGLE_PAGE_SIZE,
    });

    const {page, pageSize, refreshId, onDataLoaded} = pagination;
    const options = {page, count: pageSize, status: status.join(',')};

    const query = useQuery(
        [getGoogleConfirmationCompaniesUrl(options), refreshId],
        () => fetchGoogleConfirmationCompanies(options),
        {onSuccess: (result) => onDataLoaded({count: result.count}), keepPreviousData: true}
    );

    const tips = useGoogleConfirmationCompaniesTips(query.data?.results ?? []);

    return {...query, pagination, tips};
}

export function useGoogleConfirmationRequests(showCompleted?: boolean): GoogleConfirmationRequestsHookType {
    const pagination = usePagination({
        dependencies: null,
        shouldSaveState: false,
        initialPageSize: GOOGLE_PAGE_SIZE,
    });

    const {page, pageSize, refreshId, onDataLoaded} = pagination;
    const options = {page, count: pageSize, ...(showCompleted && {showCompleted})};

    const query = useQuery(
        [getGoogleConfirmationRequestsUrl(options), refreshId],
        () => fetchGoogleConfirmationRequests(options),
        {onSuccess: (result) => onDataLoaded({count: result.count}), keepPreviousData: true}
    );

    const tips = useGoogleConfirmationRequestTips(query.data?.results ?? []);

    return {...query, pagination, tips};
}

export function useGoogleRequestCompanies(requestId: number): GoogleRequestCompaniesHookType {
    const pagination = usePagination({
        dependencies: null,
        shouldSaveState: false,
        initialPageSize: GOOGLE_PAGE_SIZE,
    });

    const {page, pageSize, refreshId, onDataLoaded} = pagination;
    const options = {page, count: pageSize};

    const query = useQuery(
        [getGoogleRequestCompaniesUrl(requestId, options), refreshId],
        () => fetchGoogleRequestCompanies(requestId, options),
        {
            onSuccess: (result) => onDataLoaded({count: result.count}),
        }
    );

    return {...query, pagination};
}
