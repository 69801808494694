import {Chart, Plugin} from 'chart.js';

// based on https://stackoverflow.com/questions/42585861/chart-js-increase-spacing-between-legend-and-chart/67723827#67723827
export const legendMarginPlugin: Plugin = {
    id: 'legend-margin',
    beforeInit: (chart: Chart) => {
        if (chart.legend) {
            const originalFit = chart.legend.fit;

            // eslint-disable-next-line no-param-reassign
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 24;
            };
        }
    },
};
