import {z as r} from 'zod';

import {CompanyKeyEnum, lastCompanyKeys} from './company-const';
import {
    CompanyAddressFormType,
    companyAddressSchema,
    CompanyAddressServerType,
    CompanyAddressType,
} from './types/company-address-type';
import {
    CompanyAttributesFormType,
    CompanyAttributesServerType,
    CompanyAttributesType,
} from './types/company-attributes-type';
import {companyBrandSchema, CompanyBrandServerType, CompanyBrandType} from './types/company-brand-type';
import {
    CompanyCategoriesFormType,
    companyCategoriesSchema,
    CompanyCategoriesServerType,
    CompanyCategoriesType,
} from './types/company-categories-type';
import {
    companyContactsSchema,
    CompanyContactsServerType,
    CompanyContactsType,
    CompanyPhoneType,
} from './types/company-contacts-type';
import {
    companyDescriptionsSchema,
    CompanyDescriptionsServerType,
    CompanyDescriptionsType,
} from './types/company-descriptions-type';
import {
    CompanyGalleryFormType,
    companyGallerySchema,
    CompanyGalleryServerType,
    CompanyGalleryType,
} from './types/company-gallery-type';
import {
    CompanyLegalFormType,
    companyLegalSchema,
    CompanyLegalServerType,
    CompanyLegalType,
} from './types/company-legal-type';
import {
    CompanyLinksFormType,
    companyLinksSchema,
    CompanyLinksServerType,
    CompanyLinksType,
} from './types/company-links-type';
import {
    CompanyLogoFormType,
    companyLogoSchema,
    CompanyLogoServerType,
    CompanyLogoType,
} from './types/company-logo-type';
import {companyNamesSchema, CompanyNamesServerType, CompanyNamesType} from './types/company-names-type';
import {companyPaymentMethodsSchema, CompanyPaymentMethodsType} from './types/company-payment-methods-type';
import {companyPermissionsSchema, CompanyPermissionsType} from './types/company-permissions-type';
import {
    companyWorkingTimeSchema,
    CompanyWorkingTimeServerType,
    CompanyWorkingTimeType,
} from './types/company-working-time-type';

// can't use zod#extend because of TS2589 error
export const companySchema = r.object({
    ...companyNamesSchema,
    ...companyBrandSchema,
    ...companyAddressSchema,
    ...companyLegalSchema,
    ...companyCategoriesSchema,
    ...companyContactsSchema,
    ...companyLinksSchema,
    ...companyWorkingTimeSchema,
    ...companyLogoSchema,
    ...companyGallerySchema,
    ...companyDescriptionsSchema,
    ...companyPaymentMethodsSchema,
    ...companyPermissionsSchema,
    needModerate: r.boolean(),
    isDoubleGis: r.boolean(),
    attributes: r.unknown(),
    isPaidYandexPlacement: r.boolean(),
});

export type CompanyType = CompanyNamesType &
    CompanyBrandType &
    CompanyAddressType &
    CompanyLegalType &
    CompanyCategoriesType &
    CompanyContactsType &
    CompanyLinksType &
    CompanyWorkingTimeType &
    CompanyPaymentMethodsType &
    CompanyPermissionsType &
    CompanyDescriptionsType &
    CompanyLogoType &
    CompanyGalleryType &
    CompanyAttributesType & {
        [CompanyKeyEnum.NeedModerate]: boolean;
        [CompanyKeyEnum.IsDoubleGis]: boolean;
        [CompanyKeyEnum.IsPaidYandexPlacement]: boolean;
        [CompanyKeyEnum.YandexNeedActualization]: boolean;
    };

export type CompanyFormType = Omit<
    CompanyType,
    | keyof CompanyAddressType
    | keyof CompanyLegalType
    | keyof CompanyCategoriesType
    | keyof CompanyLinksType
    | keyof CompanyLogoType
    | keyof CompanyGalleryType
    | CompanyKeyEnum.Attributes
> &
    CompanyAddressFormType &
    CompanyLegalFormType &
    CompanyCategoriesFormType &
    CompanyLinksFormType &
    CompanyLogoFormType &
    CompanyGalleryFormType &
    CompanyAttributesFormType & {
        [CompanyKeyEnum.IsDoubleGis]: boolean;
        [CompanyKeyEnum.IsPaidYandexPlacement]: boolean;
        [CompanyKeyEnum.SubmitTimestamp]: string;
    };

export type CompanyServerType = CompanyNamesServerType &
    CompanyBrandServerType &
    CompanyAddressServerType &
    CompanyLegalServerType &
    CompanyCategoriesServerType &
    CompanyContactsServerType &
    CompanyLinksServerType &
    CompanyWorkingTimeServerType &
    CompanyPaymentMethodsType &
    CompanyDescriptionsServerType &
    CompanyLogoServerType &
    CompanyGalleryServerType &
    CompanyAttributesServerType;

export const lastCompanySchema = companySchema
    .pick(Object.fromEntries(lastCompanyKeys.map((key) => [key, true])))
    .passthrough();

export type LastCompanyType = Omit<r.infer<typeof lastCompanySchema>, 'attributes'> & CompanyType['attributes'];

export type CompanyGeneralErrorType = Array<string>;
export type CompanyListErrorType<T = CompanyGeneralErrorType> = CompanyGeneralErrorType | Record<number, T>;
export type CompanyInnerErrorType<T> = Partial<{[Key in keyof T]: CompanyGeneralErrorType}>;

type CompanyCatalogErrorType<T = CompanyListErrorType> =
    | CompanyGeneralErrorType
    | Record<number, CompanyGeneralErrorType | T>;

export type CompanyErrorType = Partial<{
    names: CompanyListErrorType<CompanyInnerErrorType<CompanyNamesType['names'][0]>>;
    extraNames: CompanyCatalogErrorType;
    searchNames: CompanyListErrorType;
    code: CompanyGeneralErrorType;
    brandId: CompanyGeneralErrorType;
    brandName: CompanyGeneralErrorType;
    address: CompanyInnerErrorType<CompanyAddressType['address']>;
    legal: CompanyInnerErrorType<CompanyLegalType['legal']>;
    rocketdataCategories: CompanyListErrorType;
    catalogCategories: CompanyCatalogErrorType;
    phones: CompanyListErrorType;
    extraPhones: CompanyCatalogErrorType;
    email: CompanyGeneralErrorType;
    extraEmails: CompanyCatalogErrorType;
    socialNetworks: CompanyListErrorType;
    extraSocialNetworks: CompanyCatalogErrorType;
    websites: CompanyListErrorType;
    extraWebsites: CompanyCatalogErrorType;
    workingHours: CompanyInnerErrorType<CompanyWorkingTimeType['workingHours']>;
    nonFieldErrors: CompanyInnerErrorType<{[CompanyKeyEnum.NeedModerate]: boolean}>;
    extraWorkingHours: CompanyCatalogErrorType<CompanyInnerErrorType<CompanyWorkingTimeType['workingHours']>>;
    specialHours: CompanyListErrorType<CompanyWorkingTimeType['specialHours'][0]>;
    descriptions: CompanyInnerErrorType<CompanyDescriptionsType['descriptions']>;
    extraDescriptions: CompanyCatalogErrorType<CompanyInnerErrorType<CompanyDescriptionsType['descriptions']>>;
    logo: CompanyGeneralErrorType;
    cover: CompanyGeneralErrorType;
    gallery: CompanyListErrorType;
    extraGallery: CompanyCatalogErrorType;
    attributes: {
        attributesGoogle?: CompanyCatalogErrorType;
        attributesYandex?: CompanyCatalogErrorType;
    };
    attributesGoogle?: CompanyCatalogErrorType;
    attributesYandex?: CompanyCatalogErrorType;
}>;

export type CompanyFormErrorType = Omit<CompanyErrorType, 'address' | 'legal' | 'phones' | 'extraPhones'> &
    Partial<{
        address: CompanyInnerErrorType<CompanyAddressFormType['address']>;
        legal: CompanyInnerErrorType<CompanyLegalFormType['legal']>;
        phones: CompanyListErrorType<CompanyInnerErrorType<Pick<CompanyPhoneType, 'phone'>>>;
        extraPhones: CompanyCatalogErrorType<CompanyInnerErrorType<Pick<CompanyPhoneType, 'phone'>>>;
    }>;

const relatedCompanyCatalogCategorySchema = r.object({
    catalogId: r.number(),
    categoryOriginId: r.string(),
    categoryName: r.string(),
});

export type RelatedCompanyCatalogCategoryType = r.infer<typeof relatedCompanyCatalogCategorySchema>;

export const relatedCompanyCatalogCategoriesSchema = r.array(relatedCompanyCatalogCategorySchema);

export const socialApiRegularSchema = r.array(r.object({id: r.number(), name: r.string(), regexp: r.string()}));
export type SocialApiRegularType = r.infer<typeof socialApiRegularSchema>;
