import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {useBrandValidationSettings} from '../../../../../../provider/brands/brand-validation-settings';
import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabIndex} from '../../catalog-list/catalog-list-helper';

import {CatalogLinks} from './catalog-links';
import {LinksFormItem} from './links-form-item/links-form-item';

const FIRST_WEBSITE_ACCESSOR = [CompanyKeyEnum.Websites, 0] as [CompanyKeyEnum.Websites, 0];

type PropsType = {
    name: string;
    hasInitialSocialNetworks: boolean;
    hasInitialWebsites: boolean;
    brandId?: number | null;
    initialCatalogs?: Array<number>;
};

export function LinksWidget(props: PropsType): JSX.Element {
    const {name, hasInitialSocialNetworks, hasInitialWebsites, initialCatalogs, brandId} = props;

    const {data: brandValidationSettings} = useBrandValidationSettings(brandId);

    const {getLocalizedString} = useLocale();

    const documentationLinks = useDocumentationLinks();

    return (
        <>
            <div>
                <LinksFormItem
                    hasInitialLinks={hasInitialWebsites}
                    label={getLocalizedString('COMPANY_FORM__LINKS__WEBSITES__LABEL')}
                    name={CompanyKeyEnum.Websites}
                    placeholder={getLocalizedString('COMPANY_FORM__LINKS__WEBSITES__PLACEHOLDER')}
                    required={!brandValidationSettings?.makeWebsiteOptionalField}
                />

                <LinksFormItem
                    hasInitialLinks={hasInitialSocialNetworks}
                    httpsOnly
                    label={getLocalizedString('COMPANY_FORM__LINKS__SOCIAL_NETWORKS__LABEL')}
                    name={CompanyKeyEnum.SocialNetworks}
                    placeholder={getLocalizedString('COMPANY_FORM__LINKS__SOCIAL_NETWORKS__PLACEHOLDER')}
                />
            </div>

            <Form.Item<CompanyFormType> dependencies={[FIRST_WEBSITE_ACCESSOR]} noStyle>
                {({getFieldValue, setFieldsValue, getFieldsError}) => (
                    <CatalogList
                        activeTabIndex={getErrorTabIndex(getFieldsError, [
                            CompanyKeyEnum.ExtraWebsites,
                            CompanyKeyEnum.ExtraSocialNetworks,
                        ])}
                        disabled={!getFieldValue(FIRST_WEBSITE_ACCESSOR)?.value}
                        hint={
                            <AdditionalInfo title={<Locale stringKey="COMPANY_FORM__LINKS__SOCIAL_NETWORKS__LABEL" />}>
                                <Locale
                                    stringKey="COMPANY_FORM__LINKS__SOCIAL_NETWORKS__HINT"
                                    valueMap={{
                                        link: documentationLinks && (
                                            <p>
                                                <a
                                                    href={documentationLinks.company.contactsFromCatalogs}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <Locale stringKey="GENERAL_INFO__HINT__SENDING_COMPANY_NAME__DETAILS" />
                                                </a>
                                            </p>
                                        ),
                                    }}
                                />
                            </AdditionalInfo>
                        }
                        initialCatalogs={initialCatalogs}
                        isCatalogVisible={({canRewriteWebsites, canRewriteSocialNetworks}) =>
                            canRewriteWebsites || canRewriteSocialNetworks
                        }
                        onAddCatalogs={(catalogs: Array<CatalogConfigType>) => {
                            const catalogIds = new Set(catalogs.map(({catalogId}) => catalogId));

                            setFieldsValue({
                                extraWebsites: [
                                    ...getFieldValue(CompanyKeyEnum.ExtraWebsites),
                                    ...catalogs.map(({catalogId}) => ({
                                        catalogId,
                                        websites: getFieldValue(CompanyKeyEnum.Websites),
                                    })),
                                ],
                                extraSocialNetworks: [
                                    ...(getFieldValue(CompanyKeyEnum.ExtraSocialNetworks) || []).filter(
                                        ({catalogId}) => !catalogIds.has(catalogId)
                                    ),
                                    ...catalogs.map(({catalogId}) => ({
                                        catalogId,
                                        socialNetworks: getFieldValue(CompanyKeyEnum.SocialNetworks),
                                    })),
                                ],
                            });
                        }}
                        onCatalogAvailabilityChange={(availableCatalogIds: Array<number>) => {
                            setFieldsValue({
                                extraWebsites: getFieldValue(CompanyKeyEnum.ExtraWebsites).filter(({catalogId}) =>
                                    availableCatalogIds.includes(catalogId)
                                ),
                                extraSocialNetworks: getFieldValue(CompanyKeyEnum.ExtraSocialNetworks).filter(
                                    ({catalogId}) => availableCatalogIds.includes(catalogId)
                                ),
                            });
                        }}
                        renderCatalog={(catalog: CatalogConfigWithIndexType, {handleRemoveCatalog}) => (
                            <CatalogLinks
                                catalog={catalog}
                                handleRemoveCatalog={handleRemoveCatalog}
                                hasInitialSocialNetworks={Boolean(
                                    getFieldValue([CompanyKeyEnum.ExtraSocialNetworks, catalog.index, 'socialNetworks'])
                                        ?.length
                                )}
                                hasInitialWebsites={Boolean(
                                    getFieldValue([CompanyKeyEnum.ExtraWebsites, catalog.index, 'websites'])?.length
                                )}
                                name={name}
                            />
                        )}
                        title={getLocalizedString('COMPANY_FORM__LINKS__CATALOGS_HEADER')}
                    />
                )}
            </Form.Item>
        </>
    );
}
