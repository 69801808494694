import {Locale} from '../../../../../../provider/locale/localization';

import {KeywordsAndTopicsFormTypePeriodEnum} from './keywords-and-topics-type';

export const PERIOD_OPTIONS = [
    {
        label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__DAY" />,
        value: KeywordsAndTopicsFormTypePeriodEnum.Day,
    },
    {
        label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__WEEK" />,
        value: KeywordsAndTopicsFormTypePeriodEnum.Week,
    },
    {
        label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__MONTH" />,
        value: KeywordsAndTopicsFormTypePeriodEnum.Month,
    },
    {
        label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__PREVIOUS_FULL_MONTH" />,
        value: KeywordsAndTopicsFormTypePeriodEnum.PreviousFullMonth,
    },
    {
        label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__QUARTER" />,
        value: KeywordsAndTopicsFormTypePeriodEnum.Quarter,
    },
    {
        label: <Locale stringKey="REVIEWS_ANALYSIS__FILTER__PERIOD__CUSTOM" />,
        value: KeywordsAndTopicsFormTypePeriodEnum.Custom,
    },
];

export const ANALYTICS_DELAY_DAYS = 2;
