import {useQuery} from '@tanstack/react-query';
import {useContext, useEffect, useMemo, useState} from 'react';

import {GoogleAccountSyncContext} from '../../page/main/account-sync/google/google-account-sync-context';
import {
    GoogleAccountSyncUrlParameterEnum,
    GoogleAccountSyncUrlType,
} from '../../page/main/account-sync/google/google-account-sync-type';
import {PROCESSING_STEP_REFETCH_INTERVAL} from '../../page/main/account-sync/google/steps/processing/processing-step-const';
import {useRefreshId} from '../../util/hook';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {useUrlQueryNumber} from '../../util/url-hook/url-hook';
import {useDebouncedValue} from '../debounce-hook/debounce-hook';

import {
    bulkBindingGoogleUrl,
    fetchGoogleCompanies,
    fetchGoogleCompaniesBindingProgress,
    fetchGoogleLocationsLoadingProgress,
    getGoogleCompaniesBindingProgressUrl,
    getGoogleLocationsLoadingProgressUrl,
    googleCompaniesUrl,
} from './google-api';
import {GOOGLE_PAGE_SIZE} from './google-const';
import {
    GoogleCompaniesHookOptionsType,
    GoogleCompaniesHookReturnType,
    GoogleProcessingCompaniesHookReturnType,
} from './google-type';
import {fetchGoogleProcessingKey} from './processing/google-processing-api';
import {googleProcessingLocationsLoadingStatuses} from './processing/google-processing-const';
import {GoogleProcessingStatusEnum} from './processing/google-processing-type';

export function useGoogleCompanies({
    search,
    brandsIds,
    onSuccess,
}: GoogleCompaniesHookOptionsType): GoogleCompaniesHookReturnType {
    const companyId = useUrlQueryNumber<GoogleAccountSyncUrlType>(GoogleAccountSyncUrlParameterEnum.CompanyId);
    const debouncedSearch = useDebouncedValue(search, '');

    const dependencies = useMemo(() => {
        return {search: debouncedSearch, brandsIds};
    }, [debouncedSearch, brandsIds]);

    const pagination = usePagination({dependencies, shouldSaveState: false, initialPageSize: GOOGLE_PAGE_SIZE});
    const {page, pageSize, onDataLoaded} = pagination;

    const queryOptions = useMemo(
        () => ({page, pageSize, search: debouncedSearch, brandsIds, companyId}),
        [brandsIds, companyId, debouncedSearch, page, pageSize]
    );

    const {data: companies, isInitialLoading} = useQuery(
        [googleCompaniesUrl, queryOptions],
        () => fetchGoogleCompanies(queryOptions),
        {
            keepPreviousData: true, // to make companies list keep consistent height after quickly changing filters
            onSuccess: (result) => {
                onDataLoaded(result);
                onSuccess?.(result, companyId);
            },
        }
    );

    return {...companies, isInitialLoading, pagination};
}

export function useGoogleProcessingCompanies(): GoogleProcessingCompaniesHookReturnType {
    const {status, processingKey, setStatus} = useContext(GoogleAccountSyncContext);

    const [onlyNeedAction, setOnlyNeedAction] = useState(false);
    const [refetchTimeoutId, setRefetchTimeoutId] = useState<number | null>(null);

    const {refresh, refreshId} = useRefreshId();
    const pagination = usePagination({dependencies: null, shouldSaveState: false, initialPageSize: GOOGLE_PAGE_SIZE});
    const {page, pageSize, onDataLoaded, onChange: onPaginationChange} = pagination;
    const offset = useMemo(() => (page > 1 ? pageSize * (page - 1) : 0), [page, pageSize]);

    const {refetch: refetchStatus} = useQuery([bulkBindingGoogleUrl], () => fetchGoogleProcessingKey(), {
        enabled: false,
        onSuccess: (data) => setStatus(data.status),
    });

    const locationsLoadingProgress = useQuery(
        [refreshId, getGoogleLocationsLoadingProgressUrl(processingKey)],
        () => fetchGoogleLocationsLoadingProgress(processingKey),
        {
            enabled: Boolean(status && googleProcessingLocationsLoadingStatuses.includes(status)),
            keepPreviousData: true, // keep the same values in progressbar while loading new data
            onSettled: () => {
                if (status !== GoogleProcessingStatusEnum.ProcessingFinished) {
                    refetchStatus();
                }
            },
            onSuccess: () => {
                if (status === GoogleProcessingStatusEnum.LocationsLoadingFailed) {
                    return;
                }

                setRefetchTimeoutId(window.setTimeout(() => refresh(), PROCESSING_STEP_REFETCH_INTERVAL));
            },
        }
    );

    const companiesBindingProgressOptions = useMemo(
        () => ({offset, limit: pageSize, refreshId, processingKey, onlyNeedAction}),
        [offset, onlyNeedAction, pageSize, processingKey, refreshId]
    );

    const companiesBindingProgress = useQuery(
        [status, getGoogleCompaniesBindingProgressUrl(companiesBindingProgressOptions)],
        () => fetchGoogleCompaniesBindingProgress(companiesBindingProgressOptions),
        {
            enabled: Boolean(status && !googleProcessingLocationsLoadingStatuses.includes(status)),
            keepPreviousData: true, // keep the same values in progressbar while loading new data
            onSettled: () => {
                if (status !== GoogleProcessingStatusEnum.ProcessingFinished) {
                    refetchStatus();
                }
            },
            onSuccess: (data) => {
                onDataLoaded({
                    count: data.processed + (status === GoogleProcessingStatusEnum.ProcessingFinished ? 0 : 1), // add 1 for skeleton
                });

                if (status === GoogleProcessingStatusEnum.ProcessingFinished) {
                    return;
                }

                setRefetchTimeoutId(window.setTimeout(() => refresh(), PROCESSING_STEP_REFETCH_INTERVAL));
            },
        }
    );

    const actionRequiredCompaniesOptions = useMemo(
        () => ({offset: 0, limit: pageSize, refreshId, processingKey, onlyNeedAction: true}),
        [pageSize, processingKey, refreshId]
    );

    const actionRequiredCompanies = useQuery(
        [getGoogleCompaniesBindingProgressUrl(actionRequiredCompaniesOptions)],
        () => fetchGoogleCompaniesBindingProgress(actionRequiredCompaniesOptions),
        {enabled: status === GoogleProcessingStatusEnum.ProcessingFinished}
    );

    useEffect(() => {
        if (onlyNeedAction) {
            onPaginationChange(1);
        }
    }, [onPaginationChange, onlyNeedAction]);

    useEffect(() => {
        function handleVisibilityChange() {
            if (document.visibilityState === 'hidden' && refetchTimeoutId) {
                window.clearInterval(refetchTimeoutId);
            }

            if (document.visibilityState === 'visible' && status !== GoogleProcessingStatusEnum.ProcessingFinished) {
                setRefetchTimeoutId(window.setTimeout(() => refresh(), PROCESSING_STEP_REFETCH_INTERVAL));
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [refetchTimeoutId, refresh, status]);

    useEffect(() => {
        return () => {
            if (refetchTimeoutId) {
                window.clearInterval(refetchTimeoutId);
            }
        };
    }, [refetchTimeoutId]);

    return {
        locationsLoadingProgress,
        companiesBindingProgress,
        actionRequiredCompaniesLength: actionRequiredCompanies.data?.total ?? 0,
        pagination,
        onlyNeedAction,
        setOnlyNeedAction,
    };
}
