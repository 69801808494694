import {Button, Modal, Radio, Typography} from 'antd';
import {useEffect, useMemo, useState} from 'react';

import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {googleConfirmationApi} from '../../../../../service/confirmation/confirmation-api';
import {useGoogleConfirmationMethods} from '../../../../../service/confirmation/confirmation-hook';
import {ConfirmationSourcesMethodEnum} from '../../../../../service/confirmation/confirmation-type';
import {getConfirmationMethodTranslationKey} from '../confirmations-const';

import * as styles from './get-code.scss';

const {Text} = Typography;

type PropsType = {
    hash?: string;
    brandId: number;
    companyId: number;
    catalogId: string | null;
    permanentId: string;
    onSubmitSuccess: () => void;
    buttonTextKey?: LangKeyType;
};

export function GoogleGetCode(props: PropsType): JSX.Element {
    const {
        hash,
        brandId,
        companyId,
        catalogId,
        permanentId,
        onSubmitSuccess,
        buttonTextKey = 'SOURCES_CONFIRMATION__BUTTON__GET_CODE',
    } = props;

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const {result, loadConfirmationMethodsByHash, loadVerificationMethods, processError, isInProgress} =
        useGoogleConfirmationMethods();

    const [method, setMethod] = useState<ConfirmationSourcesMethodEnum | null>(null);
    const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

    const availableMethods = useMemo(() => {
        if (
            result &&
            result.some((confirmationMethod) => confirmationMethod.method === ConfirmationSourcesMethodEnum.RightsUrl)
        ) {
            return [ConfirmationSourcesMethodEnum.RightsUrl];
        }

        return result?.map((resultItem) => resultItem.method) || [];
    }, [result]);

    const details = useMemo(() => {
        if (!method) {
            return '';
        }

        return result?.find((resultItem) => resultItem.method === method)?.detail || '';
    }, [method, result]);

    useEffect(() => {
        if (isModalOpen) {
            if (hash) {
                loadConfirmationMethodsByHash(hash);
            } else {
                loadVerificationMethods({
                    companyId,
                    brandId,
                    placeId: Number(permanentId.match(/\d+/)),
                });
            }
        }
    }, [brandId, companyId, hash, isModalOpen, loadConfirmationMethodsByHash, loadVerificationMethods, permanentId]);

    useEffect(() => {
        if (result && !method) {
            setMethod(result[0]?.method || null);
        }
    }, [method, result]);

    async function handleSubmit() {
        if (method) {
            setIsSendingCode(true);

            try {
                await googleConfirmationApi({
                    brand: brandId,
                    company: companyId,
                    catalogId: catalogId || permanentId,
                    permanentId,
                    method:
                        method === ConfirmationSourcesMethodEnum.RightsUrl
                            ? ConfirmationSourcesMethodEnum.Link
                            : method,
                    value:
                        method === ConfirmationSourcesMethodEnum.RightsUrl
                            ? details.split('/').slice(-1)[0] ?? ''
                            : details,
                });

                if (method === ConfirmationSourcesMethodEnum.RightsUrl) {
                    window.open(details, '_blank');
                } else {
                    onSubmitSuccess();
                    snackbar.success(<Locale stringKey="SOURCES_CONFIRMATION__SEND_CODE__SUCCESS" />);
                }
            } catch {
                snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
            } finally {
                setIsSendingCode(false);
                setIsModalOpen(false);
            }
        }
    }

    return (
        <>
            <Button
                onClick={() => {
                    setIsModalOpen(true);
                }}
                type="primary"
            >
                <Locale stringKey={buttonTextKey} />
            </Button>
            <Modal
                okButtonProps={{loading: isInProgress || !method || isSendingCode}}
                okText={getLocalizedString(
                    method !== ConfirmationSourcesMethodEnum.RightsUrl
                        ? 'SOURCES_CONFIRMATION__BUTTON__GET_CODE'
                        : 'SOURCES_CONFIRMATION__METHOD__RIGHTS_URL'
                )}
                onCancel={() => setIsModalOpen(false)}
                onOk={handleSubmit}
                open={isModalOpen}
                title={<Locale stringKey="SOURCES_CONFIRMATION__GOOGLE__GET_CONFIRM_CODE_TITLE" />}
            >
                {isInProgress && <Spinner />}
                {processError && <AlertFallback />}
                {availableMethods.length > 0 && method && (
                    <div className={styles.GetCode__modal_content}>
                        <Text>
                            <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__DESCRIPTION" />
                        </Text>
                        <Text>
                            <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__SELECT_METHOD" />
                        </Text>

                        <Radio.Group
                            onChange={(event) => {
                                setMethod(event.target.value);
                            }}
                            value={method}
                        >
                            {availableMethods.map((availableMethod: ConfirmationSourcesMethodEnum) => {
                                const translationKey = getConfirmationMethodTranslationKey(availableMethod);

                                return (
                                    <Radio.Button key={availableMethod} value={availableMethod}>
                                        {translationKey ? <Locale stringKey={translationKey} /> : availableMethod}
                                    </Radio.Button>
                                );
                            })}
                        </Radio.Group>

                        {method === ConfirmationSourcesMethodEnum.Address && (
                            <>
                                <Text>
                                    <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__ADDRESS__SEND_TO_CURRENT_ADDRESS" />
                                </Text>
                                <Text type="danger">
                                    <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__ADDRESS__DELIVERY_TIME" />
                                </Text>
                            </>
                        )}

                        {method !== ConfirmationSourcesMethodEnum.RightsUrl && <Text strong>{details}</Text>}
                    </div>
                )}
            </Modal>
        </>
    );
}
