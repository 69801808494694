import {Text} from '../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {NpsChart} from '../../../../../../layout/chart/progress-chart/nps-chart';
import {ProgressChart} from '../../../../../../layout/chart/progress-chart/progress-chart';
import {Locale} from '../../../../../../provider/locale/locale';
import {CompanyReviewsSummaryType} from '../../../../../../service/company-activity-and-summary/company-activity-and-summary-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import {ReviewsSummaryMainMetricsItem} from './reviews-summary-main-metrics-item';
import * as styles from './reviews-summary-main-metrics.scss';

type PropsType = {
    data: CompanyReviewsSummaryType;
};

export function ReviewsSummaryMainMetrics(props: PropsType): JSX.Element {
    const {data} = props;

    const {getFormattedNumber} = useFormat();

    const npsPercent = data.nps * 100;
    const noAnswersPercent = data.count ? (data.no_answer_count / data.count) * 100 : 0;
    const dataPositivityPercent = data.positive_percent * 100;

    return (
        <div>
            <div className={styles.ReviewsSummaryMainMetrics}>
                <ReviewsSummaryMainMetricsItem
                    title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__TOTAL_REVIEWS" />}
                    value={getFormattedNumber(data.count)}
                />
                <ReviewsSummaryMainMetricsItem
                    subValue={`(${data.day_count_max})`}
                    title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__DAY_AVERAGE" />}
                    value={getFormattedNumber(data.day_count_avg, {
                        maximumFractionDigits: 2,
                    })}
                />
                <ReviewsSummaryMainMetricsItem
                    title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__SOURCES" />}
                    value={getFormattedNumber(data.catalogs_statistic.length)}
                />
            </div>
            <div className={styles.ReviewsSummaryMainMetrics}>
                <ReviewsSummaryMainMetricsItem
                    chart={
                        <ProgressChart
                            className={styles.ReviewsSummaryMainMetrics_chartWrapper}
                            highValueThreshold={50}
                            inverseRelationship
                            mediumValueThreshold={50}
                            value={noAnswersPercent}
                        />
                    }
                    title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__WITHOUT_ANSWER" />}
                    value={getFormattedNumber(data.no_answer_count)}
                />
                <ReviewsSummaryMainMetricsItem
                    chart={<NpsChart className={styles.ReviewsSummaryMainMetrics_chartWrapper} value={npsPercent} />}
                    title={
                        <AdditionalInfo
                            leftNode={<Text secondary stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__NPS" />}
                            placement="bottomRight"
                            title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__NPS" />}
                        >
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__NPS_POPOVER_CONTENT" />
                        </AdditionalInfo>
                    }
                    value={getFormattedNumber(data.nps, {
                        maximumFractionDigits: 0,
                        style: 'percent',
                    })}
                />
                <ReviewsSummaryMainMetricsItem
                    chart={
                        <ProgressChart
                            className={styles.ReviewsSummaryMainMetrics_chartWrapper}
                            highValueThreshold={75}
                            mediumValueThreshold={50}
                            value={dataPositivityPercent}
                        />
                    }
                    title={
                        <AdditionalInfo
                            leftNode={
                                <Text
                                    className={styles.ReviewsSummaryMainMetrics_text}
                                    secondary
                                    stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__POSITIVITY"
                                />
                            }
                            placement="bottomRight"
                            title={
                                <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__POSITIVITY_POPOVER_TITLE" />
                            }
                        >
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__POSITIVITY_POPOVER_CONTENT" />
                        </AdditionalInfo>
                    }
                    value={getFormattedNumber(data.positive_percent, {
                        style: 'percent',
                        maximumFractionDigits: 1,
                    })}
                />
            </div>
        </div>
    );
}
