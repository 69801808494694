import {faRefresh} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tag, Tooltip} from 'antd';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {Text} from '../../../../../component/text/text';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {PostFormFieldsEnum, PostListItemType} from '../../../../../service/posts/posts-types';

import {CANT_USE_POST_DETAILS_STATUSES} from './post-name-const';
import * as styles from './post-name.scss';

type PropsType = {
    data: PostListItemType;
};

export function PostName(props: PropsType): JSX.Element {
    const {data} = props;

    const {getLocalizedString} = useLocale();

    const isShowLabel = data[PostFormFieldsEnum.AutoRefreshCount] && data[PostFormFieldsEnum.AutoRefreshFrequency];

    const tagContent = isShowLabel ? (
        <Tooltip title={getLocalizedString('POSTS__TABLE__AUTO_REFRESH_TAG')}>
            <Tag bordered={false} className={styles.PostName_tag}>
                <FontAwesomeIcon icon={faRefresh} />
            </Tag>
        </Tooltip>
    ) : null;

    if (CANT_USE_POST_DETAILS_STATUSES.includes(data.status)) {
        return (
            <div className={styles.PostName}>
                {tagContent}
                <Text>{data.name}</Text>
            </div>
        );
    }

    return (
        <NavigationLink to={generatePath(appRoute.postsManagementPostDetails.path, {postId: data.id})}>
            <Button className={styles.PostName} type="link">
                {tagContent}
                {data.name}
            </Button>
        </NavigationLink>
    );
}
