import {Row} from 'antd';

import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/localization';

export function LinkCompanyLabel(): JSX.Element {
    return (
        <Row>
            <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__LABEL" />
            &nbsp;
            <AdditionalInfo
                title={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__HINT_TITLE" />}
            >
                <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__HINT" />
            </AdditionalInfo>
        </Row>
    );
}
