import {useCallback, useEffect, useMemo, useState} from 'react';

import {TimezoneType} from '../../../../service/api/api-type';
import {useTimezoneLibrary} from '../../../../service/api-hook/api-hook';
import {UseHookType} from '../../../../service/api-hook/api-hook-type';
import {ProfileType} from '../../../../service/profile/profile-type';
import {findInArrayEnsure} from '../../../../util/array';

import {defaultTimezone} from './personal-information-const';

type UseProfileTimezoneType = {
    timezoneLibrary: UseHookType<Array<TimezoneType>>;
    timezoneList: Array<TimezoneType>;
    timezone: TimezoneType;
    findTimezoneAndUpdate: (id: string) => void;
};

export function useProfileTimezone(profile: ProfileType | null): UseProfileTimezoneType {
    const timezoneLibrary = useTimezoneLibrary();
    const [timezone, setTimezone] = useState<TimezoneType>(defaultTimezone);

    const timezoneList: Array<TimezoneType> = useMemo(() => timezoneLibrary.result || [], [timezoneLibrary]);

    const findTimezoneAndUpdate = useCallback(
        (newTimezoneName: string): void => {
            const selectedTimezone: TimezoneType = findInArrayEnsure<TimezoneType>(
                timezoneList,
                {name: newTimezoneName},
                defaultTimezone
            );

            setTimezone(selectedTimezone);
        },
        [setTimezone, timezoneList]
    );

    useEffect(() => {
        if (profile !== null && profile.profile.timezone !== null) {
            findTimezoneAndUpdate(profile.profile.timezone);
        }
    }, [profile, findTimezoneAndUpdate]);

    return {timezoneLibrary, timezoneList, timezone, findTimezoneAndUpdate};
}
