import {useMemo} from 'react';

import {classNames} from '../../../util/css';
import {LineChart} from '../../chart/line-chart/line-chart';
import {LineDataType} from '../../chart/line-chart/line-chart-type';

import {getTrendChartOptions, trendFillColor, trendStrokeColor} from './trend-cell-helper';
import * as styles from './trend-cell.scss';

type PropsType = {
    values: Array<number>;
    labels?: Array<string>;
    datasetLabel?: string;
    className?: string;
    strokeColor?: string;
    fillColor?: string;
    withoutAnimation?: boolean;
};

export function TrendCell(props: PropsType): JSX.Element {
    const {
        values,
        labels,
        datasetLabel,
        className,
        strokeColor = trendStrokeColor,
        fillColor = trendFillColor,
        withoutAnimation = false,
    } = props;

    const chartData: LineDataType = useMemo(() => {
        return {
            labels: labels || values.map(() => ''),
            datasets: [
                {
                    values,
                    color: fillColor,
                    borderColor: strokeColor,
                    fill: true,
                    label: datasetLabel || '',
                    tension: 0,
                },
            ],
        };
    }, [datasetLabel, fillColor, labels, strokeColor, values]);

    const options = getTrendChartOptions(chartData);

    if (withoutAnimation) {
        options.animation = false;
    }

    return (
        <div className={styles.TrendCell}>
            <div className={classNames(styles.TrendCell_chart, className)}>
                <LineChart data={chartData} options={options} />
            </div>
        </div>
    );
}
