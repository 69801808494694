import {mapValues} from 'lodash';

import {DomainNameEnum} from '../../provider/domain-config/domain-config-type';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';

import {LegalAgreementTypeEnum} from './legal-agreement-type';

export const policyLinksByDomain: Record<
    string,
    Record<LegalAgreementTypeEnum, Record<ShortLocaleNameEnum, string>>
> = {
    [DomainNameEnum.rocketData]: {
        [LegalAgreementTypeEnum.Personal]: {
            ...mapValues(ShortLocaleNameEnum, () => 'https://rocketdata.io/privacy'),
            [ShortLocaleNameEnum.ru]:
                'https://rocketdata.ru/consent-registration?_ga=2.185844011.342534258.1626168772-1102103654.1626168772',
        },
        [LegalAgreementTypeEnum.Public]: {
            ...mapValues(ShortLocaleNameEnum, () => 'https://rocketdata.io/terms'),
            [ShortLocaleNameEnum.ru]:
                'https://rocketdata.ru/terms_and_conditions?_ga=2.185844011.342534258.1626168772-1102103654.1626168772',
        },
    },
    [DomainNameEnum.brandWizard]: {
        [LegalAgreementTypeEnum.Personal]: {
            ...mapValues(ShortLocaleNameEnum, () => 'https://brandwizard.io/privacy'),
        },
        [LegalAgreementTypeEnum.Public]: {
            ...mapValues(ShortLocaleNameEnum, () => 'https://brandwizard.io/terms'),
        },
    },
};
