import {z as r} from 'zod';

import {CursorPaginationResponseType} from '../../service/api/api-type';

export type CursorPaginationHookOptionsType = {
    dependencies: unknown;
    defaultPageSize?: number;
    shouldSaveState?: boolean;
};

export type CursorPaginationHookType = {
    pageSize: number;
    previousPageCursor: string;
    nextPageCursor: string;
    previousPage: number;
    nextPage: number;
    resultPage: number;
    page: number;
    cursor: string;
    onDataLoaded: (result: CursorPaginationResponseType<unknown>) => void;
    onDataLoadFailed: () => void;
    updatePageSize: (newPageSize: number) => void;
    changePage: (newCursor: string) => void;
    changePagePagination: (newPage: number) => void;
    changeResultPage: (newPage: number) => void;
    refreshId: string;
    refresh: VoidFunction;
};

export type CursorPaginationType = {
    pagination: CursorPaginationHookType;
    lastPage?: number;
};

const cursorPaginationDataSchema = r.object({
    cursor: r.string(),
    pageSize: r.number(),
});

export const cursorPaginationStorageDataSchema = cursorPaginationDataSchema.merge(
    r.object({
        storageKey: r.string(),
    })
);

export type CursorPaginationDataType = r.infer<typeof cursorPaginationDataSchema>;

export type CursorPaginationStorageType = r.infer<typeof cursorPaginationStorageDataSchema>;
