import {signKey} from '../hmac-sha256';

type CarrotQuestType = {
    auth: (userId: string, hash: string) => void;
};

export function carrotQuestAuthenticateUser(userId: string): void {
    try {
        const hash = signKey(process.env.CARROT_QUEST_USER_AUTH_KEY || '', userId);
        const carrotquest = window.carrotquest as CarrotQuestType;

        carrotquest.auth(userId, hash);
    } catch (error) {
        console.error(error);
    }
}
