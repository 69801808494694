import {Context, createContext, PropsWithChildren, useCallback, useEffect, useState} from 'react';

import {debounce} from '../../util/function';

import {getSystemState} from './system-context-helper';
import {SystemContextType} from './system-context-type';

const defaultSystemContextData = getSystemState();

export const SystemContext: Context<SystemContextType> = createContext<SystemContextType>(defaultSystemContextData);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function SystemProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const [providedData, setProvidedData] = useState<SystemContextType>(() => getSystemState());

    const handleResize = useCallback(
        function handleResizeInner(): void {
            const {isWindowLoaded, screen} = providedData;
            const {width, height} = screen;
            const systemState = getSystemState();

            if (systemState.screen.width !== width || systemState.screen.height !== height) {
                setProvidedData({...systemState, isWindowLoaded});
            }
        },
        [providedData, setProvidedData]
    );

    const handleWindowLoad = useCallback(
        function handleWindowLoadInner(): void {
            const systemState = getSystemState();

            setProvidedData({...systemState, isWindowLoaded: true});

            window.removeEventListener('load', handleWindowLoad, false);
        },
        [setProvidedData]
    );

    useEffect(() => {
        const userAgent = navigator.userAgent;

        const systemState = getSystemState();
        const browsers = {...systemState.browsers};

        switch (true) {
            case userAgent.includes('Firefox'): {
                // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
                browsers.isFirefox = true;
                break;
            }
            case userAgent.includes('SamsungBrowser'): {
                // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
                browsers.isSamsungBrowser = true;
                break;
            }
            case userAgent.includes('Opera') || userAgent.includes('OPR'): {
                // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
                browsers.isOpera = true;
                break;
            }
            case userAgent.includes('Edge'): {
                // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
                browsers.isEdgeLegacy = true;
                break;
            }
            case userAgent.includes('Edg'): {
                // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
                browsers.isEdge = true;
                break;
            }
            case userAgent.includes('Chrome'): {
                // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
                browsers.isChrome = true;
                break;
            }
            case userAgent.includes('Safari'): {
                // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
                browsers.isSafari = true;
                break;
            }

            default:
                break;
        }

        setProvidedData({...systemState, browsers});
    }, []);

    useEffect(() => {
        const handleResizeDebounced = debounce<[]>(handleResize, 150);

        window.addEventListener('resize', handleResizeDebounced, {capture: false, passive: true});
        window.addEventListener('load', handleWindowLoad, false);

        return () => {
            window.removeEventListener('resize', handleResizeDebounced, {capture: false});
            window.removeEventListener('load', handleWindowLoad, false);
        };
    }, [setProvidedData, handleResize, handleWindowLoad]);

    return <SystemContext.Provider value={providedData}>{children}</SystemContext.Provider>;
}
