import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {getInitialLocalPackFilter} from '../../page/main/local-pack/local-pack-filter/local-pack-filter-helper';
import {
    LocalPackFilterEnum,
    LocalPackFilterQueriesType,
    LocalPackFilterType,
    LocalPackFilterUrlQueryNameEnum,
    SetLocalPackFilterType,
    UseLocalPackFilterType,
} from '../../page/main/local-pack/local-pack-filter/local-pack-filter-type';
import {LocalPackListResultsOptionsType} from '../../page/main/local-pack/local-pack-results-table/local-pack-results-table-type';
import {LocalPackAggregationTypeEnum} from '../../page/main/local-pack/position-distribution/position-distribution-type';
import {LocalPackListQueryResultsOptionsType} from '../../page/main/local-pack/query-results-table/query-result-table-type';
import {MainFilterContext} from '../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../provider/main-filter/main-filter-type';
import {getIsoYyyyMmDdString} from '../../util/date';
import {PartialNullableType} from '../../util/type';
import {useUrl} from '../../util/url-hook/url-hook';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    fetchLocalPackCompanyInfo,
    fetchLocalPackGrid,
    fetchLocalPackMinimalAggregation,
    fetchLocalPackPositionDistribution,
    fetchLocalPackQueryResults,
    fetchLocalPackResults,
    fetchLocalPackSearchQueries,
    fetchLocalPackSearchResults,
    fetchSearchServiceConfigs,
} from './local-pack-api';
import {
    LocalPackCompanyInfoType,
    LocalPackPositionDistributionResponseType,
    LocalPackPositionDistributionType,
} from './local-pack-api-type';
import {
    LocalPackDataTableResultsResponseType,
    LocalPackGridType,
    LocalPackMinimalAggregationResponseType,
    LocalPackQueryResultsResponseType,
    LocalPackSearchGridOptionsType,
    LocalPackSearchQueryType,
    SearchResultItemType,
    SearchServiceQueriesType,
} from './local-pack-type';

export function useLocalPackListResults(
    options: LocalPackListResultsOptionsType
): UseHookType<LocalPackDataTableResultsResponseType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {paginationOptions, filter} = options;
    const {count, page} = paginationOptions;
    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<LocalPackDataTableResultsResponseType>();

    const loadLocalPackResults = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        return fetchLocalPackResults({
            paginationOptions: {pageSize: count, page},
            filter,
            mainFilterKey,
        })
            .then((response: LocalPackDataTableResultsResponseType) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [count, mainFilterKey, page, setIsInProgress, setProcessError, setResult, filter]);

    useEffect(() => {
        loadLocalPackResults();
    }, [loadLocalPackResults]);

    return {isInProgress, processError, result, reset};
}

export function useLocalPackSearchServiceConfigs(companyId: number): UseHookType<Array<SearchServiceQueriesType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<Array<SearchServiceQueriesType>>();

    const loadSearchServiceConfigs = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        return fetchSearchServiceConfigs(companyId)
            .then((response: Array<SearchServiceQueriesType>) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadSearchServiceConfigs();
    }, [loadSearchServiceConfigs]);

    return {isInProgress, processError, result, reset};
}

export function useLocalPackCompanyInfo(companyId: number): UseQueryResult<LocalPackCompanyInfoType> {
    return useQuery(['local-pack-company-info', companyId], () => fetchLocalPackCompanyInfo(companyId));
}

export function useLocalPackGridData(
    props: PartialNullableType<LocalPackSearchGridOptionsType, 'searchQueryId' | 'searchServiceId'>
): UseHookType<LocalPackGridType> {
    const {companyId, searchQueryId, searchServiceId, targetDate} = props;
    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<LocalPackGridType>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        if (searchQueryId && searchServiceId) {
            fetchLocalPackGrid({
                companyId,
                searchQueryId,
                searchServiceId,
                targetDate,
            })
                .then((response: LocalPackGridType) => {
                    setResult(response);
                })
                .finally(() => setIsInProgress(false))
                .catch((error) => {
                    setProcessError(error);
                });
        }
    }, [companyId, searchQueryId, searchServiceId, setIsInProgress, setProcessError, setResult, targetDate]);

    return {isInProgress, processError, result, reset};
}

type UseLocalPackPositionDistributionAdditionalType = {
    loadLocalPackPositionDistribution: (options: {
        searchServices: Array<number>;
        filter: LocalPackFilterType;
        aggregation: LocalPackAggregationTypeEnum;
    }) => Promise<LocalPackPositionDistributionType | void>;
};

export function useLocalPackPositionDistribution(): UseHookType<LocalPackPositionDistributionType> &
    UseLocalPackPositionDistributionAdditionalType {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<LocalPackPositionDistributionType>();

    const loadLocalPackPositionDistribution = useCallback(
        (options: {
            searchServices: Array<number>;
            filter: LocalPackFilterType;
            aggregation: LocalPackAggregationTypeEnum;
        }) => {
            setIsInProgress(true);
            setProcessError(null);
            return fetchLocalPackPositionDistribution({...options, mainFilterKey})
                .then((response: LocalPackPositionDistributionResponseType) => {
                    setResult({
                        labels: response.labels || [],
                        result: response.result || [],
                    });
                })
                .finally(() => setIsInProgress(false))
                .catch((error) => {
                    setProcessError(error);
                });
        },
        [mainFilterKey, setIsInProgress, setProcessError, setResult]
    );

    return {isInProgress, processError, result, reset, loadLocalPackPositionDistribution};
}

export function useLocalPackSearchResults(
    searchQueryId: number,
    pointId: number
): UseHookType<Array<SearchResultItemType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<Array<SearchResultItemType>>();

    const loadLocalPackSearchResults = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        return fetchLocalPackSearchResults(searchQueryId, pointId)
            .then((response: Array<SearchResultItemType>) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [searchQueryId, pointId, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadLocalPackSearchResults();
    }, [loadLocalPackSearchResults]);

    return {isInProgress, processError, result, reset};
}

export function useLocalPackMinimalAggregation(): UseHookType<LocalPackMinimalAggregationResponseType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<LocalPackMinimalAggregationResponseType>();

    const loadLocalPackMinimalAggregation = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        return fetchLocalPackMinimalAggregation(mainFilterKey)
            .then((response: LocalPackMinimalAggregationResponseType) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [mainFilterKey, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadLocalPackMinimalAggregation();
    }, [loadLocalPackMinimalAggregation]);

    return {isInProgress, processError, result, reset};
}

export function useLocalPackListQueryResults(
    options: LocalPackListQueryResultsOptionsType
): UseHookType<LocalPackQueryResultsResponseType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {paginationOptions, filter} = options;
    const {count, page} = paginationOptions;
    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<LocalPackQueryResultsResponseType>();

    const loadLocalPackQueryResults = useCallback(() => {
        setIsInProgress(true);
        setProcessError(null);
        return fetchLocalPackQueryResults({
            paginationOptions: {pageSize: count, page},
            filter,
            mainFilterKey,
        })
            .then((response: LocalPackQueryResultsResponseType) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [count, mainFilterKey, page, setIsInProgress, setProcessError, setResult, filter]);

    useEffect(() => {
        loadLocalPackQueryResults();
    }, [loadLocalPackQueryResults]);

    return {isInProgress, processError, result, reset};
}

export function useLocalPackFilter(): UseLocalPackFilterType {
    const {setQuery, queries} = useUrl<LocalPackFilterQueriesType>();

    const initialLocalPackFilter = getInitialLocalPackFilter(queries);

    const [filter, setFilter] = useState<LocalPackFilterType>(initialLocalPackFilter);

    const setFilterWrapped = useCallback<SetLocalPackFilterType>(
        (filterPartial) => {
            const resultFilter: LocalPackFilterType = {...filter, ...filterPartial};
            const [dateFrom, dateTo] = resultFilter[LocalPackFilterEnum.timeRange];

            setFilter(resultFilter);

            const dateFromQueryValue = dateFrom ? getIsoYyyyMmDdString(dateFrom.toDate()) : '';
            const dateToQueryValue = dateTo ? getIsoYyyyMmDdString(dateTo.toDate()) : '';

            setQuery({
                [LocalPackFilterUrlQueryNameEnum.startDate]: dateFromQueryValue,
                [LocalPackFilterUrlQueryNameEnum.endDate]: dateToQueryValue,
                [LocalPackFilterUrlQueryNameEnum.searchQueries]: resultFilter.searchQueries.join(','),
            });
        },

        [filter, setFilter, setQuery]
    );

    const filterQueries: LocalPackFilterQueriesType = useMemo(() => {
        return Object.fromEntries(
            Object.values(LocalPackFilterUrlQueryNameEnum).map((currentValue) => [currentValue, queries[currentValue]])
        );
    }, [queries]);

    return {
        filter,
        setFilter: setFilterWrapped,
        filterQueries,
    };
}

export function useLocalPackSearchQueries(): UseQueryResult<Array<LocalPackSearchQueryType>> {
    return useQuery(['local-pack', 'search-queries'], fetchLocalPackSearchQueries);
}
