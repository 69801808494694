import {ItemType} from 'antd/es/menu/interface';
import {useCallback} from 'react';

import {UserType} from '../user/user-type';

import {AnalyticsDataType, AnalyticsTarget} from './analytics';
import {initializeGoogleAnalytics, trackNavigationGoogleAnalytics} from './analytics-helper';
import {RecursiveValueOfType, UseAnalyticsType} from './analytics-type';

export function useAnalytics(): UseAnalyticsType {
    const initialize = useCallback((user: UserType) => {
        initializeGoogleAnalytics(user);
    }, []);

    const track = useCallback(
        <TKey extends RecursiveValueOfType<typeof AnalyticsTarget>>(
            ...args: TKey extends keyof AnalyticsDataType ? [key: TKey, data: AnalyticsDataType[TKey]] : [key: TKey]
        ) => {
            track(...args);
        },
        []
    );

    const trackNavigation = useCallback((_pathname: string, previousPathname: string, menuItems: Array<ItemType>) => {
        trackNavigationGoogleAnalytics(_pathname, previousPathname, menuItems);
    }, []);

    return {initialize, trackNavigation, track};
}
