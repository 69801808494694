import {faMapMarkerCheck} from '@fortawesome/pro-solid-svg-icons';
import {useMutation} from '@tanstack/react-query';
import {Badge, Button, Space, Statistic} from 'antd';
import {useCallback, useContext, useEffect} from 'react';

import {appRoute} from '../../../../../../app-route';
import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';
import {useSystem} from '../../../../../../provider/system/system-hook';
import {getGoogleStatisticsUrl, postGoogleStatistics} from '../../../../../../service/google/google-api';
import {GoogleCclStatusEnum} from '../../../../../../service/google/google-type';
import {classNames} from '../../../../../../util/css';
import {useUrl} from '../../../../../../util/url-hook/url-hook';
import {StepHeader} from '../../common/step-header/step-header';
import {GoogleAccountSyncContext} from '../../google-account-sync-context';

import {GoogleConfirmationsUrlQueryType} from './confirmations/google-confirmations-type';
import {useFinishStepTips} from './finish-step-hook';
import {ReactComponent as FinishImage} from './images/finish.svg';
import {StatisticList} from './statistic-list/statistic-list';
import * as styles from './finish-step.scss';

export function FinishStep(): JSX.Element | null {
    const {processingKey, setHeaderButtons, setTips, goToSources} = useContext(GoogleAccountSyncContext);

    const {data: statistics, mutate: postStatistics} = useMutation([getGoogleStatisticsUrl(processingKey)], () =>
        postGoogleStatistics(processingKey)
    );

    const {tips} = useFinishStepTips(statistics);
    const {pushState} = useUrl();
    const {screen} = useSystem();

    const handleFinish = useCallback(
        (query?: GoogleConfirmationsUrlQueryType) =>
            pushState(appRoute.sourcesAccountSyncGoogleConfirmations.path, query ?? {}),
        [pushState]
    );

    useEffect(() => {
        postStatistics();
    }, [postStatistics]);

    useEffect(() => {
        setHeaderButtons(() =>
            statistics?.needAction ? (
                <>
                    <Button onClick={() => goToSources()}>
                        <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__BUTTON__FINISH" />
                    </Button>

                    <Button onClick={() => handleFinish()} type="primary">
                        <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__BUTTON__CONFIRM" />
                    </Button>
                </>
            ) : (
                <Button onClick={() => goToSources()} type="primary">
                    <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__BUTTON__FINISH" />
                </Button>
            )
        );
    }, [goToSources, handleFinish, setHeaderButtons, statistics?.needAction]);

    useEffect(() => {
        setTips(tips);
    }, [setTips, tips]);

    if (!statistics) {
        return null;
    }

    const linked = statistics.total - statistics.needAction;
    const confirmed = statistics.sent + statistics.synced;
    const confirmedPercent = Math.floor((confirmed / linked) * 100);
    const notConfirmed = statistics.waitRights + statistics.notVerified;

    return (
        <Space className={styles.FinishStep} direction="vertical" size="middle">
            {screen.isMobile ? null : (
                <div className={styles.FinishStep_image}>
                    <FinishImage />
                </div>
            )}

            <StepHeader
                alertIcon={faMapMarkerCheck}
                alertMessage={
                    statistics?.needAction
                        ? 'GOOGLE_SYNC__FINISH_STEP__ALERT__ACTION_REQUIRED'
                        : 'GOOGLE_SYNC__FINISH_STEP__ALERT__CONFIRMED'
                }
                title={
                    statistics?.needAction
                        ? 'GOOGLE_SYNC__FINISH_STEP__TITLE__ACTION_REQUIRED'
                        : 'GOOGLE_SYNC__FINISH_STEP__TITLE__CONFIRMED'
                }
            />

            <div className={styles.FinishStep_statistic}>
                <Statistic
                    title={<Text secondary stringKey="GOOGLE_SYNC__FINISH_STEP__STATICSICS__LINKED" />}
                    value={linked}
                />

                <Statistic
                    suffix={
                        Number.isNaN(confirmedPercent) ? null : (
                            <Badge
                                className={classNames(styles.Badge, styles.Badge__statistic, styles.Badge__blue)}
                                count={`${confirmedPercent}%`}
                            />
                        )
                    }
                    title={<Text secondary stringKey="GOOGLE_SYNC__FINISH_STEP__STATICSICS__CONFIRMED" />}
                    value={confirmed}
                />

                <Statistic
                    suffix={
                        Number.isNaN(confirmedPercent) ? null : (
                            <Badge
                                className={classNames(styles.Badge, styles.Badge__statistic, styles.Badge__blue)}
                                count={`${100 - confirmedPercent}%`}
                            />
                        )
                    }
                    title={<Text secondary stringKey="GOOGLE_SYNC__FINISH_STEP__STATICSICS__NOT_CONFIRMED" />}
                    value={notConfirmed}
                />
            </div>

            <StatisticList
                count={confirmed}
                dataSource={[
                    {
                        id: GoogleCclStatusEnum.Synchronize,
                        label: <Text stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED" />,
                        value: statistics.synced,
                    },
                    {
                        id: GoogleCclStatusEnum.Send,
                        label: <Text stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SENT" />,
                        value: statistics.sent,
                    },
                ]}
                header="GOOGLE_SYNC__FINISH_STEP__CONFIRMED_LOCATIONS"
            />

            <StatisticList
                boldHeader
                count={notConfirmed}
                dataSource={[
                    {
                        id: 'massConfirmation',
                        label: (
                            <span>
                                <Text stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION" />
                                {': '}
                                <Text stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__CREATED" />
                            </span>
                        ),
                        value: statistics.verificationStatistics.mass,
                        onClick: () => handleFinish({requestsTab: 'true'}),
                    },
                    {
                        id: GoogleCclStatusEnum.ActionRequired,
                        label: <Text stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__ACTION_REQUIRED__BUTTON" />,
                        value: statistics.verificationStatistics.individual,
                        onClick: () => handleFinish({status: GoogleCclStatusEnum.AwaitingConfirmation}),
                    },
                    {
                        id: GoogleCclStatusEnum.AwaitingRights,
                        label: <Text stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS__BUTTON" />,
                        value: statistics.waitRights,
                        onClick: () => handleFinish({status: GoogleCclStatusEnum.AwaitingRights}),
                    },
                ]}
                header="GOOGLE_SYNC__FINISH_STEP__NOT_CONFIRMED_LOCATIONS"
            />
        </Space>
    );
}
