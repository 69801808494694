import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

const companyImageSchema = r.object({
    hash: r.string().optional(),
    url: r.string().optional(),
});

type CompanyImageType = r.infer<typeof companyImageSchema>;

export const companyLogoSchema = {
    [CompanyKeyEnum.Logo]: companyImageSchema.nullable(),
    [CompanyKeyEnum.Cover]: companyImageSchema.nullable(),
    [CompanyKeyEnum.ExtraLogo]: r.unknown(),
    [CompanyKeyEnum.ExtraCover]: r.unknown(),
};

const companyLogoSchemaObject = r.object(companyLogoSchema);

export type CompanyLogoType = r.infer<typeof companyLogoSchemaObject>;

export type CompanyLogoFormType = {
    [CompanyKeyEnum.Logo]: CompanyImageFormType | null;
    [CompanyKeyEnum.Cover]: CompanyImageFormType | null;
    [CompanyKeyEnum.ExtraLogo]: unknown;
    [CompanyKeyEnum.ExtraCover]: unknown;
};

export type CompanyLogoServerType = {
    [CompanyKeyEnum.Logo]: Pick<CompanyImageFormType, 'tempId' | 'url'> | null;
    [CompanyKeyEnum.Cover]: Pick<CompanyImageFormType, 'tempId' | 'url'> | null;
    [CompanyKeyEnum.ExtraLogo]: unknown;
    [CompanyKeyEnum.ExtraCover]: unknown;
};

export type CompanyImageFormType = CompanyImageType & {
    tempId?: string | null;
    size?: number;
    objectUrl?: string | null;
    errorMessage?: Array<string>;
};
