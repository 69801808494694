export const enContactsTranslations = {
    CONTACTS__LABEL__PHONE_NUMBERS: 'Phone numbers',
    CONTACTS__PLACEHOLDER__PHONE_NUMBERS: '+375 (__) ___-__-__',
    CONTACTS__PLACEHOLDER__EMAIL_ADDRESS: 'mail@example.com',
    CONTACTS__PLACEHOLDER__WEBSITE: 'example.com',
    CONTACTS__LABEL__SOCIAL_NETWORKS: 'Social networks',
    CONTACTS__PLACEHOLDER__SOCIAL_NETWORKS: 'example.com',
    CONTACTS__INFO__MAIN_PHONE_NUMBER: 'Main phone number',
    CONTACTS__INFO__MAIN_PHONE_NUMBER__CONTENT:
        'The main phone number is visible first on the card by default. The rest are hidden.',
    CONTACTS__INFO__MAIN_WEBSITE: 'Main website',
    CONTACTS__INFO__MAIN_SOCIAL_NETWORK: 'Main social networks',
    CONTACTS__INFO__REMOVED_PHONE: 'Phone {value} is removed',
    CONTACTS__INFO__REMOVED_LINK: 'Link {value} is removed',
};
