import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';

import {UserType} from './user-type';

export const userUrl = '/user';

export const USER_FIXTURE: UserType = {
    id: 792,
    email: 'test@rocketdata.io',
    language: ShortLocaleNameEnum.en,
    isStaff: true,
    isRocketdata: false,
    isRocketdataUser: true,
    isDoubleGisUser: false,
    isDoubleGisTrusted: false,
    isTestUser: false,
    isDemoUser: false,
    isSuperuser: false,
    is2faEnabled: false,
    features: [],
    profile: {
        firstName: 'Bob',
    },
    regionAvailableCatalogs: {},
    canCreateGptReplySuggestionsLicence: true,
    yandexNeedActualizationExists: false,
    mailingListsLicenceSettings: {
        canCreateMailingListsLicence: true,
        mailingListLicencesConnected: true,
    },
    userHasOnlyAccess: [],
};
