import {ReactNode} from 'react';

import * as styles from './list-header.scss';

export {FilterIcon} from './filter-icon';

type PropsType = {
    left: ReactNode;
    right: ReactNode;
};

export function ListHeader(props: PropsType): JSX.Element {
    const {left, right} = props;

    return (
        <div className={styles.ListHeader}>
            <div>{left}</div>

            <div>{right}</div>
        </div>
    );
}
