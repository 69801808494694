import {groupBy, intersectionBy, keyBy, lowerCase, merge, mergeWith, upperFirst, values} from 'lodash';

import {
    DoubleGisAttributeType,
    GoogleAttributeType,
} from '../../../../../../provider/catalogs/attributes/attributes-type';

import {
    AttributeGetConfigType,
    AttributeGroupType,
    AttributeType,
    AttributeTypeEnum,
    AttributeWithNonUnionValuesType,
    BooleanAttributeType,
    DoubleGisAttributeGetCompanyType,
    GoogleAttributeGetCompanyType,
    YandexAttributeGetCompanyType,
} from './attributes-type';

function toSentenceCase(value: string) {
    return value.includes('_') ? upperFirst(lowerCase(value)) : upperFirst(value);
}

function fixNames(configAttributes: Array<AttributeGetConfigType>): Array<AttributeGetConfigType> {
    return configAttributes.map((attribute: AttributeWithNonUnionValuesType) => ({
        ...attribute,
        displayName: toSentenceCase(attribute.displayName),
        values: attribute.values.map((value) => {
            if (!('displayValue' in value)) {
                return value;
            }

            return {
                ...value,
                displayValue: value.displayValue ? toSentenceCase(value.displayValue) : value.displayValue,
            };
        }),
    }));
}

function mergeById(
    formAttributes: Array<YandexAttributeGetCompanyType>,
    configAttributes: Array<AttributeGetConfigType> | null
): Array<AttributeType> {
    return values(
        mergeWith(
            keyBy(configAttributes as Array<AttributeType>, 'internalName'),
            keyBy(formAttributes as Array<AttributeType>, 'internalName'),
            (first: AttributeType, second: AttributeType) => {
                return Array.isArray(first)
                    ? values(merge(keyBy(first, 'templateId'), keyBy(second, 'templateId')))
                    : undefined; // eslint-disable-line no-undefined
            }
        )
    );
}

export function prepareAndMergeYandexAttributes(
    formAttributes: Array<YandexAttributeGetCompanyType> | null,
    configAttributes: Array<AttributeGetConfigType> | null
): Array<AttributeType> | null {
    if (!configAttributes) {
        return null;
    }

    return mergeById(
        intersectionBy(formAttributes, configAttributes, ({internalName}) => internalName),
        fixNames(configAttributes)
    );
}

function markSelectedGoogleAttributes(
    configAttribute: AttributeWithNonUnionValuesType | undefined,
    formAttribute: GoogleAttributeGetCompanyType | AttributeWithNonUnionValuesType
): AttributeWithNonUnionValuesType | null {
    if (!configAttribute) {
        return null;
    }

    if ('internalName' in formAttribute) {
        return formAttribute;
    }

    return {
        ...configAttribute,
        values: configAttribute.values.map((value) => {
            const isBoolean = configAttribute.featureType === AttributeTypeEnum.GoogleBoolean;
            const isOneFormValue = formAttribute.values.length === 1;
            const isSameFormValue = 'value' in value && value.value === formAttribute.values[0];

            if (isBoolean && isOneFormValue && isSameFormValue) {
                return {...value, selected: true};
            }

            if (configAttribute.featureType === AttributeTypeEnum.GoogleUrl) {
                return {...value, value: formAttribute.values[0]};
            }

            return formAttribute.values.map(String).includes(String(value.templateId))
                ? {...value, selected: true}
                : value;
        }),
    };
}

function markSelectedDoubleGisAttributes(
    configAttribute: AttributeWithNonUnionValuesType | undefined,
    formAttribute: GoogleAttributeGetCompanyType | AttributeWithNonUnionValuesType
): AttributeWithNonUnionValuesType | null {
    if (!configAttribute) {
        return null;
    }

    if ('internalName' in formAttribute) {
        return formAttribute;
    }

    return {
        ...configAttribute,
        values: configAttribute.values.map((value) => {
            return formAttribute.values.map(String).includes(String(value.templateId))
                ? {...value, selected: true}
                : value;
        }),
    };
}

export function mapGoogleAttributes(
    googleAttributes: Array<GoogleAttributeType> | null
): Array<AttributeWithNonUnionValuesType & {groupDisplayName: string}> | null {
    if (!googleAttributes) {
        return null;
    }

    return googleAttributes.map((attribute) => ({
        internalName: attribute.attributeId,
        displayName: attribute.displayName,
        groupDisplayName: attribute.groupDisplayName,
        featureType: attribute.valueType,
        values:
            attribute.values.length > 0
                ? attribute.values.map(({displayName, value}) => ({
                      templateId:
                          attribute.valueType === AttributeTypeEnum.GoogleBoolean
                              ? String(Number(value))
                              : String(value),
                      displayValue: displayName,
                  }))
                : [{templateId: attribute.attributeId, value: null}],
    }));
}

function mapDoubleGisAttributes(
    doubleGisAttribute: Array<DoubleGisAttributeType> | null
): Array<AttributeWithNonUnionValuesType> | null {
    if (!doubleGisAttribute) {
        return null;
    }

    return doubleGisAttribute.map((attribute) => ({
        internalName: attribute.originId,
        displayName: attribute.name,
        featureType: attribute.attributeType,
        values:
            attribute.values.length > 0
                ? attribute.values.map(({displayName, value}) => ({
                      templateId:
                          attribute.attributeType === AttributeTypeEnum.DoubleGisBoolean
                              ? String(Number(value))
                              : String(value),
                      displayValue: displayName,
                  }))
                : [{templateId: attribute.originId, value: null}],
    }));
}

export function groupGoogleAttributes(
    googleAttributes: Array<AttributeWithNonUnionValuesType & {groupDisplayName: string}> | null
): Array<AttributeGroupType> {
    return Object.entries(groupBy(googleAttributes, ({groupDisplayName}) => groupDisplayName)).map(
        ([group, items]) => ({group, items})
    ) as Array<AttributeGroupType>;
}

export function prepareAndMergeGoogleAttributes(
    formAttributes: Array<AttributeGroupType | GoogleAttributeGetCompanyType> | null,
    configAttributes: Array<GoogleAttributeType> | null
): Array<AttributeWithNonUnionValuesType & {groupDisplayName: string}> | null {
    if (!configAttributes) {
        return null;
    }

    // usually needed after changing a category
    const formAttributesWithoutGroups = formAttributes?.flatMap<GoogleAttributeGetCompanyType | AttributeType>(
        (attribute) => ('items' in attribute ? attribute.items : [attribute])
    );

    return values(
        mergeWith(
            keyBy(mapGoogleAttributes(configAttributes), ({internalName}) => internalName),
            keyBy(formAttributesWithoutGroups, (attribute) =>
                'attributeId' in attribute ? attribute.attributeId : attribute.internalName
            ),
            markSelectedGoogleAttributes
        )
    ).filter(Boolean);
}

export function prepareAndMergeDoubleGisAttributes(
    formAttributes: Array<BooleanAttributeType | DoubleGisAttributeGetCompanyType> | null,
    configAttributes: Array<DoubleGisAttributeType> | null
): Array<BooleanAttributeType> | null {
    if (!configAttributes) {
        return null;
    }

    return values(
        mergeWith(
            keyBy(mapDoubleGisAttributes(configAttributes), ({internalName}) => internalName),
            keyBy(formAttributes, (attribute: DoubleGisAttributeGetCompanyType) =>
                'internalName' in attribute ? attribute.internalName : attribute?.originId
            ),
            markSelectedDoubleGisAttributes
        )
    ).filter(Boolean);
}
