import {useCallback, useState} from 'react';

import {LandingCongratulationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-congratulation-step';
import {LandingOnboardingPageCard} from '../../../../../component/landing/landing-onboarding-steps/landing-onboarding-page-card';
import {LandingPresentationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-presentation-step';
import * as styles from '../../../local-pack/local-pack-activation/local-pack-activation-steps/local-pack-activation-steps.scss';

import {POST_ONBOARDING_IMAGES} from './post-onboarding-helper';

export function PostsOnboarding(): JSX.Element {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const onStepFinished = useCallback(() => {
        setCurrentStepIndex((previousActiveKeyIndex) => previousActiveKeyIndex + 1);
    }, []);

    const steps = [
        <LandingPresentationStep
            descriptionLangKeyType="POSTS__ONBOARDING__STEP1__DESCRIPTION"
            images={POST_ONBOARDING_IMAGES.step1}
            key="step1"
            onStepFinish={onStepFinished}
            titleLangKeyType="POSTS__ONBOARDING__STEP1__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="POSTS__ONBOARDING__STEP2__DESCRIPTION"
            images={POST_ONBOARDING_IMAGES.step2}
            key="step2"
            onStepFinish={onStepFinished}
            titleLangKeyType="POSTS__ONBOARDING__STEP2__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="POSTS__ONBOARDING__STEP3__DESCRIPTION"
            images={POST_ONBOARDING_IMAGES.step3}
            key="step3"
            onStepFinish={onStepFinished}
            titleLangKeyType="POSTS__ONBOARDING__STEP3__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="POSTS__ONBOARDING__STEP4__DESCRIPTION"
            imageClassName={styles.LocalPackActivationSteps_image__withLeftMargin}
            images={POST_ONBOARDING_IMAGES.step4}
            key="step4"
            onStepFinish={onStepFinished}
            titleLangKeyType="POSTS__ONBOARDING__STEP4__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="POSTS__ONBOARDING__STEP5__DESCRIPTION"
            imageClassName={styles.LocalPackActivationSteps_image__withLeftMargin}
            images={POST_ONBOARDING_IMAGES.step5}
            key="step5"
            onStepFinish={onStepFinished}
            titleLangKeyType="POSTS__ONBOARDING__STEP5__TITLE"
        />,
        <LandingCongratulationStep
            descriptionLangKeyType="POSTS__ONBOARDING__STEP6__DESCRIPTION"
            key="step6"
            titleLangKeyType="POSTS__ONBOARDING__STEP6__TITLE"
        />,
    ];

    return <LandingOnboardingPageCard current={currentStepIndex} onChange={setCurrentStepIndex} steps={steps} />;
}
