import {Space, Table} from 'antd';
import {useCallback, useContext, useMemo} from 'react';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {dateRangeToWorksheetName} from '../../../../../component/request-report-base/request-report-base-helper';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {fetchReviewEmployees} from '../../../../../service/reivew-analysis/review-employees/review-employees-api';
import {useReviewEmployees} from '../../../../../service/reivew-analysis/review-employees/review-employees-hook';
import {
    ReviewAnalyticsEmployeeType,
    ReviewsAnalysisEmployeesQueryType,
} from '../../../../../service/reivew-analysis/review-employees/review-employees-type';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {ActiveFilter} from '../../components/active-filter/active-filter';
import {DrawerFilter} from '../../components/drawer-filter/drawer-filter';
import {RequestReport} from '../../components/request-report/request-report';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';
import {EmployeeTableDataType} from '../../reviews-analysis-type';

import {employeesDefaultPageSize, employeesPageSizeOptions} from './employees-page-const';
import {employeesPageColumns} from './employees-page-helper';

export function EmployeesPage(): JSX.Element {
    const {reviewsAnalysisDashboard: dashboardRoute, reviewsAnalysisEmployees: employeesRoute} = appRoute;

    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {singleFilter} = useReviewsAnalysisFilter();

    const {result, isInProgress, processError, pagination} = useReviewEmployees(
        {
            ...singleFilter,
        },
        mainFilterKey,
        {initialPageSize: employeesDefaultPageSize}
    );

    const {page, pageSize, total, onChange: onPaginationChange} = pagination;

    const getDataSource = useCallback((array: Array<ReviewAnalyticsEmployeeType>, isForExport?: boolean) => {
        return array.map<EmployeeTableDataType>(
            (employee: ReviewAnalyticsEmployeeType, index): EmployeeTableDataType => {
                const {userId} = employee;

                return {
                    ...employee,
                    key: `${userId} ${index} ${isForExport && 'export'}`,
                };
            }
        );
    }, []);

    const dataSource: Array<EmployeeTableDataType> = useMemo<Array<EmployeeTableDataType>>(
        (): Array<EmployeeTableDataType> => getDataSource(result?.results || []),
        [getDataSource, result?.results]
    );

    function formatDataForXls(data: Array<ReviewAnalyticsEmployeeType>) {
        return data.map((employee) => ({
            [getLocalizedString('REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COLUMN__NAME')]:
                employee.userEmail || employee.userId,
            [getLocalizedString('REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COLUMN__REPLY_COUNT')]: employee.total,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__COMPLAINTS')]: employee.abuses,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE')]: getFormattedNumber(
                employee.repliedPercent,
                {style: 'percent'}
            ),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REPLY_DELAY')]: employee.avgResponseSpeed
                ? getFormattedNumber(employee.avgResponseSpeed, {style: 'unit', unit: TimeSizeEnum.minute})
                : '-',
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE'
            )}`]: employee.sentiment.positive,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL'
            )}`]: employee.sentiment.neutral,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE'
            )}`]: employee.sentiment.negative,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED'
            )}`]: employee.sentiment.unspecified,
        }));
    }

    return (
        <Page data-pdf="printPdfPageAttribute">
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES')} />

            <BreadCrumbs
                list={[
                    {
                        path: dashboardRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                    },
                    {
                        path: employeesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle
                    productName={UsetifulNameProductEnum.REVIEWS_ANALYSIS__EMPLOYEES}
                    title="CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES"
                />

                <Space>
                    <RequestReport<
                        EmployeeTableDataType,
                        ReviewAnalyticsEmployeeType,
                        ReviewsAnalysisEmployeesQueryType
                    >
                        apiHandler={fetchReviewEmployees}
                        columns={employeesPageColumns}
                        defaultFilename={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES')}
                        filters={{...singleFilter}}
                        formatDataForXls={formatDataForXls}
                        getDataSource={getDataSource}
                        title={<Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES" />}
                        xlsxWorksheetName={dateRangeToWorksheetName({
                            rawStartDate: singleFilter.startDate,
                            rawEndDate: singleFilter.endDate,
                        })}
                    />
                    <DrawerFilter />
                </Space>
            </PageHeader>

            <ActiveFilter
                isCompareRangeAllowed={false}
                isCompetitorAllowed={false}
                isPreview={false}
                isWithTextOnly={false}
            />

            {processError && <AlertFallback description={processError?.message} message={processError?.name} />}

            {!processError && (
                <Table<EmployeeTableDataType>
                    columns={employeesPageColumns}
                    dataSource={dataSource}
                    loading={isInProgress}
                    pagination={{
                        total,
                        pageSize,
                        defaultPageSize: employeesDefaultPageSize,
                        pageSizeOptions: employeesPageSizeOptions,
                        current: page,
                        size: 'default',
                        showSizeChanger: true,
                        onChange: onPaginationChange,
                    }}
                    scroll={{x: true}}
                    size="middle"
                />
            )}
        </Page>
    );
}
