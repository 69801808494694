import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {reviewsPopularTagSchema, ReviewsPopularTagType} from './reviews-popular-tag-type';

export function fetchReviewsPopularTags(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<Array<ReviewsPopularTagType>> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/tags/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        reviewsPopularTagSchema.array()
    );
}
