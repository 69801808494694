import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';

import imageStep1En from './images/posts-onboarding-step1-en.png';
import imageStep1Ru from './images/posts-onboarding-step1-ru.png';
import imageStep2En from './images/posts-onboarding-step2-en.png';
import imageStep2Ru from './images/posts-onboarding-step2-ru.png';
import imageStep3En from './images/posts-onboarding-step3-en.png';
import imageStep3Ru from './images/posts-onboarding-step3-ru.png';
import imageStep4En from './images/posts-onboarding-step4-en.png';
import imageStep4Ru from './images/posts-onboarding-step4-ru.png';
import imageStep5En from './images/posts-onboarding-step5-en.png';
import imageStep5Ru from './images/posts-onboarding-step5-ru.png';

export const POST_ONBOARDING_IMAGES = {
    step1: {
        [ShortLocaleNameEnum.ru]: imageStep1Ru,
        [ShortLocaleNameEnum.en]: imageStep1En,
        brandWizardImage: imageStep1En,
    },
    step2: {
        [ShortLocaleNameEnum.ru]: imageStep2Ru,
        [ShortLocaleNameEnum.en]: imageStep2En,
        brandWizardImage: imageStep2En,
    },
    step3: {
        [ShortLocaleNameEnum.ru]: imageStep3Ru,
        [ShortLocaleNameEnum.en]: imageStep3En,
        brandWizardImage: imageStep3En,
    },
    step4: {
        [ShortLocaleNameEnum.ru]: imageStep4Ru,
        [ShortLocaleNameEnum.en]: imageStep4En,
        brandWizardImage: imageStep4En,
    },
    step5: {
        [ShortLocaleNameEnum.ru]: imageStep5Ru,
        [ShortLocaleNameEnum.en]: imageStep5En,
        brandWizardImage: imageStep5En,
    },
};
