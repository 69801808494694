import {Space, Tag, Typography} from 'antd';
import {useCallback, useMemo} from 'react';

import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {
    PostsFilterKeyEnum,
    PostsFilterStateType,
    PostStatusEnum,
    SetPostFilterType,
} from '../../../../../service/posts/posts-types';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {postStatusTranslationMap} from '../../posts-const';

import {isAnyFilterPresents} from './posts-filter-active-helper';

type PropsType = {
    filter: PostsFilterStateType;
    setFilter: SetPostFilterType;
};

const {Text} = Typography;

export function PostsFilterActive(props: PropsType): JSX.Element {
    const {filter, setFilter} = props;

    const {sources, statuses, timeRange} = filter;

    const hasFilters = isAnyFilterPresents(filter);

    const {getFormattedDateTime} = useFormat();
    const {getCatalogName} = useCatalogInfo();

    const onSourceIdTagClosed = useCallback(
        (removingSourceId: number) => {
            setFilter({
                ...filter,
                [PostsFilterKeyEnum.sources]: sources.filter((sourceId) => sourceId !== removingSourceId),
            });
        },
        [sources, setFilter, filter]
    );

    const onStatusTagClosed = useCallback(
        (clickedStatus: PostStatusEnum) => {
            setFilter({
                ...filter,
                [PostsFilterKeyEnum.statuses]: statuses.filter((status) => status !== clickedStatus),
            });
        },
        [statuses, setFilter, filter]
    );

    const onTimeRangeTagClosed = useCallback(() => {
        setFilter({
            ...filter,
            [PostsFilterKeyEnum.timeRange]: [null, null],
        });
    }, [setFilter, filter]);

    const timeRangeText = useMemo<string | null>((): string | null => {
        const [startDate, endDate] = timeRange;

        if (!startDate || !endDate) {
            return null;
        }

        const startDateText = getFormattedDateTime(startDate, {timeZone: 'UTC'});
        const endDateText = getFormattedDateTime(endDate, {timeZone: 'UTC'});

        return `${startDateText}-${endDateText}`;
    }, [getFormattedDateTime, timeRange]);

    return (
        <>
            {!hasFilters && (
                <Text type="secondary">
                    <Locale stringKey="POSTS_ACTIVE__FILTER__EMPTY_TEXT" />
                </Text>
            )}
            {hasFilters && (
                <Space>
                    <Text type="secondary">
                        <Locale stringKey="POSTS_ACTIVE__FILTER__LABEL" />
                    </Text>
                    {sources.map((sourceId) => {
                        return (
                            <Tag closable key={sourceId} onClose={() => onSourceIdTagClosed(sourceId)}>
                                {getCatalogName(sourceId)}
                            </Tag>
                        );
                    })}

                    {statuses.map((status: PostStatusEnum) => {
                        return (
                            <Tag closable key={status} onClose={() => onStatusTagClosed(status)}>
                                <Locale stringKey={postStatusTranslationMap[status]} />
                            </Tag>
                        );
                    })}

                    {timeRangeText !== null && (
                        <Tag closable onClose={onTimeRangeTagClosed}>
                            {timeRangeText}
                        </Tag>
                    )}
                </Space>
            )}
        </>
    );
}
