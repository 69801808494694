import {faCircleCheck, faPlay} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space} from 'antd';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {Text} from '../../../../../component/text/text';
import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useLoomMetadata} from '../../../../../provider/onboarding/onboarding-hook';
import {useSystem} from '../../../../../provider/system/system-hook';
import {useUser} from '../../../../../provider/user/user-hook';
import {classNames} from '../../../../../util/css';
import {OnboardingCatalogsNameEnum} from '../../onboarding-const';
import {getOnboardingStepStatus} from '../../onboarding-helper';
import {MappedDataType} from '../../onboarding-type';
import * as styles from '../step.scss';

type PropsType = {
    descriptionData: MappedDataType;
    activeCatalog: string;
    setActiveCatalog: Dispatch<SetStateAction<OnboardingCatalogsNameEnum>>;
    setTargetVideo: Dispatch<SetStateAction<string | null>>;
};

// eslint-disable-next-line complexity
export function CatalogBlock(props: PropsType): ReactElement {
    const {descriptionData, activeCatalog, setTargetVideo, setActiveCatalog} = props;
    const {user} = useUser();
    const {getLocalizedString} = useLocale();

    const {blockTitle, button, videoLink, completed, documentationLink, description, catalogStatus, valueMap} =
        descriptionData;

    const {data: loomThumbnailsResult} = useLoomMetadata(videoLink);
    const {screen} = useSystem();

    const blockClassName = classNames(styles.Step_description, {
        [styles.Step_catalogBlock]: blockTitle,
        [styles.Step_catalogBlockWithoutVideo]: !videoLink,
    });

    const videoBlockClassName = classNames(styles.Step_videoBlock, {
        [styles.Step_videoWithCatalog]: blockTitle,
    });

    const catalogTitleClassName = classNames({
        [styles.Step_endedCatalogTitle]: activeCatalog !== blockTitle && completed,
    });

    const circleClassNames = classNames(styles.Step_circle, {
        [styles.Step_circle__disabled]: activeCatalog !== blockTitle,
    });

    const largeWindowWidth = 1080;

    return activeCatalog === blockTitle || !blockTitle ? (
        <div className={blockClassName} key={description}>
            {blockTitle && <div className={circleClassNames} />}
            <Space direction="vertical" size={12}>
                {blockTitle && (
                    <Text bolder large>
                        {blockTitle}
                        {blockTitle === OnboardingCatalogsNameEnum.Facebook && user?.language === ShortLocaleNameEnum.ru
                            ? '*'
                            : ''}
                        {getOnboardingStepStatus(catalogStatus)}
                    </Text>
                )}
                <div>
                    <Text key={description} lighter stringKey={description} valueMap={{companyName: valueMap}} />
                    {screen.width < largeWindowWidth && documentationLink && (
                        <a className={styles.Step_link} href={documentationLink} rel="noreferrer" target="_blank">
                            {getLocalizedString('DASHBOARD_PAGE__HELPER__DETAILS')}
                        </a>
                    )}
                </div>
                {screen.isMobile && videoLink && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div className={videoBlockClassName} onClick={() => setTargetVideo(videoLink)}>
                        <FontAwesomeIcon className={styles.Step_playIcon} icon={faPlay} />
                        <img alt="" className={styles.Step_videoFrame} src={loomThumbnailsResult?.thumbnail_url} />
                    </div>
                )}
                {button && (
                    <div className={styles.Step_buttonWrapper}>
                        {button}
                        {screen.width >= largeWindowWidth && documentationLink && (
                            <Button className={styles.Step_linkWrapper} size="middle" type="link">
                                <a href={documentationLink} rel="noreferrer" target="_blank">
                                    {getLocalizedString('DASHBOARD_PAGE__HELPER__DETAILS')}
                                </a>
                            </Button>
                        )}
                    </div>
                )}
            </Space>
            {!screen.isMobile && videoLink && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div className={videoBlockClassName} onClick={() => setTargetVideo(videoLink)}>
                    <FontAwesomeIcon className={styles.Step_playIcon} icon={faPlay} />
                    <img alt="" className={styles.Step_videoFrame} src={loomThumbnailsResult?.thumbnail_url} />
                </div>
            )}
        </div>
    ) : (
        <Space
            className={styles.Step_catalogBlock__empty}
            onClick={() => {
                if (!completed) {
                    setActiveCatalog(blockTitle);
                }
            }}
        >
            {completed ? (
                <FontAwesomeIcon className={styles.Step_stepNumber} icon={faCircleCheck} />
            ) : (
                <div className={circleClassNames} />
            )}
            <Text className={catalogTitleClassName} large lighter>
                {blockTitle}
                {blockTitle === OnboardingCatalogsNameEnum.Facebook && user?.language === ShortLocaleNameEnum.ru
                    ? '*'
                    : ''}
            </Text>
        </Space>
    );
}
