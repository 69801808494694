import {
    OnlinePresenceCompareV2Enum,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';
import {DatePeriodEnum} from '../../../../util/date';

import {phrasesPeriodItems} from './online-presence-filter-v2-const';

export const AnalyticsDatePeriodMap: Record<DatePeriodEnum, string> = {
    [DatePeriodEnum.Month]: '30 days',
    [DatePeriodEnum.PreviousFullMonth]: 'Month',
    [DatePeriodEnum.Quarter]: 'Quater',
    [DatePeriodEnum.Week]: 'Week',
    [DatePeriodEnum.Custom]: 'Custom',
};

export function getOnlinePresencePeriodOptions(isPhrasesMode: boolean): Array<DatePeriodEnum> {
    if (isPhrasesMode) {
        return phrasesPeriodItems;
    }

    return Object.values(DatePeriodEnum);
}

export function getPeriodForAnalytics(
    filter: OnlinePresenceV2FilterStateType,
    isCompare: OnlinePresenceCompareV2Enum | null
): [string, string] {
    if (isCompare) {
        return [
            `A - ${filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart]} - ${
                filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd]
            }, B - ${filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]} - ${
                filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]
            }`,
            filter[OnlinePresenceV2FilterQueriesEnum.Aggregation],
        ];
    }

    return [
        `${filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart]} - ${
            filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd]
        }`,
        filter[OnlinePresenceV2FilterQueriesEnum.Aggregation],
    ];
}
