import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {FormattedNumber} from '../../../../../provider/locale/formatted-number';
import {objectToUrlParameters} from '../../../../../util/url';
import {ReviewsStateUrlQueryNameEnum} from '../../../reviews/reviews-state/reviews-state-type';

type PropsType = {
    companyId: number;
    rating: string | number | null;
};

export function RatingColumn(props: PropsType): JSX.Element {
    const {companyId, rating} = props;

    const parameters = objectToUrlParameters({
        [ReviewsStateUrlQueryNameEnum.companyIds]: companyId.toString(),
    });

    const ratingValue = Number(rating);

    if (!ratingValue) {
        return <span>–</span>;
    }

    return (
        <NavigationLink to={`${appRoute.reviewsManagementReviews.path}?${parameters}`}>
            <FormattedNumber value={ratingValue} />
        </NavigationLink>
    );
}
