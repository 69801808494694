import {LocaleContextType} from '../../../provider/locale/locale-context-type';
import {
    BulkEditCompaniesMainFilterType,
    BulkEditFieldNameEnum,
    BulkUpdateCompaniesFieldsType,
    BulkUpdateCompaniesFilterType,
} from '../../../service/company/company-type';

import {serializeFieldByFieldName, validateFieldByFieldName} from './bulk-edit-companies-const';
import {BulkEditFieldType, BulkUpdateCompaniesErrorsType} from './bulk-edit-companies-type';
import {BulkEditFieldsFormType} from './fields-list/fields-list-type';

export function serializeBulkEditCompaniesFields(
    fieldsValues: BulkEditFieldsFormType,
    fields: Array<BulkEditFieldType>
): BulkUpdateCompaniesFieldsType {
    const serializedFields = {} as Record<
        BulkEditFieldNameEnum,
        BulkUpdateCompaniesFieldsType[keyof BulkUpdateCompaniesFieldsType]
    >;

    fields.forEach((field) => {
        serializedFields[field.name] = serializeFieldByFieldName(fieldsValues, field)[field.name];
    });

    return serializedFields as BulkUpdateCompaniesFieldsType;
}

export function validateBulkEditCompaniesFields(
    fieldsValues: BulkEditFieldsFormType,
    fields: Array<BulkEditFieldType>,
    getLocalizedString: LocaleContextType['getLocalizedString']
): Partial<BulkUpdateCompaniesErrorsType> {
    const errors = {} as Record<
        BulkEditFieldNameEnum,
        BulkUpdateCompaniesErrorsType[keyof BulkUpdateCompaniesErrorsType]
    >;

    fields.forEach((field) => {
        errors[field.name] = validateFieldByFieldName(fieldsValues, getLocalizedString)[field.name];
    });

    return errors as Partial<BulkUpdateCompaniesErrorsType>;
}

function buildBulkEditFilterFromMainFilter(
    excludedIds: Array<number> | null,
    mainFilterKey: string
): BulkEditCompaniesMainFilterType {
    const filter: BulkEditCompaniesMainFilterType = {};

    filter.filter_key = mainFilterKey;

    if (excludedIds && excludedIds?.length > 0) {
        filter.exclude_ids = excludedIds;
    }

    return filter;
}

export function serializeBulkEditCompaniesFilters(
    companyIds: Array<number> | null,
    excludedIds: Array<number> | null,
    mainFilterKey: string
): BulkUpdateCompaniesFilterType {
    if (companyIds === null) {
        return buildBulkEditFilterFromMainFilter(excludedIds, mainFilterKey);
    }

    return {selected_ids: companyIds};
}
