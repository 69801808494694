import {Progress} from 'antd';

import {classNames} from '../../util/css';

import {formatProgressValue} from './labelled-progress-bar-helper';
import * as styles from './labelled-progress-bar.scss';

type PropsType = {
    title: string | JSX.Element;
    percent: number;
    className?: string;
};

export function LabelledProgressBar(props: PropsType): JSX.Element {
    const {title, percent, className} = props;

    const isSuccess = percent === 100;

    return (
        <div className={className}>
            <p className={styles.LabelledProgressBar_title}>{title}</p>
            <Progress
                className={classNames(styles.LabelledProgressBar__primary, {
                    [styles.LabelledProgressBar__success]: isSuccess,
                })}
                format={formatProgressValue}
                percent={percent}
            />
        </div>
    );
}
