import dayjs, {Dayjs} from 'dayjs';

import {LocaleContextType, LocaleNameEnum} from '../../../provider/locale/locale-context-type';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {ReviewsAnalysisModeEnum} from '../../../service/reivew-analysis/reivew-analysis-const';
import {ReviewRateEnum} from '../../../service/reivew-analysis/review-rate/review-rate-type';
import {SentimentEnum} from '../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {isDateValid} from '../../../util/date';
import {getEnumValue, getEnumValueEnsure} from '../../../util/enum';
import {NeverError} from '../../../util/error';
import {getFormattedDateTime} from '../../../util/format';
import {QueryMapType} from '../../../util/type';

import {KeywordsAndTopicsFormTypeAggregationEnum} from './pages/keywords-and-topics-page/keywords-and-topics-form/keywords-and-topics-type';
import {defaultReviewsAnalysisDaysPeriod} from './reviews-analysis-const';
import {
    ComparePeriodFilterRequestType,
    CompetitorFilterRequestType,
    ReviewsAnalysisFilterEnum,
    ReviewsAnalysisFilterType,
    ReviewsCompareEnum,
    SingleFilterRequestType,
} from './reviews-analysis-type';
import * as styles from './reviews-analysis-palette-export.scss';

export function getCompareModeState(filterState: ReviewsAnalysisFilterType): ReviewsCompareEnum {
    if (filterState[ReviewsAnalysisFilterEnum.CompareRange].some(Boolean)) {
        return ReviewsCompareEnum.PERIOD;
    }

    if (filterState[ReviewsAnalysisFilterEnum.Competitor]) {
        return ReviewsCompareEnum.COMPETITOR;
    }

    return ReviewsCompareEnum.OFF;
}

export function getSentimentStringKeyBySentiment(sentiment: SentimentEnum): LangKeyType {
    switch (sentiment) {
        case SentimentEnum.Negative:
            return 'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE';
        case SentimentEnum.Positive:
            return 'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE';
        case SentimentEnum.Neutral:
            return 'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL';
        case SentimentEnum.Unspecified:
            return 'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED';
        default:
            throw new NeverError(sentiment);
    }
}

export function getSentimentColorBySentiment(sentiment: SentimentEnum): string {
    switch (sentiment) {
        case SentimentEnum.Negative:
            return styles.sentiment_negative;
        case SentimentEnum.Positive:
            return styles.sentiment_positive;
        case SentimentEnum.Neutral:
            return styles.sentiment_neutral;
        case SentimentEnum.Unspecified:
            return styles.sentiment_unspecified;
        default:
            throw new NeverError(sentiment);
    }
}

export function getPeriodOfTimeFromUrl(value: string): [Date | null, Date | null] {
    const [start, end] = value.split(',');

    if (!start || !end) {
        return [null, null];
    }

    const fromDate: Date = new Date(start);
    const toDate: Date = new Date(end);

    if (!isDateValid(fromDate) || !isDateValid(toDate)) {
        return [null, null];
    }

    return [fromDate, toDate];
}

function getBooleanFromUrl(value: string): boolean | null {
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return null;
}

function getCatalogsFromUrl(value: string): Array<number> {
    return value
        .split(',')
        .map(Number)
        .filter<number>((id: number): id is number => Boolean(id) && !Number.isNaN(id));
}

export function getAggregationFromUrl(value?: string | void): KeywordsAndTopicsFormTypeAggregationEnum {
    return getEnumValueEnsure(
        KeywordsAndTopicsFormTypeAggregationEnum,
        value || '',
        KeywordsAndTopicsFormTypeAggregationEnum.Day
    );
}

export function getRangeFilterTagFormat(localeName: LocaleNameEnum, range: [Date | null, Date | null]): string {
    const [startDate, endDate] = range;

    if (!startDate || !endDate) {
        return '';
    }

    const startFormat: string = getFormattedDateTime(localeName, startDate, {timeZone: 'UTC'});
    const endFormat: string = getFormattedDateTime(localeName, endDate, {timeZone: 'UTC'});

    return `${startFormat} - ${endFormat}`;
}

export function getComparePeriodTitle(
    localeName: LocaleNameEnum,
    range: [Date | null, Date | null],
    getLocalizedString: LocaleContextType['getLocalizedString']
): string {
    return (
        getRangeFilterTagFormat(localeName, range) ||
        getLocalizedString('REVIEWS_ANALYSIS__COMPARE_PERIOD_TITLE__DEFAULT', {
            daysCount: defaultReviewsAnalysisDaysPeriod.toString(),
        })
    );
}

// eslint-disable-next-line complexity, max-statements
export function getFilterStateFromUrlQuery(
    queries: QueryMapType<ReviewsAnalysisFilterEnum>
): ReviewsAnalysisFilterType {
    const rangeQuery: string = queries[ReviewsAnalysisFilterEnum.Range] || '';
    const range: [Date | null, Date | null] = getPeriodOfTimeFromUrl(rangeQuery);

    const compareRangeQuery: string = queries[ReviewsAnalysisFilterEnum.CompareRange] || '';
    const compareRange: [Date | null, Date | null] = getPeriodOfTimeFromUrl(compareRangeQuery);

    const competitorIdQuery = String(queries[ReviewsAnalysisFilterEnum.Competitor]);
    const competitorId: number | null = Number.parseInt(competitorIdQuery, 10) || null;

    const sentimentsQuery: string = queries[ReviewsAnalysisFilterEnum.Sentiments] || '';
    const sentiments: Array<SentimentEnum> = sentimentsQuery
        .split(',')
        .map((value: string): SentimentEnum | null => getEnumValue<SentimentEnum>(SentimentEnum, value))
        .filter(Boolean);

    const catalogsQuery: string = queries[ReviewsAnalysisFilterEnum.Catalogs] || '';
    const catalogs: Array<number> = getCatalogsFromUrl(catalogsQuery);

    const tagsQuery: string = queries[ReviewsAnalysisFilterEnum.Tags] || '';
    const tags: Array<string> = tagsQuery.split(',').filter(Boolean);

    const phrasesQuery: string = queries[ReviewsAnalysisFilterEnum.Phrases] || '';
    const phrases: Array<string> = phrasesQuery.split(',').filter(Boolean);

    const topicsQuery: string = queries[ReviewsAnalysisFilterEnum.Topics] || '';
    const topics: Array<string> = topicsQuery.split(',').filter(Boolean);

    const ratesQuery: string = queries[ReviewsAnalysisFilterEnum.Rates] || '';
    const rates: Array<ReviewRateEnum> = ratesQuery
        .split(',')
        .map((value: string): ReviewRateEnum | null => getEnumValue<ReviewRateEnum>(ReviewRateEnum, value))
        .filter(Boolean);

    const withMessageQuery: string = queries[ReviewsAnalysisFilterEnum.WithMessage] || '';
    const withMessage: boolean | null = getBooleanFromUrl(withMessageQuery);

    const withReplyQuery: string = queries[ReviewsAnalysisFilterEnum.WithReply] || '';
    const withReply: boolean | null = getBooleanFromUrl(withReplyQuery);

    const ableToReplyQuery: string = queries[ReviewsAnalysisFilterEnum.AbleToReply] || '';
    const ableToReply: boolean | null = getBooleanFromUrl(ableToReplyQuery);

    const filterState: ReviewsAnalysisFilterType = {
        [ReviewsAnalysisFilterEnum.Range]: range,
        [ReviewsAnalysisFilterEnum.CompareRange]: compareRange,
        [ReviewsAnalysisFilterEnum.Competitor]: competitorId,
        [ReviewsAnalysisFilterEnum.Sentiments]: sentiments,
        [ReviewsAnalysisFilterEnum.Catalogs]: catalogs,
        [ReviewsAnalysisFilterEnum.Tags]: tags,
        [ReviewsAnalysisFilterEnum.Phrases]: phrases,
        [ReviewsAnalysisFilterEnum.Topics]: topics,
        [ReviewsAnalysisFilterEnum.Rates]: rates,
        [ReviewsAnalysisFilterEnum.WithMessage]: withMessage,
        [ReviewsAnalysisFilterEnum.WithReply]: withReply,
        [ReviewsAnalysisFilterEnum.AbleToReply]: ableToReply,
    };

    const aggregation = getAggregationFromUrl(queries[ReviewsAnalysisFilterEnum.Aggregation]);

    if (aggregation !== null) {
        filterState[ReviewsAnalysisFilterEnum.Aggregation] = aggregation;
    }

    return filterState;
}

export function serializeSingleDrawerFilters(
    filter: ReviewsAnalysisFilterType,
    isTopicsAllowed?: boolean
): SingleFilterRequestType {
    const [startDate, endDate] = filter[ReviewsAnalysisFilterEnum.Range];

    return {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
        sentiments: filter[ReviewsAnalysisFilterEnum.Sentiments],
        sources: filter[ReviewsAnalysisFilterEnum.Catalogs],
        tags: filter[ReviewsAnalysisFilterEnum.Tags],
        phrases: filter[ReviewsAnalysisFilterEnum.Phrases],
        topics: isTopicsAllowed ? filter[ReviewsAnalysisFilterEnum.Topics] : [],
        rating: filter[ReviewsAnalysisFilterEnum.Rates],
        withAnswers: filter[ReviewsAnalysisFilterEnum.WithReply],
        withText: filter[ReviewsAnalysisFilterEnum.WithMessage],
        ableToReply: filter[ReviewsAnalysisFilterEnum.AbleToReply],
        // groupBy: filter[ReviewsAnalysisFilterEnum.Aggregation],
    };
}

export function serializePeriodDrawerFilters(filter: ReviewsAnalysisFilterType): ComparePeriodFilterRequestType {
    const [startDate, endDate] = filter[ReviewsAnalysisFilterEnum.CompareRange];

    return {
        ...serializeSingleDrawerFilters(filter),
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
    };
}

export function serializeCompetitorDrawerFilters(filter: ReviewsAnalysisFilterType): CompetitorFilterRequestType {
    const competitorId: number | null = filter[ReviewsAnalysisFilterEnum.Competitor];

    return {
        ...serializeSingleDrawerFilters(filter),
        mode: ReviewsAnalysisModeEnum.competitors,
        brandIds: competitorId ? [competitorId] : [],
    };
}

export function rangeDisabledDate(date: Dayjs): boolean {
    const yesterday = dayjs().subtract(2, 'day');

    return date.isSameOrAfter(yesterday);
}
