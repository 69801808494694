import {useQuery} from '@tanstack/react-query';
import {useCallback, useContext, useEffect, useMemo} from 'react';

import {useApiHooks} from '../../service/api-hook/api-hook';
import {UseHookType} from '../../service/api-hook/api-hook-type';
import {CompanyKeyEnum} from '../../service/company-v2/company-const';
import {CompanyFormType} from '../../service/company-v2/company-type';
import {Form, FormInstance} from '../../typings/antd';
import {useLocale} from '../locale/locale-hook';

import {
    fetchAllCPVisibleCatalogs,
    fetchAvailableCatalogs,
    fetchCatalogConfig,
    fetchCatalogs,
    fetchCatalogsRules,
    fetchPaidCatalogs,
} from './catalogs-api';
import {CatalogsContext} from './catalogs-context';
import {CatalogsContextType} from './catalogs-context-type';
import {
    AvailableCatalogHookType,
    CatalogConfigType,
    CatalogRulesType,
    CatalogType,
    FetchCatalogOptionType,
    FetchCatalogRulesOptionType,
    PaidCatalogsHookType,
    ShortCatalogType,
} from './catalogs-type';

export function useAllCatalogs(): {
    catalogs: Array<CatalogType>;
    isLoadingCatalogs: boolean;
} {
    const {isLoading, catalogs} = useContext<CatalogsContextType>(CatalogsContext);

    return useMemo(
        () => ({
            catalogs,
            isLoadingCatalogs: isLoading,
        }),
        [catalogs, isLoading]
    );
}

export function useFilteredCatalogs(
    fetchCatalogOption: FetchCatalogOptionType = {},
    mainFilterKey = ''
): UseHookType<Array<CatalogType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CatalogType>>();

    const {shortLocaleName} = useLocale();

    const {catalogType, canCollectReviews, isRelated, abilityToReply} = fetchCatalogOption;

    useEffect(() => {
        setIsInProgress(true);

        fetchCatalogs({catalogType, canCollectReviews, isRelated, abilityToReply}, mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        mainFilterKey,
        catalogType,
        canCollectReviews,
        setIsInProgress,
        setProcessError,
        setResult,
        isRelated,
        abilityToReply,
        shortLocaleName,
    ]);

    return {isInProgress, processError, result, reset};
}

export function useCatalog(catalogId: number): {
    isLoading: boolean;
    catalog: CatalogType | null;
} {
    const {isLoading, catalogs} = useContext<CatalogsContextType>(CatalogsContext);

    return {
        isLoading,
        catalog:
            catalogs.find((catalog) => {
                return catalog.id === catalogId;
            }) || null,
    };
}

export function useCatalogInfo(): {
    getCatalogName: (catalogId?: number) => string;
    getCatalogLogo: (catalogId?: number) => string;
} {
    const {reviewsCatalogs, catalogs} = useContext<CatalogsContextType>(CatalogsContext);

    const getCatalogName = useCallback(
        (catalogId?: number) => {
            const reviewsCatalogsName = reviewsCatalogs?.find(({id}) => id === catalogId)?.name ?? '';
            const catalogsName = catalogs?.find(({id}) => id === catalogId)?.name ?? '';

            return reviewsCatalogsName || catalogsName;
        },
        [reviewsCatalogs, catalogs]
    );

    const getCatalogLogo = useCallback(
        (catalogId?: number) => {
            return catalogs?.find(({id}) => id === catalogId)?.logo ?? '';
        },
        [catalogs]
    );

    return useMemo(() => {
        return {
            getCatalogLogo,
            getCatalogName,
        };
    }, [getCatalogLogo, getCatalogName]);
}

export function useCatalogConfig(regionId?: number | null, companyId?: number): UseHookType<Array<CatalogConfigType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CatalogConfigType>>();

    useEffect(() => {
        setIsInProgress(true);

        fetchCatalogConfig(companyId)
            .then((value) =>
                value
                    .map((catalog) => ({
                        ...catalog,
                        catalogId: Number.parseInt(catalog.catalogId, 10),
                    }))
                    .filter(({unsupportedRegionsIds}) => (regionId ? !unsupportedRegionsIds?.includes(regionId) : true))
            )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, regionId, setResult, companyId]);

    return {isInProgress, processError, result, reset};
}

export function useCatalogConfigForCompanyForm(): UseHookType<Array<CatalogConfigType>> {
    const region = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'region'>([
        CompanyKeyEnum.Address,
        'region',
    ]);

    return useCatalogConfig(region?.id);
}

export function useAllCPVisibleCatalogs(): UseHookType<Map<CatalogType['id'], CatalogType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Map<CatalogType['id'], CatalogType>>();

    useEffect(() => {
        setIsInProgress(true);

        fetchAllCPVisibleCatalogs()
            .then((response) => {
                const mapValue = new Map(response.map((item) => [item.id, item]));

                setResult(mapValue);
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useAvailableCatalogs(): AvailableCatalogHookType {
    const {isInProgress, setIsInProgress, result, setResult, processError, setProcessError} =
        useApiHooks<Array<ShortCatalogType>>();

    useEffect(() => {
        setIsInProgress(true);
        fetchAvailableCatalogs()
            .then((response) => setResult(response))
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setResult, setProcessError]);

    const isCatalogAvailable = useCallback(
        (catalogId: number) => result?.some(({id}) => id === catalogId) || false,
        [result]
    );

    return {
        isInProgress,
        processError,
        result,
        isCatalogAvailable,
    };
}

export function useCatalogsRules(
    fetchCatalogOption: FetchCatalogRulesOptionType,
    mainFilterKey = ''
): UseHookType<Array<CatalogRulesType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CatalogRulesType>>();

    const {autoReplySupported, gptSuggestSupported} = fetchCatalogOption;

    useEffect(() => {
        setIsInProgress(true);

        fetchCatalogsRules({autoReplySupported, gptSuggestSupported}, mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [autoReplySupported, gptSuggestSupported, mainFilterKey, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function usePaidCatalogs(): PaidCatalogsHookType {
    const queryResult = useQuery({
        queryKey: ['paidCatalogs'],
        queryFn: fetchPaidCatalogs,
    });

    const isCatalogPaid = useCallback(
        (catalogId: number) => queryResult.data?.some((id) => id === catalogId) || false,
        [queryResult.data]
    );

    return {
        ...queryResult,
        isCatalogPaid,
    };
}
