import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchComplaintsCount} from './complaints-count-api';
import {ComplaintsCountType} from './complaints-count-type';

export function useComplaintsCount(filter: ReviewsAndAnswersFilterStateType): UseQueryResult<ComplaintsCountType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['complaintsCount', filter, mainFilterKey],
        queryFn: async () => {
            return fetchComplaintsCount(filter, mainFilterKey);
        },
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}
