import {DivIcon, divIcon, Icon, icon} from 'leaflet';
import {renderToString} from 'react-dom/server';

import {LocalPackGridItemStatusEnum} from '../../../../../service/local-pack/local-pack-type';
import {LocalPackAdvertisementResultIcon} from '../../local-pack-result-icon/local-pack-advertisement-result-icon';
import {LocalPackResultIcon} from '../../local-pack-result-icon/local-pack-result-icon';

import failedIcon from './images/failed-icon.svg';
import newIcon from './images/new-icon.svg';
import {mapIconConfig} from './results-on-map-const';

function getIconForNewSearchResult(): Icon {
    return icon({
        ...mapIconConfig,
        iconUrl: newIcon,
    });
}

function getIconForFailedOrEmptySearchResult(): Icon {
    return icon({
        ...mapIconConfig,
        iconUrl: failedIcon,
    });
}

function getIconForCompleteSearchResult(position: number, isCenter = false): DivIcon {
    return divIcon({
        ...mapIconConfig,
        className: '',
        html: renderToString(<LocalPackResultIcon isCenter={isCenter} position={position} />),
    });
}

function getIconForAdvertisement(): DivIcon {
    return divIcon({
        ...mapIconConfig,
        className: '',
        html: renderToString(<LocalPackAdvertisementResultIcon />),
    });
}

export function getMapIconConfig(options: {
    position: number;
    status?: LocalPackGridItemStatusEnum;
    isAdvertisement?: boolean;
    isCenter?: boolean;
}): DivIcon | Icon {
    const {position, status, isCenter, isAdvertisement} = options;

    switch (true) {
        case isAdvertisement: {
            return getIconForAdvertisement();
        }
        case status === LocalPackGridItemStatusEnum.New:
            return getIconForNewSearchResult();
        case status === LocalPackGridItemStatusEnum.Complete:
            return getIconForCompleteSearchResult(position, isCenter);
        default: {
            return getIconForFailedOrEmptySearchResult();
        }
    }
}
