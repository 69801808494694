import {z as r} from 'zod';

import {UseHookType} from '../../api-hook/api-hook-type';
import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {SentimentEnum} from '../review-sentiment/review-sentiment-type';

export enum ReviewConcordanceRequestFieldEnum {
    Range = 'range',
    Query = 'q',
    ContextSize = 'contextSize',
}

export enum ConcordanceSentimentEnum {
    positive = 'positive',
    negative = 'negative',
    unspecified = 'unspecified',
    neutral = 'neutral',
}

const reviewConcordanceItemSchema = r.object({
    id: r.number(),
    query: r.string(),
    prevText: r.string(),
    nextText: r.string(),
    sentiment: r.nativeEnum(ConcordanceSentimentEnum).optional(),
});

export type ReviewConcordanceItemType = r.infer<typeof reviewConcordanceItemSchema>;

export const reviewConcordanceResponseSchema = r.object({
    count: r.number(),
    page: r.number(),
    pages: r.number(),
    pageSize: r.number(),
    next: r.string().nullable().optional(),
    previous: r.string().nullable().optional(),
    results: r.array(reviewConcordanceItemSchema),
});

export type ReviewConcordanceRequestType = {
    page: number;
    pageSize: number;
    [ReviewConcordanceRequestFieldEnum.Query]: string;
    [ReviewConcordanceRequestFieldEnum.ContextSize]: number;
    startDate: Date | null;
    endDate: Date | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
};

export type UseReviewConcordanceType = UseHookType<FetchReviewConcordanceResultType> & {
    fetch: (options: ReviewConcordanceRequestType, mainFilterKey: string) => void;
};

export type FetchReviewConcordanceResultType = r.infer<typeof reviewConcordanceResponseSchema>;

export const reviewConcordanceSentimentResponseSchema = r.object({
    [ConcordanceSentimentEnum.positive]: r.number(),
    [ConcordanceSentimentEnum.negative]: r.number(),
    [ConcordanceSentimentEnum.unspecified]: r.number(),
    [ConcordanceSentimentEnum.neutral]: r.number(),
});

export type ReviewConcordanceSentimentType = r.infer<typeof reviewConcordanceSentimentResponseSchema>;

export type ReviewConcordanceSentimentRequestType = Omit<ReviewConcordanceRequestType, 'page' | 'pageSize'>;

export type UseReviewConcordanceSentimentType = UseHookType<ReviewConcordanceSentimentType> & {
    fetch: (options: ReviewConcordanceSentimentRequestType, mainFilterKey: string) => void;
};
