import {z as r} from 'zod';

const linkBrandSchema = r.object({
    id: r.number(),
    name: r.string(),
});

export type LinkBrandType = r.infer<typeof linkBrandSchema>;

export const linkBrandsResponseSchema = r.array(linkBrandSchema);
