import {faTag} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../../../../../../../../../../component/text/text';
import {GoogleEventType, PostTypeEnum} from '../../../../../../../../../../../service/posts/posts-types';
import {getEventDate} from '../../google-preview-helper';

import * as styles from './event-header.scss';

type PropsType = {
    event?: GoogleEventType;
    topicType?: PostTypeEnum;
};

// eslint-disable-next-line complexity
export function EventHeader(props: PropsType): JSX.Element | null {
    const {event, topicType} = props;

    const isEmptyEvent = !event?.title && !event?.start && !event?.end;
    const showEvent = topicType === PostTypeEnum.googleEvent || topicType === PostTypeEnum.googleOffer;
    const isEndSameDay = event?.end?.isSame(event?.start, 'day');

    const dateContent = isEndSameDay
        ? getEventDate(event?.start)
        : `${getEventDate(event?.start)} - ${getEventDate(event?.end)}`;

    const time = event?.start && event?.end ? `${event.start.format('LT')} - ${event.end.format('LT')}` : '';

    if (!event || !showEvent || isEmptyEvent) {
        return null;
    }

    return (
        <div>
            <div className={styles.EventHeader_titleWrapper}>
                {event?.title && <h2 className={styles.EventHeader_title}>{event.title}</h2>}
                {topicType === PostTypeEnum.googleOffer && (
                    <FontAwesomeIcon className={styles.EventHeader_tag} icon={faTag} />
                )}
            </div>
            {event.start && event.end && (
                <Text>
                    {dateContent}, {time}
                </Text>
            )}
        </div>
    );
}
