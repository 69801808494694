import {UseHookType} from '../../api-hook/api-hook-type';

import {
    DashboardPostponeRecommendationPayloadApiType,
    DashboardPostponeRecommendationResponseApiType,
} from './dashboard-recommendations-api-type';

export enum DashboardRecommendationTypeEnum {
    check = 'check',
    review = 'review',
    answer = 'answer',
    gallery = 'gallery',
    description = 'description',
    category = 'category',
    needAction = 'need_action',
    fbToken = 'fb_token',
}

export type DashboardRecommendationItemType = {
    key: DashboardRecommendationTypeEnum;
    count: number;
    postponed?: number | null;
};

export type DashboardRecommendationsHookType = UseHookType<Array<DashboardRecommendationItemType>> & {
    onPostponeChange: (recommendationKey: DashboardRecommendationTypeEnum, postpone: number | null) => void;
};

export type DashboardRecommendationHookType = {
    isInProgress: boolean;
    postponeRecommendation: (
        recommendationKey: DashboardRecommendationTypeEnum,
        payload: DashboardPostponeRecommendationPayloadApiType
    ) => Promise<DashboardPostponeRecommendationResponseApiType>;
    cancelRecommendationPostpone: (recommendationKey: DashboardRecommendationTypeEnum) => Promise<void>;
};
