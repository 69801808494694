import {defaultPageSize} from '../cursor-pagination/cursor-pagination-const';

import {PaginationDataType, PaginationOptionsType, storagePageSizeSchema} from './pagination-hook-type';

const PAGE_STORAGE_KEY = 'paginationPage';

const PAGE_SIZE_STORAGE_KEY = 'paginationPageSize';

export function getPaginationInitialState(
    options: Omit<PaginationOptionsType, 'paginationId'> & {storageKey: string}
): Pick<PaginationDataType, 'page'> {
    const {shouldSaveState, storageKey} = options;

    if (shouldSaveState) {
        const storedPaginationState = sessionStorage.getItem(PAGE_STORAGE_KEY);
        const parseResult = storedPaginationState ? JSON.parse(storedPaginationState) : null;

        if (parseResult && parseResult.storageKey === storageKey) {
            return {page: parseResult.page};
        }
    }

    return {
        page: 1,
    };
}

export function saveNewPaginationState(storageKey: string, data: Pick<PaginationDataType, 'page'>): void {
    sessionStorage.setItem(
        PAGE_STORAGE_KEY,
        JSON.stringify({
            ...data,
            storageKey,
        })
    );
}

export function getPageSizeInitialState(
    options: Omit<PaginationOptionsType, 'paginationId'> & {storageKey: string}
): Pick<PaginationDataType, 'pageSize'> {
    const {shouldSaveState, storageKey, initialPageSize = defaultPageSize} = options;
    const storedPaginationState = localStorage.getItem(PAGE_SIZE_STORAGE_KEY);
    const paginationState = storedPaginationState ? JSON.parse(storedPaginationState) : {};

    if (shouldSaveState && paginationState[storageKey]) {
        const parseResult = storagePageSizeSchema.safeParse(paginationState[storageKey]);

        if (parseResult.success) {
            return {pageSize: parseResult.data.pageSize};
        }
    }

    return {
        pageSize: initialPageSize,
    };
}

export function savePageSizeState(storageKey: string, data: Pick<PaginationDataType, 'pageSize'>): void {
    const storedPaginationState = localStorage.getItem(PAGE_SIZE_STORAGE_KEY);
    const paginationState = storedPaginationState ? JSON.parse(storedPaginationState) : {};

    paginationState[storageKey] = data;
    localStorage.setItem(PAGE_SIZE_STORAGE_KEY, JSON.stringify(paginationState));
}
