import {RcFile} from 'antd/lib/upload';
import {inRange} from 'lodash';
import {ValidatorRule} from 'rc-field-form/lib/interface';

import {Locale} from '../../../../../../../../../provider/locale/localization';
import {ModalType} from '../../../../../../../../../provider/modal/modal-type';
import {convertFileToImage} from '../../../../../../../../../util/image';
import {BYTES_IN_MB, DEFAULT_MAX_IMAGE_SIZE_MB} from '../../../../../posts-form-helper';

export async function validateImageBeforeUpload(
    file: RcFile,
    modal: ModalType,
    options: {
        minWidth: number;
        minHeight: number;
        maxWidth: number;
        maxHeight: number;
    }
): Promise<boolean> {
    const {minWidth, minHeight, maxWidth, maxHeight} = options;

    const imageToUpload: HTMLImageElement | Error = await convertFileToImage(file).catch((error: Error) => error);

    if (imageToUpload instanceof Error) {
        modal.error({
            content: <Locale stringKey="POSTS_FORM__ERROR__IMAGE_UPLOAD" />,
            title: <Locale stringKey="POSTS_FORM__ERROR__IMAGE__TITLE" />,
        });
        return false;
    }

    if (file.size / BYTES_IN_MB > DEFAULT_MAX_IMAGE_SIZE_MB) {
        modal.error({
            content: (
                <Locale stringKey="POSTS_FORM__ERROR__IMAGE_SIZE" valueMap={{maxSize: DEFAULT_MAX_IMAGE_SIZE_MB}} />
            ),
            title: <Locale stringKey="POSTS_FORM__ERROR__IMAGE__TITLE" />,
        });
        return false;
    }

    const isHeightValid = inRange(imageToUpload.naturalHeight, minHeight, maxHeight);
    const isWidthValid = inRange(imageToUpload.naturalWidth, minWidth, maxWidth);

    const isValid = isHeightValid && isWidthValid;

    if (!isValid) {
        modal.error({
            content: (
                <Locale
                    stringKey="POSTS_FORM__ERROR__IMAGE_PROPORTIONS"
                    valueMap={{
                        width: imageToUpload.naturalWidth,
                        height: imageToUpload.naturalHeight,
                        minWidth,
                        minHeight,
                        maxWidth,
                        maxHeight,
                    }}
                />
            ),
            title: <Locale stringKey="POSTS_FORM__ERROR__IMAGE__TITLE" />,
        });
    }

    return isValid;
}

export function noImagesInProgressValidator(hasImagesInProgress: boolean, errorMessage: string): ValidatorRule {
    return {
        validator: () => {
            if (hasImagesInProgress) {
                return Promise.reject(new Error(errorMessage));
            }

            return Promise.resolve();
        },
    };
}
