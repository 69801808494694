import {mergeWith} from 'lodash';

import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {CatalogNameType, cclStatisticSchema, CclStatisticType} from './ccl-statistic-type';

type CclParametersType = {
    catalog_type: CatalogNameType;
    filter_key: string | null;
    catalog_ids?: string;
    company_ids?: Array<string>;
};

async function fetchStatisticData(parameters?: CclParametersType) {
    const virtualCclStatistic = fetchAndDeserialize<CclStatisticType>(
        `/cp/ccl/v_statistic/` + (parameters ? `?${objectToUrlParameters(parameters)}` : ''),
        cclStatisticSchema
    );
    const realCclStatistic = fetchAndDeserialize<CclStatisticType>(
        `/cp/ccl/r_statistic/` + (parameters ? `?${objectToUrlParameters(parameters)}` : ''),
        cclStatisticSchema
    );

    const [virtual, real] = await Promise.all([virtualCclStatistic, realCclStatistic]);

    return mergeWith(virtual, real, (virtualItem, realItem) => virtualItem + realItem);
}

// eslint-disable-next-line max-params
export async function fetchCclStatistic(
    mainFilterKey: string,
    catalogType: CatalogNameType,
    isNewCompanies: boolean,
    sourceIdList?: Array<string>,
    companyIdList?: Array<string>,
    accessTransferRequired?: boolean
): Promise<CclStatisticType> {
    const parameters = {
        catalog_type: catalogType,
        filter_key: companyIdList?.length ? null : mainFilterKey,
        catalog_ids: sourceIdList?.join(','),
        company_ids: companyIdList,
        new_companies: isNewCompanies,
        access_transfer_required: accessTransferRequired,
    };

    return fetchStatisticData(parameters);
}

export async function fetchAllCclStatistic(): Promise<CclStatisticType> {
    return fetchStatisticData();
}
