import {useContext, useMemo} from 'react';

import {BrandsContextType} from './brand-context-type';
import {BrandType} from './brand-type';
import {BrandsContext} from './brands-context';

export function useBrands(): {
    brands: Array<BrandType>;
    isLoading: boolean;
    firstBrand: BrandType | null;
} {
    return useContext<BrandsContextType>(BrandsContext);
}

export function useBrand(brandId?: number | null): {
    brand: BrandType | null;
    isLoading: boolean;
} {
    const {isLoading, brands} = useContext<BrandsContextType>(BrandsContext);

    const brand = useMemo(() => {
        if (!brandId) {
            return null;
        }

        return brands.find((brandFromList) => brandFromList.id === brandId) ?? null;
    }, [brandId, brands]);

    return useMemo(() => {
        return {
            brand,
            isLoading: brandId ? isLoading : false,
        };
    }, [brand, brandId, isLoading]);
}
