import {Tooltip} from 'antd';
import {ReactElement} from 'react';

import {CatalogLogo} from '../../../../../../../../component/catalog-logo/catalog-logo';
import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {CompaniesImportTypeEnum} from '../../../../../../../../service/companies-import/companies-import-type';
import {ProvidersIdsEnum} from '../../../../../../../../util/type';

type PropsType = {
    feedType: CompaniesImportTypeEnum;
    logoSrc?: string;
};

export function FeedTypeLogo(props: PropsType): ReactElement {
    const {feedType} = props;
    const {feedTypeLabels, logoShortSrc} = useDomainConfig();

    if (feedType === CompaniesImportTypeEnum.YandexXml) {
        return (
            <Tooltip placement="bottom" title={feedTypeLabels?.[feedType]}>
                <div>
                    <CatalogLogo catalogId={ProvidersIdsEnum.yandex} />
                </div>
            </Tooltip>
        );
    }

    return (
        <Tooltip placement="bottom" title={feedTypeLabels?.[feedType]}>
            <img alt="logo" src={logoShortSrc} width={15} />
        </Tooltip>
    );
}
