import {isNumber} from 'lodash';

import {CompaniesImportParsingErrorCodeEnum} from '../../../../../../service/companies-import/companies-import-type';

export const feedResultsParsingErrors = Object.values(CompaniesImportParsingErrorCodeEnum).filter(isNumber);

export enum FeedResultsTypeEnum {
    Import,
    Validation,
}
