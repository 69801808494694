import {z as r} from 'zod';

import {StateHooksType} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

export type StoreLocatorConfigShortType = r.infer<typeof storeLocatorConfigShortSchema>;

const storeLocatorConfigShortSchema = r.object({
    id: r.number(),
    website: r.string(),
    name: r.string(),
    countLocations: r.number(),
});

export const storeLocatorConfigListSchema = r.array(storeLocatorConfigShortSchema);

export enum StoreLocatorLanguageEnum {
    Ru = 'ru',
    En = 'en',
    Es = 'es',
    Pl = 'pl',
    Ro = 'ro',
    Ge = 'ge',
    Bg = 'bg',
    Ar = 'ar',
}

export const storeLocatorConfigFullSchema = r.object({
    id: r.number(),
    token: r.string(),
    name: r.string(),
    website: r.string(),
    companies: r.array(r.number()),
    language: r.nativeEnum(StoreLocatorLanguageEnum),
    finishedStep: r.number(),
    publicName: r.string(),
    mapboxKey: r.string().nullable(),
    locatorConfig: r.object({
        leftListLocation: r.boolean(),
        logo: r.string().url().nullable(),
        showAddressSearch: r.boolean(),
        showOpenedNowFilter: r.boolean(),
        pinColor: r.string().min(4).max(9).regex(/^#/),
        buttonsColor: r.string().min(4).max(9).regex(/^#/),
        openedNowColor: r.string().min(4).max(9).regex(/^#/),
        locatorBackgroundColor: r.string().min(4).max(9).regex(/^#/),
        cardBackgroundColor: r.string().min(4).max(9).regex(/^#/),
        mainTextAndHeaderColor: r.string().min(4).max(9).regex(/^#/),
        secondaryTextColor: r.string().min(4).max(9).regex(/^#/),
        subtitlesAndIconsColor: r.string().min(4).max(9).regex(/^#/),
        listShowLocationName: r.boolean(),
        listShowPhoneNumber: r.boolean(),
        listShowOpenedNow: r.boolean(),
        listShowGalleryPhotos: r.boolean(),
        listShowWorkingHours: r.boolean(),
        listShowBuildRouteButton: r.boolean(),
        pinShowLocationName: r.boolean(),
        pinShowCompanyLogo: r.boolean(),
        pinShowOpenedNow: r.boolean(),
        pinShowPhoneNumber: r.boolean(),
        pinShowBuildRouteButton: r.boolean(),
        seoTitle: r.string().nullable(),
        seoDescription: r.string().nullable(),
        personalAccountDomain: r.string().nullable(),
    }),
    cardConfig: r.object({
        showOnSeparatePage: r.boolean(),
        cardShowLocationName: r.boolean(),
        cardShowBuildRouteButton: r.boolean(),
        cardShowCoordinates: r.boolean(),
        cardShowOpenedNow: r.boolean(),
        cardShowWorkingHours: r.boolean(),
        cardShowBreaks: r.boolean(),
        cardShowPaymentMethods: r.boolean(),
        cardShowSocialNetworks: r.boolean(),
        cardShowGallery: r.boolean(),
        showLocationFeedbacks: r.boolean(),
        showFeedbacksWithStars: r.array(r.number()),
        feedbacksCount: r.number(),
        cardSeoTitle: r.string().nullable(),
        cardSeoDescription: r.string().nullable(),
    }),
});

export type StoreLocatorConfigFullType = r.infer<typeof storeLocatorConfigFullSchema>;

export type StoreLocatorCreatePayloadType = Pick<
    StoreLocatorConfigFullType,
    'name' | 'website' | 'companies' | 'mapboxKey'
>;

export type StoreLocatorUpdatePayloadType = Partial<Omit<StoreLocatorConfigFullType, 'id' | 'token'>>;

export const enum StoreLocatorWebsiteErrorCodesEnum {
    invalidDomainUrl = 'invalid_domain_url',
    locatorWebsiteExists = 'locator_website_exists',
}

export const enum StoreLocatorMapboxKeyErrorCodesEnum {
    InvalidLocatorMapboxKey = 'invalid_locator_mapbox_key',
}

export const enum StoreLocatorCompaniesErrorCodesEnum {
    unavailableCompanyId = 'unavailable_company_id',
    emptyCompanies = 'empty_companies',
    improperlyConfigured = 'improperly_configured',
}

export type StoreLocatorManageServerErrorType = {
    detail: {
        name: 'empty_name';
        website: StoreLocatorWebsiteErrorCodesEnum;
        companies: StoreLocatorCompaniesErrorCodesEnum;
        mapboxKey: StoreLocatorMapboxKeyErrorCodesEnum;
    };
};

export type StoreLocatorItemContextType = StateHooksType<StoreLocatorConfigFullType, Error>;

export type StoreLocatorConfigFullManageHookType = UseHookType<StoreLocatorConfigFullType> & {
    create: (values: StoreLocatorCreatePayloadType) => Promise<StoreLocatorConfigFullType>;
    update: (
        id: number,
        values: StoreLocatorUpdatePayloadType,
        initialStep?: boolean
    ) => Promise<StoreLocatorConfigFullType>;
};

export enum StoreLocatorLogoErrorCodeEnum {
    NotFile = 'not_file',
    BrokenFile = 'broken_file',
    InvalidFileExtension = 'invalid_file_extension',
    InvalidFileSize = 'invalid_file_size',
}

export type StoreLocatorLogoErrorDataType = {
    detail: {
        file: StoreLocatorLogoErrorCodeEnum;
    };
};
