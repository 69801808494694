/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {z as r, ZodType} from 'zod';

const responseBaseSchema = r.object({
    count: r.number(),
    next: r.string().nullable().optional(),
    previous: r.string().nullable().optional(),
    page: r.number().optional(),
    pageSize: r.number().optional(),
    pages: r.number().optional(),
});

export function generateResponseSchema<T extends ZodType>(itemSchema: T) {
    return responseBaseSchema.merge(
        r.object({
            results: r.array(itemSchema),
        })
    );
}

export function getIdNameSchema() {
    return generateResponseSchema(
        r.object({
            id: r.number(),
            name: r.string(),
        })
    );
}

export function getCountryV2Schema() {
    return generateResponseSchema(
        r.object({
            id: r.number(),
            name: r.string(),
            lang: r.string(),
            countryCode: r.string(),
        })
    );
}

export function getCityV2Schema() {
    return generateResponseSchema(
        r.object({
            id: r.number(),
            name: r.string(),
            lat: r.number(),
            lon: r.number(),
        })
    );
}

export type PaginatedResponseType<ResultItemType> = r.infer<typeof responseBaseSchema> & {
    results: Array<ResultItemType>;
};

export function cursorPaginationResponseSchemaFactory<T extends ZodType>(resultSchema: T) {
    return r.object({
        results: r.array(resultSchema),
        next: r.string().nullable(),
        previous: r.string().nullable(),
        count: r.number().nullable().optional(),
    });
}

export type CursorPaginationResponseType<ResultItemType> = {
    results: Array<ResultItemType>;
    next?: string | null;
    previous?: string | null;
};

export type RequestOptionsType = {
    count?: number;
    page?: number; // start with 1
    q?: string | null;
};

export type LegalOptionDataType = {
    id: string;
    name: string;
};

export type LegalOptionV2Type = {
    form_id: string;
    form_name: string;
};

export type LegalOptionDataV2Type = {
    alpha2: string;
    legal_forms: Array<LegalOptionV2Type>;
};

export type CompanyCategoryType = {
    id: number;
    path: string;
    translate: string;
};

export type CompanyCatalogCategoryType = {
    category_origin_id: string;
    category_label: string;
    category_name: string;
};

export type CommonCategoryApiType = CompanyCategoryType | CompanyCatalogCategoryType;

export const idNameTypeSchema = r.object({
    id: r.number(),
    name: r.string(),
});

export type IdNameType = r.infer<typeof idNameTypeSchema>;

export type CountryV2Type = IdNameType & {
    lang: string;
    countryCode: string;
};

export type CountryType = {
    alpha2: string; // "ru"
    id: number; // 1
    language_code: string; // "ru"
    name: string; // "Россия"
};

export type CityV2Type = IdNameType & {
    lat: number;
    lon: number;
};

export type AddressType = {
    area: string | null;
    country: string; // "RUS"
    country_code: string; // "RU"
    formatted_address: string | null; // "Хабаровск, Гамарника, 12"
    house: string | null;
    lat: number | null; // 54.717606
    locality: string | null; // "Хабаровск" - город/city
    lon: number | null; // 135.316318
    postal_code: string | null;
    province: string | null; // "Хабаровский край" - область/region
    source: string; // "doublegis"
    street: string | null; // "Гамарника"
};

export type TimezoneType = {
    name: string; // "Africa/Abidjan"
    name_for_display: string; // "Africa/Abidjan (UTC +00:00)"
    offset: string; // "+0000"
};

export type CsrfHeadersType = {
    'x-csrftoken': string;
};
