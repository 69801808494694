import {Select} from 'antd';
import {Rule} from 'antd/lib/form';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {PostFbPageType, PostFormFieldsEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {Form} from '../../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../../post-form-type';

type PropsType = {
    pages?: Array<PostFbPageType>;
    isLoading: boolean;
    rules: Array<Rule>;
} & PostFormItemPropsType;

export function FacebookPagesItem(props: PropsType): JSX.Element {
    const {pages, isLoading, rules, disabled} = props;

    return (
        <Form.Item<PostFormType>
            label={<Locale stringKey="POSTS_FORM__FB_PAGES__LABEL" />}
            name={PostFormFieldsEnum.FbPagesIds}
            rules={!disabled ? rules : []}
            validateFirst={false}
        >
            <Select<Array<string>>
                disabled={disabled}
                loading={isLoading}
                mode="multiple"
                optionFilterProp="children"
                placeholder={<Locale stringKey="POSTS_FORM__FB_PAGES__PLACEHOLDER" />}
            >
                {pages?.map((page) => (
                    <Select.Option key={page.id} value={page.id}>
                        {page.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
