import {forwardRef} from 'react';

import {
    OnlinePresencePhrasesHookDataType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';
import {PhrasesTable} from '../phrases-table/phrases-table';

import {OnlinePresenceFilterPdf} from './filter/online-presence-filter-pdf';
import * as styles from './online-presence-pdf-content.scss';

type PropsType = {
    result: OnlinePresencePhrasesHookDataType;
    filter: OnlinePresenceV2FilterStateType;
    isCompareMode: boolean;
};

export const OnlinePresencePhrasesPdfContent = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const {result, isCompareMode, filter} = props;

    return (
        <div className={styles.PdfContainer} ref={ref}>
            <OnlinePresenceFilterPdf
                comparePeriod={[
                    filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart],
                    filter[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd],
                ]}
                mainPeriod={[
                    filter[OnlinePresenceV2FilterQueriesEnum.PeriodStart],
                    filter[OnlinePresenceV2FilterQueriesEnum.PeriodEnd],
                ]}
            />
            <PhrasesTable
                count={result?.count || null}
                data={result?.results || []}
                isCompareMode={isCompareMode}
                isInProgress={false}
                pagination={false}
            />
        </div>
    );
});

OnlinePresencePhrasesPdfContent.displayName = 'OnlinePresenceDashboardPdfContent';
