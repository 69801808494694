import {UsedAutoRepliesCountType} from '../../../../service/reviews-and-answers-statistics/used-auto-replies-count/used-auto-replies-count-type';
import {StatisticsMetric} from '../statistics-metric/statistics-metric';

type PropsType = {
    pdfPreview?: boolean;
    isLoading?: boolean;
    isCompareMode: boolean;
    usedAutoRepliesCount: UsedAutoRepliesCountType | null;
};

export function UsedAutoReplies(props: PropsType): JSX.Element {
    const {pdfPreview, isCompareMode, isLoading, usedAutoRepliesCount} = props;

    return (
        <StatisticsMetric
            compareData={
                usedAutoRepliesCount?.count?.comparedValue
                    ? {
                          value: usedAutoRepliesCount.count.comparedValue,
                          delta: usedAutoRepliesCount.count.delta || 0,
                      }
                    : null
            }
            isCompareMode={isCompareMode}
            isLoading={isLoading}
            mainData={{
                value: usedAutoRepliesCount?.count?.value || 0,
                values: usedAutoRepliesCount?.byDate || [],
            }}
            pdfPreview={pdfPreview}
            titleLangKeyType="REVIEW_AND_ANSWER_STATISTICS__USED_AUTO_ANSWERS__TITLE"
        />
    );
}
