import {ShortLocaleNameEnum} from '../../locale/locale-context-type';

export const smartLookToken = 'f1f00f7c704a4f07a1c3de488f178cbf7e8837a7';

export const usetifulSettings = {
    src: 'https://www.usetiful.com/dist/usetiful.js',
    token: '4df6d28715bb96a523ae55f46ec66445',
};

export const initialChatMessage: Record<ShortLocaleNameEnum.en | ShortLocaleNameEnum.ru, string> = {
    [ShortLocaleNameEnum.ru]: 'Добрый день! \nОставьте ваш вопрос, и наш специалист ответит в ближайшее время.',
    [ShortLocaleNameEnum.en]:
        'Hello! \nPlease leave your message, and our specialists will get back to you as soon as possible.',
};

export const helpDeskChatSettings = {
    url: '//cdn5.helpdeskeddy.com//js/modules/chat_widget/hde.js',
    host: 'roketdata.helpdeskeddy.com',
    id: 'hde-chat-widget',
    position: 'bottom-right',
    buttonId: 'hde-button',
};
