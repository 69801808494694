import {useCatalog} from '../../provider/catalogs/catalogs-hook';
import {classNames} from '../../util/css';
import {PROVIDER_TO_ITS_ID_MAP, ProvidersEnum} from '../../util/type';

import {CatalogInfoRenderer} from './catalog-info-renderer';
import * as styles from './catalog-info.scss';

type PropsType = {
    className?: string;
    iconClassName?: string;
    hideName?: boolean;
    displayName?: string | null;
} & (
    | {
          provider: ProvidersEnum;
          providerId?: never;
      }
    | {
          provider?: never;
          providerId: number;
      }
);

export function CatalogInfo(props: PropsType): JSX.Element {
    const {provider, hideName, className, iconClassName, displayName, providerId} = props;

    const {catalog} = useCatalog(providerId ?? PROVIDER_TO_ITS_ID_MAP[provider]);

    return (
        <CatalogInfoRenderer
            catalogName={hideName ? '' : displayName ?? catalog?.name ?? ''}
            className={classNames(styles.CatalogInfo, className)}
            hideName={hideName}
            iconClassName={iconClassName}
            logoUrl={catalog?.logo || ''}
        />
    );
}
