import dayjs from 'dayjs';

import {
    DatePeriodEnum,
    DateRangeType,
    endOfUtcDay,
    getCloserFullMonth,
    getCloserFullQuarter,
    getCloserFullWeekRange,
    getDaysBetween,
    getIsoDateWithTimezone,
    startOfUtcDay,
} from '../../../util/date';
import {getEnumValueEnsure} from '../../../util/enum';
import {NullableType} from '../../../util/type';

import {
    ReviewsAndAnswersCompareEnum,
    ReviewsAndAnswersFilterQueriesEnum,
    ReviewsAndAnswersFilterStateType,
} from './reviews-and-answers-statistics-filter-type';

export function getAnalyticsRangeByPeriodAndDate(lastDay: Date, period: DatePeriodEnum): DateRangeType {
    switch (period) {
        case DatePeriodEnum.Week: {
            return getCloserFullWeekRange(lastDay);
        }
        case DatePeriodEnum.Month: {
            const lastDayDayjs = dayjs(lastDay);

            return {
                start: lastDayDayjs.subtract(29, 'days').toDate(),
                end: lastDay,
            };
        }
        case DatePeriodEnum.PreviousFullMonth: {
            return getCloserFullMonth(lastDay);
        }
        case DatePeriodEnum.Quarter: {
            return getCloserFullQuarter(lastDay);
        }
        default: {
            return {
                start: lastDay,
                end: lastDay,
            };
        }
    }
}

export function getComparePeriod(
    mainPeriod: DateRangeType,
    period: DatePeriodEnum,
    compareMode: ReviewsAndAnswersCompareEnum | null
): NullableType<DateRangeType> {
    if (!compareMode) {
        return {
            start: null,
            end: null,
        };
    }

    switch (true) {
        case compareMode === ReviewsAndAnswersCompareEnum.PreviousYear: {
            return {
                start: dayjs(mainPeriod.start).subtract(1, 'year').toDate(),
                end: dayjs(mainPeriod.end).subtract(1, 'year').toDate(),
            };
        }
        case compareMode === ReviewsAndAnswersCompareEnum.PreviousPeriod && period !== DatePeriodEnum.Custom: {
            return getAnalyticsRangeByPeriodAndDate(dayjs(mainPeriod.start).subtract(1, 'day').toDate(), period);
        }
        default: {
            const countDays = getDaysBetween(mainPeriod.start, mainPeriod.end);

            return {
                start: dayjs(mainPeriod.start)
                    .subtract(countDays + 1, 'days')
                    .toDate(),
                end: dayjs(mainPeriod.end)
                    .subtract(countDays + 1, 'days')
                    .toDate(),
            };
        }
    }
}

export function getInitialPeriod(periodRaw: string | void): DatePeriodEnum {
    return getEnumValueEnsure<DatePeriodEnum>(DatePeriodEnum, periodRaw, DatePeriodEnum.Month);
}

export function reviewsAndAnswersApiFilterValues(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Record<string, string> {
    return {
        start_date: getIsoDateWithTimezone(
            startOfUtcDay(new Date(filter[ReviewsAndAnswersFilterQueriesEnum.PeriodStart]))
        ),
        end_date: getIsoDateWithTimezone(endOfUtcDay(new Date(filter[ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]))),
        ...(filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]
            ? {
                  compared_start_date: getIsoDateWithTimezone(
                      startOfUtcDay(new Date(filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]))
                  ),
              }
            : {}),
        ...(filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]
            ? {
                  compared_end_date: getIsoDateWithTimezone(
                      endOfUtcDay(new Date(filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]))
                  ),
              }
            : {}),
        filter_key: mainFilterKey,
    };
}
