import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faEye} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Image, Popover, Typography} from 'antd';
import {useCallback, useState} from 'react';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {CompanyImageFormType} from '../../../../../../../../service/company-v2/types/company-logo-type';
import {classNames} from '../../../../../../../../util/css';

import * as styles from './gallery-image.scss';

type PropsType = {
    inputImage: CompanyImageFormType;
    className?: string;
    errorMessage?: Array<string>;
    onRemove: () => void;
};

export function GalleryImage(props: PropsType): JSX.Element {
    const {className, inputImage, errorMessage, onRemove} = props;
    const {objectUrl, url} = inputImage;

    const fullClassName = classNames(styles.input_image_list_item, className);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const handleDelete = useCallback(() => {
        if (objectUrl) {
            URL.revokeObjectURL(objectUrl);
        }

        onRemove();
    }, [objectUrl, onRemove]);

    const image = (
        <>
            <Image
                className={styles.input_image_list_item__image}
                preview={{
                    visible: previewOpen,
                    onVisibleChange: (value) => setPreviewOpen(value),
                }}
                src={(url || objectUrl) ?? undefined} // eslint-disable-line no-undefined
            />

            <div className={styles.input_image_list_item__overlay}>
                <Button
                    className={styles.input_image_list_item__button}
                    icon={<FontAwesomeIcon icon={faEye} />}
                    onClick={() => {
                        setPreviewOpen(true);
                        setPopoverOpen(false);
                    }}
                    type="text"
                />

                <Button
                    className={styles.input_image_list_item__button}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={handleDelete}
                    type="text"
                />
            </div>
        </>
    );

    if (errorMessage) {
        return (
            <div className={fullClassName}>
                <Popover
                    content={
                        <>
                            <Typography.Paragraph className={styles.error_text}>
                                <Locale stringKey="COMPANY_FORM__GALLERY__ERROR__DESCRIPTION" />
                            </Typography.Paragraph>

                            {errorMessage.map((message) => (
                                <Typography.Paragraph className={styles.error_text} key={message}>
                                    {message}
                                </Typography.Paragraph>
                            ))}
                        </>
                    }
                    onOpenChange={(value) => setPopoverOpen(value)}
                    open={popoverOpen}
                    title={<Locale stringKey="COMPANY_FORM__GALLERY__ERROR__HEADER" />}
                >
                    {/* https://github.com/ant-design/ant-design/issues/15909#issuecomment-1030778255 */}
                    <div className={styles.input_image_list_item__image}>{image}</div>
                </Popover>
            </div>
        );
    }

    return <div className={fullClassName}>{image}</div>;
}
