import {faGear} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../app-route';
import {AccountsLinkingDefault} from '../../../../page/main/account-linking/accounts-linking-default';
import {CompaniesGroup} from '../../../../page/main/companies-group/companies-group';
import {CompaniesGroupActivation} from '../../../../page/main/companies-group/companies-group-activation';
import {CompaniesGroupCreate} from '../../../../page/main/companies-group-create/companies-group-create';
import {CompaniesGroupEdit} from '../../../../page/main/companies-group-edit/companies-group-edit';
import {Integrations} from '../../../../page/main/integrations/integrations';
import {Notification} from '../../../../page/main/notification/notification';
import {Profile} from '../../../../page/main/profile/profile';
import {CreateUser} from '../../../../page/main/settings/users/form/create/create-user';
import {EditUserPageGuard} from '../../../../page/main/settings/users/form/edit/edit-user-page-guard';
import {Users} from '../../../../page/main/settings/users/users';
import {SourceAccountsPage} from '../../../../page/main/source-settings/pages/source-accounts-page/source-accounts-page';
import {SourceCatalogPage} from '../../../../page/main/source-settings/pages/source-catalog-page/source-catalog-page';
import {SourceSettingsIndex} from '../../../../page/main/source-settings/source-settings-index';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {NavigationItemConfigType} from '../routing-type';

export function useSettingsNavigation(): Array<NavigationItemConfigType> {
    const {isUserProfileShow, isSourceSettingsShown} = useDomainConfig();
    const {licenses, availableFeatures} = useLicenses();

    return [
        {
            isAvailable: availableFeatures[FeaturesEnum.settings],
            path: appRoute.settings.path,
            icon: <FontAwesomeIcon icon={faGear} />,
            labelLangKey: 'CATEGORY_NAME__SETTINGS',
            exact: false,
            redirectPath: appRoute.settingsProfile.path,
            deniedPath: [appRoute.settings.path],
            children: [
                {
                    isAvailable: isUserProfileShow,
                    path: appRoute.settingsProfile.path,
                    labelLangKey: 'CATEGORY_NAME__PROFILE',
                    exact: true,
                    component: Profile,
                },
                {
                    isAvailable:
                        availableFeatures[FeaturesEnum.userManagement] &&
                        licenses[FeaturesEnum.userManagement]?.isActive,
                    path: appRoute.users.path,
                    labelLangKey: 'CATEGORY_NAME__USERS',
                    exact: true,
                    component: Users,
                },
                {
                    isOnlyRoute: true,
                    isAvailable:
                        availableFeatures[FeaturesEnum.userManagement] &&
                        licenses[FeaturesEnum.userManagement]?.isActive,
                    path: appRoute.createUser.path,
                    exact: true,
                    component: CreateUser,
                },
                {
                    isOnlyRoute: true,
                    isAvailable:
                        availableFeatures[FeaturesEnum.userManagement] &&
                        licenses[FeaturesEnum.userManagement]?.isActive,
                    path: appRoute.editUser.path,
                    exact: true,
                    component: EditUserPageGuard,
                },
                {
                    path: appRoute.accountLinkingSettings.path,
                    labelLangKey: 'CATEGORY_NAME__ACCOUNTS_LINKING',
                    exact: true,
                    component: AccountsLinkingDefault,
                },
                {
                    isAvailable:
                        availableFeatures[FeaturesEnum.companyGroups] && licenses[FeaturesEnum.companyGroups]?.isActive,
                    path: appRoute.settingsCompaniesGroup.path,
                    labelLangKey: 'CATEGORY_NAME__GROUPS_OF_COMPANIES',
                    exact: true,
                    component: licenses[FeaturesEnum.companyGroups] ? CompaniesGroup : CompaniesGroupActivation,
                },
                // todo: only routes
                {
                    isOnlyRoute: true,
                    isAvailable:
                        availableFeatures[FeaturesEnum.companyGroups] && licenses[FeaturesEnum.companyGroups]?.isActive,
                    path: appRoute.settingsCompaniesGroupCreate.path,
                    exact: true,
                    component: licenses[FeaturesEnum.companyGroups]?.isActive
                        ? CompaniesGroupCreate
                        : CompaniesGroupActivation,
                },
                {
                    isOnlyRoute: true,
                    isAvailable:
                        availableFeatures[FeaturesEnum.companyGroups] && licenses[FeaturesEnum.companyGroups]?.isActive,
                    path: appRoute.settingsCompaniesGroupEdit.path,
                    exact: true,
                    component: licenses[FeaturesEnum.companyGroups]?.isActive
                        ? CompaniesGroupEdit
                        : CompaniesGroupActivation,
                },
                {
                    isAvailable: isUserProfileShow,
                    path: appRoute.settingsNotification.path,
                    labelLangKey: 'CATEGORY_NAME__NOTIFICATIONS',
                    exact: true,
                    component: Notification,
                },
                {
                    isAvailable: availableFeatures[FeaturesEnum.integrations],
                    path: appRoute.settingsIntegrations.path,
                    labelLangKey: 'CATEGORY_NAME__INTEGRATION',
                    exact: true,
                    component: Integrations,
                },
                {
                    isAvailable: isSourceSettingsShown,
                    path: appRoute.settingsSourceSettings.path,
                    labelLangKey: 'CATEGORY_NAME__SOURCE_SETTINGS',
                    exact: true,
                    component: SourceSettingsIndex,
                },
                {
                    isOnlyRoute: true,
                    isAvailable: isSourceSettingsShown,
                    path: appRoute.settingsSourceSettingsCatalog.path,
                    labelLangKey: 'CATEGORY_NAME__SOURCE_SETTINGS',
                    exact: true,
                    component: SourceCatalogPage,
                },
                {
                    isOnlyRoute: true,
                    isAvailable: isSourceSettingsShown,
                    path: appRoute.settingsSourceSettingsAccount.path,
                    labelLangKey: 'CATEGORY_NAME__SOURCE_SETTINGS',
                    exact: true,
                    component: SourceAccountsPage,
                },
            ],
        },
    ];
}
