import {ExtractRouteParams} from 'react-router';
import {generatePath, useParams} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {
    defaultResponseTemplateData,
    useResponseTemplateById,
} from '../../../service/reviews/reviews-response-templates';
import {ResponseTemplatesForm} from '../response-templates-form/response-templates-form';

import * as styles from './response-templates-edit-template.scss';

export function ResponseTemplatesEditTemplate(): JSX.Element {
    const {
        reviewsManagementResponseTemplates: templatesRoute,
        reviewsManagementResponseTemplatesEditTemplate: templatesEditTemplateRoute,
    } = appRoute;

    const {templateId} = useParams<ExtractRouteParams<typeof templatesEditTemplateRoute.path, string>>();
    const {data, isFetching: isInProgress} = useResponseTemplateById(templateId);
    const {getLocalizedString} = useLocale();

    return (
        <Page mainClassName={styles.ResponseTemplatesEditTemplate}>
            <Meta title={getLocalizedString('RESPONSE_TEMPLATES__EDITING_A_TEMPLATE')} />

            <BreadCrumbs
                list={[
                    {path: templatesRoute.path, titleLangKey: 'CATEGORY_NAME__RESPONSE_TEMPLATES'},
                    {
                        path: generatePath(templatesEditTemplateRoute.path, {templateId}),
                        titleLangKey: 'RESPONSE_TEMPLATES__EDITING_A_TEMPLATE',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="RESPONSE_TEMPLATES__EDITING_A_TEMPLATE" />
            </PageHeader>

            <Spinner isShow={isInProgress} position="absolute" />

            <ResponseTemplatesForm mode="edit" responseTemplate={data || defaultResponseTemplateData} />
        </Page>
    );
}
