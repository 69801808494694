import {appRoute} from '../../../app-route';
import {ActivationPage} from '../../../layout/activation-page/activation-page';
import {useDocumentationLinks} from '../../../provider/help-links/help-links-hook';
import {Locale} from '../../../provider/locale/locale';
import {FeaturesEnum} from '../../../service/user/user-type';

export function FakesActivation(): JSX.Element {
    const {featureActivation} = useDocumentationLinks();

    return (
        <ActivationPage
            breadCrumbList={[
                {
                    path: appRoute.duplicatesAndFakes.path,
                    titleLangKey: 'CATEGORY_NAME__DUBS_AND_FAKES',
                },
            ]}
            interestedMessageLangKey="ACTIVATION_PAGE__INTERESTED_CONTACT_YOUR_MANAGER_TO_ACTIVATE_THIS_SERVICE__DUBS_AND_FAKES"
            link={featureActivation[FeaturesEnum.duplicatesAndFakes]}
            title={<Locale stringKey="CATEGORY_NAME__DUBS_AND_FAKES" />}
        />
    );
}
