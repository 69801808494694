import {useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query';
import {ModalFunc} from 'antd/lib/modal/confirm';
import {isArray} from 'lodash';
import {useEffect, useState} from 'react';
import {z as r} from 'zod';

import {CompaniesReportStepEnum} from '../../page/main/my-companies/companies-report/companies-report-const';
import {Locale} from '../../provider/locale/localization';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {fetchAndDeserialize, postAndDeserialize} from '../../util/api-adapter';
import {fetchBlob, FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

const DELAY_IN_SECOND = 5;

const postCreateReportSchema = r.object({
    uuid: r.string(),
});

const companiesReportSchema = r.array(
    r.object({
        uuid: r.string(),
        status: r.object({
            status: r.nativeEnum(CompaniesReportStepEnum),
            processed: r.number(),
            all: r.number(),
        }),
        link: r.string(),
        createdAt: r.string(),
        updatedAt: r.string(),
        isOpened: r.boolean(),
        canRegenerate: r.boolean(),
    })
);

type CreateReportType = r.infer<typeof postCreateReportSchema>;

export type CompaniesReportType = r.infer<typeof companiesReportSchema>;

type PostCreateReportOptionsType = {
    selectedCompaniesIds: Array<number>;
    selectedAll: boolean;
    brandName: string;
    regenerate?: boolean;
};

const reportsUrl = '/cp/reports/zabotacomparisonreports/';

function getReportUrl(timestamp: number) {
    return `${reportsUrl}?${objectToUrlParameters({timestamp})}`;
}

export function useCreateReportInfo(isReportEnabled: boolean): UseQueryResult<CompaniesReportType> {
    return useQuery([reportsUrl], () => fetchAndDeserialize(getReportUrl(Date.now()), companiesReportSchema), {
        enabled: isReportEnabled,
        cacheTime: 0,
        refetchInterval: (data) => {
            return data &&
                isArray(data) &&
                data[0] &&
                data[0].status.status !== CompaniesReportStepEnum.Done &&
                data[0].status.status !== CompaniesReportStepEnum.Failed
                ? DELAY_IN_SECOND * 1000
                : false;
        },
    });
}

export function useCreateReport(
    setShowReport: () => void
): UseMutationResult<CreateReportType, unknown, PostCreateReportOptionsType> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (options: PostCreateReportOptionsType) =>
            postAndDeserialize(reportsUrl, postCreateReportSchema, options),
        onSuccess: async () => {
            await queryClient.invalidateQueries([reportsUrl]);
            setShowReport();
            window.scrollTo({
                top: 0,
            });
        },
    });
}

async function cancelReportGeneration(uuid: string) {
    const url = `${rootApiUrl}${reportsUrl}${uuid}/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.delete,
    });
}

export function useCancelCreateReportMutation(
    status: CompaniesReportStepEnum | undefined
): UseMutationResult<void | Array<void>, unknown, string> {
    const {snackbar} = useSnackbar();
    const [abortModal, setAbortModal] = useState<ReturnType<ModalFunc> | null | undefined>(null);
    const {modal} = useModal();

    useEffect(() => {
        if (status === CompaniesReportStepEnum.Done && abortModal) {
            abortModal.destroy();
        }
    }, [status, abortModal]);

    function handleAbort(resolveFunction: () => void) {
        setAbortModal(
            modal.confirm({
                title: <Locale stringKey="MY_COMPANIES__REPORT__MODAL__CANCEL__TITLE" />,
                content: <Locale stringKey="MY_COMPANIES__REPORT__MODAL__CANCEL__CONTENT" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
                okType: 'danger',
                onOk: resolveFunction,
            })
        );
    }

    function mutate(uuid: string): Promise<void | Array<void>> {
        return new Promise((resolve) => handleAbort(() => resolve(cancelReportGeneration(uuid))));
    }

    function onError() {
        snackbar.error({
            message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
        });
    }

    return useMutation(mutate, {onError});
}

export function useRegenerateReport(): UseMutationResult<void | Array<void>, unknown, string> {
    const {snackbar} = useSnackbar();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (uuid: string) =>
            fetchNoContent(`${rootApiUrl}${reportsUrl}${uuid}/regenerate/`, {
                method: FetchMethodEnum.post,
                headers: {...mainApiHeaders, ...getCsrfHeaders()},
            }),
        onSuccess: () => queryClient.invalidateQueries([reportsUrl]),
        onError: () =>
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            }),
    });
}

export function useMarkAsOpenReport(): UseMutationResult<void | Array<void>, unknown, string> {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (uuid: string) =>
            fetchNoContent(`${rootApiUrl}${reportsUrl}${uuid}/mark_as_opened/`, {
                method: FetchMethodEnum.post,
                headers: {...mainApiHeaders, ...getCsrfHeaders()},
            }),
        onSuccess: () => queryClient.invalidateQueries([reportsUrl]),
    });
}

export function getReportFile(uuid: string): Promise<Blob | void> {
    return fetchBlob(
        `${rootApiUrl}${reportsUrl}${uuid}/download_xlsx/?${objectToUrlParameters({
            timestamp: Date.now(),
        })}`
    );
}
