import {Form, InputNumber, Select} from 'antd';
import {JSX} from 'react';

import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../typings/antd';

import {AUTO_REFRESH_COUNT_MAX, AUTO_REFRESH_COUNT_MIN, getFrequencyOptions} from './helper';
import * as styles from './auto-refresh-item.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    required?: boolean;
    disabled?: boolean;
};

export function AutoRefreshItem(props: PropsType): JSX.Element {
    const {formInstance, disabled} = props;

    const {getLocalizedString} = useLocale();

    const hasAutoRefreshCount = Form.useWatch([PostFormFieldsEnum.AutoRefreshCount], formInstance);
    const hasAutoRefreshFrequency = Form.useWatch([PostFormFieldsEnum.AutoRefreshFrequency], formInstance);
    const {requiredFieldRule} = useFormRules();

    function handleAutoRefreshCountChange(value: number | null) {
        if (!value) {
            formInstance.setFieldValue(PostFormFieldsEnum.AutoRefreshFrequency, null);
            formInstance.validateFields([PostFormFieldsEnum.AutoRefreshFrequency]);
        }
    }

    function handleAutoRefreshFrequencyChange(value: number) {
        if (!value) {
            formInstance.setFieldValue(PostFormFieldsEnum.AutoRefreshCount, null);
            formInstance.setFieldValue(PostFormFieldsEnum.AutoRefreshFrequency, null);
            formInstance.validateFields([PostFormFieldsEnum.AutoRefreshCount]);
        }
    }

    return (
        <>
            <Form.Item
                label={<Locale stringKey="POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__LABEL" />}
                name={PostFormFieldsEnum.AutoRefreshFrequency}
                rules={hasAutoRefreshCount ? [requiredFieldRule] : []}
            >
                <Select
                    allowClear
                    disabled={disabled}
                    onChange={handleAutoRefreshFrequencyChange}
                    onClear={() => {
                        formInstance.setFieldValue(PostFormFieldsEnum.AutoRefreshFrequency, null);
                    }}
                    options={getFrequencyOptions()}
                    placeholder={getLocalizedString('POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_FREQUENCY__PLACEHOLDER')}
                />
            </Form.Item>
            <Form.Item
                label={
                    <AdditionalInfo
                        leftNode={<Locale stringKey="POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_COUNT__LABEL" />}
                    >
                        <Locale stringKey="POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_COUNT__TOOLTIP" />
                    </AdditionalInfo>
                }
                name={PostFormFieldsEnum.AutoRefreshCount}
                rules={hasAutoRefreshFrequency ? [requiredFieldRule] : []}
            >
                <InputNumber
                    className={styles.AutoRefreshItem_input}
                    disabled={disabled}
                    max={AUTO_REFRESH_COUNT_MAX}
                    min={AUTO_REFRESH_COUNT_MIN}
                    onChange={handleAutoRefreshCountChange}
                    placeholder={getLocalizedString('POSTS_FORM__SCHEDULE_POST__AUTO_REFRESH_COUNT__PLACEHOLDER')}
                />
            </Form.Item>
        </>
    );
}
