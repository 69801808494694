import {faPenToSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, Input} from 'antd';
import {SyntheticEvent, useState} from 'react';

import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';
import {noop} from '../../util/function';

import * as styles from './editable-input.scss';

type PropsType = {
    className?: string;
    initialValue?: string;
    placeholder?: string;
    size?: 'small' | 'middle' | 'large';
    onSave?: (text: string) => void;
    onChange?: () => void;
    isDefaultActive?: boolean;
    hasButton?: boolean;
};

type ErrorLengthType = {
    length: number;
    hasError?: boolean;
};

export function EditableInput(props: PropsType): JSX.Element {
    const {
        className,
        initialValue = '',
        placeholder,
        size,
        onSave = noop,
        isDefaultActive = false,
        onChange,
        hasButton = true,
    } = props;
    const minLength = 2;
    const maxLength = 255;
    const [isInEdit, setIsInEdit] = useState<boolean>(isDefaultActive);
    const [text, setText] = useState<string>(initialValue);
    const [textLengthStatus, setTextLengthStatus] = useState<ErrorLengthType>({
        length: initialValue.length,
        hasError: initialValue.length < 2 || initialValue.length > 255,
    });

    function handleSave() {
        onSave(text);
        setIsInEdit(false);
    }

    function handleInput(event: SyntheticEvent<HTMLInputElement>) {
        const {value} = event.currentTarget;

        setTextLengthStatus({
            length: value.length,
            hasError: value.length < 2 || value.length > 255,
        });
        setText(value.trim());
    }

    function handleChange() {
        if (onChange) {
            onChange();
        } else {
            setIsInEdit(true);
        }
    }

    function helpMessage() {
        if (textLengthStatus.length < 2) {
            return (
                <Locale
                    stringKey="VALIDATION__ERROR__FIELD_MIN_LENGTH_INCORRECT"
                    valueMap={{length: minLength.toString()}}
                />
            );
        }

        if (textLengthStatus.length > 255) {
            return (
                <Locale
                    stringKey="VALIDATION__ERROR__FIELD_MAX_LENGTH_INCORRECT"
                    valueMap={{length: maxLength.toString()}}
                />
            );
        }

        return null;
    }

    if (isInEdit) {
        return (
            <div className={classNames(styles.editable_wrapper_input, className)}>
                <Form.Item
                    className={styles.editable_item}
                    help={helpMessage()}
                    validateStatus={textLengthStatus.hasError ? 'error' : ''}
                >
                    <Input
                        autoFocus
                        defaultValue={text}
                        onInput={handleInput}
                        placeholder={placeholder}
                        size={size}
                        type="text"
                    />
                </Form.Item>

                {!textLengthStatus.hasError ? (
                    <Button className={styles.save_button} onClick={handleSave} size={size} type="link">
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>
                ) : null}
            </div>
        );
    }

    if (!hasButton) {
        return <p className={styles.editable_button_text}>{initialValue}</p>;
    }

    return (
        <Button className={classNames(styles.editable_button, className)} onClick={handleChange} type="text">
            <p className={styles.editable_button_text}>{initialValue}</p>

            <FontAwesomeIcon className={styles.editable_button_image} icon={faPenToSquare} />
        </Button>
    );
}
