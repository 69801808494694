import {SourcePostFormFieldsEnum, SourcePostType} from '../../../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../../../util/css';

import * as styles from './preview-images.scss';

type PropsType = {
    images: SourcePostType[SourcePostFormFieldsEnum.Images];
};

export function PreviewImages(props: PropsType): JSX.Element | null {
    const {images} = props;

    if (images.length === 0) {
        return null;
    }

    const containerClasses = classNames(styles.PreviewImages, {
        [styles.PreviewImages_two]: images.length === 2,
        [styles.PreviewImages_three]: images.length === 3,
        [styles.PreviewImages_four]: images.length === 4,
    });

    function getMainImageClassName(isMain: boolean): string {
        return classNames(styles.PreviewImages_image, {
            [styles.PreviewImages_image__two]: images.length === 2 && isMain,
            [styles.PreviewImages_image__three]: images.length === 3 && isMain,
            [styles.PreviewImages_image__four]: images.length === 4 && isMain,
        });
    }

    return (
        <div className={containerClasses}>
            {images.map((image, index) => {
                return (
                    <img
                        alt=""
                        className={getMainImageClassName(image.isMain)}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        src={image.url}
                    />
                );
            })}
        </div>
    );
}
