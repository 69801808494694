import {faPenToSquare, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Table, TableColumnsType, Tooltip} from 'antd';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {Empty} from '../../../../../layout/empty/empty';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {FormattedDateTime} from '../../../../../provider/locale/formatted-date-time';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {ProductGroupListItemType} from '../../../../../service/products/products-type';
import {classNames} from '../../../../../util/css';
import {productGroupTypeIconMap, productGroupTypeTooltipMap} from '../../products-const';

import {ProductGroupStatus} from './product-group-status';
import {getDownloadFileWithErrorsUrl} from './product-group-table-helper';
import {ProductGroupsTableColumnsEnum} from './product-groups-table-type';
import * as styles from './product-groups-table.scss';

type PropsType = {
    data: Array<ProductGroupListItemType>;
    isLoading: boolean;
    removeProductGroup: (productGroupId: number) => void;
    isRemovingProductGroup: boolean;
};

export function ProductGroupsTable(props: PropsType): JSX.Element {
    const {data, isLoading, removeProductGroup, isRemovingProductGroup} = props;
    const {getCatalogName, getCatalogLogo} = useCatalogInfo();
    const {getLocalizedString} = useLocale();

    const productGroupColumns: TableColumnsType<ProductGroupListItemType> = [
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__TYPE" />,
            dataIndex: ProductGroupsTableColumnsEnum.Type,
            align: 'center',
            render: (_value, {source}) => (
                <Tooltip title={getLocalizedString(productGroupTypeTooltipMap[source])}>
                    {productGroupTypeIconMap[source]}
                </Tooltip>
            ),
        },
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__NAME" />,
            dataIndex: ProductGroupsTableColumnsEnum.Name,
            render: (_value, {name}) => name,
        },
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__PRODUCTS" />,
            dataIndex: ProductGroupsTableColumnsEnum.Products,
            align: 'center',
            render: (_value, {productsCount}) => (productsCount ? String(productsCount) : '—'),
        },
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__UPDATED_AT" />,
            dataIndex: ProductGroupsTableColumnsEnum.UpdatedAt,
            align: 'center',
            render: (_value, {lastImportDate}) =>
                lastImportDate ? <FormattedDateTime value={new Date(lastImportDate)} /> : '—',
        },
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__SERVICES" />,
            dataIndex: ProductGroupsTableColumnsEnum.Services,
            align: 'center',
            render: (_value, {catalogs}) => (
                <div className={styles.ProductGroupsTable_catalogs}>
                    {catalogs.map((catalog) => {
                        const catalogName = getCatalogName(catalog.id);
                        const catalogLogo = getCatalogLogo(catalog.id);

                        return (
                            <Tooltip key={catalog.id} title={catalogName}>
                                <img
                                    alt={catalogName}
                                    className={styles.ProductGroupsTable_catalogImage}
                                    src={catalogLogo}
                                />
                            </Tooltip>
                        );
                    })}
                </div>
            ),
        },
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__STATUS" />,
            dataIndex: ProductGroupsTableColumnsEnum.Status,
            render: (_value, productGroup) => <ProductGroupStatus productGroup={productGroup} />,
        },
        {
            title: <Locale stringKey="PRODUCTS__GROUPS__ACTIONS" />,
            dataIndex: ProductGroupsTableColumnsEnum.Actions,
            align: 'center',
            width: 100,
            render: (_value, {id, editable, hasCatalogValidationErrors}) => (
                <div className={styles.ProductGroupsTable_actions}>
                    <Button
                        className={styles.ProductGroupsTable_action}
                        disabled={!editable || isRemovingProductGroup}
                        type="text"
                    >
                        <NavigationLink to={generatePath(appRoute.editProductsGroup.path, {id})}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </NavigationLink>
                    </Button>
                    <Button
                        className={styles.ProductGroupsTable_action}
                        disabled={isRemovingProductGroup}
                        onClick={() => removeProductGroup(id)}
                        type="text"
                    >
                        <FontAwesomeIcon icon={faTrashCan} />
                    </Button>
                    {hasCatalogValidationErrors && (
                        <Button
                            className={classNames(
                                styles.ProductGroupsTable_action,
                                styles.ProductGroupsTable_action__error
                            )}
                            download
                            href={getDownloadFileWithErrorsUrl(id)}
                            type="link"
                        >
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <Table<ProductGroupListItemType>
            columns={productGroupColumns}
            dataSource={data}
            loading={isLoading}
            locale={{
                emptyText: (
                    <Empty
                        secondaryText="PRODUCTS__TABLE__EMPTY_TEXT"
                        secondaryTextClassName={styles.ProductGroupsTable_emptyText}
                    />
                ),
            }}
            pagination={false}
            rowKey={(dataItem) => dataItem.id}
            scroll={{x: true}}
            size="middle"
        />
    );
}
