import {ReactNode} from 'react';

import {useCatalog} from '../../catalogs/catalogs-hook';
import {useLocale} from '../locale-hook';
import {LangKeyType} from '../translation/type';

export type LocaleWithCatalogHookOptionsType = {
    stringKey: LangKeyType;
    valueMap?: Record<string, ReactNode>;
    catalogId: number;
    namePlaceholder?: string;
};

export function useLocalizedStringWithCatalogName(options: LocaleWithCatalogHookOptionsType): string {
    const {stringKey, valueMap, namePlaceholder = '', catalogId} = options;

    const {catalog} = useCatalog(catalogId);
    const {getLocalizedString} = useLocale();

    return getLocalizedString(stringKey, {
        ...valueMap,
        catalogName: catalog?.name || namePlaceholder || '',
    });
}
