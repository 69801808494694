import {classNames} from '../../../util/css';

import * as styles from './progress-chart.scss';

type PropsType = {
    value: number;
    className?: string;
};

export function NpsChart(props: PropsType): JSX.Element {
    const {value, className} = props;

    function getNpsMarkerColor() {
        if (value >= 50) {
            return styles.high_value_color;
        }

        if (value < 50 && value > 0) {
            return styles.medium_value_color;
        }

        return styles.low_value_color;
    }

    return (
        <div className={classNames(styles.progress_chart__wrapper, className)}>
            <div
                className={styles.nps_chart__marker}
                style={{
                    left: `${50 + value / 2}%`,
                    backgroundColor: getNpsMarkerColor(),
                }}
            />
            <div
                className={styles.progress_chart__value}
                style={{backgroundColor: styles.high_value_color, width: '100%'}}
            />
            <div
                className={styles.progress_chart__value}
                style={{backgroundColor: styles.medium_value_color, width: '75%'}}
            />
            <div
                className={styles.progress_chart__value}
                style={{backgroundColor: styles.low_value_color, width: '50%'}}
            />
        </div>
    );
}
