import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';
import {z as r} from 'zod';

import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../util/url';
import {generateResponseSchema, PaginatedResponseType} from '../api/api-type';

import {fakesUrl} from './fakes-const';
import {
    FakeClientStatusEnum,
    FakeInternalStatusEnum,
    FakeNeededActionEnum,
    FakeRejectReasonEnum,
    FakeTypeEnum,
} from './fakes-type';

const fakesResultSchema = r.object({
    id: r.number(),
    catalogId: r.number(),
    createdAt: r.string(),
    logoUrl: r.string().nullable(),
    name: r.string(),
    address: r.string(),
    phone: r.string().nullable(),
    category: r.string().nullable(),
    url: r.string(),
    clientStatus: r.nativeEnum(FakeClientStatusEnum),
    internalStatus: r.nativeEnum(FakeInternalStatusEnum),
    statusChangeTime: r.string(),
    rejectReason: r.nativeEnum(FakeRejectReasonEnum).nullable(),
    neededAction: r.nativeEnum(FakeNeededActionEnum).nullable(),
    isDuplicate: r.boolean(),
});

export type FakesResultType = r.infer<typeof fakesResultSchema>;

const fakesResultsSchema = generateResponseSchema(fakesResultSchema);

type FakesResultsType = r.infer<typeof fakesResultsSchema>;

type FakesResultsHookReturnType = PaginationType & UseQueryResult<PaginatedResponseType<FakesResultType>>;

type FakesResultsOptionsType = {
    page: number;
    count: number;
    brandIds: Array<number>;
    catalogId: number;
    type: string;
    clientStatuses: Array<string>;
    statusUpdatedFrom: string;
    statusUpdatedTo: string;
};

export type FakesFiltersType = {
    type?: FakeTypeEnum | null;
    catalogIds?: Array<number>;
    clientStatuses?: Array<FakeClientStatusEnum>;
    brandIds?: Array<number>;
    statusUpdatedFrom?: string;
    statusUpdatedTo?: string;
};

function getFakesResultsUrl(options: Partial<FakesResultsOptionsType>): string {
    return `${fakesUrl}/results/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

function fetchFakesResults(options: Partial<FakesResultsOptionsType>): Promise<FakesResultsType> {
    return fetchAndDeserialize(getFakesResultsUrl(options), fakesResultsSchema);
}

export function useFakesResults(filters: FakesFiltersType | null): FakesResultsHookReturnType {
    const dependencies = useMemo(
        () => ({
            ...filters,
            type: filters?.type ?? undefined, // eslint-disable-line no-undefined
            catalogId: filters?.catalogIds?.length === 1 ? filters.catalogIds[0] : undefined, // eslint-disable-line no-undefined
        }),
        [filters]
    );

    const pagination = usePagination({dependencies, initialPageSize: 10});
    const {page, pageSize, onDataLoaded} = pagination;

    const queryParameters = useMemo(
        () => ({
            page,
            count: pageSize,
            ...dependencies,
        }),
        [page, pageSize, dependencies]
    );

    const query = useQuery([getFakesResultsUrl(queryParameters)], () => fetchFakesResults(queryParameters), {
        onSuccess: (result) => onDataLoaded(result),
    });

    return {...query, pagination};
}

export function useFakesReportResults(
    filters: FakesFiltersType
): UseQueryResult<PaginatedResponseType<FakesResultType>> {
    const queryParameters = useMemo(
        () => ({
            ...filters,
            count: 1000,
            type: filters.type ?? undefined, // eslint-disable-line no-undefined
            catalogId: filters.catalogIds?.length === 1 ? filters.catalogIds[0] : undefined, // eslint-disable-line no-undefined
        }),
        [filters]
    );

    return useQuery([getFakesResultsUrl(queryParameters)], () => fetchFakesResults(queryParameters));
}
