import {faEye, faFlag as faRegularFlag, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {faFlag as faSolidFlag} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Image} from 'antd';
import {useState} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {PostImageType} from '../../../../../../../../../service/posts/posts-types';
import * as styles from '../media.scss';
import {MediaCard} from '../media-card';

type PropsType = {
    image: PostImageType;
    showMainImageControl: boolean;
    showRemoveControl: boolean;
    onRemove: () => void;
    onSelectAsMain?: () => void;
};

export function ImageCard(props: PropsType): JSX.Element {
    const {showMainImageControl, showRemoveControl, image, onRemove, onSelectAsMain} = props;

    const {isMain, url} = image;

    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

    function handlePreview() {
        setIsPreviewOpen(true);
    }

    return (
        <MediaCard previewSrc={url}>
            <Image
                preview={{
                    visible: isPreviewOpen,
                    onVisibleChange: (value) => setIsPreviewOpen(value),
                }}
                src={url}
                width={0}
            />
            <div className={styles.PostMedia_cardOverlay}>
                <div className={styles.PostMedia_actions}>
                    {showRemoveControl && (
                        <Button className={styles.PostMedia_actionButton} onClick={onRemove} type="text">
                            <Text inheritColor>
                                <FontAwesomeIcon icon={faTrashCan} />
                            </Text>
                        </Button>
                    )}
                    <Button className={styles.PostMedia_actionButton} onClick={handlePreview} type="text">
                        <Text inheritColor>
                            <FontAwesomeIcon icon={faEye} />
                        </Text>
                    </Button>
                    {showMainImageControl && (
                        <Button className={styles.PostMedia_actionButton} onClick={onSelectAsMain} type="text">
                            <Text inheritColor>
                                <FontAwesomeIcon icon={isMain ? faSolidFlag : faRegularFlag} />
                            </Text>
                        </Button>
                    )}
                </div>
            </div>
        </MediaCard>
    );
}
