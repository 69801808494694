import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Upload, UploadFile} from 'antd';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../../../provider/modal/modal-hook';
import {getCsrfHeaders, rootApiUrl} from '../../../../../../../../../service/api/api-const';
import {PostImageType, UploadImageResponseType} from '../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../util/css';
import {getRandomString} from '../../../../../../../../../util/string';
import {ImageContentValidationSettingsType} from '../../../content-step-type';
import * as styles from '../media.scss';
import {MediaCardLoading} from '../media-card-loading';

import {ImageCard} from './image-card';
import {validateImageBeforeUpload} from './images-helper';

type PropsType = {
    uploadedImages: Array<PostImageType & {name: number}>;
    settings: ImageContentValidationSettingsType;
    disabled: boolean;
    onSelectImageAsMain: (name: number) => void;
    onRemove: (name: number) => void;
    onUploadingStatusChanged: (isUploading: boolean) => void;
    onFileUploaded: (image: PostImageType) => void;
};

export function PostsImagesUploader(props: PropsType): JSX.Element {
    const {
        uploadedImages,
        settings,
        disabled,
        onSelectImageAsMain,
        onRemove,
        onUploadingStatusChanged,
        onFileUploaded,
    } = props;

    const {maxCount} = settings;
    const {modal} = useModal();

    const [uploadFileMap, setUploadFileMap] = useState<Record<string, UploadFile<UploadImageResponseType>>>({});

    const imagesInProgress: Array<UploadFile<UploadImageResponseType>> = useMemo(() => {
        return Object.values(uploadFileMap).filter((uploadingImage) => {
            return uploadingImage.status === 'uploading';
        });
    }, [uploadFileMap]);

    const totalImagesCount = uploadedImages.length + imagesInProgress.length;
    const uploadMaxCount = Object.keys(uploadFileMap).length + maxCount - totalImagesCount;

    useEffect(() => {
        if (Object.keys(uploadFileMap).length > 0) {
            onUploadingStatusChanged(imagesInProgress.length > 0);
        }
    }, [imagesInProgress.length, onUploadingStatusChanged, uploadFileMap]);

    const onFileChanged = useCallback(
        (
            file: UploadFile<UploadImageResponseType>,
            imagesCount: number,
            addFormImage: (newImage: PostImageType) => void
        ) => {
            if (file.response && uploadFileMap[file.uid]?.status !== 'done' && imagesCount !== maxCount) {
                addFormImage({
                    url: file.response.image,
                    isMain: imagesCount === 0,
                });
            }

            setUploadFileMap((previousState) => ({
                ...previousState,
                [file.uid]: file,
            }));
        },
        [maxCount, uploadFileMap]
    );

    const uploadVisible = totalImagesCount < maxCount;

    return (
        <div
            className={classNames(styles.PostMedia, {
                [styles.PostMedia__uploadButtonHidden]: !uploadVisible,
            })}
        >
            {uploadedImages.map((image) => (
                <ImageCard
                    image={image}
                    key={getRandomString()}
                    onRemove={() => onRemove?.(image.name)}
                    onSelectAsMain={() => onSelectImageAsMain?.(image.name)}
                    showMainImageControl={!disabled && totalImagesCount > 1 && maxCount > 1}
                    showRemoveControl={!disabled}
                />
            ))}

            {imagesInProgress.map((uploadingImage) => (
                <MediaCardLoading key={uploadingImage.uid} progress={uploadingImage.percent} />
            ))}

            <Upload<UploadImageResponseType>
                accept=".jpg,.png"
                action={`${rootApiUrl}/v1/posts/images/`}
                beforeUpload={(file) => validateImageBeforeUpload(file, modal, settings)}
                headers={{
                    ...getCsrfHeaders(),
                }}
                itemRender={() => null}
                maxCount={uploadMaxCount}
                multiple={maxCount > 1}
                name="image"
                onChange={(info) => {
                    info.fileList.forEach((file) => {
                        onFileChanged(file, uploadedImages.length, onFileUploaded);
                    });
                }}
                openFileDialogOnClick={totalImagesCount !== maxCount}
            >
                {uploadVisible && (
                    <Button className={styles.PostMedia_uploadButton} disabled={disabled}>
                        <div>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <Text inheritColor>
                            <Locale stringKey="POSTS__UPLOAD_IMAGE" />
                        </Text>
                    </Button>
                )}
            </Upload>
        </div>
    );
}
