import {Descriptions} from 'antd';

import {PageCardDivider} from '../../../../../component/page-card-divider/page-card-divider';
import {Text} from '../../../../../component/text/text';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useFakesStatistics} from '../../../../../service/fakes/fakes-statistics';

import {FakesCreateReport} from './create-report/fakes-create-report';
import {statisticsTabKey} from './fakes-statistics-tab-const';
import * as styles from './fakes-statistics-tab.scss';

export function FakesStatisticsTab(): JSX.Element {
    const {data, isLoading} = useFakesStatistics();

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <Text secondary small stringKey="FAKES__STATISTIC__LABEL__ALL_TIME_STATISTIC" />

            <Descriptions bordered className={styles.StatisticsTab_descriptions} column={1}>
                <Descriptions.Item label={<Locale stringKey={statisticsTabKey.fakesClaimAccepted} />}>
                    {data?.fakesClaimAccepted}
                </Descriptions.Item>
                <Descriptions.Item label={<Locale stringKey={statisticsTabKey.duplicatesClaimAccepted} />}>
                    {data?.duplicatesClaimAccepted}
                </Descriptions.Item>
                <Descriptions.Item label={<Locale stringKey={statisticsTabKey.claimRejected} />}>
                    {data?.claimRejected}
                </Descriptions.Item>
                <Descriptions.Item label={<Locale stringKey={statisticsTabKey.hidden} />}>
                    {data?.hidden}
                </Descriptions.Item>
                <Descriptions.Item
                    className={styles.StatisticsTab_lastItem}
                    label={<Text bolder stringKey={statisticsTabKey.all} />}
                >
                    <Text bolder>{data?.all}</Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={styles.StatisticsTab_lastItem}
                    label={<Text bolder stringKey={statisticsTabKey.totalSearchCount} />}
                >
                    <Text bolder>{data?.totalSearchCount}</Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={styles.StatisticsTab_lastItem}
                    label={<Text bolder stringKey={statisticsTabKey.filtered} />}
                >
                    <Text bolder>{data?.filtered}</Text>
                </Descriptions.Item>
            </Descriptions>

            <PageCardDivider />

            <FakesCreateReport />
        </>
    );
}
