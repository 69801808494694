import {serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {CclFetchParametersType} from './ccl-type';

export function getFetchCclUrl(shouldUseCpEndpoint: boolean, parameters: CclFetchParametersType): string {
    return shouldUseCpEndpoint
        ? `/cp/ccl/?${objectToUrlParameters(serializeToURLParameters<CclFetchParametersType>(parameters))}`
        : `/v2/ccl/?${objectToUrlParameters(serializeToURLParameters<CclFetchParametersType>(parameters))}`;
}
