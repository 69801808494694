import {LandingCallToAction} from '../../../../../../component/landing/landing-call-to-action/landing-call-to-action';
import {LandingCard} from '../../../../../../component/landing/landing-card/landing-card';
import {LandingPageCard} from '../../../../../../component/landing/landing-page-card/landing-page-card';
import {PhoneIllustration} from '../../../../../../component/landing/phone-illustration/phone-illustration';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {DEFAULT_TRIAL_PERIOD_DAYS} from '../../../../../../provider/license/license-const';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {getCarouselImages, getPostsLandingImage} from '../../post-activation-helper';

type PropsType = {
    onTryFeatureClick: VoidFunction;
};

export function PostActivationLanding(props: PropsType): JSX.Element {
    const {onTryFeatureClick} = props;

    const {companyName} = useDomainConfig();
    const {shortLocaleName} = useLocale();

    return (
        <LandingPageCard>
            <LandingCard
                buttonLangKey="BUTTON__TRY_FOR_FREE"
                imageSrc={getPostsLandingImage(shortLocaleName, 1)}
                mainItem
                onClick={onTryFeatureClick}
                primaryDescriptionItems={[
                    {stringKey: 'POSTS_ACTIVATION__LANDING__CARD_1__DESCRIPTION', valueMap: {companyName}},
                ]}
                textFirst
                titleLangKey="POSTS_ACTIVATION__LANDING__CARD_1__TITLE"
            />
            <LandingCard
                imageComponent={<PhoneIllustration images={getCarouselImages(shortLocaleName)} />}
                listDescriptionItems={[
                    'POSTS_ACTIVATION__LANDING__CARD_2__DESCRIPTION__LIST_ITEM_1',
                    'POSTS_ACTIVATION__LANDING__CARD_2__DESCRIPTION__LIST_ITEM_2',
                ]}
                primaryDescriptionItems={['POSTS_ACTIVATION__LANDING__CARD_2__DESCRIPTION']}
                titleLangKey="POSTS_ACTIVATION__LANDING__CARD_2__TITLE"
            />
            <LandingCard
                imageSrc={getPostsLandingImage(shortLocaleName, 3)}
                listDescriptionItems={[
                    'POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION__LIST_ITEM_1',
                    'POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION__LIST_ITEM_2',
                    'POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION__LIST_ITEM_3',
                ]}
                primaryDescriptionItems={['POSTS_ACTIVATION__LANDING__CARD_3__DESCRIPTION']}
                textFirst
                titleLangKey="POSTS_ACTIVATION__LANDING__CARD_3__TITLE"
            />
            <LandingCallToAction
                colorful
                descriptionLangKey="POSTS_ACTIVATION__LANDING__CARD__CALL__ACTION_DESCRIPTION"
                descriptionValueMap={{duration: DEFAULT_TRIAL_PERIOD_DAYS}}
                duration={DEFAULT_TRIAL_PERIOD_DAYS}
                onClick={onTryFeatureClick}
            />
        </LandingPageCard>
    );
}
