import {createContext} from 'react';

export type VirtualTableContextType = {
    setSize: (index: number, size: number) => void;
    windowWidth: number;
};

const defaultContext: VirtualTableContextType = {
    setSize: () => ({}),
    windowWidth: 0,
};

export const VirtualTableContext = createContext<VirtualTableContextType>(defaultContext);
