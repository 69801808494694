import {faEarthAmericas, faEllipsis, faPeriod} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {FormattedDateTime} from '../../../../../../../../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../../../../../../../../provider/locale/locale';
import {PostPreviewDetailsType} from '../../../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../../../util/css';
import {TimeSizeEnum} from '../../../../../../../../../../../util/format';
import {CompanyAvatar} from '../../../company-avatar/company-avatar';

import * as styles from './facebook-preview-header.scss';

type PropsType = {
    companyInfo?: PostPreviewDetailsType;
};

export function FacebookPreviewHeader(props: PropsType): JSX.Element {
    const {companyInfo} = props;

    return (
        <div className={classNames(styles.FacebookPreviewHeader)}>
            <div className={styles.FacebookPreviewHeader_authorContainer}>
                <CompanyAvatar logo={companyInfo?.logo} />
                <div className={styles.FacebookPreviewHeader_publicationInfo}>
                    <div className={styles.FacebookPreviewHeader_publicationAuthor}>
                        {companyInfo?.name || <Locale stringKey="POSTS_FORM__PREVIEW__FACEBOOK__COMPANY_NAME" />}
                    </div>
                    <div className={styles.FacebookPreviewHeader_publicationMetaInformationContainer}>
                        <div>
                            <FormattedDateTime
                                options={{
                                    [TimeSizeEnum.month]: 'long',
                                    [TimeSizeEnum.day]: 'numeric',
                                }}
                                value={new Date()}
                            />
                        </div>

                        <div className={styles.FacebookPreviewHeader_publicationMetaInformationDivider}>
                            <FontAwesomeIcon icon={faPeriod} />
                        </div>

                        <FontAwesomeIcon icon={faEarthAmericas} />
                    </div>
                </div>
            </div>
            <div className={styles.FacebookPreviewHeader_actionsIcon}>
                <FontAwesomeIcon icon={faEllipsis} />
            </div>
        </div>
    );
}
