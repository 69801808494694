import {BooleanAttribute} from '../attribute-boolean/boolean-attribute';
import {BooleanGoogleAttribute} from '../attribute-boolean/boolean-google-attribute';
import {EnumMultipleAttribute} from '../attribute-enum/enum-multiple-attribute';
import {EnumSingleAttribute} from '../attribute-enum/enum-single-attribute';
import {InUnitsAttribute} from '../attribute-in-units/in-units-attribute';
import {NumericAttribute} from '../attribute-numeric/numeric-attribute';
import {RangeAttribute} from '../attribute-range/range-attribute';
import {RangeInUnitsSingleAttribute} from '../attribute-range-in-units/range-in-units-attribute';
import {TextAttribute} from '../attribute-text/text-attribute';
import {AttributeGroupType, AttributeType, AttributeTypeEnum} from '../attributes-type';

import * as styles from './attribute.scss';

type PropsType = {
    value?: AttributeType | AttributeGroupType;
    onChange?: (value: AttributeType | AttributeGroupType) => void;
    resetId: string | null;
};

// eslint-disable-next-line complexity
export function Attribute(props: PropsType): JSX.Element | null {
    const {value: valueOrGroup, onChange, resetId} = props;

    if (!valueOrGroup || !onChange) {
        return null;
    }

    if ('group' in valueOrGroup && valueOrGroup.group) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return <GroupAttribute onChange={onChange} resetId={resetId} value={valueOrGroup} />;
    }

    const value = valueOrGroup as AttributeType; // for some reason TS narrows the type only in 'if' clause, but usually it works for 'else' too
    const type = value.featureType;

    switch (type) {
        case AttributeTypeEnum.DoubleGisBoolean:
        case AttributeTypeEnum.GoogleBoolean: {
            return <BooleanGoogleAttribute onChange={onChange} value={value} />;
        }

        case AttributeTypeEnum.YandexBoolean: {
            return <BooleanAttribute onChange={onChange} value={value} />;
        }
        case AttributeTypeEnum.GoogleUrl:
        case AttributeTypeEnum.YandexTextSingle: {
            return <TextAttribute onChange={onChange} value={value} />;
        }
        case AttributeTypeEnum.GoogleEnumSingle:
        case AttributeTypeEnum.YandexEnumSingle: {
            return <EnumSingleAttribute onChange={onChange} value={value} />;
        }
        case AttributeTypeEnum.GoogleEnumMultiple:
        case AttributeTypeEnum.YandexEnumMultiple: {
            return <EnumMultipleAttribute onChange={onChange} value={value} />;
        }
        case AttributeTypeEnum.YandexNumericSingle: {
            return <NumericAttribute onChange={onChange} value={value} />;
        }
        case AttributeTypeEnum.YandexInUnitsSingle: {
            return <InUnitsAttribute key={resetId} onChange={onChange} value={value} />; // need key to reset useState after widget reset
        }
        case AttributeTypeEnum.YandexRangeSingle: {
            return <RangeAttribute onChange={onChange} value={value} />;
        }
        case AttributeTypeEnum.YandexRangeInUnitsSingle: {
            return <RangeInUnitsSingleAttribute key={resetId} onChange={onChange} value={value} />; // need key to reset useState after widget reset
        }
        default: {
            console.error('Encountered an unknown attribute type:', type);

            return null;
        }
    }
}

type GroupAttributePropsType = {
    value: AttributeGroupType;
    onChange: (value: AttributeGroupType) => void;
    resetId: string | null;
};

// eslint-disable-next-line react/no-multi-comp
function GroupAttribute(props: GroupAttributePropsType) {
    const {value: valueOrGroup, onChange, resetId} = props;

    function onGroupItemChange(item: AttributeGroupType | AttributeType) {
        if ('group' in item) {
            return;
        }

        onChange({
            ...valueOrGroup,
            items: valueOrGroup.items.map((valueItem) =>
                valueItem.internalName === item.internalName ? item : valueItem
            ),
        });
    }

    return (
        <div className={styles.group}>
            <h6 className={styles.group__title}>{valueOrGroup.group}</h6>

            <div className={styles.group__items}>
                {valueOrGroup.items.map((item) => (
                    <Attribute key={item.internalName} onChange={onGroupItemChange} resetId={resetId} value={item} />
                ))}
            </div>
        </div>
    );
}
