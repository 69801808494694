import {faFile} from '@fortawesome/pro-regular-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, ConfigProvider, Flex, Modal, Space} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {Dispatch, ReactElement, SetStateAction, useState} from 'react';

import {Locale} from '../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../../../../../provider/locale/translation/type';
import {useSystem} from '../../../../../../../../provider/system/system-hook';
import {useSendAbuseMutation} from '../../../../../../../../service/reviews/reviews-abuse-hook';
import {ResponseTemplateDataType} from '../../../../../../../../service/reviews/reviews-response-templates';
import {Form} from '../../../../../../../../typings/antd';
import {IdNumberType} from '../../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';

import {abuseFormId, AbuseFormItemEnum, abuseReasons} from './new-abuse-modal-const';
import * as styles from './new-abuse-modal.scss';

type PropsType = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    reviewId: number;
    refreshReview: (reviewId: number) => Promise<unknown>;
};

type AbuseFormType = {
    choices: Array<LangKeyType>;
    description?: string;
};

export function NewAbuseModal(props: PropsType): ReactElement {
    const {setOpen, reviewId, refreshReview} = props;

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );
    const [form] = Form.useForm<AbuseFormType>();
    const {getLocalizedString} = useLocale();
    const {mutateAsync, isLoading} = useSendAbuseMutation();

    const {screen} = useSystem();

    const formId = `${abuseFormId}${reviewId}`;

    async function onSubmit(formValues: AbuseFormType) {
        await mutateAsync(
            {
                choices: formValues?.choices?.map((choice) => getLocalizedString(choice)) || [],
                description: formValues.description || '',
                reviewId,
            },
            {
                onSuccess: async () => {
                    await refreshReview(reviewId);
                    setOpen(false);
                },
            }
        );
    }

    function handleOnChangeForm() {
        if (
            form.getFieldValue(AbuseFormItemEnum.Description) ||
            (form.getFieldValue(AbuseFormItemEnum.Choices) && form.getFieldValue(AbuseFormItemEnum.Choices).length > 0)
        ) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }

    return (
        <Modal
            footer={
                <Flex justify="space-between">
                    <Button onClick={() => setIsSelectTemplatePopupOpen(true)}>
                        <FontAwesomeIcon icon={faFile} />
                        {!screen.isMobile && <Locale stringKey="REVIEWS__ABUSE__TEMPLATE__BUTTON" />}
                    </Button>
                    <Space wrap>
                        <Button onClick={() => setOpen(false)}>
                            <Locale stringKey="BUTTON__CANCEL" />
                        </Button>
                        <Button
                            disabled={isDisabled}
                            form={formId}
                            htmlType="submit"
                            loading={isLoading}
                            type="primary"
                        >
                            <Locale stringKey="POPUP__BUTTON__SEND" />
                        </Button>
                    </Space>
                </Flex>
            }
            onCancel={() => setOpen(false)}
            open
            title={<Locale stringKey="REVIEWS__COMPLAINT_POPUP__SUBMITTING_A_COMPLAINT" />}
        >
            <Form<AbuseFormType> form={form} id={formId} onChange={handleOnChangeForm} onFinish={onSubmit}>
                <ConfigProvider wave={{disabled: true}}>
                    <Form.Item<AbuseFormType> name={AbuseFormItemEnum.Choices} valuePropName="checked">
                        <Checkbox.Group className={styles.NewAbuseModal_options}>
                            {abuseReasons.map((reason) => (
                                <Checkbox className={styles.NewAbuseModal_option} key={reason.id} value={reason.title}>
                                    {getLocalizedString(reason.title)}
                                    <FontAwesomeIcon className={styles.NewAbuseModal_optionIcon} icon={faCheck} />
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </ConfigProvider>
                <Form.Item<AbuseFormType> name={AbuseFormItemEnum.Description}>
                    <TextArea
                        placeholder={getLocalizedString('REVIEWS__COMPLAINT_POPUP__ENTER_YOUR_COMPLAINT_HERE')}
                        rows={5}
                    />
                </Form.Item>
            </Form>
            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        setIsSelectTemplatePopupOpen(false);

                        const [selectedAutoReply] = selectedResponseTemplateList;

                        if (!selectedAutoReply) {
                            return;
                        }

                        const {message} = selectedAutoReply;

                        form.setFieldValue(AbuseFormItemEnum.Description, message);
                        setIsDisabled(false);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </Modal>
    );
}
