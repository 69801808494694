import {faMessageBot} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useCallback, useState} from 'react';

import {DashboardEmpty} from '../../empty/dashboard-empty';
import {AddMoreRepliesModal} from '../add-more-replies-modal/add-more-replies-modal';

export function AutoRepliesEmpty(): JSX.Element {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const onCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <>
            <DashboardEmpty
                buttonTextLangKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EMPTY__BUTTON_TEXT"
                descriptionLangKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EMPTY__TEXT"
                icon={<FontAwesomeIcon icon={faMessageBot} />}
                onClick={() => setIsModalOpen(true)}
                titleLangKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EMPTY__TITLE"
            />

            <AddMoreRepliesModal isOpen={isModalOpen} onClose={onCloseModal} />
        </>
    );
}
