import {LangKeyType} from '../../../../provider/locale/translation/type';
import {DashboardWidgetEnum} from '../dashboard-type';

export const DASHBOARD_WIDGET_DESCRIPTION_KEY: Record<DashboardWidgetEnum, LangKeyType> = {
    [DashboardWidgetEnum.PhotoTool]: 'DASHBOARD_PAGE__HELPER__PHOTO_TOOL',
    [DashboardWidgetEnum.ReviewsSummary]: 'DASHBOARD_PAGE__HELPER__REVIEWS_SUMMARY',
    [DashboardWidgetEnum.AutoReplies]: 'DASHBOARD_PAGE__HELPER__AUTO_REPLIES',
    [DashboardWidgetEnum.PricesSingle]: 'DASHBOARD_PAGE__HELPER__PRICES_SINGLE',
    [DashboardWidgetEnum.PricesMultiple]: 'DASHBOARD_PAGE__HELPER__PRICES_MULTIPLE',
    [DashboardWidgetEnum.Posts]: 'DASHBOARD_PAGE__HELPER__POSTS',
    [DashboardWidgetEnum.SynchronizationSingle]: 'DASHBOARD_PAGE__HELPER__SYNCHRONIZATION_SINGLE',
    [DashboardWidgetEnum.SynchronizationMultiple]: 'DASHBOARD_PAGE__HELPER__SYNCHRONIZATION_MULTIPLE',
    [DashboardWidgetEnum.Workload]: 'DASHBOARD_PAGE__HELPER__WORKLOAD',
    [DashboardWidgetEnum.Activity]: 'DASHBOARD_PAGE__HELPER__ACTIVITY',
    [DashboardWidgetEnum.SearchPhrases]: 'DASHBOARD_PAGE__HELPER__SEARCH_PHRASES',
    [DashboardWidgetEnum.LastReviews]: 'DASHBOARD_PAGE__HELPER__LAST_REVIEWS',
    [DashboardWidgetEnum.Rating]: 'DASHBOARD_PAGE__HELPER__RATING',
    [DashboardWidgetEnum.OnlinePresence]: 'DASHBOARD_PAGE__HELPER__ONLINE_PRESENCE',
    [DashboardWidgetEnum.RatingDistribution]: 'DASHBOARD_PAGE__HELPER__RATING_DISTRIBUTION',
};
