import {Table, TablePaginationConfig} from 'antd';
import {useMemo} from 'react';

import {header_height} from '../../../../../../css/var-export.scss';
import {useSystem} from '../../../../../../provider/system/system-hook';
import {TechnicalSupportTicketsResponseType} from '../../../../../../service/technical-support/technical-support-type';
import {PaginationHookType} from '../../../../../../util/pagination-hook/pagination-hook-type';
import {TechnicalSupportTableEmpty} from '../technical-support-table-empty/technical-support-table-empty';

import {getTechnicalSupportTableColumns} from './technical-support-table-helper';
import * as styles from './technical-support-table.scss';

type PropsType = {
    data?: TechnicalSupportTicketsResponseType;
    isLoading: boolean;
    isFieldsLoading: boolean;
    pagination: PaginationHookType;
    refetch: VoidFunction;
};

export function TechnicalSupportTable(props: PropsType): JSX.Element {
    const {isFieldsLoading, isLoading, data, pagination, refetch} = props;

    const {screen} = useSystem();
    const {width} = screen;

    const columns = getTechnicalSupportTableColumns(width, refetch);

    const paginationOptions: TablePaginationConfig | false = useMemo(() => {
        if (!pagination) {
            return false;
        }

        return {
            ...pagination,
            total: pagination.total,
            pageSize: pagination.pageSize,
            current: pagination.page,
            size: 'default',
            showSizeChanger: false,
            onChange: pagination.onChange,
        };
    }, [pagination]);

    return (
        <div>
            <Table
                bordered={false}
                className={styles.TechnicalSupportTable}
                columns={columns}
                dataSource={data?.results}
                loading={isFieldsLoading || isLoading}
                locale={{emptyText: <TechnicalSupportTableEmpty />}}
                pagination={paginationOptions}
                rowKey={(record) => record.id}
                scroll={{x: '100%'}}
                sticky={{
                    offsetHeader: Number.parseFloat(header_height),
                }}
                tableLayout="fixed"
            />
        </div>
    );
}
