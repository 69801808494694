import {Table, TableColumnsType} from 'antd';
import {ColumnFilterItem} from 'antd/lib/table/interface';
import {useMemo} from 'react';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {CatalogInfo} from '../../../../component/catalog/catalog-info';
import {Text} from '../../../../component/text/text';
import {AsciiSpinner} from '../../../../layout/spinner/ascii-spinner';
import {TableFilterDropdown} from '../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useCatalogPostLinks, usePostsAvailableCatalogs} from '../../../../service/posts/posts-hooks';
import {CatalogPostStatusEnum, PostLinkType, PostSourceEnum} from '../../../../service/posts/posts-types';
import {getEnumValue} from '../../../../util/enum';
import {CatalogPostStatus} from '../post-status/catalog-post-status';
import {CATALOG_POST_STATUS_OPTIONS} from '../post-status/post-status-const';

import {PostLinkColumn} from './columns/post-link-column';
import {SourceNameColumn} from './columns/source-name-column';
import {PostDetailTableColumnsEnum} from './post-details-table-type';
import * as styles from './post-details-page.scss';

type PropsType = {
    postId: number;
};

export function PostDetailsTable(props: PropsType): JSX.Element {
    const {postId} = props;

    const {data, pagination, isLoading, isLoadingError, setFilter} = useCatalogPostLinks(postId);

    const {catalogsInfo} = usePostsAvailableCatalogs();
    const {getLocalizedString} = useLocale();

    const sourceFilterValues: Array<ColumnFilterItem> = useMemo(() => {
        if (!catalogsInfo) {
            return [];
        }

        return Object.entries(catalogsInfo)
            .map(([key, value]) => {
                if (!value) {
                    return null;
                }

                return {
                    text: value.name,
                    value: key,
                };
            })
            .filter(Boolean);
    }, [catalogsInfo]);

    const statusFilterValues: Array<ColumnFilterItem> = Object.values(CatalogPostStatusEnum)
        .map((value: CatalogPostStatusEnum) => {
            const postStatusData = CATALOG_POST_STATUS_OPTIONS[value];

            return {
                text: getLocalizedString(postStatusData.name),
                value,
            };
        })
        .filter(Boolean);

    const {total, pageSize, page, onChange} = pagination;

    function handleTableChange(filters: Record<string, Array<unknown> | null>) {
        const sourceFilterList: Array<PostSourceEnum> = [...(filters[PostDetailTableColumnsEnum.Source] || [])]
            .map((value) => getEnumValue(PostSourceEnum, value))
            .filter(Boolean);

        const statusFilterList: Array<CatalogPostStatusEnum> = [...(filters[PostDetailTableColumnsEnum.Status] || [])]
            .map((value) => getEnumValue(CatalogPostStatusEnum, value))
            .filter(Boolean);

        setFilter({
            providers: sourceFilterList,
            statuses: statusFilterList,
        });
    }

    const postLinksTableColumns: TableColumnsType<PostLinkType> = [
        {
            title: <Locale stringKey="POST__DETAILS_TABLE__SOURCE" />,
            width: '176px',
            dataIndex: PostDetailTableColumnsEnum.Source,
            render: (_value: unknown, record: PostLinkType) => <CatalogInfo provider={record.catalog} />,
            filters: sourceFilterValues,
            filterDropdown: TableFilterDropdown,
        },
        {
            title: <Locale stringKey="POST__DETAILS_TABLE__NAME" />,
            dataIndex: PostDetailTableColumnsEnum.Name,
            width: '464px',
            render: (_value: unknown, record: PostLinkType) => <SourceNameColumn data={record} />,
        },
        {
            align: 'center',
            title: <Locale stringKey="POST__DETAILS_TABLE__STATUS" />,
            width: '140px',
            dataIndex: PostDetailTableColumnsEnum.Status,
            filters: statusFilterValues,
            filterDropdown: TableFilterDropdown,
            render: (_value: unknown, record: PostLinkType) => {
                return <CatalogPostStatus status={record.currentStatus} />;
            },
        },
        {
            title: <Locale stringKey="POST__DETAILS_TABLE__LINK" />,
            width: '100px',
            align: 'center',
            dataIndex: PostDetailTableColumnsEnum.Link,
            render: (_value: unknown, record: PostLinkType) => <PostLinkColumn data={record} />,
        },
    ];

    if (isLoadingError) {
        return <AlertFallback />;
    }

    return (
        <>
            <Text block bolder className={styles.PostDetailsPage_postName}>
                <Locale
                    stringKey="POST__DETAILS__POST_NAME"
                    valueMap={{
                        postName: data?.commonPostName || <AsciiSpinner key="common-post-name" />,
                    }}
                />
            </Text>
            <Table<PostLinkType>
                columns={postLinksTableColumns}
                dataSource={data?.results}
                loading={isLoading}
                onChange={(_action, filters) => handleTableChange(filters)}
                pagination={{
                    total,
                    pageSize,
                    current: page,
                    size: 'default',
                    showSizeChanger: true,
                    onChange,
                }}
                rowKey={(record) => record.id}
                scroll={{x: true}}
                size="small"
            />
        </>
    );
}
