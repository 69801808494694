export const enHelpTechnicalSupportTranslation = {
    HELP_TECHNICAL_SUPPORT__PAGE__TITLE: 'List of applications',
    HELP_TECHNICAL_SUPPORT__PAGE__CREATE_REQUEST: 'Create a request',
    HELP_TECHNICAL_SUPPORT__TABLE__NAME: 'Name',
    HELP_TECHNICAL_SUPPORT__TABLE__COMMENTS: 'New comments',
    HELP_TECHNICAL_SUPPORT__TABLE__CREATOR: 'Creator',
    HELP_TECHNICAL_SUPPORT__TABLE__CREATION_DATE: 'Date of creation',
    HELP_TECHNICAL_SUPPORT__TABLE__STATUS: 'Status',
    HELP_TECHNICAL_SUPPORT__TABLE__TYPE: 'Type',
    HELP_TECHNICAL_SUPPORT__TABLE__CATEGORY: 'Category',
    HELP_TECHNICAL_SUPPORT__TABLE__VISIBILITY: 'Visibility',
    HELP_TECHNICAL_SUPPORT__TABLE__EMPTY__DESCRIPTION: 'There are no applications yet. You can create the first one.',
    HELP_TECHNICAL_SUPPORT__TABLE__EMPTY_WITH_FILTERS__DESCRIPTION: 'There is nothing for the specified filters.',

    HELP_TECHNICAL_SUPPORT__FILTER__TITLE: 'Filters',
    HELP_TECHNICAL_SUPPORT__FILTER__STATUS__LABEL: 'Status',
    HELP_TECHNICAL_SUPPORT__FILTER__STATUS__PLACEHOLDER: 'Select status(es) of the request',
    HELP_TECHNICAL_SUPPORT__FILTER__DATE__LABEL: 'Creation Date',
    HELP_TECHNICAL_SUPPORT__FILTER__TYPE__LABEL: 'Type',
    HELP_TECHNICAL_SUPPORT__FILTER__TYPE__ANY: 'Any',
    HELP_TECHNICAL_SUPPORT__FILTER__TYPE__PLACEHOLDER: 'Select type of the request',
    HELP_TECHNICAL_SUPPORT__FILTER__CLIENT_CATEGORY__LABEL: 'Category',
    HELP_TECHNICAL_SUPPORT__FILTER__CLIENT_CATEGORY__ANY: 'Any category',
    HELP_TECHNICAL_SUPPORT__FILTER__CLIENT_CATEGORY__PLACEHOLDER: 'Select category of the request',
    HELP_TECHNICAL_SUPPORT__FILTER__VISIBILITY__LABEL: 'Visibility',
    HELP_TECHNICAL_SUPPORT__FILTER__VISIBILITY__ANY: 'Any',
    HELP_TECHNICAL_SUPPORT__FILTER__CREATOR__LABEL: 'Creator',
    HELP_TECHNICAL_SUPPORT__FILTER__HAS_NEW_ANSWERS__LABEL: 'Has New Comments',
    HELP_TECHNICAL_SUPPORT__FILTER__CREATOR__PLACEHOLDER: 'Select employee(s)',
    HELP_TECHNICAL_SUPPORT__FILTER__CREATOR_GROUP__PLACEHOLDER: 'Select employee group(s)',
    HELP_TECHNICAL_SUPPORT__FILTER__CREATOR_GROUP__ANY: 'Any group',
    HELP_TECHNICAL_SUPPORT__FILTER__SEARCH__PLACEHOLDER: 'Search by request ID/title/description',

    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__TITLE: 'Creating a request',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__SUCCESS_MESSAGE: 'Request created',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__SUCCESS_DESCRIPTION:
        'Your application has been successfully added to the system.',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TYPE: 'Help type',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TYPE__PLACEHOLDER: 'Select case type',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__CLIENT_CATEGORY: 'Category',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__CLIENT_CATEGORY__PLACEHOLDER: 'Search by category',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__CLIENT_CATEGORY__TOOLTIP:
        'Select the section of your Personal Account for which you are creating a request',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TITLE: 'Subject',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TITLE__PLACEHOLDER: 'Select the subject of the request',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__DESCRIPTION: 'Description',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__DESCRIPTION__PLACEHOLDER:
        'Describe in detail the essence of the request',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__FILES: 'Attachments',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__FILES__HELPER: 'File upload available up to 100MB',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__PUBLIC: 'Available to team',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__PUBLIC__TOOLTIP:
        'The team application is available for viewing for all company employees.',
    HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__PUBLIC__TOOLTIP_TITLE: 'Team Submission',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__TITLE: 'View Request',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__STATUS_LABEL: 'Status:',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__CLOSING_DATE_LABEL: 'Closing date:',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__TYPE_LABEL: 'Type:',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__CATEGORY_LABEL: 'Category:',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__TRACK_REQUEST: 'Track request',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__COMMENTS_LABEL: 'Comments',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__COMMENTS_EMPTY: 'No comments added',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__NEW_COMMENT: 'New comment',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ENTER_MESSAGE: 'Enter message',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACH_FILES: 'Attach files',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACHED_FILES__SINGLE: '{count} attachment',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACHED_FILES__FEW: '{count} attachments',
    HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACHED_FILES__MANY: '{count} attachments',
    HELP_TECHNICAL_SUPPORT__FILES_VALIDATION_ERROR__WAIT_FOR_UPLOAD: 'Wait for file upload to complete',
    HELP_TECHNICAL_SUPPORT__FILES_VALIDATION_ERROR__HAS_FAILED_ITEMS: 'Some files failed to upload',
    HELP_TECHNICAL_SUPPORT__REOPEN_TICKET__BUTTON: 'Reopen ticket',
    HELP_TECHNICAL_SUPPORT__REOPEN_TICKET__SUCCESS: 'Ticket reopened',
    HELP_TECHNICAL_SUPPORT__REOPEN_TICKET__ERROR: 'Failed to reopen ticket',
};
