import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {handleNoteError} from '../../page/main/reviews/review-notes/note-form/note-form-helper';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeV2, postAndDeserialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {AnalyticsTarget, track} from '../analytics/analytics';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

export const reviewNotesUserSchema = r
    .object({
        id: r.number(),
        email: r.string(),
        firstName: r.string().nullable(),
        lastName: r.string().nullable(),
        fullName: r.string().nullable(),
    })
    .nullable();

const reviewNotesSchema = r.object({
    id: r.number(),
    text: r.string(),
    createdAt: r.string(),
    updatedAt: r.string(),
    user: reviewNotesUserSchema,
});

const postReviewNoteSchema = r.object({
    text: r.string(),
});

export type NoteFormType = r.infer<typeof postReviewNoteSchema>;

const reviewNotesCreateSchema = reviewNotesSchema;

export type ReviewNoteType = r.infer<typeof reviewNotesSchema>;
export type ReviewNoteUserType = r.infer<typeof reviewNotesUserSchema>;

type ReviewNoteResponseType = {
    results: Array<ReviewNoteType>;
};

const reviewNotesListSchema = r.object({
    results: r.array(reviewNotesSchema),
});

type CreateReviewNoteRequestType = Pick<ReviewNoteType, 'text'>;

type PostNoteMutateType = {
    reviewId: number;
    text: string;
};

type UpdateNoteMutateType = {
    reviewId: number;
    text: string;
    noteId: number;
};

function getReviewNotesUrl(reviewId: number | null) {
    return `${rootApiUrl}/v4/reviews/${reviewId}/notes/`;
}

async function fetchNotes(reviewId: number | null): Promise<ReviewNoteResponseType> {
    const url = getReviewNotesUrl(reviewId);
    const response = await fetchX(url);

    return deserializeV2<ReviewNoteResponseType>(url, reviewNotesListSchema, response);
}

export function useNotes(reviewId: number | null, enabled: boolean): UseQueryResult<ReviewNoteResponseType> {
    return useQuery([getReviewNotesUrl(reviewId)], () => fetchNotes(reviewId), {
        enabled: reviewId !== null && enabled,
        refetchOnWindowFocus: false,
    });
}

export async function fetchNotesCreate(reviewId: number, data: CreateReviewNoteRequestType): Promise<ReviewNoteType> {
    const url = `${rootApiUrl}/v4/reviews/${reviewId}/notes/`;

    const response = await fetchX<ReviewNoteType>(url, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<ReviewNoteType>(url, reviewNotesCreateSchema, response);
}

export async function fetchNotesUpdate(
    reviewId: number,
    noteId: number,
    data: CreateReviewNoteRequestType
): Promise<ReviewNoteType> {
    const url = `${rootApiUrl}/v4/reviews/${reviewId}/notes/${noteId}/`;

    const response = await fetchX<ReviewNoteType>(url, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<ReviewNoteType>(url, reviewNotesCreateSchema, response);
}

export function fetchNotesDelete(reviewId: number, noteId: number): Promise<void> {
    const url = `${rootApiUrl}/v4/reviews/${reviewId}/notes/${noteId}/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

function postReviewNote(reviewId: number, text: string): Promise<ReviewNoteType> {
    return postAndDeserialize(`/v4/reviews/${reviewId}/notes/`, postReviewNoteSchema, {
        text,
    });
}

export function useNotesMutation(): UseMutationResult<ReviewNoteType, unknown, PostNoteMutateType> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: ({reviewId, text}) => {
            return postReviewNote(reviewId, text);
        },
        onSuccess: () => {
            snackbar.success({
                description: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_SUCCESS_DESCRIPTION" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_SUCCESS_TITLE" />,
            });
            track(AnalyticsTarget.ReviewsManagement.Reviews.CreateNote);
        },
        onError: (error) => {
            const message = handleNoteError(error);

            if (message) {
                snackbar.error(message);
                return;
            }

            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_FAIL" />,
            });
        },
    });
}

export function useNotesUpdateMutation(): UseMutationResult<ReviewNoteType, unknown, UpdateNoteMutateType> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: ({reviewId, text, noteId}) => {
            return fetchNotesUpdate(reviewId, noteId, {text});
        },

        onSuccess: () => {
            snackbar.success({
                description: <Locale stringKey="REVIEWS__REVIEW_NOTE_CHANGE_SUCCESS_DESCRIPTION" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_CHANGE_SUCCESS_TITLE" />,
            });
            track(AnalyticsTarget.ReviewsManagement.Reviews.EditNote);
        },

        onError: (error) => {
            const message = handleNoteError(error);

            if (message) {
                snackbar.error(message);
                return;
            }

            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_SEND_FAIL" />,
            });
        },
    });
}
