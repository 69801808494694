import {createLocalization} from './library/localization';
import {LocalizationConfigType, LocalizationStateType} from './library/localization-type';
import {getLocaleNameToTranslationsObjectMap} from './locale-context-const';
import {getSavedLocaleName, saveLocaleName} from './locale-context-helper';
import {LocaleNameEnum} from './locale-context-type';
import {LangKeyType} from './translation/type';

const localizationConfig: LocalizationConfigType<LangKeyType, LocaleNameEnum> = {
    defaultLocaleName: getSavedLocaleName(),
    localization: getLocaleNameToTranslationsObjectMap(),
    onUseEffect: (localizationProviderState: LocalizationStateType<LocaleNameEnum>) => {
        const {localeName} = localizationProviderState;

        saveLocaleName(localeName);
    },
};

const {
    LocalizationProvider,
    Locale,
    useLocale: useLocalizationHook,
} = createLocalization<LangKeyType, LocaleNameEnum>(localizationConfig);

export {Locale, LocalizationProvider, useLocalizationHook};
