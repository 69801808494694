import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchDeletedReviewsCount} from './deleted-reviews-count-api';
import {DeletedReviewsCountType} from './deleted-reviews-count-type';

export function useDeletedReviewsCount(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<DeletedReviewsCountType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['deletedReviewsCount', filter, mainFilterKey],
        queryFn: async () => {
            return fetchDeletedReviewsCount(filter, mainFilterKey);
        },
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}
