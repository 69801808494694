import {Button} from 'antd';
import {useContext, useState} from 'react';

import {appRoute} from '../../../app-route';
import {CursorPaginator} from '../../../component/cursor-paginator/cursor-paginator';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {useProductGroups} from '../../../service/products/products-hook';

import {ProductGroupsTable} from './components/product-groups-table/product-groups-table';
import {SelectNewProductGroupTypeModal} from './components/select-create-product-group-type-modal/select-new-product-group-type-modal';
import {productGroupsSizeOptions} from './products-const';
import * as styles from './components/product-groups-table/product-groups-table.scss';

export function Products(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {modal} = useModal();
    const {snackbar} = useSnackbar();

    const [isCreateGroupTypeModalOpen, setIsCreateGroupTypeModalOpen] = useState<boolean>(false);
    const [isRemovingProductGroup, setIsRemovingProductGroup] = useState<boolean>(false);

    const {
        result,
        isInProgress,
        remove: removeProductGroup,
        reset: refreshProductGroups,
        cursorPagination,
    } = useProductGroups(mainFilterKey);

    function handleRemoveProductGroup(productGroupId: number) {
        setIsRemovingProductGroup(true);
        modal.confirm({
            title: <Locale stringKey="PRODUCTS__GROUP_FORM__REMOVE_MODAL__TITLE" />,
            content: <Locale stringKey="PRODUCTS__GROUP_FORM__REMOVE_MODAL__CONTENT" />,
            okText: <Locale stringKey="BUTTON__DELETE" />,
            okType: 'danger',
            className: styles.ProductGroupsTable_removeWarning,
            cancelText: <Locale stringKey="BUTTON__CANCEL" />,
            onOk: () => {
                removeProductGroup(productGroupId)
                    .then(() => {
                        snackbar.success(<Locale stringKey="PRODUCTS__GROUP_FORM__REMOVE__SUCCESS" />);
                        refreshProductGroups();
                    })
                    .finally(() => {
                        setIsRemovingProductGroup(false);
                    })
                    .catch(() => {
                        snackbar.success(<Locale stringKey="PRODUCTS__GROUP_FORM__REMOVE__ERROR" />);
                    });
            },
            onCancel: () => {
                setIsRemovingProductGroup(false);
            },
        });
    }

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__PRODUCTS')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.products.path,
                        titleLangKey: 'CATEGORY_NAME__PRODUCTS',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle productName={UsetifulNameProductEnum.PRODUCTS} title="PRODUCTS__HEADER" />

                <Button onClick={() => setIsCreateGroupTypeModalOpen(true)} type="primary">
                    <Locale stringKey="PRODUCTS__CREATE_GROUP" />
                </Button>
            </PageHeader>

            <>
                <ProductGroupsTable
                    data={result || []}
                    isLoading={isInProgress}
                    isRemovingProductGroup={isRemovingProductGroup}
                    removeProductGroup={handleRemoveProductGroup}
                />
                <CursorPaginator cursorPagination={cursorPagination} pageSizeOptions={productGroupsSizeOptions} />
            </>

            <SelectNewProductGroupTypeModal
                isOpen={isCreateGroupTypeModalOpen}
                onClose={() => setIsCreateGroupTypeModalOpen(false)}
            />
        </Page>
    );
}
