import {Button} from 'antd';

import {AdditionalInfo} from '../../../../layout/additional-info/additional-info';
import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/locale';
import {DashboardWidgetEnum} from '../dashboard-type';

import {DASHBOARD_WIDGET_DESCRIPTION_KEY} from './dashboard-documentation-const';
import * as styles from './dashboard-widget-header.scss';

type PropsType = {
    title: JSX.Element;
    widget?: DashboardWidgetEnum | null;
};

export function DashboardWidgetHeader(props: PropsType): JSX.Element {
    const {title, widget} = props;
    const documentationLinks = useDocumentationLinks();
    const helpLink = widget ? documentationLinks.dashboard[widget] : null;

    if (!widget) {
        return title;
    }

    if (!helpLink) {
        return title;
    }

    return (
        <div className={styles.DashboardWidgetHeader}>
            <div className={styles.DashboardWidgetHeader_title}>{title}</div>
            <AdditionalInfo>
                <PopoverTextWrapper>
                    <div>
                        <Locale stringKey={DASHBOARD_WIDGET_DESCRIPTION_KEY[widget]} />
                    </div>
                    <Button className={styles.DashboardWidgetHeader_button} href={helpLink} target="_blank" type="link">
                        <Locale stringKey="DASHBOARD_PAGE__HELPER__DETAILS" />
                    </Button>
                </PopoverTextWrapper>
            </AdditionalInfo>
        </div>
    );
}
