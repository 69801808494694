import {Space} from 'antd';
import {isArray, isString} from 'lodash';
import {ReactNode} from 'react';

import {appRoute} from '../../../../../../../../app-route';
import {Text} from '../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../../../provider/locale/translation/type';
import {GoogleVerificationMethodEnum} from '../../../../../../../../service/google/confirmation/google-confirmation-type';
import {isRecord} from '../../../../../../../../util/object';

type GeneralMethodConfigType = {
    title: LangKeyType;
    label: LangKeyType;
    enterTitle?: LangKeyType;
    successTitle: LangKeyType;
    successText: LangKeyType;
};

type VerificationMethodsConfigType = {
    [GoogleVerificationMethodEnum.PhoneCall]: GeneralMethodConfigType;
    [GoogleVerificationMethodEnum.SMS]: GeneralMethodConfigType;
    [GoogleVerificationMethodEnum.Email]: GeneralMethodConfigType;
    [GoogleVerificationMethodEnum.Address]: {
        title: LangKeyType;
        label: LangKeyType;
        enterTitle?: LangKeyType;
        successTitle: LangKeyType;
        successNode: ReactNode;
    };
};

export function useVerificationMethodsConfig(): VerificationMethodsConfigType {
    return {
        [GoogleVerificationMethodEnum.PhoneCall]: {
            title: 'GOOGLE_SYNC__CODE_REQUEST__TYPE__PHONE_CALL',
            label: 'GOOGLE_SYNC__CODE_REQUEST__PHONE_CALL__LABEL',
            enterTitle: 'GOOGLE_SYNC__CODE_ENTER__TYPE__PHONE_CALL',
            successTitle: 'GOOGLE_SYNC__CODE_REQUEST__SUCCESS__TITLE',
            successText: 'GOOGLE_SYNC__CODE_REQUEST__PHONE_CALL__SUCCESS',
        },
        [GoogleVerificationMethodEnum.SMS]: {
            title: 'GOOGLE_SYNC__CODE_REQUEST__TYPE__SMS',
            label: 'GOOGLE_SYNC__CODE_REQUEST__PHONE_CALL__LABEL',
            successTitle: 'GOOGLE_SYNC__CODE_REQUEST__SUCCESS__TITLE',
            successText: 'GOOGLE_SYNC__CODE_REQUEST__SMS__SUCCESS',
        },
        [GoogleVerificationMethodEnum.Email]: {
            title: 'GOOGLE_SYNC__CODE_REQUEST__TYPE__EMAIL',
            label: 'GOOGLE_SYNC__CODE_REQUEST__EMAIL__LABEL',
            successTitle: 'GOOGLE_SYNC__CODE_REQUEST__SUCCESS__TITLE',
            successText: 'GOOGLE_SYNC__CODE_REQUEST__EMAIL__SUCCESS',
        },
        [GoogleVerificationMethodEnum.Address]: {
            title: 'GOOGLE_SYNC__CODE_REQUEST__TYPE__ADDRESS',
            label: 'GOOGLE_SYNC__CODE_REQUEST__ADDRESS__LABEL',
            enterTitle: 'GOOGLE_SYNC__CODE_ENTER__TYPE__ADDRESS',
            successTitle: 'GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TITLE',
            successNode: (
                <Space direction="vertical" size="small">
                    <Locale stringKey="GOOGLE_SYNC__CODE_REQUEST__ADDRESS__DESCRIPTION" />

                    <Locale
                        stringKey="GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TEXT__1"
                        valueMap={{
                            link: (
                                <a
                                    href={appRoute.sourcesAccountSyncGoogleConfirmations.path}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <Locale stringKey="GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TEXT__NEXT_LINK" />
                                </a>
                            ),
                        }}
                    />

                    <Text lighter stringKey="GOOGLE_SYNC__CODE_REQUEST__ADDRESS__SUCCESS__TEXT__2" />
                </Space>
            ),
        },
    };
}

export function getVerificationOptionsErrors(error: unknown): Array<string> {
    if (
        !isRecord(error) ||
        !isRecord(error.jsonData) ||
        !isArray(error.jsonData.errors) ||
        !isString(error.jsonData.errors[0])
    ) {
        return [];
    }

    return error.jsonData.errors;
}

export function getMutationErrors(error: unknown): Array<string> {
    if (!isRecord(error) || !isRecord(error.jsonData) || !isString(error.jsonData.error)) {
        return [];
    }

    return [error.jsonData.error];
}
