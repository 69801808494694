import {Locale} from '../../../../provider/locale/locale';
import {classNames} from '../../../../util/css';

import {BigRadioButtonNameEnum} from './big-radio-button-const';
import {getButtonDataByType} from './big-radio-button-helper';
import * as styles from './big-radio-button.scss';

type PropsType = {
    type: BigRadioButtonNameEnum;
    value: number;
    onClick: () => void;
    isActive: boolean;
    className?: string;
};

export function BigRadioButton(props: PropsType): JSX.Element {
    const {className, type, value, isActive, onClick} = props;
    const {titleKey, icon, className: classNameByType} = getButtonDataByType(type);

    return (
        <button
            className={classNames(
                classNameByType,
                styles.BigRadioButton,
                {[styles.BigRadioButton__active]: isActive},
                className
            )}
            onClick={onClick}
            type="button"
        >
            <span className={styles.BigRadioButton_title}>
                <Locale stringKey={titleKey} />
            </span>

            <div className={styles.BigRadioButton_valueRow}>
                <span className={styles.BigRadioButton_iconWrapper}>{icon}</span>

                <span className={styles.BigRadioButton_value}>{value}</span>
            </div>
        </button>
    );
}
