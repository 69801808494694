import {Button, Modal} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {useCreateTicket} from '../../../../../../service/technical-support/technical-support-hook';
import {CreateTicketFormType} from '../../../../../../service/technical-support/technical-support-type';
import {Form} from '../../../../../../typings/antd';
import {TechnicalSupportCreateForm} from '../technical-support-create-form/technical-support-create-form';

export function TechnicalSupportCreateModal(): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const [form] = Form.useForm<CreateTicketFormType>();
    const {mutateAsync} = useCreateTicket();
    const {snackbar} = useSnackbar();

    const {getLocalizedString} = useLocale();

    function handleClose() {
        form.resetFields();
        setIsOpen(false);
    }

    function handleCreateTicket(values: CreateTicketFormType) {
        mutateAsync({
            ...values,
            files: Object.values(values.files),
        })
            .then(() => {
                handleClose();
                snackbar.success({
                    description: getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__SUCCESS_DESCRIPTION'),
                    message: getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__SUCCESS_MESSAGE'),
                });
            })
            .catch(() => {
                snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
            });
    }

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                const formValues = form.getFieldValue([]) as CreateTicketFormType;

                handleCreateTicket(formValues);
            })
            .catch((error: unknown) => {
                console.log('Error', error);
            });
    }

    return (
        <>
            <Button onClick={() => setIsOpen(true)} type="primary">
                <Locale stringKey="HELP_TECHNICAL_SUPPORT__PAGE__CREATE_REQUEST" />
            </Button>
            <Modal
                destroyOnClose
                forceRender={false}
                okText={getLocalizedString('HELP_TECHNICAL_SUPPORT__PAGE__CREATE_REQUEST')}
                onCancel={handleClose}
                onOk={handleSubmit}
                open={isOpen}
                title={getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__TITLE')}
                width={600}
            >
                <TechnicalSupportCreateForm form={form} />
            </Modal>
        </>
    );
}
