import {faCamera, faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';

import {Locale} from '../../../../../provider/locale/localization';
import {FakesCompanyInfoType} from '../../../../../service/fakes/fakes-companies-info';
import {FakesResultType} from '../../../../../service/fakes/fakes-results';
import {classNames} from '../../../../../util/css';

import * as styles from './fakes-catalog-image.scss';

type PropsType = Pick<FakesResultType, 'logoUrl' | 'name'> &
    Pick<FakesCompanyInfoType, 'url'> & {
        isCompany?: boolean;
        className?: string;
    };

export function FakesCatalogImage(props: PropsType): JSX.Element {
    const {isCompany, logoUrl, url, name, className} = props;

    if (isCompany) {
        return (
            <div className={classNames(styles.FoundLocationColumn_logo, styles.FoundLocationColumn_logo__isCompany)}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
        );
    }

    if (!logoUrl) {
        const placeholder = (
            <div className={classNames(styles.FoundLocationColumn_logo, className)}>
                <FontAwesomeIcon icon={faCamera} />
            </div>
        );

        return (
            <Tooltip placement="bottom" title={<Locale stringKey="FAKES__RESULTS__NO_LOGO" />}>
                {url ? (
                    <a href={url} rel="noreferrer" target="_blank">
                        {placeholder}
                    </a>
                ) : (
                    placeholder
                )}
            </Tooltip>
        );
    }

    const logo = logoUrl && (
        <img alt={`${name} logo`} className={classNames(styles.FoundLocationColumn_logo, className)} src={logoUrl} />
    );

    return url ? (
        <a href={url} rel="noreferrer" target="_blank">
            {logo}
        </a>
    ) : (
        <span>{logo}</span>
    );
}
