import {TranslationsType} from '../type';

import {enAccountSelectorTranslations} from './translation-parts/account-selector-translations';
import {enActivationPageTranslations} from './translation-parts/activation-page-translations';
import {enAdditionalInfoTranslations} from './translation-parts/additional-info-translations';
import {enAuthorizationTranslations} from './translation-parts/authorization-tranlsations';
import {enAutoRepliesTranslations} from './translation-parts/auto-replies-translations';
import {enBulkEditCompaniesTranslations} from './translation-parts/bulk-edit-companies-translations';
import {enButtonTranslations} from './translation-parts/button-translations';
import {enCategoryNameTranslations} from './translation-parts/category-name-translations';
import {enChangeEmailTranslations} from './translation-parts/change-email-translations';
import {enCompanyActivityAndSummaryTranslations} from './translation-parts/company-activity-and-summary-translations';
import {enCompanyFormTranslations} from './translation-parts/company-form-translations';
import {enCompanyMatchingTranslations} from './translation-parts/company-matching-translations';
import {enCompanyMultiselectTranslations} from './translation-parts/company-multiselect-translations';
import {enContactsTranslations} from './translation-parts/contacts-translations';
import {enDashboardTranslations} from './translation-parts/dashboard-translations';
import {enDateTimeTranslations} from './translation-parts/datetime-translations';
import {enDoubleGisTranslations} from './translation-parts/double-gis-translations';
import {enErrorTranslations} from './translation-parts/error-translations';
import {enFakesTranslations} from './translation-parts/fakes-translations';
import {enFeedTranslations} from './translation-parts/feed-tranlsations';
import {enFormValidationTranslations} from './translation-parts/form-validation-translations';
import {enGeneralInfoTranslations} from './translation-parts/general-info-translations';
import {enGoogleSyncTranslations} from './translation-parts/google-sync-translations';
import {enGroupOfCompaniesTranslations} from './translation-parts/group-of-companies-translations';
import {enHeaderTranslations} from './translation-parts/header-translations';
import {enHelpTechnicalSupportTranslation} from './translation-parts/help-technical-support-translation';
import {enImagesTranslations} from './translation-parts/images-translations';
import {enIntegrationTranslations} from './translation-parts/integration-translations';
import {enKnowledgeTranslations} from './translation-parts/knowledge-tranlsations';
import {enLanguageSelectorTranslations} from './translation-parts/language-selector-translations';
import {enLegalAgreementTranslations} from './translation-parts/legal-agreement-translations';
import {enLocalPackTranslations} from './translation-parts/local-pack-translations';
import {enMainFilterTranslations} from './translation-parts/main-filter-translations';
import {enMyCompaniesTranslations} from './translation-parts/my-companies-translations';
import {enNeuralNetworkTranslations} from './translation-parts/neural-network-translations';
import {enNotificationsTranslations} from './translation-parts/notifications-translations';
import {enOmnichannelTranslations} from './translation-parts/omnichannel-translations';
import {enOnboardingTranslations} from './translation-parts/onboarding-translations';
import {enOnlinePresenceTranslations} from './translation-parts/online-presence-translations';
import {enPhotoToolTranslations} from './translation-parts/photo-tool-translations';
import {enPostsTranslations} from './translation-parts/posts-translations';
import {enProductsTranslations} from './translation-parts/products-translations';
import {enProfileTranslations} from './translation-parts/profile-translations';
import {enQuestionsAndAnswersTranslations} from './translation-parts/questions-and-answers-translations';
import {enResponseTemplatesTranslations} from './translation-parts/response-templates-translations';
import {enReviewGeneratorTranslations} from './translation-parts/review-generator-translations';
import {enReviewsAnalysisTranslations} from './translation-parts/reviews-analysis-translations';
import {enReviewsAndAnswersStatisticsTranslations} from './translation-parts/reviews-and-answers-statistics-translations';
import {enReviewsGeneratorPromoCodeTranslations} from './translation-parts/reviews-generator-promo-code';
import {enReviewsTranslations} from './translation-parts/reviews-translations';
import {enSnackbarTranslations} from './translation-parts/snackbar-translations';
import {enSourceSettingsTranslations} from './translation-parts/source-settings-translations';
import {enSourcesConfirmationTranslations} from './translation-parts/sources-confirmation-translations';
import {enSourcesTranslations} from './translation-parts/sources-translations';
import {euStoreLocatorTranslations} from './translation-parts/store-locator-translations';
import {enTableHeaderTranslations} from './translation-parts/table-translations';
import {enTagsTranslations} from './translation-parts/tags-translations';
import {enTariffsAndLicensesTranslations} from './translation-parts/tariffs-and-licenses-translations';
import {enTextTranslations} from './translation-parts/text-translations';
import {enUsersTranslations} from './translation-parts/users-translations';
import {enYandexStoriesTranslations} from './translation-parts/yandex-stories-translations';

export const enUs: TranslationsType = {
    ...enDateTimeTranslations,
    ...enLegalAgreementTranslations,
    ...enAuthorizationTranslations,
    ...enFormValidationTranslations,
    ...enSnackbarTranslations,
    ...enMainFilterTranslations,
    ...enLanguageSelectorTranslations,
    ...enCategoryNameTranslations,
    ...enTextTranslations,
    ...enButtonTranslations,
    ...enTableHeaderTranslations,
    ...enGeneralInfoTranslations,
    ...enContactsTranslations,
    ...enAdditionalInfoTranslations,
    ...enImagesTranslations,
    ...enCompanyMultiselectTranslations,
    ...enCompanyFormTranslations,
    ...enBulkEditCompaniesTranslations,
    ...enSourcesTranslations,
    ...enProfileTranslations,
    ...enIntegrationTranslations,
    ...enSourceSettingsTranslations,
    ...enGroupOfCompaniesTranslations,
    ...enNotificationsTranslations,
    ...enTagsTranslations,
    ...enResponseTemplatesTranslations,
    ...enAutoRepliesTranslations,
    ...enNeuralNetworkTranslations,
    ...enQuestionsAndAnswersTranslations,
    ...enReviewsTranslations,
    ...enPhotoToolTranslations,
    ...enPostsTranslations,
    ...enActivationPageTranslations,
    ...enReviewsAnalysisTranslations,
    ...enReviewGeneratorTranslations,
    ...euStoreLocatorTranslations,
    ...enCompanyActivityAndSummaryTranslations,
    ...enDashboardTranslations,
    ...enLocalPackTranslations,
    ...enSourcesConfirmationTranslations,
    ...enCompanyMatchingTranslations,
    ...enAccountSelectorTranslations,
    ...enProductsTranslations,
    ...enOnlinePresenceTranslations,
    ...enGoogleSyncTranslations,
    ...enFakesTranslations,
    ...enOmnichannelTranslations,
    ...enKnowledgeTranslations,
    ...enUsersTranslations,
    ...enFeedTranslations,
    ...enTariffsAndLicensesTranslations,
    ...enMyCompaniesTranslations,
    ...enHelpTechnicalSupportTranslation,
    ...enChangeEmailTranslations,
    ...enHeaderTranslations,
    ...enErrorTranslations,
    ...enDoubleGisTranslations,
    ...enReviewsGeneratorPromoCodeTranslations,
    ...enOnboardingTranslations,
    ...enYandexStoriesTranslations,
    ...enReviewsAndAnswersStatisticsTranslations,
};
