import {Form, Input, Space} from 'antd';

import {Text} from '../../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../../../../service/form/form-rules-hook';
import {GoogleVerificationOptionType} from '../../../../../../../../../service/google/confirmation/google-confirmation-type';
import {classNames} from '../../../../../../../../../util/css';
import * as styles from '../code-request-modal.scss';
import {CodeRequestFormType} from '../code-request-modal-type';

type PropsType = {
    verification?: GoogleVerificationOptionType;
};

export function EmailVerification(props: PropsType): JSX.Element | null {
    const {verification} = props;

    const {getLocalizedString} = useLocale();
    const {requiredFieldRule, emailFieldRule} = useFormRules();

    if (!verification) {
        return null;
    }

    const placeholder = getLocalizedString('GOOGLE_SYNC__CODE_REQUEST__EMAIL__PLACEHOLDER');
    const isNonEditableDomain = verification.detail?.includes('@');
    const noInputShown = !verification.isUserNameEditable;

    return (
        <>
            {noInputShown ? (
                <Space className={styles.CodeRequestModal_card} direction="vertical" size={2}>
                    <Text secondary small stringKey="GOOGLE_SYNC__CODE_REQUEST__EMAIL__LABEL" />

                    <Text>{verification.detail}</Text>
                </Space>
            ) : (
                <Form.Item<CodeRequestFormType>
                    label={<Text lighter stringKey="GOOGLE_SYNC__CODE_REQUEST__EMAIL__LABEL" />}
                    name="email"
                    required
                    rules={isNonEditableDomain ? [requiredFieldRule] : [requiredFieldRule, emailFieldRule]}
                >
                    <Input
                        // eslint-disable-next-line no-undefined
                        addonAfter={isNonEditableDomain ? `@${verification.detail?.split('@')[1]}` : undefined}
                        placeholder={isNonEditableDomain ? placeholder.split('@')[1] : placeholder}
                    />
                </Form.Item>
            )}

            <Text
                block
                className={classNames({[styles.CodeRequestModal_delayDescription]: !noInputShown})}
                lighter
                stringKey="GOOGLE_SYNC__CODE_REQUEST__EMAIL__DESCRIPTION"
            />
        </>
    );
}
