import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';

import {
    reviewRateCompetitorsResponseSchema,
    ReviewRateCompetitorsResponseType,
    reviewRatePerCompanyResponseSchema,
    ReviewRatePerCompanyResponseType,
    ReviewsAnalysisRateQueryType,
} from './review-rate-type';

export async function fetchReviewRatePerCompany(
    options: ReviewsAnalysisRateQueryType,
    mainFilterKey: string
): Promise<ReviewRatePerCompanyResponseType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisRateQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/companies-rating-dynamic/?${searchParameters}`;

    return fetchAndDeserialize<ReviewRatePerCompanyResponseType>(url, reviewRatePerCompanyResponseSchema);
}

export async function fetchReviewRateCompetitors(
    options: ReviewsAnalysisRateQueryType,
    mainFilterKey: string
): Promise<ReviewRateCompetitorsResponseType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisRateQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/rating-dynamic/?${searchParameters}`;

    return fetchAndDeserialize<ReviewRateCompetitorsResponseType>(url, reviewRateCompetitorsResponseSchema);
}
