import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Upload} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {UploadChangeParam} from 'antd/lib/upload/interface';
import {camelizeKeys} from 'humps';
import {useCallback, useEffect, useState} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../../../provider/modal/modal-hook';
import {getCsrfHeaders, rootApiUrl} from '../../../../../../../../../service/api/api-const';
import {removePostVideo} from '../../../../../../../../../service/posts/posts-api';
import {PostVideoType} from '../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../util/css';
import {getErrorMessages} from '../../../../../../../../../util/error';
import {BYTES_IN_MB, MAX_VIDEO_SIZE_MB} from '../../../../../posts-form-helper';
import * as styles from '../media.scss';
import {MediaCardLoading} from '../media-card-loading';

import {VideoCard} from './video-card';
import {isPostVideoUploadType} from './video-item-helper';

type PropsType = {
    video?: PostVideoType | null;
    onChange?: (newVideo: PostVideoType | null) => void;
    isUploading: boolean;
    setIsUploading: (newIsLoading: boolean) => void;
    uploadButtonClassName?: string;
    disabled: boolean;
};

export function VideoInput(props: PropsType): JSX.Element {
    const {video, onChange, setIsUploading, isUploading, uploadButtonClassName, disabled} = props;

    const {modal} = useModal();
    const {getLocalizedString} = useLocale();

    const onRemoveVideo = useCallback(async () => {
        if (video) {
            await removePostVideo(video?.id);
            onChange?.(null);
        }
    }, [video, onChange]);

    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        setProgress(0);
    }, [video]);

    const onBeforeUpload: (file: RcFile) => Promise<boolean> = useCallback(
        async (file: RcFile): Promise<boolean> => {
            if (file.size / BYTES_IN_MB > MAX_VIDEO_SIZE_MB) {
                modal.error({
                    content: (
                        <Locale stringKey="POSTS_FORM__ERROR__IMAGE_SIZE" valueMap={{maxSize: MAX_VIDEO_SIZE_MB}} />
                    ),
                    title: <Locale stringKey="POSTS_FORM__ERROR__IMAGE__TITLE" />,
                });
                return false;
            }

            setIsUploading(true);
            return true;
        },
        [modal, setIsUploading]
    );

    const onUploadChange = useCallback(
        (info: UploadChangeParam) => {
            switch (info.file.status) {
                case 'done': {
                    if (info.file.response) {
                        const responseCamelCase = camelizeKeys(info.file.response);

                        if (isPostVideoUploadType(responseCamelCase)) {
                            onChange?.(responseCamelCase);
                        }

                        setIsUploading(false);
                    }

                    break;
                }
                case 'error': {
                    const responseErrorMessages = getErrorMessages(info.file.response);

                    const errorContent =
                        responseErrorMessages.length > 0
                            ? responseErrorMessages.map((errorString, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <Text block key={String(index)}>
                                      {errorString}
                                  </Text>
                              ))
                            : getLocalizedString('POSTS_FORM__ERROR__VIDEO_UPLOAD');

                    modal.error({
                        content: errorContent,
                        title: <Locale stringKey="POSTS_FORM__ERROR__VIDEO_TITLE" />,
                    });

                    setIsUploading(false);
                    break;
                }
                default: {
                    setProgress(info.event?.percent || 0);
                }
            }
        },
        [getLocalizedString, modal, onChange, setIsUploading]
    );

    return (
        <div className={styles.PostMedia}>
            {video && <VideoCard onRemove={onRemoveVideo} showRemoveControl={!disabled} video={video} />}
            {isUploading && <MediaCardLoading progress={progress} />}
            {!video && (
                <Upload<PostVideoType>
                    accept=".mp4,.mov,.3gp,.avi,.mpeg,.m2ts,.mts,.mpe,.mpeg4,.mkv,.mpg "
                    action={`${rootApiUrl}/v1/posts/video/`}
                    beforeUpload={onBeforeUpload}
                    headers={{
                        ...getCsrfHeaders(),
                    }}
                    itemRender={() => null}
                    maxCount={1}
                    multiple={false}
                    name="video"
                    onChange={onUploadChange}
                >
                    {!isUploading && (
                        <Button
                            className={classNames(styles.PostMedia_uploadButton, uploadButtonClassName)}
                            disabled={disabled}
                        >
                            <div>
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                            <Text inheritColor>
                                <Locale stringKey="POSTS__UPLOAD_VIDEO" />
                            </Text>
                        </Button>
                    )}
                </Upload>
            )}
        </div>
    );
}
