import {ExtractRouteParams} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';

import {PostDetailsTable} from './post-detailes-table';

export function PostDetailsPage(): JSX.Element {
    const {postsManagement, postsManagementPostDetails} = appRoute;

    const {getLocalizedString} = useLocale();

    const {postId} = useParams<ExtractRouteParams<typeof postsManagementPostDetails.path, string>>();

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__POST_DETAILS')} />

            <BreadCrumbs
                list={[
                    {path: postsManagement.path, titleLangKey: 'CATEGORY_NAME__POSTS'},
                    {path: '', titleLangKey: 'POST__DETAILS__BREADCRUMBS'},
                ]}
            />

            <PageHeader>
                <Locale stringKey="POST__DETAILS__HEADER" />
            </PageHeader>

            <PostDetailsTable postId={Number(postId)} />
        </Page>
    );
}
