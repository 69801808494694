import {faFile, faPenToSquare, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {SyntheticEvent, useState} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../../service/analytics/analytics';
import {fetchReviewsReply} from '../../../../../../../service/reviews/reviews-reply';
import {ResponseTemplateDataType} from '../../../../../../../service/reviews/reviews-response-templates';
import {
    fetchReviewSuggestionApply,
    fetchReviewSuggestionDismiss,
    ReviewSuggestionType,
} from '../../../../../../../service/reviews/reviews-suggestions';
import {classNames} from '../../../../../../../util/css';
import {IdNumberType} from '../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';
import {ReplyTypeEnum} from '../reply-form/reply-form-const';
import {checkHasJsonData, constructComment, handleReviewReplyExceptions} from '../review-helper';
import {ReviewIconButton} from '../review-icon-button/review-icon-button';

import * as styles from './suggestion-item.scss';

const {TextArea} = Input;

const SENT_SUCCESSFULLY_KEY = 'sent-successfully';

type PropsType = {
    className?: string;
    reviewId: number;
    refreshReview: (reviewId: number) => Promise<unknown>;
    refreshSuggestions: () => Promise<unknown>;
    suggestion: ReviewSuggestionType;
};

export function SuggestionItem(props: PropsType): JSX.Element {
    const {className, reviewId, refreshReview, refreshSuggestions, suggestion} = props;
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    const [isAnswerActive, setIsAnswerActive] = useState<boolean>(false);
    const [answerText, setAnswerText] = useState<string>(suggestion.comment || '');
    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );

    const [isStandardReplying, setIsStandardReplying] = useState<boolean>(false);
    const [isSuggestReplying, setIsSuggestReplying] = useState<boolean>(false);

    function handleSelectTemplateClick() {
        track(AnalyticsTarget.ReviewsManagement.Reviews.ClickResponseTemplates);

        setIsSelectTemplatePopupOpen(true);
    }

    async function standardReply() {
        try {
            setIsStandardReplying(true);
            await fetchReviewSuggestionDismiss(suggestion.id);
            await fetchReviewsReply(reviewId, {
                comment: answerText.trim(),
            });
            await refreshReview(reviewId);
            await refreshSuggestions();

            setIsAnswerActive(false);
            snackbar.success({
                key: SENT_SUCCESSFULLY_KEY,
                message: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__TITLE" />,
                description: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__DESCRIPTION" />,
            });
        } catch (error: unknown) {
            handleReviewReplyExceptions(error, snackbar);
        } finally {
            setIsStandardReplying(false);
        }
    }

    async function suggestionReply(): Promise<void> {
        setIsSuggestReplying(true);

        try {
            await fetchReviewSuggestionApply(suggestion.id);
            await refreshReview(reviewId);
            await refreshSuggestions();
            snackbar.success({
                key: SENT_SUCCESSFULLY_KEY,
                message: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__TITLE" />,
                description: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__DESCRIPTION" />,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.ReplyToReview, ReplyTypeEnum.AutoReply);
        } catch (error: unknown) {
            const errorText = checkHasJsonData(error) ? (
                <span>{error.jsonData}</span>
            ) : (
                {
                    message: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_FAIL__TITLE" />,
                    description: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_FAIL__DESCRIPTION" />,
                }
            );

            snackbar.error(errorText);
        } finally {
            setIsSuggestReplying(false);
        }
    }

    async function handleDeleteSuggestedReplyClick() {
        modal.confirm({
            maskClosable: true,
            title: <Locale stringKey="REVIEWS__DELETE_AUTOANSWER__TITLE" />,
            content: <Locale stringKey="REVIEWS__DELETE_AUTOANSWER__DESCRIPTION" />,
            okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            cancelButtonProps: {type: 'default'},
            okButtonProps: {type: 'primary', danger: true},
            onOk: async () => {
                try {
                    await fetchReviewSuggestionDismiss(suggestion.id);
                    await refreshReview(reviewId);
                    await refreshSuggestions();

                    snackbar.success({
                        key: SENT_SUCCESSFULLY_KEY,
                        description: <Locale stringKey="REVIEWS__REVIEW_AUTOANSWER_REMOVE_SUCCESS__TITLE" />,
                        message: <Locale stringKey="REVIEWS__REVIEW_AUTOANSWER_REMOVE_SUCCESS__DESCRIPTION" />,
                    });
                } catch (error: unknown) {
                    handleReviewReplyExceptions(error, snackbar, {
                        description: <Locale stringKey="REVIEWS__REVIEW_AUTOANSWER_REMOVE_FAIL__TITLE" />,
                        message: <Locale stringKey="REVIEWS__REVIEW_AUTOANSWER_REMOVE_FAIL__DESCRIPTION" />,
                    });
                }
            },
        });
    }

    return (
        <div className={classNames(styles.answer_item, styles.answer_item__owner, className)}>
            <div className={styles.answer_item__short_data}>
                <FontAwesomeIcon icon={faFile} />
                <p className={styles.answer_item__short_data__response_time}>
                    <Locale stringKey="REVIEWS__OFFERING_AUTO_REPLY" />
                </p>
            </div>
            <div className={styles.answer_item__short_data__answer_text}>{constructComment(suggestion.comment)}</div>

            {isAnswerActive && (
                <>
                    <TextArea
                        className={styles.review__textarea}
                        onInput={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                            setAnswerText(event.currentTarget.value);
                        }}
                        placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_REPLY_TEXT')}
                        rows={3}
                        size="large"
                        value={answerText}
                    />

                    <div className={styles.review__button_wrapper}>
                        <Button
                            disabled={answerText.length === 0}
                            loading={isStandardReplying}
                            onClick={standardReply}
                            type="primary"
                        >
                            <Locale stringKey="REVIEWS__OFFERING_AUTO_REPLY_SEND" />
                        </Button>

                        <Button onClick={() => setIsAnswerActive(false)}>
                            <Locale stringKey="BUTTON__CANCEL" />
                        </Button>

                        <div className={styles.review__auto_reply_button_wrapper}>
                            <ReviewIconButton onClick={handleSelectTemplateClick}>
                                <FontAwesomeIcon icon={faFile} />
                            </ReviewIconButton>
                        </div>
                    </div>
                </>
            )}

            {!isAnswerActive && (
                <div className={styles.review__button_wrapper}>
                    <Button disabled={isSuggestReplying} onClick={suggestionReply}>
                        <Locale stringKey="REVIEWS__OFFERING_AUTO_REPLY_SEND" />
                    </Button>

                    <div className={styles.answer_item__short_data__button_wrapper}>
                        <button
                            className={styles.answer_item__short_data__button}
                            onClick={() => setIsAnswerActive(true)}
                            type="button"
                        >
                            <FontAwesomeIcon
                                className={styles.answer_item__short_data__button_icon}
                                icon={faPenToSquare}
                            />
                        </button>
                        <button
                            className={styles.answer_item__short_data__button}
                            onClick={handleDeleteSuggestedReplyClick}
                            type="button"
                        >
                            <FontAwesomeIcon className={styles.answer_item__short_data__button_icon} icon={faTrash} />
                        </button>
                    </div>
                </div>
            )}

            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        setIsSelectTemplatePopupOpen(false);

                        const [selectedAutoReply] = selectedResponseTemplateList;

                        if (!selectedAutoReply) {
                            return;
                        }

                        const {message} = selectedAutoReply;

                        setAnswerText(message);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </div>
    );
}
