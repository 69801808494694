import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip} from 'antd';
import {useState} from 'react';

import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {TimelineDataType} from '../../../../../service/timeline-activity/timeline-activity-type';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {TimelineCardYandexContent} from './timeline-card-yandex-content/timeline-card-yandex-content';
import * as styles from './timeline-card-yandex.scss';

type PropsType = {
    timelineData: TimelineDataType;
};

export function TimelineCardYandex(props: PropsType): JSX.Element {
    const {timelineData} = props;
    const numberOfActions = timelineData.subActions.length;
    const {getFormattedDateTime} = useFormat();
    const [showActions, setShowActions] = useState(false);
    const canShowActions = numberOfActions > 0;
    const iconClassName = classNames(styles.TimelineCardYandex_headerIcon, {[styles.reversed]: !showActions});

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    return (
        <>
            <div className={styles.TimelineCardYandex_header}>
                {getFormattedDateTime(new Date(timelineData.timestamp), {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: 'numeric',
                })}
                {canShowActions && (
                    <FontAwesomeIcon
                        className={iconClassName}
                        icon={faChevronDown}
                        onClick={() => setShowActions(!showActions)}
                    />
                )}
            </div>
            <div className={styles.TimelineCardYandex_comment}>
                {timelineData.catalog && (
                    <Tooltip
                        placement="bottom"
                        title={<Locale stringKey="DASHBOARD_PAGE__TIMELINE__YANDEX_IMAGE_TOOLTIP" />}
                    >
                        <img
                            alt={getCatalogName(timelineData.catalog.id)}
                            className={styles.TimelineCardYandex_commentLogo}
                            src={getCatalogLogo(timelineData.catalog.id)}
                        />
                    </Tooltip>
                )}
                <div className={styles.TimelineCardYandex_commentContent}>
                    <TimelineCardYandexContent showActions={showActions} timelineData={timelineData} />
                    {canShowActions && !showActions && (
                        <Button
                            className={styles.TimelineCardYandex_commentButton}
                            onClick={() => setShowActions(!showActions)}
                            type="link"
                        >
                            <LocalePlural
                                count={numberOfActions}
                                fewKey="DASHBOARD_PAGE__TIMELINE__ACTIONS__FEW"
                                manyKey="DASHBOARD_PAGE__TIMELINE__ACTIONS__MANY"
                                singularKey="DASHBOARD_PAGE__TIMELINE__ACTIONS__SINGULAR"
                                valueMap={{
                                    count: numberOfActions,
                                }}
                            />
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}
