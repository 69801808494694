import {faCheck, faCircleExclamation, faClock} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactElement} from 'react';

import {LangKeyType} from '../../../provider/locale/translation/type';
import {ConfirmationStatusEnum, ImportStatusEnum} from '../../../provider/onboarding/onboarding-hook';

export enum OnboardingCatalogsNameEnum {
    DoubleGis = '2GIS',
    Google = 'Google',
    Facebook = 'Facebook',
    Yandex = 'Yandex',
}
export enum OnboardingStepEnum {
    first = 1,
    second,
    third,
    fourth,
}

export const onboardingSuffix = '?onboarding=true';
export const onboardingStepStatus: Record<ImportStatusEnum | ConfirmationStatusEnum, LangKeyType> = {
    [ConfirmationStatusEnum.NeedConfirmation]: 'ONBOARDING__STEP_STATUS__NEED_ACTION',
    [ConfirmationStatusEnum.InProgress]: 'ONBOARDING__STEP_STATUS__PENDING',
    [ConfirmationStatusEnum.Confirmed]: 'ONBOARDING__STEP_STATUS__CONFIRMED',
    [ImportStatusEnum.InProgress]: 'ONBOARDING__STEP_STATUS__PENDING',
};

export const onboardingStepIcon: Record<ImportStatusEnum | ConfirmationStatusEnum, ReactElement> = {
    [ConfirmationStatusEnum.NeedConfirmation]: <FontAwesomeIcon icon={faCircleExclamation} />,
    [ConfirmationStatusEnum.InProgress]: <FontAwesomeIcon icon={faClock} />,
    [ConfirmationStatusEnum.Confirmed]: <FontAwesomeIcon icon={faCheck} />,
    [ImportStatusEnum.InProgress]: <FontAwesomeIcon icon={faClock} />,
};
