import {z as r} from 'zod';

import {filterSuggestionSchema, FilterSuggestionType, MainFilterFieldNameEnum} from './main-filter-type';

export enum MainFilterSelectedValuesIdsKeyEnum {
    GROUPS = 'group_ids',
    BRANDS = 'brand_ids',
    COUNTRIES = 'countries_ids',
    REGIONS = 'region_ids',
    CITIES = 'cities_ids',
    STREETS = 'streets',
    NAMES = 'names',
    CODES = 'codes',
}

export enum MainFilterSelectedValuesIdsAdditionalKeyEnum {
    CLOSED = 'is_company_closed',
    TEMP_CLOSED = 'is_company_temporary_closed',
    ACTIVE = 'is_company_active',
}

export enum SuggestionApiFieldNameEnum {
    GROUPS = 'group',
    BRANDS = 'brand',
    COUNTRIES = 'country',
    REGIONS = 'region',
    CITIES = 'city',
    STREETS = 'street',
    NAMES = 'name',
    CODES = 'code',
    CLOSED = 'closed',
    ACTIVE = 'active',
}

export type MainFilterSelectedValuesApiType = Record<
    MainFilterSelectedValuesIdsKeyEnum,
    Array<string> | boolean | null
> &
    MainFilterSelectedValuesAdditionalType;

export type MainFilterSelectedValuesAdditionalType = {
    [MainFilterSelectedValuesIdsAdditionalKeyEnum.TEMP_CLOSED]?: Array<string> | boolean | null;
    [MainFilterSelectedValuesIdsAdditionalKeyEnum.CLOSED]?: Array<string> | boolean | null;
    [MainFilterSelectedValuesIdsAdditionalKeyEnum.ACTIVE]?: Array<string> | boolean | null;
};

export type LoadSuggestionsBodyType = {
    field_name: SuggestionApiFieldNameEnum;
    after: string | null;
    limit: number;
    query: string;
    current_filter: MainFilterSelectedValuesApiType;
};

export type MainFilterCompileResultApiType = {
    key: string;
};

export type MainFilterFilterValuesApiType = {
    current_filter: Record<MainFilterFieldNameEnum, Array<FilterSuggestionType> | null> & {
        is_company_closed: boolean | null;
        is_company_temporary_closed: boolean | null;
        is_company_active?: boolean;
    };
    companies_count: number;
};

export type MainFilterGetFilteredCountResultApiType = {
    count: number;
};

export const lastStateSchema = r.object({
    key: r.string(),
    filtersAvailable: r.boolean(),
    companiesCount: r.number(),
    currentFilter: r.object({
        groups: r.array(filterSuggestionSchema).optional(),
        brands: r.array(filterSuggestionSchema).optional(),
        countries: r.array(filterSuggestionSchema).optional(),
        regions: r.array(filterSuggestionSchema).optional(),
        cities: r.array(filterSuggestionSchema).optional(),
        streets: r.array(filterSuggestionSchema).optional(),
        names: r.array(filterSuggestionSchema).optional(),
        codes: r.array(filterSuggestionSchema).optional(),
        isCompanyClosed: r.boolean().optional(),
        isCompanyTemporaryClosed: r.boolean().optional(),
        isCompanyActive: r.boolean().nullable(),
    }),
});

export type LastFilterStateType = r.infer<typeof lastStateSchema>;
