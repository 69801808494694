import {deserializeV2, serialize} from '../../../util/api-adapter';
import {ErrorWithStatusType} from '../../../util/error';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';

import {reviewUserTopicSchema, ReviewUserTopicType} from './review-user-topics-type';

export async function createReviewUserTopic(
    createPayload: Omit<ReviewUserTopicType, 'id'>
): Promise<ReviewUserTopicType> {
    const url = `${rootApiUrl}/v2/reviews-analytics/stats/user-topics/`;
    const body = JSON.stringify(serialize<Omit<ReviewUserTopicType, 'id'>>(createPayload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });

    return deserializeV2<ReviewUserTopicType>(url, reviewUserTopicSchema, response);
}

export async function getReviewUserTopic(id: number): Promise<ReviewUserTopicType> {
    const url = `${rootApiUrl}/v2/reviews-analytics/stats/user-topics/${id}/`;

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        method: FetchMethodEnum.get,
    });

    return deserializeV2<ReviewUserTopicType>(url, reviewUserTopicSchema, response);
}

export async function updateReviewUserTopic(
    id: number,
    updatePayload: Omit<ReviewUserTopicType, 'id'>
): Promise<ReviewUserTopicType> {
    const url = `${rootApiUrl}/v2/reviews-analytics/stats/user-topics/${id}/`;
    const body = JSON.stringify(serialize<Omit<ReviewUserTopicType, 'id'>>(updatePayload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.put,
    });

    return deserializeV2<ReviewUserTopicType>(url, reviewUserTopicSchema, response);
}

export function deleteReviewUserTopic(id: number): Promise<void | ErrorWithStatusType> {
    const url = `${rootApiUrl}/v2/reviews-analytics/stats/user-topics/${id}/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.delete,
    });
}
