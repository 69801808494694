import {Locale} from '../../../../../provider/locale/locale';
import {TimelineDataType} from '../../../../../service/timeline-activity/timeline-activity-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {timelineActionLocalizationMap} from './timeline-card-const';
import * as styles from './timeline-card.scss';

type PropsType = {
    timelineData: TimelineDataType;
};

export function TimelineCard(props: PropsType): JSX.Element {
    const {getFormattedDateTime} = useFormat();
    const {timelineData} = props;

    return (
        <>
            <div className={styles.TimelineCard_header}>
                {getFormattedDateTime(new Date(timelineData.timestamp), {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: 'numeric',
                })}
            </div>
            <div className={styles.TimelineCard_comment}>
                <div className={styles.TimelineCard_text}>
                    <Locale
                        stringKey={timelineActionLocalizationMap[timelineData.action]}
                        valueMap={{catalogName: timelineData.catalog?.name}}
                    />
                </div>
            </div>
        </>
    );
}
