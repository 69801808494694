export const enum DashboardWidgetEnum {
    PhotoTool = 'PhotoTool',
    ReviewsSummary = 'ReviewsSummary',
    AutoReplies = 'AutoReplies',
    PricesSingle = 'PricesSingle',
    PricesMultiple = 'PricesMultiple',
    Posts = 'Posts',
    SynchronizationSingle = 'SynchronizationSingle',
    SynchronizationMultiple = 'SynchronizationMultiple',
    Workload = 'Workload',
    Activity = 'Activity',
    SearchPhrases = 'SearchPhrases',
    LastReviews = 'LastReviews',
    Rating = 'Rating',
    OnlinePresence = 'OnlinePresence',
    RatingDistribution = 'RatingDistribution',
}
