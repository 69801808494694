import {faFilter} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableColumnsType} from 'antd';
import {useContext, useMemo} from 'react';

import {useCatalogInfo, useFilteredCatalogs} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/locale';
import {MainFilterContext} from '../../../../provider/main-filter/main-filter';
import {CclTableDataType} from '../../../../service/ccl/ccl-type';
import {CatalogNameType} from '../../../../service/ccl-statistic/ccl-statistic-type';
import {ProvidersIdsEnum} from '../../../../util/type';
import {RatingColumn} from '../../my-companies/companies-table/columns/rating-column';

import {LinkColumn} from './columns/link/link-column';
import {NameAndAddressColumn} from './columns/name-and-address/name-and-address-column';
import {SourceColumn} from './columns/source/source-column';
import {SourceFilterDropdown} from './columns/source/source-filter-dropdown';
import {StatusColumn} from './columns/status/status-column';
import {StoriesColumn} from './columns/stories-column/stories-column';

export function useSourcesTableColumns(
    catalogType: CatalogNameType,
    sourceIds: Array<string>,
    syncedItem?: {id?: number; catalogId?: number},
    isShowOnboardingPopover?: boolean
): TableColumnsType<CclTableDataType> {
    const {mainFilterKey} = useContext(MainFilterContext);
    const catalogs = useFilteredCatalogs({catalogType, isRelated: true}, mainFilterKey);

    const {getCatalogName} = useCatalogInfo();

    return useMemo(
        () => [
            {
                title: <Locale stringKey="SOURCES__TABLE__SOURCE" />,
                key: 'source',
                width: 226,
                filterIcon: <FontAwesomeIcon icon={faFilter} />,
                filters: (catalogs.result || []).map((catalog) => ({
                    text: getCatalogName(catalog.id),
                    value: String(catalog.id),
                })),
                filteredValue: sourceIds,
                filterDropdown: SourceFilterDropdown,
                render: (_value, ccl) => {
                    return ccl.catalog.id !== ProvidersIdsEnum.yandex ? (
                        <SourceColumn catalogId={ccl.catalog.id} />
                    ) : (
                        <StoriesColumn record={ccl} />
                    );
                },
            },
            {
                title: <Locale stringKey="SOURCES__TABLE__NAME_AND_ADDRESS" />,
                key: 'nameAndAddress',
                render: (_value, ccl) => <NameAndAddressColumn ccl={ccl} />,
            },
            {
                title: <Locale stringKey="SOURCES__TABLE__RATING" />,
                key: 'rating',
                align: 'center',
                render: (_value, ccl) => <RatingColumn companyId={ccl.company.id} rating={ccl.rating} />,
            },
            {
                title: <Locale stringKey="SOURCES__TABLE__STATUS" />,
                key: 'status',
                render: (_value, ccl) => {
                    return (
                        <StatusColumn
                            cclData={ccl}
                            showInfoPopover={
                                syncedItem?.id === ccl.id &&
                                syncedItem.catalogId === ccl.catalog.id &&
                                isShowOnboardingPopover
                            }
                        />
                    );
                },
            },
            {
                title: <Locale stringKey="SOURCES__TABLE__LINK" />,
                key: 'link',
                align: 'center',
                render: (_value, ccl) => <LinkColumn link={ccl.originUrl} />,
            },
        ],
        [catalogs.result, sourceIds, syncedItem?.id, syncedItem?.catalogId, isShowOnboardingPopover]
    );
}
