import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

import {userManagementUrl} from './user-management-const';

const userRoleSchema = r.object({
    pk: r.number(),
    name: r.string(),
    userCount: r.number(),
    editable: r.boolean(),
    isOwner: r.boolean(),
});

const userRolesSchema = r.array(userRoleSchema);

type UserRolesType = r.infer<typeof userRolesSchema>;

function getUserRolesUrl() {
    return `${userManagementUrl}/roles/`;
}

function fetchUserRoles(): Promise<UserRolesType> {
    return fetchAndDeserialize(getUserRolesUrl(), userRolesSchema);
}

export function useRoles(): UseQueryResult<UserRolesType> {
    return useQuery([getUserRolesUrl()], () => fetchUserRoles());
}
