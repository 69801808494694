import {VIDEO_DOMAIN_CONFIG} from './video-popup-const';

export function getVideoId(link: string): string | null {
    try {
        const url = new URL(link);

        return Object.entries(VIDEO_DOMAIN_CONFIG).reduce<string | null>(
            (embedLink, [domain, {getVideoId: getConfigVideoId}]) => {
                // might have 'www' in the beginning, don't do a strict match
                if (url.hostname.endsWith(domain)) {
                    return getConfigVideoId(url);
                }

                return embedLink;
            },
            null
        );
    } catch {
        return null;
    }
}

export function getEmbedLink(link: string): string | null {
    try {
        const url = new URL(link);
        const id = getVideoId(link);

        if (!id) {
            return null;
        }

        return Object.entries(VIDEO_DOMAIN_CONFIG).reduce<string | null>(
            (embedLink, [domain, {getEmbedLink: getConfigEmbedLink}]) => {
                if (url.hostname.endsWith(domain)) {
                    return getConfigEmbedLink(id);
                }

                return embedLink;
            },
            null
        );
    } catch {
        return null;
    }
}
