import {Col, Row} from 'antd';

import {AlertFallback} from '../../component/alert-fallback/alert-fallback';
import {useCatalogInfo} from '../../provider/catalogs/catalogs-hook';
import {useCompanyRating} from '../../service/dashboard/dashboard-hook';
import {RatingInline} from '../rating-inline/rating-inline';
import {Spinner} from '../spinner/spinner';

import * as styles from './company-rating.scss';

type CompanyRatingPropsType = {
    companyId: number;
};

export function CompanyRating(props: CompanyRatingPropsType): JSX.Element {
    const {companyId} = props;
    const {result, isInProgress, processError} = useCompanyRating(companyId);

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    if (result.length === 1 && result[0]) {
        const element = result[0];

        return (
            <div className={styles.company_rating__many__wrapper}>
                <div className={styles.company_rating__single__block_wrapper}>
                    <div className={styles.company_rating__single__catalog_wrapper}>
                        <img
                            alt={getCatalogName(element.catalog.id)}
                            className={styles.company_rating__catalog_icon}
                            src={getCatalogLogo(element.catalog.id)}
                        />
                        <div className={styles.company_rating__single__catalog__name}>
                            {getCatalogName(element.catalog.id)}
                        </div>
                    </div>
                    <div>
                        <RatingInline ratingValue={element.rating} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Row className={styles.company_rating__many__wrapper}>
            {result.map((element) => {
                return (
                    <Col
                        className={styles.company_rating__many__block_wrapper}
                        key={element.catalog.id}
                        xl={24 / result.length}
                        xs={24}
                    >
                        <img
                            alt={getCatalogName(element.catalog.id)}
                            className={styles.company_rating__catalog_icon}
                            src={getCatalogLogo(element.catalog.id)}
                        />
                        <div className={styles.company_rating__many__block__rating_catalog_wrapper}>
                            <RatingInline ratingValue={element.rating} />
                            <div className={styles.company_rating__many__block__catalog_name}>
                                {getCatalogName(element.catalog.id)}
                            </div>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );
}
