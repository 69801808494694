import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {ProductGroupTypeEnum} from '../../../../../service/products/products-type';

export const productGroupTitleMap: Record<ProductGroupTypeEnum, LangKeyType> = {
    [ProductGroupTypeEnum.File]: 'PRODUCTS__CREATE_GROUP__TYPE_TITLE__FILE',
    [ProductGroupTypeEnum.Feed]: 'PRODUCTS__CREATE_GROUP__TYPE_TITLE__FEED',
    [ProductGroupTypeEnum.Manual]: 'PRODUCTS__CREATE_GROUP__TYPE_TITLE__MANUAL',
    [ProductGroupTypeEnum.Iiko]: 'PRODUCTS__TABLE__TYPE__TOOLTIP__IIKO',
};

export const productGroupDescriptionMap: Record<ProductGroupTypeEnum, LangKeyType> = {
    [ProductGroupTypeEnum.File]: 'PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__FILE',
    [ProductGroupTypeEnum.Feed]: 'PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__FEED',
    [ProductGroupTypeEnum.Manual]: 'PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__MANUAL',
    [ProductGroupTypeEnum.Iiko]: 'PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__IIKO',
};
