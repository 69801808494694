import {FormRuleType} from '../../../../../../service/form/form-rules-hook-type';
import {YandexStorySlideType} from '../../../../../../service/yandex-stories/yandex-stories-type';

export const YANDEX_STORY_MAX_BUTTON_TEXT_LENGTH = 15;
export const YANDEX_STORY_MAX_BUTTON_LINK_LENGTH = 300;

export function validateYandexStorySlideImage(): FormRuleType<YandexStorySlideType> {
    return {
        validator: (_rule: unknown, value: YandexStorySlideType): Promise<void> => {
            if (value.status === 'done') {
                return Promise.resolve();
            }

            return Promise.reject();
        },
    };
}
