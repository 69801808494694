import {Space} from 'antd';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/localization';
import {classNames} from '../../../../../util/css';

import {authenticatorLinks} from './two-factor-description-const';
import * as styles from './two-factor-description.scss';

type PropsType = {
    className?: string;
    inlineForm?: JSX.Element;
};

export function TwoFactorDescription(props: PropsType): JSX.Element {
    const {className, inlineForm} = props;

    return (
        <div className={classNames(styles.TwoFactorDescription, className)}>
            <Text block bolder stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__HOW_TO_SET_UP_GOOGLE_AUTHENTICATOR" />

            <ol>
                <li>
                    <Text block stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__1" />

                    <Space>
                        {Object.entries(authenticatorLinks).map(([store, link]) => (
                            <a href={link} key={store} rel="noreferrer" target="_blank">
                                {store}
                            </a>
                        ))}
                    </Space>
                </li>

                <li>
                    <Text block stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__2" />

                    <Text block secondary stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__2_DESCRIPTION" />
                </li>

                <li>
                    <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__3" />

                    {inlineForm}
                </li>
            </ol>
        </div>
    );
}
