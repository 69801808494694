import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {postAndDeserialize} from '../../util/api-adapter';
import {useUrl} from '../../util/url-hook/url-hook';

import {userUrl} from './user-const';

const resetPasswordSchema = r.object({
    status: r.literal('ok').or(r.literal('error')),
});

type ResetPasswordType = r.infer<typeof resetPasswordSchema>;

export enum ResetPasswordFormEnum {
    Email = 'email',
}

export type ResetPasswordFormType = {
    [ResetPasswordFormEnum.Email]: string;
};

type ResetPasswordOptionsType = ResetPasswordFormType & {
    language: ShortLocaleNameEnum;
};

export function resetPassword(
    options: ResetPasswordOptionsType,
    shortLocaleName: ShortLocaleNameEnum
): Promise<ResetPasswordType> {
    const url = `${userUrl}/reset_password/`;

    return postAndDeserialize(url, resetPasswordSchema, options, {unauthorized: true, shortLocaleName});
}

export function useResetPasswordMutation(): UseMutationResult<ResetPasswordType, unknown, ResetPasswordFormType> {
    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();
    const {shortLocaleName} = useLocale();

    function mutate(options: ResetPasswordFormType) {
        return resetPassword(
            {
                language: shortLocaleName,
                email: options.email.trim(),
            },
            shortLocaleName
        );
    }

    function onSuccess(data: ResetPasswordType) {
        if (data.status !== 'ok') {
            throw new Error('AuthorizationResult is not "ok"');
        }

        snackbar.success(<Locale stringKey="PAGE__FORGOT_PASSWORD__RESET_LINK_SENT" />);

        pushUrl(appRoute.login.path);
    }

    function onError() {
        snackbar.error(<Locale stringKey="PAGE__FORGOT_PASSWORD__RESET_LINK_SEND_ERROR" />);
    }

    return useMutation(mutate, {onSuccess, onError});
}
