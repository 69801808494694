import {Rule} from 'antd/lib/form';

import {LocaleContextType} from '../../../../../../../provider/locale/locale-context-type';
import {SocialApiRegularType} from '../../../../../../../service/company-v2/company-type';
import {
    CompanyFormSocialNetworkType,
    CompanyFormWebsiteType,
} from '../../../../../../../service/company-v2/types/company-links-type';

export function getRequiredLinkRule(getLocalizedString: LocaleContextType['getLocalizedString']): Rule {
    return {
        validator: (_rule: unknown, value: CompanyFormWebsiteType | CompanyFormSocialNetworkType): Promise<void> => {
            if (value?.value.length > 0) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED')));
        },
    };
}

export function checkSocialsNetworkRegex(data: SocialApiRegularType, checkString: string): boolean {
    return (data || [])
        .map((element) => new RegExp(element.regexp))
        .some((regexp) => {
            return regexp.test(checkString);
        });
}
