import {Checkbox} from 'antd';

import {GoogleCclStatusEnum, GoogleCompanyType} from '../../../../../../../service/google/google-type';
import {CompanyListItem} from '../../../common/company-list-item/company-list-item';

import * as styles from './company-list-radio-item.scss';

type PropsType = {
    company: GoogleCompanyType;
    disabled?: boolean;
    onDisabledClick?: () => void;
};

export function CompanyListRadioItem(props: PropsType): JSX.Element {
    const {company, disabled, onDisabledClick} = props;

    return (
        <div
            className={styles.RadioWrapper}
            onClick={() => disabled && onDisabledClick?.()}
            onKeyPress={() => disabled && onDisabledClick?.()}
            role="button"
            tabIndex={0}
        >
            <Checkbox className={styles.Radio} disabled={disabled} value={company.id}>
                <CompanyListItem company={company} showStatus={company.status === GoogleCclStatusEnum.Synchronize} />
            </Checkbox>
        </div>
    );
}
