import {MenuNavigationBadge} from '../../../../../component/menu-navigation-badge/menu-navigation-badge';
import {useFakesStatuses} from '../../../../../service/fakes/fakes-statuses';

export function FakesBadge(): JSX.Element {
    const {data} = useFakesStatuses();

    const showWhenIsTrial =
        data?.isTrial &&
        data?.allCompaniesCount !== data?.preprocessing &&
        data.allCompaniesCount !== data.redundantLocation;

    return (
        <MenuNavigationBadge
            count={data?.isTrial ? data.allCompaniesCount : data?.newAndNeedAction}
            labelLangKey="CATEGORY_NAME__DUBS_AND_FAKES"
            showCounter={showWhenIsTrial ? data.allCompaniesCount > 0 : data && data.newAndNeedAction > 0}
        />
    );
}
