import {TableColumnsType} from 'antd';

import {Locale} from '../../../../../provider/locale/locale';
import {
    getAvgResponseDelayColumn,
    getRateColumn,
    getRepliedColumn,
    getRepliedPercentage,
    getSentimentColumn,
    getTotalColumn,
    getTrendColumn,
} from '../../components/table/reviews-analysis-table-helper';
import {PhrasesTableDataType} from '../../reviews-analysis-type';

export const phrasesPageColumns: TableColumnsType<PhrasesTableDataType> = [
    {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__PHRASE" />,
        dataIndex: 'phrase',
        key: 'phrase',
    },
    getRateColumn<PhrasesTableDataType>(),
    getTotalColumn<PhrasesTableDataType>(),
    getRepliedColumn<PhrasesTableDataType>(),
    getRepliedPercentage<PhrasesTableDataType>(),
    getAvgResponseDelayColumn<PhrasesTableDataType>(),
    getSentimentColumn<PhrasesTableDataType>(),
    getTrendColumn<PhrasesTableDataType>(),
];
