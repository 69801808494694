import {Input} from 'antd';

import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType, CompanyType} from '../../../../../../service/company-v2/company-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../typings/antd';

import * as styles from './brand-widget.scss';

type PropsType = {
    initialBrandName?: CompanyType['brandName'];
};

export function BrandWidget(props: PropsType): JSX.Element {
    const {initialBrandName} = props;

    const {requiredFieldRule} = useFormRules();
    const {hiddenCompanyFields} = useDomainConfig();
    const {getLocalizedString} = useLocale();

    return (
        <div className={styles.brand_widget__wrapper}>
            {!hiddenCompanyFields.branchCode && (
                <Form.Item<CompanyFormType>
                    className={styles.brand_widget__input}
                    label={<Locale stringKey="COMPANY_FORM__BRAND__BRANCH_CODE__LABEL" />}
                    name={CompanyKeyEnum.Code}
                >
                    <Input
                        placeholder={getLocalizedString('COMPANY_FORM__BRAND__BRANCH_CODE__PLACEHOLDER')}
                        size="large"
                    />
                </Form.Item>
            )}

            <Form.Item<CompanyFormType>
                className={styles.brand_widget__input}
                label={<Locale stringKey="COMPANY_FORM__BRAND__BRAND_NAME__LABEL" />}
                name={CompanyKeyEnum.BrandName}
                rules={[requiredFieldRule]}
            >
                <Input
                    disabled={Boolean(initialBrandName)}
                    placeholder={getLocalizedString('COMPANY_FORM__BRAND__BRAND_NAME__PLACEHOLDER')}
                    size="large"
                />
            </Form.Item>
        </div>
    );
}
