import {faPaperclip} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UploadFile} from 'antd';
import {RcFile} from 'antd/lib/upload';

import * as styles from './file-upload-icon.scss';

type PropsType = {
    file: UploadFile<RcFile>;
};

export function FileUploadIcon(props: PropsType): JSX.Element {
    const {file} = props;

    return 'status' in file && file.status === 'uploading' ? (
        <div className={styles.FileUploadIcon} />
    ) : (
        <FontAwesomeIcon icon={faPaperclip} />
    );
}
