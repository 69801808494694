import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {isReviewResponsibleUserError} from '../../page/main/reviews/review-list-page-content/review-list/review/responsible-user/responsible-user-helper';
import {Locale} from '../../provider/locale/locale';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeV2, fetchAndDeserialize, serialize, serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {AnalyticsTarget, track} from '../analytics/analytics';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {ReviewDataType} from './reviews-type';

export const reviewResponsibleUserSchema = r.object({
    id: r.number(),
    email: r.string(),
    firstName: r.string().nullable(),
    lastName: r.string().nullable(),
    fullName: r.string().nullable(),
});

export type ResponsibleUserType = r.infer<typeof reviewResponsibleUserSchema>;

const reviewResponsibleUserListSchema = r.object({
    results: r.array(reviewResponsibleUserSchema),
});

type ResponsibleUserListType = r.infer<typeof reviewResponsibleUserListSchema>;

type ResponsibleUserOptionsType = {
    filterKey?: string;
    companyId?: number;
    brandId?: number;
};

type UpdateReviewResponsibleUserType = {
    responsible_user: number | null;
};

const newReviewResponsibleUserSchema = r.object({
    newResponsibleUserId: r.number().nullable(),
});

type NewReviewResponsibleUserType = {
    newResponsibleUserId: number | null;
};

export type ReviewResponsibleUserErrorType = {
    jsonData: {
        status: string;
    };
};

function getResponsibleUsersUrl(options?: ResponsibleUserOptionsType | null) {
    const parameters = objectToUrlParameters(serializeToURLParameters({...options}));

    return `/v4/reviews/responsible_users/?${parameters}`;
}

async function fetchResponsibleUsers(options?: ResponsibleUserOptionsType | null): Promise<ResponsibleUserListType> {
    return fetchAndDeserialize<ResponsibleUserListType>(
        getResponsibleUsersUrl(options),
        reviewResponsibleUserListSchema
    );
}

export function useResponsibleUsers(
    parameters: Partial<ReviewDataType & ResponsibleUserOptionsType> | null
): UseQueryResult<ResponsibleUserListType> {
    const options: Partial<ReviewDataType & ResponsibleUserOptionsType> | null = (() => {
        if (parameters === null) {
            return null;
        }

        if (parameters.filterKey) {
            return {filterId: parameters.filterKey};
        }

        if (parameters.company) {
            return {companyId: parameters.company.id};
        }

        if (parameters.brand) {
            return {brandId: parameters.brand.id};
        }

        return {};
    })();

    return useQuery([getResponsibleUsersUrl(options)], () => fetchResponsibleUsers(options), {
        refetchOnWindowFocus: false,
        enabled: options !== null,
    });
}

export async function updateResponsibleUser(
    id: number,
    payload: UpdateReviewResponsibleUserType
): Promise<NewReviewResponsibleUserType> {
    const url = `${rootApiUrl}/v4/reviews/${id}/responsible_user/`;
    const body = JSON.stringify(serialize<UpdateReviewResponsibleUserType>(payload));

    const response = await fetchX<NewReviewResponsibleUserType>(url, {
        body,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.patch,
    });

    return deserializeV2<NewReviewResponsibleUserType>(url, newReviewResponsibleUserSchema, response);
}

export function useResponsibleUserSelect(
    reviewId: number,
    onChange?: () => void
): UseMutationResult<void, unknown, string | null> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: async (newUser: string | null) => {
            await updateResponsibleUser(reviewId, {
                responsible_user: newUser ? Number(newUser) : null,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.AddResponsibleUser);

            if (onChange) {
                onChange();
            }
        },
        onError: (error: unknown) => {
            const description = isReviewResponsibleUserError(error) ? (
                <span>{error.jsonData.status}</span>
            ) : (
                <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />
            );

            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description,
            });
        },
    });
}
