import {Input} from 'antd';

import {AddButton} from '../../../../../../../layout/add-button/add-button';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {ErrorList} from '../../../error-list/error-list';

import {PhonesFormItemButtons} from './phones-form-item-buttons';
import * as styles from './phones-form-item.scss';

type PropsType = {
    catalogId?: number;
    name: CompanyKeyEnum.Phones | [CompanyKeyEnum.ExtraPhones, number, 'phones'];
    required?: boolean;
    hasInitialPhones: boolean;
};

export function PhonesFormItem(props: PropsType): JSX.Element {
    const {catalogId, name, hasInitialPhones} = props;
    const {requiredFieldRule} = useFormRules();

    // eslint-disable-next-line no-undefined
    const initialValue = hasInitialPhones ? undefined : [{phone: '', isMain: true}];

    const {getLocalizedString} = useLocale();

    return (
        <Form.List initialValue={initialValue} name={Array.isArray(name) ? name.slice(1) : name}>
            {(phones, {add, remove}) => (
                <>
                    <div className={styles.company_phones}>
                        {phones.map(({key, name: fieldName, fieldKey}, index) => {
                            return (
                                <div className={styles.company_phone} key={key}>
                                    <Form.Item
                                        fieldKey={fieldKey}
                                        label={
                                            index === 0 ? (
                                                <AdditionalInfo
                                                    leftNode={
                                                        <Locale stringKey="COMPANY_FORM__CONTACT_INFO__PHONE__LABEL" />
                                                    }
                                                    title={
                                                        <Locale stringKey="COMPANY_FORM__CONTACT_INFO__PHONE__LABEL" />
                                                    }
                                                >
                                                    <Locale
                                                        stringKey={
                                                            catalogId === ProvidersIdsEnum.yandex
                                                                ? 'COMPANY_FORM__CONTACT_INFO__PHONE__ADDITIONAL_INFO__YANDEX'
                                                                : 'COMPANY_FORM__CONTACT_INFO__PHONE__ADDITIONAL_INFO'
                                                        }
                                                    />
                                                </AdditionalInfo>
                                            ) : null
                                        }
                                        name={[fieldName, 'phone']}
                                        rules={[requiredFieldRule]}
                                    >
                                        <Input
                                            placeholder={getLocalizedString(
                                                'COMPANY_FORM__CONTACT_INFO__PHONE__PLACEHOLDER'
                                            )}
                                            size={!index ? 'large' : 'middle'}
                                        />
                                    </Form.Item>

                                    <PhonesFormItemButtons
                                        catalogId={catalogId}
                                        index={index}
                                        onRemove={() => remove(fieldName)}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <ErrorList name={name} />

                    <AddButton
                        className={styles.company_phones__add}
                        onClick={() => add({phone: '', isMain: false})}
                        size="small"
                    >
                        <Locale stringKey="BUTTON__ADD" />
                    </AddButton>
                </>
            )}
        </Form.List>
    );
}
