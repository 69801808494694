export const enMainFilterTranslations = {
    MAIN_FILTER__ERROR: 'Filter updating error',

    MAIN_FILTER__GROUP__LABEL: 'Group',
    MAIN_FILTER__BRAND__LABEL: 'Brand',
    MAIN_FILTER__COUNTRY__LABEL: 'Country',
    MAIN_FILTER__REGION__LABEL: 'Region',
    MAIN_FILTER__CITY__LABEL: 'City',
    MAIN_FILTER__STREET__LABEL: 'Street, house number',
    MAIN_FILTER__COMPANY_NAME__LABEL: 'Company name',
    MAIN_FILTER__COMPANY_CODE__LABEL: 'Branch code',
    MAIN_FILTER__OPEN_CLOSE__LABEL: 'Open/Closed',
    MAIN_FILTER__ACTIVE__LABEL: 'Company activity',

    MAIN_FILTER__GROUP__PLACEHOLDER: 'Start typing a group name ...',
    MAIN_FILTER__BRAND__PLACEHOLDER: 'Start typing a brand name ...',
    MAIN_FILTER__COUNTRY__PLACEHOLDER: 'Start typing a country ...',
    MAIN_FILTER__REGION__PLACEHOLDER: 'Start typing a region ...',
    MAIN_FILTER__CITY__PLACEHOLDER: 'Start typing a city ...',
    MAIN_FILTER__STREET__PLACEHOLDER: 'Start typing an address ...',
    MAIN_FILTER__COMPANY_NAME__PLACEHOLDER: 'Start typing a company name ...',
    MAIN_FILTER__COMPANY_CODE__PLACEHOLDER: 'Start typing a branch code ...',
    MAIN_FILTER__OPEN_CLOSE__PLACEHOLDER: 'Choose an option ...',

    HEADER_FILTER__EMPTY: 'All companies',
    HEADER_FILTER__SELECTED_COMPANIES_COUNT__SINGLE: '{selectedCount} company',
    HEADER_FILTER__SELECTED_COMPANIES_COUNT__FEW: '{selectedCount} company',
    HEADER_FILTER__SELECTED_COMPANIES_COUNT__MANY: '{selectedCount} companies',

    MAIN_FILTER__SELECTED_COMPANIES_COUNT: 'Selected companies: {selectedCount}',

    MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_OPEN: 'Open',
    MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_CLOSE: 'Closed',
    MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_TEMPORARY_CLOSE: 'Temporary closed',
    MAIN_FILTER__ACTIVE_FILTER__ACTIVE_COMPANY: 'Active',
    MAIN_FILTER__ACTIVE_FILTER__INACTIVE_COMPANY: 'Inactive',
};
