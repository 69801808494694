import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType} from '../../catalog-list/catalog-list';
import {CatalogTab} from '../../catalog-list/catalog-tab';

import {EmailFormItem} from './email-form-item/email-form-item';
import {PhonesFormItem} from './phones-form-item/phones-form-item';

type PropsType = {
    name: string;
    catalog: CatalogConfigWithIndexType;
    hasInitialPhones: boolean;
    handleRemoveCatalog: (catalogId: number) => void;
};

export function CatalogContactInfo(props: PropsType): JSX.Element {
    const {name, catalog, hasInitialPhones, handleRemoveCatalog} = props;

    const form = Form.useFormInstance<CompanyFormType>();

    function handleRemove() {
        handleRemoveCatalog(catalog.catalogId);

        form.setFieldsValue({
            extraPhones: form
                .getFieldValue(CompanyKeyEnum.ExtraPhones)
                .filter(({catalogId}) => catalogId !== catalog.catalogId),
        });

        form.setFieldsValue({
            extraEmails: form
                .getFieldValue(CompanyKeyEnum.ExtraEmails)
                .filter(({catalogId}) => catalogId !== catalog.catalogId),
        });
    }

    return (
        <CatalogTab catalog={catalog} modalFieldName={name} onRemove={handleRemove}>
            <Form.List name={CompanyKeyEnum.ExtraPhones}>
                {() => (
                    <PhonesFormItem
                        catalogId={catalog.catalogId}
                        hasInitialPhones={hasInitialPhones}
                        key={catalog.catalogId}
                        name={[CompanyKeyEnum.ExtraPhones, catalog.index, 'phones']}
                    />
                )}
            </Form.List>

            <Form.List name={CompanyKeyEnum.ExtraEmails}>
                {() => <EmailFormItem name={[catalog.index, 'email']} />}
            </Form.List>
        </CatalogTab>
    );
}
