import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {faUserTieHair} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Form, Modal, theme} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {ReactElement, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useBuyFakesLicenseMutation} from '../../../../../service/fakes/fakes-trial-license';

import * as styles from './buy-license-modal.scss';

type PropsType = {
    buttonName: LangKeyType;
    isLinkType?: boolean;
};

export function BuyLicenseModal(props: PropsType): ReactElement {
    const {buttonName, isLinkType} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const {mutate} = useBuyFakesLicenseMutation();

    const [form] = Form.useForm();

    const {token} = theme.useToken();
    const {getLocalizedString} = useLocale();

    const {productsLink} = useDocumentationLinks();

    function onCancel() {
        setIsOpen(false);
    }

    function onOk() {
        track(
            AnalyticsTarget.DuplicatesAndFakes.Trial.SendRequest,
            form.getFieldValue('message') ? 'With comments' : 'Without comments'
        );

        mutate({message: form.getFieldValue('message')});
        setIsOpen(false);
    }

    function handleClick() {
        setIsOpen(true);
        track(AnalyticsTarget.DuplicatesAndFakes.Trial.ClickButton, 'Activate license');
    }

    return (
        <>
            <Button
                className={isLinkType ? styles.BuyLicenseModal_linkType : ''}
                onClick={handleClick}
                type={isLinkType ? 'link' : 'primary'}
            >
                <Locale stringKey={buttonName} />
            </Button>
            <Modal
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>,
                    <Button key="apply" onClick={onOk} type="primary">
                        <Locale stringKey="BUTTON__SEND_APPLICATION" />
                    </Button>,
                ]}
                onCancel={() => setIsOpen(false)}
                open={isOpen}
                title={<Locale stringKey="FAKES__TRIAL_LANDING__MODAL__TITLE" />}
                width={530}
            >
                <Form className={styles.BuyLicenseModal_formWrapper} form={form} layout="vertical">
                    <div className={styles.BuyLicenseModal_productInfo}>
                        <Text secondary small stringKey="FAKES__TRIAL_LANDING__MODAL__PRODUCT__LABEL" />
                        <Text bolder stringKey="FAKES__TRIAL_LANDING__MODAL__PRODUCT__TITLE" />
                    </div>
                    <Form.Item
                        label={<Locale stringKey="FAKES__TRIAL_LANDING__MODAL__COMMENT__LABEL" />}
                        name="message"
                    >
                        <TextArea
                            maxLength={300}
                            placeholder={getLocalizedString('FAKES__TRIAL_LANDING__MODAL__COMMENT__PLACEHOLDER')}
                            showCount
                            size="large"
                        />
                    </Form.Item>
                    <Alert
                        className={styles.BuyLicenseModal_alert}
                        description={<Locale stringKey="FAKES__TRIAL_LANDING__MODAL__ALERT__DESCRIPTION" />}
                        icon={<FontAwesomeIcon height={16} icon={faUserTieHair} width={16} />}
                        showIcon
                    />
                    {productsLink && (
                        <a className={styles.BuyLicenseModal_link} href={productsLink} rel="noreferrer" target="_blank">
                            <Text
                                bolder
                                color={token.colorPrimary}
                                inheritColor
                                stringKey="FAKES__TRIAL_LANDING__MODAL__LINK"
                            />
                            <FontAwesomeIcon height={16} icon={faArrowUpRightFromSquare} width={16} />
                        </a>
                    )}
                </Form>
            </Modal>
        </>
    );
}
