import {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';

import {AnalyticsTarget, track} from '../../service/analytics/analytics';
import {useRefreshId} from '../hook';

import {
    getPageSizeInitialState,
    getPaginationInitialState,
    saveNewPaginationState,
    savePageSizeState,
} from './pagination-helper';
import {PaginationHookOptionsType, PaginationHookType} from './pagination-hook-type';

export function usePagination(options: PaginationHookOptionsType): PaginationHookType {
    const {dependencies, shouldSaveState = true} = options;

    const routerHistory = useHistory<Location>();
    const {location: routerLocation} = routerHistory;
    const {search, pathname} = routerLocation;

    const storageKey = pathname + search;

    const {refresh, refreshId} = useRefreshId();
    const savedPaginationState = getPaginationInitialState({
        ...options,
        shouldSaveState,
        storageKey,
    });
    const savedPageSizeState = getPageSizeInitialState({
        ...options,
        shouldSaveState,
        storageKey: pathname,
    });

    const [page, setPage] = useState<number>(savedPaginationState.page);
    const [pageSize, setPageSize] = useState<number>(savedPageSizeState.pageSize);
    const [total, setTotal] = useState<number>(savedPageSizeState.pageSize);

    const isInitializedRef = useRef<boolean>(false);

    const onDataLoaded = useCallback((response: {count: number}) => {
        setTotal(response.count);
    }, []);

    const onDataLoadFailed = useCallback(() => {
        if (page > 1) {
            setPage(1);
        }
    }, [page]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page, pageSize]);

    useEffect(() => {
        if (isInitializedRef.current) {
            setPage(1);
            refresh();
        } else {
            isInitializedRef.current = true;
        }
    }, [dependencies, refresh]);

    useEffect(() => {
        if (shouldSaveState) {
            saveNewPaginationState(storageKey, {
                page,
            });
            savePageSizeState(pathname, {
                pageSize,
            });
        }

        if (isInitializedRef.current) {
            refresh();
        }
    }, [storageKey, pageSize, page, refresh, shouldSaveState]);

    const onChange = useCallback(
        (pageInner: number, pageSizeInner?: number | void): void => {
            const newPage = !pageSizeInner || pageSize === pageSizeInner ? pageInner : 1;

            setPage(newPage);

            if (pageSizeInner) {
                setPageSize(pageSizeInner);
            }

            if (page !== newPage) {
                track(AnalyticsTarget.PersonalCabinet.Pagination, newPage);
            }
        },
        [page, pageSize]
    );

    return {page, pageSize, onChange, total, onDataLoaded, onDataLoadFailed, refreshId};
}
