import {ApiError, HttpError} from '../../../util/error';

import {
    ManageSourceLinkError,
    ManageSourceLinkErrorPayloadType,
    NoPermitSourceLinkError,
    NoPermitSourceLinkErrorDataType,
    UnpaidCatalogGroupError,
} from './source-links-type';

export function handleSourceListError(error: unknown): never {
    // 403 status only is not very nice criteria for UnpaidError. However, this is all we have in API
    if (error instanceof HttpError && error.statusCode === 403) {
        throw new UnpaidCatalogGroupError(error.message, error.statusCode);
    }

    throw error;
}

function isManageSourceLinkErrorPayloadType(errorPayload: unknown): errorPayload is ManageSourceLinkErrorPayloadType {
    return (
        Boolean((errorPayload as ManageSourceLinkErrorPayloadType).status) ||
        Array.isArray((errorPayload as ManageSourceLinkErrorPayloadType)?.link) ||
        Array.isArray((errorPayload as ManageSourceLinkErrorPayloadType)?.brand_link) ||
        Array.isArray((errorPayload as ManageSourceLinkErrorPayloadType)?.company_link)
    );
}

function isNoPermitSourceLinkErrorPayloadType(errorPayload: unknown): errorPayload is NoPermitSourceLinkErrorDataType {
    return typeof (errorPayload as NoPermitSourceLinkErrorDataType)?.link === 'string';
}

export function handleManageSourceLinkError(error: unknown): never {
    if (error instanceof ApiError && error.statusCode === 403 && isNoPermitSourceLinkErrorPayloadType(error.jsonData)) {
        throw new NoPermitSourceLinkError(error.jsonData.link, error.statusCode, error.jsonData);
    }

    if (error instanceof ApiError && isManageSourceLinkErrorPayloadType(error.jsonData)) {
        throw new ManageSourceLinkError(error.message, error.statusCode, error.jsonData);
    }

    throw error;
}
