import {useMutation, UseMutationResult} from '@tanstack/react-query';

import {Locale} from '../../provider/locale/localization';
import {useMessage} from '../../provider/message/message-hook';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {companiesImportUrl} from './companies-import-const';

type ExportResultOptionsType = {
    importId: number | string;
    isValidationResult?: boolean;
};

function exportResult(importId: number | string, isValidationResult?: boolean): Promise<void> {
    if (isValidationResult) {
        return fetchNoContent(`${rootApiUrl}${companiesImportUrl}/validate_result/${importId}/send_to_emails/`, {
            method: FetchMethodEnum.post,
            headers: {...mainApiHeaders, ...getCsrfHeaders()},
        });
    }

    return fetchNoContent(`${rootApiUrl}${companiesImportUrl}/import_url/${importId}/export_result/`);
}

export function useExportResultMutation(): UseMutationResult<void, unknown, ExportResultOptionsType> {
    const {message} = useMessage();

    return useMutation((options) => exportResult(options.importId, options.isValidationResult), {
        onSuccess: () => message.success(<Locale stringKey="FEED__RESULTS__EXPORT__SUCCESS" />),
    });
}
