import {Button} from 'antd';
import {Dispatch, SetStateAction, useCallback, useMemo} from 'react';

import {Locale} from '../../../../../../../provider/locale/localization';
import {
    PostAiSuggestHookType,
    PostFormFieldsEnum,
    PostFormType,
    PostImageType,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../typings/antd';
import {classNames} from '../../../../../../../util/css';
import {PostContentWrapper} from '../../../post-content-wrapper/post-content-wrapper';
import * as postFormStyles from '../../../post-form.scss';
import {ContentValidationSettingsType} from '../content-step-type';
import {CommonImagesItem} from '../items/media/image/common-images-item';
import {CommonTextItem} from '../items/text/common-text-item';
import {PostRequirements} from '../post-requirements/post-requirements';

import {getCommonFieldsValidation} from './post-common-content-step-helper';
import * as styles from './post-common-content-step.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    suggestSettings: PostAiSuggestHookType;
    onStepFinished: () => void;

    commonTextState: [string, Dispatch<SetStateAction<string>>];
    commonImagesState: [Array<PostImageType>, Dispatch<SetStateAction<Array<PostImageType>>>];
};

export function PostCommonContentStep(props: PropsType): JSX.Element {
    const {formInstance, onStepFinished, suggestSettings, commonTextState, commonImagesState} = props;

    const [commonImages, setCommonImages] = commonImagesState;
    const [commonText, setCommonText] = commonTextState;

    const settings: ContentValidationSettingsType = useMemo(() => {
        const providers = formInstance
            .getFieldValue(PostFormFieldsEnum.Posts)
            .map((post) => post[SourcePostFormFieldsEnum.Catalog]);

        return getCommonFieldsValidation(providers);
    }, [formInstance]);

    const handleStepFinished = useCallback(async () => {
        await formInstance.validateFields();

        const posts = formInstance.getFieldValue(PostFormFieldsEnum.Posts);

        formInstance.setFieldValue(
            PostFormFieldsEnum.Posts,
            posts.map((post) => ({
                ...post,
                [SourcePostFormFieldsEnum.Text]: commonText,
                [SourcePostFormFieldsEnum.Images]: commonImages,
            }))
        );

        onStepFinished();
    }, [commonImages, commonText, formInstance, onStepFinished]);

    return (
        <div className={postFormStyles.PostForm_step}>
            <div className={classNames(postFormStyles.PostForm_stepContent)}>
                <PostContentWrapper className={styles.PostCommonContentStep}>
                    <PostContentWrapper.Left>
                        <CommonTextItem
                            maxLength={settings.maxTextLength}
                            onChange={setCommonText}
                            suggestSettings={suggestSettings}
                            value={commonText}
                        />

                        <CommonImagesItem
                            images={commonImages}
                            setImages={setCommonImages}
                            settings={settings.images}
                        />

                        <div className={styles.PostCommonContentStep_action}>
                            <Button onClick={handleStepFinished} type="primary">
                                <Locale stringKey="TEXT__NEXT" />
                            </Button>
                        </div>
                    </PostContentWrapper.Left>
                    <PostContentWrapper.Right>
                        <PostRequirements options={settings} />
                    </PostContentWrapper.Right>
                </PostContentWrapper>
            </div>
        </div>
    );
}
