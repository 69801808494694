import {ApiError} from '../../util/error';

import {QuestionReplyErrorType} from './questions-type';

export function handleReplyError(error: unknown): unknown {
    if (error instanceof ApiError && Boolean(error.message)) {
        const message = (JSON.parse(error.message) as QuestionReplyErrorType | undefined)?.non_field_errors[0] || '';

        throw new ApiError(message, error.statusCode, error.jsonData);
    }

    throw error;
}
