import {fetchAndDeserialize, postAndDeserialize, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {ProvidersIdsEnum} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {PaginatedResponseType} from '../api/api-type';

import {
    facebookBrandPagesSchema,
    facebookPagesSchema,
    matchingLocationsSchema,
    needActionCompaniesSchema,
} from './matching-const';
import {
    BrandPageType,
    CreateFacebookLocationOptionsType,
    LoadLocationMatchingDataOptionsType,
    MatchCompanyApiOptionsType,
    MatchingCompanyResponseType,
    MatchingFacebookLocationType,
    MatchingLocationsType,
    NeedActionCompaniesOptionsType,
} from './matching-types';

export async function loadFacebookPages(oauthAccountId: number): Promise<Array<MatchingFacebookLocationType>> {
    const url = `/v1/accounts/35/${oauthAccountId}/pages/`;

    return fetchAndDeserialize(url, facebookPagesSchema);
}

export async function loadFacebookBrandPages(brandId: number): Promise<Array<BrandPageType>> {
    const url = `/v1/accounts/35/brand_pages/?${objectToUrlParameters({
        brand_id: brandId,
    })}`;

    const response = await fetchAndDeserialize<PaginatedResponseType<BrandPageType>>(url, facebookBrandPagesSchema);

    return response.results;
}

export async function createFacebookLocation(options: CreateFacebookLocationOptionsType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/accounts/${ProvidersIdsEnum.facebook}/brand_pages/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({
            account: options.oauthAccountId,
            brand: options.brandId,
            location: {id: options.locationId},
            origin_id: options.originId,
            origin_url: options.originUrl,
        }),
    });
}

export async function loadNeedActionCompanies(
    options: NeedActionCompaniesOptionsType
): Promise<MatchingCompanyResponseType> {
    const parameters = objectToUrlParameters({
        count: options.pagination.pageSize,
        page: options.pagination.current,
        need_action: true,
        brand_ids: options.brandId,
    });

    return fetchAndDeserialize(
        `/v1/providers/${options.providerId}/companies/?${parameters}`,
        needActionCompaniesSchema
    );
}

export async function loadMatchingLocations(
    options: LoadLocationMatchingDataOptionsType
): Promise<MatchingLocationsType> {
    const {providerId, ...restOptions} = options;

    return postAndDeserialize(`/internal/provider/${providerId}/choices/`, matchingLocationsSchema, restOptions);
}

function massMatchCompanyApi({
    accountId,
    companies,
    providerId,
    brandPageId,
}: MatchCompanyApiOptionsType): Promise<void> {
    const body = {
        result: {
            account_id: accountId,
            choices: {
                ...Object.fromEntries(
                    companies.map((company) => [
                        company.id,
                        {
                            ...(company.cclData?.id
                                ? {
                                      sync: {
                                          active: true,
                                          company: {
                                              oauth_id: accountId,
                                              id: company.cclData?.id,
                                              brand_page_id: brandPageId,
                                          },
                                      },
                                  }
                                : {createNew: true}),
                            brand_page_id: brandPageId,
                        },
                    ])
                ),
            },
        },
    };

    return fetchNoContent(`${rootApiUrl}/company/modules/provider/${providerId}/companies/not_synced/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(body),
    });
}

export function matchCompanyApi({
    accountId,
    companies,
    providerId,
    brandPageId,
}: MatchCompanyApiOptionsType): Promise<void> {
    if (companies.length > 1) {
        return massMatchCompanyApi({accountId, companies, providerId, brandPageId});
    }

    return fetchNoContent(`${rootApiUrl}/company/modules/provider/${providerId}/link/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(
            serialize({
                accountId,
                companyId: companies[0]?.id,
                catalogId: providerId,
                ccl: companies[0]?.cclData,
                brandPageId,
            })
        ),
    });
}
