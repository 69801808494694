import {Form} from 'antd';

import {Text} from '../../../../../../../../../../component/text/text';
import {useFormRules} from '../../../../../../../../../../service/form/form-rules-hook';
import {AccountBrandSelect} from '../../account-brand-select/account-brand-select';
import {ManageAccountFormEnum} from '../../manage-account-form-type';

import * as styles from './brand-tab.scss';

type PropsType = {
    catalogGroupId: number;
};

export function BrandTab(props: PropsType): JSX.Element {
    const {catalogGroupId} = props;

    const {requiredFieldRule} = useFormRules();

    return (
        <div className={styles.BrandTab}>
            <Form.Item
                className={styles.BrandTab_formItem}
                name={ManageAccountFormEnum.brandsId}
                rules={[requiredFieldRule]}
            >
                <AccountBrandSelect catalogGroupId={catalogGroupId} />
            </Form.Item>
            <Text secondary stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__FOOTER" />
        </div>
    );
}
