import {Form, Tabs, TabsProps} from 'antd';

import {Locale} from '../../../../../../../../../provider/locale/locale';
import {UseCompaniesSelectorHookType} from '../../../../../../../../../service/feature-companies/feature-companies-type';
import {AccountBrandLabel} from '../account-brand-label/account-brand-label';
import {AccountSelectorCompanyType, ManageTypeAccountEnum} from '../manage-account-form-type';

import {AccountTab} from './account-tab/account-tab';
import {BrandTab} from './brand-tab/brand-tab';
import * as styles from './account-brand-tabs.scss';

type PropsType = {
    catalogGroupId: number;
    companiesSelector: UseCompaniesSelectorHookType<AccountSelectorCompanyType>;
    activeTab: ManageTypeAccountEnum;
    onChangeTabs: (activeKey: ManageTypeAccountEnum) => void;
    onChangeCompanyCheckbox: (checked: boolean) => void;
    onCompanyModalOk: () => void;
    checkboxValue: boolean;
    disabledTab: ManageTypeAccountEnum | null;
};

export function AccountBrandTabs(props: PropsType): JSX.Element {
    const {
        activeTab,
        onChangeTabs,
        catalogGroupId,
        companiesSelector,
        onCompanyModalOk,
        onChangeCompanyCheckbox,
        checkboxValue,
        disabledTab,
    } = props;

    function handleChangeTabs(activeKey: string) {
        onChangeTabs(activeKey as ManageTypeAccountEnum);
    }

    const itemsTabRaw: TabsProps['items'] = [
        {
            key: ManageTypeAccountEnum.brand,
            label: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__BRANDS" />,
            children: <BrandTab catalogGroupId={catalogGroupId} />,
            destroyInactiveTabPane: true,
        },
        {
            key: ManageTypeAccountEnum.company,
            label: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANIES" />,
            children: (
                <AccountTab
                    checkboxValue={checkboxValue}
                    companiesSelector={companiesSelector}
                    onChangeCheckbox={onChangeCompanyCheckbox}
                    onCompanyModalOk={onCompanyModalOk}
                />
            ),
            destroyInactiveTabPane: true,
        },
    ];

    const itemsTab = itemsTabRaw.map((item) => ({...item, disabled: item.key === disabledTab}));

    return (
        <Form.Item label={<AccountBrandLabel />} required>
            <Tabs
                activeKey={activeTab}
                centered
                className={styles.AccountBrandTabs}
                items={itemsTab}
                onChange={handleChangeTabs}
            />
        </Form.Item>
    );
}
