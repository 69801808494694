import {Locale} from '../../../../provider/locale/localization';
import {FakeClientStatusEnum, FakeTypeEnum} from '../../../../service/fakes/fakes-type';

export const FAKES_FILTERS_TYPE_OPTIONS = [
    {
        label: <Locale stringKey="FAKES__RESULTS__FILTERS__TYPE__ALL" />,
        value: 0,
    },
    {
        label: <Locale stringKey="FAKES__RESULTS__FILTERS__TYPE__FAKES" />,
        value: FakeTypeEnum.Fake,
    },
    {
        label: <Locale stringKey="FAKES__RESULTS__FILTERS__TYPE__DUPLICATES" />,
        value: FakeTypeEnum.Duplicate,
    },
];

export const FAKES_FILTERS_STATUSES_WITH_LONG_NAMES = [
    FakeClientStatusEnum.ClaimSent,
    FakeClientStatusEnum.ActionRequired,
    FakeClientStatusEnum.ClaimApproved,
    FakeClientStatusEnum.ClaimRejected,
];
