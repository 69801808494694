import {ReactNode} from 'react';
import {z as r} from 'zod';

import {DateAggregationEnum, DatePeriodEnum} from '../../util/date';
import {PaginationDataType, PaginationHookType, PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {ProvidersEnum} from '../../util/type';
import {generateResponseSchema} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';

export enum OnlinePresenceV2FilterQueriesEnum {
    PeriodStart = 'op_start_date',
    PeriodEnd = 'op_end_date',
    ComparePeriodStart = 'op_compared_start_date',
    ComparePeriodEnd = 'op_compared_end_date',
    Period = 'op_period',
    Aggregation = 'op_aggregate',
    CompareMode = 'op_compare_mode',
}

export type OnlinePresenceV2FilterQueriesType = Readonly<{
    [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: string;
    [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: string;
    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: string;
    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: string;
    [OnlinePresenceV2FilterQueriesEnum.Period]: DatePeriodEnum | '';
    [OnlinePresenceV2FilterQueriesEnum.Aggregation]: DateAggregationEnum | '';
    [OnlinePresenceV2FilterQueriesEnum.CompareMode]: OnlinePresenceCompareV2Enum | '';
}>;

export enum OnlinePresenceCompareV2Enum {
    PreviousPeriod = 'previous',
    PreviousYear = 'previous_year',
    Custom = 'custom',
}

export type OnlinePresenceV2FilterStateType = Readonly<{
    [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: Date;
    [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: Date;
    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: Date | null;
    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: Date | null;
    [OnlinePresenceV2FilterQueriesEnum.Period]: DatePeriodEnum;
    [OnlinePresenceV2FilterQueriesEnum.Aggregation]: DateAggregationEnum;
    [OnlinePresenceV2FilterQueriesEnum.CompareMode]: OnlinePresenceCompareV2Enum | null;
}>;

export type OnlinePresenceFilterDispatcherType = (action: OnlinePresenceFilterUpdateActionType) => void;

export type FormattedPeriodsType = {
    currentPeriod: string;
    comparedPeriod: string;
};

export type OnlinePresenceFilterV2HookType = {
    filter: OnlinePresenceV2FilterStateType;
    dispatchFilter: OnlinePresenceFilterDispatcherType;
    isCompareMode: boolean;
    formattedPeriods: FormattedPeriodsType;
};

const onlinePresencePhrasesItemSchema = r.object({
    searchPhrase: r.string(),
    value: r.number(),
    delta: r.number(),
    comparedValue: r.number().nullable(),
    valuesByCatalog: r.array(
        r.object({
            catalogId: r.number(),
            value: r.number(),
            comparedValue: r.number().nullable(),
            delta: r.number(),
        })
    ),
    dynamicValues: r.array(r.number()),
    dynamicValuesByCatalog: r.array(
        r.object({
            catalogId: r.number(),
            values: r.array(r.number()),
        })
    ),
});

export type OnlinePresencePhrasesItemType = r.infer<typeof onlinePresencePhrasesItemSchema>;

export const onlinePresencePhrasesResponseSchema = r.object({
    count: r.number(),
    page: r.number(),
    pageSize: r.number(),
    dynamicLabels: r.array(r.string()),
    results: r.array(onlinePresencePhrasesItemSchema),
});

export type OnlinePresencePhrasesHookDataType = r.infer<typeof onlinePresencePhrasesResponseSchema>;

export type OnlinePresencePhrasesHookType = Omit<UseHookType<OnlinePresencePhrasesHookDataType>, 'reset'> &
    PaginationType;

export enum ActionDynamicsEnum {
    CallClicks = 'call_clicks',
    WebsiteClicks = 'website_clicks',
    DirectRequests = 'direction_requests',
    Other = 'other',
}

export enum CardOccupancyEnum {
    AverageScore = 'averageScore',
    Categories = 'categories',
    PhoneNumbers = 'phoneNumbers',
    PaymentMethods = 'paymentMethods',
    Logo = 'logo',
    Cover = 'cover',
    Description = 'description',
    SocialLinks = 'socialLinks',
    Gallery = 'gallery',
    Prices = 'prices',
    Posts = 'posts',
}

const actionDynamicsDataSchema = r.object({
    label: r.nativeEnum(ActionDynamicsEnum),
    values: r.array(r.number()),
});

export type OnlinePresenceActionDynamicsDataType = r.infer<typeof actionDynamicsDataSchema>;

const baseMetricsElementSchema = r.object({
    current: r.number(),
    compared: r.number(),
    delta: r.number(),
});

export type OnlinePresenceMetricType = r.infer<typeof baseMetricsElementSchema>;

export const onlinePresenceDashboardCommonSchema = r.object({
    labels: r.array(r.string()),
    comparedLabels: r.array(r.string()),
    impressionsDynamic: r.object({
        current: r.array(r.number()),
        compared: r.array(r.number()),
    }),
    base: r.object({
        impressions: baseMetricsElementSchema,
        interactions: baseMetricsElementSchema,
        conversion: baseMetricsElementSchema,
    }),
    actionsDynamic: r.object({
        bar: r.object({
            current: r.array(actionDynamicsDataSchema),
            compared: r.array(actionDynamicsDataSchema),
        }),
        table: r.array(
            r.object({
                label: r.nativeEnum(ActionDynamicsEnum),
                compared: r.number(),
                current: r.number(),
                delta: r.number(),
            })
        ),
    }),
});

export const onlinePresenceTrialDashboardSchema = onlinePresenceDashboardCommonSchema.merge(
    r.object({
        isValid: r.boolean(),
    })
);

export type OnlinePresenceDashboardDataCommonType = r.infer<typeof onlinePresenceDashboardCommonSchema>;
export type OnlinePresenceTrialDashboardType = r.infer<typeof onlinePresenceTrialDashboardSchema>;

export type OnlinePresenceDashboardDataHookType<DataType extends Partial<OnlinePresenceDashboardDataCommonType>> = Omit<
    UseHookType<DataType>,
    'reset'
>;

export enum SourceOfTransitionEnum {
    DesktopSearch = 'desktop_search',
    DesktopMap = 'desktop_map',
    MobileSearch = 'mobile_search',
    MobileMap = 'mobile_map',
    Discovery = 'discovery',
    Recovery = 'recovery',
    Other = 'other',
}

const onlinePresenceSourcesSchema = r.array(
    baseMetricsElementSchema.merge(
        r.object({
            label: r.nativeEnum(SourceOfTransitionEnum),
        })
    )
);

export type OnlinePresenceSourcesType = r.infer<typeof onlinePresenceSourcesSchema>;

export const onlinePresenceDashboardGoogleSchema = onlinePresenceDashboardCommonSchema.and(
    r.object({
        sources: onlinePresenceSourcesSchema,
    })
);

export type OnlinePresenceGoogleDataType = r.infer<typeof onlinePresenceDashboardGoogleSchema>;

export const onlinePresenceWGisSchema = onlinePresenceDashboardGoogleSchema;

export type OnlinePresenceWgisDataType = r.infer<typeof onlinePresenceWGisSchema>;

export const onlinePresenceSynchronizationSchema = r.object({
    syncStatus: r.object({
        averageSynchronization: r.number(),
        catalogs: r.array(
            r.object({
                id: r.number(),
                name: r.string(),
                value: r.number(),
            })
        ),
        navigators: r.array(
            r.object({
                id: r.number(),
                name: r.string(),
                logo: r.string(),
            })
        ),
    }),
    dataCompleteness: r.object({
        [CardOccupancyEnum.AverageScore]: r.number(),
        [CardOccupancyEnum.Categories]: r.number(),
        [CardOccupancyEnum.PhoneNumbers]: r.number(),
        [CardOccupancyEnum.PaymentMethods]: r.number(),
        [CardOccupancyEnum.Logo]: r.number(),
        [CardOccupancyEnum.Cover]: r.number(),
        [CardOccupancyEnum.Description]: r.number(),
        [CardOccupancyEnum.SocialLinks]: r.number(),
        [CardOccupancyEnum.Gallery]: r.number(),
        [CardOccupancyEnum.Prices]: r.number(),
        [CardOccupancyEnum.Posts]: r.number(),
    }),
});

export type OnlinePresenceSynchronizationType = r.infer<typeof onlinePresenceSynchronizationSchema>;

export enum OnlinePresenceFilterActionsEnum {
    UpdateMainPeriod,
    UpdateComparePeriod,
    UpdatePeriod,
    UpdateAggregation,
    UpdateCompareMode,
    Init,
}

export type OnlinePresenceFilterUpdateActionType =
    | {
          type: OnlinePresenceFilterActionsEnum.UpdateAggregation;
          payload: OnlinePresenceV2FilterStateType[OnlinePresenceV2FilterQueriesEnum.Aggregation];
      }
    | {
          type: OnlinePresenceFilterActionsEnum.UpdateCompareMode;
          payload: OnlinePresenceV2FilterStateType[OnlinePresenceV2FilterQueriesEnum.CompareMode];
      }
    | {
          type: OnlinePresenceFilterActionsEnum.UpdateMainPeriod;
          payload: Pick<
              OnlinePresenceV2FilterStateType,
              OnlinePresenceV2FilterQueriesEnum.PeriodStart | OnlinePresenceV2FilterQueriesEnum.PeriodEnd
          >;
      }
    | {
          type: OnlinePresenceFilterActionsEnum.UpdateComparePeriod;
          payload: Pick<
              OnlinePresenceV2FilterStateType,
              OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart | OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd
          >;
      }
    | {
          type: OnlinePresenceFilterActionsEnum.UpdatePeriod;
          payload: OnlinePresenceV2FilterStateType[OnlinePresenceV2FilterQueriesEnum.Period];
      }
    | {
          type: OnlinePresenceFilterActionsEnum.Init;
      };

const locationCatalogBaseSchema = r.object({
    catalog: r.nativeEnum(ProvidersEnum),
    conversion: r.number(),
    impressions: r.number(),
    callClicks: r.number(),
    websiteClicks: r.number(),
    directionRequests: r.number(),
    other: r.number(),
});

const locationsBaseAnalyticsSchema = r.object({
    companyId: r.number(),
    companyName: r.string(),
    companyAddress: r.string(),
    catalogs: r.array(locationCatalogBaseSchema),
});

export const locationsAnalyticsSchema = generateResponseSchema(locationsBaseAnalyticsSchema).merge(
    r.object({
        availableCatalogs: r.array(r.nativeEnum(ProvidersEnum)),
    })
);

type LocationsAnalyticsType = r.infer<typeof locationsBaseAnalyticsSchema>;

type LocationCatalogsItemType = r.infer<typeof locationCatalogBaseSchema>;

export type LocationsAnalyticsResponseType = r.infer<typeof locationsAnalyticsSchema>;
export type LocationTransformedCatalogType = Record<Partial<ProvidersEnum>, LocationCatalogsItemType>;
export type LocationsAnalyticsTableDataType = Omit<LocationsAnalyticsType, 'catalogs'> & LocationTransformedCatalogType;

export type LocationsParametersType = {
    filter: OnlinePresenceV2FilterStateType;
    mainFilterKey: string;
    pagination: PaginationDataType;
};

export type UseOnlinePresenceLocationsType = {
    data?: Array<LocationsAnalyticsTableDataType>;
    isLoading: boolean;
    pagination: PaginationHookType;
    availableCatalogs: Set<ProvidersEnum>;
};

export type ExportExcelOptionsType = {
    google: boolean;
    yandex: boolean;
    double_gis: boolean;
    facebook: boolean;
    listing: boolean;
    search_phrases: boolean;
    sync_statistics: boolean;
};

export type ExportExcelType = {
    start_date: string;
    end_date: string;
    filter_key: string;
    options: ExportExcelOptionsType;
};

export enum OptionsEnum {
    google = 'google',
    yandex = 'yandex',
    doubleGis = 'double_gis',
    facebook = 'facebook',
    listing = 'listing',
    searchPhrases = 'search_phrases',
    syncStatistics = 'sync_statistics',
}

export type CatalogConfigItemType = {
    catalogId?: number;
    label: ReactNode;
    optionKey: OptionsEnum;
};
