import {z as r} from 'zod';

import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';

export enum FeaturesEnum {
    photoTool = 'phototool',
    reviewAnalyticsBase = 'review_analytics_base',
    reviewAnalyticsFull = 'review_analytics_full',
    reviewAnalyticsCompetitor = 'review_analytics_competitor',
    duplicatesAndFakes = 'duplicates_and_fakes', // hide from navigation menu and routing if feature enabled
    posts = 'posts',
    reviewsGenerator = 'reviews_generator',
    reviewSuggests = 'review_suggests',
    storeLocator = 'store_locator',
    onlinePresenceAnalytics = 'online_presence_analytics',
    dashboard = 'dashboard',
    localPack = 'local_pack',
    mlReplies = 'ml_replies',
    companyGroups = 'company_groups',
    usedeskIntegration = 'usedesk_integration',
    prices = 'prices',
    analytics = 'analytics',
    userManagement = 'user_management',
    omnichannel = 'omnichannel',
    publicApi = 'public_api',
    reviewGptReplySuggestions = 'review_gpt_reply_suggestions',
    mailingListLicence = 'mailing_list_licence',
    tariffs = 'tariffs',
    companiesExport = 'companies_export',
    knowledge = 'knowledge',
    neuralNetwork = 'neural_network',
    reviews = 'reviews',
    settings = 'settings',
    questionsAndAnswers = 'questions_and_answers',
    sources = 'sources',
    myCompanies = 'my_companies',
    integrations = 'integrations',
}

const userProfileSchema = r.object({
    firstName: r.string(),
});

export const lastRoutePathSchema = r.string();

export const userSchema = r.object({
    id: r.number(),
    email: r.string(),
    language: r.nativeEnum(ShortLocaleNameEnum),
    isStaff: r.boolean(),
    isRocketdata: r.boolean(),
    isRocketdataUser: r.boolean(),
    isDoubleGisUser: r.boolean(),
    isDoubleGisTrusted: r.boolean(),
    isTestUser: r.boolean(),
    isDemoUser: r.boolean(),
    isSuperuser: r.boolean(),
    is2faEnabled: r.boolean(),
    features: r.array(r.nativeEnum(FeaturesEnum)),
    profile: userProfileSchema,
    regionAvailableCatalogs: r.record(r.boolean()),
    canCreateGptReplySuggestionsLicence: r.boolean().optional(),
    yandexNeedActualizationExists: r.boolean(),
    mailingListsLicenceSettings: r.object({
        canCreateMailingListsLicence: r.boolean(),
        mailingListLicencesConnected: r.boolean(),
    }),
    userHasOnlyAccess: r.array(r.nativeEnum(FeaturesEnum)).nullable(),
});

export type UserType = r.infer<typeof userSchema>;
