import {useMemo, useState} from 'react';

import {gray1} from '../../../../css/var-export.scss';
import {LegendBox} from '../../../../layout/chart/legend-box/legend-box';
import {LegendWrapper} from '../../../../layout/chart/legend-wrapper/legend-wrapper';
import {LineChart} from '../../../../layout/chart/line-chart/line-chart';
import {LineDataSetType, LineDataType} from '../../../../layout/chart/line-chart/line-chart-type';
import {Empty} from '../../../../layout/empty/empty';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {noop} from '../../../../util/function';

import {ImpressionDynamicsDatasetEnum} from './impression-dynamics-type';
import {getImpressionsDynamicsChartOptions} from './impressions-dynamics-helper';
import * as styles from './impressions-dynamic.scss';

type PropsType = {
    labels: Array<string>;
    mainPeriodData: Array<number>;
    comparePeriodData: Array<number>;
    isCompareMode: boolean;
    comparedLabels: Array<string>;
    bordered?: boolean;
    staticChart?: boolean;
};

export function ImpressionsDynamic(props: PropsType): JSX.Element | null {
    const {labels, mainPeriodData, comparePeriodData, isCompareMode, comparedLabels, bordered, staticChart} = props;
    const isEmpty =
        (mainPeriodData.length === 0 && comparePeriodData.length === 0) ||
        (mainPeriodData.filter(Boolean).length === 0 && comparePeriodData.filter(Boolean).length === 0);

    const {getLocalizedString} = useLocale();

    const [legendFilter, setLegendFilter] = useState<Array<ImpressionDynamicsDatasetEnum>>(
        Object.values(ImpressionDynamicsDatasetEnum)
    );

    function onItemChange(value: ImpressionDynamicsDatasetEnum): void {
        if (legendFilter.includes(value)) {
            setLegendFilter(
                legendFilter.filter((legendItem: ImpressionDynamicsDatasetEnum): boolean => legendItem !== value)
            );
        } else {
            setLegendFilter([...legendFilter, value]);
        }
    }

    const mainPeriodDataset: LineDataSetType = useMemo(
        () => ({
            label: getLocalizedString('ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS'),
            values: mainPeriodData || [],
            color: styles.impression_dynamics_chart_color || '',
        }),
        [getLocalizedString, mainPeriodData]
    );

    const compareDataset: LineDataSetType = useMemo(
        () => ({
            label: getLocalizedString('ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS__COMPARE_B'),
            values: comparePeriodData || [],
            color: styles.impression_dynamics_chart_color_compare || '',
        }),
        [comparePeriodData, getLocalizedString]
    );

    const hasComparePeriod = comparePeriodData && legendFilter.includes(ImpressionDynamicsDatasetEnum.ComparePeriod);

    const data: LineDataType = useMemo(
        () => ({
            labels,
            datasets: [
                ...(legendFilter.includes(ImpressionDynamicsDatasetEnum.MainPeriod)
                    ? [
                          {
                              ...mainPeriodDataset,
                              pointBorderColor: styles.impression_dynamics_chart_color,
                              pointBackgroundColor: gray1,
                              tension: 0,
                          },
                      ]
                    : []),
                ...(hasComparePeriod
                    ? [
                          {
                              ...compareDataset,
                              dashed: true,
                              pointBorderColor: styles.impression_dynamics_chart_color_compare,
                              pointBackgroundColor: gray1,
                              tension: 0,
                          },
                      ]
                    : []),
            ],
        }),
        [compareDataset, hasComparePeriod, labels, legendFilter, mainPeriodDataset]
    );

    return (
        <PageCard
            bordered={bordered}
            title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__TITLE" />}
        >
            {!isEmpty ? (
                <>
                    <LegendWrapper>
                        <LegendBox
                            checked={legendFilter.includes(ImpressionDynamicsDatasetEnum.MainPeriod)}
                            color={styles.impression_dynamics_chart_color}
                            key="impressions"
                            label={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS" />}
                            onChange={staticChart ? noop : () => onItemChange(ImpressionDynamicsDatasetEnum.MainPeriod)}
                        />
                        {isCompareMode && (
                            <LegendBox
                                checked={legendFilter.includes(ImpressionDynamicsDatasetEnum.ComparePeriod)}
                                color={styles.impression_dynamics_chart_color_compare}
                                key="compare"
                                label={
                                    <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS__COMPARE_B" />
                                }
                                onChange={
                                    staticChart ? noop : () => onItemChange(ImpressionDynamicsDatasetEnum.ComparePeriod)
                                }
                                stripes
                            />
                        )}
                    </LegendWrapper>
                    <div className={styles.ImpressionDynamics_chart}>
                        <LineChart
                            data={data}
                            options={getImpressionsDynamicsChartOptions(
                                {
                                    data: {
                                        labels,
                                        datasets: [mainPeriodDataset],
                                    },
                                    ...(isCompareMode
                                        ? {
                                              compareData: {
                                                  datasets: [compareDataset],
                                                  labels: comparedLabels,
                                              },
                                          }
                                        : {}),
                                    showCompareValuesDynamics: isCompareMode,
                                },
                                !staticChart
                            )}
                        />
                    </div>
                </>
            ) : (
                <Empty secondaryText="ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__EMPTY" />
            )}
        </PageCard>
    );
}
