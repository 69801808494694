import {ShortLocaleNameEnum} from '../../../../provider/locale/locale-context-type';

import onboardingStep1BwImage from './images/photo-tool-landing-onboarding-step1-bw.png';
import onboardingStep1EnImage from './images/photo-tool-landing-onboarding-step1-en.png';
import onboardingStep1RuImage from './images/photo-tool-landing-onboarding-step1-ru.png';
import onboardingStep2BwImage from './images/photo-tool-landing-onboarding-step2-bw.png';
import onboardingStep2EnImage from './images/photo-tool-landing-onboarding-step2-en.png';
import onboardingStep2RuImage from './images/photo-tool-landing-onboarding-step2-ru.png';
import onboardingStep3BwImage from './images/photo-tool-landing-onboarding-step3-bw.png';
import onboardingStep3EnImage from './images/photo-tool-landing-onboarding-step3-en.png';
import onboardingStep3RuImage from './images/photo-tool-landing-onboarding-step3-ru.png';
import onboardingStep4BwImage from './images/photo-tool-landing-onboarding-step4-bw.png';
import onboardingStep4EnImage from './images/photo-tool-landing-onboarding-step4-en.png';
import onboardingStep4RuImage from './images/photo-tool-landing-onboarding-step4-ru.png';

export const PHOTO_TOOL_ONBOARDING_IMAGES = {
    step1: {
        [ShortLocaleNameEnum.ru]: onboardingStep1RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep1EnImage,
        brandWizardImage: onboardingStep1BwImage,
    },
    step2: {
        [ShortLocaleNameEnum.ru]: onboardingStep2RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep2EnImage,
        brandWizardImage: onboardingStep2BwImage,
    },
    step3: {
        [ShortLocaleNameEnum.ru]: onboardingStep3RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep3EnImage,
        brandWizardImage: onboardingStep3BwImage,
    },
    step4: {
        [ShortLocaleNameEnum.ru]: onboardingStep4RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep4EnImage,
        brandWizardImage: onboardingStep4BwImage,
    },
};
