import {Text} from '../../../../../../../../../../../component/text/text';
import {SourcePostType} from '../../../../../../../../../../../service/posts/posts-types';
import {ExpandableTextPreview} from '../../../expandable-text-preview/expandable-text-preview';
import {EventHeader} from '../event-header/event-header';
import {GoogleActions} from '../google-actions/google-actions';

import * as styles from './google-preview-body.scss';

type PropsType = {
    post: SourcePostType;
};

export function GooglePreviewBody(props: PropsType): JSX.Element {
    const {post} = props;

    const {text, images, actionType, topicType, event} = post;

    const imageSrc = images && images[0] ? images[0].url : '';

    return (
        <div>
            {imageSrc && (
                <div className={styles.GooglePreviewBody_imageContainer}>
                    <img alt="" className={styles.GooglePreviewBody_image} src={imageSrc} />
                </div>
            )}

            <div className={styles.GooglePreviewBody_content}>
                <EventHeader event={event} topicType={topicType} />
                <ExpandableTextPreview
                    expandButton={<Text secondary stringKey="POSTS_FORM__PREVIEW__GOOGLE__READ_MORE" />}
                    rows={2}
                    text={text}
                />
                <GoogleActions actionType={actionType} topicType={topicType} />
            </div>
        </div>
    );
}
