import {UseQueryResult} from '@tanstack/react-query';
import {Dispatch, SetStateAction} from 'react';
import {z as r} from 'zod';

import {PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {TableSorterType} from '../../../util/table';
import {generateResponseSchema} from '../../api/api-type';
import {
    LocalPackCatalogsEnum,
    LocalPackPointDistanceEnum,
    LocalPackServicesEnum,
    LocalPackSquareSizeEnum,
} from '../local-pack-type';

const localPackSearchPhrasesTop5Schema = r.object({
    provider: r.nativeEnum(LocalPackCatalogsEnum),
    service: r.nativeEnum(LocalPackServicesEnum),
    results: r.array(
        r.object({
            name: r.string(),
            rating: r.number().nullable(),
        })
    ),
});

const localPackSearchPhrasesAnalyticsItemSchema = r.object({
    company: r.object({
        id: r.number(),
        name: r.string(),
        address: r.object({
            country: r.string(),
            city: r.string().nullable(),
            street: r.string(),
            housenumber: r.string(),
        }),
    }),

    rule: r.object({
        ruleId: r.string(),
        searchQueryId: r.string(),
        searchQuery: r.string(),
        gridSize: r.nativeEnum(LocalPackSquareSizeEnum),
        gridStep: r.nativeEnum(LocalPackPointDistanceEnum),
    }),

    position: r.object({
        current: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    completedSearches: r.number(),
    cost: r.number(),
    lastSearchDate: r.string(),
    top5: r.array(localPackSearchPhrasesTop5Schema),
});

export const localPackSearchPhrasesAnalyticsResponseSchema = generateResponseSchema(
    localPackSearchPhrasesAnalyticsItemSchema
);

export type LocalPackSearchPhrasesStatisticsType = r.infer<typeof localPackSearchPhrasesAnalyticsResponseSchema>;

export type LocalPackSearchPhrasesStatisticsItemType = r.infer<typeof localPackSearchPhrasesAnalyticsItemSchema>;

export type LocalPackSearchPhrasesStatisticsHookType = UseQueryResult<LocalPackSearchPhrasesStatisticsType> &
    PaginationType & {
        updateSorter: Dispatch<SetStateAction<TableSorterType | null>>;
    };
