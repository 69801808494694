import {Badge, Button} from 'antd';
import {useState} from 'react';

import {danger60, orange60} from '../../../../../../css/var-export.scss';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../../provider/locale/locale';
import {useUser} from '../../../../../../provider/user/user-hook';
import {formatAddress} from '../../../../../../service/address/address-helper';
import {CclTableDataType} from '../../../../../../service/ccl/ccl-type';
import {saveToLocalStorage} from '../../../../../../util/local-storage';
import {CclCatalogStatusEnum, ProvidersIdsEnum} from '../../../../../../util/type';
import {YandexAccountTransferModal} from '../../../../my-companies/yandex-account-transfer-modal/yandex-account-transfer-modal';
import {SHOW_STATUS_ONBOARDING_LOCALSTORAGE_KEY} from '../../status-column-const';

import {OnboardingPopover} from './onboarding-popover/onboarding-popover';
import {StatusColumnPopover} from './popover/status-column-popover';
import {
    BADGE_COLOR_BY_CCL_STATUS,
    STATUS_LINK_TEXT_BY_CATALOG_STATUS,
    STATUS_TEXT_BY_CCL_STATUS,
} from './status-column-const';
import {
    buildProviderConfirmationRoute,
    buildProviderConfirmationRouteV2,
    buildSourcesNeedActionRoute,
    buildSourcesNeedActionRouteV2,
    getStatusByCclAndUser,
} from './status-column-helper';
import * as styles from './status-column.scss';

type PropsType = {
    cclData: CclTableDataType;
    showInfoPopover?: boolean;
};

// eslint-disable-next-line complexity
export function StatusColumn(props: PropsType): JSX.Element {
    const {cclData, showInfoPopover} = props;

    const {user} = useUser();
    const [openPopover, setOpenPopover] = useState<boolean>(true);
    const [openYandexModal, setOpenYandexModal] = useState<boolean>(false);

    const isYandexTransferRequired = cclData.transferRequired && cclData.catalog.id === ProvidersIdsEnum.yandex;

    const status = getStatusByCclAndUser(cclData, user, isYandexTransferRequired);
    const bindingRouteV2 = buildSourcesNeedActionRouteV2(
        cclData.catalog.parentId || cclData.catalog.id,
        cclData.company.id,
        cclData.brand.id
    );

    const isMassConfirmation =
        cclData?.catalog?.id === ProvidersIdsEnum.google &&
        cclData.verificationStatus === CclCatalogStatusEnum.notVerified &&
        !cclData.needAction;

    const confirmationRouteV2 = buildProviderConfirmationRouteV2(
        cclData.catalog.parentId || cclData.catalog.id,
        cclData.company.id,
        isMassConfirmation
    );

    // TODO: fix hidden status check logic linked with STATUS_LINK_TEXT_BY_CATALOG_STATUS map
    const isConfirmation = Boolean(cclData.verificationStatus) && status !== STATUS_TEXT_BY_CCL_STATUS.on_moderation;
    const isLinking = cclData.needAction && !isConfirmation && status !== STATUS_TEXT_BY_CCL_STATUS.on_moderation;

    function closePopover() {
        setOpenPopover(false);
        saveToLocalStorage(false, SHOW_STATUS_ONBOARDING_LOCALSTORAGE_KEY);
    }

    function getV1Link(link: string) {
        if (!user?.isStaff) {
            return false;
        }

        return (
            <>
                {' / '}

                <NavigationLink to={link}>v1</NavigationLink>
            </>
        );
    }

    // eslint-disable-next-line complexity
    function getStatusText() {
        const statusText = <Locale stringKey={status} />;

        if (isLinking) {
            return (
                <span>
                    <NavigationLink
                        to={
                            bindingRouteV2 ||
                            buildSourcesNeedActionRoute(
                                cclData.catalog.parentId || cclData.catalog.id || 0,
                                cclData.company.id,
                                cclData.brand.id
                            )
                        }
                    >
                        {statusText}
                    </NavigationLink>
                    {cclData.hiddenOnYandexMaps && (
                        <>
                            {' / '}
                            <span className={styles.StatusColumn_statusHidden}>
                                <Locale stringKey="SOURCES__STATUS__HIDE" />
                            </span>
                        </>
                    )}

                    {bindingRouteV2 &&
                        getV1Link(
                            buildSourcesNeedActionRoute(
                                cclData.catalog.parentId || cclData.catalog.id || 0,
                                cclData.company.id,
                                cclData.brand.id
                            )
                        )}
                </span>
            );
        }

        if (isConfirmation) {
            return (
                <span>
                    {statusText}

                    {' / '}

                    <NavigationLink
                        to={
                            confirmationRouteV2 ||
                            buildProviderConfirmationRoute(
                                cclData.catalog.parentId || cclData.catalog.id,
                                cclData.brand.id,
                                cclData.company.id,
                                cclData.verificationStatus
                            )
                        }
                    >
                        <Locale
                            stringKey={
                                isMassConfirmation
                                    ? 'GOOGLE_SYNC__CONFIRMATIONS__MASS_REQUESTS'
                                    : STATUS_LINK_TEXT_BY_CATALOG_STATUS[cclData.verificationStatus ?? ''] ??
                                      'SOURCES__ACTION_LINK__CONFIRM'
                            }
                        />
                    </NavigationLink>
                    {cclData.hiddenOnYandexMaps && (
                        <>
                            {' / '}
                            <span className={styles.StatusColumn_statusHidden}>
                                <Locale stringKey="SOURCES__STATUS__HIDE" />
                            </span>
                        </>
                    )}
                </span>
            );
        }

        return (
            <>
                {showInfoPopover && openPopover && !user?.isStaff ? (
                    <OnboardingPopover onClose={closePopover} statusText={statusText} />
                ) : (
                    statusText
                )}
                {cclData.hiddenOnYandexMaps && (
                    <>
                        {' / '}
                        <span className={styles.StatusColumn_statusHidden}>
                            <Locale stringKey="SOURCES__STATUS__HIDE" />
                        </span>
                    </>
                )}
            </>
        );
    }

    function getBadgeColor() {
        if (isLinking || isConfirmation || isYandexTransferRequired) {
            return danger60;
        }

        if (cclData.hiddenOnYandexMaps) {
            return orange60;
        }

        return BADGE_COLOR_BY_CCL_STATUS[cclData.stateStatus];
    }

    return (
        <div className={styles.StatusColumn}>
            <Badge color={getBadgeColor()} />
            {getStatusText()}{' '}
            {isYandexTransferRequired && (
                <>
                    /
                    <Button className={styles.YandexLinkButton} onClick={() => setOpenYandexModal(true)} type="link">
                        <Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__GET_ACCESS" />
                    </Button>
                </>
            )}
            <StatusColumnPopover
                cclData={cclData}
                isConfirmation={isConfirmation}
                isLinking={isLinking}
                needYandexTransferAccount={isYandexTransferRequired}
            />
            {openYandexModal && (
                <YandexAccountTransferModal
                    cclId={cclData.id}
                    companyInfo={{address: formatAddress(cclData.company.address), name: cclData.company.name}}
                    isCheckAll={false}
                    setOpenModal={setOpenYandexModal}
                />
            )}
        </div>
    );
}
