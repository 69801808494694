import {faCirclePause, faCirclePlay, faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../../app-route';
import {Text} from '../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {useActionRequiresLicense} from '../../../../../../provider/license/license-hook';
import {useUser} from '../../../../../../provider/user/user-hook';
import {useLocalPackUpdateRuleMutation} from '../../../../../../service/local-pack/rules/local-pack-rule-hook';
import {LocalPackRuleStatusEnum} from '../../../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {FeaturesEnum} from '../../../../../../service/user/user-type';

import * as styles from './local-pack-rule-action.scss';

type PropsType = {
    ruleId: number;
    ruleStatus: LocalPackRuleStatusEnum;
    onActionFinish: () => void;
};

export function LocalPackRuleAction(props: PropsType): JSX.Element {
    const {ruleId, ruleStatus, onActionFinish} = props;
    const {user} = useUser();

    const {mutateAsync, isLoading} = useLocalPackUpdateRuleMutation();

    const handleRuleActiveStatusChange = useActionRequiresLicense(async (isActive: boolean) => {
        await mutateAsync({
            ruleId,
            data: {
                isActive,
            },
        });
        onActionFinish();
    }, FeaturesEnum.localPack);

    return (
        <>
            {user?.isStaff && (
                <NavigationLink to={generatePath(appRoute.localPackRuleEdit.path, {ruleId})}>
                    <Button className={styles.LocalPackRuleActionButton} size="small" type="link">
                        <Text inheritColor>
                            <FontAwesomeIcon icon={faEdit} />
                        </Text>
                    </Button>
                </NavigationLink>
            )}
            {ruleStatus === LocalPackRuleStatusEnum.Active && (
                <Button
                    className={styles.LocalPackRuleActionButton}
                    disabled={isLoading}
                    onClick={() => handleRuleActiveStatusChange(false)}
                    size="small"
                    type="link"
                >
                    <Text inheritColor>
                        <FontAwesomeIcon icon={faCirclePause} />
                    </Text>
                </Button>
            )}
            {ruleStatus === LocalPackRuleStatusEnum.NotActive && (
                <Button
                    className={styles.LocalPackRuleActionButton}
                    disabled={isLoading}
                    onClick={() => handleRuleActiveStatusChange(true)}
                    size="small"
                    type="link"
                >
                    <Text inheritColor>
                        <FontAwesomeIcon icon={faCirclePlay} />
                    </Text>
                </Button>
            )}
        </>
    );
}
