import {fetchAndDeserialize} from '../../util/api-adapter';

import {userUrl} from './user-const';
import {userSchema, UserType} from './user-type';

export const userOwnUrl = `/v2${userUrl}/own/`;

export function fetchUserOwn(): Promise<UserType> {
    return fetchAndDeserialize(userOwnUrl, userSchema);
}
