import {isObject} from 'lodash';

import {browserHistory} from './history';

type ForceLogoutStateType = {forceLogout: boolean};

export function isForceLogoutLocationState(state: unknown): state is ForceLogoutStateType {
    if (!isObject(state) || !state) {
        return false;
    }

    return Boolean((state as Record<string, unknown>).forceLogout);
}

export function forceLogout(): void {
    if (!isForceLogoutLocationState(browserHistory?.location.state)) {
        const forceLogoutState: ForceLogoutStateType = {forceLogout: true};

        browserHistory?.replace(browserHistory.location, forceLogoutState);
    }
}
