import {BrowserNotificationsType} from '../../../../service/notifications-settings/notifications-settings-type';

export const browserNotificationsFields: Record<keyof BrowserNotificationsType, keyof BrowserNotificationsType> = {
    pushalert_notifications_time_bucket: 'pushalert_notifications_time_bucket',
    send_pushalert_notifications: 'send_pushalert_notifications',
    pushalert_notifications_catalogs: 'pushalert_notifications_catalogs',
    pushalert_notifications_content_type: 'pushalert_notifications_content_type',
    pushalert_notifications_ratings: 'pushalert_notifications_ratings',
    send_pushalert_omnichannel_notifications: 'send_pushalert_omnichannel_notifications',
};
