import {setTags, setUser} from '@sentry/react';
import {useEffect, useMemo} from 'react';

import {useBrands} from '../../provider/brands/brand-hook';
import {BrandType} from '../../provider/brands/brand-type';
import {useUser} from '../../provider/user/user-hook';
import {getHash} from '../../util/string';

export function SentryTags(): null {
    const userContext = useUser();
    const {user} = userContext;
    const userId: number | void = user?.id;

    const {brands, isLoading} = useBrands();
    const brandIds = useMemo<Array<number> | null>((): Array<number> | null => {
        return brands.map((brand: BrandType): number => brand.id) || null;
    }, [brands]);

    useEffect(() => {
        if (!isLoading && brands.length > 0) {
            setTags({
                brand: String(brands.map((brand) => brand.name).join(', ')),
            });
        }

        setUser({id: getHash(String(userId))});
    }, [brandIds, brands, isLoading, userId]);

    return null;
}
