import {FormListProps} from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';

import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {Form} from '../../../../../../../typings/antd';
import * as descriptionsWidgetStyles from '../descriptions-widget.scss';

type PropsType = {
    name: FormListProps['name'];
    maxLength?: number;
};

export function LongDescriptionFormItem(props: PropsType): JSX.Element {
    const {name, maxLength} = props;

    const {getLocalizedString} = useLocale();

    return (
        <div className={descriptionsWidgetStyles.descriptions_widget__input}>
            <Form.Item label={<Locale stringKey="COMPANY_FORM__DESCRIPTIONS__LONG_DESCRIPTION__LABEL" />} name={name}>
                <TextArea
                    maxLength={maxLength}
                    placeholder={getLocalizedString('COMPANY_FORM__DESCRIPTIONS__LONG_DESCRIPTION__PLACEHOLDER')}
                    rows={5}
                    showCount={Boolean(maxLength)}
                    size="large"
                />
            </Form.Item>
        </div>
    );
}
