import {
    MainFilterSelectedValuesIdsAdditionalKeyEnum,
    MainFilterSelectedValuesIdsKeyEnum,
    SuggestionApiFieldNameEnum,
} from './main-filter-api-type';
import {MainFilterFieldNameEnum} from './main-filter-type';

export const fieldNameToValueIdsKeyMap: Record<
    MainFilterFieldNameEnum,
    MainFilterSelectedValuesIdsKeyEnum | MainFilterSelectedValuesIdsAdditionalKeyEnum
> = {
    [MainFilterFieldNameEnum.GROUPS]: MainFilterSelectedValuesIdsKeyEnum.GROUPS,
    [MainFilterFieldNameEnum.BRANDS]: MainFilterSelectedValuesIdsKeyEnum.BRANDS,
    [MainFilterFieldNameEnum.COUNTRIES]: MainFilterSelectedValuesIdsKeyEnum.COUNTRIES,
    [MainFilterFieldNameEnum.REGIONS]: MainFilterSelectedValuesIdsKeyEnum.REGIONS,
    [MainFilterFieldNameEnum.CITIES]: MainFilterSelectedValuesIdsKeyEnum.CITIES,
    [MainFilterFieldNameEnum.STREETS]: MainFilterSelectedValuesIdsKeyEnum.STREETS,
    [MainFilterFieldNameEnum.NAMES]: MainFilterSelectedValuesIdsKeyEnum.NAMES,
    [MainFilterFieldNameEnum.CODES]: MainFilterSelectedValuesIdsKeyEnum.CODES,
    [MainFilterFieldNameEnum.CLOSED]: MainFilterSelectedValuesIdsAdditionalKeyEnum.CLOSED,
    [MainFilterFieldNameEnum.ACTIVE]: MainFilterSelectedValuesIdsAdditionalKeyEnum.ACTIVE,
};

export const fieldNameToSuggestionsFieldKey: Record<MainFilterFieldNameEnum, SuggestionApiFieldNameEnum> = {
    [MainFilterFieldNameEnum.GROUPS]: SuggestionApiFieldNameEnum.GROUPS,
    [MainFilterFieldNameEnum.BRANDS]: SuggestionApiFieldNameEnum.BRANDS,
    [MainFilterFieldNameEnum.COUNTRIES]: SuggestionApiFieldNameEnum.COUNTRIES,
    [MainFilterFieldNameEnum.REGIONS]: SuggestionApiFieldNameEnum.REGIONS,
    [MainFilterFieldNameEnum.CITIES]: SuggestionApiFieldNameEnum.CITIES,
    [MainFilterFieldNameEnum.STREETS]: SuggestionApiFieldNameEnum.STREETS,
    [MainFilterFieldNameEnum.NAMES]: SuggestionApiFieldNameEnum.NAMES,
    [MainFilterFieldNameEnum.CODES]: SuggestionApiFieldNameEnum.CODES,
    [MainFilterFieldNameEnum.CLOSED]: SuggestionApiFieldNameEnum.CLOSED,
    [MainFilterFieldNameEnum.ACTIVE]: SuggestionApiFieldNameEnum.ACTIVE,
};

export const suggestionsPerRequestsCount = 10;
