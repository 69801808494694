import {Divider} from 'antd';
import {useCallback, useState} from 'react';

import {appRoute} from '../../../../../../app-route';
import {Main} from '../../../../../../component/main/main';
import {Meta} from '../../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../../layout/page-card/main-page-card';
import {PageHeader} from '../../../../../../layout/page-header/page-header';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {useCompaniesImportValidation} from '../../../../../../service/companies-import/companies-import';
import {useCompaniesImportDeleteMutation} from '../../../../../../service/companies-import/companies-import-delete';
import {CompaniesImportTypeEnum} from '../../../../../../service/companies-import/companies-import-type';
import {FeedForm} from '../form/feed-form';
import {FeedFormTranslationType} from '../form/feed-form-type';
import {FeedResults} from '../results/feed-results';
import {FeedResultsTypeEnum} from '../results/feed-results-const';
import {TemplateLinks} from '../template-links/template-links';

import * as styles from './feed-form-page.scss';

type TranslationType = 'pageTitle' | 'breadcrumbTitle' | 'header' | FeedFormTranslationType;

type PropsType = {
    translations: Record<TranslationType, LangKeyType>;
    route: string;
    type: FeedResultsTypeEnum;
    headerButton: JSX.Element;
};

export function FeedFormPage(props: PropsType): JSX.Element {
    const {translations, route, type, headerButton} = props;
    const {pageTitle, breadcrumbTitle, header, ...formTranslations} = translations;

    const importValidation = useCompaniesImportValidation(type);
    const {getLocalizedString} = useLocale();
    const [feedFormat, setFeedFormat] = useState<CompaniesImportTypeEnum | null>(null);

    const {mutate, reset, data} = importValidation;
    const {mutate: deleteImport} = useCompaniesImportDeleteMutation(data?.importUrlId);

    const handleImportCancellation = useCallback(() => {
        if (type === FeedResultsTypeEnum.Import) {
            deleteImport();
        }

        reset();
    }, [deleteImport, reset, type]);

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString(pageTitle)} />

            <Main mainClassName={styles.FeedFormPage_main} wrapperClassName={styles.FeedFormPage_mainWrapper}>
                <BreadCrumbs
                    list={[
                        {path: appRoute.myCompanies.path, titleLangKey: 'CATEGORY_NAME__MY_COMPANIES'},
                        {path: route, titleLangKey: breadcrumbTitle},
                    ]}
                />

                <PageHeader>
                    <Locale stringKey={header} />
                    {headerButton}
                </PageHeader>

                <Divider className={styles.FeedFormPage_divider} />

                <div className={styles.FeedFormPage_content}>
                    <div className={styles.FeedFormPage_left}>
                        <FeedForm
                            hasBrands
                            importValidation={importValidation}
                            onAbort={handleImportCancellation}
                            onSubmit={(options) => {
                                mutate(options);
                                setFeedFormat(options.importType);
                            }}
                            translations={formTranslations}
                            type={type}
                        />
                    </div>

                    <aside className={styles.FeedFormPage_right}>
                        <TemplateLinks type={type} />
                    </aside>
                </div>
            </Main>

            <FeedResults feedFormat={feedFormat} importValidation={importValidation} type={type} />
        </Page>
    );
}
