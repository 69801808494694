import {Select} from 'antd';
import {PropsWithChildren, useState} from 'react';

import {CompanyCatalogCategoryType as CompanyCatalogCategoryApiType} from '../../../../../../../service/api/api-type';
import {useSearchCompanyCatalogCategoryList} from '../../../../../../../service/api-hook/api-hook';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyCatalogCategoryType} from '../../../../../../../service/company-v2/types/company-categories-type';
import {ErrorList} from '../../../error-list/error-list';
import {catalogCategoryLimits} from '../categories-widget-const';

import {SelectCategoriesFormItem} from './select-categories-form-item';
import {highlightOption} from './select-categories-form-item-helper';
import * as styles from './select-categories-form-item.scss';

type PropsType = PropsWithChildren<{
    value?: Array<CompanyCatalogCategoryType>;
    onChange?: (value: Array<CompanyCatalogCategoryType>) => void;
    catalogId: number;
    locked?: boolean;
    isPaidYandexPlacement: boolean;
}>;

export function SelectCategoriesFormItemWithCatalog(props: PropsType): JSX.Element {
    const {children, value, onChange, catalogId, locked, isPaidYandexPlacement} = props;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const {result: companyCategoryResult, isInProgress} = useSearchCompanyCatalogCategoryList(
        searchQuery || null,
        catalogId
    );

    return (
        <>
            <SelectCategoriesFormItem<CompanyCatalogCategoryType, CompanyCatalogCategoryApiType>
                catalogId={catalogId}
                companyCategoryList={companyCategoryResult ?? []}
                createCategory={(category: CompanyCatalogCategoryApiType, isMain: boolean) => ({
                    originId: category.category_origin_id,
                    categoryName: category.category_name,
                    isMain,
                })}
                disableDragging
                getApiId={(category: CompanyCatalogCategoryApiType) => category.category_origin_id}
                getId={(category: CompanyCatalogCategoryType) => category.originId}
                isInProgress={isInProgress}
                locked={locked}
                maxAmount={catalogCategoryLimits(isPaidYandexPlacement)[catalogId]}
                onChange={onChange}
                renderOption={(category: CompanyCatalogCategoryApiType) => {
                    const {category_label, category_origin_id} = category;

                    return (
                        <Select.Option
                            className={styles.select_company_category_list__select_option}
                            key={category_origin_id}
                            title={category_label}
                            value={category_origin_id}
                        >
                            {highlightOption(category_label, searchQuery)}
                        </Select.Option>
                    );
                }}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                value={value}
            >
                {children}
            </SelectCategoriesFormItem>

            <ErrorList name={CompanyKeyEnum.CatalogCategories} />
        </>
    );
}
