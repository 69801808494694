import {faCalendar, faCircleCheck, faCircleXmark, faCoins} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {LicenseType} from '../../provider/license/license-provider-type';
import {FormattedDateTime} from '../../provider/locale/formatted-date-time';
import {Locale} from '../../provider/locale/locale';
import {LicenseCardSmallType, LicenseStatusEnum} from '../../service/local-pack/local-pack-type';
import {NeverError} from '../../util/error';

function getLicenseTimeConfig(license: LicenseType): LicenseCardSmallType {
    if (license.isActive && license.finishAt) {
        return {
            icon: <FontAwesomeIcon icon={faCalendar} />,
            title: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__TIME__TITLE" />,
            description: (
                <Locale
                    stringKey="LICENSE_CARD__INFORMATION__LICENSE__TIME__DESCRIPTION"
                    valueMap={{date: <FormattedDateTime value={new Date(license.finishAt)} />}}
                />
            ),
        };
    }

    return {
        icon: <FontAwesomeIcon icon={faCircleXmark} />,
        title: (
            <Locale
                stringKey={
                    license?.isTrial
                        ? 'LICENSE_CARD__INFORMATION__TRIAL_EXPIRED__TITLE'
                        : 'LICENSE_CARD__INFORMATION__LICENSE_EXPIRED__TITLE'
                }
            />
        ),
        description: <Locale stringKey="LICENSE_CARD__INFORMATION__EXPIRED__DESCRIPTION" />,
    };
}

function getGeneralCardConfig(): LicenseCardSmallType {
    return {
        icon: <FontAwesomeIcon icon={faCircleCheck} />,
        title: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__TITLE" />,
        description: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__DESCRIPTION" />,
    };
}

function getRequestedInfoConfig(): LicenseCardSmallType {
    return {
        icon: <FontAwesomeIcon icon={faCircleCheck} />,
        title: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__TITLE" />,
        description: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__REQUESTED__DESCRIPTION" />,
    };
}

function getCostConfig(): LicenseCardSmallType {
    return {
        icon: <FontAwesomeIcon icon={faCoins} />,
        title: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__COST__TITLE" />,
        description: <Locale stringKey="LICENSE_CARD__INFORMATION__LICENSE__COST__DESCRIPTION" />,
    };
}

export function getLicenseConfig(options: {
    licenseStatus: LicenseStatusEnum;
    license: LicenseType;
}): Array<LicenseCardSmallType> {
    const {licenseStatus, license} = options;

    switch (licenseStatus) {
        case LicenseStatusEnum.active: {
            return [getLicenseTimeConfig(license), getCostConfig()];
        }
        case LicenseStatusEnum.expired: {
            return [getLicenseTimeConfig(license), getGeneralCardConfig()];
        }
        case LicenseStatusEnum.requested: {
            return [getLicenseTimeConfig(license), getRequestedInfoConfig()];
        }
        default: {
            throw new NeverError(licenseStatus);
        }
    }
}

export function licenseActivationRequestStorageKey(tariffId: number, licenseCode: string): string {
    return `LICENSE_ACTIVATION_REQUEST_${tariffId}_${licenseCode}`;
}

export const LICENSE_REQUEST_ACTIVE_DAYS = 14;
