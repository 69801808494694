import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Tooltip} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {ReactElement} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/localization';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';

type PropsType = {
    isNewCompanies: boolean;
    setIsNewCompanies: (value: boolean) => void;
};

export function NewCompaniesCheckbox(props: PropsType): ReactElement {
    const {isNewCompanies, setIsNewCompanies} = props;

    function handleChangeCheckbox(event: CheckboxChangeEvent) {
        setIsNewCompanies(event.target.checked);

        if (event.target.checked) {
            track(AnalyticsTarget.MyCompanies.SelectNewCompanies);
        }
    }

    return (
        <div>
            <Checkbox checked={isNewCompanies} onChange={handleChangeCheckbox}>
                <Text stringKey="MY_COMPANIES__NEW_COMPANY_CHECKBOX__TITLE" />
            </Checkbox>
            <Tooltip placement="bottom" title={<Locale stringKey="MY_COMPANIES__NEW_COMPANY_CHECKBOX__TOOLTIP" />}>
                <FontAwesomeIcon color="#8c8c8c" icon={faCircleInfo} />
            </Tooltip>
        </div>
    );
}
