import {Empty} from 'antd';
import {PropsWithChildren} from 'react';

import * as styles from './empty-table.scss';

type PropsType = PropsWithChildren<{
    title: JSX.Element;
    subtitle?: JSX.Element;
}>;

export function EmptyTable(props: PropsType): JSX.Element {
    const {title, children, subtitle} = props;

    return (
        <Empty
            className={styles.EmptyTable}
            description={null}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{width: '112px'}}
        >
            <h4 className={styles.EmptyTable_title}>{title}</h4>
            {subtitle && <p className={styles.EmptyTable_subtitle}>{subtitle}</p>}
            {children}
        </Empty>
    );
}
