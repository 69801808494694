import {z as r} from 'zod';

import {IdNumberType} from '../../../util/type';
import {cursorPaginationResponseSchemaFactory, idNameTypeSchema} from '../../api/api-type';
import {
    LocalPackCatalogsEnum,
    LocalPackPointDistanceEnum,
    LocalPackServicesEnum,
    LocalPackSquareSizeEnum,
} from '../local-pack-type';

export enum LocalPackRuleFormFieldsEnum {
    Name = 'name',
    Brand = 'brand_id',
    Services = 'services',
    SearchPhrases = 'search_phrases',
    Frequency = 'periodicity',
    SquareSize = 'grid_size',
    PointDistance = 'grid_step',
}

export enum LocalPackRuleFrequencyEnum {
    Daily = 'day',
    Weekly = 'week',
    Monthly = 'month',
    Once = 'once',
}

export type LocalPackRuleFormType = {
    [LocalPackRuleFormFieldsEnum.Name]: string;
    [LocalPackRuleFormFieldsEnum.Brand]: IdNumberType | null;
    [LocalPackRuleFormFieldsEnum.SearchPhrases]: Array<string>;
    [LocalPackRuleFormFieldsEnum.Services]: Array<IdNumberType>;
    [LocalPackRuleFormFieldsEnum.Frequency]: LocalPackRuleFrequencyEnum;
    [LocalPackRuleFormFieldsEnum.SquareSize]: LocalPackSquareSizeEnum;
    [LocalPackRuleFormFieldsEnum.PointDistance]: LocalPackPointDistanceEnum;
};

export enum LocalPackTrialPeriodActivationFormFieldEnum {
    Services = 'services',
    SearchPhrase = 'search_query',
    Frequency = 'periodicity',
    SquareSize = 'grid_size',
    PointDistance = 'grid_step',
    CompaniesSelector = 'selector_id',
}

export type LocalPackActivationRuleFormType = {
    [LocalPackTrialPeriodActivationFormFieldEnum.Services]: Array<IdNumberType>;
    [LocalPackTrialPeriodActivationFormFieldEnum.Frequency]: LocalPackRuleFrequencyEnum;
    [LocalPackTrialPeriodActivationFormFieldEnum.SquareSize]: LocalPackSquareSizeEnum;
    [LocalPackTrialPeriodActivationFormFieldEnum.PointDistance]: LocalPackPointDistanceEnum;
    [LocalPackTrialPeriodActivationFormFieldEnum.SearchPhrase]: string;
    [LocalPackTrialPeriodActivationFormFieldEnum.CompaniesSelector]: string;
};

export const localPackSearchServiceItemSchema = r.object({
    id: r.number(),
    provider: r.nativeEnum(LocalPackCatalogsEnum),
    service: r.nativeEnum(LocalPackServicesEnum),
    isActive: r.boolean(),
});

export type LocalPackSearchServiceType = r.infer<typeof localPackSearchServiceItemSchema>;

export const localPackBrandsResponseSchema = r.object({
    availableBrands: idNameTypeSchema.array(),
});

export type LocalPackBrandsResponseType = r.infer<typeof localPackBrandsResponseSchema>;

export enum LocalPackRuleStatusEnum {
    Active = 'active',
    NotActive = 'not_active',
    Rejected = 'rejected',
    Moderating = 'on_moderate',
}

const shortLocalPackRuleSchema = r.object({
    id: r.number(),
    name: r.string(),
    queries: r.array(
        r.object({
            id: r.number(),
            query: r.string(),
        })
    ),
    gridSize: r.nativeEnum(LocalPackSquareSizeEnum),
    gridStep: r.nativeEnum(LocalPackPointDistanceEnum),
    periodicity: r.nativeEnum(LocalPackRuleFrequencyEnum),
    status: r.nativeEnum(LocalPackRuleStatusEnum),
});

export const localPackRuleSchema = shortLocalPackRuleSchema.merge(
    r.object({
        brand: r.object({
            id: r.number(),
        }),
        services: r.array(
            r.object({
                id: r.number(),
            })
        ),
    })
);

export type LocalPackRuleType = r.infer<typeof localPackRuleSchema>;

const localPackRuleListItemSchema = shortLocalPackRuleSchema.merge(
    r.object({
        requestedChanges: shortLocalPackRuleSchema
            .pick({name: true, gridSize: true, gridStep: true, periodicity: true})
            .merge(
                r.object({
                    services: r.array(
                        r.object({
                            id: r.number(),
                        })
                    ),
                })
            )
            .deepPartial()
            .nullable(),
    })
);

export type LocalPackRuleListItemType = r.infer<typeof localPackRuleListItemSchema>;

export const localPackRulesSchema = cursorPaginationResponseSchemaFactory(localPackRuleListItemSchema);

export type LocalPackRulesType = r.infer<typeof localPackRulesSchema>;

export type UpdateLocalPackRuleOptionsType = {
    ruleId: number;
    data: Partial<LocalPackRuleFormType> & {
        isActive: boolean;
    };
};
