import {Col, Row} from 'antd';

import {ShortCatalogType} from '../../../../provider/catalogs/catalogs-type';
import {usePostsFilter} from '../../../../service/posts/posts-hooks';

import {PostsFilterActive} from './posts-filter-active/posts-filter-active';
import {PostsFilterForm} from './posts-filter-form/posts-filter-form';

type PropsType = {
    catalogs: Array<ShortCatalogType>;
};

export function PostsFilter(props: PropsType): JSX.Element {
    const {catalogs} = props;

    const {filter, setFilter} = usePostsFilter();

    return (
        <Row align="middle" justify="space-between" wrap={false}>
            <Col>
                <PostsFilterActive filter={filter} setFilter={setFilter} />
            </Col>

            <Col>
                <PostsFilterForm catalogs={catalogs} filter={filter} setFilter={setFilter} />
            </Col>
        </Row>
    );
}
