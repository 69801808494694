import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchCompetitors} from './competitiors-api';
import {CompetitorType} from './competitors-type';

export function useCompetitors(): UseHookType<Array<CompetitorType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CompetitorType>>();

    useEffect(() => {
        setIsInProgress(true);

        fetchCompetitors()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
