import {z as r} from 'zod';

import {ProvidersIdsEnum} from '../../../../../util/type';

export enum ProductsServiceTypeEnum {
    Menu = 'food',
    Services = 'services',
}

export enum CreateProductGroupFormFieldEnum {
    Name = 'name',
    Services = 'catalogs',
    CompanySelector = 'companies_selector_uid',
    ServiceType = 'service_type',
    SourceId = 'source_file_id',
    FeedUrl = 'feedUrl',
    GoogleCategory = 'google_category',
    IikoApiLogin = 'iiko_api_login',
    IikoMenuIds = 'iiko_menu_ids',
}

export type ProductGroupFormType = Partial<{
    [CreateProductGroupFormFieldEnum.Name]: string;
    [CreateProductGroupFormFieldEnum.Services]: Array<ProvidersIdsEnum>;
    [CreateProductGroupFormFieldEnum.CompanySelector]: string;
    [CreateProductGroupFormFieldEnum.ServiceType]: ProductsServiceTypeEnum | null;
    [CreateProductGroupFormFieldEnum.SourceId]: number | null;
    [CreateProductGroupFormFieldEnum.FeedUrl]?: string;
    [CreateProductGroupFormFieldEnum.GoogleCategory]?: string | null;
    [CreateProductGroupFormFieldEnum.IikoApiLogin]?: string;
    [CreateProductGroupFormFieldEnum.IikoMenuIds]?: string | Array<string>;
}>;

export const productGroupCreationErrorSchema = r.record(
    r.nativeEnum(CreateProductGroupFormFieldEnum),
    r.array(r.string())
);

export type ProductGroupCreationErrorType = r.infer<typeof productGroupCreationErrorSchema>;
