import {useMemo} from 'react';

import {classNames} from '../../../util/css';

import * as styles from './progress-chart.scss';

type PropsType = {
    value: number;
    mediumValueThreshold?: number;
    highValueThreshold?: number;
    className?: string;
    inverseRelationship?: boolean;
};

export function ProgressChart(props: PropsType): JSX.Element {
    const {value, mediumValueThreshold, highValueThreshold, className, inverseRelationship = false} = props;

    // eslint-disable-next-line complexity
    const chartConfig = useMemo(() => {
        switch (true) {
            case Boolean(inverseRelationship && highValueThreshold && value >= highValueThreshold): {
                return {
                    valueColor: styles.low_value_color,
                    backgroundColor: styles.low_backgound_color,
                };
            }
            case Boolean(inverseRelationship && mediumValueThreshold && value <= mediumValueThreshold): {
                return {
                    valueColor: styles.medium_value_color,
                    backgroundColor: styles.medium_backgound_color,
                };
            }

            case Boolean(!inverseRelationship && highValueThreshold && value >= highValueThreshold): {
                return {
                    valueColor: styles.high_value_color,
                    backgroundColor: styles.high_backgound_color,
                };
            }
            // eslint-disable-next-line sonarjs/no-duplicated-branches
            case Boolean(!inverseRelationship && mediumValueThreshold && value >= mediumValueThreshold): {
                return {
                    valueColor: styles.medium_value_color,
                    backgroundColor: styles.medium_backgound_color,
                };
            }
            // eslint-disable-next-line sonarjs/no-duplicated-branches
            case Boolean(highValueThreshold || mediumValueThreshold): {
                return {
                    valueColor: styles.low_value_color,
                    backgroundColor: styles.low_backgound_color,
                };
            }

            default: {
                return {
                    valueColor: styles.default_value_color,
                    backgroundColor: styles.default_backgound_color,
                };
            }
        }
    }, [highValueThreshold, value, inverseRelationship, mediumValueThreshold]);

    return (
        <div
            className={classNames(styles.progress_chart__wrapper, className)}
            style={{
                backgroundColor: chartConfig.backgroundColor,
            }}
        >
            <div
                className={styles.progress_chart__value}
                style={{
                    backgroundColor: chartConfig.valueColor,
                    width: `${value}%`,
                }}
            />
        </div>
    );
}
