import {Tag} from 'antd';
import {PropsWithChildren} from 'react';

import {FieldModeEnum} from '../../../../service/company/company-type';
import {classNames} from '../../../../util/css';

import {TAG_CLASS_NAME_BY_FIELD_MODE} from './tag-mode-const';

type PropsType = PropsWithChildren<{
    mode: FieldModeEnum;
    className?: string;
}>;

export function TagMode(props: PropsType): JSX.Element {
    const {mode, className, children} = props;

    const tagClassName = TAG_CLASS_NAME_BY_FIELD_MODE[mode];

    return <Tag className={classNames(tagClassName, className)}>{children}</Tag>;
}
