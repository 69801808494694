import {UseMutateFunction, useMutation, UseMutationResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {postAndDeserialize} from '../../util/api-adapter';

import {fakesUrl} from './fakes-const';
import {FakeClientStatusEnum, FakeInternalStatusEnum} from './fakes-type';

const fakesChangeSchema = r.object({
    clientStatus: r.nativeEnum(FakeClientStatusEnum),
    internalStatus: r.nativeEnum(FakeInternalStatusEnum),
});

type FakesChangeType = r.infer<typeof fakesChangeSchema>;

type FakesChangeOptionsType = {
    id: number;
    internalStatus: FakeInternalStatusEnum;
};

export type FakesChangeMutateType = UseMutateFunction<FakesChangeType, unknown, FakeInternalStatusEnum>;

function postFakesChange(options: FakesChangeOptionsType): Promise<FakesChangeType> {
    const {id, internalStatus} = options;

    return postAndDeserialize(`${fakesUrl}/fake/${id}/change/`, fakesChangeSchema, {internalStatus});
}

export function useFakesChangeMutation(
    id: number
): UseMutationResult<FakesChangeType, unknown, FakeInternalStatusEnum> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (newInternalStatus: FakeInternalStatusEnum) =>
            postFakesChange({id, internalStatus: newInternalStatus}),
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="FAKES__RESULTS__CHANGE__ERROR__TITLE" />,
                description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
            });
        },
    });
}
