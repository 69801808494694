import {UnionToTupleType} from '../../../util/type';

import {ReviewAnalysisCompaniesSortKeyType} from './review-companies-type';

const sortKeys: UnionToTupleType<ReviewAnalysisCompaniesSortKeyType> = ['total', 'avgRating'];
const stringKeysCast: Array<string> = sortKeys;

export function isReviewAnalysisCompanySortKey(stringValue: string): stringValue is ReviewAnalysisCompaniesSortKeyType {
    return stringKeysCast.includes(stringValue);
}
