import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useLocalPackCreateRuleMutation} from '../../../../service/local-pack/rules/local-pack-rule-hook';
import {LocalPackRuleFormType} from '../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {LocalPackRuleForm} from '../local-pack-rule-form/local-pack-rule-form';
import {LOCAL_PACK_RULE_FORM_INITIAL_VALUES} from '../local-pack-rule-form/local-pack-rule-helper';

export function LocalPackCreateRulePage(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {pushUrl} = useUrl();
    const {mutateAsync} = useLocalPackCreateRuleMutation();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK__MANAGE_RULES')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                        {
                            path: appRoute.localPackRules.path,
                            titleLangKey: 'LOCAL_PACK__MANAGE_RULES',
                        },
                        {
                            path: '',
                            titleLangKey: 'LOCAL_PACK__CREATE_RULE_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <Locale stringKey="LOCAL_PACK__CREATE_RULE_PAGE__TITLE" />
                </PageHeader>
            </PageCard>

            <PageCard>
                <LocalPackRuleForm
                    initialValues={LOCAL_PACK_RULE_FORM_INITIAL_VALUES}
                    mode="create"
                    onFinish={async (formValues: LocalPackRuleFormType) => {
                        await mutateAsync(formValues);
                        pushUrl(appRoute.localPackRules.path);
                    }}
                />
            </PageCard>
        </Page>
    );
}
