import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {ReviewDataChildType} from '../../../../../../../service/reviews/reviews-type';
import {classNames} from '../../../../../../../util/css';
import {useFormat} from '../../../../../../../util/format-hook/format-hook';
import {getDateTimeDifferenceHumanSize} from '../../../../../../../util/time';
import {constructComment} from '../review-helper';

import {AnswerAuthor} from './answer-author/answer-author';
import {DeleteReply} from './delete-reply/delete-reply';
import {EditForm} from './edit-form/edit-form';
import {ReplyOnReplyForm} from './reply-on-reply-form/reply-on-reply-form';
import * as styles from './answer-item.scss';

type PropsType = {
    isTestBrand: boolean;
    className?: string;
    answer: ReviewDataChildType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    isDialog: boolean;
    reviewId: number;
    replyTimeInCatalog: number;
    replyTimeInRd: number;
};

// eslint-disable-next-line complexity
export function AnswerItem(props: PropsType): JSX.Element {
    const {className, answer, reviewId, refreshReview, isDialog, isTestBrand, replyTimeInCatalog, replyTimeInRd} =
        props;
    const {
        comment,
        createdInCatalog: creationDate,
        createdInRd,
        user,
        ableToDelete,
        ableToEdit,
        ableToReply,
        isCompanyComment: isOwner,
        autoreplyRuleId,
    } = answer;
    const {localeName} = useLocale();
    const {getFormattedDateTime} = useFormat();
    const [isEditing, setIsEditing] = useState(false);
    const [isReplying, setIsReplying] = useState(false);

    const itemClassName = classNames(
        styles.AnswerItem,
        {[styles.AnswerItem__owner]: isOwner},
        {[styles.AnswerItem__user]: !isOwner},
        className
    );

    const createdInRdDateTime = (
        <span className={styles.AnswerItem_tooltipDate}>
            {getFormattedDateTime(new Date(createdInRd), {
                hour: '2-digit',
                minute: '2-digit',
            })}
            &nbsp;
            {getFormattedDateTime(new Date(createdInRd), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })}
        </span>
    );

    return (
        <div className={itemClassName}>
            <header className={styles.AnswerItem_header}>
                <AnswerAuthor answer={answer} />

                <Tooltip
                    placement="bottomRight"
                    title={
                        <Locale
                            stringKey="REVIEWS__REVIEW_ANSWER_CREATED_AT"
                            valueMap={{
                                date: createdInRdDateTime,
                            }}
                        />
                    }
                >
                    <p className={styles.AnswerItem_date}>
                        {getFormattedDateTime(creationDate ? new Date(creationDate) : new Date(createdInRd), {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                        &nbsp;·&nbsp;
                        {getFormattedDateTime(creationDate ? new Date(creationDate) : new Date(createdInRd), {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </p>
                </Tooltip>
            </header>

            {!isEditing && <div className={styles.AnswerItem_text}>{constructComment(comment || '', true)}</div>}

            {isOwner && (
                <footer className={styles.AnswerItem_meta}>
                    {Boolean(autoreplyRuleId) && (
                        <p className={styles.AnswerItem_metaItem}>
                            <Locale stringKey="REVIEWS__AUTO_REPLY" />
                        </p>
                    )}

                    {isDialog && (
                        <p className={styles.AnswerItem_metaItem}>
                            <Locale stringKey="REVIEWS__DIALOG" />
                        </p>
                    )}

                    {user && (
                        <p className={styles.AnswerItem_metaItem}>
                            <Locale
                                stringKey="REVIEWS__RESPONSE_EMPLOYEE"
                                valueMap={{
                                    name: (
                                        <strong className={styles.AnswerItem_metaItemValue} key="user-name">
                                            {user?.fullName || user.email}
                                        </strong>
                                    ),
                                }}
                            />
                        </p>
                    )}

                    {replyTimeInCatalog > 0 && (
                        <p className={styles.AnswerItem_metaItem}>
                            <Locale
                                stringKey="REVIEWS__RESPONSE_TIME_CATALOG"
                                valueMap={{
                                    time: (
                                        <strong className={styles.AnswerItem_metaItemValue} key="time">
                                            {getDateTimeDifferenceHumanSize({
                                                milliseconds: replyTimeInCatalog * 1000,
                                                sliceSize: 2,
                                                localeName,
                                            })}
                                        </strong>
                                    ),
                                }}
                            />
                        </p>
                    )}

                    {(Boolean(replyTimeInRd) || replyTimeInRd === 0) && (
                        <p className={styles.AnswerItem_metaItem}>
                            <Locale
                                stringKey="REVIEWS__RESPONSE_TIME"
                                valueMap={{
                                    time:
                                        replyTimeInRd < 60 ? (
                                            <strong className={styles.AnswerItem_metaItemValue} key="time">
                                                <Locale stringKey="REVIEWS__RESPONSE_TIME__DURING_MINUTE" />
                                            </strong>
                                        ) : (
                                            <strong className={styles.AnswerItem_metaItemValue} key="time">
                                                {getDateTimeDifferenceHumanSize({
                                                    milliseconds: replyTimeInRd * 1000,
                                                    sliceSize: 2,
                                                    localeName,
                                                })}
                                            </strong>
                                        ),
                                }}
                            />
                        </p>
                    )}

                    <div className={styles.AnswerItem_metaButtonWrapper}>
                        {ableToEdit && (
                            <Button
                                className={styles.AnswerItem_metaButton}
                                icon={<FontAwesomeIcon icon={faEdit} />}
                                onClick={() => setIsEditing(true)}
                                size="small"
                                type="link"
                            />
                        )}

                        {ableToDelete && (
                            <DeleteReply answer={answer} refreshReview={refreshReview} reviewId={reviewId} />
                        )}
                    </div>
                </footer>
            )}

            {isEditing && (
                <EditForm
                    answer={answer}
                    isTestBrand={isTestBrand}
                    onClose={() => setIsEditing(false)}
                    refreshReview={refreshReview}
                    reviewId={reviewId}
                />
            )}

            {isReplying && (
                <ReplyOnReplyForm
                    answer={answer}
                    isTestBrand={isTestBrand}
                    onClose={() => setIsReplying(false)}
                    refreshReview={refreshReview}
                    reviewId={reviewId}
                />
            )}

            {ableToReply && !isReplying && (
                <div className={styles.AnswerItem_buttonWrapper}>
                    <Button onClick={() => setIsReplying(true)}>
                        <Locale stringKey="REVIEWS__REPLY" />
                    </Button>
                </div>
            )}
        </div>
    );
}
