import {Checkbox} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';
import {useCallback} from 'react';

import {TimeRangePicker} from '../../../../layout/time-range-picker/time-range-picker';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {classNames} from '../../../../util/css';
import {WorkTimeDateType, WorkTimeType} from '../workdays-form-item-type';

import * as styles from './work-time.scss';

type PropsType = {
    className?: string;
    timeFrom: WorkTimeType | null;
    timeTo: WorkTimeType | null;
    isAroundTheClock: boolean;
    updateTime: (newTime: WorkTimeDateType) => void;
    idDisabled?: boolean;
    allowClear: boolean;
    order: boolean;
};

export function WorkTime(props: PropsType): JSX.Element {
    const {className, timeFrom, timeTo, isAroundTheClock, updateTime, idDisabled, allowClear, order} = props;
    const fullClassName = classNames(styles.WorkTime, className);
    const {getLocalizedString} = useLocale();

    const handleChangeTime = useCallback(
        (values: NoUndefinedRangeValueType<Dayjs> | null) => {
            if (!values) {
                updateTime({timeFrom: null, timeTo: null, isAroundTheClock});
                return;
            }

            const [dayjsFrom, dayjsTo] = values;

            updateTime({
                timeFrom: dayjsFrom ? {hour: dayjsFrom.hour(), minute: dayjsFrom.minute()} : null,
                timeTo: dayjsTo ? {hour: dayjsTo.hour(), minute: dayjsTo.minute()} : null,
                isAroundTheClock,
            });
        },
        [isAroundTheClock, updateTime]
    );

    return (
        <div className={fullClassName}>
            <Checkbox
                checked={isAroundTheClock}
                className={styles.WorkTime_aroundTheClockCheckbox}
                disabled={idDisabled}
                onChange={() => updateTime({timeFrom, timeTo, isAroundTheClock: !isAroundTheClock})}
            >
                <Locale stringKey="ADDITIONAL_INFO__AROUND_THE_CLOCK" />
            </Checkbox>

            <TimeRangePicker
                allowClear={allowClear}
                className={styles.WorkTime_timeRange}
                disabled={isAroundTheClock || idDisabled}
                onChange={handleChangeTime}
                order={order}
                placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                value={[
                    timeFrom && dayjs().hour(timeFrom.hour).minute(timeFrom.minute),
                    timeTo && dayjs().hour(timeTo.hour).minute(timeTo.minute),
                ]}
            />
        </div>
    );
}
