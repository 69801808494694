import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {ReviewsStateType} from '../../page/main/reviews/reviews-state/reviews-state-type';
import {createRaceFetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {rootApiUrl} from '../api/api-const';

import {reviewsStateFilterToUrlObject} from './reviews-helper';

type ReviewsStatisticDataType = {
    count: number;
    negative: number;
    neutral: number;
    positive: number;
    rating_avg: number;
};

const reviewsStatisticRaceFetchX = createRaceFetchX();

function getReviewsStatisticUrl(reviewOption: ReviewsStateType) {
    const searchParameters = objectToUrlParameters({
        ...(!reviewOption.company_ids ? {filter_key: reviewOption.filterId} : null),
        ...reviewsStateFilterToUrlObject(reviewOption),
    });

    return `${rootApiUrl}/v4/reviews/statistic/?${searchParameters}`;
}

function fetchReviewsStatistic(reviewOption: ReviewsStateType): Promise<ReviewsStatisticDataType> {
    return reviewsStatisticRaceFetchX<ReviewsStatisticDataType>(getReviewsStatisticUrl(reviewOption));
}

export function useReviewsStatistic(reviewsState: ReviewsStateType): UseQueryResult<ReviewsStatisticDataType> {
    return useQuery([getReviewsStatisticUrl(reviewsState)], () => fetchReviewsStatistic(reviewsState));
}
