import {z as r} from 'zod';

import {AttributeTypeEnum} from '../../../page/main/company-form/page-container/widgets/attributes/attributes-type';

const googleAttributeSchema = r.object({
    attributeId: r.string(),
    displayName: r.string(),
    groupDisplayName: r.string(),
    valueType: r.nativeEnum(AttributeTypeEnum),
    values: r.array(
        r.object({
            displayName: r.string(),
            value: r.union([r.boolean(), r.string()]),
        })
    ),
});

export type GoogleAttributeType = r.infer<typeof googleAttributeSchema>;

export const googleAttributesSchema = r.array(googleAttributeSchema);

export const yandexAttributesSchema = r.array(
    r.object({
        internalName: r.string(),
        displayName: r.string(),
        featureType: r.nativeEnum(AttributeTypeEnum),
        values: r.array(
            r.object({
                templateId: r.number(),
                displayValue: r.string().nullable(),
                unitValue: r.string().nullable(),
            })
        ),
    })
);

const doubleGisAttributeSchema = r.object({
    originId: r.string(),
    name: r.string(),
    attributeType: r.nativeEnum(AttributeTypeEnum),
    values: r.array(
        r.object({
            displayName: r.string(),
            value: r.union([r.boolean(), r.string()]),
        })
    ),
});

export type DoubleGisAttributeType = r.infer<typeof doubleGisAttributeSchema>;

export const doubleGisAttributesSchema = r.array(doubleGisAttributeSchema);
