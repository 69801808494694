import {faLink} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, List, Modal, Radio, RadioChangeEvent, Skeleton} from 'antd';
import {useState} from 'react';

import {CatalogLogo} from '../../../../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useGoogleAccounts} from '../../../../../../../service/accounts/accounts-hook';
import {GoogleAccountsHookReturnType} from '../../../../../../../service/google/accounts/google-accounts-type';
import {classNames} from '../../../../../../../util/css';
import {useNewAccount} from '../../../../../../../util/new-account-hook/new-account-hook';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {ActionCard} from '../action-card/action-card';

import * as styles from './accounts-popup.scss';

type PropsType = {
    data: GoogleAccountsHookReturnType['personalAccounts'];
    open: boolean;
    onOk: (accountId: number) => void;
    onCancel: () => void;
    setSearch: (value: string) => void;
    search: string;
};

export function AccountsPopup(props: PropsType): JSX.Element {
    const {data, open, onOk, onCancel, setSearch, search} = props;

    const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);
    const [searchInputValue, setSearchInputValue] = useState<string>('');

    const {linkingSrc, isInProgress} = useGoogleAccounts({skip: !open});
    const {addAccount} = useNewAccount({linkingSrc, onAccountAdd: data.refetch});

    const {getLocalizedString} = useLocale();

    function handleRadioGroupChange(event: RadioChangeEvent) {
        setSelectedAccountId(event.target.value);
    }

    function handleOk() {
        if (selectedAccountId) {
            onOk(selectedAccountId);
        }
    }

    return (
        <Modal
            cancelText={<Locale stringKey="POPUP__BUTTON__CLOSE" />}
            destroyOnClose
            okButtonProps={{disabled: !selectedAccountId}}
            okText={<Locale stringKey="POPUP__BUTTON__CONFIRM" />}
            onCancel={onCancel}
            onOk={handleOk}
            open={open}
            title={<Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__TITLE" />}
        >
            <Input.Search
                allowClear
                onChange={(event) => setSearchInputValue(event.target.value)}
                onSearch={setSearch}
                placeholder={getLocalizedString(
                    'GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__SEARCH_PLACEHOLDER'
                )}
                value={searchInputValue}
            />
            <Radio.Group className={styles.RadioGroup} onChange={handleRadioGroupChange} value={selectedAccountId}>
                <List
                    bordered
                    dataSource={data.accounts}
                    footer={
                        search && (
                            <div className={styles.List_footer}>
                                {!data.isInitialLoading ? (
                                    <>
                                        <Text
                                            secondary
                                            stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__SEARCH_FOOTER"
                                            valueMap={{
                                                searchAccounts: data.accounts?.length,
                                                total: data.total,
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                setSearch('');
                                                setSearchInputValue('');
                                            }}
                                            type="link"
                                        >
                                            <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__PERSONAL_ACCOUNT_POPUP__RESET_FILTER" />
                                        </Button>
                                    </>
                                ) : (
                                    <Skeleton />
                                )}
                            </div>
                        )
                    }
                    loading={data.isInitialLoading}
                    renderItem={(account) => (
                        <Radio
                            className={classNames(styles.Radio, {
                                [styles.Radio__selected]: account.id === selectedAccountId,
                            })}
                            value={account.id}
                        >
                            <div className={styles.Account}>
                                <strong className={styles.Account_name}>{account.name}</strong>

                                <CatalogLogo
                                    catalogId={ProvidersIdsEnum.google}
                                    circle
                                    className={styles.Account_icon}
                                />
                            </div>
                        </Radio>
                    )}
                    size="small"
                    split={false}
                />
            </Radio.Group>

            <ActionCard
                action={
                    <Button
                        icon={<FontAwesomeIcon icon={faLink} />}
                        loading={isInProgress}
                        onClick={addAccount}
                        type="link"
                    >
                        <span>
                            <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__LINK" />
                        </span>
                    </Button>
                }
                className={styles.ActionCard}
                title={<Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__LINK_ANOTHER_ACCOUNT" />}
            />
        </Modal>
    );
}
