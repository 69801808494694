import {useCallback, useEffect, useMemo} from 'react';

import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {CursorPaginationType} from '../../util/cursor-pagination/cursor-pagination-type';
import {PaginatedResponseType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    loadConfirmations,
    loadGoogleConfirmationMethods,
    loadGoogleVerificationMethods,
    loadYandexConfirmationSuggests,
} from './confirmation-api';
import {
    ConfirmationsSourcesType,
    ConfirmationSuggestsApiOptionsType,
    GoogleConfirmationMethodsHookType,
    GoogleConfirmationMethodType,
    GoogleVerificationMethodsApiOptionsType,
    YandexConfiarmationSuggestHookType,
    YandexConfirmationSuggestType,
} from './confirmation-type';

type SourcesConfirmationPropsType = {
    mainFilterKey?: string;
};

export function useYandexConfirmationSuggests(): YandexConfiarmationSuggestHookType {
    const {result, isInProgress, setIsInProgress, setResult, setProcessError} =
        useApiHooks<YandexConfirmationSuggestType>();

    const loadSuggest = useCallback(
        (options: ConfirmationSuggestsApiOptionsType) => {
            setIsInProgress(true);
            loadYandexConfirmationSuggests(options)
                .then((response) => {
                    setResult(response);
                })
                .finally(() => {
                    setIsInProgress(false);
                })
                .catch((error: Error) => {
                    setProcessError(error);
                });
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {
        suggest: result,
        isInProgress,
        loadSuggest,
    };
}

export function useSourcesConfirmation(
    props: SourcesConfirmationPropsType
): UseHookType<PaginatedResponseType<ConfirmationsSourcesType>> & CursorPaginationType {
    const {mainFilterKey} = props;
    const {result, setResult, isInProgress, setIsInProgress, setProcessError, processError} =
        useApiHooks<PaginatedResponseType<ConfirmationsSourcesType>>();

    const paginationDependencies = useMemo(() => ({mainFilterKey}), [mainFilterKey]);

    const pagination = useCursorPagination({
        dependencies: paginationDependencies,
        shouldSaveState: false,
    });

    const {page, pageSize, onDataLoaded} = pagination;

    const getConfirmations = useCallback(() => {
        setIsInProgress(true);

        loadConfirmations(mainFilterKey, {page, count: pageSize})
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, mainFilterKey, page, pageSize, setProcessError, onDataLoaded, setResult]);

    useEffect(() => {
        getConfirmations();
    }, [getConfirmations, mainFilterKey]);

    return {
        result,
        isInProgress,
        processError,
        pagination,
        reset: getConfirmations,
    };
}

export function useGoogleConfirmationMethods(): GoogleConfirmationMethodsHookType {
    const {result, setResult, isInProgress, setIsInProgress, setProcessError, processError} =
        useApiHooks<Array<GoogleConfirmationMethodType>>();

    const loadConfirmationMethodsByHash = useCallback(
        (companyHash: string) => {
            setIsInProgress(true);
            loadGoogleConfirmationMethods(companyHash)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError, setResult]
    );

    const loadVerificationMethods = useCallback(
        (options: GoogleVerificationMethodsApiOptionsType) => {
            loadGoogleVerificationMethods(options)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
            setIsInProgress(true);
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {
        result,
        isInProgress,
        processError,
        loadVerificationMethods,
        loadConfirmationMethodsByHash,
    };
}
