import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';

import {CompanyGroupType, PartialCompanyGroupType} from './company-group-type';

// https://cp.rocketdata.io/op/api/v1/company_groups/?count=50&o=title&page=1
export function getCompanyGroup(
    option: RequestOptionsType,
    mainFilterKey: string
): Promise<PaginatedResponseType<CompanyGroupType>> {
    return fetchX<PaginatedResponseType<CompanyGroupType>>(
        rootApiUrl +
            '/v1/company_groups/?' +
            objectToUrlParameters({
                ...option,
                filter_key: mainFilterKey,
            })
    );
}

export function getCompanyGroupById(companyGroupId: string | number): Promise<CompanyGroupType> {
    return fetchX<CompanyGroupType>(rootApiUrl + `/v1/company_groups/${companyGroupId}/`);
}

export function postCompanyGroup(company: PartialCompanyGroupType): Promise<CompanyGroupType> {
    return fetchX<CompanyGroupType>(rootApiUrl + '/v1/company_groups/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(company),
    });
}

export function putCompanyGroupById(
    companyGroupId: string | number,
    company: CompanyGroupType
): Promise<CompanyGroupType> {
    return fetchX<CompanyGroupType>(rootApiUrl + `/v1/company_groups/${companyGroupId}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(company),
    });
}

export function deleteCompanyGroupById(companyGroupId: number): Promise<CompanyGroupType> {
    return fetchX(rootApiUrl + `/v1/company_groups/${companyGroupId}/`, {
        method: FetchMethodEnum.delete,
        headers: getCsrfHeaders(),
    });
}
