import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {ReviewsPopularTagType} from './reviews-popular-tag-type';
import {fetchReviewsPopularTags} from './reviews-popular-tags-api';

export function useReviewsPopularTagsStats(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<Array<ReviewsPopularTagType>> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['reviewsPopularTags', filter, mainFilterKey],
        queryFn: async () => {
            return fetchReviewsPopularTags(filter, mainFilterKey);
        },
        cacheTime: 0,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}
