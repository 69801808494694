import {Button} from 'antd';

import {useLicenses} from '../../../provider/license/license-hook';
import {Locale} from '../../../provider/locale/localization';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {Text} from '../../text/text';

import {LandingOnboardingStep} from './landing-onboarding-step';
import * as styles from './landing-onboarding-step.scss';

type PropsType = {
    titleLangKeyType: LangKeyType;
    descriptionLangKeyType: LangKeyType;
};

export function LandingCongratulationStep(props: PropsType): JSX.Element {
    const {titleLangKeyType, descriptionLangKeyType} = props;

    const {refetch: refetchLicenses, isLoading: isLoadingLicenses} = useLicenses();

    return (
        <LandingOnboardingStep
            arrowButtonProps={{
                hidden: true,
            }}
        >
            <Text block className={styles.LandingOnboardingStep_congratulationImage}>
                🎉
            </Text>
            <Text block className={styles.LandingOnboardingStep_title} stringKey={titleLangKeyType} />
            <Text block lighter stringKey={descriptionLangKeyType} />

            <Button disabled={isLoadingLicenses} loading={isLoadingLicenses} onClick={refetchLicenses} type="primary">
                <Locale stringKey="BUTTON__NAVIGATE_TO_SERVICE" />
            </Button>
        </LandingOnboardingStep>
    );
}
