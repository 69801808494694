import {castArray} from 'lodash';
import {useCallback, useMemo, useState} from 'react';

import {AddButton} from '../../../../../../layout/add-button/add-button';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum, CompanyErrorsType, FieldModeEnum} from '../../../../../../service/company/company-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {getRandomString} from '../../../../../../util/string';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import {InputString} from './input-string/input-string';
import {InputStringType} from './input-string/input-string-type';
import {ValidatePhoneNumberType} from './phones-form-item-type';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.phones> & {
    containerClassName?: string;
    addButtonClassName?: string;
    hasError?: boolean;
    inputClassName?: string;
    mode?: FieldModeEnum;
    serverError?: CompanyErrorsType['phones'];
    helpText?: JSX.Element;
};

export function PhonesFormItem(props: PropsType): JSX.Element {
    const {
        value: phones,
        onChange,
        containerClassName,
        addButtonClassName,
        hasError,
        mode = FieldModeEnum.replacement,
        inputClassName,
        serverError,
        errorMessage,
        helpText,
    } = props;

    const {validatePhoneNumber} = useFormRules();
    const {getLocalizedString} = useLocale();
    const isDisabledAddButton = useMemo(() => {
        return phones[0]?.value.length === 0;
    }, [phones]);

    const [errors, setErrors] = useState<ValidatePhoneNumberType>({});
    const handleChange = useCallback(
        (itemList: Array<InputStringType>) => {
            const validateErrors: Record<string, boolean> = {};

            itemList.forEach((item: InputStringType) => {
                const {value, id} = item;

                if (value.length > 0) {
                    try {
                        const isValid = validatePhoneNumber(value);

                        validateErrors[id] = !isValid;
                    } catch {
                        validateErrors[id] = true;
                    }
                }
            });

            setErrors(validateErrors);
            onChange(itemList);
        },
        [onChange, validatePhoneNumber]
    );

    // TODO: after new design and refactoring there should be only one 'errorMessage' prop
    function getErrorMessages(index: number): Array<string> {
        if (serverError && serverError[index]) {
            return serverError[index]?.phone || [];
        }

        if (errorMessage && errorMessage[index]) {
            return castArray(errorMessage[index]) || [];
        }

        return [];
    }

    return (
        <div className={containerClassName}>
            {phones.map((phone: InputStringType, index: number): JSX.Element => {
                const errorMessages = getErrorMessages(index);

                return (
                    <InputString
                        additionalInfo={{
                            title: <Locale stringKey="CONTACTS__INFO__MAIN_PHONE_NUMBER" />,
                            content: <Locale stringKey="CONTACTS__INFO__MAIN_PHONE_NUMBER__CONTENT" />,
                        }}
                        addonBefore=""
                        className={inputClassName}
                        errorMessages={errorMessages}
                        hasError={(index === 0 && hasError) || errorMessages.length > 0}
                        hasMainItem={mode === FieldModeEnum.replacement}
                        hasPhoneValidateError={errors[phone.id]}
                        helpText={index === 0 ? helpText : null}
                        isRequired
                        item={phone}
                        itemList={phones}
                        key={phone.id}
                        placeholder={getLocalizedString('CONTACTS__PLACEHOLDER__PHONE_NUMBERS')}
                        renderRemovedItemText={(value) => (
                            <Locale stringKey="CONTACTS__INFO__REMOVED_PHONE" valueMap={{value}} />
                        )}
                        setItemList={handleChange}
                    />
                );
            })}

            <AddButton
                className={addButtonClassName}
                isDisabled={isDisabledAddButton}
                onClick={() => onChange([...phones, {value: '', id: getRandomString()}])}
                type="link"
            >
                <Locale stringKey="BUTTON__ADD" />
            </AddButton>
        </div>
    );
}
