import {classNames} from '../../util/css';

import * as styles from './logo-animated.scss';

type PropsType = {
    size?: number;
    withPulsation?: boolean;
};

export function LogoAnimated(props: PropsType): JSX.Element {
    const {size = 50, withPulsation} = props;

    return (
        <svg
            className={classNames('rd-logo', {
                [styles.LogoAnimated__withPulsation]: withPulsation,
            })}
            style={{width: `${size}px`}}
            version="1.1"
            viewBox="0 0 35 34"
            x="0px"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
        >
            <clipPath id="red-mask">
                <path
                    d="M30.7,0.1L30.7,0.1L2,9.3C1.7,9.4,1.3,9.6,1,9.8s-0.5,0.5-0.7,0.9C0.1,11,0,11.4,0,11.7c0,0.4,0,0.7,0.1,1.1
    c0.1,0.4,0.3,0.7,0.6,1s0.6,0.5,0.9,0.7s0.7,0.3,1.1,0.3s0.8,0,1.1-0.2L27,7.1L30.7,0.1z"
                />
            </clipPath>

            <clipPath id="orange-mask">
                <path
                    d="M21.7,22.5c1.3,2.2,3,8.7,3,8.7l9.5-27.6c0.2-0.7,0.2-1.5-0.2-2.1c-0.3-0.7-0.9-1.2-1.7-1.4
    c-0.7-0.2-1.5-0.2-2.2,0.1s-1.2,0.9-1.4,1.6L21.7,22.5z"
                />
            </clipPath>

            <clipPath id="blue-mask">
                <path d="M24.8,29.6c-2-7.3-6.9-13.6-13.7-17.4l-9.1,2.3c0.1,0,0.2,0.1,0.3,0.1c4.1,1,7.8,3.1,10.8,6c3,2.9,5.1,6.5,6.2,10.4c0.1,0.5,0.4,0.9,0.7,1.2c0.4,0.4,1,0.7,1.6,0.8c0.6,0.1,1.2,0,1.8-0.3c0.5-0.3,1-0.8,1.2-1.3  C24.9,30.8,24.9,30.2,24.8,29.6L24.8,29.6z" />
            </clipPath>

            <path
                className={styles.blue}
                clipPath="url(#blue-mask)"
                d="M22,30.3c-0.7-2.4-2.1-6.1-5.2-9.8c-5.1-6-11.5-7.9-14-8.6"
            />
            <line className={styles.red} clipPath="url(#red-mask)" x1="1.9" x2="32.1" y1="12.2" y2="2.5" />
            <line className={styles.orange} clipPath="url(#orange-mask)" x1="31.8" x2="21.5" y1="1.7" y2="31.7" />
        </svg>
    );
}
