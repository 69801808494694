import {Button, Form, Input, Select} from 'antd';
import {useCallback, useEffect, useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {
    fetchTagCreate,
    fetchTagUpdate,
    TagDataType,
    TagGroupType,
    useTagGroupList,
} from '../../../../service/reviews/reviews-tags';
import {classNames} from '../../../../util/css';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {tagTypeCommand} from '../tags-table/tags-table-const';

import * as styles from './tag-form.scss';

const {Option} = Select;

type PropsType = {
    mode: 'edit' | 'create';
    className?: string;
    tagData: TagDataType;
};

export function TagForm(props: PropsType): JSX.Element {
    const {className, mode, tagData} = props;
    const [form] = Form.useForm();
    const isCreate = mode === 'create';
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {data: tagGroupListResult} = useTagGroupList();
    const withoutGroup = 'withoutGroup';

    const {requiredFieldRule, maxLengthFieldRule, minLengthFieldRule} = useFormRules();

    const availableTagGroupList: Array<JSX.Element> = useMemo((): Array<JSX.Element> => {
        const optionList: Array<JSX.Element> = (tagGroupListResult || []).map((tagGroup: TagGroupType): JSX.Element => {
            return (
                <Option key={String(tagGroup.id)} value={String(tagGroup.id)}>
                    {tagGroup.title}
                </Option>
            );
        });

        optionList.push(
            <Option key="" value={withoutGroup}>
                <Locale stringKey="TEXT__WITHOUT_GROUP" />
            </Option>
        );

        return optionList;
    }, [tagGroupListResult]);
    const {pushUrl} = useUrl();

    useEffect(() => {
        form.resetFields(['title', 'folder', 'type']);
    }, [tagData, form]);

    const updateTag = useCallback(
        async (data: TagDataType, id: number) => {
            const result = await fetchTagUpdate(data, id);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__UPDATING_ERROR" />);
            } else {
                snackbar.success({
                    key: 'saved-successfully',
                    message: <Locale stringKey="TEXT__SAVED" />,
                    description: (
                        <Locale
                            stringKey="TAGS__FORM__THE_TAG_WAS_SAVED_SUCCESSFULLY"
                            valueMap={{
                                tagTitle: data.title,
                            }}
                        />
                    ),
                });
                pushUrl(appRoute.reviewsManagementTags.path);
            }
        },
        [snackbar, pushUrl]
    );

    const onFinishSuccess = useCallback(async () => {
        const folder = form.getFieldValue('folder');
        const title = form.getFieldValue('title');
        const data = {
            ...tagData,
            title,
            folder: folder !== withoutGroup ? {id: folder, title: ''} : null,
            type: tagTypeCommand,
        };

        if (isCreate) {
            const tagGroupTitle =
                tagGroupListResult && tagGroupListResult.find((tagGroup) => folder && tagGroup.id === Number(folder));

            const result = await fetchTagCreate(data);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__CREATING_ERROR" />);
            } else {
                snackbar.success({
                    key: 'saved-successfully',
                    message: <Locale stringKey="TEXT__SAVED" />,
                    description: (
                        <Locale
                            stringKey="TAGS__FORM__THE_TAG_WAS_SAVED_SUCCESSFULLY"
                            valueMap={{
                                tagTitle: title,
                            }}
                        />
                    ),
                });
                track(AnalyticsTarget.ReviewsManagement.Tags.CreateTag, tagGroupTitle?.title || withoutGroup);

                pushUrl(appRoute.reviewsManagementTags.path);
            }
        } else {
            updateTag(data, tagData.id);
        }
    }, [form, tagData, isCreate, tagGroupListResult, snackbar, pushUrl, updateTag]);

    function onFinishFailed() {
        snackbar.error(<Locale stringKey="TAGS__EMPTY_ERROR" />);
    }

    return (
        <Form
            className={classNames(styles.tag_form, className)}
            form={form}
            layout="vertical"
            name="tag"
            onFinish={onFinishSuccess}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                initialValue={tagData.title}
                label={<Locale stringKey="TAGS__TAG_FORM_TITLE" />}
                name="title"
                rules={[requiredFieldRule, minLengthFieldRule(3), maxLengthFieldRule(100)]}
            >
                <Input placeholder={getLocalizedString('TAGS__TAG_FORM__ENTER_A_NAME_FOR_THE_TAG')} size="large" />
            </Form.Item>
            <Form.Item
                initialValue={tagData.folder ? tagData.folder.id.toString() : withoutGroup}
                label={<Locale stringKey="TAGS__GROUP" />}
                name="folder"
            >
                <Select<string> filterOption={() => true} size="large">
                    {availableTagGroupList}
                </Select>
            </Form.Item>

            <div className={styles.tag_form__button_list}>
                <Button htmlType="submit" type="primary">
                    <Locale stringKey="BUTTON__SAVE" />
                </Button>
                <Button htmlType="reset">
                    <NavigationLink to={appRoute.reviewsManagementTags.path}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </NavigationLink>
                </Button>
            </div>
        </Form>
    );
}
