import {Progress} from 'antd';

import {useFormat} from '../../../../../../../../util/format-hook/format-hook';

import {MediaCard} from './media-card';

type PropsType = {
    progress?: number;
};

export function MediaCardLoading(props: PropsType): JSX.Element {
    const {progress} = props;

    const {getFormattedNumber} = useFormat();

    return (
        <MediaCard>
            <Progress
                format={() =>
                    getFormattedNumber(progress || 0, {
                        maximumFractionDigits: 0,
                    }) + '%'
                }
                percent={progress}
                type="circle"
            />
        </MediaCard>
    );
}
