import {faEllipsisVertical, faPenToSquare, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Flex, Space} from 'antd';
import {useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';
import {
    fetchNotesDelete,
    NoteFormType,
    ReviewNoteUserType,
    useNotesUpdateMutation,
} from '../../../../../../service/reviews/reviews-notes';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {NoteModal} from '../../review-list/review/review-header/note-modal/note-modal';

import ScrappyImgSrc from './common/scrappy.png';
import * as styles from './new-note.scss';

type PropsType = {
    id: number;
    reviewId: number;
    text: string;
    createdAt: string;
    updatedAt?: string | null;
    user: ReviewNoteUserType;
    onNoteEdit?: () => void;
    isLogsMode?: boolean;
};

export function NewNote(props: PropsType): JSX.Element | null {
    const {id, reviewId, text, createdAt, updatedAt, user, onNoteEdit} = props;
    const {getFormattedDateTime} = useFormat();
    const {modal} = useModal();
    const {getLocalizedString} = useLocale();
    const {mutateAsync, isLoading} = useNotesUpdateMutation();
    const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);

    const {companyName} = useDomainConfig();

    const formattedDate = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;

    const formattedUpdatedDate = updatedAt
        ? getFormattedDateTime(new Date(updatedAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;

    const {snackbar} = useSnackbar();

    async function onSubmitEditNote(formValues: NoteFormType) {
        return mutateAsync(
            {text: (formValues.text ?? '').trim(), reviewId, noteId: id},
            {
                onSuccess: async () => {
                    setIsOpenNoteModal(false);

                    if (onNoteEdit) {
                        await onNoteEdit();
                    }
                },
            }
        );
    }

    async function deleteNote() {
        try {
            await fetchNotesDelete(reviewId, id);

            if (onNoteEdit) {
                await onNoteEdit();
            }

            snackbar.success({
                description: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_SUCCESS_DESCRIPTION" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_SUCCESS_TITLE" />,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.DeleteNote);
        } catch {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_FAIL" />,
            });
        }
    }

    function handleDeleteClick() {
        modal.confirm({
            className: styles.Note_modal,
            title: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_TITLE" />,
            content: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_QUESTION" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            okText: <Locale stringKey="REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_BUTTON" />,
            okButtonProps: {
                danger: true,
                type: 'primary',
            },
            onOk: deleteNote,
        });
    }

    return (
        <Flex className={styles.Note} gap={6} vertical>
            <img alt="" className={styles.Note_scrappyIcon} src={ScrappyImgSrc} />
            <Flex justify="space-between">
                <Space size={4} wrap>
                    <Locale stringKey="REVIEWS__ENTER_NOTE_TITLE" />
                    <Text
                        bolder
                        stringKey="REVIEWS__RESPONSE_EMPLOYEE"
                        valueMap={{
                            name: <span className={styles.Note_email}>{user?.email || companyName}</span>,
                        }}
                    />
                    <Space align="start" size={4}>
                        <Text secondary>•</Text>
                        <Text secondary>
                            {formattedUpdatedDate ? (
                                <Space size={4} wrap>
                                    <Locale stringKey="REVIEWS__ENTER_NOTE_EDITED" />
                                    {formattedUpdatedDate}
                                </Space>
                            ) : (
                                formattedDate
                            )}
                        </Text>
                    </Space>
                </Space>
                <Dropdown
                    menu={{
                        items: [
                            {
                                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                                label: getLocalizedString('REVIEWS__NOTE__EDIT'),
                                key: 'edit',
                                onClick: () => setIsOpenNoteModal(true),
                            },
                            {
                                icon: <FontAwesomeIcon icon={faTrash} />,
                                label: getLocalizedString('REVIEWS__NOTE__DELETE'),
                                key: 'delete',
                                onClick: handleDeleteClick,
                            },
                        ],
                    }}
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <Button type="text">
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Button>
                </Dropdown>
            </Flex>
            <Text>{text}</Text>
            {isOpenNoteModal && (
                <NoteModal
                    editableNoteText={text}
                    isLoading={isLoading}
                    onCancel={setIsOpenNoteModal}
                    onSubmit={onSubmitEditNote}
                    reviewId={reviewId}
                    title={<Locale stringKey="REVIEWS__NOTE__EDIT" />}
                />
            )}
        </Flex>
    );
}
