export function getRandomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function castToPercentage(value: number, max: number): number {
    return max ? (100 * value) / max : 0;
}

export function fromPercentage(value: number, max: number): number {
    return (value * max) / 100;
}

export function roundToPrecision(value: number, precision = 0): number {
    return Number(value.toFixed(precision));
}

export function calculatePercentageChange(options: {current: number; previous: number; precision?: number}): number {
    const {current, precision, previous = 0} = options;

    if (previous === 0) {
        return roundToPrecision(current === 0 ? 0 : 100, precision);
    }

    if (current === 0) {
        return roundToPrecision(previous === 0 ? 0 : -100, precision);
    }

    return roundToPrecision(((current - previous) / previous) * 100, precision);
}

export function getPercentagesDistribution<T extends Record<string, number>>(
    events: T,
    precision = 0
): Record<keyof T, number> {
    const accuracy = Math.pow(10, -precision);

    const totalEvents = Object.values(events).reduce((accumulator, current) => accumulator + current, 0);

    if (!totalEvents) {
        return Object.keys(events).reduce((accumulator, key) => {
            return {
                ...accumulator,
                [key]: 0,
            };
        }, {} as Record<keyof T, number>);
    }

    let totalPercentage = 0;

    const intermediateResult: Record<
        keyof T,
        {
            value: number;
            adjustment: number;
        }
    > = Object.entries(events).reduce(
        (accumulator, [key, value]) => {
            const percentage = (value / totalEvents) * 100;
            const roundedValue = roundToPrecision(percentage, precision);

            totalPercentage += roundedValue;
            return {
                ...accumulator,
                [key]: {
                    value: roundedValue,
                    adjustment: percentage - roundedValue,
                },
            };
        },
        {} as Record<
            keyof T,
            {
                value: number;
                adjustment: number;
            }
        >
    );

    const difference = roundToPrecision(totalPercentage - 100, precision);

    Array.from({length: Math.abs(difference / accuracy)}).forEach(() => {
        const keyWithMaxAdjustment = Object.keys(intermediateResult).sort((key1: keyof T, key2: keyof T) => {
            const adjustment1 = intermediateResult[key1].adjustment;
            const adjustment2 = intermediateResult[key2].adjustment;

            return difference > 0 ? adjustment1 - adjustment2 : adjustment2 - adjustment1;
        })[0];

        if (!keyWithMaxAdjustment) {
            return;
        }

        const intermediateValueByKey = intermediateResult[keyWithMaxAdjustment];

        if (intermediateValueByKey) {
            intermediateValueByKey.value = roundToPrecision(
                intermediateValueByKey.value + (intermediateValueByKey.adjustment > 0 ? accuracy : -accuracy),
                precision
            );
            intermediateValueByKey.adjustment += intermediateValueByKey.adjustment > 0 ? accuracy : -accuracy;
        }
    });

    return Object.keys(intermediateResult).reduce((accumulator, key) => {
        return {
            ...accumulator,
            [key]: intermediateResult?.[key]?.value,
        };
    }, {} as Record<keyof T, number>);
}
