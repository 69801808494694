import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchReviewsCountByCatalog} from './reviews-count-by-catalog-api';
import {ReviewsCountByCatalogType} from './reviews-count-by-catalog-type';

export function useReviewsCountByCatalog(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<ReviewsCountByCatalogType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['fetchReviewsCountByCatalog', filter, mainFilterKey],
        queryFn: async () => {
            return fetchReviewsCountByCatalog(filter, mainFilterKey);
        },
        refetchInterval: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });
}
