import {faCheckCircle, faClone, faTimesCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Space} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {useSystem} from '../../../../../../provider/system/system-hook';
import {FakeSubscriptionPlanEnum} from '../../../../../../service/fakes/fakes-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import * as styles from './fakes-license.scss';

type PropsType = {
    license?: FakeSubscriptionPlanEnum;
    expire?: string;
    isTrial?: boolean;
};

export function FakesLicense(props: PropsType): JSX.Element {
    const {license, expire, isTrial} = props;

    const {screen} = useSystem();

    const {getFormattedDateTime} = useFormat();

    return (
        <div className={styles.FakesLicense_plan}>
            <div className={styles.FakesLicense_firstBlock}>
                <FontAwesomeIcon className={styles.FakesLicense_dubsIcon} icon={faClone} />
                <Text className={styles.FakesLicense_planTitle} stringKey="FAKES__TITLE" />
                {isTrial ? (
                    <Text large secondary stringKey="FAKES__SETTINGS__PRODUCT_CONNECTED__TRIAL" />
                ) : (
                    <Text large secondary stringKey="FAKES__SETTINGS__PRODUCT_CONNECTED" />
                )}
            </div>
            <Divider className={styles.FakesLicense_divider} type={screen.isMobile ? 'horizontal' : 'vertical'} />
            <div className={styles.FakesLicense_secondBlock}>
                <Space direction="vertical">
                    <div className={styles.FakesLicense_fakesDescriptionWrapper}>
                        {license !== FakeSubscriptionPlanEnum.Duplicates ? (
                            <FontAwesomeIcon className={styles.FakesLicense_checkIcon} icon={faCheckCircle} />
                        ) : (
                            <FontAwesomeIcon className={styles.FakesLicense_timesIcon} icon={faTimesCircle} />
                        )}
                        <div className={styles.FakesLicense_fakesText}>
                            <Text bolder stringKey="FAKES__RESULTS__FILTERS__TYPE__FAKES" />
                            <Text lighter small stringKey="FAKES__SETTINGS__SEARCH_AND_REMOVE" />
                        </div>
                    </div>
                    <div className={styles.FakesLicense_fakesDescriptionWrapper}>
                        {license !== FakeSubscriptionPlanEnum.Fakes ? (
                            <FontAwesomeIcon className={styles.FakesLicense_checkIcon} icon={faCheckCircle} />
                        ) : (
                            <FontAwesomeIcon className={styles.FakesLicense_timesIcon} icon={faTimesCircle} />
                        )}
                        <div className={styles.FakesLicense_fakesText}>
                            <Text bolder stringKey="FAKES__SETTINGS__FEATURES__DUPLICATES" />
                            <Text lighter small stringKey="FAKES__SETTINGS__SEARCH_AND_REMOVE" />
                        </div>
                    </div>
                </Space>
                {expire && (
                    <div className={styles.FakesLicense_licenseDate}>
                        <Text
                            lighter
                            small
                            stringKey={isTrial ? 'FAKES__SETTINGS__LICENSE__TRIAL' : 'FAKES__SETTINGS__LICENSE'}
                            valueMap={{
                                date: (
                                    <Text bolder small>
                                        {getFormattedDateTime(new Date(expire))}
                                    </Text>
                                ),
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
