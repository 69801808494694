import {z as r} from 'zod';

import {WeekDaysEnum} from '../../../provider/locale/locale-context-const';
import {CompanyKeyEnum} from '../company-const';

const timeRangeSchema = r.object({
    start: r.string().optional(),
    finish: r.string().optional(),
});

export type TimeRangeType = r.infer<typeof timeRangeSchema>;

const workingDaySchema = r.object({
    daily: r.boolean(),
    timeRange: timeRangeSchema,
    breaks: r.array(timeRangeSchema),
});

export type WorkingDayType = r.infer<typeof workingDaySchema>;

const workingHoursSchema = r.object({
    day1: workingDaySchema.nullable(),
    day2: workingDaySchema.nullable(),
    day3: workingDaySchema.nullable(),
    day4: workingDaySchema.nullable(),
    day5: workingDaySchema.nullable(),
    day6: workingDaySchema.nullable(),
    day7: workingDaySchema.nullable(),
});

export type WorkingDaysType = r.infer<typeof workingHoursSchema>;

const extraWorkingHoursSchema = r.object({
    catalogId: r.number(),
    workingHours: workingHoursSchema,
});

const specialHoursSchema = r.object({
    startDate: r.string(),
    endDate: r.string(),
    timeRange: timeRangeSchema,
    breaks: r.array(timeRangeSchema),
    isHoliday: r.boolean(),
    daily: r.boolean().nullable(),
});

export type SpecialHoursType = r.infer<typeof specialHoursSchema>;

export const companyWorkingTimeSchema = {
    [CompanyKeyEnum.TemporaryClosed]: r.boolean(),
    [CompanyKeyEnum.WorkingHours]: workingHoursSchema,
    [CompanyKeyEnum.ExtraWorkingHours]: r.array(extraWorkingHoursSchema),
    [CompanyKeyEnum.SpecialHours]: r.array(specialHoursSchema),
};

const companyWorkingTimeSchemaObject = r.object(companyWorkingTimeSchema);

export type CompanyWorkingTimeType = r.infer<typeof companyWorkingTimeSchemaObject>;

export type CompanyWorkingTimeServerType = CompanyWorkingTimeType;

export type WeekdayType = WorkingDayType & {
    daysOfWeek: Array<WeekDaysEnum>;
};
