import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, MenuProps, Space} from 'antd';
import {useMemo} from 'react';

import {Locale} from '../../../../provider/locale/locale';

type PropsType = {
    onActivateRequest: (enabled: boolean) => void;
    onDelete: () => void;
    isActive: boolean;
    className?: string;
};

export function ListOfRulesActionSelect(props: PropsType): JSX.Element {
    const {isActive, className, onDelete, onActivateRequest} = props;

    const actionMenuItems: MenuProps['items'] = useMemo(
        () => [
            {
                key: 'on',
                label: <Locale stringKey="BUTTON__TURN_ON_SELECTED" />,
                onClick: () => onActivateRequest(true),
            },
            {
                key: 'off',
                label: <Locale stringKey="BUTTON__TURN_OFF_SELECTED" />,
                onClick: () => onActivateRequest(false),
            },
            {
                key: 'delete',
                label: <Locale stringKey="BUTTON__DELETE_SELECTED" />,
                onClick: () => onDelete(),
            },
        ],
        [onDelete, onActivateRequest]
    );

    return (
        <Dropdown disabled={!isActive} menu={{items: actionMenuItems}}>
            <Button className={className}>
                <Space>
                    <Locale stringKey="BUTTON__ACTIONS" />
                    <FontAwesomeIcon icon={faChevronDown} size="sm" />
                </Space>
            </Button>
        </Dropdown>
    );
}
