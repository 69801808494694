export const enGroupOfCompaniesTranslations = {
    GROUP_OF_COMPANIES__SUB_HEADER: 'Group companies for quick access from the site header.',
    GROUP_OF_COMPANIES__BUTTON__ADD_GROUP: 'Add group',
    GROUP_OF_COMPANIES__TABLE__NAME: 'Name',
    GROUP_OF_COMPANIES__TABLE__COMPANIES: 'Companies',
    GROUP_OF_COMPANIES__TABLE__ACTION: 'Action',
    GROUP_OF_COMPANIES__TABLE_BUTTON__MANAGE_A_GROUP: 'Manage a group',
    GROUP_OF_COMPANIES__DELETE_CONFIRM__TITLE: 'Delete group',
    GROUP_OF_COMPANIES__DELETE_CONFIRM__CONTENT: 'The "{title}" group will be removed. Are you sure?',
    GROUP_OF_COMPANIES__DELETE__SUCCESSFULLY_DELETED: 'Group successfully removed!',
    GROUP_OF_COMPANIES__DELETE__ERROR: 'Failed to remove group',
    GROUP_OF_COMPANIES__GROUP_MANAGEMENT__SUB_HEADER:
        'Group companies for quick filtering of personal account pages for the selected group',
    GROUP_OF_COMPANIES__GROUP_MANAGEMENT__COMPANIES_WITHOUT_A_GROUP: 'Companies without a group',
    GROUP_OF_COMPANIES__GROUP_MANAGEMENT__COMPANIES_IN_THE_GROUP: 'Companies in the group: {count}',
    GROUP_OF_COMPANIES__GROUP_MANAGEMENT__NAME_OR_ADDRESS: 'Name or address',
    GROUP_OF_COMPANIES__GROUP_MANAGEMENT__GROUP_NAME_LABEL: 'Group name',
    GROUP_OF_COMPANIES__GROUP_MANAGEMENT__ALL_BRANDS: 'All brands',
    GROUP_OF_COMPANIES__CREATE__GROUP_MANAGEMENT: 'Group management',
    GROUP_OF_COMPANIES__CREATE__HEADER: 'Creation of a new group of companies',
    GROUP_OF_COMPANIES__CREATE__SUCCESSFULLY_CREATED: 'The group “{title}” has been successfully created!',
    GROUP_OF_COMPANIES__CREATE__SUCCESSFULLY_CREATED_DESCRIPTION:
        'The group “{title}” has been created and will be available in the filter in the profile header.',
    GROUP_OF_COMPANIES__CREATE__ERROR_CREATE: 'Failed to create group!',
    GROUP_OF_COMPANIES__EDIT__GROUP_MANAGEMENT: 'Group management',
    GROUP_OF_COMPANIES__EDIT__HEADER: 'Group management',
    GROUP_OF_COMPANIES__EDIT__SUCCESSFULLY_UPDATED: 'Group updated successfully!',
    GROUP_OF_COMPANIES__EDIT__ADDITIONAL_INFO__TITLE: 'Another {count} companies without access',
    GROUP_OF_COMPANIES__EDIT__ADDITIONAL_INFO__POPOVER_TITLE: 'No access to companies',
    GROUP_OF_COMPANIES__EDIT__ADDITIONAL_INFO__POPOVER_INFO: 'You can only manage companies you have access to.',
};
