import {z as r} from 'zod';

import {generateResponseSchema} from '../api/api-type';
import {companySelectorCompanySchema} from '../feature-companies/feature-companies-api-type';
import {CustomFileType, UploadFileResponseType} from '../file-upload/file-upload-type';

export enum YandexStoryStatusEnum {
    Published = 'published',
    Moderation = 'moderation',
    Rejected = 'rejected',
    Draft = 'draft',
    Sent = 'sent',
    InProgress = 'in_progress',
    Deleted = 'deleted',
}

export enum YandexStoryTypeEnum {
    Story = 'story',
    Navigation = 'navigation',
}

const yandexShortStorySchema = r.object({
    id: r.number(),
    name: r.string(),
    createdAt: r.string(),
    updatedAt: r.string().optional(),
    status: r.nativeEnum(YandexStoryStatusEnum),
    type: r.nativeEnum(YandexStoryTypeEnum),
    cover: r.object({
        id: r.string(),
        url: r.string(),
    }),
});

export const yandexStorySchema = r.object({
    id: r.number(),
    name: r.string(),
    createdAt: r.string(),
    updatedAt: r.string(),
    slides: r
        .object({
            image: r.object({
                id: r.string(),
                url: r.string(),
            }),
            buttonConfig: r
                .object({
                    text: r.string(),
                    link: r.string(),
                })
                .nullable(),
            order: r.number(),
        })
        .array(),
    status: r.nativeEnum(YandexStoryStatusEnum),
    hasPermissionToEdit: r.boolean(),
    companiesCount: r.number(),
    type: r.nativeEnum(YandexStoryTypeEnum),
    cover: r.object({
        id: r.string(),
        url: r.string(),
    }),
});

export const yandexStoriesResponseSchema = generateResponseSchema(yandexShortStorySchema);

export const yandexSourcesStoriesResponseSchema = r.object({
    companyId: r.string(),
    canAddStories: r.boolean(),
    storyInfo: yandexShortStorySchema.array(),
});

export type YandexStoryType = r.infer<typeof yandexStorySchema>;

export type YandexStoriesResponseType = r.infer<typeof yandexStoriesResponseSchema>;

const yandexStorySelectedCompanySchema = r.object({
    id: r.number(),
    status: r.nativeEnum(YandexStoryStatusEnum),
    name: r.string(),
    address: r.string().nullable(),
    catalogLink: r.string().nullable(),
    companyCode: r.string().optional(),
});

export type YandexStorySelectedCompanyType = r.infer<typeof yandexStorySelectedCompanySchema>;

export const yandexStorySelectedCompanies = generateResponseSchema(yandexStorySelectedCompanySchema);

export type YandexStorySelectedCompaniesType = r.infer<typeof yandexStorySelectedCompanies>;

export enum YandexStoryFormFieldsEnum {
    Name = 'name',
    Slides = 'slides',
    Type = 'type',
    CompaniesSelector = 'selectorId',
    SameButtonOnEachSlide = 'sameButtonOnEachSlide',
    Cover = 'cover',
}

export type YandexStorySlideType = CustomFileType &
    UploadFileResponseType & {
        order: number;
        buttonConfig: {
            text: string;
            link: string;
        } | null;
    };

export type YandexStoryFileUploaderType = Record<string, YandexStorySlideType>;

export type YandexStoryFormType = {
    [YandexStoryFormFieldsEnum.Name]: string;
    [YandexStoryFormFieldsEnum.Slides]: YandexStoryFileUploaderType;
    [YandexStoryFormFieldsEnum.Type]: YandexStoryTypeEnum;
    [YandexStoryFormFieldsEnum.CompaniesSelector]: string;
    [YandexStoryFormFieldsEnum.SameButtonOnEachSlide]: boolean;
    [YandexStoryFormFieldsEnum.Cover]: UploadFileResponseType | null;
};

export type YandexShortStoryType = r.infer<typeof yandexShortStorySchema>;

export type YandexSourcesStoriesResponseType = r.infer<typeof yandexSourcesStoriesResponseSchema>;

export type UseYandexPreviewHookType = {
    activeStoryId: number | null;
    nextStoryId: number | null;
    previousStoryId: number | null;
    onChangeStoryId: (storyId: number | null) => void;
};

export const yandexAvailableCompaniesSchema = r.object({
    totalCount: r.number(),
    availableForYandexCount: r.number(),
});

export type YandexAvailableCompaniesType = r.infer<typeof yandexAvailableCompaniesSchema>;

export const uploadStoryImageResponseSchema = r.object({
    id: r.string(),
    url: r.string(),
});

export type UploadStoryImageResponseType = r.infer<typeof uploadStoryImageResponseSchema>;

export type CreateYandexStoryRequestType = {
    name: string;
    cover: UploadStoryImageResponseType;
    slides: Array<{
        image: UploadStoryImageResponseType;
        order: number;
        buttonConfig: {
            text: string;
            link: string;
        } | null;
    }>;
    type: YandexStoryTypeEnum;
    selectorId: string;
};

export enum YandexSelectorCatalogStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    NeedAccount = 'need_account',
}

export const storiesCompanySelectorCompanySchema = companySelectorCompanySchema.merge(
    r.object({
        brandId: r.number(),
        catalogs: r.array(
            r.object({
                id: r.number(),
                name: r.string(),
                status: r.nativeEnum(YandexSelectorCatalogStatusEnum),
                website: r.string(),
            })
        ),
    })
);

type IsInProgressType = {
    isInProgress: boolean;
};

export type StoriesCompanySelectorCompanyType = r.infer<typeof storiesCompanySelectorCompanySchema> & IsInProgressType;
