import {LangKeyType} from '../../../../../../provider/locale/translation/type';

export const enum TextPluralEnum {
    companies = 'companies',
    brands = 'brands',
}

export const MAX_NUMBER_LIST_ITEM = 5;

type TextPluralMapType = Record<
    TextPluralEnum,
    {
        fewKey: LangKeyType;
        manyKey: LangKeyType;
        singularKey: LangKeyType;
    }
>;

export const textPluralMap: TextPluralMapType = {
    companies: {
        fewKey: 'SOURCE_SETTINGS__ACCOUNTS__TAG__COMPANIES__FEW',
        manyKey: 'SOURCE_SETTINGS__ACCOUNTS__TAG__COMPANIES__MANY',
        singularKey: 'SOURCE_SETTINGS__ACCOUNTS__TAG__COMPANIES__SINGULAR',
    },
    brands: {
        fewKey: 'SOURCE_SETTINGS__ACCOUNTS__TAG__BRANDS__FEW',
        manyKey: 'SOURCE_SETTINGS__ACCOUNTS__TAG__BRANDS__MANY',
        singularKey: 'SOURCE_SETTINGS__ACCOUNTS__TAG__BRANDS__SINGULAR',
    },
};

export const textPluralPopoverMap: TextPluralMapType = {
    companies: {
        fewKey: 'SOURCE_SETTINGS__ACCOUNTS__POPOVER__COMPANIES__FEW',
        manyKey: 'SOURCE_SETTINGS__ACCOUNTS__POPOVER__COMPANIES__MANY',
        singularKey: 'SOURCE_SETTINGS__ACCOUNTS__POPOVER__COMPANIES__SINGULAR',
    },
    brands: {
        fewKey: 'SOURCE_SETTINGS__ACCOUNTS__POPOVER__BRANDS__FEW',
        manyKey: 'SOURCE_SETTINGS__ACCOUNTS__POPOVER__BRANDS__MANY',
        singularKey: 'SOURCE_SETTINGS__ACCOUNTS__POPOVER__BRANDS__SINGULAR',
    },
};
