import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../../../../../../util/api-adapter';

const userGroupCompaniesSchema = r.array(
    r.object({
        id: r.number(),
        name: r.string(),
        isAvailable: r.boolean().optional(),
    })
);

type UserGroupCompaniesType = r.infer<typeof userGroupCompaniesSchema>;

export function useUserGroupCompanies(editUserId?: number): UseQueryResult<UserGroupCompaniesType> {
    const userAvailableBrandsUrl = editUserId
        ? `/cp/user_management/edit_users/${editUserId}/company_groups`
        : '/cp/user_management/company_groups';

    return useQuery(['user-management-company-groups', editUserId], () =>
        fetchAndDeserialize(userAvailableBrandsUrl, userGroupCompaniesSchema)
    );
}
