import {FC} from 'react';

import {NotificationTabKeyEnum} from '../../page/main/notification/notification-type';
import {StoreLocatorLanguageEnum} from '../../service/store-locator-config/store-locator-config-short-type';
import {FeaturesEnum} from '../../service/user/user-type';
import {ProvidersIdsEnum} from '../../util/type';
import {LocaleNameEnum, ShortLocaleNameEnum} from '../locale/locale-context-type';

export enum DomainNameEnum {
    rocketData = 'rocketdata.io',
    twoGis = 'twoGis', // check why 'presence.2gis.com'
    brandWizard = 'go.brandwizard.io',
}

export const enum HeaderVisibilityOptionsEnum {
    VISIBLE = 'visible',
    HIDDEN = 'hidden',
    HIDDEN_FOR_SINGLE_COMPANY = 'hidden_for_single_company',
}

export type DomainConfigType = Readonly<{
    basename: string;
    companyName: string;
    domainName: DomainNameEnum | string;
    LogoFull: FC<{className?: string}>;
    logoFullSrc: string;
    LogoShort: FC<{className?: string}>;
    logoShortSrc: string;
    faviconSrc: string;
    LogoBreadcrumbs: FC<{className?: string}> | null;
    onLoad: () => Promise<void>;
    onEnter: () => Promise<void>;
    isFreshChatShow: boolean;
    isNotificationShow: boolean;
    isIdeaButtonShow: boolean;
    isLogoutShow: boolean;
    isReportCompaniesShow: boolean;
    isUserProfileShow: boolean;
    isFooterShow: boolean;
    isWhiteLabel: boolean;
    headerVisibility: HeaderVisibilityOptionsEnum;
    canCreateCompany: boolean;
    isDomainFeatureEnabled: Record<FeaturesEnum, boolean> & {
        integrations: boolean;
    };
    hiddenCompanyFields: Partial<Record<keyof CompanyFieldsType, boolean>>;
    canFoldDesktopMenu: boolean;
    isPageWrapperHasPaddings: boolean;
    isLanguageSelectorVisible: boolean;
    isDynamicHelpLinksVisible: boolean;
    isSingleFeature: boolean;
    availableLocales: Array<LocaleNameEnum>;
    defaultLocale: LocaleNameEnum;
    isSourceSettingsShown: boolean;
    notificationTabs: Array<NotificationTabKeyEnum>;
    feedTypeLabels: Record<string, string> | null;
    feedTemplateLinks: Array<{name: string | undefined; link: string}>;
    storeLocatorLanguages: Array<StoreLocatorLanguageEnum>;
    mainCatalogs: Array<ProvidersIdsEnum>;
    isShowUsetifulButton: boolean;
    isShowYandexTransferAccountBanner: boolean;
    isShowTrialNeurlalinkInfo: boolean;
    isShowReviewSourcesBanner: boolean;
}>;

type CompanyFieldsType = {
    branchCode: string;
};

export type HelpDeskChatOptionsType = {
    email: string;
    firstName: string;
    locale: ShortLocaleNameEnum.ru | ShortLocaleNameEnum.en;
    brandIds?: Array<number>;
};
