import {Button, TreeSelect} from 'antd';
import {DefaultOptionType} from 'antd/lib/select';
import {ReactElement, useCallback, useEffect, useState} from 'react';

import {Locale} from '../../../../../../provider/locale/localization';
import {handleFilterOption} from '../../../../../../util/antd/select-helper';

import * as styles from './user-rules-select.scss';

type PropsType = {
    initialData?: Array<number>;
    options: Array<{value: number; label: string; disabled: boolean}>;
    isLoading: boolean;
    handleFormChange: (value: Array<number>) => void;
    selectValue: Array<number>;
    allSelectPlaceholder: ReactElement;
    placeholder: ReactElement;
};

export function UserRulesSelect(props: PropsType): ReactElement {
    const {initialData, options, isLoading, handleFormChange, selectValue, allSelectPlaceholder, placeholder} = props;
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    const handleSourceListChange = useCallback(
        (value: Array<number>) => {
            if (value.length === options.length) {
                setIsAllSelected(true);
            }

            if (value.length < options.length) {
                setIsAllSelected(false);
            }

            handleFormChange(value);
        },
        [handleFormChange, options]
    );

    const selectAll = useCallback(() => {
        setIsAllSelected(true);
        handleSourceListChange([
            ...selectValue,
            ...options
                .filter((source) => !source.disabled && !selectValue.includes(source.value))
                .map((item) => item.value),
        ]);
    }, [handleSourceListChange, options, selectValue]);

    const handleCancelButton = useCallback(() => {
        const disableCheckedOptions = options
            .filter((item) => item.disabled && selectValue.includes(item.value))
            .map((option) => option.value);

        handleSourceListChange(disableCheckedOptions);
        setIsAllSelected(false);
    }, [handleSourceListChange, options, selectValue]);

    useEffect(() => {
        if (
            Array.isArray(selectValue) &&
            selectValue.length === options.length &&
            options.length > 0 &&
            !isAllSelected
        ) {
            setIsAllSelected(true);
        }
    }, [isAllSelected, selectValue, selectValue?.length, options.length]);

    const dropdownRenderer = useCallback(
        (menu: JSX.Element) => {
            return (
                <div>
                    <header className={styles.ReviewsSources_header}>
                        <Button onClick={selectAll} size="small" type="link">
                            <Locale stringKey="TEXT__SELECT_ALL" />
                        </Button>
                    </header>
                    {menu}
                    <footer className={styles.ReviewsSources_footer}>
                        <Button onClick={handleCancelButton} size="small" type="text">
                            <Locale stringKey="TEXT__RESET" />
                        </Button>

                        <Button onClick={() => setIsOpenDropdown(false)} size="small" type="primary">
                            <Locale stringKey="TEXT__OK" />
                        </Button>
                    </footer>
                </div>
            );
        },
        [handleCancelButton, selectAll]
    );

    return (
        <TreeSelect
            className={styles.ReviewsSources}
            defaultValue={initialData}
            dropdownRender={dropdownRenderer}
            filterTreeNode={(input, filterTreeData) => handleFilterOption(input, filterTreeData as DefaultOptionType)}
            loading={isLoading}
            maxTagCount={isAllSelected ? 0 : 'responsive'}
            // eslint-disable-next-line no-undefined
            maxTagPlaceholder={isAllSelected ? allSelectPlaceholder : undefined}
            multiple
            onChange={handleSourceListChange}
            onDropdownVisibleChange={(open) => {
                setIsOpenDropdown(open);
            }}
            open={isOpenDropdown}
            placeholder={placeholder}
            treeCheckable
            treeData={options}
            treeDefaultExpandAll
            value={isLoading ? [] : selectValue}
        />
    );
}
