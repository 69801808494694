import {Form, Select} from 'antd';

import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {RatingMultiSelect} from '../../../../layout/rating-multi-select/rating-multi-select';
import {SelectCatalogOption} from '../../../../layout/select/select-catalog-option';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useUsedeskCatalogList} from '../../../../service/notifications-settings/notifications-settings-hook';
import {
    NotificationTextReviewEnum,
    UsedeskCatalogType,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {classNames} from '../../../../util/css';

import {notificationsReviewsFilterFields} from './notification-review-filter-const';
import {NotificationsReviewsFilterType} from './notification-review-filter-type';
import * as styles from './notification-review-filter.scss';

const {Option} = Select;

type PropsType = {
    fields: NotificationsReviewsFilterType;
    disabled: boolean;
    className?: string;
};

export function NotificationReviewFilter(props: PropsType): JSX.Element {
    const {fields, disabled, className} = props;
    const {getLocalizedString} = useLocale();

    const {result: catalogsResult, isInProgress: catalogIsInProgress} = useUsedeskCatalogList();
    const catalogList: Array<UsedeskCatalogType> = catalogsResult?.results || [];

    if (!fields) {
        return <Spinner position="absolute" />;
    }

    return (
        <div className={classNames(styles.NotificationReviewFilter, className)}>
            <PageSubHeader className={styles.NotificationReviewFilter_header}>
                <Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__HEADER" />
            </PageSubHeader>

            <Form.Item
                className={styles.NotificationReviewFilter_input}
                initialValue={fields.catalog}
                label={<Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__LABEL__FEEDBACK_SOURCE" />}
                name={notificationsReviewsFilterFields.catalog}
            >
                <Select<number>
                    disabled={disabled}
                    loading={catalogIsInProgress}
                    mode="multiple"
                    placeholder={getLocalizedString('NOTIFICATIONS__REVIEW_FILTER__PLACEHOLDER__FEEDBACK_SOURCE')}
                    showSearch={false}
                    size="large"
                >
                    {catalogList.map(SelectCatalogOption)}
                </Select>
            </Form.Item>

            <Form.Item
                className={styles.NotificationReviewFilter_input}
                initialValue={fields.ratings}
                label={<Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__LABEL__RATING" />}
                name={notificationsReviewsFilterFields.ratings}
            >
                <RatingMultiSelect disabled={disabled} />
            </Form.Item>

            <Form.Item
                className={styles.NotificationReviewFilter_input}
                initialValue={fields.type}
                label={<Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__LABEL__CONTENT_OF_REVIEWS" />}
                name={notificationsReviewsFilterFields.type}
            >
                <Select<NotificationTextReviewEnum>
                    disabled={disabled}
                    placeholder={getLocalizedString('NOTIFICATIONS__REVIEW_FILTER__PLACEHOLDER__CONTENT_OF_REVIEWS')}
                    size="large"
                >
                    <Option value={NotificationTextReviewEnum.all}>
                        <Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__ALL_REVIEWS" />
                    </Option>

                    <Option value={NotificationTextReviewEnum.withText}>
                        <Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__REVIEWS_WITH_TEXT" />
                    </Option>

                    <Option value={NotificationTextReviewEnum.withoutText}>
                        <Locale stringKey="NOTIFICATIONS__REVIEW_FILTER__REVIEWS_WITHOUT_TEXT" />
                    </Option>
                </Select>
            </Form.Item>
        </div>
    );
}
