import {faBars} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {SortableHandle as sortableHandle} from 'react-sortable-hoc';

import {classNames} from '../../../util/css';

import * as styles from './yandex-stories-sources.scss';

type PropsType = {
    disabled?: boolean;
};

export const DragHandle = sortableHandle<PropsType>(({disabled}: PropsType) => (
    <Button
        className={classNames(styles.YandexStoriesSources_sorter, {
            [styles.YandexStoriesSources_sorter__disabled]: disabled,
        })}
        icon={<FontAwesomeIcon icon={faBars} />}
        type="text"
    />
));
