import {LegalAgreementTypeEnum} from '../../layout/legal-agreement/legal-agreement-type';
import {ApiError} from '../../util/error';

export type ProfileType = {
    country: number;
    personal_data: {
        email: string; // "turovtsovd@rocketdata.io"
        first_name: string; // "Туровцов Дмитрий"
        last_name: string; // ""
        phone_number: string; // "+375 29 123-45-67"
    };
    profile: {
        can_use_intra_service: boolean; // false
        id: number; // 83719
        pushalert_subscriber_ids: Array<unknown>; // []
        reviews_period: number; // 1
        rules_confirmation: boolean; // false
        send_company_change_mail: boolean; // true
        send_message_notify_mail: boolean; // true
        send_news_mail: boolean; // true
        send_questions_notifications: boolean; // true
        send_review_changes_notifications: boolean; // true
        send_weekly_analytics_summary_notifications: boolean; // true
        send_weekly_phototool_summary_notifications: boolean; // true
        send_review_notification_catalogs: Array<unknown>; // []
        send_review_notification_type: string; // "all"
        send_reviews_notifications: boolean; // true
        timezone: null | string; // null | "Europe/Minsk"
        usedesk_app_id: string; // ""
        usedesk_chat_id: string; // ""
        usedesk_notify_ratings: Array<number>; // [0, 1, 2, 3, 4, 5]
        usedesk_secret: string; // ""
    };
    agreement: LegalAgreementTypeEnum;
};

export type ProfileNewPasswordType = {
    password: string;
    new_password: string;
    new_password_repeat: string;
};

export type ProfileDeleteErrorPayloadType = {
    password: string;
};

export class ProfileDeleteError extends ApiError {
    name = 'ProfileDeleteError';
    jsonData: ProfileDeleteErrorPayloadType;

    constructor(message: string, statusCode: number, jsonData: ProfileDeleteErrorPayloadType) {
        super(message, statusCode, jsonData);
        this.jsonData = jsonData;
    }
}
