import {Checkbox, Radio} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import dayjs from 'dayjs';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {BarChart} from '../../../../layout/chart/bar-chart/bar-chart';
import {BarDataType} from '../../../../layout/chart/bar-chart/bar-chart-type';
import {Empty} from '../../../../layout/empty/empty';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {
    useLocalPackMinimalAggregation,
    useLocalPackPositionDistribution,
} from '../../../../service/local-pack/local-pack-hook';
import {LocalPackSearchServiceType} from '../../../../service/local-pack/local-pack-type';
import {checkIsEmptyChartData} from '../../../../util/chart';
import {NeverError} from '../../../../util/error';
import {DateTimeFormatOptionsType, TimeSizeEnum} from '../../../../util/format';
import {useFormat} from '../../../../util/format-hook/format-hook';
import * as localPackStyles from '../local-pack.scss';
import {LOCALPACK_CATALOG_LOCALIZATION_MAP, LOCALPACK_SERVICE_LOCALIZATION_MAP} from '../local-pack-const';
import {LocalPackFilterType} from '../local-pack-filter/local-pack-filter-type';

import {localPackAggregationLocalizationMap, localPackAggregationStartDateMap} from './position-distribution-const';
import {getBarChartData, getChartOptions} from './position-distribution-helper';
import {LocalPackAggregationTypeEnum} from './position-distribution-type';
import * as styles from './position-distribution.scss';

type PropsType = {
    searchServices: Array<LocalPackSearchServiceType>;
    filter: LocalPackFilterType;
};

// eslint-disable-next-line max-statements
export function LocalPackPositionDistribution(props: PropsType): JSX.Element {
    const {searchServices, filter} = props;
    const {getFormattedNumber, getFormattedDateTime} = useFormat();
    const {
        result: minimalAggregationData,
        isInProgress: minimalAggregationProgress,
        processError: minimalAggregationError,
    } = useLocalPackMinimalAggregation();
    const {
        result: positionDistributionData,
        isInProgress,
        processError,
        loadLocalPackPositionDistribution,
    } = useLocalPackPositionDistribution();

    const {getLocalizedString} = useLocale();

    const [currentAggregation, setCurrentAggregation] = useState<LocalPackAggregationTypeEnum | null>(null);
    const [availableAggregationOptions, setAvailableAggregationOptions] = useState<Array<LocalPackAggregationTypeEnum>>(
        []
    );

    useEffect(() => {
        if (!minimalAggregationData) {
            return;
        }

        const minimalAggregationKey = minimalAggregationData.key;

        setCurrentAggregation(minimalAggregationKey);

        switch (minimalAggregationKey) {
            case LocalPackAggregationTypeEnum.day:
                setAvailableAggregationOptions([
                    LocalPackAggregationTypeEnum.day,
                    LocalPackAggregationTypeEnum.week,
                    LocalPackAggregationTypeEnum.month,
                ]);
                break;
            case LocalPackAggregationTypeEnum.week:
                setAvailableAggregationOptions([LocalPackAggregationTypeEnum.week, LocalPackAggregationTypeEnum.month]);
                break;
            case LocalPackAggregationTypeEnum.month:
                setAvailableAggregationOptions([LocalPackAggregationTypeEnum.month]);
                break;
            default:
                throw NeverError;
        }
    }, [minimalAggregationData, minimalAggregationError]);

    const searchServicesIds = useMemo(() => {
        return searchServices.map((service) => service.id);
    }, [searchServices]);
    const [checkedServices, setCheckedServices] = useState<Array<number>>(searchServicesIds);

    const formatLabel = useCallback(
        (label: string) => {
            if (!label) {
                return '';
            }

            if (currentAggregation === LocalPackAggregationTypeEnum.month) {
                return getFormattedDateTime(new Date(label), {
                    [TimeSizeEnum.month]: 'long',
                });
            }

            const options: DateTimeFormatOptionsType = {
                [TimeSizeEnum.month]: '2-digit',
                [TimeSizeEnum.day]: '2-digit',
            };

            if (currentAggregation === LocalPackAggregationTypeEnum.day) {
                return getFormattedDateTime(new Date(label), options);
            }

            const startWeek = new Date(label);
            const endWeek = new Date(label).setDate(startWeek.getDate() + 6);

            return `${getFormattedDateTime(startWeek, options)}-${getFormattedDateTime(endWeek, options)}`;
        },
        [getFormattedDateTime, currentAggregation]
    );

    const formatValue = useCallback(
        (value: string | number) => {
            return getFormattedNumber(Number(value), {
                style: 'percent',
                maximumFractionDigits: 2,
            });
        },
        [getFormattedNumber]
    );

    useEffect(() => {
        if (currentAggregation) {
            loadLocalPackPositionDistribution({
                searchServices: searchServicesIds,
                filter: {
                    ...filter,
                    ...(!filter.timeRange[0] && !filter.timeRange[1]
                        ? {
                              timeRange: [localPackAggregationStartDateMap[currentAggregation], dayjs()],
                          }
                        : {}),
                },
                aggregation: currentAggregation,
            });
        }
    }, [searchServicesIds, currentAggregation, loadLocalPackPositionDistribution, filter]);

    if (processError || minimalAggregationError) {
        return <AlertFallback />;
    }

    if (isInProgress || minimalAggregationProgress || !positionDistributionData) {
        return <Spinner />;
    }

    const filteredSearchServices = searchServices.filter((searchService) => checkedServices.includes(searchService.id));
    const barChartData: BarDataType = getBarChartData({
        getLocalizedString,
        positionDistributionData,
        filteredSearchServices,
    });
    const isEmptyData = checkIsEmptyChartData(barChartData.datasets);

    return (
        <PageCard
            title={
                <>
                    <Locale stringKey="LOCAL_PACK__POSITION_DISTRIBUTION__TITLE" />
                    <Radio.Group
                        buttonStyle="outline"
                        className={styles.PositionDistribution_actions}
                        onChange={(event: RadioChangeEvent): void => setCurrentAggregation(event.target.value)}
                        optionType="button"
                        size="small"
                        value={currentAggregation}
                    >
                        {availableAggregationOptions.map((aggregationType: string) => {
                            return (
                                <Radio.Button
                                    key={aggregationType}
                                    onClick={() =>
                                        track(
                                            AnalyticsTarget.LocalPack.DataDistibutionByPeriod,
                                            `By ${aggregationType}`
                                        )
                                    }
                                    value={aggregationType}
                                >
                                    <span>
                                        <Locale
                                            stringKey={
                                                localPackAggregationLocalizationMap[
                                                    aggregationType as LocalPackAggregationTypeEnum
                                                ]
                                            }
                                        />
                                    </span>
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                </>
            }
        >
            {!isEmptyData ? (
                <>
                    <div className={styles.PositionDistribution_chart}>
                        <BarChart
                            data={barChartData}
                            options={getChartOptions({
                                data: {
                                    ...barChartData,
                                    datasets: [...barChartData.datasets].reverse(),
                                },
                                formatLabel,
                                formatValue,
                            })}
                        />
                    </div>
                    <div>
                        <Checkbox.Group
                            defaultValue={checkedServices}
                            onChange={(checkedValue: Array<number>): void =>
                                setCheckedServices(checkedValue.map(Number))
                            }
                        >
                            {searchServices.map((service: LocalPackSearchServiceType) => {
                                return (
                                    <Checkbox key={service.id} value={service.id}>
                                        <Locale stringKey={LOCALPACK_SERVICE_LOCALIZATION_MAP[service.service]} />{' '}
                                        <Locale stringKey={LOCALPACK_CATALOG_LOCALIZATION_MAP[service.provider]} />
                                    </Checkbox>
                                );
                            })}
                        </Checkbox.Group>
                    </div>
                </>
            ) : (
                <Empty
                    secondaryText="GOOGLE_SYNC__COMPANIES_STEP__FILTER__EMPTY"
                    secondaryTextClassName={localPackStyles.emptyText}
                />
            )}
        </PageCard>
    );
}
