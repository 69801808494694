import {z as r} from 'zod';

export enum GoogleProcessingStatusEnum {
    LocationsLoadingActive = 'locations_loading_active',
    LocationsLoadingFailed = 'locations_loading_failed',
    ProcessingActive = 'processing_active',
    ProcessingFinished = 'processing_finished',
}

export const googleProcessingKeySchema = r.object({
    key: r.string().nullable(),
    tokenId: r.number().nullable(),
    status: r.nativeEnum(GoogleProcessingStatusEnum).nullable(),
});

export type GoogleProcessingKeyType = r.infer<typeof googleProcessingKeySchema>;

export const googleProcessingStartErrorSchema = r.object({
    tokenId: r.array(r.string()).optional(),
    companiesIds: r.array(r.string()).optional(),
    nonFieldErrors: r.array(r.string()).optional(),
});

export type GoogleProcessingStartErrorResponseType = r.infer<typeof googleProcessingStartErrorSchema>;

export type GoogleProcessingStartOptionsType = {
    accountId: number | null;
    companiesIds: Array<number>;
    bindAll?: boolean;
    toExclude?: Array<number>;
    brandsIds: Array<number>;
    search: string;
};
