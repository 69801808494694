import {Context, createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {z as r} from 'zod';

import {fetchReviewItem, useReviews} from '../../../../service/reviews/reviews';
import {useReviewsCatalogList} from '../../../../service/reviews/reviews-catalogs';
import {useReviewsInfo} from '../../../../service/reviews/reviews-info';
import {useReviewsScrapyCatalogsInfo} from '../../../../service/reviews/reviews-scrapy-catalogs';
import {useReviewsSuggestions} from '../../../../service/reviews/reviews-suggestions';
import {ReviewDataChildType} from '../../../../service/reviews/reviews-type';
import {getReviewsStatus} from '../../../../service/source-settings/accounts/accounts-api';
import {AccountReviewStatusesEnum} from '../../../../service/source-settings/accounts/accounts-type';
import {MainFilterQueryKeyNameEnum} from '../../../../shared-search-parameters';
import {getFromLocalStorage, saveToLocalStorage} from '../../../../util/local-storage';
import {ProvidersIdsEnum} from '../../../../util/type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {ReviewsFilterDataAsObjectUrlType} from '../review-list-page-content/reviews-filter/reviews-filter-type';
import {SuggestionMapType} from '../reviews-type';

import {getReviewStateFromUrlQuery} from './reviews-state-helper';
import {ReviewsStateContextType, ReviewsStateType, ReviewsStateUrlQueryNameEnum} from './reviews-state-type';

const ReviewsStateContext: Context<ReviewsStateContextType | null> = createContext<ReviewsStateContextType | null>(
    null
);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function ReviewsStateProvider(props: PropsType): JSX.Element {
    const {children} = props;
    const reviewLayoutState = getFromLocalStorage('is-new-layout-v2', r.boolean(), true);

    const [isNewLayout, setIsNewLayout] = useState<boolean>(reviewLayoutState);

    const {setQuery: setReviewsStateUrlQuery, queries: reviewsQueries} = useUrl<
        | ReviewsFilterDataAsObjectUrlType
        | Record<MainFilterQueryKeyNameEnum, string>
        | Record<ReviewsStateUrlQueryNameEnum, string>
    >();

    const reviewsState: ReviewsStateType = useMemo(() => getReviewStateFromUrlQuery(reviewsQueries), [reviewsQueries]);

    const {
        data: reviews = null,
        isFetching: isReviewsInProgress,
        refetch: refreshReviews,
        pagination: cursorPagination,
    } = useReviews({reviewsState});

    const {
        data: reviewsCatalogList = null,
        isFetching: reviewsCatalogListIsInProgress,
        refetch: refreshCatalogs,
    } = useReviewsCatalogList();

    const [reviewsList, setReviewsList] = useState(reviews);
    const reviewIds = useMemo(() => reviews?.results.map((review) => review.id) || [], [reviews]);
    const googleMapsReviewIds = useMemo(
        () =>
            reviews?.results
                .filter((item) => item.catalogId === ProvidersIdsEnum.googleMapsNew)
                .map((review) => review.id) || [],
        [reviews]
    );

    function handleNewReviewsLayout(isNewLayoutReview: boolean) {
        setIsNewLayout(isNewLayoutReview);
        saveToLocalStorage(isNewLayoutReview, 'is-new-layout-v2');
    }

    useEffect(() => {
        async function init() {
            try {
                if (reviewIds.length > 0) {
                    const response = await getReviewsStatus({
                        reviewsIds: reviewIds,
                    });

                    const reviewAccountErrorStatusList =
                        response.find((item) => item.accountStatus === AccountReviewStatusesEnum.errorStatus)
                            ?.reviewsIds || [];

                    if (reviewAccountErrorStatusList.length > 0 && reviews) {
                        const reviewsInner =
                            reviews?.results?.map((review) =>
                                reviewAccountErrorStatusList.includes(Number(review.id))
                                    ? {...review, hasAccountError: true}
                                    : review
                            ) || [];

                        setReviewsList({...reviews, results: reviewsInner});
                        return;
                    }

                    setReviewsList(reviews);
                    return;
                }

                setReviewsList(reviews);
            } catch (error: unknown) {
                console.log(error);
            }
        }

        init();
    }, [reviews]);

    const refreshReview = useCallback(
        async (reviewId: number) => {
            const response = await fetchReviewItem(reviewId);
            const newReviewsList = Object.assign({}, reviewsList);

            if (newReviewsList) {
                const index = newReviewsList.results?.findIndex((review) => review.id === reviewId);

                newReviewsList.results[index] = response;
                setReviewsList(newReviewsList);
            }
        },
        [reviewsList]
    );

    const refreshReviewWithAnswer = useCallback(
        (answer: ReviewDataChildType, reviewId: number) => {
            if (reviewsList && reviewsList.results) {
                setReviewsList((previousList) => {
                    if (previousList) {
                        return {
                            ...previousList,
                            results:
                                previousList?.results.map((reviewItem) => {
                                    if (reviewItem.id === reviewId) {
                                        return {
                                            ...reviewItem,
                                            children: [...(reviewItem?.children || []), answer],
                                        };
                                    }

                                    return reviewItem;
                                }) || [],
                        };
                    }

                    return null;
                });
            }
        },
        [reviewsList]
    );

    const {
        data: resultSuggestions,
        refetch: refreshSuggestions,
        isLoading: isLoadingSuggestions,
    } = useReviewsSuggestions(reviewIds);
    const suggestionMap: SuggestionMapType = useMemo(() => {
        return Object.fromEntries(
            (resultSuggestions?.results || []).map((suggestion) => [suggestion.reviewId, suggestion])
        );
    }, [resultSuggestions?.results]);
    // https://github.com/TanStack/query/issues/3975

    const {data: reviewsInfo = null, refetch: refreshReviewsInfo} = useReviewsInfo(reviewIds);
    const {data: reviewsScrapyCatalogsList} = useReviewsScrapyCatalogsInfo();

    const value: ReviewsStateContextType = useMemo(
        () => ({
            reviewsState,
            setReviewsStateUrlQuery,
            refreshReview,
            refreshReviews,
            reviewsCatalogList,
            reviewsCatalogListIsInProgress,
            reviewsScrapyCatalogsList,
            suggestionMap,
            refreshSuggestions,
            reviewsInfo,
            refreshReviewsInfo,
            refreshCatalogs,
            isReviewsInProgress: isReviewsInProgress || isLoadingSuggestions,
            cursorPagination,
            reviews: reviewsList,
            isNewLayout,
            handleNewReviewsLayout,
            reviewIds,
            googleMapsReviewIds,
            refreshReviewWithAnswer,
        }),
        [
            isNewLayout,
            reviewsState,
            setReviewsStateUrlQuery,
            refreshReview,
            refreshReviews,
            reviewsCatalogList,
            reviewsCatalogListIsInProgress,
            reviewsScrapyCatalogsList,
            suggestionMap,
            refreshSuggestions,
            reviewsInfo,
            refreshReviewsInfo,
            refreshCatalogs,
            isReviewsInProgress,
            cursorPagination,
            reviewsList,
            isLoadingSuggestions,
            reviewIds,
            googleMapsReviewIds,
            refreshReviewWithAnswer,
        ]
    );

    return <ReviewsStateContext.Provider value={value}>{children}</ReviewsStateContext.Provider>;
}

export function useReviewStateHook(): ReviewsStateContextType {
    const context: ReviewsStateContextType | null = useContext(ReviewsStateContext);

    if (!context) {
        throw new Error('useReviewStateHook must be used within a ReviewsStateProvider');
    }

    return context;
}
