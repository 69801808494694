import dayjs from 'dayjs';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {DoughnutChartWithCenterData} from '../../../../layout/doughnut-char-with-center-data/doughnut-chart-with-center-data';
import {DoughnutStatistic} from '../../../../layout/doughnut-statistic/doughnut-statistic';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useReviewsRatingDistribution} from '../../../../service/dashboard/dashboard-hook';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {
    getReviewsRatingDistributionChartOptions,
    ratingValueColorMap,
    ratingValueLocalizationMap,
} from './reviews-rating-distribution-const';
import * as styles from './reviews-rating-distribution.scss';

export function ReviewsRatingDistribution(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const today = dayjs();
    const yearAgo = dayjs().subtract(1, 'year');
    const {result, isInProgress, processError} = useReviewsRatingDistribution({
        startDate: yearAgo.format('YYYY-MM-DD'),
        endDate: today.format('YYYY-MM-DD'),
    });

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    const data = result.map((item) => {
        const label = ratingValueLocalizationMap[item.rating];

        return {
            color: ratingValueColorMap[item.rating] ?? '',
            label: label ? getLocalizedString(label) : '',
            value: item.count,
        };
    });

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__TITLE" />}
                    widget={DashboardWidgetEnum.RatingDistribution}
                />
            }
        >
            <DoughnutChartWithCenterData
                chartOptions={getReviewsRatingDistributionChartOptions({
                    data,
                })}
                className={styles.ReviewsRatingDistribution_chartContainer}
                data={data}
                hideOnEmptyData
                statisticsRenderer={DoughnutStatistic}
            />
        </PageCard>
    );
}
