import {PostPreviewPropsType} from '../../../content-step-type';

import {InstagramPreviewActions} from './components/instagram-preview-actions/instagram-preview-actions';
import {InstagramPreviewBody} from './components/instagram-preview-body/instagram-preview-body';
import {InstagramPreviewHeader} from './components/instagram-preview-header/instagram-preview-header';
import {InstagramPreviewImages} from './components/instagram-preview-images/instagram-preview-images';
import * as styles from './instagram-preview.scss';

export function InstagramPreview(props: PostPreviewPropsType): JSX.Element | null {
    const {postData, companyInfo} = props;

    if (!postData) {
        return null;
    }

    const {text, video, images} = postData;

    const isOnlyVideo = images.length === 0 && Boolean(video);

    return (
        <div className={styles.InstagramPreview}>
            <InstagramPreviewHeader companyInfo={companyInfo} isOnlyVideo={isOnlyVideo} />
            <InstagramPreviewImages images={images} isOnlyVideo={isOnlyVideo} video={video} />
            <div className={styles.InstagramPreview_content}>
                <InstagramPreviewActions />
                <InstagramPreviewBody name={companyInfo?.name} text={text} />
            </div>
        </div>
    );
}
