import {ProvidersEnum} from '../../util/type';

import {accountLinkingCardStyleMap} from './account-card-const';
import {AccountLinkingCardTypeEnum} from './account-card-type';
import * as styles from './account-card.scss';

type PropsType = {
    type?: AccountLinkingCardTypeEnum;
    catalogName: string;
    info: string | JSX.Element | null;
    iconSrc: string;
    button?: JSX.Element;
};

export function AccountCard(props: PropsType): JSX.Element {
    const {catalogName, info, iconSrc, button, type = AccountLinkingCardTypeEnum.DEFAULT} = props;

    const cardStyle = accountLinkingCardStyleMap[type];

    return (
        <div className={cardStyle}>
            <div className={styles.account_card__content_wrapper}>
                <img alt={ProvidersEnum.facebook} className={styles.account_card__catalog_icon} src={iconSrc} />
                <p className={styles.account_card__catalog_name}>{catalogName}</p>
                <p className={styles.account_card__account_name}>{info}</p>
                {button}
            </div>
        </div>
    );
}
