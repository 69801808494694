import {fetchX} from '../../util/fetch';
import {mainApiHeaders, rootApiUrl} from '../api/api-const';

import {CurrentSiteResponseType} from './site-type';

export async function indentifyCurrentSite(): Promise<CurrentSiteResponseType> {
    return fetchX<CurrentSiteResponseType>(rootApiUrl + `/v1/user/sites/${location.hostname}`, {
        headers: {...mainApiHeaders},
    });
}
