import {LocaleNameEnum} from '../provider/locale/locale-context-type';

type GetPluralConfigSimpleType<PluralWord> = {
    count: number;
    singular: PluralWord; // 1
    plural: PluralWord; // bigger than 1 or equal to 0
};

function getSimplePlural<PluralWord>(pluralConfig: GetPluralConfigSimpleType<PluralWord>): PluralWord {
    const {count, plural, singular} = pluralConfig;

    if (count === 1) {
        return singular;
    }

    return plural;
}

type GetPluralConfigRuType<PluralWord> = {
    count: number;
    singular: PluralWord; // 1
    pluralFew: PluralWord; // 2, 3, 4
    pluralMany: PluralWord; // 0, 5, 6, 7, 8, ...
};

function getPluralRu<PluralWord>(pluralConfigRu: GetPluralConfigRuType<PluralWord>): PluralWord {
    const {pluralMany, pluralFew, singular, count} = pluralConfigRu;

    const countStrictTo2 = Math.round(count % 100); // [1..99]

    const isTeenNumber = countStrictTo2 > 10 && countStrictTo2 < 20; // [11..19]

    if (isTeenNumber) {
        return pluralMany;
    }

    const countStrictTo1 = countStrictTo2 % 10; // [0..9]

    if (countStrictTo1 === 1) {
        return singular;
    }

    // 0, 5, 6, 7, 8, ...
    if (countStrictTo1 === 0 || countStrictTo1 >= 5) {
        return pluralMany;
    }

    // 2, 3, 4
    return pluralFew;
}

export type GetPluralConfigType<PluralWord> = {
    localeName: LocaleNameEnum;
    count: number;
    singular: PluralWord; // 1
    pluralFew: PluralWord; // 2, 3, 4
    pluralMany: PluralWord; // 0, 5, 6, 7, 8, ...
};

export function getPlural<PluralWord>(pluralConfig: GetPluralConfigType<PluralWord>): PluralWord {
    const {pluralMany, pluralFew, singular, count, localeName} = pluralConfig;

    if (localeName === LocaleNameEnum.ruRu) {
        return getPluralRu<PluralWord>({count, singular, pluralFew, pluralMany});
    }

    return getSimplePlural<PluralWord>({count, singular, plural: pluralFew});
}
