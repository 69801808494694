import {Input} from 'antd';

import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {Form} from '../../../../../../../../typings/antd';
import * as postFormStyles from '../../../../post-form.scss';
import {PostFormItemPropsType} from '../../../../post-form-type';
import {POST_NAME_MAX_LENGTH, POST_NAME_MIN_LENGTH} from '../../../content/content-step-helper';

export function PostNameItem(props: PostFormItemPropsType): JSX.Element {
    const {disabled} = props;
    const {minLengthFieldRule, maxLengthFieldRule, requiredFieldRuleWithText} = useFormRules();

    const {getLocalizedString} = useLocale();

    return (
        <Form.Item<PostFormType>
            className={postFormStyles.PostForm_countedInputItem}
            label={<Locale stringKey="POSTS_FORM__NAME__LABEL" />}
            name={PostFormFieldsEnum.Name}
            required
            rules={
                !disabled
                    ? [
                          requiredFieldRuleWithText('POSTS_FORM__ERROR__EMPTY_POST_NAME'),
                          minLengthFieldRule(POST_NAME_MIN_LENGTH, 'POSTS_FORM__ERROR__SHORT_POST_NAME'),
                          maxLengthFieldRule(POST_NAME_MAX_LENGTH),
                      ]
                    : []
            }
        >
            <Input
                disabled={disabled}
                maxLength={POST_NAME_MAX_LENGTH}
                placeholder={getLocalizedString('POSTS_FORM__NAME__PLACEHOLDER')}
                showCount
            />
        </Form.Item>
    );
}
