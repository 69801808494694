import {Button, Input, Select, Space} from 'antd';
import {FieldData} from 'rc-field-form/lib/interface';
import {useCallback, useEffect, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Text} from '../../../component/text/text';
import {LegalAgreement} from '../../../layout/legal-agreement/legal-agreement';
import {LegalAgreementFormKeyEnum} from '../../../layout/legal-agreement/legal-agreement-type';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {getCountryTranslation, useSortedCountries} from '../../../service/company/countries';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {
    registerErrorSchema,
    RegisterErrorType,
    RegisterFormKeyEnum,
    RegisterFormType,
    useRegisterMutation,
} from '../../../service/user/user-register';
import {useUserManagementRegisterMutation} from '../../../service/user-management/user-management-registration';
import {useUserManagementRegistrationData} from '../../../service/user-management/user-management-registration-data';
import {Form} from '../../../typings/antd';
import {handleFilterOption} from '../../../util/antd/select-helper';
import {deserializeApiError} from '../../../util/api-adapter';
import {classNames} from '../../../util/css';
import {AuthFormHeader} from '../common/form-header/auth-form-header';

import {initialValues} from './register-const';
import {FormItemsHintType} from './register-type';
import * as styles from './register.scss';

// eslint-disable-next-line complexity
export function Register(): JSX.Element {
    const [form] = Form.useForm<RegisterFormType>();
    const {
        emailFieldRule,
        getPhoneNumberRule,
        isErrorInForm,
        requiredFieldRule,
        requiredFieldRuleWithText,
        validatePasswordRules,
    } = useFormRules();
    const rulesConfirmation = Form.useWatch(LegalAgreementFormKeyEnum.RulesConfirmation, form);
    const [areAllFieldsFilled, setAreAllFieldsFilled] = useState(false);
    const [formItemsHint, setFormItemsHint] = useState<FormItemsHintType | null>(null);

    const {mutate, isLoading, error} = useRegisterMutation();
    const {
        mutate: userManagementMutate,
        isLoading: userManagementIsLoading,
        error: errorManagement,
    } = useUserManagementRegisterMutation();
    const {data: registrationData, enabled: isUserManagement} = useUserManagementRegistrationData();
    const {data: sortedCountries, isInitialLoading} = useSortedCountries();
    const {getLocalizedString, shortLocaleName} = useLocale();

    useEffect(() => {
        const apiError = deserializeApiError<RegisterErrorType>(
            '/user/register/',
            registerErrorSchema,
            isUserManagement ? errorManagement : error
        );

        if (apiError) {
            const responseItemsHint = Object.keys(apiError).reduce<FormItemsHintType>((result, current) => {
                // eslint-disable-next-line no-param-reassign
                result[current as keyof FormItemsHintType] = apiError[current as keyof typeof apiError]?.[0];
                return result;
            }, {});

            setFormItemsHint((previousState) => ({
                ...previousState,
                ...responseItemsHint,
            }));
        }
    }, [error, errorManagement, isUserManagement]);

    // can't remove rulesConfirmation check because onFieldsChange doesn't update on change event from LegalAgreement
    const disabled =
        !rulesConfirmation || !areAllFieldsFilled || (isUserManagement ? userManagementIsLoading : isLoading);

    const formInitialValues = isUserManagement
        ? {...initialValues, [RegisterFormKeyEnum.Email]: registrationData?.email}
        : initialValues;

    const onFieldsChange = useCallback(
        (changedFields: Array<FieldData>, allFields: Array<FieldData>) => {
            setFormItemsHint((previousState) => ({
                ...previousState,
                [changedFields[0]?.name as keyof FormItemsHintType]: null,
            }));

            const isFilled =
                allFields
                    .filter(({name}) => (Array.isArray(name) ? !name.includes(RegisterFormKeyEnum.LastName) : true))
                    .map(({value}) => value)
                    .every(Boolean) && !isErrorInForm(form);

            if (isFilled !== areAllFieldsFilled) {
                setAreAllFieldsFilled(isFilled);
            }
        },
        [areAllFieldsFilled, form, isErrorInForm]
    );

    // can't render the form before initialValues are fully loaded, they will not update
    if (isUserManagement && !registrationData) {
        return <Page.Loading titleLangKey="PAGE__REGISTER__REGISTER_FORM__HEADER" />;
    }

    return (
        <Page.CenteredCard className={styles.Register_card} titleLangKey="PAGE__REGISTER__REGISTER_FORM__HEADER">
            <AuthFormHeader
                className={styles.Register_formHeader}
                subtitle={<Locale stringKey="PAGE__REGISTER__REGISTER_FORM__SUB_HEADER" />}
                title={<Locale stringKey="PAGE__REGISTER__REGISTER_FORM__HEADER" />}
            />

            <Form<RegisterFormType>
                form={form}
                initialValues={formInitialValues}
                layout="vertical"
                onFieldsChange={onFieldsChange}
                onFinish={isUserManagement ? userManagementMutate : mutate}
            >
                <section className={styles.Register_formFields}>
                    <Form.Item<RegisterFormType>
                        help={formItemsHint?.firstName}
                        label={<Locale stringKey="PAGE__REGISTER__REGISTER_FORM__INPUT__NAME__LABEL" />}
                        name={RegisterFormKeyEnum.FirstName}
                        rules={[requiredFieldRuleWithText('PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_NAME')]}
                        validateStatus={formItemsHint?.firstName ? 'error' : ''}
                    >
                        <Input
                            placeholder={getLocalizedString('PAGE__REGISTER__REGISTER_FORM__INPUT__NAME__PLACEHOLDER')}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item<RegisterFormType>
                        label={<Locale stringKey="PAGE__REGISTER__REGISTER_FORM__INPUT__SURNAME__LABEL" />}
                        name={RegisterFormKeyEnum.LastName}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'PAGE__REGISTER__REGISTER_FORM__INPUT__SURNAME__PLACEHOLDER'
                            )}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item<RegisterFormType>
                        help={formItemsHint?.email}
                        label={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__LABEL" />}
                        name={RegisterFormKeyEnum.Email}
                        rules={[
                            requiredFieldRuleWithText('PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_EMAIL'),
                            emailFieldRule,
                        ]}
                        validateStatus={formItemsHint?.email ? 'error' : ''}
                    >
                        <Input
                            // setting disabled directly causes chrome to autofill other unrelated fields
                            className={classNames({'ant-input-disabled': isUserManagement})}
                            onInvalid={(event) => event.preventDefault()}
                            placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__PLACEHOLDER')}
                            readOnly={isUserManagement}
                            size="large"
                            type="email"
                        />
                    </Form.Item>

                    <Form.Item<RegisterFormType>
                        help={formItemsHint?.phone}
                        label={<Locale stringKey="PAGE__REGISTER__REGISTER_FORM__INPUT__PHONE__LABEL" />}
                        name={RegisterFormKeyEnum.Phone}
                        rules={[requiredFieldRule, getPhoneNumberRule()]}
                        validateStatus={formItemsHint?.phone ? 'error' : ''}
                    >
                        <Input
                            placeholder={getLocalizedString('PAGE__REGISTER__REGISTER_FORM__INPUT__PHONE__LABEL')}
                            size="large"
                            type="tel"
                        />
                    </Form.Item>

                    <Form.Item<RegisterFormType>
                        label={<Locale stringKey="PAGE__REGISTER__REGISTER_FORM__INPUT__CITIZEN__LABEL" />}
                        name={RegisterFormKeyEnum.Country}
                        rules={[requiredFieldRuleWithText('PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_COUNTRY')]}
                    >
                        <Select<number>
                            disabled={isInitialLoading}
                            filterOption={handleFilterOption}
                            loading={isInitialLoading}
                            options={sortedCountries?.results.map((country) => ({
                                value: country.id,
                                label: getCountryTranslation(country, shortLocaleName),
                            }))}
                            placeholder={getLocalizedString(
                                'PAGE__REGISTER__REGISTER_FORM__INPUT__CITIZEN__PLACEHOLDER'
                            )}
                            showSearch
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item<RegisterFormType>
                        label={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__LABEL" />}
                        name={RegisterFormKeyEnum.Password}
                        rules={[requiredFieldRule, ...validatePasswordRules()]}
                    >
                        <Input.Password
                            autoComplete="off"
                            placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__PLACEHOLDER')}
                            size="large"
                        />
                    </Form.Item>
                </section>

                <LegalAgreement className={styles.Register_legalAgreement} />

                <footer className={styles.Register_footer}>
                    <Space>
                        <Text lighter stringKey="PAGE__REGISTER__REGISTER_FORM__ALREADY_HAVE_AN_ACCOUNT" />

                        <NavigationLink to={appRoute.login.path}>
                            <Locale stringKey="PAGE__AUTH__LINK_TO__LOGIN" />
                        </NavigationLink>
                    </Space>

                    <Button
                        disabled={disabled}
                        htmlType="submit"
                        loading={isUserManagement ? userManagementIsLoading : isLoading}
                        size="large"
                        type="primary"
                    >
                        <Locale stringKey="PAGE__REGISTER__REGISTER_FORM__BUTTON__SIGN_UP" />
                    </Button>
                </footer>
            </Form>
        </Page.CenteredCard>
    );
}
