import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

export const companyPaymentMethodsSchema = {
    [CompanyKeyEnum.Payments]: r.array(r.number()),
};

const companyPaymentMethodsSchemaObject = r.object(companyPaymentMethodsSchema);

export type CompanyPaymentMethodsType = r.infer<typeof companyPaymentMethodsSchemaObject>;
