import {faFileArrowDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Input, Row} from 'antd';

import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../typings/antd';
import {CreateProductGroupFormFieldEnum, ProductGroupFormType} from '../product-group-form-type';

import {getSampleFeedUrl} from './product-group-feed-fields-helper';
import * as styles from './product-group-feed-field.scss';

type PropsType = {
    showWarning: boolean;
};

export function ProductGroupFeedFields(props: PropsType): JSX.Element {
    const {showWarning} = props;

    const {shortLocaleName, getLocalizedString} = useLocale();
    const {requiredFieldRule, validUrlRule} = useFormRules();

    const {domainName} = useDomainConfig();

    return (
        <>
            <Row className={styles.ProductGroupFeedField_feedUrlRow}>
                <Form.Item<ProductGroupFormType>
                    className={styles.ProductGroupFeedField_feedUrlInput}
                    hasFeedback
                    label={<Locale stringKey="PRODUCTS__GROUP_FORM__FIELD__FEED__TITLE" />}
                    name={CreateProductGroupFormFieldEnum.FeedUrl}
                    rules={[requiredFieldRule, validUrlRule()]}
                >
                    <Input placeholder={getLocalizedString('PRODUCTS__GROUP_FORM__FIELD__FEED__PLACEHOLDER')} />
                </Form.Item>

                <Button
                    className={styles.ProductGroupFeedField_templateButton}
                    href={getSampleFeedUrl(shortLocaleName, domainName)}
                    icon={<FontAwesomeIcon icon={faFileArrowDown} />}
                >
                    <Locale stringKey="PRODUCTS__GROUP_FORM__UPLOAD_FILE__DOWNLOAD_TEMPLATE" />
                </Button>
            </Row>

            {showWarning && (
                <Alert
                    className={styles.ProductGroupFeedField_warning}
                    message={getLocalizedString('PRODUCTS__EDIT_PRODUCT_GROUP__FEED_WARNING_MESSAGE')}
                    showIcon
                    type="warning"
                />
            )}
        </>
    );
}
