import {indentifyCurrentSite} from '../../service/site/site-api';
import {CurrentSiteResponseType} from '../../service/site/site-type';

import {DomainConfigType} from './domain-config-type';
import {domainConfig as rocketDataDomainConfig} from './domain-data/rocketdata/domain-config-data';
import {domainConfig as twoGisDomainConfig} from './domain-data/two-gis/domain-config-data';
import {domainConfig as getWhiteLabelConfig} from './domain-data/white-label/domain-config-data';

export const TWO_GIS_DOMAIN = '2gis.com';

function getHostname() {
    return typeof location !== 'undefined' ? location.hostname : '';
}

function getIs2gisDomain(): boolean {
    return [
        'cp.account.2gis.com',
        'cp.account.2gis.dev',
        'account.2gis.com',
        'account.2gis.dev',
        'demo.account.2gis.com',
        'demo-staging.account.2gis.com',
    ].includes(getHostname());
}

function getIsWhiteLabel(site: CurrentSiteResponseType | null): site is CurrentSiteResponseType {
    return site !== null && site.is_white_label;
}

function getIsLocalhost(): boolean {
    return location.hostname === 'localhost';
}

export async function initializeDomainConfig(): Promise<DomainConfigType> {
    if (getIsLocalhost()) {
        return rocketDataDomainConfig;
    }

    if (getIs2gisDomain()) {
        return twoGisDomainConfig;
    }

    const site = await indentifyCurrentSite();

    if (getIsWhiteLabel(site)) {
        return getWhiteLabelConfig(site);
    }

    return rocketDataDomainConfig;
}
