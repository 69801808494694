import {ChartTooltip} from '../../../../../../component/chart-tooltip/chart-tooltip';
import {ChartTooltipDivider} from '../../../../../../component/chart-tooltip/chart-tooltip-divider';
import {ChartTooltipRow} from '../../../../../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../../../../../component/chart-tooltip/chart-tooltip-title';
import {MultiSourceDataType} from '../../../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {Locale} from '../../../../../../provider/locale/localization';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

type PropsType = {
    data: MultiSourceDataType;
    currentIndex: number;
};

export function OnlinePresenceSummaryTooltip(props: PropsType): JSX.Element {
    const {data, currentIndex} = props;
    const {datasets, labels} = data;

    const {getFormattedDateTime, getFormattedNumber} = useFormat();

    const elementLabel = labels?.[currentIndex] || '';

    const impressionsDatasets = datasets.filter((dataset) => dataset.yAxisID === 'y2');
    const targetActionDatasets = datasets.filter((dataset) => dataset.yAxisID !== 'y2');

    const currentPeriodImpressions = impressionsDatasets.reduce(
        (accumulator, current) => accumulator + (current?.values?.[currentIndex] || 0),
        0
    );
    const currentPeriodTargetActions = targetActionDatasets.reduce(
        (accumulator, current) => accumulator + (current?.values?.[currentIndex] || 0),
        0
    );

    return (
        <ChartTooltip>
            <ChartTooltipTitle
                title={
                    elementLabel &&
                    getFormattedDateTime(new Date(elementLabel), {
                        month: 'long',
                        year: 'numeric',
                    })
                }
            />
            {impressionsDatasets.map(({label, color, values}) => {
                return <ChartTooltipRow color={color} key={label} label={label} value={values[currentIndex] || 0} />;
            })}
            <ChartTooltipDivider />

            {targetActionDatasets.map(({label, color, values}) => {
                return <ChartTooltipRow color={color} key={label} label={label} value={values[currentIndex] || 0} />;
            })}

            <ChartTooltipDivider />

            <ChartTooltipRow
                label={
                    <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CHART__TOTAL_TARGET_ACTIONS" />
                }
                value={currentPeriodTargetActions}
            />

            <ChartTooltipRow
                label={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CONVERSION" />}
                value={
                    currentPeriodImpressions
                        ? getFormattedNumber(currentPeriodTargetActions / currentPeriodImpressions, {
                              maximumFractionDigits: 1,
                              style: 'percent',
                          })
                        : 0 + '%'
                }
            />
        </ChartTooltip>
    );
}
