import {rootApiUrl} from '../../service/api/api-const';
import {userOwnUrl} from '../../service/user/user-own';
import {deserializeV2, fetchAndDeserialize, getUrlParameters, serializeToURLParameters} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';

import {
    CatalogConfigApiType,
    catalogConfigSchema,
    catalogSchema,
    CatalogType,
    FetchCatalogOptionType,
    FetchCatalogRulesOptionType,
    PaidCatalogIdsType,
    paidCatalogsIdsSchema,
    shortCatalogSchema,
    ShortCatalogType,
} from './catalogs-type';

export function fetchCatalogs(
    fetchCatalogOption: FetchCatalogOptionType,
    mainFilterKey: string
): Promise<Array<CatalogType>> {
    const {canCollectReviews, catalogType, isRelated, abilityToReply} = fetchCatalogOption;

    const parameters = {
        type: catalogType,
        can_collect_reviews: canCollectReviews,
        related: isRelated ? true : null,
        ability_to_reply: abilityToReply ? true : null,
        filter_key: mainFilterKey,
    };

    const url = `/v1/catalogs/?${objectToUrlParameters(parameters)}`;

    return fetchAndDeserialize(url, catalogSchema.array(), {
        fetchOptions: {
            skipCache: true,
        },
    });
}

export async function fetchCatalogConfig(companyId?: number): Promise<Array<CatalogConfigApiType>> {
    const url = `/v2/catalog/config/${getUrlParameters({companyId})}`;

    return fetchAndDeserialize(url, catalogConfigSchema.array());
}

export async function fetchAllCPVisibleCatalogs(): Promise<Array<CatalogType>> {
    const url = `/v1/catalogs/`;

    return fetchAndDeserialize(url, catalogSchema.array());
}

export async function fetchAvailableCatalogs(): Promise<Array<ShortCatalogType>> {
    const url = `${rootApiUrl}/cp/user/available_catalogs/`;
    const result = await fetchX<Array<unknown>>(url);

    return deserializeV2<Array<ShortCatalogType>>(url, shortCatalogSchema.array(), result);
}

export async function fetchCatalogsRules(
    fetchCatalogOption: FetchCatalogRulesOptionType,
    mainFilterKey: string
): Promise<Array<CatalogType>> {
    const {autoReplySupported, gptSuggestSupported} = fetchCatalogOption;

    const parameters = {
        autoReplySupported,
        gptSuggestSupported,
        filterKey: mainFilterKey,
    };

    const url = `/cp/catalogs/rules_catalogs/?${objectToUrlParameters(serializeToURLParameters(parameters))}`;

    return fetchAndDeserialize(url, catalogSchema.array());
}

export async function fetchPaidCatalogs(): Promise<PaidCatalogIdsType> {
    const url = `${userOwnUrl}paid_catalogs/`;

    return fetchAndDeserialize(url, paidCatalogsIdsSchema);
}
