import {ExtractRouteParams} from 'react-router';
import {z as r} from 'zod';

type QueryKeyType = string | number | symbol;

export type QuerySimpleValueType = Date | string | number | boolean | null | void;

export type QueryValueType = Array<QuerySimpleValueType> | QuerySimpleValueType;

export type ObjectToUrlParametersType = Readonly<Record<string, QueryValueType>>;

// TODO: remove `= string`
export type QueryMapType<QueryKey extends QueryKeyType = QueryKeyType> = Readonly<Record<QueryKey, string | void>>;

export const idNumberSchema = r.object({
    id: r.number(),
});

export type IdNumberType = r.infer<typeof idNumberSchema>;

export type TimeStringType = `${number}${number}:${number}${number}`;
export type DateStringType = `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

// use backend enum if you want to add more entries
// https://gitlab.com/rocketdata.io/rocketdata/-/blob/main/backend/app/core/constants.py#L20
export enum ProvidersEnum {
    google = 'google',
    googleMaps = 'Google maps',
    yandex = 'yandex',
    osm = 'osm',
    navitel = 'navitel',
    doubleGis = '2gis.ru',
    facebook = 'facebook',
    instagram = 'instagram',
    vkontakte = 'vk',
}

// Constant ids from server
export enum ProvidersIdsEnum {
    google = 11,
    yandex = 12,
    googleMaps = 60,
    osm = 26,
    navitel = 31,
    vkontakte = 36,
    facebook = 35,
    instagram = 61,
    doubleGis = 2,
    googleMapsNew = 98,
}

export const PROVIDER_TO_ITS_ID_MAP: Record<ProvidersEnum, ProvidersIdsEnum> = {
    [ProvidersEnum.google]: ProvidersIdsEnum.google,
    [ProvidersEnum.yandex]: ProvidersIdsEnum.yandex,
    [ProvidersEnum.googleMaps]: ProvidersIdsEnum.googleMaps,
    [ProvidersEnum.osm]: ProvidersIdsEnum.osm,
    [ProvidersEnum.navitel]: ProvidersIdsEnum.navitel,
    [ProvidersEnum.vkontakte]: ProvidersIdsEnum.vkontakte,
    [ProvidersEnum.facebook]: ProvidersIdsEnum.facebook,
    [ProvidersEnum.instagram]: ProvidersIdsEnum.instagram,
    [ProvidersEnum.doubleGis]: ProvidersIdsEnum.doubleGis,
};

export const PROVIDER_ID_TO_ITS_NAME_MAP: Record<ProvidersIdsEnum, ProvidersEnum> = {
    [ProvidersIdsEnum.google]: ProvidersEnum.google,
    [ProvidersIdsEnum.yandex]: ProvidersEnum.yandex,
    [ProvidersIdsEnum.googleMaps]: ProvidersEnum.googleMaps,
    [ProvidersIdsEnum.osm]: ProvidersEnum.osm,
    [ProvidersIdsEnum.navitel]: ProvidersEnum.navitel,
    [ProvidersIdsEnum.vkontakte]: ProvidersEnum.vkontakte,
    [ProvidersIdsEnum.facebook]: ProvidersEnum.facebook,
    [ProvidersIdsEnum.instagram]: ProvidersEnum.instagram,
    [ProvidersIdsEnum.doubleGis]: ProvidersEnum.doubleGis,
    [ProvidersIdsEnum.googleMapsNew]: ProvidersEnum.google,
};
export enum CclCatalogStatusEnum {
    correct = 'correct',
    incorrect = 'incorrect',
    duplicate = 'duplicate',
    notFound = 'not_found',
    needAction = 'action_needed',
    notVerified = 'not_verified',
    waitVerify = 'wait_verify',
    unsupportedRegion = 'unsupported_region',
    createError = 'CREATE_ERROR',
    closed = 'closed',
}

export enum CclStateStatusEnum {
    send = 'send',
    unsupportedRegion = 'unsupported_region',
    sync = 'sync',
    onModeration = 'on_moderation',
    noLicense = 'no_license',
}

export enum CclErrorEnum {
    name = 'name',
    formatted_address = 'formatted_address',
    website = 'website',
    working_hours = 'working_hours',
    phones = 'phones',
    closed_status = 'closed_status',
}

export type UnionToIntersectionType<U> = (U extends never ? never : (argument: U) => never) extends (
    argument: infer I
) => void
    ? I
    : never;

// Converts union 'A' | 'B' | 'C' -> tuple ['A', 'B', 'C']. Order is the same
export type UnionToTupleType<T> = UnionToIntersectionType<T extends never ? never : (t: T) => T> extends (
    _: never
) => infer W
    ? [...UnionToTupleType<Exclude<T, W>>, W]
    : [];

type TupleOfType<T, N extends number, R extends Array<unknown>> = R['length'] extends N
    ? R
    : TupleOfType<T, N, [T, ...R]>;

// https://stackoverflow.com/a/52490977
export type TupleType<T, N extends number> = N extends N
    ? number extends N
        ? Array<T>
        : TupleOfType<T, N, []>
    : never;

export type NullableType<T> = {
    [key in keyof T]: T[key] | null;
};

export type PartialNullableType<T, Keys extends keyof T = keyof T> = {
    [key in keyof T]: key extends Keys ? T[key] | null : T[key];
};

export type GeneratePathWithQueryParametersType<S extends string> = {
    parametersURL: ObjectToUrlParametersType;
    query?: ExtractRouteParams<S>;
};
