import {faStar, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/locale';
import {classNames} from '../../../../../../../util/css';

import * as styles from './links-form-item.scss';

type PropsType = {
    index: number;
    onRemove: () => void;
    hideAdditionalInfo?: boolean;
};

export function LinksFormItemButtons(props: PropsType): JSX.Element {
    const {index, onRemove, hideAdditionalInfo} = props;

    return (
        <div className={classNames(styles.link__info, index ? styles.link__info_small : '')}>
            {index > 0 && (
                <Button onClick={() => onRemove()} size="small" type="text">
                    <FontAwesomeIcon className={styles.link__icon} icon={faTrash} />
                </Button>
            )}

            {index === 0 && !hideAdditionalInfo && (
                <>
                    <Button className={styles.link__star_button} size="small" type="text">
                        <FontAwesomeIcon className={styles.link__icon} icon={faStar} />
                    </Button>

                    <AdditionalInfo
                        className={styles.link__additional_info}
                        rightNode={<Locale stringKey="COMPANY_FORM__LINKS__WEBSITES__ADDITIONAL_INFO_HEADER" />}
                        title={<Locale stringKey="COMPANY_FORM__LINKS__WEBSITES__ADDITIONAL_INFO_HEADER" />}
                    >
                        <Locale stringKey="COMPANY_FORM__LINKS__WEBSITES__ADDITIONAL_INFO" />
                    </AdditionalInfo>
                </>
            )}
        </div>
    );
}
