import {
    LocatorPreviewConfigEvent,
    LocatorPreviewEventEnum,
    LocatorPreviewReadyEvent,
} from './store-locator-management-page-type';

export function isLocatorReadyEvent(data: unknown): data is LocatorPreviewReadyEvent {
    return (data as LocatorPreviewReadyEvent)?.eventName === LocatorPreviewEventEnum.Ready;
}

export function isLocatorPreviewConfigEvent(data: unknown): data is LocatorPreviewConfigEvent {
    return (data as LocatorPreviewReadyEvent)?.eventName === LocatorPreviewEventEnum.Update;
}
