import {deserializeV2, fetchAndDeserialize, serialize, serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {PHOTO_REPORT_TYPE_VALUES_MAP} from './phototool-helper';
import {
    MediaItemType,
    photoSchema,
    PhotosOptionsType,
    PhotosRemoveRequestType,
    PhotosReportRequestType,
    photosSchema,
    PhotosSetStatusRequestType,
    PhotosType,
    PhotoToolCatalogStatsType,
    photoToolCounterSchema,
    PhotoToolCounterType,
    photoToolStatsSchema,
    PhotoType,
    PhotoUpdateRequestType,
    videoSchema,
    VideosRemoveRequestType,
    VideosReportRequestType,
    videosSchema,
    VideosSetStatusRequestType,
    VideosType,
    VideoType,
} from './phototool-type';

async function fetchPhotos(option: PhotosOptionsType, mainFilterKey?: string): Promise<PhotosType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<PhotosOptionsType>(option),
        filter_key: mainFilterKey,
    });

    const url = `/phototool/v1/photos/?${searchParameters}`;

    return fetchAndDeserialize(url, photosSchema);
}

async function fetchVideos(option: PhotosOptionsType, mainFilterKey?: string): Promise<VideosType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<PhotosOptionsType>(option),
        filter_key: mainFilterKey,
    });

    const url = `/phototool/v1/videos/?${searchParameters}`;

    return fetchAndDeserialize(url, videosSchema);
}

export async function fetchMedia(
    option: PhotosOptionsType,
    mainFilterKey?: string,
    isVideoMode?: boolean
): Promise<VideosType | PhotosType> {
    if (isVideoMode) {
        return fetchVideos(option, mainFilterKey);
    }

    return fetchPhotos(option, mainFilterKey);
}

export async function fetchPhoto(id: number): Promise<PhotoType> {
    const url = `/phototool/v1/photos/${id}/`;

    return fetchAndDeserialize(url, photoSchema);
}

export async function fetchVideo(id: number): Promise<VideoType> {
    const url = `/phototool/v1/videos/${id}/`;

    return fetchAndDeserialize(url, videoSchema);
}

export async function updatePhoto(photoId: number, photoUpdateRequest: PhotoUpdateRequestType): Promise<PhotoType> {
    const url = `${rootApiUrl}/phototool/v1/photos/${photoId}/`;
    const body = JSON.stringify(serialize<PhotoUpdateRequestType>(photoUpdateRequest));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.patch,
    });

    return deserializeV2<PhotoType>(url, photoSchema, response);
}

export async function updateVideo(videoId: number, videoUpdateRequest: PhotoUpdateRequestType): Promise<MediaItemType> {
    const url = `${rootApiUrl}/phototool/v1/videos/${videoId}/`;
    const body = JSON.stringify(serialize<PhotoUpdateRequestType>(videoUpdateRequest));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.patch,
    });

    return deserializeV2<MediaItemType>(url, videoSchema, response);
}

export function setPhotosStatus(photosSetStatusRequest: PhotosSetStatusRequestType): Promise<void | Error> {
    const url = `${rootApiUrl}/phototool/v1/photos/bulk_change_status/`;
    const body = JSON.stringify(serialize<PhotosSetStatusRequestType>(photosSetStatusRequest));

    return fetchX<void | Error>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });
}

export function setVideosStatus(options: VideosSetStatusRequestType): Promise<void | Error> {
    const url = `${rootApiUrl}/phototool/v1/videos/bulk_change_status/`;
    const body = JSON.stringify(serialize<VideosSetStatusRequestType>(options));

    return fetchX<void | Error>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });
}

export function reportPhotos(photosReportRequest: PhotosReportRequestType): Promise<void> {
    const url = `${rootApiUrl}/phototool/v1/photos/bulk_send_complaints/`;
    const body = JSON.stringify({
        photo_ids: photosReportRequest.photoIds,
        type: PHOTO_REPORT_TYPE_VALUES_MAP[photosReportRequest.type],
        message: photosReportRequest.message,
    });

    return fetchX<void>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });
}

export function removePhotos(photosRemoveRequest: PhotosRemoveRequestType): Promise<void> {
    const url = `${rootApiUrl}/phototool/v1/photos/bulk_send_remove_request/`;
    const body = JSON.stringify(serialize<PhotosRemoveRequestType>(photosRemoveRequest));

    return fetchX<void>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });
}

export function reportVideos(photosReportRequest: VideosReportRequestType): Promise<void> {
    const url = `${rootApiUrl}/phototool/v1/videos/bulk_send_complaints/`;
    const body = JSON.stringify({
        video_ids: photosReportRequest.videoIds,
        type: PHOTO_REPORT_TYPE_VALUES_MAP[photosReportRequest.type],
        message: photosReportRequest.message,
    });

    return fetchX<void>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });
}

export function removeVideos(photosRemoveRequest: VideosRemoveRequestType): Promise<void> {
    const url = `${rootApiUrl}/phototool/v1/videos/bulk_send_remove_request/`;
    const body = JSON.stringify(serialize<VideosRemoveRequestType>(photosRemoveRequest));

    return fetchX<void>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    });
}

export function fetchPhotoCounter(): Promise<PhotoToolCounterType> {
    const url = `/phototool/v1/counter/`;

    return fetchAndDeserialize<PhotoToolCounterType>(url, photoToolCounterSchema);
}

export function resetPhotoCounter(): Promise<void> {
    const url = `${rootApiUrl}/phototool/v1/counter/reset/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchLastPhotos(): Promise<PhotosType> {
    return fetchAndDeserialize(`/phototool/v1/last_photos/`, photosSchema);
}

export function fetchPhotoToolCatalogStats(): Promise<PhotoToolCatalogStatsType> {
    return fetchAndDeserialize(`/phototool/v1/trial/summary/`, photoToolStatsSchema);
}
