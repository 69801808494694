import {z as r} from 'zod';

export const usedNeuralNetworkSuggestionsCountSchema = r.object({
    count: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    byDate: r
        .array(
            r.object({
                date: r.string(), // формат даты DD-MM-YYYY
                count: r.number(),
            })
        )
        .optional(), // Добавляем только в случае без сравнения по периодам
});

export type UsedNeuralNetworkSuggestionsCountType = r.infer<typeof usedNeuralNetworkSuggestionsCountSchema>;
