import {MenuNavigationBadge} from '../../../../../component/menu-navigation-badge/menu-navigation-badge';
import {usePhotoToolCounter} from '../../../../../service/phototool/phototool-hook';

export function PhotoToolBadge(): JSX.Element {
    const {data} = usePhotoToolCounter();

    return (
        <MenuNavigationBadge
            count={data?.count}
            labelLangKey="CATEGORY_NAME__PHOTO_TOOL"
            showCounter={data && data.count > 0}
        />
    );
}
