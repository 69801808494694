import {faArrowRightFromBracket, faBell, faUser} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useLicenses} from '../../../../../provider/license/license-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLogoutMutation} from '../../../../../service/user/user-logout';
import {FeaturesEnum} from '../../../../../service/user/user-type';
import {HeaderDropdownButton} from '../header-dropdown-button';

import * as styles from './profile-menu.scss';

type PropsType = {
    icon: JSX.Element;
};

export function ProfileMenu(props: PropsType): JSX.Element {
    const {icon} = props;

    const {isLogoutShow} = useDomainConfig();
    const {mutate: logout} = useLogoutMutation();
    const {availableFeatures} = useLicenses();

    const items = availableFeatures[FeaturesEnum.settings]
        ? [
              {
                  key: 'profile',
                  icon: <FontAwesomeIcon fixedWidth icon={faUser} />,
                  label: (
                      <NavigationLink to={appRoute.settingsProfile.path}>
                          <Locale stringKey="HEADER__USER__TEXT__PROFILE" />
                      </NavigationLink>
                  ),
              },
              {
                  key: 'notifications',
                  icon: <FontAwesomeIcon fixedWidth icon={faBell} />,
                  label: (
                      <NavigationLink to={appRoute.settingsNotification.path}>
                          <Locale stringKey="HEADER__USER__TEXT__NOTIFICATION" />
                      </NavigationLink>
                  ),
              },
          ]
        : [];

    const logoutItems = [
        {
            type: 'divider',
        } as const,
        {
            key: 'logout',
            icon: <FontAwesomeIcon fixedWidth icon={faArrowRightFromBracket} />,
            label: <Locale stringKey="HEADER__USER__TEXT__LOGOUT" />,
            onClick: () => logout(),
        },
    ];

    return (
        <HeaderDropdownButton
            className={styles.HeaderDropdownButton}
            icon={icon}
            menu={{items: [...items, ...(isLogoutShow ? logoutItems : [])]}}
        />
    );
}
