import {z as r} from 'zod';

export enum ReviewUserTopicFieldEnum {
    name = 'name',
    includeTags = 'includeTags',
    excludeTags = 'excludeTags',
    brands = 'brands',
}

export const reviewUserTopicSchema = r.object({
    id: r.number(),
    [ReviewUserTopicFieldEnum.name]: r.string(),
    [ReviewUserTopicFieldEnum.includeTags]: r.array(r.string()),
    [ReviewUserTopicFieldEnum.excludeTags]: r.array(r.string()),
    [ReviewUserTopicFieldEnum.brands]: r.array(r.number()),
});

export type ReviewUserTopicType = r.infer<typeof reviewUserTopicSchema>;
