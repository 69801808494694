import {fetchAndDeserialize} from '../../../util/api-adapter';
import {CursorPaginationDataType} from '../../../util/cursor-pagination/cursor-pagination-type';
import {FetchMethodEnum, fetchNoContent} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';
import {LocalPackSearchServiceType} from '../local-pack-type';

import {
    LocalPackActivationRuleFormType,
    localPackBrandsResponseSchema,
    LocalPackBrandsResponseType,
    LocalPackRuleFormType,
    localPackRuleSchema,
    localPackRulesSchema,
    LocalPackRulesType,
    LocalPackRuleType,
    localPackSearchServiceItemSchema,
    UpdateLocalPackRuleOptionsType,
} from './local-pack-rule-list-item-type';

export function fetchLocalpackAvailableServices(): Promise<Array<LocalPackSearchServiceType>> {
    const url = '/cp/local_pack/search_services/';

    return fetchAndDeserialize(url, localPackSearchServiceItemSchema.array());
}

export function fetchLocalPackTrialSearchServices(): Promise<Array<LocalPackSearchServiceType>> {
    const url = '/cp/local_pack/available_search_services/';

    return fetchAndDeserialize(url, localPackSearchServiceItemSchema.array());
}

export function fetchLocalpackBrands(): Promise<LocalPackBrandsResponseType> {
    const url = '/cp/local_pack/available_brands/';

    return fetchAndDeserialize(url, localPackBrandsResponseSchema);
}

export function fetchLocalpackRule(ruleId: number): Promise<LocalPackRuleType> {
    const url = `/cp/local_pack/search_configs/${ruleId}/`;

    return fetchAndDeserialize(url, localPackRuleSchema);
}

export function fetchLocalpackRules(options: CursorPaginationDataType): Promise<LocalPackRulesType> {
    const {cursor, pageSize} = options;

    const url = `/cp/local_pack/search_configs/?${objectToUrlParameters({
        cursor,
        page_size: pageSize,
    })}`;

    return fetchAndDeserialize(url, localPackRulesSchema, {
        fetchOptions: {
            skipCache: true,
        },
    });
}

export function updateLocalpackRule(options: UpdateLocalPackRuleOptionsType): Promise<void> {
    const {data, ruleId} = options;
    const url = `${rootApiUrl}/cp/local_pack/search_configs/${ruleId}/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.patch,
        body: JSON.stringify({
            ...data,
            is_active: data.isActive,
        }),
    });
}

export function createLocalpackRule(data: LocalPackRuleFormType): Promise<void> {
    const url = `${rootApiUrl}/cp/local_pack/search_configs/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
    });
}

export function activateLocalPackTrialPeriod(data: LocalPackActivationRuleFormType): Promise<void> {
    const url = `${rootApiUrl}/cp/local_pack/trial/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
    });
}
