import {faCircleInfo, faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';
import {CarouselRef} from 'antd/es/carousel';
import {PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    SortableContainer as createSortableContainer,
    SortableElement as createSortableElement,
} from 'react-sortable-hoc';

import {Spinner} from '../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../provider/locale/locale';
import {
    YandexStoryFileUploaderType,
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
    YandexStorySlideType,
    YandexStoryTypeEnum,
} from '../../../../../../service/yandex-stories/yandex-stories-type';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {arrayMove} from '../../../../../../util/array';
import {classNames} from '../../../../../../util/css';
import {debounce} from '../../../../../../util/function';
import {isValidUrl} from '../../../../../../util/url';
import {Text} from '../../../../../text/text';
import {YandexStoriesCarousel} from '../../../../yandex-stories-preview/carousel/yandex-stories-carousel';
import {YANDEX_STORY__MAX_SLIDES} from '../../../yandex-story-form-helper';
import {YandexStoryFormStep} from '../../step/yandex-story-form-step';

import {YandexStoryActiveSlideControls} from './yandex-story-active-slide-controls';
import {YandexStoryContentStepSlideUpload} from './yandex-story-content-step-slide-upload';
import {
    validateYandexStorySlideImage,
    YANDEX_STORY_MAX_BUTTON_TEXT_LENGTH,
} from './yandex-story-form-slides-content-step-helper';
import {YandexStoryHowToFindActiveSlideControls} from './yandex-story-how-to-find-active-slide-controls';
import * as styles from './yandex-story-form-slides-content-step.scss';

type SortableContainerPropsType = PropsWithChildren<{
    className?: string;
}>;

const SortableContainer = createSortableContainer<SortableContainerPropsType>(
    (sortableContainerProps: SortableContainerPropsType) => {
        const {children, className} = sortableContainerProps;

        return <div className={className}>{children}</div>;
    }
);

type SortableItemPropsType = PropsWithChildren<{
    className?: string;
}>;

const SortableItem = createSortableElement<SortableItemPropsType>((sortableItemProps: SortableItemPropsType) => {
    const {children, className} = sortableItemProps;

    return <div className={className}>{children}</div>;
});

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    onStepFinished: VoidFunction;
    onPreviousStep: VoidFunction;
    isFirstStep: boolean;
};

// eslint-disable-next-line complexity
export function YandexStoryFormSlidesContentStep(props: PropsType): JSX.Element {
    const {formInstance, onStepFinished, onPreviousStep, isFirstStep} = props;

    const slides = Form.useWatch([YandexStoryFormFieldsEnum.Slides], {
        form: formInstance,
        preserve: true,
    });

    const storyType = Form.useWatch([YandexStoryFormFieldsEnum.Type], {
        form: formInstance,
        preserve: true,
    });

    const [activeSlideOrder, setActiveSlideOrder] = useState<number>(1);

    const carouselRef = useRef<CarouselRef | null>(null);

    useEffect(() => {
        carouselRef.current?.goTo(activeSlideOrder - 1);
    }, [activeSlideOrder, slides]);

    const {slidesByOrder, activeSlideKey, orderedSlideEntries} = useMemo(() => {
        const sortedEntries: Array<[string, YandexStorySlideType]> = slides
            ? Object.entries(slides)
                  .sort(([_key1, value1], [_key2, value2]) => value1.order - value2.order)
                  .map(([key, value], index) => {
                      return [
                          key,
                          {
                              ...value,
                              order: index + 1,
                          },
                      ];
                  })
            : [];

        return {
            slidesByOrder: sortedEntries.map(([_key, value]) => value),
            activeSlideKey:
                sortedEntries.map(([key]) => {
                    return key;
                })?.[activeSlideOrder - 1] || null,
            orderedSlideEntries: sortedEntries,
        };
    }, [activeSlideOrder, slides]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onCarouselActiveIndexChange = useCallback(
        debounce((_currentSlideIndex: number, nextSlideIndex: number) => {
            setActiveSlideOrder(nextSlideIndex + 1);
        }, 200),
        []
    );

    const handleSortEnd = useCallback(
        ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
            setActiveSlideOrder(newIndex + 1);

            if (oldIndex === newIndex) {
                return;
            }

            const newStories = arrayMove([...slidesByOrder], oldIndex, newIndex);

            formInstance.setFieldValue(
                [YandexStoryFormFieldsEnum.Slides],
                Object.fromEntries(newStories.map((item, index) => [item.uid, {...item, order: index + 1}]))
            );
        },
        [formInstance, slidesByOrder]
    );

    const handleRemoveActiveSlide = useCallback(() => {
        const newSlidesValue: YandexStoryFileUploaderType = orderedSlideEntries.reduce(
            (accumulator, [key, slideValue]) => {
                if (!slideValue) {
                    return accumulator;
                }

                if (slideValue.order === activeSlideOrder) {
                    return accumulator;
                }

                if (slideValue.order > activeSlideOrder) {
                    return {
                        ...accumulator,
                        [key]: {
                            ...slideValue,
                            order: slideValue.order - 1,
                        },
                    };
                }

                return {
                    ...accumulator,
                    [key]: slideValue,
                };
            },
            {}
        );

        formInstance.setFieldValue([YandexStoryFormFieldsEnum.Slides], newSlidesValue);

        if (activeSlideOrder > Object.keys(newSlidesValue).length) {
            const newActiveOrder = activeSlideOrder - 1;

            setActiveSlideOrder(newActiveOrder);
        }
    }, [activeSlideOrder, formInstance, orderedSlideEntries]);

    const handleStepFinished = useCallback(async () => {
        try {
            await formInstance.validateFields();

            const invalidSlide = slidesByOrder.find((slide) => {
                if (!slide.buttonConfig) {
                    return false;
                }

                const {text, link} = slide.buttonConfig;
                const isValidText = Boolean(text.trim() && text.length <= YANDEX_STORY_MAX_BUTTON_TEXT_LENGTH);
                const isValidLink = Boolean(link.trim() && isValidUrl(link));

                return !(isValidText && isValidLink) || slide.status !== 'done';
            });

            if (invalidSlide) {
                setActiveSlideOrder(invalidSlide.order);
                await formInstance.validateFields();

                throw new Error('validation error');
            }

            onStepFinished();
        } catch {
            const slideWithErrorKeys = formInstance
                .getFieldsError()
                .filter(
                    (fieldError) =>
                        fieldError.errors.length > 0 &&
                        fieldError.name.includes(YandexStoryFormFieldsEnum.Slides) &&
                        fieldError.name[1]
                )
                .map((fieldError) => fieldError.name[1]);

            if (slideWithErrorKeys.length === 0) {
                await formInstance.validateFields();
                return;
            }

            const slidesWithErrorByOrder = slideWithErrorKeys
                .map((slideKey) => {
                    return slides[String(slideKey)];
                })
                .filter(Boolean)
                .sort((slide1, slide2) => slide1.order - slide2.order);

            if (slidesWithErrorByOrder[0]) {
                setActiveSlideOrder(slidesWithErrorByOrder[0].order);
            }
        }
    }, [formInstance, slidesByOrder, onStepFinished, slides]);

    return (
        <YandexStoryFormStep
            leftFooterButtonConfig={{
                langKeyType: isFirstStep ? 'POPUP__BUTTON__CANCEL' : 'BUTTON__BACK',
                onClick: onPreviousStep,
            }}
            rightFooterButtonConfig={{
                langKeyType: 'BUTTON__NEXT',
                onClick: handleStepFinished,
            }}
        >
            <div className={styles.YandexStoryFormSlidesContentStep}>
                <div className={styles.YandexStoryFormSlidesContentStep_contentSettingsWrapper}>
                    <div className={styles.YandexStoryFormSlidesContentStep_carouselWrapper}>
                        <YandexStoriesCarousel
                            afterChange={onCarouselActiveIndexChange}
                            carouselRef={carouselRef}
                            key="yandex-stories-carousel"
                            slides={
                                slidesByOrder.map((item) => {
                                    return {
                                        image: {
                                            url: item.url,
                                        },
                                        buttonConfig: item.buttonConfig,
                                    };
                                }) || []
                            }
                        />
                    </div>

                    {storyType === YandexStoryTypeEnum.Story && activeSlideKey && (
                        <YandexStoryActiveSlideControls
                            activeSlideKey={activeSlideKey}
                            formInstance={formInstance}
                            onDeleteSlide={handleRemoveActiveSlide}
                        />
                    )}

                    {storyType === YandexStoryTypeEnum.Navigation && activeSlideKey && (
                        <YandexStoryHowToFindActiveSlideControls
                            activeSlideKey={activeSlideKey}
                            formInstance={formInstance}
                            onDeleteSlide={handleRemoveActiveSlide}
                        />
                    )}
                </div>

                <div className={styles.YandexStoryFormSlidesContentStep_slidesRailContainer}>
                    <SortableContainer
                        axis="xy"
                        className={styles.YandexStoryFormSlidesContentStep_slidesRail}
                        helperClass={styles.YandexStoryFormSlidesContentStep_slide__dragging}
                        onSortEnd={handleSortEnd}
                    >
                        {slidesByOrder.map((item, index) => {
                            return (
                                <SortableItem index={index} key={item.order}>
                                    <div
                                        className={classNames(styles.YandexStoryFormSlidesContentStep_slide, {
                                            [styles.YandexStoryFormSlidesContentStep_slide__active]:
                                                item.order === activeSlideOrder,
                                        })}
                                        style={{
                                            backgroundImage: `url("${item.url}")`,
                                        }}
                                    >
                                        {item.status === 'uploading' && <Spinner />}

                                        {item.status === 'error' && <FontAwesomeIcon icon={faTriangleExclamation} />}
                                    </div>
                                </SortableItem>
                            );
                        })}

                        {Object.keys(slides || {}).length < YANDEX_STORY__MAX_SLIDES && (
                            <YandexStoryContentStepSlideUpload formInstance={formInstance} />
                        )}
                    </SortableContainer>

                    <div className={styles.YandexStoryFormSlidesContentStep_counterWrapper}>
                        <Popover
                            content={
                                <ul className={styles.YandexStoryFormSlidesContentStep_popoverContent}>
                                    <li>
                                        <Locale stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_1__SHORT" />
                                    </li>
                                    <li>
                                        <Locale stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_2__SHORT" />
                                    </li>
                                    <li>
                                        <Locale stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_3__SHORT" />
                                    </li>
                                    <li>
                                        <Locale stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_4__SHORT" />
                                    </li>
                                </ul>
                            }
                        >
                            <Text block lightest>
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </Text>
                        </Popover>
                        <Text block className={styles.YandexStoryFormSlidesContentStep_counterWrapper} lighter>
                            {`${Object.keys(slides || {}).length} / ${YANDEX_STORY__MAX_SLIDES}`}
                        </Text>
                    </div>
                </div>

                {Object.keys(slides || {}).map((key) => (
                    <Form.Item
                        hidden
                        key={key}
                        name={[YandexStoryFormFieldsEnum.Slides, key]}
                        rules={[validateYandexStorySlideImage()]}
                    />
                ))}
            </div>
        </YandexStoryFormStep>
    );
}
