import {Alert, Button, Flex} from 'antd';
import {ReactElement} from 'react';
import {z as r} from 'zod';

import {appRoute} from '../../../../../../app-route';
import {Text} from '../../../../../../component/text/text';
import {spacer_l, spacer_xl} from '../../../../../../css/var-export.scss';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../../provider/locale/locale';
import {getFromLocalStorage, saveToLocalStorage} from '../../../../../../util/local-storage';

import bannerImage from './images/phoneGroups.png';
import {IS_SHOW_SOURCES_REVIEW_BANNER_STORAGE_KEY} from './review-sources-banner-const';
import {BannerStateEnum, useReviewSourcesBanner} from './review-sources-banner-hook';
import * as styles from './review-sources-banner.scss';

export function ReviewSourcesBanner(): ReactElement | null {
    const isShowBannerFromLocalStorage = getFromLocalStorage(
        IS_SHOW_SOURCES_REVIEW_BANNER_STORAGE_KEY,
        r.boolean(),
        true
    );
    const {data, isError, isLoading} = useReviewSourcesBanner(isShowBannerFromLocalStorage);

    if (isError || isLoading || data?.state === BannerStateEnum.Hidden || !isShowBannerFromLocalStorage) {
        return null;
    }

    function onClose() {
        saveToLocalStorage(false, IS_SHOW_SOURCES_REVIEW_BANNER_STORAGE_KEY);
    }

    return (
        <Alert
            closable={data?.state === BannerStateEnum.Closable}
            description={
                <Flex className={styles.ReviewSourcesBanner_catalogsWrapper} gap={spacer_xl} vertical>
                    <Flex gap={spacer_l}>
                        {data?.info.catalogs.map((catalog) => (
                            <div className={styles.ReviewSourcesBanner_catalogBlock} key={catalog.website}>
                                <img alt="" src={catalog.logo} />
                                <Text>{catalog.website}</Text>
                            </div>
                        ))}
                    </Flex>
                    <NavigationLink
                        className={styles.ReviewSourcesBanner_button}
                        to={appRoute.settingsSourceSettings.path}
                    >
                        <Button className={styles.ReviewSourcesBanner_button} type="primary">
                            <Locale stringKey="REVIEW__SOURCES_BANNER__BUTTON" />
                        </Button>
                    </NavigationLink>
                    <img alt="" className={styles.ReviewSourcesBanner_image} src={bannerImage} />
                </Flex>
            }
            message={
                <Text
                    block
                    bolder
                    className={styles.ReviewSourcesBanner_title}
                    large
                    stringKey="REVIEW__SOURCES_BANNER__TITLE"
                />
            }
            onClose={onClose}
            rootClassName={styles.ReviewSourcesBanner}
        />
    );
}
