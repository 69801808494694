import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {memo, useCallback, useEffect, useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {RatingStarLine} from '../../../../../../layout/rating-star-line/rating-star-line';
import {useReviewLogsList} from '../../../../../../service/reviews/reviews-logs';
import {useResponsibleUsers} from '../../../../../../service/reviews/reviews-responsible-users';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {ReviewAbuse} from '../../../review-abuse/review-abuse';
import {useReviewActivityHook} from '../../../review-activity-provider/review-activity-provider';
import {ReviewActivitySelector} from '../../../review-activity-selector/review-activity-selector';
import {ReviewAnswers} from '../../../review-answers/review-answers';
import {ReviewCatalogLogo} from '../../../review-catalog-logo/review-catalog-logo';
import {ReviewCompanyTitle} from '../../../review-company-title/review-company-title';
import {ReviewCreatedInRdDate} from '../../../review-created-in-rd-date/review-created-in-rd-date';
import {ReviewHistory} from '../../../review-history/review-history';
import {ReviewLogs} from '../../../review-logs/review-logs';
import {ReviewNotes} from '../../../review-notes/review-notes';
import {ReviewStatus} from '../../../review-status/review-status';
import {getReviewCreationDate, isReviewReplyAllowed} from '../../../reviews-helper';
import {useReviewStateHook} from '../../../reviews-state/reviews-state-provider';
import {TagList} from '../../../tag-list/tag-list';
import {ReviewDataWithCatalogType} from '../review-list-type';

import {CopyComment} from './copy-comment/copy-comment';
import {CopyLink} from './copy-link/copy-link';
import {ResponsibleUser} from './responsible-user/responsible-user';
import {ReviewComment} from './review-comment/review-comment';
import {ReviewCondition} from './review-condition/review-condition';
import {ReviewDelete} from './review-delete/review-delete';
import {ReviewEdit} from './review-edit/review-edit';
import * as styles from './review.scss';

type PropsType = {
    className?: string;
    reviewData: ReviewDataWithCatalogType;
};

// eslint-disable-next-line complexity, max-statements
export const Review = memo(function ReviewInner(props: PropsType): JSX.Element {
    const {className, reviewData} = props;

    const {
        refreshCatalogs,
        refreshReview,
        refreshReviewsInfo,
        reviewsInfo,
        reviewsCatalogListIsInProgress,
        suggestionMap,
        refreshSuggestions,
        reviewsScrapyCatalogsList = [],
    } = useReviewStateHook();
    const suggestion = suggestionMap[reviewData.id];

    const info = reviewsInfo?.results.find((reviewInfo) => reviewInfo.id === reviewData.id) || null;

    const {
        id,
        catalog,
        brand,
        company,
        author,
        originUrl,
        rating,
        children,
        deletedAt,
        ableToChangeResponsibleUser,
        responsibleUser,
        createdInCatalog,
        createdInRd,
        comment,
        processStatus,
        isChanged,
        tags,
        userFields,
        moderated,
        hasAccountError,
    } = reviewData;

    const scrapyCatalog = reviewsScrapyCatalogsList?.find((item) => item.id === catalog?.id);

    const isReviewDeleted = Boolean(deletedAt);

    const {isHistoryVisible} = useReviewActivityHook();
    const {refetch: loadLogs} = useReviewLogsList(id, isHistoryVisible);

    const {getFormattedDateTime} = useFormat();

    const handleSubmitFinishReviewForm = useCallback(() => {
        if (refreshReview) {
            refreshReview(id);
        }

        if (refreshCatalogs) {
            refreshCatalogs();
        }
    }, [id, refreshCatalogs, refreshReview]);

    const [review, setReview] = useState<ReviewDataWithCatalogType | null>(null);
    const [isResponsibleUsersLoading, setIsResponsibleUsersLoading] = useState(false);

    const {data: responsibleUsersList} = useResponsibleUsers(review);

    const isExternalReview = catalog?.is_external_reviews;

    const isGptSuggestSupported = catalog?.gpt_suggest_supported;

    function loadResponsibleUsersList() {
        setIsResponsibleUsersLoading(true);
        setReview(reviewData);
    }

    function handleRefreshAbuseReview(reviewId: number) {
        return Promise.all([refreshReview(reviewId), loadLogs()]);
    }

    useEffect(() => {
        setIsResponsibleUsersLoading(false);
    }, [responsibleUsersList]);

    const notesCount = info?.notesCount || 0;
    const isAnswerUnavailable = Boolean(reviewData && !isReviewReplyAllowed(reviewData));

    return (
        <div className={classNames(styles.Review, className)}>
            <div>
                <div className={styles.Review_catalogLine}>
                    <div className={styles.Review_catalog}>
                        <div className={styles.Review_catalogLogo}>
                            <ReviewCatalogLogo catalog={catalog} isCatalogLoading={reviewsCatalogListIsInProgress} />
                        </div>

                        <Text className={styles.Review_catalogName}>{catalog?.name || ''}</Text>
                    </div>

                    <ReviewCreatedInRdDate createdInRd={createdInRd}>
                        <div className={styles.Review_date}>
                            {getFormattedDateTime(new Date(getReviewCreationDate(reviewData)), {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })}
                        </div>
                    </ReviewCreatedInRdDate>
                </div>

                <div className={styles.Review_catalogCompanyLine}>
                    <div className={styles.Review_companyTitle}>
                        <ReviewCompanyTitle brand={brand} company={company} />

                        {!isReviewDeleted && originUrl && (
                            <a className={styles.Review_externalLink} href={originUrl} rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                        )}
                    </div>

                    <div className={styles.Review_actions}>
                        <CopyComment text={comment || ''} />
                        <CopyLink reviewId={id} />

                        <ReviewAbuse
                            hasAccountError={hasAccountError}
                            refreshReview={handleRefreshAbuseReview}
                            reviewData={reviewData}
                            scrapyCatalogInfo={scrapyCatalog}
                        />

                        {isChanged && (
                            <ReviewHistory
                                createdInCatalog={createdInCatalog}
                                createdInRd={createdInRd}
                                reviewId={id}
                            />
                        )}

                        {isExternalReview && !deletedAt && (
                            <>
                                <ReviewEdit onSubmitFinish={handleSubmitFinishReviewForm} review={reviewData} />
                                <ReviewDelete onSubmitFinish={handleSubmitFinishReviewForm} reviewId={id} />
                            </>
                        )}
                    </div>

                    <div className={styles.Review_authorLineLeft}>
                        {author && <div className={styles.Review_author}>{`${author} ${userFields.phone ?? ''}`}</div>}
                        {typeof rating === 'number' && <RatingStarLine rating={rating} />}
                    </div>
                    {userFields?.email && (
                        <Text block lighter>
                            {userFields.email}
                        </Text>
                    )}

                    <ReviewComment comment={comment} reviewId={id} />
                </div>
            </div>

            <div className={styles.Review_activitySection}>
                <div className={styles.Review_activityPrimary}>
                    <div className={styles.Review_activityPrimaryItem}>
                        {ableToChangeResponsibleUser && (
                            <ResponsibleUser
                                isLoading={isResponsibleUsersLoading}
                                onChange={loadLogs}
                                onOpen={loadResponsibleUsersList}
                                responsibleUser={responsibleUser}
                                responsibleUsersList={responsibleUsersList?.results || null}
                                reviewId={id}
                            />
                        )}
                    </div>

                    <ReviewStatus
                        className={styles.Review_status}
                        onChange={loadLogs}
                        processStatus={processStatus}
                        refreshReview={refreshReview}
                        reviewId={id}
                    />
                </div>

                <TagList deletedAt={deletedAt} refreshReview={refreshReview} reviewId={id} tags={tags} />

                <div>
                    <div className={styles.Review_activityControls}>
                        <ReviewActivitySelector
                            isAnswerAvailable={!isAnswerUnavailable}
                            notesCount={notesCount}
                            reviewChildren={children}
                        />

                        <div className={styles.Review_condition}>
                            <ReviewCondition
                                hasAccountError={hasAccountError}
                                hasCompanyComment={children.some((child) => child.isCompanyComment)}
                                isAnswerUnavailable={isAnswerUnavailable}
                                isReviewDeleted={isReviewDeleted}
                                moderated={moderated}
                                scrapyCatalogInfo={scrapyCatalog}
                            />
                        </div>
                    </div>

                    <ReviewAnswers
                        isCollapsedAnswers
                        isGptSuggestSupported={isGptSuggestSupported}
                        refreshReview={refreshReview}
                        refreshSuggestions={refreshSuggestions}
                        review={reviewData}
                        suggestion={suggestion}
                    />

                    <ReviewNotes onRefresh={refreshReviewsInfo} reviewId={id} />
                    <ReviewLogs
                        reviewAuthor={originUrl ? null : author}
                        reviewCreatedInCatalog={createdInCatalog}
                        reviewId={id}
                    />
                </div>
            </div>
        </div>
    );
});
