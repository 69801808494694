import {FormInstance} from 'antd';

import {
    EmailNotificationsEnum,
    EmailNotificationsType,
    NotificationTextReviewEnum,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {findInArrayByValueEnsure} from '../../../../util/array';
import {textReviewTypeList} from '../notification-review-filter/notification-review-filter-const';
import {NotificationsReviewsFilterType} from '../notification-review-filter/notification-review-filter-type';

export function serializeNotificationEmail(
    form: FormInstance<EmailNotificationsType & NotificationsReviewsFilterType>
): EmailNotificationsType {
    const values = form.getFieldsValue();

    const isNotificationsTurnedOn =
        values[EmailNotificationsEnum.SendReviewsNotifications] ||
        values[EmailNotificationsEnum.SendReviewChangesNotifications];

    return {
        ...values,
        [EmailNotificationsEnum.SendReviewNotificationCatalogs]: isNotificationsTurnedOn ? values.catalog : [],
        [EmailNotificationsEnum.SendReviewNotificationRatings]: isNotificationsTurnedOn ? values.ratings : [],
        [EmailNotificationsEnum.SendReviewNotificationType]: findInArrayByValueEnsure<NotificationTextReviewEnum>(
            textReviewTypeList,
            values.type
        ),
    };
}
