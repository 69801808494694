import {useContext} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {CursorPaginator} from '../../../../component/cursor-paginator/cursor-paginator';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useAllCatalogs} from '../../../../provider/catalogs/catalogs-hook';
import {CatalogType} from '../../../../provider/catalogs/catalogs-type';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {MainFilterContext} from '../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../provider/main-filter/main-filter-type';
import {useSourcesConfirmation} from '../../../../service/confirmation/confirmation-hook';
import {productGroupsSizeOptions} from '../../price/products-const';

import {ConfirmationItem} from './confirmation-item/confirmation-item';
import * as styles from './confirmations.scss';

// eslint-disable-next-line complexity
export function SourcesConfirmations(): JSX.Element {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {
        result: confirmationResult,
        isInProgress: isConfirmationsInProgress,
        reset,
        pagination,
    } = useSourcesConfirmation({mainFilterKey});

    const {catalogs, isLoadingCatalogs} = useAllCatalogs();
    const {sources: sourcesRoute, sourcesConfirmations: sourcesConfirmationsRoute} = appRoute;

    const isInProgress = isLoadingCatalogs || isConfirmationsInProgress;

    const {getLocalizedString} = useLocale();

    if (!isInProgress && (!confirmationResult || !catalogs)) {
        return <AlertFallback />;
    }

    const lastPage =
        confirmationResult?.count &&
        confirmationResult?.pageSize &&
        Math.ceil(confirmationResult.count / confirmationResult.pageSize);

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__SOURCES_CONFIRMATION')} />

            <BreadCrumbs
                list={[
                    {path: sourcesRoute.path, titleLangKey: 'CATEGORY_NAME__SOURCES'},
                    {
                        path: sourcesConfirmationsRoute.path,
                        titleLangKey: 'SOURCES_CONFIRMATION__BREADCRUMBS',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__SOURCES_CONFIRMATION" />
            </PageHeader>

            <div>
                {isInProgress && <Spinner />}

                {!isInProgress && confirmationResult?.results?.length === 0 && (
                    <div className={styles.confirmations__empty_list}>
                        <Locale stringKey="SOURCES_CONFIRMATION__EMPTY_LIST" />
                    </div>
                )}

                {!isInProgress && confirmationResult?.results && confirmationResult.results.length > 0 && (
                    <div>
                        {confirmationResult.results.map((confirmationData) => {
                            const catalogData: CatalogType | null =
                                catalogs?.find((catalog) => {
                                    return catalog.website === confirmationData.catalog;
                                }) || null;

                            return (
                                <ConfirmationItem
                                    catalogData={catalogData}
                                    confirmationData={confirmationData}
                                    key={String(confirmationData.createdAt)}
                                    onActionSuccess={reset}
                                />
                            );
                        })}
                    </div>
                )}
            </div>

            <CursorPaginator
                cursorPagination={pagination}
                dataLength={confirmationResult?.results.length || 0}
                lastPage={lastPage}
                pageSizeOptions={productGroupsSizeOptions}
            />
        </Page>
    );
}
