import {z as r} from 'zod';

export enum CompaniesReportCentrifugeStatusEnum {
    Done = 'DONE',
    Failed = 'FAILED',
}

const companiesReportCentrifugeActionDataSchema = r.object({
    status: r.nativeEnum(CompaniesReportCentrifugeStatusEnum),
    report_uuid: r.string(),
    link: r.string(),
});

export type CompaniesReportCentrifugeActionDataType = r.infer<typeof companiesReportCentrifugeActionDataSchema>;
