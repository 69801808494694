import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {useLicenses} from '../../../provider/license/license-hook';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useProfile} from '../../../service/profile/profile-hook';
import {FeaturesEnum} from '../../../service/user/user-type';

import {IntegrationButton} from './integration-button/integration-button';
import {hasUsedeskFilled} from './integrations-helper';
import * as styles from './integrations.scss';

export function Integrations(): JSX.Element {
    const {result: profileResult} = useProfile();
    const {getLocalizedString} = useLocale();
    const {companyName} = useDomainConfig();
    const {licenses} = useLicenses();

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__INTEGRATION')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.settingsIntegrations.path,
                        titleLangKey: 'CATEGORY_NAME__INTEGRATION',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="INTEGRATIONS__HEADER" />
            </PageHeader>

            <PageSubHeader>
                <Locale stringKey="INTEGRATIONS__SUB_HEADER" valueMap={{companyName}} />
            </PageSubHeader>

            <div className={styles.integration_button__wrapper}>
                <IntegrationButton
                    hasTick={licenses[FeaturesEnum.usedeskIntegration]?.isActive && hasUsedeskFilled(profileResult)}
                    link={
                        licenses[FeaturesEnum.usedeskIntegration]?.isActive
                            ? appRoute.settingsIntegrationUsedesk.path
                            : ''
                    }
                    nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__USEDESK"
                />

                <IntegrationButton
                    link={
                        profileResult?.profile.can_use_intra_service
                            ? appRoute.settingsIntegrationIntraService.path
                            : ''
                    }
                    nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__INTRASERVICE"
                />

                <IntegrationButton link="" nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__ZENDESK" />

                <IntegrationButton link="" nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__FRESHDESK" />

                <IntegrationButton link="" nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__NAUMEN" />

                <IntegrationButton link="" nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__PYRUS" />

                <IntegrationButton link="" nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__YOUR_SYSTEM" />

                <IntegrationButton link="" nameLangKey="INTEGRATIONS__INTEGRATOR_NAME__API_INTEGRATION" />
            </div>
        </Page>
    );
}
