import {Empty} from 'antd';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {TechnicalSupportCreateModal} from '../technical-support-create-modal/technical-support-create-modal';

import * as styles from './technical-support-table-empty.scss';

type PropsType = {
    hasFilters?: boolean;
};

export function TechnicalSupportTableEmpty(props: PropsType): JSX.Element {
    const {hasFilters} = props;
    const {getLocalizedString} = useLocale();

    return (
        <Empty
            className={styles.TechnicalSupportTableEmpty_image}
            description={getLocalizedString(
                hasFilters
                    ? 'HELP_TECHNICAL_SUPPORT__TABLE__EMPTY_WITH_FILTERS__DESCRIPTION'
                    : 'HELP_TECHNICAL_SUPPORT__TABLE__EMPTY__DESCRIPTION'
            )}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
            {!hasFilters && <TechnicalSupportCreateModal />}
        </Empty>
    );
}
