import {Tooltip} from 'antd';
import {useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/locale';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {localPackNullPositionValue} from '../../local-pack-const';

import {LocalPackPositionChangeIcon} from './local-pack-position-change-icon';
import * as styles from './local-pack-position-change.scss';

export function LocalPackPositionChange(props: PropsType): JSX.Element {
    const {currentPosition, previousPosition, delta} = props;
    const currentPositionNumber = currentPosition ?? localPackNullPositionValue;

    const {getFormattedNumber} = useFormat();

    const positionChange: number | null = delta || (previousPosition ? previousPosition - currentPositionNumber : null);

    const fullPreviousClassName = useMemo(() => {
        return classNames(styles.LocalPackPositionChange_previousPosition, {
            [styles.LocalPackPositionChange_previousPosition__up]: Boolean(positionChange && positionChange > 0),
            [styles.LocalPackPositionChange_previousPosition__down]: Boolean(positionChange && positionChange < 0),
            [styles.LocalPackPositionChange_previousPosition__noChange]: Boolean(!positionChange),
        });
    }, [positionChange]);

    const tooltipTitle: LangKeyType = useMemo(() => {
        if (!positionChange) {
            return 'LOCAL_PACK__POSITION_CHANGE__NOT_CHANGED';
        }

        if (positionChange > 0) {
            return 'LOCAL_PACK__POSITION_CHANGE__UP';
        }

        return 'LOCAL_PACK__POSITION_CHANGE__DOWN';
    }, [positionChange]);

    return (
        <Tooltip placement="bottom" title={<Locale stringKey={tooltipTitle} />}>
            <div className={styles.LocalPackPositionChange}>
                <Text block>
                    {currentPositionNumber === localPackNullPositionValue
                        ? `21+`
                        : getFormattedNumber(currentPositionNumber, {maximumFractionDigits: 0})}
                </Text>
                <Text block className={fullPreviousClassName} small>
                    {positionChange !== null && (
                        <>
                            <LocalPackPositionChangeIcon delta={positionChange} />
                            <span>{getFormattedNumber(Math.abs(positionChange), {maximumFractionDigits: 0})}</span>
                        </>
                    )}
                </Text>
            </div>
        </Tooltip>
    );
}

type PropsType = {
    currentPosition: number | null;
} & (
    | {
          previousPosition: number | null;
          delta?: never;
      }
    | {
          previousPosition?: never;
          delta: number | null;
      }
);
