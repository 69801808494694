import {deserializeV2, serialize, serializeToURLParameters} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';
import {PaginatedResponseType} from '../../api/api-type';

import {handleManageSourceLinkError, handleSourceListError} from './source-links-helper';
import {
    BrandLinkListRequestType,
    brandLinkListResponseSchema,
    brandLinkSchema,
    BrandLinkType,
    CompanyLinkListRequestType,
    companyLinkListResponseSchema,
    companyLinkSchema,
    CompanyLinkType,
    CreateBrandLinkType,
    CreateCompanyLinkType,
    DeleteBrandLinkType,
    DeleteCompanyLinkType,
    UpdateBrandLinkType,
    UpdateCompanyLinkType,
} from './source-links-type';

export async function fetchBrandLinkList(
    options: BrandLinkListRequestType
): Promise<PaginatedResponseType<BrandLinkType>> {
    const searchParameters = objectToUrlParameters(serializeToURLParameters<BrandLinkListRequestType>(options));
    const url = `${rootApiUrl}/v1/source_settings/brand_links/?${searchParameters}`;
    const response = await fetchX(url, {method: FetchMethodEnum.get}).catch(handleSourceListError);

    return deserializeV2<PaginatedResponseType<BrandLinkType>>(url, brandLinkListResponseSchema, response);
}

export async function createBrandLink(payload: CreateBrandLinkType): Promise<BrandLinkType> {
    const url = `${rootApiUrl}/v1/source_settings/brand_links/`;
    const body = JSON.stringify(serialize<CreateBrandLinkType>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    }).catch(handleManageSourceLinkError);

    return deserializeV2<BrandLinkType>(url, brandLinkSchema, response);
}

export async function updateBrandLink(id: number, payload: UpdateBrandLinkType): Promise<BrandLinkType> {
    const url = `${rootApiUrl}/v1/source_settings/brand_links/${id}/`;
    const body = JSON.stringify(serialize<UpdateBrandLinkType>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.put,
    }).catch(handleManageSourceLinkError);

    return deserializeV2<BrandLinkType>(url, brandLinkSchema, response);
}

export function deleteBrandLink(id: number, payload: DeleteBrandLinkType): Promise<void> {
    const url = `${rootApiUrl}/v1/source_settings/brand_links/${id}/`;
    const body = JSON.stringify(serialize<DeleteBrandLinkType>(payload));

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.delete,
    }).catch(handleManageSourceLinkError);
}

export async function fetchCompanyLinkList(
    options: CompanyLinkListRequestType
): Promise<PaginatedResponseType<CompanyLinkType>> {
    const searchParameters = objectToUrlParameters(serializeToURLParameters<CompanyLinkListRequestType>(options));
    const url = `${rootApiUrl}/v1/source_settings/company_links/?${searchParameters}`;
    const response = await fetchX(url, {method: FetchMethodEnum.get}).catch(handleSourceListError);

    return deserializeV2<PaginatedResponseType<CompanyLinkType>>(url, companyLinkListResponseSchema, response);
}

export async function createCompanyLink(payload: CreateCompanyLinkType): Promise<CompanyLinkType> {
    const url = `${rootApiUrl}/v1/source_settings/company_links/`;
    const body = JSON.stringify(serialize<CreateCompanyLinkType>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.post,
    }).catch(handleManageSourceLinkError);

    return deserializeV2<CompanyLinkType>(url, companyLinkSchema, response);
}

export async function updateCompanyLink(id: number, payload: UpdateCompanyLinkType): Promise<BrandLinkType> {
    const url = `${rootApiUrl}/v1/source_settings/company_links/${id}/`;
    const body = JSON.stringify(serialize<UpdateCompanyLinkType>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.put,
    }).catch(handleManageSourceLinkError);

    return deserializeV2<BrandLinkType>(url, brandLinkSchema, response);
}

export function deleteCompanyLink(id: number, payload: DeleteCompanyLinkType): Promise<void> {
    const url = `${rootApiUrl}/v1/source_settings/company_links/${id}/`;
    const body = JSON.stringify(serialize<DeleteCompanyLinkType>(payload));

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.delete,
    }).catch(handleManageSourceLinkError);
}
