import {Select} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {renderSelectOption} from '../drawer-filter-helper';
import {SelectOptionType} from '../drawer-filter-type';

type PropsType = {
    value?: number;
    onChange?: (value: number) => void;
    isInProgress: boolean;
    competitorOptions: Array<SelectOptionType<number>>;
};

export function CompetitorsSelect(props: PropsType): JSX.Element {
    const {competitorOptions, value, onChange, isInProgress} = props;

    return (
        <Select<number>
            allowClear
            disabled={isInProgress}
            loading={isInProgress}
            onChange={onChange}
            optionFilterProp="children"
            placeholder={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__COMPETITOR__PLACEHOLDER" />}
            value={value}
        >
            {competitorOptions.map(renderSelectOption)}
        </Select>
    );
}
