import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Modal, Select, Space, Tooltip} from 'antd';

import {Text} from '../../../../component/text/text';
import {useBrands} from '../../../../provider/brands/brand-hook';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useDoubleGisAccountConnection} from '../../../../provider/onboarding/onboarding-hook';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {Form} from '../../../../typings/antd';

import {
    SyncDoubleGisAccountFormEnum,
    syncDoubleGisAccountFormId,
    SyncDoubleGisAccountFormType,
} from './sync-doublegis-account-popup-const';
import * as styles from './sync-doublegis-account-popup.scss';

type PropsType = {
    onClose: () => void;
};

export function SyncDoubleGisAccountPopup(props: PropsType): JSX.Element {
    const {onClose} = props;

    const {companyName} = useDomainConfig();

    const {getLocalizedString} = useLocale();
    const [form] = Form.useForm<SyncDoubleGisAccountFormType>();
    const {brands, isLoading: isLoadingBrands} = useBrands();
    const {mutate} = useDoubleGisAccountConnection(onClose);
    const {emailFieldRule, getPhoneNumberRule, requiredFieldRule} = useFormRules();

    return (
        <Modal
            footer={
                <footer className={styles.SyncDoubleGisAccountPopup_footer}>
                    <Space>
                        <Button onClick={onClose}>
                            <Locale stringKey="POPUP__BUTTON__CANCEL" />
                        </Button>

                        <Button form={syncDoubleGisAccountFormId} htmlType="submit" type="primary">
                            <Locale stringKey="POPUP__BUTTON__CONFIRM" />
                        </Button>
                    </Space>
                </footer>
            }
            onCancel={onClose}
            open
            title={<Locale stringKey="ONBOARDING__ACCOUNT_CONNECT__DOUBLEGIS__TITLE" />}
        >
            <Form<SyncDoubleGisAccountFormType>
                form={form}
                id={syncDoubleGisAccountFormId}
                layout="vertical"
                onFinish={mutate}
            >
                <Space direction="vertical">
                    <Text
                        secondary
                        stringKey="ONBOARDING__ACCOUNT_CONNECT__DOUBLEGIS__DESCRIPTION"
                        valueMap={{companyName}}
                    />

                    <Form.Item<SyncDoubleGisAccountFormType>
                        label={<Locale stringKey="ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__BRANDS" />}
                        name={SyncDoubleGisAccountFormEnum.Brand}
                        required
                        rules={[requiredFieldRule]}
                    >
                        <Select
                            loading={isLoadingBrands}
                            options={brands.map((brand) => {
                                return {value: brand.id, label: brand.name};
                            })}
                            placeholder={
                                <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__PLACEHOLDER" />
                            }
                        />
                    </Form.Item>
                    <Form.Item<SyncDoubleGisAccountFormType>
                        label={<Locale stringKey="ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__NAME" />}
                        name={SyncDoubleGisAccountFormEnum.FullName}
                        required
                        rules={[requiredFieldRule]}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__NAME__PLACEHOLDER'
                            )}
                        />
                    </Form.Item>
                    <Form.Item<SyncDoubleGisAccountFormType>
                        label={<Locale stringKey="COMPANY_FORM__CONTACT_INFO__EMAIL__LABEL" />}
                        name={SyncDoubleGisAccountFormEnum.Email}
                        required
                        rules={[emailFieldRule, requiredFieldRule]}
                    >
                        <Input placeholder={getLocalizedString('COMPANY_FORM__CONTACT_INFO__EMAIL__PLACEHOLDER')} />
                    </Form.Item>
                    <Form.Item<SyncDoubleGisAccountFormType>
                        label={
                            <Space>
                                <Locale stringKey="COMPANY_FORM__CONTACT_INFO__PHONE__LABEL" />
                                <Tooltip
                                    title={
                                        <Locale stringKey="ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__PHONE__TOOLTIP" />
                                    }
                                >
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                </Tooltip>
                            </Space>
                        }
                        name={SyncDoubleGisAccountFormEnum.Phone}
                        required
                        rules={[getPhoneNumberRule()]}
                    >
                        <Input placeholder={getLocalizedString('COMPANY_FORM__CONTACT_INFO__PHONE__PLACEHOLDER')} />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
}
