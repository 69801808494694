import {Checkbox, CheckboxOptionType, Skeleton} from 'antd';
import {useMemo} from 'react';

import {useBrands} from '../../../../../../provider/brands/brand-hook';
import {BrandType} from '../../../../../../provider/brands/brand-type';

import * as styles from './brands-checkbox-group.scss';

type PropsType = {
    value?: Array<number>;
    onChange?: (value: Array<number>) => void;
};

export function BrandsCheckboxGroup(props: PropsType): JSX.Element {
    const {value, onChange} = props;

    const {brands, isLoading: isBrandsInProgress} = useBrands();

    const brandOptions: Array<CheckboxOptionType> = useMemo<Array<CheckboxOptionType>>(
        (): Array<CheckboxOptionType> =>
            brands.map<CheckboxOptionType>(
                (brand: BrandType): CheckboxOptionType => ({
                    value: brand.id,
                    label: brand.name,
                })
            ),
        [brands]
    );

    if (isBrandsInProgress) {
        return <Skeleton.Input active className={styles.brands_checkbox_group__skeleton} />;
    }

    return <Checkbox.Group onChange={onChange} options={brandOptions} value={value} />;
}
