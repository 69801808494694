import {Typography} from 'antd';
import {TextProps} from 'antd/lib/typography/Text';

import {LocalePropsType} from '../../provider/locale/library/localization-type';
import {Locale} from '../../provider/locale/localization';
import {LangKeyType} from '../../provider/locale/translation/type';
import {classNames} from '../../util/css';

import * as styles from './text.scss';

type PropsType = TextProps &
    Partial<LocalePropsType<LangKeyType>> & {
        className?: string;
        small?: boolean; // font: 12px / 20px
        large?: boolean; // font: 16px / 24px
        lighter?: boolean; // color: gray-8
        secondary?: boolean; // color: gray-7
        lightest?: boolean; // color: gray-6
        bolder?: boolean; // font-weight: 500
        bold?: boolean; // font-weight: 700
        italic?: boolean; // font-style: italic
        block?: boolean; // display: block
        inheritColor?: boolean; // color: inherit
        capitalize?: boolean; // text-transform: capitalize;
        tabularNums?: boolean; // font-variant-numeric: tabular-nums;
    };

export function Text(props: PropsType): JSX.Element {
    const {
        className,
        small,
        large,
        lighter,
        secondary,
        lightest,
        bolder,
        bold,
        italic,
        block,
        stringKey,
        valueMap,
        inheritColor,
        capitalize,
        tabularNums,
        ...restProps
    } = props;

    function getSizeClassname() {
        if (small) {
            return styles.Size__small;
        }

        if (large) {
            return styles.Size__large;
        }

        return styles.Size__middle;
    }

    const classnames = {
        [styles.Color__lighter]: lighter,
        [styles.Color__lightest]: lightest,
        [styles.Color__inherit]: inheritColor,
        [styles.FontWeight__bolder]: bolder,
        [styles.FontWeight__bold]: bold,
        [styles.Italic]: italic,
        [styles.Block]: block,
        [styles.TabularNums]: tabularNums,
        [styles.Capitalize]: capitalize,
    };

    const propsWithChildren = {
        ...restProps,
        children: stringKey ? <Locale stringKey={stringKey} valueMap={valueMap} /> : restProps.children,
    };

    return (
        <Typography.Text
            className={classNames(className, getSizeClassname(), classnames)}
            // eslint-disable-next-line no-undefined
            type={secondary ? 'secondary' : undefined}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propsWithChildren}
        />
    );
}
