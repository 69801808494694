import {lazy, Suspense} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/localization';
import {usePhotoList} from '../../../../service/phototool/phototool-hook';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {PhotoImage} from './photo-image/photo-image';
import {defaultPhotosPageSize} from './photos-const';
import * as styles from './photos.scss';

const Carousel = lazy(() => import('../../../../component/carousel/carousel'));

export function PhotosList(): JSX.Element {
    const {isInProgress, result, processError} = usePhotoList({
        filter: {
            status: null,
            source: null,
            tags: null,
            startDate: null,
            endDate: null,
            own: null,
        },
        isVideoMode: false,
        mainFilterKey: '',
        paginationOptions: {
            defaultPageSize: defaultPhotosPageSize,
            shouldSaveState: false,
        },
    });

    if (isInProgress || !result) {
        return <Spinner />;
    }

    if (processError) {
        return <AlertFallback />;
    }

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={
                        <Locale stringKey="DASHBOARD_PAGE__PHOTOS__TITLE" valueMap={{count: result.results.length}} />
                    }
                    widget={DashboardWidgetEnum.PhotoTool}
                />
            }
        >
            <div>
                <Suspense fallback={<Spinner />}>
                    <Carousel
                        items={result.results.map((photo) => {
                            return <PhotoImage key={photo.id} photo={photo} />;
                        })}
                    />
                </Suspense>
            </div>

            <div>
                <span className={styles.dashboard_photos__card_note_description}>
                    <Locale stringKey="DASHBOARD_PAGE__PHOTOS__CARD_NOTE" />
                    <NavigationLink to={appRoute.photoToolIndex.path}>
                        <Locale stringKey="CATEGORY_NAME__PHOTO_TOOL" />
                    </NavigationLink>
                </span>
            </div>
        </PageCard>
    );
}
