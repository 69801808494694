import * as mainStyles from '../../../../css/var-export.scss';

import {getLocalPackIconColor} from './local-pack-result-icon-helper';
import * as styles from './local-pack-result-icon.scss';

type PropsType = {
    position: number;
    isCenter?: boolean;
    isAd?: boolean;
};

export function LocalPackResultIcon(props: PropsType): JSX.Element {
    const {position, isCenter, isAd} = props;

    const formattedPosition = position === 21 ? '21+' : position;
    const borderColor = isCenter ? mainStyles.blue60 : mainStyles.gray1;

    return (
        <svg
            className={styles.LocalPackResultIcon}
            fill="none"
            height="32"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                fill={getLocalPackIconColor(position, isAd)}
                height="30"
                rx="15"
                stroke={borderColor}
                strokeWidth="2"
                width="30"
                x="1"
                y="1"
            />
            <text fill="white" fontSize="12px" textAnchor="middle" x="50%" y="20">
                {isAd ? 'Ads' : formattedPosition}
            </text>
        </svg>
    );
}
