import {FetchMethodEnum} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

export function sendPushNotificationsToken(token: string): Promise<Response> {
    return fetch(rootApiUrl + '/user/push_alert/', {
        credentials: 'include',
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({subscriber_id: token}),
    });
}
