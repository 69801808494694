import {Modal, Radio} from 'antd';
import {useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {ProductGroupTypeEnum} from '../../../../../service/products/products-type';
import {getEnumValue} from '../../../../../util/enum';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {productGroupTypeIconMap} from '../../products-const';

import {productGroupDescriptionMap, productGroupTitleMap} from './select-new-product-group-type-modal-const';
import * as styles from './select-new-product-group-type.scss';

type PropsType = {
    isOpen: boolean;
    onClose: () => void;
};

export function SelectNewProductGroupTypeModal(props: PropsType): JSX.Element {
    const {isOpen, onClose} = props;
    const {companyName} = useDomainConfig();

    const {pushUrl} = useUrl();

    const [selectedType, setSelectedType] = useState<ProductGroupTypeEnum | null>(null);

    return (
        <Modal
            centered
            destroyOnClose
            maskClosable
            okButtonProps={{disabled: !selectedType}}
            okText={<Locale stringKey="PRODUCTS__CREATE_GROUP" />}
            onCancel={() => {
                setSelectedType(null);
                onClose();
            }}
            onOk={() => pushUrl(generatePath(appRoute.createProductGroup.path, {type: selectedType || ''}))}
            open={isOpen}
            title={<Locale stringKey="PRODUCTS__CREATE_GROUP__MODAL__TITLE" />}
        >
            <Radio.Group
                className={styles.new_product_group_type_selector__wrapper}
                onChange={(event) => {
                    setSelectedType(getEnumValue<ProductGroupTypeEnum>(ProductGroupTypeEnum, event.target.value));
                }}
                value={selectedType}
            >
                {Object.values(ProductGroupTypeEnum)
                    .filter((productTypeOption) => productTypeOption !== ProductGroupTypeEnum.Manual)
                    .map((productTypeOption: ProductGroupTypeEnum) => {
                        return (
                            <Radio
                                className={styles.new_product_group_type_selector__option__wrapper}
                                key={productTypeOption}
                                value={productTypeOption}
                            >
                                <div className={styles.new_product_group_type_selector__option__description__wrapper}>
                                    <div className={styles.new_product_group_type_selector__option__details}>
                                        <p className={styles.new_product_group_type_selector__option__type_name}>
                                            <Locale
                                                stringKey={productGroupTitleMap[productTypeOption] || productTypeOption}
                                            />
                                        </p>
                                        <p className={styles.new_product_group_type_selector__option__type_description}>
                                            <Locale
                                                stringKey={
                                                    productGroupDescriptionMap[productTypeOption] || productTypeOption
                                                }
                                                valueMap={{companyName}}
                                            />
                                        </p>
                                    </div>

                                    {productGroupTypeIconMap[productTypeOption]}
                                </div>
                            </Radio>
                        );
                    })}
            </Radio.Group>
        </Modal>
    );
}
