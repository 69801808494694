import {Button, Input, Radio, Space, Typography} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {formatAddress} from '../../../../../service/address/address-helper';
import {MatchingCompanyType, MatchingLocationType} from '../../../../../service/matching/matching-types';
import {includesDeburredString} from '../../../../../util/string';
import {AccountMatchingOption} from '../account-matching-option/account-matching-option';
import {createNewCompanyValue} from '../accounts-matching-form/accounts-matching-const';
import {isLocation} from '../accounts-matching-form/accounts-matching-form-helper';

import * as styles from './account-matching-item.scss';

type PropsType = {
    company: MatchingCompanyType;
    locations: Array<MatchingLocationType>;
    ignoredLocations: Array<MatchingLocationType>;
    onSelectionChanged: (newValue: MatchingLocationType | string | undefined) => void;
    isDisabled: boolean;
    value?: MatchingLocationType | string | undefined;
    onChange?: (newValue: MatchingLocationType | string | undefined) => void;
};

export function AccountMatchingItem(props: PropsType): JSX.Element {
    const {company, locations, value, onChange, ignoredLocations, onSelectionChanged, isDisabled} = props;

    const [filter, setFilter] = useState('');
    const {getLocalizedString} = useLocale();

    const locationIncludesFilter = useCallback(
        (location: MatchingLocationType) => {
            const {name, formattedAddress, website, phone, categories} = location;

            return (
                includesDeburredString(name, filter) ||
                (formattedAddress && includesDeburredString(formattedAddress, filter)) ||
                (website && includesDeburredString(website, filter)) ||
                phone?.some((onePhone) => includesDeburredString(onePhone, filter)) ||
                categories?.some((category) => includesDeburredString(category, filter))
            );
        },
        [filter]
    );

    const activeLocations = useMemo(() => {
        return locations.filter((location) => {
            return (
                (isLocation(value) && value.id === location.id) ||
                !ignoredLocations.some((ignoredLocation) => ignoredLocation.id === location.id)
            );
        });
    }, [ignoredLocations, locations, value]);

    const filteredLocations = activeLocations.filter((location) =>
        filter ? locationIncludesFilter(location) : location.companiesInRadius.includes(company.id)
    );

    return (
        <div className={styles.account_matching_item__wrapper}>
            <div className={styles.account_matching_item__company_info__wrapper}>
                <Space>
                    <span className={styles.account_matching_item__name}>{company.name}</span>
                    <span className={styles.account_matching_item__code}>{company.code}</span>
                </Space>

                <Typography.Text>{formatAddress(company.address)}</Typography.Text>

                <Typography.Paragraph ellipsis={{rows: 5, tooltip: true}} type="secondary">
                    {company.categories.map((categoryItem) => categoryItem.name).join(', ')}
                </Typography.Paragraph>
            </div>

            <Radio.Group
                className={styles.account_matching_item__matching_options__wrapper}
                disabled={isDisabled}
                onChange={(event) => {
                    onChange?.(event.target.value);
                    onSelectionChanged(event.target.value);
                }}
                value={value}
            >
                <Radio>
                    <Locale stringKey="COMPANY_MATCHING__DONT_SYNC" />
                </Radio>

                <Radio value={createNewCompanyValue}>
                    <Locale stringKey="COMPANY_MATCHING__CREATE_NEW" />
                </Radio>

                <div className={styles.account_matching_item__choose_radio}>
                    <Space size="middle">
                        <Locale stringKey="COMPANY_MATCHING__CHOOSE" />

                        <Input.Search
                            allowClear
                            className={styles.account_matching_item__search}
                            onChange={(event) => setFilter(event.target.value)}
                            placeholder={getLocalizedString('COMPANY_MATCHING__FILTER__PLACEHOLDER')}
                            size="small"
                            value={filter}
                        />
                    </Space>
                </div>

                <div className={styles.account_matching_item__locations}>
                    {filteredLocations.map((location) => (
                        <AccountMatchingOption key={location.id} locationData={location} />
                    ))}

                    <div className={styles.account_matching_item__filter_summary}>
                        {filter ? (
                            <>
                                {filteredLocations.length === 0 ? (
                                    <Locale stringKey="COMPANY_MATCHING__FILTER__NOT_FOUND" />
                                ) : (
                                    <LocalePlural
                                        count={filteredLocations.length}
                                        fewKey="COMPANY_MATCHING__FILTER__FILTERED__FEW"
                                        manyKey="COMPANY_MATCHING__FILTER__FILTERED__MANY"
                                        singularKey="COMPANY_MATCHING__FILTER__FILTERED__SINGULAR"
                                        valueMap={{
                                            count: filteredLocations.length,
                                            total: activeLocations.length,
                                        }}
                                    />
                                )}

                                <Button onClick={() => setFilter('')} size="small" type="link">
                                    <Locale stringKey="COMPANY_MATCHING__FILTER__CLEAR" />
                                </Button>
                            </>
                        ) : (
                            <LocalePlural
                                count={filteredLocations.length}
                                fewKey="COMPANY_MATCHING__FILTER__TOTAL__FEW"
                                manyKey="COMPANY_MATCHING__FILTER__TOTAL__MANY"
                                singularKey="COMPANY_MATCHING__FILTER__TOTAL__SINGULAR"
                                valueMap={{count: filteredLocations.length}}
                            />
                        )}
                    </div>
                </div>
            </Radio.Group>
        </div>
    );
}
