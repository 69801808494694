import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

export const companyBrandSchema = {
    [CompanyKeyEnum.Code]: r.string().nullable(),
    [CompanyKeyEnum.BrandId]: r.number().nullable(),
    [CompanyKeyEnum.BrandName]: r.string().nullable(),
};

const companyBrandSchemaObject = r.object(companyBrandSchema);

export type CompanyBrandType = r.infer<typeof companyBrandSchemaObject>;

export type CompanyBrandServerType = CompanyBrandType;
