import {FeaturesEnum} from '../../service/user/user-type';
import {noop} from '../../util/function';

import {LicenseProviderType} from './license-provider-type';

export const LICENSE_PROVIDER_DEFAULT_VALUE: LicenseProviderType = {
    licenses: {},
    refetch: noop,
    isLoading: false,
    availableFeatures: Object.values(FeaturesEnum).reduce((result, feature) => {
        return {
            ...result,
            [feature]: true,
        };
    }, {} as Record<FeaturesEnum, boolean>),
};

export function getUserLicensesUrl(): string {
    return '/cp/nav_bar/user_licenses/';
}
