import {z as r} from 'zod';

export const reviewsCountByRatingSchema = r.array(
    r.object({
        rating: r.string(),
        count: r.object({
            value: r.number(),
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
    })
);

export type ReviewsCountByRatingType = r.infer<typeof reviewsCountByRatingSchema>;
