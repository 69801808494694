import {Button, Form, FormInstance, Input, Space} from 'antd';

import {PageSubHeader} from '../../../../../layout/page-header/page-sub-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {
    ReviewUserTopicFieldEnum,
    ReviewUserTopicType,
} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-type';
import {TagSelect} from '../tag-select/tag-select';

import {BrandsCheckboxGroup} from './brands-checkbox-group/brands-checkbox-group';
import {topicTagsRule} from './topic-management-helper';
import * as styles from './topic-management.scss';

type PropsType = {
    isSubmitting: boolean;
    onSubmit: (userTopic: Omit<ReviewUserTopicType, 'id'>) => void;
    userTopic: Omit<ReviewUserTopicType, 'id'>;
    deleteButton?: JSX.Element;
};

export function TopicManagement(props: PropsType): JSX.Element {
    const {isSubmitting, userTopic, onSubmit, deleteButton} = props;

    const {getLocalizedString} = useLocale();
    const [form]: [FormInstance<ReviewUserTopicType>] = Form.useForm();

    function onValuesChange(values: unknown): void {
        const changedValues = (values instanceof Object && Object.keys(values)) || [];

        if (
            changedValues.includes(ReviewUserTopicFieldEnum.includeTags) ||
            changedValues.includes(ReviewUserTopicFieldEnum.excludeTags)
        ) {
            form.validateFields([ReviewUserTopicFieldEnum.includeTags, ReviewUserTopicFieldEnum.excludeTags]);
        }
    }

    return (
        <>
            <PageSubHeader>
                <Locale stringKey="REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__DESCRIPTIONS" />
            </PageSubHeader>

            <Form<ReviewUserTopicType>
                className={styles.topic_management__form}
                form={form}
                initialValues={userTopic}
                layout="vertical"
                onFinish={onSubmit}
                onValuesChange={onValuesChange}
            >
                <Form.Item<ReviewUserTopicType>
                    label={getLocalizedString('REVIEWS_ANALYSIS__FORM__INPUT__TOPIC__LABEL')}
                    name={ReviewUserTopicFieldEnum.name}
                    rules={[{required: true, message: <Locale stringKey="VALIDATION__ERROR__FIELD_IS_REQUIRED" />}]}
                >
                    <Input placeholder={getLocalizedString('REVIEWS_ANALYSIS__FORM__INPUT__TOPIC__PLACEHOLDER')} />
                </Form.Item>

                <Form.Item<ReviewUserTopicType>
                    label={getLocalizedString('REVIEWS_ANALYSIS__FORM__INPUT__BRAND__LABEL')}
                    name={ReviewUserTopicFieldEnum.brands}
                    rules={[{required: true, message: <Locale stringKey="VALIDATION__ERROR__FIELD_IS_REQUIRED" />}]}
                >
                    <BrandsCheckboxGroup />
                </Form.Item>

                <Form.Item<ReviewUserTopicType>
                    label={getLocalizedString('REVIEWS_ANALYSIS__FORM__INPUT__TAG_INCLUDE__LABEL')}
                    name={ReviewUserTopicFieldEnum.includeTags}
                    rules={[topicTagsRule(getLocalizedString, form)]}
                >
                    <TagSelect />
                </Form.Item>

                <Form.Item<ReviewUserTopicType>
                    label={getLocalizedString('REVIEWS_ANALYSIS__FORM__INPUT__TAG_EXCLUDE__LABEL')}
                    name={ReviewUserTopicFieldEnum.excludeTags}
                >
                    <TagSelect />
                </Form.Item>

                <Form.Item>
                    <Space wrap>
                        <Button htmlType="submit" loading={isSubmitting} type="primary">
                            <Locale stringKey="REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__SAVE_BUTTON" />
                        </Button>

                        <Button htmlType="button" onClick={() => form.resetFields()}>
                            <Locale stringKey="REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__RESET_BUTTON" />
                        </Button>

                        {deleteButton}
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
}
