import {faUserTie} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Flex, Space} from 'antd';
import {ReactElement} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {gray7} from '../../../../../../../../css/var-export.scss';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {ResponsibleUserType} from '../../../../../../../../service/reviews/reviews-responsible-users';

type PropsType = {
    formattedDate: string | null;
    formattedTime: string | null;
    responsibleUserName: string;
    newValue: ResponsibleUserType | null;
};

export function ResponsibleUserLog(props: PropsType): ReactElement {
    const {formattedDate, formattedTime, responsibleUserName, newValue} = props;

    const newResponsibleUserName = newValue ? (
        newValue.fullName ||
        (newValue.firstName && newValue.lastName ? `${newValue.firstName} ${newValue.lastName}` : newValue.email)
    ) : (
        <Locale stringKey="REVIEWS__RESPONSIBLE_USER__NOT_SELECTED" />
    );

    return (
        <div>
            <Text secondary>
                {formattedDate} {formattedTime}
            </Text>
            <Flex align="center" gap={8}>
                <FontAwesomeIcon color={gray7} fontSize={16} icon={faUserTie} />
                <Space size={4}>
                    <Text bolder>{responsibleUserName}</Text>
                    <Text lighter stringKey="REVIEWS__REVIEW_LOG__NEW_RESPONSIBLE_USER" />
                    <Text bolder>{newResponsibleUserName}</Text>
                </Space>
            </Flex>
        </div>
    );
}
