import {faFileExcel, faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Checkbox, List, Modal} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox/Checkbox';
import {useContext, useState} from 'react';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../provider/user/user-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useExportAnalytics} from '../../../../../service/online-presence/online-presence-hook';
import {
    ExportExcelOptionsType,
    ExportExcelType,
    OnlinePresenceV2FilterStateType,
    OptionsEnum,
} from '../../../../../service/online-presence/online-presence-type';
import {getIsoYyyyMmDdString} from '../../../../../util/date';

import {catalogsOptionsDefault, otherOptionsDefault} from './export-excel-analytics-const';
import {getExportAnalyticsInitialState} from './export-excel-analytics-helper';
import * as styles from './export-excel-analytics.scss';

type PropsType = {
    filter: OnlinePresenceV2FilterStateType;
    isDisable?: boolean;
};

export function ExportExcelAnalytics(props: PropsType): JSX.Element {
    const {filter, isDisable} = props;
    const [isOpened, setIsOpened] = useState(false);
    const [parameters, setParameters] = useState<ExportExcelOptionsType>(getExportAnalyticsInitialState());
    const {mainFilterKey} = useContext(MainFilterContext);
    const isDisabledSubmit = Object.values(parameters).every((item) => !item);

    const {user} = useUser();
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();

    function handleClose(): void {
        setParameters(getExportAnalyticsInitialState());
        setIsOpened(false);
    }

    const {mutate, isLoading} = useExportAnalytics({
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__SUCCESS__TITLE" />,
                description: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__SUCCESS__MESSAGE" />,
            });
            handleClose();
        },
        onError: () =>
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            }),
    });

    const catalogsRender = catalogsOptionsDefault.filter((catalog) =>
        user && catalog.catalogId ? user.regionAvailableCatalogs[catalog.catalogId] : false
    );

    const optionsRender = [...catalogsRender, ...otherOptionsDefault];

    function handleSubmit(): void {
        const preparedParameters: ExportExcelType = {
            start_date: getIsoYyyyMmDdString(filter.op_start_date),
            end_date: getIsoYyyyMmDdString(filter.op_end_date),
            filter_key: mainFilterKey,
            options: parameters,
        };

        mutate(preparedParameters);
    }

    function handleChange(event: CheckboxChangeEvent, option: OptionsEnum): void {
        setParameters((previousState) => ({
            ...previousState,
            [option]: event.target.checked,
        }));
    }

    return (
        <>
            <Button
                disabled={isDisable}
                icon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                    setIsOpened(true);
                    track(AnalyticsTarget.OnlinePresence.DownloadReport, 'xlsx');
                }}
            >
                <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_XLSX" />
            </Button>
            {isOpened && (
                <Modal
                    okButtonProps={{disabled: isDisabledSubmit || isLoading}}
                    okText={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__MODAL__OK_BUTTON')}
                    onCancel={handleClose}
                    onOk={handleSubmit}
                    open={isOpened}
                    title={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__MODAL__TITLE')}
                >
                    <Alert
                        className={styles.ExportExcelAnalytics_alert}
                        description={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__DOWNLOAD_EXCEL__ALERT__MESSAGE')}
                        icon={<FontAwesomeIcon icon={faInfoCircle} />}
                        showIcon
                        type="info"
                    />
                    <List>
                        {optionsRender.map((option) => (
                            <List.Item key={option.optionKey}>
                                <Checkbox onChange={(event) => handleChange(event, option.optionKey)}>
                                    {option.label}
                                </Checkbox>
                            </List.Item>
                        ))}
                    </List>
                </Modal>
            )}
        </>
    );
}
