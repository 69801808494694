import {Dayjs} from 'dayjs';
import {z as r} from 'zod';

import {PaginationDataType, PaginationHookType} from '../../util/pagination-hook/pagination-hook-type';
import {TimeRangeType} from '../../util/time';
import {generateResponseSchema} from '../api/api-type';
import {CustomFileType} from '../file-upload/file-upload-type';

export enum TicketStatusEnum {
    open = 'open',
    inProgress = 'v-processe',
    inWaiting = '7',
    onCheck = '8',
    closed = 'closed',
}

enum TicketTypeEnum {
    problem = 'problem',
    question = 'question',
    improvement = 'improvement',
    feature = 'feature',
}

export const enum TechnicalSupportFieldsEnum {
    Status = 'status',
    ClientCategory = 'clientCategory',
    Type = 'type',
    IsPublic = 'isPublic',
    Creator = 'creator',
    CreatorGroup = 'creatorGroup',
    HasNewAnswers = 'hasUnreadPosts',
}

export const technicalSupportTicketFieldsSchema = r.object({
    [TechnicalSupportFieldsEnum.Status]: r.array(
        r.object({
            value: r.string(),
            name: r.string(),
        })
    ),
    [TechnicalSupportFieldsEnum.ClientCategory]: r.array(
        r.object({
            value: r.number(),
            name: r.string(),
        })
    ),
    [TechnicalSupportFieldsEnum.Type]: r.array(
        r.object({
            value: r.string(),
            name: r.string(),
            description: r.string(),
        })
    ),
    [TechnicalSupportFieldsEnum.IsPublic]: r.array(
        r.object({
            value: r.boolean(),
            name: r.string(),
        })
    ),
    [TechnicalSupportFieldsEnum.Creator]: r.array(
        r.object({
            value: r.number(),
            name: r.string(),
        })
    ),
    [TechnicalSupportFieldsEnum.CreatorGroup]: r.array(
        r.object({
            value: r.number(),
            name: r.string(),
        })
    ),
    [TechnicalSupportFieldsEnum.HasNewAnswers]: r.array(
        r.object({
            value: r.boolean(),
            name: r.string(),
        })
    ),
});

export type TechnicalSupportTicketFieldsType = r.infer<typeof technicalSupportTicketFieldsSchema>;

const technicalSupportRequestListItemSchema = r.object({
    id: r.number(),
    hdeId: r.string().nullable(),
    title: r.string(),
    creatorId: r.number(),
    creatorName: r.string(),
    created: r.string(),
    status: r.nativeEnum(TicketStatusEnum),
    type: r.nativeEnum(TicketTypeEnum),
    isPublic: r.boolean(),
    parent: r.number().nullable(),
    isSubscribed: r.boolean(),
    unreadPostsCount: r.number(),
    clientCategory: r.number(),
});

const technicalSupportAttachmentsSchema = r.array(
    r.object({
        fileS3: r.string(),
        fileName: r.string(),
        tempId: r.string(),
    })
);

export const technicalSupportNewTicketIdResponseSchema = r.object({
    last_child_id: r.number(),
});

export const technicalSupportResponseSchema = r.object({
    id: r.number(),
    title: r.string(),
    description: r.string(),
    status: r.nativeEnum(TicketStatusEnum),
    type: r.nativeEnum(TicketTypeEnum),
    clientCategory: r.number(),
    isPublic: r.boolean(),
    isClosed: r.boolean(),
    isCreator: r.boolean(),
    isSubscribed: r.boolean(),
    files: technicalSupportAttachmentsSchema,
    parent: r.number().nullable(),
    created: r.string(),
    updated: r.string(),
    unreadPostsCount: r.number(),
});

export const technicalSupportTicketsResponseSchema = generateResponseSchema(technicalSupportRequestListItemSchema);

export type TechnicalSupportRequestListItemType = r.infer<typeof technicalSupportRequestListItemSchema>;
export type TechnicalSupportTicketsResponseType = r.infer<typeof technicalSupportTicketsResponseSchema>;
export type TechnicalSupportActualTicketIdType = r.infer<typeof technicalSupportNewTicketIdResponseSchema>;
export type TechnicalSupportTicketResponseType = r.infer<typeof technicalSupportResponseSchema>;
export type UseTechnicalSupportTicketsHookType = {
    data?: TechnicalSupportTicketsResponseType;
    isLoading: boolean;
    pagination: PaginationHookType;
    refetch: VoidFunction;
};

export type TechnicalSupportFilterType = {
    [TechnicalSupportFieldsEnum.Status]: Array<string>;
    creationDate: TimeRangeType;
    q: string | null;
    [TechnicalSupportFieldsEnum.ClientCategory]: number | null;
    [TechnicalSupportFieldsEnum.Type]: string | null;
    [TechnicalSupportFieldsEnum.IsPublic]: boolean | null;
    [TechnicalSupportFieldsEnum.Creator]: Array<number>;
    [TechnicalSupportFieldsEnum.CreatorGroup]: Array<number>;
    [TechnicalSupportFieldsEnum.HasNewAnswers]: boolean | null;
};

export type TechnicalSupportFilterQueriesType = {
    [key in keyof Omit<TechnicalSupportFilterType, 'creationDate' | 'q'>]?: string | void;
} & {
    createdFrom?: string | void;
    createdTo?: string | void;
};

export type TechnicalSupportFilterDrawerType = Omit<TechnicalSupportFilterType, 'q'> & {
    creationDate: [Dayjs | null, Dayjs | null];
};

export type ParametersSupportTicketsType = {
    pagination: PaginationDataType;
    filter?: TechnicalSupportFilterType;
};

export type TechnicalSupportFilterHookType = {
    filter: TechnicalSupportFilterType;
    updateFilter: (values: Partial<TechnicalSupportFilterType>) => void;
    resetFilter: () => void;
};

export enum CreateTicketFieldsEnum {
    title = 'title',
    description = 'description',
    type = 'type',
    clientCategory = 'client_category',
    isPublic = 'is_public',
    files = 'files',
}

export type CreateTicketFormType = {
    [CreateTicketFieldsEnum.description]: string;
    [CreateTicketFieldsEnum.title]: string;
    [CreateTicketFieldsEnum.isPublic]?: boolean;
    [CreateTicketFieldsEnum.type]?: string;
    [CreateTicketFieldsEnum.clientCategory]?: string;
    [CreateTicketFieldsEnum.files]: Record<string, CustomFileType>;
};

export type CreateTicketMutationDataType = Omit<CreateTicketFormType, CreateTicketFieldsEnum.files> & {
    [CreateTicketFieldsEnum.files]: Array<CustomFileType>;
};

const technicalSupportAnswerSchema = r.object({
    id: r.number(),
    text: r.string(),
    creatorId: r.number(),
    creatorName: r.string(),
    files: technicalSupportAttachmentsSchema,
    created: r.string(),
    updated: r.string(),
    isAnswer: r.boolean(),
});

export const technicalSupportAnswersSchema = generateResponseSchema(technicalSupportAnswerSchema);

export type TechnicalSupportMessageType = r.infer<typeof technicalSupportAnswerSchema>;
export type TechnicalSupportMessagesResponseType = r.infer<typeof technicalSupportAnswersSchema>;

export type TechnicalSupportNewMessageType = {
    text: string;
    files: Array<CustomFileType>;
};

export type TechnicalSupportNewMessageFormType = Omit<TechnicalSupportNewMessageType, 'files'> & {
    files: Record<string, CustomFileType>;
};

export const ticketFileSchema = r.object({
    fileS3: r.string(),
    fileName: r.string(),
    tempId: r.string(),
});

export type TicketFileType = r.infer<typeof ticketFileSchema>;

export const technicalSupportStatusActionSchema = r.object({
    status: r.nativeEnum(TicketStatusEnum),
    ticket_id: r.number(),
});

export const technicalSupportUnreadCountActionSchema = r.object({
    ticket_id: r.number(),
    unread_posts_count: r.number(),
});

export type TechnicalSupportStatusActionType = r.infer<typeof technicalSupportStatusActionSchema>;
export type TechnicalSupportUnreadCountActionType = r.infer<typeof technicalSupportUnreadCountActionSchema>;
