import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {averageResponseTimeSchema, AverageResponseTimeType} from './average-response-time-type';

export function fetchAverageResponseTime(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<AverageResponseTimeType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/avg_reply_time/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        averageResponseTimeSchema
    );
}
