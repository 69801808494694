export const enNeuralNetworkTranslations = {
    NEURAL_NETWORK__PAGE_HEADER: 'Artificial Intelligence Answers',
    NEURAL_NETWORK__PAGE_CARD__LICENSE: 'Licence',

    NEURAL_NETWORK__LICENSE__TITLE: 'Artificial Intelligence Answers',
    NEURAL_NETWORK__LICENSE__SUBTITLE_TRIAL: 'Trial period',
    NEURAL_NETWORK__LICENSE__VALID_UNTIL: 'Valid until {date}',
    NEURAL_NETWORK__LICENSE__INFO_LINE_1:
        'The trial period will be available for one month from the moment the product is connected.',
    NEURAL_NETWORK__LICENSE__INFO_LINE_2:
        'After the expiration of the period, you can purchase a license by contacting the manager.',
    NEURAL_NETWORK__LICENSE__INFO_LINE_DISABLED_1: 'The trial period has expired.',
    NEURAL_NETWORK__LICENSE__INFO_LINE_DISABLED_2: 'You can purchase a license by contacting the manager.',
    NEURAL_NETWORK__LICENSE__ENABLE_BUTTON: 'Submit a connection request',

    NEURAL_NETWORK__SNACKBAR_SUCCESS_TITLE: 'Request has been sent',
    NEURAL_NETWORK__SNACKBAR_SUCCESS_DESCRIPTION:
        'The request to connect the Artificial Intelligence Answers service was successfully sent. A manager will contact you within 24 hours. Please note that if your request was sent on a non-business day, we will definitely contact you on the next business day.',
    NEURAL_NETWORK__SNACKBAR_ERROR_TITLE: 'Error',
    NEURAL_NETWORK__SNACKBAR_ERROR_DESCRIPTION:
        'The request to connect the Artificial Intelligence Answers service was not sent. Try again later or contact the manager.',

    /* REVIEWS MANAGEMENT - NEURAL NETWORK [END] */

    /* REVIEWS MANAGEMENT - NEURAL NETWORK LANDING [BEGIN] */
    NEURAL_NETWORK_LANDING__TITLE: 'Generate responses to reviews using an AI',
    NEURAL_NETWORK_LANDING__SUBTITLE:
        'AI will help you with the processing of reviews by providing polite and professional answers to all types of reviews.',
    NEURAL_NETWORK_LANDING__BUTTON: 'Try it for free!',
    NEURAL_NETWORK_LANDING__FOOTER:
        '{companyName} is not responsible for the possible generation of unacceptable content by the Artificial intelligence.',

    NEURAL_NETWORK_LANDING__POPUP__TITLE: 'Enable the trial period of the AI-powered response generation service?',
    NEURAL_NETWORK_LANDING__POPUP__SUBTITLE:
        'The trial period will be available for one month from the moment of activation. You can disable it at any time in your personal account.',
    NEURAL_NETWORK_LANDING__SNACKBAR__TITILE: 'Connected',
    NEURAL_NETWORK_LANDING__SNACKBAR__TEXT: 'The trial period of the AI-powered response generator service is enabled.',
    NEURAL_NETWORK_LANDING__SNACKBAR__ERROR_TEXT:
        'The trial period of the AI-powered response generator service is not connected. Try again later or contact the manager.',

    NEURAL_NETWORK_LANDING__TRIAL: 'The trial period is one month',
    NEURAL_NETWORK_LANDING__TRIAL__TITLE: 'Free trial period',
    NEURAL_NETWORK_LANDING__TRIAL__SUBMIT: 'Connect',
    NEURAL_NETWORK_LANDING__TRIAL__SUBMIT_2: 'Connect for free!',
    NEURAL_NETWORK_LANDING__TRIAL__SUBMIT_3: 'Connect an AI',
    NEURAL_NETWORK_LANDING__TRIAL__SUBTITLE:
        'Activate the free trial period of the response generation service and respond to reviews within one month using an AI.',

    NEURAL_NETWORK_LANDING__ADVANTAGE: 'Advantages of AI-powered response generation',
    NEURAL_NETWORK_LANDING__ADVANTAGE__TEXT_1: 'Speed',
    NEURAL_NETWORK_LANDING__ADVANTAGE__DESCRIPTION_1:
        'Artificial intelligence allows you to save up to 40% of your employees` time by increasing the number of responses to reviews, increasing customer loyalty.',
    NEURAL_NETWORK_LANDING__ADVANTAGE__TEXT_2: 'Individuality',
    NEURAL_NETWORK_LANDING__ADVANTAGE__DESCRIPTION_2:
        'Your employees will no longer have to use template responses, the AI will generate an individual and personalized response.',
    NEURAL_NETWORK_LANDING__ADVANTAGE__TEXT_3: 'Variability',
    NEURAL_NETWORK_LANDING__ADVANTAGE__DESCRIPTION_3:
        'The AI offer can be edited, or up to 3 possible answers can be generated. The decision on what the answer will be is up to the user.',

    NEURAL_NETWORK_LANDING__GUIDE__TITLE: 'How does it work?',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_TITLE_1: 'Connect the AI-powered response generation service',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_SUBTITLE_1: 'Try the free trial period for one month.',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_TITLE_2: 'Respond to reviews from your personal account in one click',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_SUBTITLE_2:
        '{companyName} will offer AI responses when processing reviews in test mode, after connecting the service.',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_TITLE_3: 'Make a decision yourself',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_SUBTITLE_3:
        'Edit AI suggestions or generate new responses to reviews quickly and efficiently.',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_TITLE_4: 'Enjoy the speed and quality of responses',
    NEURAL_NETWORK_LANDING__GUIDE__STEP_SUBTITLE_4:
        'Form a positive attitude towards your business through customer loyalty.',

    NEURAL_NETWORK_LANDING__BENEFITS__TITLE: 'Why is it important for a local business to process reviews?',
    NEURAL_NETWORK_LANDING__BENEFITS__STEP_TEXT_1:
        'Responses to reviews affect the loyalty and trust rating of the company',
    NEURAL_NETWORK_LANDING__BENEFITS__STEP_TEXT_2:
        'You get consumer insights and the necessary analytics for business development',
    NEURAL_NETWORK_LANDING__BENEFITS__STEP_TEXT_3: 'You increase your star rating and conversion on the sites',
    NEURAL_NETWORK_LANDING__BENEFITS__STEP_TEXT_4:
        'You influence the ranking (position of the company) in the local search results',

    NEURAL_NETWORK_LANDING__EXAMPLE__TITLE: 'Examples of AI responses',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TITLE: 'AI Response Suggestion',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_1: 'We apologize for the unsatisfactory delivery experience.',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_2:
        'We always strive to provide high quality services and reliable delivery, but, unfortunately, we could not meet your expectations.',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_3:
        'We accept your feedback and promise to take all necessary measures to improve our delivery service in the future.',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_4: 'Hello!',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_5:
        'We are glad that you liked our service. We always try to provide our customers with high quality service and solve their problems quickly and efficiently. If you have any questions or suggestions, do not hesitate to contact us.',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_6: 'We will be happy to help you at any time!',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_7:
        'Good Day! A negative experience with delivery can be very frustrating, and I`m sorry you had problems. We would like to apologize for any inconvenience you have experienced. We always strive to provide our customers with the best service and fast delivery.  We hope that you will give us another chance!',
    NEURAL_NETWORK_LANDING__EXAMPLE__ITEM__TEXT_8: 'Thank you for choosing us!',
    NEURAL_NETWORK__ALERT__GET_TRIAL__TITLE: 'Generate unique responses to reviews with neural network',
    NEURAL_NETWORK__ALERT__GET_TRIAL__DESCRIPTION:
        "Easily and quickly create professional, personalized responses to your customers' reviews. Activate the trial period and get unlimited responses with the neural network for a month for free.",
    NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__TITLE:
        'The trial period of the neural network response generation service is ending soon',
    NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__DESCRIPTION:
        'To continue easily and quickly creating professional, personalized responses to reviews, please purchase a product license.',
    NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__ACTION_BUTTON: 'Buy license',
    NEURAL_NETWORK__ALERT__GET_TRIAL_IS_OVER__TITLE:
        'The trial period of the neural network response generation service is over',
    NEURAL_NETWORK__ALERT__GET_TRIAL_IS_OVER__DESCRIPTION:
        'To use the advantages of creating professional and personalized responses to reviews with the neural network, you need to purchase a product license.',
    NEURAL_NETWORK__ALERT__GET_TRIAL__ACTION_BUTTON: 'Activate for free',
    NEURAL_NETWORK__ALERT__INFO_LINK: 'Learn more',
    NEURAL_NETWORK__ALERT__PRODUCT_NAME: 'Neural network response generation service',
    NEURAL_NETWORK__GENERATE_BUTTON: 'Generate response',
    NEURAL_NETWORK__GENERATE_BUTTON__TOOLTIP: 'The response is being generated by the neural network',
    NEURAL_NETWORK__GENERATE_BUTTON__POPOVER__CONTENT:
        'To use this feature, please connect the neural network response generation service.',
    NEURAL_NETWORK__GENERATE_BUTTON__POPOVER__TITLE: 'Connect the service',
    NEURAL_NETWORK__GENERATE_BUTTON__GENERATE: 'Generating response',
    NEURAL_NETWORK__GENERATE_BUTTON__GENERATE_MORE: 'Regenerate response',
    NEURAL_NETWORK__GENERATE_BUTTON__EMOJI_COMMENT_BLOCKER:
        'Cannot generate a neural network response to a review containing only emojis',
};
