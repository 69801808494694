import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ReactNode} from 'react';

import {LangKeyType} from '../../../../provider/locale/translation/type';

export enum GoogleAccountSyncStepEnum {
    Accounts,
    Companies,
    Processing,
    Finish,
}

export enum GoogleAccountSyncUrlParameterEnum {
    PersonalAccountId = 'personalAccountId',
    AccountId = 'accountId',
    BrandId = 'brandId',
    CompanyId = 'companyId',
    OnlyNotBound = 'onlyNotBound',
    Search = 'search',
    BrandsIds = 'brandsIds',
}

export type GoogleAccountSyncUrlType = {
    [GoogleAccountSyncUrlParameterEnum.PersonalAccountId]?: number;
    [GoogleAccountSyncUrlParameterEnum.AccountId]?: number;
    [GoogleAccountSyncUrlParameterEnum.BrandId]?: number;
    [GoogleAccountSyncUrlParameterEnum.CompanyId]?: number;
    [GoogleAccountSyncUrlParameterEnum.OnlyNotBound]?: boolean;
    [GoogleAccountSyncUrlParameterEnum.Search]?: string;
    [GoogleAccountSyncUrlParameterEnum.BrandsIds]?: string;
};

export type StepType = {
    step: GoogleAccountSyncStepEnum;
    icon: IconProp;
    title: LangKeyType;
    description: LangKeyType;
    component?: JSX.Element;
    valueMapFromDescription?: Record<string, ReactNode>;
};
