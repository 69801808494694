import {faPaperclip, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, UploadFile} from 'antd';

import * as styles from './upload-item.scss';

type PropsType = {
    file: UploadFile<unknown>;
    onRemove: () => void;
};

export function UploadItem(props: PropsType): JSX.Element {
    const {file, onRemove} = props;

    return (
        <div className={styles.UploadItem}>
            <FontAwesomeIcon className={styles.UploadItem_icon} icon={faPaperclip} />
            {file.name}
            <Button
                className={styles.UploadItem_button}
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={onRemove}
                type="text"
            />
        </div>
    );
}
