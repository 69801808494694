export const euStoreLocatorTranslations = {
    STORE_LOCATOR__LIST_BREADCRUMBS: 'Store locators list',
    STORE_LOCATOR__LIST_PAGE__TITLE: 'List of created locators',
    STORE_LOCATOR__LIST_PAGE__BUTTON__CREATE_NEW: 'Create store locator',
    STORE_LOCATOR__LIST_PAGE__CARD__LOCATIONS__SINGULAR: '{count} company',
    STORE_LOCATOR__LIST_PAGE__CARD__LOCATIONS__FEW: '{count} companies',
    STORE_LOCATOR__LIST_PAGE__CARD__LOCATIONS__MANY: '{count} companies',
    STORE_LOCATOR__LIST_PAGE__CARD__DELETE_CONFIRM: 'The store locator for {url} will be removed. Confirm?',
    STORE_LOCATOR__LIST_PAGE__CARD__DELETE_CONFIRM_HEADER: 'Remove the store locator',
    STORE_LOCATOR__LIST_PAGE__CARD__DELETED: 'The store locator for {url} has been removed.',
    STORE_LOCATOR__LIST_PAGE__CARD__DELETED_HEADER: 'The store locator has been removed',
    STORE_LOCATOR__LIST_PAGE__CARD__DELETE_ERROR:
        'Unable to remove the store locator. Please try again later or contact the support team',

    STORE_LOCATOR_CREATE_PAGE__TITLE: 'Creating a locator',
    STORE_LOCATOR_UPDATE_PAGE__TITLE: 'Locator update',
    STORE_LOCATOR_PREVIEW_PAGE__TITLE: 'Your site',
    STORE_LOCATOR_PREVIEW_PAGE__DESCRIPTION:
        'The preview reproduces the appearance of the storlocator the way it should look on your resource. Here you will see information existing on the site page where the storlocator will be integrated.',
    STORE_LOCATOR_CREATE_PAGE__STEP1__NAME: 'Creating a locator',
    STORE_LOCATOR_CREATE_PAGE__STEP1__FIELDSET__NAME: 'Locator',
    STORE_LOCATOR_CREATE_PAGE__STEP1__NAME__INPUT__LABEL: 'Name',
    STORE_LOCATOR_CREATE_PAGE__STEP1__NAME__INPUT__PLACEHOLDER: 'Name of storlocator in the personal account',
    STORE_LOCATOR_CREATE_PAGE__STEP1__DOMAIN__INPUT__LABEL: 'Domain',
    STORE_LOCATOR_CREATE_PAGE__STEP1__DOMAIN__INPUT__PLACEHOLDER: 'domain.com',
    STORE_LOCATOR_CREATE_PAGE__STEP1__DOMAIN__INPUT__HINT_TITLE: 'Domain',
    STORE_LOCATOR_CREATE_PAGE__STEP1__DOMAIN__INPUT__HINT_CONTENT:
        'Domain of your site where the storlocator will be hosted',
    STORE_LOCATOR_CREATE_PAGE__STEP1__DOMAIN__INPUT__HINT_CONTENT_EXAMPLE: 'Example: {example}',
    STORE_LOCATOR_CREATE_PAGE__STEP1__DOMAIN__INPUT__ALREADY_EXISTS_ERROR:
        'A storlocator with this domain already exists',
    STORE_LOCATOR_CREATE_PAGE__STEP1__COMPANIES__INPUT__LABEL: 'Companies',
    STORE_LOCATOR_CREATE_PAGE__STEP1__COMPANIES__INPUT__NOT_ALL_LOCATIONS_ERROR:
        'Not all locations are available in the storlocator, please contact support',
    STORE_LOCATOR_CREATE_PAGE__STEP1__MAPBOX_KEY__INPUT__LABEL: 'Mapbox Key (optional)',
    STORE_LOCATOR_CREATE_PAGE__STEP1__MAPBOX_KEY__INPUT__PLACEHOLDER: 'Mapbox Key',
    STORE_LOCATOR_CREATE_PAGE__STEP1__MAPBOX_KEY__TOOLTIP__TITLE: 'Mapbox Key',
    STORE_LOCATOR_CREATE_PAGE__STEP1__MAPBOX_KEY__TOOLTIP__BUTTON: 'Learn More',
    STORE_LOCATOR_CREATE_PAGE__STEP1__MAPBOX_KEY__INFOLABEL__DESCRIPTION:
        'If you have a {mapbox} account and want to use online maps from this service, enter the key from your account in the field. By default, {openStreetMap} maps are used.',
    STORE_LOCATOR_CREATE_PAGE__STEP1__MAPBOX_KEY__INVALID_MAPBOX_KEY: 'Invalid key',
    STORE_LOCATOR_CREATE_PAGE__STEP2__NAME: 'Setting up the locator',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SETTINGS__FIELDSET__NAME: 'Settings',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__NAME: 'Language',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__RUSSIAN: 'Russian',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__ENGLISH: 'English',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__SPANISH: 'Spanish',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__POLISH: 'Polish',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__ROMANIAN: 'Romanian',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__GEORGIAN: 'Georgian',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__BULGARIAN: 'Bulgarian',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LANGUAGE__RADIO__ARABIC: 'Arabic',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ORIENTATION__RADIO__NAME: 'List orientation',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ORIENTATION__RADIO__HINT_SUB_TITLE: 'In desktop version',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ORIENTATION__RADIO__HINT_OPTION_LEFT: 'Left',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ORIENTATION__RADIO__HINT_OPTION_RIGHT: 'Right',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ORIENTATION__RADIO__LEFT: 'To the left of the map',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ORIENTATION__RADIO__RIGHT: 'To the right of the map',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__SWITCH__NAME: 'Header logo',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__BUTTON__TEXT: 'Upload',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__BUTTON__ERROR: 'Error',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__INFO__TITLE: 'Image Format Requirements:',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__INFO__MINIMUM: 'minimum {width}x{height} px.',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__INFO__MAXIMUM: 'maximum {width}x{height} px.',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__INFO__EXTENSIONS: 'extensions',
    STORE_LOCATOR_CREATE_PAGE__STEP2__PUBLIC_NAME_INPUT__LABEL: 'Locator title',
    STORE_LOCATOR_CREATE_PAGE__STEP2__PUBLIC_NAME_INPUT__PLACEHOLDER: 'For example, the name of a brand or chain... ',
    STORE_LOCATOR_CREATE_PAGE__STEP2__FILTERS__FIELDSET__NAME: 'Filters and Search',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SEARCH_BY_ADDR__SWITCH__LABEL: 'Display search by address',
    STORE_LOCATOR_CREATE_PAGE__STEP2__OPEN_NOW__SWITCH__LABEL: 'Display filter “Open now”',
    STORE_LOCATOR_CREATE_PAGE__STEP2__COLORS__FIELDSET__NAME: 'Colors',
    STORE_LOCATOR_CREATE_PAGE__STEP2__MORE_SETTINGS: 'More settings',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LESS_SETTINGS: 'Fewer settings',
    STORE_LOCATOR_CREATE_PAGE__STEP2__PIN_COLOR__LABEL: 'Pin color',
    STORE_LOCATOR_CREATE_PAGE__STEP2__BUTTON_COLOR__LABEL: 'Color for buttons',
    STORE_LOCATOR_CREATE_PAGE__STEP2__BACKGROUND_COLOR__LABEL: 'Substrate color',
    STORE_LOCATOR_CREATE_PAGE__STEP2__CARD_BACKGROUND_COLOR__LABEL: 'Card background color',
    STORE_LOCATOR_CREATE_PAGE__STEP2__PRIMARY_TEXT_COLOR__LABEL: 'Body text and headings',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SECONDARY_TEXT_COLOR__LABEL: 'Secondary text',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ICONS_COLOR__LABEL: 'Subtitles and Icons',
    STORE_LOCATOR_CREATE_PAGE__STEP2__CARD_LIST_ELEMENTS_FIELDSET__NAME: 'Items in a card in a list',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOCATION_NAME__SWITCH__LABEL: 'Location name',
    STORE_LOCATOR_CREATE_PAGE__STEP2__PHONE__SWITCH__LABEL: 'Phone number (primary only)',
    STORE_LOCATOR_CREATE_PAGE__STEP2__OPEN_NOW_STATUS__SWITCH__LABEL: 'Status “Open Now”',
    STORE_LOCATOR_CREATE_PAGE__STEP2__PHOTO__SWITCH__LABEL: 'Photo from gallery',
    STORE_LOCATOR_CREATE_PAGE__STEP2__WORKING_HOURS__SWITCH__LABEL: 'Working hours (on the day of viewing)',
    STORE_LOCATOR_CREATE_PAGE__STEP2__ROUTE_BUTTON__SWITCH__LABEL: 'Button “Build route”',
    STORE_LOCATOR_CREATE_PAGE__STEP2__CARD_PIN_ELEMENTS_FIELDSET__NAME: 'PIN-elements in the card',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__SWITCH__LABEL: "Company's logo",
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__ERROR__HEADER: 'Logo uploading error',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__ERROR__NOT_FILE: 'File not found in request',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__ERROR__BROKEN_FILE: 'Failed to open file as image',
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__ERROR__INVALID_EXTENSION:
        "The file format does not match one of the available file extensions ('JPG', 'JPEG', 'PNG')",
    STORE_LOCATOR_CREATE_PAGE__STEP2__LOGO__ERROR__INVALID_SIZE:
        'Invalid file size (minimum 300x300, maximum 5000x5000)',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SEO__FIELDSET__NAME: 'SEO metadata',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SEO__TITLE__INPUT__LABEL: 'SEO title',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SEO__TITLE__INPUT__PLACEHOLDER: 'SEO title',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SEO__DESCRIPTION__INPUT__LABEL: 'SEO description',
    STORE_LOCATOR_CREATE_PAGE__STEP2__SEO__DESCRIPTION__INPUT__PLACEHOLDER: 'SEO description',
    STORE_LOCATOR_CREATE_PAGE__STEP3__NAME: 'Card settings',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SETTINGS__FIELDSET__NAME: 'Settings',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SETTINGS__SEPARATE_PAGE_CARD__SWITCH__LABEL: 'Open the card on a separate page',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SETTINGS__SEPARATE_PAGE_CARD__SWITCH__HINT__TITLE: 'Location card type',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SETTINGS__SEPARATE_PAGE_CARD__SWITCH__HINT__SUB_TITLE: 'In desktop version',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SETTINGS__SEPARATE_PAGE_CARD__SWITCH__HINT__OPTION_PAGE: 'Page',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SETTINGS__SEPARATE_PAGE_CARD__SWITCH__HINT__OPTION_LIST: 'List',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__FIELDSET__NAME: 'Elements on page',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__LOCATION_NAME__SWITCH__LABEL: 'Location name',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__ROUTE_BUTTON__SWITCH__LABEL: 'Button “Build route”',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__COORDINATES__SWITCH__LABEL: 'Coordinates',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__OPEN_NOW_STATUS__SWITCH__LABEL: 'Status “Open Now”',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__WORKING_HOURS__SWITCH__LABEL: 'Working hours',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__BREAKS__SWITCH__LABEL: 'Breaks',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__PAY_METHODS__SWITCH__LABEL: 'Payment Methods',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__SOCIAL_MEDIA__SWITCH__LABEL: 'Social Media',
    STORE_LOCATOR_CREATE_PAGE__STEP3__PAGE_ELEMENTS__PHOTO_GALLERY__SWITCH__LABEL: 'Photo gallery',
    STORE_LOCATOR_CREATE_PAGE__STEP3__REVIEWS__FIELDSET__NAME: 'Reviews',
    STORE_LOCATOR_CREATE_PAGE__STEP3__REVIEWS__LOCATION_REVIEWS__SWITCH__LABEL: 'Display reviews for a location',
    STORE_LOCATOR_CREATE_PAGE__STEP3__REVIEWS__RATE__INPUT__LABEL: 'Number of stars in the review',
    STORE_LOCATOR_CREATE_PAGE__STEP3__REVIEWS__RATE__INPUT__PLACEHOLDER: 'Select Rates',
    STORE_LOCATOR_CREATE_PAGE__STEP3__REVIEWS__COUNT__INPUT__LABEL: 'Reviews count',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__FIELDSET__NAME: 'SEO metadata',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__TITLE__INPUT__LABEL: 'SEO title',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__TITLE__INPUT__PLACEHOLDER: 'SEO title',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__DESCRIPTION__INPUT__LABEL: 'SEO description',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__DESCRIPTION__INPUT__PLACEHOLDER: 'SEO description',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__HEADER: 'How to work with SEO templates',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEXT:
        'Use wildcards in the fields above to unify SEO tags for each location page. The data of the corresponding location will be substituted into the tags automatically.',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEMPLATES__CAPTION: 'Template list:',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEMPLATES__NAME: '{template} - company name;',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEMPLATES__ADDRESS: '{template} - address;',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEMPLATES__PHONE: '{template} - phone number (general);',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEMPLATES__REVIEWS_COUNT: '{template} - reviews count;',
    STORE_LOCATOR_CREATE_PAGE__STEP3__SEO__HELP__TEMPLATES__RATING: '{template} - average rating.',
    STORE_LOCATOR_CREATE_PAGE__PREVIEW: 'Preview',
    STORE_LOCATOR_CREATE_PAGE__PREVIEW__DESKTOP: 'Desktop',
    STORE_LOCATOR_CREATE_PAGE__PREVIEW__MOBILE: 'Mobile',
    STORE_LOCATOR_CREATE_PAGE__STEP4__NAME: 'Connection',
    STORE_LOCATOR_CREATE_PAGE__STEP4__FINISH_MESSAGE: 'Storlocator is set! Now you can connect it.',
    STORE_LOCATOR_CREATE_PAGE__STEP4__SEND_EMAIL__BUTTON__TEXT: 'Send instructions by email',
    STORE_LOCATOR_CREATE_PAGE__STEP4__SEND_EMAIL__TITLE: 'Send instructions by email',
    STORE_LOCATOR_CREATE_PAGE__STEP4__SEND_EMAIL__SUCCESS__TITLE: 'Email sent',
    STORE_LOCATOR_CREATE_PAGE__STEP4__SEND_EMAIL__SUCCESS__DETAILS: 'Connection information sent.',
    STORE_LOCATOR_CREATE_PAGE__STEP4__SEND_EMAIL__HELP:
        'You can send information for storlocator connection by email to your web developer.',
    STORE_LOCATOR_CREATE_PAGE__STEP4__SEND_EMAIL__EMAIL_INPUT__LABEL: 'Email',
    STORE_LOCATOR_CREATE_PAGE__STEP4__WIDGET_OPTION__TITLE__TEXT: 'Embed JS widget on your website',
    STORE_LOCATOR_CREATE_PAGE__STEP4__WIDGET_OPTION__INFO:
        'The widget can be placed inside the pages of an existing website. To connect, paste the code below into your website code. The site must be located on the domain specified in the locator settings.',
};
