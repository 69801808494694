import {defaultTooltipOptions} from '../chart-const';

import {ChartJsPieOptionsType} from './pie-chart-type';

export const pieChartOptions: ChartJsPieOptionsType = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'right',
        },
        tooltip: defaultTooltipOptions,
        datalabels: {
            display: false,
        },
    },
};
