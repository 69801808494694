import 'firebase/messaging';

import firebase from 'firebase/app';

import {sendPushNotificationsToken} from './firebase-api';
import {PushNotificationPayloadType} from './firebase-type';

let isServiceWorkerRegistered = false;

function getMessaging(): firebase.messaging.Messaging {
    try {
        // eslint-disable-next-line import/no-named-as-default-member
        return firebase.messaging();
    } catch (error: unknown) {
        throw new Error('Error while trying to get messaging object' + error);
    }
}

// Register custom service worker only to handle background notifications (when the app is in background)
function registerServiceWorker(messaging: firebase.messaging.Messaging): void {
    navigator.serviceWorker
        .register(location.origin + '/firebase-messaging-sw.js')
        .then((serviceWorker) => {
            messaging.useServiceWorker(serviceWorker);

            isServiceWorkerRegistered = true;
        })
        .catch((error: unknown) => {
            throw new Error('Error while trying to register firebase service worker' + error);
        });
}

function setUpHandlers(messaging: firebase.messaging.Messaging): void {
    messaging.onMessage((payload: PushNotificationPayloadType) => {
        console.log('[Foreground notification has arrived]:', payload);

        const notification = new Notification(payload.notification.title, {
            body: payload.notification.body,
            image: payload.notification.image,
        });

        notification.addEventListener('click', (event) => {
            event.preventDefault();

            window.open(payload.notification.click_action, '_blank');

            notification.close();
        });
    });
}

export async function enablePushNotifications(): Promise<void> {
    if (!('serviceWorker' in navigator)) {
        throw new Error("Your browser doesn't support service worker");
    }

    const messaging: firebase.messaging.Messaging = getMessaging();

    if (!isServiceWorkerRegistered) {
        await registerServiceWorker(messaging);
    }

    messaging
        .getToken()
        .then((token) => sendPushNotificationsToken(token))
        .catch((error: unknown) => {
            throw new Error('Error while trying to create Firebase Messaging token' + error);
        });

    setUpHandlers(messaging);
}
