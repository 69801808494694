import {Form, FormInstance, Input} from 'antd';
import {useContext, useMemo} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {useKnowledgeList} from '../../../service/knowledge/knowledge-hook';
import {KnowledgeOptionsType} from '../../../service/knowledge/knowledge-type';

import {KnowledgeContentPage} from './knowledge-content-page/knowledge-content-page';
import {serializeKnowledgeFilter} from './knowledge-page-helper';
import {useKnowledgeFilter} from './knowledge-page-hook';
import {KnowledgeFilterEnum, KnowledgeFilterType} from './knowledge-page-type';
import * as styles from './knowledge-page.scss';

export function KnowledgePage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const [form]: [FormInstance<KnowledgeFilterType>] = Form.useForm();

    const {filter, setFilter} = useKnowledgeFilter();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const filterOptions: KnowledgeOptionsType = useMemo<KnowledgeOptionsType>(
        (): KnowledgeOptionsType => serializeKnowledgeFilter(filter),
        [filter]
    );

    const {result, isInProgress, processError} = useKnowledgeList({
        filterOptions,
        mainFilterKey,
    });

    function handleSearch(value: string) {
        setFilter({
            ...filter,
            [KnowledgeFilterEnum.name]: value,
        });
        track(AnalyticsTarget.Knowledge.Search, value);
    }

    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('CATEGORY_NAME__KNOWLEDGE')} />

            <PageCard className={styles.KnowledgePage_pageCard}>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.knowledge.path,
                            titleLangKey: 'KNOWLEDGE__TITLE',
                        },
                    ]}
                />

                <PageHeader>
                    <Locale stringKey="KNOWLEDGE__TITLE" />
                </PageHeader>

                <Form<KnowledgeFilterType> form={form}>
                    <Form.Item
                        className={styles.KnowledgePage_formItem}
                        initialValue={filter[KnowledgeFilterEnum.name]}
                        name={KnowledgeFilterEnum.name}
                    >
                        <Input.Search
                            allowClear
                            enterButton
                            loading={isInProgress}
                            onBlur={(event) => handleSearch(event.target.value)}
                            onSearch={handleSearch}
                            placeholder={getLocalizedString('REVIEWS_GENERATOR__MAILINGS_SEARCH_INPUT__PLACEHOLDER')}
                        />
                    </Form.Item>
                </Form>
            </PageCard>

            <KnowledgeContentPage
                isInProgress={isInProgress}
                processError={processError}
                result={result?.content || []}
            />
        </Page>
    );
}
