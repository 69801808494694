import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'antd';
import {useState} from 'react';

import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {CatalogByGroupType} from '../../../../../../service/source-settings/catalogs/catalogs-type';
import {useUpdateSourceLink} from '../../../../../../service/source-settings/source-links/source-links-hook';
import {
    ManageSourceLinkError,
    NoPermitSourceLinkError,
    SourceLinkType,
    UpdateSourceLinkType,
} from '../../../../../../service/source-settings/source-links/source-links-type';
import {ApiError} from '../../../../../../util/error';
import {getErrorDescriptionText} from '../add-link/add-link-helper';
import {ManageLinkForm} from '../manage-link-form/manage-link-form';
import {ManageLinkFormEnum, ManageLinkFormType} from '../manage-link-form/manage-link-form-type';

import {editLinkFormId} from './edit-link-const';
import * as styles from './edit-link.scss';

type PropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    linkEntity: SourceLinkType;
    onSuccess: () => void;
};

export function EditLink(props: PropsType): JSX.Element {
    const {catalog, catalogGroupId, linkEntity, onSuccess} = props;

    const {snackbar} = useSnackbar();
    const {isInProgress, processError, update, reset} = useUpdateSourceLink();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {companyName} = useDomainConfig();
    const {getCatalogName} = useCatalogInfo();

    const serverValidationErrors =
        processError instanceof ManageSourceLinkError
            ? {
                  [ManageLinkFormEnum.link]: processError.jsonData.status
                      ? [processError.jsonData.status]
                      : processError.jsonData.link,
              }
            : {};

    function onOpenModal() {
        reset();
        setIsModalOpen(true);
    }

    async function onSubmit(values: ManageLinkFormType): Promise<void> {
        const {link, deleteReviews, company} = values;
        const updatePayload: UpdateSourceLinkType = {link, deleteReviews, companyId: company?.id};

        try {
            await update(linkEntity.id, updatePayload);
            snackbar.success({
                description: (
                    <Locale
                        stringKey={
                            deleteReviews
                                ? 'SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__SUCCESS__CONTENT__REMOVE_REVIEWS'
                                : 'SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__SUCCESS__CONTENT__KEEP_REVIEWS'
                        }
                        valueMap={{
                            catalogName: (
                                <span className={styles.edit_link__success_catalog} key="catalog">
                                    {getCatalogName(catalog.id)}
                                </span>
                            ),
                            companyName,
                        }}
                    />
                ),
                message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__SUCCESS__TITLE" />,
            });
            onSuccess();
            setIsModalOpen(false);
        } catch (error: unknown) {
            if (error instanceof NoPermitSourceLinkError) {
                snackbar.error({
                    description: <span>{error.message}</span>,
                    message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__FAIL__TITLE" />,
                });
            } else {
                snackbar.error({
                    description: getErrorDescriptionText(error),
                    message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__FAIL__TITLE" />,
                });
            }

            if (!(error instanceof ApiError)) {
                throw error;
            }
        }
    }

    return (
        <>
            <button className={styles.edit_link__button} onClick={onOpenModal} type="button">
                <FontAwesomeIcon icon={faEdit} />
            </button>

            <Modal
                destroyOnClose
                footer={[
                    <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>,
                    <Button form={editLinkFormId} htmlType="submit" key="submit" loading={isInProgress} type="primary">
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>,
                ]}
                onCancel={() => setIsModalOpen(false)}
                open={isModalOpen}
                title={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__TITLE" />}
            >
                <ManageLinkForm
                    catalog={catalog}
                    catalogGroupId={catalogGroupId}
                    formId={editLinkFormId}
                    initialValues={{
                        ...linkEntity,
                        brandId: linkEntity.brand.id,
                        company: linkEntity.company || null,
                    }}
                    isEditMode
                    onSubmit={onSubmit}
                    serverErrors={serverValidationErrors}
                />
            </Modal>
        </>
    );
}
