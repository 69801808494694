import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactElement} from 'react';

import {Text} from '../../../../../component/text/text';
import {LangKeyType} from '../../../../../provider/locale/translation/type';

import * as styles from './statistic-card.scss';

type PropsType = {
    value: number;
    icon: IconDefinition;
    text: LangKeyType;
};

export function StatisticCard(props: PropsType): ReactElement {
    const {text, icon, value} = props;

    return (
        <div className={styles.StatisticCard}>
            <FontAwesomeIcon className={styles.StatisticCard_icon} icon={icon} />
            <Text className={styles.StatisticCard_title} stringKey={text} />
            <Text className={styles.StatisticCard_value}>{value}</Text>
        </div>
    );
}
