import {Checkbox, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Reference} from 'rc-table/lib/interface';
import {FunctionComponent, LegacyRef, MutableRefObject, useCallback, useEffect, useMemo, useRef} from 'react';

import {SelectorCompanyType} from '../../../service/feature-companies/feature-companies-type';
import {classNames} from '../../../util/css';
import {useRefreshId} from '../../../util/hook';
import {onScrollInfiniteScrollContainer} from '../../../util/infinite-scroll';
import {CompanySelectAdditionalDataColumnPropsType} from '../items/company-select-item-type';

import {
    checkHasSelectorRowBrandId,
    getCompaniesSelectorColumns,
    getIsDisabledRow,
} from './companies-selector-table-helper';
import * as styles from './companies-selector-table.scss';

type PropsType<CompanyType extends SelectorCompanyType> = {
    isInProgress: boolean;
    companies: Array<CompanyType>;
    disabled?: boolean;
    onSelectCompany: (companyId: number) => void;
    onDeselectCompany: (companyId: number) => void;
    loadMoreCompanies: () => void;
    renderAdditionalContent?: FunctionComponent<CompanySelectAdditionalDataColumnPropsType<CompanyType>>;
    scrollToTopDependency: unknown;
    onRowCallback?: (record: CompanyType) => void;
    selectedBrandId?: number | null;
    isRowDisabled?: (company: CompanyType) => boolean;
    columns?: ColumnsType<CompanyType>;
};

export function CompaniesSelectorTable<CompanyType extends SelectorCompanyType = SelectorCompanyType>(
    props: PropsType<CompanyType>
): JSX.Element {
    const {
        isInProgress,
        companies,
        disabled,
        onSelectCompany,
        onDeselectCompany,
        renderAdditionalContent: AdditionalContentComponent,
        loadMoreCompanies,
        scrollToTopDependency,
        onRowCallback,
        selectedBrandId,
        isRowDisabled,
        columns,
    } = props;

    const companiesListRef: MutableRefObject<Array<CompanyType>> = useRef(companies);

    const {refresh, refreshId} = useRefreshId();

    useEffect(() => {
        if (!companiesListRef.current?.every((item) => companies.some((company) => company.id === item.id))) {
            refresh();
        }

        companiesListRef.current = companies;
    }, [companies, refresh]);

    const containerRef: LegacyRef<ProxyHandler<HTMLDivElement> & Reference> | null = useRef(null);

    const onRow = useCallback(
        (record: CompanyType) => ({
            onClick: () => {
                if (disabled || isRowDisabled?.(record)) {
                    return;
                }

                if (selectedBrandId && checkHasSelectorRowBrandId(record) && selectedBrandId !== record.brandId) {
                    return;
                }

                if (record.selected) {
                    onDeselectCompany(record.id);
                    return;
                }

                onRowCallback?.(record);

                onSelectCompany(record.id);
            },
        }),
        [disabled, isRowDisabled, selectedBrandId, onRowCallback, onSelectCompany, onDeselectCompany]
    );

    const onContainerScroll = useCallback(
        (event: Event) => {
            if (!isInProgress) {
                onScrollInfiniteScrollContainer(event, loadMoreCompanies);
            }
        },
        [isInProgress, loadMoreCompanies]
    );

    const columnsInner = useMemo(() => {
        return columns || getCompaniesSelectorColumns(AdditionalContentComponent);
    }, [AdditionalContentComponent, columns]);

    useEffect(() => {
        const tableBody = containerRef?.current?.nativeElement.querySelector('.ant-table-body');

        tableBody?.addEventListener('scroll', onContainerScroll);

        return () => {
            tableBody?.removeEventListener('scroll', onContainerScroll);
        };
    }, [onContainerScroll, refreshId]);

    useEffect(() => {
        const tableBody = containerRef.current?.nativeElement.querySelector('.ant-table-body');

        tableBody?.scrollTo({
            top: 0,
        });
    }, [scrollToTopDependency]);

    return (
        <Table<CompanyType>
            className={styles.CompaniesSelectorTable}
            columns={columnsInner}
            dataSource={companies}
            key={refreshId}
            loading={isInProgress}
            onRow={onRow}
            pagination={false}
            ref={containerRef}
            rowClassName={(record) =>
                classNames(styles.CompaniesSelectorTable_companyRow, {
                    [styles.CompaniesSelectorTable_companyRow__disabled]: record.isInProgress,
                    [styles.CompaniesSelectorTable_companyRow__forbidden]: getIsDisabledRow(record, selectedBrandId),
                })
            }
            rowKey={(item: CompanyType) => item.id}
            rowSelection={{
                columnWidth: 47,
                hideSelectAll: true,
                selectedRowKeys: companies
                    .map((company) => {
                        if (company.selected) {
                            return company.id;
                        }

                        return false;
                    })
                    .filter(Boolean),
                renderCell: (_value, record) => {
                    return (
                        <Checkbox
                            checked={record.selected}
                            disabled={disabled || record.isInProgress || isRowDisabled?.(record)}
                        />
                    );
                },
            }}
            scroll={{
                y: 380,
                x: 806,
            }}
            size="small"
        />
    );
}
