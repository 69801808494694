import {faBars, faBell, faCircleQuestion, faGlobe, faLightbulb} from '@fortawesome/pro-regular-svg-icons';
import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space, theme} from 'antd';

import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/localization';
import {NotificationProvider} from '../../../../provider/notification/notification';
import {classNames} from '../../../../util/css';
import {Text} from '../../../text/text';

import {HeaderButtonEnum, useVisibleHeaderButtons} from './header-buttons-hook';
import {HelpLinksMenu} from './help-links-menu/help-links-menu';
import {LanguageMenu} from './language-menu/language-menu';
import {NotificationsMenu} from './notifications-menu/notifications-menu';
import {ProfileMenu} from './profile-menu/profile-menu';
import * as styles from './header-buttons.scss';

type PropsType = {
    className?: string;
    buttons?: Array<HeaderButtonEnum> | null;
};

// eslint-disable-next-line complexity
export function HeaderButtons(props: PropsType): JSX.Element | null {
    const {className, buttons} = props;
    const documentationLinks = useDocumentationLinks();

    const visibleButtons = useVisibleHeaderButtons(buttons);
    const {availableLocales} = useDomainConfig();
    const {token} = theme.useToken();

    if (visibleButtons.length === 0) {
        return null;
    }

    return (
        <div className={classNames(styles.HeaderButtons, className)}>
            {visibleButtons.includes(HeaderButtonEnum.Tariffs) && (
                <NavigationLink className={styles.TariffsButton} to={appRoute.tariffsAndLimits.path}>
                    <Locale stringKey="HEADER__TARIFFS__TITLE" />
                </NavigationLink>
            )}

            {visibleButtons.includes(HeaderButtonEnum.SuggestIdea) && documentationLinks.ideaLink && (
                <a href={documentationLinks.ideaLink} id="linkSuggestIdea" rel="noreferrer" target="_blank">
                    <Button
                        className={styles.IdeaButton}
                        color={token.colorTextSecondary}
                        icon={<FontAwesomeIcon className={styles.icon} icon={faLightbulb} />}
                        type="text"
                    />
                </a>
            )}
            {visibleButtons.includes(HeaderButtonEnum.HelpLinks) && (
                <HelpLinksMenu
                    icon={<FontAwesomeIcon aria-label="Help" className={styles.icon} icon={faCircleQuestion} />}
                />
            )}

            {visibleButtons.length === 1 && visibleButtons.includes(HeaderButtonEnum.Language) && (
                <LanguageMenu
                    icon={<FontAwesomeIcon aria-label="Language" className={styles.icon} icon={faGlobe} />}
                    text={<Text stringKey="LANGUAGE_SELECTOR__TITLE" />}
                />
            )}

            {availableLocales.length > 1 && visibleButtons.includes(HeaderButtonEnum.Notifications) && (
                <NotificationProvider>
                    <NotificationsMenu
                        icon={<FontAwesomeIcon aria-label="Notifications" className={styles.icon} icon={faBell} />}
                    />
                </NotificationProvider>
            )}

            {visibleButtons.includes(HeaderButtonEnum.Profile) && (
                <ProfileMenu
                    icon={
                        <Space className={styles.ProfileButton_wrapper}>
                            <FontAwesomeIcon aria-label="Profile" className={styles.icon} icon={faBars} />
                            <FontAwesomeIcon className={styles.ProfileButton_userIcon} icon={faUser} />
                        </Space>
                    }
                />
            )}
        </div>
    );
}
