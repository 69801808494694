import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Collapse, Modal} from 'antd';

import {Text} from '../../../../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    GoogleConfirmationCompanyLocationType,
    GoogleConfirmationCompanyType,
} from '../../../../../../../../service/google/confirmation/google-confirmation-type';
import {GoogleLocationType} from '../../../../../../../../service/google/google-type';
import {CompanyListItem} from '../../../../common/company-list-item/company-list-item';

import * as styles from './processing-step-rights-modal.scss';

type PropsType = {
    location: GoogleConfirmationCompanyType['company'] | GoogleConfirmationCompanyLocationType | GoogleLocationType;
    adminRightsUrl?: string | null;
    open: boolean;
    footer: JSX.Element;
    onCancel?: () => void;
    onOk: () => void;
};

export function ProcessingStepRightsModal(props: PropsType): JSX.Element {
    const {location, adminRightsUrl, open, footer, onCancel, onOk} = props;

    const documentationLinks = useDocumentationLinks();

    function getCompanyOrLocation() {
        if ('locationId' in location) {
            return {
                ...location,
                originId: location.locationId,
                mapsUrl: location.linkToMap,
            };
        }

        if ('pk' in location) {
            return {
                ...location,
                id: location.pk,
            };
        }

        return location;
    }

    return (
        <Modal
            // eslint-disable-next-line no-undefined
            cancelButtonProps={onCancel ? undefined : {hidden: true}}
            cancelText={<Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__CANCEL" />}
            destroyOnClose
            okText={<Locale stringKey="BUTTON__FINISH" />}
            onCancel={onCancel}
            onOk={onOk}
            open={open}
            title={<Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__TITLE" />}
            width={520}
        >
            {location && (
                <CompanyListItem className={styles.Modal_company} company={getCompanyOrLocation()} showStatus={false} />
            )}

            <Text
                block
                bolder
                className={styles.Modal_subheader}
                stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__SUBHEADER"
            />

            <Text block lighter stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__DESCRIPTION_1" />

            <Text
                block
                lighter
                stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__DESCRIPTION_2"
                valueMap={{
                    workAccount: <Text bolder stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__WORK_ACCOUNT" />,
                }}
            />

            {adminRightsUrl && (
                <a className={styles.Modal_link} href={adminRightsUrl} rel="noreferrer" target="_blank">
                    <Button
                        className={styles.Modal_linkButton}
                        icon={<FontAwesomeIcon icon={faExternalLink} />}
                        size="small"
                        type="link"
                    >
                        <span>
                            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__CLAIM" />
                        </span>
                    </Button>
                </a>
            )}

            <Collapse
                className={styles.Modal_collapse}
                items={[
                    {
                        label: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__MORE__TITLE" />,
                        key: '1',
                        children: (
                            <>
                                <Text
                                    lighter
                                    stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__MORE__DESCRIPTION_1"
                                    valueMap={{
                                        responseTime: (
                                            <Text
                                                bolder
                                                stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__RESPONSE_TIME"
                                            />
                                        ),
                                    }}
                                />

                                {documentationLinks.google.requestRights && (
                                    <Text
                                        block
                                        className={styles.Modal_collapseLinks}
                                        lighter
                                        stringKey="GOOGLE_SYNC__PROCESSING_STEP__RIGHTS_MODAL__MORE__DESCRIPTION_2"
                                        valueMap={{
                                            link: (
                                                <a
                                                    href={documentationLinks.google.requestRights}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <Locale stringKey="TEXT__HERE" />
                                                </a>
                                            ),
                                        }}
                                    />
                                )}
                            </>
                        ),
                    },
                ]}
            />

            {footer}
        </Modal>
    );
}
