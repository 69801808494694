import {ReactNode, useEffect} from 'react';

import {PageCard} from '../../layout/page-card/page-card';
import {LicenseType} from '../../provider/license/license-provider-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {LangKeyType} from '../../provider/locale/translation/type';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {LicenseCard} from '../license-card/license-card';

type PropsType = {
    icon: ReactNode;
    productLandKey: LangKeyType;
    license?: LicenseType;
};

export function LicensePageCard(props: PropsType): JSX.Element | null {
    const {icon, productLandKey, license} = props;

    const {snackbar} = useSnackbar();

    const {getLocalizedString} = useLocale();

    useEffect(() => {
        if (!license?.isActive) {
            snackbar.error({
                message: getLocalizedString('LICENSE_SNACKBAR__NOT_ACTIVE__TITLE'),
                description: getLocalizedString('LICENSE_SNACKBAR__NOT_ACTIVE__MESSAGE'),
            });
        }
    }, [getLocalizedString, license?.isActive, snackbar]);

    if (license && (license.isTrial || !license?.isActive)) {
        return (
            <PageCard collapsible title={getLocalizedString('LICENSE_CARD__TITLE')}>
                <LicenseCard icon={icon} license={license} productName={getLocalizedString(productLandKey)} />
            </PageCard>
        );
    }

    return null;
}
