import {PropsWithChildren} from 'react';

import {appRoute} from '../../../../../../app-route';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {objectToUrlParameters} from '../../../../../../util/url';
import {useUrl} from '../../../../../../util/url-hook/url-hook';
import {concordanceQueryName} from '../../../reviews-analysis-type';

type PropsType = PropsWithChildren<{
    query: string;
}>;

export function ConcordanceLink(props: PropsType): JSX.Element {
    const {children, query} = props;

    const {queries} = useUrl();

    return (
        <NavigationLink
            to={
                appRoute.reviewsAnalysisConcordance.path +
                '?' +
                objectToUrlParameters({...queries, [concordanceQueryName]: [query]})
            }
        >
            {children}
        </NavigationLink>
    );
}
