import {DefaultTooltipStaticPropsType} from '../../../../component/chart-tooltip/default-chart-tooltip';
import {barChartOptions} from '../../../../layout/chart/bar-chart/bar-chart-helper';
import {ChartJsBarOptionsType} from '../../../../layout/chart/bar-chart/bar-chart-type';
import {getDefaultReactTooltipOptions} from '../../../../layout/chart/chart-const';
import {LegendBox} from '../../../../layout/chart/legend-box/legend-box';
import {Locale} from '../../../../provider/locale/localization';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {
    ActionDynamicsEnum,
    OnlinePresenceDashboardDataCommonType,
} from '../../../../service/online-presence/online-presence-type';
import {noop} from '../../../../util/function';

import * as styles from './target-actions-dynamics.scss';

export const targetActionsLangKeyMap: Record<ActionDynamicsEnum, LangKeyType> = {
    [ActionDynamicsEnum.CallClicks]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__CALLS',
    [ActionDynamicsEnum.WebsiteClicks]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__SITE',
    [ActionDynamicsEnum.DirectRequests]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__ROUTES',
    [ActionDynamicsEnum.Other]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__OTHER',
};

export const targetActionsTableLangKeyMap: Record<ActionDynamicsEnum, LangKeyType> = {
    [ActionDynamicsEnum.CallClicks]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__CALLS',
    [ActionDynamicsEnum.WebsiteClicks]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__SITE',
    [ActionDynamicsEnum.DirectRequests]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__ROUTES',
    [ActionDynamicsEnum.Other]: 'ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS_TABLE__OTHER',
};

export const targetActionsColorMap: Record<ActionDynamicsEnum, string> = {
    [ActionDynamicsEnum.CallClicks]: styles.callClicksChartColor,
    [ActionDynamicsEnum.WebsiteClicks]: styles.websiteClicksChartColor,
    [ActionDynamicsEnum.DirectRequests]: styles.directRequestsChartColor,
    [ActionDynamicsEnum.Other]: styles.otherChartColor,
};

export function renderTargetActionDynamicsSentiments(
    legendFilter: Array<ActionDynamicsEnum>,
    setLegendFilter: (value: Array<ActionDynamicsEnum>) => void,
    data: OnlinePresenceDashboardDataCommonType['actionsDynamic']['table'],
    staticChart?: boolean
): JSX.Element {
    function onItemChange(value: ActionDynamicsEnum): void {
        if (legendFilter.includes(value)) {
            setLegendFilter(legendFilter.filter((legendItem: ActionDynamicsEnum): boolean => legendItem !== value));
        } else {
            setLegendFilter([...legendFilter, value]);
        }
    }

    return (
        <>
            {data.map((item) => {
                return (
                    <LegendBox
                        checked={legendFilter.includes(item.label)}
                        color={targetActionsColorMap[item.label]}
                        key={item.label}
                        label={<Locale stringKey={targetActionsLangKeyMap[item.label]} />}
                        onChange={staticChart ? noop : () => onItemChange(item.label)}
                    />
                );
            })}
        </>
    );
}

export function getTargetActionDynamicsChartOptions(
    tooltipOptions: DefaultTooltipStaticPropsType,
    showTooltip?: boolean
): ChartJsBarOptionsType {
    return {
        ...barChartOptions,
        plugins: {
            ...barChartOptions.plugins,
            legend: {
                display: false,
            },
            tooltip: showTooltip ? getDefaultReactTooltipOptions(tooltipOptions) : {enabled: false},
            datalabels: {display: false},
        },
    };
}

const actionDynamicsPriority: Record<ActionDynamicsEnum, number> = {
    [ActionDynamicsEnum.CallClicks]: 1,
    [ActionDynamicsEnum.WebsiteClicks]: 2,
    [ActionDynamicsEnum.DirectRequests]: 3,
    [ActionDynamicsEnum.Other]: 4,
};

export function sortActionDynamicsValues(
    item1: {label: ActionDynamicsEnum},
    item2: {label: ActionDynamicsEnum}
): number {
    return actionDynamicsPriority[item1.label] - actionDynamicsPriority[item2.label];
}
