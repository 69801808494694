import {classNames} from '../../util/css';

import * as styles from './ascii-spinner.scss';

type PropsType = {
    isShow?: boolean;
    className?: string;
};

export function AsciiSpinner(props: PropsType): JSX.Element | null {
    const {isShow = true, className} = props;

    if (!isShow) {
        return null;
    }

    const wrapperClassName = classNames(styles.AsciiSpinner, className);

    return <span aria-busy className={wrapperClassName} role="alert" />;
}
