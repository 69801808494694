import {FormListProps} from 'antd/lib/form';
import {useState} from 'react';

import {LanguagesPopup} from '../../../../../../../component/languages-popup/languages-popup';
import {AddButton} from '../../../../../../../layout/add-button/add-button';
import {CatalogConfigType} from '../../../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {useLanguages} from '../../../../../../../service/language/language-hook';
import {Form} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';

import {NameInput} from './name-input/name-input';
import * as styles from './company-names-list.scss';

type PropsType = {
    hasInitialNames: boolean;
    name: FormListProps['name'];
    basePath: [CompanyKeyEnum.Names] | [CompanyKeyEnum.ExtraNames, number, 'names'];
    catalog?: CatalogConfigType;
    openRemovalModal?: () => void;
};

export function CompanyNamesList(props: PropsType): JSX.Element {
    const {hasInitialNames, name: listName, basePath, catalog, openRemovalModal} = props;

    const {result} = useLanguages();
    const [isLanguagesPopupOpen, setIsLanguagesPopupOpen] = useState(false);

    // Shows at least 1 item in list
    // TODO: automatically change lang after changing the global locale?
    // eslint-disable-next-line no-undefined
    const initialValue = hasInitialNames ? undefined : [{name: '', lang: null}];

    return (
        <Form.List initialValue={initialValue} name={listName}>
            {(names, {add, remove}) => (
                <>
                    {/* necessary for last-child selector */}
                    <div>
                        {names.map(({key, name: fieldName, fieldKey}, index) => (
                            <NameInput
                                basePath={basePath}
                                fieldKey={fieldKey}
                                fieldName={fieldName}
                                index={index}
                                key={key}
                                openRemovalModal={names.length === 1 ? openRemovalModal : null}
                                remove={remove}
                                supportsNameWithoutLanguage={catalog ? catalog.supportsNameWithoutLanguage : true}
                                supportsPushedName={catalog ? catalog.supportsPushedName : true}
                            />
                        ))}
                    </div>

                    {result && (
                        <Form.Item<CompanyFormType> dependencies={basePath} noStyle>
                            {({getFieldValue}) => {
                                const catalogLanguages =
                                    catalog && catalog.namesLanguages.length > 0
                                        ? result.filter(({alpha2}) => catalog.namesLanguages.includes(alpha2))
                                        : result;

                                const usedLanguages = new Set(
                                    getFieldValue(basePath)
                                        .map(({lang}) => lang)
                                        .filter(Boolean)
                                );

                                const languages = catalogLanguages.filter(({alpha2}) => !usedLanguages.has(alpha2));

                                return (
                                    <>
                                        {catalog?.catalogId !== ProvidersIdsEnum.google && (
                                            <Form.Item<CompanyFormType>
                                                dependencies={[[CompanyKeyEnum.Names, 0, 'name']]}
                                                noStyle
                                            >
                                                {({getFieldValue: innerGetFieldValue}) => (
                                                    <AddButton
                                                        className={styles.company_names__add__language_button}
                                                        isDisabled={
                                                            languages.length === 0 ||
                                                            !innerGetFieldValue([CompanyKeyEnum.Names, 0, 'name'])
                                                        }
                                                        onClick={() => setIsLanguagesPopupOpen(true)}
                                                    >
                                                        <Locale stringKey="COMPANY_FORM__NAMES__BUTTON__ADD_OPTIONS_IN_OTHER_LANGUAGES" />
                                                    </AddButton>
                                                )}
                                            </Form.Item>
                                        )}

                                        <LanguagesPopup
                                            isOpen={isLanguagesPopupOpen}
                                            languages={catalogLanguages.filter(
                                                ({alpha2}) => !usedLanguages.has(alpha2)
                                            )}
                                            onApply={(languagesAlpha2: Array<string>) => {
                                                languagesAlpha2.forEach((language) =>
                                                    add({
                                                        name: '',
                                                        lang: language,
                                                        pushedName: '',
                                                    })
                                                );

                                                setIsLanguagesPopupOpen(false);
                                            }}
                                            onClose={() => setIsLanguagesPopupOpen(false)}
                                        />
                                    </>
                                );
                            }}
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
}
