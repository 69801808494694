import {computeDestinationPoint} from 'geolib';
import {LatLngLiteral, LatLngTuple} from 'leaflet';

import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';
import {LocalPackPointDistanceEnum, LocalPackSquareSizeEnum} from '../../../../../service/local-pack/local-pack-type';
import {NeverError} from '../../../../../util/error';
import {getRandomIntFromInterval} from '../../../../../util/number';

export function getCenterPointByLocaleName(shortLocaleName: ShortLocaleNameEnum): LatLngLiteral {
    const basePointByLocale: Record<ShortLocaleNameEnum, LatLngLiteral> = {
        [ShortLocaleNameEnum.en]: {
            lat: 51.533_64,
            lng: -0.181_467,
        },
        [ShortLocaleNameEnum.ru]: {
            lat: 55.751_244,
            lng: 37.618_423,
        },
        [ShortLocaleNameEnum.pl]: {
            lat: 52.234_999,
            lng: 21.016_732,
        },
        [ShortLocaleNameEnum.kk]: {
            lat: 51.157_698,
            lng: 71.438_903,
        },
        [ShortLocaleNameEnum.es]: {
            lat: 40.430_651,
            lng: -3.699_229,
        },
        [ShortLocaleNameEnum.bg]: {
            lat: 42.686_305,
            lng: 23.319_839,
        },
    };

    return basePointByLocale[shortLocaleName];
}

function getSquareSizeNumber(squareSize: LocalPackSquareSizeEnum): number {
    switch (squareSize) {
        case LocalPackSquareSizeEnum.Smallest:
            return 3;
        case LocalPackSquareSizeEnum.Small:
            return 5;
        case LocalPackSquareSizeEnum.Medium:
            return 7;
        case LocalPackSquareSizeEnum.Big:
            return 9;
        case LocalPackSquareSizeEnum.Biggest:
            return 11;
        default: {
            throw new NeverError(squareSize);
        }
    }
}

const BOUNDS_OFFSET_METERS = 250;

function getMapBounds(args: {point: LatLngLiteral; offset: number}): [LatLngTuple, LatLngTuple] {
    const {point, offset} = args;

    const topLeftBound = computeDestinationPoint(point, offset * Math.sqrt(2), 135);
    const rightBottomBound = computeDestinationPoint(point, offset * Math.sqrt(2), 315);

    return [
        [topLeftBound.latitude, topLeftBound.longitude],
        [rightBottomBound.latitude, rightBottomBound.longitude],
    ];
}

function getMapGridPoints(args: {gridSize: number; distanceBetweenPoints: number; leftTopPoint: LatLngLiteral}): Array<{
    point: LatLngTuple;
    position: number;
    id: string;
}> {
    const {gridSize, distanceBetweenPoints, leftTopPoint} = args;

    return Array.from({length: Math.pow(gridSize, 2)}).map((_value, index) => {
        const eastOffset = (index % gridSize) * distanceBetweenPoints;
        const northOffset = -Math.floor(index / gridSize) * distanceBetweenPoints;
        const pointWithEastOffset = computeDestinationPoint(leftTopPoint, eastOffset, 0);

        const resultPoint = computeDestinationPoint(pointWithEastOffset, northOffset, 90);

        return {
            point: [resultPoint.latitude, resultPoint.longitude],
            position: getRandomIntFromInterval(1, 8),
            id: String(index),
        };
    });
}

export function getLocalpackMapExampleData(options: {
    centerPoint: LatLngLiteral;
    pointsDistance: LocalPackPointDistanceEnum;
    squareSize: LocalPackSquareSizeEnum;
}): {
    points: Array<{
        point: LatLngTuple;
        position: number;
        id: string;
    }>;
    bounds: [LatLngTuple, LatLngTuple];
} {
    const {centerPoint, pointsDistance: distanceBetweenPoints, squareSize} = options;

    const squareSizeNumber = getSquareSizeNumber(squareSize);

    const leftTopPointOffsetFromCenter = Math.floor(squareSizeNumber / 2) * distanceBetweenPoints;

    const gridLeftTopPoint = computeDestinationPoint(centerPoint, leftTopPointOffsetFromCenter * Math.sqrt(2), 135);

    return {
        points: getMapGridPoints({
            gridSize: squareSizeNumber,
            leftTopPoint: {
                lat: gridLeftTopPoint.latitude,
                lng: gridLeftTopPoint.longitude,
            },
            distanceBetweenPoints,
        }),
        bounds: getMapBounds({
            point: centerPoint,
            offset: leftTopPointOffsetFromCenter + BOUNDS_OFFSET_METERS,
        }),
    };
}
