import {classNames} from '../../util/css';

import * as styles from './chart-tooltip.scss';

type PropsType = {
    label: string | JSX.Element;
    color?: string;
    borderColor?: string;
    value: string | number | JSX.Element;
    className?: string;
};

export function ChartTooltipRow(props: PropsType): JSX.Element {
    const {label, color, borderColor, value, className} = props;

    return (
        <div className={styles.ChartTooltip_contentRow}>
            <div className={classNames(styles.ChartTooltip_contentLabelContainer, className)}>
                {color && (
                    <div
                        className={styles.ChartTooltip_contentLabelBox}
                        style={{
                            background: color,
                            borderColor,
                        }}
                    />
                )}
                <span>{label}</span>
            </div>

            <div className={styles.ChartTooltip_value}>{value ?? 0}</div>
        </div>
    );
}
