import dayjs from 'dayjs';

import {saveToLocalStorage} from '../../../../../util/local-storage';

import {
    GET_BUY_LICENSE_NEURALINK_LOCALSTORAGE_KEY,
    GET_TRIAL_LICENSE_NEURALINK_LOCALSTORAGE_KEY,
} from './neuralink-alert-const';
import {AlertDataType} from './neuralink-alert-type';

export function getNeuralLinkAlertData(
    isTrial?: boolean,
    canActivateTrial?: boolean,
    finishAt?: string | null
): AlertDataType {
    const today = dayjs();

    const finishAtDate = dayjs(finishAt);

    const startRange = finishAtDate.subtract(10, 'day');
    const isTrialPeriodWillEndSoon = isTrial && today.isAfter(startRange) && today.isBefore(finishAtDate);

    if (canActivateTrial) {
        return {
            title: 'NEURAL_NETWORK__ALERT__GET_TRIAL__TITLE',
            description: 'NEURAL_NETWORK__ALERT__GET_TRIAL__DESCRIPTION',
            button: 'NEURAL_NETWORK__ALERT__GET_TRIAL__ACTION_BUTTON',
        };
    }

    if (isTrialPeriodWillEndSoon) {
        return {
            title: 'NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__TITLE',
            description: 'NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__DESCRIPTION',
            button: 'NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__ACTION_BUTTON',
        };
    }

    return {
        title: 'NEURAL_NETWORK__ALERT__GET_TRIAL_IS_OVER__TITLE',
        description: 'NEURAL_NETWORK__ALERT__GET_TRIAL_IS_OVER__DESCRIPTION',
        button: 'NEURAL_NETWORK__ALERT__GET_TRIAL_END_SOON__ACTION_BUTTON',
    };
}

export function onCloseAlert(isTrialLicense?: boolean): void {
    saveToLocalStorage(
        false,
        isTrialLicense ? GET_TRIAL_LICENSE_NEURALINK_LOCALSTORAGE_KEY : GET_BUY_LICENSE_NEURALINK_LOCALSTORAGE_KEY
    );
}
