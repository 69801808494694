import {Context, createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';

import {AnalyticsTarget, track} from '../../service/analytics/analytics';
import {
    getNotification,
    markAllNotificationAsRead,
    markNotificationAsRead,
} from '../../service/notification/notification-api';
import {MarkNotificationAsReadResultType, NotificationResultType} from '../../service/notification/notification-type';
import {useIsMounted} from '../../util/is-mounted';
import {catchError} from '../../util/promise';

import {defaultNotificationContext} from './notification-const';
import {NotificationContextType} from './notification-type';

export const NotificationContext: Context<NotificationContextType> =
    createContext<NotificationContextType>(defaultNotificationContext);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function NotificationProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const [notificationResult, setNotificationResult] = useState<NotificationResultType>(
        defaultNotificationContext.notificationResult
    );
    const [stateRefreshId, setStateRefreshId] = useState<number>(0);

    const isMounted = useIsMounted();

    useEffect(() => {
        getNotification()
            .then((result) => {
                if (!isMounted.current) {
                    return;
                }

                setNotificationResult(result);
            })
            .catch(catchError);
    }, [stateRefreshId]);

    const markAllNotificationAsReadMemoized = useCallback(() => {
        return markAllNotificationAsRead()
            .then(() => {
                setStateRefreshId(stateRefreshId + 1);
            })
            .catch(catchError);
    }, [stateRefreshId]);

    const markNotificationAsReadMemoized = useCallback(
        (notificationId: string): Promise<MarkNotificationAsReadResultType> => {
            return markNotificationAsRead(notificationId)
                .then(
                    (
                        markNotificationAsReadResult: MarkNotificationAsReadResultType
                    ): MarkNotificationAsReadResultType => {
                        setStateRefreshId(stateRefreshId + 1);
                        return markNotificationAsReadResult;
                    }
                )
                .catch(catchError)
                .finally(() => track(AnalyticsTarget.PersonalCabinet.NotificationLink, notificationId));
        },
        [stateRefreshId]
    );

    const providerData: NotificationContextType = useMemo((): NotificationContextType => {
        return {
            notificationResult,
            markAllNotificationAsRead: markAllNotificationAsReadMemoized,
            markNotificationAsRead: markNotificationAsReadMemoized,
        };
    }, [notificationResult, markAllNotificationAsReadMemoized, markNotificationAsReadMemoized]);

    return <NotificationContext.Provider value={providerData}>{children}</NotificationContext.Provider>;
}
