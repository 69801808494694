import {Form} from 'antd';
import {useState} from 'react';

import {
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    PostImageType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {ProvidersEnum} from '../../../../../../../util/type';
import {PostContentWrapper} from '../../../post-content-wrapper/post-content-wrapper';
import {FACEBOOK_POST_SETTINGS} from '../content-step-helper';
import {SourcePostPropsType} from '../content-step-type';
import {ButtonLinkItem} from '../items/button-link/button-link-item';
import {PostTextItem} from '../items/text/post-text-item';
import {PostPreview} from '../post-preview/post-preview';
import {PostRequirements} from '../post-requirements/post-requirements';

import {FacebookMediaItems} from './media/facebook-media-items';

export function FacebookCatalogData(props: SourcePostPropsType): JSX.Element {
    const {formInstance, suggestSettings, sourceIndex, formMode, companiesSelector} = props;

    const formValues = formInstance.getFieldValue([]) as PostFormType;

    const disabled = formMode === PostFormModeEnum.View;

    const fieldName = [PostFormFieldsEnum.Posts, sourceIndex];

    const buttonLink: Array<PostImageType> | undefined = Form.useWatch(
        [...fieldName, SourcePostFormFieldsEnum.ButtonLink],
        formInstance
    );

    const [hasMediaItems, setHasMediaItems] = useState<boolean>(false);

    return (
        <PostContentWrapper>
            <PostContentWrapper.Left>
                <PostTextItem
                    disabled={disabled}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Text]}
                    formInstance={formInstance}
                    maxLength={FACEBOOK_POST_SETTINGS.maxTextLength}
                    suggestSettings={suggestSettings}
                />

                {!buttonLink && (
                    <FacebookMediaItems
                        facebookNamePath={fieldName}
                        formInstance={formInstance}
                        formMode={formMode}
                        onHasMediaChanged={setHasMediaItems}
                    />
                )}

                {!hasMediaItems && (
                    <ButtonLinkItem
                        disabled={disabled}
                        fieldName={[...fieldName, SourcePostFormFieldsEnum.ButtonLink]}
                        required={false}
                    />
                )}
            </PostContentWrapper.Left>
            <PostContentWrapper.Right>
                <PostPreview
                    catalog={PostSourceEnum.facebook}
                    formInstance={formInstance}
                    sources={{
                        selectorId: companiesSelector?.selectorId,
                        fbGroupsIds: formValues[PostFormFieldsEnum.FbPagesIds],
                    }}
                />
                <PostRequirements options={FACEBOOK_POST_SETTINGS} provider={ProvidersEnum.facebook} />
            </PostContentWrapper.Right>
        </PostContentWrapper>
    );
}
