import {Form, FormInstance, Input} from 'antd';

import {InfiniteBrandSelector} from '../../../../../../../component/brand-select/infinite-brand-selector';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FilterFormEnum} from '../companies-step-const';
import {FilterFormType} from '../companies-step-type';

import * as styles from './filters.scss';

type PropsType = {
    form: FormInstance<FilterFormType>;
    initialValues: FilterFormType;
};

export function Filters(props: PropsType): JSX.Element {
    const {form, initialValues} = props;

    const {getLocalizedString} = useLocale();

    return (
        <Form className={styles.Form} form={form} initialValues={initialValues} layout="vertical">
            <Form.Item<FilterFormType>
                label={<Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__FILTER__COMPANIES__LABEL" />}
                name={FilterFormEnum.Search}
            >
                <Input.Search
                    allowClear
                    placeholder={getLocalizedString('GOOGLE_SYNC__COMPANIES_STEP__FILTER__COMPANIES__PLACEHOLDER')}
                />
            </Form.Item>

            <Form.Item<FilterFormType>
                label={<Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__FILTER__BRANDS__LABEL" />}
                name={FilterFormEnum.BrandsIds}
            >
                <InfiniteBrandSelector
                    allowClear
                    maxTagCount="responsive"
                    mode="multiple"
                    placeholder={getLocalizedString('GOOGLE_SYNC__COMPANIES_STEP__FILTER__BRANDS__PLACEHOLDER')}
                />
            </Form.Item>
        </Form>
    );
}
