import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../../../../component/text/text';
import {RatingStarLine} from '../../../../../layout/rating-star-line/rating-star-line';
import {ReviewCatalogType} from '../../../../../service/reviews/reviews-catalogs';
import {ReviewDataType} from '../../../../../service/reviews/reviews-type';
import {ReviewAbuse} from '../../review-abuse/review-abuse';
import {ReviewCompanyTitle} from '../../review-company-title/review-company-title';
import {ReviewHistory} from '../../review-history/review-history';
import {CopyComment} from '../../review-list-page-content/review-list/review/copy-comment/copy-comment';
import {CopyLink} from '../../review-list-page-content/review-list/review/copy-link/copy-link';
import {ReviewDelete} from '../../review-list-page-content/review-list/review/review-delete/review-delete';
import {ReviewEdit} from '../../review-list-page-content/review-list/review/review-edit/review-edit';
import {ReviewStatus} from '../../review-status/review-status';

import * as styles from './review-meta.scss';

type PropsType = {
    refreshReview: (reviewId: number) => Promise<unknown>;
    refreshLogs: () => void;
    reviewsCatalogList: Array<ReviewCatalogType> | null;
    review: ReviewDataType;
    closePage: () => void;
};

// eslint-disable-next-line complexity
export function ReviewMeta(props: PropsType): JSX.Element {
    const {reviewsCatalogList, review, refreshReview, refreshLogs, closePage} = props;
    const {
        id,
        brand,
        company,
        author,
        originUrl,
        rating,
        deletedAt,
        createdInCatalog,
        createdInRd,
        comment,
        processStatus,
        isChanged,
        catalogId,
        userFields,
    } = review;

    const catalog = (reviewsCatalogList || []).find((catalogInner) => catalogInner.id === catalogId) || null;

    const isReviewDeleted = Boolean(deletedAt);
    const isExternalReview = catalog?.is_external_reviews;

    function hundleRefreshAbuseReview(reviewId: number) {
        return Promise.all([refreshReview(reviewId), refreshLogs()]);
    }

    return (
        <>
            <ReviewStatus
                className={styles.ReviewMeta_status}
                onChange={refreshLogs}
                processStatus={processStatus}
                refreshReview={refreshReview}
                reviewId={id}
            />
            <div className={styles.ReviewMeta_actions}>
                <CopyComment text={comment || ''} />

                <CopyLink reviewId={id} />

                <ReviewAbuse refreshReview={hundleRefreshAbuseReview} reviewData={review} />

                {isChanged && (
                    <ReviewHistory createdInCatalog={createdInCatalog} createdInRd={createdInRd} reviewId={id} />
                )}

                {isExternalReview && !deletedAt && (
                    <>
                        <ReviewEdit onSubmitFinish={() => refreshReview(id)} review={review} />
                        <ReviewDelete onSubmitFinish={closePage} reviewId={id} />
                    </>
                )}
            </div>
            <div className={styles.ReviewMeta_author}>{author}</div>
            {userFields?.email && (
                <Text block lighter>
                    {userFields.email}
                </Text>
            )}
            {typeof rating === 'number' && <RatingStarLine rating={rating} />}
            <div className={styles.ReviewMeta_companyTitle}>
                <ReviewCompanyTitle brand={brand} className={styles.ReviewMeta_address} company={company} />
                {!isReviewDeleted && originUrl && (
                    <a className={styles.ReviewMeta_externalLink} href={originUrl} rel="noreferrer" target="_blank">
                        <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                )}
            </div>
        </>
    );
}
