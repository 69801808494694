import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Empty} from '../../../layout/empty/empty';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';

import * as styles from './dashboard.scss';

export function DashboardWithoutCompany(): JSX.Element {
    const {getLocalizedString} = useLocale();

    return (
        <>
            <PageCard>
                <Meta title={getLocalizedString('DASHBOARD_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.dashboard.path,
                            titleLangKey: 'DASHBOARD_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <PageHeaderTitle
                        productName={UsetifulNameProductEnum.DASHBOARD}
                        title="DASHBOARD_PAGE__SUB_TITLE"
                    />
                </PageHeader>
            </PageCard>
            <PageCard className={styles.dashboard_without_companies__page_card_wrapper}>
                <Empty
                    className={styles.dashboard_without_companies__empty_wrapper}
                    mainText="DASHBOARD_PAGE__WITHOUT_COMPANIES__HEADER"
                    secondaryText="DASHBOARD_PAGE__WITHOUT_COMPANIES__TEXT"
                    secondaryTextValueMap={{
                        sourceLink: (
                            <NavigationLink key="sourceLink" to={appRoute.sources.path}>
                                <Locale stringKey="CATEGORY_NAME__SOURCES" />
                            </NavigationLink>
                        ),
                        myCompaniesLink: (
                            <NavigationLink key="myCompaniesLink" to={appRoute.myCompanies.path}>
                                <Locale stringKey="CATEGORY_NAME__MY_COMPANIES" />
                            </NavigationLink>
                        ),
                    }}
                />
            </PageCard>
        </>
    );
}
