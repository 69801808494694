export const enOnboardingTranslations = {
    ONBOARDING__TITLE: 'Welcome to the {companyName} dashboard 👋',
    ONBOARDING__TITLE__FINISH: 'Congratulations! Everything is working! 🎉',
    ONBOARDING__FINISH: 'Finish',
    ONBOARDING__ADD_COMPANIES: 'Add companies',
    ONBOARDING__DEMO__BUTTON: 'Demo',
    ONBOARDING__DESCRIPTION:
        'Increase sales, attract new reviews, and improve your rating on maps with {companyName}. \nTo get started, you need to take a few simple steps.',
    ONBOARDING__CREATE_COMPANY_POPUP__TITLE: 'Select method for adding companies',
    ONBOARDING__STEP_COUNT: 'Completed {count} out of 4 steps',
    ONBOARDING__CREATE_COMPANY_POPUP__IMPORT__TITLE: 'Import file or feed',
    ONBOARDING__CREATE_COMPANY_POPUP__IMPORT__DESCRIPTION:
        'Select if you have a file or feed in JSON, CSV, XLSX, XML format.',
    ONBOARDING__CREATE_COMPANY_POPUP__SITE_IMPORT__TITLE: 'Gather and import data from your website',
    ONBOARDING__FIRST_STEP__TITLE: 'Adding companies',
    ONBOARDING__FIRST_STEP__DESCRIPTION:
        'Add companies using one of the available and convenient methods for you: manually, by gathering data from your website, through an Excel file, or via a json/xml feed.',
    ONBOARDING__CREATE_COMPANY_POPUP__SITE_IMPORT__DESCRIPTION:
        'Select if all information about locations on your website is up-to-date.',
    ONBOARDING__CREATE_COMPANY_POPUP__IMPORT__TAG: 'Recommended',
    ONBOARDING__CREATE_COMPANY_POPUP__ADD_COMPANY__TITLE: 'Adding companies in manual mode',
    ONBOARDING__CREATE_COMPANY__IN_PROGRESS__DESCRIPTION:
        'The process of adding and moderating companies has been initiated. It may take some time depending on the number of companies. We will notify you when the process is complete.',
    ONBOARDING__CREATE_COMPANY_POPUP__ADD_COMPANY__DESCRIPTION: 'Select if the above methods are not suitable for you.',
    ONBOARDING__IMPORT_MODAL__TITLE: 'Gather and import data from your website',
    ONBOARDING__IMPORT_MODAL__DESCRIPTION:
        'Please fill in the fields below so we can collect, organize, and import data into your {companyName} dashboard.',
    ONBOARDING__IMPORT_MODAL__LINK__LABEL: 'Link to the page with the list of locations',
    ONBOARDING__IMPORT_MODAL__BRAND__LABEL: 'Brand names (comma-separated)',
    ONBOARDING__IMPORT_MODAL__UPDATE__LABEL: 'Update frequency',
    ONBOARDING__IMPORT_MODAL__AGREE__CHECKBOX:
        'I confirm that the location information on the website is up-to-date for data collection and import into the {companyName} account.',
    ONBOARDING__SECOND_STEP__TITLE: 'Account connection',
    ONBOARDING__SECOND_STEP__GOOGLE__DESCRIPTION:
        'Connect your Google My Business account to the {companyName} platform to manage business promotion on Google platforms.',
    ONBOARDING__SECOND_STEP__DOUBLEGIS__DESCRIPTION:
        'Provide contacts for integrating the 2GIS profile into the {companyName} platform.',
    ONBOARDING__SECOND_STEP__FACEBOOK__DESCRIPTION:
        'Allow access to your Facebook business account to link location cards and make posts from the {companyName} dashboard, receive statistics for each post, and simplify communication with users.',
    ONBOARDING__ACCOUNT_CONNECT_BUTTON: 'Connect Account',
    ONBOARDING__ACCOUNT_CONNECT__DOUBLEGIS__TITLE: 'Providing Contact Information',
    ONBOARDING__ACCOUNT_CONNECT__DOUBLEGIS__DESCRIPTION:
        'Please fill in the fields below to integrate the 2GIS profile into the {companyName} platform.',
    ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__BRANDS: 'Brand Name',
    ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__NAME: 'Contact Person Name',
    ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__NAME__PLACEHOLDER: 'Last Name First Name Middle Name',
    ONBOARDING__ACCOUNT_CONNECT__FORM_FIELD__PHONE__TOOLTIP:
        'Phone number must be provided in the international format.',
    ONBOARDING__THIRD_STEP__TITLE: 'Company Linking',
    ONBOARDING__THIRD_STEP__BUTTON: 'Do it for me',
    ONBOARDING__THIRD_STEP__GOOGLE__DESCRIPTION: 'Link companies to manage their promotion on Google platforms.',
    ONBOARDING__THIRD_STEP__YANDEX__DESCRIPTION: 'Link companies to promote business in Yandex geoservices.',
    ONBOARDING__THIRD_STEP__FACEBOOK__DESCRIPTION:
        'Link location cards to make posts from the {companyName} dashboard.',
    ONBOARDING__THIRD_STEP__CATALOGS_BUTTON: 'Link Companies',
    ONBOARDING__THIRD_STEP__IN_PROGRESS:
        'The request to link companies in Google and Facebook* has been sent to the manager. Processing the request may take approximately 3 business days.',
    ONBOARDING__FOURTH_STEP__TITLE: 'Company Confirmation',
    ONBOARDING__FOURTH_STEP__CATALOGS__NEED_ACTION__DESCRIPTION:
        'To confirm that you are the owner of the location, you must request and enter the code received from the source service.',
    ONBOARDING__FOURTH_STEP__CATALOGS__SUCCESS__DESCRIPTION:
        'You can confirm companies either individually or by sending a mass confirmation request.',
    ONBOARDING__FOURTH_STEP__YANDEX__DESCRIPTION:
        'You can confirm locations by providing access to manage the network or company via email to {companyName} or by entering a code (phone or SMS).',
    ONBOARDING__FOURTH_STEP__GOOGLE__IN_PROGRESS__DESCRIPTION:
        "We are already working on the bulk confirmation of locations. We will contact you if necessary. Processing the request on Google's side may take up to 2 months.",
    ONBOARDING__FOURTH_STEP__YANDEX__IN_PROGRESS__DESCRIPTION:
        "We are already working on confirming the network of companies. We will contact you if necessary. Processing the request on Yandex's side may take up to 1 month.",

    ONBOARDING__FOURTH_STEP__SUCCESS_BUTTON: 'Confirm companies',
    ONBOARDING__STEP_STATUS__PENDING: 'In progress',
    ONBOARDING__STEP_STATUS__NEED_ACTION: 'Action required',
    ONBOARDING__STEP_STATUS__CONFIRMED: 'Confirmed',
    ONBOARDING__AUTO_SYNC_MODAL__TITLE: 'Company Linking Request',
    ONBOARDING__AUTO_SYNC_MODAL__DESCRIPTION:
        'A request to link companies in Google and Facebook will be sent to the manager for processing.',
    ONBOARDING__AUTO_SYNC_MODAL__CONFIRM: 'Confirm sending the request?',
    ONBOARDING__WHAT_NEXT: 'What next?',
    ONBOARDING__SNACKBAR__APPLICATION_SENT: 'Application sent',
    ONBOARDING__SNACKBAR__APPLICATION_SENT__DESCRIPTION:
        'The application for linking companies has been sent to the manager.',

    ONBOARDING__RECOMMENDATION__AUTO_REVIEW_TEMPLATE__TITLE: 'Create response templates for reviews',
    ONBOARDING__RECOMMENDATION__TAGS_FOR_REVIEW__TITLE: 'Create tags for reviews',
    ONBOARDING__RECOMMENDATION__SETTING_SOURCES__TITLE: 'Set up review collection from platforms',
    ONBOARDING__RECOMMENDATION__PRODUCTS_AND_PRICES__TITLE: 'Publish information about services, products, or menu',
    ONBOARDING__RECOMMENDATION__ONLINE_PRESENCE__TITLE: 'Manage your company’s presence on maps and geoservices',
    ONBOARDING__RECOMMENDATION__REVIEWS_ANALYTICS__TITLE: 'Analyze your business’s online reputation',
    ONBOARDING__RECOMMENDATION__POSITION_TRACKER__TITLE: 'Take advantage of the local position tracker',
    ONBOARDING__RECOMMENDATION__PHOTO_AND_VIDEO_MANAGER__TITLE: 'Manage photos in Google, Yandex, and 2GIS',
    ONBOARDING__RECOMMENDATION__PUBLICATIONS__TITLE: 'Create a post on Google, Yandex geoservices, and social networks',
    ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__TITLE: 'Generate reviews',
    ONBOARDING__RECOMMENDATION__REPLIES_TO_REVIEWS__TITLE: 'Manage your reviews and reputation',
    ONBOARDING__RECOMMENDATION__NEURAL_NETWORK__TITLE: 'Use neural network',

    ONBOARDING__RECOMMENDATION__AUTO_REVIEW_TEMPLATE__DESCRIPTION:
        'Pre-create response templates that allow you to respond to reviews in one click, adapting them for different scenarios and review types.',
    ONBOARDING__RECOMMENDATION__TAGS_FOR_REVIEW__DESCRIPTION:
        'Create tags to categorize and group reviews. You can also quickly filter reviews by tags.',
    ONBOARDING__RECOMMENDATION__SETTING_SOURCES__DESCRIPTION:
        'Set up review collection from various services, so we automatically gather customer feedback from over 80 sources. You can track and respond to reviews directly from your dashboard.',
    ONBOARDING__RECOMMENDATION__PRODUCTS_AND_PRICES__DESCRIPTION:
        'Upload prices, publish information about services, products, or menus directly on Yandex and Google. This way, you can create a more attractive company profile and increase conversion rates.',
    ONBOARDING__RECOMMENDATION__ONLINE_PRESENCE__DESCRIPTION:
        'Analyze your company’s presence and key metrics. This helps identify where information on business listings needs editing or adding.',
    ONBOARDING__RECOMMENDATION__REVIEWS_ANALYTICS__DESCRIPTION:
        'Analyze the content, tone, and rating of your reviews. Identify growth areas and improve your brand reputation.',
    ONBOARDING__RECOMMENDATION__POSITION_TRACKER__DESCRIPTION:
        'Set up rules to track your company’s position in local search results for target queries. This will help you find growth opportunities and improve your visibility.',
    ONBOARDING__RECOMMENDATION__PHOTO_AND_VIDEO_MANAGER__DESCRIPTION:
        'Gather photos from all locations in a unified dashboard, and manage any unattractive or irrelevant content.',
    ONBOARDING__RECOMMENDATION__PUBLICATIONS__DESCRIPTION:
        'Create and post updates across available platforms from the RocketData dashboard to attract more customers to your locations.',
    ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__DESCRIPTION:
        'Generate reviews about your company by sending messages to clients via SMS, Viber, WhatsApp, and other communication channels, asking them to leave feedback on directories or other sources.',
    ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR_QR__DESCRIPTION:
        'Create QR codes that can be placed at retail locations or on advertising materials, asking customers to leave a review of the goods and services at a specific location.',
    ONBOARDING__RECOMMENDATION__REPLIES_TO_REVIEWS__DESCRIPTION:
        'Respond to customer reviews from a single dashboard. The better you manage reviews, the higher your business ranks and the wider its target audience reach.',
    ONBOARDING__RECOMMENDATION__NEURAL_NETWORK__DESCRIPTION:
        'Quickly and personally respond to customer reviews using AI-powered response generation.',

    ONBOARDING__RECOMMENDATION__AUTO_REVIEW_TEMPLATE__BUTTON: 'Create template',
    ONBOARDING__RECOMMENDATION__TAGS_FOR_REVIEW__BUTTON: 'Create tag',
    ONBOARDING__RECOMMENDATION__SETTING_SOURCES__BUTTON: 'Set up sources',
    ONBOARDING__RECOMMENDATION__GO_TO_SERVICE__BUTTON: 'Go to service',
    ONBOARDING__RECOMMENDATION__POSITION_TRACKER__BUTTON: 'Create rule',
    ONBOARDING__RECOMMENDATION__PUBLICATIONS__BUTTON: 'Create post',
    ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR__BUTTON: 'Create mailing',
    ONBOARDING__RECOMMENDATION__REVIEWS_GENERATOR_QR__BUTTON: 'Create QR set',
    ONBOARDING__RECOMMENDATION__INFO__BUTTON: 'What is this product for',

    ONBOARDING__RECOMMENDATION__TAG__IS_AVAILABLE: 'Now available',
    ONBOARDING__RECOMMENDATION__TAG__IS_TRIAL: 'Trial available',
    ONBOARDING__RECOMMENDATION__TAG__IS_AVAILABLE_FOR: 'Available for {catalog}',
    ONBOARDING__RECOMMENDATION__TAG__AND_TEXT: 'and',
    ONBOARDING__FACEBOOK_FOOTNOTE:
        '*The social network Facebook is banned in the Russian Federation; it is owned by Meta Corporation, which is recognized as extremist in the Russian Federation.',
};
