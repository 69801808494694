import {faChartSimple} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {ItemType} from 'antd/es/menu/interface';
import {useContext} from 'react';

import {AsciiSpinner} from '../../../layout/spinner/ascii-spinner';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {useCompanies} from '../../../service/company/company-hook';

import {buildReportLink} from './double-gis-report-menu-item-helper';
import * as styles from './double-gis-report-menu-item.scss';

type OptionsType = {
    skip?: boolean;
};

export function useDoubleGisReportMenuItem(options: OptionsType = {}): ItemType {
    const {skip} = options;

    const {localeName} = useLocale();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {isFetching: isInProgress, data: result} = useCompanies({
        skip,
        mainFilterKey,
    });

    const commonItem = {
        key: 'double-gis-report',
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        className: styles.double_gis__report_menu__expanded,
    };

    if (isInProgress) {
        return {
            ...commonItem,
            label: (
                <div>
                    <Locale stringKey="CATEGORY_NAME__2_GIS_REPORT" />

                    <AsciiSpinner className={styles.loading} />
                </div>
            ),
        };
    }

    if (result?.count !== 1 && result?.results.length !== 1) {
        return {
            ...commonItem,
            disabled: true,
            label: (
                <Tooltip title={<Locale stringKey="DOUBLE_GIS_REPORT__DISABLE_TOOLTIP" />}>
                    <span>
                        <Locale stringKey="CATEGORY_NAME__2_GIS_REPORT" />
                    </span>
                </Tooltip>
            ),
        };
    }

    const link = buildReportLink(result.results, localeName);

    return {
        ...commonItem,
        label: (
            <a href={link} rel="noreferrer" target="_blank">
                <Locale stringKey="CATEGORY_NAME__2_GIS_REPORT" />
            </a>
        ),
    };
}
