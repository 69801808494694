import {Select} from 'antd';
import {useEffect} from 'react';

import {BrandType} from '../../../../../provider/brands/brand-type';
import {IdNumberType} from '../../../../../util/type';

type PropsType = {
    value?: IdNumberType | null;
    onChange?: (newValue: IdNumberType | null) => void;
    availableBrands?: Array<BrandType> | null;
    loading: boolean;
    placeholder?: string;
    singleItemAutoselect: boolean;
    disabled?: boolean;
};

export function SelectBrandField(props: PropsType): JSX.Element {
    const {value, onChange, availableBrands, loading, placeholder, singleItemAutoselect, disabled} = props;

    useEffect(() => {
        if (singleItemAutoselect && availableBrands?.length === 1 && availableBrands[0] && !value) {
            onChange?.({
                id: availableBrands[0].id,
            });
        }
    }, [availableBrands, onChange, singleItemAutoselect, value]);

    return (
        <Select
            disabled={disabled}
            loading={loading}
            onChange={(selectedBrandId: number) => onChange?.({id: selectedBrandId})}
            options={availableBrands?.map((brand) => ({label: brand.name, value: brand.id} || []))}
            placeholder={placeholder}
            value={value?.id}
        />
    );
}
