import {Locale} from '../../../../provider/locale/locale';
import {FieldModeEnum} from '../../../../service/company/company-type';

import {PopoverTextType} from './field-mode-tag-type';

export const TAG_TEXT_BY_MODE: Record<FieldModeEnum, JSX.Element> = {
    [FieldModeEnum.addition]: <Locale stringKey="BULK_EDIT_COMPANIES__FIELD_MODE__ADDITION" />,
    [FieldModeEnum.replacement]: <Locale stringKey="BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT" />,
};

export const POPOVER_TEXT_BY_MODE: Record<FieldModeEnum, PopoverTextType> = {
    [FieldModeEnum.addition]: {
        title: 'BULK_EDIT_COMPANIES__FIELD_MODE__ADDITION_HEADER',
        content: 'BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADDING_ALLOWED_DESCRIPTION',
    },
    [FieldModeEnum.replacement]: {
        title: 'BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT_HEADER',
        content: 'BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT_DESCRIPTION',
    },
};
