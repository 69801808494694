import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {fetchAndDeserialize} from '../../util/api-adapter';
import {sortCompare} from '../../util/string';
import {objectToUrlParameters} from '../../util/url';
import {generateResponseSchema} from '../api/api-type';

const countrySchema = r.object({
    id: r.number(),
    name: r.string(),
    translations: r.record(r.string()),
});

type CountryType = r.infer<typeof countrySchema>;

const countriesSchema = generateResponseSchema(countrySchema);

type CountriesType = r.infer<typeof countriesSchema>;

const countriesUrl = `/company/modules/details/country/?${objectToUrlParameters({count: 500})}`;

function fetchCountries(): Promise<CountriesType> {
    return fetchAndDeserialize(countriesUrl, countriesSchema);
}

export function getCountryTranslation(country: CountryType, shortLocaleName: ShortLocaleNameEnum): string {
    return country.translations[shortLocaleName.toLowerCase()] || country.name;
}

export function useSortedCountries(): UseQueryResult<CountriesType> {
    const {shortLocaleName} = useLocale();

    return useQuery([countriesUrl], () => fetchCountries(), {
        select: (countries) => ({
            ...countries,
            results: countries.results.sort((first, second) =>
                sortCompare(
                    shortLocaleName,
                    getCountryTranslation(first, shortLocaleName),
                    getCountryTranslation(second, shortLocaleName)
                )
            ),
        }),
    });
}
