import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {theme} from 'antd';

import {Text} from '../../../../../component/text/text';
import {LangKeyType} from '../../../../../provider/locale/translation/type';

import * as styles from './tariffs-link-wrapper.scss';

type PropsType = {
    link: string;
    title: LangKeyType;
};

export function TariffsLinkWrapper(props: PropsType): JSX.Element {
    const {link, title} = props;
    const {token} = theme.useToken();

    return (
        <a className={styles.TariffsLinkWrapper} href={link} rel="noreferrer" target="_blank">
            <Text bolder color={token.colorPrimary} inheritColor stringKey={title} />
            <FontAwesomeIcon height={16} icon={faArrowUpRightFromSquare} width={16} />
        </a>
    );
}
