import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

export const companyLegalSchema = {
    [CompanyKeyEnum.Legal]: r.object({
        name: r.string().nullable(),
        formId: r.string().nullable(),
        formName: r.string().nullable(),
    }),
};

const companyLegalSchemaObject = r.object(companyLegalSchema);

export type CompanyLegalType = r.infer<typeof companyLegalSchemaObject>;

export type CompanyLegalFormType = {
    [CompanyKeyEnum.Legal]: {
        name: string | null;
        form: {
            value: string | null;
            label: string | null;
        };
    };
};

export type CompanyLegalServerType = {
    legal: Omit<CompanyLegalType['legal'], 'formName'>;
};
