import {faUpload} from '@fortawesome/pro-regular-svg-icons';
import {faImages} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, FormInstance} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {useEffect, useState} from 'react';

import {Spinner} from '../../../../../../layout/spinner/spinner';
import {LocalePlural} from '../../../../../../provider/locale/locale-plural';
import {CustomFileType, UploadFileResponseType} from '../../../../../../service/file-upload/file-upload-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {useUploadStoryImage} from '../../../../../../service/yandex-stories/yandex-stories-hook';
import {
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
} from '../../../../../../service/yandex-stories/yandex-stories-type';
import {noop} from '../../../../../../util/function';
import {FileFormInput} from '../../../../../form/file-upload-item/file-form-input';
import {Text} from '../../../../../text/text';
import {
    YANDEX_STORY__MAX_SLIDES,
    YANDEX_STORY_MAX_IMAGE_HEIGHT,
    YANDEX_STORY_MAX_IMAGE_WIDTH,
    YANDEX_STORY_MIN_IMAGE_HEIGHT,
    YANDEX_STORY_MIN_IMAGE_WIDTH,
} from '../../../yandex-story-form-helper';
import {YandexStoryFormStep} from '../../step/yandex-story-form-step';

import * as styles from './yandex-story-upload-images-step.scss';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    onPreviousStep: VoidFunction;
    isFirstStep: boolean;
};

export function YandexStoryUploadImagesStep(props: PropsType): JSX.Element {
    const {formInstance, onPreviousStep, isFirstStep} = props;

    const {mutateAsync: uploadFile} = useUploadStoryImage();
    const {validateImageDimension} = useFormRules();

    const [filesMap, setFilesMap] = useState<Record<string, CustomFileType & UploadFileResponseType>>({});

    const isFileMapEmpty = Object.keys(filesMap).length === 0;

    useEffect(() => {
        if (!isFileMapEmpty && Object.values(filesMap).every((fileItem) => fileItem.status !== 'uploading')) {
            formInstance.setFieldValue(
                [YandexStoryFormFieldsEnum.Slides],
                Object.fromEntries(
                    Object.entries(filesMap)
                        .slice(0, YANDEX_STORY__MAX_SLIDES)
                        .map(([key, fileItem], index) => [
                            key,
                            {
                                ...fileItem,
                                buttonConfig: null,
                                order: index + 1,
                                name: '',
                            },
                        ])
                )
            );
        }
    }, [filesMap, formInstance, isFileMapEmpty]);

    return (
        <YandexStoryFormStep
            leftFooterButtonConfig={
                isFileMapEmpty
                    ? {onClick: onPreviousStep, langKeyType: isFirstStep ? 'BUTTON__CANCEL' : 'BUTTON__BACK'}
                    : // eslint-disable-next-line no-undefined
                      undefined
            }
        >
            <div className={styles.YandexStoryUploadImagesStep}>
                {isFileMapEmpty && (
                    <div className={styles.YandexStoryUploadImagesStep_description}>
                        <div>
                            <Text block bolder stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_1" />
                            <Text block stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_2" />
                            <Text
                                block
                                stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_3"
                                valueMap={{
                                    minWidth: YANDEX_STORY_MIN_IMAGE_WIDTH,
                                    minHeight: YANDEX_STORY_MIN_IMAGE_HEIGHT,
                                    maxWidth: YANDEX_STORY_MAX_IMAGE_WIDTH,
                                    maxHeight: YANDEX_STORY_MAX_IMAGE_HEIGHT,
                                }}
                            />
                            <Text block stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_4" />
                        </div>
                        <FontAwesomeIcon
                            className={styles.YandexStoryUploadImagesStep_descriptionIcon}
                            icon={faImages}
                        />
                    </div>
                )}

                {!isFileMapEmpty && (
                    <div className={styles.YandexStoryUploadImagesStep_spinnerWrapper}>
                        <Spinner />
                        <Text>
                            <LocalePlural
                                count={Object.keys(filesMap).length}
                                manyKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__LOADING_IMAGES_MANY"
                                singularKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__LOADING_IMAGES_SINGLE"
                                valueMap={{
                                    count: Object.keys(filesMap).length,
                                }}
                            />
                        </Text>
                    </div>
                )}

                <FileFormInput
                    onChange={setFilesMap}
                    remove={noop}
                    showCustomUploadList={false}
                    upload={uploadFile}
                    uploadProps={{accept: '.png,.jpg', maxFileSize: 10, maxCount: YANDEX_STORY__MAX_SLIDES}}
                    validationRules={[
                        async (file: RcFile) =>
                            validateImageDimension(file, {
                                min: {
                                    height: YANDEX_STORY_MIN_IMAGE_HEIGHT,
                                    width: YANDEX_STORY_MIN_IMAGE_WIDTH,
                                },
                                max: {
                                    height: YANDEX_STORY_MAX_IMAGE_HEIGHT,
                                    width: YANDEX_STORY_MAX_IMAGE_WIDTH,
                                },
                            }),
                    ]}
                    value={filesMap}
                >
                    {isFileMapEmpty && (
                        <Button className={styles.YandexStoryUploadImagesStep_uploadArea}>
                            <FontAwesomeIcon
                                className={styles.YandexStoryUploadImagesStep_uploadAreaIcon}
                                icon={faUpload}
                            />
                            <Text block large stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__UPLOADER_TEXT" />
                            <Text block secondary stringKey="YANDEX_STORY_FORM__UPLOAD_IMAGES__UPLOADER_MAX_IMAGES" />
                        </Button>
                    )}
                </FileFormInput>
            </div>
        </YandexStoryFormStep>
    );
}
