import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../../../../../../util/api-adapter';

const userAvailableBrandsSchema = r.array(
    r.object({
        id: r.number(),
        name: r.string(),
        isAvailable: r.boolean().optional(),
    })
);

type UserAvailableBrandsType = r.infer<typeof userAvailableBrandsSchema>;

export function useUserAvailableBrands(editUserId?: number): UseQueryResult<UserAvailableBrandsType> {
    const userAvailableBrandsUrl = editUserId
        ? `/cp/user_management/edit_users/${editUserId}/brands`
        : '/cp/user_management/brands';

    return useQuery(['available-user-brand', editUserId], () =>
        fetchAndDeserialize(userAvailableBrandsUrl, userAvailableBrandsSchema)
    );
}
