import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {StatusBadge} from '../../../../../component/status-badge/status-badge';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {ReviewLogUserDataType} from '../../../../../service/reviews/reviews-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './status-log.scss';

type PropsType = {
    loggedAt: string;
    oldStatus: string;
    newStatus: string;
    user: ReviewLogUserDataType;
};

export function StatusLog(props: PropsType): JSX.Element | null {
    const {loggedAt, oldStatus, newStatus, user} = props;

    const {companyName} = useDomainConfig();
    const {getFormattedDateTime} = useFormat();

    const formattedTime = loggedAt
        ? getFormattedDateTime(new Date(loggedAt), {
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;
    const formattedDate = loggedAt
        ? getFormattedDateTime(new Date(loggedAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          })
        : null;

    const userName = (() => {
        if (user) {
            const {firstName, lastName} = user;

            if (firstName || lastName) {
                return `${firstName || ''} ${lastName || ''}`;
            }
        }

        return companyName;
    })();

    return (
        <div className={styles.StatusLog}>
            <div className={styles.StatusLog_data}>
                <span className={styles.StatusLog_user}>{userName}</span>
                <div className={styles.StatusLog_statuses}>
                    <StatusBadge status={oldStatus} />
                    <FontAwesomeIcon className={styles.StatusLog_icon} icon={faArrowRight} />
                    <StatusBadge status={newStatus} />
                </div>
            </div>
            <span className={styles.StatusLog_date}>
                {formattedTime} {formattedDate}
            </span>
        </div>
    );
}
