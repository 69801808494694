import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {FeedsTableStatusEnum} from '../../page/main/my-companies/feed/management/table/feeds-table-type';
import {fetchAndDeserialize} from '../../util/api-adapter';

import {companiesImportListUrl} from './companies-import-list';

const companiesImportStatusSchema = r.array(r.nativeEnum(FeedsTableStatusEnum));

type CompaniesImportStatusType = r.infer<typeof companiesImportStatusSchema>;

function getFeedStatusQueryUrl() {
    return `${companiesImportListUrl}cp_statuses/`;
}

function fetchFeedStatuses() {
    return fetchAndDeserialize(getFeedStatusQueryUrl(), companiesImportStatusSchema);
}

export function useFeedStatus(): UseQueryResult<CompaniesImportStatusType> {
    const queryKey = getFeedStatusQueryUrl();

    return useQuery([queryKey], fetchFeedStatuses);
}
