import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {
    averageResponseTimeByCatalogItemSchema,
    AverageResponseTimeByCatalogType,
} from './average-response-time-by-catalog-type';

export function fetchAverageResponseTimeByCatalog(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<Array<AverageResponseTimeByCatalogType>> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/catalogs/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        averageResponseTimeByCatalogItemSchema.array()
    );
}
