import {Button, Descriptions, Drawer, List, Space} from 'antd';
import React from 'react';

import {Text} from '../../../../../../component/text/text';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../provider/locale/locale';
import {useFeedInfo} from '../../../../../../service/companies-import/companies-import-list';
import {useChangeFeedAutoUpdateMutation} from '../../../../../../service/companies-import/companies-import-list-edit';
import {
    useDeleteFeedMutation,
    useFeedImportInfo,
} from '../../../../../../service/companies-import/companies-import-result-import';
import {useDrawerWidthHack} from '../../../../../../util/antd/drawer-hook';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {FeedResultsTypeEnum} from '../../common/results/feed-results-const';
import {FeedResultsDescriptions} from '../../common/results/summary/source/descritpions/feed-results-descriptions';
import {FeedResultsSource} from '../../common/results/summary/source/feed-results-source';
import {LIMITED_NUMBER_OF_BRANDS} from '../table/columns/brands/feed-brands-column-const';

import {FEED_DRAWER_WIDTH} from './feed-drawer-const';
import * as styles from './feed-drawer.scss';

type PropsType = {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    importUrlId: number;
    listRefetch: () => void;
};

// eslint-disable-next-line complexity
export function FeedDrawer(props: PropsType): React.JSX.Element {
    const {isOpen, setIsOpen, importUrlId, listRefetch} = props;
    const {data: feedInfo, refetch} = useFeedInfo(importUrlId);

    const {mutate, isLoading} = useChangeFeedAutoUpdateMutation(() => {
        refetch();
        listRefetch();
    });

    const {mutate: deleteFeed} = useDeleteFeedMutation(listRefetch, setIsOpen);

    const {data} = useFeedImportInfo(importUrlId);
    const {getFormattedDateTime} = useFormat();
    const updatedAtDate = feedInfo?.updateDate ? new Date(feedInfo.updateDate) : null;

    const drawerWidth: string = useDrawerWidthHack(FEED_DRAWER_WIDTH);

    return (
        <Drawer
            extra={[
                <Space key="drawer">
                    <Button onClick={() => deleteFeed(importUrlId)}>
                        <Locale stringKey="FEED__MANAGEMENT__DRAWER__BUTTON__DELETE_FEED" />
                    </Button>
                </Space>,
            ]}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            placement="right"
            title={<Locale stringKey="FEED__MANAGEMENT__DRAWER__FEED_INFO" />}
            width={drawerWidth}
        >
            {(!feedInfo || isLoading) && <Spinner />}

            {feedInfo && (
                <FeedResultsSource
                    changeAutoUpdate={mutate}
                    id={feedInfo.id}
                    periodicUpdate={feedInfo.periodicUpdate}
                    source={{
                        link: feedInfo.link,
                        importType: feedInfo.importType,
                    }}
                    status={feedInfo.cpStatus}
                />
            )}
            <Space className={styles.Descriptions} direction="vertical" size="small">
                <Text
                    bolder
                    className={styles.Descriptions_lastImportTitle}
                    large
                    secondary
                    stringKey="FEED__MANAGEMENT__DRAWER__TABLE_LABEL__LAST_IMPORT"
                />
                <Descriptions bordered className={styles.Descriptions_wrapper} column={1} size="middle">
                    {updatedAtDate && !Number.isNaN(updatedAtDate.getTime()) && (
                        <Descriptions.Item label={<Locale stringKey="FEED__MANAGEMENT__DRAWER__TABLE_LABEL__DATE" />}>
                            <Text block secondary>
                                {getFormattedDateTime(updatedAtDate, {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: 'numeric',
                                })}
                            </Text>
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item
                        label={<Locale stringKey="FEED__MANAGEMENT__DRAWER__TABLE_LABEL__COMPANIES_IMPORT_COUNT" />}
                    >
                        {(data?.importResultInfo.all ?? 0) - (data?.importResultInfo.criticalError ?? 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Locale stringKey="FEED__IMPORT__CHANGES__NEW" />}>
                        {data?.importResultInfo.newCompanies}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<Locale stringKey="FEED__MANAGEMENT__DRAWER__TABLE_LABEL__COMPANIES_UPDATED_COUNT" />}
                    >
                        {data?.importResultInfo.updatedCompanies}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={<Locale stringKey="FEED__MANAGEMENT__DRAWER__TABLE_LABEL__COMPANIES_NO_CHANGED_COUNT" />}
                    >
                        {data?.importResultInfo.companiesUnchanged}
                    </Descriptions.Item>
                </Descriptions>

                {feedInfo && Object.values(feedInfo.importResultInfo).length > 0 && (
                    <FeedResultsDescriptions
                        companiesCount={{
                            withoutErrors: feedInfo.importResultInfo.success ?? 0,
                            withErrors:
                                (feedInfo.importResultInfo.validationError ?? 0) -
                                    (feedInfo.importResultInfo.criticalError ?? 0) >
                                0
                                    ? (feedInfo.importResultInfo.validationError ?? 0) -
                                      (feedInfo.importResultInfo.criticalError ?? 0)
                                    : feedInfo.importResultInfo.validationError ?? 0,
                            criticalErrors: feedInfo.importResultInfo.criticalError ?? 0,
                            duplicates: feedInfo.importResultInfo.sameData ?? 0,
                        }}
                        importUrlId={importUrlId}
                        type={FeedResultsTypeEnum.Import}
                    />
                )}
                {feedInfo?.brands && (
                    <List
                        bordered
                        className={styles.Descriptions_list}
                        dataSource={feedInfo.brands.slice(0, LIMITED_NUMBER_OF_BRANDS)}
                        footer={
                            feedInfo.brands.length > LIMITED_NUMBER_OF_BRANDS ? (
                                <Locale
                                    stringKey="USERS__TABLE__BRANDS__MORE"
                                    valueMap={{count: feedInfo.brands.length - LIMITED_NUMBER_OF_BRANDS}}
                                />
                            ) : null
                        }
                        header={
                            <Text
                                stringKey="FEED__MANAGEMENT__DRAWER__TABLE__BRANDS_COUNT"
                                valueMap={{count: feedInfo.brands.length}}
                            />
                        }
                        renderItem={(item) => <List.Item key={item.id}>{item.name}</List.Item>}
                    />
                )}
            </Space>
        </Drawer>
    );
}
