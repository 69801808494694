/* eslint-disable sonarjs/no-duplicate-string */
import {Button, DatePicker, Form, Select} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {isEqual, isNil} from 'lodash';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';
import {useContext, useEffect, useMemo, useRef} from 'react';

import {FormActiveMark} from '../../../../../component/form-active-mark/form-active-mark';
import {FormItemRadio} from '../../../../../component/form-item-radio/form-item-radio';
import {SelectInfiniteScroll} from '../../../../../component/select-infinite-scroll/select-infinite-scroll';
import {SelectWithCheckboxes} from '../../../../../component/select-with-checkboxes/select-with-checkboxes';
import {selectRatingContent} from '../../../../../layout/select/select-helper';
import {useBrands} from '../../../../../provider/brands/brand-hook';
import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {ReviewDoctorType, useReviewsDoctorList} from '../../../../../service/reviews/reviews-doctors';
import {ReviewReplierType, useReviewsReplierList} from '../../../../../service/reviews/reviews-repliers';
import {ResponsibleUserType, useResponsibleUsers} from '../../../../../service/reviews/reviews-responsible-users';
import {ReviewStatusType, useReviewStatusList} from '../../../../../service/reviews/reviews-statuses';
import {useInfiniteTags} from '../../../../../service/reviews/reviews-tags';
import {handleFilterOption} from '../../../../../util/antd/select-helper';
import {classNames} from '../../../../../util/css';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {defaultReviewsState} from '../../reviews-state/reviews-state-const';
import {reviewsFilterStateToUrlObject} from '../../reviews-state/reviews-state-helper';
import {useReviewStateHook} from '../../reviews-state/reviews-state-provider';
import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from '../../reviews-state/reviews-state-type';

import {ReviewTagsTreeSelect} from './review-tree-select/review-tags-tree-select';
import {
    BIG_BRAND_IDS,
    CONTENT_OF_REVIEWS_OPTIONS,
    DELETED_REVIEWS_OPTIONS,
    FilterFormName,
    MODERATED_ANSWER_OPTIONS,
    POSSIBILITY_OF_AN_ANSWER_OPTIONS,
    REPLIES_TO_REVIEWS_OPTIONS,
    REVIEW_CHANGED_OPTIONS,
    REVIEW_TYPE_OPTIONS,
    reviewsFormKeyName,
    THE_COMPLAINT_WAS_LEFT_OPTIONS,
    WAITING_FOR_A_SECOND_ANSWER_OPTIONS,
    WITHOUT_TAGS_OPTIONS,
} from './reviews-filter-const';
import {getReviewsFilterDataFromForm, trackAnalytics} from './reviews-filter-helper';
import {
    AutoReplayEnum,
    ComplaintWasLeftEnum,
    ContentOfReviewsEnum,
    DeletedReviewsEnum,
    DoctorsReviewsEnum,
    EmployeesReviewsEnum,
    ModeratedEnum,
    PossibilityOfAnAnswerEnum,
    RepliesToReviewsEnum,
    ReviewChangedEnum,
    ReviewNameEnum,
    ReviewsFilterDataType,
    ReviewsFilterFieldNameEnum,
    WaitingForSecondAnswerEnum,
    WithoutTagEnum,
} from './reviews-filter-type';
import * as styles from './reviews-filter.scss';

const {Option} = Select;

// eslint-disable-next-line max-statements
export function ReviewsFilter(): JSX.Element {
    const {reviewsState, isNewLayout, reviewsCatalogListIsInProgress, reviewsCatalogList, setReviewsStateUrlQuery} =
        useReviewStateHook();

    const dateContainerRef = useRef<HTMLDivElement | null>(null);
    const {getLocalizedString, localeName} = useLocale();
    const [form] = Form.useForm();

    const hasMedicineCatalog = reviewsCatalogList?.some((catalog) => catalog.is_medicine);

    const {data: reviewsReplierList, isFetching: reviewsReplierListIsInProgress} = useReviewsReplierList();
    const {data: reviewsDoctorList, isFetching: reviewsDoctorListIsInProgress} = useReviewsDoctorList(
        Boolean(hasMedicineCatalog)
    );
    const {data: reviewStatusList, isFetching: isReviewStatusListInProgress} = useReviewStatusList(localeName);
    const {brands} = useBrands();
    const {data, isFetching: allTagsListIsInProgress, hasNextPage, fetchNextPage} = useInfiniteTags({});
    const {queries} = useUrl<Omit<ReviewsStateType, 'filterState'> & ReviewsFilterDataType>();
    const {filterState: reviewFilterState} = reviewsState;

    const tagsData = data?.pages.flatMap((element) => element.results);

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const filterKey = useMemo(() => ({filterKey: mainFilterKey}), [mainFilterKey]);
    const {data: responsibleUsersList, isFetching: isReviewResponsibleUsersInProgress} = useResponsibleUsers(filterKey);

    const {getCatalogName} = useCatalogInfo();

    const dateFromRaw = reviewFilterState[ReviewsFilterFieldNameEnum.dateFrom];
    const dateFrom = dateFromRaw ? dayjs(dateFromRaw) : null;
    const dateToRaw = reviewFilterState[ReviewsFilterFieldNameEnum.dateTo];
    const dateTo = dateToRaw ? dayjs(dateToRaw) : null;
    const today = dayjs().format('YYYY-MM-DD');
    const thirtyDaysAgo = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
    const isMagnitBrand = brands.some((brand) => BIG_BRAND_IDS.includes(brand.id));

    const defaultReviewsStateMagnitIncluding = useMemo(
        () =>
            isMagnitBrand
                ? {
                      ...defaultReviewsState.filterState,
                      [ReviewsFilterFieldNameEnum.dateFrom]: new Date(thirtyDaysAgo),
                      [ReviewsFilterFieldNameEnum.dateTo]: new Date(today),
                  }
                : defaultReviewsState.filterState,
        [isMagnitBrand, thirtyDaysAgo, today]
    );

    const isFilterEmpty = useMemo(
        () => isEqual(reviewFilterState, defaultReviewsStateMagnitIncluding),
        [defaultReviewsStateMagnitIncluding, reviewFilterState]
    );

    function onFormReset() {
        setReviewsStateUrlQuery({
            ...reviewsFilterStateToUrlObject(defaultReviewsStateMagnitIncluding),
            [ReviewsStateUrlQueryNameEnum.ids]: '',
        });
    }

    function onFieldsChange() {
        setReviewsStateUrlQuery(reviewsFilterStateToUrlObject(getReviewsFilterDataFromForm(form)));
    }

    function handleDoctorsChange(value: Array<string>) {
        const lastSelected = value[value.length - 1];

        if (lastSelected === DoctorsReviewsEnum.all) {
            form.setFieldsValue({[ReviewsFilterFieldNameEnum.doctorNames]: [DoctorsReviewsEnum.all]});
        } else {
            form.setFieldsValue({
                [ReviewsFilterFieldNameEnum.doctorNames]: value.filter((value_) => value_ !== DoctorsReviewsEnum.all),
            });
        }

        onFieldsChange();

        trackAnalytics(ReviewsFilterFieldNameEnum.doctorNames, value);
    }

    function handleSourceListChange(value: Array<number>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.sourceList]: value});
        onFieldsChange();

        const sources = reviewsCatalogList
            ? reviewsCatalogList
                  .filter((catalog) => value.includes(catalog.id))
                  .map((catalog) => ({id: catalog.id, name: getCatalogName(catalog.id)}))
            : [];

        trackAnalytics(
            ReviewsFilterFieldNameEnum.sourceList,
            sources.map((source) => getCatalogName(source.id))
        );
    }

    function onClickRatingChange(value: Array<number>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.ratingList]: value});
        onFieldsChange();

        trackAnalytics(ReviewsFilterFieldNameEnum.ratingList, value);
    }

    function onClickResponsibleEmployeeChange(value: Array<number | string>) {
        const lastSelected = value[value.length - 1];

        if (lastSelected === EmployeesReviewsEnum.all) {
            form.setFieldsValue({[ReviewsFilterFieldNameEnum.employeesResponsible]: [EmployeesReviewsEnum.all]});
        } else {
            form.setFieldsValue({
                [ReviewsFilterFieldNameEnum.employeesResponsible]: value.filter(
                    (value_) => value_ !== EmployeesReviewsEnum.all
                ),
            });
        }

        onFieldsChange();

        trackAnalytics(ReviewsFilterFieldNameEnum.employeesResponsible, value);
    }

    function onClickRespondingEmployeeChange(value: Array<number | string>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.employeesResponding]: value});
        onFieldsChange();

        trackAnalytics(ReviewsFilterFieldNameEnum.employeesResponding, value);
    }

    function handleStatusListChange(value: Array<string>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.processStatus]: value});
        trackAnalytics(ReviewsFilterFieldNameEnum.processStatus, value);
    }

    function handleTagsChange(value: Array<number>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.tagList]: value});
        trackAnalytics(ReviewsFilterFieldNameEnum.tagList, value);
    }

    function handleNewTagsChange(value: Array<number | string>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.tagList]: value});
        onFieldsChange();
        trackAnalytics(ReviewsFilterFieldNameEnum.tagList, value);
    }

    function handleWithoutTagChange(value: WithoutTagEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.withoutTag]: value});
        onFieldsChange();

        const eventPayload = {
            [WithoutTagEnum.noSelected]: 'show all',
            [WithoutTagEnum.withoutTagTrue]: 'without tags',
            [WithoutTagEnum.withoutTagFalse]: 'with tags',
            null: 'all tags',
        }[value as WithoutTagEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.withoutTag, eventPayload);
    }

    function handleAutoReplyChange(value: Array<AutoReplayEnum>) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.autoReplay]: value});

        trackAnalytics(ReviewsFilterFieldNameEnum.autoReplay, value);
    }

    function handleContentOfReviewsChange(value: ContentOfReviewsEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.contentOfReviews]: value});
        onFieldsChange();

        const type = {
            [ContentOfReviewsEnum.noSelected]: 'all selected',
            [ContentOfReviewsEnum.reviewsWithText]: 'with text',
            [ContentOfReviewsEnum.reviewsWithoutText]: 'without text',
        }[value as ContentOfReviewsEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.contentOfReviews, type);
    }

    function handleDeletedReviewsChange(value: DeletedReviewsEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.deletedReviews]: value});
        onFieldsChange();

        const type = {
            [DeletedReviewsEnum.noSelected]: 'all selected',
            [DeletedReviewsEnum.yes]: 'yes',
            [DeletedReviewsEnum.no]: 'no',
        }[value as DeletedReviewsEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.deletedReviews, type);
    }

    function onClickRepliesToReviewsChange(value: RepliesToReviewsEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.repliesToReviews]: value});
        onFieldsChange();

        const type = {
            [RepliesToReviewsEnum.noSelected]: 'all selected',
            [RepliesToReviewsEnum.ReviewsUnanswered]: 'without response',
            [RepliesToReviewsEnum.ReviewsWithAResponse]: 'with response',
        }[value as RepliesToReviewsEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.repliesToReviews, type);
    }

    function handlePossibilityOfAnswerChange(value: PossibilityOfAnAnswerEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.possibilityOfAnAnswer]: value});
        onFieldsChange();

        const type = {
            [PossibilityOfAnAnswerEnum.noSelected]: 'all selected',
            [PossibilityOfAnAnswerEnum.HasNoPossibilityOfAnAnswer]: 'has no possibility to answer',
            [PossibilityOfAnAnswerEnum.HasPossibilityOfAnAnswer]: 'has possibility to answer',
        }[value as PossibilityOfAnAnswerEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.possibilityOfAnAnswer, type);
    }

    function onClickReviewTypeChange(value: ReviewNameEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.reviewType]: value});
        onFieldsChange();

        const type = {
            [ReviewNameEnum.noSelected]: 'all selected',
            [ReviewNameEnum.ReviewsForCompanies]: 'for companies',
            [ReviewNameEnum.ReviewsForTheBrand]: 'for brand',
        }[value as ReviewNameEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.reviewType, type);
    }

    function handleComplaintWasLeftChange(value: ComplaintWasLeftEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.complaintWasLeft]: value});
        onFieldsChange();

        const type = {
            [ComplaintWasLeftEnum.yes]: 'yes',
            [ComplaintWasLeftEnum.no]: 'no',
            [ComplaintWasLeftEnum.noSelected]: 'no selected',
        }[value as ComplaintWasLeftEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.complaintWasLeft, type);
    }

    function onClickReviewChangedChange(value: ReviewChangedEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.reviewChanged]: value});
        onFieldsChange();

        const type = {
            [ReviewChangedEnum.yes]: 'yes',
            [ReviewChangedEnum.no]: 'no',
            [ReviewChangedEnum.noSelected]: 'no selected',
        }[value as ReviewChangedEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.reviewChanged, type);
    }

    function handleWaitingForSecondAnswerChange(value: WaitingForSecondAnswerEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.waitingForSecondAnswer]: value});
        onFieldsChange();

        const type = {
            [WaitingForSecondAnswerEnum.yes]: 'yes',
            [WaitingForSecondAnswerEnum.no]: 'no',
            [WaitingForSecondAnswerEnum.noSelected]: 'no selected',
        }[value as WaitingForSecondAnswerEnum];

        trackAnalytics(ReviewsFilterFieldNameEnum.waitingForSecondAnswer, type);
    }

    function handleModeratedReviewChange(value: ModeratedEnum) {
        form.setFieldsValue({[ReviewsFilterFieldNameEnum.reviewModerated]: value});
        onFieldsChange();
    }

    function handleDataRangeChange(date: NoUndefinedRangeValueType<Dayjs> | null) {
        const [from, to] = date || [null, null];

        form.setFieldsValue({
            [ReviewsFilterFieldNameEnum.dateFrom]: from,
            [ReviewsFilterFieldNameEnum.dateTo]: to,
        });

        trackAnalytics('Period', `${from} - ${to}`);
    }

    const hasDoctors = reviewsDoctorList?.results && reviewsDoctorList.results.length > 0;

    useEffect(() => {
        form.setFieldsValue(reviewFilterState);
        form.setFieldsValue({
            [ReviewsFilterFieldNameEnum.dateFrom]: reviewFilterState['date-from'],
            [ReviewsFilterFieldNameEnum.dateTo]: reviewFilterState['date-to'],
        });
    }, [form, reviewFilterState]);

    const sourcesOptions = useMemo(
        () =>
            reviewsCatalogList
                ? reviewsCatalogList.map((catalog) => ({value: catalog.id, label: catalog.name || ''}))
                : [],
        [reviewsCatalogList]
    );

    useEffect(() => {
        if (isMagnitBrand && isNil(queries['date-from']) && isNil(queries['date-to'])) {
            const todayRaw = dayjs();
            const thirtyDaysAgoRaw = dayjs().subtract(30, 'day');

            form.setFieldValue(reviewsFormKeyName.dateRange, [thirtyDaysAgoRaw, todayRaw]);
            onFieldsChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, queries, reviewFilterState]);

    return (
        <Form
            className={styles.ReviewsFilter}
            form={form}
            layout="vertical"
            name={FilterFormName}
            onReset={onFormReset}
            onValuesChange={onFieldsChange}
        >
            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.sourceList].length > 0} />
                <Form.Item
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__SOURCE" />}
                    name={ReviewsFilterFieldNameEnum.sourceList}
                >
                    <SelectWithCheckboxes<number>
                        disabled={reviewsCatalogListIsInProgress}
                        initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.sourceList]}
                        loading={reviewsCatalogListIsInProgress}
                        onConfirm={handleSourceListChange}
                        options={sourcesOptions}
                        placeholder={getLocalizedString('REVIEWS__FILTER__SELECT_A_SOURCE')}
                        selectAllTitle={<Locale stringKey="REVIEWS__FILTER__ALL__SOURCES" />}
                    />
                </Form.Item>
            </div>

            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.ratingList].length > 0} />
                <Form.Item
                    initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.ratingList]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__RATING" />}
                    name={ReviewsFilterFieldNameEnum.ratingList}
                >
                    <Select<Array<number>>
                        mode="multiple"
                        onChange={onClickRatingChange}
                        placeholder={getLocalizedString('REVIEWS__FILTER__ANY_RATING')}
                        showSearch={false}
                    >
                        {selectRatingContent}
                    </Select>
                </Form.Item>
            </div>

            <div className={styles.ReviewsFilter_selectFilter} ref={dateContainerRef}>
                <FormActiveMark isVisible={Boolean(dateFrom) && Boolean(dateTo)} />
                <Form.Item
                    initialValue={[dateFrom, dateTo]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__PERIOD" />}
                    name={reviewsFormKeyName.dateRange}
                >
                    <DatePicker.RangePicker
                        allowEmpty={[true, true]}
                        className={styles.ReviewsFilter_datepicker}
                        format="YYYY-MM-DD"
                        getPopupContainer={() => dateContainerRef.current || document.body}
                        onChange={handleDataRangeChange}
                        placeholder={[getLocalizedString('TEXT__DATE_FROM'), getLocalizedString('TEXT__DATE_TO')]}
                    />
                </Form.Item>
            </div>

            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark
                    isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.employeesResponsible].length > 0}
                />
                <Form.Item
                    initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.employeesResponsible]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__RESPONSIBLE" />}
                    name={ReviewsFilterFieldNameEnum.employeesResponsible}
                >
                    <Select<Array<string | number>>
                        disabled={isReviewResponsibleUsersInProgress}
                        filterOption={(input, option) => handleFilterOption(input, option, true)}
                        loading={isReviewResponsibleUsersInProgress}
                        mode="multiple"
                        onChange={onClickResponsibleEmployeeChange}
                        placeholder={<Locale stringKey="REVIEWS__FILTER__SELECT_AN_EMPLOYEE" />}
                    >
                        <Option value={EmployeesReviewsEnum.all}>
                            <Locale stringKey="REVIEWS__FILTER__ANY_EMPLOYEE" />
                        </Option>
                        <Option value={EmployeesReviewsEnum.noSelected}>
                            <Locale stringKey="REVIEWS__RESPONSIBLE_USER__NOT_SELECTED" />
                        </Option>
                        {responsibleUsersList?.results.map((reviewsResponsible: ResponsibleUserType): JSX.Element => {
                            const {email, fullName, id} = reviewsResponsible;

                            return (
                                <Option key={email + fullName} value={String(id)}>
                                    {email}

                                    {Boolean(fullName) && (
                                        <>
                                            <br />
                                            <span className={styles.ReviewsFilter_username}>{fullName}</span>
                                        </>
                                    )}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </div>

            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark
                    isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.employeesResponding].length > 0}
                />
                <Form.Item
                    initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.employeesResponding]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__EMPLOYEE_RESPONDING" />}
                    name={ReviewsFilterFieldNameEnum.employeesResponding}
                >
                    <Select<Array<string | number>>
                        disabled={reviewsReplierListIsInProgress}
                        filterOption={(input, option) => handleFilterOption(input, option, true)}
                        loading={reviewsReplierListIsInProgress}
                        mode="multiple"
                        onChange={onClickRespondingEmployeeChange}
                        placeholder={<Locale stringKey="REVIEWS__FILTER__SELECT_AN_EMPLOYEE" />}
                    >
                        {reviewsReplierList?.results?.map((reviewsReplier: ReviewReplierType): JSX.Element => {
                            const {email, full_name: fullName, id} = reviewsReplier;

                            return (
                                <Option key={email + fullName} value={String(id)}>
                                    {email}

                                    {Boolean(fullName) && (
                                        <>
                                            <br />
                                            <span className={styles.ReviewsFilter_username}>{fullName}</span>
                                        </>
                                    )}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </div>

            {hasDoctors && (
                <div className={styles.ReviewsFilter_selectFilter}>
                    <FormActiveMark isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.doctorNames].length > 0} />
                    <Form.Item label={<Locale stringKey="REVIEWS__FILTER__LABEL__DOCTOR_NAME" />}>
                        <Select<Array<string>>
                            disabled={reviewsDoctorListIsInProgress}
                            loading={reviewsDoctorListIsInProgress}
                            mode="multiple"
                            onChange={handleDoctorsChange}
                            placeholder={<Locale stringKey="REVIEWS__FILTER__DOCTOR_NAME" />}
                            value={reviewFilterState[ReviewsFilterFieldNameEnum.doctorNames]}
                        >
                            <Option value={DoctorsReviewsEnum.all}>
                                <Locale stringKey="REVIEWS__FILTER__ANY_DOCTOR" />
                            </Option>

                            {reviewsDoctorList?.results?.map(
                                (doctor: ReviewDoctorType): JSX.Element => (
                                    <Option key={doctor} value={doctor}>
                                        {doctor}
                                    </Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </div>
            )}

            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.processStatus].length > 0} />
                <Form.Item
                    initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.processStatus]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__STATUS" />}
                    name={ReviewsFilterFieldNameEnum.processStatus}
                >
                    <Select<Array<string>>
                        disabled={isReviewStatusListInProgress}
                        filterOption={handleFilterOption}
                        loading={isReviewStatusListInProgress}
                        mode="multiple"
                        onChange={handleStatusListChange}
                        placeholder={<Locale stringKey="REVIEWS__FILTER__SELECT_A_STATUS" />}
                    >
                        {reviewStatusList?.statuses?.map((reviewStatus: ReviewStatusType): JSX.Element => {
                            const {statusCode, title} = reviewStatus;

                            return (
                                <Option key={statusCode} value={statusCode}>
                                    {title}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </div>

            <FormItemRadio<WithoutTagEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.withoutTag]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__TAGS" />}
                name={ReviewsFilterFieldNameEnum.withoutTag}
                onClick={handleWithoutTagChange}
                options={WITHOUT_TAGS_OPTIONS}
            />

            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.tagList].length > 0} />
                <Form.Item
                    initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.tagList]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__INCLUDES_TAGS" />}
                    name={ReviewsFilterFieldNameEnum.tagList}
                >
                    {isNewLayout ? (
                        <ReviewTagsTreeSelect
                            defaultValue={reviewFilterState[ReviewsFilterFieldNameEnum.tagList]}
                            disabled={
                                reviewFilterState[ReviewsFilterFieldNameEnum.withoutTag] ===
                                WithoutTagEnum.withoutTagTrue
                            }
                            onConfirm={handleNewTagsChange}
                        />
                    ) : (
                        <SelectInfiniteScroll<Array<number>>
                            disabled={
                                reviewFilterState[ReviewsFilterFieldNameEnum.withoutTag] ===
                                WithoutTagEnum.withoutTagTrue
                            }
                            filterOption={handleFilterOption}
                            loading={allTagsListIsInProgress}
                            mode="multiple"
                            onChange={handleTagsChange}
                            onLoadMore={() => hasNextPage && fetchNextPage()}
                            placeholder={<Locale stringKey="REVIEWS__FILTER__SELECT_TAGS" />}
                        >
                            {tagsData?.map((allTag): JSX.Element => {
                                const {title, id} = allTag;

                                return (
                                    <Option key={id} value={id}>
                                        {title}
                                    </Option>
                                );
                            })}
                        </SelectInfiniteScroll>
                    )}
                </Form.Item>
            </div>

            <div className={styles.ReviewsFilter_selectFilter}>
                <FormActiveMark isVisible={reviewFilterState[ReviewsFilterFieldNameEnum.autoReplay].length > 0} />
                <Form.Item
                    initialValue={reviewFilterState[ReviewsFilterFieldNameEnum.autoReplay]}
                    label={<Locale stringKey="REVIEWS__FILTER__LABEL__AUTO_REPLIES" />}
                    name={ReviewsFilterFieldNameEnum.autoReplay}
                >
                    <Select<Array<AutoReplayEnum>>
                        mode="multiple"
                        onChange={handleAutoReplyChange}
                        placeholder={<Locale stringKey="REVIEWS__FILTER__AUTO_REPLIES" />}
                        showSearch={false}
                    >
                        <Option value={AutoReplayEnum.automaticallyReplied}>
                            <Locale stringKey="REVIEWS__FILTER__AUTOMATICALLY_REPLIED" />
                        </Option>

                        <Option value={AutoReplayEnum.suggestionsForAutoReplies}>
                            <Locale stringKey="REVIEWS__FILTER__SUGGESTIONS_FOR_AUTO_REPLIES" />
                        </Option>
                    </Select>
                </Form.Item>
            </div>

            <FormItemRadio<ContentOfReviewsEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.contentOfReviews]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__CONTENT_OF_REVIEWS" />}
                name={ReviewsFilterFieldNameEnum.contentOfReviews}
                onClick={handleContentOfReviewsChange}
                options={CONTENT_OF_REVIEWS_OPTIONS}
            />

            <FormItemRadio<DeletedReviewsEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.deletedReviews]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__DELETED_REVIEWS" />}
                name={ReviewsFilterFieldNameEnum.deletedReviews}
                onClick={handleDeletedReviewsChange}
                options={DELETED_REVIEWS_OPTIONS}
            />

            <FormItemRadio<RepliesToReviewsEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.repliesToReviews]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__REPLIES_TO_REVIEWS" />}
                name={ReviewsFilterFieldNameEnum.repliesToReviews}
                onClick={onClickRepliesToReviewsChange}
                options={REPLIES_TO_REVIEWS_OPTIONS}
            />

            <FormItemRadio<PossibilityOfAnAnswerEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.possibilityOfAnAnswer]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__POSSIBILITY_OF_AN_ANSWER" />}
                name={ReviewsFilterFieldNameEnum.possibilityOfAnAnswer}
                onClick={handlePossibilityOfAnswerChange}
                options={POSSIBILITY_OF_AN_ANSWER_OPTIONS}
            />

            <FormItemRadio<ReviewNameEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.reviewType]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__REVIEW_TYPE" />}
                name={ReviewsFilterFieldNameEnum.reviewType}
                onClick={onClickReviewTypeChange}
                options={REVIEW_TYPE_OPTIONS}
            />

            <FormItemRadio<ComplaintWasLeftEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.complaintWasLeft]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__THE_COMPLAINT_WAS_LEFT" />}
                name={ReviewsFilterFieldNameEnum.complaintWasLeft}
                onClick={handleComplaintWasLeftChange}
                options={THE_COMPLAINT_WAS_LEFT_OPTIONS}
            />

            <FormItemRadio<ReviewChangedEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.reviewChanged]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__REVIEW_CHANGED" />}
                name={ReviewsFilterFieldNameEnum.reviewChanged}
                onClick={onClickReviewChangedChange}
                options={REVIEW_CHANGED_OPTIONS}
            />

            <FormItemRadio<WaitingForSecondAnswerEnum>
                className={styles.ReviewsFilter_selectFilter}
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.waitingForSecondAnswer]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__WAITING_FOR_A_SECOND_ANSWER" />}
                name={ReviewsFilterFieldNameEnum.waitingForSecondAnswer}
                onClick={handleWaitingForSecondAnswerChange}
                options={WAITING_FOR_A_SECOND_ANSWER_OPTIONS}
            />

            <FormItemRadio<ModeratedEnum>
                fieldStateValue={reviewFilterState[ReviewsFilterFieldNameEnum.reviewModerated]}
                form={form}
                label={<Locale stringKey="REVIEWS__FILTER__LABEL__MODERATED" />}
                name={ReviewsFilterFieldNameEnum.reviewModerated}
                onClick={handleModeratedReviewChange}
                options={MODERATED_ANSWER_OPTIONS}
            />

            <div
                className={classNames(
                    styles.ReviewsFilter_footer,
                    isFilterEmpty ? styles.ReviewsFilter_footer_disabled : ''
                )}
            >
                <Button
                    className={styles.ReviewsFilter_footerButton}
                    htmlType="reset"
                    onClick={onFormReset}
                    type="link"
                >
                    <Locale stringKey="REVIEWS__FILTER__RESET" />
                </Button>
            </div>
        </Form>
    );
}
