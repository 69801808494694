import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useProductGroup} from '../../../../../service/products/products-hook';
import {ProductGroupForm} from '../../components/product-group-form/product-group-form';
import {CreateProductGroupFormFieldEnum} from '../../components/product-group-form/product-group-form-type';

export function EditProductGroupPage(): JSX.Element | null {
    const {getLocalizedString} = useLocale();

    const {id: groupId} = useParams<ExtractRouteParams<typeof appRoute.editProductsGroup.path, string>>();

    const {result, isInProgress} = useProductGroup(groupId || '');

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__PRODUCTS')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.products.path,
                        titleLangKey: 'CATEGORY_NAME__PRODUCTS',
                    },
                    {
                        path: appRoute.editProductsGroup.path,
                        titleLangKey: 'PRODUCTS__EDIT_PRODUCT_GROUP__HEADER',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="PRODUCTS__EDIT_PRODUCT_GROUP__HEADER" />
            </PageHeader>

            {isInProgress && <Spinner />}
            {result && (
                <ProductGroupForm
                    initialCompaniesCount={result.companiesCount}
                    initialValues={{
                        [CreateProductGroupFormFieldEnum.Name]: result.name,
                        [CreateProductGroupFormFieldEnum.Services]: result.catalogs.map((catalog) => catalog.id),
                        [CreateProductGroupFormFieldEnum.ServiceType]: result.serviceType,
                        [CreateProductGroupFormFieldEnum.SourceId]: result.source?.id,
                        [CreateProductGroupFormFieldEnum.FeedUrl]: result.source?.url,
                        [CreateProductGroupFormFieldEnum.GoogleCategory]: result.googleCategory,
                        [CreateProductGroupFormFieldEnum.IikoApiLogin]: result.iikoApiLogin,
                        [CreateProductGroupFormFieldEnum.IikoMenuIds]: result.iikoMenuIds.join(','),
                    }}
                    productGroupId={result.id}
                    productGroupSourceType={result.sourceType}
                />
            )}
        </Page>
    );
}
