import {header_height} from '../../../../css/var-export.scss';
import {getEnumValue} from '../../../../util/enum';
import {DATA_WIDGET_KEY_ATTR, WidgetKeysEnum} from '../company-form-const';
import {widgets_gap} from '../page-container/company-form.scss';

export function createAnchor(link: string): string {
    return '#' + link;
}

export function extractCurrentHash(): WidgetKeysEnum | null {
    const hash = location.hash.replace('#', ''); // hash is either '' or '#any_string'

    return getEnumValue<WidgetKeysEnum>(WidgetKeysEnum, hash);
}

// pixels format - 24px
function asNumber(pixels: string): number {
    return Number.parseInt(pixels, 10);
}

export function scrollIntoWidget(key: WidgetKeysEnum, isError: boolean): Promise<void> {
    return new Promise((resolve) => {
        const widget = document.querySelector(`[${DATA_WIDGET_KEY_ATTR}="${key}"]`);

        if (widget) {
            const {y: widgetY, height} = widget.getBoundingClientRect();
            const offset = asNumber(header_height) + asNumber(widgets_gap);

            const y = isError ? widgetY + offset - (window.innerHeight - height) : widgetY - offset;

            window.scrollBy({top: y, behavior: 'smooth'});
        }

        // to wait until scrolling ends. Yes it not stable, but it works in most of the cases
        setTimeout(() => resolve(), 1000);
    });
}

export function findKeyOfFirstWidgetWithPositiveTopOffset(): WidgetKeysEnum | null {
    const widgets = [...document.querySelectorAll(`[${DATA_WIDGET_KEY_ATTR}]`)];

    const widget = widgets.find((widget_) => {
        const {bottom: widgetBottom} = widget_.getBoundingClientRect();

        return widgetBottom - 5 > asNumber(header_height);
    });

    if (widget) {
        return widget.getAttribute(DATA_WIDGET_KEY_ATTR) as WidgetKeysEnum;
    }

    return null;
}
