import {Select, Space} from 'antd';
import {LabeledValue} from 'antd/lib/select';
import {useState} from 'react';

import {useCatalogConfigForCompanyForm} from '../../../../../../../provider/catalogs/catalogs-hook';
import {CatalogConfigType} from '../../../../../../../provider/catalogs/catalogs-type';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {CountryV2Type} from '../../../../../../../service/api/api-type';
import {useSearchCountryV2List} from '../../../../../../../service/api-hook/api-hook';
import {Form} from '../../../../../../../typings/antd';
import {classNames} from '../../../../../../../util/css';
import {debounce} from '../../../../../../../util/function';
import {isCatalogAvailableInCountry} from '../../../catalog-list/catalog-list-helper';
import {getLabel} from '../build-query-helper';

import * as styles from './select-country.scss';

type SelectCountryV2Type = CountryV2Type & {
    displayLang?: boolean;
};

type PropsType = {
    value?: CountryV2Type | null;
    onChange?: (companyCountry: CountryV2Type | null) => void;
    className?: string;
    usedCatalogs?: Array<number>;
    onAfterChange: () => void;
};

export function SelectCountry(props: PropsType): JSX.Element {
    const {className, value: country, onChange, usedCatalogs, onAfterChange} = props;
    const fullClassName = classNames(styles.select_country, className);

    const {getLocalizedString} = useLocale();
    const {modal} = useModal();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const {isInProgress, result} = useSearchCountryV2List(searchQuery || null);

    const countryList: Array<SelectCountryV2Type> =
        result?.results.map((searchCountry, _index, countries) => {
            return countries.some(({name, lang}) => name === searchCountry.name && lang !== searchCountry.lang)
                ? {...searchCountry, displayLang: true}
                : searchCountry;
        }) || [];

    const catalogConfigHook = useCatalogConfigForCompanyForm();
    const catalogList = catalogConfigHook?.result || [];

    function openCatalogRemovalModal(onOk: () => void, catalogsToRemove: Array<CatalogConfigType>) {
        modal.confirm({
            maskClosable: true,
            type: 'confirm',
            title: <Locale stringKey="COMPANY_FORM__ADDRESS__UNAVAILABLE_CATALOGS_MODAL__TITLE" />,
            content: (
                <>
                    <Space direction="vertical">
                        <Locale stringKey="COMPANY_FORM__ADDRESS__UNAVAILABLE_CATALOGS_MODAL__CONTENT_START" />
                        <ul className={styles.select_country__popup_list}>
                            {catalogsToRemove.map(({catalogId, label}) => (
                                <li key={catalogId}>{label}</li>
                            ))}
                        </ul>
                    </Space>
                    <Locale stringKey="COMPANY_FORM__ADDRESS__UNAVAILABLE_CATALOGS_MODAL__CONTENT_END" />
                </>
            ),
            okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            onOk,
        });
    }

    function handleChange(selectedCountry?: LabeledValue) {
        if (!selectedCountry || !selectedCountry.value) {
            return;
        }

        const [id, lang] = selectedCountry.value.toString().split('-');

        if (!id || !lang) {
            return;
        }

        const foundCountry = countryList.find(
            (countryInList) => countryInList.id === Number(id) && countryInList.lang === lang
        );

        if (foundCountry) {
            const unavailableCatalogs = catalogList.filter(
                (catalog) => !isCatalogAvailableInCountry(catalog, foundCountry.id)
            );
            const catalogsToRemove = unavailableCatalogs.filter((catalog) => usedCatalogs?.includes(catalog.catalogId));

            if (catalogsToRemove.length > 0) {
                openCatalogRemovalModal(() => {
                    onChange?.(foundCountry);
                    onAfterChange();
                }, catalogsToRemove);
            } else {
                onChange?.(foundCountry);
                onAfterChange();
            }
        }
    }

    return (
        <Form.Item
            className={fullClassName}
            label={<Locale stringKey="COMPANY_FORM__ADDRESS__COUNTRY__LABEL" />}
            required
        >
            <Select<LabeledValue | undefined>
                data-testid="Country"
                filterOption={() => true}
                labelInValue
                loading={isInProgress}
                // show 'No data' message only if user entered some text, otherwise we don't show anything
                // eslint-disable-next-line no-undefined
                notFoundContent={searchQuery === '' ? null : undefined}
                onChange={handleChange}
                onSearch={debounce<[string]>(setSearchQuery, 300)}
                placeholder={getLocalizedString('COMPANY_FORM__ADDRESS__COUNTRY__PLACEHOLDER')}
                showSearch
                size="large"
                suffixIcon={null}
                value={getLabel(country ?? null)}
            >
                {countryList.map(({id, name, lang, displayLang}) => (
                    <Select.Option key={`${id}-${lang}`} title={`${name} ${lang}`} value={`${id}-${lang}`}>
                        {displayLang ? (
                            <>
                                {name} <span className={styles.select_country__lang}>{lang}</span>
                            </>
                        ) : (
                            name
                        )}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
