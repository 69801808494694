import {Table, TablePaginationConfig} from 'antd';
import {useEffect, useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Empty} from '../../../../layout/empty/empty';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/localization';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useLocalPackListResults} from '../../../../service/local-pack/local-pack-hook';
import {LocalPackDataTableResultType, LocalPackSearchServiceType} from '../../../../service/local-pack/local-pack-type';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {defaultPaginationState} from '../../../../util/hook';
import * as localPackStyles from '../local-pack.scss';
import {LocalPackFilterType, LocalPackParametersType} from '../local-pack-filter/local-pack-filter-type';
import {LocalPackNameAddressCell} from '../table-cells/local-pack-name-address-cell/local-pack-name-address-cell';
import {LocalPackPositionChange} from '../table-cells/local-pack-position-change/local-pack-position-change';

import {getLocalPackAllServiceResult, getLocalPackResultsColumnList} from './local-pack-results-table-helper';

type PropsType = {
    searchServices: Array<LocalPackSearchServiceType>;
    filter: LocalPackFilterType;
};

export function LocalPackResultsTable(props: PropsType): JSX.Element {
    const {searchServices, filter} = props;
    const {getFormattedDateTime} = useFormat();
    const [parameters, setParameters] = useState<LocalPackParametersType>({
        pagination: defaultPaginationState,
        filter,
    });
    const {pagination} = parameters;

    useEffect(() => {
        setParameters({
            ...parameters,
            pagination: {
                ...pagination,
                current: 1,
            },
            filter,
        });
    }, [filter]);

    const {result, isInProgress, processError} = useLocalPackListResults({
        paginationOptions: {count: Number(pagination.pageSize), page: Number(pagination.current)},
        filter: parameters.filter,
    });

    if (isInProgress) {
        return <Spinner />;
    }

    if (processError || !result) {
        return <AlertFallback />;
    }

    function handlePaginationChange(newPagination: TablePaginationConfig) {
        setParameters({
            ...parameters,
            pagination: newPagination,
        });

        if (pagination.current !== newPagination.current) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, newPagination.current ?? 1);
        }
    }

    const localPackResultsColumns = getLocalPackResultsColumnList(searchServices);

    const tableData = result.results.map((resultRow: LocalPackDataTableResultType) => {
        const allServicesPosition = getLocalPackAllServiceResult(searchServices, resultRow.values);
        const rowData: Record<string, unknown> = {
            key: `${resultRow.date}-${resultRow.company.id}`,
            date: (
                <NavigationLink
                    to={
                        generatePath(appRoute.localPackResult.path, {
                            companyId: resultRow.company.id,
                            searchQueryId: resultRow.searchQueries[0] ?? '',
                        }) + `?onDate=${resultRow.date}`
                    }
                >
                    {getFormattedDateTime(new Date(resultRow.date), {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}
                </NavigationLink>
            ),
            companyAddress: (
                <LocalPackNameAddressCell address={resultRow.company.address} name={resultRow.company.name} />
            ),
            allServices: (
                <LocalPackPositionChange
                    currentPosition={allServicesPosition.currentPosition}
                    previousPosition={allServicesPosition.previousPosition}
                />
            ),
        };

        resultRow.values.forEach((value) => {
            rowData[`${value.provider}_${value.service}`] = (
                <LocalPackPositionChange
                    currentPosition={value.currentPosition}
                    previousPosition={value.previousPosition}
                />
            );
        });

        return rowData;
    });

    return (
        <PageCard title={<Locale stringKey="LOCAL_PACK__RESULT_TABLE__TITLE" />}>
            <Table
                columns={localPackResultsColumns}
                dataSource={tableData}
                loading={isInProgress}
                locale={{
                    emptyText: (
                        <Empty
                            secondaryText="GOOGLE_SYNC__COMPANIES_STEP__FILTER__EMPTY"
                            secondaryTextClassName={localPackStyles.emptyText}
                        />
                    ),
                }}
                onChange={handlePaginationChange}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: result.count,
                }}
                scroll={{x: true}}
                size="middle"
            />
        </PageCard>
    );
}
