import {LangKeyType} from '../../../../provider/locale/translation/type';
import {GoogleAccountRoleEnum} from '../../../../service/google/accounts/google-accounts-type';

export const ACCOUNT_ROLES: Record<GoogleAccountRoleEnum, LangKeyType> = {
    [GoogleAccountRoleEnum.PrimaryOwner]: 'GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__PRIMARY_OWNER',
    [GoogleAccountRoleEnum.Owner]: 'GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__OWNER',
    [GoogleAccountRoleEnum.Manager]: 'GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__MANAGER',
    [GoogleAccountRoleEnum.SiteManager]: 'GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__SITE_MANAGER',
    [GoogleAccountRoleEnum.Unspecified]: 'GOOGLE_SYNC__ACCOUNTS_STEP__ROLE__UNSPECIFIED',
};
