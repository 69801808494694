import {faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';
import {useFakesChangeMutation} from '../../../../../../service/fakes/fakes-change';
import {FakesResultType} from '../../../../../../service/fakes/fakes-results';
import {FakeClientStatusEnum, FakeInternalStatusEnum} from '../../../../../../service/fakes/fakes-type';

import {ActionsColumnHidden} from './statuses/actions-column-hidden';
import {ActionsColumnModeration} from './statuses/actions-column-moderation';
import {ActionsColumnNew} from './statuses/actions-column-new';

type AdditionalPropsType = {
    setRejectionPopupFakeId: (value: number | null) => void;
    needActionPopupFakeId: (value: number | null) => void;
    refetch: () => void;
    isTrial?: boolean;
};

type PropsType = Pick<FakesResultType, 'id' | 'clientStatus' | 'internalStatus' | 'isDuplicate' | 'rejectReason'> &
    AdditionalPropsType;

export function ActionsColumn(props: PropsType): JSX.Element | null {
    const {
        id,
        clientStatus,
        internalStatus,
        isDuplicate,
        setRejectionPopupFakeId,
        needActionPopupFakeId,
        rejectReason,
        refetch,
        isTrial,
    } = props;

    const {mutate} = useFakesChangeMutation(id);
    const {snackbar} = useSnackbar();

    function mutateAndShowSnackbar(companyInternalStatus: FakeInternalStatusEnum) {
        if (isTrial) {
            if (companyInternalStatus === FakeInternalStatusEnum.ClientLocation) {
                track(AnalyticsTarget.DuplicatesAndFakes.Trial.ClickButton, 'My location');
            }

            if (companyInternalStatus === FakeInternalStatusEnum.NotClientLocation) {
                track(AnalyticsTarget.DuplicatesAndFakes.Trial.ClickButton, 'Not my location');
            }

            if (companyInternalStatus === FakeInternalStatusEnum.SendClaim) {
                track(AnalyticsTarget.DuplicatesAndFakes.Trial.ClickButton, 'Delete duplicate');
            }
        }

        return mutate(companyInternalStatus, {
            onSuccess: () => {
                refetch();
                return companyInternalStatus === FakeInternalStatusEnum.SendClaim
                    ? snackbar.success({
                          message: <Locale stringKey="FAKES__RESULTS__CHANGE__SUCCESS__TITLE" />,
                          description: <Locale stringKey="FAKES__RESULTS__CHANGE__SUCCESS__MESSAGE" />,
                      })
                    : snackbar.success(<Locale stringKey="FAKES__RESULTS__CHANGE__SUCCESS__TITLE" />);
            },
        });
    }

    switch (clientStatus) {
        case FakeClientStatusEnum.New:
            return <ActionsColumnNew isDuplicate={isDuplicate} mutate={mutateAndShowSnackbar} />;

        case FakeClientStatusEnum.Moderation:
            return <ActionsColumnModeration mutate={mutateAndShowSnackbar} />;

        case FakeClientStatusEnum.ClaimSent:
            return (
                <AdditionalInfo
                    leftNode={<Text lighter stringKey="FAKES__STATUS__CLAIM_SENT__DESCRIPTION" />}
                    placement="bottom"
                    title={<Locale stringKey="FAKES__STATUS__CLAIM_SENT__DESCRIPTION" />}
                >
                    <Locale stringKey="FAKES__RESULTS__CLAIM_SENT__DESCRIPTION" />
                </AdditionalInfo>
            );

        case FakeClientStatusEnum.ActionRequired:
            return (
                <Space direction="vertical" size={12}>
                    <Text lighter stringKey="FAKES__RESULTS__ACTION_REQUIRED" />
                    <Button
                        icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                        onClick={() => needActionPopupFakeId(id)}
                        size="small"
                        type="primary"
                    >
                        <span>
                            <Locale stringKey="FAKES__RESULTS__ACTION_REQUIRED__BUTTON" />
                        </span>
                    </Button>
                </Space>
            );

        case FakeClientStatusEnum.ClaimApproved:
            return <Text lighter stringKey="FAKES__RESULTS__CLAIM_APPROVED" />;

        case FakeClientStatusEnum.ClaimRejected:
            return (
                <Space direction="vertical" size={12}>
                    <Text lighter stringKey="FAKES__RESULTS__CLAIM_REJECTED" />
                    {rejectReason !== null && (
                        <Button onClick={() => setRejectionPopupFakeId(id)}>
                            <Locale stringKey="FAKES__RESULTS__CLAIM_REJECTED__BUTTON" />
                        </Button>
                    )}
                </Space>
            );

        case FakeClientStatusEnum.Hidden:
            return (
                <ActionsColumnHidden
                    internalStatus={internalStatus}
                    isDuplicate={isDuplicate}
                    mutate={mutateAndShowSnackbar}
                />
            );

        default:
            return null;
    }
}
