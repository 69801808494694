import {rootApiUrl} from '../../service/api/api-const';
import {fetchAndDeserialize} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';

import {centrifugeTokenResponseSchema, CentrifugeTokenResponseType} from './centrifuge-type';

export async function fetchCentrifugeUrl(): Promise<string> {
    return fetchX<string>(`${rootApiUrl}/cp/centrifugo/url/`);
}

export async function fetchCentrifugeToken(): Promise<string> {
    const tokenResult = await fetchAndDeserialize<CentrifugeTokenResponseType>(
        '/cp/centrifugo/token/',
        centrifugeTokenResponseSchema
    );

    return tokenResult.token;
}
