export const enSnackbarTranslations = {
    SNACKBAR__SUCCESS__SEND_APPLICATION__TITLE: 'Application has been successfully sent',
    SNACKBAR__SUCCESS__SEND_APPLICATION__DESCRIPTION: 'Our manager will contact you for details',
    SNACKBAR__ERROR__LOGIN: 'Login error',
    SNACKBAR__ERROR__LOGIN_DESCRIPTION: 'There is no such user, or the password is incorrect.',
    SNACKBAR__ERROR__LOGIN_AUTHENTICATION: 'Wrong code was entered',
    SNACKBAR__ERROR__TECH_SUPPORT: 'Try again later or contact technical support.',
    SNACKBAR__LANGUAGE: 'Interface language changed',
    SNACKBAR__LANGUAGE__DESCRIPTION: 'For the correct operation of the personal account, please refresh the page.',
    SNACKBAR__DEPLOY: 'App updated',
    SNACKBAR__DEPLOY__DESCRIPTION: 'For the correct operation of the personal account, please refresh the page.',
};
