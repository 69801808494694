import {faArrowsRotate} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Empty, Timeline} from 'antd';

import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import * as styles from './timeline-activity.scss';

type PropsType<DataType> = {
    refresh: VoidFunction;
    loadMore: VoidFunction;
    canLoadMore: boolean;
    renderTimelineItem: (dataItem: DataType) => JSX.Element;
    items: Array<DataType> | null;
    isLoading: boolean;
    processError: Error | null;
    isDashboardWidget?: boolean;
};

export function TimelineActivity<DataType>(props: PropsType<DataType>): JSX.Element {
    const {refresh, loadMore, canLoadMore, renderTimelineItem, items, isLoading, processError, isDashboardWidget} =
        props;

    const {getLocalizedString} = useLocale();

    if (!items) {
        return <Spinner />;
    }

    if (processError) {
        return <AlertFallback />;
    }

    if (items.length === 0) {
        return (
            <PageCard title={getLocalizedString('DASHBOARD_PAGE__TIMELINE__TITLE')}>
                <Empty />
            </PageCard>
        );
    }

    return (
        <PageCard
            title={
                <>
                    <DashboardWidgetHeader
                        title={<Locale stringKey="DASHBOARD_PAGE__TIMELINE__TITLE" />}
                        widget={isDashboardWidget ? DashboardWidgetEnum.Activity : null}
                    />
                    <Button className={styles.TimelineActivity_refreshButton} onClick={refresh} type="text">
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </Button>
                </>
            }
        >
            <div className={styles.TimelineActivity}>
                <Timeline className={styles.TimelineActivity_items}>{items.map(renderTimelineItem)}</Timeline>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Button
                        className={styles.TimelineActivity_showMore}
                        hidden={!canLoadMore}
                        onClick={() => loadMore()}
                        type="link"
                    >
                        <Locale stringKey="DASHBOARD_PAGE__TIMELINE__SHOW_MORE" />
                    </Button>
                )}
            </div>
        </PageCard>
    );
}
