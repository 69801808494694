import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';

import * as styles from './post-actions.scss';

type PropsType = {
    postId: number;
};

export function PostActionEdit(props: PropsType): JSX.Element {
    const {postId} = props;

    const {postsManagementEditPost} = appRoute;

    return (
        <NavigationLink to={generatePath(postsManagementEditPost.path, {postId})}>
            <Button size="small" type="link">
                <FontAwesomeIcon className={styles.PostActions_icon} icon={faEdit} />
            </Button>
        </NavigationLink>
    );
}
