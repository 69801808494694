import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {getEnumValueEnsure} from '../../../../../util/enum';
import {QueryMapType} from '../../../../../util/type';

import {reviewsFormKeyName} from './reviews-filter-const';
import {
    ComplaintWasLeftEnum,
    ContentOfReviewsEnum,
    DeletedReviewsEnum,
    ModeratedEnum,
    PossibilityOfAnAnswerEnum,
    RepliesToReviewsEnum,
    ReviewChangedEnum,
    ReviewNameEnum,
    ReviewsFilterDataType,
    ReviewsFilterFieldNameEnum,
    WaitingForSecondAnswerEnum,
    WithoutTagEnum,
} from './reviews-filter-type';

export function trackAnalytics(filterName: string, value: Array<string | number> | string): void {
    track(
        `${AnalyticsTarget.ReviewsManagement.Reviews.UseFilter} ${filterName.replaceAll(/[_-]/g, ' ')}`,
        Array.isArray(value) ? value.join(', ') : value
    );
}

// eslint-disable-next-line complexity, max-statements,sonarjs/cognitive-complexity
export function getReviewsFilterDataFromForm(form: {
    getFieldValue: (fieldName: string) => unknown;
}): ReviewsFilterDataType {
    const reviewSourceListRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.sourceList);
    const sourceList: Array<number> = Array.isArray(reviewSourceListRaw) ? reviewSourceListRaw.map(Number) : [];
    const reviewStatusListRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.processStatus);
    const statusList: Array<string> = Array.isArray(reviewStatusListRaw) ? reviewStatusListRaw : [];
    const ratingListRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.ratingList);
    const ratingList: Array<number> = Array.isArray(ratingListRaw) ? ratingListRaw.map(Number) : [];
    const employeeResponsibleRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.employeesResponsible);
    const employeeResponsible: Array<string> = Array.isArray(employeeResponsibleRaw)
        ? employeeResponsibleRaw.filter(Boolean)
        : [];
    const employeeRespondingRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.employeesResponding);
    const employeeResponding: Array<string> = Array.isArray(employeeRespondingRaw)
        ? employeeRespondingRaw.filter(Boolean)
        : [];
    const doctorsRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.doctorNames);
    const doctors: Array<string> = Array.isArray(doctorsRaw) ? doctorsRaw.map(String) : [];
    const tagListRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.tagList);
    const tagList: Array<number> = Array.isArray(tagListRaw)
        ? tagListRaw.flatMap((item) => (typeof item === 'string' ? item.split(', ').map(Number) : item))
        : [];
    const autoReplayRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.autoReplay);
    const autoReplay: Array<string> = Array.isArray(autoReplayRaw) ? autoReplayRaw.map(String) : [];

    const withoutTag: WithoutTagEnum = getEnumValueEnsure<WithoutTagEnum>(
        WithoutTagEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.withoutTag),
        WithoutTagEnum.noSelected
    );

    const contentOfReviews: ContentOfReviewsEnum = getEnumValueEnsure<ContentOfReviewsEnum>(
        ContentOfReviewsEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.contentOfReviews),
        ContentOfReviewsEnum.noSelected
    );
    const deletedReviews: DeletedReviewsEnum = getEnumValueEnsure<DeletedReviewsEnum>(
        DeletedReviewsEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.deletedReviews),
        DeletedReviewsEnum.noSelected
    );
    const repliesToReviews: RepliesToReviewsEnum = getEnumValueEnsure<RepliesToReviewsEnum>(
        RepliesToReviewsEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.repliesToReviews),
        RepliesToReviewsEnum.noSelected
    );
    const possibilityOfAnAnswer: PossibilityOfAnAnswerEnum = getEnumValueEnsure<PossibilityOfAnAnswerEnum>(
        PossibilityOfAnAnswerEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.possibilityOfAnAnswer),
        PossibilityOfAnAnswerEnum.noSelected
    );
    const reviewType: ReviewNameEnum = getEnumValueEnsure<ReviewNameEnum>(
        ReviewNameEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.reviewType),
        ReviewNameEnum.noSelected
    );
    const complaintWasLeft: ComplaintWasLeftEnum = getEnumValueEnsure<ComplaintWasLeftEnum>(
        ComplaintWasLeftEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.complaintWasLeft),
        ComplaintWasLeftEnum.noSelected
    );
    const reviewChanged: ReviewChangedEnum = getEnumValueEnsure<ReviewChangedEnum>(
        ReviewChangedEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.reviewChanged),
        ReviewChangedEnum.noSelected
    );
    const waitingForSecondAnswer: WaitingForSecondAnswerEnum = getEnumValueEnsure<WaitingForSecondAnswerEnum>(
        WaitingForSecondAnswerEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.waitingForSecondAnswer),
        WaitingForSecondAnswerEnum.noSelected
    );
    const reviewModerated: ModeratedEnum = getEnumValueEnsure<ModeratedEnum>(
        ModeratedEnum,
        form.getFieldValue(ReviewsFilterFieldNameEnum.reviewModerated),
        ModeratedEnum.noSelected
    );

    const dateRangeRaw = form.getFieldValue(reviewsFormKeyName.dateRange);
    const [dateFromRaw, dateToRaw] = Array.isArray(dateRangeRaw) ? dateRangeRaw : [null, null];

    const dateFrom: Date | null = dateFromRaw && dateFromRaw.toISOString ? new Date(dateFromRaw.toISOString()) : null;
    const dateTo: Date | null = dateToRaw && dateToRaw.toISOString ? new Date(dateToRaw.toISOString()) : null;

    const brandIdsRaw = form.getFieldValue(ReviewsFilterFieldNameEnum.brandIds);
    const brandIds: number | null = brandIdsRaw ? Number(brandIdsRaw) : null;

    return {
        [ReviewsFilterFieldNameEnum.brandIds]: brandIds,
        [ReviewsFilterFieldNameEnum.sourceList]: sourceList,
        [ReviewsFilterFieldNameEnum.processStatus]: statusList,
        [ReviewsFilterFieldNameEnum.ratingList]: ratingList,
        [ReviewsFilterFieldNameEnum.employeesResponding]: employeeResponding,
        [ReviewsFilterFieldNameEnum.employeesResponsible]: employeeResponsible,
        [ReviewsFilterFieldNameEnum.doctorNames]: doctors,
        [ReviewsFilterFieldNameEnum.tagList]: tagList,
        [ReviewsFilterFieldNameEnum.withoutTag]: withoutTag,

        [ReviewsFilterFieldNameEnum.autoReplay]: autoReplay,
        [ReviewsFilterFieldNameEnum.contentOfReviews]: contentOfReviews,
        [ReviewsFilterFieldNameEnum.deletedReviews]: deletedReviews,
        [ReviewsFilterFieldNameEnum.repliesToReviews]: repliesToReviews,
        [ReviewsFilterFieldNameEnum.possibilityOfAnAnswer]: possibilityOfAnAnswer,
        [ReviewsFilterFieldNameEnum.reviewType]: reviewType,
        [ReviewsFilterFieldNameEnum.complaintWasLeft]: complaintWasLeft,
        [ReviewsFilterFieldNameEnum.reviewChanged]: reviewChanged,
        [ReviewsFilterFieldNameEnum.waitingForSecondAnswer]: waitingForSecondAnswer,
        [ReviewsFilterFieldNameEnum.reviewModerated]: reviewModerated,

        [ReviewsFilterFieldNameEnum.dateFrom]: dateFrom,
        [ReviewsFilterFieldNameEnum.dateTo]: dateTo,
    };
}

// eslint-disable-next-line max-statements, complexity
export function getReviewsFilterDataFromUrlQuery(urlQueries: QueryMapType): ReviewsFilterDataType {
    const reviewSourceListRaw = urlQueries[ReviewsFilterFieldNameEnum.sourceList];
    const sourceList: Array<number> =
        typeof reviewSourceListRaw === 'string' ? reviewSourceListRaw.split(',').map(Number) : [];
    const reviewStatusListRaw = urlQueries[ReviewsFilterFieldNameEnum.processStatus];
    const statusList: Array<string> = typeof reviewStatusListRaw === 'string' ? reviewStatusListRaw.split(',') : [];
    const ratingListRaw = urlQueries[ReviewsFilterFieldNameEnum.ratingList];
    const ratingList: Array<number> = typeof ratingListRaw === 'string' ? ratingListRaw.split(',').map(Number) : [];
    const employeeResponsibleRaw = urlQueries[ReviewsFilterFieldNameEnum.employeesResponsible];
    const employeeResponsible: Array<string> = employeeResponsibleRaw ? employeeResponsibleRaw.split(',') : [];
    const employeeRespondingRaw = urlQueries[ReviewsFilterFieldNameEnum.employeesResponding];
    const employeeResponding: Array<string> = employeeRespondingRaw ? employeeRespondingRaw.split(',') : [];
    const doctorsRaw = urlQueries[ReviewsFilterFieldNameEnum.doctorNames];
    const doctors: Array<string> = doctorsRaw ? doctorsRaw.split(',') : [];
    const tagListRaw = urlQueries[ReviewsFilterFieldNameEnum.tagList];
    const tagList: Array<number> = typeof tagListRaw === 'string' ? tagListRaw.split(',').map(Number) : [];
    const autoReplayRaw = urlQueries[ReviewsFilterFieldNameEnum.autoReplay];
    const autoReplay: Array<string> = autoReplayRaw ? autoReplayRaw.split(',') : [];

    const withoutTag: WithoutTagEnum = getEnumValueEnsure<WithoutTagEnum>(
        WithoutTagEnum,
        urlQueries[ReviewsFilterFieldNameEnum.withoutTag],
        WithoutTagEnum.noSelected
    );

    const contentOfReviews: ContentOfReviewsEnum = getEnumValueEnsure<ContentOfReviewsEnum>(
        ContentOfReviewsEnum,
        urlQueries[ReviewsFilterFieldNameEnum.contentOfReviews],
        ContentOfReviewsEnum.noSelected
    );
    const deletedReviews: DeletedReviewsEnum = getEnumValueEnsure<DeletedReviewsEnum>(
        DeletedReviewsEnum,
        urlQueries[ReviewsFilterFieldNameEnum.deletedReviews],
        DeletedReviewsEnum.noSelected
    );
    const repliesToReviews: RepliesToReviewsEnum = getEnumValueEnsure<RepliesToReviewsEnum>(
        RepliesToReviewsEnum,
        urlQueries[ReviewsFilterFieldNameEnum.repliesToReviews],
        RepliesToReviewsEnum.noSelected
    );
    const possibilityOfAnAnswer: PossibilityOfAnAnswerEnum = getEnumValueEnsure<PossibilityOfAnAnswerEnum>(
        PossibilityOfAnAnswerEnum,
        urlQueries[ReviewsFilterFieldNameEnum.possibilityOfAnAnswer],
        PossibilityOfAnAnswerEnum.noSelected
    );
    const reviewType: ReviewNameEnum = getEnumValueEnsure<ReviewNameEnum>(
        ReviewNameEnum,
        urlQueries[ReviewsFilterFieldNameEnum.reviewType],
        ReviewNameEnum.noSelected
    );
    const complaintWasLeft: ComplaintWasLeftEnum = getEnumValueEnsure<ComplaintWasLeftEnum>(
        ComplaintWasLeftEnum,
        urlQueries[ReviewsFilterFieldNameEnum.complaintWasLeft],
        ComplaintWasLeftEnum.noSelected
    );
    const reviewChanged: ReviewChangedEnum = getEnumValueEnsure<ReviewChangedEnum>(
        ReviewChangedEnum,
        urlQueries[ReviewsFilterFieldNameEnum.reviewChanged],
        ReviewChangedEnum.noSelected
    );
    const waitingForSecondAnswer: WaitingForSecondAnswerEnum = getEnumValueEnsure<WaitingForSecondAnswerEnum>(
        WaitingForSecondAnswerEnum,
        urlQueries[ReviewsFilterFieldNameEnum.waitingForSecondAnswer],
        WaitingForSecondAnswerEnum.noSelected
    );
    const reviewModerated: ModeratedEnum = getEnumValueEnsure<ModeratedEnum>(
        ModeratedEnum,
        urlQueries[ReviewsFilterFieldNameEnum.reviewModerated],
        ModeratedEnum.noSelected
    );

    const dateFromRaw = urlQueries[ReviewsFilterFieldNameEnum.dateFrom];
    const dateFrom: Date | null = dateFromRaw ? new Date(dateFromRaw) : null;
    const dateToRaw = urlQueries[ReviewsFilterFieldNameEnum.dateTo];
    const dateTo: Date | null = dateToRaw ? new Date(dateToRaw) : null;

    const brandIdsRaw = urlQueries[ReviewsFilterFieldNameEnum.brandIds];
    const brandIds: number | null = typeof brandIdsRaw === 'string' ? Number(brandIdsRaw) : null;

    return {
        [ReviewsFilterFieldNameEnum.brandIds]: brandIds,
        [ReviewsFilterFieldNameEnum.sourceList]: sourceList,
        [ReviewsFilterFieldNameEnum.processStatus]: statusList,
        [ReviewsFilterFieldNameEnum.ratingList]: ratingList,
        [ReviewsFilterFieldNameEnum.employeesResponsible]: employeeResponsible,
        [ReviewsFilterFieldNameEnum.employeesResponding]: employeeResponding,
        [ReviewsFilterFieldNameEnum.doctorNames]: doctors,
        [ReviewsFilterFieldNameEnum.tagList]: tagList,
        [ReviewsFilterFieldNameEnum.withoutTag]: withoutTag,

        [ReviewsFilterFieldNameEnum.autoReplay]: autoReplay,
        [ReviewsFilterFieldNameEnum.contentOfReviews]: contentOfReviews,
        [ReviewsFilterFieldNameEnum.deletedReviews]: deletedReviews,
        [ReviewsFilterFieldNameEnum.repliesToReviews]: repliesToReviews,
        [ReviewsFilterFieldNameEnum.possibilityOfAnAnswer]: possibilityOfAnAnswer,
        [ReviewsFilterFieldNameEnum.reviewType]: reviewType,
        [ReviewsFilterFieldNameEnum.complaintWasLeft]: complaintWasLeft,
        [ReviewsFilterFieldNameEnum.reviewChanged]: reviewChanged,
        [ReviewsFilterFieldNameEnum.waitingForSecondAnswer]: waitingForSecondAnswer,
        [ReviewsFilterFieldNameEnum.reviewModerated]: reviewModerated,

        [ReviewsFilterFieldNameEnum.dateFrom]: dateFrom,
        [ReviewsFilterFieldNameEnum.dateTo]: dateTo,
    };
}
