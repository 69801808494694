import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';
import {generateResponseSchema} from '../api/api-type';
import {useDebouncedValue} from '../debounce-hook/debounce-hook';

import {getCompaniesImportValidationResultCommonUrl} from './companies-import-result';

const companiesImportErrorCodeSchema = r.object({
    code: r.number(),
    isCritical: r.boolean(),
    msg: r.string(),
});

export type CompaniesImportErrorCodeType = r.infer<typeof companiesImportErrorCodeSchema>;

const companiesImportErrorCodesSchema = generateResponseSchema(companiesImportErrorCodeSchema);

type CompaniesImportErrorCodesType = r.infer<typeof companiesImportErrorCodesSchema>;

export function getCompaniesImportErrorCodesUrl(id: string): string {
    return `${getCompaniesImportValidationResultCommonUrl(id)}error_code/`;
}

function fetchCompaniesImportErrorCodes(id: string): Promise<CompaniesImportErrorCodesType> {
    const url = `${getCompaniesImportErrorCodesUrl(id)}?${objectToUrlParameters({count: 100})}`;

    return fetchAndDeserialize(url, companiesImportErrorCodesSchema);
}

export function useCompaniesImportErrorCodes(
    originalId: string | null,
    importPercent: number
): UseQueryResult<CompaniesImportErrorCodesType> {
    const id = useDebouncedValue(originalId, '');

    return useQuery([getCompaniesImportErrorCodesUrl(id ?? '')], () => fetchCompaniesImportErrorCodes(id ?? ''), {
        enabled: Boolean(id) && importPercent === 100,
    });
}
