import {faHouse} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../app-route';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {NavigationLink} from '../navigation-link/navigation-link';

import {renderBreadCrumbItem} from './bread-crumbs-helper';
import {BreadCrumbItemType} from './bread-crumbs-type';
import * as styles from './bread-crumbs.scss';

type PropsType = {
    list: Array<BreadCrumbItemType>;
};

export function BreadCrumbs(props: PropsType): JSX.Element {
    const {list} = props;

    const {LogoBreadcrumbs} = useDomainConfig();

    return (
        <nav className={styles.bread_crumbs}>
            <div className={styles.item_wrapper_logo}>
                <NavigationLink className={styles.item} to={appRoute.root.path}>
                    {LogoBreadcrumbs ? (
                        <LogoBreadcrumbs className={styles.logo_image} />
                    ) : (
                        <FontAwesomeIcon icon={faHouse} />
                    )}
                </NavigationLink>
            </div>

            {list.map(renderBreadCrumbItem)}
        </nav>
    );
}
