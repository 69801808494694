import {faGear, faPlay, faUpRightAndDownLeftFromCenter, faVolumeXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Progress} from 'antd';

import {Text} from '../../../../../../../../../../../component/text/text';
import {PostVideoType} from '../../../../../../../../../../../service/posts/posts-types';
import {getFormattedVideoDuration} from '../../../../../../../../../../../util/time';

import * as styles from './facebook-preview-video.scss';

type PropsType = {
    video: PostVideoType | null;
};

export function FacebookPreviewVideo(props: PropsType): JSX.Element | null {
    const {video} = props;

    if (!video) {
        return null;
    }

    return (
        <div
            className={styles.FacebookPreviewVideo}
            style={{
                backgroundImage: `url(${video.previewImageUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}
        >
            <div className={styles.FacebookPreviewVideo_metaInformationWrapper}>
                <FontAwesomeIcon className={styles.FacebookPreviewVideo_icon} icon={faPlay} />
                <Text className={styles.FacebookPreviewVideo_duration} inheritColor>
                    {getFormattedVideoDuration(Math.floor(video.duration / 2) * 1000)} /{' '}
                    {getFormattedVideoDuration(video.duration * 1000)}
                </Text>
                <Progress className={styles.FacebookPreviewVideo_progress} percent={40} showInfo={false} />
                <FontAwesomeIcon className={styles.FacebookPreviewVideo_icon} icon={faGear} />
                <FontAwesomeIcon className={styles.FacebookPreviewVideo_icon} icon={faUpRightAndDownLeftFromCenter} />
                <FontAwesomeIcon className={styles.FacebookPreviewVideo_icon} icon={faVolumeXmark} />
            </div>
        </div>
    );
}
