import {ConfigProvider} from 'antd';
import {locale} from 'dayjs';
import {PropsWithChildren, useEffect} from 'react';

import {localeNameToAntLocaleMap} from './locale-context-const';
import {useLocale} from './locale-hook';

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function AntLocaleProvider(props: PropsType): JSX.Element {
    const {children} = props;
    const {localeName, shortLocaleName} = useLocale();

    useEffect(() => {
        locale(shortLocaleName);
    }, [shortLocaleName]);

    return <ConfigProvider locale={localeNameToAntLocaleMap[localeName]}>{children}</ConfigProvider>;
}
