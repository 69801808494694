import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// eslint-disable-next-line no-restricted-imports
import {Button, Form, Input} from 'antd';

import {FlagWithTooltip} from '../../../../../../../../component/languages-popup/flag/flag-with-tooltip';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../../../service/company-v2/company-const';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {classNames} from '../../../../../../../../util/css';
import {PushedNameInput} from '../pushed-name-input/pushed-name-input';

import * as styles from './name-input.scss';

type PropsType = {
    index: number;
    fieldKey?: number;
    fieldName: number;
    basePath: [CompanyKeyEnum.Names] | [CompanyKeyEnum.ExtraNames, number, 'names'];
    supportsPushedName: boolean;
    supportsNameWithoutLanguage: boolean;
    remove: (index: number | Array<number>) => void;
    openRemovalModal?: (() => void) | null;
};

export function NameInput(props: PropsType): JSX.Element {
    const {
        index,
        fieldKey,
        fieldName,
        basePath,
        supportsPushedName,
        supportsNameWithoutLanguage,
        remove,
        openRemovalModal,
    } = props;

    const {requiredFieldRule} = useFormRules();
    const isLarge = index === 0 && supportsNameWithoutLanguage;

    const {getLocalizedString} = useLocale();

    return (
        <div
            className={classNames(
                styles.name_input__wrapper,
                isLarge ? styles.name_input__wrapper_big : styles.name_input__wrapper_small,
                {[styles.name_input__wrapper_small_no_pushed_name]: index > 0 && !supportsPushedName}
            )}
        >
            {isLarge ? null : (
                <div className={styles.name_input__flag}>
                    <Form.Item name={[fieldName, 'lang']} noStyle>
                        <FlagWithTooltip />
                    </Form.Item>
                </div>
            )}

            <Form.Item
                className={styles.name_input__name}
                fieldKey={fieldKey}
                label={isLarge ? <Locale stringKey="COMPANY_FORM__NAMES__COMPANY_NAME__LABEL" /> : null}
                name={[fieldName, 'name']}
                rules={isLarge ? [requiredFieldRule] : []}
            >
                <Input
                    placeholder={getLocalizedString('COMPANY_FORM__NAMES__COMPANY_NAME__PLACEHOLDER')}
                    size={isLarge ? 'large' : 'middle'}
                />
            </Form.Item>

            {supportsPushedName && (
                <Form.Item dependencies={[[...basePath, index, 'name']]} noStyle>
                    {({getFieldValue}) => (
                        <PushedNameInput
                            large={isLarge}
                            name={getFieldValue([...basePath, index, 'name'])}
                            pushedName={getFieldValue([...basePath, index, 'pushedName'])}
                        />
                    )}
                </Form.Item>
            )}

            {!isLarge ? (
                <Button
                    className={styles.name_input__remove_button}
                    onClick={() => (openRemovalModal ? openRemovalModal() : remove(fieldName))}
                    type="text"
                >
                    <FontAwesomeIcon className={styles.name_input__remove_button_image} icon={faTrash} />
                </Button>
            ) : null}
        </div>
    );
}
