import {Collapse} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {AutoReplyBalanceDataType, useAutoRepliesBalance} from '../../../../service/reviews/reviews-response-templates';
import {classNames} from '../../../../util/css';

import * as styles from './auto-replies-left.scss';

type PropsType = {
    className?: string;
};

export function AutoRepliesLeft(props: PropsType): JSX.Element {
    const {className} = props;

    const {data, isFetching: isInProgress} = useAutoRepliesBalance();

    const autoRepliesBalanceList: Array<AutoReplyBalanceDataType> = data || [];

    const allBalancesCount: number = autoRepliesBalanceList.reduce<number>(
        (accum: number, item: AutoReplyBalanceDataType): number => accum + item.balance,
        0
    );

    const fullClassName = classNames(styles.auto_replies_left, className);

    return (
        <Collapse
            bordered={false}
            className={fullClassName}
            items={[
                {
                    className: styles.auto_replies__collapse_panel,
                    label: (
                        <Locale
                            stringKey="AUTO_REPLIES__TOTAL_AUTO_REPLIES_LEFT"
                            valueMap={{
                                autoRepliesLeft: (
                                    <span className={styles.fw_500} key="auto-replies-left">
                                        {isInProgress ? '-' : allBalancesCount}
                                    </span>
                                ),
                            }}
                        />
                    ),
                    key: 'auto-replies-left',
                    children: (
                        <ul className={styles.auto_replies_left__list}>
                            {autoRepliesBalanceList.map((autoReplyBalance: AutoReplyBalanceDataType): JSX.Element => {
                                const {balance, email, userId} = autoReplyBalance;

                                return (
                                    <li className={styles.auto_replies_left__list_item} key={userId}>
                                        {email}

                                        {': '}

                                        <span className={styles.fw_500}>{balance}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    ),
                },
            ]}
        />
    );
}
