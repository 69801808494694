import {Text} from '../../../../../../../../../../../component/text/text';
import {availablePostButtonTypeTranslationMap} from '../../../../../../../../../../../service/posts/posts-hook-const';
import {GoogleButtonTypeEnum, PostTypeEnum} from '../../../../../../../../../../../service/posts/posts-types';

import {ReactComponent as IconShare} from './images/icon-share.svg';
import * as styles from './google-actions.scss';

type PropsType = {
    actionType?: GoogleButtonTypeEnum;
    topicType?: PostTypeEnum;
};

export function GoogleActions(props: PropsType): JSX.Element {
    const {actionType, topicType} = props;

    const isShowButton =
        topicType !== PostTypeEnum.googleOffer && actionType && actionType !== GoogleButtonTypeEnum.Unspecified;

    return (
        <div className={styles.GoogleActions}>
            {isShowButton && (
                <Text
                    className={styles.GoogleActions_more}
                    stringKey={availablePostButtonTypeTranslationMap[actionType]}
                />
            )}
            <div className={styles.GoogleActions_share}>
                <IconShare />
            </div>
        </div>
    );
}
