import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Descriptions} from 'antd';

import {Text} from '../../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../../provider/locale/locale';
import {useExportResultMutation} from '../../../../../../../../../service/companies-import/companies-import-export-result';
import {classNames} from '../../../../../../../../../util/css';
import {FeedResultsTypeEnum} from '../../../feed-results-const';

import * as styles from './feed-results-descriptions.scss';

type PropsType = {
    importUrlId: number | string | null;
    companiesCount: Record<'withoutErrors' | 'withErrors' | 'criticalErrors' | 'duplicates', number>;
    type: FeedResultsTypeEnum;
    isValidationResult?: boolean;
};

export function FeedResultsDescriptions(props: PropsType): JSX.Element {
    const {importUrlId, companiesCount, type, isValidationResult} = props;

    const {mutate: exportResult} = useExportResultMutation();

    return (
        <Descriptions bordered className={styles.FeedResultsSummary_descriptions} column={1} size="middle">
            <Descriptions.Item label={<Locale stringKey="FEED__RESULTS__COMPANIES_WITHOUT_ERRORS" />}>
                {companiesCount.withoutErrors}
            </Descriptions.Item>

            <Descriptions.Item label={<Locale stringKey="FEED__RESULTS__COMPANIES_WITH_ERRORS" />}>
                <span
                    className={classNames({
                        [styles.FeedResultsSummary_description__warning]: companiesCount.withErrors > 0,
                    })}
                >
                    {companiesCount.withErrors}
                </span>
            </Descriptions.Item>

            <Descriptions.Item label={<Locale stringKey="FEED__RESULTS__CRITICAL_ERRORS" />}>
                <span
                    className={classNames({
                        [styles.FeedResultsSummary_description__error]: companiesCount.criticalErrors > 0,
                    })}
                >
                    {companiesCount.criticalErrors}
                </span>
            </Descriptions.Item>

            {type === FeedResultsTypeEnum.Import && (
                <Descriptions.Item label={<Locale stringKey="FEED__RESULTS__DUPLICATES" />}>
                    <span
                        className={classNames({
                            [styles.FeedResultsSummary_description__error]: companiesCount.duplicates > 0,
                        })}
                    >
                        {companiesCount.duplicates}
                    </span>
                </Descriptions.Item>
            )}

            {importUrlId && type === FeedResultsTypeEnum.Import && (
                <Descriptions.Item label={<Locale stringKey="FEED__RESULTS__EXPORT" />}>
                    {companiesCount.withErrors === 0 ? (
                        <Text className={styles.FeedResultsSummary_noErrors} stringKey="FEED__RESULTS__NO_ERRORS" />
                    ) : (
                        <Button
                            className={styles.FeedResultsSummary_exportButton}
                            icon={<FontAwesomeIcon icon={faDownload} />}
                            onClick={() => exportResult({importId: importUrlId, isValidationResult})}
                            size="small"
                            type="link"
                        >
                            <span>
                                <Locale stringKey="FEED__RESULTS__EXPORT__BUTTON" />
                            </span>
                        </Button>
                    )}
                </Descriptions.Item>
            )}
        </Descriptions>
    );
}
