import {ExtractRouteParams} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {postCompanySelectorCompanySchema} from '../../../../service/feature-companies/feature-companies-api-type';
import {CompaniesSelectorFeatureEnum} from '../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../service/feature-companies/feature-companies-hook';
import {PostSelectorCompanyType} from '../../../../service/feature-companies/feature-companies-type';
import {usePost} from '../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormModeEnum, PostStatusEnum} from '../../../../service/posts/posts-types';
import {PostForm} from '../post-form/post-form';
import {getPostFormInitialValues} from '../post-form/posts-form-helper';

export function EditPost(): JSX.Element {
    const {postsManagementEditPost, postsManagement} = appRoute;

    const {postId} = useParams<ExtractRouteParams<typeof postsManagementEditPost.path, string>>();

    const {data: post, isLoadingError, isLoading} = usePost(Number(postId));

    const companiesSelector = useCompaniesSelector<PostSelectorCompanyType>({
        feature: CompaniesSelectorFeatureEnum.Posts,
        resourceId: postId,
        companySchema: postCompanySelectorCompanySchema,
        selectorId: post?.[PostFormFieldsEnum.SelectorId],
    });

    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__EDIT_POSTS')} />

            <BreadCrumbs
                list={[
                    {path: postsManagement.path, titleLangKey: 'CATEGORY_NAME__POSTS'},
                    {path: '', titleLangKey: 'CATEGORY_NAME__EDIT_POSTS'},
                ]}
            />

            {isLoadingError ? (
                <AlertFallback />
            ) : (
                <>
                    <PageHeader>
                        <Locale stringKey="POSTS__EDIT_POST__HEADER" />
                    </PageHeader>

                    {isLoading && <Spinner position="absolute" />}

                    {post ? (
                        <PostForm
                            companiesSelector={companiesSelector}
                            formMode={
                                post[PostFormFieldsEnum.Status] === PostStatusEnum.Draft
                                    ? PostFormModeEnum.EditDraft
                                    : PostFormModeEnum.EditExisted
                            }
                            initialValues={{
                                ...getPostFormInitialValues(post),
                                [PostFormFieldsEnum.Id]: Number(postId),
                            }}
                        />
                    ) : (
                        <div />
                    )}
                </>
            )}
        </Page>
    );
}
