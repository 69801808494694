import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';
import {NavigationLink} from '../navigation-link/navigation-link';

import {BreadCrumbItemType} from './bread-crumbs-type';
import * as styles from './bread-crumbs.scss';

export function renderBreadCrumbItem(item: BreadCrumbItemType): JSX.Element {
    const {titleLangKey, path, valueMap, title} = item;
    const key = titleLangKey + '|' + path;
    const linkClassName = classNames(styles.item, {
        [styles.item_disabled]: !path.trim(),
    });

    return (
        <div className={styles.item_wrapper} key={key}>
            <span className={styles.separator}>/</span>

            <NavigationLink className={linkClassName} to={path}>
                {title ?? (titleLangKey ? <Locale stringKey={titleLangKey} valueMap={valueMap} /> : '')}
            </NavigationLink>
        </div>
    );
}
