import {DefaultTooltipStaticPropsType} from '../../../../../component/chart-tooltip/default-chart-tooltip';
import {getDefaultReactTooltipOptions} from '../../../../../layout/chart/chart-const';
import {ChartJsLineOptionsType} from '../../../../../layout/chart/line-chart/line-chart-type';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {OnlinePresenceMetricsEnum} from '../../../../../service/dashboard/dashboard-type';

import * as styles from './dashboard-online-presence-catalog-stats.scss';

export function getOnlinePresenceChartConfig(tooltipOptions: DefaultTooltipStaticPropsType): ChartJsLineOptionsType {
    return {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                hitRadius: 16,
                radius: 0,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: getDefaultReactTooltipOptions(tooltipOptions),
            datalabels: {
                display: false,
            },
        },
    };
}

export const onlinePresenceMetricsTranslationMap: Partial<Record<OnlinePresenceMetricsEnum, LangKeyType>> = {
    [OnlinePresenceMetricsEnum.DirectionRequests]: 'DASHBOARD_PAGE__ONLINE_PRESENCE__ROUTES',
    [OnlinePresenceMetricsEnum.CallClicks]: 'DASHBOARD_PAGE__ONLINE_PRESENCE__CALLS',
    [OnlinePresenceMetricsEnum.WebsiteClicks]: 'DASHBOARD_PAGE__ONLINE_PRESENCE__SITE',
};

export const onlinePresenceMetricsColorMap: Partial<Record<OnlinePresenceMetricsEnum, string>> = {
    [OnlinePresenceMetricsEnum.DirectionRequests]: styles.DirectionRequests_chartColor,
    [OnlinePresenceMetricsEnum.CallClicks]: styles.CallClicks_chartColor,
    [OnlinePresenceMetricsEnum.WebsiteClicks]: styles.WebsiteClicks_chartColor,
};

export const SLIM_ONLINE_PRESENCE_CONTAINER_BREAKPOINT = 500;
