import {fetchAndDeserialize} from '../../../util/api-adapter';

import {companyLocationSchema, CompanyLocationType} from './use-companies-locations-type';

export function fetchCompanyLocations(): Promise<Array<CompanyLocationType>> {
    return fetchAndDeserialize<Array<CompanyLocationType>>(
        '/cp/dashboard/all_companies_coordinate/',
        companyLocationSchema.array(),
        {
            fetchOptions: {skipCache: true},
        }
    );
}
