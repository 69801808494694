import {forceLogout} from '../component/app/routing/helpers/force-logout';
import {wGisCountryCodeHeaderName} from '../const';

import {UnknownType} from './api-adapter';
import {ApiError, HttpError} from './error';
import {HttpCodeEnum} from './fetch-const';

export enum FetchMethodEnum {
    get = 'GET',
    post = 'POST',
    patch = 'PATCH',
    put = 'PUT',
    delete = 'DELETE',
}

export type FetchOptionsType = Omit<NonNullable<Parameters<typeof fetch>[1]>, 'method'> & {
    method?: FetchMethodEnum;
    skipCache?: boolean;
};

type FetchCacheType = Record<string, null | Promise<unknown>>;

type AbortableFetchReturnType<ExpectedResponseType> = {
    abort: () => void;
    controller: AbortController;
    result: Promise<ExpectedResponseType>;
};

export type ResponseOkType = {
    result: 'ok';
};

const fetchCache: FetchCacheType = {};

export function dropFetchXCache(keys?: Array<string>): void {
    const keysToInvalidate = keys || Object.keys(fetchCache);

    keysToInvalidate.forEach((key: string) => {
        fetchCache[key] = null;
    });
}

function fetchEndCallBack(fetchBeginTimeStamp: number, url: string) {
    const maxFetchingTime = 2000; // 2 seconds
    const fetchEndTimeStamp = Date.now();
    const fetchingTime = fetchEndTimeStamp - fetchBeginTimeStamp;

    // process.env.TZ === don't spam console in tests
    if (fetchingTime > maxFetchingTime && !process.env.TZ) {
        console.log(`%c[WARNING]: "${url}" took %c${fetchingTime / 1000}s`, 'color: #00c', 'color: #c00');
    }
}

async function throwErrorByResponse(response: Response, options: FetchOptionsType) {
    const contentType: string | null = response.headers.get('Content-Type');

    if (response.status === HttpCodeEnum.Unauthorized && (!options.method || options.method === FetchMethodEnum.get)) {
        forceLogout();

        throw new HttpError(await response.text(), response.status);
    }

    if (contentType && contentType.includes('json')) {
        const errorPayload: unknown = await response.json();
        // Such json string message is for backward compatability.
        // If you sure you refactor all cases of such message in project, feel free to change it.
        const message = JSON.stringify(errorPayload);

        throw new ApiError(message, response.status, errorPayload);
    }

    throw new HttpError((await response.text()) || '', response.status);
}

export function fetchX<ExpectedResponseType = UnknownType>(
    url: string,
    options?: FetchOptionsType
): Promise<ExpectedResponseType> {
    let skipCache = false;

    if (options && Reflect.has(options, 'skipCache')) {
        skipCache = options.skipCache as boolean;

        Reflect.deleteProperty(options, 'skipCache');
    }

    if (options?.method && options.method !== FetchMethodEnum.get) {
        dropFetchXCache();
    }

    const cacheProperty = `${url} - ${JSON.stringify(options || '[empty]')}`;

    const savedPromiseResult = fetchCache[cacheProperty];

    // TODO: investigate why cache blocks render. Assumption: loading state wasn't updated and loader component wasn't shown
    if (savedPromiseResult && !skipCache) {
        // console.log(`[CACHE]: [fetchX]\n> url: ${url},\n> options: ${JSON.stringify(options || '[empty]')}`);
        return savedPromiseResult as Promise<ExpectedResponseType>;
    }

    const ucc = sessionStorage.getItem(wGisCountryCodeHeaderName);

    // 'X-2GIS-COUNTRY' headers is workaround for 2gis to influence on our backend
    const requestHeaders = {...options?.headers, ...(ucc ? {[wGisCountryCodeHeaderName]: ucc} : {})};

    const definedOptions: FetchOptionsType = {
        credentials: 'include',
        ...options,
        headers: {
            ...requestHeaders,
            Timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    };

    const fetchBeginTimeStamp = Date.now();

    const fetchResult: Promise<ExpectedResponseType> = fetch(url, definedOptions)
        .then((response: Response): Promise<ExpectedResponseType> => {
            return response.ok ? response.json() : throwErrorByResponse(response, definedOptions);
        })
        .finally(() => {
            fetchEndCallBack(fetchBeginTimeStamp, url);
        })
        .catch((error: unknown) => {
            fetchCache[cacheProperty] = null;
            console.error(error);
            throw error;
        });

    fetchCache[cacheProperty] = fetchResult;

    return fetchResult;
}

export function fetchNoContent(url: string, options?: FetchOptionsType): Promise<void> {
    if (options?.method && options.method !== FetchMethodEnum.get) {
        dropFetchXCache();
    }

    const definedOptions: FetchOptionsType = {
        credentials: 'include',
        ...options,
    };

    return fetch(url, definedOptions).then((result: Response): Promise<void> => {
        if (result.ok) {
            // eslint-disable-next-line promise/no-return-wrap
            return Promise.resolve();
        }

        return throwErrorByResponse(result, definedOptions);
    });
}

export function fetchBlob(url: string, options?: FetchOptionsType): Promise<Blob | void> {
    if (options?.method && options.method !== FetchMethodEnum.get) {
        dropFetchXCache();
    }

    const definedOptions: FetchOptionsType = {
        credentials: 'include',
        ...options,
    };

    return fetch(url, definedOptions).then((result: Response): Promise<Blob | void> => {
        if (result.ok) {
            return result.blob();
        }

        return throwErrorByResponse(result, definedOptions);
    });
}

export function abortableFetchX<ExpectedResponseType>(
    url: string,
    options?: FetchOptionsType
): AbortableFetchReturnType<ExpectedResponseType> {
    const controller = new AbortController();
    const signal = controller.signal;

    return {
        controller,
        abort: () => controller.abort(),
        result: fetchX<ExpectedResponseType>(url, {...options, signal}),
    };
}

// Function returns the very last result in the list of requests and cancels all previous
// It's helpful if you have a lot of sequential requests and you only need the last response
// Usage example:
// const raceFetchX = createRaceFetchX();
// const response = await raceFetchX('/api/endpoint/?search=123');
export function createRaceFetchX(): typeof fetchX {
    let previousController: AbortController | null = null;

    return function raceFetchX<ExpectedResponseType>(
        url: string,
        options?: FetchOptionsType
    ): Promise<ExpectedResponseType> {
        const {controller, result} = abortableFetchX<ExpectedResponseType>(url, options);

        if (previousController !== null) {
            previousController.abort();
        }

        previousController = controller;

        return result;
    };
}

// each browser has different abort controller error, but all of them contain 'abort' in message
export function isAbortedFetchError(error: Error): boolean {
    return error.message.includes('abort');
}

export function downloadBlob(blob: Blob, name: string): void {
    const blobUrl = URL.createObjectURL(blob);

    const link = window.document.createElement('a');

    link.href = blobUrl;
    link.download = name;

    window.document.body.append(link);

    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    link.remove();
}
