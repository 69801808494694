import {faEye} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';

import * as styles from './post-actions.scss';

type PropsType = {
    postId: number;
};

export function PostActionView(props: PropsType): JSX.Element {
    const {postId} = props;

    const {postsManagementViewPost} = appRoute;

    return (
        <NavigationLink to={generatePath(postsManagementViewPost.path, {postId})}>
            <Button size="small" type="link">
                <FontAwesomeIcon className={styles.PostActions_viewIcon} icon={faEye} />
            </Button>
        </NavigationLink>
    );
}
