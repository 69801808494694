import {useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/locale';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {averageResponseTimeTimeUnitShortTranslationMap} from './average-response-time-value-helper';
import * as styles from './average-response-time-value.scss';

type PropsType = {
    seconds?: number | null;
    large?: boolean;
    danger?: boolean;
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;
};

export function AverageResponseTimeValue(props: PropsType): JSX.Element {
    const {seconds, large, danger, timeUnit} = props;

    const {getFormattedNumber} = useFormat();

    const showUnit = typeof seconds === 'number';
    const displayedValue = useMemo(() => {
        if (typeof seconds === 'number') {
            let numberValue = 0;

            switch (timeUnit) {
                case ReviewsAndAnswersStatisticsUnitMeasurementEnum.Days: {
                    numberValue = seconds / (24 * 60 * 60);
                    break;
                }
                case ReviewsAndAnswersStatisticsUnitMeasurementEnum.Hours: {
                    numberValue = seconds / (60 * 60);
                    break;
                }
                default: {
                    numberValue = seconds / 60;
                }
            }

            return getFormattedNumber(numberValue, {
                maximumFractionDigits: 1,
                notation: 'compact',
            });
        }

        return '–';
    }, [getFormattedNumber, timeUnit, seconds]);

    return (
        <Text
            block
            className={classNames({
                [styles.AverageResponseTimeValue__large]: large,
                [styles.AverageResponseTimeValue__danger]: danger,
            })}
        >
            {displayedValue}
            {showUnit && (
                <span
                    className={classNames(styles.AverageResponseTimeValue_unit, {
                        [styles.AverageResponseTimeValue__danger]: danger,
                    })}
                >
                    {' '}
                    <Locale stringKey={averageResponseTimeTimeUnitShortTranslationMap[timeUnit]} />
                </span>
            )}
        </Text>
    );
}
