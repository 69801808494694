import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tag, Typography} from 'antd';
import {useCallback, useState} from 'react';

import {useCatalog} from '../../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';
import {PhotoSourceNameToProviderIdEnum} from '../../../../../../service/phototool/phototool-sources-helper';
import {
    MediaItemType,
    PhotosReportDataType,
    PhotoStatusKeyEnum,
} from '../../../../../../service/phototool/phototool-type';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {photoStatusDisplayMap} from '../../../photo-tool-const';

import {PhotoLazyLoadImage} from './lazy-load-image/photo-lazy-load-image';
import {PhotoItemDrawer} from './photo-item-drawer/photo-item-drawer';
import {determineImageSource} from './photo-item-helper';
import * as styles from './photo-item.scss';

const {Text} = Typography;

type PropsType = {
    isSelectMode: boolean;
    minHeight: number;
    onSelectPhoto?: (photo: MediaItemType) => void;
    onReport?: (photos: Array<MediaItemType>, reportData: PhotosReportDataType) => Promise<void>;
    onView?: (photos: Array<MediaItemType>) => Promise<void>;
    photo: MediaItemType;
    isSelected: boolean;
    width: number;
    showStatus?: boolean;
};

export function PhotoItem(props: PropsType): JSX.Element {
    const {
        isSelectMode,
        photo,
        isSelected,
        minHeight,
        onReport,
        onSelectPhoto,
        width,
        showStatus = true,
        onView,
    } = props;
    const [isViewInProgress, setIsViewInProgress] = useState<boolean>(false);
    const {getFormattedDateTime} = useFormat();
    const {modal} = useModal();

    const {catalog} = useCatalog(PhotoSourceNameToProviderIdEnum[photo.source.name]);

    const height = Math.max((width * photo.imageHeight) / photo.imageWidth, minHeight);
    const date = getFormattedDateTime(new Date(photo.createdAt), {timeZone: 'UTC'});
    const imageSource = determineImageSource(photo.originalImageUrl);

    const photoStatus = photoStatusDisplayMap[photo.status];

    const selectWrapperClassName = classNames(styles.PhotoItem, {
        [styles.PhotoItem__selected]: isSelected,
    });

    const onViewWrapper = useCallback(() => {
        setIsViewInProgress(true);
        onView?.([photo])
            .finally(() => {
                setIsViewInProgress(false);
                track(AnalyticsTarget.PhotoTool.MarkAsViewed, imageSource);
            })
            .catch((error: Error): void => {
                modal.error({
                    title: <Locale stringKey="NOTIFICATION__TEXT__SERVER_ERROR" />,
                    content: error.message,
                });
            });
    }, [onView, photo, imageSource, modal]);

    const onSelect = useCallback(() => onSelectPhoto?.(photo), [photo, onSelectPhoto]);

    const photoTagClassName = classNames(styles.PhotoItem_tag, {
        [styles.PhotoItem_tag__disabled]: photo.status !== PhotoStatusKeyEnum.New,
    });

    return (
        <div className={selectWrapperClassName}>
            <div className={styles.PhotoItem_body} style={{height, width}}>
                <PhotoLazyLoadImage height={height} photo={photo} width={width} />
                {showStatus && photoStatus && (
                    <div className={styles.PhotoItem_status}>
                        <Tag
                            className={photoTagClassName}
                            color={photoStatus?.color}
                            icon={isViewInProgress && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                            onClick={onViewWrapper}
                        >
                            <Locale stringKey={photoStatus.localeKey || ' '} />
                        </Tag>
                    </div>
                )}

                {isSelectMode && (
                    <button className={styles.PhotoItem_selectButton} onClick={onSelect} type="button">
                        <div className={styles.PhotoItem_selectCover} />

                        <div className={styles.PhotoItem_selectIcon}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                    </button>
                )}

                <div className={styles.PhotoItem_drawer}>
                    <PhotoItemDrawer
                        isSelectMode={isSelectMode}
                        onReport={onReport}
                        onSelect={onSelect}
                        photo={photo}
                    />
                </div>
            </div>

            <div className={styles.PhotoItem_footer}>
                <Text strong>{catalog?.name}</Text>

                <Text type="secondary">{date}</Text>
            </div>
        </div>
    );
}
