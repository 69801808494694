import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {faAngleRight, faLink} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../../../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../../../../provider/domain-config/domain-config-hook';
import {
    GoogleCompanyWithCandidatesType,
    GoogleLocationType,
} from '../../../../../../../../../service/google/google-type';
import {classNames} from '../../../../../../../../../util/css';
import {CompanyListItem} from '../../../../../common/company-list-item/company-list-item';
import {NEW_LOCATION_OPTION} from '../../../processing-step-const';

import * as styles from './location-popup-summary.scss';

type PropsType = {
    company: GoogleCompanyWithCandidatesType;
    selectedLocation: GoogleLocationType | typeof NEW_LOCATION_OPTION | null;
};

export function LocationPopupSummary(props: PropsType): JSX.Element {
    const {company, selectedLocation} = props;

    const {companyName} = useDomainConfig();

    return (
        <>
            <Text
                secondary
                small
                stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__COMPANY"
                valueMap={{
                    companyName,
                }}
            />

            <div className={styles.LocationPopupSummary}>
                <CompanyListItem
                    bolderName
                    className={styles.LocationPopupSummary_company}
                    company={company}
                    showStatus={false}
                />

                <footer
                    className={classNames(styles.Location, {
                        [styles.Location__selected]: Boolean(selectedLocation),
                    })}
                >
                    <FontAwesomeIcon
                        className={styles.Location_leftIcon}
                        icon={selectedLocation ? faLink : faInfoCircle}
                    />

                    {selectedLocation === NEW_LOCATION_OPTION ? (
                        <Text
                            className={styles.Location_text}
                            small
                            stringKey="GOOGLE_SYNC__PROCESSING_STEP__CREATE_NEW"
                        />
                    ) : selectedLocation ? (
                        <Text className={styles.Location_text} lighter small>
                            <Text className={styles.Location_name} small title={selectedLocation.name}>
                                {selectedLocation.name}
                            </Text>

                            <span title={selectedLocation.address}>{selectedLocation.address}</span>
                        </Text>
                    ) : (
                        <Text
                            className={styles.Location_text}
                            secondary
                            small
                            stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__CHOOSE"
                        />
                    )}

                    <FontAwesomeIcon className={styles.Location_rightIcon} icon={faAngleRight} />
                </footer>
            </div>
        </>
    );
}
