import {Table, TableColumnType, TableProps} from 'antd';
import RcResizeObserver from 'rc-resize-observer';
import {ReactNode, useEffect, useMemo, useState} from 'react';
import {GridChildComponentProps, VariableSizeGrid as Grid} from 'react-window';

import {classNames} from '../../util/css';
import {useWindowSize} from '../../util/window';

import {useGridRef} from './use-grid-ref';
import {useRowSize} from './use-row-size';
import {VirtualizedTableRow} from './virtualized-row';
import {DEFAULT_ROW_HEIGHT, VIRTUAL_TABLE_CLASS} from './virtualized-table-const';
import {VirtualTableContext, VirtualTableContextType} from './virtualized-table-context';
import {addWidthsToColumns, appendCheckboxToColumn, appendCheckboxToDataSource} from './virtualized-table-helper';
import {DefaultVirtialTableDataType, VirtualTableAdditionalType} from './virtualized-table-type';
import * as styles from './virtualized-table.scss';
// source of the base logic - https://ant.design/components/table/#components-table-demo-virtual-list
// source of the auto sizable rows - https://codesandbox.io/s/dynamic-size-of-react-window-list-items-forked-x51jm?file=/src/Chat.js

export function VirtualTable<DataType extends DefaultVirtialTableDataType>(
    props: TableProps<DataType> & VirtualTableAdditionalType
): JSX.Element {
    const {columns = [], scroll: scrollConfig, rowSelection, dataSource, additionalFooter} = props;

    const [tableWidth, setTableWidth] = useState(0);

    const [windowWidth] = useWindowSize();

    const {getSize, setSize} = useRowSize();

    const tableDataSource = appendCheckboxToDataSource(dataSource, rowSelection);

    const columnsWithCheckbox = appendCheckboxToColumn(columns, rowSelection, dataSource);
    const mergedColumns: Array<TableColumnType<DataType>> = addWidthsToColumns(columnsWithCheckbox, tableWidth);

    const {gridRef, connectObject, resetColumnGrid, resetRowGrid} = useGridRef<DataType>();

    useEffect(() => {
        resetColumnGrid();
    }, [resetColumnGrid, tableWidth]);

    const contextValue: VirtualTableContextType = useMemo(() => ({setSize, windowWidth}), [setSize, windowWidth]);

    function renderTableHeadWrapper() {
        return (
            <thead>
                <tr>
                    {mergedColumns.map((column) => (
                        <th
                            className={styles.virtual_table__th}
                            key={String(column.dataIndex)}
                            style={{width: column.width}}
                        >
                            {column.title as ReactNode}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }

    function renderVirtualList(
        rawData: ReadonlyArray<DataType>,
        {
            scrollbarSize,
            ref,
            onScroll,
        }: {
            scrollbarSize: number;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref: any;
            onScroll: (props: {scrollLeft: number}) => void;
        }
    ) {
        // eslint-disable-next-line no-param-reassign
        ref.current = connectObject;
        const totalHeight = rawData.length * DEFAULT_ROW_HEIGHT;
        const height = Number(scrollConfig?.y) || 0;

        return (
            <Grid
                className="virtual-grid"
                columnCount={mergedColumns.length}
                columnWidth={(index: number) => {
                    const {width} = mergedColumns[index] ?? {width: 0};

                    return totalHeight > height && index === mergedColumns.length - 1
                        ? (width as number) - scrollbarSize - 1
                        : (width as number);
                }}
                height={height}
                onScroll={({scrollLeft}: {scrollLeft: number}) => {
                    onScroll({scrollLeft});
                }}
                ref={gridRef}
                rowCount={rawData.length}
                rowHeight={getSize}
                width={tableWidth}
            >
                {({columnIndex, rowIndex, style}: GridChildComponentProps<DataType>) => (
                    <div
                        className={classNames(
                            styles.virtual_table__td__container,
                            mergedColumns[columnIndex]?.className
                        )}
                        key={String(columnIndex + rowIndex)}
                        style={{...style, height: getSize(rowIndex)}}
                    >
                        <VirtualizedTableRow index={rowIndex} resetVirtualGrid={resetRowGrid}>
                            {rawData[rowIndex]?.[mergedColumns[columnIndex]?.dataIndex as string | number] as ReactNode}
                        </VirtualizedTableRow>
                    </div>
                )}
            </Grid>
        );
    }

    useEffect(() => {
        // by default if we specify 'rowSelection' to antd's table, it inserts additional columns with checkbox
        // but since we do this manually (to make virtual scroll work), we should get rid of every antd's artifact
        // here we remove the <colgroup> span which sets the width for checkbox column
        const spans = document.querySelectorAll(`.${VIRTUAL_TABLE_CLASS} table > colgroup > .ant-table-selection-col`);

        spans.forEach((span) => span.remove());
    }, []);

    return (
        <VirtualTableContext.Provider value={contextValue}>
            <RcResizeObserver
                onResize={({width}) => {
                    setTableWidth(width);
                }}
            >
                <div>
                    <Table
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        className={VIRTUAL_TABLE_CLASS}
                        columns={mergedColumns}
                        components={{
                            header: {
                                wrapper: renderTableHeadWrapper,
                            },
                            body: renderVirtualList,
                        }}
                        dataSource={tableDataSource}
                        pagination={false}
                    />
                    {additionalFooter}
                </div>
            </RcResizeObserver>
        </VirtualTableContext.Provider>
    );
}
