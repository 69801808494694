import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';

import {
    FetchReviewConcordanceResultType,
    ReviewConcordanceRequestType,
    reviewConcordanceResponseSchema,
    ReviewConcordanceSentimentRequestType,
    reviewConcordanceSentimentResponseSchema,
    ReviewConcordanceSentimentType,
} from './review-concordance-type';

export async function fetchReviewConcordance(
    options: ReviewConcordanceRequestType,
    mainFilterKey: string
): Promise<FetchReviewConcordanceResultType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewConcordanceRequestType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/concordance/?${searchParameters}`;

    return fetchAndDeserialize<FetchReviewConcordanceResultType>(url, reviewConcordanceResponseSchema);
}

export async function fetchReviewConcordanceSentiment(
    options: ReviewConcordanceSentimentRequestType,
    mainFilterKey: string
): Promise<ReviewConcordanceSentimentType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewConcordanceSentimentRequestType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/concordance_sentiment/?${searchParameters}`;

    return fetchAndDeserialize<ReviewConcordanceSentimentType>(url, reviewConcordanceSentimentResponseSchema);
}
