import {faEllipsis} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../../../../../../../../../../component/text/text';
import {PostPreviewDetailsType} from '../../../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../../../util/css';
import {CompanyAvatar} from '../../../company-avatar/company-avatar';

import * as styles from './instagram-preview-header.scss';

type PropsType = {
    isOnlyVideo?: boolean;
    companyInfo?: PostPreviewDetailsType;
};

export function InstagramPreviewHeader(props: PropsType): JSX.Element {
    const {isOnlyVideo, companyInfo} = props;

    return (
        <div
            className={classNames(styles.InstagramPreviewHeader, {
                [styles.InstagramPreviewHeader_video]: isOnlyVideo,
            })}
        >
            <div className={styles.InstagramPreviewHeader_authorContainer}>
                <CompanyAvatar className={styles.InstagramPreviewHeader_avatar} logo={companyInfo?.logo} />
                <div className={styles.InstagramPreviewHeader_author}>
                    {companyInfo?.name ? (
                        <>
                            <Text bold ellipsis>
                                {companyInfo.name}
                            </Text>
                            <Text small>{companyInfo.name}</Text>
                        </>
                    ) : (
                        <>
                            <Text bold ellipsis stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__COMPANY_NICKNAME" />
                            <Text small stringKey="POSTS_FORM__PREVIEW__YANDEX__COMPANY_NAME" />
                        </>
                    )}
                </div>
            </div>
            <FontAwesomeIcon className={styles.InstagramPreviewHeader_action} icon={faEllipsis} />
        </div>
    );
}
