import {SorterResult} from 'antd/lib/table/interface';

const SORT_TABLE_STORAGE_KEY = 'tableSort';

export function getSortTableInitialState(
    storageKey: string | null
): {field: string | null; order: string | null} | null {
    const storedSortTableState = sessionStorage.getItem(SORT_TABLE_STORAGE_KEY);
    const parseResult = JSON.parse(storedSortTableState || '{}');

    if (parseResult.storageKey === storageKey) {
        return {field: parseResult.field, order: parseResult.order};
    }

    return null;
}

export function saveNewSortTableState<T>(storageKey: string, sorter: SorterResult<T> | Array<SorterResult<T>>): void {
    if (Array.isArray(sorter)) {
        return;
    }

    sessionStorage.setItem(
        SORT_TABLE_STORAGE_KEY,
        JSON.stringify({field: sorter.field, order: sorter.order, storageKey})
    );
}
