import {UsedNeuralNetworkSuggestionsCountType} from '../../../../service/reviews-and-answers-statistics/used-neural-network-suggestions-count/used-neural-network-suggestions-count-type';
import {StatisticsMetric} from '../statistics-metric/statistics-metric';

type PropsType = {
    pdfPreview?: boolean;
    isLoading?: boolean;
    isCompareMode: boolean;
    usedNeuralNetworkSuggestionsCount: UsedNeuralNetworkSuggestionsCountType | null;
};

export function UsedAiSuggests(props: PropsType): JSX.Element {
    const {pdfPreview, usedNeuralNetworkSuggestionsCount, isLoading, isCompareMode} = props;

    return (
        <StatisticsMetric
            compareData={
                usedNeuralNetworkSuggestionsCount?.count.comparedValue
                    ? {
                          value: usedNeuralNetworkSuggestionsCount.count.comparedValue,
                          delta: usedNeuralNetworkSuggestionsCount.count.delta || 0,
                      }
                    : null
            }
            isCompareMode={isCompareMode}
            isLoading={isLoading}
            mainData={{
                value: usedNeuralNetworkSuggestionsCount?.count?.value || 0,
                values: usedNeuralNetworkSuggestionsCount?.byDate || [],
            }}
            pdfPreview={pdfPreview}
            titleLangKeyType="REVIEW_AND_ANSWER_STATISTICS__USED_GPT_ANSWERS__TITLE"
        />
    );
}
