import {Checkbox} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';

import {TimeRangePicker} from '../../../../../../../../layout/time-range-picker/time-range-picker';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    TimeRangeType,
    WorkingDayType,
} from '../../../../../../../../service/company-v2/types/company-working-time-type';
import {classNames} from '../../../../../../../../util/css';
import {timeFormat} from '../../working-time-const';

import * as styles from './work-time.scss';

type PropsType = {
    className?: string;
    daily: boolean;
    timeRange: TimeRangeType;
    updateTime: (newTime: Pick<WorkingDayType, 'daily' | 'timeRange'>) => void;
};

export function WorkTime(props: PropsType): JSX.Element {
    const {className, daily, timeRange, updateTime} = props;
    const fullClassName = classNames(styles.work_time, className);
    const {getLocalizedString} = useLocale();

    function handleChangeTime(values: NoUndefinedRangeValueType<Dayjs> | null) {
        if (!values || !values[0] || !values[1]) {
            updateTime({daily, timeRange: {}});
            return;
        }

        const [dayjsFrom, dayjsTo] = values;

        updateTime({
            daily,
            timeRange: {
                start: dayjsFrom.format(timeFormat),
                finish: dayjsTo.format(timeFormat),
            },
        });
    }

    return (
        <div className={fullClassName}>
            <TimeRangePicker
                className={styles.work_time__time_range}
                disabled={daily}
                onChange={handleChangeTime}
                order={false}
                placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                value={
                    'start' in timeRange
                        ? [dayjs(timeRange.start, timeFormat), dayjs(timeRange.finish, timeFormat)]
                        : undefined // eslint-disable-line no-undefined
                }
            />

            <Checkbox
                checked={daily}
                className={styles.work_time__around_the_clock_checkbox}
                onChange={() => updateTime({daily: !daily, timeRange})}
            >
                <Locale stringKey="COMPANY_FORM__WORKING_TIME__AROUND_THE_CLOCK" />
            </Checkbox>
        </div>
    );
}
