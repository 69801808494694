import {lazy, memo, Suspense, useEffect, useMemo, useRef} from 'react';
import {CallbacksProp} from 'react-wordcloud';

import {noop} from '../../../util/function';

import {getWordCloudCallbacks, wordCloudOptions} from './word-cloud-helper';
import {WordCloudDataType, WordCloudWordType, WordExtendedType} from './word-cloud-type';
import * as styles from './word-cloud.scss';

type PropsType = {
    data: WordCloudDataType;
    maxWords: number;
    onProgressChange?: (isInProgress: boolean) => void;
    onWordClick?: (word: WordCloudWordType) => void;
};

const ReactWordCloud = lazy(() => import('react-wordcloud'));

export const WordCloud = memo(function WordCloudInner(props: PropsType): JSX.Element {
    const {data, maxWords, onProgressChange = noop, onWordClick} = props;

    const wordCloudRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data?.length > 0) {
            onProgressChange(true);
        }
    }, [data, onProgressChange]);

    useEffect(() => {
        function onElementChange(mutationsList: Array<MutationRecord>) {
            // The ReactWordCloud plugin render words as svg tags "text". Here we wait them to be shown.
            if (mutationsList.some((mutation: MutationRecord): boolean => mutation.target.nodeName === 'text')) {
                onProgressChange(false);
            }
        }

        if (wordCloudRef.current) {
            const observer = new MutationObserver(onElementChange);

            observer.observe(wordCloudRef.current, {childList: true, subtree: true});
            return () => observer.disconnect();
        }

        return () => noop;
    }, [onProgressChange]);

    const words: Array<WordExtendedType> = useMemo<Array<WordExtendedType>>(
        (): Array<WordExtendedType> =>
            data.map(({name, value, color}: WordCloudWordType) => ({
                text: name,
                value,
                color,
            })),
        [data]
    );

    const callbacks: CallbacksProp = useMemo<CallbacksProp>(
        (): CallbacksProp => getWordCloudCallbacks(onWordClick),
        [onWordClick]
    );

    return (
        <div className={styles.word_cloud} ref={wordCloudRef}>
            <Suspense fallback={<div>Loading...</div>}>
                <ReactWordCloud callbacks={callbacks} maxWords={maxWords} options={wordCloudOptions} words={words} />
            </Suspense>
        </div>
    );
});
