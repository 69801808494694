import {FC} from 'react';

import {DomainNameEnum} from '../../provider/domain-config/domain-config-type';

import {TwoGisRouting} from './routing/2gis/2gis-routing';
import {Routing} from './routing/routing';

export const ROUTING_BY_DOMAIN: Record<DomainNameEnum | string, FC> = {
    [DomainNameEnum.rocketData]: Routing,
    [DomainNameEnum.brandWizard]: Routing,
    [DomainNameEnum.twoGis]: TwoGisRouting,
};
