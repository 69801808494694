import {FormInstance} from 'antd';

import {
    NotificationTextReviewEnum,
    TelegramNotificationsType,
    TimeRangeType,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {findInArrayByValueEnsure} from '../../../../util/array';
import {TimeStringType} from '../../../../util/type';
import {textReviewTypeList} from '../notification-review-filter/notification-review-filter-const';
import {NotificationsReviewsFilterType} from '../notification-review-filter/notification-review-filter-type';

import {TelegramNotificationFormValuesType} from './notification-telegram-type';

export function serializeTelegramNotification(
    form: FormInstance<TelegramNotificationFormValuesType & NotificationsReviewsFilterType>
): TelegramNotificationsType {
    const values = form.getFieldsValue();

    const isNotificationsTurnedOn = values.send_messenger_notifications;

    let messenger_notifications_time_bucket: TimeRangeType | null = null;

    if (values.messenger_notifications_time_bucket) {
        messenger_notifications_time_bucket = {
            start: isNotificationsTurnedOn
                ? (values.messenger_notifications_time_bucket[0].format('HH:mm') as TimeStringType)
                : '00:00',
            end: isNotificationsTurnedOn
                ? (values.messenger_notifications_time_bucket[1].format('HH:mm') as TimeStringType)
                : '23:59',
        };
    }

    const messenger_notifications_catalogs = isNotificationsTurnedOn ? values.catalog : [];
    const messenger_notifications_ratings = isNotificationsTurnedOn ? values.ratings : [];
    const messenger_notifications_content_type = findInArrayByValueEnsure<NotificationTextReviewEnum>(
        textReviewTypeList,
        values.type
    );

    return {
        send_messenger_notifications: values.send_messenger_notifications,
        messenger_token: values.messenger_token,
        messenger_notifications_time_bucket,
        messenger_notifications_catalogs,
        messenger_notifications_ratings,
        messenger_notifications_content_type,
        send_messenger_omnichannel_notifications: values.send_messenger_omnichannel_notifications,
        send_messenger_review_responsible_user_notifications:
            values.send_messenger_review_responsible_user_notifications,
    };
}
