import {CallbacksProp, OptionsProp, Word} from 'react-wordcloud';

import * as cssVariableStyles from '../../../css/var-export.scss';

import {WordCloudWordType} from './word-cloud-type';

export const wordCloudOptions: OptionsProp = {
    enableTooltip: false,
    deterministic: true,
    enableOptimizations: true,
    fontFamily: 'arial',
    fontSizes: [10, 80],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 0,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000,
};

function setWordOpacity(event: MouseEvent | undefined, opacity: number) {
    const target = event?.target;

    if (target instanceof SVGTextElement) {
        target.setAttribute('style', `fill-opacity: ${opacity}`);
    }
}

function getWordColor(word: Word): string {
    return word?.color || cssVariableStyles.gray4;
}

export function getWordCloudCallbacks(onClick?: (word: WordCloudWordType) => void): CallbacksProp {
    if (!onClick) {
        return {getWordColor};
    }

    return {
        getWordColor,
        onWordMouseOver: (_word: Word, event?: MouseEvent): void => setWordOpacity(event, 0.8),
        onWordMouseOut: (_word: Word, event?: MouseEvent): void => setWordOpacity(event, 1),
        onWordClick: (word: Word): void => onClick({name: word.text, value: word.value, color: getWordColor(word)}),
    };
}
