import {Flex, Tooltip} from 'antd';
import {memo, useRef} from 'react';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/locale';
import {formatAddress} from '../../../../../../service/address/address-helper';
import {useReviewLogsList} from '../../../../../../service/reviews/reviews-logs';
import {classNames} from '../../../../../../util/css';
import {useReviewActivityHook} from '../../../review-activity-provider/review-activity-provider';
import {NewReviewAnswers} from '../../../review-answers/new-review-answers';
import {ReviewStatus} from '../../../review-status/review-status';
import {useReviewStateHook} from '../../../reviews-state/reviews-state-provider';
import {TagList} from '../../../tag-list/tag-list';
import {NewReviewNotes} from '../../new-review-notes/new-review-notes';
import {ReviewDataWithCatalogType} from '../review-list-type';

import {NewReviewComment} from './new-review-comment/new-review-comment';
import {ReviewHeader} from './review-header/review-header';
import * as styles from './new-review.scss';

type PropsType = {
    className?: string;
    reviewData: ReviewDataWithCatalogType;
};

export const NewReview = memo(function ReviewInner(props: PropsType): JSX.Element {
    const {className, reviewData} = props;

    const {refreshReview, refreshReviewsInfo, reviewsInfo, suggestionMap, refreshSuggestions, isNewLayout} =
        useReviewStateHook();
    const suggestion = suggestionMap[reviewData.id];

    const info = reviewsInfo?.results.find((reviewInfo) => reviewInfo.id === reviewData.id) || null;

    const {id, catalog, company, deletedAt, responsibleUser, brand, comment, processStatus, tags} = reviewData;

    const isReviewDeleted = Boolean(deletedAt);

    const {isHistoryVisible} = useReviewActivityHook();
    const {refetch: loadLogs} = useReviewLogsList(id, isHistoryVisible);

    const reviewRef = useRef<HTMLDivElement | null>(null);

    const isMobileWidthReview = Number(reviewRef?.current?.clientWidth) <= 500;

    const isGptSuggestSupported = catalog?.gpt_suggest_supported;

    const notesCount = info?.notesCount || 0;

    return (
        <div className={classNames(styles.Review, className)} ref={reviewRef}>
            <Flex vertical>
                <div className={styles.Review_generalWrapper}>
                    <ReviewHeader
                        info={info}
                        isMobileWidthReview={isMobileWidthReview}
                        isReviewDeleted={isReviewDeleted}
                        reviewData={reviewData}
                    />
                    {isMobileWidthReview && (
                        <ReviewStatus
                            className={styles.Review_status}
                            onChange={loadLogs}
                            processStatus={processStatus}
                            refreshReview={refreshReview}
                            reviewId={id}
                        />
                    )}
                    {!company ? (
                        <Text bolder lighter>
                            {brand?.name}
                        </Text>
                    ) : (
                        <Flex gap={4} wrap="wrap">
                            <Text bolder lighter>
                                {company?.name}
                            </Text>
                            <div className={styles.Review_catalogCompanyLine}>
                                <Text lighter>{formatAddress(company?.address || null)}</Text>
                                {company?.code && (
                                    <Tooltip
                                        placement="bottom"
                                        title={<Locale stringKey="REVIEWS__REVIEW__COMPANY_CODE__HINT" />}
                                    >
                                        <Text secondary>{company.code}</Text>
                                    </Tooltip>
                                )}
                            </div>
                        </Flex>
                    )}

                    <NewReviewComment comment={comment} reviewId={id} />
                    <TagList
                        deletedAt={deletedAt}
                        isNewLayout={isNewLayout}
                        refreshReview={refreshReview}
                        responsibleUser={responsibleUser}
                        reviewId={id}
                        tags={tags}
                    />
                    {notesCount > 0 && (
                        <NewReviewNotes notesCount={notesCount} onRefresh={refreshReviewsInfo} reviewId={id} />
                    )}
                </div>
                <div className={styles.Review_answerWrapper}>
                    <NewReviewAnswers
                        isCollapsedAnswers
                        isGptSuggestSupported={isGptSuggestSupported}
                        refreshReview={refreshReview}
                        refreshSuggestions={refreshSuggestions}
                        review={reviewData}
                        suggestion={suggestion}
                    />
                </div>
            </Flex>
        </div>
    );
});
