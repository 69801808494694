import {useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query';
import {RcFile} from 'antd/lib/upload';
import {PublicationContext} from 'centrifuge';
import {useCallback, useEffect, useState} from 'react';

import {useCentrifugeSubscription} from '../../provider/centrifuge/centrifuge-hook';
import {useLocale} from '../../provider/locale/locale-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useUser} from '../../provider/user/user-hook';
import {ApiError} from '../../util/error';
import {useRefreshId} from '../../util/hook';
import {defaultPageSize} from '../../util/pagination-hook/pagination-const';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationHookType} from '../../util/pagination-hook/pagination-hook-type';
import {IdNumberType} from '../../util/type';
import {useUrl} from '../../util/url-hook/url-hook';
import {useApiHooks} from '../api-hook/api-hook';
import {UploadFileResponseType} from '../file-upload/file-upload-type';

import {
    changeTechnicalSupportTicketSubscription,
    createNewTechnicalSupportTicketAnswer,
    createSupportTicket,
    deleteUploadedFile,
    fetchSupportTicketFields,
    fetchSupportTickets,
    fetchTechnicalSupportTicket,
    fetchTechnicalSupportTicketAnswers,
    reopenTechnicalSupportTicket,
    uploadTicketFile,
} from './technical-support-api';
import {
    getTechnicalSupportFilterInitialValues,
    getTechnicalSupportFilterQueries,
    getTechnicalSupportQueryKey,
    getTechnicalSupportStatusChannel,
    getTechnicalSupportUnreadPostsCountChannel,
    isResponseWithActualTicketId,
    isTicketStatusAction,
    isTicketUnreadCountAction,
    TECHNICAL_SUPPORT_FILTER_EMPTY,
} from './technical-support-helper';
import {
    CreateTicketMutationDataType,
    TechnicalSupportFieldsEnum,
    TechnicalSupportFilterHookType,
    TechnicalSupportFilterQueriesType,
    TechnicalSupportFilterType,
    TechnicalSupportMessageType,
    TechnicalSupportNewMessageType,
    TechnicalSupportTicketFieldsType,
    TechnicalSupportTicketResponseType,
    TechnicalSupportTicketsResponseType,
    UseTechnicalSupportTicketsHookType,
} from './technical-support-type';

export function useTechnicalSupportFields(): UseQueryResult<TechnicalSupportTicketFieldsType> & {
    getNameByValue: (field: TechnicalSupportFieldsEnum, value: string | number | boolean) => string | null;
} {
    const query = useQuery(['technical-support-fields'], fetchSupportTicketFields);

    const getNameByValue = useCallback(
        (field: TechnicalSupportFieldsEnum, value: string | number | boolean) => {
            const technicalSupportFieldValues:
                | Array<{
                      value: string | number | boolean;
                      name: string;
                  }>
                | undefined = query.data?.[field];

            if (Array.isArray(technicalSupportFieldValues)) {
                return technicalSupportFieldValues.find(({value: fieldValue}) => fieldValue === value)?.name || null;
            }

            return null;
        },
        [query.data]
    );

    return {
        ...query,
        getNameByValue,
    };
}

export function useTechnicalSupportTickets(filter: TechnicalSupportFilterType): UseTechnicalSupportTicketsHookType {
    const {getSubscription} = useCentrifugeSubscription();
    const {user} = useUser();
    const queryClient = useQueryClient();

    const pagination = usePagination({dependencies: filter, initialPageSize: 10});

    const parameters = {
        pagination: {
            page: pagination.page,
            pageSize: pagination.pageSize,
        },
    };

    const staticQueryKey = 'technical-support-tickets';

    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        function onActionReceived({data: publicationData}: PublicationContext) {
            if (isTicketStatusAction(publicationData) || isTicketUnreadCountAction(publicationData)) {
                queryClient.setQueryData<TechnicalSupportTicketsResponseType>(
                    [staticQueryKey, parameters, filter],
                    (oldData) => {
                        if (!oldData) {
                            return oldData;
                        }

                        const newResults = (oldData?.results || []).map((item) => {
                            if (item.id === publicationData.ticket_id) {
                                if (isTicketStatusAction(publicationData)) {
                                    return {
                                        ...item,
                                        status: publicationData.status,
                                    };
                                }

                                if (isTicketUnreadCountAction(publicationData)) {
                                    return {
                                        ...item,
                                        unreadPostsCount: publicationData.unread_posts_count,
                                    };
                                }
                            }

                            return item;
                        });

                        return {...oldData, results: [...newResults]};
                    }
                );
            }
        }

        const subscriptionUnreadPostsCount = getSubscription(getTechnicalSupportUnreadPostsCountChannel(user?.id));
        const subscriptionStatus = getSubscription(getTechnicalSupportStatusChannel(user?.id));

        subscriptionUnreadPostsCount?.on('publication', onActionReceived);
        subscriptionStatus?.on('publication', onActionReceived);

        return () => {
            subscriptionUnreadPostsCount?.removeListener('publication', onActionReceived);
            subscriptionStatus?.removeListener('publication', onActionReceived);
        };
    }, [filter, getSubscription, parameters, queryClient, user?.id]);

    const {data, isLoading, refetch} = useQuery<TechnicalSupportTicketsResponseType>(
        [staticQueryKey, parameters, filter],
        () => fetchSupportTickets(parameters, filter),
        {
            onSuccess(response) {
                pagination.onDataLoaded(response);
            },
        }
    );

    return {
        data,
        isLoading,
        pagination,
        refetch,
    };
}

export function useTechnicalSupportFilter(): TechnicalSupportFilterHookType {
    const {queries: technicalSupportQueries, setQuery} = useUrl<TechnicalSupportFilterQueriesType>();
    const [filter, setFilter] = useState<TechnicalSupportFilterType>(
        getTechnicalSupportFilterInitialValues(technicalSupportQueries)
    );

    const updateFilter = useCallback((newValues: Partial<TechnicalSupportFilterType>) => {
        setFilter((previousValues) => {
            return {...previousValues, ...newValues};
        });
    }, []);

    useEffect(() => {
        setQuery(getTechnicalSupportFilterQueries(filter));
    }, [filter, setQuery]);

    const resetFilter = useCallback(() => {
        setFilter(TECHNICAL_SUPPORT_FILTER_EMPTY);
    }, []);

    return {
        filter,
        updateFilter,
        resetFilter,
    };
}

export function useCreateTicket(): UseMutationResult<unknown, unknown, CreateTicketMutationDataType> {
    const queryClient = useQueryClient();

    return useMutation(
        ['create-support-ticket'],
        (options: CreateTicketMutationDataType) => createSupportTicket(options),
        {
            onSuccess() {
                queryClient.invalidateQueries(['technical-support-tickets']);
            },
        }
    );
}

export function useViewTechnicalSupportTicket(
    helpRequestId: number,
    isEnable: boolean,
    onNewestTicketInChainFound: (actualTicketId: number) => void
): UseQueryResult<TechnicalSupportTicketResponseType> {
    const {getSubscription} = useCentrifugeSubscription();
    const {user} = useUser();
    const queryClient = useQueryClient();

    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        function onActionReceived({data: publicationData}: PublicationContext) {
            if (isTicketStatusAction(publicationData) || isTicketUnreadCountAction(publicationData)) {
                queryClient.setQueryData<TechnicalSupportTicketResponseType>(
                    getTechnicalSupportQueryKey(helpRequestId),
                    (oldData) => {
                        if (!oldData) {
                            return oldData;
                        }

                        if (oldData.id === publicationData.ticket_id) {
                            if (isTicketStatusAction(publicationData)) {
                                return {
                                    ...oldData,
                                    status: publicationData.status,
                                };
                            }

                            if (isTicketUnreadCountAction(publicationData)) {
                                return {
                                    ...oldData,
                                    unreadPostsCount: publicationData.unread_posts_count,
                                };
                            }
                        }

                        return oldData;
                    }
                );
            }
        }

        const subscriptionUnreadPostsCount = getSubscription(getTechnicalSupportUnreadPostsCountChannel(user?.id));
        const subscriptionStatus = getSubscription(getTechnicalSupportStatusChannel(user?.id));

        subscriptionUnreadPostsCount?.on('publication', onActionReceived);
        subscriptionStatus?.on('publication', onActionReceived);

        return () => {
            subscriptionUnreadPostsCount?.removeListener('publication', onActionReceived);
            subscriptionStatus?.removeListener('publication', onActionReceived);
        };
    }, [getSubscription, helpRequestId, queryClient, user?.id]);

    return useQuery(
        getTechnicalSupportQueryKey(helpRequestId),
        async () => {
            try {
                return await fetchTechnicalSupportTicket(helpRequestId);
            } catch (error) {
                if (error instanceof ApiError && isResponseWithActualTicketId(error.jsonData)) {
                    onNewestTicketInChainFound(error.jsonData.last_child_id);
                }

                return null;
            }
        },
        {
            enabled: isEnable,
            keepPreviousData: true,
        }
    );
}

export function useTechnicalSupportReopenTicket(options: {
    helpRequestId: number;
    onTicketReopen: (newTicketId: number) => void;
}): UseMutationResult<IdNumberType, unknown, void> {
    const {helpRequestId, onTicketReopen} = options;
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const queryClient = useQueryClient();

    return useMutation(['reopen-support-ticket', helpRequestId], () => reopenTechnicalSupportTicket(helpRequestId), {
        onSuccess: (result) => {
            onTicketReopen(result.id);
            snackbar.success(getLocalizedString('HELP_TECHNICAL_SUPPORT__REOPEN_TICKET__SUCCESS'));
            queryClient.refetchQueries(getTechnicalSupportQueryKey(helpRequestId));
        },
        onError: () => {
            snackbar.error(getLocalizedString('HELP_TECHNICAL_SUPPORT__REOPEN_TICKET__ERROR'));
        },
    });
}

export function useChangeTechnicalSupportTicketSubscription(
    helpRequestId: number
): UseMutationResult<void, unknown, boolean> {
    const queryClient = useQueryClient();

    return useMutation(
        ['watch-support-ticket', helpRequestId],
        (isSubscribed: boolean) =>
            changeTechnicalSupportTicketSubscription({
                helpRequestId,
                isSubscribed,
            }),
        {
            onSuccess: (_data, newValue) => {
                queryClient.setQueryData(getTechnicalSupportQueryKey(helpRequestId), {
                    ...queryClient.getQueryData(getTechnicalSupportQueryKey(helpRequestId)),
                    isSubscribed: newValue,
                });
            },
        }
    );
}

export function useTechnicalSupportMessages(
    helpRequestId: number,
    refreshTicket: VoidFunction
): {
    messages: Array<TechnicalSupportMessageType> | null;
    isLoading: boolean;
    hasMoreItems: boolean;
    pagination: PaginationHookType;
    isCreatingMessage: boolean;
    createNewMessage: (data: TechnicalSupportNewMessageType) => Promise<void>;
} {
    const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);
    const [isCreatingMessage, setIsCreatingMessage] = useState<boolean>(false);
    const {isInProgress, setIsInProgress} = useApiHooks<Array<TechnicalSupportMessageType>>();
    const [result, setResult] = useState<Array<TechnicalSupportMessageType> | null>(null);
    const {refresh, refreshId} = useRefreshId();

    const pagination = usePagination({
        dependencies: refreshId,
        shouldSaveState: false,
        initialPageSize: defaultPageSize,
    });

    const createNewMessage = useCallback(
        (data: TechnicalSupportNewMessageType) => {
            setIsCreatingMessage(true);
            return createNewTechnicalSupportTicketAnswer(helpRequestId, data)
                .then(() => {
                    pagination.onChange(1);
                    refresh();
                    refreshTicket();
                })
                .finally(() => {
                    setIsCreatingMessage(false);
                });
        },
        [helpRequestId, pagination, refresh, refreshTicket]
    );

    const loadSupportMessages = useCallback(async () => {
        if (!isInProgress) {
            try {
                const messagesResponse = await fetchTechnicalSupportTicketAnswers(helpRequestId, pagination);

                setHasMoreItems(Boolean(messagesResponse?.pages && messagesResponse.pages > pagination.page));

                setResult((previousResult) => {
                    const allMessages =
                        !previousResult || pagination.page === 1
                            ? messagesResponse.results
                            : [...messagesResponse.results, ...previousResult];
                    const messageIds = [...new Set(allMessages.map(({id}) => id))];

                    return messageIds
                        .map((messageId) => allMessages.find(({id}) => messageId === id))
                        .filter(Boolean)
                        .sort((message1, message2) => {
                            return new Date(message1.created).getTime() - new Date(message2.created).getTime();
                        });
                });
            } finally {
                setIsInProgress(false);
            }
        }
    }, [helpRequestId, isInProgress, pagination, setIsInProgress, setResult]);

    useEffect(() => {
        loadSupportMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.refreshId]);

    return {
        messages: result,
        isLoading: isInProgress,
        isCreatingMessage,
        hasMoreItems,
        pagination,
        createNewMessage,
    };
}

export function useUploadTicketFile(): UseMutationResult<UploadFileResponseType, unknown, RcFile> {
    return useMutation(['upload-ticket-file'], async (file: RcFile) => {
        const response = await uploadTicketFile(file);

        return {
            url: response.fileS3,
            name: response.fileName,
            id: response.tempId,
        };
    });
}

export function useDeleteUploadedFile(): UseMutationResult<unknown, unknown, string> {
    return useMutation(['delete-uploaded-ticket-file'], (id: string) => deleteUploadedFile(id));
}
