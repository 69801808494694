import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {UseMutationOptions} from '@tanstack/react-query/src/types';
import {useCallback, useContext, useEffect, useMemo, useReducer} from 'react';

import {defaultFilterState} from '../../page/main/online-presence/online-presence-filter-v2/online-presence-filter-v2-const';
import {MainFilterContext} from '../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../provider/main-filter/main-filter-type';
import {useUser} from '../../provider/user/user-hook';
import {DateAggregationEnum, DatePeriodEnum, getCloserFullMonth, getIsoYyyyMmDdString} from '../../util/date';
import {getEnumValueEnsure} from '../../util/enum';
import {NeverError} from '../../util/error';
import {useFormat} from '../../util/format-hook/format-hook';
import {useRefreshId} from '../../util/hook';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PROVIDER_TO_ITS_ID_MAP} from '../../util/type';
import {useUrl} from '../../util/url-hook/url-hook';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    fetchExportAnalytics,
    fetchOnlinePresenceDashboardData,
    fetchOnlinePresenceLocations,
    fetchOnlinePresenceSyncStatistic,
    fetchOnlinePresenceTrialDashboardData,
    getOnlinePresencePhrases,
} from './online-presence-api';
import {initialAggregation, SEARCH_PHRASES_DELAY_DAYS} from './online-presence-const';
import {
    getAnalyticsRangeByPeriodAndDate,
    getComparePeriod,
    getDefaultPeriod,
    getInitialPeriod,
    getLastAvailableAnalyticsDay,
    getOnlinePresenceAggregationBySelectedPeriod,
} from './online-presence-helper';
import {
    ExportExcelType,
    FormattedPeriodsType,
    LocationsAnalyticsTableDataType,
    LocationsParametersType,
    LocationTransformedCatalogType,
    OnlinePresenceCompareV2Enum,
    onlinePresenceDashboardCommonSchema,
    OnlinePresenceDashboardDataCommonType,
    OnlinePresenceDashboardDataHookType,
    onlinePresenceDashboardGoogleSchema,
    OnlinePresenceFilterActionsEnum,
    OnlinePresenceFilterDispatcherType,
    OnlinePresenceFilterUpdateActionType,
    OnlinePresenceFilterV2HookType,
    OnlinePresenceGoogleDataType,
    OnlinePresencePhrasesHookDataType,
    OnlinePresencePhrasesHookType,
    OnlinePresenceSynchronizationType,
    OnlinePresenceTrialDashboardType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterQueriesType,
    OnlinePresenceV2FilterStateType,
    OnlinePresenceWgisDataType,
    onlinePresenceWGisSchema,
    UseOnlinePresenceLocationsType,
} from './online-presence-type';

export function useOnlinePresenceV2Filter(mode: 'default' | 'phrases' = 'default'): OnlinePresenceFilterV2HookType {
    const {queries: onlinePresenceQueries, setQuery: setOnlinePresenceQuery} =
        useUrl<OnlinePresenceV2FilterQueriesType>();

    const isPhrasesMode = mode === 'phrases';

    const {getFormattedDateTime} = useFormat();

    const lastAvailableAnalyticsDay = useMemo(() => {
        if (isPhrasesMode) {
            const {end} = getCloserFullMonth(getLastAvailableAnalyticsDay(SEARCH_PHRASES_DELAY_DAYS));

            return end;
        }

        return getLastAvailableAnalyticsDay();
    }, [isPhrasesMode]);

    // used to refresh nested components only when
    const {refreshId, refresh} = useRefreshId();

    // eslint-disable-next-line sonarjs/cognitive-complexity,complexity,max-statements
    function reducer(
        state: OnlinePresenceV2FilterStateType,
        action: OnlinePresenceFilterUpdateActionType
    ): OnlinePresenceV2FilterStateType {
        switch (action.type) {
            case OnlinePresenceFilterActionsEnum.UpdatePeriod: {
                const updatedState = {...state, [OnlinePresenceV2FilterQueriesEnum.Period]: action.payload};

                if (action.payload === DatePeriodEnum.Custom) {
                    return updatedState;
                }

                const newMainPeriod = getAnalyticsRangeByPeriodAndDate(lastAvailableAnalyticsDay, action.payload);

                return reducer(updatedState, {
                    type: OnlinePresenceFilterActionsEnum.UpdateMainPeriod,
                    payload: {
                        [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: newMainPeriod.start,
                        [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: newMainPeriod.end,
                    },
                });
            }
            case OnlinePresenceFilterActionsEnum.UpdateMainPeriod: {
                const newComparePeriod = getComparePeriod(
                    {
                        start: action.payload[OnlinePresenceV2FilterQueriesEnum.PeriodStart],
                        end: action.payload[OnlinePresenceV2FilterQueriesEnum.PeriodEnd],
                    },
                    state[OnlinePresenceV2FilterQueriesEnum.Period],
                    state[OnlinePresenceV2FilterQueriesEnum.CompareMode],
                    true
                );

                const newAggregationPeriod = getOnlinePresenceAggregationBySelectedPeriod({
                    start: action.payload[OnlinePresenceV2FilterQueriesEnum.PeriodStart],
                    end: action.payload[OnlinePresenceV2FilterQueriesEnum.PeriodEnd],
                });

                return {
                    ...state,
                    ...action.payload,
                    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: newComparePeriod.start,
                    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: newComparePeriod.end,
                    [OnlinePresenceV2FilterQueriesEnum.Aggregation]: newAggregationPeriod,
                };
            }
            case OnlinePresenceFilterActionsEnum.UpdateComparePeriod: {
                return {
                    ...state,
                    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]:
                        action.payload[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart],
                    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]:
                        action.payload[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd],
                };
            }
            case OnlinePresenceFilterActionsEnum.UpdateCompareMode: {
                const newComparePeriod = getComparePeriod(
                    {
                        start: state[OnlinePresenceV2FilterQueriesEnum.PeriodStart],
                        end: state[OnlinePresenceV2FilterQueriesEnum.PeriodEnd],
                    },
                    state[OnlinePresenceV2FilterQueriesEnum.Period],
                    action.payload,
                    true
                );

                return {
                    ...state,
                    [OnlinePresenceV2FilterQueriesEnum.CompareMode]: action.payload,
                    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: newComparePeriod.start,
                    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: newComparePeriod.end,
                };
            }
            case OnlinePresenceFilterActionsEnum.UpdateAggregation: {
                return {
                    ...state,
                    [OnlinePresenceV2FilterQueriesEnum.Aggregation]: action.payload,
                };
            }
            case OnlinePresenceFilterActionsEnum.Init: {
                let result = state;

                const period = getInitialPeriod(
                    onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.Period],
                    isPhrasesMode
                );

                result = reducer(result, {
                    type: OnlinePresenceFilterActionsEnum.UpdatePeriod,
                    payload: period,
                });

                if (period === DatePeriodEnum.Custom) {
                    const periodStartRaw = onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.PeriodStart];
                    const periodEndRaw = onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.PeriodEnd];

                    result = reducer(result, {
                        type: OnlinePresenceFilterActionsEnum.UpdateMainPeriod,
                        payload: {
                            [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: periodStartRaw
                                ? new Date(periodStartRaw)
                                : lastAvailableAnalyticsDay,
                            [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: periodEndRaw
                                ? new Date(periodEndRaw)
                                : lastAvailableAnalyticsDay,
                        },
                    });
                }

                const aggregation = getEnumValueEnsure<DateAggregationEnum>(
                    DateAggregationEnum,
                    onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.Aggregation],
                    initialAggregation
                );

                result = reducer(result, {
                    type: OnlinePresenceFilterActionsEnum.UpdateAggregation,
                    payload: aggregation,
                });

                const compareMode = getEnumValueEnsure<OnlinePresenceCompareV2Enum | null>(
                    OnlinePresenceCompareV2Enum,
                    onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.CompareMode],
                    null
                );

                if (compareMode) {
                    result = reducer(result, {
                        type: OnlinePresenceFilterActionsEnum.UpdateCompareMode,
                        payload: compareMode,
                    });
                } else {
                    const comparePeriodStartRaw =
                        onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart];
                    const comparePeriodEndRaw =
                        onlinePresenceQueries[OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd];

                    result = reducer(result, {
                        type: OnlinePresenceFilterActionsEnum.UpdateComparePeriod,
                        payload: {
                            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: comparePeriodStartRaw
                                ? new Date(comparePeriodStartRaw)
                                : null,
                            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: comparePeriodEndRaw
                                ? new Date(comparePeriodEndRaw)
                                : null,
                        },
                    });
                }

                return result;
            }
            default:
                throw new NeverError(action);
        }
    }

    const [filter, dispatchFilter] = useReducer(
        reducer,
        reducer(defaultFilterState, {
            type: OnlinePresenceFilterActionsEnum.Init,
        })
    );

    const refreshingDispatcher: OnlinePresenceFilterDispatcherType = useCallback(
        (action: OnlinePresenceFilterUpdateActionType) => {
            dispatchFilter(action);
            refresh();
        },
        [refresh]
    );

    const memoizedFilter = useMemo(() => {
        return filter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId]);

    const formattedPeriods: FormattedPeriodsType = useMemo(() => {
        const {
            [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: currentPeriodStart,
            [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: currentPeriodEnd,
            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: currentComparePeriodStart,
            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: currentComparePeriodEnd,
        } = filter;

        const currentPeriod = `${getFormattedDateTime(currentPeriodStart)}-${getFormattedDateTime(currentPeriodEnd)}`;

        const comparedPeriod =
            currentComparePeriodStart && currentComparePeriodEnd
                ? `${getFormattedDateTime(currentComparePeriodStart)}-${getFormattedDateTime(currentComparePeriodEnd)}`
                : '';

        return {
            currentPeriod,
            comparedPeriod,
        };
    }, [filter, getFormattedDateTime]);

    useEffect(() => {
        const {
            [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: currentPeriodStart,
            [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: currentPeriodEnd,
            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: currentComparePeriodStart,
            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: currentComparePeriodEnd,
            [OnlinePresenceV2FilterQueriesEnum.Period]: currentPeriod,
            [OnlinePresenceV2FilterQueriesEnum.Aggregation]: currentAggregation,
            [OnlinePresenceV2FilterQueriesEnum.CompareMode]: currentCompareMode,
        } = filter;

        setOnlinePresenceQuery({
            [OnlinePresenceV2FilterQueriesEnum.PeriodStart]:
                currentPeriod === DatePeriodEnum.Custom ? getIsoYyyyMmDdString(currentPeriodStart) : '',
            [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]:
                currentPeriod === DatePeriodEnum.Custom ? getIsoYyyyMmDdString(currentPeriodEnd) : '',
            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]:
                currentCompareMode === OnlinePresenceCompareV2Enum.Custom && currentComparePeriodStart
                    ? getIsoYyyyMmDdString(currentComparePeriodStart)
                    : '',
            [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]:
                currentCompareMode === OnlinePresenceCompareV2Enum.Custom && currentComparePeriodEnd
                    ? getIsoYyyyMmDdString(currentComparePeriodEnd)
                    : '',
            [OnlinePresenceV2FilterQueriesEnum.Period]:
                currentPeriod !== getDefaultPeriod(isPhrasesMode) ? currentPeriod : '',
            [OnlinePresenceV2FilterQueriesEnum.Aggregation]:
                currentAggregation !==
                getOnlinePresenceAggregationBySelectedPeriod({
                    start: currentPeriodStart,
                    end: currentPeriodEnd,
                })
                    ? currentAggregation
                    : '',
            [OnlinePresenceV2FilterQueriesEnum.CompareMode]: currentCompareMode || '',
        });
    }, [filter, isPhrasesMode, setOnlinePresenceQuery]);

    return {
        filter: memoizedFilter,
        dispatchFilter: refreshingDispatcher,
        isCompareMode: Boolean(filter[OnlinePresenceV2FilterQueriesEnum.CompareMode]),
        formattedPeriods,
    };
}

export function useOnlinePresenceSearchPhrases(filter: OnlinePresenceV2FilterStateType): OnlinePresencePhrasesHookType {
    const {result, isInProgress, processError, setResult, setIsInProgress, setProcessError} =
        useApiHooks<OnlinePresencePhrasesHookDataType>();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const paginationOptions = useMemo(() => {
        return {
            filter,
            mainFilterKey,
        };
    }, [filter, mainFilterKey]);

    const pagination = usePagination({
        dependencies: paginationOptions,
    });

    const {refreshId, onDataLoaded, onDataLoadFailed, page, pageSize} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);
        setResult(null);
        getOnlinePresencePhrases(filter, mainFilterKey, {
            page,
            pageSize,
        })
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId]);

    return {
        result,
        isInProgress,
        processError,
        pagination,
    };
}

function useOnlinePresenceDashboard<DataType extends Partial<OnlinePresenceDashboardDataCommonType>>(
    fetchFunction: () => Promise<DataType>
): OnlinePresenceDashboardDataHookType<DataType> {
    const {result, isInProgress, processError, setResult, setIsInProgress, setProcessError} = useApiHooks<DataType>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);
        setResult(null);
        fetchFunction()
            .then((response: DataType) => {
                setResult(response);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error) => {
                setProcessError(error);
            });
    }, [fetchFunction, setIsInProgress, setProcessError, setResult]);

    return {
        result,
        isInProgress,
        processError,
    };
}

export function useOnlinePresenceV2Dashboard(
    filter: OnlinePresenceV2FilterStateType
): OnlinePresenceDashboardDataHookType<OnlinePresenceDashboardDataCommonType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const fetchFunction = useCallback(() => {
        return fetchOnlinePresenceDashboardData({
            filter,
            mainFilterKey,
            apiPath: 'cp/online_presence/main_metrics',
            schema: onlinePresenceDashboardCommonSchema,
        });
    }, [filter, mainFilterKey]);

    return useOnlinePresenceDashboard(fetchFunction);
}

export function useOnlinePresenceTrialDashboard(): UseQueryResult<OnlinePresenceTrialDashboardType> {
    return useQuery(['online-presence-trial-dashboard'], () => fetchOnlinePresenceTrialDashboardData(), {
        retry: 0,
    });
}

export function useOnlinePresenceV2YandexStats(
    filter: OnlinePresenceV2FilterStateType
): OnlinePresenceDashboardDataHookType<OnlinePresenceDashboardDataCommonType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const fetchFunction = useCallback(() => {
        return fetchOnlinePresenceDashboardData({
            filter,
            mainFilterKey,
            apiPath: 'cp/online_presence/yandex',
            schema: onlinePresenceDashboardCommonSchema,
        });
    }, [filter, mainFilterKey]);

    return useOnlinePresenceDashboard(fetchFunction);
}

export function useOnlinePresenceWGisStats(
    filter: OnlinePresenceV2FilterStateType
): OnlinePresenceDashboardDataHookType<OnlinePresenceWgisDataType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const fetchFunction = useCallback(() => {
        return fetchOnlinePresenceDashboardData<OnlinePresenceWgisDataType>({
            filter,
            mainFilterKey,
            apiPath: 'cp/online_presence/double_gis',
            schema: onlinePresenceWGisSchema,
        });
    }, [filter, mainFilterKey]);

    return useOnlinePresenceDashboard<OnlinePresenceWgisDataType>(fetchFunction);
}

export function useOnlinePresenceGoogleStats(
    filter: OnlinePresenceV2FilterStateType
): OnlinePresenceDashboardDataHookType<OnlinePresenceGoogleDataType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const fetchFunction = useCallback(() => {
        return fetchOnlinePresenceDashboardData<OnlinePresenceGoogleDataType>({
            filter,
            mainFilterKey,
            apiPath: 'cp/online_presence/google',
            schema: onlinePresenceDashboardGoogleSchema,
        });
    }, [filter, mainFilterKey]);

    return useOnlinePresenceDashboard<OnlinePresenceGoogleDataType>(fetchFunction);
}

export function useOnlinePresenceFacebookStats(
    filter: OnlinePresenceV2FilterStateType
): OnlinePresenceDashboardDataHookType<OnlinePresenceDashboardDataCommonType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const fetchFunction = useCallback(() => {
        return fetchOnlinePresenceDashboardData({
            filter,
            mainFilterKey,
            apiPath: 'cp/online_presence/facebook',
            schema: onlinePresenceDashboardCommonSchema,
        });
    }, [filter, mainFilterKey]);

    return useOnlinePresenceDashboard(fetchFunction);
}

export function useOnlinePresenceSyncStatistic(
    date: Date
): Omit<UseHookType<OnlinePresenceSynchronizationType>, 'reset'> {
    const {result, isInProgress, processError, setResult, setIsInProgress, setProcessError} =
        useApiHooks<OnlinePresenceSynchronizationType>();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);
        setResult(null);
        fetchOnlinePresenceSyncStatistic(date, mainFilterKey)
            .then((response: OnlinePresenceSynchronizationType) => {
                setResult(response);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error) => {
                setProcessError(error);
            });
    }, [date, mainFilterKey, setIsInProgress, setProcessError, setResult]);

    return {
        result,
        isInProgress,
        processError,
    };
}

export function useOnlinePresenceLocations(filter: OnlinePresenceV2FilterStateType): UseOnlinePresenceLocationsType {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {user} = useUser();

    const paginationOptions = useMemo(() => {
        return {
            filter,
            mainFilterKey,
        };
    }, [filter, mainFilterKey]);

    const pagination = usePagination({
        dependencies: paginationOptions,
    });

    const parameters: LocationsParametersType = {
        filter,
        mainFilterKey,
        pagination: {
            page: pagination.page,
            pageSize: pagination.pageSize,
        },
    };

    const {data, isLoading} = useQuery(
        ['locations-analytics', parameters],
        () => fetchOnlinePresenceLocations(parameters),
        {
            onSuccess(response) {
                pagination.onDataLoaded(response);
            },
        }
    );

    const availableCatalogs = new Set(data?.availableCatalogs);

    const transformedData: Array<LocationsAnalyticsTableDataType> = (data?.results || []).map((item) => {
        const catalogsObject = item.catalogs
            .filter((catalog) => {
                return (
                    availableCatalogs.has(catalog.catalog) &&
                    user?.regionAvailableCatalogs[PROVIDER_TO_ITS_ID_MAP[catalog.catalog]]
                );
            })
            .reduce((result, values) => {
                // eslint-disable-next-line no-param-reassign
                result[values.catalog] = values;
                return result;
            }, {} as LocationTransformedCatalogType);

        return {
            companyName: item.companyName,
            companyAddress: item.companyAddress,
            companyId: item.companyId,
            ...catalogsObject,
        };
    });

    return {
        data: transformedData,
        isLoading,
        pagination,
        availableCatalogs,
    };
}

export function useExportAnalytics(
    options?: UseMutationOptions<unknown, unknown, ExportExcelType>
): UseMutationResult<unknown, unknown, ExportExcelType> {
    return useMutation(
        ['export-analytics'],
        (parameters: ExportExcelType) => fetchExportAnalytics(parameters),
        options
    );
}

export function useOnlinePresenceFormattedLabels(
    labels: Array<string> | undefined,
    comparedLabels: Array<string> | undefined
): {labels: Array<string>; comparedLabels: Array<string>} {
    const {getFormattedDateTime} = useFormat();

    const formattedLabels = labels?.map((label) => getFormattedDateTime(new Date(label))) || [];
    const formattedComparedLabels = comparedLabels?.map((label) => getFormattedDateTime(new Date(label))) || [];

    return {
        labels: formattedLabels,
        comparedLabels: formattedComparedLabels,
    };
}
