import {Button, Checkbox, Divider, Select, Space, Tabs} from 'antd';
import {Dispatch, SetStateAction, useContext, useEffect} from 'react';

import {appRoute} from '../../../../../../../../app-route';
import {Text} from '../../../../../../../../component/text/text';
import {BreadCrumbs} from '../../../../../../../../layout/bread-crumbs/bread-crumbs';
import {PageCard} from '../../../../../../../../layout/page-card/page-card';
import {AsciiSpinner} from '../../../../../../../../layout/spinner/ascii-spinner';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSystem} from '../../../../../../../../provider/system/system-hook';
import {GoogleCclStatusEnum} from '../../../../../../../../service/google/google-type';
import {PageHeader} from '../../../../common/page-header/page-header';
import {GoogleAccountSyncContext} from '../../../../google-account-sync-context';
import {GoogleConfirmationTabEnum} from '../google-confirmations-type';

import * as styles from './confirmations-header.scss';

type PropsType = {
    confirmationsLength?: number;
    requestsLength?: number;
    activeTab: GoogleConfirmationTabEnum;
    setActiveTab: (tab: GoogleConfirmationTabEnum) => void;
    statusFilter: Array<GoogleCclStatusEnum>;
    setStatusFilter: Dispatch<SetStateAction<Array<GoogleCclStatusEnum>>>;
    showCompletedRequests: boolean;
    setShowCompletedRequests: Dispatch<SetStateAction<boolean>>;
};

export function ConfirmationsHeader(props: PropsType): JSX.Element {
    const {
        confirmationsLength,
        requestsLength,
        activeTab,
        setActiveTab,
        statusFilter,
        setStatusFilter,
        showCompletedRequests,
        setShowCompletedRequests,
    } = props;

    const {setHeaderButtons, goToSources} = useContext(GoogleAccountSyncContext);
    const {getLocalizedString} = useLocale();
    const {screen} = useSystem();

    useEffect(() => {
        setHeaderButtons(() => (
            <Button onClick={() => goToSources()}>
                <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__BUTTON__BACK" />
            </Button>
        ));
    }, [goToSources, setHeaderButtons]);

    return (
        <PageCard>
            <BreadCrumbs
                list={[
                    {
                        path: appRoute.sources.path,
                        titleLangKey: 'CATEGORY_NAME__SOURCES',
                    },
                    {
                        path: appRoute.sourcesAccountSyncGoogleConfirmations.path,
                        titleLangKey: 'GOOGLE_SYNC__CONFIRMATIONS__TITLE',
                    },
                ]}
            />

            <PageHeader titleLangKey="GOOGLE_SYNC__CONFIRMATIONS__TITLE" />

            <Divider className={styles.Divider} />

            <Tabs
                activeKey={activeTab.toString()}
                className={styles.Tabs}
                items={[
                    {
                        key: GoogleConfirmationTabEnum.Single.toString(),
                        label: (
                            <Locale
                                stringKey="GOOGLE_SYNC__CONFIRMATIONS__SINGLE__TITLE"
                                valueMap={{count: confirmationsLength ?? <AsciiSpinner />}}
                            />
                        ),
                    },
                    {
                        key: GoogleConfirmationTabEnum.Request.toString(),
                        label: (
                            <Locale
                                stringKey="GOOGLE_SYNC__CONFIRMATIONS__REQUEST__TITLE"
                                valueMap={{count: requestsLength ?? <AsciiSpinner />}}
                            />
                        ),
                    },
                ]}
                onTabClick={(tab) => setActiveTab(Number(tab))}
                size={screen.isMobile ? 'middle' : 'large'}
            />

            {activeTab === GoogleConfirmationTabEnum.Single && statusFilter && (
                <Space className={styles.Filters} size="middle">
                    <span>
                        <Locale stringKey="SOURCES__TABLE__STATUS" />:
                    </span>

                    <Select
                        allowClear
                        className={styles.Filters_select}
                        mode="multiple"
                        onChange={setStatusFilter}
                        options={[
                            {
                                value: GoogleCclStatusEnum.AwaitingConfirmation,
                                label: (
                                    <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_CONFIRMATION" />
                                ),
                            },
                            {
                                value: GoogleCclStatusEnum.AwaitingRights,
                                label: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_RIGHTS" />,
                            },
                            {
                                value: GoogleCclStatusEnum.AwaitingVerification,
                                label: <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__AWAITING_VERIFICATION" />,
                            },
                        ]}
                        placeholder={`${getLocalizedString('SOURCES__TABLE__STATUS')}...`}
                        popupMatchSelectWidth={false}
                        value={statusFilter}
                    />
                </Space>
            )}

            {activeTab === GoogleConfirmationTabEnum.Request && (
                <Space className={styles.Filters} size="large">
                    <Text
                        stringKey="GOOGLE_SYNC__CONFIRMATIONS__REQUESTS"
                        valueMap={{
                            count: (
                                <Text bolder className={styles.Filters_requestsLength}>
                                    {requestsLength}
                                </Text>
                            ),
                        }}
                    />

                    <Checkbox
                        checked={showCompletedRequests}
                        onChange={(event) => setShowCompletedRequests(event.target.checked)}
                    >
                        <Text stringKey="GOOGLE_SYNC__CONFIRMATIONS__SHOW_COMPLETED" />
                    </Checkbox>
                </Space>
            )}
        </PageCard>
    );
}
