import {useEffect} from 'react';

import {
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
} from '../../../../../service/yandex-stories/yandex-stories-type';
import {Form, FormInstance} from '../../../../../typings/antd';
import {YANDEX_CONTENT_STEP_WIDTH} from '../../yandex-story-form-helper';

import {YandexStoryFormSlidesContentStep} from './slides-content-step/yandex-story-form-slides-content-step';
import {YandexStoryUploadImagesStep} from './upload-images-step/yandex-story-upload-images-step';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    onStepFinished: VoidFunction;
    onPreviousStep: VoidFunction;
    isFirstStep: boolean;
    onStepActivated: (width: number) => void;
};

export function YandexStoryFormContentStep(props: PropsType): JSX.Element {
    const {formInstance, onStepFinished, onPreviousStep, isFirstStep, onStepActivated} = props;

    useEffect(() => {
        onStepActivated(YANDEX_CONTENT_STEP_WIDTH);
    }, [onStepActivated]);

    const slides = Form.useWatch([YandexStoryFormFieldsEnum.Slides], {
        preserve: true,
        form: formInstance,
    });

    if (Object.keys(slides || {}).length === 0) {
        return (
            <YandexStoryUploadImagesStep
                formInstance={formInstance}
                isFirstStep={isFirstStep}
                onPreviousStep={onPreviousStep}
            />
        );
    }

    return (
        <YandexStoryFormSlidesContentStep
            formInstance={formInstance}
            isFirstStep={isFirstStep}
            onPreviousStep={onPreviousStep}
            onStepFinished={onStepFinished}
        />
    );
}
