import {Form, Input, Popover, Select, Tag} from 'antd';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {
    LocalPackPointDistanceEnum,
    LocalPackSquareSizeEnum,
} from '../../../../../../service/local-pack/local-pack-type';
import {useLocalPackSearchServices} from '../../../../../../service/local-pack/rules/local-pack-rule-hook';
import {
    LocalPackRuleFrequencyEnum,
    LocalPackRuleListItemType,
    LocalPackRuleStatusEnum,
} from '../../../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {LOCALPACK_FREQUENCY_LOCALIZATION_MAP} from '../../../local-pack-const';
import {SelectServicesField} from '../../../local-pack-rule-form/select-services/select-services-field';

import {LOCALPACK_RULE_STATUS_OPTIONS} from './local-pack-rule-status-helper';

type PropsType = {
    rule: LocalPackRuleListItemType;
};

export function LocalPackRuleStatus(props: PropsType): JSX.Element {
    const {rule} = props;

    const {status, requestedChanges} = rule;

    const {getLocalizedString} = useLocale();

    const statusOptions = LOCALPACK_RULE_STATUS_OPTIONS[status];
    const {data: availableServices, isLoading: isLoadingAvailableServices} = useLocalPackSearchServices();

    const statusTag = (
        <Tag color={statusOptions.color}>
            <Locale stringKey={statusOptions.textKey} />
        </Tag>
    );

    if (status === LocalPackRuleStatusEnum.Moderating && requestedChanges) {
        return (
            <Popover
                content={
                    <Form<LocalPackRuleListItemType['requestedChanges']>
                        disabled
                        initialValues={requestedChanges}
                        size="small"
                    >
                        {requestedChanges.name && (
                            <Form.Item<LocalPackRuleListItemType['requestedChanges']>
                                label={getLocalizedString('LOCAL_PACK__RULE_FORM__NAME__LABEL')}
                                name="name"
                            >
                                <Input placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__NAME__PLACEHOLDER')} />
                            </Form.Item>
                        )}

                        {requestedChanges.services && (
                            <Form.Item<LocalPackRuleListItemType['requestedChanges']>
                                label={getLocalizedString('LOCAL_PACK__RULE_FORM__SERVICES__LABEL')}
                                name="services"
                            >
                                <SelectServicesField
                                    availableCatalogs={availableServices}
                                    loading={isLoadingAvailableServices}
                                />
                            </Form.Item>
                        )}

                        {requestedChanges.periodicity && (
                            <Form.Item<LocalPackRuleListItemType['requestedChanges']>
                                label={getLocalizedString('LOCAL_PACK__RULE_FORM__FREQUENCY__LABEL')}
                                name="periodicity"
                            >
                                <Select<LocalPackRuleFrequencyEnum>
                                    options={Object.values(LocalPackRuleFrequencyEnum).map(
                                        (frequencyOption) =>
                                            ({
                                                label: getLocalizedString(
                                                    LOCALPACK_FREQUENCY_LOCALIZATION_MAP[frequencyOption]
                                                ),
                                                value: frequencyOption,
                                            } || [])
                                    )}
                                />
                            </Form.Item>
                        )}

                        {requestedChanges.gridSize && (
                            <Form.Item<LocalPackRuleListItemType['requestedChanges']>
                                label={getLocalizedString('LOCAL_PACK__RULE_FORM__SQUARE_SIZE__LABEL')}
                                name="gridSize"
                            >
                                <Select<LocalPackSquareSizeEnum>
                                    options={Object.values(LocalPackSquareSizeEnum).map(
                                        (squareSizeOptions) =>
                                            ({label: squareSizeOptions, value: squareSizeOptions} || [])
                                    )}
                                />
                            </Form.Item>
                        )}

                        {requestedChanges.gridStep && (
                            <Form.Item
                                label={getLocalizedString('LOCAL_PACK__RULE_FORM__POINT_DISTANCE__LABEL')}
                                name="gridStep"
                            >
                                <Select<LocalPackPointDistanceEnum>
                                    options={Object.values(LocalPackPointDistanceEnum)
                                        .filter(Number.isInteger)
                                        .map(
                                            (pointDistanceOption) =>
                                                ({
                                                    label: getLocalizedString('LOCAL_PACK__DISTANCE', {
                                                        distance: String(pointDistanceOption),
                                                    }),
                                                    value: pointDistanceOption,
                                                } || [])
                                        )}
                                />
                            </Form.Item>
                        )}
                    </Form>
                }
            >
                {statusTag}
            </Popover>
        );
    }

    return statusTag;
}
