import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {Locale} from '../../provider/locale/localization';
import {useMessage} from '../../provider/message/message-hook';
import {useUser} from '../../provider/user/user-hook';
import {deserializeApiError, deserializeV2, UnknownType} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, rootApiUrl} from '../api/api-const';
import {UserType} from '../user/user-type';

import {companiesImportUrl} from './companies-import-const';

const feedFileSchema = r.object({
    tempId: r.string(),
});

type FeedFileType = r.infer<typeof feedFileSchema>;

export const feedFileErrorSchema = r.object({
    feedFileS3: r.array(r.string()).optional(),
});

export type FeedFileErrorType = r.infer<typeof feedFileErrorSchema>;

export const feedFileUrl = `${rootApiUrl}${companiesImportUrl}/feed_file/`;

async function uploadFeedFile(file: File, user: UserType | null): Promise<FeedFileType> {
    const formData = new FormData();

    formData.append('feed_file_s3', file);

    const response: UnknownType = await fetchX(feedFileUrl, {
        method: FetchMethodEnum.post,
        body: formData,
        ...(user ? {headers: getCsrfHeaders()} : {}),
    });

    return deserializeV2<FeedFileType>(feedFileUrl, feedFileSchema, response);
}

export function useUploadFeedFileMutation(): UseMutationResult<FeedFileType, unknown, File> {
    const {message} = useMessage();
    const {user} = useUser();

    return useMutation((file) => uploadFeedFile(file, user), {
        onError(error) {
            const apiError = deserializeApiError<FeedFileErrorType>(feedFileUrl, feedFileErrorSchema, error);

            message.error(apiError?.feedFileS3 || <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />);
        },
    });
}
