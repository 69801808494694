import {LangKeyType} from '../../provider/locale/translation/type';

export enum ActionThumbStateNameEnum {
    'synchronized' = 'synchronized',
    'sent' = 'sent',
    'actionRequired' = 'action-required',
    confirmation = 'confirmation',
}

export type ActionThumbUiDataType = {
    image: JSX.Element;
    className: string;
    langKey: LangKeyType;
};
