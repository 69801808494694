import {Context, createContext, PropsWithChildren, useEffect, useMemo, useState} from 'react';

import {useRefreshId} from '../../util/hook';

import {getBrands} from './brand-api';
import {BrandsContextType} from './brand-context-type';
import {BrandType} from './brand-type';

export const BrandsContext: Context<BrandsContextType> = createContext<BrandsContextType>({
    isLoading: true,
    brands: [],
    firstBrand: null,
});

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function BrandsContextProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brands, setBrands] = useState<Array<BrandType>>([]);

    const {refresh, refreshId} = useRefreshId();

    useEffect(() => {
        setIsLoading(true);

        async function fetchAllBrands(): Promise<Array<BrandType>> {
            const result: Array<BrandType> = [];

            const {pages, results: firstPageResults} = await getBrands({
                page: 1,
                count: 200,
            });

            result.push(...firstPageResults);

            // eslint-disable-next-line no-loops/no-loops
            for (let pageIndex = 2; pageIndex < (pages ?? 1); pageIndex += 1) {
                const {results: pageResults} = await getBrands({
                    page: pageIndex,
                    count: 200,
                });

                result.push(...pageResults);
            }

            return result;
        }

        fetchAllBrands()
            .then((result: Array<BrandType>) => {
                setBrands(result);
            })
            .finally(() => {
                setIsLoading(false);
            })
            .catch(() => {
                refresh();
            });
    }, [refresh, refreshId]);

    const providerData: BrandsContextType = useMemo(() => {
        return {
            isLoading,
            brands,
            firstBrand: brands[0] ?? null,
        };
    }, [brands, isLoading]);

    return <BrandsContext.Provider value={providerData}>{children}</BrandsContext.Provider>;
}
