import {Button, Space} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FilterIcon, ListHeader} from '../../../common/list-header/list-header';

type PropsType = {
    total: number;
    selected: number;
    hasFilters: boolean;
    hideSelectAll: boolean;
    selectAll: () => void;
    deselectAll: () => void;
};

export function CompaniesListHeader(props: PropsType): JSX.Element {
    const {total, selected, hasFilters, hideSelectAll, selectAll, deselectAll} = props;

    return (
        <ListHeader
            left={
                <>
                    {hasFilters && <FilterIcon />}

                    <span>
                        {selected > 0 ? (
                            <Locale
                                stringKey="GOOGLE_SYNC__COMPANIES_STEP__SELECTED"
                                valueMap={{
                                    selected: <Text bolder>{selected}</Text>,
                                    total,
                                }}
                            />
                        ) : (
                            <Locale
                                stringKey="GOOGLE_SYNC__COMPANIES_STEP__FOUND"
                                valueMap={{
                                    total: <Text bolder>{total}</Text>,
                                }}
                            />
                        )}
                    </span>
                </>
            }
            right={
                <Space>
                    {selected > 0 && (
                        <Button onClick={deselectAll} size="small" type="text">
                            <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__DESELECT_ALL" />
                        </Button>
                    )}

                    {!hideSelectAll && (
                        <Button onClick={selectAll} size="small">
                            <Locale stringKey="TEXT__SELECT_ALL" />
                        </Button>
                    )}
                </Space>
            }
        />
    );
}
