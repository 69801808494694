import {PageCard} from '../../../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../../../provider/locale/localization';
import {ProvidersEnum} from '../../../../../../../util/type';
import {ContentValidationSettingsType} from '../content-step-type';

import {ActionRequirements} from './components/action-requirements/action-requirements';
import {CatalogRequirementsLink} from './components/catalog-requirements-link/catalog-requirements-link';
import {ImagesRequirements} from './components/images-requirements/images-requirements';
import {TextRequirements} from './components/text-requirements/text-requirements';
import {VideoRequirements} from './components/video-requirements/video-requirements';
import * as styles from './post-requirements.scss';

type PropsType = {
    options: ContentValidationSettingsType;
    provider?: ProvidersEnum;
};

export function PostRequirements(props: PropsType): JSX.Element {
    const {options, provider} = props;

    return (
        <PageCard
            className={styles.PostRequirements}
            title={<Locale stringKey={provider ? 'POSTS_REQUIREMENTS__TITLE' : 'POSTS_REQUIREMENTS__COMMON_TITLE'} />}
        >
            <ImagesRequirements extraRequirements={options.extra} requirements={options.images} />
            <TextRequirements extraRequirements={options.extra} maxTextLength={options.maxTextLength} />
            <ActionRequirements extraRequirements={options.extra} />
            <VideoRequirements requirements={options.video} />
            <CatalogRequirementsLink requirements={options.catalogRequirementsLink} />
        </PageCard>
    );
}
