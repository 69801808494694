import {Route, Switch} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {useLocale} from '../../../provider/locale/locale-hook';
import {ErrorPage404} from '../../error/page-404/page-404';

import {SourcesConfirmations} from './confirmations/confirmations';
import {YandexConfirmation} from './provider-confirmations/yandex-confirmation';
import {Sources} from './sources';

export function SourcesRouting(): JSX.Element {
    const {getLocalizedString} = useLocale();

    return (
        <>
            <Meta title={getLocalizedString('CATEGORY_NAME__SOURCES')} />

            <Switch>
                <Route component={SourcesConfirmations} exact path={appRoute.sourcesConfirmations.path} />
                <Route component={YandexConfirmation} exact path={appRoute.sourcesYandexConfirmation.path} />
                <Route component={Sources} exact path={appRoute.sources.path} />
                <Route component={ErrorPage404} />
            </Switch>
        </>
    );
}
