import * as styles from './chart-tooltip.scss';

type PropsType = {
    title: string | JSX.Element;
};

export function ChartTooltipTitle(props: PropsType): JSX.Element {
    const {title} = props;

    return <div className={styles.ChartTooltip_title}>{title}</div>;
}
