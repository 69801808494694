import {Dayjs} from 'dayjs';

import {PostStepEnum} from '../../page/main/posts/post-form/post-form-type';
import {PostsFilterStateUrlQueryNameEnum} from '../../page/main/posts/posts-filter/posts-filter-type';
import {serialize} from '../../util/api-adapter';
import {getEnumValue} from '../../util/enum';
import {QueryMapType} from '../../util/type';

import {
    postAiSuggestActionSchema,
    PostAiSuggestActionType,
    PostFormFieldsEnum,
    PostFormType,
    PostsFilterKeyEnum,
    PostsFilterStateType,
    PostStatusEnum,
    SourcePostFormFieldsEnum,
} from './posts-types';

export function getInitialPostsFilter(urlQueries: QueryMapType): PostsFilterStateType {
    const sourcesRaw = urlQueries[PostsFilterStateUrlQueryNameEnum.catalogs];
    const filterSources =
        typeof sourcesRaw === 'string' && sourcesRaw.length > 0
            ? sourcesRaw.split(',').map((sourceIterInRow) => {
                  return Number.parseInt(sourceIterInRow, 10);
              })
            : [];
    const statusesRaw = urlQueries[PostsFilterStateUrlQueryNameEnum.statuses];

    const filterStatuses =
        typeof statusesRaw === 'string' && statusesRaw.length > 0
            ? statusesRaw.split(',').reduce<Array<PostStatusEnum>>((accumulator, statusRaw) => {
                  const status = getEnumValue(PostStatusEnum, statusRaw);

                  if (status) {
                      return [...accumulator, status];
                  }

                  return accumulator;
              }, [])
            : [];

    const dateFromRaw = urlQueries[PostsFilterStateUrlQueryNameEnum.createdAtAfter];
    const dateFrom: Date | null = dateFromRaw ? new Date(dateFromRaw) : null;

    const dateToRaw = urlQueries[PostsFilterStateUrlQueryNameEnum.createdAtBefore];
    const dateTo: Date | null = dateToRaw ? new Date(dateToRaw) : null;

    return {
        [PostsFilterKeyEnum.sources]: filterSources,
        [PostsFilterKeyEnum.statuses]: filterStatuses,
        [PostsFilterKeyEnum.timeRange]: [dateFrom, dateTo],
    };
}

export function isOmnichannelSuggestAction(data: unknown): data is PostAiSuggestActionType {
    return postAiSuggestActionSchema.safeParse(data).success;
}

export function getCentrifugePostSuggestChannel(options: {channelId: string; userId: string}): string {
    return `posts:suggests_${options.channelId}#${options.userId}`;
}

export const FORM_STEP_FIELDS: Record<PostStepEnum, Array<PostFormFieldsEnum>> = {
    [PostStepEnum.Target]: [
        PostFormFieldsEnum.Brands,
        PostFormFieldsEnum.Name,
        PostFormFieldsEnum.SelectorId,
        PostFormFieldsEnum.VkGroupsIds,
        PostFormFieldsEnum.FbPagesIds,
        PostFormFieldsEnum.InstagramGroupsIds,
    ],
    [PostStepEnum.CommonContent]: [],
    [PostStepEnum.AdaptiveContent]: [PostFormFieldsEnum.Posts],
    [PostStepEnum.SendParameters]: [
        PostFormFieldsEnum.Timezone,
        PostFormFieldsEnum.CreateDateTime,
        PostFormFieldsEnum.DeleteDateTime,
    ],
};

function prepareDateTimeForSending(date: Dayjs | null): string | undefined {
    // we work with device timezone in datePickers but send it like it was chosen in specified timezone

    if (!date) {
        // eslint-disable-next-line no-undefined
        return undefined;
    }

    return date.tz('UTC', true).toISOString();
}

export function preparePostFormRequestData(formData: PostFormType, status: PostStatusEnum): string {
    return JSON.stringify(
        serialize({
            ...formData,
            [PostFormFieldsEnum.Status]: status,
            [PostFormFieldsEnum.DeleteDateTime]: prepareDateTimeForSending(formData[PostFormFieldsEnum.DeleteDateTime]),
            [PostFormFieldsEnum.CreateDateTime]: prepareDateTimeForSending(formData[PostFormFieldsEnum.CreateDateTime]),
            time: null,
            date: null,
            isAdaptiveContent: true,
            posts: formData.posts.map((post) => ({
                title: null,
                ...post,
                ...(post[SourcePostFormFieldsEnum.Event]
                    ? {
                          [SourcePostFormFieldsEnum.Event]: {
                              title: post[SourcePostFormFieldsEnum.Event].title,
                              start: post[SourcePostFormFieldsEnum.Event].start.utc(true).toISOString(),
                              end: post[SourcePostFormFieldsEnum.Event].end.utc(true).toISOString(),
                          },
                      }
                    : {}),
            })),
        })
    );
}
