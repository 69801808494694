import {useMutation} from '@tanstack/react-query';
import {Button, Checkbox, FormInstance, Modal} from 'antd';
import {noop} from 'lodash';
import {useCallback, useState} from 'react';

import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {
    CreateReviewFormPaylaodType,
    CustomReviewError,
    fetchCreateReview,
    handleCustomReviewError,
    ReviewFormErrorType,
    ReviewPayloadType,
} from '../../../../../service/reviews/custom-reviews';
import {useReviewFormSources} from '../../../../../service/reviews/reviews-form-sources';
import {ReviewForm} from '../review-form';
import * as styles from '../review-form.scss';
import {serializeCreateReviewForm} from '../review-form-helper';
import {ReviewFormFieldNamesEnum, ReviewFormFieldsType} from '../review-form-type';

type PropsType = {
    isOpen: boolean;
    onCancel: () => void;
    form: FormInstance<ReviewFormFieldsType>;
    onSubmitFinish: () => void;
};

export function ReviewFormModalCreate(props: PropsType): JSX.Element | null {
    const {isOpen, onCancel, form, onSubmitFinish} = props;

    const {snackbar} = useSnackbar();

    const [closeAfterSubmit, setCloseAfterSubmit] = useState(true);

    const handleCancel = useCallback(() => {
        form.resetFields();

        onCancel();
    }, [form, onCancel]);

    const {
        mutate: createReview,
        isLoading: isReviewFormInProgress,
        error: serverError,
    } = useMutation<ReviewPayloadType, ReviewFormErrorType, CreateReviewFormPaylaodType>({
        mutationFn: (fields: CreateReviewFormPaylaodType) => fetchCreateReview(fields),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="REVIEW__FORM__CREATED__SUCCESS_HEADER" />,
                description: <Locale stringKey="REVIEW__FORM__CREATED__SUCCESS_BODY" />,
            });

            if (closeAfterSubmit) {
                handleCancel();
            } else {
                form.resetFields();
            }

            onSubmitFinish();
        },
        onError: (error: unknown) => {
            if (error instanceof CustomReviewError) {
                snackbar.error((error as Error).message);
            } else {
                snackbar.error({
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                    description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                });
            }

            handleCustomReviewError(error);
        },
    });
    const {data: sources} = useReviewFormSources();

    const handleSubmit = useCallback(async () => {
        await form
            .validateFields()
            .then((values) => {
                track(
                    AnalyticsTarget.ReviewsManagement.Reviews.CreateReview,
                    sources?.find((source) => source.id === values[ReviewFormFieldNamesEnum.source])?.name || ''
                );

                createReview(serializeCreateReviewForm(values));
            })
            .catch(noop);
    }, [createReview, form, sources]);

    return (
        <Modal
            closable={false}
            footer={
                <div className={styles.ReviewForm_footer}>
                    <div className={styles.ReviewForm_addOneMore}>
                        <Checkbox
                            checked={!closeAfterSubmit}
                            onChange={(event) => setCloseAfterSubmit(!event.target.checked)}
                        >
                            <Locale stringKey="REVIEW__FORM__MODAL__ADD_ONE_MORE_REVIEW" />
                        </Checkbox>
                    </div>
                    <Button
                        disabled={isReviewFormInProgress}
                        loading={isReviewFormInProgress}
                        onClick={handleSubmit}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>
                    <Button onClick={handleCancel}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>
                </div>
            }
            open={isOpen}
            title={<Locale stringKey="REVIEW__FORM__MODAL__HEADER__ADDING" />}
        >
            <ReviewForm form={form} serverError={serverError} />
        </Modal>
    );
}
