import {Space, Switch} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {fetchGeocoderList, fetchSuggesterList} from '../../../../../../../service/api/api';
import {AddressType} from '../../../../../../../service/api/api-type';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {buildFullSearchQuery} from '../build-query-helper';

type PropsType = {
    showLatLng: boolean;
    setShowLatLng: (value: boolean) => void;
    form: FormInstance<CompanyFormType>;
};

export function CoordinateModeSwitch(props: PropsType): JSX.Element {
    const {setShowLatLng, showLatLng, form} = props;

    const formAddress = Form.useWatch(CompanyKeyEnum.Address, form);

    const searchAddress = buildFullSearchQuery(
        formAddress?.country,
        formAddress?.region,
        formAddress?.city,
        formAddress?.street,
        formAddress?.houseNumber
    ).join(', ');

    const addressKey = formAddress?.houseNumber
        ? 'house'
        : formAddress?.street
        ? 'street'
        : formAddress?.city
        ? 'locality'
        : formAddress?.region
        ? 'province'
        : 'country';

    function getSuggestedAddress(suggestResult: Array<AddressType>) {
        return suggestResult.find((address) => address[addressKey]) || null;
    }

    async function handleSelectChange() {
        setShowLatLng(!showLatLng);

        if (!formAddress?.country || !showLatLng) {
            return;
        }

        const suggestResult = await fetchSuggesterList(
            searchAddress,
            formAddress?.country.countryCode,
            formAddress?.country.lang
        );

        let selectedAddress = getSuggestedAddress(suggestResult);

        if (selectedAddress === null || selectedAddress.lat === null || selectedAddress.lon === null) {
            const geocodingResult = await fetchGeocoderList(searchAddress, formAddress?.country.countryCode);

            if (geocodingResult[0]) {
                selectedAddress = geocodingResult[0];
            }
        }

        if (typeof selectedAddress?.lat === 'number' && typeof selectedAddress?.lon === 'number') {
            form.setFieldsValue({
                address: {latLng: [selectedAddress.lat, selectedAddress.lon]},
            });
        }
    }

    return (
        <Space>
            <Switch checked={showLatLng} onChange={handleSelectChange} />
            <Locale stringKey="COMPANY_FORM__ADDRESS__SHOW_LAT_LONG__LABEL" />
        </Space>
    );
}
