import {Button} from 'antd';
import {useCallback} from 'react';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../provider/locale/locale';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {CompanyGroupType} from '../../../../../service/company-group/company-group-type';
import {classNames} from '../../../../../util/css';

import * as styles from './companies-group-table-action.scss';

type PropsType = {
    companyGroup: CompanyGroupType;
    onRemove: (companyGroupId: number) => void;
    className?: string;
};

export function CompaniesGroupTableAction(props: PropsType): JSX.Element {
    const {className, companyGroup, onRemove} = props;
    const {title, id, has_delete_permission: hasDeletePermission} = companyGroup;

    const {modal} = useModal();

    const handleClickDelete = useCallback(() => {
        modal.confirm({
            title: <Locale stringKey="GROUP_OF_COMPANIES__DELETE_CONFIRM__TITLE" />,
            content: <Locale stringKey="GROUP_OF_COMPANIES__DELETE_CONFIRM__CONTENT" valueMap={{title}} />,
            okText: <Locale stringKey="BUTTON__DELETE" />,
            okType: 'danger',
            onOk: () => onRemove(id),
            cancelText: <Locale stringKey="BUTTON__CANCEL" />,
            cancelButtonProps: {type: 'primary'},
        });
    }, [modal, title, onRemove, id]);

    return (
        <div className={classNames(styles.companies_group_table_action, className)}>
            <Button className={styles.companies_group_table_action__button} type="link">
                <NavigationLink
                    onClick={() => track(AnalyticsTarget.Settings.GroupOfCompanies.Manage)}
                    to={generatePath(appRoute.settingsCompaniesGroupEdit.path, {id})}
                >
                    <Locale stringKey="GROUP_OF_COMPANIES__TABLE_BUTTON__MANAGE_A_GROUP" />
                </NavigationLink>
            </Button>

            <Button
                className={styles.companies_group_table_action__button}
                danger
                disabled={!hasDeletePermission}
                onClick={handleClickDelete}
                type="link"
            >
                <Locale stringKey="BUTTON__DELETE" />
            </Button>
        </div>
    );
}
