import {Checkbox, Input, Select} from 'antd';
import {useMemo} from 'react';

import {FileFormInput} from '../../../../../../component/form/file-upload-item/file-form-input';
import {getTechnicalSupportFileUploadValidator} from '../../../../../../component/form/file-upload-item/file-upload-helper';
import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {
    useDeleteUploadedFile,
    useTechnicalSupportFields,
    useUploadTicketFile,
} from '../../../../../../service/technical-support/technical-support-hook';
import {
    CreateTicketFieldsEnum,
    CreateTicketFormType,
} from '../../../../../../service/technical-support/technical-support-type';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';

import * as styles from './technical-support-create-form.scss';

type PropsType = {
    form: FormInstance<CreateTicketFormType>;
};

export function TechnicalSupportCreateForm(props: PropsType): JSX.Element {
    const {form} = props;
    const {data: fields} = useTechnicalSupportFields();
    const {requiredFieldRule, maxLengthFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();
    const {mutateAsync: uploadFile} = useUploadTicketFile();
    const {mutateAsync: removeFile} = useDeleteUploadedFile();

    const filesValidator = useMemo(() => {
        return getTechnicalSupportFileUploadValidator(getLocalizedString);
    }, [getLocalizedString]);

    const TICKET_TYPE_OPTIONS =
        fields?.type.map((item) => ({
            value: item.value,
            name: (
                <div className={styles.TechnicalSupportCreateForm_selectOption}>
                    {item.name}
                    <AdditionalInfo>{item.description}</AdditionalInfo>
                </div>
            ),
        })) || [];

    return (
        <div>
            <Form<CreateTicketFormType>
                form={form}
                initialValues={{
                    [CreateTicketFieldsEnum.description]: '',
                    [CreateTicketFieldsEnum.title]: '',
                    [CreateTicketFieldsEnum.files]: {},
                    [CreateTicketFieldsEnum.isPublic]: true,
                }}
                layout="vertical"
            >
                <Form.Item<CreateTicketFormType>
                    label={getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TYPE')}
                    name={CreateTicketFieldsEnum.type}
                    rules={[requiredFieldRule]}
                >
                    <Select
                        fieldNames={{label: 'name'}}
                        optionFilterProp="name"
                        optionLabelProp="name"
                        options={TICKET_TYPE_OPTIONS}
                        placeholder={getLocalizedString(
                            'HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TYPE__PLACEHOLDER'
                        )}
                        popupClassName={styles.TechnicalSupportCreateForm_selectPopup}
                    />
                </Form.Item>
                <Form.Item<CreateTicketFormType>
                    label={
                        <div
                            className={classNames(
                                styles.TechnicalSupportCreateForm_formRow,
                                styles.TechnicalSupportCreateForm_formRow__spacing
                            )}
                        >
                            <Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__CLIENT_CATEGORY" />
                            <AdditionalInfo>
                                <Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__CLIENT_CATEGORY__TOOLTIP" />
                            </AdditionalInfo>
                        </div>
                    }
                    name={CreateTicketFieldsEnum.clientCategory}
                    rules={[requiredFieldRule]}
                >
                    <Select
                        fieldNames={{label: 'name'}}
                        optionFilterProp="name"
                        optionLabelProp="name"
                        options={fields?.clientCategory || []}
                        placeholder={getLocalizedString(
                            'HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__CLIENT_CATEGORY__PLACEHOLDER'
                        )}
                        showSearch
                    />
                </Form.Item>
                <Form.Item<CreateTicketFormType>
                    label={getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TITLE')}
                    name={CreateTicketFieldsEnum.title}
                    required
                    rules={[requiredFieldRule]}
                >
                    <Input
                        maxLength={100}
                        placeholder={getLocalizedString(
                            'HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__TITLE__PLACEHOLDER'
                        )}
                        showCount
                    />
                </Form.Item>
                <Form.Item<CreateTicketFormType>
                    label={getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__DESCRIPTION')}
                    name={CreateTicketFieldsEnum.description}
                    required
                    rules={[requiredFieldRule, maxLengthFieldRule(8000)]}
                >
                    <Input.TextArea
                        placeholder={getLocalizedString(
                            'HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__DESCRIPTION__PLACEHOLDER'
                        )}
                        rows={4}
                    />
                </Form.Item>
                <Form.Item<CreateTicketFormType>
                    label={
                        <AdditionalInfo
                            leftNode={<Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__FILES" />}
                        >
                            <Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__FILES__HELPER" />
                        </AdditionalInfo>
                    }
                    name={CreateTicketFieldsEnum.files}
                    rules={[filesValidator]}
                >
                    <FileFormInput remove={removeFile} upload={uploadFile} />
                </Form.Item>
                <div className={styles.TechnicalSupportCreateForm_formRow}>
                    <Form.Item<CreateTicketFormType>
                        name={CreateTicketFieldsEnum.isPublic}
                        noStyle
                        valuePropName="checked"
                    >
                        <Checkbox>
                            <Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__PUBLIC" />
                        </Checkbox>
                    </Form.Item>
                    <AdditionalInfo
                        title={getLocalizedString('HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__PUBLIC__TOOLTIP_TITLE')}
                    >
                        <Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__PUBLIC__TOOLTIP" />
                    </AdditionalInfo>
                </div>
            </Form>
        </div>
    );
}
