import {Rule} from 'antd/lib/form';

export const TWO_FACTOR_CODE_LENGTH = 6;

export const initialValues = {
    code: '',
};

export const codeRules: Array<Rule> = [
    {
        pattern: /\d+/,
        transform: (value) => value.replace(/\s/g, ''),
        message: '',
        warningOnly: true,
    },
    {
        pattern: new RegExp(`\\d{${TWO_FACTOR_CODE_LENGTH}}`),
        transform: (value) => value.replace(/\s/g, ''),
        message: '',
        warningOnly: true,
        validateTrigger: 'onSubmit',
    },
];
