import {z as r} from 'zod';

import {CursorPaginationHookType} from '../../util/cursor-pagination/cursor-pagination-type';
import {ApiError} from '../../util/error';
import {PaginatedResponseType} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';

export enum NpsGroupEnum {
    unspecified = 'unspecified',
    promoter = 'promoter',
    neutral = 'neutral',
    detractor = 'detractor',
}

export enum MailingClientBasicFieldEnum {
    name = 'name',
    email = 'email',
    phone = 'phone',
    companyId = 'company',
    npsGroup = 'npsGroup',
}

export const mailingClientSchema = r.object({
    id: r.number(),
    [MailingClientBasicFieldEnum.name]: r.string(),
    [MailingClientBasicFieldEnum.email]: r.string(),
    [MailingClientBasicFieldEnum.phone]: r.string(),
    [MailingClientBasicFieldEnum.companyId]: r.string(),
    [MailingClientBasicFieldEnum.npsGroup]: r.nativeEnum(NpsGroupEnum),
    messagesCount: r.number().optional(),
    clicksCount: r.number().optional(),
    failsCount: r.number().optional(),
    unsubscribed: r.boolean().optional(),
    negativeReviewsCount: r.number().optional(),
    createdAt: r.string().optional(),
});

export type MailingClientType = MailingClientManagementRequestType & {
    id: number;
    [MailingClientBasicFieldEnum.name]: string;
    [MailingClientBasicFieldEnum.email]: string;
    [MailingClientBasicFieldEnum.phone]: string;
    [MailingClientBasicFieldEnum.companyId]: string;
    [MailingClientBasicFieldEnum.npsGroup]: NpsGroupEnum;
    companyAddress: string;
    messagesCount: number;
    clicksCount: number;
    failsCount: number;
    unsubscribed: boolean;
    negativeReviewsCount: number;
    createdAt: string;
};

export const mailingClientResponseSchema = r.object({
    count: r.number(),
    next: r.string().nullable(),
    previous: r.string().nullable(),
    pageSize: r.number(),
    results: r.array(mailingClientSchema),
});

export type UseMailingClientListType = UseHookType<PaginatedResponseType<MailingClientType>> & {
    cursorPagination: CursorPaginationHookType;
    load: (options: MailingClientListRequestType) => Promise<void>;
};

export const mailingClientImportStatisticsSchema = r.object({
    count: r.number(),
    lastUpdated: r.string(),
});

export type MailingClientImportStatisticsType = r.infer<typeof mailingClientImportStatisticsSchema>;

export type MailingClientManagementRequestType = {
    [MailingClientBasicFieldEnum.name]: string;
    [MailingClientBasicFieldEnum.email]: string;
    [MailingClientBasicFieldEnum.phone]: string;
    [MailingClientBasicFieldEnum.companyId]: string;
    [MailingClientBasicFieldEnum.npsGroup]: NpsGroupEnum;
};

export const enum ClientManagementNonFieldErrorEnum {
    ClientExists = 'client_exists',
}

export type MailingClientManagementErrorPayloadType = {
    company?: Array<string>;
    non_field_errors?: Array<ClientManagementNonFieldErrorEnum | unknown>;
};

export class MailingClientManagementError extends ApiError {
    name = 'MailingClientManagementError';
    jsonData: MailingClientManagementErrorPayloadType;

    constructor(message: string, statusCode: number, jsonData: MailingClientManagementErrorPayloadType) {
        super(message, statusCode, jsonData);
        this.jsonData = jsonData;
    }
}

export type MailingClientFilterPartRequestType = {
    startDate: string | null;
    endDate: string | null;
    npsGroups: Array<NpsGroupEnum>;
    phoneExists?: string;
    templateIds: Array<number>;
    brandId: number | null;
    unsubscribed: boolean | null;
};

export type MailingClientListRequestType = MailingClientFilterPartRequestType;

type MailingClientBulkIncludeRequestType = {
    ids: Array<number>;
};

type MailingClientBulkExcludeRequestType = Omit<MailingClientFilterPartRequestType, 'ids'> & {
    exclude: Array<number>;
    filter_key: string;
};

export type MailingClientBulkDeleteRequestType =
    | MailingClientBulkIncludeRequestType
    | MailingClientBulkExcludeRequestType;

export type MailingClientUploadType = {
    detail?: string;
    newRecords?: number;
    intersectionRecords?: number;
    npsOverride?: number;
    locationOverride?: number;
    failed?: number;
    key?: string;
};

export const MailingClientDeleteSchema = r.object({
    deleted: r.number(),
    activeMailings: r.array(r.string()),
    activeClientIds: r.array(r.number()),
});

export type MailingClientDeleteType = r.infer<typeof MailingClientDeleteSchema>;

export const mailingClientIdsSchema = r.object({
    clientIds: r.array(r.number()),
});

export type MailingClientIdsType = r.infer<typeof mailingClientIdsSchema>;

type MailingClientsCreateErrorDataType = {
    status: string;
};

export class MailingClientsCreateError extends ApiError {
    name = 'MailingClientsCreateError';
    jsonData: MailingClientsCreateErrorDataType;

    constructor(message: string, statusCode: number, jsonData: MailingClientsCreateErrorDataType) {
        super(message, statusCode, jsonData);
        const {status = ''} = jsonData;

        this.jsonData = {status};
    }
}
