import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {z as r} from 'zod';

import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {postAndDeserialize} from '../../util/api-adapter';

import {userUrl} from './user-const';
import {userOwnUrl} from './user-own';

const userLanguageSchema = r.object({
    language: r.string(),
});

type UserLanguageType = r.infer<typeof userLanguageSchema>;

function updateUserLanguage(language: ShortLocaleNameEnum): Promise<UserLanguageType> {
    const url = `${userUrl}/language/`;

    return postAndDeserialize(url, userLanguageSchema, {language});
}

export function useUserLanguageMutation(): UseMutationResult<UserLanguageType, unknown, ShortLocaleNameEnum> {
    const queryClient = useQueryClient();

    return useMutation(updateUserLanguage, {
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [userOwnUrl]});
        },
    });
}
