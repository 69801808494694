import * as styles from './keywords-chart-legend.scss';

type KeywordsChartLegendItemType = {
    label: string;
    color: string;
};

type PropsType = {
    items: Array<KeywordsChartLegendItemType>;
};

export function KeywordsChartLegend(props: PropsType): JSX.Element {
    const {items} = props;

    return (
        <div className={styles.KeywordsChartLegend}>
            {items.map((item) => (
                <span className={styles.KeywordsChartLegend_item} key={item.label}>
                    <div className={styles.KeywordsChartLegend_marker} style={{backgroundColor: item.color}} />
                    {item.label}
                </span>
            ))}
        </div>
    );
}
