import {useCallback, useEffect, useRef, useState} from 'react';

import {useLocale} from '../../../provider/locale/locale-hook';
import {UseCompaniesSelectorHookType} from '../../../service/feature-companies/feature-companies-type';
import {
    StoriesCompanySelectorCompanyType,
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
    YandexStoryTypeEnum,
} from '../../../service/yandex-stories/yandex-stories-type';
import {Form} from '../../../typings/antd';

import {YandexStoryConfirmationStep} from './slides-content-steps/confirmation-step/yandex-story-confirmation-step';
import {YandexStoryFormContentStep} from './slides-content-steps/content-step/yandex-story-form-content-step';
import {YandexStoriesSelectCompaniesStep} from './slides-content-steps/select-companies-step/yandex-stories-select-companies-step';

type PropsType = {
    onSubmit: (values: YandexStoryFormType) => Promise<void>;
    onChangeStep: (width: number) => void;
    closeModal: () => void;
    companyId?: number;
    mode: 'edit' | 'create';
    type: YandexStoryTypeEnum;
    companiesSelector: UseCompaniesSelectorHookType<StoriesCompanySelectorCompanyType>;
    initialValues?: Partial<YandexStoryFormType>;
    brandId?: number;
};

export function YandexStoryForm(props: PropsType): JSX.Element {
    const {onSubmit, closeModal, companyId, mode, type, companiesSelector, initialValues, onChangeStep, brandId} =
        props;

    const {getLocalizedString} = useLocale();

    const [form] = Form.useForm<YandexStoryFormType>();

    const [currentStep, setCurrentStep] = useState<number>(0);

    const [selectedBrandId, setSelectedBrandId] = useState<number | null>(brandId || null);

    const {selectCompany, selectorId, isInProgress, selectedCount} = companiesSelector;
    const isDirtyRef = useRef<boolean>(false);

    useEffect(() => {
        if (companyId && mode === 'create' && selectorId && !isInProgress && !isDirtyRef.current) {
            isDirtyRef.current = true;

            selectCompany(companyId);
        }
    }, [companyId, mode, selectCompany, selectorId, isInProgress]);

    useEffect(() => {
        if (brandId) {
            setSelectedBrandId(brandId);
        }

        if (selectedCount === 0 && !brandId) {
            setSelectedBrandId(null);
        }
    }, [brandId, selectedCount, setSelectedBrandId]);

    useEffect(() => {
        form.setFieldsValue({[YandexStoryFormFieldsEnum.CompaniesSelector]: selectorId});
    }, [form, selectorId]);

    const goToNextStep = useCallback(() => {
        setCurrentStep((previousState) => previousState + 1);
    }, []);

    const goToPreviousStep = useCallback(() => {
        if (currentStep === 0) {
            closeModal();
            return;
        }

        setCurrentStep((previousState) => previousState - 1);
    }, [currentStep, closeModal]);

    const handleFormSubmit = useCallback(
        async (values: YandexStoryFormType) => {
            await onSubmit(values);
            closeModal();
        },
        [closeModal, onSubmit]
    );

    const hasCompaniesSelectorStep = mode === 'create' && type === YandexStoryTypeEnum.Story;

    const formSteps = [
        ...(hasCompaniesSelectorStep
            ? [
                  <YandexStoriesSelectCompaniesStep
                      companiesSelector={companiesSelector}
                      formInstance={form}
                      key="companiesStep"
                      onCancel={closeModal}
                      onStepActivated={onChangeStep}
                      onStepFinished={goToNextStep}
                      selectedBrandId={selectedBrandId}
                      setSelectedBrandId={brandId ? null : setSelectedBrandId}
                  />,
              ]
            : []),
        <YandexStoryFormContentStep
            formInstance={form}
            isFirstStep={!hasCompaniesSelectorStep}
            key="contentStep"
            onPreviousStep={goToPreviousStep}
            onStepActivated={onChangeStep}
            onStepFinished={goToNextStep}
        />,
        <YandexStoryConfirmationStep
            formInstance={form}
            formMode={mode}
            key="confirmationStep"
            onPreviousStep={goToPreviousStep}
            onStepActivated={onChangeStep}
            onSubmit={handleFormSubmit}
        />,
    ];

    return (
        <Form<YandexStoryFormType>
            form={form}
            initialValues={{
                ...(type === YandexStoryTypeEnum.Navigation
                    ? {
                          [YandexStoryFormFieldsEnum.Name]: getLocalizedString('YANDEX_STORY__HOW_TO_FIND__NAME'),
                      }
                    : {}),
                type,
                ...initialValues,
            }}
            layout="vertical"
        >
            {formSteps && formSteps[currentStep]}
            <Form.Item<YandexStoryFormType> hidden name={YandexStoryFormFieldsEnum.Type} />
            <Form.Item<YandexStoryFormType> hidden name={YandexStoryFormFieldsEnum.CompaniesSelector} />
        </Form>
    );
}
