import {Button, Popover, Space, Tooltip} from 'antd';
import {PropsWithChildren, ReactElement, useState} from 'react';

import {TrialActivationModal} from '../../../../../../../../component/landing/trial-activation-modal/trial-activation-modal';
import {Text} from '../../../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {useTrialActivation} from '../../../../../../../../provider/license/license-hook';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {useBuyTariffLicense} from '../../../../../../../../service/tariffs/tariffs-buy-license';
import {LicensesCodeEnum} from '../../../../../../tariffs-and-limits/licenses-block/license/license-const';
import {TariffModalWrapper} from '../../../../../../tariffs-and-limits/tariffs-modals/tariffs-modal-wrapper/tariff-modal-wrapper';

import * as styles from './trial-popover.scss';

type PropsType = PropsWithChildren<{
    isTrialWrapper: boolean;
    isCanActivateTrial?: boolean;
    isOnlyEmojiComment?: boolean;
    refetch: () => void;
}>;

export function GetNeuralLinkButtonWrapper(props: PropsType): ReactElement {
    const {isTrialWrapper, children, isCanActivateTrial, isOnlyEmojiComment, refetch} = props;
    const {mutateAsync, isLoading} = useTrialActivation();
    const {isShowTrialNeurlalinkInfo} = useDomainConfig();
    const {mutate} = useBuyTariffLicense();
    const [isOpenTrialModal, setIsOpenTrialModal] = useState<boolean>(false);
    const [isOpenLicenseModal, setIsOpenLicenseModal] = useState<boolean>(false);

    if (isOnlyEmojiComment) {
        return (
            <Tooltip
                placement="top"
                title={<Locale stringKey="NEURAL_NETWORK__GENERATE_BUTTON__EMOJI_COMMENT_BLOCKER" />}
            >
                {children}
            </Tooltip>
        );
    }

    if (!isTrialWrapper || !isShowTrialNeurlalinkInfo) {
        return (
            <Tooltip placement="top" title={<Locale stringKey="NEURAL_NETWORK__GENERATE_BUTTON__TOOLTIP" />}>
                {children}
            </Tooltip>
        );
    }

    function handleBuyLicense(message: string) {
        mutate(
            {message, licenseCode: LicensesCodeEnum.GptReplySuggestionsLicence},
            {
                onSuccess: () => {
                    setIsOpenLicenseModal(false);
                    refetch();
                },
            }
        );
    }

    return (
        <>
            <Popover
                content={
                    <Space align="start" direction="vertical" size={4}>
                        <Text stringKey="NEURAL_NETWORK__GENERATE_BUTTON__POPOVER__CONTENT" />
                        <Button
                            block
                            className={styles.TrialPopover_linkButton}
                            onClick={() =>
                                isCanActivateTrial ? setIsOpenTrialModal(true) : setIsOpenLicenseModal(true)
                            }
                            type="link"
                        >
                            <Locale stringKey="BUTTON__CONNECT" />
                        </Button>
                    </Space>
                }
                placement="top"
                rootClassName={styles.TrialPopover}
                title={<Locale stringKey="NEURAL_NETWORK__GENERATE_BUTTON__POPOVER__TITLE" />}
            >
                {children}
            </Popover>
            <TrialActivationModal
                activateTrial={() => mutateAsync(LicensesCodeEnum.GptReplySuggestionsLicence)}
                isActivatingTrial={isLoading}
                onCancel={() => setIsOpenTrialModal(false)}
                onTrialPeriodActivated={() => {
                    setIsOpenTrialModal(false);
                    refetch();
                }}
                open={isOpenTrialModal}
                productNameLangKey="NEURAL_NETWORK__LICENSE__TITLE"
            />
            <TariffModalWrapper
                buttonTitle="BUTTON__ACTIVATE"
                isOpen={isOpenLicenseModal}
                onOk={handleBuyLicense}
                setIsOpen={setIsOpenLicenseModal}
                title="FAKES__TRIAL_LANDING__MODAL__TITLE"
            >
                <div className={styles.TrialPopover_cardWrapper}>
                    <Text bolder lighter small stringKey="FAKES__TRIAL_LANDING__MODAL__PRODUCT__LABEL" />
                    <Text bolder stringKey="NEURAL_NETWORK__ALERT__PRODUCT_NAME" />
                </div>
            </TariffModalWrapper>
        </>
    );
}
