import {faFileExcel, faFilePdf} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Row} from 'antd';
import {useMemo, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useAverageResponseTime} from '../../../service/reviews-and-answers-statistics/average-response-time/average-response-time';
import {useAverageResponseTimeByCatalog} from '../../../service/reviews-and-answers-statistics/average-response-time-by-catalog/average-response-time-by-catalog';
import {useAverageResponseTimeByEmployee} from '../../../service/reviews-and-answers-statistics/average-response-time-by-empoyee/average-response-time-by-employee';
import {useComplaintsCount} from '../../../service/reviews-and-answers-statistics/complaints-count/complaints-count';
import {useDeletedReviewsCount} from '../../../service/reviews-and-answers-statistics/deleted-reviews-count/deleted-reviews-count';
import {useReviewsAndAnswersFilter} from '../../../service/reviews-and-answers-statistics/filter/reviews-and-answers-statistics-filter';
import {ReviewsAndAnswersFilterQueriesEnum} from '../../../service/reviews-and-answers-statistics/filter/reviews-and-answers-statistics-filter-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {useReviewsCountByCatalog} from '../../../service/reviews-and-answers-statistics/reviews-count-by-catalog/reviews-count-by-catalog';
import {useReviewsCountByRating} from '../../../service/reviews-and-answers-statistics/reviews-count-by-rating/reviews-count-by-rating';
import {useReviewsPopularTagsStats} from '../../../service/reviews-and-answers-statistics/reviews-popular-tags/reviews-popular-tags';
import {useReviewsRatingByDate} from '../../../service/reviews-and-answers-statistics/reviews-ratings-by-date/reviews-rating-by-date';
import {useTonalityDistribution} from '../../../service/reviews-and-answers-statistics/tonality-distribution/tonality-distribution';
import {useUnansweredReviewsCount} from '../../../service/reviews-and-answers-statistics/unanswered-reviews-count/unanswered-reviews-count';
import {useRequestReviewsAndAnswersExport} from '../../../service/reviews-and-answers-statistics/use-request-reviews-and-answers-export/use-request-reviews-and-answers-export';
import {useUsedAutoRepliesCount} from '../../../service/reviews-and-answers-statistics/used-auto-replies-count/used-auto-replies-count';
import {useUsedNeuralNetworkSuggestionsCount} from '../../../service/reviews-and-answers-statistics/used-neural-network-suggestions-count/used-neural-network-suggestions-count';

import {AverageResponseTime} from './average-response-time/average-response-time';
import {ComplaintsCount} from './complaints-count/complaints-count';
import {DeletedReviews} from './deleted-reviews/deleted-reviews';
import {RatingDistributionByTimeCard} from './rating-distribution-by-time/rating-distribution-by-time-card';
import {ReviewTagsTable} from './review-tags-table/review-tags-table';
import {ReviewsAndAnswersFilter} from './reviews-and-answers-statistics-filter/reviews-and-answers-statistics-filter';
import {ReviewsAndAnswersStatisticsPdfContent} from './reviews-and-answers-statistics-pdf/reviews-and-answers-statistics-pdf';
import {ReviewsCountByCatalog} from './reviews-count-by-catalog/reviews-count-by-catalog';
import {ReviewsWithRating} from './reviews-destribution/reviews-with-rating';
import {ReviewsWithoutAnswer} from './reviews-without-answer/reviews-without-answer';
import {UsedAiSuggests} from './used-ai-suggests/used-ai-suggests';
import {UsedAutoReplies} from './used-auto-replies/used-auto-replies';
import * as styles from './reviews-and-answers-statistics.scss';

// eslint-disable-next-line complexity,max-statements
export function ReviewsAndAnswersStatistics(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {filter, dispatchFilter, lastAvailableAnalyticsDay} = useReviewsAndAnswersFilter();

    const pdfContentRef = useRef<HTMLDivElement | null>(null);

    const handlePrintClick = useReactToPrint({
        content: () => pdfContentRef.current,
    });

    const {data: reviewsCountByCatalog, isLoading: isLoadingReviewsCountByCatalog} = useReviewsCountByCatalog(filter);

    const {data: reviewsCountByRating, isLoading: isLoadingReviewsCountByRating} = useReviewsCountByRating(filter);

    const {data: reviewsRatingByDate, isLoading: isLoadingReviewsRatingByDate} = useReviewsRatingByDate(filter);

    const {data: tonalityDistribution, isLoading: isLoadingTonalityDistribution} = useTonalityDistribution(filter);

    const {data: unansweredReviewsCount, isLoading: isLoadingUnansweredReviewsCount} =
        useUnansweredReviewsCount(filter);

    const {data: usedAutoRepliesCount, isLoading: isLoadingUsedAutoRepliesCount} = useUsedAutoRepliesCount(filter);
    const {data: topTagsStats, isLoading: isLoadingTopTagsStats} = useReviewsPopularTagsStats(filter);

    const {data: usedNeuralNetworkSuggestionsCount, isLoading: isLoadingUsedNeuralNetworkSuggestionsCount} =
        useUsedNeuralNetworkSuggestionsCount(filter);

    const {data: averageResponseTime, isLoading: isLoadingAverageResponseTime} = useAverageResponseTime(filter);

    const {data: averageResponseTimeByEmployee, isLoading: isLoadingAverageResponseTimeByEmployee} =
        useAverageResponseTimeByEmployee(filter);

    const {data: averageResponseTimeByCatalog, isLoading: isLoadingAverageResponseTimeByCatalog} =
        useAverageResponseTimeByCatalog(filter);

    const [timeUnit, setTimeUnit] = useState<ReviewsAndAnswersStatisticsUnitMeasurementEnum>(
        ReviewsAndAnswersStatisticsUnitMeasurementEnum.Hours
    );

    const {isLoading: isLoadingComplaintsCount, data: complaintsCount} = useComplaintsCount(filter);
    const {isLoading: isLoadingDeletedReviews, data: deletedReviewsCount} = useDeletedReviewsCount(filter);

    const {isLoading: isRequestingReport, mutateAsync} = useRequestReviewsAndAnswersExport(filter);

    const isLoading = useMemo(() => {
        return [
            isLoadingReviewsCountByCatalog,
            isLoadingReviewsCountByRating,
            isLoadingReviewsRatingByDate,
            isLoadingTonalityDistribution,
            isLoadingUnansweredReviewsCount,
            isLoadingUsedAutoRepliesCount,
            isLoadingUsedNeuralNetworkSuggestionsCount,
            isLoadingAverageResponseTime,
            isLoadingAverageResponseTimeByEmployee,
            isLoadingAverageResponseTimeByCatalog,
            isLoadingComplaintsCount,
            isLoadingDeletedReviews,
            isLoadingTopTagsStats,
        ].some(Boolean);
    }, [
        isLoadingReviewsCountByCatalog,
        isLoadingReviewsCountByRating,
        isLoadingReviewsRatingByDate,
        isLoadingTonalityDistribution,
        isLoadingUnansweredReviewsCount,
        isLoadingUsedAutoRepliesCount,
        isLoadingUsedNeuralNetworkSuggestionsCount,
        isLoadingAverageResponseTime,
        isLoadingAverageResponseTimeByEmployee,
        isLoadingAverageResponseTimeByCatalog,
        isLoadingComplaintsCount,
        isLoadingDeletedReviews,
        isLoadingTopTagsStats,
    ]);

    const isCompareMode = useMemo(() => {
        return Boolean(filter[ReviewsAndAnswersFilterQueriesEnum.CompareMode]);
    }, [filter]);

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.reviewsManagement.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS',
                        },
                        {
                            path: appRoute.reviewsManagementReviewsAndAnswersStatistics.path,
                            titleLangKey: 'REVIEW_AND_ANSWER_STATISTICS__BREADCRUMBS',
                        },
                    ]}
                />

                <PageHeader className={styles.ReviewsAndAnswersStatistics_header}>
                    <Locale stringKey="CATEGORY_NAME__REVIEWS_AND_ANSWERS_STATISTICS" />

                    <Row gutter={[12, 12]}>
                        <Col>
                            <Button
                                className={styles.ReviewsAndAnswersStatistics_button}
                                disabled={isLoading}
                                icon={<FontAwesomeIcon icon={faFilePdf} />}
                                loading={isLoading}
                                onClick={handlePrintClick}
                            >
                                <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__DOWNLOAD_REPORT_PDF" />
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className={styles.ReviewsAndAnswersStatistics_button}
                                disabled={isRequestingReport}
                                icon={<FontAwesomeIcon icon={faFileExcel} />}
                                loading={isRequestingReport}
                                onClick={() => mutateAsync()}
                            >
                                <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__DOWNLOAD_REPORT_XLSX" />
                            </Button>
                        </Col>
                    </Row>
                </PageHeader>
                <Meta title={getLocalizedString('REVIEW_AND_ANSWER_STATISTICS__BREADCRUMBS')} />

                <MainPageCardDivider />

                <ReviewsAndAnswersFilter
                    dispatchFilter={dispatchFilter}
                    filter={filter}
                    isDisabled={false}
                    lastAvailableDate={lastAvailableAnalyticsDay}
                />
            </PageCard>

            <Row gutter={[24, 24]} justify="space-between">
                <Col xl={24} xs={24}>
                    <ReviewsWithRating
                        isCompareMode={isCompareMode}
                        reviewsCountByRating={reviewsCountByRating || null}
                    />
                </Col>

                <Col xl={24} xs={24}>
                    <RatingDistributionByTimeCard
                        isCompareMode={isCompareMode}
                        reviewsRatingByDate={reviewsRatingByDate || null}
                    />
                </Col>

                <Col xl={24} xs={24}>
                    <ReviewsCountByCatalog
                        filter={filter}
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingReviewsCountByCatalog}
                        reviewsCountByCatalog={reviewsCountByCatalog || null}
                    />
                </Col>

                <Col flex="auto" xl={12} xs={24}>
                    <UsedAutoReplies
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingUsedAutoRepliesCount}
                        usedAutoRepliesCount={usedAutoRepliesCount || null}
                    />
                </Col>
                <Col flex="auto" xl={12} xs={24}>
                    <UsedAiSuggests
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingUsedNeuralNetworkSuggestionsCount}
                        usedNeuralNetworkSuggestionsCount={usedNeuralNetworkSuggestionsCount || null}
                    />
                </Col>
            </Row>
            <Row gutter={[24, 24]} justify="space-between">
                <Col flex="auto" xl={8} xs={24}>
                    <ReviewsWithoutAnswer
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingUnansweredReviewsCount}
                        unansweredReviewsCount={unansweredReviewsCount || null}
                    />
                </Col>
                <Col flex="auto" xl={8} xs={24}>
                    <ComplaintsCount
                        complaintsCount={complaintsCount || null}
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingUnansweredReviewsCount}
                    />
                </Col>
                <Col flex="auto" xl={8} xs={24}>
                    <DeletedReviews
                        deletedReviews={deletedReviewsCount || null}
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingUsedAutoRepliesCount}
                    />
                </Col>

                <Col xs={24}>
                    <ReviewTagsTable
                        isCompareMode={isCompareMode}
                        isLoading={isLoadingTopTagsStats}
                        topTagsStats={topTagsStats || null}
                    />
                </Col>

                <Col xs={24}>
                    <AverageResponseTime
                        averageResponseTimeData={{
                            averageResponseTime: averageResponseTime || null,
                            isLoading: isLoadingAverageResponseTime,
                        }}
                        catalogStatsData={{
                            responseTimeByCatalog: averageResponseTimeByCatalog || [],
                            isLoading: isLoadingAverageResponseTimeByCatalog,
                        }}
                        employeeStatsData={{
                            responseTimeByEmployee: averageResponseTimeByEmployee || [],
                            isLoading: isLoadingAverageResponseTimeByEmployee,
                        }}
                        isCompareMode={isCompareMode}
                        reviewsCountByCatalogData={
                            {
                                reviewsCountByCatalog: reviewsCountByCatalog || null,
                                isLoading: isLoadingReviewsCountByCatalog,
                            } || null
                        }
                        setTimeUnit={setTimeUnit}
                        timeUnit={timeUnit}
                    />
                </Col>
            </Row>

            <ReviewsAndAnswersStatisticsPdfContent
                averageResponseTime={averageResponseTime || null}
                averageResponseTimeByCatalog={averageResponseTimeByCatalog || []}
                averageResponseTimeByEmployee={averageResponseTimeByEmployee || []}
                complaintsCount={complaintsCount || null}
                deletedReviews={deletedReviewsCount || null}
                filter={filter}
                isCompareMode={isCompareMode}
                ref={pdfContentRef}
                reviewsCountByCatalog={reviewsCountByCatalog || null}
                reviewsCountByRating={reviewsCountByRating || null}
                reviewsRatingByDate={reviewsRatingByDate || null}
                timeUnit={timeUnit}
                tonalityDistribution={tonalityDistribution || null}
                topTagsStats={topTagsStats || null}
                unansweredReviewsCount={unansweredReviewsCount || null}
                usedAutoRepliesCount={usedAutoRepliesCount || null}
                usedNeuralNetworkSuggestionsCount={usedNeuralNetworkSuggestionsCount || null}
            />
        </Page>
    );
}
