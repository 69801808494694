import {faArrowRightLong} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {InputNumberProps} from 'antd';

import * as styles from './range-picker.scss';

type PropsType = {
    from: (className: InputNumberProps['className'], type: InputNumberProps['type']) => JSX.Element | null;
    to: (className: InputNumberProps['className'], type: InputNumberProps['type']) => JSX.Element | null;
};

export function RangePicker(props: PropsType): JSX.Element {
    const {from, to} = props;

    return (
        <div className={styles.range_picker}>
            {from(styles.range_picker__left_input, 'number')}
            <div className={styles.range_picker__divider}>
                <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
            {to(styles.range_picker__right_input, 'number')}
        </div>
    );
}
