import * as mainStyles from '../../../../css/var-export.scss';

export function LocalPackAdvertisementResultIcon(): JSX.Element {
    return (
        <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
            <rect
                fill={mainStyles.blue50}
                height="30"
                rx="15"
                stroke={mainStyles.gray1}
                strokeWidth="2"
                width="30"
                x="1"
                y="1"
            />
            <text fill="white" fontSize="12px" textAnchor="middle" x="50%" y="20">
                Ads
            </text>
        </svg>
    );
}
