import {z as r} from 'zod';

const paginationDataSchema = r.object({
    page: r.number(),
    pageSize: r.number(),
});

export type PaginationDataType = r.infer<typeof paginationDataSchema>;

export const storagePageSizeSchema = r.object({
    storageKey: r.string().optional(),
    pageSize: r.number(),
});

export type PaginationHookType = PaginationDataType & {
    onDataLoaded: (response: {count: number}) => void;
    onDataLoadFailed: () => void;
    onChange: (pageInner: number, pageSizeInner?: number | void) => void;
    total: number;
    refreshId: string;
};

export type PaginationType = {
    pagination: PaginationHookType;
};

export type PaginationHookOptionsType = {
    dependencies: unknown;
    initialPageSize?: number;
    shouldSaveState?: boolean;
};

export type PaginationOptionsType = Partial<Omit<PaginationHookOptionsType, 'dependencies'>>;
