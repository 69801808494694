import {classNames} from '../../../../../../util/css';

// import {ReactComponent as LogoShortSvg} from './image/logo-short.svg';
import twoGisLogoShortSrc from './image/two-gis-logo-short.png';
import * as styles from './logo-short.scss';

type PropsType = {
    className?: string;
};

export function LogoShort(props: PropsType): JSX.Element {
    const {className} = props;

    const fullClassName = classNames(styles.logo_short, className);

    return <img alt="" className={fullClassName} src={twoGisLogoShortSrc} />;
}
