import {Button, TreeSelect} from 'antd';
import {DefaultOptionType} from 'antd/lib/select';
import {capitalize} from 'lodash';
import {BaseSelectRef} from 'rc-select';
import {ReactElement, UIEvent, useCallback, useEffect, useRef, useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useInfiniteTags, useReviewsAspects} from '../../../../../../service/reviews/reviews-tags';
import {handleFilterOption} from '../../../../../../util/antd/select-helper';
import {onScrollInfiniteScrollContainer} from '../../../../../../util/infinite-scroll';

import * as styles from './review-tags-tree-select.scss';

type PropsType = {
    defaultValue: Array<number>;
    onConfirm: (items: Array<number | string>) => void;
    disabled?: boolean;
};

export function ReviewTagsTreeSelect(props: PropsType): ReactElement {
    const {defaultValue, onConfirm, disabled} = props;
    const {getLocalizedString} = useLocale();
    const {data, isLoading} = useReviewsAspects();
    const {data: reviewTagsList, isFetching: allTagsListIsInProgress, hasNextPage, fetchNextPage} = useInfiniteTags({});
    const tagsData = reviewTagsList?.pages.flatMap((element) => element.results);

    const [selectedItems, setSelectedItems] = useState<Array<number | string>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const selectRef = useRef<BaseSelectRef | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!isLoading && defaultValue.length > 0) {
            const foundGroups = (data || [])
                .map(({ids}) => ids.filter((id) => defaultValue.includes(id)))
                .filter((ids) => ids.length);

            const defaultSelectItems = isLoading
                ? []
                : [
                      ...defaultValue.filter((number_) => !foundGroups.flat().includes(number_)),
                      ...foundGroups.map((ids) => ids.join(', ')),
                  ];

            setSelectedItems(defaultSelectItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue?.length, isLoading]);

    function handleCancelClick() {
        setOpen(false);
        selectRef.current?.blur();
    }

    const handleConfirmClick = useCallback(() => {
        onConfirm(selectedItems);
        setOpen(false);
        selectRef.current?.blur();
    }, [onConfirm, selectedItems]);

    function onPopupScroll(event: UIEvent) {
        onScrollInfiniteScrollContainer(event, () => hasNextPage && !allTagsListIsInProgress && fetchNextPage(), 50);
    }

    const autotagsData =
        data && data.length > 0
            ? {
                  value: 'parent 1-0',
                  title: getLocalizedString('REVIEWS__AUTOTAGS_POPUP__HEADER'),
                  children: (data || []).map(({ids, title}) => ({
                      value: ids.join(', '),
                      label: capitalize(title),
                  })),
              }
            : null;

    function dropdownRender(menu: ReactElement): ReactElement {
        return (
            <div>
                <div className={styles.TagsSelect_dropdownMenu}>{menu}</div>
                <div className={styles.TagsSelect_infiniteSpinnerFaux}>
                    {allTagsListIsInProgress && (
                        <div className={styles.TagsSelect_infiniteSpinner}>
                            <Spinner size={20} spinSize="small" />
                        </div>
                    )}
                </div>

                <div className={styles.TagsSelect_dropdownFooter}>
                    <Button onClick={handleCancelClick} size="small" type="text">
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>
                    <div>
                        {selectedItems.length > 0 && (
                            <span className={styles.TagsSelect_selectedCount}>
                                <Locale
                                    stringKey="DROPDOWN_WITH_CONFIRM_COUNT"
                                    valueMap={{count: selectedItems.length}}
                                />
                            </span>
                        )}
                        <Button onClick={handleConfirmClick} size="small" type="primary">
                            <Locale stringKey="TEXT__OK" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    const treeData = [
        {
            value: 'parent 1',
            title: getLocalizedString('TEXT__SELECT_ALL'),
            isLeaf: true,
            children: [
                ...(autotagsData ? [autotagsData] : []),
                ...(tagsData || []).map(({id, title}): {label: string; value: number} => ({
                    value: id,
                    label: title,
                })),
            ],
        },
    ];

    useEffect(() => {
        function handleClick({target}: MouseEvent) {
            if (!containerRef.current?.contains(target as HTMLElement) && open) {
                handleConfirmClick();
            }
        }

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, [handleConfirmClick, open]);

    function maxTagPlaceholder() {
        return (
            <Locale
                stringKey="DROPDOWN_WITH_CONFIRM_COUNT"
                valueMap={{
                    count: <Text large>{selectedItems.length}</Text>,
                }}
            />
        );
    }

    return (
        <div ref={containerRef}>
            <TreeSelect
                disabled={disabled}
                dropdownRender={dropdownRender}
                filterTreeNode={(input, filterTreeData) =>
                    handleFilterOption(input, filterTreeData as DefaultOptionType)
                }
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                loading={allTagsListIsInProgress}
                maxTagCount={0}
                maxTagPlaceholder={maxTagPlaceholder}
                multiple
                onChange={(items) => setSelectedItems(items)}
                onFocus={() => setOpen(true)}
                onPopupScroll={onPopupScroll}
                open={open}
                placeholder={getLocalizedString('REVIEWS__FILTER__SELECT_TAGS')}
                ref={selectRef}
                treeCheckable
                treeData={treeData}
                treeDefaultExpandAll
                value={selectedItems}
            />
        </div>
    );
}
