import {fetchAndDeserialize} from '../../util/api-adapter';
import {getIsoYyyyMmDdString} from '../../util/date';
import {ObjectToUrlParametersType} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {fetchReviewsResultSchema, FetchReviewsResultType} from '../reviews/reviews-type';

import {
    AutoRepliesSummaryDatesDataType,
    autoRepliesSummaryResponseSchema,
    AutoRepliesSummaryType,
    CatalogsSyncResponseType,
    catalogsSyncStatisticsResponseSchema,
    CatalogStatisticsType,
    companyInfoSchema,
    CompanyInfoType,
    companyMapResponseSchema,
    CompanyMapType,
    companyRatingSchema,
    CompanyRatingType,
    companyStatsSchema,
    CompanyStatsType,
    companySummarySchema,
    CompanySummaryType,
    dashboardManyCompaniesGoodsSchema,
    DashboardManyCompaniesGoodsStatsType,
    dashboardSearchPhrasesSchema,
    DashboardSearchPhrasesType,
    FetchTimelineAggregatedResultType,
    giftInfoSchema,
    GiftInfoType,
    OnlinePresencePropsType,
    OnlinePresenceResponseType,
    onlinePresenceSchema,
    ReviewsBasePaginationType,
    ReviewsDashboardStatisticDataType,
    reviewsDashboardStatisticSchema,
    ReviewsDashboardStatisticsType,
    ReviewsRatingDistributionDatesDataType,
    reviewsRatingDistributionListResponseSchema,
    ReviewsRatingDistributionType,
    syncStatisticsResponseSchema,
    SyncStatisticsType,
    timelineAggregatedActivityResponseSchema,
} from './dashboard-type';

export async function fetchLastReviews(paginationData: ReviewsBasePaginationType): Promise<FetchReviewsResultType> {
    const urlParameters: ObjectToUrlParametersType = {
        per_page: paginationData.pageSize,
        date_gte: getIsoYyyyMmDdString(paginationData.dateGte),
    };
    const url = `/v4/reviews/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<FetchReviewsResultType>(url, fetchReviewsResultSchema);
}

export function fetchReviewsDashboardStatistic(
    props: ReviewsDashboardStatisticsType
): Promise<ReviewsDashboardStatisticDataType> {
    const {dateGte} = props;
    const searchParameters = objectToUrlParameters({
        date_gte: getIsoYyyyMmDdString(dateGte),
    });
    const url = `/cp/dashboard/reviews_statistic/?${searchParameters}`;

    return fetchAndDeserialize<ReviewsDashboardStatisticDataType>(url, reviewsDashboardStatisticSchema);
}

export async function getCompanySyncStatistics(id: number): Promise<Array<SyncStatisticsType>> {
    const url = `/v1/analytics/company/sync-statistics/${id}/`;

    return fetchAndDeserialize<Array<SyncStatisticsType>>(url, syncStatisticsResponseSchema.array());
}

export function getCompanyRating(id: number): Promise<Array<CompanyRatingType>> {
    const url = `/v1/analytics/company/rating_by_catalog/${id}/`;

    return fetchAndDeserialize<Array<CompanyRatingType>>(url, companyRatingSchema.array());
}

export async function loadCatalogsSyncStatistics(props: CatalogStatisticsType): Promise<CatalogsSyncResponseType> {
    const {dateFrom, dateTo} = props;
    const searchParameters = objectToUrlParameters({
        start_date: getIsoYyyyMmDdString(dateFrom),
        end_date: getIsoYyyyMmDdString(dateTo),
    });
    const url = `/cp/dashboard/sync_statistic/?${searchParameters}`;

    return fetchAndDeserialize<CatalogsSyncResponseType>(url, catalogsSyncStatisticsResponseSchema);
}

export async function getAutoRepliesSummary(
    datesData: AutoRepliesSummaryDatesDataType
): Promise<AutoRepliesSummaryType> {
    const url = `/cp/dashboard/auto_replies_summary/?${objectToUrlParameters({
        start_date: datesData.startDate,
        end_date: datesData.endDate,
    })}`;

    return fetchAndDeserialize<AutoRepliesSummaryType>(url, autoRepliesSummaryResponseSchema);
}

export async function getReviewsRatingDistribution(
    datesData: ReviewsRatingDistributionDatesDataType
): Promise<Array<ReviewsRatingDistributionType>> {
    const url = `/cp/dashboard/reviews_rating_distribution/?${objectToUrlParameters({
        start_date: datesData.startDate,
        end_date: datesData.endDate,
    })}`;

    return fetchAndDeserialize<Array<ReviewsRatingDistributionType>>(
        url,
        reviewsRatingDistributionListResponseSchema.array()
    );
}

export async function getDashboardSearchPhrases(): Promise<DashboardSearchPhrasesType> {
    const url = `/cp/dashboard/search_phrases/`;

    return fetchAndDeserialize<DashboardSearchPhrasesType>(url, dashboardSearchPhrasesSchema);
}

export function fetchManyCompaniesGoodsStats(): Promise<Array<DashboardManyCompaniesGoodsStatsType>> {
    const url = `/cp/prices/companies_with_prices/`;

    return fetchAndDeserialize<Array<DashboardManyCompaniesGoodsStatsType>>(
        url,
        dashboardManyCompaniesGoodsSchema.array()
    );
}

export async function getTimelineForManyCompanies(
    cursor: string | null,
    perPage?: number
): Promise<FetchTimelineAggregatedResultType> {
    const searchParameters = objectToUrlParameters({
        cursor,
        per_page: perPage,
        timestamp: Date.now(),
    });
    const url = `/cp/dashboard/timeline/aggregated_by_hour/?${searchParameters}`;

    return fetchAndDeserialize<FetchTimelineAggregatedResultType>(url, timelineAggregatedActivityResponseSchema);
}

export async function loadOnlinePresenceStatistics(
    props: OnlinePresencePropsType
): Promise<OnlinePresenceResponseType> {
    const {dateFrom, dateTo} = props;
    const searchParameters = objectToUrlParameters({
        start_date: getIsoYyyyMmDdString(dateFrom),
        end_date: getIsoYyyyMmDdString(dateTo),
    });
    const url = `/cp/dashboard/online_presence_stats/?${searchParameters}`;

    return fetchAndDeserialize<OnlinePresenceResponseType>(url, onlinePresenceSchema);
}

export async function getCompanyMapInfo(companyId: number): Promise<CompanyMapType> {
    const url = `/cp/dashboard/map_company/${companyId}/`;

    return fetchAndDeserialize<CompanyMapType>(url, companyMapResponseSchema);
}

export async function getCompanySummary(): Promise<CompanySummaryType> {
    const url = `/cp/dashboard/company_summary/`;

    return fetchAndDeserialize<CompanySummaryType>(url, companySummarySchema);
}

export async function getCompanyStats(companyId: number): Promise<CompanyStatsType> {
    const url = `/cp/company_stats/${companyId}/`;

    return fetchAndDeserialize<CompanyStatsType>(url, companyStatsSchema);
}

export async function getCompanyInfo(companyId: number): Promise<CompanyInfoType> {
    const url = `/cp/data_completeness/company_data_completeness/${companyId}/`;

    return fetchAndDeserialize<CompanyInfoType>(url, companyInfoSchema);
}

export const giftUrl = '/cp/user/bw_bonus_notification/';

export async function getGiftInfo(): Promise<GiftInfoType> {
    return fetchAndDeserialize<GiftInfoType>(giftUrl, giftInfoSchema);
}
