import {ExtractRouteParams, generatePath} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';

import {SingleAccountMatchingContent} from './components/single-account-matching-content';

export function SingleAccountMatching(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {accountsMatching, sources} = appRoute;

    const {providerId, companyId, brandId} = useParams<ExtractRouteParams<typeof accountsMatching.path, string>>();

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__COMPANIES_MATCHING')} />

            <BreadCrumbs
                list={[
                    {
                        path: generatePath(sources.path, {providerId, companyId, brandId}),
                        titleLangKey: 'CATEGORY_NAME__SOURCES',
                    },
                    {
                        path: generatePath(accountsMatching.path, {providerId, companyId, brandId}),
                        titleLangKey: 'CATEGORY_NAME__COMPANIES_MATCHING__SINGLE',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__COMPANIES_MATCHING__SINGLE" />
            </PageHeader>

            <SingleAccountMatchingContent
                brandId={Number(brandId)}
                companyId={Number(companyId)}
                providerId={Number(providerId)}
            />
        </Page>
    );
}
