import {LangKeyType} from '../../../provider/locale/translation/type';
import {Text} from '../../text/text';

import {LandingAdvantagesCard} from './landing-advantages-card';
import {LandingAdvantagesCardPropsType} from './landing-advantages-type';
import * as styles from './landing-advantages.scss';

type PropsType = {
    cardsData: Array<LandingAdvantagesCardPropsType>;
    titleLangKey: LangKeyType;
};

export function LandingAdvantages(props: PropsType): JSX.Element {
    const {cardsData, titleLangKey} = props;

    return (
        <div className={styles.LandingAdvantages}>
            <Text block bolder className={styles.LandingAdvantages_title} stringKey={titleLangKey} />
            <div className={styles.LandingAdvantages_cardsContainer}>
                {cardsData.map(({icon, titleLangKey: cardTitleLangKey, descriptionLangKey}) => (
                    <LandingAdvantagesCard
                        descriptionLangKey={descriptionLangKey}
                        icon={icon}
                        key={cardTitleLangKey}
                        titleLangKey={cardTitleLangKey}
                    />
                ))}
            </div>
        </div>
    );
}
