import {AnyZodObject, z as r} from 'zod';

import {deserializeV2} from '../../util/api-adapter';
import {createRaceFetchX, FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    CompaniesApiRequestType,
    companiesSelectorIdResponseSchema,
    CompaniesSelectorIdResponseType,
    CompanySelectorCompaniesResponseType,
    getCompaniesSelectorCompaniesSchema,
    UpdateSelectorCompaniesRequestType,
    updateSelectorCompaniesSchema,
    UpdateSelectorCompaniesType,
} from './feature-companies-api-type';
import {RestoreCompanySelectorOptionsType, selectorBrandSchema, SelectorBrandType} from './feature-companies-type';

export async function restoreCompaniesSelectorId(
    options: RestoreCompanySelectorOptionsType
): Promise<CompaniesSelectorIdResponseType> {
    const url = `${rootApiUrl}/v1/companies_selector/${options.feature}/reconstruct/`;
    const result = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {
            ...mainApiHeaders,
            ...getCsrfHeaders(),
        },
        body: JSON.stringify({
            pk: options.resourceId,
        }),
    });

    return deserializeV2<CompaniesSelectorIdResponseType>(url, companiesSelectorIdResponseSchema, result);
}

export async function generateCompaniesSelectorIdApi(feature: string): Promise<CompaniesSelectorIdResponseType> {
    const url = `${rootApiUrl}/v1/companies_selector/${feature}/`;
    const result = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {
            ...mainApiHeaders,
            ...getCsrfHeaders(),
        },
    });

    return deserializeV2<CompaniesSelectorIdResponseType>(url, companiesSelectorIdResponseSchema, result);
}

export async function getBrandsListApi(options: {
    selectorId: string;
    feature: string;
}): Promise<Array<SelectorBrandType>> {
    const url = `${rootApiUrl}/v1/companies_selector/${options.feature}/${options.selectorId}/brands/`;
    const result = await fetchX(url, {
        method: FetchMethodEnum.get,
        headers: {
            ...mainApiHeaders,
            ...getCsrfHeaders(),
        },
    });

    return deserializeV2<Array<SelectorBrandType>>(url, r.array(selectorBrandSchema), result);
}

const companySelectorCompaniesRaceFetchX = createRaceFetchX();

export async function getCompaniesListApi<CompanyType>(options: {
    selectorId: string;
    body: CompaniesApiRequestType;
    feature: string;
    companySchema: AnyZodObject;
    useRaceFetch?: boolean;
}): Promise<CompanySelectorCompaniesResponseType<CompanyType>> {
    const {feature, selectorId, body, companySchema, useRaceFetch = true} = options;

    const url = `${rootApiUrl}/v1/companies_selector/${feature}/${selectorId}/list/`;

    const result = await (useRaceFetch ? companySelectorCompaniesRaceFetchX : fetchX)(url, {
        method: FetchMethodEnum.post,
        headers: {
            ...mainApiHeaders,
            ...getCsrfHeaders(),
        },
        body: JSON.stringify(body),
    });

    return deserializeV2<CompanySelectorCompaniesResponseType<CompanyType>>(
        url,
        getCompaniesSelectorCompaniesSchema(companySchema),
        result
    );
}

export async function addCompaniesApi(options: {
    selectorId: string;
    body: UpdateSelectorCompaniesRequestType;
    feature: string;
}): Promise<UpdateSelectorCompaniesType> {
    const url = `${rootApiUrl}/v1/companies_selector/${options.feature}/${options.selectorId}/add/`;

    const result = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {
            ...mainApiHeaders,
            ...getCsrfHeaders(),
        },
        body: JSON.stringify(options.body),
    });

    return deserializeV2<UpdateSelectorCompaniesType>(url, updateSelectorCompaniesSchema, result);
}

export async function excludeCompaniesApi(options: {
    selectorId: string;
    body: UpdateSelectorCompaniesRequestType;
    feature: string;
}): Promise<UpdateSelectorCompaniesType> {
    const url = `${rootApiUrl}/v1/companies_selector/${options.feature}/${options.selectorId}/remove/`;

    const result = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {
            ...mainApiHeaders,
            ...getCsrfHeaders(),
        },
        body: JSON.stringify(options.body),
    });

    return deserializeV2<UpdateSelectorCompaniesType>(url, updateSelectorCompaniesSchema, result);
}
