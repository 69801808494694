import {Alert, Tabs} from 'antd';

import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {PopoverTextWrapper} from '../../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useOnlinePresenceSummary} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-hook';

import {OnlinePresenceSummaryCatalog} from './online-presence-summary-catalog';
import * as styles from './online-presence-summary.scss';

type PropsType = {
    companyId: number;
};

export function OnlinePresenceSummary(props: PropsType): JSX.Element {
    const {companyId} = props;

    const {getLocalizedString} = useLocale();

    const {result, isInProgress, processError} = useOnlinePresenceSummary(companyId);
    const {getCatalogName} = useCatalogInfo();

    return (
        <PageCard
            title={
                <>
                    <div>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__TITLE" />
                    </div>
                    <AdditionalInfo
                        title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__POPOVER_HEADER" />}
                    >
                        <PopoverTextWrapper>
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__POPOVER_DESCRIPTION" />
                        </PopoverTextWrapper>
                    </AdditionalInfo>
                </>
            }
        >
            {isInProgress && <Spinner />}

            {processError && <Alert message={getLocalizedString('ERROR__SOMETHING_WENT_WRONG')} type="error" />}

            {result && (
                <Tabs
                    items={result.results.map((item) => {
                        return {
                            key: item.catalog.id.toString(),
                            label: getCatalogName(item.catalog.id),
                            className: styles.OnlinePresenceSummary_tab,
                            children: <OnlinePresenceSummaryCatalog data={item} />,
                        };
                    })}
                />
            )}
        </PageCard>
    );
}
