import {Button, Form, Input} from 'antd';
import {useCallback} from 'react';

import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {useUpdateProfilePassword} from '../../../../service/profile/profile-hook';
import {ProfileNewPasswordType} from '../../../../service/profile/profile-type';
import {classNames} from '../../../../util/css';
import {getMapFromObject} from '../../../../util/object';
import * as profileStyles from '../profile.scss';
import {defaultChangePassword} from '../profile-const';
import {ChangePasswordType} from '../profile-type';

import {passwordChangeFieldsNames} from './password-change-const';
import * as styles from './password-change.scss';

type PropsType = {
    className?: string;
};

export function PasswordChange(props: PropsType): JSX.Element {
    const {className} = props;
    const [form] = Form.useForm();
    const {minLengthFieldRule, requiredFieldRule, validatePasswordRules} = useFormRules();
    const fullClassName = classNames(styles.password_change, className);
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const {isInProgress, updatePassword, processError: updatePasswordError} = useUpdateProfilePassword();

    const onFormSubmit = useCallback(async () => {
        const {repeatNewPassword, newPassword, currentPassword} = getMapFromObject<ChangePasswordType>(
            form.getFieldsValue(),
            defaultChangePassword
        );

        const formData: ProfileNewPasswordType = {
            password: currentPassword,
            new_password: newPassword,
            new_password_repeat: repeatNewPassword,
        };

        try {
            await updatePassword(formData);
            snackbar.success(<Locale stringKey="PROFILE__PASSWORD_CHANGE__UPDATED" />);
        } catch (error) {
            if (!(error instanceof Error)) {
                snackbar.error({
                    description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                });

                return;
            }

            const errors = JSON.parse(error.message);

            if (errors.new_password) {
                snackbar.error({
                    message: <Locale stringKey="PROFILE__PASSWORD_CHANGE__UPDATE_FAIL" />,
                    description: <Locale stringKey="PROFILE__PASSWORD_CHANGE__UPDATE_FAIL_INCORRECT_NEW_PASSWORD" />,
                });
            }

            if (errors.password) {
                snackbar.error({
                    message: <Locale stringKey="PROFILE__PASSWORD_CHANGE__UPDATE_FAIL" />,
                    description: <Locale stringKey="PROFILE__PASSWORD_CHANGE__UPDATE_FAIL_INCORRECT_PASSWORD" />,
                });
            }

            return;
        }
    }, [form, updatePassword, snackbar]);

    return (
        <Form
            className={fullClassName}
            form={form}
            layout="vertical"
            name="personal-information"
            onFinish={onFormSubmit}
        >
            <PageSubHeader>
                <Locale stringKey="PROFILE__PASSWORD_CHANGE__SUB_HEADER" />
            </PageSubHeader>

            <div className={styles.password_change__input_list_wrapper}>
                <Form.Item
                    className={styles.password_change__input}
                    label={<Locale stringKey="PROFILE__PASSWORD_CHANGE__LABEL__CURRENT_PASSWORD" />}
                    name={passwordChangeFieldsNames.currentPassword}
                    rules={[requiredFieldRule, minLengthFieldRule(6)]}
                >
                    <Input.Password
                        placeholder={getLocalizedString('PROFILE__PASSWORD_CHANGE__PLACEHOLDER__CURRENT_PASSWORD')}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    className={styles.password_change__input}
                    hasFeedback
                    label={<Locale stringKey="PROFILE__PASSWORD_CHANGE__LABEL__NEW_PASSWORD" />}
                    name={passwordChangeFieldsNames.newPassword}
                    rules={[requiredFieldRule, ...validatePasswordRules()]}
                >
                    <Input.Password
                        placeholder={getLocalizedString('PROFILE__PASSWORD_CHANGE__PLACEHOLDER__NEW_PASSWORD')}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    className={styles.password_change__input}
                    dependencies={[passwordChangeFieldsNames.newPassword]}
                    hasFeedback
                    label={<Locale stringKey="PROFILE__PASSWORD_CHANGE__LABEL__REPEAT_NEW_PASSWORD" />}
                    name={passwordChangeFieldsNames.repeatNewPassword}
                    rules={[
                        requiredFieldRule,
                        ...validatePasswordRules(),
                        () => ({
                            validator(_formItemData: unknown, value: string): Promise<void> {
                                if (!value || form.getFieldValue(passwordChangeFieldsNames.newPassword) === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject();
                            },
                            message: (
                                <Locale stringKey="VALIDATION__ERROR__THE_TWO_PASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH" />
                            ),
                        }),
                    ]}
                >
                    <Input.Password
                        placeholder={getLocalizedString('PROFILE__PASSWORD_CHANGE__PLACEHOLDER__REPEAT_NEW_PASSWORD')}
                        size="large"
                    />
                </Form.Item>
            </div>

            {updatePasswordError && <p className={profileStyles.profile__error_message}>{updatePasswordError}</p>}

            <Button htmlType="submit" loading={isInProgress} type="primary">
                <Locale stringKey="BUTTON__CHANGE_PASSWORD" />
            </Button>
        </Form>
    );
}
