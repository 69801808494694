import {Timeline} from 'antd';

import {useTimelineForManyCompanies} from '../../../../service/dashboard/dashboard-hook';
import {AggregatedActionsType} from '../../../../service/dashboard/dashboard-type';

import {TimelineActivity} from './timeline-activity';
import {timelinePerPage} from './timeline-activity-aggregated-const';
import {TimelineAggregatedCard} from './timeline-aggregated-card/timeline-aggregated-card';
import * as styles from './timeline-activity.scss';

export function TimelineActivityAggregated(): JSX.Element {
    const {result, isInProgress, processError, loadMore, canLoadMore, refresh} =
        useTimelineForManyCompanies(timelinePerPage);

    return (
        <TimelineActivity<AggregatedActionsType>
            canLoadMore={canLoadMore}
            isDashboardWidget={false}
            isLoading={isInProgress}
            items={result}
            loadMore={loadMore}
            processError={processError}
            refresh={refresh}
            renderTimelineItem={(timelineData: AggregatedActionsType) => (
                <Timeline.Item className={styles.TimelineActivity_item} key={timelineData.timestamp.start}>
                    <TimelineAggregatedCard timelineData={timelineData} />
                </Timeline.Item>
            )}
        />
    );
}
