import {z as r} from 'zod';

import {CompanyNameStyleEnum} from '../../../page/main/reviews-generator/pages/questionnare/form/questionnaire-settings/multisurvey-settings/multisurvey-settings-helper';
import {generateResponseSchema} from '../../api/api-type';

export enum QuestionnaireListStatusEnum {
    Ready = 'ready',
    Released = 'released',
}

const questionnaireListItemSchema = r.object({
    id: r.number(),
    code: r.string(),
    name: r.string(),
    status: r.nativeEnum(QuestionnaireListStatusEnum),
});

export type QuestionnaireListItemType = r.infer<typeof questionnaireListItemSchema>;
export const questionnaireListSchema = generateResponseSchema(questionnaireListItemSchema);

export type QuestionnaireListType = r.infer<typeof questionnaireListSchema>;

export enum SourceItemTypeEnum {
    Catalog = 'catalog',
    CustomLink = 'custom_link',
}

const catalogSourceItemSchema = r.object({
    type: r.literal(SourceItemTypeEnum.Catalog),
    ordering: r.number(),
    catalogId: r.number(),
});

const customLinkSourceItemSchema = r.object({
    type: r.literal(SourceItemTypeEnum.CustomLink),
    ordering: r.number(),
    linkUrl: r.string(),
    linkText: r.string(),
    logoId: r.string().nullable(),
    logoUrl: r.string().nullable(),
    catalogId: r.string().nullable(),
});

const sourceItemSchema = r.union([catalogSourceItemSchema, customLinkSourceItemSchema]);

export type QuestionnaireSourceItemType = r.infer<typeof sourceItemSchema>;

export enum ReviewGeneratorPromoCodeTypeEnum {
    Positive = 'positive',
    Negative = 'negative',
    All = 'all',
}

export enum QuestionnaireStatusEnum {
    Ready = 'ready',
    Temporary = 'temporary',
}

export enum ReviewGeneratorRatingScaleTypeEnum {
    tenPoint = 'ten_point',
    fivePoint = 'five_point',
    twoPoint = 'two_point',
}

export enum ReviewGeneratorQuestionnaireFormFieldsEnum {
    id = 'id',
    name = 'name',
    brand = 'brandId',
    customBrandName = 'customBrandName',
    catalogs = 'catalogLinks',
    negativeCatalogs = 'negativeCatalogLinks',
    negativeReviewMode = 'negativeReviewMode',

    skipQuestionnaireRating = 'skipInitAssessment',
    questionText = 'questionText',
    ratingText = 'ratingText',
    positiveReaction = 'positiveReactionText',
    negativeReaction = 'negativeReactionText',
    thanksToNegative = 'thanksNegativeReactionText',
    thanksToPositive = 'thanksPositiveReactionText',
    thresholdOfPositivity = 'thresholdOfPositivity',
    emailsForNegative = 'emailForNegativeReviews',
    notificationSettings = 'notificationSettings',
    telegramForNegative = 'tgNotifications',
    tgChatToken = 'tgChatToken',
    termsAndConditionsLanguage = 'languagePrivacy',
    negativeReactionFormSettings = 'negativeReactionFormSettings',
    positiveRatingButtonName = 'positiveRatingButtonName',
    negativeRatingButtonName = 'negativeRatingButtonName',
    ratingScaleType = 'ratingScaleType',
    customRatingButtonName = 'customRatingButtonName',
    canSendPromoCodeOnReview = 'canSendPromoCodeOnReview',
    promoCodeId = 'promoAction',
    promoReviewType = 'promoType',
    promoText = 'promoText',
    promoTextThanks = 'promoTextThanks',
    logo = 'logo',
    color = 'color',
    logoHeight = 'logoHeight',
    brandTemplateSettings = 'brandTemplateSettings',
    shouldSaveTemplateSettings = 'shouldSaveTemplateSettings',
    mailingSubscriptionSettings = 'mailingSubscriptionSettings',
    directRedirect = 'directRedirect',
    displayAddress = 'displayAddress',
    multiSurveyTitle = 'multiSurveyTitle',
    multiSurveySearchPlaceholder = 'multiSurveySearchPlaceholder',
    multiSurveyButton = 'multiSurveyButton',
    multiSurveyCompanyNameDisplay = 'multiSurveyCompanyNameDisplay',
    autoTagOption = 'allowReviewMapping',
    textSettingsCollapseItems = 'textSettingsCollapseItems',
}

export const reviewGeneratorQuestionnaireFormSchema = r.object({
    [ReviewGeneratorQuestionnaireFormFieldsEnum.id]: r.number().optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.name]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.brand]: r.number().optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.customBrandName]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.questionText]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.ratingText]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.positiveReaction]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.negativeReaction]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.thanksToNegative]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.thanksToPositive]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.thresholdOfPositivity]: r.number(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.emailsForNegative]: r.string().nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.notificationSettings]: r
        .object({
            [ReviewGeneratorQuestionnaireFormFieldsEnum.telegramForNegative]: r.boolean(),
            [ReviewGeneratorQuestionnaireFormFieldsEnum.tgChatToken]: r.string().nullable(),
        })
        .optional()
        .nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.termsAndConditionsLanguage]: r.string().nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.positiveRatingButtonName]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.negativeRatingButtonName]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.customRatingButtonName]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.ratingScaleType]: r.nativeEnum(ReviewGeneratorRatingScaleTypeEnum),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.negativeReactionFormSettings]: r.object({
        name: r.object({
            show: r.boolean(),
            isRequired: r.boolean(),
            label: r.string(),
            placeholder: r.string(),
        }),
        email: r.object({
            show: r.boolean(),
            isRequired: r.boolean(),
            label: r.string(),
        }),
        phone: r.object({
            show: r.boolean(),
            isRequired: r.boolean(),
            label: r.string(),
        }),
        text: r.object({
            label: r.string(),
            placeholder: r.string(),
        }),
        submitButton: r.object({
            label: r.string(),
        }),
        skipButton: r.object({
            label: r.string(),
        }),
        reviewButton: r.object({
            label: r.string(),
        }),
    }),

    [ReviewGeneratorQuestionnaireFormFieldsEnum.canSendPromoCodeOnReview]: r.boolean().optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.promoCodeId]: r.number().nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.promoReviewType]: r
        .nativeEnum(ReviewGeneratorPromoCodeTypeEnum)
        .nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.promoText]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.promoTextThanks]: r.string(),

    [ReviewGeneratorQuestionnaireFormFieldsEnum.logo]: r.string().nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.brandTemplateSettings]: r.number().nullable(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.color]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.shouldSaveTemplateSettings]: r.boolean().optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.mailingSubscriptionSettings]: r.object({
        ratingPageText: r.string(),
        ratingPageUnsubscribeLinkText: r.string(),

        subscribeTitle: r.string(),
        subscribeText: r.string(),

        returnToSurveyButtonText: r.string(),

        subscribeAgainText: r.string(),
        subscribeAgainButtonText: r.string(),

        unsubscribeTitle: r.string(),
        unsubscribeText: r.string(),
    }),
    code: r.string().nullable().optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.directRedirect]: r.boolean().optional(),

    [ReviewGeneratorQuestionnaireFormFieldsEnum.skipQuestionnaireRating]: r.boolean(),

    [ReviewGeneratorQuestionnaireFormFieldsEnum.catalogs]: r.array(sourceItemSchema).optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.negativeCatalogs]: r.array(sourceItemSchema).optional(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.negativeReviewMode]: r
        .union([r.literal('form'), r.literal('catalogs')])
        .optional(),

    [ReviewGeneratorQuestionnaireFormFieldsEnum.logoHeight]: r.number(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.displayAddress]: r.boolean(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.multiSurveyTitle]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.multiSurveySearchPlaceholder]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.multiSurveyButton]: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.multiSurveyCompanyNameDisplay]: r.nativeEnum(CompanyNameStyleEnum),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.autoTagOption]: r.boolean(),
});

export type ReviewGeneratorQuestionnaireFormType = r.infer<typeof reviewGeneratorQuestionnaireFormSchema>;

export const questionnaireRetrieveSchema = r.object({
    id: r.number(),
    name: r.string(),
    code: r.string(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.catalogs]: r.array(sourceItemSchema).optional(),
    directRedirect: r.boolean(),

    [ReviewGeneratorQuestionnaireFormFieldsEnum.skipQuestionnaireRating]: r.boolean(),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.promoReviewType]: r.nativeEnum(ReviewGeneratorPromoCodeTypeEnum),
    [ReviewGeneratorQuestionnaireFormFieldsEnum.promoCodeId]: r.number().nullable(),
});

export type ReviewGeneratorRetrieveQuestionnaireType = r.infer<typeof questionnaireRetrieveSchema>;

export const questionnaireBrandTemplateSettingsSchema = r.object({
    color: r.string(),
    id: r.number(),
    logo: r.string().nullable(),
    logoHeight: r.number(),
});

export const uploadQuestionnaireLogoResponseSchema = r.object({
    logo: r.string(),
});

export type UploadQuestionnaireLogoResponseType = r.infer<typeof uploadQuestionnaireLogoResponseSchema>;

export type QuestionnaireBrandTemplateSettingsType = r.infer<typeof questionnaireBrandTemplateSettingsSchema>;

export const uploadLinkImageResponseSchema = r.object({
    id: r.string(),
    url: r.string(),
});

export type UploadQuestionnaireChangeLinkLogoUrlType = r.infer<typeof uploadLinkImageResponseSchema>;
