import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Input, Select, Tooltip} from 'antd';
import {ChangeEvent} from 'react';

import {Locale} from '../../../../../../../../provider/locale/locale';
import {ProtocolEnum} from '../../../../../../../../service/company-v2/company-const';
import {
    CompanyFormSocialNetworkType,
    CompanyFormWebsiteType,
} from '../../../../../../../../service/company-v2/types/company-links-type';
import {Form} from '../../../../../../../../typings/antd';
import {classNames} from '../../../../../../../../util/css';
import {extractProtocol} from '../../../../company-form-helper';

import * as styles from './url-input.scss';

type PropsType = {
    value?: CompanyFormWebsiteType | CompanyFormSocialNetworkType;
    onChange?: (newValue: Partial<CompanyFormWebsiteType | CompanyFormSocialNetworkType>) => void;
    index: number;
    placeholder: string;
    httpsOnly?: boolean;
};

export function UrlInput(props: PropsType): JSX.Element {
    const {value, onChange, index, placeholder, httpsOnly} = props;
    const {status} = Form.Item.useStatus();

    function handleValueChange(event: ChangeEvent<HTMLInputElement>) {
        const protocolData = extractProtocol(event.target.value);

        if (!protocolData) {
            onChange?.({
                ...value,
                value: event.target.value,
            });

            return;
        }

        onChange?.({
            ...value,
            value: protocolData.value,
            protocol: httpsOnly ? value?.protocol : protocolData.protocol,
        });
    }

    function handleProtocolChange(protocol: ProtocolEnum) {
        if (httpsOnly) {
            return;
        }

        onChange?.({
            ...(value || {value: ''}),
            protocol,
        });
    }

    const suffixIconProps: {suffixIcon?: null} = {};

    if (httpsOnly) {
        suffixIconProps.suffixIcon = null;
    }

    return (
        <Input
            addonBefore={
                <Select<ProtocolEnum>
                    className={classNames({
                        [styles.url_input__select_disabled]: httpsOnly,
                    })}
                    onChange={handleProtocolChange}
                    size={!index ? 'large' : 'middle'}
                    value={value?.protocol}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...suffixIconProps}
                >
                    {!httpsOnly && <Select.Option value={ProtocolEnum.HTTP}>{ProtocolEnum.HTTP}</Select.Option>}
                    <Select.Option value={ProtocolEnum.HTTPS}>{ProtocolEnum.HTTPS}</Select.Option>
                </Select>
            }
            className={classNames({[styles.warningSelect]: status === 'warning'})}
            onChange={handleValueChange}
            placeholder={placeholder}
            size={!index ? 'large' : 'middle'}
            suffix={
                <div>
                    {status === 'warning' ? (
                        <Tooltip
                            placement="bottom"
                            title={<Locale stringKey="COMPANY_FORM__LINKS__SOCIAL_NETWORKS__TOOLTIP__WARNING" />}
                        >
                            <FontAwesomeIcon className={styles.icon} icon={faCircleExclamation} />
                        </Tooltip>
                    ) : (
                        // if we return null outside the div, an extra render appears and the field loses focus
                        ''
                    )}
                </div>
            }
            value={value?.value}
        />
    );
}
