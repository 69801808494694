import {camelize} from 'humps';
import {ReactElement} from 'react';

import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {useTariffLicenses} from '../../../../service/tariffs/tariffs-licenses';

import {License} from './license/license';
import {LicensesBlockEnum} from './licenses-block-type';
import * as styles from './licenses-block.scss';

type PropsType = {
    icon: ReactElement;
    name: LangKeyType;
    type: LicensesBlockEnum;
    tariffId: number;
    description?: LangKeyType;
};

export function LicensesBlock(props: PropsType): ReactElement {
    const {name, description, icon, tariffId, type} = props;

    const {licenseBalance, licenseData, refetch} = useTariffLicenses(tariffId);

    return (
        <PageCard className={styles.LicensesBlock}>
            <div className={styles.LicensesBlock_header}>
                {icon}
                <div className={styles.LicensesBlock_nameWrapper}>
                    <Text className={styles.LicensesBlock_name} stringKey={name} />
                    {description && <Text large secondary stringKey={description} />}
                </div>
            </div>
            {licenseData[`${type}`]?.map((license) => {
                const licenseCode = camelize(license.licenceCode);
                const licenseBalanceKey = `${licenseCode}Balance` as keyof typeof licenseBalance;
                const uniqueLicenseBalance = licenseBalance[licenseBalanceKey] ?? null;

                return (
                    <License
                        data={'data' in license ? license.data : null}
                        isBonus={'isBonus' in license ? license?.isBonus : false}
                        isPaid={'isPaid' in license ? license?.isPaid : true}
                        key={license.licenceName}
                        licenseBalance={uniqueLicenseBalance}
                        licenseCode={license.licenceCode}
                        licenseName={license.licenceName}
                        licensesRefetch={refetch}
                        tariffId={tariffId}
                        type={type}
                    />
                );
            })}
        </PageCard>
    );
}
