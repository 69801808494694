import {UIEvent} from 'react';

export function onScrollInfiniteScrollContainer(event: Event | UIEvent, onLoadMore: () => void, threshold = 50): void {
    if (event.target instanceof HTMLElement) {
        const target = event.target;
        const maxScroll = target.scrollHeight - target.clientHeight;
        const currentScroll = target.scrollTop;

        if (maxScroll - currentScroll <= threshold) {
            onLoadMore();
        }
    }
}

export function onScrollInfiniteScrollContainerToTop(
    event: Event | UIEvent,
    onLoadMore: () => void,
    threshold = 50
): void {
    if (event.target instanceof HTMLElement) {
        const target = event.target;
        const currentScroll = target.scrollTop;

        if (currentScroll <= threshold) {
            onLoadMore();
        }
    }
}
