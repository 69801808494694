import {Button, Space, Table} from 'antd';
import {FilterValue, SorterResult} from 'antd/lib/table/interface';
import {Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Text} from '../../../../component/text/text';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {AsciiSpinner} from '../../../../layout/spinner/ascii-spinner';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/locale';
import {MainFilterContext} from '../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../provider/main-filter/main-filter-type';
import {defaultAddress} from '../../../../service/address/address-const';
import {formatAddress} from '../../../../service/address/address-helper';
import {useAddresses} from '../../../../service/address/address-hook';
import {AddressType} from '../../../../service/address/address-type';
import {CclStatusEnum} from '../../../../service/ccl-statistic/ccl-statistic-type';
import {useCompanies} from '../../../../service/company/company-hook';
import {useCreateReport} from '../../../../service/company/company-reports';
import {CompanyShortType, PermissionNameEnum} from '../../../../service/company/company-type';
import {findInArrayEnsure} from '../../../../util/array';
import {classNames} from '../../../../util/css';
import {saveNewSortTableState} from '../../../../util/save-sort-table-state/save-sort-table-state';
import {objectToUrlParameters} from '../../../../util/url';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {BulkEditSearchParametersEnum} from '../../bulk-edit-companies/bulk-edit-companies-type';
import {YandexAccountTransferModal} from '../yandex-account-transfer-modal/yandex-account-transfer-modal';
import {YandexVerificationQueryEnum} from '../yandex-verification-alert/yandex-verification-alert-type';

import {CatalogStatusColumn} from './columns/ccl-status-column';
import {ExpiredColumn} from './columns/expired-column';
import {RatingColumn} from './columns/rating-column';
import {SELECTED_ALL} from './companies-table-const';
import {
    addDefaultSortOrder,
    getCompanyAddressNode,
    getCompanyNameNode,
    useCompaniesColumnList,
} from './companies-table-helper';
import {MyCompaniesTableRowDataType} from './companies-table-type';
import {NewCompaniesCheckbox} from './new-companies-checkbox/new-companies-checkbox';
import {RowAction} from './row-actions/row-actions';
import {useRowSelection} from './row-selection-hook';
import * as styles from './companies-table.scss';

type PropsType = {
    className?: string;
    ordering: string | null;
    setOrdering: Dispatch<SetStateAction<string | null>>;
    routerLocation: string;
    isNewCompanies: boolean;
    setIsNewCompanies: Dispatch<SetStateAction<boolean>>;
    isDisableReportButton: boolean;
    setShowReport: Dispatch<SetStateAction<boolean>>;
    isReportEnabled: boolean;
};

// eslint-disable-next-line max-statements,complexity
export function CompaniesTable(props: PropsType): JSX.Element {
    const {
        isDisableReportButton,
        className,
        ordering,
        setOrdering,
        routerLocation,
        isNewCompanies,
        setIsNewCompanies,
        setShowReport,
        isReportEnabled,
    } = props;

    const {mainFilterKey, isLoadingInitialFilter} = useContext<MainFilterContextType>(MainFilterContext);
    const {getQuery, setQuery} = useUrl();
    const yandexVerificationQuery = getQuery(YandexVerificationQueryEnum.YandexVerification);
    const accessTransferRequired = getQuery('accessTransferRequired');
    const [openYandexAccessModal, setOpenYandexAccessModal] = useState<boolean>(false);
    const [companyInfoForYandexModal, setCompanyInfoForYandexModal] = useState<{name: string; address: string} | null>(
        null
    );

    const [companyIdForModal, setCompanyIdForModal] = useState<number>();
    const {mainCatalogs} = useDomainConfig();

    const getCatalogsFiltersQuery = mainCatalogs
        .map((catalog) => {
            return {
                [catalog]: getQuery(catalog.toString())?.split(',') as Array<CclStatusEnum>,
            };
        })
        .filter((ids) => Boolean(Object.values(ids)[0]));

    const {
        isLoading: companyIsInProgress,
        data: companyResult,
        pagination,
    } = useCompanies({
        ordering,
        mainFilterKey,
        skip: isLoadingInitialFilter && !mainFilterKey,
        isNewCompanies,
        yandexNeedActualization: yandexVerificationQuery,
        catalogsFiltersQuery: getCatalogsFiltersQuery,
        accessTransferRequired,
    });

    const {onChange: onPaginationChange, pageSize, page} = pagination;

    const {mutate} = useCreateReport(() => setShowReport(true));

    const companyIdList: Array<number> = useMemo((): Array<number> => {
        return companyResult?.results.map<number>((companyData: CompanyShortType): number => companyData.id) || [];
    }, [companyResult]);

    const {result: useAddressesResult, isInProgress: useAddressesIsInProgress} = useAddresses(
        {companyIdList},
        {count: companyIdList.length, page: 1}
    );
    const companiesColumnList = useCompaniesColumnList(getCatalogsFiltersQuery);

    const companyAddressList: Array<AddressType> = useMemo((): Array<AddressType> => {
        return useAddressesResult?.results || [];
    }, [useAddressesResult]);

    const {
        selectedRows,
        excludedRows,
        selectedCount,
        selectedFirstCompanyName,
        selectedCompaniesIds,
        selectAllCheckbox,
        handleRowSelection,
    } = useRowSelection({
        companiesResult: companyResult || null,
        mainFilterKey,
        yandexNeedActualization: yandexVerificationQuery,
        newCompanies: isNewCompanies,
    });

    const convertCompanyTableRow = useCallback(
        (company: CompanyShortType): MyCompaniesTableRowDataType => {
            const {
                name,
                id,
                rating,
                expired,
                brandId,
                code,
                tariffId,
                yandexNeedActualization,
                accessTransferRequired: companyAccessTransferRequired,
                ccls,
            } = company;

            const address = findInArrayEnsure<AddressType>(companyAddressList, {companyId: id}, defaultAddress);
            const companyStringId = String(id);
            const expiredString: string = expired || '-';

            const companyNameNode = getCompanyNameNode(name, id, yandexNeedActualization);
            const companyAddressNode = getCompanyAddressNode(address, company);

            function setCompanyInfoHandler() {
                setCompanyInfoForYandexModal({name, address: formatAddress(address)});
                setCompanyIdForModal(id);
            }

            const mappedCatalogsStatus = ccls.reduce((accumulator: {[key: string]: ReactNode}, catalogsStatistic) => {
                if (catalogsStatistic.catalogId) {
                    return {
                        ...accumulator,
                        [catalogsStatistic.catalogId.toString()]: (
                            <CatalogStatusColumn
                                accessTransferRequired={companyAccessTransferRequired}
                                brandId={brandId}
                                cclData={catalogsStatistic}
                                companyId={id}
                                setCompanyInfo={setCompanyInfoHandler}
                                setOpenYandexModal={setOpenYandexAccessModal}
                            />
                        ),
                    };
                }

                return accumulator;
            }, {});

            const emptyCatalogRows = mainCatalogs.reduce((accumulator, catalog) => {
                return {...accumulator, [catalog.toString()]: '-'};
            }, {});

            return {
                name: companyNameNode,
                address: companyAddressNode,
                code,
                rating: <RatingColumn companyId={id} rating={rating} />,
                ...emptyCatalogRows,
                ...mappedCatalogsStatus,
                expired: <ExpiredColumn expired={expiredString.replace(/T[\S\s]+/, '')} tariffId={tariffId} />,
                action: (
                    <RowAction
                        company={company}
                        isDisableReportButton={isDisableReportButton}
                        isReportEnabled={isReportEnabled}
                        setShowReport={setShowReport}
                    />
                ),
                key: companyStringId,
            };
        },
        [companyAddressList, isDisableReportButton]
    );

    function renderCellByCompanyPermissions(
        _value: boolean,
        _record: MyCompaniesTableRowDataType,
        index: number,
        originNode: ReactNode
    ) {
        const company = companyResult?.results[index];

        if (company && company.permissions.includes(PermissionNameEnum.update)) {
            return originNode;
        }

        return null;
    }

    function onSortingChange(
        sorter: SorterResult<MyCompaniesTableRowDataType> | Array<SorterResult<MyCompaniesTableRowDataType>>
    ) {
        if (Array.isArray(sorter) || !sorter.field || !sorter.order) {
            if (ordering === null) {
                return;
            }

            saveNewSortTableState(routerLocation, sorter);
            onPaginationChange(1);
            setOrdering(null);
        } else {
            const direction = sorter.order === 'descend' ? '-' : '';

            if (ordering === `${direction}${String(sorter.field)}`) {
                return;
            }

            setOrdering(`${direction}${String(sorter.field)}`);
            saveNewSortTableState(routerLocation, sorter);

            onPaginationChange(1);
        }
    }

    function filtersHandle(filters: Record<string, FilterValue | null>) {
        const queryFilters = Object.fromEntries(
            Object.entries(filters).map(([key, value]) => {
                if (Array.isArray(value)) {
                    const transformedArray = value.map((item) => item.toString());

                    return [key, transformedArray];
                }

                return [key, value];
            })
        );

        setQuery(queryFilters || []);
    }

    async function createReportHandle() {
        if (selectedRows === 'all') {
            mutate({
                selectedAll: true,
                selectedCompaniesIds: excludedRows.map(Number),
                brandName: selectedFirstCompanyName,
            });
            return;
        }

        await mutate({
            selectedAll: false,
            selectedCompaniesIds: selectedRows.map(Number),
            brandName: selectedFirstCompanyName,
        });
    }

    const tableDataSourceList = useMemo(() => {
        if (!companyResult) {
            return [];
        }

        return companyResult.results.map(convertCompanyTableRow);
    }, [companyResult, convertCompanyTableRow]);

    const hasSelectedCompanies = selectedRows === SELECTED_ALL || selectedRows.length > 0;

    return (
        <div className={classNames(styles.CompaniesTable, className)}>
            <PageSubHeader className={styles.CompaniesTable_subHeader}>
                <Space size="large" wrap>
                    <Text>
                        {hasSelectedCompanies ? (
                            <Locale
                                stringKey="TEXT__SELECTED_COMPANIES"
                                valueMap={{
                                    selected: <Text strong>{selectedCount}</Text>,
                                    all: (
                                        <Text className={styles.CompaniesTable_secondary} type="secondary">
                                            {companyResult?.count ?? <AsciiSpinner />}
                                        </Text>
                                    ),
                                }}
                            />
                        ) : (
                            <Locale
                                stringKey="TEXT__LIST_OF_COMPANIES"
                                valueMap={{
                                    count: (
                                        <Text bolder id="companiesCount">
                                            {companyIsInProgress ? <AsciiSpinner /> : companyResult?.count ?? 0}
                                        </Text>
                                    ),
                                }}
                            />
                        )}
                    </Text>
                    <NewCompaniesCheckbox isNewCompanies={isNewCompanies} setIsNewCompanies={setIsNewCompanies} />
                </Space>
                <Space size="small" wrap>
                    {isReportEnabled && (
                        <Button
                            disabled={!hasSelectedCompanies || isDisableReportButton}
                            onClick={createReportHandle}
                            type="default"
                        >
                            <Locale stringKey="MY_COMPANIES__REPORT__TABLE__BUTTON__SELECTED_REPORT" />
                        </Button>
                    )}
                    <Button disabled={!hasSelectedCompanies} type="default">
                        <NavigationLink
                            isSaveQuery
                            to={`${appRoute.bulkEditCompanies.path}?${objectToUrlParameters({
                                [BulkEditSearchParametersEnum.SelectedIds]: selectedRows,
                                [BulkEditSearchParametersEnum.ExcludedIds]: excludedRows,
                                [BulkEditSearchParametersEnum.YandexNeedActualization]: yandexVerificationQuery,
                                [BulkEditSearchParametersEnum.IsNewCompanies]: isNewCompanies,
                            })}`}
                        >
                            <Locale stringKey="TEXT__EDIT_SELECTED" />
                        </NavigationLink>
                    </Button>
                </Space>
            </PageSubHeader>
            <Table<MyCompaniesTableRowDataType>
                columns={addDefaultSortOrder(companiesColumnList, ordering)}
                dataSource={tableDataSourceList}
                loading={companyIsInProgress || useAddressesIsInProgress}
                onChange={(newPagination, filters, sorter) => {
                    const {current, pageSize: newPageSize} = newPagination;

                    filtersHandle(filters);
                    onPaginationChange(current || 1, newPageSize);
                    onSortingChange(sorter);
                }}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: page,
                    pageSize,
                    total: companyResult?.count,
                }}
                rowSelection={{
                    columnWidth: 47,
                    onChange: handleRowSelection,
                    selectedRowKeys: selectedCompaniesIds,
                    columnTitle: selectAllCheckbox,
                    renderCell: renderCellByCompanyPermissions,
                }}
                scroll={{x: true}}
                size="middle"
            />
            {openYandexAccessModal && (
                <YandexAccountTransferModal
                    companyId={companyIdForModal}
                    companyInfo={companyInfoForYandexModal}
                    isCheckAll={false}
                    setOpenModal={setOpenYandexAccessModal}
                />
            )}
        </div>
    );
}
