import {UseQueryResult} from '@tanstack/react-query';
import {Dayjs} from 'dayjs';
import {z as r} from 'zod';

import {PostsFilterQueriesType} from '../../page/main/posts/posts-filter/posts-filter-type';
import {shortCatalogSchema, ShortCatalogType} from '../../provider/catalogs/catalogs-type';
import {CursorPaginationHookType} from '../../util/cursor-pagination/cursor-pagination-type';
import {PaginationDataType, PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {TimeRangeType} from '../../util/time';
import {ProvidersEnum} from '../../util/type';
import {cursorPaginationResponseSchemaFactory, generateResponseSchema, idNameTypeSchema} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';

export enum PostStatusEnum {
    Draft = 'draft',
    Deleted = 'deleted',
    Planned = 'planned',
    Sent = 'sent',
    Active = 'active',
    Sending = 'in_progress',
}

export enum CalendarPostStatusEnum {
    publishPlanned = 'publish_planned',
    deletePlanned = 'deleting_planned',
    published = 'published',
    refreshPlanned = 'refresh_planned',
}

export enum PostActionEnum {
    PUBLISH = 'publish',
    REMOVE = 'delete',
    EDIT = 'edit',
}

type PostsHookAdditionalType = {
    cursorPagination: CursorPaginationHookType;
    refresh: () => void;
};

export type PostsHookPropsType = {
    filter: PostsFilterQueriesType;
    mainFilterKey: string;
};

export enum PostFormFieldsEnum {
    Brands = 'brandsIds',
    Timezone = 'timezone',
    CreateDateTime = 'datetimeCreation',
    DeleteDateTime = 'datetimeDeleting',
    Status = 'status',
    Name = 'name',
    Posts = 'posts',
    SelectorId = 'selectorId',
    VkGroupsIds = 'vkGroupsIds',
    FbPagesIds = 'fbPagesIds',
    InstagramGroupsIds = 'instagramGroupsIds',
    Id = 'id',
    AutoRefreshFrequency = 'autorefreshFrequency',
    AutoRefreshCount = 'autorefreshCount',
}

const postListItemScheme = r.object({
    id: r.number(),
    name: r.string(),
    status: r.nativeEnum(PostStatusEnum),
    createdAt: r.string(),
    catalogs: r.array(
        shortCatalogSchema.merge(
            r.object({
                isHasPost: r.boolean(),
            })
        )
    ),
    canBeRefreshed: r.boolean(),
    [PostFormFieldsEnum.AutoRefreshCount]: r.number().optional().nullable(),
    [PostFormFieldsEnum.AutoRefreshFrequency]: r.number().optional().nullable(),
});

export const postsListResponseSchema = cursorPaginationResponseSchemaFactory(postListItemScheme);

export type PostListItemType = r.infer<typeof postListItemScheme>;

export type PostsListResponseType = r.infer<typeof postsListResponseSchema>;

export type PostsHookType = UseHookType<Array<PostListItemType>> & PostsHookAdditionalType;

export enum PostsFilterKeyEnum {
    sources = 'sources',
    statuses = 'statuses',
    timeRange = 'timeRange',
}

export type PostsFilterStateType = {
    [PostsFilterKeyEnum.sources]: Array<number>;
    [PostsFilterKeyEnum.statuses]: Array<PostStatusEnum>;
    [PostsFilterKeyEnum.timeRange]: TimeRangeType;
};

export type PostsDrawerFilterType = Readonly<PostsFilterStateType>;

export type SetPostFilterType = (filterPartial: Partial<PostsFilterStateType>) => void;

export type UsePostFilterHookType = {
    filter: PostsFilterStateType;
    setFilter: SetPostFilterType;
};

export enum PostTypeEnum {
    standard = 'standard',
    googleEvent = 'event',
    googleOffer = 'offer',
}
export enum PostSourceEnum {
    google = 'google',
    yandex = 'yandex',
    facebook = 'facebook',
    vkontakte = 'vk',
    instagram = 'instagram',
}

export enum GoogleButtonTypeEnum {
    Unspecified = 'unspecified',
    Book = 'book',
    Order = 'order',
    Shop = 'shop',
    LearnMore = 'learn_more',
    SignUp = 'sign_up',
    Call = 'call',
}

const calendarPostsRecordSchema = r
    .record(
        r.nativeEnum(CalendarPostStatusEnum),
        r.array(
            r.object({
                id: r.number(),
                name: r.string(),
            })
        )
    )
    .optional();

const calendarPostsSchema = r.record(r.number(), calendarPostsRecordSchema);

export type CalendarPostsResponseType = r.infer<typeof calendarPostsSchema>;

export type CalendarPostsRequestParametersType = {
    year: number;
    month: number | null;
};

export type PostsAvailableCatalogsHookType = {
    availableCatalogs: Array<ShortCatalogType>;
    isInProgress: boolean;
    processError: Error | null;
    catalogsInfo: {
        [key in PostSourceEnum]: ShortCatalogType | null;
    };
};

export const lastPostsSchema = r.object({
    id: r.number(),
    name: r.string(),
    createdAt: r.string(),
    catalogsCount: r.number(),
    status: r.nativeEnum(PostStatusEnum),
});

export type LastPostsType = r.infer<typeof lastPostsSchema>;

type LastPostsAdditionalType = {
    getLastPosts: () => void;
};

export type LastPostsHookType = UseHookType<Array<LastPostsType>> & LastPostsAdditionalType;

export const postVkGroupSchema = r.object({
    groupId: r.number(),
    name: r.string(),
    screenName: r.string(),
    brand: r.string(),
});

export type PostVkGroupType = r.infer<typeof postVkGroupSchema>;

export enum CatalogPostStatusEnum {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted',
    Error = 'error',
    OnModeration = 'on_moderation',
    Rejected = 'rejected',
}

const postLinkItemSchema = r.object({
    id: r.number(),
    catalog: r.nativeEnum(ProvidersEnum),
    currentStatus: r.nativeEnum(CatalogPostStatusEnum),
    address: r.string().nullable(),
    pageLink: r.string().nullable(),
    sourceType: r.literal('web_page').or(r.literal('company')),
    sourceName: r.string(),
    catalogLink: r.string().nullable(),
    companyCode: r.string().optional(),
});

export const postLinksResponseSchema = generateResponseSchema(postLinkItemSchema).merge(
    r.object({
        commonPostName: r.string(),
    })
);

export type PostLinkType = r.infer<typeof postLinkItemSchema>;

export type PostLinksFilterType = {
    statuses: Array<CatalogPostStatusEnum>;
    providers: Array<PostSourceEnum>;
};

export type PostCatalogLinksResultType = r.infer<typeof postLinksResponseSchema>;

export type CatalogLinksHookType = UseQueryResult<PostCatalogLinksResultType> &
    PaginationType & {
        setFilter: (newValue: PostLinksFilterType) => void;
    };

export type PostLinkRequestOptionType = PaginationDataType & {
    postId: number;
} & PostLinksFilterType;

const postSubmitInformationCatalogStatsSchema = r.object({
    catalog: r.nativeEnum(ProvidersEnum),
    locationsCount: r.number(),
});

export const postSubmitInformationSchema = r.object({
    total: r.number(),
    catalogStats: r.array(postSubmitInformationCatalogStatsSchema),
});

export type PostSubmitInformationType = r.infer<typeof postSubmitInformationSchema>;
export type PostSubmitInformationParametersType = {
    selectorId: string;
    providerCodes: Array<ProvidersEnum>;
};
export type PostFbPageType = r.infer<typeof idNameTypeSchema>;

export const postInstagramPageSchema = r.object({
    groupId: r.string(),
    igId: r.string(),
    name: r.string(),
});

export type PostInstagramPageType = r.infer<typeof postInstagramPageSchema>;

const postAiSuggestSchema = r.object({
    text: r.string(),
});

export type PostAiSuggestItemType = r.infer<typeof postAiSuggestSchema>;

export const postAiSuggestActionSchema = r.object({
    requestId: r.string(),
    postSuggestions: r.array(r.string()),
});

export type PostAiSuggestActionType = r.infer<typeof postAiSuggestActionSchema>;

export type PostAiSuggestHookType = {
    suggests: Array<PostAiSuggestItemType>;
    requestSuggest: () => void;
    isGeneratingSuggest: boolean;
};

export enum SourcePostFormFieldsEnum {
    Text = 'text',
    TopicType = 'topicType',
    ButtonLink = 'buttonLink',
    ButtonType = 'actionType',
    Catalog = 'catalog',
    Images = 'images',
    Offer = 'offer',
    Event = 'event',
    Video = 'video',
}

export const enum PostFormModeEnum {
    View = 'view',
    New = 'new',
    EditDraft = 'editDraft',
    EditExisted = 'editExisted',
}

const uploadImageResponseSchema = r.object({
    image: r.string(),
    id: r.string(),
});

export type UploadImageResponseType = r.infer<typeof uploadImageResponseSchema>;

export type PostImageType = {
    isMain: boolean;
    url: string;
};

export const postVideoSchema = r.object({
    id: r.number(),
    url: r.string(),
    duration: r.number(),
    size: r.number(),
    name: r.string(),
    previewImageUrl: r.string(),
});

export type PostVideoType = r.infer<typeof postVideoSchema>;

const googleOfferSchema = r.object({
    couponCode: r.string().optional(),
    redeemOnlineUrl: r.string().optional(),
    termsConditions: r.string().optional(),
});

export type GoogleEventType = {
    title: string;
    start: Dayjs;
    end: Dayjs;
};

const fetchSourcePostSchema = r.object({
    [SourcePostFormFieldsEnum.Text]: r.string(),
    [SourcePostFormFieldsEnum.TopicType]: r.nativeEnum(PostTypeEnum).optional(),
    [SourcePostFormFieldsEnum.ButtonLink]: r.string().nullable().optional(),
    [SourcePostFormFieldsEnum.ButtonType]: r.nativeEnum(GoogleButtonTypeEnum).optional(),
    [SourcePostFormFieldsEnum.Catalog]: r.nativeEnum(PostSourceEnum),
    [SourcePostFormFieldsEnum.Images]: r.array(
        r.object({
            url: r.string(),
            isMain: r.boolean(),
        })
    ),
    [SourcePostFormFieldsEnum.Offer]: googleOfferSchema.optional().nullable(),
    [SourcePostFormFieldsEnum.Event]: r
        .object({
            title: r.string(),
            start: r.string(),
            end: r.string(),
        })
        .optional()
        .nullable(),
    [SourcePostFormFieldsEnum.Video]: postVideoSchema.nullable().optional(),
});

export type FetchSourcePostType = r.infer<typeof fetchSourcePostSchema>;

export const fetchPostSchema = r.object({
    [PostFormFieldsEnum.Id]: r.number().optional(),
    [PostFormFieldsEnum.Timezone]: r.string().nullable(),
    [PostFormFieldsEnum.Brands]: r.array(r.number()).nullable().optional(),
    [PostFormFieldsEnum.CreateDateTime]: r.string().nullable(),
    [PostFormFieldsEnum.DeleteDateTime]: r.string().nullable(),
    [PostFormFieldsEnum.Status]: r.nativeEnum(PostStatusEnum),
    [PostFormFieldsEnum.Name]: r.string(),
    [PostFormFieldsEnum.Posts]: r.array(fetchSourcePostSchema),
    [PostFormFieldsEnum.VkGroupsIds]: r.array(r.string()).nullable(),
    [PostFormFieldsEnum.FbPagesIds]: r.array(r.string()).nullable(),
    [PostFormFieldsEnum.InstagramGroupsIds]: r.array(r.string()).nullable(),
    [PostFormFieldsEnum.SelectorId]: r.string(),
    [PostFormFieldsEnum.AutoRefreshCount]: r.number().nullable(),
    [PostFormFieldsEnum.AutoRefreshFrequency]: r.number().nullable(),
    nextRefreshDatetime: r.string().nullable(),
    companiesCount: r.number(),
});

export type FetchPostType = r.infer<typeof fetchPostSchema>;

export type SourcePostType = Omit<FetchSourcePostType, SourcePostFormFieldsEnum.Event> & {
    [SourcePostFormFieldsEnum.Event]?: GoogleEventType;
};

export type PostFormType = {
    [PostFormFieldsEnum.Timezone]: string | null;
    [PostFormFieldsEnum.Brands]: Array<number>;
    [PostFormFieldsEnum.Status]: PostStatusEnum;
    [PostFormFieldsEnum.Name]: string;
    [PostFormFieldsEnum.Posts]: Array<SourcePostType>;
    [PostFormFieldsEnum.VkGroupsIds]: Array<string>;
    [PostFormFieldsEnum.FbPagesIds]: Array<string>;
    [PostFormFieldsEnum.InstagramGroupsIds]: Array<string>;
    [PostFormFieldsEnum.SelectorId]: string;
    [PostFormFieldsEnum.CreateDateTime]: Dayjs | null;
    [PostFormFieldsEnum.DeleteDateTime]: Dayjs | null;
    [PostFormFieldsEnum.Id]?: number;
    [PostFormFieldsEnum.AutoRefreshFrequency]: number | null;
    [PostFormFieldsEnum.AutoRefreshCount]: number | null;
};

export const postPreviewDetailsSchema = r.object({
    name: r.string().nullable(),
    logo: r.string().nullable(),
});

export type PostPreviewOptionsType = {
    selectorId?: string;
    groupIds?: Array<string>;
    igGroupsIds?: Array<string>;
    fbGroupsIds?: Array<string>;
};

export type PostPreviewDetailsType = r.infer<typeof postPreviewDetailsSchema>;
