import {useCallback, useMemo} from 'react';

import {StatisticsParameter} from '../../../../component/statistic-parameter/statistics-parameter';
import {StatisticsParameterValueRendererPropsType} from '../../../../component/statistic-parameter/statistics-parameter-value-renderer';
import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {FormattedNumber} from '../../../../provider/locale/formatted-number';
import {Locale} from '../../../../provider/locale/localization';
import {ReviewsCountByRatingType} from '../../../../service/reviews-and-answers-statistics/reviews-count-by-rating/reviews-count-by-rating-type';
import {classNames} from '../../../../util/css';
import {calculatePercentageChange} from '../../../../util/number';

import {ReviewsDistributionStatsType} from './reviews-distribution-type';
import {ReviewsRatingSentimentsChart} from './reviews-rating-sentiments/reviews-rating-sentiments-chart';
import * as styles from './reviews-distribution.scss';

type PropsType = {
    reviewsCountByRating: ReviewsCountByRatingType | null;

    isCompareMode: boolean;
};

export function ReviewsWithRating(props: PropsType): JSX.Element {
    const {isCompareMode, reviewsCountByRating} = props;

    const {reviewsCount, reviewsRating, reviewCountByRating}: ReviewsDistributionStatsType = useMemo(() => {
        const reducedRatingStats = (
            reviewsCountByRating?.filter((reviewsByRatingItem) => {
                return Number(reviewsByRatingItem.rating);
            }) || []
        ).reduce(
            (accumulator, item) => {
                return {
                    currentPeriod: {
                        ratingSum: accumulator.currentPeriod.ratingSum + Number(item.rating) * item.count.value,
                        reviewsCount: accumulator.currentPeriod.reviewsCount + item.count.value,
                    },
                    comparePeriod: {
                        ratingSum:
                            accumulator.comparePeriod.ratingSum + Number(item.rating) * (item.count.comparedValue || 0),
                        reviewsCount: accumulator.comparePeriod.reviewsCount + (item.count.comparedValue || 0),
                    },
                    reviewCountByRating: {
                        currentPeriod: {
                            ...accumulator.reviewCountByRating.currentPeriod,
                            [item.rating]: item.count.value,
                        },
                        comparePeriod: {
                            ...accumulator.reviewCountByRating.comparePeriod,
                            [item.rating]: item.count.comparedValue || 0,
                        },
                    },
                };
            },
            {
                currentPeriod: {
                    ratingSum: 0,
                    reviewsCount: 0,
                },
                comparePeriod: {
                    ratingSum: 0,
                    reviewsCount: 0,
                },
                reviewCountByRating: {
                    currentPeriod: {},
                    comparePeriod: {},
                },
            } as {
                currentPeriod: {
                    ratingSum: number;
                    reviewsCount: number;
                };
                comparePeriod: {
                    ratingSum: number;
                    reviewsCount: number;
                };
                reviewCountByRating: {
                    currentPeriod: Record<string, number>;
                    comparePeriod: Record<string, number>;
                };
            }
        );

        const averageRatingCurrentPeriod = reducedRatingStats.currentPeriod.reviewsCount
            ? reducedRatingStats.currentPeriod.ratingSum / reducedRatingStats.currentPeriod.reviewsCount
            : 0;
        const averageRatingComparePeriod = reducedRatingStats.comparePeriod.reviewsCount
            ? reducedRatingStats.comparePeriod.ratingSum / reducedRatingStats.comparePeriod.reviewsCount
            : 0;

        return {
            reviewsCount: {
                currentPeriod: reducedRatingStats.currentPeriod.reviewsCount,
                comparePeriod: isCompareMode ? reducedRatingStats.comparePeriod.reviewsCount : null,
                delta: isCompareMode
                    ? calculatePercentageChange({
                          current: reducedRatingStats.currentPeriod.reviewsCount,
                          previous: reducedRatingStats.comparePeriod.reviewsCount,
                      })
                    : null,
            },
            reviewsRating: {
                currentPeriod: averageRatingCurrentPeriod,
                comparePeriod: isCompareMode ? averageRatingComparePeriod : null,
                delta: isCompareMode ? averageRatingCurrentPeriod - averageRatingComparePeriod : null,
            },
            reviewCountByRating: {
                currentPeriod: reducedRatingStats.reviewCountByRating.currentPeriod,
                comparePeriod: isCompareMode ? reducedRatingStats.reviewCountByRating.comparePeriod : null,
            },
        };
    }, [isCompareMode, reviewsCountByRating]);

    const newRatingsRendered = useCallback(({value, compareMode}: StatisticsParameterValueRendererPropsType) => {
        return (
            <Text
                className={classNames(styles.ReviewsDistribution_statisticsValue, {
                    [styles.ReviewsDistribution_statisticsValue__compare]: compareMode,
                })}
            >
                <FormattedNumber
                    options={{
                        notation: 'compact',
                        maximumFractionDigits: 2,
                    }}
                    value={value}
                />
            </Text>
        );
    }, []);

    const averageRatingRenderer = useCallback(({value, compareMode}: StatisticsParameterValueRendererPropsType) => {
        return (
            <Text
                className={classNames(styles.ReviewsDistribution_statisticsValue, {
                    [styles.ReviewsDistribution_statisticsValue__compare]: compareMode,
                })}
            >
                <FormattedNumber
                    options={{
                        maximumFractionDigits: 2,
                    }}
                    value={value}
                />
            </Text>
        );
    }, []);

    return (
        <PageCard
            bodyClassName={styles.ReviewsDistribution}
            title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITH_RATING" />}
        >
            <StatisticsParameter
                ValueRenderer={newRatingsRendered}
                titleLangKey="REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITH_RATING__COUNT"
                value={reviewsCount}
            />

            <StatisticsParameter
                ValueRenderer={averageRatingRenderer}
                deltaUnit=""
                titleLangKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RATING"
                value={reviewsRating}
            />

            <div
                className={classNames(styles.ReviewsDistribution_distributionChartsContainer, {
                    [styles.ReviewsDistribution_distributionChartsContainer__compare]: isCompareMode,
                })}
            >
                <ReviewsRatingSentimentsChart
                    chartLabelPosition={isCompareMode ? 'top' : 'bottom'}
                    periodLabel={
                        isCompareMode ? (
                            <Text block bolder className={styles.ReviewsDistribution_distributionMainPeriodLabel}>
                                A
                            </Text>
                        ) : null
                    }
                    reviewsRatings={reviewCountByRating.currentPeriod}
                />

                {reviewCountByRating.comparePeriod && (
                    <ReviewsRatingSentimentsChart
                        chartLabelPosition="bottom"
                        periodLabel={
                            <Text block bolder>
                                B
                            </Text>
                        }
                        reviewsRatings={reviewCountByRating.comparePeriod}
                    />
                )}
            </div>
        </PageCard>
    );
}
