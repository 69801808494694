import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {Spinner} from '../../../layout/spinner/spinner';
import {useCompanies} from '../../../service/company/company-hook';

import {DashboardFewCompanies} from './dashboard-few-companies';
import {DashboardManyCompanies} from './dashboard-many-companies';
import {DashboardSingleCompany} from './dashboard-single-company';
import {DashboardWithoutCompany} from './dashboard-without-companies';
import {BwGiftInfo} from './gift-alert/bw-gift-info';

export function Dashboard(): JSX.Element {
    const {data: companiesResult, isLoading: isInProgress, error: processError} = useCompanies({});

    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <>
                {processError && <AlertFallback />}
                <BwGiftInfo />
                {isInProgress && <Spinner />}

                {companiesResult &&
                    !isInProgress &&
                    !processError &&
                    (() => {
                        switch (true) {
                            case companiesResult.count === 0:
                                return <DashboardWithoutCompany />;
                            case companiesResult.count === 1: {
                                if (companiesResult.results[0]) {
                                    return (
                                        <DashboardSingleCompany
                                            companyId={companiesResult.results[0].id}
                                            permissions={companiesResult.results[0].permissions}
                                        />
                                    );
                                }

                                return <AlertFallback />;
                            }
                            case companiesResult.count > 70:
                                return <DashboardManyCompanies companiesCount={companiesResult.count} />;
                            case companiesResult.count > 1:
                                return <DashboardFewCompanies companiesCount={companiesResult.count} />;
                            default:
                                return <AlertFallback />;
                        }
                    })()}
            </>
        </Page>
    );
}
