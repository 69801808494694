export const enTextTranslations = {
    TEXT__ADD_COMPANY: 'Add company',
    TEXT__MAPS_SOCIAL_NETWORKS_CATALOGS: 'Maps, social networks, catalogs',
    TEXT__NAVIGATORS: 'Navigators',
    TEXT__LIST_OF_COMPANIES: 'Total companies: {count}',
    TEXT__SELECTED_COMPANIES: 'Selected: {selected} / {all}',
    TEXT__EDIT_SELECTED: 'Edit selected',
    TEXT__EDIT: 'Edit',
    TEXT__SELECT_THE_BRAND_THE_COMPANY_BELONGS_TO: 'Select the brand the company belongs to:',
    TEXT__NEW_BRAND: 'New brand',
    TEXT__START_CREATING: 'Start creating',
    TEXT__LEARN_MORE: 'Learn more',
    TEXT__MORE: 'More',
    TEXT__START_TYPING: 'Start typing',
    TEXT__TIME_FROM: 'From',
    TEXT__TIME_TO: 'To',
    TEXT__DATE_FROM: 'Date from',
    TEXT__DATE_TO: 'Date to',
    TEXT__WITHOUT_GROUP: 'Without group',
    TEXT__SAVED: 'Saved',
    TEXT__LIST_OF_GROUPS: 'List of groups',
    TEXT__NO_RATING: 'No rating',
    TEXT__NO_DATA: 'No data',
    TEXT__OK: 'Ok',
    TEXT__OR: 'Or',
    TEXT__APPLY: 'Apply',
    TEXT__NEXT: 'Next',
    TEXT__RESET: 'Reset',
    TEXT__SEARCH: 'Search',
    TEXT__YES: 'Yes',
    TEXT__NO: 'No',
    TEXT__CLOSE: 'Close',
    TEXT__HERE: 'here',
    TEXT__TOTAL: 'Total: {total}',
    TEXT__SELECT: 'Choose',
    TEXT__SELECT_ALL: 'Choose all',
    TEXT__CANCEL_SELECT: 'Deselect all',
    TEXT__SIZE__MB: 'Mb',
    TEXT__SERVICE_NOT_CONNECTED: 'Service is not connected',
    TEXT__SERVICE_CONNECTED: 'Service is connected',
    TEXT__SERVICE_NOT_ACTIVE: 'Service is not active.',
    TEXT__TRIAL_PERIOD: 'Test period',
    TEXT__OPTIONAL: 'optional',
    TEXT__REPORT: 'Report',
    TEXT__OTHER: 'Other',
    TEXT__GO: 'Go',
    TEXT__DEFAULT: 'default',
    TEXT__MORE_DETAILS: 'More details',
    TEXT__COPIED: 'Copied',

    EMPTY__THERE_IS_NOTHING_HERE_YET: 'There is nothing here yet.',
    EMPTY__TRY_CHANGING_FILTERS: 'Try changing filters.',
    PAGE__ERROR__LINK_TO_HOME: 'Home page',
    DRAWER_FILTER__FORM__TITLE: 'Filters',
    LINK__OPEN: 'Link',
    LOADING_THREE_POINTS: 'Loading...',
    COMPANY__CLOSED: 'Closed',
    COMPANY__CLOSED__TOOLTIP: 'The company is permanently closed',
    COMPANY__TEMPORARILY_CLOSED__TOOLTIP: 'The company is temporarily closed',
    DROPDOWN_WITH_CONFIRM_COUNT: 'Selected: {count}',
    DROPDOWN_WITH_CONFIRM_COUNT_ALL: 'All',
    EMPTY__DESCRIPTION: 'No results for the specified filters',
    TOOLTIP_SUMMARY: 'Total:',
    INPUT__PLACEHOLDER: 'Enter the title',

    DATE_PERIOD__WEEK: 'Week',
    DATE_PERIOD__MONTH: '30 days',
    DATE_PERIOD__FULL_MONTH: 'Month',
    DATE_PERIOD__QUARTER: 'Quarter',
    DATE_PERIOD__CUSTOM: 'Custom',

    DATE_AGGREGATION__DAY: 'Day',
    DATE_AGGREGATION__WEEK: 'Week',
    DATE_AGGREGATION__MONTH: 'Month',
};
