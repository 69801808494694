import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, MenuProps, Space} from 'antd';
import {useCallback, useMemo} from 'react';

import {Locale} from '../../../../provider/locale/locale';
import {TagGroupType, useTagGroupList} from '../../../../service/reviews/reviews-tags';

type PropsType = {
    onDelete: () => void;
    isActive: boolean;
    className?: string;
    isGroup?: boolean;
    updateTagsGroups?: (id: number | null) => void;
    updateTagsType?: (type: string) => void;
};

export function TagsActionSelect(props: PropsType): JSX.Element {
    const {isActive, className, onDelete, isGroup, updateTagsGroups, updateTagsType} = props;

    const {data: tagGroupListResult} = useTagGroupList();

    const availableTagGroupList: Array<TagGroupType> = useMemo((): Array<TagGroupType> => {
        return tagGroupListResult || [];
    }, [tagGroupListResult]);

    const updateGroup = useCallback(
        (id: number | null) => {
            if (updateTagsGroups) {
                updateTagsGroups(id);
            }
        },
        [updateTagsGroups]
    );

    const actionMenuItems: MenuProps['items'] = useMemo(
        () => [
            ...(!isGroup && updateTagsType
                ? [
                      {
                          key: 'move-selected',
                          label: <Locale stringKey="BUTTON__MOVE_SELECTED" />,
                          children: [
                              ...availableTagGroupList.map((tagGroup: TagGroupType) => {
                                  return {
                                      key: tagGroup.id,
                                      label: tagGroup.title,
                                      onClick: () => updateGroup(tagGroup.id),
                                  };
                              }),
                              {
                                  key: '',
                                  label: <Locale stringKey="TEXT__WITHOUT_GROUP" />,
                                  onClick: () => updateGroup(null),
                              },
                          ],
                      },
                  ]
                : []),
            {
                key: 'delete-selected',
                label: <Locale stringKey="BUTTON__DELETE_SELECTED" />,
                danger: true,
                onClick: () => onDelete(),
            },
        ],
        [availableTagGroupList, isGroup, onDelete, updateGroup, updateTagsType]
    );

    return (
        <Dropdown disabled={!isActive} menu={{items: actionMenuItems}} trigger={['click', 'hover']}>
            <Button className={className}>
                <Space>
                    <Locale stringKey="BUTTON__ACTIONS" />
                    <FontAwesomeIcon icon={faChevronDown} size="sm" />
                </Space>
            </Button>
        </Dropdown>
    );
}
