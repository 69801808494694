import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

import {userManagementUrl} from './user-management-const';

const editUserSchema = r.object({
    role: r.object({
        pk: r.number(),
        name: r.string(),
        isOwner: r.boolean(),
    }),
    brands: r.array(r.number()),
    companyGroups: r.array(r.number()),
    availableCompaniesCount: r.number(),
    email: r.string(),
    catalogs: r.array(r.number()),
    creatorEmail: r.string(),
    updaterEmail: r.string().nullable(),
    createdAt: r.string(),
    updatedAt: r.string(),
});

export type EditUserType = r.infer<typeof editUserSchema>;

function getUserEditUrl(id: number) {
    return `${userManagementUrl}/users/${id}/`;
}

function fetchEditUser(id: number): Promise<EditUserType> {
    return fetchAndDeserialize(getUserEditUrl(id), editUserSchema);
}

export function useEditUser(id: number): UseQueryResult<EditUserType> {
    return useQuery([getUserEditUrl(id)], () => fetchEditUser(id), {
        enabled: !Number.isNaN(id),
    });
}
