import {Text} from '../../../../../../../../../../../component/text/text';
import {classNames} from '../../../../../../../../../../../util/css';
import {ExpandableTextPreview} from '../../../expandable-text-preview/expandable-text-preview';

import {FACEBOOK_BIG_TEXT_LENGTH_THRESHOLD} from './facebook-preview-text-helper';
import * as styles from './facebook-preview-text.scss';

type PropsType = {
    text: string;
    small: boolean;
};

export function FacebookPreviewText(props: PropsType): JSX.Element | null {
    const {text, small} = props;

    if (text.length === 0) {
        return null;
    }

    return (
        <ExpandableTextPreview
            className={classNames(styles.FacebookPreviewText, {
                [styles.FacebookPreviewText__short]: !small && text.length < FACEBOOK_BIG_TEXT_LENGTH_THRESHOLD,
            })}
            expandButton={<Text secondary stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__READ_MORE" />}
            rows={6}
            text={text}
        />
    );
}
