import {Divider} from 'antd';
import {sum} from 'lodash';
import {useCallback, useMemo, useRef} from 'react';

import {orange70, orange90} from '../../../../../css/var-export.scss';
import {LineChart} from '../../../../../layout/chart/line-chart/line-chart';
import {ChartLegend} from '../../../../../layout/chart/utils/legend/chart-legend';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {
    OnlinePresenceBaseMetricType,
    OnlinePresenceMetricsDynamicsType,
    OnlinePresenceMetricsEnum,
} from '../../../../../service/dashboard/dashboard-type';
import {classNames} from '../../../../../util/css';
import {useDomElementRect} from '../../../../../util/dom-hook/dom-hook';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {DashboardOnlinePresenceBaseMetrics} from './dashboard-online-presence-base-metrics';
import {
    getOnlinePresenceChartConfig,
    onlinePresenceMetricsColorMap,
    onlinePresenceMetricsTranslationMap,
    SLIM_ONLINE_PRESENCE_CONTAINER_BREAKPOINT,
} from './dashboard-online-presence-catalog-stats-const';
import * as styles from './dashboard-online-presence-catalog-stats.scss';

type PropsType = {
    baseMetrics: {
        impressions: OnlinePresenceBaseMetricType;
        interactions: OnlinePresenceBaseMetricType;
        conversion: OnlinePresenceBaseMetricType;
    };
    dynamicMetrics: OnlinePresenceMetricsDynamicsType;
};

export function DashboardOnlinePresenceCatalogStats(props: PropsType): JSX.Element {
    const {baseMetrics, dynamicMetrics} = props;

    const {getLocalizedString} = useLocale();
    const {getFormattedDateTime} = useFormat();
    const chartsContainerRef = useRef<HTMLDivElement | null>(null);

    const domElementRect = useDomElementRect(chartsContainerRef);
    const isSlimContainer = domElementRect && domElementRect.width < SLIM_ONLINE_PRESENCE_CONTAINER_BREAKPOINT;

    const impressionsChartData = useMemo(() => {
        const impressionsData = dynamicMetrics.data.find((item) => item.name === OnlinePresenceMetricsEnum.Impressions);
        const targetActionsData = dynamicMetrics.data.filter(
            (item) => item.name !== OnlinePresenceMetricsEnum.Impressions
        );

        const targetActionsValues: Array<number> = [];
        const noTargetActionsValues: Array<number> = [];

        impressionsData?.values.forEach((value, index) => {
            const targetActionsItemValue = sum(
                targetActionsData.map((targetActionItem) => targetActionItem.values?.[index] || 0)
            );

            targetActionsValues.push(targetActionsItemValue);
            noTargetActionsValues.push(value - targetActionsItemValue);
        });

        return {
            labels: dynamicMetrics.labels,
            datasets: [
                {
                    fill: true,
                    label: getLocalizedString('DASHBOARD_PAGE__ONLINE_PRESENCE__TARGET_ACTIONS_IMPRESSIONS'),
                    values: targetActionsValues,
                    color: orange70,
                },
                {
                    fill: true,
                    label: getLocalizedString('DASHBOARD_PAGE__ONLINE_PRESENCE__NO_TARGET_ACTIONS_IMPRESSIONS'),
                    values: noTargetActionsValues,
                    color: orange90,
                },
            ],
        };
    }, [dynamicMetrics.data, dynamicMetrics.labels, getLocalizedString]);

    const targetActionsChartData = useMemo(
        () => ({
            labels: dynamicMetrics.labels,
            datasets: dynamicMetrics.data
                .filter((item) => item.name !== OnlinePresenceMetricsEnum.Impressions)
                .map((item) => {
                    const labelKey = onlinePresenceMetricsTranslationMap[item.name];

                    return {
                        fill: true,
                        label: labelKey ? getLocalizedString(labelKey) : '',
                        values: item.values,
                        color: onlinePresenceMetricsColorMap[item.name] || '',
                    };
                }),
        }),
        [dynamicMetrics.data, dynamicMetrics.labels, getLocalizedString]
    );

    const hasImpressionsData = impressionsChartData.datasets.some((item) => item.values.length > 0);
    const hasTargetActionsValue = targetActionsChartData.datasets.some((item) => item.values.length > 0);

    const formatLabel = useCallback(
        (label: string) =>
            label
                ? getFormattedDateTime(new Date(label), {
                      month: 'numeric',
                      day: 'numeric',
                  })
                : '',
        [getFormattedDateTime]
    );

    const targetActionsChartOptions = useMemo(
        () =>
            getOnlinePresenceChartConfig({
                data: {
                    datasets: targetActionsChartData.datasets,
                    labels: targetActionsChartData.labels || [],
                },
                formatLabel,
                showSummary: true,
            }),
        [formatLabel, targetActionsChartData.datasets, targetActionsChartData.labels]
    );

    const impressionsChartOptions = useMemo(
        () =>
            getOnlinePresenceChartConfig({
                data: {
                    datasets: impressionsChartData.datasets,
                    labels: impressionsChartData.labels || [],
                },
                formatLabel,
                showSummary: true,
            }),
        [formatLabel, impressionsChartData.datasets, impressionsChartData.labels]
    );

    return (
        <>
            <DashboardOnlinePresenceBaseMetrics
                conversion={baseMetrics.conversion}
                impressions={baseMetrics.impressions}
                interactions={baseMetrics.interactions}
            />

            <Divider className={styles.DashboardOnlinePresenceCatalogStats_divider} />

            <div className={styles.DashboardOnlinePresenceCatalogStats_chartsContainer} ref={chartsContainerRef}>
                {hasImpressionsData && (
                    <div
                        className={classNames(
                            styles.DashboardOnlinePresenceCatalogStats_chartWrapper,
                            styles.DashboardOnlinePresenceCatalogStats_chartWrapper__impressions
                        )}
                    >
                        <ChartLegend data={[...impressionsChartData.datasets].reverse()} />
                        <div className={styles.DashboardOnlinePresenceCatalogStats_chartContainer}>
                            <LineChart data={impressionsChartData} options={impressionsChartOptions} />
                        </div>
                    </div>
                )}
                {hasTargetActionsValue && (
                    <div
                        className={classNames(
                            styles.DashboardOnlinePresenceCatalogStats_chartWrapper,
                            styles.DashboardOnlinePresenceCatalogStats_chartWrapper__targetActions,
                            {
                                [styles.DashboardOnlinePresenceCatalogStats_chartWrapper__narrow]: isSlimContainer,
                            }
                        )}
                    >
                        <ChartLegend
                            data={targetActionsChartData.datasets}
                            itemClassName={styles.DashboardOnlinePresenceCatalogStats_targetActionLegendItem}
                        />
                        <div
                            className={classNames(
                                styles.DashboardOnlinePresenceCatalogStats_chartContainer,
                                styles.DashboardOnlinePresenceCatalogStats_chartContainer__targetActions
                            )}
                        >
                            <LineChart data={targetActionsChartData} options={targetActionsChartOptions} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
