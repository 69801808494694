import {ReactElement, useMemo} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {LogActionsEnum} from '../../../../../review-logs/review-log-type';

type PropsType = {
    action: string;
    formattedDate: string | null;
    formattedTime: string | null;
    userName: string;
};

export function NoteLog(props: PropsType): ReactElement {
    const {formattedDate, formattedTime, action, userName} = props;

    const noteLocaleString = useMemo(() => {
        if (action === LogActionsEnum.Created) {
            return 'REVIEWS__REVIEW_NOTE_CREATED_BY';
        }

        if (action === LogActionsEnum.Deleted) {
            return 'REVIEWS__REVIEW_NOTE_DELETED_BY';
        }

        return 'REVIEWS__REVIEW_NOTE_UPDATED_BY';
    }, [action]);

    return (
        <div>
            <Text secondary>
                {formattedDate} {formattedTime}
            </Text>
            <Text block lighter stringKey={noteLocaleString} valueMap={{user: <Text bolder>{userName}</Text>}} />
        </div>
    );
}
