import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {LocalPackRuleStatusEnum} from '../../../../../../service/local-pack/rules/local-pack-rule-list-item-type';

export const LOCALPACK_RULE_STATUS_OPTIONS: Record<
    LocalPackRuleStatusEnum,
    {
        color?: string;
        textKey: LangKeyType;
    }
> = {
    [LocalPackRuleStatusEnum.Active]: {
        color: 'blue',
        textKey: 'LOCAL_PACK__RULES_STATUS__ACTIVE',
    },
    [LocalPackRuleStatusEnum.NotActive]: {
        color: 'gold',
        textKey: 'LOCAL_PACK__RULES_STATUS__NOT_ACTIVE',
    },
    [LocalPackRuleStatusEnum.Rejected]: {
        color: 'red',
        textKey: 'LOCAL_PACK__RULES_STATUS__REJECTED',
    },
    [LocalPackRuleStatusEnum.Moderating]: {
        textKey: 'LOCAL_PACK__RULES_STATUS__MODERATING',
    },
};
