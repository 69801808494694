import {AddressType} from './address-type';

export const defaultAddress: AddressType = {
    city: '',
    country: '',
    countryCode: '',
    housenumber: '',
    lat: Number.NaN,
    lon: Number.NaN,
    postcode: '',
    region: '',
    street: '',
    rawAddress: '',
};
