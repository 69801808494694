import {useContext} from 'react';

import {SystemContext} from '../../provider/system/system-context';
import {SystemContextType} from '../../provider/system/system-context-type';

import {defaultFilterDrawerWidth} from './drawer-const';

export function useDrawerWidthHack(drawerWidth?: number): string {
    const systemContext = useContext<SystemContextType>(SystemContext);
    const {screen} = systemContext;
    const {isMobile} = screen;

    return isMobile ? '100%' : `${drawerWidth || defaultFilterDrawerWidth}px`;
}
