import {gray7} from '../../../css/var-export.scss';
import {classNames} from '../../../util/css';

import * as styles from './legend-box.scss';

type PropsType = {
    checked: boolean;
    label: string | JSX.Element;
    onChange: (checked: boolean) => void;
    color?: string;
    stripes?: boolean;
    className?: string;
};

export function LegendBox(props: PropsType): JSX.Element {
    const {checked, label, onChange, color = gray7, stripes, className} = props;

    const isCheckedStripes = stripes && checked;

    return (
        <label className={classNames(styles.LegendBox_label, className)}>
            <div
                className={classNames(styles.LegendBox, {
                    [styles.LegendBox__checkedStripes]: isCheckedStripes,
                })}
                style={!isCheckedStripes ? {borderColor: color} : {}}
            >
                <input
                    checked={checked}
                    className={styles.LegendBox_realInput}
                    onChange={() => onChange(!checked)}
                    type="checkbox"
                />
                <div
                    className={styles.LegendBox_fauxInput}
                    style={{
                        ...(checked
                            ? {
                                  backgroundColor: color,
                              }
                            : {}),
                        ...(isCheckedStripes
                            ? {
                                  background: `repeating-linear-gradient(-45deg, transparent, transparent 1.8px, ${color} 1.8px, ${color} 3.6px)`,
                                  borderStyle: 'none',
                              }
                            : {}),
                    }}
                />
            </div>
            {label}
        </label>
    );
}
