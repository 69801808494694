import {Flex, TableColumnType} from 'antd';

import {Text} from '../../../../../component/text/text';
import {spacer_xs} from '../../../../../css/var-export.scss';
import {EmptyCell} from '../../../../../layout/table/empty-cell/empty-cell';
import {TrendCell} from '../../../../../layout/table/trend-cell/trend-cell';
import {ValuePercentCell} from '../../../../../layout/table/value-percent-cell/value-percent-cell';
import {FormattedNumber} from '../../../../../provider/locale/formatted-number';
import {Locale} from '../../../../../provider/locale/locale';
import {SentimentDataType} from '../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {TimeSizeEnum} from '../../../../../util/format';
import {SentimentBar} from '../sentiment-bar/sentiment-bar';

import {SortableTableColumnType} from './reviews-analysis-table-type';
import * as styles from './reviews-analysis-table.scss';

export function getCompanyColumn<
    CompanyType extends {companyName: string; address: string}
>(): TableColumnType<CompanyType> {
    return {
        dataIndex: 'company',
        key: 'company',
        render: function renderCompany(_value: unknown, company: CompanyType): JSX.Element {
            return (
                <div className={styles.ReviewsAnalysisTable_company}>
                    <div>{company.companyName}</div>

                    <div className={styles.ReviewsAnalysisTable_email}>{company.address}</div>
                </div>
            );
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY" />,
    };
}

export function getEmployeeColumn<
    EmployeeType extends {userName: string | null; userEmail: string | null; userId: number}
>(): TableColumnType<EmployeeType> {
    return {
        dataIndex: 'employee',
        key: 'employee',
        render: function renderEmployee(_value: unknown, employee: EmployeeType): JSX.Element {
            const {userId, userEmail, userName} = employee;

            return (
                <span>
                    {userName}

                    <span className={styles.ReviewsAnalysisTable_email}> {userEmail || userId}</span>
                </span>
            );
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COLUMN__NAME" />,
    };
}

export function getRateColumn<RecordType extends {avgRating: number}>(): SortableTableColumnType<RecordType> {
    return {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__RATE" />,
        dataIndex: 'avgRating',
        align: 'center',
        key: 'avgRating',
        render: function renderRate(_value: unknown, record: RecordType): JSX.Element {
            return <span>{record.avgRating.toFixed(1)}</span>;
        },
    };
}

export function getCompanyCodeColumn<RecordType extends {companyCode: string | null}>(): TableColumnType<RecordType> {
    return {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY_CODE" />,
        align: 'center',
        dataIndex: 'companyCode',
        key: 'companyCode',
    };
}

export function getTotalColumn<RecordType extends {total: number}>(): TableColumnType<RecordType> {
    return {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEW_TOTAL" />,
        dataIndex: 'total',
        align: 'center',
        key: 'total',
        render: function renderTotal(_value: unknown, record: RecordType): JSX.Element {
            return <FormattedNumber value={record.total} />;
        },
    };
}

export function getTotalWithPercentageColumn<
    RecordType extends {total: number; totalItems: number}
>(): TableColumnType<RecordType> {
    return {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEW_TOTAL" />,
        dataIndex: 'total',
        key: 'total',
        render: function renderTotal(_value: unknown, record: RecordType): JSX.Element {
            return <ValuePercentCell percent={record.total / record.totalItems} value={record.total} />;
        },
    };
}

export function getRepliedColumn<RecordType extends {replied: number}>(): TableColumnType<RecordType> {
    return {
        dataIndex: 'replied',
        align: 'center',
        key: 'replied',
        render: function renderReplied(_value: unknown, record: RecordType): JSX.Element {
            return <FormattedNumber value={record.replied} />;
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEWS_REPLIED" />,
    };
}

export function getEmployeeRepliedColumn<RecordType extends {total: number}>(): TableColumnType<RecordType> {
    return {
        title: <Locale stringKey="REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COLUMN__REPLY_COUNT" />,
        dataIndex: 'employeeReplied',
        key: 'employeeReplied',
        render: function renderTotal(_value: unknown, record: RecordType): JSX.Element {
            return <FormattedNumber value={record.total} />;
        },
    };
}

export function getRepliedPercentage<RecordType extends {repliedPercent: number}>(): TableColumnType<RecordType> {
    return {
        dataIndex: 'replyPercent',
        key: 'replyPercent',
        render: function renderRepliedPercentage(_value: unknown, record: RecordType): JSX.Element {
            return <FormattedNumber options={{style: 'percent'}} value={record.repliedPercent} />;
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE" />,
    };
}

export function getRepliedWithPercentageColumn<
    RecordType extends {replied: number; repliedPercent: number}
>(): TableColumnType<RecordType> {
    return {
        dataIndex: 'repliedPercentage',
        key: 'repliedPercentage',
        align: 'center',
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE" />,
        render: function renderRepliedPercentage(_value: unknown, record: RecordType): JSX.Element {
            return <ValuePercentCell percent={record.repliedPercent} value={record.replied} />;
        },
    };
}

export function getComplaintColumn<RecordType extends {abuses: number}>(): TableColumnType<RecordType> {
    return {
        align: 'center',
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__COMPLAINTS" />,
        dataIndex: 'employeeReplied',
        key: 'employeeReplied',
        render: function renderTotal(_value: unknown, record: RecordType): JSX.Element {
            return <FormattedNumber value={record.abuses} />;
        },
    };
}

export function getAvgResponseDelayColumn<
    RecordType extends {avgResponseSpeed: number | null}
>(): TableColumnType<RecordType> {
    return {
        align: 'center',
        dataIndex: 'avgResponseSpeed',
        key: 'avgResponseSpeed',
        render: function renderAvgResponseDelay(_value: unknown, record: RecordType): JSX.Element {
            if (!record.avgResponseSpeed) {
                return <EmptyCell />;
            }

            return (
                <FormattedNumber options={{style: 'unit', unit: TimeSizeEnum.minute}} value={record.avgResponseSpeed} />
            );
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__REPLY_DELAY" />,
    };
}

export function getSentimentColumn<RecordType extends {sentiment: SentimentDataType}>(): TableColumnType<RecordType> {
    return {
        dataIndex: 'sentiment',
        key: 'sentiment',
        render: function renderSentiment(_value: unknown, record: RecordType): JSX.Element {
            return <SentimentBar sentimentData={record.sentiment} />;
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT" />,
    };
}

export function getTrendColumn<
    RecordType extends {trendByThreeMonths: Array<{date: string; value: number}>}
>(): TableColumnType<RecordType> {
    return {
        dataIndex: 'trend',
        key: 'trend',
        render: function renderTrend(_value: unknown, record: RecordType): JSX.Element {
            return (
                <TrendCell
                    values={record.trendByThreeMonths.map(
                        (point: {date: string; value: number}): number => point.value
                    )}
                    withoutAnimation
                />
            );
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__TREND" />,
    };
}

export function getCompanyGroupsColumn<
    RecordType extends {companyGroups: Array<string>}
>(): TableColumnType<RecordType> {
    return {
        key: 'companyGroups',
        width: '200px',
        render: function renderTrend(_value: unknown, record: RecordType): JSX.Element {
            return (
                <Flex gap={spacer_xs} vertical>
                    {record.companyGroups.map((groupName) => {
                        return (
                            <Text block key={groupName}>
                                {groupName}
                            </Text>
                        );
                    })}
                </Flex>
            );
        },
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY_GROUPS" />,
    };
}
