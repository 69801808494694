import {Button, Modal, Table} from 'antd';
import {isString} from 'lodash';
import {ReactElement, useState} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {UniqueLicenseDataType} from '../../../../../../service/tariffs/tariffs-licenses';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {
    AdditionalLicenseKeyEnum,
    additionalLicenseKeyMap,
    LicensesCodeEnum,
    ProductTypeEnum,
    productTypeKeyMap,
} from '../license-const';
import {LicenseDateTableType, uniqueLicenseKeys} from '../license-helper';

import * as styles from './license-dates-modal.scss';

type PropsType = {
    descriptions: UniqueLicenseDataType;
    licenseName: string;
    licenseCode: string;
};

export function LicenseDatesModal(props: PropsType): ReactElement {
    const {descriptions, licenseName, licenseCode} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const {getFormattedDateTime} = useFormat();

    const {getLocalizedString} = useLocale();

    return (
        <>
            <Button className={styles.LicenseDateModal_periodsButton} onClick={() => setIsOpen(true)} type="link">
                <Locale stringKey="TARIFFS__LICENSE_BLOCK__MODAL__BUTTON__TITLE" />
            </Button>
            <Modal
                closable
                footer={[
                    <Button
                        key="close"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        type="default"
                    >
                        <Locale stringKey="POPUP__BUTTON__CLOSE" />
                    </Button>,
                ]}
                onCancel={() => {
                    setIsOpen(false);
                }}
                open={isOpen}
                title={licenseName}
                width={530}
            >
                <Table<LicenseDateTableType>
                    bordered
                    columns={[...uniqueLicenseKeys(descriptions).keysArray]
                        .filter((columnName) => {
                            if (licenseCode === LicensesCodeEnum.ReviewLicence) {
                                return (
                                    columnName !== AdditionalLicenseKeyEnum.ProductType &&
                                    columnName !== AdditionalLicenseKeyEnum.ProviderCodes
                                );
                            }

                            return columnName !== AdditionalLicenseKeyEnum.ProductType;
                        })
                        .map((columnName) => {
                            return {
                                title: getLocalizedString(additionalLicenseKeyMap[columnName]),
                                render: (_value, license) => {
                                    const value = license[columnName];

                                    if (columnName === AdditionalLicenseKeyEnum.Dates) {
                                        return `${getFormattedDateTime(
                                            new Date(license[columnName][0])
                                        )} - ${getFormattedDateTime(new Date(license[columnName][1]))}`;
                                    }

                                    if (
                                        columnName === AdditionalLicenseKeyEnum.Variant &&
                                        value &&
                                        licenseCode !== LicensesCodeEnum.NotificationsLicence
                                    ) {
                                        if (isString(value) && value.includes(', ')) {
                                            return value
                                                .split(', ')
                                                .map((variant) =>
                                                    getLocalizedString(productTypeKeyMap[variant as ProductTypeEnum])
                                                )
                                                .join(', ');
                                        }

                                        return getLocalizedString(productTypeKeyMap[value as ProductTypeEnum]);
                                    }

                                    if (columnName === AdditionalLicenseKeyEnum.ProviderCodes) {
                                        if (license[columnName]?.[0] === 'all') {
                                            return <Locale stringKey="TAGS__TAG_TYPE__ALL" />;
                                        }

                                        return license[columnName]?.join(', ');
                                    }

                                    return license[columnName];
                                },
                            };
                        })}
                    dataSource={[...uniqueLicenseKeys(descriptions).transformedList]}
                    pagination={false}
                    size="small"
                />
            </Modal>
        </>
    );
}
