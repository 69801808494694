import {memo, useMemo} from 'react';
import {Marker} from 'react-leaflet';

import {LeafletMap} from '../../../../../component/map/leaflet-map';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {
    LocalPackGridItemStatusEnum,
    LocalPackPointDistanceEnum,
    LocalPackSquareSizeEnum,
} from '../../../../../service/local-pack/local-pack-type';
import {getMapIconConfig} from '../../local-pack-result-page/results-on-map/results-on-map-helper';

import {LocalPackMapBoundsUpdater} from './loca-pack-map-bounds-updater';
import {getCenterPointByLocaleName, getLocalpackMapExampleData} from './local-pack-form-map-helper';
import * as styles from './local-pack-form-map.scss';

type PropsType = {
    squareSize?: LocalPackSquareSizeEnum;
    pointsDistance?: LocalPackPointDistanceEnum;
};

export const LocalPackFormMap = memo(function LocalPackFormMapInner(props: PropsType): JSX.Element {
    const {squareSize = LocalPackSquareSizeEnum.Smallest, pointsDistance = LocalPackPointDistanceEnum.Low} = props;
    const {shortLocaleName} = useLocale();

    const {points, bounds} = useMemo(() => {
        return getLocalpackMapExampleData({
            centerPoint: getCenterPointByLocaleName(shortLocaleName),
            pointsDistance,
            squareSize,
        });
    }, [pointsDistance, shortLocaleName, squareSize]);

    return (
        <LeafletMap bounds={bounds} className={styles.LocalPackFormMap}>
            <LocalPackMapBoundsUpdater bounds={bounds} />
            {points.map((mapPoint) => {
                return (
                    <Marker
                        autoPan={false}
                        draggable={false}
                        icon={getMapIconConfig({
                            position: mapPoint.position,
                            status: LocalPackGridItemStatusEnum.Complete,
                        })}
                        key={mapPoint.id}
                        position={mapPoint.point}
                    />
                );
            })}
        </LeafletMap>
    );
});
