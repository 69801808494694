import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {timelineDataResponseSchema, TimelineResultResponseType} from './timeline-activity-type';

export async function getTimelineForOneCompany(
    companyId: number,
    cursor: string | null,
    perPage?: number
): Promise<TimelineResultResponseType> {
    const searchParameters = objectToUrlParameters({
        company_ids: companyId,
        cursor,
        per_page: perPage,
        timestamp: Date.now(),
    });

    return fetchAndDeserialize<TimelineResultResponseType>(
        `/v1/timeline/?${searchParameters}`,
        timelineDataResponseSchema
    );
}
