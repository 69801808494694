import {useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo} from 'react';

import {useLicenses} from '../../provider/license/license-hook';
import {useLocale} from '../../provider/locale/locale-hook';
import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {CursorPaginationResponseType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {FeaturesEnum} from '../user/user-type';

import {
    fetchLastPhotos,
    fetchMedia,
    fetchPhoto,
    fetchPhotoCounter,
    fetchPhotoToolCatalogStats,
    fetchVideo,
    removePhotos,
    removeVideos,
    reportPhotos,
    reportVideos,
    resetPhotoCounter,
    setPhotosStatus,
    setVideosStatus,
    updatePhoto,
    updateVideo,
} from './phototool-api';
import {
    checkIsVideo,
    checkIsVideoType,
    copyPhotoListWithStatus,
    copyPhotoWithStatus,
    getIsRemoveAllowed,
    getIsReportAllowed,
    PHOTO_TOOL_FILTER_OPTIONS_EMPTY,
    transformVideoItem,
    transformVideoResponse,
} from './phototool-helper';
import {
    MediaItemType,
    PhotoHookOptionsType,
    PhotoItemHookType,
    PhotoItemReportType,
    PhotoItemSetStatusType,
    PhotoItemUpdateTagsType,
    PhotoListHookType,
    PhotoListReportType,
    PhotoListSetViewedType,
    PhotosReportDataType,
    PhotosReportRequestType,
    PhotoStatusKeyEnum,
    PhotosType,
    PhotoToolCatalogStatsType,
    PhotoToolCounterType,
    PhotoType,
    VideoItemHookType,
} from './phototool-type';

export function usePhotoList(options: PhotoHookOptionsType = {}): PhotoListHookType {
    const {filter = PHOTO_TOOL_FILTER_OPTIONS_EMPTY, mainFilterKey, paginationOptions, isVideoMode} = options;

    const {status, source, tags, startDate, endDate, own} = filter;

    const {getLocalizedString} = useLocale();

    const cursorPaginationDependencies = useMemo(() => {
        return {
            mainFilterKey,
            status,
            source,
            tags,
            startDate,
            endDate,
            own,
        };
    }, [status, source, tags, startDate, endDate, own, mainFilterKey, isVideoMode]);

    const cursorPagination = useCursorPagination({
        dependencies: cursorPaginationDependencies,
        ...paginationOptions,
    });

    const {cursor, pageSize, onDataLoaded, onDataLoadFailed, refreshId, updatePageSize, refresh} = cursorPagination;

    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CursorPaginationResponseType<MediaItemType>>();

    useEffect(() => {
        if (paginationOptions && paginationOptions.defaultPageSize) {
            updatePageSize(paginationOptions.defaultPageSize);
        }
    }, [updatePageSize, paginationOptions]);

    useEffect(() => {
        setProcessError(null);
        setIsInProgress(true);

        fetchMedia(
            {
                ...filter,
                pageSize,
                cursor,
            },
            mainFilterKey,
            isVideoMode
        )
            .then((response) => {
                onDataLoaded(response);

                if (checkIsVideoType(response, isVideoMode)) {
                    setResult(transformVideoResponse(response));
                } else {
                    setResult(response);
                }
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
                onDataLoadFailed();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, setIsInProgress, setProcessError, setResult, onDataLoaded, onDataLoadFailed]);

    const setViewed = useCallback<PhotoListSetViewedType>(
        (photos) => {
            if (!result) {
                return Promise.resolve();
            }

            const newPhotoIds: Array<number> = [];
            const newVideoIds: Array<number> = [];

            photos.forEach((photo) => {
                if (photo.status === PhotoStatusKeyEnum.New) {
                    if (checkIsVideo(photo)) {
                        newVideoIds.push(photo.id);
                    } else {
                        newPhotoIds.push(photo.id);
                    }
                }
            });

            if (newPhotoIds.length === 0 && newVideoIds.length === 0) {
                return Promise.reject(new Error(getLocalizedString('PHOTO_TOOL__REPORT__BULK_ERROR__EMPTY__TEXT')));
            }

            const promises: Array<Promise<void>> = [];

            if (newPhotoIds.length > 0) {
                const photoPromise: Promise<void> = setPhotosStatus({
                    photoIds: newPhotoIds,
                    status: PhotoStatusKeyEnum.Viewed,
                }).then(() => {
                    setResult({
                        ...result,
                        results: copyPhotoListWithStatus(result.results, newPhotoIds, PhotoStatusKeyEnum.Viewed),
                    });
                });

                promises.push(photoPromise);
            }

            if (newVideoIds.length > 0) {
                const videoPromise: Promise<void> = setVideosStatus({
                    videoIds: newVideoIds,
                    status: PhotoStatusKeyEnum.Viewed,
                }).then(() => {
                    setResult({
                        ...result,
                        results: copyPhotoListWithStatus(result.results, newVideoIds, PhotoStatusKeyEnum.Viewed),
                    });
                });

                promises.push(videoPromise);
            }

            return Promise.all(promises).then(([voidVariable]: Array<void>): void => voidVariable);
        },
        [getLocalizedString, result, setResult]
    );

    const report = useCallback<PhotoListReportType>(
        (photos: Array<PhotoType>, reportData: Omit<PhotosReportRequestType, 'photoIds'>): Promise<void> => {
            if (!result) {
                return Promise.resolve();
            }

            const reportPhotosIds: Array<number> = [];
            const removePhotosIds: Array<number> = [];

            const reportVideosIds: Array<number> = [];
            const removeVideosIds: Array<number> = [];

            photos.forEach((photo: MediaItemType): void => {
                if (getIsRemoveAllowed(photo)) {
                    if (checkIsVideo(photo)) {
                        removeVideosIds.push(photo.id);
                        return;
                    }

                    removePhotosIds.push(photo.id);
                    return;
                }

                if (getIsReportAllowed(photo)) {
                    if (checkIsVideo(photo)) {
                        reportVideosIds.push(photo.id);
                        return;
                    }

                    reportPhotosIds.push(photo.id);
                }
            });

            const reportPhotoPromise: Promise<void> =
                reportPhotosIds.length === 0
                    ? Promise.resolve()
                    : reportPhotos({photoIds: reportPhotosIds, ...reportData}).then(() =>
                          setResult({
                              ...result,
                              results: copyPhotoListWithStatus(
                                  result.results,
                                  reportPhotosIds,
                                  PhotoStatusKeyEnum.Reported
                              ),
                          })
                      );

            const reportVideoPromise: Promise<void> =
                reportVideosIds.length === 0
                    ? Promise.resolve()
                    : reportVideos({videoIds: reportVideosIds, ...reportData}).then(() =>
                          setResult({
                              ...result,
                              results: copyPhotoListWithStatus(
                                  result.results,
                                  reportVideosIds,
                                  PhotoStatusKeyEnum.Reported
                              ),
                          })
                      );

            const removePhotoPromise: Promise<void> =
                removePhotosIds.length === 0
                    ? Promise.resolve()
                    : removePhotos({photoIds: removePhotosIds}).then(() =>
                          setResult({
                              ...result,
                              results: copyPhotoListWithStatus(
                                  result.results,
                                  removePhotosIds,
                                  PhotoStatusKeyEnum.RemoveRequest
                              ),
                          })
                      );

            const removeVideoPromise: Promise<void> =
                removeVideosIds.length === 0
                    ? Promise.resolve()
                    : removeVideos({videoIds: removeVideosIds}).then(() =>
                          setResult({
                              ...result,
                              results: copyPhotoListWithStatus(
                                  result.results,
                                  removeVideosIds,
                                  PhotoStatusKeyEnum.RemoveRequest
                              ),
                          })
                      );

            return Promise.all([reportPhotoPromise, reportVideoPromise, removePhotoPromise, removeVideoPromise]).then(
                ([voidVariable]: [void, void, void, void]): void => voidVariable
            );
        },
        [result, setResult]
    );

    return {
        isInProgress,
        processError,
        result,
        reset,
        report,
        cursorPagination,
        refresh,
        setViewed,
    };
}

export function usePhotoItem(id: number): PhotoItemHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<MediaItemType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchPhoto(id)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [id, setIsInProgress, setProcessError, setResult]);

    const setStatus = useCallback<PhotoItemSetStatusType>(
        (status) => {
            if (!result) {
                return Promise.resolve();
            }

            return setPhotosStatus({photoIds: [id], status}).then(() => {
                setResult({...result, status});
            });
        },
        [result, setResult, id]
    );

    const report = useCallback<PhotoItemReportType>(
        (reportData: PhotosReportDataType) => {
            if (!result) {
                return Promise.resolve();
            }

            if (getIsRemoveAllowed(result)) {
                return removePhotos({photoIds: [id]}).then(() =>
                    setResult(copyPhotoWithStatus(result, PhotoStatusKeyEnum.RemoveRequest))
                );
            }

            return reportPhotos({photoIds: [id], ...reportData}).then(() =>
                setResult(copyPhotoWithStatus(result, PhotoStatusKeyEnum.Reported))
            );
        },
        [result, setResult, id]
    );

    const updateTags = useCallback<PhotoItemUpdateTagsType>(
        (tagIds: Array<number>): Promise<void> => {
            return updatePhoto(id, {tags: tagIds}).then(setResult);
        },
        [setResult, id]
    );

    return {
        isInProgress,
        processError,
        result,
        reset,
        report,
        updateTags,
        setStatus,
    };
}

export function usePhotoToolCounter(): UseQueryResult<PhotoToolCounterType> {
    const {licenses} = useLicenses();
    const photoToolLicense = licenses[FeaturesEnum.photoTool];

    return useQuery(['photo-tool-counter'], fetchPhotoCounter, {
        enabled: photoToolLicense && photoToolLicense.isActive,
    });
}

export function useResetPhotoToolCounter(): void {
    const queryClient = useQueryClient();

    useEffect(() => {
        resetPhotoCounter()
            .then(() => {
                return queryClient.invalidateQueries({queryKey: ['photo-tool-counter']});
            })
            .catch((error) => {
                console.error('Error resetting photo count', error.message);
            });
    }, [queryClient]);
}

export function usePhotoToolLastPhotos(): UseQueryResult<PhotosType> {
    return useQuery(['photo-tool-last-photos'], fetchLastPhotos);
}

export function usePhotoToolCatalogStats(): UseQueryResult<PhotoToolCatalogStatsType> {
    return useQuery(['photo-tool-catalog-stats'], () => fetchPhotoToolCatalogStats());
}

export function useVideoItem(id: number): VideoItemHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<MediaItemType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchVideo(id)
            .then((response) => {
                setResult(transformVideoItem(response));
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [id, setIsInProgress, setProcessError, setResult]);

    const setStatus = useCallback<PhotoItemSetStatusType>(
        (status) => {
            if (!result) {
                return Promise.resolve();
            }

            return setVideosStatus({videoIds: [id], status}).then(() => {
                setResult({...result, status});
            });
        },
        [result, setResult, id]
    );

    const report = useCallback<PhotoItemReportType>(
        (reportData: PhotosReportDataType) => {
            if (!result) {
                return Promise.resolve();
            }

            if (getIsRemoveAllowed(result)) {
                return removeVideos({videoIds: [id]}).then(() => {
                    if (checkIsVideo(result)) {
                        setResult(copyPhotoWithStatus(result, PhotoStatusKeyEnum.RemoveRequest));
                    }
                });
            }

            return reportVideos({videoIds: [id], ...reportData}).then(() =>
                setResult(copyPhotoWithStatus(result, PhotoStatusKeyEnum.Reported))
            );
        },
        [result, setResult, id]
    );

    const updateTags = useCallback<PhotoItemUpdateTagsType>(
        (tagIds: Array<number>): Promise<void> => {
            return updateVideo(id, {tags: tagIds}).then(setResult);
        },
        [setResult, id]
    );

    return {
        isInProgress,
        processError,
        result,
        reset,
        setStatus,
        report,
        updateTags,
    };
}
