import {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {BetaSup} from '../../../component/sup/beta-sup';
import {ActivationPage} from '../../../layout/activation-page/activation-page';
import {Page} from '../../../layout/page/page';
import {Spinner} from '../../../layout/spinner/spinner';
import {useDocumentationLinks} from '../../../provider/help-links/help-links-hook';
import {useLicenses} from '../../../provider/license/license-hook';
import {Locale} from '../../../provider/locale/localization';
import {FeaturesEnum} from '../../../service/user/user-type';

import {LocatorPreviewPage} from './pages/locator-preview-page/locator-preview-page';

const StoreLocatorRouter = lazy(() => import('./pages/store-locator-router'));

export function StoreLocatorIndex(): JSX.Element {
    const {licenses} = useLicenses();
    const {featureActivation} = useDocumentationLinks();

    if (licenses[FeaturesEnum.storeLocator]?.isActive) {
        return (
            <Switch>
                <Route exact path={appRoute.storeLocatorPreview.path}>
                    <LocatorPreviewPage />
                </Route>

                <Route exact={false} path={appRoute.storeLocator.path}>
                    <Page isTopFilterHidden>
                        <Suspense fallback={<Spinner position="absolute" />}>
                            <StoreLocatorRouter />
                        </Suspense>
                    </Page>
                </Route>
            </Switch>
        );
    }

    return (
        <ActivationPage
            breadCrumbList={[
                {
                    path: appRoute.storeLocator.path,
                    titleLangKey: 'CATEGORY_NAME__STORE_LOCATOR',
                },
            ]}
            link={featureActivation[FeaturesEnum.storeLocator]}
            title={
                <div>
                    <Locale stringKey="CATEGORY_NAME__STORE_LOCATOR" /> <BetaSup />
                </div>
            }
        />
    );
}
