import {Space, Table, TableColumnsType} from 'antd';
import {SorterResult} from 'antd/lib/table/interface';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {dateRangeToWorksheetName} from '../../../../../component/request-report-base/request-report-base-helper';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {fetchReviewCompanies} from '../../../../../service/reivew-analysis/review-companies/review-companies-api';
import {isReviewAnalysisCompanySortKey} from '../../../../../service/reivew-analysis/review-companies/review-companies-helper';
import {useReviewCompanies} from '../../../../../service/reivew-analysis/review-companies/review-companies-hook';
import {
    ReviewAnalysisCompaniesSortType,
    ReviewsAnalysisCompaniesQueryType,
    ReviewsAnalysisCompanyType,
} from '../../../../../service/reivew-analysis/review-companies/review-companies-type';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {defaultPageSize} from '../../../../../util/pagination-hook/pagination-const';
import {useSavedTableSort} from '../../../../../util/save-sort-table-state/save-sort-table-hook';
import {saveNewSortTableState} from '../../../../../util/save-sort-table-state/save-sort-table-state';
import {ActiveFilter} from '../../components/active-filter/active-filter';
import {DrawerFilter} from '../../components/drawer-filter/drawer-filter';
import {RequestReport} from '../../components/request-report/request-report';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';

import {companiesPageTableColumn, formatInitialStateSort, serializeSortOrder} from './companies-page-helper';
import {ReviewCompaniesTableDataType} from './companies-page-type';

export function LocationsPage(): JSX.Element {
    const {reviewsAnalysisDashboard: dashboardRoute, reviewsAnalysisCompanies: companiesRoute} = appRoute;

    const {getLocalizedString} = useLocale();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {routerLocation, savedSortState} = useSavedTableSort();

    const {singleFilter} = useReviewsAnalysisFilter();
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [sort, setSort] = useState<ReviewAnalysisCompaniesSortType | null>(formatInitialStateSort(savedSortState));

    const {fetch, result, isInProgress, processError} = useReviewCompanies();
    const page: number = result?.page || 1;
    const total: number = result?.count || 0;

    const {getFormattedNumber} = useFormat();

    useEffect(() => {
        fetch({page: 1, pageSize, sort, ...singleFilter}, mainFilterKey);
    }, [mainFilterKey, fetch, pageSize, sort, singleFilter]);

    function fetchPage(pageInner: number) {
        fetch({page: pageInner, pageSize, sort, ...singleFilter}, mainFilterKey);
    }

    const getDataSource = useCallback((array: Array<ReviewsAnalysisCompanyType>, isForExport?: boolean) => {
        return array.map<ReviewCompaniesTableDataType>(
            (dataItem: ReviewsAnalysisCompanyType, index): ReviewCompaniesTableDataType => ({
                ...dataItem,
                key: `${dataItem.id} ${index} ${isForExport && 'export'}`,
            })
        );
    }, []);

    const dataSource: Array<ReviewCompaniesTableDataType> = useMemo<Array<ReviewCompaniesTableDataType>>(
        (): Array<ReviewCompaniesTableDataType> => getDataSource(result?.results || []),
        [getDataSource, result?.results]
    );

    function onPaginationChange(pageInner: number, pageSizeInner?: number | void): void {
        if (pageSizeInner && pageSize !== pageSizeInner) {
            setPageSize(pageSizeInner);
            return;
        }

        fetchPage(pageInner);

        if (result?.page !== pageInner) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, pageInner);
        }
    }

    function addDefaultSortOrder(
        columns: TableColumnsType<ReviewCompaniesTableDataType>,
        defaultSortColumn: ReviewAnalysisCompaniesSortType | null
    ): TableColumnsType<ReviewCompaniesTableDataType> {
        if (!defaultSortColumn) {
            return columns;
        }

        const [dataIndex, sortOrder] = defaultSortColumn?.split('.') ?? [];

        if (!dataIndex || !sortOrder) {
            return columns;
        }

        const normalizedSortOrder = sortOrder === 'asc' ? 'ascend' : 'descend';

        return columns.map((column) => {
            if ('dataIndex' in column && column.dataIndex === dataIndex && column.sorter) {
                return {
                    ...column,
                    defaultSortOrder: normalizedSortOrder,
                };
            }

            return column;
        });
    }

    function onSortingChange(sorter: SorterResult<ReviewCompaniesTableDataType>) {
        const sortKey = String(sorter.field);
        const serializedOrder = serializeSortOrder(sorter.order);

        if (serializedOrder && isReviewAnalysisCompanySortKey(sortKey)) {
            setSort(`${sortKey}.${serializedOrder}`);
            saveNewSortTableState(routerLocation, sorter);
        } else {
            setSort(null);
            saveNewSortTableState(routerLocation, sorter);
        }
    }

    function formatDataForXls(data: Array<ReviewsAnalysisCompanyType>) {
        return data.map((company) => ({
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY')]: company.companyName,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__ADDRESS')]: company.address,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__RATE')]: company.avgRating.toFixed(1),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEW_TOTAL')]: getFormattedNumber(company.total),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE')]: `${getFormattedNumber(
                company.replied
            )} (${company.total > 0 ? getFormattedNumber(company.replied / company.total, {style: 'percent'}) : '0%'})`,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REPLY_DELAY')]: company.avgResponseSpeed
                ? getFormattedNumber(company.avgResponseSpeed, {style: 'unit', unit: TimeSizeEnum.minute})
                : '—',
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE'
            )}`]: company.sentiment.positive,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL'
            )}`]: company.sentiment.neutral,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE'
            )}`]: company.sentiment.negative,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED'
            )}`]: company.sentiment.unspecified,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY_GROUPS')]: company.companyGroups.join('\n'),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY_CODE')]: company.companyCode,
        }));
    }

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES')} />

            <BreadCrumbs
                list={[
                    {
                        path: dashboardRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                    },
                    {
                        path: companiesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle
                    productName={UsetifulNameProductEnum.REVIEWS_ANALYSIS__COMPANIES}
                    title="CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES"
                />

                <Space>
                    <RequestReport<
                        ReviewCompaniesTableDataType,
                        ReviewsAnalysisCompanyType,
                        ReviewsAnalysisCompaniesQueryType
                    >
                        apiHandler={fetchReviewCompanies}
                        columns={companiesPageTableColumn}
                        defaultFilename={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES')}
                        filters={{...singleFilter, sort}}
                        formatDataForXls={formatDataForXls}
                        getDataSource={getDataSource}
                        title={<Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES" />}
                        xlsxWorksheetName={dateRangeToWorksheetName({
                            rawStartDate: singleFilter.startDate,
                            rawEndDate: singleFilter.endDate,
                        })}
                    />
                    <DrawerFilter />
                </Space>
            </PageHeader>

            <ActiveFilter
                isCompareRangeAllowed={false}
                isCompetitorAllowed={false}
                isPreview={false}
                isWithTextOnly={false}
            />

            {processError && <AlertFallback description={processError?.message} message={processError?.name} />}

            {result && (
                <Table<ReviewCompaniesTableDataType>
                    columns={addDefaultSortOrder(companiesPageTableColumn, sort)}
                    dataSource={dataSource}
                    loading={isInProgress}
                    onChange={(_pagination, _filter, sorter): void => {
                        if (!Array.isArray(sorter) && sorter.field) {
                            onSortingChange(sorter);
                        }
                    }}
                    pagination={{
                        total,
                        pageSize,
                        current: page,
                        size: 'default',
                        showSizeChanger: true,
                        onChange: onPaginationChange,
                    }}
                    scroll={{x: true}}
                    showSorterTooltip={false}
                    size="middle"
                />
            )}
        </Page>
    );
}
