import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

type ReviewFormBrandsType = {
    id: number;
    name: string;
};

const reviewCustomBrandsUrl = `${rootApiUrl}/v4/reviews/custom_reviews/brands/`;

function fetchReviewCustomBrands(): Promise<Array<ReviewFormBrandsType>> {
    return fetchX<Array<ReviewFormBrandsType>>(reviewCustomBrandsUrl);
}

export function useReviewCustomBrands(): UseQueryResult<Array<ReviewFormBrandsType>> {
    return useQuery([reviewCustomBrandsUrl], fetchReviewCustomBrands, {
        refetchOnWindowFocus: false,
    });
}
