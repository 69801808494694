export const enAuthorizationTranslations = {
    PAGE__LOGIN__LOGIN_FORM__HEADER: 'Login to personal account',
    PAGE__LOGIN__LOGIN_FORM__SUB_HEADER: 'For existing users',
    PAGE__LOGIN__LOGIN_FORM__SUB_HEADER_TWO_FACTOR_AUTHENTICATION: 'Confirm your login with Google Authenticator',
    PAGE__LOGIN__LOGIN_FORM__BUTTON__SIGN_IN: 'Sign in',
    PAGE__LOGIN__LOGIN_FORM__NO_ACCOUNT: 'No account?',
    PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__LABEL: 'E-Mail:',
    PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__PLACEHOLDER: 'Your Email address',
    PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__LABEL: 'Password:',
    PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__PLACEHOLDER: 'Password',
    PAGE__LOGIN__LOGIN_FORM__INPUT__REMEMBER_ME: 'Remember me',

    PAGE__REGISTER__REGISTER_FORM__HEADER: 'Register',
    PAGE__REGISTER__REGISTER_FORM__SUB_HEADER: 'Creating a new account',
    PAGE__REGISTER__REGISTER_FORM__BUTTON__SIGN_UP: 'Create an account',
    PAGE__REGISTER__REGISTER_FORM__INPUT__NAME__LABEL: 'Name:',
    PAGE__REGISTER__REGISTER_FORM__INPUT__NAME__PLACEHOLDER: 'Your name',
    PAGE__REGISTER__REGISTER_FORM__INPUT__SURNAME__LABEL: 'Surname:',
    PAGE__REGISTER__REGISTER_FORM__INPUT__SURNAME__PLACEHOLDER: 'Your surname',
    PAGE__REGISTER__REGISTER_FORM__INPUT__PHONE__LABEL: 'Phone number:',
    PAGE__REGISTER__REGISTER_FORM__INPUT__CITIZEN__LABEL: 'Citizenship:',
    PAGE__REGISTER__REGISTER_FORM__INPUT__CITIZEN__PLACEHOLDER: 'Citizenship',
    PAGE__REGISTER__REGISTER_FORM__ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',

    PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_NAME: 'Please enter your name',
    PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_EMAIL: 'Please enter a valid email address',
    PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_PHONE_NUMBER: 'Please enter a number in international format',
    PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_COUNTRY: 'Please specify citizenship',

    PAGE__REGISTER__NOTIFICATION__SUCCESS__HEADER: 'Account successfully created',
    PAGE__REGISTER__NOTIFICATION__SUCCESS__CONTENT: 'Confirm your email address. A confirmation link has been sent.',
    PAGE__REGISTER__NOTIFICATION__SUCCESS__CONTENT__NO_CONFIRMATION: 'Sign in to continue.',
    PAGE__REGISTER__NOTIFICATION__ERROR__HEADER: 'Failed to create an account',

    PAGE__FORGOT_PASSWORD__FORGOT_PASSWORD_FORM__HEADER: 'Access recovery',
    PAGE__FORGOT_PASSWORD__FORGOT_PASSWORD_FORM__BUTTON__RESTORE: 'Reset the password',
    PAGE__FORGOT_PASSWORD__RESET_LINK_SENT: 'Password reset link sent successfully.',
    PAGE__FORGOT_PASSWORD__RESET_LINK_SEND_ERROR: 'Failed to send password reset link',
    PAGE__FORGOT_PASSWORD__RESET_LINK_SEND__LABEL: 'E-mail to which the link was sent:',
    PAGE__FORGOT_PASSWORD__RESET_LINK_SEND_TO_EMAIL:
        'A link to reset your password has been sent to your email address. Follow the link to create a new password.',

    PAGE__NEW_PASSWORD__TITLE: 'New password',
    PAGE__NEW_PASSWORD__ENTER: 'Make up a new password',
    PAGE__NEW_PASSWORD__PASSWORD__PLACEHOLDER: 'Password',
    PAGE__NEW_PASSWORD__CONFIRM_PASSWORD__PLACEHOLDER: 'Repeat your password',
    PAGE__NEW_PASSWORD__SUCCESS: 'New password has been successfully created',
    PAGE__NEW_PASSWORD__SERVER_ERROR: 'An error occurred while trying to reset a password',
    PAGE__NEW_PASSWORD__URL_ERROR: 'Incorrect URL format',
    PAGE__NEW_PASSWORD__PASSWORDS_DONT_MATCH: 'The entered passwords do not match',

    PAGE__AUTH__LINK_TO__FORGOT_PASSWORD: 'Forgot password?',
    PAGE__AUTH__LINK_TO__REGISTER: 'Create an account',
    PAGE__AUTH__LINK_TO__LOGIN: 'Login',

    PAGE__TWO_FACTOR_AUTHENTICATION_ENTER_SIX_DIGIT_CODE: 'Enter 6-digit code',

    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__HEADER: '2FA connected',
    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__DESCRIPTION:
        'Secure your account with two-factor authentication to continue',
    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__SUBMIT: 'Connect',
    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__SUCCESS: '2FA connected',
    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__SUCCESS__DESCRIPTION:
        'You have successfully connected two-factor authentication to your account.',
    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__ERROR: 'Invalid authentication code',
    PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__ERROR__DESCRIPTION: 'Enter the code from the application for this account',
    SNACKBAR__SUCCESS__LOGIN: 'You have successfully logged in to your personal account',
};
