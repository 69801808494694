export const enCompanyMultiselectTranslations = {
    COMPANY__MULTISELECT__BUTTON__LABEL: 'Select companies',
    COMPANY__MULTISELECT__ERROR: 'No companies selected!',
    COMPANY__MULTISELECT__NO_COMPANIES_SELECTED: 'No companies selected',
    COMPANY__MULTISELECT__SELECTED_COUNT: 'Selected: {count}',
    COMPANY__MULTISELECT__SELECTED_COMPANY_COUNT: 'Selected companies: {count}',
    COMPANY__MULTISELECT__POPUP__SELECTION_OF_COMPANIES: 'Selection of companies',
    COMPANY__MULTISELECT__POPUP__COMPANIES_COUNT: 'Companies: {count}',
    COMPANY__MULTISELECT__POPUP__FILTERED_COUNT: 'Shown: {count}',
    COMPANY__MULTISELECT__POPUP__ALL_BRANDS: 'All brands',
    COMPANY__MULTISELECT__POPUP__ALL_GROUPS: 'All groups',
    COMPANY__MULTISELECT__POPUP__SEARCH_FOR_COMPANIES: 'Search by name, address, or branch code...',
    COMPANY__MULTISELECT__POPUP__QR_ALL: 'All companies',
    COMPANY__MULTISELECT__POPUP__QR_WITH: 'With Qr code',
    COMPANY__MULTISELECT__POPUP__QR_WITHOUT: 'Without Qr code',
    COMPANY__MULTISELECT__POPUP__QR_RATING_PLACEHOLDER: 'Any rating',
    COMPANY__MULTISELECT__CLEAR_SELECTION: 'Reset selection',
    COMPANY__MULTISELECT__DESELECT_FILTERED: 'Exclude all',
    COMPANY__MULTISELECT__SELECT_FROM_MAIN_FILTER: 'Select from common filter ({count})',
    COMPANY__MULTISELECT__SHOW_SELECTED_ONLY: 'Show only selected companies',
    COMPANY__MULTISELECT__CHOSEN_FILTERS_LENGTH: 'Selected filters: {count}',
};
