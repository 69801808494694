import {appRoute} from '../../app-route';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {NavigationLink} from '../navigation-link/navigation-link';

type PropsType = {
    isShort?: boolean;
    className?: string;
    linkClassName?: string;
};

export function Logo(props: PropsType): JSX.Element | null {
    const {isShort, className, linkClassName} = props;

    const {logoFullSrc, logoShortSrc, companyName} = useDomainConfig();
    const logoSrc = isShort ? logoShortSrc : logoFullSrc;

    if (!logoSrc) {
        return null;
    }

    return (
        <NavigationLink className={linkClassName} title={companyName} to={appRoute.root.path}>
            <img alt={companyName} className={className} src={logoSrc} />
        </NavigationLink>
    );
}
