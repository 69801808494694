import {YandexStorySelectedCompanyType} from '../../../../../service/yandex-stories/yandex-stories-type';
import {Text} from '../../../../text/text';

import * as styles from './yandex-story-selected-companies-cells.scss';

type PropsType = {
    record: YandexStorySelectedCompanyType;
};

export function YandexSelectedCompaniesCompanyCell(props: PropsType): JSX.Element {
    const {record} = props;

    return (
        <div className={styles.YandexStorySelectedCompaniesCells_companyCell}>
            <div className={styles.YandexStorySelectedCompaniesCells_companyCellName}>
                <Text block>{record.name}</Text>
                <Text block lightest>
                    {record.companyCode}
                </Text>
            </div>
            <Text block small>
                {record.address}
            </Text>
        </div>
    );
}
