import {Select} from 'antd';

import {FilterSuggestionType} from '../../../../../service/main-filter/main-filter-type';

const {Option} = Select;

export function renderOption(item: FilterSuggestionType): JSX.Element {
    const {id, label} = item;

    return (
        <Option key={id} value={id}>
            {label}
        </Option>
    );
}
