import {faCaretUp} from '@fortawesome/pro-regular-svg-icons';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Typography} from 'antd';
import {useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {ReviewNoteUserType} from '../../../../../service/reviews/reviews-notes';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {LogActionsEnum} from '../review-log-type';

import * as styles from './abuse-log.scss';

type PropsType = {
    action: string;
    createdAt: string;
    user: ReviewNoteUserType;
    value: string;
};

export function AbuseLog(props: PropsType): JSX.Element {
    const {action, user, createdAt, value} = props;

    const {companyName} = useDomainConfig();
    const {getFormattedDateTime} = useFormat();

    const [ellipsis, setEllipsis] = useState(false);
    const [uniqueKey, setUniqueKey] = useState(0);

    const formattedDate = createdAt
        ? getFormattedDateTime(new Date(createdAt), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              [TimeSizeEnum.hour]: 'numeric',
              [TimeSizeEnum.minute]: 'numeric',
          })
        : null;

    // eslint-disable-next-line unicorn/no-unreadable-iife
    const userName = (() => (user ? `${user.firstName || ''} ${user.lastName || ''}` : companyName))();

    const localeString = (() => {
        switch (action) {
            case LogActionsEnum.Created:
                return 'REVIEWS__REVIEW_ABUSE_CREATED_BY';
            case LogActionsEnum.Deleted:
                return 'REVIEWS__REVIEW_ABUSE_DELETED_BY';
            default:
                return 'REVIEWS__REVIEW_ABUSE_UPDATED_BY';
        }
    })();

    function onExpand() {
        setEllipsis(true);
    }

    function handleClose() {
        setEllipsis(false);
        setUniqueKey(uniqueKey + 1);
    }

    return (
        <div className={styles.AbuseLog}>
            <div className={styles.AbuseLog_title}>
                <FontAwesomeIcon className={styles.AbuseLog_icon} icon={faTriangleExclamation} />

                <Text
                    className={styles.AbuseLog_text}
                    stringKey={localeString}
                    valueMap={{
                        user: <span className={styles.AbuseLog_user}>{userName}</span>,
                    }}
                />
            </div>

            <span className={styles.AbuseLog_date}>{formattedDate}</span>

            <Typography.Paragraph
                className={styles.AbuseLog_data}
                ellipsis={{
                    rows: 1,
                    expandable: true,
                    symbol: <Locale stringKey="REVIEWS__REVIEW_ABUSE__SHOW_TEXT" />,
                    onExpand,
                }}
                key={`${createdAt}-${uniqueKey}`}
                type="secondary"
            >
                {value}
            </Typography.Paragraph>

            {ellipsis && (
                <Button
                    className={styles.AbuseLog_button}
                    icon={<FontAwesomeIcon icon={faCaretUp} />}
                    onClick={handleClose}
                    type="link"
                >
                    <Text stringKey="REVIEWS__REVIEW_ABUSE__HIDE_TEXT" />
                </Button>
            )}
        </div>
    );
}
