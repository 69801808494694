import {faClockRotateLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../provider/locale/localization';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import * as styles from '../review-list-page-content/review-list/review/review.scss';

import {ReviewHistoryPopup} from './review-history-popup/review-history-popup';

type PropsType = {
    reviewId: number;
    createdInRd: string;
    createdInCatalog: string;
};

export function ReviewHistory(props: PropsType): JSX.Element | null {
    const {reviewId, createdInRd, createdInCatalog} = props;

    const [isReviewHistoryOpen, setIsReviewHistoryOpen] = useState<boolean>(false);

    return (
        <>
            <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_CHANGE_HISTORY__HINT" />}>
                <button
                    className={styles.Review_iconButton}
                    onClick={() => {
                        setIsReviewHistoryOpen(true);
                        track(AnalyticsTarget.ReviewsManagement.Reviews.ViewHistory);
                    }}
                    type="button"
                >
                    <FontAwesomeIcon icon={faClockRotateLeft} />
                </button>
            </Tooltip>

            <ReviewHistoryPopup
                isOpen={isReviewHistoryOpen}
                onCancel={() => setIsReviewHistoryOpen(false)}
                reviewCreationDate={createdInCatalog || createdInRd}
                reviewId={reviewId}
            />
        </>
    );
}
