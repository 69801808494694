import {Button, Space} from 'antd';
import {useEffect} from 'react';

import {Text} from '../../../../../../component/text/text';
import {PageCard} from '../../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../../provider/locale/localization';
import {useDeleteUserMutation} from '../../../../../../service/user-management/user-management-user';

import * as styles from './selection-summary.scss';

type PropsType = {
    total: number;
    totalSelected: number;
    selectedIds: Array<number>;
    email?: string | null;
    onDelete: () => void;
    toggleSelectAll: (selectAll: boolean) => void;
};

export function SelectionSummary(props: PropsType): JSX.Element | null {
    const {total, totalSelected, selectedIds, email, onDelete, toggleSelectAll} = props;

    const {mutate} = useDeleteUserMutation({
        ids: selectedIds,
        email,
        onSuccess: () => onDelete(),
    });

    useEffect(() => {
        const floatingButton = document.querySelector('#hde-container');

        if (totalSelected > 0) {
            floatingButton?.setAttribute('hidden', 'true');
        }

        return () => {
            floatingButton?.removeAttribute('hidden');
        };
    }, [totalSelected]);

    if (totalSelected === 0) {
        return null;
    }

    return (
        <PageCard bodyClassName={styles.SelectionSummary_content} className={styles.SelectionSummary_card}>
            <Space className={styles.SelectionSummary_section} size="middle">
                <Text
                    large
                    stringKey="USERS__TABLE__SELECTION"
                    valueMap={{
                        selected: (
                            <Text bolder large>
                                {totalSelected}
                            </Text>
                        ),
                        total: (
                            <Text large secondary>
                                {total}
                            </Text>
                        ),
                    }}
                />

                {totalSelected < total && (
                    <Button onClick={() => toggleSelectAll(true)} size="small">
                        <Locale stringKey="TEXT__SELECT_ALL" />
                    </Button>
                )}
            </Space>

            <Space className={styles.SelectionSummary_section}>
                <Button htmlType="reset" onClick={() => toggleSelectAll(false)}>
                    <Locale stringKey="BUTTON__CANCEL" />
                </Button>

                <Button danger onClick={() => mutate(null)} type="primary">
                    <Locale stringKey="BUTTON__DELETE" />
                </Button>
            </Space>
        </PageCard>
    );
}
