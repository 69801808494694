import {Badge} from 'antd';
import {sum} from 'lodash';
import {PropsWithChildren} from 'react';

import {useFeedManagementList} from '../../../../../../service/companies-import/companies-import-list';
import {classNames} from '../../../../../../util/css';

import {getBadgeLeftOffsetPx} from './feed-badge-helper';
import * as styles from './feed-badge.scss';

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function FeedBadge(props: PropsType): JSX.Element {
    const {children} = props;

    const {data} = useFeedManagementList();

    const importErrorsCount = sum(data?.results.map(({importResultInfo}) => importResultInfo.validationError));
    const hasCriticalErrors = Boolean(
        data?.results.some(({importResultInfo}) => importResultInfo.criticalError && importResultInfo.criticalError > 0)
    );

    return (
        <Badge
            className={classNames(styles.FeedBadge, {
                [styles.FeedBadge__critical]: hasCriticalErrors,
            })}
            count={0}
            offset={[getBadgeLeftOffsetPx(importErrorsCount), 0]}
            overflowCount={999}
        >
            {children}
        </Badge>
    );
}
