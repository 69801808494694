import {Button} from 'antd';
import {useCallback, useState} from 'react';
import {ExtractRouteParams, generatePath} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {
    deleteReviewUserTopic,
    updateReviewUserTopic,
} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-api';
import {emptyReviewUserTopic} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-const';
import {useReviewUserTopicRead} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-hook';
import {ReviewUserTopicType} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-type';
import {ErrorWithStatus, getMessageFromStatusError, isErrorHasStatusMessage} from '../../../../../util/error';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {TopicManagement} from '../../components/topic-management/topic-management';
import {topicsErrorMessagesMap} from '../../reviews-analysis-const';

export function TopicUpdatePage(): JSX.Element {
    const {
        reviewsAnalysisDashboard: dashboardRoute,
        reviewsAnalysisTopic: topicDetailsRoute,
        reviewsAnalysisKeywordsAndTopics: tagsAndTopicsRoute,
        reviewsAnalysisTopicUpdate: topicUpdateRoute,
    } = appRoute;

    const {getLocalizedString} = useLocale();
    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const {id} = useParams<ExtractRouteParams<typeof topicUpdateRoute.path, string>>();
    const topicId = Number(id);
    const returnRedirectPath = generatePath(topicDetailsRoute.path, {id});

    const [isInProgress, setIsInProgress] = useState(false);

    const {
        result: userTopic,
        isInProgress: isUserTopicInProgress,
        processError: userTopicProcessError,
    } = useReviewUserTopicRead(topicId);

    const onSubmit = useCallback(
        async (newUserTopic: Omit<ReviewUserTopicType, 'id'>) => {
            setIsInProgress(true);

            try {
                await updateReviewUserTopic(topicId, newUserTopic);

                snackbar.success(<Locale stringKey="REVIEWS_ANALYSIS__FORM__UPDATE__MESSAGE_TITLE" />);
                pushUrl(returnRedirectPath);
            } catch (error) {
                if (isErrorHasStatusMessage<ErrorWithStatus>(error)) {
                    const errorCode = getMessageFromStatusError(error);
                    const errorFromMap = topicsErrorMessagesMap.find((message) => message.code === errorCode);

                    snackbar.error(errorFromMap ? errorFromMap.message : errorCode);
                } else {
                    snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
                }
            } finally {
                setIsInProgress(false);
            }
        },
        [pushUrl, returnRedirectPath, snackbar, topicId]
    );

    const onDeleteClick = useCallback(async () => {
        const response = await deleteReviewUserTopic(userTopic?.id || Number.NaN);

        if (response instanceof Error) {
            snackbar.error(
                isErrorHasStatusMessage<ErrorWithStatus>(response) ? (
                    response.jsonData.status
                ) : (
                    <Locale stringKey="REVIEW_ANALYSIS__SNACKBAR__TEXT__TOPIC_DELETE_ERROR" />
                )
            );
        } else {
            snackbar.success(<Locale stringKey="REVIEW_ANALYSIS__SNACKBAR__TEXT__TOPIC_DELETED" />);
        }
    }, [userTopic?.id, snackbar]);

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC_UPDATE')} />

            <BreadCrumbs
                list={[
                    {
                        path: dashboardRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                    },
                    {
                        path: tagsAndTopicsRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__TOPICS',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC_UPDATE" />
            </PageHeader>

            <Spinner isShow={isUserTopicInProgress} position="absolute" />

            {userTopicProcessError && (
                <AlertFallback description={userTopicProcessError?.message} message={userTopicProcessError?.name} />
            )}

            {userTopic && (
                <TopicManagement
                    deleteButton={
                        <Button
                            danger
                            htmlType="button"
                            onClick={() => {
                                modal.confirm({
                                    maskClosable: true,
                                    title: <Locale stringKey="REVIEW_ANALYSIS__POPUP_HEADER__DELETE_TOPIC" />,
                                    content: (
                                        <Locale
                                            stringKey="REVIEW_ANALYSIS__POPUP__TEXT__THE_TOPIC_WILL_BE_REMOVED_ARE_YOU_SURE"
                                            valueMap={{
                                                topic: userTopic?.name || '',
                                            }}
                                        />
                                    ),
                                    okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
                                    cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                                    cancelButtonProps: {type: 'primary'},
                                    okButtonProps: {type: 'default', danger: true},
                                    onOk: onDeleteClick,
                                });
                            }}
                            type="link"
                        >
                            <Locale stringKey="REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__DELETE_BUTTON" />
                        </Button>
                    }
                    isSubmitting={isInProgress}
                    onSubmit={onSubmit}
                    userTopic={userTopic || emptyReviewUserTopic}
                />
            )}
        </Page>
    );
}
