import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

import {tariffUrl} from './tariffs-const';

export const tariffSchema = r.object({
    id: r.number(),
    name: r.string(),
    isActive: r.boolean(),
    isTest: r.boolean(),
    isDemo: r.boolean(),
    startAt: r.string(),
    finishAt: r.string(),
    trialStartAt: r.string().nullable(),
    brandCount: r.number(),
    brands: r.array(r.object({name: r.string(), companyCount: r.number()})),
    isChangeTariffEnabled: r.boolean(),
    isViewTariffsListEnabled: r.boolean(),
});

export type TariffType = r.infer<typeof tariffSchema>;

function getTariffUrl(tariffId: number | null) {
    return `${tariffUrl}/${tariffId}/`;
}

function fetchTariff(tariffId: number | null): Promise<TariffType> {
    return fetchAndDeserialize(getTariffUrl(tariffId), tariffSchema);
}

export function useTariff(tariffId: number | null): UseQueryResult<TariffType> {
    return useQuery([getTariffUrl(tariffId)], () => fetchTariff(tariffId), {
        enabled: tariffId !== null,
    });
}
