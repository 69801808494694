import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {isString, noop} from 'lodash';
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {z as r} from 'zod';

import {FeedResultsTypeEnum} from '../../page/main/my-companies/feed/common/results/feed-results-const';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useMessage} from '../../provider/message/message-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useUser} from '../../provider/user/user-hook';
import {postAndDeserialize} from '../../util/api-adapter';
import {ApiError} from '../../util/error';
import {UserType} from '../user/user-type';

import {companiesImportUrl} from './companies-import-const';
import {CompaniesImportResultType, fetchCompaniesImportValidationResult} from './companies-import-result';
import {getCompaniesImportErrorCodesUrl} from './companies-import-result-error-codes';
import {CompaniesImportTypeEnum} from './companies-import-type';

const DELAY_SECONDS = 5;

const companiesImportValidationSchema = r.object({
    tempId: r.string(),
    importUrlId: r.number().optional(),
});

type CompaniesImportValidationType = r.infer<typeof companiesImportValidationSchema>;

export type CompaniesValidationOptionsType = {
    importType: CompaniesImportTypeEnum;
    link?: string | null;
    feedFileTempId?: string;
    feedFileName?: string;
    brandId?: number;
    allowedBrands?: Array<{brandId: number; brandCode: string}>;
};

function postValidateCompany(
    options: CompaniesValidationOptionsType & {user: UserType | null; shortLocaleName: ShortLocaleNameEnum}
): Promise<CompaniesImportValidationType> {
    const {feedFileName, user, shortLocaleName, ...urlOptions} = options;

    const url = `${companiesImportUrl}/validate/`;

    if (user) {
        return postAndDeserialize(
            `${companiesImportUrl}/authorized_validate/`,
            companiesImportValidationSchema,
            urlOptions
        );
    }

    return postAndDeserialize(url, companiesImportValidationSchema, urlOptions, {unauthorized: true, shortLocaleName});
}

function postImportCompany(options: CompaniesValidationOptionsType): Promise<CompaniesImportValidationType> {
    const {feedFileName, ...urlOptions} = options;

    const url = `${companiesImportUrl}/import_url/`;

    return postAndDeserialize(url, companiesImportValidationSchema, urlOptions);
}

export type UseCompaniesImportValidationHookType = UseMutationResult<
    CompaniesImportValidationType,
    unknown,
    CompaniesValidationOptionsType
> & {
    id: string | null;
    importUrlId: number | null;
    result: CompaniesImportResultType | null;
    percent: number;
    remainingRelativeTime: string | null;
    source: CompaniesValidationOptionsType | null;
    isDisabled: boolean;
    setIsDisabled: Dispatch<SetStateAction<boolean>>;
    isCancelled: boolean;
    setIsCancelled: Dispatch<SetStateAction<boolean>>;
};

export function useCompaniesImportValidation(type: FeedResultsTypeEnum): UseCompaniesImportValidationHookType {
    const [percent, setPercent] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [result, setResult] = useState<CompaniesImportResultType | null>(null);
    const [source, setSource] = useState<CompaniesValidationOptionsType | null>(null);

    const timeout = useRef<NodeJS.Timeout>();
    const queryClient = useQueryClient();
    const {feedTypeLabels} = useDomainConfig();
    const {user} = useUser();
    const {snackbar} = useSnackbar();
    const {message} = useMessage();
    const {shortLocaleName} = useLocale();

    function reset() {
        setPercent(0);
        setResult(null);
        setSource(null);
        setIsDisabled(false);
    }

    function showSuccess(newType?: CompaniesImportTypeEnum) {
        if (type === FeedResultsTypeEnum.Import) {
            setIsDisabled(true);
        }

        snackbar.success({
            message: (
                <Locale
                    stringKey={
                        type === FeedResultsTypeEnum.Import
                            ? 'FEED__IMPORT__SUCCESS__TITLE'
                            : 'FEED__VALIDATION__SUCCESS__TITLE'
                    }
                />
            ),
            description: (
                <Locale
                    stringKey={
                        type === FeedResultsTypeEnum.Import
                            ? 'FEED__IMPORT__SUCCESS__DESCRIPTION'
                            : 'FEED__VALIDATION__SUCCESS__DESCRIPTION'
                    }
                    valueMap={{
                        format: newType && feedTypeLabels ? feedTypeLabels[newType] : '',
                    }}
                />
            ),
        });
    }

    async function updateResult(id?: string, importType?: CompaniesImportTypeEnum) {
        if (!id || percent === 100) {
            return;
        }

        try {
            const {validationResult} = await fetchCompaniesImportValidationResult(id);
            const newPercent = Math.round((validationResult.processedCount / validationResult.all) * 100);
            const newType = importType || source?.importType;

            setResult(validationResult);
            setPercent(newPercent);

            if (newPercent === 100 && !validationResult.foreignError && !validationResult.internalError) {
                showSuccess(newType);
            } else {
                timeout.current = setTimeout(() => updateResult(id, importType), DELAY_SECONDS * 1000);
            }

            queryClient.invalidateQueries({queryKey: [getCompaniesImportErrorCodesUrl(id)]});
        } catch {
            timeout.current = setTimeout(() => updateResult(id, importType), DELAY_SECONDS * 1000);
        }
    }

    const mutation = useMutation({
        mutationFn: (options: CompaniesValidationOptionsType) =>
            type === FeedResultsTypeEnum.Validation
                ? postValidateCompany({...options, user, shortLocaleName})
                : postImportCompany(options),
        onMutate: () => {
            setIsCancelled(false);
        },
        onSuccess: ({tempId}, options) => {
            updateResult(tempId, options.importType);
            setSource(options);
        },
        onError: (error, options) => {
            if (error instanceof ApiError && Array.isArray(error.jsonData) && error.jsonData.every(isString)) {
                message.error(error.jsonData.join(', '));
                reset();
            } else {
                setSource(options);
            }
        },
    });

    const {data} = mutation;

    useEffect(() => {
        if (!data?.tempId) {
            return noop;
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [data?.tempId]);

    return {
        ...mutation,
        id: data?.tempId ?? null,
        importUrlId: data?.importUrlId ?? null,
        result,
        percent,
        remainingRelativeTime: null,
        source,
        isDisabled,
        isCancelled,
        setIsDisabled,
        setIsCancelled,
        reset: () => {
            mutation.reset();
            reset();
        },
    };
}
