import {
    faBuilding,
    faCalendar,
    faCalendarCheck,
    faChevronDown,
    faHourglassHalf,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Collapse, Space} from 'antd';

import {CatalogLogo} from '../../../../../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../../../../../component/text/text';
import {
    GoogleConfirmationRequestStatusEnum,
    GoogleConfirmationRequestType,
} from '../../../../../../../../service/google/confirmation/google-confirmation-type';
import {classNames} from '../../../../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../../../../util/type';
import {getGoogleRequestStatusTag} from '../../../../common/company-list-item/company-list-item-helper';

import {RequestCompanies} from './request-companies/request-companies';
import {dateOfChangeStatuses, requestMethodNames} from './requests-list-item-const';
import * as styles from './requests-list-item.scss';

type PropsType = {
    request: GoogleConfirmationRequestType;
};

export function RequestsListItem(props: PropsType): JSX.Element {
    const {request} = props;

    const isWaiting = request.status === GoogleConfirmationRequestStatusEnum.Pending;

    return (
        <Collapse
            className={styles.RequestsListItem}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({isActive}) => (
                <Button
                    className={styles.RequestsListItem_expandIcon}
                    icon={<FontAwesomeIcon icon={faChevronDown} rotate={isActive ? 180 : 0} />}
                    type="text"
                />
            )}
            expandIconPosition="end"
            ghost
            items={[
                {
                    key: request.pk,
                    children: <RequestCompanies request={request} />,
                    label: (
                        <>
                            <div className={styles.RequestsListItem_grid}>
                                <div className={styles.RequestsListItem_title}>
                                    <CatalogLogo catalogId={ProvidersIdsEnum.google} circle />

                                    <Space className={styles.RequestsListItem_text} direction="vertical" size={2}>
                                        <Text
                                            stringKey="GOOGLE_SYNC__CONFIRMATIONS__REQUEST"
                                            valueMap={{request: request.pk}}
                                        />

                                        <Text
                                            secondary
                                            stringKey="GOOGLE_SYNC__CONFIRMATIONS__METHOD__TYPE"
                                            valueMap={{
                                                method: <Text lighter stringKey={requestMethodNames[request.method]} />,
                                            }}
                                        />
                                    </Space>
                                </div>

                                <div
                                    className={classNames(
                                        styles.RequestsListItem_iconWithData,
                                        styles.RequestsListItem_iconWithData__companies
                                    )}
                                >
                                    <FontAwesomeIcon icon={faBuilding} />
                                    <Text lighter>{request.companiesCount}</Text>
                                </div>

                                <div
                                    className={classNames(
                                        styles.RequestsListItem_iconWithData,
                                        styles.RequestsListItem_iconWithData__blue
                                    )}
                                >
                                    <FontAwesomeIcon icon={faCalendar} />
                                    <Text lighter>{request.createdAt}</Text>
                                </div>

                                {request.dateOfChangeStatus && dateOfChangeStatuses.includes(request.status) && (
                                    <div
                                        className={classNames(
                                            styles.RequestsListItem_iconWithData,
                                            isWaiting
                                                ? styles.RequestsListItem_iconWithData__orange
                                                : styles.RequestsListItem_iconWithData__green
                                        )}
                                    >
                                        <FontAwesomeIcon icon={isWaiting ? faHourglassHalf : faCalendarCheck} />

                                        <Text lighter>{request.dateOfChangeStatus}</Text>
                                    </div>
                                )}
                            </div>

                            {getGoogleRequestStatusTag(request.status)}
                        </>
                    ),
                },
            ]}
        />
    );
}
