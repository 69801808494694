import {useQueryClient} from '@tanstack/react-query';
import {Button, Modal} from 'antd';
import {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {
    SortableContainer as createSortableContainer,
    SortableElement as createSortableElement,
} from 'react-sortable-hoc';

import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useYandexPreview} from '../../../service/yandex-stories/yandex-stories-hook';
import {
    YandexShortStoryType,
    YandexStoryStatusEnum,
    YandexStoryTypeEnum,
} from '../../../service/yandex-stories/yandex-stories-type';
import {arrayMove} from '../../../util/array';
import {getSortedStoriesList} from '../stories-helper';
import {YandexStoriesPreview} from '../yandex-stories-preview/yandex-stories-preview';
import {NewYandexStoryCard} from '../yandex-story-card/new-yandex-story-card';
import {YandexStoryCard} from '../yandex-story-card/yandex-story-card';
import {YandexStoryEdit} from '../yandex-story-edit/yandex-story-edit';

import {DragHandle} from './drag-handle';
import {YandexDeleteButton} from './yandex-delete-button';
import * as styles from './yandex-stories-sources.scss';

type SortableListPropsType = PropsWithChildren<{
    className?: string;
}>;

type SortableItemStoryType = {
    story: YandexShortStoryType;
    isDisabled?: boolean;
};

type PropsType = {
    open: boolean;
    onClose: () => void;
    shortStories: Array<YandexShortStoryType>;
    companyId?: number;
    brandId?: number;
};

export function YandexStoriesSources(props: PropsType): JSX.Element {
    const {open, onClose, shortStories, companyId, brandId} = props;

    const {getLocalizedString} = useLocale();
    const queryClient = useQueryClient();

    const [stories, setStories] = useState(getSortedStoriesList(shortStories));
    const navigationStories = useMemo(() => getSortedStoriesList(shortStories, true), [shortStories]);
    const hasActiveNavigationStory = navigationStories.some(
        (story) => story.status !== YandexStoryStatusEnum.Rejected && story.status !== YandexStoryStatusEnum.Deleted
    );

    useEffect(() => {
        setStories(getSortedStoriesList(shortStories));
    }, [shortStories]);

    const yandexPreviewStories: Array<YandexShortStoryType> = useMemo(() => {
        return [...navigationStories, ...stories];
    }, [navigationStories, stories]);

    const {activeStoryId, nextStoryId, previousStoryId, onChangeStoryId} = useYandexPreview(yandexPreviewStories);

    function handleSortEnd(sortData: {oldIndex: number; newIndex: number}) {
        const {newIndex, oldIndex} = sortData;
        const newStories = arrayMove([...stories], oldIndex, newIndex);

        setStories(newStories);
    }

    const handleRevalidation = useCallback(() => {
        queryClient.invalidateQueries(['yandex-sources-stories']);
    }, [queryClient]);

    const SortableItem = useMemo(() => {
        return createSortableElement<SortableItemStoryType>((storyProps: SortableItemStoryType) => {
            const {story, isDisabled} = storyProps;

            const isActionDisabled = story.status === YandexStoryStatusEnum.Deleted;

            return (
                <li className={styles.YandexStoriesSources_row}>
                    <DragHandle disabled={isDisabled || isActionDisabled} />
                    <div className={styles.YandexStoriesSources_storyBox}>
                        <YandexStoryCard
                            data={story}
                            onClick={() => {
                                onChangeStoryId(story.id);
                            }}
                            variant="horizontal"
                        />
                    </div>
                    <div className={styles.YandexStoriesSources_rowActions}>
                        {!isActionDisabled && (
                            <YandexDeleteButton
                                iconClassName={styles.YandexStoriesSources_deleteIcon}
                                onDeleteSuccess={handleRevalidation}
                                storyId={story.id}
                                storyName={story.name}
                                type="text"
                            />
                        )}
                        <YandexStoryEdit
                            disabled={isActionDisabled}
                            iconOnly
                            onEditSuccess={handleRevalidation}
                            storyId={story.id}
                        />
                    </div>
                </li>
            );
        });
    }, [handleRevalidation, onChangeStoryId]);

    const SortableList = useMemo(() => {
        return createSortableContainer<SortableListPropsType>((sortableContainerProps: SortableListPropsType) => {
            const {children, className} = sortableContainerProps;

            return <ul className={className}>{children}</ul>;
        });
    }, []);

    function handleClose() {
        // todo: implement in future
        // const resultStories = [...navigationStories, ...stories];

        // todo: handle callback from props and update via api stories order
        onClose();
    }

    // todo: implement modal footer conditional buttons save order and cancel

    return (
        <>
            {open && (
                <Modal
                    destroyOnClose
                    footer={[
                        <Button key="close" onClick={handleClose} type="primary">
                            <Locale stringKey="POPUP__BUTTON__CLOSE" />
                        </Button>,
                    ]}
                    maskClosable={false}
                    onCancel={onClose}
                    open={open}
                    title={getLocalizedString('YANDEX_STORY__SOURCES__MODAL__TITLE')}
                >
                    <div className={styles.YandexStoriesSources_listWrapper}>
                        <SortableList
                            className={styles.YandexStoriesSources_list}
                            helperClass={styles.YandexStoriesSources_row__dragging}
                            lockAxis="y"
                            onSortEnd={handleSortEnd}
                            useDragHandle
                        >
                            <li className={styles.YandexStoriesSources_row}>
                                <NewYandexStoryCard
                                    brandId={brandId}
                                    companyId={companyId}
                                    onCreateSuccess={handleRevalidation}
                                    type={YandexStoryTypeEnum.Story}
                                    variant="horizontal"
                                />
                            </li>
                            {!hasActiveNavigationStory && (
                                <li className={styles.YandexStoriesSources_row}>
                                    <NewYandexStoryCard
                                        brandId={brandId}
                                        companyId={companyId}
                                        onCreateSuccess={handleRevalidation}
                                        type={YandexStoryTypeEnum.Navigation}
                                        variant="horizontal"
                                    />
                                </li>
                            )}
                            {navigationStories.map((story) => {
                                return (
                                    <li className={styles.YandexStoriesSources_row} key={story.id}>
                                        <div className={styles.YandexStoriesSources_storyBox}>
                                            <YandexStoryCard
                                                data={story}
                                                onClick={() => {
                                                    onChangeStoryId(story.id);
                                                }}
                                                variant="horizontal"
                                            />
                                        </div>
                                        <div className={styles.YandexStoriesSources_rowActions}>
                                            {story.status !== YandexStoryStatusEnum.Deleted && (
                                                <YandexDeleteButton
                                                    iconClassName={styles.YandexStoriesSources_deleteIcon}
                                                    onDeleteSuccess={handleRevalidation}
                                                    storyId={story.id}
                                                    storyName={story.name}
                                                    type="text"
                                                />
                                            )}
                                            <YandexStoryEdit
                                                disabled={story.status === YandexStoryStatusEnum.Deleted}
                                                iconOnly
                                                onEditSuccess={handleRevalidation}
                                                storyId={story.id}
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                            {...stories.map((item, index): JSX.Element => {
                                return (
                                    <SortableItem
                                        disabled={item.type === YandexStoryTypeEnum.Navigation}
                                        index={index}
                                        isDisabled={item.type === YandexStoryTypeEnum.Navigation}
                                        key={item.id}
                                        story={item}
                                    />
                                );
                            })}
                        </SortableList>
                    </div>
                </Modal>
            )}
            {activeStoryId !== null && (
                <YandexStoriesPreview
                    activeStoryId={activeStoryId}
                    nextStoryId={nextStoryId}
                    onChangeStoryId={onChangeStoryId}
                    previousStoryId={previousStoryId}
                />
            )}
        </>
    );
}
