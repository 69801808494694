import {Centrifuge, Subscription} from 'centrifuge';
import {useCallback, useContext} from 'react';

import {CentrifugeContext} from './centrifuge-context';
import {CentrifugeHookType} from './centrifuge-type';

export function useCentrifugeSubscription(): CentrifugeHookType {
    const centrifuge = useContext<Centrifuge | null>(CentrifugeContext);

    const getSubscription = useCallback(
        (channelName: string): Subscription | null => {
            if (!centrifuge) {
                return null;
            }

            const subscription = centrifuge.subscriptions()[channelName];

            if (subscription) {
                return subscription;
            }

            const newSubscription = centrifuge.newSubscription(channelName);

            newSubscription.subscribe();

            return newSubscription;
        },
        [centrifuge]
    );

    return {
        getSubscription,
    };
}
