import {useMemo} from 'react';

import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../provider/domain-config/domain-config-type';
import {ShortLocaleNameEnum} from '../../../provider/locale/locale-context-type';
import {useLocale} from '../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {classNames} from '../../../util/css';
import {Text} from '../../text/text';

import {LandingOnboardingStep} from './landing-onboarding-step';
import * as styles from './landing-onboarding-step.scss';

type PropsType = {
    images: {
        [ShortLocaleNameEnum.ru]: string;
        [ShortLocaleNameEnum.en]: string;
        brandWizardImage?: string;
    };
    onStepFinish: VoidFunction;
    titleLangKeyType: LangKeyType;
    descriptionLangKeyType: LangKeyType;
    imageClassName?: string;
};

export function LandingPresentationStep(props: PropsType): JSX.Element {
    const {images, titleLangKeyType, descriptionLangKeyType, onStepFinish, imageClassName} = props;

    const {domainName} = useDomainConfig();
    const {shortLocaleName} = useLocale();

    const imageSrc = useMemo(() => {
        if (domainName === DomainNameEnum.brandWizard && images.brandWizardImage) {
            return images.brandWizardImage;
        }

        if (shortLocaleName === ShortLocaleNameEnum.ru || shortLocaleName === ShortLocaleNameEnum.kk) {
            return images.ru;
        }

        return images.en;
    }, [domainName, images.brandWizardImage, images.en, images.ru, shortLocaleName]);

    return (
        <LandingOnboardingStep
            arrowButtonProps={{
                onClick: onStepFinish,
            }}
        >
            <Text block className={styles.LandingOnboardingStep_title} stringKey={titleLangKeyType} />
            <Text block lighter stringKey={descriptionLangKeyType} />
            <img
                alt=""
                className={classNames(styles.LandingOnboardingStep_presentationStepImage, imageClassName)}
                src={imageSrc}
            />
        </LandingOnboardingStep>
    );
}
