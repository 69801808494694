import {useCatalog} from '../../../../../provider/catalogs/catalogs-hook';
import {useFacebookAccounts} from '../../../../../service/accounts/accounts-hook';
import {ProvidersIdsEnum} from '../../../../../util/type';
import {AccountSelector} from '../account-selector/account-selector';

type PropsType = {
    onSelectAccount: (accountId: number) => void;
};

export function FacebookAccountSelector(props: PropsType): JSX.Element {
    const {onSelectAccount} = props;

    const {
        result: accountsResult,
        reset: reloadAccounts,
        isInProgress: isAccountsInProgress,
        processError: accountsError,
        linkingSrc,
    } = useFacebookAccounts();

    const {catalog: catalogInfo, isLoading: isCatalogDataInProgress} = useCatalog(ProvidersIdsEnum.facebook);

    return (
        <AccountSelector
            accounts={accountsResult}
            catalogInfo={catalogInfo}
            hasError={Boolean(accountsError)}
            isInProgress={isCatalogDataInProgress || isAccountsInProgress}
            linkingSrc={linkingSrc}
            onSelectAccount={onSelectAccount}
            reloadAccounts={reloadAccounts}
        />
    );
}
