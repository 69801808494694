import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {UsedeskType} from './usedesk-type';

export function fetchUsedesk(): Promise<UsedeskType> {
    return fetchX<UsedeskType>(rootApiUrl + '/v1/user/notification_settings/usedesk/');
}

export function updateUsedesk(usedesk: UsedeskType): Promise<UsedeskType> {
    return fetchX<UsedeskType>(rootApiUrl + '/v1/user/notification_settings/usedesk/', {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(usedesk),
    });
}
