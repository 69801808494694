import {UserType} from '../../user/user-type';

import {
    omnichannelCommunicationActionDataSchema,
    OmnichannelCommunicationActionDataType,
} from './omnichannel-actions-type';

export function isOmnichannelCommunicationActionDataType(
    data: unknown
): data is OmnichannelCommunicationActionDataType {
    return omnichannelCommunicationActionDataSchema.safeParse(data).success;
}

export function getCentrifugeOmnichannelSubscriptionName(user: UserType | null): string {
    return `omnichannel:chats#${user?.id}`;
}
