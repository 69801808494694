import {AnyZodObject, z as r} from 'zod';

import {ProvidersIdsEnum} from '../../util/type';
import {addressSchema} from '../address/address-type';

export const companiesSelectorIdResponseSchema = r.object({
    uid: r.string(),
});

export type CompaniesSelectorIdResponseType = r.infer<typeof companiesSelectorIdResponseSchema>;

export type SelectorApiCommonRequestType = {
    ids?: Array<number> | null;
    exclude_ids?: Array<number> | null;
    company_group_ids?: Array<number> | null;
    brand_ids?: Array<number> | null;
    city_ids?: Array<number> | null;
    selected_only?: boolean;
    q?: string | null;
    code?: string | null;
    filter_key?: string;
    with_source_settings_accounts?: boolean;
    ratings?: Array<{
        catalog_id: ProvidersIdsEnum;
        ratings: Array<number>;
        include_no_rating: boolean;
    }>;
};

export type CompaniesApiRequestType = SelectorApiCommonRequestType & {
    count: number;
    token: number;
};

export const companySelectorCompanySchema = r.object({
    id: r.number(),
    name: r.string(),
    address: addressSchema,
    selected: r.boolean(),
    code: r.string(),
});

export enum PostsSelectorCatalogStatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    NeedAccount = 'need_account',
}

export const postCompanySelectorCompanySchema = companySelectorCompanySchema.merge(
    r.object({
        catalogs: r.array(
            r.object({
                id: r.number(),
                name: r.string(),
                status: r.nativeEnum(PostsSelectorCatalogStatusEnum),
                website: r.string(),
            })
        ),
    })
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCompaniesSelectorCompaniesSchema(companiesSchema: AnyZodObject) {
    return r.object({
        companies: r.array(companiesSchema),
        nextPageToken: r.number().nullable(),
        count: r.number(),
    });
}

const companyResponseSchema = getCompaniesSelectorCompaniesSchema(companySelectorCompanySchema);

export type CompanySelectorCompaniesResponseType<CompanyType> = Omit<
    r.infer<typeof companyResponseSchema>,
    'companies'
> & {
    companies: Array<CompanyType>;
};

export type UpdateSelectorCompaniesRequestType = SelectorApiCommonRequestType;

export const updateSelectorCompaniesSchema = r.object({
    count: r.number(),
});

export type UpdateSelectorCompaniesType = r.infer<typeof updateSelectorCompaniesSchema>;
