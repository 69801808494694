import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {companiesImportUrl} from './companies-import-const';

const companiesImportRunSchema = r.object({
    taskId: r.string(),
});

type CompaniesImportRunType = r.infer<typeof companiesImportRunSchema>;

function getCompaniesImportRunUrl(id: number, periodicUpdate: boolean): string {
    const url = `${companiesImportUrl}/import_url/${id}/run_import/`;

    if (periodicUpdate) {
        return `${url}?${objectToUrlParameters(serializeToURLParameters({periodicUpdate: true}))}`;
    }

    return url;
}

function fetchCompaniesImportRun(id: number, periodicUpdate: boolean): Promise<CompaniesImportRunType> {
    return fetchAndDeserialize(getCompaniesImportRunUrl(id, periodicUpdate), companiesImportRunSchema);
}

export function useCompaniesImportRunMutation(id: number): UseMutationResult<CompaniesImportRunType, unknown, boolean> {
    return useMutation([getCompaniesImportRunUrl(id, false)], (periodicUpdate: boolean) =>
        fetchCompaniesImportRun(id, periodicUpdate)
    );
}
