import {ColumnGroupType} from 'antd/es/table';
import {ColumnsType} from 'antd/lib/table';
import {Key} from 'react';

import {FIXED_COLUMN_THRESHOLD} from '../../../../const';
import {LocaleWithCatalogName} from '../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {FormattedNumber} from '../../../../provider/locale/formatted-number';
import {Locale} from '../../../../provider/locale/locale';
import {LocationsAnalyticsTableDataType} from '../../../../service/online-presence/online-presence-type';
import {getEnumValue} from '../../../../util/enum';
import {PROVIDER_TO_ITS_ID_MAP, ProvidersEnum, ProvidersIdsEnum} from '../../../../util/type';

function getChildrenCells(catalog: ProvidersEnum): ColumnGroupType<LocationsAnalyticsTableDataType>['children'] {
    return [
        {
            key: 'impressions',
            dataIndex: 'impressions',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__IMPRESSION_DYNAMICS__IMPRESSIONS" />,
            align: 'center',
            width: 120,
            render(_value: unknown, row: LocationsAnalyticsTableDataType): JSX.Element {
                return <FormattedNumber value={row[catalog].impressions} />;
            },
        },
        {
            key: 'callClicks',
            dataIndex: 'callClicks',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__CALLS" />,
            align: 'center',
            width: 110,
            render(_value: unknown, row: LocationsAnalyticsTableDataType): JSX.Element {
                return <FormattedNumber value={row[catalog].callClicks} />;
            },
        },
        {
            key: 'websiteClicks',
            dataIndex: 'websiteClicks',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__SITE" />,
            align: 'center',
            width: 110,
            render(_value: unknown, row: LocationsAnalyticsTableDataType): JSX.Element {
                return <FormattedNumber value={row[catalog].websiteClicks} />;
            },
        },
        {
            key: 'directionRequests',
            dataIndex: 'directionRequests',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__ROUTES" />,
            align: 'center',
            width: 110,
            render(_value: unknown, row: LocationsAnalyticsTableDataType): JSX.Element {
                return <FormattedNumber value={row[catalog].directionRequests} />;
            },
        },
        {
            key: 'other',
            dataIndex: 'other',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__TARGET_ACTION_DYNAMICS__OTHER" />,
            align: 'center',
            width: 110,
            render(_value: unknown, row: LocationsAnalyticsTableDataType): JSX.Element {
                return <FormattedNumber value={row[catalog].other} />;
            },
        },
    ];
}

function checkIsProviders(columnKey: Key | ProvidersEnum): columnKey is ProvidersEnum {
    return Boolean(getEnumValue(ProvidersEnum, columnKey));
}

export function getAvailableColumns(
    columns: ColumnsType<LocationsAnalyticsTableDataType>,
    availableCatalogs: Set<ProvidersEnum>,
    regionAvailableCatalogs: Record<string, boolean> | undefined
): ColumnsType<LocationsAnalyticsTableDataType> {
    return columns.filter((column) =>
        column.key && checkIsProviders(column.key)
            ? availableCatalogs.has(column.key) && regionAvailableCatalogs?.[PROVIDER_TO_ITS_ID_MAP[column.key]]
            : true
    );
}

export function getLocationsTableColumns(screenWidth: number): ColumnsType<LocationsAnalyticsTableDataType> {
    return [
        {
            key: 'companyName',
            dataIndex: 'companyName',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__LOCATIONS__TABLE__NAME" />,
            width: 200,
            fixed: screenWidth > FIXED_COLUMN_THRESHOLD,
        },
        {
            key: 'companyAddress',
            dataIndex: 'companyAddress',
            title: <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__LOCATIONS__TABLE__ADDRESS" />,
            width: 200,
            fixed: screenWidth > FIXED_COLUMN_THRESHOLD,
        },
        {
            title: <Locale stringKey="CATEGORY_NAME__ONLINE_PRESENCE__YANDEX" />,
            key: ProvidersEnum.yandex,
            dataIndex: ProvidersEnum.yandex,
            children: getChildrenCells(ProvidersEnum.yandex),
        },
        {
            title: <Locale stringKey="CATEGORY_NAME__ONLINE_PRESENCE__GOOGLE" />,
            key: ProvidersEnum.google,
            dataIndex: ProvidersEnum.google,
            children: getChildrenCells(ProvidersEnum.google),
        },
        {
            title: <Locale stringKey="CATEGORY_NAME__ONLINE_PRESENCE__WGIS" />,
            key: ProvidersEnum.doubleGis,
            dataIndex: ProvidersEnum.doubleGis,
            children: getChildrenCells(ProvidersEnum.doubleGis),
        },
        {
            title: (
                <LocaleWithCatalogName
                    catalogId={ProvidersIdsEnum.facebook}
                    namePlaceholder="FB"
                    stringKey="CATEGORY_NAME__ONLINE_PRESENCE__FACEBOOK"
                />
            ),
            key: ProvidersEnum.facebook,
            dataIndex: ProvidersEnum.facebook,
            children: getChildrenCells(ProvidersEnum.facebook),
        },
    ];
}
