import {faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, Input, List, Modal, Tooltip} from 'antd';
import {partition, sortBy, upperFirst} from 'lodash';
import {useCallback, useMemo, useState} from 'react';

import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {LanguageType} from '../../service/language/language-type';
import {Form} from '../../typings/antd';
import {classNames} from '../../util/css';
import {includesDeburredString} from '../../util/string';

import {Flag} from './flag/flag';
import {getFlag} from './flag/flag-helper';
import {POPULAR_LANGUAGES} from './languages-popup-const';
import * as styles from './languages-popup.scss';

type PropsType = {
    languages: Array<LanguageType>;
    isOpen: boolean;
    onClose: () => void;
    onApply: (languagesAlpha2: Array<string>) => void;
};

export function LanguagesPopup(props: PropsType): JSX.Element {
    const {languages: rawLanguages, isOpen, onClose, onApply} = props;

    const {shortLocaleName, getLocalizedString} = useLocale();
    const [selectedLanguages, setSelectedLanguages] = useState<Array<string>>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const languages = useMemo(() => rawLanguages.filter(({alpha2}) => Boolean(getFlag(alpha2))), [rawLanguages]);

    const displayName = useMemo(
        () => (Intl.DisplayNames ? new Intl.DisplayNames(shortLocaleName, {type: 'language'}) : null),
        [shortLocaleName]
    );

    const [popularLanguages, restLanguages] = useMemo(() => {
        const [popular, rest] = partition(languages, ({alpha2}) => POPULAR_LANGUAGES.includes(alpha2));

        return [
            sortBy(popular, ({alpha2}) => POPULAR_LANGUAGES.indexOf(alpha2)),
            rest.sort((first, second) => {
                if (!displayName) {
                    return first.name.localeCompare(second.name);
                }

                const firstTranslatedName = displayName.of(first.alpha2);
                const secondTranslatedName = displayName.of(second.alpha2);

                return firstTranslatedName && secondTranslatedName
                    ? firstTranslatedName.localeCompare(secondTranslatedName)
                    : first.name.localeCompare(second.name);
            }),
        ];
    }, [displayName, languages]);

    function handleClose() {
        setSelectedLanguages([]);
        setSearchQuery('');
        onClose();
    }

    function handleApply() {
        onApply(selectedLanguages);
        setSelectedLanguages([]);
        setSearchQuery('');
    }

    const handleCheckboxChange = useCallback(
        (language: LanguageType, checked: boolean) => {
            setSelectedLanguages(
                checked
                    ? selectedLanguages.filter((selectedId) => selectedId !== language.alpha2)
                    : [...selectedLanguages, language.alpha2]
            );
        },
        [selectedLanguages]
    );

    const renderLanguage = useCallback(
        (language: LanguageType) => {
            const checked = selectedLanguages.includes(language.alpha2);
            const translatedName = displayName?.of(language.alpha2);

            return (
                <List.Item className={classNames(styles.catalog_popup__item)} key={language.alpha2}>
                    <Checkbox
                        checked={checked}
                        className={classNames(
                            styles.catalog_popup__checkbox,
                            checked ? styles.catalog_popup__checkbox_checked : ''
                        )}
                        onChange={() => handleCheckboxChange(language, checked)}
                    >
                        <Tooltip
                            className={styles.catalog_popup__checkbox_content}
                            placement="bottom"
                            title={language.name}
                        >
                            <Flag className={styles.catalog_popup__flag} isLanguageCode value={language.alpha2} />
                            {translatedName ? upperFirst(translatedName) : language.name}
                        </Tooltip>
                    </Checkbox>
                </List.Item>
            );
        },
        [displayName, handleCheckboxChange, selectedLanguages]
    );

    return (
        <Modal
            destroyOnClose // needed for autofocus
            footer={
                <div className={styles.catalog_popup__footer}>
                    <span className={styles.catalog_popup__counter}>
                        <Locale
                            stringKey="COMPANY_FORM__NAMES__LANGUAGES_POPUP__SELECTED"
                            valueMap={{count: selectedLanguages.length}}
                        />
                    </span>
                    <Button key="cancel" onClick={handleClose}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>
                    <Button key="apply" onClick={handleApply} type="primary">
                        <Locale stringKey="TEXT__APPLY" />
                    </Button>
                </div>
            }
            onCancel={handleClose}
            open={isOpen}
            title={<Locale stringKey="COMPANY_FORM__NAMES__LANGUAGES_POPUP__TITLE" />}
        >
            <Form layout="vertical">
                <Form.Item
                    className={styles.catalog_popup__input}
                    label={<Locale stringKey="COMPANY_FORM__NAMES__LANGUAGES_POPUP__SEARCH" />}
                >
                    <Input
                        autoFocus
                        onChange={(event) => setSearchQuery(event.target.value)}
                        placeholder={getLocalizedString('TEXT__START_TYPING')}
                        size="large"
                        suffix={<FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />}
                        value={searchQuery}
                    />
                </Form.Item>
            </Form>

            <div className={styles.catalog_popup__scroll_area}>
                {searchQuery ? (
                    <List
                        dataSource={languages.filter(
                            ({alpha2, name}) =>
                                includesDeburredString(name, searchQuery) ||
                                includesDeburredString(displayName?.of(alpha2) ?? '', searchQuery)
                        )}
                        renderItem={renderLanguage}
                    />
                ) : (
                    <>
                        {popularLanguages.length > 0 && (
                            <>
                                <div className={styles.catalog_popup__category}>
                                    <Locale stringKey="COMPANY_FORM__NAMES__LANGUAGES_POPUP__POPULAR" />
                                </div>
                                <List dataSource={popularLanguages} renderItem={renderLanguage} />
                                <div className={styles.catalog_popup__category}>
                                    <Locale stringKey="COMPANY_FORM__NAMES__LANGUAGES_POPUP__AZ" />
                                </div>
                            </>
                        )}
                        <List dataSource={restLanguages} renderItem={renderLanguage} />
                    </>
                )}
            </div>
        </Modal>
    );
}
