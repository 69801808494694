import {Row} from 'antd';

import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {useAvailableCatalogs} from '../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {ProvidersIdsEnum} from '../../../util/type';

import {AccountLinkingFacebookCard} from './account-linking-facebook/account-linking-facebook-card';
import {AccountLinkingGoogleCard} from './account-linking-google/account-linking-google-card';
import {AccountLinkingVkCard} from './account-linking-vk/account-linking-vk-card';
import * as styles from './accounts-linking.scss';

type PropsType = {
    headerNavigationButtons?: JSX.Element;
    breadCrumbs?: JSX.Element;
};

export function AccountsLinkingCommon(props: PropsType): JSX.Element {
    const {headerNavigationButtons, breadCrumbs} = props;

    const {isInProgress: isAvailableCatalogsInProgress, isCatalogAvailable} = useAvailableCatalogs();

    const {getLocalizedString} = useLocale();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__ACCOUNTS_LINKING')} />

                {breadCrumbs || <BreadCrumbs list={[{path: '', titleLangKey: 'CATEGORY_NAME__ACCOUNTS_LINKING'}]} />}

                <PageHeader>
                    <Locale stringKey="POSTS__ACCOUNTS__HEADER" />
                    <Row className={styles.accounts_linking__header__buttons_wrapper}>{headerNavigationButtons}</Row>
                </PageHeader>

                <span className={styles.account_linking__subheader}>
                    <Locale stringKey="ACCOUNTS__SUBHEADER" />
                </span>
            </PageCard>

            <>
                <Spinner isShow={isAvailableCatalogsInProgress} position="absolute" />
                {isCatalogAvailable(ProvidersIdsEnum.google) && <AccountLinkingGoogleCard />}
                {isCatalogAvailable(ProvidersIdsEnum.facebook) && <AccountLinkingFacebookCard />}
                {isCatalogAvailable(ProvidersIdsEnum.vkontakte) && <AccountLinkingVkCard />}
            </>
        </Page>
    );
}
