import {ModalFuncProps} from 'antd';

import {PageHeaderSub} from '../../../../layout/page-header/page-header-sub';
import {Locale} from '../../../../provider/locale/locale';

import {CONFIRM_MODAL_WIDTH} from './confirm-fields-modal-const';
import * as styles from './confirm-fields-modal.scss';

type PropsType = {
    onOk: () => void;
};

export function getConfirmCancelModalConfig(props: PropsType): ModalFuncProps {
    const {onOk} = props;

    return {
        width: CONFIRM_MODAL_WIDTH,
        title: <Locale stringKey="BULK_EDIT_COMPANIES__CONFIRM_CANCEL_MODAL__TITLE" />,
        cancelText: <Locale stringKey="TEXT__NO" />,
        okText: <Locale stringKey="TEXT__YES" />,
        onOk,
        okButtonProps: {type: 'default', danger: true},
        cancelButtonProps: {type: 'default'},
        content: (
            <PageHeaderSub className={styles.fields_list__info_sub_header}>
                <Locale stringKey="BULK_EDIT_COMPANIES__CONFIRM_CANCEL_MODAL__BODY" />
            </PageHeaderSub>
        ),
    };
}
