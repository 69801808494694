export const enAutoRepliesTranslations = {
    AUTO_REPLIES__PAGE_HEADER: 'Auto-replies to reviews',
    AUTO_REPLIES__ADD_RULE: 'Add Rule',
    AUTO_REPLIES__TOTAL_AUTO_REPLIES_LEFT: 'Total auto responses left: {autoRepliesLeft}',
    AUTO_REPLIES__LIST_OF_RULES: 'List of rules',
    AUTO_REPLIES__TABLE__TITLE: 'Title',
    AUTO_REPLIES__TABLE__TYPE: 'Type',
    AUTO_REPLIES__TABLE__CONDITION: 'Condition',
    AUTO_REPLIES__REPLY_TYPE__TEMPORARY: 'Temporary',
    AUTO_REPLIES__REPLY_TYPE__PERMANENT: 'Permanent',
    AUTO_REPLIES__TABLE__ACTIONS: 'Actions',
    AUTO_REPLIES__TABLE__VALIDITY: 'Validity',

    AUTO_REPLIES__REMOVING_A_RULE: 'Rule removing',
    AUTO_REPLIES__THE_RULE_RULE_NAME_WILL_BE_REMOVED_CONFIRM: 'The rule(s) "{ruleNames}" will be removed. Confirm?',
    AUTO_REPLIES__HEADER__RULE_CREATION: 'Rule creating',
    AUTO_REPLIES__HEADER__EDITING_A_RULE: 'Rule editing',
    AUTO_REPLIES__HEADER__SUBHEADER__INFO: 'You can read more about setting up auto-replies in our {articleLink}.',
    AUTO_REPLIES__HEADER__SUBHEADER__INFO_SITE: 'article',

    AUTO_REPLIES__NOTIFICATION__ERROR_CREATE: 'Auto response creating error',
    AUTO_REPLIES__NOTIFICATION__SUCCESS_CREATE: 'Auto reply created successfully',
    AUTO_REPLIES__NOTIFICATION__ERROR_UPDATE: 'Auto reply update error',
    AUTO_REPLIES__NOTIFICATION__SUCCESS_UPDATE: 'Auto reply updated successfully',
    AUTO_REPLIES__NOTIFICATION__ERROR_DELETE: 'Auto-response deleting error',
    AUTO_REPLIES__NOTIFICATION__SUCCESS_DELETE: 'Auto reply deleted successfully',

    AUTO_REPLIES__FORM__DELETE_RULE: 'Delete rule',
    AUTO_REPLIES__FORM__CONTROL: 'Control',
    AUTO_REPLIES__FORM__ENABLE_THE_RULE: 'Enable the rule',
    AUTO_REPLIES__FORM__TEST_MODE: 'Test mode',
    AUTO_REPLIES__FORM__TEST_MODE__POPOVER_TEXT:
        'The reviews page will display auto-reply suggestions. This way you can make sure the settings are correct.',
    AUTO_REPLIES__FORM__BASIC_INFORMATION: 'Basic information',
    AUTO_REPLIES__FORM__TITLE: 'Title',
    AUTO_REPLIES__FORM__ENTER_A_NAME_FOR_THE_RULE: 'Enter a name for the rule',
    AUTO_REPLIES__FORM__DESCRIPTION: 'Description',
    AUTO_REPLIES__FORM__ENTER_A_DESCRIPTION: 'Enter a description',

    AUTO_REPLIES__FORM__FILTERS: 'Filters',
    AUTO_REPLIES__FORM__COMPANIES: 'Companies',
    AUTO_REPLIES__FORM__FEEDBACK_SOURCE: 'Feedback source',
    AUTO_REPLIES__FORM__SELECTED: 'Selected: {count}',
    AUTO_REPLIES__FORM__SELECT_SOURCES: 'Select sources',
    AUTO_REPLIES__FORM__RATING: 'Rating',
    AUTO_REPLIES__FORM__CONTENT_OF_REVIEWS: 'Content of reviews',
    AUTO_REPLIES__FORM__ALL_REVIEWS: 'All reviews',
    AUTO_REPLIES__FORM__REVIEWS_WITH_TEXT: 'Reviews with text',
    AUTO_REPLIES__FORM__REVIEWS_WITHOUT_TEXT: 'Reviews without text',
    AUTO_REPLIES__FORM__TIME_TO_RECEIVE_FEEDBACK: 'Time to receive feedback',
    AUTO_REPLIES__FORM__TIMEZONE: 'Timezone',
    AUTO_REPLIES__FORM__RESPONSE_TEMPLATES: 'Response templates',
    AUTO_REPLIES__FORM__SELECT_TEMPLATES: 'Select templates',
    AUTO_REPLIES__FORM__THE_ORDER_OF_CHOOSING_A_TEMPLATE: 'The order of choosing a template',
    AUTO_REPLIES__FORM__RANDOM: 'Random',
    AUTO_REPLIES__FORM__CONSISTENTLY: 'Consistently',
    AUTO_REPLIES__FORM__SCHEDULE: 'Schedule',
    AUTO_REPLIES__FORM__TEMPORARY: 'Temporary',
    AUTO_REPLIES__FORM__INFINITE_RANGE: 'Infinite validity',
    AUTO_REPLIES__FORM__DAYS_OF_THE_WEEK: 'Days of the week',
    AUTO_REPLIES__FORM__SELECT_THE_DAYS_OF_THE_WEEK: 'Select days of the week',
    AUTO_REPLIES__FORM__DATE_PERIOD: 'Date period',
    AUTO_REPLIES__FORM__FOR_DATES: 'For dates',
    AUTO_REPLIES__FORM__ERROR: 'Please fill in all required fields',

    AUTO_REPLIES__POPUP__RESPONSE_TEMPLATES: 'Response templates',
    AUTO_REPLIES__POPUP__SEARCH_FOR_TEMPLATES: 'Search for templates',
    AUTO_REPLIES__POPUP__ALL_GROUPS: 'All groups',
    AUTO_REPLIES__POPUP__UPDATE_TEMPLATES: 'Update templates list',
    AUTO_REPLIES__POPUP__GO_TO_TEMPLATE_SETTINGS: 'Template settings',
    AUTO_REPLIES__POPUP__PRIVATE_TEMPLATE_TOOLTIP__TITLE: 'Private Template',
    AUTO_REPLIES__POPUP__PRIVATE_TEMPLATE_TOOLTIP__TEXT:
        'The private template cannot be used in auto-response rules. Create a new one or change access to the command one in {articleLink}.',
    AUTO_REPLIES__POPUP__PRIVATE_TEMPLATE_TOOLTIP__TEXT_LINK: 'Template settings',
};
