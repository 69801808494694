import {Context, createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';

import {useUserLanguageMutation} from '../../service/user/user-language';
import {fetchUserOwn} from '../../service/user/user-own';
import {UserType} from '../../service/user/user-type';
import {useUser as useUserManagement} from '../../service/user-management/user-management-user';
import {getCookie} from '../../util/cookie';
import {catchError} from '../../util/promise';
import {useHelpLink} from '../help-links/help-links-hook';
import {localeNameToShortLocaleNameMap, shortLocaleNameToLocaleNameMap} from '../locale/locale-context-const';
import {getIsAuthLanguageFromSessionStorage, getLanguageFromUrl} from '../locale/locale-context-helper';
import {LocaleNameEnum, ShortLocaleNameEnum} from '../locale/locale-context-type';
import {useLocale} from '../locale/locale-hook';

import {
    defaultUserContextData,
    DEMO_CABINET_URL_KEY,
    REPUTATION_MANAGER_ID,
    technicalSupportTestUserIds,
} from './user-context-helper';
import {UserContextType} from './user-context-type';
import {useUsetiful} from './usetiful-hook';

export const UserContext: Context<UserContextType> = createContext<UserContextType>(defaultUserContextData);

type PropsType = PropsWithChildren<Record<string, unknown>>;

// eslint-disable-next-line max-statements
export function UserProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const [isInGettingUser, setIsInGettingUser] = useState<boolean>(true); // true by default to show spinner

    const [gettingUserError, setGettingUserError] = useState<Error | null>(null);
    const [user, setUser] = useState<UserType | null>(null);
    const [selectAfterFetching, setSelectAfterFetching] = useState<ShortLocaleNameEnum | null>(null);

    const {mutate: updateUserLanguage} = useUserLanguageMutation();
    const {isFetchingLocaleData, setLocaleName, shortLocaleName} = useLocale();

    const demoCabinetHelpLink = useHelpLink(DEMO_CABINET_URL_KEY);
    const {data: userManagement, refetch} = useUserManagement();

    const {setRole} = useUsetiful();

    const isLanguageFromUrl = getLanguageFromUrl();
    const isLanguageSessionStorage = getIsAuthLanguageFromSessionStorage();
    const isReputationManager = userManagement?.role?.pk === REPUTATION_MANAGER_ID;
    const isTechnicalSupportUser = Boolean(user && technicalSupportTestUserIds.has(user.id));

    const memoizedGettingUser = useCallback(
        function tryToGetUser(): Promise<UserType | Error> {
            setIsInGettingUser(true);
            setGettingUserError(null);

            return fetchUserOwn()
                .then((result: UserType | Error): UserType | Error => {
                    if (result instanceof Error) {
                        setGettingUserError(result);
                    } else {
                        setUser(result);
                        setRole(result);
                        refetch();

                        // Usually we prefer to use backend language for consistency between browsers/devices, but
                        // if user changes language on login page (or has 'lang' query param) then it has more priority
                        if (result.language && !isLanguageFromUrl && !isLanguageSessionStorage) {
                            setSelectAfterFetching(result.language);
                        }
                    }

                    setIsInGettingUser(false);

                    // console.log('---> get user', result);
                    // console.log(JSON.stringify(result, null, 4));

                    return result;
                })
                .catch(catchError);
        },
        [isLanguageSessionStorage, isLanguageFromUrl, setRole]
    );

    const refetchUser = useCallback(function tryToRefetchUser(): Promise<UserType | Error> {
        return fetchUserOwn()
            .then((result: UserType | Error): UserType | Error => {
                if (result instanceof Error) {
                    setGettingUserError(result);
                } else {
                    setUser(result);
                    refetch();
                }

                return result;
            })
            .catch(catchError);
    }, []);

    const resetUser = useCallback(() => {
        setUser(null);
        setIsInGettingUser(false);
    }, []);

    const setLocaleNameByUser = useCallback(
        (locale: LocaleNameEnum) => {
            if (user?.isDemoUser && demoCabinetHelpLink?.documentationLink) {
                window.location.assign(
                    `${demoCabinetHelpLink?.documentationLink}/${localeNameToShortLocaleNameMap[locale]}`
                );
            } else {
                setLocaleName(locale);
            }
        },
        [user?.isDemoUser, demoCabinetHelpLink, setLocaleName]
    );

    useEffect(() => {
        const token = getCookie('csrftoken');
        const sessionId = getCookie('sessionid');

        // if we have token and session id - user is authorized and so we do a request, otherwise we end loading
        if (token && sessionId) {
            memoizedGettingUser();
        } else {
            setIsInGettingUser(false);
        }
    }, [memoizedGettingUser]);

    useEffect(() => {
        if (!isFetchingLocaleData && selectAfterFetching) {
            if (shortLocaleName !== selectAfterFetching) {
                setLocaleName(shortLocaleNameToLocaleNameMap[selectAfterFetching]);
            }

            setSelectAfterFetching(null);
        }
    }, [isFetchingLocaleData, selectAfterFetching, setLocaleName, shortLocaleName]);

    useEffect(() => {
        if (user && user.language !== shortLocaleName) {
            updateUserLanguage(shortLocaleName);
            setUser({...user, language: shortLocaleName});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortLocaleName, user]);

    const providerData: UserContextType = useMemo<UserContextType>((): UserContextType => {
        return {
            getUser: memoizedGettingUser,
            refetchUser,
            resetUser,
            setLocaleNameByUser,
            user,
            isInGettingUser,
            gettingUserError,
            isReputationManager,
            isTechnicalSupportUser,
        };
    }, [
        memoizedGettingUser,
        refetchUser,
        resetUser,
        setLocaleNameByUser,
        user,
        isInGettingUser,
        gettingUserError,
        isReputationManager,
        isTechnicalSupportUser,
    ]);

    return <UserContext.Provider value={providerData}>{children}</UserContext.Provider>;
}
