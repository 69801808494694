import {faEdit, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space, Tooltip} from 'antd';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {NavigationLink} from '../../../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../../../provider/locale/locale';
import {UserStatusEnum} from '../../../../../../../service/user-management/user-management-type';
import {useDeleteUserMutation, useUser} from '../../../../../../../service/user-management/user-management-user';
import {UsersType} from '../../../../../../../service/user-management/user-management-users';

import {USER_ADMIN_IDS} from './actions-column-const';
import * as styles from './actions-column.scss';

type PropsType = Pick<UsersType['results'][number], 'pk' | 'email' | 'status' | 'creator'> & {
    onDelete: () => void;
};

export function ActionsColumn(props: PropsType): JSX.Element | null {
    const {pk, email, status, onDelete, creator} = props;

    const {data: ownUser} = useUser();
    const {mutate} = useDeleteUserMutation({
        ids: [pk],
        email,
        onSuccess: onDelete,
    });

    if (status === UserStatusEnum.Blocked) {
        return null;
    }

    if (ownUser?.email !== creator && ownUser?.role.pk && USER_ADMIN_IDS.includes(ownUser?.role.pk)) {
        return (
            <Tooltip placement="topRight" title={<Locale stringKey="USERS__TABLE__DISABLE_ACTION__TOOLTIP" />}>
                <Space className={styles.ActionsColumn}>
                    <Button disabled icon={<FontAwesomeIcon icon={faEdit} />} type="text" />
                    <Button disabled icon={<FontAwesomeIcon icon={faTrash} />} type="text" />
                </Space>
            </Tooltip>
        );
    }

    return (
        <Space>
            <NavigationLink to={generatePath(appRoute.editUser.path, {userId: pk})}>
                <Button icon={<FontAwesomeIcon icon={faEdit} />} type="text" />
            </NavigationLink>

            <Button icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => mutate(pk)} type="text" />
        </Space>
    );
}
