export const enReviewsTranslations = {
    REVIEWS__DOWNLOAD_THE_REPORT: 'Download the report',
    REVIEWS__RADIO__TOTAL_REVIEWS: 'Total reviews',
    REVIEWS__RADIO__POSITIVE: 'Positive',
    REVIEWS__RADIO__NEGATIVE: 'Negative',
    REVIEWS__RADIO__NOT_RATED: 'Not rated',
    REVIEWS__RADIO__AVERAGE_RATING: 'Average rating',
    REVIEWS__OLD: 'Old first',
    REVIEWS__NEW: 'New first',

    REVIEWS__FILTER__RESET: 'Reset filter',
    REVIEWS__FILTER__SELECT_A_BRAND: 'Select a brand',
    REVIEWS__FILTER__SELECT_A_SOURCE: 'Select a source',
    REVIEWS__FILTER__SELECT_A_STATUS: 'Select a status',
    REVIEWS__FILTER__ANY_RATING: 'Any rating',
    REVIEWS__FILTER__DOCTOR_NAME: 'Select doctors',
    REVIEWS__FILTER__ANY_EMPLOYEE: 'Any employee',
    REVIEWS__FILTER__SELECT_AN_EMPLOYEE: 'Select employee(s)',
    REVIEWS__FILTER__ANY_DOCTOR: 'All doctors',
    REVIEWS__FILTER__SELECT_TAGS: 'Select tags',
    REVIEWS__FILTER__AUTO_REPLIES: 'Auto-replies',
    REVIEWS__FILTER__AUTOMATICALLY_REPLIED: 'Automatically replied',
    REVIEWS__FILTER__SUGGESTIONS_FOR_AUTO_REPLIES: 'Suggestions for auto responses',
    REVIEWS__FILTER__NEURAL_NETWORK_SUGGESTIONS_FOR_AUTO_REPLIES: 'Neural Network Suggestions',
    REVIEWS__FILTER__REVIEWS_WITHOUT_TEXT: 'Without text',
    REVIEWS__FILTER__REVIEWS_WITH_TEXT: 'With text',
    REVIEWS__FILTER__REVIEWS_WITH_A_RESPONSE: 'With a response',
    REVIEWS__FILTER__REVIEWS_UNANSWERED: 'Unanswered',
    REVIEWS__FILTER__THERE_IS_A_POSSIBILITY_OF_AN_ANSWER: 'Yes',
    REVIEWS__FILTER__THERE_IS_NO_POSSIBILITY_OF_AN_ANSWER: 'No',
    REVIEWS__FILTER__REVIEWS_FOR_THE_BRAND: 'Brand',
    REVIEWS__FILTER__REVIEWS_FOR_COMPANIES: 'Companies',
    REVIEWS__FILTER__WITH_TAGS: 'With tags',
    REVIEWS__FILTER__NO_TAGS: 'No tags',
    REVIEWS__FILTER__LABEL__BRAND: 'Brand',
    REVIEWS__FILTER__LABEL__SOURCE: 'Source',
    REVIEWS__FILTER__ALL__SOURCES: 'All sources',
    REVIEWS__FILTER__LABEL__RATING: 'Rating',
    REVIEWS__FILTER__LABEL__PERIOD: 'Period',
    REVIEWS__FILTER__LABEL__RESPONSIBLE: 'Responsible employee',
    REVIEWS__FILTER__LABEL__EMPLOYEE_RESPONDING: 'Responding employee',
    REVIEWS__FILTER__LABEL__STATUS: 'Status',
    REVIEWS__FILTER__LABEL__TAGS: 'Tags',
    REVIEWS__FILTER__LABEL__INCLUDES_TAGS: 'Tags included',
    REVIEWS__FILTER__LABEL__AUTO_REPLIES: 'Auto replies',
    REVIEWS__FILTER__LABEL__CONTENT_OF_REVIEWS: 'Content of reviews',
    REVIEWS__FILTER__LABEL__DELETED_REVIEWS: 'Deleted reviews',
    REVIEWS__FILTER__LABEL__REPLIES_TO_REVIEWS: 'Replies to reviews',
    REVIEWS__FILTER__LABEL__POSSIBILITY_OF_AN_ANSWER: 'Possibility of an answer',
    REVIEWS__FILTER__LABEL__REVIEW_TYPE: 'Review type',
    REVIEWS__FILTER__LABEL__THE_COMPLAINT_WAS_LEFT: 'The complained was left',
    REVIEWS__FILTER__LABEL__REVIEW_CHANGED: 'Review changed',
    REVIEWS__FILTER__LABEL__WAITING_FOR_A_SECOND_ANSWER: 'Waiting for a second answer',
    REVIEWS__FILTER__LABEL__MODERATED: 'Review confirmed',
    REVIEWS__FILTER__LABEL__DOCTOR_NAME: 'Doctors',

    REVIEWS__REPLY: 'Reply',
    REVIEWS__ADD_REPLY: 'Add a reply',
    REVIEWS__SAVE: 'Save',
    REVIEWS__ENTER_YOUR_REPLY_TEXT: 'Enter your reply text...',
    REVIEWS__AUTO_REPLY: 'Auto reply',
    REVIEWS__DIALOG: 'Dialog',
    REVIEWS__OFFERING_AUTO_REPLY: 'Offering an autoreply template',
    REVIEWS__OFFERING_AUTO_REPLY_SEND: 'Send an autoreply',
    REVIEWS__DELETE_ANSWER__TITLE: 'Deleting a response',
    REVIEWS__DELETE_ANSWER__DESCRIPTION:
        'Do you really want to delete the answer? The answer will also be deleted in the company cards.',
    REVIEWS__DELETE_AUTOANSWER__TITLE: 'Delete an autoresponder?',
    REVIEWS__DELETE_AUTOANSWER__DESCRIPTION: 'The auto-reply template suggestion will be deleted.',
    REVIEWS__RESPONSE_TIME: 'Reply time in the app: {time}',
    REVIEWS__RESPONSE_TIME__DURING_MINUTE: 'Responded within a minute',
    REVIEWS__RESPONSE_TIME_CATALOG: 'Reply time on site: {time}',
    REVIEWS__RESPONSE_EMPLOYEE: 'Employee: {name}',
    REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__HEADER: 'The complaint has been sent {date}',
    REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__TEXT:
        'The processing time for a complaint takes up to a week. If it is deemed reasonable, the review will no longer be displayed on the site.',
    REVIEWS__REVIEW__COMPANY_CODE__HINT: 'Company Code',
    REVIEWS__REVIEW_DELETED: 'Review deleted',
    REVIEWS__REVIEW_UNAVAILABLE_ANSWER: 'Answer unavailable',
    REVIEWS__REVIEW_UNAVAILABLE_ANSWER__LINK_TEXT: 'To answer, ',
    REVIEWS__REVIEW_UNAVAILABLE_ANSWER__LINK: 'connect your account',
    REVIEWS__REVIEW_UNAVAILABLE_ANSWER__TOOLTIP_TITLE: 'Source account not connected',
    REVIEWS__REVIEW_UNAVAILABLE_ANSWER__TOOLTIP_TEXT:
        'Connect the source account to send responses and complaints via {companyName}.',
    REVIEWS__REVIEW_UNAVAILABLE_COMPLAINT__POPUP_TITLE: 'Connect your account to report reviews',
    REVIEWS__REVIEW_UNAVAILABLE_COMPLAINT__POPUP_TEXT:
        'For this source, sending a complaint via RocketData is possible after connecting the source account.',
    REVIEWS__REVIEW_NOT_CONFIRMED: 'The review has not been confirmed',
    REVIEWS__REVIEW_NOT_CONFIRMED__DESCRIPTION:
        'The review is not confirmed by the catalog and is not displayed in it.',
    REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT: 'Correct the error for the response ',
    REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__LINK: 'account connections',
    REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__POPOVER_TITLE: 'Account Connection error',
    REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__POPOVER_TEXT:
        'Sending responses and complaints via {companyName} is possible after the connection error has been resolved.',
    REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__POPOVER_TEXT__LINK: 'Go to fix',
    REVIEWS__REVIEW__NO_TEXT: 'Review without text',
    REVIEWS__REVIEW__ATTACHMENTS__SINGLE: '{count} attachment',
    REVIEWS__REVIEW__ATTACHMENTS__FEW: '{count} attachments',
    REVIEWS__REVIEW__ATTACHMENTS__MANY: '{count} attachments',
    REVIEWS__REVIEW__ADD_TAG: 'Tag',
    REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__TITLE: 'The response has been sent',
    REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__DESCRIPTION: 'The response to the review has been sent successfully.',
    REVIEWS__REVIEW_ANSWER_SEND_FAIL__TITLE: 'Failed to send response',
    REVIEWS__REVIEW_ANSWER_SEND_FAIL__DESCRIPTION: 'Please try again later, or contact tech support.',
    REVIEWS__REVIEW_ANSWER_CHANGE_TAG_FAIL: 'Failed to change tags',
    REVIEWS__REVIEW_ANSWER_CHANGE_TAG_SUCCESS: 'Tags successfully changed',
    REVIEWS__REVIEW_ANSWER_REMOVE_SUCCESS__TITLE: 'Response deleted',
    REVIEWS__REVIEW_ANSWER_REMOVE_SUCCESS__DESCRIPTION: 'The response was successfully deleted',
    REVIEWS__REVIEW_ANSWER_REMOVE_FAIL__TITLE: 'Failed to delete response',
    REVIEWS__REVIEW_ANSWER_REMOVE_FAIL__DESCRIPTION: 'Please try again later, or contact tech support.',
    REVIEWS__REVIEW_AUTOANSWER_REMOVE_SUCCESS__TITLE: 'Auto-reply deleted',
    REVIEWS__REVIEW_AUTOANSWER_REMOVE_SUCCESS__DESCRIPTION:
        'The auto-reply template suggestion has been successfully deleted.',
    REVIEWS__REVIEW_AUTOANSWER_REMOVE_FAIL__TITLE: 'Error',
    REVIEWS__REVIEW_AUTOANSWER_REMOVE_FAIL__DESCRIPTION:
        'Could not delete the autoresponder. Try again later or contact tech support.',
    REVIEWS__REVIEW_COPY__HINT: 'Copy the review text',
    REVIEWS__REVIEW_COPY__SUCCESS: 'Review Text copied',
    REVIEWS__REVIEW_COPY__BROWSER_ERROR:
        'The review text could not be copied. Try updating your browser to the latest version',
    REVIEWS__REVIEW_URL_COPY__HINT: 'Copy link to review',
    REVIEWS__REVIEW_URL_COPY__SUCCESS: 'Review link copied',
    REVIEWS__REVIEW_DELETE__HINT: 'Delete a review',
    REVIEWS__REVIEW_EDIT__HINT: 'Edit a review',
    REVIEWS__REVIEW_ABUSE__HINT: 'Report a review',
    REVIEWS__REVIEW_ABUSE__ERROR_CONNECT_ACCOUNT__POPOVER__TITLE: 'Account Connection error',
    REVIEWS__REVIEW_ABUSE__ERROR_CONNECT_ACCOUNT__POPOVER__TEXT:
        'An error occurred connecting the source account. Sending a complaint via {companyName} is possible after the connection error has been resolved.',
    REVIEWS__REVIEW_ABUSE__ERROR_CONNECT_ACCOUNT__POPOVER__BUTTON: 'Fix',
    REVIEWS__REVIEW_CHANGE_HISTORY__HINT: 'Review has been changed',
    REVIEWS__REVIEW_CHANGE_HISTORY: 'Review change history',
    REVIEWS__REVIEW_CHANGE_HISTORY__BEFORE: 'Before',
    REVIEWS__REVIEW_CHANGE_HISTORY__AFTER: 'After',
    REVIEWS__REVIEW_CHANGE_HISTORY__RATING: 'Rating',
    REVIEWS__COMPLAINT_POPUP__SUBMITTING_A_COMPLAINT: 'Submitting a complaint',
    REVIEWS__COMPLAINT_POPUP__ENTER_YOUR_COMPLAINT_HERE: 'Enter your complaint here',
    REVIEWS__COMPLAINT__COMPLAINT_HAS_BEEN_SENT: 'The complaint has been sent',
    REVIEWS__COMPLAINT__COMPLAINT_SENT_ERROR: 'Failed to send a complaint',
    REVIEWS__COMPLAINT__COMPLAINT_HAS_BEEN_SENT__DESCRIPTION:
        'The complaint about the review was sent successfully. The processing time for a complaint takes up to a week. If it is deemed reasonable, the review will no longer be displayed on the site.',
    REVIEWS__DOWNLOAD_REPORT_POPUP__HEADER: 'Download report',
    REVIEWS__DOWNLOAD_REPORT_POPUP__INFO: 'When uploading reviews, the selected filters will be applied',
    REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEWS__EMPTY:
        'The review list is empty. Wait for filters to be collected or changed.',
    REVIEWS__DOWNLOAD_REPORT_POPUP__BUTTON__REQUEST: 'Request',
    REVIEWS__REVIEWS_PARSED_AT: 'Review collected in dashboard: {date}',
    REVIEWS__REVIEWS_IN_CATALOG: 'Review in source: {date}',
    REVIEWS__REVIEW_ANSWER__TOOLTIP_TITLE__SENT: 'Response sent and awaiting publication',
    REVIEWS__REVIEW_ANSWER__TOOLTIP_TITLE__PUBLISH: 'Response published',
    REVIEWS__REVIEW_ANSWER__AUTHOR__COMPANY: 'Company response',
    REVIEWS__REVIEW_ANSWER__AUTHOR__USER: 'User response',
    REVIEWS__REVIEW_ANSWER__TEMPLATE__HINT: 'Response templates',
    REVIEWS__REVIEW_ANSWER_CREATED_AT: 'Date of creation: {date}',
    REVIEWS__RESPONSIBLE_USER__NOT_SELECTED: 'Not assigned',
    REVIEWS__RESPONSIBLE_USER__PLACEHOLDER: 'Responsible',
    REVIEWS__ENTER_NOTE_TITLE: 'Note',
    REVIEWS__ENTER_NOTE_EDITING: 'Editing',
    REVIEWS__ENTER_NOTE_EDITED: 'Edited',
    REVIEWS__ENTER_YOUR_NOTE_TEXT: 'Enter your note text...',
    REVIEWS__REVIEW_NOTE_SEND_SUCCESS_TITLE: 'Note created',
    REVIEWS__REVIEW_NOTE_SEND_SUCCESS_DESCRIPTION: 'Review note successfully created.',
    REVIEWS__REVIEW_NOTE_SEND_FAIL: 'Failed to create note',
    REVIEWS__REVIEW_NOTE_CHANGE_SUCCESS_TITLE: 'Note changed',
    REVIEWS__REVIEW_NOTE_CHANGE_SUCCESS_DESCRIPTION: 'The note has been successfully modified.',
    REVIEWS__REVIEW_NOTE_CHANGE_FAIL: 'Failed to edit note',
    REVIEWS__REVIEW_NOTE_DELETE_SUCCESS_TITLE: 'Note deleted',
    REVIEWS__REVIEW_NOTE_DELETE_SUCCESS_DESCRIPTION: 'The note has been successfully deleted.',
    REVIEWS__REVIEW_NOTE_DELETE_FAIL: 'Failed to delete note',
    REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_TITLE: 'Deleting a note',
    REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_QUESTION: 'Are you sure you want to delete the note?',
    REVIEWS__REVIEW_NOTE_DELETE_CONFIRM_BUTTON: 'Delete',
    REVIEWS__REVIEW_NOTE_CREATED_BY: 'User {user} created note',
    REVIEWS__REVIEW_NOTE_UPDATED_BY: 'User {user} changed note',
    REVIEWS__REVIEW_NOTE_DELETED_BY: 'Note deleted by user {user}',
    REVIEWS__REVIEW_LOG__NEW_RESPONSIBLE_USER: 'appointed a responsible person',
    REVIEWS__REVIEW_ABUSE_CREATED_BY: '{user} created a complaint about the review',
    REVIEWS__REVIEW_ABUSE_UPDATED_BY: '{user} changed the review complaint',
    REVIEWS__REVIEW_ABUSE_DELETED_BY: '{user} deleted the review complaint',
    REVIEWS__REVIEW_ABUSE__SHOW_TEXT: 'Expand the text',
    REVIEWS__REVIEW_ABUSE__HIDE_TEXT: 'Collapse the text',
    REVIEWS__REVIEW_STATUS__CHANGE: '{user} changed the status',

    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__HEADER__NEW: 'Full upload of reviews (new version)',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_TITLE__NEW: 'new version',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_CONTENT__NEW:
        'An upload that can be constantly updated',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__HEADER__OLD: 'Full upload of reviews (old version)',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_TITLE__OLD: 'Old version',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_CONTENT__OLD:
        'An upload that is no longer updated, it remains in the same state',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TEXT: 'Reviews and answers',
    REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__PLACEHOLDER: 'Full unloading of reviews',
    REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__HEADER: 'Review statistics by brand and city',
    REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__TEXT:
        'Changes in review ratings for the selected period',
    REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__PLACEHOLDER:
        'Review statistics by brand and city',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RESPONSE_STATISTICS_BY_BRAND_AND_CITY__HEADER:
        'Response statistics by brand and city',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RESPONSE_STATISTICS_BY_BRAND_AND_CITY__TEXT:
        'Number of unanswered reviews and average response rate',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RESPONSE_STATISTICS_BY_BRAND_AND_CITY__PLACEHOLDER:
        'Response statistics by brand and city',
    REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__FEEDBACK_REPORT: 'Feedback report',
    REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__TAG_REPORT: 'Tag report',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_BY_SOURCE__HEADER: 'Rating statistics by source',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_BY_SOURCE__TEXT:
        'Rating of reviews in sources for the selected period',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_BY_SOURCE__PLACEHOLDER: 'Rating statistics by source',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_GOOGLE_YANDEX_GIS__HEADER:
        'Rating of companies on the sources of Google, Yandex, 2GIS',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_GOOGLE_YANDEX_GIS__TEXT: 'Over the last 24 hours',
    REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_GOOGLE_YANDEX_GIS__PLACEHOLDER:
        'Rating of companies on the sources of Google, Yandex, 2GIS',
    REVIEWS__DOWNLOAD_REPORT_POPUP__THE_APPLICATION_HAS_BEEN_ACCEPTED__HEADER: 'The application has been accepted',
    REVIEWS__DOWNLOAD_REPORT_POPUP__THE_APPLICATION_HAS_BEEN_ACCEPTED__TEXT:
        'The requested report will be sent to you by email specified in your profile.',
    REVIEWS__DOWNLOAD_REPORT_POPUP__THE_APPLICATION_ERROR_SENT: 'Failed to send request',
    REVIEWS__DOWNLOAD_REPORT_POPUP__NAME__LABEL__TITLE: 'Download name',
    REVIEWS__DOWNLOAD_REPORT_POPUP__NAME__HINT__TITLE: 'Download name',
    REVIEWS__DOWNLOAD_REPORT_POPUP__ADDITIONAL_DATA__LABEL__TITLE: 'Additional data in the upload',
    REVIEWS__DOWNLOAD_REPORT_POPUP__NAME__HINT__TEXT:
        'You can change the name of the uploaded file or leave the default one.',
    REVIEWS__TAGS_POPUP__HEADER: 'Tags',
    REVIEWS__AUTOTAGS_POPUP__HEADER: 'Autotags',
    REVIEWS__TAGS_POPUP__SEARCH_FOR_TAGS: 'Search for tags',
    REVIEWS__TAGS_POPUP__ALL_GROUPS: 'All groups',
    REVIEWS__TAGS_POPUP__GO_TO_TAG_SETUP: 'Go to tag setup',
    REVIEWS__TAGS_POPUP__REFRESH: 'Refresh tag list',
    REVIEW__FORM__MODAL__HEADER__ADDING: 'Add review',
    REVIEW__FORM__MODAL__HEADER__EDITING: 'Edit review',
    REVIEW__FORM__MODAL__ADD_ONE_MORE_REVIEW: 'Add one more review',
    REVIEW__FORM__MODAL__NO_RATING: 'Without rating',
    REVIEW__FORM__FIELD_SOURCE: 'Source',
    REVIEW__FORM__FIELD_BRAND: 'Brand',
    REVIEW__FORM__FIELD_COMPANY: 'Company',
    REVIEW__FORM__FIELD_CREATED_DATE: 'Created date',
    REVIEW__FORM__FIELD_AUTHOR: 'Author',
    REVIEW__FORM__FIELD_PHONE: 'Phone',
    REVIEW__FORM__FIELD_RATING: 'Rating',
    REVIEW__FORM__FIELD_CONTENT: 'Review text',
    REVIEW__FORM__FIELD_BRAND__PLACEHOLDER: 'Select brand',
    REVIEW__FORM__FIELD_AUTHOR__PLACEHOLDER: 'Name and Surname of review author',
    REVIEW__FORM__FIELD_PHONE__PLACEHOLDER: 'Phone number with a country code and operator',
    REVIEW__FORM__FIELD_CONTENT__PLACEHOLDER: 'Enter a text of the review here...',
    REVIEW__FORM__CREATED__SUCCESS_HEADER: 'Review saved',
    REVIEW__FORM__CREATED__SUCCESS_BODY: 'Your review has been successfully added',
    REVIEW__FORM__EDITED__SUCCESS_BODY: 'Your review has been successfully updated',
    CUSTOM_REIVEW__DELETE_MODAL_TITLE: 'Delete review?',
    CUSTOM_REIVEW__DELETE_MODAL_CONTENT: 'Review will be deleted',
    CUSTOM_REIVEW__DELETE__SUCCESS__TITLE: 'The review has been deleted',
    CUSTOM_REIVEW__DELETE__SUCCESS__DESCRIPTION: 'Your review has been successfully deleted from the system.',

    REVIEW_ITEM_PAGE__TITLE: 'View review',
    REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__SINGLE: '{count} reply',
    REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__FEW: '{count} replies',
    REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__MANY: '{count} replies',
    REVIEW_ITEM_PAGE__ACTIVITY__NOTES__SINGLE: '{count} note',
    REVIEW_ITEM_PAGE__ACTIVITY__NOTES__FEW: '{count} notes',
    REVIEW_ITEM_PAGE__ACTIVITY__NOTES__MANY: '{count} notes',
    REVIEW_ITEM_PAGE__ACTIVITY__LOGS: 'History',
    REVIEW_ITEM_PAGE__ACTIVITY__LOGS__CREATED: 'Review added to the catalog',
    REVIEW_ITEM_PAGE__ACTIVITY__LOGS__CREATED__BY_USER: 'User {user} created a review',
    REVIEW_ITEM_PAGE__ACTIVITY__NEURO_NETWORK_RESPONSE__TITLE: 'Neural network response',
    REVIEW_ITEM_PAGE__ACTIVITY__NEURO_NETWORK_RESPONSE__OPTION: 'Proposal:',
    REVIEW_ITEM_PAGE__ACTIVITY__NEURO_NETWORK_RESPONSE__EDIT_MARK: '- editing',
    REVIEW_ITEM_PAGE__NO_TEXT_TITLE: 'Review without text',

    REVIEW__DROPDOWN_MENU__OPEN_LINK: 'Open in a new tab',
    REVIEW__DROPDOWN_MENU__ADD_NOTE: 'Add a note',
    REVIEW__DROPDOWN_MENU__APPOINT_RESPONSIBLE: 'Assign responsible person',

    REVIEW__NEW_DESIGN__SWITCHER: 'New Design',
    REVIEW__NEW_DESIGN_MODAL__TITLE: 'Try the New Design',
    REVIEW__NEW_DESIGN_MODAL__FOOTER__CANCEL: 'Revert to the Old Version',
    REVIEW__NEW_DESIGN_MODAL__FOOTER__CONFIRM: 'Continue with the New Design',
    REVIEW__NEW_DESIGN_MODAL__CONTENT__DESCRIPTION:
        "We know it may be unusual to see the new version of the section, but don't rush to give up on it!",
    REVIEW__NEW_DESIGN_MODAL__CONTENT__LIST__TITLE: 'In the future, we will add:',
    REVIEW__NEW_DESIGN_MODAL__CONTENT__LIST__PARAGRAPH_1: 'New features to simplify work and increase efficiency.',
    REVIEW__NEW_DESIGN_MODAL__CONTENT__LIST__PARAGRAPH_2: 'Enhancements for smoother and more intuitive interactions.',
    REVIEW__NEW_DESIGN_MODAL__CONTENT__ALERT:
        'Please note that the old design will only be available for a limited time.',
    REVIEWS__COMPLAINT_POPUP__OFFENSIVE_CONTENT: 'Offensive or Obscene Content',
    REVIEWS__COMPLAINT_POPUP__MENTION_COMPETITOR: 'Mentions Competitor',
    REVIEWS__COMPLAINT_POPUP__NOT_APPLY_THIS_COMPANY: 'Does Not Apply to This Company',
    REVIEWS__COMPLAINT_POPUP__PRIVACY_VIOLATION: 'Privacy Violation',
    REVIEWS__NOTE_MODAL__TITLE: 'Adding a note',
    REVIEWS__NOTE__EDIT: 'Edit note',
    REVIEWS__NOTE__DELETE: 'Delete note',

    REVIEW__ABUSE_STATUS__SENT: 'Complaint sent',
    REVIEW__ABUSE_STATUS__ACCEPTED: 'Complaint approved',
    REVIEW__ABUSE_STATUS__DECLINED: 'Complaint declined',
    REVIEW__ABUSE_STATUS_DESCRIPTION__ACCEPTED: 'The review is no longer displayed on the platform.',
    REVIEW__ABUSE_STATUS_DESCRIPTION__DECLINED: 'You can try to {link}',
    REVIEW__ABUSE_STATUS_DESCRIPTION__DECLINED__LINK: 'resubmit the complaint',
    REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT: 'Auto-complaint sent',
    REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT__TITLE: 'Complaint was sent automatically',
    REVIEW__ABUSE_STATUS_DESCRIPTION__AUTO_SENT__DESCRIPTION:
        "The review was checked for compliance with the platform's rules. Violations were detected, and a complaint was sent automatically.",
    REVIEW__ABUSE_STATUS_DESCRIPTION__CHECKED__TITLE: 'Review under verification',
    REVIEW__ABUSE_STATUS_DESCRIPTION__CHECKED__DESCRIPTION:
        "We are checking the possibility of automatic complaint filing. If violations of the platform's rules are detected, a complaint will be sent automatically.",
    REVIEWS__ABUSE__TEMPLATE__BUTTON: 'Templates',
    REVIEW__AUTO_TAG__TITLE: 'Autotag',
    REVIEW__SENT_ANSWER_IN_CABINET: 'Response to the review sent in the account',
    REVIEW__POST_ANSWER_IN_CATALOG: 'Response to the review published on the platform',
    REVIEW__RESPONSE_TIME: 'Response time: {time}',
    REVIEW__SAVE_ANSWER: 'Save response',
    REVIEW__IRRELEVANT_TOOLTIP__TITLE: 'Your response is outdated because the review has been modified',
    REVIEW__IRRELEVANT_TOOLTIP__TEXT: 'Outdated',
    REVIEW__SOURCES_BANNER__TITLE: 'Collect more reviews! Connect new sources',
    REVIEW__SOURCES_BANNER__BUTTON: 'Connect sources',
    REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION__STATUS: 'To reply, {link}',
    REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION__STATUS__LINK: 'confirm the company',
    REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION: 'Company not confirmed',
    REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION__DESCRIPTION:
        'Confirm the company to send replies and complaints via {companyName}.',
    REVIEW__ANSWER__CONFIRMATION_MODAL__TITLE: 'Confirm the company to submit complaints about reviews',
    REVIEW__ANSWER__CONFIRMATION_MODAL__CONTENT:
        'For this source, submitting a complaint via {companyName} is possible only after company confirmation.',
};
