import {z as r} from 'zod';

const reviewAnalysisSourceSchema = r.object({
    id: r.number(),
    name: r.string(),
});

export const reviewAnalysisSourcesResponseSchema = r.array(reviewAnalysisSourceSchema);

export type ReviewAnalysisSourceType = r.infer<typeof reviewAnalysisSourceSchema>;
