import {ConfigProvider, Radio} from 'antd';
import {Dispatch, SetStateAction} from 'react';

import {CompanyMultiSelectV2} from '../../../../../../layout/company-multi-select-v2/company-multi-select-v2';
import {Locale} from '../../../../../../provider/locale/localization';
import {UseCompaniesSelectorHookType} from '../../../../../../service/feature-companies/feature-companies-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {EditUserType} from '../../../../../../service/user-management/user-management-edit-user';
import {UsersFormKeyEnum, UsersFormType} from '../../../../../../service/user-management/user-management-users';
import {Form, FormInstance} from '../../../../../../typings/antd';

import {BrandRulesTab} from './brand-rules-tab/brand-rules-tab';
import {GroupRulesTab} from './group-rules-tab/group-rules-tab';
import {UsersFormTabEnum} from './users-form-tabs-type';
import * as styles from './users-form-tabs.scss';

type PropsType = {
    isOwner?: boolean;
    companiesSelector: UseCompaniesSelectorHookType;
    form: FormInstance<UsersFormType>;
    user?: EditUserType;
    activeTab: UsersFormTabEnum;
    setActiveTab: Dispatch<SetStateAction<UsersFormTabEnum>>;
    userId?: number;
};

export function UsersFormTabs(props: PropsType): JSX.Element | null {
    const {isOwner, companiesSelector, form, user, setActiveTab, activeTab, userId} = props;
    const {getCompaniesMultiselectValidationRule} = useFormRules();

    if (isOwner) {
        return null;
    }

    return (
        <Form.Item label={<Locale stringKey="USERS__FORM__TAB__ACCESS_RULES" />} required>
            <ConfigProvider wave={{disabled: true}}>
                <Radio.Group
                    className={styles.UserRulesRadioGroup}
                    onChange={(event) => setActiveTab(event.target.value)}
                    options={[
                        {
                            label: (
                                <div>
                                    <Locale stringKey="USERS__TABLE__COLUMN__BRANDS" />
                                    {activeTab === UsersFormTabEnum.AccessRules && (
                                        <BrandRulesTab form={form} user={user} userId={userId} />
                                    )}
                                </div>
                            ),
                            value: UsersFormTabEnum.AccessRules,
                        },
                        {
                            label: (
                                <div>
                                    <Locale stringKey="USERS__TABLE__COLUMN__GROUPS" />
                                    {activeTab === UsersFormTabEnum.CompaniesGroup && (
                                        <GroupRulesTab form={form} user={user} userId={userId} />
                                    )}
                                </div>
                            ),
                            value: UsersFormTabEnum.CompaniesGroup,
                        },
                        {
                            label: (
                                <>
                                    <Locale stringKey="USERS__FORM__TAB__COMPANIES" />
                                    {activeTab === UsersFormTabEnum.IndividualCompanies && (
                                        <Form.Item
                                            name={[UsersFormKeyEnum.CompaniesSelector]}
                                            required
                                            rules={[
                                                getCompaniesMultiselectValidationRule(companiesSelector.selectedCount),
                                            ]}
                                        >
                                            <CompanyMultiSelectV2
                                                className={styles.UserRulesRadioGroup_choiceCompanies}
                                                companiesSelector={companiesSelector}
                                                isDisabled={false}
                                                selectedCountLangKey="COMPANY__MULTISELECT__SELECTED_COMPANY_COUNT"
                                            />
                                        </Form.Item>
                                    )}
                                </>
                            ),
                            value: UsersFormTabEnum.IndividualCompanies,
                        },
                    ]}
                    value={activeTab}
                />
            </ConfigProvider>
        </Form.Item>
    );
}
