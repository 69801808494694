import {useFooterText} from '../../../../../../component/footer/footer-hook';

import * as styles from './fakes-report-table-footer.scss';

export function FakesReportTableFooter(): JSX.Element {
    const footerText = useFooterText();

    return (
        <tr>
            <td className={styles.FakesReportTableFooter} colSpan={2}>
                {footerText}
            </td>
        </tr>
    );
}
