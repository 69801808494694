import {Button, Form, Input, Select, Space} from 'antd';
import {useState} from 'react';

import {LegalAgreement} from '../../../../layout/legal-agreement/legal-agreement';
import {LegalAgreementFormKeyEnum} from '../../../../layout/legal-agreement/legal-agreement-type';
import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {TimezoneType} from '../../../../service/api/api-type';
import {getCountryTranslation, useSortedCountries} from '../../../../service/company/countries';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {useProfile, useUpdateProfile} from '../../../../service/profile/profile-hook';
import {ProfileType} from '../../../../service/profile/profile-type';
import {handleFilterOption} from '../../../../util/antd/select-helper';
import {classNames} from '../../../../util/css';
import {getMapFromObject} from '../../../../util/object';
import * as profileStyles from '../profile.scss';
import {defaultPersonalInformation} from '../profile-const';
import {PersonalInformationType} from '../profile-type';

import {DeleteAccountModal} from './delete-account-modal/delete-account-modal';
import {serializeProfileFromPersonalInfo} from './personal-information-helper';
import {useProfileTimezone} from './profile-timezone-hook';
import * as styles from './personal-information.scss';

const {Option} = Select;

type PropsType = {
    className?: string;
};

export function PersonalInformation(props: PropsType): JSX.Element {
    const [form] = Form.useForm<PersonalInformationType>();
    const {emailFieldRule, getPhoneNumberRule, maxLengthFieldRule, minLengthFieldRule, requiredFieldRule} =
        useFormRules();
    const {className} = props;
    const [isOpenDeleteAccountModal, setIsOpenDeleteAccountModal] = useState<boolean>(false);
    const {getLocalizedString, shortLocaleName} = useLocale();
    const {snackbar} = useSnackbar();
    const fullClassName = classNames(styles.personal_information, className);
    const {result: profileResult, isInProgress} = useProfile();
    const {updateProfile, processError: updateProfileError, isInProgress: isProfileUpdating} = useUpdateProfile();
    const {user} = useUser();
    const [citizenshipId, setCitizenshipId] = useState<number | null>(null);
    const {data: sortedCountries, isInitialLoading} = useSortedCountries();
    const {timezoneLibrary, timezoneList, timezone, findTimezoneAndUpdate} = useProfileTimezone(profileResult);

    function handleFormSubmit() {
        if (profileResult !== null) {
            const {name, surname, email, phone} = getMapFromObject<PersonalInformationType>(
                form.getFieldsValue(),
                defaultPersonalInformation
            );

            const newProfile: ProfileType = serializeProfileFromPersonalInfo(
                {
                    name,
                    surname,
                    email,
                    phone,
                    citizen: sortedCountries?.results.find(({id}) => id === citizenshipId)?.id ?? profileResult.country,
                    timezone: timezone.name,
                },
                profileResult
            );

            updateProfile(newProfile)
                .then(() => {
                    snackbar.success(<Locale stringKey="PROFILE__PERSONAL_INFORMATION__UPDATED" />);
                })
                .catch((error: unknown) => console.error(error));
        }
    }

    function submitForbidden() {
        snackbar.error(<Locale stringKey="PROFILE__PERSONAL_INFORMATION__UPDATE_IS_FORBIDDEN" />);
    }

    if (!profileResult) {
        return <Spinner position="absolute" />;
    }

    return (
        <>
            <Form<PersonalInformationType>
                className={fullClassName}
                form={form}
                initialValues={{
                    citizenship: profileResult.country,
                    [LegalAgreementFormKeyEnum.Agreement]: profileResult.agreement,
                    [LegalAgreementFormKeyEnum.RulesConfirmation]: true,
                }}
                layout="vertical"
                name="change-password"
                onFinish={user?.isDemoUser ? submitForbidden : handleFormSubmit}
            >
                <PageSubHeader>
                    <Locale stringKey="PROFILE__PERSONAL_INFORMATION__SUB_HEADER" />
                </PageSubHeader>

                <div className={styles.personal_information__input_list_wrapper}>
                    <Form.Item
                        className={styles.personal_information__input}
                        initialValue={profileResult.personal_data.first_name}
                        label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__LABEL__NAME" />}
                        name="name"
                        rules={[requiredFieldRule, minLengthFieldRule(2), maxLengthFieldRule(255)]}
                    >
                        <Input
                            placeholder={getLocalizedString('PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__YOUR_NAME')}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.personal_information__input}
                        initialValue={profileResult.personal_data.last_name}
                        label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__LABEL__SURNAME" />}
                        name="surname"
                        rules={[minLengthFieldRule(2), maxLengthFieldRule(255)]}
                    >
                        <Input
                            placeholder={getLocalizedString('PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__YOUR_SURNAME')}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.personal_information__input}
                        initialValue={profileResult.personal_data.email}
                        label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__LABEL__EMAIL_ADDRESS" />}
                        name="email"
                        rules={[requiredFieldRule, emailFieldRule]}
                    >
                        <Input
                            onInvalid={(event) => event.preventDefault()}
                            placeholder={getLocalizedString(
                                'PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__YOUR_EMAIL_ADDRESS'
                            )}
                            size="large"
                            type="email"
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.personal_information__input}
                        initialValue={profileResult.personal_data.phone_number}
                        label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__LABEL__PHONE_NUMBER" />}
                        name="phone"
                        rules={[requiredFieldRule, getPhoneNumberRule()]}
                    >
                        <Input
                            placeholder={getLocalizedString('PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__PHONE_NUMBER')}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.personal_information__input}
                        label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__LABEL__CITIZENSHIP" />}
                        name="citizenship"
                        rules={[requiredFieldRule]}
                    >
                        <Select<number>
                            disabled={isInitialLoading}
                            filterOption={handleFilterOption}
                            loading={isInitialLoading}
                            onChange={(value) => setCitizenshipId(Number(value))}
                            options={sortedCountries?.results.map((country) => ({
                                value: country.id,
                                label: getCountryTranslation(country, shortLocaleName),
                            }))}
                            placeholder={getLocalizedString('PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__COUNTRY')}
                            showSearch
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={styles.personal_information__input}
                        initialValue={profileResult.profile.timezone}
                        label={<Locale stringKey="PROFILE__PERSONAL_INFORMATION__LABEL__TIMEZONE" />}
                        name="timezone"
                        rules={[requiredFieldRule]}
                    >
                        <Select<string>
                            disabled={timezoneLibrary.isInProgress}
                            filterOption={handleFilterOption}
                            loading={timezoneLibrary.isInProgress}
                            onChange={findTimezoneAndUpdate}
                            placeholder={getLocalizedString('PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__TIMEZONE')}
                            showSearch
                            size="large"
                        >
                            {timezoneList.map((timezoneInList: TimezoneType): JSX.Element => {
                                const {name_for_display: nameForDisplay, name} = timezoneInList;

                                return (
                                    <Option key={nameForDisplay} value={name}>
                                        {nameForDisplay}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>

                <LegalAgreement className={styles.personal_information__legal_agreement} disabled />

                {updateProfileError && <p className={profileStyles.profile__error_message}>{updateProfileError}</p>}

                <Space style={{justifyContent: 'space-between', width: '100%'}} wrap>
                    <Button htmlType="submit" loading={isInProgress || isProfileUpdating} type="primary">
                        <Locale stringKey="BUTTON__UPDATE_INFO" />
                    </Button>
                    <Button danger onClick={() => setIsOpenDeleteAccountModal(true)}>
                        <Locale stringKey="PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT" />
                    </Button>
                </Space>
            </Form>
            <DeleteAccountModal
                isOpenDeleteAccountModal={isOpenDeleteAccountModal}
                setIsOpenDeleteAccountModal={setIsOpenDeleteAccountModal}
            />
        </>
    );
}
