import {faUserPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../../component/page-card-divider/main-page-card-divider';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useUser} from '../../../../../service/user-management/user-management-user';

import {UserDetails} from './details/user-details';
import {UserStatistics} from './statistics/user-statistics';
import * as styles from './users-header.scss';

type PropsType = {
    isUserAddButtonPrimary: boolean;
};

export function UsersHeader(props: PropsType): JSX.Element {
    const {isUserAddButtonPrimary} = props;

    const {data: user} = useUser();

    const {getLocalizedString} = useLocale();

    return (
        <PageCard>
            <Meta title={getLocalizedString('USERS__TITLE')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.localPack.path,
                        titleLangKey: 'USERS__BREADCRUMB',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle productName={UsetifulNameProductEnum.USERS_MANAGEMENT} title="USERS__TITLE" />

                <NavigationLink to={appRoute.createUser.path}>
                    <Button
                        disabled={user?.licenceAvailable === 0}
                        icon={<FontAwesomeIcon icon={faUserPlus} size="sm" />}
                        type={isUserAddButtonPrimary ? 'primary' : 'default'}
                    >
                        <span>
                            <Locale stringKey="USERS__HEADER__BUTTON__ADD" />
                        </span>
                    </Button>
                </NavigationLink>
            </PageHeader>

            <MainPageCardDivider />

            <div className={styles.UsersHeader_summary}>
                <section className={styles.UsersHeader_details}>
                    <UserDetails />
                </section>

                <section className={styles.UsersHeader_statistics}>
                    <UserStatistics />
                </section>
            </div>
        </PageCard>
    );
}
