import {useCallback, useEffect, useState} from 'react';

import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    fetchQuestionRepliers,
    fetchQuestions,
    fetchQuestionsStatistic,
    sendReply as fetchSendReply,
} from './questions-api';
import {handleReplyError} from './questions-helper';
import {
    FetchQuestionsOptionType,
    QuestionDataType,
    QuestionReplyType,
    QuestionsStatisticType,
    ReplierDataType,
} from './questions-type';

type QuestionsAdditionalType = {
    loadQuestions: () => void;
};

type QuestionRepliersAdditionalType = {
    loadQuestionRepliers: () => void;
};

type QuestionsStatisticAdditionalType = {
    loadQuestionsStatistic: () => void;
};

type QuestionsReplyAdditionalType = {
    sendReply: (id: number, reply: string) => Promise<QuestionReplyType | void>;
};

export function useQuestions(
    option: RequestOptionsType,
    parameters: FetchQuestionsOptionType,
    mainFilterKey: string
): UseHookType<PaginatedResponseType<QuestionDataType>> & QuestionsAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<QuestionDataType>>();

    const {count, page} = option;
    const {hasOwnerAnswer, employerId, dateBefore, dateAfter} = parameters;

    const loadQuestions = useCallback(() => {
        setIsInProgress(true);

        fetchQuestions({count, page}, {hasOwnerAnswer, employerId, dateBefore, dateAfter}, mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        setIsInProgress,
        count,
        page,
        mainFilterKey,
        hasOwnerAnswer,
        employerId,
        dateBefore,
        dateAfter,
        setResult,
        setProcessError,
    ]);

    useEffect(() => {
        loadQuestions();
    }, [loadQuestions]);

    return {isInProgress, processError, result, reset, loadQuestions};
}

export function useQuestionRepliers(): UseHookType<Array<ReplierDataType>> & QuestionRepliersAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, reset} =
        useApiHooks<PaginatedResponseType<ReplierDataType>>();

    const [result, setResult] = useState<Array<ReplierDataType> | null>(null);

    const loadQuestionRepliers = useCallback(() => {
        setIsInProgress(true);

        fetchQuestionRepliers()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        loadQuestionRepliers();
    }, [loadQuestionRepliers]);

    return {isInProgress, processError, result, reset, loadQuestionRepliers};
}

export function useQuestionsStatistic(
    parameters: FetchQuestionsOptionType,
    mainFilterKey: string
): UseHookType<QuestionsStatisticType> & QuestionsStatisticAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<QuestionsStatisticType>();

    const {hasOwnerAnswer, employerId, dateAfter, dateBefore} = parameters;

    const loadQuestionsStatistic = useCallback(() => {
        setIsInProgress(true);

        fetchQuestionsStatistic(
            {
                hasOwnerAnswer,
                employerId,
                dateAfter,
                dateBefore,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, mainFilterKey, hasOwnerAnswer, employerId, dateAfter, dateBefore, setResult, setProcessError]);

    useEffect(() => {
        loadQuestionsStatistic();
    }, [loadQuestionsStatistic]);

    return {isInProgress, processError, result, reset, loadQuestionsStatistic};
}

export function useQuestionsReply(): UseHookType<QuestionReplyType> & QuestionsReplyAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<QuestionReplyType>();

    const sendReply = useCallback(
        (id: number, reply: string): Promise<QuestionReplyType | void> => {
            setIsInProgress(true);

            return fetchSendReply(id, reply)
                .then((response) => {
                    setResult(response);
                })
                .finally(() => setIsInProgress(false))
                .catch((error: unknown) => {
                    if (error instanceof Error) {
                        setProcessError(error);
                    } else {
                        setProcessError(new Error('[not Error type error]:' + error));
                    }

                    handleReplyError(error);
                });
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {isInProgress, processError, result, reset, sendReply};
}
