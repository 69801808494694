import {LatLngBoundsLiteral} from 'leaflet';
import {TileLayerProps} from 'react-leaflet/types/TileLayer';

const LEAFLET_URL_TEMPLATE = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
const LEAFLET_LAYER_ID = 'osm-layer';

const MAPBOX_URL_TEMPLATE = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
const MAPBOX_LAYER_ID = 'mapbox/streets-v11';

const MAP_BOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoicm9ja2V0ZGF0YSIsImEiOiJjanllNzBoMGwwMjU0M29tc3E4bGgwbmloIn0.MdfyNg2nbQEhuJZVr-V4kw';

const showMapBoxMap = process.env.SHOW_MAPBOX_MAP;

const isShowMapBoxMap: boolean =
    showMapBoxMap && (showMapBoxMap === 'true' || showMapBoxMap === 'false') ? JSON.parse(showMapBoxMap) : false;

export const TILE_LAYER_CONFIG: TileLayerProps = isShowMapBoxMap
    ? {
          id: MAPBOX_LAYER_ID,
          url: MAPBOX_URL_TEMPLATE,
          accessToken: MAP_BOX_ACCESS_TOKEN,
      }
    : {
          url: LEAFLET_URL_TEMPLATE,
          id: LEAFLET_LAYER_ID,
      };

export const LEAFLET_ZOOM_CONFIG = {
    maxMapZoom: 18,
    minMapZoom: 1,
    defaultMapZoom: 12,
};

export const LEAFLET_TILE_SIZE = 512;

export const LEAFLET_ZOOM_OFFSET = -1;

export const EUROPE_BOUNDS: LatLngBoundsLiteral = [
    [71.2, -31.3],
    [34.5, 69],
];
