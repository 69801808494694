import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from 'antd';

import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {CclCompanyStatsType} from '../../../../../service/ccl/ccl-type';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {
    cclErrorTranslationMap,
    getVerificationStatusIconData,
    getVerificationStatusTextKey,
    shouldShowSynchronizationDetails,
} from './sources-synchronization-helper';
import * as styles from './sources-synchronization.scss';

type PropsType = {
    cclCompanyStats: CclCompanyStatsType;
};

export function SourceSynchronizationDetails(props: PropsType): JSX.Element {
    const {cclCompanyStats} = props;

    const showSynchronizationDetails = shouldShowSynchronizationDetails(cclCompanyStats);

    const {getFormattedDateTime} = useFormat();

    const iconData = getVerificationStatusIconData(cclCompanyStats);

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    return (
        <div className={styles.sources_synchronization__item} key={cclCompanyStats.catalog.id}>
            <img
                alt={getCatalogName(cclCompanyStats.catalog.id)}
                className={styles.sources_synchronization__item__logo}
                src={getCatalogLogo(cclCompanyStats.catalog.id)}
            />
            <div className={styles.sources_synchronization__item__info}>
                <div className={styles.sources_synchronization__item__info__catalog}>
                    <p className={styles.sources_synchronization__item__info__catalog__name}>
                        {getCatalogName(cclCompanyStats.catalog.id)}
                    </p>

                    <div className={styles.sources_synchronization__item__info__synchronization}>
                        <p className={styles.sources_synchronization__item__info__synchronization__status}>
                            <Locale stringKey={getVerificationStatusTextKey(cclCompanyStats)} />
                        </p>
                        <FontAwesomeIcon
                            className={classNames(styles.sources_synchronization__item__info__icon, iconData.className)}
                            icon={iconData.icon}
                        />
                    </div>
                </div>
                <Divider className={styles.sources_synchronization__item__divider} />

                {cclCompanyStats.needAction && (
                    <p className={styles.sources_synchronization__item__text_description}>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__ACTION_NEEDED" />
                    </p>
                )}

                {!cclCompanyStats.needAction && !showSynchronizationDetails && (
                    <p className={styles.sources_synchronization__item__text_description}>
                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__SENT" />
                    </p>
                )}

                {showSynchronizationDetails && (
                    <table className={styles.sources_synchronization__item__description}>
                        <tbody>
                            <tr>
                                <td>
                                    <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__DETAILS__SYNCHRONIZATION_DATE" />
                                </td>
                                <td className={styles.sources_synchronization__item__description__value}>
                                    {cclCompanyStats.catalogStatusDate
                                        ? getFormattedDateTime(new Date(cclCompanyStats.catalogStatusDate))
                                        : '—'}
                                </td>
                            </tr>
                            {cclCompanyStats.errors.length > 0 && (
                                <tr>
                                    <td>
                                        <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__DETAILS__SYNCHRONIZATION_ERRORS" />
                                    </td>
                                    <td className={styles.sources_synchronization__item__description__value}>
                                        <span className={styles.sources_synchronization__item__description__error}>
                                            {cclCompanyStats.errors.length}
                                        </span>
                                        <AdditionalInfo
                                            title={
                                                <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__DETAILS__SYNCHRONIZATION_ERRORS__TITLE" />
                                            }
                                        >
                                            <ul
                                                className={
                                                    styles.sources_synchronization__item__description__error_list
                                                }
                                            >
                                                {cclCompanyStats.errors.map((error) => (
                                                    <li key={error}>
                                                        <Locale stringKey={cclErrorTranslationMap[error]} />
                                                    </li>
                                                ))}
                                            </ul>
                                        </AdditionalInfo>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}
