import {faCaretDown, faCaretUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';

import * as styles from './enum-attribute.scss';

type PropsType = {
    showAll: boolean;
    setShowAll: (showAll: boolean) => void;
};

export function EnumAttributeToggleButton(props: PropsType): JSX.Element {
    const {showAll, setShowAll} = props;

    return (
        <Button
            className={styles.enum_attribute__button}
            icon={<FontAwesomeIcon icon={showAll ? faCaretUp : faCaretDown} />}
            onClick={() => setShowAll(!showAll)}
            size="small"
        >
            <span>
                <Locale
                    stringKey={
                        showAll
                            ? 'COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW_LESS'
                            : 'COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW_MORE'
                    }
                />
            </span>
        </Button>
    );
}
