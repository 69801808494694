import {z as r} from 'zod';

export const averageResponseTimeSchema = r.object({
    avgReplyTime: r.object({
        // eslint-disable-next-line id-length
        cp: r.object({
            value: r.number().nullable(), // время в секундах
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
        catalog: r.object({
            value: r.number().nullable(), // время в секундах
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
    }),
});

export type AverageResponseTimeType = r.infer<typeof averageResponseTimeSchema>;
