import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Input, Select, Tooltip} from 'antd';
import {ReactElement, SyntheticEvent} from 'react';

import {Locale} from '../../../../../../../provider/locale/localization';
import {BulkEditFieldNameEnum} from '../../../../../../../service/company/company-type';
import {useRegularSocialsApi} from '../../../../../../../service/company-v2/company-hook';
import {classNames} from '../../../../../../../util/css';
import {checkSocialsNetworkRegex} from '../../../../../company-form/page-container/widgets/links/links-form-item/links-form-item-helper';
import {UrlInputStringType} from '../websites-form-item-type';

import {extractProtocol} from './url-input-helper';
import {ProtocolEnum} from './url-input-type';
import * as styles from './custom-input.scss';

type PropsType = {
    item: UrlInputStringType;
    itemList: Array<UrlInputStringType>;
    setItemList: (newItemList: Array<UrlInputStringType>) => void;
    placeholder?: string;
    tag?: BulkEditFieldNameEnum;
};

export function CustomInput(props: PropsType): ReactElement {
    const {item, itemList, setItemList, placeholder, tag} = props;
    const index = itemList.indexOf(item);
    const {data} = useRegularSocialsApi();

    function handleChangeProtocol(protocol: ProtocolEnum): void {
        const newItemList = [...itemList];

        newItemList[index] = {...item, protocol};

        setItemList(newItemList);
    }

    function handleInput(event: SyntheticEvent<HTMLInputElement>) {
        const enteredText = event.currentTarget.value;

        const newValue: UrlInputStringType = {
            id: item.id,
            ...extractProtocol(enteredText, item.protocol),
        };

        const newItemList = [...itemList];

        newItemList[index] = newValue;

        setItemList(newItemList);
    }

    const showSuffix = tag === BulkEditFieldNameEnum.websites && checkSocialsNetworkRegex(data || [], item.value);

    return (
        <Input
            addonBefore={
                <Select<ProtocolEnum> onChange={handleChangeProtocol} value={item.protocol}>
                    <Select.Option value={ProtocolEnum.HTTP}>{ProtocolEnum.HTTP}</Select.Option>
                    <Select.Option value={ProtocolEnum.HTTPS}>{ProtocolEnum.HTTPS}</Select.Option>
                </Select>
            }
            className={classNames({[styles.CustomInput_warningSelect]: showSuffix})}
            onInput={handleInput}
            placeholder={placeholder}
            size="large"
            suffix={
                <div>
                    {showSuffix ? (
                        <Tooltip
                            placement="bottom"
                            title={<Locale stringKey="COMPANY_FORM__LINKS__SOCIAL_NETWORKS__TOOLTIP__WARNING" />}
                        >
                            <FontAwesomeIcon className={styles.CustomInput_icon} icon={faCircleExclamation} />
                        </Tooltip>
                    ) : (
                        // if we return null outside the div, an extra render appears and the field loses focus
                        ''
                    )}
                </div>
            }
            value={item.value}
        />
    );
}
