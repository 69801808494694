import {Button, Modal} from 'antd';
import {useState} from 'react';

import {AddButton} from '../../../../../../component/add-button/add-button';
import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../../provider/user/user-hook';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';
import {CatalogByGroupType} from '../../../../../../service/source-settings/catalogs/catalogs-type';
import {useCreateSourceLink} from '../../../../../../service/source-settings/source-links/source-links-hook';
import {ManageSourceLinkError} from '../../../../../../service/source-settings/source-links/source-links-type';
import {ApiError} from '../../../../../../util/error';
import {ManageLinkForm} from '../manage-link-form/manage-link-form';
import {ManageLinkFormEnum, ManageLinkFormType} from '../manage-link-form/manage-link-form-type';

import {addLinkFormId} from './add-link-const';
import {getErrorDescriptionText} from './add-link-helper';
import * as styles from './add-link.scss';

type PropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    isPrimary?: boolean;
    onSuccess: () => void;
};

export function AddLink(props: PropsType): JSX.Element {
    const {catalog, catalogGroupId, isPrimary, onSuccess} = props;

    const {snackbar} = useSnackbar();
    const {isInProgress, processError, create, reset} = useCreateSourceLink();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {companyName} = useDomainConfig();
    const {user} = useUser();

    const {getCatalogName} = useCatalogInfo();

    const serverValidationErrors =
        processError instanceof ManageSourceLinkError
            ? {
                  [ManageLinkFormEnum.link]: processError.jsonData.status
                      ? [processError.jsonData.status]
                      : processError.jsonData.link,
              }
            : {};

    function onOpenModal() {
        reset();
        setIsModalOpen(true);
        track(AnalyticsTarget.Settings.SourceSettings.ClickAddLink);
    }

    async function onSubmit(values: ManageLinkFormType): Promise<void> {
        const {link, brandId, company} = values;

        track(AnalyticsTarget.Settings.SourceSettings.AddLinkToReviewPage);

        try {
            await create({link, brandId, catalogId: catalog.id, companyId: company?.id});
            snackbar.success({
                description: (
                    <Locale
                        stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__SUCCESS__CONTENT"
                        valueMap={{
                            catalogName: (
                                <span className={styles.add_link__success_catalog} key="catalog">
                                    {getCatalogName(catalog.id)}
                                </span>
                            ),
                            companyName,
                        }}
                    />
                ),
                message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__SUCCESS__TITLE" />,
            });
            onSuccess();
            setIsModalOpen(false);
        } catch (error: unknown) {
            snackbar.error({
                message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__FAIL__TITLE" />,
                description: getErrorDescriptionText(error),
            });

            if (!(error instanceof ApiError)) {
                throw error;
            }
        }
    }

    return (
        <>
            <AddButton onClick={onOpenModal} type={isPrimary ? 'primary' : 'default'}>
                <Locale stringKey="SOURCE_SETTINGS__LINKS__ADD_NEW__BUTTON__LABEL" />
            </AddButton>

            <Modal
                destroyOnClose
                footer={[
                    <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>,
                    <Button
                        disabled={user?.isTestUser}
                        form={addLinkFormId}
                        htmlType="submit"
                        key="submit"
                        loading={isInProgress}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__ADD" />
                    </Button>,
                ]}
                onCancel={() => setIsModalOpen(false)}
                open={isModalOpen}
                title={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__TITLE" />}
            >
                <ManageLinkForm
                    catalog={catalog}
                    catalogGroupId={catalogGroupId}
                    formId={addLinkFormId}
                    isEditMode={false}
                    onSubmit={onSubmit}
                    serverErrors={serverValidationErrors}
                />
            </Modal>
        </>
    );
}
