import {PropsWithChildren, ReactNode} from 'react';

import {Text} from '../../../component/text/text';
import {classNames} from '../../../util/css';

import * as styles from './form-fieldset.scss';

type PropsType = PropsWithChildren<{
    className?: string;
    legend?: ReactNode;
}>;

export function FormFieldset(props: PropsType): JSX.Element {
    const {className, children, legend} = props;

    const fullClassName = classNames(styles.FormFieldset, className);

    return (
        <fieldset className={fullClassName}>
            <Text block className={styles.FormFieldset_legend} large>
                {legend}
            </Text>
            <div className={styles.FormFieldset_content}>{children}</div>
        </fieldset>
    );
}
