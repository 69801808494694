import {GoogleCclStatusEnum, GoogleCompanyWithCandidatesType} from '../../../../../../../service/google/google-type';
import {GoogleProcessingStatusEnum} from '../../../../../../../service/google/processing/google-processing-type';
import {CompanyListItem} from '../../../common/company-list-item/company-list-item';
import {ManualBindingType} from '../processing-step-type';

import {CompanyListItemFooter} from './footer/company-list-item-footer';
import * as styles from './processing-step-list-item.scss';

type PropsType = {
    company: GoogleCompanyWithCandidatesType;
    status: GoogleProcessingStatusEnum;
    manualBinding: ManualBindingType;
    editBinding: (companyId: number) => void;
};

export function ProcessingStepListItem(props: PropsType): JSX.Element {
    const {company, status, manualBinding, editBinding} = props;

    return (
        <div className={styles.CompanyListItemWrapper}>
            <CompanyListItem
                company={{
                    ...company,
                    status:
                        company.status === GoogleCclStatusEnum.Send ? GoogleCclStatusEnum.ReadyToSend : company.status,
                }}
                footer={
                    status === GoogleProcessingStatusEnum.ProcessingFinished ? (
                        <CompanyListItemFooter
                            company={company}
                            editBinding={editBinding}
                            manualBinding={manualBinding}
                        />
                    ) : null
                }
                showContacts={false}
            />
        </div>
    );
}
