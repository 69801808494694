import {Dispatch, SetStateAction} from 'react';
import {z as r} from 'zod';

export enum MainFilterFieldNameEnum {
    GROUPS = 'groups',
    BRANDS = 'brands',
    COUNTRIES = 'countries',
    REGIONS = 'regions',
    CITIES = 'cities',
    STREETS = 'streets',
    NAMES = 'names',
    CODES = 'codes',
    CLOSED = 'closed',
    ACTIVE = 'active',
}

export enum MainFilterOpenCloseSuggestionIdEnum {
    OPEN = 'opened',
    IS_CLOSE = 'isClosed',
    IS_TEMPORARY_CLOSE = 'isTemporaryClosed',
}

export enum IsActiveFieldNameEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type MainFiltersStateType = {
    [key in MainFilterFieldNameEnum]: Array<FilterSuggestionType>;
};

export type MainFilterLoadSuggestionsPropsType = {
    after: string | null;
    searchText: string;
    fieldName: MainFilterFieldNameEnum;
};

export type MainFilterLoadMorePropsType = {
    fieldName: MainFilterFieldNameEnum;
    searchText: string;
    isNewSearchText?: boolean;
};

export const filterSuggestionSchema = r.object({
    id: r.string(),
    label: r.string(),
});

export type FilterSuggestionType = r.infer<typeof filterSuggestionSchema>;

export type LoadFieldSuggestionsType = (loadSuggestsProps: MainFilterLoadMorePropsType) => void;

export type MainFilterFieldHookType = {
    value: Array<FilterSuggestionType>;
    setValue: (newValue: Array<FilterSuggestionType>) => void;
    suggests: Array<FilterSuggestionType>;
    setSuggests: (suggests: Array<FilterSuggestionType>) => void;
    fieldName: MainFilterFieldNameEnum;

    shouldRefreshSuggests: boolean;
    setShouldRefreshSuggests: (value: boolean) => void;

    clearSuggests: () => void;
    isInProgress: boolean;
    setIsInProgress: (isInProgress: boolean) => void;

    canLoadMore: boolean;
    setCanLoadMore: (canLoadMore: boolean) => void;

    lastSuggestionId: string | null;
};

export type MainFilterHookType = {
    activeFilterCompaniesCount: number;
    isInProgress: boolean;
    isFilterEmpty: boolean;
    isAppliedFilterEmpty: boolean;
    clear: () => void;
    filteredCount: number;
    applyFilter: () => Promise<void>;
    reset: () => void;
    loadFieldSuggests: LoadFieldSuggestionsType;
    fields: {
        [key in MainFilterFieldNameEnum]: Omit<MainFilterFieldHookType, 'setValue'>;
    };
    setFieldValue: (fieldName: MainFilterFieldNameEnum, newValue: Array<FilterSuggestionType>) => void;
    setFilteredCount?: Dispatch<SetStateAction<number>>;
};

export type MainFilterFieldsType = {
    [key in MainFilterFieldNameEnum]: MainFilterFieldHookType;
};
