import {useContext, useEffect, useMemo, useState} from 'react';
import {generatePath, Redirect, useLocation} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageHeaderSub} from '../../../layout/page-header/page-header-sub';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {useAvailableForUpdateCompaniesCount, useBulkUpdateCompanies} from '../../../service/company/company-hook';
import {splitCommaSeparatedString} from '../../../util/string';
import {useUrl} from '../../../util/url-hook/url-hook';
import {SELECTED_ALL} from '../my-companies/companies-table/companies-table-const';

import {BULK_EDIT_COMPANIES_BREADCRUMB} from './bulk-edit-companies-const';
import {serializeBulkEditCompaniesFields, serializeBulkEditCompaniesFilters} from './bulk-edit-companies-helper';
import {BulkEditFieldType, BulkEditSearchParametersEnum} from './bulk-edit-companies-type';
import {ChooseFieldsModal} from './choose-fields-modal/choose-fields-modal';
import {EmptyBody} from './empty-body/empty-body';
import {FieldsList} from './fields-list/fields-list';
import {BulkEditFieldsFormType} from './fields-list/fields-list-type';
import * as styles from './bulk-edit-companies.scss';

// eslint-disable-next-line max-statements
export function BulkEditCompanies(): JSX.Element | null {
    const {getQuery, pushUrl} = useUrl();
    const {search} = useLocation();
    const selectedQueryRawValue: string | typeof SELECTED_ALL =
        getQuery(BulkEditSearchParametersEnum.SelectedIds) || '';
    const excludedQueryRawValue: string = getQuery(BulkEditSearchParametersEnum.ExcludedIds) || '';

    const yandexNeedActualization = getQuery(BulkEditSearchParametersEnum.YandexNeedActualization);
    const isNewCompanies = getQuery(BulkEditSearchParametersEnum.IsNewCompanies);

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectedFields, setSelectedFields] = useState<Array<BulkEditFieldType>>([]);
    const companyIdList = useMemo(
        () => splitCommaSeparatedString(selectedQueryRawValue).map(Number),
        [selectedQueryRawValue]
    );
    const excludedIdList = useMemo(
        () => splitCommaSeparatedString(excludedQueryRawValue).map(Number),
        [excludedQueryRawValue]
    );

    const bulkEditFilter = useMemo(() => {
        const companyIds = selectedQueryRawValue === SELECTED_ALL ? null : companyIdList;

        return serializeBulkEditCompaniesFilters(companyIds, excludedIdList, mainFilterKey);

        // Do not add 'mainFilter' and 'excludedIdList' variable to dependecies
        // we have to run this effect only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        bulkUpdateCompanies,
        isInProgress: isBulkEditingInProgress,
        processError: serverError,
    } = useBulkUpdateCompanies({
        newCompanies: isNewCompanies === 'true',
        yandexActualizationHasError: yandexNeedActualization,
    });
    const {
        isInProgress: isCompaniesCountInProgress,
        getAvailableForUpdateCompaniesCount,
        result: availableForUpdateCompaniesCount,
    } = useAvailableForUpdateCompaniesCount({
        newCompanies: isNewCompanies === 'true',
        yandexActualizationHasError: yandexNeedActualization,
    });

    function handleSubmit(fieldsValues: BulkEditFieldsFormType): void {
        const fieldSet = serializeBulkEditCompaniesFields(fieldsValues, selectedFields);

        bulkUpdateCompanies({field_set: fieldSet, filter: bulkEditFilter})
            .then(() => {
                snackbar.success({
                    message: <Locale stringKey="BULK_EDIT_COMPANIES__UPDATED_SUCCESSFULLY_HEAD" />,
                    description: (
                        <Locale
                            stringKey="BULK_EDIT_COMPANIES__UPDATED_SUCCESSFULLY_BODY"
                            valueMap={{
                                count: String(availableForUpdateCompaniesCount?.update),
                            }}
                        />
                    ),
                });

                pushUrl(appRoute.myCompanies.path);
            })
            .catch(() => {
                snackbar.error(<Locale stringKey="BULK_EDIT_COMPANIES__ERROR_HEAD" />);
            });
    }

    useEffect(() => {
        getAvailableForUpdateCompaniesCount(bulkEditFilter);
    }, [getAvailableForUpdateCompaniesCount, bulkEditFilter]);

    if (availableForUpdateCompaniesCount === null || isCompaniesCountInProgress) {
        return (
            <Page mainClassName={styles.main_page__loading__container}>
                <div className={styles.loading__container}>
                    <Spinner />
                </div>
            </Page>
        );
    }

    if (availableForUpdateCompaniesCount.update === 0) {
        return <Redirect to={{pathname: appRoute.myCompanies.path, search}} />;
    }

    if (availableForUpdateCompaniesCount.update < 2) {
        const [companyId] = companyIdList;

        return <Redirect to={{pathname: generatePath(appRoute.editCompany.path, {companyId}), search}} />;
    }

    return (
        <Page mainClassName={styles.main_page__container}>
            <Meta title={getLocalizedString('CATEGORY_NAME__EDITING_COMPANIES')} />

            <BreadCrumbs list={BULK_EDIT_COMPANIES_BREADCRUMB} />

            <PageHeader>
                <Locale
                    stringKey="BULK_EDIT_COMPANIES__HEADER"
                    valueMap={{count: availableForUpdateCompaniesCount.update}}
                />
            </PageHeader>

            <PageSubHeader className={styles.bulk_edit_companies__sub_header}>
                <Locale stringKey="BULK_EDIT_COMPANIES__SUB_HEADER" />
            </PageSubHeader>

            <PageHeaderSub>
                <Locale stringKey="BULK_EDIT_COMPANIES__BODY_TEXT" />
            </PageHeaderSub>

            {selectedFields.length > 0 ? (
                <FieldsList
                    companiesCount={availableForUpdateCompaniesCount.update}
                    fields={selectedFields}
                    isInProgress={isBulkEditingInProgress}
                    onChangeFields={setSelectedFields}
                    onSubmit={handleSubmit}
                    serverError={serverError}
                    setIsModalOpen={setIsModalOpen}
                />
            ) : (
                <EmptyBody setIsModalOpen={setIsModalOpen} />
            )}

            <ChooseFieldsModal
                fields={selectedFields}
                isOpen={isModalOpen}
                onOk={setSelectedFields}
                setIsOpen={setIsModalOpen}
            />
        </Page>
    );
}
