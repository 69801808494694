import {faLock} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space} from 'antd';
import {ReactElement} from 'react';

import {appRoute} from '../../../../../../app-route';
import {Text} from '../../../../../../component/text/text';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../../../layout/page/page';
import {Locale} from '../../../../../../provider/locale/locale';

import * as styles from './no-access-to-company.scss';

export function NoAccessToCompany(): ReactElement {
    return (
        <Page mainClassName={styles.NoAccessToCompany}>
            <Space align="center" direction="vertical" size="large">
                <FontAwesomeIcon className={styles.NoAccessToCompany_icon} icon={faLock} />
                <Text bolder large stringKey="COMPANY_FORM__ERROR__NO_ACCESS" />
                <NavigationLink to={appRoute.myCompanies.path}>
                    <Button>
                        <Locale stringKey="BUTTON__BACK" />
                    </Button>
                </NavigationLink>
            </Space>
        </Page>
    );
}
