import {Badge} from 'antd';
import {ColumnsType} from 'antd/lib/table';

import {Text} from '../../../../../../component/text/text';
import {FIXED_COLUMN_THRESHOLD} from '../../../../../../const';
import {gray6, primary50} from '../../../../../../css/var-export.scss';
import {FormattedDateTime} from '../../../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../../../provider/locale/locale';
import {
    TechnicalSupportFieldsEnum,
    TechnicalSupportRequestListItemType,
} from '../../../../../../service/technical-support/technical-support-type';
import {TechnicalSupportFieldValueTag} from '../technical-support-field-value/technical-support-field-value-tag';

import {TechnicalSupportTableNameCell} from './columns/technical-support-table-name-cell';

export function getTechnicalSupportTableColumns(
    screenWidth: number,
    refetchTableData: VoidFunction
): ColumnsType<TechnicalSupportRequestListItemType> {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 130,
            fixed: FIXED_COLUMN_THRESHOLD < screenWidth,
            render: (_value: string, rowValue: TechnicalSupportRequestListItemType) => <Text>{rowValue.hdeId}</Text>,
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__NAME" />,
            dataIndex: 'title',
            fixed: FIXED_COLUMN_THRESHOLD < screenWidth,
            width: 280,
            ellipsis: true,
            render: (_value: string, rowValue: TechnicalSupportRequestListItemType) => (
                <TechnicalSupportTableNameCell data={rowValue} refetchTableData={refetchTableData} />
            ),
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__COMMENTS" />,
            dataIndex: 'unreadPostsCount',
            width: 130,
            render: (value: number, row: TechnicalSupportRequestListItemType) => (
                <Badge
                    color={row.unreadPostsCount === 0 || !row.isSubscribed ? gray6 : primary50}
                    count={value}
                    showZero
                />
            ),
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__CREATOR" />,
            dataIndex: 'creatorName',
            width: 140,
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__CREATION_DATE" />,
            dataIndex: 'created',
            width: 110,
            render: (value: string) => <FormattedDateTime value={new Date(value)} />,
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__STATUS" />,
            dataIndex: 'status',
            width: 120,
            render: (value: string) => (
                <TechnicalSupportFieldValueTag field={TechnicalSupportFieldsEnum.Status} value={value} />
            ),
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__TYPE" />,
            dataIndex: 'type',
            width: 199,
            render: (value: string) => (
                <TechnicalSupportFieldValueTag field={TechnicalSupportFieldsEnum.Type} value={value} />
            ),
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__CATEGORY" />,
            dataIndex: 'clientCategory',
            width: 199,
            render: (value: number) => (
                <TechnicalSupportFieldValueTag field={TechnicalSupportFieldsEnum.ClientCategory} value={value} />
            ),
        },
        {
            title: <Locale stringKey="HELP_TECHNICAL_SUPPORT__TABLE__VISIBILITY" />,
            dataIndex: 'isPublic',
            width: 114,
            render: (value: boolean) => (
                <TechnicalSupportFieldValueTag field={TechnicalSupportFieldsEnum.IsPublic} value={value} />
            ),
        },
    ];
}
