import {PropsWithChildren} from 'react';

import {classNames} from '../../../../../util/css';

import {LeftContent} from './components/left-content/left-content';
import {RightContent} from './components/right-content/right-content';
import * as styles from './post-content-wrapper.scss';

type PropsType = {
    className?: string;
};

export function PostContentWrapper(props: PropsWithChildren<PropsType>): JSX.Element {
    const {children, className} = props;

    return <div className={classNames(styles.PostContentWrapper, className)}>{children}</div>;
}

PostContentWrapper.Left = LeftContent;
PostContentWrapper.Right = RightContent;
