import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useState} from 'react';

import {ReviewFormModal} from '../../review-form/review-form-modal';
import {ReviewFormModeEnum} from '../../review-form/review-form-type';

type PropsType = {
    refreshReviews: () => Promise<unknown>;
    refreshCatalogs: () => void;
};

export function AddReviewButton(props: PropsType): JSX.Element {
    const {refreshReviews, refreshCatalogs} = props;

    const [isReviewFormModalOpen, setIsReviewFormModalOpen] = useState(false);

    function handleSubmitFinishReviewForm() {
        refreshReviews();
        refreshCatalogs();
    }

    return (
        <>
            <Button icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setIsReviewFormModalOpen(true)} />

            <ReviewFormModal
                isOpen={isReviewFormModalOpen}
                mode={ReviewFormModeEnum.create}
                onCancel={() => setIsReviewFormModalOpen(false)}
                onSubmitFinish={handleSubmitFinishReviewForm}
            />
        </>
    );
}
