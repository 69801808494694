import {memo, useEffect, useMemo, useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {ChartLegend} from '../../../../../../../layout/chart/legend/chart-legend';
import {MultiSourceChart} from '../../../../../../../layout/chart/multisource-chart/multi-source-chart';
import {MultiSourceDataSetType} from '../../../../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {PageCard} from '../../../../../../../layout/page-card/page-card';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {useAllCatalogs, useCatalogInfo} from '../../../../../../../provider/catalogs/catalogs-hook';
import {FormattedDateTime} from '../../../../../../../provider/locale/formatted-date-time';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useRatingDynamicsChartData} from '../../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-hook';
import {
    RatingsDynamicsFilterQueryParametersEnum,
    RatingsDynamicsFilterType,
} from '../../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';
import {ProvidersIdsEnum} from '../../../../../../../util/type';

import {getCompanyRatingChartOptions, getRatingDynamicsChartDatasetColor} from './company-rating-history-chart-helper';
import * as styles from './company-rating-history-chart.scss';

type PropsType = {
    companyId: string;
    filter: RatingsDynamicsFilterType;
};

export const CompanyRatingHistoryChart = memo(function CompanyRatingHistoryChartInner(props: PropsType): JSX.Element {
    const {companyId, filter} = props;

    const {isLoading, data} = useRatingDynamicsChartData(companyId, filter);

    const {getLocalizedString} = useLocale();

    const {catalogs} = useAllCatalogs();
    const {getCatalogName} = useCatalogInfo();

    const [enabledDatasets, setEnabledDatasets] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (catalogs && !Object.keys(enabledDatasets)) {
            setEnabledDatasets({
                [getCatalogName(ProvidersIdsEnum.google)]: true,
                [getCatalogName(ProvidersIdsEnum.yandex)]: true,
                [getCatalogName(ProvidersIdsEnum.doubleGis)]: true,
            });
        }
    }, [catalogs, enabledDatasets, getCatalogName]);

    const chartData: {
        labels: Array<string>;
        datasets: Array<
            MultiSourceDataSetType & {
                questionnaireTooltips: Array<{
                    label: string;
                    type: 'mailing_list' | 'qr_codes';
                    title: string;
                }>;
            }
        >;
    } = useMemo(() => {
        return {
            labels: data?.labels || [],
            datasets:
                data?.ratingDynamics
                    .filter((ratingsDynamicsCatalogData) => {
                        return getCatalogName(ratingsDynamicsCatalogData.catalogId);
                    })
                    .map((ratingsDynamicsCatalogData) => {
                        const color = getRatingDynamicsChartDatasetColor(ratingsDynamicsCatalogData.catalogId);

                        return {
                            values: ratingsDynamicsCatalogData.values.map((value) => value ?? 0),
                            label: getCatalogName(ratingsDynamicsCatalogData.catalogId),
                            type: 'line' as const,
                            borderColor: color,
                            tension: 0.5,
                            questionnaireTooltips: data?.questionnaireTooltips.map((reviewGeneratorAction) => {
                                return {
                                    label: reviewGeneratorAction.date,
                                    type: reviewGeneratorAction.sourceType,
                                    title: reviewGeneratorAction.title,
                                };
                            }),
                            color,
                            pointRadius: data?.labels.map((label) => {
                                return data?.questionnaireTooltips.some((tooltipItem) => {
                                    return (
                                        tooltipItem.date === label &&
                                        tooltipItem.catalogId === ratingsDynamicsCatalogData.catalogId
                                    );
                                })
                                    ? 3
                                    : 1;
                            }),
                        };
                    }) || [],
        };
    }, [data, getCatalogName]);

    const chartOptions = useMemo(() => {
        return getCompanyRatingChartOptions(chartData, getLocalizedString);
    }, [chartData, getLocalizedString]);

    return (
        <PageCard bodyClassName={styles.CompanyRatingHistoryChart_wrapper}>
            <Text block stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__CHART_TITLE" />
            <Text secondary small>
                <FormattedDateTime value={filter[RatingsDynamicsFilterQueryParametersEnum.BaseDate]} />
                {' – '}
                <FormattedDateTime value={filter[RatingsDynamicsFilterQueryParametersEnum.TargetDate]} />
            </Text>

            <ChartLegend
                data={chartData}
                enabledDatasetsMap={enabledDatasets}
                setEnabledDatasetsMap={setEnabledDatasets}
            />

            <div className={styles.CompanyRatingHistoryChart_chartWrapper}>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <MultiSourceChart
                        data={{
                            labels: chartData.labels,
                            datasets: chartData.datasets.filter((dataset) => enabledDatasets[dataset.label]),
                        }}
                        options={chartOptions}
                    />
                )}
            </div>
        </PageCard>
    );
});
