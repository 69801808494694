import {useEffect} from 'react';

import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {fetchLanguages} from './language';
import {LanguageType} from './language-type';

export function useLanguages(): UseHookType<Array<LanguageType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<LanguageType>>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchLanguages()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
