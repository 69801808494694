import {fetchAndDeserialize} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {vkAccountsSchema} from '../accounts/accounts-const';
import {rootApiUrl} from '../api/api-const';

import {
    CompanyPostsType,
    CompanyPricesType,
    CompanyReviewsSummaryType,
    OnlinePresenceSummaryType,
    ReviewsSummaryOptionsType,
} from './company-activity-and-summary-type';

export function getPrices(companyId: number): Promise<Array<CompanyPricesType>> {
    return fetchX<Array<CompanyPricesType>>(rootApiUrl + `/cp/prices/prices_for_company/${companyId}/`);
}

export function getPosts(companyId: number): Promise<CompanyPostsType> {
    return fetchX<CompanyPostsType>(rootApiUrl + `/v1/company_stats/${companyId}/posts/`);
}

export async function getReviewsSummary(options: ReviewsSummaryOptionsType): Promise<CompanyReviewsSummaryType> {
    const {companyId, startDate, endDate} = options;
    let analyticsPath = '/v1/analytics/reviews/analysis_summary/';

    if (companyId) {
        analyticsPath += `${companyId}/`;
    }

    return fetchX(
        rootApiUrl +
            analyticsPath +
            '?' +
            objectToUrlParameters({
                start_date: startDate,
                end_date: endDate,
            })
    );
}

export async function getOnlinePresenceSummary(companyId: number): Promise<OnlinePresenceSummaryType> {
    const url = `/cp/company_stats/${companyId}/analytics/`;

    return fetchAndDeserialize(url, vkAccountsSchema);
}
