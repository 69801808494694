import {Radio, Table} from 'antd';
import {useCallback, useContext, useMemo, useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {useReviewPhraseList} from '../../../../../service/reivew-analysis/review-phrases/review-phrases-hook';
import {ReviewPhraseType} from '../../../../../service/reivew-analysis/review-phrases/review-phrases-type';
import {ActiveFilter} from '../../components/active-filter/active-filter';
import {DrawerFilter} from '../../components/drawer-filter/drawer-filter';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';
import {PhrasesTableDataType} from '../../reviews-analysis-type';

import {phrasesPageColumns} from './phrases-page-helper';
import * as styles from './phrases-page.scss';

export function PhrasesPage(): JSX.Element {
    const {reviewsAnalysisDashboard: dashboardRoute, reviewsAnalysisPhrases: phrasesRoute} = appRoute;

    const {getLocalizedString} = useLocale();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {singleFilter} = useReviewsAnalysisFilter();

    const [wordCount, setWordCount] = useState<number | null>(null);

    const {result, isInProgress, processError, pagination} = useReviewPhraseList(
        {
            ...singleFilter,
            words: wordCount,
        },
        mainFilterKey
    );

    const {page, pageSize, onChange: onPaginationChange, total} = pagination;

    const dataSource: Array<PhrasesTableDataType> = useMemo<
        Array<PhrasesTableDataType>
    >((): Array<PhrasesTableDataType> => {
        return (result?.results || []).map(
            (dataItem: ReviewPhraseType): PhrasesTableDataType => ({
                ...dataItem,
                key: dataItem.phrase,
            })
        );
    }, [result]);

    const onWordCountChange = useCallback(
        (value: number): void => {
            if (value === wordCount) {
                setWordCount(null);
                return;
            }

            setWordCount(value);
        },
        [wordCount, setWordCount]
    );

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__PHRASES')} />

            <BreadCrumbs
                list={[
                    {
                        path: dashboardRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                    },
                    {
                        path: phrasesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__PHRASES',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__PHRASES" />
                <DrawerFilter />
            </PageHeader>

            <ActiveFilter
                isCompareRangeAllowed={false}
                isCompetitorAllowed={false}
                isPreview={false}
                isWithTextOnly={false}
            />

            <div className={styles.words_tabs_wrapper}>
                <Radio.Group value={wordCount}>
                    {[2, 3, 4].map((option: number): JSX.Element => {
                        return (
                            <Radio.Button key={option} onClick={() => onWordCountChange(option)} value={option}>
                                <Locale stringKey="REVIEWS_ANALYSIS__WORDS_FILTER__OPTION" valueMap={{option}} />
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
            </div>

            {processError && <AlertFallback description={processError?.message} message={processError?.name} />}

            {!processError && (
                <Table<PhrasesTableDataType>
                    columns={phrasesPageColumns}
                    dataSource={dataSource}
                    loading={isInProgress}
                    pagination={{
                        total,
                        pageSize,
                        current: page,
                        size: 'default',
                        showSizeChanger: true,
                        onChange: onPaginationChange,
                    }}
                    scroll={{x: true}}
                    size="middle"
                />
            )}
        </Page>
    );
}
