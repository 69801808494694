import {Select} from 'antd';
import {useContext, useMemo, useState} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {useReviewTopicSearch} from '../../../../../service/reivew-analysis/review-topics/review-topics-hook';
import {debounce} from '../../../../../util/function';
import {reviewAnalysisAutocompleteLimit} from '../drawer-filter/drawer-filter-const';
import {compareSelectOptions, renderSelectOption} from '../drawer-filter/drawer-filter-helper';
import {SelectOptionType} from '../drawer-filter/drawer-filter-type';

type PropsType = {
    value?: Array<string>;
    onChange?: (value: Array<string>) => void;
};

export function TopicSelect(props: PropsType): JSX.Element {
    const {value, onChange} = props;

    const {shortLocaleName} = useLocale();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const [q, setQ] = useState<string>('');
    const {result: topicsResult, isInProgress: isTopicsInProgress} = useReviewTopicSearch(
        {
            count: reviewAnalysisAutocompleteLimit,
            page: 1,
            q,
        },
        mainFilterKey
    );
    const topicOptions: Array<SelectOptionType<string>> = useMemo((): Array<SelectOptionType<string>> => {
        return (topicsResult || [])
            .map<SelectOptionType<string>>(
                (topic: string): SelectOptionType<string> => ({
                    id: topic,
                    name: topic,
                })
            )
            .sort(compareSelectOptions<string>(shortLocaleName));
    }, [topicsResult, shortLocaleName]);

    return (
        <Select<Array<string>>
            disabled={false}
            loading={isTopicsInProgress}
            mode="multiple"
            // eslint-disable-next-line no-undefined
            notFoundContent={q === '' ? null : undefined}
            onChange={onChange}
            onSearch={debounce<[string]>(setQ, 300)}
            optionFilterProp="children"
            placeholder={<Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__TOPICS__PLACEHOLDER" />}
            value={value}
        >
            {topicOptions.map(renderSelectOption)}
        </Select>
    );
}
