import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {Spinner} from '../../../layout/spinner/spinner';

export function renderFallback(): JSX.Element {
    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Spinner position="absolute" />
        </Page>
    );
}
