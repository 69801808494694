import {z as r} from 'zod';

import {NullableType} from '../../../util/type';
import {CompanyKeyEnum} from '../company-const';

const companyGeneralDescriptionsSchema = r.object({
    short: r.string(),
    long: r.string(),
});

type CompanyGeneralDescriptionsType = r.infer<typeof companyGeneralDescriptionsSchema>;

const companyCatalogDescriptionsSchema = companyGeneralDescriptionsSchema.extend({
    catalogId: r.number(),
});

export const companyDescriptionsSchema = {
    [CompanyKeyEnum.Descriptions]: companyGeneralDescriptionsSchema,
    [CompanyKeyEnum.ExtraDescriptions]: r.array(companyCatalogDescriptionsSchema),
};

export type CompanyCatalogDescriptionsType = NullableType<CompanyGeneralDescriptionsType> & {
    catalogId: number;
};

export type CompanyDescriptionsType = {
    [CompanyKeyEnum.Descriptions]: NullableType<CompanyGeneralDescriptionsType>;
    [CompanyKeyEnum.ExtraDescriptions]: Array<CompanyCatalogDescriptionsType>;
};

export type CompanyDescriptionsServerType = CompanyDescriptionsType;
