import {Table, TablePaginationConfig} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useCompanyGroup} from '../../../../service/company-group/company-group-hook';
import {CompanyGroupType} from '../../../../service/company-group/company-group-type';
import {defaultPaginationState} from '../../../../util/hook';

import {CompaniesGroupTableAction} from './companies-group-table-action/companies-group-table-action';
import {companiesGroupColumnList} from './companies-group-table-const';
import {CompanyGroupTableRowDataType} from './companies-group-table-type';

type PropsType = {
    className?: string;
};

export function CompaniesGroupTable(props: PropsType): JSX.Element {
    const {className} = props;

    const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPaginationState);

    const {snackbar} = useSnackbar();
    const companyGroupHook = useCompanyGroup({
        count: Number(pagination.pageSize),
        page: Number(pagination.current),
    });

    const companyGroupHookList: Array<CompanyGroupType> = useMemo((): Array<CompanyGroupType> => {
        return companyGroupHook.result?.results || [];
    }, [companyGroupHook.result?.results]);

    function handlePaginationChange(newPagination: TablePaginationConfig) {
        setPagination(newPagination);

        if (pagination.current !== newPagination.current) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, newPagination.current ?? 1);
        }
    }

    const handleCompanyGroupRemove = useCallback(
        async (companyGroupId: number) => {
            const removingResult = await companyGroupHook.deleteCompanyGroup(companyGroupId);

            if (removingResult instanceof Error) {
                snackbar.error(<Locale stringKey="GROUP_OF_COMPANIES__DELETE__ERROR" />);
                return;
            }

            snackbar.success(<Locale stringKey="GROUP_OF_COMPANIES__DELETE__SUCCESSFULLY_DELETED" />);

            companyGroupHook.requestCompanyGroup();
        },
        [companyGroupHook, snackbar]
    );

    const convertCompanyTableRow = useCallback(
        (companyGroup: CompanyGroupType): CompanyGroupTableRowDataType => {
            const {companies, id, title} = companyGroup;

            return {
                name: title,
                count: companies.length,
                action: <CompaniesGroupTableAction companyGroup={companyGroup} onRemove={handleCompanyGroupRemove} />,
                key: String(id),
            };
        },
        [handleCompanyGroupRemove]
    );

    const dataList: Array<CompanyGroupTableRowDataType> = useMemo((): Array<CompanyGroupTableRowDataType> => {
        return companyGroupHookList.map(convertCompanyTableRow) || [];
    }, [companyGroupHookList, convertCompanyTableRow]);

    return (
        <div className={className}>
            <Spinner isShow={companyGroupHook.isInProgress} position="absolute" />

            <Table<CompanyGroupTableRowDataType>
                columns={companiesGroupColumnList}
                dataSource={dataList}
                onChange={handlePaginationChange}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: companyGroupHook.result?.count,
                }}
                scroll={{x: true}}
                size="middle"
            />
        </div>
    );
}
