import {Dispatch, SetStateAction, useCallback, useEffect} from 'react';

import {LegendBox} from '../legend-box/legend-box';
import {LegendWrapper} from '../legend-wrapper/legend-wrapper';
import {MultiSourceDataType} from '../multisource-chart/multi-source-chart-type';

import {getDefaultChartLegendEnabledState} from './chart-legend-helper';

type PropsType = {
    data: MultiSourceDataType;
    enabledDatasetsMap: Record<string, boolean>;
    setEnabledDatasetsMap: Dispatch<SetStateAction<Record<string, boolean>>>;
};

export function ChartLegend(props: PropsType): JSX.Element {
    const {data, enabledDatasetsMap, setEnabledDatasetsMap} = props;

    const toggleLegendBox = useCallback(
        (label: string) => {
            setEnabledDatasetsMap((previousEnabledDatasetsIndices) => {
                return {
                    ...previousEnabledDatasetsIndices,
                    [label]: !previousEnabledDatasetsIndices[label],
                };
            });
        },
        [setEnabledDatasetsMap]
    );

    useEffect(() => {
        setEnabledDatasetsMap((previousEnabledDatasetsIndices) => {
            if (Object.keys(data.datasets).some((key) => !Reflect.has(previousEnabledDatasetsIndices, key))) {
                return getDefaultChartLegendEnabledState(data);
            }

            return previousEnabledDatasetsIndices;
        });
    }, [data, setEnabledDatasetsMap]);

    return (
        <LegendWrapper>
            {data.datasets.map((dataset) => {
                return (
                    <LegendBox
                        checked={Boolean(enabledDatasetsMap[dataset.label])}
                        color={dataset.color}
                        key={dataset.label}
                        label={dataset.label}
                        onChange={() => toggleLegendBox(dataset.label)}
                    />
                );
            })}
        </LegendWrapper>
    );
}
