import {Button, Input, Modal} from 'antd';
import {useState} from 'react';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {confirmCompany} from '../../../../../service/confirmation/confirmation-api';
import {ConfirmationsSourcesType} from '../../../../../service/confirmation/confirmation-type';

type PropsType = {
    confirmationData: ConfirmationsSourcesType;
    onCodeEntered: () => void;
};

export function EnterCode(props: PropsType): JSX.Element {
    const {confirmationData, onCodeEntered} = props;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const [code, setCode] = useState<string>('');

    async function onOk() {
        try {
            await confirmCompany({code, hash: confirmationData.hash, provider: confirmationData.catalog});

            snackbar.success(<Locale stringKey="SOURCES_CONFIRMATION__ENTER_CODE__SUCCESS" />);
            onCodeEntered();
        } catch {
            snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
        }
    }

    return (
        <>
            <Button onClick={() => setIsModalOpen(true)}>
                <Locale stringKey="SOURCES_CONFIRMATION__ENTER_CODE__BUTTON" />
            </Button>
            <Modal
                okButtonProps={{
                    disabled: !code,
                }}
                okText={getLocalizedString('SOURCES_CONFIRMATION__ENTER_CODE__BUTTON')}
                onCancel={() => setIsModalOpen(false)}
                onOk={onOk}
                open={isModalOpen}
                title={<Locale stringKey="SOURCES_CONFIRMATION__ENTER_CODE__TITLE" />}
            >
                <Input
                    onChange={(event) => setCode(event.target.value)}
                    placeholder={getLocalizedString('SOURCES_CONFIRMATION__ENTER_CODE__CODE_PLACEHOLDER')}
                    value={code}
                />
            </Modal>
        </>
    );
}
