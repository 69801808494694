import {faFileArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal} from 'antd';
import Text from 'antd/lib/typography/Text';
import {SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactImageCrop, {Crop} from 'react-image-crop';

import {Locale} from '../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {CompanyImageFormType} from '../../../../../../../service/company-v2/types/company-logo-type';
import {classNames} from '../../../../../../../util/css';
import {useRefreshId} from '../../../../../../../util/hook';
import {getCropImage, getImageFromUrl, RealSizeType} from '../../../../../../../util/image';
import {catchError} from '../../../../../../../util/promise';
import {DEFAULT_INPUT_IMAGE} from '../../../../../bulk-edit-companies/form-items/company-form-items/logo-form-item-v2/input-image/images-const';

import {InputImageContent} from './input-image-content/input-image-content';
import {UploadButton} from './upload-button/upload-button';
import * as styles from './input-image.scss';

type InputImageType = CompanyImageFormType & {
    file?: File | null;
};

type PropsType = {
    className?: string;
    minWidth: number;
    minHeight: number;
    inputImage: InputImageType;
    modalHeader: JSX.Element;
    noImageText: JSX.Element;
    setInputImage: (inputImage: InputImageType) => void;
    onUpload: (image: File) => void;
    onRemove: () => void;
};

// eslint-disable-next-line max-statements
export function InputImage(props: PropsType): JSX.Element {
    const {className, minWidth, minHeight, inputImage, modalHeader, noImageText, setInputImage, onUpload, onRemove} =
        props;
    const {objectUrl, url} = inputImage;
    const targetRef = useRef<HTMLDivElement>(null);
    const drawImageUrl = url || objectUrl;
    const {refreshId, refresh} = useRefreshId();
    const {modal} = useModal();

    const [inputActive, setInputActive] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>({x: 0, y: 0, width: minWidth, height: minHeight, unit: 'px'});
    const [relativeRealSize, setRelativeRealSize] = useState<RealSizeType>({width: 1, height: 1});
    const [minLocalSize, setMinLocalSize] = useState<RealSizeType>({width: minWidth, height: minHeight});

    const [innerInputImage, setInnerInputImage] = useState<File | null>(null);

    const isLogo = useMemo(() => minWidth === minHeight, [minWidth, minHeight]);

    useEffect(() => {
        const x = crop.x < 0 ? 0 : crop.x;
        const y = crop.y < 0 ? 0 : crop.y;

        setCrop((previousState: Crop) => {
            return {
                ...previousState,
                x,
                y,
            };
        });
    }, [crop.x, crop.y]);

    const resetImage = useCallback(() => {
        if (objectUrl) {
            URL.revokeObjectURL(objectUrl);
        }

        setInputImage(DEFAULT_INPUT_IMAGE);

        refresh();

        onRemove();
    }, [refresh, objectUrl, setInputImage, onRemove]);

    const getUploadImageError = useCallback(() => {
        resetImage();
        modal.error({
            title: <Locale stringKey="COMPANY_FORM__LOGO__ERROR__INVALID_IMAGE" />,
            content: <Locale stringKey="COMPANY_FORM__LOGO__ERROR__INVALID_IMAGE_DESCRIPTION" />,
        });
    }, [modal, resetImage]);

    const changeSizeParameters = useCallback(
        (newObjectUrl: string) => {
            if (newObjectUrl) {
                getImageFromUrl(newObjectUrl)
                    .then((image: HTMLImageElement) => {
                        const currentWidth = targetRef?.current?.children[0]?.clientWidth || minWidth;
                        const currentHeight = targetRef?.current?.children[0]?.clientHeight || minHeight;
                        const {naturalWidth, naturalHeight} = image;
                        const ratioOfRealToMinWidth = naturalWidth > minWidth ? naturalWidth / minWidth : 1;
                        const ratioOfRealToMinHeight = naturalHeight > minHeight ? naturalHeight / minHeight : 1;

                        if (isLogo) {
                            setRelativeRealSize({
                                width: naturalWidth / currentWidth,
                                height: naturalWidth / currentWidth,
                            });
                        } else {
                            setRelativeRealSize({
                                width: naturalWidth / currentWidth,
                                height: naturalHeight / currentHeight,
                            });
                        }

                        setMinLocalSize({
                            width: currentWidth / ratioOfRealToMinWidth,
                            height: currentHeight / ratioOfRealToMinHeight,
                        });
                        setCrop((previousState: Crop) => {
                            return {
                                ...previousState,
                                x: 0,
                                y: 0,
                                width: currentWidth / ratioOfRealToMinWidth,
                                height: currentHeight / ratioOfRealToMinHeight,
                            };
                        });
                    })
                    .catch(() => getUploadImageError());
            }
        },
        [getUploadImageError, isLogo, minHeight, minWidth]
    );

    const resizeWindow = useCallback(() => {
        if (objectUrl) {
            changeSizeParameters(objectUrl);
        }
    }, [objectUrl, changeSizeParameters]);

    useEffect(() => {
        window.addEventListener('resize', resizeWindow);

        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, [resizeWindow]);

    const handleSetCrop = useCallback(
        (newCrop: Crop) => {
            let width = newCrop.width < minLocalSize.width ? minLocalSize.width : newCrop.width;
            let height = newCrop.height < minLocalSize.height ? minLocalSize.height : newCrop.height;
            const currentWidth = targetRef?.current?.children[0]?.clientWidth || minWidth;
            const currentHeight = targetRef?.current?.children[0]?.clientHeight || minHeight;

            if (isLogo) {
                const size = newCrop.width > newCrop.height ? newCrop.width : newCrop.height;
                const minSide = currentWidth > currentHeight ? currentHeight : currentWidth;
                const minSideSize = size > minSide ? minSide : size;

                width = minSideSize;
                height = minSideSize;
            } else {
                height = Math.ceil((width * 9) / 16);
            }

            setCrop({
                ...newCrop,
                width,
                height,
            });
        },
        [minLocalSize.width, minLocalSize.height, minWidth, minHeight, isLogo]
    );

    const applyChanges = useCallback(() => {
        getCropImage(drawImageUrl || '', crop, relativeRealSize)
            .then((blob: Blob) => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                }

                const filename: string = innerInputImage?.name || JSON.stringify(crop);

                const croppedFile = new File([blob], filename);

                setInputImage({
                    tempId: null,
                    objectUrl: URL.createObjectURL(croppedFile),
                    url: '',
                    file: croppedFile,
                });

                setIsModalOpen(false);

                onUpload(croppedFile);
            })
            .catch(catchError);
    }, [drawImageUrl, crop, objectUrl, innerInputImage?.name, setInputImage, onUpload, relativeRealSize]);

    const handleOnChange = useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
            const {files} = event.currentTarget;
            const newFile: File | null = files && files[0] ? files[0] : null;

            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }

            setInputImage({
                tempId: null,
                url: '',
                objectUrl: newFile ? URL.createObjectURL(newFile) : '',
                file: newFile,
            });

            refresh();
        },
        [refresh, setInputImage, objectUrl]
    );

    useEffect(() => {
        if (!objectUrl) {
            return;
        }

        getImageFromUrl(objectUrl)
            .then((image: HTMLImageElement) => {
                const {naturalWidth, naturalHeight} = image;
                const isValid = naturalWidth >= minWidth && naturalHeight >= minHeight;

                if (!isValid) {
                    getUploadImageError();
                }
            })
            .catch(() => getUploadImageError());
    }, [objectUrl, getUploadImageError, relativeRealSize.width, relativeRealSize.height, minWidth, minHeight]);

    const handleModalCancel = useCallback(() => {
        resetImage();
        setIsModalOpen(false);
    }, [resetImage]);

    useEffect(() => {
        const {file} = inputImage;

        if (file) {
            setInnerInputImage(file);
            changeSizeParameters(objectUrl || URL.createObjectURL(file));
        }
    }, [changeSizeParameters, inputImage, objectUrl]);

    return (
        <>
            <InputImageContent
                className={className}
                drawImageUrl={drawImageUrl}
                noImageText={noImageText}
                resetImage={resetImage}
                setIsModalOpen={setIsModalOpen}
            />

            <Modal
                cancelText={<Locale stringKey="POPUP__BUTTON__CANCEL" />}
                centered
                destroyOnClose
                maskClosable={false}
                okText={<Locale stringKey="BUTTON__UPLOAD" />}
                onCancel={handleModalCancel}
                onOk={applyChanges}
                open={isModalOpen}
                title={<Locale stringKey="COMPANY_FORM__LOGO__UPLOAD_AND_CROP" />}
                width={1000}
            >
                <div
                    className={classNames(
                        styles.drop_photo_place,
                        inputActive ? styles.drop_photo_place_active : '',
                        drawImageUrl ? styles.drop_photo_place_editing : ''
                    )}
                    ref={targetRef}
                >
                    {drawImageUrl ? (
                        <ReactImageCrop
                            crop={crop}
                            minHeight={minLocalSize.height}
                            minWidth={minLocalSize.width}
                            onChange={setCrop}
                            onComplete={handleSetCrop}
                            src={drawImageUrl}
                        />
                    ) : (
                        <>
                            <input
                                accept="image/jpeg, image/jpg, image/png, image/gif"
                                className={styles.drop_photo_place__input}
                                key={refreshId}
                                onChange={handleOnChange}
                                onDragEnter={() => setInputActive(true)}
                                onDragLeave={() => setInputActive(false)}
                                type="file"
                            />

                            <div className={styles.drop_photo_place__left}>
                                <h4 className={styles.drop_photo_place__header}>{modalHeader}</h4>

                                <Text className={styles.drop_photo_place__allowed_formats}>
                                    <Locale
                                        stringKey="COMPANY_FORM__LOGO__RESTRICTIONS"
                                        valueMap={{minWidth, minHeight}}
                                    />
                                </Text>
                            </div>

                            <div className={styles.drop_photo_place__right}>
                                <div className={styles.drop_photo_place__input_focus}>
                                    <FontAwesomeIcon icon={faFileArrowUp} />
                                </div>

                                <UploadButton className={styles.drop_photo_place__upload_button} />

                                <Text className={styles.drop_photo_place__text}>
                                    <Locale stringKey="COMPANY_FORM__LOGO__DRAG_FILE_HERE" />
                                </Text>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}
