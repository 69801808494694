import {Input} from 'antd';
import {FormListProps} from 'antd/lib/form';

import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {Form} from '../../../../../../../typings/antd';

import * as styles from './email-form-item.scss';

type PropsType = {
    name: FormListProps['name'];
};

export function EmailFormItem(props: PropsType): JSX.Element {
    const {name} = props;

    const {emailFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();

    return (
        <div className={styles.company_email}>
            <Form.Item
                label={<Locale stringKey="COMPANY_FORM__CONTACT_INFO__EMAIL__LABEL" />}
                name={name}
                rules={[emailFieldRule]}
            >
                <Input
                    placeholder={getLocalizedString('COMPANY_FORM__CONTACT_INFO__EMAIL__PLACEHOLDER')}
                    size="large"
                />
            </Form.Item>
        </div>
    );
}
