import {classNames} from '../../../util/css';

import * as styles from './rating-star.scss';

type PropsType = {
    className?: string;
    fillPercent: number;
    width: number;
    height: number;
};

export function RatingStar(props: PropsType): JSX.Element {
    const {className, fillPercent, width, height} = props;

    const defaultStarWidth = 16;
    const endStarWidth = (defaultStarWidth * fillPercent) / 100;

    return (
        <div className={classNames(styles.rating_star_wrapper, className)} style={{width, height}}>
            {fillPercent !== 100 && (
                <svg
                    className={styles.rating_star__empty}
                    fill="currentColor"
                    height="12"
                    viewBox="0 0 16 16"
                    width="12"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M14.6695 5.65267L10.6111 5.061L8.79725 1.38322C8.47225 0.727669 7.53058 0.719335 7.2028 1.38322L5.38892 5.061L1.33058 5.65267C0.602805 5.75822 0.311138 6.65545 0.838916 7.16934L3.77503 10.0304L3.08058 14.0721C2.95558 14.8027 3.72503 15.3499 4.36947 15.0082L8.00003 13.0999L11.6306 15.0082C12.275 15.3471 13.0445 14.8027 12.9195 14.0721L12.225 10.0304L15.1611 7.16934C15.6889 6.65545 15.3972 5.75822 14.6695 5.65267ZM10.7945 9.56378L11.4528 13.4082L8.00003 11.5943L4.54725 13.4082L5.20558 9.56378L2.41114 6.84156L6.27225 6.28045L8.00003 2.78045L9.72781 6.28045L13.5889 6.84156L10.7945 9.56378Z" />
                </svg>
            )}

            {fillPercent > 0 && (
                <svg
                    className={styles.rating_star__filled}
                    fill="currentColor"
                    height="16"
                    style={{width: `${fillPercent}%`}}
                    viewBox={`0 0 ${endStarWidth} 16`}
                    width={endStarWidth}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M7.20244 1.38335L5.38855 5.06112L1.33022 5.65279C0.602438 5.75835 0.310772 6.65557 0.838549 7.16946L3.77466 10.0306L3.08022 14.0722C2.95522 14.8028 3.72466 15.35 4.36911 15.0083L7.99966 13.1L11.6302 15.0083C12.2747 15.3472 13.0441 14.8028 12.9191 14.0722L12.2247 10.0306L15.1608 7.16946C15.6886 6.65557 15.3969 5.75835 14.6691 5.65279L10.6108 5.06112L8.79688 1.38335C8.47188 0.727791 7.53022 0.719458 7.20244 1.38335Z" />
                </svg>
            )}
        </div>
    );
}
