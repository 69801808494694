import {Steps} from 'antd';
import {useState} from 'react';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useAccountMatchingCompanies} from '../../../../../service/matching/matching-hook';
import {ProvidersIdsEnum} from '../../../../../util/type';
import {AccountsMatchingForm} from '../accounts-matching-form/accounts-matching-form';

import {GoogleAccountSelector} from './google-account-selector';

type PropsType = {
    brandId: number;
    companyId?: number;
};

export function GoogleAccountsMatching(props: PropsType): JSX.Element {
    const {brandId, companyId} = props;

    const [current, setCurrent] = useState<number>(0);
    const [accountOauthId, setAccountOauthId] = useState<number | null>(null);

    const {getLocalizedString} = useLocale();

    const accountMatchingData = useAccountMatchingCompanies({
        oauthAccountId: accountOauthId,
        brandId,
        providerId: Number(ProvidersIdsEnum.google),
        companyId,
    });

    function next() {
        setCurrent(current + 1);
    }

    return (
        <>
            <Steps current={current}>
                <Steps.Step key={0} title={getLocalizedString('COMPANY_MATCHING__STEP__SELECT_ACCOUNT')} />
                <Steps.Step key={1} title={getLocalizedString('COMPANY_MATCHING__STEP__MATCH_LOCATIONS')} />
            </Steps>

            {current === 0 && (
                <GoogleAccountSelector
                    brandId={brandId}
                    onSelectAccount={(accountId) => {
                        setAccountOauthId(accountId);
                        next();
                    }}
                />
            )}

            {current === 1 && accountOauthId && (
                <AccountsMatchingForm
                    accountMatchingData={accountMatchingData}
                    accountOauthId={accountOauthId}
                    providerId={Number(ProvidersIdsEnum.google)}
                />
            )}
        </>
    );
}
