import {lazy, Suspense} from 'react';

import {Page} from '../../../layout/page/page';
import {Spinner} from '../../../layout/spinner/spinner';

const DashboardLazy = lazy(() => import('./pages/dashboard/dashboard'));

export function ReviewsAnalysisDashboard(): JSX.Element {
    return (
        <Page>
            <Suspense fallback={<Spinner position="absolute" />}>
                <DashboardLazy />
            </Suspense>
        </Page>
    );
}

export {ActivationBasePage as ReviewsAnalysisActivationBasePage} from './pages/activation-base-page/activation-base-page';
export {ActivationFullPage as ReviewsAnalysisActivationFullPage} from './pages/activation-full-page/activation-full-page';
export {CitiesPage as ReviewsAnalysisCitiesPage} from './pages/cities-page/cities-page';
export {LocationsPage as ReviewsAnalysisLocationsPage} from './pages/companies-page/companies-page';
export {ConcordancePage as ReviewsAnalysisConcordancePage} from './pages/concordance-page/concordance-page';
export {EmployeesPage as ReviewsAnalysisEmployeesPage} from './pages/employees-page/employees-page';
export {KeywordsAndTopicsPage as ReviewsAnalysisKeywordsAndTopicsPage} from './pages/keywords-and-topics-page/keywords-and-topics-page';
export {PhrasesPage as ReviewsAnalysisPhrasesPage} from './pages/phrases-page/phrases-page';
export {TopicCreatePage as ReviewsAnalysisTopicCreatePage} from './pages/topic-create-page/topic-create-page';
export {TopicPage as ReviewsAnalysisTopicPage} from './pages/topic-page/topic-page';
export {TopicUpdatePage as ReviewsAnalysisTopicUpdatePage} from './pages/topic-update-page/topic-update-page';
