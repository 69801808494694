import {useMemo} from 'react';

import {
    useTechnicalSupportFields,
    useTechnicalSupportFilter,
    useTechnicalSupportTickets,
} from '../../../../../../service/technical-support/technical-support-hook';
import {TechnicalSupportFieldsEnum} from '../../../../../../service/technical-support/technical-support-type';
import {TechnicalSupportFilter} from '../technical-support-filter/technical-support-filter';
import {TechnicalSupportTable} from '../technical-support-table/technical-support-table';
import {TechnicalSupportTableEmpty} from '../technical-support-table-empty/technical-support-table-empty';

export function TechnicalSupportContent(): JSX.Element {
    const {filter, updateFilter, resetFilter} = useTechnicalSupportFilter();

    const {isLoading: isFieldsLoading} = useTechnicalSupportFields();

    const {data, isLoading, pagination, refetch} = useTechnicalSupportTickets(filter);

    const hasFilters = useMemo(() => {
        const activeFilterFields = {
            [TechnicalSupportFieldsEnum.Status]: filter[TechnicalSupportFieldsEnum.Status].length > 0,
            creationDate: Boolean(filter?.creationDate[0]),
            [TechnicalSupportFieldsEnum.ClientCategory]: Boolean(filter[TechnicalSupportFieldsEnum.ClientCategory]),
            [TechnicalSupportFieldsEnum.Type]: Boolean(filter[TechnicalSupportFieldsEnum.Type]),
            [TechnicalSupportFieldsEnum.IsPublic]: filter[TechnicalSupportFieldsEnum.IsPublic] !== null,
            [TechnicalSupportFieldsEnum.Creator]: filter[TechnicalSupportFieldsEnum.Creator].length > 0,
            [TechnicalSupportFieldsEnum.CreatorGroup]: filter[TechnicalSupportFieldsEnum.CreatorGroup].length > 0,
            [TechnicalSupportFieldsEnum.HasNewAnswers]: filter[TechnicalSupportFieldsEnum.HasNewAnswers] !== null,
            q: filter.q,
        };

        return Object.values(activeFilterFields).some(Boolean);
    }, [filter]);

    const isEmptyWithoutFilters = !hasFilters && data?.results.length === 0;
    const isEmptyWithFilters = hasFilters && data?.results.length === 0;

    return (
        <>
            {!isEmptyWithoutFilters && (
                <TechnicalSupportFilter filter={filter} resetFilter={resetFilter} updateFilter={updateFilter} />
            )}

            {!(isEmptyWithFilters || isEmptyWithoutFilters) && (
                <TechnicalSupportTable
                    data={data}
                    isFieldsLoading={isFieldsLoading}
                    isLoading={isLoading}
                    pagination={pagination}
                    refetch={refetch}
                />
            )}

            {(isEmptyWithoutFilters || isEmptyWithFilters) && <TechnicalSupportTableEmpty hasFilters={hasFilters} />}
        </>
    );
}
