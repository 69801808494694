import {fetchNoContent} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {rootApiUrl} from '../../api/api-const';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

export function requestReviewsAndAnswersExport(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<void> {
    return fetchNoContent(
        `${rootApiUrl}/cp/reviews/statistics/export/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`
    );
}
