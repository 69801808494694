import {Table, TablePaginationConfig} from 'antd';
import {SorterResult} from 'antd/lib/table/interface';

import {header_height} from '../../../../../css/var-export.scss';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../provider/locale/locale';
import {useSystem} from '../../../../../provider/system/system-hook';
import {useLocalPackSearchPhrasesStatistics} from '../../../../../service/local-pack/search-phrase-analytics/local-pack-search-phrases-statistics-hook';
import {LocalPackSearchPhrasesStatisticsItemType} from '../../../../../service/local-pack/search-phrase-analytics/local-pack-search-phrases-statistics-type';
import {LocalPackFilterType} from '../../local-pack-filter/local-pack-filter-type';

import {
    getLocalPackSearchPhrasesStatisticsColumns,
    LOCAL_PACK_SEARCH_PHRASES_STATISTICS_TABLE_WIDTH,
} from './local-pack-search-phrases-statistics-table-helper';
import * as styles from './local-pack-search-phrases-statistics-table.scss';

type PropsType = {
    filter: LocalPackFilterType;
};

export function LocalPackSearchPhrasesStatisticsTable(props: PropsType): JSX.Element {
    const {filter} = props;
    const {data, isLoading, pagination, updateSorter} = useLocalPackSearchPhrasesStatistics(filter);

    const isEmptyTopFive = !data?.results.some((entry) => {
        return entry.top5.some((topFive) => {
            return topFive.results.length === 0;
        });
    });

    const systemContext = useSystem();
    const {screen} = systemContext;
    const {width} = screen;

    function onTableChange(
        newPagination: TablePaginationConfig,
        _filters: unknown,
        sorter:
            | SorterResult<LocalPackSearchPhrasesStatisticsItemType>
            | Array<SorterResult<LocalPackSearchPhrasesStatisticsItemType>>
    ) {
        if (pagination.page === newPagination.current && pagination.pageSize === newPagination.pageSize) {
            const firstSortingField = Array.isArray(sorter) ? sorter?.[0] : sorter;

            updateSorter({
                sorterField: firstSortingField?.field,
                sorterDirection: firstSortingField?.order,
            });
            pagination.onChange(1, newPagination.pageSize);
            return;
        }

        pagination.onChange(newPagination.current || 1, newPagination.pageSize);
    }

    return (
        <PageCard title={<Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS" />}>
            <Table<LocalPackSearchPhrasesStatisticsItemType>
                bordered
                className={styles.LocalPackSearchPhrasesStatisticsTable}
                columns={getLocalPackSearchPhrasesStatisticsColumns(width, isEmptyTopFive)}
                dataSource={data?.results || []}
                loading={isLoading}
                onChange={onTableChange}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: data?.count || 0,
                    onChange: pagination.onChange,
                }}
                rowKey={(entry) =>
                    `${entry.company.id}_${entry.rule.ruleId}_${entry.rule.searchQuery}_${entry.lastSearchDate}`
                }
                scroll={{
                    x: LOCAL_PACK_SEARCH_PHRASES_STATISTICS_TABLE_WIDTH,
                }}
                sticky={{
                    offsetHeader: Number.parseFloat(header_height),
                }}
            />
        </PageCard>
    );
}
