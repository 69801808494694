import {Form} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {DESCRIPTION_LIMITS} from '../../../../company-form/page-container/widgets/descriptions/descriptions-widget-const';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.fullDescription> & {
    className?: string;
};

export function FullDescriptionFormItem(props: PropsType): JSX.Element {
    const {value, onChange, className, errorMessage} = props;

    const {getLocalizedString} = useLocale();

    return (
        <Form.Item help={errorMessage} validateStatus={errorMessage ? 'error' : ''}>
            <TextArea
                className={className}
                maxLength={DESCRIPTION_LIMITS.long}
                onChange={(event) => onChange(event.currentTarget.value)}
                placeholder={getLocalizedString('ADDITIONAL_INFO__FULL_DESCRIPTION__PLACEHOLDER')}
                showCount
                value={value}
            />
        </Form.Item>
    );
}
