import 'linkify-plugin-hashtag';

import Paragraph from 'antd/lib/typography/Paragraph';
import Linkify from 'linkify-react';
import {Opts} from 'linkifyjs';
import {ReactNode, useState} from 'react';

import {classNames} from '../../../../../../../../../util/css';

import * as styles from './expandable-text-preview.scss';

type PropsType = {
    text: ReactNode;
    rows?: number;
    expandButton: ReactNode;
    className?: string;
};

export function ExpandableTextPreview(props: PropsType): JSX.Element {
    const {text, rows = 5, expandButton, className} = props;
    const [showFullText, setShowFullText] = useState(false);
    const [hasEllipsis, setHasEllipsis] = useState(false);

    const options: Opts = {
        target: '_blank',
        truncate: 30,
        events: {
            onClick: (event: MouseEvent) => {
                event.stopPropagation();
            },
        },
    };

    const textContent = showFullText ? <Linkify options={options}>{text}</Linkify> : text;

    return (
        <Paragraph
            className={classNames(styles.ExpandableTextPreview, className)}
            ellipsis={{
                rows,
                expandable: true,
                onExpand: () => setShowFullText(true),
                onEllipsis: () => setHasEllipsis(true),
                symbol: expandButton,
            }}
        >
            {hasEllipsis ? textContent : <Linkify options={options}>{text}</Linkify>}
        </Paragraph>
    );
}
