import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover, Table, TablePaginationConfig} from 'antd';
import {useCallback, useMemo} from 'react';

import {Text} from '../../../../component/text/text';
import {orange70, orange90} from '../../../../css/var-export.scss';
import {PageCard} from '../../../../layout/page-card/page-card';
import {CatalogCell} from '../../../../layout/table/catalog-cell/catalog-cell';
import {TrendCell} from '../../../../layout/table/trend-cell/trend-cell';
import {Trend} from '../../../../layout/trend/trend';
import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../provider/locale/locale';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {OnlinePresencePhrasesItemType} from '../../../../service/online-presence/online-presence-type';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {PaginationHookType} from '../../../../util/pagination-hook/pagination-hook-type';

import {getIsLowValue, PHRASES_LOW_VALUE_THRESHOLD} from './phrases-table-helper';
import {OnlinePresenceRowType} from './phrases-table-type';
import * as styles from './phrases-table.scss';

type PropsType = {
    isCompareMode: boolean;
    data: Array<OnlinePresencePhrasesItemType>;
    count: number | null;
    isInProgress: boolean;
    pagination: PaginationHookType | false;
    labels?: Array<string>;
};

export function PhrasesTable(props: PropsType): JSX.Element {
    const {isCompareMode, data, isInProgress, pagination, count, labels} = props;

    const {getFormattedNumber} = useFormat();

    const tableDataSource: Array<OnlinePresenceRowType> = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map((item) => {
            return {
                ...item,
                children: item.valuesByCatalog.map((catalogItem): OnlinePresenceRowType => {
                    return {
                        ...catalogItem,
                        delta: catalogItem.delta,
                        dynamicValues:
                            item.dynamicValuesByCatalog.find((dynamicByCatalog) => {
                                return dynamicByCatalog.catalogId === catalogItem.catalogId;
                            })?.values || [],
                        searchPhrase: item.searchPhrase,
                    };
                }),
            };
        });
    }, [data]);

    const paginationOptions: TablePaginationConfig | false = useMemo(() => {
        if (!pagination) {
            return false;
        }

        return {
            ...pagination,
            total: pagination.total,
            pageSize: pagination.pageSize,
            current: pagination.page,
            size: 'default',
            showSizeChanger: true,
            onChange: pagination.onChange,
        };
    }, [pagination]);

    const getRowKey = useCallback((record: OnlinePresenceRowType) => `${record.searchPhrase}__${record.catalogId}`, []);

    return (
        <PageCard>
            <div className={styles.PhrasesTable_totalResults}>
                <Locale
                    stringKey="ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__TOTAL_RESULTS"
                    valueMap={{
                        count: (
                            <span className={styles.PhrasesTable_totalResults__value} key="value">
                                {count || ''}
                            </span>
                        ),
                    }}
                />
            </div>
            <Table<OnlinePresenceRowType>
                className={styles.PhrasesTable}
                dataSource={tableDataSource || []}
                expandable={{
                    defaultExpandAllRows: !pagination,
                    onExpand: (expanded) => expanded && track(AnalyticsTarget.OnlinePresence.ClickPlus),
                }}
                loading={isInProgress}
                pagination={paginationOptions}
                rowClassName={(item) => {
                    if (item.catalogId) {
                        return styles.PhrasesTable_catalogRow;
                    }

                    return styles.PhrasesTable_mainRow;
                }}
                rowKey={getRowKey}
                scroll={{x: true}}
                size="middle"
            >
                <Table.Column<OnlinePresenceRowType>
                    render={(_value: unknown, source: OnlinePresenceRowType) => {
                        if (source.catalogId) {
                            return <CatalogCell catalogId={source.catalogId} />;
                        }

                        return source.searchPhrase;
                    }}
                    title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__PHRASE" />}
                    width={240}
                />

                <Table.Column<OnlinePresenceRowType>
                    align="right"
                    render={(_value: unknown, source: OnlinePresenceRowType): JSX.Element => {
                        const isLowValue = getIsLowValue(source.value);

                        return (
                            <span
                                className={classNames(styles.PhrasesTable_value, {
                                    [styles.PhrasesTable__lowValue]: isLowValue,
                                })}
                            >
                                {isLowValue ? `<${PHRASES_LOW_VALUE_THRESHOLD}` : getFormattedNumber(source.value)}
                            </span>
                        );
                    }}
                    title={
                        isCompareMode ? (
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__MAIN" />
                        ) : (
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__MAIN_NO_COMPARE" />
                        )
                    }
                    width={128}
                />

                {isCompareMode && (
                    <Table.Column<OnlinePresenceRowType>
                        align="right"
                        render={(_value: unknown, source: OnlinePresenceRowType): JSX.Element | null => {
                            if (
                                !source.comparedValue ||
                                source.value <= PHRASES_LOW_VALUE_THRESHOLD ||
                                source.comparedValue <= PHRASES_LOW_VALUE_THRESHOLD
                            ) {
                                return <span />;
                            }

                            return (
                                <div className={styles.PhrasesTable_trendContainer}>
                                    <Trend delta={source.delta} />
                                </div>
                            );
                        }}
                        title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__DELTA" />}
                        width={128}
                    />
                )}
                {isCompareMode && (
                    <Table.Column<OnlinePresenceRowType>
                        align="right"
                        render={(_value: unknown, source: OnlinePresenceRowType): JSX.Element => {
                            const compareValue = source.comparedValue || 0;
                            const isLowValue = getIsLowValue(compareValue);

                            return (
                                <span
                                    className={classNames(styles.PhrasesTable_value, {
                                        [styles.PhrasesTable__compareValue]: !isLowValue,
                                        [styles.PhrasesTable__lowValue]: isLowValue,
                                    })}
                                >
                                    {isLowValue ? `<${PHRASES_LOW_VALUE_THRESHOLD}` : getFormattedNumber(compareValue)}
                                </span>
                            );
                        }}
                        title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__RANGE_PICKER_LABEL__COMPARE" />}
                        width={128}
                    />
                )}

                <Table.Column<OnlinePresenceRowType> title="" width={56} />

                <Table.Column<OnlinePresenceRowType>
                    key="trend"
                    render={(_value: unknown, record: OnlinePresenceRowType): JSX.Element => (
                        <TrendCell
                            className={styles.PhrasesTable_trendChart}
                            datasetLabel={record.searchPhrase}
                            fillColor={orange90}
                            labels={labels}
                            strokeColor={orange70}
                            values={record.dynamicValues}
                        />
                    )}
                    title={
                        <div className={styles.DynamicValuesTitle}>
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__TREND" />
                            <Popover
                                content={
                                    <PopoverTextWrapper>
                                        <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__POPOVER_TEXT" />
                                    </PopoverTextWrapper>
                                }
                                placement="bottomLeft"
                                title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__SEARCH_PHRASES__POPOVER_TITLE" />}
                                trigger="hover"
                            >
                                <Text large secondary>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Text>
                            </Popover>
                        </div>
                    }
                    width={240}
                />
            </Table>
        </PageCard>
    );
}
