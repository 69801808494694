import {faTable} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Button, Calendar, CalendarProps} from 'antd';
import {CalendarMode} from 'antd/lib/calendar/generateCalendar';
import dayjs, {Dayjs} from 'dayjs';
import {ReactNode, useCallback, useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {usePostsCalendar} from '../../../../service/posts/posts-hooks';
import {CalendarPostStatusEnum} from '../../../../service/posts/posts-types';
import * as postsStyles from '../posts.scss';

import {BADGE_COLOR_BY_CALENDAR_POST_STATUS, TEXT_IN_YEAR_CARD_BY_POST_STATUS} from './posts-calendar-const';
import {extractPostsByIndexForMonthView, extractPostsByIndexForYearView} from './posts-calendar-helper';
import {CalendarPanelType} from './posts-calendar-type';
import * as styles from './posts-calendar.scss';

export function PostsCalendar(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const [panel, setPanel] = useState<CalendarPanelType>({date: dayjs(), mode: 'month'});

    const {result: calendarPosts, isInProgress, processError} = usePostsCalendar(panel);

    function handlePanelChange(date: Dayjs, mode: CalendarMode) {
        setPanel({date, mode});
    }

    function handleDateSelect(date: Dayjs) {
        // if we are on year mode and try to change the year then we must stay on year mode
        if (panel.mode === 'year' && panel.date.year() !== date.year()) {
            return;
        }

        if (panel.mode === 'year') {
            setPanel({date, mode: 'month'});
        }
    }

    const renderDateCell = useCallback(
        (date: Dayjs): ReactNode => {
            const isSameMonth = date.month() === panel.date.month();

            if (!isSameMonth) {
                return null;
            }

            const posts = extractPostsByIndexForMonthView(calendarPosts, date.date());

            if (!posts) {
                return null;
            }

            return (
                <ul className={styles.PostCalendar_list}>
                    {posts.map((post) => {
                        const link = generatePath(appRoute.postsManagementViewPost.path, {postId: post.id});

                        return (
                            <li key={post.id} title={post.name}>
                                <NavigationLink to={link}>
                                    <Button className={styles.PostCalendar_listButtonLink} size="small" type="link">
                                        <Badge
                                            className={styles.PostCalendar_listItem}
                                            color={BADGE_COLOR_BY_CALENDAR_POST_STATUS[post.status]}
                                            text={post.name}
                                        />
                                    </Button>
                                </NavigationLink>
                            </li>
                        );
                    })}
                </ul>
            );
        },
        [calendarPosts, panel]
    );

    const renderMonthCell = useCallback(
        (date: Dayjs): ReactNode => {
            const posts = extractPostsByIndexForYearView(calendarPosts, date.month() + 1);

            if (!posts) {
                return null;
            }

            return (
                <ul className={styles.PostCalendar_list}>
                    {posts.map((post) => {
                        const text = getLocalizedString(TEXT_IN_YEAR_CARD_BY_POST_STATUS[post.status], {
                            count: String(post.count),
                        });

                        return (
                            <li key={post.status} title={String(text)}>
                                <Badge
                                    className={styles.PostCalendar_listItem}
                                    color={BADGE_COLOR_BY_CALENDAR_POST_STATUS[post.status]}
                                    text={text}
                                />
                            </li>
                        );
                    })}
                </ul>
            );
        },
        [calendarPosts, getLocalizedString]
    );

    const renderCell = useCallback(
        (date: Dayjs, info: Parameters<NonNullable<CalendarProps<Dayjs>['cellRender']>>[1]): ReactNode => {
            if (info.type === 'month') {
                return renderMonthCell(date);
            }

            if (info.type === 'date') {
                return renderDateCell(date);
            }

            return null;
        },
        [renderDateCell, renderMonthCell]
    );

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__POSTS_CALENDAR')} />

            <BreadCrumbs list={[{path: appRoute.postsManagement.path, titleLangKey: 'CATEGORY_NAME__POSTS'}]} />

            {processError ? (
                <AlertFallback />
            ) : (
                <>
                    <PageHeader>
                        <Locale stringKey="POSTS__CALENDAR_HEADER" />

                        <div className={postsStyles.posts__header__button_wrapper}>
                            <NavigationLink className={styles.PostCalendar_link} to={appRoute.postsManagement.path}>
                                <Button
                                    className={styles.PostCalendar_button}
                                    icon={<FontAwesomeIcon className={styles.PostCalendar_icon} icon={faTable} />}
                                    type="default"
                                >
                                    <Locale stringKey="POSTS__TABLE_PAGE" />
                                </Button>
                            </NavigationLink>
                            <NavigationLink to={appRoute.postsManagementCreatePost.path}>
                                <Button type="primary">
                                    <Locale stringKey="POSTS__CREATE_POST" />
                                </Button>
                            </NavigationLink>
                        </div>
                    </PageHeader>

                    <div className={styles.PostCalendar_badges}>
                        <Badge
                            color={BADGE_COLOR_BY_CALENDAR_POST_STATUS[CalendarPostStatusEnum.publishPlanned]}
                            text={<Locale stringKey="POSTS__CALENDAR_BADGE__PUBLISH_PLANNED" />}
                        />
                        <Badge
                            color={BADGE_COLOR_BY_CALENDAR_POST_STATUS[CalendarPostStatusEnum.deletePlanned]}
                            text={<Locale stringKey="POSTS__CALENDAR_BADGE__DELETE_PLANNED" />}
                        />
                        <Badge
                            color={BADGE_COLOR_BY_CALENDAR_POST_STATUS[CalendarPostStatusEnum.published]}
                            text={<Locale stringKey="POSTS__CALENDAR_BADGE__SENT" />}
                        />
                        <Badge
                            color={BADGE_COLOR_BY_CALENDAR_POST_STATUS[CalendarPostStatusEnum.refreshPlanned]}
                            text={<Locale stringKey="POSTS__CALENDAR_BADGE__REFRESH_PLANNED" />}
                        />
                    </div>

                    <div className={styles.PostCalendar_content}>
                        <Calendar
                            cellRender={renderCell}
                            mode={panel.mode}
                            onPanelChange={handlePanelChange}
                            onSelect={handleDateSelect}
                            value={panel.date}
                        />

                        {isInProgress && <Spinner className={styles.PostCalendar_spinner} position="absolute" />}
                    </div>
                </>
            )}
        </Page>
    );
}
