import {QueryMapType} from '../../../../util/type';
import {PostsFilterQueriesType, PostsFilterStateUrlQueryNameEnum} from '../posts-filter/posts-filter-type';

import {defaultPostsQueriesValues} from './posts-state-const';

export function getPostsStateFromUrlQuery(urlQueries: QueryMapType): PostsFilterQueriesType {
    return {
        [PostsFilterStateUrlQueryNameEnum.statuses]:
            urlQueries[PostsFilterStateUrlQueryNameEnum.statuses] ||
            defaultPostsQueriesValues[PostsFilterStateUrlQueryNameEnum.statuses],
        [PostsFilterStateUrlQueryNameEnum.createdAtBefore]:
            urlQueries[PostsFilterStateUrlQueryNameEnum.createdAtBefore] ||
            defaultPostsQueriesValues[PostsFilterStateUrlQueryNameEnum.createdAtBefore],
        [PostsFilterStateUrlQueryNameEnum.createdAtAfter]:
            urlQueries[PostsFilterStateUrlQueryNameEnum.createdAtAfter] ||
            defaultPostsQueriesValues[PostsFilterStateUrlQueryNameEnum.createdAtAfter],
        [PostsFilterStateUrlQueryNameEnum.catalogs]:
            urlQueries[PostsFilterStateUrlQueryNameEnum.catalogs] ||
            defaultPostsQueriesValues[PostsFilterStateUrlQueryNameEnum.catalogs],
    };
}
