export const enDashboardTranslations = {
    DASHBOARD_PAGE__TITLE: 'Overview',
    DASHBOARD_PAGE__SUB_TITLE: 'Current information about your business',
    DASHBOARD_PAGE__REVIEWS_SUMMARY__HEADER: 'Reviews summary',
    DASHBOARD_PAGE__REVIEWS_TITLE: 'Last reviews collected',
    DASHBOARD_PAGE__REVIEWS__STATS_HEADER: 'Statistics',
    DASHBOARD_PAGE__REVIEWS__STATS_DAYS: 'last {numberOfDays} days',
    DASHBOARD_PAGE__REVIEWS__STATS_ALL: 'Total',
    DASHBOARD_PAGE__REVIEWS__STATS_WITHOUT_ANSWER: 'No response',
    DASHBOARD_PAGE__REVIEWS__STATS_ANSWERS__SINGULAR: '{count} response',
    DASHBOARD_PAGE__REVIEWS__STATS_ANSWERS__FEW: '{count} response',
    DASHBOARD_PAGE__REVIEWS__STATS_ANSWERS__MANY: '{count} replies',
    DASHBOARD_PAGE__REVIEWS__FOOTER_TEXT: 'You can reply to reviews on the page',
    DASHBOARD_PAGE__REVIEWS__FOOTER_LINK_TEXT: 'Reviews',
    DASHBOARD_PAGE__REVIEWS__ATTACHMENTS__SINGULAR: '{count} attachment',
    DASHBOARD_PAGE__REVIEWS__ATTACHMENTS__FEW: '{count} attachments',
    DASHBOARD_PAGE__REVIEWS__ATTACHMENTS__MANY: '{count} attachments',
    DASHBOARD_PAGE__WORKLOAD__TITLE: 'Graph of workload by hours and week days',
    DASHBOARD_PAGE__WORKLOAD__FOOTER: 'Workload data from Google Business Profile.',
    DASHBOARD_PAGE__WORKLOAD__EMPTY: 'Insufficient workload data in Google Business Profile',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__TITLE: 'General sync',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__OVERVIEW_TEXT: 'Average synchronization rating',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__SHOW_TEXT: 'Show all ({count})',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__HIDE_TEXT: 'Hide',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__NAVIGATORS: 'Navigators we sent data to',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__FOOTER_TEXT: 'You can get more information on the page ',
    DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__FOOTER_LINK: 'Sources',
    DASHBOARD_PAGE__MAP__LABEL: 'Map',

    DASHBOARD_PAGE__SYNC_STATISTICS__TITLE: 'Synchronization with main sources',
    DASHBOARD_PAGE__SYNC_STATISTICS__STATUS__SYNC: 'Synchronized',
    DASHBOARD_PAGE__SYNC_STATISTICS__STATUS__SENT: 'Data sent',
    DASHBOARD_PAGE__SYNC_STATISTICS__STATUS__NEED_ACTION: 'Action required',
    DASHBOARD_PAGE__SYNC_STATISTICS__EXPLANATION: 'You can get more information on the page ',
    DASHBOARD_PAGE__GOODS__CATALOG__COLUMN: 'Catalog',
    DASHBOARD_PAGE__GOODS__COMPANIES_COUNT_COLUMN: 'Companies with goods',
    DASHBOARD_PAGE__GOODS__PRICES_COUNT_COLUMN: 'Loaded Products',
    DASHBOARD_PAGE__GOODS__EMPTY__TITLE: 'No products loaded',
    DASHBOARD_PAGE__GOODS__EMPTY__DESCRIPTION:
        'Upload the price list for goods and services to all company cards on geoservices to increase customer loyalty',
    DASHBOARD_PAGE__GOODS__EMPTY__BUTTON: 'Upload products',
    DASHBOARD_PAGE__GOODS__CARD__NOTE__MANY: 'You can update information on products in catalogs on the page ',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__TITLE: 'Summary of auto replies',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__BALANCE: 'Auto replies available',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__SPENT: 'Spent in 30 days',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__DETAILED: 'Available number of auto replies for users',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EXPLANATION: 'You can set the rules of work on the page {link}',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__SPEED_UP: 'Automotive tools for your work',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__BUTTON: 'Get',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__TITLE: 'Auto-reply packages',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__HEADER: 'Purchase auto-reply packages from your manager!',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__TEXT_PART1:
        'Set up automatic replies to similar reviews for different scenarios and save up to 40% of staff time.',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__TEXT_PART2:
        'To top up your balance, please contact your Account Manager for bank transfer billing.',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__LINK_TEXT: 'More about auto-replies',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EMPTY__TITLE: 'No auto replies available',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EMPTY__TEXT:
        'Set up automatic responses to reviews of the same type or personalized responses based on ChatGPT for different scenarios and save up to 40% of your employees’ time',
    DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EMPTY__BUTTON_TEXT: 'Get auto replies',
    DASHBOARD_PAGE__SEARCH_PHRASES__TITLE: 'Most popular search phrases',
    DASHBOARD_PAGE__SEARCH_PHRASES__DESCRIPTION: 'Up to {count} most popular last month',
    DASHBOARD_PAGE__SEARCH_PHRASES__FULL_INFO_DESCRIPTION:
        'Full online presence analytics can be found in the {link} section',
    DASHBOARD_PAGE__SEARCH_PHRASES__LINK: 'analytics',
    DASHBOARD_PAGE__SEARCH_PHRASES__HAS_EMPTY_PHRASE: 'No results for last month',
    DASHBOARD_PAGE__SEARCH_PHRASES__WAIT_COLLECT_PHRASES:
        "Last month's data will be available after the 3rd of the current month.",
    DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__TITLE: 'Distribution of reviews for the last year by rating',
    DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__FIVE_STARS: '5 stars',
    DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__FOUR_STARS: '4 stars',
    DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__THREE_STARS: '3 stars',
    DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__TWO_STARS: '2 stars',
    DASHBOARD_PAGE__REVIEWS_RATING_DISTRIBUTION__ONE_STAR: '1 star',
    DASHBOARD_PAGE__TIMELINE__TITLE: 'Activities in the card',
    DASHBOARD_PAGE__TIMELINE__ACTION__CHECK_CCL: 'Checked data in directory {catalogName}',
    DASHBOARD_PAGE__TIMELINE__ACTION__PUSH: 'Changed data has been pushed to directory {catalogName}',
    DASHBOARD_PAGE__TIMELINE__ACTION__COLLECT_REVIEWS: 'Collect reviews from card in {catalogName}',
    DASHBOARD_PAGE__TIMELINE__ACTION__NEW_PHOTOS: "There's a new photo in {catalogName}",
    DASHBOARD_PAGE__TIMELINE__ACTION__POST_PUBLISHED: 'Post published in {catalogName}',
    DASHBOARD_PAGE__TIMELINE__ACTION__CHANGE_COMPANY: 'Company data in account changed',
    DASHBOARD_PAGE__TIMELINE__ACTION__CHANGE_COMPANY_FEED: 'Company data changed via feed',
    DASHBOARD_PAGE__TIMELINE__ACTION__PRODUCTS_LOADED: 'Loaded products in {catalogName}',
    DASHBOARD_PAGE__TIMELINE__ACTION__PRODUCTS_DELETED: 'Products deleted from {catalogName}',
    DASHBOARD_PAGE__TIMELINE__ACTION__YANDEX_GEOVISOR: 'Yandex Geovisor',
    DASHBOARD_PAGE__TIMELINE__SHOW_MORE: 'Show more',
    DASHBOARD_PAGE__TIMELINE__YANDEX_IMAGE_TOOLTIP: 'Data from Yandex geovisor',
    DASHBOARD_PAGE__TIMELINE__EVENT__TEXT_SEARCH_ON_MAP: 'Search on Maps',
    DASHBOARD_PAGE__TIMELINE__EVENT__SUGGEST_SEARCH_ON_MAP: 'Search on Maps',
    DASHBOARD_PAGE__TIMELINE__EVENT__POI: 'Click on map point',
    DASHBOARD_PAGE__TIMELINE__EVENT__ARBITRATE_GEOADV_DIRECT: 'Placement in Yandex.Direct',
    DASHBOARD_PAGE__TIMELINE__EVENT__ARBITRATE_GEOADV_YABS: 'Placement in YAN',
    DASHBOARD_PAGE__TIMELINE__EVENT__ARBITRATE_GEOADV_ZEN: 'Placement in Yandex.Zen',
    DASHBOARD_PAGE__TIMELINE__EVENT__ROUTE_ADVERT: 'Route Placement',
    DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT: 'Placement in search by addresses',
    DASHBOARD_PAGE__TIMELINE__EVENT__WHATS_HERE_ADVERT: 'Placement on neighboring buildings',
    DASHBOARD_PAGE__TIMELINE__EVENT__INJECT_BY_RUBRIC_AT_BOTTOM: 'Placement on similar organizations',
    DASHBOARD_PAGE__TIMELINE__EVENT__RELATED: 'Placement on similar organizations',
    DASHBOARD_PAGE__TIMELINE__EVENT__EXTERNAL_OPEN: 'Follow link to Maps',
    DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR_OF_1ORG: 'Placement on similar organizations',
    DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR: 'Placement on similar organizations',
    DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR_OF_CHAIN: 'Placement on similar organizations',
    DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR_OF_POI: 'Click on map point',
    DASHBOARD_PAGE__TIMELINE__EVENT__RUBRIC_ALIKE: 'Search Maps',
    DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT_FROM_SEARCH: 'Placement in address search',
    DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT_FROM_CLICK: 'Placement in search by addresses',
    DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT_FROM_WHATS_HERE: 'Placement on neighboring buildings',
    DASHBOARD_PAGE__TIMELINE__EVENT__UNKNOWN: 'Unknown event',
    DASHBOARD_PAGE__TIMELINE__ACTIONS__SINGULAR: '{count} action in card',
    DASHBOARD_PAGE__TIMELINE__ACTIONS__FEW: '{count} actions in card',
    DASHBOARD_PAGE__TIMELINE__ACTIONS__MANY: '{count} actions in card',
    DASHBOARD_PAGE__TIMELINE__ACTION: 'Action',
    DASHBOARD_PAGE__TIMELINE__ACTION__ROUTE: 'Route is built',
    DASHBOARD_PAGE__TIMELINE__ACTION__CALL: 'Called',
    DASHBOARD_PAGE__TIMELINE__ACTION__REVIEW: 'Viewed reviews',
    DASHBOARD_PAGE__TIMELINE__ACTION__PHOTO: 'Viewed photo',
    DASHBOARD_PAGE__TIMELINE__ACTION__LINKS: 'Went to website',
    DASHBOARD_PAGE__TIMELINE__ACTION__WORKING_STATUS: 'Saw working time',
    DASHBOARD_PAGE__TIMELINE__ACTION__ENTRANCES: 'Saw where the entrance is',
    DASHBOARD_PAGE__TIMELINE__ACTION__ADVERT: 'Viewed promotion',
    DASHBOARD_PAGE__TIMELINE__ACTION__CTA: 'Clicked on the action button',
    DASHBOARD_PAGE__TIMELINE__ACTION__BOOKMARK: 'Added to favorites',
    DASHBOARD_PAGE__TIMELINE__ACTION__BRANCHES: 'Viewed all branches',
    DASHBOARD_PAGE__TIMELINE__ACTION__DETAILS: 'Read more',
    DASHBOARD_PAGE__TIMELINE__ACTION__SHARING: 'Copied card link',
    DASHBOARD_PAGE__TIMELINE__ACTION__BOOKING_SECTION: 'Clicked on the booking button',
    DASHBOARD_PAGE__TIMELINE__ACTION__MENU: 'Opened menu',
    DASHBOARD_PAGE__TIMELINE__ACTION__UNKNOWN: 'Unknown',
    // RECOMMENDATION
    DASHBOARD_PAGE__RECOMMENDATION__TITLE__SINGULAR: 'Recommendations for optimizing company cards',
    DASHBOARD_PAGE__RECOMMENDATION__TITLE__FEW: 'Recommendations for optimizing company cards',
    DASHBOARD_PAGE__RECOMMENDATION__TITLE__MANY: 'Recommendations for optimizing company cards',
    DASHBOARD_PAGE__RECOMMENDATION__CHECK_TITLE: 'Check and update data',
    DASHBOARD_PAGE__RECOMMENDATION__CHECK_SUBTITLE: 'Information has not been updated for more than a month.',
    DASHBOARD_PAGE__RECOMMENDATION__CHECK_DETAILS: 'Keep information up to date. This is how clients can find you.',
    DASHBOARD_PAGE__RECOMMENDATION__CHECK_LINK_TEXT: 'Go to companies',
    DASHBOARD_PAGE__RECOMMENDATION__REVIEW_TITLE: 'Leave answers to the latest reviews',
    DASHBOARD_PAGE__RECOMMENDATION__REVIEW_SUBTITLE: 'There are unanswered reviews from the last week.',
    DASHBOARD_PAGE__RECOMMENDATION__REVIEW_DETAILS:
        "By responding to reviews and encouraging clients to leave positive reviews on your cards, you influence the increase in the company's star rating.",
    DASHBOARD_PAGE__RECOMMENDATION__REVIEW_LINK_TEXT: 'Go to reviews',
    DASHBOARD_PAGE__RECOMMENDATION__ANSWER_TITLE: 'Answer customer questions',
    DASHBOARD_PAGE__RECOMMENDATION__ANSWER_SUBTITLE: 'There are unanswered questions from the last week.',
    DASHBOARD_PAGE__RECOMMENDATION__ANSWER_DETAILS:
        'Answer customer questions so that they feel cared for and come back to you again.',
    DASHBOARD_PAGE__RECOMMENDATION__ANSWER_LINK_TEXT: 'Go to questions',
    DASHBOARD_PAGE__RECOMMENDATION__GALLERY_TITLE: 'Update photos in cards',
    DASHBOARD_PAGE__RECOMMENDATION__GALLERY_SUBTITLE:
        'Photos in the gallery have not been updated for more than 3 months.',
    DASHBOARD_PAGE__RECOMMENDATION__GALLERY_DETAILS:
        'Relevant photos will help customers accurately find your company.',
    DASHBOARD_PAGE__RECOMMENDATION__GALLERY_LINK_TEXT: 'Go to companies',
    DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_TITLE: 'Add a description to company cards',
    DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_SUBTITLE: 'You have companies without a description.',
    DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_DETAILS:
        'A completed company description improves the position of your card in search results.',
    DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_LINK_TEXT: 'Go to companies',
    DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_TITLE: 'Specify more than 3 categories in the cards',
    DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_SUBTITLE: 'Add categories to cards.',
    DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_DETAILS:
        'The more categories in the card company, the better the position in the search results.',
    DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_LINK_TEXT: 'Go to companies',
    DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_TITLE: 'Synchronize cards',
    DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_SUBTITLE: 'Some cards require your action.',
    DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_DETAILS:
        'Card synchronization allows you to update business data and collect feedback.',
    DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_LINK_TEXT: 'Go to sources',
    DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_TITLE: 'Update your {catalogName} linking.',
    DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_SUBTITLE: 'Your {catalogName} account(s) needs to be re-linked.',
    DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_DETAILS: 'Linking {catalogName} allows you to synchronize company cards.',
    DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_LINK_TEXT: 'Setup linking',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__SINGULAR: '{count} company',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__FEW: '{count} companies',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__MANY: '{count} companies',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_REVIEW__SINGULAR: '{count} review',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_REVIEW__FEW: '{count} reviews',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_REVIEW__MANY: '{count} reviews',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_ANSWER__SINGULAR: '{count} question',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_ANSWER__FEW: '{count} questions',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_ANSWER__MANY: '{count} questions',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_SOURCE__SINGULAR: '{count} source',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_SOURCE__FEW: '{count} sources',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_SOURCE__MANY: '{count} sources',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_ACCOUNT__SINGULAR: '{count} account',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_ACCOUNT__FEW: '{count} accounts',
    DASHBOARD_PAGE__RECOMMENDATION__TAG_ACCOUNT__MANY: '{count} accounts',
    DASHBOARD_PAGE__RECOMMENDATION__SHOW_POSTPONED: 'Show postponed',
    DASHBOARD_PAGE__RECOMMENDATION__HIDE_POSTPONED: 'Hide postponed',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONED_TO: 'Postponed to {date}',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_TITLE: 'Postpone recommendation?',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_BUTTON: 'Postpone',
    DASHBOARD_PAGE__RECOMMENDATION__CANCEL_BUTTON: 'Cancel',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ONE_WEEK: '7 days',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ONE_MONTH: '1 month',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__THREE_MONTHS: '3 months',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ERROR_CANCELING_POSTPONE: 'Error canceling postpone',
    DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ERROR_POSTPONE: 'Error postpone',
    // FEATURE DISABLED
    DASHBOARD_PAGE__FEATURE_DISABLED__LEARN_MORE: 'Learn more',
    DASHBOARD_PAGE__FEATURE_DISABLED__POSTS:
        'Post promotions, news or events in geoservices cards to attract more customers to points of sale.',
    DASHBOARD_PAGE__FEATURE_DISABLED__PHOTO_TOOL:
        'Collect photos of all locations in a single account and manage content that is unattractive or inappropriate for your business.',
    DASHBOARD_PAGE__FEATURE_DISABLED__ONLINE_PRESENCE:
        "Connect advanced {companyName} analytics for a comprehensive assessment of your company's online presence with recommendations for improving it.",
    DASHBOARD_PAGE__POSTS__TABLE__TITLE: 'Title',
    DASHBOARD_PAGE__POSTS__TABLE__CATALOGS_COUNT: 'Services',
    DASHBOARD_PAGE__POSTS__TABLE__DATE: 'Publication date',
    DASHBOARD_PAGE__POSTS__TITLE: 'Publication Summary',
    DASHBOARD_PAGE__POSTS__SUBTITLE: 'Last posts sent in the last 30 days',
    DASHBOARD_PAGE__POSTS__EXPLANATION: 'The entire list can be found on the page {link}',
    DASHBOARD_PAGE__POSTS__SENT_PUBLICATIONS: 'Submitted publications',
    DASHBOARD_PAGE__POSTS__LAST_PERIOD: 'in the last 30 days',
    DASHBOARD_PAGE__POSTS__EMPTY__TITLE: 'No posts in the last 30 days',
    DASHBOARD_PAGE__POSTS__EMPTY__DESCRIPTION:
        'Send publications to geoservice cards regularly to increase the visibility of the company for the user',
    DASHBOARD_PAGE__POSTS__EMPTY__BUTTON: 'Create a publication',
    DASHBOARD_PAGE__PHOTOS__TITLE: 'Last {count} photos from catalogs',
    DASHBOARD_PAGE__PHOTOS__CARD_NOTE: 'You can manage user photos on the page ',
    DASHBOARD_PAGE__PHOTOS__OPEN_IN_SOURCE: 'Open in manager',
    DASHBOARD_PAGE__ONLINE_PRESENCE__TITLE: 'Online presence summary',

    DASHBOARD_PAGE__ONLINE_PRESENCE__IMPRESSIONS: 'Impressions',
    DASHBOARD_PAGE__ONLINE_PRESENCE__TARGET_ACTIONS_IMPRESSIONS: 'Impressions with TA',
    DASHBOARD_PAGE__ONLINE_PRESENCE__NO_TARGET_ACTIONS_IMPRESSIONS: 'Impressions without TA',
    DASHBOARD_PAGE__ONLINE_PRESENCE__TARGET_ACTIONS: 'Target Actions',
    DASHBOARD_PAGE__ONLINE_PRESENCE__CONVERSION: 'Conversion',
    DASHBOARD_PAGE__ONLINE_PRESENCE__ROUTES: 'Routes',
    DASHBOARD_PAGE__ONLINE_PRESENCE__SITE: 'Site',
    DASHBOARD_PAGE__ONLINE_PRESENCE__CALLS: 'Calls',
    DASHBOARD_PAGE__ONLINE_PRESENCE__PERIOD: 'per period',
    DASHBOARD_PAGE__ONLINE_PRESENCE__ONLINE_PRESENCE_LINK:
        'Full online presence analytics can be found in the {link} section.',
    DASHBOARD_PAGE__ONLINE_PRESENCE__ONLINE_PRESENCE_LINK__TEXT: 'Analytics',

    DASHBOARD_PAGE__COMPANIES_MAP__STATS__HEADER: 'Statistics for the last {count} available days',
    DASHBOARD_PAGE__COMPANIES_MAP__STAT__IMPRESSIONS: 'Impressions',
    DASHBOARD_PAGE__COMPANIES_MAP__STAT__ACTIONS: 'Actions',
    DASHBOARD_PAGE__COMPANIES_MAP__STAT__CONVERSION: 'Conversion',
    DASHBOARD_PAGE__COMPANIES_MAP__RATING__TOOLTIP: 'Average rating',
    DASHBOARD_PAGE__COMPANIES_MAP__REVIEWS__TOOLTIP: 'Reviews total',
    DASHBOARD_PAGE__WITHOUT_COMPANIES__HEADER: 'No active companies',
    DASHBOARD_PAGE__WITHOUT_COMPANIES__TEXT:
        'The data on this page is displayed for open companies linked to cards in sources. You can manage companies and links on the {sourceLink} and {myCompaniesLink} pages.',
    DASHBOARD_PAGE__COMPANY_SUMMARY__HEADER: 'Company summary:',
    DASHBOARD_PAGE__COMPANY_SUMMARY__ACTIVE: 'Working',
    DASHBOARD_PAGE__COMPANY_SUMMARY__CLOSED: 'Closed forever',
    DASHBOARD_PAGE__COMPANY_SUMMARY__TEMPORARY_CLOSED: 'Temporarily closed',

    DASHBOARD_PAGE__HELPER__PHOTO_TOOL:
        'Recent photos in cards, yours or from users, with the date of upload and the name of the catalog.',
    DASHBOARD_PAGE__HELPER__REVIEWS_SUMMARY:
        'Key metrics, sentiment of reviews, and number of reviews across catalogs over the past year.',
    DASHBOARD_PAGE__HELPER__AUTO_REPLIES: 'Available balance of auto-replies in RocketData by employee.',
    DASHBOARD_PAGE__HELPER__PRICES_SINGLE: 'The number of goods loaded into company cards.',
    DASHBOARD_PAGE__HELPER__PRICES_MULTIPLE: 'The number of cards of companies in which goods are loaded.',
    DASHBOARD_PAGE__HELPER__POSTS: "Recent posts you've sent, with the number of services to send.",
    DASHBOARD_PAGE__HELPER__SYNCHRONIZATION_SINGLE: 'The status of synchronization of company data with sources.',
    DASHBOARD_PAGE__HELPER__SYNCHRONIZATION_MULTIPLE: 'Statistics on synchronization of company data with sources.',
    DASHBOARD_PAGE__HELPER__WORKLOAD:
        'Graph of the load point by day of the week and time of day based on data from Google.',
    DASHBOARD_PAGE__HELPER__ACTIVITY: 'History of checking and updating data in sources.',
    DASHBOARD_PAGE__HELPER__SEARCH_PHRASES:
        'Statistics on the phrases that customers used to find your cards in search engines.',
    DASHBOARD_PAGE__HELPER__LAST_REVIEWS: 'Latest reviews from sources with number of replies.',
    DASHBOARD_PAGE__HELPER__RATING: 'The current rating that your customers see in the cards.',
    DASHBOARD_PAGE__HELPER__ONLINE_PRESENCE:
        'Statistics on card impressions and customer actions in them for the last 30 available days',
    DASHBOARD_PAGE__HELPER__RATING_DISTRIBUTION:
        'Statistics of ratings for the last year of viewing across all sources.',
    DASHBOARD_PAGE__HELPER__DETAILS: 'More',

    DASHBOARD_YANDEX_STORIES__TITLE: 'Yandex Stories',
    DASHBOARD_YANDEX_STORIES__ADD_STORY: 'Add Story',
    DASHBOARD_YANDEX_STORIES__ADD_HOW_TO_FIND: 'Add "How to find"',
    DASHBOARD_YANDEX_STORIES__ADD_HOW_TO_FIND__DESCRIPTION: 'Displayed first on the card',
};
