import {lazy, Suspense} from 'react';

import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {Spinner} from '../../../layout/spinner/spinner';

const PhotoToolRouter = lazy(() => import('./photo-tool-router'));

export function PhotoToolIndex(): JSX.Element {
    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Suspense fallback={<Spinner position="absolute" />}>
                <PhotoToolRouter />
            </Suspense>
        </Page>
    );
}
