import {Switch} from 'antd';
import React, {useEffect, useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {useUser} from '../../../../../../provider/user/user-hook';
import {SendExportFormType} from '../../../../../../service/companies-export/companies-export';
import {Form} from '../../../../../../typings/antd';
import {EmailsFormItem} from '../../../../settings/users/form/email-form-item/emails-form-item';
import {FeedExportFormEnum} from '../feed-export-page-type';

import * as styles from './emails-for-export.scss';

export function EmailsForExport(): React.JSX.Element {
    const [isSendEmail, setIsSendEmail] = useState<boolean>(false);

    const form = Form.useFormInstance<SendExportFormType>();
    const user = useUser();

    useEffect(() => {
        if (isSendEmail && user.user) {
            form.setFieldValue([FeedExportFormEnum.EmailsForExport], [user.user.email]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSendEmail, user]);

    return (
        <div className={styles.EmailsForExport}>
            <div className={styles.EmailsForExport_wrapper}>
                <Text bolder stringKey="FEED__EXPORT__EMAILS__SEND_TO_EMAIL" />
                <Switch onChange={() => setIsSendEmail((isSend) => !isSend)} />
            </div>
            {isSendEmail && (
                <div className={styles.EmailsForExport_emailsFormList}>
                    <EmailsFormItem
                        formInputTitle="COMPANY_FORM__CONTACT_INFO__EMAIL__LABEL"
                        hasInitialEmails={false}
                        isRequired={false}
                        name={FeedExportFormEnum.EmailsForExport}
                    />
                </div>
            )}
        </div>
    );
}
