import {ApiError} from '../../util/error';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

export type CreateReviewFormPaylaodType = {
    catalog_id: number;
    brand_id: number;
    company_id: number | null;
    creation_time: string | null;
    author: string | null;
    rating: number | null;
    comment: string | null;
    origin_url: string | null;
    user_fields?: {email: string | null; phone: string | null};
};

export type EditReviewFormPaylaodType = {id: number} & CreateReviewFormPaylaodType;

export type ReviewPayloadType = {
    id: number;
    catalog_id: number;
    brand_id: number;
    company_id: number;
    creation_time: string;
    author: string;
    phone: string;
    rating: number;
    comment: string;
    tags: Array<number>;
};

export type ReviewFormErrorType = {
    non_field_errors: Array<string>;
};

type CustomReviewErrorPayloadType = {
    detail?: string;
    company_id?: string;
    brand_id?: string;
};

export class CustomReviewError extends ApiError {
    name = 'CustomReviewError';
    jsonData: CustomReviewErrorPayloadType;

    constructor(message: string, statusCode: number, jsonData: CustomReviewErrorPayloadType) {
        super(message, statusCode, jsonData);
        this.jsonData = jsonData;
    }
}

function isCustomReviewErrorPayload(errorPayload: unknown): errorPayload is CustomReviewErrorPayloadType {
    return (
        Boolean((errorPayload as CustomReviewErrorPayloadType).detail) ||
        Boolean((errorPayload as CustomReviewErrorPayloadType).company_id) ||
        Boolean((errorPayload as CustomReviewErrorPayloadType).brand_id)
    );
}

export function handleCustomReviewError(error: unknown): never {
    if (error instanceof ApiError && error.statusCode === 403 && isCustomReviewErrorPayload(error.jsonData)) {
        throw new CustomReviewError(
            error.jsonData.detail || error.jsonData.brand_id || error.jsonData.company_id || '',
            error.statusCode,
            error.jsonData
        );
    }

    throw error;
}

function getEditReviewUrl(reviewId: number) {
    return `${rootApiUrl}/v4/reviews/custom_reviews/${reviewId}/`;
}

export function fetchCreateReview(review: CreateReviewFormPaylaodType): Promise<ReviewPayloadType> {
    return fetchX(rootApiUrl + '/v4/reviews/custom_reviews/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(review),
    });
}

export function fetchEditReview(review: EditReviewFormPaylaodType): Promise<ReviewPayloadType> {
    return fetchX(getEditReviewUrl(review.id), {
        method: FetchMethodEnum.patch,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(review),
    });
}

export function fetchDeleteReview(reviewId: number): Promise<void> {
    return fetchNoContent(rootApiUrl + `/v4/reviews/custom_reviews/${reviewId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
