import {Button, Form, Radio} from 'antd';
import {ValidateErrorEntity} from 'rc-field-form/lib/interface';
import {useEffect, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useUsedesk} from '../../../../service/usedesk/usedesk-hook';
import {NotificationReviewFilter} from '../../notification/notification-review-filter/notification-review-filter';
import * as integrationsStyles from '../integrations.scss';

import {
    ERRORS_CHECKER_BY_TAB,
    initialValues,
    SNACKBAR_MESSAGE_BY_TAB,
    USEDESK_SETTINGS_FORM_OPTIONS,
} from './integration-usedesk-const';
import {deserializeUsedesk, extractNotificationSettings, serializeUsedesk} from './integration-usedesk-helper';
import {UsedeskFieldsType, UsedeskSettingsFormOptionsEnum} from './integration-usedesk-type';
import {UsedeskSettingsControls} from './settings-form/settings-controls';
import * as styles from './integration-usedesk.scss';

export function IntegrationUsedesk(): JSX.Element {
    const [form] = Form.useForm<UsedeskFieldsType>();
    const {getLocalizedString} = useLocale();
    const {result: usedesk, updateUsedesk, isInProgress} = useUsedesk();
    const documentationLinks = useDocumentationLinks();
    const {snackbar} = useSnackbar();

    const [currentSetting, setCurrentSetting] = useState(UsedeskSettingsFormOptionsEnum.general);

    function onFormSubmit() {
        const serializedUsedesk = serializeUsedesk(form.getFieldsValue());

        updateUsedesk(serializedUsedesk)
            .then(() => {
                snackbar.success(<Locale stringKey="INTEGRATIONS__USEDESK__SAVED" />);
            })
            .catch(() => {
                snackbar.error(<Locale stringKey="INTEGRATIONS__USEDESK__REJECTED" />);
            });
    }

    function handleFormSubmitFailed({errorFields}: ValidateErrorEntity<UsedeskFieldsType>) {
        const hasErrors = ERRORS_CHECKER_BY_TAB[currentSetting];

        if (hasErrors(errorFields)) {
            snackbar.error(<Locale stringKey={SNACKBAR_MESSAGE_BY_TAB[currentSetting]} />);
        }
    }

    useEffect(() => {
        if (usedesk) {
            form.setFieldsValue(deserializeUsedesk(usedesk));
        }
    }, [form, usedesk]);

    return (
        <Page>
            <Meta title={getLocalizedString('INTEGRATIONS__INTEGRATOR_NAME__USEDESK')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.settingsIntegrations.path,
                        titleLangKey: 'CATEGORY_NAME__INTEGRATION',
                    },
                    {
                        path: appRoute.settingsIntegrationUsedesk.path,
                        titleLangKey: 'INTEGRATIONS__INTEGRATOR_NAME__USEDESK',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="INTEGRATION__USEDESK__HEADER" />
            </PageHeader>

            <Form
                form={form}
                initialValues={initialValues}
                layout="vertical"
                name="integration-usedesk"
                onFinish={onFormSubmit}
                onFinishFailed={handleFormSubmitFailed}
            >
                <Radio.Group
                    className={styles.radio_buttons_container}
                    onChange={(event) => setCurrentSetting(event.target.value)}
                    optionType="button"
                    options={USEDESK_SETTINGS_FORM_OPTIONS}
                    value={currentSetting}
                />

                <div className={integrationsStyles.integration__item_list}>
                    <div className={currentSetting !== UsedeskSettingsFormOptionsEnum.general ? styles.invisible : ''}>
                        <PageSubHeader>
                            <span>
                                <Locale stringKey="INTEGRATION__USEDESK__SUB_HEADER" />
                                &nbsp;
                                {documentationLinks.usedeskIntegration ? (
                                    <a href={documentationLinks.usedeskIntegration} rel="noreferrer" target="_blank">
                                        <Locale stringKey="INTEGRATION__USEDESK__SUB_HEADER__LINK" />
                                    </a>
                                ) : null}
                            </span>
                        </PageSubHeader>

                        <Spinner isShow={isInProgress} position="absolute" />

                        <UsedeskSettingsControls form={form} />
                    </div>

                    <div
                        className={
                            currentSetting !== UsedeskSettingsFormOptionsEnum.notifications ? styles.invisible : ''
                        }
                    >
                        <PageSubHeader>
                            <Locale stringKey="INTEGRATION__USEDESK__NOTIFICATION__SUB_HEADER" />
                        </PageSubHeader>

                        <NotificationReviewFilter
                            disabled={isInProgress}
                            fields={extractNotificationSettings(form.getFieldsValue())}
                        />
                    </div>
                </div>

                <Button disabled={!usedesk || isInProgress} htmlType="submit" loading={isInProgress} type="primary">
                    <Locale stringKey="BUTTON__SAVE_INFORMATION" />
                </Button>
            </Form>
        </Page>
    );
}
