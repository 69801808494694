import {faInfo} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Paragraph from 'antd/lib/typography/Paragraph';

import {Text} from '../../../../../../../../../../../component/text/text';
import {isValidUrl} from '../../../../../../../../../../../util/url';

import * as styles from './facebook-preview-button-link.scss';

type PropsType = {
    buttonLink: string;
    text: string;
};

export function FacebookPreviewButtonLink(props: PropsType): JSX.Element | null {
    const {buttonLink, text} = props;

    if (!buttonLink || !isValidUrl(buttonLink)) {
        return null;
    }

    const linkHostname = new URL(buttonLink).hostname;

    return (
        <div className={styles.FacebookPreviewButtonLink}>
            <div className={styles.FacebookPreviewButtonLink_linkPath}>{linkHostname}</div>
            <Paragraph
                className={styles.FacebookPreviewButtonLink_description}
                ellipsis={{
                    rows: 2,
                    expandable: false,
                    symbol: <Text secondary stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__READ_MORE" />,
                }}
            >
                {text}
            </Paragraph>
            <div className={styles.FacebookPreviewButtonLink_additionalInfo}>
                <FontAwesomeIcon icon={faInfo} />
            </div>
        </div>
    );
}
