import {Button, ButtonProps} from 'antd';
import {PropsWithChildren} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {LangKeyType} from '../../../../../provider/locale/translation/type';

import * as styles from './yandex-story-form-step.scss';

type PropsType = {
    leftFooterButtonConfig?: ButtonProps & {
        langKeyType: LangKeyType;
    };
    rightFooterButtonConfig?: ButtonProps & {
        langKeyType: LangKeyType;
    };
    footer?: JSX.Element | Array<JSX.Element>;
};

export function YandexStoryFormStep(props: PropsWithChildren<PropsType>): JSX.Element {
    const {leftFooterButtonConfig, rightFooterButtonConfig, footer, children} = props;

    const {langKeyType: leftButtonLangKey, ...leftButtonProps} = leftFooterButtonConfig || {};
    const {langKeyType: rightButtonLangKey, ...rightButtonProps} = rightFooterButtonConfig || {};

    return (
        <div>
            <div className={styles.YandexStoryFormStep_content}>{children}</div>

            <div className={styles.YandexStoryFormStep_footer}>
                {leftButtonLangKey && (
                    <Button
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...leftButtonProps}
                    >
                        <Locale stringKey={leftButtonLangKey} />
                    </Button>
                )}

                {footer || null}

                {rightButtonLangKey && (
                    <Button
                        type="primary"
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...rightButtonProps}
                    >
                        <Locale stringKey={rightButtonLangKey} />
                    </Button>
                )}
            </div>
        </div>
    );
}
