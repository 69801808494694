export const appRoute = {
    root: {
        path: '/',
    },

    // auth
    login: {
        path: '/auth/login',
    },
    register: {
        path: '/auth/register',
    },
    forgotPassword: {
        path: '/auth/forgot-password',
    },
    newPassword: {
        path: '/account/passset',
    },
    twoFactorAuthentication: {
        path: '/auth/two-factor-authentication/:authId',
    },
    twoFactorAuthenticationSetup: {
        path: '/auth/two-factor-authentication-setup/:authId' as const,
    },
    changeEmail: {
        path: '/account/email_change',
    },
    questionnaire: {
        path: '/account/questionnaire',
    },

    // main routes
    // Company - all companies
    myCompanies: {
        path: '/company',
    },

    // Company - create new company - select brand or new brand
    createCompanySelectBrand: {
        path: '/company/create-select-brand',
    },

    // Company - create new company - several (real one) form with company's info
    createCompany: {
        path: '/company/create',
    },

    // Company - edit existed company - several form with company's info
    editCompany: {
        path: '/company/edit/:companyId',
    },

    // Company - edit existed companies - several form with company's info
    bulkEditCompanies: {
        path: '/company/bulk-edit',
    },

    companyActivityAndSummary: {
        path: '/company/activity-and-summary/:companyId' as const,
    },

    companyValidate: {
        path: '/company/validate',
    },

    companyImport: {
        path: '/company/import',
    },

    companyExport: {
        path: '/company/export',
    },

    companyFeedManagement: {
        path: '/company/feed-management',
    },

    // Sources
    sources: {
        path: '/sources',
    },

    sourcesConfirmations: {
        path: '/sources/confirmations',
    },

    sourcesYandexConfirmation: {
        path: '/sources/confirmations/yandex/:brandId/:companyId' as const,
    },

    sourcesAccountSyncGoogle: {
        path: '/sources/account-sync/google/' as const,
    },

    sourcesAccountSyncGoogleConfirmations: {
        path: '/sources/account-sync/google/confirmations' as const,
    },

    // Reviews management - not used, just for left navigation
    reviewsManagement: {
        path: '/reviews-management',
    },

    // Reviews management - Reviews
    reviewsManagementReviews: {
        path: '/reviews-management/reviews',
    },

    // Reviews management - Review Item
    reviewsManagementReviewItem: {
        path: '/reviews-management/reviews/:id' as const,
    },

    postsManagement: {
        path: '/posts-management',
    },

    postsManagementPostDetails: {
        path: '/posts-management/details/:postId' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    postsManagementEditPost: {
        path: '/posts-management/edit/:postId' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    postsManagementViewPost: {
        path: '/posts-management/view/:postId' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    postsManagementCreatePost: {
        path: '/posts-management/create',
    },

    postsManagementCalendar: {
        path: '/posts-management/calendar',
    },

    // Reviews management - Tags
    reviewsManagementTags: {
        path: '/reviews-management/tags',
    },

    // Reviews management - Tags edit
    reviewsManagementTagsEdit: {
        path: '/reviews-management/tags-edit',
    },

    // Reviews management - Tag - create
    reviewsManagementTagCreate: {
        path: '/reviews-management/tags/create',
    },

    // Reviews management - Tag - edit
    reviewsManagementTagEdit: {
        path: '/reviews-management/tags/edit/:tagId' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    // Reviews management - Response templates
    reviewsManagementResponseTemplates: {
        path: '/reviews-management/response-templates',
    },

    // Reviews management - Response templates edit group
    reviewsManagementResponseTemplatesEditGroup: {
        path: '/reviews-management/response-templates/edit-group',
    },

    // Reviews management - Response templates edit template
    reviewsManagementResponseTemplatesEditTemplate: {
        path: '/reviews-management/response-templates/edit-template/:templateId' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    // Reviews management - Response templates create template
    reviewsManagementResponseTemplatesCreateTemplate: {
        path: '/reviews-management/response-templates/create-template',
    },

    // Reviews management - Auto replies
    reviewsManagementAutoReplies: {
        path: '/reviews-management/auto-replies',
    },

    // Reviews management - Auto replies - create
    reviewsManagementAutoReplyCreate: {
        path: '/reviews-management/auto-replies/create',
    },

    // Reviews management - Reviews and answers statistics
    reviewsManagementReviewsAndAnswersStatistics: {
        path: '/reviews-management/statistics' as const,
    },

    // Reviews management - Auto replies - edit
    reviewsManagementAutoReplyEdit: {
        path: '/reviews-management/auto-replies/edit/:autoReplyId' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    // Reviews management - Neural network
    reviewsManagementNeuralNetwork: {
        path: '/reviews-management/neural-network',
    },

    // Reviews management - Questions and Answers
    questionsAndAnswers: {
        path: '/questions-and-answers',
    },

    // Settings - not used, just for left navigation
    settings: {
        path: '/settings',
    },

    // Settings -> Profile
    settingsProfile: {
        path: '/settings/profile',
    },

    // Settings -> Group of companies
    settingsCompaniesGroup: {
        path: '/settings/group-of-companies',
    },

    // Settings -> Accounts linking
    accountLinkingSettings: {
        path: '/settings/accounts-settings',
    },

    users: {
        path: '/settings/users',
    },

    createUser: {
        path: '/settings/users/create',
    },

    editUser: {
        path: '/settings/users/edit/:userId',
    },

    // Settings -> Group of companies
    settingsCompaniesGroupCreate: {
        path: '/settings/group-of-companies/new' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    // Settings -> Group of companies
    settingsCompaniesGroupEdit: {
        path: '/settings/group-of-companies/:id' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    // Settings -> Notification
    settingsNotification: {
        path: '/settings/notification',
    },

    // Settings -> Integration
    settingsIntegrations: {
        path: '/settings/integrations',
    },

    // Settings -> Integration -> Usedesk
    settingsIntegrationUsedesk: {
        path: '/settings/integrations/usedesk',
        oldPath: '/settings/integration/usedesk',
    },

    // Settings -> Integration -> IntraService
    settingsIntegrationIntraService: {
        path: '/settings/integrations/intra-service',
        oldPath: '/settings/integration/intra-service',
    },

    // Settings -> SourceSettings
    settingsSourceSettings: {
        path: '/settings/source-settings',
    },

    // Settings -> SourceSettings -> catalog
    settingsSourceSettingsCatalog: {
        path: '/settings/source-settings/:groupId/catalog/:id',
    },

    // Settings -> SourceSettings -> account
    settingsSourceSettingsAccount: {
        path: '/settings/source-settings/:groupId/account/:id',
    },

    // Photo-tool - photo list
    photoToolIndex: {
        path: '/photo-tool' as const,
    },

    // Photo-tool - photo details
    photoToolDetails: {
        path: '/photo-tool/:id' as const, // `as const` is required for TS validation of useParams and generatePath of react-router
    },

    photoToolVideoDetails: {
        path: '/photo-tool/video/:id' as const,
    },

    photoToolAnalytics: {
        path: '/photo-tool/analytics',
    },

    // Reviews-analysis - not used, just for left navigation
    reviewsAnalysis: {
        path: '/reviews-analysis',
    },

    // Reviews-analysis
    reviewsAnalysisDashboard: {
        path: '/reviews-analysis/dashboard' as const,
    },

    // Reviews-analysis - companies page
    reviewsAnalysisCompanies: {
        path: '/reviews-analysis/companies' as const,
    },

    // Reviews-analysis - cities page
    reviewsAnalysisCities: {
        path: '/reviews-analysis/cities' as const,
    },

    // Reviews-analysis - tags page
    reviewsAnalysisKeywordsAndTopics: {
        path: '/reviews-analysis/keywords-and-topics' as const,
    },

    // Reviews-analysis - topic page
    reviewsAnalysisTopic: {
        path: '/reviews-analysis/keywords-and-topics/topic/:id' as const,
    },

    // Reviews-analysis - create new topic
    reviewsAnalysisTopicCreate: {
        path: '/reviews-analysis/keywords-and-topics/topic-create' as const,
    },

    // Reviews-analysis - update new topic
    reviewsAnalysisTopicUpdate: {
        path: '/reviews-analysis/keywords-and-topics/topic-update/:id' as const,
    },

    // Reviews-analysis - phrases page
    reviewsAnalysisPhrases: {
        path: '/reviews-analysis/phrases' as const,
    },

    // Reviews-analysis - employees page
    reviewsAnalysisEmployees: {
        path: '/reviews-analysis/employees' as const,
    },

    // Reviews-analysis - concordance page
    reviewsAnalysisConcordance: {
        path: '/reviews-analysis/concordance' as const,
    },

    // Reviews-analysis - rating history page
    reviewAnalysisGeneratorRatings: {
        path: '/reviews-analysis/ratings' as const,
    },

    products: {
        path: '/products',
    },

    createProductGroup: {
        path: '/products/create-group/:type',
    },

    editProductsGroup: {
        path: '/products/edit-group/:id',
    },

    duplicatesAndFakes: {
        path: '/duplicates-and-fakes',
    },

    fakesSettings: {
        path: '/duplicates-and-fakes/settings',
    },

    reviewsGenerator: {
        path: '/reviews-generator',
    },

    reviewsGeneratorMailings: {
        path: '/reviews-generator/mailings',
    },

    reviewsGeneratorMailingCreate: {
        path: '/reviews-generator/mailings/create',
    },

    reviewsGeneratorMailingUpdate: {
        path: '/reviews-generator/mailings/:id/update' as const,
    },

    reviewsGeneratorMailingDetails: {
        path: '/reviews-generator/mailings/:id/details' as const,
    },

    reviewsGeneratorMailingClientsSelect: {
        path: '/reviews-generator/mailings/:id/clients-select' as const,
    },

    reviewsGeneratorMailingTemplates: {
        path: '/reviews-generator/mailings/templates',
    },

    reviewsGeneratorMailingTemplatesSelect: {
        path: '/reviews-generator/mailings/:id/templates-select',
    },

    reviewsGeneratorChannelActivation: {
        path: '/reviews-generator/mailings/channel-activation/:id',
    },

    reviewsGeneratorMailingRocketmail: {
        path: '/reviews-generator/mailings/rocketmail',
    },

    reviewsGeneratorClients: {
        path: '/reviews-generator/clients',
    },

    reviewsGeneratorClientCreate: {
        path: '/reviews-generator/clients/new',
    },

    reviewsGeneratorClientUpdate: {
        path: '/reviews-generator/clients/:id' as const,
    },

    reviewsGeneratorClientImport: {
        path: '/reviews-generator/clients/import',
    },

    reviewsGeneratorNegativeReviews: {
        path: '/reviews-generator/negative-reviews',
    },

    reviewsGeneratorNps: {
        path: '/reviews-generator/nps',
    },

    reviewsGeneratorQrCode: {
        path: '/reviews-generator/qr-code',
    },

    reviewsGeneratorQrCodeDetails: {
        path: '/reviews-generator/qr-code/:id/details' as const,
    },

    reviewsGeneratorQrCodeCreate: {
        path: '/reviews-generator/qr-code/create' as const,
    },

    reviewsGeneratorQrCodeUpdate: {
        path: '/reviews-generator/qr-code/:id/update' as const,
    },

    reviewsGeneratorPromoActions: {
        path: '/reviews-generator/promo-actions' as const,
    },

    reviewsGeneratorPromoCodesList: {
        path: '/reviews-generator/promo-actions' as const,
    },

    reviewsGeneratorQuestionnaireList: {
        path: '/reviews-generator/questionnaires' as const,
    },

    reviewsGeneratorQuestionnaireCreate: {
        path: '/reviews-generator/questionnaires/create' as const,
    },

    reviewsGeneratorQuestionnaireEdit: {
        path: '/reviews-generator/questionnaires/update/:questionnaireId' as const,
    },

    reviewsGeneratorUsedPromoActions: {
        path: '/reviews-generator/used-promo-actions' as const,
    },

    reviewsGeneratorPromoCodeCreate: {
        path: '/reviews-generator/promo-actions/create' as const,
    },

    reviewsGeneratorPromoCodeEdit: {
        path: '/reviews-generator/promo-actions/edit/:id' as const,
    },

    reviewsGeneratorAnalytics: {
        path: '/reviews-generator/analytics' as const,
    },

    storeLocator: {
        path: '/store-locator',
    },

    storeLocatorCreate: {
        path: '/store-locator/new',
    },

    storeLocatorUpdate: {
        path: '/store-locator/:id/update' as const,
    },

    storeLocatorPreview: {
        path: '/store-locator/preview' as const,
    },

    dashboard: {
        path: '/dashboard',
    },

    localPack: {
        path: '/local-pack',
    },

    localPackResult: {
        path: '/local-pack/results/:companyId/:searchQueryId' as const,
    },

    localPackRules: {
        path: '/local-pack/rules',
    },

    localPackRuleCreate: {
        path: '/local-pack/rules/create',
    },

    localPackRuleEdit: {
        path: '/local-pack/rules/edit/:ruleId' as const,
    },

    localPackLeaderBoard: {
        path: '/local-pack/leader-board' as const,
    },

    localPackSearchPhrasesStatistics: {
        path: '/local-pack/search-phrases-statistics' as const,
    },

    accountsMatching: {
        path: '/company-matching/:providerId/:brandId/:companyId' as const,
    },

    onlinePresence: {
        path: '/online-presence' as const,
    },
    onlinePresenceDashboard: {
        path: '/online-presence/dashboard' as const,
    },
    onlinePresenceGoogle: {
        path: '/online-presence/google' as const,
    },
    onlinePresenceWgis: {
        path: '/online-presence/2gis' as const,
    },
    onlinePresenceFacebook: {
        path: '/online-presence/facebook' as const,
    },
    onlinePresenceYandex: {
        path: '/online-presence/yandex' as const,
    },
    onlinePresenceSynchronization: {
        path: '/online-presence/synchronization' as const,
    },
    onlinePresencePhrases: {
        path: '/online-presence/phrases' as const,
    },
    onlinePresenceLocations: {
        path: '/online-presence/locations' as const,
    },

    omnichannel: {
        path: '/omnichannel' as const,
    },
    omnichannelChats: {
        path: '/omnichannel/chats' as const,
    },
    omnichannelChat: {
        path: '/omnichannel/chats/:chatId' as const,
    },
    omnichannelSettings: {
        path: '/omnichannel/settings' as const,
    },

    omnichannelVkontakteSettings: {
        path: '/omnichannel/settings/vkontakte' as const,
    },
    omnichannelFacebookSettings: {
        path: '/omnichannel/settings/facebook' as const,
    },
    omnichannelInstagramSettings: {
        path: '/omnichannel/settings/instagram' as const,
    },
    tariffsAndLimits: {
        path: '/tariffs' as const,
    },
    knowledge: {
        path: '/knowledgebase/',
    },
    knowledgeArticle: {
        path: '/knowledgebase/article/:id',
    },
    help: {
        path: '/help',
    },
    helpTechnicalSupport: {
        path: '/support/tickets',
    },
    helpTechnicalSupportTicket: {
        path: '/support/tickets/:ticketId' as const,
    },
    onboarding: {
        path: '/onboarding' as const,
    },
};
