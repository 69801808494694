import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Row} from 'antd';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../component/page-card-divider/main-page-card-divider';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSystem} from '../../../../provider/system/system-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {rootApiUrl} from '../../../../service/api/api-const';
import {useLocalPackFilter} from '../../../../service/local-pack/local-pack-hook';
import {generatePathWithQueryParameters, objectToUrlParameters} from '../../../../util/url';
import {magicMobileMinWidth} from '../../photo-tool/pages/photo-tool-list/photo-tool-controls/photo-tool-controls-const';
import {LocalPackFilter} from '../local-pack-filter/local-pack-filter';
import {getLocalPackFilterValues} from '../local-pack-helper';

import {LocalPackSearchPhrasesStatisticsTable} from './search-phrases-statistics-table/local-pack-search-phrases-statistics-table';
import * as styles from './local-pack-search-phrases-statistics-page.scss';

export function LocalPackSearchPhrasesStatisticsPage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {filter, setFilter, filterQueries} = useLocalPackFilter();
    const {width} = screen;

    const localPackMainPagePath = generatePathWithQueryParameters(appRoute.localPack.path, {
        parametersURL: filterQueries,
    });

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK__SEARCH_PHRASES_STATISTICS')} />
                <BreadCrumbs
                    list={[
                        {
                            path: localPackMainPagePath,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                        {
                            path: appRoute.localPackSearchPhrasesStatistics.path,
                            titleLangKey: 'LOCAL_PACK__SEARCH_PHRASES_STATISTICS',
                        },
                    ]}
                />
                <PageHeader>
                    <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS" />

                    <div className={styles.LocalPackSearchPhrasesStatistics_headerButtons}>
                        <Button href={localPackMainPagePath}>
                            <Locale stringKey="BUTTON__BACK" />
                        </Button>

                        <Button
                            download
                            href={`${rootApiUrl}/cp/local_pack/locations_and_queries_csv/?${objectToUrlParameters(
                                getLocalPackFilterValues(filter)
                            )}`}
                            icon={<FontAwesomeIcon icon={faDownload} />}
                            onClick={() => {
                                track(AnalyticsTarget.LocalPack.DownloadReport, '');
                            }}
                            type="primary"
                        >
                            <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__DOWNLOAD_REPORT" />
                        </Button>
                    </div>
                </PageHeader>
                <MainPageCardDivider />
                <Row align="middle" gutter={[16, 16]} justify="space-between" wrap={width <= magicMobileMinWidth}>
                    <Col flex="auto">
                        <LocalPackFilter filter={filter} setFilter={setFilter} />
                    </Col>
                </Row>
            </PageCard>

            <LocalPackSearchPhrasesStatisticsTable filter={filter} />
        </Page>
    );
}
