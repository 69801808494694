import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

const companyCategorySchema = r.object({
    categoryId: r.number(),
    categoryName: r.string(),
    isMain: r.boolean(),
});

export type CompanyCategoryType = r.infer<typeof companyCategorySchema>;

const companyCatalogCategorySchema = r.object({
    originId: r.string(),
    categoryName: r.string(),
    isMain: r.boolean(),
});

export type CompanyCatalogCategoryType = r.infer<typeof companyCatalogCategorySchema>;

const companyCatalogCategoriesSchema = r.object({
    catalogId: r.number(),
    categories: r.array(companyCatalogCategorySchema),
});

export const companyCategoriesSchema = {
    [CompanyKeyEnum.RocketdataCategories]: r.array(companyCategorySchema),
    [CompanyKeyEnum.CatalogCategories]: r.array(companyCatalogCategoriesSchema),
    [CompanyKeyEnum.MappedCatalogCategories]: r.array(companyCatalogCategoriesSchema),
};

const companyCategoriesSchemaObject = r.object(companyCategoriesSchema);

export type CompanyCategoriesType = r.infer<typeof companyCategoriesSchemaObject>;

export type CompanyCategoriesFormType = Omit<CompanyCategoriesType, 'catalogCategories'> & {
    [CompanyKeyEnum.CatalogCategories]: Array<
        CompanyCategoriesType['catalogCategories'][0] & {reset?: boolean; edited?: boolean}
    >;
};

export type CompanyCategoriesServerType = {
    rocketdataCategories: Array<Omit<CompanyCategoriesType['rocketdataCategories'][0], 'categoryName'>>;
    catalogCategories: Array<{
        catalogId: CompanyCategoriesType['catalogCategories'][0]['catalogId'];
        categories: Array<Omit<CompanyCategoriesType['catalogCategories'][0]['categories'][0], 'categoryName'>>;
    }>;
};
