import {ShortCatalogType} from '../../../../../provider/catalogs/catalogs-type';
import {useUser} from '../../../../../provider/user/user-hook';
import {PostStatusEnum} from '../../../../../service/posts/posts-types';
import {ProvidersIdsEnum} from '../../../../../util/type';

import {PostActionEdit} from './post-action-edit';
import {PostActionHardRemove} from './post-action-hard-remove';
import {PostActionPublish} from './post-action-publish';
import {PostActionRefresh} from './post-action-refresh';
import {PostActionRemove} from './post-action-remove';
import {PostActionView} from './post-action-view';
import * as styles from './post-actions.scss';

type PropsType = {
    status: PostStatusEnum | null;
    postId: number;
    postSources: Array<ShortCatalogType>;
    onActionCompleted: () => void;
    setIsTableInProgress: (isInProgress: boolean) => void;
    catalogs: Array<ProvidersIdsEnum>;
    canBeRefreshed: boolean;
};

// eslint-disable-next-line complexity
export function PostActions(props: PropsType): JSX.Element {
    const {status, postId, setIsTableInProgress, postSources, onActionCompleted, catalogs, canBeRefreshed} = props;
    const {user} = useUser();

    const isPublishActionPresents: boolean = status === PostStatusEnum.Draft;

    const isEditActionPresents: boolean = status
        ? [
              PostStatusEnum.Draft,
              PostStatusEnum.Planned,
              PostStatusEnum.Sent,
              PostStatusEnum.Active,
              PostStatusEnum.Sending,
          ].includes(status) && !(catalogs.length === 1 && catalogs.includes(ProvidersIdsEnum.instagram))
        : false;

    const isViewActionPresents = !isEditActionPresents;

    const isRemoveActionPresents: boolean = status
        ? catalogs.some((catalog) => catalog !== ProvidersIdsEnum.instagram) &&
          [PostStatusEnum.Active, PostStatusEnum.Sent, PostStatusEnum.Planned].includes(status)
        : false;

    return (
        <div className={styles.PostActions}>
            {isViewActionPresents && <PostActionView postId={postId} />}
            {isPublishActionPresents && (
                <PostActionPublish
                    isDisabled={Boolean(user?.isDemoUser)}
                    onActionCompleted={onActionCompleted}
                    postId={postId}
                    postSources={postSources}
                />
            )}
            {isEditActionPresents && <PostActionEdit postId={postId} />}
            {isRemoveActionPresents && (
                <PostActionRemove
                    isDisabled={Boolean(user?.isDemoUser)}
                    onActionCompleted={onActionCompleted}
                    postId={postId}
                    setIsTableInProgress={setIsTableInProgress}
                    showInstagramWarning={catalogs.includes(ProvidersIdsEnum.instagram)}
                />
            )}
            {canBeRefreshed && <PostActionRefresh postId={postId} />}
            {(status === PostStatusEnum.Draft || status === PostStatusEnum.Deleted) && (
                <PostActionHardRemove
                    isDisabled={Boolean(user?.isDemoUser)}
                    onActionCompleted={onActionCompleted}
                    postId={postId}
                    setIsTableInProgress={setIsTableInProgress}
                    showInstagramWarning={catalogs.includes(ProvidersIdsEnum.instagram)}
                />
            )}
        </div>
    );
}
