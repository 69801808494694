import {NamePath} from 'antd/lib/form/interface';
import {isArray, isPlainObject, isString} from 'lodash';

const DELIMETER = '.';

type ObjectOrArrayType<T> = Record<string, T> | Array<T>;

function addDelimiter(object: string, accessor: string) {
    return object ? `${object}${DELIMETER}${accessor}` : accessor;
}

function getEntries<T>(objectOrArray: ObjectOrArrayType<T>): Array<[string, T]> {
    if (isArray(objectOrArray)) {
        return [...objectOrArray.entries()].map(([key, value]) => [key.toString(), value]);
    }

    if (isPlainObject(objectOrArray)) {
        return Object.entries(objectOrArray);
    }

    return [];
}

function propertiesToMap<T extends ObjectOrArrayType<unknown>>(objectOrArray: T, prefix = '') {
    return getEntries(objectOrArray).reduce((product, [key, value]) => {
        const fullPath = addDelimiter(prefix, key);

        if (isPlainObject(value) || (isArray(value) && !value.every(isString))) {
            propertiesToMap(value as ObjectOrArrayType<T>, fullPath).forEach((property, innerKey) =>
                product.set(innerKey, property)
            );

            return product;
        }

        return product.set(addDelimiter(prefix, key), value);
    }, new Map<string, unknown>());
}

// {names: {"0": {"name": ["Error message"]}}} => ["names.0.name", ["Error message"]]
function propertiesToArray<T extends ObjectOrArrayType<unknown>>(object: T): Array<[string, unknown]> {
    return [...propertiesToMap(object)];
}

// unsafe means we can't pass it to ant, if there's an object inside an error it will cause a runtime error
export function convertErrorToFormUnsafeError<T extends ObjectOrArrayType<unknown>>(
    errorMessage: T
): Array<{
    name: NamePath;
    errors: unknown;
}> {
    return propertiesToArray(errorMessage).map(([name, errors]) => ({
        name: name
            .split(DELIMETER)
            .map((key) => (Number.isNaN(Number.parseInt(key, 10)) ? key : Number.parseInt(key, 10))),
        errors,
    }));
}

// {names: {"0": {"name": ["Error message"]}}} => [{name: ["names", 0, "name"], errors: ["Error message"]}]
export function convertErrorToFormError<T extends ObjectOrArrayType<unknown>>(
    errorMessage: T
): Array<{
    name: NamePath;
    errors: Array<string>;
}> {
    return convertErrorToFormUnsafeError(errorMessage).map((result) => ({
        ...result,
        errors: isArray(result.errors) && result.errors.every(isString) ? result.errors : [],
    }));
}
