import {Dayjs} from 'dayjs';

import {LocaleNameEnum} from '../../../../../provider/locale/locale-context-type';
import {getFormattedDateTime} from '../../../../../util/format';

export function getRangeFilterTagFormat(localeName: LocaleNameEnum, startDate: Dayjs, endDate: Dayjs): string {
    const startFormat: string = getFormattedDateTime(localeName, startDate.toDate());
    const endFormat: string = getFormattedDateTime(localeName, endDate.toDate());

    return `${startFormat} - ${endFormat}`;
}
