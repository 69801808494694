import {Table, TableColumnsType} from 'antd';
import {chunk} from 'lodash';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import {RequestReportBase} from '../../../../../component/request-report-base/request-report-base';
import {saveAsExcel} from '../../../../../component/request-report-base/request-report-base-helper';
import {RequestReportPopup} from '../../../../../component/request-report-base/request-report-popup/request-report-popup';
import {ExportReportModeEnum} from '../../../../../component/request-report-base/request-report-popup/request-report-popup-const';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/localization';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {PaginatedResponseType} from '../../../../../service/api/api-type';
import {ActiveFilter} from '../active-filter/active-filter';

import {ReviewsReportFormEnum, ReviewsReportFormType} from './request-report-type';
import * as styles from './request-report.scss';

type PropsType<T, K, L> = {
    filters: L;
    title: JSX.Element;
    defaultFilename: string;
    columns: TableColumnsType<T>;
    getDataSource: (data: Array<K>, isForExport?: boolean) => Array<T>;
    formatDataForXls: (data: Array<K>) => Array<unknown>;
    xlsxWorksheetName?: string;
    apiHandler: (
        options: L & {
            page: number;
            pageSize: number;
        },
        mainFilterKey: string
    ) => Promise<PaginatedResponseType<K>>;
};

export function RequestReport<T extends object, K, L>(props: PropsType<T, K, L>): JSX.Element {
    const {filters, columns, getDataSource, title, apiHandler, formatDataForXls, defaultFilename, xlsxWorksheetName} =
        props;

    const {snackbar} = useSnackbar();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const [isDownloadReportPopupOpen, setIsDownloadReportPopupOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const [tableData, setTableData] = useState<Array<K>>([]);
    const [fileName, setFileName] = useState<string>('');

    const pdfContentRef = useRef<HTMLDivElement | null>(null);

    const dataSource: Array<Array<T>> = useMemo<Array<Array<T>>>(
        () => chunk(getDataSource(tableData, true), 20),
        [getDataSource, tableData]
    );

    const handlePrintClick = useReactToPrint({
        content: () => pdfContentRef.current,
        documentTitle: fileName || defaultFilename,
    });

    const [pdfReportRequested, setPdfReportRequested] = useState<boolean>(false);

    useEffect(() => {
        if (pdfReportRequested) {
            handlePrintClick();

            setPdfReportRequested(false);
        }
    }, [pdfReportRequested, handlePrintClick]);

    const handleSave = useCallback(
        async (mode: ExportReportModeEnum, formValues?: ReviewsReportFormType) => {
            try {
                setIsLoading(true);
                const initialResponce = await apiHandler(
                    {
                        page: 1,
                        pageSize: 200,
                        ...filters,
                    },
                    mainFilterKey
                );
                const results = initialResponce.results;

                setFileName(formValues?.[ReviewsReportFormEnum.Name] || '');

                // eslint-disable-next-line no-loops/no-loops, unicorn/new-for-builtins
                for await (const index of [...Array(initialResponce.pages).keys()].slice(1)) {
                    const responce = await apiHandler(
                        {
                            page: index + 1,
                            pageSize: 200,
                            ...filters,
                        },
                        mainFilterKey
                    );

                    results.push(...responce.results);
                }

                if (mode === ExportReportModeEnum.Pdf) {
                    setTableData(results);
                    setPdfReportRequested(true);
                }

                if (mode === ExportReportModeEnum.excel) {
                    saveAsExcel({
                        data: formatDataForXls(results),
                        fileName: formValues?.[ReviewsReportFormEnum.Name] || defaultFilename,
                        worksheetName: xlsxWorksheetName,
                    });
                }
            } catch {
                snackbar.error({
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                    description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                });
            } finally {
                setIsLoading(false);
            }
        },
        [apiHandler, defaultFilename, filters, formatDataForXls, mainFilterKey, snackbar, xlsxWorksheetName]
    );

    return (
        <>
            <RequestReportBase onChangeModal={setIsDownloadReportPopupOpen}>
                <RequestReportPopup<ReviewsReportFormType>
                    defaultFilename={defaultFilename}
                    formItemNameInput={ReviewsReportFormEnum.Name}
                    isOpen={isDownloadReportPopupOpen}
                    loading={isLoading}
                    onClose={() => setIsDownloadReportPopupOpen(false)}
                    onSubmit={handleSave}
                    title={<Locale stringKey="REVIEWS_ANALYSIS__DOWNLOAD_REPORT" />}
                />
            </RequestReportBase>
            <div className={styles.RequestReport_pdfContent}>
                <div className={styles.RequestReport_pdfContentBody} ref={pdfContentRef}>
                    <PageHeader>{title}</PageHeader>

                    <ActiveFilter isCompareRangeAllowed isCompetitorAllowed isPreview isWithTextOnly />

                    <Table<T>
                        className={styles.RequestReport_table}
                        columns={columns}
                        dataSource={dataSource.flat()}
                        pagination={false}
                        size="middle"
                    />
                </div>
            </div>
        </>
    );
}
