import {useMemo} from 'react';

import {AddButton} from '../../../../../../layout/add-button/add-button';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum, CompanyErrorsType, FieldModeEnum} from '../../../../../../service/company/company-type';
import {getRandomString} from '../../../../../../util/string';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import {UrlInput} from './url-input/url-input';
import {ProtocolEnum} from './url-input/url-input-type';
import {UrlInputStringType} from './websites-form-item-type';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.websites> & {
    containerClassName?: string;
    addButtonClassName?: string;
    inputClassName?: string;
    mode?: FieldModeEnum;
    serverError?: CompanyErrorsType['website'];
    helpText?: JSX.Element;
    multiple?: boolean;
};

export function WebsitesFormItem(props: PropsType): JSX.Element {
    const {
        value: websites,
        onChange,
        containerClassName,
        addButtonClassName,
        inputClassName,
        mode = FieldModeEnum.replacement,
        serverError,
        errorMessage,
        helpText,
        multiple,
    } = props;

    const {getLocalizedString} = useLocale();

    const isDisabledAddButton = useMemo(() => {
        return websites[0]?.value.length === 0;
    }, [websites]);

    // TODO: after new design and refactoring there should be only one 'errorMessage' prop
    function getErrorMessages(index: number): Array<string> {
        if (serverError && serverError[index]) {
            return serverError[index]?.website || [];
        }

        if (errorMessage && (errorMessage as Array<Array<string>>)[index]) {
            return (errorMessage as Array<Array<string>>)[index] as Array<string>;
        }

        return [];
    }

    return (
        <div className={containerClassName}>
            {websites.map((website: UrlInputStringType, index): JSX.Element => {
                const errorMessages = getErrorMessages(index);

                return (
                    <UrlInput
                        errorMessages={errorMessages}
                        helpText={helpText}
                        inputClassName={inputClassName}
                        item={website}
                        itemList={websites}
                        key={website.id}
                        label={<Locale stringKey="CONTACTS__INFO__MAIN_WEBSITE" />}
                        mode={mode}
                        placeholder={getLocalizedString('CONTACTS__PLACEHOLDER__WEBSITE')}
                        setItemList={onChange}
                        tag={BulkEditFieldNameEnum.websites}
                    />
                );
            })}
            {multiple && (
                <AddButton
                    className={addButtonClassName}
                    isDisabled={isDisabledAddButton}
                    onClick={() => {
                        onChange([...websites, {value: '', id: getRandomString(), protocol: ProtocolEnum.HTTPS}]);
                    }}
                    type="link"
                >
                    <Locale stringKey="BUTTON__ADD" />
                </AddButton>
            )}
        </div>
    );
}
