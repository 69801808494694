import {Popover, Tag} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {LocalePlural} from '../../../../../../provider/locale/locale-plural';
import {LocalPackLeaderboardItemType} from '../../../../../../service/local-pack/leaderboard/local-pack-laderboard-type';

import * as styles from './local-pack-leaderboard-search-keys-cell.scss';

type PropsType = {
    entry: LocalPackLeaderboardItemType;
};

const SEARCH_PHRASES_MAX_SHOWN_COUNT = 10;

export function LocalPackLeaderboardSearchKeysCell(props: PropsType): JSX.Element {
    const {entry} = props;

    const searchPhrasesCount = entry.keys.length;

    return (
        <Popover
            content={
                <div className={styles.LocalPackLeaderBoardTable_tooltipContainer}>
                    <Text
                        block
                        bolder
                        stringKey="LOCAL_PACK__LEADERBOARD__SEARCH_KEYS"
                        valueMap={{
                            count: searchPhrasesCount,
                        }}
                    />
                    <ul className={styles.LocalPackLeaderBoardTable_tooltipSearchPhrase}>
                        {entry.keys?.slice(0, SEARCH_PHRASES_MAX_SHOWN_COUNT).map((searchPhrase) => {
                            return <li key={searchPhrase}>{searchPhrase}</li>;
                        })}
                    </ul>
                    {searchPhrasesCount > SEARCH_PHRASES_MAX_SHOWN_COUNT && (
                        <Text block secondary>
                            <LocalePlural
                                count={searchPhrasesCount - SEARCH_PHRASES_MAX_SHOWN_COUNT}
                                fewKey="LOCAL_PACK__LEADERBOARD__MORE_SEARCH_KEYS__FEW"
                                manyKey="LOCAL_PACK__LEADERBOARD__MORE_SEARCH_KEYS__MANY"
                                singularKey="LOCAL_PACK__LEADERBOARD__MORE_SEARCH_KEYS__SINGULAR"
                                valueMap={{
                                    count: searchPhrasesCount - SEARCH_PHRASES_MAX_SHOWN_COUNT,
                                }}
                            />
                        </Text>
                    )}
                </div>
            }
            // eslint-disable-next-line no-undefined
            trigger={searchPhrasesCount !== 0 ? 'hover' : undefined}
        >
            <Tag className={styles.LocalPackLeaderBoardTable_tag}>
                <Text bolder>{searchPhrasesCount}</Text>
            </Tag>
        </Popover>
    );
}
