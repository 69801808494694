import {DatePicker} from 'antd';
import {RangePickerProps} from 'antd/lib/date-picker';
import {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';
import {useCallback, useEffect, useRef, useState} from 'react';

export function DateRangePicker(props: RangePickerProps): JSX.Element {
    const {onChange, disabledDate, disabled, format, value = [null, null]} = props;

    const containerRef = useRef<HTMLDivElement | null>(null);

    const [isOpened, setIsOpened] = useState(false);
    const [rangePickerValue, setRangePickerValue] = useState<NoUndefinedRangeValueType<Dayjs>>([null, null]);

    const handleOutsideClick = useCallback(
        ({target}: MouseEvent) => {
            if (containerRef.current?.contains(target as HTMLElement) && !isOpened) {
                onChange?.(rangePickerValue, [String(format), String(format)]);
            }
        },
        [format, isOpened, onChange, rangePickerValue]
    );

    const handleOpenChange = useCallback(
        (open: boolean) => {
            setIsOpened(open);

            if (!open) {
                onChange?.(rangePickerValue, [String(format), String(format)]);
            }
        },
        [format, onChange, rangePickerValue]
    );

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    return (
        <div ref={containerRef}>
            <DatePicker.RangePicker
                clearIcon={false}
                disabled={disabled}
                disabledDate={disabledDate}
                format={format}
                getPopupContainer={() => containerRef.current || document.body}
                onChange={(dates) => setRangePickerValue(dates || [null, null])}
                onOpenChange={handleOpenChange}
                value={value}
            />
        </div>
    );
}
