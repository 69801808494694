import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {faFileLines, faPaperclip} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {NavigationLink} from '../../../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../../../provider/locale/locale';
import {LocalePlural} from '../../../../../../../provider/locale/locale-plural';
import {constructCommentPreview, getImageCountFromComment} from '../review-helper';

import * as styles from './review-comment.scss';

type PropsType = {
    comment: string | null;
    reviewId: number;
    isLinkHidden?: boolean;
    hasIcon?: boolean;
};

export function ReviewComment(props: PropsType): JSX.Element {
    const {isLinkHidden, comment = '', reviewId, hasIcon = false} = props;

    const text: string = isLinkHidden ? comment || '' : constructCommentPreview(comment || '');
    const attachmentsCount: number = getImageCountFromComment(comment || '');

    return (
        <div className={styles.review_comment}>
            <div>
                <div className={styles.review_comment_text}>
                    {hasIcon && <FontAwesomeIcon className={styles.review_comment__icon} icon={faFileLines} />}
                    <div>
                        {text || (
                            <span className={styles.review_comment_text__secondary}>
                                <Locale stringKey="REVIEWS__REVIEW__NO_TEXT" />
                            </span>
                        )}
                    </div>
                </div>
                {attachmentsCount > 0 && (
                    <div className={styles.review_attachments}>
                        <FontAwesomeIcon className={styles.review_comment__icon} icon={faPaperclip} />
                        <span className={styles.review_comment_text__secondary}>
                            <LocalePlural
                                count={attachmentsCount}
                                fewKey="REVIEWS__REVIEW__ATTACHMENTS__FEW"
                                manyKey="REVIEWS__REVIEW__ATTACHMENTS__MANY"
                                singularKey="REVIEWS__REVIEW__ATTACHMENTS__SINGLE"
                                valueMap={{count: attachmentsCount}}
                            />
                        </span>
                    </div>
                )}
            </div>
            {!isLinkHidden && (
                <NavigationLink
                    className={styles.review__link}
                    isSaveQuery
                    to={generatePath(appRoute.reviewsManagementReviewItem.path, {id: reviewId})}
                >
                    <FontAwesomeIcon className={styles.review__link_icon} icon={faChevronRight} />
                </NavigationLink>
            )}
        </div>
    );
}
