import {Select} from 'antd';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {usePostsInstagramPages} from '../../../../../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {Form} from '../../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../../post-form-type';

export function InstagramPagesItem(props: PostFormItemPropsType): JSX.Element {
    const {disabled} = props;

    const {requiredFieldRule} = useFormRules();

    const {data: availableInstagramPages, isLoading: isLoadingInstagramPages} = usePostsInstagramPages();

    return (
        <Form.Item<PostFormType>
            label={<Locale stringKey="POSTS_FORM__INSTAGRAM_PAGES__LABEL" />}
            name={PostFormFieldsEnum.InstagramGroupsIds}
            rules={[requiredFieldRule]}
        >
            <Select<Array<string>>
                disabled={disabled}
                loading={isLoadingInstagramPages}
                mode="multiple"
                optionFilterProp="children"
                placeholder={<Locale stringKey="POSTS_FORM__INSTAGRAM_PAGES__PLACEHOLDER" />}
            >
                {availableInstagramPages?.map((page) => (
                    <Select.Option key={page.igId} value={page.igId}>
                        {page.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
