export enum AuthorTypeEnum {
    user = 'user',
    merchant = 'merchant',
}

type QuestionDataBrandFromListType = {
    id: number;
    name: string; // "Автомир, официальный дилер Volkswagen",
    is_test: boolean; // false
};

type QuestionDataCompanyCategoryType = {
    id: number; // 6854
    is_main: boolean; // true
    name: string; // "Автосалон"
};

type QuestionDataCompanyAddressType = {
    city: string; // "Саратов"
    country: string; // "Россия"
    country_code: string; // "ru"
    description: string | null; // null
    housenumber: string; // "дом 30"
    lat: number; // 51.558201
    lon: number; // 46.0456
    postcode: string; // "410038"
    region: string; // "Саратовская область"
    street: string; // "ул.Аэропорт"
};

type QuestionDataCompanyType = {
    address: QuestionDataCompanyAddressType;
    categories: Array<QuestionDataCompanyCategoryType>;
    id: number; // 95774,
    name: string; // "Автомир, официальный дилер Volkswagen",
    code: string; // "74"
};

export type QuestionDataAnswerType = {
    author: string; // "Денис Парыгин"
    author_type: AuthorTypeEnum;
    date_created: string; // "2021-02-23T06:19:33.179364Z"
    id: number; // 193083;
    text: string; // "Продают. Рядом салон открыли. Сервис общий у них"
    is_company_comment: boolean;
};

type QuestionDataCatalogType = {
    id: number; // 11
    logo: string; // "https://assets.rocketdata.io/media/cache/bb/38/bb3864498030c7c5e88c1e2c7dc47ac7.png"
    name: string; // "Google Business Profile"
    website: string; // "google"
};

export type QuestionDataType = {
    answers: Array<QuestionDataAnswerType>; // []
    author: string; // "Mamet Gelog"
    author_type: string; // "user"
    brand: QuestionDataBrandFromListType;
    can_reply: boolean; // true
    catalog: QuestionDataCatalogType;
    company: QuestionDataCompanyType;
    date_created: string; // "2021-04-20T11:47:50.702188Z"
    has_owner_answer: boolean; // false
    id: number; // 253758
    text: string; // "Сколько стоит масло для замены в ДВС?"
    url: string; // "https://google.com/search?q=%D0%90%D0%B2%D1%82%D0%BE%D0%BC%D0%B8%D1%80%2C%20%D0%BE%D1%84%D0%B8%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D0%B4%D0%B8%D0%BB%D0%B5%D1%80%20Volkswagen%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F%2C%20%D0%A1%D0%B0%D1%80%D0%B0%D1%82%D0%BE%D0%B2%2C%20%D1%83%D0%BB.%D0%90%D1%8D%D1%80%D0%BE%D0%BF%D0%BE%D1%80%D1%82%2C%20%D0%B4%D0%BE%D0%BC%2030#lpqa=d,3,a,AIe9_BFL0OZrFaHgoJtqifT1GHrIs9OueV5xJf27WIpeN632EbzgP_jmV7orG8du7D-EiNXKLZfM5_E9x96RWe_PB0p_szpWDkfLUP-Pu7uaRl4MiOQnHc2Y-BZcz9tuid5IAX_Hk-XM"
};

export type ReplierDataType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
};

export type QuestionsStatisticType = {
    all: number;
    has_answers: number;
    has_no_answers: number;
};

export type FetchQuestionsOptionType = {
    hasOwnerAnswer: boolean | null;
    employerId: number | null;
    dateBefore: Date | null;
    dateAfter: Date | null;
};

export type QuestionReplyType = {
    author: string;
    author_type: 'merchant' | 'user';
    date_created: string;
    id: number;
    text: string;
};

export type QuestionReplyErrorType = {
    non_field_errors: Array<string>;
};
