import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';

import {CompanyGroupTableRowDataType} from './companies-group-table-type';

export const companiesGroupColumnList: TableColumnsType<CompanyGroupTableRowDataType> = [
    {
        title: <Locale stringKey="GROUP_OF_COMPANIES__TABLE__NAME" />,
        dataIndex: 'name',
        align: 'left',
    },
    {
        title: <Locale stringKey="GROUP_OF_COMPANIES__TABLE__COMPANIES" />,
        dataIndex: 'count',
        align: 'right',
    },
    {
        title: <Locale stringKey="GROUP_OF_COMPANIES__TABLE__ACTION" />,
        dataIndex: 'action',
        align: 'center',
    },
];
