import {Select} from 'antd';
import {Key} from 'react';

import {appRoute} from '../../../../../app-route';
import {LocaleContextType, ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {ReviewRateEnum} from '../../../../../service/reivew-analysis/review-rate/review-rate-type';
import {SentimentEnum} from '../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {sortCompare} from '../../../../../util/string';
import {ReviewsAnalysisFilterEnum, ReviewsAnalysisFilterType} from '../../reviews-analysis-type';
import {RateStar} from '../rate-star/rate-star';

import {SelectOptionType, TrackAnalyticsType} from './drawer-filter-type';

export function compareSelectOptions<IdType>(shortLocaleName: ShortLocaleNameEnum) {
    return function compare(optionA: SelectOptionType<IdType>, optionB: SelectOptionType<IdType>): number {
        return sortCompare(shortLocaleName, optionA.name, optionB.name);
    };
}

export function renderSelectOption<IdType extends Key>(option: SelectOptionType<IdType>): JSX.Element {
    return (
        <Select.Option key={option.id} value={option.id}>
            {option.name}
        </Select.Option>
    );
}

export function renderSentimentOptions(
    getLocalizedString: LocaleContextType['getLocalizedString']
): Array<JSX.Element> {
    return [
        {
            id: SentimentEnum.Positive,
            name: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__POSITIVE'),
        },
        {
            id: SentimentEnum.Unspecified,
            name: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED'),
        },
        {
            id: SentimentEnum.Neutral,
            name: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL'),
        },
        {
            id: SentimentEnum.Negative,
            name: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE'),
        },
    ].map(renderSelectOption);
}

export function renderRateOptions(): Array<JSX.Element> {
    return Object.values<ReviewRateEnum>(ReviewRateEnum).map((rate: ReviewRateEnum): JSX.Element => {
        return (
            <Select.Option
                key={rate}
                label={
                    <>
                        <span>{rate} </span>

                        <RateStar />
                    </>
                }
                value={rate}
            >
                {Array.from(
                    {length: Number(rate)},
                    (_value, index): JSX.Element => (
                        <RateStar key={index} />
                    )
                )}
            </Select.Option>
        );
    });
}

function trackAnalytics({key, filterName, value}: TrackAnalyticsType): void {
    track(`${key} ${filterName.replaceAll(/[_-]/g, ' ')}`, Array.isArray(value) ? value.join(', ') : value);
}

function sendAnalyticsConcordance(value: ReviewsAnalysisFilterType): void {
    const ReviewsAnalysisFilterKeys = Object.keys(value) as Array<keyof ReviewsAnalysisFilterType>;

    // eslint-disable-next-line no-loops/no-loops
    for (const ReviewsAnalysisFilterKey of ReviewsAnalysisFilterKeys) {
        const valuepParameter = value[ReviewsAnalysisFilterKey];

        // eslint-disable-next-line default-case
        switch (true) {
            case ReviewsAnalysisFilterKey === ReviewsAnalysisFilterEnum.Range:
                trackAnalytics({
                    key: AnalyticsTarget.ReviewsAnalysis.SearchByReviews.UseFilter,
                    filterName: 'Period',
                    value: Array.isArray(valuepParameter) ? `${valuepParameter[0]} - ${valuepParameter[1]}` : '',
                });
                break;
            case Array.isArray(valuepParameter) && valuepParameter.length > 0:
                trackAnalytics({
                    key: AnalyticsTarget.ReviewsAnalysis.SearchByReviews.UseFilter,
                    filterName: ReviewsAnalysisFilterKey,
                    value: valuepParameter as Array<string | number>,
                });
                break;
            case valuepParameter !== null && !Array.isArray(valuepParameter):
                trackAnalytics({
                    key: AnalyticsTarget.ReviewsAnalysis.SearchByReviews.UseFilter,
                    filterName: ReviewsAnalysisFilterKey,
                    value: `${valuepParameter}`,
                });
                break;
        }
    }
}

export function sendDrawerFilterAnalytics(
    value: ReviewsAnalysisFilterType,
    competitorOptions: Array<SelectOptionType<number>>
): void {
    const url = window.location.pathname;

    // eslint-disable-next-line default-case
    switch (url) {
        case appRoute.reviewsAnalysisConcordance.path:
            sendAnalyticsConcordance(value);
            break;
        case appRoute.reviewsAnalysisDashboard.path:
            // eslint-disable-next-line no-case-declarations
            const competitorName = competitorOptions.find(
                (competitor) => competitor.id === value[ReviewsAnalysisFilterEnum.Competitor]
            )?.name;

            if (competitorName) {
                track(AnalyticsTarget.ReviewsAnalysis.Dashboard.CompareWithCompetitorFilter, `${competitorName}`);
            }

            break;
    }
}
