import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {Text} from '../../component/text/text';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {LocalePlural} from '../../provider/locale/locale-plural';
import {Locale} from '../../provider/locale/localization';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {fetchAndDeserialize, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {userManagementUrl} from './user-management-const';

export const roleSchema = r.object({
    pk: r.number(),
    name: r.string(),
    isOwner: r.boolean(),
});

export const userSchema = r.object({
    pk: r.number(),
    fullName: r.string().nullable(),
    email: r.string(),
    role: roleSchema,
    usersCreatedByMeCount: r.number(),
    licenceTotal: r.number(),
    licenceAvailable: r.number(),
});

type UserType = r.infer<typeof userSchema>;

const userUrl = `${userManagementUrl}/users/user_data/`;

function getDeleteUserUrl(id: number) {
    return `${rootApiUrl}${userManagementUrl}/users/${id}/`;
}

function fetchUser(): Promise<UserType> {
    return fetchAndDeserialize(userUrl, userSchema);
}

export function useUser(): UseQueryResult<UserType> {
    return useQuery([userUrl], fetchUser);
}

function deleteUser(id: number): Promise<void> {
    return fetchNoContent(getDeleteUserUrl(id), {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize({id})),
    });
}

type DeleteUserMutationOptionsType = {
    ids: Array<number>;
    email?: string | null;
    onSuccess: () => void;
};

export function useDeleteUserMutation(
    options: DeleteUserMutationOptionsType
): UseMutationResult<void | Array<void>, unknown, number | null, unknown> {
    const {ids, email, onSuccess: handleSuccess} = options;

    const {companyName} = useDomainConfig();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    function mutate(id: number | null): Promise<void | Array<void>> {
        return new Promise((resolve) =>
            modal.confirm({
                title: (
                    <LocalePlural
                        count={ids.length}
                        manyKey="USERS__TABLE__DELETE_MODAL__TITLE__MANY"
                        singularKey="USERS__TABLE__DELETE_MODAL__TITLE__SINGULAR"
                    />
                ),
                content: (
                    <LocalePlural
                        count={ids.length}
                        manyKey="USERS__TABLE__DELETE_MODAL__DESCRIPTION__MANY"
                        singularKey="USERS__TABLE__DELETE_MODAL__DESCRIPTION__SINGULAR"
                        valueMap={{count: ids.length, companyName, email: <Text lighter>{email}</Text>}}
                    />
                ),
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                okText: <Locale stringKey="BUTTON__DELETE" />,
                okType: 'danger',
                onOk: () => {
                    if (id) {
                        resolve(deleteUser(id));
                    } else {
                        resolve(Promise.all(ids.map((innerId) => deleteUser(innerId))));
                    }
                },
            })
        );
    }

    function onSuccess() {
        snackbar.success({
            message: (
                <LocalePlural
                    count={ids.length}
                    manyKey="USERS__TABLE__DELETE_SNACKBAR__TITLE__MANY"
                    singularKey="USERS__TABLE__DELETE_SNACKBAR__TITLE__SINGULAR"
                />
            ),
            description: (
                <LocalePlural
                    count={ids.length}
                    manyKey="USERS__TABLE__DELETE_SNACKBAR__DESCRIPTION__MANY"
                    singularKey="USERS__TABLE__DELETE_SNACKBAR__DESCRIPTION__SINGULAR"
                    valueMap={{count: ids.length, email: <Text lighter>{email}</Text>}}
                />
            ),
        });

        handleSuccess();
    }

    return useMutation(mutate, {onSuccess});
}
