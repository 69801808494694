import {last} from 'lodash';

export const VIDEO_WIDTH = 887;
export const VIDEO_HEIGHT = 499;

type VideoDomainConfigType = Record<
    string,
    {
        getVideoId: (url: URL) => string | null;
        getEmbedLink: (id: string) => string;
    }
>;

export const VIDEO_DOMAIN_CONFIG: VideoDomainConfigType = {
    'youtube.com': {
        // https://www.youtube.com/watch?v=dQw4w9WgXcQ?someFlag => dQw4w9WgXcQ
        // https://www.youtube.com/embed/dQw4w9WgXcQ?someFlag => dQw4w9WgXcQ
        getVideoId: ({searchParams, pathname}: URL) => searchParams.get('v') || last(pathname.split('/')) || null,
        getEmbedLink: (id: string) => `https://www.youtube.com/embed/${id}`,
    },
    'loom.com': {
        getVideoId: ({pathname}: URL) => last(pathname.split('/')) || null,
        getEmbedLink: (id: string) => `https://www.loom.com/embed/${id}`,
    },
};
