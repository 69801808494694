import {PropsWithChildren} from 'react';

import {classNames} from '../../../../../../../util/css';

import * as styles from './review-icon-button.scss';

type PropsType = PropsWithChildren<{
    onClick: () => void;
    id?: string;
    className?: string;
}>;

export function ReviewIconButton(props: PropsType): JSX.Element {
    const {children, id, onClick, className} = props;
    const buttonClassNames = classNames(styles.review_icon_button, className);

    return (
        <button className={buttonClassNames} id={id} onClick={onClick} type="button">
            {children}
        </button>
    );
}
