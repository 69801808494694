import {FilterDropdownProps} from 'antd/lib/table/interface';

import {TableFilterDropdown} from '../../../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {AnalyticsTarget, track} from '../../../../../../service/analytics/analytics';

export function SourceFilterDropdown(props: FilterDropdownProps): JSX.Element {
    return (
        <TableFilterDropdown
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            trackConfirm={(selectedIds) => track(AnalyticsTarget.Sources.CatalogFilter, selectedIds)}
        />
    );
}
