import {Checkbox} from 'antd';

import {Locale} from '../../../../../../provider/locale/localization';
import {CompanyPaymentMethodV2Enum} from '../../../../../../service/company/company-type';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {LABEL_BY_METHOD} from '../../../../bulk-edit-companies/form-items/company-form-items/payment-methods-form-item/payment-methods-form-item-const';

import * as styles from './payment-methods-widget.scss';

export function PaymentMethodsWidget(): JSX.Element {
    const {cash, nonCash, card, emoney, crypto, bank} = CompanyPaymentMethodV2Enum;
    const methodsIds = [cash, card, nonCash, emoney, crypto, bank];

    return (
        <Form.Item<CompanyFormType> name={CompanyKeyEnum.Payments}>
            <Checkbox.Group
                className={styles.payment_methods_widget__checkbox_group}
                options={methodsIds.map((method) => ({
                    label: <Locale stringKey={LABEL_BY_METHOD[method]} />,
                    value: method,
                }))}
            />
        </Form.Item>
    );
}
