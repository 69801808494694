import {RcFile} from 'antd/lib/upload';

import {deserializeV2, fetchAndDeserialize, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    CreateYandexStoryRequestType,
    uploadStoryImageResponseSchema,
    UploadStoryImageResponseType,
    yandexAvailableCompaniesSchema,
    YandexAvailableCompaniesType,
    yandexSourcesStoriesResponseSchema,
    YandexSourcesStoriesResponseType,
    yandexStoriesResponseSchema,
    YandexStoriesResponseType,
    yandexStorySchema,
    yandexStorySelectedCompanies,
    YandexStorySelectedCompaniesType,
    YandexStoryType,
} from './yandex-stories-type';

export async function fetchDashboardYandexStories(page: number | null): Promise<YandexStoriesResponseType> {
    const url = `/cp/stories/stories/dashboard/?page=${page}`;

    return fetchAndDeserialize<YandexStoriesResponseType>(url, yandexStoriesResponseSchema);
}

export async function fetchYandexStorySelectedCompanies(
    storyId: number,
    page: number | null
): Promise<YandexStorySelectedCompaniesType> {
    const url = `/cp/stories/stories/${storyId}/companies_details/?page=${page}`;

    return fetchAndDeserialize<YandexStorySelectedCompaniesType>(url, yandexStorySelectedCompanies);
}

export async function fetchYandexSourcesStories(
    sourceIds: Array<number>
): Promise<Array<YandexSourcesStoriesResponseType>> {
    const url = `/cp/stories/location/stories/?${objectToUrlParameters({companies: sourceIds})}`;

    return fetchAndDeserialize<Array<YandexSourcesStoriesResponseType>>(
        url,
        yandexSourcesStoriesResponseSchema.array()
    );
}

export async function fetchYandexStoryById(storyId: number): Promise<YandexStoryType> {
    const url = `/cp/stories/stories/${storyId}/`;

    return fetchAndDeserialize<YandexStoryType>(url, yandexStorySchema);
}

export function deleteYandexStory(storyId: number): Promise<void> {
    const url = `${rootApiUrl}/cp/stories/stories/${storyId}/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchYandexStoryAvailableCompanies(selectorId: string): Promise<YandexAvailableCompaniesType> {
    const url = `/cp/stories/stories/statistics_by_companies/${selectorId}/`;

    return fetchAndDeserialize<YandexAvailableCompaniesType>(url, yandexAvailableCompaniesSchema);
}

export async function uploadStoryImage(file: RcFile): Promise<UploadStoryImageResponseType> {
    const url = `${rootApiUrl}/cp/stories/upload_image/`;

    const formData = new FormData();

    formData.append('image', file);

    const response = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {...getCsrfHeaders()},
        body: formData,
    });

    return deserializeV2<UploadStoryImageResponseType>(url, uploadStoryImageResponseSchema, response);
}

export async function createYandexStory(data: CreateYandexStoryRequestType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/cp/stories/stories/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export async function updateYandexStory(storyId: number, data: CreateYandexStoryRequestType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/cp/stories/stories/${storyId}/`, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
