import {Context} from 'chartjs-plugin-datalabels';

import {ChartTooltip} from '../../../../component/chart-tooltip/chart-tooltip';
import {ChartTooltipDivider} from '../../../../component/chart-tooltip/chart-tooltip-divider';
import {ChartTooltipRow} from '../../../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../../../component/chart-tooltip/chart-tooltip-title';
import {Text} from '../../../../component/text/text';
import {getDefaultReactDoughnutTooltipOptions} from '../../../../layout/chart/chart-const';
import {doughnutChartOptions} from '../../../../layout/chart/doughnut-chart/doughnut-chart-const';
import {
    ChartJsDoughnutOptionsType,
    DoughnutDataType,
} from '../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {customReactChartTooltip} from '../../../../layout/chart/utils/chart-helper';

import * as styles from './reviews-count-by-catalog.scss';

export function getReviewsByCountChartOptions(
    data: Array<DoughnutDataType>,
    tooltipTitle: string,
    pdfPreview = false
): ChartJsDoughnutOptionsType {
    return {
        ...doughnutChartOptions,
        animation: !pdfPreview,
        radius: 150,
        responsive: true,
        resizeDelay: 1000,
        aspectRatio: 1,
        spacing: 0,
        plugins: {
            ...doughnutChartOptions.plugins,
            tooltip: getDefaultReactDoughnutTooltipOptions({
                data,
                title: <Text bolder>{tooltipTitle}</Text>,
            }),
        },
    };
}

export function getReviewsByCountCompareChartOptions(options: {
    data: Array<
        DoughnutDataType & {
            compareValue: number | null;
        }
    >;
    mainPeriodTitle: string;
    comparePeriodTitle: string;
    pdfPreview?: boolean;
}): ChartJsDoughnutOptionsType {
    const {data, comparePeriodTitle, mainPeriodTitle, pdfPreview} = options;

    return {
        ...doughnutChartOptions,
        circumference: 180,
        radius: 150,
        spacing: 0,
        responsive: false,
        maintainAspectRatio: true,
        cutout: '30%',
        animation: !pdfPreview,
        plugins: {
            ...doughnutChartOptions.plugins,
            tooltip: {
                enabled: false,
                yAlign: 'top',
                xAlign: 'center',
                position: 'nearest',
                external: (context: Context) =>
                    customReactChartTooltip({
                        context,
                        componentRenderer: (index) => {
                            const itemValue = data?.[index];

                            return (
                                <ChartTooltip>
                                    <ChartTooltipTitle
                                        title={
                                            <div className={styles.ReviewByCatalog_tooltipTitle}>{mainPeriodTitle}</div>
                                        }
                                    />

                                    <ChartTooltipRow
                                        color={itemValue?.color}
                                        label={itemValue?.label || ''}
                                        value={itemValue?.value || 0}
                                    />

                                    <ChartTooltipDivider />

                                    <ChartTooltipTitle
                                        title={
                                            <div className={styles.ReviewByCatalog_tooltipTitle}>
                                                {comparePeriodTitle}
                                            </div>
                                        }
                                    />

                                    <ChartTooltipRow
                                        color={itemValue?.color}
                                        label={itemValue?.label || ''}
                                        value={itemValue?.compareValue || 0}
                                    />
                                </ChartTooltip>
                            );
                        },
                    }),
            },
        },
    };
}
