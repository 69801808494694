import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyErrorType, CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../../typings/antd';
import {TemporaryClosedFormItem} from '../temporary-closed-form-item/temporary-closed-form-item';
import {WorkdaysFormItem} from '../workdays-form-item/workdays-form-item';

import * as styles from './working-time-form-item.scss';

type PropsType = {
    errorMessage: CompanyErrorType | null;
    isDoubleGisCompany: boolean;
};

export function WorkingTimeFormItem(props: PropsType): JSX.Element {
    const {errorMessage, isDoubleGisCompany} = props;

    return (
        <>
            <TemporaryClosedFormItem name={CompanyKeyEnum.TemporaryClosed} />
            <Form.Item<CompanyFormType>
                className={styles.WorkingTimeFormItem_workDayList}
                name={CompanyKeyEnum.WorkingHours}
            >
                <WorkdaysFormItem errorMessage={errorMessage?.workingHours} isDoubleGisCompany={isDoubleGisCompany} />
            </Form.Item>
        </>
    );
}
