import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {userUrl} from './user-const';

const logoutSchema = r.object({
    result: r.literal('ok').or(r.literal('error')),
});

type LogoutType = r.infer<typeof logoutSchema>;

function logout(): Promise<LogoutType> {
    const parameters = objectToUrlParameters({'time-stamp': Date.now()});
    const url = `${userUrl}/logout/?${parameters}`;

    return fetchAndDeserialize(url, logoutSchema);
}

export function useLogoutMutation(): UseMutationResult<LogoutType, unknown, void> {
    function onSuccess(data: LogoutType) {
        if (data.result !== 'ok') {
            throw new Error('LogoutResult is not "ok"');
        }

        location.reload();
    }

    return useMutation(logout, {onSuccess});
}
