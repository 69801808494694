import {z as r} from 'zod';

export const reviewStatsDynamicSchema = r.array(
    r.object({
        date: r.string(),
        total: r.number(),
    })
);

export type ReviewStatsDynamicType = r.infer<typeof reviewStatsDynamicSchema>;
