import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useCallback, useEffect, useState} from 'react';

import {DomainNameEnum} from '../../provider/domain-config/domain-config-type';
import {useRefreshId} from '../../util/hook';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';
import {ExecuteRefreshReviewsType, FetchReviewsResultType} from '../reviews/reviews-type';

import {
    fetchLastReviews,
    fetchManyCompaniesGoodsStats,
    fetchReviewsDashboardStatistic,
    getAutoRepliesSummary,
    getCompanyInfo,
    getCompanyMapInfo,
    getCompanyRating,
    getCompanyStats,
    getCompanySummary,
    getCompanySyncStatistics,
    getDashboardSearchPhrases,
    getGiftInfo,
    getReviewsRatingDistribution,
    getTimelineForManyCompanies,
    giftUrl,
    loadCatalogsSyncStatistics,
    loadOnlinePresenceStatistics,
} from './dashboard-api';
import {
    AdditionalTimelineAggregatedResultType,
    AggregatedActionsType,
    AutoRepliesSummaryDatesDataType,
    AutoRepliesSummaryType,
    CatalogsSyncResponseType,
    CatalogStatisticsType,
    CompanyInfoType,
    CompanyMapType,
    CompanyRatingType,
    CompanyStatsType,
    CompanySummaryType,
    DashboardManyCompaniesGoodsStatsType,
    DashboardSearchPhrasesType,
    GiftInfoType,
    LoadTimelineDataOptionsType,
    OnlinePresencePropsType,
    OnlinePresenceResponseType,
    ReviewsBasePaginationType,
    ReviewsDashboardStatisticDataType,
    ReviewsDashboardStatisticsType,
    ReviewsRatingDistributionDatesDataType,
    ReviewsRatingDistributionType,
    SyncStatisticsType,
} from './dashboard-type';

export function useLastReviews(
    props: ReviewsBasePaginationType
): UseHookType<FetchReviewsResultType> & ExecuteRefreshReviewsType {
    const {pageSize, dateGte} = props;

    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<FetchReviewsResultType>();

    const refreshReviews = useCallback(() => {
        return fetchLastReviews({pageSize, dateGte})
            .then((response: FetchReviewsResultType) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [pageSize, dateGte, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);
        refreshReviews();
    }, [refreshReviews, pageSize, setIsInProgress, setProcessError]);

    return {isInProgress, processError, result, reset, refreshReviews};
}

export function useReviewsDashboardStatistic(
    props: ReviewsDashboardStatisticsType
): UseHookType<ReviewsDashboardStatisticDataType> {
    const {dateGte} = props;
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewsDashboardStatisticDataType>();

    const refreshStatistic = useCallback(() => {
        return fetchReviewsDashboardStatistic({dateGte})
            .then((response: ReviewsDashboardStatisticDataType) => {
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error);
            });
    }, [dateGte, setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);
        refreshStatistic();
    }, [dateGte, setIsInProgress, setProcessError, refreshStatistic]);

    return {isInProgress, processError, result, reset};
}

export function useCompanySyncStatistics(companyId: number): UseHookType<Array<SyncStatisticsType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<SyncStatisticsType>>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanySyncStatistics(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCompanyRating(companyId: number): UseHookType<Array<CompanyRatingType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CompanyRatingType>>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanyRating(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCatalogsSyncStatistics(props: CatalogStatisticsType): UseHookType<CatalogsSyncResponseType> {
    const {dateFrom, dateTo} = props;
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CatalogsSyncResponseType>();

    useEffect(() => {
        setIsInProgress(true);

        loadCatalogsSyncStatistics({dateFrom, dateTo})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [dateFrom, dateTo, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useAutoRepliesSummary(datesData: AutoRepliesSummaryDatesDataType): UseHookType<AutoRepliesSummaryType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<AutoRepliesSummaryType>();

    const {startDate, endDate} = datesData;

    useEffect(() => {
        setIsInProgress(true);

        getAutoRepliesSummary({startDate, endDate})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [startDate, endDate, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useReviewsRatingDistribution(
    datesData: ReviewsRatingDistributionDatesDataType
): UseHookType<Array<ReviewsRatingDistributionType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<ReviewsRatingDistributionType>>();

    const {startDate, endDate} = datesData;

    useEffect(() => {
        setIsInProgress(true);

        getReviewsRatingDistribution({startDate, endDate})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [startDate, endDate, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useDashboardSearchPhrases(): UseHookType<DashboardSearchPhrasesType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<DashboardSearchPhrasesType>();

    useEffect(() => {
        setIsInProgress(true);

        getDashboardSearchPhrases()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useDashboardManyCompaniesGoodsStat(): UseHookType<Array<DashboardManyCompaniesGoodsStatsType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<DashboardManyCompaniesGoodsStatsType>>();
    const getManyCompaniesGoodsStats = useCallback(() => {
        setIsInProgress(true);

        fetchManyCompaniesGoodsStats()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    useEffect(() => {
        getManyCompaniesGoodsStats();
    }, [getManyCompaniesGoodsStats]);

    return {isInProgress, processError, result, reset};
}

export function useTimelineForManyCompanies(
    perPage?: number
): UseHookType<Array<AggregatedActionsType>> & AdditionalTimelineAggregatedResultType {
    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<Array<AggregatedActionsType>>();
    const [nextCursor, setNextCursor] = useState<string | null>(null);
    const {refreshId, refresh} = useRefreshId();

    const loadData = useCallback(
        (options: LoadTimelineDataOptionsType = {}) => {
            const {cursor = null, shouldReplaceContent = true} = options;

            setIsInProgress(true);

            getTimelineForManyCompanies(cursor, perPage)
                .then((response) => {
                    setNextCursor(response.next);
                    setResult(shouldReplaceContent ? response.results : [...(result || []), ...response.results]);
                })
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [perPage, setIsInProgress, setProcessError, setResult, result]
    );

    function loadMore() {
        if (!isInProgress && result !== null && nextCursor !== null) {
            loadData({cursor: nextCursor, shouldReplaceContent: false});
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId]);

    return {isInProgress, processError, result, reset, loadMore, canLoadMore: nextCursor !== null, refresh};
}

export function useOnlinePresenceStatistics(props: OnlinePresencePropsType): UseHookType<OnlinePresenceResponseType> {
    const {dateFrom, dateTo} = props;
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<OnlinePresenceResponseType>();

    useEffect(() => {
        setIsInProgress(true);

        loadOnlinePresenceStatistics({dateFrom, dateTo})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [dateFrom, dateTo, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCompanyMapInfo(companyId: number): UseHookType<CompanyMapType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyMapType>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanyMapInfo(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCompanySummary(): UseHookType<CompanySummaryType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanySummaryType>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanySummary()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCompanyStats(companyId: number): UseHookType<CompanyStatsType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyStatsType>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanyStats(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useCompanyInfo(companyId: number): UseHookType<CompanyInfoType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyInfoType>();

    useEffect(() => {
        setIsInProgress(true);

        getCompanyInfo(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useGiftInfo(domainName: string): UseQueryResult<GiftInfoType> {
    return useQuery([giftUrl], getGiftInfo, {
        enabled: domainName === DomainNameEnum.brandWizard,
    });
}
