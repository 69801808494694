import {PropsWithChildren} from 'react';

import {classNames} from '../../util/css';

import * as styles from './page-header.scss';

type PropsType = PropsWithChildren<{
    className?: string;
}>;

export function PageSubHeader(props: PropsType): JSX.Element {
    const {className, children} = props;

    const fullClassName = classNames(styles.PageHeader_subHeader, className);

    return <h3 className={fullClassName}>{children}</h3>;
}
