import {Input} from 'antd';
import {useEffect} from 'react';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {CountryV2Type} from '../../../../../../service/api/api-type';
import {useLegalOptionDataV2List} from '../../../../../../service/api-hook/api-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {Form} from '../../../../../../typings/antd';

import {OrganizationalFormItem} from './organizational-form-item/organizational-form-item';
import * as styles from './legal-widget.scss';

const emptyValidator = {validator: () => Promise.resolve()}; // seems like the easiest hack to force revalidation on dependencies change

type PropsType = {
    country: CountryV2Type | null;
    clearLegalValue: () => void;
};

export function LegalWidgetItems(props: PropsType): JSX.Element {
    const {country, clearLegalValue} = props;

    const {getLocalizedString} = useLocale();
    const {result} = useLegalOptionDataV2List();

    const legalOptionDataList = result?.find(({alpha2}) => alpha2 === country?.countryCode)?.legal_forms ?? null;
    const disabled = !legalOptionDataList || legalOptionDataList.length === 0;

    useEffect(() => {
        if (result && legalOptionDataList === null) {
            clearLegalValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result, legalOptionDataList]);

    return (
        <>
            <Form.Item
                className={styles.legal_widget__input}
                dependencies={[[CompanyKeyEnum.Legal, 'name']]}
                label={<Locale stringKey="COMPANY_FORM__LEGAL__ORGANIZATIONAL_AND_LEGAL_FORM__LABEL" />}
                name={[CompanyKeyEnum.Legal, 'form']}
                rules={disabled ? [] : [emptyValidator]}
            >
                <OrganizationalFormItem country={country} />
            </Form.Item>

            <Form.Item
                className={styles.legal_widget__input}
                dependencies={[[CompanyKeyEnum.Legal, 'form']]}
                label={<Locale stringKey="COMPANY_FORM__LEGAL__LEGAL_NAME__LABEL" />}
                name={[CompanyKeyEnum.Legal, 'name']}
                rules={disabled ? [] : [emptyValidator]}
            >
                <Input
                    disabled={disabled}
                    placeholder={getLocalizedString('COMPANY_FORM__LEGAL__LEGAL_NAME__PLACEHOLDER')}
                    size="large"
                />
            </Form.Item>
        </>
    );
}
