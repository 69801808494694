import {z as r} from 'zod';

export const reviewsCountByCatalogSchema = r.object({
    count: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    byCatalogs: r.array(
        r.object({
            pk: r.number(),
            name: r.string(),
            count: r.object({
                value: r.number(),
                comparedValue: r.number().nullable(),
                delta: r.number().nullable(),
            }),
        })
    ),
});

export type ReviewsCountByCatalogType = r.infer<typeof reviewsCountByCatalogSchema>;
