import {Crop} from 'react-image-crop';

export type RealSizeType = {
    width: number;
    height: number;
};

export function getImageFromUrl(imageUrl: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve: (image: HTMLImageElement) => void, reject: () => void) => {
        const newImage = new Image();

        newImage.addEventListener('load', () => resolve(newImage), false);
        newImage.addEventListener('error', reject, false);

        newImage.src = imageUrl;
    });
}

type ValidCropType = {
    x: number;
    y: number;
    width: number;
    height: number;
};

function makeValidCrop(image: HTMLImageElement, crop: Crop): ValidCropType {
    const {width, height, x, y} = crop;

    if (
        typeof width !== 'number' ||
        typeof height !== 'number' ||
        typeof x !== 'number' ||
        typeof y !== 'number' ||
        width + height === 0
    ) {
        return {
            x: 0,
            y: 0,
            width: image.naturalWidth,
            height: image.naturalHeight,
        };
    }

    return {width, height, x, y};
}

export function getCropImage(imageUrl: string, crop: Crop, relativeRealSize: RealSizeType): Promise<Blob> {
    return getImageFromUrl(imageUrl).then((image: HTMLImageElement): Promise<Blob> => {
        const canvas = document.createElement('canvas');
        const {width, height, x, y} = makeValidCrop(image, crop);

        canvas.width = Math.ceil(width * relativeRealSize.width);
        canvas.height = Math.ceil(height * relativeRealSize.height);

        const canvasContext = canvas.getContext('2d');

        if (!canvasContext) {
            throw new Error('[ERROR]: getCropImage - can not get context');
        }

        canvasContext.drawImage(
            image,
            x * relativeRealSize.width,
            y * relativeRealSize.height,
            Math.ceil(width * relativeRealSize.width),
            Math.ceil(height * relativeRealSize.height),
            0,
            0,
            Math.ceil(width * relativeRealSize.width),
            Math.ceil(height * relativeRealSize.height)
        );

        return new Promise<Blob>((resolve: (blob: Blob) => void, reject: () => void): void => {
            canvasContext.canvas.toBlob((blob: Blob | null) => (blob ? resolve(blob) : reject()), 'image/jpeg', 1);
        });
    });
}

export function convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.addEventListener('load', () => resolve(String(reader.result)));
        reader.addEventListener('error', (error) => reject(error));
    });
}

export function convertFileToImage(file: File): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.addEventListener('loadend', () => {
            const readerResult = reader.result;
            const newImage = new Image();

            if (typeof readerResult === 'string') {
                newImage.src = readerResult;
                newImage.addEventListener('load', () => resolve(newImage), false);
                newImage.addEventListener('error', () => reject(new Error('error loading image')), false);
                return;
            }

            reject(new Error('error loading image'));
        });
        reader.addEventListener('error', () => {
            reject(new Error('error reading file'));
        });
    });
}
