import {faFileArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Locale} from '../../../../../../../../provider/locale/locale';
import {classNames} from '../../../../../../../../util/css';

import * as styles from './upload-button.scss';

type PropsType = {
    className?: string;
};

export function UploadButton(props: PropsType): JSX.Element {
    const {className} = props;

    return (
        <div className={classNames(styles.upload_button, className)}>
            <FontAwesomeIcon className={styles.upload_button_icon} icon={faFileArrowUp} />

            <Locale stringKey="BUTTON__UPLOAD" />
        </div>
    );
}
