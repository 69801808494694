import {faUserShield} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, FormInstance, Input, Modal, Table} from 'antd';
import {useEffect, useState} from 'react';

import {useCatalogInfo} from '../../../../../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../../../../provider/user/user-hook';
import {CompaniesSelectorFeatureEnum} from '../../../../../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../../../../../service/feature-companies/feature-companies-hook';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {
    getAccountListBrandAlreadySelected,
    getAccountListCompanyAlreadySelected,
    rebindBrands,
    rebindCompanies,
} from '../../../../../../../../service/source-settings/accounts/accounts-api';
import {
    AccountBrandAlreadySelectedType,
    AccountCompanyAlreadySelectedType,
} from '../../../../../../../../service/source-settings/accounts/accounts-type';
import {CatalogByGroupType} from '../../../../../../../../service/source-settings/catalogs/catalogs-type';
import {ApiError} from '../../../../../../../../util/error';

import {AccountBrandTabs} from './account-brand-tabs/account-brand-tabs';
import {AccountLoginLabel} from './account-login-label/account-login-label';
import {
    getAccountAlreadySelectedColumns,
    isAccountCompanyAlreadySelectedType,
    isAccountEditError,
} from './manage-account-form-helper';
import {
    AccountSelectorCompanyType,
    ManageAccountFormEnum,
    ManageAccountFormType,
    ManageTypeAccountEnum,
} from './manage-account-form-type';
import * as styles from './manage-account-form.scss';

type CreatePropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    formId: string;
    initialValues?: undefined;
    isEditMode: boolean;
    onSubmit: (values: ManageAccountFormType) => void;
};

type EditPropsType = Omit<CreatePropsType, 'isEditMode' | 'initialValues'> & {
    isEditMode: true;
    initialValues: Omit<ManageAccountFormType, ManageAccountFormEnum.password | ManageAccountFormEnum.uuid> & {
        defaultActiveKey: ManageTypeAccountEnum;
        companyCount: number;
        accountId: number;
    };
};

// eslint-disable-next-line max-statements
export function ManageAccountForm(props: CreatePropsType | EditPropsType): JSX.Element {
    const {catalog, catalogGroupId, initialValues, isEditMode, formId, onSubmit} = props;
    const {accountId, companyCount} = initialValues || {companyCount: 0};

    const {getLocalizedString} = useLocale();
    const {companyName} = useDomainConfig();
    const {snackbar} = useSnackbar();
    const {user} = useUser();

    const conditionTabs =
        catalog.supportCompaniesReviews && catalog.supportBrandsReviews
            ? {initActiveTab: ManageTypeAccountEnum.brand, disabledTab: null}
            : catalog.supportCompaniesReviews
            ? {initActiveTab: ManageTypeAccountEnum.company, disabledTab: ManageTypeAccountEnum.brand}
            : {initActiveTab: ManageTypeAccountEnum.brand, disabledTab: ManageTypeAccountEnum.company};

    const [activeTab, setActiveTab] = useState(initialValues?.defaultActiveKey || conditionTabs.initActiveTab);
    const [companiesOrBrandsAlreadySelected, setCompaniesOrBrandsAlreadySelected] = useState<
        Array<AccountCompanyAlreadySelectedType | AccountBrandAlreadySelectedType>
    >([]);
    const [isOpenAlreadySelectedModal, setIsOpenAlreadySelectedModal] = useState(false);
    const [withSourceSettingsAccounts, setWithSourceSettingsAccounts] = useState(false);

    const [form]: [FormInstance<ManageAccountFormType>] = Form.useForm();
    const {requiredFieldRule, minLengthFieldRule} = useFormRules();
    const catalogId = catalog.id;
    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    const companiesSelector = useCompaniesSelector<AccountSelectorCompanyType>({
        feature: CompaniesSelectorFeatureEnum.SourceSettingsAccounts,
        resourceId: accountId ? String(accountId) : null,
        initialCompaniesCount: companyCount,
        withSourceSettingsAccounts,
        catalogId,
    });

    const {initializeSelector, selectedCount, selectorId} = companiesSelector;
    const isActiveBrandTab = activeTab === ManageTypeAccountEnum.brand;

    useEffect(() => {
        initializeSelector();
    }, []);

    useEffect(() => {
        form.setFieldValue('companies', selectedCount);
    }, [selectedCount]);

    async function handleSubmit(values: ManageAccountFormType) {
        try {
            const response = isActiveBrandTab
                ? await getAccountListBrandAlreadySelected({
                      brandsIds: values[ManageAccountFormEnum.brandsId],
                      catalogId,
                      accountId,
                  })
                : await getAccountListCompanyAlreadySelected({
                      uuid: selectorId,
                      catalogId,
                      accountId,
                  });

            if (response.length > 0) {
                setCompaniesOrBrandsAlreadySelected(response);
                setIsOpenAlreadySelectedModal(true);
                return;
            }

            onSubmit({...values, uuid: selectorId});
        } catch (error: unknown) {
            if (error instanceof ApiError && isAccountEditError(error.jsonData)) {
                snackbar.error(error.jsonData.status);
            }

            console.log(error);
        }
    }

    function handleAccountCheckbox(checked: boolean) {
        setWithSourceSettingsAccounts(checked);
    }

    async function handleItemAlreadySelected() {
        try {
            const accountsIds = companiesOrBrandsAlreadySelected.map((item) => item.account.id);
            const rebindItemsId = companiesOrBrandsAlreadySelected.map((item) =>
                isAccountCompanyAlreadySelectedType(item) ? item.company.id : item.brand.id
            );

            await (isActiveBrandTab
                ? rebindBrands({
                      catalogId,
                      accountsIds: [...new Set(accountsIds)],
                      brandsIds: rebindItemsId,
                  })
                : rebindCompanies({
                      accountsIds: [...new Set(accountsIds)],
                      catalogId,
                      companiesIds: rebindItemsId,
                  }));

            setIsOpenAlreadySelectedModal(false);
        } catch (error) {
            if (error instanceof ApiError && isAccountEditError(error.jsonData)) {
                snackbar.error(error.jsonData.status);
            }

            console.log(error);
        }
    }

    function handleChangeTabs(activeTabInner: ManageTypeAccountEnum) {
        if (isEditMode || !(catalog.supportCompaniesReviews && catalog.supportBrandsReviews)) {
            return;
        }

        setActiveTab(activeTabInner);
    }

    return (
        <>
            <p className={styles.ManageAccount_catalog}>
                <img alt="logo" src={getCatalogLogo(catalog.id)} />
                {getCatalogName(catalog.id)}
            </p>
            <p className={styles.ManageAccount_help}>
                <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT__MODAL_TEXT" valueMap={{companyName}} />
            </p>

            <Form<ManageAccountFormType>
                disabled={user?.isTestUser}
                form={form}
                id={formId}
                initialValues={initialValues}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item<ManageAccountFormType>
                    label={<AccountLoginLabel isEditMode={isEditMode} />}
                    name={ManageAccountFormEnum.login}
                    rules={[requiredFieldRule]}
                >
                    <Input placeholder={getLocalizedString('SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_PLACEHOLDER')} />
                </Form.Item>

                <Form.Item<ManageAccountFormType>
                    label={
                        isEditMode ? (
                            <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__EDIT__PASSWORD_INPUT" />
                        ) : (
                            <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD__PASSWORD_INPUT" />
                        )
                    }
                    name={ManageAccountFormEnum.password}
                    rules={[requiredFieldRule, minLengthFieldRule(6)]}
                    validateFirst
                >
                    <Input.Password
                        placeholder={getLocalizedString('SOURCE_SETTINGS__ACCOUNTS__ADD__PASSWORD_PLACEHOLDER')}
                    />
                </Form.Item>

                <AccountBrandTabs
                    activeTab={activeTab}
                    catalogGroupId={catalogGroupId}
                    checkboxValue={withSourceSettingsAccounts}
                    companiesSelector={companiesSelector}
                    disabledTab={conditionTabs.disabledTab}
                    onChangeCompanyCheckbox={handleAccountCheckbox}
                    onChangeTabs={handleChangeTabs}
                    onCompanyModalOk={() => form.validateFields(['companies'])}
                />
            </Form>

            <p className={styles.ManageAccount_encryption}>
                <FontAwesomeIcon className={styles.ManageAccount_encryptionIcon} icon={faUserShield} />
                <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT__MODAL_DISCLAIMER" />
            </p>

            <Modal
                cancelText={<Locale stringKey="POPUP__BUTTON__CANCEL" />}
                okText={<Locale stringKey="POPUP__BUTTON__CONFIRM" />}
                onCancel={() => setIsOpenAlreadySelectedModal(false)}
                onOk={handleItemAlreadySelected}
                open={isOpenAlreadySelectedModal}
                title={
                    <Locale
                        stringKey={
                            isActiveBrandTab
                                ? 'SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__TITLE_BRAND'
                                : 'SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__TITLE'
                        }
                    />
                }
            >
                <div>
                    <Locale
                        stringKey={
                            isActiveBrandTab
                                ? 'SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__CONTENT_BRAND'
                                : 'SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__CONTENT'
                        }
                    />
                </div>

                <Table<AccountCompanyAlreadySelectedType | AccountBrandAlreadySelectedType>
                    bordered
                    className={styles.ManageAccount_table}
                    columns={getAccountAlreadySelectedColumns(isActiveBrandTab, getCatalogName)}
                    dataSource={companiesOrBrandsAlreadySelected}
                    pagination={false}
                    scroll={{
                        y: 380,
                    }}
                    size="small"
                />
            </Modal>
        </>
    );
}
