import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchAverageResponseTimeByEmployee} from './average-response-time-by-employee-api';
import {AverageResponseTimeByEmployeeType} from './average-response-time-by-employee-type';

export function useAverageResponseTimeByEmployee(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<Array<AverageResponseTimeByEmployeeType>> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['averageResponseTimeByEmployee', filter, mainFilterKey],
        queryFn: async () => {
            return fetchAverageResponseTimeByEmployee(filter, mainFilterKey);
        },
    });
}
