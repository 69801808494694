import {z as r} from 'zod';

import {CompanyKeyEnum} from '../company-const';

const companyPhoneSchema = r.object({
    phone: r.string(),
    isMain: r.boolean(),
});

export type CompanyPhoneType = r.infer<typeof companyPhoneSchema>;

const companyCatalogPhonesSchema = r.object({
    catalogId: r.number(),
    phones: r.array(companyPhoneSchema).optional(),
});

const companyCatalogEmailSchema = r.object({
    catalogId: r.number(),
    email: r.string().nullable(),
});

export const companyContactsSchema = {
    [CompanyKeyEnum.Phones]: r.array(companyPhoneSchema),
    [CompanyKeyEnum.ExtraPhones]: r.array(companyCatalogPhonesSchema),
    [CompanyKeyEnum.Email]: r.string().nullable(),
    [CompanyKeyEnum.ExtraEmails]: r.array(companyCatalogEmailSchema),
};

const companyContactsSchemaObject = r.object(companyContactsSchema);

export type CompanyContactsType = r.infer<typeof companyContactsSchemaObject>;

export type CompanyContactsServerType = CompanyContactsType;
