import {Button} from 'antd';

import {Locale} from '../../provider/locale/localization';

import errorImageSrc from './images/error.svg';
import * as styles from './widget-error.scss';

function reloadPage() {
    window.location.reload();
}

export function WidgetError(): JSX.Element {
    return (
        <div className={styles.WidgetError}>
            <img alt="" src={errorImageSrc} />
            <p className={styles.WidgetError_title}>
                <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />
            </p>
            <p className={styles.WidgetError_text}>
                <Locale stringKey="ERROR__CAN_NOT_LOAD_WIDGET" />
            </p>
            <Button className={styles.WidgetError_button} onClick={reloadPage}>
                <Locale stringKey="BUTTON__RELOAD_PAGE" />
            </Button>
        </div>
    );
}
