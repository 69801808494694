import {Button, Modal} from 'antd';

import {Text} from '../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {FeaturesEnum} from '../../../../../service/user/user-type';
import * as styles from '../add-more-replies/add-more-replies.scss';
import repliesModalImageSrc from '../add-more-replies/images/replies-modal-image.svg';

type PropsType = {
    isOpen: boolean;
    onClose: VoidFunction;
};

export function AddMoreRepliesModal(props: PropsType): JSX.Element {
    const {isOpen, onClose} = props;
    const {featureActivation} = useDocumentationLinks();

    return (
        <Modal
            className={styles.DashboardAddMoreReplies_modalContent}
            footer={[
                <Button key="submit" onClick={onClose} type="primary">
                    <Locale stringKey="POPUP__BUTTON__OK" />
                </Button>,
            ]}
            onCancel={onClose}
            open={isOpen}
            title={<Locale stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__TITLE" />}
        >
            <div className={styles.DashboardAddMoreReplies_imageContainer}>
                <img alt="" src={repliesModalImageSrc} />
            </div>
            <div className={styles.DashboardAddMoreReplies_modalTextContainer}>
                <Text block bolder stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__HEADER" />
                <Text block secondary stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__TEXT_PART1" />
                <Text block secondary stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__TEXT_PART2" />
            </div>
            {featureActivation[FeaturesEnum.reviewSuggests] && (
                <p>
                    <a href={featureActivation[FeaturesEnum.reviewSuggests]} rel="noreferrer" target="_blank">
                        <Locale stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__LINK_TEXT" />
                    </a>
                </p>
            )}
        </Modal>
    );
}
