import {faChevronLeft, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Select} from 'antd';

import {Locale} from '../../provider/locale/localization';
import {classNames} from '../../util/css';
import {CursorPaginationHookType} from '../../util/cursor-pagination/cursor-pagination-type';

import * as styles from './cursor-paginator.scss';

type PropsType = {
    cursorPagination: CursorPaginationHookType;
    pageSizeOptions: [number, ...Array<number>];
    lastPage?: number;
    dataLength?: number;
    className?: string;
};

export function CursorPaginator(props: PropsType): JSX.Element {
    const {cursorPagination, pageSizeOptions, lastPage, dataLength, className} = props;
    const {
        pageSize,
        previousPageCursor,
        nextPageCursor,
        changePage,
        changePagePagination,
        updatePageSize,
        changeResultPage,
        resultPage,
        nextPage,
        page,
    } = cursorPagination;

    function nextPageHandler() {
        changePage(nextPageCursor || '');
        changeResultPage(resultPage + 1);

        if (nextPage) {
            changePagePagination(page + 1);
        }
    }

    function previousPageHandler() {
        changePage(previousPageCursor || '');
        changePagePagination(page - 1);
        changeResultPage(resultPage - 1);
    }

    const isNextPageDisabled: boolean =
        lastPage && dataLength ? lastPage <= resultPage && dataLength / pageSize < 1 : nextPageCursor === '';

    return (
        <div className={classNames(styles.Pagination, className)}>
            <Button
                className={styles.Pagination_button}
                disabled={!previousPageCursor && resultPage - 1 <= 0}
                onClick={previousPageHandler}
            >
                <FontAwesomeIcon fixedWidth icon={faChevronLeft} />
            </Button>

            <Button className={styles.Pagination_button} disabled={isNextPageDisabled} onClick={nextPageHandler}>
                <FontAwesomeIcon fixedWidth icon={faChevronRight} />
            </Button>

            <Select
                onChange={(newValue) => {
                    updatePageSize(newValue);
                }}
                value={pageSize}
            >
                {pageSizeOptions.map((pageSizeOption) => (
                    <Select.Option key={pageSizeOption} value={pageSizeOption}>
                        <Locale stringKey="TABLE__PAGINATION__PAGE_SIZE" valueMap={{pageSize: pageSizeOption}} />
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
}
