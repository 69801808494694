import {PropsWithChildren} from 'react';

import {classNames} from '../../util/css';

import * as styles from './chart-tooltip.scss';

type PropsType = PropsWithChildren<Record<string, unknown>> & {
    className?: string;
};

export function ChartTooltip(props: PropsType): JSX.Element {
    const {children, className} = props;

    return <div className={classNames(styles.ChartTooltip_container, className)}>{children}</div>;
}
