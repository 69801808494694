import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dayjs} from 'dayjs';
import {forwardRef} from 'react';

import {Locale} from '../../../../provider/locale/localization';
import {FakeClientStatusEnum, FakeTypeEnum} from '../../../../service/fakes/fakes-type';

import {fakesReportIcons} from './fakes-report-const';
import {FakesReportSummary} from './summary/fakes-report-summary';
import {FakesReportTable} from './table/fakes-report-table';
import * as styles from './fakes-report.scss';

type PropsType = {
    dateRange: [Dayjs, Dayjs] | null;
    brandIds?: Array<number>;
};

export const FakesReport = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const {dateRange, brandIds} = props;

    return (
        <div className={styles.FakesReport} ref={ref}>
            <div className={styles.FakesReport_pageBreak}>
                <FakesReportSummary brandIds={brandIds} dateRange={dateRange} />
            </div>

            <FakesReportTable
                dateRange={dateRange}
                description={<Locale stringKey="FAKES__REPORT__FAKES__DESCRIPTION" />}
                filter={{
                    type: FakeTypeEnum.Fake,
                    clientStatuses: [FakeClientStatusEnum.ClaimApproved],
                    brandIds,
                }}
                icon={<FontAwesomeIcon className={styles.FakesReport_icon__green} icon={fakesReportIcons.approved} />}
                locationColumnTitle={<Locale stringKey="FAKES__REPORT__FAKES__LOCATION" />}
                title={<Locale stringKey="FAKES__REPORT__FAKES__TITLE" />}
            />

            <FakesReportTable
                dateRange={dateRange}
                description={<Locale stringKey="FAKES__REPORT__DUPLICATES__DESCRIPTION" />}
                filter={{
                    type: FakeTypeEnum.Duplicate,
                    clientStatuses: [FakeClientStatusEnum.ClaimApproved],
                    brandIds,
                }}
                icon={<FontAwesomeIcon className={styles.FakesReport_icon__green} icon={fakesReportIcons.approved} />}
                locationColumnTitle={<Locale stringKey="FAKES__REPORT__DUPLICATES__LOCATION" />}
                title={<Locale stringKey="FAKES__REPORT__DUPLICATES__TITLE" />}
            />

            <FakesReportTable
                dateRange={dateRange}
                description={<Locale stringKey="FAKES__REPORT__REJECTED__DESCRIPTION" />}
                filter={{clientStatuses: [FakeClientStatusEnum.ClaimRejected], brandIds}}
                icon={<FontAwesomeIcon className={styles.FakesReport_icon__orange} icon={fakesReportIcons.rejected} />}
                locationColumnTitle={<Locale stringKey="FAKES__REPORT__DUPLICATES__LOCATION" />}
                title={<Locale stringKey="FAKES__REPORT__REJECTED__TITLE" />}
            />

            <FakesReportTable
                dateRange={dateRange}
                description={<Locale stringKey="FAKES__REPORT__HIDDEN__DESCRIPTION" />}
                filter={{clientStatuses: [FakeClientStatusEnum.Hidden], brandIds}}
                icon={<FontAwesomeIcon className={styles.FakesReport_icon__gray} icon={fakesReportIcons.hidden} />}
                locationColumnTitle={<Locale stringKey="FAKES__REPORT__DUPLICATES__LOCATION" />}
                title={<Locale stringKey="FAKES__REPORT__HIDDEN__TITLE" />}
            />
        </div>
    );
});

FakesReport.displayName = 'FakesReport';
