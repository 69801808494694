import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import * as styles from '../review.scss';
import {constructCommentString} from '../review-helper';

type PropsType = {
    text: string;
};

export function CopyComment(props: PropsType): JSX.Element {
    const {text} = props;
    const {snackbar} = useSnackbar();

    async function onCopy() {
        const commentString = constructCommentString(text);

        if (typeof navigator?.clipboard?.writeText !== 'function') {
            snackbar.error(<Locale stringKey="REVIEWS__REVIEW_COPY__BROWSER_ERROR" />);
            return;
        }

        try {
            await navigator.clipboard.writeText(commentString);
            snackbar.success(<Locale stringKey="REVIEWS__REVIEW_COPY__SUCCESS" />);
        } catch (error: unknown) {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            });

            throw error;
        }
    }

    return (
        <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_COPY__HINT" />}>
            <button className={styles.Review_iconButton} onClick={onCopy} type="button">
                <FontAwesomeIcon icon={faCopy} />
            </button>
        </Tooltip>
    );
}
