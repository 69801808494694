import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';
import {ReviewsAnalysisRadarQueryType} from '../review-radar/review-radar-type';

import {fetchReviewStatsDynamic} from './review-stats-dynamic-api';
import {ReviewStatsDynamicType} from './review-stats-dynamic-type';

export function useReviewStatsDynamic(
    options: ReviewsAnalysisRadarQueryType,
    mainFilterKey: string
): UseHookType<ReviewStatsDynamicType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewStatsDynamicType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewStatsDynamic(options, mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult, options, mainFilterKey]);

    return {isInProgress, processError, result, reset};
}
