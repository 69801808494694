export const enIntegrationTranslations = {
    INTEGRATIONS__ALERT__HEADER: 'Integration connection',
    INTEGRATIONS__ALERT__TEXT: 'Contact our support team to enable this integration.',
    INTEGRATIONS__HEADER: 'Integration settings',
    INTEGRATIONS__SUB_HEADER: 'Here you can integrate {companyName} with your CRM system or other software.',
    INTEGRATIONS__INTEGRATOR_NAME__USEDESK: 'Usedesk',
    INTEGRATIONS__INTEGRATOR_NAME__INTRASERVICE: 'IntraService',
    INTEGRATIONS__INTEGRATOR_NAME__ZENDESK: 'Zendesk',
    INTEGRATIONS__INTEGRATOR_NAME__FRESHDESK: 'Freshdesk',
    INTEGRATIONS__INTEGRATOR_NAME__NAUMEN: 'Naumen',
    INTEGRATIONS__INTEGRATOR_NAME__PYRUS: 'Pyrus',
    INTEGRATIONS__INTEGRATOR_NAME__YOUR_SYSTEM: 'Your system',
    INTEGRATIONS__INTEGRATOR_NAME__API_INTEGRATION: 'API integration',
    INTEGRATIONS__USEDESK__SAVED: 'Settings successfully saved',
    INTEGRATIONS__USEDESK__REJECTED: 'An error occurred while trying to save data',
    INTEGRATIONS__USEDESK__VALIDATION_ERROR__NOTIFICATIONS: 'Validation error on tab "Notifications filters"',
    INTEGRATIONS__USEDESK__VALIDATION_ERROR__GENERAL: 'Validation error on tab "Integration settings"',
    INTEGRATION__USEDESK__HEADER: 'Integration settings with Usedesk',
    INTEGRATION__USEDESK__SUB_HEADER: 'Enter data for integration.',
    INTEGRATION__USEDESK__SUB_HEADER__LINK: 'Where can I get data for integration?',
    INTEGRATION__USEDESK__LABEL__SECRET_KEY: 'Secret key',
    INTEGRATION__USEDESK__PLACEHOLDER__SECRET_KEY: 'Key Usedesk API',
    INTEGRATION__USEDESK__LABEL__APPLICATION_ID: 'Application ID',
    INTEGRATION__USEDESK__PLACEHOLDER__APPLICATION_ID: 'Application ID',
    INTEGRATION__USEDESK__LABEL__CHAT_ID: 'Chat ID',
    INTEGRATION__USEDESK__PLACEHOLDER__CHAT_ID: 'Chat ID',
    INTEGRATION__USEDESK__LABEL__WEBHOOK_LINK: 'Webhook link',
    INTEGRATION__USEDESK__PLACEHOLDER__WEBHOOK_LINK: 'Webhook link',
    INTEGRATION__USEDESK__NOTIFICATION__SUB_HEADER: 'You may configure a filter to receive reviews in UseDesk',
    INTEGRATION__USEDESK__SETTINGS_OPTION__GENERAL: 'Integration settings',
    INTEGRATION__USEDESK__SETTINGS_OPTION__NOTIFICATIONS: 'Filter Reviews',
    INTEGRATION__INTRASERVICE__HEADER: 'Integration settings with IntraService',
    INTEGRATION__INTRASERVICE__SUB_HEADER: 'Enter data for integration.',
    INTEGRATION__INTRASERVICE__SUB_HEADER__LINK: 'Where can I get data for integration?',
    INTEGRATION__INTRASERVICE__ENABLE_INTEGRATION: 'Enable Integration',
    INTEGRATION__INTRASERVICE__LABEL__LINK_TO_YOUR_INTRASERVICE: 'Link to your IntraService',
    INTEGRATION__INTRASERVICE__PLACEHOLDER__LINK_TO_YOUR_INTRASERVICE: 'Link to your IntraService',
    INTEGRATION__INTRASERVICE__LABEL__LOGIN_FOR_INTRASERVICE: 'Login for IntraService',
    INTEGRATION__INTRASERVICE__PLACEHOLDER__LOGIN_FOR_INTRASERVICE: 'Login for IntraService',
    INTEGRATION__INTRASERVICE__LABEL__PASSWORD_FOR_INTRASERVICE: 'Password for IntraService',
    INTEGRATION__INTRASERVICE__PLACEHOLDER__PASSWORD_FOR_INTRASERVICE: 'Password for IntraService',
};
