import {CompaniesValidationOptionsType} from '../../../../../../../service/companies-import/companies-import';
import {FeedResultsTypeEnum} from '../feed-results-const';

import {FeedResultsDescriptions} from './source/descritpions/feed-results-descriptions';
import {FeedResultsSource} from './source/feed-results-source';

type PropsType = {
    importUrlId: number | string | null;
    source: CompaniesValidationOptionsType;
    companiesCount: Record<'withoutErrors' | 'withErrors' | 'criticalErrors' | 'duplicates', number>;
    type: FeedResultsTypeEnum;
};

export function FeedResultsSummary(props: PropsType): JSX.Element {
    const {importUrlId, source, companiesCount, type} = props;

    return (
        <>
            <FeedResultsSource source={source} />

            <FeedResultsDescriptions
                companiesCount={companiesCount}
                importUrlId={importUrlId}
                isValidationResult
                type={type}
            />
        </>
    );
}
