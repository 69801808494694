import {useMemo} from 'react';
import {Tooltip} from 'react-leaflet';

import {Locale} from '../../../../provider/locale/localization';
import {LocalPackGridItemStatusEnum, LocalPackGridItemType} from '../../../../service/local-pack/local-pack-type';
import {LocalPackResultIcon} from '../local-pack-result-icon/local-pack-result-icon';

import * as styles from './local-pack-result-map-tooltip.scss';

type PropsType = {
    gridItem: LocalPackGridItemType;
};

export function LocalPackResultMapTooltip(props: PropsType): JSX.Element {
    const {gridItem} = props;

    const {searchResults, status, isAd} = gridItem;

    const tooltipContent = useMemo(() => {
        switch (true) {
            case status === LocalPackGridItemStatusEnum.New:
                return (
                    <div className={styles.ResultsOnMapTooltip_content}>
                        <Locale stringKey="LOCAL_PACK__MAP__STATUS__NEW" />
                    </div>
                );
            case isAd:
            case status === LocalPackGridItemStatusEnum.Complete:
                return (
                    <>
                        {searchResults.map((searchResult) => (
                            <div className={styles.ResultsOnMapTooltip_content} key={searchResult.id}>
                                <LocalPackResultIcon isAd={searchResult.isAd} position={searchResult.position} />
                                <span className={styles.ResultsOnMapTooltip_name}>{searchResult.name}</span>
                            </div>
                        ))}
                    </>
                );
            case status === LocalPackGridItemStatusEnum.Empty:
                return (
                    <div className={styles.ResultsOnMapTooltip_content}>
                        <Locale stringKey="LOCAL_PACK__MAP__STATUS__EMPTY" />
                    </div>
                );
            default:
                return (
                    <div className={styles.ResultsOnMapTooltip_content}>
                        <Locale stringKey="LOCAL_PACK__MAP__STATUS__FAILED" />
                    </div>
                );
        }
    }, [isAd, searchResults, status]);

    return (
        <Tooltip className={styles.ResultsOnMapTooltip} direction="auto">
            {tooltipContent}
        </Tooltip>
    );
}
