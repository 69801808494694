import {Switch} from 'antd';
import {ReactNode, useCallback} from 'react';

import {Locale} from '../../../../provider/locale/locale';
import {WEEK_DAY_BY_ID} from '../../../../provider/locale/locale-context-const';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {classNames} from '../../../../util/css';
import {AntdErrorMessage} from '../../../antd-error-message/antd-error-message';
import {BreakTimeList} from '../break-time-list/break-time-list';
import {WorkTime} from '../work-time/work-time';
import {WorkDayDataType, WorkDayTimeRangeType, WorkTimeDateType} from '../workdays-form-item-type';

import * as styles from './work-day.scss';

type PropsType = {
    onChange: (newItem: WorkDayDataType) => void;
    workDayData: WorkDayDataType;
    className?: string;
    errorMessage?: ReactNode;
    disableBreaks?: boolean;
    isDisabled?: boolean;
    allowClear: boolean;
    order: boolean;
    openStateMessageKeys: {
        open: LangKeyType;
        closed: LangKeyType;
    };
};

export function WorkDay(props: PropsType): JSX.Element {
    const {
        className,
        workDayData,
        onChange,
        errorMessage,
        disableBreaks,
        isDisabled,
        allowClear,
        order,
        openStateMessageKeys,
    } = props;
    const {day, timeFrom, timeTo, breakTimeList, isAroundTheClock, isOpen} = workDayData;

    const fullClassName = classNames(styles.WorkDay, errorMessage ? styles.error : '', className);

    const updateTime = useCallback(
        (newTime: WorkTimeDateType) => {
            onChange({...workDayData, ...newTime});
        },
        [workDayData, onChange]
    );

    const setBreakTimeList = useCallback(
        (newBreakTimeList: Array<WorkDayTimeRangeType>) => {
            onChange({...workDayData, breakTimeList: newBreakTimeList});
        },
        [workDayData, onChange]
    );

    return (
        <div className={styles.WorkDay_container}>
            <div className={fullClassName}>
                <div className={styles.WorkDay_day}>
                    <Switch
                        checked={isOpen}
                        className={styles.WorkDay_daySwitcher}
                        disabled={isDisabled}
                        onChange={() => onChange({...workDayData, isOpen: !isOpen})}
                    />

                    <div className={styles.WorkDay_weekDayWrapper}>
                        <p className={styles.WorkDay_weekDayName}>
                            <Locale stringKey={WEEK_DAY_BY_ID[day]} />
                        </p>

                        <p className={styles.WorkDay_weekDayOpenState}>
                            <Locale stringKey={isOpen ? openStateMessageKeys.open : openStateMessageKeys.closed} />
                        </p>
                    </div>
                </div>

                {isOpen && (
                    <>
                        <WorkTime
                            allowClear={allowClear}
                            className={styles.WorkDay_time}
                            idDisabled={isDisabled}
                            isAroundTheClock={isAroundTheClock}
                            order={order}
                            timeFrom={timeFrom}
                            timeTo={timeTo}
                            updateTime={updateTime}
                        />

                        {!disableBreaks && (
                            <BreakTimeList
                                breakTimeList={breakTimeList}
                                className={styles.WorkDay_breakTime}
                                setBreakTimeList={setBreakTimeList}
                            />
                        )}
                    </>
                )}
            </div>

            {errorMessage && <AntdErrorMessage>{errorMessage}</AntdErrorMessage>}
        </div>
    );
}
