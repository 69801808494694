import {LogoAnimated} from '../../../../../../component/logo-animated/logo-animated';
import {classNames} from '../../../../../../util/css';

import {ReactComponent as LogoTextSvg} from './image/logo-text.svg';
import * as styles from './logo-full.scss';

type PropsType = {
    className?: string;
};

export function LogoFull(props: PropsType): JSX.Element {
    const {className} = props;

    const fullClassName = classNames(styles.LogoFull, className);
    const titleClassName = classNames(styles.LogoFull_title, className);

    return (
        <div className={fullClassName}>
            <LogoAnimated />
            <div className={titleClassName}>
                <LogoTextSvg />
            </div>
        </div>
    );
}
