import {faCheck, faCheckDouble, faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Flex, Space, Tag, Tooltip} from 'antd';
import {useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {orange50, orange95, spacer_s} from '../../../../../../../css/var-export.scss';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {ReviewDataChildType, ReviewReplyTypeAuthorEnum} from '../../../../../../../service/reviews/reviews-type';
import {classNames} from '../../../../../../../util/css';
import {useFormat} from '../../../../../../../util/format-hook/format-hook';
import {constructComment} from '../review-helper';

import {DeleteReply} from './delete-reply/delete-reply';
import {NewEditForm} from './edit-form/new-edit-form';
import {isLastChangeDateAfter} from './new-answer-item-helper';
import {NewReplyOnReplyForm} from './reply-on-reply-form/reply-on-reply-form';
import * as styles from './new-answer-item.scss';

type PropsType = {
    isTestBrand: boolean;
    answer: ReviewDataChildType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    isDialog: boolean;
    reviewId: number;
    lastChangesData: string | null;
};

// eslint-disable-next-line complexity
export function NewAnswerItem(props: PropsType): JSX.Element {
    const {answer, reviewId, refreshReview, isTestBrand, lastChangesData} = props;
    const {
        comment,
        createdInRd,
        user,
        ableToDelete,
        ableToEdit,
        ableToReply,
        isCompanyComment: isOwner,
        lastChangesDate: lastChangesAnswer,
        autoreplyRuleId,
        author,
        type,
    } = answer;
    const {getLocalizedString} = useLocale();
    const {getFormattedDateTime} = useFormat();
    const [isEditing, setIsEditing] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [isShowAnswerTooltip, setIsShowAnswerTooltip] = useState(false);

    const createdInRdDateTime = getFormattedDateTime(new Date(createdInRd), {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    const cardHeaderClassNames = classNames(styles.AnswerItem_cardHeader, {
        [styles.AnswerItem__user]: !isOwner,
    });

    const cardBodyClassNames = classNames(styles.AnswerItem_cardBody, {
        [styles.AnswerItem__user]: !isOwner,
    });

    return (
        <Card
            className={styles.AnswerItem}
            classNames={{
                header: cardHeaderClassNames,
                title: styles.AnswerItem_cardTitle,
                body: cardBodyClassNames,
            }}
            title={
                <>
                    <Flex className={styles.AnswerItem_headerUserBlock}>
                        {isOwner && (
                            <Text
                                stringKey={
                                    user
                                        ? 'REVIEWS__REVIEW_ANSWER__AUTHOR__USER'
                                        : isOwner
                                        ? 'REVIEWS__REVIEW_ANSWER__AUTHOR__COMPANY'
                                        : 'REVIEWS__REVIEW_ANSWER__AUTHOR__USER'
                                }
                            />
                        )}
                        <Text bolder>{user ? user.fullName || user.email : author}</Text>·
                        <Space
                            onMouseEnter={() => setIsShowAnswerTooltip(true)}
                            onMouseLeave={() => setIsShowAnswerTooltip(false)}
                        >
                            <Text lighter>{createdInRdDateTime}</Text>
                            {Boolean(autoreplyRuleId) && (
                                <p className={styles.AnswerItem_metaItem}>
                                    <Text bolder stringKey="REVIEWS__AUTO_REPLY" />
                                </p>
                            )}
                            {type === ReviewReplyTypeAuthorEnum.command && (
                                <Tooltip
                                    open={isShowAnswerTooltip}
                                    placement="top"
                                    title={<Locale stringKey="REVIEWS__REVIEW_ANSWER__TOOLTIP_TITLE__SENT" />}
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </Tooltip>
                            )}
                            {type === ReviewReplyTypeAuthorEnum.reply && (
                                <Tooltip
                                    open={isShowAnswerTooltip}
                                    placement="top"
                                    title={<Locale stringKey="REVIEWS__REVIEW_ANSWER__TOOLTIP_TITLE__PUBLISH" />}
                                >
                                    <FontAwesomeIcon icon={faCheckDouble} />
                                </Tooltip>
                            )}
                        </Space>
                    </Flex>
                    {isLastChangeDateAfter(lastChangesAnswer || createdInRd, lastChangesData) ? (
                        <Tooltip title={getLocalizedString('REVIEW__IRRELEVANT_TOOLTIP__TITLE')}>
                            <Tag color={orange95}>
                                <Text
                                    className={styles.AnswerItem_tagIrrelevant}
                                    color={orange50}
                                    stringKey="REVIEW__IRRELEVANT_TOOLTIP__TEXT"
                                />
                            </Tag>
                        </Tooltip>
                    ) : (
                        <div />
                    )}
                </>
            }
        >
            <Flex gap={spacer_s} vertical>
                {isEditing ? (
                    <NewEditForm
                        answer={answer}
                        isTestBrand={isTestBrand}
                        onClose={() => setIsEditing(false)}
                        refreshReview={refreshReview}
                        reviewId={reviewId}
                    />
                ) : (
                    constructComment(comment || '', true)
                )}
                {isReplying && (
                    <NewReplyOnReplyForm
                        answer={answer}
                        isTestBrand={isTestBrand}
                        onClose={() => setIsReplying(false)}
                        refreshReview={refreshReview}
                        reviewId={reviewId}
                    />
                )}
                {!isEditing && !isReplying && (ableToEdit || ableToDelete || ableToReply) && (
                    <Space>
                        {ableToEdit && (
                            <Button
                                icon={<FontAwesomeIcon icon={faEdit} />}
                                onClick={() => setIsEditing(true)}
                                size="small"
                                type="link"
                            >
                                <Locale stringKey="BUTTON__EDIT" />
                            </Button>
                        )}
                        {ableToDelete && (
                            <DeleteReply
                                answer={answer}
                                descriprion={getLocalizedString('BUTTON__DELETE')}
                                refreshReview={refreshReview}
                                reviewId={reviewId}
                            />
                        )}
                        {ableToReply && (
                            <Button
                                className={styles.AnswerItem_answerButton}
                                onClick={() => setIsReplying(true)}
                                size="small"
                                type="link"
                            >
                                <Locale stringKey="REVIEWS__REPLY" />
                            </Button>
                        )}
                    </Space>
                )}
            </Flex>
        </Card>
    );
}
