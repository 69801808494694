import {faCirclePlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {useState} from 'react';

import {YandexStoriesSources} from '../../../../../../component/stories/yandex-stories-sources/yandex-stories-sources';
import {gray1, gray9} from '../../../../../../css/var-export.scss';
import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {CclTableDataType} from '../../../../../../service/ccl/ccl-type';
import {YandexStoryStatusEnum} from '../../../../../../service/yandex-stories/yandex-stories-type';
import {classNames} from '../../../../../../util/css';
import {noop} from '../../../../../../util/function';

import {getStoriesInformation} from './stories-column-helper';
import * as styles from './stories-column.scss';

type PropsType = {
    record: CclTableDataType;
};

export function StoriesColumn(props: PropsType): JSX.Element {
    const {record} = props;
    const [open, setOpen] = useState(false);
    const {stories, catalog, company, brand} = record;

    const isEmpty = !stories?.storyInfo || stories.storyInfo.length === 0;
    const canAddStories = stories?.canAddStories;
    const isOnModeration = stories?.storyInfo.some(
        (story) =>
            story.status === YandexStoryStatusEnum.Moderation || story.status === YandexStoryStatusEnum.InProgress
    );
    const isRejected = stories?.storyInfo.some((story) => story.status === YandexStoryStatusEnum.Rejected);
    const isPublished = stories?.storyInfo.every(
        (story) => story.status === YandexStoryStatusEnum.Published || story.status === YandexStoryStatusEnum.Sent
    );

    const {getLocalizedString} = useLocale();

    const {tooltipLangKey, modifierClassName} = getStoriesInformation({
        isRejected,
        isPublished,
        isOnModeration,
        canAddStories,
        isEmpty,
    });

    const {getCatalogName} = useCatalogInfo();

    return (
        <div className={styles.StoriesColumn}>
            <Tooltip
                color={!canAddStories ? gray1 : ''}
                overlayInnerStyle={{
                    color: !canAddStories ? gray9 : gray1,
                }}
                title={getLocalizedString(tooltipLangKey)}
            >
                <button
                    className={classNames(styles.StoriesColumn_container, modifierClassName)}
                    onClick={canAddStories ? () => setOpen(true) : noop}
                    type="button"
                >
                    <img alt="" className={styles.StoriesColumn_logo} src={catalog.logo} />
                    {canAddStories && (
                        <div className={styles.StoriesColumn_action}>
                            <FontAwesomeIcon icon={faCirclePlus} />
                        </div>
                    )}
                </button>
            </Tooltip>
            <span>{getCatalogName(catalog.id)}</span>
            <YandexStoriesSources
                brandId={brand.id}
                companyId={company.id}
                onClose={() => setOpen(false)}
                open={open}
                shortStories={stories?.storyInfo || []}
            />
        </div>
    );
}
