import {ReactElement} from 'react';

import * as styles from './reviews-summary-main-metrics.scss';

type PropsType = {
    title: ReactElement;
    value: string;
    subValue?: string;
    chart?: ReactElement;
};

export function ReviewsSummaryMainMetricsItem(props: PropsType): JSX.Element {
    const {title, value, subValue, chart} = props;

    return (
        <div className={styles.ReviewsSummaryMainMetrics_itemWrapper}>
            <div className={styles.ReviewsSummaryMainMetrics_itemTitle}>{title}</div>
            <div className={styles.ReviewsSummaryMainMetrics_itemValueWrapper}>
                <div className={styles.ReviewsSummaryMainMetrics_itemValue}>{value}</div>
                <div className={styles.ReviewsSummaryMainMetrics_itemSubValue}>{subValue}</div>
            </div>
            {chart && <div className={styles.ReviewsSummaryMainMetrics_itemChart}>{chart}</div>}
        </div>
    );
}
