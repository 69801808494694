import {CreateYandexStoryRequestType, YandexStoryFormFieldsEnum, YandexStoryFormType} from './yandex-stories-type';

export function prepareCreateYandexStoryRequestData(data: YandexStoryFormType): CreateYandexStoryRequestType {
    const firstSlide = Object.values(data.slides).find((slide) => slide.order === 1);

    const cover =
        data[YandexStoryFormFieldsEnum.Cover] ||
        (firstSlide
            ? {
                  id: firstSlide.id,
                  url: firstSlide.url,
              }
            : {
                  id: '',
                  url: '',
              });

    return {
        selectorId: data[YandexStoryFormFieldsEnum.CompaniesSelector],
        name: data[YandexStoryFormFieldsEnum.Name],
        cover,
        slides: Object.values(data.slides).map((slide) => ({
            image: {
                id: slide.id,
                url: slide.url,
            },
            order: slide.order,
            buttonConfig: slide.buttonConfig,
        })),
        type: data.type,
    };
}
