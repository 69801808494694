import {Button, Form, Input} from 'antd';

import {PageSubHeader} from '../../../../../layout/page-header/page-sub-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {useConnect2fa} from '../../../../../service/user-2fa/user-2fa-hook';
import {User2faCodeType} from '../../../../../service/user-2fa/user-2fa-type';
import {getMapFromObject} from '../../../../../util/object';
import {TwoFactorDescription} from '../../../../auth/two-factor-authentication/setup/description/two-factor-description';
import {TwoFactorQrCode} from '../../../../auth/two-factor-authentication/setup/qr-code/two-factor-qr-code';
import {defaultTwoFactorAuthentication} from '../../profile-const';
import {TwoFactorAuthenticationType} from '../../profile-type';
import * as twoFactorAuthenticationStyles from '../two-factor-authentication.scss';
import {serializeAuthorizationCode} from '../two-factor-authentication-helper';

type PropsType = {
    className: string;
    onFinishSubmit: () => void;
};

export function EnableTwoFactor(props: PropsType): JSX.Element {
    const {className, onFinishSubmit} = props;

    const {connect2fa, isInProgress, processError} = useConnect2fa();
    const [form] = Form.useForm();
    const {lengthFieldRule, requiredFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();

    async function handleFormSubmit(): Promise<void> {
        const {authorizationCode} = getMapFromObject<TwoFactorAuthenticationType>(
            form.getFieldsValue(),
            defaultTwoFactorAuthentication
        );

        const formData: User2faCodeType = serializeAuthorizationCode(authorizationCode);

        connect2fa(formData)
            .then(onFinishSubmit)
            .catch((error_) => console.error('Error while trying to connect 2 factor authoriztion', error_));
    }

    const error = processError?.jsonData?.code?.[0];

    return (
        <Form
            className={className}
            form={form}
            layout="vertical"
            name="two-factor-authentication"
            onFinish={handleFormSubmit}
        >
            <div className={twoFactorAuthenticationStyles.two_factor_authentication__input_list_wrapper}>
                <div className={twoFactorAuthenticationStyles.two_factor_authentication__left}>
                    <PageSubHeader>
                        <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__SUB_HEADER" />
                    </PageSubHeader>

                    <TwoFactorDescription
                        className={twoFactorAuthenticationStyles.two_factor_authentication__step_list}
                    />

                    <Form.Item
                        className={twoFactorAuthenticationStyles.two_factor_authentication__input}
                        help={error}
                        label={<Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__LABEL__AUTHORIZATION_CODE" />}
                        name="authorizationCode"
                        rules={[requiredFieldRule, lengthFieldRule(6, false)]}
                        validateStatus={error && 'error'}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'PROFILE__TWO_FACTOR_AUTHENTICATION__PLACEHOLDER__AUTHORIZATION_CODE'
                            )}
                            size="large"
                        />
                    </Form.Item>

                    <Button htmlType="submit" loading={isInProgress} type="primary">
                        <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__BUTTON__CONNECT_GOOGLE_AUTHENTICATOR" />
                    </Button>
                </div>

                <div className={twoFactorAuthenticationStyles.two_factor_authentication__right}>
                    <TwoFactorQrCode />
                </div>
            </div>
        </Form>
    );
}
