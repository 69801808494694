import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartData,
    ChartOptions,
    ChartType,
    DefaultDataPoint,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PieController,
    Plugin,
    PointElement,
    PolarAreaController,
    RadarController,
    RadialLinearScale,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {RefObject, useEffect, useRef, useState} from 'react';

import {useLocale} from '../../../provider/locale/locale-hook';
import {noop} from '../../../util/function';
import {mapObjectValuesDeep} from '../../../util/object';
import {convertStringToCssVariable} from '../chart-helper';

Chart.register(
    ArcElement,
    BarController,
    BarElement,
    ChartDataLabels,
    DoughnutController,
    Filler,
    CategoryScale,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    RadarController,
    PointElement,
    RadialLinearScale,
    Tooltip,
    PolarAreaController,
    PieController
);

type PropsType<TType extends ChartType, TData> = {
    type: TType;
    data: ChartData<ChartType, TData>;
    options: ChartOptions<TType>;
    plugins?: Array<Plugin<TType>>;
};

export function ChartRenderer<TType extends ChartType, TData = DefaultDataPoint<TType>, TLabel = unknown>(
    props: PropsType<TType, TData>
): JSX.Element {
    const {type, data, options, plugins} = props;

    const {localeName} = useLocale();
    const canvasRef: RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);
    const [chartInstance, setChartInstance] = useState<Chart<TType, TData, TLabel> | null>(null);

    useEffect(() => {
        const canvasElement: HTMLCanvasElement | null = canvasRef.current;

        if (!canvasElement) {
            return () => noop;
        }

        const chartInstanceInner: Chart<TType, TData, TLabel> = new Chart<TType, TData, TLabel>(canvasElement, {
            type,
            data: {
                labels: [],
                datasets: [],
            },
            plugins,
        });

        setChartInstance(chartInstanceInner);

        return () => {
            chartInstanceInner.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        if (chartInstance) {
            const optionsWithCorrectCssVariables = Object.assign({}, options);

            mapObjectValuesDeep(optionsWithCorrectCssVariables, convertStringToCssVariable);

            chartInstance.options = {
                ...chartInstance.options,
                ...optionsWithCorrectCssVariables,
                locale: localeName,
            };
            chartInstance.update();
        }
    }, [options, chartInstance, localeName]);

    useEffect(() => {
        if (chartInstance) {
            const dataWithCorrectCssVariables = Object.assign({}, data);

            mapObjectValuesDeep(dataWithCorrectCssVariables.datasets, convertStringToCssVariable);

            // Added 'as' because the library does not account for generics in data.
            chartInstance.data = dataWithCorrectCssVariables as ChartData<ChartType, DefaultDataPoint<TType>, unknown>;
            chartInstance.update();
        }
    }, [chartInstance, data]);

    return <canvas key="chart-renderer" ref={canvasRef} />;
}
