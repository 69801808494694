import {appRoute} from '../../../../../app-route';
import {BreadCrumbItemType} from '../../../../../layout/bread-crumbs/bread-crumbs-type';

export const BREADCRUMBS_ITEMS: Array<BreadCrumbItemType> = [
    {
        path: appRoute.myCompanies.path,
        titleLangKey: 'CATEGORY_NAME__MY_COMPANIES',
    },
    {
        path: appRoute.editCompany.path,
        titleLangKey: 'CATEGORY_NAME__EDITING_A_COMPANY',
    },
];
