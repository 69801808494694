import dayjs from 'dayjs';

import {
    DateAggregationEnum,
    DatePeriodEnum,
    DateRangeType,
    getCloserFullMonth,
    getCloserFullQuarter,
    getCloserFullWeekRange,
    getDaysBetween,
} from '../../util/date';
import {getEnumValueEnsure} from '../../util/enum';
import {NullableType} from '../../util/type';

import {ANALYTICS_DELAY_DAYS, onlinePresencePhrasesFilterPeriodValues} from './online-presence-const';
import {OnlinePresenceCompareV2Enum} from './online-presence-type';

export function getLastAvailableAnalyticsDay(daysDelay = ANALYTICS_DELAY_DAYS): Date {
    return dayjs().subtract(daysDelay, 'day').minute(0).second(0).millisecond(0).utc(true).toDate();
}

export function getAnalyticsRangeByPeriodAndDate(lastDay: Date, period: DatePeriodEnum): DateRangeType {
    switch (period) {
        case DatePeriodEnum.Week: {
            return getCloserFullWeekRange(lastDay);
        }
        case DatePeriodEnum.Month: {
            const lastDayDayjs = dayjs(lastDay);

            return {
                start: lastDayDayjs.subtract(30, 'days').toDate(),
                end: lastDay,
            };
        }
        case DatePeriodEnum.PreviousFullMonth: {
            return getCloserFullMonth(lastDay);
        }
        case DatePeriodEnum.Quarter: {
            return getCloserFullQuarter(lastDay);
        }
        default: {
            return {
                start: lastDay,
                end: lastDay,
            };
        }
    }
}

function getMonthsBetween(startDate: Date, endDate: Date): number {
    const averageMonth = 1000 * 60 * 60 * 24 * (365 / 12);
    const deltaInMs = Math.abs(startDate.getTime() - endDate.getTime());

    return Math.round(deltaInMs / averageMonth);
}

export function getComparePeriod(
    mainPeriod: DateRangeType,
    period: DatePeriodEnum,
    compareMode: OnlinePresenceCompareV2Enum | null,
    isPhrasesMode: boolean
): NullableType<DateRangeType> {
    if (!compareMode) {
        return {
            start: null,
            end: null,
        };
    }

    switch (true) {
        case compareMode === OnlinePresenceCompareV2Enum.PreviousYear: {
            return {
                start: dayjs(mainPeriod.start).subtract(1, 'year').toDate(),
                end: dayjs(mainPeriod.end).subtract(1, 'year').toDate(),
            };
        }
        case compareMode === OnlinePresenceCompareV2Enum.PreviousPeriod && period !== DatePeriodEnum.Custom: {
            return getAnalyticsRangeByPeriodAndDate(dayjs(mainPeriod.start).subtract(1, 'day').toDate(), period);
        }
        default: {
            if (isPhrasesMode) {
                const countMonths = getMonthsBetween(mainPeriod.start, mainPeriod.end);

                return {
                    start: dayjs(mainPeriod.start)
                        .subtract(countMonths + 1, 'month')
                        .toDate(),
                    end: dayjs(mainPeriod.end)
                        .subtract(countMonths + 1, 'month')
                        .toDate(),
                };
            }

            const countDays = getDaysBetween(mainPeriod.start, mainPeriod.end);

            return {
                start: dayjs(mainPeriod.start)
                    .subtract(countDays + 1, 'days')
                    .toDate(),
                end: dayjs(mainPeriod.end)
                    .subtract(countDays + 1, 'days')
                    .toDate(),
            };
        }
    }
}

export function getDefaultPeriod(isPhrasesMode: boolean): DatePeriodEnum {
    return isPhrasesMode ? DatePeriodEnum.PreviousFullMonth : DatePeriodEnum.Month;
}

export function getOnlinePresenceAggregationBySelectedPeriod(mainPeriod: DateRangeType): DateAggregationEnum {
    const daysBetween = getDaysBetween(mainPeriod.start, mainPeriod.end);

    switch (true) {
        case daysBetween < 32: {
            return DateAggregationEnum.Day;
        }
        case daysBetween < 150: {
            return DateAggregationEnum.Week;
        }
        default: {
            return DateAggregationEnum.Month;
        }
    }
}

export function getInitialPeriod(periodRaw: string | void, isPhrasesMode: boolean): DatePeriodEnum {
    const qualifiedPeriod = getEnumValueEnsure<DatePeriodEnum>(
        DatePeriodEnum,
        periodRaw,
        getDefaultPeriod(isPhrasesMode)
    );

    if (isPhrasesMode) {
        return onlinePresencePhrasesFilterPeriodValues.includes(qualifiedPeriod)
            ? qualifiedPeriod
            : getDefaultPeriod(true);
    }

    return qualifiedPeriod;
}
