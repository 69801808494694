import {deserializeV2, serializeToURLParameters} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {rootApiUrl} from '../../api/api-const';

import {LinkCompaniesRequestType, linkCompaniesResponseSchema, LinkCompaniesResponseType} from './link-companies-type';

export async function fetchLinkCompanies(
    options: LinkCompaniesRequestType,
    signal?: AbortSignal
): Promise<LinkCompaniesResponseType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<LinkCompaniesRequestType>(options),
    });

    const url = `${rootApiUrl}/v1/source_settings/companies/?${searchParameters}`;
    const response = await fetchX(url, {method: FetchMethodEnum.get, signal});

    return deserializeV2<LinkCompaniesResponseType>(url, linkCompaniesResponseSchema, response);
}
