import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/localization';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {SyncStatistics} from './sync-statistics/sync-statistics';
import * as styles from './catalogs-sync-statistics.scss';

type PropsType = {
    showCatalogSyncStats: boolean;
};

export function CatalogsSyncStatisticsMultipleCompanies(props: PropsType): JSX.Element {
    const {showCatalogSyncStats} = props;

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__TITLE" />}
                    widget={DashboardWidgetEnum.SynchronizationMultiple}
                />
            }
        >
            <SyncStatistics showCatalogSyncStats={showCatalogSyncStats} />

            <p className={styles.footer__text}>
                <Locale stringKey="DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__FOOTER_TEXT" />
                <NavigationLink to={appRoute.sources.path}>
                    <Locale stringKey="DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__FOOTER_LINK" />
                </NavigationLink>
            </p>
        </PageCard>
    );
}
