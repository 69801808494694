import {Empty} from 'antd';
import {useCallback, useMemo} from 'react';

import {appRoute} from '../../../../../../../../app-route';
import {WidgetError} from '../../../../../../../../component/widget-error/widget-error';
import {WordCloud} from '../../../../../../../../layout/chart/word-cloud/word-cloud';
import {WordCloudDataType, WordCloudWordType} from '../../../../../../../../layout/chart/word-cloud/word-cloud-type';
import {Spinner} from '../../../../../../../../layout/spinner/spinner';
import {ReviewsAnalysisTagBySentimentType} from '../../../../../../../../service/reivew-analysis/review-analytics-tags/review-analytics-tags-type';
import {classNames} from '../../../../../../../../util/css';
import {useUrl} from '../../../../../../../../util/url-hook/url-hook';
import {concordanceQueryName, ConcordanceQueryType} from '../../../../../reviews-analysis-type';

import {castToWordCloudData} from './tag-cloud-helper';
import * as styles from './tag-cloud-chart.scss';

type PropsType = {
    data: Array<ReviewsAnalysisTagBySentimentType> | null;
    header?: JSX.Element;
    footer?: JSX.Element | null;
    isInProgress: boolean;
    onProgressChange?: (isInProgress: boolean) => void;
    tagLimit: number;
    processError: Error | null;
    isInactive?: boolean;
};

export function TagCloudChart(props: PropsType): JSX.Element {
    const {data, header, footer, isInProgress, onProgressChange, tagLimit, processError, isInactive} = props;

    const {pushState} = useUrl<ConcordanceQueryType>();

    const isEmpty = data?.length === 0;
    const wordCloudData: WordCloudDataType = useMemo(() => castToWordCloudData(data || []), [data]);

    const onWordClick = useCallback(
        (word: WordCloudWordType) =>
            pushState(
                appRoute.reviewsAnalysisConcordance.path,
                {[concordanceQueryName]: word.name},
                {isSaveQuery: true}
            ),
        [pushState]
    );

    return (
        <div
            className={classNames(styles.TagCloudChart, {
                [styles.TagCloudChart__disabled]: isInactive,
            })}
        >
            <Spinner isShow={isInProgress} position="absolute" />

            {Boolean(header) && header}

            {Boolean(processError) && <WidgetError />}

            {isEmpty && !processError && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

            {!processError && !isEmpty && (
                <div className={styles.TagCloudChart_container}>
                    <WordCloud
                        data={wordCloudData}
                        maxWords={tagLimit}
                        onProgressChange={onProgressChange}
                        onWordClick={onWordClick}
                    />
                </div>
            )}

            {footer}
        </div>
    );
}
