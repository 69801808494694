import {z as r} from 'zod';

import {
    deserializeV2,
    fetchAndDeserialize,
    postAndDeserialize,
    serialize,
    serializeToURLParameters,
} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';
import {PaginatedResponseType} from '../../api/api-type';
import {SourceLinkListRequestType} from '../source-links/source-links-type';

import {
    AccountBrandAlreadySelectedRequestType,
    accountBrandAlreadySelectedSchema,
    AccountBrandAlreadySelectedType,
    AccountCompanyAlreadySelectedRequestType,
    accountCompanyAlreadySelectedSchema,
    AccountCompanyAlreadySelectedType,
    accountReviewStatusesSchema,
    AccountReviewStatusesType,
    newSourcesAccountSchema,
    NewSourcesAccountType,
    RebindBrandsRequestType,
    RebindCompaniesRequestType,
    ReviewStatusesRequestType,
    SourceAccountsRequestType,
    sourcesAccountSchema,
    sourcesAccountsSchema,
    SourcesAccountType,
    UpdateSourcesAccountType,
} from './accounts-type';

export async function fetchAccountsList(
    options: SourceAccountsRequestType
): Promise<PaginatedResponseType<SourcesAccountType>> {
    const searchParameters = objectToUrlParameters(serializeToURLParameters<SourceLinkListRequestType>(options));
    const url = `/v1/source_settings/accounts/?${searchParameters}`;

    return fetchAndDeserialize(url, sourcesAccountsSchema);
}

export async function createAccount(payload: NewSourcesAccountType): Promise<Array<SourcesAccountType>> {
    const url = '/v1/source_settings/accounts/';

    return postAndDeserialize(url, sourcesAccountSchema, payload);
}

export async function updateAccount(id: number, payload: UpdateSourcesAccountType): Promise<NewSourcesAccountType> {
    const url = `${rootApiUrl}/v1/source_settings/accounts/${id}/`;
    const body = JSON.stringify(serialize<UpdateSourcesAccountType>(payload));

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body,
        method: FetchMethodEnum.put,
    });

    return deserializeV2<NewSourcesAccountType>(url, newSourcesAccountSchema, response);
}

export async function deleteAccount(id: number): Promise<void> {
    const url = `${rootApiUrl}/v1/source_settings/accounts/${id}/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.delete,
    });
}

export async function getAccountListCompanyAlreadySelected(
    options: AccountCompanyAlreadySelectedRequestType
): Promise<Array<AccountCompanyAlreadySelectedType>> {
    const searchParameters = objectToUrlParameters(
        serializeToURLParameters<AccountCompanyAlreadySelectedRequestType>(options)
    );
    const url = `/v1/source_settings/accounts/linked_companies/?${searchParameters}`;

    return fetchAndDeserialize(url, r.array(accountCompanyAlreadySelectedSchema));
}

export async function getAccountListBrandAlreadySelected(
    options: AccountBrandAlreadySelectedRequestType
): Promise<Array<AccountBrandAlreadySelectedType>> {
    const searchParameters = objectToUrlParameters(
        serializeToURLParameters<AccountBrandAlreadySelectedRequestType>(options)
    );
    const url = `/v1/source_settings/accounts/linked_brands/?${searchParameters}`;

    return fetchAndDeserialize(url, r.array(accountBrandAlreadySelectedSchema));
}

export async function rebindBrands(payload: RebindBrandsRequestType): Promise<void> {
    const url = `${rootApiUrl}/v1/source_settings/accounts/rebind_brands/`;
    const body = JSON.stringify(serialize<RebindBrandsRequestType>(payload));

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body,
    });
}

export async function rebindCompanies(payload: RebindCompaniesRequestType): Promise<void> {
    const url = `${rootApiUrl}/v1/source_settings/accounts/rebind_companies/`;
    const body = JSON.stringify(serialize<RebindCompaniesRequestType>(payload));

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body,
    });
}

export async function getReviewsStatus(options: ReviewStatusesRequestType): Promise<AccountReviewStatusesType> {
    const searchParameters = objectToUrlParameters(serializeToURLParameters<ReviewStatusesRequestType>(options));
    const url = `/v1/source_settings/accounts/statuses/?${searchParameters}`;

    return fetchAndDeserialize(url, accountReviewStatusesSchema);
}
