import {cloneDeep, merge} from 'lodash';

import {barChartOptions} from '../../../../../layout/chart/bar-chart/bar-chart-helper';
import {BarDataType, ChartJsBarOptionsType} from '../../../../../layout/chart/bar-chart/bar-chart-type';
import {getDefaultReactTooltipOptions} from '../../../../../layout/chart/chart-const';
import {LocaleContextType} from '../../../../../provider/locale/locale-context-type';
import {ReviewsSentimentsEnum} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-type';

import * as styles from './reviews-summary.scss';

export function getSentimentsNamesByKey(
    getLocalizedString: LocaleContextType['getLocalizedString']
): Record<ReviewsSentimentsEnum, string> {
    return {
        [ReviewsSentimentsEnum.negative]: getLocalizedString(
            'COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_NEGATIVE'
        ),
        [ReviewsSentimentsEnum.positive]: getLocalizedString(
            'COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_POSITIVE'
        ),
        [ReviewsSentimentsEnum.neutral]: getLocalizedString(
            'COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY_CATALOGS_REVIEWS_DYNAMIC_NEUTRAL'
        ),
    };
}

export const SENTIMENTS_COLORS_BY_KEY: Record<ReviewsSentimentsEnum, string | undefined> = {
    [ReviewsSentimentsEnum.negative]: styles.bar_chart_negative_color,
    [ReviewsSentimentsEnum.positive]: styles.bar_chart_positive_color,
    [ReviewsSentimentsEnum.neutral]: styles.bar_chart_neutral_color,
};

export function getDashboardReviewAnalysisChartOptions(data: BarDataType): ChartJsBarOptionsType {
    return merge(cloneDeep(barChartOptions), {
        scales: {
            y: {
                ticks: {
                    maxTicksLimit: 5,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: getDefaultReactTooltipOptions({
                data,
            }),
        },
    });
}
