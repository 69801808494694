import {z as r} from 'zod';

import {CompanyKeyEnum, ProtocolEnum} from '../company-const';

const companyWebsiteSchema = r.object({
    website: r.string(),
    isMain: r.boolean(),
    isUseInInfoTag: r.boolean().optional(),
});

type CompanyWebsiteType = r.infer<typeof companyWebsiteSchema>;

const companyCatalogWebsitesSchema = r.object({
    catalogId: r.number(),
    websites: r.array(companyWebsiteSchema),
});

const companyCatalogSocialNetworksSchema = r.object({
    catalogId: r.number(),
    socialNetworks: r.array(r.string()),
});

export const companyLinksSchema = {
    [CompanyKeyEnum.Websites]: r.array(companyWebsiteSchema),
    [CompanyKeyEnum.ExtraWebsites]: r.array(companyCatalogWebsitesSchema),
    [CompanyKeyEnum.SocialNetworks]: r.array(r.string()),
    [CompanyKeyEnum.ExtraSocialNetworks]: r.array(companyCatalogSocialNetworksSchema),
};

const companyLinksSchemaObject = r.object(companyLinksSchema);

export type CompanyLinksType = r.infer<typeof companyLinksSchemaObject>;

export type CompanyLinksFormType = {
    [CompanyKeyEnum.Websites]: Array<CompanyFormWebsiteType>;
    [CompanyKeyEnum.ExtraWebsites]: Array<CompanyCatalogWebsitesType<CompanyFormWebsiteType>>;
    [CompanyKeyEnum.SocialNetworks]: Array<CompanyFormSocialNetworkType>;
    [CompanyKeyEnum.ExtraSocialNetworks]: Array<CompanyCatalogSocialNetworksType<CompanyFormSocialNetworkType>>;
};

export type CompanyLinksServerType = Omit<
    CompanyLinksType,
    CompanyKeyEnum.ExtraWebsites | CompanyKeyEnum.ExtraSocialNetworks
> & {
    [CompanyKeyEnum.ExtraWebsites]: Array<CompanyCatalogWebsitesType<CompanyWebsiteType>>;
    [CompanyKeyEnum.ExtraSocialNetworks]: Array<CompanyCatalogSocialNetworksType<string>>;
};

type CompantyFormProtocolType = {
    protocol: ProtocolEnum;
};

export type CompanyFormWebsiteType = CompantyFormProtocolType & {
    value: string;
    isMain: boolean;
    isUseInInfoTag?: boolean;
};

export type CompanyFormSocialNetworkType = CompantyFormProtocolType & {
    value: string;
};

type CompanyCatalogWebsitesType<T> = {
    catalogId: number;
    websites?: Array<T>;
};

type CompanyCatalogSocialNetworksType<T> = {
    catalogId: number;
    socialNetworks?: Array<T> | null;
};
