import {Skeleton} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {ReviewRatingTypeEnum} from '../../../../../../service/reviews/reviews-type';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import {getButtonDataByType} from './big-radio-button-helper';
import {BigRadioButtonNameEnum} from './big-radio-button-type';
import * as styles from './big-radio-button.scss';

type PropsType = {
    type: ReviewRatingTypeEnum | BigRadioButtonNameEnum;
    value: number;
    onClick: () => void;
    isActive: boolean;
    className?: string;
    isInfoOnly?: boolean;
    isLoading?: boolean;
};

export function BigRadioButton(props: PropsType): JSX.Element {
    const {className, type, value, isActive, onClick, isLoading, isInfoOnly = false} = props;
    const {titleKey, icon, className: classNameByType} = getButtonDataByType(type);
    const {getFormattedNumber} = useFormat();

    return (
        <button
            className={classNames(
                classNameByType,
                styles.BigRadioButton,
                {
                    [styles.BigRadioButton_active]: isActive,
                    // [bigRadioButtonStyle.big_radio_button__height_long_value]: isLongValue,
                    [styles.BigRadioButton_infoOnly]: isInfoOnly,
                },
                className
            )}
            onClick={onClick}
            type="button"
        >
            <span className={styles.BigRadioButton_title}>
                <Locale stringKey={titleKey} />
            </span>

            <div className={styles.BigRadioButton_valueRow}>
                <span className={styles.BigRadioButton_iconWrapper}>{icon}</span>

                {isLoading ? (
                    <Skeleton active paragraph={false} title={{className: styles.BigRadioButton_skeleton}} />
                ) : (
                    <span className={styles.BigRadioButton_value}>{getFormattedNumber(value)}</span>
                )}
            </div>
        </button>
    );
}
