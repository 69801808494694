import dayjs from 'dayjs';

import {BarDataSetType, BarDataType} from '../../../../../layout/chart/bar-chart/bar-chart-type';
import {PieDataType} from '../../../../../layout/chart/pie-chart/pie-chart-type';
import {LocaleContextType} from '../../../../../provider/locale/locale-context-type';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {
    CompanyReviewsSummaryType,
    ReviewsCatalogStatisticType,
    ReviewsSentimentsEnum,
    ReviewsSummaryOptionsType,
} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-type';
import {getDatasetColor} from '../../../../../util/chart-colors/chart-colors';
import {getMonthNameByItsIndex} from '../../../../../util/date';

import {getSentimentsNamesByKey, SENTIMENTS_COLORS_BY_KEY} from './reviews-summary-const';

function prepareCatalogs(
    reviewsSummary: CompanyReviewsSummaryType,
    getLocalizedString: (stringKey: LangKeyType, valueMap?: Record<string, string>) => string
): Array<ReviewsCatalogStatisticType> {
    const catalogsStatistic = reviewsSummary.catalogs_statistic;

    if (catalogsStatistic.length > 10) {
        const catalogs = catalogsStatistic.slice(0, 9);

        catalogs.push({
            name: getLocalizedString('COMPANY_ACTIVITY_AND_SUMMARY__REVIEWS_SUMMARY__OTHER_CATALOGS'),
            count: catalogsStatistic.slice(9).reduce((accumulator, catalog) => accumulator + catalog.count, 0),
        });
        return catalogs;
    }

    return catalogsStatistic;
}

export function castToPieChartData(
    reviewsSummary: CompanyReviewsSummaryType,
    getLocalizedString: (stringKey: LangKeyType, valueMap?: Record<string, string>) => string
): Array<PieDataType> {
    const catalogs = prepareCatalogs(reviewsSummary, getLocalizedString);

    return catalogs.map((statistic, index) => {
        return {
            label: statistic.name,
            value: statistic.count,
            color: getDatasetColor(index, statistic.name),
        };
    });
}

function deserializeLabels(
    reviewsSummary: CompanyReviewsSummaryType,
    getLocalizedString: LocaleContextType['getLocalizedString']
): Array<string> {
    return reviewsSummary.dynamic.map((reviewsDynamic) => {
        const monthIndex = new Date(reviewsDynamic.date).getMonth();

        return getMonthNameByItsIndex(getLocalizedString)[monthIndex] ?? '';
    });
}

export function castToBarChartData(
    reviewsSummary: CompanyReviewsSummaryType,
    getLocalizedString: LocaleContextType['getLocalizedString']
): BarDataType {
    const labels = deserializeLabels(reviewsSummary, getLocalizedString);

    const datasets: Array<BarDataSetType> = Object.values(ReviewsSentimentsEnum).map(
        (sentimentKey: ReviewsSentimentsEnum) => {
            return {
                label: getSentimentsNamesByKey(getLocalizedString)[sentimentKey],
                values: reviewsSummary.dynamic.map((reviewsDynamic) => reviewsDynamic.sentiments[sentimentKey]),
                color: SENTIMENTS_COLORS_BY_KEY[sentimentKey],
            };
        }
    );

    return {
        labels,
        datasets,
    };
}

export function getEdgeDates(): {start: Date; end: Date} {
    const currentDate = new Date();
    const end = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const start = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);

    return {start, end};
}

export function getReviewsSummaryOptions(companyId: number, start: Date, end: Date): ReviewsSummaryOptionsType {
    return {
        companyId,
        startDate: dayjs(start).format('YYYY-MM-DD'),
        endDate: dayjs(end).format('YYYY-MM-DD'),
    };
}
