import {Skeleton} from 'antd';

import * as styles from './processing-step-skeleton.scss';

export function ProcessingStepSkeleton(): JSX.Element {
    return (
        <div className={styles.Skeleton}>
            <div className={styles.Skeleton_left}>
                <Skeleton active className={styles.Skeleton_emptyParagraph} paragraph={{rows: 0}} />

                <Skeleton active paragraph={{rows: 1}} title={false} />
            </div>

            <Skeleton.Button active className={styles.Skeleton_button} />
        </div>
    );
}
