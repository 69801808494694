import {Tab} from 'rc-tabs/lib/interface';

import {Locale} from '../../../provider/locale/locale';

import {NotificationBrowser} from './notification-browser/notification-browser';
import {NotificationEmail} from './notification-email/notification-email';
import {NotificationTabWrapper} from './notification-tab-wrapper/notification-tab-wrapper';
import {NotificationTelegram} from './notification-telegram/notification-telegram';
import {NotificationTabKeyEnum} from './notification-type';

const NOTIFICATION_TAB_NAME_TELEGRAM = 'telegram';
const NOTIFICATION_TAB_NAME_EMAIL = 'email';
const NOTIFICATION_TAB_NAME_BROWSER = 'push';

const emailTab: Tab = {
    key: 'email-notifications',
    label: <Locale stringKey="NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS" />,
    children: (
        <NotificationTabWrapper type={NOTIFICATION_TAB_NAME_EMAIL}>
            <NotificationEmail />
        </NotificationTabWrapper>
    ),
};

const browserTab: Tab = {
    key: 'browser-notifications',
    label: <Locale stringKey="NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS" />,
    children: (
        <NotificationTabWrapper type={NOTIFICATION_TAB_NAME_BROWSER}>
            <NotificationBrowser />
        </NotificationTabWrapper>
    ),
};

const telegramTab: Tab = {
    key: 'telegram-notifications',
    label: <Locale stringKey="NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS" />,
    children: (
        <NotificationTabWrapper type={NOTIFICATION_TAB_NAME_TELEGRAM}>
            <NotificationTelegram />
        </NotificationTabWrapper>
    ),
};

export const notificationsMap: Record<NotificationTabKeyEnum, Tab> = {
    [NotificationTabKeyEnum.browser]: browserTab,
    [NotificationTabKeyEnum.email]: emailTab,
    [NotificationTabKeyEnum.telegram]: telegramTab,
};
