import {faChartSimple, faLightbulb, faThumbsUp} from '@fortawesome/pro-solid-svg-icons';

import {LandingAdvantages} from '../../../../../component/landing/landing-advantages/landing-advantages';
import {LandingArrow} from '../../../../../component/landing/landing-arrow/landing-arrow';
import {LandingCallToAction} from '../../../../../component/landing/landing-call-to-action/landing-call-to-action';
import {LandingCard} from '../../../../../component/landing/landing-card/landing-card';
import {LandingPageCard} from '../../../../../component/landing/landing-page-card/landing-page-card';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {LOCAL_PACK_TRIAL_PERIOD_DAYS} from '../local-pack-activation-steps/steps/rules/local-pack-activation-rule-helper';

import {getLocalPackLandingImage} from './local-pack-landing-helper';
import * as styles from './local-pack-landing.scss';

type PropsType = {
    onTryFeatureClick: VoidFunction;
};

export function LocalPackLanding(props: PropsType): JSX.Element {
    const {onTryFeatureClick} = props;
    const {shortLocaleName} = useLocale();

    return (
        <LandingPageCard>
            <LandingCard
                buttonLangKey="BUTTON__TRY_FOR_FREE"
                imageSrc={getLocalPackLandingImage(shortLocaleName, 1)}
                mainItem
                onClick={onTryFeatureClick}
                primaryDescriptionItems={['LOCAL_PACK__LANDING__CARD_1__TEXT']}
                textFirst
                titleLangKey="LOCAL_PACK__LANDING__CARD_1__TITLE"
            />

            <LandingAdvantages
                cardsData={[
                    {
                        titleLangKey: 'LOCAL_PACK__LANDING__ADVANTAGES_CARD_1__TITLE',
                        descriptionLangKey: 'LOCAL_PACK__LANDING__ADVANTAGES_CARD_1__DESCRIPTION',
                        icon: faThumbsUp,
                    },
                    {
                        titleLangKey: 'LOCAL_PACK__LANDING__ADVANTAGES_CARD_2__TITLE',
                        descriptionLangKey: 'LOCAL_PACK__LANDING__ADVANTAGES_CARD_2__DESCRIPTION',
                        icon: faLightbulb,
                    },
                    {
                        titleLangKey: 'LOCAL_PACK__LANDING__ADVANTAGES_CARD_3__TITLE',
                        descriptionLangKey: 'LOCAL_PACK__LANDING__ADVANTAGES_CARD_3__DESCRIPTION',
                        icon: faChartSimple,
                    },
                ]}
                titleLangKey="LOCAL_PACK__LANDING__ADVANTAGES__TITLE"
            />

            <LandingCard
                imageClassName={styles.LocalPackLanding_landingCard2Image}
                imageSrc={getLocalPackLandingImage(shortLocaleName, 2)}
                primaryDescriptionItems={['LOCAL_PACK__LANDING__CARD_2__TEXT_1', 'LOCAL_PACK__LANDING__CARD_2__TEXT_2']}
                titleLangKey="LOCAL_PACK__LANDING__CARD_2__TITLE"
            />

            <LandingCard
                imageClassName={styles.LocalPackLanding_landingCard3Image}
                imageSrc={getLocalPackLandingImage(shortLocaleName, 3)}
                primaryDescriptionItems={[
                    'LOCAL_PACK__LANDING__CARD_3__TEXT_1',
                    'LOCAL_PACK__LANDING__CARD_3__TEXT_2',
                    'LOCAL_PACK__LANDING__CARD_3__TEXT_3',
                ]}
                textFirst
                titleLangKey="LOCAL_PACK__LANDING__CARD_3__TITLE"
            />

            <LandingCallToAction
                descriptionLangKey="LOCAL_PACK__LANDING__CALL_TO_ACTION_1__DESCRIPTION"
                duration={LOCAL_PACK_TRIAL_PERIOD_DAYS}
                onClick={onTryFeatureClick}
            />

            <LandingCard
                imageClassName={styles.LocalPackLanding_landingCard4Image}
                imageSrc={getLocalPackLandingImage(shortLocaleName, 4)}
                primaryDescriptionItems={['LOCAL_PACK__LANDING__CARD_4__TEXT_1', 'LOCAL_PACK__LANDING__CARD_4__TEXT_2']}
                titleLangKey="LOCAL_PACK__LANDING__CARD_4__TITLE"
            />
            <div>
                <LandingArrow position="left" />
                <LandingCard
                    imageClassName={styles.LocalPackLanding_landingCard5Image}
                    imageSrc={getLocalPackLandingImage(shortLocaleName, 5)}
                    primaryDescriptionItems={[
                        'LOCAL_PACK__LANDING__CARD_5__TEXT_1',
                        'LOCAL_PACK__LANDING__CARD_5__TEXT_2',
                    ]}
                    textFirst
                    titleLangKey="LOCAL_PACK__LANDING__CARD_5__TITLE"
                />
            </div>
            <div>
                <LandingArrow position="right" />

                <LandingCard
                    imageClassName={styles.LocalPackLanding_landingCard6Image}
                    imageSrc={getLocalPackLandingImage(shortLocaleName, 6)}
                    primaryDescriptionItems={[
                        'LOCAL_PACK__LANDING__CARD_6__TEXT_1',
                        'LOCAL_PACK__LANDING__CARD_6__TEXT_2',
                    ]}
                    titleLangKey="LOCAL_PACK__LANDING__CARD_6__TITLE"
                />
            </div>

            <LandingCallToAction
                colorful
                descriptionLangKey="LOCAL_PACK__LANDING__CALL_TO_ACTION_2__DESCRIPTION"
                duration={LOCAL_PACK_TRIAL_PERIOD_DAYS}
                onClick={onTryFeatureClick}
            />
        </LandingPageCard>
    );
}
