import {LangKeyType} from '../../../../../../../provider/locale/translation/type';
import {CclErrorEnum} from '../../../../../../../util/type';

export const cclErrorTranslationMap: Record<CclErrorEnum, LangKeyType> = {
    [CclErrorEnum.formatted_address]: 'SOURCES__STATUS__ERROR__ADDRESS',
    [CclErrorEnum.name]: 'SOURCES__STATUS__ERROR__NAME',
    [CclErrorEnum.phones]: 'SOURCES__STATUS__ERROR__PHONES',
    [CclErrorEnum.website]: 'SOURCES__STATUS__ERROR__WEBSITE',
    [CclErrorEnum.working_hours]: 'SOURCES__STATUS__ERROR__WORKING_HOURS',
    [CclErrorEnum.closed_status]: 'SOURCES__ONBOARDING__POPOVER__STATUS_DATA__OPEN',
};
