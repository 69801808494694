import {Text} from '../../../../component/text/text';
import {AdditionalInfo} from '../../../../layout/additional-info/additional-info';

import * as styles from './company-group-form.scss';

export function CompanyGroupFormFooter(props: {counterCompanyWithoutAccess: number}): JSX.Element {
    const {counterCompanyWithoutAccess} = props;

    return (
        <AdditionalInfo
            className={styles.VirtualizedTable_additionalInfo}
            leftNode={
                <Text
                    stringKey="GROUP_OF_COMPANIES__EDIT__ADDITIONAL_INFO__TITLE"
                    valueMap={{
                        count: counterCompanyWithoutAccess,
                    }}
                />
            }
            title={<Text stringKey="GROUP_OF_COMPANIES__EDIT__ADDITIONAL_INFO__POPOVER_TITLE" />}
        >
            <Text stringKey="GROUP_OF_COMPANIES__EDIT__ADDITIONAL_INFO__POPOVER_INFO" />
        </AdditionalInfo>
    );
}
