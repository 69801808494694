// do not change values, they matched with server's api
export enum AutoReplayEnum {
    automaticallyReplied = 'replied',
    suggestionsForAutoReplies = 'suggested',
}

// do not change values, they matched with server's api
export enum ContentOfReviewsEnum {
    noSelected = '',
    reviewsWithoutText = 'true',
    reviewsWithText = 'false',
}

// do not change values, they matched with server's api
export enum DeletedReviewsEnum {
    noSelected = '',
    yes = 'true',
    no = 'false',
}

// do not change values, they matched with server's api
export enum EmployeesReviewsEnum {
    all = 'True',
    noSelected = 'null',
}

// do not change values, they matched with server's api
export enum DoctorsReviewsEnum {
    all = 'True',
}

// do not change values, they matched with server's api
export enum RepliesToReviewsEnum {
    noSelected = '',
    ReviewsWithAResponse = 'true',
    ReviewsUnanswered = 'false',
}

// do not change values, they matched with server's api
export enum PossibilityOfAnAnswerEnum {
    noSelected = '',
    HasPossibilityOfAnAnswer = 'true',
    HasNoPossibilityOfAnAnswer = 'false',
}

// do not change values, they matched with server's api
export enum ReviewNameEnum {
    noSelected = '',
    ReviewsForTheBrand = 'brand',
    ReviewsForCompanies = 'company',
}

// do not change values, they matched with server's api
export enum ComplaintWasLeftEnum {
    noSelected = '',
    yes = 'true',
    no = 'false',
}

// do not change values, they matched with server's api
export enum ReviewChangedEnum {
    noSelected = '',
    yes = 'true',
    no = 'false',
}

// do not change values, they matched with server's api
export enum WaitingForSecondAnswerEnum {
    noSelected = '',
    yes = 'true',
    no = 'false',
}

export enum ModeratedEnum {
    noSelected = '',
    yes = 'true',
    no = 'false',
}

export enum WithoutTagEnum {
    noSelected = '',
    withoutTagTrue = 'true',
    withoutTagFalse = 'false',
}

export enum ReviewsFilterFieldNameEnum {
    brandIds = 'brand_ids',
    sourceList = 'source-list',
    processStatus = 'process_status',
    ratingList = 'rating-list',
    employeesResponsible = 'employee-responsible',
    employeesResponding = 'employee-responding',
    doctorNames = 'doctors-name',
    allDoctors = 'all-doctors',
    tagList = 'tag-list',
    withoutTag = 'without-tag',

    autoReplay = 'auto-replay',
    contentOfReviews = 'content-of-reviews',
    deletedReviews = 'deleted-reviews',
    repliesToReviews = 'replies-to-reviews',
    possibilityOfAnAnswer = 'possibility-of-an-answer',
    reviewType = 'review-type',
    complaintWasLeft = 'complaint-was-left',
    reviewChanged = 'review-changed',
    waitingForSecondAnswer = 'waiting-for-second-answer',
    reviewModerated = 'moderated',

    dateFrom = 'date-from',
    dateTo = 'date-to',
}

export type ReviewsFilterDataType = Readonly<{
    [ReviewsFilterFieldNameEnum.brandIds]: number | null;
    [ReviewsFilterFieldNameEnum.sourceList]: Array<number>;
    [ReviewsFilterFieldNameEnum.processStatus]: Array<string>;
    [ReviewsFilterFieldNameEnum.ratingList]: Array<number>;
    [ReviewsFilterFieldNameEnum.employeesResponding]: Array<string | number>;
    [ReviewsFilterFieldNameEnum.employeesResponsible]: Array<string | number>;
    [ReviewsFilterFieldNameEnum.doctorNames]: Array<string>;
    [ReviewsFilterFieldNameEnum.tagList]: Array<number>;
    [ReviewsFilterFieldNameEnum.withoutTag]: WithoutTagEnum;

    [ReviewsFilterFieldNameEnum.autoReplay]: Array<string>;
    [ReviewsFilterFieldNameEnum.contentOfReviews]: ContentOfReviewsEnum;
    [ReviewsFilterFieldNameEnum.deletedReviews]: DeletedReviewsEnum;
    [ReviewsFilterFieldNameEnum.repliesToReviews]: RepliesToReviewsEnum;
    [ReviewsFilterFieldNameEnum.possibilityOfAnAnswer]: PossibilityOfAnAnswerEnum;
    [ReviewsFilterFieldNameEnum.reviewType]: ReviewNameEnum;
    [ReviewsFilterFieldNameEnum.complaintWasLeft]: ComplaintWasLeftEnum;
    [ReviewsFilterFieldNameEnum.reviewChanged]: ReviewChangedEnum;
    [ReviewsFilterFieldNameEnum.waitingForSecondAnswer]: WaitingForSecondAnswerEnum;
    [ReviewsFilterFieldNameEnum.reviewModerated]: ModeratedEnum;

    [ReviewsFilterFieldNameEnum.dateFrom]: Date | null;
    [ReviewsFilterFieldNameEnum.dateTo]: Date | null;
}>;

export type ReviewsFilterDataAsObjectUrlType = Readonly<Partial<Record<ReviewsFilterFieldNameEnum, string | void>>>;
