import {Checkbox} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {CompanyPaymentMethodEnum, CompanyPaymentMethodV2Enum} from '../../../../../../service/company/company-type';
import {classNames} from '../../../../../../util/css';
import {BaseCompanyFormItemDirectPropsType} from '../company-form-items-type';

import {LABEL_BY_METHOD} from './payment-methods-form-item-const';
import * as styles from './payment-methods-form-item.scss';

type EnumsType = CompanyPaymentMethodEnum | CompanyPaymentMethodV2Enum;

type PropsType<T extends EnumsType> = BaseCompanyFormItemDirectPropsType<Record<T, boolean>> & {
    className?: string;
};

export function PaymentMethodsFormItem<TEnum extends EnumsType>(props: PropsType<TEnum>): JSX.Element {
    const {value: paymentMethods, onChange, className} = props;

    function handleChange(method: TEnum) {
        return function change(): void {
            onChange({...paymentMethods, [method]: !paymentMethods[method]});
        };
    }

    function renderPaymentCheckbox(method: TEnum): JSX.Element {
        return (
            <Checkbox
                checked={paymentMethods[method]}
                className={styles.payment_method_checkbox}
                key={LABEL_BY_METHOD[method]}
                onChange={handleChange(method)}
            >
                <Locale stringKey={LABEL_BY_METHOD[method]} />
            </Checkbox>
        );
    }

    return (
        <div className={classNames(styles.payment_method_list, className)}>
            {Object.keys(paymentMethods).map((method) => renderPaymentCheckbox(method as TEnum))}
        </div>
    );
}
