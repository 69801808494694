import {DoughnutDataType} from '../../layout/chart/doughnut-chart/doughnut-chart-type';

import {ChartTooltip} from './chart-tooltip';
import {ChartTooltipRow} from './chart-tooltip-row';
import {ChartTooltipTitle} from './chart-tooltip-title';

export type DefaultDoughnutChartTooltipPropsType = {
    data: Array<DoughnutDataType>;
    title: string | JSX.Element;
    currentIndex: number;
    labelFormatter?: (label: string) => string;
};

export function DefaultDoughnutChartTooltip(props: DefaultDoughnutChartTooltipPropsType): JSX.Element {
    const {data, currentIndex, title, labelFormatter} = props;

    const elementData = data[currentIndex];

    return (
        <ChartTooltip>
            <ChartTooltipTitle title={title} />
            {elementData && (
                <ChartTooltipRow
                    color={String(elementData.color)}
                    label={labelFormatter ? labelFormatter(elementData.label) : elementData.label}
                    value={elementData.value}
                />
            )}
        </ChartTooltip>
    );
}
