import {faCircleQuestion} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Alert, Modal, Radio, RadioChangeEvent, Space} from 'antd';
import {useState} from 'react';

import {AntdErrorMessage} from '../../../../../../../../component/antd-error-message/antd-error-message';
import {Text} from '../../../../../../../../component/text/text';
import {Spinner} from '../../../../../../../../layout/spinner/spinner';
import {useDocumentationLinks} from '../../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {
    fetchGoogleVerificationOptions,
    getGoogleVerificationOptionsUrl,
    postGoogleVerificationOption,
} from '../../../../../../../../service/google/confirmation/google-confirmation-api';
import {
    GoogleConfirmationCompanyType,
    GoogleVerificationMethodEnum,
    GoogleVerificationOptionBodyType,
    GoogleVerificationOptionResponseType,
} from '../../../../../../../../service/google/confirmation/google-confirmation-type';
import {Form} from '../../../../../../../../typings/antd';
import {ApiError} from '../../../../../../../../util/error';
import {CompanyListItem} from '../../../../common/company-list-item/company-list-item';

import {
    getMutationErrors,
    getVerificationOptionsErrors,
    useVerificationMethodsConfig,
} from './code-request-modal-helper';
import {CodeRequestFormType} from './code-request-modal-type';
import {AddressVerification} from './verifications/address-verification';
import {EmailVerification} from './verifications/email-verification';
import {PhoneCallVerification} from './verifications/phone-call-verification';
import {SmsVerification} from './verifications/sms-verification';
import * as styles from './code-request-modal.scss';

type PropsType = {
    confirmation: GoogleConfirmationCompanyType;
    open: boolean;
    repeat?: boolean;
    onCancel: () => void;
    onOk: (method: GoogleVerificationOptionResponseType) => void;
};

// eslint-disable-next-line complexity
export function CodeRequestModal(props: PropsType): JSX.Element {
    const {confirmation, open, repeat, onCancel, onOk} = props;

    const [selectedMethod, setSelectedMethod] = useState(GoogleVerificationMethodEnum.PhoneCall);
    const [form] = Form.useForm<CodeRequestFormType>();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const documentationLinks = useDocumentationLinks();

    const {
        data: verificationOptions,
        error,
        isError,
        isLoading,
    } = useQuery(
        [getGoogleVerificationOptionsUrl(confirmation.company.pk)],
        () => fetchGoogleVerificationOptions(confirmation.company.pk),
        {retry: false}
    );

    const verificationMethodsConfig = useVerificationMethodsConfig();
    const verificationOptionTypes = verificationOptions?.map((option) => option.verificationMethod) ?? [];
    const verification = verificationOptions?.find((option) => option.verificationMethod === selectedMethod);

    const initialValues = {
        email:
            verificationOptions
                ?.find((option) => option.verificationMethod === GoogleVerificationMethodEnum.Email)
                ?.detail?.split('@')[0] ?? '',
    };

    const {
        mutate,
        error: mutationError,
        isLoading: isMutationLoading,
    } = useMutation({
        mutationFn: (body: GoogleVerificationOptionBodyType) =>
            postGoogleVerificationOption(confirmation.company.pk, body),
        onSuccess: (data) => {
            onOk(data);

            if (selectedMethod === GoogleVerificationMethodEnum.Address) {
                const config = verificationMethodsConfig[selectedMethod];

                modal.success({
                    title: config.successTitle ? <Locale stringKey={config.successTitle} /> : '',
                    content: config.successNode ?? '',
                    cancelText: null,
                });
            } else {
                const config = verificationMethodsConfig[selectedMethod];

                snackbar.success({
                    message: config.successTitle ? <Locale stringKey={config.successTitle} /> : <span />,
                    description: config.successText ? <Locale stringKey={config.successText} /> : <span />,
                });
            }
        },
    });

    function handleRadioGroupChange(event: RadioChangeEvent) {
        setSelectedMethod(event.target.value);
    }

    function getDetail() {
        if (selectedMethod === GoogleVerificationMethodEnum.Email && verification) {
            const email = form.getFieldValue('email') ?? '';
            const isNonEditableDomain = verification.detail?.includes('@');

            if (isNonEditableDomain) {
                return `${email.replace(/@/g, '')}@${verification.detail?.split('@')[1]}`;
            }

            return email;
        }

        return verification?.detail ?? '';
    }

    async function handleOk() {
        try {
            await form.validateFields();
        } catch {
            return;
        }

        if (selectedMethod) {
            await mutate({
                method: selectedMethod,
                detail: getDetail(),
            });
        }
    }

    return (
        <Modal
            cancelText={<Locale stringKey="POPUP__BUTTON__CANCEL" />}
            destroyOnClose
            okButtonProps={{disabled: isLoading || isMutationLoading || isError}}
            okText={<Locale stringKey="POPUP__BUTTON__SEND" />}
            onCancel={onCancel}
            onOk={handleOk}
            open={open}
            title={<Locale stringKey="GOOGLE_SYNC__CODE_REQUEST__TITLE" />}
        >
            {confirmation.location && confirmation.location.locationId && (
                <CompanyListItem
                    className={styles.CodeRequestModal_card}
                    company={{
                        ...confirmation.location,
                        originId: confirmation.location.locationId,
                        mapsUrl: confirmation.location.linkToMap,
                    }}
                    showStatus={false}
                />
            )}

            <Space className={styles.CodeRequestModal_descriptions} direction="vertical" size={4}>
                <Text block bolder stringKey="GOOGLE_SYNC__CODE_REQUEST__SUBTITLE" />

                {documentationLinks.google.confirmationTypes && (
                    <Text
                        block
                        lighter
                        stringKey="GOOGLE_SYNC__CODE_REQUEST__DESCRIPTION"
                        valueMap={{
                            link: (
                                <a href={documentationLinks.google.confirmationTypes} rel="noreferrer" target="_blank">
                                    <Locale stringKey="GOOGLE_SYNC__CODE_REQUEST__DESCRIPTION__LINK" />
                                </a>
                            ),
                        }}
                    />
                )}
            </Space>

            {isLoading ? (
                <Spinner className={styles.CodeRequestModal_spinner} />
            ) : (
                <>
                    <Radio.Group
                        className={styles.CodeRequestModal_radioGroup}
                        defaultValue={selectedMethod}
                        onChange={handleRadioGroupChange}
                    >
                        {Object.entries(verificationMethodsConfig)
                            .filter(([id]) => verificationOptionTypes.includes(id as GoogleVerificationMethodEnum))
                            .map(([id, {title}]) => (
                                <Radio.Button key={id} value={id}>
                                    {title && <Locale stringKey={title} />}
                                </Radio.Button>
                            ))}
                    </Radio.Group>

                    <Form
                        className={styles.CodeRequestModal_form}
                        form={form}
                        initialValues={initialValues}
                        layout="vertical"
                    >
                        {(() => {
                            switch (selectedMethod) {
                                case GoogleVerificationMethodEnum.PhoneCall:
                                    return <PhoneCallVerification verification={verification} />;

                                case GoogleVerificationMethodEnum.SMS:
                                    return <SmsVerification verification={verification} />;

                                case GoogleVerificationMethodEnum.Email:
                                    return <EmailVerification verification={verification} />;

                                case GoogleVerificationMethodEnum.Address:
                                    return <AddressVerification verification={verification} />;

                                default:
                                    return null;
                            }
                        })()}
                    </Form>
                </>
            )}

            {error instanceof ApiError && <AntdErrorMessage>{getVerificationOptionsErrors(error)}</AntdErrorMessage>}

            {mutationError instanceof ApiError && (
                <AntdErrorMessage>{getMutationErrors(mutationError)}</AntdErrorMessage>
            )}

            {repeat && (
                <Alert
                    className={styles.CodeRequestModal_alert}
                    icon={<FontAwesomeIcon icon={faCircleQuestion} />}
                    message={<Text stringKey="GOOGLE_SYNC__CODE_REQUEST__ALERT" />}
                    showIcon
                    type="info"
                />
            )}
        </Modal>
    );
}
