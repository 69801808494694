import {Modal} from 'antd';
import {LatLngTuple, LeafletMouseEvent} from 'leaflet';
import {useMemo, useState} from 'react';
import {Marker} from 'react-leaflet';

import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {LeafletMap} from '../../../../../component/map/leaflet-map';
import {Empty} from '../../../../../layout/empty/empty';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {
    LocalPackGridItemStatusEnum,
    LocalPackGridItemType,
    LocalPackGridType,
} from '../../../../../service/local-pack/local-pack-type';
import {localPackNullPositionValue} from '../../local-pack-const';
import {LocalPackResultMapTooltip} from '../../local-pack-result-map-tooltip/local-pack-result-map-tooltip';
import {LocalPackPositionsTable} from '../positions-table/positions-table';

import {getMapIconConfig} from './results-on-map-helper';
import * as styles from './results-on-map.scss';

type ResultsOnMapPropsType = {
    data: LocalPackGridType | null;
    isInProgress: boolean;
    error: Error | null;
    searchQuery: string;
    searchQueryId: number;
};

export function LocalPackResultsOnMap(props: ResultsOnMapPropsType): JSX.Element {
    const {searchQueryId, data, isInProgress, error, searchQuery} = props;
    const [currentPoint, setCurrentPoint] = useState<LocalPackGridItemType | null>();

    const centerIndex = useMemo(() => {
        if (data) {
            const sortedLatLanArea = data.searchGrid
                .map((item, index) => ({index, value: item.lat * item.lon}))
                .sort((item1, item2) => item1.value - item2.value);

            return sortedLatLanArea[(data.searchGrid.length - 1) / 2]?.index || -1;
        }

        return -1;
    }, [data]);

    if (isInProgress) {
        return <Spinner />;
    }

    if (error || !data) {
        return <AlertFallback />;
    }

    if (data.searchGrid.length === 0) {
        return <Empty mainText="TEXT__NO_DATA" />;
    }

    function handleClose(): void {
        setCurrentPoint(null);
    }

    function handleMarkerClick(event: LeafletMouseEvent): void {
        const newCurrentPoint =
            data?.searchGrid.find((point) => point.lat === event.latlng.lat && point.lon === event.latlng.lng) || null;

        setCurrentPoint(newCurrentPoint);
        event.sourceTarget.closeTooltip();
    }

    const latLngBounds: Array<LatLngTuple> = data.searchGrid.map((gridItem: LocalPackGridItemType) => {
        return [gridItem.lat, gridItem.lon];
    });

    return (
        <div>
            <LeafletMap bounds={latLngBounds} className={styles.LocalPackMap}>
                {data.searchGrid.map((gridItem: LocalPackGridItemType, index: number) => {
                    const isCenter = centerIndex === index;

                    return (
                        <Marker
                            autoPan={false}
                            draggable={false}
                            eventHandlers={
                                gridItem.status === LocalPackGridItemStatusEnum.Complete
                                    ? {click: handleMarkerClick}
                                    : {}
                            }
                            icon={getMapIconConfig({
                                position: gridItem.position || localPackNullPositionValue,
                                status: gridItem.status,
                                isCenter,
                                isAdvertisement: Boolean(gridItem.isAd),
                            })}
                            key={gridItem.id}
                            position={[gridItem.lat, gridItem.lon]}
                        >
                            <LocalPackResultMapTooltip gridItem={gridItem} />
                        </Marker>
                    );
                })}
            </LeafletMap>
            <Modal
                cancelButtonProps={{hidden: true}}
                destroyOnClose
                footer={null}
                okButtonProps={{hidden: true}}
                onCancel={handleClose}
                open={Boolean(currentPoint)}
                title={
                    <Locale
                        stringKey="LOCAL_PACK__POSITIONS_MODAL__TITLE"
                        valueMap={{
                            searchQuery,
                        }}
                    />
                }
                width={849}
            >
                {currentPoint ? (
                    <LocalPackPositionsTable currentPoint={currentPoint} searchQueryId={searchQueryId} />
                ) : (
                    <AlertFallback />
                )}
            </Modal>
        </div>
    );
}
