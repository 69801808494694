import {faBars} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useContext} from 'react';

import {useSystem} from '../../../provider/system/system-hook';
import {UiContext} from '../../../provider/ui/ui-context';
import {Header} from '../common/header/header';

type PropsType = {
    isTopFilterHidden: boolean;
};

export function MobileHeader(props: PropsType): JSX.Element {
    const {isTopFilterHidden} = props;

    const {isMobileNavigationOpen, setIsMobileNavigationOpen} = useContext(UiContext);
    const {screen} = useSystem();

    return (
        <Header
            isFilterHidden={isTopFilterHidden}
            isShortLogo={!screen.isTablet}
            menuIcon={<FontAwesomeIcon icon={faBars} />}
            onMenuToggle={() => setIsMobileNavigationOpen(!isMobileNavigationOpen)}
        />
    );
}
