import {ProvidersEnum, ProvidersIdsEnum} from '../../util/type';

export enum PhotoSourceNameEnum {
    TwoGis = '2gis.ru',
    Yandex = 'Yandex',
    Google = 'Google',
}

export const PhotoSourceNameToProviderIdEnum: Record<PhotoSourceNameEnum, ProvidersIdsEnum> = {
    [PhotoSourceNameEnum.Google]: ProvidersIdsEnum.googleMaps,
    [PhotoSourceNameEnum.TwoGis]: ProvidersIdsEnum.doubleGis,
    [PhotoSourceNameEnum.Yandex]: ProvidersIdsEnum.yandex,
};

export const photoSourceIdToProviderIdEnum: Record<number, ProvidersEnum> = {
    1: ProvidersEnum.doubleGis,
    2: ProvidersEnum.yandex,
    3: ProvidersEnum.google,
};
