import {Space} from 'antd';
import {ReactNode} from 'react';

import {CatalogLogo} from '../../../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../../../component/text/text';
import {GoogleCompanyType, GoogleLocationType} from '../../../../../../service/google/google-type';
import {classNames} from '../../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../../util/type';

import {getGoogleCompanyOrLocationStatusTag} from './company-list-item-helper';
import {CompanyListItemContacts} from './contacts/company-list-item-contacts';
import * as styles from './company-list-item.scss';

type PropsType = {
    company: GoogleCompanyType | GoogleLocationType;
    footer?: ReactNode;
    showCatalogLogo?: boolean;
    showStatus?: boolean;
    showContacts?: boolean;
    bolderName?: boolean;
    className?: string;
};

export function CompanyListItem(props: PropsType): JSX.Element {
    const {
        company,
        footer,
        showCatalogLogo = false,
        showStatus = true,
        showContacts = true,
        bolderName,
        className,
    } = props;

    return (
        <div className={classNames(styles.CompanyAndFooter, className)}>
            <div className={styles.CompanyAndLogo}>
                {showCatalogLogo && (
                    <CatalogLogo catalogId={ProvidersIdsEnum.google} circle className={styles.CompanyAndLogo_logo} />
                )}

                <Space className={styles.Company} direction="vertical" size={2}>
                    <header className={styles.Company_header}>
                        <div className={styles.Company_nameAndCode}>
                            <Text
                                bolder={bolderName}
                                className={classNames(styles.Company_name, {
                                    [styles.Company_name__noCode]: !('code' in company) || !company.code,
                                })}
                                title={company.name}
                            >
                                {company.name}
                            </Text>

                            {'code' in company && company.code && (
                                <Text bolder secondary small title={company.code}>
                                    {company.code}
                                </Text>
                            )}
                        </div>

                        {showStatus && getGoogleCompanyOrLocationStatusTag(company)}
                    </header>

                    {'category' in company && company.category && (
                        <Text secondary small>
                            {company.category}
                        </Text>
                    )}

                    {'categories' in company && company.categories && (
                        <Text secondary small>
                            {company.categories.join(', ')}
                        </Text>
                    )}

                    <CompanyListItemContacts company={company} showContacts={showContacts} />
                </Space>
            </div>

            {footer}
        </div>
    );
}
