import {LabelledProgressBar} from '../../../../../layout/labeled-progress-bar/labelled-progress-bar';
import {useCatalog} from '../../../../../provider/catalogs/catalogs-hook';

import * as styles from './sync-cards.scss';

type PropsType = {
    catalogId: number;
    percent: number;
};

export function CatalogSyncProgressBar(props: PropsType): JSX.Element {
    const {catalogId, percent} = props;

    const {catalog} = useCatalog(catalogId);

    return <LabelledProgressBar className={styles.sync_card_item} percent={percent} title={catalog?.name || ''} />;
}
