export const enCategoryNameTranslations = {
    CATEGORY_NAME__MY_COMPANIES: 'My companies',
    CATEGORY_NAME__SOURCES: 'Sources',
    CATEGORY_NAME__2_GIS_REPORT: 'Report',
    CATEGORY_NAME__WORKING_WITH_REVIEWS: 'Reviews management',
    CATEGORY_NAME__REVIEWS: 'Reviews',
    CATEGORY_NAME__REVIEWS_ANALYSIS: 'Reviews analysis',
    CATEGORY_NAME__REVIEWS_ANALYSIS__DASHBOARD: 'Dashboard',
    CATEGORY_NAME__REVIEWS_ANALYSIS__COMPANIES: 'Companies',
    CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES: 'Cities',
    CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS: 'Keywords and topics',
    CATEGORY_NAME__REVIEWS_ANALYSIS__PHRASES: 'Phrases',
    CATEGORY_NAME__REVIEWS_ANALYSIS__EMPLOYEES: 'Employees',
    CATEGORY_NAME__REVIEWS_ANALYSIS__TOPICS: 'Topics',
    CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC_CREATE: 'Topic creation',
    CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC_UPDATE: 'Topic edition',
    CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC: 'View topic',
    CATEGORY_NAME__REVIEWS_ANALYSIS__CONCORDANCE: 'Search by reviews',
    CATEGORY_NAME__ONLINE_PRESENCE: 'Online presence analytics',
    CATEGORY_NAME__RESPONSE_TEMPLATES: 'Response templates',
    CATEGORY_NAME__TAGS: 'Tags',
    CATEGORY_NAME__AUTO_REPLIES: 'Auto-replies',
    CATEGORY_NAME__NEURAL_NETWORK: 'Ai Review responder',
    CATEGORY_NAME__QUESTIONS_N_ANSWERS: 'Questions and answers',
    CATEGORY_NAME__PHOTO_TOOL: 'Photo & Video',
    CATEGORY_NAME__PHOTO_TOOL_ANALYTICS: 'Analytics',
    CATEGORY_NAME__PRODUCTS: 'Products and prices',
    CATEGORY_NAME__TARIFFS: 'Tariffs and limits',
    CATEGORY_NAME__SETTINGS: 'Settings',
    CATEGORY_NAME__PROFILE: 'Profile',
    CATEGORY_NAME__GROUPS_OF_COMPANIES: 'Groups of companies',
    CATEGORY_NAME__DASHBOARD: 'Dashboard',
    CATEGORY_NAME__NOTIFICATIONS: 'Notifications',
    CATEGORY_NAME__INTEGRATION: 'Integration',
    CATEGORY_NAME__SOURCE_SETTINGS: 'Source settings',
    CATEGORY_NAME__DUBS_AND_FAKES: 'Dubs and fakes',
    CATEGORY_NAME__REVIEWS_GENERATOR: 'Reviews Generator',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILINGS: 'Notification campaigns',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__CREATE: 'Create notification mailing',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__EDIT: 'Edit notification mailing',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__CLIENTS_SELECT: 'Selected clients',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__CHANNEL_ACTIVATION: 'Choosing an activation channel',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__TEMPLATES: 'Templates',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__CHOOSING_TEMPLATES: 'Choosing a template',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__MAIL: 'Rocketmail',
    CATEGORY_NAME__REVIEWS_GENERATOR__MAILING__DETAILS: 'Mailing List Details',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS: 'Clients',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_CREATE: 'Client creation',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_CREATE__SUCCESS__TITLE: 'Data saved',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_CREATE__SUCCESS__TEXT:
        'Information about the new client has been successfully added to the customer database.',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_UPDATE: 'Client update',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_UPDATE__SUCCESS__TITLE: 'Data saved',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_UPDATE__SUCCESS__TEXT:
        'The customer information has been successfully updated.',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS__MANAGEMENT_ERROR__CLIENT_EXISTS__TITLE: 'The client already exists',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS__MANAGEMENT_ERROR__CLIENT_EXISTS__TEXT:
        'An entry with this phone number already exists in your customer base.',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS__MANAGEMENT_ERROR__FALLBACK__TITLE: 'Failed to save data',
    CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS_IMPORT: 'Import client database',
    CATEGORY_NAME__REVIEWS_GENERATOR__NEGATIVE_REVIEWS: 'Negative reviews',
    CATEGORY_NAME__REVIEWS_GENERATOR__NPS: 'NPS',
    CATEGORY_NAME__REVIEWS_GENERATOR__QRCODE: 'QR Codes',
    CATEGORY_NAME__REVIEWS_GENERATOR__ANALYTICS: 'Analytics',
    CATEGORY_NAME__STORE_LOCATOR: 'Store locator',
    CATEGORY_NAME__KNOWLEDGE: 'Knowledge base',
    CATEGORY_NAME__POSTS: 'Posts',
    CATEGORY_NAME__ONLINE_PRESENCE_ANALYTICS: 'Online presence',
    CATEGORY_NAME__POSTS_CALENDAR: 'Posts calendar',
    CATEGORY_NAME__CREATE_POSTS: 'Create post',
    CATEGORY_NAME__EDIT_POSTS: 'Edit post',
    CATEGORY_NAME__POST_DETAILS: 'Links to the publication on the site',
    CATEGORY_NAME__USERS: 'Users',
    CATEGORY_NAME__ACCOUNTS_LINKING: 'Account settings',
    CATEGORY_NAME__VIEW_POSTS: 'View post',
    CATEGORY_NAME__LOCAL_PACK: 'Local rank tracker',
    CATEGORY_NAME__COMPANIES_MATCHING: 'Accounts matching',
    CATEGORY_NAME__COMPANIES_MATCHING__SINGLE: 'Single company account matching',
    CATEGORY_NAME__COMPANIES_MATCHING__MASS: 'Mass company accounts matching',
    CATEGORY_NAME__SOURCES_CONFIRMATION: 'Confirmation Requests',
    CATEGORY_NAME__SOURCES_CONFIRMATION__GOOGLE: 'Company confirmation in Google',
    CATEGORY_NAME__SOURCES_CONFIRMATION__YANDEX: 'Company confirmation in Yandex',
    CATEGORY_NAME__ONLINE_PRESENCE__DASHBOARD: 'Overall indicators',
    CATEGORY_NAME__ONLINE_PRESENCE__GOOGLE: 'Google Business Profile',
    CATEGORY_NAME__ONLINE_PRESENCE__YANDEX: 'Yandex Business',
    CATEGORY_NAME__ONLINE_PRESENCE__FACEBOOK: '{catalogName}',
    CATEGORY_NAME__ONLINE_PRESENCE__WGIS: '2GIS',
    CATEGORY_NAME__ONLINE_PRESENCE__SYNCHRONIZATION: 'Synchronization at platforms',
    CATEGORY_NAME__ONLINE_PRESENCE__PHRASES: 'Search phrases',
    CATEGORY_NAME__ONLINE_PRESENCE__LOCATIONS: 'Analytics by location',
    CATEGORY_NAME__OMNICHANNEL: 'Communication',
    CATEGORY_NAME__OMNICHANNEL__COMMUNICATION: 'Communication with clients',
    CATEGORY_NAME__OMNICHANNEL__SETTINGS: 'Settings',
    CATEGORY_NAME__HELP: 'Help',
    CATEGORY_NAME__HELP__TECHNICAL_SUPPORT: 'Contact support',
    CATEGORY_NAME__CREATION_OF_THE_COMPANY: 'Creation of the company',
    CATEGORY_NAME__PHOTO_TOOL_DETAILS: 'Photo info',
    CATEGORY_NAME__PHOTO_TOOL__VIDEO_DETAILS: 'Video info',
    CATEGORY_NAME__EDITING_A_COMPANY: 'Editing a company',
    CATEGORY_NAME__EDITING_COMPANIES: 'Editing of companies',
    CATEGORY_NAME__REVIEWS_GENERATOR__PROMO_CODES: 'Promo codes',
    CATEGORY_NAME__REVIEWS_GENERATOR__QUESTIONNAIRE: 'Questionnaires',
    CATEGORY_NAME__REVIEWS_ANALYSIS__RATINGS: 'Card Ratings',
    CATEGORY_NAME__REVIEWS_GENERATOR__PROMO_CODES_LIST: 'Promo codes list',
    CATEGORY_NAME__REVIEWS_GENERATOR__USED_PROMO_CODES_LIST: 'List of Sent and Pending Promo Codes',
    CATEGORY_NAME__REVIEWS_GENERATOR__PROMO_CODE__CREATE: 'Create promo code',
    CATEGORY_NAME__REVIEWS_GENERATOR__PROMO_CODE__EDIT: 'Edit promo code',
    CATEGORY_NAME__ONBOARDING__DESCRIPTION: 'Where to start?',
    CATEGORY_NAME__REVIEWS_AND_ANSWERS_STATISTICS: 'Review and Response Statistics',
    CATEGORY_NAME__REVIEWS_AND_ANSWERS_STATISTICS__NAVIGATION: 'Statistics',

    BETA_FEATURE__TOOLTIP: 'Beta version of the section. Thank you for understanding.',
    NEW_FEATURE__TOOLTIP: 'New version of the section.',
};
