import {ReactNode} from 'react';
import {z as r} from 'zod';

import {LocalPackAggregationTypeEnum} from '../../page/main/local-pack/position-distribution/position-distribution-type';

import {localPackSearchResultsItemSchema} from './local-pack-api-type';

export enum LocalPackCatalogsEnum {
    google = 'google',
    yandex = 'yandex',
}

export enum LocalPackServicesEnum {
    desktop = 'desktop',
    mobile = 'mobile',
    maps = 'maps',
}

export type LocalPackSearchServiceType = {
    id: number;
    provider: LocalPackCatalogsEnum;
    service: LocalPackServicesEnum;
    isActive: boolean;
};

export type LocalPackPositionsType = {
    currentPosition: number | null;
    previousPosition: number | null;
    hasPrev: boolean;
};

export type SearchQueryType = {
    id: number;
    query: string;
};

export type SearchServiceQueriesType = {
    id: number;
    provider: LocalPackCatalogsEnum;
    service: LocalPackServicesEnum;
    queries: [SearchQueryType, ...Array<SearchQueryType>];
};

export enum LocalPackGridItemStatusEnum {
    New = 'new',
    Complete = 'complete',
    Failed = 'failed',
    Empty = 'empty',
}

const localPackGridSchema = r.object({
    id: r.number(),
    position: r.number().nullable(),
    isAd: r.boolean().nullable(),
    lat: r.number(),
    lon: r.number(),
    searchResults: r.array(
        r.object({
            id: r.number(),
            name: r.string(),
            position: r.number(),
            isAd: r.boolean(),
        })
    ),
    status: r.nativeEnum(LocalPackGridItemStatusEnum),
});

export const localPackGridDataSchema = r.object({
    previousDate: r.string().nullable(),
    nextDate: r.string().nullable(),
    searchGrid: r.array(localPackGridSchema),
});

export type LocalPackGridItemType = r.infer<typeof localPackGridSchema>;
export type LocalPackGridType = r.infer<typeof localPackGridDataSchema>;

export type SearchResultItemType = r.infer<typeof localPackSearchResultsItemSchema>;

export const localPackMinimalAggregationSchema = r.object({
    key: r.nativeEnum(LocalPackAggregationTypeEnum),
});

export type LocalPackMinimalAggregationResponseType = r.infer<typeof localPackMinimalAggregationSchema>;

const localPackQueryResultValueSchema = r.object({
    currentPosition: r.number().nullable(),
    previousPosition: r.number().nullable(),
    hasPrev: r.boolean(),
});

const localPackQueryResultFullValueSchema = localPackQueryResultValueSchema.merge(
    r.object({
        service: r.nativeEnum(LocalPackServicesEnum),
    })
);

const localPackQueryResultSchema = r.object({
    searchQueryId: r.number(),
    searchQuery: r.string(),
    date: r.string(),
    values: r.array(localPackQueryResultFullValueSchema),
});

export const localPackQueryResultsSchema = r.object({
    count: r.number(),
    results: r.array(localPackQueryResultSchema),
});

export type LocalPackQueryResultValueResponseType = r.infer<typeof localPackQueryResultValueSchema>;
export type LocalPackQueryResultFullValueResponseType = r.infer<typeof localPackQueryResultFullValueSchema>;
export type LocalPackQueryResultResponseType = r.infer<typeof localPackQueryResultSchema>;
export type LocalPackQueryResultsResponseType = r.infer<typeof localPackQueryResultsSchema>;

const localPackDataTableResultPositionSchema = r.object({
    currentPosition: r.number().nullable(),
    previousPosition: r.number().nullable(),
    hasPrev: r.boolean(),
});

const localPackDataTableResultFullValueSchema = localPackDataTableResultPositionSchema.merge(
    r.object({
        service: r.nativeEnum(LocalPackServicesEnum),
        provider: r.nativeEnum(LocalPackCatalogsEnum),
    })
);

const localPackDataTableResultAddressSchema = r.object({
    country: r.string(),
    city: r.nullable(r.string()),
    street: r.string(),
    housenumber: r.string(),
});

const localPackDataTableCompanyResultValueSchema = r.object({
    id: r.number(),
    name: r.string(),
    address: localPackDataTableResultAddressSchema,
});

const localPackDataTableResultSchema = r.object({
    company: localPackDataTableCompanyResultValueSchema,
    searchQueries: r.array(r.number()),
    date: r.string(),
    values: r.array(localPackDataTableResultFullValueSchema),
});

export const localPackDataTableResultsSchema = r.object({
    count: r.number(),
    results: r.array(localPackDataTableResultSchema),
});

export type LocalPackDataTableResultPositionType = r.infer<typeof localPackDataTableResultPositionSchema>;
export type LocalPackDataTableResultFullValueType = r.infer<typeof localPackDataTableResultFullValueSchema>;
export type LocalPackDataTableResultType = r.infer<typeof localPackDataTableResultSchema>;
export type LocalPackDataTableResultsResponseType = r.infer<typeof localPackDataTableResultsSchema>;
export type LocalPackSearchGridOptionsType = {
    companyId: number;
    searchQueryId: number;
    searchServiceId: number;
    targetDate: string | null;
};

export enum LocalPackPointDistanceEnum {
    Low = 500,
    Medium = 1000,
    High = 1500,
}

export enum LocalPackSquareSizeEnum {
    Smallest = '3x3',
    Small = '5x5',
    Medium = '7x7',
    Big = '9x9',
    Biggest = '11x11',
}

export const localPackSearchQuerySchema = r.object({
    id: r.number(),
    query: r.string(),
});

export type LocalPackSearchQueryType = r.infer<typeof localPackSearchQuerySchema>;

export type LicenseCardSmallType = {
    icon: ReactNode;
    title: ReactNode;
    description: ReactNode;
};

export enum LicenseStatusEnum {
    active = 'active',
    expired = 'expired',
    requested = 'requested',
}
