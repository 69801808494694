import {Button, Modal} from 'antd';
import {useCallback, useMemo} from 'react';

import {RatingStarLine} from '../../../../../layout/rating-star-line/rating-star-line';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {ReviewHistoryDataType, useReviewHistoryList} from '../../../../../service/reviews/reviews-history';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {constructComment} from '../../review-list-page-content/review-list/review/review-helper';

import * as styles from './review-history-popup.scss';

type PropsType = {
    className?: string;
    isOpen: boolean;
    onCancel: () => void;
    reviewId: number;
    reviewCreationDate: string;
};

export function ReviewHistoryPopup(props: PropsType): JSX.Element {
    const {className, isOpen, onCancel, reviewId, reviewCreationDate} = props;
    const {getFormattedDateTime} = useFormat();

    const {isFetching: reviewHistoryListIsInProgress, data: reviewHistoryListResult} = useReviewHistoryList(
        reviewId,
        isOpen
    );

    const reviewHistoryList: Array<ReviewHistoryDataType> = useMemo(
        (): Array<ReviewHistoryDataType> => reviewHistoryListResult || [],
        [reviewHistoryListResult]
    );

    const renderHistoryItem = useCallback(
        (reviewHistoryData: ReviewHistoryDataType): JSX.Element => {
            const {date, old_value: oldValue, new_value: newValue} = reviewHistoryData;

            const oldValueAsNumber = Number.parseInt(oldValue, 10) || 0;
            const newValueAsNumber = Number.parseInt(newValue, 10) || 0;

            const creationDate = new Date(reviewCreationDate.replace('Z', '')); // TODO remove replace() after FDBK-1444 fix
            const changedDate = new Date(date);

            return (
                <li className={styles.review_history__list_item} key={date}>
                    <h4 className={styles.review_history__item__header}>
                        <Locale stringKey="REVIEWS__REVIEW_CHANGE_HISTORY__BEFORE" />

                        <span className={styles.review_history__item__header_time}>
                            {getFormattedDateTime(creationDate, {hour: '2-digit', minute: '2-digit'})}
                            &nbsp;
                            {getFormattedDateTime(creationDate, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })}
                        </span>
                    </h4>

                    <div className={styles.review_history__item__rating}>
                        <span>
                            <Locale stringKey="REVIEWS__REVIEW_CHANGE_HISTORY__RATING" />
                        </span>

                        <RatingStarLine rating={oldValueAsNumber} />
                    </div>

                    <div className={styles.review_history__item__review_text}>{constructComment(oldValue)}</div>

                    <h4 className={styles.review_history__item__header}>
                        <Locale stringKey="REVIEWS__REVIEW_CHANGE_HISTORY__AFTER" />

                        <span className={styles.review_history__item__header_time}>
                            {getFormattedDateTime(changedDate, {hour: '2-digit', minute: '2-digit'})}
                            &nbsp;
                            {getFormattedDateTime(changedDate, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })}
                        </span>
                    </h4>

                    <div className={styles.review_history__item__rating}>
                        <span>
                            <Locale stringKey="REVIEWS__REVIEW_CHANGE_HISTORY__RATING" />
                        </span>

                        <RatingStarLine rating={newValueAsNumber} />
                    </div>

                    <div className={styles.review_history__item__review_text}>{constructComment(newValue)}</div>
                </li>
            );
        },
        [getFormattedDateTime, reviewCreationDate]
    );

    return (
        <Modal
            className={classNames(styles.review_history_popup, className)}
            footer={[
                <Button key="close" onClick={onCancel} type="primary">
                    <Locale stringKey="POPUP__BUTTON__CLOSE" />
                </Button>,
            ]}
            onCancel={onCancel}
            open={isOpen}
            title={<Locale stringKey="REVIEWS__REVIEW_CHANGE_HISTORY" />}
        >
            <div className={styles.review_history_popup__container}>
                <Spinner isShow={reviewHistoryListIsInProgress} position="absolute" />

                <ul className={styles.review_history__list}>{reviewHistoryList.map(renderHistoryItem)}</ul>
            </div>
        </Modal>
    );
}
