import {useFooterText} from './footer-hook';
import * as styles from './footer.scss';

export function Footer(): JSX.Element {
    const text = useFooterText();

    return (
        <footer className={styles.footer}>
            <p className={styles.text}>{text}</p>
        </footer>
    );
}
