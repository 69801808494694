import {TablePaginationConfig} from 'antd';
import {Dayjs} from 'dayjs';

export enum LocalPackFilterEnum {
    timeRange = 'timeRange',
    searchQueries = 'searchQueries',
}

export enum LocalPackFilterUrlQueryNameEnum {
    startDate = 'start_date',
    endDate = 'end_date',
    searchQueries = 'search_query_ids',
}

type TimeRangeType = [Dayjs | null, Dayjs | null];

export type LocalPackFilterType = Readonly<{
    [LocalPackFilterEnum.timeRange]: TimeRangeType;
    [LocalPackFilterEnum.searchQueries]: Array<number>;
}>;

export type SetLocalPackFilterType = (filterPartial: Partial<LocalPackFilterType>) => void;

export type LocalPackFilterQueriesType = {
    [key in LocalPackFilterUrlQueryNameEnum]?: string;
};

export type UseLocalPackFilterType = {
    filter: LocalPackFilterType;
    setFilter: (filter: Partial<LocalPackFilterType>) => void;
    filterQueries: LocalPackFilterQueriesType;
};

export type LocalPackParametersType = {
    pagination: TablePaginationConfig;
    filter: LocalPackFilterType;
};
