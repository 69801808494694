import {Subscription} from 'centrifuge';
import {z as r} from 'zod';

export const centrifugeTokenResponseSchema = r.object({
    token: r.string(),
});

export type CentrifugeTokenResponseType = r.infer<typeof centrifugeTokenResponseSchema>;

export type CentrifugeHookType = {
    getSubscription: (channelName: string) => Subscription | null;
};
