import {Typography} from 'antd';

import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../provider/user/user-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../util/type';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabIndex} from '../../catalog-list/catalog-list-helper';
import {useIsCatalogAvailableInCountry} from '../../catalog-list/catalog-list-hook';

import {CatalogDescriptions} from './catalog-descriptions';
import {CATALOG_DESCRIPTION_LIMITS, DESCRIPTION_LIMITS} from './descriptions-widget-const';
import {LongDescriptionFormItem} from './long-description-form-item/long-description-form-item';
import {ShortDescriptionFormItem} from './short-description-form-item/short-description-form-item';
import {ShortDescriptionWithFacebookFormItem} from './short-description-form-item/short-description-with-facebook-form-item';
import * as styles from './descriptions-widget.scss';

type PropsType = {
    name: string;
    initialCatalogs?: Array<number>;
    isDoubleGisCompany: boolean;
};

export function DescriptionsWidget(props: PropsType): JSX.Element {
    const {name, initialCatalogs, isDoubleGisCompany} = props;

    const {user} = useUser();
    const isFacebookAvailable = useIsCatalogAvailableInCountry(ProvidersIdsEnum.facebook);
    const {getLocalizedString} = useLocale();

    return (
        <>
            <div className={styles.descriptions_widget__wrapper}>
                <div className={styles.descriptions_widget__inputs}>
                    {isFacebookAvailable && user?.regionAvailableCatalogs[ProvidersIdsEnum.facebook] ? (
                        <ShortDescriptionWithFacebookFormItem
                            isDoubleGisCompany={isDoubleGisCompany}
                            maxLength={DESCRIPTION_LIMITS.short}
                            name={[CompanyKeyEnum.Descriptions, 'short']}
                        />
                    ) : (
                        <ShortDescriptionFormItem
                            maxLength={DESCRIPTION_LIMITS.short}
                            name={[CompanyKeyEnum.Descriptions, 'short']}
                        />
                    )}
                    <LongDescriptionFormItem
                        maxLength={DESCRIPTION_LIMITS.long}
                        name={[CompanyKeyEnum.Descriptions, 'long']}
                    />
                </div>

                <Typography.Text className={styles.descriptions_widget__description} type="secondary">
                    <Locale stringKey="COMPANY_FORM__DESCRIPTIONS__DESCRIPTION" />
                </Typography.Text>
            </div>

            <Form.Item<CompanyFormType>
                dependencies={[
                    [CompanyKeyEnum.Descriptions, 'short'],
                    [CompanyKeyEnum.Descriptions, 'long'],
                ]}
                noStyle
            >
                {({getFieldValue, setFieldsValue, getFieldsError}) => (
                    <CatalogList
                        activeTabIndex={getErrorTabIndex(getFieldsError, CompanyKeyEnum.ExtraDescriptions)}
                        disabled={
                            !getFieldValue([CompanyKeyEnum.Descriptions, 'short']) &&
                            !getFieldValue([CompanyKeyEnum.Descriptions, 'long'])
                        }
                        initialCatalogs={initialCatalogs}
                        isCatalogVisible={({canRewriteShortDescription, canRewriteLongDescription}) =>
                            canRewriteShortDescription || canRewriteLongDescription
                        }
                        onAddCatalogs={(catalogs: Array<CatalogConfigType>) => {
                            const descriptions = getFieldValue(CompanyKeyEnum.Descriptions);
                            const extraDescriptions = catalogs.map(({catalogId}) => ({
                                catalogId,
                                ...(descriptions
                                    ? {
                                          short: descriptions.short?.slice(
                                              0,
                                              CATALOG_DESCRIPTION_LIMITS.short[catalogId]
                                          ),
                                          long: descriptions.long?.slice(0, CATALOG_DESCRIPTION_LIMITS.long[catalogId]),
                                      }
                                    : {}),
                            }));

                            setFieldsValue({
                                extraDescriptions: [
                                    ...getFieldValue(CompanyKeyEnum.ExtraDescriptions),
                                    ...extraDescriptions,
                                ],
                            });
                        }}
                        onCatalogAvailabilityChange={(availableCatalogIds: Array<number>) => {
                            setFieldsValue({
                                extraDescriptions: getFieldValue(CompanyKeyEnum.ExtraDescriptions).filter(
                                    ({catalogId}) => availableCatalogIds.includes(catalogId)
                                ),
                            });
                        }}
                        renderCatalog={(catalog: CatalogConfigWithIndexType, {handleRemoveCatalog}) => (
                            <CatalogDescriptions
                                catalog={catalog}
                                handleRemoveCatalog={handleRemoveCatalog}
                                name={name}
                            />
                        )}
                        title={getLocalizedString('COMPANY_FORM__DESCRIPTIONS__CATALOGS_HEADER')}
                    />
                )}
            </Form.Item>
        </>
    );
}
