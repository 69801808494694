import {Context, createContext, PropsWithChildren, useMemo} from 'react';

import {useBrands} from '../brands/brand-hook';
import {BrandType} from '../brands/brand-type';

import {BrandFeaturesContextType, BrandFeaturesType} from './brand-features-context-type';

const BrandFeaturesContext: Context<BrandFeaturesContextType | null> = createContext<BrandFeaturesContextType | null>(
    null
);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function BrandFeaturesProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const {brands, isLoading} = useBrands();

    const providerData: BrandFeaturesContextType = useMemo(() => {
        const brandFeatureEntries: Array<[string, BrandFeaturesType]> =
            brands.map((brand: BrandType): [string, BrandFeaturesType] => [String(brand.id), brand?.features || {}]) ||
            [];

        return {
            isInProgress: isLoading,
            featuresByBrand: Object.fromEntries(brandFeatureEntries),
        };
    }, [brands, isLoading]);

    return <BrandFeaturesContext.Provider value={providerData}>{children}</BrandFeaturesContext.Provider>;
}
