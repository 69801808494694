import {useCallback, useMemo} from 'react';

import {Empty} from '../../../../../layout/empty/empty';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {ReviewCatalogType} from '../../../../../service/reviews/reviews-catalogs';
import {ReviewDataType} from '../../../../../service/reviews/reviews-type';
import {classNames} from '../../../../../util/css';
import {ReviewActivityProvider} from '../../review-activity-provider/review-activity-provider';
import {useReviewStateHook} from '../../reviews-state/reviews-state-provider';

import {NewReview} from './review/new-review';
import {checkLastComment} from './review-list-helper';
import {ReviewDataWithCatalogType} from './review-list-type';
import {ReviewSkeleton} from './review-skeleton/review-skeleton';
import {ReviewSourcesBanner} from './review-sources-banner/review-sources-banner';
import * as styles from './review-list.scss';

type PropsType = {
    className?: string;
};

interface CatalogListInterface {
    [key: string]: ReviewCatalogType;
}

export function NewReviewList(props: PropsType): JSX.Element {
    const {className} = props;

    const {reviews, reviewsCatalogList, isReviewsInProgress} = useReviewStateHook();
    const {isShowReviewSourcesBanner} = useDomainConfig();

    const fullClassName = classNames(styles.ReviewList, className);
    const {results: reviewListRaw} = reviews || {};

    const isExpandedReplies = useCallback(
        (reviewData: ReviewDataWithCatalogType) => checkLastComment(reviewData),
        [reviewListRaw]
    );

    const catalogList = useMemo(() => {
        if (!reviewsCatalogList) {
            return [];
        }

        return reviewsCatalogList.reduce((accumulator: CatalogListInterface, current: ReviewCatalogType) => {
            return {
                ...accumulator,
                [current.id]: current,
            };
        }, {} as Record<number, ReviewCatalogType>);
    }, [reviewsCatalogList]);

    const reviewList: Array<ReviewDataWithCatalogType> =
        reviewListRaw?.map((review: ReviewDataType) => {
            return {
                ...review,
                catalog: catalogList[review.catalogId] || null,
            };
        }) || [];

    if (isReviewsInProgress) {
        return (
            <ul className={fullClassName}>
                {Array.from({length: 3}).map((_item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className={styles.ReviewList_item} key={String(index)}>
                        <ReviewSkeleton />
                    </li>
                ))}
            </ul>
        );
    }

    if (reviewList.length === 0 && !isReviewsInProgress) {
        return (
            <PageCard className={styles.ReviewList__sticky}>
                <div className={fullClassName}>
                    <Empty
                        className={styles.ReviewList_emptyBlock}
                        mainText="EMPTY__THERE_IS_NOTHING_HERE_YET"
                        secondaryText="EMPTY__TRY_CHANGING_FILTERS"
                    />
                </div>
            </PageCard>
        );
    }

    return (
        <ul className={fullClassName}>
            {reviewList.map((reviewData: ReviewDataWithCatalogType, index): JSX.Element => {
                const reviewKey = `${reviewData.id}-${reviewData.tags?.map((tag) => tag.id).join('-')}`;

                return (
                    <li className={styles.ReviewList_item} key={reviewKey}>
                        {isShowReviewSourcesBanner && index === 2 && <ReviewSourcesBanner />}
                        <ReviewActivityProvider expandedReplies={isExpandedReplies(reviewData)}>
                            <NewReview reviewData={reviewData} />
                        </ReviewActivityProvider>
                    </li>
                );
            })}
        </ul>
    );
}
