import {Button, Form, Input} from 'antd';

import {PageSubHeader} from '../../../../../layout/page-header/page-sub-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {useDisonnect2fa} from '../../../../../service/user-2fa/user-2fa-hook';
import {User2faCodeType} from '../../../../../service/user-2fa/user-2fa-type';
import {getMapFromObject} from '../../../../../util/object';
import {defaultTwoFactorAuthentication} from '../../profile-const';
import {TwoFactorAuthenticationType} from '../../profile-type';
import * as twoFactorAuthenticationStyles from '../two-factor-authentication.scss';
import {serializeAuthorizationCode} from '../two-factor-authentication-helper';

type PropsType = {
    className: string;
    onFinishSubmit: () => void;
};

export function DisableTwoFactor(props: PropsType): JSX.Element {
    const {className, onFinishSubmit} = props;
    const [form] = Form.useForm<{authorizationCode: string}>();

    const {getLocalizedString} = useLocale();
    const {lengthFieldRule, requiredFieldRule} = useFormRules();

    const {disconnect2fa, isInProgress, processError} = useDisonnect2fa();

    async function handleFormSubmit(): Promise<void> {
        const {authorizationCode} = getMapFromObject<TwoFactorAuthenticationType>(
            form.getFieldsValue(),
            defaultTwoFactorAuthentication
        );

        const formData: User2faCodeType = serializeAuthorizationCode(authorizationCode);

        disconnect2fa(formData)
            .then(onFinishSubmit)
            .catch((error_: unknown) =>
                console.error('Error while trying to disconnect 2 factor authoriztion', error_)
            );
    }

    const error = processError?.jsonData.code?.[0];

    return (
        <Form
            className={className}
            form={form}
            layout="vertical"
            name="two-factor-authentication"
            onFinish={handleFormSubmit}
            requiredMark={false}
        >
            <PageSubHeader>
                <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__SUB_HEADER_DISABLE_TWO_FACTOR" />
            </PageSubHeader>

            <div className={twoFactorAuthenticationStyles.two_factor_authentication__input_list_wrapper}>
                <div className={twoFactorAuthenticationStyles.two_factor_authentication__left}>
                    <Form.Item
                        className={twoFactorAuthenticationStyles.two_factor_authentication__input}
                        help={error}
                        label={<Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__LABEL__AUTHORIZATION_CODE" />}
                        name="authorizationCode"
                        rules={[requiredFieldRule, lengthFieldRule(6, false)]}
                        validateStatus={error && 'error'}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'PROFILE__TWO_FACTOR_AUTHENTICATION__PLACEHOLDER__AUTHORIZATION_CODE'
                            )}
                            size="large"
                        />
                    </Form.Item>
                </div>
            </div>

            <Button htmlType="submit" loading={isInProgress} type="primary">
                <Locale stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__BUTTON__DISABLE_GOOGLE_AUTHENTICATOR" />
            </Button>
        </Form>
    );
}
