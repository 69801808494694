import {Table} from 'antd';
import {useMemo} from 'react';

import {FormattedDateTime} from '../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../provider/locale/localization';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {
    ReviewsAndAnswersFilterQueriesEnum,
    ReviewsAndAnswersFilterStateType,
} from '../../../../service/reviews-and-answers-statistics/filter/reviews-and-answers-statistics-filter-type';

type ReviewsAndAnswersPdfFilterTableItemType = {
    periodLangKeyType: LangKeyType;
    start: Date;
    end: Date;
};

type PropsType = {
    filter: ReviewsAndAnswersFilterStateType;
};

export function ReviewsAndAnswersFilterPdf(props: PropsType): JSX.Element {
    const {filter} = props;

    const dataSource: Array<ReviewsAndAnswersPdfFilterTableItemType> = useMemo(() => {
        const periodAItem: ReviewsAndAnswersPdfFilterTableItemType = {
            periodLangKeyType: 'REVIEW_AND_ANSWER_STATISTICS__PERIOD_A',
            start: filter[ReviewsAndAnswersFilterQueriesEnum.PeriodStart],
            end: filter[ReviewsAndAnswersFilterQueriesEnum.PeriodEnd],
        };

        const periodBItem: ReviewsAndAnswersPdfFilterTableItemType | null =
            filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart] &&
            filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]
                ? {
                      periodLangKeyType: 'REVIEW_AND_ANSWER_STATISTICS__PERIOD_B',
                      start: filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart],
                      end: filter[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd],
                  }
                : null;

        return [periodAItem, ...(periodBItem ? [periodBItem] : [])];
    }, [filter]);

    return (
        <Table<ReviewsAndAnswersPdfFilterTableItemType>
            dataSource={dataSource}
            pagination={false}
            rowKey={(record) => record.periodLangKeyType}
        >
            <Table.Column<ReviewsAndAnswersPdfFilterTableItemType>
                render={(_value, record) => {
                    return <Locale stringKey={record.periodLangKeyType} />;
                }}
                title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__PDF_FILTER__COLUMN_PERIOD" />}
            />
            <Table.Column<ReviewsAndAnswersPdfFilterTableItemType>
                render={(_value, record) => {
                    return <FormattedDateTime value={record.start} />;
                }}
                title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__PDF_FILTER__COLUMN_START" />}
            />
            <Table.Column<ReviewsAndAnswersPdfFilterTableItemType>
                render={(_value, record) => {
                    return <FormattedDateTime value={record.end} />;
                }}
                title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__PDF_FILTER__COLUMN_END" />}
            />
        </Table>
    );
}
