import {z as r} from 'zod';

import {PermissionNameEnum} from '../../company/company-type';
import {CompanyKeyEnum} from '../company-const';

export const companyPermissionsSchema = {
    [CompanyKeyEnum.Permissions]: r.array(r.nativeEnum(PermissionNameEnum)),
};

const companyPermissionsSchemaObject = r.object(companyPermissionsSchema);

export type CompanyPermissionsType = r.infer<typeof companyPermissionsSchemaObject>;
