import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';
import {PaginatedResponseType, RequestOptionsType} from '../api/api-type';

import {addressResponseSchema, AddressType, GetAddressArgumentType} from './address-type';

export function fetchAddresses(
    addressArgument: GetAddressArgumentType,
    option: RequestOptionsType
): Promise<PaginatedResponseType<AddressType>> {
    const addressData = {
        company_ids: addressArgument?.companyIdList?.join(',') || null,
        // brand_ids: addressArgument?.brandIdList?.join(',') || null,
    };

    const url = `/v2/companies/address/?${objectToUrlParameters({...option, ...addressData})}`;

    return fetchAndDeserialize(url, addressResponseSchema);
}
