import {useEffect, useRef, useState} from 'react';

import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {noop} from '../../../../../../../util/function';
import {waitForTime} from '../../../../../../../util/time';

import * as styles from './lazy-image.scss';

type PropsType = {
    src: string;
    alt: string;
};

let queue: Promise<void> = Promise.resolve();

// This method is created only for images from irecommend.ru
// https://bizdata.atlassian.net/browse/FDBK-1484
// irecommend bans frequently requests. Thats why we need this queue
// This is very tricky way to make queue. Don't use it somewhere else!
// The correct way to avoid this is to keep
// This was done because it
function addImageToLoadQueue(image: HTMLImageElement, src: string): Promise<void> {
    const newLoadPromise: Promise<void> = queue.then(() => {
        // eslint-disable-next-line promise/no-nesting
        return new Promise((resolve) => {
            image.addEventListener(
                'load',
                () => {
                    resolve({});
                },
                {once: true}
            );

            // eslint-disable-next-line no-param-reassign
            image.src = src;
        }).then(() => waitForTime(2500)); // This is interval between images' loadings
    });

    queue = newLoadPromise;

    return newLoadPromise;
}

// This tricky component is for reviews page only. Don't use it somewhere else!
export function ReviewLazyImage(props: PropsType): JSX.Element {
    const {src, alt} = props;

    const imgRef = useRef<HTMLImageElement | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const image = imgRef.current;

        let delayTimer: number | null = null;

        if (image) {
            const observer = new IntersectionObserver(([entry]) => {
                if (!entry || image.src) {
                    // is image already loaded?
                    return;
                }

                if (entry.isIntersecting && !delayTimer) {
                    setIsLoading(true);
                    // we load the image if user see image in the viewport for some time (2.5s)
                    delayTimer = setTimeout(() => {
                        addImageToLoadQueue(image, src)
                            .finally(() => setIsLoading(false))
                            .catch(console.error);
                    }, 2500);
                }

                if (!entry.isIntersecting && delayTimer) {
                    // the image loading wasn't started but user doesn't see it anymore. Thus we don't want to start loading
                    clearTimeout(delayTimer);
                    delayTimer = null;
                }
            });

            observer.observe(image);

            return () => observer.unobserve(image);
        }

        return () => noop();
    }, [src]);

    return (
        <div className={styles.container}>
            {isLoading && <Spinner position="absolute" size="1em" spinSize="small" />}
            <img alt={alt} ref={imgRef} />
        </div>
    );
}
