import {useCallback} from 'react';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useCompany} from '../../../../../service/company-v2/company-hook';
import {CompanyFormType, CompanyServerType, CompanyType} from '../../../../../service/company-v2/company-type';
import {FormInstance} from '../../../../../typings/antd';
import {WidgetKeysEnum} from '../../company-form-const';
import {WidgetConfigType, WidgetNamesType} from '../../company-form-type';
import {CompanyForm} from '../company-form';
import {DeletionWidget} from '../widgets/deletion/deletion-widget';

import {BREADCRUMBS_ITEMS} from './edit-company-form-const';

type PropsType = {
    company: CompanyType;
    companyId: number;
};

export function EditCompanyForm(props: PropsType): JSX.Element {
    const {company, companyId} = props;

    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const {updateCompany, isInProgress, processError: serverError} = useCompany(null);

    const companyName = company.names[0]?.name;

    const getWidgets = useCallback(
        (widgets: Array<WidgetConfigType>, widgetNames: WidgetNamesType) => {
            return [
                ...widgets,
                {
                    id: WidgetKeysEnum.deletion,
                    name: widgetNames[WidgetKeysEnum.deletion].name,
                    component: (
                        <DeletionWidget
                            address={company.address}
                            companyId={companyId}
                            name={company.names[0]?.name ?? ''}
                            needModerate={company.needModerate}
                        />
                    ),
                },
            ];
        },
        [company.address, company.names, company.needModerate, companyId]
    );

    async function handleUpdateCompany(id: number, values: CompanyServerType, form: FormInstance<CompanyFormType>) {
        const result = await updateCompany(id, values);

        if (!result) {
            return;
        }

        snackbar.success({
            message: <Locale stringKey="COMPANY_FORM__SNACKBAR__UPDATED__HEADER" />,
            description: (
                <Locale
                    stringKey="COMPANY_FORM__SNACKBAR__UPDATED__BODY"
                    valueMap={{
                        title: company?.names[0]?.name ?? '',
                    }}
                />
            ),
        });

        track(AnalyticsTarget.MyCompanies.Update, Object.keys(form.getFieldsValue(true, ({touched}) => touched)));
    }

    return (
        <CompanyForm
            breadcrumbsList={BREADCRUMBS_ITEMS}
            companyId={companyId}
            getWidgets={getWidgets}
            initialValues={company}
            isEdit
            isShowEmptyFields
            meta={getLocalizedString('COMPANY_FORM__EDITING')}
            onFinish={(values, form) => handleUpdateCompany(companyId, values, form)}
            pageTitle={`${getLocalizedString('COMPANY_FORM__EDITING')} «${companyName}»`}
            serverError={serverError}
            stickyButtons
            submitButtonText={getLocalizedString('COMPANY_FORM__UPDATE')}
            submitDisabled={isInProgress}
        />
    );
}
