import {faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {faRightLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Checkbox, Input, Switch} from 'antd';
import {useCallback} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
} from '../../../../../../service/yandex-stories/yandex-stories-type';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {Text} from '../../../../../text/text';

import {
    YANDEX_STORY_MAX_BUTTON_LINK_LENGTH,
    YANDEX_STORY_MAX_BUTTON_TEXT_LENGTH,
} from './yandex-story-form-slides-content-step-helper';
import * as styles from './yandex-story-form-slides-content-step.scss';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    activeSlideKey: string;
    onDeleteSlide: () => void;
};

export function YandexStoryActiveSlideControls(props: PropsType): JSX.Element {
    const {formInstance, activeSlideKey, onDeleteSlide} = props;

    const {requiredFieldRule, maxLengthFieldRule, validUrlRule} = useFormRules();

    const {getLocalizedString} = useLocale();

    const handleToggleButtonOnSlide = useCallback(
        (allowSlideButton: boolean) => {
            if (allowSlideButton) {
                formInstance.setFieldValue([YandexStoryFormFieldsEnum.Slides, activeSlideKey, 'buttonConfig'], {
                    link: '',
                    text: '',
                });
                return;
            }

            formInstance.setFieldValue([YandexStoryFormFieldsEnum.Slides, activeSlideKey, 'buttonConfig'], null);
            formInstance.setFieldValue([YandexStoryFormFieldsEnum.SameButtonOnEachSlide], false);
        },
        [activeSlideKey, formInstance]
    );

    const activeSlide = formInstance.getFieldValue([YandexStoryFormFieldsEnum.Slides, activeSlideKey]);

    const handleButtonConfigChange = useCallback(() => {
        const activeSlideButtonConfig = activeSlide.buttonConfig;

        if (!activeSlideButtonConfig) {
            formInstance.setFieldValue([YandexStoryFormFieldsEnum.SameButtonOnEachSlide], false);
            return;
        }

        const slides = formInstance.getFieldValue([YandexStoryFormFieldsEnum.Slides]);
        const sameButtonOnEachSlide = formInstance.getFieldValue([YandexStoryFormFieldsEnum.SameButtonOnEachSlide]);

        if (sameButtonOnEachSlide) {
            Object.keys(slides).forEach((key) => {
                formInstance.setFieldValue([YandexStoryFormFieldsEnum.Slides, key, 'buttonConfig'], {
                    ...activeSlideButtonConfig,
                });
            });
        }
    }, [activeSlide.buttonConfig, formInstance]);

    return (
        <div className={styles.YandexStoryFormSlidesContentStep_settingsWrapper}>
            <div className={styles.YandexStoryFormSlidesContentStep_addSlideToButtonWrapper}>
                <Text block stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__ADD_BUTTON_TO_SLIDE" />
                <Switch checked={Boolean(activeSlide.buttonConfig)} onChange={handleToggleButtonOnSlide} />
            </div>

            {activeSlide.buttonConfig && (
                <>
                    <div className={styles.YandexStoryFormSlidesContentStep_sameButtonOnEachSlideWrapper}>
                        <Form.Item<YandexStoryFormType>
                            name={[YandexStoryFormFieldsEnum.SameButtonOnEachSlide]}
                            noStyle
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Text block stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__SAME_BUTTON_ON_EACH_SIDE" />
                    </div>

                    <Form.Item<YandexStoryFormType>
                        label={<Locale stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__BUTTON_TEXT__LABEL" />}
                        name={[YandexStoryFormFieldsEnum.Slides, activeSlideKey, 'buttonConfig', 'text']}
                        rules={[requiredFieldRule, maxLengthFieldRule(YANDEX_STORY_MAX_BUTTON_TEXT_LENGTH)]}
                    >
                        <Input
                            maxLength={YANDEX_STORY_MAX_BUTTON_TEXT_LENGTH}
                            placeholder={getLocalizedString(
                                'YANDEX_STORY_FORM__SLIDES_CONTENT__BUTTON_TEXT__PLACEHOLDER'
                            )}
                            showCount
                        />
                    </Form.Item>

                    <Form.Item<YandexStoryFormType>
                        label={<Locale stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__BUTTON_LINK__LABEL" />}
                        name={[YandexStoryFormFieldsEnum.Slides, activeSlideKey, 'buttonConfig', 'link']}
                        rules={[
                            requiredFieldRule,
                            validUrlRule,
                            maxLengthFieldRule(YANDEX_STORY_MAX_BUTTON_LINK_LENGTH),
                        ]}
                    >
                        <Input placeholder="https://" />
                    </Form.Item>
                </>
            )}

            <div className={styles.YandexStoryFormSlidesContentStep_settingsSpacer}>
                {activeSlide.status === 'error' && (
                    <Alert
                        description={getLocalizedString(
                            'YANDEX_STORY_FORM__SLIDES_CONTENT__LOADING_ERROR__DESCRIPTION'
                        )}
                        message={getLocalizedString('YANDEX_STORY_FORM__SLIDES_CONTENT__LOADING_ERROR__MESSAGE')}
                        showIcon
                        type="error"
                    />
                )}
            </div>

            <Form.Item
                dependencies={[
                    [YandexStoryFormFieldsEnum.Slides, activeSlideKey, 'buttonConfig', 'text'],
                    [YandexStoryFormFieldsEnum.Slides, activeSlideKey, 'buttonConfig', 'link'],
                    [YandexStoryFormFieldsEnum.SameButtonOnEachSlide],
                ]}
                hidden
            >
                {() => {
                    handleButtonConfigChange();
                    return null;
                }}
            </Form.Item>

            <Button
                className={styles.YandexStoryFormSlidesContentStep_deleteSlideButton}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
                onClick={onDeleteSlide}
                type="text"
            >
                <Locale stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__REMOVE_SLIDE" />
            </Button>

            <div className={styles.YandexStoryFormSlidesContentStep_helpSpacer}>
                <Text block stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__SORT_SLIDES" />
                <FontAwesomeIcon
                    className={styles.YandexStoryFormSlidesContentStep_helpSpacerIcon}
                    icon={faRightLeft}
                />
            </div>
        </div>
    );
}
