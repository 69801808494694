import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchReviewsAnalysisSources} from './review-analysis-sources-api';
import {ReviewAnalysisSourceType} from './review-analysis-sources-type';

export function useReviewAnalysisSources(): UseHookType<Array<ReviewAnalysisSourceType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<ReviewAnalysisSourceType>>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewsAnalysisSources()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
