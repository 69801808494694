import dayjs from 'dayjs';
import {useMemo} from 'react';

import {DateRangePicker} from '../../../../component/date-range-picker/date-range-picker';
import {getLastAvailableAnalyticsDay} from '../../../../service/online-presence/online-presence-helper';
import {
    OnlinePresenceFilterActionsEnum,
    OnlinePresenceFilterDispatcherType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';
import {classNames} from '../../../../util/css';
import {DatePeriodEnum} from '../../../../util/date';

import * as styles from './online-presence-filter-v2.scss';

type PropsType = {
    filter: OnlinePresenceV2FilterStateType;
    dispatchFilter: OnlinePresenceFilterDispatcherType;
    isDisabled: boolean;
};

export function OnlinePresenceCompanyStatsFilter(props: PropsType): JSX.Element {
    const {filter, dispatchFilter, isDisabled} = props;

    const {
        [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: periodStart,
        [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: periodEnd,
    } = filter;

    const lastDayAnalyticsAvailableDayjs = useMemo(() => dayjs(getLastAvailableAnalyticsDay()), []);

    return (
        <div className={styles.OnlinePresencePeriodPicker}>
            <div className={classNames(styles.OnlinePresencePeriodPicker_rangePickerContainer)}>
                <DateRangePicker
                    allowClear={false}
                    className={styles.OnlinePresencePeriodPicker_rangePicker}
                    disabled={isDisabled}
                    disabledDate={(selectorDayjs) => lastDayAnalyticsAvailableDayjs.isSameOrBefore(selectorDayjs)}
                    format="DD.MM.YYYY"
                    onChange={(dateRangeValue) => {
                        dispatchFilter({
                            type: OnlinePresenceFilterActionsEnum.UpdateMainPeriod,
                            payload: {
                                [OnlinePresenceV2FilterQueriesEnum.PeriodStart]:
                                    dateRangeValue?.[0]?.toDate() || new Date(),
                                [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]:
                                    dateRangeValue?.[1]?.toDate() || new Date(),
                            },
                        });
                        dispatchFilter({
                            type: OnlinePresenceFilterActionsEnum.UpdatePeriod,
                            payload: DatePeriodEnum.Custom,
                        });
                    }}
                    picker="date"
                    value={[dayjs(periodStart), dayjs(periodEnd)]}
                />
            </div>
        </div>
    );
}
