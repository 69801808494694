import {faComment, faComments, faStar} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip} from 'antd';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Spinner} from '../../../../layout/spinner/spinner';
import {FormattedNumber} from '../../../../provider/locale/formatted-number';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {formatAddress} from '../../../../service/address/address-helper';
import {useCompanyStats} from '../../../../service/dashboard/dashboard-hook';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';

import * as styles from './company-stats.scss';

type PropsType = {
    companyId: number;
};

export function CompanyStats(props: PropsType): JSX.Element {
    const {companyId} = props;
    const {
        result: companyStatsResult,
        isInProgress: companyStatsIsInProgress,
        processError: companyStatsProcessError,
    } = useCompanyStats(companyId);

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    if (companyStatsProcessError) {
        return <AlertFallback />;
    }

    if (companyStatsIsInProgress || !companyStatsResult) {
        return <Spinner />;
    }

    return (
        <div className={styles.company_stats_container}>
            <div className={styles.company_stats__id_info}>
                <span className={styles.company_stats__id_info__name}>{companyStatsResult.name}</span>

                <Tooltip placement="bottom" title={getLocalizedString('TABLE__HEADER__CODE')}>
                    <span className={styles.company_stats__id_info__code}>{companyStatsResult.code}</span>
                </Tooltip>
            </div>
            <div className={styles.company_stats__dates_address_info}>
                <span className={styles.company_stats__dates_address_info__address}>
                    {formatAddress(companyStatsResult.address)}
                </span>
                <div className={styles.company_stats__dates_address_info__dates}>
                    <div className={styles.company_stats__dates_address_info__date_wrapper}>
                        <div
                            className={classNames(
                                styles.company_stats__dates_address_info__base_text,
                                styles.company_stats__dates_address_info__description
                            )}
                        >
                            {getLocalizedString('TABLE__HEADER__DATE_OF_CREATION')}
                        </div>
                        <div
                            className={classNames(
                                styles.company_stats__dates_address_info__base_text,
                                styles.company_stats__dates_address_info__date
                            )}
                        >
                            {getFormattedDateTime(new Date(companyStatsResult.created), {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })}
                        </div>
                    </div>
                    <div className={styles.company_stats__dates_address_info__date_wrapper}>
                        <div
                            className={classNames(
                                styles.company_stats__dates_address_info__base_text,
                                styles.company_stats__dates_address_info__description
                            )}
                        >
                            {getLocalizedString('TABLE__HEADER__ACTIVE_UNTIL')}
                        </div>
                        <div
                            className={classNames(
                                styles.company_stats__dates_address_info__base_text,
                                styles.company_stats__dates_address_info__date
                            )}
                        >
                            {getFormattedDateTime(new Date(companyStatsResult.expired), {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })}
                        </div>
                    </div>
                </div>
                {companyStatsResult.temporaryClosed && (
                    <div
                        className={classNames(
                            styles.company_stats__dates_address_info__base_text,
                            styles.company_stats__dates_address_info__temp_closed
                        )}
                    >
                        {getLocalizedString('TABLE__HEADER__TEMPORARILY_CLOSED')}
                    </div>
                )}
            </div>
            <div className={styles.company_stats__reviews_info}>
                <div className={styles.company_stats__reviews_info__data}>
                    {companyStatsResult.rating && (
                        <span>
                            <FontAwesomeIcon
                                className={styles.company_stats__reviews_info__data__rating_star_icon}
                                icon={faStar}
                            />
                            <FormattedNumber value={Number(companyStatsResult.rating)} />
                        </span>
                    )}
                    <span>
                        <FontAwesomeIcon
                            className={styles.company_stats__reviews_info__data__comments_icon}
                            icon={faComments}
                        />
                        <FormattedNumber value={companyStatsResult.reviewsCount} />
                    </span>
                </div>

                <Button
                    className={styles.company_stats__reviews_info__link}
                    icon={
                        <FontAwesomeIcon className={styles.company_stats__reviews_info__link__icon} icon={faComment} />
                    }
                    type="link"
                >
                    <NavigationLink to={appRoute.reviewsManagementReviews.path + `?company_ids=${companyId}`}>
                        <Locale stringKey="TABLE__HEADER__COMPANY_REVIEWS" />
                    </NavigationLink>
                </Button>
            </div>
        </div>
    );
}
