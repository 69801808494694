import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMemo} from 'react';

import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';
import {useFormat} from '../../util/format-hook/format-hook';
import {NavigationLink} from '../navigation-link/navigation-link';

import {getCclActionThumbUiData} from './ccl-action-thumb-helper';
import {CclActionThumbStateNameEnum} from './ccl-action-thumb-type';
import * as styles from './ccl-action-thumb.scss';

type PropsType = {
    value: number;
    type: CclActionThumbStateNameEnum;
    href?: string;
};

export function CclActionThumb(props: PropsType): JSX.Element {
    const {value, type, href} = props;
    const {className: cclActionThumbClassName, image, langKey} = getCclActionThumbUiData(type);
    const fullClassName = classNames(styles.CclActionThumb, cclActionThumbClassName);

    const {getFormattedNumber} = useFormat();

    const content = useMemo((): JSX.Element => {
        return (
            <>
                <div className={styles.CclActionThumb_leftColumn}>
                    <p className={styles.CclActionThumb_header}>
                        <Locale stringKey={langKey} />
                    </p>
                    <div className={styles.CclActionThumb_data}>
                        {image}
                        <span className={styles.CclActionThumb_value}>{getFormattedNumber(value)}</span>
                    </div>
                </div>
                <div className={styles.CclActionThumb_rightColumn}>
                    <FontAwesomeIcon className={styles.CclActionThumb_arrowImage} fixedWidth icon={faArrowRight} />
                </div>
            </>
        );
    }, [langKey, image, getFormattedNumber, value]);

    if (href) {
        return (
            <NavigationLink className={fullClassName} to={href}>
                {content}
            </NavigationLink>
        );
    }

    return (
        <article aria-label={type} className={fullClassName}>
            {content}
        </article>
    );
}
