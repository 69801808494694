import {z as r, ZodType} from 'zod';

import {ApiError, HttpError} from '../../../util/error';
import {cursorPaginationResponseSchemaFactory} from '../../api/api-type';

export enum SourceLinkStatusEnum {
    Synchronization = 'synchronization_status',
    Synced = 'synced_status',
    Fail = 'error_status',
}

const linkCommonPart = {
    id: r.number().int(),
    link: r.string().url(),
    status: r.nativeEnum(SourceLinkStatusEnum),
    brand: r.object({
        id: r.number().int(),
        name: r.string(),
    }),
};
const companySchema: ZodType = r.object({
    id: r.number().int(),
    name: r.string(),
    address: r.object({
        city: r.string().nullable(),
        country: r.string(),
        countryCode: r.string(),
        housenumber: r.string(),
        postcode: r.string(),
        region: r.string().nullable(),
        street: r.string(),
    }),
});

export const brandLinkSchema = r.object(linkCommonPart);
export const companyLinkSchema = r.object({
    ...linkCommonPart,
    company: companySchema,
});
export const brandLinkListResponseSchema = cursorPaginationResponseSchemaFactory(brandLinkSchema);
export const companyLinkListResponseSchema = cursorPaginationResponseSchemaFactory(companyLinkSchema);

export type BrandLinkType = r.infer<typeof brandLinkSchema>;
export type CompanyLinkType = r.infer<typeof companyLinkSchema>;

// SourceLink is "union" of BrandLink and CompanyLink
// BrandLink and CompanyLink both almost similar for the user and UI. The difference is on api (service layer).
// Thus, components know nothing about this difference and works with the union SourceLink
// Meanwhile, api service knows the difference and works with BrandLink and CompanyLink separately
const sourceLinkSchema = r.object({
    ...linkCommonPart,
    company: companySchema.optional(),
});

export type SourceLinkType = r.infer<typeof sourceLinkSchema>;

export enum SourceLinkTypeEnum {
    Brand = 'brand',
    Company = 'company',
}

export type SourceLinkListRequestType = {
    catalogId: number;
    cursor: string | null;
    pageSize: number;
    filterKey: string;
    status: Array<SourceLinkStatusEnum>;
};

export type BrandLinkListRequestType = SourceLinkListRequestType;
export type CompanyLinkListRequestType = SourceLinkListRequestType;

export class UnpaidCatalogGroupError extends HttpError {
    name = 'UnpaidCatalogGroupError';
    statusCode = 403;
}

export type CreateSourceLinkType = {
    link: string;
    catalogId: number;
    brandId: number;
    companyId: number | undefined;
};
export type CreateBrandLinkType = Omit<CreateSourceLinkType, 'companyId'>;
export type CreateCompanyLinkType = Required<Omit<CreateSourceLinkType, 'brandId'>>;

export type UpdateBrandLinkType = {
    link: string;
    deleteReviews: boolean;
};
export type UpdateCompanyLinkType = UpdateBrandLinkType;
export type UpdateSourceLinkType = UpdateBrandLinkType & {companyId: number | undefined};

export type ManageSourceLinkErrorPayloadType = {
    link?: Array<string>;
    brand_link?: Array<string>;
    company_link?: Array<string>;
    status?: string;
};

type ManageSourceLinkErrorDataType = {
    link?: Array<string>;
    status?: string;
};

export class ManageSourceLinkError extends ApiError {
    name = 'ManageSourceLinkError';
    jsonData: ManageSourceLinkErrorDataType;

    constructor(message: string, statusCode: number, jsonData: ManageSourceLinkErrorPayloadType) {
        super(message, statusCode, jsonData);
        const {link = [], brand_link = [], company_link = [], status = '', ...rest} = jsonData;

        // for some reason backend returns 3 different errors for one single link field
        this.jsonData = {...rest, link: [...link, ...brand_link, ...company_link], status};
    }
}

// for some reason NoPermit error comes in the different format
export type NoPermitSourceLinkErrorDataType = {
    link: string;
};
export class NoPermitSourceLinkError extends ApiError {
    name = 'NoPermitSourceLinkError';
    statusCode = 403;
    jsonData: NoPermitSourceLinkErrorDataType;

    constructor(message: string, statusCode: 403, jsonData: NoPermitSourceLinkErrorDataType) {
        super(message, statusCode, jsonData);

        this.jsonData = jsonData;
    }
}

export type DeleteBrandLinkType = {
    deleteReviews: boolean;
};
export type DeleteCompanyLinkType = DeleteBrandLinkType;
export type DeleteSourceLinkType = DeleteBrandLinkType & {companyId: number | undefined};
