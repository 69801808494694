import {TableColumnsType} from 'antd';

import {Text} from '../../../../../component/text/text';
import {FIXED_COLUMN_THRESHOLD} from '../../../../../const';
import {Locale} from '../../../../../provider/locale/localization';
import {
    LocalPackLeaderboardItemType,
    LocalPackLeaderboardPositionsEnum,
} from '../../../../../service/local-pack/leaderboard/local-pack-laderboard-type';
import {LocalPackNameAddressCell} from '../../table-cells/local-pack-name-address-cell/local-pack-name-address-cell';

import {LocalPackLeaderboardSearchKeysCell} from './search-phrases-cell/local-pack-leaderboard-search-keys-cell';

export const LOCAL_PACK_LEADER_BOARD_WIDTH = 1064;

export function getLocalPackLeaderboardColumns(screenWidth: number): TableColumnsType<LocalPackLeaderboardItemType> {
    return [
        {
            title: <Locale stringKey="LOCAL_PACK__LEADERBOARD__COLUMN__ADDRESS_NAME" />,
            width: 344,
            dataIndex: 'name',
            render: (_value: unknown, entry: LocalPackLeaderboardItemType): JSX.Element => (
                <LocalPackNameAddressCell address={entry.address} name={entry.name} />
            ),
            fixed: FIXED_COLUMN_THRESHOLD < screenWidth ? 'left' : false,
        },
        {
            title: <Locale stringKey="LOCAL_PACK__LEADERBOARD__COLUMN__KEYS" />,
            sorter: true,
            align: 'center',
            dataIndex: 'keys',
            width: 134,
            fixed: FIXED_COLUMN_THRESHOLD < screenWidth ? 'left' : false,
            render: (_value: unknown, entry: LocalPackLeaderboardItemType): JSX.Element => (
                <LocalPackLeaderboardSearchKeysCell entry={entry} />
            ),
        },
        {
            title: <Locale stringKey="LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_1_5" />,
            align: 'center',
            dataIndex: LocalPackLeaderboardPositionsEnum.Position1_5,
            sorter: true,
            width: 134,
            render: (_value: unknown, entry: LocalPackLeaderboardItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.distribution[LocalPackLeaderboardPositionsEnum.Position1_5]}
                </Text>
            ),
        },
        {
            align: 'center',
            title: <Locale stringKey="LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_6_9" />,
            dataIndex: LocalPackLeaderboardPositionsEnum.Position6_9,
            sorter: true,
            width: 134,
            render: (_value: unknown, entry: LocalPackLeaderboardItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.distribution[LocalPackLeaderboardPositionsEnum.Position6_9]}
                </Text>
            ),
        },
        {
            align: 'center',
            title: <Locale stringKey="LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_10_20" />,
            width: 134,
            dataIndex: LocalPackLeaderboardPositionsEnum.Position10_20,
            sorter: true,
            render: (_value: unknown, entry: LocalPackLeaderboardItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.distribution[LocalPackLeaderboardPositionsEnum.Position10_20]}
                </Text>
            ),
        },
        {
            align: 'center',
            title: <Locale stringKey="LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_OVER_21" />,
            dataIndex: LocalPackLeaderboardPositionsEnum.Position21AndMore,
            sorter: true,
            width: 134,
            render: (_value: unknown, entry: LocalPackLeaderboardItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.distribution[LocalPackLeaderboardPositionsEnum.Position21AndMore]}
                </Text>
            ),
        },
    ];
}
