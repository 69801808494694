import {Alert, Button, Input, Tabs} from 'antd';
import {isNumber, without} from 'lodash';
import {RcFile} from 'rc-upload/lib/interface';
import {DragEvent, useEffect, useState} from 'react';

import {Locale} from '../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {
    CompaniesValidationOptionsType,
    UseCompaniesImportValidationHookType,
} from '../../../../../../service/companies-import/companies-import';
import {useCompaniesImportErrorCodes} from '../../../../../../service/companies-import/companies-import-result-error-codes';
import {
    CompaniesImportParsingErrorCodeEnum,
    CompaniesImportTypeEnum,
} from '../../../../../../service/companies-import/companies-import-type';
import {Form} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';
import {FeedResultsTypeEnum} from '../results/feed-results-const';

import {BrandsFormItem} from './brands-form-item/brands-form-item';
import {FileExtensionEnum, initialValues, MimeTypeEnum} from './feed-form-const';
import {FeedFormKeyEnum, FeedFormTabEnum, FeedFormTranslationType, FeedFormType} from './feed-form-type';
import {FormatFormItem} from './format-form-item/format-form-item';
import {FeedProgress} from './progress/feed-progress';
import {FeedUpload} from './upload/feed-upload';
import * as styles from './feed-form.scss';

const onlyParsingErrors = without(
    Object.values(CompaniesImportParsingErrorCodeEnum).filter(isNumber),
    CompaniesImportParsingErrorCodeEnum.LoadingError
);

type PropsType = {
    type: FeedResultsTypeEnum;
    importValidation: UseCompaniesImportValidationHookType;
    hasBrands?: boolean;
    translations: Record<FeedFormTranslationType, LangKeyType>;
    onSubmit: (values: CompaniesValidationOptionsType) => void;
    onAbort: () => void;
};

// eslint-disable-next-line complexity
export function FeedForm(props: PropsType): JSX.Element {
    const {type, importValidation, hasBrands, translations, onSubmit, onAbort} = props;
    const {id, percent, isLoading, result, source, isDisabled, isCancelled, reset} = importValidation;
    const {submitButtonLabel, ...progressTranslations} = translations;

    const [dragActive, setDragActive] = useState<boolean>(false);
    const [sourceFileId, setSourceFileId] = useState<string | null>(null);
    const [sourceFileName, setSourceFileName] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>(FeedFormTabEnum.Link);

    const [form] = Form.useForm<FeedFormType>();
    const sourceUrl = Form.useWatch(FeedFormKeyEnum.SourceUrl, form);

    const {data: errors} = useCompaniesImportErrorCodes(id, percent);

    useEffect(() => {
        if (sourceUrl?.endsWith(FileExtensionEnum.Csv)) {
            form.setFieldValue(FeedFormKeyEnum.Format, CompaniesImportTypeEnum.RocketdataCsv);
        } else if (sourceUrl?.endsWith(FileExtensionEnum.Xml)) {
            form.setFieldValue(FeedFormKeyEnum.Format, CompaniesImportTypeEnum.YandexXml);
        } else if (sourceUrl?.endsWith(FileExtensionEnum.Xlsx)) {
            form.setFieldValue(FeedFormKeyEnum.Format, CompaniesImportTypeEnum.RocketdataXlsx);
        }
    }, [form, sourceUrl]);

    useEffect(() => {
        if (isCancelled) {
            form.resetFields();

            setSourceFileId(null);
            setSourceFileName(null);
        }
    }, [form, isCancelled]);

    function handleFinish(values: FeedFormType) {
        reset();

        const options = {
            ...(values.sourceUrl ? {link: values.sourceUrl} : {}),
            ...(sourceFileId ? {feedFileTempId: sourceFileId} : {}),
            ...(sourceFileName ? {feedFileName: sourceFileName} : {}),
            importType: values.format,
        };

        if (hasBrands && values.allowedBrands?.[0]?.brandId) {
            onSubmit({
                ...options,
                ...(values.allowedBrands.length > 1
                    ? {allowedBrands: values.allowedBrands}
                    : {brandId: values.allowedBrands[0]?.brandId}),
            });
        } else {
            onSubmit(options);
        }
    }

    function handleUploadChange(fileId: string | null, file?: RcFile | null) {
        setSourceFileId(fileId);

        if (file) {
            setSourceFileName(file.name);
            form.setFieldValue(FeedFormKeyEnum.SourceUrl, '');

            switch (file.type) {
                case MimeTypeEnum.TextCsv: {
                    form.setFieldValue(FeedFormKeyEnum.Format, CompaniesImportTypeEnum.RocketdataCsv);

                    break;
                }
                case MimeTypeEnum.TextXml: {
                    form.setFieldValue(FeedFormKeyEnum.Format, CompaniesImportTypeEnum.YandexXml);

                    break;
                }
                case MimeTypeEnum.ApplicationXlsx: {
                    form.setFieldValue(FeedFormKeyEnum.Format, CompaniesImportTypeEnum.RocketdataXlsx);

                    break;
                }
                // No default
            }
        }

        setDragActive(false);
    }

    function handleDragEnter(event: DragEvent<HTMLDivElement>) {
        if (sourceFileId) {
            return;
        }

        if (event.relatedTarget instanceof Node && event.currentTarget.contains(event.relatedTarget)) {
            return;
        }

        setDragActive(true);
        setActiveTab(FeedFormTabEnum.File);
    }

    function handleDragLeave(event: DragEvent<HTMLDivElement>) {
        if (event.relatedTarget instanceof Node && event.currentTarget.contains(event.relatedTarget)) {
            return;
        }

        setDragActive(false);
    }

    return (
        <>
            <Form<FeedFormType>
                disabled={isDisabled || (Boolean(source) && percent !== 100)}
                form={form}
                initialValues={initialValues}
                layout="vertical"
                onFinish={handleFinish}
            >
                <Tabs
                    activeKey={activeTab}
                    className={classNames(styles.FeedForm_tabs, {
                        [styles.FeedForm_tabs__touched]: Boolean(sourceUrl || sourceFileId),
                        [styles.FeedForm_tabs__dragActive]: dragActive,
                    })}
                    items={[
                        {
                            key: FeedFormTabEnum.Link,
                            label: <Locale stringKey="FEED__FORM__FROM_LINK" />,
                            className: styles.FeedForm_tab,
                            disabled: Boolean(sourceFileId),
                            children: (
                                <Form.Item<FeedFormType>
                                    label={<Locale stringKey="FEED__FORM__LINK__LABEL" />}
                                    name={FeedFormKeyEnum.SourceUrl}
                                >
                                    <Input placeholder="https://..." size="large" />
                                </Form.Item>
                            ),
                        },
                        {
                            key: FeedFormTabEnum.File,
                            label: <Locale stringKey="FEED__FORM__FROM_FILE" />,
                            disabled: sourceUrl?.length > 0,
                            children: (
                                <FeedUpload
                                    handleUploadChange={handleUploadChange}
                                    isCancelled={isCancelled}
                                    sourceFileId={sourceFileId}
                                />
                            ),
                        },
                    ]}
                    onChange={setActiveTab}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    size="large"
                />

                <FormatFormItem />

                {hasBrands && <BrandsFormItem className={styles.FeedForm_brandsFormItem} type={type} />}

                <Button
                    block
                    disabled={isDisabled || !(sourceUrl || sourceFileId) || (Boolean(source) && percent !== 100)}
                    htmlType="submit"
                    loading={isLoading}
                    size="large"
                    type="primary"
                >
                    <Locale stringKey={submitButtonLabel} />
                </Button>
            </Form>

            {source && (
                <FeedProgress
                    isResultAvailable={result !== null}
                    onAbort={onAbort}
                    percent={percent}
                    translations={progressTranslations}
                />
            )}

            {errors?.results.some(({code}) => code === CompaniesImportParsingErrorCodeEnum.LoadingError) && (
                <Alert
                    className={styles.FeedForm_alert}
                    description={<Locale stringKey="FEED__FORM__LOADING_ERROR__DESCRIPTION" />}
                    message={<Locale stringKey="FEED__FORM__LOADING_ERROR__TITLE" />}
                    showIcon
                    type="error"
                />
            )}

            {(errors?.results.some(({code}) => onlyParsingErrors.includes(code)) ||
                (result && result.internalError > 0)) && (
                <Alert
                    className={styles.FeedForm_alert}
                    description={<Locale stringKey="FEED__FORM__PARSING_ERROR__DESCRIPTION" />}
                    message={
                        <Locale
                            stringKey="FEED__FORM__PARSING_ERROR__TITLE"
                            valueMap={{
                                source: (
                                    <Locale
                                        stringKey={
                                            sourceFileId
                                                ? 'FEED__FORM__PARSING_ERROR__TITLE__FILE'
                                                : 'FEED__FORM__PARSING_ERROR__TITLE__FEED'
                                        }
                                    />
                                ),
                            }}
                        />
                    }
                    showIcon
                    type="error"
                />
            )}
        </>
    );
}
