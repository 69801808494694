import {ReactElement} from 'react';

import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';

import * as styles from './info-for-image.scss';

type PropsType = {
    label: LangKeyType;
    title: LangKeyType;
    minResolution: LangKeyType;
    maxResolution: LangKeyType;
    maxSize: LangKeyType;
    minSize: LangKeyType;
    fileFormats: LangKeyType;
    additionalNode?: ReactElement | null;
};

export function InfoForImage(props: PropsType): ReactElement {
    const {label, title, additionalNode, maxSize, maxResolution, minResolution, fileFormats, minSize} = props;

    return (
        <AdditionalInfo
            className={styles.AdditionalInfo}
            leftNode={<Locale stringKey={label} />}
            title={<Locale stringKey={title} />}
        >
            <div>
                <span className={styles.AdditionalInfo_title}>
                    <Locale stringKey="COMPANY_FORM__GALLERY__HINT__MIN_RESOLUTION__TITLE" />
                </span>

                <Locale stringKey={minResolution} />
            </div>

            <div>
                <span className={styles.AdditionalInfo_title}>
                    <Locale stringKey="COMPANY_FORM__GALLERY__HINT__MAX_RESOLUTION__TITLE" />
                </span>

                <Locale stringKey={maxResolution} />
            </div>

            <div>
                <span className={styles.AdditionalInfo_title}>
                    <Locale stringKey="COMPANY_FORM__GALLERY__HINT__MIN_SIZE__TITLE" />
                </span>

                <Locale stringKey={minSize} />
            </div>

            <div>
                <span className={styles.AdditionalInfo_title}>
                    <Locale stringKey="COMPANY_FORM__GALLERY__HINT__MAX_SIZE__TITLE" />
                </span>

                <Locale stringKey={maxSize} />
            </div>

            <div>
                <span className={styles.AdditionalInfo_title}>
                    <Locale stringKey="COMPANY_FORM__GALLERY__HINT__FILE_FORMATS__TITLE" />
                </span>

                <Locale stringKey={fileFormats} />
            </div>
            {additionalNode && <div>{additionalNode}</div>}
        </AdditionalInfo>
    );
}
