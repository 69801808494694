export const enYandexStoriesTranslations = {
    YANDEX_STORY__STATUS: 'Status',
    YANDEX_STORY__STATUS__PUBLISHED: 'Published',
    YANDEX_STORY__STATUS__MODERATION: 'In moderation',
    YANDEX_STORY__STATUS__REJECTED: 'Rejected',
    YANDEX_STORY__STATUS__DRAFT: 'Draft',
    YANDEX_STORY__STATUS__DELETED: 'Deleted',

    YANDEX_STORY__COMPANIES: 'Companies',

    YANDEX_STORY__ALERT__REJECTED: 'The story did not pass moderation in the catalog.',
    YANDEX_STORY__ALERT__MODERATION: 'Moderation in the catalog takes from two to four hours.',

    YANDEX_STORY__SELECTED_COMPANIES__TITLE: 'View companies',
    YANDEX_STORY__SELECTED_COMPANIES__COMPANY: 'Company',
    YANDEX_STORY__SELECTED_COMPANIES__STATUS: 'Status',
    YANDEX_STORY__SELECTED_COMPANIES__ACTIONS: 'Actions',

    YANDEX_STORY__SELECTED_COMPANIES__COPY_ACTION__POPOVER: 'Copy link',
    YANDEX_STORY__SELECTED_COMPANIES__COPY_ACTION__COPIED: 'Link copied',
    YANDEX_STORY__SELECTED_COMPANIES__FOLLOW_LINK_ACTION__POPOVER: 'Go to card',
    YANDEX_STORY__SELECTED_COMPANIES__CONFIRM_COMPANIES__TITLE:
        'Available companies for publication: {availableCount}/{totalCount}',
    YANDEX_STORY__SELECTED_COMPANIES__CONFIRM_COMPANIES__CONTENT:
        'Story publication is not available for {count} companies. Are you sure you want to continue?',

    YANDEX_STORY__SOURCES__TOOLTIP__ON_MODERATION: 'Stories in the card for moderation',
    YANDEX_STORY__SOURCES__TOOLTIP__NEW: 'Add a story to a card',
    YANDEX_STORY__SOURCES__TOOLTIP__FORBIDDEN: 'To publish stories, link your company card',
    YANDEX_STORY__SOURCES__TOOLTIP__REJECTED: 'There are rejected stories',
    YANDEX_STORY__TOOLTIP__REJECTED: 'Story rejected',
    YANDEX_STORY__TOOLTIP__ON_MODERATION: 'History on moderation',
    YANDEX_STORY__TOOLTIP__DELETED: 'History deleted',
    YANDEX_STORY__TOOLTIP__PUBLISHED: 'Story published',

    YANDEX_STORY__SOURCES__MODAL__TITLE: 'Stories in a card',
    YANDEX_STORY__SOURCES__MODAL__SAVE_BUTTON: 'Keep order',

    YANDEX_STORY__SNACKBAR__DELETE_STORY__SUCCESS__MESSAGE: 'You deleted your history',
    YANDEX_STORY__SNACKBAR__DELETE_STORY__SUCCESS__DESCRIPTION:
        'The story “{storyName}” will be removed from the card on Yandex Maps in the near future',
    YANDEX_STORY__SNACKBAR__DELETE_STORY__ERROR__MESSAGE: 'Failed to delete history',

    YANDEX_STORY__CONFIRM__DELETE_STORY__DESCRIPTION:
        'The history will be permanently deleted from the company card on Yandex Maps.',
    YANDEX_STORY__CONFIRM__DELETE_STORY__TITLE: 'Delete history?',

    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_1: 'Upload up to 10 images',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_2: 'File format: JPG or PNG',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_3:
        'Image resolution: from {minWidth} × {minHeight} to {maxWidth} × {maxHeight} px',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_4: 'Recommended aspect ratio: 9:16',

    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_1__SHORT: 'Max. 10 images',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_2__SHORT: 'JPG or PNG',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_3__SHORT: 'From 100 × 68 to 5000 × 5000 px',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__RULES_4__SHORT: 'Aspect ratio 9:16',

    YANDEX_STORY_FORM__UPLOAD_IMAGES__UPLOADER_TEXT: 'Click or drag files to this area to upload',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__UPLOADER_MAX_IMAGES: 'You can upload up to 10 images simultaneously',

    YANDEX_STORY_FORM__UPLOAD_IMAGES__LOADING_IMAGES_SINGLE: 'Uploading image...',
    YANDEX_STORY_FORM__UPLOAD_IMAGES__LOADING_IMAGES_MANY: 'Uploading {count} images...',

    YANDEX_STORY_FORM__SLIDES_CONTENT__NO_BUTTON_ON_HOW_TO_FIND: 'Buttons are not supported for "How to find" stories',
    YANDEX_STORY_FORM__SLIDES_CONTENT__ADD_BUTTON_TO_SLIDE: 'Add button to slide',
    YANDEX_STORY_FORM__SLIDES_CONTENT__SAME_BUTTON_ON_EACH_SIDE: 'Use the same button for all slides',
    YANDEX_STORY_FORM__SLIDES_CONTENT__BUTTON_TEXT__LABEL: 'Button text',
    YANDEX_STORY_FORM__SLIDES_CONTENT__BUTTON_TEXT__PLACEHOLDER: 'Button',
    YANDEX_STORY_FORM__SLIDES_CONTENT__BUTTON_LINK__LABEL: 'Link',
    YANDEX_STORY_FORM__SLIDES_CONTENT__LOADING_ERROR__DESCRIPTION:
        'An error occurred while loading the image. Reload the image or choose another one.',
    YANDEX_STORY_FORM__SLIDES_CONTENT__LOADING_ERROR__MESSAGE: 'Error loading image',
    YANDEX_STORY_FORM__SLIDES_CONTENT__REMOVE_SLIDE: 'Remove slide',
    YANDEX_STORY_FORM__SLIDES_CONTENT__SORT_SLIDES: 'Drag images in the row below to change the order of slides',

    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER_ERROR__TITLE: 'Upload error',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER_ERROR__CONTENT: 'An error occurred while uploading the cover',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__STORY_NAME__LABEL: 'Story name',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__STORY_NAME__PLACEHOLDER: 'Story name',

    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER: 'Cover',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_TITLE: 'Select or upload a cover',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_RULE_1: 'JPG or PNG',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_RULE_2: 'From 100 × 68 to 5000 × 5000 px',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_RULE_3: 'Aspect ratio 3:4',

    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__SELECTED_FROM_SLIDES: 'Selected from slide',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__UPLOAD_ANOTHER: 'Upload another',
    YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__SELECT_FROM_SLIDE: 'Select cover',

    YANDEX_STORY_FORM__CONFIRMATION_STEP__SELECTED_COMPANIES__MODERATION_HELPER:
        'The story will be published after moderation in the catalog (2-4 hours)',

    YANDEX_STORY__CREATE__TITLE: 'Add story to company cards',
    YANDEX_STORY__EDIT__TITLE: 'Edit story in company cards',
    YANDEX_STORY__CONFIRM_CLOSE__CONTENT: 'Changes will be lost permanently',

    YANDEX_STORY__CREATE__CONFIRM_CLOSE__TITLE: 'Discard story creation?',
    YANDEX_STORY__CREATE__CONFIRM_CLOSE__CONFIRM: 'Discard',

    YANDEX_STORY__EDIT__CONFIRM_CLOSE__TITLE: 'Discard changes?',
    YANDEX_STORY__EDIT__CONFIRM_CLOSE__CONFIRM: 'Discard',

    YANDEX_STORY__CREATE_SUCCESS: 'Story published',
    YANDEX_STORY__CREATE_SUCCESS__DESCRIPTION: 'The story will appear in cards on Yandex Maps after moderation',
    YANDEX_STORY__CREATE_ERROR: 'Error creating story',
    YANDEX_STORY__UPDATE_SUCCESS: 'Story successfully updated',
    YANDEX_STORY__UPDATE_ERROR: 'Error updating story',

    YANDEX_STORY__HOW_TO_FIND__NAME: 'How to Find',
    YANDEX_STORY__HOW_TO_FIND__NAME__TOOLTIP: 'The name of this story cannot be changed',
};
