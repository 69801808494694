import {ReviewsAnalysisFilterEnum} from '../../reviews-analysis-type';

export function isPrimaryFilterStateEmpty(queries: Record<ReviewsAnalysisFilterEnum, string | void>): boolean {
    const {
        [ReviewsAnalysisFilterEnum.CompareRange]: compareRangeQuery,
        [ReviewsAnalysisFilterEnum.Competitor]: competitorQuery,
        ...primaryQueries
    } = queries;

    return Object.entries(primaryQueries)
        .filter(([key]: [string, string | void]): boolean => {
            return Object.values<string>(ReviewsAnalysisFilterEnum).includes(key);
        })
        .every(([, query]: [string, string | void]): boolean => !query);
}
