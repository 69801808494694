import {PropsWithChildren} from 'react';

import {AntLocaleProvider} from './ant-locale-provider';
import {LocalizationProvider} from './localization';

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function LocaleProvider(props: PropsType): JSX.Element {
    const {children} = props;

    return (
        <LocalizationProvider>
            <AntLocaleProvider>{children}</AntLocaleProvider>
        </LocalizationProvider>
    );
}
