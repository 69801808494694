import {QueryObserverBaseResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {TipType} from '../../../page/main/account-sync/google/tips/tips-type';
import {PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {generateResponseSchema} from '../../api/api-type';
import {GoogleCclStatusEnum, googleCompanySchema, googleLocationSchema} from '../google-type';

export enum GoogleConfirmationRequestMethodEnum {
    Mass = 'mass',
}

export enum GoogleConfirmationRequestStatusEnum {
    Formed = 'formed',
    InProcess = 'in_process',
    Pending = 'pending',
    Completed = 'completed',
}

enum GoogleVerificationMethodBackendEnum {
    PhoneCall = 'phone_call',
    SMS = 'sms',
    Email = 'email',
    Address = 'address',

    FakeTransformedByZodPhoneCall = 'PHONE_CALL',
    FakeTransformedByZodSMS = 'SMS',
    FakeTransformedByZodEmail = 'EMAIL',
    FakeTransformedByZodAddress = 'ADDRESS',
}

export enum GoogleVerificationMethodEnum {
    PhoneCall = 'phone_call',
    SMS = 'sms',
    Email = 'email',
    Address = 'address',
}

const googleConfirmationSchema = r.object({
    pk: r.number(),
    createdAt: r.string(),
    method: r
        .nativeEnum(GoogleVerificationMethodBackendEnum)
        .transform((value) => value.toLowerCase() as GoogleVerificationMethodEnum),
    detail: r.string(),
});

const googleConfirmationCompanyLocationSchema = googleLocationSchema
    .pick({
        name: true,
        address: true,
        website: true,
        phones: true,
    })
    .extend({
        locationId: r.string(),
        linkToMap: r.string().nullable(),
    });

export type GoogleConfirmationCompanyLocationType = r.infer<typeof googleConfirmationCompanyLocationSchema>;

const googleConfirmationCompanySchema = r.object({
    company: googleCompanySchema
        .pick({
            name: true,
            code: true,
            address: true,
        })
        .extend({
            pk: r.number(),
        }),
    confirmation: googleConfirmationSchema.nullable(),
    location: r
        .union([
            googleConfirmationCompanyLocationSchema,
            r.object({
                name: r.null(),
                locationId: r.null(),
                address: r.null(),
                website: r.null(),
                phones: r.array(r.string()),
                linkToMap: r.null(),
            }),
        ])
        .nullable(),
    status: r.nativeEnum(GoogleCclStatusEnum),
    description: r.string(),
    adminRightsUrl: r.string().nullable(),
});

export type GoogleConfirmationCompanyType = r.infer<typeof googleConfirmationCompanySchema>;

export const googleConfirmationCompaniesSchema = generateResponseSchema(googleConfirmationCompanySchema);

export type GoogleConfirmationCompaniesType = r.infer<typeof googleConfirmationCompaniesSchema>;

export type GoogleConfirmationCompaniesHookType = QueryObserverBaseResult<GoogleConfirmationCompaniesType> &
    PaginationType & {tips: Array<TipType>};

const googleConfirmationRequestSchema = r.object({
    pk: r.number(),
    brandName: r.string(),
    method: r.nativeEnum(GoogleConfirmationRequestMethodEnum),
    status: r.nativeEnum(GoogleConfirmationRequestStatusEnum),
    createdAt: r.string(),
    dateOfChangeStatus: r.string().optional(),
    companiesCount: r.number(),
});

export type GoogleConfirmationRequestType = r.infer<typeof googleConfirmationRequestSchema>;

export const googleConfirmationRequestsSchema = generateResponseSchema(googleConfirmationRequestSchema);

export type GoogleConfirmationRequestsType = r.infer<typeof googleConfirmationRequestsSchema>;

export type GoogleConfirmationRequestsHookType = QueryObserverBaseResult<GoogleConfirmationRequestsType> &
    PaginationType & {tips: Array<TipType>};

const googleRequestCompanySchema = r.object({
    pk: r.number(),
    name: r.string(),
    code: r.string().optional(),
    formattedAddress: r.string(),
    status: r.nativeEnum(GoogleCclStatusEnum).optional(),
});

export const googleRequestCompaniesSchema = generateResponseSchema(googleRequestCompanySchema);

export type GoogleRequestCompaniesType = r.infer<typeof googleRequestCompaniesSchema>;

export type GoogleRequestCompaniesHookType = QueryObserverBaseResult<GoogleRequestCompaniesType> & PaginationType;

const googleVerificationOptionSchema = r.object({
    verificationMethod: r.nativeEnum(GoogleVerificationMethodEnum),
    detail: r.string().optional(),
    isUserNameEditable: r.boolean().optional(),
});

export const googleVerificationOptionsSchema = r.array(googleVerificationOptionSchema);

export type GoogleVerificationOptionType = r.infer<typeof googleVerificationOptionSchema>;

export type GoogleVerificationOptionBodyType = {
    method: GoogleVerificationMethodEnum;
    detail?: string;
};

export const googleVerificationOptionResponseSchema = googleConfirmationSchema.extend({
    verificationId: r.string(),
});

export type GoogleVerificationOptionResponseType = r.infer<typeof googleVerificationOptionResponseSchema>;

export type GoogleVerificationCompleteBodyType = {
    pin: string;
    verificationId: string;
};
