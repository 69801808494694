import {intersection, isNumber} from 'lodash';

import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {ShortLocaleNameEnum} from '../../../../provider/locale/locale-context-type';
import {useSystem} from '../../../../provider/system/system-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {useTariffs} from '../../../../service/tariffs/tariffs-user-tariffs';
import {FeaturesEnum} from '../../../../service/user/user-type';

export enum HeaderButtonEnum {
    HelpLinks,
    Language,
    Notifications,
    Profile,
    SuggestIdea,
    Tariffs,
}

// eslint-disable-next-line complexity
export function useVisibleHeaderButtons(buttonsOverride?: Array<HeaderButtonEnum> | null): Array<number> {
    const {screen} = useSystem();
    const {
        isDynamicHelpLinksVisible,
        isLanguageSelectorVisible,
        isNotificationShow,
        isUserProfileShow,
        isIdeaButtonShow,
    } = useDomainConfig();
    const {user} = useUser();
    const {data: tariffs} = useTariffs();
    const {availableFeatures} = useLicenses();
    const availableLangForIdeaButton = [ShortLocaleNameEnum.ru, ShortLocaleNameEnum.kk];

    const isVisibleIdeaButton = isIdeaButtonShow && user && availableLangForIdeaButton.includes(user.language);

    const isHidden = screen.isMobile && !buttonsOverride;

    const enabledButtons = [
        !isHidden && isDynamicHelpLinksVisible && HeaderButtonEnum.HelpLinks,
        !isHidden && isLanguageSelectorVisible && HeaderButtonEnum.Language,
        isNotificationShow && HeaderButtonEnum.Notifications,
        !isHidden && isUserProfileShow && HeaderButtonEnum.Profile,
        !isHidden &&
            user &&
            availableFeatures[FeaturesEnum.tariffs] &&
            tariffs &&
            tariffs.results.length > 0 &&
            HeaderButtonEnum.Tariffs,
        !isHidden && isVisibleIdeaButton && HeaderButtonEnum.SuggestIdea,
    ].filter(isNumber); // not Boolean because first enum item is 0

    return buttonsOverride ? intersection(enabledButtons, buttonsOverride) : enabledButtons;
}
