import {Button, Tabs} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../service/feature-companies/feature-companies-type';
import {
    PostAiSuggestHookType,
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    SourcePostFormFieldsEnum,
} from '../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';
import {useRefreshId} from '../../../../../../util/hook';
import * as postFormStyles from '../../post-form.scss';

import {CatalogTab} from './catalog-tab/data-tab';
import {clearHiddenCatalogFields, POST_SOURCE_TO_PROVIDER_ID_MAP} from './content-step-helper';
import {PostCatalogData} from './post-catalog-data';
import * as styles from './post-content-step.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    suggestSettings: PostAiSuggestHookType;
    onStepFinished: () => void;
    formMode: PostFormModeEnum;
    companiesSelector?: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
};

export function PostContentStep(props: PropsType): JSX.Element {
    const {formInstance, suggestSettings, onStepFinished, formMode, companiesSelector} = props;

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    const [activeKey, setActiveKey] = useState<string>('0');

    const {refreshId, refresh: refreshTabsValidation} = useRefreshId();

    const isSourcePostHasErrors = useCallback(
        (postSourceIndex: number) => {
            return Object.values(SourcePostFormFieldsEnum).some(
                (sourcePostField) =>
                    formInstance.getFieldError([PostFormFieldsEnum.Posts, postSourceIndex, sourcePostField]).length > 0
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formInstance, refreshId]
    );

    const tabs = useMemo(() => {
        return formInstance
            .getFieldValue(PostFormFieldsEnum.Posts)
            .map((post) => post[SourcePostFormFieldsEnum.Catalog])
            .map((source, index) => {
                const catalogName = getCatalogName(POST_SOURCE_TO_PROVIDER_ID_MAP[source]);
                const catalogLogo = getCatalogLogo(POST_SOURCE_TO_PROVIDER_ID_MAP[source]);

                return {
                    forceRender: true,
                    key: String(index),
                    label: (
                        <CatalogTab alt={catalogName} hasErrors={isSourcePostHasErrors(index)} imageSrc={catalogLogo} />
                    ),
                    children: (
                        <PostCatalogData
                            companiesSelector={companiesSelector}
                            formElementIndex={index}
                            formInstance={formInstance}
                            formMode={formMode}
                            source={source}
                            suggestSettings={suggestSettings}
                        />
                    ),
                };
            });
    }, [
        formInstance,
        getCatalogLogo,
        getCatalogName,
        isSourcePostHasErrors,
        companiesSelector,
        formMode,
        suggestSettings,
    ]);

    const navigateToFirstTabWithError = useCallback(() => {
        const fieldsErrors = formInstance.getFieldsError();

        fieldsErrors.find((error) => {
            if (
                error.errors.length > 0 &&
                Array.isArray(error.name) &&
                error.name[0] === PostFormFieldsEnum.Posts &&
                error.name.length > 1
            ) {
                setActiveKey(String(error.name[1]));
                return true;
            }

            return false;
        });
    }, [formInstance]);

    const handleStepFinish = useCallback(async () => {
        try {
            await formInstance.validateFields();
            clearHiddenCatalogFields(formInstance);
            onStepFinished();
        } catch {
            refreshTabsValidation();
            navigateToFirstTabWithError();
        }
    }, [formInstance, navigateToFirstTabWithError, onStepFinished, refreshTabsValidation]);

    return (
        <div className={postFormStyles.PostForm_step}>
            <div className={classNames(postFormStyles.PostForm_stepContent, styles.PostContentWrapper)}>
                <Tabs
                    activeKey={activeKey}
                    className={styles.PostContentWrapper_tabs}
                    items={tabs}
                    onChange={setActiveKey}
                    size="large"
                />
            </div>

            <div>
                <Button onClick={handleStepFinish} type="primary">
                    <Locale stringKey="TEXT__NEXT" />
                </Button>
            </div>
        </div>
    );
}
