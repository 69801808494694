import {Collapse} from 'antd';
import {MouseEvent, useRef, useState} from 'react';

import {useLocale} from '../../../../provider/locale/locale-hook';

import {DashboardCompaniesContent} from './components/dashboard-companies-content/dashboard-companies-content';
import {MapPopup} from './components/map-popup/map-popup';
import * as styles from './dashboard-companies-map.scss';

export function DashboardCompaniesMap(): JSX.Element {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<number | null>(null);
    const popupRef = useRef<HTMLDivElement | null>(null);
    const {getLocalizedString} = useLocale();

    function handleClose(): void {
        setShowPopup(false);
        setCompanyId(null);
    }

    function handleClickOutside(event: MouseEvent): void {
        if (popupRef.current && event.target instanceof Node && !popupRef.current.contains(event.target)) {
            handleClose();
        }
    }

    return (
        <Collapse
            items={[
                {
                    label: getLocalizedString('DASHBOARD_PAGE__MAP__LABEL'),
                    key: 'dashboard-companies-map',
                    children: (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <div className={styles.DashboardCompaniesMap} onClick={handleClickOutside}>
                            <DashboardCompaniesContent setCompanyId={setCompanyId} setShowPopup={setShowPopup} />
                            <MapPopup
                                companyId={companyId}
                                onClose={handleClose}
                                open={showPopup}
                                popupRef={popupRef}
                            />
                        </div>
                    ),
                },
            ]}
        />
    );
}
