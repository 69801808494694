import {faFile, faMessageBot, faRepeat} from '@fortawesome/pro-regular-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Space, Spin, Tooltip} from 'antd';
import {TextAreaRef} from 'antd/lib/input/TextArea';
import {camelizeKeys} from 'humps';
import {Dispatch, ReactElement, RefObject, SetStateAction, SyntheticEvent, useCallback, useState} from 'react';

import {gray1} from '../../../../../../../../css/var-export.scss';
import {useLicenses} from '../../../../../../../../provider/license/license-hook';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {useSystem} from '../../../../../../../../provider/system/system-hook';
import {useUser} from '../../../../../../../../provider/user/user-hook';
import {AnalyticsTarget, track} from '../../../../../../../../service/analytics/analytics';
import {fetchReviewsReply} from '../../../../../../../../service/reviews/reviews-reply';
import {ReviewDataBrandFromListType, ReviewDataChildType} from '../../../../../../../../service/reviews/reviews-type';
import {FeaturesEnum} from '../../../../../../../../service/user/user-type';
import {classNames} from '../../../../../../../../util/css';
import {getFromLocalStorage, saveToLocalStorage} from '../../../../../../../../util/local-storage';
import {MAX_ROWS_TEXTAREA, MIN_ROWS_TEXTAREA} from '../../../../../reviews-const';
import {useReviewStateHook} from '../../../../../reviews-state/reviews-state-provider';
import {handleEnter, handleReviewReplyExceptions, isOnlyEmojis} from '../../review-helper';
import {useReviewAiSuggest} from '../neuralink-hook';
import * as styles from '../reply-form.scss';
import {ReplyTypeEnum} from '../reply-form-const';
import {GetNeuralLinkButtonWrapper} from '../trial-popover/trial-popover';

import {ReplySourceTypeEnum, TEST_AND_DEMO_USER_COUNT_AI_STORAGE_KEY} from './ai-review-generator-button-const';
import {localStorageAiButtonShowSchema, LocalStorageAiButtonShowType} from './ai-review-generator-button-type';

type PropsType = {
    reviewId: number;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    replyAreaRef: RefObject<TextAreaRef>;
    brand: ReviewDataBrandFromListType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    setIsSelectTemplatePopupOpen: Dispatch<SetStateAction<boolean>>;
    answerType: string;
    setAnswerType: Dispatch<SetStateAction<string>>;
    shouldCollapseOnClose?: boolean;
    isShowGenerateButton: boolean;
    answerText: string;
    setAnswerText: Dispatch<SetStateAction<string>>;
    comment: string | null;
};

// eslint-disable-next-line complexity,max-statements
export function AiReviewGeneratorButton(props: PropsType): ReactElement {
    const {
        reviewId,
        shouldCollapseOnClose,
        refreshReview,
        replyAreaRef,
        brand,
        setIsSelectTemplatePopupOpen,
        setIsOpen,
        setAnswerType,
        answerType,
        isShowGenerateButton,
        setAnswerText,
        answerText,
        comment,
    } = props;

    const systemContext = useSystem();
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {licenses, refetch} = useLicenses();
    const {user} = useUser();

    const {refreshReviewWithAnswer, refreshSuggestions} = useReviewStateHook();

    const reviewGptReplyLicense = licenses[FeaturesEnum.reviewGptReplySuggestions];
    const [isReviewReplyInProgress, setIsReviewReplyInProgress] = useState<boolean>(false);
    const [wasGeneration, setWasGeneration] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isButtonDisabledFromError, setIsButtonDisabledFromError] = useState<boolean>(false);

    const {requestSuggest, isGeneratingSuggest} = useReviewAiSuggest(setAnswerText);

    const localStorageShowButton = getFromLocalStorage<LocalStorageAiButtonShowType>(
        TEST_AND_DEMO_USER_COUNT_AI_STORAGE_KEY,
        localStorageAiButtonShowSchema,
        {}
    );

    const isButtonDisabled = answerText?.length === 0 || brand.isTest || isButtonDisabledFromError;

    const isAiButtonDisabled =
        (user?.isDemoUser || user?.isTestUser) &&
        ((localStorageShowButton?.[reviewId]?.count ?? 0) > 1 || Object.keys(localStorageShowButton).length > 4);

    const handleSendReply = useCallback(async () => {
        if (isButtonDisabled || isReviewReplyInProgress) {
            return;
        }

        try {
            setIsReviewReplyInProgress(true);
            const reviewReply = await fetchReviewsReply(reviewId, {
                comment: answerText.trim(),
                ...(wasGeneration ? {replySourceType: ReplySourceTypeEnum.Neuronetwork} : {}),
            });

            await refreshReviewWithAnswer(camelizeKeys(reviewReply) as ReviewDataChildType, reviewId);
            await refreshSuggestions();

            snackbar.success({
                message: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__TITLE" />,
                description: <Locale stringKey="REVIEWS__REVIEW_ANSWER_SEND_SUCCESS__DESCRIPTION" />,
            });

            track(AnalyticsTarget.ReviewsManagement.Reviews.ReplyToReview, answerType);

            if (shouldCollapseOnClose) {
                setIsOpen(false);
            }

            setAnswerText('');
        } catch (error: unknown) {
            // eslint-disable-next-line no-undefined
            handleReviewReplyExceptions(error, snackbar, undefined, () => setIsButtonDisabledFromError(true));
        } finally {
            setIsReviewReplyInProgress(false);
        }
    }, [
        isButtonDisabled,
        isReviewReplyInProgress,
        reviewId,
        answerText,
        refreshReview,
        refreshSuggestions,
        snackbar,
        answerType,
        shouldCollapseOnClose,
        setIsOpen,
    ]);

    function handlePressEnter(event: React.KeyboardEvent<HTMLTextAreaElement>): void {
        handleEnter(event, setAnswerText, systemContext.screen.isDesktop ? handleSendReply : null);
    }

    function handleSelectTemplateClick() {
        track(AnalyticsTarget.ReviewsManagement.Reviews.ClickResponseTemplates);

        setIsSelectTemplatePopupOpen(true);
    }

    function handleRequestAi() {
        setAnswerText('');
        requestSuggest(reviewId);
        setWasGeneration(true);

        if (user?.isTestUser || user?.isDemoUser) {
            const currentCount = localStorageShowButton?.[reviewId]?.count ?? 0;

            saveToLocalStorage(
                {
                    ...localStorageShowButton,
                    [reviewId]: {
                        count: currentCount + 1,
                    },
                },
                TEST_AND_DEMO_USER_COUNT_AI_STORAGE_KEY
            );
        }
    }

    const aiButtonClassNames = classNames(styles.ReplyForm_neuralLinkButton, {
        [styles.ReplyForm_neuralLinkButton__isGenerating]: isGeneratingSuggest,
    });

    const isEmojiCommentReview = isOnlyEmojis(comment);

    return (
        <Space className={styles.ReplyForm_replyFormWrapper} direction="vertical">
            <div className={styles.ReplyForm_textAreaBlock}>
                <Input.TextArea
                    autoSize
                    className={styles.ReplyForm_textArea}
                    onFocus={() => setIsFocus(true)}
                    onInput={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                        setAnswerText(event.currentTarget.value);

                        setAnswerType((type) => {
                            if (type.includes(ReplyTypeEnum.TemplateReply)) {
                                return `${ReplyTypeEnum.TemplateReply}, ${ReplyTypeEnum.ManualReply}`;
                            }

                            return ReplyTypeEnum.ManualReply;
                        });
                    }}
                    onPressEnter={handlePressEnter}
                    placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_REPLY_TEXT')}
                    ref={replyAreaRef}
                    rows={isFocus ? MAX_ROWS_TEXTAREA : MIN_ROWS_TEXTAREA}
                    size="large"
                    value={answerText}
                />
                <div className={styles.ReplyForm_buttonsWrapper}>
                    {isShowGenerateButton && (
                        <GetNeuralLinkButtonWrapper
                            isCanActivateTrial={reviewGptReplyLicense?.canActivateTrial}
                            isOnlyEmojiComment={isEmojiCommentReview}
                            isTrialWrapper={!reviewGptReplyLicense?.isActive}
                            refetch={refetch}
                        >
                            <Button
                                className={aiButtonClassNames}
                                disabled={
                                    !reviewGptReplyLicense?.isActive || isAiButtonDisabled || isEmojiCommentReview
                                }
                                icon={
                                    isGeneratingSuggest ? (
                                        <Spin indicator={<FontAwesomeIcon color={gray1} icon={faSpinner} spin />} />
                                    ) : wasGeneration ? (
                                        <FontAwesomeIcon icon={faRepeat} />
                                    ) : (
                                        <FontAwesomeIcon icon={faMessageBot} />
                                    )
                                }
                                id="GU30W"
                                onClick={() => !isGeneratingSuggest && handleRequestAi()}
                                type="primary"
                            >
                                <span>
                                    <Locale
                                        stringKey={
                                            isGeneratingSuggest
                                                ? 'NEURAL_NETWORK__GENERATE_BUTTON__GENERATE'
                                                : wasGeneration
                                                ? 'NEURAL_NETWORK__GENERATE_BUTTON__GENERATE_MORE'
                                                : 'NEURAL_NETWORK__GENERATE_BUTTON'
                                        }
                                    />
                                </span>
                            </Button>
                        </GetNeuralLinkButtonWrapper>
                    )}
                    <Tooltip placement="top" title={<Locale stringKey="REVIEWS__REVIEW_ANSWER__TEMPLATE__HINT" />}>
                        <Button className={styles.ReplyForm_templateButton} onClick={handleSelectTemplateClick}>
                            <FontAwesomeIcon height={32} icon={faFile} width={32} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.ReplyForm_sendButtonsWrapper}>
                <Button
                    disabled={isButtonDisabled}
                    loading={isReviewReplyInProgress}
                    onClick={handleSendReply}
                    type="primary"
                >
                    <Locale stringKey="REVIEWS__REPLY" />
                </Button>

                <Button onClick={() => setIsOpen(false)}>
                    <Locale stringKey="BUTTON__CANCEL" />
                </Button>
            </div>
        </Space>
    );
}
