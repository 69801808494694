export const enLocalPackTranslations = {
    LOCAL_PACK_PAGE__TITLE: 'Local Pack positions',
    LOCAL_PACK_PAGE__SUB_TITLE: 'Local Pack positions',
    LOCAL_PACK__LEADERBOARD__TITLE: 'Leaderboard',
    LOCAL_PACK__RESULT_TABLE__TITLE: 'Pickup results',
    LOCAL_PACK__RESULT_TABLE__DATE: 'Date and time',
    LOCAL_PACK__RESULT_TABLE__COMPANY_ADDRESS: 'Name and address',
    LOCAL_PACK__RESULT_TABLE__ALL_SERVICES: 'Average position',
    LOCAL_PACK__CATALOG__GOOGLE: 'Google',
    LOCAL_PACK__CATALOG__YANDEX: 'Yandex',
    LOCAL_PACK__SERVICE_TYPE_TABLE__DESKTOP: 'Search',
    LOCAL_PACK__SERVICE_TYPE_TABLE__MOBILE: 'Mobile',
    LOCAL_PACK__SERVICE_TYPE_TABLE__MAPS: 'Maps',
    LOCAL_PACK__RESULT_PAGE__TITLE: 'Pack result',
    LOCAL_PACK__RESULT_PAGE__SUB_TITLE: 'Pack result from {date}',
    LOCAL_PACK__RESULT_PAGE__SUB_TITLE_WITHOUT_DATE: 'Pack result',
    LOCAL_PACK__CREATE_RULE_PAGE__TITLE: 'Create rule',
    LOCAL_PACK__EDIT_RULE_PAGE__TITLE: 'Edit rule',
    LOCAL_PACK__RESULT_PAGE__PLATFORM: 'Platform',
    LOCAL_PACK__RESULT__PENDING: 'Pending collection',
    LOCAL_PACK__POSITION_DISTRIBUTION__TITLE: 'Distribution of positions',
    LOCAL_PACK__POSITION_DISTRIBUTION__BY_DAY: 'By day',
    LOCAL_PACK__POSITION_DISTRIBUTION__BY_WEEK: 'By week',
    LOCAL_PACK__POSITION_DISTRIBUTION__BY_MONTH: 'By month',
    LOCAL_PACK__POSITIONS_MODAL__TITLE: 'Results by: «{searchQuery}»',
    LOCAL_PACK__POSITIONS_TABLE__NAME_ADDRESS: 'Name and address',
    LOCAL_PACK__POSITIONS_TABLE__REMOTENESS: 'Remoteness',
    LOCAL_PACK__POSITIONS_TABLE__RATING: 'Rating',
    LOCAL_PACK__POSITIONS_TABLE__REVIEWS_COUNT: 'Reviews',
    LOCAL_PACK__POSITIONS_TABLE__MAP_LINK: 'Link',
    LOCAL_PACK__MAP__STATUS__NEW: 'Waiting for search',
    LOCAL_PACK__MAP__STATUS__FAILED: 'No results available',
    LOCAL_PACK__MAP__STATUS__EMPTY: 'Empty results list',

    LOCAL_PACK__QUERY_RESULTS_TABLE__TITLE: 'Survey results by queries',
    LOCAL_PACK__QUERY_RESULTS_TABLE__KEY: 'Search query',
    LOCAL_PACK__QUERY_RESULTS_TABLE__DATE: 'Date',
    LOCAL_PACK__QUERY_RESULTS_TABLE__MOBILE: 'Mobile, average',
    LOCAL_PACK__QUERY_RESULTS_TABLE__MAPS: 'Maps, average',
    LOCAL_PACK__QUERY_RESULTS_TABLE__WEB: 'Search, average',
    LOCAL_PACK__QUERY_RESULTS_TABLE__ALL: 'Average position',

    LOCAL_PACK__FILTER_FORM__INPUT__TIME_PERIOD__LABEL: 'Time period',
    LOCAL_PACK__FILTER_FORM__INPUT__SEARCH_QUERIES__LABEL: 'Search queries',
    LOCAL_PACK__FILTER_FORM__TITLE: 'Filters',
    LOCAL_PACK__FILTER__LABEL: 'Filters:',
    LOCAL_PACK__FILTER__EMPTY_TEXT: 'Filters are not applied',

    LOCAL_PACK__RULE_FORM__NAME__LABEL: 'Name of the search rule',
    LOCAL_PACK__RULE_FORM__NAME__PLACEHOLDER: 'Enter the name of the rule',
    LOCAL_PACK__RULE_FORM__BRAND__LABEL: 'brand',
    LOCAL_PACK__RULE_FORM__BRAND__PLACEHOLDER: 'Select brand',
    LOCAL_PACK__RULE_FORM__SERVICES__LABEL: 'Services',
    LOCAL_PACK__RULE_FORM__SERVICES__PLACEHOLDER: 'Select services',
    LOCAL_PACK__RULE_FORM__SEARCH_PHRASE__LABEL: 'Search phrase',
    LOCAL_PACK__RULE_FORM__SEARCH_PHRASES__LABEL: 'Search phrases',
    LOCAL_PACK__RULE_FORM__SEARCH_PHRASE__PLACEHOLDER: 'Enter search phrase',
    LOCAL_PACK__RULE_FORM__SEARCH_PHRASES__PLACEHOLDER: 'Enter search phrases',
    LOCAL_PACK__RULE_FORM__FREQUENCY__LABEL: 'Frequency',
    LOCAL_PACK__RULE_FORM__ONLY_FOR_CARD_AND_MOBILE_DEVICES: '(only for maps and mobile devices)',
    LOCAL_PACK__RULE_FORM__SQUARE_SIZE__LABEL: 'Square size',
    LOCAL_PACK__RULE_FORM__POINT_DISTANCE__LABEL: 'Distance between points',
    LOCAL_PACK__RULE_FORM__CANCEL_EDIT__TITLE: 'Cancel query editing',
    LOCAL_PACK__RULE_FORM__CANCEL_CREATE__TITLE: 'Cancel request creation',
    LOCAL_PACK__RULE_FORM__CANCEL__CONTENT: 'Unsaved changes will be lost. Confirm?',
    LOCAL_PACK__RULE_FORM__CREATE__FOOTER: 'Rule will be created after review by moderation',
    LOCAL_PACK__RULE_FORM__EDIT__FOOTER: 'The rule will be changed after review by moderation',

    LOCAL_PACK__DISTANCE: '{distance} m.',
    LOCAL_PACK__DISTANCE__LONG: '{distance} meters',

    LOCAL_PACK__FREQUENCY__DAILY: 'Daily',
    LOCAL_PACK__FREQUENCY__WEEKLY: 'Weekly',
    LOCAL_PACK__FREQUENCY__MONTHLY: 'Monthly',
    LOCAL_PACK__FREQUENCY__ONCE: 'One-off',

    LOCAL_PACK__RULES__TITLE: 'Created rules',
    LOCAL_PACK__RULES_TABLE__KEY: 'Queries and keys',
    LOCAL_PACK__RULES_TABLE__FREQUENCY: 'Frequency',
    LOCAL_PACK__RULES_TABLE__GRID_SIZE: 'Size',
    LOCAL_PACK__RULES_TABLE__GRID_DISTANCE: 'Distance',
    LOCAL_PACK__RULES_TABLE__STATUS: 'Status',
    LOCAL_PACK__RULES_TABLE__ACTIONS: 'Actions',

    LOCAL_PACK__RULES_STATUS__ACTIVE: 'Active',
    LOCAL_PACK__RULES_STATUS__NOT_ACTIVE: 'Not active',
    LOCAL_PACK__RULES_STATUS__REJECTED: 'Rejected',
    LOCAL_PACK__RULES_STATUS__MODERATING: 'Moderating',

    LOCAL_PACK__MANAGE_RULES: 'Manage rules',
    LOCAL_PACK__LEADERBOARD: 'Leaderboard',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS: 'Analytics by queries',
    LOCAL_PACK__RULE__CREATE_SUCCESS: 'Rule successfully created',
    LOCAL_PACK__RULE__CREATE_ERROR: 'Error occured when updating rule',
    LOCAL_PACK__RULE__UPDATE_SUCCESS: 'Rule update request sent successfully.',
    LOCAL_PACK__RULE__UPDATE_ERROR: 'Something went wrong updating rule',

    LOCAL_PACK__LEADERBOARD__SEARCH_KEYS: 'Queries: {count}',
    LOCAL_PACK__LEADERBOARD__MORE_SEARCH_KEYS__SINGULAR: '1 more query...',
    LOCAL_PACK__LEADERBOARD__MORE_SEARCH_KEYS__FEW: '{count} more queries...',
    LOCAL_PACK__LEADERBOARD__MORE_SEARCH_KEYS__MANY: '{count} more queries...',

    LOCAL_PACK__LEADERBOARD__TABLE_TITLE: 'Number of positions in tops',
    LOCAL_PACK__LEADERBOARD__COLUMN__ADDRESS_NAME: 'Company',
    LOCAL_PACK__LEADERBOARD__COLUMN__KEYS: 'Number of queries',
    LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_1_5: 'Top 1–5',
    LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_6_9: 'Top 6–9',
    LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_10_20: 'Top 10–20',
    LOCAL_PACK__LEADERBOARD__COLUMN__POSITION_OVER_21: 'Top 21+',

    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__DOWNLOAD_REPORT: 'Download the report',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COMPANY: 'Company',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__KEY: 'Search query',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__GRID_SIZE: 'Square size',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__GRID_STEP: 'Distance between points',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COST: 'Cost for 1 shoot {description}',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COST__DESCRIPTION: 'Number of limits required for 1 withdrawal',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COMPLETED_SEARCHES: 'Number of completed searches',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__AVERAGE_POSITION: 'Middle position',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__LAST_SEARCH_DATE: 'Date of last rent',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE: 'Top 5',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__GOOGLE__MAPS: 'Google maps',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__GOOGLE__DESKTOP: 'Google',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__YANDEX__MAPS: 'Yandex maps',
    LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__YANDEX__DESKTOP: 'Yandex',

    LOCAL_PACK__POSITION_CHANGE__UP: 'Growth',
    LOCAL_PACK__POSITION_CHANGE__DOWN: 'Reduction',
    LOCAL_PACK__POSITION_CHANGE__NOT_CHANGED: 'No changes',

    LOCAL_PACK__LANDING__CARD_1__TITLE: 'Local position tracker',
    LOCAL_PACK__LANDING__CARD_1__TEXT:
        "Monitor the company's position in local search results for targeted queries, find points of growth and improve promotion results",

    LOCAL_PACK__LANDING__CARD_2__TITLE: 'Promote local searches',
    LOCAL_PACK__LANDING__CARD_2__TEXT_1:
        '“A store nearby”, “a coffee shop near the metro”, “where to have dinner” – users enter local queries every day in search engines, navigators and even taxi services when they are looking for goods and services nearby.',
    LOCAL_PACK__LANDING__CARD_2__TEXT_2:
        '76% of users who find a company through local searches visit it within a day.',

    LOCAL_PACK__LANDING__CARD_3__TITLE: "Increase your company's visibility on maps",
    LOCAL_PACK__LANDING__CARD_3__TEXT_1:
        'When we search for goods and services nearby, in the search results we first see local results with a map and company cards.',
    LOCAL_PACK__LANDING__CARD_3__TEXT_2:
        'The card issue takes up a significant part of the first screen and takes up a large volume of transitions.',
    LOCAL_PACK__LANDING__CARD_3__TEXT_3:
        'Therefore, it is important for companies that want to attract more customers to work with cards and increase their positions in local search results for targeted queries.',

    LOCAL_PACK__LANDING__CARD_4__TITLE: "Track your company's position in local search results",
    LOCAL_PACK__LANDING__CARD_4__TEXT_1:
        'On Google and Yandex maps, positions for a target query can change every X meters. That is why it is necessary to evaluate the results at different distances from your point.',
    LOCAL_PACK__LANDING__CARD_4__TEXT_2:
        'The “Local Position Tracker” will show you how your company’s points are ranked for the target request within a given radius, as well as who your competitors are and what positions they occupy.',

    LOCAL_PACK__LANDING__CARD_5__TITLE: 'Evaluate your progress and find growth points',
    LOCAL_PACK__LANDING__CARD_5__TEXT_1:
        'Track the dynamics of growth or decline of positions in the search results, monitor positions for each point or keyword on all sites.',
    LOCAL_PACK__LANDING__CARD_5__TEXT_2:
        'This will allow you to quickly respond to changes and understand which keys and points you should focus your efforts on to increase your audience reach and generate leads.',

    LOCAL_PACK__LANDING__CARD_6__TITLE: 'Analyze your competitors and get ahead of them in search results',
    LOCAL_PACK__LANDING__CARD_6__TEXT_1: 'Generate a detailed report on competitors for each of your locations.',
    LOCAL_PACK__LANDING__CARD_6__TEXT_2:
        'This will allow you to evaluate how your competitors achieve such results and how you can beat them in local search results.',

    LOCAL_PACK__LANDING__ADVANTAGES__TITLE: 'Benefits of a local position tracker',
    LOCAL_PACK__LANDING__ADVANTAGES_CARD_1__TITLE: 'Evaluate promotion results',
    LOCAL_PACK__LANDING__ADVANTAGES_CARD_1__DESCRIPTION:
        'You will see a real picture of the distribution of your cards in local search results by keywords and will be able to track your progress.',
    LOCAL_PACK__LANDING__ADVANTAGES_CARD_2__TITLE: 'Find growth points to increase your reach',
    LOCAL_PACK__LANDING__ADVANTAGES_CARD_2__DESCRIPTION:
        "You'll understand which cards and keywords to focus your efforts on to increase your reach and generate leads.",
    LOCAL_PACK__LANDING__ADVANTAGES_CARD_3__TITLE: 'Track your competitors',
    LOCAL_PACK__LANDING__ADVANTAGES_CARD_3__DESCRIPTION:
        'You will know the competitors of each point of your business, track how their positions are changing, evaluate how they achieve results and how you can get around them.',

    LOCAL_PACK__LANDING__CALL_TO_ACTION_1__DESCRIPTION:
        'Sign up for a free trial period of the local position tracker and improve your company’s promotion results now',
    LOCAL_PACK__LANDING__CALL_TO_ACTION_2__DESCRIPTION:
        'Sign up for a free trial period and use all the benefits of the local position tracker for free for {duration} days',

    LOCAL_PACK__ACTIVATION__STEP_1__TITLE: 'Welcome to the local position tracker! 👋',
    LOCAL_PACK__ACTIVATION__STEP_1__TEXT_1:
        'You have taken the first step towards tracking your business.{break}To help you understand how this service works, we have prepared a short guide for you.',
    LOCAL_PACK__ACTIVATION__STEP_1__TEXT_2: 'First, select the companies for which positions will be removed.',
    LOCAL_PACK__ACTIVATION__STEP_1__NOT_READY_TOOLTIP: 'You must select from 1 to 100 companies',

    LOCAL_PACK__ACTIVATION__STEP_2__TITLE: 'Create a rule for reading positions 🔎',
    LOCAL_PACK__ACTIVATION__STEP_2__TEXT_1:
        'Enter the search key, select the sampling frequency, grid size and distance between points.{break}This will allow you to run position surveys to get a personalized report.',
    LOCAL_PACK__ACTIVATION__STEP_2__BUTTON: 'Start search',
    LOCAL_PACK__ACTIVATION__STEP_2__NOT_READY_TOOLTIP: 'Fill out the form to continue',
    LOCAL_PACK__ACTIVATION__STEP_2__FREQUENCY_POPOVER: 'During the test period, only 1 position pickup can be launched',
    LOCAL_PACK__ACTIVATION__STEP_2__SQUARE_SIZE_POPOVER: 'During the trial period, only 1 square size is available',

    LOCAL_PACK__ACTIVATION__STEP_3__TITLE: 'View local SEO results 📄',
    LOCAL_PACK__ACTIVATION__STEP_3__TEXT:
        'The indicator on the page for viewing the withdrawal result indicates the position number of your company.{break}Click on the marker to view the search results ranking.',

    LOCAL_PACK__ACTIVATION__STEP_4__TITLE: 'Manage analytics according to your needs 📊',
    LOCAL_PACK__ACTIVATION__STEP_4__TEXT:
        'Review the analytics by keys; it will allow you to understand which queries have significant drawdowns.',

    LOCAL_PACK__ACTIVATION__STEP_5__TITLE: 'Service is ready!',
    LOCAL_PACK__ACTIVATION__STEP_5__TEXT:
        'You have successfully launched the process of taking positions for the selected companies.{break}Go to the position tracker home page to view the results.',
};
