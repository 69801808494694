import {Button, Input} from 'antd';

import {Page} from '../../../layout/page/page';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {
    NewPasswordFormEnum,
    NewPasswordFormType,
    use2GisResetPassword,
    useNewPasswordMutation,
    useNewPasswordUrlQuery,
} from '../../../service/user/user-new-password';
import {Form} from '../../../typings/antd';
import {AuthFormHeader} from '../common/form-header/auth-form-header';
import {TwoGisResetPassword} from '../two-gis-reset-password/two-gis-reset-password';

export function NewPassword(): JSX.Element {
    const [form] = Form.useForm<NewPasswordFormType>();
    const password = Form.useWatch(NewPasswordFormEnum.Password, form);
    const passwordConfirm = Form.useWatch(NewPasswordFormEnum.PasswordConfirm, form);
    const {requiredFieldRule, validatePasswordRules} = useFormRules();
    const {data, isError} = use2GisResetPassword();
    const {mutate, isLoading} = useNewPasswordMutation();
    const {isCorrectUrl, jwtToken} = useNewPasswordUrlQuery();
    const {getLocalizedString} = useLocale();

    const disabled = !password || !passwordConfirm || isLoading;

    if (!isCorrectUrl || isError) {
        return (
            <Page.Error titleLangKey="PAGE__NEW_PASSWORD__TITLE">
                <Locale stringKey="PAGE__NEW_PASSWORD__URL_ERROR" />
            </Page.Error>
        );
    }

    if (data?.isCurrentUserExists && data.email && jwtToken) {
        return <TwoGisResetPassword email={data.email} jwtToken={jwtToken} />;
    }

    return (
        <Page.CenteredCard titleLangKey="PAGE__NEW_PASSWORD__TITLE">
            <AuthFormHeader subtitle={<Locale stringKey="PAGE__NEW_PASSWORD__ENTER" />} />

            <Form<NewPasswordFormType> form={form} layout="vertical" onFinish={mutate}>
                <Form.Item<NewPasswordFormType>
                    label={<Locale stringKey="PAGE__NEW_PASSWORD__PASSWORD__PLACEHOLDER" />}
                    name={NewPasswordFormEnum.Password}
                    rules={[requiredFieldRule, ...validatePasswordRules()]}
                >
                    <Input.Password
                        placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__PLACEHOLDER')}
                        size="large"
                    />
                </Form.Item>

                <Form.Item<NewPasswordFormType>
                    label={<Locale stringKey="PAGE__NEW_PASSWORD__CONFIRM_PASSWORD__PLACEHOLDER" />}
                    name={NewPasswordFormEnum.PasswordConfirm}
                    rules={[requiredFieldRule, ...validatePasswordRules()]}
                >
                    <Input.Password
                        placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__PASSWORD__PLACEHOLDER')}
                        size="large"
                    />
                </Form.Item>

                <Button block disabled={disabled} htmlType="submit" loading={isLoading} size="large" type="primary">
                    <Locale stringKey="BUTTON__SAVE" />
                </Button>
            </Form>
        </Page.CenteredCard>
    );
}
