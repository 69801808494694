import {DomainNameEnum} from '../../provider/domain-config/domain-config-type';
import {getSystemState} from '../../provider/system/system-context-helper';

import {PhotoSourceNameEnum} from './phototool-sources-helper';
import {
    MediaItemType,
    MediaType,
    PhotoFilterOptionsType,
    PhotoReportTypeEnum,
    PhotoStatusKeyEnum,
    PhotosType,
    VideosType,
    VideoType,
} from './phototool-type';

type ImageProxyParametersType = {
    width: number;
    height: number;
    gravity: string;
    resizingType: string;
    enlarge: number;
    extension: string;
};

const defaultImageProxyParameters: ImageProxyParametersType = {
    height: 0,
    gravity: 'no',
    resizingType: 'fit',
    enlarge: 0,
    extension: 'jpeg',
    width: 0,
};

export function getPreviewSrc(options: {
    sourceSrc: string;
    imageProxyParameters: Partial<ImageProxyParametersType>;
    domainName: string;
}): string {
    const {sourceSrc, imageProxyParameters, domainName} = options;

    const proxyParameters = {...defaultImageProxyParameters, ...imageProxyParameters};

    const {screen} = getSystemState();
    const {devicePixelRatio: pixelRatio} = screen;
    const {width, height, gravity, resizingType, enlarge, extension} = proxyParameters;
    const resultWidth: number = Math.round(width * pixelRatio);
    const resultHeight: number = Math.round(height * pixelRatio);
    const base_64url = btoa(sourceSrc).replace('=', '').replace('/', '_').replace('+', '-');

    return `https://media.${
        domainName === DomainNameEnum.brandWizard ? 'brandwizard' : 'rocketdata'
    }.io/imgproxy/g:${gravity}/rs:${resizingType}:${resultWidth}:${resultHeight}:${enlarge}/${base_64url}.${extension}`;
}

export function copyPhotoWithStatus(photo: MediaItemType, status: PhotoStatusKeyEnum): MediaItemType {
    return {...photo, status};
}

export function copyPhotoListWithStatus(
    photoList: Array<MediaItemType>,
    updateIds: Array<number>,
    status: PhotoStatusKeyEnum
): Array<MediaItemType> {
    return photoList.map((photo) => {
        if (updateIds.includes(photo.id)) {
            return copyPhotoWithStatus(photo, status);
        }

        return photo;
    });
}

const reportAllowedStatuses = new Set<PhotoStatusKeyEnum>([
    PhotoStatusKeyEnum.New,
    PhotoStatusKeyEnum.Viewed,
    PhotoStatusKeyEnum.ReportRejected,
]);

export function getIsReportAllowed(photo: MediaItemType | null): boolean {
    if (!photo) {
        return false;
    }

    return reportAllowedStatuses.has(photo.status);
}

export function getIsRemoveAllowed(photo: MediaItemType | null): boolean {
    if (!photo || photo.reasonNotAbleToDelete) {
        return false;
    }

    return (
        (photo.own || photo.source.name === PhotoSourceNameEnum.Yandex) &&
        photo.status !== PhotoStatusKeyEnum.Removed &&
        photo.status !== PhotoStatusKeyEnum.RemoveRequest
    );
}

export const PHOTO_REPORT_TYPE_VALUES_MAP: Record<PhotoReportTypeEnum, number> = {
    [PhotoReportTypeEnum.OffensiveContent]: 1,
    [PhotoReportTypeEnum.LawViolation]: 2,
    [PhotoReportTypeEnum.PrivacyViolation]: 3,
    [PhotoReportTypeEnum.PoorQuality]: 4,
    [PhotoReportTypeEnum.LocationMatchingError]: 5,
    [PhotoReportTypeEnum.OrganizationMatchingError]: 6,
    [PhotoReportTypeEnum.Other]: 7,
};

export function transformVideoItem(item: VideoType): MediaItemType {
    return {
        id: item.id,
        imageHeight: item.videoHeight,
        imageWidth: item.videoWidth,
        originalImageUrl: item.originalVideoUrl,
        originalCardUrl: item.originalCardUrl,
        createdAt: item.createdAt,
        source: item.source,
        status: item.status,
        tags: item.tags,
        own: item.own,
        duration: item.duration,
        contentType: item.contentType,
        previewUrl: item.previewUrl,
        reasonNotAbleToDelete: item.reasonNotAbleToDelete,
    };
}

export function transformVideoResponse(response: VideosType): MediaType {
    return {
        ...response,
        results: response.results.map(transformVideoItem),
    };
}

export function checkIsVideoType(_data: VideosType | PhotosType, isVideoMode = false): _data is VideosType {
    return isVideoMode;
}

export function checkIsVideo(mediaItem: MediaItemType): boolean {
    return mediaItem && 'duration' in mediaItem;
}

export const PHOTO_TOOL_FILTER_OPTIONS_EMPTY: PhotoFilterOptionsType = {
    status: null,
    source: null,
    tags: null,
    startDate: null,
    endDate: null,
    own: null,
};
