import {Typography} from 'antd';

import {Locale} from '../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';

import {LegalWidgetItems} from './legal-widget-items';
import * as styles from './legal-widget.scss';

export function LegalWidget(): JSX.Element {
    return (
        <div className={styles.legal_widget__wrapper}>
            <div className={styles.legal_widget__inputs}>
                <Form.Item<CompanyFormType> dependencies={[[CompanyKeyEnum.Address, 'country']]} noStyle>
                    {({getFieldValue, setFieldsValue}) => (
                        <LegalWidgetItems
                            clearLegalValue={() => {
                                if (
                                    getFieldValue([CompanyKeyEnum.Legal, 'name']) ||
                                    getFieldValue([CompanyKeyEnum.Legal, 'form'])
                                ) {
                                    setFieldsValue({legal: {name: null, form: {label: null, value: null}}});
                                }
                            }}
                            country={getFieldValue([CompanyKeyEnum.Address, 'country'])}
                        />
                    )}
                </Form.Item>
            </div>

            <Typography.Text className={styles.legal_widget__description} type="secondary">
                <Locale stringKey="COMPANY_FORM__LEGAL__DESCRIPTION" />
            </Typography.Text>
        </div>
    );
}
