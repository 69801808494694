export const enCompanyFormTranslations = {
    COMPANY_FORM__CREATING: 'Create company',
    COMPANY_FORM__CREATING_BREADCRUMB: 'Creation',
    COMPANY_FORM__EDITING: 'Edit company',
    COMPANY_FORM__DESCRIPTION:
        'Enter data to be sent to catalogs. You may add data for all catalogs at once or different variations for specific catalogs.',
    COMPANY_FORM__CREATE: 'Create company',
    COMPANY_FORM__UPDATE: 'Update info',
    COMPANY_FORM__CANCEL: 'Cancel',
    COMPANY_FORM__HIDE: 'Hide',
    COMPANY_FORM__SHOW: 'Show',
    COMPANY_FORM__PROGRESSBAR: 'Card fullness',
    COMPANY_FORM__FIELDS_SECTION: 'Not enough data',
    COMPANY_FORM__SNACKBAR__CREATED__HEADER: 'Company created',
    COMPANY_FORM__SNACKBAR__CREATED__BODY: 'Company “{title}” has been successfully created.',
    COMPANY_FORM__SNACKBAR__UPDATED__HEADER: 'Changes saved',
    COMPANY_FORM__SNACKBAR__UPDATED__BODY: 'Company “{title}” has been successfully changed.',
    COMPANY_FORM__SNACKBAR__CLOSED__HEADER: 'Company closed',
    COMPANY_FORM__SNACKBAR__CLOSED__BODY: 'Company “{title}” has been permanently closed.',
    COMPANY_FORM__SNACKBAR__REMOVED__HEADER: 'Card removed',
    COMPANY_FORM__SNACKBAR__REMOVED__BODY: 'Company card “{title}” has been removed.',
    COMPANY_FORM__SNACKBAR__SAVE_ERROR__HEADER: 'Unable to save data',
    COMPANY_FORM__SNACKBAR__VALIDATION_ERROR__HEADER: 'There are errors in form fields',
    COMPANY_FORM__SNACKBAR__VALIDATION_ERROR__BODY:
        'Please fill out the required fields and verify that the data is correct.',
    COMPANY_FORM__CATALOG_LIST__CONFIGURE_CATALOGS_BUTTON: 'Configure catalogs',
    COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_TITLE: 'Catalog selector',
    COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_SUBTITLE: 'Choose one or more catalogs',
    COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_DESCRIPTION: 'You can enter unique names for these catalogs.',
    COMPANY_FORM__CATALOG_LIST__CATALOG_POPUP_UNAVAILABLE_IN_COUNTRY:
        'The catalog is not available in the country of the company',
    COMPANY_FORM__CATALOG_LIST__CATALOG_DESCRIPTION: 'These data will be sent only to {catalogName}',
    COMPANY_FORM__CATALOG_LIST__FOOTER__BUTTON: 'Remove settings',
    COMPANY_FORM__CATALOG_LIST__MODAL__TITLE: 'Remove settings for catalog',
    COMPANY_FORM__CATALOG_LIST__MODAL__CONTENT: 'Settings for "{fieldName}" field for {catalogName} will be deleted.',
    COMPANY__FORM__WIDGET_NAME__NAMES: 'Name',
    COMPANY__FORM__WIDGET_NAME__BRAND: 'Brand and branch code',
    COMPANY__FORM__WIDGET_NAME__ADDRESS: 'Address',
    COMPANY__FORM__WIDGET_NAME__LEGAL: 'Legal information',
    COMPANY__FORM__WIDGET_NAME__CATEGORIES: 'Categories (type of activity)',
    COMPANY__FORM__WIDGET_NAME__CATEGORIES__NAVIGATION: 'Categories',
    COMPANY__FORM__WIDGET_NAME__CONTACT_INFO: 'Contact information',
    COMPANY__FORM__WIDGET_NAME__LINKS: 'Website and links to social networks',
    COMPANY__FORM__WIDGET_NAME__LINKS__NAVIGATION: 'Website and social networks',
    COMPANY__FORM__WIDGET_NAME__WORKING_TIME: 'Working time',
    COMPANY__FORM__WIDGET_NAME__TEMPORARY_WORKING_TIME: 'Temporary opening hours',
    COMPANY__FORM__WIDGET_NAME__PAYMENT_METHOD: 'Payment method',
    COMPANY__FORM__WIDGET_NAME__DESCRIPTION: 'Description',
    COMPANY__FORM__WIDGET_NAME__LOGO: 'Logo and cover',
    COMPANY__FORM__WIDGET_NAME__GALLERY: 'Photo gallery',
    COMPANY__FORM__WIDGET_NAME__ATTRIBUTES: 'Features and additional information for catalogs',
    COMPANY__FORM__WIDGET_NAME__ATTRIBUTES__NAVIGATION: 'Features',
    COMPANY__FORM__WIDGET_NAME__DELETION: 'Closing and/or deleting a company',
    COMPANY__FORM__WIDGET_NAME__DELETION__NAVIGATION: 'Closing and deleting',
    COMPANY_FORM__NAMES__CATALOGS_HEADER: 'Name variations for catalogs',
    COMPANY_FORM__NAMES__COMPANY_NAME__LABEL: 'Company name',
    COMPANY_FORM__NAMES__COMPANY_NAME__PLACEHOLDER: 'My company',
    COMPANY_FORM__NAMES__SENT_COMPANY_NAME__LABEL: 'Sent name (except Google)',
    COMPANY_FORM__NAMES__SENT_COMPANY_NAME__PLACEHOLDER: 'Waiting for moderation...',
    COMPANY_FORM__NAMES__SENT_COMPANY_NAME__HINT: 'Moderated name for catalogs other than Google.',
    COMPANY_FORM__NAMES__SENT_COMPANY_NAME__HINT_DETAILS: 'Details',
    COMPANY_FORM__NAMES__BUTTON__ADD_OPTIONS_IN_OTHER_LANGUAGES: 'Add options in other languages',
    COMPANY_FORM__NAMES__LANGUAGES_POPUP__TITLE: 'Add languages',
    COMPANY_FORM__NAMES__LANGUAGES_POPUP__SEARCH: 'Search languages',
    COMPANY_FORM__NAMES__LANGUAGES_POPUP__POPULAR: 'Popular',
    COMPANY_FORM__NAMES__LANGUAGES_POPUP__AZ: 'A-Z',
    COMPANY_FORM__NAMES__LANGUAGES_POPUP__SELECTED: 'Selected: {count}',
    COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__LABEL: 'Alternative company names',
    COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__HINT_HEADER: 'Alternative names',
    COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__HINT:
        'Alternative names are necessary for finding companies on maps by a key feature if they were not found by the main name. Alternative names are not used to improve search rankings. Data on them is not sent anywhere.',
    COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__PLACEHOLDER: 'Alternative names for searching',
    COMPANY_FORM__BRAND__BRANCH_CODE__LABEL: 'Branch code',
    COMPANY_FORM__BRAND__BRANCH_CODE__PLACEHOLDER: 'Branch code within the brand',
    COMPANY_FORM__BRAND__BRAND_NAME__LABEL: 'Brand name',
    COMPANY_FORM__BRAND__BRAND_NAME__PLACEHOLDER: 'Enter brand name',
    COMPANY_FORM__ADDRESS__COUNTRY__LABEL: 'Country',
    COMPANY_FORM__ADDRESS__COUNTRY__PLACEHOLDER: 'United Arab Emirates',
    COMPANY_FORM__ADDRESS__REGION__LABEL: 'Region',
    COMPANY_FORM__ADDRESS__REGION__PLACEHOLDER: 'Dubai',
    COMPANY_FORM__ADDRESS__CITY__LABEL: 'City',
    COMPANY_FORM__ADDRESS__CITY__PLACEHOLDER: 'Dubai',
    COMPANY_FORM__ADDRESS__POSTCODE__LABEL: 'Postcode',
    COMPANY_FORM__ADDRESS__POSTCODE__PLACEHOLDER: '00000',
    COMPANY_FORM__ADDRESS__STREET__LABEL: 'Street',
    COMPANY_FORM__ADDRESS__STREET__PLACEHOLDER: 'Al Wasl Rd',
    COMPANY_FORM__ADDRESS__HOUSE_NUMBER__LABEL: 'House number',
    COMPANY_FORM__ADDRESS__HOUSE_NUMBER__PLACEHOLDER: '2',
    COMPANY_FORM__ADDRESS__COMMENT__LABEL: 'Comment to address',
    COMPANY_FORM__ADDRESS__COMMENT__HINT:
        'Additional information can be entered in the field. It can be used to specify the location of the company, such as the name of a shopping center, floor, room number, etc.',
    COMPANY_FORM__ADDRESS__COMMENT__PLACEHOLDER: 'ENOC 1011 Petrol Station',
    COMPANY_FORM__ADDRESS__SHOW_LAT_LONG__LABEL: 'Set coordinates on the map manually',
    COMPANY_FORM__ADDRESS__LATITUDE__LABEL: 'Latitude',
    COMPANY_FORM__ADDRESS__LATITUDE__PLACEHOLDER: '00.00000',
    COMPANY_FORM__ADDRESS__LONGITUDE__LABEL: 'Longitude',
    COMPANY_FORM__ADDRESS__LONGITUDE__PLACEHOLDER: '00.00000',
    COMPANY_FORM__ADDRESS__MAP__MODAL_TITLE: 'Select a point on the map',
    COMPANY_FORM__ADDRESS__MAP__PLACHOLDER: 'Select a city',
    COMPANY_FORM__ADDRESS__UNAVAILABLE_CATALOGS_MODAL__TITLE: 'Some catalogs are not available',
    COMPANY_FORM__ADDRESS__UNAVAILABLE_CATALOGS_MODAL__CONTENT_START:
        'The following catalogs are not available in your country of choice:',
    COMPANY_FORM__ADDRESS__UNAVAILABLE_CATALOGS_MODAL__CONTENT_END:
        'Unique settings for these catalogs will be removed. Click "Cancel" if you chose the wrong country.',
    COMPANY_FORM__LEGAL__DESCRIPTION: 'Both fields must be filled in or left empty',
    COMPANY_FORM__LEGAL__ORGANIZATIONAL_AND_LEGAL_FORM__LABEL: 'Type of business',
    COMPANY_FORM__LEGAL__ORGANIZATIONAL_AND_LEGAL_FORM__PLACEHOLDER: 'Select form',
    COMPANY_FORM__LEGAL__LEGAL_NAME__LABEL: 'Legal name',
    COMPANY_FORM__LEGAL__LEGAL_NAME__PLACEHOLDER: 'Horns and hooves',
    COMPANY_FORM__CATEGORIES__CATALOGS_HEADER: 'Categories in catalogs',
    COMPANY_FORM__CATEGORIES__ADD_ONE_MAIN_CATEGORY_AND_THE_OTHERS_IN_DECREASING_RELEVANCE:
        'Add one main category and the rest in descending order of relevance.',
    COMPANY_FORM__CATEGORIES__SEARCH_CATEGORIES__LABEL: 'Adding search categories',
    COMPANY_FORM__CATEGORIES__SEARCH_CATEGORIES__DESCRIPTION:
        'Corresponding catalog categories will be chosen automatically.',
    COMPANY_FORM__CATEGORIES__LIMIT: '{catalogName} supports up to {limit} categories for a card.',
    COMPANY_FORM__CATEGORIES__LIMIT_SINGULAR: '{catalogName} supports {limit} category for a card.',
    COMPANY_FORM__CATEGORIES__CATEGORIES_EMPTY: 'No categories selected',
    COMPANY_FORM__CATEGORIES__CATEGORIES_COUNT: 'Total categories: {count}',
    COMPANY_FORM__CATEGORIES__CATEGORIES_MAX: 'Maximum',
    COMPANY_FORM__CATEGORIES__CATALOG_LIST__FOOTER__BUTTON: 'Reset settings',
    COMPANY_FORM__CATEGORIES__CATALOG_LIST__MODAL__TITLE: 'Reset settings for catalog',
    COMPANY_FORM__CATEGORIES__CATALOG_LIST__MODAL__CONTENT:
        'Manually entered categories for {catalogName} will be reset. System will set categories automatically.',
    COMPANY_FORM__CATEGORIES__VALIDATION_ERROR: 'Select at least one category.',
    COMPANY_FORM__CONTACT_INFO__CATALOGS_HEADER: 'Contact information for catalogs',
    COMPANY_FORM__CONTACT_INFO__CATALOGS_HINT:
        'Specify contact options for catalogs if you use substitute numbers or call tracking. {link}',
    COMPANY_FORM__CONTACT_INFO__PHONE__LABEL: 'Phone number',
    COMPANY_FORM__CONTACT_INFO__PHONE__PLACEHOLDER: '+123 000 00 00',
    COMPANY_FORM__CONTACT_INFO__PHONE__ADDITIONAL_INFO:
        'Phone numbers must be in international format. Entering short numbers is allowed.',
    COMPANY_FORM__CONTACT_INFO__MAIN_PHONE__ADDITIONAL_INFO_HEADER: 'Main phone number',
    COMPANY_FORM__CONTACT_INFO__EMAIL__LABEL: 'Email address',
    COMPANY_FORM__CONTACT_INFO__EMAIL__PLACEHOLDER: 'hello@company.com',
    COMPANY_FORM__LINKS__CATALOGS_HEADER: 'Websites for catalogs',
    COMPANY_FORM__LINKS__WEBSITES__LABEL: 'Website link',
    COMPANY_FORM__LINKS__WEBSITES__PLACEHOLDER: 'company.com',
    COMPANY_FORM__LINKS__WEBSITES__ADDITIONAL_INFO_HEADER: 'Main website',
    COMPANY_FORM__LINKS__WEBSITES__ADDITIONAL_INFO:
        'The main website is visible first on the card by default. The rest are hidden.',
    COMPANY_FORM__LINKS__SOCIAL_NETWORKS__LABEL: 'Social network link',
    COMPANY_FORM__LINKS__SOCIAL_NETWORKS__HINT: 'Specify link options for catalogs if you use utm tags. {link}',
    COMPANY_FORM__LINKS__SOCIAL_NETWORKS__PLACEHOLDER: 'twitter.com/mycompany',
    COMPANY_FORM__LINKS__SOCIAL_NETWORKS__TOOLTIP__WARNING:
        'Please note that some directories do not accept links to social networks as a website link.',
    COMPANY_FORM__WORKING_TIME__CATALOGS_HEADER: 'Working time for catalogs',
    COMPANY_FORM__WORKING_TIME__BUTTON__ADD_BREAK: 'Add break',
    COMPANY_FORM__WORKING_TIME__BUTTON__ADD_TEMPORARY_WORKING_HOURS: 'Add dates',
    COMPANY_FORM__WORKING_TIME__THE_POINT_IS_TEMPORARILY_CLOSED: 'The point is temporarily closed',
    COMPANY_FORM__WORKING_TIME__FACEBOOK_BREAKS__TITLE: '{catalogName} breaks',
    COMPANY_FORM__WORKING_TIME__FACEBOOK_BREAKS__DESCRIPTION:
        'Set the individual work time option for {catalogName} with the required break, or leave one break per day for all directories.',
    COMPANY_FORM__WORKING_TIME__AROUND_THE_CLOCK: 'Around the clock',
    COMPANY_FORM__WORKING_TIME__DAYS_OF_THE_WEEK: 'Days of the week',
    COMPANY_FORM__WORKING_TIME__DATES: 'Dates',
    COMPANY_FORM__WORKING_TIME__ADD_DAYS: 'Add days',
    COMPANY_FORM__WORKING_TIME__REMOVE_DAYS: 'Delete days',
    COMPANY_FORM__WORKING_TIME__DAYS_WHICH_NO_TIME:
        'Days for which no time is specified are considered non-working days.',
    COMPANY_FORM__WORKING_TIME__SEND_TEMPORARY_WORK: 'Temporary operating modes will only be sent to {catalog}',
    COMPANY_FORM__WORKING_TIME__SEND_TEMPORARY_WORK_TWO_CATALOG:
        'Temporary operating modes will only be sent to {firstCatalog} and {secondCatalog}',
    COMPANY_FORM__DESCRIPTIONS__DESCRIPTION:
        'Catalogs have their own limitations on the length of short and long descriptions.',
    COMPANY_FORM__DESCRIPTIONS__CATALOGS_HEADER: 'Descriptions for catalogs',
    COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__LABEL: 'Short description',
    COMPANY_FORM__DESCRIPTIONS__LONG_DESCRIPTION__LABEL: 'Long description',
    COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__PLACEHOLDER: 'Short company description',
    COMPANY_FORM__DESCRIPTIONS__LONG_DESCRIPTION__PLACEHOLDER: 'Long description',
    COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__FB_WARNING:
        '{catalogName} supports up to {maxLength} characters in the short description. You can either shorten the text or add a separate version of the short description for {catalogName}.',
    COMPANY_FORM__LOGO__YOUR_COMPANY_LOGO: 'Your company logo',
    COMPANY_FORM__LOGO__HINT__MIN_RESOLUTION: '300x300',
    COMPANY_FORM__LOGO__HINT__MAX_RESOLUTION: '3000x3000',
    COMPANY_FORM__LOGO__HINT__MAX_SIZE: '10MB',
    COMPANY_FORM__LOGO__HINT__FILE_FORMATS: '.jpg .jpeg .png .gif',
    COMPANY_FORM__LOGO__COMPANY_CARD_COVER: 'Company card cover',
    COMPANY_FORM__LOGO__COMPANY_CARD_COVER__HINT__MIN_RESOLUTION: '1366x768',
    COMPANY_FORM__LOGO__COMPANY_CARD_COVER__HINT__MAX_RESOLUTION: '6830x3840',
    COMPANY_FORM__LOGO__COMPANY_CARD_COVER__HINT__MAX_SIZE: '10MB',
    COMPANY_FORM__LOGO__COMPANY_CARD_COVER__FILE_FORMATS: '.jpg .jpeg .png',
    COMPANY_FORM__LOGO__NO_LOGO: 'No logo',
    COMPANY_FORM__LOGO__NO_COVER: 'No cover',
    COMPANY_FORM__LOGO__ERROR__INVALID_IMAGE: 'Image does not fit',
    COMPANY_FORM__LOGO__ERROR__INVALID_IMAGE_DESCRIPTION: 'Please upload an image that meets the requirements.',
    COMPANY_FORM__LOGO__UPLOAD_AND_CROP: 'Upload and crop',
    COMPANY_FORM__LOGO__DRAG_FILE_HERE: 'Or drag a file in this area.',
    COMPANY_FORM__LOGO__UPLOAD_LOGO: 'Upload logo',
    COMPANY_FORM__LOGO__UPLOAD_COVER: 'Upload cover',
    COMPANY_FORM__LOGO__RESTRICTIONS: 'You can upload .jpg .png .gif, min. size {minWidth}x{minHeight}',
    COMPANY_FORM__GALLERY__CATALOGS_HEADER: 'Gallery for catalogs',
    COMPANY_FORM__GALLERY__LABEL: 'Gallery',
    COMPANY_FORM__GALLERY__HINT__HEADER: 'Gallery images',
    COMPANY_FORM__GALLERY__HINT__MIN_RESOLUTION__TITLE: 'Min resolution',
    COMPANY_FORM__GALLERY__HINT__MIN_RESOLUTION: '720x720',
    COMPANY_FORM__GALLERY__HINT__MAX_RESOLUTION__TITLE: 'Max resolution',
    COMPANY_FORM__GALLERY__HINT__MAX_RESOLUTION: '3000x3000',
    COMPANY_FORM__GALLERY__HINT__MAX_SIZE__TITLE: 'Max image size',
    COMPANY_FORM__GALLERY__HINT__MAX_SIZE: '10MB',
    COMPANY_FORM__GALLERY__HINT__FILE_FORMATS__TITLE: 'File formats',
    COMPANY_FORM__GALLERY__HINT__FILE_FORMATS: '.jpg .jpeg .png',
    COMPANY_FORM__GALLERY__HINT__2GIS__MIN_RESOLUTION: '600×600',
    COMPANY_FORM__GALLERY__HINT__2GIS__MAX_RESOLUTION: '7000×7000',
    COMPANY_FORM__GALLERY__HINT__2GIS__FILE_FORMATS: '.jpg .jpeg',
    COMPANY_FORM__GALLERY__HINT__2GIS__PROPORTIONS: 'The proportions of the photo should not exceed 1:5 on any side',
    COMPANY_FORM__GALLERY__ERROR__HEADER: 'Upload error',
    COMPANY_FORM__GALLERY__ERROR__DESCRIPTION: "Uploaded image doesn't fit the requirements.",
    COMPANY_FORM__GALLERY__ERROR__MAX_SIZE: 'Image size should not exceed 10MB',
    COMPANY_FORM__GALLERY__ERROR: 'Please check the images.',
    COMPANY_FORM__ATTRIBUTES__DESCRIPTION: 'Feature options depend on selected categories for this catalog.',
    COMPANY_FORM__ATTRIBUTES__MAIN_CATEGORY: 'Main category',
    COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW: 'Show features',
    COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW__NO_CATEGORY: 'Select the categories first',
    COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW__NO_ATTRIBUTES:
        'There are no features to fill in for the selected categories',
    COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW_MORE: 'Show more',
    COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW_LESS: 'Show less',
    COMPANY_FORM__ATTRIBUTES__BOOLEAN__TRUE: 'True',
    COMPANY_FORM__ATTRIBUTES__BOOLEAN__FALSE: 'False',
    COMPANY_FORM__ATTRIBUTES__BOOLEAN__UNKNOWN: 'Not selected',
    COMPANY_FORM__ATTRIBUTES__CATALOG_LIST__MODAL__CONTENT: 'Features for {catalogName} will be reset.',
    COMPANY_FORM__DELETION__CLOSING: 'Close forever',
    COMPANY_FORM__DELETION__CLOSING__DESCRIPTION:
        'You can show in the catalogs that the company is permanently closed.',
    COMPANY_FORM__DELETION__CLOSING__MODAL__TITLE: 'Closing the company permanently',
    COMPANY_FORM__DELETION__CLOSING__MODAL__DESCRIPTION:
        'The cards in the catalogs will indicate that the company is permanently closed. It is not possible to undo this action.',
    COMPANY_FORM__DELETION__REMOVAL: 'Delete card',
    COMPANY_FORM__DELETION__REMOVAL__DESCRIPTION: 'You can remove a company from your {companyName} account.',
    COMPANY_FORM__DELETION__REMOVAL__MODAL__TITLE: 'Removing a company card',
    COMPANY_FORM__DELETION__REMOVAL__MODAL__DESCRIPTION: 'Card will be removed from {companyName}.',
    COMPANY_FORM__ERROR_MODAL__TITLE: 'Unable to save changes',
    COMPANY_FORM__CONTACT_INFO__PHONE__ADDITIONAL_INFO__YANDEX:
        'Phone numbers must be in international or national format. Entering short numbers is allowed.',
    COMPANY_FORM__ERROR__NO_ACCESS: 'You do not have access to this company',
    COMPANY_FORM__SUBMIT_CARD__SEND_TO: 'The data will be sent to:',
    COMPANY_FORM__SUBMIT_CARD__EMPTY_MAIN_CATALOGS_LIST:
        'To submit data to catalogs, link the company on the {page} page',
    COMPANY_FORM__SUBMIT_CARD__EMPTY_MAIN_CATALOGS_LIST__SOURCES: 'of sources',
    COMPANY_FORM__POPOVER__FIELD__FEW: '{count} more fields...',
    COMPANY_FORM__POPOVER__FIELD__MANY: '{count} more fields...',
    COMPANY_FORM__POPOVER__FIELD__SINGULAR: '{count} more field...',
    COMPANY_FORM__GALLERY__HINT__MIN_SIZE__TITLE: 'Min. image size',
    COMPANY_FORM__GALLERY__HINT__MIN_SIZE: '10KB',
    COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__MESSAGE:
        'Company creation completed, but there is not enough license quota.',
    COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__DESCRIPTION:
        'Not enough licenses to send data. Purchase additional licenses to enable data submission.',
    COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__BUTTON: 'Purchase license',
};
