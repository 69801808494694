import {useState} from 'react';
import {ExtractRouteParams} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';

import {TechnicalSupportContent} from './components/technical-support-content/technical-support-content';
import {TechnicalSupportCreateModal} from './components/technical-support-create-modal/technical-support-create-modal';
import {TechnicalSupportViewModal} from './components/technical-support-view-modal/technical-support-view-modal';
import * as styles from './technical-support.scss';

export function TechnicalSupport(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {ticketId} = useParams<ExtractRouteParams<typeof appRoute.helpTechnicalSupportTicket.path, string>>();

    const [isModalVisible, setIsModalVisible] = useState(Boolean(ticketId));

    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('HELP_TECHNICAL_SUPPORT__PAGE__TITLE')} />

            <PageCard>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.helpTechnicalSupport.path,
                            titleLangKey: 'CATEGORY_NAME__HELP__TECHNICAL_SUPPORT',
                        },
                    ]}
                />
                <PageHeader className={styles.TechnicalSupport_header}>
                    <Locale stringKey="HELP_TECHNICAL_SUPPORT__PAGE__TITLE" />
                    <TechnicalSupportCreateModal />
                </PageHeader>
            </PageCard>
            <PageCard>
                <TechnicalSupportContent />
            </PageCard>

            {ticketId && (
                <TechnicalSupportViewModal
                    helpRequestId={Number(ticketId)}
                    isOpen={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
        </Page>
    );
}
