import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {useLocale} from '../../../../provider/locale/locale-hook';

import {GoogleAccountSync} from './google-account-sync';
import {GoogleAccountSyncProvider} from './google-account-sync-context';

export function GoogleAccountSyncPage(): JSX.Element {
    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('GOOGLE_SYNC__TITLE')} />

            <BreadCrumbs
                list={[
                    {path: appRoute.sources.path, titleLangKey: 'CATEGORY_NAME__SOURCES'},
                    {path: appRoute.sourcesAccountSyncGoogle.path, titleLangKey: 'GOOGLE_SYNC__TITLE'},
                ]}
            />

            <GoogleAccountSyncProvider>
                <GoogleAccountSync />
            </GoogleAccountSyncProvider>
        </Page>
    );
}
