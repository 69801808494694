import {useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';

import * as styles from './widget-footer-text.scss';

type PropsType = {
    textLangKey: LangKeyType;
    linkHref: string;
    linkLangKey: LangKeyType;
    isExternalLink?: boolean;
};

export function WidgetFooterText(props: PropsType): JSX.Element {
    const {textLangKey, linkHref, linkLangKey, isExternalLink} = props;

    const link = useMemo(() => {
        if (isExternalLink) {
            return (
                <a href={linkHref} rel="noreferrer" target="_blank">
                    <Locale stringKey="CATEGORY_NAME__AUTO_REPLIES" />
                </a>
            );
        }

        return (
            <NavigationLink key={linkHref} to={linkHref}>
                <Locale stringKey={linkLangKey} />
            </NavigationLink>
        );
    }, [isExternalLink, linkHref, linkLangKey]);

    return (
        <Text block className={styles.WidgetFooterText} secondary small>
            <Locale
                stringKey={textLangKey}
                valueMap={{
                    link,
                }}
            />
        </Text>
    );
}
