import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize, getUrlParameters} from '../../util/api-adapter';

export enum ReviewsGmbCclStatusEnum {
    Correct = 'correct',
    Incorrect = 'incorrect',
    PartIncorrect = 'part_incorrect',
    Duplicate = 'duplicate',
    NotFound = 'not_found',
    ActionNeeded = 'action_needed',
    NotVerified = 'not_verified',
    WaitVerify = 'wait_verify',
    Rejected = 'rejected',
    UnsupportedRegion = 'unsupported_region',
    CreateError = 'CREATE_ERROR',
    AlreadyTied = 'ALREADY_TIED',
    WaitRights = 'wait_rights',
}

const reviewsGmbCclStatusSchema = r.record(r.string(), r.nativeEnum(ReviewsGmbCclStatusEnum));

type ReviewsGmbCclStatusType = r.infer<typeof reviewsGmbCclStatusSchema>;

function getReviewsGmbCclStatusUrl(reviewsId: Array<number>): string {
    return `/v4/reviews/gmb_ccl_statuses/${getUrlParameters({ids: reviewsId})}`;
}

async function fetchGmbCclStatus(reviewIds: Array<number>): Promise<ReviewsGmbCclStatusType> {
    return fetchAndDeserialize(getReviewsGmbCclStatusUrl(reviewIds), reviewsGmbCclStatusSchema);
}

export function useReviewsGmbCclStatuses(reviewIds: Array<number>): UseQueryResult<ReviewsGmbCclStatusType> {
    return useQuery([getReviewsGmbCclStatusUrl(reviewIds)], () => fetchGmbCclStatus(reviewIds), {
        enabled: reviewIds.length > 0,
        refetchOnWindowFocus: false,
    });
}
