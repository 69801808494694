import {Form, Input, Select, Typography} from 'antd';
import {LabeledValue} from 'antd/lib/select';

import {Locale} from '../../../../../../provider/locale/locale';
import {ShortLocaleNameEnum} from '../../../../../../provider/locale/locale-context-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {LegalOptionDataType} from '../../../../../../service/api/api-type';
import {useLegalOptionDataList} from '../../../../../../service/api-hook/api-hook';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {classNames} from '../../../../../../util/css';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import * as styles from './select-organizational-form-item.scss';

const {Text} = Typography;
const {Option} = Select;

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.legalForm> & {
    className?: string;
};

export function SelectOrganizationalFormItem(props: PropsType): JSX.Element {
    const {className, value, onChange, errorMessage} = props;
    const {getLocalizedString, shortLocaleName} = useLocale();
    const {requiredFieldRule} = useFormRules();

    // WARNING: fix shortLocaleName && 'ru'
    const {result: legalOptionDataList, isInProgress: isLegalOptionsInProgress} = useLegalOptionDataList(
        shortLocaleName && ShortLocaleNameEnum.ru
    );

    const fullClassName = classNames(styles.select_organizational, className);

    if (isLegalOptionsInProgress) {
        return (
            <Form.Item
                className={fullClassName}
                label={<Locale stringKey="GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM" />}
            >
                <Input size="large" value={getLocalizedString('LOADING_THREE_POINTS')} />
            </Form.Item>
        );
    }

    if (!legalOptionDataList || legalOptionDataList.length === 0) {
        return (
            <Form.Item
                className={fullClassName}
                key="not-initialized"
                label={<Locale stringKey="GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM" />}
                name="organizationalAndLegalForm"
                rules={[requiredFieldRule]}
            >
                <Input
                    placeholder={getLocalizedString('GENERAL_INFO__PLACEHOLDER__ORGANIZATIONAL_AND_LEGAL_FORM')}
                    size="large"
                />
            </Form.Item>
        );
    }

    function getDisplayedValue(): LabeledValue | undefined {
        if (!value || !legalOptionDataList) {
            return;
        }

        const foundOption = legalOptionDataList.find((option) => option.id === value.value);

        if (!foundOption) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return {value: foundOption.id, label: foundOption.name};
    }

    return (
        <Form.Item
            className={fullClassName}
            help={errorMessage}
            key="initialized"
            label={<Locale stringKey="GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM" />}
            rules={[requiredFieldRule]}
            validateStatus={errorMessage ? 'error' : ''}
        >
            <Select<LabeledValue | undefined>
                labelInValue
                onChange={(selected: LabeledValue | undefined) => {
                    if (!selected) {
                        return;
                    }

                    onChange(selected);
                }}
                placeholder={<Locale stringKey="GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM__SELECT" />}
                size="large"
                value={getDisplayedValue()}
            >
                <Option disabled key="no-select-data" value="">
                    <Text disabled>
                        <Locale stringKey="GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM__SELECT" />
                    </Text>
                </Option>

                {legalOptionDataList.map((legalOptionData: LegalOptionDataType): JSX.Element => {
                    const {name, id} = legalOptionData;

                    return (
                        <Option key={id} value={id}>
                            {name}
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
}
