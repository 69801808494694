import {ReactElement} from 'react';

import {danger60, green60, orange60} from '../../../../../../css/var-export.scss';
import {Locale} from '../../../../../../provider/locale/localization';
import {ImportResultInfoType} from '../../../../../../service/companies-import/companies-import-list';

export function getBadgeColor(objectWithError: ImportResultInfoType): string {
    const {internalError, validationError, foreignError, criticalError} = objectWithError;
    const errorSum = (internalError || 0) + (validationError || 0) + (foreignError || 0) + (criticalError || 0);

    if (criticalError && criticalError > 0) {
        return danger60;
    } else if (errorSum > 0) {
        return orange60;
    }

    return green60;
}

export function getErrorTooltipTitle(objectWithError: ImportResultInfoType): ReactElement {
    const {internalError, validationError, foreignError, criticalError} = objectWithError;
    const errorSum = (internalError || 0) + (validationError || 0) + (foreignError || 0) + (criticalError || 0);

    if (criticalError && criticalError > 0) {
        return <Locale stringKey="FEED__MANAGEMENT__TABLE__ERROR__HAS_CRITICAL" />;
    } else if (errorSum > 0) {
        return <Locale stringKey="FEED__MANAGEMENT__TABLE__ERROR__HAS_ERRORS" />;
    }

    return <Locale stringKey="FEED__MANAGEMENT__TABLE__ERROR__NO_ERRORS" />;
}
