import {ExtractRouteParams} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {defaultTagData, useTag} from '../../../../service/reviews/reviews-tags';
import {TagForm} from '../tag-form/tag-form';

export function TagEdit(): JSX.Element {
    const {reviewsManagementTags: reviewsManagementTagsRoute, reviewsManagementTagEdit: reviewsManagementTagEditRoute} =
        appRoute;

    const {tagId} = useParams<ExtractRouteParams<typeof reviewsManagementTagEditRoute.path, string>>();
    const {data: tagResult, isFetching: tagIsInProgress} = useTag(Number(tagId));

    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('TAGS__UPDATING')} />

            <BreadCrumbs
                list={[
                    {
                        path: reviewsManagementTagsRoute.path,
                        titleLangKey: 'CATEGORY_NAME__TAGS',
                    },
                    {
                        path: reviewsManagementTagEditRoute.path,
                        titleLangKey: 'TAGS__UPDATING',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="TAGS__UPDATING" />
            </PageHeader>

            <TagForm mode="edit" tagData={tagResult || defaultTagData} />
            <Spinner isShow={tagIsInProgress} position="absolute" />
        </Page>
    );
}
