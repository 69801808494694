import {Form} from 'antd';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {PostFormFieldsEnum, PostFormType, PostImageType} from '../../../../../../../../../service/posts/posts-types';
import {ImageContentValidationSettingsType} from '../../../content-step-type';

import {noImagesInProgressValidator} from './images-helper';
import {PostsImagesUploader} from './posts-images-uploader';

type PropsType = {
    settings: ImageContentValidationSettingsType;
    images: Array<PostImageType>;
    setImages: Dispatch<SetStateAction<Array<PostImageType>>>;
};

export function CommonImagesItem(props: PropsType): JSX.Element {
    const {settings, images, setImages} = props;

    const [hasImagesInProgress, setHasImagesInProgress] = useState<boolean>(false);
    const {getLocalizedString} = useLocale();

    useEffect(() => {
        if (images.length > 0 && !images.some((item) => item.isMain)) {
            setImages((previousImages) =>
                previousImages.map((image, index) => ({
                    ...image,
                    isMain: index === 0,
                }))
            );
        }
    }, [images, setImages]);

    return (
        <Form.Item<PostFormType>
            dependencies={[PostFormFieldsEnum.Posts]}
            label={<Locale stringKey={settings.maxCount === 1 ? 'POSTS_FORM__IMAGE' : 'POSTS_FORM__IMAGES'} />}
            rules={[
                noImagesInProgressValidator(
                    hasImagesInProgress,
                    getLocalizedString('POSTS_FORM__MEDIA_IN_PROGRESS_ERROR')
                ),
            ]}
        >
            {() => (
                <div>
                    <PostsImagesUploader
                        disabled={false}
                        onFileUploaded={(newImage) => {
                            setImages((previousImages) => [
                                ...previousImages,
                                {...newImage, name: previousImages.length},
                            ]);
                        }}
                        onRemove={(imageToRemoveIndex) => {
                            setImages((previousImages) =>
                                previousImages.filter((_image, index) => index !== imageToRemoveIndex)
                            );
                        }}
                        onSelectImageAsMain={(selectedImageIndex) => {
                            setImages((previousImages) =>
                                previousImages.map((image, index) => {
                                    return {
                                        ...image,
                                        isMain: selectedImageIndex === index,
                                    };
                                })
                            );
                        }}
                        onUploadingStatusChanged={setHasImagesInProgress}
                        settings={settings}
                        uploadedImages={images.map((image, index) => ({...image, name: index}))}
                    />
                </div>
            )}
        </Form.Item>
    );
}
