import {Button, List} from 'antd';
import {isNumber} from 'lodash';
import {useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/locale';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {classNames} from '../../../../../util/css';
import {MAX_NON_EXPANDABLE_LIST_LENGTH} from '../../../duplicates-and-fakes/settings-and-statistics/settings-tab/expandable-list/fakes-expandable-list-const';

import * as styles from './tariffs-brands-list.scss';

type PropsType = {
    list?: Array<{name: string; companyCount: number}>;
    count?: number;
};

// eslint-disable-next-line complexity
export function TariffsBrandsList(props: PropsType): JSX.Element {
    const {list, count} = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const brandList = isOpen ? list : list?.slice(0, 5);

    return (
        <div className={classNames({[styles.TariffsBrandsList]: isOpen})}>
            {list && (
                <List
                    className={styles.TariffsBrandsList_list}
                    dataSource={brandList}
                    header={
                        <List.Item className={styles.TariffsBrandsList_header}>
                            <Text bolder stringKey="TARIFFS__ALL_BRANDS" valueMap={{count}} />
                            <Text bolder stringKey="TARIFFS__COMPANIES" />
                        </List.Item>
                    }
                    renderItem={(item) => (
                        <List.Item className={styles.TariffsBrandsList_item} key={item.name}>
                            <Text ellipsis>{item.name}</Text>
                            <Text className={styles.TariffsBrandsList_brandCount}>{item.companyCount}</Text>
                        </List.Item>
                    )}
                />
            )}
            {isOpen && list && isNumber(count) && list.length < count && (
                <List.Item className={classNames(styles.TariffsBrandsList_item, styles.TariffsBrandsList_manyBrands)}>
                    <LocalePlural
                        count={list.length - count}
                        fewKey="TARIFFS__BRAND__FEW"
                        manyKey="TARIFFS__BRAND__MANY"
                        singularKey="TARIFFS__BRAND__SINGULAR"
                        valueMap={{count: count - list.length}}
                    />
                </List.Item>
            )}
            {list && isNumber(count) && MAX_NON_EXPANDABLE_LIST_LENGTH < count && (
                <Button
                    className={styles.TariffsBrandsList_item}
                    onClick={() => setIsOpen((previousState) => !previousState)}
                    type="link"
                >
                    {!isOpen ? (
                        <LocalePlural
                            count={count - MAX_NON_EXPANDABLE_LIST_LENGTH}
                            fewKey="TARIFFS__BRAND__FEW"
                            manyKey="TARIFFS__BRAND__MANY"
                            singularKey="TARIFFS__BRAND__SINGULAR"
                            valueMap={{count: count - MAX_NON_EXPANDABLE_LIST_LENGTH}}
                        />
                    ) : (
                        <Locale stringKey="COMPANY_FORM__HIDE" />
                    )}
                </Button>
            )}
        </div>
    );
}
