import {faArrowLeft, faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useEffect, useMemo, useState} from 'react';
import {ExtractRouteParams, generatePath} from 'react-router';
import {useParams} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useLocalPackGridData, useLocalPackSearchServiceConfigs} from '../../../../service/local-pack/local-pack-hook';
import {SearchQueryType, SearchServiceQueriesType} from '../../../../service/local-pack/local-pack-type';
import {objectToUrlParameters} from '../../../../util/url';

import {LocalPackCompanyInfo} from './company-info/company-info';
import {LocalPackResultsOnMap} from './results-on-map/results-on-map';
import {LocalPackResultPageServices} from './services-list/services-list';
import * as styles from './local-pack-result-page.scss';

// eslint-disable-next-line complexity
export function LocalPackResultPage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {companyId, searchQueryId: defaultSearchQueryId} =
        useParams<ExtractRouteParams<typeof appRoute.localPackResult.path, string>>();
    const onDate = new URLSearchParams(location.search).get('onDate');

    const [searchServiceId, setSearchServiceId] = useState<number | null>(null);
    const [searchQueryId, setSearchQueryId] = useState<number | null>(Number(defaultSearchQueryId) || null);

    const {
        result: searchServiceQueriesResult,
        isInProgress: queriesIsInProgress,
        processError: queriesProcessError,
    } = useLocalPackSearchServiceConfigs(Number(companyId));

    const currentSearchServiceQueries = useMemo(() => {
        return searchServiceQueriesResult
            ? searchServiceQueriesResult.find((config: SearchServiceQueriesType) => {
                  return config.id === searchServiceId;
              })
            : null;
    }, [searchServiceQueriesResult, searchServiceId]);

    const searchQuery: SearchQueryType | null =
        currentSearchServiceQueries?.queries.find((query: SearchQueryType) => {
            return query.id === searchQueryId;
        }) || null;

    const {
        result: gridResult,
        isInProgress: gridDataIsInProgress,
        processError: gridDataError,
    } = useLocalPackGridData({
        companyId: Number(companyId),
        searchQueryId,
        searchServiceId,
        targetDate: onDate,
    });

    useEffect(() => {
        if (searchServiceQueriesResult?.[0]) {
            setSearchServiceId(searchServiceQueriesResult[0].id);
        }
    }, [setSearchServiceId, searchServiceQueriesResult]);

    useEffect(() => {
        if (
            currentSearchServiceQueries &&
            currentSearchServiceQueries.queries.length > 0 &&
            !currentSearchServiceQueries.queries.some((value) => value.id === searchQueryId)
        ) {
            setSearchQueryId(currentSearchServiceQueries.queries[0].id);
        }
    }, [currentSearchServiceQueries, searchQueryId, setSearchQueryId]);

    if (queriesIsInProgress || !searchServiceQueriesResult || !searchQueryId || !searchServiceId) {
        return <Spinner />;
    }

    if (queriesProcessError || !currentSearchServiceQueries) {
        return <AlertFallback />;
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK__RESULT_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                        {
                            path: generatePath(appRoute.localPackResult.path, {
                                companyId,
                                searchQueryId: defaultSearchQueryId,
                            }),
                            titleLangKey: 'LOCAL_PACK__RESULT_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <div>
                        {onDate ? (
                            <Locale stringKey="LOCAL_PACK__RESULT_PAGE__SUB_TITLE" valueMap={{date: onDate}} />
                        ) : (
                            <Locale stringKey="LOCAL_PACK__RESULT_PAGE__SUB_TITLE_WITHOUT_DATE" />
                        )}
                    </div>
                    <div className={styles.LocalPackResultPage_navigationButtonsContainer}>
                        <NavigationLink
                            to={
                                generatePath(appRoute.localPackResult.path, {
                                    companyId,
                                    searchQueryId,
                                }) +
                                `?${objectToUrlParameters({
                                    onDate: gridResult?.previousDate,
                                })}`
                            }
                        >
                            <Button
                                className={styles.LocalPackResultPage_navigationButton}
                                disabled={!gridResult?.previousDate}
                            >
                                <div className={styles.LocalPackResultPage_navigationIconContainer}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </div>
                            </Button>
                        </NavigationLink>
                        <NavigationLink
                            to={
                                generatePath(appRoute.localPackResult.path, {
                                    companyId,
                                    searchQueryId,
                                }) +
                                `?${objectToUrlParameters({
                                    onDate: gridResult?.nextDate,
                                })}`
                            }
                        >
                            <Button
                                className={styles.LocalPackResultPage_navigationButton}
                                disabled={!gridResult?.nextDate}
                            >
                                <div className={styles.LocalPackResultPage_navigationIconContainer}>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                            </Button>
                        </NavigationLink>
                    </div>
                </PageHeader>
                <div>
                    <LocalPackCompanyInfo companyId={Number(companyId)} />
                </div>
            </PageCard>
            <PageCard>
                <LocalPackResultPageServices
                    currentSearchServiceQueries={currentSearchServiceQueries}
                    searchQueryId={searchQueryId}
                    searchServiceId={searchServiceId}
                    searchServiceQueries={searchServiceQueriesResult}
                    setSearchQueryId={setSearchQueryId}
                    setSearchServiceId={setSearchServiceId}
                />
                <div>
                    {companyId && searchQueryId && searchServiceId && (
                        <LocalPackResultsOnMap
                            data={gridResult}
                            error={gridDataError}
                            isInProgress={gridDataIsInProgress}
                            searchQuery={searchQuery ? searchQuery.query : ''}
                            searchQueryId={searchQueryId}
                        />
                    )}
                </div>
            </PageCard>
        </Page>
    );
}
