import {Button, Descriptions, Modal} from 'antd';

import {Text} from '../../../../component/text/text';
import {Locale} from '../../../../provider/locale/locale';
import {FakesCompanyInfoType} from '../../../../service/fakes/fakes-companies-info';
import {FakesResultType} from '../../../../service/fakes/fakes-results';
import {classNames} from '../../../../util/css';
import {FakesPopupCompanyInfo} from '../common/company-info/fakes-popup-company-info';

import {fakeRejectReasonLabels} from './fakes-popup-const';
import * as styles from './fakes-popup.scss';

type PropsType = {
    onCancel: () => void;
    open: boolean;
    fake: FakesResultType;
    companiesInfo?: Array<FakesCompanyInfoType>;
    className?: string;
};

export function FakesPopupRejection(props: PropsType): JSX.Element {
    const {className, open, onCancel, companiesInfo, fake} = props;

    return (
        <Modal
            className={classNames(styles.FakesPopup, className)}
            footer={[
                <Button key="ok" onClick={onCancel} type="primary">
                    <Locale stringKey="TEXT__CLOSE" />
                </Button>,
            ]}
            onCancel={onCancel}
            open={open}
            title={<Locale stringKey="FAKES__RESULTS__REJECTED_POPUP__TITLE_REJECTION" />}
        >
            <div className={styles.FakesPopup_scrollArea}>
                <h5 className={styles.FakesPopup_infoTitle}>
                    <Locale stringKey="FAKES__RESULTS__REJECTED_POPUP__LOCATION_CANNOT_BE_REMOVED" />
                </h5>
                <FakesPopupCompanyInfo
                    address={fake.address}
                    catalogId={fake.catalogId}
                    category={fake.category}
                    logoUrl={fake.logoUrl}
                    name={fake.name}
                    phone={fake.phone}
                    url={fake.url}
                />
                {companiesInfo && (
                    <>
                        <h5 className={styles.FakesPopup_infoTitle}>
                            <Locale stringKey="FAKES__RESULTS__REJECTED_POPUP__YOUR_COMPANY" />
                        </h5>
                        {companiesInfo.map((company) => (
                            <FakesPopupCompanyInfo
                                address={company.address}
                                catalogId={fake.catalogId}
                                category={company.category}
                                className={styles.FakesPopup_additionalBackground}
                                key={company.id}
                                logoUrl={company.logoUrl}
                                name={company.name}
                                phone={company.phone}
                                url={company.url}
                            />
                        ))}
                    </>
                )}
                <Descriptions bordered className={styles.FakesPopup_descriptionBlock} column={1} size="small">
                    <Descriptions.Item label={<Locale stringKey="FAKES__RESULTS__REJECTED_POPUP__STATUS__LABEL" />}>
                        <Text
                            className={styles.FakesPopup_descriptionItemText}
                            small
                            stringKey="FAKES__RESULTS__REJECTED_POPUP__STATUS__REJECTED"
                        />
                    </Descriptions.Item>
                    {fake?.rejectReason && (
                        <Descriptions.Item label={<Locale stringKey="FAKES__RESULTS__REJECTED_POPUP__CAUSE_LABEL" />}>
                            <Locale stringKey={fakeRejectReasonLabels[fake.rejectReason]} />
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </div>
        </Modal>
    );
}
