import {faBuilding, faClock, faClone, faExclamationCircle, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {Button, Divider, Space, Tag} from 'antd';
import {chunk} from 'lodash';
import {useContext} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../component/page-card-divider/main-page-card-divider';
import {PageHeaderTitle} from '../../../../component/page-header-title/page-header-title';
import {Text} from '../../../../component/text/text';
import {UsetifulNameProductEnum} from '../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {SystemContext} from '../../../../provider/system/system-context';
import {SystemContextType} from '../../../../provider/system/system-context-type';
import {useFakesStatuses} from '../../../../service/fakes/fakes-statuses';
import {FakesNextSearch} from '../common/next-search/fakes-next-search';

import {FAKES_HEADER_STATISTIC_GROUP_SIZE} from './fakes-header-const';
import {FakesHeaderStatisticValue} from './fakes-header-statistic-value';
import * as styles from './fakes-header.scss';

// eslint-disable-next-line complexity
export function FakesHeader(): JSX.Element {
    const {data: statuses, isInitialLoading} = useFakesStatuses();

    const systemContext = useContext<SystemContextType>(SystemContext);
    const {screen} = systemContext;
    const {isMobile} = screen;

    const {getLocalizedString} = useLocale();

    if (isInitialLoading) {
        return <Spinner />;
    }

    const statistics = statuses
        ? [
              ...(statuses.totalSearchCount && statuses.filtered
                  ? [
                        {
                            title: 'FAKES__RESULTS__HEADER__TOTAL_SEARCHES' as const,
                            count: statuses.totalSearchCount || 0,
                            icon: faMagnifyingGlass,
                            className: styles.FakesHeader_icon__green,
                        },
                        {
                            title: 'FAKES__RESULTS__HEADER__FILTERED_COMPANIES' as const,
                            count: statuses.filtered || 0,
                            icon: faBuilding,
                            className: styles.FakesHeader_icon__green,
                        },
                    ]
                  : []),
              {
                  title: 'FAKES__RESULTS__HEADER__NEW_DUPLICATES' as const,
                  count: statuses.newDuplicates,
                  icon: faClone,
                  className: styles.FakesHeader_icon__blue,
              },
              {
                  title: 'FAKES__RESULTS__HEADER__NEW_FAKES' as const,
                  count: statuses.newFakes,
                  icon: faClone,
                  className: styles.FakesHeader_icon__blue,
              },
              {
                  title: 'FAKES__STATUS__MODERATION' as const,
                  count: statuses.moderation,
                  icon: faClock,
                  className: styles.FakesHeader_icon__purple,
              },
              {
                  title: 'FAKES__STATUS__CLAIM_SENT__DESCRIPTION' as const,
                  count: statuses.claimSent,
                  icon: faSpinner,
                  className: styles.FakesHeader_icon__orange,
              },
              ...(statuses.needAction > 0
                  ? [
                        {
                            title: 'FAKES__STATUS__ACTION_REQUIRED__DESCRIPTION' as const,
                            count: statuses.needAction,
                            icon: faExclamationCircle,
                            className: styles.FakesHeader_icon__red,
                        },
                    ]
                  : []),
          ]
        : [];

    return (
        <PageCard>
            <Meta title={getLocalizedString('FAKES__RESULTS__TITLE')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.duplicatesAndFakes.path,
                        titleLangKey: 'FAKES__TITLE',
                    },
                ]}
            />

            <PageHeader className={styles.FakesHeader_header}>
                <div className={styles.FakesHeader_titleWrapper}>
                    <PageHeaderTitle
                        isShowProductButton={!statuses?.isTrial}
                        productName={UsetifulNameProductEnum.DUBS_AND_FAKES}
                        title="FAKES__RESULTS__TITLE"
                    />
                    {statuses?.isTrial && (
                        <Tag className={styles.FakesHeader_trialTag}>
                            <Text
                                bolder
                                className={styles.FakesHeader_tagText}
                                small
                                stringKey="FAKES__SETTINGS__PRODUCT_CONNECTED__TRIAL"
                            />
                        </Tag>
                    )}
                </div>

                <Space size="middle" wrap>
                    {!statuses?.isTrial && <FakesNextSearch />}
                    {!isMobile && !statuses?.isTrial && (
                        <Divider className={styles.FakesHeader_divider} type="vertical" />
                    )}
                    <NavigationLink to={appRoute.fakesSettings.path}>
                        <Button>
                            <Locale stringKey="FAKES__SETTINGS__TITLE" />
                        </Button>
                    </NavigationLink>
                </Space>
            </PageHeader>

            <MainPageCardDivider />

            <section className={styles.FakesHeader_statistics}>
                {chunk(statistics, FAKES_HEADER_STATISTIC_GROUP_SIZE).map((statisticGroup) => (
                    <div
                        className={styles.FakesHeader_statisticGroup}
                        key={statisticGroup.map(({title}) => title).join('')}
                    >
                        {statisticGroup.map(({title, count, icon, className}) => (
                            <FakesHeaderStatisticValue
                                className={className}
                                count={count}
                                icon={icon}
                                key={title}
                                title={title}
                            />
                        ))}
                    </div>
                ))}
            </section>
        </PageCard>
    );
}
