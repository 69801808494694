import {DomainNameEnum} from '../../../../provider/domain-config/domain-config-type';
import {ExportReportModeEnum} from '../../../../service/reviews/reviews-report';

import {ReviewsDownloadReportPopupItemType} from './reviews-download-report-popup-type';

export const reportFormId = 'report-form-id';

export function getReportDataList(domainName: string): Array<ReviewsDownloadReportPopupItemType> {
    const itemBaseMode: ReviewsDownloadReportPopupItemType = {
        mode: ExportReportModeEnum.Base,
        header: 'REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__HEADER__NEW',
        description: 'REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TEXT',
        placeholder: 'REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__PLACEHOLDER',
    };
    const itemBase1Mode: ReviewsDownloadReportPopupItemType = {
        mode: ExportReportModeEnum.Base1,
        header: 'REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__HEADER',
        description: 'REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__TEXT',
        placeholder: 'REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__PLACEHOLDER',
    };
    const itemBase2Mode: ReviewsDownloadReportPopupItemType = {
        mode: ExportReportModeEnum.Base2,
        header: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RESPONSE_STATISTICS_BY_BRAND_AND_CITY__HEADER',
        description: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RESPONSE_STATISTICS_BY_BRAND_AND_CITY__TEXT',
        placeholder: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RESPONSE_STATISTICS_BY_BRAND_AND_CITY__PLACEHOLDER',
    };
    const itemBase3Mode: ReviewsDownloadReportPopupItemType = {
        mode: ExportReportModeEnum.Base3,
        header: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_BY_SOURCE__HEADER',
        description: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_BY_SOURCE__TEXT',
        placeholder: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_BY_SOURCE__PLACEHOLDER',
    };
    const itemBase4Mode: ReviewsDownloadReportPopupItemType = {
        mode: ExportReportModeEnum.Base4,
        header: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_GOOGLE_YANDEX_GIS__HEADER',
        description: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_GOOGLE_YANDEX_GIS__TEXT',
        placeholder: 'REVIEWS__DOWNLOAD_REPORT_POPUP__RATING_STATISTICS_GOOGLE_YANDEX_GIS__PLACEHOLDER',
    };
    const itemBase5Mode: ReviewsDownloadReportPopupItemType = {
        mode: ExportReportModeEnum.Base5,
        header: 'REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__HEADER__OLD',
        description: 'REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TEXT',
        placeholder: 'REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__PLACEHOLDER',
    };

    return [
        itemBaseMode,
        itemBase5Mode,
        itemBase1Mode,
        itemBase2Mode,
        itemBase3Mode,
        ...(domainName !== DomainNameEnum.brandWizard ? [itemBase4Mode] : []),
    ];
}
