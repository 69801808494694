import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {CustomReviewError, fetchDeleteReview} from '../../../../../../../service/reviews/custom-reviews';
import {ReviewDataType} from '../../../../../../../service/reviews/reviews-type';
import * as styles from '../review.scss';

type PropsType = {
    reviewId: ReviewDataType['id'];
    onSubmitFinish: () => void;
};

export function ReviewDelete(props: PropsType): JSX.Element {
    const {reviewId, onSubmitFinish} = props;

    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const {getLocalizedString} = useLocale();

    function handleDeleteClick() {
        modal.confirm({
            title: <Locale stringKey="CUSTOM_REIVEW__DELETE_MODAL_TITLE" />,
            content: <Locale stringKey="CUSTOM_REIVEW__DELETE_MODAL_CONTENT" />,
            onOk: (closeModal) =>
                fetchDeleteReview(reviewId)
                    .then(() => {
                        closeModal();
                        onSubmitFinish();

                        snackbar.success({
                            message: <Locale stringKey="CUSTOM_REIVEW__DELETE__SUCCESS__TITLE" />,
                            description: <Locale stringKey="CUSTOM_REIVEW__DELETE__SUCCESS__DESCRIPTION" />,
                        });
                    })
                    .catch((error: unknown) => {
                        if (error instanceof CustomReviewError) {
                            snackbar.error((error as Error).message);
                        } else {
                            snackbar.error({
                                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                            });
                        }
                    }),
            okButtonProps: {danger: true, type: 'primary'},
            okText: getLocalizedString('POPUP__BUTTON__CONFIRM'),
            cancelText: getLocalizedString('POPUP__BUTTON__CANCEL'),
        });
    }

    return (
        <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_DELETE__HINT" />}>
            <button className={styles.Review_iconButton} onClick={handleDeleteClick} type="button">
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </Tooltip>
    );
}
