export enum GoogleConfirmationTabEnum {
    Single,
    Request,
}

export type GoogleConfirmationsUrlQueryType = {
    requestsTab?: string;
    status?: string;
    isMassConfirmation?: string;
};
