import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchUsedNeuralNetworkSuggestionsCount} from './used-neural-network-suggestions-count-api';
import {UsedNeuralNetworkSuggestionsCountType} from './used-neural-network-suggestions-count-type';

export function useUsedNeuralNetworkSuggestionsCount(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<UsedNeuralNetworkSuggestionsCountType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['usedNeuralNetworkSuggestionsCount', filter, mainFilterKey],
        queryFn: async () => {
            return fetchUsedNeuralNetworkSuggestionsCount(filter, mainFilterKey);
        },
    });
}
