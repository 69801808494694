import {Dispatch, SetStateAction} from 'react';
import {AnyZodObject, z as r} from 'zod';

import {ProvidersIdsEnum} from '../../util/type';

import {
    companySelectorCompanySchema,
    postCompanySelectorCompanySchema,
    SelectorApiCommonRequestType,
} from './feature-companies-api-type';
import {CompaniesSelectorFeatureEnum} from './feature-companies-const';

type IsInProgressType = {
    isInProgress: boolean;
};

export type SelectorCompanyType = r.infer<typeof companySelectorCompanySchema> & IsInProgressType;

export type PostSelectorCompanyType = r.infer<typeof postCompanySelectorCompanySchema> & IsInProgressType;

export const selectorBrandSchema = r.object({
    id: r.number(),
    name: r.string(),
});

export type SelectorBrandType = r.infer<typeof selectorBrandSchema>;

export type UseCompaniesSelectorHookPropsType = {
    feature: CompaniesSelectorFeatureEnum;
    resourceId?: string | null;
    excludeIds?: string;
    initialCompaniesCount?: number;
    companySchema?: AnyZodObject;
    selectorId?: string;
    withSourceSettingsAccounts?: boolean;
    catalogId?: number;
};

export type UseCompaniesSelectorFilterHookType = {
    companiesSelectorFilter: CompaniesSelectorFilterType;
    setCompaniesSelectorFilter: Dispatch<SetStateAction<CompaniesSelectorFilterType>>;
    filterRequestParameters: Pick<
        SelectorApiCommonRequestType,
        'company_group_ids' | 'brand_ids' | 'q' | 'ids' | 'exclude_ids' | 'selected_only'
    >;
    clearFilter: () => void;
};

export type UseCompaniesSelectorHookType<CompanyType extends SelectorCompanyType = SelectorCompanyType> = {
    selectorId: string;
    feature: string;
    filteredCount: number;
    selectedCount: number;
    mainFilterCompaniesCount: number;
    processError: Error | null;
    isInProgress: boolean;
    initializeSelector: () => void;
    companies: Array<CompanyType>;
    loadMoreCompanies: () => void;
    selectCompany: (companyId: number) => void;
    selectFiltered: () => void;
    selectAllFromTopFilter: () => Promise<void>;
    deselectCompany: (companyId: number) => void;
    deselectCompanies: (companyIds: Array<number>) => void;
    deselectFiltered: () => void;
    deselectAll: () => void;
    isAllSelected: boolean;
} & Pick<UseCompaniesSelectorFilterHookType, 'companiesSelectorFilter' | 'setCompaniesSelectorFilter' | 'clearFilter'>;

export type RestoreCompanySelectorOptionsType = {
    feature: string;
    resourceId: string;
};

const companyCatalogRatingSchema = r.object({
    catalogId: r.nativeEnum(ProvidersIdsEnum),
    ratings: r.number().array(),
    includeNoRating: r.boolean(),
});

export type CompanyRatingStateType = r.infer<typeof companyCatalogRatingSchema>;

export type CompaniesSelectorFilterType = {
    companyGroupId?: number | null;
    brandId?: number | null;
    q?: string | null;
    excludeIds?: Array<number> | null;
    ids?: Array<number> | null;
    selectedOnly?: boolean;
    ratings?: Array<CompanyRatingStateType>;
};
