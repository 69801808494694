import {faCircleCheck, faExclamationCircle, faInfoCircle, faTimesCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {App, message as globalMessage} from 'antd'; // eslint-disable-line no-restricted-imports
import {MessageType, NoticeType} from 'antd/lib/message/interface';
import {isNumber} from 'lodash';
import {ReactNode, useCallback, useEffect, useMemo} from 'react';

import {MESSAGE_TOP_PX} from './message-const';
import {MessageArgsType, MessageHookType} from './message-type';

export function useMessage(): MessageHookType {
    const {message} = App.useApp();

    const {open} = message;

    useEffect(() => {
        globalMessage.config({top: MESSAGE_TOP_PX});
    }, []);

    const showMessage = useCallback(
        ({args, type, icon}: {args: MessageArgsType; type: NoticeType; icon: ReactNode}) => {
            const [content, duration, onClose] = args;

            return open({
                duration: isNumber(duration) ? duration : undefined, // eslint-disable-line no-undefined
                content,
                onClose,
                type,
                icon,
            }) as MessageType;
        },
        [open]
    );

    const info = useCallback(
        (...args: MessageArgsType) => {
            return showMessage({
                args,
                type: 'info',
                icon: <FontAwesomeIcon className="anticon" icon={faInfoCircle} />,
            });
        },
        [showMessage]
    );

    const success = useCallback(
        (...args: MessageArgsType) => {
            return showMessage({
                args,
                type: 'success',
                icon: <FontAwesomeIcon className="anticon" icon={faCircleCheck} />,
            });
        },
        [showMessage]
    );

    const error = useCallback(
        (...args: MessageArgsType) => {
            return showMessage({
                args,
                type: 'error',
                icon: <FontAwesomeIcon className="anticon" icon={faTimesCircle} />,
            });
        },
        [showMessage]
    );

    const warning = useCallback(
        (...args: MessageArgsType) => {
            return showMessage({
                args,
                type: 'warning',
                icon: <FontAwesomeIcon className="anticon" icon={faExclamationCircle} />,
            });
        },
        [showMessage]
    );

    return useMemo(
        () => ({
            message: {info, success, error, warning},
        }),
        [error, info, success, warning]
    );
}
