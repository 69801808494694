import {PropsWithChildren} from 'react';

import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../provider/domain-config/domain-config-type';
import {Spinner} from '../../spinner/spinner';
import {PageWrapper, PageWrapperOptionalPropsType} from '../wrapper/page-wrapper';

import * as styles from './loading-page.scss';

type PropsType = PropsWithChildren<{className?: string} & PageWrapperOptionalPropsType>;

export function LoadingPage(props: PropsType): JSX.Element {
    const {titleLangKey, title} = props;

    const {LogoFull, domainName} = useDomainConfig();

    return (
        <PageWrapper title={title} titleLangKey={titleLangKey}>
            <div className={styles.LoadingPage_container}>
                <LogoFull />
                {domainName !== DomainNameEnum.rocketData && <Spinner />}
            </div>
        </PageWrapper>
    );
}
