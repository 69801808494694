import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {TimelineActionTypeEnum} from '../../../../../service/timeline-activity/timeline-activity-type';

export const timelineActionLocalizationMap: Record<TimelineActionTypeEnum, LangKeyType> = {
    [TimelineActionTypeEnum.CheckCCL]: 'DASHBOARD_PAGE__TIMELINE__ACTION__CHECK_CCL',
    [TimelineActionTypeEnum.Push]: 'DASHBOARD_PAGE__TIMELINE__ACTION__PUSH',
    [TimelineActionTypeEnum.CollectReviews]: 'DASHBOARD_PAGE__TIMELINE__ACTION__COLLECT_REVIEWS',
    [TimelineActionTypeEnum.NewPhotos]: 'DASHBOARD_PAGE__TIMELINE__ACTION__NEW_PHOTOS',
    [TimelineActionTypeEnum.PostPublished]: 'DASHBOARD_PAGE__TIMELINE__ACTION__POST_PUBLISHED',
    [TimelineActionTypeEnum.ChangeCompany]: 'DASHBOARD_PAGE__TIMELINE__ACTION__CHANGE_COMPANY',
    [TimelineActionTypeEnum.ChangeCompanyFeed]: 'DASHBOARD_PAGE__TIMELINE__ACTION__CHANGE_COMPANY_FEED',
    [TimelineActionTypeEnum.ProductsLoaded]: 'DASHBOARD_PAGE__TIMELINE__ACTION__PRODUCTS_LOADED',
    [TimelineActionTypeEnum.ProductsDeleted]: 'DASHBOARD_PAGE__TIMELINE__ACTION__PRODUCTS_DELETED',
    [TimelineActionTypeEnum.YandexGeovisor]: 'DASHBOARD_PAGE__TIMELINE__ACTION__YANDEX_GEOVISOR',
};
