import {faStar} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {classNames} from '../../../../../util/css';

import * as styles from './rate-star.scss';

type PropsType = {
    className?: string;
};

export function RateStar(props: PropsType): JSX.Element {
    const {className = ''} = props;

    return <FontAwesomeIcon className={classNames(styles.rate_star, className)} icon={faStar} />;
}
