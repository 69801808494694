import {faUserLock} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, Input} from 'antd';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../app-route';
import {Text} from '../../../../component/text/text';
import {Page} from '../../../../layout/page/page';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useMessage} from '../../../../provider/message/message-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useConnect2fa} from '../../../../service/user-2fa/user-2fa-hook';
import {useUrl} from '../../../../util/url-hook/url-hook';

import {TwoFactorDescription} from './description/two-factor-description';
import {TwoFactorQrCode} from './qr-code/two-factor-qr-code';
import {codeRules, initialValues, TWO_FACTOR_CODE_LENGTH} from './two-factor-authentication-setup-const';
import * as styles from './two-factor-authentication-setup.scss';

type FormType = {
    code: string;
};

export function TwoFactorAuthenticationSetup(): JSX.Element {
    const {authId} = useParams<ExtractRouteParams<typeof appRoute.twoFactorAuthenticationSetup.path, string>>();

    const {connect2fa, isInProgress, processError} = useConnect2fa({unauthorized: true});
    const [form] = Form.useForm<FormType>();
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {message} = useMessage();
    const {pushUrl} = useUrl();

    const error = processError?.jsonData.code?.[0];

    function handleSubmit(values: FormType) {
        const code = values.code.replace(/\D/g, '');

        if (code.length !== TWO_FACTOR_CODE_LENGTH) {
            message.error(getLocalizedString('PAGE__TWO_FACTOR_AUTHENTICATION_ENTER_SIX_DIGIT_CODE'));

            return;
        }

        connect2fa({code, authId})
            .then(() => {
                snackbar.success({
                    message: <Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__SUCCESS" />,
                    description: <Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__SUCCESS__DESCRIPTION" />,
                });

                pushUrl('/');
            })
            .catch(() => {
                snackbar.error({
                    message: <Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__ERROR" />,
                    description: <Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__ERROR__DESCRIPTION" />,
                });
            });
    }

    return (
        <Page.CenteredCard
            bodyClassName={styles.Setup_cardBody}
            className={styles.Setup}
            titleLangKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__HEADER"
        >
            <div>
                <div className={styles.Setup_iconWrapper}>
                    <FontAwesomeIcon className={styles.Setup_icon} icon={faUserLock} />
                </div>

                <h1>
                    <Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__HEADER" />
                </h1>

                <Text
                    block
                    className={styles.Setup_description}
                    lighter
                    stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__DESCRIPTION"
                />

                <TwoFactorDescription
                    inlineForm={
                        <Form form={form} initialValues={initialValues} layout="inline" onFinish={handleSubmit}>
                            <Form.Item<FormType>
                                help={error}
                                name="code"
                                rules={codeRules}
                                validateStatus={error ? 'error' : undefined} // eslint-disable-line no-undefined
                            >
                                <Input autoComplete="off" autoFocus placeholder="000000" />
                            </Form.Item>

                            <Button htmlType="submit" loading={isInProgress} type="primary">
                                <Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_SETUP__SUBMIT" />
                            </Button>
                        </Form>
                    }
                />
            </div>

            <TwoFactorQrCode />
        </Page.CenteredCard>
    );
}
