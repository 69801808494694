import {useEffect, useState} from 'react';

import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {getOnlinePresenceSummary, getPosts, getPrices, getReviewsSummary} from './company-activity-and-summary-api';
import {
    CompanyPostsType,
    CompanyPricesType,
    CompanyReviewsSummaryType,
    OnlinePresenceSummaryType,
    ReviewsSummaryOptionsType,
    UsePricesHookAdditionalType,
} from './company-activity-and-summary-type';

export function usePrices(companyId: number): UseHookType<Array<CompanyPricesType>> & UsePricesHookAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<CompanyPricesType>>();

    const [fetchPricesErrorCode, setFetchPricesErrorCode] = useState<number | null>(null);

    useEffect(() => {
        setIsInProgress(true);

        getPrices(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error.message);
                setFetchPricesErrorCode(error.statusCode);
            });
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset, fetchPricesErrorCode};
}

export function usePosts(companyId: number): UseHookType<CompanyPostsType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyPostsType>();

    useEffect(() => {
        setIsInProgress(true);

        getPosts(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error.message);
            });
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useReviewsSummary(options: ReviewsSummaryOptionsType): UseHookType<CompanyReviewsSummaryType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyReviewsSummaryType>();

    const {companyId, startDate, endDate} = options;

    useEffect(() => {
        setIsInProgress(true);

        getReviewsSummary({companyId, startDate, endDate})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error.message);
            });
    }, [companyId, startDate, endDate, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useOnlinePresenceSummary(companyId: number): UseHookType<OnlinePresenceSummaryType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<OnlinePresenceSummaryType>();

    useEffect(() => {
        setIsInProgress(true);

        getOnlinePresenceSummary(companyId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error.message);
            });
    }, [companyId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
