import {Button, Form, Input, Modal, Radio, Select, Typography} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {yandexConfirmationApi} from '../../../../../service/confirmation/confirmation-api';
import {
    ConfirmationSourcesMethodEnum,
    YandexGetCodeRequestOptionsType,
} from '../../../../../service/confirmation/confirmation-type';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {noop} from '../../../../../util/function';
import {getConfirmationMethodTranslationKey} from '../confirmations-const';

import {GetCodeFormFieldEnum, YandexConfirmationCodeFormFieldType} from './get-code-type';
import * as styles from './get-code.scss';

const {Text} = Typography;

type PropsType = {
    phones: Array<string>;
    brandId: number;
    companyId?: number;
    catalogId: number;
    onSubmitSuccess: () => void;
    buttonTextKey?: LangKeyType;
    extraInformation?: Record<string, unknown>;
    catalogType?: 'brand' | 'company';
};

export function YandexGetCode(props: PropsType): JSX.Element {
    const {
        phones,
        brandId,
        catalogType = 'company',
        catalogId,
        extraInformation,
        companyId,
        buttonTextKey = 'SOURCES_CONFIRMATION__BUTTON__GET_CODE',
        onSubmitSuccess = noop,
    } = props;

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const [form] = Form.useForm<YandexConfirmationCodeFormFieldType>();
    const {requiredFieldRule} = useFormRules();

    const [method, setMethod] = useState<ConfirmationSourcesMethodEnum>(ConfirmationSourcesMethodEnum.Sms);

    const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

    const formInitialValues: YandexConfirmationCodeFormFieldType = {
        [GetCodeFormFieldEnum.Method]: method,
        [GetCodeFormFieldEnum.Phone]: phones?.[0] || '',
    };

    async function handleSubmit() {
        setIsSendingCode(true);

        try {
            await form.validateFields();

            const formValues = form.getFieldsValue();

            const requestOptions: YandexGetCodeRequestOptionsType = {
                brand: brandId,
                catalogId,
                catalogType,
                company: companyId,
                extra: extraInformation,
                extensionPhoneCode: formValues[GetCodeFormFieldEnum.AdditionalNumber],
                contactPerson: formValues[GetCodeFormFieldEnum.ContactPerson],
                method,
                phone: formValues[GetCodeFormFieldEnum.Phone],
            };

            await yandexConfirmationApi(requestOptions);

            snackbar.success(<Locale stringKey="SOURCES_CONFIRMATION__SEND_CODE__SUCCESS" />);
            onSubmitSuccess();
            setIsModalOpen(false);
        } catch {
            snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
        } finally {
            setIsSendingCode(false);
        }
    }

    return (
        <>
            <Button
                onClick={() => {
                    setIsModalOpen(true);
                }}
                type="primary"
            >
                <Locale stringKey={buttonTextKey} />
            </Button>
            <Modal
                okButtonProps={{loading: isSendingCode}}
                okText={getLocalizedString('SOURCES_CONFIRMATION__BUTTON__GET_CODE')}
                onCancel={() => setIsModalOpen(false)}
                onOk={handleSubmit}
                open={isModalOpen}
                title={<Locale stringKey="SOURCES_CONFIRMATION__YANDEX__GET_CONFIRM_CODE_TITLE" />}
                width={530}
            >
                <div className={styles.GetCode__modal_content}>
                    <Text>
                        <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__DESCRIPTION" />
                    </Text>
                    <Text>
                        <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__SELECT_METHOD" />
                    </Text>
                    <Form form={form} initialValues={formInitialValues} layout="vertical">
                        <Form.Item<ConfirmationSourcesMethodEnum>
                            label={<Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__PHONE" />}
                            name={GetCodeFormFieldEnum.Method}
                        >
                            <Radio.Group onChange={(event) => setMethod(event.target.value)}>
                                {[ConfirmationSourcesMethodEnum.Sms, ConfirmationSourcesMethodEnum.Phone].map(
                                    (availableMethod: ConfirmationSourcesMethodEnum) => {
                                        const translationKey = getConfirmationMethodTranslationKey(availableMethod);

                                        return (
                                            <Radio.Button key={availableMethod} value={availableMethod}>
                                                {translationKey ? (
                                                    <Locale stringKey={translationKey} />
                                                ) : (
                                                    availableMethod
                                                )}
                                            </Radio.Button>
                                        );
                                    }
                                )}
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item<string>
                            label={<Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__PHONE" />}
                            name={GetCodeFormFieldEnum.Phone}
                        >
                            <Select
                                disabled={phones.length < 2}
                                placeholder={getLocalizedString('SOURCES_CONFIRMATION__GET_CODE__PHONE')}
                            >
                                {phones.map((phoneItem) => {
                                    return (
                                        <Select.Option key={phoneItem} value={phoneItem}>
                                            {phoneItem}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        {form.getFieldValue(GetCodeFormFieldEnum.Method) === ConfirmationSourcesMethodEnum.Phone && (
                            <>
                                <Form.Item
                                    label={<Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__ADDITIONAL_NUMBER" />}
                                    name={GetCodeFormFieldEnum.AdditionalNumber}
                                >
                                    <Input
                                        placeholder={getLocalizedString(
                                            'SOURCES_CONFIRMATION__GET_CODE__ADDITIONAL_NUMBER'
                                        )}
                                        type="string"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__CONTACT_PERSON" />}
                                    name={GetCodeFormFieldEnum.ContactPerson}
                                    required
                                    rules={[requiredFieldRule]}
                                >
                                    <Input
                                        placeholder={getLocalizedString(
                                            'SOURCES_CONFIRMATION__GET_CODE__CONTACT_PERSON'
                                        )}
                                        required
                                        type="string"
                                    />
                                </Form.Item>

                                <Text>
                                    <Locale stringKey="SOURCES_CONFIRMATION__GET_CODE__YANDEX__TIME_TO_CALL" />
                                </Text>
                            </>
                        )}
                    </Form>
                </div>
            </Modal>
        </>
    );
}
