import {Form, Space} from 'antd';
import {ReactElement, useMemo} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {EditUserType} from '../../../../../../../service/user-management/user-management-edit-user';
import {UsersFormKeyEnum, UsersFormType} from '../../../../../../../service/user-management/user-management-users';
import {FormInstance} from '../../../../../../../typings/antd';
import {UserRulesSelect} from '../../user-rules-select/user-rules-select';

import {useUserGroupCompanies} from './group-rules-tab-hook';
import * as styles from './group-rules-tab.scss';

type PropsType = {
    form: FormInstance<UsersFormType>;
    user?: EditUserType;
    userId?: number;
};

export function GroupRulesTab(props: PropsType): ReactElement {
    const {form, user, userId} = props;

    const {data: groups = [], isLoading} = useUserGroupCompanies(userId);
    const {requiredFieldRule} = useFormRules();
    const currentValue = Form.useWatch(UsersFormKeyEnum.Groups, form);

    const initialGroupData = useMemo(() => user?.companyGroups, [user?.companyGroups]);
    const options = useMemo(
        () =>
            groups.map((item) => {
                return {value: item.id, label: item.name, disabled: !item.isAvailable};
            }),
        [groups]
    );

    return (
        <Space
            className={styles.AccessRulesTab}
            direction="vertical"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <Form.Item
                className={styles.AccessRulesTab_select}
                name={UsersFormKeyEnum.Groups}
                rules={[requiredFieldRule]}
            >
                <UserRulesSelect
                    allSelectPlaceholder={<Locale stringKey="COMPANY__MULTISELECT__POPUP__ALL_GROUPS" />}
                    handleFormChange={(value) => form.setFieldValue(UsersFormKeyEnum.Groups, value)}
                    initialData={initialGroupData}
                    isLoading={isLoading}
                    options={options}
                    placeholder={<Locale stringKey="USERS__TABLE__COLUMN__GROUPS" />}
                    selectValue={currentValue || []}
                />
            </Form.Item>
        </Space>
    );
}
