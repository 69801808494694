import {Progress, ProgressProps} from 'antd';

import {Locale} from '../../../../../provider/locale/locale';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './company-progress.scss';

type PropsType = {
    percent: number;
};

const progressColors: ProgressProps['strokeColor'] = {
    '0%': '#D34730',
    '50%': '#FFDF11',
    '100%': '#3C845B',
};

export function CompanyProgress(props: PropsType): JSX.Element {
    const {percent} = props;
    const {getFormattedNumber} = useFormat();

    return (
        <>
            <div className={styles.CompanyProgress}>
                <Locale stringKey="COMPANY_FORM__PROGRESSBAR" />

                <div>
                    {getFormattedNumber(percent, {
                        maximumFractionDigits: 0,
                        style: 'percent',
                    })}
                </div>
            </div>
            <Progress percent={Math.round(percent * 100)} showInfo={false} strokeColor={progressColors} />
        </>
    );
}
