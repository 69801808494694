import {ReportPopupItemType} from './request-report-popup-type';

export const reportFormId = 'report-form-id';

export enum ExportReportModeEnum {
    Pdf = 'pdf',
    excel = 'excel',
}

export const defaultReportTypeOptions: Array<ReportPopupItemType> = [
    {
        mode: ExportReportModeEnum.Pdf,
        header: 'REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__PDF__OPTION_TITLE',
        description: 'REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__PDF__OPTION_TEXT',
    },
    {
        mode: ExportReportModeEnum.excel,
        header: 'REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__EXCEL__OPTION_TITLE',
        description: 'REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__EXCEL__OPTION_TEXT',
    },
];
