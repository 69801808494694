import dayjs, {Dayjs} from 'dayjs';

import {LocaleContextType} from '../provider/locale/locale-context-type';
import {LangKeyType} from '../provider/locale/translation/type';

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export type DateRangeType = {
    start: Date;
    end: Date;
};

export function getIsoYyyyMmDdString(date: Date): string {
    const dateParts = date.toISOString().split('T');

    if (!dateParts[0]) {
        throw new Error(`getIsoYyyyMmDdString received an incorrect date: ${date}`);
    }

    return dateParts[0];
}

export function isDateValid(date: Date): boolean {
    return !Number.isNaN(Number(date));
}

export function startOfDate(date: Date): Date {
    const dateCopy: Date = new Date(date.getTime());

    dateCopy.setHours(0);
    dateCopy.setMinutes(0);
    dateCopy.setSeconds(0);
    dateCopy.setMilliseconds(0);

    return dateCopy;
}

export function endOfDate(date: Date): Date {
    const dateCopy: Date = new Date(date.getTime());

    dateCopy.setHours(23);
    dateCopy.setMinutes(59);
    dateCopy.setSeconds(59);
    dateCopy.setMilliseconds(999);

    return dateCopy;
}

export function startOfUtcDay(date: Date): Date {
    const startOfUtcDayTimestamp: number = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

    return new Date(startOfUtcDayTimestamp);
}

export function endOfUtcDay(date: Date): Date {
    const startOfUtcDayTimestamp: number = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
    );

    return new Date(startOfUtcDayTimestamp);
}

export function shiftToCurrentTimezone(utcDate: Date): Date {
    return new Date(utcDate.toISOString().slice(0, -1));
}

function getCurrentTimezoneOffset(): string {
    const offsetRaw = new Date().getTimezoneOffset();

    const sign = offsetRaw < 0 ? '+' : '-';
    const hourOffsetRaw = Math.abs(offsetRaw / 60);
    const minutesOffsetRaw = Math.abs(offsetRaw % 60);

    const hoursOffset = hourOffsetRaw < 10 ? '0' + String(hourOffsetRaw) : hourOffsetRaw;

    const minutesOffset = minutesOffsetRaw < 10 ? '0' + String(minutesOffsetRaw) : minutesOffsetRaw;

    return `${sign}${hoursOffset}${minutesOffset}`;
}

export function getIsoDateWithTimezone(date: Date): string {
    const offset = getCurrentTimezoneOffset();

    return date.toISOString().replace('Z', offset);
}

export function getDateInCurrentTimezone(date: Date): Date {
    return new Date(date.getTime() + Math.abs(new Date().getTimezoneOffset() * 60 * 1000));
}

export function getCurrentTimezone(): string {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function isLastDayOfMonth(date: Date): boolean {
    const dayjsOfDate = dayjs(date);

    return dayjsOfDate.date() === dayjsOfDate.daysInMonth();
}

export function getCloserFullWeekRange(fromDate: Date = new Date()): DateRangeType {
    const dayjsFromDate = dayjs(fromDate);

    if (dayjsFromDate.day() !== 6) {
        const endDate = dayjsFromDate.subtract(dayjsFromDate.day(), 'days');

        return {
            end: endDate.toDate(),
            start: endDate.subtract(6, 'days').toDate(),
        };
    }

    return {
        start: dayjsFromDate.subtract(dayjsFromDate.day() + 6, 'days').toDate(),
        end: dayjsFromDate.subtract(dayjsFromDate.day(), 'days').toDate(),
    };
}

export function getCloserFullMonth(fromDate: Date = new Date()): DateRangeType {
    const dayjsFromDate = dayjs(fromDate);

    if (isLastDayOfMonth(fromDate)) {
        return getCloserFullMonth(dayjsFromDate.date(dayjsFromDate.date() + 1).toDate());
    }

    const endDate = dayjsFromDate.subtract(dayjsFromDate.date(), 'days');

    return {
        end: endDate.toDate(),
        start: endDate.subtract(endDate.date() - 1, 'days').toDate(),
    };
}

export function getCloserFullQuarter(fromDate: Date = new Date()): DateRangeType {
    const dayjsFromDate = dayjs(fromDate);

    if (isLastDayOfMonth(fromDate)) {
        return getCloserFullQuarter(dayjsFromDate.date(dayjsFromDate.date() + 1).toDate());
    }

    const startOfNextQuarterDayjs = dayjsFromDate
        .date(1)
        .clone()
        .month(dayjsFromDate.month() + (3 - (dayjsFromDate.month() % 3)));
    const closerFullQuarterStart = startOfNextQuarterDayjs.subtract(6, 'month');

    return {
        end: closerFullQuarterStart
            .clone()
            .month(closerFullQuarterStart.month() + 3)
            .subtract(1, 'day')
            .toDate(),
        start: closerFullQuarterStart.toDate(),
    };
}

type DateFromRangeType = {
    startOfFromDate: Date | null;
    endOfToDate: Date | null;
};

export function getDatesFromRangeToFilter(timeRange: [Dayjs | null, Dayjs | null] | null): DateFromRangeType {
    const defaultValue = {
        startOfFromDate: null,
        endOfToDate: null,
    };

    if (!timeRange) {
        return defaultValue;
    }

    const [from, to] = timeRange || [];

    if (from && to) {
        const fromDate = from.toDate();
        const toDate = to.toDate();

        const startOfFromDate: Date = new Date(
            Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0, 0)
        );
        const endOfToDate: Date = new Date(
            Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999)
        );

        return {
            startOfFromDate,
            endOfToDate,
        };
    }

    return defaultValue;
}

export function isToday(date: Date): boolean {
    return date.toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10);
}

export function getMonthNameByItsIndex(
    getLocalizedString: LocaleContextType['getLocalizedString']
): Record<string, string> {
    return {
        '0': getLocalizedString('MONTH_NAME__SHORT__JANUARY'),
        '1': getLocalizedString('MONTH_NAME__SHORT__FEBRUARY'),
        '2': getLocalizedString('MONTH_NAME__SHORT__MARCH'),
        '3': getLocalizedString('MONTH_NAME__SHORT__APRIL'),
        '4': getLocalizedString('MONTH_NAME__SHORT__MAY'),
        '5': getLocalizedString('MONTH_NAME__SHORT__JUNE'),
        '6': getLocalizedString('MONTH_NAME__SHORT__JULE'),
        '7': getLocalizedString('MONTH_NAME__SHORT__AUGUST'),
        '8': getLocalizedString('MONTH_NAME__SHORT__SEPTEMBER'),
        '9': getLocalizedString('MONTH_NAME__SHORT__OCTOBER'),
        '10': getLocalizedString('MONTH_NAME__SHORT__NOVEMBER'),
        '11': getLocalizedString('MONTH_NAME__SHORT__DECEMBER'),
    };
}

export enum DatePeriodEnum {
    Week = 'week',
    Month = 'month',
    PreviousFullMonth = 'full_month',
    Quarter = 'quarter',
    Custom = 'custom',
}

export enum DateAggregationEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month',
}

export const datePeriodTranslations: Record<DatePeriodEnum, LangKeyType> = {
    [DatePeriodEnum.Week]: 'DATE_PERIOD__WEEK',
    [DatePeriodEnum.Month]: 'DATE_PERIOD__MONTH',
    [DatePeriodEnum.PreviousFullMonth]: 'DATE_PERIOD__FULL_MONTH',
    [DatePeriodEnum.Quarter]: 'DATE_PERIOD__QUARTER',
    [DatePeriodEnum.Custom]: 'DATE_PERIOD__CUSTOM',
};

export const dateAggregationTranslations: Record<DateAggregationEnum, LangKeyType> = {
    [DateAggregationEnum.Day]: 'DATE_AGGREGATION__DAY',
    [DateAggregationEnum.Week]: 'DATE_AGGREGATION__WEEK',
    [DateAggregationEnum.Month]: 'DATE_AGGREGATION__MONTH',
};

export function getDaysBetween(startDate: Date, endDate: Date): number {
    const oneDay = 1000 * 60 * 60 * 24;
    const deltaInMs = Math.abs(startDate.getTime() - endDate.getTime());

    return Math.round(deltaInMs / oneDay);
}
