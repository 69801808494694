import {FilterDropdownProps} from 'antd/es/table/interface';

import {TableFilterInputDropdown} from '../../../../../../layout/table-filter-dropdown/table-filter-input-dropdown';
import {useLocale} from '../../../../../../provider/locale/locale-hook';

export function RatingHistoryCodeFilterDropdown(props: FilterDropdownProps): JSX.Element {
    const {getLocalizedString} = useLocale();

    return (
        <TableFilterInputDropdown
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            placeholder={getLocalizedString('REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CODE__PLACEHOLDER')}
        />
    );
}
