import {useInfiniteQuery, UseInfiniteQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {deserializeV2} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

import {reviewNotesUserSchema} from './reviews-notes';
import {reviewResponsibleUserSchema} from './reviews-responsible-users';

export type ReviewLogDataType = r.infer<typeof reviewLogDataSchema>;

const reviewLogDataSchema = r.object({
    loggedAt: r.string(),
    action: r.string(),
    type: r.string(),
    newValue: r.union([r.string(), reviewResponsibleUserSchema]),
    oldValue: r.union([r.string(), reviewResponsibleUserSchema]),
    user: reviewNotesUserSchema,
});

const fetchReviewLogsSchema = r.object({
    results: r.array(reviewLogDataSchema),
    next: r.string().nullable(),
    previous: r.string().nullable(),
});

type FetchReviewsLogsType = r.infer<typeof fetchReviewLogsSchema>;

function getReviewLogsListUrl(reviewId: number, nextPageCursor?: string | null) {
    const search = nextPageCursor ? '?cursor=' + new URL(nextPageCursor).searchParams.get('cursor') : '';

    return `${rootApiUrl}/v4/reviews/${reviewId}/logs/${search}`;
}

async function fetchReviewLogsList(reviewId: number, nextPageCursor: string | null): Promise<FetchReviewsLogsType> {
    const url = getReviewLogsListUrl(reviewId, nextPageCursor);

    const response = await fetchX<ReviewLogDataType>(url);

    return deserializeV2<FetchReviewsLogsType>(url, fetchReviewLogsSchema, response);
}

export function useReviewLogsList(reviewId: number, enabled?: boolean): UseInfiniteQueryResult<FetchReviewsLogsType> {
    return useInfiniteQuery(
        [getReviewLogsListUrl(reviewId)],
        ({pageParam: pageParameter = null}) => fetchReviewLogsList(reviewId, pageParameter),
        {
            getNextPageParam: (lastPage) => lastPage.next || null,
            enabled,
            refetchOnWindowFocus: false,
        }
    );
}
