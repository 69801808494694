import {Space} from 'antd';
import {ReactNode} from 'react';

import {CatalogLogo} from '../../../../../../../component/catalog-logo/catalog-logo';
import {classNames} from '../../../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../../../util/type';

import * as styles from './action-card.scss';

type PropsType = {
    action: ReactNode;
    title: ReactNode;
    description?: string;
    className?: string;
};

export function ActionCard(props: PropsType): JSX.Element {
    const {action, title, description, className} = props;

    return (
        <div className={classNames(styles.ActionCard, className)}>
            <CatalogLogo catalogId={ProvidersIdsEnum.google} circle />

            {description ? (
                <Space className={styles.ActionCard_text} direction="vertical" size={2}>
                    <strong className={styles.ActionCard_title}>{title}</strong>

                    <div className={styles.ActionCard_description}>{description}</div>
                </Space>
            ) : (
                <div className={styles.ActionCard_text}>{title}</div>
            )}

            {action}
        </div>
    );
}
