import {Checkbox, Form} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {useEffect, useState} from 'react';

import {CompanyMultiSelectV2} from '../../../../../../../../../../layout/company-multi-select-v2/company-multi-select-v2';
import {Locale} from '../../../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../../../provider/locale/locale-hook';
import {
    SelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../../../../../service/feature-companies/feature-companies-type';
import {accountCompanyFieldRule} from '../account-brand-tabs-helper';

import {getCompaniesSelectorAccountColumns} from './account-tab-helper';
import * as styles from './account-tab.scss';

type PropsType = {
    companiesSelector: UseCompaniesSelectorHookType<SelectorCompanyType>;
    onChangeCheckbox: (checked: boolean) => void;
    checkboxValue: boolean;
    onCompanyModalOk?: () => void;
};

export function AccountTab(props: PropsType): JSX.Element {
    const {companiesSelector, onChangeCheckbox, checkboxValue, onCompanyModalOk} = props;

    const [hasRequiredError, setHasRequiredError] = useState(false);

    const {getLocalizedString} = useLocale();

    function handleCheckbox(event: CheckboxChangeEvent) {
        onChangeCheckbox(event.target.checked);
    }

    const columns = getCompaniesSelectorAccountColumns();

    useEffect(() => {
        if (companiesSelector.selectedCount) {
            setHasRequiredError(false);
        }
    }, [companiesSelector.selectedCount]);

    return (
        <Form.Item
            className={styles.AccountTab_formItem}
            name="companies"
            rules={[accountCompanyFieldRule(companiesSelector.selectedCount, getLocalizedString, setHasRequiredError)]}
        >
            <CompanyMultiSelectV2
                className={styles.AccountTab}
                columns={columns}
                companiesSelector={companiesSelector}
                hasError={hasRequiredError}
                onOk={onCompanyModalOk}
                renderAdditionalControl={
                    <Checkbox checked={checkboxValue} className={styles.AccountTab_checkbox} onChange={handleCheckbox}>
                        <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANY__MODAL__CHECKBOX" />
                    </Checkbox>
                }
            />
        </Form.Item>
    );
}
