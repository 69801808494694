import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {classNames} from '../../../../../../../util/css';

import * as styles from './data-tab.scss';

type PropsType = {
    alt?: string;
    imageSrc?: string;
    hasErrors: boolean;
};

export function CatalogTab(props: PropsType): JSX.Element {
    const {alt, imageSrc, hasErrors} = props;

    return (
        <div className={styles.DataTab}>
            {imageSrc && alt ? (
                <img
                    alt={alt}
                    className={classNames(styles.DataTab_image, {
                        [styles.DataTab_image__error]: hasErrors,
                    })}
                    src={imageSrc}
                />
            ) : (
                <Spinner />
            )}
            {hasErrors && <FontAwesomeIcon className={styles.DataTab_errorMarker} icon={faTriangleExclamation} />}
        </div>
    );
}
