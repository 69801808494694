import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {faUserTieHair} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Form, Modal, theme} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {Text} from '../../../../../component/text/text';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';

import * as styles from './tariff-modal-wrapper.scss';

type PropsType = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    children: ReactElement;
    onOk: (option: string) => void;
    showMessage?: boolean;
    link?: string;
    tariffType?: boolean;
    title: LangKeyType;
    buttonTitle: LangKeyType;
    buttonLoading?: boolean;
    showListLink?: boolean;
};

export function TariffModalWrapper(props: PropsType): ReactElement {
    const {
        isOpen,
        setIsOpen,
        children,
        onOk,
        title,
        buttonTitle,
        tariffType,
        link,
        buttonLoading,
        showMessage = true,
    } = props;

    const [form] = Form.useForm();

    const {token} = theme.useToken();

    const {getLocalizedString} = useLocale();

    function onCancel() {
        setIsOpen(false);
    }

    async function onSubmit() {
        await form.validateFields();
        onOk(form.getFieldValue(tariffType ? 'tariffName' : 'message'));
    }

    return (
        <Modal
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button htmlType="submit" key="apply" loading={buttonLoading} onClick={onSubmit} type="primary">
                    <Locale stringKey={buttonTitle} />
                </Button>,
            ]}
            onCancel={() => setIsOpen(false)}
            open={isOpen}
            title={<Locale stringKey={title} />}
            width={530}
            zIndex={2051}
        >
            <Form className={styles.TariffModalWrapper_formWrapper} form={form} layout="vertical">
                {children}
                {showMessage && (
                    <Form.Item
                        label={<Locale stringKey="FAKES__TRIAL_LANDING__MODAL__COMMENT__LABEL" />}
                        name="message"
                    >
                        <TextArea
                            placeholder={getLocalizedString('FAKES__TRIAL_LANDING__MODAL__COMMENT__PLACEHOLDER')}
                            size="large"
                        />
                    </Form.Item>
                )}
                <Alert
                    className={styles.TariffModalWrapper_alert}
                    description={<Locale stringKey="FAKES__TRIAL_LANDING__MODAL__ALERT__DESCRIPTION" />}
                    icon={<FontAwesomeIcon height={16} icon={faUserTieHair} width={16} />}
                    showIcon
                />
                {link && (
                    <a
                        className={styles.TariffModalWrapper_link}
                        href={link}
                        onClick={() => {
                            track(AnalyticsTarget.Tariffs.ClickLink, 'View all products');
                        }}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <Text
                            bolder
                            color={token.colorPrimary}
                            inheritColor
                            stringKey={
                                tariffType
                                    ? 'TARIFFS__LINK_TITLE__SHOW_ALL_TARIFFS'
                                    : 'FAKES__TRIAL_LANDING__MODAL__LINK'
                            }
                        />
                        <FontAwesomeIcon height={16} icon={faArrowUpRightFromSquare} width={16} />
                    </a>
                )}
            </Form>
        </Modal>
    );
}
