import {Menu} from 'antd';
import {MenuInfo} from 'rc-menu/lib/interface';
import {useCallback, useEffect, useMemo} from 'react';

import {useLocale} from '../../../../provider/locale/locale-hook';
import {getEnumValue} from '../../../../util/enum';
import {debounce} from '../../../../util/function';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {getWidgetNames, WidgetKeysEnum} from '../company-form-const';

import {createAnchor, extractCurrentHash} from './navigation-helper';
import * as styles from './navigation.scss';

const DELAY = 500; // wait until all effects are completed

type PropsType = {
    widgetIds: Array<WidgetKeysEnum>;
    changeSelected: (key: WidgetKeysEnum) => Promise<void>;
    selected: WidgetKeysEnum | null;
};

export function Navigation(props: PropsType): JSX.Element {
    const {widgetIds, changeSelected, selected} = props;

    const {hash} = useUrl();
    const {getLocalizedString} = useLocale();

    const widgetNames = useMemo(() => getWidgetNames(getLocalizedString), [getLocalizedString]);

    function handleClick({key}: MenuInfo) {
        const value = getEnumValue<WidgetKeysEnum>(WidgetKeysEnum, key);

        if (value) {
            changeSelected(value);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeSelectedDelayed = useCallback(
        debounce(async (key: WidgetKeysEnum) => {
            changeSelected(key);
        }, DELAY),
        [changeSelected]
    );

    useEffect(() => {
        if (selected && hash !== selected) {
            history.replaceState(null, '', `#${selected}`); // replacing the hash with react router causes a rerender
        }
    }, [hash, selected]);

    useEffect(() => {
        const currentHash = extractCurrentHash();

        if (currentHash) {
            changeSelectedDelayed(currentHash);
        }
    }, [changeSelectedDelayed]);

    return (
        <div>
            <Menu
                className={styles.navigation}
                defaultSelectedKeys={selected ? [selected] : []}
                items={Object.entries(widgetNames)
                    .filter(([key]) => widgetIds.includes(key as WidgetKeysEnum))
                    .map(([key, names]) => ({
                        key,
                        label: (
                            <a href={createAnchor(key)}>
                                {'navigationName' in names ? names.navigationName : names.name}
                            </a>
                        ),
                    }))}
                mode="inline"
                onClick={handleClick}
                selectedKeys={selected ? [selected] : []}
            />
        </div>
    );
}
