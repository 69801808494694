import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';

import {Locale} from '../../../../../../../provider/locale/locale';

import * as styles from './post-summary.scss';

type PropsType = {
    locationsCount: number;
    sourcePostsCount: number;
};

export function PostSummaryTooltip(props: PropsType): JSX.Element | null {
    const {locationsCount, sourcePostsCount} = props;

    return (
        <Tooltip
            className={styles.PostSummaryTooltip}
            overlay={
                <div>
                    <Locale
                        stringKey="POSTS_SUBMIT_INFORMATION__PUBLICATION__TOOLTIP_TEXT"
                        valueMap={{
                            countCompanies: locationsCount - sourcePostsCount,
                            totalCompanies: locationsCount,
                        }}
                    />
                </div>
            }
            overlayClassName={styles.PostSummaryTooltip_overlay}
        >
            <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
    );
}
