import {DatePeriodEnum} from '../../../util/date';

export enum ReviewsAndAnswersFilterQueriesEnum {
    PeriodStart = 'raa_start_date',
    PeriodEnd = 'raa_end_date',
    ComparePeriodStart = 'raa_compared_start_date',
    ComparePeriodEnd = 'raa_compared_end_date',
    Period = 'raa_period',
    CompareMode = 'raa_compare_mode',
}

export type ReviewsAndAnswersFilterQueriesType = Readonly<{
    [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: string;
    [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: string;
    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: string;
    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: string;
    [ReviewsAndAnswersFilterQueriesEnum.Period]: DatePeriodEnum | '';
    [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: ReviewsAndAnswersCompareEnum | '';
}>;

export enum ReviewsAndAnswersCompareEnum {
    PreviousPeriod = 'previous',
    PreviousYear = 'previous_year',
    Custom = 'custom',
}

export type ReviewsAndAnswersFilterStateType = Readonly<{
    [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: Date;
    [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: Date;
    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: Date | null;
    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: Date | null;
    [ReviewsAndAnswersFilterQueriesEnum.Period]: DatePeriodEnum;
    [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: ReviewsAndAnswersCompareEnum | null;
}>;

export type ReviewsAndAnswersFilterDispatcherType = (action: ReviewsAndAnswersFilterUpdateActionType) => void;

export type ReviewsAndAnswersFilterHookType = {
    filter: ReviewsAndAnswersFilterStateType;
    dispatchFilter: ReviewsAndAnswersFilterDispatcherType;
    isCompareMode: boolean;
    lastAvailableAnalyticsDay: Date;
};

export enum ReviewsAndAnswersFilterActionsEnum {
    UpdateMainPeriod,
    UpdateComparePeriod,
    UpdatePeriod,
    UpdateCompareMode,
    Init,
}

export type ReviewsAndAnswersFilterUpdateActionType =
    | {
          type: ReviewsAndAnswersFilterActionsEnum.UpdateCompareMode;
          payload: ReviewsAndAnswersFilterStateType[ReviewsAndAnswersFilterQueriesEnum.CompareMode];
      }
    | {
          type: ReviewsAndAnswersFilterActionsEnum.UpdateMainPeriod;
          payload: Pick<
              ReviewsAndAnswersFilterStateType,
              ReviewsAndAnswersFilterQueriesEnum.PeriodStart | ReviewsAndAnswersFilterQueriesEnum.PeriodEnd
          >;
      }
    | {
          type: ReviewsAndAnswersFilterActionsEnum.UpdateComparePeriod;
          payload: Pick<
              ReviewsAndAnswersFilterStateType,
              | ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart
              | ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd
          > & {
              [ReviewsAndAnswersFilterQueriesEnum.CompareMode]?: ReviewsAndAnswersFilterStateType[ReviewsAndAnswersFilterQueriesEnum.CompareMode];
          };
      }
    | {
          type: ReviewsAndAnswersFilterActionsEnum.UpdatePeriod;
          payload: ReviewsAndAnswersFilterStateType[ReviewsAndAnswersFilterQueriesEnum.Period];
      }
    | {
          type: ReviewsAndAnswersFilterActionsEnum.Init;
      };
