export enum OnlinePresenceFilterPdfRowEnum {
    Main = 'main',
    Compare = 'compare',
}

export type OnlinePresenceFilterPdfDataType = {
    type: OnlinePresenceFilterPdfRowEnum;
    start: Date;
    end: Date;
};
