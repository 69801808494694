import {Empty} from 'antd';

import {DoughnutChart} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart';
import {DoughnutDataType} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import * as styles from './topic-pie-chart.scss';

type PropsType = {
    caption?: string;
    data: Array<DoughnutDataType>;
    additionalData?: Array<DoughnutDataType>;
    isInProgress: boolean;
    total?: number;
};

export function TopicPieChart(props: PropsType): JSX.Element {
    const {caption, data, isInProgress, additionalData, total = 0} = props;

    const {getFormattedNumber} = useFormat();

    if (data.length === 0 || total === 0) {
        return (
            <div className={styles.TopicPieChart}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
        );
    }

    return (
        <div className={styles.TopicPieChart}>
            <Spinner isShow={isInProgress} position="absolute" />

            <span className={styles.TopicPieChart_caption}>{caption}</span>

            <div className={styles.TopicPieChart_content}>
                <div className={styles.TopicPieChart_chart}>
                    <DoughnutChart chartOptions={{cutout: 0}} data={data} />
                </div>
                <div className={styles.TopicPieChart_legend}>
                    {data.map((item) => (
                        <div className={styles.TopicPieChart_legendItem} key={item.label}>
                            <span className={styles.TopicPieChart_legendItemTitle}>
                                <div
                                    className={styles.TopicPieChart_legendItemMarker}
                                    style={{backgroundColor: item.color}}
                                />
                                {item.label}
                            </span>
                            <span>
                                {item.value}
                                {item.value > 0 && (
                                    <span className={styles.TopicPieChart_legendItemPercent}>
                                        (
                                        {getFormattedNumber(item.value / total, {
                                            maximumFractionDigits: 0,
                                            style: 'percent',
                                        })}
                                        )
                                    </span>
                                )}
                            </span>
                        </div>
                    ))}
                    {additionalData && (
                        <div className={styles.TopicPieChart_additionalLegend}>
                            {additionalData.map((item) => (
                                <div className={styles.TopicPieChart_legendItem} key={item.label}>
                                    <span
                                        className={classNames(
                                            styles.TopicPieChart_legendItemTitle,
                                            styles.TopicPieChart_legendItemTitle__additional
                                        )}
                                    >
                                        {item.label}
                                    </span>
                                    <span>
                                        <span
                                            className={styles.TopicPieChart_legendItemPercent}
                                            style={{color: item.color}}
                                        >
                                            {getFormattedNumber(item.value)} %
                                        </span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
