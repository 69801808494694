import {NotificationTextReviewEnum} from '../../../../service/notifications-settings/notifications-settings-type';

import {NotificationsReviewsFilterType} from './notification-review-filter-type';

export const textReviewTypeList: Array<NotificationTextReviewEnum> = Object.values(NotificationTextReviewEnum);

export const notificationsReviewsFilterFields: Record<
    keyof NotificationsReviewsFilterType,
    keyof NotificationsReviewsFilterType
> = {
    catalog: 'catalog',
    ratings: 'ratings',
    type: 'type',
};
