import {CatalogInfo} from '../../../../../../../component/catalog/catalog-info';
import {Text} from '../../../../../../../component/text/text';
import {LocalePlural} from '../../../../../../../provider/locale/locale-plural';
import {ProvidersEnum} from '../../../../../../../util/type';

import {PostSummaryTooltip} from './post-summary-tooltip';
import * as styles from './post-summary.scss';

type PropsType = {
    selectedCompaniesCount: number;
    provider: ProvidersEnum;
    sourcePostsCount: number;
    sourceDisplayName?: string;
    showCompaniesCountWarning?: boolean;
};

export function PostSummaryCatalog(props: PropsType): JSX.Element {
    const {
        selectedCompaniesCount,
        provider,
        sourcePostsCount,
        sourceDisplayName,
        showCompaniesCountWarning = true,
    } = props;

    return (
        <div className={styles.PostSummaryCatalog}>
            <CatalogInfo displayName={sourceDisplayName} provider={provider} />
            <div className={styles.PostSummaryCatalog_text}>
                <Text lighter>
                    <LocalePlural
                        count={sourcePostsCount}
                        fewKey="POSTS_SUBMIT_INFORMATION__PUBLICATION__COUNT_FEW"
                        manyKey="POSTS_SUBMIT_INFORMATION__PUBLICATION__COUNT_MANY"
                        singularKey="POSTS_SUBMIT_INFORMATION__PUBLICATION__COUNT_SINGULAR"
                        valueMap={{
                            count: (
                                <span className={styles.PostSummaryCatalog_count} key={provider}>
                                    {sourcePostsCount}
                                </span>
                            ),
                        }}
                    />
                </Text>
                {showCompaniesCountWarning && (
                    <PostSummaryTooltip locationsCount={selectedCompaniesCount} sourcePostsCount={sourcePostsCount} />
                )}
            </div>
        </div>
    );
}
