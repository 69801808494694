import {Button, Col, Row} from 'antd';
import {ExtractRouteParams, generatePath, useParams} from 'react-router';

import {appRoute} from '../../../app-route';
import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {CompanyRating} from '../../../layout/company-rating/company-rating';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLicenses} from '../../../provider/license/license-hook';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {useCompany} from '../../../service/company-v2/company-hook';
import {FeaturesEnum} from '../../../service/user/user-type';
import {ErrorPage404} from '../../error/page-404/page-404';
import {AnalyticsWorkLoadPageCard} from '../dashboard/analytics-workload/analytics-workload-page';
import {CompanyInfoCompleteness} from '../dashboard/company-info-completeness/company-info-completeness';
import {SingleCompanyPrices} from '../dashboard/prices/single-company-prices';
import {TimelineActivitySingleCompany} from '../dashboard/timeline-activity/timeline-activity-single-company';

import {OnlinePresenceSummary} from './widgets/online-presence/online-presence-summary';
import {Posts} from './widgets/posts/posts';
import {ReviewsSummary} from './widgets/reviews-summary/reviews-summary';
import {SourcesSynchronization} from './widgets/sources-synchronization/sources-synchronization';
import * as styles from './company-activity-and-summary.scss';

export function CompanyActivityAndSummary(): JSX.Element {
    const {companyId} = useParams<ExtractRouteParams<typeof appRoute.companyActivityAndSummary.path, string>>();

    const {result, processError, isInProgress, fetchCompanyErrorCode} = useCompany(Number(companyId));

    const {getLocalizedString} = useLocale();

    const {licenses} = useLicenses();

    if (fetchCompanyErrorCode === 403 || fetchCompanyErrorCode === 404) {
        return <ErrorPage404 />;
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('COMPANY_ACTIVITY_AND_SUMMARY__BREADCRUMB_TEXT')} />

            <PageCard>
                <BreadCrumbs
                    list={[
                        {path: appRoute.myCompanies.path, titleLangKey: 'CATEGORY_NAME__MY_COMPANIES'},
                        {
                            path: generatePath(appRoute.companyActivityAndSummary.path, {companyId}),
                            titleLangKey: 'COMPANY_ACTIVITY_AND_SUMMARY__BREADCRUMB_TEXT',
                        },
                    ]}
                />

                <PageHeader>
                    <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__PAGE_HEADER" />

                    <Button>
                        <NavigationLink to={appRoute.myCompanies.path}>
                            <Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__PAGE_HEADER_LINK" />
                        </NavigationLink>
                    </Button>
                </PageHeader>
                <MainPageCardDivider />

                {processError && <AlertFallback />}

                {result && <CompanyInfoCompleteness companyId={Number(companyId)} permissions={result.permissions} />}
            </PageCard>

            {isInProgress && <Spinner position="absolute" />}

            {result && (
                <Row gutter={[24, 24]}>
                    <Col className={styles.CompanyActivityAndSummary_cards} lg={12} xs={24}>
                        <OnlinePresenceSummary companyId={Number(companyId)} />
                        <AnalyticsWorkLoadPageCard companyId={Number(companyId)} isDashboardWidget={false} />

                        <SingleCompanyPrices companyId={companyId} isDashboardWidget={false} />
                        {licenses[FeaturesEnum.posts]?.isActive && (
                            <PageCard title={getLocalizedString('COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__POSTS_HEADER')}>
                                <Posts />
                            </PageCard>
                        )}
                        <PageCard
                            title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__COMPANY_RATING_HEADER" />}
                        >
                            <CompanyRating companyId={Number(companyId)} />
                        </PageCard>
                        <PageCard
                            title={getLocalizedString('COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__REVIEWS_SUMMARY_HEADER')}
                        >
                            <ReviewsSummary />
                        </PageCard>
                    </Col>

                    <Col className={styles.CompanyActivityAndSummary_cards} lg={12} xs={24}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <SourcesSynchronization companyId={Number(companyId)} />
                            </Col>

                            <Col span={24}>
                                <TimelineActivitySingleCompany
                                    companyId={Number(companyId)}
                                    isDashboardWidget={false}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Page>
    );
}
