import {DefaultDoughnutChartTooltipPropsType} from '../../../../component/chart-tooltip/default-doughnut-chart-tooltip';
import {getDefaultReactDoughnutTooltipOptions} from '../../../../layout/chart/chart-const';
import {doughnutChartOptions} from '../../../../layout/chart/doughnut-chart/doughnut-chart-const';
import {ChartJsDoughnutOptionsType} from '../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {SourceOfTransitionEnum} from '../../../../service/online-presence/online-presence-type';

import * as styles from './source-of-transition.scss';

export const SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP: Record<SourceOfTransitionEnum, LangKeyType> = {
    [SourceOfTransitionEnum.DesktopSearch]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_SEARCH',
    [SourceOfTransitionEnum.MobileSearch]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_SEARCH',
    [SourceOfTransitionEnum.DesktopMap]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_MAP',
    [SourceOfTransitionEnum.MobileMap]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_MAP',

    [SourceOfTransitionEnum.Discovery]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DISCOVERY',
    [SourceOfTransitionEnum.Other]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__OTHER',
    [SourceOfTransitionEnum.Recovery]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__RECOVERY',
};

export const SOURCE_OF_TRANSITION_COLOR_MAP: Record<SourceOfTransitionEnum, string> = {
    [SourceOfTransitionEnum.DesktopSearch]: styles.SourceOfTransition__desktopSearchQueriesColor,
    [SourceOfTransitionEnum.MobileSearch]: styles.SourceOfTransition__mobileSearchQueriesColor,
    [SourceOfTransitionEnum.DesktopMap]: styles.SourceOfTransition__desktopMapQueriesColor,
    [SourceOfTransitionEnum.MobileMap]: styles.SourceOfTransition__mobileMapQueriesColor,

    [SourceOfTransitionEnum.Discovery]: styles.SourceOfTransition__nonDirectQueries,
    [SourceOfTransitionEnum.Other]: styles.SourceOfTransition__others,
    [SourceOfTransitionEnum.Recovery]: styles.SourceOfTransition__directQueries,
};

export const SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP: Record<SourceOfTransitionEnum, LangKeyType> = {
    [SourceOfTransitionEnum.DesktopSearch]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_SEARCH__SHORT',
    [SourceOfTransitionEnum.MobileSearch]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_SEARCH__SHORT',
    [SourceOfTransitionEnum.DesktopMap]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DESKTOP_MAP__SHORT',
    [SourceOfTransitionEnum.MobileMap]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__MOBILE_MAP__SHORT',

    [SourceOfTransitionEnum.Recovery]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__RECOVERY',
    [SourceOfTransitionEnum.Discovery]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__DISCOVERY',
    [SourceOfTransitionEnum.Other]: 'ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION__OTHER',
};

function tooltipLabelFormatter(label: string): string {
    return label.replace(' • ', '. ');
}

export function getSourceOfTransitionsChartOptions(
    options: Partial<ChartJsDoughnutOptionsType>,
    tooltipOptions: Omit<DefaultDoughnutChartTooltipPropsType, 'currentIndex'>
): ChartJsDoughnutOptionsType {
    return {
        ...doughnutChartOptions,
        ...options,
        plugins: {
            ...doughnutChartOptions.plugins,
            tooltip: getDefaultReactDoughnutTooltipOptions({
                ...tooltipOptions,
                labelFormatter: tooltipLabelFormatter,
            }),
        },
    };
}

export const SOURCE_OF_TRANSITION_ORDER: Record<SourceOfTransitionEnum, number> = {
    [SourceOfTransitionEnum.DesktopSearch]: 1,
    [SourceOfTransitionEnum.MobileSearch]: 2,
    [SourceOfTransitionEnum.DesktopMap]: 3,
    [SourceOfTransitionEnum.MobileMap]: 4,

    [SourceOfTransitionEnum.Recovery]: 5,
    [SourceOfTransitionEnum.Discovery]: 6,
    [SourceOfTransitionEnum.Other]: 7,
};
