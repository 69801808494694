import {Checkbox} from 'antd';
import {Dispatch, SetStateAction} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {ListHeader} from '../../../common/list-header/list-header';

import * as styles from './processing-step-list-header.scss';

type PropsType = {
    companiesLength: number;
    actionRequiredCompaniesLength: number;
    showOnlyActionRequired: boolean;
    setShowOnlyActionRequired: Dispatch<SetStateAction<boolean>>;
};

export function ProcessingStepListHeader(props: PropsType): JSX.Element {
    const {companiesLength, actionRequiredCompaniesLength, showOnlyActionRequired, setShowOnlyActionRequired} = props;

    const hasActionRequiredCompanies = actionRequiredCompaniesLength > 0;

    return (
        <ListHeader
            left={
                hasActionRequiredCompanies ? (
                    <Locale
                        stringKey="GOOGLE_SYNC__PROCESSING_STEP__LIST_HEADER__ACTION_REQUIRED"
                        valueMap={{
                            actionRequired: <Text bolder>{actionRequiredCompaniesLength}</Text>,
                            total: <Text secondary>{companiesLength}</Text>,
                        }}
                    />
                ) : (
                    <Locale
                        stringKey="TEXT__TOTAL"
                        valueMap={{
                            total: <Text bolder>{companiesLength}</Text>,
                        }}
                    />
                )
            }
            right={
                hasActionRequiredCompanies && (
                    <Checkbox
                        checked={showOnlyActionRequired}
                        className={styles.Checkbox}
                        onChange={(event) => setShowOnlyActionRequired(event.target.checked)}
                    >
                        <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__SHOW_ONLY_ACTION_REQUIRED" />
                    </Checkbox>
                )
            }
        />
    );
}
