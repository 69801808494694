import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

import {useCatalogInfo} from '../../provider/catalogs/catalogs-hook';
import {MainFilterContext} from '../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../provider/main-filter/main-filter-type';
import {serialize} from '../../util/api-adapter';
import {useDebouncedValue} from '../debounce-hook/debounce-hook';
import {getAccountListCompanyAlreadySelected} from '../source-settings/accounts/accounts-api';

import {
    addCompaniesApi,
    excludeCompaniesApi,
    generateCompaniesSelectorIdApi,
    getBrandsListApi,
    getCompaniesListApi,
    restoreCompaniesSelectorId,
} from './feature-companies-api';
import {
    companySelectorCompanySchema,
    SelectorApiCommonRequestType,
    UpdateSelectorCompaniesType,
} from './feature-companies-api-type';
import {defaultCompaniesCountPerPage} from './feature-companies-const';
import {
    CompaniesSelectorFilterType,
    SelectorBrandType,
    SelectorCompanyType,
    UseCompaniesSelectorFilterHookType,
    UseCompaniesSelectorHookPropsType,
    UseCompaniesSelectorHookType,
} from './feature-companies-type';

function useCompaniesSelectorFilter(): UseCompaniesSelectorFilterHookType {
    const [companiesSelectorFilter, setCompaniesSelectorFilter] = useState<CompaniesSelectorFilterType>({
        brandId: null,
        q: null,
        companyGroupId: null,
        excludeIds: null,
        ids: null,
        selectedOnly: false,
        ratings: [],
    });

    const clearFilter = useCallback(() => {
        setCompaniesSelectorFilter({});
    }, [setCompaniesSelectorFilter]);

    const filterRequestParameters: Pick<
        SelectorApiCommonRequestType,
        'company_group_ids' | 'brand_ids' | 'q' | 'ids' | 'exclude_ids' | 'selected_only' | 'ratings'
    > = useMemo(
        () => ({
            company_group_ids: companiesSelectorFilter.companyGroupId ? [companiesSelectorFilter.companyGroupId] : null,
            brand_ids: companiesSelectorFilter.brandId ? [companiesSelectorFilter.brandId] : null,
            q: companiesSelectorFilter.q || null,
            exclude_ids: companiesSelectorFilter.excludeIds || null,
            ids: companiesSelectorFilter.ids || null,
            selected_only: companiesSelectorFilter.selectedOnly,
            ratings: companiesSelectorFilter.ratings?.map((item) => {
                return {
                    catalog_id: item.catalogId,
                    ratings: item.ratings,
                    include_no_rating: item.includeNoRating,
                };
            }),
        }),
        [companiesSelectorFilter]
    );

    const debouncedFilterRequests = useDebouncedValue(filterRequestParameters);

    return {
        companiesSelectorFilter,
        setCompaniesSelectorFilter,
        filterRequestParameters: debouncedFilterRequests,
        clearFilter,
    };
}

// eslint-disable-next-line max-statements
export function useCompaniesSelector<CompanyType extends SelectorCompanyType>(
    options: UseCompaniesSelectorHookPropsType
): UseCompaniesSelectorHookType<CompanyType> {
    const {
        feature,
        resourceId,
        initialCompaniesCount = 0,
        selectorId: initialSelectorId = '',
        companySchema = companySelectorCompanySchema,
        withSourceSettingsAccounts,
        catalogId,
    } = options;

    const hasNextPageRef = useRef<boolean>(true);
    const nextPageTokenRef = useRef<number>(0);

    const [processError, setProcessError] = useState<Error | null>(null);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [mainFilterCompaniesCount, setMainFilterCompaniesCount] = useState<number>(0);
    const [filteredCount, setFilteredCount] = useState<number>(0);
    const [selectorId, setSelectorId] = useState<string>('');
    const [isInProgress, setIsInProgress] = useState<boolean>(true);
    const [companies, setCompanies] = useState<Array<CompanyType>>([]);
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {companiesSelectorFilter, setCompaniesSelectorFilter, filterRequestParameters, clearFilter} =
        useCompaniesSelectorFilter();

    const {getCatalogName} = useCatalogInfo();

    const withSourceSettingsAccountsSerialize = useMemo(
        () => serialize({withSourceSettingsAccounts}),
        [withSourceSettingsAccounts]
    );

    const getMainFilterCompaniesCount = useCallback(() => {
        if (mainFilterKey && selectorId) {
            getCompaniesListApi<CompanyType>({
                selectorId,
                body: {
                    count: defaultCompaniesCountPerPage,
                    filter_key: mainFilterKey,
                    token: 0,
                    ...withSourceSettingsAccountsSerialize,
                },
                companySchema,
                feature,
                useRaceFetch: false,
            })
                .then((result) => setMainFilterCompaniesCount(result.count))
                .catch(setProcessError);
        } else {
            setMainFilterCompaniesCount(0);
        }
    }, [companySchema, feature, mainFilterKey, selectorId, withSourceSettingsAccountsSerialize]);

    useEffect(() => {
        getMainFilterCompaniesCount();
    }, [getMainFilterCompaniesCount, withSourceSettingsAccounts]);

    useEffect(() => {
        setSelectedCount(initialCompaniesCount || 0);
    }, [initialCompaniesCount]);

    useEffect(() => {
        if (initialSelectorId) {
            // hack to get current selected companies count from selector
            addCompaniesApi({
                selectorId: initialSelectorId,
                body: {
                    ids: [],
                    ...withSourceSettingsAccountsSerialize,
                },
                feature,
            })
                .then(({count}) => {
                    setSelectedCount(count);
                })
                .finally(() => setSelectorId(initialSelectorId))
                .catch(setProcessError);
        }
    }, [feature, initialSelectorId, withSourceSettingsAccounts]);

    function deselectAll() {
        setIsInProgress(true);
        excludeCompaniesApi({
            selectorId,
            body: {},
            feature,
        })
            .then(() => {
                setSelectedCount(0);
                setIsAllSelected(false);

                if (companiesSelectorFilter.selectedOnly) {
                    setCompanies([]);
                    setFilteredCount(0);
                } else {
                    setCompanies(
                        companies.map((company) => {
                            return {
                                ...company,
                                selected: false,
                            };
                        })
                    );
                }
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }

    function deselectFiltered() {
        setIsInProgress(true);
        excludeCompaniesApi({
            selectorId,
            body: {...filterRequestParameters, ...withSourceSettingsAccountsSerialize},
            feature,
        })
            .then((result: UpdateSelectorCompaniesType) => {
                setSelectedCount(result.count);
                setIsAllSelected(false);

                if (companiesSelectorFilter.selectedOnly) {
                    setCompanies([]);
                    setFilteredCount(0);
                } else {
                    setCompanies(
                        companies.map((company) => {
                            return {
                                ...company,
                                selected: false,
                            };
                        })
                    );
                }
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }

    const initializeSelector = useCallback(() => {
        if (!selectorId) {
            (!resourceId
                ? generateCompaniesSelectorIdApi(feature)
                : restoreCompaniesSelectorId({
                      feature,
                      resourceId,
                  })
            )
                .then((apiResponseCompaniesSelectorIdResponseType) =>
                    setSelectorId(apiResponseCompaniesSelectorIdResponseType.uid)
                )
                .catch(setProcessError);
        }
    }, [feature, resourceId, selectorId]);

    const setCompanyInProgress = useCallback((companyId: number, isCompanyInProgress: boolean) => {
        setCompanies((previousState) => {
            return previousState.map((company) => {
                if (company.id === companyId) {
                    return {...company, isInProgress: isCompanyInProgress};
                }

                return company;
            });
        });
    }, []);

    const loadMoreCompanies = useCallback(async () => {
        if (hasNextPageRef.current && selectorId) {
            setIsInProgress(true);

            const isFirstPage = !nextPageTokenRef.current;

            const companiesResponse = await getCompaniesListApi<CompanyType>({
                selectorId,
                body: {
                    ...filterRequestParameters,
                    count: defaultCompaniesCountPerPage,
                    token: nextPageTokenRef.current,
                    ...withSourceSettingsAccountsSerialize,
                },
                companySchema,
                feature,
            });

            setFilteredCount(companiesResponse.count);
            nextPageTokenRef.current = companiesResponse.nextPageToken || 0;
            hasNextPageRef.current = companiesResponse.companies.length === defaultCompaniesCountPerPage;
            const fetchedCompanies = companiesResponse.companies.map((companyResponse) => {
                return {
                    ...companyResponse,
                    isInProgress: false,
                };
            });

            setCompanies((previousValue: Array<CompanyType>) => {
                if (isFirstPage) {
                    return fetchedCompanies;
                }

                return [...previousValue, ...fetchedCompanies];
            });

            if (withSourceSettingsAccounts && catalogId) {
                const response = await getAccountListCompanyAlreadySelected({
                    companiesIds: fetchedCompanies.map((company) => company.id),
                    catalogId,
                });

                setCompanies((previousCompanies) => {
                    return previousCompanies.map((company) => {
                        const foundItem = response.find((item) => item.company.id === company.id);

                        if (foundItem) {
                            return {
                                ...company,
                                account: {
                                    login: foundItem.account.login,
                                    catalog: getCatalogName(foundItem.catalog.id),
                                },
                            };
                        }

                        return company;
                    });
                });
            }

            setIsInProgress(false);
        }
    }, [
        getCatalogName,
        selectorId,
        filterRequestParameters,
        withSourceSettingsAccountsSerialize,
        companySchema,
        feature,
        withSourceSettingsAccounts,
        catalogId,
    ]);

    const loadFirstCompaniesPage = useCallback(() => {
        hasNextPageRef.current = true;
        nextPageTokenRef.current = 0;
        loadMoreCompanies();
    }, [loadMoreCompanies]);

    useEffect(() => {
        loadFirstCompaniesPage();
    }, [loadFirstCompaniesPage]);

    const setCompanySelected = useCallback((companyId: number, isCompanySelected: boolean) => {
        setCompanies((previousState: Array<CompanyType>) => {
            return previousState.map((company) => {
                if (company.id === companyId) {
                    return {...company, selected: isCompanySelected};
                }

                return company;
            });
        });
    }, []);

    const selectCompany = useCallback(
        (companyId: number) => {
            setCompanyInProgress(companyId, true);
            addCompaniesApi({
                selectorId,
                body: {
                    ids: [companyId],
                    ...withSourceSettingsAccountsSerialize,
                },
                feature,
            })
                .then(() => {
                    setCompanySelected(companyId, true);
                    setSelectedCount((previousCount) => previousCount + 1);
                })
                .finally(() => setCompanyInProgress(companyId, false))
                .catch(setProcessError);
        },
        [feature, selectorId, setCompanyInProgress, setCompanySelected, withSourceSettingsAccounts]
    );

    function selectFiltered() {
        setIsInProgress(true);
        addCompaniesApi({
            selectorId,
            body: {...filterRequestParameters, ...withSourceSettingsAccountsSerialize},
            feature,
        })
            .then((result: UpdateSelectorCompaniesType) => {
                setSelectedCount(result.count);
                setIsAllSelected(true);

                setCompanies((previousState) =>
                    previousState.map((company) => {
                        return {
                            ...company,
                            selected: true,
                        };
                    })
                );
            })
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }

    async function selectAllFromTopFilter(): Promise<void> {
        setIsInProgress(true);

        const result = await addCompaniesApi({
            selectorId,
            body: {
                filter_key: mainFilterKey,
                ...withSourceSettingsAccountsSerialize,
            },
            feature,
        });

        setSelectedCount(result.count);

        loadFirstCompaniesPage();
    }

    const deselectCompany = useCallback(
        (companyId: number) => {
            setCompanyInProgress(companyId, true);
            excludeCompaniesApi({
                selectorId,
                body: {
                    ids: [companyId],
                    ...withSourceSettingsAccountsSerialize,
                },
                feature,
            })
                .then(() => {
                    if (companiesSelectorFilter.selectedOnly) {
                        setFilteredCount((previousFilteredCount) => previousFilteredCount - 1);

                        setCompanies((previousCompanies) => {
                            return previousCompanies.filter((company) => company.id !== companyId);
                        });
                    } else {
                        setCompanySelected(companyId, false);
                    }

                    setSelectedCount((previousCount) => previousCount - 1);
                    setIsAllSelected(false);
                })
                .finally(() => setCompanyInProgress(companyId, false))
                .catch(setProcessError);
        },
        [
            setCompanyInProgress,
            selectorId,
            withSourceSettingsAccountsSerialize,
            feature,
            companiesSelectorFilter,
            setCompanySelected,
        ]
    );

    const deselectCompanies = useCallback(
        (companyIds: Array<number>) => {
            companyIds.forEach((companyId) => {
                setCompanyInProgress(companyId, true);
            });

            excludeCompaniesApi({
                selectorId,
                body: {
                    ids: companyIds,
                    selected_only: companiesSelectorFilter.selectedOnly,
                },
                feature,
            })
                .then(() => {
                    companyIds.forEach((companyId) => {
                        setCompanySelected(companyId, false);
                    });
                    setSelectedCount((previousCount) => previousCount - companyIds.length);
                    setIsAllSelected(false);
                })
                .finally(() => {
                    companyIds.forEach((companyId) => {
                        setCompanyInProgress(companyId, false);
                    });
                })
                .catch(setProcessError);
        },
        [companiesSelectorFilter.selectedOnly, feature, selectorId, setCompanyInProgress, setCompanySelected]
    );

    return {
        processError,
        companies,
        deselectCompany,
        deselectCompanies,
        isInProgress,
        selectCompany,
        loadMoreCompanies,
        selectedCount,
        selectFiltered,
        deselectFiltered,
        deselectAll,
        isAllSelected,
        selectorId,
        initializeSelector,
        selectAllFromTopFilter,
        companiesSelectorFilter,
        setCompaniesSelectorFilter,
        feature,
        filteredCount,
        mainFilterCompaniesCount,
        clearFilter,
    };
}

export function useCompaniesSelectorBrands(options: {
    selectorId: string;
    feature: string;
}): UseQueryResult<Array<SelectorBrandType>> {
    return useQuery(['companies-selector-brands'], () => getBrandsListApi(options), {
        enabled: Boolean(options.selectorId),
    });
}
