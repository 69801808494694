import {faBadgeCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Popover} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {YandexCompanyStatusEnum} from '../../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';
import {classNames} from '../../../../../../../util/css';

import {
    getDocumentationLink,
    yandexActualizationStatusMarkPopoverContentLangKey,
    yandexActualizationStatusMarkPopoverTitleLangKey,
} from './yandex-actualization-status-mark-helper';
import * as styles from './yandex-actualization-status-mark.scss';

type PropsType = {
    status?: YandexCompanyStatusEnum;
};

export function YandexActualizationStatusMark(props: PropsType): JSX.Element | null {
    const {status} = props;

    const {shortLocaleName} = useLocale();

    const {domainName} = useDomainConfig();

    if (!status) {
        return null;
    }

    return (
        <Popover
            className={styles.YandexActualizationStatusMark_popoverContent}
            content={
                <div className={styles.YandexActualizationStatusMark_popoverContent}>
                    <Text block stringKey={yandexActualizationStatusMarkPopoverContentLangKey[status]} />
                    <Button
                        className={styles.YandexActualizationStatusMark_popoverLink}
                        href={getDocumentationLink(shortLocaleName, domainName)}
                        target="_blank"
                        type="link"
                    >
                        <Locale stringKey="TEXT__MORE_DETAILS" />
                    </Button>
                </div>
            }
            title={
                <Text
                    block
                    bolder
                    className={styles.YandexActualizationStatusMark_popoverTitle}
                    stringKey={yandexActualizationStatusMarkPopoverTitleLangKey[status]}
                />
            }
        >
            <div
                className={classNames(styles.YandexActualizationStatusMark, {
                    [styles.YandexActualizationStatusMark__actualized]: status === YandexCompanyStatusEnum.Actualized,
                    [styles.YandexActualizationStatusMark__notActualized]:
                        status === YandexCompanyStatusEnum.NotActualized,
                    [styles.YandexActualizationStatusMark__needActualize]:
                        status === YandexCompanyStatusEnum.NeedActualize,
                })}
            >
                <FontAwesomeIcon color="inherit" icon={faBadgeCheck} />
            </div>
        </Popover>
    );
}
