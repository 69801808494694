export const enReviewsGeneratorPromoCodeTranslations = {
    REVIEW_GENERATOR__PROMO_ACTION__FORM__MAIN_SETTINGS: 'Main Settings',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__NAME_TITLE: 'Name',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__NAME_PLACEHOLDER: 'Enter promo code name',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__NAME_TOOLTIP: 'Internal name visible only to you in the personal account.',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__KIND_TITLE: 'Promo Code Type',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__KIND_PLACEHOLDER: 'Select promo code type',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__KIND__REUSABLE_DESCRIPTION:
        'A code that can be used by an unlimited number of customers.',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__KIND__DISPOSABLE: 'Disposable',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__KIND__REUSABLE: 'Reusable',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__KIND__WEBHOOK: 'Webhook',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE_TITLE: 'Type',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE_PLACEHOLDER: 'Select promo code type',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__STRING: 'Alphanumeric',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__IMAGE: 'Barcode',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__LINK: 'Link-based',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__SET: 'String codes',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__BARCODE: 'Barcode',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__QRCODE: 'QR code',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__BARCODE__DESCRIPTION:
        'The promo code will be converted to a barcode upon submission',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TYPE__QRCODE__DESCRIPTION:
        'The promo code will be converted to a QR code upon submission',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_LIST__TITLE: 'Promo Code Set',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_LIST__PLACEHOLDER: 'Enter promo code',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_LIST__TOOLTIP__TITLE: 'Batch template',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_LIST__TOOLTIP: 'Each promo code must start on a new line.',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE__TITLE: 'Promo Code Text',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE__PLACEHOLDER: 'Enter promo code',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__WEBHOOK_LINK__TITLE: 'Webhook link',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__WEBHOOK_LINK__PLACEHOLDER: 'Enter webhook link',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_LINK__TITLE: 'Promo Code Link',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_LINK__PLACEHOLDER: 'Enter promo code link',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__PROMO_CODE_IMAGE__TITLE: 'Barcode Image',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__EXPLANATION: 'Description',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__RULES__TITLE: 'Promo Code Usage Rules',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__RULES__PLACEHOLDER: 'Enter description',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__RULES__TOOLTIP__TITLE: 'Substitution Template',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__RULES__TOOLTIP__TEXT:
        'Text with promo code usage rules or a link to the rules.',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD: 'Fraud protection',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__MAX__ACTION: 'Number of bonuses per contact',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__MAX__ACTION__PLACEHOLDER: 'Enter the number of bonuses',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__MAX__LOCATION: 'Number of bonuses per location',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__MAX__LOCATION__TOOLTIP:
        'Limiting the number of bonuses sent for reviews left on a location',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__INTERVAL: 'Frequency (in days)',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__INTERVAL__PLACEHOLDER: 'Enter the frequency of days',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__INTERVAL__TOOLTIP:
        'Limiting the period of time during which the user cannot leave reviews again to receive a bonus',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__WARNING__TEXT: 'Violation text',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__WARNING__TEXT__PLACEHOLDER: 'Enter the text of the violation',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__FRAUD__WARNING__TEXT__TOOLTIP:
        'The text that will appear to the user if they re-enter contacts',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__TITLE: 'Email text',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__PLACEHOLDER: 'Enter email text',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__TOOLTIP_TITLE: 'Substitution Template',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__TOOLTIP_DESCRIPTION: '{PROMOCODE} – promo code text.',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__EMAIL_SUBJECT__INITIAL_VALUE: 'Your promo code',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__EMAIL_SUBJECT__TITLE: 'Email subject',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__EMAIL_SUBJECT__PLACEHOLDER: 'Enter email subject',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__SENDER_NAME__TITLE: 'Sender company name',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__SENDER_NAME__PLACEHOLDER: 'Enter sender company name',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__INITIAL_VALUE:
        "Thank you for your review! Your opinion is very important to us. Here's your promo code {PROMOCODE}.",
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__INITIAL_VALUE__IMAGE:
        'Thank you for your positive review! Your opinion is very important to us. Here is your promo code.',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__HAS_NO_TEMPLATE_ERROR:
        'The string must contain the "{PROMOCODE}" template',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__TEMPLATE__HAS_TEMPLATE_ERROR:
        'The string cannot contain the "{PROMOCODE}" template',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__COMPANIES__GROUP_TITLE: 'Select Companies',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__COMPANIES__TITLE: 'Companies',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__COMPANIES__TOOLTIP:
        'If no companies are selected, the promo code will be valid for all companies.',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__VALIDITY__TITLE: 'Validity Period',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__VALIDITY__UNLIMITED: 'Unlimited validity period for the promo code',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__VALIDITY__VALIDATION_ERROR: 'Please select a date in the future',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__CREATE_SUCCESS_SNACKBAR__TITLE: 'Saved',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__CREATE_SUCCESS_SNACKBAR__DESCRIPTION: 'Promo code successfully saved',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__EDIT_SUCCESS_SNACKBAR__TITLE: 'Updated',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__EDIT_SUCCESS_SNACKBAR__DESCRIPTION: 'Promo code successfully updated',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__CANCEL_CREATE_MODAL__TITLE: 'Cancel creating promo code?',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__CANCEL_CREATE_MODAL__DESCRIPTION: 'Changes will not be saved.',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__CANCEL_EDIT_MODAL__TITLE: 'Cancel editing promo code?',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__CANCEL_EDIT_MODAL__DESCRIPTION: 'Changes will not be saved.',

    REVIEW_GENERATOR__PROMO_ACTION__FORM__CREATE_ERROR_SNACKBAR__TITLE: 'Error',
    REVIEW_GENERATOR__PROMO_ACTION__FORM__CREATE_ERROR_SNACKBAR__DESCRIPTION:
        'An error occurred while saving the promo code',

    REVIEW_GENERATOR__PROMO_CODES_LIST__CELL__NAME: 'Name',
    REVIEW_GENERATOR__PROMO_CODES_LIST__CELL__KIND: 'Kind',
    REVIEW_GENERATOR__PROMO_CODES_LIST__CELL__TYPE: 'Type',
    REVIEW_GENERATOR__PROMO_CODES_LIST__CELL__UNTIL: 'Validity Period',
    REVIEW_GENERATOR__PROMO_CODES_LIST__CELL__UNTIL_VALUE: 'Until {date}',
    REVIEW_GENERATOR__PROMO_CODES_LIST__CELL__ACTIVITIES: 'Actions',
    REVIEW_GENERATOR__PROMO_CODES_LIST__UNLIMITED_CODE__TOOLTIP: 'Unlimited validity period for the promo code',
    REVIEW_GENERATOR__PROMO_CODES_LIST__TOTAL_COUNT: 'Total Promo Codes: {count}',
    REVIEW_GENERATOR__PROMO_CODES_LIST__EMPTY: 'Nothing matches the specified filters.',
    REVIEW_GENERATOR__PROMO_CODES_LIST__CREATE_BUTTON: 'Create Promo Code',
    REVIEW_GENERATOR__USED_CODES: 'Used Promo Codes',

    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__NAME: 'Name',
    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__CONTACT: 'Customer Contact',
    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__REVIEW_DATE: 'Request Date and Time',
    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__STATUS: 'Status',
    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__REVIEW_LINK: 'Review Link',
    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__REVIEW_ACTIONS: 'Actions',
    REVIEW_GENERATOR__USED_PROMO_CODES_TABLE__REVIEW_ACTION__CANCEL: 'Cancel',
    REVIEW_GENERATOR__USED_PROMO_CODES_LIST__CANCEL_MODAL__TITLE: 'Cancel Promo Code Sending',
    REVIEW_GENERATOR__USED_PROMO_CODES_LIST__CANCEL_MODAL__DESCRIPTION:
        'Are you sure you want to cancel sending the promo code?',
    REVIEW_GENERATOR__USED_PROMO_CODES_LIST__CANCEL__SUCCESS: 'Promo code sending canceled',

    REVIEW_GENERATOR__PROMO_CODE_STATUS__READY: 'Awaiting Sending',
    REVIEW_GENERATOR__PROMO_CODE_STATUS__SENT: 'Sent',
    REVIEW_GENERATOR__PROMO_CODE_STATUS__CANCELED: 'Sending Canceled',

    REVIEW_GENERATOR__PROMO_CODES_LIST__REMOVE_MODAL__TITLE: 'Promo Code Deletion',
    REVIEW_GENERATOR__PROMO_CODES_LIST__REMOVE_MODAL__DESCRIPTION: 'The promo code "{name}" will be deleted. Confirm?',
    REVIEW_GENERATOR__PROMO_CODES_LIST__REMOVE__SUCCESS: 'Promo code successfully removed',

    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW: 'Provide a promo code to the customer for a review',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__TITLE: 'Bonus for review',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__PROMO_CODE__LABEL: 'Promo Code',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__PROMO_CODE__DESCRIPTION:
        "You don't have any active promo codes. You can create them on the {pageLink} page.",
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__PROMO_CODE__PLACEHOLDER: 'Select a promo code',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__REVIEW_TYPE__LABEL: 'Promo code provided for:',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__REVIEW_TYPE__PLACEHOLDER: 'Select review type',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__TEXT__LABEL: 'Text before adding the review',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__TEXT__PLACEHOLDER: 'Enter text before adding the review',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__TEXT_THANKS__LABEL: 'Text after adding the review',
    REVIEW_GENERATOR__SEND__PROMO_CODE__FOR_REVIEW__TEXT_THANKS__PLACEHOLDER: 'Enter text after adding the review',
    REVIEW_GENERATOR__FORM__PROMO_CODE__FOR_POSITIVE_REVIEW__LABEL: 'Positive Reviews',
    REVIEW_GENERATOR__FORM__PROMO_CODE__FOR_NEGATIVE_REVIEW__LABEL: 'Negative Reviews',
    REVIEW_GENERATOR__FORM__PROMO_CODE__FOR_ANY_REVIEW__LABEL: 'All Reviews',
    REVIEW_GENERATOR__FORM__PROMO_CODE__NO_CONTACTS_INPUT_SELECTED_VALIDATION_ERROR:
        'At least one of the fields for entering user contacts must be activated to send the promo code',
};
