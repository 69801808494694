import {Space, Typography} from 'antd';
import {Dispatch, SetStateAction, useRef} from 'react';

import {PageCard} from '../../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {DATA_WIDGET_KEY_ATTR, WidgetKeysEnum} from '../../../company-form-const';
import * as companyFormStyles from '../../company-form.scss';

import {AttributesWidgetOpenButton} from './attributes-widget-open-button';
import {AttributesWidgetCatalogList} from './tabs/attributes-widget-catalog-list';
import * as styles from './attributes-widget.scss';

type PropsType = {
    name: string;
    areAttributesOpened: boolean;
    setAreAttributesOpened: Dispatch<SetStateAction<boolean>>;
    brandId?: number | null;
};

export function AttributesWidget(props: PropsType): JSX.Element {
    const {name, areAttributesOpened, setAreAttributesOpened, brandId} = props;

    const pageCardRef = useRef<HTMLDivElement | null>(null);

    const watchedMainCategory = Form.useWatch<CompanyKeyEnum.RocketdataCategories, FormInstance<CompanyFormType>, 0>([
        CompanyKeyEnum.RocketdataCategories,
        0,
    ]);

    return (
        <PageCard
            bodyClassName={companyFormStyles.SeparationContainer_formCardBody}
            ref={pageCardRef}
            title={
                areAttributesOpened ? (
                    <div className={styles.attributes_widget__title}>
                        {name}
                        <AttributesWidgetOpenButton isOpen onClick={() => setAreAttributesOpened(false)} />
                    </div>
                ) : (
                    name
                )
            }
            {...{[DATA_WIDGET_KEY_ATTR]: WidgetKeysEnum.attributes}} // eslint-disable-line react/jsx-props-no-spreading
        >
            <div className={styles.attributes_widget__description}>
                <Locale stringKey="COMPANY_FORM__ATTRIBUTES__DESCRIPTION" />
            </div>

            <AttributesWidgetCatalogList
                brandId={brandId}
                isShown={areAttributesOpened}
                renderNoCatalogsPlaceholder={(disabled) => (
                    <Space size="large">
                        <AttributesWidgetOpenButton
                            className={styles.attributes_widget__show_button}
                            disabled={!watchedMainCategory || disabled}
                            onClick={() => setAreAttributesOpened(true)}
                        />

                        {(!watchedMainCategory || disabled) && (
                            <Typography.Text type="secondary">
                                <Locale
                                    stringKey={
                                        !watchedMainCategory
                                            ? 'COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW__NO_CATEGORY'
                                            : 'COMPANY_FORM__ATTRIBUTES__BUTTON__SHOW__NO_ATTRIBUTES'
                                    }
                                />
                            </Typography.Text>
                        )}
                    </Space>
                )}
                widgetName={name}
            />
        </PageCard>
    );
}
