import {z as r} from 'zod';

import {CountryV2Type, IdNameType} from '../../api/api-type';
import {CompanyKeyEnum} from '../company-const';

export const companyAddressSchema = {
    [CompanyKeyEnum.Address]: r.object({
        lang: r.string().nullable(),
        countryId: r.number().nullable(),
        countryName: r.string(),
        countryCode: r.string().nullable(),
        regionId: r.number().nullable(),
        regionName: r.string().nullable(),
        cityId: r.number().nullable(),
        cityName: r.string().nullable(),
        street: r.string(),
        houseNumber: r.string().nullable(),
        postalCode: r.string().nullable(),
        description: r.string(),
        lat: r.number().nullable(),
        lon: r.number().nullable(),
    }),
};

const companyAddressSchemaObject = r.object(companyAddressSchema);

export type CompanyAddressType = r.infer<typeof companyAddressSchemaObject>;

export type CompanyAddressFormType = {
    [CompanyKeyEnum.Address]: {
        lang: string | null;
        country: CountryV2Type | null;
        region: IdNameType | null;
        city: IdNameType | string | null;
        street: string | null;
        houseNumber: string | null;
        postalCode: string | null;
        description: string | null;
        latLng: [number, number] | null;
    };
};

export type CompanyAddressServerType = {
    address: Omit<CompanyAddressType['address'], 'countryName' | 'countryCode' | 'lat' | 'lon'> & {
        lat: number;
        lon: number;
    };
};
