import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {useCallback, useContext} from 'react';

import {LicensesCodeEnum} from '../../page/main/tariffs-and-limits/licenses-block/license/license-const';
import {useTariffs} from '../../service/tariffs/tariffs-user-tariffs';
import {FeaturesEnum} from '../../service/user/user-type';
import {useLocale} from '../locale/locale-hook';
import {useSnackbar} from '../snackbar/snackbar-hook';

import {activateTrialLicense} from './license-api';
import {LicenseContext} from './license-context';
import {LicenseProviderType} from './license-provider-type';

export function useLicenses(): LicenseProviderType {
    return useContext<LicenseProviderType>(LicenseContext);
}

export function useActionRequiresLicense<ArgsType extends Array<unknown>, ReturnType>(
    onLicenseActive: (...args: ArgsType) => ReturnType,
    feature: FeaturesEnum
): (...args: ArgsType) => ReturnType {
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const {licenses} = useLicenses();
    const license = licenses[feature];

    const licenceIsNotPaidSnackbar = useCallback(() => {
        snackbar.error({
            message: getLocalizedString('LICENSE_SNACKBAR__NOT_ACTIVE__TITLE'),
            description: getLocalizedString('LICENSE_SNACKBAR__NOT_ACTIVE__MESSAGE'),
        });
    }, [getLocalizedString, snackbar]);

    return useCallback(
        (...args: ArgsType): ReturnType => {
            if (!license || !license.isActive) {
                licenceIsNotPaidSnackbar();
                throw new Error('license is not active');
            }

            return onLicenseActive(...args);
        },
        [licenceIsNotPaidSnackbar, license, onLicenseActive]
    );
}

export function useTrialActivation(): UseMutationResult<void, unknown, LicensesCodeEnum> {
    const {data} = useTariffs();

    const sortedActiveTariffs = (data?.results || [])
        .filter((tariff) => tariff.isActive)
        .sort((tariffA, tariffB) => {
            return new Date(tariffB.finishAt).getTime() - new Date(tariffA.finishAt).getTime();
        });

    const longestTariffId = sortedActiveTariffs[0] ? sortedActiveTariffs[0].id : 0;

    return useMutation(['trial-activation'], (licenseCode: LicensesCodeEnum) =>
        activateTrialLicense({licenseCode, tariffId: longestTariffId})
    );
}
