import {forwardRef} from 'react';

import {Text} from '../../../../component/text/text';
import {AverageResponseTimeType} from '../../../../service/reviews-and-answers-statistics/average-response-time/average-response-time-type';
import {AverageResponseTimeByCatalogType} from '../../../../service/reviews-and-answers-statistics/average-response-time-by-catalog/average-response-time-by-catalog-type';
import {AverageResponseTimeByEmployeeType} from '../../../../service/reviews-and-answers-statistics/average-response-time-by-empoyee/average-response-time-by-employee-type';
import {ComplaintsCountType} from '../../../../service/reviews-and-answers-statistics/complaints-count/complaints-count-type';
import {DeletedReviewsCountType} from '../../../../service/reviews-and-answers-statistics/deleted-reviews-count/deleted-reviews-count-type';
import {ReviewsAndAnswersFilterStateType} from '../../../../service/reviews-and-answers-statistics/filter/reviews-and-answers-statistics-filter-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {ReviewsCountByCatalogType} from '../../../../service/reviews-and-answers-statistics/reviews-count-by-catalog/reviews-count-by-catalog-type';
import {ReviewsCountByRatingType} from '../../../../service/reviews-and-answers-statistics/reviews-count-by-rating/reviews-count-by-rating-type';
import {ReviewsPopularTagType} from '../../../../service/reviews-and-answers-statistics/reviews-popular-tags/reviews-popular-tag-type';
import {ReviewsRatingByDateType} from '../../../../service/reviews-and-answers-statistics/reviews-ratings-by-date/reviews-rating-by-date-type';
import {TonalityDistributionType} from '../../../../service/reviews-and-answers-statistics/tonality-distribution/tonality-distribution-type';
import {UnansweredReviewsCountType} from '../../../../service/reviews-and-answers-statistics/unanswered-reviews-count/unanswered-reviews-count-type';
import {UsedAutoRepliesCountType} from '../../../../service/reviews-and-answers-statistics/used-auto-replies-count/used-auto-replies-count-type';
import {UsedNeuralNetworkSuggestionsCountType} from '../../../../service/reviews-and-answers-statistics/used-neural-network-suggestions-count/used-neural-network-suggestions-count-type';
import {AverageResponseTimeStats} from '../average-response-time/average-response-time-stats/average-response-time-stats';
import {CatalogsStatsTable} from '../average-response-time/catalogs-stats-table/catalogs-stats-table';
import {EmployeeStatsTable} from '../average-response-time/employee-stats-table/employee-stats-table';
import {ComplaintsCount} from '../complaints-count/complaints-count';
import {DeletedReviews} from '../deleted-reviews/deleted-reviews';
import {RatingDistributionByTimeCard} from '../rating-distribution-by-time/rating-distribution-by-time-card';
import {ReviewTagsTable} from '../review-tags-table/review-tags-table';
import {ReviewsCountByCatalog} from '../reviews-count-by-catalog/reviews-count-by-catalog';
import {ReviewsWithRating} from '../reviews-destribution/reviews-with-rating';
import {ReviewsWithoutAnswer} from '../reviews-without-answer/reviews-without-answer';
import {UsedAiSuggests} from '../used-ai-suggests/used-ai-suggests';
import {UsedAutoReplies} from '../used-auto-replies/used-auto-replies';

import {ReviewsAndAnswersFilterPdf} from './reviews-and-answers-filter-pdf';
import * as styles from './reviews-and-answers-statistics-pdf.scss';

type PropsType = {
    filter: ReviewsAndAnswersFilterStateType;
    isCompareMode: boolean;
    reviewsCountByCatalog: ReviewsCountByCatalogType | null;
    reviewsCountByRating: ReviewsCountByRatingType | null;
    reviewsRatingByDate: ReviewsRatingByDateType | null;
    tonalityDistribution: TonalityDistributionType | null;
    unansweredReviewsCount: UnansweredReviewsCountType | null;
    usedAutoRepliesCount: UsedAutoRepliesCountType | null;
    usedNeuralNetworkSuggestionsCount: UsedNeuralNetworkSuggestionsCountType | null;
    averageResponseTime: AverageResponseTimeType | null;
    averageResponseTimeByEmployee: Array<AverageResponseTimeByEmployeeType>;
    averageResponseTimeByCatalog: Array<AverageResponseTimeByCatalogType>;
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;

    complaintsCount: ComplaintsCountType | null;
    deletedReviews: DeletedReviewsCountType | null;
    topTagsStats: Array<ReviewsPopularTagType> | null;
};

export const ReviewsAndAnswersStatisticsPdfContent = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const {
        filter,
        reviewsCountByCatalog,
        unansweredReviewsCount,
        reviewsCountByRating,
        reviewsRatingByDate,
        usedAutoRepliesCount,
        usedNeuralNetworkSuggestionsCount,
        averageResponseTime,
        averageResponseTimeByEmployee,
        averageResponseTimeByCatalog,
        isCompareMode,
        timeUnit,
        deletedReviews,
        complaintsCount,
        topTagsStats,
    } = props;

    return (
        <div className={styles.PdfContainer_wrapper}>
            <div className={styles.PdfContainer} ref={ref}>
                <ReviewsAndAnswersFilterPdf filter={filter} />

                <ReviewsWithRating isCompareMode={isCompareMode} reviewsCountByRating={reviewsCountByRating} />
                <RatingDistributionByTimeCard
                    isCompareMode={isCompareMode}
                    pdfPreview
                    reviewsRatingByDate={reviewsRatingByDate}
                />

                <ReviewsCountByCatalog
                    filter={filter}
                    isCompareMode={isCompareMode}
                    pdfPreview
                    reviewsCountByCatalog={reviewsCountByCatalog}
                />

                <ReviewsWithoutAnswer isCompareMode={isCompareMode} unansweredReviewsCount={unansweredReviewsCount} />

                <UsedAutoReplies isCompareMode={isCompareMode} pdfPreview usedAutoRepliesCount={usedAutoRepliesCount} />

                <UsedAiSuggests
                    isCompareMode={isCompareMode}
                    pdfPreview
                    usedNeuralNetworkSuggestionsCount={usedNeuralNetworkSuggestionsCount}
                />

                <ComplaintsCount complaintsCount={complaintsCount || null} isCompareMode={isCompareMode} />

                <DeletedReviews deletedReviews={deletedReviews || null} isCompareMode={isCompareMode} />

                <AverageResponseTimeStats
                    averageResponseTime={averageResponseTime}
                    isCompareMode={isCompareMode}
                    timeUnit={timeUnit}
                />

                <ReviewTagsTable isCompareMode={isCompareMode} isLoading={false} topTagsStats={topTagsStats || null} />

                <Text
                    block
                    bolder
                    large
                    stringKey="REVIEW_AND_ANSWER_STATISTICS__EMPLOYEE_AVERAGE_RESPONSE_TIME__TITLE"
                />

                <EmployeeStatsTable
                    averageResponseTimeByEmployee={averageResponseTimeByEmployee}
                    isCompareMode={isCompareMode}
                    pdfPreview
                    timeUnit={timeUnit}
                />

                <Text
                    block
                    bolder
                    large
                    stringKey="REVIEW_AND_ANSWER_STATISTICS__CATALOG_AVERAGE_RESPONSE_TIME__TITLE"
                />

                <CatalogsStatsTable
                    averageResponseTimeByCatalog={averageResponseTimeByCatalog}
                    isCompareMode={isCompareMode}
                    pdfPreview
                    reviewsCountByCatalog={reviewsCountByCatalog}
                    timeUnit={timeUnit}
                />
            </div>
        </div>
    );
});

ReviewsAndAnswersStatisticsPdfContent.displayName = 'ReviewsAndAnswersStatisticsPdfContent';
