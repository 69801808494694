import {faComment, faShare, faThumbsUp} from '@fortawesome/pro-regular-svg-icons';
import {faComment as faCommentFilled, faShare as faShareFilled} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from 'antd';

import {FormattedNumber} from '../../../../../../../../../../../provider/locale/formatted-number';
import {Locale} from '../../../../../../../../../../../provider/locale/locale';

import {FACEBOOK_HEART_IMAGE_SRC, FACEBOOK_LIKE_IMAGE_SRC} from './facebook-preview-footer-helper';
import * as styles from './facebook-preview-footer.scss';

export function FacebookPreviewFooter(): JSX.Element {
    return (
        <div className={styles.FacebookPreviewFooter}>
            <div className={styles.FacebookPreviewFooter_reactions}>
                <div className={styles.FacebookPreviewFooter_likes}>
                    <img alt="" className={styles.FacebookPreviewFooter_likeImage} src={FACEBOOK_LIKE_IMAGE_SRC} />
                    <img alt="" className={styles.FacebookPreviewFooter_heartImage} src={FACEBOOK_HEART_IMAGE_SRC} />
                    <span>
                        <FormattedNumber
                            options={{
                                notation: 'compact',
                            }}
                            value={3200}
                        />
                    </span>
                </div>
                <div className={styles.FacebookPreviewFooter_replies}>
                    <div>
                        134{' '}
                        <FontAwesomeIcon className={styles.FacebookPreviewFooter_commentIcon} icon={faCommentFilled} />
                    </div>
                    <div>
                        37 <FontAwesomeIcon icon={faShareFilled} />
                    </div>
                </div>
            </div>
            <Divider className={styles.FacebookPreviewFooter_divider} />
            <div className={styles.FacebookPreviewFooter_actions}>
                <div className={styles.FacebookPreviewFooter_button}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                    <div>
                        <Locale stringKey="POSTS_FORM__PREVIEW__FACEBOOK__LIKE" />
                    </div>
                </div>
                <div className={styles.FacebookPreviewFooter_button}>
                    <FontAwesomeIcon className={styles.FacebookPreviewFooter_commentIcon} icon={faComment} />
                    <div>
                        <Locale stringKey="POSTS_FORM__PREVIEW__FACEBOOK__COMMENT" />
                    </div>
                </div>
                <div className={styles.FacebookPreviewFooter_button}>
                    <FontAwesomeIcon icon={faShare} />
                    <div>
                        <Locale stringKey="POSTS_FORM__PREVIEW__FACEBOOK__SHARE" />
                    </div>
                </div>
            </div>
        </div>
    );
}
