import {classNames} from '../../../../../../../../../util/css';

import * as styles from './company-avatar.scss';

type PropsType = {
    className?: string;
    logo?: string | null;
};

export function CompanyAvatar(props: PropsType): JSX.Element {
    const {logo, className} = props;

    return (
        <div className={classNames(styles.CompanyAvatar, className)}>
            {logo ? <img alt="" className={styles.CompanyAvatar_image} src={logo || ''} /> : <div />}
        </div>
    );
}
