import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchAverageResponseTime} from './average-response-time-api';
import {AverageResponseTimeType} from './average-response-time-type';

export function useAverageResponseTime(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<AverageResponseTimeType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['averageResponseTime', filter, mainFilterKey],
        queryFn: async () => {
            return fetchAverageResponseTime(filter, mainFilterKey);
        },
    });
}
