export const enResponseTemplatesTranslations = {
    RESPONSE_TEMPLATES__ADD_TEMPLATES: 'Add templates',
    RESPONSE_TEMPLATES__LIST_OF_TEMPLATES: 'List of templates',
    RESPONSE_TEMPLATES__TEMPLATE_TYPE__PRIVATE: 'Private',
    RESPONSE_TEMPLATES__TEMPLATE_TYPE__COMMAND: 'Command',
    RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE__HEADER: 'Template removing',
    RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE__CONTENT:
        'The {responseTemplateTitle} template(s) will be removed. Confirm?',
    RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE_GROUP__HEADER: 'Removing templates',
    RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE_GROUP__CONTENT:
        'The {responseTemplateTitle} template(s) will be removed. Confirm?',
    RESPONSE_TEMPLATES__TEMPLATE: 'Template',
    RESPONSE_TEMPLATES__GROUP: 'Group',
    RESPONSE_TEMPLATES__TYPE: 'Type',
    RESPONSE_TEMPLATES__TEMPLATE_GROUPS: 'Template groups',

    RESPONSE_TEMPLATES__CREATING_A_TEMPLATE: 'Template creating',
    RESPONSE_TEMPLATES__EDITING_A_TEMPLATE: 'Template editing',

    RESPONSE_TEMPLATES__FORM__TITLE: 'Title',
    RESPONSE_TEMPLATES__FORM__ENTER_A_NAME_FOR_THE_RULE: 'Enter a name for the rule',
    RESPONSE_TEMPLATES__FORM__TEXT: 'Text',
    RESPONSE_TEMPLATES__FORM__ENTER_A_DESCRIPTION: 'Enter a description',
    RESPONSE_TEMPLATES__FORM__GROUP: 'Group',
    RESPONSE_TEMPLATES__FORM__SUBSTITUTION_PATTERNS: 'Substitution patterns',
    RESPONSE_TEMPLATES__FORM__SUBSTITUTION_PATTERNS__CONTENT: '{% username %} - username.\n{% my_name %} - your name.',
    RESPONSE_TEMPLATES__FORM__DELETE_TEMPLATE: 'Delete template',
    RESPONSE_TEMPLATES__FORM__THE_TEMPLATE_WAS_SAVED_SUCCESSFULLY: 'The template was saved successfully.',

    RESPONSE_TEMPLATES__FORM__PUBLIC: 'Available for command',
    RESPONSE_TEMPLATES__FORM__COMMAND_TEMPLATE: 'Command Template',
    RESPONSE_TEMPLATES__FORM__TEAM_TEMPLATE_CAN_BE_USED_BY_YOUR_COLLEAGUES:
        'Team template can be used by your colleagues',

    RESPONSE_TEMPLATES__CREATING_ERROR: 'Failed to create template',
    RESPONSE_TEMPLATES__UPDATING_ERROR: 'Failed to update template',
    RESPONSE_TEMPLATES__DELETING_SUCCESS: 'Template deleted successfully',
    RESPONSE_TEMPLATES__DELETING_ERROR: 'Failed to delete template',
    RESPONSE_TEMPLATES__DELETING_USED_ERROR:
        'You are trying to delete a template that is being used in auto responses. If you need to delete a template, first exclude it from all rules.',
    RESPONSE_TEMPLATES__GROUP__ACTIONS: 'Actions',
    RESPONSE_TEMPLATES__ENTER_GROUP_NAME: 'Enter group name',
    RESPONSE_TEMPLATES__EMPTY_ERROR: 'Please fill in all fields',
};
