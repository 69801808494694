import {z as r} from 'zod';

export const companyLocationSchema = r.object({
    id: r.number(),
    rating: r.string().nullable(),
    lat: r.number().nullable(),
    lon: r.number().nullable(),
});

export type CompanyLocationType = r.infer<typeof companyLocationSchema>;

export function isCompanyLocationType(data: unknown): data is CompanyLocationType {
    return companyLocationSchema.safeParse(data).success;
}
