import {Button, Checkbox} from 'antd';
import {FilterDropdownProps} from 'antd/lib/table/interface';
import {mapValues} from 'lodash';
import {useEffect, useState} from 'react';

import {Locale} from '../../provider/locale/localization';

import {FilterDropdownListType} from './table-filter-dropdown-type';
import * as styles from './table-filter-dropdown.scss';

type PropsType = FilterDropdownProps & {
    trackConfirm?: (selectedIds: Array<string>) => void;
    showClear?: boolean;
};

export function TableFilterDropdown(props: PropsType): JSX.Element {
    const {
        clearFilters,
        confirm,
        filters: filterList,
        selectedKeys,
        setSelectedKeys,
        trackConfirm,
        showClear = true,
    } = props;

    const [filters, setFilters] = useState<FilterDropdownListType>({});

    useEffect(() => {
        const filterEntries = (filterList || []).map((filter) => [
            filter.value,
            {...filter, selected: selectedKeys.includes(filter.value.toString())},
        ]);

        setFilters(Object.fromEntries(filterEntries));
    }, [filterList, selectedKeys]);

    function handleChange(isChecked: boolean, id: string) {
        setFilters((previousFilters) => {
            const previousFilter = previousFilters[id];

            if (previousFilter) {
                return {...previousFilters, [id]: {...previousFilter, selected: isChecked}};
            }

            return previousFilters;
        });
    }

    function handleConfirm() {
        const selectedIds = Object.keys(filters).filter((item) => filters[item]?.selected);

        setSelectedKeys(selectedIds);
        confirm();

        const trackedIds = selectedIds.map((id) => filters[id]?.text?.toString()).filter(Boolean);

        if (trackedIds.length > 0) {
            trackConfirm?.(trackedIds);
        }
    }

    function selectAll() {
        setFilters(mapValues(filters, (filter) => ({...filter, selected: true})));
    }

    return (
        <div>
            <header className={styles.TableFilterDropdown_header}>
                <Button onClick={selectAll} size="small" type="link">
                    <Locale stringKey="TEXT__SELECT_ALL" />
                </Button>
            </header>

            <ul className={styles.TableFilterDropdown_list}>
                {Object.values(filters).map((filter) => (
                    <li className={styles.TableFilterDropdown_item} key={filter.value.toString()}>
                        <Checkbox
                            checked={filter.selected}
                            onChange={(event) => handleChange(event.target.checked, filter.value.toString())}
                        >
                            {filter.text}
                        </Checkbox>
                    </li>
                ))}
            </ul>

            <footer className={styles.TableFilterDropdown_footer}>
                {showClear && (
                    <Button onClick={clearFilters} size="small" type="text">
                        <Locale stringKey="TEXT__RESET" />
                    </Button>
                )}

                <Button onClick={handleConfirm} size="small" type="primary">
                    <Locale stringKey="TEXT__OK" />
                </Button>
            </footer>
        </div>
    );
}
