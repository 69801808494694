import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, DatePicker, Drawer, Row, Select, Space} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useCallback, useState} from 'react';

import {Locale} from '../../../../../provider/locale/localization';
import {useLocalPackSearchQueries} from '../../../../../service/local-pack/local-pack-hook';
import {Form} from '../../../../../typings/antd';
import {useDrawerWidthHack} from '../../../../../util/antd/drawer-hook';
import {LocalPackFilterEnum, LocalPackFilterType} from '../local-pack-filter-type';

import {filterFormId} from './local-pack-filter-form-const';
import * as styles from './local-pack-filter-form.scss';

type PropsType = {
    filter: LocalPackFilterType;
    setFilter: (filter: Partial<LocalPackFilterType>) => void;
};

export function LocalPackFilterForm(props: PropsType): JSX.Element {
    const drawerWidth: string = useDrawerWidthHack();
    const {filter, setFilter} = props;

    const [form] = Form.useForm<LocalPackFilterType>();
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const {data: searchQueries} = useLocalPackSearchQueries();

    const onFormOpen = useCallback((): void => {
        form.resetFields();
        setIsFormOpen(true);
    }, [form, setIsFormOpen]);

    const onFormClose = useCallback((): void => {
        form.resetFields();
        setIsFormOpen(false);
    }, [form, setIsFormOpen]);

    const onResetForm = useCallback((): void => {
        const emptyFormFilter: LocalPackFilterType = {
            [LocalPackFilterEnum.timeRange]: [null, null],
            [LocalPackFilterEnum.searchQueries]: [],
        };

        form.setFieldsValue(emptyFormFilter);
    }, [form]);

    const onSubmit = useCallback(
        (formValues: LocalPackFilterType): void => {
            setFilter({...formValues, timeRange: formValues.timeRange || [null, null]});
            setIsFormOpen(false);
        },
        [setFilter, setIsFormOpen]
    );

    return (
        <>
            <Button icon={<FontAwesomeIcon icon={faFilter} />} onClick={onFormOpen} />

            <Drawer
                footer={
                    <Row justify="end">
                        <Col>
                            <Space>
                                <Button onClick={onResetForm}>
                                    <Locale stringKey="TEXT__RESET" />
                                </Button>

                                <Button form={filterFormId} htmlType="submit" type="primary">
                                    <Locale stringKey="TEXT__APPLY" />
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                }
                onClose={onFormClose}
                open={isFormOpen}
                placement="right"
                title={<Locale stringKey="LOCAL_PACK__FILTER_FORM__TITLE" />}
                width={drawerWidth}
            >
                <Form<LocalPackFilterType>
                    form={form}
                    id={filterFormId}
                    initialValues={filter}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        className={styles.LocalPackFilterForm_timeRange}
                        label={<Locale stringKey="LOCAL_PACK__FILTER_FORM__INPUT__TIME_PERIOD__LABEL" />}
                        name={LocalPackFilterEnum.timeRange}
                    >
                        <DatePicker.RangePicker
                            disabledDate={(date: Dayjs) => {
                                return date.isAfter(dayjs());
                            }}
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>

                    <Form.Item
                        label={<Locale stringKey="LOCAL_PACK__FILTER_FORM__INPUT__SEARCH_QUERIES__LABEL" />}
                        name={LocalPackFilterEnum.searchQueries}
                    >
                        <Select
                            mode="multiple"
                            optionFilterProp="label"
                            options={searchQueries?.map((searchQuery) => ({
                                label: searchQuery.query,
                                value: searchQuery.id,
                            }))}
                            placeholder={<Locale stringKey="LOCAL_PACK__FILTER_FORM__INPUT__SEARCH_QUERIES__LABEL" />}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}
