import {faCheck, faEllipsis} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import {Text} from '../../../../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../../../../provider/locale/locale-hook';
import {PostPreviewDetailsType} from '../../../../../../../../../../../service/posts/posts-types';
import {CompanyAvatar} from '../../../company-avatar/company-avatar';

import * as styles from './vkontakte-preview-header.scss';

type PropsType = {
    companyInfo?: PostPreviewDetailsType;
};

export function VkontaktePreviewHeader(props: PropsType): JSX.Element {
    const {companyInfo} = props;

    const {getLocalizedString} = useLocale();

    const twoMinuteAgo = dayjs(Date.now() - 120_000).fromNow();

    return (
        <div className={styles.VkontaktePreviewHeader}>
            <div className={styles.VkontaktePreviewHeader_authorContainer}>
                <div className={styles.VkontaktePreviewHeader_avatarContainer}>
                    <CompanyAvatar logo={companyInfo?.logo} />
                </div>
                <div className={styles.VkontaktePreviewHeader_text}>
                    <Text bold className={styles.VkontaktePreviewHeader_name} ellipsis>
                        {companyInfo?.name || getLocalizedString('POSTS_FORM__PREVIEW__YANDEX__COMPANY_NAME')}
                        <FontAwesomeIcon className={styles.VkontaktePreviewHeader_nameIcon} icon={faCheck} />
                    </Text>
                    <Text className={styles.VkontaktePreviewHeader_date}>{twoMinuteAgo}</Text>
                </div>
            </div>
            <FontAwesomeIcon className={styles.VkontaktePreviewHeader_action} icon={faEllipsis} />
        </div>
    );
}
