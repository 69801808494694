import {
    GoogleConfirmationRequestMethodEnum,
    GoogleConfirmationRequestStatusEnum,
} from '../../../../../../../../service/google/confirmation/google-confirmation-type';

export const requestMethodNames = {
    [GoogleConfirmationRequestMethodEnum.Mass]: 'GOOGLE_SYNC__CONFIRMATIONS__METHOD__MASS',
} as const;

export const dateOfChangeStatuses = [
    GoogleConfirmationRequestStatusEnum.Pending,
    GoogleConfirmationRequestStatusEnum.Completed,
];
