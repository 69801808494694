import {useCallback, useMemo} from 'react';

import {useUrl} from '../../../util/url-hook/url-hook';

import {
    getCompareModeState,
    getFilterStateFromUrlQuery,
    serializeCompetitorDrawerFilters,
    serializePeriodDrawerFilters,
    serializeSingleDrawerFilters,
} from './reviews-analysis-helper';
import {
    ComparePeriodFilterRequestType,
    CompetitorFilterRequestType,
    ReviewsAnalysisFilterType,
    ReviewsCompareEnum,
    SingleFilterRequestType,
    UseReviewsAnalysisFilterType,
} from './reviews-analysis-type';

export function useReviewsAnalysisFilter(isTopicsAllowed = true): UseReviewsAnalysisFilterType {
    const {queries, replaceQuery} = useUrl<ReviewsAnalysisFilterType>();
    const filter: ReviewsAnalysisFilterType = useMemo<ReviewsAnalysisFilterType>(
        (): ReviewsAnalysisFilterType => getFilterStateFromUrlQuery(queries),
        [queries]
    );
    const compareMode: ReviewsCompareEnum = useMemo<ReviewsCompareEnum>((): ReviewsCompareEnum => {
        return getCompareModeState(filter);
    }, [filter]);
    const singleFilter: SingleFilterRequestType = useMemo<SingleFilterRequestType>(
        (): SingleFilterRequestType => serializeSingleDrawerFilters(filter, isTopicsAllowed),
        [filter, isTopicsAllowed]
    );
    const comparePeriodFilter: ComparePeriodFilterRequestType = useMemo<ComparePeriodFilterRequestType>(
        (): ComparePeriodFilterRequestType => serializePeriodDrawerFilters(filter),
        [filter]
    );
    const competitorFilter: CompetitorFilterRequestType = useMemo<CompetitorFilterRequestType>(
        (): CompetitorFilterRequestType => serializeCompetitorDrawerFilters(filter),
        [filter]
    );
    const setFilter = useCallback(
        (filterPartial: Partial<ReviewsAnalysisFilterType>): void => {
            replaceQuery(filterPartial);
        },
        [replaceQuery]
    );

    return {filter, singleFilter, comparePeriodFilter, competitorFilter, setFilter, queries, compareMode};
}
