import * as companyActivityAndSummaryStyles from '../../page/main/company-activity-and-summary/company-activity-and-summary.scss';
import {ProvidersEnum} from '../type';

const KNOWN_CATALOGS_COLORS: Record<
    ProvidersEnum.google | ProvidersEnum.yandex | ProvidersEnum.doubleGis | string,
    string
> = {
    [ProvidersEnum.google]: companyActivityAndSummaryStyles.purple_chart_color,
    [ProvidersEnum.yandex]: companyActivityAndSummaryStyles.coral_chart_color,
    [ProvidersEnum.doubleGis]: companyActivityAndSummaryStyles.grass_chart_color,
};

const COLORS_BY_INDEX: Record<string, string> = {
    '0': companyActivityAndSummaryStyles.chart_color_0,
    '1': companyActivityAndSummaryStyles.chart_color_1,
    '2': companyActivityAndSummaryStyles.chart_color_2,
    '3': companyActivityAndSummaryStyles.chart_color_3,
    '4': companyActivityAndSummaryStyles.chart_color_4,
    '5': companyActivityAndSummaryStyles.chart_color_5,
    '6': companyActivityAndSummaryStyles.chart_color_6,
    '7': companyActivityAndSummaryStyles.chart_color_7,
    '8': companyActivityAndSummaryStyles.chart_color_8,
    '9': companyActivityAndSummaryStyles.chart_color_9,
    '10': companyActivityAndSummaryStyles.chart_color_10,
    '11': companyActivityAndSummaryStyles.chart_color_11,
    '12': companyActivityAndSummaryStyles.chart_color_12,
    '13': companyActivityAndSummaryStyles.chart_color_13,
    '14': companyActivityAndSummaryStyles.chart_color_14,
    '15': companyActivityAndSummaryStyles.chart_color_15,
    '16': companyActivityAndSummaryStyles.chart_color_16,
    '17': companyActivityAndSummaryStyles.chart_color_17,
    '18': companyActivityAndSummaryStyles.chart_color_18,
    '19': companyActivityAndSummaryStyles.chart_color_19,
};

export function getDatasetColor(index: number, catalogName = ''): string {
    const colorsCount = Object.keys(COLORS_BY_INDEX).length;

    return (KNOWN_CATALOGS_COLORS[catalogName] || COLORS_BY_INDEX[index % colorsCount]) ?? '';
}
