import {NumberFormatOptionsType} from '../../util/format';
import {useFormat} from '../../util/format-hook/format-hook';

type PropsType = {
    value: number;
    options?: NumberFormatOptionsType;
};

export function FormattedNumber(props: PropsType): JSX.Element {
    const {value, options} = props;

    const {getFormattedNumber} = useFormat();

    return <>{getFormattedNumber(value, options)}</>;
}
