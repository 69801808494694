import {faBalanceScale, faInfoCircle, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Popover, Radio, Select} from 'antd';
import dayjs from 'dayjs';
import {useMemo} from 'react';

import {DateRangePicker} from '../../../../component/date-range-picker/date-range-picker';
import {Text} from '../../../../component/text/text';
import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {SEARCH_PHRASES_DELAY_DAYS} from '../../../../service/online-presence/online-presence-const';
import {
    getAnalyticsRangeByPeriodAndDate,
    getLastAvailableAnalyticsDay,
} from '../../../../service/online-presence/online-presence-helper';
import {
    OnlinePresenceCompareV2Enum,
    OnlinePresenceFilterActionsEnum,
    OnlinePresenceFilterDispatcherType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';
import {classNames} from '../../../../util/css';
import {
    DateAggregationEnum,
    dateAggregationTranslations,
    DatePeriodEnum,
    datePeriodTranslations,
} from '../../../../util/date';
import {getEnumValueEnsure} from '../../../../util/enum';

import {
    AnalyticsDatePeriodMap,
    getOnlinePresencePeriodOptions,
    getPeriodForAnalytics,
} from './online-presence-filter-helper';
import {onlinePresenceCompareModeTranslationMap} from './online-presence-filter-v2-const';
import * as styles from './online-presence-filter-v2.scss';

const {Option} = Select;

type PropsType = {
    phrasesFilterMode?: boolean;
    filter: OnlinePresenceV2FilterStateType;
    dispatchFilter: OnlinePresenceFilterDispatcherType;
    isDisabled: boolean;
    withoutCompare?: boolean;
};

// eslint-disable-next-line complexity
export function OnlinePresenceFilterV2(props: PropsType): JSX.Element {
    const {filter, dispatchFilter, isDisabled, phrasesFilterMode = false, withoutCompare} = props;

    const {getLocalizedString} = useLocale();

    const {
        [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: periodStart,
        [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: periodEnd,
        [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: comparePeriodStart,
        [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: comparePeriodEnd,
        [OnlinePresenceV2FilterQueriesEnum.Period]: period,
        [OnlinePresenceV2FilterQueriesEnum.Aggregation]: aggregation,
        [OnlinePresenceV2FilterQueriesEnum.CompareMode]: compareMode,
    } = filter;

    const lastDayAnalyticsAvailableDayjs = useMemo(() => {
        if (phrasesFilterMode) {
            const lastPhrasesDataMonthRange = getAnalyticsRangeByPeriodAndDate(
                getLastAvailableAnalyticsDay(SEARCH_PHRASES_DELAY_DAYS),
                DatePeriodEnum.PreviousFullMonth
            );

            return dayjs(lastPhrasesDataMonthRange.end);
        }

        return dayjs(getLastAvailableAnalyticsDay());
    }, [phrasesFilterMode]);

    const periodOptions = getOnlinePresencePeriodOptions(phrasesFilterMode);

    return (
        <div className={styles.OnlinePresencePeriodPicker}>
            <Radio.Group disabled={isDisabled} value={period}>
                {periodOptions
                    .filter((periodItem) => periodItem !== DatePeriodEnum.Custom)
                    .map((periodItem: DatePeriodEnum) => {
                        return (
                            <Radio.Button
                                key={periodItem}
                                onClick={() => {
                                    if (periodItem !== DatePeriodEnum.Custom) {
                                        dispatchFilter({
                                            type: OnlinePresenceFilterActionsEnum.UpdatePeriod,
                                            payload: periodItem,
                                        });

                                        track(
                                            AnalyticsTarget.OnlinePresence.ClickPeriodButton,
                                            AnalyticsDatePeriodMap[periodItem]
                                        );
                                    }
                                }}
                                value={periodItem}
                            >
                                <Locale stringKey={datePeriodTranslations[periodItem]} />
                            </Radio.Button>
                        );
                    })}
            </Radio.Group>
            <div className={styles.OnlinePresencePeriodPicker_secondRowWrapper}>
                <div className={styles.OnlinePresencePeriodPicker_secondRow}>
                    <div
                        className={classNames(styles.OnlinePresencePeriodPicker_rangePickerContainer, {
                            [styles.OnlinePresencePeriodPicker_rangePicker__compare]: compareMode,
                        })}
                    >
                        {compareMode && (
                            <Text block className={styles.OnlinePresencePeriodPicker_label}>
                                A
                            </Text>
                        )}
                        <DateRangePicker
                            allowClear={false}
                            className={styles.OnlinePresencePeriodPicker_rangePicker}
                            disabled={isDisabled}
                            disabledDate={(selectorDayjs) =>
                                lastDayAnalyticsAvailableDayjs.isSameOrBefore(selectorDayjs)
                            }
                            format={phrasesFilterMode ? 'MM.YYYY' : 'DD.MM.YYYY'}
                            onChange={(dateRangeValue) => {
                                dispatchFilter({
                                    type: OnlinePresenceFilterActionsEnum.UpdateMainPeriod,
                                    payload: {
                                        [OnlinePresenceV2FilterQueriesEnum.PeriodStart]:
                                            dateRangeValue?.[0]?.tz('UTC', true).toDate() || new Date(),
                                        [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]:
                                            dateRangeValue?.[1]?.tz('UTC', true).toDate() || new Date(),
                                    },
                                });
                                dispatchFilter({
                                    type: OnlinePresenceFilterActionsEnum.UpdatePeriod,
                                    payload: DatePeriodEnum.Custom,
                                });

                                if (dateRangeValue && dateRangeValue[0] && dateRangeValue[1]) {
                                    track(
                                        AnalyticsTarget.OnlinePresence.ChooseDataOrPeriod,
                                        getPeriodForAnalytics(
                                            {
                                                ...filter,
                                                op_start_date: dateRangeValue?.[0]?.tz('UTC', true).toDate(),
                                                op_end_date: dateRangeValue?.[1]?.tz('UTC', true).toDate(),
                                            },
                                            compareMode
                                        )
                                    );
                                }
                            }}
                            picker={phrasesFilterMode ? 'month' : 'date'}
                            value={[dayjs(periodStart), dayjs(periodEnd)]}
                        />
                    </div>

                    {compareMode && (
                        <div
                            className={classNames(
                                styles.OnlinePresencePeriodPicker_rangePickerContainer,
                                styles.OnlinePresencePeriodPicker_rangePicker__compare
                            )}
                        >
                            <Text block className={styles.OnlinePresencePeriodPicker_label}>
                                B
                            </Text>
                            <DateRangePicker
                                allowClear={false}
                                className={styles.OnlinePresencePeriodPicker_rangePicker}
                                disabled={isDisabled}
                                disabledDate={(selectorDayjs) =>
                                    lastDayAnalyticsAvailableDayjs.isSameOrBefore(selectorDayjs)
                                }
                                format={phrasesFilterMode ? 'MM.YYYY' : 'DD.MM.YYYY'}
                                onChange={(values) => {
                                    if (values?.[0] && values?.[1]) {
                                        dispatchFilter({
                                            type: OnlinePresenceFilterActionsEnum.UpdateComparePeriod,
                                            payload: {
                                                [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: values[0]
                                                    .tz('UTC', true)
                                                    .toDate(),
                                                [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: values[1]
                                                    .tz('UTC', true)
                                                    .toDate(),
                                            },
                                        });

                                        if (values[0] && values[1]) {
                                            track(
                                                AnalyticsTarget.OnlinePresence.ChooseDataOrPeriod,
                                                getPeriodForAnalytics(
                                                    {
                                                        ...filter,
                                                        op_compared_start_date: values[0].tz('UTC', true).toDate(),
                                                        op_compared_end_date: values[1].tz('UTC', true).toDate(),
                                                    },
                                                    compareMode
                                                )
                                            );
                                        }
                                    }
                                }}
                                picker={phrasesFilterMode ? 'month' : 'date'}
                                value={[dayjs(comparePeriodStart), dayjs(comparePeriodEnd)]}
                            />
                        </div>
                    )}

                    {!phrasesFilterMode && (
                        <Select
                            disabled={isDisabled}
                            onChange={(newValueRaw: string) => {
                                const newAggregationValue = getEnumValueEnsure<DateAggregationEnum>(
                                    DateAggregationEnum,
                                    newValueRaw,
                                    DateAggregationEnum.Day
                                );

                                dispatchFilter({
                                    type: OnlinePresenceFilterActionsEnum.UpdateAggregation,
                                    payload: newAggregationValue,
                                });

                                track(
                                    AnalyticsTarget.OnlinePresence.ChooseDataOrPeriod,
                                    getPeriodForAnalytics(
                                        {
                                            ...filter,
                                            op_aggregate: newAggregationValue,
                                        },
                                        compareMode
                                    )
                                );
                            }}
                            value={aggregation}
                        >
                            {Object.values(DateAggregationEnum).map((aggregationItem: DateAggregationEnum) => {
                                return (
                                    <Option key={aggregationItem} value={aggregationItem}>
                                        <Locale stringKey={dateAggregationTranslations[aggregationItem]} />
                                    </Option>
                                );
                            })}
                        </Select>
                    )}

                    {!compareMode && !withoutCompare && (
                        <Dropdown
                            disabled={isDisabled}
                            menu={{
                                items: Object.values(OnlinePresenceCompareV2Enum).map(
                                    (compareModeItem: OnlinePresenceCompareV2Enum) => {
                                        if (compareModeItem === OnlinePresenceCompareV2Enum.Custom) {
                                            return null;
                                        }

                                        return {
                                            title: compareModeItem,
                                            key: compareModeItem,
                                            label: getLocalizedString(
                                                onlinePresenceCompareModeTranslationMap[compareModeItem]
                                            ),
                                            value: compareModeItem,
                                            onClick: () => {
                                                dispatchFilter({
                                                    type: OnlinePresenceFilterActionsEnum.UpdateCompareMode,
                                                    payload: compareModeItem,
                                                });

                                                track(
                                                    AnalyticsTarget.OnlinePresence.CompareData,
                                                    compareModeItem === OnlinePresenceCompareV2Enum.PreviousPeriod
                                                        ? 'previous_period'
                                                        : compareModeItem
                                                );
                                            },
                                        };
                                    }
                                ),
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                        >
                            <Button
                                className={styles.OnlinePresencePeriodPicker_button}
                                icon={<FontAwesomeIcon icon={faBalanceScale} />}
                            >
                                <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__COMPARE" />
                            </Button>
                        </Dropdown>
                    )}
                    {compareMode && (
                        <Button
                            className={styles.OnlinePresencePeriodPicker_button}
                            disabled={isDisabled}
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            onClick={() => {
                                dispatchFilter({
                                    type: OnlinePresenceFilterActionsEnum.UpdateCompareMode,
                                    payload: null,
                                });
                            }}
                        >
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__RESET" />
                        </Button>
                    )}
                </div>
                <Popover
                    content={
                        <PopoverTextWrapper>
                            <Locale
                                stringKey={
                                    phrasesFilterMode
                                        ? 'ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__SEARCH_PHRASES_INFO_TITLE'
                                        : 'ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__DASHBOARD_INFO_TITLE'
                                }
                            />
                        </PopoverTextWrapper>
                    }
                    placement="bottomLeft"
                    title={
                        <Locale
                            stringKey={
                                phrasesFilterMode
                                    ? 'ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__SEARCH_PHRASES_INFO_TEXT'
                                    : 'ONLINE_PRESENCE_ANALYTICS__DATE_PICKER__DASHBOARD_INFO_TEXT'
                            }
                        />
                    }
                    trigger="hover"
                >
                    <Text large secondary>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Text>
                </Popover>
            </div>
        </div>
    );
}
