import {Flex, Table} from 'antd';
import {ColumnFilterItem} from 'antd/es/table/interface';
import {useCallback, useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {TableFilterDropdown} from '../../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {Trend} from '../../../../../layout/trend/trend';
import {Locale} from '../../../../../provider/locale/localization';
import {AverageResponseTimeByEmployeeType} from '../../../../../service/reviews-and-answers-statistics/average-response-time-by-empoyee/average-response-time-by-employee-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {useAverageResponseTableSort} from '../average-response-table-sort-hook/average-response-table-sort-hook';
import {AverageResponseTimeTable} from '../average-response-time-table/average-response-time-table';

type PropsType = {
    pdfPreview?: boolean;
    averageResponseTimeByEmployee: Array<AverageResponseTimeByEmployeeType>;
    isLoading?: boolean;
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;
    isCompareMode: boolean;
};

export function EmployeeStatsTable(props: PropsType): JSX.Element {
    const {pdfPreview, averageResponseTimeByEmployee, isLoading, timeUnit, isCompareMode} = props;

    const getRowKey = useCallback((record: AverageResponseTimeByEmployeeType) => record.employee.pk, []);

    const employeeFilters: Array<ColumnFilterItem> = useMemo(() => {
        return averageResponseTimeByEmployee.map((item) => {
            return {
                text: item.employee.name,
                value: getRowKey(item),
            };
        });
    }, [averageResponseTimeByEmployee, getRowKey]);

    const {getSortOrder, setSorterOrder} = useAverageResponseTableSort();

    return (
        <AverageResponseTimeTable<AverageResponseTimeByEmployeeType>
            dataSource={averageResponseTimeByEmployee || []}
            getSortOrder={getSortOrder}
            isCompareMode={isCompareMode}
            isLoading={isLoading}
            pdfPreview={pdfPreview}
            rowKey={getRowKey}
            setSorterState={setSorterOrder}
            timeUnit={timeUnit}
        >
            <Table.Column<AverageResponseTimeByEmployeeType>
                // eslint-disable-next-line no-undefined
                filterDropdown={pdfPreview ? undefined : TableFilterDropdown}
                filters={pdfPreview ? [] : employeeFilters}
                onFilter={(recordKey, record) => getRowKey(record) === recordKey}
                render={(_value, record) => {
                    return (
                        <Flex vertical>
                            <Text block bolder>
                                {record.employee.name}
                            </Text>
                            <Text block secondary>
                                {record.employee.email || '–'}
                            </Text>
                        </Flex>
                    );
                }}
                title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__EMPLOYEE" />}
            />
            {isCompareMode ? (
                <Table.ColumnGroup<AverageResponseTimeByEmployeeType>
                    title={
                        <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__TAGS_COUNT" />
                    }
                >
                    <Table.Column<AverageResponseTimeByEmployeeType>
                        align="right"
                        dataIndex="tagsCountValue"
                        render={(_value, record) => {
                            return <Text block>{record.tagsCount.value}</Text>;
                        }}
                        sortOrder={getSortOrder('tagsCountValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) => (record1.tagsCount.value || 0) - (record2.tagsCount.value || 0)
                        }
                        title="A"
                    />

                    <Table.Column<AverageResponseTimeByEmployeeType>
                        align="center"
                        dataIndex="tagsCountDelta"
                        render={(_value, record) => {
                            return <Trend delta={record.tagsCount.delta || 0} />;
                        }}
                        sortOrder={getSortOrder('tagsCountDelta')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) => (record1.tagsCount.delta || 0) - (record2.tagsCount.delta || 0)
                        }
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                    />

                    <Table.Column<AverageResponseTimeByEmployeeType>
                        dataIndex="tagsCountComparedValue"
                        render={(_value, record) => {
                            return <Text block>{record.tagsCount.comparedValue}</Text>;
                        }}
                        sortOrder={getSortOrder('tagsCountComparedValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.tagsCount.comparedValue ?? 0) - (record2.tagsCount.comparedValue ?? 0)
                        }
                        title="B"
                    />
                </Table.ColumnGroup>
            ) : (
                <Table.Column<AverageResponseTimeByEmployeeType>
                    align="center"
                    render={(_value, record) => {
                        return <Text block>{record.tagsCount.value}</Text>;
                    }}
                    sorter={
                        pdfPreview
                            ? false
                            : (record1, record2) => (record1.tagsCount.value || 0) - (record2.tagsCount.value || 0)
                    }
                    title={
                        <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TABLE__TAGS_COUNT" />
                    }
                />
            )}
        </AverageResponseTimeTable>
    );
}
