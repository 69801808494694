import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ButtonProps} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {getReportFile} from '../../../../../service/company/company-reports';
import {downloadBlob} from '../../../../../util/fetch';
import {useFormat} from '../../../../../util/format-hook/format-hook';

type PropsType = ButtonProps & {
    uuid: string;
    createdAt: string | Date | number;
    onClick: () => void;
};

export function CompaniesReportDownloadButton(props: PropsType): JSX.Element {
    const {uuid, createdAt, onClick, ...restProps} = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const {getFormattedDateTime} = useFormat();

    const reportDate = new Date(createdAt);

    async function handleRequestForReport() {
        setIsLoading(true);
        onClick();

        if (!uuid) {
            setIsLoading(false);
            return;
        }

        try {
            const response = await getReportFile(uuid);

            if (response && response.size > 0) {
                downloadBlob(
                    response,
                    `${getLocalizedString('MY_COMPANIES__REPORT__PROGRESS_BLOCK__DATE', {
                        date: getFormattedDateTime(reportDate),
                    })}.xlsx`
                );
            } else {
                snackbar.success({
                    message: <Locale stringKey="MY_COMPANIES__REPORT__SNACKBAR__DOWNLOAD__TITLE" />,
                    description: <Locale stringKey="MY_COMPANIES__REPORT__SNACKBAR__DOWNLOAD__DESCRIPTION" />,
                });
            }
        } catch {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
            icon={<FontAwesomeIcon icon={faDownload} />}
            loading={isLoading}
            onClick={handleRequestForReport}
        >
            <span>
                <Locale stringKey="MY_COMPANIES__REPORT__DOWNLOAD__BUTTON" />
            </span>
        </Button>
    );
}
