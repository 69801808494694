import {TableColumnsType} from 'antd';

import {Locale} from '../../../../../provider/locale/locale';
import {
    getAvgResponseDelayColumn,
    getRateColumn,
    getRepliedColumn,
    getRepliedPercentage,
    getSentimentColumn,
    getTotalColumn,
    getTrendColumn,
} from '../../components/table/reviews-analysis-table-helper';

import {CityTableDataType} from './cities-page-type';

export const citiesPageColumns: TableColumnsType<CityTableDataType> = [
    {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__CITY" />,
        dataIndex: 'cityName',
        key: 'cityName',
    },
    getRateColumn<CityTableDataType>(),
    getTotalColumn<CityTableDataType>(),
    getRepliedColumn<CityTableDataType>(),
    getRepliedPercentage<CityTableDataType>(),
    getAvgResponseDelayColumn<CityTableDataType>(),
    getSentimentColumn<CityTableDataType>(),
    getTrendColumn<CityTableDataType>(),
];
