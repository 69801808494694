import {z as r} from 'zod';

import {cursorPaginationResponseSchemaFactory} from '../../api/api-type';
import {SourceLinkStatusEnum} from '../source-links/source-links-type';

export const sourcesAccountSchema = r.object({
    id: r.number(),
    login: r.string(),
    status: r.nativeEnum(SourceLinkStatusEnum),
    brandsCount: r.number(),
    companiesCount: r.number(),
    limitedCompaniesNames: r.array(r.string()),
    limitedBrandsNames: r.array(
        r.object({
            id: r.number(),
            name: r.string(),
        })
    ),
    errorText: r.string().optional().nullable(),
    catalog: r.object({
        id: r.number(),
        name: r.string(),
    }),
    updatedAt: r.string().optional(),
    editAbility: r.boolean(),
});

export const newSourcesAccountSchema = r.object({
    catalogId: r.number(),
    brands: r.array(r.number()),
    uuid: r.string(),
    login: r.string(),
    password: r.string(),
});

export type NewSourcesAccountType = r.infer<typeof newSourcesAccountSchema>;

export const sourcesAccountsSchema = cursorPaginationResponseSchemaFactory(sourcesAccountSchema);

export type SourcesAccountType = r.infer<typeof sourcesAccountSchema>;

export type SourceAccountsRequestType = {
    catalogId: number;
    cursor: string | null;
    pageSize: number;
    filterKey: string;
    status: Array<SourceLinkStatusEnum>;
};

export type UpdateSourcesAccountType = {
    login: string;
    password: string;
    brands: Array<number>;
    uuid: string;
};

const idNameSchema = r.object({
    id: r.number(),
    name: r.string(),
});

const accountRawAlreadySelectedSchema = r.object({
    account: r.object({
        id: r.number(),
        login: r.string(),
    }),
    catalog: idNameSchema,
});

export const accountCompanyAlreadySelectedSchema = accountRawAlreadySelectedSchema.merge(
    r.object({
        company: idNameSchema,
    })
);

export const accountBrandAlreadySelectedSchema = accountRawAlreadySelectedSchema.merge(
    r.object({
        brand: idNameSchema,
    })
);

export type AccountCompanyAlreadySelectedType = r.infer<typeof accountCompanyAlreadySelectedSchema>;
export type AccountBrandAlreadySelectedType = r.infer<typeof accountBrandAlreadySelectedSchema>;

type AccountAlreadySelectedRequestRawType = {
    catalogId: number;
    accountId?: number;
};

export type AccountBrandAlreadySelectedRequestType = AccountAlreadySelectedRequestRawType & {
    brandsIds: Array<number>;
};

export type AccountCompanyAlreadySelectedRequestType = AccountAlreadySelectedRequestRawType & {
    uuid?: string;
    companiesIds?: Array<number>;
};

export type RebindBrandsRequestType = {
    accountsIds: Array<number>;
    catalogId: number;
    brandsIds: Array<number>;
};

export type RebindCompaniesRequestType = {
    accountsIds: Array<number>;
    catalogId: number;
    companiesIds: Array<number>;
};

export enum AccountReviewStatusesEnum {
    errorStatus = 'error_status',
    synchronizationStatus = 'synchronization_status',
    syncedStatus = 'synced_status',
}

export const accountReviewStatusesSchema = r.array(
    r.object({
        reviewsIds: r.array(r.number()),
        accountStatus: r.nativeEnum(AccountReviewStatusesEnum).nullable(),
    })
);

export type AccountReviewStatusesType = r.infer<typeof accountReviewStatusesSchema>;

export type ReviewStatusesRequestType = {
    reviewsIds: Array<number>;
};
