import {ProvidersIdsEnum} from '../../../../../../util/type';

export const DESCRIPTION_LIMITS = {
    short: 200,
    long: 500,
};

export const CATALOG_DESCRIPTION_LIMITS: {
    short: Record<ProvidersIdsEnum.google | ProvidersIdsEnum.facebook, number> & Record<number, number>;
    long: Record<ProvidersIdsEnum.google, number> & Record<number, number>;
} = {
    short: {
        [ProvidersIdsEnum.google]: 750,
        [ProvidersIdsEnum.facebook]: 100,
    },
    long: {
        [ProvidersIdsEnum.google]: 750,
        [ProvidersIdsEnum.facebook]: 550,
    },
};
