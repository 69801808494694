import {defaultApiDomain, defaultApiProtocol} from '../../../../const';
import {UsedeskType} from '../../../../service/usedesk/usedesk-type';
import {NotificationsReviewsFilterType} from '../../notification/notification-review-filter/notification-review-filter-type';

import {UsedeskFieldNamesEnum, UsedeskFieldsType} from './integration-usedesk-type';

export function getWebHookUrl(addId: string): string {
    const trimmedAddId = addId.trim();

    return `${defaultApiProtocol}${defaultApiDomain}/usedesk/webhook/${trimmedAddId}/`;
}

export function deserializeUsedesk(usedesk: UsedeskType): UsedeskFieldsType {
    return {
        [UsedeskFieldNamesEnum.secretKey]: usedesk.usedesk_secret,
        [UsedeskFieldNamesEnum.applicationId]: usedesk.usedesk_app_id,
        [UsedeskFieldNamesEnum.chatId]: usedesk.usedesk_chat_id,
        [UsedeskFieldNamesEnum.webhook]: getWebHookUrl(usedesk.usedesk_app_id),
        [UsedeskFieldNamesEnum.catalog]: usedesk.usedesk_notify_catalogs,
        [UsedeskFieldNamesEnum.ratings]: usedesk.usedesk_notify_ratings,
        [UsedeskFieldNamesEnum.type]: usedesk.usedesk_notify_content_type,
    };
}

export function extractNotificationSettings(fields: UsedeskFieldsType): NotificationsReviewsFilterType {
    return {
        catalog: fields[UsedeskFieldNamesEnum.catalog],
        ratings: fields[UsedeskFieldNamesEnum.ratings],
        type: fields[UsedeskFieldNamesEnum.type],
    };
}

export function serializeUsedesk(fields: UsedeskFieldsType): UsedeskType {
    return {
        usedesk_app_id: fields[UsedeskFieldNamesEnum.applicationId],
        usedesk_chat_id: fields[UsedeskFieldNamesEnum.chatId],
        usedesk_secret: fields[UsedeskFieldNamesEnum.secretKey],
        usedesk_notify_catalogs: fields[UsedeskFieldNamesEnum.catalog],
        usedesk_notify_ratings: fields[UsedeskFieldNamesEnum.ratings],
        usedesk_notify_content_type: fields[UsedeskFieldNamesEnum.type],
    };
}
