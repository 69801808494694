import {PropsWithChildren} from 'react';

import {classNames} from '../../../util/css';

import * as styles from './popover-text-wrapper.scss';

type PropsType = PropsWithChildren<{
    className?: string;
}>;

export function PopoverTextWrapper(props: PropsType): JSX.Element {
    const {className, children} = props;

    const fullClassName = classNames(styles.popover_text_wrapper, className);

    return <div className={fullClassName}>{children}</div>;
}
