import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {LocaleNameEnum} from '../../provider/locale/locale-context-type';
import {fetchAndDeserialize, serialize} from '../../util/api-adapter';
import {ApiError} from '../../util/error';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

const reviewStatusSchema = r.object({
    statusCode: r.string(),
    title: r.string(),
});

export type ReviewStatusType = r.infer<typeof reviewStatusSchema>;

const reviewStatusListResponseSchema = r.object({
    statuses: r.array(reviewStatusSchema),
});

type ReviewStatusListResponseType = r.infer<typeof reviewStatusListResponseSchema>;

type UpdateReviewStatusType = {
    status: string;
};

type ManageReviewStatusErrorPayloadType = {
    status: string;
};

export class ManageReviewStatusError extends ApiError {
    name = 'ManageReviewStatusError';
    jsonData: ManageReviewStatusErrorPayloadType;

    constructor(message: string, statusCode: number, jsonData: ManageReviewStatusErrorPayloadType) {
        super(message, statusCode, jsonData);

        this.jsonData = jsonData;
    }
}

function isManageReviewStatusErrorPayloadType(
    errorPayload: unknown
): errorPayload is ManageReviewStatusErrorPayloadType {
    return typeof (errorPayload as ManageReviewStatusErrorPayloadType)?.status === 'string';
}

function handleManageReviewStatusError(error: unknown): never {
    if (error instanceof ApiError && isManageReviewStatusErrorPayloadType(error.jsonData)) {
        throw new ManageReviewStatusError(error.message, error.statusCode, error.jsonData);
    }

    throw error;
}

const reviewStatusListUrl = '/v4/reviews/statuses/';

async function fetchReviewStatusList(): Promise<ReviewStatusListResponseType> {
    return fetchAndDeserialize<ReviewStatusListResponseType>(reviewStatusListUrl, reviewStatusListResponseSchema);
}

export function useReviewStatusList(localeName: LocaleNameEnum): UseQueryResult<ReviewStatusListResponseType> {
    return useQuery([reviewStatusListUrl + localeName], () => fetchReviewStatusList(), {
        refetchOnWindowFocus: false,
    });
}

export function updateReviewStatus(id: number, payload: UpdateReviewStatusType): Promise<void> {
    const url = `${rootApiUrl}/v4/reviews/${id}/status/`;
    const body = JSON.stringify(serialize<UpdateReviewStatusType>(payload));

    return fetchX<void>(url, {
        body,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.patch,
    }).catch(handleManageReviewStatusError);
}
