import {noop} from 'lodash';
import {
    Context,
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {appRoute} from '../../../../app-route';
import {CclStatusEnum} from '../../../../service/ccl-statistic/ccl-statistic-type';
import {deleteGoogleProcessingKey} from '../../../../service/google/processing/google-processing-api';
import {GoogleProcessingStatusEnum} from '../../../../service/google/processing/google-processing-type';
import {useUrl, useUrlQueryNumber} from '../../../../util/url-hook/url-hook';
import {SourcesSearchParametersEnum} from '../../sources/sources-type';

import {
    GoogleAccountSyncUrlParameterEnum as UrlParameterEnum,
    GoogleAccountSyncUrlType,
} from './google-account-sync-type';
import {TipType} from './tips/tips-type';

type GoogleAccountSyncContextType = {
    status: GoogleProcessingStatusEnum | null;
    setStatus: Dispatch<SetStateAction<GoogleProcessingStatusEnum | null>>;
    processingKey: string | null;
    setProcessingKey: Dispatch<SetStateAction<string | null>>;
    personalAccountId: number | null;
    setPersonalAccountId: Dispatch<SetStateAction<number | null>>;
    accountId: number | null;
    setAccountId: Dispatch<SetStateAction<number | null>>;
    tips: Array<TipType>;
    setTips: Dispatch<SetStateAction<Array<TipType>>>;
    headerButtons: JSX.Element | null;
    setHeaderButtons: Dispatch<SetStateAction<JSX.Element | null>>;
    goToSources: () => void;
    deleteKeyAndGoToSources: () => void;
};

const defaultContext: GoogleAccountSyncContextType = {
    status: null,
    setStatus: noop,
    processingKey: null,
    setProcessingKey: noop,
    personalAccountId: null,
    setPersonalAccountId: noop,
    accountId: null,
    setAccountId: noop,
    tips: [],
    setTips: noop,
    headerButtons: null,
    setHeaderButtons: noop,
    goToSources: noop,
    deleteKeyAndGoToSources: noop,
};

export const GoogleAccountSyncContext: Context<GoogleAccountSyncContextType> = createContext(defaultContext);

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function GoogleAccountSyncProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const {replaceQuery, pushState} = useUrl();

    const defaultAccountIdQuery = useUrlQueryNumber<GoogleAccountSyncUrlType>(UrlParameterEnum.AccountId);
    const defaultPersonalAccountId =
        useUrlQueryNumber<GoogleAccountSyncUrlType>(UrlParameterEnum.PersonalAccountId) ?? defaultAccountIdQuery;

    const [status, setStatus] = useState(defaultContext.status);
    const [processingKey, setProcessingKey] = useState(defaultContext.processingKey);
    const [personalAccountId, setPersonalAccountId] = useState(defaultPersonalAccountId ?? defaultContext.accountId);
    const [accountId, setAccountId] = useState(defaultAccountIdQuery ?? defaultContext.accountId);
    const [tips, setTips] = useState(defaultContext.tips);
    const [headerButtons, setHeaderButtons] = useState(defaultContext.headerButtons);

    useEffect(() => {
        if (!personalAccountId) {
            return;
        }

        if (personalAccountId === accountId) {
            replaceQuery({accountId, personalAccountId: undefined}); // eslint-disable-line no-undefined
        } else {
            replaceQuery(accountId ? {personalAccountId, accountId} : {personalAccountId});
        }
    }, [accountId, personalAccountId, replaceQuery]);

    const goToSources = useCallback(() => {
        pushState(appRoute.sources.path, {
            [SourcesSearchParametersEnum.CclStatus]: CclStatusEnum.actionNeeded,
        });
    }, [pushState]);

    const deleteKeyAndGoToSources = useCallback(() => {
        if (!processingKey) {
            return goToSources();
        }

        return deleteGoogleProcessingKey(processingKey).then(() => goToSources());
    }, [goToSources, processingKey]);

    const providerData: GoogleAccountSyncContextType = useMemo(
        () => ({
            status,
            setStatus,
            processingKey,
            setProcessingKey,
            personalAccountId,
            setPersonalAccountId,
            accountId,
            setAccountId,
            tips,
            setTips,
            headerButtons,
            setHeaderButtons,
            goToSources,
            deleteKeyAndGoToSources,
        }),
        [status, processingKey, personalAccountId, accountId, tips, headerButtons, goToSources, deleteKeyAndGoToSources]
    );

    return <GoogleAccountSyncContext.Provider value={providerData}>{children}</GoogleAccountSyncContext.Provider>;
}
