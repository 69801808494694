import {Select} from 'antd';

const {Option} = Select;

export function SelectCatalogOption(catalog: {id: number; name: string}): JSX.Element {
    const {id, name} = catalog;

    return (
        <Option key={String(id)} value={id}>
            {name}
        </Option>
    );
}
