import {Button, TablePaginationConfig} from 'antd';
import {useCallback, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {
    ChangeTemplateGroupBodyType,
    fetchTemplateChangeGroup,
    fetchTemplateChangeType,
    fetchTemplateDelete,
    ResponseTemplateDataType,
    useResponseTemplates,
} from '../../../service/reviews/reviews-response-templates';
import {findInArray} from '../../../util/array';
import {ErrorWithStatus, isErrorHasStatusMessage} from '../../../util/error';
import {toTrimmedString} from '../../../util/string';
import {useUrl} from '../../../util/url-hook/url-hook';

import {ResponseTemplatesActionSelect} from './response-templates-action-select/response-templates-action-select';
import {ResponseTemplatesTable} from './response-templates-table/response-templates-table';
import * as styles from './response-templates-table.scss';

// eslint-disable-next-line max-statements
export function ResponseTemplates(): JSX.Element {
    const [responseTemplateIdList, setResponseTemplateIdList] = useState<Array<string>>([]);
    const [responseTemplateDataList, setResponseTemplateDataList] = useState<Array<ResponseTemplateDataType>>([]);
    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const {getQuery} = useUrl();
    const initialGroupsIdString = getQuery('groupIds');
    const initialFilterString = getQuery('filterType');

    const initialGroupArray = initialGroupsIdString ? initialGroupsIdString.split(',') : [];
    const initialFilter = initialFilterString === 'true' ? true : initialFilterString === 'false' ? false : null;

    const [responseTemplateGroupIdFilterList, setResponseTemplateGroupIdFilterList] =
        useState<Array<string>>(initialGroupArray);
    const [responseTemplateTypeFilter, setResponseTemplateTypeFilter] = useState<boolean | null>(initialFilter);

    const {
        data: responseTemplatesHookResult,
        isFetching: responseTemplatesHookIsInProgress,
        refetch: refreshTemplates,
        pagination: {page, onChange, pageSize, total},
    } = useResponseTemplates(responseTemplateGroupIdFilterList, responseTemplateTypeFilter);

    const {
        reviewsManagementResponseTemplates: reviewsManagementResponseTemplatesRoute,
        reviewsManagementResponseTemplatesEditGroup: reviewsManagementResponseTemplatesEditGroupRoute,
        reviewsManagementResponseTemplatesCreateTemplate: templatesCreateTemplateRoute,
    } = appRoute;
    const {getLocalizedString} = useLocale();

    function handlePaginationChange(newPagination: TablePaginationConfig) {
        onChange(newPagination.current ?? 1, newPagination.pageSize);

        if (page !== newPagination.current) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, newPagination.current ?? 1);
        }
    }

    function handleChangeGroupIdList(groupIds: Array<string>) {
        setResponseTemplateGroupIdFilterList(groupIds);
    }

    const handleUpdateType = useCallback(
        async (isPrivate: boolean) => {
            const data = {
                ids: responseTemplateIdList,
                is_private: isPrivate,
            };

            try {
                await fetchTemplateChangeType(data);

                snackbar.success({
                    key: 'saved-successfully',
                    message: <Locale stringKey="TEXT__SAVED" />,
                    description: <Locale stringKey="RESPONSE_TEMPLATES__FORM__THE_TEMPLATE_WAS_SAVED_SUCCESSFULLY" />,
                });
                refreshTemplates();
            } catch (error) {
                snackbar.error(
                    isErrorHasStatusMessage<ErrorWithStatus>(error) ? (
                        error.jsonData.status
                    ) : (
                        <Locale stringKey="RESPONSE_TEMPLATES__UPDATING_ERROR" />
                    )
                );
            }
        },
        [responseTemplateIdList, snackbar, refreshTemplates]
    );

    const handleUpdateGroup = useCallback(
        async (folderId: number | null) => {
            const data: ChangeTemplateGroupBodyType = {
                ids: responseTemplateIdList,
            };

            if (folderId) {
                data.destination_folder_id = folderId;
            } else {
                data.without_folder = true;
            }

            try {
                await fetchTemplateChangeGroup(data);

                snackbar.success({
                    key: 'saved-successfully',
                    message: <Locale stringKey="TEXT__SAVED" />,
                    description: <Locale stringKey="RESPONSE_TEMPLATES__FORM__THE_TEMPLATE_WAS_SAVED_SUCCESSFULLY" />,
                });
                refreshTemplates();
            } catch (error) {
                snackbar.error(
                    isErrorHasStatusMessage<ErrorWithStatus>(error) ? (
                        error.jsonData.status
                    ) : (
                        <Locale stringKey="RESPONSE_TEMPLATES__UPDATING_ERROR" />
                    )
                );
            }
        },
        [responseTemplateIdList, snackbar, refreshTemplates]
    );

    const handleDeleteTemplate = useCallback(
        async (responseTemplateData?: ResponseTemplateDataType) => {
            try {
                await fetchTemplateDelete({
                    ids: responseTemplateData ? responseTemplateData.id : responseTemplateIdList,
                });

                snackbar.success(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_SUCCESS" />);
                refreshTemplates();
            } catch (removeError) {
                if (!(removeError instanceof Error)) {
                    return snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_ERROR" />);
                }

                try {
                    const errors = JSON.parse(removeError.message);

                    if (Array.isArray(errors)) {
                        const hasUsedTemplates = errors.some((error) => error.rules.length > 0);

                        if (hasUsedTemplates) {
                            snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_USED_ERROR" />);

                            // eslint-disable-next-line no-undefined, unicorn/no-useless-undefined
                            return undefined;
                        }
                    }

                    snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_ERROR" />);
                } catch {
                    snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_ERROR" />);
                }
            }

            // eslint-disable-next-line no-undefined, unicorn/no-useless-undefined
            return undefined;
        },
        [responseTemplateIdList, snackbar, refreshTemplates]
    );

    const handleDelete = useCallback(
        (responseTemplateData?: ResponseTemplateDataType) => {
            const responseTemplateTitleList: string = responseTemplateData
                ? responseTemplateData.title
                : responseTemplateIdList
                      .map((responseTemplateDataId: string): string => {
                          const responseTemplateGroup = findInArray<ResponseTemplateDataType>(
                              responseTemplateDataList,
                              {
                                  id: Number(responseTemplateDataId),
                              }
                          );

                          return toTrimmedString(responseTemplateGroup?.title || '');
                      })
                      .filter(Boolean)
                      .map((responseTemplateDataTitle: string): string => `"${responseTemplateDataTitle}"`)
                      .join(', ');

            modal.confirm({
                maskClosable: true,
                title: <Locale stringKey="RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE__HEADER" />,
                content: (
                    <Locale
                        stringKey="RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE__CONTENT"
                        valueMap={{
                            responseTemplateTitle: responseTemplateTitleList,
                        }}
                    />
                ),
                okText: <Locale stringKey="TEXT__APPLY" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                cancelButtonProps: {type: 'primary'},
                okButtonProps: {type: 'default', danger: true},
                onOk: () => {
                    handleDeleteTemplate(responseTemplateData);
                },
            });
        },
        [responseTemplateIdList, modal, responseTemplateDataList, handleDeleteTemplate]
    );

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__RESPONSE_TEMPLATES')} />

            <BreadCrumbs
                list={[
                    {
                        path: reviewsManagementResponseTemplatesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__RESPONSE_TEMPLATES',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle
                    productName={UsetifulNameProductEnum.RESPONSE_TEMPLATES}
                    title="CATEGORY_NAME__RESPONSE_TEMPLATES"
                />
                <div className={styles.buttons_container}>
                    <Button type="link">
                        <NavigationLink to={reviewsManagementResponseTemplatesEditGroupRoute.path}>
                            <Locale stringKey="BUTTON__SET_UP_GROUPS" />
                        </NavigationLink>
                    </Button>
                    <Button type="primary">
                        <NavigationLink to={templatesCreateTemplateRoute.path}>
                            <Locale stringKey="RESPONSE_TEMPLATES__ADD_TEMPLATES" />
                        </NavigationLink>
                    </Button>
                </div>
            </PageHeader>

            <PageSubHeader>
                <Locale stringKey="RESPONSE_TEMPLATES__LIST_OF_TEMPLATES" />

                <ResponseTemplatesActionSelect
                    isActive={responseTemplateIdList.length > 0}
                    onDelete={handleDelete}
                    updateTemplateGroups={handleUpdateGroup}
                    updateTemplatesType={handleUpdateType}
                />
            </PageSubHeader>

            <ResponseTemplatesTable
                countTemplates={responseTemplatesHookResult?.count || 0}
                groupFilterInitialState={initialFilter}
                onDelete={handleDelete}
                pagination={{current: page, pageSize, total}}
                responseTemplateGroupIdFilterList={responseTemplateGroupIdFilterList}
                responseTemplatesHookIsInProgress={responseTemplatesHookIsInProgress}
                responseTemplatesHookResult={responseTemplatesHookResult?.results}
                setPagination={handlePaginationChange}
                setResponseTemplateDataList={setResponseTemplateDataList}
                setResponseTemplateGroupIdFilterList={handleChangeGroupIdList}
                setResponseTemplateIdList={setResponseTemplateIdList}
                setResponseTemplateTypeFilter={setResponseTemplateTypeFilter}
            />
        </Page>
    );
}
