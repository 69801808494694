import {TableColumnsType} from 'antd';

import {Locale} from '../../../../../provider/locale/localization';

import {PositionsTableDataType} from './positions-table-types';

export const positionsTableColumnsList: TableColumnsType<PositionsTableDataType> = [
    {
        title: '№',
        dataIndex: 'position',
        width: '50px',
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__POSITIONS_TABLE__NAME_ADDRESS" />,
        dataIndex: 'nameAddress',
        width: '50%',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__POSITIONS_TABLE__REMOTENESS" />,
        dataIndex: 'distance',
        width: '14%',
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__POSITIONS_TABLE__RATING" />,
        dataIndex: 'rating',
        width: '11%',
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__POSITIONS_TABLE__REVIEWS_COUNT" />,
        dataIndex: 'reviewsCount',
        width: '10%',
        align: 'center',
    },
    {
        title: <Locale stringKey="LOCAL_PACK__POSITIONS_TABLE__MAP_LINK" />,
        dataIndex: 'mapsUrl',
        width: '10%',
        align: 'center',
    },
];
