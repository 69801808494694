import {Button, Checkbox, Form, Input} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';

import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {TimeRangePicker} from '../../../../layout/time-range-picker/time-range-picker';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useTelegramNotifications} from '../../../../service/notifications-settings/notifications-settings-hook';
import {
    NotificationTextReviewEnum,
    TelegramNotificationsType,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {classNames} from '../../../../util/css';
import {ErrorWithStatus, isErrorHasStatusMessage} from '../../../../util/error';
import * as notificationStyles from '../notification.scss';
import {NotificationReviewFilter} from '../notification-review-filter/notification-review-filter';
import {NotificationsReviewsFilterType} from '../notification-review-filter/notification-review-filter-type';

import {telegramNotificationsFields} from './notification-telegram-const';
import {serializeTelegramNotification} from './notification-telegram-helper';
import {TelegramNotificationFormValuesType} from './notification-telegram-type';
import * as styles from './notification-telegram.scss';

export function NotificationTelegram(): JSX.Element {
    const [form] = Form.useForm<TelegramNotificationFormValuesType & NotificationsReviewsFilterType>();
    const {getLocalizedString} = useLocale();
    const documentationLinks = useDocumentationLinks();
    const {snackbar} = useSnackbar();
    const {licenses} = useLicenses();

    const hasOmnichannel = licenses[FeaturesEnum.omnichannel]?.isActive;

    const [isNotificationsTurnedOn, setIsNotificationsTurnedOn] = useState(false);
    const [isOmnichannelTurnedOn, setIsOmnichannelTurnedOn] = useState(false);

    const {isInProgress, result: telegramNotifications, updateTelegramNotifications} = useTelegramNotifications();

    async function handleFinishSuccess() {
        const formData: TelegramNotificationsType = serializeTelegramNotification(form);

        const response = await updateTelegramNotifications(formData);

        if (response instanceof Error) {
            snackbar.error(
                isErrorHasStatusMessage<ErrorWithStatus>(response) ? (
                    response.jsonData.status
                ) : (
                    <Locale stringKey="NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS__FAILED_TO_UPDATE" />
                )
            );
        } else {
            snackbar.success({
                description: <Locale stringKey="NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS__UPDATED" />,
                message: <Locale stringKey="NOTIFICATIONS__SETTINGS_SAVED" />,
            });
        }
    }

    function handleFormChange() {
        const isTurnedOn = form.getFieldValue(telegramNotificationsFields.send_messenger_notifications);

        setIsNotificationsTurnedOn(isTurnedOn);

        if (hasOmnichannel) {
            const isOmnichannelOn = form.getFieldValue(
                telegramNotificationsFields.send_messenger_omnichannel_notifications
            );

            setIsOmnichannelTurnedOn(isOmnichannelOn);
        }
    }

    useEffect(() => {
        if (telegramNotifications) {
            setIsNotificationsTurnedOn(telegramNotifications.send_messenger_notifications);

            if (hasOmnichannel) {
                setIsOmnichannelTurnedOn(telegramNotifications.send_messenger_omnichannel_notifications);
            }

            form.setFieldsValue({
                catalog: telegramNotifications.messenger_notifications_catalogs || [],
                ratings: telegramNotifications.messenger_notifications_ratings || [],
                type: telegramNotifications.messenger_notifications_content_type || NotificationTextReviewEnum.all,
            });
        }
    }, [telegramNotifications, form]);

    if (!telegramNotifications) {
        return <Spinner wrapperHeight={400} />;
    }

    const timeStart: Dayjs = dayjs(
        telegramNotifications.messenger_notifications_time_bucket?.start || '00:00',
        'HH:mm'
    );
    const timeEnd: Dayjs = dayjs(telegramNotifications.messenger_notifications_time_bucket?.end || '23:59', 'HH:mm');

    const reviewsFilterFields: NotificationsReviewsFilterType = {
        catalog: telegramNotifications.messenger_notifications_catalogs || [],
        ratings: telegramNotifications.messenger_notifications_ratings || [],
        type: telegramNotifications.messenger_notifications_content_type || NotificationTextReviewEnum.all,
    };

    return (
        <>
            <PageSubHeader className={notificationStyles.Notification_reviewsSubHeader}>
                <Locale stringKey="NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS__SUB_HEADER" />
            </PageSubHeader>

            <Form
                className={notificationStyles.Notification_form}
                form={form}
                layout="vertical"
                name="notification-telegram"
                onChange={handleFormChange}
                onFinish={handleFinishSuccess}
            >
                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    initialValue={telegramNotifications.send_messenger_notifications}
                    name={telegramNotificationsFields.send_messenger_notifications}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NOTIFICATIONS_OF_NEW_REVIEWS" />
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    initialValue={telegramNotifications.send_messenger_review_responsible_user_notifications}
                    name={telegramNotificationsFields.send_messenger_review_responsible_user_notifications}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__CHANGE_RESPONSIBLE_USER" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={classNames(notificationStyles.Notification_checkbox)}
                    hidden={!hasOmnichannel}
                    initialValue={telegramNotifications.send_messenger_omnichannel_notifications}
                    name={telegramNotificationsFields.send_messenger_omnichannel_notifications}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_OMNICHANNEL" />
                    </Checkbox>
                </Form.Item>

                <PageSubHeader
                    className={classNames(
                        notificationStyles.Notification_reviewsSubHeader,
                        notificationStyles.Notification_reviewsSubHeader__space
                    )}
                >
                    <Locale stringKey="NOTIFICATIONS__SERVICE_CONNECTION" />
                </PageSubHeader>

                <Form.Item
                    className={notificationStyles.Notification_wideInput}
                    initialValue={telegramNotifications.messenger_token}
                    label={<Locale stringKey="NOTIFICATIONS__CHAT_TOKEN" />}
                    name={telegramNotificationsFields.messenger_token}
                >
                    <Input
                        className={notificationStyles.Notification_wideInput}
                        disabled={!isNotificationsTurnedOn && !isOmnichannelTurnedOn}
                        placeholder={getLocalizedString('NOTIFICATIONS__YOUR_TOKEN')}
                        size="large"
                    />
                </Form.Item>

                <div className={styles.NotificationTelegram_inputSubLine}>
                    <p className={styles.NotificationTelegram_inputSubText}>
                        <Locale stringKey="NOTIFICATIONS__THE_TOKEN_CAN_BE_OBTAINED_FROM_OUR_TELEGRAM_BOT" />
                    </p>

                    {documentationLinks.telegramNotificationBotLink && (
                        <Button>
                            <a href={documentationLinks.telegramNotificationBotLink} rel="noreferrer" target="_blank">
                                <Locale stringKey="NOTIFICATIONS__GET_A_TOKEN" />
                            </a>
                        </Button>
                    )}
                </div>

                <PageSubHeader className={notificationStyles.Notification_reviewsSubHeader}>
                    <Locale stringKey="NOTIFICATIONS__SETTING_THE_TIME_FOR_RECEIVING_NOTIFICATIONS" />
                </PageSubHeader>

                <Form.Item
                    className={notificationStyles.Notification_wideInput}
                    initialValue={[timeStart, timeEnd]}
                    label={<Locale stringKey="NOTIFICATIONS__TIME_PERIOD" />}
                    name={telegramNotificationsFields.messenger_notifications_time_bucket}
                >
                    <TimeRangePicker
                        className={notificationStyles.Notification_wideInput}
                        disabled={!isNotificationsTurnedOn && !isOmnichannelTurnedOn}
                        placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                        size="large"
                    />
                </Form.Item>

                <NotificationReviewFilter
                    className={notificationStyles.Notification_reviewFilter}
                    disabled={!isNotificationsTurnedOn}
                    fields={reviewsFilterFields}
                />

                <Button htmlType="submit" loading={isInProgress} type="primary">
                    <Locale stringKey="BUTTON__SAVE_SETTINGS" />
                </Button>
            </Form>
        </>
    );
}
