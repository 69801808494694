import {Alert, Button, Space} from 'antd';
import dayjs from 'dayjs';
import {ReactElement, useState} from 'react';
import {z as r} from 'zod';

import {TrialActivationModal} from '../../../../../component/landing/trial-activation-modal/trial-activation-modal';
import {Text} from '../../../../../component/text/text';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {useLicenses, useTrialActivation} from '../../../../../provider/license/license-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useBuyTariffLicense} from '../../../../../service/tariffs/tariffs-buy-license';
import {FeaturesEnum} from '../../../../../service/user/user-type';
import {getFromLocalStorage} from '../../../../../util/local-storage';
import {LicensesCodeEnum} from '../../../tariffs-and-limits/licenses-block/license/license-const';
import {TariffModalWrapper} from '../../../tariffs-and-limits/tariffs-modals/tariffs-modal-wrapper/tariff-modal-wrapper';

import neuralRobotPictureSrc from './images/neural-alert-picture.png';
import neuralBuyPictureSrc from './images/neural-buy-aler-picture.png';
import {
    GET_BUY_LICENSE_NEURALINK_LOCALSTORAGE_KEY,
    GET_TRIAL_LICENSE_NEURALINK_LOCALSTORAGE_KEY,
} from './neuralink-alert-const';
import {getNeuralLinkAlertData, onCloseAlert} from './neuralink-alert-helper';
import * as styles from './neuralink-alert.scss';

// eslint-disable-next-line complexity
export function NeuralNetworkAlert(): ReactElement | null {
    const {licenses, refetch} = useLicenses();
    const {mutateAsync, isLoading} = useTrialActivation();
    const {mutate} = useBuyTariffLicense();
    const {reviews} = useDocumentationLinks();
    const {isShowTrialNeurlalinkInfo} = useDomainConfig();

    const [isOpenTrialModal, setIsOpenTrialModal] = useState<boolean>(false);
    const [isOpenLicenseModal, setIsOpenLicenseModal] = useState<boolean>(false);
    const reviewGptReplyLicense = licenses[FeaturesEnum.reviewGptReplySuggestions];
    const isOpenedAlert = getFromLocalStorage(
        reviewGptReplyLicense?.canActivateTrial
            ? GET_TRIAL_LICENSE_NEURALINK_LOCALSTORAGE_KEY
            : GET_BUY_LICENSE_NEURALINK_LOCALSTORAGE_KEY,
        r.boolean(),
        true
    );
    const today = dayjs();

    if (
        (reviewGptReplyLicense?.isActive && !reviewGptReplyLicense?.isTrial) ||
        (reviewGptReplyLicense?.isTrial &&
            dayjs(today).isBefore(dayjs(reviewGptReplyLicense?.finishAt).subtract(10, 'day'))) ||
        !isOpenedAlert ||
        !isShowTrialNeurlalinkInfo ||
        (!reviewGptReplyLicense?.isActive &&
            dayjs(today).isAfter(dayjs(reviewGptReplyLicense?.finishAt)) &&
            !reviewGptReplyLicense?.isTrial)
    ) {
        return null;
    }

    const textData = getNeuralLinkAlertData(
        reviewGptReplyLicense?.isTrial,
        reviewGptReplyLicense?.canActivateTrial,
        licenses[FeaturesEnum.reviewGptReplySuggestions]?.finishAt
    );

    function handleBuyLicense(message: string) {
        mutate(
            {message, licenseCode: LicensesCodeEnum.GptReplySuggestionsLicence},
            {
                onSuccess: () => {
                    setIsOpenLicenseModal(false);
                    onCloseAlert(reviewGptReplyLicense?.canActivateTrial);
                    refetch();
                },
            }
        );
    }

    function handleOpenModal() {
        return reviewGptReplyLicense?.canActivateTrial ? setIsOpenTrialModal(true) : setIsOpenLicenseModal(true);
    }

    return (
        <>
            <Alert
                closable
                description={
                    <div className={styles.NeuralAlert}>
                        <Space direction="vertical">
                            <Text bolder large stringKey={textData.title} />
                            <Space direction="vertical">
                                <Text stringKey={textData.description} />
                                <div className={styles.NeuralAlert_buttonsWrapper}>
                                    <Button onClick={handleOpenModal} type="primary">
                                        <Locale stringKey={textData.button} />
                                    </Button>
                                    <a href={reviews.trialLicenseLink} rel="noreferrer" target="_blank">
                                        <Button type="link">
                                            <Locale stringKey="NEURAL_NETWORK__ALERT__INFO_LINK" />
                                        </Button>
                                    </a>
                                </div>
                            </Space>
                        </Space>
                        <div className={styles.NeuralAlert_imageBlock}>
                            {reviewGptReplyLicense?.canActivateTrial ? (
                                <img
                                    alt=""
                                    className={styles.NeuralAlert_connectTrialIcon}
                                    src={neuralRobotPictureSrc}
                                />
                            ) : (
                                <img alt="" className={styles.NeuralAlert_buyTrialIcon} src={neuralBuyPictureSrc} />
                            )}
                        </div>
                    </div>
                }
                onClose={() => onCloseAlert(reviewGptReplyLicense?.canActivateTrial)}
                showIcon={false}
                type="info"
            />
            <TrialActivationModal
                activateTrial={() => mutateAsync(LicensesCodeEnum.GptReplySuggestionsLicence)}
                isActivatingTrial={isLoading}
                onCancel={() => setIsOpenTrialModal(false)}
                onTrialPeriodActivated={() => {
                    setIsOpenTrialModal(false);
                    onCloseAlert(reviewGptReplyLicense?.canActivateTrial);
                    refetch();
                }}
                open={isOpenTrialModal}
                productNameLangKey="NEURAL_NETWORK__LICENSE__TITLE"
            />
            <TariffModalWrapper
                buttonTitle="BUTTON__ACTIVATE"
                isOpen={isOpenLicenseModal}
                onOk={handleBuyLicense}
                setIsOpen={setIsOpenLicenseModal}
                title="FAKES__TRIAL_LANDING__MODAL__TITLE"
            >
                <div className={styles.NeuralAlert_cardWrapper}>
                    <Text bolder lighter small stringKey="FAKES__TRIAL_LANDING__MODAL__PRODUCT__LABEL" />
                    <Text bolder stringKey="NEURAL_NETWORK__ALERT__PRODUCT_NAME" />
                </div>
            </TariffModalWrapper>
        </>
    );
}
