import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {faExclamationTriangle, faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Table, TableColumnsType, Tooltip} from 'antd';
import {upperFirst} from 'lodash';
import {useMemo} from 'react';

import {CatalogLogo} from '../../../../../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../../../../../component/text/text';
import {useCatalogInfo} from '../../../../../../../../provider/catalogs/catalogs-hook';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    CompaniesImportErrorType,
    useCompaniesImportErrors,
} from '../../../../../../../../service/companies-import/companies-import-result-errors';
import {CompaniesImportTypeEnum} from '../../../../../../../../service/companies-import/companies-import-type';

import * as styles from './feed-results-errors.scss';

type PropsType = {
    importValidationId: string;
    code: number;
    isCritical: boolean;
    feedFormat: CompaniesImportTypeEnum | null;
};

export function FeedResultsErrors(props: PropsType): JSX.Element {
    const {importValidationId, code, isCritical, feedFormat} = props;

    const {
        data,
        pagination: {page, pageSize, total, onChange},
    } = useCompaniesImportErrors(importValidationId, code);

    const {getCatalogName} = useCatalogInfo();
    const {getLocalizedString} = useLocale();

    const columns: TableColumnsType<CompaniesImportErrorType> = useMemo(
        () => [
            {
                key: 'name',
                dataIndex: 'name',
                title: <Locale stringKey="FEED__RESULTS__TABLE__COMPANY" />,
                width: '25%',
                render(_value, record) {
                    return (
                        <div className={styles.FeedResultsErrors_company}>
                            <div className={styles.FeedResultsErrors_companyTitle}>
                                <FontAwesomeIcon
                                    className={styles.FeedResultsErrors_companyIcon}
                                    icon={faMapMarkerAlt}
                                />
                                <span>{record.companyName}</span>
                                <Tooltip
                                    placement="bottom"
                                    title={
                                        <Locale
                                            stringKey={
                                                feedFormat === CompaniesImportTypeEnum.RocketdataCsv ||
                                                feedFormat === CompaniesImportTypeEnum.RocketdataXlsx
                                                    ? 'FEED__RESULTS__FEED_LINE_NUMBER__FILE'
                                                    : 'FEED__RESULTS__FEED_LINE_NUMBER__LINK'
                                            }
                                        />
                                    }
                                >
                                    <Text className={styles.FeedResultsErrors_companyCode} secondary>
                                        {record.companyCode} <FontAwesomeIcon icon={faCircleInfo} />
                                    </Text>
                                </Tooltip>
                            </div>
                            <Text lighter>{record.companyAddress}</Text>
                        </div>
                    );
                },
            },
            {
                key: 'code',
                dataIndex: 'code',
                title: <Locale stringKey="MAIN_FILTER__COMPANY_CODE__LABEL" />,
                width: '15%',
                render(_value, record) {
                    return <Text className={styles.FeedResultsErrors_branchCode}>{record.companyBranchCode}</Text>;
                },
            },
            {
                key: 'catalog',
                dataIndex: 'catalog',
                title: <Locale stringKey="FEED__RESULTS__TABLE__CATALOG" />,
                width: '15%',
                render(_value, record) {
                    if (record.catalogId) {
                        return (
                            <div className={styles.FeedResultsErrors_catalog}>
                                <CatalogLogo catalogId={record.catalogId} />
                                <span>{getCatalogName(record.catalogId)}</span>
                            </div>
                        );
                    }

                    if (record.catalog) {
                        return <div className={styles.FeedResultsErrors_catalog}>{record.catalog}</div>;
                    }

                    return (
                        <Text className={styles.FeedResultsErrors_catalog} italic secondary>
                            {upperFirst(getLocalizedString('TEXT__DEFAULT'))}
                        </Text>
                    );
                },
            },
            {
                key: 'error',
                dataIndex: 'error',
                title: <Locale stringKey="FEED__RESULTS__TABLE__FIELD" />,
                width: '45%',
                render(_value, record) {
                    return (
                        <div className={styles.FeedResultsErrors_errorMessage}>
                            <span>{record.field}: </span>
                            <span className={styles.FeedResultsErrors_danger}>{record.value}</span>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <Alert
                banner
                className={styles.FeedResultsErrors_alert}
                icon={isCritical ? <FontAwesomeIcon icon={faExclamationTriangle} /> : null}
                message={
                    <Locale
                        stringKey={
                            isCritical
                                ? 'FEED__RESULTS__CRITICAL_ERROR__DESCRIPTION'
                                : 'FEED__RESULTS__NON_CRITICAL_ERROR__DESCRIPTION'
                        }
                    />
                }
                type={isCritical ? 'error' : 'warning'}
            />

            <Text
                block
                className={styles.FeedResultsErrors_tableSummary}
                secondary
                stringKey="TEXT__TOTAL"
                valueMap={{
                    total: (
                        <Text bolder className={styles.FeedResultsErrors_tableSummaryStrong}>
                            {data?.count}
                        </Text>
                    ),
                }}
            />

            <Table
                columns={columns}
                dataSource={data?.results.map((error, index) => ({...error, key: index}))}
                onChange={(newPagination) => onChange(newPagination.current ?? 1, newPagination.pageSize)}
                pagination={{
                    position: ['topRight'],
                    simple: true,
                    current: page,
                    pageSize,
                    total,
                }}
            />
        </>
    );
}
