import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Descriptions, Space} from 'antd';
import {upperFirst} from 'lodash';
import {useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useFakesSubscriptions} from '../../../../../service/fakes/fakes-subscriptions';
import {FakeNamesComparingModeEnum} from '../../../../../service/fakes/fakes-type';
import {BuyLicenseModal} from '../../trial-landing/buy-license-modal/buy-license-modal';

import {FakesExpandableList} from './expandable-list/fakes-expandable-list';
import {FakesLicense} from './fakes-license/fakes-license';
import {citiesModeKey} from './fakes-settings-tab-const';
import * as styles from './fakes-settings-tab.scss';

type PropsType = {
    isTrial?: boolean;
};

export function FakesSettingsTab(props: PropsType): JSX.Element {
    const {isTrial} = props;
    const {data: subscription, isLoading} = useFakesSubscriptions();

    const {shortLocaleName} = useLocale();

    const displayName = useMemo(
        () => (Intl.DisplayNames ? new Intl.DisplayNames(shortLocaleName, {type: 'language'}) : null),
        [shortLocaleName]
    );

    const subscriptionItem = subscription && subscription[0];

    if (isLoading) {
        return <Spinner />;
    }

    const result = {
        languages: [...new Set(subscription?.map(({language}) => upperFirst(displayName?.of(language))))].join(', '),
        brandNames: [...new Set(subscription?.map(({brandName}) => brandName))],
        searchNames: [
            ...new Set(
                subscription?.reduce(
                    (accumulator: Array<string>, {searchNames}) => [...accumulator, ...searchNames],
                    []
                )
            ),
        ],
    };

    return (
        <>
            <Space align="end" className={styles.SettingsTab_planWrapper} size="large">
                <FakesLicense expire={subscriptionItem?.expire} isTrial={isTrial} license={subscriptionItem?.plan} />
                <Alert
                    className={styles.SettingsTab_alert}
                    description={
                        <Text
                            lighter
                            stringKey={isTrial ? 'FAKES__SETTINGS__ALERT__TRIAL' : 'FAKES__SETTINGS__ALERT'}
                            valueMap={{
                                link: <BuyLicenseModal buttonName="FAKES__SETTINGS__ALERT__TRIAL__LINK" isLinkType />,
                            }}
                        />
                    }
                    icon={<FontAwesomeIcon className={styles.SettingsTab_alertIcon} icon={faInfoCircle} />}
                    showIcon
                    type="info"
                />
            </Space>

            <Descriptions bordered className={styles.SettingsTab_descriptionsWrapper} column={1}>
                <Descriptions.Item label={<Text lighter stringKey="FAKES__SETTINGS__DESCRIPTION__SEARCH_TYPE" />}>
                    {subscriptionItem?.namesComparingMode === FakeNamesComparingModeEnum.Phonetic ? (
                        <AdditionalInfo
                            leftNode={<Text stringKey="FAKES__SETTINGS__COMPARING_MODE__PHONETIC" />}
                            placement="bottom"
                            title={<Text stringKey="FAKES__SETTINGS__COMPARING_MODE__PHONETIC" />}
                        >
                            <Locale stringKey="FAKES__SETTINGS__COMPARING_MODE__PHONETIC_DESCRIPTION" />
                        </AdditionalInfo>
                    ) : (
                        <AdditionalInfo
                            leftNode={<Text stringKey="FAKES__SETTINGS__COMPARING_MODE__STRICT" />}
                            placement="bottom"
                            title={<Text stringKey="FAKES__SETTINGS__COMPARING_MODE__STRICT" />}
                        >
                            <Locale stringKey="FAKES__SETTINGS__COMPARING_MODE__STRICT_DESCRIPTION" />
                        </AdditionalInfo>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={<Text lighter stringKey="FAKES__SETTINGS__DESCRIPTION__LANGUAGE" />}>
                    {result.languages}
                </Descriptions.Item>
                <Descriptions.Item label={<Text lighter stringKey="FAKES__SETTINGS__DESCRIPTION__SELECTED_CITIES" />}>
                    {subscriptionItem ? <Locale stringKey={citiesModeKey[subscriptionItem?.searchInCities]} /> : ''}
                </Descriptions.Item>
                <Descriptions.Item label={<Text lighter stringKey="FAKES__SETTINGS__DESCRIPTION__SELECTED_BRANDS" />}>
                    <FakesExpandableList
                        header={
                            <Locale
                                stringKey="FAKES__SETTINGS__DESCRIPTION__ALL_BRANDS"
                                valueMap={{count: result.brandNames.length}}
                            />
                        }
                        list={result.brandNames}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<Text lighter stringKey="FAKES__SETTINGS__DESCRIPTION__PROPOSED_TITLES" />}>
                    <FakesExpandableList
                        header={
                            <Locale
                                stringKey="FAKES__SETTINGS__DESCRIPTION__ALL_TITLES"
                                valueMap={{count: result.searchNames.length}}
                            />
                        }
                        list={result.searchNames}
                    />
                </Descriptions.Item>
            </Descriptions>
        </>
    );
}
