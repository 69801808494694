import {faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import {faSync, faTimesCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tag, Tooltip} from 'antd';
import {ReactNode} from 'react';

import {Locale} from '../../../../../../provider/locale/localization';
import {GoogleConfirmationRequestStatusEnum} from '../../../../../../service/google/confirmation/google-confirmation-type';
import {GoogleCclStatusEnum, GoogleCompanyType, GoogleLocationType} from '../../../../../../service/google/google-type';
import {classNames} from '../../../../../../util/css';

import * as styles from './company-list-item.scss';

const CCL_STATUS_TO_TAG_MAPPING: Record<GoogleCclStatusEnum, ReactNode> = {
    [GoogleCclStatusEnum.Synchronize]: (
        <Tag className={classNames(styles.Company_status, styles.Status__green)}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED" />
        </Tag>
    ),
    [GoogleCclStatusEnum.Send]: (
        <Tag className={styles.Company_status}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SENT" />
            <FontAwesomeIcon icon={faSync} />
        </Tag>
    ),
    [GoogleCclStatusEnum.ActionRequired]: (
        <Tag className={classNames(styles.Company_status, styles.Status__orange)}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__ACTION_REQUIRED" />
        </Tag>
    ),
    [GoogleCclStatusEnum.AwaitingRights]: (
        <Tag className={classNames(styles.Company_status, styles.Status__purple)}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_RIGHTS" />
            <FontAwesomeIcon icon={faExclamationCircle} />
        </Tag>
    ),
    [GoogleCclStatusEnum.AwaitingConfirmation]: (
        <Tag className={classNames(styles.Company_status, styles.Status__blue)}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__AWAITING_CONFIRMATION" />
            <FontAwesomeIcon icon={faExclamationCircle} />
        </Tag>
    ),
    [GoogleCclStatusEnum.Skipped]: (
        <Tag className={styles.Company_status}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SKIPPED" />
            <FontAwesomeIcon icon={faTimesCircle} />
        </Tag>
    ),
    [GoogleCclStatusEnum.ReadyToSend]: (
        <Tag className={styles.Company_status}>
            <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__READY_TO_SEND" />
            <FontAwesomeIcon icon={faSync} />
        </Tag>
    ),
    [GoogleCclStatusEnum.AwaitingVerification]: (
        <Tag className={styles.Company_status}>
            <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__AWAITING_VERIFICATION" />
        </Tag>
    ),
};

const REQUEST_STATUS_TO_TAG_MAPPING: Record<GoogleConfirmationRequestStatusEnum, ReactNode> = {
    [GoogleConfirmationRequestStatusEnum.Formed]: (
        <Tag className={styles.Company_status}>
            <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__CREATED" />
        </Tag>
    ),
    [GoogleConfirmationRequestStatusEnum.InProcess]: (
        <Tag className={classNames(styles.Company_status, styles.Status__blue)}>
            <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__IN_PROGRESS" />
        </Tag>
    ),
    [GoogleConfirmationRequestStatusEnum.Pending]: (
        <Tooltip placement="bottom" title={<Locale stringKey="GOOGLE_SYNC__FINISH_STEP__TOOLTIP_WAITING" />}>
            <Tag className={classNames(styles.Company_status, styles.Status__orange)}>
                <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__WAITING" />
                <FontAwesomeIcon icon={faExclamationCircle} />
            </Tag>
        </Tooltip>
    ),
    [GoogleConfirmationRequestStatusEnum.Completed]: (
        <Tag className={classNames(styles.Company_status, styles.Status__green)}>
            <Locale stringKey="GOOGLE_SYNC__CONFIRMATIONS__STATUS__COMPLETE" />
        </Tag>
    ),
};

export function getGoogleCompanyOrLocationStatusTag(
    companyOrLocation: GoogleCompanyType | GoogleLocationType
): ReactNode {
    if ('isVerified' in companyOrLocation) {
        return companyOrLocation.isVerified ? (
            <Tag className={styles.Company_status}>
                <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__CONFIRMED" />
            </Tag>
        ) : (
            <Tag className={classNames(styles.Company_status, styles.Status__orange)}>
                <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__NOT_CONFIRMED" />
                <FontAwesomeIcon icon={faExclamationCircle} />
            </Tag>
        );
    }

    if ('adminRightsUrl' in companyOrLocation) {
        return companyOrLocation.adminRightsUrl ? (
            <Tag className={classNames(styles.Company_status, styles.Status__purple)}>
                <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__HAS_OWNER" />
                <FontAwesomeIcon icon={faExclamationCircle} />
            </Tag>
        ) : (
            <Tag className={styles.Company_status}>
                <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__NO_OWNER" />
            </Tag>
        );
    }

    if ('status' in companyOrLocation && companyOrLocation.status) {
        return CCL_STATUS_TO_TAG_MAPPING[companyOrLocation.status];
    }

    return null;
}

export function getGoogleRequestStatusTag(status: GoogleConfirmationRequestStatusEnum): ReactNode {
    return REQUEST_STATUS_TO_TAG_MAPPING[status];
}
