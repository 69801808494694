import {faCircleArrowRight, faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ConfigProvider, Progress, Space} from 'antd';
import {ReactElement, useContext} from 'react';

import {appRoute} from '../../../app-route';
import {orange50, primary50} from '../../../css/var-export.scss';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {OnboardingContext} from '../../../provider/onboarding/onboarding-context';
import {classNames} from '../../../util/css';
import {Text} from '../../text/text';

import * as styles from './onboading-button.scss';

type PropsType = {
    inlineCollapsed: boolean;
};

export function OnboardingButton(props: PropsType): ReactElement | null {
    const {inlineCollapsed} = props;
    const {onboardingPercent, isOnboardingCompleted, showOnboarding, hasWarningStatus} = useContext(OnboardingContext);

    const onboardingButtonClassNames = classNames(styles.OnboardingButton, {
        [styles.OnboardingButton__needAction]: hasWarningStatus,
    });

    if (!showOnboarding) {
        return null;
    }

    const onboardingCircleClassNames = classNames(styles.OnboardingButton_circleProgressBar, {
        [styles.OnboardingButton_relativeProgressBar]: inlineCollapsed,
    });

    const iconClassNames = classNames(styles.OnboardingButton_icon, {
        [styles.OnboardingButton_iconCollapsed]: inlineCollapsed,
    });

    return (
        <NavigationLink to={appRoute.onboarding.path}>
            <ConfigProvider
                theme={{
                    components: {
                        Progress: {
                            defaultColor: hasWarningStatus ? orange50 : primary50,
                        },
                    },
                }}
            >
                <Button className={onboardingButtonClassNames}>
                    <Space
                        className={classNames(styles.OnboardingButton_description, {
                            [styles.OnboardingButton_centered]: inlineCollapsed,
                        })}
                    >
                        {!inlineCollapsed && (
                            <>
                                <Text
                                    bolder
                                    large
                                    stringKey={
                                        isOnboardingCompleted
                                            ? 'ONBOARDING__WHAT_NEXT'
                                            : hasWarningStatus
                                            ? 'ONBOARDING__STEP_STATUS__NEED_ACTION'
                                            : 'CATEGORY_NAME__ONBOARDING__DESCRIPTION'
                                    }
                                />
                                <FontAwesomeIcon
                                    className={styles.OnboardingButton_icon}
                                    icon={hasWarningStatus ? faCircleExclamation : faCircleArrowRight}
                                />
                            </>
                        )}
                        {inlineCollapsed && (
                            <div className={styles.OnboardingButton_circleProgressBarBlock}>
                                <Progress
                                    className={onboardingCircleClassNames}
                                    percent={onboardingPercent}
                                    showInfo={false}
                                    size={38}
                                    type="circle"
                                />
                                <FontAwesomeIcon
                                    className={iconClassNames}
                                    icon={hasWarningStatus ? faCircleExclamation : faCircleArrowRight}
                                />
                            </div>
                        )}
                    </Space>
                    {!inlineCollapsed && (
                        <Progress
                            className={styles.OnboardingButton_progressBar}
                            percent={onboardingPercent}
                            showInfo={false}
                        />
                    )}
                </Button>
            </ConfigProvider>
        </NavigationLink>
    );
}
