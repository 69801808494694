import {Table} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {useFormat} from '../../../../util/format-hook/format-hook';

import * as styles from './single-period-stats.scss';

type StatsRowType = {
    titleKey: LangKeyType;
    value: number;
};

type PropsType = {
    rows: Array<StatsRowType>;
    metricsNameHeadKey: LangKeyType;
};

export function SinglePeriodStatsTable(props: PropsType): JSX.Element {
    const {rows, metricsNameHeadKey} = props;

    const {getFormattedNumber} = useFormat();

    return (
        <Table<StatsRowType>
            className={styles.StatsTable}
            dataSource={rows}
            pagination={false}
            rowKey={(row) => row.titleKey}
        >
            <Table.Column<StatsRowType>
                align="left"
                dataIndex="title"
                key="prevText"
                render={(_value: unknown, rowData: StatsRowType): JSX.Element => (
                    <Locale stringKey={rowData.titleKey} />
                )}
                title={<Locale stringKey={metricsNameHeadKey} />}
            />
            <Table.Column<StatsRowType>
                align="right"
                dataIndex="value"
                key="prevText"
                render={(_value: unknown, rowData: StatsRowType): JSX.Element => (
                    <span>{getFormattedNumber(rowData.value)}</span>
                )}
                title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__COUNT" />}
            />
        </Table>
    );
}
