import {Form} from 'antd';
import {useMemo} from 'react';

import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../service/feature-companies/feature-companies-type';
import {
    PostAiSuggestHookType,
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    PostSourceEnum,
} from '../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../typings/antd';
import {NeverError} from '../../../../../../util/error';

import {FacebookCatalogData} from './facebook-catalog-data/facebook-catalog-data';
import {GoogleCatalogData} from './google-catalog-data/google-catalog-data';
import {InstagramCatalogData} from './instagram-catalog-data/instagram-catalog-data';
import {VkontakteCatalogData} from './vkontakte-catalog-data/vkontakte-catalog-data';
import {YandexCatalogData} from './yandex-catalog-data/yandex-catalog-data';

type PropsType = {
    source: PostSourceEnum;
    formInstance: FormInstance<PostFormType>;
    suggestSettings: PostAiSuggestHookType;
    formMode: PostFormModeEnum;
    formElementIndex: number;
    companiesSelector?: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
};

export function PostCatalogData(props: PropsType): JSX.Element {
    const {source, formInstance, formMode, formElementIndex, suggestSettings, companiesSelector} = props;

    const CatalogDataComponent = useMemo(() => {
        switch (source) {
            case PostSourceEnum.google: {
                return GoogleCatalogData;
            }
            case PostSourceEnum.yandex: {
                return YandexCatalogData;
            }
            case PostSourceEnum.vkontakte: {
                return VkontakteCatalogData;
            }
            case PostSourceEnum.facebook: {
                return FacebookCatalogData;
            }
            case PostSourceEnum.instagram: {
                return InstagramCatalogData;
            }
            default: {
                throw new NeverError(source);
            }
        }
    }, [source]);

    return (
        <Form.Item name={[PostFormFieldsEnum.Posts, formElementIndex]}>
            <CatalogDataComponent
                companiesSelector={companiesSelector}
                formInstance={formInstance}
                formMode={formMode}
                sourceIndex={formElementIndex}
                suggestSettings={suggestSettings}
            />
        </Form.Item>
    );
}
