import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCircleCheck, faExclamationCircle, faSpinner} from '@fortawesome/pro-solid-svg-icons';

import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {CclCompanyStatsType} from '../../../../../service/ccl/ccl-type';
import {CclErrorEnum, CclStateStatusEnum} from '../../../../../util/type';

import * as styles from './sources-synchronization.scss';

export function getVerificationStatusTextKey(cclCompanyStats: CclCompanyStatsType): LangKeyType {
    if (cclCompanyStats.needAction) {
        return 'SOURCES__STATUS__ACTION_REQUIRED';
    }

    return cclCompanyStats.stateStatus === CclStateStatusEnum.send
        ? 'SOURCES__STATUS__SENT'
        : 'SOURCES__STATUS__SYNCED';
}

export function getVerificationStatusIconData(cclCompanyStats: CclCompanyStatsType): {
    icon: IconProp;
    className: string;
} {
    if (cclCompanyStats.needAction) {
        return {
            icon: faExclamationCircle,
            className: styles.sources_synchronization__item__info__icon__needAction,
        };
    }

    if (cclCompanyStats.stateStatus === CclStateStatusEnum.send) {
        return {
            icon: faSpinner,
            className: styles.sources_synchronization__item__info__icon__sent,
        };
    }

    return {
        icon: faCircleCheck,
        className: styles.sources_synchronization__item__info__icon__synched,
    };
}

export function shouldShowSynchronizationDetails(catalogSyncronizationDetails: CclCompanyStatsType): boolean {
    if (catalogSyncronizationDetails.needAction) {
        return false;
    }

    if (catalogSyncronizationDetails.stateStatus === CclStateStatusEnum.send) {
        return catalogSyncronizationDetails.errors.length > 0;
    }

    return catalogSyncronizationDetails.stateStatus === CclStateStatusEnum.sync;
}

export const cclErrorTranslationMap: Record<CclErrorEnum, LangKeyType> = {
    [CclErrorEnum.formatted_address]: 'SOURCES__STATUS__ERROR__ADDRESS',
    [CclErrorEnum.name]: 'SOURCES__STATUS__ERROR__NAME',
    [CclErrorEnum.phones]: 'SOURCES__STATUS__ERROR__PHONES',
    [CclErrorEnum.website]: 'SOURCES__STATUS__ERROR__WEBSITE',
    [CclErrorEnum.working_hours]: 'SOURCES__STATUS__ERROR__WORKING_HOURS',
    [CclErrorEnum.closed_status]: 'SOURCES__ONBOARDING__POPOVER__STATUS_DATA__OPEN',
};
