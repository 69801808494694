import {camelize} from 'humps';
import {isEqual, omit} from 'lodash';

type CamelizeKeysPartialType = {
    object: unknown;
    ignorePaths?: Array<RegExp>;
    ignoreKeys?: Array<RegExp>;
    parentPath?: string;
};

export function isObjectInclude<ObjectType extends Record<string, unknown>>(
    object: ObjectType,
    query: Record<string, unknown>
): boolean {
    return Object.keys(query).every((queryKey: string): boolean => query[queryKey] === object[queryKey]);
}

export function getMapFromObject<MapType extends Record<string, unknown>>(
    object: Record<string, unknown>,
    keyMap: MapType
): MapType {
    const newKeyMap: MapType = {...keyMap};

    return Object.keys(newKeyMap).reduce<MapType>((_accumulator: MapType, key: string): MapType => {
        if (typeof newKeyMap[key] === typeof object[key]) {
            (newKeyMap as Record<string, unknown>)[key] = object[key];
        }

        return newKeyMap;
    }, newKeyMap);
}

export function isObjectsEqual<ItemType extends Record<string, unknown>>(
    objectA: ItemType | null,
    objectB: ItemType | null
): boolean {
    if (objectA !== null && objectB !== null) {
        return Object.keys(objectA).every((key) => objectA[key] === objectB[key]);
    }

    return objectA === objectB;
}

export function isRecord(object: unknown): object is Record<string, unknown> {
    return object !== null && typeof object === 'object';
}

export function camelizeKeysPartial({object, ignorePaths, ignoreKeys, parentPath}: CamelizeKeysPartialType): unknown {
    if (Array.isArray(object)) {
        return object.map((item) => camelizeKeysPartial({object: item, ignorePaths, ignoreKeys, parentPath}));
    }

    if (isRecord(object)) {
        const newEntries = Object.entries(object).map(([key, value]) => {
            const fullPath = parentPath ? parentPath + '.' + key : key;

            const isIgnorePath = ignorePaths && ignorePaths.some((pattern) => fullPath.match(pattern));
            const isIgnoreKey = ignoreKeys && ignoreKeys.some((pattern) => key.match(pattern));

            if (isIgnorePath) {
                return [key, value];
            }

            return [
                isIgnoreKey ? key : camelize(key),
                camelizeKeysPartial({object: value, ignorePaths, ignoreKeys, parentPath: fullPath}),
            ];
        });

        return Object.fromEntries(newEntries);
    }

    return object;
}

export function mapObjectValuesDeep(targetObject: object, handler: (value: string) => string): void {
    (Object.keys(targetObject) as Array<keyof typeof targetObject>).forEach((key) => {
        if (typeof targetObject[key] === 'object') {
            mapObjectValuesDeep(targetObject[key], handler);
        }

        // eslint-disable-next-line no-param-reassign
        (targetObject[key] as string) = handler(targetObject[key]);
    });
}

export function getFlatObjectArray(value: unknown): Array<unknown> {
    if (isRecord(value)) {
        return Object.entries(value).flatMap(([_key, keyValue]) => {
            if (isRecord(keyValue)) {
                return getFlatObjectArray(keyValue);
            }

            if (Array.isArray(keyValue)) {
                return keyValue.map(getFlatObjectArray);
            }

            return keyValue;
        });
    }

    return [value];
}

export function hasProperty<ObjectType, RequiredPropertyKey extends PropertyKey>(
    object: ObjectType,
    propertyKey: RequiredPropertyKey
): object is ObjectType & Record<RequiredPropertyKey, unknown> {
    return object instanceof Object && propertyKey in object;
}

export function getObjectsDiff<T extends Record<string, unknown>>(
    object1: Partial<T>,
    object2: Partial<T>,
    excludeFields: Array<keyof T> = []
): Partial<T> {
    const diffValues: Partial<T> = {};

    const objectRaw = omit(object1, excludeFields);

    const diffKeys: Array<keyof T> = Object.keys(objectRaw)
        .map((key) => (!isEqual(object1[key as keyof T], object2[key as keyof T]) ? key : null))
        .filter(Boolean);

    diffKeys.forEach((key) => {
        (diffValues[key] as unknown) = object2[key];
    });

    return diffValues;
}
