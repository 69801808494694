import {faCalendar} from '@fortawesome/pro-regular-svg-icons';
import {faNewspaper} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {ReactElement, useContext, useMemo} from 'react';

import {appRoute} from '../../../app-route';
import {AlertFallback} from '../../../component/alert-fallback/alert-fallback';
import {CursorPaginator} from '../../../component/cursor-paginator/cursor-paginator';
import {PageHeaderWithLicenseTag} from '../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {LicensePageCard} from '../../../component/license-page-card/license-page-card';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {useLicenses} from '../../../provider/license/license-hook';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {useUser} from '../../../provider/user/user-hook';
import {usePosts, usePostsCatalogs} from '../../../service/posts/posts-hooks';
import {FeaturesEnum} from '../../../service/user/user-type';
import {useUrl} from '../../../util/url-hook/url-hook';
import {productGroupsSizeOptions} from '../price/products-const';

import {PostsControl} from './posts-control/posts-control';
import {PostsFilterQueriesType} from './posts-filter/posts-filter-type';
import {getPostsStateFromUrlQuery} from './posts-state/posts-state-helper';
import {PostsTable} from './posts-table/posts-table';
import * as postsCalendarStyles from './calendar/posts-calendar.scss';
import * as styles from './posts.scss';

export function Posts(): ReactElement {
    const {getLocalizedString} = useLocale();

    const {postsManagement, postsManagementCreatePost, postsManagementCalendar, accountLinkingSettings} = appRoute;

    const {queries: queries} = useUrl<PostsFilterQueriesType>();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const postsFilterQueries: PostsFilterQueriesType = useMemo(() => getPostsStateFromUrlQuery(queries), [queries]);

    const {user} = useUser();

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.posts];

    const isLicenseExpired = !license?.isActive;

    const {
        result: posts,
        isInProgress: isPostsInProgress,
        processError: postsProcessError,
        refresh,
        cursorPagination,
    } = usePosts({
        filter: postsFilterQueries,
        mainFilterKey: user?.isDemoUser ? '' : mainFilterKey,
    });

    const {
        result: catalogs,
        isInProgress: isCatalogsInProgress,
        processError: catalogsProcessError,
    } = usePostsCatalogs();

    const processError = postsProcessError || catalogsProcessError;
    const isInProgress = isPostsInProgress || isCatalogsInProgress;

    return (
        <Page
            isTopFilterHidden={user?.isDemoUser}
            renderContainer={(pageContent) => <MainPageContainer>{pageContent}</MainPageContainer>}
        >
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__POSTS')} />
                <BreadCrumbs list={[{path: postsManagement.path, titleLangKey: 'CATEGORY_NAME__POSTS'}]} />

                <PageHeaderWithLicenseTag
                    headerLangKey="CATEGORY_NAME__POSTS"
                    licenseActive={license?.isActive}
                    productName={UsetifulNameProductEnum.POSTS}
                    trialLicense={license?.isTrial}
                >
                    <div className={styles.posts__header__button_wrapper}>
                        <NavigationLink
                            className={postsCalendarStyles.PostCalendar_link}
                            to={postsManagementCalendar.path}
                        >
                            <Button
                                className={postsCalendarStyles.PostCalendar_button}
                                disabled={isLicenseExpired}
                                icon={
                                    <FontAwesomeIcon
                                        className={postsCalendarStyles.PostCalendar_icon}
                                        icon={faCalendar}
                                    />
                                }
                                type="default"
                            >
                                <Locale stringKey="POSTS__CALENDAR_PAGE" />
                            </Button>
                        </NavigationLink>

                        <NavigationLink to={accountLinkingSettings.path}>
                            <Button disabled={isLicenseExpired}>
                                <Locale stringKey="POSTS__ACCOUNTS_SETTINGS" />
                            </Button>
                        </NavigationLink>

                        <NavigationLink to={postsManagementCreatePost.path}>
                            <Button disabled={isLicenseExpired} type="primary">
                                <Locale stringKey="POSTS__CREATE_POST" />
                            </Button>
                        </NavigationLink>
                    </div>
                </PageHeaderWithLicenseTag>
                <MainPageCardDivider />
                {!isLicenseExpired && <PostsControl catalogs={catalogs || []} />}
            </PageCard>

            <LicensePageCard
                icon={<FontAwesomeIcon icon={faNewspaper} />}
                license={license}
                productLandKey="CATEGORY_NAME__POSTS"
            />

            {processError && !isLicenseExpired && <AlertFallback />}

            {!isLicenseExpired && (
                <PageCard>
                    <PostsTable datasource={posts || []} isLoadingData={isInProgress} onActionCompleted={refresh} />
                    <CursorPaginator cursorPagination={cursorPagination} pageSizeOptions={productGroupsSizeOptions} />
                </PageCard>
            )}
        </Page>
    );
}
