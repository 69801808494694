import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCheckSquare, faTimesSquare, faUserTie} from '@fortawesome/pro-regular-svg-icons';

import {Locale} from '../../../../../../provider/locale/localization';
import {
    GoogleAccountType,
    GoogleAccountTypeEnum,
    GoogleVerificationStateEnum,
} from '../../../../../../service/google/accounts/google-accounts-type';

import * as styles from './account-tag.scss';

type GoogleAccountTagType = {
    isShown: (account: GoogleAccountType) => boolean;
    label: JSX.Element;
    icon: IconProp;
    tooltip?: JSX.Element;
    className?: string;
};

export const googleAccountTags: Array<GoogleAccountTagType> = [
    {
        isShown: (account) => account.accountType === GoogleAccountTypeEnum.Personal,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__PERSONAL" />,
        icon: faUserTie,
    },
    {
        isShown: (account) =>
            account.accountType === GoogleAccountTypeEnum.LocationGroup &&
            account.verificationState === GoogleVerificationStateEnum.Verified,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__LOCATION_GROUP" />,
        tooltip: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__TOOLTIP__VERIFIED_GROUP" />,
        icon: faCheckSquare,
        className: styles.AccountTag__verified,
    },
    {
        isShown: (account) =>
            account.accountType === GoogleAccountTypeEnum.LocationGroup &&
            account.verificationState === GoogleVerificationStateEnum.VerificationRequested,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__LOCATION_GROUP" />,
        tooltip: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__TOOLTIP__VERIFICATION_REQUESTED_GROUP" />,
        icon: faCheckSquare,
        className: styles.AccountTag__requested,
    },
    {
        isShown: (account) =>
            account.accountType === GoogleAccountTypeEnum.LocationGroup &&
            account.verificationState !== GoogleVerificationStateEnum.Verified,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__LOCATION_GROUP" />,
        tooltip: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__TOOLTIP__NOT_VERIFIED_GROUP" />,
        icon: faTimesSquare,
    },
    {
        isShown: (account) => account.accountType === GoogleAccountTypeEnum.UserGroup,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__USER_GROUP" />,
        icon: faTimesSquare,
    },
    {
        isShown: (account) => account.accountType === GoogleAccountTypeEnum.Organization,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__ORGANIZATION" />,
        icon: faTimesSquare,
    },
    {
        isShown: (account) => account.accountType === GoogleAccountTypeEnum.Unspecified,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__UNSPECIFIED" />,
        icon: faTimesSquare,
    },
];
