import {InputNumber, Select, Space} from 'antd';
import {omit} from 'lodash';
import {useState} from 'react';

import {Form} from '../../../../../../../typings/antd';
import {
    AttributePropsType,
    AttributeValueType,
    InUnitsSingleAttributeType,
    InUnitsSingleAttributeValueType,
} from '../attributes-type';

import * as styles from './in-units-attribute.scss';

export function InUnitsAttribute({
    value,
    onChange,
}: AttributePropsType<InUnitsSingleAttributeType>): JSX.Element | null {
    const {displayName: label, values} = value;

    const [selectedValue, setSelectedValue] = useState(values.find((item) => item.value) || values[0]);
    const {status} = Form.Item.useStatus();

    function handleOnChange(newValue: string | null) {
        // eslint-disable-next-line no-undefined
        const newValueInner = newValue ?? undefined;

        onChange?.({
            ...value,
            values: values.map((item) =>
                item.templateId === selectedValue?.templateId ? {...item, value: newValueInner} : item
            ),
        });

        if (selectedValue) {
            setSelectedValue({...selectedValue, value: newValueInner});
        }
    }

    function handleOnSelectChange(option: string | number) {
        let newSelectedValue: AttributeValueType<InUnitsSingleAttributeValueType> | null = null;

        onChange?.({
            ...value,
            values: values.map((item) => {
                if (item.templateId === option) {
                    newSelectedValue = {
                        ...item,
                        selected: true,
                        value: selectedValue?.value,
                    };

                    return newSelectedValue;
                }

                return item.templateId === selectedValue?.templateId
                    ? (omit(item, ['selected', 'value']) as AttributeValueType<InUnitsSingleAttributeValueType>)
                    : item;
            }),
        });

        if (newSelectedValue) {
            setSelectedValue(newSelectedValue);
        }
    }

    if (!selectedValue) {
        return null;
    }

    return (
        <Form.Item label={label}>
            <Space size={0}>
                <InputNumber
                    className={styles.attribute__in_units__input}
                    onChange={handleOnChange}
                    status={status === 'error' ? status : undefined} // eslint-disable-line no-undefined
                    type="number"
                    value={selectedValue.value}
                />

                <Select
                    className={styles.attribute__in_units__select}
                    onChange={handleOnSelectChange}
                    popupMatchSelectWidth={false}
                    value={selectedValue.templateId}
                >
                    {values.map((option) => (
                        <Select.Option key={option.templateId} value={option.templateId}>
                            {option.unitValue}
                        </Select.Option>
                    ))}
                </Select>
            </Space>
        </Form.Item>
    );
}
