import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Space, Tooltip} from 'antd';
import {ReactElement, useMemo} from 'react';

import {gray7} from '../../../../../../css/var-export.scss';
import {Locale} from '../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {EditUserType} from '../../../../../../service/user-management/user-management-edit-user';
import {UsersFormKeyEnum, UsersFormType} from '../../../../../../service/user-management/user-management-users';
import {FormInstance} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';
import {UserRulesSelect} from '../user-rules-select/user-rules-select';

import {useUserSourcesHook} from './user-sources-hook';
import * as styles from './user-sources-select.scss';

type PropsType = {
    form: FormInstance<UsersFormType>;
    user?: EditUserType;
};

export function UserSourcesSelect(props: PropsType): ReactElement {
    const {form, user} = props;

    const {data: reviewsCatalogList = [], isLoading: reviewsCatalogListIsInProgress} = useUserSourcesHook();
    const {requiredFieldRule} = useFormRules();
    const sources = Form.useWatch(UsersFormKeyEnum.Sources, form);

    const sourcesInitialData = useMemo(() => {
        return user?.catalogs;
    }, [user?.catalogs]);

    const sourcesOptions = useMemo(() => {
        return reviewsCatalogList
            ?.filter((item) => (!user ? item.isAvailable : true))
            .map((catalog) => ({
                value: catalog.id,
                label: catalog.name,
                disabled: !catalog.isAvailable,
            }));
    }, [reviewsCatalogList, user]);

    const formItemClassNames = classNames({
        [styles.SourcesSelect_editForm]: Boolean(user),
    });

    return (
        <Form.Item
            className={formItemClassNames}
            label={
                <Space>
                    <Locale stringKey="USERS__FORM__SOURCES__LABEL" />
                    <Tooltip placement="top" title={<Locale stringKey="USERS__FORM__SOURCES__TOOLTIP" />}>
                        <FontAwesomeIcon color={gray7} icon={faCircleInfo} />
                    </Tooltip>
                </Space>
            }
            name={UsersFormKeyEnum.Sources}
            rules={[requiredFieldRule]}
        >
            <UserRulesSelect
                allSelectPlaceholder={<Locale stringKey="USERS__FORM__SOURCES__MAX_TAG_PLACEHOLDER" />}
                handleFormChange={(value) => form.setFieldValue(UsersFormKeyEnum.Sources, value)}
                initialData={sourcesInitialData}
                isLoading={reviewsCatalogListIsInProgress}
                options={sourcesOptions}
                placeholder={<Locale stringKey="REVIEWS__FILTER__SELECT_A_SOURCE" />}
                selectValue={sources || []}
            />
        </Form.Item>
    );
}
