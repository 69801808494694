import {Children, PropsWithChildren} from 'react';

import {classNames} from '../../util/css';
import {Text} from '../text/text';

import * as styles from './antd-error-message.scss';

type PropsType = PropsWithChildren<{
    className?: string;
}>;

export function AntdErrorMessage(props: PropsType): JSX.Element {
    const {className, children} = props;

    return (
        <>
            {Children.map(children, (error) => (
                <div
                    className={classNames('ant-form-item-explain', styles.antd_error_message, className)}
                    key={error?.toString()}
                >
                    <span className="ant-form-item-explain-error" role="alert">
                        {error ? <Text type="danger">{error.toString()}</Text> : null}
                    </span>
                </div>
            ))}
        </>
    );
}
