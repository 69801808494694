import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover, TableColumnsType} from 'antd';

import {appRoute} from '../../../../../app-route';
import {Text} from '../../../../../component/text/text';
import {FIXED_COLUMN_THRESHOLD} from '../../../../../const';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {FormattedDateTime} from '../../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../../provider/locale/locale';
import {LocalPackCatalogsEnum, LocalPackServicesEnum} from '../../../../../service/local-pack/local-pack-type';
import {LocalPackSearchPhrasesStatisticsItemType} from '../../../../../service/local-pack/search-phrase-analytics/local-pack-search-phrases-statistics-type';
import {generatePathWithQueryParameters} from '../../../../../util/url';
import {LocalPackNameAddressCell} from '../../table-cells/local-pack-name-address-cell/local-pack-name-address-cell';
import {LocalPackPositionChange} from '../../table-cells/local-pack-position-change/local-pack-position-change';
import {LocalPackTopFive} from '../../table-cells/local-pack-top-five/local-pack-top-five';

export const LOCAL_PACK_SEARCH_PHRASES_STATISTICS_TABLE_WIDTH = 2440;

function getTopFiveWidth(isEmptyTopFive: boolean): number {
    return isEmptyTopFive ? 70 : 344;
}

export function getLocalPackSearchPhrasesStatisticsColumns(
    screenWidth: number,
    isEmptyTopFive: boolean
): TableColumnsType<LocalPackSearchPhrasesStatisticsItemType> {
    return [
        {
            width: 344,
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COMPANY" />,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <LocalPackNameAddressCell address={entry.company.address} name={entry.company.name} />
            ),
            fixed: FIXED_COLUMN_THRESHOLD < screenWidth ? 'left' : false,
        },
        {
            align: 'left',
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__KEY" />,
            width: 200,
            fixed: FIXED_COLUMN_THRESHOLD < screenWidth ? 'left' : false,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <div>{entry.rule.searchQuery}</div>
            ),
        },
        {
            align: 'center',
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__GRID_SIZE" />,
            width: 100,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.rule.gridSize}
                </Text>
            ),
        },
        {
            align: 'right',
            width: 150,
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__GRID_STEP" />,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <Text block tabularNums>
                    <Locale
                        stringKey="LOCAL_PACK__DISTANCE"
                        valueMap={{
                            distance: entry.rule.gridStep,
                        }}
                    />
                </Text>
            ),
        },
        {
            align: 'right',
            width: 130,
            title: (
                <Locale
                    stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COST"
                    valueMap={{
                        description: (
                            <Popover
                                content={
                                    <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COST__DESCRIPTION" />
                                }
                                key="description"
                                trigger="hover"
                            >
                                <Text secondary>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Text>
                            </Popover>
                        ),
                    }}
                />
            ),
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.cost}
                </Text>
            ),
        },
        {
            align: 'right',
            width: 185,
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__COMPLETED_SEARCHES" />,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <Text block tabularNums>
                    {entry.completedSearches}
                </Text>
            ),
        },

        {
            align: 'left',
            sorter: true,
            dataIndex: 'position',
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__AVERAGE_POSITION" />,
            width: 110,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <LocalPackPositionChange currentPosition={entry.position.current} delta={entry.position.delta} />
            ),
        },
        {
            align: 'center',
            sorter: true,
            dataIndex: 'date',
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__LAST_SEARCH_DATE" />,
            width: 170,
            render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                <NavigationLink
                    to={generatePathWithQueryParameters(appRoute.localPackResult.path, {
                        query: {
                            companyId: entry.company.id,
                            searchQueryId: entry.rule.searchQueryId,
                        },
                        parametersURL: {
                            onDate: entry.lastSearchDate,
                        },
                    })}
                >
                    <FormattedDateTime value={new Date(entry.lastSearchDate)} />
                </NavigationLink>
            ),
        },
        {
            align: 'center',
            dataIndex: 'top5',
            title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE" />,
            children: [
                {
                    align: 'center',
                    dataIndex: 'top5',
                    title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__GOOGLE__MAPS" />,
                    width: getTopFiveWidth(isEmptyTopFive),
                    render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                        <LocalPackTopFive
                            entry={entry}
                            provider={LocalPackCatalogsEnum.google}
                            service={LocalPackServicesEnum.maps}
                        />
                    ),
                },
                {
                    align: 'center',
                    dataIndex: 'top5',
                    title: (
                        <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__GOOGLE__DESKTOP" />
                    ),
                    width: getTopFiveWidth(isEmptyTopFive),
                    render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                        <LocalPackTopFive
                            entry={entry}
                            provider={LocalPackCatalogsEnum.google}
                            service={LocalPackServicesEnum.desktop}
                        />
                    ),
                },
                {
                    align: 'center',
                    dataIndex: 'top5',
                    title: <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__YANDEX__MAPS" />,
                    width: getTopFiveWidth(isEmptyTopFive),
                    render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                        <LocalPackTopFive
                            entry={entry}
                            provider={LocalPackCatalogsEnum.yandex}
                            service={LocalPackServicesEnum.maps}
                        />
                    ),
                },
                {
                    align: 'center',
                    dataIndex: 'top5',
                    title: (
                        <Locale stringKey="LOCAL_PACK__SEARCH_PHRASES_STATISTICS__COLUMN__TOP_FIVE__YANDEX__DESKTOP" />
                    ),
                    width: getTopFiveWidth(isEmptyTopFive),
                    render: (_value: unknown, entry: LocalPackSearchPhrasesStatisticsItemType): JSX.Element => (
                        <LocalPackTopFive
                            entry={entry}
                            provider={LocalPackCatalogsEnum.yandex}
                            service={LocalPackServicesEnum.desktop}
                        />
                    ),
                },
            ],
        },
    ];
}
