import {DefaultTooltipStaticPropsType} from '../../../../component/chart-tooltip/default-chart-tooltip';
import {getDefaultReactTooltipOptions} from '../../../../layout/chart/chart-const';
import {defaultLineChartOptions} from '../../../../layout/chart/line-chart/line-chart-helper';
import {ChartJsLineOptionsType} from '../../../../layout/chart/line-chart/line-chart-type';

export function getImpressionsDynamicsChartOptions(
    tooltipOptions: DefaultTooltipStaticPropsType,
    showTooltip?: boolean
): ChartJsLineOptionsType {
    return {
        ...defaultLineChartOptions,

        plugins: {
            ...defaultLineChartOptions.plugins,
            legend: {
                display: false,
            },
            tooltip: showTooltip ? getDefaultReactTooltipOptions(tooltipOptions) : {enabled: false},
            datalabels: {
                display: false,
            },
        },
    };
}
