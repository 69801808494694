import {useMemo} from 'react';

import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';
import {NavigationLink} from '../navigation-link/navigation-link';

import {getActionThumbUiData} from './action-thumb-helper';
import {ActionThumbStateNameEnum} from './action-thumb-type';
import * as styles from './action-thumb.scss';

type PropsType = {
    className?: string;
    value: number | string;
    type: ActionThumbStateNameEnum;
    onClick?: () => void;
    isActive?: boolean;
    href?: string;
};

export function ActionThumb(props: PropsType): JSX.Element {
    const {className, value, type, onClick, isActive = false, href} = props;
    const {className: actionThumbClassName, langKey, image} = getActionThumbUiData(type);

    const fullClassName = classNames(
        styles.ActionThumb,
        actionThumbClassName,
        {
            [styles.ActionThumb_asButton]: Boolean(onClick) || Boolean(href),
            [styles.ActionThumb__isSelected]: isActive,
        },
        className
    );

    const content = useMemo((): JSX.Element => {
        return (
            <>
                <h5 className={styles.ActionThumb_header}>
                    <Locale stringKey={langKey} />
                </h5>

                <div className={styles.ActionThumb_content}>
                    {image}

                    <p className={styles.ActionThumb_value}>{value}</p>
                </div>
            </>
        );
    }, [image, langKey, value]);

    if (href) {
        return (
            <NavigationLink
                className={classNames(fullClassName, styles.ActionThumb_asLink)}
                onClick={onClick}
                to={href}
            >
                {content}
            </NavigationLink>
        );
    }

    if (onClick) {
        return (
            <button className={fullClassName} onClick={onClick} type="button">
                {content}
            </button>
        );
    }

    return (
        <article aria-label={type} className={fullClassName}>
            {content}
        </article>
    );
}
