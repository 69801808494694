import {AddressType} from './address-type';

export function formatAddress(
    address: Pick<AddressType, 'country' | 'region' | 'city' | 'street' | 'housenumber'> | string | null
): string {
    if (typeof address === 'string') {
        return address;
    }

    const {country, region, city, street, housenumber} = address || {};

    return [country, region, city, street, housenumber].filter(Boolean).join(', ') || '—';
}
