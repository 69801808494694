import {debounce} from 'lodash';
import {Dispatch, SetStateAction, useCallback, useEffect, useRef} from 'react';

import {WidgetKeysEnum} from '../company-form-const';

import {findKeyOfFirstWidgetWithPositiveTopOffset, scrollIntoWidget} from './navigation-helper';

type ResultType = {
    changeSelected: (key: WidgetKeysEnum) => Promise<void>;
};

export function useScroller(
    initialValue: boolean,
    setState: Dispatch<SetStateAction<WidgetKeysEnum | null>>
): ResultType {
    const isInScrolling = useRef(initialValue);

    const changeSelected = useCallback(
        async (key: WidgetKeysEnum) => {
            setState(key as WidgetKeysEnum);

            isInScrolling.current = true;

            await scrollIntoWidget(key as WidgetKeysEnum, false);

            isInScrolling.current = false;
        },
        [setState]
    );

    useEffect(() => {
        const handler = debounce(function handler() {
            // block subscription if browser is in scrolling right now
            // it might be if the user picks on of the menu item for example
            if (isInScrolling.current) {
                return;
            }

            const key = findKeyOfFirstWidgetWithPositiveTopOffset();

            if (key) {
                setState(key);
            }
        }, 300);

        window.addEventListener('scroll', handler);

        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, [setState]);

    return {changeSelected};
}
