import {z as r} from 'zod';

export const reviewsPopularTagSchema = r.object({
    id: r.number(),
    title: r.string(),
    reviewsCount: r.object({
        value: r.number(),
        comparedValue: r.number().nullish(),
        delta: r.number().nullish(),
    }),
});

export type ReviewsPopularTagType = r.infer<typeof reviewsPopularTagSchema>;
