import {Button, Checkbox, Select, SelectProps} from 'antd';
import {lowerFirst} from 'lodash';
import {BaseSelectRef} from 'rc-select';
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react';

import {Locale} from '../../provider/locale/locale';
import {LocalePlural} from '../../provider/locale/locale-plural';
import {LangKeyType} from '../../provider/locale/translation/type';
import {handleFilterOption} from '../../util/antd/select-helper';
import {classNames} from '../../util/css';

import {handleKeyDown} from './select-with-checkboxes-helper';
import * as styles from './select-with-checkboxes.scss';

const {Option} = Select;

type OptionType<ValueType> = {
    value: ValueType;
    label: string;
    disabled?: boolean;
};

type OptionListType<ValueType> = OptionType<ValueType> & {
    checked: boolean;
};

type IgnoredPropsType =
    | 'options'
    | 'dropdownRender'
    | 'filterOption'
    | 'getPopupContainer'
    | 'maxTagCount'
    | 'maxTagPlaceholder'
    | 'mode'
    | 'onChange'
    | 'onInputKeyDown'
    | 'onSearch'
    | 'open'
    | 'searchValue'
    | 'value'
    | 'placeholder';

type PropsType<ValueType> = Omit<SelectProps<Array<ValueType>>, IgnoredPropsType> & {
    initialValue: Array<ValueType>;
    onConfirm: (value: Array<ValueType>) => void;
    options: Array<OptionType<ValueType>>;
    placeholder?: string;
    selectedPlaceholder?: string;
    wrapperClassName?: string;
    hideFooter?: boolean;
    withoutIndeterminate?: boolean;
    restSelectedItemsPluralLocales?: {
        fewKey: LangKeyType;
        manyKey: LangKeyType;
        singularKey: LangKeyType;
    };
    selectAllTitle?: ReactElement;
};

export function SelectWithCheckboxes<ValueType>(props: PropsType<ValueType>): JSX.Element {
    const {
        initialValue,
        onConfirm,
        selectedPlaceholder,
        options,
        wrapperClassName,
        restSelectedItemsPluralLocales,
        selectAllTitle,
        hideFooter,
        withoutIndeterminate,
        ...selectProps
    } = props;

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [optionsList, setOptionsList] = useState<Array<OptionListType<ValueType>>>([]);
    const [selectedValues, setSelectedValues] = useState<Array<ValueType>>([]);
    const [initialValues, setInitialValues] = useState<Array<ValueType>>([]);
    const [searchText, setSearchText] = useState<string>('');

    const containerRef = useRef<HTMLDivElement | null>(null);
    const selectRef = useRef<BaseSelectRef | null>(null);

    useEffect(() => {
        const newOptions = options.map((option) => ({
            value: option.value,
            label: option.label,
            checked: initialValue.includes(option.value),
            disabled: option.disabled,
        }));

        setOptionsList(newOptions);
        setInitialValues(initialValue);
        setSelectedValues(initialValue);

        if (initialValues.length > 0 && initialValues.length === optionsList.length) {
            setIsAllSelected(true);
        }
    }, [options, initialValue, optionsList.length, initialValues.length]);

    function handleChange(value: Array<ValueType>) {
        setSelectedValues((previous) => {
            const newSelectedValues = [...value];

            if (newSelectedValues.length <= previous.length) {
                const difference = previous.find((item) => !newSelectedValues.includes(item));

                setIsAllSelected(false);

                return previous.filter((item) => item !== difference);
            }

            setIsAllSelected(newSelectedValues.length === optionsList.length);

            return newSelectedValues;
        });
    }

    useEffect(() => {
        setOptionsList((listOptions) =>
            listOptions.map((option) => {
                return {
                    value: option.value,
                    label: option.label,
                    checked: selectedValues.includes(option.value),
                    disabled: option.disabled,
                };
            })
        );
    }, [selectedValues]);

    function handleSelectAllSources() {
        setIsAllSelected((previous) => {
            setSelectedValues(() => {
                if (previous) {
                    return [];
                }

                return optionsList.map((option) => option.value);
            });

            return !previous;
        });
    }

    function handleCancelClick() {
        setIsDropdownOpened(false);
        setSelectedValues(initialValues);
        setSearchText('');

        if (isAllSelected && initialValues.length === 0) {
            setIsAllSelected(false);
        }
    }

    const handleConfirmClick = useCallback(() => {
        const selectedOptions = optionsList.filter((option) => option.checked).map((option) => option.value);

        onConfirm(selectedOptions);
        setIsDropdownOpened(false);
        setInitialValues(selectedValues);
        setSearchText('');

        selectRef.current?.blur(); // safari focus fix
    }, [onConfirm, optionsList, selectedValues]);

    function handleSearch(value: string) {
        setSearchText(value);
    }

    function dropdownRender(menu: ReactElement): JSX.Element {
        const hasSelectedItems = optionsList.some((option) => option.checked);
        const isDisabledOption = optionsList.some((option) => option.disabled);

        return (
            <>
                {searchText === '' && (
                    <div className={styles.SelectWithConfirm_selectOption}>
                        <Checkbox
                            checked={isAllSelected}
                            disabled={isDisabledOption}
                            indeterminate={!withoutIndeterminate && !isAllSelected && hasSelectedItems}
                            onChange={handleSelectAllSources}
                        >
                            {selectAllTitle ?? (
                                <>
                                    <Locale stringKey="DROPDOWN_WITH_CONFIRM_COUNT_ALL" />{' '}
                                    {selectProps.placeholder && lowerFirst(selectProps.placeholder)}
                                </>
                            )}
                        </Checkbox>
                    </div>
                )}
                <div className={styles.SelectWithConfirm_selectOptions}>{menu}</div>
                {!hideFooter && (
                    <div className={styles.SelectWithConfirm_dropdownFooter}>
                        <Button onClick={handleCancelClick} size="small" type="text">
                            <Locale stringKey="BUTTON__CANCEL" />
                        </Button>
                        <div>
                            {selectedValues.length > 0 && (
                                <span className={styles.SelectWithConfirm_selectedCount}>
                                    <Locale
                                        stringKey="DROPDOWN_WITH_CONFIRM_COUNT"
                                        valueMap={{count: selectedValues.length}}
                                    />
                                </span>
                            )}
                            <Button onClick={handleConfirmClick} size="small" type="primary">
                                <Locale stringKey="TEXT__OK" />
                            </Button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    function maxTagPlaceholder() {
        const count =
            selectedValues.length === optionsList.length ? (
                <Locale stringKey="DROPDOWN_WITH_CONFIRM_COUNT_ALL" />
            ) : (
                selectedValues.length
            );

        if (restSelectedItemsPluralLocales) {
            return (
                <LocalePlural
                    count={selectedValues.length}
                    fewKey={restSelectedItemsPluralLocales.fewKey}
                    manyKey={restSelectedItemsPluralLocales.manyKey}
                    singularKey={restSelectedItemsPluralLocales.singularKey}
                    valueMap={{
                        count: <span className={styles.SelectWithConfirm_selectSelectedCount}>{count}</span>,
                    }}
                />
            );
        }

        if (selectProps.placeholder) {
            return (
                <>
                    {selectProps.placeholder}:{' '}
                    <span className={styles.SelectWithConfirm_selectSelectedCount}>{count}</span>
                </>
            );
        }

        return (
            selectedPlaceholder ?? (
                <Locale
                    stringKey="DROPDOWN_WITH_CONFIRM_COUNT"
                    valueMap={{count: <span className={styles.SelectWithConfirm_selectSelectedCount}>{count}</span>}}
                />
            )
        );
    }

    useEffect(() => {
        function handleClick({target}: MouseEvent) {
            if (!containerRef.current?.contains(target as HTMLElement) && isDropdownOpened) {
                handleConfirmClick();
            }
        }

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, [handleConfirmClick, isDropdownOpened]);

    return (
        <div
            className={classNames(styles.SelectWithConfirm, wrapperClassName)}
            onFocus={() => setIsDropdownOpened(true)}
            ref={containerRef}
        >
            <Select<Array<ValueType>>
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...selectProps}
                dropdownRender={dropdownRender}
                filterOption={(input, option) => handleFilterOption(input, option, true)}
                getPopupContainer={() => containerRef.current || document.body}
                maxTagCount={0}
                maxTagPlaceholder={maxTagPlaceholder}
                mode="multiple"
                onChange={(value) => handleChange(value)}
                onInputKeyDown={handleKeyDown}
                onSearch={handleSearch}
                open={isDropdownOpened}
                ref={selectRef}
                searchValue={searchText}
                value={selectedValues}
            >
                {optionsList?.map((option: OptionListType<ValueType>): JSX.Element => {
                    const {value, label, checked, disabled} = option;

                    return (
                        <Option disabled={disabled} key={String(value) + label} value={value}>
                            <Checkbox
                                checked={checked}
                                className={styles.SelectWithConfirm_selectOptionCheckbox}
                                disabled={disabled}
                            />
                            {label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
}
