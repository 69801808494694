import {Checkbox} from 'antd';
import {Key, useCallback, useEffect, useState} from 'react';

import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {PaginatedResponseType} from '../../../../service/api/api-type';
import {useAvailableForUpdateCompaniesCount} from '../../../../service/company/company-hook';
import {CompanyShortType, PermissionNameEnum} from '../../../../service/company/company-type';
import {serializeBulkEditCompaniesFilters} from '../../bulk-edit-companies/bulk-edit-companies-helper';

import {SELECTED_ALL} from './companies-table-const';
import {MyCompaniesTableRowDataType} from './companies-table-type';

type PropsType = {
    companiesResult: PaginatedResponseType<CompanyShortType> | null;
    mainFilterKey: string;
    newCompanies?: boolean;
    yandexNeedActualization?: string | null;
};

type RowSelectionType = {
    selectedRows: Array<string> | typeof SELECTED_ALL;
    excludedRows: Array<string>;
    selectedCount: string;
    handleRowSelection: (_selectedRowKeys: Array<Key>, rows: Array<MyCompaniesTableRowDataType>) => void;
    selectAllCheckbox: JSX.Element;
    selectedFirstCompanyName: string;
    selectedCompaniesIds: Array<string>;
};

// eslint-disable-next-line sonarjs/cognitive-complexity,max-statements
export function useRowSelection(props: PropsType): RowSelectionType {
    const {companiesResult, mainFilterKey, newCompanies, yandexNeedActualization} = props;

    const {
        isInProgress: isAvailableCompaniesInProgress,
        getAvailableForUpdateCompaniesCount,
        result: availableCompanies,
        reset: resetAvailableCompanies,
    } = useAvailableForUpdateCompaniesCount({newCompanies, yandexActualizationHasError: yandexNeedActualization});

    const [selectedRows, setSelectedRows] = useState<Array<string> | typeof SELECTED_ALL>([]);
    const [excludedRows, setExcludedRows] = useState<Array<string>>([]);
    const [selectedFirstCompanyName, setSelectedFirstCompanyName] = useState<string>('');
    const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<Array<string>>([]);

    function getSelectedCompaniesForAllMode() {
        return isAvailableCompaniesInProgress || availableCompanies === null
            ? '...'
            : String(availableCompanies.update);
    }

    function getSelectedCompaniesForSelectiveMode() {
        return String(selectedRows.length);
    }

    function handleRowSelectionForAllMode(rows: Array<MyCompaniesTableRowDataType>) {
        const allSelected = new Set(rows.map((rowData) => rowData.key));
        const selected = excludedRows.filter((row) => !allSelected.has(row));
        const excluded = selectedCompaniesIds.filter((row) => !allSelected.has(row));

        setExcludedRows([...selected, ...excluded]);

        if (companiesResult?.results) {
            setSelectedFirstCompanyName(
                companiesResult.results.find((element) => allSelected.has(String(element.id)))?.name ?? ''
            );
        }
    }

    function handleRowSelectionForSelectiveMode(rows: Array<MyCompaniesTableRowDataType>) {
        const rowKeys = rows.map((rowData) => rowData.key);

        if (Array.isArray(selectedRows) && companiesResult?.results) {
            setSelectedRows([
                ...selectedRows.filter((selectedRow) => {
                    return !companiesResult.results
                        .map((companyResult) => String(companyResult.id))
                        .includes(selectedRow);
                }),
                ...rowKeys,
            ]);
            setSelectedFirstCompanyName(
                companiesResult?.results.find((company) => String(company.id) === rowKeys[0])?.name ?? ''
            );
            return;
        }

        setSelectedRows(rowKeys);
    }

    function handleTurnOnAllMode() {
        setSelectedRows([]);
        setExcludedRows([]);
        setSelectedFirstCompanyName('');

        resetAvailableCompanies();
    }

    function handleTurnOnSelectiveMode() {
        setSelectedRows(SELECTED_ALL);

        if (companiesResult) {
            setSelectedFirstCompanyName(companiesResult.results[0]?.name ?? '');
        }
    }

    const updateSelectedCompaniesIdsForAllMode = useCallback(() => {
        const allCompaniesIds =
            companiesResult?.results
                .filter(
                    (company) =>
                        company.permissions.includes(PermissionNameEnum.update) &&
                        !excludedRows.includes(String(company.id))
                )
                .map((company) => String(company.id)) || [];

        setSelectedCompaniesIds(allCompaniesIds);
    }, [companiesResult?.results, excludedRows]);

    const updateSelectedCompaniesIdsForSelectiveMode = useCallback(() => {
        setSelectedCompaniesIds(selectedRows as Array<string>);
    }, [selectedRows]);

    const selectedCount =
        selectedRows === SELECTED_ALL ? getSelectedCompaniesForAllMode() : getSelectedCompaniesForSelectiveMode();

    function handleRowSelection(_selectedRowKeys: Array<Key>, rows: Array<MyCompaniesTableRowDataType>) {
        if (selectedRows === SELECTED_ALL) {
            handleRowSelectionForAllMode(rows);
        } else {
            handleRowSelectionForSelectiveMode(rows);
        }
    }

    const selectAllCheckbox = (
        <Checkbox
            checked={selectedRows === SELECTED_ALL}
            indeterminate={selectedRows !== SELECTED_ALL && selectedRows.length > 0}
            onChange={() => {
                if (selectedRows === SELECTED_ALL) {
                    handleTurnOnAllMode();
                } else {
                    handleTurnOnSelectiveMode();
                    track(AnalyticsTarget.MyCompanies.SelectAllCompanies);
                }
            }}
        />
    );

    useEffect(() => {
        if (selectedRows === SELECTED_ALL) {
            updateSelectedCompaniesIdsForAllMode();
        } else {
            updateSelectedCompaniesIdsForSelectiveMode();
        }
    }, [selectedRows, updateSelectedCompaniesIdsForAllMode, updateSelectedCompaniesIdsForSelectiveMode]);

    useEffect(() => {
        if (selectedRows === SELECTED_ALL) {
            const filter = serializeBulkEditCompaniesFilters(null, excludedRows.map(Number), mainFilterKey);

            getAvailableForUpdateCompaniesCount(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [excludedRows, getAvailableForUpdateCompaniesCount, selectedRows]);

    useEffect(() => {
        handleTurnOnAllMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainFilterKey]);

    return {
        selectedRows,
        excludedRows,
        selectedCount,
        selectedFirstCompanyName,
        handleRowSelection,
        selectAllCheckbox,
        selectedCompaniesIds,
    };
}
