import {Button, Modal} from 'antd';
import {useState} from 'react';

import {AddButton} from '../../../../../../../../component/add-button/add-button';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../../../../provider/user/user-hook';
import {createAccount} from '../../../../../../../../service/source-settings/accounts/accounts-api';
import {CatalogByGroupType} from '../../../../../../../../service/source-settings/catalogs/catalogs-type';
import {ManageAccountForm} from '../manage-account-form/manage-account-form';
import {ManageAccountFormType} from '../manage-account-form/manage-account-form-type';

import {addAccountFormId} from './add-account-const';

type PropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    isPrimary?: boolean;
    onSuccess: () => void;
};

export function AddAccount(props: PropsType): JSX.Element {
    const {catalog, catalogGroupId, isPrimary, onSuccess} = props;

    const {snackbar} = useSnackbar();
    const [isInProgress, setIsInProgress] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {user} = useUser();

    function onOpenModal() {
        setIsModalOpen(true);
    }

    async function onSubmit(values: ManageAccountFormType): Promise<void> {
        const {login, password, brands, uuid} = values;

        try {
            setIsInProgress(true);
            await createAccount({login, password, brands, uuid, catalogId: catalog.id});
            snackbar.success({
                message: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_SUCCESS_TITLE" />,
                description: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_SUCCESS_TEXT" />,
            });
            onSuccess();
            setIsModalOpen(false);
        } catch {
            snackbar.error({
                message: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_FAIL_TITLE" />,
                description: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_FAIL_TEXT" />,
            });
        } finally {
            setIsInProgress(false);
        }
    }

    return (
        <>
            <AddButton onClick={onOpenModal} type={isPrimary ? 'primary' : 'default'}>
                <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT" />
            </AddButton>

            <Modal
                destroyOnClose
                footer={[
                    <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>,
                    <Button
                        disabled={user?.isTestUser}
                        form={addAccountFormId}
                        htmlType="submit"
                        key="submit"
                        loading={isInProgress}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__ADD" />
                    </Button>,
                ]}
                onCancel={() => setIsModalOpen(false)}
                open={isModalOpen}
                title={<Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT__MODAL_TITLE" />}
            >
                <ManageAccountForm
                    catalog={catalog}
                    catalogGroupId={catalogGroupId}
                    formId={addAccountFormId}
                    isEditMode={false}
                    onSubmit={onSubmit}
                />
            </Modal>
        </>
    );
}
