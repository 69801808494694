import {DivIcon, divIcon, latLng, LatLngBounds, LatLngExpression} from 'leaflet';
import {renderToString} from 'react-dom/server';

import {CompanyLocationType} from '../../../../../../service/dashboard/dashboard-map/use-companies-locations-type';

import {DashboardCompanyGroupMarker} from './map-marker/dashboard-company-group-marker';
import {DashboardCompanyMarker} from './map-marker/dashboard-company-marker';

export function getCompanyIcon(rating: string | null): DivIcon {
    return divIcon({
        className: '',
        iconSize: [16, 16],
        html: renderToString(<DashboardCompanyMarker rating={rating} />),
    });
}

export function getCompanyGroupIcon(companiesCount: number): DivIcon {
    return divIcon({
        className: '',
        html: renderToString(<DashboardCompanyGroupMarker companiesCount={companiesCount} />),
    });
}

// eslint-disable-next-line sonarjs/cognitive-complexity,complexity
export function clusterCompanies(
    companies: Array<CompanyLocationType>,
    mapBounds: LatLngBounds
): Array<CompanyLocationType | {center: LatLngExpression; count: number}> {
    const clusteredMarkers: Array<CompanyLocationType | {center: LatLngExpression; count: number}> = [];
    const remainingCompanies = [...companies];

    const clusterRadius = mapBounds.getSouthEast().distanceTo(mapBounds.getNorthWest()) / 16;

    // eslint-disable-next-line no-loops/no-loops
    while (remainingCompanies.length > 0) {
        const baseCompany = remainingCompanies.shift(); // Берём первую компанию

        if (baseCompany && typeof baseCompany.lat === 'number' && typeof baseCompany.lon === 'number') {
            const cluster: Array<CompanyLocationType> = [baseCompany];

            // Отбираем компании, попадающие в кластер
            // eslint-disable-next-line no-loops/no-loops
            for (let index = remainingCompanies.length - 1; index >= 0; index -= 1) {
                const company = remainingCompanies[index];

                if (!company || typeof company.lat !== 'number' || typeof company.lon !== 'number') {
                    remainingCompanies.splice(index, 1);
                } else if (
                    latLng([baseCompany.lat, baseCompany.lon]).distanceTo(latLng([company.lat, company.lon])) <
                    clusterRadius
                ) {
                    cluster.push(company);
                    remainingCompanies.splice(index, 1);
                }
            }

            // Если в кластере больше одной компании, создаём группированный маркер с координатами первой компании
            if (cluster.length > 1) {
                clusteredMarkers.push({center: [baseCompany.lat, baseCompany.lon], count: cluster.length});
            } else {
                clusteredMarkers.push(baseCompany);
            }
        }
    }

    return clusteredMarkers;
}
