import {Select} from 'antd';
import {keyBy, merge, values} from 'lodash';
import {memo, useEffect, useMemo, useState} from 'react';

import {useBrands} from '../../../../provider/brands/brand-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {
    ManageReviewStatusError,
    ReviewStatusType,
    updateReviewStatus,
    useReviewStatusList,
} from '../../../../service/reviews/reviews-statuses';
import {handleFilterOption} from '../../../../util/antd/select-helper';
import {classNames} from '../../../../util/css';
import {HttpError} from '../../../../util/error';

import {MOKKANO_BRAND_IDS, mokkanoStatuses} from './review-status-const';
import * as styles from './review-status.scss';

const {Option} = Select;

type PropsType = {
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewId: number;
    processStatus: string;
    className?: string;
    onChange?: () => void;
};

export const ReviewStatus = memo(function ReviewStatusInner(props: PropsType): JSX.Element {
    const {refreshReview, reviewId, processStatus, className, onChange} = props;

    const {localeName} = useLocale();
    const {snackbar} = useSnackbar();
    const {
        data: reviewStatusList,
        isFetching: isReviewStatusListInProgress,
        error: processError,
        remove: reset,
    } = useReviewStatusList(localeName);
    const {brands, isLoading} = useBrands();

    useEffect(() => {
        if (processError) {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        }

        return reset;
    }, [snackbar, processError, reset]);

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [innerStatus, setInnerStatus] = useState<string>(processStatus);
    const [isOpen, setIsOpen] = useState(false);

    const status = useMemo(() => {
        return reviewStatusList?.statuses.find((statusItem) => statusItem.statusCode === innerStatus) || null;
    }, [innerStatus, reviewStatusList?.statuses]);

    const results = values(
        merge(keyBy(mokkanoStatuses.statuses, 'statusCode'), keyBy(reviewStatusList?.statuses, 'statusCode'))
    ).filter((item) => item.availableStatus);

    const mappedStatus = new Map<string, Array<string>>(results.map((item) => [item.statusCode, item.availableStatus]));

    useEffect(() => {
        setInnerStatus(processStatus);
    }, [processStatus]);

    async function onStatusSelect(selectedStatus: string) {
        try {
            setIsUpdating(true);
            setInnerStatus(selectedStatus);
            await updateReviewStatus(reviewId, {status: selectedStatus});
            await refreshReview(reviewId);

            if (onChange) {
                onChange();
            }
        } catch (error: unknown) {
            const description =
                error instanceof ManageReviewStatusError ? (
                    <span>{error.jsonData.status}</span>
                ) : (
                    <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />
                );

            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description,
            });

            if (!(error instanceof HttpError)) {
                throw error;
            }
        } finally {
            setIsUpdating(false);
        }
    }

    const isMokkanoBrand = brands.some((brand) => MOKKANO_BRAND_IDS.includes(brand.id));

    return (
        <div className={classNames(className, styles.ReviewStatus, isOpen ? styles.ReviewStatus__focused : '')}>
            <Select<Array<string>>
                className={styles.ReviewStatus_select}
                disabled={isReviewStatusListInProgress}
                filterOption={handleFilterOption}
                loading={isReviewStatusListInProgress || isUpdating || isLoading}
                onDropdownVisibleChange={setIsOpen}
                onSelect={onStatusSelect}
                optionFilterProp="children"
                placeholder={<Locale stringKey="REVIEWS__FILTER__SELECT_A_STATUS" />}
                showSearch
                value={[status?.title || '']}
            >
                {isMokkanoBrand
                    ? mappedStatus.get(innerStatus)?.map((item) => (
                          <Option key={item} value={item}>
                              {reviewStatusList?.statuses.find((findItem) => findItem.statusCode === item)?.title}
                          </Option>
                      ))
                    : reviewStatusList?.statuses.map(
                          (reviewStatus: ReviewStatusType): JSX.Element => (
                              <Option key={reviewStatus.statusCode} value={reviewStatus.statusCode}>
                                  {reviewStatus.title}
                              </Option>
                          )
                      )}
            </Select>
        </div>
    );
});
