import {BulkUpdateCompaniesFieldsErrorsType} from '../../../../service/company/company-type';
import {BulkUpdateCompaniesErrorsType} from '../bulk-edit-companies-type';

export function extractErrorMessage(
    error: BulkUpdateCompaniesFieldsErrorsType['field_set'][keyof BulkUpdateCompaniesFieldsErrorsType['field_set']]
): Array<string> | Array<Array<string>> | null {
    if (!error) {
        return null;
    }

    if ('value' in error) {
        return error.value;
    }

    return error;
}

export function hasErrors(errors: Partial<BulkUpdateCompaniesErrorsType>): boolean {
    return Object.values(errors).some((error) => {
        if (error === null) {
            return false;
        }

        return Array.isArray(error) && error.some(Boolean);
    });
}

export function hasNonEmptyValue(errors: Partial<BulkUpdateCompaniesErrorsType>): boolean {
    return Object.values(errors).some((error) => {
        if (error === null) {
            return true;
        }

        if (Array.isArray(error)) {
            return error.some((error_) => !error_);
        }

        return true;
    });
}
