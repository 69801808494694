import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Button, Modal, Space} from 'antd';

import {Text} from '../../../../component/text/text';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/locale';

import {CREATE_COMPANY_ONBOARDING_LINKS} from './create-companies-onboarding-popup-const';
import * as styles from './create-companies-onboarding-popup.scss';

type PropsType = {
    onClose: () => void;
};

export function CreateCompaniesOnboardingPopup(props: PropsType): JSX.Element {
    const {onClose} = props;

    const {companyName} = useDomainConfig();

    return (
        <Modal
            footer={
                <Button onClick={onClose}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>
            }
            onCancel={onClose}
            open
            title={
                <Text
                    className={styles.CreateCompaniesOnboardingPopup_title}
                    large
                    stringKey="ONBOARDING__CREATE_COMPANY_POPUP__TITLE"
                />
            }
        >
            <Space className={styles.CreateCompaniesOnboardingPopup_space} direction="vertical" size={12}>
                {CREATE_COMPANY_ONBOARDING_LINKS.map(({label, description, tag, link, icon}) => (
                    <NavigationLink className={styles.OnboardingRouteLink} key={label} to={link ?? '/'}>
                        <Avatar className={styles.OnboardingRouteLink_icon} icon={<FontAwesomeIcon icon={icon} />} />

                        <Space className={styles.OnboardingRouteLink_content} direction="vertical" size={2}>
                            <strong className={styles.OnboardingRouteLink_label}>
                                <Locale stringKey={label} />
                                {tag}
                            </strong>

                            <Text secondary stringKey={description} valueMap={{companyName}} />
                        </Space>

                        <FontAwesomeIcon className={styles.OnboardingRouteLink_arrowIcon} icon={faArrowRight} />
                    </NavigationLink>
                ))}
            </Space>
        </Modal>
    );
}
