import React from 'react';

import {Spinner} from '../../layout/spinner/spinner';
import {CompareModeDelta} from '../../page/main/reviews-and-answers-statistics/compare-mode-delta/compare-mode-delta';
import {Locale} from '../../provider/locale/locale';
import {LangKeyType} from '../../provider/locale/translation/type';
import {Text} from '../text/text';

import {
    StatisticsParameterValueRenderer,
    StatisticsParameterValueRendererPropsType,
} from './statistics-parameter-value-renderer';
import * as styles from './statistics-parameter.scss';

type PropsType = {
    value: {
        currentPeriod: number;
        comparePeriod?: number | null;
        delta?: number | null;
    };

    titleLangKey: LangKeyType;
    isLoading?: boolean;

    ValueRenderer?: React.FunctionComponent<StatisticsParameterValueRendererPropsType>;
    deltaUnit?: string;
};

export function StatisticsParameter(props: PropsType): JSX.Element {
    const {value, titleLangKey, isLoading, deltaUnit = '%', ValueRenderer = StatisticsParameterValueRenderer} = props;
    const {comparePeriod, currentPeriod, delta} = value;

    const isCompareMode = Boolean(comparePeriod ?? false);

    if (isLoading) {
        return (
            <div className={styles.StatisticsParameter}>
                <Locale stringKey={titleLangKey} />
                <Spinner />
            </div>
        );
    }

    return (
        <div className={styles.StatisticsParameter}>
            <Text block secondary stringKey={titleLangKey} />

            {isCompareMode ? (
                <CompareModeDelta
                    compareValue={<ValueRenderer compareMode value={comparePeriod ?? 0} />}
                    delta={delta ?? currentPeriod - (comparePeriod || 0)}
                    unit={deltaUnit}
                    value={<ValueRenderer compareMode value={currentPeriod} />}
                />
            ) : (
                <ValueRenderer compareMode={false} value={currentPeriod} />
            )}
        </div>
    );
}
