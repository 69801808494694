import {z as r, ZodType} from 'zod';

import {ReviewsStateType} from '../../page/main/reviews/reviews-state/reviews-state-type';
import {NegativeReviewSourceTypeEnum} from '../negative-review/negative-review-type';
import {ReviewGeneratorRatingScaleTypeEnum} from '../reviews-generator/questionnaire/review-generator-questionnaire-type';

import {reviewNotesUserSchema} from './reviews-notes';
import {tagGroupSchema} from './reviews-tags';

// do not change values, they matched with server's api
export enum ReviewRatingTypeEnum {
    allRating = '',
    positive = 'positive',
    negative = 'negative',
    notRated = 'neutral',
}

export enum ReviewSortingTypeEnum {
    acs = '-creation_date',
    desc = 'creation_date',
}

const reviewDataBrandFromListSchema = r.object({
    id: r.number(),
    name: r.string(),
    isTest: r.boolean(),
});

export type ReviewDataBrandFromListType = r.infer<typeof reviewDataBrandFromListSchema>;

const companySchema = r.object({
    id: r.number(),
    name: r.string(),
    code: r.string(),
    categories: r
        .array(
            r.object({
                id: r.number(),
                isMain: r.boolean(),
                name: r.string(),
            })
        )
        .optional(),
    address: r.union([
        r.object({
            city: r.string().nullable(),
            country: r.string(),
            countryCode: r.string(),
            housenumber: r.string(),
            lat: r.number().optional(),
            lon: r.number().optional(),
            postcode: r.string(),
            region: r.string().nullable(),
            street: r.string(),
        }),
        r.string(),
    ]),
});

export type ReviewDataCompanyFromListType = r.infer<typeof companySchema>;

const reviewDataTagSchema = r.object({
    createdAt: r.string().optional(), // "2021-02-09T11:48:54.201196Z";
    folder: tagGroupSchema.nullable().optional(),
    id: r.number(),
    isComplex: r.boolean().optional(),
    keys: r.array(r.string()).optional(),
    keywords: r.array(r.string()).optional(),
    subTags: r.array(r.unknown()).optional(),
    title: r.string(),
    isAspect: r.boolean().optional(),
});

export type ReviewDataTagType = r.infer<typeof reviewDataTagSchema>;

const reviewDataChildUserSchema = r.object({
    id: r.number(),
    email: r.string(),
    firstName: r.string().nullable(),
    lastName: r.string().nullable(),
    fullName: r.string().nullable(),
});

export enum ReviewReplyTypeAuthorEnum {
    reply = 'reply',
    command = 'command',
}

const childCommonSchema = r.object({
    author: r.string().nullable(),
    createdAt: r.string().optional(), // "2021-05-13T13:47:23.419459Z",
    comment: r.string().nullable(),
    createdInRd: r.string(),
    ableToDelete: r.boolean(),
    ableToEdit: r.boolean(),
    lastChangesDate: r.string().nullable(),
    ableToReply: r.boolean(),
    isCompanyComment: r.boolean(),
    autoreplyRuleId: r.union([r.string(), r.number()]).nullable().optional(),
    user: reviewDataChildUserSchema.nullable(),
});
const commandChildProperties = childCommonSchema.extend({
    type: r.literal(ReviewReplyTypeAuthorEnum.command),
    commandId: r.number(),
    replyId: r.null(),
    createdInCatalog: r.null(),
});
const replyChildProperties = childCommonSchema.extend({
    type: r.literal(ReviewReplyTypeAuthorEnum.reply),
    commandId: r.null(),
    replyId: r.number(),
    createdInCatalog: r.string().nullable(), // "2021-05-13T13:47:24.378000Z",
});
const childPartialSchema = r.union([commandChildProperties, replyChildProperties]);

type ChildPartialType = r.infer<typeof childPartialSchema>;

// ReviewDataChildType is recursive type. It isn't possible to generate it from r.infer
export type ReviewDataChildType = ChildPartialType & {children: Array<ReviewDataChildType>};

const reviewDataChildSchema: ZodType<ReviewDataChildType> = r.lazy(() =>
    r.union([
        commandChildProperties.extend({children: r.array(reviewDataChildSchema)}),
        replyChildProperties.extend({children: r.array(reviewDataChildSchema)}),
    ])
);

const reviewDataResponsibleUserSchema = r.object({
    id: r.number(),
    firstName: r.string(),
    lastName: r.string().nullable(),
    fullName: r.string(),
    email: r.string(),
});

export type ReviewResponsibleUserType = r.infer<typeof reviewDataResponsibleUserSchema>;

export const reviewDataSchema = r.object({
    abuseLastDate: r.string().nullable(),
    ableToAbuse: r.boolean(),
    ableToChangeResponsibleUser: r.boolean(),
    ableToReply: r.boolean(),
    catalogId: r.number(),
    author: r.string().nullable(),
    authorPhone: r.string().optional(),
    authorEmail: r.string().optional(),
    brand: reviewDataBrandFromListSchema,
    children: r.array(reviewDataChildSchema),
    comment: r.string().nullable(),
    company: companySchema.nullable(),
    createdInCatalog: r.string(),
    createdInRd: r.string(),
    parsedAt: r.string(),
    processStatus: r.string(),
    responsibleUser: reviewDataResponsibleUserSchema.nullable(),
    lastChangesDate: r.string().nullable(),
    deletedAt: r.string().nullable(),
    id: r.number(),
    isChanged: r.boolean(),
    originUrl: r.string().url().nullable(),
    rating: r.number().nullable(),
    ratingScaleType: r.nativeEnum(ReviewGeneratorRatingScaleTypeEnum).optional(),
    tags: r.array(reviewDataTagSchema).nullable(),
    sourceType: r.nativeEnum(NegativeReviewSourceTypeEnum).optional(),
    moderated: r.boolean(),
    replyTimeInCatalog: r.number(),
    replyTimeInRd: r.number(),
    hasAccountError: r.boolean().optional(),
    userFields: r.object({
        email: r.string().optional(),
        phone: r.string().optional(),
    }),
});

export type ReviewDataType = r.infer<typeof reviewDataSchema>;

export const fetchReviewsResultSchema = r.object({
    results: r.array(reviewDataSchema),
    next: r.string().nullable(),
    previous: r.string().nullable(),
});

export type FetchReviewsResultType = r.infer<typeof fetchReviewsResultSchema>;

export type ReviewLogUserDataType = r.infer<typeof reviewNotesUserSchema>;

export type ReviewsAbuseType = {
    reason: string;
};

export type ReviewsReplyTagsType = {
    tag_ids: Array<number>;
};

export type ExecuteRefreshReviewsType = {
    refreshReviews: () => Promise<unknown>;
};

export type ReviewsHookPropsType = {
    reviewsState: ReviewsStateType;
};
