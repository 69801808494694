import {Radio} from 'antd';

import {PageCardDivider} from '../../../../component/page-card-divider/page-card-divider';
import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {AverageResponseTimeType} from '../../../../service/reviews-and-answers-statistics/average-response-time/average-response-time-type';
import {AverageResponseTimeByCatalogType} from '../../../../service/reviews-and-answers-statistics/average-response-time-by-catalog/average-response-time-by-catalog-type';
import {AverageResponseTimeByEmployeeType} from '../../../../service/reviews-and-answers-statistics/average-response-time-by-empoyee/average-response-time-by-employee-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {ReviewsCountByCatalogType} from '../../../../service/reviews-and-answers-statistics/reviews-count-by-catalog/reviews-count-by-catalog-type';

import {AverageResponseTimeStats} from './average-response-time-stats/average-response-time-stats';
import {averageResponseTimeTimeUnitTranslationMap} from './average-time-value/average-response-time-value-helper';
import {CatalogsStatsTable} from './catalogs-stats-table/catalogs-stats-table';
import {EmployeeStatsTable} from './employee-stats-table/employee-stats-table';
import * as styles from './average-response-time.scss';

type PropsType = {
    pdfPreview?: boolean;
    isCompareMode: boolean;
    reviewsCountByCatalogData: {
        reviewsCountByCatalog: ReviewsCountByCatalogType | null;
        isLoading: boolean;
    };
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;
    setTimeUnit?: (newValue: ReviewsAndAnswersStatisticsUnitMeasurementEnum) => void;
    averageResponseTimeData: {
        averageResponseTime: AverageResponseTimeType | null;
        isLoading: boolean;
    };
    employeeStatsData: {
        responseTimeByEmployee: Array<AverageResponseTimeByEmployeeType>;
        isLoading: boolean;
    };

    catalogStatsData: {
        responseTimeByCatalog: Array<AverageResponseTimeByCatalogType>;
        isLoading: boolean;
    };
};

export function AverageResponseTime(props: PropsType): JSX.Element {
    const {
        isCompareMode,
        averageResponseTimeData,
        employeeStatsData,
        pdfPreview,
        catalogStatsData,
        timeUnit,
        setTimeUnit,
        reviewsCountByCatalogData,
    } = props;

    return (
        <PageCard
            bodyClassName={styles.AverageResponseTime}
            headerClassName={styles.AverageResponseTime_header}
            title={
                <>
                    <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__TITLE" />
                    {!pdfPreview && (
                        <Radio.Group optionType="button" size="small" value={timeUnit}>
                            {Object.values(ReviewsAndAnswersStatisticsUnitMeasurementEnum).map(
                                (measurementUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum) => (
                                    <Radio
                                        key={measurementUnit}
                                        onClick={() => setTimeUnit?.(measurementUnit)}
                                        value={measurementUnit}
                                    >
                                        <Locale
                                            stringKey={averageResponseTimeTimeUnitTranslationMap[measurementUnit]}
                                        />
                                    </Radio>
                                )
                            )}
                        </Radio.Group>
                    )}
                </>
            }
        >
            <AverageResponseTimeStats
                averageResponseTime={averageResponseTimeData.averageResponseTime}
                isCompareMode={isCompareMode}
                isLoading={averageResponseTimeData.isLoading}
                timeUnit={timeUnit}
            />

            <PageCardDivider />

            <Text block bolder large stringKey="REVIEW_AND_ANSWER_STATISTICS__EMPLOYEE_AVERAGE_RESPONSE_TIME__TITLE" />

            <EmployeeStatsTable
                averageResponseTimeByEmployee={employeeStatsData.responseTimeByEmployee}
                isCompareMode={isCompareMode}
                isLoading={employeeStatsData.isLoading}
                pdfPreview={pdfPreview}
                timeUnit={timeUnit}
            />

            <PageCardDivider />

            <Text block bolder large stringKey="REVIEW_AND_ANSWER_STATISTICS__CATALOG_AVERAGE_RESPONSE_TIME__TITLE" />

            <CatalogsStatsTable
                averageResponseTimeByCatalog={catalogStatsData.responseTimeByCatalog}
                isCompareMode={isCompareMode}
                isLoading={catalogStatsData.isLoading || reviewsCountByCatalogData.isLoading}
                pdfPreview={pdfPreview}
                reviewsCountByCatalog={reviewsCountByCatalogData.reviewsCountByCatalog}
                timeUnit={timeUnit}
            />
        </PageCard>
    );
}
