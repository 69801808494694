import {Button} from 'antd';
import {useState} from 'react';

import {TechnicalSupportRequestListItemType} from '../../../../../../../service/technical-support/technical-support-type';
import {TechnicalSupportViewModal} from '../../technical-support-view-modal/technical-support-view-modal';
import * as styles from '../technical-support-table.scss';

type PropsType = {
    data: TechnicalSupportRequestListItemType;
    refetchTableData: VoidFunction;
};

export function TechnicalSupportTableNameCell(props: PropsType): JSX.Element {
    const {data, refetchTableData} = props;

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Button className={styles.TechnicalSupportTable__name} onClick={() => setIsModalVisible(true)} type="link">
                {data.title}
            </Button>
            <TechnicalSupportViewModal
                helpRequestId={data.id}
                isOpen={isModalVisible}
                onClose={() => {
                    setIsModalVisible(false);
                    refetchTableData();
                }}
            />
        </>
    );
}
