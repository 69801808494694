import {useEffect, useState} from 'react';

import {classNames} from '../../util/css';
import {getQrCodeDataUrl} from '../../util/qr-code';
import {Spinner} from '../spinner/spinner';

import * as styles from './qr-code.scss';

type PropsType = {
    text: string;
    className?: string;
};

export function QrCode(props: PropsType): JSX.Element | null {
    const {className, text} = props;

    const fullClassName = classNames(styles.qr_code, className);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!text) {
            setImageUrl('');
            return;
        }

        setIsLoading(true);

        getQrCodeDataUrl(text)
            .then((url: string): void => setImageUrl(url))
            .finally(() => setIsLoading(false))
            .catch((): void => setImageUrl(''));
    }, [text]);

    if (isLoading) {
        return <Spinner />;
    }

    if (!imageUrl.trim()) {
        return null;
    }

    return <img alt="" className={fullClassName} src={imageUrl} />;
}
