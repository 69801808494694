import {StepProps, Steps} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {useLocale} from '../../../../provider/locale/locale-hook';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../service/feature-companies/feature-companies-type';
import {usePostAiSuggests, usePostFormSubmitErrors} from '../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormModeEnum, PostFormType, PostImageType} from '../../../../service/posts/posts-types';
import {Form} from '../../../../typings/antd';
import {useRefreshId} from '../../../../util/hook';

import {PostFormStepType, PostStepEnum} from './post-form-type';
import {
    POSTS_FORM_ADAPTIVE_CONTENT_STEP,
    POSTS_FORM_COMMON_CONTENT_STEP,
    POSTS_FORM_POST_TARGET_STEP,
    POSTS_FORM_SEND_PARAMETERS_STEP,
} from './posts-form-helper';
import {PostCommonContentStep} from './steps/content/common-content/post-common-content-step';
import {PostContentStep} from './steps/content/post-content-step';
import {SendParametersStep} from './steps/send-parameters/send-parameters-step';
import {PostTargetStep} from './steps/target/post-target-step';
import * as styles from './post-form.scss';

type PropsType = {
    initialValues: PostFormType;
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
    formMode: PostFormModeEnum;
};

export function PostForm(props: PropsType): JSX.Element {
    const {initialValues, companiesSelector, formMode: initialFormMode} = props;
    const [formInstance] = Form.useForm<PostFormType>();
    const postSuggests = usePostAiSuggests(companiesSelector.selectorId);
    const [formMode, setFormMode] = useState<PostFormModeEnum>(initialFormMode);
    const {getLocalizedString} = useLocale();

    const [commonText, setCommonText] = useState<string>('');
    const [commonImages, setCommonImages] = useState<Array<PostImageType>>([]);

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const {refreshId: sourcesStateId, refresh: refreshSourcesStateId} = useRefreshId();

    const availableFormSteps: Array<PostFormStepType> = useMemo(() => {
        const posts = formInstance.getFieldValue(PostFormFieldsEnum.Posts);

        return [
            POSTS_FORM_POST_TARGET_STEP,
            ...(formMode === PostFormModeEnum.New && posts?.length > 1 ? [POSTS_FORM_COMMON_CONTENT_STEP] : []),
            POSTS_FORM_ADAPTIVE_CONTENT_STEP,
            POSTS_FORM_SEND_PARAMETERS_STEP,
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInstance, formMode, sourcesStateId]);

    const updateFormModeOnCreateDraft = useCallback(() => {
        setFormMode(PostFormModeEnum.EditDraft);
        setCurrentStepIndex((previousActiveStepIndex) => previousActiveStepIndex - 1);
    }, []);

    const currentStep: PostStepEnum = availableFormSteps[currentStepIndex]?.stepName || PostStepEnum.Target;

    const {handleSubmitError, stepsWithErrors} = usePostFormSubmitErrors(formInstance, currentStep);

    const steps: Array<StepProps> = useMemo(
        () =>
            availableFormSteps.map((step, index) => ({
                name: step.stepName,
                title: getLocalizedString(step.titleKey),
                ...(formMode === PostFormModeEnum.View
                    ? {
                          disabled: false,
                      }
                    : {
                          disabled: index > currentStepIndex,
                      }),
                ...(stepsWithErrors.includes(step.stepName)
                    ? {
                          status: 'error',
                      }
                    : {}),
            })),
        [availableFormSteps, currentStepIndex, formMode, getLocalizedString, stepsWithErrors]
    );

    const onStepFinished = useCallback(async () => {
        await formInstance.validateFields();
        setCurrentStepIndex((previousIndex) => previousIndex + 1);
    }, [formInstance]);

    const stepContent = useMemo(() => {
        switch (currentStep) {
            case PostStepEnum.Target:
                return (
                    <PostTargetStep
                        companiesSelector={companiesSelector}
                        formInstance={formInstance}
                        formMode={formMode}
                        onSourcesChange={refreshSourcesStateId}
                        onStepFinished={onStepFinished}
                    />
                );
            case PostStepEnum.CommonContent:
                return (
                    <PostCommonContentStep
                        commonImagesState={[commonImages, setCommonImages]}
                        commonTextState={[commonText, setCommonText]}
                        formInstance={formInstance}
                        onStepFinished={onStepFinished}
                        suggestSettings={postSuggests}
                    />
                );
            case PostStepEnum.AdaptiveContent:
                return (
                    <PostContentStep
                        companiesSelector={companiesSelector}
                        formInstance={formInstance}
                        formMode={formMode}
                        onStepFinished={onStepFinished}
                        suggestSettings={postSuggests}
                    />
                );
            case PostStepEnum.SendParameters:
                return (
                    <SendParametersStep
                        companiesSelector={companiesSelector}
                        formInstance={formInstance}
                        formMode={formMode}
                        handleSubmitError={handleSubmitError}
                        onCreateDraft={updateFormModeOnCreateDraft}
                    />
                );
            default: {
                return null;
            }
        }
    }, [
        commonImages,
        commonText,
        companiesSelector,
        currentStep,
        formInstance,
        formMode,
        handleSubmitError,
        onStepFinished,
        postSuggests,
        refreshSourcesStateId,
        updateFormModeOnCreateDraft,
    ]);

    return (
        <Form<PostFormType>
            className={styles.PostForm}
            form={formInstance}
            initialValues={initialValues}
            layout="vertical"
        >
            <div className={styles.PostForm_contentWrapper}>
                <Steps current={currentStepIndex} items={steps} onChange={setCurrentStepIndex} size="small" />

                {stepContent}
            </div>
        </Form>
    );
}
