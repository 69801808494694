import {FormattedNumber} from '../../../../../provider/locale/formatted-number';
import {Locale} from '../../../../../provider/locale/locale';

import * as styles from './overview-stat.scss';

type PropsType = {
    value: number;
};

export function OverviewsStat(props: PropsType): JSX.Element {
    const {value} = props;

    return (
        <div className={styles.OverviewStats}>
            <p className={styles.OverviewStats_value}>
                <FormattedNumber value={value} />%
            </p>
            <div className={styles.OverviewStats_footer}>
                <p className={styles.OverviewStats_name}>
                    <Locale stringKey="DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__OVERVIEW_TEXT" />
                </p>
            </div>
        </div>
    );
}
