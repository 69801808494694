import {LangKeyType} from '../../../../provider/locale/translation/type';
import {MainFilterFieldNameEnum} from '../../../../service/main-filter/main-filter-type';

export const headerFilterDrawerWidth = 512;

export const mainFilterFieldPlaceholderKeyMap: Record<MainFilterFieldNameEnum, LangKeyType> = {
    [MainFilterFieldNameEnum.BRANDS]: 'MAIN_FILTER__BRAND__PLACEHOLDER',
    [MainFilterFieldNameEnum.CITIES]: 'MAIN_FILTER__CITY__PLACEHOLDER',
    [MainFilterFieldNameEnum.CODES]: 'MAIN_FILTER__COMPANY_CODE__PLACEHOLDER',
    [MainFilterFieldNameEnum.COUNTRIES]: 'MAIN_FILTER__COUNTRY__PLACEHOLDER',
    [MainFilterFieldNameEnum.GROUPS]: 'MAIN_FILTER__GROUP__PLACEHOLDER',
    [MainFilterFieldNameEnum.NAMES]: 'MAIN_FILTER__COMPANY_NAME__PLACEHOLDER',
    [MainFilterFieldNameEnum.REGIONS]: 'MAIN_FILTER__REGION__PLACEHOLDER',
    [MainFilterFieldNameEnum.STREETS]: 'MAIN_FILTER__STREET__PLACEHOLDER',
    [MainFilterFieldNameEnum.CLOSED]: 'MAIN_FILTER__OPEN_CLOSE__PLACEHOLDER',
    [MainFilterFieldNameEnum.ACTIVE]: 'MAIN_FILTER__OPEN_CLOSE__PLACEHOLDER',
};

export const mainFilterFieldLabelKeyMap: Record<MainFilterFieldNameEnum, LangKeyType> = {
    [MainFilterFieldNameEnum.BRANDS]: 'MAIN_FILTER__BRAND__LABEL',
    [MainFilterFieldNameEnum.CITIES]: 'MAIN_FILTER__CITY__LABEL',
    [MainFilterFieldNameEnum.CODES]: 'MAIN_FILTER__COMPANY_CODE__LABEL',
    [MainFilterFieldNameEnum.COUNTRIES]: 'MAIN_FILTER__COUNTRY__LABEL',
    [MainFilterFieldNameEnum.GROUPS]: 'MAIN_FILTER__GROUP__LABEL',
    [MainFilterFieldNameEnum.NAMES]: 'MAIN_FILTER__COMPANY_NAME__LABEL',
    [MainFilterFieldNameEnum.REGIONS]: 'MAIN_FILTER__REGION__LABEL',
    [MainFilterFieldNameEnum.STREETS]: 'MAIN_FILTER__STREET__LABEL',
    [MainFilterFieldNameEnum.CLOSED]: 'MAIN_FILTER__OPEN_CLOSE__LABEL',
    [MainFilterFieldNameEnum.ACTIVE]: 'MAIN_FILTER__ACTIVE__LABEL',
};

export const fieldToRenderNames: Array<MainFilterFieldNameEnum> = [
    MainFilterFieldNameEnum.GROUPS,
    MainFilterFieldNameEnum.BRANDS,
    MainFilterFieldNameEnum.COUNTRIES,
    MainFilterFieldNameEnum.REGIONS,
    MainFilterFieldNameEnum.CITIES,
    MainFilterFieldNameEnum.STREETS,
    MainFilterFieldNameEnum.NAMES,
    MainFilterFieldNameEnum.CODES,
    MainFilterFieldNameEnum.CLOSED,
    MainFilterFieldNameEnum.ACTIVE,
];
