import {Select} from 'antd';
import {LabeledValue} from 'antd/lib/select';
import {useState} from 'react';

import {AlertFallback} from '../../../../../../../component/alert-fallback/alert-fallback';
import {SelectInfiniteScroll} from '../../../../../../../component/select-infinite-scroll/select-infinite-scroll';
import {Locale} from '../../../../../../../provider/locale/locale';
import {formatAddress} from '../../../../../../../service/address/address-helper';
import {useLinkCompaniesInfinite} from '../../../../../../../service/source-settings/link-companies/link-companies-hook';
import {LinkCompanyType} from '../../../../../../../service/source-settings/link-companies/link-companies-type';

import {companyLimit} from './link-company-select-const';
import * as styles from './link-company-select.scss';

type PropsType = {
    brandId: number | null;
    isDisabled: boolean;
    onChange?: (value: LinkCompanyType | null) => void;
    value?: LinkCompanyType;
};

export function LinkCompanySelect(props: PropsType): JSX.Element {
    const {brandId, isDisabled, onChange, value} = props;

    const [query, setQuery] = useState<string>('');
    const {result, isInProgress, processError, loadMore} = useLinkCompaniesInfinite({
        q: query,
        count: companyLimit,
        brandId,
    });

    const selectedValue: LabeledValue | undefined = value && {
        key: String(value.id),
        value: value.id,
        label: (
            <>
                {value.name}{' '}
                <span className={styles.company_select__selected_address}>{formatAddress(value.address)}</span>
            </>
        ),
    };

    function onChangeInner(valueInner: LabeledValue | undefined): void {
        // I didn't find a way to set|get custom company value to Select`s LabeledValue
        // Thus I forced to .find() corresponding company among companies search result
        // The important part: companies search result contains a selected company inside the onChangeInner method
        // However we can`t guarantee the search result contains a selected company outside the onChangeInner method
        // Because of Infinite scroll. We can select a company, and we should display its name and address.
        // Meanwhile, search result can show a bunch of the other companies which didn't include the selected one
        const newSelectedCompany = result?.companies.find(({id}) => id === Number(valueInner?.value)) || null;

        onChange?.(newSelectedCompany);
    }

    return (
        <SelectInfiniteScroll<LabeledValue>
            allowClear
            className={styles.link_company_select}
            disabled={!brandId || isDisabled}
            filterOption={false}
            labelInValue
            loading={isInProgress}
            // eslint-disable-next-line no-undefined
            notFoundContent={processError ? <AlertFallback /> : undefined}
            onChange={onChangeInner}
            onLoadMore={loadMore}
            onSearch={setQuery}
            placeholder={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__PLACEHOLDER" />}
            searchValue={query}
            showSearch
            value={selectedValue}
        >
            {!processError &&
                result?.companies.map(
                    (company: LinkCompanyType): JSX.Element => (
                        <Select.Option key={company.id} value={company.id}>
                            <div>{company.name}</div>

                            <div className={styles.company_select__option_address}>
                                {formatAddress(company.address)}
                            </div>
                        </Select.Option>
                    )
                )}
        </SelectInfiniteScroll>
    );
}
