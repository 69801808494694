import {Button, Space} from 'antd';
import {ReactElement, useEffect, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useUser} from '../../../provider/user/user-hook';
import {useCreateReportInfo} from '../../../service/company/company-reports';
import {useSavedTableSort} from '../../../util/save-sort-table-state/save-sort-table-hook';
import {ProvidersIdsEnum} from '../../../util/type';

import {CompaniesReport} from './companies-report/companies-report';
import {CompaniesReportStepEnum, LOCALSTORAGE_REPORT_UUID_KEY} from './companies-report/companies-report-const';
import {CompaniesTable} from './companies-table/companies-table';
import {formatSortState} from './companies-table/companies-table-helper';
import {FeedBadge} from './feed/common/badge/feed-badge';
import {FeedRoutesPopup} from './feed/common/routes-popup/feed-routes-popup';
import {YandexAccountTransferAlert} from './yandex-account-transfer-alert/yandex-account-transfer-alert';
import * as styles from './my-companies.scss';

// eslint-disable-next-line complexity
export function MyCompanies(): ReactElement {
    const [isFeedModalOpen, setIsFeedModalOpen] = useState(false);
    const [showReport, setShowReport] = useState(false);

    const {user} = useUser();
    const {canCreateCompany, isReportCompaniesShow} = useDomainConfig();
    const {savedSortState, routerLocation} = useSavedTableSort();

    const isAvailableCatalogs = [ProvidersIdsEnum.yandex, ProvidersIdsEnum.google, ProvidersIdsEnum.doubleGis].every(
        (number) => user && number in user.regionAvailableCatalogs && user.regionAvailableCatalogs[number]
    );

    const isReportsEnabled = !user?.isDemoUser && !user?.isTestUser && isReportCompaniesShow && isAvailableCatalogs;

    const initialOrdering = formatSortState(savedSortState);

    const [ordering, setOrdering] = useState<string | null>(initialOrdering);
    const [isNewCompanies, setIsNewCompanies] = useState<boolean>(false);

    const {data: reportData} = useCreateReportInfo(isReportsEnabled);

    const {myCompanies: myCompaniesRoute, createCompanySelectBrand: createCompanySelectBrandRoute} = appRoute;

    const isDisableReportButton =
        reportData && reportData.length > 0
            ? reportData[0]?.status.status !== CompaniesReportStepEnum.Done &&
              reportData[0]?.status.status !== CompaniesReportStepEnum.Failed
            : false;

    const {getLocalizedString} = useLocale();

    useEffect(() => {
        // todo remove this after realize right function on the backend
        if (reportData && localStorage.getItem(LOCALSTORAGE_REPORT_UUID_KEY) !== reportData[0]?.uuid) {
            setShowReport(true);
        }
    }, [reportData]);

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard className={styles.MyCompanies_header}>
                <Meta title={getLocalizedString('CATEGORY_NAME__MY_COMPANIES')} />

                <BreadCrumbs list={[{path: myCompaniesRoute.path, titleLangKey: 'CATEGORY_NAME__MY_COMPANIES'}]} />

                <PageHeader className={styles.MyCompanies_headerBody}>
                    <PageHeaderTitle
                        id="myCompaniesSectionName"
                        productName={UsetifulNameProductEnum.MY_COMPANIES}
                        title="CATEGORY_NAME__MY_COMPANIES"
                    />

                    {canCreateCompany ? (
                        <Space wrap>
                            <FeedBadge>
                                <Button onClick={() => setIsFeedModalOpen(true)}>
                                    <Locale stringKey="FEED__IMPORT_AND_EXPORT" />
                                </Button>
                            </FeedBadge>

                            <Button type="primary">
                                <NavigationLink to={createCompanySelectBrandRoute.path}>
                                    <Locale stringKey="TEXT__ADD_COMPANY" />
                                </NavigationLink>
                            </Button>
                        </Space>
                    ) : null}
                </PageHeader>
            </PageCard>
            {isReportsEnabled && reportData && showReport && isReportCompaniesShow && reportData.length > 0 && (
                <CompaniesReport report={reportData} setShowReport={setShowReport} />
            )}

            <YandexAccountTransferAlert />
            <PageCard>
                <CompaniesTable
                    isDisableReportButton={isDisableReportButton}
                    isNewCompanies={isNewCompanies}
                    isReportEnabled={isReportsEnabled}
                    ordering={ordering}
                    routerLocation={routerLocation}
                    setIsNewCompanies={setIsNewCompanies}
                    setOrdering={setOrdering}
                    setShowReport={setShowReport}
                />
            </PageCard>

            {isFeedModalOpen ? <FeedRoutesPopup onClose={() => setIsFeedModalOpen(false)} /> : null}
        </Page>
    );
}
