import dayjs from 'dayjs';

import {
    DatePeriodEnum,
    DateRangeType,
    endOfUtcDay,
    getCloserFullMonth,
    getCloserFullQuarter,
    getCloserFullWeekRange,
    ISO_DATE_FORMAT,
    startOfUtcDay,
} from '../../../util/date';
import {getEnumValue} from '../../../util/enum';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {ProvidersIdsEnum} from '../../../util/type';
import {objectToUrlParameters} from '../../../util/url';
import {UserType} from '../../user/user-type';

import {
    RatingsDynamicsCatalogFilterValuesEnum,
    RatingsDynamicsCatalogSortingValuesEnum,
    RatingsDynamicsFilterQueryParametersEnum,
    RatingsDynamicsFilterType,
    RatingsDynamicsYandexBaseStatusValuesEnum,
    RatingsDynamicsYandexTargetStatusValuesEnum,
} from './reviews-analytics-ratings-dynamic-type';

export function getDatePeriodRangeValues<T extends DatePeriodEnum>(
    datePreset: T,
    fromDate?: Date
): T extends DatePeriodEnum.Custom ? null : DateRangeType | null {
    // eslint-disable-next-line default-case
    switch (datePreset) {
        case DatePeriodEnum.Week: {
            return getCloserFullWeekRange(fromDate) as T extends DatePeriodEnum.Custom ? null : DateRangeType;
        }
        case DatePeriodEnum.Month: {
            return {
                start: dayjs(fromDate).subtract(29, 'days').toDate(),
                end: dayjs(fromDate).toDate(),
            } as T extends DatePeriodEnum.Custom ? null : DateRangeType;
        }
        case DatePeriodEnum.PreviousFullMonth: {
            return getCloserFullMonth(fromDate) as T extends DatePeriodEnum.Custom ? null : DateRangeType;
        }
        case DatePeriodEnum.Quarter: {
            return getCloserFullQuarter(fromDate) as T extends DatePeriodEnum.Custom ? null : DateRangeType;
        }
        case DatePeriodEnum.Custom: {
            return null;
        }
    }

    return null;
}

export function getCatalogsFilterState(
    queries: Readonly<
        Record<
            | RatingsDynamicsFilterQueryParametersEnum.GoogleFilter
            | RatingsDynamicsFilterQueryParametersEnum.YandexFilter
            | RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter
            | RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter,
            string | void
        >
    >
): Pick<
    RatingsDynamicsFilterType,
    | RatingsDynamicsFilterQueryParametersEnum.GoogleFilter
    | RatingsDynamicsFilterQueryParametersEnum.YandexFilter
    | RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter
    | RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter
> {
    let sorterAlreadySet = false;

    function getCatalogFilterValue(
        catalogFilterQuery: string
    ): RatingsDynamicsFilterType[RatingsDynamicsFilterQueryParametersEnum.GoogleFilter] {
        return (
            catalogFilterQuery?.split(',').reduce((accumulator, filterItem) => {
                const filterItemValue = getEnumValue(RatingsDynamicsCatalogFilterValuesEnum, filterItem);

                if (filterItemValue && !accumulator.includes(filterItemValue)) {
                    accumulator.push(filterItemValue);
                    return accumulator;
                }

                const sorterValue = getEnumValue(RatingsDynamicsCatalogSortingValuesEnum, filterItem);

                if (sorterValue && !sorterAlreadySet) {
                    sorterAlreadySet = true;
                    accumulator.push(sorterValue);
                }

                return accumulator;
            }, [] as RatingsDynamicsFilterType[RatingsDynamicsFilterQueryParametersEnum.GoogleFilter]) || []
        );
    }

    function getYandexStatusFilterValue(
        yandexStatusQueryString: string
    ): RatingsDynamicsFilterType[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter] {
        return (
            yandexStatusQueryString?.split(',').reduce((accumulator, filterItem) => {
                const baseStatus = getEnumValue(RatingsDynamicsYandexBaseStatusValuesEnum, filterItem);
                const targetStatus = getEnumValue(RatingsDynamicsYandexTargetStatusValuesEnum, filterItem);

                const yandexStatusValue = baseStatus ?? targetStatus;

                if (yandexStatusValue) {
                    accumulator.push(yandexStatusValue);
                    return accumulator;
                }

                return accumulator;
            }, [] as RatingsDynamicsFilterType[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter]) || []
        );
    }

    return {
        [RatingsDynamicsFilterQueryParametersEnum.GoogleFilter]: getCatalogFilterValue(
            queries[RatingsDynamicsFilterQueryParametersEnum.GoogleFilter] || ''
        ),
        [RatingsDynamicsFilterQueryParametersEnum.YandexFilter]: getCatalogFilterValue(
            queries[RatingsDynamicsFilterQueryParametersEnum.YandexFilter] || ''
        ),
        [RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter]: getCatalogFilterValue(
            queries[RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter] || ''
        ),
        [RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter]: getYandexStatusFilterValue(
            queries[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter] || ''
        ),
    };
}

function serializeRatingsDynamicsFilter(
    filter: RatingsDynamicsFilterType,
    mainFilterKey: string,
    pagination: PaginationDataType
): string {
    return objectToUrlParameters({
        [RatingsDynamicsFilterQueryParametersEnum.BaseDate]: dayjs(
            filter[RatingsDynamicsFilterQueryParametersEnum.BaseDate]
        ).format(ISO_DATE_FORMAT),
        [RatingsDynamicsFilterQueryParametersEnum.TargetDate]: dayjs(
            filter[RatingsDynamicsFilterQueryParametersEnum.TargetDate]
        ).format(ISO_DATE_FORMAT),
        [RatingsDynamicsFilterQueryParametersEnum.Address]: filter[RatingsDynamicsFilterQueryParametersEnum.Address],
        [RatingsDynamicsFilterQueryParametersEnum.Name]: filter[RatingsDynamicsFilterQueryParametersEnum.Name],
        [RatingsDynamicsFilterQueryParametersEnum.Code]: filter[RatingsDynamicsFilterQueryParametersEnum.Code],
        [RatingsDynamicsFilterQueryParametersEnum.GoogleFilter]:
            filter[RatingsDynamicsFilterQueryParametersEnum.GoogleFilter],
        [RatingsDynamicsFilterQueryParametersEnum.YandexFilter]: [
            ...filter[RatingsDynamicsFilterQueryParametersEnum.YandexFilter],
            ...filter[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter],
        ],
        double_gis: filter[RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter],
        page: pagination.page,
        page_size: pagination.pageSize,
        filter_key: mainFilterKey,
    });
}

export function getReviewsRatingsDynamicsUrl(
    filter: RatingsDynamicsFilterType,
    mainFilterKey: string,
    pagination: PaginationDataType
): string {
    return `/v1/analytics/reviews/analytics_delta/?${serializeRatingsDynamicsFilter(
        filter,
        mainFilterKey,
        pagination
    )}`;
}

export function getReviewsRatingsCompanyChartDataUrl(options: {
    companyId: string;
    filter: RatingsDynamicsFilterType;
    mainFilterKey: string;
    regionAvailableCatalogs: UserType['regionAvailableCatalogs'] | null;
}): string {
    const {companyId, filter, regionAvailableCatalogs} = options;

    return `/v2/reviews-analytics/stats/companies/${companyId}/locations/ratings/dynamics/?${objectToUrlParameters({
        start_date: dayjs(startOfUtcDay(filter[RatingsDynamicsFilterQueryParametersEnum.BaseDate])).format(
            ISO_DATE_FORMAT
        ),
        end_date: dayjs(endOfUtcDay(filter[RatingsDynamicsFilterQueryParametersEnum.TargetDate])).format(
            ISO_DATE_FORMAT
        ),
        catalog_ids: [
            ...(regionAvailableCatalogs?.[ProvidersIdsEnum.google] ? [ProvidersIdsEnum.google] : []),
            ...(regionAvailableCatalogs?.[ProvidersIdsEnum.yandex] ? [ProvidersIdsEnum.yandex] : []),
            ...(regionAvailableCatalogs?.[ProvidersIdsEnum.doubleGis] ? [ProvidersIdsEnum.doubleGis] : []),
        ],
    })}`;
}

export function getReviewsRatingsDynamicsRequestExportUrl(
    filter: RatingsDynamicsFilterType,
    mainFilterKey: string,
    regionAvailableCatalogs: UserType['regionAvailableCatalogs'] | null
): string {
    return `/v1/analytics/reviews/analytics_delta/export/?${objectToUrlParameters({
        base_date: dayjs(startOfUtcDay(filter[RatingsDynamicsFilterQueryParametersEnum.BaseDate])).format(
            ISO_DATE_FORMAT
        ),
        target_date: dayjs(endOfUtcDay(filter[RatingsDynamicsFilterQueryParametersEnum.TargetDate])).format(
            ISO_DATE_FORMAT
        ),
        catalog_ids: [
            ...(regionAvailableCatalogs?.[ProvidersIdsEnum.google] ? [ProvidersIdsEnum.google] : []),
            ...(regionAvailableCatalogs?.[ProvidersIdsEnum.yandex] ? [ProvidersIdsEnum.yandex] : []),
            ...(regionAvailableCatalogs?.[ProvidersIdsEnum.doubleGis] ? [ProvidersIdsEnum.doubleGis] : []),
        ],
        [RatingsDynamicsFilterQueryParametersEnum.Address]: filter[RatingsDynamicsFilterQueryParametersEnum.Address],
        [RatingsDynamicsFilterQueryParametersEnum.Name]: filter[RatingsDynamicsFilterQueryParametersEnum.Name],
        [RatingsDynamicsFilterQueryParametersEnum.Code]: filter[RatingsDynamicsFilterQueryParametersEnum.Code],
        [RatingsDynamicsFilterQueryParametersEnum.GoogleFilter]:
            filter[RatingsDynamicsFilterQueryParametersEnum.GoogleFilter],
        [RatingsDynamicsFilterQueryParametersEnum.YandexFilter]: [
            ...filter[RatingsDynamicsFilterQueryParametersEnum.YandexFilter],
            ...filter[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter],
        ],
        double_gis: filter[RatingsDynamicsFilterQueryParametersEnum.DoubleGisFilter],
        filter_key: mainFilterKey,
    })}`;
}
