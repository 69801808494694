/*
brands
Request URL: https://cp.rocketdata.io/op/api/v1/brands/?count=50&page=1&related=true&with_companies=true

brands with company group
Request URL: https://cp.rocketdata.io/op/api/v1/brands/?company_group_ids=22&count=50&page=1&related=true&with_companies=true
*/
import {generateResponseSchema, PaginatedResponseType, RequestOptionsType} from '../../service/api/api-type';
import {fetchAndDeserialize} from '../../util/api-adapter';

import {getFetchBrandsUrl} from './brand-helper';
import {brandSchema, BrandType} from './brand-type';

export function getBrands(option: RequestOptionsType): Promise<PaginatedResponseType<BrandType>> {
    return fetchAndDeserialize(getFetchBrandsUrl(option), generateResponseSchema(brandSchema), {
        deserializeOptions: {
            ignorePaths: [/features.*/],
        },
    });
}
