import {BarDataSetType} from '../layout/chart/bar-chart/bar-chart-type';

export function checkIsEmptyChartData(chartData: Array<BarDataSetType> | undefined): boolean {
    if (!chartData) {
        return false;
    }

    return (
        chartData
            .flatMap(({values}: BarDataSetType): Array<number> => values)
            .filter((value: number): boolean => value !== 0).length === 0
    );
}
