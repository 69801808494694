import {Carousel} from 'antd';

import {useSystem} from '../../../provider/system/system-hook';
import {classNames} from '../../../util/css';
import {PhoneLayout} from '../../phone-layout/phone-layout';

import {SafariImagesCarousel} from './components/safari-images-carousel/safari-images-carousel';
import {AUTO_SPEED_PLAY_CAROUSEL} from './phone-illustration-const';
import * as styles from './phone-illustration.scss';

type PropsType = {
    className?: string;
    images: [string, ...Array<string>];
};

export function PhoneIllustration(props: PropsType): JSX.Element {
    const {className, images} = props;

    const {browsers} = useSystem();
    const {isSafari} = browsers;

    return (
        <div className={classNames(styles.PhoneIllustration, className)}>
            <PhoneLayout>
                {!isSafari ? (
                    <Carousel autoplay autoplaySpeed={AUTO_SPEED_PLAY_CAROUSEL} effect="fade">
                        {images.map((imageSrc: string) => (
                            <img alt="" className={styles.PhoneIllustration_image} key={imageSrc} src={imageSrc} />
                        ))}
                    </Carousel>
                ) : (
                    <SafariImagesCarousel autoplaySpeed={AUTO_SPEED_PLAY_CAROUSEL} images={images} />
                )}
            </PhoneLayout>
        </div>
    );
}
