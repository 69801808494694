import {z as r} from 'zod';

import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const reviewCitySchema = r.object({
    cityId: r.number(),
    cityName: r.string(),
    total: r.number(),
    avgRating: r.number(),
    replied: r.number(),
    repliedPercent: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
    trendByThreeMonths: r.array(
        r.object({
            date: r.string(),
            value: r.number(),
        })
    ),
});

export type ReviewCityType = r.infer<typeof reviewCitySchema>;

export const reviewCityListResponse = r.object({
    count: r.number(),
    page: r.number(),
    pages: r.number(),
    next: r.string().nullable().optional(),
    previous: r.string().nullable().optional(),
    pageSize: r.number(),
    results: r.array(reviewCitySchema),
});

export type ReviewCityListQueryType = {
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};
