import {Tag, Tooltip} from 'antd';
import {ReactElement} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {CompaniesImportListType} from '../../../../../../../../service/companies-import/companies-import-list';
import {classNames} from '../../../../../../../../util/css';

import {LIMITED_NUMBER_OF_BRANDS} from './feed-brands-column-const';
import * as styles from './feed-brands-column.scss';

type PropsType = {
    brands: CompaniesImportListType['results'][0]['brands'];
};

export function FeedBrandsColumn(props: PropsType): ReactElement {
    const {brands} = props;

    const firstFiveBrands = brands.slice(0, LIMITED_NUMBER_OF_BRANDS);

    if (brands.length === 1) {
        return (
            <Tooltip placement="bottom" title={brands[0]?.name}>
                <Text className={styles.BrandsColumn_tag}>{brands[0]?.name}</Text>
            </Tooltip>
        );
    }

    return (
        <AdditionalInfo
            className={styles.BrandsColumn_additionalInfo}
            icon={<Tag className={styles.BrandsColumn_tag}>{brands.length}</Tag>}
            placement="bottom"
            title={
                <div className={styles.BrandsColumn_title}>
                    <Locale stringKey="USERS__TABLE__BRANDS__TITLE" />

                    <span>{brands.length}</span>
                </div>
            }
        >
            <ul
                className={classNames(styles.BrandsColumn_list, {
                    [styles.BrandsColumn_list__noTextBelow]: brands.length <= LIMITED_NUMBER_OF_BRANDS,
                })}
            >
                {firstFiveBrands.map(({name, id}) => (
                    <li key={id}>{name}</li>
                ))}
            </ul>

            {brands.length > LIMITED_NUMBER_OF_BRANDS ? (
                <Text
                    secondary
                    stringKey="USERS__TABLE__BRANDS__MORE"
                    valueMap={{count: brands.length - LIMITED_NUMBER_OF_BRANDS}}
                />
            ) : null}
        </AdditionalInfo>
    );
}
