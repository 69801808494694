import {Alert} from 'antd';

import {useCatalog} from '../../../../provider/catalogs/catalogs-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {ProvidersIdsEnum} from '../../../../util/type';

import * as styles from './online-presence-ananytics-wgis-problems.scss';

export function OnlinePresenceAnalyticsTemporaryProblems(): JSX.Element | null {
    const {getLocalizedString} = useLocale();

    const {user} = useUser();

    const {regionAvailableCatalogs} = user || {};

    const {catalog: wGisConfig} = useCatalog(ProvidersIdsEnum.doubleGis);

    if (regionAvailableCatalogs?.[ProvidersIdsEnum.doubleGis]) {
        return (
            <Alert
                className={styles.OnlinePresenceAnalyticsTemporaryProblems}
                message={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__PROBLEMS_WGIS', {
                    wGis: wGisConfig?.name || '',
                })}
                showIcon
                type="warning"
            />
        );
    }

    return null;
}
