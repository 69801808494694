import {ReviewDataType} from '../../../service/reviews/reviews-type';
import {getDateInCurrentTimezone} from '../../../util/date';

export function isReviewReplyAllowed(review: ReviewDataType): boolean {
    const {brand, deletedAt, ableToReply} = review;

    return (ableToReply || brand.isTest) && !deletedAt;
}

export function getReviewCreationDate(review: {
    createdInCatalog: ReviewDataType['createdInCatalog'];
    createdInRd: ReviewDataType['createdInRd'];
}): string {
    const {createdInCatalog, createdInRd} = review;

    return getDateInCurrentTimezone(new Date(createdInCatalog || createdInRd))
        .toISOString()
        .replace('Z', '');
}
