import {z as r} from 'zod';

import {deserializeV2} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

import {languageSchema, LanguageType} from './language-type';

export async function fetchLanguages(): Promise<Array<LanguageType>> {
    const url = rootApiUrl + '/v2/languages/';
    const response: Array<unknown> = await fetchX(url);

    return deserializeV2<Array<LanguageType>>(url, r.array(languageSchema), response);
}
