import {Tooltip} from 'antd';

import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';

import * as styles from './sup.scss';

export function NewSup(): JSX.Element {
    return (
        <Tooltip
            placement="bottom"
            title={
                <div className={styles.Sup_tooltip}>
                    <Locale stringKey="NEW_FEATURE__TOOLTIP" />
                </div>
            }
        >
            <sup className={classNames(styles.Sup, styles.Sup__new)}>new</sup>
        </Tooltip>
    );
}
