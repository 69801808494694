import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Form, FormInstance, Input, Modal, Radio, RadioChangeEvent} from 'antd';
import {useEffect, useState} from 'react';

import {Locale} from '../../../provider/locale/locale';
import {classNames} from '../../../util/css';
import {getEnumValue} from '../../../util/enum';

import {ReportNameHint} from './report-name-hint/report-name-hint';
import {defaultReportTypeOptions, ExportReportModeEnum, reportFormId} from './request-report-popup-const';
import {ReportPopupItemType} from './request-report-popup-type';
import * as styles from './request-report-popup.scss';

type PropsType<F> = {
    width?: string | number;
    className?: string;
    onClose: () => void;
    isOpen: boolean;
    onSubmit: (mode: ExportReportModeEnum, formValues?: F) => void;
    title: React.ReactNode;
    defaultFilename: string;
    reportTypeOptions?: Array<ReportPopupItemType>;
    loading: boolean;
    formItemNameInput: string;
};

export function RequestReportPopup<F>(props: PropsType<F>): JSX.Element {
    const {
        width,
        className,
        isOpen,
        onClose,
        title,
        defaultFilename,
        reportTypeOptions = defaultReportTypeOptions,
        onSubmit,
        loading,
        formItemNameInput,
    } = props;

    const [form]: [FormInstance<F>] = Form.useForm();
    const [selectedMode, setSelectedMode] = useState<ExportReportModeEnum | null>(null);

    useEffect(() => {
        if (isOpen) {
            setSelectedMode(ExportReportModeEnum.Pdf);
        }
    }, [form, isOpen]);

    function onSelectModeChange(radioEvent: RadioChangeEvent) {
        const reportMode: ExportReportModeEnum | null = getEnumValue<ExportReportModeEnum>(
            ExportReportModeEnum,
            radioEvent.target.value
        );

        setSelectedMode(reportMode);
        form.resetFields();
    }

    async function handleSubmit(formValues: F) {
        await onSubmit(selectedMode || ExportReportModeEnum.Pdf, formValues);

        onClose();
        form.resetFields();
    }

    return (
        <Modal
            className={classNames(styles.RequestReportPopup, className)}
            footer={[
                <Button className={styles.RequestReportPopup_footerButton} key="close" onClick={onClose}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button
                    className={styles.RequestReportPopup_footerButton}
                    disabled={selectedMode === null}
                    form={reportFormId}
                    htmlType="submit"
                    key="submit"
                    loading={loading}
                    type="primary"
                >
                    <Locale stringKey="BUTTON__DOWNLOAD" />
                </Button>,
            ]}
            onCancel={onClose}
            open={isOpen}
            title={title}
            width={width}
        >
            <Form<F> form={form} id={reportFormId} layout="vertical" onFinish={handleSubmit}>
                <Alert
                    className={styles.RequestReportPopup_info}
                    icon={<FontAwesomeIcon className={styles.RequestReportPopup_infoIcon} icon={faInfoCircle} />}
                    message={<Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__INFO" />}
                    showIcon
                    type="info"
                />
                <Radio.Group
                    className={styles.RequestReportPopup_list}
                    onChange={onSelectModeChange}
                    value={selectedMode}
                >
                    {reportTypeOptions.map((reportData: ReportPopupItemType): JSX.Element => {
                        const {mode, header, description} = reportData;

                        return (
                            <div className={styles.RequestReportPopup_listItem} key={mode}>
                                <Radio value={mode}>
                                    <h5 className={styles.RequestReportPopup_itemHeader}>
                                        <Locale stringKey={header} />
                                    </h5>
                                </Radio>
                                <div className={styles.RequestReportPopup_descriptionSection}>
                                    <p className={styles.RequestReportPopup_itemDescription}>
                                        <Locale stringKey={description} />
                                    </p>
                                    {selectedMode === mode && (
                                        <Form.Item
                                            className={styles.RequestReportPopup_infoFormItem}
                                            initialValue=""
                                            label={<ReportNameHint />}
                                            name={formItemNameInput}
                                        >
                                            <Input placeholder={defaultFilename} />
                                        </Form.Item>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </Radio.Group>
            </Form>
        </Modal>
    );
}
