import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useCatalog} from '../../../../provider/catalogs/catalogs-hook';
import {ProvidersIdsEnum} from '../../../../util/type';

import {AccountLinkingVk} from './account-linking-vk';

export function AccountLinkingVkCard(): JSX.Element {
    const {catalog, isLoading} = useCatalog(ProvidersIdsEnum.vkontakte);

    return (
        <PageCard title={catalog?.name || ''}>
            {catalog && <AccountLinkingVk catalogInfo={catalog} />}
            {isLoading && <Spinner />}
        </PageCard>
    );
}
