export const enSourceSettingsTranslations = {
    SOURCE_SETTINGS__ACCESS_DENIED_PAGE__TITLE: 'We are sorry, access is restricted',
    SOURCE_SETTINGS__ACCESS_DENIED_PAGE__SUB_TITLE: 'Contact your administration to obtain access rights',
    SOURCE_SETTINGS__GROUPS__TITLE: 'Source groups',
    SOURCE_SETTINGS__GROUPS__ALL_SOURCES: 'All sources',
    SOURCE_SETTINGS__GROUPS__HINT_TITLE: 'Sources',
    SOURCE_SETTINGS__GROUPS__HINT_TEXT: 'Check out popular sites and geoservices {link}',
    SOURCE_SETTINGS__GROUPS__SUB_TITLE: 'Add origin credentials to respond to reviews via RocketData.',
    SOURCE_SETTINGS__GROUPS__GROUP_SELECTOR__HINT__LINK: 'here',
    SOURCE_SETTINGS__GROUPS__SOURCE_OPTION__LINKS: 'Links',
    SOURCE_SETTINGS__GROUPS__SOURCE_OPTION__ACCOUNTS: 'Accounts',
    SOURCE_SETTINGS__SOURCES__PROPOSE_BUTTON__LABEL: 'Suggest a source',
    SOURCE_SETTINGS__LINKS__LINK_TYPE_BRAND: 'Brands',
    SOURCE_SETTINGS__LINKS__LINK_TYPE_COMPANY: 'Companies',
    SOURCE_SETTINGS__LINKS__ADD_NEW__BUTTON__LABEL: 'Add link',
    SOURCE_SETTINGS__LINKS__LINKS__EMPTY__TITLE: 'No added links',
    SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__LINK: 'Link',
    SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__COMPANY: 'Company',
    SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__BRAND: 'Brand',
    SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__STATUS: 'Status',
    SOURCE_SETTINGS__LINKS__LINKS__COLUMN_HEADER__ACTIONS: 'Action',
    SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCED: 'Synchronized',
    SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCHRONIZATION: 'In moderation',
    SOURCE_SETTINGS__LINKS__LINKS__STATUS__FAIL: 'Error',
    SOURCE_SETTINGS__LINKS__LINKS__STATUS__FAIL__HINT: 'Please update the link to collect feedback',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__TITLE: 'Add a link to the review page',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__TITLE: 'Change a link to the review page',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__HELP: 'Enter a link to the page',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__LABEL: 'Link',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__PLACEHOLDER: 'Paste a link',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__SERVER_ERROR_ADDON:
        'Check the link or contact our support team.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__HELP: 'You can see examples of links {here}.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__LINK__INPUT__HELP_HERE: 'here',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE_REVIEWS__CHECKBOX__LABEL:
        'Delete reviews previously collected at this link from the {companyName} personal account',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__LABEL: 'Relevant brands or companies',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__FOOTER:
        'Brand reviews do not include reviews of individual companies',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__HINT:
        'Select brands or companies inside your personal account {companyName} to respond to reviews.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__PLACEHOLDER: 'Choose a brand',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__LABEL: 'Company',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__HINT_TITLE: 'Relevant company',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__HINT:
        'If the company is not selected, the review will be attributed to the brand',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__COMPANY__SELECT__PLACEHOLDER: 'Start typing your name or address',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__SUCCESS__TITLE: 'Link added',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__SUCCESS__CONTENT:
        'A link to the {catalogName} page was successfully added. New feedback will begin to arrive in the {companyName} personal account within 24 hours.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__ADD__FAIL__TITLE: 'Unable to add link',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__SUCCESS__TITLE: 'Link updated',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__SUCCESS__CONTENT__REMOVE_REVIEWS:
        'The link to the {catalogName} page was successfully changed. Reviews collected before at the link will be removed from the {companyName} personal account. New feedback will begin to arrive in the {companyName} personal account within 24 hours.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__SUCCESS__CONTENT__KEEP_REVIEWS:
        'The link to the {catalogName} page was successfully changed. Feedback collected before via the link will be saved in the {companyName} personal account. New feedback will begin to arrive in the {companyName} personal account within 24 hours.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__EDIT__FAIL__TITLE: 'Unable to update link',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__TITLE: 'Delete a link',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__CONTENT:
        'Reviews from this page {catalogName} will no longer appear in your account.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__SUCCESS__TITLE: 'Link removed',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__SUCCESS__CONTENT__REMOVE_REVIEWS:
        'The link to the {catalogName} page was successfully deleted. Feedback will no longer be collected. Reviews collected before at the link will be deleted from the {companyName} personal account.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__SUCCESS__CONTENT__KEEP_REVIEWS:
        'The link to the {catalogName} page was successfully deleted. Feedback will no longer be collected. Feedback collected before via the link will be saved in the {companyName} personal account.',
    SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__FAIL__TITLE: 'Unable to remove link',
    SOURCE_SETTINGS__LINKS__PROPOSE__ADD__TITLE: 'Suggest platform or source',
    SOURCE_SETTINGS__LINKS__PROPOSE__HELP:
        'Here you can offer platforms from which you would like to collect reviews in {companyName}',
    SOURCE_SETTINGS__LINKS__PROPOSE__INPUT__PLACEHOLDER: 'Specify platforms/sources',
    SOURCE_SETTINGS__LINKS__PROPOSE__SUCCESS__CONTENT:
        'Your request was sent! {companyName} will notify you of the result by email',
    SOURCE_SETTINGS__LINKS__PROPOSE__SUCCESS__TITLE: 'Sent',
    SOURCE_SETTINGS__LINKS__PROPOSE__FAIL__TITLE: 'Request sending failed',
    SOURCE_SETTINGS__ACCOUNTS__PAGE_TITLE: '{name} accounts',
    SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT: 'Add an account',
    SOURCE_SETTINGS__ACCOUNTS__EDIT_ACCOUNT__MODAL_TITLE: 'Changing account',
    SOURCE_SETTINGS__ACCOUNTS__EDIT_SUCCESS_TITLE: 'Account changed',
    SOURCE_SETTINGS__ACCOUNTS__EDIT_FAIL_TITLE: 'Failed to change account',
    SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT__MODAL_TITLE: 'Adding an account',
    SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT__MODAL_TEXT:
        'Add your service login credentials to be able to respond and report reviews to your {companyName} account.',
    SOURCE_SETTINGS__ACCOUNTS__ADD_ACCOUNT__MODAL_DISCLAIMER: 'We store your data securely in encrypted form!',
    SOURCE_SETTINGS__ACCOUNTS__ADD_SUCCESS_TITLE: 'Account added',
    SOURCE_SETTINGS__ACCOUNTS__ADD_SUCCESS_TEXT:
        'You will be able to reply to and report reviews once your credentials have been verified.',
    SOURCE_SETTINGS__ACCOUNTS__ADD_FAIL_TITLE: 'Failed to add account',
    SOURCE_SETTINGS__ACCOUNTS__ADD_FAIL_TEXT: 'Please try again later or contact support.',
    SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_INPUT: 'Login',
    SOURCE_SETTINGS__ACCOUNTS__EDIT__LOGIN_INPUT: 'New login',
    SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_HINT: 'Email address or username in the source.',
    SOURCE_SETTINGS__ACCOUNTS__ADD__LOGIN_PLACEHOLDER: 'Your login',
    SOURCE_SETTINGS__ACCOUNTS__ADD__PASSWORD_INPUT: 'Password',
    SOURCE_SETTINGS__ACCOUNTS__EDIT__PASSWORD_INPUT: 'New password',
    SOURCE_SETTINGS__ACCOUNTS__ADD__PASSWORD_PLACEHOLDER: 'Your password',
    SOURCE_SETTINGS__ACCOUNTS__BRANDS: 'Brands',
    SOURCE_SETTINGS__ACCOUNTS__COMPANIES: 'Companies',
    SOURCE_SETTINGS__ACCOUNTS__ACCOUNT_CELL: 'Account',
    SOURCE_SETTINGS__ACCOUNTS__DELETE_MODAL_TITLE: 'Delete account?',
    SOURCE_SETTINGS__ACCOUNTS__DELETE_MODAL_TEXT:
        'You will lose the ability to respond to and report reviews from this source through {companyName} if there are no other accounts for the brand.',
    SOURCE_SETTINGS__ACCOUNTS__DELETE_SUCCESS_TITLE: 'Account deleted',
    SOURCE_SETTINGS__ACCOUNTS__DELETE_SUCCESS_TEXT: 'You have deleted your {catalogName} account.',
    SOURCE_SETTINGS__ACCOUNTS__DELETE_FAIL_TITLE: 'Failed to delete account',
    SOURCE_SETTINGS__ACCOUNTS__DELETE_FAIL_TEXT: 'Please try again later or contact support.',
    SOURCE_SETTINGS__ACCOUNTS__EMPTY__TITLE: 'No accounts added',
    SOURCE_SETTINGS__ACCOUNTS__DISABLED_MANUAL_MANAGEMENT:
        'Add credentials to log in to the service to be able to respond and send complaints about reviews inside the {companyName} personal account.',
    SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__LOGIN: 'Account',
    SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__BRANDS_COMPANIES: 'Brands / Companies',
    SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__STATUS: 'Status',
    SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__DATE: 'Date of change',
    SOURCE_SETTINGS__ACCOUNTS__COLUMN_HEADER__ACTIONS: 'Actions',
    SOURCE_SETTINGS__ACCOUNTS__ERROR_HAS_NOT_ACCESS: 'Has not access to brand',
    SOURCE_SETTINGS__ACCOUNTS__ERROR_INVALID_CREDITNAILS: 'Invalid credentials',
    SOURCE_SETTINGS__ACCOUNTS__ERROR_UNAVAILABLE: 'Reply or abuse unavailable',
    SOURCE_SETTINGS__ACCOUNTS__POPOVER__BRANDS__SINGULAR: 'More {count} brand...',
    SOURCE_SETTINGS__ACCOUNTS__POPOVER__BRANDS__FEW: 'More {count} brands...',
    SOURCE_SETTINGS__ACCOUNTS__POPOVER__BRANDS__MANY: 'More {count} brands...',
    SOURCE_SETTINGS__ACCOUNTS__POPOVER__COMPANIES__SINGULAR: 'More {count}company..',
    SOURCE_SETTINGS__ACCOUNTS__POPOVER__COMPANIES__FEW: 'More {count} companies...',
    SOURCE_SETTINGS__ACCOUNTS__POPOVER__COMPANIES__MANY: 'More {count} companies...', //
    SOURCE_SETTINGS__ACCOUNTS__TAG__BRANDS__SINGULAR: 'brand',
    SOURCE_SETTINGS__ACCOUNTS__TAG__BRANDS__FEW: 'brands',
    SOURCE_SETTINGS__ACCOUNTS__TAG__BRANDS__MANY: 'brands',
    SOURCE_SETTINGS__ACCOUNTS__TAG__COMPANIES__SINGULAR: 'company',
    SOURCE_SETTINGS__ACCOUNTS__TAG__COMPANIES__FEW: 'companies',
    SOURCE_SETTINGS__ACCOUNTS__TAG__COMPANIES__MANY: 'companies',
    SOURCE_SETTINGS__ACCOUNTS__ERROR_TEXT__TITLE: 'Connection error',
    SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__TITLE: 'Linking companies to a new account',
    SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__TITLE_BRAND: 'Linking brands to a new account', //
    SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__CONTENT:
        'You have selected companies that were previously linked to other accounts. If you confirm the choice of these companies, they will be linked to the new account.',
    SOURCE_SETTINGS__ACCOUNTS__NEW_ACCOUNT__MODAL__CONTENT_BRAND:
        'You have selected brands that were previously linked to other accounts. If you confirm the selection of these brands, they will be linked to the new account.', //
    SOURCE_SETTINGS__ACCOUNTS__COMPANY__MODAL__CHECKBOX: 'Show companies linked to accounts',
    SOURCE_SETTINGS__ACCOUNTS__COMPANY__TABLE__COLUMN__NAME: 'Name',
    SOURCE_SETTINGS__ACCOUNTS__COMPANY__TABLE__COLUMN__CODE: 'Code',
    SOURCE_SETTINGS__ACCOUNTS__COMPANY__TABLE__COLUMN__ADDRESS: 'Company address',
    SOURCE_SETTINGS__ACCOUNTS__COMPANY__POPOVER__TITLE: 'The company is already linked',
    SOURCE_SETTINGS__ACCOUNTS__COMPANY__POPOVER__CONTENT: 'Account {account}',
    SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__COMPANY: 'Company',
    SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__BRAND: 'Brand', //
    SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__SOURCE: 'Source',
    SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__ACCOUNT: 'Account',
};
