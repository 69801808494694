import {FormattedNumberWithTooltip} from '../../../../component/number-value/formatted-number-with-tooltip';
import {PageCard} from '../../../../layout/page-card/page-card';
import {FormattedNumber} from '../../../../provider/locale/formatted-number';
import {Locale} from '../../../../provider/locale/locale';
import {FormattedPeriodsType, OnlinePresenceMetricType} from '../../../../service/online-presence/online-presence-type';
import {CompareTable} from '../compare-table/compare-table';

import * as styles from './primary-indicators.scss';

type PropsType = {
    data: {
        impressions: OnlinePresenceMetricType;
        interactions: OnlinePresenceMetricType;
        conversion: OnlinePresenceMetricType;
    };

    isCompareMode: boolean;
    formattedPeriods?: FormattedPeriodsType;
    bordered?: boolean;
};

export function PrimaryIndicators(props: PropsType): JSX.Element {
    const {data, isCompareMode, formattedPeriods, bordered} = props;
    const {impressions, interactions, conversion} = data;

    return (
        <PageCard bordered={bordered} title={<Locale stringKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS" />}>
            {isCompareMode && (
                <CompareTable
                    formattedPeriods={formattedPeriods}
                    metricsNameHeadKey="ONLINE_PRESENCE_ANALYTICS__MARKER"
                    rows={[
                        {
                            titleKey: 'ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__IMPRESSIONS',
                            currentValue: impressions.current,
                            comparePeriodValue: impressions.compared,
                            delta: impressions.delta,
                        },
                        {
                            titleKey: 'ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__TARGET_ACTIONS',
                            currentValue: interactions.current,
                            comparePeriodValue: interactions.compared,
                            delta: interactions.delta,
                        },
                        {
                            titleKey: 'ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__CONVERSION',
                            currentValue: conversion.current,
                            comparePeriodValue: conversion.compared,
                            unit: 'percent',
                            delta: conversion.delta,
                        },
                    ]}
                />
            )}

            {!isCompareMode && (
                <div className={styles.PrimaryIndicators_singlePeriod}>
                    <div>
                        <div className={styles.PrimaryIndicators_singlePeriod__title}>
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__IMPRESSIONS" />
                        </div>
                        <div className={styles.PrimaryIndicators_singlePeriod__value}>
                            <FormattedNumberWithTooltip value={impressions.current} />
                        </div>
                    </div>
                    <div>
                        <div className={styles.PrimaryIndicators_singlePeriod__title}>
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__TARGET_ACTIONS" />
                        </div>
                        <div className={styles.PrimaryIndicators_singlePeriod__value}>
                            <FormattedNumberWithTooltip value={interactions.current} />
                        </div>
                    </div>
                    <div>
                        <div className={styles.PrimaryIndicators_singlePeriod__title}>
                            <Locale stringKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__CONVERSION" />
                        </div>
                        <div className={styles.PrimaryIndicators_singlePeriod__value}>
                            <FormattedNumber
                                options={{
                                    maximumFractionDigits: 1,
                                }}
                                value={conversion.current}
                            />
                            %
                        </div>
                    </div>
                </div>
            )}
        </PageCard>
    );
}
