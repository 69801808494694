import {faCircleCheck, faCircleXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {App} from 'antd';
import {isObject} from 'lodash';
import {useCallback, useMemo} from 'react';

import {classNames} from '../../util/css';

import {DURATION_MS} from './snackbar-const';
import {SnackbarArgsType, SnackbarHookType} from './snackbar-type';
import * as styles from './snackbar.scss';

// TODO: rename to useNotification
export function useSnackbar(): SnackbarHookType {
    const {notification} = App.useApp();

    const {open, destroy} = notification;

    const getFullArgs = useCallback((rawArgs: SnackbarArgsType) => {
        return isObject(rawArgs) && 'message' in rawArgs ? rawArgs : {message: rawArgs};
    }, []);

    const showNotification = useCallback(
        (args: ReturnType<typeof getFullArgs>, className: string) => {
            open({
                duration: DURATION_MS,
                ...args,
                className: classNames(styles.snackbar, className, 'className' in args ? args.className : ''),
            });
        },
        [open]
    );

    const success = useCallback(
        (args: SnackbarArgsType) => {
            showNotification(
                {
                    icon: (
                        <FontAwesomeIcon
                            className="anticon ant-notification-notice-icon-success"
                            icon={faCircleCheck}
                        />
                    ),
                    ...getFullArgs(args),
                },
                styles.snackbar__success
            );
        },
        [getFullArgs, showNotification]
    );

    const error = useCallback(
        (args: SnackbarArgsType) => {
            showNotification(
                {
                    icon: (
                        <FontAwesomeIcon className="anticon ant-notification-notice-icon-error" icon={faCircleXmark} />
                    ),
                    ...getFullArgs(args),
                },
                styles.snackbar__error
            );
        },
        [getFullArgs, showNotification]
    );

    return useMemo(
        () => ({
            snackbar: {error, success},
            destroy,
        }),
        [error, success, destroy]
    );
}
