import {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {sharedSearchParameters} from '../../shared-search-parameters';
import {ObjectToUrlParametersType, QueryMapType} from '../type';
import {getParametersFromUrl, objectToUrlParameters} from '../url';

import {urlHookDefaultOptions} from './url-hook-const';
import {UseUrlHookOptionsType, UseUrlHookType} from './url-hook-type';

export function useUrl<
    QueryMap extends ObjectToUrlParametersType = ObjectToUrlParametersType
>(): UseUrlHookType<QueryMap> {
    const routerHistory = useHistory<Location>();
    const {location: routerLocation, goBack, action} = routerHistory;
    const {search, pathname, hash} = routerLocation;

    const queries: QueryMapType<keyof QueryMap> = useMemo(() => {
        return getParametersFromUrl('http://localhost/' + search);
    }, [search]);

    const pushRoute = useCallback(
        (newPathname: string, queriesInner: ObjectToUrlParametersType, options?: UseUrlHookOptionsType): void => {
            const definedOptions = {...urlHookDefaultOptions, ...options};

            const resultQueries = definedOptions.isSaveQuery
                ? queries
                : Object.fromEntries(Object.entries(queries).filter(([key]) => sharedSearchParameters.includes(key)));
            const resultQueryMap = {...resultQueries, ...queriesInner};

            routerHistory.push({
                pathname: newPathname,
                search: objectToUrlParameters(resultQueryMap, {isOnlyDate: options?.isOnlyDate}),
                hash: definedOptions.isSaveHash ? hash : undefined, // eslint-disable-line no-undefined
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queries, routerHistory]
    );

    const replaceRoute = useCallback(
        (newPathname: string, queriesInner: ObjectToUrlParametersType, options?: UseUrlHookOptionsType): void => {
            const definedOptions = {...urlHookDefaultOptions, ...options};

            const resultQueries = definedOptions.isSaveQuery
                ? queries
                : Object.fromEntries(Object.entries(queries).filter(([key]) => sharedSearchParameters.includes(key)));
            const resultQueryMap = {...resultQueries, ...queriesInner};

            routerHistory.replace({
                pathname: newPathname,
                search: objectToUrlParameters(resultQueryMap),
                hash,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queries, routerHistory]
    );

    const setQuery = useCallback(
        (queryMap: Partial<QueryMap>, options?: UseUrlHookOptionsType): void => {
            pushRoute(pathname, queryMap, {isSaveQuery: true, ...options});
        },
        [pushRoute, pathname]
    );

    const getQuery = useCallback(
        (key: keyof QueryMap): string | null => {
            const queryValue: string | void = queries[key];

            return queryValue || null;
        },
        [queries]
    );

    const deleteQuery = useCallback(
        (key: keyof QueryMap | Array<keyof QueryMap>): void => {
            const keys = Array.isArray(key) ? key : [key];
            const query = Object.fromEntries(keys.map((keyToRemove) => [keyToRemove, null]));

            pushRoute(pathname, query, {isSaveQuery: true});
        },
        [pathname, pushRoute]
    );

    const pushUrl = useCallback(
        (newPathname: string, options?: UseUrlHookOptionsType): void => {
            pushRoute(newPathname, {}, options);
        },
        [pushRoute]
    );

    const pushState = useCallback(
        (newPathname: string, queryMap: Partial<QueryMap>, options?: UseUrlHookOptionsType): void => {
            pushRoute(newPathname, queryMap, options);
        },
        [pushRoute]
    );

    const replaceUrl = useCallback(
        (newPathname: string, options?: UseUrlHookOptionsType): void => {
            replaceRoute(newPathname, {}, options);
        },
        [replaceRoute]
    );

    const replaceHash = useCallback(
        (newHash: string): void => {
            routerHistory.replace({search, hash: newHash});
        },
        [routerHistory, search]
    );

    const replaceState = useCallback(
        (newPathname: string, queryMap: Partial<QueryMap>, options?: UseUrlHookOptionsType): void => {
            replaceRoute(newPathname, queryMap, options);
        },
        [replaceRoute]
    );

    const replaceQuery = useCallback(
        (queryMap: Partial<QueryMap>, options?: UseUrlHookOptionsType): void => {
            replaceRoute(pathname, queryMap, {isSaveQuery: true, ...options});
        },
        [replaceRoute, pathname]
    );

    const hasBack = useMemo(() => {
        return action !== 'POP';
    }, [action]);

    return useMemo((): UseUrlHookType<QueryMap> => {
        return {
            setQuery,
            getQuery,
            deleteQuery,
            pushUrl,
            pushState,
            queries,
            pathname,
            hash,
            replaceHash,
            replaceState,
            replaceQuery,
            replaceUrl,
            goBack,
            hasBack,
        };
    }, [
        setQuery,
        getQuery,
        deleteQuery,
        pushUrl,
        pushState,
        queries,
        pathname,
        hash,
        replaceHash,
        replaceState,
        replaceQuery,
        replaceUrl,
        goBack,
        hasBack,
    ]);
}

export function useUrlQueryNumber<QueryMap extends ObjectToUrlParametersType = ObjectToUrlParametersType>(
    value: keyof QueryMap
): number | null {
    const {getQuery} = useUrl<QueryMap>();

    const number = Number.parseInt(getQuery(value) ?? '', 10);

    return Number.isSafeInteger(number) ? number : null;
}

export function useUrlQueryNumberArray<QueryMap extends ObjectToUrlParametersType = ObjectToUrlParametersType>(
    value: keyof QueryMap
): Array<number> {
    const {getQuery} = useUrl<QueryMap>();

    const query = getQuery(value);

    if (!query) {
        return [];
    }

    const numbers = query.split(',').map((number) => Number.parseInt(number ?? '', 10));

    if (numbers.some((number) => !Number.isSafeInteger(number))) {
        return [];
    }

    return numbers;
}
