import {z as r} from 'zod';

export const localStorageAiButtonShowSchema = r.record(
    r.string(),
    r.object({
        count: r.number(),
    })
);

export type LocalStorageAiButtonShowType = r.infer<typeof localStorageAiButtonShowSchema>;
