import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';

import {searchPhrasesLowValueThreshold} from './search-phrases-const';
import * as styles from './search-phrases.scss';

type PropsType = {
    text: string;
    value: number;
};

export function SearchPhrasesItem(props: PropsType): JSX.Element {
    const {text, value} = props;

    const {getFormattedNumber} = useFormat();

    return (
        <div className={styles.SearchPhrases_itemContainer}>
            <div className={styles.SearchPhrases_itemText}>{text}</div>
            <div
                className={classNames(styles.SearchPhrases_itemValue, {
                    [styles.SearchPhrases_itemValue__lowValue]: value < searchPhrasesLowValueThreshold,
                })}
            >
                {value < searchPhrasesLowValueThreshold
                    ? `<${searchPhrasesLowValueThreshold}`
                    : getFormattedNumber(value)}
            </div>
        </div>
    );
}
