import {z as r} from 'zod';

export type UiContextType = {
    isLeftSideOpen: boolean;
    setIsLeftSideOpen: (isLeftSideOpen: boolean) => void;
    isMobileNavigationOpen: boolean;
    setIsMobileNavigationOpen: (isMobileNavigationOpen: boolean) => void;
};

export const uiContextSavedDataSchema = r.object({
    isLeftSideOpen: r.boolean(),
});

export type UiContextSavedDataType = r.infer<typeof uiContextSavedDataSchema>;
