import {AccountLinkingCardTypeEnum} from '../../../../../component/account/account-card-type';
import {FacebookAccountType, GoogleAccountType} from '../../../../../service/accounts/accounts-type';

function isFacebookAccount(account: FacebookAccountType | GoogleAccountType): account is FacebookAccountType {
    return 'notGrantedScopes' in account;
}

export function getAccountCardType(account: FacebookAccountType | GoogleAccountType): AccountLinkingCardTypeEnum {
    return isFacebookAccount(account) && account.notGrantedScopes.length > 0
        ? AccountLinkingCardTypeEnum.ERROR
        : AccountLinkingCardTypeEnum.DEFAULT;
}
