import {CentrifugeDeployNotifications} from './deploy/centrifuge-deploy-notifications';

type PropsType = {
    onDeployActionReceived: VoidFunction;
};

export function CentrifugeListenersUnauthorized(props: PropsType): JSX.Element {
    const {onDeployActionReceived} = props;

    return <CentrifugeDeployNotifications onDeployActionReceived={onDeployActionReceived} />;
}
