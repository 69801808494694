import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {PhotoReportTypeEnum} from '../../../../../service/phototool/phototool-type';

import {ReportFormFieldEnum, ReportFormType} from './photo-report-modal-type';

export const reportFormId = 'photo-report-form-id';

export const INITIAL_REPORT_PHOTOS_VALUES: ReportFormType = {
    [ReportFormFieldEnum.type]: null,
    [ReportFormFieldEnum.message]: '',
};

export const ALLOWED_PHOTO_REPORT_TYPES: Array<PhotoReportTypeEnum> = Object.values(PhotoReportTypeEnum).filter(
    (item) => item !== PhotoReportTypeEnum.OrganizationMatchingError
);

export const PHOTO_REPORT_TYPE_LOCALIZATION_MAP: Record<PhotoReportTypeEnum, LangKeyType> = {
    [PhotoReportTypeEnum.OffensiveContent]: 'PHOTO_TOOL__REPORT_TYPE__OFFENSIVE_CONTENT',
    [PhotoReportTypeEnum.LawViolation]: 'PHOTO_TOOL__REPORT_TYPE__LAW_VIOLATION',
    [PhotoReportTypeEnum.PrivacyViolation]: 'PHOTO_TOOL__REPORT_TYPE__PRIVACY_VIOLATION',
    [PhotoReportTypeEnum.PoorQuality]: 'PHOTO_TOOL__REPORT_TYPE__POOR_QUALITY',
    [PhotoReportTypeEnum.LocationMatchingError]: 'PHOTO_TOOL__REPORT_TYPE__LOCATION_MATCHING_ERROR',
    [PhotoReportTypeEnum.OrganizationMatchingError]: 'PHOTO_TOOL__REPORT_TYPE__LOCATION_MATCHING_ERROR',
    [PhotoReportTypeEnum.Other]: 'PHOTO_TOOL__REPORT_TYPE__OTHER',
};
