import {LandingCard} from '../../../../../component/landing/landing-card/landing-card';
import {LandingPageCard} from '../../../../../component/landing/landing-page-card/landing-page-card';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../../provider/domain-config/domain-config-type';
import {usePhotoToolCatalogStats, usePhotoToolLastPhotos} from '../../../../../service/phototool/phototool-hook';
import photoToolLandingImageBw from '../images/photo-tool-landing-image-bw.png';
import photoToolLandingImageRd from '../images/photo-tool-landing-image-rd.png';

import {PhotoToolLandingStats} from './photo-tool-landing-stats/photo-tool-landing-stats';
import * as styles from './photo-tool-landing.scss';

type PropsType = {
    onTryFeatureClick: VoidFunction;
};

export function PhotoToolLanding(props: PropsType): JSX.Element {
    const {onTryFeatureClick} = props;

    const {domainName} = useDomainConfig();

    const {data, isLoading: isLoadingLastPhotos} = usePhotoToolLastPhotos();
    const {data: catalogStats, isLoading: isLoadingStats} = usePhotoToolCatalogStats();

    if (isLoadingLastPhotos || isLoadingStats) {
        return <Spinner />;
    }

    return (
        <LandingPageCard bodyClassName={styles.PhotoToolLanding}>
            {catalogStats && <PhotoToolLandingStats catalogStats={catalogStats} photos={data?.results || []} />}
            <LandingCard
                buttonLangKey="BUTTON__TRY_FOR_FREE"
                imageSrc={domainName === DomainNameEnum.brandWizard ? photoToolLandingImageBw : photoToolLandingImageRd}
                mainItem
                onClick={onTryFeatureClick}
                primaryDescriptionItems={['PHOTO_TOOL__LANDING_CALL_TO_ACTION__DESCRIPTION']}
                textFirst
                titleLangKey="PHOTO_TOOL__LANDING_CALL_TO_ACTION__TITLE"
            />
        </LandingPageCard>
    );
}
