import {ColumnsType} from 'antd/lib/table';

import {Text} from '../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {
    AccountBrandAlreadySelectedType as BrandType,
    AccountCompanyAlreadySelectedType as CompanyType,
} from '../../../../../../../../service/source-settings/accounts/accounts-type';

import {AccountEditErrorPayloadType} from './manage-account-form-type';

export function isAccountCompanyAlreadySelectedType(
    accountSelected: CompanyType | BrandType
): accountSelected is CompanyType {
    return 'company' in accountSelected;
}

export function getAccountAlreadySelectedColumns(
    isActiveBrandTab: boolean,
    getCatalogName: (catalogId: number) => string
): ColumnsType<BrandType | CompanyType> {
    return [
        {
            title: (
                <Locale
                    stringKey={
                        isActiveBrandTab
                            ? 'SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__BRAND'
                            : 'SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__COMPANY'
                    }
                />
            ),
            key: 'company',
            width: '180px',
            render: (_value, item: BrandType | CompanyType) => (
                <Text>{isAccountCompanyAlreadySelectedType(item) ? item.company.name : item.brand.name}</Text>
            ),
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__SOURCE" />,
            key: 'source',
            render: (_value, item: BrandType | CompanyType) => <Text>{getCatalogName(item.catalog.id)}</Text>,
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__NEW_ACCOUNTS__COMPANY__TABLE__COLUMN__ACCOUNT" />,
            key: 'account',
            render: (_value, item: BrandType | CompanyType) => <Text>{item.account.login}</Text>,
        },
    ];
}

export function isAccountEditError(errorPayload: unknown): errorPayload is AccountEditErrorPayloadType {
    return typeof (errorPayload as AccountEditErrorPayloadType)?.status === 'string';
}
