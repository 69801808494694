import {Locale} from '../../../../../../../../provider/locale/locale';
import {ReviewDataChildType} from '../../../../../../../../service/reviews/reviews-type';

import * as styles from './answer-author.scss';

type PropsType = {
    answer: ReviewDataChildType;
};

export function AnswerAuthor(props: PropsType): JSX.Element {
    const {answer} = props;
    const {isCompanyComment, author} = answer;

    return (
        <div className={styles.answer_author}>
            {isCompanyComment ? (
                <Locale stringKey="REVIEWS__REVIEW_ANSWER__AUTHOR__COMPANY" />
            ) : (
                <Locale stringKey="REVIEWS__REVIEW_ANSWER__AUTHOR__USER" />
            )}{' '}
            <strong className={styles.answer_author__author_name}>{author}</strong>
        </div>
    );
}
