import {Tooltip} from 'antd';

import {FormattedNumber} from '../../provider/locale/formatted-number';
import {useFormat} from '../../util/format-hook/format-hook';

type PropsType = {
    value: number;
    tooltipThreshold?: number;
};

export function FormattedNumberWithTooltip(props: PropsType): JSX.Element {
    const {value, tooltipThreshold = 1_000_000} = props;

    const {getFormattedNumber} = useFormat();

    return value > tooltipThreshold ? (
        <Tooltip title={getFormattedNumber(value)}>{getFormattedNumber(value, {notation: 'compact'})}</Tooltip>
    ) : (
        <FormattedNumber value={value} />
    );
}
