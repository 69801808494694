import {Button, Drawer, List, Radio} from 'antd';
import {useState} from 'react';

import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {localeNameToLanguageNameMap} from '../../../provider/locale/locale-context-const';
import {LocaleNameEnum} from '../../../provider/locale/locale-context-type';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {useUser} from '../../../provider/user/user-hook';

import * as styles from './mobile-language-selector.scss';

type PropsType = {
    open: boolean;
    onClose: () => void;
};

export function MobileLanguageSelector(props: PropsType): JSX.Element {
    const {open, onClose} = props;

    const {localeName} = useLocale();
    const {setLocaleNameByUser} = useUser();
    const [newLocale, setNewLocale] = useState(localeName);
    const {availableLocales} = useDomainConfig();

    function handleCancel() {
        setNewLocale(localeName);
        onClose();
    }

    function handleChange() {
        setLocaleNameByUser(newLocale);
        onClose();
    }

    return (
        <Drawer
            footer={
                <footer className={styles.footer}>
                    <Button onClick={handleCancel}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>
                    <Button onClick={handleChange} type="primary">
                        <Locale stringKey="TEXT__APPLY" />
                    </Button>
                </footer>
            }
            height="100%"
            onClose={onClose}
            open={open}
            placement="top"
            title={<Locale stringKey="LANGUAGE_SELECTOR__MOBILE__TITLE" />}
        >
            <Locale stringKey="LANGUAGE_SELECTOR__MOBILE__DESCRIPTION" />
            <Radio.Group
                className={styles.radio_group}
                onChange={(event) => setNewLocale(event.target.value as LocaleNameEnum)}
                value={newLocale}
            >
                <List
                    dataSource={availableLocales.map((locale) => [locale, localeNameToLanguageNameMap[locale]])}
                    renderItem={([locale, localeDisplayName]) => (
                        <List.Item className={styles.list_item}>
                            <Radio value={locale}>{localeDisplayName}</Radio>
                        </List.Item>
                    )}
                    split={false}
                />
            </Radio.Group>
        </Drawer>
    );
}
