import {useCallback, useEffect} from 'react';

import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {fetchIntraService, updateIntraService as fetchUpdateIntraService} from './intra-service-api';
import {IntraServiceType} from './intra-service-type';

type IntraServiceAdittionalType = {
    updateIntraService: (intraService: IntraServiceType) => void;
};

export function useIntraService(): UseHookType<IntraServiceType> & IntraServiceAdittionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<IntraServiceType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchIntraService()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    const updateIntraService = useCallback(
        (intraService: IntraServiceType) => {
            setIsInProgress(true);

            fetchUpdateIntraService(intraService)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setResult, setIsInProgress, setProcessError]
    );

    return {isInProgress, processError, result, reset, updateIntraService};
}
