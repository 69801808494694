import {Alert, Button, Space} from 'antd';
import {PublicationContext} from 'centrifuge';
import {ReactElement, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {z as r} from 'zod';

import {Text} from '../../../../../component/text/text';
import {useCentrifugeSubscription} from '../../../../../provider/centrifuge/centrifuge-hook';
import {LocalePlural} from '../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../provider/user/user-hook';
import {useBuyTariffLicense} from '../../../../../service/tariffs/tariffs-buy-license';
import {useLicensesDescription} from '../../../../../service/tariffs/tariffs-licenses-description';
import {LicensesCodeEnum} from '../../../tariffs-and-limits/licenses-block/license/license-const';
import {TariffModalWrapper} from '../../../tariffs-and-limits/tariffs-modals/tariffs-modal-wrapper/tariff-modal-wrapper';

import * as styles from './companies-import-centrifuge-hook.scss';

function getCentrifugeCompaniesImportNotificationChannelName(userId: string): string {
    return `companies_import:${userId}`;
}

const companiesCompaniesImportCentrifugeActionDataSchema = r.object({
    user_id: r.number(),
    companies_added: r.number(),
    companies_sent: r.number(),
    companies_not_sent_count: r.number(),
    companies_not_sent: r.array(r.string()),
});

type CompaniesCompaniesImportCentrifugeActionDataType = r.infer<
    typeof companiesCompaniesImportCentrifugeActionDataSchema
>;

export function CentrifugeCompaniesImportNotification(): ReactElement {
    const {user} = useUser();
    const {getSubscription} = useCentrifugeSubscription();
    const {pathname} = useLocation();
    const {snackbar} = useSnackbar();
    const {data: licensesDescription} = useLicensesDescription();
    const {mutate} = useBuyTariffLicense();

    const [isOpenLicenseModal, setIsOpenLicenseModal] = useState<boolean>(false);

    const licenseName = licensesDescription?.find(
        (license) => license.licenseCode === LicensesCodeEnum.CompanyLicence
    )?.name;

    function handleBuyLicense(message: string) {
        mutate(
            {message, licenseCode: LicensesCodeEnum.CompanyLicence},
            {
                onSuccess: () => {
                    setIsOpenLicenseModal(false);
                },
            }
        );
    }

    const onPublication = useCallback(
        (payload: CompaniesCompaniesImportCentrifugeActionDataType) => {
            function handleSnackbar() {
                return snackbar.success({
                    duration: null,
                    icon: false,
                    message: <Locale stringKey="FEED__IMPORT__ALERT__MESSAGE" />,
                    btn: (
                        <Button onClick={() => setIsOpenLicenseModal(true)} size="small" type="primary">
                            <Locale stringKey="COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__BUTTON" />
                        </Button>
                    ),
                    description: (
                        <Space align="end" direction="vertical">
                            <Alert
                                className={styles.Alert}
                                message={
                                    <LocalePlural
                                        count={payload.companies_sent}
                                        fewKey="FEED__IMPORT__ALERT__SUCCESS__FEW"
                                        manyKey="FEED__IMPORT__ALERT__SUCCESS__MANY"
                                        singularKey="FEED__IMPORT__ALERT__SUCCESS__SINGULAR"
                                        valueMap={{count: payload.companies_sent}}
                                    />
                                }
                                showIcon
                                type="success"
                            />
                            <Alert
                                className={styles.Alert}
                                message={
                                    <LocalePlural
                                        count={payload.companies_not_sent_count}
                                        manyKey="FEED__IMPORT__ALERT__ERROR__MANY"
                                        singularKey="FEED__IMPORT__ALERT__ERROR__SINGULAR"
                                        valueMap={{count: payload.companies_not_sent_count}}
                                    />
                                }
                                showIcon
                                type="error"
                            />
                        </Space>
                    ),
                });
            }

            if (payload && payload.user_id) {
                handleSnackbar();
            }
        },
        [snackbar]
    );

    useEffect(() => {
        function onActionReceived({data}: PublicationContext) {
            onPublication(data);
        }

        const subscription = getSubscription(getCentrifugeCompaniesImportNotificationChannelName(String(user?.id)));

        subscription?.on('publication', onActionReceived);

        return () => {
            subscription?.removeListener('publication', onActionReceived);
        };
    }, [getSubscription, onPublication, user, pathname]);

    return (
        <TariffModalWrapper
            buttonTitle="BUTTON__ACTIVATE"
            isOpen={isOpenLicenseModal}
            onOk={handleBuyLicense}
            setIsOpen={setIsOpenLicenseModal}
            title="TARIFFS__MODAL__TITLE__LIMITS"
        >
            <div className={styles.ImportCentrifugeModal_cardWrapper}>
                <Text bolder>{licenseName}</Text>
            </div>
        </TariffModalWrapper>
    );
}
