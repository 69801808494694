import {useEffect} from 'react';

import {useRefreshId} from '../../../util/hook';
import {PaginatedResponseType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType, UseRefreshApiHookType} from '../../api-hook/api-hook-type';

import {fetchAccountsList} from './accounts-api';
import {SourceAccountsRequestType, SourcesAccountType} from './accounts-type';

export function useSourcesAccounts(
    options: SourceAccountsRequestType
): UseHookType<PaginatedResponseType<SourcesAccountType>> & UseRefreshApiHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<SourcesAccountType>>();

    const {catalogId, filterKey, status, cursor, pageSize} = options;
    const {refreshId, refresh} = useRefreshId();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchAccountsList({catalogId, filterKey, status, cursor, pageSize})
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [catalogId, setIsInProgress, setProcessError, setResult, refreshId, filterKey, status, cursor, pageSize]);

    return {isInProgress, processError, result, reset, refreshId, refresh};
}
