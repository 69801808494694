import {ReactNode} from 'react';

import {LicenseType} from '../../../../provider/license/license-provider-type';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Text} from '../../../text/text';

import * as styles from './license-card-product.scss';

type PropsType = {
    icon: ReactNode;
    product: string;
    license: LicenseType;
};

export function LicenseCardProduct(props: PropsType): JSX.Element {
    const {icon, product, license} = props;

    const {getLocalizedString} = useLocale();

    const description = getLocalizedString(
        license?.isActive
            ? 'LICENSE_CARD__PRODUCT__DESCRIPTION_TRIAL'
            : 'LICENSE_CARD__PRODUCT__DESCRIPTION_NOT_CONNECTED'
    );

    return (
        <div className={styles.LicenseCardProduct}>
            <div className={styles.LicenseCardProduct_iconContainer}>{icon}</div>
            <div className={styles.LicenseCardProduct_textContainer}>
                <Text className={styles.LicenseCardProduct_title} large>
                    {product}
                </Text>
                <Text large secondary>
                    {description}
                </Text>
            </div>
        </div>
    );
}
