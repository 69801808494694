import dayjs from 'dayjs';
import {useCallback, useEffect, useMemo, useReducer} from 'react';

import {DatePeriodEnum, getIsoYyyyMmDdString} from '../../../util/date';
import {getEnumValueEnsure} from '../../../util/enum';
import {NeverError} from '../../../util/error';
import {useRefreshId} from '../../../util/hook';
import {useUrl} from '../../../util/url-hook/url-hook';

import {defaultFilterState} from './reviews-and-answers-statistics-const';
import {
    ReviewsAndAnswersCompareEnum,
    ReviewsAndAnswersFilterActionsEnum,
    ReviewsAndAnswersFilterDispatcherType,
    ReviewsAndAnswersFilterHookType,
    ReviewsAndAnswersFilterQueriesEnum,
    ReviewsAndAnswersFilterQueriesType,
    ReviewsAndAnswersFilterStateType,
    ReviewsAndAnswersFilterUpdateActionType,
} from './reviews-and-answers-statistics-filter-type';
import {
    getAnalyticsRangeByPeriodAndDate,
    getComparePeriod,
    getInitialPeriod,
} from './reviews-and-answers-statistics-helper';

export function useReviewsAndAnswersFilter(): ReviewsAndAnswersFilterHookType {
    const {queries: reviewsAndAnswersQueries, setQuery: setReviewsAndAnswersQueries} =
        useUrl<ReviewsAndAnswersFilterQueriesType>();

    const {refreshId, refresh} = useRefreshId();

    const lastAvailableAnalyticsDay = useMemo(() => {
        return dayjs().subtract(1).toDate();
    }, []);

    // eslint-disable-next-line sonarjs/cognitive-complexity,complexity,max-statements
    function reducer(
        state: ReviewsAndAnswersFilterStateType,
        action: ReviewsAndAnswersFilterUpdateActionType
    ): ReviewsAndAnswersFilterStateType {
        switch (action.type) {
            case ReviewsAndAnswersFilterActionsEnum.UpdatePeriod: {
                const updatedState = {...state, [ReviewsAndAnswersFilterQueriesEnum.Period]: action.payload};

                if (action.payload === DatePeriodEnum.Custom) {
                    return updatedState;
                }

                const newMainPeriod = getAnalyticsRangeByPeriodAndDate(lastAvailableAnalyticsDay, action.payload);

                return reducer(updatedState, {
                    type: ReviewsAndAnswersFilterActionsEnum.UpdateMainPeriod,
                    payload: {
                        [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: newMainPeriod.start,
                        [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: newMainPeriod.end,
                    },
                });
            }
            case ReviewsAndAnswersFilterActionsEnum.UpdateMainPeriod: {
                const newComparePeriod = getComparePeriod(
                    {
                        start: action.payload[ReviewsAndAnswersFilterQueriesEnum.PeriodStart],
                        end: action.payload[ReviewsAndAnswersFilterQueriesEnum.PeriodEnd],
                    },
                    state[ReviewsAndAnswersFilterQueriesEnum.Period],
                    state[ReviewsAndAnswersFilterQueriesEnum.CompareMode]
                );

                return {
                    ...state,
                    ...action.payload,
                    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: newComparePeriod.start,
                    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: newComparePeriod.end,
                };
            }
            case ReviewsAndAnswersFilterActionsEnum.UpdateComparePeriod: {
                return {
                    ...state,
                    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]:
                        action.payload[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart],
                    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]:
                        action.payload[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd],
                    ...(action.payload[ReviewsAndAnswersFilterQueriesEnum.CompareMode]
                        ? {
                              [ReviewsAndAnswersFilterQueriesEnum.CompareMode]:
                                  action.payload[ReviewsAndAnswersFilterQueriesEnum.CompareMode],
                          }
                        : {}),
                };
            }
            case ReviewsAndAnswersFilterActionsEnum.UpdateCompareMode: {
                const newComparePeriod = getComparePeriod(
                    {
                        start: state[ReviewsAndAnswersFilterQueriesEnum.PeriodStart],
                        end: state[ReviewsAndAnswersFilterQueriesEnum.PeriodEnd],
                    },
                    state[ReviewsAndAnswersFilterQueriesEnum.Period],
                    action.payload
                );

                return {
                    ...state,
                    [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: action.payload,
                    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: newComparePeriod.start,
                    [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: newComparePeriod.end,
                };
            }
            case ReviewsAndAnswersFilterActionsEnum.Init: {
                let result = state;

                const period = getInitialPeriod(reviewsAndAnswersQueries[ReviewsAndAnswersFilterQueriesEnum.Period]);

                result = reducer(result, {
                    type: ReviewsAndAnswersFilterActionsEnum.UpdatePeriod,
                    payload: period,
                });

                if (period === DatePeriodEnum.Custom) {
                    const periodStartRaw = reviewsAndAnswersQueries[ReviewsAndAnswersFilterQueriesEnum.PeriodStart];
                    const periodEndRaw = reviewsAndAnswersQueries[ReviewsAndAnswersFilterQueriesEnum.PeriodEnd];

                    result = reducer(result, {
                        type: ReviewsAndAnswersFilterActionsEnum.UpdateMainPeriod,
                        payload: {
                            [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: periodStartRaw
                                ? new Date(periodStartRaw)
                                : dayjs().subtract(29, 'day').toDate(),
                            [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: periodEndRaw
                                ? new Date(periodEndRaw)
                                : dayjs().toDate(),
                        },
                    });
                }

                const compareMode = getEnumValueEnsure<ReviewsAndAnswersCompareEnum | null>(
                    ReviewsAndAnswersCompareEnum,
                    reviewsAndAnswersQueries[ReviewsAndAnswersFilterQueriesEnum.CompareMode],
                    null
                );

                if (compareMode) {
                    result = reducer(result, {
                        type: ReviewsAndAnswersFilterActionsEnum.UpdateCompareMode,
                        payload: compareMode,
                    });
                } else {
                    const comparePeriodStartRaw =
                        reviewsAndAnswersQueries[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart];
                    const comparePeriodEndRaw =
                        reviewsAndAnswersQueries[ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd];

                    result = reducer(result, {
                        type: ReviewsAndAnswersFilterActionsEnum.UpdateComparePeriod,
                        payload: {
                            [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: comparePeriodStartRaw
                                ? new Date(comparePeriodStartRaw)
                                : null,
                            [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: comparePeriodEndRaw
                                ? new Date(comparePeriodEndRaw)
                                : null,
                        },
                    });
                }

                return result;
            }
            default:
                throw new NeverError(action);
        }
    }

    const [filter, dispatchFilter] = useReducer(
        reducer,
        reducer(defaultFilterState, {
            type: ReviewsAndAnswersFilterActionsEnum.Init,
        })
    );

    const refreshingDispatcher: ReviewsAndAnswersFilterDispatcherType = useCallback(
        (action: ReviewsAndAnswersFilterUpdateActionType) => {
            dispatchFilter(action);
            refresh();
        },
        [refresh]
    );

    const memoizedFilter = useMemo(() => {
        return filter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId]);

    useEffect(() => {
        const {
            [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: currentPeriodStart,
            [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: currentPeriodEnd,
            [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: currentComparePeriodStart,
            [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: currentComparePeriodEnd,
            [ReviewsAndAnswersFilterQueriesEnum.Period]: currentPeriod,
            [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: currentCompareMode,
        } = filter;

        setReviewsAndAnswersQueries({
            [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]:
                currentPeriod === DatePeriodEnum.Custom ? getIsoYyyyMmDdString(currentPeriodStart) : '',
            [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]:
                currentPeriod === DatePeriodEnum.Custom ? getIsoYyyyMmDdString(currentPeriodEnd) : '',
            [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]:
                currentCompareMode === ReviewsAndAnswersCompareEnum.Custom && currentComparePeriodStart
                    ? getIsoYyyyMmDdString(currentComparePeriodStart)
                    : '',
            [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]:
                currentCompareMode === ReviewsAndAnswersCompareEnum.Custom && currentComparePeriodEnd
                    ? getIsoYyyyMmDdString(currentComparePeriodEnd)
                    : '',
            [ReviewsAndAnswersFilterQueriesEnum.Period]: currentPeriod !== DatePeriodEnum.Month ? currentPeriod : '',
            [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: currentCompareMode || '',
        });
    }, [filter, setReviewsAndAnswersQueries]);

    return {
        filter: memoizedFilter,
        dispatchFilter: refreshingDispatcher,
        isCompareMode: Boolean(filter[ReviewsAndAnswersFilterQueriesEnum.CompareMode]),
        lastAvailableAnalyticsDay,
    };
}
