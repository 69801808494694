import {LatLngBounds, LatLngExpression, LeafletMouseEvent} from 'leaflet';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Marker, useMap} from 'react-leaflet';

import {
    CompanyLocationType,
    isCompanyLocationType,
} from '../../../../../../service/dashboard/dashboard-map/use-companies-locations-type';

import {clusterCompanies, getCompanyGroupIcon, getCompanyIcon} from './dashboard-companies-helper';

type DashboardMarkerType = CompanyLocationType | {center: LatLngExpression; count: number};

type PropsType = {
    setCompanyId: (id: number | null) => void;
    setShowPopup: (isShow: boolean) => void;
    companiesResult: Array<CompanyLocationType>;
};

export function DashboardCompaniesMarkers(props: PropsType): JSX.Element {
    const {setCompanyId, setShowPopup, companiesResult} = props;

    const map = useMap();

    const [zoom, setZoom] = useState<number>(map.getZoom());
    const [mapBounds, setMapBounds] = useState<LatLngBounds>(map.getBounds());

    const handleClickMarker = useCallback(
        (event: LeafletMouseEvent, id: number): void => {
            event.originalEvent.stopPropagation();
            setShowPopup(true);
            setCompanyId(id);
        },
        [setCompanyId, setShowPopup]
    );

    useEffect(() => {
        function onBoundsChanged() {
            setMapBounds(map.getBounds());
        }

        function onZoomChanged() {
            setZoom(map.getZoom());
        }

        map.on('moveend', onBoundsChanged);
        map.on('zoomend', onZoomChanged);

        return () => {
            map.off('moveend', onBoundsChanged);
            map.off('zoomend', onZoomChanged);
        };
    }, [map, setMapBounds]);

    const markersToRenderInfo: Array<
        | CompanyLocationType
        | {
              center: LatLngExpression;
              count: number;
          }
    > = useMemo(() => {
        const companiesInsideBounds = companiesResult.filter((company: CompanyLocationType) =>
            Boolean(company.lat && company.lon && mapBounds.contains([company.lat, company.lon]))
        );

        if (zoom >= 11) {
            return companiesInsideBounds;
        }

        return clusterCompanies(companiesInsideBounds, mapBounds);
    }, [companiesResult, mapBounds, zoom]);

    return (
        <>
            {markersToRenderInfo.map((markerInfo: DashboardMarkerType) => {
                if (isCompanyLocationType(markerInfo)) {
                    if (markerInfo.lat && markerInfo.lon) {
                        return (
                            <Marker
                                autoPan={false}
                                draggable={false}
                                eventHandlers={{
                                    click: (event) => handleClickMarker(event, markerInfo.id),
                                }}
                                icon={getCompanyIcon(markerInfo.rating)}
                                key={markerInfo.id}
                                position={[markerInfo.lat, markerInfo.lon]}
                            />
                        );
                    }

                    return null;
                }

                return (
                    <Marker
                        autoPan={false}
                        draggable={false}
                        icon={getCompanyGroupIcon(markerInfo.count)}
                        key={JSON.stringify(markerInfo.center)}
                        position={markerInfo.center}
                    />
                );
            })}
        </>
    );
}
