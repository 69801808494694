import {LegalAgreementTypeEnum} from '../../../layout/legal-agreement/legal-agreement-type';
import {RegisterFormKeyEnum, RegisterFormType} from '../../../service/user/user-register';

export const initialValues: RegisterFormType = {
    [RegisterFormKeyEnum.FirstName]: '',
    [RegisterFormKeyEnum.LastName]: '',
    [RegisterFormKeyEnum.Email]: '',
    [RegisterFormKeyEnum.Phone]: '',
    [RegisterFormKeyEnum.Country]: null,
    [RegisterFormKeyEnum.Password]: '',
    [RegisterFormKeyEnum.Agreement]: LegalAgreementTypeEnum.Personal,
    [RegisterFormKeyEnum.RulesConfirmation]: false,
};
