import {useQuery} from '@tanstack/react-query';
import {PropsWithChildren, useMemo} from 'react';

import {Page} from '../../layout/page/page';
import {LicensesCodeEnum} from '../../page/main/tariffs-and-limits/licenses-block/license/license-const';
import {fetchGptLicenses} from '../../service/neural-network/neural-network-api';
import {GptLicensesListResponseType} from '../../service/neural-network/neural-network-type';
import {useTariffs} from '../../service/tariffs/tariffs-user-tariffs';
import {FeaturesEnum} from '../../service/user/user-type';
import {useDomainConfig} from '../domain-config/domain-config-hook';
import {useUser} from '../user/user-hook';

import {fetchUserLicenses} from './license-api';
import {LicenseContext} from './license-context';
import {getUserLicensesUrl} from './license-provider-helper';
import {LicenseProviderType, LicenseType} from './license-provider-type';

export function LicenseProvider(props: PropsWithChildren<Record<string, unknown>>): JSX.Element {
    const {children} = props;
    const {user} = useUser();
    const {isFetching: isTariffsLoading} = useTariffs();
    const {isDomainFeatureEnabled} = useDomainConfig();

    const {
        data: licenses,
        refetch,
        isInitialLoading,
        isLoading,
    } = useQuery<Array<LicenseType>>(
        [getUserLicensesUrl(), user?.id],
        () => {
            if (user?.id) {
                return fetchUserLicenses();
            }

            return [];
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const {data: licensesGpt, isInitialLoading: isInitialLoadingGpt} = useQuery<GptLicensesListResponseType>(
        [user?.id, user?.isDoubleGisUser],
        () => {
            if (user?.id && user?.isDoubleGisUser) {
                return fetchGptLicenses();
            }

            return [];
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const providerValue: LicenseProviderType = useMemo(() => {
        const features = user?.features || [];
        const onlyAvailableSections = user?.userHasOnlyAccess || [];
        const localPackLicense = licenses?.find((license) => license.code === LicensesCodeEnum.LocalPackLicence);
        const onlinePresenceLicense = licenses?.find(
            (license) => license.code === LicensesCodeEnum.OnlinePresenceAnalyticsLicence
        );
        const photoToolLicense = licenses?.find((license) => license.code === LicensesCodeEnum.PhototoolLicence);
        const postsLicense = licenses?.find((license) => license.code === LicensesCodeEnum.PostsLicence);
        const neurallinkSuggestionLicense = licenses?.find(
            (license) => license.code === LicensesCodeEnum.GptReplySuggestionsLicence
        );

        const allFeatures = Object.values(FeaturesEnum) as Array<FeaturesEnum>;

        const availableFeatures = allFeatures.reduce((result, feature) => {
            const isFeatureAvailable =
                (onlyAvailableSections.length === 0 || onlyAvailableSections.includes(feature)) &&
                isDomainFeatureEnabled[feature];

            return {
                ...result,
                [feature]: isFeatureAvailable,
            };
        }, {} as Record<FeaturesEnum, boolean>);

        return {
            availableFeatures,
            licenses: {
                [FeaturesEnum.reviewAnalyticsBase]: {
                    isActive:
                        features.includes(FeaturesEnum.reviewAnalyticsBase) ||
                        features.includes(FeaturesEnum.reviewAnalyticsFull) ||
                        features.includes(FeaturesEnum.reviewAnalyticsCompetitor),
                },
                [FeaturesEnum.reviewAnalyticsFull]: {
                    isActive:
                        features.includes(FeaturesEnum.reviewAnalyticsFull) ||
                        features.includes(FeaturesEnum.reviewAnalyticsCompetitor),
                },
                [FeaturesEnum.reviewAnalyticsCompetitor]: {
                    isActive: features.includes(FeaturesEnum.reviewAnalyticsCompetitor),
                },
                [FeaturesEnum.duplicatesAndFakes]: {
                    isActive: features.includes(FeaturesEnum.duplicatesAndFakes),
                },
                [FeaturesEnum.reviewsGenerator]: {
                    isActive: features.includes(FeaturesEnum.reviewsGenerator),
                },
                [FeaturesEnum.reviewSuggests]: {
                    isActive: features.includes(FeaturesEnum.reviewSuggests),
                },
                [FeaturesEnum.storeLocator]: {
                    isActive: features.includes(FeaturesEnum.storeLocator),
                },
                [FeaturesEnum.dashboard]: {
                    isActive: features.includes(FeaturesEnum.dashboard),
                },
                [FeaturesEnum.mlReplies]: {
                    isActive: features.includes(FeaturesEnum.mlReplies),
                },
                [FeaturesEnum.companyGroups]: {
                    isActive: features.includes(FeaturesEnum.companyGroups),
                },
                [FeaturesEnum.usedeskIntegration]: {
                    isActive: features.includes(FeaturesEnum.usedeskIntegration),
                },
                [FeaturesEnum.prices]: {
                    isActive: features.includes(FeaturesEnum.prices),
                },
                [FeaturesEnum.analytics]: {
                    isActive: features.includes(FeaturesEnum.analytics),
                },
                [FeaturesEnum.tariffs]: {
                    isActive: features.includes(FeaturesEnum.tariffs),
                },
                [FeaturesEnum.userManagement]: {
                    isActive: features.includes(FeaturesEnum.userManagement),
                },
                [FeaturesEnum.omnichannel]: {
                    isActive: features.includes(FeaturesEnum.omnichannel),
                },
                [FeaturesEnum.publicApi]: {
                    isActive: features.includes(FeaturesEnum.publicApi),
                },
                [FeaturesEnum.reviewGptReplySuggestions]: neurallinkSuggestionLicense,
                [FeaturesEnum.mailingListLicence]: {
                    isActive: features.includes(FeaturesEnum.mailingListLicence),
                },
                [FeaturesEnum.companiesExport]: {
                    isActive: features.includes(FeaturesEnum.companiesExport),
                },
                [FeaturesEnum.knowledge]: {
                    isActive: features.includes(FeaturesEnum.knowledge),
                },
                [FeaturesEnum.localPack]: localPackLicense,
                [FeaturesEnum.photoTool]: photoToolLicense,
                [FeaturesEnum.onlinePresenceAnalytics]: onlinePresenceLicense,
                [FeaturesEnum.posts]: postsLicense,
                [FeaturesEnum.neuralNetwork]: {
                    isActive: user?.isDoubleGisUser ? Boolean(licensesGpt?.length) : false,
                },
            },
            refetch,
            isLoading,
        };
    }, [isLoading, licenses, refetch, user, licensesGpt]);

    if (isInitialLoading || isInitialLoadingGpt || isTariffsLoading) {
        return <Page.Loading titleLangKey="LOADING_THREE_POINTS" />;
    }

    return <LicenseContext.Provider value={providerValue}>{children}</LicenseContext.Provider>;
}
