import {TelegramNotificationsType} from '../../../../service/notifications-settings/notifications-settings-type';

export const telegramNotificationsFields: Record<keyof TelegramNotificationsType, keyof TelegramNotificationsType> = {
    send_messenger_review_responsible_user_notifications: 'send_messenger_review_responsible_user_notifications',
    send_messenger_notifications: 'send_messenger_notifications',
    messenger_token: 'messenger_token',
    messenger_notifications_time_bucket: 'messenger_notifications_time_bucket',
    messenger_notifications_catalogs: 'messenger_notifications_catalogs',
    messenger_notifications_ratings: 'messenger_notifications_ratings',
    messenger_notifications_content_type: 'messenger_notifications_content_type',
    send_messenger_omnichannel_notifications: 'send_messenger_omnichannel_notifications',
};
