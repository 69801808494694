import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {postAndDeserialize} from '../../util/api-adapter';

import {fakesUrl} from './fakes-const';
import {FakeClientStatusEnum, FakeInternalStatusEnum} from './fakes-type';

const fakesApproveActionSchema = r.object({
    clientStatus: r.nativeEnum(FakeClientStatusEnum),
    internalStatus: r.nativeEnum(FakeInternalStatusEnum),
});

type FakesApproveActionType = r.infer<typeof fakesApproveActionSchema>;

function postFakesApproveAction(id: number): Promise<FakesApproveActionType> {
    return postAndDeserialize(`${fakesUrl}/fake/${id}/approve_action/`, fakesApproveActionSchema);
}

export function useFakesApproveActionMutation(
    id: number,
    onSubmit: () => void
): UseMutationResult<FakesApproveActionType, unknown, number> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: () => postFakesApproveAction(id),
        onSuccess: () => {
            onSubmit();
            snackbar.success({
                description: <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__SUCCESS__MESSAGE" />,
                message: <Locale stringKey="FAKES__RESULTS__CHANGE__SUCCESS__TITLE" />,
            });
        },
        onError: () => {
            snackbar.error({
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            });
        },
    });
}
