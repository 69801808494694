import {Divider} from 'antd';

import {classNames} from '../../util/css';

import * as styles from './page-card-divider.scss';

type PropsType = {
    className?: string;
};

export function PageCardDivider(props: PropsType): JSX.Element {
    const {className} = props;
    const fullClassName = classNames(className, styles.page_card_divider);

    return <Divider className={fullClassName} />;
}
