import {formatAddress} from '../../../../../../service/address/address-helper';
import {ReviewCustomCompanyType} from '../../../../../../service/reviews/reviews-custom-compamies';

import * as styles from './company-select-option.scss';

type PropsType = {
    company: ReviewCustomCompanyType;
};

export function CompanySelectOption(props: PropsType): JSX.Element {
    const {company} = props;

    return (
        <>
            <div>{company.name}</div>

            <div className={styles.company_select__option_address}>{formatAddress(company.address)}</div>

            {company.code && <div className={styles.company_select__option_code}>{company.code}</div>}
        </>
    );
}
