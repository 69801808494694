import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';

export function useFooterText(): string {
    const {companyName} = useDomainConfig();

    const data = new Date();
    const fullYear = data.getFullYear();

    return `© ${companyName} ${fullYear}`;
}
