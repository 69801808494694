export function catchError(error: unknown): Error {
    console.warn('Catch a error in promise!');
    console.error(error);

    if (error instanceof Error) {
        return error;
    }

    return new Error('[error of Unknown Type]' + error);
}
