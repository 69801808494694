import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, List} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../provider/user/user-hook';
import {FeedResultsTypeEnum} from '../results/feed-results-const';

import * as styles from './template-links.scss';

type PropsType = {
    type: FeedResultsTypeEnum;
};

export function TemplateLinks(props: PropsType): JSX.Element {
    const {type} = props;
    const {feedTemplateLinks} = useDomainConfig();

    const documentationLinks = useDocumentationLinks();
    const {user} = useUser();

    const documentationLink = user
        ? type === FeedResultsTypeEnum.Validation
            ? documentationLinks.company.feedImportAndValidation
            : documentationLinks.company.feedImport
        : null;

    return (
        <>
            <List
                bordered
                className={styles.TemplateLinks}
                header={
                    <List.Item className={styles.TemplateLinks_header}>
                        <Text bolder stringKey="FEED__TEMPLATE_LINKS" />
                    </List.Item>
                }
            >
                {feedTemplateLinks.map(({name, link}) => (
                    <List.Item className={styles.TemplateLinks_item} key={name}>
                        <Text lighter>{name}</Text>

                        <a download href={link}>
                            <Button icon={<FontAwesomeIcon icon={faDownload} />} type="text" />
                        </a>
                    </List.Item>
                ))}
            </List>

            {documentationLink && (
                <Text
                    stringKey="FEED__TEMPLATE_LINKS__HELP"
                    valueMap={{
                        link: (
                            <a href={documentationLink} rel="noreferrer" target="_blank">
                                <Locale stringKey="FEED__TEMPLATE_LINKS__HELP_LINK" />
                            </a>
                        ),
                    }}
                />
            )}
        </>
    );
}
