import {Form} from 'antd';
import {NamePath} from 'antd/lib/form/interface';
import {ValidatorRule} from 'rc-field-form/lib/interface';
import {useCallback, useState} from 'react';

import {Locale} from '../../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {PostFormItemPropsType} from '../../../../../post-form-type';

import {VideoInput} from './video-input';

type PropsType = {
    fieldName: Array<string | number>;
    hideLabel?: boolean;
    uploadButtonClassName?: string;
    onUploadingStatusChanged?: (isUploading: boolean) => void;
    extraRules?: Array<ValidatorRule>;
    dependencies?: Array<NamePath>;
} & PostFormItemPropsType;

export function VideoItem(props: PropsType): JSX.Element {
    const {
        fieldName,
        hideLabel,
        uploadButtonClassName,
        onUploadingStatusChanged,
        dependencies = [],
        extraRules = [],
        disabled,
    } = props;
    const {getLocalizedString} = useLocale();
    const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);

    const handleUploadStatusChange = useCallback(
        (isUploading: boolean) => {
            setIsUploadingVideo((previousValue) => {
                if (previousValue) {
                    onUploadingStatusChanged?.(isUploading);
                }

                return isUploading;
            });
        },
        [onUploadingStatusChanged]
    );

    return (
        <Form.Item
            dependencies={dependencies}
            initialValue={null}
            label={hideLabel ? null : <Locale stringKey="POSTS_FORM__VIDEO" />}
            name={fieldName}
            rules={[
                {
                    validator: () => {
                        if (isUploadingVideo) {
                            return Promise.reject(new Error(getLocalizedString('POSTS_FORM__MEDIA_IN_PROGRESS_ERROR')));
                        }

                        return Promise.resolve();
                    },
                },
                ...extraRules,
            ]}
            valuePropName="video"
        >
            <VideoInput
                disabled={disabled}
                isUploading={isUploadingVideo}
                setIsUploading={handleUploadStatusChange}
                uploadButtonClassName={uploadButtonClassName}
            />
        </Form.Item>
    );
}
