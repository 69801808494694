import {useState} from 'react';

import {appRoute} from '../../../../app-route';
import {PageHeaderWithLicenseTag} from '../../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useLocale} from '../../../../provider/locale/locale-hook';

import {LocalPackLanding} from './landing/local-pack-landing';
import {LocalPackActivationSteps} from './local-pack-activation-steps/local-pack-activation-steps';

export function LocalPackActivation(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const [showActivationForm, setShowActivationForm] = useState<boolean>(false);
    const [isLicenseActivated, setIsLicenseActivated] = useState<boolean>(false);

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK_PAGE__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.localPack.path,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                    ]}
                />
                <PageHeaderWithLicenseTag
                    headerLangKey="LOCAL_PACK_PAGE__SUB_TITLE"
                    licenseActive={isLicenseActivated}
                    trialLicense
                />
            </PageCard>

            {!showActivationForm && <LocalPackLanding onTryFeatureClick={() => setShowActivationForm(true)} />}
            {showActivationForm && <LocalPackActivationSteps onLicenseActivated={() => setIsLicenseActivated(true)} />}
        </Page>
    );
}
