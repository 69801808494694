import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {Page} from '../../../../../layout/page/page';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useBrand} from '../../../../../provider/brands/brand-hook';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useCompany, useLastCompany} from '../../../../../service/company-v2/company-hook';
import {CompanyServerType} from '../../../../../service/company-v2/company-type';
import {urlQueryMap} from '../../../../../shared-search-parameters';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {CompanyForm} from '../company-form';

import {BREADCRUMBS_ITEMS, initialCompanyValues} from './create-company-form-const';

export function CreateCompanyForm(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {createCompany, isInProgress, processError: serverError} = useCompany(null);
    const {getQuery, pushUrl, pushState} = useUrl();
    const {snackbar} = useSnackbar();
    const brandId: number | null = Number(getQuery(urlQueryMap.brandId)) || null;

    const {isInProgress: isLastCompanyInProgress, result: lastCompany} = useLastCompany(brandId);

    const {brand: defaultBrand, isLoading: isBrandsInProgress} = useBrand(brandId);

    if (isLastCompanyInProgress || isBrandsInProgress) {
        return (
            <Page>
                <Spinner />
            </Page>
        );
    }

    async function handleCreateCompany(values: CompanyServerType) {
        const result = await createCompany(values);

        if (!result) {
            return;
        }

        snackbar.success({
            message: <Locale stringKey="COMPANY_FORM__SNACKBAR__CREATED__HEADER" />,
            description: (
                <Locale
                    stringKey="COMPANY_FORM__SNACKBAR__CREATED__BODY"
                    valueMap={{
                        title: values?.names[0]?.name ?? '',
                    }}
                />
            ),
        });
        track(AnalyticsTarget.MyCompanies.Create, brandId ? 'Old' : 'New');

        if ('id' in result) {
            const editCompanyRoute =
                defaultBrand?.companyLicenceBalance === 0
                    ? {
                          isLicenceExpired: true,
                      }
                    : {};

            await pushState(
                generatePath(appRoute.editCompany.path, {companyId: result.id as number}),
                editCompanyRoute
            );
        } else {
            await pushUrl(appRoute.myCompanies.path);
        }
    }

    return (
        <CompanyForm
            breadcrumbsList={BREADCRUMBS_ITEMS}
            initialValues={{
                ...initialCompanyValues,
                ...lastCompany,
                brandId,
                brandName: defaultBrand?.name ?? '',
            }}
            meta={getLocalizedString('COMPANY_FORM__CREATING')}
            onFinish={(values) => handleCreateCompany(values)}
            pageTitle={getLocalizedString('COMPANY_FORM__CREATING')}
            serverError={serverError}
            submitButtonText={getLocalizedString('COMPANY_FORM__CREATE')}
            submitDisabled={isInProgress}
        />
    );
}
