import {faPaperclip} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {useCallback, useMemo, useState} from 'react';

import {FileFormInput} from '../../../../../../../component/form/file-upload-item/file-form-input';
import {getTechnicalSupportFileUploadValidator} from '../../../../../../../component/form/file-upload-item/file-upload-helper';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {
    useDeleteUploadedFile,
    useUploadTicketFile,
} from '../../../../../../../service/technical-support/technical-support-hook';
import {
    CreateTicketFieldsEnum,
    CreateTicketFormType,
    TechnicalSupportNewMessageFormType,
    TechnicalSupportNewMessageType,
} from '../../../../../../../service/technical-support/technical-support-type';

import * as styles from './technical-support-conversation.scss';

type PropsType = {
    isCreatingMessage: boolean;
    createNewMessage: (data: TechnicalSupportNewMessageType) => Promise<void>;
};

export function TechnicalSupportNewMessageForm(props: PropsType): JSX.Element {
    const {createNewMessage, isCreatingMessage} = props;
    const [form] = Form.useForm<TechnicalSupportNewMessageFormType>();

    const [isShowCreateMessageForm, setIsShowCreateMessageForm] = useState<boolean>(false);
    const {getLocalizedString} = useLocale();

    const {requiredFieldRule, maxLengthFieldRule} = useFormRules();
    const {mutateAsync: uploadFile} = useUploadTicketFile();
    const {mutateAsync: removeFile} = useDeleteUploadedFile();

    const filesValidator = useMemo(() => {
        return getTechnicalSupportFileUploadValidator(getLocalizedString);
    }, [getLocalizedString]);

    const handleCancelCreateMessage = useCallback(() => {
        form.resetFields();
        setIsShowCreateMessageForm(false);
    }, [form]);

    const handleSubmit = useCallback(
        async (data: TechnicalSupportNewMessageFormType) => {
            await createNewMessage({
                ...data,
                files: Object.values(data.files),
            });
            form.resetFields();
        },
        [createNewMessage, form]
    );

    return (
        <div className={styles.TechnicalSupportConversation_controls}>
            {!isShowCreateMessageForm && (
                <Button onClick={() => setIsShowCreateMessageForm(true)}>
                    <Locale stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__NEW_COMMENT" />
                </Button>
            )}
            {isShowCreateMessageForm && (
                <Form<TechnicalSupportNewMessageFormType>
                    className={styles.TechnicalSupportConversation_newMessageForm}
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Form.Item name="text" rules={[requiredFieldRule, maxLengthFieldRule(8000)]}>
                        <TextArea
                            placeholder={getLocalizedString('HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ENTER_MESSAGE')}
                        />
                    </Form.Item>
                    <Form.Item<CreateTicketFormType> name={CreateTicketFieldsEnum.files} rules={[filesValidator]}>
                        <FileFormInput remove={removeFile} upload={uploadFile}>
                            <AdditionalInfo
                                leftNode={
                                    <Button
                                        className={styles.TechnicalSupportConversation_attachFileButton}
                                        icon={<FontAwesomeIcon icon={faPaperclip} />}
                                        type="link"
                                    >
                                        <Locale stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACH_FILES" />
                                    </Button>
                                }
                            >
                                <Locale stringKey="HELP_TECHNICAL_SUPPORT__CREATE_MODAL__FORM__FILES__HELPER" />
                            </AdditionalInfo>
                        </FileFormInput>
                    </Form.Item>

                    <div className={styles.TechnicalSupportConversation_newMessageButtonsContainer}>
                        <Button
                            disabled={isCreatingMessage}
                            htmlType="submit"
                            loading={isCreatingMessage}
                            type="primary"
                        >
                            <Locale stringKey="BUTTON__SAVE" />
                        </Button>
                        <Button onClick={handleCancelCreateMessage}>
                            <Locale stringKey="BUTTON__CANCEL" />
                        </Button>
                    </div>
                </Form>
            )}
        </div>
    );
}
