import {PropsWithChildren, useMemo, useState} from 'react';

import {Page} from '../../layout/page/page';
import {OnboardingStepEnum} from '../../page/main/onboarding/onboarding-const';

import {OnboardingContext} from './onboarding-context';
import {calculateStepCompletionPercent} from './onboarding-helper';
import {ConfirmationStatusEnum, ImportStatusEnum, SyncCompaniesStatusEnum, useOnboarding} from './onboarding-hook';
import {OnboardingStepDataType} from './onboarding-type';

export function OnboardingProvider(props: PropsWithChildren<Record<string, unknown>>): JSX.Element {
    const {children} = props;

    const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
    const [isDemoOnboarding, setIsDemoOnboarding] = useState<boolean>(false);
    const [demoOnboardingStep, setDemoOnboardingStep] = useState<OnboardingStepEnum>(OnboardingStepEnum.first);

    const {data: onboardingData, isLoading, refetch} = useOnboarding(setShowOnboarding, isDemoOnboarding);

    const [openAutoSyncPopup, setOpenAutoSyncPopup] = useState<boolean>(false);

    const isSyncCompaniesStatus =
        onboardingData?.syncCompaniesStep?.syncCompanies?.google?.status === SyncCompaniesStatusEnum.InProgress ||
        onboardingData?.syncCompaniesStep?.syncCompanies?.facebook?.status === SyncCompaniesStatusEnum.InProgress;

    const onboardingStepValue: Array<OnboardingStepDataType> = useMemo(
        () => [
            {
                title: 'ONBOARDING__FIRST_STEP__TITLE',
                isOpen: isDemoOnboarding
                    ? demoOnboardingStep === OnboardingStepEnum.first
                    : onboardingData?.addCompaniesStep?.passed === false,
                active: onboardingData?.addCompaniesStep.active,
                stepIndex: OnboardingStepEnum.first,
                status: onboardingData?.addCompaniesStep.importStatus,
            },
            {
                title: 'ONBOARDING__SECOND_STEP__TITLE',
                isOpen: isDemoOnboarding
                    ? demoOnboardingStep === OnboardingStepEnum.second
                    : onboardingData?.accountsConnectionStep?.passed === false,
                active: onboardingData?.accountsConnectionStep?.active,
                stepIndex: OnboardingStepEnum.second,
                stepData: onboardingData?.accountsConnectionStep?.accountsConnection,
            },
            {
                stepIndex: OnboardingStepEnum.third,
                title: 'ONBOARDING__THIRD_STEP__TITLE',
                isOpen: isDemoOnboarding
                    ? demoOnboardingStep === OnboardingStepEnum.third
                    : onboardingData?.syncCompaniesStep?.passed === false,
                active: onboardingData?.syncCompaniesStep?.active,
                stepData: onboardingData?.syncCompaniesStep?.syncCompanies,
                // eslint-disable-next-line no-undefined
                status: isSyncCompaniesStatus ? ImportStatusEnum.InProgress : undefined,
            },
            {
                stepIndex: OnboardingStepEnum.fourth,
                title: 'ONBOARDING__FOURTH_STEP__TITLE',
                isOpen: isDemoOnboarding
                    ? demoOnboardingStep === OnboardingStepEnum.fourth
                    : onboardingData?.confirmCompaniesStep?.passed === false,
                active: onboardingData?.confirmCompaniesStep?.active,
                stepData: onboardingData?.confirmCompaniesStep?.confirmCompanies,
            },
        ],
        [
            demoOnboardingStep,
            isDemoOnboarding,
            isSyncCompaniesStatus,
            onboardingData?.accountsConnectionStep?.accountsConnection,
            onboardingData?.accountsConnectionStep?.active,
            onboardingData?.accountsConnectionStep?.passed,
            onboardingData?.addCompaniesStep.active,
            onboardingData?.addCompaniesStep.importStatus,
            onboardingData?.addCompaniesStep?.passed,
            onboardingData?.confirmCompaniesStep?.active,
            onboardingData?.confirmCompaniesStep?.confirmCompanies,
            onboardingData?.confirmCompaniesStep?.passed,
            onboardingData?.syncCompaniesStep?.active,
            onboardingData?.syncCompaniesStep?.passed,
            onboardingData?.syncCompaniesStep?.syncCompanies,
        ]
    );

    const hasWarningStatus = Object.values(onboardingData?.confirmCompaniesStep?.confirmCompanies || {})
        .map((mappedData) => mappedData.status)
        .includes(ConfirmationStatusEnum.NeedConfirmation);

    const stepCompletionPercents = onboardingStepValue.map((step) => {
        switch (step.stepIndex) {
            case OnboardingStepEnum.second:
            case OnboardingStepEnum.third:
            case OnboardingStepEnum.fourth:
                return calculateStepCompletionPercent(step.stepData);
            default:
                return step.isOpen ? 0 : 100;
        }
    });

    const totalSteps = onboardingStepValue.length;
    const isOnboardingCompleted = onboardingStepValue.every((step) => !step.isOpen);

    const onboardingPercent = isDemoOnboarding
        ? 0
        : isOnboardingCompleted
        ? 100
        : stepCompletionPercents.reduce((accumulator, percent) => accumulator + percent, 0) / totalSteps;

    const providerValue = useMemo(() => {
        return {
            onboardingStepValue,
            openAutoSyncPopup,
            setOpenAutoSyncPopup,
            openStep: onboardingStepValue.find((step) => step.isOpen)?.stepIndex || OnboardingStepEnum.first,
            onboardingPercent,
            showOnboarding,
            isOnboardingCompleted,
            onboardingData,
            hasWarningStatus,
            refetch,
            setIsDemoOnboarding,
            isDemoOnboarding,
            demoOnboardingStep,
            setDemoOnboardingStep,
        };
    }, [
        onboardingStepValue,
        openAutoSyncPopup,
        onboardingPercent,
        showOnboarding,
        isOnboardingCompleted,
        hasWarningStatus,
        onboardingData,
        isDemoOnboarding,
        setIsDemoOnboarding,
        demoOnboardingStep,
        setDemoOnboardingStep,
    ]);

    if (isLoading) {
        return <Page.Loading titleLangKey="LOADING_THREE_POINTS" />;
    }

    return <OnboardingContext.Provider value={providerValue}>{children}</OnboardingContext.Provider>;
}
