import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/localization';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {Prices} from './prices';

type PropsType = {
    companyId: string | number;
    isDashboardWidget: boolean;
};

export function SingleCompanyPrices(props: PropsType): JSX.Element {
    const {companyId, isDashboardWidget} = props;

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_HEADER" />}
                    widget={isDashboardWidget ? DashboardWidgetEnum.PricesSingle : null}
                />
            }
        >
            <Prices companyId={Number(companyId)} />
        </PageCard>
    );
}
