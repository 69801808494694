import {Spinner} from '../../../../../../../../../layout/spinner/spinner';
import {useCatalogPreviewData} from '../../../../../../../../../service/posts/posts-hooks';
import {
    PostPreviewOptionsType,
    PostSourceEnum,
    SourcePostType,
} from '../../../../../../../../../service/posts/posts-types';
import {FacebookPreview} from '../facebook-preview/facebook-preview';
import {GooglePreview} from '../google-preview/google-preview';
import {InstagramPreview} from '../instagram-preview/instagram-preview';
import {VkontaktePreview} from '../vkontakte-preview/vkontakte-preview';
import {YandexPreview} from '../yandex-preview/yandex-preview';

import * as styles from './catalog-post-preview.scss';

type PropsType = {
    catalog: PostSourceEnum;
    postData?: SourcePostType;
    sources?: PostPreviewOptionsType;
};

export function CatalogPostPreview(props: PropsType): JSX.Element | null {
    const {catalog, postData, sources} = props;

    const {data, isLoading} = useCatalogPreviewData(catalog, sources || {});

    if (isLoading) {
        return <Spinner className={styles.CatalogPostPreview} position="absolute" />;
    }

    switch (catalog) {
        case PostSourceEnum.yandex: {
            return <YandexPreview companyInfo={data} postData={postData} />;
        }
        case PostSourceEnum.instagram: {
            return <InstagramPreview companyInfo={data} postData={postData} />;
        }
        case PostSourceEnum.facebook: {
            return <FacebookPreview companyInfo={data} postData={postData} />;
        }
        case PostSourceEnum.google: {
            return <GooglePreview companyInfo={data} postData={postData} />;
        }
        case PostSourceEnum.vkontakte: {
            return <VkontaktePreview companyInfo={data} postData={postData} />;
        }
        default: {
            return null;
        }
    }
}
