import {PresetStatusColorType} from 'antd/lib/_util/colors';

import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {
    ConfirmationErrorMessageEnum,
    ConfirmationSourcesStatusEnum,
} from '../../../../../service/confirmation/confirmation-type';

export const confirmationItemStatusTranslationMap: Record<ConfirmationSourcesStatusEnum, LangKeyType> = {
    [ConfirmationSourcesStatusEnum.Created]: 'SOURCES_CONFIRMATION__STATUS__CREATED',
    [ConfirmationSourcesStatusEnum.InProgress]: 'SOURCES_CONFIRMATION__STATUS__IN_PROGRESS',
    [ConfirmationSourcesStatusEnum.Sent]: 'SOURCES_CONFIRMATION__STATUS__SENT',
    [ConfirmationSourcesStatusEnum.ErrorOnSending]: 'SOURCES_CONFIRMATION__STATUS__ERROR_ON_SENDING',
    [ConfirmationSourcesStatusEnum.Received]: 'SOURCES_CONFIRMATION__STATUS__RECEIVED',
    [ConfirmationSourcesStatusEnum.ErrorOnConfirm]: 'SOURCES_CONFIRMATION__STATUS__ERROR_ON_CONFIRM',
    [ConfirmationSourcesStatusEnum.Confirm]: 'SOURCES_CONFIRMATION__STATUS__CONFIRM',
    [ConfirmationSourcesStatusEnum.RequestRightsSent]: 'SOURCES_CONFIRMATION__STATUS__REQUEST_RIGHTS_SENT',
    [ConfirmationSourcesStatusEnum.RequestRightsExpired]: 'SOURCES_CONFIRMATION__STATUS__REQUEST_RIGHTS_EXPIRED',
};

export const confirmationErrorMessageTranslationMap: Record<ConfirmationErrorMessageEnum, LangKeyType> = {
    [ConfirmationErrorMessageEnum.IsNotACompanyPhone]: 'SOURCES_CONFIRMATION__ERROR_MESSAGE__IS_NOT_A_COMPANY_PHONE',
    [ConfirmationErrorMessageEnum.IsNotAChainPhone]: 'SOURCES_CONFIRMATION__ERROR_MESSAGE__IS_NOT_A_CHAIN_PHONE',
    [ConfirmationErrorMessageEnum.IsNotMobile]: 'SOURCES_CONFIRMATION__ERROR_MESSAGE__IS_NOT_MOBILE',
    [ConfirmationErrorMessageEnum.AttemptsLimitExceeded]:
        'SOURCES_CONFIRMATION__ERROR_MESSAGE__ATTEMPTS_LIMIT_EXCEEDED',
    [ConfirmationErrorMessageEnum.Common]: 'SOURCES_CONFIRMATION__ERROR_MESSAGE__COMMON',
};

export const statusBadgeStatusMap: Record<ConfirmationSourcesStatusEnum, PresetStatusColorType> = {
    [ConfirmationSourcesStatusEnum.Created]: 'warning',
    [ConfirmationSourcesStatusEnum.InProgress]: 'warning',
    [ConfirmationSourcesStatusEnum.Sent]: 'warning',
    [ConfirmationSourcesStatusEnum.ErrorOnSending]: 'error',
    [ConfirmationSourcesStatusEnum.Received]: 'success',
    [ConfirmationSourcesStatusEnum.ErrorOnConfirm]: 'error',
    [ConfirmationSourcesStatusEnum.Confirm]: 'default',
    [ConfirmationSourcesStatusEnum.RequestRightsSent]: 'warning',
    [ConfirmationSourcesStatusEnum.RequestRightsExpired]: 'error',
};
