import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';
import {ReviewsAnalysisRadarQueryType} from '../review-radar/review-radar-type';

import {fetchReviewRateCompetitors, fetchReviewRatePerCompany} from './review-rate-api';
import {
    ReviewRateCompetitorsResponseType,
    ReviewRatePerCompanyResponseType,
    ReviewsAnalysisRateQueryType,
} from './review-rate-type';

export function useReviewRatePerCompany(
    options: ReviewsAnalysisRateQueryType,
    mainFilterKey: string
): UseHookType<ReviewRatePerCompanyResponseType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewRatePerCompanyResponseType>();

    const {
        limit,
        brandIds: compareBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
    } = options;

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewRatePerCompany(
            {
                brandIds: compareBrandIds,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then((response: ReviewRatePerCompanyResponseType) => ({
                ...response,
                companies: response.companies.slice(0, limit),
            }))
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        compareBrandIds,
        mainFilterKey,
        setIsInProgress,
        setProcessError,
        limit,
        setResult,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
    ]);

    return {isInProgress, processError, result, reset};
}

export function useReviewRateCompetitors(
    options: ReviewsAnalysisRadarQueryType,
    mainFilterKey: string
): UseHookType<ReviewRateCompetitorsResponseType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewRateCompetitorsResponseType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewRateCompetitors(options, mainFilterKey)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult, options, mainFilterKey]);

    return {isInProgress, processError, result, reset};
}
