import * as styles from '../reviews-distribution.scss';

export enum ReviewSentimentRatingEnum {
    One = '1',
    Two = '2',
    Three = '3',
    Four = '4',
    Five = '5',
}

export const reviewRatingSentimentColor: Record<ReviewSentimentRatingEnum, string> = {
    [ReviewSentimentRatingEnum.One]: styles.reviews_rating_1_color,
    [ReviewSentimentRatingEnum.Two]: styles.reviews_rating_2_color,
    [ReviewSentimentRatingEnum.Three]: styles.reviews_rating_3_color,
    [ReviewSentimentRatingEnum.Four]: styles.reviews_rating_4_color,
    [ReviewSentimentRatingEnum.Five]: styles.reviews_rating_5_color,
};

export const minWidthToShowRatingSentimentLabel = 32;
