import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';

import {PostsTableRenderingType} from './post-table-type';

export const postsTableColumnList: TableColumnsType<PostsTableRenderingType> = [
    {
        title: <Locale stringKey="POSTS__TABLE__TITLE" />,
        dataIndex: 'title',
    },
    {
        title: <Locale stringKey="POSTS__TABLE__CATALOGS" />,
        dataIndex: 'catalogs',
    },
    {
        title: <Locale stringKey="POSTS__TABLE__DATE" />,
        dataIndex: 'date',
    },
    {
        title: <Locale stringKey="POSTS__TABLE__STATUS" />,
        dataIndex: 'status',
    },
    {
        title: <Locale stringKey="POSTS__TABLE__ACTIONS" />,
        dataIndex: 'actions',
        width: 100,
    },
];
