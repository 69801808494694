import {Checkbox, Form, Input, Select, Transfer} from 'antd';
import {Key, useCallback, useEffect, useRef, useState} from 'react';

import {VirtualTable} from '../../../../component/virtualized-table/virtualized-table';
import {BrandType} from '../../../../provider/brands/brand-type';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useAllCompanies} from '../../../../service/company/company-hook';
import {AllCompanyType} from '../../../../service/company/company-type';
import {CompanyGroupFieldsType, CompanyGroupType} from '../../../../service/company-group/company-group-type';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {classNames} from '../../../../util/css';

import {COMPANY_GROUP_TITLE_VALIDATION} from './company-group-form-const';
import {CompanyGroupFormFooter} from './company-group-form-footer';
import {companyToEditCompany} from './company-group-form-helper';
import {
    CompanyGroupFormPropsType,
    EditCompanyTransferType,
    TransferDirectionEnum,
    VirtualTableDirectionEnum,
} from './company-group-form-type';
import * as styles from './company-group-form.scss';

const {Option} = Select;

export function CompanyGroupForm(props: CompanyGroupFormPropsType): JSX.Element {
    const {className, companyGroup, companiesGroup, onChange} = props;
    const [form] = Form.useForm<CompanyGroupFieldsType>();
    const {maxLengthFieldRule, minLengthFieldRule, requiredFieldRule} = useFormRules();
    const totalTargetKeyList: Array<Key> = companyGroup.companies.map(String);
    const [targetKeyList, setTargetKeyList] = useState<Array<Key>>(totalTargetKeyList); // отфильтрованный
    const allCompaniesHook = useAllCompanies({featuresToCheck: FeaturesEnum.companyGroups});
    const [isCompaniesWithoutGroup, setIsCompaniesWithoutGroup] = useState<boolean>(false);
    const [leftSelectedBrandId, setLeftSelectedBrandId] = useState<string>('');
    const [rightSelectedBrandId, setRightSelectedBrandId] = useState<string>('');
    const {getLocalizedString} = useLocale();
    const allCompaniesFullList: Array<AllCompanyType> = allCompaniesHook.result || [];
    const editCompanyTransferList: Array<EditCompanyTransferType> = allCompaniesFullList.map(companyToEditCompany);
    const companyGroupList: Array<CompanyGroupType> = companiesGroup;
    const deltaTargetKeyList = useRef(0);

    const handleOnChangeForm = useCallback(() => {
        const companies = targetKeyList.map(Number);

        onChange({...form.getFieldsValue(), companies});
    }, [form, targetKeyList, onChange]);

    useEffect(() => {
        handleOnChangeForm();
    }, [handleOnChangeForm]);

    useEffect(() => {
        if (allCompaniesHook.result) {
            const targetKeyListFiltered = totalTargetKeyList.filter((id) =>
                editCompanyTransferList.some((company) => company.companyId === id)
            );

            setTargetKeyList(targetKeyListFiltered);
            deltaTargetKeyList.current = totalTargetKeyList.length - targetKeyListFiltered.length;
        }
    }, [allCompaniesHook.result]);

    const brandList: Array<BrandType> = allCompaniesFullList.reduce(
        (accum: Array<BrandType>, allCompany: AllCompanyType): Array<BrandType> => {
            const brandExistedInAccum = accum.find(
                (brandInAccum: BrandType): boolean => brandInAccum.id === allCompany.brand.id
            );

            if (!brandExistedInAccum) {
                accum.push(allCompany.brand);
            }

            return accum;
        },
        []
    );

    const renderSelect = useCallback(
        (setSelectedBrandId: (selectedBrandId: string) => void): JSX.Element => {
            return (
                <Select<string>
                    className={styles.edit_company_group__select}
                    defaultValue=""
                    filterOption={() => true}
                    onChange={setSelectedBrandId}
                >
                    <Option key="all-brands" value="">
                        <Locale stringKey="GROUP_OF_COMPANIES__GROUP_MANAGEMENT__ALL_BRANDS" />
                    </Option>

                    {brandList.map((brandInList: BrandType): JSX.Element => {
                        const {id, name} = brandInList;

                        return (
                            <Option key={id} value={String(id)}>
                                {name}
                            </Option>
                        );
                    })}
                </Select>
            );
        },
        [brandList]
    );

    return (
        <Form
            className={classNames(styles.edit_company_group, className)}
            form={form}
            initialValues={companyGroup}
            layout="vertical"
            onChange={handleOnChangeForm}
        >
            <Form.Item
                className={styles.edit_company_group__title_input}
                label={<Locale stringKey="GROUP_OF_COMPANIES__GROUP_MANAGEMENT__GROUP_NAME_LABEL" />}
                name="title"
                rules={[
                    requiredFieldRule,
                    minLengthFieldRule(COMPANY_GROUP_TITLE_VALIDATION.min),
                    maxLengthFieldRule(COMPANY_GROUP_TITLE_VALIDATION.max),
                ]}
            >
                <Input
                    placeholder={getLocalizedString('GROUP_OF_COMPANIES__GROUP_MANAGEMENT__GROUP_NAME_LABEL')}
                    size="large"
                />
            </Form.Item>

            <div className={styles.edit_company_group__transfer_header}>
                <div className={styles.edit_company_group__transfer_header_part}>
                    <Checkbox
                        defaultChecked={isCompaniesWithoutGroup}
                        onChange={() => setIsCompaniesWithoutGroup(!isCompaniesWithoutGroup)}
                    >
                        <Locale stringKey="GROUP_OF_COMPANIES__GROUP_MANAGEMENT__COMPANIES_WITHOUT_A_GROUP" />
                    </Checkbox>

                    {renderSelect(setLeftSelectedBrandId)}
                </div>

                <div className={styles.edit_company_group__transfer_header_part}>
                    <p className={styles.edit_company_group__companies_in_the_group}>
                        <Locale
                            stringKey="GROUP_OF_COMPANIES__GROUP_MANAGEMENT__COMPANIES_IN_THE_GROUP"
                            valueMap={{
                                count: (
                                    <span className={styles.fw_500}>
                                        {targetKeyList.length + deltaTargetKeyList.current}
                                    </span>
                                ),
                            }}
                        />
                    </p>

                    {renderSelect(setRightSelectedBrandId)}
                </div>
            </div>

            <Transfer<EditCompanyTransferType>
                className={styles.edit_company_group__transfer}
                dataSource={editCompanyTransferList}
                filterOption={(rawInputValue: string, item: EditCompanyTransferType): boolean => {
                    const name = item.name.trim().toLowerCase();
                    const address = item.address.trim().toLowerCase();
                    const inputValue = rawInputValue.trim().toLowerCase();

                    return name.includes(inputValue) || address.includes(inputValue);
                }}
                locale={{
                    searchPlaceholder: getLocalizedString('GROUP_OF_COMPANIES__GROUP_MANAGEMENT__NAME_OR_ADDRESS'),
                }}
                onChange={setTargetKeyList}
                showSearch
                targetKeys={targetKeyList}
            >
                {(data) => {
                    const onItemSelect: (key: string, isSelected: boolean) => void = data.onItemSelect;
                    const onItemSelectAll: (keyList: Array<string>, isSelected: boolean) => void = data.onItemSelectAll;
                    const selectedKeyList: Array<Key> = data.selectedKeys;
                    const direction = data.direction;
                    const selectedBrandId: string =
                        direction === TransferDirectionEnum.left ? leftSelectedBrandId : rightSelectedBrandId;

                    const returnedDirectionValue =
                        direction === TransferDirectionEnum.right
                            ? VirtualTableDirectionEnum.right
                            : VirtualTableDirectionEnum.left;

                    const dataSource: Array<EditCompanyTransferType> = data.filteredItems
                        // filter by brand
                        .filter((editCompanyTransfer: EditCompanyTransferType): boolean => {
                            return !selectedBrandId || editCompanyTransfer.brandId === selectedBrandId;
                        })
                        // filter by in/out of company group
                        .filter((editCompanyTransfer: EditCompanyTransferType): boolean => {
                            if (direction === TransferDirectionEnum.right || !isCompaniesWithoutGroup) {
                                return true;
                            }

                            const hasCompanyGroup: boolean = companyGroupList.some(
                                (companyGroupInList: CompanyGroupType): boolean => {
                                    return companyGroupInList.companies.includes(Number(editCompanyTransfer.companyId));
                                }
                            );

                            return !hasCompanyGroup;
                        });
                    const counterCompanyWithoutAccess =
                        deltaTargetKeyList.current + targetKeyList.length - dataSource.length;

                    return (
                        <VirtualTable<EditCompanyTransferType>
                            additionalFooter={
                                returnedDirectionValue === VirtualTableDirectionEnum.right &&
                                counterCompanyWithoutAccess > 0 && (
                                    <CompanyGroupFormFooter counterCompanyWithoutAccess={counterCompanyWithoutAccess} />
                                )
                            }
                            columns={[
                                {
                                    dataIndex: 'name',
                                    title: getLocalizedString('TABLE__HEADER__TITLE'),
                                },
                                {
                                    dataIndex: 'code',
                                    title: getLocalizedString('TABLE__HEADER__CODE'),
                                    className: styles.edit_company_group__column__code,
                                },
                                {
                                    dataIndex: 'address',
                                    title: getLocalizedString('TABLE__HEADER__ADDRESS'),
                                },
                            ]}
                            dataSource={dataSource}
                            loading={allCompaniesHook.isInProgress}
                            pagination={false}
                            rowSelection={{
                                onSelect: (editCompanyTransfer: EditCompanyTransferType, isSelected: boolean) => {
                                    onItemSelect(editCompanyTransfer.key, isSelected);
                                },
                                selectedRowKeys: selectedKeyList,
                                columnWidth: 47,
                                onSelectAll: (isSelected: boolean) =>
                                    onItemSelectAll(
                                        dataSource.map((item) => item.companyId),
                                        isSelected
                                    ),
                            }}
                            scroll={{y: 500}}
                            size="small"
                        />
                    );
                }}
            </Transfer>
        </Form>
    );
}
