import {LangKeyType} from '../../../../provider/locale/translation/type';
import {initialAggregation} from '../../../../service/online-presence/online-presence-const';
import {
    OnlinePresenceCompareV2Enum,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../service/online-presence/online-presence-type';
import {DatePeriodEnum} from '../../../../util/date';

export const onlinePresenceCompareModeTranslationMap: Record<OnlinePresenceCompareV2Enum, LangKeyType> = {
    [OnlinePresenceCompareV2Enum.PreviousPeriod]: 'ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__PREVIOUS_PERIOD',
    [OnlinePresenceCompareV2Enum.PreviousYear]: 'ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__PREVIOUS_YEAR',
    [OnlinePresenceCompareV2Enum.Custom]: 'ONLINE_PRESENCE_ANALYTICS__COMPARE_MODE__CUSTOM',
};

export const phrasesPeriodItems: Array<DatePeriodEnum> = [
    DatePeriodEnum.PreviousFullMonth,
    DatePeriodEnum.Quarter,
    DatePeriodEnum.Custom,
];

export const defaultFilterState: OnlinePresenceV2FilterStateType = {
    [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: new Date(),
    [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: new Date(),
    [OnlinePresenceV2FilterQueriesEnum.Aggregation]: initialAggregation,
    [OnlinePresenceV2FilterQueriesEnum.Period]: DatePeriodEnum.Month,
    [OnlinePresenceV2FilterQueriesEnum.CompareMode]: null,
    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: null,
    [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: null,
};
