import {Divider, Space} from 'antd';
import {useContext} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/localization';
import {AccountTag} from '../common/account-tag/account-tag';
import {ACCOUNT_ROLES} from '../google-account-sync-const';
import {GoogleAccountSyncContext} from '../google-account-sync-context';
import {useGoogleAccount} from '../google-account-sync-context-hook';

import {TipType} from './tips-type';
import * as styles from './tips.scss';

type PropsType = {
    tips?: Array<TipType>;
};

export function Tips(props: PropsType): JSX.Element {
    const {tips: tips} = props;

    const {tips: contextTips} = useContext(GoogleAccountSyncContext);
    const account = useGoogleAccount();
    const roleLangKey = account?.role ? ACCOUNT_ROLES[account.role] : null;

    return (
        <>
            {account && (
                <Space className={styles.Account} direction="vertical" size={2}>
                    <AccountTag account={account} />

                    <Text className={styles.Account_name} large>
                        {account.name}
                    </Text>

                    {roleLangKey && <Text lighter small stringKey={roleLangKey} />}

                    <Divider className={styles.Divider} />
                </Space>
            )}

            <Space direction="vertical" size="middle">
                {(tips || contextTips).map(({title, text, icon, link}) => (
                    <div key={title}>
                        <Text bolder className={styles.Tip_title} lighter>
                            {icon}

                            <Locale stringKey={title} />
                        </Text>

                        <Text className={styles.Tip_text} lighter small>
                            {text}
                        </Text>

                        {link && link.href && (
                            <a href={link.href} rel="noreferrer" target="_blank">
                                {link.text ?? <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__MORE" />}
                            </a>
                        )}
                    </div>
                ))}
            </Space>
        </>
    );
}
