import {faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert} from 'antd';
import {useEffect, useState} from 'react';

import {Text} from '../../../component/text/text';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {FakesFiltersType} from '../../../service/fakes/fakes-results';
import {useFakesStatuses} from '../../../service/fakes/fakes-statuses';
import {useUrl} from '../../../util/url-hook/url-hook';

import {FakesEmptyPage} from './empty-page/fakes-empty-page';
import {fakesPreviousResultsUrlQuery} from './fakes-page-const';
import {useFakesDefaultFilters} from './fakes-page-hook';
import {FakesFilters} from './filters/fakes-filters';
import {FakesHeader} from './header/fakes-header';
import {FakesTable} from './table/fakes-table';
import {BuyLicenseModal} from './trial-landing/buy-license-modal/buy-license-modal';
import {FakesTrialLanding} from './trial-landing/fakes-trial-landing';

// eslint-disable-next-line complexity
export function FakesPage(): JSX.Element {
    const {getQuery, replaceQuery} = useUrl();

    const [filters, setFilters] = useState<FakesFiltersType | null>(null);
    const [showPreviousResults, setShowPreviousResults] = useState(Boolean(getQuery(fakesPreviousResultsUrlQuery)));

    const {isInitialLoading, data} = useFakesStatuses();
    const defaultFilters = useFakesDefaultFilters();

    useEffect(() => {
        if (showPreviousResults) {
            replaceQuery({[fakesPreviousResultsUrlQuery]: showPreviousResults});
        }
    }, [showPreviousResults, replaceQuery]);

    useEffect(() => {
        if (filters === null && !data?.isTrial) {
            setFilters(defaultFilters);
        }
    }, [defaultFilters, filters, data?.newAndNeedAction, data?.isTrial]);

    const isNoDataToShow = !showPreviousResults && !data?.newAndNeedAction && !data?.isTrial;

    if (isInitialLoading || (isNoDataToShow && (!data?.newAndNeedAction || data?.isSearching))) {
        return <FakesEmptyPage setShowPreviousResults={setShowPreviousResults} />;
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <FakesHeader />

            {!data?.isTrial && <FakesFilters filters={filters} setFilters={setFilters} />}

            {Boolean(data?.hasSearchData) && data?.isTrial && (
                <Alert
                    closable
                    description={
                        <Text
                            stringKey="FAKES__SETTINGS__ALERT__TRIAL"
                            valueMap={{
                                link: <BuyLicenseModal buttonName="FAKES__SETTINGS__ALERT__TRIAL__LINK" isLinkType />,
                            }}
                        />
                    }
                    icon={<FontAwesomeIcon icon={faCircleInfo} />}
                    message={<Text large stringKey="FAKES__TRIAL_LANDING__ALERT__MESSAGE" />}
                    showIcon
                    type="info"
                />
            )}
            {(Boolean(data?.hasSearchData) || Number(data?.processed) > 0) && <FakesTable filters={filters} />}

            {data?.isTrial && data?.processed === 0 && <FakesTrialLanding />}
        </Page>
    );
}
