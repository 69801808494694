import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {faStar} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Tooltip} from 'antd';

import {Locale} from '../../../../../provider/locale/locale';
import {SearchResultItemType} from '../../../../../service/local-pack/local-pack-type';

import {PositionsTableDataType} from './positions-table-types';
import * as styles from './positions-table.scss';

function mapSearchResultToTableData(searchResult: SearchResultItemType, rowIndex: number): PositionsTableDataType {
    const {position, category, name, address, distance, rating, reviewsCount, mapsUrl, isAd} = searchResult;

    return {
        position: isAd ? (
            <Badge.Ribbon placement="start" text="Ads">
                <div className={styles.PositionTables_advertisement} />
            </Badge.Ribbon>
        ) : (
            position
        ),
        key: String(rowIndex),
        nameAddress: (
            <div className={styles.PositionTables_address}>
                <Tooltip placement="bottom" title={category}>
                    <span>{name}</span>
                </Tooltip>
                <span className={styles.PositionTables_addressValue}>{address}</span>
            </div>
        ),
        distance: (
            <Locale
                stringKey="LOCAL_PACK__DISTANCE"
                valueMap={{
                    distance: distance ? String(distance) : '0',
                }}
            />
        ),
        rating: rating ? (
            <div>
                <FontAwesomeIcon className={styles.PositionTables_ratingIcon} icon={faStar} />
                <span>{rating}</span>
            </div>
        ) : (
            ''
        ),
        reviewsCount,
        mapsUrl: (
            <a href={mapsUrl} rel="noreferrer" target="_blank">
                <FontAwesomeIcon className={styles.PositionTables_linkIcon} icon={faArrowUpRightFromSquare} />
            </a>
        ),
    };
}

export function getPositionsTableData(
    searchResults: Array<SearchResultItemType>,
    firstElement?: SearchResultItemType | null
): Array<PositionsTableDataType> {
    const tableData: Array<PositionsTableDataType> = searchResults.map((resultRow: SearchResultItemType, index) =>
        mapSearchResultToTableData(resultRow, index)
    );

    if (firstElement) {
        tableData.unshift(mapSearchResultToTableData(firstElement, tableData.length));
    }

    return tableData;
}
