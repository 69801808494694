import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useUser} from '../../../../../../service/user-management/user-management-user';
import {
    useCreateUsersMutation,
    UsersFormKeyEnum,
} from '../../../../../../service/user-management/user-management-users';
import {EmailsFormItem} from '../email-form-item/emails-form-item';
import {UsersForm} from '../users-form';

export function CreateUser(): JSX.Element {
    const {data: user} = useUser();
    const {mutate, error} = useCreateUsersMutation();

    const {getLocalizedString} = useLocale();

    const emailFormItem = (
        <EmailsFormItem
            formInputTitle="USERS__FORM__EMAILS_FORM__LABEL"
            hasInitialEmails={false}
            isRequired
            licenseCount={user?.licenceAvailable ?? 0}
            name={UsersFormKeyEnum.Emails}
        />
    );

    return (
        <UsersForm
            cancelButtonText={<Locale stringKey="BUTTON__CANCEL" />}
            emailFormItem={emailFormItem}
            emailsError={error}
            onFinish={(values, activeTab) => mutate({options: values, activeTab})}
            pageHeaderTitle={getLocalizedString('USERS__FORM__ADD_USERS')}
            submitButtonText={<Locale stringKey="USERS__FORM__SAVE_USERS" />}
        />
    );
}
