import {
    CatalogTypeEnum,
    ConfirmationsSourcesType,
    YandexConfirmationSuggestType,
} from '../../../../../service/confirmation/confirmation-type';
import {ProvidersEnum} from '../../../../../util/type';

import {GoogleGetCode} from './google-get-code';
import {YandexGetCode} from './yandex-get-code';

type PropsType = {
    confirmationData: ConfirmationsSourcesType;
    onCodeSent: () => void;
    suggest: YandexConfirmationSuggestType | null;
};

export function GetCode(props: PropsType): JSX.Element | null {
    const {confirmationData, onCodeSent, suggest} = props;
    const {catalog, cclBrand, permanentId, catalogType, cclCompany, phones, hash} = confirmationData;

    switch (catalog) {
        case ProvidersEnum.yandex: {
            if (catalogType === CatalogTypeEnum.Brand && suggest?.yandexChainSuggest.chains[0]) {
                return (
                    <YandexGetCode
                        brandId={cclBrand[0]}
                        catalogId={suggest.yandexChainSuggest.chains[0].permanentId}
                        catalogType={catalogType}
                        extraInformation={suggest.yandexChainSuggest.chains[0].extra}
                        onSubmitSuccess={onCodeSent}
                        phones={phones}
                    />
                );
            }

            return (
                <YandexGetCode
                    brandId={cclBrand[0]}
                    catalogId={Number(permanentId)}
                    companyId={cclCompany}
                    onSubmitSuccess={onCodeSent}
                    phones={phones}
                />
            );
        }
        case ProvidersEnum.googleMaps:
        case ProvidersEnum.google:
            return (
                <GoogleGetCode
                    brandId={cclBrand[0]}
                    catalogId={permanentId}
                    companyId={cclCompany}
                    hash={hash}
                    onSubmitSuccess={onCodeSent}
                    permanentId={permanentId}
                />
            );
        default:
            return null;
    }
}
