import {isBoolean, isNil} from 'lodash';

import {CompanyFormType, CompanyServerType, CompanyType} from '../../../../../../service/company-v2/company-type';

import {booleanAttributeFalse, booleanAttributeTrue} from './attribute-boolean/boolean-attribute-const';
import {
    AttributeType,
    AttributeTypeEnum,
    AttributeWithNonUnionValuesType,
    BooleanAttributeType,
    DoubleGisAttributeGetCompanyType,
    GoogleAttributeGetCompanyType,
    YandexAttributeGetCompanyType,
} from './attributes-type';

function isVisible(value: unknown) {
    return !isNil(value) && value !== '';
}

export function isAttributeValueChanged(value: Record<string, unknown>): boolean {
    const hasEnteredValue = isVisible(value.value) || isVisible(value.valueFrom) || isVisible(value.valueTo);
    const isSelected = Boolean(value.selected) && !value.unitValue; // filters out 'in units' attributes that have nothing entered

    return hasEnteredValue || isSelected;
}

function serializeGoogleAttributeValue(
    attributeValue: AttributeWithNonUnionValuesType['values'][0],
    featureType: AttributeTypeEnum
): string | boolean {
    if (
        (featureType === AttributeTypeEnum.GoogleBoolean || featureType === AttributeTypeEnum.DoubleGisBoolean) &&
        'selected' in attributeValue &&
        attributeValue.selected
    ) {
        return Boolean(Number(attributeValue.templateId)); // '0' => false, '1' => true
    }

    if (featureType === AttributeTypeEnum.GoogleUrl && 'value' in attributeValue && attributeValue.value) {
        return attributeValue.value;
    }

    return attributeValue.templateId.toString();
}

function serializeYandexAttributeValue(
    attributeValue: AttributeType['values'][0],
    featureType: AttributeTypeEnum
): AttributeType['values'][0] & {featureType: AttributeTypeEnum} {
    return {
        featureType,
        templateId: attributeValue.templateId,
        ...('value' in attributeValue && isVisible(attributeValue.value) ? {value: attributeValue.value} : {}),
        ...('valueFrom' in attributeValue && isVisible(attributeValue.valueFrom)
            ? {valueFrom: attributeValue.valueFrom}
            : {}),
        ...('valueTo' in attributeValue && isVisible(attributeValue.valueTo) ? {valueTo: attributeValue.valueTo} : {}),
    };
}

function fixSelectedGoogleAttributes(
    attributes?: Array<GoogleAttributeGetCompanyType>
): Array<GoogleAttributeGetCompanyType> | undefined {
    return attributes?.map((attribute) => {
        const isBooleanType = attribute.values.length === 1 && isBoolean(attribute.values[0]);

        return {
            ...attribute,
            values: isBooleanType
                ? attribute.values.map((value) => {
                      if (value === true) {
                          return booleanAttributeTrue;
                      }

                      if (value === false) {
                          return booleanAttributeFalse;
                      }

                      return value;
                  })
                : attribute.values,
        };
    });
}

function fixSelectedDoubleGisAttributes(
    attributes?: Array<DoubleGisAttributeGetCompanyType>
): Array<DoubleGisAttributeGetCompanyType> | undefined {
    return attributes?.map((attribute) => {
        return {
            ...attribute,
            values: attribute.values.map((value) => {
                if (value === true) {
                    return booleanAttributeTrue;
                }

                if (value === false) {
                    return booleanAttributeFalse;
                }

                return value;
            }),
        };
    });
}

function markSelectedYandexAttributes(
    attributes?: Array<YandexAttributeGetCompanyType>
): Array<YandexAttributeGetCompanyType> | undefined {
    const SELECTABLE_TYPES = new Set([
        AttributeTypeEnum.YandexInUnitsSingle,
        AttributeTypeEnum.YandexRangeInUnitsSingle,
        AttributeTypeEnum.YandexEnumSingle,
        AttributeTypeEnum.YandexEnumMultiple,
    ]);

    return attributes?.map((item) => ({
        ...item,
        values: item.values.map((value) => {
            if (SELECTABLE_TYPES.has(item.featureType)) {
                return {...value, selected: true};
            }

            return value;
        }),
    }));
}

export function convertAttributesToFormFormat(attributes?: CompanyType['attributes']): CompanyFormType['attributes'] {
    return {
        attributesGoogle: fixSelectedGoogleAttributes(attributes?.attributesGoogle) ?? [],
        attributesDoubleGis: fixSelectedDoubleGisAttributes(attributes?.attributesDoubleGis) ?? [],
        attributesYandex:
            markSelectedYandexAttributes(
                attributes?.attributesYandex.filter(({featureType}) =>
                    Object.values(AttributeTypeEnum).includes(featureType)
                )
            ) ?? [],
    };
}

export function convertAttributesToServerFormat(
    formAttributes?: CompanyFormType['attributes'],
    initialAttributes?: CompanyFormType['attributes']
): CompanyServerType['attributes'] {
    return {
        attributesGoogle:
            formAttributes?.attributesGoogle
                .flatMap((attribute) => ('items' in attribute ? attribute.items : []))
                .filter(({values}) => values.some(isAttributeValueChanged))
                .map(({internalName, featureType, values}: AttributeWithNonUnionValuesType) => ({
                    attributeId: internalName,
                    values: values
                        .filter(isAttributeValueChanged)
                        .map((attributeValue) => serializeGoogleAttributeValue(attributeValue, featureType)),
                })) ??
            initialAttributes?.attributesGoogle
                ?.flatMap((attribute) => ('items' in attribute ? attribute.items : []))
                .flatMap(({internalName, featureType, values}) => ({
                    attributeId: internalName,
                    values: values.map((attributeValue) => serializeGoogleAttributeValue(attributeValue, featureType)),
                })),
        attributesYandex:
            formAttributes?.attributesYandex
                .filter(({values}) => values.some(isAttributeValueChanged))
                .filter((attribute): attribute is AttributeType => 'displayName' in attribute)
                .flatMap(({featureType, values}: AttributeWithNonUnionValuesType) => {
                    return values
                        .filter(isAttributeValueChanged)
                        .map((changedValue) => serializeYandexAttributeValue(changedValue, featureType));
                }) ??
            initialAttributes?.attributesYandex?.flatMap(({featureType, values}) => {
                return values.map((changedValue) => serializeYandexAttributeValue(changedValue, featureType));
            }),
        attributesDoubleGis: formAttributes?.attributesDoubleGis
            .filter((attribute): attribute is BooleanAttributeType => 'displayName' in attribute)
            .filter((attribute) => attribute.values.some(isAttributeValueChanged))
            .map((attribute) => {
                return {
                    originId: attribute.internalName,
                    values: attribute.values
                        .filter(isAttributeValueChanged)
                        .map((attributeValue) =>
                            serializeGoogleAttributeValue(attributeValue, AttributeTypeEnum.DoubleGisBoolean)
                        ),
                };
            }),
    };
}
