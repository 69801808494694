import {deserializeV2} from '../../util/api-adapter';
import {createRaceFetchX, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';

import {rootApiUrl} from './api-const';
import {
    AddressType,
    CityV2Type,
    CompanyCatalogCategoryType,
    CompanyCategoryType,
    CountryV2Type,
    getCityV2Schema,
    getCountryV2Schema,
    getIdNameSchema,
    IdNameType,
    LegalOptionDataType,
    LegalOptionDataV2Type,
    PaginatedResponseType,
    TimezoneType,
} from './api-type';

export function fetchLegalOptionDataList(shortLocaleName: string): Promise<Array<LegalOptionDataType>> {
    return fetchX<Array<LegalOptionDataType>>(
        rootApiUrl + '/v1/companies/legal_types/?country_code=' + shortLocaleName
    );
}

export function fetchLegalOptionDataV2List(): Promise<Array<LegalOptionDataV2Type>> {
    return fetchX<Array<LegalOptionDataV2Type>>(rootApiUrl + '/v2/legal/config/');
}

export function fetchSearchCompanyCategoryList(
    searchQuery: string
): Promise<PaginatedResponseType<CompanyCategoryType>> {
    return fetchX<PaginatedResponseType<CompanyCategoryType>>(
        rootApiUrl + '/company/modules/companies/categories/?q=' + encodeURIComponent(searchQuery)
    );
}

export function fetchSearchCompanyCatalogCategoryList(
    searchQuery: string,
    catalogId: number
): Promise<Array<CompanyCatalogCategoryType>> {
    return fetchX<Array<CompanyCatalogCategoryType>>(
        `${rootApiUrl}/v2/catalog/${catalogId}/categories/suggest/?${objectToUrlParameters({query: searchQuery})}`
    );
}

const countryV2ListRaceFetchX = createRaceFetchX();

export async function fetchCountryV2List(searchQuery: string): Promise<PaginatedResponseType<CountryV2Type>> {
    const url = `${rootApiUrl}/v2/geo/countries/?name=${encodeURIComponent(searchQuery)}`;
    const response = await countryV2ListRaceFetchX<PaginatedResponseType<CountryV2Type>>(url);

    return deserializeV2<PaginatedResponseType<CountryV2Type>>(url, getCountryV2Schema(), response);
}

const regionListRaceFetchX = createRaceFetchX();

export async function fetchRegionList(
    searchQuery: string,
    countryId: number,
    language: string
): Promise<PaginatedResponseType<IdNameType>> {
    const parameters = objectToUrlParameters({
        lang: language,
        name: searchQuery,
    });

    const url = `${rootApiUrl}/v2/geo/countries/${countryId}/regions/?${parameters}`;
    const response = await regionListRaceFetchX<PaginatedResponseType<IdNameType>>(url);

    return deserializeV2<PaginatedResponseType<IdNameType>>(url, getIdNameSchema(), response);
}

const cityListRaceFetchX = createRaceFetchX();

export async function fetchCityList(
    searchQuery: string,
    regionId: number,
    language: string
): Promise<PaginatedResponseType<CityV2Type>> {
    const parameters = objectToUrlParameters({
        lang: language,
        name: searchQuery,
    });

    const url = `${rootApiUrl}/v2/geo/regions/${regionId}/cities/?${parameters}`;
    const response = await cityListRaceFetchX<PaginatedResponseType<CityV2Type>>(url);

    return deserializeV2<PaginatedResponseType<CityV2Type>>(url, getCityV2Schema(), response);
}

const suggesterListRaceFetchX = createRaceFetchX();

export function fetchSuggesterList(
    searchQuery: string,
    countryCode: string,
    language: string
): Promise<Array<AddressType>> {
    const parameters = objectToUrlParameters({
        country_code: countryCode, // country_code: ru
        language, // language: ru
        query: searchQuery,
    });

    return suggesterListRaceFetchX<Array<AddressType>>(rootApiUrl + '/geocoding/suggest/?' + parameters);
}

const geocoderListRaceFetchX = createRaceFetchX();

export function fetchGeocoderList(searchQuery: string, countryCode: string): Promise<Array<AddressType>> {
    const parameters = objectToUrlParameters({
        country_code: countryCode,
        addr: searchQuery,
    });

    return geocoderListRaceFetchX<Array<AddressType>>(rootApiUrl + '/geocoding/direct/?' + parameters);
}

export function fetchTimezoneList(): Promise<Array<TimezoneType>> {
    return fetchX<Array<TimezoneType>>(rootApiUrl + '/v1/geo/time_zones/');
}
