import {Locale} from '../../../../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../../../../provider/locale/translation/type';
import {ExtraContentValidationSettingsType} from '../../../content-step-type';

type PropsType = {
    extraRequirements?: ExtraContentValidationSettingsType;
};

export function ActionRequirements(props: PropsType): JSX.Element | null {
    const {extraRequirements} = props;

    if (Object.values(extraRequirements?.action || {}).length === 0) {
        return null;
    }

    return (
        <>
            <Locale stringKey="POSTS_REQUIREMENTS__CALL_TO_ACTION" />
            <ul>
                {Object.values(extraRequirements?.action || {}).map((extra: LangKeyType) => {
                    return (
                        <li key={extra}>
                            <Locale stringKey={extra} />
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
