import {Dispatch, SetStateAction} from 'react';

import {Locale} from '../../provider/locale/locale';
import {SnackbarType} from '../../provider/snackbar/snackbar-type';
import {ApiError, HttpError} from '../../util/error';

import {ProfileDeleteError, ProfileDeleteErrorPayloadType} from './profile-type';

function isProfileDeleteErrorPayload(errorPayload: unknown): errorPayload is ProfileDeleteErrorPayloadType {
    return typeof (errorPayload as ProfileDeleteErrorPayloadType)?.password[0] === 'string';
}

export function handleReplyError(error: unknown): never {
    if (error instanceof ApiError && isProfileDeleteErrorPayload(error.jsonData)) {
        throw new ProfileDeleteError(error.message, error.statusCode, error.jsonData);
    }

    throw error;
}

export function handleProfileExceptions(
    error: unknown,
    snackbar: SnackbarType,
    setErrorText: Dispatch<SetStateAction<string>>
): void {
    if (error instanceof ProfileDeleteError) {
        setErrorText(error.jsonData.password);
        return;
    }

    snackbar.error({
        description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
        message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
    });

    if (!(error instanceof HttpError)) {
        console.error(error);
    }
}
