import {decamelizeKeys} from 'humps';
import {z as r} from 'zod';

import {PostsFilterQueriesType} from '../../page/main/posts/posts-filter/posts-filter-type';
import {shortCatalogSchema, ShortCatalogType} from '../../provider/catalogs/catalogs-type';
import {deserializeV2, fetchAndDeserialize, postAndDeserialize, serialize} from '../../util/api-adapter';
import {CursorPaginationDataType} from '../../util/cursor-pagination/cursor-pagination-type';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {IdNumberType} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {generateResponseSchema, idNameTypeSchema, PaginatedResponseType} from '../api/api-type';

import {preparePostFormRequestData} from './posts-helper';
import {
    CalendarPostsRequestParametersType,
    CalendarPostsResponseType,
    fetchPostSchema,
    FetchPostType,
    lastPostsSchema,
    LastPostsType,
    PostCatalogLinksResultType,
    PostFbPageType,
    PostFormType,
    postInstagramPageSchema,
    PostInstagramPageType,
    PostLinkRequestOptionType,
    postLinksResponseSchema,
    postPreviewDetailsSchema,
    PostPreviewDetailsType,
    PostPreviewOptionsType,
    postsListResponseSchema,
    PostsListResponseType,
    PostStatusEnum,
    PostSubmitInformationParametersType,
    postSubmitInformationSchema,
    PostSubmitInformationType,
    postVkGroupSchema,
    PostVkGroupType,
} from './posts-types';

export function getPostsListApi(
    postsState: PostsFilterQueriesType,
    paginationData: CursorPaginationDataType,
    mainFilterKey: string
): Promise<PostsListResponseType> {
    const url =
        `/v1/posts/?` +
        objectToUrlParameters({
            ...postsState,
            filter_key: mainFilterKey,
            cursor: paginationData.cursor,
            page_size: paginationData.pageSize,
            timestamp: Date.now(),
        });

    return fetchAndDeserialize<PostsListResponseType>(url, postsListResponseSchema);
}

export function removePostApi(postId: number): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/posts/${postId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function hardRemovePostApi(postId: number): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/posts/hard_delete/${postId}`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function publishPostApi(postId: number): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/posts/${postId}/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function getPostApi(postId: number): Promise<FetchPostType> {
    return fetchAndDeserialize<FetchPostType>(`/v1/posts/${postId}/`, fetchPostSchema);
}

export function fetchAvailableVkGroups(): Promise<Array<PostVkGroupType>> {
    const url = `/v1/vk/groups/`;

    return fetchAndDeserialize<Array<PostVkGroupType>>(url, postVkGroupSchema.array());
}

export function createPostApi(postValues: PostFormType, status: PostStatusEnum): Promise<IdNumberType> {
    return fetchX<IdNumberType>(`${rootApiUrl}/v1/posts/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: preparePostFormRequestData(postValues, status),
    });
}

export function updatePostApi(body: PostFormType, status: PostStatusEnum): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/posts/${body?.id}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: preparePostFormRequestData(body, status),
    });
}

export function getPostCatalogsApi(): Promise<PaginatedResponseType<ShortCatalogType>> {
    return fetchAndDeserialize<PaginatedResponseType<ShortCatalogType>>(
        `/v1/posts/available_catalogs/`,
        generateResponseSchema(shortCatalogSchema)
    );
}

export async function getPostAvailableCatalogsApi(): Promise<Array<ShortCatalogType>> {
    const url = `${rootApiUrl}/v1/posts/available_catalogs_for_user/`;

    const response = await fetchX(url, {
        method: FetchMethodEnum.get,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<Array<ShortCatalogType>>(url, r.array(shortCatalogSchema), response);
}

export function getPostsCalendar(
    parameters: CalendarPostsRequestParametersType,
    mainFilterKey: string
): Promise<CalendarPostsResponseType> {
    return fetchX<CalendarPostsResponseType>(
        `${rootApiUrl}/v1/posts/calendar/?` +
            objectToUrlParameters({
                ...parameters,
                filter_key: mainFilterKey,
            }),
        {
            headers: {...mainApiHeaders, ...getCsrfHeaders()},
        }
    );
}

export async function getLastPostsApi(): Promise<Array<LastPostsType>> {
    const url = `${rootApiUrl}/cp/dashboard/last_posts/`;
    const response = await fetchX(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<Array<LastPostsType>>(url, lastPostsSchema.array(), response);
}

export async function getPostLinks(options: PostLinkRequestOptionType): Promise<PostCatalogLinksResultType> {
    const {postId, pageSize, page, statuses, providers} = options;
    const url = `/v1/posts/detailed_info/${postId}/?${objectToUrlParameters({
        page_size: pageSize,
        page,
        statuses,
        ...(providers ? {provider_codes: providers} : {}),
        ...(statuses ? {statuses} : {}),
    })}`;

    return fetchAndDeserialize(url, postLinksResponseSchema);
}

export async function getPostSubmitInformation(
    options: PostSubmitInformationParametersType
): Promise<PostSubmitInformationType> {
    const {selectorId, providerCodes} = options;
    const url = `/v1/posts/statistics_by_catalogs_for_companies/${selectorId}/?${objectToUrlParameters({
        provider_codes: providerCodes,
    })}`;

    return fetchAndDeserialize(url, postSubmitInformationSchema);
}

export function fetchAvailableFbPages(): Promise<Array<PostFbPageType>> {
    const url = `/cp/posts/fb_pages/`;

    return fetchAndDeserialize<Array<PostFbPageType>>(url, idNameTypeSchema.array());
}

export function fetchAvailableInstagramPages(): Promise<Array<PostInstagramPageType>> {
    const url = `/cp/posts/ig_pages/`;

    return fetchAndDeserialize<Array<PostInstagramPageType>>(url, postInstagramPageSchema.array());
}

export function removePostVideo(videoId: number): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/v1/posts/video/${videoId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function requestPostSuggest(options: {channelId: string; requestId: string; selectorId: string}): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/cp/posts/generate_template/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(decamelizeKeys(options)),
    });
}

export async function fetchCompanyPreviewData(
    catalog: string,
    options: PostPreviewOptionsType
): Promise<PostPreviewDetailsType> {
    const url = `/cp/posts/preview_catalog/${catalog}/`;
    const body = serialize(options) as Record<string, unknown>;

    return postAndDeserialize<PostPreviewDetailsType>(url, postPreviewDetailsSchema, body);
}

export function refreshPost(postId: number): Promise<void> {
    const url = `${rootApiUrl}/v1/posts/refresh_post/${postId}/`;

    return fetchNoContent(url, {
        method: FetchMethodEnum.get,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
