import {z as r} from 'zod';

import {generateResponseSchema} from '../../api/api-type';
import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const reviewPhraseSchema = r.object({
    phrase: r.string(),
    total: r.number(),
    avgRating: r.number(),
    replied: r.number(),
    repliedPercent: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
    trendByThreeMonths: r.array(
        r.object({
            date: r.string(),
            value: r.number(),
        })
    ),
});

export type ReviewPhraseType = r.infer<typeof reviewPhraseSchema>;

export const reviewPhraseListResponse = generateResponseSchema(reviewPhraseSchema);

export const reviewsPhraseSearchSchema = r.array(r.string());

export type ReviewPhraseListQueryType = {
    words: number | null;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};
