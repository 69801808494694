import {faChartPie, faCloud} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Segmented, Table} from 'antd';
import {SegmentedValue} from 'antd/lib/segmented';
import {useContext, useEffect, useMemo, useState} from 'react';

import {AlertFallback} from '../../../../../../component/alert-fallback/alert-fallback';
import {PageCard} from '../../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../provider/main-filter/main-filter-type';
import {
    useReviewAnalyticsTagBySentiment,
    useReviewAnalyticsTagList,
} from '../../../../../../service/reivew-analysis/review-analytics-tags/review-analytics-tag-hook';
import {ReviewsAnalysisTagType} from '../../../../../../service/reivew-analysis/review-analytics-tags/review-analytics-tags-type';
import {classNames} from '../../../../../../util/css';
import {ActiveFilter} from '../../../components/active-filter/active-filter';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {TagCloudChart} from '../../dashboard/widgets/tag-cloud/tag-cloud-chart/tag-cloud-chart';
import {keywordsAndTopicsDefaultPageSize, keywordsAndTopicsPageSizeOptions} from '../keywords-and-topics-page-const';
import {KeywordsAndTopicsPageContext} from '../keywords-and-topics-page-context';
import {tagsPageTableColumns} from '../keywords-and-topics-page-helper';
import {ReviewsAnalysisTagTableDataType} from '../keywords-and-topics-page-type';

import {KeywordsChartLegend} from './chart-legend/keywords-chart-legend';
import {KeywordsDoughnutChart} from './doughnut-chart/keywords-doughnut-chart';
import {tagCloudFetchCount} from './keywords-tab-const';
import {KeywordsTabSegmentEnum} from './keywords-tab-type';
import * as styles from './keywords-tab.scss';

export function KeywordsTab(): JSX.Element {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {singleFilter} = useReviewsAnalysisFilter(false);
    const {onIsFormInProgressChange} = useContext(KeywordsAndTopicsPageContext);

    const {result, isInProgress, processError, pagination} = useReviewAnalyticsTagList(singleFilter, mainFilterKey, {
        initialPageSize: keywordsAndTopicsDefaultPageSize,
    });

    const {total, pageSize, page, onChange: onPaginationChange} = pagination;

    const dataSource: Array<ReviewsAnalysisTagTableDataType> = useMemo<
        Array<ReviewsAnalysisTagTableDataType>
    >((): Array<ReviewsAnalysisTagTableDataType> => {
        return (result?.results || []).map(
            (listItem: ReviewsAnalysisTagType, index): ReviewsAnalysisTagTableDataType => ({
                key: listItem.tag + index,
                totalItems: total,
                ...listItem,
            })
        );
    }, [result, total]);

    const {
        result: tagsCloudResult,
        isInProgress: tagsCloudisInProgress,
        processError: tagsCloudProcessError,
        pagination: tagsCloudPagination,
    } = useReviewAnalyticsTagBySentiment(singleFilter, mainFilterKey, {
        initialPageSize: tagCloudFetchCount,
        shouldSaveState: false,
    });

    useEffect(() => {
        onIsFormInProgressChange(isInProgress || tagsCloudisInProgress);
    }, [isInProgress, onIsFormInProgressChange, tagsCloudisInProgress]);

    const [activeSegment, setActiveSegment] = useState<string>(KeywordsTabSegmentEnum.TagsCloud);

    function handleSegmentChange(value: SegmentedValue) {
        setActiveSegment(value as string);
    }

    const {getLocalizedString} = useLocale();

    const keywordsLegendItems = useMemo(
        () => [
            {
                label: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__POSITIVE'),
                color: '#92C564',
            },
            {
                label: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL'),
                color: '#F6C758',
            },
            {
                label: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE'),
                color: '#DC304B',
            },
            {
                label: getLocalizedString('REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED'),
                color: '#D8DBE2',
            },
        ],
        [getLocalizedString]
    );

    return (
        <>
            <PageCard
                collapsible
                title={<Locale stringKey="REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__TAGS_CLOUD_TITLE" />}
            >
                <div className={styles.KeywordsTab}>
                    <div className={styles.KeywordsTab_segment}>
                        <Segmented
                            onChange={handleSegmentChange}
                            options={[
                                {
                                    value: KeywordsTabSegmentEnum.TagsCloud,
                                    icon: <FontAwesomeIcon icon={faCloud} />,
                                },
                                {
                                    value: KeywordsTabSegmentEnum.Chart,
                                    icon: <FontAwesomeIcon icon={faChartPie} />,
                                },
                            ]}
                        />
                    </div>
                    <div
                        className={classNames(styles.KeywordsTab_segmentContent, {
                            [styles.KeywordsTab_segmentContent__visible]:
                                activeSegment === KeywordsTabSegmentEnum.TagsCloud,
                        })}
                    >
                        {Boolean(tagsCloudResult?.results.length) && (
                            <KeywordsChartLegend items={keywordsLegendItems} />
                        )}
                        <div className={styles.KeywordsTab_tagsCloud}>
                            <TagCloudChart
                                data={tagsCloudResult?.results || []}
                                isInProgress={tagsCloudisInProgress}
                                processError={tagsCloudProcessError}
                                tagLimit={tagCloudFetchCount}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(styles.KeywordsTab_segmentContent, {
                            [styles.KeywordsTab_segmentContent__visible]:
                                activeSegment === KeywordsTabSegmentEnum.Chart,
                        })}
                    >
                        <KeywordsDoughnutChart
                            data={tagsCloudResult?.results || []}
                            isInProgress={tagsCloudisInProgress}
                            total={tagsCloudPagination.total}
                        />
                    </div>
                </div>
            </PageCard>
            <PageCard collapsible title={<Locale stringKey="REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__KEYWORDS_TITLE" />}>
                <ActiveFilter
                    isCompareRangeAllowed={false}
                    isCompetitorAllowed={false}
                    isPeriodHidden
                    isPreview={false}
                    isTopicsAllowed={false}
                    isWithTextOnly={false}
                />

                {processError && <AlertFallback description={processError?.message} message={processError?.name} />}

                {!processError && (
                    <Table<ReviewsAnalysisTagTableDataType>
                        columns={tagsPageTableColumns}
                        dataSource={dataSource}
                        loading={isInProgress}
                        pagination={{
                            total,
                            pageSize,
                            defaultPageSize: keywordsAndTopicsDefaultPageSize,
                            pageSizeOptions: keywordsAndTopicsPageSizeOptions,
                            current: page,
                            size: 'default',
                            showSizeChanger: true,
                            onChange: onPaginationChange,
                        }}
                        scroll={{x: true}}
                        size="middle"
                    />
                )}
            </PageCard>
        </>
    );
}
