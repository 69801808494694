import {Text} from '../../../../../component/text/text';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/localization';
import {AverageResponseTimeType} from '../../../../../service/reviews-and-answers-statistics/average-response-time/average-response-time-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {classNames} from '../../../../../util/css';
import {CompareModeDelta} from '../../compare-mode-delta/compare-mode-delta';
import {AverageResponseTimeValue} from '../average-time-value/average-response-time-value';

import * as styles from './average-response-time-stats.scss';

type PropsType = {
    averageResponseTime: AverageResponseTimeType | null;
    isLoading?: boolean;
    isCompareMode: boolean;
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;
};

// eslint-disable-next-line complexity
export function AverageResponseTimeStats(props: PropsType): JSX.Element {
    const {averageResponseTime, isCompareMode, isLoading, timeUnit} = props;

    if (isLoading) {
        return (
            <div className={styles.AverageResponseTimeStats}>
                <div className={styles.AverageResponseTimeStats_item}>
                    <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__IN_CUSTOMER_PROFILE" />
                    <Spinner />
                </div>

                <div className={styles.AverageResponseTimeStats_item}>
                    <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__FOR_CLIENTS" />
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.AverageResponseTimeStats}>
            <div className={styles.AverageResponseTimeStats_item}>
                <Text
                    block
                    secondary
                    stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__IN_CUSTOMER_PROFILE"
                />

                {isCompareMode ? (
                    <CompareModeDelta
                        compareValue={
                            <AverageResponseTimeValue
                                seconds={averageResponseTime?.avgReplyTime?.cp?.comparedValue || 0}
                                timeUnit={timeUnit}
                            />
                        }
                        delta={averageResponseTime?.avgReplyTime?.cp?.delta || 0}
                        trendReverse
                        value={
                            <AverageResponseTimeValue
                                seconds={averageResponseTime?.avgReplyTime?.cp?.value || 0}
                                timeUnit={timeUnit}
                            />
                        }
                    />
                ) : (
                    <div className={styles.AverageResponseTimeStats_itemValueContainer}>
                        <AverageResponseTimeValue
                            large
                            seconds={averageResponseTime?.avgReplyTime?.cp?.value || 0}
                            timeUnit={timeUnit}
                        />
                    </div>
                )}
            </div>

            <div className={styles.AverageResponseTimeStats_item}>
                <Text block secondary stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__FOR_CLIENTS" />

                <div
                    className={classNames(
                        styles.AverageResponseTimeStats_itemValueContainer,
                        styles.AverageResponseTimeStats_itemValueContainer__forClient
                    )}
                >
                    {isCompareMode ? (
                        <CompareModeDelta
                            compareValue={
                                <AverageResponseTimeValue
                                    seconds={averageResponseTime?.avgReplyTime?.catalog?.comparedValue || 0}
                                    timeUnit={timeUnit}
                                />
                            }
                            delta={averageResponseTime?.avgReplyTime?.catalog?.delta || 0}
                            trendReverse
                            value={
                                <AverageResponseTimeValue
                                    seconds={averageResponseTime?.avgReplyTime?.catalog?.value || 0}
                                    timeUnit={timeUnit}
                                />
                            }
                        />
                    ) : (
                        <AverageResponseTimeValue
                            danger
                            large
                            seconds={averageResponseTime?.avgReplyTime?.catalog?.value || 0}
                            timeUnit={timeUnit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
