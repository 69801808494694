import {faCheck, faChevronLeft, faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, Form, Input, Radio} from 'antd';
import {useState} from 'react';

import {Locale} from '../../provider/locale/localization';
import {classNames} from '../../util/css';

import * as styles from './questionnaire.scss';

type PropsType = {
    isLoading?: boolean;
    pages: Array<{
        title: string;
        subtitle?: string;
        multipleChoise: boolean;
        fieldName: string;
        options: Array<{
            label: string;
            value: string;
        }>;
        inputPlaceholder: string;
    }>;
    onSubmit: (id: string, value: string, isLastStep?: boolean) => void;
};

export function Questionnaire(props: PropsType): JSX.Element {
    const {isLoading, pages, onSubmit} = props;

    const [form] = Form.useForm();
    const [isOtherFieldVisible, setIsOtherFieldVisible] = useState(Array.from({length: pages.length}).fill(false));

    const [currentPage, setCurrentPage] = useState(0);

    function handleFieldsChange() {
        const newIsOtherFieldVisible = [...isOtherFieldVisible];

        pages.map((page, index) => {
            const value = form.getFieldValue(page.fieldName);

            newIsOtherFieldVisible[index] = Array.isArray(value) ? value.includes('other') : value === 'other';
        });
        setIsOtherFieldVisible(newIsOtherFieldVisible);
    }

    return (
        <div className={styles.Questionnaire}>
            {pages.map((page, index) => {
                const isOther = isOtherFieldVisible[index];
                const value = isOther
                    ? form.getFieldValue(`other_${page.fieldName}`)
                    : form.getFieldValue(page.fieldName);
                const isMultiple = Array.isArray(form.getFieldValue(page.fieldName));

                const pageValue = (() => {
                    if (isMultiple) {
                        const generalValues = form
                            .getFieldValue(page.fieldName)
                            .filter((item: string) => item !== 'other');

                        return String(isOther ? [...generalValues, value] : generalValues);
                    }

                    return value;
                })();

                return (
                    <div
                        className={classNames(styles.Questionnaire_page, {
                            [styles.Questionnaire_page__closed]: index < currentPage,
                            [styles.Questionnaire_page__active]: index === currentPage,
                            [styles.Questionnaire_page__middle]: index === currentPage + 1,
                            [styles.Questionnaire_page__loading]: isLoading,
                        })}
                        key={page.fieldName}
                    >
                        <header className={styles.Questionnaire_pageHeader}>
                            <h3 className={styles.Questionnaire_title}>{page.title}</h3>
                            {page.subtitle && <p className={styles.Questionnaire_subtitle}>{page.subtitle}</p>}
                        </header>
                        <Form form={form} onFieldsChange={handleFieldsChange}>
                            {page.multipleChoise && (
                                <Form.Item name={page.fieldName} valuePropName="checked">
                                    <Checkbox.Group className={styles.Questionnaire_options}>
                                        {page.options &&
                                            page.options.map((option) => (
                                                <Checkbox
                                                    className={styles.Questionnaire_option}
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                    <FontAwesomeIcon
                                                        className={styles.Questionnaire_optionIcon}
                                                        icon={faCheck}
                                                    />
                                                </Checkbox>
                                            ))}
                                        <Checkbox className={styles.Questionnaire_option} value="other">
                                            <Locale stringKey="TEXT__OTHER" />
                                            <FontAwesomeIcon
                                                className={styles.Questionnaire_optionIcon}
                                                icon={faCheck}
                                            />
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            )}
                            {!page.multipleChoise && (
                                <Form.Item name={page.fieldName}>
                                    <Radio.Group className={styles.Questionnaire_options}>
                                        {page.options &&
                                            page.options.map((option) => (
                                                <Radio
                                                    className={styles.Questionnaire_option}
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                    <FontAwesomeIcon
                                                        className={styles.Questionnaire_optionIcon}
                                                        icon={faCheck}
                                                    />
                                                </Radio>
                                            ))}
                                        <Radio className={styles.Questionnaire_option} value="other">
                                            <Locale stringKey="TEXT__OTHER" />
                                            <FontAwesomeIcon
                                                className={styles.Questionnaire_optionIcon}
                                                icon={faCheck}
                                            />
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            <Form.Item hidden={!isOtherFieldVisible[index]} name={`other_${page.fieldName}`}>
                                <Input className={styles.Questionnaire_input} placeholder={page.inputPlaceholder} />
                            </Form.Item>
                        </Form>

                        <div className={styles.Questionnaire_navigation}>
                            <Button
                                className={classNames(styles.Questionnaire_navigationButton, {
                                    [styles.Questionnaire_navigationButton__hidden]: currentPage === 0,
                                })}
                                onClick={() => setCurrentPage((pageNumber) => pageNumber - 1)}
                            >
                                <FontAwesomeIcon className={styles.Questionnaire_navigationIcon} icon={faChevronLeft} />
                                <Locale stringKey="BUTTON__BACK" />
                            </Button>

                            <Button
                                className={classNames(styles.Questionnaire_navigationButton, {
                                    [styles.Questionnaire_navigationButton__hidden]: currentPage === pages.length - 1,
                                })}
                                disabled={!value}
                                onClick={() => {
                                    onSubmit(page.fieldName, pageValue);
                                    setCurrentPage((pageNumber) => pageNumber + 1);
                                }}
                            >
                                <Locale stringKey="TEXT__NEXT" />
                                <FontAwesomeIcon
                                    className={styles.Questionnaire_navigationIcon}
                                    icon={faChevronRight}
                                />
                            </Button>

                            <Button
                                className={classNames(
                                    styles.Questionnaire_navigationButton,
                                    styles.Questionnaire_navigationButton__submit,
                                    {
                                        [styles.Questionnaire_navigationButton__hidden]:
                                            currentPage !== pages.length - 1,
                                    }
                                )}
                                disabled={!value}
                                onClick={() => onSubmit(page.fieldName, pageValue, true)}
                            >
                                <Locale stringKey="BUTTON__SAVE" />
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
