import {RcFile} from 'antd/lib/upload';
import {z as r} from 'zod';

import {shortCatalogSchema, ShortCatalogType} from '../../provider/catalogs/catalogs-type';
import {deserializeV2, UnknownType} from '../../util/api-adapter';
import {CursorPaginationDataType} from '../../util/cursor-pagination/cursor-pagination-type';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {CursorPaginationResponseType} from '../api/api-type';

import {
    CreateProductGroupPayloadType,
    googleServiceCategorySchema,
    GoogleServiceCategoryType,
    ProductGroupListItemType,
    productGroupSchema,
    productGroupsListResponseSchema,
    ProductGroupType,
    productsFeedSchema,
    ProductsFeedType,
    productsFileSchema,
    ProductsFileType,
} from './products-type';

export async function loadProductGroupsApi(
    paginationData: CursorPaginationDataType,
    mainFilterKey?: string
): Promise<CursorPaginationResponseType<ProductGroupListItemType>> {
    const url = `${rootApiUrl}/cp/prices/product_groups/?${objectToUrlParameters({
        filter_key: mainFilterKey || '',
        cursor: paginationData.cursor,
        page_size: paginationData.pageSize,
    })}`;

    const response = await fetchX(url);

    return deserializeV2<CursorPaginationResponseType<ProductGroupListItemType>>(
        url,
        productGroupsListResponseSchema,
        response
    );
}

export function removeProductGroupApi(productGroupId: number): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/cp/prices/product_group/${productGroupId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export async function loadProductsAvailableCatalogsApi(): Promise<Array<ShortCatalogType>> {
    const url = `${rootApiUrl}/cp/prices/available_catalogs_for_user/`;

    const response = await fetchX(url, {
        method: FetchMethodEnum.get,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<Array<ShortCatalogType>>(url, r.array(shortCatalogSchema), response);
}

export async function loadAvailableGoogleCategoriesApi(
    companySelectorId: string
): Promise<Array<GoogleServiceCategoryType>> {
    const url = `${rootApiUrl}/cp/prices/available_categories_for_google/?selector_uid=${companySelectorId}`;

    const response = await fetchX(url, {
        method: FetchMethodEnum.get,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<Array<GoogleServiceCategoryType>>(url, r.array(googleServiceCategorySchema), response);
}

export async function uploadProductsFileApi(file: RcFile): Promise<ProductsFileType> {
    const url = `${rootApiUrl}/cp/prices/source_file/`;
    const formData = new FormData();

    formData.append('source_file', file);
    const result = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {...getCsrfHeaders()},
        body: formData,
    });

    return deserializeV2<ProductsFileType>(url, productsFileSchema, result);
}

export async function createProductSourceFromFeedApi(feedUrl: string): Promise<ProductsFeedType> {
    const url = `${rootApiUrl}/cp/prices/source_feed/`;

    const result = await fetchX(url, {
        method: FetchMethodEnum.post,
        headers: {...getCsrfHeaders(), ...mainApiHeaders},
        body: JSON.stringify({
            url: feedUrl,
            save: String(true),
        }),
    });

    return deserializeV2<ProductsFeedType>(url, productsFeedSchema, result);
}

export async function createProductsGroupApi(values: CreateProductGroupPayloadType): Promise<unknown> {
    return fetchNoContent(`${rootApiUrl}/cp/prices/product_groups/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(values),
    });
}

export async function editProductsGroupApi(groupId: number, values: CreateProductGroupPayloadType): Promise<unknown> {
    return fetchNoContent(`${rootApiUrl}/cp/prices/product_group/${groupId}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(values),
    });
}

export async function loadProductGroupApi(groupId: string): Promise<ProductGroupType> {
    const url = `${rootApiUrl}/cp/prices/product_group/${groupId}/`;

    const result = await fetchX<UnknownType>(url, {
        method: FetchMethodEnum.get,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });

    return deserializeV2<ProductGroupType>(url, productGroupSchema, result);
}
