import {faCaretDown, faCaretUp, faCircleSmall} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type PropsType = {
    delta: number;
};

export function LocalPackPositionChangeIcon(props: PropsType): JSX.Element {
    const {delta} = props;

    switch (true) {
        case delta > 0: {
            return <FontAwesomeIcon icon={faCaretUp} />;
        }
        case delta < 0: {
            return <FontAwesomeIcon icon={faCaretDown} />;
        }
        default:
            return <FontAwesomeIcon icon={faCircleSmall} size="2xs" />;
    }
}
