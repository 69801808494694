import {z as r} from 'zod';

export const averageResponseTimeByEmployeeItemSchema = r.object({
    employee: r.object({
        pk: r.string(),
        name: r.string(),
        email: r.string(),
    }),
    abusesCount: r.object({
        value: r.number().nullable(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    repliesCount: r.object({
        value: r.number().nullable(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    avgReplyTime: r.object({
        // eslint-disable-next-line id-length
        cp: r.object({
            value: r.number().nullable(), // время в секундах
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
        catalog: r.object({
            value: r.number().nullable(), // время в секундах
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
    }),
    tagsCount: r.object({
        value: r.number().nullable(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
});

export type AverageResponseTimeByEmployeeType = r.infer<typeof averageResponseTimeByEmployeeItemSchema>;
