import {CompaniesImportTypeEnum} from '../../../../../../service/companies-import/companies-import-type';

import {ProgressTranslationType} from './progress/feed-progress';

export enum FeedFormKeyEnum {
    SourceUrl = 'sourceUrl',
    Format = 'format',
    AllowedBrands = 'allowedBrands',
}

export type FeedFormType = {
    [FeedFormKeyEnum.SourceUrl]: string;
    [FeedFormKeyEnum.Format]: CompaniesImportTypeEnum;
    [FeedFormKeyEnum.AllowedBrands]: Array<{brandId: number; brandCode: string}>;
};

export enum FeedFormTabEnum {
    Link = 'link',
    File = 'file',
}

export type FeedFormTranslationType = 'submitButtonLabel' | ProgressTranslationType;
