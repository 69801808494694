import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {DashboardPostponeRecommendationPayloadApiType} from '../../../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-api-type';

export enum RecommendationPostponeEnum {
    oneWeek = 'oneWeek',
    oneMonth = 'oneMonth',
    threeMonths = 'threeMonths',
}

export const recommendationPostponeOptionText: Record<RecommendationPostponeEnum, LangKeyType> = {
    [RecommendationPostponeEnum.oneWeek]: 'DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ONE_WEEK',
    [RecommendationPostponeEnum.oneMonth]: 'DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__ONE_MONTH',
    [RecommendationPostponeEnum.threeMonths]: 'DASHBOARD_PAGE__RECOMMENDATION__POSTPONE_OPTION__THREE_MONTHS',
};
export const recommendationPostponeOptionPayload: Record<
    RecommendationPostponeEnum,
    DashboardPostponeRecommendationPayloadApiType
> = {
    [RecommendationPostponeEnum.oneWeek]: {weeks: 1},
    [RecommendationPostponeEnum.oneMonth]: {months: 1},
    [RecommendationPostponeEnum.threeMonths]: {months: 3},
};
