import {Table, TableColumnType, TablePaginationConfig} from 'antd';
import {ColumnFilterItem} from 'antd/lib/table/interface';
import {Key, useCallback, useEffect, useMemo, useState} from 'react';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {renderCellActions} from '../../../../layout/actions-cell/actions-cell';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {TagDataType, TagGroupType, useTagGroupList} from '../../../../service/reviews/reviews-tags';
import {toTrimmedString} from '../../../../util/string';
import {useUrl} from '../../../../util/url-hook/url-hook';

import {tagColumnList, withoutGroupItemId} from './tags-table-const';
import {TagsTableRowDataType} from './tags-table-type';

type PropsType = {
    setTagIdList: (tagIdList: Array<string>) => void;
    setTagDataList: (tagDataList: Array<TagDataType>) => void;
    tagsHookResult: Array<TagDataType> | null;
    tagsHookIsInProgress: boolean;
    tagsCount: number;
    pagination: TablePaginationConfig;
    setPagination: (pagination: TablePaginationConfig) => void;
    tagGroupIdFilterList: Array<string>;
    setTagGroupIdFilterList: (groupIdFilterList: Array<string>) => void;
    onDelete: (tagData?: TagDataType) => void;
    setWithoutFolder: (value: true | null) => void;
    withoutFolder: true | null;
};

export function TagsTable(props: PropsType): JSX.Element {
    const {
        setTagIdList,
        setTagDataList,
        tagsHookResult,
        tagsHookIsInProgress,
        tagsCount,
        pagination,
        setPagination,
        setTagGroupIdFilterList,
        tagGroupIdFilterList,
        onDelete,
        setWithoutFolder,
        withoutFolder,
    } = props;

    const {reviewsManagementTagEdit: reviewsManagementTagEditRoute} = appRoute;

    const {getLocalizedString} = useLocale();

    const {pushUrl} = useUrl();

    const tagDataList: Array<TagDataType> = useMemo((): Array<TagDataType> => {
        return tagsHookResult || [];
    }, [tagsHookResult]);

    useEffect(() => {
        setTagDataList(tagDataList);
    }, [tagDataList, setTagDataList]);

    const {data: tagGroupListResult} = useTagGroupList();

    const availableTagGroupList: Array<TagGroupType> = useMemo((): Array<TagGroupType> => {
        return tagGroupListResult || [];
    }, [tagGroupListResult]);

    function handleRowSelection(_selectedRowKeys: Array<Key>, rowDataList: Array<TagsTableRowDataType>) {
        setTagIdList(rowDataList.map((rowData: TagsTableRowDataType): string => rowData.key));
    }

    const convertTagTableRow = useCallback(
        (tagData: TagDataType, index: number): TagsTableRowDataType => {
            return {
                tagName: tagData.title,
                tagGroup: tagData.folder ? tagData.folder.title : '—',
                tagActions: renderCellActions(
                    () => pushUrl(generatePath(reviewsManagementTagEditRoute.path, {tagId: tagData.id})),
                    () => onDelete(tagData)
                ),
                key: String(tagData.id),
                index,
            };
        },
        [pushUrl, onDelete, reviewsManagementTagEditRoute.path]
    );

    const [dataList, setDataList] = useState<Array<TagsTableRowDataType>>([]);

    useEffect(() => {
        setDataList(tagDataList.map(convertTagTableRow));
    }, [tagDataList, convertTagTableRow]);

    const [tagNameColumn, tagGroupColumn, tagActions] = tagColumnList;

    function handleTableChange(newPagination: TablePaginationConfig, filters: Record<string, Array<unknown> | null>) {
        const tagGroupList = filters.tagGroup || [];

        const hasWithoutFolderRequestFlag = tagGroupList.includes('-1');
        const groupFilters = tagGroupList.filter((group) => group !== '-1');

        setPagination(newPagination);
        setWithoutFolder(hasWithoutFolderRequestFlag || null);

        setTagGroupIdFilterList([...groupFilters].map(toTrimmedString));
    }

    const groupColumnWithFilter = useMemo((): TableColumnType<TagsTableRowDataType> => {
        const filters: Array<ColumnFilterItem> = availableTagGroupList.map((tagGroup: TagGroupType) => {
            return {text: tagGroup.title, value: String(tagGroup.id)};
        });

        filters.push({text: getLocalizedString('TEXT__WITHOUT_GROUP'), value: withoutGroupItemId});

        return {
            ...tagGroupColumn,
            filters,
            filteredValue: withoutFolder ? [...tagGroupIdFilterList, '-1'] : tagGroupIdFilterList,
        };
    }, [availableTagGroupList, tagGroupIdFilterList, tagGroupColumn, getLocalizedString, withoutFolder]);

    return (
        <Table<TagsTableRowDataType>
            columns={[
                tagNameColumn,
                groupColumnWithFilter, // groupColumn,
                tagActions,
            ]}
            dataSource={dataList}
            loading={tagsHookIsInProgress}
            onChange={handleTableChange}
            pagination={{
                size: 'default',
                showSizeChanger: true,
                hideOnSinglePage: false,
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: tagsCount,
            }}
            rowKey="key"
            rowSelection={{columnWidth: 47, onChange: handleRowSelection}}
            scroll={{x: true}}
            size="middle"
        />
    );
}
