import {Input, Select} from 'antd';
import {LabeledValue, SelectProps} from 'antd/lib/select';
import {SyntheticEvent, useState} from 'react';

import {fetchGeocoderList, fetchSuggesterList} from '../../../../../../../service/api/api';
import {AddressType, CityV2Type, CountryV2Type, IdNameType} from '../../../../../../../service/api/api-type';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../../typings/antd';
import {classNames} from '../../../../../../../util/css';
import {debounce} from '../../../../../../../util/function';
import {getLabel} from '../build-query-helper';

import {getAddressByAddressKey, renderOption} from './select-location-helper';
import * as styles from './select-location.scss';

export type AddressInputType = CountryV2Type | CityV2Type | IdNameType | string;

type PropsType<T extends AddressInputType> = {
    value?: T | null;
    onChange?: (value?: T | null) => void;

    isInProgress: boolean;
    selectItems: Array<T>;

    searchQuery: string;
    setSearchQuery: (query: string) => void;

    className?: string;
    searchQueryList: Array<string | null>;
    addressKey: keyof AddressType;
    setAddress?: (value: string | null) => void;
    isRequired: boolean;
    isSelectRequired: boolean;
    label: JSX.Element;
    placeholder: string;
    country: CountryV2Type | null;
    isDisabled: boolean;
};

export function SelectLocation<T extends AddressInputType>(props: PropsType<T>): JSX.Element {
    const {
        className,
        isRequired,
        isSelectRequired,
        label,
        placeholder,
        country,
        value,
        onChange,
        addressKey,
        searchQueryList,
        isInProgress,
        selectItems: selectItems,
        searchQuery,
        setSearchQuery,
        isDisabled,
    } = props;

    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const form = Form.useFormInstance<CompanyFormType>();

    const fullClassName = classNames(styles.select_location, className);
    const debouncedSetSearchQuery = debounce<[string]>(setSearchQuery, 300);

    // eslint-disable-next-line complexity
    async function handleSuggestChange(selected: LabeledValue, selectedItem?: T) {
        const selectedLabel = selected.label?.toString();

        if (!selectedLabel || !country) {
            return;
        }

        try {
            const fullAddress = [...searchQueryList, selectedLabel].join(', ');
            const suggestResult = await fetchSuggesterList(fullAddress, country.countryCode, country.lang);
            let selectedAddress = getAddressByAddressKey(suggestResult || [], addressKey, selectedLabel);

            if (selectedAddress === null || selectedAddress.lat === null || selectedAddress.lon === null) {
                const geocodingResult = await fetchGeocoderList(fullAddress, country.countryCode);

                if (geocodingResult[0]) {
                    selectedAddress = geocodingResult[0];
                }
            }

            if (!selectedAddress) {
                return;
            }

            const latLngAlreadyUpdated =
                typeof selectedItem === 'object' && 'lat' in selectedItem && selectedItem?.lat && selectedItem?.lon;

            if (
                !latLngAlreadyUpdated &&
                typeof selectedAddress.lat === 'number' &&
                typeof selectedAddress.lon === 'number'
            ) {
                form.setFieldsValue({
                    address: {latLng: [selectedAddress.lat, selectedAddress.lon]},
                });
            }

            if (selectedAddress.postal_code) {
                form.setFieldsValue({
                    address: {postalCode: selectedAddress.postal_code},
                });
            }
        } catch (error: unknown) {
            console.error(error);
        }
    }

    function handleSelectChange(selected: LabeledValue | undefined) {
        if (!selected) {
            return;
        }

        const selectedItem = selectItems?.find(
            (item): boolean => selected.label === (typeof item === 'string' ? item : item.name)
        );

        onChange?.(selectedItem);
        handleSuggestChange(selected, selectedItem);
    }

    const commonSelectProps: SelectProps<LabeledValue | undefined> = {
        filterOption: () => true,
        labelInValue: true,
        // show 'No data' message only if user entered some text, otherwise we don't show anything
        // eslint-disable-next-line no-undefined
        notFoundContent: searchQuery === '' ? null : undefined,
        size: 'large',
        value: getLabel(typeof value === 'string' ? {id: 0, name: value} : value),
    };

    if (isSelectRequired) {
        return (
            <Form.Item className={fullClassName} label={label} required={isRequired}>
                <Select<LabeledValue | undefined>
                    disabled={Boolean(isDisabled)}
                    loading={isInProgress}
                    onChange={handleSelectChange}
                    onSearch={(searchValue: string) => {
                        debouncedSetSearchQuery(searchValue.trim());
                    }}
                    placeholder={placeholder}
                    showSearch
                    suffixIcon={null}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonSelectProps}
                >
                    {selectItems.map(renderOption)}
                </Select>
            </Form.Item>
        );
    }

    return (
        <div className={classNames(fullClassName, styles.select_location__free_wrapper)}>
            <Form.Item
                className={styles.select_location__free_input}
                initialValue={searchQuery || null}
                label={label}
                required={isRequired}
            >
                <Input
                    disabled={Boolean(isDisabled)}
                    onFocus={() => setHasFocus(true)}
                    onInput={(event: SyntheticEvent<HTMLInputElement>) => {
                        const newValue = event.currentTarget.value;

                        onChange?.(newValue as T);
                        debouncedSetSearchQuery(newValue);

                        if (!hasFocus) {
                            setHasFocus(true); // just in case we managed to hide select after focusing the field
                        }
                    }}
                    placeholder={placeholder}
                    size="large"
                    value={(typeof value === 'string' ? value : value?.name) || ''}
                />
            </Form.Item>

            <Form.Item className={styles.select_location__free_select} initialValue={searchQuery || null} label={label}>
                <Select<LabeledValue | undefined>
                    onChange={(selected: LabeledValue | undefined) => {
                        handleSelectChange(selected);
                        setHasFocus(false);
                    }}
                    onDropdownVisibleChange={(isOpen: boolean) => {
                        if (!isOpen) {
                            setHasFocus(false);
                        }
                    }}
                    open={hasFocus}
                    suffixIcon={null}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonSelectProps}
                >
                    {selectItems.map(renderOption)}
                </Select>
            </Form.Item>
        </div>
    );
}
