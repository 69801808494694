import {useMutation, UseMutationResult} from '@tanstack/react-query';

import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {companiesImportUrl} from './companies-import-const';

function getCompaniesImporDeleteUrl(id?: number): string {
    return `${rootApiUrl}${companiesImportUrl}/import_url/${id}/`;
}

async function fetchCompaniesImportDelete(id?: number): Promise<void> {
    return fetchNoContent(getCompaniesImporDeleteUrl(id), {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function useCompaniesImportDeleteMutation(id?: number): UseMutationResult<void, unknown, void> {
    return useMutation([getCompaniesImporDeleteUrl(id)], () => fetchCompaniesImportDelete(id));
}
