import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {getReviewUserTopic} from './review-user-topics-api';
import {ReviewUserTopicType} from './review-user-topics-type';

export function useReviewUserTopicRead(id: number): UseHookType<ReviewUserTopicType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewUserTopicType>();

    useEffect(() => {
        setIsInProgress(true);

        getReviewUserTopic(id)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [id, setIsInProgress, setResult, setProcessError]);

    return {isInProgress, processError, result, reset};
}
