import {Button} from 'antd';
import {useCallback, useEffect, useMemo} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../service/feature-companies/feature-companies-type';
import {usePostsFacebookPages} from '../../../../../../service/posts/posts-hooks';
import {
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../service/posts/posts-types';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {PostContentWrapper} from '../../post-content-wrapper/post-content-wrapper';
import * as postFormStyles from '../../post-form.scss';

import {BrandsItem} from './items/brands/brands-item';
import {CompaniesSelectItem} from './items/companies/companies-select-item';
import {FacebookPagesItem} from './items/facebook-pages/facebook-pages-item';
import {InstagramPagesItem} from './items/instagram-pages/instagram-pages-item';
import {PostNameItem} from './items/post-name/post-name-item';
import {PostSourcesItem} from './items/sources/post-sources-item';
import {VkGroupsItem} from './items/vk-groups/vk-groups-item';
import {
    clearHiddenPostFormTargetStepFields,
    getFbPageOrCompanySelectedRule,
    getIsBrandSelectRequired,
    getIsCompanySelectorRequired,
    getIsCompanySelectorVisible,
    getIsFacebookPagesItemAvailable,
    getIsInstagramPagesItemAvailable,
    getIsVkPagesItemAvailable,
} from './post-target-step-helper';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    formMode: PostFormModeEnum;
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
    onStepFinished: () => void;
    onSourcesChange: () => void;
};

export function PostTargetStep(props: PropsType): JSX.Element {
    const {formInstance, companiesSelector, onStepFinished, formMode, onSourcesChange} = props;

    const {selectedCount} = companiesSelector;

    const stepDisabled = formMode === PostFormModeEnum.View || formMode === PostFormModeEnum.EditExisted;
    const isCompanySelectorDisabled = formMode === PostFormModeEnum.View;

    const {getLocalizedString} = useLocale();
    const posts = Form.useWatch<PostFormFieldsEnum.Posts, FormInstance<PostFormType>>(
        PostFormFieldsEnum.Posts,
        formInstance
    );

    const sources: Array<PostSourceEnum> = useMemo(() => {
        return (
            posts?.map((post) => {
                return post[SourcePostFormFieldsEnum.Catalog];
            }) || []
        );
    }, [posts]);

    useEffect(() => {
        onSourcesChange();
    }, [onSourcesChange, sources]);

    const fbPages = Form.useWatch<PostFormFieldsEnum.FbPagesIds, FormInstance<PostFormType>>(
        PostFormFieldsEnum.FbPagesIds,
        formInstance
    ) as PostFormType[PostFormFieldsEnum.FbPagesIds] | null;

    const {data: availableFbPages, isLoading: isLoadingFacebookPages} = usePostsFacebookPages();

    const isCompanySelectVisible = useMemo(() => getIsCompanySelectorVisible(sources), [sources]);

    const postName: string | null =
        Form.useWatch<PostFormFieldsEnum.Name, FormInstance<PostFormType>>(PostFormFieldsEnum.Name, formInstance) ||
        null;

    useEffect(() => {
        if (typeof postName === 'string') {
            clearHiddenPostFormTargetStepFields(formInstance, companiesSelector);
        }
    });

    const isCompanySelectorRequired = useMemo(() => getIsCompanySelectorRequired(sources || []), [sources]);

    const isBrandSelectRequired = useMemo(
        () => getIsBrandSelectRequired(sources || [], companiesSelector),
        [companiesSelector, sources]
    );

    const fbPageOrCompanySelectedRule = useMemo(() => {
        return getFbPageOrCompanySelectedRule({
            enabled: isCompanySelectVisible && !isCompanySelectorRequired,
            selectedCompaniesCount: selectedCount,
            fbPagesCount: fbPages?.length || 0,
            errorMessage: getLocalizedString('POSTS_VALIDATION_ERROR__SELECT_COMPANY_OR_PAGE'),
        });
    }, [selectedCount, fbPages?.length, getLocalizedString, isCompanySelectVisible, isCompanySelectorRequired]);

    const handleStepFinish = useCallback(async () => {
        if (!stepDisabled) {
            await formInstance.validateFields();
        }

        onStepFinished();
    }, [stepDisabled, onStepFinished, formInstance]);

    return (
        <div className={postFormStyles.PostForm_step}>
            <div className={postFormStyles.PostForm_stepContent}>
                <PostContentWrapper>
                    <PostContentWrapper.Left>
                        <PostNameItem disabled={stepDisabled} />

                        <PostSourcesItem disabled={stepDisabled} formInstance={formInstance} />

                        {isCompanySelectVisible && (
                            <CompaniesSelectItem
                                companiesSelector={companiesSelector}
                                dependencies={[PostFormFieldsEnum.FbPagesIds]}
                                disabled={isCompanySelectorDisabled}
                                extraRules={!isCompanySelectorRequired ? [fbPageOrCompanySelectedRule] : []}
                                formInstance={formInstance}
                                required={isCompanySelectorRequired}
                            />
                        )}

                        {getIsVkPagesItemAvailable(sources) && <VkGroupsItem disabled={stepDisabled} />}

                        {getIsFacebookPagesItemAvailable(sources) && (
                            <FacebookPagesItem
                                disabled={stepDisabled}
                                isLoading={isLoadingFacebookPages}
                                pages={availableFbPages}
                                rules={!isCompanySelectorRequired ? [fbPageOrCompanySelectedRule] : []}
                            />
                        )}

                        {getIsInstagramPagesItemAvailable(sources) && <InstagramPagesItem disabled={stepDisabled} />}

                        <BrandsItem
                            disabled={stepDisabled}
                            formInstance={formInstance}
                            required={isBrandSelectRequired}
                        />
                    </PostContentWrapper.Left>
                </PostContentWrapper>
            </div>

            <div>
                <Button onClick={handleStepFinish} type="primary">
                    <Locale stringKey="TEXT__NEXT" />
                </Button>
            </div>
        </div>
    );
}
