import {useState} from 'react';

import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';

import {UsersHeader} from './header/users-header';
import {UsersTable} from './table/users-table';

export function Users(): JSX.Element {
    const [isUserAddButtonPrimary, setIsUserAddButtonPrimary] = useState(true);

    return (
        <Page isTopFilterHidden renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <UsersHeader isUserAddButtonPrimary={isUserAddButtonPrimary} />

            <UsersTable setIsUserAddButtonPrimary={setIsUserAddButtonPrimary} />
        </Page>
    );
}
