import {CentrifugeCompaniesReportNotifications} from '../../page/main/my-companies/companies-report/companies-report-notification/companies-report-notification';

import {CentrifugeDeployNotifications} from './deploy/centrifuge-deploy-notifications';
import {CentrifugeOmnichannelNotifications} from './omnichannel/centrifuge-omnichannel-notifications';

type PropsType = {
    onDeployActionReceived: VoidFunction;
};

export function CentrifugeListenersAuthorized(props: PropsType): JSX.Element {
    const {onDeployActionReceived} = props;

    return (
        <>
            <CentrifugeDeployNotifications onDeployActionReceived={onDeployActionReceived} />
            <CentrifugeOmnichannelNotifications />
            <CentrifugeCompaniesReportNotifications />
        </>
    );
}
