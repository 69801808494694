import {Tabs} from 'antd';
import {Dispatch, SetStateAction} from 'react';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    GoogleCompanyWithCandidatesType,
    GoogleLocationSourceEnum,
} from '../../../../../../../../service/google/google-type';

import {LocationModalTab} from './tab/location-modal-tab';
import * as styles from './location-modal-tabs.scss';

type PropsType = {
    locations?: GoogleCompanyWithCandidatesType['candidates'];
    selectedLocationId: string | null;
    setSelectedLocationId: Dispatch<SetStateAction<string | null>>;
};

export function LocationModalTabs(props: PropsType): JSX.Element {
    const {locations, selectedLocationId, setSelectedLocationId} = props;

    const isAccountTab = locations && locations.source === GoogleLocationSourceEnum.Account;

    return (
        <Tabs
            className={styles.LocationModalTabs}
            items={[
                {
                    key: isAccountTab ? GoogleLocationSourceEnum.Account : GoogleLocationSourceEnum.Maps,
                    label: (
                        <div className={styles.LocationModalTabs_label}>
                            <Locale
                                stringKey={
                                    isAccountTab
                                        ? 'GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__LIST_HEADER__ACCOUNT'
                                        : 'GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__LIST_HEADER__MAPS'
                                }
                                valueMap={{count: locations?.data.length ?? 0}}
                            />
                        </div>
                    ),
                    children: (
                        <LocationModalTab
                            locations={locations ?? {source: GoogleLocationSourceEnum.Maps, data: []}}
                            selectedLocationId={selectedLocationId}
                            setSelectedLocationId={setSelectedLocationId}
                        />
                    ),
                },
            ]}
        />
    );
}
