import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

export type ReviewFormSourcesType = {
    id: number;
    name: string;
    logo: string;
};

const reviewFormSourcesUrl = `${rootApiUrl}/v4/reviews/custom_catalogs/`;

function fetchReviewFormSources(): Promise<Array<ReviewFormSourcesType>> {
    return fetchX<Array<ReviewFormSourcesType>>(reviewFormSourcesUrl);
}

export function useReviewFormSources(): UseQueryResult<Array<ReviewFormSourcesType>> {
    return useQuery([reviewFormSourcesUrl], fetchReviewFormSources, {
        refetchOnWindowFocus: false,
    });
}
