import {appRoute} from '../../../../app-route';
import {ReviewsGeneratorActivation} from '../../../../page/main/reviews-generator/pages/reviews-generator-activation';
import {ReviewsGeneratorIndex} from '../../../../page/main/reviews-generator/reviews-generator-index';
import {useLicenses} from '../../../../provider/license/license-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {useReviewGeneratorMailingLicensesList} from '../../../../service/reviews-generator/review-generator-mailing';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {isNpsFeatureEnabled} from '../../../../util/feature-toggle/feature-toggle';
import {NavigationItemConfigType} from '../routing-type';

import {ReactComponent as MobileStar} from './images/mobile-star.svg';

export function useReviewsGeneratorNavigation(): Array<NavigationItemConfigType> {
    const {user} = useUser();
    const {licenses, availableFeatures} = useLicenses();
    const {data: ReviewGeneratorMailingLicenses} = useReviewGeneratorMailingLicensesList();

    const reviewsGeneratorLicenses = licenses[FeaturesEnum.reviewsGenerator]?.isActive;
    const mailingListLicence =
        licenses[FeaturesEnum.mailingListLicence]?.isActive || ReviewGeneratorMailingLicenses?.length !== 0;

    const isMailingsAvailable = availableFeatures[FeaturesEnum.mailingListLicence] && !user?.isDoubleGisUser;

    return [
        {
            isAvailable: availableFeatures[FeaturesEnum.reviewsGenerator],
            isActivated: reviewsGeneratorLicenses || mailingListLicence,
            path: appRoute.reviewsGenerator.path,
            icon: <MobileStar />,
            labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR',
            exact: false,
            redirectPath: appRoute.reviewsGeneratorMailings.path,
            deniedPath: [appRoute.reviewsGenerator.path],
            component:
                reviewsGeneratorLicenses || mailingListLicence ? ReviewsGeneratorIndex : ReviewsGeneratorActivation,
            // todo: остальные роуты находятся в родительском компоненте ReviewsGeneratorIndex
            // todo: think about replace components to here
            children: [
                {
                    isAvailable: isMailingsAvailable,
                    isActivated: isMailingsAvailable && mailingListLicence,
                    path: appRoute.reviewsGeneratorMailings.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__MAILINGS',
                },
                {
                    isAvailable: isMailingsAvailable,
                    isActivated: isMailingsAvailable && mailingListLicence,
                    path: appRoute.reviewsGeneratorClients.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__CLIENTS',
                },
                {
                    path: appRoute.reviewsGeneratorNegativeReviews.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__NEGATIVE_REVIEWS',
                },
                {
                    isAvailable: isNpsFeatureEnabled(),
                    showBeta: true,
                    path: appRoute.reviewsGeneratorNps.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__NPS',
                },
                {
                    isActivated: reviewsGeneratorLicenses,
                    path: appRoute.reviewsGeneratorQrCode.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__QRCODE',
                },
                {
                    isActivated: reviewsGeneratorLicenses,
                    path: appRoute.reviewsGeneratorAnalytics.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__ANALYTICS',
                },
                {
                    path: appRoute.reviewsGeneratorPromoCodesList.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__PROMO_CODES',
                },
                {
                    path: appRoute.reviewsGeneratorQuestionnaireList.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_GENERATOR__QUESTIONNAIRE',
                },
            ],
        },
    ];
}
