import {Context, createContext, PropsWithChildren, useMemo} from 'react';

import {useReviewsCatalogList} from '../../service/reviews/reviews-catalogs';

import {CatalogsContextType} from './catalogs-context-type';
import {useFilteredCatalogs} from './catalogs-hook';

export const CatalogsContext: Context<CatalogsContextType> = createContext<CatalogsContextType>({
    isLoading: true,
    catalogs: [],
    reviewsCatalogs: [],
});

type PropsType = PropsWithChildren<Record<string, unknown>>;

export function CatalogsContextProvider(props: PropsType): JSX.Element {
    const {children} = props;

    const {isInProgress, result: catalogs} = useFilteredCatalogs();

    const {data: reviewsCatalogList = null, isFetching: reviewsCatalogListIsInProgress} = useReviewsCatalogList();

    const providerData: CatalogsContextType = useMemo(() => {
        return {
            isLoading: isInProgress || reviewsCatalogListIsInProgress,
            catalogs: catalogs || [],
            reviewsCatalogs: reviewsCatalogList || [],
        };
    }, [catalogs, reviewsCatalogList, reviewsCatalogListIsInProgress, isInProgress]);

    return <CatalogsContext.Provider value={providerData}>{children}</CatalogsContext.Provider>;
}
