import {Button} from 'antd';
import {ReactNode} from 'react';

import {CatalogConfigType} from '../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../provider/locale/locale';
import {useModal} from '../../../../../provider/modal/modal-hook';

import * as styles from './catalog-list.scss';

type CatalogConfigWithIndexType = CatalogConfigType & {
    index: number; // needed for ant form array access
};

type ChildrenPropsType = {
    openRemovalModal: () => void;
};

type CommonPropsType = {
    catalog: CatalogConfigWithIndexType;
    modalFieldName: string;
    footerButtonVisible?: boolean;
    footerButtonText?: JSX.Element;
    modalTitle?: JSX.Element;
    modalContent?: JSX.Element;
    onRemove?: (index: number) => void;
};

type PropsType =
    | (CommonPropsType & {
          children: ReactNode;
          canOpenRemovalModal?: false;
      })
    | (CommonPropsType & {
          children: (childrenProps: ChildrenPropsType) => ReactNode;
          canOpenRemovalModal: true;
      });

export function CatalogTab(props: PropsType): JSX.Element {
    const {
        children,
        canOpenRemovalModal,
        catalog,
        modalFieldName,
        footerButtonVisible = true,
        footerButtonText,
        modalTitle,
        modalContent,
        onRemove,
    } = props;

    const {modal} = useModal();

    function openRemovalModal(onOk: () => void) {
        modal.confirm({
            maskClosable: true,
            type: 'confirm',
            title: modalTitle || <Locale stringKey="COMPANY_FORM__CATALOG_LIST__MODAL__TITLE" />,
            content: modalContent || (
                <Locale
                    stringKey="COMPANY_FORM__CATALOG_LIST__MODAL__CONTENT"
                    valueMap={{
                        catalogName: catalog.label,
                        fieldName: modalFieldName,
                    }}
                />
            ),
            okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            onOk,
        });
    }

    return (
        <>
            {canOpenRemovalModal
                ? children({openRemovalModal: () => openRemovalModal(() => onRemove?.(catalog.index))})
                : children}

            <footer className={styles.catalog_list__footer}>
                <div className={styles.catalog_list__footer_description}>
                    <Locale
                        stringKey="COMPANY_FORM__CATALOG_LIST__CATALOG_DESCRIPTION"
                        valueMap={{catalogName: catalog.label}}
                    />
                </div>

                {footerButtonVisible && (
                    <Button onClick={() => openRemovalModal(() => onRemove?.(catalog.index))} type="text">
                        {footerButtonText || <Locale stringKey="COMPANY_FORM__CATALOG_LIST__FOOTER__BUTTON" />}
                    </Button>
                )}
            </footer>
        </>
    );
}
