import {Steps} from 'antd';
import {StepsProps} from 'antd/es/steps';

import {PageCard} from '../../../layout/page-card/page-card';

import * as styles from './landing-onboarding-step.scss';

type PropsType = Omit<StepsProps, 'items' | 'children' | 'current'> & {
    steps: Array<JSX.Element>;
    current: number;
    stepDisabled?: (stepIndex: number) => boolean;
};

export function LandingOnboardingPageCard(props: PropsType): JSX.Element {
    const {steps, current, stepDisabled, ...restProps} = props;

    return (
        <PageCard
            bodyClassName={styles.LandingOnboardingStep_stepsCardBody}
            className={styles.LandingOnboardingStep_stepsCard}
        >
            <Steps
                className={styles.LandingOnboardingStep_stepsControls}
                current={current}
                items={steps.map((_value, index) => ({
                    disabled: stepDisabled ? stepDisabled(index) : index > current,
                    key: index,
                    title: '',
                }))}
                responsive={false}
                size="default"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restProps}
            />
            {steps[current]}
        </PageCard>
    );
}
