import {Input} from 'antd';

import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {Form} from '../../../../../../../typings/antd';

import * as styles from './lat-lng-input.scss';

type PropsType = {
    value?: Array<string>;
    onChange?: (value: Array<string>) => void;
    disabled?: boolean;
};

export function LatLngInput(props: PropsType): JSX.Element {
    const {value, onChange, disabled} = props;

    const {getLocalizedString} = useLocale();

    return (
        <div className={styles.lat_lng_input__wrapper}>
            <Form.Item
                className={styles.lat_lng_input__input}
                label={<Locale stringKey="COMPANY_FORM__ADDRESS__LATITUDE__LABEL" />}
                required
            >
                <Input
                    disabled={disabled}
                    onChange={(event) => {
                        const eventValue = event.target.value;

                        // We check if the entered string is a number (even with floating point), and if it is, we write the new value
                        if (Number(eventValue) || eventValue === '') {
                            onChange?.([eventValue, value?.[1] ?? '']);
                        }
                    }}
                    placeholder={getLocalizedString('COMPANY_FORM__ADDRESS__LATITUDE__PLACEHOLDER')}
                    size="large"
                    value={value?.[0]}
                />
            </Form.Item>

            <Form.Item
                className={styles.lat_lng_input__input}
                label={<Locale stringKey="COMPANY_FORM__ADDRESS__LONGITUDE__LABEL" />}
                required
            >
                <Input
                    disabled={disabled}
                    onChange={(event) => {
                        const eventValue = event.target.value;

                        // We check if the entered string is a number (even with floating point), and if it is, we write the new value
                        if (Number(eventValue) || eventValue === '') {
                            onChange?.([value?.[0] ?? '', eventValue]);
                        }
                    }}
                    placeholder={getLocalizedString('COMPANY_FORM__ADDRESS__LONGITUDE__PLACEHOLDER')}
                    size="large"
                    value={value?.[1]}
                />
            </Form.Item>
        </div>
    );
}
