import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, List} from 'antd';

import {classNames} from '../../../../../../../../util/css';
import * as stepStyles from '../../finish-step.scss';
import {badgeForStatus, STATISTIC_BADGE_OVERFLOW} from '../statistic-list-const';
import {StatisticItemType} from '../statistic-list-type';

import * as styles from './statistic-list-item.scss';

export function StatisticListItem(props: StatisticItemType): JSX.Element {
    const {id, label, value, onClick} = props;

    return (
        <List.Item
            className={classNames(styles.StatisticListItem, {
                [styles.StatisticListItem__clickable]: Boolean(onClick),
            })}
            onClick={onClick}
        >
            <div>
                {label}

                {onClick && <FontAwesomeIcon className={styles.StatisticListItem_arrowRight} icon={faArrowRight} />}
            </div>

            <div className={styles.StatisticListItem_badgeWrapper}>
                <Badge
                    className={classNames(stepStyles.Badge, badgeForStatus[id] ?? stepStyles.Badge__blue)}
                    count={value}
                    overflowCount={STATISTIC_BADGE_OVERFLOW}
                />
            </div>
        </List.Item>
    );
}
