import {useContext, useMemo} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {WidgetError} from '../../../../../../component/widget-error/widget-error';
import {LineDataType} from '../../../../../../layout/chart/line-chart/line-chart-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../provider/main-filter/main-filter-type';
import {useReviewRateCompetitors} from '../../../../../../service/reivew-analysis/review-rate/review-rate-hook';
import {useUrl} from '../../../../../../util/url-hook/url-hook';
import {getAggregationFromUrl} from '../../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {ReviewsAnalysisFilterEnum} from '../../../reviews-analysis-type';
import {TopicLineChart} from '../topic-line-chart/topic-line-chart';

export function TopicRatingChart(): JSX.Element {
    const {singleFilter} = useReviewsAnalysisFilter();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();
    const {queries} = useUrl();

    const ratingOptions = useMemo(
        () => ({
            ...singleFilter,
            userTopics: [id],
            groupBy: getAggregationFromUrl(queries[ReviewsAnalysisFilterEnum.Aggregation]),
        }),
        [id, queries, singleFilter]
    );

    const {result, isInProgress, processError} = useReviewRateCompetitors(ratingOptions, mainFilterKey);
    const {getLocalizedString} = useLocale();

    const lineChartData: LineDataType = useMemo(() => {
        if (!result) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const valuesWithRating = result ? result.filter((item) => item.brands[0]?.avgRating) : [];

        return {
            labels: valuesWithRating.map((item) => item.date),
            datasets: [
                {
                    values: valuesWithRating.map((item) => item.brands[0]?.avgRating || 0),
                    color: 'hsla(233, 94%, 79%, 1)',
                    label: getLocalizedString('REVIEWS_ANALYSIS__TOPIC__RATING_CHART_RATING'),
                    fill: true,
                    borderWidth: 2,
                    backgroundColor: 'hsla(233, 94%, 79%, 0.3)',
                    tension: 0.5,
                },
            ],
        };
    }, [getLocalizedString, result]);

    if (processError) {
        return <WidgetError />;
    }

    return <TopicLineChart data={lineChartData} isInProgress={isInProgress} />;
}
