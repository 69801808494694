import {isEqual} from 'lodash';

import {WeekDaysEnum} from '../../../../../../../../provider/locale/locale-context-const';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';

import {WeekdayGroupEnum, weekdays, weekDayShortNameValuesMap, weekends, workdays} from './days-group-const';

export function useDaysGroupPlaceholder(weekdaysPlaceholder: Array<WeekDaysEnum>): string {
    const {getLocalizedString} = useLocale();

    if (isEqual(weekdaysPlaceholder, weekdays)) {
        return getLocalizedString('WEEK_DAY__EVERYDAY');
    }

    return weekdaysPlaceholder
        .join('')
        .replace(workdays.join(''), WeekdayGroupEnum.Workday)
        .replace(weekends.join(''), WeekdayGroupEnum.Weekend)
        .split('') // eslint-disable-line unicorn/prefer-spread
        .map((weekday) => {
            const stringKey = weekDayShortNameValuesMap[weekday as WeekDaysEnum | WeekdayGroupEnum];

            return stringKey ? getLocalizedString(stringKey) : null;
        })
        .join(', ');
}
