import {Locale} from '../../../provider/locale/localization';

export const defaultReviewsAnalysisDaysPeriod = 30;
export const defaultPageSize = 50;

type TopicErrorMessageType = {
    code: string;
    message: JSX.Element;
};

export const topicsErrorMessagesMap: Array<TopicErrorMessageType> = [
    {
        code: 'empty_tags',
        message: <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__TAG__VALIDATION__ERROR" />,
    },
    {
        code: 'too_many_tags',
        message: <Locale stringKey="REVIEWS_ANALYSIS__TOPIC__MESSAGE_ERROR__TOO_MANY_TAGS" />,
    },
];
