import {Button} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useMemo, useState} from 'react';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks, useVideoLinks} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {SyncCompaniesStatusEnum, SyncCompaniesType} from '../../../../../provider/onboarding/onboarding-hook';
import {useCclData} from '../../../../../service/ccl/ccl-hook';
import {CclStatusEnum} from '../../../../../service/ccl-statistic/ccl-statistic-type';
import {ProvidersEnum, ProvidersIdsEnum} from '../../../../../util/type';
import * as styles from '../../onboarding.scss';
import {OnboardingCatalogsNameEnum, OnboardingStepEnum, onboardingSuffix} from '../../onboarding-const';
import {MappedDataType} from '../../onboarding-type';
import {SyncDoubleGisAccountPopup} from '../../sync-doublegis-account-popup/sync-doublegis-account-popup';
import {OnboardingStep} from '../step';

type PropsType = {
    title: ReactElement;
    isOpenStep: boolean;
    stepIndex: OnboardingStepEnum;
    syncCompanies: SyncCompaniesType;
    refetch: () => void;
    setIsOpenAutoOnboardingPopup?: Dispatch<SetStateAction<boolean>>;
    active?: boolean;
};

export function SyncCompaniesStep(props: PropsType): ReactElement {
    const {isOpenStep, stepIndex, title, active, syncCompanies, refetch, setIsOpenAutoOnboardingPopup} = props;
    const {onboarding} = useDocumentationLinks();
    const {onboarding: onboardingVideos} = useVideoLinks();
    const {companyName} = useDomainConfig();

    const facebookCatalogArray = useMemo(() => [`${ProvidersIdsEnum.facebook}`], []);

    const cclData = useCclData('', 'map', CclStatusEnum.actionNeeded, false, facebookCatalogArray);
    const facebookBrandId = cclData.result?.results[0]?.brand.id;
    const facebookCompanyId = cclData.result?.results[0]?.company.id;

    const [openSyncTwoGisPopup, setOpenSyncTwoGisPopup] = useState<boolean>(false);

    const availableSyncCompaniesCatalogs = Object.keys(syncCompanies || {});

    const isInProgress =
        syncCompanies.google?.status === SyncCompaniesStatusEnum.InProgress ||
        syncCompanies.facebook?.status === SyncCompaniesStatusEnum.InProgress;

    const inProgressMappedData: Array<MappedDataType> = [
        {
            description: 'ONBOARDING__THIRD_STEP__IN_PROGRESS',
            catalogStatus: SyncCompaniesStatusEnum.InProgress,
            documentationLink: '',
            button: null,
            videoLink: '',
        },
    ];

    const syncCompaniesMappedData: Array<MappedDataType> = [
        {
            providerName: ProvidersEnum.google,
            blockTitle: OnboardingCatalogsNameEnum.Google,
            description: 'ONBOARDING__THIRD_STEP__GOOGLE__DESCRIPTION' as LangKeyType,
            completed: syncCompanies?.google?.synced,
            catalogStatus: syncCompanies.google?.status,
            button: (
                <NavigationLink
                    className={styles.Onboarding_successButton}
                    to={appRoute.sourcesAccountSyncGoogle.path + onboardingSuffix}
                >
                    <Button className={styles.Onboarding_successButton} size="middle" type="primary">
                        <Locale stringKey="ONBOARDING__THIRD_STEP__CATALOGS_BUTTON" />
                    </Button>
                </NavigationLink>
            ),
            documentationLink: onboarding.syncGoogleCompanies,
            videoLink: onboardingVideos.syncGoogleCompanies,
        },
        {
            providerName: ProvidersEnum.facebook,
            description: 'ONBOARDING__THIRD_STEP__FACEBOOK__DESCRIPTION' as LangKeyType,
            completed: syncCompanies?.facebook?.synced,
            valueMap: companyName,
            blockTitle: OnboardingCatalogsNameEnum.Facebook,
            catalogStatus: syncCompanies.facebook?.status,
            button: (
                <NavigationLink
                    className={styles.Onboarding_successButton}
                    to={
                        generatePath(appRoute.accountsMatching.path, {
                            providerId: ProvidersIdsEnum.facebook,
                            companyId: `${facebookCompanyId}`,
                            brandId: `${facebookBrandId}`,
                        }) + onboardingSuffix
                    }
                >
                    <Button className={styles.Onboarding_successButton} size="middle" type="primary">
                        <Locale stringKey="ONBOARDING__THIRD_STEP__CATALOGS_BUTTON" />
                    </Button>
                </NavigationLink>
            ),
            documentationLink: onboarding.syncFacebookCompanies,
            videoLink: onboardingVideos.syncFacebookCompanies,
        },
    ].filter((catalogStep) => availableSyncCompaniesCatalogs.includes(catalogStep.providerName));

    return (
        <>
            <OnboardingStep
                active={active}
                isOpenStep={isOpenStep}
                key={stepIndex}
                mappedData={isInProgress ? inProgressMappedData : syncCompaniesMappedData}
                setIsOpenAutoOnboardingPopup={setIsOpenAutoOnboardingPopup}
                stepIndex={stepIndex}
                title={title}
            />
            {openSyncTwoGisPopup && (
                <SyncDoubleGisAccountPopup
                    onClose={() => {
                        setOpenSyncTwoGisPopup(false);
                        refetch();
                    }}
                />
            )}
        </>
    );
}
