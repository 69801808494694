import {
    faComment,
    faFaceFrownSlight,
    faFaceMeh,
    faFaceSmile,
    faStarHalfStroke,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ReviewRatingTypeEnum} from '../../../../../../service/reviews/reviews-type';
import {NeverError} from '../../../../../../util/error';

import {BigRadioButtonDataType, BigRadioButtonNameEnum} from './big-radio-button-type';
import * as styles from './big-radio-button.scss';

export function getButtonDataByType(type: BigRadioButtonNameEnum | ReviewRatingTypeEnum): BigRadioButtonDataType {
    switch (type) {
        case ReviewRatingTypeEnum.negative:
            return {
                titleKey: 'REVIEWS__RADIO__NEGATIVE',
                icon: <FontAwesomeIcon icon={faFaceFrownSlight} />,
                className: styles.BigRadioButton_negative,
            };
        case ReviewRatingTypeEnum.notRated:
            return {
                titleKey: 'REVIEWS__RADIO__NOT_RATED',
                icon: <FontAwesomeIcon icon={faFaceMeh} />,
                className: styles.BigRadioButton_notRated,
            };
        case ReviewRatingTypeEnum.positive:
            return {
                titleKey: 'REVIEWS__RADIO__POSITIVE',
                icon: <FontAwesomeIcon icon={faFaceSmile} />,
                className: styles.BigRadioButton_positive,
            };
        case ReviewRatingTypeEnum.allRating:
            return {
                titleKey: 'REVIEWS__RADIO__TOTAL_REVIEWS',
                icon: <FontAwesomeIcon icon={faComment} />,
                className: styles.BigRadioButton_totalReviews,
            };
        case BigRadioButtonNameEnum.averageRating:
            return {
                titleKey: 'REVIEWS__RADIO__AVERAGE_RATING',
                icon: <FontAwesomeIcon icon={faStarHalfStroke} />,
                className: styles.BigRadioButton_averageRating,
            };
        default:
            throw new NeverError(type);
    }
}
