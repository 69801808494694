import {FormattedNumber} from '../../provider/locale/formatted-number';
import {RatingStar} from '../rating-star-line/rating-star/rating-star';

import * as styles from './rating-inline.scss';

type RatingInlinePropsType = {
    ratingValue: number;
    height?: number;
    width?: number;
    fillPercent?: number;
    starClassName?: string;
    isDecimal?: boolean;
    maxRating?: number;
};

export function RatingInline(props: RatingInlinePropsType): JSX.Element {
    const {ratingValue, width, height, fillPercent, starClassName, isDecimal, maxRating = 10} = props;

    return (
        <div className={styles.RatingInline}>
            <RatingStar
                className={starClassName || styles.RatingInline_image}
                fillPercent={fillPercent || 0}
                height={height || 24}
                width={width || 24}
            />
            {isDecimal ? (
                <div className={styles.RatingInline_decimal}>
                    <span className={styles.RatingInline_decimalValue}>{ratingValue}</span> / {maxRating}
                </div>
            ) : (
                <div className={styles.RatingInline_value}>
                    <FormattedNumber value={ratingValue} />
                </div>
            )}
        </div>
    );
}
