import {init as sentryInit} from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {extend} from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import firebase from 'firebase/app';

import {firebaseConfig} from './const';
import {enablePushNotifications} from './service/firebase/firebase-messaging';

const sentryDsn = 'https://fa06d53c11941631a59e7aa71bd250bb@sentry.rocketdata.io/50';

export function extendDayjs(): void {
    extend(customParseFormat);
    extend(isSameOrAfter);
    extend(isSameOrBefore);
    extend(duration);
    extend(minMax);
    extend(utc);
    extend(localizedFormat);
    extend(relativeTime);
    extend(timezone);
}

export function initialize(): void {
    extendDayjs();

    if (typeof IS_PRODUCTION === 'boolean' && IS_PRODUCTION) {
        sentryInit({
            dsn: sentryDsn,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1,
            environment: location.pathname,
        });

        // eslint-disable-next-line import/no-named-as-default-member
        firebase.initializeApp(firebaseConfig);

        // If we have permissions, we initialize push notifications once the app load
        // otherwise we'll wait until user click to 'Get browser notifications' checkbox
        if (window.Notification && Notification.permission === 'granted') {
            enablePushNotifications();
        }
    }
}

export function sayHi(): void {
    const {info} = console;

    const hiString = `
    _   _  _ _____ ___ ___
   /_\\ | \\| |_   _/ __| _ \\
  / _ \\| .\` | | || (__|  _/
 /_/ \\_\\_|\\_| |_| \\___|_|


`;

    info(hiString);

    if (typeof BUILD_DATE_H === 'string') {
        info('Build date:', BUILD_DATE_H);
    }
}
