import {AllCompanyType} from '../../../../service/company/company-type';

import {EditCompanyTransferType} from './company-group-form-type';

export function companyToEditCompany(company: AllCompanyType): EditCompanyTransferType {
    return {
        address: company.address.formattedFull || company.address.formattedShort,
        companyId: String(company.id),
        name: company.name,
        brandId: String(company.brand.id),
        key: String(company.id),
        code: String(company.code),
    };
}
