import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useEffect, useState} from 'react';

import {Text} from '../../component/text/text';
import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';

import * as styles from './company-popup-v2.scss';

type PropsType = {
    className?: string;
    selectorId: string;
    selectedCount: number;
    onClear: () => void;
    onReady?: () => void;
    disabled?: boolean;
    hideSubmitButton?: boolean;
};

export function CompanySelectModalFooter(props: PropsType): JSX.Element {
    const {selectedCount, onClear, onReady, disabled, hideSubmitButton, className, selectorId} = props;

    const [counterAnimationClassName, setCounterAnimationClassName] = useState<string | null>(null);

    useEffect(() => {
        if (selectorId && selectedCount) {
            setCounterAnimationClassName(styles.CompaniesSelectModal_selectedCount__animateNotEmpty);
        }
    }, [selectedCount, selectorId]);

    useEffect(() => {
        const timeoutId = setTimeout(() => setCounterAnimationClassName(null), 300);

        return () => clearTimeout(timeoutId);
    }, [counterAnimationClassName]);

    return (
        <div className={classNames(styles.CompaniesSelectModal_footer, className)}>
            <div
                className={classNames(
                    styles.CompaniesSelectModal_selectedCount,
                    {
                        [styles.CompaniesSelectModal_selectedCount__empty]: !selectedCount,
                    },
                    counterAnimationClassName
                )}
            >
                <Text block inheritColor>
                    {selectedCount ? (
                        <Locale
                            stringKey="COMPANY__MULTISELECT__SELECTED_COUNT"
                            valueMap={{
                                count: selectedCount,
                            }}
                        />
                    ) : (
                        <Locale stringKey="COMPANY__MULTISELECT__NO_COMPANIES_SELECTED" />
                    )}
                </Text>
            </div>
            <Button
                disabled={disabled || !selectedCount}
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={onClear}
                type="default"
            >
                <Text inheritColor>
                    <Locale stringKey="COMPANY__MULTISELECT__CLEAR_SELECTION" />
                </Text>
            </Button>
            {!hideSubmitButton && onReady && (
                <Button onClick={onReady} type="primary">
                    <Locale stringKey="BUTTON__FINISH" />
                </Button>
            )}
        </div>
    );
}
