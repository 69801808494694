import {Context} from 'chartjs-plugin-datalabels';

import {multiSourceChartOptions} from '../../../../../layout/chart/multisource-chart/multi-source-chart-helper';
import {
    ChartJsMultiSourceOptionsType,
    MultiSourceDataType,
} from '../../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {customReactChartTooltip} from '../../../../../layout/chart/utils/chart-helper';
import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {OnlinePresenceSummaryMetricsEnum} from '../../../../../service/company-activity-and-summary/company-activity-and-summary-type';

import {OnlinePresenceSummaryTooltip} from './tooltip/online-presence-summary-tooltip';
import * as styles from './online-presence-summary.scss';

export function getCompanyActivityAndSummaryChartOptions(options: {
    data: MultiSourceDataType;
    tickFormatter: (label: string) => string;
}): ChartJsMultiSourceOptionsType {
    const {data, tickFormatter} = options;

    return {
        ...multiSourceChartOptions,
        hover: {
            mode: 'nearest',
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    callback(_value, index: number) {
                        const rawLabel = data?.labels?.[index];

                        return rawLabel ? tickFormatter(rawLabel) : '';
                    },
                },
            },
            y: {
                stacked: true,
                ticks: {
                    maxTicksLimit: 0,
                },
                position: 'right',
            },
            // eslint-disable-next-line id-length
            y2: {
                stacked: true,
                ticks: {
                    maxTicksLimit: 0,
                },
                position: 'left',
            },
        },
        plugins: {
            ...multiSourceChartOptions.plugins,
            tooltip: {
                enabled: false,
                yAlign: 'top',
                xAlign: 'center',
                position: 'nearest',
                external: (context: Context) => {
                    return customReactChartTooltip({
                        context,
                        componentRenderer: (index) => {
                            return <OnlinePresenceSummaryTooltip currentIndex={index} data={data} />;
                        },
                    });
                },
            },
            legend: {
                display: false,
            },
        },
    };
}

export const onlinePresenceSummaryTranslationMap: Record<OnlinePresenceSummaryMetricsEnum, LangKeyType> = {
    [OnlinePresenceSummaryMetricsEnum.DirectionRequests]: 'COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__ROUTES',
    [OnlinePresenceSummaryMetricsEnum.Impressions]: 'COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__IMPRESSIONS',
    [OnlinePresenceSummaryMetricsEnum.CallClicks]: 'COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__CALLS',
    [OnlinePresenceSummaryMetricsEnum.WebsiteClicks]: 'COMPANY_ACTIVITY_AND_SUMMARY__ONLINE_PRESENCE__SITE',
};

export const onlinePresenceSummaryColorMap: Record<OnlinePresenceSummaryMetricsEnum, string> = {
    [OnlinePresenceSummaryMetricsEnum.DirectionRequests]: styles.DirectionRequests_chartColor,
    [OnlinePresenceSummaryMetricsEnum.Impressions]: styles.Impressions_chartColor,
    [OnlinePresenceSummaryMetricsEnum.CallClicks]: styles.CallClicks_chartColor,
    [OnlinePresenceSummaryMetricsEnum.WebsiteClicks]: styles.WebsiteClicks_chartColor,
};
