import {Fragment} from 'react';

import {ReviewDataChildType} from '../../../../../service/reviews/reviews-type';
import {classNames} from '../../../../../util/css';
import {AnswerItem} from '../../review-list-page-content/review-list/review/answer-item/answer-item';
import * as reviewAnswersStyles from '../review-answers.scss';

type PropsType = {
    answerList: Array<ReviewDataChildType>;
    reviewId: number;
    createdInCatalog: string;
    isTestBrand: boolean;
    refreshReview: (reviewId: number) => Promise<unknown>;
    isFirst: boolean;
    replyTimeInCatalog: number;
    replyTimeInRd: number;
    isOnlyLastAnswer?: boolean;
};

export function AnswersBunch(props: PropsType): JSX.Element | null {
    const {
        answerList,
        isTestBrand,
        reviewId,
        createdInCatalog,
        refreshReview,
        isFirst,
        isOnlyLastAnswer,
        replyTimeInCatalog,
        replyTimeInRd,
    } = props;

    if (answerList.length > 0 && isOnlyLastAnswer) {
        const officialAnswers: Array<ReviewDataChildType> = [];

        (function getAnswers(answers: Array<ReviewDataChildType>): void {
            answers.forEach((answer) => {
                if (answer.children && answer.children.length > 0) {
                    getAnswers(answer.children);
                }

                if (answer.isCompanyComment) {
                    officialAnswers.push(answer);
                }
            });
        })(answerList);

        const officialAnswersWithDate = officialAnswers.filter((answer) => answer.createdInCatalog);

        if (officialAnswersWithDate.length > 0) {
            officialAnswersWithDate.sort(
                (itemA: ReviewDataChildType, itemB: ReviewDataChildType) =>
                    new Date(itemB.createdInCatalog || '').getTime() - new Date(itemA.createdInCatalog || '').getTime()
            );

            const lastAnswer = officialAnswers[0];

            return lastAnswer ? (
                <li className={reviewAnswersStyles.review__answer_list_item}>
                    <AnswerItem
                        answer={lastAnswer}
                        isDialog={!isFirst}
                        isTestBrand={isTestBrand}
                        refreshReview={refreshReview}
                        replyTimeInCatalog={replyTimeInCatalog}
                        replyTimeInRd={replyTimeInRd}
                        reviewId={reviewId}
                    />
                </li>
            ) : null;
        }

        if (officialAnswers[0]) {
            return (
                <li className={reviewAnswersStyles.review__answer_list_item}>
                    <AnswerItem
                        answer={officialAnswers[0]}
                        isDialog={!isFirst}
                        isTestBrand={isTestBrand}
                        refreshReview={refreshReview}
                        replyTimeInCatalog={replyTimeInCatalog}
                        replyTimeInRd={replyTimeInRd}
                        reviewId={reviewId}
                    />
                </li>
            );
        }

        return null;
    }

    return (
        <>
            {answerList.map((answerData: ReviewDataChildType) => (
                <Fragment key={`${answerData.replyId}-${answerData.commandId}`}>
                    <li className={reviewAnswersStyles.review__answer_list_item}>
                        <AnswerItem
                            answer={answerData}
                            isDialog={!isFirst}
                            isTestBrand={isTestBrand}
                            refreshReview={refreshReview}
                            replyTimeInCatalog={replyTimeInCatalog}
                            replyTimeInRd={replyTimeInRd}
                            reviewId={reviewId}
                        />
                    </li>

                    {answerData.children.length > 0 && (
                        <ul
                            className={classNames(
                                reviewAnswersStyles.review__answer_list,
                                reviewAnswersStyles.review__answer_list__nested
                            )}
                        >
                            <AnswersBunch
                                answerList={answerData.children}
                                createdInCatalog={createdInCatalog}
                                isFirst={false}
                                isTestBrand={isTestBrand}
                                refreshReview={refreshReview}
                                replyTimeInCatalog={replyTimeInCatalog}
                                replyTimeInRd={replyTimeInRd}
                                reviewId={reviewId}
                            />
                        </ul>
                    )}
                </Fragment>
            ))}
        </>
    );
}
