import {z as r} from 'zod';

import {ReviewsAnalysisModeEnum} from '../reivew-analysis-const';
import {ReviewRateEnum} from '../review-rate/review-rate-type';

export enum SentimentEnum {
    Negative = 'negative',
    Unspecified = 'unspecified',
    Neutral = 'neutral',
    Positive = 'positive',
}

export const sentimentDataSchema = r.object({
    [SentimentEnum.Negative]: r.number(),
    [SentimentEnum.Unspecified]: r.number(),
    [SentimentEnum.Neutral]: r.number(),
    [SentimentEnum.Positive]: r.number(),
});

export type SentimentDataType = r.infer<typeof sentimentDataSchema>;

export const reviewAnalysisSentimentSchema = sentimentDataSchema.merge(
    r.object({
        tonalityIndex: r.number().nullable(),
        tonalityIndexThreshold: r.number(),
    })
);

export type ReviewAnalysisSentimentResponseType = r.infer<typeof reviewAnalysisSentimentSchema>;

const reviewAnalysisSentimentByTime = sentimentDataSchema.merge(
    r.object({
        date: r.string(),
    })
);

export type SentimentByTimeDataType = r.infer<typeof reviewAnalysisSentimentByTime>;

export type SentimentByTimeResponseType = Array<SentimentByTimeDataType>;

export const reviewAnalysisSentimentByTimeSchema = r.array(reviewAnalysisSentimentByTime);

export enum SentimentByTimeGroupByEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month',
}

export type ReviewsAnalysisSentimentQueryType = {
    mode?: ReviewsAnalysisModeEnum;
    brandIds?: Array<number>;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    userTopics?: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};

export type RequestSentimentByTimeQueryType = ReviewsAnalysisSentimentQueryType & {
    groupBy: SentimentByTimeGroupByEnum;
};
