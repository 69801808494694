import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Space} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FakesChangeMutateType} from '../../../../../../../service/fakes/fakes-change';
import {FakesResultType} from '../../../../../../../service/fakes/fakes-results';
import {FakeInternalStatusEnum} from '../../../../../../../service/fakes/fakes-type';
import * as styles from '../actions-column.scss';

type PropsType = Pick<FakesResultType, 'internalStatus' | 'isDuplicate'> & {
    mutate: FakesChangeMutateType;
};

export function ActionsColumnHidden(props: PropsType): JSX.Element {
    const {internalStatus, isDuplicate, mutate} = props;

    const menuItems = [
        {
            key: 'remove',
            label: (
                <Locale
                    stringKey={
                        isDuplicate ? 'FAKES__RESULTS__BUTTON__REMOVE_DUPLICATE' : 'FAKES__RESULTS__BUTTON__REMOVE_FAKE'
                    }
                />
            ),
            onClick: () => mutate(FakeInternalStatusEnum.SendClaim),
        },
        {
            key: 'not-mine',
            label: (
                <Locale
                    stringKey={
                        internalStatus === FakeInternalStatusEnum.ClientLocation
                            ? 'FAKES__RESULTS__BUTTON__NOT_MY_LOCATION'
                            : 'FAKES__RESULTS__BUTTON__MY_LOCATION'
                    }
                />
            ),
            onClick: () =>
                internalStatus === FakeInternalStatusEnum.ClientLocation
                    ? mutate(FakeInternalStatusEnum.NotClientLocation)
                    : mutate(FakeInternalStatusEnum.ClientLocation),
        },
    ];

    return (
        <Space className={styles.ActionColumn} direction="vertical" size={12}>
            <Text
                lighter
                stringKey={
                    internalStatus === FakeInternalStatusEnum.ClientLocation
                        ? 'FAKES__RESULTS__HIDDEN__CLIENT_LOCATION'
                        : 'FAKES__RESULTS__HIDDEN__NOT_CLIENT_LOCATION'
                }
            />

            <Dropdown menu={{items: menuItems}}>
                <Button>
                    <Space>
                        <Locale stringKey="FAKES__RESULTS__HIDDEN__BUTTON" />
                        <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    </Space>
                </Button>
            </Dropdown>
        </Space>
    );
}
