import {Button} from 'antd';
import {useState} from 'react';

import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';

import {AccountsMatchingModeEnum} from './accounts-matching-type';
import companyMatchingMultiSrc from './images/company-matching-multi.png';
import companyMatchingSingleSrc from './images/company-matching-single.png';
import {MassAccountsMatching} from './mass-accounts-matching';
import {SingleAccountMatching} from './single-account-matching';
import * as styles from './accounts-matchings.scss';

export function AccountsMatching(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const [accountsMatchingMode, setAccountsMatchingMode] = useState<AccountsMatchingModeEnum | null>(null);

    if (accountsMatchingMode === AccountsMatchingModeEnum.Single) {
        return <SingleAccountMatching />;
    }

    if (accountsMatchingMode === AccountsMatchingModeEnum.Mass) {
        return <MassAccountsMatching />;
    }

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__MY_COMPANIES')} />

            <BreadCrumbs list={[{path: '', titleLangKey: 'CATEGORY_NAME__COMPANIES_MATCHING'}]} />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__COMPANIES_MATCHING" />
            </PageHeader>

            <div className={styles.accounts_matching__type_selector__wrapper}>
                <div className={styles.accounts_matching__type_selector__option__wrapper}>
                    <img
                        alt="single company matching"
                        className={styles.accounts_matching__image}
                        src={companyMatchingSingleSrc}
                    />
                    <Button
                        className={styles.accounts_matching__type_selector__option__button}
                        onClick={() => setAccountsMatchingMode(AccountsMatchingModeEnum.Single)}
                    >
                        <Locale stringKey="COMPANY_MATCHING__SINGLE_COMPANY" />
                    </Button>
                </div>
                <div className={styles.accounts_matching__type_selector__option__wrapper}>
                    <img
                        alt="mass companies matching"
                        className={styles.accounts_matching__image}
                        src={companyMatchingMultiSrc}
                    />

                    <Button
                        className={styles.accounts_matching__type_selector__option__button}
                        onClick={() => setAccountsMatchingMode(AccountsMatchingModeEnum.Mass)}
                    >
                        <Locale stringKey="COMPANY_MATCHING__MASS" />
                    </Button>
                </div>
            </div>
        </Page>
    );
}
