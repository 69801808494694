import {Button, TablePaginationConfig} from 'antd';
import {useCallback, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {
    ChangeTagGroupRequestType,
    fetchTagChangeGroup,
    fetchTagChangeType,
    fetchTagDelete,
    TagDataType,
    useTags,
} from '../../../service/reviews/reviews-tags';
import {findInArray} from '../../../util/array';
import {toTrimmedString} from '../../../util/string';
import * as responseTemplatesTableStyles from '../response-templates/response-templates-table.scss';

import {TagsActionSelect} from './tags-action-select/tags-action-select';
import {TagsTable} from './tags-table/tags-table';

export function Tags(): JSX.Element {
    const [tagIdList, setTagIdList] = useState<Array<string>>([]);
    const [tagDataList, setTagDataList] = useState<Array<TagDataType>>([]);

    const {
        reviewsManagementTags: reviewsManagementTagsRoute,
        reviewsManagementTagsEdit: reviewsManagementTagsEditRoute,
        reviewsManagementTagCreate: reviewsManagementTagCreateRoute,
    } = appRoute;
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    const [tagGroupIdFilterList, setTagGroupIdFilterList] = useState<Array<string>>([]);
    const [withoutFolder, setWithoutFolder] = useState<true | null>(null);

    const {
        data: tagsHookResult,
        isFetching: tagsHookIsInProgress,
        refetch: refreshTags,
        pagination: {page, pageSize, total, onChange},
    } = useTags(tagGroupIdFilterList, [], withoutFolder);

    function handlePaginationChange(newPagination: TablePaginationConfig) {
        onChange(newPagination.current ?? 1, newPagination.pageSize);

        if (page !== newPagination.current) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, newPagination.current ?? 1);
        }
    }

    const handleUpdateType = useCallback(
        async (type: string) => {
            const data = {
                ids: tagIdList,
                type,
            };
            const result = await fetchTagChangeType(data);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__UPDATING_ERROR" />);
            } else {
                snackbar.success(<Locale stringKey="TAGS__UPDATING_SUCCESS" />);
                refreshTags();
            }
        },
        [tagIdList, snackbar, refreshTags]
    );

    const handleUpdateGroup = useCallback(
        async (folderId: number | null) => {
            const data: ChangeTagGroupRequestType = {
                ids: tagIdList,
            };

            if (folderId) {
                data.destination_folder_id = folderId;
            } else {
                data.without_folder = true;
            }

            const result = await fetchTagChangeGroup(data);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__UPDATING_ERROR" />);
            } else {
                snackbar.success(<Locale stringKey="TAGS__UPDATING_SUCCESS" />);
                refreshTags();
            }
        },
        [tagIdList, snackbar, refreshTags]
    );

    const handleDelete = useCallback(
        (tagData?: TagDataType) => {
            const tagTitleList: string = tagData
                ? tagData.title
                : tagIdList
                      .map((tagDataId: string): string => {
                          const tagGroup = findInArray<TagDataType>(tagDataList, {id: Number(tagDataId)});

                          return toTrimmedString(tagGroup?.title || '');
                      })
                      .filter(Boolean)
                      .map((tagDataTitle: string): string => `"${tagDataTitle}"`)
                      .join(', ');

            modal.confirm({
                maskClosable: true,
                title: <Locale stringKey="TAGS__REMOVING_A_TAG__HEADER" />,
                content: (
                    <Locale
                        stringKey="TAGS__REMOVING_A_TAG__CONTENT"
                        valueMap={{
                            tagTitle: tagTitleList,
                        }}
                    />
                ),
                okText: <Locale stringKey="TEXT__APPLY" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                cancelButtonProps: {type: 'primary'},
                okButtonProps: {type: 'default', danger: true},
                onOk: async () => {
                    const result = await fetchTagDelete({
                        ids: tagData ? tagData.id : tagIdList,
                    });

                    if (result instanceof Error) {
                        snackbar.error(<Locale stringKey="TAGS__DELETING_ERROR" />);
                    } else {
                        snackbar.success(<Locale stringKey="TAGS__DELETING_SUCCESS" />);
                        refreshTags();
                    }
                },
            });
        },
        [tagIdList, modal, tagDataList, snackbar, refreshTags]
    );

    return (
        <Page>
            <Meta title={getLocalizedString('TAGS__TAGS_FOR_REVIEWS')} />

            <BreadCrumbs list={[{path: reviewsManagementTagsRoute.path, titleLangKey: 'CATEGORY_NAME__TAGS'}]} />

            <PageHeader>
                <PageHeaderTitle productName={UsetifulNameProductEnum.TAGS} title="TAGS__TAGS_FOR_REVIEWS" />

                <div className={responseTemplatesTableStyles.buttons_container}>
                    <Button type="link">
                        <NavigationLink to={reviewsManagementTagsEditRoute.path}>
                            <Locale stringKey="BUTTON__SET_UP_GROUPS" />
                        </NavigationLink>
                    </Button>
                    <Button type="primary">
                        <NavigationLink to={reviewsManagementTagCreateRoute.path}>
                            <Locale stringKey="TAGS__ADD_TAG" />
                        </NavigationLink>
                    </Button>
                </div>
            </PageHeader>

            <PageSubHeader>
                <Locale stringKey="TAGS__LIST_OF_TAGS" />

                <TagsActionSelect
                    isActive={tagIdList.length > 0}
                    onDelete={handleDelete}
                    updateTagsGroups={handleUpdateGroup}
                    updateTagsType={handleUpdateType}
                />
            </PageSubHeader>

            <TagsTable
                onDelete={handleDelete}
                pagination={{current: page, pageSize, total}}
                setPagination={handlePaginationChange}
                setTagDataList={setTagDataList}
                setTagGroupIdFilterList={setTagGroupIdFilterList}
                setTagIdList={setTagIdList}
                setWithoutFolder={setWithoutFolder}
                tagGroupIdFilterList={tagGroupIdFilterList}
                tagsCount={tagsHookResult?.count || 0}
                tagsHookIsInProgress={tagsHookIsInProgress}
                tagsHookResult={tagsHookResult?.results || null}
                withoutFolder={withoutFolder}
            />
        </Page>
    );
}
