import {faComment} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Flex, Modal, Timeline} from 'antd';
import {TimeLineItemProps} from 'antd/es/timeline/TimelineItem';
import {ReactElement, UIEvent} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {gray7} from '../../../../../../../../css/var-export.scss';
import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {ReviewLogDataType, useReviewLogsList} from '../../../../../../../../service/reviews/reviews-logs';
import {ResponsibleUserType} from '../../../../../../../../service/reviews/reviews-responsible-users';
import {TimeSizeEnum} from '../../../../../../../../util/format';
import {useFormat} from '../../../../../../../../util/format-hook/format-hook';
import {onScrollInfiniteScrollContainer} from '../../../../../../../../util/infinite-scroll';
import {getDateTimeDifferenceHumanSize} from '../../../../../../../../util/time';
import {LogTypesEnum} from '../../../../../review-logs/review-log-type';

import {AbuseLog} from './abuse-log';
import {NoteLog} from './note-log';
import {ResponsibleUserLog} from './responsible-user-log';
import {StatusLog} from './status-log';
import * as styles from './history-modal.scss';

type PropsType = {
    onCancel: () => void;
    reviewId: number;
    reviewCreatedInCatalog: string;
    reviewAuthor: string | null;
    replyTimeInRd: number;
    replyTimeInCatalog: number;
};

export function ReviewHistoryModal(props: PropsType): ReactElement {
    const {onCancel, reviewId, reviewCreatedInCatalog, reviewAuthor, replyTimeInCatalog, replyTimeInRd} = props;
    const {companyName} = useDomainConfig();

    const {data: logs, fetchNextPage: loadMore, hasNextPage, isFetching, isLoading} = useReviewLogsList(reviewId, true);

    const logsList = logs?.pages.map((log) => log.results).flatMap((array) => [...array]) || [];
    const {getFormattedDateTime} = useFormat();
    const {localeName} = useLocale();
    const createdDateInCatalog = reviewCreatedInCatalog
        ? getFormattedDateTime(new Date(reviewCreatedInCatalog), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              [TimeSizeEnum.hour]: 'numeric',
              [TimeSizeEnum.minute]: 'numeric',
          })
        : null;

    function onPopupScroll(event: UIEvent) {
        onScrollInfiniteScrollContainer(event, () => hasNextPage && !isFetching && loadMore(), 50);
    }

    const lastChildren = [
        replyTimeInCatalog > 0 && {
            children: (
                <Flex vertical>
                    <Flex align="center" gap={4}>
                        <FontAwesomeIcon color={gray7} icon={faComment} />
                        <Text lighter stringKey="REVIEW__POST_ANSWER_IN_CATALOG" />
                    </Flex>
                    <Text
                        secondary
                        stringKey="REVIEW__RESPONSE_TIME"
                        valueMap={{
                            time: getDateTimeDifferenceHumanSize({
                                milliseconds: replyTimeInCatalog * 1000,
                                sliceSize: 2,
                                localeName,
                            }),
                        }}
                    />
                </Flex>
            ),
        },
        Boolean(replyTimeInRd) && {
            children: (
                <Flex vertical>
                    <Flex align="center" gap={4}>
                        <FontAwesomeIcon color={gray7} icon={faComment} />
                        <Text lighter stringKey="REVIEW__SENT_ANSWER_IN_CABINET" />
                    </Flex>
                    <Text
                        secondary
                        stringKey="REVIEW__RESPONSE_TIME"
                        valueMap={{
                            time:
                                replyTimeInRd < 60 ? (
                                    <Locale stringKey="REVIEWS__RESPONSE_TIME__DURING_MINUTE" />
                                ) : (
                                    getDateTimeDifferenceHumanSize({
                                        milliseconds: replyTimeInRd * 1000,
                                        sliceSize: 2,
                                        localeName,
                                    })
                                ),
                        }}
                    />
                </Flex>
            ),
        },
        {
            children: (
                <Flex vertical>
                    <Text block secondary>
                        {createdDateInCatalog}
                    </Text>
                    <Flex align="center" gap={4}>
                        <FontAwesomeIcon color={gray7} icon={faComment} />
                        {reviewAuthor ? (
                            <Text
                                lighter
                                stringKey="REVIEW_ITEM_PAGE__ACTIVITY__LOGS__CREATED__BY_USER"
                                valueMap={{
                                    user: <Text bolder>{reviewAuthor}</Text>,
                                }}
                            />
                        ) : (
                            <Text lighter stringKey="REVIEW_ITEM_PAGE__ACTIVITY__LOGS__CREATED" />
                        )}
                    </Flex>
                </Flex>
            ),
        },
    ].filter(Boolean);

    return (
        <Modal
            className={styles.HistoryModal}
            footer={[
                <Button key="close" onClick={onCancel} type="primary">
                    <Locale stringKey="POPUP__BUTTON__CLOSE" />
                </Button>,
            ]}
            loading={isLoading}
            onCancel={onCancel}
            open
            title={<Locale stringKey="REVIEW_ITEM_PAGE__ACTIVITY__LOGS" />}
            width={632}
        >
            <div className={styles.HistoryModal_listWrapper} onScroll={onPopupScroll}>
                <Timeline
                    items={[
                        ...logsList.map((log: ReviewLogDataType): TimeLineItemProps => {
                            const formattedTime = log.loggedAt
                                ? getFormattedDateTime(new Date(log.loggedAt), {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })
                                : null;
                            const formattedDate = log.loggedAt
                                ? getFormattedDateTime(new Date(log.loggedAt), {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                  })
                                : null;

                            const responsibleUserName = (() => {
                                if (log.user) {
                                    const {firstName, lastName, fullName, email} = log.user;

                                    return fullName || (firstName && lastName ? `${firstName} ${lastName}` : email);
                                }

                                return companyName.replace('presence.2gis.com', '2GIS');
                            })();

                            switch (log.type) {
                                case LogTypesEnum.Note:
                                    return {
                                        children: (
                                            <NoteLog
                                                action={log.action}
                                                formattedDate={formattedDate}
                                                formattedTime={formattedTime}
                                                userName={responsibleUserName}
                                            />
                                        ),
                                    };
                                case LogTypesEnum.ResponsibleUser:
                                    return {
                                        children: (
                                            <ResponsibleUserLog
                                                formattedDate={formattedDate}
                                                formattedTime={formattedTime}
                                                newValue={log.newValue as ResponsibleUserType}
                                                responsibleUserName={responsibleUserName}
                                            />
                                        ),
                                    };
                                case LogTypesEnum.Status:
                                    return {
                                        children: (
                                            <StatusLog
                                                formattedDate={formattedDate}
                                                formattedTime={formattedTime}
                                                newValue={String(log.newValue)}
                                                oldValue={String(log.oldValue)}
                                                responsibleUserName={responsibleUserName}
                                            />
                                        ),
                                    };
                                case LogTypesEnum.Abuse:
                                    return {
                                        children: (
                                            <AbuseLog
                                                action={log.action}
                                                formattedDate={formattedDate}
                                                formattedTime={formattedTime}
                                                userName={responsibleUserName}
                                                value={String(log.newValue)}
                                            />
                                        ),
                                    };
                                default:
                                    return {children: null};
                            }
                        }),
                        ...(logs?.pages[logs?.pages.length - 1]?.next ? [] : lastChildren),
                    ]}
                />
            </div>
        </Modal>
    );
}
