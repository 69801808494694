import {faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {faCircleInfo, faRightLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from 'antd';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
} from '../../../../../../service/yandex-stories/yandex-stories-type';
import {FormInstance} from '../../../../../../typings/antd';
import {Text} from '../../../../../text/text';

import * as styles from './yandex-story-form-slides-content-step.scss';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    activeSlideKey: string;
    onDeleteSlide: () => void;
};

export function YandexStoryHowToFindActiveSlideControls(props: PropsType): JSX.Element {
    const {activeSlideKey, onDeleteSlide, formInstance} = props;

    const activeSlide = formInstance.getFieldValue([YandexStoryFormFieldsEnum.Slides, activeSlideKey]);

    const {getLocalizedString} = useLocale();

    return (
        <div className={styles.YandexStoryFormSlidesContentStep_settingsWrapper}>
            <Alert
                className={styles.YandexStoryFormSlidesContentStep_howToFindButtonsHelper}
                description={getLocalizedString('YANDEX_STORY_FORM__SLIDES_CONTENT__NO_BUTTON_ON_HOW_TO_FIND')}
                icon={<FontAwesomeIcon icon={faCircleInfo} />}
                showIcon
                type="info"
            />

            <div className={styles.YandexStoryFormSlidesContentStep_settingsSpacer}>
                {activeSlide.status === 'error' && (
                    <Alert
                        description={getLocalizedString(
                            'YANDEX_STORY_FORM__SLIDES_CONTENT__LOADING_ERROR__DESCRIPTION'
                        )}
                        message={getLocalizedString('YANDEX_STORY_FORM__SLIDES_CONTENT__LOADING_ERROR__MESSAGE')}
                        showIcon
                        type="error"
                    />
                )}
            </div>

            <Button
                className={styles.YandexStoryFormSlidesContentStep_deleteSlideButton}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
                onClick={onDeleteSlide}
                type="text"
            >
                <Text stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__REMOVE_SLIDE" />
            </Button>

            <div className={styles.YandexStoryFormSlidesContentStep_helpSpacer}>
                <Text block stringKey="YANDEX_STORY_FORM__SLIDES_CONTENT__SORT_SLIDES" />
                <FontAwesomeIcon
                    className={styles.YandexStoryFormSlidesContentStep_helpSpacerIcon}
                    icon={faRightLeft}
                />
            </div>
        </div>
    );
}
