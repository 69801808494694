import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Tag} from 'antd';

import {AddButton} from '../../../../../../../component/add-button/add-button';
import {Text} from '../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {useBrands} from '../../../../../../../provider/brands/brand-hook';
import {useDocumentationLinks} from '../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../../provider/user/user-hook';
import {FeedResultsTypeEnum} from '../../results/feed-results-const';
import {FeedFormKeyEnum} from '../feed-form-type';

import {initialValue} from './brands-form-item-const';
import {BrandsFormItemTable} from './table/brands-form-item-table';
import * as styles from './brands-form-item.scss';

type PropsType = {
    type: FeedResultsTypeEnum;
    className?: string;
};

export function BrandsFormItem(props: PropsType): JSX.Element | null {
    const {type, className} = props;

    const {user} = useUser();
    const {brands} = useBrands();
    const documentationLinks = useDocumentationLinks();

    if (!user) {
        return null;
    }

    const documentationLink =
        type === FeedResultsTypeEnum.Validation
            ? documentationLinks.company.feedImportAndValidation
            : documentationLinks.company.feedImport;

    return (
        <div className={className}>
            <Text bolder stringKey="FEED__FORM__BRANDS__LABEL" />

            <Text block secondary>
                <Locale stringKey="FEED__FORM__BRANDS__INFO_FROM_MULTIPLE_CHOICE" />

                <AdditionalInfo
                    className={styles.BrandsFormItem_additionalInfo}
                    icon={<FontAwesomeIcon color="#8c8c8c" icon={faCircleInfo} />}
                    placement="bottom"
                    title={<Locale stringKey="FEED__FORM__BRANDS__INFO_BRAND_CODE_IN_FEED" />}
                >
                    <Locale
                        stringKey="FEED__FORM__BRANDS__INFO_DESCRIPTION"
                        valueMap={{
                            link: (
                                <a href={documentationLink} rel="noreferrer" target="_blank">
                                    <Locale stringKey="FEED__FORM__BRANDS__INFO__LINK_HELP" />
                                </a>
                            ),
                            field: <Tag className={styles.BrandsFormItem_tag}>brand_code</Tag>,
                        }}
                    />
                </AdditionalInfo>
            </Text>

            <Form.List initialValue={initialValue} name={FeedFormKeyEnum.AllowedBrands}>
                {(formListBrands, {add, remove}) => {
                    return (
                        <>
                            <BrandsFormItemTable
                                brands={formListBrands}
                                brandsList={brands}
                                remove={remove}
                                type={type}
                            />

                            {brands.length > formListBrands.length && (
                                <AddButton
                                    className={styles.BrandsFormItem_addButton}
                                    onClick={() => add({brandId: null, brandCode: ''})}
                                    size="small"
                                    type="text"
                                >
                                    <Text stringKey="FEED__FORM__BRANDS__BUTTON__ADD_BRAND" />
                                </AddButton>
                            )}
                        </>
                    );
                }}
            </Form.List>
        </div>
    );
}
