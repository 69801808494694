import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAnalysisRadarQueryType} from '../review-radar/review-radar-type';

import {reviewStatsDynamicSchema, ReviewStatsDynamicType} from './review-stats-dynamic-type';

export async function fetchReviewStatsDynamic(
    options: ReviewsAnalysisRadarQueryType,
    mainFilterKey: string
): Promise<ReviewStatsDynamicType> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisRadarQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/general-stats-dynamic/?${searchParameters}`;

    return fetchAndDeserialize<ReviewStatsDynamicType>(url, reviewStatsDynamicSchema);
}
