import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Skeleton} from 'antd';
import {useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {PageCard} from '../../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../../provider/locale/localization';
import {UseCompaniesImportValidationHookType} from '../../../../../../service/companies-import/companies-import';
import {useCompaniesImportErrorCodes} from '../../../../../../service/companies-import/companies-import-result-error-codes';
import {CompaniesImportTypeEnum} from '../../../../../../service/companies-import/companies-import-type';
import * as feedFormPageStyles from '../form-page/feed-form-page.scss';

import {FeedResultsErrorGroups} from './error-groups/feed-results-error-groups';
import {feedResultsParsingErrors, FeedResultsTypeEnum} from './feed-results-const';
import {FeedResultsImport} from './import/feed-results-import';
import {FeedResultsSummary} from './summary/feed-results-summary';
import * as styles from './feed-results.scss';

type PropsType = {
    importValidation: UseCompaniesImportValidationHookType;
    type: FeedResultsTypeEnum;
    feedFormat: CompaniesImportTypeEnum | null;
};

// eslint-disable-next-line complexity
export function FeedResults(props: PropsType): JSX.Element | null {
    const {
        importValidation: {id, importUrlId, result, percent, source, isCancelled, setIsDisabled, setIsCancelled},
        type,
        feedFormat,
    } = props;

    const [isOpenAll, setIsOpenAll] = useState<boolean | null>(null); // boolean - controlled by button, null - by user

    const {data: errors} = useCompaniesImportErrorCodes(id, percent);

    const hasParsingError = errors?.results.some((error) => feedResultsParsingErrors.includes(error.code));

    if (hasParsingError) {
        return null;
    }

    if (source && percent !== 100) {
        return (
            <>
                {type === FeedResultsTypeEnum.Import && (
                    <PageCard title={<Locale stringKey="FEED__IMPORT__TITLE" />}>
                        <Skeleton loading />
                    </PageCard>
                )}

                <PageCard title={<Locale stringKey="FEED__RESULTS__TITLE" />}>
                    <Skeleton loading />
                </PageCard>
            </>
        );
    }

    if (id && source && result && !result.foreignError && !result.internalError) {
        return (
            <>
                {type === FeedResultsTypeEnum.Import && importUrlId && (
                    <FeedResultsImport
                        companiesCount={{
                            all: result.all,
                            withoutErrors: result.success + result.validationError - result.criticalError,
                            withErrors: result.criticalError,
                        }}
                        importUrlId={importUrlId}
                        isImportCancelled={isCancelled}
                        setIsImportCancelled={setIsCancelled}
                        setIsImportDisabled={setIsDisabled}
                        source={source}
                    />
                )}

                <PageCard title={<Locale stringKey="FEED__RESULTS__TITLE" />}>
                    <div className={feedFormPageStyles.FeedFormPage_content}>
                        <div className={feedFormPageStyles.FeedFormPage_left}>
                            <FeedResultsSummary
                                companiesCount={{
                                    withoutErrors: result.success,
                                    withErrors:
                                        result.validationError - result.criticalError > 0
                                            ? result.validationError - result.criticalError
                                            : result.validationError,
                                    criticalErrors: result.criticalError,
                                    duplicates: result.sameData,
                                }}
                                importUrlId={id}
                                source={source}
                                type={type}
                            />
                        </div>
                    </div>

                    {errors && errors.results.length > 0 && (
                        <>
                            <div className={styles.FeedResults_errorsTitle}>
                                <Text block large secondary>
                                    <Locale stringKey="FEED__RESULTS__ERROR_DESCRIPTIONS" />
                                </Text>

                                <Button
                                    icon={<FontAwesomeIcon icon={isOpenAll ? faEyeSlash : faEye} size="sm" />}
                                    onClick={() => setIsOpenAll((oldOpenAll) => !oldOpenAll)}
                                    type="text"
                                />
                            </div>

                            {/* 'Open all' button sets activeKey prop, but when it exists, user can't open/close anything */}
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div onMouseUp={() => setIsOpenAll(null)}>
                                <FeedResultsErrorGroups
                                    errors={errors?.results}
                                    feedFormat={feedFormat}
                                    importValidationId={id}
                                    isOpenAll={isOpenAll}
                                />
                            </div>
                        </>
                    )}
                </PageCard>
            </>
        );
    }

    return null;
}
