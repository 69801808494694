import {Button, Form} from 'antd';
import {useCallback} from 'react';

import {appRoute} from '../../../../../../../app-route';
import {useActionRequiresLicense} from '../../../../../../../provider/license/license-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {useRemovePostMutation, useUpdatePostMutation} from '../../../../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormType, PostStatusEnum} from '../../../../../../../service/posts/posts-types';
import {FeaturesEnum} from '../../../../../../../service/user/user-type';
import {FormInstance} from '../../../../../../../typings/antd';
import {useUrl} from '../../../../../../../util/url-hook/url-hook';

import * as styles from './post-submit-actions.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    handleSubmitError: (error: unknown) => void;
    isDisabled: boolean;
};

export function EditDraftActions(props: PropsType): JSX.Element {
    const {formInstance, handleSubmitError, isDisabled} = props;

    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const {pushUrl} = useUrl();
    const {postsManagement} = appRoute;

    const createDateTime = Form.useWatch(PostFormFieldsEnum.CreateDateTime, formInstance);

    const {mutateAsync: sendPost, isLoading: isSending} = useUpdatePostMutation(
        createDateTime ? PostStatusEnum.Planned : PostStatusEnum.Sent
    );
    const {mutateAsync: updateDraft, isLoading: isSavingDraft} = useUpdatePostMutation(PostStatusEnum.Draft);
    const {mutateAsync: removePost, isLoading: isRemoving} = useRemovePostMutation();

    const handlePostSend = useActionRequiresLicense(
        useCallback(async () => {
            await formInstance.validateFields();
            modal.confirm({
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
                title: <Locale stringKey="POSTS_SUBMIT_INFORMATION__HEADING" />,
                content: <Locale stringKey="POSTS_FORM__MESSAGE__CREATE_POST_MODAL__CONTENT" />,
                onOk: async () => {
                    try {
                        const formValue = formInstance.getFieldValue([]) as PostFormType;

                        await sendPost(formValue);

                        snackbar.success(<Locale stringKey="POSTS_FORM__MESSAGE__CREATE_SUCCESS" />);

                        pushUrl(postsManagement.path);
                    } catch (error) {
                        handleSubmitError(error);
                        snackbar.error(<Locale stringKey="POSTS_FORM__ERROR__CREATE_POST" />);
                    }
                },
            });
        }, [modal, formInstance, sendPost, snackbar, pushUrl, postsManagement.path, handleSubmitError]),
        FeaturesEnum.posts
    );

    const handleUpdateDraft = useActionRequiresLicense(
        useCallback(async () => {
            await formInstance.validateFields();

            try {
                const formValue = formInstance.getFieldValue([]) as PostFormType;

                await updateDraft(formValue);

                snackbar.success(<Locale stringKey="POSTS_FORM__MESSAGE__UPDATE_DRAFT_SUCCESS" />);
            } catch (error: unknown) {
                handleSubmitError(error);
                snackbar.error(<Locale stringKey="POSTS_FORM__ERROR__UPDATE_POST" />);
            }
        }, [formInstance, updateDraft, snackbar, handleSubmitError]),
        FeaturesEnum.posts
    );

    const handleRemoveClick = useActionRequiresLicense(
        useCallback(async () => {
            modal.confirm({
                title: <Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_DRAFT_MODAL__CONTENT" />,
                content: <Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_DRAFT_MODAL__TITLE" />,
                okText: <Locale stringKey="BUTTON__DELETE" />,
                cancelText: <Locale stringKey="BUTTON__CANCEL" />,
                onOk: async () => {
                    const postId = formInstance.getFieldValue([PostFormFieldsEnum.Id]);

                    if (!postId) {
                        return;
                    }

                    await removePost(postId);
                    pushUrl(postsManagement.path);
                },
            });
        }, [formInstance, modal, postsManagement.path, pushUrl, removePost]),
        FeaturesEnum.posts
    );

    const isInProgress = isSending || isSavingDraft || isRemoving;

    return (
        <div className={styles.PostSubmitButtons}>
            <Button
                className={styles.PostSubmitButtons_button}
                disabled={isInProgress || isDisabled}
                loading={isSending}
                onClick={handlePostSend}
                type="primary"
            >
                <Locale stringKey="POSTS_FORM__BUTTON__SEND" />
            </Button>

            <Button
                className={styles.PostSubmitButtons_button}
                disabled={isInProgress || isDisabled}
                loading={isSavingDraft}
                onClick={handleUpdateDraft}
                type="primary"
            >
                <Locale stringKey="POSTS_FORM__BUTTON__UPDATE_DRAFT" />
            </Button>

            <Button
                className={styles.PostSubmitButtons_button}
                danger
                disabled={isInProgress || isDisabled}
                loading={isRemoving}
                onClick={handleRemoveClick}
            >
                <Locale stringKey="POSTS_FORM__BUTTON__REMOVE_DRAFT" />
            </Button>
        </div>
    );
}
