import {DefaultOptionType} from 'antd/lib/select';

import {includesDeburredString} from '../string';

export function handleFilterOption(input: string, option?: DefaultOptionType, isFilterByKey?: boolean): boolean {
    if (!option) {
        return false;
    }

    if (isFilterByKey && option.key) {
        return includesDeburredString(option.key, input);
    }

    if (option.children && typeof option.children === 'string') {
        return includesDeburredString(option.children as string, input);
    }

    if (option.label && typeof option.label === 'string') {
        return includesDeburredString(option.label, input);
    }

    return false;
}
