import {Context} from 'chartjs-plugin-datalabels';

import {ChartTooltip} from '../../../../../../../component/chart-tooltip/chart-tooltip';
import {ChartTooltipDivider} from '../../../../../../../component/chart-tooltip/chart-tooltip-divider';
import {ChartTooltipRow} from '../../../../../../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../../../../../../component/chart-tooltip/chart-tooltip-title';
import {Text} from '../../../../../../../component/text/text';
import {multiSourceChartOptions} from '../../../../../../../layout/chart/multisource-chart/multi-source-chart-helper';
import {
    ChartJsMultiSourceOptionsType,
    MultiSourceDataSetType,
} from '../../../../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {customReactChartTooltip} from '../../../../../../../layout/chart/utils/chart-helper';
import {LocaleContextType} from '../../../../../../../provider/locale/locale-context-type';
import {LangKeyType} from '../../../../../../../provider/locale/translation/type';
import {ProvidersIdsEnum} from '../../../../../../../util/type';

import * as styles from './company-rating-history-chart.scss';

const preferredAdditionalTooltipRows = 4;
const maxAdditionalTooltipTitleLength = 25;

export function getCompanyRatingChartOptions(
    chartData: {
        labels: Array<string>;
        datasets: Array<
            MultiSourceDataSetType & {
                questionnaireTooltips: Array<{
                    label: string;
                    type: 'mailing_list' | 'qr_codes';
                    title: string;
                }>;
            }
        >;
    },
    getLocalizedString: LocaleContextType['getLocalizedString']
): ChartJsMultiSourceOptionsType {
    return {
        ...multiSourceChartOptions,
        responsive: true,
        hover: {
            mode: 'nearest',
        },
        scales: {
            x: {
                stacked: false,
                ticks: {
                    maxTicksLimit: 20,
                },
            },
            y: {
                display: true,
                stacked: false,
                max: 5.2,
                afterBuildTicks: (scale) => {
                    // eslint-disable-next-line no-param-reassign
                    scale.ticks = [0, 1, 2, 3, 4, 5].map((rating) => {
                        return {
                            label: String(rating),
                            value: rating,
                        };
                    });
                },
                ticks: {
                    maxTicksLimit: 0,
                },
            },
        },
        plugins: {
            ...multiSourceChartOptions.plugins,
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                yAlign: 'top',
                xAlign: 'center',
                position: 'nearest',
                external: (context: Context) =>
                    customReactChartTooltip({
                        context,
                        componentRenderer: (index) => {
                            const additionalTooltipRows: Array<{
                                color: string;
                                labelKey: LangKeyType;
                                title: string;
                            }> = context?.tooltip.dataPoints.flatMap((dataPoint) => {
                                const datasetIndex = dataPoint.datasetIndex;

                                return (
                                    chartData.datasets?.[datasetIndex]?.questionnaireTooltips
                                        ?.filter((tooltipItem) => {
                                            return tooltipItem.label === chartData.labels[index];
                                        })
                                        .map((tooltipItem) => {
                                            const title =
                                                tooltipItem.title.length <= maxAdditionalTooltipTitleLength
                                                    ? `«${tooltipItem.title}»`
                                                    : `«${tooltipItem.title.slice(
                                                          0,
                                                          maxAdditionalTooltipTitleLength - 3
                                                      )}...»`;

                                            return {
                                                color: String(dataPoint.dataset.backgroundColor || ''),
                                                labelKey:
                                                    tooltipItem.type === 'mailing_list'
                                                        ? 'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__MAILING'
                                                        : 'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__QR_CODE',
                                                title,
                                            };
                                        }) || []
                                );
                            });

                            return (
                                <ChartTooltip>
                                    <ChartTooltipTitle title={String(context?.tooltip.dataPoints?.[0]?.label || '')} />

                                    {context?.tooltip.dataPoints.map((dataPoint) => {
                                        return (
                                            <ChartTooltipRow
                                                color={String(dataPoint.dataset.backgroundColor || '')}
                                                key={dataPoint.datasetIndex}
                                                label={String(dataPoint?.dataset?.label || '')}
                                                value={dataPoint.formattedValue}
                                            />
                                        );
                                    })}

                                    {additionalTooltipRows.length > 0 && (
                                        <>
                                            <ChartTooltipDivider />

                                            {additionalTooltipRows
                                                .slice(
                                                    0,
                                                    additionalTooltipRows.length === preferredAdditionalTooltipRows + 1
                                                        ? preferredAdditionalTooltipRows + 1
                                                        : preferredAdditionalTooltipRows
                                                )
                                                .map((tooltipRow, currentIndex) => (
                                                    <ChartTooltipRow
                                                        color={tooltipRow.color}
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={currentIndex}
                                                        label={getLocalizedString(tooltipRow.labelKey)}
                                                        value={tooltipRow.title}
                                                    />
                                                ))}

                                            {additionalTooltipRows.length > preferredAdditionalTooltipRows + 1 && (
                                                <Text secondary small>
                                                    {getLocalizedString(
                                                        'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__HIDDEN_ITEMS',
                                                        {
                                                            count: String(
                                                                additionalTooltipRows.length -
                                                                    preferredAdditionalTooltipRows
                                                            ),
                                                        }
                                                    )}
                                                </Text>
                                            )}
                                        </>
                                    )}
                                </ChartTooltip>
                            );
                        },
                    }),
            },
        },
    };
}

export function getRatingDynamicsChartDatasetColor(providerId: ProvidersIdsEnum): string {
    switch (providerId) {
        case ProvidersIdsEnum.google:
            return styles.googleColor || '';
        case ProvidersIdsEnum.yandex:
            return styles.yandexColor || '';
        case ProvidersIdsEnum.doubleGis:
            return styles.wGisColor || '';
        default:
            return '';
    }
}
