import {TableColumnsType} from 'antd';
import {TableRowSelection} from 'antd/lib/table/interface';
import {difference, uniq, uniqBy} from 'lodash';
import {useCallback, useMemo, useState} from 'react';

import {FIXED_COLUMN_THRESHOLD} from '../../../../../const';
import {Locale} from '../../../../../provider/locale/localization';
import {useSystem} from '../../../../../provider/system/system-hook';
import {useRoles} from '../../../../../service/user-management/user-management-roles';
import {UserStatusEnum} from '../../../../../service/user-management/user-management-type';

import {USER_STATUS_TO_LOCALE} from './users-table-const';
import {UsersTableRowType} from './users-table-type';

export function useUsersColumns(): TableColumnsType<UsersTableRowType> {
    const {data: roles} = useRoles();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {width} = screen;

    return [
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__EMAIL" />,
            dataIndex: 'email',
            fixed: FIXED_COLUMN_THRESHOLD < width ? 'left' : false,
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__ROLE" />,
            dataIndex: 'role',
            filterMultiple: false,
            filters: uniqBy(roles, ({pk}) => pk).map((role) => ({
                value: role.pk,
                text: role.name,
            })),
            onFilter: (value, record) => record.roleId === value,
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__COMPANIES" />,
            dataIndex: 'companies',
            align: 'center',
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__BRANDS" />,
            dataIndex: 'brands',
            align: 'center',
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__STATUS" />,
            dataIndex: 'status',
            filterMultiple: false,
            filters: Object.values(UserStatusEnum).map((value) => ({value, text: USER_STATUS_TO_LOCALE[value]})),
            onFilter: (value, record) => record.statusValue === value,
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__CREATED_AT" />,
            dataIndex: 'createdAt',
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__CREATOR" />,
            dataIndex: 'creator',
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__LAST_UPDATE" />,
            dataIndex: 'lastActivity',
            align: 'center',
        },
        {
            title: <Locale stringKey="USERS__TABLE__COLUMN__ACTIONS" />,
            dataIndex: 'actions',
            fixed: FIXED_COLUMN_THRESHOLD < width ? 'right' : false,
        },
    ];
}

type SelectionHookOptionsType = {
    total: number;
    selectAllOnlyInCurrentPage?: boolean;
    shownIds?: Array<number>;
};

type SelectionHookReturnType<TableData> = {
    selectedIds: Array<number>;
    totalSelected: number;
    rowSelection: TableRowSelection<TableData>;
    toggleSelectAll: (selectAll: boolean) => void;
};

export function usePaginatedSelection<TableData>(
    options: SelectionHookOptionsType
): SelectionHookReturnType<TableData> {
    const {total, selectAllOnlyInCurrentPage = true, shownIds: shownIdsRaw} = options;

    const [isSelectAll, setIsSelectAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);

    const shownIds = useMemo(() => shownIdsRaw || [], [shownIdsRaw]);

    const selectedTotal = useMemo(
        () => (isSelectAll ? total - selectedIds.length : selectedIds.length),
        [isSelectAll, selectedIds.length, total]
    );

    const toggleSelectAll = useCallback((selectAll: boolean) => {
        setIsSelectAll(selectAll);
        setSelectedIds([]);
    }, []);

    const onSelectionChange = useCallback<NonNullable<TableRowSelection<unknown>['onChange']>>(
        (keys, _rows, info) => {
            if (info.type === 'all' && !selectAllOnlyInCurrentPage) {
                toggleSelectAll(keys.length > 0);

                return;
            }

            const newIds = keys.map((key): number =>
                typeof key === 'string' ? Number.parseInt(key, 10) : Number(key)
            );
            const newSelectedIds = uniq([...difference(selectedIds, shownIds), ...newIds]);

            setSelectedIds(isSelectAll ? difference(shownIds, newSelectedIds) : newSelectedIds);
        },
        [isSelectAll, selectAllOnlyInCurrentPage, selectedIds, shownIds, toggleSelectAll]
    );

    const rowSelection: TableRowSelection<TableData> = useMemo(
        () => ({
            columnWidth: 48,
            selectedRowKeys: !isSelectAll ? selectedIds : difference(shownIds, selectedIds),
            onChange: onSelectionChange,
        }),
        [isSelectAll, onSelectionChange, selectedIds, shownIds]
    );

    return {selectedIds, totalSelected: selectedTotal, rowSelection, toggleSelectAll};
}
