import {Select} from 'antd';
import {PropsWithChildren, useState} from 'react';

import {CompanyCategoryType as CompanyCategoryApiType} from '../../../../../../../service/api/api-type';
import {useSearchCompanyCategoryList} from '../../../../../../../service/api-hook/api-hook';
import {CompanyCategoryType} from '../../../../../../../service/company-v2/types/company-categories-type';
import {getRandomString} from '../../../../../../../util/string';

import {SelectCategoriesFormItem} from './select-categories-form-item';
import {highlightOption} from './select-categories-form-item-helper';
import * as styles from './select-categories-form-item.scss';

type PropsType = PropsWithChildren<{
    value?: Array<CompanyCategoryType>;
    onChange?: (value: Array<CompanyCategoryType>) => void;
}>;

export function SelectCategoriesFormItemWithoutCatalog(props: PropsType): JSX.Element {
    const {children, value, onChange} = props;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const {result: companyCategoryResult, isInProgress} = useSearchCompanyCategoryList(searchQuery || null);

    return (
        <SelectCategoriesFormItem<CompanyCategoryType, CompanyCategoryApiType>
            companyCategoryList={companyCategoryResult?.results ?? []}
            createCategory={(category: CompanyCategoryApiType, isMain: boolean) => ({
                categoryId: category.id,
                categoryName: category.translate,
                isMain,
            })}
            getApiId={(category: CompanyCategoryApiType) => category.id}
            getId={(category: CompanyCategoryType) => category.categoryId}
            isInProgress={isInProgress}
            onChange={onChange}
            renderOption={(category: CompanyCategoryApiType) => {
                const {id, path, translate} = category;

                return (
                    <Select.Option
                        className={styles.select_company_category_list__select_option}
                        key={id + getRandomString()}
                        title={translate}
                        value={id}
                    >
                        {highlightOption(path, searchQuery)}
                    </Select.Option>
                );
            }}
            required
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            value={value}
        >
            {children}
        </SelectCategoriesFormItem>
    );
}
