import dayjs from 'dayjs';

import {
    TechnicalSupportActualTicketIdType,
    TechnicalSupportFieldsEnum,
    TechnicalSupportFilterQueriesType,
    TechnicalSupportFilterType,
    technicalSupportNewTicketIdResponseSchema,
    technicalSupportStatusActionSchema,
    TechnicalSupportStatusActionType,
    technicalSupportUnreadCountActionSchema,
    TechnicalSupportUnreadCountActionType,
} from './technical-support-type';

export const TECHNICAL_SUPPORT_FILTER_EMPTY: TechnicalSupportFilterType = {
    [TechnicalSupportFieldsEnum.Status]: [],
    creationDate: [null, null],
    q: null,
    [TechnicalSupportFieldsEnum.ClientCategory]: null,
    [TechnicalSupportFieldsEnum.Type]: null,
    [TechnicalSupportFieldsEnum.IsPublic]: null,
    [TechnicalSupportFieldsEnum.Creator]: [],
    [TechnicalSupportFieldsEnum.CreatorGroup]: [],
    [TechnicalSupportFieldsEnum.HasNewAnswers]: null,
};

export function getTechnicalSupportFilterInitialValues(
    query: TechnicalSupportFilterQueriesType
): TechnicalSupportFilterType {
    return {
        [TechnicalSupportFieldsEnum.Status]: query.status
            ? query.status.split(',')
            : TECHNICAL_SUPPORT_FILTER_EMPTY[TechnicalSupportFieldsEnum.Status],
        q: '',
        creationDate:
            query.createdFrom && query.createdTo
                ? [new Date(query.createdFrom), new Date(query.createdTo)]
                : TECHNICAL_SUPPORT_FILTER_EMPTY.creationDate,
        [TechnicalSupportFieldsEnum.ClientCategory]: query.clientCategory
            ? Number(query.clientCategory)
            : TECHNICAL_SUPPORT_FILTER_EMPTY[TechnicalSupportFieldsEnum.ClientCategory],
        [TechnicalSupportFieldsEnum.Type]:
            query.type || TECHNICAL_SUPPORT_FILTER_EMPTY[TechnicalSupportFieldsEnum.Type],
        [TechnicalSupportFieldsEnum.IsPublic]: Reflect.has(query, 'isPublic')
            ? Boolean(query.isPublic)
            : TECHNICAL_SUPPORT_FILTER_EMPTY[TechnicalSupportFieldsEnum.IsPublic],
        [TechnicalSupportFieldsEnum.Creator]: query.creator
            ? query.creator.split(',').map(Number)
            : TECHNICAL_SUPPORT_FILTER_EMPTY[TechnicalSupportFieldsEnum.Creator],
        [TechnicalSupportFieldsEnum.CreatorGroup]: query.creatorGroup
            ? query.creatorGroup.split(',').map(Number)
            : TECHNICAL_SUPPORT_FILTER_EMPTY[TechnicalSupportFieldsEnum.CreatorGroup],
        [TechnicalSupportFieldsEnum.HasNewAnswers]: query[TechnicalSupportFieldsEnum.HasNewAnswers]
            ? query[TechnicalSupportFieldsEnum.HasNewAnswers] === 'true'
            : null,
    };
}

export function getTechnicalSupportFilterQueries(
    filter: TechnicalSupportFilterType
): TechnicalSupportFilterQueriesType {
    return {
        [TechnicalSupportFieldsEnum.Status]: filter[TechnicalSupportFieldsEnum.Status].join(','),
        createdFrom: filter.creationDate[0] ? dayjs(filter.creationDate[0]).format('YYYY-MM-DD') : '',
        createdTo: filter.creationDate[1] ? dayjs(filter.creationDate[1]).format('YYYY-MM-DD') : '',
        [TechnicalSupportFieldsEnum.ClientCategory]: String(filter[TechnicalSupportFieldsEnum.ClientCategory] || ''),
        [TechnicalSupportFieldsEnum.Type]: String(filter[TechnicalSupportFieldsEnum.Type] || ''),
        [TechnicalSupportFieldsEnum.IsPublic]:
            filter[TechnicalSupportFieldsEnum.IsPublic] !== null
                ? String(filter[TechnicalSupportFieldsEnum.IsPublic])
                : '',
        [TechnicalSupportFieldsEnum.Creator]: filter[TechnicalSupportFieldsEnum.Creator].join(','),
        [TechnicalSupportFieldsEnum.CreatorGroup]: filter[TechnicalSupportFieldsEnum.CreatorGroup].join(','),
        [TechnicalSupportFieldsEnum.HasNewAnswers]:
            filter[TechnicalSupportFieldsEnum.HasNewAnswers] !== null
                ? String(filter[TechnicalSupportFieldsEnum.HasNewAnswers])
                : '',
    };
}

export function getTechnicalSupportQueryKey(helpRequestId: number): Array<string | number> {
    return ['view-support-ticket', helpRequestId];
}

export function isResponseWithActualTicketId(data: unknown): data is TechnicalSupportActualTicketIdType {
    return technicalSupportNewTicketIdResponseSchema.safeParse(data).success;
}

export function isTicketStatusAction(data: unknown): data is TechnicalSupportStatusActionType {
    return technicalSupportStatusActionSchema.safeParse(data).success;
}

export function isTicketUnreadCountAction(data: unknown): data is TechnicalSupportUnreadCountActionType {
    return technicalSupportUnreadCountActionSchema.safeParse(data).success;
}

export function getTechnicalSupportStatusChannel(userId?: number): string {
    return `hde_ticket:status#${userId}`;
}

export function getTechnicalSupportUnreadPostsCountChannel(userId?: number): string {
    return `hde_ticket:unread_posts_count#${userId}`;
}
