import {Modal} from 'antd';
import {useEffect, useState} from 'react';

import {Locale} from '../../../provider/locale/localization';
import {useModal} from '../../../provider/modal/modal-hook';
import {CompaniesSelectorFeatureEnum} from '../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../service/feature-companies/feature-companies-hook';
import {useUpdateYandexStory} from '../../../service/yandex-stories/yandex-stories-hook';
import {
    storiesCompanySelectorCompanySchema,
    StoriesCompanySelectorCompanyType,
    YandexStoryFormType,
    YandexStoryType,
    YandexStoryTypeEnum,
} from '../../../service/yandex-stories/yandex-stories-type';

import {YandexStoryForm} from './yandex-story-form';
import {getYandexStoryFormInitialValues} from './yandex-story-form-helper';
import * as styles from './yandex-story-form.scss';

type PropsType = {
    close: (isOpen: boolean) => void;
    type: YandexStoryTypeEnum;
    storyData: YandexStoryType;
    onEditSuccess?: VoidFunction;
};

export function EditYandexStoryFormModal(props: PropsType): JSX.Element {
    const {close, type, storyData, onEditSuccess} = props;

    const [modalWidth, setModalWidth] = useState<number>(862);

    const companiesSelector = useCompaniesSelector<StoriesCompanySelectorCompanyType>({
        feature: CompaniesSelectorFeatureEnum.YandexStories,
        resourceId: String(storyData.id),
        companySchema: storiesCompanySelectorCompanySchema,
        initialCompaniesCount: storyData.companiesCount,
    });

    const {modal} = useModal();

    const {mutateAsync: updateStory} = useUpdateYandexStory(storyData.id);

    const {initializeSelector} = companiesSelector;

    useEffect(() => {
        initializeSelector();
    }, [initializeSelector]);

    async function handleSubmit(values: YandexStoryFormType): Promise<void> {
        await updateStory(values);
        onEditSuccess?.();
    }

    return (
        <Modal
            centered
            className={styles.YandexStoryFormModal}
            closable
            destroyOnClose
            footer={null}
            maskClosable
            onCancel={() => {
                modal.confirm({
                    maskClosable: true,
                    title: <Locale stringKey="YANDEX_STORY__EDIT__CONFIRM_CLOSE__TITLE" />,
                    content: <Locale stringKey="YANDEX_STORY__CONFIRM_CLOSE__CONTENT" />,
                    okText: <Locale stringKey="YANDEX_STORY__EDIT__CONFIRM_CLOSE__CONFIRM" />,
                    cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                    onOk: async () => {
                        close(false);
                    },
                    okButtonProps: {
                        danger: true,
                        rootClassName: styles.YandexStoryFormModal_confirmCLose,
                        type: 'primary',
                    },
                });
            }}
            open
            title={<Locale stringKey="YANDEX_STORY__EDIT__TITLE" />}
            width={modalWidth}
        >
            <YandexStoryForm
                closeModal={() => {
                    close(false);
                }}
                companiesSelector={companiesSelector}
                initialValues={getYandexStoryFormInitialValues(storyData)}
                mode="edit"
                onChangeStep={setModalWidth}
                onSubmit={handleSubmit}
                type={type}
            />
        </Modal>
    );
}
