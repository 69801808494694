export function getTrendDelta(options: {current: number; previous: number}): number {
    const {current, previous} = options;

    if (current && !previous) {
        return 100;
    }

    if (previous && !current) {
        return -100;
    }

    if (!previous && !current) {
        return 0;
    }

    return ((current - previous) / previous) * 100;
}
