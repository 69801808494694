import {z as r} from 'zod';

export const usedAutoRepliesCountSchema = r.object({
    count: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    byDate: r
        .array(
            r.object({
                date: r.string(), // формат даты DD-MM-YYYY
                count: r.number(),
            })
        )
        .optional(),
});

export type UsedAutoRepliesCountType = r.infer<typeof usedAutoRepliesCountSchema>;
