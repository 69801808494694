import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';

import * as styles from './problem-alert.scss';

type PropsType = {
    onClick: () => void;
};

export function ProblemAlert(props: PropsType): JSX.Element {
    const {onClick} = props;

    return (
        <Alert
            action={
                <Button onClick={onClick} size="small">
                    <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__LINK" />
                </Button>
            }
            className={styles.ProblemAlert}
            description={<Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__PROBLEM__MESSAGE" />}
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
            message={<Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__ALERT__PROBLEM" />}
            showIcon
            type="error"
        />
    );
}
