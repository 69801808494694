import {Table, TablePaginationConfig} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import {useContext, useEffect, useState} from 'react';

import {AlertFallback} from '../../../../../../../component/alert-fallback/alert-fallback';
import {PaginationControls} from '../../../../../../../layout/pagination-controls/pagination-controls';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../../provider/locale/localization';
import {MainFilterContext} from '../../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../../provider/main-filter/main-filter-type';
import {CatalogByGroupType} from '../../../../../../../service/source-settings/catalogs/catalogs-type';
import {useSourceLinkList} from '../../../../../../../service/source-settings/source-links/source-links-hook';
import {
    SourceLinkStatusEnum,
    SourceLinkType,
    SourceLinkTypeEnum,
} from '../../../../../../../service/source-settings/source-links/source-links-type';
import {defaultPageSize} from '../../../../../../../util/pagination-hook/pagination-const';
import {AddLink} from '../../add-link/add-link';
import {EditLink} from '../../edit-link/edit-link';
import {RemoveLink} from '../../remove-link/remove-link';
import {EmptyTable} from '../empty-table/empty-table';

import {statusFilterKey} from './links-table-const';
import {convertToLinkStatusList, getLinksTableColumns} from './links-table-helper';
import {SourceLinksTableType} from './links-table-type';
import * as styles from './links-table.scss';

type PropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    linkType: SourceLinkTypeEnum;
    linkTypeControl: JSX.Element;
};

export function LinksTable(props: PropsType): JSX.Element {
    const {catalog, catalogGroupId, linkType, linkTypeControl} = props;

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const [cursor, setCursor] = useState<string | null>(null);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [statusFilter, setStatusFilter] = useState<Array<SourceLinkStatusEnum>>([]);

    const {result, isInProgress, processError, refresh} = useSourceLinkList(linkType, {
        cursor,
        catalogId: catalog.id,
        filterKey: mainFilterKey,
        status: statusFilter,
        pageSize,
    });
    const isStatusFilterEmpty = statusFilter.length === 0;
    const isEmptyPlaceholderVisible = result && result.count === 0 && isStatusFilterEmpty;

    useEffect(() => {
        setCursor(null);
        setStatusFilter([]);
    }, [mainFilterKey, pageSize]);

    function onTableChange(_pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>): void {
        const filterValues = filters[statusFilterKey] || [];
        const newStatusFilter: Array<SourceLinkStatusEnum> = convertToLinkStatusList(filterValues);

        setCursor(null);
        setStatusFilter(newStatusFilter);
    }

    return (
        <>
            <div className={styles.link_controls}>
                {linkTypeControl}
                <AddLink catalog={catalog} catalogGroupId={catalogGroupId} onSuccess={refresh} />
            </div>

            <div className={styles.table__content}>
                <Spinner isShow={isInProgress} position="absolute" />

                {processError && <AlertFallback />}

                {isEmptyPlaceholderVisible && (
                    <EmptyTable title={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINKS__EMPTY__TITLE" />}>
                        <AddLink catalog={catalog} catalogGroupId={catalogGroupId} isPrimary onSuccess={refresh} />
                    </EmptyTable>
                )}

                {result && !isEmptyPlaceholderVisible && !processError && (
                    <>
                        <div className={styles.table__wrapper}>
                            <Table<SourceLinksTableType>
                                columns={getLinksTableColumns(linkType === SourceLinkTypeEnum.Company)}
                                dataSource={result.results.map((linkEntity: SourceLinkType) => ({
                                    ...linkEntity,
                                    key: linkEntity.id,
                                    actions: (
                                        <>
                                            <EditLink
                                                catalog={catalog}
                                                catalogGroupId={catalogGroupId}
                                                linkEntity={linkEntity}
                                                onSuccess={refresh}
                                            />
                                            <RemoveLink catalog={catalog} linkEntity={linkEntity} onSuccess={refresh} />
                                        </>
                                    ),
                                }))}
                                onChange={onTableChange}
                                pagination={false}
                                scroll={{x: true}}
                                size="middle"
                            />
                        </div>
                        <PaginationControls
                            onCursorChange={setCursor}
                            onPageSizeChange={setPageSize}
                            pageSize={pageSize}
                            payload={result}
                        />
                    </>
                )}
            </div>
        </>
    );
}
