import * as styles from './local-pack-result-icon.scss';

export function getLocalPackIconColor(position: number, isAd?: boolean): string {
    switch (true) {
        case isAd: {
            return styles.advertisementColor;
        }
        case position < 5: {
            return styles.positionBucketColor1;
        }
        case position < 10: {
            return styles.positionBucketColor2;
        }
        case position < 15: {
            return styles.positionBucketColor3;
        }
        case position < 20: {
            return styles.positionBucketColor4;
        }
        default:
            return styles.positionBucketColor5;
    }
}
