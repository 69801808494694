import {LangKeyType} from '../../../provider/locale/translation/type';
import {LocalPackCatalogsEnum, LocalPackServicesEnum} from '../../../service/local-pack/local-pack-type';
import {LocalPackRuleFrequencyEnum} from '../../../service/local-pack/rules/local-pack-rule-list-item-type';

export const localPackNullPositionValue = 21;

export const LOCALPACK_CATALOG_LOCALIZATION_MAP: Record<LocalPackCatalogsEnum, LangKeyType> = {
    [LocalPackCatalogsEnum.google]: 'LOCAL_PACK__CATALOG__GOOGLE',
    [LocalPackCatalogsEnum.yandex]: 'LOCAL_PACK__CATALOG__YANDEX',
};

export const LOCALPACK_SERVICE_LOCALIZATION_MAP: Record<LocalPackServicesEnum, LangKeyType> = {
    [LocalPackServicesEnum.desktop]: 'LOCAL_PACK__SERVICE_TYPE_TABLE__DESKTOP',
    [LocalPackServicesEnum.mobile]: 'LOCAL_PACK__SERVICE_TYPE_TABLE__MOBILE',
    [LocalPackServicesEnum.maps]: 'LOCAL_PACK__SERVICE_TYPE_TABLE__MAPS',
};

export const LOCALPACK_FREQUENCY_LOCALIZATION_MAP: Record<LocalPackRuleFrequencyEnum, LangKeyType> = {
    [LocalPackRuleFrequencyEnum.Daily]: 'LOCAL_PACK__FREQUENCY__DAILY',
    [LocalPackRuleFrequencyEnum.Weekly]: 'LOCAL_PACK__FREQUENCY__WEEKLY',
    [LocalPackRuleFrequencyEnum.Monthly]: 'LOCAL_PACK__FREQUENCY__MONTHLY',
    [LocalPackRuleFrequencyEnum.Once]: 'LOCAL_PACK__FREQUENCY__ONCE',
};
