export enum CompaniesImportTypeEnum {
    BrandWizardJson = 'bwf',
    RocketdataJson = 'rdf',
    RocketdataCsv = 'csv',
    RocketdataXlsx = 'excel',
    YandexXml = 'yandex_xml',
}

// https://gitlab.com/rocketdata.io/rocketdata/-/blob/35a87d92e246f17f6640f1a6a82d594fe9e4907d/backend/app/companies_import/validator/error_codes.py#L13
export enum CompaniesImportParsingErrorCodeEnum {
    LoadingError = 1001,
    InvalidJson = 1002,
    InvalidXml = 1003,
    InvalidCsv = 1004,
    InvalidCsvFileScheme = 1005,
}
