import {z as r} from 'zod';

export enum DeployServiceEnum {
    Frontend = 'frontend',
    Backend = 'backend',
}

export const centrifugeDeployNotificationSchema = r.object({
    deploy: r.nativeEnum(DeployServiceEnum),
});

export type CentrifugoDeployNotificationType = r.infer<typeof centrifugeDeployNotificationSchema>;
