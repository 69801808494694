import {DefaultTooltipStaticPropsType} from '../../../../component/chart-tooltip/default-chart-tooltip';
import {getDefaultReactTooltipOptions} from '../../../../layout/chart/chart-const';
import {multiSourceChartOptions} from '../../../../layout/chart/multisource-chart/multi-source-chart-helper';
import {ChartJsMultiSourceOptionsType} from '../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {ReviewRatingByDateKeysEnum} from '../../../../service/reviews-and-answers-statistics/reviews-ratings-by-date/reviews-rating-by-date-type';
import * as styles from '../reviews-destribution/reviews-distribution.scss';

export function getRatingDistributionChartOptions(options: {
    tooltipOptions: DefaultTooltipStaticPropsType;
    showTooltip: boolean;
}): ChartJsMultiSourceOptionsType {
    const {tooltipOptions, showTooltip} = options;

    return {
        ...multiSourceChartOptions,
        responsive: true,
        hover: {
            mode: 'nearest',
        },
        scales: {
            x: {
                stacked: true,
                offset: false,
                ticks: {
                    maxTicksLimit: 20,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                    maxTicksLimit: 0,
                },
                position: 'left',
            },
            // eslint-disable-next-line id-length
            y2: {
                display: true,
                stacked: false,
                max: 5.2,
                position: 'right',
                afterBuildTicks: (scale) => {
                    // eslint-disable-next-line no-param-reassign
                    scale.ticks = [0, 1, 2, 3, 4, 5].map((rating) => {
                        return {
                            label: String(rating),
                            value: rating,
                        };
                    });
                },
                ticks: {
                    maxTicksLimit: 0,
                },
            },
        },
        plugins: {
            ...multiSourceChartOptions.plugins,
            legend: {
                display: false,
            },
            tooltip: showTooltip ? getDefaultReactTooltipOptions(tooltipOptions) : {enabled: false},
        },
    };
}

export const reviewRatingByTimeChartColor: Record<ReviewRatingByDateKeysEnum | 'compareAverageRating', string> = {
    [ReviewRatingByDateKeysEnum.One]: styles.reviews_rating_1_color,
    [ReviewRatingByDateKeysEnum.Two]: styles.reviews_rating_2_color,
    [ReviewRatingByDateKeysEnum.Three]: styles.reviews_rating_3_color,
    [ReviewRatingByDateKeysEnum.Four]: styles.reviews_rating_4_color,
    [ReviewRatingByDateKeysEnum.Five]: styles.reviews_rating_5_color,
    [ReviewRatingByDateKeysEnum.Average]: styles.average_rating_color,
    compareAverageRating: styles.compare_average_rating_color,
};
