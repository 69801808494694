import {Button, Modal, Space} from 'antd';

import {Text} from '../../../../component/text/text';
import {Locale} from '../../../../provider/locale/locale';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useFakesApproveActionMutation} from '../../../../service/fakes/fakes-companies-approve-action';
import {FakesResultType} from '../../../../service/fakes/fakes-results';
import {FakeNeededActionEnum} from '../../../../service/fakes/fakes-type';
import {classNames} from '../../../../util/css';
import {FakesPopupCompanyInfo} from '../common/company-info/fakes-popup-company-info';

import * as styles from './fakes-popup.scss';

type PropsType = {
    onCancel: () => void;
    open: boolean;
    fake: FakesResultType;
    className?: string;
    onSubmit: () => void;
};

export function FakesPopupNeedAction(props: PropsType): JSX.Element {
    const {className, open, onCancel, fake, onSubmit} = props;

    const {mutate} = useFakesApproveActionMutation(fake.id, onSubmit);

    function neededActionText(usage: 'locationInfo' | 'deleteInformation') {
        if (usage === 'deleteInformation') {
            switch (fake.neededAction) {
                case FakeNeededActionEnum.FakeOnSite:
                    return <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__FROM_YOUR_SITE" />;
                case FakeNeededActionEnum.FakeInFeed:
                    return <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__FROM_YOUR_FEED" />;
                default:
                    return <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__FROM_YOUR_SITE" />;
            }
        } else {
            switch (fake.neededAction) {
                case FakeNeededActionEnum.FakeOnSite:
                    return <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__SITE" />;
                case FakeNeededActionEnum.FakeInFeed:
                    return <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__FEED" />;
                default:
                    return <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__SITE" />;
            }
        }
    }

    return (
        <Modal
            className={classNames(styles.FakesPopup, className)}
            footer={[
                <Button key="ok" onClick={onCancel} type="primary">
                    <Locale stringKey="TEXT__CLOSE" />
                </Button>,
            ]}
            onCancel={onCancel}
            open={open}
            title={<Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__TITLE_REQUIREMENTS" />}
        >
            <h5 className={styles.FakesPopup_infoTitle}>
                <Locale
                    stringKey="FAKES__RESULTS__MODERATION_POPUP__DELETE_INFORMATION"
                    valueMap={{
                        fromSiteOrFeed: neededActionText('deleteInformation'),
                    }}
                />
            </h5>
            <FakesPopupCompanyInfo
                address={fake.address}
                catalogId={fake.catalogId}
                category={fake.category}
                logoUrl={fake.logoUrl}
                name={fake.name}
                phone={fake.phone}
                url={fake.url}
            />

            <Space direction="vertical" size={12}>
                <Text
                    lighter
                    stringKey="FAKES__RESULTS__MODERATION_POPUP__DUPLICATE_LOCATION_INFO"
                    valueMap={{
                        dubsOrFake: fake.isDuplicate ? (
                            <Text lighter stringKey="FAKES__RESULTS__MODERATION_POPUP__DUPLICATES" />
                        ) : (
                            <Text lighter stringKey="FAKES__RESULTS__MODERATION_POPUP__FAKES" />
                        ),
                        siteOrFeed: neededActionText('locationInfo'),
                    }}
                />
                <Button
                    className={styles.FakesPopup_confirmButton}
                    onClick={() => {
                        mutate(fake.id);
                        track(AnalyticsTarget.DuplicatesAndFakes.ConfirmInfoDeletion);
                    }}
                >
                    <Locale stringKey="FAKES__RESULTS__MODERATION_POPUP__CONFIRM_HAS_BEEN_DELETED" />
                </Button>
            </Space>
        </Modal>
    );
}
