import {WeekDaysEnum} from '../../../../../../provider/locale/locale-context-const';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';

export const timeFormat = 'HH:mm';

export const weekDayAllNameValuesMap: Record<WeekDaysEnum, LangKeyType> = {
    [WeekDaysEnum.monday]: 'WEEK_DAY__MONDAY',
    [WeekDaysEnum.tuesday]: 'WEEK_DAY__TUESDAY',
    [WeekDaysEnum.wednesday]: 'WEEK_DAY__WEDNESDAY',
    [WeekDaysEnum.thursday]: 'WEEK_DAY__THURSDAY',
    [WeekDaysEnum.friday]: 'WEEK_DAY__FRIDAY',
    [WeekDaysEnum.saturday]: 'WEEK_DAY__SATURDAY',
    [WeekDaysEnum.sunday]: 'WEEK_DAY__SUNDAY',
};
