import {useMutation, UseMutationResult} from '@tanstack/react-query';

import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {licenseUrl} from './tariffs-const';

const fetchBuyTariffLicenseUrl = `${rootApiUrl}${licenseUrl}`;

type BuyLicenseOptionsType = {
    tariffId?: number;
    licenseCode: string;
    message: string;
};

function fetchBuyTariffLicense(options: BuyLicenseOptionsType): Promise<void> {
    return fetchNoContent(`${fetchBuyTariffLicenseUrl}/${options.licenseCode}/full/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize({message: options.message, tariffId: options.tariffId})),
    });
}

function fetchBuyTariffTrialLicense(options: BuyLicenseOptionsType): Promise<void> {
    return fetchNoContent(`${fetchBuyTariffLicenseUrl}/${options.licenseCode}/trial/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize({tariffId: options.tariffId, trialStartAt: new Date()})),
    });
}

export function useBuyTariffLicense(): UseMutationResult<void, unknown, BuyLicenseOptionsType> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (licenseOptions: BuyLicenseOptionsType) => fetchBuyTariffLicense(licenseOptions),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="SNACKBAR__SUCCESS__SEND_APPLICATION__TITLE" />,
                description: <Locale stringKey="SNACKBAR__SUCCESS__SEND_APPLICATION__DESCRIPTION" />,
            });
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}

export function useBuyTariffTrialLicense(): UseMutationResult<void, unknown, BuyLicenseOptionsType> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (licenseOptions: BuyLicenseOptionsType) => fetchBuyTariffTrialLicense(licenseOptions),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="TARIFFS__MODAL__TEST__SNACKBAR__TITLE" />,
                description: <Locale stringKey="TARIFFS__MODAL__TEST__SNACKBAR__DESCRIPTION" />,
            });
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}
