import {Tooltip} from 'antd';

import {useCatalog} from '../../../../../provider/catalogs/catalogs-hook';

import * as styles from './navigator.scss';

type PropsType = {
    catalogId: number;
};

export function CatalogSyncNavigator(props: PropsType): JSX.Element {
    const {catalogId} = props;

    const {catalog} = useCatalog(catalogId);

    return (
        <div className={styles.navigator__wrapper}>
            <Tooltip title={catalog?.name}>
                <img alt={catalog?.name} className={styles.navigator__icon} src={catalog?.logo} />
            </Tooltip>
        </div>
    );
}
