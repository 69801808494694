import {Table} from 'antd';

import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Trend} from '../../../../layout/trend/trend';
import {Locale} from '../../../../provider/locale/localization';
import {ReviewsPopularTagType} from '../../../../service/reviews-and-answers-statistics/reviews-popular-tags/reviews-popular-tag-type';

type PropsType = {
    isCompareMode?: boolean;
    topTagsStats: Array<ReviewsPopularTagType> | null;
    isLoading: boolean;
};

export function ReviewTagsTable(props: PropsType): JSX.Element {
    const {isCompareMode, topTagsStats, isLoading} = props;

    return (
        <PageCard title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__TABLE_TITLE" />}>
            <Table<ReviewsPopularTagType> dataSource={topTagsStats || []} loading={isLoading} pagination={false}>
                <Table.Column<ReviewsPopularTagType>
                    align="left"
                    render={(_value, record) => {
                        return (
                            <Text block bolder>
                                {record.title}
                            </Text>
                        );
                    }}
                    title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__NAME_COLUMN" />}
                />

                {isCompareMode ? (
                    <Table.ColumnGroup
                        align="center"
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__USAGES_COLUMN" />}
                    >
                        <Table.Column<ReviewsPopularTagType>
                            align="right"
                            render={(_value, record) => {
                                return <Text block>{record.reviewsCount.value}</Text>;
                            }}
                            title="A"
                        />

                        <Table.Column<ReviewsPopularTagType>
                            align="center"
                            render={(_value, record) => {
                                return <Trend delta={record.reviewsCount.delta || 0} />;
                            }}
                            title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                        />

                        <Table.Column<ReviewsPopularTagType>
                            render={(_value, record) => {
                                return <Text block>{record.reviewsCount.comparedValue}</Text>;
                            }}
                            title="B"
                        />
                    </Table.ColumnGroup>
                ) : (
                    <Table.Column<ReviewsPopularTagType>
                        align="center"
                        render={(_value, record) => {
                            return <Text block>{record.reviewsCount.value}</Text>;
                        }}
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__POPULAR_TAGS__USAGES_COLUMN" />}
                    />
                )}
            </Table>
        </PageCard>
    );
}
