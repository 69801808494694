import {fetchX} from '../../util/fetch';
import {ProvidersIdsEnum} from '../../util/type';
import {rootApiUrl} from '../api/api-const';

import {WorkloadByHoursAndWeekDaysType} from './analytics-workload-type';

export function getWorkloadByHoursAndWeekDays(
    companyId: number,
    catalogId: ProvidersIdsEnum
): Promise<WorkloadByHoursAndWeekDaysType> {
    return fetchX<WorkloadByHoursAndWeekDaysType>(
        rootApiUrl + `/v1/company_stats/${companyId}/attendance/?catalog_id=${catalogId}`
    );
}
