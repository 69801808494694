import {Select} from 'antd';

import {Text} from '../../../../component/text/text';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {localeNameToLanguageNameMap} from '../../../../provider/locale/locale-context-const';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useChangeLanguage} from '../../../../util/change-language-hook/change-language-hook';

import * as styles from './language.scss';

export function Language(): JSX.Element {
    const {availableLocales} = useDomainConfig();
    const {localeName} = useLocale();

    const {onChangeLanguage} = useChangeLanguage();

    return (
        <div className={styles.Language}>
            <Text block stringKey="PROFILE__LANGUAGE__SELECT__LABEL" />
            <Select
                className={styles.Language_select}
                onChange={(value) => onChangeLanguage(value)}
                options={availableLocales.map((availableLocale) => {
                    return {label: localeNameToLanguageNameMap[availableLocale], value: availableLocale};
                })}
                value={localeName}
            />
        </div>
    );
}
