import {faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ButtonProps, Tooltip} from 'antd';
import {PropsWithChildren, useMemo} from 'react';

import {useLocale} from '../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {useSystem} from '../../../provider/system/system-hook';
import {classNames} from '../../../util/css';

import * as styles from './landing-onboarding-step.scss';

type PropsType = {
    arrowButtonProps: Omit<ButtonProps, 'className' | 'children'> & {
        hidden?: boolean;
        disabledTooltipLangKey?: LangKeyType | null;
    };
    direction?: 'row' | 'column';
};

export function LandingOnboardingStep(props: PropsWithChildren<PropsType>): JSX.Element {
    const {children, arrowButtonProps, direction} = props;

    const {hidden, disabledTooltipLangKey, ...buttonProps} = arrowButtonProps;
    const {getLocalizedString} = useLocale();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isDesktop} = screen;

    const containerDirection = useMemo(() => {
        if (direction) {
            return direction;
        }

        return isDesktop ? 'row' : 'column';
    }, [direction, isDesktop]);

    return (
        <div
            className={classNames(styles.LandingOnboardingStep, {
                [styles.LandingOnboardingStep__directionColumn]: containerDirection === 'column',
            })}
        >
            <div className={styles.LandingOnboardingStep_contentLimiter}>
                <div className={styles.LandingOnboardingStep_content}>{children}</div>
            </div>
            {!hidden && (
                <Tooltip
                    className={classNames(styles.LandingOnboardingStep_buttonWrapper, {
                        [styles.LandingOnboardingStep_buttonWrapper__directionRow]: containerDirection === 'row',
                    })}
                    title={disabledTooltipLangKey ? getLocalizedString(disabledTooltipLangKey) : ''}
                    trigger="hover"
                >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Button type="primary" {...buttonProps}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </Tooltip>
            )}
        </div>
    );
}
