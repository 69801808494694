import {TemporaryWorkDayDataType} from './temporary-workdays-form-item-type';

export const DEFAULT_TEMPORARY_WORK_DAY_DATA: TemporaryWorkDayDataType = {
    dateFrom: null,
    dateTo: null,
    isDayOff: false,
    timeFrom: null,
    timeTo: null,
    isAroundTheClock: false,
    breakTimeList: [],
};
