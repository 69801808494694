import {LocalPackFilterType} from '../../page/main/local-pack/local-pack-filter/local-pack-filter-type';
import {getLocalPackFilterValues} from '../../page/main/local-pack/local-pack-helper';
import {LocalPackAggregationTypeEnum} from '../../page/main/local-pack/position-distribution/position-distribution-type';
import {fetchAndDeserialize} from '../../util/api-adapter';
import {PaginationDataType} from '../../util/pagination-hook/pagination-hook-type';
import {ObjectToUrlParametersType} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {PaginatedResponseType} from '../api/api-type';

import {
    localPackCompanyInfoSchema,
    LocalPackCompanyInfoType,
    LocalPackPositionDistributionResponseType,
    localPackPositionDistributionSchema,
    localPackSearchResultsSchema,
    searchServiceConfigsSchema,
} from './local-pack-api-type';
import {
    LocalPackDataTableResultsResponseType,
    localPackDataTableResultsSchema,
    localPackGridDataSchema,
    LocalPackGridType,
    LocalPackMinimalAggregationResponseType,
    localPackMinimalAggregationSchema,
    LocalPackQueryResultsResponseType,
    localPackQueryResultsSchema,
    LocalPackSearchGridOptionsType,
    localPackSearchQuerySchema,
    LocalPackSearchQueryType,
    SearchResultItemType,
    SearchServiceQueriesType,
} from './local-pack-type';

export async function fetchLocalPackResults(options: {
    paginationOptions: Partial<PaginationDataType>;
    filter: LocalPackFilterType;
    mainFilterKey: string;
}): Promise<LocalPackDataTableResultsResponseType> {
    const {paginationOptions, mainFilterKey, filter} = options;

    const urlParameters: ObjectToUrlParametersType = {
        count: paginationOptions.pageSize,
        page: paginationOptions.page,
        filter_key: mainFilterKey,
        ...getLocalPackFilterValues(filter),
    };
    const url = `/cp/local_pack/data_table_results/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<LocalPackDataTableResultsResponseType>(url, localPackDataTableResultsSchema);
}

export async function fetchSearchServiceConfigs(companyId: number): Promise<Array<SearchServiceQueriesType>> {
    const urlParameters: ObjectToUrlParametersType = {
        company_id: companyId,
    };
    const url = `/cp/local_pack/search_service_configs/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<Array<SearchServiceQueriesType>>(url, searchServiceConfigsSchema);
}

export async function fetchLocalPackGrid(options: LocalPackSearchGridOptionsType): Promise<LocalPackGridType> {
    const {companyId, searchQueryId, searchServiceId, targetDate} = options;

    const urlParameters: ObjectToUrlParametersType = {
        company_id: companyId,
        search_query_id: searchQueryId,
        search_service_id: searchServiceId,
        target_date: targetDate,
    };
    const url = `/cp/local_pack/v2/search_grid/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<LocalPackGridType>(url, localPackGridDataSchema);
}

export async function fetchLocalPackPositionDistribution(options: {
    searchServices: Array<number>;
    aggregation: LocalPackAggregationTypeEnum;
    filter: LocalPackFilterType;
    mainFilterKey: string;
}): Promise<LocalPackPositionDistributionResponseType> {
    const {searchServices, aggregation, filter, mainFilterKey} = options;

    const urlParameters: ObjectToUrlParametersType = {
        search_services: searchServices,
        aggregation,
        ...getLocalPackFilterValues(filter),
        filter_key: mainFilterKey,
    };
    const url = `/cp/local_pack/position_distribution/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<LocalPackPositionDistributionResponseType>(url, localPackPositionDistributionSchema);
}

export async function fetchLocalPackSearchResults(
    searchQueryId: number,
    pointId: number
): Promise<Array<SearchResultItemType>> {
    const urlParameters: ObjectToUrlParametersType = {
        search_query_id: searchQueryId,
        search_point_id: pointId,
    };
    const url = `/cp/local_pack/v2/search_results/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<Array<SearchResultItemType>>(url, localPackSearchResultsSchema);
}

export async function fetchLocalPackMinimalAggregation(
    mainFilterKey: string
): Promise<LocalPackMinimalAggregationResponseType> {
    const urlParameters: ObjectToUrlParametersType = {
        filter_key: mainFilterKey,
    };
    const url = `/cp/local_pack/minimal_aggregation/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<LocalPackMinimalAggregationResponseType>(url, localPackMinimalAggregationSchema);
}

export async function fetchLocalPackQueryResults(options: {
    paginationOptions: Partial<PaginationDataType>;
    filter: LocalPackFilterType;
    mainFilterKey: string;
}): Promise<LocalPackQueryResultsResponseType> {
    const {paginationOptions, mainFilterKey, filter} = options;

    const urlParameters: ObjectToUrlParametersType = {
        page_size: paginationOptions.pageSize,
        page: paginationOptions.page,
        filter_key: mainFilterKey,
        ...getLocalPackFilterValues(filter),
    };
    const url = `/cp/local_pack/result_by_query/?${objectToUrlParameters(urlParameters)}`;

    return fetchAndDeserialize<LocalPackQueryResultsResponseType>(url, localPackQueryResultsSchema);
}

export function fetchLocalPackSearchQueries(): Promise<PaginatedResponseType<LocalPackSearchQueryType>> {
    const url = '/cp/local_pack/search_queries/';

    return fetchAndDeserialize<PaginatedResponseType<LocalPackSearchQueryType>>(
        url,
        localPackSearchQuerySchema.array()
    );
}

export function fetchLocalPackCompanyInfo(companyId: number): Promise<LocalPackCompanyInfoType> {
    return fetchAndDeserialize<LocalPackCompanyInfoType>(
        `/cp/local_pack/companies/${companyId}/`,
        localPackCompanyInfoSchema
    );
}
