import {Alert} from 'antd';

import {Locale} from '../../provider/locale/localization';

type PropsType = {
    message?: JSX.Element | string | Array<string>;
    description?: JSX.Element | string | Array<string>;
};

export function AlertFallback(props: PropsType): JSX.Element {
    const {message, description} = props;

    return (
        <Alert
            description={description || <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />}
            message={message || <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />}
            showIcon
            type="error"
        />
    );
}
