import {z as r} from 'zod';

import {ReviewsAnalysisModeEnum} from '../reivew-analysis-const';
import {SentimentEnum} from '../review-sentiment/review-sentiment-type';

export enum ReviewRateEnum {
    Rate5 = '5',
    Rate4 = '4',
    Rate3 = '3',
    Rate2 = '2',
    Rate1 = '1',
}

const reviewRateByTimeSchema = r.object({
    date: r.string(),
    value: r.number(),
});

export type ReviewRateByTimeType = r.infer<typeof reviewRateByTimeSchema>;

const reviewRateDynamicSchema = r.array(reviewRateByTimeSchema);

const reviewRatePerCompanySchema = r.object({
    id: r.number(),
    companyName: r.string(),
    companyAddress: r.string(),
    companyCode: r.string().optional().nullable(),
    ratingDynamic: r.array(reviewRateByTimeSchema),
});

export type ReviewRatePerCompanyType = r.infer<typeof reviewRatePerCompanySchema>;

export const reviewRatePerCompanyResponseSchema = r.object({
    companies: r.array(reviewRatePerCompanySchema),
    interval: r.array(r.string()),
    ratingDynamic: reviewRateDynamicSchema,
});

export type ReviewRatePerCompanyResponseType = r.infer<typeof reviewRatePerCompanyResponseSchema>;

const reviewRateCompetitorSchema = r.object({
    brandId: r.number(),
    avgRating: r.number(),
});

export type ReviewRateCompetitorType = r.infer<typeof reviewRateCompetitorSchema>;

const reviewRateCompetitorByTimeSchema = r.object({
    date: r.string(),
    brands: r.array(reviewRateCompetitorSchema),
});

export type ReviewRateCompetitorByTimeType = r.infer<typeof reviewRateCompetitorByTimeSchema>;

export type ReviewRateCompetitorsResponseType = Array<ReviewRateCompetitorByTimeType>;

export const reviewRateCompetitorsResponseSchema = r.array(reviewRateCompetitorByTimeSchema);

export type ReviewsAnalysisRateQueryType = {
    limit?: number;
    mode?: ReviewsAnalysisModeEnum;
    brandIds?: Array<number>;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
};
