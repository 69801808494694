import {DefaultTooltipStaticPropsType} from '../../../../component/chart-tooltip/default-chart-tooltip';
import {barChartOptions} from '../../../../layout/chart/bar-chart/bar-chart-helper';
import {BarDataType, ChartJsBarOptionsType} from '../../../../layout/chart/bar-chart/bar-chart-type';
import {getDefaultReactTooltipOptions} from '../../../../layout/chart/chart-const';
import {LocaleContextType} from '../../../../provider/locale/locale-context-type';
import {
    LocalPackPositionDistributionLabelsEnum,
    LocalPackPositionDistributionType,
} from '../../../../service/local-pack/local-pack-api-type';
import {LocalPackSearchServiceType} from '../../../../service/local-pack/local-pack-type';
import {NeverError} from '../../../../util/error';

import * as styles from './position-distribution.scss';

function getPercentageRow(row: Array<number>): Array<number> {
    const rowLength = row.length;

    if (!rowLength) {
        return row;
    }

    const rowSum = row.reduce((first, second) => first + second);

    const percentageRow = row.slice(0, rowLength - 1).map((rowElement: number) => {
        return Number(rowElement / rowSum);
    });

    const lastElement = 1 - percentageRow.reduce((first, second) => first + second);

    percentageRow.push(lastElement > 0 ? lastElement : 0);

    return percentageRow;
}

function calcPercentageData(data: Array<Array<number>>, labels: Array<string>): Array<Array<number>> {
    const convertedData = labels.map((_label, labelIndex) => data.map((dataRow) => dataRow[labelIndex] ?? 0));
    const convertedPercentageData = convertedData.map((row) => getPercentageRow(row));

    return data.map((_row, rowIndex) => convertedPercentageData.map((value) => value[rowIndex] ?? 0));
}

function getPositionDistributionBarColor(rawLabel: LocalPackPositionDistributionLabelsEnum): string {
    switch (rawLabel) {
        case LocalPackPositionDistributionLabelsEnum.High:
            return styles.position_destribution_color__high;
        case LocalPackPositionDistributionLabelsEnum.Middle:
            return styles.position_destribution_color__middle;
        case LocalPackPositionDistributionLabelsEnum.Low:
            return styles.position_destribution_color__low;
        case LocalPackPositionDistributionLabelsEnum.Lowest:
            return styles.position_destribution_color__lowest;
        case LocalPackPositionDistributionLabelsEnum.Wait:
            return styles.position_destribution_color__wait;
        case LocalPackPositionDistributionLabelsEnum.NoData:
            return styles.position_destribution_color__no_data;
        default:
            throw new NeverError(rawLabel);
    }
}

function getPositionDistributionLocalizedLabel(options: {
    getLocalizedString: LocaleContextType['getLocalizedString'];
    rawLabel: LocalPackPositionDistributionLabelsEnum;
}): string {
    const {getLocalizedString, rawLabel} = options;

    switch (rawLabel) {
        case LocalPackPositionDistributionLabelsEnum.High:
        case LocalPackPositionDistributionLabelsEnum.Middle:
        case LocalPackPositionDistributionLabelsEnum.Low:
        case LocalPackPositionDistributionLabelsEnum.Lowest:
            return rawLabel;
        case LocalPackPositionDistributionLabelsEnum.Wait:
            return getLocalizedString('LOCAL_PACK__RESULT__PENDING');
        case LocalPackPositionDistributionLabelsEnum.NoData:
            return getLocalizedString('TEXT__NO_DATA');
        default:
            throw new NeverError(rawLabel);
    }
}

export function getBarChartData(options: {
    getLocalizedString: LocaleContextType['getLocalizedString'];
    positionDistributionData: LocalPackPositionDistributionType;
    filteredSearchServices: Array<LocalPackSearchServiceType>;
}): BarDataType {
    const {getLocalizedString, filteredSearchServices, positionDistributionData} = options;

    const labels: Array<string> = positionDistributionData.result.map((result) => result.key);
    const data: Array<Array<number>> = positionDistributionData.labels.map((_label, labelIndex) => {
        const positionsData: Array<Array<number>> = positionDistributionData.result.map((result) => {
            return result.values
                .filter((value) =>
                    filteredSearchServices.some(
                        (searchService) =>
                            searchService.provider === value.provider && searchService.service === value.service
                    )
                )
                .map((value) => {
                    return value.positions[labelIndex] ?? 0;
                });
        });

        return positionsData.map((row) => {
            return row.length > 0 ? row.reduce((first, second) => first + second) : 0;
        });
    });

    const percentageData = calcPercentageData(data, labels);

    return {
        labels,
        datasets: positionDistributionData.labels
            .map((rawLabel, labelIndex) => {
                return {
                    label: getPositionDistributionLocalizedLabel({getLocalizedString, rawLabel}),
                    color: getPositionDistributionBarColor(rawLabel),
                    values: percentageData[labelIndex] ?? [],
                    barPercentage: 0.98,
                    categoryPercentage: 1,
                };
            })
            .reverse(),
    };
}

export function getChartOptions(tooltipOptions: DefaultTooltipStaticPropsType): ChartJsBarOptionsType {
    return {
        ...barChartOptions,
        scales: {
            ...barChartOptions.scales,
            y: {
                ...barChartOptions.scales?.y,
                ticks: {
                    display: false,
                },
                min: 0,
                max: 1,
            },
        },
        plugins: {
            ...barChartOptions.plugins,
            tooltip: getDefaultReactTooltipOptions(tooltipOptions),
        },
    };
}
