import {Space, Table} from 'antd';
import {useCallback, useContext, useMemo} from 'react';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {dateRangeToWorksheetName} from '../../../../../component/request-report-base/request-report-base-helper';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {fetchReviewCityList} from '../../../../../service/reivew-analysis/review-cities/review-cities-api';
import {useReviewCityList} from '../../../../../service/reivew-analysis/review-cities/review-cities-hook';
import {
    ReviewCityListQueryType,
    ReviewCityType,
} from '../../../../../service/reivew-analysis/review-cities/review-cities-type';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {ActiveFilter} from '../../components/active-filter/active-filter';
import {DrawerFilter} from '../../components/drawer-filter/drawer-filter';
import {RequestReport} from '../../components/request-report/request-report';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';

import {citiesDefaultPageSize, citiesPageSizeOptions} from './cities-page-const';
import {citiesPageColumns} from './cities-page-helper';
import {CityTableDataType} from './cities-page-type';

export function CitiesPage(): JSX.Element {
    const {reviewsAnalysisDashboard: dashboardRoute, reviewsAnalysisCities: citiesRoute} = appRoute;

    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    const {singleFilter} = useReviewsAnalysisFilter();

    const {result, isInProgress, processError, pagination} = useReviewCityList(singleFilter, mainFilterKey, {
        initialPageSize: citiesDefaultPageSize,
    });
    const {page, pageSize, onChange: onPaginationChange, total} = pagination;

    const getDataSource = useCallback((array: Array<ReviewCityType>, isForExport?: boolean) => {
        return array.map<CityTableDataType>(
            (dataItem: ReviewCityType, index): CityTableDataType => ({
                ...dataItem,
                key: `${dataItem.cityId} ${index} ${isForExport && 'export'}`,
            })
        );
    }, []);

    const dataSource: Array<CityTableDataType> = useMemo<Array<CityTableDataType>>(
        (): Array<CityTableDataType> => getDataSource(result?.results || []),
        [getDataSource, result?.results]
    );

    function formatDataForXls(data: Array<ReviewCityType>) {
        return data.map((city) => ({
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__CITY')]: city.cityName,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__RATE')]: city.avgRating.toFixed(1),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEW_TOTAL')]: city.total,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEWS_REPLIED')]: city.replied,
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE')]: getFormattedNumber(
                city.repliedPercent,
                {style: 'percent'}
            ),
            [getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__REPLY_DELAY')]: city.avgResponseSpeed
                ? getFormattedNumber(city.avgResponseSpeed, {style: 'unit', unit: TimeSizeEnum.minute})
                : '-',
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__POSITIVE'
            )}`]: city.sentiment.positive,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL'
            )}`]: city.sentiment.neutral,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE'
            )}`]: city.sentiment.negative,
            [`${getLocalizedString('REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT')}: ${getLocalizedString(
                'REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED'
            )}`]: city.sentiment.unspecified,
        }));
    }

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES')} />

            <BreadCrumbs
                list={[
                    {
                        path: dashboardRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                    },
                    {
                        path: citiesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle
                    productName={UsetifulNameProductEnum.REVIEWS_ANALYSIS__CITIES}
                    title="CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES"
                />

                <Space>
                    <RequestReport<CityTableDataType, ReviewCityType, ReviewCityListQueryType>
                        apiHandler={fetchReviewCityList}
                        columns={citiesPageColumns}
                        defaultFilename={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES')}
                        filters={{...singleFilter}}
                        formatDataForXls={formatDataForXls}
                        getDataSource={getDataSource}
                        title={<Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__CITIES" />}
                        xlsxWorksheetName={dateRangeToWorksheetName({
                            rawStartDate: singleFilter.startDate,
                            rawEndDate: singleFilter.endDate,
                        })}
                    />
                    <DrawerFilter />
                </Space>
            </PageHeader>

            <ActiveFilter
                isCompareRangeAllowed={false}
                isCompetitorAllowed={false}
                isPreview={false}
                isWithTextOnly={false}
            />

            {Boolean(processError) && (
                <AlertFallback description={processError?.message} message={processError?.name} />
            )}

            {!processError && (
                <Table<CityTableDataType>
                    columns={citiesPageColumns}
                    dataSource={dataSource}
                    loading={isInProgress}
                    pagination={{
                        total,
                        pageSize,
                        defaultPageSize: citiesDefaultPageSize,
                        pageSizeOptions: citiesPageSizeOptions,
                        current: page,
                        size: 'default',
                        showSizeChanger: true,
                        onChange: onPaginationChange,
                    }}
                    scroll={{x: true}}
                    size="middle"
                />
            )}
        </Page>
    );
}
