import {faPenToSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {ReviewDataType} from '../../../../../../../service/reviews/reviews-type';
import {ReviewFormModal} from '../../../../review-form/review-form-modal';
import {ReviewFormModeEnum} from '../../../../review-form/review-form-type';
import * as styles from '../review.scss';

type PropsType = {
    review: ReviewDataType;
    onSubmitFinish: () => void;
};

export function ReviewEdit(props: PropsType): JSX.Element {
    const {review, onSubmitFinish} = props;

    const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);

    function handleEditClick() {
        setIsReviewFormOpen(true);
    }

    return (
        <>
            <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_EDIT__HINT" />}>
                <button className={styles.Review_iconButton} onClick={handleEditClick} type="button">
                    <FontAwesomeIcon icon={faPenToSquare} />
                </button>
            </Tooltip>

            <ReviewFormModal
                isOpen={isReviewFormOpen}
                mode={ReviewFormModeEnum.edit}
                onCancel={() => setIsReviewFormOpen(false)}
                onSubmitFinish={onSubmitFinish}
                review={review}
            />
        </>
    );
}
