import {Form, FormInstance, Radio} from 'antd';
import React, {ReactElement} from 'react';

import {useLocale} from '../../provider/locale/locale-hook';
import {classNames} from '../../util/css';
import {FormActiveMark} from '../form-active-mark/form-active-mark';

import * as styles from './form-item-radio.scss';

type OptionType<T> = {
    label: JSX.Element;
    value: T;
};

type PropsType<T> = {
    name: string;
    options: Array<OptionType<T>>;
    onClick?: (value: T) => void;
    label: ReactElement;
    fieldStateValue: T;
    className?: string;
    form: FormInstance;
};

export function FormItemRadio<T>(props: PropsType<T>): JSX.Element {
    const {name, label, options, onClick, fieldStateValue, className, form} = props;

    function handleClick(event: React.MouseEvent<HTMLElement>): void {
        const target = event.target as HTMLInputElement;
        const {value} = target;

        target.blur();

        if (onClick) {
            onClick((String(value) === String(form.getFieldValue(name)) ? null : value) as T);
        }
    }

    const {getLocalizedString} = useLocale();

    return (
        <div className={classNames(styles.FormItemRadio, className)}>
            <FormActiveMark isVisible={Boolean(fieldStateValue)} />

            <Form.Item initialValue={fieldStateValue} label={label} name={name}>
                <Radio.Group
                    className={styles.FormItemRadio_radioGroup}
                    optionType="button"
                    size="small"
                    value={form.getFieldValue(name)}
                >
                    {options.map((option) => (
                        <Radio key={option.value as string} onClick={handleClick} value={option.value}>
                            <span
                                className={styles.FormItemRadio_radioTitle}
                                title={getLocalizedString(option.label.props.stringKey)}
                            >
                                {option.label}
                            </span>
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        </div>
    );
}
