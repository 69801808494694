import {faUndo} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Segmented, Tag, Tooltip} from 'antd';
import {isEqual} from 'lodash';
import {Dispatch, SetStateAction} from 'react';

import {SelectWithCheckboxes} from '../../../../component/select-with-checkboxes/select-with-checkboxes';
import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSystem} from '../../../../provider/system/system-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {FakesFiltersType} from '../../../../service/fakes/fakes-results';
import {useFakesSubscriptions} from '../../../../service/fakes/fakes-subscriptions';
import {FakeClientStatusEnum, FakeTypeEnum} from '../../../../service/fakes/fakes-type';
import {ProvidersIdsEnum} from '../../../../util/type';
import {useFakesDefaultFilters} from '../fakes-page-hook';
import {FAKE_CLIENT_STATUS_TO_TAGS} from '../table/columns/status/status-column-const';

import {FAKES_FILTERS_STATUSES_WITH_LONG_NAMES, FAKES_FILTERS_TYPE_OPTIONS} from './fakes-filters-const';
import * as styles from './fakes-filters.scss';
import {fakes_filters_breakpoint} from './fakes-filters-export.scss';

type PropsType = {
    filters: FakesFiltersType | null;
    setFilters: Dispatch<SetStateAction<FakesFiltersType | null>>;
};

// eslint-disable-next-line complexity
export function FakesFilters(props: PropsType): JSX.Element {
    const {filters, setFilters} = props;

    const defaultFilters = useFakesDefaultFilters();
    const {data: subscriptions} = useFakesSubscriptions();

    const {getCatalogName} = useCatalogInfo();
    const {screen} = useSystem();
    const {getLocalizedString} = useLocale();

    const isVerticalLayout = screen.width <= Number.parseInt(fakes_filters_breakpoint, 10);

    const {user} = useUser();

    function handleStatusChange(status: FakeClientStatusEnum, checked: boolean) {
        setFilters((oldFilters) => {
            if (!oldFilters) {
                return oldFilters;
            }

            const clientStatuses = checked
                ? [...(oldFilters.clientStatuses ?? []), status]
                : (oldFilters.clientStatuses ?? []).filter((clientStatus) => clientStatus !== status);

            return {...oldFilters, clientStatuses};
        });
    }

    const resetButton = defaultFilters && !isEqual(filters, defaultFilters) && (
        <div className={styles.FakesFilters_resetButton}>
            <Tooltip placement="left" title={<Locale stringKey="FAKES__RESULTS__FILTERS__RESET__TOOLTIP" />}>
                <Button
                    icon={<FontAwesomeIcon icon={faUndo} />}
                    onClick={() => setFilters(defaultFilters)}
                    size={isVerticalLayout ? 'small' : 'middle'}
                >
                    {isVerticalLayout && (
                        <span>
                            <Locale stringKey="FAKES__RESULTS__FILTERS__RESET__MOBILE" />
                        </span>
                    )}
                </Button>
            </Tooltip>
        </div>
    );

    return (
        <PageCard bodyClassName={styles.FakesFilters}>
            <section className={styles.FakesFilters_topSection}>
                <Segmented
                    onChange={(newType) => setFilters({...filters, type: newType ? (newType as FakeTypeEnum) : null})}
                    options={FAKES_FILTERS_TYPE_OPTIONS}
                    value={filters?.type ?? 0}
                />

                <SelectWithCheckboxes
                    className={styles.FakesFilters_select}
                    initialValue={filters?.catalogIds ?? []}
                    onConfirm={(newCatalogIds) => setFilters({...filters, catalogIds: newCatalogIds})}
                    options={[ProvidersIdsEnum.google, ProvidersIdsEnum.yandex]
                        .filter((id) => {
                            return user?.regionAvailableCatalogs[id];
                        })
                        .map((providerId) => ({
                            value: providerId,
                            label: getCatalogName(providerId),
                        }))}
                    placeholder={getLocalizedString('FAKES__RESULTS__FILTERS__CATALOGS__PLACEHOLDER')}
                />

                <SelectWithCheckboxes
                    className={styles.FakesFilters_select}
                    initialValue={filters?.brandIds ?? []}
                    onConfirm={(newBrandIds) => setFilters({...filters, brandIds: newBrandIds})}
                    options={(subscriptions ?? []).map(({brandId, brandName}) => ({
                        value: brandId,
                        label: brandName,
                    }))}
                    placeholder={getLocalizedString('FAKES__RESULTS__FILTERS__BRANDS__PLACEHOLDER')}
                />

                {!isVerticalLayout && resetButton}
            </section>

            <section className={styles.FakesFilters_bottomSection}>
                <Text
                    className={styles.FakesFilters_statusesText}
                    lighter
                    stringKey="FAKES__RESULTS__FILTERS__STATUSES"
                />

                <div className={styles.FakesFilters_statuses}>
                    {FAKE_CLIENT_STATUS_TO_TAGS.map((tag) => (
                        <Tag.CheckableTag
                            checked={(filters?.clientStatuses ?? []).includes(tag.id)}
                            key={tag.id}
                            onChange={(checked) => handleStatusChange(tag.id, checked)}
                        >
                            <Locale
                                stringKey={
                                    FAKES_FILTERS_STATUSES_WITH_LONG_NAMES.includes(tag.id) ? tag.tooltip : tag.text
                                }
                            />
                        </Tag.CheckableTag>
                    ))}
                </div>

                {isVerticalLayout && resetButton}
            </section>
        </PageCard>
    );
}
