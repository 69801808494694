import {classNames} from '../../../../../../util/css';

// import {ReactComponent as LogoFullSvg} from './image/logo-full.svg';
import twoGisLogoFullSrc from './image/two-gis-logo-full.png';
import * as styles from './logo-full.scss';

type PropsType = {
    className?: string;
};

export function LogoFull(props: PropsType): JSX.Element {
    const {className} = props;

    const fullClassName = classNames(styles.logo_full, className);

    return <img alt="" className={fullClassName} src={twoGisLogoFullSrc} />;
}
