import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Flex, Space} from 'antd';
import {useCallback, useMemo, useState} from 'react';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {LocalePlural} from '../../../../provider/locale/locale-plural';
import {Locale} from '../../../../provider/locale/localization';
import {useModal} from '../../../../provider/modal/modal-hook';
import {useReviewsAbuseReason} from '../../../../service/reviews/reviews-abuse-hook';
import {
    ReviewsGmbCclStatusEnum,
    useReviewsGmbCclStatuses,
} from '../../../../service/reviews/reviews-gmb-confirmation-hook';
import {ReviewSuggestionType} from '../../../../service/reviews/reviews-suggestions';
import {classNames} from '../../../../util/css';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';
import {getReplyCount} from '../review-item-page-content/activity-area/activity-area-help';
import {ReviewAbusePopover} from '../review-list-page-content/review-list/review/reply-form/abuse-tag/review-abuse-popover';
import {NewAbuseModal} from '../review-list-page-content/review-list/review/reply-form/new-abuse-modal/new-abuse-modal';
import {NewReplyForm} from '../review-list-page-content/review-list/review/reply-form/new-reply-form';
import {ReviewCondition} from '../review-list-page-content/review-list/review/review-condition/review-condition';
import {SuggestionItem} from '../review-list-page-content/review-list/review/suggestion-item/suggestion-item';
import {ReviewDataWithCatalogType} from '../review-list-page-content/review-list/review-list-type';
import {isReviewReplyAllowed} from '../reviews-helper';
import {useReviewStateHook} from '../reviews-state/reviews-state-provider';

import {NewAnswersBunch} from './new-answers-bunch/new-answers-bunch';
import * as styles from './review-answers.scss';

type PropsType = {
    review: ReviewDataWithCatalogType;
    refreshReview: (reviewId: number) => Promise<unknown>;
    refreshSuggestions: () => Promise<unknown>;
    suggestion?: ReviewSuggestionType;
    isCollapsedAnswers?: boolean;
    isGptSuggestSupported?: boolean;
};

// eslint-disable-next-line complexity,max-statements
export function NewReviewAnswers(props: PropsType): JSX.Element {
    const {review, refreshReview, isCollapsedAnswers, suggestion, refreshSuggestions, isGptSuggestSupported} = props;
    const {
        children,
        id: reviewId,
        deletedAt,
        brand,
        createdInCatalog,
        replyTimeInCatalog,
        replyTimeInRd,
        ableToAbuse,
        abuseLastDate,
        hasAccountError,
        lastChangesDate,
        moderated,
        catalog,
    } = review;
    const isDeleted = Boolean(deletedAt);
    const [isOpenAbuseModal, setIsOpenAbuseModal] = useState<boolean>(false);

    const {replyAreaRef, isRepliesActive, onRepliesActivityPress} = useReviewActivityHook();
    const {reviewIds, reviewsScrapyCatalogsList = [], googleMapsReviewIds} = useReviewStateHook();
    const [isOpen, setIsOpen] = useState(false);

    const {data: reviewsAbuseReason, refetch} = useReviewsAbuseReason(reviewIds);
    const {data: reviewGmbStatuses} = useReviewsGmbCclStatuses(googleMapsReviewIds);
    const {companyName} = useDomainConfig();
    const {modal} = useModal();
    const {pushUrl} = useUrl();

    const isNeedConfirmationStatus =
        reviewGmbStatuses &&
        (reviewGmbStatuses[reviewId] === ReviewsGmbCclStatusEnum.WaitVerify ||
            reviewGmbStatuses[reviewId] === ReviewsGmbCclStatusEnum.NotVerified);

    const reviewAbuseReason = (reviewsAbuseReason || []).find((reviewReason) => reviewReason.id === reviewId) || null;

    function handleRefreshReview(id: number): Promise<unknown> {
        refetch();
        return refreshReview(id);
    }

    const isAnswerUnavailable = Boolean(review && !isReviewReplyAllowed(review));
    const isReviewDeleted = Boolean(deletedAt);
    const isReplyVisible = isReviewReplyAllowed(review) && !isNeedConfirmationStatus;
    const replyCount = useMemo(() => getReplyCount(children), [children]);
    const scrapyCatalog = reviewsScrapyCatalogsList?.find((item) => item.id === catalog?.id);

    const handleClick = useCallback(() => {
        if (isNeedConfirmationStatus) {
            modal.confirm({
                icon: <FontAwesomeIcon className={styles.ReviewAnswers_modalIcon} icon={faCircleInfo} size="xl" />,
                maskClosable: true,
                title: <Locale stringKey="REVIEW__ANSWER__CONFIRMATION_MODAL__TITLE" />,
                content: <Locale stringKey="REVIEW__ANSWER__CONFIRMATION_MODAL__CONTENT" valueMap={{companyName}} />,
                okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
                cancelText: <Locale stringKey="BUTTON__CANCEL" />,
                onOk: () => {
                    pushUrl(generatePath(appRoute.sourcesAccountSyncGoogleConfirmations.path));
                },
            });
        } else {
            setIsOpenAbuseModal(true);
        }
    }, [isNeedConfirmationStatus, modal, companyName, pushUrl]);

    return (
        <Flex align="center" className={styles.ReviewAnswers_answerContainer} justify="space-between" vertical wrap>
            {children.length > 0 && (
                <ul
                    className={classNames(styles.ReviewAnswers_answerList, {
                        [styles.disabled]: isDeleted,
                    })}
                >
                    <NewAnswersBunch
                        answerList={children}
                        createdInCatalog={createdInCatalog}
                        isFirst
                        isOnlyLastAnswer={isCollapsedAnswers && !isRepliesActive}
                        isTestBrand={brand.isTest}
                        lastChangesData={lastChangesDate}
                        refreshReview={refreshReview}
                        replyTimeInCatalog={replyTimeInCatalog}
                        replyTimeInRd={replyTimeInRd}
                        reviewId={reviewId}
                    />
                </ul>
            )}

            <Flex className={styles.ReviewAnswers_actionContainer}>
                {!isOpen && (
                    <Space>
                        {isReplyVisible && (
                            <Button onClick={() => setIsOpen(true)} type="primary">
                                <Locale stringKey={replyCount > 0 ? 'REVIEWS__ADD_REPLY' : 'REVIEWS__REPLY'} />
                            </Button>
                        )}
                        <ReviewCondition
                            hasAccountError={hasAccountError}
                            hasCompanyComment={children.some((child) => child.isCompanyComment)}
                            isAnswerUnavailable={isAnswerUnavailable}
                            isNeedConfirmationStatus={Boolean(isNeedConfirmationStatus)}
                            isReviewDeleted={isReviewDeleted}
                            moderated={moderated}
                            scrapyCatalogInfo={scrapyCatalog}
                        />
                        {replyCount > 1 && (
                            <Button disabled={replyCount === 0} onClick={onRepliesActivityPress} type="text">
                                <LocalePlural
                                    count={replyCount}
                                    fewKey="REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__FEW"
                                    manyKey="REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__MANY"
                                    singularKey="REVIEW_ITEM_PAGE__ACTIVITY__REPLIES__SINGLE"
                                    valueMap={{count: replyCount}}
                                />
                                {/* eslint-disable-next-line no-undefined*/}
                                <FontAwesomeIcon icon={faChevronDown} rotation={isRepliesActive ? 180 : undefined} />
                            </Button>
                        )}
                    </Space>
                )}
                {isReplyVisible && (
                    <NewReplyForm
                        isGptSuggestSupported={isGptSuggestSupported}
                        isOpen={isOpen}
                        refreshReview={refreshReview}
                        replyAreaRef={replyAreaRef}
                        reviewData={review}
                        setIsOpen={setIsOpen}
                        shouldCollapseOnClose={isCollapsedAnswers}
                    />
                )}

                {!isOpen && (
                    <Space>
                        <ReviewAbusePopover
                            abuseLastDate={abuseLastDate}
                            onAbuseModalOpen={() => setIsOpenAbuseModal(true)}
                            reviewAbuseReason={reviewAbuseReason}
                        />
                        {!abuseLastDate && ableToAbuse && (
                            <Button onClick={handleClick}>
                                <Locale stringKey="TEXT__REPORT" />
                            </Button>
                        )}
                    </Space>
                )}
            </Flex>
            {suggestion && (
                <div className={styles.ReviewAnswers_suggestionItem}>
                    <SuggestionItem
                        refreshReview={refreshReview}
                        refreshSuggestions={refreshSuggestions}
                        reviewId={reviewId}
                        suggestion={suggestion}
                    />
                </div>
            )}
            {isOpenAbuseModal && (
                <NewAbuseModal refreshReview={handleRefreshReview} reviewId={reviewId} setOpen={setIsOpenAbuseModal} />
            )}
        </Flex>
    );
}
