import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

import {useUser} from '../../provider/user/user-hook';
import {useAnalytics} from '../../service/analytics/analytics-hook';
import {useMenuNavigation} from '../app/routing/hooks/use-app-navigation';

export function Analytics(): null {
    const userContext = useUser();
    const {user} = userContext;
    const isAnalyticsEnabled = Boolean(user);
    const {pathname} = useLocation();
    const {trackNavigation, initialize} = useAnalytics();
    const previousPathnameRef = useRef<string>('');
    const menuItems = useMenuNavigation();

    useEffect(() => {
        if (user) {
            initialize(user);
        }
    }, [user, initialize]);

    useEffect(() => {
        if (isAnalyticsEnabled) {
            trackNavigation(pathname, previousPathnameRef.current, menuItems);
            previousPathnameRef.current = pathname;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnalyticsEnabled, trackNavigation, pathname]);

    return null;
}
