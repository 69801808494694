export const enTariffsAndLicensesTranslations = {
    TARIFFS__LINK_TITLE__SHOW_ALL_TARIFFS: 'View all tariffs',
    TARIFFS__PAID_PERIOD: 'Paid period',
    TARIFFS__DEMO_TARIFF: 'DEMO',
    TARIFFS__TEST_TARIFF: 'TEST',
    TARIFFS__ALL_BRANDS: 'Brands: {count}',
    TARIFFS__COMPANIES: 'Companies',
    TARIFFS__BRAND__SINGULAR: 'Another {count} brand...',
    TARIFFS__BRAND__FEW: 'Another {count} brands...',
    TARIFFS__BRAND__MANY: 'Another {count} brands...',
    TARIFFS__CURRENT: 'Current rate',
    TARIFFS__LICENSE_BLOCK__ACTIVE_TEST__NAME: 'Active test licenses',
    TARIFFS__LICENSE_BLOCK__ACTIVE_TEST__DESCRIPTION: 'Licenses can be purchased after the end of the trial period',
    TARIFFS__LICENSE_BLOCK__AVAILABLE_LICENSES__NAME: 'Available trial licenses',
    TARIFFS__LICENSE_BLOCK__AVAILABLE_LICENSES__DESCRIPTION: 'Test functionality for free without obligations',
    TARIFFS__LICENSE_BLOCK__LIMIT_LICENSES__NAME: 'Licenses with limits',
    TARIFFS__LICENSE_BLOCK__LIMIT_LICENSES__DESCRIPTION: 'Purchase additional limits as needed',
    TARIFFS__LICENSE_BLOCK__ADDITIONAL_LICENSES__NAME: 'Additional licenses',
    TARIFFS__LICENSE_BLOCK__MODAL__BUTTON__TITLE: 'View periods',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__DATES: 'Validity period',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__DIALOGS: 'Dialogues',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__CATALOGS: 'Catalogs',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__LIMIT: 'Limit',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__USERS: 'Users',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__VARIANT: 'Variant',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__SOURCES: 'Sources',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__REQUESTS: 'Requests',
    TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__TYPES: 'Product type',
    TARIFFS__LICENSE_BLOCK__LIMIT_IS_OVER: 'Limit is reached',
    TARIFFS__LICENSE_BLOCK__TAG_TITLE__TEST: 'Test',
    TARIFFS__LICENSE_BLOCK__TAG_TITLE__TEST_AVAILABLE: 'Test available',
    TARIFFS__LICENSE_BLOCK__TAG_TITLE__ACTIVE: 'Active',
    TARIFFS__LICENSE_BLOCK__TAG_TITLE__NOT_ACTIVE: 'Not active',
    TARIFFS__LICENSE_BLOCK__TAG_TITLE__BONUS: 'Bonus',
    TARIFFS__LICENSE_BLOCK__BUY_BUTTON__ACTIVATE: 'Activate',
    TARIFFS__LICENSE_BLOCK__BUY_BUTTON__BUY: 'Buy',
    TARIFFS__LICENSE_BLOCK__BUY_BUTTON__CHANGE_LIMIT: 'Change limit',
    TARIFFS__LICENSE_BLOCK__CATALOG_LIMIT__SINGULAR: '{count} catalog',
    TARIFFS__LICENSE_BLOCK__CATALOG_LIMIT__FEW: '{count} catalogs',
    TARIFFS__LICENSE_BLOCK__CATALOG_LIMIT__MANY: '{count} catalogs',
    TARIFFS__LICENSE_BLOCK__PRODUCT_TYPE__BASE_REVIEW: 'Basic review analytics',
    TARIFFS__LICENSE_BLOCK__PRODUCT_TYPE__COMPETITOR_REVIEW: 'Competitive review analytics',
    TARIFFS__LICENSE_BLOCK__PRODUCT_TYPE__FULL_REVIEW: 'Advanced review analytics',
    TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__ANALYTICS: 'Analytics',
    TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__TAGS: 'Tags',
    TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__KEY: 'Tone',
    TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__TEMPLATES: 'Templates',
    TARIFFS__MODAL__ACTIVE_PRODUCT: 'Active product and current limits',
    TARIFFS__MODAL__ADDITIONAL_FEATURES: 'Additional features',
    TARIFFS__MODAL__TITLE__LIMITS: 'Request for limit increase',
    TARIFFS__MODAL__TEST__TITLE: 'Connecting a trial period',
    TARIFFS__MODAL__TEST__PRODUCTS_AND_LIMITS: 'Trial product and limits',
    TARIFFS__MODAL__TEST__DURATION: 'Duration of the trial period',
    TARIFFS__MODAL__TEST__DURATION__DESCRIPTION: '14 days until {date}',
    TARIFFS__MODAL__TEST__SNACKBAR__TITLE: 'Trial period connected',
    TARIFFS__MODAL__TEST__SNACKBAR__DESCRIPTION: 'The service will be available for 14 days.',
    TRIAL_PERIOD_MODAL__TITLE: 'Connecting a trial period',
    TRIAL_PERIOD_MODAL__SERVICE: 'Trial product',
    TRIAL_PERIOD_MODAL__TEST_PERIOD: 'Trial period duration',
    TRIAL_PERIOD_MODAL__TEST_PERIOD__ACTIVATE: 'Connect',
    TRIAL_PERIOD_MODAL__TEST_PERIOD__DURATION__SINGLE: '{trialDuration} day until {endDate}',
    TRIAL_PERIOD_MODAL__TEST_PERIOD__DURATION__FEW: '{trialDuration} days until {endDate}',
    TRIAL_PERIOD_MODAL__TEST_PERIOD__DURATION__PLURAL: '{trialDuration} days until {endDate}',
    TRIAL_PERIOD_MODAL__SUCCESS__MESSAGE: 'Trial period connected',
    TRIAL_PERIOD_MODAL__SUCCESS__DESCRIPTION: 'The service will be available until {endDate}.',
    TRIAL_PERIOD_MODAL__ERROR__MESSAGE: 'Error',
    TRIAL_PERIOD_MODAL__ERROR__DESCRIPTION:
        'Failed to send the connection request. Please try again later or contact your manager.',

    LICENSE_CARD__TITLE: 'License',
    LICENSE_CARD__PRODUCT__LOCAL_PACK: 'Local Positions Tracker',
    LICENSE_CARD__SNACKBAR__SUCCESS_MESSAGE: 'Request successfully sent',
    LICENSE_CARD__SNACKBAR__SUCCESS_DESCRIPTION: 'Our manager will contact you to clarify the details',
    LICENSE_CARD__SNACKBAR__ERROR_MESSAGE: 'Error',
    LICENSE_CARD__SNACKBAR__ERROR_DESCRIPTION:
        'Failed to send the connection request. Please try again later or contact your manager.',
    LICENSE_CARD__PRODUCT__DESCRIPTION_TRIAL: 'Trial period',
    LICENSE_CARD__PRODUCT__DESCRIPTION_NOT_CONNECTED: 'Service not connected',
    LICENSE_CARD__BUY_LICENSE: 'Buy License',
    LICENSE_CARD__INFORMATION__LICENSE_EXPIRED__TITLE: 'License Expired',
    LICENSE_CARD__INFORMATION__TRIAL_EXPIRED__TITLE: 'Trial Period Expired',
    LICENSE_CARD__INFORMATION__EXPIRED__DESCRIPTION: 'Expiration date reached',
    LICENSE_CARD__INFORMATION__LICENSE__TITLE: 'License',
    LICENSE_CARD__INFORMATION__LICENSE__DESCRIPTION: 'To be purchased from your personal manager',
    LICENSE_CARD__INFORMATION__LICENSE__REQUESTED__DESCRIPTION: 'Connection request sent',
    LICENSE_CARD__INFORMATION__LICENSE__TIME__TITLE: 'Validity Period',
    LICENSE_CARD__INFORMATION__LICENSE__TIME__DESCRIPTION: 'Until {date}',
    LICENSE_CARD__INFORMATION__LICENSE__COST__TITLE: 'Cost',
    LICENSE_CARD__INFORMATION__LICENSE__COST__DESCRIPTION: 'Free',

    LICENSE_SNACKBAR__NOT_ACTIVE__TITLE: 'License Not Active',
    LICENSE_SNACKBAR__NOT_ACTIVE__MESSAGE: 'Purchase a license to resume service operation.',

    LANDING__TEST_PERIOD: 'Test period {duration} days',
    LANDING__TEST_PERIOD__TITLE: 'Free trial period',
};
