import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {handleReplyError} from './reviews-helper';
import {ReviewsReplyType} from './reviews-reply';
import {FetchReviewsResultType} from './reviews-type';

export function fetchReviewsCommandEdit(
    reviewId: number,
    commandId: number,
    data: ReviewsReplyType
): Promise<ReviewsReplyType> {
    return fetchX<ReviewsReplyType>(`${rootApiUrl}/v4/reviews/${reviewId}/commands/${commandId}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(data),
    }).catch(handleReplyError);
}

export function fetchReviewsCommandRemove(reviewId: number, commandId: number): Promise<FetchReviewsResultType> {
    return fetchX<FetchReviewsResultType>(`${rootApiUrl}/v4/reviews/${reviewId}/commands/${commandId}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
