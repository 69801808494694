import {Space, Table} from 'antd';
import {FilterValue} from 'antd/lib/table/interface';
import {useMemo} from 'react';

import {CursorPaginator} from '../../../../component/cursor-paginator/cursor-paginator';
import {PaginatedResponseType} from '../../../../service/api/api-type';
import {UseHookType} from '../../../../service/api-hook/api-hook-type';
import {CclDataType, CclTableDataType} from '../../../../service/ccl/ccl-type';
import {CATALOG_TYPES} from '../../../../service/ccl-statistic/ccl-statistic-const';
import {useYandexSourcesStories} from '../../../../service/yandex-stories/yandex-stories-hook';
import {CatalogTypeSearchParameterEnum} from '../../../../shared-search-parameters';
import {findInArrayByValueEnsure} from '../../../../util/array';
import {CursorPaginationType} from '../../../../util/cursor-pagination/cursor-pagination-type';
import {getFromLocalStorage} from '../../../../util/local-storage';
import {toTrimmedString} from '../../../../util/string';
import {CclStateStatusEnum, ProvidersIdsEnum} from '../../../../util/type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {NewCompaniesCheckbox} from '../../my-companies/companies-table/new-companies-checkbox/new-companies-checkbox';
import {productGroupsSizeOptions} from '../../price/products-const';
import {SourcesSearchParametersEnum} from '../sources-type';

import {useSourcesTableColumns} from './sources-table-hook';
import {
    onboardingLocalstorageSchema,
    SourcesTourNameEnum,
    sourcesTourSchema,
    SourcesTourStateEnum,
    SourcesTourType,
} from './sources-table-type';
import {
    SHOW_STATUS_ONBOARDING_LOCALSTORAGE_KEY,
    USETIFUL_SOURCE_ONBOARDING_LOCALSTORAGE_KEY,
} from './status-column-const';
import * as styles from './sources-table.scss';

type PropsType = {
    sourceIds: Array<string>;
    cclData: UseHookType<PaginatedResponseType<CclDataType>> & CursorPaginationType;
};

export function SourcesTable(props: PropsType): JSX.Element {
    const {sourceIds, cclData} = props;

    const {getQuery, setQuery} = useUrl();

    const catalogType = findInArrayByValueEnsure(CATALOG_TYPES, getQuery(CatalogTypeSearchParameterEnum.catalogType));
    const isNewCompanies = getQuery(SourcesSearchParametersEnum.isNewCompanies) === 'true';

    const firstSyncItem = cclData.result?.results.find((ccl) => ccl.stateStatus === CclStateStatusEnum.sync);
    const isShowOnboardingPopover = getFromLocalStorage(
        SHOW_STATUS_ONBOARDING_LOCALSTORAGE_KEY,
        onboardingLocalstorageSchema,
        true
    );
    const isHideUsetifulSourcesOnboarding = getFromLocalStorage<SourcesTourType>(
        USETIFUL_SOURCE_ONBOARDING_LOCALSTORAGE_KEY,
        sourcesTourSchema,
        []
    ).find((tour) => tour.name === SourcesTourNameEnum.Sources && tour.state === SourcesTourStateEnum.Closed);

    const firstSyncItemData = {
        id: firstSyncItem?.id,
        catalogId: firstSyncItem?.catalog.id,
    };

    const columns = useSourcesTableColumns(
        catalogType,
        sourceIds,
        firstSyncItemData,
        isShowOnboardingPopover && Boolean(isHideUsetifulSourcesOnboarding)
    );

    const dataSource = useMemo(() => {
        const startIndex = (cclData.pagination.resultPage - 1) * cclData.pagination.pageSize;
        const endIndex = startIndex + cclData.pagination.pageSize;

        return cclData.result?.results.slice(startIndex, endIndex) || [];
    }, [cclData.pagination.pageSize, cclData.pagination.resultPage, cclData.result?.results]);

    const yandexSourcesIds = dataSource
        .filter((source) => source.catalog.id === ProvidersIdsEnum.yandex)
        .map((source) => source.company.id);

    const {data: yandexStories} = useYandexSourcesStories(yandexSourcesIds);

    const dataSourceWithStories = useMemo(() => {
        return dataSource.map((source) => {
            return {
                ...source,
                ...(source.catalog.id === ProvidersIdsEnum.yandex
                    ? {stories: yandexStories?.get(source.company.id)}
                    : {}),
            };
        });
    }, [dataSource, yandexStories]);

    function handleTableChange(filters: Record<string, FilterValue | null>) {
        setQuery({
            [SourcesSearchParametersEnum.SourceIdList]: [...(filters.source || [])].map(toTrimmedString).join(','),
        });
    }

    return (
        <>
            <Space className={styles.TableWrapper} direction="vertical" size="small">
                <NewCompaniesCheckbox
                    isNewCompanies={isNewCompanies}
                    setIsNewCompanies={(value: boolean) =>
                        setQuery({[SourcesSearchParametersEnum.isNewCompanies]: value})
                    }
                />
                <Table<CclTableDataType>
                    columns={columns}
                    dataSource={dataSourceWithStories}
                    loading={cclData.isInProgress}
                    onChange={(_action, filters) => handleTableChange(filters)}
                    pagination={false}
                    rowKey={(item) => String(item.id) + String(item.catalog.id)}
                    scroll={{x: true}}
                    size="middle"
                />
            </Space>
            <CursorPaginator
                cursorPagination={cclData.pagination}
                dataLength={dataSourceWithStories.length}
                lastPage={cclData.lastPage}
                pageSizeOptions={productGroupsSizeOptions}
            />
        </>
    );
}
