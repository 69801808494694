import {LocaleContextType} from '../../../provider/locale/locale-context-type';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {CompanyKeyEnum} from '../../../service/company-v2/company-const';

import {CompanyFieldFillType, WidgetNamesType} from './company-form-type';

export const DATA_WIDGET_KEY_ATTR = 'data-widget-key';

export enum WidgetKeysEnum {
    names = 'names',
    brand = 'brand',
    address = 'address',
    legal = 'legal',
    categories = 'categories',
    contactInfo = 'contactInfo',
    links = 'links',
    workingTime = 'workingTime',
    temporaryWorkingTime = 'temporaryWorkingTime',
    paymentMethod = 'paymentMethod',
    description = 'description',
    logo = 'logo',
    gallery = 'gallery',
    attributes = 'attributes',
    deletion = 'deletion',
}

export enum Unsupported2GisFacebookCountryEnum {
    RussianFederation = 1,
}

export const companyKeyToWidgetKeyMap: Record<CompanyKeyEnum | string, WidgetKeysEnum> = {
    [CompanyKeyEnum.Names]: WidgetKeysEnum.names,
    [CompanyKeyEnum.ExtraNames]: WidgetKeysEnum.names,
    [CompanyKeyEnum.SearchNames]: WidgetKeysEnum.names,
    [CompanyKeyEnum.Code]: WidgetKeysEnum.brand,
    [CompanyKeyEnum.BrandId]: WidgetKeysEnum.brand,
    [CompanyKeyEnum.BrandName]: WidgetKeysEnum.brand,
    [CompanyKeyEnum.Address]: WidgetKeysEnum.address,
    [CompanyKeyEnum.Legal]: WidgetKeysEnum.legal,
    [CompanyKeyEnum.RocketdataCategories]: WidgetKeysEnum.categories,
    [CompanyKeyEnum.CatalogCategories]: WidgetKeysEnum.categories,
    [CompanyKeyEnum.MappedCatalogCategories]: WidgetKeysEnum.categories,
    [CompanyKeyEnum.Phones]: WidgetKeysEnum.contactInfo,
    [CompanyKeyEnum.ExtraPhones]: WidgetKeysEnum.contactInfo,
    [CompanyKeyEnum.Email]: WidgetKeysEnum.contactInfo,
    [CompanyKeyEnum.ExtraEmails]: WidgetKeysEnum.contactInfo,
    [CompanyKeyEnum.Websites]: WidgetKeysEnum.links,
    [CompanyKeyEnum.ExtraWebsites]: WidgetKeysEnum.links,
    [CompanyKeyEnum.SocialNetworks]: WidgetKeysEnum.links,
    [CompanyKeyEnum.ExtraSocialNetworks]: WidgetKeysEnum.links,
    [CompanyKeyEnum.TemporaryClosed]: WidgetKeysEnum.workingTime,
    [CompanyKeyEnum.WorkingHours]: WidgetKeysEnum.workingTime,
    [CompanyKeyEnum.ExtraWorkingHours]: WidgetKeysEnum.workingTime,
    [CompanyKeyEnum.SpecialHours]: WidgetKeysEnum.temporaryWorkingTime,
    [CompanyKeyEnum.Payments]: WidgetKeysEnum.paymentMethod,
    [CompanyKeyEnum.Descriptions]: WidgetKeysEnum.description,
    [CompanyKeyEnum.ExtraDescriptions]: WidgetKeysEnum.description,
    [CompanyKeyEnum.Logo]: WidgetKeysEnum.logo,
    [CompanyKeyEnum.Cover]: WidgetKeysEnum.logo,
    [CompanyKeyEnum.Gallery]: WidgetKeysEnum.gallery,
    [CompanyKeyEnum.ExtraGallery]: WidgetKeysEnum.gallery,
    [CompanyKeyEnum.Attributes]: WidgetKeysEnum.attributes,
    attributesGoogle: WidgetKeysEnum.attributes,
    attributesYandex: WidgetKeysEnum.attributes,
};

export function getWidgetNames(getLocalizedString: LocaleContextType['getLocalizedString']): WidgetNamesType {
    return {
        [WidgetKeysEnum.names]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__NAMES'),
        },
        [WidgetKeysEnum.brand]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__BRAND'),
        },
        [WidgetKeysEnum.address]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__ADDRESS'),
        },
        [WidgetKeysEnum.legal]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__LEGAL'),
        },
        [WidgetKeysEnum.categories]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__CATEGORIES'),
            navigationName: getLocalizedString('COMPANY__FORM__WIDGET_NAME__CATEGORIES__NAVIGATION'),
        },
        [WidgetKeysEnum.contactInfo]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__CONTACT_INFO'),
        },
        [WidgetKeysEnum.links]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__LINKS'),
            navigationName: getLocalizedString('COMPANY__FORM__WIDGET_NAME__LINKS__NAVIGATION'),
        },
        [WidgetKeysEnum.workingTime]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__WORKING_TIME'),
            navigationName: getLocalizedString('COMPANY__FORM__WIDGET_NAME__WORKING_TIME'),
        },
        [WidgetKeysEnum.temporaryWorkingTime]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__TEMPORARY_WORKING_TIME'),
        },
        [WidgetKeysEnum.paymentMethod]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__PAYMENT_METHOD'),
        },
        [WidgetKeysEnum.description]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__DESCRIPTION'),
        },
        [WidgetKeysEnum.logo]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__LOGO'),
        },
        [WidgetKeysEnum.gallery]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__GALLERY'),
        },
        [WidgetKeysEnum.attributes]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__ATTRIBUTES'),
            navigationName: getLocalizedString('COMPANY__FORM__WIDGET_NAME__ATTRIBUTES__NAVIGATION'),
        },
        [WidgetKeysEnum.deletion]: {
            name: getLocalizedString('COMPANY__FORM__WIDGET_NAME__DELETION'),
            navigationName: getLocalizedString('COMPANY__FORM__WIDGET_NAME__DELETION__NAVIGATION'),
        },
    };
}

export enum CompanyWidgetKeyEnum {
    SearchNames = 'search_names',
    Code = 'code',
    BrandName = 'brand_name',
    PostalCode = 'postal_code',
    City = 'city',
    Street = 'street',
    HouseNumber = 'house_number',
    LegalName = 'legal_name',
    LegalForm = 'org_form',
    Email = 'email',
    SocialNetworks = 'socials',
    WorkingHours = 'working_hours',
    Payments = 'payment_methods',
    DescriptionsLong = 'long_description',
    DescriptionsShort = 'short_description',
    Logo = 'logo',
    Cover = 'cover',
    Gallery = 'gallery',
    Names = 'names',
    Country = 'country',
    Region = 'region',
    Phones = 'phones',
    RocketdataCategories = 'rocketdata_categories',
    Website = 'website',
}

export const companyWidgetToNamesMap: Record<CompanyWidgetKeyEnum | 'categories', LangKeyType> = {
    [CompanyWidgetKeyEnum.SearchNames]: 'COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__LABEL',
    [CompanyWidgetKeyEnum.Names]: 'COMPANY_FORM__NAMES__COMPANY_NAME__LABEL',
    [CompanyWidgetKeyEnum.Code]: 'COMPANY_FORM__BRAND__BRANCH_CODE__LABEL',
    [CompanyWidgetKeyEnum.BrandName]: 'COMPANY_FORM__BRAND__BRAND_NAME__LABEL',
    [CompanyWidgetKeyEnum.PostalCode]: 'COMPANY_FORM__ADDRESS__POSTCODE__LABEL',
    [CompanyWidgetKeyEnum.City]: 'COMPANY_FORM__ADDRESS__CITY__LABEL',
    [CompanyWidgetKeyEnum.Street]: 'COMPANY_FORM__ADDRESS__STREET__LABEL',
    [CompanyWidgetKeyEnum.HouseNumber]: 'COMPANY_FORM__ADDRESS__HOUSE_NUMBER__LABEL',
    [CompanyWidgetKeyEnum.Country]: 'COMPANY_FORM__ADDRESS__COUNTRY__LABEL',
    [CompanyWidgetKeyEnum.Region]: 'COMPANY_FORM__ADDRESS__REGION__LABEL',
    [CompanyWidgetKeyEnum.LegalName]: 'COMPANY_FORM__LEGAL__LEGAL_NAME__LABEL',
    [CompanyWidgetKeyEnum.LegalForm]: 'COMPANY_FORM__LEGAL__ORGANIZATIONAL_AND_LEGAL_FORM__LABEL',
    [CompanyWidgetKeyEnum.Email]: 'COMPANY_FORM__CONTACT_INFO__EMAIL__LABEL',
    [CompanyWidgetKeyEnum.Phones]: 'COMPANY_FORM__CONTACT_INFO__PHONE__LABEL',
    [CompanyWidgetKeyEnum.SocialNetworks]: 'COMPANY_FORM__LINKS__SOCIAL_NETWORKS__LABEL',
    [CompanyWidgetKeyEnum.Website]: 'COMPANY_FORM__LINKS__WEBSITES__LABEL',
    [CompanyWidgetKeyEnum.WorkingHours]: 'COMPANY__FORM__WIDGET_NAME__WORKING_TIME',
    [CompanyWidgetKeyEnum.Payments]: 'COMPANY__FORM__WIDGET_NAME__PAYMENT_METHOD',
    [CompanyWidgetKeyEnum.DescriptionsLong]: 'COMPANY_FORM__DESCRIPTIONS__LONG_DESCRIPTION__LABEL',
    [CompanyWidgetKeyEnum.DescriptionsShort]: 'COMPANY_FORM__DESCRIPTIONS__SHORT_DESCRIPTION__LABEL',
    [CompanyWidgetKeyEnum.Logo]: 'COMPANY_FORM__LOGO__YOUR_COMPANY_LOGO',
    [CompanyWidgetKeyEnum.Cover]: 'COMPANY_FORM__LOGO__COMPANY_CARD_COVER',
    [CompanyWidgetKeyEnum.Gallery]: 'COMPANY_FORM__GALLERY__LABEL',
    [CompanyWidgetKeyEnum.RocketdataCategories]: 'COMPANY__FORM__WIDGET_NAME__CATEGORIES__NAVIGATION',
    categories: 'TABLE__HEADER__BLOCK__CATEGORIES',
};

export const companyWidgetKeyToWidgetKeyMap: Record<CompanyWidgetKeyEnum | 'categories', WidgetKeysEnum> = {
    [CompanyWidgetKeyEnum.SearchNames]: WidgetKeysEnum.names,
    [CompanyWidgetKeyEnum.Names]: WidgetKeysEnum.names,
    [CompanyWidgetKeyEnum.Code]: WidgetKeysEnum.brand,
    [CompanyWidgetKeyEnum.BrandName]: WidgetKeysEnum.brand,
    [CompanyWidgetKeyEnum.PostalCode]: WidgetKeysEnum.address,
    [CompanyWidgetKeyEnum.City]: WidgetKeysEnum.address,
    [CompanyWidgetKeyEnum.Street]: WidgetKeysEnum.address,
    [CompanyWidgetKeyEnum.HouseNumber]: WidgetKeysEnum.address,
    [CompanyWidgetKeyEnum.Country]: WidgetKeysEnum.address,
    [CompanyWidgetKeyEnum.Region]: WidgetKeysEnum.address,
    [CompanyWidgetKeyEnum.LegalName]: WidgetKeysEnum.legal,
    [CompanyWidgetKeyEnum.LegalForm]: WidgetKeysEnum.legal,
    [CompanyWidgetKeyEnum.Email]: WidgetKeysEnum.contactInfo,
    [CompanyWidgetKeyEnum.Phones]: WidgetKeysEnum.contactInfo,
    [CompanyWidgetKeyEnum.SocialNetworks]: WidgetKeysEnum.links,
    [CompanyWidgetKeyEnum.Website]: WidgetKeysEnum.links,
    [CompanyWidgetKeyEnum.WorkingHours]: WidgetKeysEnum.workingTime,
    [CompanyWidgetKeyEnum.Payments]: WidgetKeysEnum.paymentMethod,
    [CompanyWidgetKeyEnum.DescriptionsLong]: WidgetKeysEnum.description,
    [CompanyWidgetKeyEnum.DescriptionsShort]: WidgetKeysEnum.description,
    [CompanyWidgetKeyEnum.Logo]: WidgetKeysEnum.logo,
    [CompanyWidgetKeyEnum.Cover]: WidgetKeysEnum.logo,
    [CompanyWidgetKeyEnum.Gallery]: WidgetKeysEnum.gallery,
    [CompanyWidgetKeyEnum.RocketdataCategories]: WidgetKeysEnum.categories,
    categories: WidgetKeysEnum.categories,
};

export const initCompanyFieldFill: CompanyFieldFillType = {
    [CompanyWidgetKeyEnum.SearchNames]: false,
    [CompanyWidgetKeyEnum.Code]: false,
    [CompanyWidgetKeyEnum.BrandName]: false,
    [CompanyWidgetKeyEnum.PostalCode]: false,
    [CompanyWidgetKeyEnum.City]: false,
    [CompanyWidgetKeyEnum.Street]: false,
    [CompanyWidgetKeyEnum.HouseNumber]: false,
    [CompanyWidgetKeyEnum.LegalName]: false,
    [CompanyWidgetKeyEnum.LegalForm]: false,
    [CompanyWidgetKeyEnum.Email]: false,
    [CompanyWidgetKeyEnum.SocialNetworks]: false,
    [CompanyWidgetKeyEnum.WorkingHours]: false,
    [CompanyWidgetKeyEnum.Payments]: false,
    [CompanyWidgetKeyEnum.DescriptionsLong]: false,
    [CompanyWidgetKeyEnum.DescriptionsShort]: false,
    [CompanyWidgetKeyEnum.Logo]: false,
    [CompanyWidgetKeyEnum.Cover]: false,
    [CompanyWidgetKeyEnum.Gallery]: false,
    [CompanyWidgetKeyEnum.Names]: false,
    [CompanyWidgetKeyEnum.Country]: false,
    [CompanyWidgetKeyEnum.Region]: false,
    [CompanyWidgetKeyEnum.Phones]: false,
    [CompanyWidgetKeyEnum.RocketdataCategories]: false,
    [CompanyWidgetKeyEnum.Website]: false,
    categories: false,
};
