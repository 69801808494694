import {LangKeyType} from '../../../provider/locale/translation/type';
import {
    BulkEditFieldNameEnum as FieldEnum,
    CompanyMediaFileType,
    FieldModeEnum,
} from '../../../service/company/company-type';

export const enum BulkEditSearchParametersEnum {
    SelectedIds = 'companies-ids',
    ExcludedIds = 'excluded-ids',
    YandexNeedActualization = 'yandex-actualization-has-error',
    IsNewCompanies = 'is-new-companies',
}

export type BulkEditFieldType = {
    name: FieldEnum;
    label: LangKeyType;
    isAddingAvailable: boolean;
    mode: FieldModeEnum;
};

export type GalleryValidatorPropsType = {
    gallery: Array<CompanyMediaFileType>;
    errorOnEmptyField: Array<string>;
};

export type BulkUpdateCompaniesErrorsType = {
    [FieldEnum.names]: Array<string> | null;
    [FieldEnum.alternativeNames]: Array<string> | null;
    [FieldEnum.legalForm]: Array<string> | null;
    [FieldEnum.legalName]: Array<string> | null;
    [FieldEnum.categories]: Array<string> | null;
    [FieldEnum.phones]: [Array<string>] | null;
    [FieldEnum.email]: Array<string> | null;
    [FieldEnum.websites]: [Array<string>] | null;
    [FieldEnum.socials]: [Array<string>] | null;
    [FieldEnum.workingDays]: Array<Array<string> | null>;
    [FieldEnum.temporaryWorkingDays]: Array<string> | null;
    [FieldEnum.isTemporaryClosed]: Array<string> | null;
    [FieldEnum.paymentMethods]: Array<string> | null;
    [FieldEnum.shortDescription]: Array<string> | null;
    [FieldEnum.fullDescription]: Array<string> | null;
    [FieldEnum.logo]: Array<string> | null;
    [FieldEnum.cover]: Array<string> | null;
    [FieldEnum.gallery]: Array<string> | null;
};
