import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {Locale} from '../../../../provider/locale/locale';
import {useMessage} from '../../../../provider/message/message-hook';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../../../service/api/api-const';
import {fetchAndDeserialize, getUrlParameters, serialize} from '../../../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../../../util/fetch';

const showYandexBannerSchema = r.object({
    showAccessTransferBanner: r.boolean(),
});

type ShowYandexBannerType = r.infer<typeof showYandexBannerSchema>;

const transferAccountInfoSchema = r.object({
    chainLink: r.string().nullable(),
    companyLink: r.string().nullable(),
    newAccountEmail: r.string(),
    organizationsLink: r.string(),
    companySpravPk: r.number().nullable(),
    companyChainPk: r.number().nullable(),
});

type TransferAccountInfoType = r.infer<typeof transferAccountInfoSchema>;

type YandexTransferAccountConnectOptionType = {
    isCheckAll: boolean;
    companySpravPk?: number;
    companyChainPk?: number;
};

const accountTransferUrl = '/cp/yandex_accounts/';

export function useShowYandexTransferAccountBanner(): UseQueryResult<ShowYandexBannerType> {
    return useQuery(['show-yandex-transfer-alert'], () =>
        fetchAndDeserialize(`${accountTransferUrl}show_banner/`, showYandexBannerSchema)
    );
}

function getAccountInfoUrl(companyParameters: {companyId?: number; cclId?: number}) {
    return `${accountTransferUrl}account_info/${getUrlParameters(companyParameters)}`;
}

function fetchAccountInfoUrl(companyParameters: {companyId?: number; cclId?: number}) {
    return fetchAndDeserialize(getAccountInfoUrl(companyParameters), transferAccountInfoSchema);
}

export function useYandexTransferModalData(companyParameters: {
    companyId?: number;
    cclId?: number;
}): UseQueryResult<TransferAccountInfoType> {
    return useQuery([getAccountInfoUrl(companyParameters)], () => fetchAccountInfoUrl(companyParameters));
}

export function useYandexTransferAccountSuccess(): UseMutationResult<
    void,
    unknown,
    YandexTransferAccountConnectOptionType
> {
    const {message} = useMessage();

    return useMutation({
        mutationFn: (options) =>
            fetchNoContent(`${rootApiUrl}/cp/yandex_accounts/confirm_transfer/`, {
                method: FetchMethodEnum.post,
                headers: {...mainApiHeaders, ...getCsrfHeaders()},
                body: JSON.stringify(serialize(options)),
            }),
        onSuccess: () => message.success(<Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__MODAL__SUCCESS" />),
    });
}
