import {faFilterList} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Drawer, Form, Row, Space} from 'antd';
import {useCallback, useState} from 'react';

import {filterFormId} from '../../../../page/main/posts/posts-filter/posts-filter-form/posts-filter-form-const';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useHeaderFilter} from '../../../../service/main-filter/main-filter-hook';
import {
    IsActiveFieldNameEnum,
    MainFilterFieldHookType,
    MainFilterFieldNameEnum,
    MainFilterOpenCloseSuggestionIdEnum,
} from '../../../../service/main-filter/main-filter-type';
import {useDrawerWidthHack} from '../../../../util/antd/drawer-hook';
import {classNames} from '../../../../util/css';
import {getPlural} from '../../../../util/plural';

import {
    fieldToRenderNames,
    headerFilterDrawerWidth,
    mainFilterFieldLabelKeyMap,
    mainFilterFieldPlaceholderKeyMap,
} from './header-filter-const';
import {MainFilterSelect} from './main-filter-select/main-filter-select';
import * as styles from './header-filter.scss';

type PropsType = {
    className?: string;
};

export function HeaderFilter(props: PropsType): JSX.Element {
    const {className} = props;

    const drawerWidth: string = useDrawerWidthHack(headerFilterDrawerWidth);

    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const {getLocalizedString} = useLocale();

    const {localeName} = useLocale();
    const {snackbar} = useSnackbar();

    const {
        isInProgress,
        fields,
        loadFieldSuggests,
        clear: clearFilter,
        filteredCount,
        activeFilterCompaniesCount,
        applyFilter,
        isFilterEmpty,
        reset: resetFilter,
        isAppliedFilterEmpty,
        setFieldValue,
    } = useHeaderFilter();

    const onFormOpen = useCallback((): void => {
        resetFilter();
        setIsFormOpen(true);
    }, [setIsFormOpen, resetFilter]);

    const onFormClose = useCallback((): void => {
        setIsFormOpen(false);
    }, [setIsFormOpen]);

    const openCloseSuggest = [
        {
            id: MainFilterOpenCloseSuggestionIdEnum.IS_CLOSE,
            label: getLocalizedString('MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_CLOSE'),
        },
        {
            id: MainFilterOpenCloseSuggestionIdEnum.IS_TEMPORARY_CLOSE,
            label: getLocalizedString('MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_TEMPORARY_CLOSE'),
        },
        {
            id: MainFilterOpenCloseSuggestionIdEnum.OPEN,
            label: getLocalizedString('MAIN_FILTER__OPEN_CLOSE_FILTER__SUGGESTION_OPEN'),
        },
    ];

    const activeSuggest = [
        {
            id: IsActiveFieldNameEnum.ACTIVE,
            label: getLocalizedString('MAIN_FILTER__ACTIVE_FILTER__ACTIVE_COMPANY'),
        },
        {
            id: IsActiveFieldNameEnum.INACTIVE,
            label: getLocalizedString('MAIN_FILTER__ACTIVE_FILTER__INACTIVE_COMPANY'),
        },
    ];

    const selectedCompaniesHeaderText: JSX.Element = getPlural<JSX.Element>({
        count: activeFilterCompaniesCount,
        localeName,
        singular: (
            <Locale
                key="HEADER_FILTER__SELECTED_COMPANIES_COUNT__SINGULAR"
                stringKey="HEADER_FILTER__SELECTED_COMPANIES_COUNT__SINGLE"
                valueMap={{selectedCount: activeFilterCompaniesCount}}
            />
        ),
        pluralFew: (
            <Locale
                key="HEADER_FILTER__SELECTED_COMPANIES_COUNT__FEW"
                stringKey="HEADER_FILTER__SELECTED_COMPANIES_COUNT__FEW"
                valueMap={{selectedCount: activeFilterCompaniesCount}}
            />
        ),
        pluralMany: (
            <Locale
                key="HEADER_FILTER__SELECTED_COMPANIES_COUNT__MANY"
                stringKey="HEADER_FILTER__SELECTED_COMPANIES_COUNT__MANY"
                valueMap={{selectedCount: activeFilterCompaniesCount}}
            />
        ),
    });

    const handleSubmit = useCallback(() => {
        applyFilter()
            .finally(() => {
                setIsFormOpen(false);
            })
            .catch(() => {
                snackbar.error(<Locale stringKey="MAIN_FILTER__ERROR" />);
            });
    }, [applyFilter, snackbar]);

    return (
        <div className={classNames(styles.HeaderFilter, className)}>
            {isAppliedFilterEmpty ? (
                <div className={styles.Filter_tagEmpty}>
                    <Locale stringKey="HEADER_FILTER__EMPTY" />
                </div>
            ) : (
                <div className={styles.Filter_tagNotEmpty}>{selectedCompaniesHeaderText}</div>
            )}

            <Button
                className={styles.Filter_entryButton}
                icon={<FontAwesomeIcon icon={faFilterList} />}
                onClick={onFormOpen}
            />
            <Drawer
                className={styles.Filter_drawer}
                footer={
                    <>
                        <Row className={styles.Form_selectedCountWrapper}>
                            <span className={styles.Form_selectedCountText}>
                                {isFilterEmpty ? (
                                    <Locale key={2} stringKey="HEADER_FILTER__EMPTY" />
                                ) : (
                                    <Locale
                                        key={1}
                                        stringKey="MAIN_FILTER__SELECTED_COMPANIES_COUNT"
                                        valueMap={{
                                            selectedCount: (
                                                <span className={styles.Form_selectedCountNumber} key="selectedCount">
                                                    {filteredCount}
                                                </span>
                                            ),
                                        }}
                                    />
                                )}
                            </span>
                        </Row>
                        <Row className={styles.Form_buttonsWrapper} justify="space-between">
                            <Space>
                                <Button disabled={isInProgress || isFilterEmpty} onClick={clearFilter}>
                                    <Locale stringKey="TEXT__RESET" />
                                </Button>
                            </Space>
                            <Space>
                                <Button disabled={isInProgress} onClick={resetFilter}>
                                    <Locale stringKey="BUTTON__CANCEL" />
                                </Button>
                                <Button
                                    disabled={isInProgress}
                                    form={filterFormId}
                                    htmlType="submit"
                                    onClick={handleSubmit}
                                    type="primary"
                                >
                                    <Locale stringKey="TEXT__APPLY" />
                                </Button>
                            </Space>
                        </Row>
                    </>
                }
                onClose={onFormClose}
                open={isFormOpen}
                placement="right"
                title={<Locale stringKey="POSTS__FILTER_FORM__TITLE" />}
                width={drawerWidth}
            >
                <Form layout="vertical">
                    {fieldToRenderNames
                        .map((fieldName: MainFilterFieldNameEnum) => fields[fieldName])
                        .map((fieldHook: Omit<MainFilterFieldHookType, 'setValue'>) => {
                            function getSuggestMainFilter(
                                fieldName: MainFilterFieldNameEnum
                            ): typeof fieldHook.suggests {
                                if (fieldName === MainFilterFieldNameEnum.CLOSED) {
                                    return openCloseSuggest;
                                }

                                if (fieldName === MainFilterFieldNameEnum.ACTIVE) {
                                    return activeSuggest;
                                }

                                return fieldHook.suggests;
                            }

                            return (
                                <Form.Item
                                    className={styles.Filter_formItem}
                                    key={fieldHook.fieldName}
                                    label={<Locale stringKey={mainFilterFieldLabelKeyMap[fieldHook.fieldName]} />}
                                    name={fieldHook.fieldName}
                                >
                                    <MainFilterSelect
                                        fieldName={fieldHook.fieldName}
                                        fieldValue={fieldHook.value}
                                        isInProgress={fieldHook.isInProgress}
                                        loadSuggests={loadFieldSuggests}
                                        placeholder={
                                            <Locale stringKey={mainFilterFieldPlaceholderKeyMap[fieldHook.fieldName]} />
                                        }
                                        setFieldValue={setFieldValue}
                                        shouldRefreshSuggests={fieldHook.shouldRefreshSuggests}
                                        suggests={getSuggestMainFilter(fieldHook.fieldName)}
                                    />
                                </Form.Item>
                            );
                        })}
                </Form>
            </Drawer>
        </div>
    );
}
