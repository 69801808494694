import {Form, Input} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {EditUserType} from '../../../../../../service/user-management/user-management-edit-user';
import {useEditUserMutation, UsersFormKeyEnum} from '../../../../../../service/user-management/user-management-users';
import * as styles from '../email-form-item/emails-form-item.scss';
import {UsersForm} from '../users-form';

type PropsType = {
    user: EditUserType;
    userId: number;
};

export function EditUser(props: PropsType): JSX.Element {
    const {userId, user} = props;

    const {emailFieldRule} = useFormRules();
    const {mutate} = useEditUserMutation({id: userId, email: user.email});

    const {getLocalizedString} = useLocale();

    const emailFormItem = user && (
        <Form.Item
            className={styles.EmailsFormItem_formItem}
            initialValue={user?.email}
            label={<Text stringKey="USERS__FORM__EMAILS_FORM__LABEL" />}
            name={UsersFormKeyEnum.Emails}
            rules={[emailFieldRule]}
        >
            <Input
                disabled
                placeholder={getLocalizedString('COMPANY_FORM__CONTACT_INFO__EMAIL__PLACEHOLDER')}
                size="large"
                value={user?.email}
            />
        </Form.Item>
    );

    return (
        <UsersForm
            cancelButtonText={<Locale stringKey="BUTTON__RESET_CHANGES" />}
            emailFormItem={emailFormItem}
            onFinish={(values, activeTab) => mutate({options: values, activeTab})}
            pageHeaderTitle={getLocalizedString('USERS__FORM__EDIT_USER')}
            submitButtonText={<Locale stringKey="BUTTON__SAVE" />}
            user={user}
            userId={userId}
        />
    );
}
