import {Locale} from '../../../../../../provider/locale/locale';
import {AuthorTypeEnum, QuestionDataAnswerType} from '../../../../../../service/questions/questions-type';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import * as styles from './answer-item.scss';

type PropsType = {
    className?: string;
    answer: QuestionDataAnswerType;
};

export function AnswerItem(props: PropsType): JSX.Element {
    const {className, answer} = props;
    const {author, text, author_type: authorType, date_created: dateCreated} = answer;
    const {getFormattedDateTime} = useFormat();
    const date = new Date(dateCreated);
    const isOwner = authorType === AuthorTypeEnum.merchant;

    const itemClassName = classNames(
        styles.answer_item,
        {[styles.answer_item__owner]: isOwner},
        {[styles.answer_item__user]: !isOwner},
        className
    );

    return (
        <div className={itemClassName}>
            <div className={styles.answer_item__short_data}>
                {isOwner && (
                    <p className={styles.answer_item__short_data__owner}>
                        <Locale stringKey="QUESTIONS_N_ANSWERS__OWNER" />
                    </p>
                )}

                <p className={styles.answer_item__short_data__title}>{author}</p>

                <p className={styles.answer_item__short_data__timestamp}>
                    {getFormattedDateTime(date, {hour: '2-digit', minute: '2-digit'})}
                    &nbsp;
                    {getFormattedDateTime(date, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}
                </p>
            </div>

            <p className={styles.answer_item__short_data__answer_text}>{text}</p>
        </div>
    );
}
