import {Tooltip} from 'antd';
import {useMemo} from 'react';

import {ChartTooltipRow} from '../../../../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../../../../component/chart-tooltip/chart-tooltip-title';
import {Text} from '../../../../../component/text/text';
import * as cssVariableStyles from '../../../../../css/var-export.scss';
import {Locale} from '../../../../../provider/locale/locale';
import {classNames} from '../../../../../util/css';
import {getEnumValue} from '../../../../../util/enum';
import {getPercentagesDistribution} from '../../../../../util/number';

import {ReviewRatingBucket} from './review-rating-bucket';
import {reviewRatingSentimentColor, ReviewSentimentRatingEnum} from './reviews-rating-sentiments-helper';
import * as styles from './reviews-rating-sentiments.scss';

type PropsType = {
    reviewsRatings: Record<ReviewSentimentRatingEnum, number>;
    chartLabelPosition?: 'top' | 'bottom';
    periodLabel: JSX.Element | null;
};

export function ReviewsRatingSentimentsChart(props: PropsType): JSX.Element {
    const {periodLabel, reviewsRatings, chartLabelPosition = 'bottom'} = props;

    const percentagesDistribution = getPercentagesDistribution(reviewsRatings);

    const ratingKeysWithValues: Array<ReviewSentimentRatingEnum> = useMemo(() => {
        return Object.entries(reviewsRatings).flatMap(([rating, reviewsCount]) => {
            const ratingEnumValue = getEnumValue(ReviewSentimentRatingEnum, rating);

            if (ratingEnumValue && reviewsCount) {
                return [ratingEnumValue];
            }

            return [];
        });
    }, [reviewsRatings]);

    return (
        <Tooltip
            color={cssVariableStyles.gray1}
            title={
                <div>
                    <ChartTooltipTitle title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEWS_RATINGS" />} />
                    {Object.values(ReviewSentimentRatingEnum).map((rating) => {
                        return (
                            <ChartTooltipRow
                                color={reviewRatingSentimentColor[rating]}
                                key={rating}
                                label={
                                    <div className={styles.ReviewsRatingSentiments_tooltipLabel}>
                                        <Text block>{rating}</Text> ⭐
                                    </div>
                                }
                                value={
                                    <>
                                        {reviewsRatings[rating]} ({percentagesDistribution[rating]}%)
                                    </>
                                }
                            />
                        );
                    })}
                </div>
            }
            trigger="hover"
        >
            <div className={styles.ReviewsRatingSentiments_wrapper}>
                {periodLabel ?? null}
                <div
                    className={classNames(styles.ReviewsRatingSentiments_bar, {
                        [styles.ReviewsRatingSentiments_bar__alignEnd]: Boolean(chartLabelPosition === 'top'),
                    })}
                >
                    {ratingKeysWithValues.map((rating, index) => {
                        const currentColor = reviewRatingSentimentColor[rating];

                        const previousRatingWithValue = ratingKeysWithValues[index - 1];
                        const previousColor: string | null = previousRatingWithValue
                            ? reviewRatingSentimentColor[previousRatingWithValue]
                            : null;

                        return (
                            <ReviewRatingBucket
                                color={currentColor}
                                key={rating}
                                labelPosition={chartLabelPosition}
                                percentage={percentagesDistribution[rating]}
                                previousColor={previousColor}
                                rating={rating}
                                reviewsCount={reviewsRatings[rating]}
                            />
                        );
                    })}
                </div>
            </div>
        </Tooltip>
    );
}
