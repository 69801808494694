import {LinkCompanyType} from '../../../../../../service/source-settings/link-companies/link-companies-type';

export const enum ManageLinkFormEnum {
    link = 'link',
    brandId = 'brandId',
    company = 'company',
    deleteReviews = 'deleteReviews',
    status = 'status',
}

export type ManageLinkFormType = {
    [ManageLinkFormEnum.link]: string;
    [ManageLinkFormEnum.brandId]: number;
    [ManageLinkFormEnum.company]: LinkCompanyType | null;
    [ManageLinkFormEnum.deleteReviews]: boolean;
    [ManageLinkFormEnum.status]?: string;
};

export type ManageLinkFormServerErrorType = {
    [ManageLinkFormEnum.link]?: Array<string>;
};
