import {ReactNode} from 'react';

import {LangKeyType} from '../../../provider/locale/translation/type';
import {replaceInList} from '../../../util/array';

import {WorkDay} from './work-day/work-day';
import {WorkDayDataType} from './workdays-form-item-type';

type PropsType = {
    value: Array<WorkDayDataType>;
    className?: string;
    onChange: (value: Array<WorkDayDataType>) => void;
    hasError?: boolean;
    errorMessage?: Array<string> | Array<Array<string>> | null | Array<ReactNode>;
    disableBreaks?: boolean;
    isDisabled?: boolean;
    allowClear?: boolean;
    order?: boolean;
    openStateMessageKeys?: {
        open: LangKeyType;
        closed: LangKeyType;
    };
    id?: string;
};

export function WorkdaysFormItem(props: PropsType): JSX.Element {
    const {
        id,
        value: workdays,
        onChange,
        className,
        errorMessage,
        isDisabled,
        disableBreaks = false,
        allowClear = true,
        order = false,
        openStateMessageKeys = {
            open: 'ADDITIONAL_INFO__OPEN',
            closed: 'ADDITIONAL_INFO__CLOSED',
        },
    } = props;

    return (
        <div className={className} id={id}>
            {workdays.map((workday: WorkDayDataType, index): JSX.Element => {
                const error: ReactNode | null = errorMessage ? errorMessage[index] : null;

                return (
                    <WorkDay
                        allowClear={allowClear}
                        disableBreaks={disableBreaks}
                        errorMessage={error}
                        isDisabled={isDisabled}
                        key={workday.day}
                        onChange={(newWorkday: WorkDayDataType) => {
                            onChange(replaceInList(workdays, workday, newWorkday));
                        }}
                        openStateMessageKeys={openStateMessageKeys}
                        order={order}
                        workDayData={workday}
                    />
                );
            })}
        </div>
    );
}
