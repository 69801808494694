import {Button} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyErrorType} from '../../../../../../../service/company-v2/company-type';
import {SpecialHoursType} from '../../../../../../../service/company-v2/types/company-working-time-type';

import {TemporaryWorkDay} from './temporary-work-day/temporary-work-day';

const DEFAULT_VALUE: SpecialHoursType = {
    startDate: '',
    endDate: '',
    timeRange: {},
    breaks: [],
    isHoliday: false,
    daily: false,
};

type PropsType = {
    value?: Array<SpecialHoursType>;
    onChange?: (value: Array<SpecialHoursType>) => void;
    containerClassName?: string;
    addButtonClassName?: string;
    errorMessage?: CompanyErrorType['specialHours'];
    isDoubleGisCompany: boolean;
};

export function TemporaryWorkdaysFormItem(props: PropsType): JSX.Element {
    const {
        value: temporaryWorkdays = [],
        onChange = () => null,
        containerClassName,
        errorMessage,
        isDoubleGisCompany,
    } = props;

    function handleAddWorkday(): void {
        onChange?.([...temporaryWorkdays, {...DEFAULT_VALUE}]);
    }

    return (
        <>
            <div className={containerClassName}>
                {temporaryWorkdays.map((workday: SpecialHoursType, index: number): JSX.Element => {
                    return (
                        <TemporaryWorkDay
                            errorMessage={Array.isArray(errorMessage) ? null : errorMessage?.[index]}
                            isDoubleGisCompany={isDoubleGisCompany}
                            key={`${String(index)}-${JSON.stringify(workday)}`}
                            setTemporaryWorkDayDataList={onChange}
                            temporaryWorkDayData={workday}
                            temporaryWorkDayDataList={temporaryWorkdays}
                        />
                    );
                })}
            </div>

            <Button onClick={handleAddWorkday}>
                <Locale stringKey="COMPANY_FORM__WORKING_TIME__BUTTON__ADD_TEMPORARY_WORKING_HOURS" />
            </Button>
        </>
    );
}
