import {Button} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useState} from 'react';

import {useDocumentationLinks, useVideoLinks} from '../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {ConfirmationStatusEnum, ImportStatusEnum} from '../../../../../provider/onboarding/onboarding-hook';
import {CreateCompaniesOnboardingPopup} from '../../create-companies-onboarding-popup/create-companies-onboarding-popup';
import * as styles from '../../onboarding.scss';
import {OnboardingStepEnum} from '../../onboarding-const';
import {MappedDataType} from '../../onboarding-type';
import {OnboardingStep} from '../step';

type PropsType = {
    title: ReactElement;
    isOpenStep: boolean;
    stepIndex: OnboardingStepEnum;
    refetch: () => void;
    active?: boolean;
    status?: ConfirmationStatusEnum | ImportStatusEnum | undefined;
    setIsOpenAutoOnboardingPopup?: Dispatch<SetStateAction<boolean>>;
};

export function AddCompaniesStep(props: PropsType): ReactElement {
    const {isOpenStep, stepIndex, title, active, refetch, status, setIsOpenAutoOnboardingPopup} = props;
    const {getLocalizedString} = useLocale();
    const {onboarding} = useDocumentationLinks();
    const {onboarding: onboardingVideos} = useVideoLinks();

    const [openCompaniesPopup, setOpenCompaniesPopup] = useState<boolean>(false);

    const inProgressStatus = status === ImportStatusEnum.InProgress;

    const addCompaniesMappedData: Array<MappedDataType> = [
        {
            description: inProgressStatus
                ? 'ONBOARDING__CREATE_COMPANY__IN_PROGRESS__DESCRIPTION'
                : 'ONBOARDING__FIRST_STEP__DESCRIPTION',
            documentationLink: onboarding.addCompany,
            button: inProgressStatus ? null : (
                <Button
                    className={styles.Onboarding_successButton}
                    onClick={() => setOpenCompaniesPopup(true)}
                    size="middle"
                    type="primary"
                >
                    {getLocalizedString('ONBOARDING__ADD_COMPANIES')}
                </Button>
            ),
            videoLink: onboardingVideos.addCompany,
        },
    ];

    return (
        <>
            <OnboardingStep
                active={active}
                isOpenStep={isOpenStep}
                key={stepIndex}
                mappedData={addCompaniesMappedData}
                setIsOpenAutoOnboardingPopup={setIsOpenAutoOnboardingPopup}
                stepIndex={stepIndex}
                title={title}
            />
            {openCompaniesPopup && (
                <CreateCompaniesOnboardingPopup
                    onClose={() => {
                        setOpenCompaniesPopup(false);
                        refetch();
                    }}
                />
            )}
        </>
    );
}
