export const enAdditionalInfoTranslations = {
    ADDITIONAL_INFO__WORKING_HOURS: 'Working hours',
    ADDITIONAL_INFO__TEMPORARY_WORKING_HOURS: 'Temporary working hours',
    ADDITIONAL_INFO__BUTTON__ADD_BREAK: 'Add break',
    ADDITIONAL_INFO__BUTTON__ADD_TEMPORARY_WORKING_HOURS: 'Add temporary working hours',
    ADDITIONAL_INFO__THE_POINT_IS_TEMPORARILY_CLOSED: 'The point is temporarily closed',
    ADDITIONAL_INFO__OPEN: 'Open',
    ADDITIONAL_INFO__CLOSED: 'Closed',
    ADDITIONAL_INFO__BREAKS: 'Breaks',
    ADDITIONAL_INFO__AROUND_THE_CLOCK: 'Around the clock',
    ADDITIONAL_INFO__PAYMENT_METHODS: 'Payment Methods',
    ADDITIONAL_INFO__PAYMENT_METHODS__CASH: 'Cash',
    ADDITIONAL_INFO__PAYMENT_METHODS__CASHLESS_TRANSFERS: 'Non-cash transfers',
    ADDITIONAL_INFO__PAYMENT_METHODS__CARD: 'Card',
    ADDITIONAL_INFO__PAYMENT_METHODS__ELECTRONIC_MONEY: 'Electronic money',
    ADDITIONAL_INFO__PAYMENT_METHODS__CRYPTOCURRENCY: 'Cryptocurrency',
    ADDITIONAL_INFO__PAYMENT_METHODS__BANK: 'Bank transfer',
    ADDITIONAL_INFO__SHORT_DESCRIPTION: 'Short description',
    ADDITIONAL_INFO__SHORT_DESCRIPTION__PLACEHOLDER: 'Sporting goods store',
    ADDITIONAL_INFO__FULL_DESCRIPTION: 'Full description',
    ADDITIONAL_INFO__FULL_DESCRIPTION__PLACEHOLDER: 'Full description',
    ADDITIONAL_INFO__PERIOD: 'Period',
    ADDITIONAL_INFO__DAY_OFF: 'Day off',
};
