import {useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query';
import {keyBy, merge, values} from 'lodash';
import {useEffect, useMemo} from 'react';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {UsersFormTabEnum} from '../../page/main/settings/users/form/tabs/users-form-tabs-type';
import {Locale} from '../../provider/locale/locale';
import {LocalePlural} from '../../provider/locale/locale-plural';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeApiError, fetchAndDeserialize, serialize, serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../util/url';
import {useUrl} from '../../util/url-hook/url-hook';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {generateResponseSchema} from '../api/api-type';

import {userManagementUrl} from './user-management-const';
import {useRoles} from './user-management-roles';
import {UserStatusEnum} from './user-management-type';
import {roleSchema, userSchema} from './user-management-user';

const userFromListSchema = userSchema
    .pick({
        pk: true,
        fullName: true,
        email: true,
    })
    .extend({
        role: roleSchema,
        status: r.nativeEnum(UserStatusEnum),
        createdAt: r.string(),
        creator: r.string().nullable(),
        lastActivity: r.string().nullable(),
    });

const userIdSchema = userSchema.pick({pk: true});

const userBrandsSchema = r.object({
    pk: r.number(),
    companiesCount: r.number(),
    brands: r.object({
        count: r.number(),
        names: r.array(r.string()),
    }),
});
const usersIdSchema = generateResponseSchema(userIdSchema);

const usersSchema = generateResponseSchema(userFromListSchema);

type UsersIdType = r.infer<typeof usersIdSchema>;

export type UsersBrandsType = r.infer<typeof userBrandsSchema>;
export type UsersType = r.infer<typeof usersSchema>;

type UsersDataType = r.infer<typeof userFromListSchema>;

const usersErrorSchema = r.object({
    detail: r.string().optional(),
    emails: r.array(r.string()).optional(),
});

type UsersErrorType = r.infer<typeof usersErrorSchema>;

export enum UsersFormKeyEnum {
    RoleId = 'roleId',
    Emails = 'emails',
    ExtraEmails = 'extraEmails',
    Sources = 'catalogIds',
    Brands = 'brandIds',
    Groups = 'groupIds',
    CompaniesSelector = 'userCompaniesSelector',
}

export type UsersFormType = {
    roleId: number;
    emails: Array<string>;
    catalogIds: Array<number>;
    companiesSelectorUid?: string;
    brandIds?: Array<number>;
    groupIds?: Array<number>;
};

type UsersOptionsType = {
    page: number;
    count: number;
    q?: string | null;
    role?: string | null;
    status?: string | null;
};

type HookOptionsType = {
    id: number;
    email: string;
};

type UserArgsMutationType = {options: UsersFormType; activeTab: UsersFormTabEnum};

type UsersHookReturnType = PaginationType & UseQueryResult<UsersType> & {queryKey: string};

const url = `${userManagementUrl}/users/`;

function getUsersQueryUrl(options: UsersOptionsType) {
    return `${url}?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

function getUsersBrandsQueryUrl(options: {userIds: Array<number>}) {
    return `${url}location_info/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

async function fetchUsers(options: UsersOptionsType): Promise<UsersType> {
    const responseUsersId = await fetchAndDeserialize<UsersIdType>(getUsersQueryUrl(options), usersIdSchema);

    const usersId = {userIds: responseUsersId.results.map((item) => item.pk)};

    if (responseUsersId.results.length === 0) {
        return {...responseUsersId, results: []};
    }

    const responseUsers = await fetchAndDeserialize<Array<UsersDataType>>(
        `${url}personal_data/?${objectToUrlParameters(serializeToURLParameters(usersId))}`,
        userFromListSchema
    );

    const results = values(merge(keyBy(responseUsersId.results, 'pk'), keyBy(responseUsers, 'pk')));

    return {
        ...responseUsersId,
        results,
    };
}

function fetchUsersBrands(userIds: {userIds: Array<number>}): Promise<Array<UsersBrandsType>> {
    return fetchAndDeserialize(getUsersBrandsQueryUrl(userIds), userBrandsSchema);
}

export function useUsersBrands(options: {userIds: Array<number>}): UseQueryResult<Map<number, UsersBrandsType>> {
    const query = useQuery([getUsersBrandsQueryUrl(options)], () => fetchUsersBrands(options), {
        enabled: options.userIds.length > 0,
        select: (data) => new Map<number, UsersBrandsType>(data?.map((item) => [item.pk, item])),
    });

    return {...query};
}

export function useUsers(options: Pick<UsersOptionsType, 'q' | 'role' | 'status'> = {}): UsersHookReturnType {
    const {q, role, status: userStatus} = options;

    const dependencies = useMemo(() => ({q, role, userStatus}), [role, q, userStatus]);
    const pagination = usePagination({dependencies, initialPageSize: 10});
    const {page, pageSize, onDataLoaded, onChange} = pagination;

    const queryParameters = useMemo(() => ({page, count: pageSize, ...dependencies}), [page, pageSize, dependencies]);

    const queryKey = getUsersQueryUrl(queryParameters);

    const query = useQuery([queryKey], () => fetchUsers(queryParameters), {
        onError: async (error) => {
            const apiError = deserializeApiError<UsersErrorType>(url, usersErrorSchema, error);

            if (apiError) {
                const usersFirstData = await fetchUsers({...queryParameters, page: 1});

                return onChange(usersFirstData.pages || 1);
            }

            return false;
        },
    });

    useEffect(() => {
        if (query.data) {
            onDataLoaded(query.data);
        }
    }, [onDataLoaded, query.data]);

    return {...query, pagination, queryKey};
}

function createUsers(options: UsersFormType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}${url}`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize(options)),
    });
}

function updateUser(
    id: number,
    options: Pick<UsersFormType, 'roleId' | 'companiesSelectorUid' | 'catalogIds'>
): Promise<void> {
    return fetchNoContent(`${rootApiUrl}${url}${id}/`, {
        method: FetchMethodEnum.patch,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize(options)),
    });
}

export function useCreateUsersMutation(): UseMutationResult<
    void,
    Array<string>,
    {options: UsersFormType; activeTab: UsersFormTabEnum}
> {
    const {data: roles} = useRoles();

    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const {pushUrl} = useUrl();

    async function mutateOnOk(
        argumentsMutation: UserArgsMutationType,
        resolve: (value: void | PromiseLike<void>) => void,
        reject: (emailsError: Array<string>) => void
    ) {
        const {options, activeTab} = argumentsMutation;
        const emailsLength = options.emails?.length;

        function sendInfoUsingActiveTab() {
            switch (activeTab) {
                case UsersFormTabEnum.CompaniesGroup: {
                    return {companyGroupIds: options.groupIds};
                }
                case UsersFormTabEnum.AccessRules: {
                    return {brandIds: options.brandIds};
                }

                default:
                    return {companiesSelectorUid: options.companiesSelectorUid};
            }
        }

        try {
            await createUsers({
                roleId: options.roleId,
                emails: options.emails,
                catalogIds: options.catalogIds,
                ...sendInfoUsingActiveTab(),
            });
            resolve();
            pushUrl(appRoute.users.path);

            snackbar.success({
                message: (
                    <LocalePlural
                        count={emailsLength}
                        fewKey="USERS__FORM__SNACKBAR__SUCCESS_MESSAGE__MANY"
                        manyKey="USERS__FORM__SNACKBAR__SUCCESS_MESSAGE__MANY"
                        singularKey="USERS__FORM__SNACKBAR__SUCCESS_MESSAGE__SINGLE"
                    />
                ),
                description: (
                    <LocalePlural
                        count={emailsLength}
                        fewKey="USERS__FORM__SNACKBAR__SUCCESS_DESCRIPTION__MANY"
                        manyKey="USERS__FORM__SNACKBAR__SUCCESS_DESCRIPTION__MANY"
                        singularKey="USERS__FORM__SNACKBAR__SUCCESS_DESCRIPTION__SINGLE"
                    />
                ),
            });
        } catch (error) {
            const apiError = deserializeApiError<UsersErrorType>(url, usersErrorSchema, error);

            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: apiError?.detail ?? <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });

            if (apiError && apiError.emails) {
                reject(apiError.emails);
            }
        }
    }

    function mutate(optionsMutate: UserArgsMutationType): Promise<void> {
        const {options, activeTab} = optionsMutate;
        const emailsLength = options.emails?.length;
        const role = roles?.find(({pk}) => pk === options.roleId)?.name;

        return new Promise((resolve, reject) => {
            return modal.confirm({
                onOk: () => mutateOnOk({options, activeTab}, resolve, reject),
                okText: <Locale stringKey="BUTTON__CREATE" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                title: <Locale stringKey="USERS__FORM__CREATE_USER" />,
                content: (
                    <LocalePlural
                        count={emailsLength}
                        fewKey="USERS__FORM__CONFIRM_MODAL__CONTENT__FEW"
                        manyKey="USERS__FORM__CONFIRM_MODAL__CONTENT__MANY"
                        singularKey="USERS__FORM__CONFIRM_MODAL__CONTENT__SINGLE"
                        valueMap={{count: emailsLength, role}}
                    />
                ),
            });
        });
    }

    return useMutation(mutate);
}

export function useEditUserMutation(
    hookOptions: HookOptionsType
): UseMutationResult<void, unknown, {options: UsersFormType; activeTab: UsersFormTabEnum}> {
    const {snackbar} = useSnackbar();
    const {queryKey} = useUsers();
    const {data: roles} = useRoles();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({options, activeTab}) => {
            const uniqueBrands = [...new Set(options.brandIds)];
            const uniqueCatalogs = [...new Set(options.catalogIds)];
            const uniqueGroups = [...new Set(options.groupIds)];

            function sendInfoUsingActiveTab() {
                switch (activeTab) {
                    case UsersFormTabEnum.CompaniesGroup: {
                        return {companyGroupIds: uniqueGroups};
                    }
                    case UsersFormTabEnum.AccessRules: {
                        return {brandIds: uniqueBrands};
                    }

                    default:
                        return {companiesSelectorUid: options.companiesSelectorUid};
                }
            }

            return updateUser(hookOptions.id, {
                roleId: options.roleId,
                catalogIds: uniqueCatalogs,
                ...sendInfoUsingActiveTab(),
            });
        },
        onSuccess: (_data, {options}) => {
            const roleName = roles?.find((role) => role.pk === options.roleId)?.name;

            snackbar.success({
                message: <Locale stringKey="COMPANY_FORM__SNACKBAR__UPDATED__HEADER" />,
                description: (
                    <Locale
                        stringKey="USERS__FORM__SNACKBAR__SUCCESS_EDIT_DESCRIPTION"
                        valueMap={{email: hookOptions.email}}
                    />
                ),
            });
            queryClient.setQueryData([queryKey], (oldData?: UsersType) => {
                if (!oldData) {
                    return oldData;
                }

                return {
                    ...oldData,
                    results: oldData.results.map((user) => {
                        return user.pk === hookOptions.id
                            ? {...user, role: {...user.role, name: roleName ?? user.role.name, pk: options.roleId}}
                            : user;
                    }),
                };
            });
        },
        onError: (error) => {
            const apiError = deserializeApiError<UsersErrorType>(url, usersErrorSchema, error);

            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: apiError?.detail ?? <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}
