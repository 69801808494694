import {Tag} from 'antd';
import {useCallback, useMemo} from 'react';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';
import {useTechnicalSupportFields} from '../../../../../../service/technical-support/technical-support-hook';
import {
    TechnicalSupportFieldsEnum,
    TechnicalSupportFilterType,
} from '../../../../../../service/technical-support/technical-support-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import * as styles from './technical-support-filter.scss';

type PropsType = {
    filter: TechnicalSupportFilterType;
    updateFilter: (values: Partial<TechnicalSupportFilterType>) => void;
};

export function TechnicalSupportFilterActive(props: PropsType): JSX.Element {
    const {filter, updateFilter} = props;

    const {getFormattedDateTime} = useFormat();

    const {data} = useTechnicalSupportFields();

    const hasFilters = useMemo(() => {
        const activeFilterFields = {
            [TechnicalSupportFieldsEnum.Status]: filter[TechnicalSupportFieldsEnum.Status].length > 0,
            creationDate: Boolean(filter?.creationDate[0]),
            [TechnicalSupportFieldsEnum.ClientCategory]: Boolean(filter[TechnicalSupportFieldsEnum.ClientCategory]),
            [TechnicalSupportFieldsEnum.Type]: Boolean(filter[TechnicalSupportFieldsEnum.Type]),
            [TechnicalSupportFieldsEnum.IsPublic]: filter[TechnicalSupportFieldsEnum.IsPublic] !== null,
            [TechnicalSupportFieldsEnum.Creator]: filter[TechnicalSupportFieldsEnum.Creator].length > 0,
            [TechnicalSupportFieldsEnum.CreatorGroup]: filter[TechnicalSupportFieldsEnum.CreatorGroup].length > 0,
        };

        return Object.values(activeFilterFields).some(Boolean);
    }, [filter]);

    const timeRangeText = useMemo<string | null>((): string | null => {
        const [startDate, endDate] = filter.creationDate;

        if (!startDate || !endDate) {
            return null;
        }

        const startDateText = getFormattedDateTime(startDate, {timeZone: 'UTC'});
        const endDateText = getFormattedDateTime(endDate, {timeZone: 'UTC'});

        return `${startDateText}-${endDateText}`;
    }, [getFormattedDateTime, filter]);

    const onCreatorClose = useCallback(
        (value: number) => {
            updateFilter({
                [TechnicalSupportFieldsEnum.Creator]: filter[TechnicalSupportFieldsEnum.Creator].filter(
                    (selectedValue) => selectedValue !== value
                ),
            });
        },
        [filter, updateFilter]
    );

    const onCreatorGroupClose = useCallback(
        (value: number) => {
            updateFilter({
                [TechnicalSupportFieldsEnum.CreatorGroup]: filter[TechnicalSupportFieldsEnum.CreatorGroup].filter(
                    (selectedValue) => selectedValue !== value
                ),
            });
        },
        [filter, updateFilter]
    );

    const onStatusClose = useCallback(
        (value: string) => {
            updateFilter({
                [TechnicalSupportFieldsEnum.Status]: filter[TechnicalSupportFieldsEnum.Status].filter(
                    (selectedValue) => selectedValue !== value
                ),
            });
        },
        [filter, updateFilter]
    );

    return (
        <>
            {!hasFilters && (
                <Text type="secondary">
                    <Locale stringKey="POSTS_ACTIVE__FILTER__EMPTY_TEXT" />
                </Text>
            )}
            {hasFilters && (
                <>
                    <Text className={styles.TechnicalSupportFilterRow_filterText} type="secondary">
                        <Locale stringKey="POSTS_ACTIVE__FILTER__LABEL" />:
                    </Text>

                    {data?.status
                        .filter(({value}) => filter[TechnicalSupportFieldsEnum.Status].includes(value))
                        .map(({value, name}) => {
                            return (
                                <Tag closable key={value} onClose={() => onStatusClose(value)}>
                                    {name}
                                </Tag>
                            );
                        })}

                    {timeRangeText !== null && (
                        <Tag
                            closable
                            onClose={() =>
                                updateFilter({
                                    creationDate: [null, null],
                                })
                            }
                        >
                            {timeRangeText}
                        </Tag>
                    )}

                    {filter[TechnicalSupportFieldsEnum.ClientCategory] && (
                        <Tag
                            closable
                            onClose={() =>
                                updateFilter({
                                    [TechnicalSupportFieldsEnum.ClientCategory]: null,
                                })
                            }
                        >
                            {
                                data?.clientCategory.find(({value}) => {
                                    return value === filter[TechnicalSupportFieldsEnum.ClientCategory];
                                })?.name
                            }
                        </Tag>
                    )}

                    {filter[TechnicalSupportFieldsEnum.Type] && (
                        <Tag
                            closable
                            onClose={() =>
                                updateFilter({
                                    [TechnicalSupportFieldsEnum.Type]: null,
                                })
                            }
                        >
                            {
                                data?.type.find(({value}) => {
                                    return value === filter[TechnicalSupportFieldsEnum.Type];
                                })?.name
                            }
                        </Tag>
                    )}

                    {filter[TechnicalSupportFieldsEnum.IsPublic] !== null && (
                        <Tag
                            closable
                            onClose={() =>
                                updateFilter({
                                    [TechnicalSupportFieldsEnum.IsPublic]: null,
                                })
                            }
                        >
                            {
                                data?.isPublic.find(({value}) => {
                                    return value === filter[TechnicalSupportFieldsEnum.IsPublic];
                                })?.name
                            }
                        </Tag>
                    )}

                    {data?.creator
                        .filter(({value}) => filter[TechnicalSupportFieldsEnum.Creator].includes(value))
                        .map(({value, name}) => {
                            return (
                                <Tag closable key={value} onClose={() => onCreatorClose(value)}>
                                    {name}
                                </Tag>
                            );
                        })}

                    {data?.creatorGroup
                        .filter(({value}) => filter[TechnicalSupportFieldsEnum.CreatorGroup].includes(value))
                        .map(({value, name}) => {
                            return (
                                <Tag closable key={value} onClose={() => onCreatorGroupClose(value)}>
                                    {name}
                                </Tag>
                            );
                        })}
                </>
            )}
        </>
    );
}
