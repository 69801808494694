import {useCallback, useEffect, useState} from 'react';

import {AnalyticsTarget, track} from '../../service/analytics/analytics';

import {ACCOUNT_REFETCH_DELAY_MS} from './new-account-hook-const';
import {UseNewAccountHookPropsType, UseNewAccountHookType} from './new-account-hook-type';

export function useNewAccount(props: UseNewAccountHookPropsType): UseNewAccountHookType {
    const {linkingSrc, catalogName, onAccountAdd} = props;

    const [authWindow, setAuthWindow] = useState<Window | null>(null);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    const addAccount = useCallback(() => {
        if (authWindow && !authWindow.closed) {
            authWindow.focus();
            return;
        }

        if (catalogName) {
            track(AnalyticsTarget.Settings.AccountSettings.AddAccount, catalogName);
        }

        setAuthWindow(window.open(linkingSrc, '_blank', 'popup,width=720,height=480'));
    }, [authWindow, catalogName, linkingSrc]);

    // window.closed doesn't work because of redirects, trying to refetch on page focus instead
    useEffect(() => {
        function handleFocusChange() {
            setTimeoutId(setTimeout(() => onAccountAdd(), ACCOUNT_REFETCH_DELAY_MS));
        }

        window.addEventListener('focus', handleFocusChange);

        return () => {
            window.removeEventListener('focus', handleFocusChange);
            clearTimeout(timeoutId);
        };
    }, [onAccountAdd, timeoutId]);

    return {addAccount};
}
