import {LicensesCodeEnum} from '../../page/main/tariffs-and-limits/licenses-block/license/license-const';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../service/api/api-const';
import {fetchAndDeserialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';

import {getUserLicensesUrl} from './license-provider-helper';
import {licenseSchema, LicenseType, TrialActivationOptionsType} from './license-provider-type';

export function fetchUserLicenses(): Promise<Array<LicenseType>> {
    const url = `${getUserLicensesUrl()}`;

    return fetchAndDeserialize<Array<LicenseType>>(url, licenseSchema.array(), {
        fetchOptions: {
            skipCache: true,
        },
    });
}

export function requestLicense(tariffId: number, licenseCode: LicensesCodeEnum): Promise<unknown> {
    return fetchNoContent(`${rootApiUrl}/cp/licence/${licenseCode}/full/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({
            tariff_id: tariffId,
        }),
    });
}

export async function activateTrialLicense(options: TrialActivationOptionsType): Promise<void> {
    const url = `${rootApiUrl}/cp/licence/${options.licenseCode}/trial/`;

    return fetchNoContent(url, {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body: JSON.stringify({tariff_id: options.tariffId}),
    });
}
