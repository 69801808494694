import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchReviewSentimentByTime, fetchReviewSentimentStats} from './review-sentiment-api';
import {
    RequestSentimentByTimeQueryType,
    ReviewAnalysisSentimentResponseType,
    ReviewsAnalysisSentimentQueryType,
    SentimentByTimeResponseType,
} from './review-sentiment-type';

export function useReviewSentimentStats(
    options: ReviewsAnalysisSentimentQueryType,
    mainFilterKey: string
): UseHookType<ReviewAnalysisSentimentResponseType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewAnalysisSentimentResponseType>();

    const {
        mode,
        brandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
    } = options;

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewSentimentStats(
            {
                mode,
                brandIds,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                userTopics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        mode,
        brandIds,
        mainFilterKey,
        setIsInProgress,
        setProcessError,
        setResult,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
    ]);

    return {isInProgress, processError, result, reset};
}

export function useReviewSentimentByTime(
    options: RequestSentimentByTimeQueryType,
    mainFilterKey: string
): UseHookType<SentimentByTimeResponseType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<SentimentByTimeResponseType>();

    const {
        groupBy,
        mode,
        brandIds: compareBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
    } = options;

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewSentimentByTime(
            {
                groupBy,
                mode,
                brandIds: compareBrandIds,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                userTopics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        mainFilterKey,
        mode,
        groupBy,
        compareBrandIds,
        setIsInProgress,
        setProcessError,
        setResult,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
    ]);

    return {isInProgress, processError, result, reset};
}
