export function getBadgeLeftOffsetPx(errorsCount: number): number {
    if (errorsCount > 1000) {
        return -20;
    }

    if (errorsCount > 100) {
        return -16;
    }

    if (errorsCount > 10) {
        return -12;
    }

    return -6;
}
