import {useCallback, useEffect, useMemo, useState} from 'react';

import {ShortCatalogType} from '../../provider/catalogs/catalogs-type';
import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {useRefreshId} from '../../util/hook';
import {CursorPaginationResponseType} from '../api/api-type';
import {useApiHooks} from '../api-hook/api-hook';

import {
    loadAvailableGoogleCategoriesApi,
    loadProductGroupApi,
    loadProductGroupsApi,
    loadProductsAvailableCatalogsApi,
    removeProductGroupApi,
} from './products-api';
import {
    GoogleServiceCategoriesHookType,
    GoogleServiceCategoryType,
    ProductAvailableCatalogsHookType,
    ProductGroupHookType,
    ProductGroupListItemType,
    ProductGroupsHookType,
    ProductGroupType,
} from './products-type';

export function useProductGroups(mainFilterKey?: string): ProductGroupsHookType {
    const {result, isInProgress, setIsInProgress, setResult, processError, setProcessError} =
        useApiHooks<Array<ProductGroupListItemType>>();

    const {refresh, refreshId} = useRefreshId();

    const paginationDependencies = useMemo(() => {
        return {
            mainFilterKey,
            refreshId,
        };
    }, [mainFilterKey, refreshId]);

    const cursorPagination = useCursorPagination({
        dependencies: paginationDependencies,
    });

    const {pageSize, cursor, onDataLoadFailed, onDataLoaded, refreshId: paginationRefreshId} = cursorPagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);
        loadProductGroupsApi({pageSize, cursor}, mainFilterKey)
            .then((response: CursorPaginationResponseType<ProductGroupListItemType>) => {
                setResult(response.results);
                onDataLoaded(response);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
                onDataLoadFailed();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationRefreshId, onDataLoadFailed, onDataLoaded, setIsInProgress, setProcessError, setResult]);

    return {
        result,
        isInProgress,
        processError,
        remove: removeProductGroupApi,
        reset: refresh,
        refreshId,
        cursorPagination,
    };
}

export function useProductAvailableCatalogs(): ProductAvailableCatalogsHookType {
    const {result, setResult, isInProgress, setIsInProgress, processError, setProcessError} =
        useApiHooks<Array<ShortCatalogType>>();

    useEffect(() => {
        loadProductsAvailableCatalogsApi()
            .then((responseData) => {
                setResult(responseData);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });
    }, [setIsInProgress, setProcessError, setResult]);

    return {
        result,
        isInProgress,
        processError,
    };
}

export function useProductGroup(groupId: string): ProductGroupHookType {
    const {result, setResult, isInProgress, setIsInProgress, processError, setProcessError} =
        useApiHooks<ProductGroupType>();

    useEffect(() => {
        loadProductGroupApi(groupId)
            .then((responseData) => {
                setResult(responseData);
            })
            .finally(() => {
                setIsInProgress(false);
            })
            .catch((error: Error) => {
                setProcessError(error);
            });
    }, [groupId, setIsInProgress, setProcessError, setResult]);

    return {
        result,
        isInProgress,
        processError,
    };
}

export function useGoogleServiceCategories(): GoogleServiceCategoriesHookType {
    const {result, setResult, isInProgress, setIsInProgress, processError, setProcessError} =
        useApiHooks<Array<GoogleServiceCategoryType>>();

    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const loadAvailableGoogleCategories = useCallback(
        (companySelectorId: string) => {
            const categoriesPromise = loadAvailableGoogleCategoriesApi(companySelectorId);

            categoriesPromise
                .then((responseData) => {
                    setResult(responseData);
                })
                .finally(() => {
                    setIsInitialized(true);
                    setIsInProgress(false);
                })
                .catch((error: Error) => {
                    setResult([]);
                    setProcessError(error);
                });
            return categoriesPromise;
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {
        result,
        isInProgress,
        processError,
        loadServiceCategories: loadAvailableGoogleCategories,
        isInitialized,
    };
}
