import {ColumnsType} from 'antd/lib/table';
import {FunctionComponent} from 'react';

import {Text} from '../../../component/text/text';
import {Locale} from '../../../provider/locale/localization';
import {formatAddress} from '../../../service/address/address-helper';
import {SelectorCompanyType} from '../../../service/feature-companies/feature-companies-type';
import {CompanySelectAdditionalDataColumnPropsType} from '../items/company-select-item-type';

export function getCompaniesSelectorColumns<CompanyType extends SelectorCompanyType = SelectorCompanyType>(
    AdditionalContentComponent?: FunctionComponent<CompanySelectAdditionalDataColumnPropsType<CompanyType>>
): ColumnsType<CompanyType> {
    return [
        {
            title: <Locale stringKey="TABLE__HEADER__TITLE_AND_CODE" />,
            width: '240px',
            key: 'name',
            render: (_value, item: CompanyType) => {
                return (
                    <Text>
                        {item.name} <Text lightest>{item.code}</Text>
                    </Text>
                );
            },
        },
        {
            title: <Locale stringKey="TABLE__HEADER__ADDRESS_LONG" />,
            key: 'address',
            render: (_value, item: CompanyType) => {
                return <Text>{formatAddress(item.address)}</Text>;
            },
        },
        ...(AdditionalContentComponent
            ? [
                  {
                      key: 'additionalInfo',
                      width: '120px',
                      render: (_value: unknown, item: CompanyType) => {
                          if (AdditionalContentComponent) {
                              return <AdditionalContentComponent company={item} />;
                          }

                          return null;
                      },
                  },
              ]
            : []),
    ];
}

export function checkHasSelectorRowBrandId(
    record: SelectorCompanyType
): record is SelectorCompanyType & {brandId: number} {
    return record && 'brandId' in record;
}

export function getIsDisabledRow(record: SelectorCompanyType, selectedBrandId?: number | null): boolean {
    if (checkHasSelectorRowBrandId(record)) {
        return Boolean(selectedBrandId && record.brandId !== selectedBrandId);
    }

    return false;
}
