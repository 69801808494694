import {faLanguage} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {CountryCodeType, LanguageCodeType} from '../../../service/language/language-const';
import {classNames} from '../../../util/css';

import {flagIcons} from './flag-const';
import {getFlag} from './flag-helper';
import * as styles from './flag.scss';

type PropsType = {
    className?: string;
} & ({value?: LanguageCodeType; isLanguageCode: true} | {value?: CountryCodeType; isLanguageCode: false});

export function Flag({value, className, isLanguageCode = true}: PropsType): JSX.Element | null {
    if (!value) {
        return null;
    }

    const flag = isLanguageCode
        ? getFlag(value as LanguageCodeType)
        : flagIcons[value.toLowerCase() as CountryCodeType];

    return (
        <div className={classNames(flag ? styles.Flag : styles.Flag_absent, className)}>
            {flag ? <img alt={value} src={flag} /> : <FontAwesomeIcon icon={faLanguage} />}
        </div>
    );
}
