import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {PropsWithChildren, useCallback, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

import {selector} from '../../const';
import {classNames} from '../../util/css';

import * as styles from './overlay.scss';

type PropsType = PropsWithChildren<{
    label?: string;
    onClose: () => void;
    contentClassName?: string;
}>;

export function Overlay(props: PropsType): JSX.Element | null {
    const {children, label, onClose, contentClassName} = props;

    const headerRef = useRef<HTMLElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const handleEsc = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                headerRef.current &&
                contentRef.current &&
                event.target instanceof Node &&
                !headerRef.current.contains(event.target) &&
                !contentRef.current.contains(event.target)
            ) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        const appWrapper = document.querySelector(selector.appWrapper);

        document.addEventListener('keydown', handleEsc);
        document.addEventListener('mousedown', handleClickOutside);

        document.body.style.paddingRight = `${window.innerWidth - document.body.clientWidth}px`;
        document.body.classList.add(styles.overlay_open);

        appWrapper?.setAttribute('aria-hidden', 'true');
        appWrapper?.setAttribute('tabIndex', '-1');

        contentRef.current?.querySelector('button')?.focus();

        return () => {
            document.removeEventListener('keydown', handleEsc);
            document.removeEventListener('mousedown', handleClickOutside);

            document.body.style.paddingRight = '';
            document.body.classList.remove(styles.overlay_open);

            appWrapper?.removeAttribute('aria-hidden');
            appWrapper?.removeAttribute('tabIndex');
        };
    }, [headerRef, contentRef, handleClickOutside, handleEsc]);

    return createPortal(
        <div aria-labelledby="dialog-title" className={classNames(styles.overlay)} role="dialog">
            {label && (
                <h6 hidden id="dialog-title">
                    {label}
                </h6>
            )}

            <div className={styles.mask} />

            <header className={styles.controls} ref={headerRef}>
                <ul className={styles.list}>
                    <li>
                        <Button
                            className={styles.overlayButton}
                            icon={<FontAwesomeIcon icon={faXmark} />}
                            onClick={() => onClose()}
                            type="text"
                        />
                    </li>
                </ul>
            </header>

            <div className={styles.wrapper}>
                <div className={contentClassName} ref={contentRef}>
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
}
