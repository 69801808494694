import {isNumber, isString} from 'lodash';

import {CatalogConfigType} from '../../../../../provider/catalogs/catalogs-type';
import {CompanyErrorType} from '../../../../../service/company-v2/company-type';
import {FormInstance} from '../../../../../typings/antd';

export function isCatalogAvailableInCountry({countriesIds}: CatalogConfigType, countryId?: number): boolean {
    if (!countryId || countriesIds.length === 0) {
        return true;
    }

    return countriesIds.includes(countryId);
}

export function getErrorTabIndex(
    getFieldsError: FormInstance<unknown>['getFieldsError'],
    formKey: keyof CompanyErrorType | Array<keyof CompanyErrorType>
): number | null {
    const index = getFieldsError().find((error) => {
        const name = error.name[0];

        if (error.errors.length === 0 || !isString(name)) {
            return false;
        }

        return Array.isArray(formKey) ? formKey.includes(name as keyof CompanyErrorType) : name === formKey;
    })?.name[1];

    return isNumber(index) ? index : null;
}

export function getErrorTabWorkingTimeIndex(
    errorMessage: CompanyErrorType | null,
    catalogs: Array<number>
): number | null {
    const keys = Object.keys(errorMessage?.extraWorkingHours ?? {});

    return Number(keys[0]) ? catalogs.indexOf(Number(keys[0])) : null;
}
