import {ReactNode} from 'react';

import {AntdErrorMessage} from '../../../../../../component/antd-error-message/antd-error-message';
import {AddButton} from '../../../../../../layout/add-button/add-button';
import {Locale} from '../../../../../../provider/locale/locale';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {classNames} from '../../../../../../util/css';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import {TemporaryWorkDay} from './temporary-work-day/temporary-work-day';
import {DEFAULT_TEMPORARY_WORK_DAY_DATA} from './temporary-workdays-form-item-const';
import {TemporaryWorkDayDataType} from './temporary-workdays-form-item-type';
import * as styles from './temporary-workdays-form-item.scss';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.temporaryWorkingDays> & {
    containerClassName?: string;
    addButtonClassName?: string;
    commonErrorMessage?: ReactNode;
};

export function TemporaryWorkdaysFormItem(props: PropsType): JSX.Element {
    const {
        value: temporaryWorkdays,
        onChange,
        containerClassName,
        addButtonClassName,
        errorMessage,
        commonErrorMessage,
    } = props;

    function handleAddWorkday(): void {
        onChange([...temporaryWorkdays, {...DEFAULT_TEMPORARY_WORK_DAY_DATA}]);
    }

    const fullClassName = classNames(
        commonErrorMessage && temporaryWorkdays.length > 0 ? styles.container_error : '',
        containerClassName
    );

    return (
        <>
            <div className={fullClassName}>
                {temporaryWorkdays.map((workday: TemporaryWorkDayDataType, index: number): JSX.Element => {
                    return (
                        <TemporaryWorkDay
                            errorMessage={errorMessage && (errorMessage as Array<ReactNode>)[index]}
                            key={`${String(index)}-${JSON.stringify(workday)}`}
                            setTemporaryWorkDayDataList={onChange}
                            temporaryWorkDayData={workday}
                            temporaryWorkDayDataList={temporaryWorkdays}
                        />
                    );
                })}
            </div>

            <AddButton className={addButtonClassName} onClick={handleAddWorkday}>
                <Locale stringKey="ADDITIONAL_INFO__BUTTON__ADD_TEMPORARY_WORKING_HOURS" />
            </AddButton>

            {commonErrorMessage && (
                <AntdErrorMessage className={styles.error_message}>{commonErrorMessage}</AntdErrorMessage>
            )}
        </>
    );
}
