import {Button, Input} from 'antd';

import {Page} from '../../../layout/page/page';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {
    ResetPasswordFormEnum,
    ResetPasswordFormType,
    useResetPasswordMutation,
} from '../../../service/user/user-reset-password';
import {Form} from '../../../typings/antd';
import {AuthFormFooter} from '../common/form-footer/auth-form-footer';
import {AuthFormHeader} from '../common/form-header/auth-form-header';

import {initialValues} from './forgot-password-const';

export function ForgotPassword(): JSX.Element {
    const [form] = Form.useForm<ResetPasswordFormType>();
    const email = Form.useWatch(ResetPasswordFormEnum.Email, form);
    const {requiredFieldRule, emailFieldRule} = useFormRules();

    const {mutate, isLoading} = useResetPasswordMutation();
    const {getLocalizedString} = useLocale();

    const disabled = !email || isLoading;

    return (
        <Page.CenteredCard titleLangKey="PAGE__FORGOT_PASSWORD__FORGOT_PASSWORD_FORM__HEADER">
            <AuthFormHeader title={<Locale stringKey="PAGE__FORGOT_PASSWORD__FORGOT_PASSWORD_FORM__HEADER" />} />

            <Form<ResetPasswordFormType> form={form} initialValues={initialValues} layout="vertical" onFinish={mutate}>
                <Form.Item<ResetPasswordFormType>
                    label={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__LABEL" />}
                    name={ResetPasswordFormEnum.Email}
                    rules={[requiredFieldRule, emailFieldRule]}
                >
                    <Input
                        onInvalid={(event) => event.preventDefault()}
                        placeholder={getLocalizedString('PAGE__LOGIN__LOGIN_FORM__INPUT__E_MAIL__PLACEHOLDER')}
                        size="large"
                        type="email"
                    />
                </Form.Item>

                <Button block disabled={disabled} htmlType="submit" loading={isLoading} size="large" type="primary">
                    <Locale stringKey="PAGE__FORGOT_PASSWORD__FORGOT_PASSWORD_FORM__BUTTON__RESTORE" />
                </Button>
            </Form>

            <AuthFormFooter />
        </Page.CenteredCard>
    );
}
