import {useCallback} from 'react';

import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {useApiHooks} from '../../api-hook/api-hook';

import {fetchReviewCompanies} from './review-companies-api';
import {
    CompanyResponseType,
    ReviewAnalysisCompaniesHookType,
    ReviewsAnalysisCompaniesQueryType,
    ReviewsAnalysisCompanyType,
} from './review-companies-type';

export function useReviewCompanies(): ReviewAnalysisCompaniesHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CompanyResponseType<ReviewsAnalysisCompanyType>>();

    const fetch = useCallback(
        (options: ReviewsAnalysisCompaniesQueryType & PaginationDataType, mainFilterKey: string): void => {
            setIsInProgress(true);
            setProcessError(null);

            fetchReviewCompanies(options, mainFilterKey)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setResult, setIsInProgress, setProcessError]
    );

    return {isInProgress, processError, result, reset, fetch};
}
