import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../../typings/antd';

import {TemporaryClosedButton} from './temprorary-closed-button/temporary-closed-button';

type PropsType = {
    name: CompanyKeyEnum.TemporaryClosed;
    className?: string;
    onChange?: (value: boolean) => void;
    value?: boolean;
};

export function TemporaryClosedFormItem(props: PropsType): JSX.Element {
    const {name} = props;

    return (
        <Form.Item<CompanyFormType> name={name} valuePropName="checked">
            <TemporaryClosedButton />
        </Form.Item>
    );
}
