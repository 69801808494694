import {ProvidersIdsEnum} from '../../../../../../util/type';

export function catalogCategoryLimits(isPaidYandexPlacement: boolean): typeof CATALOG_CATEGORY_LIMITS {
    const numberYandexCats = isPaidYandexPlacement ? 5 : 3;

    const CATALOG_CATEGORY_LIMITS: Record<
        | ProvidersIdsEnum.google
        | ProvidersIdsEnum.yandex
        | ProvidersIdsEnum.facebook
        | ProvidersIdsEnum.osm
        | ProvidersIdsEnum.navitel
        | string
        | number,
        number
    > = {
        [ProvidersIdsEnum.google]: 10,
        [ProvidersIdsEnum.yandex]: numberYandexCats,
        [ProvidersIdsEnum.facebook]: 3,
        [ProvidersIdsEnum.osm]: 1,
        [ProvidersIdsEnum.navitel]: 1,
    };

    return CATALOG_CATEGORY_LIMITS;
}
