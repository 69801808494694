import {Space} from 'antd';

import {Text} from '../../../../../../../../component/text/text';
import {
    GoogleCompanyWithCandidatesType,
    GoogleLocationSourceEnum,
    GoogleLocationType,
} from '../../../../../../../../service/google/google-type';
import {AccountTag} from '../../../../common/account-tag/account-tag';
import {useGoogleAccount} from '../../../../google-account-sync-context-hook';
import {NEW_LOCATION_OPTION} from '../../processing-step-const';

import {LocationPopupSummary} from './summary/location-popup-summary';
import * as styles from './location-popup-sidebar.scss';

type PropsType = {
    company: GoogleCompanyWithCandidatesType;
    selectedLocation: GoogleLocationType | typeof NEW_LOCATION_OPTION | null;
};

export function LocationPopupSidebar(props: PropsType): JSX.Element {
    const {company, selectedLocation} = props;

    const account = useGoogleAccount();

    return (
        <aside className={styles.LocationPopupSidebar}>
            {account && (
                <div className={styles.LocationPopupSidebar_account}>
                    <Text>{account.name}</Text>

                    <AccountTag account={account} />
                </div>
            )}

            <Space className={styles.LocationPopupSidebar_description} direction="vertical" size={4}>
                <Text bolder stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__HEADER" />

                {company.candidates?.source === GoogleLocationSourceEnum.Account && (
                    <Text lighter stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__DESCRIPTION__ACCOUNT" />
                )}

                {company.candidates?.source === GoogleLocationSourceEnum.Maps && (
                    <Text lighter stringKey="GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__DESCRIPTION__MAPS" />
                )}
            </Space>

            <div className={styles.LocationPopupSidebar_summary}>
                <LocationPopupSummary company={company} selectedLocation={selectedLocation} />
            </div>
        </aside>
    );
}
