import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {rootApiUrl} from '../api/api-const';

export type ReviewDoctorType = string;

type ReviewDoctorResponseType = {
    results: Array<ReviewDoctorType>;
};

const reviewsReviewsDoctorListUrl = `${rootApiUrl}/v4/reviews/doctors/`;

function fetchReviewsDoctorList(): Promise<ReviewDoctorResponseType> {
    return fetchX<ReviewDoctorResponseType>(reviewsReviewsDoctorListUrl);
}

export function useReviewsDoctorList(hasMedicineCatalog: boolean): UseQueryResult<ReviewDoctorResponseType> {
    return useQuery([reviewsReviewsDoctorListUrl], fetchReviewsDoctorList, {
        refetchOnWindowFocus: false,
        enabled: hasMedicineCatalog,
    });
}
