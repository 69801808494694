import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import * as styles from './link-column.scss';

type PropsType = {
    link: string | null;
};

export function LinkColumn(props: PropsType): JSX.Element | null {
    const {link} = props;

    if (!link || link.trim().length === 0) {
        return null;
    }

    return (
        <a href={link} rel="noreferrer" target="_blank">
            <FontAwesomeIcon className={styles.LinkColumn_icon} icon={faExternalLink} />
        </a>
    );
}
