import {ShortLocaleNameEnum} from '../../../../../provider/locale/locale-context-type';

import onboardingStep1EnImage from './images/online-presense-onboarding-step-1-en.png';
import onboardingStep1RuImage from './images/online-presense-onboarding-step-1-ru.png';
import onboardingStep2EnImage from './images/online-presense-onboarding-step-2-en.png';
import onboardingStep2RuImage from './images/online-presense-onboarding-step-2-ru.png';
import onboardingStep3EnImage from './images/online-presense-onboarding-step-3-en.png';
import onboardingStep3RuImage from './images/online-presense-onboarding-step-3-ru.png';

export const ONLINE_PRESENCE_ONBOARDING_IMAGES = {
    step1: {
        [ShortLocaleNameEnum.ru]: onboardingStep1RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep1EnImage,
        brandWizardImage: onboardingStep1EnImage,
    },
    step2: {
        [ShortLocaleNameEnum.ru]: onboardingStep2RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep2EnImage,
        brandWizardImage: onboardingStep2EnImage,
    },
    step3: {
        [ShortLocaleNameEnum.ru]: onboardingStep3RuImage,
        [ShortLocaleNameEnum.en]: onboardingStep3EnImage,
        brandWizardImage: onboardingStep3EnImage,
    },
};
