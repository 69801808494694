import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {CompanyPaymentMethodEnum, CompanyPaymentMethodV2Enum} from '../../../../../../service/company/company-type';

import {PaymentMethodsFieldV2Type} from './payment-methods-form-item-type';

export const LABEL_BY_METHOD: Record<CompanyPaymentMethodEnum | CompanyPaymentMethodV2Enum, LangKeyType> = {
    [CompanyPaymentMethodEnum.cash]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CASH',
    [CompanyPaymentMethodEnum.nonCash]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CASHLESS_TRANSFERS',
    [CompanyPaymentMethodEnum.card]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CARD',
    [CompanyPaymentMethodEnum.emoney]: 'ADDITIONAL_INFO__PAYMENT_METHODS__ELECTRONIC_MONEY',
    [CompanyPaymentMethodEnum.crypto]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CRYPTOCURRENCY',
    [CompanyPaymentMethodEnum.bank]: 'ADDITIONAL_INFO__PAYMENT_METHODS__BANK',

    [CompanyPaymentMethodV2Enum.cash]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CASH',
    [CompanyPaymentMethodV2Enum.nonCash]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CASHLESS_TRANSFERS',
    [CompanyPaymentMethodV2Enum.card]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CARD',
    [CompanyPaymentMethodV2Enum.emoney]: 'ADDITIONAL_INFO__PAYMENT_METHODS__ELECTRONIC_MONEY',
    [CompanyPaymentMethodV2Enum.crypto]: 'ADDITIONAL_INFO__PAYMENT_METHODS__CRYPTOCURRENCY',
    [CompanyPaymentMethodV2Enum.bank]: 'ADDITIONAL_INFO__PAYMENT_METHODS__BANK',
};

export const DEFAULT_PAYMENT_METHODS_V2: PaymentMethodsFieldV2Type = {
    [CompanyPaymentMethodV2Enum.card]: false,
    [CompanyPaymentMethodV2Enum.cash]: false,
    [CompanyPaymentMethodV2Enum.emoney]: false,
    [CompanyPaymentMethodV2Enum.nonCash]: false,
    [CompanyPaymentMethodV2Enum.crypto]: false,
    [CompanyPaymentMethodV2Enum.bank]: false,
};
