import {LangKeyType} from '../../../../provider/locale/translation/type';
import {CalendarPostStatusEnum} from '../../../../service/posts/posts-types';

export const BADGE_COLOR_BY_CALENDAR_POST_STATUS: Record<CalendarPostStatusEnum, string> = {
    [CalendarPostStatusEnum.publishPlanned]: '#4581c5',
    [CalendarPostStatusEnum.deletePlanned]: '#dc6c59',
    [CalendarPostStatusEnum.published]: '#50b079',
    [CalendarPostStatusEnum.refreshPlanned]: '#fdad59',
};

export const TEXT_IN_YEAR_CARD_BY_POST_STATUS: Record<CalendarPostStatusEnum, LangKeyType> = {
    [CalendarPostStatusEnum.publishPlanned]: 'POSTS__CALENDAR_BADGE__YEAR_CARD__PUBLISH_PLANNED',
    [CalendarPostStatusEnum.deletePlanned]: 'POSTS__CALENDAR_BADGE__YEAR_CARD__DELETE_PLANNED',
    [CalendarPostStatusEnum.published]: 'POSTS__CALENDAR_BADGE__YEAR_CARD__SENT',
    [CalendarPostStatusEnum.refreshPlanned]: 'POSTS__CALENDAR_BADGE__YEAR_CARD__REFRESH_PLANNED',
};
