import {Button} from 'antd';
import {useCallback, useState} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {defaultGroupData, fetchGroupDelete, TagGroupType, useTagGroupList} from '../../../service/reviews/reviews-tags';
import {findInArray} from '../../../util/array';
import {toTrimmedString} from '../../../util/string';
import {TagsActionSelect} from '../tags/tags-action-select/tags-action-select';

import {TagsEditTable} from './tags-edit-table/tags-edit-table';

export function TagsEdit(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const [tagGroupIdList, setTagGroupIdList] = useState<Array<string>>([]);

    const {
        data: tagGroupListResult = [],
        isFetching: tagGroupListIsInProgress,
        refetch: refreshGroupList,
    } = useTagGroupList();
    const [newGroup, setNewGroup] = useState<Array<TagGroupType>>([]);

    const handleDelete = useCallback(
        (groupData?: TagGroupType) => {
            const groupTitleList: string = groupData
                ? groupData.title
                : tagGroupIdList
                      .map((tagGroupId: string): string => {
                          const tagGroup = findInArray<TagGroupType>(tagGroupListResult || [], {
                              id: Number(tagGroupId),
                          });

                          return toTrimmedString(tagGroup?.title);
                      })
                      .filter(Boolean)
                      .map((tagGroupTitle: string): string => `"${tagGroupTitle}"`)
                      .join(', ');

            modal.confirm({
                maskClosable: true,
                title: <Locale stringKey="TAGS__REMOVING_A_TAG_GROUP__HEADER" />,
                content: (
                    <Locale
                        stringKey="TAGS__REMOVING_A_TAG_GROUP__CONTENT"
                        valueMap={{
                            tagGroupTitle: groupTitleList,
                        }}
                    />
                ),
                okText: <Locale stringKey="TEXT__APPLY" />,
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                cancelButtonProps: {type: 'primary'},
                okButtonProps: {type: 'default', danger: true},
                onOk: async () => {
                    try {
                        await fetchGroupDelete({
                            ids: groupData ? groupData.id : tagGroupIdList,
                        });

                        snackbar.success(<Locale stringKey="TAGS__GROUP__DELETING_SUCCESS" />);
                        refreshGroupList();
                    } catch {
                        snackbar.error(<Locale stringKey="TAGS__GROUP__DELETING_ERROR" />);
                    }
                },
            });
        },
        [tagGroupIdList, modal, tagGroupListResult, snackbar, refreshGroupList]
    );

    const {
        reviewsManagementTags: reviewsManagementTagsRoute,
        reviewsManagementTagsEdit: reviewsManagementTagsEditRoute,
    } = appRoute;

    return (
        <Page>
            <Meta title={getLocalizedString('TAG_GROUPS__TAG_GROUPS')} />

            <BreadCrumbs
                list={[
                    {path: reviewsManagementTagsRoute.path, titleLangKey: 'CATEGORY_NAME__TAGS'},
                    {
                        path: reviewsManagementTagsEditRoute.path,
                        titleLangKey: 'TAG_GROUPS__TAG_GROUPS',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="TAG_GROUPS__TAG_GROUPS" />

                <Button
                    disabled={newGroup.length > 0}
                    onClick={() => {
                        setNewGroup([...newGroup, {...defaultGroupData}]);
                    }}
                    type="primary"
                >
                    <Locale stringKey="BUTTON__ADD_GROUP" />
                </Button>
            </PageHeader>

            <PageSubHeader>
                <Locale stringKey="TEXT__LIST_OF_GROUPS" />

                <TagsActionSelect isActive={tagGroupIdList.length > 0} isGroup onDelete={handleDelete} />
            </PageSubHeader>

            <TagsEditTable
                newGroup={newGroup}
                onDelete={handleDelete}
                refreshGroupList={refreshGroupList}
                setNewGroup={setNewGroup}
                setTagGroupIdList={setTagGroupIdList}
                tagGroupListIsInProgress={tagGroupListIsInProgress}
                tagGroupListResult={tagGroupListResult}
            />
        </Page>
    );
}
