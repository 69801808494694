import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {Text} from '../../../../../component/text/text';
import {useLoomMetadata} from '../../../../../provider/onboarding/onboarding-hook';
import {getFormattedVideoDuration} from '../../../../../util/time';
import * as styles from '../recommendations.scss';

type PropsType = {
    setTargetVideo: Dispatch<SetStateAction<string | null>>;
    recommendationsVideoLink: string;
    isLoadImage: boolean;
};

export function RecommendationsVideoBlock(props: PropsType): ReactElement | null {
    const {setTargetVideo, recommendationsVideoLink, isLoadImage} = props;
    const {data: loomThumbnailsResult} = useLoomMetadata(recommendationsVideoLink);

    return isLoadImage ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div className={styles.Recommendations_videoBlock} onClick={() => setTargetVideo(recommendationsVideoLink)}>
            <FontAwesomeIcon className={styles.Recommendations_playIcon} icon={faPlay} />
            {loomThumbnailsResult?.duration && (
                <Text className={styles.Recommendations_duration} small>
                    {getFormattedVideoDuration(Math.round(loomThumbnailsResult?.duration) * 1000)}
                </Text>
            )}
        </div>
    ) : null;
}
