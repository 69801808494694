import {useMemo, useState} from 'react';

import {getDefaultChartLegendEnabledState} from '../../../../layout/chart/legend/chart-legend-helper';
import {MultiSourceChart} from '../../../../layout/chart/multisource-chart/multi-source-chart';
import {
    MultiSourceDataSetType,
    MultiSourceDataType,
} from '../../../../layout/chart/multisource-chart/multi-source-chart-type';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {
    ReviewRatingByDateKeysEnum,
    ReviewsRatingByDateType,
} from '../../../../service/reviews-and-answers-statistics/reviews-ratings-by-date/reviews-rating-by-date-type';
import {roundToPrecision} from '../../../../util/number';

import {getRatingDistributionChartOptions, reviewRatingByTimeChartColor} from './rating-distribution-by-time-helper';
import {RatingDistributionByTimeLegend} from './rating-distribution-by-time-legend';
import * as styles from './rating-distribution-by-time.scss';

type PropsType = {
    isCompareMode: boolean;
    pdfPreview?: boolean;
    reviewsRatingByDate: ReviewsRatingByDateType;
};

export function RatingDistributionByTime(props: PropsType): JSX.Element {
    const {isCompareMode, reviewsRatingByDate, pdfPreview = false} = props;

    const {getLocalizedString} = useLocale();

    const {
        datasets: mainPeriodDatasets,
        labels: mainPeriodLabels,
    }: {
        datasets: Array<Omit<MultiSourceDataSetType, 'color'> & {color: string}>;
        labels: Array<string>;
    } = useMemo(() => {
        const currentPeriodDatasetValues = Object.values(reviewsRatingByDate.current).reduce(
            (accumulator, dayValue) => {
                accumulator.rating_1.push(dayValue.rating_1);
                accumulator.rating_2.push(dayValue.rating_2);
                accumulator.rating_3.push(dayValue.rating_3);
                accumulator.rating_4.push(dayValue.rating_4);
                accumulator.rating_5.push(dayValue.rating_5);

                const lastPreviousAverageRating = accumulator.avg_rating.at(-1);

                accumulator.avg_rating.push(roundToPrecision(dayValue.avg_rating || lastPreviousAverageRating || 0, 2));

                return accumulator;
            },
            {
                rating_1: [],
                rating_2: [],
                rating_3: [],
                rating_4: [],
                rating_5: [],
                avg_rating: [],
            } as Record<ReviewRatingByDateKeysEnum, Array<number>>
        );

        return {
            datasets: [
                {
                    label: '1 ⭐',
                    values: currentPeriodDatasetValues.rating_1,
                    stack: 'currentPeriod',
                    color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.One],
                    order: 2,
                    barPercentage: 1,
                },
                {
                    label: '2 ⭐',
                    values: currentPeriodDatasetValues.rating_2,
                    stack: 'currentPeriod',
                    color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Two],
                    order: 2,
                    barPercentage: 1,
                },
                {
                    label: '3 ⭐',
                    values: currentPeriodDatasetValues.rating_3,
                    stack: 'currentPeriod',
                    color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Three],
                    order: 2,
                    barPercentage: 1,
                },
                {
                    label: '4 ⭐',
                    values: currentPeriodDatasetValues.rating_4,
                    stack: 'currentPeriod',
                    color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Four],
                    order: 2,
                    barPercentage: 1,
                },
                {
                    label: '5 ⭐',
                    values: currentPeriodDatasetValues.rating_5,
                    stack: 'currentPeriod',
                    color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Five],
                    order: 2,
                    barPercentage: 1,
                },
                {
                    label: getLocalizedString('REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RATING'),
                    type: 'line',
                    yAxisID: 'y2',
                    values: currentPeriodDatasetValues.avg_rating,
                    stack: 'currentPeriodAvgRating',
                    color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Average],
                    borderColor: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Average],
                    order: 1,
                },
            ],
            labels: Object.keys(reviewsRatingByDate.current),
        };
    }, [getLocalizedString, reviewsRatingByDate]);

    const {
        datasets: compareDataDatasets,
        labels: compareLabels,
    }: {
        datasets: Array<MultiSourceDataSetType>;
        labels: Array<string>;
    } = useMemo(() => {
        if (!reviewsRatingByDate.compared) {
            return {
                datasets: [],
                labels: [],
            };
        }

        const currentPeriodValuesCount = Object.values(reviewsRatingByDate.current).length;

        const comparePeriodDatasetValues = Object.values(reviewsRatingByDate.compared)
            .slice(0, currentPeriodValuesCount)
            .reduce(
                (accumulator, dayValue) => {
                    accumulator.rating_1.push(dayValue.rating_1);
                    accumulator.rating_2.push(dayValue.rating_2);
                    accumulator.rating_3.push(dayValue.rating_3);
                    accumulator.rating_4.push(dayValue.rating_4);
                    accumulator.rating_5.push(dayValue.rating_5);

                    const lastPreviousAverageRating = accumulator.avg_rating.at(-1);

                    accumulator.avg_rating.push(
                        roundToPrecision(dayValue.avg_rating || lastPreviousAverageRating || 0)
                    );

                    return accumulator;
                },
                {
                    rating_1: [],
                    rating_2: [],
                    rating_3: [],
                    rating_4: [],
                    rating_5: [],
                    avg_rating: [],
                } as Record<ReviewRatingByDateKeysEnum, Array<number>>
            );

        const comparePeriodDatasets: Array<MultiSourceDataSetType> = isCompareMode
            ? [
                  {
                      label: '1 ⭐',
                      values: comparePeriodDatasetValues.rating_1,
                      stack: 'comparePeriod',
                      color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.One],
                      stripes: true,
                      dashed: true,
                      order: 2,
                  },
                  {
                      label: '2 ⭐',
                      values: comparePeriodDatasetValues.rating_2,
                      stack: 'comparePeriod',
                      color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Two],
                      stripes: true,
                      order: 2,
                  },
                  {
                      label: '3 ⭐',
                      values: comparePeriodDatasetValues.rating_3,
                      stack: 'comparePeriod',
                      color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Three],
                      stripes: true,
                      order: 2,
                  },
                  {
                      label: '4 ⭐',
                      values: comparePeriodDatasetValues.rating_4,
                      stack: 'comparePeriod',
                      color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Four],
                      stripes: true,
                      order: 2,
                  },
                  {
                      label: '5 ⭐',
                      values: comparePeriodDatasetValues.rating_5,
                      stack: 'comparePeriod',
                      color: reviewRatingByTimeChartColor[ReviewRatingByDateKeysEnum.Five],
                      stripes: true,
                      order: 2,
                  },
                  {
                      label: getLocalizedString('REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RATING'),
                      type: 'line',
                      yAxisID: 'y2',
                      values: comparePeriodDatasetValues.avg_rating,
                      stack: 'comparePeriodAvgRating',
                      color: reviewRatingByTimeChartColor.compareAverageRating,
                      borderColor: reviewRatingByTimeChartColor.compareAverageRating,
                      order: 1,
                      dashed: true,
                  },
              ]
            : [];

        return {
            datasets: comparePeriodDatasets,
            labels: Object.keys(reviewsRatingByDate.compared).slice(0, currentPeriodValuesCount),
        };
    }, [getLocalizedString, isCompareMode, reviewsRatingByDate]);

    const nonFilteredChartData: MultiSourceDataType = useMemo(() => {
        return {
            labels: mainPeriodLabels,
            datasets: [...mainPeriodDatasets, ...compareDataDatasets],
        };
    }, [mainPeriodLabels, mainPeriodDatasets, compareDataDatasets]);

    const [enabledDatasetsMap, setEnabledDatasetsMap] = useState<Record<string, boolean>>(
        getDefaultChartLegendEnabledState(nonFilteredChartData)
    );

    const activeDatasetsData: MultiSourceDataType = useMemo(() => {
        return {
            labels: Object.keys(reviewsRatingByDate.current),
            datasets: [...mainPeriodDatasets, ...compareDataDatasets].filter((dataset) =>
                Boolean(enabledDatasetsMap[dataset.label])
            ),
        };
    }, [reviewsRatingByDate, mainPeriodDatasets, compareDataDatasets, enabledDatasetsMap]);

    return (
        <div className={styles.RatingDistributionByTime}>
            <RatingDistributionByTimeLegend
                enabledDatasetsMap={enabledDatasetsMap}
                isCompareMode={isCompareMode}
                mainPeriodDatasets={mainPeriodDatasets}
                setEnabledDatasetsMap={setEnabledDatasetsMap}
            />

            <div className={styles.RatingDistributionByTime_chartWrapper}>
                <MultiSourceChart
                    data={activeDatasetsData}
                    options={getRatingDistributionChartOptions({
                        tooltipOptions: {
                            data: {
                                datasets: mainPeriodDatasets,
                                labels: mainPeriodLabels,
                            },
                            ...(isCompareMode
                                ? {
                                      compareData: {
                                          datasets: compareDataDatasets,
                                          labels: compareLabels,
                                      },
                                  }
                                : {}),
                        },
                        showTooltip: !pdfPreview,
                    })}
                />
            </div>
        </div>
    );
}
