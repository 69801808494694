import {useCallback, useEffect, useState} from 'react';

import {useRefreshId} from '../../util/hook';
import {useApiHooks} from '../api-hook/api-hook';
import {LoadTimelineDataOptionsType} from '../dashboard/dashboard-type';

import {getTimelineForOneCompany} from './timeline-activity-api';
import {
    TimelineDataType,
    TimelineForOneCompanyHookPropsType,
    TimelineForOneCompanyHookType,
} from './timeline-activity-type';

export function useTimelineForOneCompany(props: TimelineForOneCompanyHookPropsType): TimelineForOneCompanyHookType {
    const {perPage, companyId} = props;

    const {isInProgress, setIsInProgress, processError, setProcessError, reset, result, setResult} =
        useApiHooks<Array<TimelineDataType>>();
    const {refreshId, refresh} = useRefreshId();

    const [nextCursor, setNextCursor] = useState<string | null>(null);

    const loadData = useCallback(
        (options: LoadTimelineDataOptionsType = {}) => {
            const {cursor = null, shouldReplaceContent = true} = options;

            setIsInProgress(true);

            getTimelineForOneCompany(companyId, cursor, perPage)
                .then((response) => {
                    setNextCursor(response.next);
                    setResult(shouldReplaceContent ? response.results : [...(result || []), ...response.results]);
                })
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [companyId, perPage, setIsInProgress, setProcessError, setResult, result]
    );

    function loadMore() {
        if (!isInProgress && result !== null && nextCursor !== null) {
            loadData({cursor: nextCursor, shouldReplaceContent: false});
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId]);

    return {isInProgress, processError, result, reset, loadMore, canLoadMore: nextCursor !== null, refresh};
}
