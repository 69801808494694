import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, MenuProps, Space} from 'antd';
import {useCallback, useMemo} from 'react';

import {Locale} from '../../../../provider/locale/locale';
import {
    ResponseTemplateGroupType,
    useResponseTemplateGroupList,
} from '../../../../service/reviews/reviews-response-templates';

type PropsType = {
    onDelete: () => void;
    isActive: boolean;
    className?: string;
    isGroup?: boolean;
    updateTemplateGroups?: (id: number | null) => void;
    updateTemplatesType?: (type: boolean) => void;
};

export function ResponseTemplatesActionSelect(props: PropsType): JSX.Element {
    const {isActive, className, onDelete, isGroup, updateTemplateGroups, updateTemplatesType} = props;

    const {data: responseTemplateGroupListResult} = useResponseTemplateGroupList();

    const updateGroup = useCallback(
        (id: number | null) => {
            if (updateTemplateGroups) {
                updateTemplateGroups(id);
            }
        },
        [updateTemplateGroups]
    );

    const availableResponseTemplateGroupList: Array<ResponseTemplateGroupType> =
        useMemo((): Array<ResponseTemplateGroupType> => {
            return responseTemplateGroupListResult || [];
        }, [responseTemplateGroupListResult]);

    const actionMenuItems: MenuProps['items'] = useMemo(
        () => [
            ...(!isGroup && updateTemplatesType
                ? [
                      {
                          key: 'assign-type',
                          label: <Locale stringKey="BUTTON__ASSIGN_TYPE" />,
                          children: [
                              {
                                  key: 'tag-type-private',
                                  label: <Locale stringKey="TAGS__TAG_TYPE__PRIVATE" />,
                                  onClick: () => updateTemplatesType(true),
                              },
                              {
                                  key: 'tag-type-command',
                                  label: <Locale stringKey="TAGS__TAG_TYPE__COMMAND" />,
                                  onClick: () => updateTemplatesType(false),
                              },
                          ],
                      },
                      {
                          key: 'move-selected',
                          label: <Locale stringKey="BUTTON__MOVE_SELECTED" />,
                          children: [
                              ...availableResponseTemplateGroupList.map(
                                  (responseTemplateGroup: ResponseTemplateGroupType) => {
                                      return {
                                          key: responseTemplateGroup.id,
                                          label: responseTemplateGroup.title,
                                          onClick: () => updateGroup(responseTemplateGroup.id),
                                      };
                                  }
                              ),
                              {
                                  key: '',
                                  label: <Locale stringKey="TEXT__WITHOUT_GROUP" />,
                                  onClick: () => updateGroup(null),
                              },
                          ],
                      },
                  ]
                : []),
            {
                key: 'delete-selected',
                label: <Locale stringKey="BUTTON__DELETE_SELECTED" />,
                danger: true,
                onClick: () => onDelete(),
            },
        ],
        [availableResponseTemplateGroupList, isGroup, onDelete, updateGroup, updateTemplatesType]
    );

    return (
        <Dropdown disabled={!isActive} menu={{items: actionMenuItems}} trigger={['click', 'hover']}>
            <Button className={className}>
                <Space>
                    <Locale stringKey="BUTTON__ACTIONS" />
                    <FontAwesomeIcon icon={faChevronDown} size="sm" />
                </Space>
            </Button>
        </Dropdown>
    );
}
