import {Button, Space} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';

import {VideoPopup} from '../../../../component/video-popup/video-popup';
import {Locale} from '../../../../provider/locale/locale';
import {SyncCompaniesStatusEnum} from '../../../../provider/onboarding/onboarding-hook';
import {OnboardingCatalogsNameEnum, OnboardingStepEnum} from '../onboarding-const';
import {MappedDataType} from '../onboarding-type';

import {CatalogBlock} from './catalog-block/catalog-block';
import * as styles from './step.scss';

type PropsType = {
    title: ReactElement;
    mappedData: Array<MappedDataType>;
    isOpenStep: boolean;
    stepIndex: OnboardingStepEnum;
    active?: boolean;
    setIsOpenAutoOnboardingPopup?: Dispatch<SetStateAction<boolean>>;
};

export function OnboardingStep(props: PropsType): ReactElement {
    const {title, mappedData, isOpenStep, stepIndex, setIsOpenAutoOnboardingPopup} = props;
    const [targetVideo, setTargetVideo] = useState<string | null>(null);
    const [activeCatalog, setActiveCatalog] = useState<OnboardingCatalogsNameEnum>(OnboardingCatalogsNameEnum.Google);

    const firstOpenCatalog = mappedData.find((catalog) => catalog.completed === false)?.blockTitle;

    useEffect(() => {
        if (firstOpenCatalog) {
            setActiveCatalog(firstOpenCatalog);
        }
    }, [firstOpenCatalog]);

    const isShowAutoSyncPopupButton =
        stepIndex === OnboardingStepEnum.third &&
        mappedData.some((catalog) => catalog.catalogStatus === SyncCompaniesStatusEnum.Default);

    return (
        <div className={styles.Step}>
            {isOpenStep && targetVideo && <VideoPopup onClose={() => setTargetVideo(null)} url={targetVideo} />}

            <div className={styles.Step_stepNumber}>{stepIndex}</div>

            <Space className={styles.Step_wrapper} direction="vertical" size={12}>
                <Space size={8}>
                    {title}
                    {isShowAutoSyncPopupButton && (
                        <Button
                            className={styles.Step_autoSyncButton}
                            onClick={() => (setIsOpenAutoOnboardingPopup ? setIsOpenAutoOnboardingPopup(true) : null)}
                        >
                            <Locale stringKey="ONBOARDING__THIRD_STEP__BUTTON" />
                        </Button>
                    )}
                </Space>
                <Space className={styles.Step_wrapper} direction="vertical" size={4}>
                    {isOpenStep &&
                        mappedData.map((descriptionElement, indexData) => {
                            return (
                                <CatalogBlock
                                    activeCatalog={activeCatalog}
                                    descriptionData={descriptionElement}
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={indexData}
                                    setActiveCatalog={setActiveCatalog}
                                    setTargetVideo={setTargetVideo}
                                />
                            );
                        })}
                </Space>
            </Space>
        </div>
    );
}
