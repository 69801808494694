import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {Locale} from '../../../provider/locale/locale';
import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {requestReviewsAndAnswersExport} from './use-request-reviews-and-answers-export-api';

export function useRequestReviewsAndAnswersExport(
    filter: ReviewsAndAnswersFilterStateType
): UseMutationResult<void, unknown, void> {
    const {snackbar} = useSnackbar();

    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useMutation(
        ['reviews-and-answers-export', filter, mainFilterKey],
        () => {
            return requestReviewsAndAnswersExport(filter, mainFilterKey);
        },
        {
            onSuccess: () => {
                snackbar.success({
                    message: <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REPORT__SENT_BY_EMAIL__TITLE" />,
                    description: (
                        <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REPORT__SENT_BY_EMAIL__DESCRIPTION" />
                    ),
                });
            },
            onError: () => {
                snackbar.error({
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                    description: (
                        <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__REPORT__SENT_BY_EMAIL__ERROR_DESCRIPTION" />
                    ),
                });
            },
        }
    );
}
