import {Text} from '../../component/text/text';
import {classNames} from '../../util/css';

import {DoughnutStatisticPropsType} from './doughnut-statistic-type';
import * as styles from './doughnut-statistic.scss';

export function DoughnutStatistic(props: DoughnutStatisticPropsType): JSX.Element {
    const {color, label, value, className} = props;

    return (
        <div className={classNames(styles.DoughnutStatistic, className)} key={label}>
            <div className={styles.DoughnutStatistic_label}>
                <div className={styles.DoughnutStatistic_colorBox} style={{backgroundColor: color}} />
                <Text block lighter>
                    {label}
                </Text>
            </div>
            <Text block bolder lighter>
                {value}
            </Text>
        </div>
    );
}
