import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Collapse} from 'antd';

import {Text} from '../../../../component/text/text';
import {KnowledgeArticleType, KnowledgeDataChildType} from '../../../../service/knowledge/knowledge-type';
import {getRandomString} from '../../../../util/string';
import {KnowledgeArticleLink} from '../knowledge-article-link/knowledge-article-link';

import * as styles from './knowledge-category-item.scss';

type PropsType = {
    item: KnowledgeDataChildType | KnowledgeArticleType;
};

export function KnowledgeCategoryItem(props: PropsType): JSX.Element {
    const {item} = props;

    if ('categoryId' in item) {
        return (
            <Collapse
                className={styles.KnowledgeCategoryItem}
                // eslint-disable-next-line react/no-unstable-nested-components
                expandIcon={({isActive}) => (
                    <FontAwesomeIcon
                        className={styles.KnowledgeCategoryItem_expandIcon}
                        icon={faChevronDown}
                        // eslint-disable-next-line no-undefined
                        rotation={isActive ? 180 : undefined}
                    />
                )}
                expandIconPosition="end"
                items={[
                    {
                        key: item.categoryId,
                        label: (
                            <>
                                {item.title}
                                &nbsp;
                                <Text secondary>({item.content.length})</Text>
                            </>
                        ),
                        children:
                            item.content.length > 0 &&
                            item.content.map((category) => (
                                <KnowledgeCategoryItem
                                    item={category}
                                    key={`${(category as KnowledgeDataChildType).categoryId}-${getRandomString()}`}
                                />
                            )),
                    },
                ]}
            />
        );
    }

    return <KnowledgeArticleLink article={item} />;
}
