import {Space} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {ErrorList} from '../../../error-list/error-list';
import {SelectedCompanyCategoryItem} from '../../categories/select-categories-form-item/selected-category-item/selected-category-item';
import {Attribute} from '../attribute/attribute';
import * as attributesWidgetStyles from '../attributes-widget.scss';

type PropsType = {
    catalogId: ProvidersIdsEnum;
    namePath: [CompanyKeyEnum.Attributes, string];
    resetId: string | null;
};

export function AttributesWidgetTab(props: PropsType): JSX.Element | null {
    const {catalogId, namePath, resetId} = props;

    const mainCatalogCategory = Form.useWatch<CompanyKeyEnum.CatalogCategories, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.CatalogCategories
    )?.find((catalog) => catalog.catalogId === catalogId)?.categories?.[0];

    if (!mainCatalogCategory) {
        return null;
    }

    return (
        <div className={attributesWidgetStyles.attributes_widget__grid}>
            <Space direction="vertical" size={0}>
                <SelectedCompanyCategoryItem
                    className={attributesWidgetStyles.attributes_widget__category}
                    companyCategoryItem={mainCatalogCategory}
                    disableDragging
                    isEditingFirst={false}
                    isFirst
                    isMarkPrimaryAvailable
                    locked={false}
                    removeCompanyCategory={() => null}
                />

                <span className={attributesWidgetStyles.attributes_widget__category_description}>
                    <Locale stringKey="COMPANY_FORM__ATTRIBUTES__MAIN_CATEGORY" />
                </span>

                <Form.List name={namePath}>
                    {(formAttributes) => (
                        <>
                            {formAttributes.map(({name, key}) => (
                                <Form.Item key={key} name={name}>
                                    <Attribute resetId={resetId} />
                                </Form.Item>
                            ))}

                            <ErrorList name={namePath} />
                        </>
                    )}
                </Form.List>
            </Space>
        </div>
    );
}
