import {Text} from '../../../../../../../../../../../component/text/text';
import {useFormat} from '../../../../../../../../../../../util/format-hook/format-hook';
import {ExpandableTextPreview} from '../../../expandable-text-preview/expandable-text-preview';

import * as styles from './instagram-preview-body.scss';

type PropsType = {
    text: string;
    name?: string | null;
};

export function InstagramPreviewBody(props: PropsType): JSX.Element {
    const {text, name} = props;

    const {getFormattedDateTime} = useFormat();

    const dateNow = getFormattedDateTime(Date.now(), {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });

    return (
        <div className={styles.InstagramPreviewBody}>
            <Text bold stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__LIKE_PLACEHOLDER" />
            <ExpandableTextPreview
                className={styles.InstagramPreviewBody_content}
                expandButton={<Text secondary stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__READ_MORE" />}
                rows={2}
                text={
                    name ? (
                        <>
                            <Text bold>{name}</Text> {text}
                        </>
                    ) : (
                        <>
                            <Text bold stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__COMPANY_NICKNAME" /> {text}
                        </>
                    )
                }
            />
            <Text secondary stringKey="POSTS_FORM__PREVIEW__INSTAGRAM__VIEW_COMMENTS" />
            <Text secondary small>
                {dateNow}
            </Text>
        </div>
    );
}
