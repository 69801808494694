import {capitalize} from 'lodash';
import {useContext, useMemo} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {WidgetError} from '../../../../../../component/widget-error/widget-error';
import {DoughnutDataType} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../provider/main-filter/main-filter-type';
import {useReviewSentimentStats} from '../../../../../../service/reivew-analysis/review-sentiment/review-sentiment-hook';
import {SentimentEnum} from '../../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {getSentimentColorBySentiment, getSentimentStringKeyBySentiment} from '../../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {TopicPieChart} from '../topic-pie-chart/topic-pie-chart';

const TONALITY_FIELDNAME = 'tonalityIndex';

export function TopicSentimentChart(): JSX.Element {
    const {singleFilter} = useReviewsAnalysisFilter();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();

    const sentimentOptions = useMemo(() => ({...singleFilter, userTopics: [id]}), [id, singleFilter]);

    const {result, isInProgress, processError} = useReviewSentimentStats(sentimentOptions, mainFilterKey);

    const total = useMemo(() => {
        let sum = 0;

        if (result) {
            Object.entries(result).map((entry) => {
                if (capitalize(entry[0]) in SentimentEnum) {
                    sum += entry[1] || 0;
                }
            });
        }

        return sum;
    }, [result]);

    const {getLocalizedString} = useLocale();

    const pieChartData: Array<DoughnutDataType> = useMemo(() => {
        if (result) {
            return Object.entries(result)
                .filter((entry) => capitalize(entry[0]) in SentimentEnum)
                .map(([key, value]) => ({
                    label: getLocalizedString(getSentimentStringKeyBySentiment(key as SentimentEnum)),
                    value: value || 0,
                    color: getSentimentColorBySentiment(key as SentimentEnum),
                }));
        }

        return [];
    }, [getLocalizedString, result]);

    const additionalChartData = useMemo(() => {
        if (result) {
            return Object.entries(result)
                .filter((entry) => entry[0] === TONALITY_FIELDNAME)
                .map(([_key, value]) => ({
                    label: getLocalizedString('REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_DISTRIBUTION__CENTER_LABEL'),
                    value: value || 0,
                    color: getSentimentColorBySentiment(SentimentEnum.Positive),
                }));
        }

        return [];
    }, [getLocalizedString, result]);

    if (processError) {
        return <WidgetError />;
    }

    return (
        <TopicPieChart
            additionalData={additionalChartData}
            caption={getLocalizedString('REVIEWS_ANALYSIS__TOPIC__SENTIMENT_CHART_TOTAL', {total: total.toString()})}
            data={pieChartData}
            isInProgress={isInProgress}
            total={total}
        />
    );
}
