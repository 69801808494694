import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {handleReplyError} from './profile-helper';
import {ProfileNewPasswordType, ProfileType} from './profile-type';

export function fetchProfile(): Promise<ProfileType> {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    return fetchX<ProfileType>(rootApiUrl + '/user/profile/');
}

export function postProfile(profile: ProfileType): Promise<ProfileType> {
    return fetchX<ProfileType>(rootApiUrl + '/user/profile/', {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.post,
        body: JSON.stringify(profile),
    });
}

export function putProfileNewPassword(password: ProfileNewPasswordType): Promise<ProfileType> {
    return fetchX<ProfileType>(rootApiUrl + '/user/profile/', {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.put,
        body: JSON.stringify(password),
    });
}

export function deleteProfile(password: string): Promise<unknown> {
    return fetchX(rootApiUrl + '/user/profile/', {
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        method: FetchMethodEnum.delete,
        body: JSON.stringify({password}),
    }).catch(handleReplyError);
}
