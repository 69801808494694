import {Tag} from 'antd';
import {flatten, uniq} from 'lodash';
import {ReactElement} from 'react';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';
import {UniqueLicenseDataType} from '../../../../../../service/tariffs/tariffs-licenses';
import {useLicensesDescription} from '../../../../../../service/tariffs/tariffs-licenses-description';
import {classNames} from '../../../../../../util/css';
import {LicensesCodeEnum, omnichannelFieldToRenderNames, omnichannelOptionsKeyMap} from '../license-const';
import {compareTypes} from '../license-helper';

import * as styles from './license-tags.scss';

type PropsType = {
    data: UniqueLicenseDataType | null;
    licenseCode: string;
};

// eslint-disable-next-line complexity
export function LicenseTags(props: PropsType): ReactElement | null {
    const {data, licenseCode} = props;

    const {data: licensesDescription} = useLicensesDescription();

    const allType =
        licenseCode === LicensesCodeEnum.NotificationsLicence
            ? licensesDescription
                  ?.find(
                      (licenseDescription) => licenseDescription.licenseCode === LicensesCodeEnum.NotificationsLicence
                  )
                  ?.types?.map((item) => item.label) ?? []
            : [];

    const groupedTypes =
        licenseCode === LicensesCodeEnum.NotificationsLicence && data
            ? uniq(flatten(data.map((item) => item.types)))
            : [];

    const omnichannelTypes =
        licenseCode === 'omnichannel_licence' && data ? uniq(flatten(data.map((item) => item.omnichannelOptions))) : [];

    const omnichannelTags =
        omnichannelTypes.length > 0 &&
        omnichannelFieldToRenderNames.map((option) => {
            return omnichannelTypes.includes(option) ? (
                <Tag className={styles.License_additionalTag} key={option}>
                    <Text bolder lighter small>
                        <Locale stringKey={omnichannelOptionsKeyMap[option]} />
                    </Text>
                </Tag>
            ) : (
                <Tag
                    bordered={false}
                    className={classNames(styles.License_additionalTag, styles.License_additionalTag__disabled)}
                >
                    <Text secondary small>
                        <Locale stringKey={omnichannelOptionsKeyMap[option]} />
                    </Text>
                </Tag>
            );
        });

    const groupedTags = groupedTypes.map((tag) => {
        return (
            <Tag className={styles.License_additionalTag} key={tag}>
                <Text bolder lighter small>
                    {tag}
                </Text>
            </Tag>
        );
    });

    const allGroupedTags = compareTypes(allType, groupedTypes).map((option) => (
        <Tag
            bordered={false}
            className={classNames(styles.License_additionalTag, styles.License_additionalTag__disabled)}
            key={option}
        >
            <Text secondary small>
                {option}
            </Text>
        </Tag>
    ));

    if (groupedTypes.length === 0 && compareTypes(allType, groupedTypes).length === 0 && omnichannelTypes.length > 0) {
        return null;
    }

    return (
        <>
            {groupedTags}
            {allGroupedTags}
            {omnichannelTags}
        </>
    );
}
