import {Table} from 'antd';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../component/text/text';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/localization';
import {useDashboardManyCompaniesGoodsStat} from '../../../../service/dashboard/dashboard-hook';
import {DashboardManyCompaniesGoodsStatsType} from '../../../../service/dashboard/dashboard-type';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {MANY_COMPANIES_PRICES_COLUMNS} from './prices-const';
import {PricesEmpty} from './prices-empty';
import * as styles from './prices.scss';

export function ManyCompaniesPrices(): JSX.Element {
    const {result: dashboardManyCompaniesGoodsStats, isInProgress, processError} = useDashboardManyCompaniesGoodsStat();

    if (isInProgress || !dashboardManyCompaniesGoodsStats) {
        return <Spinner />;
    }

    if (processError) {
        return <AlertFallback />;
    }

    const isEmpty = dashboardManyCompaniesGoodsStats.every((item) => item.count === 0);

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_HEADER" />}
                    widget={DashboardWidgetEnum.PricesMultiple}
                />
            }
        >
            {!isEmpty ? (
                <>
                    <Table<DashboardManyCompaniesGoodsStatsType>
                        className={styles.PricesTable}
                        columns={MANY_COMPANIES_PRICES_COLUMNS}
                        dataSource={dashboardManyCompaniesGoodsStats}
                        pagination={false}
                        rowKey={(record) => record.catalog.id}
                        size="small"
                    />
                    <Text block secondary small>
                        <span>
                            <Locale stringKey="DASHBOARD_PAGE__GOODS__CARD__NOTE__MANY" />
                            <NavigationLink to={appRoute.products.path}>
                                <Locale stringKey="CATEGORY_NAME__PRODUCTS" />
                            </NavigationLink>
                        </span>
                    </Text>
                </>
            ) : (
                <PricesEmpty />
            )}
        </PageCard>
    );
}
