import {Radio, Space} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {GoogleAccountType} from '../../../../../../../service/google/accounts/google-accounts-type';
import {AccountTag} from '../../../common/account-tag/account-tag';
import {ACCOUNT_ROLES} from '../../../google-account-sync-const';

import * as styles from './account-list-item.scss';

type PropsType = {
    account: GoogleAccountType;
};

export function AccountListItem(props: PropsType): JSX.Element {
    const {account} = props;

    const roleLangKey = account.role ? ACCOUNT_ROLES[account.role] : null;

    return (
        <Radio className={styles.Radio} value={account.id}>
            <div className={styles.Account}>
                <Space direction="vertical" size={0}>
                    <Text large>{account.name}</Text>

                    {roleLangKey && (
                        <Text
                            secondary
                            small
                            stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__ROLE"
                            valueMap={{
                                role: <Text lighter stringKey={roleLangKey} />,
                            }}
                        />
                    )}
                </Space>

                <AccountTag account={account} />
            </div>
        </Radio>
    );
}
