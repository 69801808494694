import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {CatalogLogo} from '../../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../../component/text/text';
import {useFilteredCatalogs} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {FakesCompanyInfoType} from '../../../../../service/fakes/fakes-companies-info';
import {FakesResultType} from '../../../../../service/fakes/fakes-results';
import {classNames} from '../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../util/type';
import {FakesCatalogImage} from '../catalog-image/fakes-catalog-image';

import * as styles from './fakes-popup-company-info.scss';

type PropsType = Pick<FakesResultType, 'logoUrl' | 'name' | 'address' | 'phone' | 'catalogId' | 'category'> &
    Pick<FakesCompanyInfoType, 'url'> & {className?: string};

export function FakesPopupCompanyInfo(props: PropsType): JSX.Element {
    const {className, name, phone, catalogId, url, logoUrl, category, address} = props;
    const {result: catalogs} = useFilteredCatalogs({abilityToReply: true}, '');

    function getCatalogName(providerId: number): string {
        const replacedProviderId = providerId === ProvidersIdsEnum.google ? ProvidersIdsEnum.googleMapsNew : providerId;

        return catalogs?.find(({id}) => id === replacedProviderId)?.name ?? '';
    }

    return (
        <div className={classNames(styles.FakesPopupCompanyInfo, className)}>
            <div className={styles.FakesPopupCompanyInfo_infoWrapper}>
                <Text>{name}</Text>
                <Text lighter>{address}</Text>
                <Text secondary small>
                    {category}
                </Text>
                <div className={styles.FakesPopupCompanyInfo_numberBlock}>
                    <Text secondary small>
                        {phone}
                    </Text>
                </div>
            </div>
            <div className={styles.FakesPopupCompanyInfo_logoBlock}>
                <FakesCatalogImage
                    className={styles.FakesPopupCompanyInfo_companyLogo}
                    isCompany={false}
                    logoUrl={logoUrl}
                    name={name}
                    url={url}
                />
            </div>
            <footer className={styles.FakesPopupCompanyInfo_footer}>
                <span>
                    <CatalogLogo catalogId={catalogId} className={styles.FakesPopupCompanyInfo_catalogLogo} />
                    <Text lighter>{getCatalogName(catalogId)}</Text>
                </span>
                {url && (
                    <a className={styles.FakesPopupCompanyInfo_linkBlock} href={url} rel="noreferrer" target="_blank">
                        <FontAwesomeIcon icon={faExternalLink} />
                        <Locale stringKey="SOURCES__TABLE__SOURCE" />
                    </a>
                )}
            </footer>
        </div>
    );
}
