import {ReactNode, useMemo} from 'react';

import {Text} from '../../component/text/text';
import {Locale} from '../../provider/locale/locale';
import {LangKeyType} from '../../provider/locale/translation/type';
import {classNames} from '../../util/css';

import defaultEmptyImageSrc from './image/empty.svg';
import * as styles from './empty.scss';

type PropsType = {
    className?: string;
    mainText?: LangKeyType;
    mainTextValueMap?: Record<string, ReactNode>;
    secondaryText?: LangKeyType;
    secondaryTextValueMap?: Record<string, ReactNode>;
    image?: ReactNode;
    imageSrc?: string | null;
    secondaryTextClassName?: string;
};

export function Empty(props: PropsType): JSX.Element {
    const {
        className,
        mainText,
        mainTextValueMap,
        secondaryText,
        secondaryTextValueMap,
        image,
        imageSrc,
        secondaryTextClassName,
    } = props;

    const mainTextNode = useMemo((): JSX.Element | null => {
        if (!mainText) {
            return null;
        }

        return (
            <h4 className={styles.empty__header}>
                <Locale stringKey={mainText} valueMap={mainTextValueMap} />
            </h4>
        );
    }, [mainText]);

    const secondaryTextNode = useMemo((): JSX.Element | null => {
        if (!secondaryText) {
            return null;
        }

        return (
            <Text
                className={classNames(styles.empty__text, secondaryTextClassName)}
                secondary
                stringKey={secondaryText}
                valueMap={secondaryTextValueMap}
            />
        );
    }, [secondaryText, secondaryTextValueMap, secondaryTextClassName]);

    const emptyImageSrc = imageSrc ?? defaultEmptyImageSrc;

    return (
        <div className={classNames(styles.empty, className)}>
            {image || <img alt="" className={styles.empty__image} src={emptyImageSrc} />}

            {mainTextNode}

            {secondaryTextNode}
        </div>
    );
}
