import {DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';

import {useLicenses} from '../../../../../../../provider/license/license-hook';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useDateTimePickerDisabler} from '../../../../../../../service/datepicker/datetime-picker-hook';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../service/posts/posts-types';
import {FeaturesEnum} from '../../../../../../../service/user/user-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../post-form-type';
import {DATE_TIME_FORMAT} from '../../../posts-form-helper';

import {dayJsInSelectedTimezone} from './helper';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
} & PostFormItemPropsType;

export function RemoveDateTimeItem(props: PropsType): JSX.Element {
    const {formInstance, disabled} = props;
    const {getLocalizedString} = useLocale();
    const {validateDayjsAfterRule, validateDayjsBeforeRule} = useFormRules();

    const timezone = Form.useWatch(PostFormFieldsEnum.Timezone, formInstance);

    const creationDayTime = Form.useWatch<PostFormFieldsEnum.CreateDateTime, FormInstance<PostFormType>>(
        PostFormFieldsEnum.CreateDateTime,
        formInstance
    ) as PostFormType[PostFormFieldsEnum.CreateDateTime] | null;

    const {dateDisabled: removeDateDisabled, timeDisabled: removeTimeDisabled} = useDateTimePickerDisabler({
        after: creationDayTime ?? dayJsInSelectedTimezone(timezone),
    });

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.posts];

    function handleChange(date: Dayjs | null) {
        if (!date) {
            formInstance.validateFields([PostFormFieldsEnum.Timezone]);
        }
    }

    return (
        <Form.Item<PostFormType>
            initialValue={null}
            label={<Locale stringKey="POSTS_FORM__SCHEDULE_POST__DELETE_DATE_TIME__LABEL" />}
            name={PostFormFieldsEnum.DeleteDateTime}
            rules={
                !disabled
                    ? [
                          validateDayjsAfterRule(
                              getLocalizedString('POSTS_VALIDATION_ERROR__DATE_NOT_IN_FUTURE'),
                              dayJsInSelectedTimezone(timezone).toDate()
                          ),
                          validateDayjsBeforeRule(
                              getLocalizedString('POSTS_VALIDATION_ERROR__LICENSE_WILL_EXPIRE_ON_DATE'),
                              dayJsInSelectedTimezone(timezone, dayjs(license?.finishAt)).toDate()
                          ),
                          ...(creationDayTime
                              ? [
                                    validateDayjsAfterRule(
                                        getLocalizedString('POSTS_VALIDATION_ERROR__REMOVE_DATE_AFTER_CREATION'),
                                        creationDayTime.toDate()
                                    ),
                                ]
                              : []),
                      ]
                    : []
            }
        >
            <DatePicker
                disabled={disabled}
                disabledDate={removeDateDisabled}
                disabledTime={removeTimeDisabled}
                format={DATE_TIME_FORMAT}
                onChange={handleChange}
                showTime={{format: 'HH:mm'}}
            />
        </Form.Item>
    );
}
