import {sortBy} from 'lodash';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType} from '../../catalog-list/catalog-list';
import {CatalogTab} from '../../catalog-list/catalog-tab';

import {LinksFormItem} from './links-form-item/links-form-item';

type PropsType = {
    name: string;
    catalog: CatalogConfigWithIndexType;
    hasInitialSocialNetworks: boolean;
    hasInitialWebsites: boolean;
    handleRemoveCatalog: (catalogId: number) => void;
};

export function CatalogLinks(props: PropsType): JSX.Element {
    const {name, catalog, hasInitialSocialNetworks, hasInitialWebsites, handleRemoveCatalog} = props;

    const {getLocalizedString} = useLocale();
    const form = Form.useFormInstance<CompanyFormType>();

    function handleRemove() {
        handleRemoveCatalog(catalog.catalogId);

        form.setFieldsValue({
            extraWebsites: form
                .getFieldValue(CompanyKeyEnum.ExtraWebsites)
                .filter(({catalogId}) => catalogId !== catalog.catalogId),
        });

        form.setFieldsValue({
            extraSocialNetworks: sortBy(
                form
                    .getFieldValue(CompanyKeyEnum.ExtraSocialNetworks)
                    .map((item) => (item.catalogId === catalog.catalogId ? {...item, socialNetworks: null} : item)),
                ({socialNetworks}) => socialNetworks // moving null values to the end of the array ensures that ant won't use them in tabs mapping
            ),
        });
    }

    return (
        <CatalogTab catalog={catalog} modalFieldName={name} onRemove={handleRemove}>
            <Form.List name={CompanyKeyEnum.ExtraWebsites}>
                {() => (
                    <LinksFormItem
                        hasInitialLinks={hasInitialWebsites}
                        label={getLocalizedString('COMPANY_FORM__LINKS__WEBSITES__LABEL')}
                        name={[CompanyKeyEnum.ExtraWebsites, catalog.index, 'websites']}
                        placeholder={getLocalizedString('COMPANY_FORM__LINKS__WEBSITES__PLACEHOLDER')}
                    />
                )}
            </Form.List>

            <Form.List name={CompanyKeyEnum.ExtraSocialNetworks}>
                {() => (
                    <LinksFormItem
                        hasInitialLinks={hasInitialSocialNetworks}
                        httpsOnly
                        label={getLocalizedString('COMPANY_FORM__LINKS__SOCIAL_NETWORKS__LABEL')}
                        name={[CompanyKeyEnum.ExtraSocialNetworks, catalog.index, 'socialNetworks']}
                        placeholder={getLocalizedString('COMPANY_FORM__LINKS__SOCIAL_NETWORKS__PLACEHOLDER')}
                    />
                )}
            </Form.List>
        </CatalogTab>
    );
}
