import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {RenderExpandIconProps} from 'rc-table/lib/interface';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {RatingDynamicsCompaniesStatsItemDataType} from '../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';

import * as styles from './rating-history-table.scss';

export function RatingHistoryExpandButton(
    props: RenderExpandIconProps<RatingDynamicsCompaniesStatsItemDataType>
): JSX.Element {
    const {expanded, onExpand, record} = props;

    const {getLocalizedString} = useLocale();

    return (
        <Tooltip
            title={expanded ? '' : getLocalizedString('REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__EXPAND_TABLE_TOOLTIP')}
            trigger="hover"
        >
            <Button
                className={styles.RatingHistoryTable_expandButton}
                icon={expanded ? <MinusOutlined /> : <PlusOutlined />}
                onClick={(event) => onExpand(record, event)}
            />
        </Tooltip>
    );
}
