import {Select} from 'antd';

import {useCatalogInfo} from '../../../../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {usePostsAvailableCatalogs} from '../../../../../../../../service/posts/posts-hooks';
import {
    PostFormFieldsEnum,
    PostFormType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../../service/posts/posts-types';
import {Form, FormInstance} from '../../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../../post-form-type';
import {POST_SOURCE_BY_ID} from '../../post-target-step-helper';

import {POST_SOURCE_INITIAL_VALUES} from './post-source-item-helper';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
} & PostFormItemPropsType;

export function PostSourcesItem(props: PropsType): JSX.Element {
    const {disabled, formInstance} = props;
    const {minLengthFieldRule} = useFormRules();

    const {availableCatalogs, isInProgress: isLoadingAvailableCatalogsInProgress} = usePostsAvailableCatalogs();
    const {getCatalogName} = useCatalogInfo();

    return (
        <Form.Item label={<Locale stringKey="POSTS_FORM__SOURCES__LABEL" />} required>
            <Form.List name={PostFormFieldsEnum.Posts} rules={[minLengthFieldRule(1, 'POSTS_FORM__ERROR__NO_SOURCES')]}>
                {(postFields, {add, remove}, {errors}) => {
                    return (
                        <div>
                            <Select<Array<PostSourceEnum>>
                                disabled={disabled}
                                loading={isLoadingAvailableCatalogsInProgress}
                                mode="multiple"
                                onDeselect={(source) => {
                                    const posts = formInstance.getFieldValue(PostFormFieldsEnum.Posts);
                                    const postCatalogIndex = posts.findIndex(
                                        (post) => post[SourcePostFormFieldsEnum.Catalog] === source
                                    );

                                    remove(postCatalogIndex);
                                }}
                                onSelect={(source) => {
                                    add({
                                        ...POST_SOURCE_INITIAL_VALUES,
                                        [SourcePostFormFieldsEnum.Catalog]: source,
                                    });
                                }}
                                optionFilterProp="children"
                                placeholder={<Locale stringKey="POSTS_FORM__SOURCES__PLACEHOLDER" />}
                                value={postFields.map(({name}) => {
                                    const post = formInstance.getFieldValue([PostFormFieldsEnum.Posts, name]);

                                    return post[SourcePostFormFieldsEnum.Catalog];
                                })}
                            >
                                {availableCatalogs.map((catalog) => {
                                    const catalogName = getCatalogName(catalog.id);

                                    return (
                                        <Select.Option key={catalogName} value={POST_SOURCE_BY_ID[catalog.id]}>
                                            {catalogName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                            <Form.ErrorList errors={errors} />
                        </div>
                    );
                }}
            </Form.List>
        </Form.Item>
    );
}
