import {useMemo} from 'react';
import {useHistory} from 'react-router';

import {getSortTableInitialState} from './save-sort-table-state';
import {SaveSortTableHookType} from './save-sort-table-type';

export function useSavedTableSort(): SaveSortTableHookType {
    const routerHistory = useHistory<Location>();
    const {location: routerLocation} = routerHistory;

    const savedSortState = useMemo(() => getSortTableInitialState(routerLocation.pathname), [routerLocation.pathname]);

    return {savedSortState, routerLocation: routerLocation.pathname};
}
