import {Rule} from 'antd/lib/form';
import {Dispatch, SetStateAction} from 'react';

import {LocaleContextType} from '../../../../../../../../../provider/locale/locale-context-type';

export function accountCompanyFieldRule(
    companyCount: number,
    getLocalizedString: LocaleContextType['getLocalizedString'],
    setCustomError: Dispatch<SetStateAction<boolean>>
): Rule {
    return {
        validator: (): Promise<void> => {
            if (companyCount > 0) {
                return Promise.resolve().then(() => setCustomError(false));
            }

            setCustomError(true);
            return Promise.reject(new Error(getLocalizedString('VALIDATION__ERROR__FIELD_IS_REQUIRED')));
        },
    };
}
