import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';

export const PROCESSING_STEP_REFETCH_INTERVAL = 5000;

export const NEW_LOCATION_OPTION = 'new';

export const processingStepCancelModalConfig = {
    title: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__CANCEL_MODAL__TITLE" />,
    content: <Text stringKey="GOOGLE_SYNC__PROCESSING_STEP__CANCEL_MODAL__CONTENT" />,
    okText: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__CANCEL_MODAL__CONFIRM" />,
    cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
    okButtonProps: {type: 'primary', danger: true, ghost: true},
    width: 400,
} as const;
