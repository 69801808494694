import {PropsWithChildren, useContext, useEffect, useRef} from 'react';

import {VirtualTableContext} from './virtualized-table-context';
import * as styles from './virtualized-table.scss';

type PropsType = PropsWithChildren<{
    index: number;
    resetVirtualGrid: (index: number) => void;
}>;

export function VirtualizedTableRow(props: PropsType): JSX.Element {
    const {children, index, resetVirtualGrid} = props;

    const root = useRef<HTMLDivElement | null>(null);
    const {setSize, windowWidth} = useContext(VirtualTableContext);

    useEffect(() => {
        if (root.current) {
            setSize(index, root.current.getBoundingClientRect().height);

            resetVirtualGrid(index);
        }
    }, [index, resetVirtualGrid, setSize, windowWidth]);

    return (
        <div className={styles.virtual_table__td} ref={root}>
            {children}
        </div>
    );
}
