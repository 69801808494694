import {LabeledValue} from 'antd/lib/select';
import {useEffect, useState} from 'react';

import {AddressType, CountryV2Type} from '../../../../../../service/api/api-type';
import {useSuggesterList} from '../../../../../../service/api-hook/api-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../typings/antd';

import {SelectLocation} from './select-location/select-location';
import {getUniqueCleanValueList} from './select-location/select-location-helper';

type PropsType = {
    value?: string | null;
    onChange?: (value?: string | null) => void;
    isDisabled: boolean;
    addressKey: keyof AddressType;
    label: JSX.Element;
    placeholder: string;
    searchQueryList: Array<string | null>;
    country: CountryV2Type | null;
    setAddress?: (value: LabeledValue | null) => void;
};

export function SelectText(props: PropsType): JSX.Element {
    const {value, onChange, isDisabled, addressKey, label, placeholder, searchQueryList, country} = props;

    const [searchQuery, setSearchQuery] = useState('');
    const {isInProgress, result} = useSuggesterList(
        searchQuery ? [...searchQueryList, searchQuery].join(', ') : null,
        country?.countryCode,
        country?.lang
    );

    const formCity = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'city'>([
        CompanyKeyEnum.Address,
        'city',
    ]);

    useEffect(() => {
        if (!formCity) {
            setSearchQuery('');
        }
    }, [formCity]);

    return (
        <SelectLocation
            addressKey={addressKey}
            country={country}
            isDisabled={isDisabled}
            isInProgress={isInProgress}
            isRequired={false}
            isSelectRequired={false}
            label={label}
            onChange={onChange}
            placeholder={placeholder}
            searchQuery={searchQuery}
            searchQueryList={searchQueryList}
            selectItems={getUniqueCleanValueList(result || [], addressKey)}
            setSearchQuery={setSearchQuery}
            value={value}
        />
    );
}
