import {faBullseyeArrow, faShoePrints, faStar} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Card, Space, Steps, theme} from 'antd';
import {ReactElement, useContext} from 'react';

import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {SystemContext} from '../../../../provider/system/system-context';
import {SystemContextType} from '../../../../provider/system/system-context-type';

import {BuyLicenseModal} from './buy-license-modal/buy-license-modal';
import image1 from './images/image_1.png';
import image2 from './images/image_2.png';
import image3 from './images/image_3.png';
import * as styles from './fakes-trial-landing.scss';

// eslint-disable-next-line complexity
export function FakesTrialLanding(): ReactElement {
    const {token} = theme.useToken();

    const systemContext = useContext<SystemContextType>(SystemContext);
    const {screen} = systemContext;
    const {isTablet, isMobile} = screen;

    return (
        <PageCard bodyClassName={styles.Wrapper} className={styles.Main}>
            <section className={styles.DeleteDescription}>
                <Space className={styles.DeleteDescription_wrapper} direction="vertical" size="large">
                    <span className={styles.DeleteDescription_title}>
                        <Locale stringKey="FAKES__TRIAL_LANDING__DELETE__TITLE" />
                        <sup>
                            <Text bolder className={styles.DeleteDescription_badge}>
                                new
                            </Text>
                        </sup>
                    </span>

                    <Text large stringKey="FAKES__TRIAL_LANDING__DELETE__DESCRIPTION" />
                    {!isTablet && !isMobile && (
                        <BuyLicenseModal buttonName="FAKES__TRIAL_LANDING__BUTTON__TITLE__LICENSE_CONNECT" />
                    )}
                </Space>
                <img alt="" className={styles.DeleteDescription_picture} src={image1} />
                {(isTablet || isMobile) && (
                    <BuyLicenseModal buttonName="FAKES__TRIAL_LANDING__BUTTON__TITLE__LICENSE_CONNECT" />
                )}
            </section>
            <section className={styles.DuplicatesCard}>
                {!isTablet && !isMobile && (
                    <div>
                        <img alt="" src={image2} />
                    </div>
                )}
                <Space className={styles.DeleteDescription_wrapper} direction="vertical" size="large">
                    <Text
                        bolder
                        className={styles.DuplicatesCard_title}
                        stringKey="FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__TITLE"
                    />
                    {(isTablet || isMobile) && <img alt="" src={image2} />}
                    <span>
                        <Text bolder large stringKey="FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__DESCRIPTION__LARGE" />
                        <Text large stringKey="FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__DESCRIPTION" />
                    </span>
                    <Badge
                        color={token.colorPrimary}
                        text={<Text secondary small stringKey="FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__BADGE_FIRST" />}
                    />
                    <Badge
                        color={token.colorPrimary}
                        text={<Text secondary small stringKey="FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__BADGE_SECOND" />}
                    />
                    <BuyLicenseModal buttonName="FAKES__TRIAL_LANDING__BUTTON__TITLE__DELETE_DUPLICATES" />
                </Space>
            </section>
            <section className={styles.DuplicatesCard}>
                <Space className={styles.DeleteDescription_wrapper} direction="vertical" size="large">
                    <Text
                        bolder
                        className={styles.DuplicatesCard_title}
                        stringKey="FAKES__TRIAL_LANDING__FAKES_BLOCK__TITLE"
                    />
                    {(isTablet || isMobile) && <img alt="" src={image3} />}

                    <span>
                        <Text bolder large stringKey="FAKES__TRIAL_LANDING__FAKES_BLOCK__DESCRIPTION__LARGE" />
                        <Text large stringKey="FAKES__TRIAL_LANDING__FAKES_BLOCK__DESCRIPTION" />
                    </span>
                    <Badge
                        color={token.colorPrimary}
                        text={<Text secondary small stringKey="FAKES__TRIAL_LANDING__FAKES_BLOCK__BADGE_FIRST" />}
                    />
                    <Badge
                        color={token.colorPrimary}
                        text={<Text secondary small stringKey="FAKES__TRIAL_LANDING__FAKES_BLOCK__BADGE_SECOND" />}
                    />
                    <BuyLicenseModal buttonName="FAKES__TRIAL_LANDING__BUTTON__TITLE__DELETE_FAKES" />
                </Space>
                {!isTablet && !isMobile && (
                    <div>
                        <img alt="" src={image3} />
                    </div>
                )}
            </section>
            <section className={styles.Cards}>
                <Text bolder className={styles.Cards_title} stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__TITLE" />
                <div className={styles.Cards_wrapper}>
                    <Card
                        className={styles.Cards_card}
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__FIRST_CARD__TITLE" />}
                    >
                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__FIRST_CARD__PARAGRAPH_ONE"
                            />
                        </p>
                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__FIRST_CARD__PARAGRAPH_TWO"
                            />
                        </p>
                    </Card>
                    <Card
                        className={styles.Cards_card}
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__SECOND_CARD__TITLE" />}
                    >
                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__SECOND_CARD__PARAGRAPH_ONE"
                            />
                        </p>

                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__SECOND_CARD__PARAGRAPH_TWO"
                            />
                        </p>
                    </Card>
                    <Card
                        className={styles.Cards_card}
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__THIRD_CARD__TITLE" />}
                    >
                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__THIRD_CARD__PARAGRAPH_ONE"
                            />
                        </p>
                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__THIRD_CARD__PARAGRAPH_TWO"
                            />
                        </p>
                    </Card>
                    <Card
                        className={styles.Cards_card}
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__FOURTH_CARD__TITLE" />}
                    >
                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__FOURTH_CARD__PARAGRAPH_ONE"
                            />
                        </p>

                        <p>
                            <Text
                                lighter
                                small
                                stringKey="FAKES__TRIAL_LANDING__CARDS_BLOCK__FOURTH_CARD__PARAGRAPH_TWO"
                            />
                        </p>
                    </Card>
                </div>
            </section>
            <section className={styles.Steps}>
                <Text bolder className={styles.Cards_title} stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__TITLE" />
                <Steps className={styles.Steps_wrapper} direction="vertical">
                    <Steps.Step
                        className={styles.Steps_step}
                        description={
                            <Text secondary stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__FIRST_STEP__DESCRIPTION" />
                        }
                        status="process"
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__FIRST_STEP__TITLE" />}
                    />
                    <Steps.Step
                        className={styles.Steps_step}
                        description={
                            <Text secondary stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__SECOND_STEP__DESCRIPTION" />
                        }
                        status="process"
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__SECOND_STEP__TITLE" />}
                    />
                    <Steps.Step
                        className={styles.Steps_step}
                        description={
                            <Text secondary stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__THIRD_STEP__DESCRIPTION" />
                        }
                        status="process"
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__THIRD_STEP__TITLE" />}
                    />
                    <Steps.Step
                        className={styles.Steps_step}
                        description={
                            <Text secondary stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__FOURTH_STEP__DESCRIPTION" />
                        }
                        status="process"
                        title={<Locale stringKey="FAKES__TRIAL_LANDING__STEPS_BLOCK__FOURTH_STEP__TITLE" />}
                    />
                </Steps>
            </section>
            <section className={styles.FinishSection}>
                <Text bolder className={styles.Cards_title} stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__TITLE" />
                <div className={styles.FinishSection_cardWrapper}>
                    <Card
                        className={styles.FinishSection_card}
                        title={
                            <>
                                <FontAwesomeIcon className={styles.FinishSection_icon} icon={faShoePrints} />
                                <Text bolder large stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__FIRST_CARD__TITLE" />
                            </>
                        }
                    >
                        <Text lighter stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__FIRST_CARD__DESCRIPTION" />
                    </Card>
                    <Card
                        className={styles.FinishSection_card}
                        title={
                            <>
                                <FontAwesomeIcon className={styles.FinishSection_icon} icon={faBullseyeArrow} />
                                <Text bolder large stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__SECOND_CARD__TITLE" />
                            </>
                        }
                    >
                        <Text lighter stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__SECOND_CARD__DESCRIPTION" />
                    </Card>
                    <Card
                        className={styles.FinishSection_card}
                        title={
                            <>
                                <FontAwesomeIcon className={styles.FinishSection_icon} icon={faStar} />
                                <Text bolder large stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__THIRD_CARD__TITLE" />
                            </>
                        }
                    >
                        <Text lighter stringKey="FAKES__TRIAL_LANDING__FINISH_BLOCK__THIRD_CARD__DESCRIPTION" />
                    </Card>
                </div>
                <BuyLicenseModal buttonName="FAKES__TRIAL_LANDING__BUTTON__TITLE__LICENSE_CONNECT" />
            </section>
        </PageCard>
    );
}
