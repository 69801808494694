import {faExternalLink, faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space} from 'antd';

import {CatalogLogo} from '../../../../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../../../../component/text/text';
import {useCatalogInfo} from '../../../../../../../provider/catalogs/catalogs-hook';
import {FakesCompanyInfoType} from '../../../../../../../service/fakes/fakes-companies-info';
import {FakesResultType} from '../../../../../../../service/fakes/fakes-results';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {FakesCatalogImage} from '../../../../common/catalog-image/fakes-catalog-image';

import * as styles from './fakes-location-column.scss';

type PropsType = {
    fake: FakesResultType | {catalog: ProvidersIdsEnum};
    companies: Array<FakesCompanyInfoType>;
};

export function FakesLocationColumn(props: PropsType): JSX.Element {
    const {fake, companies} = props;

    const {getCatalogName} = useCatalogInfo();

    if ('catalog' in fake) {
        return (
            <Space>
                <CatalogLogo catalogId={fake.catalog} className={styles.FakesLocationColumn_catalogLogo} />
                <span>{getCatalogName(fake.catalog)}</span>
            </Space>
        );
    }

    const {catalogId, logoUrl, name, address, phone, category, url} = fake;

    return (
        <>
            <Space align="start" size="middle">
                <FakesCatalogImage
                    className={styles.FakesLocationColumn_catalogImage}
                    logoUrl={logoUrl}
                    name={name}
                    url={url}
                />

                <div>
                    <div>
                        <Text bolder>{name}</Text>

                        {url && (
                            <a className={styles.FakesLocationColumn_link} href={url} rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                        )}
                    </div>

                    <Text block lighter>
                        {address}
                    </Text>

                    {(category || phone) && (
                        <Text block secondary>
                            {category && phone && catalogId !== ProvidersIdsEnum.yandex ? (
                                <>
                                    {category}, {phone}
                                </>
                            ) : (
                                category || phone
                            )}
                        </Text>
                    )}
                </div>
            </Space>

            {companies.map((company) => (
                <div className={styles.FakesLocationColumn_company} key={company.id}>
                    <FontAwesomeIcon className={styles.FakesLocationColumn_companyIcon} icon={faMapMarkerAlt} />

                    <div>
                        <div>
                            <Text bolder>{company.name}</Text>

                            {company.url && (
                                <a
                                    className={styles.FakesLocationColumn_link}
                                    href={company.url}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faExternalLink} />
                                </a>
                            )}
                        </div>

                        <Text block lighter>
                            {company.address}
                        </Text>
                    </div>
                </div>
            ))}
        </>
    );
}
