import {ApiError} from '../../../util/error';

import {SuggestedCatalogError, SuggestedCatalogErrorPayloadType} from './catalogs-type';

function isSuggestCatalogErrorPayloadType(errorPayload: unknown): errorPayload is SuggestedCatalogErrorPayloadType {
    return Array.isArray((errorPayload as SuggestedCatalogErrorPayloadType)?.name);
}

export function handleSuggestCatalogError(error: unknown): never {
    if (error instanceof ApiError && isSuggestCatalogErrorPayloadType(error.jsonData)) {
        throw new SuggestedCatalogError(error.message, error.statusCode, error.jsonData);
    }

    throw error;
}
