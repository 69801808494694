import {faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover, Tooltip} from 'antd';
import {useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../app-route';
import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {FormattedDateTime} from '../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../provider/locale/localization';
import {useModal} from '../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {fetchReviewsAbuse} from '../../../../service/reviews/reviews';
import {ReviewsScrapyCatalogType} from '../../../../service/reviews/reviews-scrapy-catalogs';
import {ReviewDataType} from '../../../../service/reviews/reviews-type';
import {classNames} from '../../../../util/css';
import {ApiError} from '../../../../util/error';
import {useUrl} from '../../../../util/url-hook/url-hook';
import * as styles from '../review-list-page-content/review-list/review/review.scss';
import {ReviewComplaintPopup} from '../review-list-page-content/review-list/review/review-complaint-popup/review-complaint-popup';

type PropsType = {
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewData: ReviewDataType;
    scrapyCatalogInfo?: ReviewsScrapyCatalogType;
    hasAccountError?: boolean;
};

// eslint-disable-next-line complexity
export function ReviewAbuse(props: PropsType): JSX.Element | null {
    const {refreshReview, reviewData, scrapyCatalogInfo, hasAccountError} = props;
    const {brand, abuseLastDate, id: reviewId, ableToAbuse, deletedAt} = reviewData;
    const {snackbar} = useSnackbar();
    const {companyName} = useDomainConfig();
    const {modal} = useModal();
    const {pushUrl} = useUrl();

    const [isComplaintPopupOpen, setIsComplaintPopupOpen] = useState<boolean>(false);
    const [complaintText, setComplaintText] = useState<string>('');
    const [isLoadingSendButton, setIsLoadingSendButton] = useState<boolean>(false);

    const isDeleted = Boolean(deletedAt);

    const sourceSettingsAccountLink = generatePath(appRoute.settingsSourceSettingsAccount.path, {
        id: scrapyCatalogInfo?.id || 0,
        groupId: scrapyCatalogInfo?.groupId || 0,
    });

    async function sendCompliant() {
        setIsLoadingSendButton(true);

        try {
            await fetchReviewsAbuse(reviewId, {
                reason: complaintText.trim(),
            });
            await refreshReview(reviewId);
            await setIsLoadingSendButton(false);
            snackbar.success({
                message: <Locale stringKey="REVIEWS__COMPLAINT__COMPLAINT_HAS_BEEN_SENT" />,
                description: <Locale stringKey="REVIEWS__COMPLAINT__COMPLAINT_HAS_BEEN_SENT__DESCRIPTION" />,
            });
            setIsComplaintPopupOpen(false);
        } catch (error: unknown) {
            await setIsLoadingSendButton(false);
            snackbar.error(<Locale stringKey="REVIEWS__COMPLAINT__COMPLAINT_SENT_ERROR" />);

            if (!(error instanceof ApiError)) {
                throw error;
            }
        }
    }

    if (isDeleted && !abuseLastDate && !ableToAbuse) {
        return null;
    }

    if (hasAccountError) {
        return (
            <button
                className={styles.Review_iconButton}
                onClick={() => {
                    modal.confirm({
                        title: <Locale stringKey="REVIEWS__REVIEW_ABUSE__ERROR_CONNECT_ACCOUNT__POPOVER__TITLE" />,
                        content: (
                            <Locale
                                stringKey="REVIEWS__REVIEW_ABUSE__ERROR_CONNECT_ACCOUNT__POPOVER__TEXT"
                                valueMap={{companyName}}
                            />
                        ),
                        okText: <Locale stringKey="REVIEWS__REVIEW_ABUSE__ERROR_CONNECT_ACCOUNT__POPOVER__BUTTON" />,
                        onOk: () => pushUrl(sourceSettingsAccountLink),
                        width: 400,
                        icon: (
                            <FontAwesomeIcon
                                className={classNames('anticon', styles.Review_infoIcon)}
                                icon={faInfoCircle}
                            />
                        ),
                    });
                }}
                type="button"
            >
                <FontAwesomeIcon icon={faTriangleExclamation} />
            </button>
        );
    }

    return (
        <>
            {abuseLastDate && (
                <Popover
                    content={
                        <PopoverTextWrapper>
                            <Locale stringKey="REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__TEXT" />
                        </PopoverTextWrapper>
                    }
                    title={
                        <PopoverTextWrapper>
                            <Locale
                                stringKey="REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__HEADER"
                                valueMap={{
                                    date: <FormattedDateTime key="date" value={new Date(abuseLastDate)} />,
                                }}
                            />
                        </PopoverTextWrapper>
                    }
                >
                    <button className={styles.Review_iconButton} disabled type="button">
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                    </button>
                </Popover>
            )}

            {!abuseLastDate && ableToAbuse ? (
                isComplaintPopupOpen ? (
                    <button className={styles.Review_iconButton} type="button">
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                    </button>
                ) : (
                    <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW_ABUSE__HINT" />}>
                        <button
                            className={styles.Review_iconButton}
                            onClick={() => setIsComplaintPopupOpen(true)}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                        </button>
                    </Tooltip>
                )
            ) : null}

            <ReviewComplaintPopup
                brandIsTest={brand.isTest}
                complaintText={complaintText}
                isLoading={isLoadingSendButton}
                isOpen={isComplaintPopupOpen}
                onCancel={() => setIsComplaintPopupOpen(false)}
                onOk={sendCompliant}
                reviewId={reviewId}
                setComplaintText={setComplaintText}
            />
        </>
    );
}
