export const enProfileTranslations = {
    PROFILE__PERSONAL_INFORMATION__TAB_NAME: 'Personal information',
    PROFILE__PERSONAL_INFORMATION__SUB_HEADER:
        'Using these contacts, representatives of catalogs can contact you to clarify information about the company.',
    PROFILE__PERSONAL_INFORMATION__LABEL__NAME: 'Name',
    PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__YOUR_NAME: 'Your name',
    PROFILE__PERSONAL_INFORMATION__LABEL__SURNAME: 'Surname',
    PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__YOUR_SURNAME: 'Your surname',
    PROFILE__PERSONAL_INFORMATION__LABEL__EMAIL_ADDRESS: 'E-mail address',
    PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__YOUR_EMAIL_ADDRESS: 'Your Email address',
    PROFILE__PERSONAL_INFORMATION__LABEL__PHONE_NUMBER: 'Phone number',
    PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__PHONE_NUMBER: 'Phone number',
    PROFILE__PERSONAL_INFORMATION__LABEL__CITIZENSHIP: 'Citizenship',
    PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__COUNTRY: 'Country',
    PROFILE__PERSONAL_INFORMATION__LABEL__TIMEZONE: 'Timezone',
    PROFILE__PERSONAL_INFORMATION__PLACEHOLDER__TIMEZONE: 'Timezone',
    PROFILE__PERSONAL_INFORMATION__UPDATED: 'Profile updated successfully',
    PROFILE__PERSONAL_INFORMATION__UPDATE_IS_FORBIDDEN: 'Updating personal information is forbidden.',
    PROFILE__PASSWORD_CHANGE__TAB_NAME: 'Password change',
    PROFILE__PASSWORD_CHANGE__SUB_HEADER: 'Use only strong passwords.',
    PROFILE__PASSWORD_CHANGE__LABEL__CURRENT_PASSWORD: 'Current Password',
    PROFILE__PASSWORD_CHANGE__PLACEHOLDER__CURRENT_PASSWORD: 'Current Password',
    PROFILE__PASSWORD_CHANGE__LABEL__NEW_PASSWORD: 'New Password',
    PROFILE__PASSWORD_CHANGE__PLACEHOLDER__NEW_PASSWORD: 'New Password',
    PROFILE__PASSWORD_CHANGE__LABEL__REPEAT_NEW_PASSWORD: 'Repeat new password',
    PROFILE__PASSWORD_CHANGE__PLACEHOLDER__REPEAT_NEW_PASSWORD: 'Repeat new password',
    PROFILE__PASSWORD_CHANGE__UPDATED: 'Password updated successfully',
    PROFILE__PASSWORD_CHANGE__UPDATE_FAIL: 'Failed to update the password',
    PROFILE__PASSWORD_CHANGE__UPDATE_FAIL_INCORRECT_PASSWORD: 'Incorrect old password',
    PROFILE__PASSWORD_CHANGE__UPDATE_FAIL_INCORRECT_NEW_PASSWORD: 'The new password is identical to the old one',
    PROFILE__TWO_FACTOR_AUTHENTICATION__TAB_NAME: 'Two-factor authentication',
    PROFILE__TWO_FACTOR_AUTHENTICATION__SUB_HEADER:
        'Protect your account with Google Authenticator for Android and iOS.',
    PROFILE__TWO_FACTOR_AUTHENTICATION__HOW_TO_SET_UP_GOOGLE_AUTHENTICATOR: 'How to set up Google Authenticator',
    PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__1: 'Install Google Authenticator',
    PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__2: 'Scan the QR code or enter the key manually',
    PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__2_DESCRIPTION: 'The "+" button in the smartphone application.',
    PROFILE__TWO_FACTOR_AUTHENTICATION__SET_UP_STEP__3: 'Enter a 6-digit code from the application.',
    PROFILE__TWO_FACTOR_AUTHENTICATION__LABEL__AUTHORIZATION_CODE: 'Authentication code',
    PROFILE__TWO_FACTOR_AUTHENTICATION__PLACEHOLDER__AUTHORIZATION_CODE: '000 000',
    PROFILE__TWO_FACTOR_AUTHENTICATION__BUTTON__CONNECT_GOOGLE_AUTHENTICATOR: 'Connect Google Authenticator',
    PROFILE__TWO_FACTOR_AUTHENTICATION__GOOGLE_AUTHENTICATOR_CONNECTED: 'Google Authenticator connected',
    PROFILE__TWO_FACTOR_AUTHENTICATION__BUTTON__DISABLE_GOOGLE_AUTHENTICATOR: 'Disable Google Authenticator',
    PROFILE__TWO_FACTOR_AUTHENTICATION__CODE: 'or use a key',
    PROFILE__TWO_FACTOR_AUTHENTICATION__CODE_COPIED: 'Key copied',
    PROFILE__TWO_FACTOR_AUTHENTICATION__SUB_HEADER_DISABLE_TWO_FACTOR:
        'To disable two-factor authentication, enter the 6-digit code.',
    PROFILE__LANGUAGE__TAB_NAME: 'Language',
    PROFILE__LANGUAGE__SELECT__LABEL: 'Select the language of your personal account',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT: 'Delete account',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__TITLE: 'Are you sure you want to delete your account?',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__DESCRIPTION:
        'After deleting your account, all your data will be inaccessible. To confirm the deletion of your account, please enter your account password.',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__NOTIFICATION__SUCCESS__TITLE: 'Account deleted',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__NOTIFICATION__SUCCESS__CONTENT:
        'Your account and all personal data have been successfully deleted.',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__PASSWORD__LABEL: 'Password',
    PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__PASSWORD__PLACEHOLDER: 'Password',
};
