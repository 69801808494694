import {Checkbox, TableColumnsType, TableColumnType} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {TableRowSelection} from 'antd/lib/table/interface';

import {CHECKBOX_DATA_INDEX, DEFAULT_CHECKBOX_COLUMNS_WIDTH} from './virtualized-table-const';
import {DefaultVirtialTableDataType} from './virtualized-table-type';

export function addWidthsToColumns<DataType extends DefaultVirtialTableDataType>(
    propsColumns: TableColumnsType<DataType>,
    tableWidth: number
): TableColumnsType<DataType> {
    const columnsWithoutWidthCount = propsColumns.filter((column) => !column.width).length;
    const restTableWidth = propsColumns.reduce(
        (width, column) => (column.width ? width - Number(column.width) : width),
        tableWidth
    );

    return propsColumns.map((column) => {
        if (column.width) {
            return column;
        }

        return {
            ...column,
            width: Math.floor(restTableWidth / columnsWithoutWidthCount),
        };
    });
}

function getCheckboxStates<DataType extends DefaultVirtialTableDataType>(
    rowSelection: TableRowSelection<DataType> | undefined,
    dataSource: ReadonlyArray<DataType> | undefined
): {isChecked: boolean; isIndeterminate: boolean} {
    if (!rowSelection || !dataSource) {
        return {isChecked: false, isIndeterminate: false};
    }

    const allKeys: Array<string> = dataSource.map((data) => data.key);

    if (allKeys.length === 0) {
        return {isChecked: false, isIndeterminate: false};
    }

    const isChecked = allKeys.every((key) => rowSelection.selectedRowKeys?.includes(key));
    const isIndeterminate = isChecked ? false : allKeys.some((key) => rowSelection.selectedRowKeys?.includes(key));

    return {isChecked, isIndeterminate};
}

export function appendCheckboxToColumn<DataType extends DefaultVirtialTableDataType>(
    columns: TableColumnsType<DataType>,
    rowSelection: TableRowSelection<DataType> | undefined,
    dataSource: ReadonlyArray<DataType> | undefined
): TableColumnsType<DataType> {
    if (!rowSelection || !dataSource) {
        return columns;
    }

    const {isChecked, isIndeterminate} = getCheckboxStates(rowSelection, dataSource);

    function handleChange(checkboxEvent: CheckboxChangeEvent): void {
        if (rowSelection?.onSelectAll) {
            rowSelection.onSelectAll(checkboxEvent.target.checked, [], []);
        }
    }

    const checkboxCell: TableColumnType<DataType> = {
        title: <Checkbox checked={isChecked} indeterminate={isIndeterminate} onChange={handleChange} />,
        width: rowSelection.columnWidth || DEFAULT_CHECKBOX_COLUMNS_WIDTH,
        dataIndex: CHECKBOX_DATA_INDEX,
    };

    return [checkboxCell, ...columns];
}

export function appendCheckboxToDataSource<DataType extends DefaultVirtialTableDataType>(
    dataSource: ReadonlyArray<DataType> | undefined,
    rowSelection: TableRowSelection<DataType> | undefined
): ReadonlyArray<DataType> {
    if (!dataSource) {
        return [];
    }

    if (!rowSelection) {
        return dataSource;
    }

    function handleChange(data: DataType, checkboxEvent: CheckboxChangeEvent): void {
        if (!rowSelection || !rowSelection.onSelect || !rowSelection.selectedRowKeys) {
            return;
        }

        rowSelection.onSelect(data, checkboxEvent.target.checked, [], checkboxEvent.nativeEvent);
    }

    return dataSource.map((data) => {
        const isSelect = rowSelection.selectedRowKeys?.includes(data.key);

        return {
            ...data,
            [CHECKBOX_DATA_INDEX]: <Checkbox checked={isSelect} onChange={(event) => handleChange(data, event)} />,
        };
    });
}
