import {faCheck, faRotateRight, faUserLock} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Popover, Select} from 'antd';
import {SyntheticEvent, useCallback, useMemo, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {DraggableModal} from '../../../../component/draggable-modal/draggable-modal';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Spinner} from '../../../../layout/spinner/spinner';
import {PopoverTextWrapper} from '../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {
    ResponseTemplateDataType,
    ResponseTemplateGroupType,
    useAllResponseTemplateList,
} from '../../../../service/reviews/reviews-response-templates';
import {classNames} from '../../../../util/css';
import {sortCompare} from '../../../../util/string';
import {IdNumberType} from '../../../../util/type';

import {searchIcon} from './auto-reply-form-popup-helper';
import * as styles from './auto-reply-form-popup.scss';

const {Option} = Select;

type PropsType = {
    onCancel: () => void;
    onOk: () => void;
    isOpen: boolean;
    isPrivateTemplatesDisabled?: boolean;
    selectedItemList: Array<IdNumberType>;
    setSelectedItemList: (selectedItemList: Array<IdNumberType>) => void;
    selectedResponseTemplateList: Array<ResponseTemplateDataType>;
    setSelectedResponseTemplateList: (selectedItemList: Array<ResponseTemplateDataType>) => void;
    className?: string;
    isRadioMode?: boolean;
    reviewId?: number;
};

export function AutoReplyTemplatePopup(props: PropsType): JSX.Element {
    const {
        selectedResponseTemplateList,
        setSelectedResponseTemplateList,
        className,
        isOpen,
        isPrivateTemplatesDisabled,
        onCancel,
        selectedItemList,
        setSelectedItemList,
        onOk,
        isRadioMode = false,
        reviewId,
    } = props;

    const {getLocalizedString, shortLocaleName} = useLocale();
    const {data, refetch, isFetching: isInProgress} = useAllResponseTemplateList(reviewId);

    const [selectedGroupId, setSelectedGroupId] = useState<number>(0);
    const [searchFilter, setSearchFilter] = useState<string>('');

    const allTemplateFullList: Array<ResponseTemplateDataType> = useMemo((): Array<ResponseTemplateDataType> => {
        return (data || []).sort((templateA: ResponseTemplateDataType, templateB: ResponseTemplateDataType): number =>
            sortCompare(shortLocaleName, templateA.title, templateB.title)
        );
    }, [data, shortLocaleName]);

    const handleClose = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const groupList: Array<ResponseTemplateGroupType> = useMemo(() => {
        const uniqueGroupList: Array<ResponseTemplateGroupType> = [];
        const uniqueGroupIdList: Array<number> = [];

        allTemplateFullList.forEach((templateInList: ResponseTemplateDataType) => {
            const {folder} = templateInList;

            if (!folder) {
                return;
            }

            const {id: groupId} = folder;

            if (uniqueGroupIdList.includes(groupId)) {
                return;
            }

            uniqueGroupIdList.push(groupId);
            uniqueGroupList.push(folder);
        });

        return uniqueGroupList.sort((groupA: ResponseTemplateGroupType, groupB: ResponseTemplateGroupType): number =>
            sortCompare(shortLocaleName, groupA.title, groupB.title)
        );
    }, [allTemplateFullList, shortLocaleName]);

    const allTemplateFilteredList: Array<ResponseTemplateDataType> = useMemo((): Array<ResponseTemplateDataType> => {
        const stringFilter = searchFilter.toLowerCase();

        return allTemplateFullList.filter((templateInList: ResponseTemplateDataType): boolean => {
            const {folder: group, title} = templateInList;
            const isSelectedGroupAccept = selectedGroupId === 0 || (group ? group.id === selectedGroupId : false);
            const isSearchFilterAccept = title.toLowerCase().includes(stringFilter);

            return isSearchFilterAccept && isSelectedGroupAccept;
        });
    }, [allTemplateFullList, selectedGroupId, searchFilter]);

    const selectedItemListLength = selectedItemList.length;
    const allTemplateFilteredListLength = allTemplateFilteredList.length;

    const articleLink = useMemo(
        () => (
            <NavigationLink to={appRoute.reviewsManagementResponseTemplates.path}>
                <Locale stringKey="AUTO_REPLIES__POPUP__PRIVATE_TEMPLATE_TOOLTIP__TEXT_LINK" />
            </NavigationLink>
        ),
        []
    );

    const renderListItem = useCallback(
        (responseTemplate: ResponseTemplateDataType): JSX.Element => {
            const {id, isPrivate, title, message} = responseTemplate;

            const isSelected = selectedItemList.some((selectedItem: IdNumberType): boolean => selectedItem.id === id);

            const listItemClassName = classNames(styles.AutoReplyFormPopup_listItem, {
                [styles.AutoReplyFormPopup_listItem__private]: isPrivate,
            });
            const buttonClassName = classNames(styles.AutoReplyFormPopup_listItemButton, {
                [styles.AutoReplyFormPopup_listItemButton__selected]: isSelected,
                [styles.AutoReplyFormPopup_listItemButton__disabled]: isPrivate && isPrivateTemplatesDisabled,
            });

            function handleOptionSelect() {
                if (isRadioMode) {
                    setSelectedResponseTemplateList(isSelected ? [] : [responseTemplate]);
                    // eslint-disable-next-line unicorn/consistent-destructuring
                    setSelectedItemList(isSelected ? [] : [{id: responseTemplate.id}]);
                    return;
                }

                if (isSelected) {
                    setSelectedResponseTemplateList(
                        selectedResponseTemplateList.filter(
                            (selectedItem: ResponseTemplateDataType): boolean => selectedItem.id !== id
                        )
                    );
                    setSelectedItemList(
                        selectedItemList.filter((selectedItem: IdNumberType): boolean => selectedItem.id !== id)
                    );
                    return;
                }

                setSelectedResponseTemplateList([...selectedResponseTemplateList, responseTemplate]);
                // eslint-disable-next-line unicorn/consistent-destructuring
                setSelectedItemList([...selectedItemList, {id: responseTemplate.id}]);
            }

            return (
                <li className={listItemClassName} key={id}>
                    <button
                        className={buttonClassName}
                        disabled={isPrivate && isPrivateTemplatesDisabled}
                        onClick={handleOptionSelect}
                        type="button"
                    >
                        {isSelected && (
                            <FontAwesomeIcon className={styles.AutoReplyFormPopup_listItemTick} icon={faCheck} />
                        )}

                        <div className={styles.AutoReplyFormPopup_listItemContent}>
                            <h4 className={styles.AutoReplyFormPopup_listItemHeader}>
                                {title}
                                {isPrivate && (
                                    <Popover
                                        content={
                                            <PopoverTextWrapper>
                                                <Locale
                                                    stringKey="AUTO_REPLIES__POPUP__PRIVATE_TEMPLATE_TOOLTIP__TEXT"
                                                    valueMap={{articleLink}}
                                                />
                                            </PopoverTextWrapper>
                                        }
                                        placement="right"
                                        title={
                                            <PopoverTextWrapper>
                                                <Locale stringKey="AUTO_REPLIES__POPUP__PRIVATE_TEMPLATE_TOOLTIP__TITLE" />
                                            </PopoverTextWrapper>
                                        }
                                    >
                                        <FontAwesomeIcon
                                            className={styles.AutoReplyFormPopup_listItemHeaderIcon}
                                            icon={faUserLock}
                                        />
                                    </Popover>
                                )}
                            </h4>
                            <p className={styles.AutoReplyFormPopup_listItemText}>{message}</p>
                        </div>
                    </button>
                </li>
            );
        },
        [
            isPrivateTemplatesDisabled,
            selectedItemList,
            articleLink,
            isRadioMode,
            setSelectedResponseTemplateList,
            selectedResponseTemplateList,
            setSelectedItemList,
        ]
    );

    function onSubmit() {
        if (selectedItemList[0]) {
            track(AnalyticsTarget.ReviewsManagement.Reviews.AddResponseTemplate, selectedItemList[0].id);
        }

        onOk();
    }

    return (
        <DraggableModal
            className={classNames(styles.AutoReplyFormPopup, className)}
            footer={[
                <Button className={styles.AutoReplyFormPopup_footerButton} key="cancel" onClick={handleClose}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button
                    className={styles.AutoReplyFormPopup_footerButton}
                    disabled={selectedItemListLength === 0}
                    key="ok"
                    onClick={onSubmit}
                    type="primary"
                >
                    <Locale stringKey="TEXT__APPLY" />
                </Button>,
            ]}
            onCancel={handleClose}
            onOk={onSubmit}
            open={isOpen}
            title={<Locale stringKey="AUTO_REPLIES__POPUP__RESPONSE_TEMPLATES" />}
            width="720px"
        >
            <div className={styles.AutoReplyFormPopup_controlsWrapper}>
                <Input
                    onInput={(event: SyntheticEvent<HTMLInputElement>) => {
                        setSearchFilter(event.currentTarget.value.trim());
                    }}
                    placeholder={getLocalizedString('AUTO_REPLIES__POPUP__SEARCH_FOR_TEMPLATES')}
                    suffix={searchIcon}
                />

                <Select<number>
                    defaultValue={selectedGroupId}
                    filterOption={() => true}
                    onChange={setSelectedGroupId}
                    placeholder={getLocalizedString('AUTO_REPLIES__POPUP__ALL_GROUPS')}
                    popupMatchSelectWidth={false}
                >
                    <Option key="all-groups" value={0}>
                        <Locale stringKey="AUTO_REPLIES__POPUP__ALL_GROUPS" />
                    </Option>

                    {groupList.map((groupItem: ResponseTemplateGroupType): JSX.Element => {
                        const {title, id} = groupItem;

                        return (
                            <Option key={id} value={id}>
                                {title}
                            </Option>
                        );
                    })}
                </Select>
            </div>

            <div className={styles.AutoReplyFormPopup_scrollArea}>
                {isInProgress && <Spinner />}

                {!isInProgress && allTemplateFilteredListLength > 0 && (
                    <ul className={styles.AutoReplyFormPopup_list}>{allTemplateFilteredList.map(renderListItem)}</ul>
                )}

                {!isInProgress && allTemplateFilteredListLength === 0 && (
                    <p className={styles.AutoReplyFormPopup_noData}>
                        <Locale stringKey="TEXT__NO_DATA" />
                    </p>
                )}
            </div>

            <div className={styles.AutoReplyFormPopup_bottomLine}>
                <Button
                    className={styles.AutoReplyFormPopup_refreshButton}
                    icon={<FontAwesomeIcon icon={faRotateRight} />}
                    onClick={() => refetch()}
                >
                    <Locale stringKey="AUTO_REPLIES__POPUP__UPDATE_TEMPLATES" />
                </Button>
                <a
                    className={styles.AutoReplyFormPopup_rightLink}
                    href={appRoute.reviewsManagementResponseTemplates.path}
                    rel="noreferrer"
                    target="_blank"
                >
                    <Locale stringKey="AUTO_REPLIES__POPUP__GO_TO_TEMPLATE_SETTINGS" />
                </a>
            </div>
        </DraggableModal>
    );
}
