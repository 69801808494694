import {PublicationContext} from 'centrifuge';
import {camelizeKeys, decamelizeKeys} from 'humps';
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {z as r} from 'zod';

import {useCentrifugeSubscription} from '../../../../../../../provider/centrifuge/centrifuge-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../../../provider/user/user-hook';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../../../../../../service/api/api-const';
import {FetchMethodEnum, fetchNoContent} from '../../../../../../../util/fetch';

const reviewAiSuggestsSchema = r.object({
    error: r.string().nullable(),
    requestId: r.string(),
    stop: r.boolean(),
    text: r.string(),
});

type ReviewAiSuggestType = r.infer<typeof reviewAiSuggestsSchema>;

function requestReviewSuggest(options: {reviewId: number; channelId: string; requestId: string}): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/cp/neuronetwork_replies/create_suggestion/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(decamelizeKeys(options)),
    });
}

function isReviewSuggestAction(data: unknown): data is ReviewAiSuggestType {
    return reviewAiSuggestsSchema.safeParse(data).success;
}

function getCentrifugeReviewSuggestChannel(options: {channelId: string}) {
    return `review_suggestions:suggest_${options.channelId}`;
}

type ReviewAiSuggestHookType = {
    requestSuggest: (reviewId: number) => void;
    isGeneratingSuggest: boolean;
};

export function useReviewAiSuggest(setAnswerText: Dispatch<SetStateAction<string>>): ReviewAiSuggestHookType {
    const [isGeneratingSuggest, setIsGeneratingSuggest] = useState<boolean>(false);
    const {getSubscription} = useCentrifugeSubscription();

    const {user} = useUser();

    const {snackbar} = useSnackbar();
    const channelId = useMemo(() => uuidv4(), []);

    const handleSuggestError = useCallback(() => {
        setIsGeneratingSuggest(false);
        snackbar.error({
            message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
            description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
        });
    }, [snackbar]);

    useEffect(() => {
        const timeoutId = isGeneratingSuggest ? setTimeout(handleSuggestError, 120_000) : null;

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [handleSuggestError, isGeneratingSuggest]);

    useEffect(() => {
        function onActionReceived({data}: PublicationContext) {
            const camelcaseData = camelizeKeys(data);

            if (isReviewSuggestAction(camelcaseData)) {
                if (camelcaseData.stop) {
                    setIsGeneratingSuggest(false);
                }

                setAnswerText((previousState) => previousState + camelcaseData.text);
            }
        }

        const subscription = getSubscription(
            getCentrifugeReviewSuggestChannel({
                channelId,
            })
        );

        subscription?.on('publication', onActionReceived);

        return () => {
            subscription?.removeListener('publication', onActionReceived);
        };
    }, [channelId, setAnswerText, getSubscription, user]);

    const requestSuggest = useCallback(
        (reviewId: number) => {
            setIsGeneratingSuggest(true);
            const requestId = uuidv4();

            requestReviewSuggest({
                reviewId,
                channelId,
                requestId,
            }).catch(handleSuggestError);
        },
        [channelId, handleSuggestError]
    );

    return {
        requestSuggest,
        isGeneratingSuggest,
    };
}
