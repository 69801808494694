import {LocalPackFilterType} from '../../../page/main/local-pack/local-pack-filter/local-pack-filter-type';
import {getLocalPackFilterValues} from '../../../page/main/local-pack/local-pack-helper';
import {fetchAndDeserialize} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {TableSorterType} from '../../../util/table';
import {objectToUrlParameters} from '../../../util/url';

import {localPackLeaderboardResponseSchema, LocalPackLeaderboardType} from './local-pack-laderboard-type';

export function fetchLocalPackLeaderboard(
    options: PaginationDataType & {
        filter: LocalPackFilterType;
        mainFilterKey: string;
    } & TableSorterType
): Promise<LocalPackLeaderboardType> {
    const {page, pageSize, mainFilterKey, sorterField, sorterDirection, filter} = options;

    const url = `/cp/local_pack/leaderboard/?${objectToUrlParameters({
        page,
        page_size: pageSize,
        filter_key: mainFilterKey,
        ...(sorterField && sorterDirection
            ? {
                  sort_field: String(sorterField),
                  sort_direction: sorterDirection,
              }
            : {}),
        ...getLocalPackFilterValues(filter),
    })}`;

    return fetchAndDeserialize(url, localPackLeaderboardResponseSchema, {
        deserializeOptions: {ignorePaths: [/results\.distribution/]},
        fetchOptions: {
            skipCache: true,
        },
    });
}
