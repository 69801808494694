import {Spinner} from '../../../../../layout/spinner/spinner';
import {ReviewNoteType, useNotes} from '../../../../../service/reviews/reviews-notes';

import {NewNote} from './new-note/new-note';
import * as styles from './new-review-notes.scss';

type PropsType = {
    reviewId: number;
    onRefresh?: () => void;
    notesCount: number;
};

export function NewReviewNotes(props: PropsType): JSX.Element | null {
    const {onRefresh, reviewId, notesCount} = props;
    const {data: notesList, isFetching: isInProgress, refetch: refreshNotes} = useNotes(reviewId, notesCount > 0);

    async function refresh() {
        if (onRefresh) {
            await onRefresh();
        }

        await refreshNotes();
    }

    return (
        <div className={styles.ReviewNotes}>
            <Spinner isShow={isInProgress} position="absolute" />
            {notesList?.results.map((note: ReviewNoteType) => {
                return (
                    <NewNote
                        createdAt={note.createdAt}
                        id={note.id}
                        key={note.id}
                        onNoteEdit={refresh}
                        reviewId={reviewId}
                        text={note.text}
                        updatedAt={note.updatedAt}
                        user={note.user}
                    />
                );
            })}
        </div>
    );
}
