import {getIsoYyyyMmDdString} from '../../../util/date';

import {
    LocalPackFilterQueriesType,
    LocalPackFilterType,
    LocalPackFilterUrlQueryNameEnum,
} from './local-pack-filter/local-pack-filter-type';

export function getLocalPackFilterValues(filter: LocalPackFilterType): LocalPackFilterQueriesType {
    return {
        [LocalPackFilterUrlQueryNameEnum.startDate]: filter.timeRange[0]
            ? getIsoYyyyMmDdString(filter?.timeRange[0]?.toDate())
            : '',
        [LocalPackFilterUrlQueryNameEnum.endDate]: filter.timeRange[1]
            ? getIsoYyyyMmDdString(filter?.timeRange[1]?.toDate())
            : '',
        [LocalPackFilterUrlQueryNameEnum.searchQueries]: filter.searchQueries.join(','),
    };
}
