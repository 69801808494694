import {faLock} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {appRoute} from '../../../../../app-route';
import {Page} from '../../../../../layout/page/page';
import {Locale} from '../../../../../provider/locale/localization';

import * as styles from './access-denied-page.scss';

export function AccessDeniedPage(): JSX.Element {
    return (
        <Page isTopFilterHidden>
            <div className={styles.access_denied_page}>
                <FontAwesomeIcon className={styles.access_denied_page__lock} icon={faLock} />
                <h3 className={styles.access_denied_page__header}>
                    <Locale stringKey="SOURCE_SETTINGS__ACCESS_DENIED_PAGE__TITLE" />
                </h3>
                <p className={styles.access_denied_page__sub_header}>
                    <Locale stringKey="SOURCE_SETTINGS__ACCESS_DENIED_PAGE__SUB_TITLE" />
                </p>
                <Button href={appRoute.root.path} type="primary">
                    <Locale stringKey="PAGE__ERROR__LINK_TO_HOME" />
                </Button>
            </div>
        </Page>
    );
}
