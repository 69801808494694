import {faCogs, faMapPin} from '@fortawesome/pro-solid-svg-icons';
import {useContext} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {googleProcessingLocationsLoadingStatuses} from '../../../../../../../service/google/processing/google-processing-const';
import {GoogleProcessingStatusEnum} from '../../../../../../../service/google/processing/google-processing-type';
import {StepHeader} from '../../../common/step-header/step-header';
import {GoogleAccountSyncContext} from '../../../google-account-sync-context';

import {Progressbar} from './progressbar/progressbar';

type PropsType = {
    completed: number;
    total?: number;
    noActionsLeft?: boolean;
};

export function ProcessingStepHeader(props: PropsType): JSX.Element {
    const {completed, total, noActionsLeft} = props;

    const {status} = useContext(GoogleAccountSyncContext);

    if (status === GoogleProcessingStatusEnum.ProcessingFinished) {
        return (
            <StepHeader
                alertIcon={faMapPin}
                alertMessage={
                    noActionsLeft
                        ? 'GOOGLE_SYNC__PROCESSING_STEP__ALERT__NO_ACTIONS'
                        : 'GOOGLE_SYNC__PROCESSING_STEP__ALERT__FINISHED'
                }
                title="GOOGLE_SYNC__PROCESSING_STEP__TITLE__FINISHED"
            />
        );
    }

    return (
        <>
            <StepHeader
                alertIcon={faCogs}
                alertMessage="GOOGLE_SYNC__PROCESSING_STEP__ALERT__IN_PROGRESS"
                title="GOOGLE_SYNC__PROCESSING_STEP__TITLE__IN_PROGRESS"
            />

            <Progressbar
                description={
                    status && googleProcessingLocationsLoadingStatuses.includes(status) ? (
                        <span>
                            <Text stringKey="GOOGLE_SYNC__PROCESSING_STEP__PROGRESSBAR__STEP1" /> - {completed}
                        </span>
                    ) : (
                        <Text
                            stringKey="GOOGLE_SYNC__PROCESSING_STEP__PROGRESSBAR__STEP2"
                            valueMap={{
                                completed: <Text bolder>{completed}</Text>,
                                total,
                            }}
                        />
                    )
                }
                percent={(completed / (total || 1)) * 100}
                // eslint-disable-next-line no-undefined
                status={status === GoogleProcessingStatusEnum.LocationsLoadingFailed ? 'exception' : undefined}
            />
        </>
    );
}
