import {NotificationTextReviewEnum} from '../../../../service/notifications-settings/notifications-settings-type';
import {RatingType} from '../../../../service/reviews/reviews-rating';

export enum UsedeskFieldNamesEnum {
    secretKey = 'secretKey',
    applicationId = 'applicationId',
    chatId = 'chatId',
    webhook = 'webhook',
    catalog = 'catalog',
    ratings = 'ratings',
    type = 'type',
}

export type UsedeskFieldsType = {
    [UsedeskFieldNamesEnum.secretKey]: string;
    [UsedeskFieldNamesEnum.applicationId]: string;
    [UsedeskFieldNamesEnum.chatId]: string;
    [UsedeskFieldNamesEnum.webhook]: string;
    [UsedeskFieldNamesEnum.catalog]: Array<number>;
    [UsedeskFieldNamesEnum.ratings]: Array<RatingType>;
    [UsedeskFieldNamesEnum.type]: NotificationTextReviewEnum;
};

export enum UsedeskSettingsFormOptionsEnum {
    general = 'general',
    notifications = 'notifications',
}
