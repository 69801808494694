import {getRandomString} from '../../../../../../util/string';

import {ProtocolEnum} from './url-input/url-input-type';
import {UrlInputStringType} from './websites-form-item-type';

export const DEFAULT_URL_INPUT_STRING_VALUE: Array<UrlInputStringType> = [
    {value: '', id: getRandomString(), protocol: ProtocolEnum.HTTPS},
];

export const WEBSITE_PROTOCOL = 'https://';
