import {Button, Modal} from 'antd';
import {useCallback, useState} from 'react';

import {ErrorData} from '../../../../../../../layout/error-data/error-data';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CountryV2Type, IdNameType} from '../../../../../../../service/api/api-type';
import {LoadComponent} from '../../../../../../../util/load-component';

import type {InputMap as InputMapType} from './input-map/input-map';
import {defaultLatLng, mapModalWidth} from './map-modal-const';
import * as styles from './map-modal.scss';

type PropsType = {
    country: CountryV2Type | null;
    region: IdNameType | null;
    latLng: [number, number] | null;
    setLatLng: (latLng: [number, number]) => void;
};

export function MapModal(props: PropsType): JSX.Element {
    const {country, region, latLng = defaultLatLng, setLatLng}: PropsType = props;

    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [originalLatLng, setOriginalLatLng] = useState<[number, number]>(defaultLatLng);

    function handleCancelMapModal() {
        setLatLng(originalLatLng);
        setIsFullScreen(false);
    }

    const renderMap = useCallback(async () => {
        const {InputMap}: {InputMap: typeof InputMapType} = await import(
            /* webpackChunkName: 'async-input-map' */ './input-map/input-map'
        );

        if (!latLng) {
            return <span />;
        }

        return (
            <InputMap
                isFullScreen={isFullScreen}
                latLng={latLng}
                setIsFullScreen={(isFullScreenMap: boolean) => {
                    setOriginalLatLng(latLng);
                    setIsFullScreen(isFullScreenMap);
                }}
                setLatLng={setLatLng}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullScreen, latLng]);

    const renderMapInModal = useCallback(async (): Promise<JSX.Element> => {
        const {InputMap}: {InputMap: typeof InputMapType} = await import(
            /* webpackChunkName: 'async-input-map' */ './input-map/input-map'
        );

        if (!latLng) {
            return <span />;
        }

        return (
            <InputMap
                className={styles.company_info__fullscreen_map_input}
                isFullScreen={isFullScreen}
                latLng={latLng}
                setIsFullScreen={setIsFullScreen}
                setLatLng={setLatLng}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullScreen, latLng]);

    return (
        <div className={styles.company_info__map_wrapper}>
            {(!country || !region || !latLng) && (
                <p className={styles.company_info__map_text_placeholder}>
                    <Locale stringKey="COMPANY_FORM__ADDRESS__MAP__PLACHOLDER" />
                </p>
            )}

            {country && region && latLng && (
                <>
                    <LoadComponent
                        error={<ErrorData langKey="ERROR__CAN_NOT_LOAD_THE_COMPONENT" />}
                        spinner={<Spinner />}
                    >
                        {renderMap}
                    </LoadComponent>

                    <Modal
                        className={styles.company_info__modal_map}
                        closable={false}
                        footer={[
                            <Button key="cancel" onClick={handleCancelMapModal}>
                                <Locale stringKey="POPUP__BUTTON__CANCEL" />
                            </Button>,
                            <Button key="ok" onClick={() => setIsFullScreen(false)} type="primary">
                                <Locale stringKey="TEXT__APPLY" />
                            </Button>,
                        ]}
                        onCancel={handleCancelMapModal}
                        open={isFullScreen}
                        title={<Locale stringKey="COMPANY_FORM__ADDRESS__MAP__MODAL_TITLE" />}
                        width={mapModalWidth}
                    >
                        <LoadComponent
                            error={<ErrorData langKey="ERROR__CAN_NOT_LOAD_THE_COMPONENT" />}
                            spinner={<Spinner />}
                        >
                            {renderMapInModal}
                        </LoadComponent>
                    </Modal>
                </>
            )}
        </div>
    );
}
