import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useContext, useState} from 'react';

import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {enUs} from '../../../../provider/locale/translation/en-us/data';
import {MainFilterContext} from '../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../provider/main-filter/main-filter-type';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {ExportReportOptionsType, fetchReviewsGetReport} from '../../../../service/reviews/reviews-report';
import {ReviewsDownloadReportPopup} from '../reviews-download-report-popup/reviews-download-report-popup';
import {getReportDataList} from '../reviews-download-report-popup/reviews-download-report-popup-const';
import {ReviewsStateType} from '../reviews-state/reviews-state-type';

type PropsType = {
    reviewsState: ReviewsStateType;
    hasReviews: boolean;
};

export function RequestReport(props: PropsType): JSX.Element {
    const {reviewsState, hasReviews} = props;

    const {domainName} = useDomainConfig();
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const [isDownloadReportPopupOpen, setIsDownloadReportPopupOpen] = useState<boolean>(false);

    async function handleRequestForReport(requestReport: ExportReportOptionsType) {
        try {
            const {mode, customReportName, withReviewsSummary, withTagsStat, version} = requestReport;

            await fetchReviewsGetReport(
                {mode, customReportName, withReviewsSummary, withTagsStat, version},
                reviewsState,
                mainFilterKey
            );

            snackbar.success({
                key: 'sent-successfully',
                message: (
                    <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__THE_APPLICATION_HAS_BEEN_ACCEPTED__HEADER" />
                ),
                description: (
                    <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__THE_APPLICATION_HAS_BEEN_ACCEPTED__TEXT" />
                ),
            });

            const reportHeader = getReportDataList(domainName).find((item) => item.mode === mode)?.header;

            if (reportHeader) {
                track(AnalyticsTarget.ReviewsManagement.Reviews.ReportDownload, enUs[reportHeader]);
            }
        } catch {
            snackbar.error({
                message: <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__THE_APPLICATION_ERROR_SENT" />,
                description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
            });
        }
    }

    return (
        <>
            <Button
                icon={<FontAwesomeIcon icon={faDownload} />}
                onClick={() => setIsDownloadReportPopupOpen(true)}
                title={getLocalizedString('REVIEWS__DOWNLOAD_THE_REPORT')}
            />
            <ReviewsDownloadReportPopup
                hasReviews={hasReviews}
                isOpen={isDownloadReportPopupOpen}
                onClose={() => setIsDownloadReportPopupOpen(false)}
                requestForReport={handleRequestForReport}
            />
        </>
    );
}
