import {useCallback} from 'react';

import {AddButton} from '../../../../layout/add-button/add-button';
import {Locale} from '../../../../provider/locale/locale';
import {classNames} from '../../../../util/css';
import {WorkDayTimeRangeType} from '../workdays-form-item-type';

import {BreakTimeItem} from './break-time-item/break-time-item';
import {defaultWorkDayTimeRange} from './break-time-list-const';
import * as styles from './break-time-list.scss';

type PropsType = {
    className?: string;
    breakTimeList: Array<WorkDayTimeRangeType>;
    setBreakTimeList: (breakTimeList: Array<WorkDayTimeRangeType>) => void;
    disabled?: boolean;
};

export function BreakTimeList(props: PropsType): JSX.Element {
    const {className, breakTimeList, setBreakTimeList, disabled} = props;

    const fullClassName = classNames(styles.BreakTimeList, className);
    const isBreakTimeListEmpty = breakTimeList.length === 0;

    const handleAddBreakTime = useCallback(() => {
        setBreakTimeList([...breakTimeList, {...defaultWorkDayTimeRange}]);
    }, [breakTimeList, setBreakTimeList]);

    const updateBreakTime = useCallback(
        (oldBreakTime: WorkDayTimeRangeType, newBreakTime: WorkDayTimeRangeType) => {
            const newBreakTimeList = [...breakTimeList];

            newBreakTimeList[breakTimeList.indexOf(oldBreakTime)] = newBreakTime;

            setBreakTimeList(newBreakTimeList);
        },
        [breakTimeList, setBreakTimeList]
    );

    const deleteBreakTime = useCallback(
        (breakTime: WorkDayTimeRangeType) => {
            const newBreakTimeList = [...breakTimeList];

            newBreakTimeList.splice(newBreakTimeList.indexOf(breakTime), 1);

            setBreakTimeList(newBreakTimeList);
        },
        [breakTimeList, setBreakTimeList]
    );

    return (
        <div className={fullClassName}>
            {!isBreakTimeListEmpty && (
                <p className={styles.BreakTimeList_header}>
                    <Locale stringKey="ADDITIONAL_INFO__BREAKS" />
                </p>
            )}

            {breakTimeList.map((breakTime: WorkDayTimeRangeType, index: number): JSX.Element => {
                return (
                    <BreakTimeItem
                        breakTime={breakTime}
                        deleteBreakTime={deleteBreakTime}
                        key={`${String(index)}-${JSON.stringify(breakTime)}`}
                        prevBreakTime={breakTimeList[index - 1] || null}
                        updateBreakTime={updateBreakTime}
                    />
                );
            })}

            <AddButton
                className={styles.BreakTimeList_addBreak}
                isDisabled={disabled}
                key={String(isBreakTimeListEmpty)}
                onClick={handleAddBreakTime}
                type="link"
            >
                <Locale stringKey="ADDITIONAL_INFO__BUTTON__ADD_BREAK" />
            </AddButton>
        </div>
    );
}
