import {ItemType} from 'antd/es/menu/interface';
import {omit} from 'lodash';
import {ReactNode} from 'react';
import {Route, RouteProps} from 'react-router';
import {Redirect} from 'react-router-dom';

import {appRoute} from '../../../../app-route';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../provider/locale/localization';
import {classNames} from '../../../../util/css';
import * as styles from '../../../navigation/navigation.scss';
import {navigationDataLangKey} from '../../../navigation/navigation-type';
import {BetaSup} from '../../../sup/beta-sup';
import {NewSup} from '../../../sup/new-sup';
import {CustomNavigationType, CustomRouteItemType, NavigationItemConfigType} from '../routing-type';

export function getAvailableItems(items: Array<NavigationItemConfigType>): Array<NavigationItemConfigType> {
    return items.filter((item) => {
        if (item.children) {
            // eslint-disable-next-line no-param-reassign
            item.children = getAvailableItems(item.children);
        }

        return !Reflect.has(item, 'isAvailable') || item.isAvailable;
    });
}

export function getRenderRoutes(routeItems: Array<RouteProps & CustomRouteItemType>): Array<ReactNode> {
    return routeItems.map((route) => {
        const {exact, component, path, deniedPath, redirectPath, routes, search} = route;

        return [
            redirectPath &&
                deniedPath &&
                deniedPath.length > 0 &&
                deniedPath.map((navigateToPath) => {
                    return (
                        <Route exact key={navigateToPath} path={navigateToPath}>
                            <Redirect to={search ? {pathname: redirectPath, search} : redirectPath} />
                        </Route>
                    );
                }),
            path && component && <Route component={component} exact={exact} key={path as string} path={path} />,
            routes && routes.length > 0 && getRenderRoutes(routes),
        ];
    });
}

function getBasicItem(props: CustomNavigationType): ItemType {
    const {label, labelLangKey, showBeta, showNew, isActivated, icon, children, allowedSearchParameters, path} = props;
    const fullLabel =
        label ??
        (labelLangKey ? (
            <>
                <Locale stringKey={labelLangKey} /> {showBeta && <BetaSup />}
                {showNew && <NewSup />}
            </>
        ) : (
            ''
        ));

    return {
        ...(children && {children}),
        key: path || '',
        icon,
        className: classNames({
            [styles.navigation__sub_menu]: Boolean(children),
            [styles.navigation__need_activation]: isActivated === false,
        }),
        label: children ? (
            fullLabel
        ) : (
            <NavigationLink allowedSearchParameters={allowedSearchParameters} to={path || appRoute.root.path}>
                {fullLabel}
            </NavigationLink>
        ),
        [navigationDataLangKey]: labelLangKey,
    };
}

export function getPreparedMenuItems(items: Array<NavigationItemConfigType>): Array<NavigationItemConfigType> {
    return items
        .map((item) => {
            if (!item || item.isOnlyRoute) {
                return null;
            }

            if (item.children) {
                // eslint-disable-next-line no-param-reassign
                item.children = getPreparedMenuItems(item.children);
            }

            return 'isCustom' in item && item.isCustom ? omit(item, ['isCustom', 'isAvailable']) : getBasicItem(item);
        })
        .filter(Boolean);
}

export function getPreparedRoutes(routes: Array<NavigationItemConfigType>): Array<RouteProps & CustomRouteItemType> {
    return (
        // todo: improve transform function
        routes
            .map((route) => {
                return {
                    ...((route.path || route.key) && {path: route.path || route.key}),
                    ...(route.component && {component: route.component}),
                    ...('exact' in route && {exact: route.exact}),
                    ...('search' in route && {search: route.search}),
                    ...(route.deniedPath && {deniedPath: route.deniedPath}),
                    ...(route.redirectPath && {redirectPath: route.redirectPath}),
                    ...(route.children && {routes: getPreparedRoutes(route.children)}),
                } as RouteProps & CustomRouteItemType;
            })
            .filter((route) => Object.keys(route).length)
    );
}
