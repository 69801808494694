import {z as r} from 'zod';

import {cursorPaginationResponseSchemaFactory} from '../api/api-type';

export type GetAddressArgumentType = {
    companyIdList?: Array<number>;
    // brandIdList?: Array<number>,
};

export const addressSchema = r.object({
    city: r.string().nullable(),
    companyId: r.number().nullable().optional(),
    country: r.string().nullable(),
    countryCode: r.string().nullable(),
    housenumber: r.string().nullable(),
    lat: r.number().nullable(),
    lon: r.number().nullable(),
    postcode: r.string().nullable(),
    region: r.string().nullable(),
    street: r.string().nullable(),
    rawAddress: r.string().nullable(),
});

export const addressResponseSchema = cursorPaginationResponseSchemaFactory(addressSchema);

export type AddressType = r.infer<typeof addressSchema>;
