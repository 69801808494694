import {deserializeV2, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    companySchema,
    CompanyServerType,
    CompanyType,
    lastCompanySchema,
    LastCompanyType,
    relatedCompanyCatalogCategoriesSchema,
    RelatedCompanyCatalogCategoryType,
} from './company-type';

export async function getCompany(id: number): Promise<CompanyType> {
    const url = rootApiUrl + `/v2/companies/${id}/`;
    const response = await fetchX<CompanyType>(url);

    return deserializeV2<CompanyType>(url, companySchema, response);
}

export function createCompany(values: CompanyServerType): Promise<CompanyType> {
    return fetchX<CompanyType>(rootApiUrl + '/v2/companies/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize<CompanyServerType>(values)),
    });
}

export function updateCompany(id: number, values: CompanyServerType): Promise<CompanyType> {
    return fetchX<CompanyType>(rootApiUrl + `/v2/companies/${id}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize<CompanyServerType>(values)),
    });
}

export function deleteCompany(id: number): Promise<CompanyType> {
    return fetchX<CompanyType>(rootApiUrl + `/v2/companies/${id}/mark_as_delete/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export async function fetchRelatedCompanyCatalogCategoriesList(
    categoryId: number
): Promise<Array<RelatedCompanyCatalogCategoryType>> {
    const url = `${rootApiUrl}/v2/catalog/rocketdata_category/${categoryId}/catalog_categories/`;
    const response: Array<unknown> = await fetchX(url);

    return deserializeV2<Array<RelatedCompanyCatalogCategoryType>>(
        url,
        relatedCompanyCatalogCategoriesSchema,
        response
    );
}

export async function fetchLastCompany(brandId: number | null): Promise<LastCompanyType> {
    const url = `${rootApiUrl}/v2/companies/brands/${brandId}/last_company/`;
    const response: Record<string, unknown> = await fetchX(url);

    return deserializeV2<LastCompanyType>(url, lastCompanySchema, response);
}
