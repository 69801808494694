import {PostPreviewDetailsType, SourcePostType} from '../../../../../../../../../service/posts/posts-types';

import {FacebookPreviewButtonLink} from './components/facebook-preview-button-link/facebook-preview-button-link';
import {FacebookPreviewFooter} from './components/facebook-preview-footer/facebook-preview-footer';
import {FacebookPreviewHeader} from './components/facebook-preview-header/facebook-preview-header';
import {FacebookPreviewImages} from './components/facebook-preview-images/facebook-preview-images';
import {FacebookPreviewText} from './components/facebook-preview-text/facebook-preview-text';
import {FacebookPreviewVideo} from './components/facebook-preview-video/facebook-preview-video';
import * as styles from './facebook-preview.scss';

type PropsType = {
    postData?: SourcePostType;
    companyInfo?: PostPreviewDetailsType;
};

export function FacebookPreview(props: PropsType): JSX.Element {
    const {postData, companyInfo} = props;

    return (
        <div className={styles.FacebookPreview}>
            <FacebookPreviewHeader companyInfo={companyInfo} />
            <FacebookPreviewText
                small={Boolean(postData?.images?.length || postData?.video)}
                text={postData?.text || ''}
            />
            <FacebookPreviewImages images={postData?.images || []} />
            <FacebookPreviewVideo video={postData?.video || null} />
            <FacebookPreviewButtonLink buttonLink={postData?.buttonLink || ''} text={postData?.text || ''} />
            <FacebookPreviewFooter />
        </div>
    );
}
