import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Button, Switch, TableColumnsType, Tag, Tooltip} from 'antd';
import {ReactNode, useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {TableFilterDropdown} from '../../../../../../layout/table-filter-dropdown/table-filter-dropdown';
import {TableFilterDropdownWithInfiniteScroll} from '../../../../../../layout/table-filter-dropdown/table-filter-dropdown-with-infinite-scroll';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {CompaniesImportType} from '../../../../../../service/companies-import/companies-import-list';
import {useBrandsFilter} from '../../../../../../service/companies-import/companies-import-list-brands-filter';
import {MutateFeedAutoUpdateType} from '../../../../../../service/companies-import/companies-import-list-edit';
import {useFeedStatus} from '../../../../../../service/companies-import/companies-import-statuses';
import {useDebouncedValue} from '../../../../../../service/debounce-hook/debounce-hook';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import {FeedBrandsColumn} from './columns/brands/feed-brands-column';
import {FeedTypeLogo} from './columns/type-logo/feed-type-logo';
import {getBadgeColor, getErrorTooltipTitle} from './feeds-table-helper';
import {FeedsTableStatusEnum} from './feeds-table-type';
import * as styles from './feeds-table.scss';

export const FEEDS_STATUS_TO_TAGS: Record<FeedsTableStatusEnum, ReactNode> = {
    [FeedsTableStatusEnum.NotDefined]: (
        <Tag className={classNames(styles.Status, styles.Status_gray)}>
            <Locale stringKey="FEED__MANAGEMENT__TABLE__STATUS__NOT_DEFINED" />
        </Tag>
    ),
    [FeedsTableStatusEnum.Active]: (
        <Tag className={classNames(styles.Status, styles.Status_blue)}>
            <Locale stringKey="FEED__MANAGEMENT__TABLE__STATUS__ACTIVE" />
        </Tag>
    ),
    [FeedsTableStatusEnum.Loading]: (
        <Tag className={classNames(styles.Status, styles.Status_gray)}>
            <Locale stringKey="FEED__MANAGEMENT__TABLE__STATUS__LOADING" />
        </Tag>
    ),
    [FeedsTableStatusEnum.Error]: (
        <Tag className={classNames(styles.Status, styles.Status_red)}>
            <Locale stringKey="FEED__MANAGEMENT__TABLE__STATUS__ERROR" />
        </Tag>
    ),
    [FeedsTableStatusEnum.NoData]: (
        <Tag className={classNames(styles.Status, styles.Status_red)}>
            <Locale stringKey="FEED__MANAGEMENT__TABLE__STATUS__NO_DATA" />
        </Tag>
    ),
};

const FEED_STATUS_TO_STRING: Record<FeedsTableStatusEnum, LangKeyType> = {
    [FeedsTableStatusEnum.NotDefined]: 'FEED__MANAGEMENT__TABLE__STATUS__NOT_DEFINED',
    [FeedsTableStatusEnum.Active]: 'FEED__MANAGEMENT__TABLE__STATUS__ACTIVE',
    [FeedsTableStatusEnum.Loading]: 'FEED__MANAGEMENT__TABLE__STATUS__LOADING',
    [FeedsTableStatusEnum.Error]: 'FEED__MANAGEMENT__TABLE__STATUS__ERROR',
    [FeedsTableStatusEnum.NoData]: 'FEED__MANAGEMENT__TABLE__STATUS__NO_DATA',
};

export function useFeedsColumns(
    mutate: (parameters: MutateFeedAutoUpdateType) => void,
    setFeedDrawerOpen: (feedId: number | null) => void,
    tableStatuses: Array<string>,
    brandIds: Array<string>
): TableColumnsType<CompaniesImportType> {
    const [brandsFilterInput, setBrandsFilterInput] = useState<string>('');

    const debouncedValue = useDebouncedValue(brandsFilterInput);
    const {data: statuses} = useFeedStatus();
    const {data, fetchNextPage, isFetching} = useBrandsFilter(debouncedValue);

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    const brandFlattenedArray = data?.pages.map((page) => page.results).flatMap((array) => [...array]);

    return [
        {
            title: '',
            dataIndex: 'management',
            width: '76px',
            render: (_value, feed: CompaniesImportType) => {
                return (
                    <Switch
                        checked={feed.periodicUpdate}
                        onChange={() => {
                            mutate({feedId: feed.id, value: !feed.periodicUpdate});
                        }}
                    />
                );
            },
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__FEED_WITH_DATA" />,
            dataIndex: 'link',
            align: 'center',
            width: '248px',
            render: (_value, feed: CompaniesImportType) =>
                feed.link ? (
                    <Tooltip placement="bottom" title={feed.link}>
                        <Button
                            className={styles.FeedsTable_link}
                            onClick={() => {
                                setFeedDrawerOpen(feed.id);
                            }}
                            type="link"
                        >
                            {feed.link}
                        </Button>
                    </Tooltip>
                ) : (
                    '-'
                ),
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__BRANDS" />,
            dataIndex: 'brands',
            align: 'center',
            width: '176px',
            filters: (brandFlattenedArray || []).map((brand) => ({
                text: brand.name,
                value: brand.id,
            })),
            filteredValue: brandIds,
            filterDropdown: (props) => (
                <TableFilterDropdownWithInfiniteScroll
                    {...props} // eslint-disable-line react/jsx-props-no-spreading
                    className={styles.FeedsTable_brandsDropdown}
                    fetchNextPage={fetchNextPage}
                    infiniteLoading={isFetching}
                    inputPlaceholder={getLocalizedString('FEED__MANAGEMENT__TABLE__COLUMN__BRANDS__INPUT_PLACEHOLDER')}
                    inputValue={brandsFilterInput}
                    setInputValue={setBrandsFilterInput}
                />
            ),
            render: (_value, feed: CompaniesImportType) => <FeedBrandsColumn brands={feed.brands} />,
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__TYPE" />,
            dataIndex: 'type',
            align: 'center',
            width: '60px',
            render: (_value, feed) => <FeedTypeLogo feedType={feed.importType} />,
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__UPDATED" />,
            dataIndex: 'updatedAt',
            width: '152px',
            render: (_value, feed) => {
                const updatedAtDate = feed.updateDate ? new Date(feed.updateDate) : null;

                return updatedAtDate && !Number.isNaN(updatedAtDate.getTime()) ? (
                    <Text block>{getFormattedDateTime(updatedAtDate)}</Text>
                ) : (
                    '-'
                );
            },
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__STATUS" />,
            dataIndex: 'status',
            filters: (statuses || []).map((status) => ({
                text: <Locale stringKey={FEED_STATUS_TO_STRING[status]} />,
                value: status,
            })),
            filteredValue: tableStatuses,
            filterDropdown: TableFilterDropdown,
            width: '112px',
            render: (_value, feed) => FEEDS_STATUS_TO_TAGS[feed.cpStatus],
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__ERRORS" />,
            dataIndex: 'errorCount',
            width: '88px',
            render: (_value, feed) => {
                const errorSum =
                    (feed.importResultInfo.internalError || 0) +
                    (feed.importResultInfo.validationError || 0) +
                    (feed.importResultInfo.foreignError || 0) +
                    (feed.importResultInfo.criticalError || 0);

                return (
                    <Tooltip placement="bottom" title={getErrorTooltipTitle(feed.importResultInfo)}>
                        <Badge color={getBadgeColor(feed.importResultInfo)} text={`${errorSum}`} />
                    </Tooltip>
                );
            },
        },
        {
            title: <Locale stringKey="FEED__MANAGEMENT__TABLE__COLUMN__SOURCE" />,
            dataIndex: 'source',
            width: '104px',
            align: 'center',
            render: (_value, feed) =>
                feed.link ? (
                    <a href={feed.link} rel="noreferrer" target="_blank">
                        <FontAwesomeIcon
                            className={styles.FeedsTable_source}
                            icon={faArrowUpRightFromSquare}
                            type="button"
                        />
                    </a>
                ) : (
                    '-'
                ),
        },
    ];
}
