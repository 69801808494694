import {faChevronLeft, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useQueryClient} from '@tanstack/react-query';
import {Button} from 'antd';
import {UIEvent, useEffect, useRef, useState} from 'react';

import {CatalogInfo} from '../../../../component/catalog/catalog-info';
import {PageCardDivider} from '../../../../component/page-card-divider/page-card-divider';
import {YandexStoriesPreview} from '../../../../component/stories/yandex-stories-preview/yandex-stories-preview';
import {NewYandexStoryCard} from '../../../../component/stories/yandex-story-card/new-yandex-story-card';
import {YandexStoryCard} from '../../../../component/stories/yandex-story-card/yandex-story-card';
import {Text} from '../../../../component/text/text';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useYandexPreview, useYandexStories} from '../../../../service/yandex-stories/yandex-stories-hook';
import {YandexStoryTypeEnum} from '../../../../service/yandex-stories/yandex-stories-type';
import {noop} from '../../../../util/function';
import {ProvidersEnum} from '../../../../util/type';

import {YANDEX_STORIES_SCROLL_STEP} from './yandex-stories-helper';
import * as styles from './yandex-stories.scss';

export function YandexStoriesWidget(): JSX.Element {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [showScrollControls, setShowScrollControls] = useState<boolean>(false);
    const [leftScrollDisabled, setLeftScrollDisabled] = useState<boolean>(true);
    const [rightScrollDisabled, setRightScrollDisabled] = useState<boolean>(false);

    const {data, isLoading, hasNextPage, fetchNextPage} = useYandexStories();
    const queryClient = useQueryClient();
    const storiesData = data?.pages.flatMap((element) => element.results.map((element_) => element_));

    const {activeStoryId, nextStoryId, previousStoryId, onChangeStoryId} = useYandexPreview(
        storiesData || [],
        hasNextPage ? fetchNextPage : noop
    );

    function onScrollLeft() {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= YANDEX_STORIES_SCROLL_STEP;
        }
    }

    function onScrollRight() {
        if (containerRef.current) {
            containerRef.current.scrollLeft += YANDEX_STORIES_SCROLL_STEP;
        }
    }

    const isNeedLoadNextPageRef = useRef<boolean>(false);

    async function onContainerScroll(event: UIEvent) {
        if (event.target instanceof HTMLElement) {
            const {scrollLeft, scrollWidth, clientWidth} = event.target;

            if (!isNeedLoadNextPageRef.current) {
                isNeedLoadNextPageRef.current = scrollWidth - clientWidth - scrollLeft < YANDEX_STORIES_SCROLL_STEP;

                if (isNeedLoadNextPageRef.current && hasNextPage) {
                    await fetchNextPage();
                    isNeedLoadNextPageRef.current = false;
                }
            }

            setLeftScrollDisabled(scrollLeft === 0);
            setRightScrollDisabled(scrollLeft + clientWidth === scrollWidth);
        }
    }

    function handleRevalidation() {
        queryClient.invalidateQueries(['yandex-stories']);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (
            containerRef.current &&
            containerRef.current.scrollWidth > containerRef.current.getBoundingClientRect().width
        ) {
            setShowScrollControls(true);
            setLeftScrollDisabled(containerRef.current?.scrollLeft === 0);
            setRightScrollDisabled(
                containerRef.current?.scrollLeft + containerRef.current?.clientWidth ===
                    containerRef.current?.scrollWidth
            );
        } else {
            setShowScrollControls(false);
        }
    });

    return (
        <div>
            <PageCardDivider />

            <div className={styles.YandexStories_headerContainer}>
                <div className={styles.YandexStories_header}>
                    <CatalogInfo
                        hideName
                        iconClassName={styles.YandexStories_headerLogo}
                        provider={ProvidersEnum.yandex}
                    />
                    <Text block stringKey="DASHBOARD_YANDEX_STORIES__TITLE" />
                </div>

                {showScrollControls && (
                    <div className={styles.YandexStories_scroller}>
                        <Button disabled={leftScrollDisabled} onClick={onScrollLeft} size="small" type="text">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <Button disabled={rightScrollDisabled} onClick={onScrollRight} size="small" type="text">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>
                )}
            </div>
            {data && (
                <div className={styles.YandexStories_cardsWrapper} onScroll={onContainerScroll} ref={containerRef}>
                    <NewYandexStoryCard onCreateSuccess={handleRevalidation} type={YandexStoryTypeEnum.Story} />

                    {storiesData?.map((story) => (
                        <YandexStoryCard
                            data={story}
                            key={story.id}
                            onClick={() => {
                                onChangeStoryId(story.id);
                            }}
                        />
                    ))}
                </div>
            )}

            <YandexStoriesPreview
                activeStoryId={activeStoryId}
                nextStoryId={nextStoryId}
                onChangeStoryId={onChangeStoryId}
                previousStoryId={previousStoryId}
            />

            {isLoading && <Spinner />}
        </div>
    );
}
