import {Color} from 'chart.js';
import {Context} from 'chartjs-plugin-datalabels';
import {Options} from 'chartjs-plugin-datalabels/types/options';

import {defaultTooltipOptions, defaultYAxisScale, gridColor, labelColor} from '../chart-const';

import {ChartJsLineOptionsType} from './line-chart-type';

export const defaultLineChartOptions: ChartJsLineOptionsType = {
    maintainAspectRatio: false,
    scales: {
        x: {
            border: {
                display: false,
            },
            type: 'category',
            grid: {
                color: gridColor,
                drawOnChartArea: true,
                drawTicks: false,
            },
            ticks: {
                color: labelColor,
                padding: 10,
            },
        },
        y: defaultYAxisScale,
    },
    plugins: {
        legend: {
            display: true,
            align: 'start',
            labels: {
                boxWidth: 16,
                boxHeight: 16,
            },
        },
        tooltip: defaultTooltipOptions,
        datalabels: {
            display: false,
        },
    },
};

export const lineChartDataLabelsOptions: Options = {
    backgroundColor: (context: Context): Color | null => {
        if (typeof context.dataset.backgroundColor === 'string') {
            return context.dataset.backgroundColor;
        }

        return null;
    },
    borderRadius: 4,
    color: 'white',
    font: {
        weight: 'bold',
    },
    formatter: Math.round,
    padding: 4,
};
