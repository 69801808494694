import {Space, SpaceProps, Tag} from 'antd';
import {ReactElement} from 'react';

import {Text} from '../../../component/text/text';
import {Locale} from '../../../provider/locale/localization';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {
    ConfirmationStatusEnum,
    ImportStatusEnum,
    SyncCompaniesStatusEnum,
} from '../../../provider/onboarding/onboarding-hook';
import {classNames} from '../../../util/css';

import {onboardingStepIcon, onboardingStepStatus} from './onboarding-const';
import * as styles from './onboarding.scss';

export function getOnboardingStepStatus(
    status?: ImportStatusEnum | ConfirmationStatusEnum | SyncCompaniesStatusEnum
): ReactElement | null {
    if (!status || status === SyncCompaniesStatusEnum.Default || status === SyncCompaniesStatusEnum.Passed) {
        return null;
    }

    const tagClassName = classNames({
        [styles.Status_icon__needAction]: status === ConfirmationStatusEnum.NeedConfirmation,
        [styles.Status_icon__pending]:
            status === ConfirmationStatusEnum.InProgress || status === ImportStatusEnum.InProgress,
    });

    return (
        <Tag className={tagClassName}>
            <Locale stringKey={onboardingStepStatus[status]} /> {onboardingStepIcon[status]}
        </Tag>
    );
}

function titleClassNames(success: boolean, active?: boolean): string {
    return classNames({
        [styles.Onboarding_successTitle]: success && active !== false,
        [styles.Onboarding_disabledTitle]: active === false,
    });
}

function mobileLayoutForSpace(isMobile: boolean): SpaceProps {
    return {
        align: isMobile ? 'start' : 'center',
        direction: isMobile ? 'vertical' : 'horizontal',
        size: isMobile ? 'small' : 'large',
    };
}

export function getOnboardingTitle(
    isMobile: boolean,
    passed: boolean,
    stringKey: LangKeyType,
    active?: boolean,
    status?: ImportStatusEnum | ConfirmationStatusEnum
): ReactElement {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Space {...mobileLayoutForSpace(isMobile)}>
            <Text bolder className={titleClassNames(Boolean(passed), active)} large stringKey={stringKey} />
            {status && getOnboardingStepStatus(status)}
        </Space>
    );
}
