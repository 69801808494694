import {Card} from 'antd';

import {PostPreviewPropsType} from '../../../content-step-type';

import {PreviewBody} from './components/preview-body/preview-body';
import {PreviewHeader} from './components/preview-header/preview-header';
import * as styles from './yandex-preview.scss';

export function YandexPreview(props: PostPreviewPropsType): JSX.Element | null {
    const {postData, companyInfo} = props;

    if (!postData) {
        return null;
    }

    return (
        <Card bordered={false} className={styles.YandexPreview}>
            <PreviewHeader companyInfo={companyInfo} />
            <PreviewBody post={postData} />
        </Card>
    );
}
