import {Button} from 'antd';
import {useCallback} from 'react';
import {useLocation} from 'react-router';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../app-route';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {useOmnichannelAction} from '../../../service/omnichannel/actions/omnichannel-actions-hook';
import {OmnichannelCommunicationActionDataType} from '../../../service/omnichannel/actions/omnichannel-actions-type';
import {useUrl} from '../../../util/url-hook/url-hook';

import omnichannelNotificationAudio from './audio/omnichannel-notification.wav';

const notificationAudio = new Audio(omnichannelNotificationAudio);

export function CentrifugeOmnichannelNotifications(): null {
    const {snackbar} = useSnackbar();
    const {pathname} = useLocation();
    const {pushUrl} = useUrl();
    const {getLocalizedString} = useLocale();

    const onOmnichannelActionReceived = useCallback(
        (payload: OmnichannelCommunicationActionDataType) => {
            if (!pathname.includes(appRoute.omnichannelChats.path)) {
                notificationAudio.play();
                snackbar.success(
                    <Button
                        onClick={() => pushUrl(generatePath(appRoute.omnichannelChat.path, {chatId: payload.chatId}))}
                        type="link"
                    >
                        {getLocalizedString('OMNICHANNEL__NOTIFICATION__NEW_MESSAGE_RECEIVED')}
                    </Button>
                );
            }
        },
        [getLocalizedString, pathname, pushUrl, snackbar]
    );

    useOmnichannelAction(onOmnichannelActionReceived);

    return null;
}
