import {InputNumber, InputNumberProps} from 'antd';
import {useCallback} from 'react';

import {Form} from '../../../../../../../typings/antd';
import {AttributePropsType, RangeSingleAttributeType} from '../attributes-type';
import {RangePicker} from '../range-picker/range-picker';

export function RangeAttribute({value, onChange}: AttributePropsType<RangeSingleAttributeType>): JSX.Element | null {
    const {displayName: label, values} = value;

    const firstAndOnlyValue = values[0];

    const {status} = Form.Item.useStatus();

    const fromInput = useCallback(
        (className: InputNumberProps['className'], type: InputNumberProps['type']) => {
            if (!firstAndOnlyValue) {
                return null;
            }

            return (
                <InputNumber
                    className={className}
                    onChange={(newValue) =>
                        newValue !== null &&
                        onChange?.({...value, values: [{...firstAndOnlyValue, valueFrom: newValue}]})
                    }
                    status={status === 'error' ? status : undefined} // eslint-disable-line no-undefined
                    type={type}
                    value={values.find((item) => item.valueFrom)?.valueFrom}
                />
            );
        },
        [firstAndOnlyValue, onChange, status, value, values]
    );

    const toInput = useCallback(
        (className: InputNumberProps['className'], type: InputNumberProps['type']) => {
            if (!firstAndOnlyValue) {
                return null;
            }

            return (
                <InputNumber
                    className={className}
                    onChange={(newValue) =>
                        newValue !== null && onChange?.({...value, values: [{...firstAndOnlyValue, valueTo: newValue}]})
                    }
                    status={status === 'error' ? status : undefined} // eslint-disable-line no-undefined
                    type={type}
                    value={values.find((item) => item.valueTo)?.valueTo}
                />
            );
        },
        [firstAndOnlyValue, onChange, status, value, values]
    );

    if (!firstAndOnlyValue) {
        return null;
    }

    return (
        <Form.Item label={label}>
            <RangePicker from={fromInput} to={toInput} />
        </Form.Item>
    );
}
