import {defaultPageSize} from './cursor-pagination-const';
import {
    CursorPaginationHookOptionsType,
    cursorPaginationStorageDataSchema,
    CursorPaginationStorageType,
} from './cursor-pagination-type';

function getCursorPaginationFullStorageKey(): string {
    return 'pagination';
}

export function getPaginationInitialState(
    options: Omit<CursorPaginationHookOptionsType, 'storageId'> & {storageKey: string}
): CursorPaginationStorageType {
    const {shouldSaveState, storageKey, defaultPageSize: optionsPageSize} = options;

    if (shouldSaveState) {
        const storedPaginationState = sessionStorage.getItem(getCursorPaginationFullStorageKey());
        const parseResult = cursorPaginationStorageDataSchema.safeParse(JSON.parse(storedPaginationState || '{}'));

        if (parseResult.success && parseResult.data.storageKey === storageKey) {
            return parseResult.data;
        }
    }

    return {
        pageSize: optionsPageSize || defaultPageSize,
        cursor: '',
        storageKey: '',
    };
}

export function saveNewCursorPaginationState(data: Pick<CursorPaginationStorageType, 'cursor' | 'storageKey'>): void {
    sessionStorage.setItem(getCursorPaginationFullStorageKey(), JSON.stringify(data));
}
