import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {useHistory} from 'react-router';

import {appRoute} from '../../app-route';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeApiError, postAndDeserialize} from '../../util/api-adapter';
import {
    registerErrorSchema,
    RegisterErrorType,
    RegisterFormType,
    RegisterOptionsType,
    registerSchema,
    RegisterType,
} from '../user/user-register';

import {userManagementUrl} from './user-management-const';
import {useUserManagementRegistrationUrlQuery} from './user-management-registration-data';

type UserManagementRegisterOptionsType = RegisterOptionsType & {
    jwtToken: string | null;
};

const url = `${userManagementUrl}/register/`;

function register(
    options: UserManagementRegisterOptionsType,
    shortLocaleName: ShortLocaleNameEnum
): Promise<RegisterType> {
    return postAndDeserialize(url, registerSchema, options, {unauthorized: true, shortLocaleName});
}

export function useUserManagementRegisterMutation(): UseMutationResult<RegisterType, unknown, RegisterFormType> {
    const {jwtToken} = useUserManagementRegistrationUrlQuery();

    const {push} = useHistory();
    const {snackbar} = useSnackbar();
    const {shortLocaleName} = useLocale();

    function mutate(options: RegisterFormType) {
        return register(
            {
                ...options,
                jwtToken,
                firstName: options.firstName.trim(),
                lastName: options.lastName?.trim() ?? '',
                email: options.email.trim(),
                phone: options.phone.trim(),
                password: options.password.trim(),
                language: shortLocaleName,
            },
            shortLocaleName
        );
    }

    async function onSuccess() {
        snackbar.success({
            message: <Locale stringKey="PAGE__REGISTER__NOTIFICATION__SUCCESS__HEADER" />,
            description: <Locale stringKey="PAGE__REGISTER__NOTIFICATION__SUCCESS__CONTENT__NO_CONFIRMATION" />,
        });

        push(appRoute.login.path);
    }

    function onError(error: unknown) {
        const apiError = deserializeApiError<RegisterErrorType>(url, registerErrorSchema, error);

        if (apiError?.firstName?.[0] || apiError?.lastName?.[0] || apiError?.email?.[0] || apiError?.phone?.[0]) {
            return;
        }

        snackbar.error({
            message: <Locale stringKey="PAGE__REGISTER__NOTIFICATION__ERROR__HEADER" />,
            description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
        });
    }

    return useMutation(mutate, {onSuccess, onError});
}
