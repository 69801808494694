import {Rule} from 'antd/lib/form';

import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../service/feature-companies/feature-companies-type';
import {
    PostFormFieldsEnum,
    PostFormType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../util/type';
import {POST_FORM_EMPTY_VALUES} from '../../posts-form-helper';

export const SOURCES_WITH_COMPANY_SELECT = [PostSourceEnum.google, PostSourceEnum.yandex, PostSourceEnum.facebook];

const SOURCES_REQUIRES_COMPANY_SELECTOR = [PostSourceEnum.google, PostSourceEnum.yandex];

export const POST_SOURCE_BY_ID: Partial<Record<ProvidersIdsEnum, PostSourceEnum>> = {
    [ProvidersIdsEnum.google]: PostSourceEnum.google,
    [ProvidersIdsEnum.yandex]: PostSourceEnum.yandex,
    [ProvidersIdsEnum.facebook]: PostSourceEnum.facebook,
    [ProvidersIdsEnum.vkontakte]: PostSourceEnum.vkontakte,
    [ProvidersIdsEnum.instagram]: PostSourceEnum.instagram,
};

export function getFbPageOrCompanySelectedRule(options: {
    enabled: boolean;
    selectedCompaniesCount: number;
    fbPagesCount: number;
    errorMessage: string;
}): Rule {
    const {selectedCompaniesCount, fbPagesCount, enabled, errorMessage} = options;

    return {
        validator: () => {
            if (enabled && (selectedCompaniesCount || fbPagesCount)) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(errorMessage));
        },
    };
}

export function getIsCompanySelectorVisible(sources: Array<PostSourceEnum>): boolean {
    return Boolean(sources.some((source) => SOURCES_WITH_COMPANY_SELECT.includes(source)));
}

export function getIsCompanySelectorRequired(sources: Array<PostSourceEnum>): boolean {
    return SOURCES_REQUIRES_COMPANY_SELECTOR.some((source) => sources.includes(source));
}

export function getIsBrandSelectRequired(
    sources: Array<PostSourceEnum>,
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>
): boolean {
    return Boolean(sources.length > 0 && !companiesSelector.selectedCount && !getIsCompanySelectorRequired(sources));
}

export function getIsVkPagesItemAvailable(sources?: Array<PostSourceEnum>): boolean {
    return sources?.includes(PostSourceEnum.vkontakte) || false;
}

export function getIsFacebookPagesItemAvailable(sources?: Array<PostSourceEnum>): boolean {
    return sources?.includes(PostSourceEnum.facebook) || false;
}

export function getIsInstagramPagesItemAvailable(sources?: Array<PostSourceEnum>): boolean {
    return sources?.includes(PostSourceEnum.instagram) || false;
}

export function clearHiddenPostFormTargetStepFields(
    formInstance: FormInstance<PostFormType>,
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>
): void {
    const sources = formInstance
        .getFieldValue(PostFormFieldsEnum.Posts)
        .map((post) => post[SourcePostFormFieldsEnum.Catalog]);

    if (!getIsCompanySelectorVisible(sources) && companiesSelector.selectedCount) {
        companiesSelector.deselectAll();
    }

    if (!getIsBrandSelectRequired(sources, companiesSelector)) {
        formInstance.setFieldValue(PostFormFieldsEnum.Brands, POST_FORM_EMPTY_VALUES[PostFormFieldsEnum.Brands]);
    }

    if (!getIsVkPagesItemAvailable(sources)) {
        formInstance.setFieldValue(
            PostFormFieldsEnum.VkGroupsIds,
            POST_FORM_EMPTY_VALUES[PostFormFieldsEnum.VkGroupsIds]
        );
    }

    if (!getIsFacebookPagesItemAvailable(sources)) {
        formInstance.setFieldValue(
            PostFormFieldsEnum.FbPagesIds,
            POST_FORM_EMPTY_VALUES[PostFormFieldsEnum.FbPagesIds]
        );
    }

    if (!getIsInstagramPagesItemAvailable(sources)) {
        formInstance.setFieldValue(
            PostFormFieldsEnum.InstagramGroupsIds,
            POST_FORM_EMPTY_VALUES[PostFormFieldsEnum.InstagramGroupsIds]
        );
    }
}
