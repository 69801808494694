import {fetchAndDeserialize, postAndDeserialize, serialize, serializeToURLParameters} from '../../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../../util/fetch';
import {objectToUrlParameters} from '../../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../../api/api-const';
import {RequestOptionsType} from '../../api/api-type';

import {
    googleConfirmationCompaniesSchema,
    GoogleConfirmationCompaniesType,
    googleConfirmationRequestsSchema,
    GoogleConfirmationRequestsType,
    googleRequestCompaniesSchema,
    GoogleRequestCompaniesType,
    GoogleVerificationCompleteBodyType,
    GoogleVerificationOptionBodyType,
    googleVerificationOptionResponseSchema,
    GoogleVerificationOptionResponseType,
    googleVerificationOptionsSchema,
    GoogleVerificationOptionType,
} from './google-confirmation-type';

const googleConfirmationCompaniesUrl = '/cp/confirmation/google';

export function getGoogleConfirmationCompaniesUrl(options: RequestOptionsType): string {
    return `${googleConfirmationCompaniesUrl}/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

export function fetchGoogleConfirmationCompanies(
    options: RequestOptionsType & {status?: string}
): Promise<GoogleConfirmationCompaniesType> {
    const url = getGoogleConfirmationCompaniesUrl(options);

    return fetchAndDeserialize(url, googleConfirmationCompaniesSchema);
}

export function getGoogleConfirmationRequestsUrl(options: RequestOptionsType): string {
    return `${googleConfirmationCompaniesUrl}/requests/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

export function fetchGoogleConfirmationRequests(
    options: RequestOptionsType & {showCompleted?: boolean}
): Promise<GoogleConfirmationRequestsType> {
    return fetchAndDeserialize(getGoogleConfirmationRequestsUrl(options), googleConfirmationRequestsSchema);
}

export function getGoogleRequestCompaniesUrl(requestId: number | null, options: RequestOptionsType): string {
    return `${googleConfirmationCompaniesUrl}/requests/${requestId}/?${objectToUrlParameters(
        serializeToURLParameters(options)
    )}`;
}

export function fetchGoogleRequestCompanies(
    requestId: number | null,
    options: RequestOptionsType
): Promise<GoogleRequestCompaniesType> {
    return fetchAndDeserialize(getGoogleRequestCompaniesUrl(requestId, options), googleRequestCompaniesSchema);
}

export function getGoogleVerificationOptionsUrl(companyId: number | null): string {
    return `${googleConfirmationCompaniesUrl}/${companyId}/verification_options/`;
}

export function fetchGoogleVerificationOptions(companyId: number | null): Promise<Array<GoogleVerificationOptionType>> {
    return fetchAndDeserialize(getGoogleVerificationOptionsUrl(companyId), googleVerificationOptionsSchema);
}

export function postGoogleVerificationOption(
    companyId: number,
    body: GoogleVerificationOptionBodyType
): Promise<GoogleVerificationOptionResponseType> {
    return postAndDeserialize(
        `${googleConfirmationCompaniesUrl}/${companyId}/`,
        googleVerificationOptionResponseSchema,
        body
    );
}

export function postGoogleVerificationComplete(
    confirmationId: number,
    body: GoogleVerificationCompleteBodyType
): Promise<void> {
    return fetchX(`${rootApiUrl}${googleConfirmationCompaniesUrl}/${confirmationId}/complete/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize(body)),
    });
}
