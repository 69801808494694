import {useCallback} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';

import {fetchReviewConcordance, fetchReviewConcordanceSentiment} from './review-concordance-api';
import {
    FetchReviewConcordanceResultType,
    ReviewConcordanceRequestType,
    ReviewConcordanceSentimentRequestType,
    ReviewConcordanceSentimentType,
    UseReviewConcordanceSentimentType,
    UseReviewConcordanceType,
} from './review-concordance-type';

export function useReviewConcordance(): UseReviewConcordanceType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<FetchReviewConcordanceResultType>();

    const fetch = useCallback(
        (options: ReviewConcordanceRequestType, mainFilterKey: string) => {
            const {
                q,
                startDate,
                endDate,
                contextSize,
                page,
                pageSize,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
            } = options;

            if (!q) {
                return;
            }

            setIsInProgress(true);
            setProcessError(null);

            fetchReviewConcordance(
                {
                    q,
                    startDate,
                    endDate,
                    contextSize,
                    page,
                    pageSize,
                    sentiments,
                    sources,
                    tags,
                    phrases,
                    topics,
                    rating,
                    withAnswers,
                },
                mainFilterKey
            )
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {fetch, isInProgress, processError, result, reset};
}

export function useReviewConcordanceSentiment(): UseReviewConcordanceSentimentType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ReviewConcordanceSentimentType>();

    const fetch = useCallback(
        (options: ReviewConcordanceSentimentRequestType, mainFilterKey: string) => {
            const {
                q,
                startDate,
                endDate,
                contextSize,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
            } = options;

            if (!q) {
                return;
            }

            setIsInProgress(true);
            setProcessError(null);

            fetchReviewConcordanceSentiment(
                {
                    q,
                    startDate,
                    endDate,
                    contextSize,
                    sentiments,
                    sources,
                    tags,
                    phrases,
                    topics,
                    rating,
                    withAnswers,
                },
                mainFilterKey
            )
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {fetch, isInProgress, processError, result, reset};
}
