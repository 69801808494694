import {lazy, Suspense, useCallback, useEffect, useState} from 'react';

import {wGisCountryCodeHeaderName} from '../../../../const';
import {Page} from '../../../../layout/page/page';
import {Login} from '../../../../page/auth/login/login';
import {ReactCatchError} from '../../../../page/error/react-catch-error/react-catch-error';
import {BrandFeaturesProvider} from '../../../../provider/brand-features/brand-features-context';
import {CatalogsContextProvider} from '../../../../provider/catalogs/catalogs-context';
import {identifySmartLook} from '../../../../provider/domain-config/init-scripts/init-scripts';
import {MainFilterProvider} from '../../../../provider/main-filter/main-filter';
import {useUser} from '../../../../provider/user/user-hook';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {Analytics} from '../../../analytics/analytics';
import {CentrifugeListenersAuthorized} from '../../../centrifuge-actions/centrifuge-listeners-authorized';
import {ErrorBoundary} from '../../../error-boundary/error-boundary';
import {SentryTags} from '../../../sentry-tags/sentry-tags';

const RoutingNoAuthorized = lazy(() => import(/* webpackChunkName: 'no-authorized' */ './2gis-routing-no-authorized'));
const RoutingAuthorized = lazy(() => import(/* webpackChunkName: 'authorized' */ '../routing-authorized'));

export function TwoGisRouting(): JSX.Element {
    const {user, isInGettingUser} = useUser();

    const {queries} = useUrl<{ucc: string}>();
    const {ucc} = queries;
    const [newVersionDeployed, setNewVersionDeployed] = useState<boolean>(false);

    const onNewVersionDeployed = useCallback(() => {
        setNewVersionDeployed(true);
    }, []);

    useEffect(() => {
        if (ucc) {
            sessionStorage.setItem(wGisCountryCodeHeaderName, ucc);
        }
    }, [ucc]);

    useEffect(() => {
        if (user) {
            identifySmartLook(user.id);
        }
    }, [user]);

    const fallback = <Page.Loading titleLangKey="LOADING_THREE_POINTS" />;

    if (isInGettingUser) {
        return fallback;
    }

    if (user) {
        return (
            <Suspense fallback={fallback}>
                <SentryTags />
                <CentrifugeListenersAuthorized onDeployActionReceived={onNewVersionDeployed} />
                <ErrorBoundary appHasNewVersion={newVersionDeployed} errorFallBack={<ReactCatchError />}>
                    <MainFilterProvider>
                        <BrandFeaturesProvider>
                            <CatalogsContextProvider>
                                <RoutingAuthorized />
                            </CatalogsContextProvider>
                        </BrandFeaturesProvider>
                    </MainFilterProvider>
                </ErrorBoundary>
                <Analytics />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={fallback}>
            <CentrifugeListenersAuthorized onDeployActionReceived={onNewVersionDeployed} />
            <ErrorBoundary appHasNewVersion={newVersionDeployed} errorFallBack={<Login />}>
                <RoutingNoAuthorized />
            </ErrorBoundary>
        </Suspense>
    );
}
