import {Descriptions, ModalFuncProps, Space} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';
import {bulkBindingGoogleUrl} from '../../../../../../service/google/google-api';
import {
    GoogleProcessingStartErrorResponseType,
    googleProcessingStartErrorSchema,
} from '../../../../../../service/google/processing/google-processing-type';
import {deserializeV2} from '../../../../../../util/api-adapter';
import {isRecord} from '../../../../../../util/object';

import * as styles from './companies-step.scss';

export function getConfirmationModalConfig(companiesLength: number, onOk: () => void): ModalFuncProps {
    return {
        title: <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__TITLE" />,
        content: (
            <Space direction="vertical" size={12}>
                <Text stringKey="GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__CONTENT" />

                <Descriptions bordered className={styles.ConfirmationModal_descriptions} size="small">
                    <Descriptions.Item
                        label={<Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__COMPANIES" />}
                    >
                        {companiesLength}
                    </Descriptions.Item>
                </Descriptions>

                <Text secondary small stringKey="GOOGLE_SYNC__COMPANIES_STEP__CONFIRM_MODAL__NOTE" />
            </Space>
        ),
        okText: <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__START_SYNC" />,
        cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
        okButtonProps: {type: 'primary', ghost: true},
        onOk,
        width: 480,
    };
}

export function getGoogleProcessingStartErrors(error: unknown): Array<string> {
    if (!isRecord(error) || !isRecord(error.jsonData)) {
        return [];
    }

    const errorData: GoogleProcessingStartErrorResponseType = deserializeV2(
        bulkBindingGoogleUrl,
        googleProcessingStartErrorSchema,
        error.jsonData
    );

    return [errorData.companiesIds, errorData.tokenId, errorData.nonFieldErrors].flat().filter(Boolean);
}
