import dayjs from 'dayjs';

import {
    FetchPostType,
    PostFormFieldsEnum,
    PostFormType,
    PostStatusEnum,
    SourcePostFormFieldsEnum,
} from '../../../../service/posts/posts-types';

import {PostFormStepType, PostStepEnum} from './post-form-type';

export const POSTS_FORM_POST_TARGET_STEP: PostFormStepType = {
    titleKey: 'POSTS_FORM__TARGET_STEP',
    stepName: PostStepEnum.Target,
};

export const POSTS_FORM_COMMON_CONTENT_STEP: PostFormStepType = {
    titleKey: 'POSTS_FORM__COMMON_CONTENT_STEP',
    stepName: PostStepEnum.CommonContent,
};

export const POSTS_FORM_ADAPTIVE_CONTENT_STEP: PostFormStepType = {
    titleKey: 'POSTS_FORM__ADAPTIVE_CONTENT_STEP',
    stepName: PostStepEnum.AdaptiveContent,
};

export const POSTS_FORM_SEND_PARAMETERS_STEP: PostFormStepType = {
    titleKey: 'POSTS_FORM__POST_CONFIRMATION_STEP',
    stepName: PostStepEnum.SendParameters,
};

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DEFAULT_MAX_IMAGE_SIZE_MB = 8;
export const MAX_VIDEO_SIZE_MB = 400;
export const BYTES_IN_MB = 1_048_576; // 1024 ** 2;

export const POST_FORM_EMPTY_VALUES: Omit<PostFormType, PostFormFieldsEnum.SelectorId> = {
    [PostFormFieldsEnum.Timezone]: null,
    [PostFormFieldsEnum.CreateDateTime]: null,
    [PostFormFieldsEnum.DeleteDateTime]: null,
    [PostFormFieldsEnum.Status]: PostStatusEnum.Draft,
    [PostFormFieldsEnum.Name]: '',
    [PostFormFieldsEnum.Posts]: [],
    [PostFormFieldsEnum.VkGroupsIds]: [],
    [PostFormFieldsEnum.FbPagesIds]: [],
    [PostFormFieldsEnum.InstagramGroupsIds]: [],
    [PostFormFieldsEnum.Brands]: [],
    [PostFormFieldsEnum.AutoRefreshFrequency]: null,
    [PostFormFieldsEnum.AutoRefreshCount]: null,
};

export function getPostFormInitialValues(fetchedPost: FetchPostType): Omit<PostFormType, PostFormFieldsEnum.Id> {
    return {
        [PostFormFieldsEnum.Timezone]: fetchedPost[PostFormFieldsEnum.Timezone],
        [PostFormFieldsEnum.Brands]: fetchedPost[PostFormFieldsEnum.Brands] || [],
        [PostFormFieldsEnum.Status]: fetchedPost[PostFormFieldsEnum.Status],
        [PostFormFieldsEnum.Name]: fetchedPost[PostFormFieldsEnum.Name],
        [PostFormFieldsEnum.VkGroupsIds]: fetchedPost[PostFormFieldsEnum.VkGroupsIds] || [],
        [PostFormFieldsEnum.FbPagesIds]: fetchedPost[PostFormFieldsEnum.FbPagesIds] || [],
        [PostFormFieldsEnum.InstagramGroupsIds]: fetchedPost[PostFormFieldsEnum.InstagramGroupsIds] || [],
        [PostFormFieldsEnum.SelectorId]: fetchedPost[PostFormFieldsEnum.SelectorId],
        [PostFormFieldsEnum.CreateDateTime]: fetchedPost[PostFormFieldsEnum.CreateDateTime]
            ? dayjs(fetchedPost[PostFormFieldsEnum.CreateDateTime])
            : null,
        [PostFormFieldsEnum.DeleteDateTime]: fetchedPost[PostFormFieldsEnum.DeleteDateTime]
            ? dayjs(fetchedPost[PostFormFieldsEnum.DeleteDateTime])
            : null,
        [PostFormFieldsEnum.Posts]: fetchedPost[PostFormFieldsEnum.Posts].map((fetchedSourcePost) => {
            const sourcePostEvent = fetchedSourcePost[SourcePostFormFieldsEnum.Event];

            return {
                [SourcePostFormFieldsEnum.Text]: fetchedSourcePost[SourcePostFormFieldsEnum.Text],
                [SourcePostFormFieldsEnum.TopicType]: fetchedSourcePost[SourcePostFormFieldsEnum.TopicType],
                [SourcePostFormFieldsEnum.ButtonLink]: fetchedSourcePost[SourcePostFormFieldsEnum.ButtonLink],
                [SourcePostFormFieldsEnum.ButtonType]: fetchedSourcePost[SourcePostFormFieldsEnum.ButtonType],
                [SourcePostFormFieldsEnum.Catalog]: fetchedSourcePost[SourcePostFormFieldsEnum.Catalog],
                [SourcePostFormFieldsEnum.Images]: fetchedSourcePost[SourcePostFormFieldsEnum.Images],
                [SourcePostFormFieldsEnum.Offer]: fetchedSourcePost[SourcePostFormFieldsEnum.Offer],
                [SourcePostFormFieldsEnum.Video]: fetchedSourcePost[SourcePostFormFieldsEnum.Video],
                ...(sourcePostEvent
                    ? {
                          [SourcePostFormFieldsEnum.Event]: {
                              title: sourcePostEvent.title,
                              start: dayjs(sourcePostEvent.start).utc(false),
                              end: dayjs(sourcePostEvent.end).utc(false),
                          },
                      }
                    : {}),
            };
        }),
        [PostFormFieldsEnum.AutoRefreshCount]: fetchedPost[PostFormFieldsEnum.AutoRefreshCount],
        [PostFormFieldsEnum.AutoRefreshFrequency]: fetchedPost[PostFormFieldsEnum.AutoRefreshFrequency],
    };
}
