import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';
import {ColumnsType} from 'antd/lib/table';

import {Text} from '../../../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../../../provider/locale/locale';
import {formatAddress} from '../../../../../../../../../../service/address/address-helper';
import {AccountSelectorCompanyType} from '../../manage-account-form-type';

import * as styles from './account-tab.scss';

export function getCompaniesSelectorAccountColumns<
    CompanyType extends AccountSelectorCompanyType = AccountSelectorCompanyType
>(): ColumnsType<CompanyType> {
    return [
        {
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANY__TABLE__COLUMN__NAME" />,
            width: '240px',
            key: 'name',
            render: (_value, item: CompanyType) => {
                return (
                    <Text className={styles.AccountTab_text}>
                        {item.name}
                        {'account' in item && (
                            <Popover
                                content={
                                    <div className={styles.AccountTab_popover}>
                                        <Text
                                            stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANY__POPOVER__CONTENT"
                                            valueMap={{account: item.account?.catalog || ''}}
                                        />
                                        <Text className={styles.AccountTab_text}>
                                            <FontAwesomeIcon className={styles.AccountTab_icon} icon={faUser} />
                                            {item.account?.login || ''}
                                        </Text>
                                    </div>
                                }
                                title={<Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANY__POPOVER__TITLE" />}
                            >
                                <FontAwesomeIcon className={styles.AccountTab_icon} icon={faUser} />
                            </Popover>
                        )}
                    </Text>
                );
            },
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANY__TABLE__COLUMN__CODE" />,
            key: 'code',
            width: '140px',
            render: (_value, item: CompanyType) => {
                return <Text>{item.code}</Text>;
            },
        },
        {
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__COMPANY__TABLE__COLUMN__ADDRESS" />,
            key: 'address',
            render: (_value, item: CompanyType) => {
                return <Text>{formatAddress(item.address)}</Text>;
            },
        },
    ];
}
