import {Button, Radio, RadioChangeEvent} from 'antd';
import {useState} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useCatalogsByGroup} from '../../../../../service/source-settings/catalogs/catalogs-hook';
import {CatalogByGroupType} from '../../../../../service/source-settings/catalogs/catalogs-type';
import {SourceLinkTypeEnum} from '../../../../../service/source-settings/source-links/source-links-type';
import {getEnumValueEnsure} from '../../../../../util/enum';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {LinksTable} from '../source-links-page/source-content/links-table/links-table';

import * as styles from './source-catalog-page.scss';

export function SourceCatalogPage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {id, groupId} = useParams<
        ExtractRouteParams<typeof appRoute.settingsSourceSettingsCatalog.path, string>
    >() || {id: '', groupId: ''};
    const {goBack} = useUrl();

    const {result, isInProgress, processError} = useCatalogsByGroup(Number(groupId));

    const activeCatalog: CatalogByGroupType | undefined = result
        ?.find((group) => String(group.id) === groupId)
        ?.catalogs.find((catalog) => String(catalog.id) === id);

    const [activeLinkType, setActiveLinkType] = useState<SourceLinkTypeEnum>(SourceLinkTypeEnum.Brand);

    function onLinkTypeSelect(event: RadioChangeEvent): void {
        const linkType: SourceLinkTypeEnum = getEnumValueEnsure<SourceLinkTypeEnum>(
            SourceLinkTypeEnum,
            event.target.value,
            SourceLinkTypeEnum.Brand
        );

        setActiveLinkType(linkType);
    }

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('CATEGORY_NAME__SOURCE_SETTINGS')} />

            <PageCard>
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.settingsSourceSettings.path,
                            titleLangKey: 'CATEGORY_NAME__SOURCE_SETTINGS',
                        },
                        {
                            path: appRoute.settingsSourceSettings.path,
                            title: activeCatalog?.name,
                        },
                    ]}
                />

                <PageHeader>
                    {activeCatalog?.name}

                    <Button className={styles.SourceCatalogPage_button} onClick={goBack}>
                        <Locale stringKey="BUTTON__BACK" />
                    </Button>
                </PageHeader>
            </PageCard>

            <PageCard>
                <Spinner isShow={isInProgress} position="absolute" />

                {processError && <AlertFallback />}

                {activeCatalog && (
                    <LinksTable
                        catalog={activeCatalog}
                        catalogGroupId={Number(groupId)}
                        key={`${activeCatalog.id}=${activeLinkType}`}
                        linkType={activeLinkType}
                        linkTypeControl={
                            <Radio.Group onChange={onLinkTypeSelect} value={activeLinkType}>
                                <Radio.Button value={SourceLinkTypeEnum.Brand}>
                                    <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_TYPE_BRAND" />
                                </Radio.Button>
                                <Radio.Button value={SourceLinkTypeEnum.Company}>
                                    <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_TYPE_COMPANY" />
                                </Radio.Button>
                            </Radio.Group>
                        }
                    />
                )}
            </PageCard>
        </Page>
    );
}
