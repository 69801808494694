import {faArrowRight, faExternalLinkSquare} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useContext} from 'react';

import {NotificationContext} from '../../../../../../provider/notification/notification';
import {NotificationContextType} from '../../../../../../provider/notification/notification-type';
import {AnalyticsTarget, useTrackViewRef} from '../../../../../../service/analytics/analytics';
import {NotificationType} from '../../../../../../service/notification/notification-type';
import {classNames} from '../../../../../../util/css';
import {useFormat} from '../../../../../../util/format-hook/format-hook';

import {isRelativeLink} from './notification-item-helper';
import * as styles from './notification-item.scss';

type PropsType = {
    notification: NotificationType;
};

export function NotificationItem(props: PropsType): JSX.Element {
    const {notification} = props;
    const {id, create_date: createDate, header, is_read: isRead, link, message} = notification;

    const fullClassName = classNames(styles.notification_item, {
        [styles.notification_item__has_been_read]: isRead,
    });

    const notificationItemRef = useTrackViewRef<
        HTMLDivElement,
        typeof AnalyticsTarget.PersonalCabinet.NotificationView
    >(AnalyticsTarget.PersonalCabinet.NotificationView, id);

    const {markNotificationAsRead} = useContext<NotificationContextType>(NotificationContext);
    const {getFormattedDateTime} = useFormat();

    const content = (
        <>
            <h5 className={styles.header}>{header}</h5>

            <p className={styles.text}>{message}</p>

            <span className={styles.date}>
                {getFormattedDateTime(new Date(createDate), {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}
            </span>
        </>
    );

    return (
        <div ref={notificationItemRef}>
            {link ? (
                <a
                    className={fullClassName}
                    href={link}
                    onClick={() => markNotificationAsRead(id)}
                    rel="noreferrer"
                    role="button"
                    target="_blank"
                >
                    <div className={styles.wrapper}>
                        <div>{content}</div>
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={isRelativeLink(link) ? faArrowRight : faExternalLinkSquare} />
                        </div>
                    </div>
                </a>
            ) : (
                <button className={fullClassName} onClick={() => markNotificationAsRead(id)} type="button">
                    {content}
                </button>
            )}
        </div>
    );
}
