import {Radio, Typography} from 'antd';

import {Locale} from '../../../../../provider/locale/locale';
import {MatchingLocationType} from '../../../../../service/matching/matching-types';

import * as styles from './account-matching-option.scss';

type PropsType = {
    locationData: MatchingLocationType;
};

export function AccountMatchingOption(props: PropsType): JSX.Element {
    const {locationData} = props;

    return (
        <Radio value={locationData}>
            <div className={styles.match_company_option__wrapper}>
                <h5 className={styles.match_company_option__name}>{locationData.name}</h5>

                {locationData.formattedAddress && (
                    <address className={styles.match_company_option__address}>{locationData.formattedAddress}</address>
                )}

                {locationData.website && <span>{locationData.website}</span>}

                {locationData.phone && locationData.phone.length > 0 && <span>{locationData.phone.join(', ')}</span>}

                {locationData.categories && locationData.categories?.length > 0 && (
                    <span>{locationData.categories.join(', ')}</span>
                )}

                {locationData.verified === true ? (
                    <Typography.Text type="success">
                        <Locale stringKey="COMPANY_MATCHING__VERIFIED" />
                    </Typography.Text>
                ) : locationData.verified === false ? (
                    <Typography.Text type="danger">
                        <Locale stringKey="COMPANY_MATCHING__NOT_VERIFIED" />
                    </Typography.Text>
                ) : null}
            </div>
        </Radio>
    );
}
