export const enCompanyMatchingTranslations = {
    COMPANY_MATCHING__SINGLE_COMPANY: 'Single company matching',
    COMPANY_MATCHING__MASS: 'Mass matching',

    COMPANY_MATCHING__VERIFIED: 'Verified',
    COMPANY_MATCHING__NOT_VERIFIED: 'Not verified',

    COMPANY_MATCHING__CREATE_NEW: 'Create new',
    COMPANY_MATCHING__CHOOSE: 'Choose from options',
    COMPANY_MATCHING__DONT_SYNC: "Don't link",
    COMPANY_MATCHING__SYNC: 'Link',

    COMPANY_MATCHING__FILTER__PLACEHOLDER: 'Filter by address...',
    COMPANY_MATCHING__FILTER__TOTAL__SINGULAR: 'Found {count} option',
    COMPANY_MATCHING__FILTER__TOTAL__FEW: 'Found {count} options',
    COMPANY_MATCHING__FILTER__TOTAL__MANY: 'Found {count} options',
    COMPANY_MATCHING__FILTER__FILTERED__SINGULAR: 'Showing {count} of {total} options',
    COMPANY_MATCHING__FILTER__FILTERED__FEW: 'Showing {count} of {total} options',
    COMPANY_MATCHING__FILTER__FILTERED__MANY: 'Showing {count} of {total} options',
    COMPANY_MATCHING__FILTER__NOT_FOUND: 'No matching options',
    COMPANY_MATCHING__FILTER__CLEAR: 'Reset filter',

    COMPANY_MATCHING__STEP__SELECT_ACCOUNT: 'Select account',
    COMPANY_MATCHING__STEP__MATCH_PAGES: 'Match page',
    COMPANY_MATCHING__STEP__MATCH_LOCATIONS: 'Match location',

    COMPANY_MATCHING__MATCHING_PAGE_ERROR__TITLE: 'You cannot create locations for your page',
    COMPANY_MATCHING__MATCHING_PAGE_ERROR__INSTRUCTIONS: 'instructions',
    COMPANY_MATCHING__MATCHING_PAGE_ERROR__DOC_FACEBOOK: 'documentation',
    COMPANY_MATCHING__MATCHING_PAGE_ERROR__MESSAGE:
        'At the moment, your page type does not allow you to create locations for it. If you have the classic version of the pages, then go through the steps described in {instructions}. If you have a new version, then it is not possible to create locations yet. You can read more about page versions in {docFacebook} {catalogName}.',
    COMPANY_MATCHING__MATCHING_SUCCESS__MESSAGE: 'Company ({company}) matched',
    COMPANY_MATCHING__CREATE_NEW_SUCCESS__MESSAGE: 'Location for company ({company}) created successfully',

    ACTION_THUMBS__SYNCHRONIZED: 'Synchronized',
    ACTION_THUMBS__SENT: 'Sent',
    ACTION_THUMBS__ACTION_REQUIRED: 'Action required',
    ACTION_THUMBS__CONFIRMATION: 'Confirmation',
};
