import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';

import {ReviewsStateType} from '../../page/main/reviews/reviews-state/reviews-state-type';
import {deserializeV2, serializeToURLParameters} from '../../util/api-adapter';
import {useCursorPagination} from '../../util/cursor-pagination/cursor-pagination-hook';
import {CursorPaginationDataType, CursorPaginationType} from '../../util/cursor-pagination/cursor-pagination-type';
import {createRaceFetchX, FetchMethodEnum, fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {reviewsStateToUrlObject} from './reviews-helper';
import {
    fetchReviewsResultSchema,
    FetchReviewsResultType,
    reviewDataSchema,
    ReviewDataType,
    ReviewsAbuseType,
    ReviewsHookPropsType,
    ReviewsReplyTagsType,
} from './reviews-type';

const reviewsRaceFetchX = createRaceFetchX();

function getReviewsUrl(reviewOption: ReviewsStateType, paginationData: CursorPaginationDataType) {
    const {pageSize, ...restPaginationData} = paginationData;

    const urlParameters = objectToUrlParameters({
        ...(!reviewOption.company_ids ? {filter_key: reviewOption.filterId} : null),
        ...reviewsStateToUrlObject(reviewOption),
        per_page: pageSize,
        ...restPaginationData,
    });

    return `${rootApiUrl}/v4/reviews/?${urlParameters}`;
}

async function fetchReviews(
    reviewOption: ReviewsStateType,
    paginationData: CursorPaginationDataType
): Promise<FetchReviewsResultType> {
    const url = getReviewsUrl(reviewOption, paginationData);

    const response = await reviewsRaceFetchX(url);

    return deserializeV2<FetchReviewsResultType>(url, fetchReviewsResultSchema, response);
}

export function useReviews(props: ReviewsHookPropsType): UseQueryResult<FetchReviewsResultType> & CursorPaginationType {
    const {reviewsState} = props;

    const paginationDependencies = useMemo(() => {
        return {mainFilterKey: reviewsState.filterId, reviewsState};
    }, [reviewsState]);

    const cursorPagination = useCursorPagination({
        dependencies: paginationDependencies,
    });
    const {pageSize, cursor, onDataLoaded} = cursorPagination;

    const query = useQuery(
        [getReviewsUrl(reviewsState, {cursor, pageSize})],
        () => fetchReviews(reviewsState, {cursor, pageSize}),
        {
            onSuccess: (data) => onDataLoaded(data),
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    return {
        ...query,
        pagination: cursorPagination,
    };
}

function getReviewItemUrl(id: number) {
    return `${rootApiUrl}/v4/reviews/${id}/`;
}

export async function fetchReviewItem(id: number): Promise<ReviewDataType> {
    const url = getReviewItemUrl(id);
    const response = await fetchX<ReviewDataType>(url);

    return deserializeV2<ReviewDataType>(url, reviewDataSchema, response);
}

export function useReviewItem(id: number): UseQueryResult<ReviewDataType> {
    return useQuery([getReviewItemUrl(id)], () => fetchReviewItem(id), {
        refetchOnWindowFocus: false,
    });
}

export function fetchReviewsAbuse(reviewId: number, data: ReviewsAbuseType): Promise<ReviewsAbuseType> {
    return fetchX<ReviewsAbuseType>(`${rootApiUrl}/v4/reviews/${reviewId}/abuse/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serializeToURLParameters(data)),
    });
}

export function fetchReviewsReplyTagsSend(reviewId: number, data: ReviewsReplyTagsType): Promise<ReviewsReplyTagsType> {
    return fetchX<ReviewsReplyTagsType>(`${rootApiUrl}/v4/reviews/${reviewId}/tags/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(data),
    });
}
