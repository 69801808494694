export const enNotificationsTranslations = {
    NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS: 'Email notifications',
    NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS__SUB_HEADER: 'What notifications do you want to receive by email?',
    NOTIFICATIONS__SETTINGS_SAVED: 'Settings have been saved',
    NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS__UPDATED: 'Settings of email notifications were successfully saved',
    NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS__FAILED_TO_UPDATE: 'Failed to save email notification settings',
    NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS: 'Browser notifications',
    NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__SUB_HEADER:
        'You can receive notifications in the browser on your computer',
    NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__SUB_HEADER__HINT:
        'To receive notifications, you need to allow notifications from {domain} in the browser',
    NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__UPDATED:
        'Settings of the browser notifications were successfully saved',
    NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__FAILED_TO_UPDATE:
        'Failed to save settings of browser notifications',
    NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS: 'Telegram notifications',
    NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS__SUB_HEADER: 'You can receive notifications in Telegram',
    NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS__UPDATED:
        'Settings of the telegram notifications were successfully saved',
    NOTIFICATIONS__TAB_INFO__TELEGRAM_NOTIFICATIONS__FAILED_TO_UPDATE:
        'Failed to save settings of telegram notifications',
    NOTIFICATIONS__RECEIVE_NEWS: 'Product news',
    NOTIFICATIONS__RECEIVE_NOTIFICATIONS_OF_NEW_REVIEWS: 'New reviews',
    NOTIFICATIONS__CHANGE_RESPONSIBLE_USER: 'Change of responsible person for the review',
    NOTIFICATIONS__RECEIVE_NOTIFICATIONS_WHEN_REVIEWS_HAVE_CHANGED: 'Changing reviews',
    NOTIFICATIONS__RECEIVE_NOTIFICATIONS_OF_NEW_QUESTIONS: 'New questions/answers',
    NOTIFICATIONS__RECEIVE_WEEKLY_ANALYTICS_SUMMARY_NOTIFICATIONS: 'Weekly analytics summary',
    NOTIFICATIONS__RECEIVE_WEEKLY_PHOTOTOOL_SUMMARY_NOTIFICATIONS: 'New photos in cards for the week',
    NOTIFICATIONS__RECEIVE_NEW_COMMENTS_IN_TICKETS_NOTIFICATIONS: 'New comments in tickets',
    NOTIFICATIONS__RECEIVE_OMNICHANNEL: 'New messages from communication',
    NOTIFICATIONS__RECEIVE_NEW_REVIEWS_SUB_HEADER: 'Recieve new notifications',
    NOTIFICATIONS__RECEIVE_NEW_REVIEWS_EMAIL_LABEL: 'Email for the new reviews (comma separated list)',
    NOTIFICATIONS__RECEIVE_NEW_REVIEWS_FREQUENCY_LABEL: 'Frequency of new reviews',
    NOTIFICATIONS__RECEIVE_NEW_REVIEWS_FREQUENCY_ONE_HOUR_OPTION: '1 per hour',
    NOTIFICATIONS__RECEIVE_NEW_REVIEWS_FREQUENCY_ONE_DAY_OPTION: '1 per day',
    NOTIFICATIONS__SETTING_THE_TIME_FOR_RECEIVING_NOTIFICATIONS: 'Setting the time for receiving notifications',
    NOTIFICATIONS__TIME_PERIOD: 'Time period',
    NOTIFICATIONS__SERVICE_CONNECTION: 'Service connection',
    NOTIFICATIONS__CHAT_TOKEN: 'Chat token',
    NOTIFICATIONS__YOUR_TOKEN: 'Your token',
    NOTIFICATIONS__THE_TOKEN_CAN_BE_OBTAINED_FROM_OUR_TELEGRAM_BOT: 'The token can be obtained from our Telegram bot',
    NOTIFICATIONS__GET_A_TOKEN: 'Get a token',
    NOTIFICATIONS__REVIEW_FILTER__HEADER: 'Filtering reviews',
    NOTIFICATIONS__REVIEW_FILTER__LABEL__FEEDBACK_SOURCE: 'Feedback source',
    NOTIFICATIONS__REVIEW_FILTER__PLACEHOLDER__FEEDBACK_SOURCE: 'Select sources',
    NOTIFICATIONS__REVIEW_FILTER__LABEL__RATING: 'Rating',
    NOTIFICATIONS__REVIEW_FILTER__PLACEHOLDER__RATING: 'Select rating',
    NOTIFICATIONS__REVIEW_FILTER__LABEL__CONTENT_OF_REVIEWS: 'Content of reviews',
    NOTIFICATIONS__REVIEW_FILTER__PLACEHOLDER__CONTENT_OF_REVIEWS: 'All reviews',
    NOTIFICATIONS__REVIEW_FILTER__ALL_REVIEWS: 'All reviews',
    NOTIFICATIONS__REVIEW_FILTER__REVIEWS_WITH_TEXT: 'Reviews with text',
    NOTIFICATIONS__REVIEW_FILTER__REVIEWS_WITHOUT_TEXT: 'Reviews without text',
    NOTIFICATION__TEXT__NO_NOTIFICATIONS: 'No notifications',
    NOTIFICATION__TEXT__NO_NOTIFICATIONS__DESCRIPTION:
        'Important announcements, updates and product\nnews will appear here.',
    NOTIFICATION__TEXT__SYSTEM_NOTIFICATIONS: 'Notifications',
    NOTIFICATION__TEXT__MARK_ALL_AS_READ: 'Mark all as read',
    NOTIFICATION__TEXT__SERVER_ERROR: 'Action is not possible',
    NOTIFICATION__NOTIFY_ABOUT_RATING_DROP: 'Notify about the rating drop of the company profile',
};
