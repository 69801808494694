import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useLocalPackCompanyInfo} from '../../../../../service/local-pack/local-pack-hook';
import {toTrimmedString} from '../../../../../util/string';

import * as styles from './company-info.scss';

type CompanyInfoPropsType = {
    companyId: number;
};

export function LocalPackCompanyInfo(props: CompanyInfoPropsType): JSX.Element {
    const {companyId} = props;

    const {data: companyInfo, isLoading: isInProgress, error} = useLocalPackCompanyInfo(Number(companyId));

    if (isInProgress) {
        return <Spinner />;
    }

    if (error || !companyInfo) {
        return <AlertFallback />;
    }

    const addressText: string = [
        companyInfo.address.countryName,
        companyInfo.address.cityName,
        companyInfo.address.street,
        companyInfo.address.houseNumber,
    ]
        .map(toTrimmedString)
        .filter(Boolean)
        .join(', ');

    return (
        <div className={styles.company_info__wrapper}>
            <p className={styles.company_info__name}>{companyInfo.name}</p>
            <p className={styles.company_info__address}>{addressText}</p>
        </div>
    );
}
