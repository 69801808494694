import {Checkbox, DatePicker, Space} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';

import {AntdErrorMessage} from '../../../../../../../../component/antd-error-message/antd-error-message';
import {Text} from '../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    SpecialHoursType,
    TimeRangeType,
    WorkingDayType,
} from '../../../../../../../../service/company-v2/types/company-working-time-type';
import {replaceInList} from '../../../../../../../../util/array';
import {classNames} from '../../../../../../../../util/css';
import {BreakTimeList} from '../../common/break-time-list/break-time-list';
import {TimeDeleteButton} from '../../common/time-delete-button/time-delete-button';
import {WorkTime} from '../../common/work-time/work-time';

import * as styles from './temporary-work-day.scss';

type PropsType = {
    setTemporaryWorkDayDataList: (temporaryWorkDayDataList: Array<SpecialHoursType>) => void;
    temporaryWorkDayDataList: Array<SpecialHoursType>;
    temporaryWorkDayData: SpecialHoursType;
    className?: string;
    errorMessage?: SpecialHoursType | null;
    isDoubleGisCompany: boolean;
};

// eslint-disable-next-line complexity
export function TemporaryWorkDay(props: PropsType): JSX.Element {
    const {
        className,
        temporaryWorkDayData,
        setTemporaryWorkDayDataList,
        temporaryWorkDayDataList,
        errorMessage,
        isDoubleGisCompany,
    } = props;
    const {startDate, endDate, timeRange, breaks = [], isHoliday, daily} = temporaryWorkDayData;

    const fullClassName = classNames(styles.TemporaryWorkDay, className);
    const {getLocalizedString} = useLocale();
    const today = dayjs().hour(0).minute(0).second(0).millisecond(0);
    const format = 'YYYY-MM-DD';

    const dateErrors = [
        ...new Set([
            ...(errorMessage?.startDate && Array.isArray(errorMessage.startDate) ? errorMessage.startDate : []),
            ...(errorMessage?.endDate && Array.isArray(errorMessage.endDate) ? errorMessage.endDate : []),
        ]),
    ];

    function handleRemoveTemporaryWorkDayData() {
        const newTemporaryWorkDayDataList = [...temporaryWorkDayDataList];

        newTemporaryWorkDayDataList.splice(temporaryWorkDayDataList.indexOf(temporaryWorkDayData), 1);

        setTemporaryWorkDayDataList(newTemporaryWorkDayDataList);
    }

    function updateTime(newTime: Pick<WorkingDayType, 'daily' | 'timeRange'>) {
        setTemporaryWorkDayDataList(
            replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {...temporaryWorkDayData, ...newTime})
        );
    }

    function setBreakTimeList(newBreakTimeList: Array<TimeRangeType>) {
        setTemporaryWorkDayDataList(
            replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                ...temporaryWorkDayData,
                breaks: newBreakTimeList,
            })
        );
    }

    function handleChangePeriod(values: NoUndefinedRangeValueType<Dayjs> | null) {
        if (!values || !values[0] || !values[1]) {
            setTemporaryWorkDayDataList(
                replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                    ...temporaryWorkDayData,
                    startDate: '',
                    endDate: '',
                })
            );
            return;
        }

        const [dayjsFrom, dayjsTo] = values;

        setTemporaryWorkDayDataList(
            replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                ...temporaryWorkDayData,
                startDate: dayjsFrom.format(format),
                endDate: dayjsTo.format(format),
            })
        );
    }

    return (
        <div className={styles.container}>
            <div className={fullClassName}>
                <div className={styles.TemporaryWorkDay_period}>
                    <Text
                        className={styles.TemporaryWorkDay_descriptionText}
                        lighter
                        stringKey="COMPANY_FORM__WORKING_TIME__DATES"
                    />
                    <Space>
                        <DatePicker.RangePicker
                            className={styles.TemporaryWorkDay_workTimeWrapper}
                            disabledDate={(date: Dayjs) => date.isBefore(today)}
                            format={format}
                            onChange={handleChangePeriod}
                            placeholder={[getLocalizedString('TEXT__DATE_FROM'), getLocalizedString('TEXT__DATE_TO')]}
                            value={
                                // eslint-disable-next-line no-undefined
                                startDate && endDate ? [dayjs(startDate, format), dayjs(endDate, format)] : undefined
                            }
                        />

                        <Checkbox
                            checked={isHoliday}
                            className={styles.TemporaryWorkDay_period__dayOffCheckBox}
                            onChange={() => {
                                setTemporaryWorkDayDataList(
                                    replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                                        ...temporaryWorkDayData,
                                        isHoliday: !isHoliday,
                                    })
                                );
                            }}
                        >
                            <Locale stringKey="ADDITIONAL_INFO__CLOSED" />
                        </Checkbox>
                        <TimeDeleteButton onClick={handleRemoveTemporaryWorkDayData} />
                    </Space>
                    {Array.isArray(dateErrors) && dateErrors.length > 0 && (
                        <AntdErrorMessage>{dateErrors}</AntdErrorMessage>
                    )}
                </div>

                {!isHoliday && (
                    <div className={styles.TemporaryWorkDay_timePeriod}>
                        <Text
                            className={styles.TemporaryWorkDay_descriptionText}
                            stringKey="ADDITIONAL_INFO__WORKING_HOURS"
                        />
                        <Space align="baseline">
                            <div className={styles.TemporaryWorkDay_workTimeWrapper}>
                                <WorkTime
                                    className={styles.TemporaryWorkDay_time}
                                    daily={Boolean(daily)}
                                    timeRange={timeRange}
                                    updateTime={updateTime}
                                />
                            </div>
                            <BreakTimeList
                                breakTimeList={breaks}
                                className={styles.TemporaryWorkDay_breakTime}
                                errorMessage={errorMessage?.breaks}
                                isDoubleGisCompany={isDoubleGisCompany}
                                setBreakTimeList={setBreakTimeList}
                            />
                        </Space>
                    </div>
                )}
            </div>

            {Array.isArray(errorMessage) && <AntdErrorMessage>{errorMessage}</AntdErrorMessage>}
        </div>
    );
}
