import {useCallback, useMemo} from 'react';

import {classNames} from '../../util/css';

import {RatingStar} from './rating-star/rating-star';
import {defaultRatingStarLineData} from './rating-star-line-const';
import * as styles from './rating-star-line.scss';

type PropsType = {
    className?: string;
    color?: string;
    columnGap?: number;
    rating?: number;
    starWidth?: number;
    starHeight?: number;
    starCount?: number;
};

export function RatingStarLine(props: PropsType): JSX.Element {
    const {
        className,
        color = defaultRatingStarLineData.color,
        columnGap = defaultRatingStarLineData.columnGap,
        rating = defaultRatingStarLineData.rating,
        starWidth = defaultRatingStarLineData.starWidth,
        starHeight = defaultRatingStarLineData.starHeight,
        starCount = defaultRatingStarLineData.starCount,
    } = props;

    const ratingIntegerPart = Math.floor(rating);

    // eslint-disable-next-line unicorn/no-new-array
    const emptyList: Array<unknown> = useMemo(() => new Array(starCount).fill(null), [starCount]);

    const renderStar = useCallback(
        (_value: unknown, index: number): JSX.Element => {
            if (index === ratingIntegerPart) {
                return (
                    <RatingStar
                        fillPercent={(rating % 1) * 100}
                        height={starHeight}
                        key={String(index)}
                        width={starWidth}
                    />
                );
            }

            if (index < ratingIntegerPart) {
                return <RatingStar fillPercent={100} height={starHeight} key={String(index)} width={starWidth} />;
            }

            return <RatingStar fillPercent={0} height={starHeight} key={String(index)} width={starWidth} />;
        },
        [starHeight, starWidth, rating, ratingIntegerPart]
    );

    return (
        <div
            className={classNames(styles.rating_star_line, className)}
            data-testid={`rating-${rating}`}
            style={{columnGap, color}}
        >
            {emptyList.map(renderStar)}
        </div>
    );
}
