import {PropsWithChildren} from 'react';

import {classNames} from '../../../util/css';

import * as styles from './landing-blurred-card.scss';

type PropsType = {
    maxHeight?: number;
    disablePointerEvents?: boolean;
};

export function LandingBlurredCard(props: PropsWithChildren<PropsType>): JSX.Element {
    const {children, maxHeight, disablePointerEvents} = props;

    return (
        <div
            className={classNames(styles.LandingBlurredCard, {
                [styles.LandingBlurredCard__disabled]: disablePointerEvents,
            })}
            style={{maxHeight}}
        >
            {children}
        </div>
    );
}
