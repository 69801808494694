import {Input, List, Radio, RadioChangeEvent} from 'antd';
import {ChangeEvent, Dispatch, SetStateAction, useState} from 'react';

import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {
    GoogleCompanyWithCandidatesType,
    GoogleLocationType,
} from '../../../../../../../../../service/google/google-type';
import {classNames} from '../../../../../../../../../util/css';
import {includesDeburredString} from '../../../../../../../../../util/string';
import {CompanyListItem} from '../../../../../common/company-list-item/company-list-item';
import {NEW_LOCATION_OPTION} from '../../../processing-step-const';

import {FilterSummary} from './filter-summary/filter-summary';
import {NewLocation} from './new-location/new-location';
import * as styles from './location-modal-tab.scss';

type PropsType = {
    locations: NonNullable<GoogleCompanyWithCandidatesType['candidates']>;
    selectedLocationId: string | null;
    setSelectedLocationId: Dispatch<SetStateAction<string | null>>;
};

export function LocationModalTab(props: PropsType): JSX.Element {
    const {locations, selectedLocationId, setSelectedLocationId} = props;

    const [filter, setFilter] = useState('');
    const {getLocalizedString} = useLocale();

    function locationIncludesFilter(location: GoogleLocationType) {
        const {name, address, phones, website} = location;

        return (
            includesDeburredString(name, filter) ||
            includesDeburredString(address, filter) ||
            includesDeburredString(website, filter) ||
            phones.some((phone) => includesDeburredString(phone, filter))
        );
    }

    const filteredLocations = filter.length === 0 ? locations.data : locations.data.filter(locationIncludesFilter);

    const dataSource =
        filter.length === 0
            ? [...filteredLocations, {originId: NEW_LOCATION_OPTION, newLocation: true as const}]
            : filteredLocations;

    function handleRadioGroupChange(event: RadioChangeEvent) {
        setSelectedLocationId(event.target.value);
    }

    function handleSearch(event: ChangeEvent<HTMLInputElement>) {
        setFilter(event.target.value);
    }

    function resetFilter() {
        setFilter('');
    }

    return (
        <>
            <header className={styles.LocationModalTab_filter}>
                <Input.Search
                    allowClear
                    onChange={handleSearch}
                    placeholder={getLocalizedString('GOOGLE_SYNC__PROCESSING_STEP__LOCATION_MODAL__FILTER')}
                    size="small"
                    value={filter}
                />
            </header>

            <Radio.Group
                className={styles.LocationModalTab_radioGroup}
                onChange={handleRadioGroupChange}
                value={selectedLocationId}
            >
                {filteredLocations.length === 0 && filter.length > 0 ? null : (
                    <List
                        className={styles.LocationModalTab_list}
                        dataSource={dataSource}
                        renderItem={(location: GoogleLocationType | {originId: string; newLocation: true}) => (
                            <Radio
                                className={classNames(styles.LocationModalTabRadio, {
                                    [styles.LocationModalTabRadio__selected]: location.originId === selectedLocationId,
                                })}
                                value={location.originId}
                            >
                                {'newLocation' in location ? (
                                    <NewLocation markerClassName={styles.NewLocation_marker} />
                                ) : (
                                    <CompanyListItem company={location} />
                                )}
                            </Radio>
                        )}
                        size="small"
                        split={false}
                    />
                )}

                {filter.length > 0 && (
                    <FilterSummary
                        count={filteredLocations.length}
                        resetFilter={resetFilter}
                        total={locations.data.length}
                    />
                )}
            </Radio.Group>
        </>
    );
}
