import {faRefresh} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip} from 'antd';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {usePostRefresh} from '../../../../../service/posts/posts-hooks';

import * as styles from './post-actions.scss';

type PropsType = {
    postId: number;
};

export function PostActionRefresh(props: PropsType): JSX.Element {
    const {postId} = props;

    const {getLocalizedString} = useLocale();
    const {mutate, isLoading} = usePostRefresh();

    return (
        <Tooltip title={getLocalizedString('BUTTON__UPDATE')}>
            <Button disabled={isLoading} loading={isLoading} onClick={() => mutate(postId)} size="small" type="link">
                <FontAwesomeIcon className={styles.PostActions_icon} icon={faRefresh} />
            </Button>
        </Tooltip>
    );
}
