import {useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';
import {z as r} from 'zod';

import {fetchAndDeserialize, getUrlParameters} from '../../util/api-adapter';
import {dropFetchXCache, FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {generateResponseSchema, PaginatedResponseType} from '../api/api-type';

export const tagGroupSchema = r.object({
    id: r.number(),
    title: r.string(),
});

const tagGroupListSchema = r.array(tagGroupSchema);

export type TagGroupType = r.infer<typeof tagGroupSchema>;

const tagDataSchema = r.object({
    created_at: r.string().optional(),
    folder: tagGroupSchema.nullable(),
    id: r.number(),
    isComplex: r.boolean().optional(),
    keys: r.array(r.string()),
    keywords: r.array(r.string()),
    subTags: r.array(r.unknown()).optional(),
    title: r.string(),
    type: r.string(),
    user: r.object({
        id: r.number(),
    }),
});

const tagsDataSchema = generateResponseSchema(tagDataSchema);

export type TagDataType = r.infer<typeof tagDataSchema>;

const aspectSchema = r.object({
    ids: r.array(r.number()),
    title: r.string(),
});

type AspectType = r.infer<typeof aspectSchema>;

type DeleteTagRequestType = {
    ids: Array<string> | number;
};

type DeleteTagGroupRequestType = {
    ids: Array<string> | number;
};

export type ChangeTagGroupRequestType = {
    ids: Array<string>;
    destination_folder_id?: number;
    without_folder?: boolean;
};

type ChangeTagTypeRequestType = {
    ids: Array<string>;
    type: string;
};

type CreateGroupRequestType = {
    title: string;
};

type ReviewResultsOptionType = {
    page?: number;
    count?: number;
    folderIds: string;
    tagType: string;
    withoutFolder: true | null;
};

type ReviewTagsOptionType = {
    q?: string;
    folderIds?: number;
    withoutRg?: boolean;
};

export const defaultTagData: TagDataType = {
    created_at: '0000-00-00T00:00:00.000000Z',
    folder: null,
    id: -1,
    isComplex: false,
    keys: [],
    keywords: [],
    subTags: [],
    title: '',
    type: 'public',
    user: {
        id: 1,
    },
};

export const defaultGroupData: TagGroupType = {
    id: -1,
    title: '',
};

const tagGroupListUrl = '/cp/review_tags/folders/';

function fetchTagGroupList(): Promise<Array<TagGroupType>> {
    return fetchAndDeserialize<Array<TagGroupType>>(tagGroupListUrl, tagGroupListSchema);
}

export function useTagGroupList(): UseQueryResult<Array<TagGroupType>> {
    return useQuery([tagGroupListUrl], () => fetchTagGroupList(), {
        refetchOnWindowFocus: false,
    });
}

function getReviewTagsUrl(options: ReviewResultsOptionType | ReviewTagsOptionType) {
    return `/cp/review_tags/${getUrlParameters(options)}`;
}

function fetchTags(
    options: ReviewResultsOptionType | ReviewTagsOptionType
): Promise<PaginatedResponseType<TagDataType>> {
    return fetchAndDeserialize<PaginatedResponseType<TagDataType>>(getReviewTagsUrl(options), tagsDataSchema, {
        fetchOptions: {skipCache: true},
    });
}

export function useTags(
    folderIdList: Array<string>,
    typeTagList: Array<string>,
    withoutFolder: true | null
): PaginationType & UseQueryResult<PaginatedResponseType<TagDataType>> {
    const dependencies = useMemo(
        () => ({
            folderIds: folderIdList.join(','),
            tagType: typeTagList.join(','),
            withoutFolder,
        }),
        [folderIdList, typeTagList, withoutFolder]
    );

    const pagination = usePagination({dependencies: null, initialPageSize: 10});

    const {page, pageSize, onDataLoaded} = pagination;

    const queryParameters = useMemo(
        () => ({
            page,
            count: pageSize,
            ...dependencies,
        }),
        [page, pageSize, dependencies]
    );

    const query = useQuery([getReviewTagsUrl(queryParameters)], () => fetchTags(queryParameters), {
        onSuccess: (data) => onDataLoaded(data),
        refetchOnWindowFocus: false,
    });

    return {...query, pagination};
}

export function useInfiniteTags(
    options: ReviewTagsOptionType
): UseInfiniteQueryResult<PaginatedResponseType<TagDataType>> {
    return useInfiniteQuery(
        [getReviewTagsUrl({...options, count: 20})],
        async ({pageParam: page = 1}) => fetchTags({page, ...options, count: 20}),
        {
            getPreviousPageParam: (firstPage) => (firstPage.previous && firstPage.page ? firstPage.page - 1 : 1),
            getNextPageParam: (lastPage) => (lastPage.next && lastPage.page ? lastPage.page + 1 : null),
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            refetchInterval: 0,
            retry: 0,
        }
    );
}

function fetchAspects(): Promise<Array<AspectType>> {
    return fetchAndDeserialize('/cp/review_tags/aspects/', aspectSchema);
}

export function useReviewsAspects(): UseQueryResult<Array<AspectType>> {
    return useQuery(['/cp/review_tags/aspects'], () => fetchAspects());
}

function getTagUrl(id: number) {
    return `/cp/review_tags/${id}/`;
}

function fetchTag(id: number): Promise<TagDataType> {
    return fetchAndDeserialize<TagDataType>(getTagUrl(id), tagDataSchema);
}

export function useTag(idTag: number): UseQueryResult<TagDataType> {
    return useQuery([getTagUrl(idTag)], () => fetchTag(idTag), {
        refetchOnWindowFocus: false,
    });
}

export function fetchTagCreate(data: TagDataType): Promise<TagDataType> {
    return fetchX<TagDataType>(rootApiUrl + '/cp/review_tags/', {
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTagUpdate(data: TagDataType, id: number): Promise<TagDataType> {
    return fetchX<TagDataType>(`${rootApiUrl}/cp/review_tags/${id}/`, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTagChangeGroup(data: ChangeTagGroupRequestType): Promise<TagDataType> {
    return fetchX<TagDataType>(`${rootApiUrl}/cp/review_tags/change_tags_folder/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTagChangeType(data: ChangeTagTypeRequestType): Promise<TagDataType> {
    return fetchX<TagDataType>(`${rootApiUrl}/cp/review_tags/change_tags_type/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTagDelete(data: DeleteTagRequestType): Promise<TagDataType> {
    const parameters = objectToUrlParameters(data);

    return fetchX<TagDataType>(`${rootApiUrl}/cp/review_tags/?${parameters}`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchGroupCreate(data: CreateGroupRequestType): Promise<TagGroupType> {
    return fetchX<TagDataType>(rootApiUrl + '/cp/review_tags/folders/', {
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchGroupUpdate(data: TagGroupType, id: number): Promise<TagGroupType> {
    return fetchX<TagDataType>(`${rootApiUrl}/cp/review_tags/folders/${id}/`, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchGroupDelete(data: DeleteTagGroupRequestType): Promise<void> {
    dropFetchXCache([`${rootApiUrl}/cp/review_tags/folders/ - ${JSON.stringify('[empty]')}`]);

    const parameters = objectToUrlParameters(data);

    return fetchNoContent(`${rootApiUrl}/cp/review_tags/folders/?${parameters}`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
