import {useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';
import {z as r} from 'zod';

import {useUser} from '../../provider/user/user-hook';
import {fetchAndDeserialize, getUrlParameters} from '../../util/api-adapter';
import {generateResponseSchema} from '../api/api-type';

import {tariffUrl} from './tariffs-const';
import {tariffSchema} from './tariffs-user-tariff';

const tariffFromListSchema = tariffSchema
    .extend({
        id: r.number(),
        brandCount: r.number(),
        companyCount: r.number(),
    })
    .omit({brands: true});

const tariffsSchema = generateResponseSchema(tariffFromListSchema);

type TariffsType = r.infer<typeof tariffsSchema>;

export type TariffFromListType = r.infer<typeof tariffFromListSchema>;

function getTariffsUrl(firstTariffId?: number | null, page?: number) {
    return `${tariffUrl}/${getUrlParameters({firstTariffId, page})}`;
}

function fetchTariffs(initialTariff?: number | null, page?: number): Promise<TariffsType> {
    return fetchAndDeserialize(getTariffsUrl(initialTariff, page), tariffsSchema);
}

export function useTariffs(): UseQueryResult<TariffsType> {
    const {user} = useUser();

    return useQuery([`${getTariffsUrl()}_regularHook`, user?.id], () => fetchTariffs(), {
        enabled: Boolean(user?.id),
        retry: 0,
        refetchOnMount: false,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useTariffsInfinite(initialTariff: number | null): UseInfiniteQueryResult<TariffsType> {
    const queryParameters = useMemo(() => initialTariff, [initialTariff]);

    return useInfiniteQuery(
        [getTariffsUrl(initialTariff)],
        ({pageParam: pageParameter = 0}) => fetchTariffs(queryParameters, pageParameter),
        {
            getPreviousPageParam: (firstPage) => firstPage.previous,
            // eslint-disable-next-line no-undefined
            getNextPageParam: (lastPage) => (lastPage.next && lastPage.page ? lastPage.page + 1 : undefined),
        }
    );
}
