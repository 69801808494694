import {Timeline} from 'antd';

import {useTimelineForOneCompany} from '../../../../service/timeline-activity/timeline-activity-hook';
import {TimelineActionTypeEnum, TimelineDataType} from '../../../../service/timeline-activity/timeline-activity-type';

import {TimelineActivity} from './timeline-activity';
import {timelinePerPage} from './timeline-activity-const';
import {TimelineCard} from './timeline-card/timeline-card';
import {TimelineCardYandex} from './timeline-card-yandex/timeline-card-yandex';
import * as styles from './timeline-activity.scss';

type PropsType = {
    companyId: number;
    isDashboardWidget: boolean;
};

export function TimelineActivitySingleCompany(props: PropsType): JSX.Element {
    const {companyId, isDashboardWidget} = props;

    const {result, isInProgress, processError, loadMore, canLoadMore, refresh} = useTimelineForOneCompany({
        companyId,
        perPage: timelinePerPage,
    });

    return (
        <TimelineActivity<TimelineDataType>
            canLoadMore={canLoadMore}
            isDashboardWidget={isDashboardWidget}
            isLoading={isInProgress}
            items={result}
            loadMore={loadMore}
            processError={processError}
            refresh={refresh}
            renderTimelineItem={(timelineData: TimelineDataType) => (
                <Timeline.Item className={styles.TimelineActivity_item} key={timelineData.id}>
                    {timelineData.action === TimelineActionTypeEnum.YandexGeovisor ? (
                        <TimelineCardYandex timelineData={timelineData} />
                    ) : (
                        <TimelineCard timelineData={timelineData} />
                    )}
                </Timeline.Item>
            )}
        />
    );
}
