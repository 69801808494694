import dayjs from 'dayjs';
import {useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useDocumentationLinks} from '../../../../provider/help-links/help-links-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useAutoRepliesSummary} from '../../../../service/dashboard/dashboard-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {WidgetFooterText} from '../components/widget-footer-text/widget-footer-text';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {AddMoreReplies} from './add-more-replies/add-more-replies';
import {AutoRepliesEmpty} from './auto-replies-empty/auto-replies-empty';
import {AutoRepliesUsers} from './users-auto-replies/auto-replies-users';
import * as styles from './auto-replies-summary.scss';

export function AutoRepliesSummary(): JSX.Element {
    const {featureActivation} = useDocumentationLinks();
    const {licenses} = useLicenses();
    const {getFormattedNumber} = useFormat();
    const today = dayjs();
    const monthAgo = dayjs().subtract(30, 'days');

    const {result, isInProgress, processError} = useAutoRepliesSummary({
        startDate: monthAgo.format('YYYY-MM-DD'),
        endDate: today.format('YYYY-MM-DD'),
    });

    const footerLinkHref = useMemo(() => {
        if (licenses[FeaturesEnum.reviewSuggests]?.isActive) {
            return appRoute.reviewsManagementAutoReplies.path;
        }

        return featureActivation[FeaturesEnum.reviewSuggests] || '';
    }, [featureActivation, licenses]);

    const hasSpentOrAvailableAutoReplies = Boolean(result?.autoRepliesAvailable || result?.spentAutoRepliesCount);

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    return (
        <PageCard
            bodyClassName={styles.AutoReplies}
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__TITLE" />}
                    widget={DashboardWidgetEnum.AutoReplies}
                />
            }
        >
            {hasSpentOrAvailableAutoReplies ? (
                <>
                    <div className={styles.AutoReplies_tableStats}>
                        <div className={styles.AutoReplies_tableStatsCell}>
                            <Text block secondary stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__BALANCE" />
                            <Text block className={styles.AutoReplies_balanceCount}>
                                {getFormattedNumber(result.autoRepliesAvailable)}
                            </Text>
                        </div>
                        <div className={styles.AutoReplies_tableStatsCell}>
                            <Text block secondary stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__SPENT" />
                            <Text block className={styles.AutoReplies_balanceCount} lighter>
                                {getFormattedNumber(result.spentAutoRepliesCount)}
                            </Text>
                        </div>
                    </div>

                    {result.autoRepliesAvailable ? <AutoRepliesUsers balance={result.balance} /> : <AddMoreReplies />}

                    <WidgetFooterText
                        isExternalLink={!licenses[FeaturesEnum.reviewSuggests]?.isActive}
                        linkHref={footerLinkHref}
                        linkLangKey="CATEGORY_NAME__AUTO_REPLIES"
                        textLangKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__EXPLANATION"
                    />
                </>
            ) : (
                <AutoRepliesEmpty />
            )}
        </PageCard>
    );
}
