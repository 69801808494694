import {Table, TablePaginationConfig} from 'antd';
import {SorterResult} from 'antd/lib/table/interface';

import {header_height} from '../../../../../css/var-export.scss';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {Locale} from '../../../../../provider/locale/locale';
import {useSystem} from '../../../../../provider/system/system-hook';
import {LocalPackLeaderboardItemType} from '../../../../../service/local-pack/leaderboard/local-pack-laderboard-type';
import {useLocalPackLeaderboard} from '../../../../../service/local-pack/leaderboard/local-pack-leaderboard-hook';
import {LocalPackFilterType} from '../../local-pack-filter/local-pack-filter-type';

import {getLocalPackLeaderboardColumns, LOCAL_PACK_LEADER_BOARD_WIDTH} from './local-pack-leaderboard-table-helper';
import * as styles from './local-pack-leaderboard-table.scss';

type PropsType = {
    filter: LocalPackFilterType;
};

export function LocalPackLeaderboardTable(props: PropsType): JSX.Element {
    const {filter} = props;
    const {data, isLoading, pagination, updateSorter} = useLocalPackLeaderboard(filter);

    const systemContext = useSystem();
    const {screen} = systemContext;
    const {width} = screen;

    function onTableChange(
        newPagination: TablePaginationConfig,
        _filters: unknown,
        sorter: SorterResult<LocalPackLeaderboardItemType> | Array<SorterResult<LocalPackLeaderboardItemType>>
    ) {
        if (pagination.page === newPagination.current && pagination.pageSize === newPagination.pageSize) {
            const firstSortingField = Array.isArray(sorter) ? sorter?.[0] : sorter;

            updateSorter({
                sorterField: firstSortingField?.field,
                sorterDirection: firstSortingField?.order,
            });
            pagination.onChange(1, newPagination.pageSize);
            return;
        }

        pagination.onChange(newPagination.current || 1, newPagination.pageSize);
    }

    return (
        <PageCard title={<Locale stringKey="LOCAL_PACK__LEADERBOARD__TABLE_TITLE" />}>
            <Table<LocalPackLeaderboardItemType>
                className={styles.LocalPackLeaderboardTable}
                columns={getLocalPackLeaderboardColumns(width)}
                dataSource={data?.results || []}
                loading={isLoading}
                onChange={onTableChange}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: data?.count || 0,
                    onChange: pagination.onChange,
                }}
                rowKey={(entry) => entry.id}
                scroll={{
                    x: LOCAL_PACK_LEADER_BOARD_WIDTH,
                }}
                sticky={{
                    offsetHeader: Number.parseFloat(header_height),
                }}
            />
        </PageCard>
    );
}
