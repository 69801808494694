import {DomainNameEnum} from '../../../../../../provider/domain-config/domain-config-type';
import {ShortLocaleNameEnum} from '../../../../../../provider/locale/locale-context-type';

export function getSampleFeedUrl(locale: ShortLocaleNameEnum, domainName: string): string {
    if (domainName === DomainNameEnum.brandWizard) {
        return 'https://go.brandwizard.io/static/prices/products_sample_yml_en.xml';
    }

    if (locale === ShortLocaleNameEnum.ru) {
        return 'https://go.rocketdata.io/static/prices/products_sample_yml_ru.xml';
    }

    return 'https://go.rocketdata.io/static/prices/products_sample_yml_en.xml';
}
