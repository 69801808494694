import {Empty, Table} from 'antd';
import {useMemo} from 'react';

import {DoughnutChart} from '../../../../../../../layout/chart/doughnut-chart/doughnut-chart';
import {DoughnutDataType} from '../../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {ReviewsAnalysisTagBySentimentType} from '../../../../../../../service/reivew-analysis/review-analytics-tags/review-analytics-tags-type';
import {KeywordsChartLegend} from '../chart-legend/keywords-chart-legend';

import {KEYWORDS_DOUGHNUTCHART_COLORS, topKeywordsTableColumns} from './keywords-doughnut-chart-const';
import {DoughnutTableDataType} from './keywords-doughnut-chart-type';
import * as styles from './keywords-doughnut-chart.scss';

type PropsType = {
    data: Array<ReviewsAnalysisTagBySentimentType>;
    total: number;
    isInProgress: boolean;
};

export function KeywordsDoughnutChart(props: PropsType): JSX.Element {
    const {data, isInProgress, total} = props;

    const {getLocalizedString} = useLocale();

    const doughnutChartData: Array<DoughnutDataType> = useMemo(() => {
        const topKeywords = data?.slice(0, 4);

        if (topKeywords && topKeywords.length > 0) {
            const topKeywordsSum = topKeywords.reduce<number>(
                (accum: number, keyword: ReviewsAnalysisTagBySentimentType): number => accum + keyword.total,
                0
            );

            return [
                ...topKeywords.map((keyword, index) => ({
                    label: keyword.tag,
                    value: keyword.total,
                    color: KEYWORDS_DOUGHNUTCHART_COLORS[index] || '',
                    key: keyword.tag + index,
                })),
                {
                    label: getLocalizedString('REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__DOUGNUT_CHART_ANOTHER'),
                    value: total > topKeywordsSum ? total - topKeywordsSum : 0,
                    color: KEYWORDS_DOUGHNUTCHART_COLORS[4] || '',
                    key: 'another',
                },
            ];
        }

        return [];
    }, [data, getLocalizedString, total]);

    const doughnutChartTableData: Array<DoughnutDataType | DoughnutTableDataType> = useMemo(
        () => [
            ...doughnutChartData,
            {
                label: (
                    <span className={styles.KeywordsDoughnutChart_tableTotal}>
                        <Locale stringKey="REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__DOUGNUT_CHART_TOTAL" />
                    </span>
                ),
                value: total,
                key: 'total',
            },
        ],
        [doughnutChartData, total]
    );

    if (doughnutChartData.length === 0) {
        return (
            <div className={styles.KeywordsDoughnutChartEmpty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
        );
    }

    return (
        <div className={styles.KeywordsDoughnutChart}>
            <Spinner isShow={isInProgress} position="absolute" />
            <KeywordsChartLegend
                items={doughnutChartData.map((item) => ({
                    label: item.label,
                    color: item.color,
                }))}
            />
            <div className={styles.KeywordsDoughnutChart_content}>
                <div className={styles.KeywordsDoughnutChart_chartWrapper}>
                    <div className={styles.KeywordsDoughnutChart_chart}>
                        <DoughnutChart chartOptions={{cutout: '30%'}} data={doughnutChartData} />
                    </div>
                </div>
                <div className={styles.KeywordsDoughnutChart_legend}>
                    <Table<DoughnutDataType | DoughnutTableDataType>
                        columns={topKeywordsTableColumns}
                        dataSource={doughnutChartTableData}
                        pagination={false}
                        rowKey="label"
                        size="small"
                    />
                </div>
            </div>
        </div>
    );
}
