import {useEffect, useMemo, useState} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    PostFormModeEnum,
    PostFormType,
    PostImageType,
    PostVideoType,
    SourcePostFormFieldsEnum,
} from '../../../../../../../../service/posts/posts-types';
import {Form, FormInstance} from '../../../../../../../../typings/antd';
import {classNames} from '../../../../../../../../util/css';
import {FACEBOOK_POST_SETTINGS} from '../../content-step-helper';
import {ImagesItem} from '../../items/media/image/images-item';
import {VideoItem} from '../../items/media/video/video-item';

import {FacebookMediaModeEnum} from './facebook-media-type';
import * as styles from './facebook-media-items.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    facebookNamePath: Array<string | number>;
    formMode: PostFormModeEnum;
    onHasMediaChanged: (hasMedia: boolean) => void;
};

// eslint-disable-next-line complexity
export function FacebookMediaItems(props: PropsType): JSX.Element {
    const {facebookNamePath, formInstance, formMode, onHasMediaChanged} = props;

    const isViewMode = formMode === PostFormModeEnum.View;
    const isVideoDisabled = formMode === PostFormModeEnum.View || formMode === PostFormModeEnum.EditExisted;

    const mediaType: FacebookMediaModeEnum = useMemo(() => {
        const initialVideoValue = formInstance.getFieldValue([...facebookNamePath, SourcePostFormFieldsEnum.Video]);

        if (formMode === PostFormModeEnum.EditExisted) {
            return initialVideoValue ? FacebookMediaModeEnum.Video : FacebookMediaModeEnum.Images;
        }

        return FacebookMediaModeEnum.Unspecified;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);
    const [isUploadingImages, setIsUploadingImages] = useState<boolean>(false);

    const images: Array<PostImageType> | undefined = Form.useWatch(
        [...facebookNamePath, SourcePostFormFieldsEnum.Images],
        formInstance
    );
    const video: PostVideoType | null | undefined = Form.useWatch(
        [...facebookNamePath, SourcePostFormFieldsEnum.Video],
        formInstance
    );

    useEffect(() => {
        const hasMediaItems = Boolean((images && images?.length > 0) || isUploadingImages || video || isUploadingVideo);

        onHasMediaChanged(hasMediaItems);
    }, [images, isUploadingImages, isUploadingVideo, onHasMediaChanged, video]);

    if (mediaType === FacebookMediaModeEnum.Images || (images && images.length > 0 && !isUploadingImages)) {
        return (
            <ImagesItem
                disabled={isViewMode}
                fieldName={[...facebookNamePath, SourcePostFormFieldsEnum.Images]}
                formInstance={formInstance}
                key="imagesUpload"
                settings={FACEBOOK_POST_SETTINGS.images}
            />
        );
    }

    if (mediaType === FacebookMediaModeEnum.Video || video) {
        return (
            <VideoItem
                disabled={isVideoDisabled}
                fieldName={[...facebookNamePath, SourcePostFormFieldsEnum.Video]}
                key="videoUpload"
            />
        );
    }

    return (
        <div className={classNames(styles.FacebookMedia)}>
            <Text className={styles.FacebookMedia_label}>
                <Locale stringKey="POSTS_FORM__MEDIA" />
                &nbsp;
                <AdditionalInfo title={<Locale stringKey="POSTS_FORM__MEDIA" />}>
                    <Locale stringKey="POSTS_FORM__MEDIA__TOOLTIP__POPOVER__BODY" />
                </AdditionalInfo>
            </Text>
            <div>
                {!isUploadingVideo && (
                    <ImagesItem
                        disabled={isViewMode}
                        fieldName={[...facebookNamePath, SourcePostFormFieldsEnum.Images]}
                        formInstance={formInstance}
                        hideLabel
                        key="imagesUpload"
                        onUploadingStatusChanged={setIsUploadingImages}
                        settings={FACEBOOK_POST_SETTINGS.images}
                    />
                )}
                {!isUploadingImages && !isUploadingVideo && (
                    <Text
                        block
                        className={classNames(styles.FacebookMedia_dividerText, {
                            [styles.FacebookMedia_dividerText__disabled]: isViewMode,
                        })}
                        secondary
                    >
                        <Locale stringKey="TEXT__OR" />
                    </Text>
                )}
                {!isUploadingImages && (
                    <VideoItem
                        disabled={isVideoDisabled}
                        fieldName={[...facebookNamePath, SourcePostFormFieldsEnum.Video]}
                        hideLabel
                        key="videoUpload"
                        onUploadingStatusChanged={setIsUploadingVideo}
                        uploadButtonClassName={styles.FacebookMedia_videoUploadButton}
                    />
                )}
            </div>
        </div>
    );
}
