import {Button, Input} from 'antd';

import {Page} from '../../../layout/page/page';
import {Locale} from '../../../provider/locale/locale';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {useVerifyCodeMutation, VerifyCodeFormEnum, VerifyCodeFormType} from '../../../service/user/user-verify-code';
import {Form} from '../../../typings/antd';
import {AuthFormFooter} from '../common/form-footer/auth-form-footer';
import {AuthFormHeader} from '../common/form-header/auth-form-header';

import {initialValues} from './two-factor-authentication-const';

export function TwoFactorAuthentication(): JSX.Element {
    const [form] = Form.useForm<VerifyCodeFormType>();
    const {requiredFieldRule} = useFormRules();
    const code = Form.useWatch(VerifyCodeFormEnum.Code, form);

    const {mutate, isLoading} = useVerifyCodeMutation();

    const disabled = !code || isLoading;

    return (
        <Page.CenteredCard titleLangKey="PAGE__LOGIN__LOGIN_FORM__HEADER">
            <AuthFormHeader
                subtitle={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__SUB_HEADER_TWO_FACTOR_AUTHENTICATION" />}
                title={<Locale stringKey="PAGE__LOGIN__LOGIN_FORM__HEADER" />}
            />

            <Form<VerifyCodeFormType> form={form} initialValues={initialValues} layout="vertical" onFinish={mutate}>
                <Form.Item<VerifyCodeFormType>
                    label={<Locale stringKey="PAGE__TWO_FACTOR_AUTHENTICATION_ENTER_SIX_DIGIT_CODE" />}
                    name={VerifyCodeFormEnum.Code}
                    rules={[requiredFieldRule]}
                >
                    <Input autoComplete="off" autoFocus placeholder="000000" size="large" />
                </Form.Item>

                <Button block disabled={disabled} htmlType="submit" loading={isLoading} size="large" type="primary">
                    <Locale stringKey="PAGE__LOGIN__LOGIN_FORM__BUTTON__SIGN_IN" />
                </Button>
            </Form>

            <AuthFormFooter />
        </Page.CenteredCard>
    );
}
