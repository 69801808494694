import {Button, Empty} from 'antd';

import {appRoute} from '../../../../app-route';
import {Text} from '../../../../component/text/text';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../provider/locale/locale';

import * as styles from './empty-body.scss';

type PropsType = {
    setIsModalOpen: (open: boolean) => void;
};

export function EmptyBody(props: PropsType): JSX.Element {
    const {setIsModalOpen} = props;

    function handleOpenModal(): void {
        setIsModalOpen(true);
    }

    return (
        <div className={styles.EmptyBody}>
            <Empty
                className={styles.EmptyBody_content}
                description={
                    <Text
                        className={styles.EmptyBody_description}
                        stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__PLACEHOLDER"
                    />
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{height: 117}}
            >
                <Button className={styles.EmptyBody_button} ghost onClick={handleOpenModal} type="primary">
                    <Locale stringKey="BULK_EDIT_COMPANIES__CHOOSE_FIELDS__CHOOSE_BUTTON" />
                </Button>

                <Button type="default">
                    <NavigationLink to={appRoute.myCompanies.path}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </NavigationLink>
                </Button>
            </Empty>
        </div>
    );
}
