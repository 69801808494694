import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Text} from '../../text/text';

import {LandingAdvantagesCardPropsType} from './landing-advantages-type';
import * as styles from './landing-advantages.scss';

export function LandingAdvantagesCard(props: LandingAdvantagesCardPropsType): JSX.Element {
    const {icon, titleLangKey, descriptionLangKey} = props;

    return (
        <div className={styles.LandingAdvantages_card}>
            <div className={styles.LandingAdvantages_cardTitle}>
                <div className={styles.LandingAdvantages_iconWrapper}>
                    <FontAwesomeIcon icon={icon} />
                </div>

                <Text block bolder large stringKey={titleLangKey} />
            </div>

            <Text block lighter stringKey={descriptionLangKey} />
        </div>
    );
}
