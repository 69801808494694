import {Table, TablePaginationConfig} from 'antd';
import {useEffect, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Empty} from '../../../../layout/empty/empty';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/localization';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useLocalPackListQueryResults} from '../../../../service/local-pack/local-pack-hook';
import {
    LocalPackQueryResultFullValueResponseType,
    LocalPackQueryResultResponseType,
    LocalPackSearchServiceType,
} from '../../../../service/local-pack/local-pack-type';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {defaultPaginationState} from '../../../../util/hook';
import {objectToUrlParameters} from '../../../../util/url';
import * as localPackStyles from '../local-pack.scss';
import {
    LocalPackFilterType,
    LocalPackFilterUrlQueryNameEnum,
    LocalPackParametersType,
} from '../local-pack-filter/local-pack-filter-type';

import {
    getLocalPackAllServiceResult,
    getLocalPackQueryResultsTableColumns,
    getLocalPackQueryResultsTableValueRow,
} from './query-results-table-helper';

type PropsType = {
    searchServices: Array<LocalPackSearchServiceType>;
    filter: LocalPackFilterType;
};

export function LocalPackQueryResultsTable(props: PropsType): JSX.Element {
    const {getFormattedDateTime, getFormattedNumber} = useFormat();
    const {searchServices, filter} = props;
    const [parameters, setParameters] = useState<LocalPackParametersType>({
        pagination: defaultPaginationState,
        filter,
    });
    const {pagination} = parameters;

    useEffect(() => {
        setParameters({
            ...parameters,
            pagination: {
                ...pagination,
                current: 1,
            },
            filter,
        });
    }, [filter]);

    const {result, isInProgress, processError} = useLocalPackListQueryResults({
        paginationOptions: {count: Number(pagination.pageSize), page: Number(pagination.current)},
        filter: parameters.filter,
    });

    if (isInProgress) {
        return <Spinner />;
    }

    if (processError || !result) {
        return <AlertFallback />;
    }

    function handlePaginationChange(newPagination: TablePaginationConfig) {
        setParameters({
            ...parameters,
            pagination: newPagination,
        });

        if (pagination.current !== newPagination.current) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, newPagination.current ?? 1);
        }
    }

    const localPackQueryResultsColumns = getLocalPackQueryResultsTableColumns(searchServices);

    const tableData = result.results.map((resultRow: LocalPackQueryResultResponseType) => {
        const rowData: Record<string, string | JSX.Element> = {
            key: `${resultRow.date}-${resultRow.searchQueryId}`,
            keyColumn: (
                <NavigationLink
                    allowedSearchParameters={Object.values(LocalPackFilterUrlQueryNameEnum).filter(
                        (queryName) => queryName !== LocalPackFilterUrlQueryNameEnum.searchQueries
                    )}
                    to={`${appRoute.localPackSearchPhrasesStatistics.path}?${objectToUrlParameters({
                        [LocalPackFilterUrlQueryNameEnum.searchQueries]: resultRow.searchQueryId,
                    })}`}
                >
                    {resultRow.searchQuery}
                </NavigationLink>
            ),
            date: getFormattedDateTime(new Date(resultRow.date), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }),
            allServices: getLocalPackQueryResultsTableValueRow({
                positions: getLocalPackAllServiceResult(searchServices, resultRow.values),
                getFormattedNumber,
            }),
        };

        resultRow.values.forEach((value: LocalPackQueryResultFullValueResponseType) => {
            rowData[value.service] = getLocalPackQueryResultsTableValueRow({
                positions: value,
                getFormattedNumber,
            });
        });

        return rowData;
    });

    return (
        <PageCard title={<Locale stringKey="LOCAL_PACK__QUERY_RESULTS_TABLE__TITLE" />}>
            <Table
                columns={localPackQueryResultsColumns}
                dataSource={tableData}
                loading={isInProgress}
                locale={{
                    emptyText: (
                        <Empty
                            secondaryText="GOOGLE_SYNC__COMPANIES_STEP__FILTER__EMPTY"
                            secondaryTextClassName={localPackStyles.emptyText}
                        />
                    ),
                }}
                onChange={handlePaginationChange}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: result.count,
                }}
                scroll={{x: true}}
                size="middle"
            />
        </PageCard>
    );
}
