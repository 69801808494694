import {DocumentationLinksType} from '../../../../../../provider/help-links/help-links-type';
import {Locale} from '../../../../../../provider/locale/localization';
import {GoogleAccountTypeEnum} from '../../../../../../service/google/accounts/google-accounts-type';
import {TupleType} from '../../../../../../util/type';
import {TipType} from '../../tips/tips-type';

import {AccountFilterEnum, AccountFilterType} from './accounts-step-type';

export const googleAccountFilters: TupleType<AccountFilterType, 3> = [
    {
        value: AccountFilterEnum.All,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__FILTER__ALL" />,
    },
    {
        value: AccountFilterEnum.Group,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TAG__LOCATION_GROUP" />,
        accountType: GoogleAccountTypeEnum.LocationGroup,
    },
    {
        value: AccountFilterEnum.Personal,
        label: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__FILTER__PERSONAL" />,
        accountType: GoogleAccountTypeEnum.Personal,
    },
];

export const noPersonalAccountTips: Array<TipType> = [
    {
        title: 'GOOGLE_SYNC__ACCOUNTS_STEP__TIP__NO_ACCOUNT__TITLE',
        text: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__NO_ACCOUNT__TEXT" />,
    },
];

export function getPersonalAccountTips(documentationLinks: DocumentationLinksType): Array<TipType> {
    return [
        {
            title: 'GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__TITLE',
            text: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__TEXT" />,
            ...(documentationLinks.google.addressGroup
                ? {
                      link: {
                          text: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__LINK" />,
                          href: documentationLinks.google.addressGroup,
                      },
                  }
                : null),
        },
        {
            title: 'GOOGLE_SYNC__ACCOUNTS_STEP__TIP__MANAGER__TITLE',
            text: <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__MANAGER__TEXT" />,
            ...(documentationLinks.google?.addressGroupAdmin && {
                link: {href: documentationLinks.google?.addressGroupAdmin},
            }),
        },
    ];
}
