import {Flex} from 'antd';
import {useCallback} from 'react';

import {Text} from '../../../../component/text/text';
import {spacer_m} from '../../../../css/var-export.scss';
import {AdditionalInfo} from '../../../../layout/additional-info/additional-info';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {UnansweredReviewsCountType} from '../../../../service/reviews-and-answers-statistics/unanswered-reviews-count/unanswered-reviews-count-type';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {CompareModeDelta} from '../compare-mode-delta/compare-mode-delta';

import * as styles from './reviews-without-answer.scss';

type PropsType = {
    unansweredReviewsCount: UnansweredReviewsCountType | null;
    isLoading?: boolean;
    isCompareMode: boolean;
};

export function ReviewsWithoutAnswer(props: PropsType): JSX.Element {
    const {unansweredReviewsCount, isLoading, isCompareMode} = props;

    const {getFormattedNumber} = useFormat();

    const formatValue = useCallback(
        (value: number) => {
            return `${getFormattedNumber(value, {
                maximumFractionDigits: 2,
            })}%`;
        },
        [getFormattedNumber]
    );

    return (
        <PageCard
            className={styles.ReviewsWithoutAnswer}
            title={
                <Flex className={styles.ReviewsWithoutAnswer_header} justify="space-between">
                    <Text block bolder large stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITHOUT_ANSWERS__TITLE" />
                    <AdditionalInfo>
                        <Flex gap={spacer_m} vertical>
                            <Text
                                block
                                bolder
                                stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITHOUT_ANSWERS__TOOLTIP_TITLE"
                            />
                            <Text
                                block
                                stringKey="REVIEW_AND_ANSWER_STATISTICS__REVIEWS_WITHOUT_ANSWERS__TOOLTIP_DESCRIPTION"
                            />
                        </Flex>
                    </AdditionalInfo>
                </Flex>
            }
        >
            {isLoading ? (
                <Spinner />
            ) : isCompareMode ? (
                <CompareModeDelta
                    compareValue={formatValue(unansweredReviewsCount?.percentage?.comparedValue || 0)}
                    delta={unansweredReviewsCount?.percentage?.delta || 0}
                    value={formatValue(unansweredReviewsCount?.percentage?.value || 0)}
                />
            ) : (
                <Text block bolder className={styles.ReviewsWithoutAnswer_value}>
                    {formatValue(unansweredReviewsCount?.percentage?.value || 0)}
                </Text>
            )}
        </PageCard>
    );
}
