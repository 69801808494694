import {
    FormattedPeriodsType,
    OnlinePresenceDashboardDataCommonType,
} from '../../../../../../service/online-presence/online-presence-type';
import {CompareTable} from '../../../compare-table/compare-table';
import {SinglePeriodStatsTable} from '../../../single-period-stats/single-period-stats-table';
import {sortActionDynamicsValues, targetActionsTableLangKeyMap} from '../../target-actions-dynamics-helper';

type PropsType = {
    data: OnlinePresenceDashboardDataCommonType['actionsDynamic'];
    isCompareMode: boolean;
    formattedPeriods?: FormattedPeriodsType;
};

export function TargetActionsTable(props: PropsType): JSX.Element {
    const {data, isCompareMode, formattedPeriods} = props;

    return isCompareMode ? (
        <CompareTable
            formattedPeriods={formattedPeriods}
            metricsNameHeadKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__ACTION"
            rows={data.table.sort(sortActionDynamicsValues).map((dataItem) => {
                return {
                    titleKey: targetActionsTableLangKeyMap[dataItem.label],
                    currentValue: dataItem.current,
                    comparePeriodValue: dataItem.compared,
                    delta: dataItem.delta,
                };
            })}
        />
    ) : (
        <SinglePeriodStatsTable
            metricsNameHeadKey="ONLINE_PRESENCE_ANALYTICS__PRIMARY_MARKERS__ACTION"
            rows={data.table.sort(sortActionDynamicsValues).map((dataItem) => {
                return {
                    titleKey: targetActionsTableLangKeyMap[dataItem.label],
                    value: dataItem.current,
                };
            })}
        />
    );
}
