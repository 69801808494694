import {useEffect, useState} from 'react';

import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {CountryV2Type, IdNameType} from '../../../../../../service/api/api-type';
import {useSearchCityList} from '../../../../../../service/api-hook/api-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../../typings/antd';

import {CountryIdEnum} from './address-widget-const';
import {buildFullSearchQuery} from './build-query-helper';
import {AddressInputType, SelectLocation} from './select-location/select-location';

type PropsType = {
    value?: IdNameType | string | null;
    onChange?: (value?: IdNameType | string | null) => void;
    country: CountryV2Type | null;
    region: IdNameType | null;
    onAfterChange: (value?: IdNameType | string | null) => void;
};

export function SelectCity(props: PropsType): JSX.Element {
    const {value, onChange, country, region, onAfterChange} = props;

    const {getLocalizedString} = useLocale();
    const [searchQuery, setSearchQuery] = useState('');
    const {isInProgress, result} = useSearchCityList(searchQuery || null, region?.id, country?.lang);

    const form = Form.useFormInstance<CompanyFormType>();
    const formCity = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'city'>([
        CompanyKeyEnum.Address,
        'city',
    ]);

    const results: Array<IdNameType> = result?.results || [];
    const isRussiaOrBelarusSelected = Boolean(
        country && [CountryIdEnum.Russia, CountryIdEnum.Belarus].includes(country.id)
    );

    useEffect(() => {
        if (!formCity) {
            setSearchQuery('');
        }
    }, [formCity]);

    function handleChange(newValue?: AddressInputType | null) {
        onChange?.(newValue);
        onAfterChange(newValue);

        // There's a similar code inside SelectLocation#handleSuggestChange, but it
        // depends on /geocoding/direct/ and can't really be reused
        if (newValue && typeof newValue !== 'string' && 'lat' in newValue) {
            form.setFieldsValue({
                address: {latLng: [newValue.lat, newValue.lon]},
            });
        }
    }

    return (
        <SelectLocation
            addressKey="locality"
            country={country}
            isDisabled={!country || (!region && isRussiaOrBelarusSelected)}
            isInProgress={isInProgress}
            isRequired={false}
            isSelectRequired={false}
            label={<Locale stringKey="COMPANY_FORM__ADDRESS__CITY__LABEL" />}
            onChange={handleChange}
            placeholder={getLocalizedString('COMPANY_FORM__ADDRESS__CITY__PLACEHOLDER')}
            searchQuery={searchQuery}
            searchQueryList={buildFullSearchQuery(country, region)}
            selectItems={results}
            setSearchQuery={setSearchQuery}
            value={value}
        />
    );
}
