import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {faUpload} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover, Upload} from 'antd';
import {ItemRender, UploadFile} from 'antd/lib/upload/interface';
import {UploadRequestOption} from 'rc-upload/lib/interface';
import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';

import {AntdErrorMessage} from '../../../../../../component/antd-error-message/antd-error-message';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useCompanyMediaFile} from '../../../../../../service/company/company-hook';
import {
    BulkEditFieldNameEnum,
    CompanyImageKindEnum,
    CompanyMediaFileType,
    CompanyUploadMediaFileErrorType,
} from '../../../../../../service/company/company-type';
import {convertFileToBase64} from '../../../../../../util/image';
import {InfoForImage} from '../../../../company-form/page-container/info-for-image/info-for-image';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import {acceptGalleryImages} from './gallery-form-item-v2-const';
import * as styles from './gallery-form-item.scss';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.gallery>;

export function GalleryFormItemV2(props: PropsType): JSX.Element {
    const {value, onChange, errorMessage} = props;

    const {uploadCompanyMediaFile} = useCompanyMediaFile();

    const {getLocalizedString} = useLocale();

    const [uploadStarts, setUploadStarts] = useState<number>(0);
    const [uploadEnds, setUploadEnds] = useState<number>(0);

    const isInProgress: boolean = useMemo(() => {
        return uploadStarts !== uploadEnds;
    }, [uploadStarts, uploadEnds]);

    const [fileList, setFileList] = useState<Array<UploadFile<unknown>>>(
        value.map((item: CompanyMediaFileType) => {
            const uploadFile: UploadFile<unknown> = {
                name: '',
                uid: String(item.id),
                url: item.file_s3,
            };

            return uploadFile;
        })
    );

    const onRemove = useCallback(
        (file: UploadFile<unknown>) => {
            setFileList(fileList.filter((item) => item !== file));
        },
        [fileList]
    );

    useEffect(() => {
        if (uploadEnds !== 0 && !isInProgress) {
            onChange(
                fileList.map((imageItem: UploadFile<unknown>) => {
                    if (imageItem.error) {
                        const imageWithError: CompanyMediaFileType = {
                            file_s3: '',
                            file_type: CompanyImageKindEnum.gallery,
                            hasError: true,
                            id: 0,
                            temp_id: '',
                        };

                        return imageWithError;
                    }

                    const valueItem: CompanyMediaFileType = {
                        file_s3: imageItem.url || '',
                        file_type: CompanyImageKindEnum.gallery,
                        hasError: false,
                        id: imageItem.uid,
                        temp_id: imageItem.uid,
                    };

                    return valueItem;
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList, uploadEnds, isInProgress]);

    const beforeUpload = useCallback(() => {
        setUploadStarts((previousState) => {
            return previousState + 1;
        });
    }, [setUploadStarts]);

    const uploadMainImageCustomRequest = useCallback(
        async (options: UploadRequestOption<unknown>) => {
            try {
                if (!(options.file instanceof File)) {
                    return;
                }

                const uploadResponse = await uploadCompanyMediaFile({
                    file: options.file,
                    file_type: CompanyImageKindEnum.gallery,
                });

                const imageSrc = await convertFileToBase64(options.file);

                setFileList((previousState) => {
                    return [...previousState, {name: '', uid: uploadResponse.temp_id, url: imageSrc}];
                });
            } catch (error) {
                const elementId = Math.random();

                let uploadErrorMessage = '';

                try {
                    uploadErrorMessage =
                        error instanceof Error
                            ? (JSON.parse(error.message) as CompanyUploadMediaFileErrorType).file_s3.join(' ')
                            : getLocalizedString('BULK_EDIT_COMPANIES__IMAGE_UPLOAD_ERROR__UNKNOWN_ERROR');
                } catch {
                    uploadErrorMessage = getLocalizedString('BULK_EDIT_COMPANIES__IMAGE_UPLOAD_ERROR__UNKNOWN_ERROR');
                }

                setFileList((previousState) => {
                    return [
                        ...previousState,
                        {
                            name: '',
                            uid: String(elementId),
                            error: uploadErrorMessage,
                            url: URL.createObjectURL(options.file as File),
                        },
                    ];
                });
            } finally {
                setUploadEnds((previousState) => {
                    return previousState + 1;
                });
            }
        },
        [uploadCompanyMediaFile, getLocalizedString]
    );

    const itemRenderer: ItemRender = useCallback(
        (originNode: ReactElement<unknown>, file: UploadFile<unknown>) => {
            if (file.error) {
                return (
                    <Popover
                        content={file.error}
                        title={getLocalizedString('BULK_EDIT_COMPANIES__IMAGE_UPLOAD_ERROR__TITLE')}
                        trigger="hover"
                    >
                        <div className={styles.gallery__item__error}>{originNode}</div>
                    </Popover>
                );
            }

            return originNode;
        },
        [getLocalizedString]
    );

    return (
        <>
            <InfoForImage
                fileFormats="COMPANY_FORM__GALLERY__HINT__FILE_FORMATS"
                label="COMPANY_FORM__GALLERY__LABEL"
                maxResolution="COMPANY_FORM__GALLERY__HINT__MAX_RESOLUTION"
                maxSize="COMPANY_FORM__GALLERY__HINT__MAX_SIZE"
                minResolution="COMPANY_FORM__GALLERY__HINT__MIN_RESOLUTION"
                minSize="COMPANY_FORM__GALLERY__HINT__MIN_SIZE"
                title="COMPANY_FORM__GALLERY__HINT__HEADER"
            />
            <Upload
                accept={acceptGalleryImages}
                beforeUpload={beforeUpload}
                className={styles.gallery__upload}
                customRequest={uploadMainImageCustomRequest}
                disabled={isInProgress}
                fileList={fileList}
                itemRender={itemRenderer}
                listType="picture-card"
                multiple
                onRemove={onRemove}
                showUploadList={{
                    showPreviewIcon: false,
                }}
            >
                <div>
                    {isInProgress ? (
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                    ) : (
                        <FontAwesomeIcon icon={faUpload} />
                    )}
                </div>
            </Upload>
            {!isInProgress && errorMessage && <AntdErrorMessage>{errorMessage}</AntdErrorMessage>}
        </>
    );
}
