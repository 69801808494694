// This module is used to hide features feature client, if the feature is in progress.
// However, devs and testers can enable it over local storage flag.
import {z as r} from 'zod';

import {getFromLocalStorage} from '../local-storage';

import {npsLocalStorageKey, phrasesLocalStorageKey} from './feature-toggle-const';
import {FeatureToggleKeyType} from './feature-toggle-type';

function isFeatureEnabled(key: FeatureToggleKeyType): boolean {
    return getFromLocalStorage<boolean>(key, r.boolean().optional(), false);
}

export function isReviewPhrasesFeatureEnabled(): boolean {
    return isFeatureEnabled(phrasesLocalStorageKey);
}

export function isNpsFeatureEnabled(): boolean {
    return isFeatureEnabled(npsLocalStorageKey);
}
