import {Key} from 'react';

import {ReviewsAnalysisModeEnum} from '../../../service/reivew-analysis/reivew-analysis-const';
import {ReviewAnalyticsEmployeeType} from '../../../service/reivew-analysis/review-employees/review-employees-type';
import {ReviewPhraseType} from '../../../service/reivew-analysis/review-phrases/review-phrases-type';
import {ReviewRateEnum} from '../../../service/reivew-analysis/review-rate/review-rate-type';
import {SentimentEnum} from '../../../service/reivew-analysis/review-sentiment/review-sentiment-type';

import {KeywordsAndTopicsFormTypeAggregationEnum} from './pages/keywords-and-topics-page/keywords-and-topics-form/keywords-and-topics-type';

export enum ReviewsCompareEnum {
    OFF = 'OFF',
    PERIOD = 'PERIOD',
    COMPETITOR = 'COMPETITOR',
}

export enum ReviewsAnalysisFilterEnum {
    Range = 'rwa-time-range',
    CompareRange = 'rwa-compare-range',
    Competitor = 'rwa-competitor',
    Sentiments = 'rwa-sentiments',
    Catalogs = 'rwa-catalogs',
    Tags = 'rwa-tags',
    Phrases = 'rwa-phrases',
    Topics = 'rwa-topics',
    Rates = 'rwa-rates',
    WithMessage = 'rwa-with-message',
    WithReply = 'rwa-with-reply',
    AbleToReply = 'rwa-able-to-reply',
    Aggregation = 'group-by',
}

export const concordanceQueryName = 'rwa-cncrdnce';
export const concordanceRangeName = 'rwa-time-range';
export const concordanceContextSizeName = 'rwa-context-size';

export type ReviewsAnalysisFilterType = {
    [ReviewsAnalysisFilterEnum.Range]: [Date | null, Date | null];
    [ReviewsAnalysisFilterEnum.CompareRange]: [Date | null, Date | null];
    [ReviewsAnalysisFilterEnum.Competitor]: number | null;
    [ReviewsAnalysisFilterEnum.Sentiments]: Array<SentimentEnum>;
    [ReviewsAnalysisFilterEnum.Catalogs]: Array<number>;
    [ReviewsAnalysisFilterEnum.Tags]: Array<string>;
    [ReviewsAnalysisFilterEnum.Phrases]: Array<string>;
    [ReviewsAnalysisFilterEnum.Topics]: Array<string>;
    [ReviewsAnalysisFilterEnum.Rates]: Array<ReviewRateEnum>;
    [ReviewsAnalysisFilterEnum.WithMessage]: boolean | null;
    [ReviewsAnalysisFilterEnum.WithReply]: boolean | null;
    [ReviewsAnalysisFilterEnum.AbleToReply]: boolean | null;
    [ReviewsAnalysisFilterEnum.Aggregation]?: KeywordsAndTopicsFormTypeAggregationEnum;
};

export type ConcordanceQueryType = {
    [concordanceQueryName]: string;
};

export type UseReviewsAnalysisFilterType = {
    filter: ReviewsAnalysisFilterType;
    singleFilter: SingleFilterRequestType;
    comparePeriodFilter: ComparePeriodFilterRequestType;
    competitorFilter: CompetitorFilterRequestType;
    setFilter: (filterState: Partial<ReviewsAnalysisFilterType>) => void;
    queries: Record<ReviewsAnalysisFilterEnum, string | void>;
    compareMode: ReviewsCompareEnum;
};

export type EmployeeTableDataType = ReviewAnalyticsEmployeeType & {
    key: Key;
};

export type PhrasesTableDataType = ReviewPhraseType & {
    key: Key;
};

export type SingleFilterRequestType = {
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    userTopics?: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
    ableToReply: boolean | null;
};

export type ComparePeriodFilterRequestType = SingleFilterRequestType;

export type CompetitorFilterRequestType = SingleFilterRequestType & {
    brandIds: Array<number>;
    mode: ReviewsAnalysisModeEnum;
};
