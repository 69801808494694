import {Table} from 'antd';
import {useEffect} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../component/text/text';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLastPosts} from '../../../../service/posts/posts-hooks';
import {LastPostsType, PostStatusEnum} from '../../../../service/posts/posts-types';
import {WidgetFooterText} from '../components/widget-footer-text/widget-footer-text';

import {lastPostsTableColumnList, LASTS_POSTS_LIMIT} from './dashboard-posts-const';
import {PostsEmpty} from './empty/posts-empty';
import {DashboardLastPostsStatistics} from './statistics/dashboard-last-posts-statistics';
import * as styles from './dashboard-posts.scss';

export function DashboardPosts(): JSX.Element {
    const {getLastPosts, result: posts, isInProgress, processError} = useLastPosts();

    useEffect(() => {
        getLastPosts();
    }, [getLastPosts]);

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !posts) {
        return (
            <div className={styles.DashboardPosts_spinner}>
                <Spinner />
            </div>
        );
    }

    return (
        <div className={styles.DashboardPosts}>
            {posts.length > 0 ? (
                <>
                    <DashboardLastPostsStatistics count={posts.length} />

                    <Text block bolder secondary small stringKey="DASHBOARD_PAGE__POSTS__SUBTITLE" />

                    <Table<LastPostsType>
                        columns={lastPostsTableColumnList}
                        dataSource={posts?.slice(0, LASTS_POSTS_LIMIT) || []}
                        pagination={false}
                        rowClassName={(post) => {
                            return post.status === PostStatusEnum.Deleted ? styles.DashboardPosts_post__deleted : '';
                        }}
                        rowKey={(post) => post.id}
                        scroll={{x: true}}
                        size="small"
                    />

                    <WidgetFooterText
                        linkHref={appRoute.postsManagement.path}
                        linkLangKey="CATEGORY_NAME__POSTS"
                        textLangKey="DASHBOARD_PAGE__POSTS__EXPLANATION"
                    />
                </>
            ) : (
                <PostsEmpty />
            )}
        </div>
    );
}
