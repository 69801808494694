import {faFileLines, faSquareList} from '@fortawesome/pro-regular-svg-icons';
import {faCode} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {LangKeyType} from '../../../provider/locale/translation/type';
import {ProductGroupTypeEnum} from '../../../service/products/products-type';

export const productGroupTypeIconMap: Record<ProductGroupTypeEnum, JSX.Element> = {
    [ProductGroupTypeEnum.File]: <FontAwesomeIcon fixedWidth icon={faFileLines} />,
    [ProductGroupTypeEnum.Manual]: <FontAwesomeIcon fixedWidth icon={faSquareList} />,
    [ProductGroupTypeEnum.Feed]: <FontAwesomeIcon fixedWidth icon={faCode} />,
    [ProductGroupTypeEnum.Iiko]: <FontAwesomeIcon fixedWidth icon={faSquareList} />,
};

export const productGroupTypeTooltipMap: Record<ProductGroupTypeEnum, LangKeyType> = {
    [ProductGroupTypeEnum.File]: 'PRODUCTS__TABLE__TYPE__TOOLTIP__FILE',
    [ProductGroupTypeEnum.Manual]: 'PRODUCTS__TABLE__TYPE__TOOLTIP__MANUAL',
    [ProductGroupTypeEnum.Feed]: 'PRODUCTS__TABLE__TYPE__TOOLTIP__FEED',
    [ProductGroupTypeEnum.Iiko]: 'PRODUCTS__TABLE__TYPE__TOOLTIP__IIKO',
};

export const productGroupsSizeOptions: [number, ...Array<number>] = [25, 50, 100];
