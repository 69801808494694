import {Table} from 'antd';
import {Dayjs} from 'dayjs';
import {useMemo} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/localization';
import {useFakesCompaniesInfo} from '../../../../../service/fakes/fakes-companies-info';
import {FakesFiltersType, FakesResultType, useFakesReportResults} from '../../../../../service/fakes/fakes-results';
import {ProvidersIdsEnum} from '../../../../../util/type';

import {FakesLocationColumn} from './columns/location/fakes-location-column';
import {FakesResultColumn} from './columns/result/fakes-result-column';
import {FakesReportTableFooter} from './footer/fakes-report-table-footer';
import * as styles from './fakes-report-table.scss';

type PropsType = {
    dateRange: [Dayjs, Dayjs] | null;
    icon: JSX.Element;
    filter: Partial<FakesFiltersType>;
    title: JSX.Element;
    description: JSX.Element;
    locationColumnTitle: JSX.Element;
};

export function FakesReportTable(props: PropsType): JSX.Element | null {
    const {dateRange, icon, filter, title, description, locationColumnTitle} = props;

    const googleResultsOptions = useMemo(
        () => ({
            ...filter,
            catalogIds: [ProvidersIdsEnum.google],
            ...(dateRange && {
                statusUpdatedFrom: dateRange[0].format('YYYY-MM-DD'),
                statusUpdatedTo: dateRange[1].format('YYYY-MM-DD'),
            }),
        }),
        [dateRange, filter]
    );

    const yandexResultsOptions = useMemo(
        () => ({
            ...googleResultsOptions,
            catalogIds: [ProvidersIdsEnum.yandex],
        }),
        [googleResultsOptions]
    );

    const {data: googleResults} = useFakesReportResults(googleResultsOptions);
    const {data: yandexResults} = useFakesReportResults(yandexResultsOptions);

    const companiesInfo = useFakesCompaniesInfo([
        ...(googleResults?.results.map(({id}) => id) || []),
        ...(yandexResults?.results.map(({id}) => id) || []),
    ]);

    const dataSource = useMemo(() => {
        if (!googleResults || !yandexResults) {
            return [];
        }

        const fakes: Array<FakesResultType | {catalog: ProvidersIdsEnum}> = [];

        if (googleResults.results.length > 0) {
            fakes.push({catalog: ProvidersIdsEnum.google}, ...googleResults.results);
        }

        if (yandexResults.results.length > 0) {
            fakes.push({catalog: ProvidersIdsEnum.yandex}, ...yandexResults.results);
        }

        return fakes;
    }, [googleResults, yandexResults]);

    if (!googleResults || !yandexResults || dataSource.length === 0) {
        return null;
    }

    return (
        <>
            <div className={styles.FakesReportTable_titleIcon}>{icon}</div>

            <h1 className={styles.FakesReportTable_title}>{title}</h1>

            <Text block className={styles.FakesReportTable_description} lighter>
                {description}
            </Text>

            <Text
                block
                className={styles.FakesReportTable_total}
                lighter
                stringKey="TEXT__TOTAL"
                valueMap={{total: <Text bolder>{googleResults.count + yandexResults.count}</Text>}}
            />

            <Table<FakesResultType | {catalog: ProvidersIdsEnum}>
                className={styles.FakesReportTable_table}
                columns={[
                    {
                        key: 'location',
                        title: locationColumnTitle,
                        render: (_value, fake) => (
                            <FakesLocationColumn
                                companies={'id' in fake ? companiesInfo?.[fake.id] ?? [] : []}
                                fake={fake}
                            />
                        ),
                        onCell: (value) => ({colSpan: 'catalog' in value ? 2 : 1}),
                    },
                    {
                        key: 'result',
                        title: <Locale stringKey="FAKES__REPORT__FAKES__RESULT" />,
                        render: (_value, fake) => <FakesResultColumn fake={fake} />,
                        onCell: (value) => ({colSpan: 'catalog' in value ? 0 : 1}),
                    },
                ]}
                dataSource={dataSource}
                pagination={false}
                rowKey={(fake) => ('catalog' in fake ? fake.catalog : fake.id)}
                summary={FakesReportTableFooter}
            />
        </>
    );
}
