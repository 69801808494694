import {useInfiniteQuery, UseInfiniteQueryResult} from '@tanstack/react-query';

import {fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {rootApiUrl} from '../api/api-const';

type ReviewCustomCompaniesAddressType = {
    country: string;
    country_code: string;
    region: string;
    region_id: number;
    city: string;
    city_id: number;
    street: string;
    housenumber: string;
    postcode: string;
    lat: number;
    lon: number;
    company_id: number;
};

export type ReviewCustomCompanyType = {
    id: number;
    name: string;
    address: ReviewCustomCompaniesAddressType | null;
    code: string;
};

type ReviewCustomCompaniesType = {
    companies: Array<ReviewCustomCompanyType>;
    next_page_token: number | null;
    count: number;
};

function getReviewCustomCompaniesUrl(search?: string, brandId?: number | null, token?: number | null) {
    const count = 20;

    return `${rootApiUrl}/v4/reviews/custom_reviews/companies/?${objectToUrlParameters({
        q: search,
        count,
        token,
        brand_id: brandId,
    })}`;
}

function fetchReviewCustomCompanies(
    search?: string,
    brandId?: number | null,
    token?: number | null
): Promise<ReviewCustomCompaniesType> {
    return fetchX<ReviewCustomCompaniesType>(getReviewCustomCompaniesUrl(search, brandId, token));
}

export function useReviewCustomCompanies(
    search: string,
    brandId: number | null
): UseInfiniteQueryResult<ReviewCustomCompaniesType> {
    return useInfiniteQuery(
        [getReviewCustomCompaniesUrl(search, brandId)],
        ({pageParam: pageParameter = null}) => fetchReviewCustomCompanies(search, brandId, pageParameter),
        {
            getNextPageParam: (lastPage) => lastPage.next_page_token || null,
        }
    );
}
