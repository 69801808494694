import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, FormListFieldData, Input, Select, Table, TableColumnsType, TableColumnType, Tooltip} from 'antd';
import {FormInstance} from 'antd/es/form/Form';
import {ReactElement} from 'react';

import {BrandType} from '../../../../../../../../provider/brands/brand-type';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {FeedResultsTypeEnum} from '../../../results/feed-results-const';
import {FeedFormKeyEnum as KeyEnum, FeedFormType} from '../../feed-form-type';

import * as styles from './brands-form-item-table.scss';

type PropsType = {
    type: FeedResultsTypeEnum;
    brands: Array<FormListFieldData>;
    brandsList: Array<BrandType>;
    remove: (index: number | Array<number>) => void;
};

export function BrandsFormItemTable(props: PropsType): ReactElement {
    const {type, brands, brandsList, remove} = props;

    const allowedBrands = Form.useWatch<KeyEnum.AllowedBrands, FormInstance<FeedFormType>>(KeyEnum.AllowedBrands);
    const allowedBrandIds = allowedBrands?.map((brand) => brand.brandId) ?? [];

    const {requiredFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();

    const brandCodeColumn: TableColumnType<FormListFieldData> = {
        dataIndex: 'brandCode',
        title: <Locale stringKey="FEED__FORM__BRANDS__COLUMN_TITLE__BRAND_CODE" />,
        render: (_value, _row, index) => {
            return (
                <Form.Item
                    className={styles.BrandsFormItemTable_formItem}
                    name={[index, 'brandCode']}
                    rules={[requiredFieldRule]}
                >
                    <Input placeholder={getLocalizedString('FEED__FORM__BRANDS__COLUMN_TITLE__BRAND_CODE')} />
                </Form.Item>
            );
        },
    };

    const columns: TableColumnsType<FormListFieldData> = [
        {
            dataIndex: 'brandId',
            title: <Locale stringKey="FEED__FORM__BRANDS__COLUMN_TITLE__BRAND" />,
            width: brands.length > 1 ? '50%' : '80%',
            render: (_value, _row, index) => {
                return (
                    <Form.Item
                        className={styles.BrandsFormItemTable_formItem}
                        name={[index, 'brandId']}
                        rules={type === FeedResultsTypeEnum.Import ? [requiredFieldRule] : []}
                    >
                        <Select
                            options={brandsList
                                .filter(
                                    (brand) =>
                                        !allowedBrandIds
                                            .filter((_id, brandIndex) => index !== brandIndex)
                                            .includes(brand.id)
                                )
                                .map((brand) => ({value: brand.id, label: brand.name}))}
                            placeholder={<Locale stringKey="FEED__FORM__BRANDS__PLACEHOLDER" />}
                        />
                    </Form.Item>
                );
            },
        },
        ...(brands.length > 1 ? [brandCodeColumn] : []),
        {
            title: <Locale stringKey="FEED__FORM__BRANDS__COLUMN_TITLE__ACTION" />,
            align: 'center',
            render: (_value, _row, index) => {
                return brands.length > 1 ? (
                    <Button icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => remove(index)} type="text" />
                ) : (
                    <Tooltip placement="bottom" title={<Locale stringKey="FEED__FORM__BRANDS__DELETE_TOOLTIP" />}>
                        <Button disabled icon={<FontAwesomeIcon icon={faTrash} />} type="text" />
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <Table<FormListFieldData>
            className={styles.BrandsFormItemTable}
            columns={columns}
            dataSource={brands}
            pagination={false}
            size="small"
        />
    );
}
