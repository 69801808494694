import {LandingOnboardingStep} from '../../../../../../../component/landing/landing-onboarding-steps/landing-onboarding-step';
import {Text} from '../../../../../../../component/text/text';
import {CompanyMultiSelectV2} from '../../../../../../../layout/company-multi-select-v2/company-multi-select-v2';
import {UseCompaniesSelectorHookType} from '../../../../../../../service/feature-companies/feature-companies-type';

import * as styles from './local-pack-activation-companies-step.scss';

type PropsType = {
    companiesSelector: UseCompaniesSelectorHookType;
    onStepFinish: VoidFunction;
};

export function LocalPackActivationCompaniesStep(props: PropsType): JSX.Element {
    const {companiesSelector, onStepFinish} = props;

    const {selectedCount} = companiesSelector;
    const nextButtonDisabled = selectedCount === 0 || selectedCount > 100;

    return (
        <LandingOnboardingStep
            arrowButtonProps={{
                onClick: onStepFinish,
                disabled: nextButtonDisabled,
                disabledTooltipLangKey: nextButtonDisabled ? 'LOCAL_PACK__ACTIVATION__STEP_1__NOT_READY_TOOLTIP' : null,
            }}
        >
            <Text
                block
                className={styles.LocalPackActivationCompaniesStepContent_title}
                stringKey="LOCAL_PACK__ACTIVATION__STEP_1__TITLE"
            />
            <Text block lighter stringKey="LOCAL_PACK__ACTIVATION__STEP_1__TEXT_1" />
            <Text block lighter stringKey="LOCAL_PACK__ACTIVATION__STEP_1__TEXT_2" />

            <div className={styles.LocalPackActivationCompaniesStepContent_companiesSelector}>
                <CompanyMultiSelectV2 companiesSelector={companiesSelector} isDisabled={false} showCounter={false} />
                <Text block stringKey="COMPANY__MULTISELECT__SELECTED_COUNT" valueMap={{count: selectedCount}} />
            </div>
        </LandingOnboardingStep>
    );
}
