import {useMemo, useRef} from 'react';

import {classNames} from '../../../../../../util/css';
import {useDomElementRect} from '../../../../../../util/dom-hook/dom-hook';

import {ColumnType, PinterestElementType} from './pinterest-layout-type';
import * as styles from './pinterest-layout.scss';

type PropsType<T> = {
    className?: string;
    columnGap: number;
    columnWidth: number;
    list: Array<T>;
    minHeight: number;
    renderItem: (item: T, width: number) => JSX.Element;
    minColumns?: number;
};

export function PinterestLayout<T extends PinterestElementType>(props: PropsType<T>): JSX.Element {
    const {className, columnGap, columnWidth, list, minHeight, renderItem, minColumns = 1} = props;

    const pinterestListRef = useRef<HTMLDivElement>(null);
    const elementRect = useDomElementRect(pinterestListRef);
    const photoListWidth: number = elementRect?.width || 0;
    const columnCount = Math.max(Math.floor(photoListWidth / (columnWidth + columnGap)), minColumns);

    const itemWidth = useMemo(() => {
        return (photoListWidth - (columnCount - 1) * columnGap) / columnCount;
    }, [columnCount, columnGap, photoListWidth]);

    const columnList: Array<ColumnType<T>> = useMemo<Array<ColumnType<T>>>((): Array<ColumnType<T>> => {
        const columnListInner: Array<ColumnType<T>> = Array.from<undefined, ColumnType<T>>(
            {length: columnCount},
            () => []
        );

        if (columnCount < 1) {
            return [];
        }

        const columnFullnessList = Array.from<void, number>({length: columnCount}, () => 0);

        list.forEach((photo) => {
            const minFullnessIndex = columnFullnessList.indexOf(Math.min(...columnFullnessList));
            const previewHeight = Math.max((columnWidth * photo.imageHeight) / photo.imageWidth, minHeight);

            columnFullnessList[minFullnessIndex] += previewHeight;
            columnListInner[minFullnessIndex]?.push(photo);
        });
        // nice bottom algorithm [END]

        return columnListInner.filter((column) => column.length > 0);
    }, [columnCount, columnWidth, list, minHeight]);

    return (
        <div className={classNames(styles.pinterest_layout, className)} ref={pinterestListRef}>
            {columnList.map((column: ColumnType<T>): JSX.Element => {
                return (
                    <div key={column[0]?.id} style={{marginRight: columnGap}}>
                        {column.map((item) => renderItem(item, itemWidth))}
                    </div>
                );
            })}
        </div>
    );
}
