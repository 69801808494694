import {faUpload} from '@fortawesome/pro-regular-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {AdditionalInfo} from '../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {CustomFileType, UploadFileResponseType} from '../../../../../service/file-upload/file-upload-type';
import {useFormRules} from '../../../../../service/form/form-rules-hook';
import {useUploadStoryImage} from '../../../../../service/yandex-stories/yandex-stories-hook';
import {
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
    YandexStorySlideType,
    YandexStoryTypeEnum,
} from '../../../../../service/yandex-stories/yandex-stories-type';
import {Form, FormInstance} from '../../../../../typings/antd';
import {noop} from '../../../../../util/function';
import {FileFormInput} from '../../../../form/file-upload-item/file-form-input';
import {Text} from '../../../../text/text';
import {YandexStoryIllustration} from '../../../yandex-story-illustration/yandex-story-illustration';
import {
    YANDEX_CONFIRMATION_STEP_WIDTH,
    YANDEX_STORY_MAX_IMAGE_HEIGHT,
    YANDEX_STORY_MAX_IMAGE_WIDTH,
    YANDEX_STORY_MIN_IMAGE_HEIGHT,
    YANDEX_STORY_MIN_IMAGE_WIDTH,
} from '../../yandex-story-form-helper';
import {YandexStoryFormStep} from '../step/yandex-story-form-step';

import {YandexConfirmationStepSlide} from './yandex-confirmation-step-slide';
import {YANDEX_STORY_MAX_NAME_LENGTH} from './yandex-story-confirmation-step-helper';
import * as styles from './yandex-story-confirmation-step.scss';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
    onPreviousStep: VoidFunction;
    onSubmit: (values: YandexStoryFormType) => Promise<void>;
    formMode: 'create' | 'edit';
    onStepActivated: (width: number) => void;
};

// eslint-disable-next-line max-statements
export function YandexStoryConfirmationStep(props: PropsType): JSX.Element {
    const {formInstance, onPreviousStep, onSubmit, formMode, onStepActivated} = props;

    useEffect(() => {
        onStepActivated(YANDEX_CONFIRMATION_STEP_WIDTH);
    }, [onStepActivated]);

    const {mutateAsync: uploadFile, isLoading: isUploadingCover} = useUploadStoryImage();

    const {maxLengthFieldRule, validateImageDimension, requiredFieldTrimmedTextRule} = useFormRules();

    const {getLocalizedString} = useLocale();

    const [coverUploadMap, setCoverUploadMap] = useState<Record<string, CustomFileType & UploadFileResponseType>>({});

    const [isSubmitting, setIsSubmitting] = useState(false);
    const uploaderRef = useRef<{remove: (fileUid: string) => void}>({remove: noop});

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);

        try {
            await formInstance.validateFields();
            await onSubmit(formInstance.getFieldsValue(true));
        } finally {
            setIsSubmitting(false);
        }
    }, [formInstance, onSubmit]);

    const slides = Form.useWatch([YandexStoryFormFieldsEnum.Slides], {
        form: formInstance,
        preserve: true,
    });

    const {modal} = useModal();

    const handleRemoveCover = useCallback(() => {
        setCoverUploadMap({});
        formInstance.setFieldValue([YandexStoryFormFieldsEnum.Cover], null);
    }, [formInstance]);

    useEffect(() => {
        return () => formInstance.setFieldValue([YandexStoryFormFieldsEnum.Cover], null);
    }, [formInstance]);

    useEffect(() => {
        const firstUploadItem = Object.values(coverUploadMap)?.[0];

        if (firstUploadItem && firstUploadItem.status === 'error') {
            modal.error({
                title: getLocalizedString('YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER_ERROR__TITLE'),
                content: getLocalizedString('YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER_ERROR__CONTENT'),
            });
            setCoverUploadMap({});
        }

        if (firstUploadItem && firstUploadItem.status === 'done') {
            formInstance.setFieldValue([YandexStoryFormFieldsEnum.Cover], {
                id: firstUploadItem.id,
                url: firstUploadItem.url,
            });
        }
    }, [coverUploadMap, formInstance, getLocalizedString, modal]);

    const onCoverChanged = useCallback(() => {
        const coverValue = formInstance.getFieldValue([YandexStoryFormFieldsEnum.Cover]);

        const slidesValue = formInstance.getFieldValue([YandexStoryFormFieldsEnum.Slides]);

        const firstSlide = Object.values(slidesValue).find((slide) => slide.order === 1);

        if (!coverValue && firstSlide) {
            formInstance.setFieldValue([YandexStoryFormFieldsEnum.Cover], {
                id: firstSlide.id,
                url: firstSlide.url,
            });
        }

        return null;
    }, [formInstance]);

    const cover = Form.useWatch([YandexStoryFormFieldsEnum.Cover], {
        form: formInstance,
        preserve: true,
    });

    const storyType = Form.useWatch([YandexStoryFormFieldsEnum.Type], {
        form: formInstance,
        preserve: true,
    });

    const handleSelectCoverFromSlide = useCallback(
        (slide: YandexStorySlideType) => {
            const coversFromMap = Object.values(coverUploadMap);

            if (coversFromMap[0]) {
                uploaderRef.current?.remove?.(coversFromMap[0].id);
            }

            formInstance.setFieldValue([YandexStoryFormFieldsEnum.Cover], {
                id: slide.id,
                url: slide.url,
            });
        },
        [formInstance, coverUploadMap]
    );

    const isUsedCoverFromSlides = useMemo(() => {
        return Boolean(cover && slides && Object.values(slides).some((slide) => slide.id === cover.id));
    }, [cover, slides]);

    const slidesByOrder = useMemo(() => {
        return slides ? Object.values(slides).sort((item1, item2) => item1.order - item2.order) : [];
    }, [slides]);

    return (
        <YandexStoryFormStep
            leftFooterButtonConfig={{
                langKeyType: 'BUTTON__BACK',
                onClick: onPreviousStep,
            }}
            rightFooterButtonConfig={{
                langKeyType: formMode === 'create' ? 'BUTTON__PUBLISH' : 'BUTTON__UPDATE',
                onClick: handleSubmit,
                loading: isSubmitting,
                disabled: isSubmitting || isUploadingCover,
                type: 'primary',
            }}
        >
            <div className={styles.YandexStoryConfirmationStep}>
                <div className={styles.YandexStoryConfirmationStep_settingsWrapper}>
                    <YandexStoryIllustration
                        className={styles.YandexStoryConfirmationStep_cover}
                        image={cover?.url || ''}
                    />
                    <div className={styles.YandexStoryConfirmationStep_settings}>
                        <Form.Item<YandexStoryFormType>
                            className={styles.YandexStoryConfirmationStep_nameField}
                            label={
                                storyType === YandexStoryTypeEnum.Navigation ? (
                                    <AdditionalInfo
                                        leftNode={
                                            <Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__STORY_NAME__LABEL" />
                                        }
                                    >
                                        <Locale stringKey="YANDEX_STORY__HOW_TO_FIND__NAME__TOOLTIP" />
                                    </AdditionalInfo>
                                ) : (
                                    <Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__STORY_NAME__LABEL" />
                                )
                            }
                            name={YandexStoryFormFieldsEnum.Name}
                            required
                            rules={[requiredFieldTrimmedTextRule, maxLengthFieldRule(YANDEX_STORY_MAX_NAME_LENGTH)]}
                        >
                            <Input
                                disabled={storyType === YandexStoryTypeEnum.Navigation}
                                maxLength={YANDEX_STORY_MAX_NAME_LENGTH}
                                placeholder={getLocalizedString(
                                    'YANDEX_STORY_FORM__CONFIRMATION_STEP__STORY_NAME__PLACEHOLDER'
                                )}
                                showCount
                            />
                        </Form.Item>

                        <Form.Item<YandexStoryFormType> dependencies={[[YandexStoryFormFieldsEnum.Cover]]} hidden>
                            {onCoverChanged}
                        </Form.Item>

                        <AdditionalInfo leftNode={<Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER" />}>
                            <Text block bolder stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_TITLE" />
                            <ul className={styles.YandexStoryConfirmationStep_coverRulesList}>
                                <li>
                                    <Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_RULE_1" />
                                </li>
                                <li>
                                    <Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_RULE_2" />
                                </li>
                                <li>
                                    <Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__TOOLTIP_RULE_3" />
                                </li>
                            </ul>
                        </AdditionalInfo>

                        <div className={styles.YandexStoryConfirmationStep_selectedCover}>
                            {isUsedCoverFromSlides && (
                                <Text
                                    block
                                    className={styles.YandexStoryConfirmationStep_selectedCover_title}
                                    secondary
                                    stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__SELECTED_FROM_SLIDES"
                                />
                            )}
                            <FileFormInput
                                innerRef={uploaderRef}
                                onChange={setCoverUploadMap}
                                remove={handleRemoveCover}
                                upload={uploadFile}
                                uploadProps={{maxCount: 1, showUploadList: true, accept: '.png,.jpg', listType: 'text'}}
                                validationRules={[
                                    async (file: RcFile) =>
                                        validateImageDimension(file, {
                                            min: {
                                                height: YANDEX_STORY_MIN_IMAGE_HEIGHT,
                                                width: YANDEX_STORY_MIN_IMAGE_WIDTH,
                                            },
                                            max: {
                                                height: YANDEX_STORY_MAX_IMAGE_HEIGHT,
                                                width: YANDEX_STORY_MAX_IMAGE_WIDTH,
                                            },
                                        }),
                                ]}
                                value={coverUploadMap}
                            >
                                <Button
                                    className={styles.YandexStoryConfirmationStep_uploadCoverButton}
                                    icon={<FontAwesomeIcon icon={faUpload} />}
                                    type="link"
                                >
                                    <Locale stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__UPLOAD_ANOTHER" />
                                </Button>
                            </FileFormInput>
                        </div>
                    </div>
                </div>

                <div className={styles.YandexStoryConfirmationStep_slidesRail}>
                    {slidesByOrder.map((slide) => {
                        return (
                            <YandexConfirmationStepSlide
                                isSelected={cover?.id === slide.id}
                                key={slide.order}
                                onSelectAsCover={() => {
                                    handleSelectCoverFromSlide(slide);
                                }}
                                slide={slide}
                            />
                        );
                    })}
                </div>

                <div className={styles.YandexStoryConfirmationStep_moderationDuration}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                    <Text
                        block
                        stringKey="YANDEX_STORY_FORM__CONFIRMATION_STEP__SELECTED_COMPANIES__MODERATION_HELPER"
                    />
                </div>
            </div>
        </YandexStoryFormStep>
    );
}
