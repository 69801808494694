import {LangKeyType} from '../../provider/locale/translation/type';

import {GoogleButtonTypeEnum, PostTypeEnum} from './posts-types';

export const availablePostTypeTranslationMap: Record<PostTypeEnum, LangKeyType> = {
    [PostTypeEnum.standard]: 'POSTS_FORM__TYPE_VALUE__NEWS',
    [PostTypeEnum.googleEvent]: 'POSTS_FORM__TYPE_VALUE__EVENT',
    [PostTypeEnum.googleOffer]: 'POSTS_FORM__TYPE_VALUE__OFFER',
};

export const availablePostButtonTypeTranslationMap: Record<GoogleButtonTypeEnum, LangKeyType> = {
    [GoogleButtonTypeEnum.Book]: 'POSTS_FORM__BUTTON_TYPE__VALUE__BOOK',
    [GoogleButtonTypeEnum.Call]: 'POSTS_FORM__BUTTON_TYPE__VALUE__CALL',
    [GoogleButtonTypeEnum.LearnMore]: 'POSTS_FORM__BUTTON_TYPE__VALUE__LEARN_MORE',
    [GoogleButtonTypeEnum.Order]: 'POSTS_FORM__BUTTON_TYPE__VALUE__ORDER',
    [GoogleButtonTypeEnum.Shop]: 'POSTS_FORM__BUTTON_TYPE__VALUE__SHOP',
    [GoogleButtonTypeEnum.SignUp]: 'POSTS_FORM__BUTTON_TYPE__VALUE__SIGN_UP',
    [GoogleButtonTypeEnum.Unspecified]: 'POSTS_FORM__BUTTON_TYPE__VALUE__UNSPECIFIED',
};
