import {faStar, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faBars} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Row, Typography} from 'antd';
import {useMemo} from 'react';

import {CompanyGeneralInfoCategoryType} from '../../../../../../../service/company/company-type';
import {classNames} from '../../../../../../../util/css';

import * as styles from './selected-category-item.scss';

const {Text} = Typography;

type PropsType = {
    className?: string;
    isFirst: boolean;
    companyCategoryItem: CompanyGeneralInfoCategoryType;
    removeFromSelectedCompanyCategory: (item: CompanyGeneralInfoCategoryType) => void;
    makePrimaryCompanyCategory: (item: CompanyGeneralInfoCategoryType) => void;
    isMarkPrimaryAvailable: boolean;
};

export function SelectedCompanyCategoryItem(props: PropsType): JSX.Element {
    const {
        isFirst,
        companyCategoryItem,
        className,
        isMarkPrimaryAvailable,
        removeFromSelectedCompanyCategory,
        makePrimaryCompanyCategory,
    } = props;

    const fullClassName = classNames(
        styles.selected_company_category_item,
        isFirst && isMarkPrimaryAvailable ? styles.selected_company_category_item_primary : '',
        className
    );

    const {name} = companyCategoryItem;

    const text = useMemo((): JSX.Element => {
        return <Text className={styles.selected_category_item__text}>{name}</Text>;
    }, [name]);

    const buttonTrashBin = useMemo((): JSX.Element => {
        return (
            <Button
                className={styles.selected_category_item__button}
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => removeFromSelectedCompanyCategory(companyCategoryItem)}
                type="link"
            />
        );
    }, [removeFromSelectedCompanyCategory, companyCategoryItem]);

    if (isFirst) {
        return (
            <li className={fullClassName}>
                <Row>
                    <FontAwesomeIcon className={styles.selected_category_item__drag} icon={faStar} />

                    {text}
                </Row>
                {buttonTrashBin}
            </li>
        );
    }

    return (
        <li className={fullClassName}>
            <Row>
                <FontAwesomeIcon className={styles.selected_category_item__drag} icon={faBars} />

                {text}
            </Row>
            <Row>
                {isMarkPrimaryAvailable && (
                    <Button
                        className={styles.selected_category_item__button}
                        icon={<FontAwesomeIcon icon={faStar} />}
                        onClick={() => makePrimaryCompanyCategory(companyCategoryItem)}
                        type="link"
                    />
                )}

                {buttonTrashBin}
            </Row>
        </li>
    );
}
