import {ReviewDataChildType} from '../../../../../service/reviews/reviews-type';

export function getAnswers(answers: Array<ReviewDataChildType>): Array<ReviewDataChildType> {
    const generalAnswers: Array<ReviewDataChildType> = [];

    answers.forEach((answer) => {
        if (answer.children && answer.children.length > 0) {
            generalAnswers.push(...getAnswers(answer.children));
        }

        if (answer.isCompanyComment) {
            generalAnswers.push(answer);
        }
    });

    return generalAnswers;
}
