import {Button, Form, Pagination} from 'antd';
import {useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {matchCompanyApi} from '../../../../../service/matching/matching-api';
import {
    AccountMatchingCompaniesHookType,
    MatchingCompanyType,
    MatchingLocationType,
} from '../../../../../service/matching/matching-types';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {AccountMatchingItem} from '../account-matching-item/account-matching-item';

import {createNewCompanyValue, MAX_MATCHING_ALERTS_COUNT} from './accounts-matching-const';
import {getMatchingCompanyId, isLocation} from './accounts-matching-form-helper';
import * as styles from './accounts-matching-form.scss';

type PropsType = {
    accountOauthId: number;
    providerId: number;
    brandPageId?: string;
    accountMatchingData: AccountMatchingCompaniesHookType;
};

export function AccountsMatchingForm(props: PropsType): JSX.Element {
    const {accountOauthId, brandPageId, providerId, accountMatchingData} = props;
    const {companies, locations, processError, isInProgress, pagination, setPagination} = accountMatchingData;

    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();

    const [form] = Form.useForm();

    const [matchedCompanies, setMatchedCompanies] = useState<Array<MatchingCompanyType>>([]);

    async function handleSubmit() {
        const formValues = form.getFieldsValue();
        const fields = Object.keys(formValues).filter((field) => field.startsWith('company_') && formValues[field]);

        if (fields.length === 0) {
            return;
        }

        const companiesToMatch = fields.map((field) => ({
            id: getMatchingCompanyId(field),
            cclData: formValues[field] === createNewCompanyValue ? null : formValues[field],
        }));

        await matchCompanyApi({
            companies: companiesToMatch,
            accountId: accountOauthId,
            providerId,
            brandPageId,
        });

        const companyIds = new Set(companiesToMatch.map(({id}) => id));
        const newMatchedCompanies = companies.filter(({id}) => companyIds.has(id));

        setMatchedCompanies((items) => (newMatchedCompanies.length > 0 ? [...items, ...newMatchedCompanies] : items));

        if (fields.length >= MAX_MATCHING_ALERTS_COUNT) {
            snackbar.success(<Locale stringKey="TEXT__SAVED" />);
        } else {
            fields.forEach((field) => {
                const companyId = getMatchingCompanyId(field);
                const company = companies.find(({id}) => id === companyId);

                if (!company) {
                    return;
                }

                snackbar.success(
                    <Locale
                        stringKey={
                            isLocation(formValues[field])
                                ? 'COMPANY_MATCHING__MATCHING_SUCCESS__MESSAGE'
                                : 'COMPANY_MATCHING__CREATE_NEW_SUCCESS__MESSAGE'
                        }
                        valueMap={{company: `${company.code} ${company.name}`}}
                    />
                );
            });
        }

        pushUrl(appRoute.sources.path, {isSaveQuery: true});
    }

    const [selectedLocations, setSelectedLocations] = useState<Array<MatchingLocationType>>([]);

    function onSelect() {
        const formValues = form.getFieldsValue();

        const formSelectedLocations: Array<MatchingLocationType> = [];

        Object.keys(formValues).forEach((fieldName) => {
            const fieldValue: string | MatchingLocationType | undefined = formValues[fieldName];

            if (fieldName.startsWith('company_') && isLocation(fieldValue)) {
                formSelectedLocations.push(fieldValue);
            }
        });

        setSelectedLocations(formSelectedLocations);
    }

    function handlePaginationChange(pageNumber: number, pageSize?: number) {
        setPagination({
            current: pageNumber,
            pageSize: Number(pageSize) || 0,
        });

        if (pagination.current !== pageNumber) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, pageNumber);
        }
    }

    if (isInProgress) {
        return <Spinner />;
    }

    if (processError) {
        return <AlertFallback message={processError.message} />;
    }

    return (
        <Form className={styles.form} form={form}>
            {companies.map((company) => {
                const formItemName = `company_${company.id}`;

                return (
                    <Form.Item<MatchingLocationType | string> key={company.id} name={formItemName}>
                        <AccountMatchingItem
                            company={company}
                            ignoredLocations={selectedLocations}
                            isDisabled={matchedCompanies.includes(company)}
                            locations={locations}
                            onSelectionChanged={onSelect}
                        />
                    </Form.Item>
                );
            })}

            <footer className={styles.footer}>
                <Button onClick={handleSubmit} type="primary">
                    <Locale stringKey="COMPANY_MATCHING__SYNC" />
                </Button>

                <Pagination
                    current={pagination.current}
                    hideOnSinglePage={false}
                    onChange={handlePaginationChange}
                    pageSize={pagination.pageSize}
                    showSizeChanger
                    size="default"
                    total={pagination.total}
                />
            </footer>
        </Form>
    );
}
