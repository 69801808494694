import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {CclDataType} from '../../../../../../service/ccl/ccl-type';
import {StatisticCclsForCompanyType} from '../../../../../../service/company/company-type';
import {UserType} from '../../../../../../service/user/user-type';
import {CclCatalogStatusEnum, CclStateStatusEnum, ProvidersIdsEnum} from '../../../../../../util/type';
import {objectToUrlParameters} from '../../../../../../util/url';

import {STATUS_TEXT_BY_CCL_STATUS} from './status-column-const';

export function getStatusByCclAndUser(
    cclData: CclDataType | StatisticCclsForCompanyType,
    user: UserType | null,
    needYandexTransferAccount?: boolean
): LangKeyType {
    const isSend = cclData.stateStatus === CclStateStatusEnum.send;
    const isUserRocketData = user?.isRocketdata;
    const status = cclData.stateStatus || cclData.controlPanelStatus;

    if (isSend && isUserRocketData) {
        return 'SOURCES__STATUS__WAITING_FOR_PUSH';
    }

    if (cclData.needAction || needYandexTransferAccount) {
        return 'SOURCES__STATUS__ACTION_REQUIRED';
    }

    return STATUS_TEXT_BY_CCL_STATUS[status] || 'SOURCES__STATUS__NOT_DEFINED';
}

export function buildProviderConfirmationRoute(
    catalogId: number | null,
    brandId: number,
    companyId: number,
    verificationStatus: CclCatalogStatusEnum | null
): string {
    if (verificationStatus === CclCatalogStatusEnum.waitVerify) {
        return appRoute.sourcesConfirmations.path;
    }

    if (catalogId === ProvidersIdsEnum.yandex) {
        return generatePath(appRoute.sourcesYandexConfirmation.path, {brandId, companyId});
    }

    console.error('undefined provider id:', catalogId);

    return '';
}

export function buildProviderConfirmationRouteV2(
    catalogId: ProvidersIdsEnum | null,
    companyId: number,
    isMassConfirmation: boolean
): string | null {
    if (catalogId !== ProvidersIdsEnum.google) {
        return null;
    }

    return `${appRoute.sourcesAccountSyncGoogleConfirmations.path}?${objectToUrlParameters({
        companyId,
        isMassConfirmation,
    })}`;
}

export function buildSourcesNeedActionRoute(catalogId: number, companyId: number, brandId: number): string {
    return generatePath(appRoute.accountsMatching.path, {companyId, providerId: catalogId, brandId});
}

export function buildSourcesNeedActionRouteV2(
    catalogId: ProvidersIdsEnum | null,
    companyId: number,
    brandId: number
): string | null {
    if (catalogId !== ProvidersIdsEnum.google) {
        return null;
    }

    return `${appRoute.sourcesAccountSyncGoogle.path}?${objectToUrlParameters({
        companyId,
        brandId,
    })}`;
}
