import {faMessageBot} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useCallback, useState} from 'react';

import {Locale} from '../../../../../provider/locale/locale';
import {AddMoreRepliesModal} from '../add-more-replies-modal/add-more-replies-modal';

import * as styles from './add-more-replies.scss';

export function AddMoreReplies(): JSX.Element {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const onCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <div className={styles.DashboardAddMoreReplies}>
            <FontAwesomeIcon className={styles.DashboardAddMoreReplies_icon} icon={faMessageBot} />
            <span>
                <Locale stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__SPEED_UP" />
            </span>
            <Button
                className={styles.DashboardAddMoreReplies_openModalButton}
                onClick={() => setIsModalOpen(true)}
                type="text"
            >
                <Locale stringKey="DASHBOARD_PAGE__AUTO_REPLIES_SUMMARY__MODAL__BUTTON" />
            </Button>
            <AddMoreRepliesModal isOpen={isModalOpen} onClose={onCloseModal} />
        </div>
    );
}
