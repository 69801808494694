import {LangKeyType} from '../../../../provider/locale/translation/type';
import {CardOccupancyEnum} from '../../../../service/online-presence/online-presence-type';

export const dataCompletenessTranslationMap: Record<CardOccupancyEnum, LangKeyType> = {
    [CardOccupancyEnum.AverageScore]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__AVERAGE_SCORE',
    [CardOccupancyEnum.Categories]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__CATEGORIES',
    [CardOccupancyEnum.PhoneNumbers]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__PHONE_NUMBERS',
    [CardOccupancyEnum.PaymentMethods]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__PAYMENT_METHODS',
    [CardOccupancyEnum.Logo]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__LOGO',
    [CardOccupancyEnum.Cover]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__COVER',
    [CardOccupancyEnum.Description]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__DESCRIPTION',
    [CardOccupancyEnum.SocialLinks]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__SOCIAL_LINKS',
    [CardOccupancyEnum.Gallery]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__GALLERY',
    [CardOccupancyEnum.Prices]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__PRICES',
    [CardOccupancyEnum.Posts]: 'ONLINE_PRESENCE_ANALYTICS__OCCUPANCY_STATS__POSTS',
};
