import {z as r} from 'zod';

import {TimeStringType} from '../../util/type';
import {RatingType} from '../reviews/reviews-rating';

export enum NotificationTextReviewEnum {
    all = 'all',
    withText = 'with_text',
    withoutText = 'without_text',
}

export type EmailNotificationsFrequencyType = 1 | 24;

export enum EmailNotificationsEnum {
    SendNewsMail = 'send_news_mail',
    SendReviewsNotifications = 'send_reviews_notifications',
    SendReviewChangesNotifications = 'send_review_changes_notifications',
    SendQuestionsNotifications = 'send_questions_notifications',
    SendWeeklyAnalyticsSummaryNotifications = 'send_weekly_analytics_summary_notifications',
    SendWeeklyPhototoolSummaryNotifications = 'send_weekly_phototool_summary_notifications',
    NotificationEmails = 'notification_emails',
    SendReviewsNotificationsPeriod = 'send_reviews_notifications_period',
    SendReviewNotificationCatalogs = 'send_review_notification_catalogs',
    SendReviewNotificationRatings = 'send_review_notification_ratings',
    SendReviewNotificationType = 'send_review_notification_type',
    SendNewPostsInTickets = 'send_new_posts_in_tickets',
    NotifyAboutRatingDrop = 'send_location_rating_decline_notifications',
}

export type EmailNotificationsType = {
    [EmailNotificationsEnum.SendNewsMail]: boolean;
    [EmailNotificationsEnum.SendReviewsNotifications]: boolean;
    [EmailNotificationsEnum.SendReviewChangesNotifications]: boolean;
    [EmailNotificationsEnum.SendQuestionsNotifications]: boolean;
    [EmailNotificationsEnum.SendWeeklyAnalyticsSummaryNotifications]: boolean;
    [EmailNotificationsEnum.SendWeeklyPhototoolSummaryNotifications]: boolean;
    [EmailNotificationsEnum.NotificationEmails]: string;
    [EmailNotificationsEnum.SendReviewsNotificationsPeriod]: EmailNotificationsFrequencyType;
    [EmailNotificationsEnum.SendReviewNotificationCatalogs]: Array<number> | null;
    [EmailNotificationsEnum.SendReviewNotificationRatings]: Array<RatingType> | null;
    [EmailNotificationsEnum.SendReviewNotificationType]: NotificationTextReviewEnum | null;
    [EmailNotificationsEnum.SendNewPostsInTickets]?: boolean;
    [EmailNotificationsEnum.NotifyAboutRatingDrop]?: boolean;
};

export type TimeRangeType = {start: TimeStringType; end: TimeStringType};

export type BrowserNotificationsType = {
    send_pushalert_notifications: boolean;
    send_pushalert_omnichannel_notifications: boolean;
    pushalert_notifications_time_bucket: TimeRangeType | null;
    pushalert_notifications_catalogs: Array<number> | null;
    pushalert_notifications_ratings: Array<RatingType> | null;
    pushalert_notifications_content_type: NotificationTextReviewEnum | null;
};

export type TelegramNotificationsType = {
    send_messenger_notifications: boolean;
    send_messenger_omnichannel_notifications: boolean;
    send_messenger_review_responsible_user_notifications: boolean;
    messenger_token: string | null;
    messenger_notifications_time_bucket: TimeRangeType | null;
    messenger_notifications_catalogs: Array<number> | null;
    messenger_notifications_ratings: Array<RatingType> | null;
    messenger_notifications_content_type: NotificationTextReviewEnum | null;
};

const usedeskCatalogSchema = r.object({
    id: r.number(),
    name: r.string(),
    website: r.string().url(),
    icon: r.string().url(),
    logo: r.string().url(),
});

export type UsedeskCatalogType = r.infer<typeof usedeskCatalogSchema>;

export const usedeskCatalogResponseSchema = r.object({
    results: r.array(usedeskCatalogSchema),
});

export type UsedeskCatalogResponseType = r.infer<typeof usedeskCatalogResponseSchema>;
