import {useMemo} from 'react';

import {ChartRenderer} from '../chart-renderer/chart-renderer';

import {defaultLineChartOptions} from './line-chart-helper';
import {
    ChartJsLineDataSetType,
    ChartJsLineDataType,
    ChartJsLineOptionsType,
    LineDataSetType,
    LineDataType,
} from './line-chart-type';

type PropsType = {
    data: LineDataType | null;
    options?: ChartJsLineOptionsType;
};

export function LineChart(props: PropsType): JSX.Element {
    const {data, options} = props;

    const chartJsData: ChartJsLineDataType = useMemo<ChartJsLineDataType>(
        (): ChartJsLineDataType => ({
            ...(data?.labels ? {labels: data.labels} : {}),
            datasets:
                data?.datasets.map(
                    (dataset: LineDataSetType): ChartJsLineDataSetType => ({
                        type: 'line',
                        label: dataset.label,
                        data: dataset.values,
                        borderColor: dataset.borderColor || dataset.color,
                        pointBackgroundColor: dataset.pointBackgroundColor || dataset.color,
                        backgroundColor: dataset.backgroundColor || dataset.color,
                        fill: dataset.fill,
                        tension: dataset.tension ?? 0.3,
                        pointBorderColor: dataset.pointBorderColor || '#fff',
                        pointBorderWidth: 1,
                        borderWidth: dataset.borderWidth || dataset.width || 1,
                        borderDash: dataset.dashed ? [5, 2] : [],
                        pointRadius: dataset.pointRadius,
                    })
                ) || [],
        }),
        [data]
    );

    return (
        <ChartRenderer<'line', Array<number>, string>
            data={chartJsData}
            options={options || defaultLineChartOptions}
            type="line"
        />
    );
}
