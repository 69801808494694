import {faArrowRotateRight, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, Input} from 'antd';
import {ReactNode, SyntheticEvent} from 'react';

import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {classNames} from '../../../../../../../../util/css';
import {noop} from '../../../../../../../../util/function';
import {TextType} from '../input-text-type';

import * as styles from './input-text.scss';

type PropsType = {
    className?: string;
    size?: 'large' | 'middle' | 'small';
    label?: string | JSX.Element;
    onRemove?: (() => void) | null;
    onInput: (value: TextType) => unknown;
    textValue: TextType;
    placeholder: string;
    prefix?: ReactNode;
    disabled?: boolean;
    errorMessage?: ReactNode;
};

export function InputText(props: PropsType): JSX.Element {
    const {className, size, label, onRemove, onInput, textValue, placeholder, disabled, errorMessage} = props;

    const {requiredFieldRule} = useFormRules();
    const fullClassName = classNames(styles.input_text, className);

    return (
        <div className={fullClassName}>
            <Form.Item
                className={styles.input_wrapper}
                help={errorMessage}
                initialValue={textValue.text}
                label={label}
                required={!disabled}
                rules={disabled ? [] : [requiredFieldRule]}
                validateStatus={errorMessage ? 'error' : ''}
            >
                <Input
                    disabled={disabled}
                    onInput={(event: SyntheticEvent<HTMLInputElement>) => {
                        onInput({...textValue, text: event.currentTarget.value});
                    }}
                    placeholder={placeholder}
                    prefix={
                        textValue.isWaitingForPush && (
                            <FontAwesomeIcon className={styles.input_prefix} icon={faArrowRotateRight} />
                        )
                    }
                    size={size}
                    value={textValue.text}
                />
            </Form.Item>

            {Boolean(onRemove) && (
                <Button
                    className={styles.button_remove}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={onRemove || noop}
                    size={size}
                    type="link"
                />
            )}
        </div>
    );
}
