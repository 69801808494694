export enum SyncDoubleGisAccountFormEnum {
    Brand = 'brand',
    Email = 'email',
    Phone = 'phone',
    FullName = 'fullName',
}

export const syncDoubleGisAccountFormId = 'sync-double-gis-account-form-id';

export type SyncDoubleGisAccountFormType = {
    [SyncDoubleGisAccountFormEnum.Brand]: number;
    [SyncDoubleGisAccountFormEnum.Email]: string;
    [SyncDoubleGisAccountFormEnum.Phone]: string;
    [SyncDoubleGisAccountFormEnum.FullName]: string;
};
