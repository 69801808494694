/* eslint-disable func-names */

import smartlookClient from 'smartlook-client';

import {ShortLocaleNameEnum} from '../../locale/locale-context-type';
import {HelpDeskChatOptionsType} from '../domain-config-type';

import {helpDeskChatSettings, initialChatMessage, smartLookToken, usetifulSettings} from './init-scripts-const';
import {setDynamicScriptAttributes} from './init-scripts-helper';

type HdeWidgetType = {
    setInitialChatMessage: (message: string) => void;
};

declare global {
    interface Window {
        HDE: HdeWidgetType;
    }
}

export function initSmartLook(): void {
    smartlookClient.init(smartLookToken);
}

export function identifySmartLook(id: number): void {
    smartlookClient.identify(id, {});
}

function loadHelDeskChatScript(url: string, options: HelpDeskChatOptionsType): void {
    const initializedScript: HTMLScriptElement | null = document.querySelector(`#${helpDeskChatSettings.id}`);

    if (initializedScript) {
        setDynamicScriptAttributes(initializedScript, options);

        return;
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.setAttribute('id', helpDeskChatSettings.id);
    script.dataset.customButton = helpDeskChatSettings.buttonId;
    script.dataset.host = helpDeskChatSettings.host;
    script.dataset.position = helpDeskChatSettings.position;
    setDynamicScriptAttributes(script, options);

    script.addEventListener('load', function () {
        window?.HDE.setInitialChatMessage(initialChatMessage[options?.locale || ShortLocaleNameEnum.ru]);
    });

    script.addEventListener('error', function () {
        console.error('error load helpdeskchat script');
    });

    script.src = url;

    document.querySelectorAll('head')[0]?.append(script);
}

export function initHelpDeskChat(options: HelpDeskChatOptionsType): void {
    loadHelDeskChatScript(helpDeskChatSettings.url, options);
}

export function initUsetiful(): void {
    const head = document.querySelectorAll('head')[0];
    const script = document.createElement('script');
    const {src, token} = usetifulSettings;

    script.async = true;
    script.src = src;
    script.setAttribute('id', 'usetifulScript');
    script.dataset.token = token;

    head?.append(script);
}
