import dayjs, {Dayjs} from 'dayjs';

import {CreateReviewFormPaylaodType, EditReviewFormPaylaodType} from '../../../../service/reviews/custom-reviews';
import {ReviewFormSourcesType} from '../../../../service/reviews/reviews-form-sources';
import {ReviewDataType} from '../../../../service/reviews/reviews-type';

import {ReviewFormFieldNamesEnum, ReviewFormFieldsType} from './review-form-type';

export function extractSource(
    sourceId: ReviewFormSourcesType['id'],
    sources: Array<ReviewFormSourcesType> | null
): ReviewFormSourcesType | null {
    if (!sources) {
        return null;
    }

    const source = sources.find(({id}) => id === sourceId);

    if (!source) {
        return null;
    }

    return source;
}

export function getDisabledDate(current: Dayjs): boolean {
    return current.isAfter(dayjs());
}

export function serializeCreateReviewForm(fields: ReviewFormFieldsType): CreateReviewFormPaylaodType {
    const companyId = fields[ReviewFormFieldNamesEnum.company];

    return {
        catalog_id: fields[ReviewFormFieldNamesEnum.source],
        brand_id: fields[ReviewFormFieldNamesEnum.brand],
        company_id: companyId ? Number(companyId) : null,
        creation_time: fields[ReviewFormFieldNamesEnum.createdAt].toISOString(),
        author: fields[ReviewFormFieldNamesEnum.author] || null,
        origin_url: fields[ReviewFormFieldNamesEnum.url] || null,
        rating: fields[ReviewFormFieldNamesEnum.rating] || null,
        comment: fields[ReviewFormFieldNamesEnum.content] || null,
        user_fields: {
            email: fields[ReviewFormFieldNamesEnum.email] || null,
            phone: fields[ReviewFormFieldNamesEnum.phone] || null,
        },
    };
}

export function serializeEditReviewForm(fields: ReviewFormFieldsType, id: number): EditReviewFormPaylaodType {
    const companyId = fields[ReviewFormFieldNamesEnum.company];

    return {
        id,
        catalog_id: fields[ReviewFormFieldNamesEnum.source],
        brand_id: fields[ReviewFormFieldNamesEnum.brand],
        company_id: companyId ? Number(companyId) : null,
        creation_time: fields[ReviewFormFieldNamesEnum.createdAt].toISOString(),
        author: fields[ReviewFormFieldNamesEnum.author] || null,
        origin_url: fields[ReviewFormFieldNamesEnum.url] || null,
        rating: fields[ReviewFormFieldNamesEnum.rating] || null,
        comment: fields[ReviewFormFieldNamesEnum.content] || null,
        user_fields: {
            email: fields[ReviewFormFieldNamesEnum.email] || null,
            phone: fields[ReviewFormFieldNamesEnum.phone] || null,
        },
    };
}

/* eslint-disable no-undefined */
export function deserializeReview(review: ReviewDataType): ReviewFormFieldsType {
    const [authorName] = (review.author || '+').split('+');

    return {
        [ReviewFormFieldNamesEnum.source]: review.catalogId,
        [ReviewFormFieldNamesEnum.brand]: review.brand.id,
        [ReviewFormFieldNamesEnum.company]: review.company ? String(review.company?.id) : undefined,
        [ReviewFormFieldNamesEnum.createdAt]: dayjs(review.createdInCatalog),
        [ReviewFormFieldNamesEnum.author]: authorName ? authorName.trim() : '',
        [ReviewFormFieldNamesEnum.phone]: review.userFields.email || undefined,
        [ReviewFormFieldNamesEnum.url]: review.originUrl || undefined,
        [ReviewFormFieldNamesEnum.rating]: review.rating || undefined,
        [ReviewFormFieldNamesEnum.content]: review.comment || undefined,
        [ReviewFormFieldNamesEnum.email]: review.userFields.email || undefined,
    };
}
/* eslint-enable no-undefined */
