import {faCheckDouble} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ConfigProvider, List} from 'antd';
import {useContext} from 'react';

import {Locale} from '../../../../../provider/locale/localization';
import {NotificationContext} from '../../../../../provider/notification/notification';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {HeaderDropdownButton} from '../header-dropdown-button';

import {NotificationsMenuEmpty} from './empty/notifications-menu-empty';
import {NotificationItem} from './item/notification-item';
import * as styles from './notifications-menu.scss';

type PropsType = {
    icon: JSX.Element;
};

export function NotificationsMenu(props: PropsType): JSX.Element {
    const {icon} = props;

    const {notificationResult, markAllNotificationAsRead} = useContext(NotificationContext);
    const {results: notifications, unread_count} = notificationResult;

    const menuItems = [
        {
            key: 'header',
            label: (
                <header className={styles.header}>
                    <p className={styles.header_title}>
                        <Locale stringKey="NOTIFICATION__TEXT__SYSTEM_NOTIFICATIONS" />
                    </p>

                    <Button
                        disabled={notifications.every(({is_read}) => is_read)}
                        icon={<FontAwesomeIcon icon={faCheckDouble} />}
                        onClick={markAllNotificationAsRead}
                        size="small"
                        type="text"
                    >
                        <span>
                            <Locale stringKey="NOTIFICATION__TEXT__MARK_ALL_AS_READ" />
                        </span>
                    </Button>
                </header>
            ),
        },
        {
            key: 'notifications',
            label: (
                <ConfigProvider renderEmpty={NotificationsMenuEmpty}>
                    <List
                        className={styles.list}
                        dataSource={notifications}
                        renderItem={(item) => (
                            <List.Item key={item.id}>
                                <NotificationItem notification={item} />
                            </List.Item>
                        )}
                    />
                </ConfigProvider>
            ),
        },
    ];

    return (
        <ConfigProvider theme={{token: {controlItemBgHover: 'inherit'}}}>
            <HeaderDropdownButton
                badge={{
                    active: unread_count > 0,
                    offset: [unread_count > 9 ? 4 : 0, -2],
                    count: unread_count,
                    size: 'small',
                    overflowCount: 9999,
                }}
                destroyPopupOnHide
                icon={icon}
                menu={({setOpen}) => ({
                    className: styles.notifications_menu,
                    items: menuItems,
                    onClick: () => {
                        setOpen(true);

                        track(AnalyticsTarget.PersonalCabinet.Notifications);
                    },
                })}
                onClick={() => track(AnalyticsTarget.PersonalCabinet.Notifications)}
                shape="circle"
                type="text"
            />
        </ConfigProvider>
    );
}
