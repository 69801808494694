import {RcFile} from 'antd/lib/upload';
import {useEffect, useState} from 'react';

import {Spinner} from '../../../../../../layout/spinner/spinner';
import {CustomFileType, UploadFileResponseType} from '../../../../../../service/file-upload/file-upload-type';
import {useFormRules} from '../../../../../../service/form/form-rules-hook';
import {useUploadStoryImage} from '../../../../../../service/yandex-stories/yandex-stories-hook';
import {
    YandexStoryFormFieldsEnum,
    YandexStoryFormType,
} from '../../../../../../service/yandex-stories/yandex-stories-type';
import {FormInstance} from '../../../../../../typings/antd';
import {classNames} from '../../../../../../util/css';
import {noop} from '../../../../../../util/function';
import {useRefreshId} from '../../../../../../util/hook';
import {FileFormInput} from '../../../../../form/file-upload-item/file-form-input';
import {
    YANDEX_STORY__MAX_SLIDES,
    YANDEX_STORY_MAX_IMAGE_HEIGHT,
    YANDEX_STORY_MAX_IMAGE_WIDTH,
    YANDEX_STORY_MIN_IMAGE_HEIGHT,
    YANDEX_STORY_MIN_IMAGE_WIDTH,
} from '../../../yandex-story-form-helper';

import * as styles from './yandex-story-form-slides-content-step.scss';

type PropsType = {
    formInstance: FormInstance<YandexStoryFormType>;
};

export function YandexStoryContentStepSlideUpload(props: PropsType): JSX.Element {
    const {formInstance} = props;

    const {mutateAsync: uploadFile} = useUploadStoryImage();
    const {validateImageDimension} = useFormRules();

    const [fileList, setFileList] = useState<Record<string, CustomFileType & UploadFileResponseType>>({});

    const isFileMapEmpty = Object.keys(fileList).length === 0;

    const {refresh, refreshId} = useRefreshId();

    useEffect(() => {
        if (!isFileMapEmpty && Object.values(fileList).every((fileItem) => fileItem.status !== 'uploading')) {
            const currentSlidesCount = Object.values(
                formInstance.getFieldValue([YandexStoryFormFieldsEnum.Slides])
            ).length;

            Object.keys(fileList)
                .slice(0, YANDEX_STORY__MAX_SLIDES - currentSlidesCount)
                .forEach((key, index) => {
                    formInstance.setFieldValue([YandexStoryFormFieldsEnum.Slides, key], {
                        ...fileList[key],
                        buttonConfig: null,
                        order: currentSlidesCount + index + 1,
                        name: '',
                    });
                });

            setFileList({});
            refresh();
        }
    }, [fileList, formInstance, isFileMapEmpty, refresh]);

    return (
        <FileFormInput
            key={refreshId}
            onChange={setFileList}
            remove={noop}
            showCustomUploadList={false}
            upload={uploadFile}
            uploadProps={{accept: '.png,.jpg', maxFileSize: 10, maxCount: YANDEX_STORY__MAX_SLIDES}}
            validationRules={[
                async (file: RcFile) =>
                    validateImageDimension(file, {
                        min: {
                            height: YANDEX_STORY_MIN_IMAGE_HEIGHT,
                            width: YANDEX_STORY_MIN_IMAGE_WIDTH,
                        },
                        max: {
                            height: YANDEX_STORY_MAX_IMAGE_HEIGHT,
                            width: YANDEX_STORY_MAX_IMAGE_WIDTH,
                        },
                    }),
            ]}
            value={fileList}
        >
            <button
                className={classNames(
                    styles.YandexStoryFormSlidesContentStep_slide,
                    styles.YandexStoryFormSlidesContentStep_addSlideButton
                )}
                disabled={!isFileMapEmpty}
                type="button"
            >
                {isFileMapEmpty ? '+' : <Spinner />}
            </button>
        </FileFormInput>
    );
}
