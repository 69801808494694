import {ItemType} from 'antd/es/menu/interface';
import {FC} from 'react';

import {appRoute} from '../../app-route';
import {TWO_GIS_DOMAIN} from '../../provider/domain-config/domain-config-helper';
import {DomainNameEnum} from '../../provider/domain-config/domain-config-type';
import {LicenseType} from '../../provider/license/license-provider-type';

export function getDefaultOpenKeys(pathname: string): Array<string> {
    const settingsPath = appRoute.settings.path;
    const reviewsManagementPath = appRoute.reviewsManagement.path;
    const reviewsAnalysisPath = appRoute.reviewsAnalysis.path;
    const reviewsGeneratorPath = appRoute.reviewsGenerator.path;
    const onlinePresenceAnalytics = appRoute.onlinePresence.path;
    const omnichannelPath = appRoute.omnichannel.path;

    if (pathname.startsWith(settingsPath + '/')) {
        return [settingsPath];
    }

    if (pathname.startsWith(reviewsManagementPath + '/')) {
        return [reviewsManagementPath];
    }

    if (pathname.startsWith(reviewsAnalysisPath + '/')) {
        return [reviewsAnalysisPath];
    }

    if (pathname.startsWith(reviewsGeneratorPath + '/')) {
        return [reviewsGeneratorPath];
    }

    if (pathname.startsWith(onlinePresenceAnalytics + '/')) {
        return [onlinePresenceAnalytics];
    }

    if (pathname.startsWith(omnichannelPath + '/')) {
        return [omnichannelPath];
    }

    return [];
}

function pushSelectedKeyToResult(result: Array<string>, items: Array<ItemType>, pathname: string) {
    items.forEach((item) => {
        const keyString = item?.key?.toString() || '';

        if (item && 'children' in item && item.children) {
            pushSelectedKeyToResult(result, item.children, pathname);
        }

        if (pathname.startsWith(keyString)) {
            result.push(keyString);
        }
    });
}

export function getDefaultSelectedKeys(items: Array<ItemType>, pathname: string): Array<string> {
    const result: Array<string> = [];

    pushSelectedKeyToResult(result, items, pathname);

    return result;
}

export function makeHardReload(): void {
    if (typeof window === 'undefined') {
        return;
    }

    window.location.reload();
}

export function isEnabledForDomainAndDoubleGis(options: {
    isDomainFeatureEnabled: boolean;
    isFeatureEnabled: boolean;
    domainName: DomainNameEnum | string;
}): boolean {
    if (options.domainName === DomainNameEnum.twoGis) {
        return options.isDomainFeatureEnabled && options.isFeatureEnabled;
    }

    return options.isDomainFeatureEnabled;
}

export function isEnabledForDomainAndDoubleGisWhiteLabel(options: {
    isDomainFeatureEnabled: boolean;
    isFeatureEnabled: boolean;
    domainName: DomainNameEnum | string;
}): boolean {
    const isDoubleGisWhiteLabel = options.domainName.includes(TWO_GIS_DOMAIN);

    if (isDoubleGisWhiteLabel) {
        return options.isDomainFeatureEnabled && options.isFeatureEnabled;
    }

    return options.isDomainFeatureEnabled;
}

export function getFeatureOrLandingPage(options: {
    license?: LicenseType;
    activationComponent: FC<Record<string, unknown>>;
    pageComponent: FC<Record<string, unknown>>;
}): FC<Record<string, unknown>> {
    const {license, activationComponent, pageComponent} = options;

    if (!license || (!license.isActive && license.canActivateTrial)) {
        return activationComponent;
    }

    return pageComponent;
}
