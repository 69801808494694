import {Table} from 'antd';
import {Key, useCallback, useEffect, useMemo, useState} from 'react';

import {renderCellActions} from '../../../../layout/actions-cell/actions-cell';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {fetchGroupCreate, fetchGroupUpdate, TagGroupType} from '../../../../service/reviews/reviews-tags';

import {tagColumnList} from './tags-edit-table-const';
import {renderGroupName} from './tags-edit-table-helper';
import {TagsEditTableRowDataType} from './tags-edit-table-type';

type PropsType = {
    setTagGroupIdList: (selectedIdList: Array<string>) => void;
    tagGroupListIsInProgress: boolean;
    tagGroupListResult: Array<TagGroupType> | null;
    newGroup: Array<TagGroupType>;
    refreshGroupList: () => void;
    setNewGroup: (group: Array<TagGroupType>) => void;
    onDelete: (tagGroupData?: TagGroupType) => void;
};

export function TagsEditTable(props: PropsType): JSX.Element {
    const {
        setTagGroupIdList,
        tagGroupListIsInProgress,
        tagGroupListResult,
        newGroup,
        refreshGroupList,
        setNewGroup,
        onDelete,
    } = props;
    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const [editableId, setEditableId] = useState<number>(-1);

    function handleRowSelection(_selectedRowKeys: Array<Key>, rowDataList: Array<TagsEditTableRowDataType>) {
        setTagGroupIdList(rowDataList.map((rowData: TagsEditTableRowDataType): string => rowData.key));
    }

    const groupDataList: Array<TagGroupType> = useMemo((): Array<TagGroupType> => {
        const realGroupDataList = tagGroupListResult || [];

        return [...newGroup, ...realGroupDataList];
    }, [tagGroupListResult, newGroup]);

    const updateGroup = useCallback(
        async (data: TagGroupType, id: number) => {
            const result = await fetchGroupUpdate(data, id);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__GROUP__UPDATING_ERROR" />);
            } else {
                setEditableId(-1);
                snackbar.success(<Locale stringKey="TAGS__GROUP__UPDATING_SUCCESS" />);

                refreshGroupList();
            }
        },
        [snackbar, refreshGroupList]
    );

    const onSave = useCallback(
        async (text: string, tagGroupData: TagGroupType) => {
            if (text.length === 0) {
                snackbar.error(<Locale stringKey="TAGS__EMPTY_ERROR" />);
                return;
            }

            if (tagGroupData.title.length > 0) {
                const data = {
                    ...tagGroupData,
                    title: text,
                };

                updateGroup(data, tagGroupData.id);
                return;
            }

            const result = await fetchGroupCreate({title: text});

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="TAGS__GROUP__CREATING_ERROR" />);
                return;
            }

            snackbar.success(<Locale stringKey="TAGS__GROUP__CREATING_SUCCESS" />);

            setNewGroup([]);
            refreshGroupList();
        },
        [snackbar, setNewGroup, refreshGroupList, updateGroup]
    );

    const convertTagTableRow = useCallback(
        (tagGroupData: TagGroupType, index: number): TagsEditTableRowDataType => {
            return {
                tagName: renderGroupName(
                    tagGroupData,
                    getLocalizedString,
                    (text: string) => onSave(text, tagGroupData),
                    editableId
                ),
                key: String(tagGroupData.id),
                index,
                groupActions: renderCellActions(
                    () => setEditableId(tagGroupData.id),
                    () => onDelete(tagGroupData)
                ),
            };
        },
        [getLocalizedString, onSave, editableId, onDelete]
    );

    const [dataList, setDataList] = useState<Array<TagsEditTableRowDataType>>([]);

    useEffect(() => {
        if (groupDataList) {
            setDataList(groupDataList.map(convertTagTableRow));
        }
    }, [groupDataList, convertTagTableRow]);

    return (
        <Table<TagsEditTableRowDataType>
            bordered
            columns={tagColumnList}
            dataSource={dataList}
            loading={tagGroupListIsInProgress}
            pagination={false}
            rowKey="key"
            rowSelection={{columnWidth: 47, onChange: handleRowSelection}}
            scroll={{x: true}}
            size="middle"
        />
    );
}
