import {faCircleCheck, faCircleExclamation, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {LangKeyType} from '../../../../provider/locale/translation/type';

import * as styles from './sync-statistics.scss';

export enum SyncStatisticsEnum {
    sync = 'sync',
    sent = 'sent',
    needAction = 'needAction',
}

export const cclStatusIconSrcMap: Record<SyncStatisticsEnum | string, JSX.Element> = {
    [SyncStatisticsEnum.sync]: <FontAwesomeIcon className={styles.SyncStatistickIcon_sync} icon={faCircleCheck} />,
    [SyncStatisticsEnum.sent]: <FontAwesomeIcon className={styles.SyncStatistickIcon_sent} icon={faSpinner} />,
    [SyncStatisticsEnum.needAction]: (
        <FontAwesomeIcon className={styles.SyncStatistickIcon_needAction} icon={faCircleExclamation} />
    ),
};

export const cclStatusLocaleKeyMap: Record<SyncStatisticsEnum | string, LangKeyType> = {
    [SyncStatisticsEnum.sync]: 'DASHBOARD_PAGE__SYNC_STATISTICS__STATUS__SYNC',
    [SyncStatisticsEnum.sent]: 'DASHBOARD_PAGE__SYNC_STATISTICS__STATUS__SENT',
    [SyncStatisticsEnum.needAction]: 'DASHBOARD_PAGE__SYNC_STATISTICS__STATUS__NEED_ACTION',
};
