import {useState} from 'react';

import {AntdErrorMessage} from '../../../../../../component/antd-error-message/antd-error-message';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {useCompanyMediaFile} from '../../../../../../service/company/company-hook';
import {BulkEditFieldNameEnum, CompanyImageKindEnum} from '../../../../../../service/company/company-type';
import {classNames} from '../../../../../../util/css';
import {InfoForImage} from '../../../../company-form/page-container/info-for-image/info-for-image';
import {DEFAULT_MEDIA_FILE} from '../../../bulk-edit-companies-const';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';
import {DEFAULT_INPUT_IMAGE} from '../logo-form-item-v2/input-image/images-const';
import {InputImage} from '../logo-form-item-v2/input-image/input-image';

import {COVER_SIZE} from './cover-form-item-v2-const';
import * as styles from './cover-form-item-v2.scss';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.cover> & {
    className?: string;
};

export function CoverFormItemV2(props: PropsType): JSX.Element {
    const {onChange, className, errorMessage} = props;

    const [inputImage, setInputImage] = useState(DEFAULT_INPUT_IMAGE);
    const {isInProgress, processError, reset, uploadCompanyMediaFile} = useCompanyMediaFile();

    async function handleUploadImage(image: File, type: CompanyImageKindEnum): Promise<void> {
        try {
            const loadedMediaFile = await uploadCompanyMediaFile({file: image, file_type: type});

            onChange(loadedMediaFile);
        } catch {
            onChange?.({...DEFAULT_MEDIA_FILE, size: image.size});
        }
    }

    function handleRemove() {
        onChange(DEFAULT_MEDIA_FILE);

        reset();
    }

    function extractError() {
        if (processError) {
            return processError.file_s3;
        }

        if (errorMessage) {
            return errorMessage;
        }

        return null;
    }

    const error = extractError();

    return (
        <div className={styles.logo_form_item__container}>
            {isInProgress && (
                <div className={styles.spinner__container}>
                    <Spinner />
                </div>
            )}

            <InfoForImage
                fileFormats="COMPANY_FORM__LOGO__COMPANY_CARD_COVER__FILE_FORMATS"
                label="COMPANY_FORM__LOGO__COMPANY_CARD_COVER"
                maxResolution="COMPANY_FORM__LOGO__COMPANY_CARD_COVER__HINT__MAX_RESOLUTION"
                maxSize="COMPANY_FORM__LOGO__COMPANY_CARD_COVER__HINT__MAX_SIZE"
                minResolution="COMPANY_FORM__LOGO__COMPANY_CARD_COVER__HINT__MIN_RESOLUTION"
                minSize="COMPANY_FORM__GALLERY__HINT__MIN_SIZE"
                title="COMPANY_FORM__LOGO__COMPANY_CARD_COVER"
            />

            <InputImage
                className={classNames(className, error ? styles.image__error : '')}
                inputImage={inputImage}
                minHeight={COVER_SIZE.minHeight}
                minWidth={COVER_SIZE.minWidth}
                onRemove={handleRemove}
                onUpload={(image) => handleUploadImage(image, CompanyImageKindEnum.cover)}
                setInputImage={setInputImage}
            />

            {error && <AntdErrorMessage>{error}</AntdErrorMessage>}
        </div>
    );
}
