import {isEqual, mapValues} from 'lodash';

import {WeekDaysEnum} from '../../../../../../../provider/locale/locale-context-const';
import {
    WeekdayType,
    WorkingDaysType,
    WorkingDayType,
} from '../../../../../../../service/company-v2/types/company-working-time-type';
import {getWorkingDaysObject} from '../../../company-form-helper';

export const emptyWeekday: WorkingDayType = {daily: false, timeRange: {}, breaks: []};

function getWeekday(weekday: string) {
    return weekday.replace(/\D/g, '') as WeekDaysEnum;
}

export function getSortedWorkingDays(workingDays: WorkingDaysType): Array<WeekdayType> {
    const workingDaysArray: Array<WeekdayType> = [];

    // Loop through the array of open working days
    // eslint-disable-next-line no-loops/no-loops
    for (const [dayOfWeek, workingDay] of Object.entries(workingDays)) {
        // Check if there is already a working day with the same properties
        const index = workingDaysArray.findIndex(
            (existingWorkingDay) =>
                existingWorkingDay.daily === workingDay?.daily &&
                isEqual(existingWorkingDay.breaks, workingDay?.breaks) &&
                isEqual(existingWorkingDay.timeRange, workingDay?.timeRange)
        );

        // If found, add the current day of week to the existing working day
        if (index !== -1 && workingDay !== null) {
            workingDaysArray[index]?.daysOfWeek.push(getWeekday(dayOfWeek) as WeekDaysEnum);
        }
        // If not found, create a new working day with the current day of week
        else if (workingDay !== null) {
            workingDaysArray.push({
                daysOfWeek: [getWeekday(dayOfWeek) as WeekDaysEnum],
                daily: workingDay.daily,
                timeRange: workingDay.timeRange,
                breaks: workingDay.breaks,
            });
        }
    }

    return workingDaysArray;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getSortedWeekdays(
    workingDays: WorkingDaysType,
    workingDay: WeekdayType,
    weekdays: Array<WeekDaysEnum>
): WorkingDaysType {
    const availableDays = getSortedWorkingDays(workingDays);
    let updatedDays = workingDays;

    if (Array.isArray(weekdays) && weekdays.length === 0) {
        availableDays.forEach((day) => {
            if (isEqual(day.daysOfWeek, workingDay.daysOfWeek)) {
                return day.daysOfWeek.forEach((dayNumber) => {
                    updatedDays = {...updatedDays, [`day${dayNumber}`]: null};
                });
            }

            return null;
        });

        return updatedDays;
    }

    if (Array.isArray(weekdays)) {
        availableDays
            .filter((day) => day.daysOfWeek.some((dayNumber) => weekdays.includes(dayNumber)))
            .forEach((day) => {
                day.daysOfWeek.forEach((dayNumber) => {
                    if (!weekdays.includes(dayNumber)) {
                        updatedDays = {...updatedDays, [`day${dayNumber}`]: null};
                    }
                });

                if (isEqual(day.daysOfWeek, workingDay.daysOfWeek)) {
                    // eslint-disable-next-line no-param-reassign
                    day.daysOfWeek = weekdays;
                }
            });
    }

    availableDays.forEach((day) => {
        day.daysOfWeek.forEach((dayNumber) => {
            if ((weekdays && weekdays.includes(dayNumber)) || workingDay.daysOfWeek.includes(dayNumber)) {
                updatedDays = {
                    ...updatedDays,
                    [`day${dayNumber}`]: {
                        daily: workingDay.daily,
                        timeRange: workingDay.timeRange,
                        breaks: day.breaks,
                    },
                };
            }
        });
    });

    return updatedDays;
}

export function getUpdatedWeekdays(
    weekdays: WorkingDaysType,
    daysToUpdate: Array<WeekDaysEnum>,
    update: Partial<WorkingDayType>
): WorkingDaysType {
    return mapValues(weekdays, (day, weekday) =>
        daysToUpdate.includes(getWeekday(weekday)) ? {...(day || emptyWeekday), ...update} : day
    );
}

export function getEmptyWeekdays(weekdays: Array<WeekDaysEnum>): WorkingDaysType {
    return getWorkingDaysObject(weekdays.map((weekday) => [`day${weekday}`, emptyWeekday]));
}

export function getAvailableDays(workingDays: WorkingDaysType): Array<WeekDaysEnum> {
    return Object.entries(workingDays)
        .filter(([, optionDay]) => optionDay === null)
        .map(([dayName]) => getWeekday(dayName)) as Array<WeekDaysEnum>;
}
