import {faFile} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Flex, Input} from 'antd';
import {SyntheticEvent, useState} from 'react';

import {spacer_m} from '../../../../../../../../css/var-export.scss';
import {Spinner} from '../../../../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../../../../service/analytics/analytics';
import {fetchReviewsReply} from '../../../../../../../../service/reviews/reviews-reply';
import {ResponseTemplateDataType} from '../../../../../../../../service/reviews/reviews-response-templates';
import {ReviewDataChildType} from '../../../../../../../../service/reviews/reviews-type';
import {IdNumberType} from '../../../../../../../../util/type';
import {AutoReplyTemplatePopup} from '../../../../../../auto-reply-form/auto-reply-form-popup/auto-reply-template-popup';
import {handleReviewReplyExceptions} from '../../review-helper';

import * as styles from './reply-on-reply-form.scss';

type PropsType = {
    answer: ReviewDataChildType;
    isTestBrand: boolean;
    onClose: () => void;
    refreshReview: (reviewId: number) => Promise<unknown>;
    reviewId: number;
};

export function NewReplyOnReplyForm(props: PropsType): JSX.Element {
    const {answer, isTestBrand, onClose, refreshReview, reviewId} = props;

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();

    const [reply, setReply] = useState<string>('');
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [isSelectTemplatePopupOpen, setIsSelectTemplatePopupOpen] = useState<boolean>(false);
    const [selectedTemplateList, setSelectedTemplateList] = useState<Array<IdNumberType>>([]);
    const [selectedResponseTemplateList, setSelectedResponseTemplateList] = useState<Array<ResponseTemplateDataType>>(
        []
    );

    async function onSubmit() {
        if (!answer.replyId) {
            return;
        }

        setIsInProgress(true);

        try {
            await fetchReviewsReply(answer.replyId, {comment: reply.trim()});
            await refreshReview(reviewId);
            onClose();
        } catch (error: unknown) {
            handleReviewReplyExceptions(error, snackbar);
        } finally {
            setIsInProgress(false);
        }
    }

    function handleSelectTemplateClick() {
        track(AnalyticsTarget.ReviewsManagement.Reviews.ClickResponseTemplates);

        setIsSelectTemplatePopupOpen(true);
    }

    return (
        <form className={styles.review__edit_form}>
            <Spinner isShow={isInProgress} position="absolute" />
            <Flex align="start" gap={spacer_m}>
                <Input.TextArea
                    autoSize
                    className={styles.ReplyOnReply_textArea}
                    onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                        setReply(event.currentTarget.value);
                    }}
                    placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_REPLY_TEXT')}
                    size="large"
                    value={reply}
                />
                <Button className={styles.ReplyOnReply_templateButton} onClick={handleSelectTemplateClick}>
                    <FontAwesomeIcon height={32} icon={faFile} width={32} />
                </Button>
            </Flex>
            <div className={styles.review__button_wrapper}>
                <Button
                    disabled={reply.length === 0 || isTestBrand}
                    loading={isInProgress}
                    onClick={onSubmit}
                    type="primary"
                >
                    <Locale stringKey="REVIEWS__REPLY" />
                </Button>

                <Button onClick={onClose}>
                    <Locale stringKey="BUTTON__CANCEL" />
                </Button>
            </div>

            {isSelectTemplatePopupOpen && (
                <AutoReplyTemplatePopup
                    isOpen
                    isRadioMode
                    onCancel={() => setIsSelectTemplatePopupOpen(false)}
                    onOk={() => {
                        const [selectedAutoReply] = selectedResponseTemplateList;
                        const message = selectedAutoReply?.message;

                        if (message) {
                            setReply(message);
                        }

                        setIsSelectTemplatePopupOpen(false);
                    }}
                    reviewId={reviewId}
                    selectedItemList={selectedTemplateList}
                    selectedResponseTemplateList={selectedResponseTemplateList}
                    setSelectedItemList={setSelectedTemplateList}
                    setSelectedResponseTemplateList={setSelectedResponseTemplateList}
                />
            )}
        </form>
    );
}
