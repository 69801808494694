import {Input} from 'antd';
import {useEffect} from 'react';

import {Text} from '../../../component/text/text';
import {Page} from '../../../layout/page/page';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {setAclPermissionsFor2GisUser} from '../../../service/user/user-new-password';
import {resetPassword} from '../../../service/user/user-reset-password';
import {Form} from '../../../typings/antd';
import {AuthFormHeader} from '../common/form-header/auth-form-header';

import * as styles from './two-gis-reset-password.scss';

type PropsType = {
    email: string;
    jwtToken: string;
};

export function TwoGisResetPassword(props: PropsType): JSX.Element {
    const {email, jwtToken} = props;

    const {shortLocaleName} = useLocale();

    useEffect(() => {
        resetPassword({email, language: shortLocaleName}, shortLocaleName)
            .then(() => setAclPermissionsFor2GisUser(jwtToken))
            .catch(() => console.error('error'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page.CenteredCard titleLangKey="PAGE__NEW_PASSWORD__TITLE">
            <AuthFormHeader
                subtitle={
                    <Text
                        className={styles.TwoGisResetPassword_formHeader}
                        stringKey="PAGE__LOGIN__LOGIN_FORM__HEADER"
                    />
                }
            />
            <Text stringKey="PAGE__FORGOT_PASSWORD__RESET_LINK_SEND_TO_EMAIL" />
            <Form.Item label={<Locale stringKey="PAGE__FORGOT_PASSWORD__RESET_LINK_SEND__LABEL" />}>
                <Input disabled size="large" value={email} />
            </Form.Item>
        </Page.CenteredCard>
    );
}
