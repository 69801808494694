/* eslint-disable no-param-reassign */

import {TimeRangePickerProps} from 'antd';
import dayjs from 'dayjs';

import {MIN_NOT_ALLOWED_HOUR, RANGE_SEPARATOR, TIME_FORMAT, TIME_SEPARATOR} from './time-range-picker-const';

function getInputEventValue(event: InputEvent) {
    return event.target && 'value' in event.target ? (event.target as HTMLInputElement).value.trim() : null;
}

// Necessary to force ant to update internal state and dropdown content. Without it pressing enter does nothing
function triggerFakeInputEvent(target: HTMLInputElement, value: string) {
    if (!('Reflect' in window)) {
        return;
    }

    // https://stackoverflow.com/a/46012210
    const nativeInputSetter = Reflect.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;

    if (!nativeInputSetter) {
        return;
    }

    Reflect.apply(nativeInputSetter, target, [value]);

    target.dispatchEvent(new Event('input'));
}

// handles HH:mm-HH:mm format
function formatRange(value: string, onChange: TimeRangePickerProps['onChange']) {
    if (value.includes(RANGE_SEPARATOR)) {
        const [fromTime, toTime] = value.split(RANGE_SEPARATOR);
        // disallows entering X-0, X-1 and X-2, because you probably wanted to enter a second digit next
        const isToTimeValid = toTime && (toTime.length >= 2 || Number(toTime) >= 3);

        if (fromTime && isToTimeValid) {
            onChange?.(
                // TODO: dayjs doesn't support time without : but moment does
                [dayjs(fromTime.padStart(2, '0'), TIME_FORMAT), dayjs(toTime.padStart(2, '0'), TIME_FORMAT)],
                [TIME_FORMAT, TIME_FORMAT]
            );
        }

        // need to return here and not in if above, range doesn't need other checks
        return true;
    }

    return false;
}

function formatTwoCharacters(value: string, event: InputEvent & {target: HTMLInputElement}) {
    if (value.length === 2) {
        if (value.endsWith(TIME_SEPARATOR)) {
            event.target.value = value.padStart(3, '0');

            return true;
        }

        if (!value.includes(TIME_SEPARATOR) && Number(value) < MIN_NOT_ALLOWED_HOUR) {
            event.target.value = `${value}${TIME_SEPARATOR}`;

            return true;
        }

        if (!value.endsWith(TIME_SEPARATOR) && Number(value) >= MIN_NOT_ALLOWED_HOUR) {
            const [hour, minute] = value;

            event.target.value = `${hour?.padStart(2, '0')}${TIME_SEPARATOR}${minute}`;

            return true;
        }
    }

    return false;
}

function formatRestCases(value: string, event: InputEvent & {target: HTMLInputElement}) {
    if (value.includes(TIME_SEPARATOR.repeat(2))) {
        event.target.value = value.replace(TIME_SEPARATOR.repeat(2), TIME_SEPARATOR);

        return true;
    }

    if (value.replace(TIME_SEPARATOR, '').length >= 4) {
        const dayjsTime = dayjs(value, TIME_FORMAT);

        triggerFakeInputEvent(event.target, dayjsTime.isValid() ? dayjsTime.format(TIME_FORMAT) : value);

        return true;
    }

    return false;
}

export function autoformatTime(event: Event, onChange: TimeRangePickerProps['onChange']): boolean {
    if (!(event instanceof InputEvent)) {
        return false;
    }

    const value = getInputEventValue(event);

    const hasNewCharacters = event.data || event.inputType === 'insertFromPaste';
    const isCorrectTarget = event.target && event.target instanceof HTMLInputElement;

    if (!value || !hasNewCharacters || !isCorrectTarget) {
        return false;
    }

    const castedEvent = event as InputEvent & {target: HTMLInputElement};

    return (
        formatRange(value, onChange) || formatTwoCharacters(value, castedEvent) || formatRestCases(value, castedEvent)
    );
}
