import {useEffect, useMemo, useState} from 'react';

import {CursorPaginator} from '../../../../component/cursor-paginator/cursor-paginator';
import {Meta} from '../../../../component/meta/meta';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSystem} from '../../../../provider/system/system-hook';
import {ReviewSortingTypeEnum} from '../../../../service/reviews/reviews-type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {reviewPageSizeOptions} from '../reviews-const';
import {ReviewsHeader} from '../reviews-header/reviews-header';
import {useReviewStateHook} from '../reviews-state/reviews-state-provider';
import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from '../reviews-state/reviews-state-type';

import {NeuralNetworkAlert} from './neuralink-alert/neuralink-alert';
import {NewReviewList} from './review-list/new-review-list';
import {ReviewList} from './review-list/review-list';
import {ReviewsFilter} from './reviews-filter/reviews-filter';
import {ReviewsFilterDataType} from './reviews-filter/reviews-filter-type';
import * as styles from './review-list-page-content.scss';

export function ReviewListPageContent(): JSX.Element {
    const [isFilterOpened, setIsFilterOpened] = useState(true);
    const {reviews, cursorPagination, isReviewsInProgress, isNewLayout} = useReviewStateHook();
    const {getLocalizedString} = useLocale();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isMobile} = screen;
    const {replaceQuery: setReviewsStateUrlQuery, queries} = useUrl<
        Omit<ReviewsStateType, 'filterState'> & ReviewsFilterDataType
    >();

    const hasReviews = useMemo(() => Boolean(reviews?.results.length), [reviews]);

    const newFilterComponent = useMemo(() => <ReviewsHeader setIsFilterOpened={setIsFilterOpened} />, []);

    useEffect(() => {
        // eslint-disable-next-line no-undefined
        if (queries.ordering === undefined) {
            setReviewsStateUrlQuery({
                [ReviewsStateUrlQueryNameEnum.sorting]: ReviewSortingTypeEnum.acs,
            });
        }
    }, [queries.ordering]);

    useEffect(() => {
        setIsFilterOpened(!isMobile);
    }, [isMobile]);

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS')} />
            {newFilterComponent}
            <NeuralNetworkAlert />
            <div className={styles.Reviews_contentWrapper}>
                <div className={styles.Reviews_list}>
                    {isNewLayout ? <NewReviewList /> : <ReviewList />}
                    {hasReviews && !isReviewsInProgress && (
                        <CursorPaginator cursorPagination={cursorPagination} pageSizeOptions={reviewPageSizeOptions} />
                    )}
                </div>

                <div className={styles.Reviews_filter} hidden={!isFilterOpened}>
                    <ReviewsFilter />
                </div>
            </div>
        </Page>
    );
}
