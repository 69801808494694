import {Button} from 'antd';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {PageHeaderTitle} from '../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';

import {CompaniesGroupTable} from './companies-group-table/companies-group-table';

export function CompaniesGroup(): JSX.Element {
    const {getLocalizedString} = useLocale();

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__GROUPS_OF_COMPANIES')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.settingsCompaniesGroup.path,
                        titleLangKey: 'CATEGORY_NAME__GROUPS_OF_COMPANIES',
                    },
                ]}
            />

            <PageHeader>
                <PageHeaderTitle
                    productName={UsetifulNameProductEnum.GROUPS_OF_COMPANIES}
                    title="CATEGORY_NAME__GROUPS_OF_COMPANIES"
                />

                <Button type="primary">
                    <NavigationLink to={appRoute.settingsCompaniesGroupCreate.path}>
                        <Locale stringKey="GROUP_OF_COMPANIES__BUTTON__ADD_GROUP" />
                    </NavigationLink>
                </Button>
            </PageHeader>

            <PageSubHeader>
                <Locale stringKey="GROUP_OF_COMPANIES__SUB_HEADER" />
            </PageSubHeader>

            <CompaniesGroupTable />
        </Page>
    );
}
