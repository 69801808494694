import {QueryObserverBaseResult} from '@tanstack/react-query';
import {Dispatch, SetStateAction} from 'react';
import {z as r} from 'zod';

import {PaginationHookType, PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {generateResponseSchema, PaginatedResponseType} from '../api/api-type';

export enum GoogleLocationSourceEnum {
    Account = 'context',
    Maps = 'search',
}

export enum GoogleCclStatusEnum {
    // binding backend
    Synchronize = 'synced',
    Send = 'sent',
    ActionRequired = 'need_action',
    AwaitingRights = 'wait_rights',
    AwaitingConfirmation = 'need_verify',

    // binding frontend
    Skipped = 'FAKE_skipped',
    ReadyToSend = 'FAKE_ready_to_send',

    // confirmation
    AwaitingVerification = 'wait_verify',
}

export const googleCompanySchema = r.object({
    id: r.number(),
    name: r.string(),
    code: r.string().optional(),
    address: r.string(),
    locationAddress: r.string().nullable().optional(),
    websites: r.array(r.string()).optional(),
    phones: r.array(r.string()).optional(),
    categories: r.array(r.string()).optional(),
    status: r.nativeEnum(GoogleCclStatusEnum).optional(),
});

export type GoogleCompanyType = r.infer<typeof googleCompanySchema>;

export const googleLocationSchema = r.object({
    originId: r.string(),
    name: r.string(),
    category: r.string().nullable().optional(),
    address: r.string(),
    website: r.string(),
    phones: r.array(r.string()),
    mapsUrl: r.string().nullable(),
    adminRightsUrl: r.string().nullable().optional(),
    isVerified: r.boolean().optional(),
});

export type GoogleLocationType = r.infer<typeof googleLocationSchema>;

const googleCompanyWithCandidatesSchema = googleCompanySchema.extend({
    candidates: r
        .object({
            source: r.nativeEnum(GoogleLocationSourceEnum),
            data: r.array(googleLocationSchema),
        })
        .nullable(),
});

export type GoogleCompanyWithCandidatesType = r.infer<typeof googleCompanyWithCandidatesSchema>;

export const googleCompaniesSchema = generateResponseSchema(googleCompanySchema);

export type GoogleCompaniesType = r.infer<typeof googleCompaniesSchema>;

export type GoogleCompaniesOptionsType = {
    page: number;
    pageSize: number;
    search: string;
    brandsIds: Array<number>;
    companyId?: number | null;
};

export type GoogleCompaniesHookOptionsType = {
    search: string;
    brandsIds: Array<number>;
    onSuccess?: (data: GoogleCompaniesType, companyId?: number | null) => void;
};

export type GoogleCompaniesHookReturnType = Partial<PaginatedResponseType<GoogleCompanyType>> &
    PaginationType &
    Pick<QueryObserverBaseResult<GoogleCompanyType>, 'isInitialLoading'>;

export type GoogleProcessingCompaniesHookReturnType = {
    locationsLoadingProgress: QueryObserverBaseResult<GoogleLocationsLoadingProgressType>;
    companiesBindingProgress: QueryObserverBaseResult<GoogleCompaniesBindingProgressType>;
    actionRequiredCompaniesLength: number;
    pagination: PaginationHookType;
    onlyNeedAction: boolean;
    setOnlyNeedAction: Dispatch<SetStateAction<boolean>>;
};

export const googleLocationsLoadingProgressSchema = r.object({
    processed: r.number(),
});

export type GoogleLocationsLoadingProgressType = r.infer<typeof googleLocationsLoadingProgressSchema>;

export const googleCompaniesBindingProgressSchema = r.object({
    total: r.number(),
    processed: r.number(),
    offset: r.number(),
    companies: r.array(googleCompanyWithCandidatesSchema),
});

export type GoogleCompaniesBindingProgressType = r.infer<typeof googleCompaniesBindingProgressSchema>;

export type GoogleCompaniesBindingProgressOptionsType = {
    offset: number;
    limit: number;
    refreshId: string;
    processingKey?: string | null;
    onlyNeedAction?: boolean;
};

export const googleResolveCandidateSchema = r.object({
    error: r.string().optional(),
    nonFieldErrors: r.array(r.string()).optional(),
});

export type GoogleResolveCandidateType = r.infer<typeof googleResolveCandidateSchema>;

export type GoogleResolveCandidateOptionsType = {
    source?: GoogleLocationSourceEnum;
    originId: string | null;
    companyId: number;
    tokenId?: number | null;
};

export const googleStatisticsSchema = r.object({
    total: r.number(),
    synced: r.number(),
    sent: r.number(),
    notVerified: r.number(),
    waitRights: r.number(),
    needAction: r.number(),
    verificationStatistics: r.object({
        partner: r.number(),
        mass: r.number(),
        individual: r.number(),
    }),
});

export type GoogleStatisticsType = r.infer<typeof googleStatisticsSchema>;
