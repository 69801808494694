import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

import {fakesUrl} from './fakes-const';
import {FakeNamesComparingModeEnum, FakeSearchInCitiesEnum, FakeSubscriptionPlanEnum} from './fakes-type';

const fakesSubscriptionSchema = r.object({
    id: r.number(),
    brandId: r.number(),
    brandName: r.string(),
    plan: r.nativeEnum(FakeSubscriptionPlanEnum),
    expire: r.string(),
    namesComparingMode: r.nativeEnum(FakeNamesComparingModeEnum),
    language: r.string(),
    searchInCities: r.nativeEnum(FakeSearchInCitiesEnum),
    searchNames: r.array(r.string()),
});

const fakesSubscriptionsSchema = r.array(fakesSubscriptionSchema);

type FakesSubscriptionsType = r.infer<typeof fakesSubscriptionsSchema>;

const fakesSubscriptionsUrl = `${fakesUrl}/subscriptions/`;

function fetchFakesSubscriptions(): Promise<FakesSubscriptionsType> {
    return fetchAndDeserialize(fakesSubscriptionsUrl, fakesSubscriptionsSchema);
}

export function useFakesSubscriptions(): UseQueryResult<FakesSubscriptionsType> {
    return useQuery([fakesSubscriptionsUrl], () => fetchFakesSubscriptions(), {cacheTime: 0});
}
