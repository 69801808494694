import {useState} from 'react';

import {appRoute} from '../../../../app-route';
import {PageHeaderWithLicenseTag} from '../../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {TrialActivationModal} from '../../../../component/landing/trial-activation-modal/trial-activation-modal';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-container';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useTrialActivation} from '../../../../provider/license/license-hook';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {LicensesCodeEnum} from '../../tariffs-and-limits/licenses-block/license/license-const';

import {PhotoToolLanding} from './landing/photo-tool-landing';
import {PhotoToolOnboardingSteps} from './onboarding/photo-tool-onboarding-steps';
import * as styles from './photo-tool-activation.scss';

export function PhotoToolActivation(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const [showOnboardingSteps, setShowOnboardingSteps] = useState<boolean>(false);
    const [isLicenseCreated, setIsLicenseCreated] = useState<boolean>(false);

    const {mutateAsync, isLoading} = useTrialActivation();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__PHOTO_TOOL')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.photoToolIndex.path,
                            titleLangKey: 'CATEGORY_NAME__PHOTO_TOOL',
                        },
                    ]}
                />
                <PageHeaderWithLicenseTag
                    className={styles.PhotoToolActivation_pageHeader}
                    headerLangKey="CATEGORY_NAME__PHOTO_TOOL"
                    licenseActive={isLicenseCreated}
                    trialLicense
                />
            </PageCard>

            {!showOnboardingSteps && <PhotoToolLanding onTryFeatureClick={() => setIsLicenseCreated(true)} />}
            {showOnboardingSteps && <PhotoToolOnboardingSteps />}

            <TrialActivationModal
                activateTrial={() => mutateAsync(LicensesCodeEnum.PhototoolLicence)}
                isActivatingTrial={isLoading}
                onCancel={() => setIsLicenseCreated(false)}
                onTrialPeriodActivated={() => {
                    setIsLicenseCreated(false);
                    setShowOnboardingSteps(true);
                }}
                open={isLicenseCreated}
                productNameLangKey="CATEGORY_NAME__PHOTO_TOOL"
            />
        </Page>
    );
}
