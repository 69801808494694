export enum KnowledgeFilterEnum {
    name = 'name',
}

export type KnowledgeFilterType = {
    [KnowledgeFilterEnum.name]: string;
};

export type UseKnowledgeFilterType = {
    filter: KnowledgeFilterType;
    isFilterEmpty: boolean;
    setFilter: (filter: Partial<KnowledgeFilterType>) => void;
};
