import {useMemo} from 'react';

import {CATALOG_TYPES} from '../../../service/ccl-statistic/ccl-statistic-const';
import {CatalogNameType, CclStatusEnum} from '../../../service/ccl-statistic/ccl-statistic-type';
import {CatalogTypeSearchParameterEnum} from '../../../shared-search-parameters';
import {findInArrayByValue, findInArrayByValueEnsure} from '../../../util/array';
import {splitCommaSeparatedString, toTrimmedString} from '../../../util/string';
import {useUrl} from '../../../util/url-hook/url-hook';

import {FILTERABLE_CCL_STATUSES} from './sources-const';
import {SourcesSearchParametersEnum} from './sources-type';

export function useSourcesUrlQuery(): {
    sourceIds: Array<string>;
    companyIds: Array<string>;
    catalogType: CatalogNameType;
    cclStatus: CclStatusEnum | null;
} {
    const {getQuery} = useUrl();

    const sourceIdsUrl = getQuery(SourcesSearchParametersEnum.SourceIdList) || '';
    const sourceIds = useMemo(() => sourceIdsUrl.split(',').map(toTrimmedString).filter(Boolean), [sourceIdsUrl]);

    const companyIdsUrl = getQuery(SourcesSearchParametersEnum.CompanyIdList) || '';
    const companyIds = useMemo(() => splitCommaSeparatedString(companyIdsUrl), [companyIdsUrl]);

    const catalogType = findInArrayByValueEnsure(CATALOG_TYPES, getQuery(CatalogTypeSearchParameterEnum.catalogType));

    const cclStatus = findInArrayByValue(FILTERABLE_CCL_STATUSES, getQuery(SourcesSearchParametersEnum.CclStatus));

    return {sourceIds, companyIds, catalogType, cclStatus};
}
