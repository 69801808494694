import {useMutation, UseMutationResult} from '@tanstack/react-query';

import {Locale} from '../../provider/locale/localization';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {useFormat} from '../../util/format-hook/format-hook';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {companiesImportListUrl} from './companies-import-list';

export type MutateFeedAutoUpdateType = {
    feedId: number;
    value: boolean;
};

function changeAutoUpdateMode(feedId: number, value: boolean): Promise<void> {
    return fetchNoContent(`${rootApiUrl}${companiesImportListUrl}${feedId}/`, {
        method: FetchMethodEnum.patch,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serialize({periodicUpdate: value})),
    });
}

export function useChangeFeedAutoUpdateMutation(
    refetch: () => void
): UseMutationResult<void, unknown, MutateFeedAutoUpdateType, unknown> {
    const {snackbar} = useSnackbar();
    const {modal} = useModal();
    const {getFormattedDateTime} = useFormat();

    async function mutate(parameters: MutateFeedAutoUpdateType): Promise<void> {
        const {feedId, value} = parameters;

        return new Promise((resolve, reject) =>
            modal.confirm({
                title: (
                    <Locale
                        stringKey={
                            value
                                ? 'FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_ON'
                                : 'FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_OFF'
                        }
                    />
                ),
                content: value ? (
                    ''
                ) : (
                    <Locale stringKey="FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_OFF_DESCRIPTION" />
                ),
                cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                okText: (
                    <Locale
                        stringKey={
                            value
                                ? 'FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_ON_BUTTON'
                                : 'FEED__MANAGEMENT__CHANGE_UPDATE__POPUP__TURN_OFF_BUTTON'
                        }
                    />
                ),
                okType: 'default',
                onOk: () => {
                    resolve(changeAutoUpdateMode(feedId, value));
                },
                onCancel: () => {
                    reject();
                },
            })
        );
    }

    function onSuccess(_data: void, parameters: MutateFeedAutoUpdateType) {
        const {value} = parameters;

        refetch();
        snackbar.success({
            message: (
                <Locale
                    stringKey={
                        value
                            ? 'FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_ON__TITLE'
                            : 'FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_OFF__TITLE'
                    }
                />
            ),
            description: (
                <Locale
                    stringKey={
                        value
                            ? 'FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_ON__DESCRIPTION'
                            : 'FEED__MANAGEMENT__CHANGE_UPDATE__SNACKBAR__AUTOUPDATE_OFF__DESCRIPTION'
                    }
                    valueMap={{
                        date: getFormattedDateTime(new Date().setDate(new Date().getDate() + 1), {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                        }),
                    }}
                />
            ),
        });
    }

    return useMutation(mutate, {onSuccess});
}
