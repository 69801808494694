import {faCopy as faRegCopy} from '@fortawesome/pro-regular-svg-icons';
import {faCopy as faSolidCopy} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Typography} from 'antd';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {Text} from '../../../../../component/text/text';
import {QrCode} from '../../../../../layout/qr-code/qr-code';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useMessage} from '../../../../../provider/message/message-hook';
import {use2faEnableCode} from '../../../../../service/user-2fa/user-2fa-hook';

import * as styles from './two-factor-qr-code.scss';

export function TwoFactorQrCode(): JSX.Element {
    const {authId} = useParams<ExtractRouteParams<typeof appRoute.twoFactorAuthenticationSetup.path, string>>();

    const {result: twoFaEnableCodeResult} = use2faEnableCode(authId);
    const {message} = useMessage();
    const {getLocalizedString} = useLocale();

    const qrCodeUrl = twoFaEnableCodeResult?.otp_uri;
    const qrCodeUrlSearchParameters = qrCodeUrl ? new URLSearchParams(new URL(qrCodeUrl).search) : null;
    const secret = qrCodeUrlSearchParameters?.get('secret');

    return (
        <div className={styles.TwoFactorQrCode}>
            <QrCode text={qrCodeUrl || ''} />

            {secret && (
                <>
                    <Divider>
                        <Text secondary small stringKey="PROFILE__TWO_FACTOR_AUTHENTICATION__CODE" />
                    </Divider>

                    <Typography.Paragraph
                        className={styles.TwoFactorQrCode_codeParagraph}
                        copyable={{
                            text: secret,
                            icon: [
                                <FontAwesomeIcon
                                    className={styles.TwoFactorQrCode_iconCopy}
                                    icon={faRegCopy}
                                    key="faRegCopy"
                                />,
                                <FontAwesomeIcon
                                    className={styles.TwoFactorQrCode_iconCopied}
                                    icon={faSolidCopy}
                                    key="faSolidCopy"
                                />,
                            ],
                            tooltips: false,
                            onCopy: () =>
                                message.success(getLocalizedString('PROFILE__TWO_FACTOR_AUTHENTICATION__CODE_COPIED')),
                        }}
                    >
                        <Typography.Text className={styles.TwoFactorQrCode_code} code>
                            {secret}
                        </Typography.Text>
                    </Typography.Paragraph>
                </>
            )}
        </div>
    );
}
