import {Table} from 'antd';

import {CursorPaginator} from '../../../../../component/cursor-paginator/cursor-paginator';
import {useLocalPackRules} from '../../../../../service/local-pack/rules/local-pack-rule-hook';
import {defaultPageSizeOptions} from '../../../../../util/cursor-pagination/cursor-pagination-const';

import {localPackRulesTableColumns, renderRuleTableItem} from './local-pack-rules-table-helper';
import {LocalPackRulesTableItemType} from './local-pack-rules-table-type';
import * as styles from './local-pack-rules-table.scss';

export function LocalPackRulesTable(): JSX.Element {
    const {data, isLoading, pagination, refetch} = useLocalPackRules();

    const dataSource = data?.results.map((item) => renderRuleTableItem(item, refetch)) || [];

    return (
        <div>
            <Table<LocalPackRulesTableItemType>
                bordered={false}
                columns={localPackRulesTableColumns}
                dataSource={dataSource}
                loading={isLoading}
                pagination={false}
                rowClassName={(record) => (record.children ? '' : styles.LocalPackRulesTable_childRow)}
                rowKey={(record) => record.id}
                scroll={{x: true}}
                size="middle"
            />

            <CursorPaginator cursorPagination={pagination} pageSizeOptions={defaultPageSizeOptions} />
        </div>
    );
}
