import {serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX, ResponseOkType} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {User2faCodeType, User2faEnableType} from './user-2fa-type';

export function fetch2faEnableCode(authId?: string): Promise<User2faEnableType> {
    const url = rootApiUrl + '/user/2fa/enable/';

    if (!authId) {
        return fetchX<User2faEnableType>(url);
    }

    return fetchX<User2faEnableType>(url, {
        method: FetchMethodEnum.post,
        headers: mainApiHeaders,
        body: JSON.stringify(serializeToURLParameters({authId})),
    });
}

export function connect2fa(code: User2faCodeType, unauthorized?: boolean): Promise<ResponseOkType> {
    return fetchX<ResponseOkType>(rootApiUrl + '/user/2fa/verify_code/', {
        method: FetchMethodEnum.post,
        headers: unauthorized ? mainApiHeaders : {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(serializeToURLParameters(code)),
    });
}

export function disconnect2fa(code: User2faCodeType): Promise<ResponseOkType> {
    return fetchX<ResponseOkType>(rootApiUrl + '/user/2fa/disable/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(code),
    });
}
