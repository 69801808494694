import {LocalePlural} from '../../../../../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../../../../provider/locale/translation/type';
import {DEFAULT_MAX_IMAGE_SIZE_MB} from '../../../../../posts-form-helper';
import {ExtraContentValidationSettingsType, ImageContentValidationSettingsType} from '../../../content-step-type';

type PropsType = {
    requirements: ImageContentValidationSettingsType;
    extraRequirements?: ExtraContentValidationSettingsType;
};

export function ImagesRequirements(props: PropsType): JSX.Element {
    const {requirements, extraRequirements} = props;

    return (
        <>
            <Locale stringKey="POSTS_REQUIREMENTS__IMAGES" />
            <ul>
                <li>
                    <LocalePlural
                        count={requirements.maxCount}
                        fewKey="POSTS_REQUIREMENTS__IMAGES__RULE_1_FEW"
                        manyKey="POSTS_REQUIREMENTS__IMAGES__RULE_1_MANY"
                        singularKey="POSTS_REQUIREMENTS__IMAGES__RULE_1_SINGULAR"
                        valueMap={{
                            imageCount: requirements.maxCount,
                        }}
                    />
                </li>
                <li>
                    <Locale
                        stringKey="POSTS_REQUIREMENTS__IMAGES__RULE_2"
                        valueMap={{
                            minHeight: requirements.minHeight,
                            minWidth: requirements.minWidth,
                        }}
                    />
                </li>
                <li>
                    <Locale
                        stringKey="POSTS_REQUIREMENTS__IMAGES__RULE_3"
                        valueMap={{
                            maxHeight: requirements.maxHeight,
                            maxWidth: requirements.maxWidth,
                        }}
                    />
                </li>
                <li>
                    <Locale
                        stringKey="POSTS_REQUIREMENTS__IMAGES__RULE_4"
                        valueMap={{
                            maxSize: DEFAULT_MAX_IMAGE_SIZE_MB,
                        }}
                    />
                </li>
                <li>
                    <Locale
                        stringKey="POSTS_REQUIREMENTS__IMAGES__RULE_5"
                        valueMap={{
                            imageMime: requirements.imageMime.join(', '),
                        }}
                    />
                </li>
                {Object.values(extraRequirements?.images || {}).map((extra: LangKeyType) => {
                    return (
                        <li key={extra}>
                            <Locale stringKey={extra} />
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
