export const enBulkEditCompaniesTranslations = {
    BULK_EDIT_COMPANIES__HEADER: 'Bulk editing of companies ({count})',
    BULK_EDIT_COMPANIES__SUB_HEADER: 'Choose the fields in which you want to add or replace information.',
    BULK_EDIT_COMPANIES__BODY_TEXT: 'In some fields You can add data, while in others - only replace.',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__PLACEHOLDER: 'Choose fields for editing',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__CHOOSE_BUTTON: 'Choose fields',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADD_FIELD_BUTTON: 'Add fields',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__MODAL__TITLE: 'Fields for bulk editing',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__MODAL__HEADER: 'Choose one or more fields for editing',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__MODAL__SUB_HEADER:
        'You can add or remove fields at any time. In some fields, data may only be replaced',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADDING_ALLOWED: 'Adding allowed',
    BULK_EDIT_COMPANIES__CHOOSE_FIELDS__ADDING_ALLOWED_DESCRIPTION:
        'You can add new data to this field of the selected companies, without replacing existing data.',
    BULK_EDIT_COMPANIES__FIELD_MODE__ADDITION: 'Add to selected companies',
    BULK_EDIT_COMPANIES__FIELD_MODE__ADDITION_HEADER: 'Adding data to field',
    BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT: 'Replace in selected companies',
    BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT_HEADER: 'Full replacement of data in field',
    BULK_EDIT_COMPANIES__FIELD_MODE__REPLACEMENT_DESCRIPTION:
        'Entered data will replace existing data in this field in selected companies',
    BULK_EDIT_COMPANIES__FIELD_LABEL__NAME: 'Name',
    BULK_EDIT_COMPANIES__FIELD_LABEL__ALTERNATIVE_NAME: 'Alternative names',
    BULK_EDIT_COMPANIES__FIELD_LABEL__CATEGORIES: 'Categories',
    BULK_EDIT_COMPANIES__FIELD_LABEL__EMAIL: 'Email address(es)',
    BULK_EDIT_COMPANIES__FIELD_LABEL__STATUS_TEMPORARILY_CLOSED: 'Status: Temporarily closed',
    BULK_EDIT_COMPANIES__BUTTON__UPDATE: 'Update information',
    BULK_EDIT_COMPANIES__BUTTON__CANCEL: 'Cancel changes',
    BULK_EDIT_COMPANIES__SELECTED__SINGULAR: '{count} company selected',
    BULK_EDIT_COMPANIES__SELECTED__FEW: '{count} companies selected',
    BULK_EDIT_COMPANIES__SELECTED__MANY: '{count} companies selected',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS_FIELD_MODE__ADDITION: 'Adding',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS_FIELD_MODE__REPLACEMENT: 'Replacement',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS_HEADER: 'Bulk editing',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS_SUB_HEADER__SINGULAR: '{count} company will be changed. Confirm next changes?',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS_SUB_HEADER__FEW: '{count} companies will be changed. Confirm next changes?',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS_SUB_HEADER__MANY: '{count} companies will be changed. Confirm next changes?',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS__BUTTON_CONFIRM: 'Confirm changes',
    BULK_EDIT_COMPANIES__CONFIRM_FIELDS__BUTTON_CANCEL: 'Cancel',
    BULK_EDIT_COMPANIES__CONFIRM_CANCEL_MODAL__TITLE: 'Changes are unsaved',
    BULK_EDIT_COMPANIES__CONFIRM_CANCEL_MODAL__BODY: 'Are you sure you want to cancel changes for selected companies?',
    BULK_EDIT_COMPANIES__CATEGORIES_FIELD_ADDITIONAL_INFO:
        'This category will be added as main if main is missed in company',
    BULK_EDIT_COMPANIES__PHONE_FIELD_ADDITIONAL_INFO: 'This phone will be added as main',
    BULK_EDIT_COMPANIES__WEBSITE_FIELD_ADDITIONAL_INFO: 'This website will be added as main',
    BULK_EDIT_COMPANIES__UPDATED_SUCCESSFULLY_HEAD: 'Companies updated',
    BULK_EDIT_COMPANIES__UPDATED_SUCCESSFULLY_BODY:
        'Information in {count} companies will be updated in several minutes',
    BULK_EDIT_COMPANIES__ERROR_HEAD: 'Error occurred',
    BULK_EDIT_COMPANIES__ERROR_GALLERY: 'Please, check uploaded images',
    BULK_EDIT_COMPANIES__IMAGE_UPLOAD_ERROR__TITLE: 'Upload failed',
    BULK_EDIT_COMPANIES__IMAGE_UPLOAD_ERROR__UNKNOWN_ERROR: 'Unknown error',
    BULK_EDIT_COMPANIES__LABEL_WEBSITE: 'Websites',
};
