import {isObjectInclude} from './object';

export function arrayMove<ItemType>(list: Array<ItemType>, fromIndex: number, toIndex: number): Array<ItemType> {
    const item = list[fromIndex];

    if (!item) {
        return list;
    }

    list.splice(fromIndex, 1);
    list.splice(toIndex, 0, item);

    return list;
}

export function findInArray<ItemType extends Record<string, unknown>>(
    list: Array<ItemType>,
    query: Partial<ItemType>
): ItemType | null {
    return list.find((item: ItemType): boolean => isObjectInclude<ItemType>(item, query)) || null;
}

export function findInArrayEnsure<ItemType extends Record<string, unknown>>(
    list: Array<ItemType>,
    query: Partial<ItemType>,
    defaultValue: ItemType
): ItemType {
    return findInArray<ItemType>(list, query) || defaultValue;
}

// export function findManyInArray<ItemType>(list: Array<ItemType>, query: Record<string, unknown>): Array<ItemType> {
//     return list.filter((item: ItemType): boolean => isObjectInclude<ItemType>(item, query));
// }

export function findInArrayByValue<ItemType>(list: Array<ItemType>, value: unknown): ItemType | null {
    return list.find((item: ItemType): boolean => item === value) || null;
}

export function findInArrayByValueEnsure<ItemType>(
    list: Array<ItemType>,
    value: unknown,
    defaultValue?: ItemType
): ItemType {
    const findResult = findInArrayByValue<ItemType>(list, value);

    if (findResult === null) {
        const fallback = defaultValue || list[0];

        if (!fallback) {
            throw new Error('no fallback found');
        }

        return fallback;
    }

    return findResult;
}

export function getUniqueListByKey<ItemType>(list: Array<ItemType>, keyName: keyof ItemType): Array<ItemType> {
    const savedValueList: Array<unknown> = [];

    return list.reduce<Array<ItemType>>((accum: Array<ItemType>, item: ItemType): Array<ItemType> => {
        const value = item[keyName];

        if (!savedValueList.includes(value)) {
            savedValueList.push(value);
            accum.push(item);
        }

        return accum;
    }, []);
}

export function replaceInList<ItemType>(list: Array<ItemType>, oldItem: ItemType, newItem: ItemType): Array<ItemType> {
    const index = list.indexOf(oldItem);

    if (index === -1) {
        throw new Error('[ERROR]: Can not replace item in replaceInList function');
    }

    const newItemList = [...list];

    newItemList[index] = newItem;

    return newItemList;
}

export function isArraysEqual<ItemType>(arrayA: Array<ItemType>, arrayB: Array<ItemType>): boolean {
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    return arrayA.every((itemA, index) => {
        return itemA === arrayB[index];
    });
}

export function isArraysEqualByField<ItemType>(
    arrayA: Array<ItemType>,
    arrayB: Array<ItemType>,
    fields: keyof ItemType | Array<keyof ItemType>
): boolean {
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    return arrayA.every((itemA, index) => {
        if (Array.isArray(fields)) {
            return fields.every((field) => itemA[field] === arrayB[index]?.[field]);
        }

        return itemA[fields] === arrayB[index]?.[fields];
    });
}
