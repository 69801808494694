import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchReviewsCountByRating} from './reviews-count-by-rating-api';
import {ReviewsCountByRatingType} from './reviews-count-by-rating-type';

export function useReviewsCountByRating(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<ReviewsCountByRatingType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['reviewsCountByRating', filter, mainFilterKey],
        queryFn: async () => {
            return fetchReviewsCountByRating(filter, mainFilterKey);
        },
        cacheTime: 0,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}
