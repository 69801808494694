import {SyntheticEvent, useEffect, useState} from 'react';

import {AntdErrorMessage} from '../../../../../../../component/antd-error-message/antd-error-message';
import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useCompanyMediaFile} from '../../../../../../../service/company/company-hook';
import {CompanyImageKindEnum, CompanyUploadMediaFileErrorType} from '../../../../../../../service/company/company-type';
import {CompanyGalleryImageType} from '../../../../../../../service/company-v2/types/company-gallery-type';
import {classNames} from '../../../../../../../util/css';
import {useRefreshId} from '../../../../../../../util/hook';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {InfoForImage} from '../../../info-for-image/info-for-image';
import {UploadButton} from '../../logo/input-image/upload-button/upload-button';

import {GalleryImage} from './gallery-image/gallery-image';
import * as styles from './gallery-form-item.scss';

type PropsType = {
    value?: Array<CompanyGalleryImageType>;
    onChange?: (inputImageList: Array<CompanyGalleryImageType>) => void;
    errorMessage?: Array<string>;
    setGalleryError: (error: Array<string> | null) => void;
    catalogId?: number;
};

export function GalleryFormItem(props: PropsType): JSX.Element {
    const {value: images = [], onChange: setImages, errorMessage, setGalleryError, catalogId} = props;

    const {reset, uploadCompanyMediaFile} = useCompanyMediaFile();
    const {refreshId, refresh} = useRefreshId();
    const {getLocalizedString} = useLocale();

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [inputActive, setInputActive] = useState<boolean>(false);

    async function uploadFile(file: File) {
        const objectUrl = URL.createObjectURL(file);

        try {
            const {temp_id} = await uploadCompanyMediaFile({
                file,
                file_type: CompanyImageKindEnum.gallery,
                ...(catalogId ? {catalogId} : {}),
            });

            return {tempId: temp_id, objectUrl};
        } catch (error: unknown) {
            return {
                objectUrl,
                size: file.size,
                errorMessage:
                    error instanceof Error
                        ? ((JSON.parse(error.message) as CompanyUploadMediaFileErrorType) || {})?.file_s3
                        : [getLocalizedString('NOTIFICATION__TEXT__SERVER_ERROR')],
            };
        }
    }

    async function handleOnChange(event: SyntheticEvent<HTMLInputElement>) {
        setInProgress(true);

        const {files} = event.currentTarget;
        const fileList: Array<File> = [...(files || [])];
        const uploadedImages = await Promise.all(fileList.map(uploadFile));

        setImages?.([...images, ...uploadedImages]);
        setInputActive(false);
        refresh();
        reset();

        setInProgress(false);
    }

    useEffect(() => {
        setGalleryError(
            images.some(({errorMessage: _errorMessage}) => _errorMessage)
                ? [getLocalizedString('COMPANY_FORM__GALLERY__ERROR')]
                : null
        );
    }, [getLocalizedString, images, setGalleryError]);

    const isDoubleGisCatalog = catalogId && catalogId === ProvidersIdsEnum.doubleGis;

    return (
        <>
            <InfoForImage
                additionalNode={
                    isDoubleGisCatalog ? <Locale stringKey="COMPANY_FORM__GALLERY__HINT__2GIS__PROPORTIONS" /> : null
                }
                fileFormats={
                    isDoubleGisCatalog
                        ? 'COMPANY_FORM__GALLERY__HINT__2GIS__FILE_FORMATS'
                        : 'COMPANY_FORM__GALLERY__HINT__FILE_FORMATS'
                }
                label="COMPANY_FORM__GALLERY__LABEL"
                maxResolution={
                    isDoubleGisCatalog
                        ? 'COMPANY_FORM__GALLERY__HINT__2GIS__MAX_RESOLUTION'
                        : 'COMPANY_FORM__GALLERY__HINT__MAX_RESOLUTION'
                }
                maxSize="COMPANY_FORM__GALLERY__HINT__MAX_SIZE"
                minResolution={
                    isDoubleGisCatalog
                        ? 'COMPANY_FORM__GALLERY__HINT__2GIS__MIN_RESOLUTION'
                        : 'COMPANY_FORM__GALLERY__HINT__MIN_RESOLUTION'
                }
                minSize="COMPANY_FORM__GALLERY__HINT__MIN_SIZE"
                title="COMPANY_FORM__GALLERY__HINT__HEADER"
            />

            <div className={styles.input_image_list}>
                {inProgress && (
                    <div className={styles.spinner__container}>
                        <Spinner />
                    </div>
                )}

                {images.map((inputImage: CompanyGalleryImageType, index: number): JSX.Element => {
                    const {tempId, hash, url, errorMessage: _errorMessage} = inputImage;

                    return (
                        <GalleryImage
                            className={classNames(_errorMessage ? styles.image_error : '')}
                            errorMessage={_errorMessage}
                            inputImage={inputImage}
                            key={`${String(index)}-${tempId || hash}-${url}`}
                            onRemove={() => setImages?.(images.filter((_images, _index) => index !== _index))}
                        />
                    );
                })}

                <div className={classNames(styles.upload_place)}>
                    <input
                        accept="image/jpeg, image/jpg, image/png, image/gif"
                        className={styles.input_file}
                        key={refreshId + '-input'}
                        multiple
                        onChange={handleOnChange}
                        onDragEnter={() => setInputActive(true)}
                        onDragLeave={() => setInputActive(false)}
                        type="file"
                    />

                    <UploadButton className={classNames(inputActive ? styles.upload_place_active : '')} />
                </div>
            </div>

            {errorMessage && <AntdErrorMessage className={styles.error}>{errorMessage}</AntdErrorMessage>}
        </>
    );
}
