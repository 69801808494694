import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {useHistory} from 'react-router';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {LegalAgreementTypeEnum} from '../../layout/legal-agreement/legal-agreement-type';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {deserializeApiError, postAndDeserialize} from '../../util/api-adapter';

import {userUrl} from './user-const';

export const registerSchema = r.object({
    firstName: r.string(),
    lastName: r.string().nullable(),
    email: r.string(),
    phone: r.string(),
    country: r.number(),
    agreement: r.nativeEnum(LegalAgreementTypeEnum),
    rulesConfirmation: r.boolean(),
    language: r.nativeEnum(ShortLocaleNameEnum),
});

export type RegisterType = r.infer<typeof registerSchema>;

export const registerErrorSchema = r.object({
    firstName: r.array(r.string()).optional(),
    lastName: r.array(r.string()).optional(),
    email: r.array(r.string()).optional(),
    phone: r.array(r.string()).optional(),
});

export type RegisterErrorType = r.infer<typeof registerErrorSchema>;

export enum RegisterFormKeyEnum {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    Phone = 'phone',
    Country = 'country',
    Password = 'password',
    Agreement = 'agreement',
    RulesConfirmation = 'rulesConfirmation',
}

export type RegisterOptionsType = Omit<RegisterType, 'country'> & {
    country: number | null;
    password: string;
};

export type RegisterFormType = Omit<RegisterOptionsType, 'language'>;

const url = `${userUrl}/register/`;

function register(options: RegisterOptionsType, shortLocaleName: ShortLocaleNameEnum): Promise<RegisterType> {
    return postAndDeserialize(url, registerSchema, options, {unauthorized: true, shortLocaleName});
}

export function useRegisterMutation(): UseMutationResult<RegisterType, unknown, RegisterFormType> {
    const {push} = useHistory();
    const {snackbar} = useSnackbar();
    const {shortLocaleName} = useLocale();

    function mutate(options: RegisterFormType) {
        return register(
            {
                ...options,
                firstName: options.firstName.trim(),
                lastName: options.lastName?.trim() ?? '',
                email: options.email.trim(),
                phone: options.phone.trim(),
                password: options.password.trim(),
                language: shortLocaleName,
            },
            shortLocaleName
        );
    }

    async function onSuccess() {
        snackbar.success({
            message: <Locale stringKey="PAGE__REGISTER__NOTIFICATION__SUCCESS__HEADER" />,
            description: <Locale stringKey="PAGE__REGISTER__NOTIFICATION__SUCCESS__CONTENT" />,
        });

        push(appRoute.login.path);
    }

    function onError(error: unknown) {
        const apiError = deserializeApiError<RegisterErrorType>(url, registerErrorSchema, error);

        if (apiError?.firstName?.[0] || apiError?.lastName?.[0] || apiError?.email?.[0] || apiError?.phone?.[0]) {
            return;
        }

        snackbar.error({
            message: <Locale stringKey="PAGE__REGISTER__NOTIFICATION__ERROR__HEADER" />,
            description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
        });
    }

    return useMutation(mutate, {onSuccess, onError});
}
