import {ZodType} from 'zod';

export function saveToLocalStorage<SavedDataType>(content: SavedDataType, key: string): void {
    if (typeof localStorage === 'undefined') {
        console.log('[ERROR]: saveToLocalStorage: localStorage is not defined');
        return;
    }

    localStorage.setItem(key, JSON.stringify(content));
}

export function getFromLocalStorage<DataType>(key: string, schema: ZodType, fallbackData: DataType): DataType {
    if (typeof localStorage === 'undefined') {
        return fallbackData;
    }

    const keyList = Object.keys(localStorage);

    if (!keyList.includes(key)) {
        return fallbackData;
    }

    const parsedData: DataType = JSON.parse(localStorage.getItem(key) || '');

    const {success} = schema.safeParse(parsedData);

    return success ? parsedData : fallbackData;
}
