import {TimezoneType} from '../../../../service/api/api-type';

export const defaultTimezone: TimezoneType = {
    name: '',
    name_for_display: '',
    offset: '',
};

export enum DeleteProfileFormFieldsEnum {
    password = 'password',
}
