import {faClose} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Popover, Space} from 'antd';
import {ReactElement} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../provider/locale/localization';

import {cclOnboardingTranslationMap} from './onboarding-popover-const';
import * as styles from './onboarding-popover.scss';

type PropsType = {
    statusText: ReactElement;
    onClose: () => void;
};

export function OnboardingPopover(props: PropsType): ReactElement {
    const {statusText, onClose} = props;

    const {sources} = useDocumentationLinks();

    return (
        <Popover
            autoAdjustOverflow={false}
            content={
                <div>
                    <Text className={styles.SyncPopover_title} stringKey="SOURCES__ONBOARDING__POPOVER__DESCRIPTION" />
                    <ul>
                        {Object.values(cclOnboardingTranslationMap).map((status) => (
                            <li key={status}>
                                <Text className={styles.SyncPopover_title} stringKey={status} />
                            </li>
                        ))}
                    </ul>
                    <Space className={styles.SyncPopover_buttonsBlock}>
                        <a href={sources.status} rel="noreferrer" target="_blank">
                            <Button className={styles.SyncPopover_linkButton} size="small" type="primary">
                                <Locale stringKey="SOURCES__ONBOARDING__BUTTON__LINK" />
                            </Button>
                        </a>

                        <Button onClick={onClose} size="small">
                            <Text
                                className={styles.SyncPopover_submitButton}
                                stringKey="SOURCES__ONBOARDING__BUTTON__SUBMIT"
                            />
                        </Button>
                    </Space>
                </div>
            }
            open
            placement="top"
            rootClassName={styles.SyncPopover}
            title={
                <Space className={styles.SyncPopover_titleBlock}>
                    <Text bolder className={styles.SyncPopover_title} stringKey="SOURCES__ONBOARDING__POPOVER__TITLE" />
                    <FontAwesomeIcon className={styles.SyncPopover_icon} icon={faClose} onClick={onClose} />
                </Space>
            }
        >
            <div>{statusText}</div>
        </Popover>
    );
}
