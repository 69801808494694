import {PageCardDivider} from '../../../../../component/page-card-divider/page-card-divider';
import {CclStatistics} from '../ccl-statistics/ccl-statistics';

import {SyncStatisticCatalogsInfo} from './sync-statistic-catalogs-info';

type PropsType = {
    showCatalogSyncStats: boolean;
};

export function SyncStatistics(props: PropsType): JSX.Element {
    const {showCatalogSyncStats} = props;

    return (
        <>
            <CclStatistics />
            <PageCardDivider />
            {/* TODO Remove this workaround-condition when https://bizdata.atlassian.net/browse/BST-2540 is ready */}
            {showCatalogSyncStats && <SyncStatisticCatalogsInfo />}
        </>
    );
}
