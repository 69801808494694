import {TableColumnsType} from 'antd';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../provider/locale/locale';
import {
    getAvgResponseDelayColumn,
    getRateColumn,
    getRepliedWithPercentageColumn,
    getSentimentColumn,
    getTotalColumn,
    getTotalWithPercentageColumn,
    getTrendColumn,
} from '../../components/table/reviews-analysis-table-helper';

import {ConcordanceLink} from './concordance-link/concordance-link';
import {ReviewsAnalysisTagTableDataType, TopicTableDataType} from './keywords-and-topics-page-type';

export const tagsPageTableColumns: TableColumnsType<ReviewsAnalysisTagTableDataType> = [
    {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__TAG" />,
        dataIndex: 'tag',
        key: 'tag',
        render: (_value: unknown, record: ReviewsAnalysisTagTableDataType): JSX.Element => (
            <ConcordanceLink query={record.tag}>{record.tag}</ConcordanceLink>
        ),
    },
    getRateColumn<ReviewsAnalysisTagTableDataType>(),
    getTotalWithPercentageColumn<ReviewsAnalysisTagTableDataType>(),
    getRepliedWithPercentageColumn<ReviewsAnalysisTagTableDataType>(),
    getAvgResponseDelayColumn<ReviewsAnalysisTagTableDataType>(),
    getTrendColumn<ReviewsAnalysisTagTableDataType>(),
    getSentimentColumn<ReviewsAnalysisTagTableDataType>(),
];

export const topicsPageTableColumns: TableColumnsType<TopicTableDataType> = [
    {
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__TOPIC" />,
        dataIndex: 'topic',
        key: 'topic',
        render: (_value: unknown, record: TopicTableDataType): JSX.Element => {
            if (!record.userTopic || !record.userTopicId) {
                return <span>{record.topic}</span>;
            }

            return (
                <NavigationLink to={generatePath(appRoute.reviewsAnalysisTopic.path, {id: record.userTopicId})}>
                    {record.topic}
                </NavigationLink>
            );
        },
    },
    getRateColumn<TopicTableDataType>(),
    getTotalColumn<TopicTableDataType>(),
    getRepliedWithPercentageColumn<TopicTableDataType>(),
    getAvgResponseDelayColumn<TopicTableDataType>(),
    getSentimentColumn<TopicTableDataType>(),
    getTrendColumn<TopicTableDataType>(),
    {
        align: 'center',
        title: <Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__ACTIONS" />,
        dataIndex: 'action',
        key: 'action',
    },
];
