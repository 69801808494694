import {Empty, Table} from 'antd';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../layout/spinner/spinner';
import {usePrices} from '../../../../service/company-activity-and-summary/company-activity-and-summary-hook';
import {CompanyPricesType} from '../../../../service/company-activity-and-summary/company-activity-and-summary-type';
import {WidgetFooterText} from '../components/widget-footer-text/widget-footer-text';

import {SINGLE_COMPANY_PRICES_COLUMNS} from './prices-const';
import {PricesEmpty} from './prices-empty';
import * as styles from './prices.scss';

type PropsType = {
    companyId: number;
};

export function Prices(props: PropsType): JSX.Element {
    const {companyId} = props;
    const {processError, result, fetchPricesErrorCode} = usePrices(companyId);

    const isEmpty = !result || result.every((item) => item.product_count === 0);

    if (fetchPricesErrorCode === 404) {
        return <Empty />;
    }

    if (processError) {
        return <AlertFallback />;
    }

    if (result) {
        return (
            <div>
                {!isEmpty ? (
                    <>
                        <Table<CompanyPricesType>
                            className={styles.PricesTable}
                            columns={SINGLE_COMPANY_PRICES_COLUMNS}
                            dataSource={result}
                            pagination={false}
                            rowKey={(record) => record.catalog.id}
                            size="small"
                        />
                        <WidgetFooterText
                            linkHref={appRoute.products.path}
                            linkLangKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_FOOTER_TEXT_PLACEHOLDER"
                            textLangKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__PRICES_FOOTER_TEXT"
                        />
                    </>
                ) : (
                    <PricesEmpty />
                )}
            </div>
        );
    }

    return (
        <div className={styles.PricesTable_spinner}>
            <Spinner />
        </div>
    );
}
