import {Form, Space} from 'antd';
import {ReactElement, useMemo} from 'react';

import {Locale} from '../../../../../../../provider/locale/locale';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {EditUserType} from '../../../../../../../service/user-management/user-management-edit-user';
import {UsersFormKeyEnum, UsersFormType} from '../../../../../../../service/user-management/user-management-users';
import {FormInstance} from '../../../../../../../typings/antd';
import {UserRulesSelect} from '../../user-rules-select/user-rules-select';

import {useUserAvailableBrands} from './brand-rules-tab-hook';
import * as styles from './brand-rules-tab.scss';

type PropsType = {
    form: FormInstance<UsersFormType>;
    user?: EditUserType;
    userId?: number;
};

export function BrandRulesTab(props: PropsType): ReactElement {
    const {form, user, userId} = props;

    const {data: brands = [], isLoading} = useUserAvailableBrands(userId);
    const {requiredFieldRule} = useFormRules();
    const currentValue = Form.useWatch(UsersFormKeyEnum.Brands, form);

    const initialBrandData = useMemo(() => user?.brands, [user?.brands]);
    const options = useMemo(
        () =>
            brands.map((item) => {
                return {value: item.id, label: item.name, disabled: !item.isAvailable};
            }),
        [brands]
    );

    return (
        <Space
            className={styles.AccessRulesTab}
            direction="vertical"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
            }}
        >
            <Form.Item
                className={styles.AccessRulesTab_select}
                name={UsersFormKeyEnum.Brands}
                rules={[requiredFieldRule]}
            >
                <UserRulesSelect
                    allSelectPlaceholder={<Locale stringKey="GROUP_OF_COMPANIES__GROUP_MANAGEMENT__ALL_BRANDS" />}
                    handleFormChange={(value) => form.setFieldValue(UsersFormKeyEnum.Brands, value)}
                    initialData={initialBrandData}
                    isLoading={isLoading}
                    options={options}
                    placeholder={<Locale stringKey="USERS__TABLE__COLUMN__BRANDS" />}
                    selectValue={currentValue || []}
                />
            </Form.Item>
        </Space>
    );
}
