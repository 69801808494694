import {
    centrifugeDeployNotificationSchema,
    CentrifugoDeployNotificationType,
} from './centrifuge-deploy-notifications-type';

export function isCentrifugoDeployNotification(payload: unknown): payload is CentrifugoDeployNotificationType {
    return centrifugeDeployNotificationSchema.safeParse(payload).success;
}

export const CENTRIFUGE_PUBLIC_CHANNEL = 'public:public';
