import {InputRef, Modal} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useRef} from 'react';

import {Locale} from '../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {NoteFormType, ReviewNoteType} from '../../../../../../../../service/reviews/reviews-notes';
import {Form} from '../../../../../../../../typings/antd';

import {noteFormId} from './note-modal-const';

type PropsType = {
    reviewId: number;
    onSubmit: (formValues: NoteFormType) => Promise<ReviewNoteType>;
    isLoading: boolean;
    onCancel: Dispatch<SetStateAction<boolean>>;
    editableNoteText?: string;
    title: ReactNode;
};

export function NoteModal(props: PropsType): ReactElement {
    const {reviewId, onSubmit, isLoading, onCancel, editableNoteText, title} = props;
    const {getLocalizedString} = useLocale();
    const formId = `${noteFormId}${reviewId}`;

    const [form] = Form.useForm<NoteFormType>();
    const {requiredFieldTrimmedTextRule} = useFormRules();
    const autoFocusRef = useRef<InputRef | null>(null);

    async function onSubmitForm(formValues: NoteFormType) {
        await onSubmit(formValues);
        form.resetFields();
    }

    useEffect(() => {
        if (editableNoteText && editableNoteText !== '') {
            form.setFieldValue('text', editableNoteText);
        }
    }, [editableNoteText, form]);

    return (
        <Form<NoteFormType> form={form} id={formId} onFinish={onSubmitForm}>
            <Modal
                afterOpenChange={(open) => open && autoFocusRef.current?.focus()}
                okButtonProps={{htmlType: 'submit', loading: isLoading, form: formId}}
                okText={<Locale stringKey="BUTTON__SAVE" />}
                onCancel={() => onCancel(false)}
                open
                title={title}
            >
                <Form.Item name="text" rules={[requiredFieldTrimmedTextRule()]}>
                    <TextArea
                        placeholder={getLocalizedString('REVIEWS__ENTER_YOUR_NOTE_TEXT')}
                        ref={autoFocusRef}
                        rows={5}
                    />
                </Form.Item>
            </Modal>
        </Form>
    );
}
