import {
    WorkDateType,
    WorkDayDataType,
    WorkDayTimeRangeType,
    WorkTimeType,
} from '../../../component/form/workdays-form-item/workdays-form-item-type';
import {WeekDaysEnum} from '../../../provider/locale/locale-context-const';
import {
    CompanyPaymentMethodV2Enum,
    CompanyPhoneType,
    CompanySpecialHoursType,
    CompanyTimeBreaksType,
    CompanyWorkDaysType,
    CompanyWorkTimeType,
} from '../../../service/company/company-type';
import {DateStringType, TimeStringType} from '../../../util/type';

import {PaymentMethodsFieldV2Type} from './form-items/company-form-items/payment-methods-form-item/payment-methods-form-item-type';
import {InputStringType} from './form-items/company-form-items/phones-form-item/input-string/input-string-type';
import {TemporaryWorkDayDataType} from './form-items/company-form-items/temporary-workdays-form-item/temporary-workdays-form-item-type';
import {UrlInputStringType} from './form-items/company-form-items/websites-form-item/websites-form-item-type';

const aroundTheClockTimeFrom: TimeStringType = '00:00';
const aroundTheClockTimeTo: TimeStringType = '23:59';

function convertToTimeString(time: number): `${number}${number}` {
    const hasOneDigit = String(time).length === 1;

    return hasOneDigit ? `${0}${time}` : (String(time) as `${number}${number}`);
}

function buildTimeFromTimeObject(time: WorkTimeType): TimeStringType {
    const hours = convertToTimeString(time.hour);
    const minutes = convertToTimeString(time.minute);

    return `${hours}:${minutes}`;
}

function buildDateFromDateObject(dateObject: WorkDateType): DateStringType {
    const year = String(dateObject.year) as `${number}${number}${number}${number}`;
    const month = convertToTimeString(dateObject.month + 1);
    const day = convertToTimeString(dateObject.date);

    return `${year}-${month}-${day}`;
}

export function serializePaymentMethodsV2(paymentMethods: PaymentMethodsFieldV2Type): Array<number> {
    const methods: Array<number> = [];

    Object.keys(paymentMethods).forEach((key) => {
        if (paymentMethods[key as unknown as CompanyPaymentMethodV2Enum]) {
            methods.push(Number(key));
        }
    });

    return methods;
}

function serializeTimeBreaks(breaks: Array<WorkDayTimeRangeType>): Array<CompanyTimeBreaksType> {
    return breaks.reduce<Array<CompanyTimeBreaksType>>((accum, item: WorkDayTimeRangeType) => {
        if (!item.timeFrom || !item.timeTo) {
            return accum;
        }

        const breakTime: CompanyTimeBreaksType = {
            start: buildTimeFromTimeObject(item.timeFrom),
            end: buildTimeFromTimeObject(item.timeTo),
        };

        return [...accum, breakTime];
    }, []);
}

function buildWorkTimeFromWorkDayData(workDayData: WorkDayDataType | TemporaryWorkDayDataType): CompanyWorkTimeType {
    let workTime: CompanyWorkTimeType = {
        start: null,
        end: null,
        breaks: [],
    };

    const canHaveTime =
        ('isOpen' in workDayData && workDayData.isOpen) || ('isDayOff' in workDayData && !workDayData.isDayOff);

    if (canHaveTime && workDayData.timeFrom && workDayData.timeTo) {
        workTime = {
            start: buildTimeFromTimeObject(workDayData.timeFrom),
            end: buildTimeFromTimeObject(workDayData.timeTo),
            breaks: serializeTimeBreaks(workDayData.breakTimeList),
        };
    }

    if (canHaveTime && workDayData.isAroundTheClock) {
        workTime = {
            start: aroundTheClockTimeFrom,
            end: aroundTheClockTimeTo,
            breaks: serializeTimeBreaks(workDayData.breakTimeList),
        };
    }

    return workTime;
}

export function serializeWorkDays(workDays: Array<WorkDayDataType>): CompanyWorkDaysType {
    const defaultWorkDay: CompanyWorkDaysType = {
        [WeekDaysEnum.monday]: {start: null, end: null, breaks: []},
        [WeekDaysEnum.tuesday]: {start: null, end: null, breaks: []},
        [WeekDaysEnum.wednesday]: {start: null, end: null, breaks: []},
        [WeekDaysEnum.thursday]: {start: null, end: null, breaks: []},
        [WeekDaysEnum.friday]: {start: null, end: null, breaks: []},
        [WeekDaysEnum.saturday]: {start: null, end: null, breaks: []},
        [WeekDaysEnum.sunday]: {start: null, end: null, breaks: []},
    };

    return workDays.reduce<CompanyWorkDaysType>((accum, workDay: WorkDayDataType) => {
        const workTime = buildWorkTimeFromWorkDayData(workDay);

        return {
            ...accum,
            [workDay.day]: workTime,
        };
    }, defaultWorkDay);
}

export function serializeTemporaryWorkDays(
    temporaryDays: Array<TemporaryWorkDayDataType>
): Array<CompanySpecialHoursType> {
    return temporaryDays.reduce<Array<CompanySpecialHoursType>>((accum, day: TemporaryWorkDayDataType) => {
        const hasNoTimeSpecified = !day.isDayOff && (!day.timeFrom || !day.timeTo);

        if (!day.dateFrom || !day.dateTo || hasNoTimeSpecified) {
            return accum;
        }

        const startDate = buildDateFromDateObject(day.dateFrom);
        const endDate = buildDateFromDateObject(day.dateTo);
        const workTime = buildWorkTimeFromWorkDayData(day);

        const workDay: CompanySpecialHoursType = {
            start_date: startDate,
            end_date: endDate,
            is_holiday: day.isDayOff,
            ...workTime,
        };

        return [...accum, workDay];
    }, []);
}

function isBreakTimeEmpty(breaks: Array<WorkDayTimeRangeType>): boolean {
    return breaks.length > 0 && (breaks[0]?.timeTo === null || breaks[0]?.timeFrom === null);
}

export function isWorkDayEmpty(workday: WorkDayDataType): boolean {
    const isTimeEmpty =
        workday.isOpen && !workday.isAroundTheClock && (workday.timeFrom === null || workday.timeTo === null);

    return isTimeEmpty || isBreakTimeEmpty(workday.breakTimeList);
}

export function isTemporaryWorkDayEmpty(temporaryWorkday: TemporaryWorkDayDataType): boolean {
    const isDateEmpty = temporaryWorkday.dateTo === null || temporaryWorkday.dateFrom === null;
    const isTimeEmpty =
        !temporaryWorkday.isDayOff &&
        !temporaryWorkday.isAroundTheClock &&
        (temporaryWorkday.timeTo === null || temporaryWorkday.timeFrom === null);

    return isDateEmpty || isTimeEmpty || isBreakTimeEmpty(temporaryWorkday.breakTimeList);
}

export function isInputStringFalsy(input: InputStringType): boolean {
    return !input.isRemoved && Boolean(input.value);
}

export function serializeBulkUpdateWebsite(websites: Array<UrlInputStringType>): Array<string> {
    return websites.filter(isInputStringFalsy).map((website) => website.protocol + website.value);
}

function cleanPhone(phone: string): string {
    const spaceBetweenWords = /\s+/;

    return phone.replace('+', '').split(spaceBetweenWords).join('');
}

export function serializePhones(phones: Array<InputStringType>): Array<CompanyPhoneType> {
    return phones.filter(isInputStringFalsy).map((phone, index) => ({
        phone: phone.value,
        clean_phone: cleanPhone(phone.value),
        is_main: index === 0,
        is_fax: false,
    }));
}
