import {faUserLock} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Locale} from '../../provider/locale/locale';

import * as styles from './types-cell.scss';

export function renderTagType(isPrivate: boolean): JSX.Element {
    return isPrivate ? (
        <div className={styles.tag_type}>
            <FontAwesomeIcon className={styles.tag_type_private_icon} icon={faUserLock} />
            <Locale stringKey="TAGS__TAG_TYPE__PRIVATE" />
        </div>
    ) : (
        <div className={styles.tag_type}>
            <Locale stringKey="TAGS__TAG_TYPE__COMMAND" />
        </div>
    );
}
