/* eslint-disable react/no-multi-comp */

import {WorkdaysFormItem} from '../../../../component/form/workdays-form-item/workdays-form-item';
import {DEFAULT_WORK_DAY_DATA_LIST} from '../../../../component/form/workdays-form-item/workdays-form-item-const';
import {Locale} from '../../../../provider/locale/locale';
import {
    BulkEditFieldNameEnum,
    CompanyPaymentMethodV2Enum,
    FieldModeEnum,
} from '../../../../service/company/company-type';
import {getRandomString} from '../../../../util/string';
import {DEFAULT_MEDIA_FILE} from '../bulk-edit-companies-const';
import {BulkUpdateCompaniesErrorsType} from '../bulk-edit-companies-type';
import {AlternativeNamesFormItem} from '../form-items/company-form-items/alternative-names-form-item/alternative-names-form-item';
import {BaseCompanyFormItemDirectPropsType} from '../form-items/company-form-items/company-form-items-type';
import {CompanyNamesFormItem} from '../form-items/company-form-items/company-names-form-item/company-names-form-item';
import {CoverFormItemV2} from '../form-items/company-form-items/cover-form-item-v2/cover-form-item-v2';
import {EmailFormItem} from '../form-items/company-form-items/email-form-item/email-form-item';
import {FullDescriptionFormItem} from '../form-items/company-form-items/full-description-form-item/full-description-form-item';
import {GalleryFormItemV2} from '../form-items/company-form-items/gallery-form-item-v2/gallery-form-item-v2';
import {LegalNameFormItem} from '../form-items/company-form-items/legal-name-form-item/legal-name-form-item';
import {LogoFormItemV2} from '../form-items/company-form-items/logo-form-item-v2/logo-form-item-v2';
import {PaymentMethodsFormItem} from '../form-items/company-form-items/payment-methods-form-item/payment-methods-form-item';
import {DEFAULT_PAYMENT_METHODS_V2} from '../form-items/company-form-items/payment-methods-form-item/payment-methods-form-item-const';
import {DEFAULT_INPUT_STRING_VALUE} from '../form-items/company-form-items/phones-form-item/input-string/input-string-const';
import {PhonesFormItem} from '../form-items/company-form-items/phones-form-item/phones-form-item';
import {SelectCategoriesFormItem} from '../form-items/company-form-items/select-categories-form-item/select-categories-form-item';
import {SelectOrganizationalFormItem} from '../form-items/company-form-items/select-organizational-form-item/select-organizational-form-item';
import {ShortDescriptionFormItem} from '../form-items/company-form-items/short-description-form-item/short-description-form-item';
import {SocialsFormItem} from '../form-items/company-form-items/socials-form-item/socials-form-item';
import {TemporaryClosedFormItem} from '../form-items/company-form-items/temporary-closed-form-item/temporary-closed-form-item';
import {TemporaryWorkdaysFormItem} from '../form-items/company-form-items/temporary-workdays-form-item/temporary-workdays-form-item';
import {WebsitesFormItem} from '../form-items/company-form-items/websites-form-item/websites-form-item';
import {DEFAULT_URL_INPUT_STRING_VALUE} from '../form-items/company-form-items/websites-form-item/websites-form-item-const';

import {BulkEditFieldsFormType} from './fields-list-type';
import * as styles from './fields-list.scss';

export function getDefaultBulkFieldsValues(): BulkEditFieldsFormType {
    return {
        [BulkEditFieldNameEnum.names]: [{id: getRandomString(), text: ''}],
        [BulkEditFieldNameEnum.alternativeNames]: '',
        [BulkEditFieldNameEnum.legalForm]: null,
        [BulkEditFieldNameEnum.legalName]: '',
        [BulkEditFieldNameEnum.categories]: [],
        [BulkEditFieldNameEnum.phones]: DEFAULT_INPUT_STRING_VALUE,
        [BulkEditFieldNameEnum.email]: '',
        [BulkEditFieldNameEnum.websites]: DEFAULT_URL_INPUT_STRING_VALUE,
        [BulkEditFieldNameEnum.socials]: DEFAULT_INPUT_STRING_VALUE,
        [BulkEditFieldNameEnum.workingDays]: DEFAULT_WORK_DAY_DATA_LIST,
        [BulkEditFieldNameEnum.temporaryWorkingDays]: [],
        [BulkEditFieldNameEnum.isTemporaryClosed]: false,
        [BulkEditFieldNameEnum.paymentMethods]: DEFAULT_PAYMENT_METHODS_V2,
        [BulkEditFieldNameEnum.shortDescription]: '',
        [BulkEditFieldNameEnum.fullDescription]: '',
        [BulkEditFieldNameEnum.logo]: DEFAULT_MEDIA_FILE,
        [BulkEditFieldNameEnum.cover]: DEFAULT_MEDIA_FILE,
        [BulkEditFieldNameEnum.gallery]: [],
    };
}

type ComponentFieldPropsType<TEnumKey extends BulkEditFieldNameEnum> = Omit<
    BaseCompanyFormItemDirectPropsType<BulkEditFieldsFormType[TEnumKey]>,
    'hasError'
> & {
    mode: FieldModeEnum;
    commonErrorMessage?: BulkUpdateCompaniesErrorsType[TEnumKey];
};

export const COMPONENT_FIELD_BY_ITS_NAME: {
    [Key in BulkEditFieldNameEnum]: (props: ComponentFieldPropsType<Key>) => JSX.Element;
} = {
    [BulkEditFieldNameEnum.names]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <CompanyNamesFormItem
            errorMessage={commonErrorMessage || errorMessage}
            namesForPush={[]}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.alternativeNames]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <AlternativeNamesFormItem
            className={styles.field_list__input_container}
            errorMessage={commonErrorMessage || errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.legalForm]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <SelectOrganizationalFormItem
            className={styles.field_list__input_container}
            errorMessage={commonErrorMessage || errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.legalName]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <LegalNameFormItem
            className={styles.field_list__input_container}
            errorMessage={commonErrorMessage || errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.categories]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <SelectCategoriesFormItem
            className={styles.field_list__categories_container}
            errorMessage={commonErrorMessage || errorMessage}
            helpText={<Locale stringKey="BULK_EDIT_COMPANIES__CATEGORIES_FIELD_ADDITIONAL_INFO" />}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.phones]: ({value, onChange, mode, errorMessage, commonErrorMessage}) => (
        <PhonesFormItem
            containerClassName={styles.field_list__phones_container}
            errorMessage={commonErrorMessage || errorMessage}
            helpText={<Locale stringKey="BULK_EDIT_COMPANIES__PHONE_FIELD_ADDITIONAL_INFO" />}
            mode={mode}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.email]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <EmailFormItem
            className={styles.field_list__input_container}
            errorMessage={commonErrorMessage || errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.websites]: ({value, onChange, mode, errorMessage, commonErrorMessage}) => (
        <WebsitesFormItem
            containerClassName={styles.field_list__input_container}
            errorMessage={commonErrorMessage || errorMessage}
            helpText={<Locale stringKey="BULK_EDIT_COMPANIES__WEBSITE_FIELD_ADDITIONAL_INFO" />}
            mode={mode}
            multiple
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.socials]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <SocialsFormItem
            containerClassName={styles.field_list__input_container}
            errorMessage={commonErrorMessage || errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.workingDays]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <WorkdaysFormItem errorMessage={commonErrorMessage || errorMessage} onChange={onChange} value={value} />
    ),
    [BulkEditFieldNameEnum.temporaryWorkingDays]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <TemporaryWorkdaysFormItem
            addButtonClassName={styles.fields_list__add_temporary_work_time__button}
            commonErrorMessage={commonErrorMessage}
            errorMessage={errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.isTemporaryClosed]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <TemporaryClosedFormItem errorMessage={commonErrorMessage || errorMessage} onChange={onChange} value={value} />
    ),
    [BulkEditFieldNameEnum.paymentMethods]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <PaymentMethodsFormItem<CompanyPaymentMethodV2Enum>
            errorMessage={commonErrorMessage || errorMessage}
            onChange={onChange}
            value={value}
        />
    ),
    [BulkEditFieldNameEnum.shortDescription]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <div className={styles.field_list__input_container}>
            <ShortDescriptionFormItem
                errorMessage={commonErrorMessage || errorMessage}
                onChange={onChange}
                value={value}
            />
        </div>
    ),
    [BulkEditFieldNameEnum.fullDescription]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <div className={styles.field_list__input_container}>
            <FullDescriptionFormItem
                errorMessage={commonErrorMessage || errorMessage}
                onChange={onChange}
                value={value}
            />
        </div>
    ),
    [BulkEditFieldNameEnum.logo]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <LogoFormItemV2 errorMessage={commonErrorMessage || errorMessage} onChange={onChange} value={value} />
    ),
    [BulkEditFieldNameEnum.cover]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <CoverFormItemV2 errorMessage={commonErrorMessage || errorMessage} onChange={onChange} value={value} />
    ),
    [BulkEditFieldNameEnum.gallery]: ({value, onChange, errorMessage, commonErrorMessage}) => (
        <GalleryFormItemV2 errorMessage={commonErrorMessage || errorMessage} onChange={onChange} value={value} />
    ),
};
