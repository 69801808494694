import {noop} from '../../util/function';

import {UiContextType} from './ui-context-type';

export const uiProviderLocalStorageKey = 'ui-provider-key-v.1.0.0';

export const defaultUiContext: UiContextType = {
    isLeftSideOpen: true,
    setIsLeftSideOpen: noop,
    isMobileNavigationOpen: false,
    setIsMobileNavigationOpen: noop,
};
