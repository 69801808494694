import {z as r} from 'zod';

export const averageResponseTimeByCatalogItemSchema = r.object({
    catalog: r.object({
        pk: r.number(),
        name: r.string(),
    }),
    deletedReviewsCount: r.object({
        value: r.number().nullable(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    abusesCount: r.object({
        value: r.number().nullable(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    repliesCount: r.object({
        value: r.number().nullable(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    avgReplyTime: r.object({
        // eslint-disable-next-line id-length
        cp: r.object({
            value: r.number().nullable(), // время в секундах
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
        catalog: r.object({
            value: r.number().nullable(), // время в секундах
            comparedValue: r.number().nullable(),
            delta: r.number().nullable(),
        }),
    }),
});

export type AverageResponseTimeByCatalogType = r.infer<typeof averageResponseTimeByCatalogItemSchema>;
