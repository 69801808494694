export const enFormValidationTranslations = {
    VALIDATION__ERROR__FIELD_IS_REQUIRED: 'This field is required',
    VALIDATION__ERROR__FIELD_LENGTH_INCORRECT: 'Field must have {length} characters',
    VALIDATION__ERROR__FIELD_MIN_LENGTH_INCORRECT: 'Field must be at least {length} characters',
    VALIDATION__ERROR__FIELD_MAX_LENGTH_INCORRECT: 'Field must be less or equal to {length} characters',
    VALIDATION__ERROR__FIELD_COMMA_SEPARATED_EMAILS_INCORRECT: 'These emails are incorrect: {emails}',
    VALIDATION__ERROR__FIELD_COMMA_SEPARATED_LENGTH: 'Field values must be less or equal to {length}',
    VALIDATION__ERROR__ENTER_A_VALID_EMAIL_ADDRESS: 'Enter a valid email address',
    VALIDATION__ERROR__ENTER_A_VALID_URL: 'Enter a valid email URL',
    VALIDATION__ERROR__ENTER_A_VALID_DATE: 'Enter a valid date and time',
    VALIDATION__ERROR__ENTER_A_VALID_DOMAIN: 'Specify the correct domain in the format "domain.com"',
    VALIDATION__ERROR__THE_TWO_PASSWORDS_THAT_YOU_ENTERED_DO_NOT_MATCH:
        'The two passwords that you entered do not match',
    VALIDATION__ERROR__PASSWORD_MUST_CONTAIN_NUMBER: 'Password must include numbers',
    VALIDATION__ERROR__PASSWORD_MUST_CONTAIN_LOWER_LATIN: 'Password must include lower case Latin letters',
    VALIDATION__ERROR__PASSWORD_MUST_CONTAIN_UPPER_LATIN: 'Password must include upper case Latin letters',
    VALIDATION__ERROR__FILE_UPLOAD__ERROR_TITLE: 'File Upload Error',
    VALIDATION__ERROR__TOO_BIG_FILE_SIZE: 'File size cannot exceed {maxSize}MB',
    VALIDATION__ERROR__FIELD_MAX_ROWS_INCORRECT: 'Maximum number of rows should not exceed {maxRows}',

    VALIDATION__ERROR__IMAGE_UPLOAD__CONTENT: 'Error uploading image',
    VALIDATION__ERROR__IMAGE_UPLOAD__TITLE: 'Failed to upload image.',

    VALIDATION__ERROR__DIMENSION__TITLE: 'Images do not meet the requirements',
    VALIDATION__ERROR__DIMENSION: 'Image resolution: {min}{max}',
    VALIDATION__ERROR__MIN_IMAGE_DIMENSION: 'from {width} x {height} ',
    VALIDATION__ERROR__MAX_IMAGE_DIMENSION: 'to {width} x {height}',

    VALIDATION__ERROR__CODE128: 'Invalid barcode',
};
