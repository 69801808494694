import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useEffect, useMemo} from 'react';
import {z as r} from 'zod';

import {fetchAndDeserialize, serializeToURLParameters} from '../../util/api-adapter';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../util/url';
import {generateResponseSchema} from '../api/api-type';

import {getCompaniesImportErrorCodesUrl} from './companies-import-result-error-codes';

const companiesImportErrorSchema = r.object({
    companyName: r.string(),
    companyAddress: r.string(),
    companyCode: r.number(),
    code: r.number(),
    companyBranchCode: r.string(),
    field: r.string(),
    value: r.string(),
    isCritical: r.boolean(),
    msg: r.string(),
    catalog: r.string().nullable().optional(),
    catalogId: r.number().nullable().optional(),
});

export type CompaniesImportErrorType = r.infer<typeof companiesImportErrorSchema>;

const companiesImportErrorsSchema = generateResponseSchema(companiesImportErrorSchema);

type CompaniesImportErrorsType = r.infer<typeof companiesImportErrorsSchema>;

type CompaniesImportErrorsOptionsType = {
    page: number;
    count: number;
};

function getCompaniesImportErrorsUrl(id: string, code: number, options: CompaniesImportErrorsOptionsType): string {
    return `${getCompaniesImportErrorCodesUrl(id)}${code}/?${objectToUrlParameters(serializeToURLParameters(options))}`;
}

function fetchCompaniesImportErrors(
    id: string,
    code: number,
    options: CompaniesImportErrorsOptionsType
): Promise<CompaniesImportErrorsType> {
    return fetchAndDeserialize(getCompaniesImportErrorsUrl(id, code, options), companiesImportErrorsSchema);
}

export function useCompaniesImportErrors(
    id: string | null,
    code: number
): UseQueryResult<CompaniesImportErrorsType> & PaginationType {
    const pagination = usePagination({dependencies: null, initialPageSize: 50, shouldSaveState: false});
    const {page, pageSize, onDataLoaded} = pagination;

    const options = useMemo(() => ({page, count: pageSize}), [page, pageSize]);

    const query = useQuery(
        [getCompaniesImportErrorsUrl(id ?? '', code, options)],
        () => fetchCompaniesImportErrors(id ?? '', code, options),
        {enabled: Boolean(id)}
    );

    useEffect(() => {
        if (query.data) {
            onDataLoaded(query.data);
        }
    }, [onDataLoaded, query.data]);

    return {...query, pagination};
}
