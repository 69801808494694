import {Space} from 'antd';

import {Text} from '../../../../../../../../../component/text/text';
import {GoogleVerificationOptionType} from '../../../../../../../../../service/google/confirmation/google-confirmation-type';
import * as styles from '../code-request-modal.scss';

type PropsType = {
    verification?: GoogleVerificationOptionType;
};

export function AddressVerification(props: PropsType): JSX.Element | null {
    const {verification} = props;

    if (!verification) {
        return null;
    }

    return (
        <>
            {verification.detail && (
                <Space className={styles.CodeRequestModal_card} direction="vertical" size={2}>
                    <Text secondary small stringKey="GOOGLE_SYNC__CODE_REQUEST__ADDRESS__LABEL" />

                    <Text>{verification.detail}</Text>
                </Space>
            )}

            <Text block lighter stringKey="GOOGLE_SYNC__CODE_REQUEST__ADDRESS__DESCRIPTION" />
        </>
    );
}
