import {useEffect, useMemo} from 'react';

import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {PaginationOptionsType, PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {PaginatedResponseType, RequestOptionsType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {
    fetchReviewsAnalysisTagBySentiment,
    fetchReviewsAnalysisTagList,
    fetchReviewsAnalysisTagSearch,
} from './review-analytics-tags-api';
import {
    ReviewsAnalysisTagBySentimentType,
    ReviewsAnalysisTagsQueryType,
    ReviewsAnalysisTagType,
} from './review-analytics-tags-type';

export function useReviewAnalyticsTagList(
    options: ReviewsAnalysisTagsQueryType,
    mainFilterKey: string,
    paginationOption?: PaginationOptionsType
): UseHookType<PaginatedResponseType<ReviewsAnalysisTagType>> & PaginationType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<ReviewsAnalysisTagType>>();

    const {startDate, endDate, sentiments, sources, tags, phrases, topics, rating, withAnswers, withText, ableToReply} =
        options;

    const paginationDependencies = useMemo(() => {
        return {
            startDate,
            endDate,
            sentiments,
            sources,
            tags,
            phrases,
            topics,
            rating,
            withAnswers,
            withText,
            mainFilterKey,
            ableToReply,
        };
    }, [
        endDate,
        mainFilterKey,
        phrases,
        rating,
        sentiments,
        sources,
        startDate,
        tags,
        topics,
        withAnswers,
        withText,
        ableToReply,
    ]);

    const pagination = usePagination({
        dependencies: paginationDependencies,
        shouldSaveState: false,
        ...paginationOption,
    });

    const {page, pageSize, onDataLoaded, onDataLoadFailed, refreshId} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewsAnalysisTagList(
            {
                page,
                pageSize,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
                withText,
                ableToReply,
            },
            mainFilterKey
        )
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error: Error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, setIsInProgress, setProcessError, setResult, onDataLoaded, onDataLoadFailed]);

    return {isInProgress, processError, result, reset, pagination};
}

export function useReviewAnalyticsTagSearch(
    options: RequestOptionsType,
    mainFilterKey: string
): UseHookType<Array<string>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<string>>();

    const {q, count, page} = options;

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewsAnalysisTagSearch(
            {
                q,
                page,
                count,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [page, count, q, mainFilterKey, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

export function useReviewAnalyticsTagBySentiment(
    options: ReviewsAnalysisTagsQueryType,
    mainFilterKey: string,
    paginationOption?: PaginationOptionsType
): UseHookType<PaginatedResponseType<ReviewsAnalysisTagBySentimentType>> & PaginationType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<ReviewsAnalysisTagBySentimentType>>();

    const {
        mode,
        brandIds: competitorBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
        ableToReply,
        maxTagsCount,
    } = options;

    const paginationDependencies = useMemo(() => {
        return {
            mode,
            competitorBrandIds,
            startDate,
            endDate,
            sentiments,
            sources,
            tags,
            phrases,
            topics,
            userTopics,
            rating,
            withAnswers,
            withText,
            ableToReply,
            mainFilterKey,
            maxTagsCount,
        };
    }, [
        mode,
        competitorBrandIds,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        userTopics,
        rating,
        withAnswers,
        withText,
        ableToReply,
        mainFilterKey,
        maxTagsCount,
    ]);

    const pagination = usePagination({
        dependencies: paginationDependencies,
        ...paginationOption,
    });

    const {page, pageSize, onDataLoadFailed, onDataLoaded, refreshId} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewsAnalysisTagBySentiment(
            {
                page,
                pageSize,
                mode,
                brandIds: competitorBrandIds,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                userTopics,
                rating,
                withAnswers,
                withText,
                ableToReply,
                maxTagsCount,
            },
            mainFilterKey
        )
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error: Error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, setIsInProgress, setProcessError, setResult, startDate, onDataLoaded, onDataLoadFailed]);

    return {isInProgress, processError, result, reset, pagination};
}
