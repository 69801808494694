import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useCclCompanyStats} from '../../../../../service/ccl/ccl-hook';
import {CclCompanyStatsType} from '../../../../../service/ccl/ccl-type';

import {SourceSynchronizationDetails} from './source-synchronization-details';
import * as styles from './sources-synchronization.scss';

type PropsType = {
    companyId: number;
};

export function SourcesSynchronization(props: PropsType): JSX.Element {
    const {companyId} = props;
    const {getLocalizedString} = useLocale();

    const {result, isInProgress} = useCclCompanyStats(companyId);

    return (
        <PageCard title={getLocalizedString('COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__HEADER')}>
            {isInProgress && <Spinner />}
            {result && (
                <>
                    <div className={styles.sources_synchronization__content}>
                        {result.map((catalogSyncronizationDetails: CclCompanyStatsType) => {
                            return (
                                <SourceSynchronizationDetails
                                    cclCompanyStats={catalogSyncronizationDetails}
                                    key={catalogSyncronizationDetails.catalog.id}
                                />
                            );
                        })}
                    </div>
                    <span className={styles.sources_synchronization__item__footer}>
                        <Locale
                            stringKey="COMPANY_ACTIVITY_AND_SUMMARY__WIDGET__SOURCES_SYNCHRONIZATION__FOOTER"
                            valueMap={{
                                sourcesLink: (
                                    <NavigationLink key="sourcesLink" to={appRoute.sources.path}>
                                        <Locale stringKey="CATEGORY_NAME__SOURCES" />
                                    </NavigationLink>
                                ),
                            }}
                        />
                    </span>
                </>
            )}
        </PageCard>
    );
}
