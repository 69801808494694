import {useCallback, useState} from 'react';

import {LandingCongratulationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-congratulation-step';
import {LandingOnboardingPageCard} from '../../../../../component/landing/landing-onboarding-steps/landing-onboarding-page-card';
import {LandingPresentationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-presentation-step';

import {ONLINE_PRESENCE_ONBOARDING_IMAGES} from './online-presence-onboarding-const';

export function OnlinePresenceOnboarding(): JSX.Element {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const onStepFinished = useCallback(() => {
        setCurrentStepIndex((previousActiveKeyIndex) => previousActiveKeyIndex + 1);
    }, []);

    const steps = [
        <LandingPresentationStep
            descriptionLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP1__DESCRIPTION"
            images={ONLINE_PRESENCE_ONBOARDING_IMAGES.step1}
            key="step1"
            onStepFinish={onStepFinished}
            titleLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP1__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP2__DESCRIPTION"
            images={ONLINE_PRESENCE_ONBOARDING_IMAGES.step2}
            key="step2"
            onStepFinish={onStepFinished}
            titleLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP2__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP3__DESCRIPTION"
            images={ONLINE_PRESENCE_ONBOARDING_IMAGES.step3}
            key="step3"
            onStepFinish={onStepFinished}
            titleLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP3__TITLE"
        />,
        <LandingCongratulationStep
            descriptionLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP4__DESCRIPTION"
            key="step5"
            titleLangKeyType="ONLINE_PRESENCE_ANALYTICS__ONBOARDING__STEP4__TITLE"
        />,
    ];

    return <LandingOnboardingPageCard current={currentStepIndex} onChange={setCurrentStepIndex} steps={steps} />;
}
