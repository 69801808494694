import {faPaperclip} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Modal} from 'antd';
import {useCallback, useContext, useMemo, useState} from 'react';

import {UploadFileList} from '../../../../../../component/form/file-upload-item/upload-file-list/upload-file-list';
import {Text} from '../../../../../../component/text/text';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {FormattedDateTime} from '../../../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../../../provider/locale/locale';
import {LocalePlural} from '../../../../../../provider/locale/locale-plural';
import {SystemContext} from '../../../../../../provider/system/system-context';
import {SystemContextType} from '../../../../../../provider/system/system-context-type';
import {
    useChangeTechnicalSupportTicketSubscription,
    useTechnicalSupportReopenTicket,
    useViewTechnicalSupportTicket,
} from '../../../../../../service/technical-support/technical-support-hook';
import {TechnicalSupportFieldsEnum} from '../../../../../../service/technical-support/technical-support-type';
import {TechnicalSupportFieldValueTag} from '../technical-support-field-value/technical-support-field-value-tag';

import {TechnicalSupportConversation} from './answers/technical-support-conversation';
import * as styles from './technical-support-view-modal.scss';

interface PropsType {
    helpRequestId: number;
    onClose: VoidFunction;
    isOpen: boolean;
}

export function TechnicalSupportViewModal(props: PropsType): JSX.Element {
    const {helpRequestId, onClose, isOpen = false} = props;

    const [openedTicketId, setOpenedTicketId] = useState<number>(helpRequestId);

    const {
        data: helpRequest,
        isLoading,
        refetch,
    } = useViewTechnicalSupportTicket(openedTicketId, isOpen, setOpenedTicketId);
    const {mutateAsync: changeSubscriptionStatus, isLoading: isChangingSubscriptionStatus} =
        useChangeTechnicalSupportTicketSubscription(openedTicketId);

    const {mutateAsync: reopenTicket, isLoading: isReopeningTicket} = useTechnicalSupportReopenTicket({
        helpRequestId: openedTicketId,
        onTicketReopen: (newTicketId) => {
            setOpenedTicketId(newTicketId);
        },
    });

    const handleTicketReopen = useCallback(async () => {
        await reopenTicket();
    }, [reopenTicket]);

    const systemContext = useContext<SystemContextType>(SystemContext);
    const {screen} = systemContext;
    const {isMobile} = screen;

    const statusColumnContent = useMemo(() => {
        if (!helpRequest) {
            return null;
        }

        return (
            <>
                <Text block stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__STATUS_LABEL" />
                <TechnicalSupportFieldValueTag field={TechnicalSupportFieldsEnum.Status} value={helpRequest.status} />
                {helpRequest?.isClosed && (
                    <>
                        <Text block stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__CLOSING_DATE_LABEL" />
                        <FormattedDateTime value={new Date(helpRequest.updated)} />
                    </>
                )}
            </>
        );
    }, [helpRequest]);

    return (
        <Modal
            cancelText={<Locale stringKey="TEXT__CLOSE" />}
            destroyOnClose
            forceRender={false}
            okButtonProps={{
                disabled: isReopeningTicket,
                loading: isReopeningTicket,
                hidden: !(helpRequest && helpRequest?.isClosed && helpRequest?.isSubscribed),
            }}
            okText={<Locale stringKey="HELP_TECHNICAL_SUPPORT__REOPEN_TICKET__BUTTON" />}
            onCancel={onClose}
            onOk={handleTicketReopen}
            open={isOpen}
            title={<Locale stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__TITLE" />}
            width={800}
        >
            {helpRequest && (
                <div className={styles.TechnicalSupportViewModalContent}>
                    <div className={styles.TechnicalSupportViewModalContent_requestInfoWrapper}>
                        <div className={styles.TechnicalSupportViewModalContent_requestInfo}>
                            <Text block stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__TYPE_LABEL" />
                            <TechnicalSupportFieldValueTag
                                field={TechnicalSupportFieldsEnum.Type}
                                value={helpRequest.type}
                            />
                            <Text block stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__CATEGORY_LABEL" />
                            <TechnicalSupportFieldValueTag
                                field={TechnicalSupportFieldsEnum.ClientCategory}
                                value={helpRequest.clientCategory}
                            />

                            {isMobile && statusColumnContent}
                        </div>
                        {!isMobile && (
                            <div className={styles.TechnicalSupportViewModalContent_requestInfo}>
                                {statusColumnContent}
                            </div>
                        )}
                    </div>
                    <div className={styles.TechnicalSupportViewModalContent_requestData}>
                        <div className={styles.TechnicalSupportViewModalContent_requestDataTitle}>
                            <Text block bolder>
                                {helpRequest.title}
                            </Text>

                            {!helpRequest.isCreator && (
                                <Checkbox
                                    checked={helpRequest.isSubscribed}
                                    disabled={isChangingSubscriptionStatus}
                                    onChange={(event) => changeSubscriptionStatus(event.target.checked)}
                                >
                                    <Text block stringKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__TRACK_REQUEST" />
                                </Checkbox>
                            )}
                        </div>
                        {helpRequest.description && <Text block>{helpRequest.description}</Text>}
                        {helpRequest.files.length > 0 && (
                            <div className={styles.TechnicalSupportViewModalContent_attachments}>
                                <div className={styles.TechnicalSupportViewModalContent_attachmentsTitle}>
                                    <Text block large secondary>
                                        <FontAwesomeIcon icon={faPaperclip} />
                                    </Text>
                                    <Text block secondary>
                                        <LocalePlural
                                            count={helpRequest.files.length}
                                            fewKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACHED_FILES__FEW"
                                            manyKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACHED_FILES__MANY"
                                            singularKey="HELP_TECHNICAL_SUPPORT__VIEW_MODAL__ATTACHED_FILES__SINGLE"
                                            valueMap={{
                                                count: helpRequest.files.length,
                                            }}
                                        />
                                    </Text>
                                </div>
                                <UploadFileList
                                    allowDownload
                                    fileList={helpRequest.files.map((uploadedFileInfo) => ({
                                        name: uploadedFileInfo.fileName,
                                        uid: uploadedFileInfo.tempId,
                                        url: uploadedFileInfo.fileS3,
                                        status: 'done',
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                    <TechnicalSupportConversation
                        canCreateNewMessage={!helpRequest?.isClosed}
                        helpRequestId={openedTicketId}
                        newMessages={helpRequest.unreadPostsCount}
                        refreshTicketData={refetch}
                    />
                </div>
            )}
            {isLoading && <Spinner />}
        </Modal>
    );
}
