import {classNames} from '../../../util/css';

import landingImageLeftSrc from './image/landing-arrow-left.svg';
import landingImageRightSrc from './image/landing-arrow-right.svg';
import * as styles from './landing-arrow.scss';

type PropsType = {
    position: 'left' | 'right';
};

export function LandingArrow(props: PropsType): JSX.Element {
    const {position} = props;

    return (
        <div
            className={classNames(styles.LandingArrow, {
                [styles.LandingArrow__left]: position === 'left',
                [styles.LandingArrow__right]: position === 'right',
            })}
        >
            <img alt="" src={position === 'left' ? landingImageLeftSrc : landingImageRightSrc} />
        </div>
    );
}
