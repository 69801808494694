import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal, Space} from 'antd';

import {Text} from '../../../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../provider/locale/localization';

type PropsType = {
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
};

export function GroupRequiredModal(props: PropsType): JSX.Element {
    const {open, onCancel, onOk} = props;

    const documentationLinks = useDocumentationLinks();

    return (
        <Modal
            footer={
                <footer>
                    <Button onClick={onOk}>
                        <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__CHOOSE_A_GROUP" />
                    </Button>

                    <Button onClick={onCancel} type="primary">
                        <Locale stringKey="POPUP__BUTTON__OK" />
                    </Button>
                </footer>
            }
            onCancel={onCancel}
            open={open}
            title={<Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__TITLE" />}
        >
            <Space direction="vertical" size={16}>
                <Space direction="vertical" size={4}>
                    <Text block bolder stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__SUBHEADER" />

                    <Text block lighter stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__NOT_SUPPORTED" />
                </Space>

                {documentationLinks.google.addressGroup && (
                    <a href={documentationLinks.google.addressGroup} rel="noreferrer" target="_blank">
                        <Space size={8}>
                            <FontAwesomeIcon icon={faExternalLink} />

                            <Locale stringKey="GOOGLE_SYNC__ACCOUNTS_STEP__TIP__GROUP__LINK" />
                        </Space>
                    </a>
                )}

                <div>
                    {documentationLinks.google.massConfirmation && (
                        <Text
                            block
                            lighter
                            stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__MASS_MATCHING"
                            valueMap={{
                                link: (
                                    <a
                                        href={documentationLinks.google.massConfirmation}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__LINK" />
                                    </a>
                                ),
                            }}
                        />
                    )}

                    {documentationLinks.google.addressGroup && (
                        <Text
                            block
                            lighter
                            stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__MOVE_TO_GROUP"
                            valueMap={{
                                link: (
                                    <a href={documentationLinks.google.addressGroup} rel="noreferrer" target="_blank">
                                        <Locale stringKey="GOOGLE_SYNC__COMPANIES_STEP__GROUP_MODAL__LINK" />
                                    </a>
                                ),
                            }}
                        />
                    )}
                </div>
            </Space>
        </Modal>
    );
}
