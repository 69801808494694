import {faArrowsRotate, faCircleCheck, faCircleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {faLocationCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {NeverError} from '../../util/error';

import {CclActionThumbStateNameEnum, CclActionThumbUiDataType} from './ccl-action-thumb-type';
import * as styles from './ccl-action-thumb.scss';

export function getCclActionThumbUiData(type: CclActionThumbStateNameEnum): CclActionThumbUiDataType {
    switch (type) {
        case CclActionThumbStateNameEnum.synchronized:
            return {
                className: styles.CclActionThumb_synchronized,
                image: <FontAwesomeIcon className={styles.CclActionThumb_synchronized_image} icon={faCircleCheck} />,
                langKey: 'ACTION_THUMBS__SYNCHRONIZED',
            };

        case CclActionThumbStateNameEnum.sent:
            return {
                className: styles.CclActionThumb_sent,
                image: <FontAwesomeIcon className={styles.CclActionThumb_sent_image} icon={faArrowsRotate} />,
                langKey: 'ACTION_THUMBS__SENT',
            };

        case CclActionThumbStateNameEnum.actionRequired:
            return {
                className: styles.CclActionThumb_required,
                image: <FontAwesomeIcon className={styles.CclActionThumb_required_image} icon={faCircleExclamation} />,
                langKey: 'ACTION_THUMBS__ACTION_REQUIRED',
            };

        case CclActionThumbStateNameEnum.confirmation:
            return {
                className: styles.CclActionThumb_confirmation,
                image: <FontAwesomeIcon className={styles.CclActionThumb_confirmation_image} icon={faLocationCheck} />,
                langKey: 'ACTION_THUMBS__CONFIRMATION',
            };

        default:
            throw new NeverError(type);
    }
}
