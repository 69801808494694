import {z as r} from 'zod';

export type CatalogNameType = 'map' | 'navigator';

// taken from server
export enum CclStatusEnum {
    synced = 'synced',
    sent = 'sended',
    actionNeeded = 'action_needed',
    accessTransferRequired = 'access_transfer_required',
}

export const cclStatisticSchema = r.object({
    all: r.number(),
    syncCount: r.number(),
    sendedCount: r.number(),
    actionNeededCount: r.number(),
    confirmationCount: r.number(),
});

export type CclStatisticType = r.infer<typeof cclStatisticSchema>;
