import {UploadProps} from 'antd';
import {RcFile} from 'antd/lib/upload';
import {Dispatch, PropsWithChildren, RefObject, SetStateAction, useCallback, useEffect, useState} from 'react';

import {CustomFileType, UploadFileResponseType} from '../../../service/file-upload/file-upload-type';
import {noop} from '../../../util/function';

import {FileUpload} from './file-upload';
import {UploadFileList} from './upload-file-list/upload-file-list';

type PropsType<FileType extends UploadFileResponseType> = {
    onChange?: Dispatch<SetStateAction<Record<string, CustomFileType & FileType>>>;
    value?: Record<string, CustomFileType & FileType>;
    remove?: (fileUid: string) => void;
    upload: (file: RcFile) => Promise<FileType>;
    uploadProps?: UploadProps<CustomFileType & FileType> & {maxFileSize?: number};
    showCustomUploadList?: boolean;
    innerRef?: RefObject<{remove: ((fileUid: string) => void) | null}>;
    validationRules?: Array<(file: RcFile) => Promise<void>>;
};

export function FileFormInput<FileType extends UploadFileResponseType>(
    props: PropsWithChildren<PropsType<FileType>>
): JSX.Element {
    const {
        value = {},
        onChange = noop,
        children,
        upload,
        remove,
        uploadProps,
        showCustomUploadList = true,
        validationRules,
        innerRef,
    } = props;

    const {showUploadList} = uploadProps || {};

    const [files, setFiles] = useState<Record<string, CustomFileType & FileType>>(value || {});

    useEffect(() => {
        onChange?.(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const handleDeleteFile = useCallback(
        (fileUid: string) => {
            onChange?.((previous) => {
                Reflect.deleteProperty(previous, fileUid);
                return {
                    ...previous,
                };
            });
            remove?.(fileUid);
        },
        [onChange, remove]
    );

    useEffect(() => {
        if (innerRef?.current) {
            innerRef.current.remove = handleDeleteFile;
        }
    }, [handleDeleteFile, innerRef]);

    return (
        <>
            <FileUpload<FileType>
                fileList={Object.values(value || {})}
                onRemove={(file) => {
                    handleDeleteFile(file.uid);
                }}
                setFileMap={setFiles}
                upload={upload}
                validationRules={validationRules}
                {...uploadProps} // eslint-disable-line react/jsx-props-no-spreading
            >
                {children}
            </FileUpload>

            {!showUploadList && showCustomUploadList && (
                <UploadFileList fileList={Object.values(files)} onRemove={handleDeleteFile} />
            )}
        </>
    );
}
