import {TableColumnsType} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {YandexStorySelectedCompanyType} from '../../../../service/yandex-stories/yandex-stories-type';

import {YandexSelectedCompaniesCompanyCell} from './cell/yandex-selected-companies-company-cell';
import {YandexSelectedCompaniesStatusCell} from './cell/yandex-selected-companies-status-cell';

export const yandexStorySelectedCompaniesTableColumns: TableColumnsType<YandexStorySelectedCompanyType> = [
    {
        title: <Locale stringKey="YANDEX_STORY__SELECTED_COMPANIES__COMPANY" />,
        width: '560px',
        render: (_value: unknown, record: YandexStorySelectedCompanyType) => (
            <YandexSelectedCompaniesCompanyCell record={record} />
        ),
    },
    {
        align: 'center',
        title: <Locale stringKey="YANDEX_STORY__SELECTED_COMPANIES__STATUS" />,
        width: '120px',
        render: (_value: unknown, record: YandexStorySelectedCompanyType) => (
            <YandexSelectedCompaniesStatusCell record={record} />
        ),
    },
];
