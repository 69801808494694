/* eslint-disable complexity */

import {Empty, Table, Tooltip} from 'antd';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {generatePath} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Meta} from '../../../../../component/meta/meta';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-container';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../provider/main-filter/main-filter-type';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {
    useReviewConcordance,
    useReviewConcordanceSentiment,
} from '../../../../../service/reivew-analysis/review-concordance/review-concordance-hook';
import {
    ReviewConcordanceItemType,
    ReviewConcordanceRequestFieldEnum,
} from '../../../../../service/reivew-analysis/review-concordance/review-concordance-type';
import {classNames} from '../../../../../util/css';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {defaultPageSize} from '../../reviews-analysis-const';
import {getPeriodOfTimeFromUrl} from '../../reviews-analysis-helper';
import {useReviewsAnalysisFilter} from '../../reviews-analysis-hook';
import {concordanceContextSizeName, concordanceQueryName, concordanceRangeName} from '../../reviews-analysis-type';

import {ConcordanceChart} from './concordance-chart/concordance-chart';
import {ConcordanceForm} from './concordance-form/concordance-form';
import {defaultContextValue} from './concordance-form/concordance-form-const';
import {ConcordanceFormType} from './concordance-form/concordance-form-type';
import {defaultConcordancePeriod} from './concordance-page-const';
import {ConcordanceDataType, ConcordanceSentimentTooltipEnum} from './concordance-page-type';
import * as styles from './concordance-page.scss';

export function ConcordancePage(): JSX.Element {
    const {
        reviewsAnalysisDashboard: dashboardRoute,
        reviewsAnalysisConcordance: concordanceRoute,
        reviewsManagementReviewItem,
    } = appRoute;

    const {getLocalizedString} = useLocale();
    const {getFormattedNumber} = useFormat();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {queries, replaceQuery} = useUrl();

    const [searchValues, setSearchValues] = useState<ConcordanceFormType>({
        [ReviewConcordanceRequestFieldEnum.Range]: queries[concordanceRangeName]
            ? getPeriodOfTimeFromUrl(queries[concordanceRangeName])
            : defaultConcordancePeriod,
        [ReviewConcordanceRequestFieldEnum.Query]: queries[concordanceQueryName] || '',
        [ReviewConcordanceRequestFieldEnum.ContextSize]: defaultContextValue,
    });

    const isQueryEmpty = !searchValues[ReviewConcordanceRequestFieldEnum.Query];

    useEffect(() => {
        setSearchValues({
            [ReviewConcordanceRequestFieldEnum.Range]: queries[concordanceRangeName]
                ? getPeriodOfTimeFromUrl(queries[concordanceRangeName])
                : defaultConcordancePeriod,
            [ReviewConcordanceRequestFieldEnum.Query]: queries[concordanceQueryName] || '',
            [ReviewConcordanceRequestFieldEnum.ContextSize]:
                Number(queries[concordanceContextSizeName]) || defaultContextValue,
        });
    }, [queries]);

    const {singleFilter} = useReviewsAnalysisFilter();

    const {fetch, result, isInProgress, processError} = useReviewConcordance();
    const {
        fetch: fetchSentiment,
        result: sentimentResult,
        processError: sentimentProcessError,
    } = useReviewConcordanceSentiment();

    const pageSize: number = result?.pageSize || defaultPageSize;

    const fetchPage = useCallback(
        (pageInner: number, pageSizeInner: number): void => {
            const options = {
                startDate: searchValues[ReviewConcordanceRequestFieldEnum.Range][0],
                endDate: searchValues[ReviewConcordanceRequestFieldEnum.Range][1],
                [ReviewConcordanceRequestFieldEnum.Query]: searchValues[ReviewConcordanceRequestFieldEnum.Query],
                [ReviewConcordanceRequestFieldEnum.ContextSize]:
                    searchValues[ReviewConcordanceRequestFieldEnum.ContextSize],
            };

            fetch(
                {
                    page: pageInner,
                    pageSize: pageSizeInner,
                    ...singleFilter,
                    ...options,
                },
                mainFilterKey
            );
            fetchSentiment(
                {
                    ...singleFilter,
                    ...options,
                },
                mainFilterKey
            );
        },
        [fetch, fetchSentiment, mainFilterKey, searchValues, singleFilter]
    );

    useEffect(() => {
        fetchPage(1, pageSize);
    }, [fetchPage, pageSize]);

    const dataSource: Array<ConcordanceDataType> = useMemo<
        Array<ConcordanceDataType>
    >((): Array<ConcordanceDataType> => {
        if (!result) {
            return [];
        }

        return result.results.map(
            (concordance: ReviewConcordanceItemType): ConcordanceDataType => ({
                key: concordance.id,
                ...concordance,
            })
        );
    }, [result]);

    function onPaginationChange(pageInner: number, pageSizeInner?: number | void): void {
        const hasPageSizeChanged = pageSizeInner && pageSizeInner !== pageSize;

        if (hasPageSizeChanged) {
            fetchPage(1, pageSizeInner);
            return;
        }

        fetchPage(pageInner, pageSize);

        if (result?.page !== pageInner) {
            track(AnalyticsTarget.PersonalCabinet.Pagination, pageInner);
        }
    }

    useEffect(() => {
        if (!queries[concordanceRangeName]) {
            replaceQuery({
                [concordanceRangeName]: defaultConcordancePeriod,
            });
        }
    }, [queries, replaceQuery]);

    function onFormSubmit(values: ConcordanceFormType) {
        replaceQuery({
            [concordanceQueryName]: values.q,
            [concordanceRangeName]: values.range,
            [concordanceContextSizeName]: values.contextSize,
        });
    }

    const hasSeveralSentiments = sentimentResult && Object.values(sentimentResult).filter(Boolean).length > 1;

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__CONCORDANCE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: dashboardRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                        },
                        {
                            path: concordanceRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__CONCORDANCE',
                        },
                    ]}
                />
                <PageHeader>
                    <PageHeaderTitle
                        productName={UsetifulNameProductEnum.REVIEWS_ANALYSIS__CONCORDANCE}
                        title="REVIEWS_ANALYSIS__CONCORDANCE_FORM__TITLE"
                    />
                </PageHeader>

                <ConcordanceForm onSubmit={onFormSubmit} values={searchValues} />
            </PageCard>

            <PageCard>
                {processError && <AlertFallback description={processError?.message} message={processError?.name} />}

                <Spinner isShow={isInProgress && isQueryEmpty} position="absolute" />

                {!processError && isQueryEmpty && (
                    <Empty
                        description={<Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE__START_SEARCH" />}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}

                {!processError && !isQueryEmpty && (
                    <>
                        <PageSubHeader>
                            <Locale
                                stringKey="REVIEWS_ANALYSIS__CONCORDANCE_SUB_HEADER__TOTAL"
                                valueMap={{total: getFormattedNumber(result?.count || 0)}}
                            />
                        </PageSubHeader>

                        <Table<ConcordanceDataType>
                            dataSource={dataSource}
                            loading={isInProgress}
                            pagination={{
                                total: result?.count,
                                pageSize,
                                current: result?.page,
                                size: 'default',
                                showSizeChanger: true,
                                onChange: onPaginationChange,
                            }}
                            size="middle"
                        >
                            <Table.Column<ConcordanceDataType>
                                align="center"
                                dataIndex="prevText"
                                ellipsis
                                key="prevText"
                                render={(_value: unknown, concordance: ConcordanceDataType): JSX.Element => (
                                    // The left ellipsis behaviour like this "...example text" is required here.
                                    // So dir="rtl" attribute does this
                                    <NavigationLink
                                        to={generatePath(reviewsManagementReviewItem.path, {id: concordance.id})}
                                    >
                                        {/* However the text is not arabic here. And we should set dir="ltr" here */}
                                        {/* to avoid punctuation problems like this: "!Hello" */}
                                        {/* This is similar like we insert ltr quote inside of arabic text.  */}
                                        <div
                                            className={classNames(
                                                styles.ConcordancePage_context,
                                                styles.ConcordancePage_context__prev
                                            )}
                                            dir="rtl"
                                        >
                                            {/* However the text is not arabic here. And we should set dir="ltr" here */}
                                            {/* to avoid punctuation problems like this: "!Hello" */}
                                            {/* This is similar like we insert ltr quote inside of arabic text.  */}
                                            <Tooltip placement="bottomRight" title={concordance.prevText}>
                                                <span dir="ltr">{concordance.prevText}</span>
                                            </Tooltip>
                                        </div>
                                    </NavigationLink>
                                )}
                                title={<Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE_TABLE__COLUMN__PREFIX" />}
                                width="43%"
                            />

                            <Table.Column<ConcordanceDataType>
                                align="center"
                                dataIndex="text"
                                key="text"
                                render={(_value: unknown, concordance: ConcordanceDataType): JSX.Element => {
                                    return (
                                        <span
                                            className={classNames(
                                                styles.ConcordancePage_query,
                                                concordance.sentiment
                                                    ? styles[`ConcordancePage_query__${concordance.sentiment}`]
                                                    : ''
                                            )}
                                        >
                                            <Tooltip
                                                placement="bottom"
                                                title={
                                                    concordance.sentiment
                                                        ? getLocalizedString(
                                                              ConcordanceSentimentTooltipEnum[concordance.sentiment]
                                                          )
                                                        : ''
                                                }
                                            >
                                                {concordance.query}
                                            </Tooltip>
                                        </span>
                                    );
                                }}
                                title={<Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE_TABLE__COLUMN__QUERY" />}
                                width="14%"
                            />

                            <Table.Column<ConcordanceDataType>
                                align="center"
                                dataIndex="nextText"
                                ellipsis
                                key="nextText"
                                render={(_value: unknown, concordance: ConcordanceDataType): JSX.Element => (
                                    <NavigationLink
                                        to={generatePath(reviewsManagementReviewItem.path, {id: concordance.id})}
                                    >
                                        <div className={styles.ConcordancePage_context}>
                                            <Tooltip placement="bottomLeft" title={concordance.nextText}>
                                                {concordance.nextText}
                                            </Tooltip>
                                        </div>
                                    </NavigationLink>
                                )}
                                title={<Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE_TABLE__COLUMN__POSTFIX" />}
                                width="43%"
                            />
                        </Table>
                    </>
                )}
            </PageCard>

            {sentimentProcessError && (
                <AlertFallback description={sentimentProcessError?.message} message={sentimentProcessError?.name} />
            )}

            {!sentimentProcessError && hasSeveralSentiments && (
                <PageCard>
                    <PageSubHeader className={styles.ConcordancePage_sectionHeader}>
                        <Locale stringKey="REVIEWS_ANALYSIS__CONCORDANCE__SENTIMENT_CHART__TITLE" />
                    </PageSubHeader>
                    <ConcordanceChart
                        data={sentimentResult}
                        period={searchValues[ReviewConcordanceRequestFieldEnum.Range]}
                    />
                </PageCard>
            )}
        </Page>
    );
}
