import {Button} from 'antd';

import {appRoute} from '../../../../../app-route';
import {NavigationLink} from '../../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../../provider/locale/localization';
import {FeedFormPage} from '../common/form-page/feed-form-page';
import {FeedResultsTypeEnum} from '../common/results/feed-results-const';

export function CompaniesValidation(): JSX.Element {
    return (
        <FeedFormPage
            headerButton={
                <NavigationLink to={appRoute.myCompanies.path}>
                    <Button>
                        <Locale stringKey="BUTTON__BACK" />
                    </Button>
                </NavigationLink>
            }
            route={appRoute.companyValidate.path}
            translations={{
                beforeProgressTitle: 'FEED__IMPORT__BEFORE_PROGRESS__TITLE',
                breadcrumbTitle: 'FEED__VALIDATION__TITLE',
                header: 'FEED__VALIDATION__TITLE',
                pageTitle: 'FEED__VALIDATION__TITLE',
                submitButtonLabel: 'FEED__VALIDATION__FORM__SUBMIT',
                abortButtonLabel: 'FEED__VALIDATION__ABORT__BUTTON',
                abortModalTitle: 'FEED__VALIDATION__ABORT_MODAL__TITLE',
                abortModalDescription: 'FEED__VALIDATION__ABORT_MODAL__DESCRIPTION',
                progressTitle: 'FEED__VALIDATION__PROGRESS__TITLE',
            }}
            type={FeedResultsTypeEnum.Validation}
        />
    );
}
