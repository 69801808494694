import {faAtom} from '@fortawesome/pro-regular-svg-icons';
import {faAngleLeft, faAngleRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'antd';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {Spinner} from '../../../../../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../../../../../provider/locale/localization';
import {PostAiSuggestHookType, PostAiSuggestItemType} from '../../../../../../../../../service/posts/posts-types';

import * as styles from './post-ai-suggest.scss';

type PropsType = {
    suggestSettings: PostAiSuggestHookType;
    onSelectSuggest: (text: string) => void;
};

export function PostAiSuggest(props: PropsType): JSX.Element {
    const {suggestSettings, onSelectSuggest} = props;

    const {suggests, isGeneratingSuggest, requestSuggest} = suggestSettings;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [activeSuggestIndex, setActiveSuggestIndex] = useState<number>(0);

    const previousSuggestLengthRef = useRef<number>(0);

    useEffect(() => {
        if (suggests[previousSuggestLengthRef.current]) {
            setActiveSuggestIndex(previousSuggestLengthRef.current);
            previousSuggestLengthRef.current = suggests.length;
        }
    }, [setActiveSuggestIndex, suggests]);

    const activeSuggest: PostAiSuggestItemType | null = useMemo(() => {
        return suggests[activeSuggestIndex] || null;
    }, [activeSuggestIndex, suggests]);

    const showPrevious = useCallback(() => {
        setActiveSuggestIndex(activeSuggestIndex - 1);
    }, [activeSuggestIndex, setActiveSuggestIndex]);

    const showNext = useCallback(() => {
        setActiveSuggestIndex(activeSuggestIndex + 1);
    }, [activeSuggestIndex, setActiveSuggestIndex]);

    const isLastSuggestActive = activeSuggestIndex === suggests.length - 1;

    const handleOpenSuggestModal = useCallback(() => {
        if (suggests.length === 0 && !isGeneratingSuggest) {
            requestSuggest();
        }

        setIsOpen(true);
    }, [isGeneratingSuggest, requestSuggest, suggests.length]);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <div className={styles.PostAiSuggest}>
            <FontAwesomeIcon className={styles.PostAiSuggest_leftBackgroundIcon} icon={faAtom} />
            <FontAwesomeIcon className={styles.PostAiSuggest_rightBackgroundIcon} icon={faAtom} />

            <Text block className={styles.PostAiSuggest_text} stringKey="POSTS_SUGGESTS__TITLE" />

            <Button className={styles.PostAiSuggest_button} onClick={handleOpenSuggestModal}>
                <Locale stringKey="POSTS_SUGGESTS__GENERATE" />
            </Button>

            <Modal
                closable
                footer={
                    <div>
                        <Button disabled={isGeneratingSuggest} loading={isGeneratingSuggest} onClick={requestSuggest}>
                            <Locale stringKey="POSTS_SUGGESTS__GENERATE_MORE" />
                        </Button>
                        <Button
                            disabled={!activeSuggest}
                            onClick={() => {
                                onSelectSuggest(activeSuggest?.text || '');
                                closeModal();
                            }}
                            type="primary"
                        >
                            <Locale stringKey="TEXT__SELECT" />
                        </Button>
                    </div>
                }
                onCancel={closeModal}
                open={isOpen}
                title={<Locale stringKey="POSTS_SUGGESTS__TITLE" />}
                width={848}
            >
                {suggests.length === 0 && isGeneratingSuggest ? (
                    <Spinner />
                ) : (
                    <div className={styles.PostAiSuggestModalContent}>
                        <div className={styles.PostAiSuggestModalContent_header}>
                            <Text bolder lighter>
                                <Locale
                                    stringKey="POSTS_SUGGESTS__SUGGEST"
                                    valueMap={{
                                        current: activeSuggestIndex + 1,
                                        total: suggests.length,
                                    }}
                                />
                            </Text>

                            <div className={styles.PostAiSuggestModalContent_suggestNavigation}>
                                <Button
                                    className={styles.PostAiSuggestModalContent_suggestNavigationButton}
                                    disabled={activeSuggestIndex === 0}
                                    onClick={showPrevious}
                                >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </Button>
                                <Button
                                    className={styles.PostAiSuggestModalContent_suggestNavigationButton}
                                    disabled={isLastSuggestActive}
                                    onClick={showNext}
                                >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </Button>
                            </div>
                        </div>

                        {activeSuggest && (
                            <div className={styles.PostAiSuggestModalContent_suggest}>
                                <div className={styles.PostAiSuggestModalContent_suggestContent}>
                                    {activeSuggest.text}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
}
