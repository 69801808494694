import {PageCard} from '../../../../layout/page-card/page-card';
import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/locale';
import {OnlinePresenceSynchronizationType} from '../../../../service/online-presence/online-presence-type';

import * as styles from './synchronization-stats.scss';

type PropsType = {
    data: OnlinePresenceSynchronizationType['syncStatus']['navigators'];
};

export function SynchronizedNavigators(props: PropsType): JSX.Element | null {
    const {data} = props;

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    return (
        <PageCard
            bodyClassName={styles.SyncNavigators_wrapper}
            title={<Locale stringKey="ONLINE_PRESENCE__SYNCHRONIZED_NAVIGATORS" />}
        >
            {data.map((item) => {
                const catalogName = getCatalogName(item.id);
                const catalogLogo = getCatalogLogo(item.id);

                return (
                    <div className={styles.SyncNavigators_element} key={item.id}>
                        <img alt={catalogName} className={styles.SyncNavigators_element__icon} src={catalogLogo} />
                        <p className={styles.SyncNavigators_element__name}>{catalogName}</p>
                    </div>
                );
            })}
        </PageCard>
    );
}
