import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/locale';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {AnalyticsWorkload} from './analytics-workload';

type PropsType = {
    companyId: number;
    isDashboardWidget?: boolean;
};

export function AnalyticsWorkLoadPageCard(props: PropsType): JSX.Element {
    const {companyId, isDashboardWidget} = props;

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__WORKLOAD__TITLE" />}
                    widget={isDashboardWidget ? DashboardWidgetEnum.Workload : null}
                />
            }
        >
            <AnalyticsWorkload companyId={companyId} />
        </PageCard>
    );
}
