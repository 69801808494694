import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {defaultAutoReplyToReviewData} from '../../../service/reviews/reviews-response-templates';
import {AutoReplyForm} from '../auto-reply-form/auto-reply-form';
import {AutoReplyFormSubHeader} from '../auto-reply-form/auto-reply-form-sub-header';

export function AutoReplyCreate(): JSX.Element {
    const {
        reviewsManagementAutoReplies: reviewsManagementAutoRepliesRoute,
        reviewsManagementAutoReplyCreate: reviewsManagementAutoReplyCreateRoute,
    } = appRoute;

    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('AUTO_REPLIES__HEADER__RULE_CREATION')} />

            <BreadCrumbs
                list={[
                    {
                        path: reviewsManagementAutoRepliesRoute.path,
                        titleLangKey: 'CATEGORY_NAME__AUTO_REPLIES',
                    },
                    {
                        path: reviewsManagementAutoReplyCreateRoute.path,
                        titleLangKey: 'AUTO_REPLIES__HEADER__RULE_CREATION',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="AUTO_REPLIES__HEADER__RULE_CREATION" />
            </PageHeader>

            <AutoReplyFormSubHeader />

            <AutoReplyForm autoReplyData={defaultAutoReplyToReviewData} mode="create" />
        </Page>
    );
}
