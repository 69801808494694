import {useMemo} from 'react';

import {CatalogType} from '../../../../../../provider/catalogs/catalogs-type';
import {ReviewDataType} from '../../../../../../service/reviews/reviews-type';
import {toTrimmedString} from '../../../../../../util/string';

import * as styles from './reviews-item-address.scss';

type PropsType = {
    reviewData: ReviewDataType;
    catalogData?: CatalogType;
};

export function DashboardReviewsItemAddress(props: PropsType): JSX.Element {
    const {reviewData, catalogData} = props;
    const {company, brand} = reviewData;

    const addressText = useMemo(() => {
        if (company) {
            if (typeof company.address === 'string') {
                return company.address;
            }

            const {region, city, street, housenumber} = company.address;

            return [company.name, region, city, street, housenumber].map(toTrimmedString).filter(Boolean).join(', ');
        }

        return brand.name;
    }, [brand, company]);

    return (
        <div className={styles.review__address_wrapper}>
            <img
                alt={catalogData ? catalogData.name : ''}
                className={styles.review__catalog_icon}
                src={catalogData ? catalogData.logo : ''}
                title={catalogData ? catalogData.name : ''}
            />
            <p className={styles.review__address}>{addressText}</p>
        </div>
    );
}
