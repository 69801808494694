import {
    ReviewConcordanceRequestFieldEnum,
    ReviewConcordanceRequestType,
} from '../../../../../../service/reivew-analysis/review-concordance/review-concordance-type';

export type KeywordsAndTopicsFormType = ReviewConcordanceRequestType & {
    [ReviewConcordanceRequestFieldEnum.Range]: [Date | null, Date | null];
};

export enum KeywordsAndTopicsFormTypePeriodEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    PreviousFullMonth = 'full_month',
    Quarter = 'quarter',
    Custom = 'custom',
}

export enum KeywordsAndTopicsFormTypeAggregationEnum {
    Day = 'day',
    Week = 'week',
    Month = 'month',
}
