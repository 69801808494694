import {Alert} from 'antd';
import {useCallback, useMemo} from 'react';

import {LocaleWithCatalogName} from '../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    PostImageType,
    PostSourceEnum,
    PostVideoType,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {Form} from '../../../../../../../typings/antd';
import {ProvidersEnum, ProvidersIdsEnum} from '../../../../../../../util/type';
import {PostContentWrapper} from '../../../post-content-wrapper/post-content-wrapper';
import {INSTAGRAM_POST_SETTINGS} from '../content-step-helper';
import {SourcePostPropsType} from '../content-step-type';
import {ImagesItem} from '../items/media/image/images-item';
import {VideoItem} from '../items/media/video/video-item';
import {PostTextItem} from '../items/text/post-text-item';
import {PostPreview} from '../post-preview/post-preview';
import {PostRequirements} from '../post-requirements/post-requirements';

export function InstagramCatalogData(props: SourcePostPropsType): JSX.Element {
    const {formInstance, sourceIndex, suggestSettings, formMode} = props;

    const disabled = formMode === PostFormModeEnum.View || formMode === PostFormModeEnum.EditExisted;
    const formValues = formInstance.getFieldValue([]) as PostFormType;

    const {getLocalizedString} = useLocale();

    const fieldName = useMemo(() => [PostFormFieldsEnum.Posts, sourceIndex], [sourceIndex]);
    const imagesNamePath = useMemo(() => [...fieldName, SourcePostFormFieldsEnum.Images], [fieldName]);
    const videoNamePath = useMemo(() => [...fieldName, SourcePostFormFieldsEnum.Video], [fieldName]);

    const images: Array<PostImageType> | null = Form.useWatch(imagesNamePath, formInstance);

    const video: PostVideoType | null = Form.useWatch(videoNamePath, formInstance);

    const hasAtLeastOneMediaFileValidationRule = useMemo(() => {
        return {
            validator: () => {
                if (!video && images && images.length === 0) {
                    return Promise.reject(new Error(getLocalizedString('POSTS_FORM__AT_LEAST_ONE_MEDIA_ERROR')));
                }

                return Promise.resolve();
            },
        };
    }, [getLocalizedString, images, video]);

    const validateOnUploadFinished = useCallback(
        (isUploading: boolean) => {
            if (!isUploading) {
                formInstance.validateFields([imagesNamePath, videoNamePath]);
            }
        },
        [formInstance, imagesNamePath, videoNamePath]
    );

    return (
        <PostContentWrapper>
            <PostContentWrapper.Left>
                {formMode === PostFormModeEnum.EditExisted && (
                    <Alert
                        description={
                            <LocaleWithCatalogName
                                catalogId={ProvidersIdsEnum.instagram}
                                stringKey="POSTS_EDIT__NOT_SUPPORTED"
                            />
                        }
                        type="warning"
                    />
                )}

                <PostTextItem
                    disabled={disabled}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Text]}
                    formInstance={formInstance}
                    maxLength={INSTAGRAM_POST_SETTINGS.maxTextLength}
                    suggestSettings={suggestSettings}
                />

                <ImagesItem
                    disabled={disabled}
                    extraRules={[hasAtLeastOneMediaFileValidationRule]}
                    fieldName={imagesNamePath}
                    formInstance={formInstance}
                    onUploadingStatusChanged={validateOnUploadFinished}
                    settings={INSTAGRAM_POST_SETTINGS.images}
                />

                <VideoItem
                    dependencies={[imagesNamePath]}
                    disabled={disabled}
                    extraRules={[hasAtLeastOneMediaFileValidationRule]}
                    fieldName={videoNamePath}
                    onUploadingStatusChanged={validateOnUploadFinished}
                />
            </PostContentWrapper.Left>
            <PostContentWrapper.Right>
                <PostPreview
                    catalog={PostSourceEnum.instagram}
                    formInstance={formInstance}
                    sources={{
                        igGroupsIds: formValues[PostFormFieldsEnum.InstagramGroupsIds],
                    }}
                />
                <PostRequirements options={INSTAGRAM_POST_SETTINGS} provider={ProvidersEnum.instagram} />
            </PostContentWrapper.Right>
        </PostContentWrapper>
    );
}
