import {faClock} from '@fortawesome/pro-regular-svg-icons';
import {faCheck, faExclamation, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {NeverError} from '../../util/error';

import {ActionThumbStateNameEnum, ActionThumbUiDataType} from './action-thumb-type';
import * as styles from './action-thumb.scss';

export function getActionThumbUiData(type: ActionThumbStateNameEnum): ActionThumbUiDataType {
    switch (type) {
        case ActionThumbStateNameEnum.synchronized:
            return {
                className: styles.ActionThumb_synchronized,
                image: <FontAwesomeIcon className={styles.ActionThumb_image} icon={faCheck} />,
                langKey: 'ACTION_THUMBS__SYNCHRONIZED',
            };

        case ActionThumbStateNameEnum.sent:
            return {
                className: styles.ActionThumb_sent,
                image: <FontAwesomeIcon className={styles.ActionThumb_image} icon={faSpinner} />,
                langKey: 'ACTION_THUMBS__SENT',
            };

        case ActionThumbStateNameEnum.actionRequired:
            return {
                className: styles.ActionThumb_actionRequired,
                image: <FontAwesomeIcon className={styles.ActionThumb_image} icon={faExclamation} />,
                langKey: 'ACTION_THUMBS__ACTION_REQUIRED',
            };

        case ActionThumbStateNameEnum.confirmation:
            return {
                className: styles.ActionThumb_confirmation,
                image: <FontAwesomeIcon className={styles.ActionThumb_image} icon={faClock} />,
                langKey: 'ACTION_THUMBS__CONFIRMATION',
            };

        default:
            throw new NeverError(type);
    }
}
