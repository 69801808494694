import {PropsWithChildren} from 'react';

import * as styles from './phone-layout.scss';

export function PhoneLayout(props: PropsWithChildren<Record<string, unknown>>): JSX.Element {
    const {children} = props;

    return (
        <svg
            className={styles.PhoneLayout}
            fill="none"
            height="334"
            id="svg110"
            version="1.1"
            viewBox="0 0 358 334"
            width="358"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#a)" id="g21">
                <g filter="url(#b)" id="g1">
                    <rect
                        fill="black"
                        height="504.107"
                        id="rect1"
                        rx="34.4738"
                        width="239.667"
                        x="59.6016"
                        y="14.6484"
                    />
                </g>
                <rect
                    height="501.501"
                    id="rect2"
                    rx="33.171"
                    stroke="#4F5179"
                    strokeWidth="2.60558"
                    width="237.062"
                    x="60.9044"
                    y="15.9512"
                />
                <g filter="url(#c)" id="g3">
                    <rect
                        fill="black"
                        fillOpacity="0.01"
                        height="504.995"
                        id="rect3"
                        rx="34.4738"
                        width="240.977"
                        x="58.9453"
                        y="14.2031"
                    />
                </g>
                <rect
                    height="504.594"
                    id="rect4"
                    rx="34.2734"
                    stroke="#474970"
                    strokeWidth="0.400859"
                    width="240.576"
                    x="59.1457"
                    y="14.4036"
                />
                <g filter="url(#d)" id="g5" />
                <rect
                    height="503.30499"
                    id="rect6"
                    rx="34.073002"
                    stroke="url(#e)"
                    strokeWidth="0.801717"
                    style={{display: 'inline'}}
                    width="238.866"
                    x="60.002399"
                    y="15.0493"
                />
                <mask
                    height="488"
                    id="f"
                    maskUnits="userSpaceOnUse"
                    style={{maskType: 'alpha'}}
                    width="224"
                    x="68"
                    y="23"
                >
                    <rect
                        fill="white"
                        height="486.292"
                        id="rect7"
                        rx="25.0554"
                        width="223.073"
                        x="68.0938"
                        y="23.7578"
                    />
                </mask>
                <g id="g8" mask="url(#f)" style={{fill: 'none'}}>
                    <foreignObject height={486.292} rx={25.055} width={223.073} x={68.094} y={23.758}>
                        <div className={styles.PhoneLayout_contentWrapper}>{children}</div>
                    </foreignObject>
                </g>
                <rect
                    fill="#7373A7"
                    fillOpacity="0.52"
                    height="4.08737"
                    id="rect9"
                    width="3.61414"
                    x="58.9453"
                    y="65.0898"
                />
                <rect
                    fill="#7373A7"
                    fillOpacity="0.52"
                    height="3.883"
                    id="rect10"
                    width="3.61414"
                    x="296.273"
                    y="65.2969"
                />
                <rect
                    fill="#7373A7"
                    fillOpacity="0.52"
                    height="3.47427"
                    id="rect11"
                    width="3.81493"
                    x="248.086"
                    y="14.2031"
                />
                <g filter="url(#g)" id="g12">
                    <rect
                        fill="#4F5179"
                        height="39.4431"
                        id="rect12"
                        rx="0.801717"
                        width="2.40943"
                        x="56.5273"
                        y="197.262"
                    />
                </g>
                <rect
                    height="39.1425"
                    id="rect13"
                    rx="0.651395"
                    stroke="url(#h)"
                    strokeWidth="0.300644"
                    width="2.10878"
                    x="56.6777"
                    y="197.412"
                />
                <g filter="url(#i)" id="g14">
                    <rect
                        fill="#4F5179"
                        height="39.8519"
                        id="rect14"
                        rx="0.801717"
                        width="2.40943"
                        x="56.5273"
                        y="149.031"
                    />
                </g>
                <rect
                    height="39.5512"
                    id="rect15"
                    rx="0.651395"
                    stroke="url(#j)"
                    strokeWidth="0.300644"
                    width="2.10878"
                    x="56.6777"
                    y="149.182"
                />
                <g filter="url(#k)" id="g16">
                    <rect
                        fill="#4F5179"
                        height="21.4587"
                        id="rect16"
                        rx="0.801717"
                        width="2.40943"
                        x="56.5273"
                        y="111.836"
                    />
                </g>
                <rect
                    height="21.158"
                    id="rect17"
                    rx="0.651395"
                    stroke="url(#l)"
                    strokeWidth="0.300644"
                    width="2.10878"
                    x="56.6777"
                    y="111.986"
                />
                <g filter="url(#m)" id="g18">
                    <rect
                        fill="#4F5179"
                        height="60.4931"
                        id="rect18"
                        rx="0.801717"
                        width="2.40943"
                        x="299.676"
                        y="162.316"
                    />
                </g>
                <rect
                    height="60.1924"
                    id="rect19"
                    rx="0.651395"
                    stroke="url(#n)"
                    strokeWidth="0.300644"
                    width="2.10878"
                    x="299.826"
                    y="162.467"
                />
                <g filter="url(#o)" id="g20">
                    <rect
                        fill="#141414"
                        height="3.4326"
                        id="rect20"
                        rx="1.7163"
                        width="34.972"
                        x="161.707"
                        y="18.6836"
                    />
                </g>
                <rect
                    height="3.22361"
                    id="rect21"
                    rx="1.6118"
                    stroke="#0E0E0E"
                    strokeWidth="0.208986"
                    width="34.7631"
                    x="161.812"
                    y="18.7881"
                />
            </g>
            <defs id="defs110">
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="631.905"
                    id="a"
                    width="356.771"
                    x="0.921234"
                    y="-20.5615"
                >
                    <feFlood floodOpacity="0" id="feFlood21" result="BackgroundImageFix" />
                    <feColorMatrix
                        id="feColorMatrix21"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="16.4594" id="feOffset21" />
                    <feGaussianBlur id="feGaussianBlur21" stdDeviation="8.2297" />
                    <feComposite id="feComposite21" in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        id="feColorMatrix22"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        id="feBlend22"
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix23"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="36.4776" id="feOffset23" />
                    <feGaussianBlur id="feGaussianBlur23" stdDeviation="27.8031" />
                    <feComposite id="feComposite23" in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        id="feColorMatrix24"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        id="feBlend24"
                        in2="effect1_dropShadow_19929_42669"
                        mode="normal"
                        result="effect2_dropShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix25"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-16.9043" id="feOffset25" />
                    <feGaussianBlur id="feGaussianBlur25" stdDeviation="8.89698" />
                    <feComposite id="feComposite25" in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        id="feColorMatrix26"
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
                    />
                    <feBlend
                        id="feBlend26"
                        in2="effect2_dropShadow_19929_42669"
                        mode="normal"
                        result="effect3_dropShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix27"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-5.78303" id="feOffset27" />
                    <feGaussianBlur id="feGaussianBlur27" stdDeviation="12.6782" />
                    <feComposite id="feComposite27" in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        id="feColorMatrix28"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        id="feBlend28"
                        in2="effect3_dropShadow_19929_42669"
                        mode="normal"
                        result="effect4_dropShadow_19929_42669"
                    />
                    <feBlend
                        id="feBlend29"
                        in="SourceGraphic"
                        in2="effect4_dropShadow_19929_42669"
                        mode="normal"
                        result="shape"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="504.106"
                    id="b"
                    width="239.668"
                    x="59.6016"
                    y="14.6484"
                >
                    <feFlood floodOpacity="0" id="feFlood29" result="BackgroundImageFix" />
                    <feBlend id="feBlend30" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix30"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feMorphology
                        id="feMorphology30"
                        in="SourceAlpha"
                        operator="erode"
                        radius="3.00644"
                        result="effect1_innerShadow_19929_42669"
                    />
                    <feOffset id="feOffset30" />
                    <feGaussianBlur id="feGaussianBlur30" stdDeviation="0.100215" />
                    <feComposite id="feComposite30" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix31"
                        type="matrix"
                        values="0 0 0 0 0.247059 0 0 0 0 0.247059 0 0 0 0 0.247059 0 0 0 1 0"
                    />
                    <feBlend id="feBlend31" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="504.995"
                    id="c"
                    width="240.977"
                    x="58.9453"
                    y="14.2031"
                >
                    <feFlood floodOpacity="0" id="feFlood31" result="BackgroundImageFix" />
                    <feBlend id="feBlend32" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix32"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feMorphology
                        id="feMorphology32"
                        in="SourceAlpha"
                        operator="erode"
                        radius="1.00215"
                        result="effect1_innerShadow_19929_42669"
                    />
                    <feOffset id="feOffset32" />
                    <feGaussianBlur id="feGaussianBlur32" stdDeviation="0.100215" />
                    <feComposite id="feComposite32" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix33"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.101961 0 0 0 0 0.152941 0 0 0 1 0"
                    />
                    <feBlend id="feBlend33" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="504.106"
                    id="d"
                    width="239.668"
                    x="59.6016"
                    y="14.6484"
                >
                    <feFlood floodOpacity="0" id="feFlood33" result="BackgroundImageFix" />
                    <feBlend id="feBlend34" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix34"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feMorphology
                        id="feMorphology34"
                        in="SourceAlpha"
                        operator="erode"
                        radius="1.00215"
                        result="effect1_innerShadow_19929_42669"
                    />
                    <feOffset id="feOffset34" />
                    <feGaussianBlur id="feGaussianBlur34" stdDeviation="0.100215" />
                    <feComposite id="feComposite34" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix35"
                        type="matrix"
                        values="0 0 0 0 0.686275 0 0 0 0 0.690196 0 0 0 0 0.670588 0 0 0 1 0"
                    />
                    <feBlend id="feBlend35" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                </filter>
                <pattern height="1" id="pattern0" patternContentUnits="objectBoundingBox" width="1">
                    <use id="use35" transform="scale(0.00119474 0.000677507)" xlinkHref="#image0_19929_42669" />
                </pattern>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="40.2451"
                    id="g"
                    width="3.21187"
                    x="55.7256"
                    y="196.861"
                >
                    <feFlood floodOpacity="0" id="feFlood35" result="BackgroundImageFix" />
                    <feBlend id="feBlend36" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix36"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset36" />
                    <feComposite id="feComposite36" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix37"
                        type="matrix"
                        values="0 0 0 0 0.854902 0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 1 0"
                    />
                    <feBlend id="feBlend37" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                    <feColorMatrix
                        id="feColorMatrix38"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset38" />
                    <feGaussianBlur id="feGaussianBlur38" stdDeviation="0.400859" />
                    <feComposite id="feComposite38" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix39"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend39"
                        in2="effect1_innerShadow_19929_42669"
                        mode="normal"
                        result="effect2_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix40"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-1.60343" id="feOffset40" />
                    <feGaussianBlur id="feGaussianBlur40" stdDeviation="0.200429" />
                    <feComposite id="feComposite40" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix41"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend41"
                        in2="effect2_innerShadow_19929_42669"
                        mode="normal"
                        result="effect3_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix42"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1.60343" id="feOffset42" />
                    <feGaussianBlur id="feGaussianBlur42" stdDeviation="0.200429" />
                    <feComposite id="feComposite42" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix43"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend43"
                        in2="effect3_innerShadow_19929_42669"
                        mode="normal"
                        result="effect4_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix44"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="0.601288" id="feOffset44" />
                    <feComposite id="feComposite44" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix45"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend45"
                        in2="effect4_innerShadow_19929_42669"
                        mode="normal"
                        result="effect5_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix46"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-0.601288" id="feOffset46" />
                    <feComposite id="feComposite46" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix47"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend47"
                        in2="effect5_innerShadow_19929_42669"
                        mode="normal"
                        result="effect6_innerShadow_19929_42669"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="40.6533"
                    id="i"
                    width="3.21187"
                    x="55.7256"
                    y="148.63"
                >
                    <feFlood floodOpacity="0" id="feFlood47" result="BackgroundImageFix" />
                    <feBlend id="feBlend48" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix48"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset48" />
                    <feComposite id="feComposite48" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix49"
                        type="matrix"
                        values="0 0 0 0 0.854902 0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 1 0"
                    />
                    <feBlend id="feBlend49" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                    <feColorMatrix
                        id="feColorMatrix50"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset50" />
                    <feGaussianBlur id="feGaussianBlur50" stdDeviation="0.400859" />
                    <feComposite id="feComposite50" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix51"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend51"
                        in2="effect1_innerShadow_19929_42669"
                        mode="normal"
                        result="effect2_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix52"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-1.60343" id="feOffset52" />
                    <feGaussianBlur id="feGaussianBlur52" stdDeviation="0.200429" />
                    <feComposite id="feComposite52" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix53"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend53"
                        in2="effect2_innerShadow_19929_42669"
                        mode="normal"
                        result="effect3_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix54"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1.60343" id="feOffset54" />
                    <feGaussianBlur id="feGaussianBlur54" stdDeviation="0.200429" />
                    <feComposite id="feComposite54" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix55"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend55"
                        in2="effect3_innerShadow_19929_42669"
                        mode="normal"
                        result="effect4_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix56"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="0.601288" id="feOffset56" />
                    <feComposite id="feComposite56" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix57"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend57"
                        in2="effect4_innerShadow_19929_42669"
                        mode="normal"
                        result="effect5_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix58"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-0.601288" id="feOffset58" />
                    <feComposite id="feComposite58" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix59"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend59"
                        in2="effect5_innerShadow_19929_42669"
                        mode="normal"
                        result="effect6_innerShadow_19929_42669"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="22.2607"
                    id="k"
                    width="3.21187"
                    x="55.7256"
                    y="111.435"
                >
                    <feFlood floodOpacity="0" id="feFlood59" result="BackgroundImageFix" />
                    <feBlend id="feBlend60" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix60"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset60" />
                    <feComposite id="feComposite60" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix61"
                        type="matrix"
                        values="0 0 0 0 0.854902 0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 1 0"
                    />
                    <feBlend id="feBlend61" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                    <feColorMatrix
                        id="feColorMatrix62"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset62" />
                    <feGaussianBlur id="feGaussianBlur62" stdDeviation="0.400859" />
                    <feComposite id="feComposite62" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix63"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend63"
                        in2="effect1_innerShadow_19929_42669"
                        mode="normal"
                        result="effect2_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix64"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-1.60343" id="feOffset64" />
                    <feGaussianBlur id="feGaussianBlur64" stdDeviation="0.200429" />
                    <feComposite id="feComposite64" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix65"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend65"
                        in2="effect2_innerShadow_19929_42669"
                        mode="normal"
                        result="effect3_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix66"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1.60343" id="feOffset66" />
                    <feGaussianBlur id="feGaussianBlur66" stdDeviation="0.200429" />
                    <feComposite id="feComposite66" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix67"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend67"
                        in2="effect3_innerShadow_19929_42669"
                        mode="normal"
                        result="effect4_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix68"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="0.601288" id="feOffset68" />
                    <feComposite id="feComposite68" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix69"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend69"
                        in2="effect4_innerShadow_19929_42669"
                        mode="normal"
                        result="effect5_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix70"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-0.601288" id="feOffset70" />
                    <feComposite id="feComposite70" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix71"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend71"
                        in2="effect5_innerShadow_19929_42669"
                        mode="normal"
                        result="effect6_innerShadow_19929_42669"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="61.2949"
                    id="m"
                    width="3.21187"
                    x="298.874"
                    y="161.916"
                >
                    <feFlood floodOpacity="0" id="feFlood71" result="BackgroundImageFix" />
                    <feBlend id="feBlend72" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix72"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset72" />
                    <feComposite id="feComposite72" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix73"
                        type="matrix"
                        values="0 0 0 0 0.854902 0 0 0 0 0.858824 0 0 0 0 0.839216 0 0 0 1 0"
                    />
                    <feBlend id="feBlend73" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                    <feColorMatrix
                        id="feColorMatrix74"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-1.00215" id="feOffset74" />
                    <feGaussianBlur id="feGaussianBlur74" stdDeviation="0.400859" />
                    <feComposite id="feComposite74" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix75"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend75"
                        in2="effect1_innerShadow_19929_42669"
                        mode="normal"
                        result="effect2_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix76"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-1.60343" id="feOffset76" />
                    <feGaussianBlur id="feGaussianBlur76" stdDeviation="0.200429" />
                    <feComposite id="feComposite76" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix77"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend77"
                        in2="effect2_innerShadow_19929_42669"
                        mode="normal"
                        result="effect3_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix78"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1.60343" id="feOffset78" />
                    <feGaussianBlur id="feGaussianBlur78" stdDeviation="0.200429" />
                    <feComposite id="feComposite78" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix79"
                        type="matrix"
                        values="0 0 0 0 0.254902 0 0 0 0 0.258824 0 0 0 0 0.235294 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend79"
                        in2="effect3_innerShadow_19929_42669"
                        mode="normal"
                        result="effect4_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix80"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="0.601288" id="feOffset80" />
                    <feComposite id="feComposite80" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix81"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend81"
                        in2="effect4_innerShadow_19929_42669"
                        mode="normal"
                        result="effect5_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix82"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="-0.601288" id="feOffset82" />
                    <feComposite id="feComposite82" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix83"
                        type="matrix"
                        values="0 0 0 0 0.623529 0 0 0 0 0.623529 0 0 0 0 0.772549 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend83"
                        in2="effect5_innerShadow_19929_42669"
                        mode="normal"
                        result="effect6_innerShadow_19929_42669"
                    />
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="3.43262"
                    id="o"
                    width="34.9727"
                    x="161.707"
                    y="18.6836"
                >
                    <feFlood floodOpacity="0" id="feFlood83" result="BackgroundImageFix" />
                    <feBlend id="feBlend84" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
                    <feColorMatrix
                        id="feColorMatrix84"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feMorphology
                        id="feMorphology84"
                        in="SourceAlpha"
                        operator="erode"
                        radius="0.208986"
                        result="effect1_innerShadow_19929_42669"
                    />
                    <feOffset id="feOffset84" />
                    <feGaussianBlur id="feGaussianBlur84" stdDeviation="0.522465" />
                    <feComposite id="feComposite84" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix85"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                    />
                    <feBlend id="feBlend85" in2="shape" mode="normal" result="effect1_innerShadow_19929_42669" />
                    <feColorMatrix
                        id="feColorMatrix86"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="0.626958" id="feOffset86" />
                    <feComposite id="feComposite86" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix87"
                        type="matrix"
                        values="0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend87"
                        in2="effect1_innerShadow_19929_42669"
                        mode="normal"
                        result="effect2_innerShadow_19929_42669"
                    />
                    <feColorMatrix
                        id="feColorMatrix88"
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="-0.626958" id="feOffset88" />
                    <feComposite id="feComposite88" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
                    <feColorMatrix
                        id="feColorMatrix89"
                        type="matrix"
                        values="0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 0 0.164706 0 0 0 1 0"
                    />
                    <feBlend
                        id="feBlend89"
                        in2="effect2_innerShadow_19929_42669"
                        mode="normal"
                        result="effect3_innerShadow_19929_42669"
                    />
                </filter>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="e"
                    x1="179.435"
                    x2="179.435"
                    y1="14.6484"
                    y2="518.755"
                >
                    <stop id="stop89" stopColor="#9F9FC5" />
                    <stop id="stop90" offset="1" stopColor="#C8C8E3" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="h"
                    x1="55.2131"
                    x2="60.251"
                    y1="214.431"
                    y2="214.431"
                >
                    <stop id="stop91" stopColor="#AAAACD" />
                    <stop id="stop92" offset="0.25" stopColor="#4F5179" />
                    <stop id="stop93" offset="0.5" stopColor="#4F5179" />
                    <stop id="stop94" offset="0.71875" stopColor="#7D7EA3" />
                    <stop id="stop95" offset="0.937329" stopColor="#ABABCE" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="j"
                    x1="55.2131"
                    x2="60.251"
                    y1="166.379"
                    y2="166.379"
                >
                    <stop id="stop96" stopColor="#AAAACD" />
                    <stop id="stop97" offset="0.25" stopColor="#4F5179" />
                    <stop id="stop98" offset="0.5" stopColor="#4F5179" />
                    <stop id="stop99" offset="0.71875" stopColor="#7D7EA3" />
                    <stop id="stop100" offset="0.937329" stopColor="#ABABCE" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="l"
                    x1="55.2131"
                    x2="60.251"
                    y1="121.177"
                    y2="121.177"
                >
                    <stop id="stop101" stopColor="#AAAACD" />
                    <stop id="stop102" offset="0.25" stopColor="#4F5179" />
                    <stop id="stop103" offset="0.5" stopColor="#4F5179" />
                    <stop id="stop104" offset="0.71875" stopColor="#7D7EA3" />
                    <stop id="stop105" offset="0.937329" stopColor="#ABABCE" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="n"
                    x1="298.362"
                    x2="303.399"
                    y1="188.649"
                    y2="188.649"
                >
                    <stop id="stop106" stopColor="#AAAACD" />
                    <stop id="stop107" offset="0.25" stopColor="#4F5179" />
                    <stop id="stop108" offset="0.5" stopColor="#4F5179" />
                    <stop id="stop109" offset="0.71875" stopColor="#7D7EA3" />
                    <stop id="stop110" offset="0.937329" stopColor="#ABABCE" />
                </linearGradient>
            </defs>
        </svg>
    );
}
