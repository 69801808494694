import {LabeledValue} from 'antd/lib/select';

import {CountryType, CountryV2Type, IdNameType} from '../../../../../../service/api/api-type';

export function getLabel(value?: IdNameType | null): LabeledValue | undefined {
    if (!value) {
        // eslint-disable-next-line no-undefined
        return undefined;
    }

    return {
        value: value.id,
        label: value.name,
    };
}

export function buildFullSearchQuery(
    country: CountryType | CountryV2Type | null,
    ...args: Array<IdNameType | string | null>
): Array<string> {
    return [country?.name, ...args.map((item) => (typeof item === 'string' ? item : item?.name))]
        .filter(Boolean)
        .map(String);
}
