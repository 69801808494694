import {Modal} from 'antd';
import {PropsWithChildren} from 'react';

import * as styles from './post-preview-overlay.scss';

type PropsType = {
    onClose: () => void;
};

export function PostPreviewOverlay(props: PropsWithChildren<PropsType>): JSX.Element {
    const {children, onClose} = props;

    return (
        <Modal
            centered
            className={styles.PostPreviewOverlay}
            closable
            footer={null}
            onCancel={onClose}
            open
            width={800}
        >
            <div className={styles.PostPreviewOverlay_content}>{children}</div>
        </Modal>
    );
}
