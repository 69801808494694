import {Tooltip} from 'antd';

import {Locale} from '../../provider/locale/locale';
import {classNames} from '../../util/css';

import * as styles from './sup.scss';

export function BetaSup(): JSX.Element {
    return (
        <Tooltip
            placement="bottom"
            title={
                <div className={styles.Sup_tooltip}>
                    <Locale stringKey="BETA_FEATURE__TOOLTIP" />
                </div>
            }
        >
            <sup className={classNames(styles.Sup, styles.Sup__beta)}>beta</sup>
        </Tooltip>
    );
}
