import {ReviewsStateType} from '../../page/main/reviews/reviews-state/reviews-state-type';
import {serializeToURLParameters} from '../../util/api-adapter';
import {fetchX} from '../../util/fetch';
import {objectToUrlParameters} from '../../util/url';
import {rootApiUrl} from '../api/api-const';

import {reviewsStateFilterToUrlObject} from './reviews-helper';

export enum ExportReportModeEnum {
    Base = 'base',
    Base1 = 'base1',
    Base2 = 'base2',
    Base3 = 'base3',
    Base4 = 'base4',
    Base5 = 'base5',
}

export type ExportReportOptionsType = {
    mode: ExportReportModeEnum;
    customReportName: string;
    withReviewsSummary?: boolean;
    withTagsStat?: boolean;
    version?: number;
};

export function fetchReviewsGetReport(
    reportOptions: ExportReportOptionsType,
    reviewOption: ReviewsStateType,
    mainFilterKey: string
): Promise<void> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ExportReportOptionsType>(reportOptions),
        ...reviewsStateFilterToUrlObject(reviewOption),
        ...(!reviewOption.company_ids ? {filter_key: mainFilterKey} : null),
        timestamp: Date.now(),
    });

    return fetchX<void>(`${rootApiUrl}/v4/reviews/export/?${searchParameters}`);
}
