import {ProfileType} from '../../../../service/profile/profile-type';
import {PersonalInformationType} from '../profile-type';

export function serializeProfileFromPersonalInfo(
    personalInfo: PersonalInformationType,
    profile: ProfileType
): ProfileType {
    return {
        ...profile,
        country: personalInfo.citizen,
        personal_data: {
            first_name: personalInfo.name,
            last_name: personalInfo.surname,
            email: personalInfo.email,
            phone_number: personalInfo.phone,
        },
        profile: {
            ...profile.profile,
            timezone: personalInfo.timezone,
        },
    };
}
