import {Input} from 'antd';

import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {useDocumentationLinks} from '../../../../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {Form} from '../../../../../../../../typings/antd';

import * as styles from './pushed-name-input.scss';

type PropsType = {
    large: boolean;
    name: string;
    pushedName: string;
};

export function PushedNameInput(props: PropsType): JSX.Element {
    const {large, name, pushedName} = props;

    const documentationLinks = useDocumentationLinks();
    const {getLocalizedString} = useLocale();

    const sendingNameHint = (
        <AdditionalInfo leftNode={<Locale stringKey="COMPANY_FORM__NAMES__SENT_COMPANY_NAME__LABEL" />}>
            <p className={styles.pushed_name_input__hint_text}>
                <Locale stringKey="COMPANY_FORM__NAMES__SENT_COMPANY_NAME__HINT" />
            </p>
            {documentationLinks.company.sentName ? (
                <p className={styles.pushed_name_input__hint_text}>
                    <a href={documentationLinks.company.sentName} rel="noreferrer" target="_blank">
                        <Locale stringKey="COMPANY_FORM__NAMES__SENT_COMPANY_NAME__HINT_DETAILS" />
                    </a>
                </p>
            ) : null}
        </AdditionalInfo>
    );

    return (
        <Form.Item label={large ? sendingNameHint : null}>
            <Input
                disabled
                placeholder={!name ? '...' : getLocalizedString('COMPANY_FORM__NAMES__SENT_COMPANY_NAME__PLACEHOLDER')}
                prefix="↻"
                size={large ? 'large' : 'middle'}
                value={pushedName}
            />
        </Form.Item>
    );
}
