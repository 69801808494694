export const MOKKANO_BRAND_IDS = [303_399, 293_480];

export const mokkanoStatuses = {
    statuses: [
        {
            statusCode: 'not_processed',
            availableStatus: ['in_process', 'abuse_sent', 'review_deleted'],
        },
        {
            statusCode: 'processed',
            availableStatus: ['in_process', 'decided', 'request_info'],
        },
        {
            statusCode: 'in_process',
            availableStatus: ['not_processed', 'processed', 'request_info'],
        },
        {
            statusCode: 'decided',
            availableStatus: ['processed', 'request_info', 'abuse_sent'],
        },
        {
            statusCode: 'request_info',
            availableStatus: ['in_process', 'decided'],
        },
        {
            statusCode: 'abuse_sent',
            availableStatus: ['not_processed', 'in_process', 'decided', 'review_deleted'],
        },
        {
            statusCode: 'review_deleted',
            availableStatus: ['not_processed', 'abuse_sent'],
        },
        {
            statusCode: 'review_changed',
            availableStatus: ['in_process', 'abuse_sent', 'review_deleted'],
        },
    ],
};
