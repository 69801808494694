import {LatLngTuple} from 'leaflet';
import {useEffect} from 'react';
import {useMap} from 'react-leaflet';

type PropsType = {
    bounds: [LatLngTuple, LatLngTuple];
};

export function LocalPackMapBoundsUpdater(props: PropsType): null {
    const {bounds} = props;

    const map = useMap();

    useEffect(() => {
        map.fitBounds(bounds);
    }, [bounds, map]);

    return null;
}
