import {faComments, faFaceMeh, faFaceSmile} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {NeverError} from '../../../../util/error';
import {BigRadioButtonDataType} from '../../reviews/review-list-page-content/reviews-statistic/big-radio-button/big-radio-button-type';

import {BigRadioButtonNameEnum} from './big-radio-button-const';
import * as styles from './big-radio-button.scss';

export function getButtonDataByType(type: BigRadioButtonNameEnum): BigRadioButtonDataType {
    switch (type) {
        case BigRadioButtonNameEnum.all:
            return {
                titleKey: 'QUESTIONS_N_ANSWERS__TOTAL_QUESTIONS',
                icon: <FontAwesomeIcon icon={faComments} />,
                className: styles.BigRadioButton__typeAll,
            };

        case BigRadioButtonNameEnum.withAnswer:
            return {
                titleKey: 'QUESTIONS_N_ANSWERS__WITH_AN_OFFICIAL_ANSWER',
                icon: <FontAwesomeIcon icon={faFaceSmile} />,
                className: styles.BigRadioButton__typeWithAnswer,
            };
        case BigRadioButtonNameEnum.withoutAnswer:
            return {
                titleKey: 'QUESTIONS_N_ANSWERS__NO_OFFICIAL_RESPONSE',
                icon: <FontAwesomeIcon icon={faFaceMeh} />,
                className: styles.BigRadioButton__typeWithAnswer,
            };
        default:
            throw new NeverError(type);
    }
}
