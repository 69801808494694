import {PostFormModeEnum, PostFormType} from '../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../typings/antd';
import {NeverError} from '../../../../../../../util/error';

import {EditDraftActions} from './edit-draft-actions';
import {EditExistedPostActions} from './edit-existed-post-actions';
import {NewPostActions} from './new-post-actions';
import {ViewPostActions} from './view-post-actions';

type PropsType = {
    formMode: PostFormModeEnum;
    formInstance: FormInstance<PostFormType>;
    onCreateDraft: () => void;
    handleSubmitError: (error: unknown) => void;
    isDisabled: boolean;
};

export function PostSubmitActions(props: PropsType): JSX.Element {
    const {formMode, formInstance, onCreateDraft, handleSubmitError, isDisabled} = props;

    switch (formMode) {
        case PostFormModeEnum.New: {
            return (
                <NewPostActions
                    formInstance={formInstance}
                    handleSubmitError={handleSubmitError}
                    isDisabled={isDisabled}
                    onCreateDraft={onCreateDraft}
                />
            );
        }
        case PostFormModeEnum.View: {
            return <ViewPostActions formInstance={formInstance} isDisabled={isDisabled} />;
        }
        case PostFormModeEnum.EditDraft: {
            return (
                <EditDraftActions
                    formInstance={formInstance}
                    handleSubmitError={handleSubmitError}
                    isDisabled={isDisabled}
                />
            );
        }
        case PostFormModeEnum.EditExisted: {
            return (
                <EditExistedPostActions
                    formInstance={formInstance}
                    handleSubmitError={handleSubmitError}
                    isDisabled={isDisabled}
                />
            );
        }
        default: {
            throw new NeverError(formMode);
        }
    }
}
