import {Switch} from 'antd';

import {Text} from '../../../../../../../../component/text/text';
import {classNames} from '../../../../../../../../util/css';

import * as styles from './temporary-closed-button.scss';

type PropsType = {
    onChange?: (value: boolean) => void;
    checked?: boolean;
};

export function TemporaryClosedButton(props: PropsType): JSX.Element {
    const {onChange, checked} = props;

    return (
        // it is not possible to use a Button here because the Switch component also has a Button. This causes an error in the console
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className={classNames(styles.container, {
                [styles.temporaryClosed]: checked,
            })}
            onClick={() => onChange?.(!checked)}
        >
            <Text stringKey="COMPANY_FORM__WORKING_TIME__THE_POINT_IS_TEMPORARILY_CLOSED" />
            <Switch checked={checked} />
        </div>
    );
}
