import {Text} from '../../../../component/text/text';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/localization';
import {DeletedReviewsCountType} from '../../../../service/reviews-and-answers-statistics/deleted-reviews-count/deleted-reviews-count-type';
import {CompareModeDelta} from '../compare-mode-delta/compare-mode-delta';

import * as styles from './deleted-reviews.scss';

type PropsType = {
    isLoading?: boolean;
    deletedReviews: DeletedReviewsCountType | null;
    isCompareMode?: boolean;
};

export function DeletedReviews(props: PropsType): JSX.Element {
    const {isLoading, deletedReviews, isCompareMode} = props;

    if (isLoading) {
        return <PageCard title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__DELETED_REVIEWS__TITLE" />} />;
    }

    return (
        <PageCard title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__DELETED_REVIEWS__TITLE" />}>
            {isCompareMode ? (
                <CompareModeDelta
                    compareValue={deletedReviews?.comparedValue || 0}
                    delta={deletedReviews?.delta || 0}
                    value={deletedReviews?.value || 0}
                />
            ) : (
                <Text block bolder className={styles.DeletedReviews_mainPeriodValue}>
                    {deletedReviews?.value}
                </Text>
            )}
        </PageCard>
    );
}
