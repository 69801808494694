import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize, serialize} from '../../util/api-adapter';
import {dropFetchXCache, FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {usePagination} from '../../util/pagination-hook/pagination-hook';
import {PaginationType} from '../../util/pagination-hook/pagination-hook-type';
import {idNumberSchema, IdNumberType} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, responseTemplatesSuggestionsUrl, rootApiUrl} from '../api/api-const';
import {generateResponseSchema, PaginatedResponseType, RequestOptionsType} from '../api/api-type';

const responseTemplateGroupSchema = r.object({
    id: r.number(),
    title: r.string(),
});

const responseTemplateGroupListSchema = r.array(responseTemplateGroupSchema);

export type ResponseTemplateGroupType = r.infer<typeof responseTemplateGroupSchema>;

const responseTemplateDataSchema = r.object({
    folder: responseTemplateGroupSchema.nullable(),
    id: r.number(),
    isPrivate: r.boolean(),
    message: r.string(),
    title: r.string(),
    user: r.object({
        id: r.number(),
        email: r.string(),
    }),
});

const responseTemplatesListDataSchema = generateResponseSchema(responseTemplateDataSchema);

export type ResponseTemplateDataType = r.infer<typeof responseTemplateDataSchema>;

const autoReplyBalanceDataSchema = r.object({
    balance: r.number(),
    email: r.string(),
    userId: r.number(),
});

export type AutoReplyBalanceDataType = r.infer<typeof autoReplyBalanceDataSchema>;

const timeBucketSchema = r.object({
    start: r.string(),
    end: r.string(),
});

type TimeBucketType = r.infer<typeof timeBucketSchema>;

const autoReplyToReviewDataSchema = r.object({
    catalogs: r.array(idNumberSchema),
    companies: r.array(idNumberSchema).optional(),
    description: r.string(),
    enabled: r.boolean(),
    enabledForDates: r.null(),
    enabledFrom: r.string(),
    enabledTo: r.string(),
    id: r.number(),
    isDeleted: r.boolean(),
    isTest: r.boolean(),
    mode: r.string(),
    name: r.string(),
    responseTemplates: r.array(idNumberSchema),
    reviewsRatings: r.array(r.number()),
    reviewsTimeBucket: timeBucketSchema.nullable(),
    reviewsWeekdaysIndexes: r.array(r.number()),
    reviewsWithBody: r.boolean().nullable(),
    templateSelectionStrategy: r.string(),
    templateTimeZone: r.string(),
});

export type AutoReplyToReviewDataType = r.infer<typeof autoReplyToReviewDataSchema>;

export type AutoReplyDataErrorType = {
    selectTemplates: boolean;
    datePeriod: boolean;
    dateStart: boolean;
};

export enum AutoReplyErrorEnum {
    feedbackSource = 'feedbackSource',
    selectTemplates = 'selectTemplates',
    datePeriod = 'datePeriod',
    dateStart = 'dateStart',
}

type AutoReplyToReviewBodyType = {
    catalogs: Array<IdNumberType>;
    description: string;
    enabled: boolean;
    enabledForDates: Array<string> | null;
    enabledFrom: string | null;
    enabledTo: string | null;
    id: number;
    isDeleted: boolean;
    isTest: boolean;
    mode: string;
    name: string;
    responseTemplates: Array<number>;
    reviewsRatings: Array<number>;
    reviewsTimeBucket: TimeBucketType | null;
    reviewsWeekdaysIndexes: Array<number> | null;
    reviewsWithBody: boolean | null;
    uuid: string;
    templateSelectionStrategy: string;
    templateTimeZone: string;
};

export type ChangeTemplateGroupBodyType = {
    ids: Array<string>;
    destination_folder_id?: number;
    without_folder?: boolean;
};

type ChangeTemplateBodyType = {
    ids: Array<string>;
    is_private: boolean;
};

type CreateGroupTemplatesType = {
    title: string;
};

type ChangeConditionAutoReplayBodyType = {
    enabled: boolean;
};

type ChangeEnabledAutoReplayBodyType = {
    ids: Array<string>;
    enabled: boolean;
};

type DeleteWithIdsType = {
    ids: Array<string> | number;
};

export const defaultResponseTemplateData: ResponseTemplateDataType = {
    folder: null,
    id: -1,
    isPrivate: false,
    message: '',
    title: '',
    user: {id: -1, email: ''},
};

export const defaultGroupTemplateData: ResponseTemplateGroupType = {
    id: -1,
    title: '',
};

export const defaultAutoReplyToReviewData: AutoReplyToReviewDataType = {
    catalogs: [],
    description: '',
    enabled: false,
    enabledForDates: null,
    enabledFrom: '',
    enabledTo: '',
    id: 0,
    isDeleted: false,
    isTest: false,
    mode: '',
    name: '',
    responseTemplates: [],
    reviewsRatings: [],
    reviewsTimeBucket: null,
    reviewsWeekdaysIndexes: [],
    reviewsWithBody: false,
    templateSelectionStrategy: '',
    templateTimeZone: '',
};

export const defaultAutoReplyInfoErrors: AutoReplyDataErrorType = {
    selectTemplates: false,
    datePeriod: false,
    dateStart: false,
};

const responseTemplateGroupListUrl = '/v1/response_templates/folders/';

function fetchResponseTemplateGroupList(): Promise<Array<ResponseTemplateGroupType>> {
    return fetchAndDeserialize<Array<ResponseTemplateGroupType>>(
        responseTemplateGroupListUrl,
        responseTemplateGroupListSchema
    );
}

export function useResponseTemplateGroupList(): UseQueryResult<Array<ResponseTemplateGroupType>> {
    return useQuery([responseTemplateGroupListUrl], () => fetchResponseTemplateGroupList(), {
        refetchOnWindowFocus: false,
    });
}

function getResponseTemplatesUrl(
    option: RequestOptionsType,
    folderIdList: Array<string>,
    templateType: boolean | null
) {
    return `/v1/response_templates/?${objectToUrlParameters({
        ...option,
        is_private: templateType,
    })}${folderIdList.map((id) => '&folder_id=' + id).join('')}`;
}

function fetchResponseTemplates(
    option: RequestOptionsType,
    folderIdList: Array<string>,
    templateType: boolean | null
): Promise<PaginatedResponseType<ResponseTemplateDataType>> {
    return fetchAndDeserialize<PaginatedResponseType<ResponseTemplateDataType>>(
        getResponseTemplatesUrl(option, folderIdList, templateType),
        responseTemplatesListDataSchema
    );
}

export function useResponseTemplates(
    folderIdList: Array<string>,
    templateType: boolean | null
): PaginationType & UseQueryResult<PaginatedResponseType<ResponseTemplateDataType>> {
    const pagination = usePagination({dependencies: null, initialPageSize: 10});
    const {page, pageSize, onDataLoaded} = pagination;

    const query = useQuery(
        [getResponseTemplatesUrl({count: pageSize, page}, folderIdList, templateType)],
        () => fetchResponseTemplates({count: pageSize, page}, folderIdList, templateType),
        {
            onSuccess: (data) => onDataLoaded(data),
            refetchOnWindowFocus: false,
        }
    );

    return {...query, pagination};
}

function getResponseTemplateUrl(id: string) {
    return `/v1/response_templates/${id}/`;
}

function fetchResponseTemplateById(templateId: string): Promise<ResponseTemplateDataType> {
    return fetchAndDeserialize<ResponseTemplateDataType>(
        getResponseTemplateUrl(templateId),
        responseTemplateDataSchema
    );
}

export function useResponseTemplateById(templateId: string): UseQueryResult<ResponseTemplateDataType> {
    return useQuery([getResponseTemplateUrl(templateId)], () => fetchResponseTemplateById(templateId), {
        refetchOnWindowFocus: false,
    });
}

const autoRepliesBalanceUrl = '/v1/response_templates/suggestions/balance/';

function fetchAutoRepliesBalance(): Promise<Array<AutoReplyBalanceDataType>> {
    return fetchAndDeserialize<Array<AutoReplyBalanceDataType>>(autoRepliesBalanceUrl, autoReplyBalanceDataSchema);
}

export function useAutoRepliesBalance(): UseQueryResult<Array<AutoReplyBalanceDataType>> {
    return useQuery([autoRepliesBalanceUrl], () => fetchAutoRepliesBalance(), {
        refetchOnWindowFocus: false,
    });
}

function getAutoRepliesToReviewsUrl(option: RequestOptionsType) {
    return `/v1/response_templates/suggestions/?${objectToUrlParameters({
        is_deleted: false,
        ...option,
    })}`;
}

function fetchAutoRepliesToReviews(
    option: RequestOptionsType
): Promise<PaginatedResponseType<AutoReplyToReviewDataType>> {
    return fetchAndDeserialize<PaginatedResponseType<AutoReplyToReviewDataType>>(
        getAutoRepliesToReviewsUrl(option),
        autoReplyToReviewDataSchema
    );
}

export function useAutoRepliesToReviews(
    option: RequestOptionsType
): UseQueryResult<PaginatedResponseType<AutoReplyToReviewDataType>> {
    const {count, page, q} = option;

    return useQuery([getAutoRepliesToReviewsUrl({count, page, q})], () => fetchAutoRepliesToReviews({count, page, q}), {
        refetchOnWindowFocus: false,
    });
}

function getAllResponseTemplateListUrl(reviewId?: number) {
    return `/v1/response_templates/?${objectToUrlParameters({
        all: true,
        review_id: reviewId,
    })}`;
}

function fetchAllResponseTemplateList(reviewId?: number): Promise<Array<ResponseTemplateDataType>> {
    return fetchAndDeserialize<Array<ResponseTemplateDataType>>(
        getAllResponseTemplateListUrl(reviewId),
        responseTemplatesListDataSchema,
        {fetchOptions: {skipCache: true}}
    );
}

export function useAllResponseTemplateList(reviewId?: number): UseQueryResult<Array<ResponseTemplateDataType>> {
    return useQuery([getAllResponseTemplateListUrl(reviewId)], () => fetchAllResponseTemplateList(reviewId), {
        refetchOnWindowFocus: false,
    });
}

function getAutoReplyToReviewByIdUrl(autoReplyToReviewId: string | number) {
    return `${responseTemplatesSuggestionsUrl}${autoReplyToReviewId}/`;
}

function fetchAutoReplyToReviewById(autoReplyToReviewId: string | number): Promise<AutoReplyToReviewDataType> {
    return fetchAndDeserialize<AutoReplyToReviewDataType>(
        getAutoReplyToReviewByIdUrl(autoReplyToReviewId),
        autoReplyToReviewDataSchema
    );
}

export function useAutoReplyToReviewById(
    autoReplyToReviewId: string | number
): UseQueryResult<AutoReplyToReviewDataType> {
    return useQuery(
        [getAutoReplyToReviewByIdUrl(autoReplyToReviewId)],
        () => fetchAutoReplyToReviewById(autoReplyToReviewId),
        {
            refetchOnWindowFocus: false,
        }
    );
}

export function fetchAutoReplayChangeCondition(
    id: string,
    data: ChangeConditionAutoReplayBodyType
): Promise<AutoReplyToReviewDataType> {
    return fetchX<AutoReplyToReviewDataType>(`${rootApiUrl}${responseTemplatesSuggestionsUrl}${id}/`, {
        method: FetchMethodEnum.patch,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchAutoReplayUpdate(data: AutoReplyToReviewBodyType, id: string): Promise<AutoReplyToReviewDataType> {
    return fetchX<AutoReplyToReviewDataType>(`${rootApiUrl}${responseTemplatesSuggestionsUrl}${id}/`, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchAutoReplayCreate(data: AutoReplyToReviewBodyType): Promise<AutoReplyToReviewDataType> {
    return fetchX<AutoReplyToReviewDataType>(`${rootApiUrl}${responseTemplatesSuggestionsUrl}`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchAutoReplayDelete(id: string): Promise<AutoReplyToReviewDataType> {
    return fetchX<AutoReplyToReviewDataType>(`${rootApiUrl}${responseTemplatesSuggestionsUrl}${id}/`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchAutoReplaysDelete(data: DeleteWithIdsType): Promise<AutoReplyToReviewDataType> {
    const parameters = objectToUrlParameters(data);

    return fetchX<AutoReplyToReviewDataType>(`${rootApiUrl}${responseTemplatesSuggestionsUrl}?${parameters}`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchAutoReplayChangeEnabledType(
    data: ChangeEnabledAutoReplayBodyType
): Promise<AutoReplyToReviewDataType> {
    return fetchX<AutoReplyToReviewDataType>(`${rootApiUrl}${responseTemplatesSuggestionsUrl}change_enabled/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(data),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateCreate(data: ResponseTemplateDataType): Promise<ResponseTemplateDataType> {
    return fetchX<ResponseTemplateDataType>(`${rootApiUrl}/v1/response_templates/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateUpdate(data: ResponseTemplateDataType, id: number): Promise<ResponseTemplateDataType> {
    return fetchX<ResponseTemplateDataType>(`${rootApiUrl}/v1/response_templates/${id}/`, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateDelete(data: DeleteWithIdsType): Promise<ResponseTemplateDataType> {
    const parameters = objectToUrlParameters(data);

    return fetchX<ResponseTemplateDataType>(`${rootApiUrl}/v1/response_templates/?${parameters}`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateGroupCreate(data: CreateGroupTemplatesType): Promise<ResponseTemplateGroupType> {
    return fetchX<ResponseTemplateGroupType>(rootApiUrl + '/v1/response_templates/folders/', {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateGroupUpdate(
    data: ResponseTemplateGroupType,
    id: number
): Promise<ResponseTemplateGroupType> {
    return fetchX<ResponseTemplateGroupType>(`${rootApiUrl}/v1/response_templates/folders/${id}/`, {
        method: FetchMethodEnum.put,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateGroupDelete(data: DeleteWithIdsType): Promise<void> {
    dropFetchXCache([`${rootApiUrl}/v1/response_templates/folders/ - ${JSON.stringify('[empty]')}`]);
    const parameters = objectToUrlParameters(data);

    return fetchNoContent(`${rootApiUrl}/v1/response_templates/folders/?${parameters}`, {
        method: FetchMethodEnum.delete,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateChangeType(data: ChangeTemplateBodyType): Promise<ResponseTemplateDataType> {
    return fetchX<ResponseTemplateDataType>(`${rootApiUrl}/v1/response_templates/change_templates_type/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function fetchTemplateChangeGroup(data: ChangeTemplateGroupBodyType): Promise<ResponseTemplateDataType> {
    return fetchX<ResponseTemplateDataType>(`${rootApiUrl}/v1/response_templates/change_templates_folder/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify(serialize(data)),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}
