import {Button} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {LicenseCardSmallType} from '../../../../service/local-pack/local-pack-type';
import {getRandomString} from '../../../../util/string';
import {LicenseCardSmall} from '../license-card-small/license-card-small';

import * as styles from './license-card-information.scss';

type PropsType = {
    config: Array<LicenseCardSmallType>;
    showAction: boolean;
    onRequestLicense: () => void;
};

export function LicenseCardInformation(props: PropsType): JSX.Element {
    const {config, showAction, onRequestLicense} = props;

    return (
        <div className={styles.LicenseCardInformation}>
            {config.map((item) => (
                <LicenseCardSmall
                    description={item.description}
                    icon={item.icon}
                    key={getRandomString()}
                    title={item.title}
                />
            ))}
            {showAction && (
                <Button className={styles.LicenseCardInformation_action} onClick={onRequestLicense} type="primary">
                    <Locale stringKey="LICENSE_CARD__BUY_LICENSE" />
                </Button>
            )}
        </div>
    );
}
