import {Text} from '../../../../component/text/text';
import {Trend} from '../../../../layout/trend/trend';
import {classNames} from '../../../../util/css';

import * as styles from './compare-mode-delta.scss';

type PropsType = {
    value: number | string | JSX.Element;
    compareValue: number | string | JSX.Element;
    delta: number;
    trendReverse?: boolean;
    className?: string;
    unit?: string;
};

export function CompareModeDelta(props: PropsType): JSX.Element {
    const {value, compareValue, delta, trendReverse, className, unit} = props;

    return (
        <div className={classNames(styles.CompareModeDelta, className)}>
            <div className={styles.CompareModeDelta_item}>
                <Text block large lighter>
                    A
                </Text>

                <Text block bolder large>
                    {value}
                </Text>
            </div>

            <Trend delta={delta} reverse={trendReverse} unit={unit} />

            <div className={styles.CompareModeDelta_item}>
                <Text block bolder large>
                    {compareValue}
                </Text>

                <Text block large lighter>
                    B
                </Text>
            </div>
        </div>
    );
}
