import {faFileUpload} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Upload, UploadFile} from 'antd';
import {ItemRender} from 'antd/es/upload/interface';
import {uniq} from 'lodash';
import {RcFile, UploadRequestOption} from 'rc-upload/lib/interface';
import {ReactElement, useCallback, useEffect, useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {
    feedFileErrorSchema,
    FeedFileErrorType,
    feedFileUrl,
    useUploadFeedFileMutation,
} from '../../../../../../../service/companies-import/companies-import-upload';
import {deserializeApiError} from '../../../../../../../util/api-adapter';
import {classNames} from '../../../../../../../util/css';
import {checkIsFile} from '../../../../../help/technical-support/components/technical-support-create-form/technical-support-create-form-helper';
import {MimeTypeEnum} from '../feed-form-const';

import {FEED_UPLOAD_TYPES} from './feed-upload-const';
import {UploadItem} from './upload-item/upload-item';
import * as styles from './feed-upload.scss';

type PropsType = {
    sourceFileId: string | null;
    isCancelled: boolean;
    handleUploadChange: (fileId: string | null, file?: RcFile | null) => void;
};

export function FeedUpload(props: PropsType): JSX.Element {
    const {sourceFileId, isCancelled, handleUploadChange} = props;

    const [fileList, setFileList] = useState<Array<UploadFile>>([]);

    const {mutateAsync, isLoading} = useUploadFeedFileMutation();
    const {feedTemplateLinks} = useDomainConfig();

    const fileExtensions = feedTemplateLinks.map((link) => link.link.split('.')[1]).filter(Boolean);

    useEffect(() => {
        if (isCancelled) {
            setFileList([]);
        }
    }, [isCancelled]);

    async function handleFileUpload(options: UploadRequestOption<unknown>) {
        const file = options.file;

        if (!checkIsFile(file)) {
            return;
        }

        try {
            await mutateAsync(file, {
                onSuccess: (result) => {
                    options.onSuccess?.(result);

                    handleUploadChange(result.tempId, file);
                },
            });
        } catch (error: unknown) {
            const apiError = deserializeApiError<FeedFileErrorType>(feedFileUrl, feedFileErrorSchema, error);

            if (error instanceof Error) {
                options.onError?.(new Error(apiError?.feedFileS3?.join(', ') ?? ''));
            }

            handleUploadChange(null);
        }
    }

    const itemRenderer: ItemRender = useCallback(
        (originNode: ReactElement<unknown>, file: UploadFile<unknown>, _fileList, {remove}) => {
            if (file.status === 'done') {
                return <UploadItem file={file} onRemove={remove} />;
            }

            return originNode;
        },
        []
    );

    return (
        <div
            className={classNames(styles.FeedUpload_fileList, {
                [styles.FeedUpload_fileList__hasFile]: Boolean(sourceFileId),
            })}
        >
            <Upload.Dragger
                accept={FEED_UPLOAD_TYPES.extensions.map((extension) => `.${extension}`).join(', ')}
                beforeUpload={(file) =>
                    FEED_UPLOAD_TYPES.mimeTypes.includes(file.type as MimeTypeEnum) || Upload.LIST_IGNORE
                }
                className={classNames(styles.FeedUpload, {
                    [styles.FeedUpload__hidden]: Boolean(sourceFileId) || fileList.length > 0,
                })}
                customRequest={handleFileUpload}
                fileList={fileList}
                itemRender={itemRenderer}
                listType="picture"
                maxCount={1}
                onChange={(event) => setFileList(event.fileList)}
                onRemove={(file) => (file.error ? true : handleUploadChange(null))}
            >
                {fileList.length === 0 && !isLoading && (
                    <>
                        <FontAwesomeIcon className={styles.FeedUpload_icon} icon={faFileUpload} />

                        <div className={styles.FeedUpload_title}>
                            <Locale stringKey="FEED__FORM__FILE__TITLE" />
                        </div>

                        <Text
                            secondary
                            small
                            stringKey="FEED__FORM__FILE__DESCRIPTION"
                            valueMap={{
                                extensions: uniq(fileExtensions)
                                    .map((extension) => extension.toUpperCase())
                                    .join(', '),
                            }}
                        />
                    </>
                )}
            </Upload.Dragger>
        </div>
    );
}
