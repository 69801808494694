import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';
import {ReactNode, useCallback} from 'react';

import {AntdErrorMessage} from '../../../../../../../component/antd-error-message/antd-error-message';
import {BreakTimeList} from '../../../../../../../component/form/workdays-form-item/break-time-list/break-time-list';
import * as workDayStyles from '../../../../../../../component/form/workdays-form-item/work-day/work-day.scss';
import {WorkTime} from '../../../../../../../component/form/workdays-form-item/work-time/work-time';
import {
    WorkDayTimeRangeType,
    WorkTimeDateType,
} from '../../../../../../../component/form/workdays-form-item/workdays-form-item-type';
import {Locale} from '../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {replaceInList} from '../../../../../../../util/array';
import {classNames} from '../../../../../../../util/css';
import {TemporaryWorkDayDataType} from '../temporary-workdays-form-item-type';

import * as styles from './temporary-work-day.scss';

type PropsType = {
    setTemporaryWorkDayDataList: (temporaryWorkDayDataList: Array<TemporaryWorkDayDataType>) => void;
    temporaryWorkDayDataList: Array<TemporaryWorkDayDataType>;
    temporaryWorkDayData: TemporaryWorkDayDataType;
    className?: string;
    errorMessage?: ReactNode;
};

export function TemporaryWorkDay(props: PropsType): JSX.Element {
    const {className, temporaryWorkDayData, setTemporaryWorkDayDataList, temporaryWorkDayDataList, errorMessage} =
        props;
    const {breakTimeList, dateFrom, dateTo, isAroundTheClock, isDayOff, timeFrom, timeTo} = temporaryWorkDayData;

    const fullClassName = classNames(styles.temporary_work_day, className);
    const {getLocalizedString} = useLocale();
    const today = dayjs().hour(0).minute(0).second(0);
    const format = 'YYYY-MM-DD';

    const handleRemoveTemporaryWorkDayData = useCallback(() => {
        const newTemporaryWorkDayDataList = [...temporaryWorkDayDataList];

        newTemporaryWorkDayDataList.splice(temporaryWorkDayDataList.indexOf(temporaryWorkDayData), 1);

        setTemporaryWorkDayDataList(newTemporaryWorkDayDataList);
    }, [temporaryWorkDayDataList, setTemporaryWorkDayDataList, temporaryWorkDayData]);

    const updateTime = useCallback(
        (newTime: WorkTimeDateType) => {
            setTemporaryWorkDayDataList(
                replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {...temporaryWorkDayData, ...newTime})
            );
        },
        [temporaryWorkDayData, temporaryWorkDayDataList, setTemporaryWorkDayDataList]
    );

    const setBreakTimeList = useCallback(
        (newBreakTimeList: Array<WorkDayTimeRangeType>) => {
            setTemporaryWorkDayDataList(
                replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                    ...temporaryWorkDayData,
                    breakTimeList: newBreakTimeList,
                })
            );
        },
        [temporaryWorkDayData, temporaryWorkDayDataList, setTemporaryWorkDayDataList]
    );

    const handleChangePeriod = useCallback(
        (values: NoUndefinedRangeValueType<Dayjs> | null) => {
            if (!values) {
                setTemporaryWorkDayDataList(
                    replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                        ...temporaryWorkDayData,
                        dateFrom: null,
                        dateTo: null,
                    })
                );
                return;
            }

            const [dayjsFrom, dayjsTo] = values;

            setTemporaryWorkDayDataList(
                replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                    ...temporaryWorkDayData,
                    dateFrom: dayjsFrom
                        ? {year: dayjsFrom.year(), month: dayjsFrom.month(), date: dayjsFrom.date()}
                        : null,
                    dateTo: dayjsTo ? {year: dayjsTo.year(), month: dayjsTo.month(), date: dayjsTo.date()} : null,
                })
            );
        },
        [temporaryWorkDayData, temporaryWorkDayDataList, setTemporaryWorkDayDataList]
    );

    return (
        <div className={styles.container}>
            <div className={fullClassName}>
                <Button className={styles.temporary_work_day__delete_button} onClick={handleRemoveTemporaryWorkDayData}>
                    <FontAwesomeIcon className={styles.temporary_work_day__delete_button__icon} icon={faTrash} />
                </Button>

                <div className={styles.temporary_work_day__period}>
                    <p className={styles.temporary_work_day__period__header}>
                        <Locale stringKey="ADDITIONAL_INFO__PERIOD" />
                    </p>

                    <DatePicker.RangePicker
                        className={styles.temporary_work_day__period__range_picker}
                        disabledDate={(date: Dayjs) => date.isBefore(today)}
                        format={format}
                        onChange={handleChangePeriod}
                        placeholder={[getLocalizedString('TEXT__DATE_FROM'), getLocalizedString('TEXT__DATE_TO')]}
                        value={[
                            dateFrom && dayjs().year(dateFrom.year).month(dateFrom.month).date(dateFrom.date),
                            dateTo && dayjs().year(dateTo.year).month(dateTo.month).date(dateTo.date),
                        ]}
                    />

                    <Checkbox
                        checked={isDayOff}
                        className={styles.temporary_work_day__period__day_off_check_box}
                        onChange={() => {
                            setTemporaryWorkDayDataList(
                                replaceInList(temporaryWorkDayDataList, temporaryWorkDayData, {
                                    ...temporaryWorkDayData,
                                    isDayOff: !isDayOff,
                                })
                            );
                        }}
                    >
                        <Locale stringKey="ADDITIONAL_INFO__DAY_OFF" />
                    </Checkbox>
                </div>

                {!isDayOff && (
                    <>
                        <WorkTime
                            allowClear
                            className={workDayStyles.WorkDay_time}
                            isAroundTheClock={isAroundTheClock}
                            order={false}
                            timeFrom={timeFrom}
                            timeTo={timeTo}
                            updateTime={updateTime}
                        />

                        <BreakTimeList
                            breakTimeList={breakTimeList}
                            className={workDayStyles.WorkDay_breakTime}
                            setBreakTimeList={setBreakTimeList}
                        />
                    </>
                )}
            </div>

            {errorMessage && <AntdErrorMessage>{errorMessage}</AntdErrorMessage>}
        </div>
    );
}
