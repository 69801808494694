import {Tooltip} from 'antd';

import {ChartTooltipRow} from '../../../../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../../../../component/chart-tooltip/chart-tooltip-title';
import * as cssVariableStyles from '../../../../../css/var-export.scss';
import {FormattedNumber} from '../../../../../provider/locale/formatted-number';
import {Locale} from '../../../../../provider/locale/locale';
import {
    SentimentDataType,
    SentimentEnum,
} from '../../../../../service/reivew-analysis/review-sentiment/review-sentiment-type';
import {getPercentagesDistribution} from '../../../../../util/number';

import {SENTIMENT_COLOR_MAP, SENTIMENT_TRANSLATION_MAP} from './sentiment-bar-helper';
import * as styles from './sentiment-bar.scss';

type PropsType = {
    sentimentData: SentimentDataType;
    showTooltip?: boolean;
};

export function SentimentBar(props: PropsType): JSX.Element {
    const {sentimentData, showTooltip = true} = props;

    const percentagesDistribution = getPercentagesDistribution(sentimentData);

    return (
        <Tooltip
            color={cssVariableStyles.gray1}
            title={
                <div className={styles.SentimentBar_tooltipTitleCont}>
                    <ChartTooltipTitle title={<Locale stringKey="REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT" />} />
                    {Object.values(SentimentEnum)
                        .filter((sentiment) => sentimentData[sentiment])
                        .map((sentiment) => {
                            return (
                                <ChartTooltipRow
                                    color={SENTIMENT_COLOR_MAP[sentiment]}
                                    key={sentiment}
                                    label={<Locale stringKey={SENTIMENT_TRANSLATION_MAP[sentiment]} />}
                                    value={
                                        <>
                                            <FormattedNumber value={sentimentData[sentiment]} />
                                            &nbsp; ({percentagesDistribution[sentiment]}%)
                                        </>
                                    }
                                />
                            );
                        })}
                </div>
            }
            trigger={showTooltip ? ['hover'] : []}
        >
            <div className={styles.SentimentBar_moodBar}>
                <div className={styles.SentimentBar_moodBar__itemNegative} style={{flexGrow: sentimentData.negative}} />
                <div
                    className={styles.SentimentBar_moodBar__itemUnspecified}
                    style={{
                        flexGrow: sentimentData.unspecified,
                    }}
                />
                <div className={styles.SentimentBar_moodBar__itemNeutral} style={{flexGrow: sentimentData.neutral}} />
                <div className={styles.SentimentBar_moodBar__itemPositive} style={{flexGrow: sentimentData.positive}} />
            </div>
        </Tooltip>
    );
}
