import {TooltipOptions} from 'chart.js';
import {Context} from 'chartjs-plugin-datalabels';

import {ChartTooltip} from '../../component/chart-tooltip/chart-tooltip';
import {ChartTooltipRow} from '../../component/chart-tooltip/chart-tooltip-row';
import {ChartTooltipTitle} from '../../component/chart-tooltip/chart-tooltip-title';
import {DefaultChartTooltip, DefaultTooltipStaticPropsType} from '../../component/chart-tooltip/default-chart-tooltip';
import {
    DefaultDoughnutChartTooltip,
    DefaultDoughnutChartTooltipPropsType,
} from '../../component/chart-tooltip/default-doughnut-chart-tooltip';
import * as cssVariableStyles from '../../css/var-export.scss';

import {customReactChartTooltip} from './utils/chart-helper';

export const gridColor = cssVariableStyles.gray3;
export const labelColor = cssVariableStyles.gray8;

const tooltipTitleColor = cssVariableStyles.gray9;

const tooltipBodyColor = cssVariableStyles.gray9;

const tooltipFooterColor = cssVariableStyles.gray7;

export const defaultYAxisScale = {
    grid: {
        color: gridColor,
        drawBorder: false,
        drawTicks: false,
    },
    ticks: {
        color: labelColor,
        padding: 10,
    },
};

export const defaultTooltipOptions = {
    enabled: true,

    backgroundColor: cssVariableStyles.gray1,
    titleSpacing: 5,
    titleMarginBottom: 15,
    titleColor: tooltipTitleColor,
    titleFont: {
        lineHeight: '22px',
        size: 14,
    },
    bodyColor: tooltipBodyColor,
    bodyFont: {
        lineHeight: '22px',
        size: 14,
    },
    bodySpacing: 8,
    footerColor: tooltipFooterColor,
    footerFont: {
        lineHeight: '22px',
        size: 14,
        weight: 'normal',
    },
    cornerRadius: 2,
    mode: 'index' as const,
    position: 'average' as const,
    borderColor: cssVariableStyles.gray4,
    borderWidth: 1,
    padding: {left: 16, right: 16, top: 12, bottom: 12},
    boxHeight: 16,
    boxWidth: 16,
    multiKeyBackground: cssVariableStyles.gray1,
};

export function getDefaultReactTooltipOptions(options: DefaultTooltipStaticPropsType): Partial<TooltipOptions> {
    return {
        enabled: false,
        yAlign: 'top',
        xAlign: 'center',
        position: 'nearest',
        external: (context: Context) =>
            customReactChartTooltip({
                context,
                componentRenderer: (index) => {
                    return (
                        <DefaultChartTooltip
                            currentIndex={index}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...options}
                        />
                    );
                },
            }),
    };
}

export function getDefaultReactTooltipV2(): Partial<TooltipOptions> {
    return {
        enabled: false,
        yAlign: 'top',
        xAlign: 'center',
        position: 'nearest',
        external: (context: Context) =>
            customReactChartTooltip({
                context,
                componentRenderer: () => {
                    return (
                        <ChartTooltip>
                            <ChartTooltipTitle title={String(context?.tooltip.dataPoints?.[0]?.label || '')} />

                            {context?.tooltip.dataPoints.map((dataPoint) => {
                                return (
                                    <ChartTooltipRow
                                        color={String(dataPoint.dataset.backgroundColor || '')}
                                        key={dataPoint.datasetIndex}
                                        label={String(dataPoint?.dataset?.label || '')}
                                        value={dataPoint.formattedValue}
                                    />
                                );
                            })}
                        </ChartTooltip>
                    );
                },
            }),
    };
}

export function getDefaultReactDoughnutTooltipOptions(
    options: Omit<DefaultDoughnutChartTooltipPropsType, 'currentIndex'>
): Partial<TooltipOptions> {
    return {
        enabled: false,
        yAlign: 'top',
        xAlign: 'center',
        position: 'nearest',
        external: (context: Context) =>
            customReactChartTooltip({
                context,
                componentRenderer: (index) => {
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    return <DefaultDoughnutChartTooltip currentIndex={index} {...options} />;
                },
            }),
    };
}
