import {Button, Tabs} from 'antd';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {Text} from '../../../../component/text/text';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useFakesStatuses} from '../../../../service/fakes/fakes-statuses';

import {SettingsAndStatisticsTabsEnum} from './fakes-settings-and-statistics-const';
import {FakesSettingsTab} from './settings-tab/fakes-settings-tab';
import {FakesStatisticsTab} from './statistics-tab/fakes-statistics-tab';
import * as styles from './fakes-settings-and-statistics.scss';

export function FakesSettingsAndStatistics(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {data} = useFakesStatuses();

    const tabs = [
        {
            key: SettingsAndStatisticsTabsEnum.Settings,
            label: <Locale stringKey="FAKES__STATISTIC__TABS__SETTINGS_LABEL" />,
            children: <FakesSettingsTab isTrial={data?.isTrial} />,
        },
        {
            key: SettingsAndStatisticsTabsEnum.Statistics,
            label: <Locale stringKey="FAKES__STATISTIC__TABS__STATISTIC_LABEL" />,
            disabled: data?.isTrial,
            children: <FakesStatisticsTab />,
        },
    ];

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('FAKES__SETTINGS__TITLE')} />

                <BreadCrumbs
                    list={[
                        {path: appRoute.duplicatesAndFakes.path, titleLangKey: 'FAKES__TITLE'},
                        {
                            path: appRoute.fakesSettings.path,
                            titleLangKey: 'FAKES__SETTINGS__TITLE',
                        },
                    ]}
                />

                <PageHeader className={styles.SettingsAndStatistics_header}>
                    <Locale stringKey="FAKES__SETTINGS__TITLE" />
                    <NavigationLink to={appRoute.duplicatesAndFakes.path}>
                        <Button>
                            <Text stringKey="BUTTON__BACK" />
                        </Button>
                    </NavigationLink>
                </PageHeader>
            </PageCard>

            <PageCard>
                <Tabs
                    className={styles.SettingsAndStatistics_tabs}
                    defaultActiveKey={SettingsAndStatisticsTabsEnum.Settings}
                    items={tabs}
                />
            </PageCard>
        </Page>
    );
}
