import {useCallback, useEffect} from 'react';

import {ResponseOkType} from '../../util/fetch';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {connect2fa as fetchConnect2fa, disconnect2fa as fetchDisconnect2fa, fetch2faEnableCode} from './user-2fa-api';
import {User2faCodeErrorType, User2faCodeType, User2faEnableType} from './user-2fa-type';

type Connect2fAdditionalType = {
    connect2fa: (code: User2faCodeType) => Promise<void>;
};

type Disonnect2fAdditionalType = {
    disconnect2fa: (code: User2faCodeType) => Promise<void>;
};

export function use2faEnableCode(authId?: string): UseHookType<User2faEnableType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<User2faEnableType>();

    useEffect(() => {
        setIsInProgress(true);

        fetch2faEnableCode(authId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [authId, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}

type UseConnect2faHookType = UseHookType<ResponseOkType, User2faCodeErrorType> & Connect2fAdditionalType;

export function useConnect2fa({unauthorized}: {unauthorized?: boolean} = {}): UseConnect2faHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} = useApiHooks<
        ResponseOkType,
        User2faCodeErrorType
    >();

    const connect2fa = useCallback(
        (code: User2faCodeType) => {
            setIsInProgress(true);

            return fetchConnect2fa(code, unauthorized)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch((error: unknown) => {
                    setProcessError(error as User2faCodeErrorType);
                    throw error;
                });
        },
        [setIsInProgress, unauthorized, setResult, setProcessError]
    );

    return {isInProgress, processError, result, reset, connect2fa};
}

export function useDisonnect2fa(): UseHookType<ResponseOkType, User2faCodeErrorType> & Disonnect2fAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} = useApiHooks<
        ResponseOkType,
        User2faCodeErrorType
    >();

    const disconnect2fa = useCallback(
        (code: User2faCodeType) => {
            setIsInProgress(true);

            return fetchDisconnect2fa(code)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch((error: unknown) => {
                    setProcessError(error as User2faCodeErrorType);
                    throw error;
                });
        },
        [setIsInProgress, setResult, setProcessError]
    );

    return {isInProgress, processError, result, reset, disconnect2fa};
}
