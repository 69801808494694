import {faReply} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {useLocale} from '../../../../provider/locale/locale-hook';
import {ReviewDataType} from '../../../../service/reviews/reviews-type';
import {classNames} from '../../../../util/css';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';
import {isReviewReplyAllowed} from '../reviews-helper';

import * as styles from './reply-alias-button.scss';

type PropsType = {
    review: ReviewDataType;
    wide?: boolean;
};

export function ReplyAliasButton(props: PropsType): JSX.Element | null {
    const {review, wide} = props;

    const {getLocalizedString} = useLocale();
    const {onReplyAliasPress} = useReviewActivityHook();

    if (!isReviewReplyAllowed(review)) {
        return null;
    }

    return (
        <Button
            className={classNames({
                [styles.ReplyAliasButton__wide]: wide,
            })}
            icon={<FontAwesomeIcon className={styles.ReplyAliasButton_icon} icon={faReply} />}
            onClick={onReplyAliasPress}
        >
            {getLocalizedString('REVIEWS__REPLY')}
        </Button>
    );
}
