import {useCallback, useEffect} from 'react';

import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {fetchUsedesk, updateUsedesk as fetchUpdateUsedesk} from './usedesk-api';
import {UsedeskType} from './usedesk-type';

type UsedeskAdittionalType = {
    updateUsedesk: (usedesk: UsedeskType) => Promise<void>;
};

export function useUsedesk(): UseHookType<UsedeskType> & UsedeskAdittionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<UsedeskType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchUsedesk()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    const updateUsedesk = useCallback(
        (usedesk: UsedeskType) => {
            setIsInProgress(true);

            return fetchUpdateUsedesk(usedesk)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setResult, setIsInProgress, setProcessError]
    );

    return {isInProgress, processError, result, reset, updateUsedesk};
}
