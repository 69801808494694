import {Alert, Button, Space} from 'antd';
import {useEffect, useState} from 'react';

import {SelectWithCheckboxes} from '../../../../../../../component/select-with-checkboxes/select-with-checkboxes';
import {Text} from '../../../../../../../component/text/text';
import {LocaleWithCatalogName} from '../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../../../provider/locale/locale';
import {WeekDaysEnum} from '../../../../../../../provider/locale/locale-context-const';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {useUser} from '../../../../../../../provider/user/user-hook';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {
    CompanyErrorType,
    CompanyFormType,
    CompanyInnerErrorType,
} from '../../../../../../../service/company-v2/company-type';
import {WorkingDaysType, WorkingDayType} from '../../../../../../../service/company-v2/types/company-working-time-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {useRefreshId} from '../../../../../../../util/hook';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {Unsupported2GisFacebookCountryEnum} from '../../../../company-form-const';
import {getWorkingDaysObject} from '../../../company-form-helper';
import {weekDayAllNameValuesMap} from '../working-time-const';

import {DaysGroup} from './days-group/days-group';
import {
    emptyWeekday,
    getAvailableDays,
    getEmptyWeekdays,
    getSortedWeekdays,
    getSortedWorkingDays,
    getUpdatedWeekdays,
} from './workdays-form-item-helper';
import * as daysGroupStyles from './days-group/days-group.scss';
import * as styles from './workdays-form-item.scss';

const DEFAULT_VALUE = getWorkingDaysObject(Object.values(WeekDaysEnum).map((day) => [`day${day}`, null]));

type PropsType = {
    value?: WorkingDaysType;
    onChange?: (value: WorkingDaysType) => void;
    errorMessage?: CompanyErrorType['workingHours'] | CompanyErrorType['extraWorkingHours'] | null;
    catalogId?: number;
    isDoubleGisCompany: boolean;
};

export function WorkdaysFormItem(props: PropsType): JSX.Element {
    const {value: workingDays = DEFAULT_VALUE, onChange, errorMessage, catalogId, isDoubleGisCompany} = props;

    const [isAddButtonShown, setIsAddButtonShown] = useState<boolean>(false);

    const facebookExtrafieldExists = Form.useWatch<CompanyKeyEnum.ExtraWorkingHours, FormInstance<CompanyFormType>>(
        CompanyKeyEnum.ExtraWorkingHours
    )?.some((catalog) => catalog.catalogId === ProvidersIdsEnum.facebook);
    const address = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>>(CompanyKeyEnum.Address);

    const {user} = useUser();
    const {refresh} = useRefreshId();
    const {getLocalizedString} = useLocale();

    const isShowFacebookAlert =
        user?.regionAvailableCatalogs[ProvidersIdsEnum.facebook] &&
        !catalogId &&
        !facebookExtrafieldExists &&
        (isDoubleGisCompany ? address?.country?.id !== Unsupported2GisFacebookCountryEnum.RussianFederation : true);

    function onNewDayAdd(weekdays: Array<WeekDaysEnum>) {
        setIsAddButtonShown(false);
        onChange?.({...workingDays, ...getEmptyWeekdays(weekdays)});
    }

    function handleOnChange(newValue: WorkingDaysType) {
        onChange?.(newValue);
        refresh();
    }

    useEffect(() => {
        if (getSortedWorkingDays(workingDays).length === 0) {
            onChange?.(getWorkingDaysObject(Object.values(WeekDaysEnum).map((day) => [`day${day}`, emptyWeekday])));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {getSortedWorkingDays(workingDays).map((days, index) => (
                <DaysGroup
                    availableDays={getAvailableDays(workingDays)}
                    catalogId={catalogId}
                    days={days}
                    errorMessage={(errorMessage as Record<number, CompanyInnerErrorType<WorkingDayType>>)?.[index]}
                    isDoubleGisCompany={isDoubleGisCompany}
                    key={days.daysOfWeek[0]}
                    onChange={(breaks) => {
                        handleOnChange(getUpdatedWeekdays(workingDays, days.daysOfWeek, breaks));
                    }}
                    updateDays={(weekdays) => {
                        handleOnChange(getSortedWeekdays(workingDays, days, weekdays));
                    }}
                />
            ))}
            {isAddButtonShown && (
                <div className={daysGroupStyles.DaysGroup_timeBlock}>
                    <div className={daysGroupStyles.DaysGroup_weekDayBlock}>
                        <Text
                            className={daysGroupStyles.DaysGroup_text}
                            stringKey="COMPANY_FORM__WORKING_TIME__DAYS_OF_THE_WEEK"
                        />
                        <SelectWithCheckboxes<WeekDaysEnum>
                            hideFooter
                            initialValue={[]}
                            onConfirm={onNewDayAdd}
                            options={Object.entries(weekDayAllNameValuesMap)
                                .filter(([weekday]) => getAvailableDays(workingDays).includes(weekday as WeekDaysEnum))
                                .map(([weekday, stringKey]) => ({
                                    value: weekday as WeekDaysEnum,
                                    label: getLocalizedString(stringKey),
                                }))}
                            withoutIndeterminate
                            wrapperClassName={daysGroupStyles.DaysGroup_customSelect}
                        />
                    </div>
                </div>
            )}
            {isShowFacebookAlert && (
                <Alert
                    className={styles.WorkdaysFormItem_facebookAlert}
                    closable
                    description={
                        <LocaleWithCatalogName
                            catalogId={ProvidersIdsEnum.facebook}
                            namePlaceholder="FB"
                            stringKey="COMPANY_FORM__WORKING_TIME__FACEBOOK_BREAKS__DESCRIPTION"
                        />
                    }
                    showIcon
                    type="warning"
                />
            )}
            {getAvailableDays(workingDays).length > 0 && (
                <Space align="baseline" className={styles.WorkdaysFormItem_add} size="middle">
                    <Button onClick={() => setIsAddButtonShown(true)}>
                        <Locale stringKey="COMPANY_FORM__WORKING_TIME__ADD_DAYS" />
                    </Button>
                    <Text secondary stringKey="COMPANY_FORM__WORKING_TIME__DAYS_WHICH_NO_TIME" />
                </Space>
            )}
        </>
    );
}
