import {Row, Select} from 'antd';

import {ALL_DAYS_ID} from '../../const';
import {Locale} from '../../provider/locale/locale';
import {RatingStar} from '../rating-star-line/rating-star/rating-star';
import {RatingStarLine} from '../rating-star-line/rating-star-line';

const {Option} = Select;

const selectRatingContentStars: Array<JSX.Element> = [
    <Option key="rating-5" value={5}>
        <RatingStarLine rating={5} starHeight={22} starWidth={20} />
    </Option>,
    <Option key="rating-4" value={4}>
        <RatingStarLine rating={4} starHeight={22} starWidth={20} />
    </Option>,
    <Option key="rating-3" value={3}>
        <RatingStarLine rating={3} starHeight={22} starWidth={20} />
    </Option>,
    <Option key="rating-2" value={2}>
        <RatingStarLine rating={2} starHeight={22} starWidth={20} />
    </Option>,
    <Option key="rating-1" value={1}>
        <RatingStarLine rating={1} starHeight={22} starWidth={20} />
    </Option>,
];

export const selectRatingContentStarsWithNoRating: Array<JSX.Element> = [
    ...selectRatingContentStars,
    <Option key="no-rating" value={0}>
        <Locale stringKey="REVIEW__FORM__MODAL__NO_RATING" />
    </Option>,
];

export const selectRatingContent: Array<JSX.Element> = [
    <Option key="rating-5" value={5}>
        <Row>
            5&nbsp;
            <RatingStar fillPercent={100} height={22} width={20} />
        </Row>
    </Option>,
    <Option key="rating-4" value={4}>
        <Row>
            4&nbsp;
            <RatingStar fillPercent={100} height={22} width={20} />
        </Row>
    </Option>,
    <Option key="rating-3" value={3}>
        <Row>
            3&nbsp;
            <RatingStar fillPercent={100} height={22} width={20} />
        </Row>
    </Option>,
    <Option key="rating-2" value={2}>
        <Row>
            2&nbsp;
            <RatingStar fillPercent={100} height={22} width={20} />
        </Row>
    </Option>,
    <Option key="rating-1" value={1}>
        <Row>
            1&nbsp;
            <RatingStar fillPercent={100} height={22} width={20} />
        </Row>
    </Option>,
];

export const selectRatingContentWithNoRating: Array<JSX.Element> = [
    ...selectRatingContent,
    <Option key="no-rating" value={0}>
        <Locale stringKey="TEXT__NO_RATING" />
    </Option>,
];

export const selectDayOfWeekContent: Array<JSX.Element> = [
    <Option key="all-days" value={ALL_DAYS_ID}>
        <Locale stringKey="WEEK_DAY__ALL_DAYS" />
    </Option>,
    <Option key="monday" value={1}>
        <Locale stringKey="WEEK_DAY__MONDAY" />
    </Option>,
    <Option key="tuesday" value={2}>
        <Locale stringKey="WEEK_DAY__TUESDAY" />
    </Option>,
    <Option key="wednesday" value={3}>
        <Locale stringKey="WEEK_DAY__WEDNESDAY" />
    </Option>,
    <Option key="thursday" value={4}>
        <Locale stringKey="WEEK_DAY__THURSDAY" />
    </Option>,
    <Option key="friday" value={5}>
        <Locale stringKey="WEEK_DAY__FRIDAY" />
    </Option>,
    <Option key="saturday" value={6}>
        <Locale stringKey="WEEK_DAY__SATURDAY" />
    </Option>,
    <Option key="sunday" value={7}>
        <Locale stringKey="WEEK_DAY__SUNDAY" />
    </Option>,
];
