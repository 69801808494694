export const enProductsTranslations = {
    PRODUCTS__HEADER: 'Prices (goods, services, menus)',
    PRODUCTS__CREATE_GROUP__FEED__HEADER: 'Create products group from feed',
    PRODUCTS__CREATE_GROUP__FILE__HEADER: 'Create products group from file',
    PRODUCTS__CREATE_GROUP__MANUAL__HEADER: 'Create products group manually',
    PRODUCTS__CREATE_GROUP__IIKO__HEADER: 'Creating a group from iiko',
    PRODUCTS__EDIT_PRODUCT_GROUP__HEADER: 'Edit products group',
    PRODUCTS__EDIT_PRODUCT_GROUP__FILE_WARNING_MESSAGE:
        'Uploaded products will be deleted! Only products from the new file will be sent to catalogs.',
    PRODUCTS__EDIT_PRODUCT_GROUP__FEED_WARNING_MESSAGE:
        'Feeding products will be deleted! Only products from the new feed will be sent to catalogs.',
    PRODUCTS__GROUPS__TYPE: 'Type',
    PRODUCTS__GROUPS__NAME: 'Name',
    PRODUCTS__GROUPS__PRODUCTS: 'Products',
    PRODUCTS__GROUPS__UPDATED_AT: 'Last updated',
    PRODUCTS__GROUPS__SERVICES: 'Services',
    PRODUCTS__GROUPS__STATUS: 'Status',
    PRODUCTS__GROUPS__ACTIONS: 'Action',
    PRODUCTS__GROUPS__STATUS__NEW: 'New',
    PRODUCTS__GROUPS__STATUS__PROCESSING: 'Processing',
    PRODUCTS__GROUPS__STATUS__ERROR: 'Error',
    PRODUCTS__GROUPS__STATUS__HAS_ERRORS: 'Has errors',
    PRODUCTS__GROUPS__STATUS__FINISHED: 'Synchronized',
    PRODUCTS__GROUPS__STATUS__UPLOAD_ERROR: 'Upload error',
    PRODUCTS__CREATE_GROUP: 'Create group',
    PRODUCTS__CREATE_GROUP__UNSUPPORTED_MODE: 'Unsupported type',
    PRODUCTS__CREATE_GROUP__MODAL__TITLE: 'Group creation',
    PRODUCTS__CREATE_GROUP__TYPE_TITLE__FILE: 'Load from file',
    PRODUCTS__CREATE_GROUP__TYPE_TITLE__FEED: 'Connect Feed',
    PRODUCTS__CREATE_GROUP__TYPE_TITLE__MANUAL: 'Manual',
    PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__FILE: 'Products from Excel or CSV file',
    PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__FEED: 'Automatically load products',
    PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__IIKO: 'Upload products via Iiko',
    PRODUCTS__CREATE_GROUP__TYPE_DESCRIPTION__MANUAL: 'Create products in {companyName}',
    PRODUCTS__GROUP_FORM__FIELD__NAME__TITLE: 'Name',
    PRODUCTS__GROUP_FORM__FIELD__IIKO__KEY: 'Iiko key',
    PRODUCTS__GROUP_FORM__FIELD__IIKO__KEY__PLACEHODLER: 'Iiko key',
    PRODUCTS__GROUP_FORM__FIELD__IIKO__MENU: 'Iiko menu',
    PRODUCTS__GROUP_FORM__FIELD__IIKO__MENU__ADDITIONAL_INFO: 'Enter one or more IDs, separating them with a comma.',
    PRODUCTS__GROUP_FORM__FIELD__IIKO__MENU__PLACEHODLER: 'Iiko Menu',
    PRODUCTS__GROUP_FORM__FIELD__SERVICES__TITLE: 'Services',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__TITLE: 'Google service category',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__TITLE__POPOVER__TITLE: 'Google service category',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__TITLE__POPOVER__CONTENT:
        'Select the category that best describes the services (or most of them), as all services in that group will have that category listed.',
    PRODUCTS__GROUP_FORM__COMPANY_IDS__TITLE: 'Companies',
    PRODUCTS__GROUP_FORM__APPOINTMENT__TITLE: 'Product Group Type',
    PRODUCTS__GROUP_FORM__APPOINTMENT__POPOVER__TITLE: 'Product Group Type',
    PRODUCTS__GROUP_FORM__APPOINTMENT__POPOVER__CONTENT:
        'The product group type determines the display in the Google card.',
    PRODUCTS__GROUP_FORM__FIELD__FEED__TITLE: 'Feed link',
    PRODUCTS__GROUP_FORM__COMPANY_IDS__ERROR: 'Failed loading Google categories',
    PRODUCTS__GROUP_FORM__FIELD__SERVICES__PLACEHOLDER: 'Select services to load',
    PRODUCTS__GROUP_FORM__FIELD__NAME__PLACEHOLDER: 'Group Name',
    PRODUCTS__GROUP_FORM__FIELD__APPOINTMENT__PLACEHOLDER: 'Select a product group type',
    PRODUCTS__GROUP_FORM__FIELD__FEED__PLACEHOLDER: 'Enter feed link',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__PLACEHOLDER: 'Select Google category',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE_CATEGORY__PLACEHOLDER__EMPTY_COMPANIES: 'Select companies',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE__WARNING__MESSAGE: 'No service categories available for companies',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE__WARNING__DESCRIPTION:
        'Select a company with common activities to upload services in your {googleBusinessProfile}.',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE__INFO__AVAILABLE_SERVICES:
        'Currently, Google Business Profile is only available for downloading services and menus.',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE__INFO__SERVICE_LIMITS:
        'Service limit: {servicesCount} for one category in one company.',
    PRODUCTS__GROUP_FORM__FIELD__GOOGLE__INFO__SERVICE_LIMITS__SERVICES_COUNT: '100 goods',
    PRODUCTS__GROUP_FORM__APPOINTMENT__MENU: 'Menu',
    PRODUCTS__GROUP_FORM__SERVICES: 'Services',
    PRODUCTS__GROUP_FORM__VALIDATION_TIME: 'It can take up to several hours to check items before shipping.',
    PRODUCTS__GROUP_FORM__UPLOAD_FILE__UPLOAD: 'Upload',
    PRODUCTS__GROUP_FORM__UPLOAD_FILE__UPLOAD_PRODUCTS_BASE: 'Upload the database of goods',
    PRODUCTS__GROUP_FORM__UPLOAD_FILE__DOWNLOAD_TEMPLATE: 'Template',
    PRODUCTS__GROUP_FORM__UPLOAD_FILE__DOWNLOAD_ORIGINAL_FILE: 'Source file',
    PRODUCTS__GROUP_FORM__UPLOAD_FILE__ERROR: 'Failed to upload file. Please, try again later or contact support.',
    PRODUCTS__GROUP_FORM__FEED__INVALID_FEED: 'Failed to open file via entered link',
    PRODUCTS__GROUP_FORM__CREATION_ERROR: 'Error updating product group',
    PRODUCTS__GROUP_FORM__CREATION_SUCCESS: 'Products group created successfully',
    PRODUCTS__GROUP_FORM__EDIT_SUCCESS: 'Products group updated successfully',
    PRODUCTS__GROUP_FORM__EDIT_ERROR: 'Error updating the product group',
    PRODUCTS__GROUP_FORM__REMOVE_MODAL__TITLE: 'Delete product group?',
    PRODUCTS__GROUP_FORM__REMOVE_MODAL__CONTENT: 'Uploaded items will be removed from the selected services.',
    PRODUCTS__GROUP_FORM__REMOVE__SUCCESS: 'Product group removed successfully',
    PRODUCTS__GROUP_FORM__REMOVE__ERROR: 'Unable to remove product group',
    PRODUCTS__GROUP_FORM__PRODUCTS__HELP_LINK: 'More details about product requirements',
    PRODUCTS__TABLE__TYPE__TOOLTIP__MANUAL: 'Manual',
    PRODUCTS__TABLE__TYPE__TOOLTIP__FILE: 'File',
    PRODUCTS__TABLE__TYPE__TOOLTIP__FEED: 'Feed',
    PRODUCTS__TABLE__TYPE__TOOLTIP__IIKO: 'Iiko',
    PRODUCTS__TABLE__EMPTY_TEXT: 'Create a group of products to upload to services',
};
