import {CatalogNameType, CclStatisticType} from './ccl-statistic-type';

export const defaultCclStatisticData: CclStatisticType = {
    all: 0,
    syncCount: 0,
    sendedCount: 0,
    actionNeededCount: 0,
    confirmationCount: 0,
};

export const CATALOG_TYPES: [CatalogNameType, CatalogNameType] = ['map', 'navigator'];
