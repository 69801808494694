/* eslint-disable id-length */
import al from 'flagpack-core/lib/flags/m/AL.svg';
import am from 'flagpack-core/lib/flags/m/AM.svg';
import az from 'flagpack-core/lib/flags/m/AZ.svg';
import ba from 'flagpack-core/lib/flags/m/BA.svg';
import bd from 'flagpack-core/lib/flags/m/BD.svg';
import bg from 'flagpack-core/lib/flags/m/BG.svg';
import by from 'flagpack-core/lib/flags/m/BY.svg';
import cn from 'flagpack-core/lib/flags/m/CN.svg';
import cz from 'flagpack-core/lib/flags/m/CZ.svg';
import de from 'flagpack-core/lib/flags/m/DE.svg';
import dk from 'flagpack-core/lib/flags/m/DK.svg';
import ee from 'flagpack-core/lib/flags/m/EE.svg';
import es from 'flagpack-core/lib/flags/m/ES.svg';
import fi from 'flagpack-core/lib/flags/m/FI.svg';
import fr from 'flagpack-core/lib/flags/m/FR.svg';
import ge from 'flagpack-core/lib/flags/m/GE.svg';
import gr from 'flagpack-core/lib/flags/m/GR.svg';
import hr from 'flagpack-core/lib/flags/m/HR.svg';
import hu from 'flagpack-core/lib/flags/m/HU.svg';
import id from 'flagpack-core/lib/flags/m/ID.svg';
import ie from 'flagpack-core/lib/flags/m/IE.svg';
import il from 'flagpack-core/lib/flags/m/IL.svg';
import IN from 'flagpack-core/lib/flags/m/IN.svg';
import ir from 'flagpack-core/lib/flags/m/IR.svg';
import is from 'flagpack-core/lib/flags/m/IS.svg';
import it from 'flagpack-core/lib/flags/m/IT.svg';
import jp from 'flagpack-core/lib/flags/m/JP.svg';
import kg from 'flagpack-core/lib/flags/m/KG.svg';
import kr from 'flagpack-core/lib/flags/m/KR.svg';
import kz from 'flagpack-core/lib/flags/m/KZ.svg';
import lt from 'flagpack-core/lib/flags/m/LT.svg';
import lu from 'flagpack-core/lib/flags/m/LU.svg';
import lv from 'flagpack-core/lib/flags/m/LV.svg';
import md from 'flagpack-core/lib/flags/m/MD.svg';
import mk from 'flagpack-core/lib/flags/m/MK.svg';
import mn from 'flagpack-core/lib/flags/m/MN.svg';
import mt from 'flagpack-core/lib/flags/m/MT.svg';
import nl from 'flagpack-core/lib/flags/m/NL.svg';
import no from 'flagpack-core/lib/flags/m/NO.svg';
import ph from 'flagpack-core/lib/flags/m/PH.svg';
import pk from 'flagpack-core/lib/flags/m/PK.svg';
import pl from 'flagpack-core/lib/flags/m/PL.svg';
import pt from 'flagpack-core/lib/flags/m/PT.svg';
import ro from 'flagpack-core/lib/flags/m/RO.svg';
import rs from 'flagpack-core/lib/flags/m/RS.svg';
import ru from 'flagpack-core/lib/flags/m/RU.svg';
import sa from 'flagpack-core/lib/flags/m/SA.svg';
import se from 'flagpack-core/lib/flags/m/SE.svg';
import si from 'flagpack-core/lib/flags/m/SI.svg';
import sk from 'flagpack-core/lib/flags/m/SK.svg';
import th from 'flagpack-core/lib/flags/m/TH.svg';
import tj from 'flagpack-core/lib/flags/m/TJ.svg';
import tm from 'flagpack-core/lib/flags/m/TM.svg';
import tr from 'flagpack-core/lib/flags/m/TR.svg';
import ua from 'flagpack-core/lib/flags/m/UA.svg';
import us from 'flagpack-core/lib/flags/m/US.svg';
import uz from 'flagpack-core/lib/flags/m/UZ.svg';
import vn from 'flagpack-core/lib/flags/m/VN.svg';

import {CountryCodeType} from '../../../service/language/language-const';

export const flagIcons: {[Key in CountryCodeType]: string} = {
    sa,
    az,
    by,
    bg,
    bd,
    ba,
    cz,
    dk,
    de,
    gr,
    us,
    es,
    ee,
    ir,
    fi,
    fr,
    ie,
    il,
    in: IN,
    hr,
    hu,
    am,
    id,
    is,
    it,
    jp,
    ge,
    kz,
    kr,
    kg,
    lu,
    lt,
    lv,
    md,
    mk,
    mn,
    mt,
    nl,
    no,
    pl,
    pt,
    ro,
    ru,
    sk,
    si,
    al,
    rs,
    se,
    tj,
    th,
    tm,
    ph,
    tr,
    ua,
    pk,
    uz,
    vn,
    cn,
};
