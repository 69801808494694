import {faBarsSort, faFilter, faGear} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Flex} from 'antd';
import {Dispatch, ReactElement, SetStateAction, useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {PageHeaderTitle} from '../../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Locale} from '../../../../provider/locale/localization';
import {useSystem} from '../../../../provider/system/system-hook';
import {ReviewSortingTypeEnum} from '../../../../service/reviews/reviews-type';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {RequestReport} from '../request-report/request-report';
import {AddReviewButton} from '../review-list-page-content/add-review-button/add-review-button';
import {NewDesignSwitcher} from '../review-list-page-content/new-design-switcher/new-design-switcher';
import {ReviewsFilterDataType} from '../review-list-page-content/reviews-filter/reviews-filter-type';
import {ReviewsStatistic} from '../review-list-page-content/reviews-statistic/reviews-statistic';
import {useReviewStateHook} from '../reviews-state/reviews-state-provider';
import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from '../reviews-state/reviews-state-type';

import * as styles from './reviews-header.scss';

type PropsType = {
    setIsFilterOpened: Dispatch<SetStateAction<boolean>>;
};

export function ReviewsHeader(props: PropsType): ReactElement {
    const {setIsFilterOpened} = props;

    const {refreshCatalogs, refreshReviews, reviewsState, reviews, cursorPagination} = useReviewStateHook();
    const {screen} = useSystem();
    const hasReviews = useMemo(() => Boolean(reviews?.results.length), [reviews]);

    const {setQuery: setReviewsStateUrlQuery, queries} = useUrl<
        Omit<ReviewsStateType, 'filterState'> & ReviewsFilterDataType
    >();

    const isSorted = queries.ordering === ReviewSortingTypeEnum.acs;

    function handleSorting() {
        setReviewsStateUrlQuery({
            [ReviewsStateUrlQueryNameEnum.sorting]: isSorted ? ReviewSortingTypeEnum.desc : ReviewSortingTypeEnum.acs,
        });
        cursorPagination.changePagePagination(1);
    }

    return (
        <PageCard>
            <Flex align="center" justify="space-between">
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.reviewsManagementReviews.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS',
                        },
                    ]}
                />
                <NewDesignSwitcher />
            </Flex>
            <PageHeader className={styles.ReviewsHeader}>
                <PageHeaderTitle productName={UsetifulNameProductEnum.REVIEWS} title="CATEGORY_NAME__REVIEWS" />

                <div className={styles.ReviewsHeader_buttonWrapper}>
                    <AddReviewButton refreshCatalogs={refreshCatalogs} refreshReviews={refreshReviews} />
                    <RequestReport hasReviews={hasReviews} reviewsState={reviewsState} />
                    <NavigationLink
                        className={styles.ReviewsHeader_buttonLink}
                        to={appRoute.settingsSourceSettings.path}
                    >
                        <Button icon={<FontAwesomeIcon icon={faGear} />} />
                    </NavigationLink>
                </div>
            </PageHeader>

            <Flex>
                <div className={styles.ReviewsHeader_radioButtonWrapper}>
                    <ReviewsStatistic reviewsState={reviewsState} />
                </div>
                <Button
                    className={styles.ReviewsHeader_orderingButton}
                    // eslint-disable-next-line no-undefined
                    icon={<FontAwesomeIcon flip={!isSorted ? 'vertical' : undefined} icon={faBarsSort} />}
                    onClick={handleSorting}
                    type="link"
                >
                    <Locale stringKey={isSorted ? 'REVIEWS__NEW' : 'REVIEWS__OLD'} />
                </Button>
            </Flex>
            {screen.isMobile && (
                <Button
                    block
                    className={styles.ReviewsHeader_filterButton}
                    icon={<FontAwesomeIcon icon={faFilter} />}
                    onClick={() => setIsFilterOpened((state) => !state)}
                    type="default"
                >
                    <Locale stringKey="BUTTON__FILTER" />
                </Button>
            )}
        </PageCard>
    );
}
