import {useMemo} from 'react';

import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/localization';
import {useCatalogsSyncStatistics} from '../../../../../service/dashboard/dashboard-hook';
import {CatalogsSyncNavigatorApiType} from '../../../../../service/dashboard/dashboard-type';
import {CatalogSyncNavigator} from '../navigator/navigator';
import {OverviewsStat} from '../overview-stat/overviews-stat';
import {SyncCards} from '../sync-cards/sync-cards';

import {numberOfDays} from './sync-statistics-consts';
import * as styles from './sync-statistics.scss';

export function SyncStatisticCatalogsInfo(): JSX.Element | null {
    const dateFrom = useMemo(() => {
        const temporaryDate = new Date();

        temporaryDate.setDate(temporaryDate.getDate() - numberOfDays);
        return temporaryDate;
    }, []);
    const dateTo = useMemo(() => {
        return new Date();
    }, []);

    const {result, isInProgress, processError} = useCatalogsSyncStatistics({
        dateFrom,
        dateTo,
    });

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress) {
        return <Spinner />;
    }

    if (!result) {
        return null;
    }

    return (
        <>
            <OverviewsStat value={result.catalogStats.score} />
            <SyncCards cardsData={result.catalogStats.data} />
            {result.navigators.length > 0 && (
                <div className={styles.sync__navigators__wrapper}>
                    <p className={styles.sync__navigators__text}>
                        <Locale stringKey="DASHBOARD_PAGE__CATALOGS_SYNC_STATISTICS__NAVIGATORS" />
                    </p>
                    {result.navigators.map(({id: catalogId}: CatalogsSyncNavigatorApiType) => {
                        return <CatalogSyncNavigator catalogId={catalogId} key={catalogId} />;
                    })}
                </div>
            )}
        </>
    );
}
