import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Checkbox, Form, FormInstance, Input, Modal, Radio, RadioChangeEvent} from 'antd';
import {useEffect, useState} from 'react';

import {Text} from '../../../../component/text/text';
import {AdditionalInfo} from '../../../../layout/additional-info/additional-info';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {ExportReportModeEnum, ExportReportOptionsType} from '../../../../service/reviews/reviews-report';
import {classNames} from '../../../../util/css';
import {getEnumValue} from '../../../../util/enum';

import {getReportDataList, reportFormId} from './reviews-download-report-popup-const';
import {
    ReviewsDownloadReportPopupItemType,
    ReviewsReportFormEnum,
    ReviewsReportFormType,
} from './reviews-download-report-popup-type';
import {ReviewsReportNameHint} from './reviews-report-name-hint/reviews-report-name-hint';
import * as styles from './reviews-download-report-popup.scss';

type PropsType = {
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    requestForReport: (requestReport: ExportReportOptionsType) => void;
    hasReviews: boolean;
};

export function ReviewsDownloadReportPopup(props: PropsType): JSX.Element {
    const {className, isOpen, onClose, requestForReport, hasReviews} = props;

    const {getLocalizedString} = useLocale();
    const {domainName} = useDomainConfig();

    const [form]: [FormInstance<ReviewsReportFormType>] = Form.useForm();
    const [selectedMode, setSelectedMode] = useState<ExportReportModeEnum | null>(null);

    const isDownloadDisebled = selectedMode !== ExportReportModeEnum.Base4 && !hasReviews;

    useEffect(() => {
        if (isOpen) {
            setSelectedMode(null);
        }
    }, [form, isOpen]);

    function onSelectModeChange(radioEvent: RadioChangeEvent) {
        const reportMode: ExportReportModeEnum | null = getEnumValue<ExportReportModeEnum>(
            ExportReportModeEnum,
            radioEvent.target.value
        );

        setSelectedMode(reportMode);
        form.resetFields();
    }

    function onSubmit(formValues: ReviewsReportFormType) {
        const {name, withReviewsSummary, withTagsStat} = formValues;

        const reportMode: ExportReportModeEnum | null = getEnumValue<ExportReportModeEnum>(
            ExportReportModeEnum,
            selectedMode
        );

        if (!reportMode) {
            return;
        }

        onClose();

        switch (reportMode) {
            case ExportReportModeEnum.Base:
                requestForReport({
                    mode: reportMode,
                    customReportName: name,
                    withReviewsSummary,
                    withTagsStat,
                    version: 2,
                });
                break;
            case ExportReportModeEnum.Base5:
                requestForReport({
                    mode: ExportReportModeEnum.Base,
                    customReportName: name,
                    withReviewsSummary,
                    withTagsStat,
                    version: 1,
                });
                break;
            default:
                requestForReport({
                    mode: reportMode,
                    customReportName: name,
                    withReviewsSummary,
                    withTagsStat,
                });
        }
    }

    function renderAdditionalInfo(mode: ExportReportModeEnum): JSX.Element | null {
        switch (mode) {
            case ExportReportModeEnum.Base:
                return (
                    <>
                        &nbsp;
                        <AdditionalInfo
                            icon={
                                <FontAwesomeIcon
                                    className={styles.ReviewsDownloadReportPopup_tooltipIcon}
                                    icon={faInfoCircle}
                                />
                            }
                            placement="bottom"
                            title={
                                <Text
                                    bolder
                                    stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_TITLE__NEW"
                                />
                            }
                        >
                            <Text stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_CONTENT__NEW" />
                        </AdditionalInfo>
                    </>
                );
            case ExportReportModeEnum.Base5:
                return (
                    <>
                        &nbsp;
                        <AdditionalInfo
                            icon={
                                <FontAwesomeIcon
                                    className={styles.ReviewsDownloadReportPopup_tooltipIcon}
                                    icon={faInfoCircle}
                                />
                            }
                            placement="bottom"
                            title={
                                <Text
                                    bolder
                                    stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_TITLE__OLD"
                                />
                            }
                        >
                            <p>
                                <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__FULL_UNLOADING_OF_REVIEWS__TOOLTIP_CONTENT__OLD" />
                            </p>
                        </AdditionalInfo>
                    </>
                );
            default:
                return null;
        }
    }

    return (
        <Modal
            className={classNames(styles.ReviewsDownloadReportPopup, className)}
            footer={[
                <Button className={styles.ReviewsDownloadReportPopup_footerButton} key="close" onClick={onClose}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button
                    className={styles.ReviewsDownloadReportPopup_footerButton}
                    disabled={isDownloadDisebled}
                    form={reportFormId}
                    htmlType="submit"
                    key="submit"
                    type="primary"
                >
                    <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__BUTTON__REQUEST" />
                </Button>,
            ]}
            onCancel={onClose}
            open={isOpen}
            title={<Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__HEADER" />}
            width="568px"
        >
            <Form<ReviewsReportFormType> form={form} id={reportFormId} layout="vertical" onFinish={onSubmit}>
                <Alert
                    icon={<FontAwesomeIcon className={styles.ReviewsDownloadReportPopup_info} icon={faInfoCircle} />}
                    message={<Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__INFO" />}
                    showIcon
                    type="info"
                />

                <Radio.Group
                    className={styles.ReviewsDownloadReportPopup_list}
                    onChange={onSelectModeChange}
                    value={selectedMode}
                >
                    {getReportDataList(domainName).map(
                        (reportData: ReviewsDownloadReportPopupItemType): JSX.Element => {
                            const {mode, header, description, placeholder} = reportData;

                            return (
                                <div className={styles.ReviewsDownloadReportPopup_listItem} key={mode}>
                                    <Radio value={mode}>
                                        <h5 className={styles.ReviewsDownloadReportPopup_itemHeader}>
                                            <Locale stringKey={header} />
                                            <div className={styles.ReviewsDownloadReportPopup_itemHeaderIcon}>
                                                {renderAdditionalInfo(mode)}
                                            </div>
                                        </h5>
                                    </Radio>
                                    <div className={styles.ReviewsDownloadReportPopup_descriptionSection}>
                                        <p className={styles.ReviewsDownloadReportPopup_itemDescription}>
                                            <Locale stringKey={description} />
                                        </p>
                                        {selectedMode === mode && (
                                            <>
                                                {isDownloadDisebled && (
                                                    <Alert
                                                        className={styles.ReviewsDownloadReportPopup_alert}
                                                        icon={
                                                            <FontAwesomeIcon
                                                                className={styles.ReviewsDownloadReportPopup_alertIcon}
                                                                icon={faInfoCircle}
                                                            />
                                                        }
                                                        message={
                                                            <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEWS__EMPTY" />
                                                        }
                                                        showIcon
                                                        type="warning"
                                                    />
                                                )}
                                                <Form.Item<ReviewsReportFormType>
                                                    className={styles.ReviewsDownloadReportPopup_infoFormItem}
                                                    initialValue=""
                                                    label={<ReviewsReportNameHint />}
                                                    name={ReviewsReportFormEnum.Name}
                                                >
                                                    <Input
                                                        autoFocus
                                                        disabled={isDownloadDisebled}
                                                        placeholder={getLocalizedString(placeholder)}
                                                    />
                                                </Form.Item>

                                                {mode === ExportReportModeEnum.Base && (
                                                    <div className={styles.ReviewsDownloadReportPopup_checkboxes}>
                                                        <Form.Item<ReviewsReportFormType>
                                                            className={styles.ReviewsDownloadReportPopup_checkbox}
                                                            initialValue={false}
                                                            label={
                                                                <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__ADDITIONAL_DATA__LABEL__TITLE" />
                                                            }
                                                            name={ReviewsReportFormEnum.WithReviewsSummary}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox disabled={isDownloadDisebled}>
                                                                <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__FEEDBACK_REPORT" />
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item<ReviewsReportFormType>
                                                            className={classNames(
                                                                styles.ReviewsDownloadReportPopup_checkbox,
                                                                styles.ReviewsDownloadReportPopup_checkbox__withoutIndent
                                                            )}
                                                            initialValue={false}
                                                            name={ReviewsReportFormEnum.WithTagsStat}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox
                                                                defaultChecked={false}
                                                                disabled={isDownloadDisebled}
                                                            >
                                                                <Locale stringKey="REVIEWS__DOWNLOAD_REPORT_POPUP__REVIEW_STATISTICS_BY_BRAND_AND_CITY__TAG_REPORT" />
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                    )}
                </Radio.Group>
            </Form>
        </Modal>
    );
}
