import {Select} from 'antd';

import {Locale} from '../../../provider/locale/localization';
import {defaultPageSizeOptions} from '../../../util/cursor-pagination/cursor-pagination-const';

type PropsType = {
    onChange: (pageSize: number) => void;
    pageSize: number;
};

export function PageSizeSelect(props: PropsType): JSX.Element {
    const {pageSize, onChange} = props;

    return (
        <Select<number> aria-label="page-size" onChange={onChange} value={pageSize}>
            {defaultPageSizeOptions.map((size: number): JSX.Element => {
                return (
                    <Select.Option key={size} value={size}>
                        <Locale stringKey="TABLE__PAGINATION__PAGE_SIZE" valueMap={{pageSize: size.toString()}} />
                    </Select.Option>
                );
            })}
        </Select>
    );
}
