import {Button} from 'antd';

import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/localization';
import {ReviewLogDataType, useReviewLogsList} from '../../../../service/reviews/reviews-logs';
import {ResponsibleUserType} from '../../../../service/reviews/reviews-responsible-users';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';

import {AbuseLog} from './abuse-log/abuse-log';
import {CreatedLog} from './created-log/created-log';
import {NoteLog} from './note-log/note-log';
import {ResponsibleUserLog} from './responsible-user-log/responsible-user-log';
import {LogTypesEnum} from './review-log-type';
import {StatusLog} from './status-log/status-log';
import * as styles from './review-logs.scss';

type PropsType = {
    reviewId: number;
    reviewCreatedInCatalog: string;
    reviewAuthor: string | null;
};

export function ReviewLogs(props: PropsType): JSX.Element | null {
    const {isHistoryVisible} = useReviewActivityHook();
    const {reviewId, reviewCreatedInCatalog, reviewAuthor} = props;

    const {data: logs, fetchNextPage: loadMore, isLoading} = useReviewLogsList(reviewId, isHistoryVisible);

    const logsList = logs?.pages.map((log) => log.results).flatMap((array) => [...array]) || [];

    if (!isHistoryVisible) {
        return null;
    }

    return (
        <div className={styles.ReviewLogs}>
            <Spinner isShow={isLoading} position="absolute" />
            {logsList.map((log: ReviewLogDataType) => {
                switch (log.type) {
                    case LogTypesEnum.Note:
                        return (
                            <div key={log.loggedAt}>
                                <NoteLog action={log.action} createdAt={log.loggedAt} user={log.user} />
                            </div>
                        );
                    case LogTypesEnum.ResponsibleUser:
                        return (
                            <div key={log.loggedAt}>
                                <ResponsibleUserLog
                                    loggedAt={log.loggedAt}
                                    user={log.user}
                                    value={log.newValue as ResponsibleUserType}
                                />
                            </div>
                        );
                    case LogTypesEnum.Status:
                        return (
                            <div key={log.loggedAt}>
                                <StatusLog
                                    loggedAt={log.loggedAt}
                                    newStatus={String(log.newValue)}
                                    oldStatus={String(log.oldValue)}
                                    user={log.user}
                                />
                            </div>
                        );
                    case LogTypesEnum.Abuse:
                        return (
                            <div key={log.loggedAt}>
                                <AbuseLog
                                    action={log.action}
                                    createdAt={log.loggedAt}
                                    user={log.user}
                                    value={String(log.newValue)}
                                />
                            </div>
                        );
                    default:
                        return null;
                }
            })}
            {logs?.pages[logs?.pages.length - 1]?.next ? (
                <Button
                    className={styles.ReviewLogs_moreButton}
                    disabled={isLoading}
                    onClick={() => loadMore}
                    type="text"
                >
                    <Locale stringKey="DASHBOARD_PAGE__TIMELINE__SHOW_MORE" />
                </Button>
            ) : (
                <CreatedLog author={reviewAuthor} createdAt={reviewCreatedInCatalog} />
            )}
        </div>
    );
}
