import {Button} from 'antd';

import {appRoute} from '../../../../../../app-route';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {formatAddress} from '../../../../../../service/address/address-helper';
import {deleteCompany} from '../../../../../../service/company-v2/company-api';
import {CompanyAddressType} from '../../../../../../service/company-v2/types/company-address-type';
import {useUrl} from '../../../../../../util/url-hook/url-hook';

import * as styles from './deletion-widget.scss';

type PropsType = {
    companyId: number;
    name: string;
    address: CompanyAddressType['address'];
    needModerate: boolean;
};

export function DeletionWidget(props: PropsType): JSX.Element {
    const {companyId, name, address, needModerate} = props;
    const {companyName} = useDomainConfig();

    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    async function onRemove() {
        try {
            await deleteCompany(companyId);

            pushUrl(appRoute.myCompanies.path);

            snackbar.success({
                message: (
                    <Locale
                        stringKey={
                            needModerate
                                ? 'COMPANY_FORM__SNACKBAR__REMOVED__HEADER'
                                : 'COMPANY_FORM__SNACKBAR__CLOSED__HEADER'
                        }
                    />
                ),
                description: (
                    <Locale
                        stringKey={
                            needModerate
                                ? 'COMPANY_FORM__SNACKBAR__REMOVED__BODY'
                                : 'COMPANY_FORM__SNACKBAR__CLOSED__BODY'
                        }
                        valueMap={{title: name}}
                    />
                ),
            });
        } catch {
            snackbar.error({
                message: <Locale stringKey="COMPANY_FORM__SNACKBAR__SAVE_ERROR__HEADER" />,
                description: <Locale stringKey="SNACKBAR__ERROR__TECH_SUPPORT" />,
            });
        }
    }

    function openDeletionModal() {
        modal.confirm({
            title: (
                <Locale
                    stringKey={
                        needModerate
                            ? 'COMPANY_FORM__DELETION__REMOVAL__MODAL__TITLE'
                            : 'COMPANY_FORM__DELETION__CLOSING__MODAL__TITLE'
                    }
                />
            ),
            content: (
                <>
                    <Locale
                        stringKey={
                            needModerate
                                ? 'COMPANY_FORM__DELETION__REMOVAL__MODAL__DESCRIPTION'
                                : 'COMPANY_FORM__DELETION__CLOSING__MODAL__DESCRIPTION'
                        }
                        valueMap={{companyName}}
                    />
                    <div className={styles.deletion_widget__modal_summary}>
                        <h4>{name}</h4>
                        <address>
                            {formatAddress({
                                country: address.countryName,
                                region: address.regionName,
                                city: address.cityName,
                                street: address.street,
                                housenumber: address.houseNumber,
                            })}
                        </address>
                    </div>
                </>
            ),
            okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            okButtonProps: {type: 'default', danger: true},
            onOk: onRemove,
            width: 512,
        });
    }

    return (
        <div className={styles.deletion_widget}>
            <div className={styles.deletion_widget__description}>
                <Locale
                    stringKey={
                        needModerate
                            ? 'COMPANY_FORM__DELETION__REMOVAL__DESCRIPTION'
                            : 'COMPANY_FORM__DELETION__CLOSING__DESCRIPTION'
                    }
                    valueMap={{companyName}}
                />
            </div>

            <Button className={styles.deletion_widget__button} danger onClick={openDeletionModal}>
                <Locale
                    stringKey={needModerate ? 'COMPANY_FORM__DELETION__REMOVAL' : 'COMPANY_FORM__DELETION__CLOSING'}
                />
            </Button>
        </div>
    );
}
