import {Button, Checkbox, Form, Input, Select} from 'antd';
import {useEffect, useState} from 'react';

import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../../provider/domain-config/domain-config-type';
import {Locale} from '../../../../provider/locale/locale';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {useEmailNotifications} from '../../../../service/notifications-settings/notifications-settings-hook';
import {
    EmailNotificationsEnum,
    EmailNotificationsFrequencyType,
    EmailNotificationsType,
    NotificationTextReviewEnum,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import * as notificationStyles from '../notification.scss';
import {NotificationReviewFilter} from '../notification-review-filter/notification-review-filter';
import {NotificationsReviewsFilterType} from '../notification-review-filter/notification-review-filter-type';

import {emailNotificationsFrequencyList} from './notification-email-const';
import {serializeNotificationEmail} from './notification-email-helper';
import * as styles from './notification-email.scss';

export function NotificationEmail(): JSX.Element {
    const [form] = Form.useForm<EmailNotificationsType & NotificationsReviewsFilterType>();
    const [isReceiveNotifications, setIsReceiveNotifications] = useState<boolean>(false);
    const [isDisabledEmailField, setIsDisabledEmailField] = useState(false);
    const [isReceiveChanges, setIsReceiveChanges] = useState<boolean>(false);

    const {isDomainFeatureEnabled, domainName} = useDomainConfig();
    const {user} = useUser();
    const {commaSeparatedEmailFieldRule} = useFormRules();
    const {snackbar} = useSnackbar();

    const {result: emailNotifications, updateEmailNotifications, isInProgress} = useEmailNotifications();

    function handleFinishSuccess() {
        const formData: EmailNotificationsType = serializeNotificationEmail(form);

        updateEmailNotifications(formData)
            .then(() =>
                snackbar.success({
                    message: <Locale stringKey="NOTIFICATIONS__SETTINGS_SAVED" />,
                    description: <Locale stringKey="NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS__UPDATED" />,
                })
            )
            .catch(() =>
                snackbar.error(<Locale stringKey="NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS__FAILED_TO_UPDATE" />)
            );
    }

    function handleFormChange() {
        const checkboxFieldValue = form.getFieldsValue([
            EmailNotificationsEnum.SendNewsMail,
            EmailNotificationsEnum.SendReviewsNotifications,
            EmailNotificationsEnum.SendReviewChangesNotifications,
            EmailNotificationsEnum.SendQuestionsNotifications,
            EmailNotificationsEnum.SendWeeklyAnalyticsSummaryNotifications,
            EmailNotificationsEnum.SendWeeklyPhototoolSummaryNotifications,
            EmailNotificationsEnum.SendNewPostsInTickets,
            EmailNotificationsEnum.NotifyAboutRatingDrop,
        ]);

        setIsReceiveNotifications(checkboxFieldValue[EmailNotificationsEnum.SendReviewsNotifications]);
        setIsReceiveChanges(checkboxFieldValue[EmailNotificationsEnum.SendReviewChangesNotifications]);
        setIsDisabledEmailField(!Object.values(checkboxFieldValue).includes(true));
    }

    useEffect(() => {
        if (emailNotifications) {
            setIsReceiveNotifications(emailNotifications[EmailNotificationsEnum.SendReviewsNotifications]);
            setIsReceiveChanges(emailNotifications[EmailNotificationsEnum.SendReviewChangesNotifications]);

            form.setFieldsValue({
                catalog: emailNotifications[EmailNotificationsEnum.SendReviewNotificationCatalogs] || [],
                ratings: emailNotifications[EmailNotificationsEnum.SendReviewNotificationRatings] || [],
                type:
                    emailNotifications[EmailNotificationsEnum.SendReviewNotificationType] ||
                    NotificationTextReviewEnum.all,
            });
        }
    }, [emailNotifications, form]);

    if (!emailNotifications) {
        return <Spinner position="absolute" wrapperHeight={400} />;
    }

    const isNotificationsTurnedOn = isReceiveChanges || isReceiveNotifications;

    const reviewsFilterFields: NotificationsReviewsFilterType = {
        catalog: emailNotifications[EmailNotificationsEnum.SendReviewNotificationCatalogs] || [],
        ratings: emailNotifications[EmailNotificationsEnum.SendReviewNotificationRatings] || [],
        type: emailNotifications[EmailNotificationsEnum.SendReviewNotificationType] || NotificationTextReviewEnum.all,
    };

    return (
        <>
            <PageSubHeader className={notificationStyles.Notification_reviewsSubHeader}>
                <Locale stringKey="NOTIFICATIONS__TAB_INFO__EMAIL_NOTIFICATIONS__SUB_HEADER" />
            </PageSubHeader>

            <Form<EmailNotificationsType & NotificationsReviewsFilterType>
                className={notificationStyles.Notification_form}
                form={form}
                initialValues={emailNotifications}
                layout="vertical"
                name="notification-email"
                onChange={handleFormChange}
                onFinish={handleFinishSuccess}
            >
                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    name={EmailNotificationsEnum.SendNewsMail}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NEWS" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    name={EmailNotificationsEnum.SendReviewsNotifications}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NOTIFICATIONS_OF_NEW_REVIEWS" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    name={EmailNotificationsEnum.SendReviewChangesNotifications}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NOTIFICATIONS_WHEN_REVIEWS_HAVE_CHANGED" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    name={EmailNotificationsEnum.SendQuestionsNotifications}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NOTIFICATIONS_OF_NEW_QUESTIONS" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    hidden={!isDomainFeatureEnabled[FeaturesEnum.onlinePresenceAnalytics]}
                    name={EmailNotificationsEnum.SendWeeklyAnalyticsSummaryNotifications}
                    valuePropName="checked"
                >
                    <Checkbox disabled={!user?.features?.includes(FeaturesEnum.onlinePresenceAnalytics)}>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_WEEKLY_ANALYTICS_SUMMARY_NOTIFICATIONS" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    hidden={!isDomainFeatureEnabled[FeaturesEnum.photoTool]}
                    name={EmailNotificationsEnum.SendWeeklyPhototoolSummaryNotifications}
                    valuePropName="checked"
                >
                    <Checkbox disabled={!user?.features?.includes(FeaturesEnum.photoTool)}>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_WEEKLY_PHOTOTOOL_SUMMARY_NOTIFICATIONS" />
                    </Checkbox>
                </Form.Item>

                {domainName === DomainNameEnum.rocketData && (
                    <Form.Item
                        className={notificationStyles.Notification_checkbox}
                        name={EmailNotificationsEnum.SendNewPostsInTickets}
                        valuePropName="checked"
                    >
                        <Checkbox>
                            <Locale stringKey="NOTIFICATIONS__RECEIVE_NEW_COMMENTS_IN_TICKETS_NOTIFICATIONS" />
                        </Checkbox>
                    </Form.Item>
                )}

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    name={EmailNotificationsEnum.NotifyAboutRatingDrop}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <Locale stringKey="NOTIFICATION__NOTIFY_ABOUT_RATING_DROP" />
                    </Checkbox>
                </Form.Item>

                <div className={styles.EmailNotifications_newReviewsBlock}>
                    <PageSubHeader className={styles.EmailNotifications_newReviewsHeader}>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NEW_REVIEWS_SUB_HEADER" />
                    </PageSubHeader>

                    <Form.Item
                        className={notificationStyles.Notification_wideInput}
                        label={<Locale stringKey="NOTIFICATIONS__RECEIVE_NEW_REVIEWS_EMAIL_LABEL" />}
                        name={EmailNotificationsEnum.NotificationEmails}
                        rules={[commaSeparatedEmailFieldRule()]}
                    >
                        <Input
                            className={notificationStyles.Notification_wideInput}
                            disabled={isDisabledEmailField}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={notificationStyles.Notification_wideInput}
                        label={<Locale stringKey="NOTIFICATIONS__RECEIVE_NEW_REVIEWS_FREQUENCY_LABEL" />}
                        name={EmailNotificationsEnum.SendReviewsNotificationsPeriod}
                    >
                        <Select<EmailNotificationsFrequencyType> disabled={!isReceiveNotifications} size="large">
                            <Select.Option value={emailNotificationsFrequencyList[0]}>
                                <Locale stringKey="NOTIFICATIONS__RECEIVE_NEW_REVIEWS_FREQUENCY_ONE_HOUR_OPTION" />
                            </Select.Option>

                            <Select.Option value={emailNotificationsFrequencyList[1]}>
                                <Locale stringKey="NOTIFICATIONS__RECEIVE_NEW_REVIEWS_FREQUENCY_ONE_DAY_OPTION" />
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </div>

                <NotificationReviewFilter
                    className={notificationStyles.Notification_reviewFilter}
                    disabled={!isNotificationsTurnedOn}
                    fields={reviewsFilterFields}
                />

                <Button htmlType="submit" loading={isInProgress} type="primary">
                    <Locale stringKey="BUTTON__SAVE_SETTINGS" />
                </Button>
            </Form>
        </>
    );
}
