import {
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {ProvidersEnum} from '../../../../../../../util/type';
import {PostContentWrapper} from '../../../post-content-wrapper/post-content-wrapper';
import {VKONTAKTE_POST_SETTINGS} from '../content-step-helper';
import {SourcePostPropsType} from '../content-step-type';
import {ImagesItem} from '../items/media/image/images-item';
import {VideoItem} from '../items/media/video/video-item';
import {PostTextItem} from '../items/text/post-text-item';
import {PostPreview} from '../post-preview/post-preview';
import {PostRequirements} from '../post-requirements/post-requirements';

export function VkontakteCatalogData(props: SourcePostPropsType): JSX.Element {
    const {formInstance, sourceIndex, suggestSettings, formMode} = props;

    const disabled = formMode === PostFormModeEnum.View;
    const fieldName = [PostFormFieldsEnum.Posts, sourceIndex];
    const formValues = formInstance.getFieldValue([]) as PostFormType;

    return (
        <PostContentWrapper>
            <PostContentWrapper.Left>
                <PostTextItem
                    disabled={disabled}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Text]}
                    formInstance={formInstance}
                    maxLength={VKONTAKTE_POST_SETTINGS.maxTextLength}
                    suggestSettings={suggestSettings}
                />

                <ImagesItem
                    disabled={disabled}
                    fieldName={[...fieldName, SourcePostFormFieldsEnum.Images]}
                    formInstance={formInstance}
                    settings={VKONTAKTE_POST_SETTINGS.images}
                />

                <VideoItem disabled={disabled} fieldName={[...fieldName, SourcePostFormFieldsEnum.Video]} />
            </PostContentWrapper.Left>
            <PostContentWrapper.Right>
                <PostPreview
                    catalog={PostSourceEnum.vkontakte}
                    formInstance={formInstance}
                    sources={{groupIds: formValues[PostFormFieldsEnum.VkGroupsIds]}}
                />
                <PostRequirements options={VKONTAKTE_POST_SETTINGS} provider={ProvidersEnum.vkontakte} />
            </PostContentWrapper.Right>
        </PostContentWrapper>
    );
}
