import {RouteProps} from 'react-router';

import {appRoute} from '../../../../app-route';
import {ChangeEmail} from '../../../../page/auth/change-email/change-email';
import {ForgotPassword} from '../../../../page/auth/forgot-password/forgot-password';
import {Login} from '../../../../page/auth/login/login';
import {NewPassword} from '../../../../page/auth/new-password/new-password';
import {Register} from '../../../../page/auth/register/register';
import {TwoFactorAuthenticationSetup} from '../../../../page/auth/two-factor-authentication/setup/two-factor-authentication-setup';
import {TwoFactorAuthentication} from '../../../../page/auth/two-factor-authentication/two-factor-authentication';
import {CompaniesValidationNotAuthorized} from '../../../../page/main/my-companies/feed/validation/companies-validation-not-authorized';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {getAvailableItems, getPreparedRoutes} from '../helpers/common';
import {CustomRouteItemType, NavigationItemConfigType} from '../routing-type';

export function useNotAuthorizedNavigation(): Array<RouteProps & CustomRouteItemType> {
    const {isWhiteLabel} = useDomainConfig();

    const routes: Array<NavigationItemConfigType> = [
        {
            path: appRoute.login.path,
            exact: true,
            component: Login,
        },
        {
            path: appRoute.forgotPassword.path,
            exact: true,
            component: ForgotPassword,
        },
        {
            path: appRoute.newPassword.path,
            exact: true,
            component: NewPassword,
        },
        {
            path: appRoute.twoFactorAuthentication.path,
            exact: true,
            component: TwoFactorAuthentication,
        },
        {
            path: appRoute.twoFactorAuthenticationSetup.path,
            exact: true,
            component: TwoFactorAuthenticationSetup,
        },
        {
            isAvailable: !isWhiteLabel,
            path: appRoute.register.path,
            exact: true,
            component: Register,
        },
        {
            isAvailable: !isWhiteLabel,
            path: appRoute.changeEmail.path,
            exact: true,
            component: ChangeEmail,
        },
        {
            path: appRoute.companyValidate.path,
            exact: true,
            component: CompaniesValidationNotAuthorized,
        },
        // always should be last, for correct work
        {
            path: '*',
            exact: false,
            component: Login,
        },
    ];

    return getPreparedRoutes(getAvailableItems(routes));
}
