import {Modal, ModalProps} from 'antd';
import {PropsWithChildren, ReactNode, useCallback, useRef, useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';

import {noop} from '../../util/function';

import * as styles from './draggable-modal.scss';

type PropsType = PropsWithChildren<ModalProps>;

export function DraggableModal(props: PropsType): JSX.Element {
    const {children, className, title = '', ...restProps} = props;

    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0});
    const draggleRef = useRef<HTMLDivElement>(null);

    const momoizedModalRender = useCallback(
        (modal: ReactNode): ReactNode => {
            function onDragStart(_event: DraggableEvent, uiData: DraggableData) {
                const {clientWidth, clientHeight} = window.document.documentElement;
                const targetRect = draggleRef.current?.getBoundingClientRect();

                if (!targetRect) {
                    return;
                }

                setBounds({
                    left: -targetRect.left + uiData.x,
                    right: clientWidth - (targetRect.right - uiData.x),
                    top: -targetRect.top + uiData.y,
                    bottom: clientHeight - (targetRect.bottom - uiData.y),
                });
            }

            return (
                <Draggable
                    bounds={bounds}
                    disabled={disabled}
                    nodeRef={draggleRef}
                    onStart={(event, uiData) => onDragStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            );
        },
        [bounds, disabled]
    );

    return (
        <Modal
            afterOpenChange={() => {
                document.body.style.overflow = 'auto';
            }}
            className={className}
            mask={false}
            modalRender={momoizedModalRender}
            title={
                <div
                    className={styles.DraggableModal_title}
                    onBlur={() => noop}
                    onFocus={() => noop}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                >
                    {title}
                </div>
            }
            wrapClassName={styles.DraggableModal}
            {...restProps} // eslint-disable-line react/jsx-props-no-spreading
        >
            {children}
        </Modal>
    );
}
