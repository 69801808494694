import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {ReviewsRatingByDateType} from '../../../../service/reviews-and-answers-statistics/reviews-ratings-by-date/reviews-rating-by-date-type';

import {RatingDistributionByTime} from './rating-distribution-by-time';

type PropsType = {
    isCompareMode: boolean;
    pdfPreview?: boolean;

    reviewsRatingByDate: ReviewsRatingByDateType | null;
};

export function RatingDistributionByTimeCard(props: PropsType): JSX.Element {
    const {isCompareMode, pdfPreview, reviewsRatingByDate} = props;

    const {getLocalizedString} = useLocale();

    if (!reviewsRatingByDate) {
        return (
            <PageCard title={getLocalizedString('REVIEW_AND_ANSWER_STATISTICS__REVIEWS_RATING_BY_TIME')}>
                <Spinner />
            </PageCard>
        );
    }

    return (
        <PageCard title={getLocalizedString('REVIEW_AND_ANSWER_STATISTICS__REVIEWS_RATING_BY_TIME')}>
            <RatingDistributionByTime
                isCompareMode={isCompareMode}
                pdfPreview={pdfPreview}
                reviewsRatingByDate={reviewsRatingByDate}
            />
        </PageCard>
    );
}
