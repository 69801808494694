import {faFileAlt, faPlayCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip} from 'antd';
import {useEffect, useState} from 'react';

import {useDomainConfig} from '../../../../../provider/domain-config/domain-config-hook';
import {useHelpLink} from '../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {VideoPopup} from '../../../../video-popup/video-popup';
import {getVideoId} from '../../../../video-popup/video-popup-helper';
import * as headerButtonsStyles from '../header-buttons.scss';
import {HeaderDropdownButton} from '../header-dropdown-button';

type PropsType = {
    icon: JSX.Element;
};

function handleLinkClick() {
    track(AnalyticsTarget.PersonalCabinet.Help, 'Text');
}

// eslint-disable-next-line complexity
export function HelpLinksMenu(props: PropsType): JSX.Element | null {
    const {icon} = props;

    const {getLocalizedString} = useLocale();
    const {pathname, hash} = useUrl();
    const helpLink = useHelpLink(pathname);
    const {isDynamicHelpLinksVisible} = useDomainConfig();
    const [isVideoPopupShown, setIsVideoPopupShown] = useState(false);

    useEffect(() => {
        if (hash === '#video') {
            setIsVideoPopupShown(true);
        }
    }, [hash]);

    if (!isDynamicHelpLinksVisible) {
        return null;
    }

    const {documentationLink, videoLink: rawVideoLink} = helpLink || {videoLink: null, documentationLink: null};
    const videoLink = rawVideoLink && getVideoId(rawVideoLink) ? rawVideoLink : null;

    function handleVideoClick() {
        setIsVideoPopupShown(true);

        if (videoLink) {
            track(AnalyticsTarget.PersonalCabinet.Help, 'Video');
        }
    }

    return (
        <>
            {isVideoPopupShown && videoLink && (
                <VideoPopup onClose={() => setIsVideoPopupShown(false)} url={videoLink} />
            )}

            {documentationLink && videoLink ? (
                <HeaderDropdownButton
                    icon={icon}
                    menu={{
                        items: [
                            {
                                key: 'video',
                                label: <Locale stringKey="HEADER__HELP_LINKS__VIDEO_LINK" />,
                                icon: (
                                    <FontAwesomeIcon
                                        className={headerButtonsStyles.icon}
                                        fixedWidth
                                        icon={faPlayCircle}
                                    />
                                ),
                                onClick: handleVideoClick,
                            },
                            {
                                key: 'link',
                                label: (
                                    <a href={documentationLink} rel="noreferrer" target="_blank">
                                        <Locale stringKey="HEADER__HELP_LINKS__DOCUMENTATION_LINK" />
                                    </a>
                                ),
                                icon: (
                                    <FontAwesomeIcon className={headerButtonsStyles.icon} fixedWidth icon={faFileAlt} />
                                ),
                                onClick: handleLinkClick,
                            },
                        ],
                    }}
                    shape="circle"
                    type="text"
                />
            ) : (
                <Tooltip
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                    placement="bottom"
                    title={getLocalizedString('HEADER__HELP_LINKS__DOCUMENTATION_LINK')}
                >
                    {documentationLink ? (
                        <a href={documentationLink} onClick={handleLinkClick} rel="noreferrer" target="_blank">
                            <Button icon={icon} shape="circle" type="text" />
                        </a>
                    ) : (
                        videoLink && <Button icon={icon} onClick={handleVideoClick} type="text" />
                    )}
                </Tooltip>
            )}
        </>
    );
}
