import {useMemo} from 'react';

import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {GoogleStatisticsType} from '../../../../../../service/google/google-type';
import {TipType} from '../../tips/tips-type';

export function useFinishStepTips(statistics?: GoogleStatisticsType): {tips: Array<TipType>} {
    const {localeName} = useLocale();
    const {companyName} = useDomainConfig();
    const documentationLinks = useDocumentationLinks();

    const tips = useMemo(() => {
        if (!statistics) {
            return [];
        }

        const tipsArray: Array<TipType> = [];

        if (statistics.verificationStatistics.mass > 0) {
            tipsArray.push({
                title: 'GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION' as const,
                text: <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__MASS_CONFIRMATION__DESCRIPTION" />,
                link: {href: documentationLinks.google?.massConfirmation},
            });
        }

        if (statistics.needAction > 0) {
            tipsArray.push({
                title: 'GOOGLE_SYNC__FINISH_STEP__STATUS__ACTION_REQUIRED' as const,
                text: <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__ACTION_REQUIRED__DESCRIPTION" />,
                link: {href: documentationLinks.google?.confirmationTypes},
            });
        }

        if (statistics.waitRights > 0) {
            tipsArray.push({
                title: 'GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS' as const,
                text: <Locale stringKey="GOOGLE_SYNC__FINISH_STEP__STATUS__AWAITING_RIGHTS__DESCRIPTION" />,
                link: {href: documentationLinks.google?.requestRights},
            });
        }

        if (statistics.synced > 0) {
            tipsArray.push({
                title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED' as const,
                text: (
                    <Locale
                        stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SYNCHRONIZED__DESCRIPTION"
                        valueMap={{companyName}}
                    />
                ),
            });
        }

        if (statistics.sent > 0) {
            tipsArray.push({
                title: 'GOOGLE_SYNC__PROCESSING_STEP__STATUS__SENT' as const,
                text: <Locale stringKey="GOOGLE_SYNC__PROCESSING_STEP__STATUS__SENT__DESCRIPTION" />,
            });
        }

        return tipsArray;
    }, [companyName, documentationLinks, localeName, statistics]);

    return {tips};
}
