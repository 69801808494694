import {PropsWithChildren} from 'react';

import * as styles from './media.scss';

type PropsType = {
    previewSrc?: string;
};

export function MediaCard(props: PropsWithChildren<PropsType>): JSX.Element {
    const {previewSrc, children} = props;

    return (
        <div className={styles.PostMedia_card}>
            {previewSrc && <img alt="" className={styles.PostMedia_cardPreviewImage} src={previewSrc} />}
            {children}
        </div>
    );
}
