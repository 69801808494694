import {Space} from 'antd';
import {useContext} from 'react';

import {PageHeader as OriginalPageHeader} from '../../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {classNames} from '../../../../../../util/css';
import {GoogleAccountSyncContext} from '../../google-account-sync-context';

import * as styles from './page-header.scss';

type PropsType = {
    titleLangKey: LangKeyType;
    className?: string;
};

export function PageHeader(props: PropsType): JSX.Element {
    const {titleLangKey, className} = props;

    const {headerButtons} = useContext(GoogleAccountSyncContext);

    return (
        <OriginalPageHeader className={classNames(styles.PageHeader, className)}>
            <Locale stringKey={titleLangKey} />

            <Space className={styles.PageHeader_buttons} size="small">
                {headerButtons}
            </Space>
        </OriginalPageHeader>
    );
}
