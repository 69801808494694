import {getCookie} from '../../util/cookie';

import {CsrfHeadersType} from './api-type';

const baseUrl = '';

export const rootApiUrl: string = baseUrl + '/op/api';

export const responseTemplatesSuggestionsUrl = '/v1/response_templates/suggestions/';

export const mainApiHeaders = {
    'content-type': 'application/json',
    'accept': 'application/json',
};

export function getCsrfHeaders(): CsrfHeadersType {
    const csrfToken = getCookie('csrftoken');

    if (csrfToken === null) {
        throw new Error("[ERROR]: user doesn' have CSRF token in cookies");
    }

    return {'x-csrftoken': csrfToken};
}
