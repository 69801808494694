import {faCircleQuestion} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMutation} from '@tanstack/react-query';
import {Alert, Descriptions, Input, Modal, Space} from 'antd';

import {AntdErrorMessage} from '../../../../../../../../component/antd-error-message/antd-error-message';
import {Text} from '../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../../provider/modal/modal-hook';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {postGoogleVerificationComplete} from '../../../../../../../../service/google/confirmation/google-confirmation-api';
import {
    GoogleConfirmationCompanyType,
    GoogleVerificationCompleteBodyType,
    GoogleVerificationOptionResponseType,
} from '../../../../../../../../service/google/confirmation/google-confirmation-type';
import {Form} from '../../../../../../../../typings/antd';
import {ApiError} from '../../../../../../../../util/error';
import {CompanyListItem} from '../../../../common/company-list-item/company-list-item';
import {getMutationErrors, useVerificationMethodsConfig} from '../code-request-modal/code-request-modal-helper';

import {codeFieldRule} from './code-enter-modal-helper';
import * as styles from './code-enter-modal.scss';

type CodeEnterFormType = GoogleVerificationCompleteBodyType;

type PropsType = {
    confirmation: Omit<GoogleConfirmationCompanyType, 'confirmation'> & {
        confirmation: GoogleVerificationOptionResponseType | GoogleConfirmationCompanyType['confirmation'];
    };
    open: boolean;
    repeat?: boolean;
    onCancel: () => void;
    onOk: () => void;
};

// eslint-disable-next-line complexity
export function CodeEnterModal(props: PropsType): JSX.Element | null {
    const {
        confirmation: {company, confirmation, location},
        open,
        repeat,
        onCancel,
        onOk,
    } = props;

    const verificationMethodsConfig = useVerificationMethodsConfig();
    const [form] = Form.useForm<CodeEnterFormType>();
    const {requiredFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();
    const {modal} = useModal();

    const {mutate, error, isLoading} = useMutation({
        mutationFn: () =>
            postGoogleVerificationComplete(company.pk, {
                pin: form.getFieldValue('pin').replace(/\D/g, ''),
                verificationId: confirmation && 'verificationId' in confirmation ? confirmation.verificationId : '',
            }),
        onSuccess: () => {
            onOk();

            modal.success({
                title: <Locale stringKey="GOOGLE_SYNC__CODE_ENTER__SUCCESS__TITLE" />,
                content: (
                    <Locale
                        stringKey="GOOGLE_SYNC__CODE_ENTER__SUCCESS__MESSAGE"
                        valueMap={{name: company.name, address: company.address}}
                    />
                ),
                cancelText: null,
            });
        },
    });

    async function handleOk() {
        try {
            await form.validateFields();
        } catch {
            return;
        }

        await mutate();
    }

    if (!confirmation) {
        return null;
    }

    return (
        <Modal
            cancelText={<Locale stringKey="POPUP__BUTTON__CANCEL" />}
            destroyOnClose
            okButtonProps={{disabled: isLoading}}
            okText={<Locale stringKey="POPUP__BUTTON__CONFIRM" />}
            onCancel={onCancel}
            onOk={handleOk}
            open={open}
            title={<Locale stringKey="GOOGLE_SYNC__CODE_ENTER__TITLE" />}
        >
            {location && location.locationId && (
                <CompanyListItem
                    className={styles.CodeEnterModal_card}
                    company={{
                        ...location,
                        originId: location.locationId,
                        mapsUrl: location.linkToMap,
                    }}
                    showStatus={false}
                />
            )}

            <Space direction="vertical" size={4}>
                <Text bolder stringKey="GOOGLE_SYNC__CODE_ENTER__SUBTITLE" />

                <Text lighter stringKey="GOOGLE_SYNC__CODE_ENTER__DESCRIPTION" />
            </Space>

            <Descriptions className={styles.CodeEnterModal_descriptions} colon={false} column={2} layout="vertical">
                <Descriptions.Item label={<Text secondary small stringKey="GOOGLE_SYNC__CODE_ENTER__METHOD" />}>
                    {confirmation.method && verificationMethodsConfig[confirmation.method]?.title && (
                        <Locale
                            stringKey={
                                verificationMethodsConfig[confirmation.method].enterTitle ??
                                verificationMethodsConfig[confirmation.method].title
                            }
                        />
                    )}
                </Descriptions.Item>

                <Descriptions.Item label={<Text secondary small stringKey="GOOGLE_SYNC__CODE_ENTER__DATE" />}>
                    {confirmation.createdAt}
                </Descriptions.Item>

                {confirmation.detail && confirmation.method && (
                    <Descriptions.Item
                        label={
                            <Text secondary small stringKey={verificationMethodsConfig[confirmation.method].label} />
                        }
                        span={2}
                    >
                        <Text>{confirmation.detail}</Text>
                    </Descriptions.Item>
                )}
            </Descriptions>

            <Form className={styles.CodeEnterModal_form} form={form} layout="vertical">
                <Form.Item<CodeEnterFormType>
                    label={<Text lighter stringKey="GOOGLE_SYNC__CODE_ENTER__CODE__LABEL" />}
                    name="pin"
                    rules={[requiredFieldRule, codeFieldRule(getLocalizedString)]}
                >
                    <Input className={styles.CodeEnterModal_inputNumber} placeholder="_ _ _ _ _ _" />
                </Form.Item>
            </Form>

            {error instanceof ApiError && <AntdErrorMessage>{getMutationErrors(error)}</AntdErrorMessage>}

            {repeat && (
                <Alert
                    className={styles.CodeEnterModal_alert}
                    icon={<FontAwesomeIcon icon={faCircleQuestion} />}
                    message={<Text stringKey="GOOGLE_SYNC__CODE_ENTER__ALERT" />}
                    showIcon
                    type="info"
                />
            )}
        </Modal>
    );
}
