import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Space} from 'antd';

import {Locale} from '../../../../../../../provider/locale/localization';
import {FakesChangeMutateType} from '../../../../../../../service/fakes/fakes-change';
import {FakesResultType} from '../../../../../../../service/fakes/fakes-results';
import {FakeInternalStatusEnum} from '../../../../../../../service/fakes/fakes-type';
import * as styles from '../actions-column.scss';

type PropsType = Pick<FakesResultType, 'isDuplicate'> & {
    mutate: FakesChangeMutateType;
};

export function ActionsColumnNew(props: PropsType): JSX.Element {
    const {isDuplicate, mutate} = props;

    const menuItems = [
        {
            key: 'client',
            label: <Locale stringKey="FAKES__RESULTS__BUTTON__MY_LOCATION" />,
            onClick: () => mutate(FakeInternalStatusEnum.ClientLocation),
        },
        {
            key: 'not-client',
            label: <Locale stringKey="FAKES__RESULTS__BUTTON__NOT_MY_LOCATION" />,
            onClick: () => mutate(FakeInternalStatusEnum.NotClientLocation),
        },
    ];

    return (
        <Space className={styles.ActionColumn} direction="vertical" size={12}>
            <Button
                onClick={() => {
                    mutate(FakeInternalStatusEnum.SendClaim);
                }}
                type="primary"
            >
                <Locale
                    stringKey={
                        isDuplicate ? 'FAKES__RESULTS__BUTTON__REMOVE_DUPLICATE' : 'FAKES__RESULTS__BUTTON__REMOVE_FAKE'
                    }
                />
            </Button>

            <Dropdown menu={{items: menuItems}}>
                <Button>
                    <Space>
                        <Locale
                            stringKey={
                                isDuplicate
                                    ? 'FAKES__RESULTS__BUTTON__NOT_A_DUPLICATE'
                                    : 'FAKES__RESULTS__BUTTON__NOT_A_FAKE'
                            }
                        />

                        <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    </Space>
                </Button>
            </Dropdown>
        </Space>
    );
}
