import {CompanySelectAdditionalDataColumnPropsType} from '../../../../../layout/company-popup-v2/items/company-select-item-type';
import {
    StoriesCompanySelectorCompanyType,
    YandexSelectorCatalogStatusEnum,
} from '../../../../../service/yandex-stories/yandex-stories-type';
import {classNames} from '../../../../../util/css';
import {ProvidersEnum, ProvidersIdsEnum} from '../../../../../util/type';
import {CatalogInfo} from '../../../../catalog/catalog-info';

import * as styles from './yandex-stories-select-companies-step.scss';

export function YandexStoriesCompaniesSelectorAdditionalContent(
    props: CompanySelectAdditionalDataColumnPropsType<StoriesCompanySelectorCompanyType>
): JSX.Element {
    const {company} = props;

    const yandexStoriesAvailableForCompany = company.catalogs.some(
        ({status, id}) => id === ProvidersIdsEnum.yandex && status === YandexSelectorCatalogStatusEnum.Active
    );

    return (
        <div className={styles.YandexStoriesSelectCompaniesStep_companyAdditionalInfoWrapper}>
            <CatalogInfo
                className={classNames({
                    [styles.YandexStoriesSelectCompaniesStep_companyAdditionalInfo__catalogDisabled]:
                        !yandexStoriesAvailableForCompany,
                })}
                hideName
                provider={ProvidersEnum.yandex}
            />
        </div>
    );
}
