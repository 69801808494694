import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space} from 'antd';
import {Dispatch, ReactElement, SetStateAction} from 'react';

import {classNames} from '../../../../util/css';
import {OnboardingStepEnum} from '../onboarding-const';

import * as styles from './step.scss';

type PropsType = {
    title: ReactElement;
    openStep: OnboardingStepEnum;
    stepIndex: OnboardingStepEnum;
    isOnboardingCompleted: boolean;
    setDemoOnboardingStep: Dispatch<SetStateAction<OnboardingStepEnum>>;
    isDemoOnboarding: boolean;
};

export function EmptyOnboardingStep(props: PropsType): ReactElement {
    const {title, stepIndex, openStep, isOnboardingCompleted, setDemoOnboardingStep, isDemoOnboarding} = props;

    const stepNumberClassNames = classNames(styles.Step_stepNumber, {
        [styles.Step_disabledStepNumber]: openStep <= stepIndex,
    });

    const emptyOnboardingStepClassNames = classNames(styles.Step, {
        [styles.Step_isEmptyDemoStep]: isDemoOnboarding,
    });

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            className={emptyOnboardingStepClassNames}
            onClick={() => {
                setDemoOnboardingStep(stepIndex);
            }}
        >
            {isOnboardingCompleted || openStep > stepIndex ? (
                <FontAwesomeIcon className={stepNumberClassNames} icon={faCircleCheck} />
            ) : (
                <div className={stepNumberClassNames}>{stepIndex}</div>
            )}

            <Space className={styles.Step_wrapper} direction="vertical" size={12}>
                {title}
            </Space>
        </div>
    );
}
