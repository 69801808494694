import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Modal, Select} from 'antd';
import {SyntheticEvent, UIEvent, useCallback, useMemo, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Empty} from '../../../../layout/empty/empty';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useDebouncedValue} from '../../../../service/debounce-hook/debounce-hook';
import {TagDataType, TagGroupType, useInfiniteTags, useTagGroupList} from '../../../../service/reviews/reviews-tags';
import {classNames} from '../../../../util/css';
import {onScrollInfiniteScrollContainer} from '../../../../util/infinite-scroll';

import {searchIcon} from './tags-popup-helper';
import * as styles from './tags-popup.scss';

const {Option} = Select;

type PropsType = {
    className?: string;
    isCreating: boolean;
    isOpen: boolean;
    onClose: () => void;
    onApply: (tagList: Array<TagDataType>) => void;
};

export function TagsPopup(props: PropsType): JSX.Element {
    const {className, onClose, isOpen, isCreating, onApply} = props;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedTagGroupId, setSelectedTagGroupId] = useState<number>(0);
    const [selectedItemList, setSelectedItemList] = useState<Array<TagDataType>>([]);

    const {getLocalizedString} = useLocale();

    const {companyName} = useDomainConfig();

    const {data: tagGroupListResult, isFetching: tagGroupListIsInProgress} = useTagGroupList();
    const debouncedSearch = useDebouncedValue(searchFilter, '');

    const {
        data,
        refetch: reload,
        isFetching: allTagsListIsInProgress,
        hasNextPage,
        fetchNextPage,
    } = useInfiniteTags({q: debouncedSearch, withoutRg: true, folderIds: selectedTagGroupId});

    const handleClose = useCallback(() => {
        setSelectedItemList([]);
        onClose();
    }, [onClose]);

    const tagGroupList: Array<TagGroupType> = useMemo(() => tagGroupListResult || [], [tagGroupListResult]);
    const tagList = data?.pages.flatMap((element) => element.results) || [];

    const renderListItem = useCallback(
        (allCompanyItem: TagDataType): JSX.Element => {
            const {id, title} = allCompanyItem;

            const isSelected = selectedItemList.some((selectedItem: TagDataType): boolean => selectedItem.id === id);

            const buttonClassName = classNames(styles.TagsPopup_listItemButton, {
                [styles.TagsPopup_listItemButton__selected]: isSelected,
            });

            return (
                <li className={styles.TagsPopup_listItem} key={id}>
                    <button
                        className={buttonClassName}
                        onClick={() => {
                            const newSelectedItemList = isSelected
                                ? selectedItemList.filter(
                                      (selectedItem: TagDataType): boolean => selectedItem.id !== id
                                  )
                                : [...selectedItemList, allCompanyItem];

                            setSelectedItemList(newSelectedItemList);
                        }}
                        type="button"
                    >
                        {isSelected && <FontAwesomeIcon className={styles.TagsPopup_listItemTick} icon={faCheck} />}

                        <div className={styles.TagsPopup_listItemContent}>
                            <h4 className={styles.TagsPopup_listItemHeader}>{title}</h4>
                        </div>
                    </button>
                </li>
            );
        },
        [selectedItemList, setSelectedItemList]
    );

    function handleSubmit() {
        track(
            AnalyticsTarget.ReviewsManagement.Reviews.AddTag,
            selectedItemList.map((item: TagDataType) => item.id)
        );

        onApply(selectedItemList);
    }

    function onContainerScroll(event: UIEvent) {
        if (!allTagsListIsInProgress && hasNextPage) {
            onScrollInfiniteScrollContainer(event, () => fetchNextPage());
        }
    }

    return (
        <Modal
            className={classNames(styles.TagsPopup, className)}
            footer={
                <div className={styles.TagsPopup_footer}>
                    <Button className={styles.TagsPopup_footerButton} key="cancel" onClick={handleClose}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>
                    <Button
                        className={styles.TagsPopup_footerButton}
                        key="apply"
                        loading={isCreating}
                        onClick={handleSubmit}
                        type="primary"
                    >
                        <Locale stringKey="TEXT__APPLY" />
                    </Button>
                </div>
            }
            onCancel={handleClose}
            open={isOpen}
            title={<Locale stringKey="REVIEWS__TAGS_POPUP__HEADER" />}
        >
            <div className={styles.TagsPopup_top}>
                <Input
                    onInput={(event: SyntheticEvent<HTMLInputElement>) => {
                        setSearchFilter(event.currentTarget.value.trim());
                    }}
                    placeholder={getLocalizedString('REVIEWS__TAGS_POPUP__SEARCH_FOR_TAGS')}
                    suffix={searchIcon}
                />

                <Select<number>
                    defaultValue={selectedTagGroupId}
                    disabled={tagGroupListIsInProgress}
                    filterOption={() => true}
                    loading={tagGroupListIsInProgress}
                    onChange={setSelectedTagGroupId}
                    placeholder={getLocalizedString('REVIEWS__TAGS_POPUP__ALL_GROUPS')}
                >
                    <Option key="all-groups" value={0}>
                        <Locale stringKey="REVIEWS__TAGS_POPUP__ALL_GROUPS" />
                    </Option>

                    {tagGroupList.map((tagGroupItem: TagGroupType): JSX.Element => {
                        const {id, title} = tagGroupItem;

                        return (
                            <Option key={id} value={id}>
                                {title}
                            </Option>
                        );
                    })}
                </Select>
            </div>

            <div className={styles.TagsPopup_scrollArea} onScroll={onContainerScroll}>
                {tagList.length > 0 && <ul className={styles.TagsPopup_list}>{tagList.map(renderListItem)}</ul>}

                {!allTagsListIsInProgress && tagList.length === 0 && (
                    <Empty mainText="EMPTY__THERE_IS_NOTHING_HERE_YET" secondaryText="EMPTY__TRY_CHANGING_FILTERS" />
                )}
                {allTagsListIsInProgress && (
                    <div className={styles.TagsPopup_spinner}>
                        <Spinner />
                    </div>
                )}
            </div>

            <div className={styles.TagsPopup_bottom}>
                <Button onClick={() => reload()}>
                    <Locale stringKey="REVIEWS__TAGS_POPUP__REFRESH" />
                </Button>
                {companyName === 'RocketData' ? (
                    <a
                        className={styles.TagsPopup_link}
                        href={appRoute.reviewsManagementTags.path}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <Locale stringKey="REVIEWS__TAGS_POPUP__GO_TO_TAG_SETUP" />
                    </a>
                ) : (
                    <NavigationLink className={styles.TagsPopup_link} to={appRoute.reviewsManagementTags.path}>
                        <Locale stringKey="REVIEWS__TAGS_POPUP__GO_TO_TAG_SETUP" />
                    </NavigationLink>
                )}
            </div>
        </Modal>
    );
}
