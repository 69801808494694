import {Button, Input, List, Modal, Radio, RadioChangeEvent} from 'antd';
import {ReactElement, useState} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {
    useResponsibleUsers,
    useResponsibleUserSelect,
} from '../../../../../../../../service/reviews/reviews-responsible-users';
import {classNames} from '../../../../../../../../util/css';
import {ReviewDataWithCatalogType} from '../../../review-list-type';

import * as styles from './responsible-user-modal.scss';

type PropsType = {
    onCancel: () => void;
    reviewId: number;
    refreshReview: (value: number) => void;
    initialSelectedUserId?: number;
    reviewData: ReviewDataWithCatalogType;
};

export function ResponsibleUserModal(props: PropsType): ReactElement {
    const {onCancel, reviewId, refreshReview, initialSelectedUserId, reviewData} = props;
    const [selectedUserId, setSelectedUserId] = useState<number | null>(initialSelectedUserId || null);
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const {getLocalizedString} = useLocale();

    const {mutateAsync, isLoading} = useResponsibleUserSelect(reviewId);
    const {data: userList, isLoading: isUserListLoading} = useResponsibleUsers(reviewData);

    function handleRadioGroupChange(event: RadioChangeEvent) {
        setSelectedUserId(event.target.value);
    }

    async function handleOk() {
        if (selectedUserId) {
            await mutateAsync(String(selectedUserId));
            await onCancel();
            return refreshReview(reviewId);
        }

        return onCancel();
    }

    const listData = userList?.results.filter(
        (user) => user.fullName?.includes(searchInputValue) || user.email?.includes(searchInputValue)
    );

    return (
        <Modal
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    <Locale stringKey="POPUP__BUTTON__CANCEL" />
                </Button>,
                <Button key="ok" loading={isLoading} onClick={handleOk} type="primary">
                    <Locale stringKey="TEXT__APPLY" />
                </Button>,
            ]}
            onCancel={onCancel}
            open
            title={<Locale stringKey="REVIEW__DROPDOWN_MENU__APPOINT_RESPONSIBLE" />}
        >
            <Input
                allowClear
                onChange={(event) => setSearchInputValue(event.target.value)}
                placeholder={getLocalizedString('USERS__TABLE__SEARCH__PLACEHOLDER')}
                value={searchInputValue}
            />
            {userList && (
                <Radio.Group
                    className={styles.ResponsibleUser_radioGroup}
                    onChange={handleRadioGroupChange}
                    value={selectedUserId}
                >
                    <List
                        bordered
                        dataSource={listData}
                        loading={isUserListLoading}
                        renderItem={(account) => (
                            <Radio
                                className={classNames(styles.Radio, {
                                    [styles.Radio__selected]: account.id === selectedUserId,
                                })}
                                value={account.id}
                            >
                                <Text bolder>{account.fullName}</Text>
                                <Text block secondary>
                                    {account.email}
                                </Text>
                            </Radio>
                        )}
                        size="small"
                        split={false}
                    />
                </Radio.Group>
            )}
        </Modal>
    );
}
