export const enPhotoToolTranslations = {
    PHOTO_TOOL__EMPTY_LIST: 'Empty list',

    PHOTO_TOOL__TAB_ALL_TITLE: 'All photos',
    PHOTO_TOOL__TAB_YOURS_TITLE: 'By owner',
    PHOTO_TOOL__TAB_USERS_TITLE: 'By customer',
    PHOTO_TOOL__TAB_SELECTED_TITLE: 'Selected',
    PHOTO_TOOL__TAB_VIDEO_TITLE: 'Video',

    PHOTO_TOOL__SELECT__BUTTON: 'Select',
    PHOTO_TOOL__SELECT__COUNT__TITLE: 'Selected',
    PHOTO_TOOL__SELECT__MODAL__COUNT__TITLE: 'Selected images: {count}',
    PHOTO_TOOL__SELECT__VIEW__BUTTON: 'Viewed',

    PHOTO_TOOL__FILTER__LABEL: 'Filters',
    PHOTO_TOOL__FILTER__EMPTY_TEXT: 'Filters are not applied',

    PHOTO_TOOL__FILTER_FORM__TITLE: 'Filters',
    PHOTO_TOOL__FILTER_FORM__INPUT__SOURCE__LABEL: 'Sources',
    PHOTO_TOOL__FILTER_FORM__INPUT__SOURCE__PLACEHOLDER: 'Select sources',
    PHOTO_TOOL__FILTER_FORM__INPUT__STATUS__LABEL: 'Statuses',
    PHOTO_TOOL__FILTER_FORM__INPUT__STATUS__PLACEHOLDER: 'Select statuses',
    PHOTO_TOOL__FILTER_FORM__INPUT__TAG__LABEL: 'Tags',
    PHOTO_TOOL__FILTER_FORM__INPUT__TAG__PLACEHOLDER: 'Select tags',
    PHOTO_TOOL__FILTER_FORM__INPUT__TIME_PERIOD__LABEL: 'Time period',

    PHOTO_TOOL__PHOTO_META__OWNER__LABEL: 'Uploaded by',
    PHOTO_TOOL__PHOTO_META__OWNER__TRUE: 'Owner',
    PHOTO_TOOL__PHOTO_META__OWNER__FALSE: 'Customer',
    PHOTO_TOOL__PHOTO_META__STATUS__REPORT: 'Report sent',
    PHOTO_TOOL__PHOTO_META__STATUS__REPORT_REJECTED: 'Report rejected',
    PHOTO_TOOL__PHOTO_META__STATUS__REMOVE_REQUEST: 'Request is being removed',
    PHOTO_TOOL__PHOTO_META__STATUS__REMOVED: 'Removed',
    PHOTO_TOOL__PHOTO_META__STATUS__NEW: 'New',
    PHOTO_TOOL__PHOTO_META__STATUS__VIEWED: 'Viewed',
    PHOTO_TOOL__PHOTO_META__STATUS__ERROR_REMOVING: 'Error removing',

    PHOTO_TOOL__DETAILS_META__STATUS__LABEL: 'Status',
    PHOTO_TOOL__DETAILS_META__DATE__LABEL: 'Date',
    PHOTO_TOOL__DETAILS_META__SOURCE__LABEL: 'Source',
    PHOTO_TOOL__DETAILS_META__ORIGINAL__LABEL: 'Original',
    PHOTO_TOOL__DETAILS_META__CARD__LABEL: 'Location',

    PHOTO_TOOL__REMOVE__SUCCESS__TITLE: 'Removal request sent',
    PHOTO_TOOL__REMOVE__CONFIRM__TITLE: 'Delete media content',
    PHOTO_TOOL__REMOVE__CONFIRM__TEXT: 'The media content will be removed from the service',
    PHOTO_TOOL__REMOVE__SUCCESS__TEXT: 'The media content will be removed shortly',
    PHOTO_TOOL__BULK_REMOVE__CONFIRM__TITLE: 'Delete materials',
    PHOTO_TOOL__BULK_REMOVE__CONFIRM__TEXT: 'Materials will be removed from the services',
    PHOTO_TOOL__BULK_REMOVE__SUCCESS__TEXT: 'Materials will be removed shortly',

    PHOTO_TOOL__SELECT_ALL__BUTTON: 'Select all media on this page',
    PHOTO_TOOL__SELECTED_LABEL: 'Selected on the page: ',
    PHOTO_TOOL__DESELECT_ALL_BUTTON: 'Deselect all displayed items',

    PHOTO_TOOL__REPORT_FORM__INPUT__MESSAGE__PLACEHOLDER: 'Enter report text here',
    PHOTO_TOOL__REPORT__SUCCESS__TITLE: 'Report sent',
    PHOTO_TOOL__REPORT__SUCCESS__TEXT:
        'The media content complaint was sent successfully. The material will be removed from the cards after consideration by the moderation of the services.',
    PHOTO_TOOL__REPORT__BULK_SUCCESS__TEXT:
        'The complaint about {count} photos/videos was sent successfully. Materials will be removed from the cards after consideration by the moderation of the services.',
    PHOTO_TOOL__REPORT__BULK_ERROR__EMPTY__TEXT: 'No suitable media selected for this action',
    PHOTO_TOOL__REPORT_BUTTON__PREVENT_REPORT__TITLE: 'Report is not available',
    PHOTO_TOOL__REPORT_BUTTON__PREVENT_REPORT__TEXT:
        'The service does not support report sending or less than 3 weeks has passed after the last submission.',

    PHOTO_TOOL__REPORT_TYPE__OFFENSIVE_CONTENT: 'Offensive or obscene content',
    PHOTO_TOOL__REPORT_TYPE__LAW_VIOLATION: 'Violation of the law',
    PHOTO_TOOL__REPORT_TYPE__PRIVACY_VIOLATION: 'privacy violation',
    PHOTO_TOOL__REPORT_TYPE__POOR_QUALITY: 'Poor image quality',
    PHOTO_TOOL__REPORT_TYPE__LOCATION_MATCHING_ERROR: 'Media content does not match the location',
    PHOTO_TOOL__REPORT_TYPE__OTHER: 'Specify another reason',
    PHOTO_TOOL__REPORT_TYPE__TIMELINE__OTHER__REASON: 'Another reason',

    PHOTO_TOOL__DETAILS_HISTORY__TITLE: 'History',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__CREATED: 'Photo uploaded',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REPORT_REQUEST: 'Report created',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REPORT_SENT: 'Report sent',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REPORT_FAIL: 'Failed to send a report',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REPORT_REJECTED: 'Report rejected',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REMOVE_REQUEST: 'Request to delete photo has been created',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REMOVED: 'Delete request succeeded',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REMOVE_FAIL: 'Failed to delete photo',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__CREATED__VIDEO: 'Video uploaded',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REMOVE_REQUEST__VIDEO: 'Request to delete video has been created',
    PHOTO_TOOL__DETAILS_HISTORY__STATUS__REMOVE_FAIL__VIDEO: 'Failed to delete video',

    PHOTO_TOOL__DETAILS_TAGS__TITLE: 'Tags',
    PHOTO_TOOL__DETAILS_TAGS__REMOVE_ERROR: 'Failed to remove tag',
    PHOTO_TOOL__DETAILS_TAGS__CREATE_ERROR: 'Failed to add tag',
    PHOTO_TOOL__DETAILS_TAGS__ADD_NEW__BUTTON: 'New tag',

    PHOTO_TOOL__LANDING_CALL_TO_ACTION__TITLE: 'Photo and Video Management Service for Google, Yandex, and 2GIS',
    PHOTO_TOOL__LANDING_CALL_TO_ACTION__DESCRIPTION:
        'Collect photos and videos of all locations in a single dashboard{break}and manage unattractive or mismatched content to your business',

    PHOTO_TOOL__LANDING__TOTAL__PHOTOS: 'Total photos',

    PHOTO_TOOL__ONBOARDING__STEP1__TITLE: 'Welcome to the photo management service! 👋',
    PHOTO_TOOL__ONBOARDING__STEP1__TEXT: 'In a single account, the service collects all photos from cards in Google',
    PHOTO_TOOL__ONBOARDING__STEP2__TITLE: 'Machine learning 🧠',
    PHOTO_TOOL__ONBOARDING__STEP2__TEXT: 'Using machine learning, it is possible to determine the accuracy of a photo.',
    PHOTO_TOOL__ONBOARDING__STEP3__TITLE: 'Convenient filtering 👍',
    PHOTO_TOOL__ONBOARDING__STEP3__TEXT:
        'Set photo filtering by sources, tags, effects, location and publication date.',
    PHOTO_TOOL__ONBOARDING__STEP4__TITLE: 'Removing incorrect photos 🧹',
    PHOTO_TOOL__ONBOARDING__STEP4__TEXT: 'mass send complaints to sites with requests to remove incorrect photos.',
    PHOTO_TOOL__ONBOARDING__STEP5__TITLE: 'Everything is ready to go!',
    PHOTO_TOOL__ONBOARDING__STEP5__TEXT:
        'Go to the main photo manager page to manage content{break} that is unattractive or irrelevant to your business.',

    PHOTO_TOOL__ANALYTICS: 'Analytics',
    PHOTO_TOOL__ANALYTICS_PHOTOS: 'Photo Analytics',
    PHOTO_TOOL__ANALYTICS_VIDEOS: 'Video Analytics',

    PHOTO_TOOL__ANALYTICS_TABLE__CELL__SOURCE: 'Platform',
    PHOTO_TOOL__ANALYTICS_TABLE__CELL__VIEWS: 'Views',
    PHOTO_TOOL__ANALYTICS_TABLE__CELL__COMPLAINTS: 'Complaints',
    PHOTO_TOOL__ANALYTICS_TABLE__CELL__DELETING: 'Deleting',
    PHOTO_TOOL__ANALYTICS_TABLE__CELL__REMOVED: 'Removed',

    PHOTO_TOOL__ANALYTICS_CHART__REMOVED_MEDIA: 'Removed',

    PHOTO_TOOL__ANALYTICS_TABLE__ROW__TOTAL: 'Total',
    PHOTO_TOOL__ANALYTICS_STATISTICS__TITLE: 'Statistics',
    PHOTO_TOOL__ANALYTICS_STATISTICS__TOTAL_PHOTOS: 'Total photos',
    PHOTO_TOOL__ANALYTICS_STATISTICS__TOTAL_VIDEOS: 'Total videos',
    PHOTO_TOOL__ANALYTICS_STATISTICS__DELETED_PHOTOS: 'Deleted photos',
    PHOTO_TOOL__ANALYTICS_STATISTICS__DELETED_VIDEOS: 'Deleted videos',

    PHOTO_TOOL__STATISTICS__CARD_TITLE: 'Views and appeals of materials',
    PHOTO_TOOL__STATISTICS__DOWNLOAD_REPORT: 'Download report',

    PHOTO_TOOL__STATISTICS__SENT_BY_EMAIL__TITLE: 'Request sent',
    PHOTO_TOOL__STATISTICS__SENT_BY_EMAIL__DESCRIPTION:
        'The requested report will be sent to your email address listed in your profile.',
    PHOTO_TOOL__STATISTICS__SENT_BY_EMAIL__ERROR_DESCRIPTION:
        'Error sending the report, please contact the support team',
};
