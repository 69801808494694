import {faGrid2Plus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Divider, Space, Tabs, Tag} from 'antd';
import {isNumber} from 'lodash';
import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';

import {Spinner} from '../../../../../layout/spinner/spinner';
import {useCatalogConfigForCompanyForm} from '../../../../../provider/catalogs/catalogs-hook';
import {CatalogConfigType} from '../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../typings/antd';

import {isCatalogAvailableInCountry} from './catalog-list-helper';
import {CatalogPopup} from './catalog-popup/catalog-popup';
import * as styles from './catalog-list.scss';

export type CatalogConfigWithIndexType = CatalogConfigType & {
    index: number; // needed for ant form array access
};

type RenderCatalogInfoType = {
    handleRemoveCatalog: (catalogId: number) => void;
};

type CommonPropsType = {
    title?: string;
    disabled?: boolean;
    catalogTabListHidden?: boolean;
    activeTabIndex: number | null;
    isCatalogVisible: (catalog: CatalogConfigType) => boolean;
    renderCatalog: (catalog: CatalogConfigWithIndexType, info: RenderCatalogInfoType) => JSX.Element;
    onCatalogAvailabilityChange: (availableCatalogIds: Array<number>) => void;
    transformCatalogTitle?: (catalogId: number, catalogTitle: string) => string;
    renderNoCatalogsPlaceholder?: (disabled: boolean) => JSX.Element;
    hint?: ReactElement;
};

type PropsType =
    | (CommonPropsType & {
          showWithoutConfiguration: true;
          initialCatalogs?: undefined;
          onAddCatalogs?: undefined;
      })
    | (CommonPropsType & {
          showWithoutConfiguration?: boolean;
          initialCatalogs?: Array<number>;
          onAddCatalogs: (catalogs: Array<CatalogConfigType>) => void;
      });

// eslint-disable-next-line complexity, max-statements
export function CatalogList(props: PropsType): JSX.Element {
    const {
        title,
        disabled,
        catalogTabListHidden: forceCatalogTabListHidden,
        showWithoutConfiguration,
        initialCatalogs,
        activeTabIndex,
        isCatalogVisible,
        renderCatalog,
        transformCatalogTitle,
        renderNoCatalogsPlaceholder,
        onAddCatalogs,
        onCatalogAvailabilityChange,
        hint,
    } = props;

    const country = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'country'>([
        CompanyKeyEnum.Address,
        'country',
    ]);
    const updateToken = Form.useWatch<CompanyKeyEnum.SubmitTimestamp, FormInstance<CompanyFormType>>([
        CompanyKeyEnum.SubmitTimestamp,
    ]);

    const catalogConfigHook = useCatalogConfigForCompanyForm();
    const catalogList: Array<CatalogConfigType> = useMemo(
        () =>
            (catalogConfigHook?.result || []).filter(isCatalogVisible).map((catalog) => ({
                ...catalog,
                disabled: !isCatalogAvailableInCountry(catalog, country?.id),
            })),
        [catalogConfigHook?.result, country, isCatalogVisible]
    );

    const [catalogs, setCatalogs] = useState<Array<number>>(initialCatalogs ?? []);
    const [catalogTabListHidden, setCatalogTabListHidden] = useState(forceCatalogTabListHidden);
    const [isCatalogPopupOpen, setIsCatalogPopupOpen] = useState<boolean>(false);
    const [activeTabKey, setActiveTabKey] = useState<number | undefined>();

    const unusedCatalogs = catalogList.filter(({catalogId}) => !catalogs.includes(catalogId));
    const unusedAndAvailableCatalogs = catalogList.filter(
        (catalog) => !catalog.disabled && !catalogs.includes(catalog.catalogId)
    );

    useEffect(() => {
        setCatalogTabListHidden(forceCatalogTabListHidden);
    }, [forceCatalogTabListHidden]);

    useEffect(() => {
        if (showWithoutConfiguration) {
            setCatalogs(catalogList.filter((catalog) => !catalog.disabled).map(({catalogId}) => catalogId));
        }
    }, [catalogList, showWithoutConfiguration]);

    useEffect(() => {
        if (isNumber(activeTabIndex) && catalogs[activeTabIndex] && activeTabKey !== catalogs[activeTabIndex]) {
            setActiveTabKey(catalogs[activeTabIndex]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateToken]);

    useEffect(() => {
        if (activeTabKey && !catalogs.includes(activeTabKey)) {
            setActiveTabKey(catalogs[0]);
        }
    }, [activeTabKey, catalogs]);

    useEffect(() => {
        if (!catalogConfigHook?.result?.length) {
            return;
        }

        const availableCatalogIds = catalogConfigHook.result
            .filter((catalog) => isCatalogAvailableInCountry(catalog, country?.id))
            .filter(isCatalogVisible)
            .map(({catalogId}) => catalogId);

        setCatalogs((oldCatalogs) => oldCatalogs.filter((id) => availableCatalogIds.includes(id)));
        onCatalogAvailabilityChange(availableCatalogIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogConfigHook.result, country]);

    const getCatalogById = useCallback(
        (catalogId: number) => catalogList.find((catalog) => catalog.catalogId === catalogId),
        [catalogList]
    );

    const getCatalogNameById = useCallback(
        (catalogId: number) => {
            const catalogName = getCatalogById(catalogId)?.label ?? '';

            return transformCatalogTitle ? transformCatalogTitle(catalogId, catalogName) : catalogName;
        },
        [getCatalogById, transformCatalogTitle]
    );

    const handleAddCatalogs = useCallback(
        (newCatalogs: Array<number>) => {
            setCatalogs([...new Set([...catalogs, ...newCatalogs])]);
            setIsCatalogPopupOpen(false);
            setCatalogTabListHidden(false);
            setActiveTabKey(newCatalogs[0]);
            onAddCatalogs?.(newCatalogs.map((id) => getCatalogById(id)).filter(Boolean));
        },
        [catalogs, getCatalogById, onAddCatalogs]
    );

    const handleRemoveCatalog = useCallback(
        (catalogId: number) => {
            setCatalogs(catalogs.filter((id) => id !== catalogId));

            const previousTabIndex = catalogs.indexOf(catalogId) - 1;
            const nextTabIndex = catalogs.indexOf(catalogId) + 1;
            const newTabIndex = previousTabIndex >= 0 ? previousTabIndex : nextTabIndex;

            if (newTabIndex < catalogs.length) {
                setActiveTabKey(catalogs[newTabIndex]);
            }
        },
        [catalogs]
    );

    const configureCatalogsButton = renderNoCatalogsPlaceholder?.(catalogs.length === 0) || (
        <Button
            className={styles.catalog_list__open_catalogs_button}
            disabled={disabled || unusedAndAvailableCatalogs.length === 0}
            icon={<FontAwesomeIcon className={styles.catalog_list__open_catalogs_icon} icon={faGrid2Plus} size="sm" />}
            onClick={() => setIsCatalogPopupOpen(true)}
        >
            <span>
                <Locale stringKey="COMPANY_FORM__CATALOG_LIST__CONFIGURE_CATALOGS_BUTTON" />
            </span>
        </Button>
    );

    if (!catalogConfigHook?.result) {
        return <Spinner />;
    }

    return (
        <>
            {title && (
                <Divider className={styles.catalog_list__divider} dashed orientation="left">
                    {title}
                    {!showWithoutConfiguration && catalogs.length > 0 && (
                        <Button
                            className={styles.catalog_list__hide_button}
                            onClick={() => setCatalogTabListHidden(!catalogTabListHidden)}
                            size="small"
                        >
                            <Locale stringKey={catalogTabListHidden ? 'COMPANY_FORM__SHOW' : 'COMPANY_FORM__HIDE'} />
                        </Button>
                    )}
                </Divider>
            )}

            {catalogs.length === 0 ? (
                <Space align="center" size="small">
                    {configureCatalogsButton}
                    {hint}
                </Space>
            ) : (
                <div className={styles.catalog_list__tabs}>
                    {catalogTabListHidden && (
                        <>
                            <div className={styles.catalog_list__tags}>
                                {catalogs.map((catalogId) => (
                                    <Tag key={catalogId}>{getCatalogNameById(catalogId)}</Tag>
                                ))}
                            </div>
                            <div>{configureCatalogsButton}</div>
                        </>
                    )}

                    <Tabs
                        activeKey={activeTabKey?.toString()}
                        hidden={catalogTabListHidden}
                        items={catalogs.map((catalogId, index) => {
                            const catalog = getCatalogById(catalogId);

                            return {
                                forceRender: true,
                                key: catalogId.toString(),
                                label: getCatalogNameById(catalogId),
                                children: catalog ? renderCatalog({index, ...catalog}, {handleRemoveCatalog}) : null,
                            };
                        })}
                        onChange={(activeKey) => setActiveTabKey(Number(activeKey))}
                        tabBarExtraContent={{
                            left:
                                showWithoutConfiguration || unusedAndAvailableCatalogs.length === 0 ? null : (
                                    <Button
                                        className={styles.catalog_list__add_catalog_button}
                                        icon={<FontAwesomeIcon icon={faGrid2Plus} />}
                                        onClick={() => setIsCatalogPopupOpen(true)}
                                    />
                                ),
                        }}
                    />
                </div>
            )}

            <CatalogPopup
                catalogs={unusedCatalogs}
                isOpen={isCatalogPopupOpen}
                onApply={handleAddCatalogs}
                onClose={() => setIsCatalogPopupOpen(false)}
            />
        </>
    );
}
