import {generatePath} from 'react-router';

import {appRoute} from '../../../../../../../app-route';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {NavigationLink} from '../../../../../../../layout/navigation-link/navigation-link';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../provider/locale/locale';
import {ReviewsScrapyCatalogType} from '../../../../../../../service/reviews/reviews-scrapy-catalogs';

import * as styles from './review-condition.scss';

type PropsType = {
    moderated?: boolean;
    isReviewDeleted?: boolean;
    isAnswerUnavailable?: boolean;
    hasCompanyComment: boolean;
    hasAccountError?: boolean;
    scrapyCatalogInfo?: ReviewsScrapyCatalogType;
    isNeedConfirmationStatus?: boolean;
};

// eslint-disable-next-line complexity
export function ReviewCondition(props: PropsType): JSX.Element {
    const {
        moderated,
        isReviewDeleted,
        isAnswerUnavailable,
        scrapyCatalogInfo,
        hasCompanyComment,
        hasAccountError,
        isNeedConfirmationStatus,
    } = props;

    const {companyName} = useDomainConfig();

    const isFromScrapyCatalog = Boolean(scrapyCatalogInfo);
    const sourceSettingsAccountLink = generatePath(appRoute.settingsSourceSettingsAccount.path, {
        id: scrapyCatalogInfo?.id || 0,
        groupId: scrapyCatalogInfo?.groupId || 0,
    });

    switch (true) {
        case isReviewDeleted:
            return (
                <p className={styles.ReviewCondition_label}>
                    <Locale stringKey="REVIEWS__REVIEW_DELETED" />
                </p>
            );
        case isNeedConfirmationStatus:
            return (
                <AdditionalInfo
                    rightNode={
                        <p className={styles.ReviewCondition_label}>
                            <Locale
                                stringKey="REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION__STATUS"
                                valueMap={{
                                    link: (
                                        <NavigationLink to={appRoute.sourcesAccountSyncGoogleConfirmations.path}>
                                            <Locale stringKey="REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION__STATUS__LINK" />
                                        </NavigationLink>
                                    ),
                                }}
                            />
                        </p>
                    }
                    title={<Locale stringKey="REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION" />}
                >
                    <Locale
                        stringKey="REVIEW__ANSWER__COMPANY_IS_NOT_CONFIRMATION__DESCRIPTION"
                        valueMap={{companyName}}
                    />
                </AdditionalInfo>
            );

        case !moderated:
            return (
                <AdditionalInfo
                    rightNode={
                        <p className={styles.ReviewCondition_label}>
                            <Locale stringKey="REVIEWS__REVIEW_NOT_CONFIRMED" />
                        </p>
                    }
                    title={<Locale stringKey="REVIEWS__REVIEW_NOT_CONFIRMED" />}
                >
                    <Locale stringKey="REVIEWS__REVIEW_NOT_CONFIRMED__DESCRIPTION" />
                </AdditionalInfo>
            );
        case hasAccountError:
            return (
                <AdditionalInfo
                    rightNode={
                        <p className={styles.ReviewCondition_label}>
                            <Locale stringKey="REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT" />
                            <NavigationLink to={sourceSettingsAccountLink}>
                                <Locale stringKey="REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__LINK" />
                            </NavigationLink>
                        </p>
                    }
                    title={<Locale stringKey="REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__POPOVER_TITLE" />}
                >
                    <Locale
                        stringKey="REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__POPOVER_TEXT"
                        valueMap={{companyName}}
                    />
                    <NavigationLink to={sourceSettingsAccountLink}>
                        <Locale stringKey="REVIEWS__REVIEW_HAS_ERROR_CONNECT_ACCOUNT__POPOVER_TEXT__LINK" />
                    </NavigationLink>
                </AdditionalInfo>
            );
        case isAnswerUnavailable && !isFromScrapyCatalog && !hasCompanyComment:
            return (
                <p className={styles.ReviewCondition_label}>
                    <Locale stringKey="REVIEWS__REVIEW_UNAVAILABLE_ANSWER" />
                </p>
            );
        case isFromScrapyCatalog && scrapyCatalogInfo?.canReviewReply && isAnswerUnavailable && !hasCompanyComment:
            return (
                <AdditionalInfo
                    rightNode={
                        <p className={styles.ReviewCondition_label}>
                            <Locale stringKey="REVIEWS__REVIEW_UNAVAILABLE_ANSWER__LINK_TEXT" />
                            &nbsp;
                            <NavigationLink key="myCompaniesLink" to={sourceSettingsAccountLink}>
                                <Locale stringKey="REVIEWS__REVIEW_UNAVAILABLE_ANSWER__LINK" />
                            </NavigationLink>
                        </p>
                    }
                    title={<Locale stringKey="REVIEWS__REVIEW_UNAVAILABLE_ANSWER__TOOLTIP_TITLE" />}
                >
                    <Locale stringKey="REVIEWS__REVIEW_UNAVAILABLE_ANSWER__TOOLTIP_TEXT" valueMap={{companyName}} />
                </AdditionalInfo>
            );
        default:
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
    }
}
