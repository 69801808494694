import {Dayjs} from 'dayjs';

export enum ReviewFormFieldNamesEnum {
    id = 'id',
    source = 'source',
    brand = 'brand',
    company = 'company',
    createdAt = 'createdAt',
    author = 'author',
    phone = 'phone',
    rating = 'rating',
    content = 'content',
    email = 'email',
    url = 'url',
}

export type ReviewFormFieldsType = {
    [ReviewFormFieldNamesEnum.id]?: number;
    [ReviewFormFieldNamesEnum.source]: number;
    [ReviewFormFieldNamesEnum.brand]: number;
    [ReviewFormFieldNamesEnum.company]: string | undefined;
    [ReviewFormFieldNamesEnum.createdAt]: Dayjs;
    [ReviewFormFieldNamesEnum.author]: string | undefined;
    [ReviewFormFieldNamesEnum.phone]: string | undefined;
    [ReviewFormFieldNamesEnum.rating]: number | undefined;
    [ReviewFormFieldNamesEnum.content]: string | undefined;
    [ReviewFormFieldNamesEnum.email]: string | undefined;
    [ReviewFormFieldNamesEnum.url]: string | undefined;
};

export enum ReviewFormModeEnum {
    create = 'create',
    edit = 'edit',
}
