import {useCallback, useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {createReviewUserTopic} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-api';
import {emptyReviewUserTopic} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-const';
import {ReviewUserTopicType} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-type';
import {ErrorWithStatus, getMessageFromStatusError, isErrorHasStatusMessage} from '../../../../../util/error';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {TopicManagement} from '../../components/topic-management/topic-management';
import {topicsErrorMessagesMap} from '../../reviews-analysis-const';

export function TopicCreatePage(): JSX.Element {
    const {reviewsAnalysisDashboard: dashboardRoute, reviewsAnalysisKeywordsAndTopics: tagsAndTopicsRoute} = appRoute;

    const {getLocalizedString} = useLocale();
    const {pushUrl} = useUrl();
    const returnRedirectPath = tagsAndTopicsRoute.path;

    const [isInProgress, setIsInProgress] = useState(false);

    const {snackbar} = useSnackbar();

    const onSubmit = useCallback(
        async (userTopic: Omit<ReviewUserTopicType, 'id'>) => {
            setIsInProgress(true);

            try {
                await createReviewUserTopic(userTopic);

                snackbar.success({
                    message: <Locale stringKey="REVIEWS_ANALYSIS__FORM__CREATE__MESSAGE_TITLE" />,
                    description: <Locale stringKey="REVIEWS_ANALYSIS__FORM__CREATE__MESSAGE_TEXT" />,
                });
                pushUrl(returnRedirectPath);
            } catch (error) {
                if (isErrorHasStatusMessage<ErrorWithStatus>(error)) {
                    const errorCode = getMessageFromStatusError(error);
                    const errorFromMap = topicsErrorMessagesMap.find((message) => message.code === errorCode);

                    snackbar.error(errorFromMap ? errorFromMap.message : errorCode);
                } else {
                    snackbar.error(<Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />);
                }
            } finally {
                setIsInProgress(false);
            }
        },
        [pushUrl, returnRedirectPath, snackbar]
    );

    return (
        <Page>
            <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC_CREATE')} />

            <BreadCrumbs
                list={[
                    {
                        path: dashboardRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                    },
                    {
                        path: tagsAndTopicsRoute.path,
                        titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__TOPICS',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__REVIEWS_ANALYSIS__TOPIC_CREATE" />
            </PageHeader>

            <TopicManagement isSubmitting={isInProgress} onSubmit={onSubmit} userTopic={emptyReviewUserTopic} />
        </Page>
    );
}
