export const enLegalAgreementTranslations = {
    LEGAL_AGREEMENT__PERSONAL: 'Personal contract',
    LEGAL_AGREEMENT__PERSONAL__DESCRIPTION: 'I agree with the personal data {privacyPolicyLink}.',
    LEGAL_AGREEMENT__PERSONAL__ADDITIONAL_INFO:
        'Choose this type of contract if you have already entered into a personal contract with {companyName}.',
    LEGAL_AGREEMENT__PUBLIC: 'Public contract',
    LEGAL_AGREEMENT__PUBLIC__DESCRIPTION:
        'I agree with the personal data {privacyPolicyLink} and the terms of the {publicAgreementLink}.',
    LEGAL_AGREEMENT__PRIVACY_POLICY_LINK: 'privacy statement',
    LEGAL_AGREEMENT__PUBLIC_AGREEMENT_LINK: 'public contract',
};
