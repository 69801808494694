import {WorkDayTimeRangeType} from '../../workdays-form-item-type';

export function getDisabledHours(previousBreakTime: WorkDayTimeRangeType | null): () => Array<number> {
    return function disabledHours() {
        if (!previousBreakTime || !previousBreakTime.timeTo) {
            return [];
        }

        const previousHour = previousBreakTime.timeTo.hour;
        const previousMinute = previousBreakTime.timeTo.minute;
        const lastPossibleMinute = 59;

        const hours = Array.from({length: 24}, (_item, index) => index);

        return hours.filter(
            (hour) => hour < previousHour || (hour === previousHour && previousMinute === lastPossibleMinute)
        );
    };
}

export function getDisabledMinutes(previousBreakTime: WorkDayTimeRangeType | null): (hour: number) => Array<number> {
    return function disabledMinutes(hour: number): Array<number> {
        if (!previousBreakTime || !previousBreakTime.timeTo) {
            return [];
        }

        const previousHour = previousBreakTime.timeTo.hour;
        const previousMinute = previousBreakTime.timeTo.minute;

        const minutes = Array.from({length: 60}, (_item, index) => index);

        return minutes.filter((minute) => (hour === previousHour && minute <= previousMinute) || hour < previousHour);
    };
}
