import {Col, Row} from 'antd';

import {TechnicalSupportFilterType} from '../../../../../../service/technical-support/technical-support-type';

import {TechnicalSupportFilterActive} from './technical-support-filter-active';
import {TechnicalSupportFilterControls} from './technical-support-filter-controls';
import * as styles from './technical-support-filter.scss';

type PropsType = {
    filter: TechnicalSupportFilterType;
    updateFilter: (values: Partial<TechnicalSupportFilterType>) => void;
    resetFilter: () => void;
};

export function TechnicalSupportFilter(props: PropsType): JSX.Element {
    const {filter, updateFilter, resetFilter} = props;

    return (
        <Row align="middle" className={styles.TechnicalSupportFilterRow} justify="space-between" wrap={false}>
            <Col className={styles.TechnicalSupportFilterRow_activeFilterTags}>
                <TechnicalSupportFilterActive filter={filter} updateFilter={updateFilter} />
            </Col>

            <Col>
                <TechnicalSupportFilterControls filter={filter} resetFilter={resetFilter} updateFilter={updateFilter} />
            </Col>
        </Row>
    );
}
