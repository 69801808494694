import {Space, Switch} from 'antd';

import {Text} from '../../../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {CompaniesValidationOptionsType} from '../../../../../../../../service/companies-import/companies-import';
import {MutateFeedAutoUpdateType} from '../../../../../../../../service/companies-import/companies-import-list-edit';
import {classNames} from '../../../../../../../../util/css';
import {FEEDS_STATUS_TO_TAGS} from '../../../../management/table/feeds-table-const';
import {FeedsTableStatusEnum} from '../../../../management/table/feeds-table-type';

import {FeedResultsSourceLink} from './link/feed-results-source-link';
import * as styles from './feed-results-source.scss';

type PropsType = {
    source: CompaniesValidationOptionsType;
    changeAutoUpdate?: (parameters: MutateFeedAutoUpdateType) => void;
    periodicUpdate?: boolean;
    id?: number;
    status?: FeedsTableStatusEnum;
};

export function FeedResultsSource(props: PropsType): JSX.Element {
    const {source, changeAutoUpdate, periodicUpdate, status, id} = props;

    const {feedTypeLabels} = useDomainConfig();

    return (
        <div className={styles.FeedResultsSource_options}>
            <div className={styles.FeedResultsSource_option}>
                <Text block secondary small stringKey="FEED__RESULTS__SOURCE__LABEL" />

                {source.link ? (
                    <a href={source.link} rel="noreferrer" target="_blank">
                        <FeedResultsSourceLink source={source} />
                    </a>
                ) : (
                    <FeedResultsSourceLink source={source} />
                )}
            </div>
            <div className={styles.FeedResultsSource_itemWrapper}>
                {feedTypeLabels && (
                    <div className={classNames(styles.FeedResultsSource_item, styles.FeedResultsSource_formatItem)}>
                        <Text block secondary small stringKey="FEED__FORM__FORMAT__LABEL" />

                        <span>{feedTypeLabels[source.importType]}</span>
                    </div>
                )}
                {changeAutoUpdate && id && (
                    <div className={styles.FeedResultsSource_item}>
                        <Text block secondary small stringKey="FEED__FORM__UPDATE__LABEL" />
                        <Space>
                            <Switch
                                checked={periodicUpdate}
                                onChange={() => {
                                    changeAutoUpdate({feedId: id, value: !periodicUpdate});
                                }}
                            />
                            <Text
                                stringKey={
                                    periodicUpdate
                                        ? 'FEED__MANAGEMENT__DRAWER__ON_AUTOUPDATE'
                                        : 'FEED__MANAGEMENT__DRAWER__OFF_AUTOUPDATE'
                                }
                            />
                        </Space>
                    </div>
                )}
                {status && (
                    <div className={styles.FeedResultsSource_item}>
                        <Text block secondary small stringKey="FEED__FORM__STATUS__LABEL" />

                        <span>{FEEDS_STATUS_TO_TAGS[status]}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
