import * as cssVariableStyles from '../../css/var-export.scss';

export const defaultRatingStarLineData = {
    color: cssVariableStyles.danger60,
    columnGap: 3,
    rating: 0,
    starWidth: 14,
    starHeight: 14,
    starCount: 5,
};
