import {Select} from 'antd';

import {PageCardDivider} from '../../../../../../component/page-card-divider/page-card-divider';
import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabIndex} from '../../catalog-list/catalog-list-helper';
import {CatalogTab} from '../../catalog-list/catalog-tab';

import {CompanyNamesList} from './company-names-list/company-names-list';
import * as styles from './names-widget.scss';

type PropsType = {
    name: string;
    hasInitialNames: boolean;
    initialCatalogs: Array<number>;
};

export function NamesWidget(props: PropsType): JSX.Element {
    const {name, hasInitialNames, initialCatalogs} = props;
    const {getLocalizedString} = useLocale();

    return (
        <>
            <CompanyNamesList
                basePath={[CompanyKeyEnum.Names]}
                hasInitialNames={hasInitialNames}
                name={CompanyKeyEnum.Names}
            />

            <Form.Item<CompanyFormType> noStyle shouldUpdate={(_previousValues, _nextValues, info) => !info.source}>
                {({getFieldValue, setFieldsValue, getFieldsError}) => (
                    <CatalogList
                        activeTabIndex={getErrorTabIndex(getFieldsError, CompanyKeyEnum.ExtraNames)}
                        disabled={!getFieldValue([CompanyKeyEnum.Names, 0, 'name'])}
                        initialCatalogs={initialCatalogs}
                        isCatalogVisible={() => true}
                        onAddCatalogs={(catalogs: Array<CatalogConfigType>) => {
                            setFieldsValue({
                                extraNames: [
                                    ...getFieldValue(CompanyKeyEnum.ExtraNames),
                                    ...catalogs.map(({catalogId, namesLanguages}) => ({
                                        catalogId,
                                        names:
                                            namesLanguages.length > 0
                                                ? (getFieldValue(CompanyKeyEnum.Names) || []).filter(
                                                      ({lang}) => lang && namesLanguages.includes(lang)
                                                  )
                                                : getFieldValue(CompanyKeyEnum.Names),
                                    })),
                                ],
                            });
                        }}
                        onCatalogAvailabilityChange={(availableCatalogIds: Array<number>) => {
                            setFieldsValue({
                                extraNames: getFieldValue(CompanyKeyEnum.ExtraNames).filter(({catalogId}) =>
                                    availableCatalogIds.includes(catalogId)
                                ),
                            });
                        }}
                        renderCatalog={(catalog: CatalogConfigWithIndexType, {handleRemoveCatalog}) => {
                            return (
                                <Form.List name={CompanyKeyEnum.ExtraNames}>
                                    {(_fields, {remove}) => (
                                        <CatalogTab
                                            canOpenRemovalModal
                                            catalog={catalog}
                                            modalFieldName={name}
                                            onRemove={(index: number) => {
                                                remove(index);
                                                handleRemoveCatalog(catalog.catalogId);
                                            }}
                                        >
                                            {({openRemovalModal}) => (
                                                <CompanyNamesList
                                                    basePath={[CompanyKeyEnum.ExtraNames, catalog.index, 'names']}
                                                    catalog={catalog}
                                                    hasInitialNames={Boolean(
                                                        getFieldValue([
                                                            CompanyKeyEnum.ExtraNames,
                                                            catalog.index,
                                                            'names',
                                                        ])
                                                    )}
                                                    name={[catalog.index, 'names']}
                                                    openRemovalModal={openRemovalModal}
                                                />
                                            )}
                                        </CatalogTab>
                                    )}
                                </Form.List>
                            );
                        }}
                        title={getLocalizedString('COMPANY_FORM__NAMES__CATALOGS_HEADER')}
                    />
                )}
            </Form.Item>

            <PageCardDivider />

            <Form.Item<CompanyFormType>
                label={
                    <AdditionalInfo
                        leftNode={<Locale stringKey="COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__LABEL" />}
                        title={<Locale stringKey="COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__HINT_HEADER" />}
                    >
                        <Locale stringKey="COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__HINT" />
                    </AdditionalInfo>
                }
                name={CompanyKeyEnum.SearchNames}
            >
                <Select
                    className={styles.NamesWidget_select}
                    mode="tags"
                    open={false}
                    placeholder={getLocalizedString('COMPANY_FORM__NAMES__ALTERNATIVE_NAMES__PLACEHOLDER')}
                    size="large"
                />
            </Form.Item>
        </>
    );
}
