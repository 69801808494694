import {useCallback, useEffect} from 'react';

import {catchError} from '../../util/promise';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {
    fetchBrowserNotifications,
    fetchEmailNotifications,
    fetchTelegramNotifications,
    fetchUsedeskCatalogList,
    updateBrowserNotifications as fetchUpdateBrowserNotifications,
    updateEmailNotifications as fetchUpdateEmailNotifications,
    updateTelegramNotifications as fetchUpdateTelegramNotifications,
} from './notifications-settings-api';
import {
    BrowserNotificationsType,
    EmailNotificationsType,
    TelegramNotificationsType,
    UsedeskCatalogResponseType,
} from './notifications-settings-type';

type UpdateEmailNotificationsAdditionalType = {
    updateEmailNotifications: (settings: EmailNotificationsType) => Promise<EmailNotificationsType | void>;
};

type UpdateBrowserNotificationsAdditionalType = {
    updateBrowserNotifications: (
        settings: BrowserNotificationsType
    ) => Promise<BrowserNotificationsType | void | Error>;
};

type UpdateTelegramNotificationsAdditionalType = {
    updateTelegramNotifications: (
        settings: TelegramNotificationsType
    ) => Promise<TelegramNotificationsType | void | Error>;
};

export function useEmailNotifications(): UseHookType<EmailNotificationsType> & UpdateEmailNotificationsAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<EmailNotificationsType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchEmailNotifications()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    const updateEmailNotifications = useCallback(
        (settings: EmailNotificationsType): Promise<EmailNotificationsType | void> => {
            setIsInProgress(true);

            return fetchUpdateEmailNotifications(settings)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(setProcessError);
        },
        [setIsInProgress, setProcessError, setResult]
    );

    return {isInProgress, processError, result, reset, updateEmailNotifications};
}

export function useBrowserNotifications(): UseHookType<BrowserNotificationsType> &
    UpdateBrowserNotificationsAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<BrowserNotificationsType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchBrowserNotifications()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    const updateBrowserNotifications = useCallback(
        (settings: BrowserNotificationsType): Promise<BrowserNotificationsType | void | Error> => {
            setIsInProgress(true);

            return fetchUpdateBrowserNotifications(settings)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(catchError);
        },
        [setIsInProgress, setResult]
    );

    return {isInProgress, processError, result, reset, updateBrowserNotifications};
}

export function useTelegramNotifications(): UseHookType<TelegramNotificationsType> &
    UpdateTelegramNotificationsAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<TelegramNotificationsType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchTelegramNotifications()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    const updateTelegramNotifications = useCallback(
        (settings: TelegramNotificationsType): Promise<TelegramNotificationsType | void | Error> => {
            setIsInProgress(true);

            return fetchUpdateTelegramNotifications(settings)
                .then(setResult)
                .finally(() => setIsInProgress(false))
                .catch(catchError);
        },
        [setIsInProgress, setResult]
    );

    return {isInProgress, processError, result, reset, updateTelegramNotifications};
}

export function useUsedeskCatalogList(): UseHookType<UsedeskCatalogResponseType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<UsedeskCatalogResponseType>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchUsedeskCatalogList()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
