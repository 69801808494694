import {faBalanceScale, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Radio} from 'antd';
import dayjs from 'dayjs';
import {useMemo} from 'react';

import {DateRangePicker} from '../../../../component/date-range-picker/date-range-picker';
import {Text} from '../../../../component/text/text';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {
    ReviewsAndAnswersCompareEnum,
    ReviewsAndAnswersFilterActionsEnum,
    ReviewsAndAnswersFilterDispatcherType,
    ReviewsAndAnswersFilterQueriesEnum,
    ReviewsAndAnswersFilterStateType,
} from '../../../../service/reviews-and-answers-statistics/filter/reviews-and-answers-statistics-filter-type';
import {classNames} from '../../../../util/css';
import {DatePeriodEnum, datePeriodTranslations, DEFAULT_DATE_FORMAT} from '../../../../util/date';

import * as styles from './reviews-and-answers-statistics-filter.scss';

type PropsType = {
    filter: ReviewsAndAnswersFilterStateType;
    dispatchFilter: ReviewsAndAnswersFilterDispatcherType;
    isDisabled: boolean;
    lastAvailableDate: Date;
};

export function ReviewsAndAnswersFilter(props: PropsType): JSX.Element {
    const {filter, dispatchFilter, isDisabled, lastAvailableDate} = props;

    const {getLocalizedString} = useLocale();

    const {
        [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]: periodStart,
        [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]: periodEnd,
        [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: comparePeriodStart,
        [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: comparePeriodEnd,
        [ReviewsAndAnswersFilterQueriesEnum.Period]: period,
        [ReviewsAndAnswersFilterQueriesEnum.CompareMode]: compareMode,
    } = filter;

    const lastDayAnalyticsAvailableDayjs = useMemo(() => {
        return dayjs(lastAvailableDate);
    }, [lastAvailableDate]);

    return (
        <div className={styles.ReviewAndAnswersPeriodPicker}>
            <Radio.Group disabled={isDisabled} value={period}>
                {Object.values(DatePeriodEnum)
                    .filter((periodItem) => periodItem !== DatePeriodEnum.Custom)
                    .map((periodItem: DatePeriodEnum) => {
                        return (
                            <Radio.Button
                                key={periodItem}
                                onClick={() => {
                                    if (periodItem !== DatePeriodEnum.Custom) {
                                        dispatchFilter({
                                            type: ReviewsAndAnswersFilterActionsEnum.UpdatePeriod,
                                            payload: periodItem,
                                        });
                                    }
                                }}
                                value={periodItem}
                            >
                                <Locale stringKey={datePeriodTranslations[periodItem]} />
                            </Radio.Button>
                        );
                    })}
            </Radio.Group>
            <div className={styles.ReviewAndAnswersPeriodPicker_secondRowWrapper}>
                <div className={styles.ReviewAndAnswersPeriodPicker_secondRow}>
                    <div
                        className={classNames(styles.ReviewAndAnswersPeriodPicker_rangePickerContainer, {
                            [styles.ReviewAndAnswersPeriodPicker_rangePicker__compare]: compareMode,
                        })}
                    >
                        {compareMode && (
                            <Text block className={styles.ReviewAndAnswersPeriodPicker_label}>
                                A
                            </Text>
                        )}
                        <DateRangePicker
                            allowClear={false}
                            className={styles.ReviewAndAnswersPeriodPicker_rangePicker}
                            disabled={isDisabled}
                            disabledDate={(selectorDayjs) =>
                                lastDayAnalyticsAvailableDayjs.isSameOrBefore(selectorDayjs)
                            }
                            format={DEFAULT_DATE_FORMAT}
                            onChange={(dateRangeValue) => {
                                dispatchFilter({
                                    type: ReviewsAndAnswersFilterActionsEnum.UpdateMainPeriod,
                                    payload: {
                                        [ReviewsAndAnswersFilterQueriesEnum.PeriodStart]:
                                            dateRangeValue?.[0]?.tz('UTC', true).toDate() || new Date(),
                                        [ReviewsAndAnswersFilterQueriesEnum.PeriodEnd]:
                                            dateRangeValue?.[1]?.tz('UTC', true).toDate() || new Date(),
                                    },
                                });
                                dispatchFilter({
                                    type: ReviewsAndAnswersFilterActionsEnum.UpdatePeriod,
                                    payload: DatePeriodEnum.Custom,
                                });
                            }}
                            value={[dayjs(periodStart), dayjs(periodEnd)]}
                        />
                    </div>

                    {compareMode && (
                        <div
                            className={classNames(
                                styles.ReviewAndAnswersPeriodPicker_rangePickerContainer,
                                styles.ReviewAndAnswersPeriodPicker_rangePicker__compare
                            )}
                        >
                            <Text block className={styles.ReviewAndAnswersPeriodPicker_label}>
                                B
                            </Text>
                            <DateRangePicker
                                allowClear={false}
                                className={styles.ReviewAndAnswersPeriodPicker_rangePicker}
                                disabled={isDisabled}
                                disabledDate={(selectorDayjs) =>
                                    lastDayAnalyticsAvailableDayjs.isSameOrBefore(selectorDayjs)
                                }
                                format={DEFAULT_DATE_FORMAT}
                                onChange={(values) => {
                                    if (values?.[0] && values?.[1]) {
                                        dispatchFilter({
                                            type: ReviewsAndAnswersFilterActionsEnum.UpdateComparePeriod,
                                            payload: {
                                                [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodStart]: values[0]
                                                    .tz('UTC', true)
                                                    .toDate(),
                                                [ReviewsAndAnswersFilterQueriesEnum.ComparePeriodEnd]: values[1]
                                                    .tz('UTC', true)
                                                    .toDate(),
                                                [ReviewsAndAnswersFilterQueriesEnum.CompareMode]:
                                                    ReviewsAndAnswersCompareEnum.Custom,
                                            },
                                        });
                                    }
                                }}
                                value={[dayjs(comparePeriodStart), dayjs(comparePeriodEnd)]}
                            />
                        </div>
                    )}

                    {!compareMode && (
                        <Dropdown
                            disabled={isDisabled}
                            menu={{
                                items: Object.values(ReviewsAndAnswersCompareEnum).map(
                                    (compareModeItem: ReviewsAndAnswersCompareEnum) => {
                                        if (compareModeItem === ReviewsAndAnswersCompareEnum.Custom) {
                                            return null;
                                        }

                                        return {
                                            key: compareModeItem,
                                            label: getLocalizedString(
                                                compareModeItem === ReviewsAndAnswersCompareEnum.PreviousPeriod
                                                    ? 'REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__PREVIOUS_PERIOD'
                                                    : 'REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__PREVIOUS_YEAR'
                                            ),
                                            value: compareModeItem,
                                            onClick: () => {
                                                dispatchFilter({
                                                    type: ReviewsAndAnswersFilterActionsEnum.UpdateCompareMode,
                                                    payload: compareModeItem,
                                                });
                                            },
                                        };
                                    }
                                ),
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                        >
                            <Button
                                className={styles.ReviewAndAnswersPeriodPicker_button}
                                icon={<FontAwesomeIcon icon={faBalanceScale} />}
                            >
                                <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__COMPARE" />
                            </Button>
                        </Dropdown>
                    )}
                    {compareMode && (
                        <Button
                            className={styles.ReviewAndAnswersPeriodPicker_button}
                            disabled={isDisabled}
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            onClick={() => {
                                dispatchFilter({
                                    type: ReviewsAndAnswersFilterActionsEnum.UpdateCompareMode,
                                    payload: null,
                                });
                            }}
                        >
                            <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__COMPARE_MODE__RESET" />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
