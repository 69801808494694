export const enTableHeaderTranslations = {
    TABLE__HEADER__TITLE: 'Title',
    TABLE__HEADER__TITLE_AND_CODE: 'Name and code of branch',
    TABLE__HEADER__ADDRESS: 'Address',
    TABLE__HEADER__ADDRESS_LONG: 'Company address',
    TABLE__HEADER__CODE: 'Branch code',
    TABLE__HEADER__RATING: 'Rating',
    TABLE__HEADER__CARD_COMPLETENESS: 'Card completeness',
    TABLE__HEADER__ACTIVE_UNTIL: 'Active until:',
    TABLE__HEADER__DATE_OF_CREATION: 'Date of creation:',
    TABLE__HEADER__ACTION: 'Action',
    TABLE__HEADER__COMPANY_REVIEWS: 'Company reviews',
    TABLE__HEADER__COMPANY_EDIT: 'Edit',
    TABLE__HEADER__TEMPORARILY_CLOSED: 'Temporarily closed',
    TABLE__HEADER__ERROR_TITLE: 'Add information to the card',
    TABLE__HEADER__COUNT: 'Count',
    TABLE__HEADER__BLOCK__CATEGORIES: 'Additional categories',
    TABLE__HEADER__BLOCK__PHONES: 'Phones',
    TABLE__HEADER__BLOCK__PAYMENT_METHODS: 'Payment methods',
    TABLE__HEADER__BLOCK__LOGO: 'Logo',
    TABLE__HEADER__BLOCK__COVER: 'Cover',
    TABLE__HEADER__BLOCK__DESCRIPTION: 'Description',
    TABLE__HEADER__BLOCK__GALLERY: 'Gallery',
    TABLE__HEADER__BLOCK__VK_LINK: 'VK link',
    TABLE__HEADER__BLOCK__FACEBOOK_LINK: 'FB link',
    TABLE__HEADER__BLOCK__INSTAGRAM_LINK: 'IG link',
    TABLE__HEADER__BLOCK__VIBER_LINK: 'Viber link',
    TABLE__HEADER__BLOCK__WHATSAPP_LINK: 'WhatsApp link',
    TABLE__HEADER__BLOCK__YANDEX_ZEN_LINK: 'Yandex.Zen link',
    TABLE__HEADER__BLOCK__TELEGRAM_LINK: 'Telegram link',
    TABLE__HEADER__BLOCK__LINKEDIN_LINK: 'LinkedIn link',
    TABLE__HEADER__BLOCK__OK_RU_LINK: 'OK.RU link',
    TABLE__HEADER__BLOCK__YOUTUBE_LINK: 'Youtube link',
    TABLE__HEADER__BLOCK__TWITTER_LINK: 'X (Twitter) link',
    TABLE__HEADER__STATUS: 'Status',
    TABLE__HEADER__ACTIONS: 'Actions',
    TABLE__PAGINATION__PAGE_SIZE: '{pageSize} / page',
};
