import {faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';

import {Text} from '../../../../../../component/text/text';
import {Locale} from '../../../../../../provider/locale/localization';
import {SourceLinkStatusEnum} from '../../../../../../service/source-settings/source-links/source-links-type';
import {classNames} from '../../../../../../util/css';
import {NeverError} from '../../../../../../util/error';

import * as styles from './status-cell.scss';

type PropsType = {
    status: SourceLinkStatusEnum;
    errorText?: string | null;
};

export function StatusCell(props: PropsType): JSX.Element {
    const {status, errorText = null} = props;

    switch (status) {
        case SourceLinkStatusEnum.Synchronization:
            return (
                <div className={styles.StatusCell} role="status">
                    <Text
                        className={styles.StatusCell_label}
                        stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCHRONIZATION"
                    />
                </div>
            );
        case SourceLinkStatusEnum.Synced:
            return (
                <div className={classNames(styles.StatusCell, styles.StatusCell__synced)} role="status">
                    <Text
                        className={styles.StatusCell_label}
                        stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__SYNCED"
                    />
                </div>
            );
        case SourceLinkStatusEnum.Fail:
            return errorText ? (
                <Popover
                    content={errorText}
                    title={<Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ERROR_TEXT__TITLE" />}
                >
                    <div className={styles.StatusCell_wrapper}>
                        <div className={classNames(styles.StatusCell, styles.StatusCell__error)} role="status">
                            <Text
                                className={styles.StatusCell_label}
                                stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__FAIL"
                            />
                            <FontAwesomeIcon className={styles.StatusCell_icon} icon={faCircleInfo} />
                        </div>
                    </div>
                </Popover>
            ) : (
                <div className={classNames(styles.StatusCell, styles.StatusCell__error)} role="status">
                    <Text className={styles.StatusCell_label} stringKey="SOURCE_SETTINGS__LINKS__LINKS__STATUS__FAIL" />
                </div>
            );
        default:
            throw new NeverError(status);
    }
}
