import {oembed} from '@loomhq/loom-embed';
import {OEmbedInterface} from '@loomhq/loom-embed/dist/.types/oembed';
import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {Dispatch, SetStateAction} from 'react';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {getCsrfHeaders, mainApiHeaders} from '../../service/api/api-const';
import {fetchAndDeserialize, getUrlParameters, postAndDeserialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {useUrl} from '../../util/url-hook/url-hook';
import {useDomainConfig} from '../domain-config/domain-config-hook';
import {DomainNameEnum} from '../domain-config/domain-config-type';
import {Locale} from '../locale/locale';
import {useSnackbar} from '../snackbar/snackbar-hook';
import {useUser} from '../user/user-hook';

export enum ImportStatusEnum {
    InProgress = 'IN_PROGRESS',
}

export enum ConfirmationStatusEnum {
    NeedConfirmation = 'NEED_CONFIRMATION',
    InProgress = 'IN_PROGRESS',
    Confirmed = 'CONFIRMED',
}

export enum OnboardingStepNameEnum {
    AddCompaniesStep = 'add_companies',
    AccountsConnectionStep = 'accounts_connection',
    SyncCompaniesStep = 'sync_companies',
    ConfirmCompaniesStep = 'confirm_companies',
}

export const onboardingUrl = '/cp/onboarding/';

function getOnboardingUrl(demo: boolean) {
    return `/cp/onboarding/${demo ? getUrlParameters({demo}) : ''}`;
}

export enum SyncCompaniesStatusEnum {
    Default = 'DEFAULT',
    InProgress = 'IN_PROGRESS',
    Passed = 'SYNCED',
}

const accountConnectionSchema = r.object({
    '2gis.ru': r.boolean().optional(),
    'google': r.boolean().optional(),
    'facebook': r.boolean().optional(),
});

export type AccountConnectionType = r.infer<typeof accountConnectionSchema>;

const syncCompaniesSchema = r.object({
    facebook: r
        .object({
            status: r.nativeEnum(SyncCompaniesStatusEnum),
            synced: r.boolean(),
        })
        .optional(),
    google: r
        .object({
            status: r.nativeEnum(SyncCompaniesStatusEnum),
            synced: r.boolean(),
        })
        .optional(),
    yandex: r
        .object({
            status: r.nativeEnum(SyncCompaniesStatusEnum),
            synced: r.boolean(),
        })
        .optional(),
});

export type SyncCompaniesType = r.infer<typeof syncCompaniesSchema>;

const confirmCompaniesSchema = r.object({
    google: r
        .object({
            status: r.nativeEnum(ConfirmationStatusEnum),
            confirmed: r.boolean(),
        })
        .optional(),
    yandex: r
        .object({
            status: r.nativeEnum(ConfirmationStatusEnum),
            confirmed: r.boolean(),
        })
        .optional(),
});

export type ConfirmCompaniesType = r.infer<typeof confirmCompaniesSchema>;

export type StepDataType = SyncCompaniesType | AccountConnectionType | ConfirmCompaniesType;

const onboardingSchema = r.object({
    id: r.number(),
    counterparty: r.number(),
    addCompaniesStep: r.object({
        passed: r.boolean().optional(),
        importStatus: r.nativeEnum(ImportStatusEnum),
        active: r.boolean().optional(),
    }),
    accountsConnectionStep: r.object({
        accountsConnection: accountConnectionSchema,
        passed: r.boolean().optional(),
        active: r.boolean().optional(),
    }),
    syncCompaniesStep: r.object({
        active: r.boolean().optional(),
        passed: r.boolean().optional(),
        syncCompanies: syncCompaniesSchema,
    }),
    confirmCompaniesStep: r.object({
        active: r.boolean().optional(),
        passed: r.boolean().optional(),
        confirmCompanies: confirmCompaniesSchema,
    }),
});

export type OnboardingType = r.infer<typeof onboardingSchema>;

const postDoubleGisAccountConnectionSchema = r.object({
    brand: r.number(),
    email: r.string(),
    phone: r.string(),
    fullName: r.string(),
});

type PostDoubleGisAccountConnectionType = r.infer<typeof postDoubleGisAccountConnectionSchema>;

const postDoubleGisConnectionResponse = r.object({
    result: r.string(),
});

type PostDoubleGistConnectionResponseType = r.infer<typeof postDoubleGisConnectionResponse>;

export function useLoomMetadata(linkUrl: string): UseQueryResult<OEmbedInterface> {
    return useQuery([linkUrl], () => oembed(linkUrl, {gifThumbnail: false}), {
        retry: 0,
        refetchOnMount: false,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
}

export function useOnboarding(
    setShowOnboarding: Dispatch<SetStateAction<boolean>>,
    isDemo: boolean
): UseQueryResult<OnboardingType> {
    const {user} = useUser();
    const {domainName} = useDomainConfig();

    return useQuery(
        [getOnboardingUrl(isDemo)],
        () =>
            fetchAndDeserialize<OnboardingType>(getOnboardingUrl(isDemo), onboardingSchema, {
                fetchOptions: {skipCache: true},
            }),
        {
            onError: () => {
                setShowOnboarding(false);
            },
            onSuccess: (data) => {
                if (data.id && (domainName === DomainNameEnum.rocketData || user?.isStaff)) {
                    setShowOnboarding(true);
                }
            },
            retry: 0,
            refetchOnMount: false,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: 0,
        }
    );
}

function fetchDoubleGisAccountConnection(
    body: PostDoubleGisAccountConnectionType
): Promise<PostDoubleGistConnectionResponseType> {
    return postAndDeserialize(
        `${onboardingUrl}double_gis_connection_request/`,
        postDoubleGisAccountConnectionSchema,
        body
    );
}

function fetchFinishOnboarding(): Promise<unknown> {
    return fetchNoContent(`op/api${onboardingUrl}finish/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

function fetchAutoSyncCompanies(): Promise<unknown> {
    return fetchNoContent(`op/api${onboardingUrl}bitrix_request/sync_companies/`, {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function useDoubleGisAccountConnection(
    onClose: () => void
): UseMutationResult<PostDoubleGistConnectionResponseType, unknown, PostDoubleGisAccountConnectionType> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: (accountBody: PostDoubleGisAccountConnectionType) =>
            fetchDoubleGisAccountConnection(accountBody).finally(() => onClose()),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="SNACKBAR__SUCCESS__SEND_APPLICATION__TITLE" />,
                description: <Locale stringKey="SNACKBAR__SUCCESS__SEND_APPLICATION__DESCRIPTION" />,
            });
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}

export function useFinishOnboarding(refetch: () => void): UseMutationResult<unknown, unknown, never, unknown> {
    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: () => fetchFinishOnboarding(),
        onSuccess: () => {
            refetch();
            pushUrl(appRoute.dashboard.path);
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}

export function useAutoSyncCompanies(): UseMutationResult<unknown, unknown, void, unknown> {
    const {snackbar} = useSnackbar();

    return useMutation({
        mutationFn: () => fetchAutoSyncCompanies(),
        onSuccess: () => {
            snackbar.success({
                message: <Locale stringKey="ONBOARDING__SNACKBAR__APPLICATION_SENT" />,
                description: <Locale stringKey="ONBOARDING__SNACKBAR__APPLICATION_SENT__DESCRIPTION" />,
            });
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}
