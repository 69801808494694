export const enOmnichannelTranslations = {
    OMNICHANNEL__COMMUNICATION_PAGE__TITLE: 'Communication with clients',
    OMNICHANNEL__COMMUNICATION_PAGE__SEARCH_PLACEHOLDER: 'Search...',

    OMNICHANNEL__SENTIMENT_STATUS__NEGATIVE: 'Negative',
    OMNICHANNEL__SENTIMENT_STATUS__NEUTRAL: 'Neutral',
    OMNICHANNEL__SENTIMENT_STATUS__POSITIVE: 'Positive',
    OMNICHANNEL__SENTIMENT_STATUS__UNSPECIFIED: 'Unspecified',

    OMNICHANNEL__MANAGER_NOT_ASSIGNED: 'Not assigned',
    OMNICHANNEL__ADD_TAG_ACTION: '+ Tag',
    OMNICHANNEL__ACTIVE_CHAT__FINISH_ACTION: 'End session',
    OMNICHANNEL__ACTIVE_CHAT__SPAM_ACTION: 'Spam',
    OMNICHANNEL__ACTIVE_CHAT__MORE_DETAILS_ACTION: 'More details',
    OMNICHANNEL__ACTIVE_CHAT__EMPTY_PLACEHOLDER: 'Choose appeal',
    OMNICHANNEL__ACTIVE_CHAT__MESSAGE_FORM__PLACEHOLDER: 'Type here...',
    OMNICHANNEL__ACTIVE_CHAT__EMOJI_SEARCH__PLACEHOLDER: 'Search',
    OMNICHANNEL__ACTIVE_CHAT__TODAY: 'Today',
    OMNICHANNEL__ACTIVE_CHAT__DROPDOWN__CLOSE_CHAT: 'Finish chat',
    OMNICHANNEL__ACTIVE_CHAT__DROPDOWN__NEW_CHAT: 'Start new chat',
    OMNICHANNEL__INACTIVE_SOURCE__GROUP: 'Group {sourceName} inactive',
    OMNICHANNEL__INACTIVE_SOURCE__PAGE: 'Page {sourceName} inactive',
    OMNICHANNEL__INACTIVE_SOURCE__AGENT: 'Agent {sourceName} inactive',
    OMNICHANNEL__INACTIVE_SOURCE: 'Source {sourceName} inactive',

    OMNICHANNEL__NOTIFICATION__NEW_MESSAGE_RECEIVED: 'New message received',

    OMNICHANNEL__SETTINGS_TAB__ACCOUNTS: 'Accounts {catalogName}',
    OMNICHANNEL__SETTINGS_TAB__RESPONSE_TEMPLATES: 'Response templates',
    OMNICHANNEL__SETTINGS_TAB__NOTIFICATIONS: 'Notifications',
    OMNICHANNEL__SETTINGS_TAB__LIMITS: 'Plans and Limits',

    OMNICHANNEL__TITLE: '{catalogName} accounts',
    OMNICHANNEL__SUBTITLE:
        'Connect your {catalogName} account to receive and reply to messages and brand mentions through a group or public page.',
    OMNICHANNEL__ADD_GROUP: 'Add group',
    OMNICHANNEL__ADD_ACCOUNT: 'Link account',
    OMNICHANNEL__LINK_GROUP: 'Link group',
    OMNICHANNEL__RESOURCES__LINKED: 'List of {resourceType}',
    OMNICHANNEL__RESOURCES__EMPTY: 'No available {resourceType}',
    OMNICHANNEL__RESOURCE__GROUPS: 'groups',
    OMNICHANNEL__RESOURCE__PAGES: 'pages',
    OMNICHANNEL__RESOURCE__ACCOUNTS: 'accounts',
    OMNICHANNEL__RESOURCE__AGENTS: 'agents',
    OMNICHANNEL__ACTIVE_STATUS_CHANGED: 'Collection rules saved',
    OMNICHANNEL__REMOVE_GROUP__SUCCESS: 'Group successfully removed',
    OMNICHANNEL__REMOVE_GROUP__LIMIT_REACHED: 'The limit of connectable sources has been reached',
    OMNICHANNEL__ACCOUNT_HAS_ERROR__POPOVER: 'The account needs to be re-linked',
};
