import {Select} from 'antd';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {
    FilterSuggestionType,
    LoadFieldSuggestionsType,
    MainFilterFieldNameEnum,
} from '../../../../../service/main-filter/main-filter-type';
import {SelectInfiniteScroll} from '../../../../select-infinite-scroll/select-infinite-scroll';

import {renderOption} from './main-filter-select-helper';
import * as styles from './main-filter-select.scss';

type PropsType = {
    fieldValue: Array<FilterSuggestionType>;
    setFieldValue: (fieldName: MainFilterFieldNameEnum, newValues: Array<FilterSuggestionType>) => void;
    loadSuggests: LoadFieldSuggestionsType;
    fieldName: MainFilterFieldNameEnum;
    placeholder: JSX.Element;

    isInProgress: boolean;
    suggests: Array<FilterSuggestionType>;
    shouldRefreshSuggests: boolean;
};

export function MainFilterSelect(props: PropsType): JSX.Element {
    const {
        fieldValue,
        setFieldValue,
        loadSuggests,
        fieldName,
        placeholder,
        isInProgress,
        suggests,
        shouldRefreshSuggests,
    } = props;

    const [searchText, setSearchText] = useState<string>('');

    const [isOpen, setIsOpen] = useState(false);

    const loadFieldSuggestsMemoized = useCallback(() => {
        loadSuggests({
            fieldName,
            searchText,
        });
    }, [loadSuggests, fieldName, searchText]);

    const onSearch = useCallback(
        (newSearchText: string) => {
            setSearchText(newSearchText);
            loadSuggests({
                fieldName,
                searchText: newSearchText,
                isNewSearchText: true,
            });
        },
        [fieldName, loadSuggests]
    );

    const selectOptions = useMemo(() => {
        return [
            ...fieldValue.filter((selectedOption) => {
                return !suggests.some((suggest: FilterSuggestionType) => {
                    return selectedOption.id === suggest.id;
                });
            }),
            ...suggests,
        ];
    }, [suggests, fieldValue]);

    const selectedIds = useMemo(() => {
        return fieldValue.map((option) => option.id);
    }, [fieldValue]);

    const onChange = useCallback(
        (newSelectedIds: Array<string> | string) => {
            const newSelectedItems = selectOptions.filter((selectOption) => {
                if (typeof newSelectedIds === 'string') {
                    return newSelectedIds === selectOption.id;
                }

                return newSelectedIds.includes(selectOption.id);
            });

            setFieldValue(fieldName, newSelectedItems);
            onSearch('');
        },
        [selectOptions, setFieldValue, fieldName, onSearch]
    );

    useEffect(() => {
        if (isOpen && shouldRefreshSuggests && !isInProgress) {
            loadFieldSuggestsMemoized();
        }
    }, [fieldName, isInProgress, isOpen, loadFieldSuggestsMemoized, shouldRefreshSuggests]);

    return fieldName === MainFilterFieldNameEnum.ACTIVE || fieldName === MainFilterFieldNameEnum.CLOSED ? (
        <Select className={styles.header_select} onChange={onChange} placeholder={placeholder} value={selectedIds}>
            {selectOptions.map(renderOption)}
        </Select>
    ) : (
        <SelectInfiniteScroll<Array<string>>
            className={styles.header_select}
            filterOption={() => true}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            loading={isInProgress}
            mode="multiple"
            onChange={onChange}
            onDropdownVisibleChange={setIsOpen}
            onLoadMore={loadFieldSuggestsMemoized}
            onSearch={onSearch}
            placeholder={placeholder}
            searchValue={searchText}
            showSearch
            value={selectedIds}
        >
            {selectOptions.map(renderOption)}
        </SelectInfiniteScroll>
    );
}
