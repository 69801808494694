import {LangKeyType} from '../../../../../../provider/locale/translation/type';
import {
    TimelineYandexActionTypeEnum,
    TimelineYandexEventTypeEnum,
} from '../../../../../../service/timeline-activity/timeline-activity-type';

export const timelineYandexEventLocalizationMap: Record<TimelineYandexEventTypeEnum, LangKeyType> = {
    [TimelineYandexEventTypeEnum.TextSearchOnMap]: 'DASHBOARD_PAGE__TIMELINE__EVENT__TEXT_SEARCH_ON_MAP',
    [TimelineYandexEventTypeEnum.SuggestSearchOnMap]: 'DASHBOARD_PAGE__TIMELINE__EVENT__SUGGEST_SEARCH_ON_MAP',
    [TimelineYandexEventTypeEnum.Poi]: 'DASHBOARD_PAGE__TIMELINE__EVENT__POI',
    [TimelineYandexEventTypeEnum.ArbitrateGeoadvDirect]: 'DASHBOARD_PAGE__TIMELINE__EVENT__ARBITRATE_GEOADV_DIRECT',
    [TimelineYandexEventTypeEnum.ArbitrateGeoadvYabs]: 'DASHBOARD_PAGE__TIMELINE__EVENT__ARBITRATE_GEOADV_YABS',
    [TimelineYandexEventTypeEnum.ArbitrateGeoadvZen]: 'DASHBOARD_PAGE__TIMELINE__EVENT__ARBITRATE_GEOADV_ZEN',
    [TimelineYandexEventTypeEnum.RouteAdvert]: 'DASHBOARD_PAGE__TIMELINE__EVENT__ROUTE_ADVERT',
    [TimelineYandexEventTypeEnum.ToponymAdvert]: 'DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT',
    [TimelineYandexEventTypeEnum.WhatshereAdvert]: 'DASHBOARD_PAGE__TIMELINE__EVENT__WHATS_HERE_ADVERT',
    [TimelineYandexEventTypeEnum.InjectByRubricAtBottom]: 'DASHBOARD_PAGE__TIMELINE__EVENT__INJECT_BY_RUBRIC_AT_BOTTOM',
    [TimelineYandexEventTypeEnum.Related]: 'DASHBOARD_PAGE__TIMELINE__EVENT__RELATED',
    [TimelineYandexEventTypeEnum.ExternalOpen]: 'DASHBOARD_PAGE__TIMELINE__EVENT__EXTERNAL_OPEN',
    [TimelineYandexEventTypeEnum.CompetitorOf1org]: 'DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR_OF_1ORG',
    [TimelineYandexEventTypeEnum.Competitor]: 'DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR',
    [TimelineYandexEventTypeEnum.CompetitorOfChain]: 'DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR_OF_CHAIN',
    [TimelineYandexEventTypeEnum.CompetitorOfPoi]: 'DASHBOARD_PAGE__TIMELINE__EVENT__COMPETITOR_OF_POI',
    [TimelineYandexEventTypeEnum.RubricAlike]: 'DASHBOARD_PAGE__TIMELINE__EVENT__RUBRIC_ALIKE',
    [TimelineYandexEventTypeEnum.ToponymAdvertFromSearch]:
        'DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT_FROM_SEARCH',
    [TimelineYandexEventTypeEnum.ToponymAdvertFromClick]: 'DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT_FROM_CLICK',
    [TimelineYandexEventTypeEnum.ToponymAdvertFromWhatsHere]:
        'DASHBOARD_PAGE__TIMELINE__EVENT__TOPONYM_ADVERT_FROM_WHATS_HERE',
};

export const timelineYandexActionLocalizationMap: Record<TimelineYandexActionTypeEnum, LangKeyType> = {
    [TimelineYandexActionTypeEnum.Route]: 'DASHBOARD_PAGE__TIMELINE__ACTION__ROUTE',
    [TimelineYandexActionTypeEnum.Call]: 'DASHBOARD_PAGE__TIMELINE__ACTION__CALL',
    [TimelineYandexActionTypeEnum.Review]: 'DASHBOARD_PAGE__TIMELINE__ACTION__REVIEW',
    [TimelineYandexActionTypeEnum.Photo]: 'DASHBOARD_PAGE__TIMELINE__ACTION__PHOTO',
    [TimelineYandexActionTypeEnum.Links]: 'DASHBOARD_PAGE__TIMELINE__ACTION__LINKS',
    [TimelineYandexActionTypeEnum.WorkingStatus]: 'DASHBOARD_PAGE__TIMELINE__ACTION__WORKING_STATUS',
    [TimelineYandexActionTypeEnum.Entrances]: 'DASHBOARD_PAGE__TIMELINE__ACTION__ENTRANCES',
    [TimelineYandexActionTypeEnum.Advert]: 'DASHBOARD_PAGE__TIMELINE__ACTION__ADVERT',
    [TimelineYandexActionTypeEnum.Cta]: 'DASHBOARD_PAGE__TIMELINE__ACTION__CTA',
    [TimelineYandexActionTypeEnum.Bookmark]: 'DASHBOARD_PAGE__TIMELINE__ACTION__BOOKMARK',
    [TimelineYandexActionTypeEnum.Branches]: 'DASHBOARD_PAGE__TIMELINE__ACTION__BRANCHES',
    [TimelineYandexActionTypeEnum.Details]: 'DASHBOARD_PAGE__TIMELINE__ACTION__DETAILS',
    [TimelineYandexActionTypeEnum.Sharing]: 'DASHBOARD_PAGE__TIMELINE__ACTION__SHARING',
    [TimelineYandexActionTypeEnum.BookingSection]: 'DASHBOARD_PAGE__TIMELINE__ACTION__BOOKING_SECTION',
    [TimelineYandexActionTypeEnum.Menu]: 'DASHBOARD_PAGE__TIMELINE__ACTION__MENU',
    [TimelineYandexActionTypeEnum.Unknown]: 'DASHBOARD_PAGE__TIMELINE__ACTION__UNKNOWN',
};
