import {Button, ButtonProps} from 'antd';
import {ReactElement} from 'react';

import {useNewAccount} from '../../util/new-account-hook/new-account-hook';

type PropsType = ButtonProps & {
    linkingSrc: string;
    onClose: () => void;
    buttonContent: ReactElement;
    catalogName?: string;
};

export function AddAccountButton(props: PropsType): JSX.Element {
    const {linkingSrc, onClose, buttonContent, catalogName, ...restProps} = props;

    const {addAccount} = useNewAccount({linkingSrc, catalogName, onAccountAdd: onClose});

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button {...restProps} onClick={addAccount}>
            {buttonContent}
        </Button>
    );
}
