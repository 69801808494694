import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Progress, ProgressProps, Spin} from 'antd';
import {ReactNode, useContext} from 'react';

import {Text} from '../../../../../../../../component/text/text';
import {googleProcessingLocationsLoadingStatuses} from '../../../../../../../../service/google/processing/google-processing-const';
import {GoogleAccountSyncContext} from '../../../../google-account-sync-context';

import * as styles from './progressbar.scss';

type PropsType = Pick<ProgressProps, 'percent' | 'status'> & {
    description: ReactNode;
};

export function Progressbar(props: PropsType): JSX.Element {
    const {percent, status: progressbarStatus, description} = props;

    const {status} = useContext(GoogleAccountSyncContext);

    return (
        <div className={styles.Progressbar}>
            <div className={styles.Progressbar_space}>
                <Text
                    bolder
                    large
                    stringKey="GOOGLE_SYNC__PROCESSING_STEP__PROGRESSBAR__TITLE"
                    valueMap={{
                        step: status && !googleProcessingLocationsLoadingStatuses.includes(status) ? 2 : 1,
                        steps: 2,
                    }}
                />

                {percent !== 100 && progressbarStatus !== 'exception' && (
                    <Spin indicator={<FontAwesomeIcon icon={faSpinner} spin />} />
                )}
            </div>

            <Progress percent={percent} showInfo={false} status={progressbarStatus} />

            {description}
        </div>
    );
}
