import {FormInstance} from 'antd';

import {LocaleContextType} from '../../../../../provider/locale/locale-context-type';
import {FormRuleType} from '../../../../../service/form/form-rules-hook-type';
import {
    ReviewUserTopicFieldEnum,
    ReviewUserTopicType,
} from '../../../../../service/reivew-analysis/review-user-topics/review-user-topics-type';

export function topicTagsRule(
    getLocalizedString: LocaleContextType['getLocalizedString'],
    form: FormInstance<ReviewUserTopicType>
): FormRuleType<Array<string>> {
    return {
        validator: (): Promise<void> => {
            const includeTags: unknown = form.getFieldValue(ReviewUserTopicFieldEnum.includeTags);
            const isIncludeTagsEmpty: boolean = Array.isArray(includeTags) && includeTags.length === 0;

            if (isIncludeTagsEmpty) {
                return Promise.reject(getLocalizedString('REVIEWS_ANALYSIS__FORM__INPUT__TAG__VALIDATION__ERROR'));
            }

            return Promise.resolve();
        },
    };
}
