import {useEffect, useMemo} from 'react';

import {ProvidersIdsEnum} from '../../util/type';
import {useApiHooks} from '../api-hook/api-hook';

import {getWorkloadByHoursAndWeekDays} from './analytics-workload-api';
import {WorkloadByHoursAndWeekDaysHookType, WorkloadByHoursAndWeekDaysType} from './analytics-workload-type';

export function useWorkload(companyId: number, catalogId: ProvidersIdsEnum): WorkloadByHoursAndWeekDaysHookType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<WorkloadByHoursAndWeekDaysType>();

    useEffect(() => {
        setIsInProgress(true);

        getWorkloadByHoursAndWeekDays(companyId, catalogId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch((error) => {
                setProcessError(error.message);
            });
    }, [catalogId, companyId, setIsInProgress, setProcessError, setResult]);

    const isDataEmpty = useMemo(() => {
        if (!result) {
            return false;
        }

        return !Object.entries(result).some(([_dayKey, dayValues]) =>
            Object.keys(dayValues).length === 0 ? false : Object.values(dayValues).some(Boolean)
        );
    }, [result]);

    return {isInProgress, processError, result, reset, isDataEmpty};
}
