import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {tonalityDistributionSchema, TonalityDistributionType} from './tonality-distribution-type';

export function fetchTonalityDistribution(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<TonalityDistributionType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/sentiments/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        tonalityDistributionSchema
    );
}
