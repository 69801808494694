import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {FilterDropdownProps} from 'antd/lib/table/interface';
import {useCallback, useState} from 'react';

import {Locale} from '../../provider/locale/localization';
import {classNames} from '../../util/css';

import * as styles from './table-filter-dropdown.scss';

type PropsType = FilterDropdownProps & {
    placeholder: string;
};

export function TableFilterInputDropdown(props: PropsType): JSX.Element {
    const {placeholder, confirm, selectedKeys, setSelectedKeys} = props;

    const [queryString, setQueryString] = useState<string>(String(selectedKeys[0] || ''));

    const handleConfirm = useCallback(() => {
        setSelectedKeys(queryString ? [queryString] : []);
        confirm();
    }, [confirm, queryString, setSelectedKeys]);

    const handleReset = useCallback(() => {
        setQueryString('');
        setSelectedKeys([]);
        confirm();
    }, [confirm, setSelectedKeys]);

    const handleClose = useCallback(() => {
        confirm();
    }, [confirm]);

    return (
        <div>
            <div className={styles.TableFilterDropdown_input}>
                <Input
                    onChange={(event) => setQueryString(event.target.value)}
                    placeholder={placeholder}
                    value={queryString}
                />
            </div>

            <footer className={classNames(styles.TableFilterDropdown_footer)}>
                <Button
                    icon={<FontAwesomeIcon className={styles.TableFilterDropdown_buttonIcon} icon={faSearch} />}
                    onClick={handleConfirm}
                    size="small"
                    type="primary"
                >
                    <Locale stringKey="TEXT__OK" />
                </Button>
                <Button onClick={handleReset} size="small">
                    <Locale stringKey="TEXT__RESET" />
                </Button>
                <Button onClick={handleClose} size="small">
                    <Locale stringKey="TEXT__CLOSE" />
                </Button>
            </footer>
        </div>
    );
}
