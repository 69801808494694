import {Empty, Skeleton, Space, Table} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {CatalogLogo} from '../../../../component/catalog-logo/catalog-logo';
import {Text} from '../../../../component/text/text';
import {header_height} from '../../../../css/var-export.scss';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Locale} from '../../../../provider/locale/localization';
import {useFakesCompaniesInfo} from '../../../../service/fakes/fakes-companies-info';
import {FakesFiltersType, useFakesResults} from '../../../../service/fakes/fakes-results';
import {useFakesStatuses} from '../../../../service/fakes/fakes-statuses';
import {classNames} from '../../../../util/css';
import {useFormat} from '../../../../util/format-hook/format-hook';
import {FakesPopupNeedAction} from '../popups/fakes-popup-need-action';
import {FakesPopupRejection} from '../popups/fakes-popup-rejection';

import {ActionsColumn} from './columns/actions/actions-column';
import {FoundLocationColumn} from './columns/found-location/found-location-column';
import {StatusColumn} from './columns/status/status-column';
import {fakesTableColumns} from './fakes-table-const';
import {FakesTableType} from './fakes-table-type';
import * as styles from './fakes-table.scss';

type PropsType = {
    filters: FakesFiltersType | null;
};

export function FakesTable(props: PropsType): JSX.Element | null {
    const {filters} = props;

    const {
        data,
        isInitialLoading,
        pagination: {page, pageSize, total, onChange},
        refetch: refetchResults,
    } = useFakesResults(filters);

    const {refetch: refetchStatuses, data: fakesStatuses} = useFakesStatuses();
    const companiesInfo = useFakesCompaniesInfo(data?.results.map(({id}) => id));

    const [rejectionPopupFakeId, setRejectionPopupFakeId] = useState<number | null>(null);
    const [needActionPopupFakeId, setNeedActionPopupFakeId] = useState<number | null>(null);

    const {getFormattedDateTime} = useFormat();

    const fake = useMemo(
        () => data?.results.find(({id}) => id === rejectionPopupFakeId || id === needActionPopupFakeId),
        [rejectionPopupFakeId, needActionPopupFakeId, data]
    );

    const refetch = useCallback(() => {
        refetchResults();
        refetchStatuses();
    }, [refetchResults, refetchStatuses]);

    function onSubmitNeedActionPopup() {
        setNeedActionPopupFakeId(null);
        refetch();
    }

    const tableClassNames = classNames(styles.FakesTable, {
        [styles.FakesTrial]: fakesStatuses?.isTrial,
    });

    const dataSource =
        data &&
        (fakesStatuses?.isTrial ? data.results.slice(0, 3) : data.results).map((result) => {
            const date = new Date(result.createdAt);
            const companies = companiesInfo?.[result.id];

            return {
                key: result.id,
                catalogIcon: <CatalogLogo catalogId={result.catalogId} className={styles.FakesTable_catalogIcon} />,
                date: <span>{!Number.isNaN(date.getTime()) ? getFormattedDateTime(date) : null}</span>,
                foundLocation: (
                    <FoundLocationColumn
                        address={result.address}
                        category={result.category}
                        logoUrl={result.logoUrl}
                        name={result.name}
                        phone={result.phone}
                        url={result.url}
                    />
                ),
                mainLocation: companies ? (
                    <Space direction="vertical">
                        {companies.map((company) => (
                            <FoundLocationColumn
                                address={company.address}
                                category={company.category}
                                isCompany
                                key={company.id}
                                logoUrl={null}
                                name={company.name}
                                phone={company.phone}
                                url={company.url}
                            />
                        ))}
                    </Space>
                ) : (
                    <Text bolder>---</Text>
                ),
                status: <StatusColumn clientStatus={result.clientStatus} statusChangeTime={result.statusChangeTime} />,
                actions: (
                    <ActionsColumn
                        clientStatus={result.clientStatus}
                        id={result.id}
                        internalStatus={result.internalStatus}
                        isDuplicate={result.isDuplicate}
                        isTrial={fakesStatuses?.isTrial}
                        needActionPopupFakeId={setNeedActionPopupFakeId}
                        refetch={refetch}
                        rejectReason={result.rejectReason}
                        setRejectionPopupFakeId={setRejectionPopupFakeId}
                    />
                ),
            };
        });

    return (
        <PageCard bodyClassName={styles.FakesTable_pageCard}>
            {fake && (
                <FakesPopupNeedAction
                    fake={fake}
                    onCancel={() => setNeedActionPopupFakeId(null)}
                    onSubmit={onSubmitNeedActionPopup}
                    open={needActionPopupFakeId !== null}
                />
            )}

            {fake && (
                <FakesPopupRejection
                    companiesInfo={companiesInfo?.[fake.id]}
                    fake={fake}
                    onCancel={() => setRejectionPopupFakeId(null)}
                    open={rejectionPopupFakeId !== null}
                />
            )}

            {dataSource?.length === 0 ? (
                <Empty
                    className={styles.FakesTable_empty}
                    description={<Locale stringKey="EMPTY__DESCRIPTION" />}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{height: 117}}
                />
            ) : (
                <>
                    {!data ? (
                        <Skeleton
                            active
                            className={styles.FakesTable_prefixText}
                            paragraph={false}
                            title={{width: 180}}
                        />
                    ) : (
                        <Text
                            block
                            className={styles.FakesTable_prefixText}
                            stringKey="FAKES__RESULTS__COUNT"
                            valueMap={{count: <Text bolder>{data.count}</Text>}}
                        />
                    )}

                    <Table<FakesTableType>
                        className={tableClassNames}
                        columns={fakesTableColumns}
                        dataSource={dataSource}
                        loading={isInitialLoading}
                        onChange={(newPagination) => onChange(newPagination.current ?? 1, newPagination.pageSize)}
                        pagination={fakesStatuses?.isTrial ? false : {current: page, pageSize, total}}
                        scroll={{x: true}}
                        sticky={{offsetHeader: Number.parseInt(header_height, 10)}}
                    />
                </>
            )}
        </PageCard>
    );
}
