import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';

import {UploadFileList} from '../../../../../../../component/form/file-upload-item/upload-file-list/upload-file-list';
import {Text} from '../../../../../../../component/text/text';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {FormattedDateTime} from '../../../../../../../provider/locale/formatted-date-time';
import {TechnicalSupportMessageType} from '../../../../../../../service/technical-support/technical-support-type';
import {classNames} from '../../../../../../../util/css';
import {TimeSizeEnum} from '../../../../../../../util/format';

import * as styles from './technical-support-conversation.scss';

type PropsType = {
    message: TechnicalSupportMessageType;
    isAnswer: boolean;
};

export function TechnicalSupportConversationMessage(props: PropsType): JSX.Element {
    const {message, isAnswer} = props;
    const {LogoShort} = useDomainConfig();

    const createdDate = new Date(message.created);

    return (
        <div
            className={classNames(styles.TechnicalSupportConversation_message, {
                [styles.TechnicalSupportConversation_message__answer]: isAnswer,
            })}
        >
            <div className={styles.TechnicalSupportConversation_messageTitle}>
                <div className={styles.TechnicalSupportConversation_titleColumn}>
                    <Text block>
                        {isAnswer ? (
                            <LogoShort className={styles.TechnicalSupportConversation_answerLogo} />
                        ) : (
                            <FontAwesomeIcon icon={faUser} />
                        )}
                    </Text>
                    <Text block>{message.creatorName}</Text>
                </div>
                <div className={styles.TechnicalSupportConversation_titleColumn}>
                    <Text block secondary>
                        <FormattedDateTime
                            options={{
                                [TimeSizeEnum.hour]: '2-digit',
                                [TimeSizeEnum.minute]: '2-digit',
                            }}
                            value={createdDate}
                        />
                    </Text>
                    <Text block secondary>
                        <FormattedDateTime
                            options={{
                                [TimeSizeEnum.day]: 'numeric',
                                [TimeSizeEnum.month]: '2-digit',
                                [TimeSizeEnum.year]: 'numeric',
                            }}
                            value={createdDate}
                        />
                    </Text>
                </div>
            </div>

            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message.text)}} />
            {message.files.length > 0 && (
                <UploadFileList
                    allowDownload
                    fileList={message.files.map((uploadedFileInfo) => ({
                        name: uploadedFileInfo.fileName,
                        uid: uploadedFileInfo.tempId,
                        url: uploadedFileInfo.fileS3,
                        status: 'done',
                    }))}
                />
            )}
        </div>
    );
}
