import {Input, Space, Table, Typography} from 'antd';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {AsciiSpinner} from '../../../../../layout/spinner/ascii-spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useUser} from '../../../../../service/user-management/user-management-user';
import {UsersBrandsType, useUsers, useUsersBrands} from '../../../../../service/user-management/user-management-users';
import {TimeSizeEnum} from '../../../../../util/format';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import {ActionsColumn} from './columns/actions/actions-column';
import {BrandsColumn} from './columns/brands/brands-column';
import {SelectionSummary} from './selection-summary/selection-summary';
import {USER_STATUS_TO_TAG} from './users-table-const';
import {usePaginatedSelection, useUsersColumns} from './users-table-hook';
import {UsersTableRowType} from './users-table-type';
import * as styles from './users-table.scss';

type PropsType = {
    setIsUserAddButtonPrimary: Dispatch<SetStateAction<boolean>>;
};

export function UsersTable(props: PropsType): JSX.Element {
    const {setIsUserAddButtonPrimary} = props;

    const [search, setSearch] = useState('');
    const [role, setRole] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);

    const {
        data: users,
        isInitialLoading,
        isLoading,
        isRefetching,
        pagination: {page, pageSize, total, onChange},
        refetch,
    } = useUsers({q: search || null, status, role});

    const {data: brandsMap} = useUsersBrands({userIds: users?.results.map((item) => item.pk) ?? []});

    const {selectedIds, totalSelected, rowSelection, toggleSelectAll} = usePaginatedSelection<UsersTableRowType>({
        total,
        shownIds: users?.results.map(({pk}) => pk),
    });

    const columns = useUsersColumns();
    const {data: userData, refetch: updateStatistic} = useUser();
    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    useEffect(() => {
        setIsUserAddButtonPrimary(totalSelected === 0);
    }, [totalSelected, setIsUserAddButtonPrimary]);

    const dataSource = users?.results.map((user) => {
        const createdAtDate = new Date(user.createdAt);
        const updatedAtDate = user.lastActivity ? new Date(user.lastActivity) : null;

        return {
            email: (
                <Space direction="vertical" size={2}>
                    {user.fullName && (
                        <Typography.Text className={styles.UsersTable_ellipsis} ellipsis title={user.fullName}>
                            <Text bolder>{user.fullName}</Text>
                        </Typography.Text>
                    )}

                    <Typography.Text className={styles.UsersTable_ellipsis} ellipsis title={user.email}>
                        <Text bolder={!user.fullName} lighter>
                            {user.email}
                        </Text>
                    </Typography.Text>
                </Space>
            ),
            key: user.pk,
            roleId: user.role.pk,
            role: user.role.name,
            companies: brandsMap?.get(user.pk)?.companiesCount ?? <AsciiSpinner />,
            brands: brandsMap?.get(user.pk)?.brands ? (
                <BrandsColumn brand={brandsMap.get(user.pk)?.brands as UsersBrandsType['brands']} />
            ) : (
                <AsciiSpinner />
            ),
            statusValue: user.status,
            status: USER_STATUS_TO_TAG[user.status],
            createdAt: !Number.isNaN(createdAtDate.getTime()) ? (
                <Text block className={styles.UsersTable_date} secondary>
                    {getFormattedDateTime(createdAtDate)}
                </Text>
            ) : null,
            creator: user.creator ? (
                <Text block className={styles.UsersTable_creator}>
                    {user.creator}
                </Text>
            ) : (
                '-'
            ),
            lastActivity:
                updatedAtDate && !Number.isNaN(updatedAtDate.getTime()) ? (
                    <Text block className={styles.UsersTable_date}>
                        {getFormattedDateTime(updatedAtDate, {
                            [TimeSizeEnum.day]: '2-digit',
                            [TimeSizeEnum.month]: '2-digit',
                            [TimeSizeEnum.year]: '2-digit',
                        })}
                        ,&nbsp;
                        <Text secondary>
                            {getFormattedDateTime(updatedAtDate, {
                                [TimeSizeEnum.hour]: '2-digit',
                                [TimeSizeEnum.minute]: '2-digit',
                            })}
                        </Text>
                    </Text>
                ) : (
                    '-'
                ),
            actions: (
                <ActionsColumn
                    creator={user.creator}
                    email={user.email}
                    onDelete={() => {
                        refetch();
                        updateStatistic();
                    }}
                    pk={user.pk}
                    status={user.status}
                />
            ),
        };
    });

    return (
        <PageCard
            title={
                <Text
                    bolder
                    large
                    stringKey={userData?.role.isOwner ? 'USERS__TABLE__TITLE__OWNER' : 'USERS__TABLE__TITLE'}
                />
            }
        >
            <header className={styles.UsersTable_header}>
                <Text stringKey="TEXT__TOTAL" valueMap={{total: <Text bolder>{users?.count}</Text>}} />

                <Input.Search
                    allowClear
                    className={styles.UsersTable_search}
                    onSearch={setSearch}
                    placeholder={getLocalizedString('USERS__TABLE__SEARCH__PLACEHOLDER')}
                />
            </header>

            <Table<UsersTableRowType>
                columns={columns}
                dataSource={dataSource}
                loading={isInitialLoading || isLoading || isRefetching}
                onChange={(newPagination, filters) => {
                    onChange(newPagination.current ?? 1, newPagination.pageSize);
                    setStatus(filters.status?.toString() ?? null);
                    setRole(filters.role?.toString() ?? null);
                }}
                pagination={{hideOnSinglePage: true, current: page, pageSize, total}}
                rowSelection={rowSelection}
                scroll={{x: true}}
            />

            <SelectionSummary
                email={
                    selectedIds.length === 1 ? users?.results.find((user) => user.pk === selectedIds[0])?.email : null
                }
                onDelete={() => {
                    toggleSelectAll(false);
                    updateStatistic();
                    refetch();
                }}
                selectedIds={selectedIds}
                toggleSelectAll={toggleSelectAll}
                total={total}
                totalSelected={totalSelected}
            />
        </PageCard>
    );
}
