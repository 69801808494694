import {faClock, faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Popover, Tag} from 'antd';

import {gray7} from '../../../../../../../../css/var-export.scss';
import {PopoverTextWrapper} from '../../../../../../../../layout/typography/popover-text-wrapper/popover-text-wrapper';
import {FormattedDateTime} from '../../../../../../../../provider/locale/formatted-date-time';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {AbuseReasonStatusEnum} from '../../../../../../../../service/reviews/reviews-abuse-hook';
import {classNames} from '../../../../../../../../util/css';
import * as styles from '../new-reply-form.scss';
import {
    reviewAbuseReasonDescriptionToLocaleMap,
    reviewAbuseReasonTitleToLocaleMap,
    reviewAbuseReasonToLocaleMap,
} from '../reply-form-const';

type ReviewAbusePopoverPropsType = {
    abuseLastDate: string | null;
    reviewAbuseReason: {
        id: number;
        status: AbuseReasonStatusEnum;
    } | null;
    onAbuseModalOpen: () => void;
};

export function ReviewAbusePopover(props: ReviewAbusePopoverPropsType): JSX.Element | null {
    const {abuseLastDate, reviewAbuseReason, onAbuseModalOpen} = props;

    if (!abuseLastDate && reviewAbuseReason?.status !== AbuseReasonStatusEnum.WillBeCheckedByAi) {
        return null;
    }

    const tagClassName = classNames(styles.ReplyForm_abuseTag, {
        [styles.ReplyForm_abuseTag__accepted]: reviewAbuseReason?.status === AbuseReasonStatusEnum.Accepted,
        [styles.ReplyForm_abuseTag__declined]: reviewAbuseReason?.status === AbuseReasonStatusEnum.Declined,
    });

    return (
        <Popover
            content={
                <PopoverTextWrapper>
                    <Locale
                        stringKey={
                            reviewAbuseReason
                                ? reviewAbuseReasonDescriptionToLocaleMap[reviewAbuseReason.status]
                                : 'REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__TEXT'
                        }
                        valueMap={{
                            link: (
                                <Button
                                    className={styles.ReplyForm_linkAbuseButton}
                                    onClick={onAbuseModalOpen}
                                    type="link"
                                >
                                    <Locale stringKey="REVIEW__ABUSE_STATUS_DESCRIPTION__DECLINED__LINK" />
                                </Button>
                            ),
                        }}
                    />
                </PopoverTextWrapper>
            }
            title={
                <PopoverTextWrapper>
                    <Locale
                        stringKey={
                            reviewAbuseReason
                                ? reviewAbuseReasonTitleToLocaleMap[reviewAbuseReason.status]
                                : 'REVIEWS__POPOVER__COMPLAINT_HAS_BEEN_SENT__HEADER'
                        }
                        valueMap={{
                            date: abuseLastDate ? <FormattedDateTime key="date" value={new Date(abuseLastDate)} /> : '',
                        }}
                    />
                </PopoverTextWrapper>
            }
            trigger="hover"
            zIndex={10}
        >
            {reviewAbuseReason?.status === AbuseReasonStatusEnum.WillBeCheckedByAi ? (
                <FontAwesomeIcon color={gray7} cursor="pointer" fontSize={16} icon={faClock} />
            ) : (
                <Tag className={tagClassName}>
                    <Locale
                        stringKey={
                            reviewAbuseReason
                                ? reviewAbuseReasonToLocaleMap[reviewAbuseReason.status]
                                : 'REVIEW__ABUSE_STATUS__SENT'
                        }
                    />
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Tag>
            )}
        </Popover>
    );
}
