import {Steps} from 'antd';
import {useCallback, useEffect, useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {useCompanies} from '../../../../../service/company/company-hook';
import {matchCompanyApi} from '../../../../../service/matching/matching-api';
import {useAccountMatchingCompanies} from '../../../../../service/matching/matching-hook';
import {ProvidersIdsEnum} from '../../../../../util/type';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import {AccountsMatchingForm} from '../accounts-matching-form/accounts-matching-form';

import {FacebookAccountSelector} from './facebook-account-selector';
import {FacebookPageMatching} from './facebook-page-matching';

type PropsType = {
    brandId: number;
    companyId?: number;
};

export function FacebookAccountsMatching(props: PropsType): JSX.Element {
    const {brandId, companyId} = props;

    const [current, setCurrent] = useState<number>(0);

    const [accountOauthId, setAccountOauthId] = useState<number | null>(null);

    const [brandPageId, setBrandPageId] = useState<string | null>(null);

    const [isMathchingInProgress, setIsMathchingInProgress] = useState<boolean>(false);

    const {getLocalizedString} = useLocale();

    const {pushUrl} = useUrl();

    const {snackbar} = useSnackbar();

    const {data: apiCompanies} = useCompanies({});

    const accountMatchingData = useAccountMatchingCompanies({
        oauthAccountId: accountOauthId,
        brandId,
        providerId: Number(ProvidersIdsEnum.facebook),
        companyId,
        ...(brandPageId ? {brandPageId} : null),
        skip: !brandPageId,
    });

    const {companies, processError, isInProgress} = accountMatchingData;

    const nextStep = useCallback(() => {
        setCurrent(current + 1);
    }, [current]);

    const onSyncBrand = useCallback(
        (selectedBrandPageId: string) => {
            if (accountOauthId && companies.length === 1 && apiCompanies?.count === 1 && companies[0]) {
                const matchingCompany = companies[0];

                setIsMathchingInProgress(true);
                matchCompanyApi({
                    companies: [{id: matchingCompany.id, cclData: null}],
                    providerId: Number(ProvidersIdsEnum.facebook),
                    accountId: accountOauthId,
                    brandPageId: selectedBrandPageId,
                })
                    .then(() => {
                        nextStep();
                    })
                    .finally(() => {
                        pushUrl(appRoute.sources.path);
                        setIsMathchingInProgress(false);
                    })
                    .catch(() => {
                        console.error('Failed to automatically match facebook location');
                    });
            } else {
                setBrandPageId(selectedBrandPageId);
                nextStep();
            }
        },
        [accountOauthId, apiCompanies?.count, companies, nextStep, pushUrl]
    );

    useEffect(() => {
        if (processError && 'error' in processError && typeof processError.error === 'string') {
            snackbar.error(processError.error);
            setCurrent(0);
            setBrandPageId(null);
        }
    }, [processError, snackbar]);

    return (
        <>
            <Steps current={current}>
                <Steps.Step key={0} title={getLocalizedString('COMPANY_MATCHING__STEP__SELECT_ACCOUNT')} />
                <Steps.Step key={1} title={getLocalizedString('COMPANY_MATCHING__STEP__MATCH_PAGES')} />
                <Steps.Step key={2} title={getLocalizedString('COMPANY_MATCHING__STEP__MATCH_LOCATIONS')} />
            </Steps>

            {isInProgress ? (
                <Spinner />
            ) : (
                <>
                    {current === 0 && (
                        <FacebookAccountSelector
                            onSelectAccount={(accountId) => {
                                setAccountOauthId(accountId);
                                nextStep();
                            }}
                        />
                    )}

                    {current === 1 && accountOauthId && (
                        <FacebookPageMatching accountOauthId={accountOauthId} brandId={brandId} onSync={onSyncBrand} />
                    )}

                    {current === 2 &&
                        accountOauthId &&
                        brandPageId &&
                        (isMathchingInProgress ? (
                            <Spinner />
                        ) : (
                            <AccountsMatchingForm
                                accountMatchingData={accountMatchingData}
                                accountOauthId={accountOauthId}
                                brandPageId={brandPageId}
                                providerId={Number(ProvidersIdsEnum.facebook)}
                            />
                        ))}
                </>
            )}
        </>
    );
}
