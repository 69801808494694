import {Tooltip} from 'antd';

import {Locale} from '../../../../provider/locale/locale';
import {formatAddress} from '../../../../service/address/address-helper';
import {ReviewDataBrandFromListType, ReviewDataCompanyFromListType} from '../../../../service/reviews/reviews-type';
import {classNames} from '../../../../util/css';

import * as styles from './review-company-title.scss';

type PropsType = {
    brand: ReviewDataBrandFromListType;
    company: ReviewDataCompanyFromListType | null;
    className?: string;
};

export function ReviewCompanyTitle(props: PropsType): JSX.Element {
    const {brand, company, className} = props;

    if (!company) {
        return <div className={styles.ReviewCompanyTitle_brand}>{brand.name}</div>;
    }

    return (
        <address className={classNames(styles.ReviewCompanyTitle_address, className)}>
            <span className={styles.ReviewCompanyTitle_companyName}>{company?.name || ''}</span>

            <span>{formatAddress(company.address)}</span>

            <Tooltip placement="bottom" title={<Locale stringKey="REVIEWS__REVIEW__COMPANY_CODE__HINT" />}>
                <span className={styles.ReviewCompanyTitle_code}>{company.code}</span>
            </Tooltip>
        </address>
    );
}
