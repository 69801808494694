import {useEffect} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {postCompanySelectorCompanySchema} from '../../../../service/feature-companies/feature-companies-api-type';
import {CompaniesSelectorFeatureEnum} from '../../../../service/feature-companies/feature-companies-const';
import {useCompaniesSelector} from '../../../../service/feature-companies/feature-companies-hook';
import {PostSelectorCompanyType} from '../../../../service/feature-companies/feature-companies-type';
import {PostFormFieldsEnum, PostFormModeEnum} from '../../../../service/posts/posts-types';
import {PostForm} from '../post-form/post-form';
import {POST_FORM_EMPTY_VALUES} from '../post-form/posts-form-helper';
import * as styles from '../posts.scss';

export function CreatePost(): JSX.Element {
    const {postsManagement} = appRoute;

    const {getLocalizedString} = useLocale();

    const companiesSelector = useCompaniesSelector<PostSelectorCompanyType>({
        feature: CompaniesSelectorFeatureEnum.Posts,
        resourceId: null,
        companySchema: postCompanySelectorCompanySchema,
        initialCompaniesCount: 0,
    });

    const {initializeSelector, selectorId} = companiesSelector;

    useEffect(() => {
        initializeSelector();
    }, [initializeSelector]);

    return (
        <Page mainClassName={styles.PostsFormPageContainer}>
            <Meta title={getLocalizedString('CATEGORY_NAME__CREATE_POSTS')} />

            <BreadCrumbs
                list={[
                    {path: postsManagement.path, titleLangKey: 'CATEGORY_NAME__POSTS'},
                    {path: '', titleLangKey: 'CATEGORY_NAME__CREATE_POSTS'},
                ]}
            />

            <PageHeader>
                <Locale stringKey="POSTS__CREATE_POST__HEADER" />
            </PageHeader>

            {selectorId && (
                <PostForm
                    companiesSelector={companiesSelector}
                    formMode={PostFormModeEnum.New}
                    initialValues={{
                        ...POST_FORM_EMPTY_VALUES,
                        [PostFormFieldsEnum.SelectorId]: selectorId,
                    }}
                />
            )}
        </Page>
    );
}
