import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {useRef} from 'react';
import {z as r} from 'zod';

import {FeedExportFormEnum} from '../../page/main/my-companies/feed/export/feed-export-page-type';
import {Locale} from '../../provider/locale/localization';
import {useModal} from '../../provider/modal/modal-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {fetchAndDeserialize, postAndDeserialize, serialize} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent} from '../../util/fetch';
import {waitForTime} from '../../util/time';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {companiesImportUrl} from '../companies-import/companies-import-const';

export type SendExportFormType = {
    [FeedExportFormEnum.EmailsForExport]: Array<string>;
    [FeedExportFormEnum.CompaniesSelectorUid]: string;
};
const url = `${companiesImportUrl}/export_companies/`;

const exportFileResponseSchema = r.object({
    exportIdentifier: r.string().optional(),
});

type ExportResponseType = r.infer<typeof exportFileResponseSchema>;

const exportFileSchema = r.object({
    fileUrls: r.array(r.string()).optional(),
    message: r.string().optional(),
});

type ExportFileType = r.infer<typeof exportFileSchema>;

export async function getExportFile(identifier: string): Promise<ExportFileType> {
    return fetchAndDeserialize(
        `${companiesImportUrl}/company_export/${identifier}/export_file/?${objectToUrlParameters({
            timestamp: Date.now(),
        })}`,
        exportFileSchema
    );
}

async function downloadFile(links: Array<string>, index: number) {
    if (index >= links.length) {
        return;
    }

    try {
        const downloadLink = document.createElement('a');

        downloadLink.href = links[index] as string;
        downloadLink.style.display = 'none';
        document.body.append(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        await waitForTime(1000);
        await downloadFile(links, index + 1);
    } catch (error) {
        console.error(error);
    }
}

export async function downloadExportFiles(links: Array<string>): Promise<void> {
    await downloadFile(links, 0);
}

export function useSendExportMutation(): UseMutationResult<ExportResponseType | void, unknown, SendExportFormType> {
    const timeout = useRef<NodeJS.Timeout>();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    async function downloadFileUpdateResult(identifier: string) {
        const response = await getExportFile(identifier);

        if (response.fileUrls) {
            downloadExportFiles(response.fileUrls);

            return;
        }

        try {
            timeout.current = setTimeout(() => downloadFileUpdateResult(identifier), 10_000);
        } catch (error) {
            console.error(error);
        }
    }

    function sendExportFile(options: SendExportFormType): Promise<ExportResponseType> {
        return postAndDeserialize(url, exportFileResponseSchema, options);
    }

    function sendExportEmail(options: SendExportFormType): Promise<void> {
        return fetchNoContent(`${rootApiUrl}${url}`, {
            method: FetchMethodEnum.post,
            headers: {...mainApiHeaders, ...getCsrfHeaders()},
            body: JSON.stringify(serialize(options)),
        });
    }

    return useMutation({
        mutationFn: (options): Promise<ExportResponseType | void> => {
            return options[FeedExportFormEnum.EmailsForExport] ? sendExportEmail(options) : sendExportFile(options);
        },
        onSuccess: (data) => {
            if (data?.exportIdentifier) {
                downloadFileUpdateResult(data.exportIdentifier);
            }

            modal.success({
                title: data?.exportIdentifier ? (
                    <Locale stringKey="FEED__EXPORT__MODAL__FILE_SUCCESS__TITLE" />
                ) : (
                    <Locale stringKey="FEED__EXPORT__MODAL__EMAIL_SUCCESS__TITLE" />
                ),
                content: data?.exportIdentifier ? (
                    <Locale stringKey="FEED__EXPORT__MODAL__FILE_SUCCESS__CONTENT" />
                ) : (
                    <Locale stringKey="FEED__EXPORT__MODAL__EMAIL_SUCCESS__CONTENT" />
                ),
            });
        },
        onError: () => {
            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        },
    });
}
