import {useEffect} from 'react';

import {Page} from '../../../layout/page/page';
import {Spinner} from '../../../layout/spinner/spinner';
import {Locale} from '../../../provider/locale/localization';
import {useChangeEmailMutation, useChangeEmailUrlQuery} from '../../../service/user/user-change-email';

const titleLangKey = 'CHANGE_EMAIL__TITLE';

export function ChangeEmail(): JSX.Element {
    const {isCorrectUrl} = useChangeEmailUrlQuery();
    const {mutate, isError} = useChangeEmailMutation();

    useEffect(() => {
        mutate();
    }, [mutate]);

    if (!isCorrectUrl) {
        return (
            <Page.Error titleLangKey={titleLangKey}>
                <Locale stringKey="CHANGE_EMAIL__INCORRECT_LINK" />
            </Page.Error>
        );
    }

    if (isError) {
        return (
            <Page.Error titleLangKey={titleLangKey}>
                <Locale stringKey="CHANGE_EMAIL__ERROR_WHILE_CHANGING" />
            </Page.Error>
        );
    }

    return (
        <Page.Error titleLangKey={titleLangKey}>
            <Locale stringKey="CHANGE_EMAIL__WE_ARE_CHANGING" />

            <Spinner />
        </Page.Error>
    );
}
