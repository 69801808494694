import {Locale} from '../../../../../provider/locale/locale';

import {
    ComplaintWasLeftEnum,
    ContentOfReviewsEnum,
    DeletedReviewsEnum,
    ModeratedEnum,
    PossibilityOfAnAnswerEnum,
    RepliesToReviewsEnum,
    ReviewChangedEnum,
    ReviewNameEnum,
    WaitingForSecondAnswerEnum,
    WithoutTagEnum,
} from './reviews-filter-type';

export const reviewsFormKeyName = {
    dateRange: 'date-range',
};

export const WITHOUT_TAGS_OPTIONS = [
    {
        label: <Locale stringKey="REVIEWS__FILTER__WITH_TAGS" />,
        value: WithoutTagEnum.withoutTagFalse,
    },
    {
        label: <Locale stringKey="REVIEWS__FILTER__NO_TAGS" />,
        value: WithoutTagEnum.withoutTagTrue,
    },
];

export const CONTENT_OF_REVIEWS_OPTIONS = [
    {
        label: <Locale stringKey="REVIEWS__FILTER__REVIEWS_WITH_TEXT" />,
        value: ContentOfReviewsEnum.reviewsWithText,
    },
    {
        label: <Locale stringKey="REVIEWS__FILTER__REVIEWS_WITHOUT_TEXT" />,
        value: ContentOfReviewsEnum.reviewsWithoutText,
    },
];

export const DELETED_REVIEWS_OPTIONS = [
    {label: <Locale stringKey="TEXT__YES" />, value: DeletedReviewsEnum.yes},
    {label: <Locale stringKey="TEXT__NO" />, value: DeletedReviewsEnum.no},
];

export const REPLIES_TO_REVIEWS_OPTIONS = [
    {
        label: <Locale stringKey="REVIEWS__FILTER__REVIEWS_WITH_A_RESPONSE" />,
        value: RepliesToReviewsEnum.ReviewsWithAResponse,
    },
    {
        label: <Locale stringKey="REVIEWS__FILTER__REVIEWS_UNANSWERED" />,
        value: RepliesToReviewsEnum.ReviewsUnanswered,
    },
];

export const POSSIBILITY_OF_AN_ANSWER_OPTIONS = [
    {
        label: <Locale stringKey="REVIEWS__FILTER__THERE_IS_A_POSSIBILITY_OF_AN_ANSWER" />,
        value: PossibilityOfAnAnswerEnum.HasPossibilityOfAnAnswer,
    },
    {
        label: <Locale stringKey="REVIEWS__FILTER__THERE_IS_NO_POSSIBILITY_OF_AN_ANSWER" />,
        value: PossibilityOfAnAnswerEnum.HasNoPossibilityOfAnAnswer,
    },
];

export const REVIEW_TYPE_OPTIONS = [
    {
        label: <Locale stringKey="REVIEWS__FILTER__REVIEWS_FOR_THE_BRAND" />,
        value: ReviewNameEnum.ReviewsForTheBrand,
    },
    {
        label: <Locale stringKey="REVIEWS__FILTER__REVIEWS_FOR_COMPANIES" />,
        value: ReviewNameEnum.ReviewsForCompanies,
    },
];

export const THE_COMPLAINT_WAS_LEFT_OPTIONS = [
    {label: <Locale stringKey="TEXT__YES" />, value: ComplaintWasLeftEnum.yes},
    {label: <Locale stringKey="TEXT__NO" />, value: ComplaintWasLeftEnum.no},
];

export const REVIEW_CHANGED_OPTIONS = [
    {label: <Locale stringKey="TEXT__YES" />, value: ReviewChangedEnum.yes},
    {label: <Locale stringKey="TEXT__NO" />, value: ReviewChangedEnum.no},
];

export const WAITING_FOR_A_SECOND_ANSWER_OPTIONS = [
    {label: <Locale stringKey="TEXT__YES" />, value: WaitingForSecondAnswerEnum.yes},
    {label: <Locale stringKey="TEXT__NO" />, value: WaitingForSecondAnswerEnum.no},
];

export const MODERATED_ANSWER_OPTIONS = [
    {label: <Locale stringKey="TEXT__YES" />, value: ModeratedEnum.yes},
    {label: <Locale stringKey="TEXT__NO" />, value: ModeratedEnum.no},
];

export const FilterFormName = 'reviews-filter';

const MAGNIT_DEFAULT_ID = 110_753;

function getHostName() {
    return typeof location !== 'undefined' ? location.hostname : '';
}

export const BIG_BRAND_IDS = getHostName().includes('.space')
    ? [65_682, 65_768]
    : [
          110_758,
          110_763,
          110_757,
          110_762,
          110_756,
          110_498,
          MAGNIT_DEFAULT_ID,
          110_489,
          12_467,
          110_758,
          205_051,
          287_803,
          312_602,
          191_810,
          376_267,
          57_346,
          260_346,
          122_460,
          219_146,
          106_345,
      ];
