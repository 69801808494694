import {DatePicker} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useMemo} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {useLicenses} from '../../../../../../../provider/license/license-hook';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useDateTimePickerDisabler} from '../../../../../../../service/datepicker/datetime-picker-hook';
import {useFormRules} from '../../../../../../../service/form/form-rules-hook';
import {
    PostFormFieldsEnum,
    PostFormType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {FeaturesEnum} from '../../../../../../../service/user/user-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../post-form-type';
import {DATE_TIME_FORMAT} from '../../../posts-form-helper';

import {dayJsInSelectedTimezone} from './helper';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
} & PostFormItemPropsType;

export function CreationDateTimeItem(props: PropsType): JSX.Element {
    const {formInstance, disabled} = props;
    const {getLocalizedString} = useLocale();
    const {validateDayjsAfterRule, validateDayjsBeforeRule} = useFormRules();

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.posts];

    const timezone = Form.useWatch(PostFormFieldsEnum.Timezone, formInstance);

    const isYandexSelected: boolean = useMemo(() => {
        return formInstance
            .getFieldValue(PostFormFieldsEnum.Posts)
            .some((post) => post[SourcePostFormFieldsEnum.Catalog] === PostSourceEnum.yandex);
    }, [formInstance]);

    const deleteDayTime = Form.useWatch<PostFormFieldsEnum.DeleteDateTime, FormInstance<PostFormType>>(
        PostFormFieldsEnum.DeleteDateTime,
        formInstance
    ) as PostFormType[PostFormFieldsEnum.DeleteDateTime] | null;

    const {dateDisabled: dispatchDateDisabled, timeDisabled: dispatchTimeDisabled} = useDateTimePickerDisabler({
        after: dayJsInSelectedTimezone(timezone),
        before: deleteDayTime,
    });

    function handleChange(date: Dayjs | null) {
        if (!date) {
            formInstance.validateFields([PostFormFieldsEnum.Timezone]);
        }
    }

    return (
        <>
            <Form.Item<PostFormType>
                dependencies={[PostFormFieldsEnum.Timezone]}
                initialValue={null}
                label={<Locale stringKey="POSTS_FORM__SCHEDULE_POST__CREATION_DATE_TIME__LABEL" />}
                name={PostFormFieldsEnum.CreateDateTime}
                rules={
                    !disabled
                        ? [
                              validateDayjsAfterRule(
                                  getLocalizedString('POSTS_VALIDATION_ERROR__DATE_NOT_IN_FUTURE'),
                                  dayJsInSelectedTimezone(timezone).toDate()
                              ),
                              validateDayjsBeforeRule(
                                  getLocalizedString('POSTS_VALIDATION_ERROR__LICENSE_WILL_EXPIRE_ON_DATE'),
                                  dayJsInSelectedTimezone(timezone, dayjs(license?.finishAt)).toDate()
                              ),
                          ]
                        : []
                }
            >
                <DatePicker
                    disabled={disabled}
                    disabledDate={dispatchDateDisabled}
                    disabledTime={dispatchTimeDisabled}
                    format={DATE_TIME_FORMAT}
                    onChange={handleChange}
                    showTime={{format: 'HH:mm'}}
                />
            </Form.Item>
            <Text block secondary>
                <Locale
                    stringKey={isYandexSelected ? 'POSTS__CREATION_DATE__HELPER' : 'POSTS__EMPTY_CREATION_DATE__HELPER'}
                />
            </Text>
        </>
    );
}
