import {z as r} from 'zod';

import {ReviewsAnalysisModeEnum} from '../reivew-analysis-const';
import {ReviewRateEnum} from '../review-rate/review-rate-type';
import {sentimentDataSchema, SentimentEnum} from '../review-sentiment/review-sentiment-type';

const reviewTopicSchema = r.object({
    userTopic: r.boolean(),
    userTopicId: r.number().nullable(),
    topic: r.string(),
    total: r.number(),
    avgRating: r.number(),
    replied: r.number(),
    repliedPercent: r.number(),
    avgResponseSpeed: r.number().nullable(),
    sentiment: sentimentDataSchema,
    trendByThreeMonths: r.array(
        r.object({
            date: r.string(),
            value: r.number(),
        })
    ),
});

export type ReviewTopicType = r.infer<typeof reviewTopicSchema>;

export const reviewTopicsResponseSchema = r.object({
    count: r.number(),
    page: r.number(),
    pages: r.number(),
    next: r.string().optional(),
    previous: r.string().optional(),
    pageSize: r.number(),
    results: r.array(reviewTopicSchema),
});

export const reviewTopicSearchSchema = r.array(r.string());

export enum ReviewAnalysisTopicTypeEnum {
    all = 'all',
    auto = 'auto',
    user = 'user',
}

export type ReviewsAnalysisTopicsQueryType = {
    mode?: ReviewsAnalysisModeEnum;
    brandIds?: Array<number>;
    startDate: string | null;
    endDate: string | null;
    sentiments: Array<SentimentEnum>;
    sources: Array<number>;
    tags: Array<string>;
    phrases: Array<string>;
    topics: Array<string>;
    rating: Array<ReviewRateEnum>;
    withAnswers: boolean | null;
    withText: boolean | null;
    ableToReply: boolean | null;
    topicType: ReviewAnalysisTopicTypeEnum | null;
};

export const reviewUserTopicTypeSchema = r.object({
    id: r.number(),
    name: r.string(),
    includeTags: r.array(r.string()).optional(),
    excludeTags: r.array(r.string()).optional(),
    brands: r.array(r.number()),
});

export type ReviewUserTopicType = r.infer<typeof reviewUserTopicTypeSchema>;
