import {PostImageType} from '../../../../../../../../../../../service/posts/posts-types';
import {classNames} from '../../../../../../../../../../../util/css';

import * as styles from './facebook-preview-images.scss';

type PropsType = {
    images: Array<PostImageType>;
};

export function FacebookPreviewImages(props: PropsType): JSX.Element | null {
    const {images} = props;

    if (images.length === 0) {
        return null;
    }

    return (
        <div
            className={classNames(styles.FacebookPreviewImages, {
                [styles.FacebookPreviewImages__singleImage]: images.length === 1,
                [styles.FacebookPreviewImages__twoImages]: images.length === 2,
                [styles.FacebookPreviewImages__threeImages]: images.length === 3,
                [styles.FacebookPreviewImages__fourImages]: images.length === 4,
            })}
        >
            {images.map((image) => (
                <div
                    className={classNames(styles.FacebookPreviewImages_image, {
                        [styles.FacebookPreviewImages_image__main]: image.isMain,
                        [styles.FacebookPreviewImages__fourImages__mainImage]: image.isMain && images.length === 4,
                    })}
                    key={image.url}
                    style={{
                        backgroundImage: `url(${image.url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                />
            ))}
        </div>
    );
}
