import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {generatePath} from 'react-router';
import {z as r} from 'zod';

import {appRoute} from '../../app-route';
import {localStorageLastRoutePathKey} from '../../component/app/routing/routing-const';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';
import {useMessage} from '../../provider/message/message-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useUser} from '../../provider/user/user-hook';
import {deserializeApiError, postAndDeserialize} from '../../util/api-adapter';
import {carrotQuestAuthenticateUser} from '../../util/carrot-quest/carrot-quest';
import {ApiError} from '../../util/error';
import {HttpCodeEnum} from '../../util/fetch-const';
import {getFromLocalStorage} from '../../util/local-storage';
import {useUrl} from '../../util/url-hook/url-hook';
import {AnalyticsTarget, track} from '../analytics/analytics';

import {fetchCheckQuiz, QUIZ_STORAGE_KEY} from './initial-questionnaire';
import {userUrl} from './user-const';
import {lastRoutePathSchema} from './user-type';

const loginSchema = r.object({
    result: r.literal('ok').or(r.literal('error')).optional(),
    authId: r.string().optional(),
    need2faEnable: r.boolean().optional(),
});

type LoginType = r.infer<typeof loginSchema>;

const loginErrorSchema = r.object({
    detail: r.string().optional(),
});

type LoginErrorType = r.infer<typeof loginErrorSchema>;

export enum LoginFormKeyEnum {
    Email = 'email',
    Password = 'password',
    RememberMe = 'rememberMe',
}

export type LoginFormType = {
    [LoginFormKeyEnum.Email]: string;
    [LoginFormKeyEnum.Password]: string;
    [LoginFormKeyEnum.RememberMe]: boolean;
};

type LoginOptionsType = LoginFormType;

const url = `${userUrl}/login/`;

function login(options: LoginOptionsType, shortLocaleName: ShortLocaleNameEnum): Promise<LoginType> {
    return postAndDeserialize(url, loginSchema, options, {unauthorized: true, shortLocaleName});
}

export function useLoginMutation(): UseMutationResult<LoginType, unknown, LoginOptionsType> {
    const {getUser} = useUser();
    const {pushUrl} = useUrl();
    const {message} = useMessage();
    const {snackbar} = useSnackbar();
    const {shortLocaleName} = useLocale();
    const lastRoutePath = getFromLocalStorage<string>(
        localStorageLastRoutePathKey,
        lastRoutePathSchema,
        appRoute.root.path
    );

    function mutate(options: LoginOptionsType) {
        return login({...options, email: options.email.trim()}, shortLocaleName);
    }

    // eslint-disable-next-line max-statements
    async function onSuccess(data: LoginType) {
        if (!(data.result === 'ok' || data.authId)) {
            throw new Error('AuthorizationResult is not "ok"');
        }

        if (data.authId) {
            const route = data.need2faEnable
                ? appRoute.twoFactorAuthenticationSetup.path
                : appRoute.twoFactorAuthentication.path;

            pushUrl(generatePath(route, {authId: data.authId}));

            return;
        }

        const user = await getUser();

        if (user instanceof Error) {
            snackbar.error({
                message: <Locale stringKey="SNACKBAR__ERROR__LOGIN" />,
                description: <Locale stringKey="SNACKBAR__ERROR__LOGIN_DESCRIPTION" />,
            });

            return;
        }

        if (localStorage.getItem(QUIZ_STORAGE_KEY)) {
            pushUrl(lastRoutePath);
        } else {
            try {
                const responce = await fetchCheckQuiz();

                if (responce.showQuiz) {
                    pushUrl(appRoute.questionnaire.path);
                } else {
                    pushUrl(lastRoutePath);
                }
            } catch (error) {
                console.error(error);
            }
        }

        message.info(<Locale stringKey="SNACKBAR__SUCCESS__LOGIN" />);

        track(AnalyticsTarget.PersonalCabinet.Login);
        carrotQuestAuthenticateUser(user.id.toString());
    }

    function onError(error: unknown) {
        if (error instanceof ApiError && error.statusCode === HttpCodeEnum.TooManyRequests) {
            const apiError = deserializeApiError<LoginErrorType>(url, loginErrorSchema, error);

            snackbar.error({
                message: <Locale stringKey="SNACKBAR__ERROR__LOGIN" />,
                description: apiError?.detail || <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });

            return;
        }

        snackbar.error({
            message: <Locale stringKey="SNACKBAR__ERROR__LOGIN" />,
            description: <Locale stringKey="SNACKBAR__ERROR__LOGIN_DESCRIPTION" />,
        });
    }

    return useMutation(mutate, {onSuccess, onError});
}
