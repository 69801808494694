import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';

import {
    googleAccountSchema,
    GoogleAccountsOptionsType,
    googleAccountsSchema,
    GoogleAccountsType,
    GoogleAccountType,
} from './google-accounts-type';

export const googleAccountsUrl = `/cp/oauth_accounts/google/`;

export function fetchGoogleAccounts(options: GoogleAccountsOptionsType): Promise<GoogleAccountsType> {
    const url = `${googleAccountsUrl}?${objectToUrlParameters(serializeToURLParameters(options))}`;

    return fetchAndDeserialize(url, googleAccountsSchema);
}

export function getGoogleAccountUrl(accountId: number): string {
    return `${googleAccountsUrl}${accountId}/`;
}

export function fetchGoogleAccount(accountId: number): Promise<GoogleAccountType> {
    return fetchAndDeserialize(getGoogleAccountUrl(accountId), googleAccountSchema);
}
