import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {LicensesBlockEnum} from '../licenses-block-type';

export const keyColumnName = 'key';
export enum AdditionalLicenseKeyEnum {
    Dates = 'dates',
    Limit = 'limit',
    Requests = 'requests',
    Variant = 'variant',
    ProviderCodes = 'providerCodes',
    ConversationsLimit = 'conversationsLimit',
    SourcesLimit = 'sourcesLimit',
    UsersLimit = 'usersLimit',
    ProductType = 'types',
}

export enum ProductTypeEnum {
    Duplicates = 'duplicates',
    Fakes = 'fakes',
    Competitor = 'competitor',
    Base = 'base',
    Full = 'full',
}

export enum OmnichannelOptionsEnum {
    HasTags = 'hasTags',
    HasAnalytics = 'hasAnalytics',
    HasSentiments = 'hasSentiments',
    HasResponseTemplate = 'hasResponseTemplate',
}

export enum LicensesCodeEnum {
    AutoReviewLicence = 'auto_review_licence',
    MlAutoReviewLicence = 'ml_auto_review_licence',
    CatalogsSynchronizeLicence = 'catalogs_synchronize_licence',
    CompanyGroupLicense = 'company_group_license',
    CompanyLicence = 'company_licence',
    GptReplySuggestionsLicence = 'gpt_reply_suggestions_licence',
    FakesAndDuplicatesLicence = 'fakes_and_duplicates_licence',
    LocalPackLicence = 'local_pack_licence',
    MailingListLicence = 'mailing_list_licence',
    NotificationsLicence = 'notifications_licence',
    OmnichannelLicence = 'omnichannel_licence',
    OnlinePresenceAnalyticsLicence = 'online_presence_analytics_licence',
    PhototoolLicence = 'phototool_licence',
    PostsLicence = 'posts_licence',
    PricesLicence = 'prices_licence',
    PublicApiLicence = 'public_api_licence',
    ReviewAnalyticsLicence = 'review_analytics_licence',
    ReviewLicence = 'review_licence',
    ReviewsGeneratorLicence = 'reviews_generator_licence',
    RoleLicence = 'role_licence',
    StorelocatorLicence = 'storelocator_licence',
    SourceGroupReviewsEmployersLicence = 'source_group_reviews_employers_licence',
    UsedeskIntegrationLicence = 'usedesk_integration_licence',
}

export const additionalLicenseKeyMap: Record<AdditionalLicenseKeyEnum, LangKeyType> = {
    [AdditionalLicenseKeyEnum.UsersLimit]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__USERS',
    [AdditionalLicenseKeyEnum.Limit]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__LIMIT',
    [AdditionalLicenseKeyEnum.Dates]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__DATES',
    [AdditionalLicenseKeyEnum.SourcesLimit]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__SOURCES',
    [AdditionalLicenseKeyEnum.ConversationsLimit]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__DIALOGS',
    [AdditionalLicenseKeyEnum.ProviderCodes]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__CATALOGS',
    [AdditionalLicenseKeyEnum.Variant]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__VARIANT',
    [AdditionalLicenseKeyEnum.Requests]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__REQUESTS',
    [AdditionalLicenseKeyEnum.ProductType]: 'TARIFFS__LICENSE_BLOCK__OPTIONAL_KEYS__TYPES',
};

export const buyButtonKeyMap: Record<LicensesBlockEnum, LangKeyType> = {
    [LicensesBlockEnum.TestActive]: 'TARIFFS__LICENSE_BLOCK__BUY_BUTTON__BUY',
    [LicensesBlockEnum.TestAvailable]: 'TARIFFS__LICENSE_BLOCK__BUY_BUTTON__ACTIVATE',
    [LicensesBlockEnum.LicensesWithLimits]: 'TARIFFS__LICENSE_BLOCK__BUY_BUTTON__CHANGE_LIMIT',
    [LicensesBlockEnum.AdditionalLicenses]: 'TARIFFS__LICENSE_BLOCK__BUY_BUTTON__BUY',
};

export const tagTextKeyMap: Record<LicensesBlockEnum, LangKeyType> = {
    [LicensesBlockEnum.TestActive]: 'TARIFFS__LICENSE_BLOCK__TAG_TITLE__TEST',
    [LicensesBlockEnum.TestAvailable]: 'TARIFFS__LICENSE_BLOCK__TAG_TITLE__TEST_AVAILABLE',
    [LicensesBlockEnum.LicensesWithLimits]: 'TARIFFS__LICENSE_BLOCK__TAG_TITLE__ACTIVE',
    [LicensesBlockEnum.AdditionalLicenses]: 'TARIFFS__LICENSE_BLOCK__TAG_TITLE__ACTIVE',
};

export const productTypeKeyMap: Record<ProductTypeEnum, LangKeyType> = {
    [ProductTypeEnum.Fakes]: 'FAKES__RESULTS__FILTERS__TYPE__FAKES',
    [ProductTypeEnum.Duplicates]: 'FAKES__RESULTS__FILTERS__TYPE__DUPLICATES',
    [ProductTypeEnum.Base]: 'TARIFFS__LICENSE_BLOCK__PRODUCT_TYPE__BASE_REVIEW',
    [ProductTypeEnum.Competitor]: 'TARIFFS__LICENSE_BLOCK__PRODUCT_TYPE__COMPETITOR_REVIEW',
    [ProductTypeEnum.Full]: 'TARIFFS__LICENSE_BLOCK__PRODUCT_TYPE__FULL_REVIEW',
};
export const omnichannelOptionsKeyMap: Record<OmnichannelOptionsEnum, LangKeyType> = {
    [OmnichannelOptionsEnum.HasResponseTemplate]: 'TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__TEMPLATES',
    [OmnichannelOptionsEnum.HasSentiments]: 'TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__KEY',
    [OmnichannelOptionsEnum.HasTags]: 'TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__TAGS',
    [OmnichannelOptionsEnum.HasAnalytics]: 'TARIFFS__LICENSE_BLOCK__OMNICHANNEL_TYPE__ANALYTICS',
};
export const omnichannelFieldToRenderNames: Array<OmnichannelOptionsEnum> = [
    OmnichannelOptionsEnum.HasResponseTemplate,
    OmnichannelOptionsEnum.HasAnalytics,
    OmnichannelOptionsEnum.HasSentiments,
    OmnichannelOptionsEnum.HasTags,
];
