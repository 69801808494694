import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {useLocale} from '../../provider/locale/locale-hook';
import {Locale} from '../../provider/locale/localization';

import {policyLinksByDomain} from './legal-agreement-const';
import {LegalAgreementTypeEnum} from './legal-agreement-type';

export function useAgreementAcceptanceText(legalAgreementType?: LegalAgreementTypeEnum): JSX.Element | null {
    const {domainName} = useDomainConfig();
    const {shortLocaleName} = useLocale();

    const policyLinks = policyLinksByDomain[domainName];

    if (!legalAgreementType || !policyLinks) {
        return null;
    }

    const privacyPolicyLink = (
        <a href={policyLinks[LegalAgreementTypeEnum.Personal][shortLocaleName]} rel="noreferrer" target="_blank">
            <Locale stringKey="LEGAL_AGREEMENT__PRIVACY_POLICY_LINK" />
        </a>
    );

    const publicAgreementLink = (
        <a href={policyLinks[LegalAgreementTypeEnum.Public][shortLocaleName]} rel="noreferrer" target="_blank">
            <Locale stringKey="LEGAL_AGREEMENT__PUBLIC_AGREEMENT_LINK" />
        </a>
    );

    const textsByAgreementType = {
        [LegalAgreementTypeEnum.Personal]: (
            <Locale stringKey="LEGAL_AGREEMENT__PERSONAL__DESCRIPTION" valueMap={{privacyPolicyLink}} />
        ),
        [LegalAgreementTypeEnum.Public]: (
            <Locale
                stringKey="LEGAL_AGREEMENT__PUBLIC__DESCRIPTION"
                valueMap={{privacyPolicyLink, publicAgreementLink}}
            />
        ),
    };

    return textsByAgreementType[legalAgreementType];
}
