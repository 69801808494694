import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {NoUndefinedRangeValueType} from 'rc-picker/lib/PickerInput/RangePicker';

import {AntdErrorMessage} from '../../../../../../../../../component/antd-error-message/antd-error-message';
import {TimeRangePicker} from '../../../../../../../../../layout/time-range-picker/time-range-picker';
import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {TimeRangeType} from '../../../../../../../../../service/company-v2/types/company-working-time-type';
import {classNames} from '../../../../../../../../../util/css';
import {timeFormat} from '../../../working-time-const';

import {getDisabledHours, getDisabledMinutes} from './break-time-item-helper';
import * as styles from './break-time-item.scss';

type PropsType = {
    className?: string;
    updateBreakTime: (oldBreakTime: TimeRangeType, newBreakTime: TimeRangeType) => void;
    deleteBreakTime: (breakTime: TimeRangeType) => void;
    breakTime: TimeRangeType;
    prevBreakTime: TimeRangeType | null;
    errorMessage?: TimeRangeType;
};

// eslint-disable-next-line complexity
export function BreakTimeItem(props: PropsType): JSX.Element {
    const {className, updateBreakTime, breakTime, deleteBreakTime, prevBreakTime, errorMessage} = props;

    const fullClassName = classNames(styles.break_time_item, className);
    const {getLocalizedString} = useLocale();

    const timeErrors = [
        ...new Set([
            ...(errorMessage && 'start' in errorMessage && errorMessage?.start && Array.isArray(errorMessage.start)
                ? errorMessage.start
                : []),
            ...(errorMessage && 'finish' in errorMessage && errorMessage?.finish && Array.isArray(errorMessage.finish)
                ? errorMessage.finish
                : []),
        ]),
    ];

    function handleTimeChange(values: NoUndefinedRangeValueType<Dayjs> | null) {
        if (!values || !values[0] || !values[1]) {
            updateBreakTime(breakTime, {});
            return;
        }

        const [dayjsFrom, dayjsTo] = values;

        updateBreakTime(breakTime, {
            start: dayjsFrom.format(timeFormat),
            finish: dayjsTo.format(timeFormat),
        });
    }

    function getValue(): NoUndefinedRangeValueType<Dayjs> | undefined {
        if ('start' in breakTime) {
            const {start, finish} = breakTime;

            return [dayjs(start, timeFormat), dayjs(finish, timeFormat)];
        }

        // eslint-disable-next-line no-undefined
        return undefined;
    }

    return (
        <>
            <div className={fullClassName}>
                <TimeRangePicker
                    className={styles.break_time_item__time_range}
                    disabledTime={() => ({
                        disabledHours: getDisabledHours(prevBreakTime),
                        disabledMinutes: getDisabledMinutes(prevBreakTime),
                    })}
                    onChange={handleTimeChange}
                    order={false}
                    placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                    size="small"
                    value={getValue()}
                />

                <Button
                    className={styles.break_time_item__remove_break}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => deleteBreakTime(breakTime)}
                    type="text"
                />
            </div>

            {Array.isArray(timeErrors) && timeErrors.length > 0 && <AntdErrorMessage>{timeErrors}</AntdErrorMessage>}
        </>
    );
}
