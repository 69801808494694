import dayjs, {Dayjs} from 'dayjs';

import {LangKeyType} from '../../../../provider/locale/translation/type';

import {LocalPackAggregationTypeEnum} from './position-distribution-type';

export const localPackAggregationLocalizationMap: Record<LocalPackAggregationTypeEnum, LangKeyType> = {
    [LocalPackAggregationTypeEnum.day]: 'LOCAL_PACK__POSITION_DISTRIBUTION__BY_DAY',
    [LocalPackAggregationTypeEnum.week]: 'LOCAL_PACK__POSITION_DISTRIBUTION__BY_WEEK',
    [LocalPackAggregationTypeEnum.month]: 'LOCAL_PACK__POSITION_DISTRIBUTION__BY_MONTH',
};

export const localPackAggregationStartDateMap: Record<LocalPackAggregationTypeEnum, Dayjs> = {
    [LocalPackAggregationTypeEnum.day]: dayjs().subtract(1, 'months'),
    [LocalPackAggregationTypeEnum.week]: dayjs().subtract(3, 'months'),
    [LocalPackAggregationTypeEnum.month]: dayjs().subtract(1, 'years'),
};
