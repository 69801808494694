import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Form, FormInstance} from 'antd';

import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {CatalogByGroupType} from '../../../../../../service/source-settings/catalogs/catalogs-type';
import {useDeleteSourceLink} from '../../../../../../service/source-settings/source-links/source-links-hook';
import {
    NoPermitSourceLinkError,
    SourceLinkType,
} from '../../../../../../service/source-settings/source-links/source-links-type';
import {classNames} from '../../../../../../util/css';

import {deleteReviewsFormFieldName} from './remove-link-const';
import {DeleteLinkFormType} from './remove-link-type';
import * as styles from './remove-link.scss';

type PropsType = {
    catalog: CatalogByGroupType;
    linkEntity: SourceLinkType;
    onSuccess: () => void;
};

export function RemoveLink(props: PropsType): JSX.Element {
    const {catalog, linkEntity, onSuccess} = props;

    const {modal} = useModal();
    const {snackbar} = useSnackbar();
    const {deleteLink} = useDeleteSourceLink();

    const [form]: [FormInstance<DeleteLinkFormType>] = Form.useForm();
    const {companyName} = useDomainConfig();
    const {getCatalogName} = useCatalogInfo();

    async function onRemoveSubmit() {
        const values: DeleteLinkFormType = form.getFieldsValue();
        const deleteReviews: boolean = values[deleteReviewsFormFieldName];

        try {
            await deleteLink(linkEntity.id, {
                companyId: linkEntity.company?.id,
                deleteReviews,
            });
            snackbar.success({
                description: (
                    <Locale
                        stringKey={
                            deleteReviews
                                ? 'SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__SUCCESS__CONTENT__REMOVE_REVIEWS'
                                : 'SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__SUCCESS__CONTENT__KEEP_REVIEWS'
                        }
                        valueMap={{
                            catalogName: (
                                <span className={styles.RemoveLink_catalog} key="catalog">
                                    {getCatalogName(catalog.id)}
                                </span>
                            ),
                            companyName,
                        }}
                    />
                ),
                message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__SUCCESS__TITLE" />,
            });
            onSuccess();
            form.resetFields();
            return true;
        } catch (error: unknown) {
            if (error instanceof NoPermitSourceLinkError) {
                snackbar.error({
                    description: <span>{error.message}</span>,
                    message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__FAIL__TITLE" />,
                });
            } else {
                snackbar.error({
                    description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                    message: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__FAIL__TITLE" />,
                });
            }

            // throw error prevents ant-modal closing
            throw error;
        }
    }

    function onRemoveClick() {
        modal.confirm({
            title: <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__TITLE" />,
            content: (
                <>
                    <p className={styles.RemoveLink_content}>
                        <Locale
                            stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE__CONTENT"
                            valueMap={{
                                catalogName: (
                                    <span className={styles.RemoveLink_catalog} key="catalog">
                                        {getCatalogName(catalog.id)}
                                    </span>
                                ),
                            }}
                        />
                    </p>
                    <Form<DeleteLinkFormType> form={form} initialValues={{[deleteReviewsFormFieldName]: false}}>
                        <Form.Item<DeleteLinkFormType>
                            className={styles.RemoveLink_formItem}
                            name={deleteReviewsFormFieldName}
                            valuePropName="checked"
                        >
                            <Checkbox className={styles.RemoveLink_checkbox}>
                                <Locale
                                    stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__DELETE_REVIEWS__CHECKBOX__LABEL"
                                    valueMap={{companyName}}
                                />
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </>
            ),
            icon: (
                <FontAwesomeIcon className={classNames('anticon', styles.RemoveLink_icon)} icon={faCircleExclamation} />
            ),
            maskClosable: true,
            okText: <Locale stringKey="BUTTON__DELETE" />,
            okType: 'danger',
            okButtonProps: {type: 'primary'},
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            onOk: onRemoveSubmit,
            onCancel: () => form.resetFields(),
        });
    }

    return (
        <button className={styles.RemoveLink_button} onClick={onRemoveClick} type="button">
            <FontAwesomeIcon icon={faTrash} />
        </button>
    );
}
