import dayjs from 'dayjs';
import {useEffect, useMemo, useState} from 'react';

import {useUser} from '../../../../../../provider/user/user-hook';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../service/feature-companies/feature-companies-type';
import {
    PostFormFieldsEnum,
    PostFormModeEnum,
    PostFormType,
    PostStatusEnum,
    PostTypeEnum,
} from '../../../../../../service/posts/posts-types';
import {Form, FormInstance} from '../../../../../../typings/antd';
import {PostContentWrapper} from '../../post-content-wrapper/post-content-wrapper';
import * as postFormStyles from '../../post-form.scss';

import {AutoRefreshItem} from './items/auto-refresh-item';
import {CreationDateTimeItem} from './items/creation-date-time-item';
import {dayJsInSelectedTimezone} from './items/helper';
import {RemoveDateTimeItem} from './items/remove-date-time-item';
import {TimezoneItem} from './items/timezone-item';
import {PostSubmitActions} from './post-submit-actions/post-submit-actions';
import {PostSummary} from './post-summary/post-summary';
import * as styles from './send-parameters-step.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
    formMode: PostFormModeEnum;
    onCreateDraft: () => void;
    handleSubmitError: (error: unknown) => void;
};

export function SendParametersStep(props: PropsType): JSX.Element {
    const {companiesSelector, formInstance, formMode, onCreateDraft, handleSubmitError} = props;
    const [isDisabledBySelectedCompanies, setIsDisabledBySelectedCompanies] = useState(false);

    const {user} = useUser();
    const isSubmitDisabled = Boolean(isDisabledBySelectedCompanies || user?.isDemoUser);

    const timezone = Form.useWatch(PostFormFieldsEnum.Timezone, formInstance);
    const createTime = Form.useWatch(PostFormFieldsEnum.CreateDateTime, formInstance);
    const deleteTime = Form.useWatch(PostFormFieldsEnum.DeleteDateTime, formInstance);

    useEffect(() => {
        dayJsInSelectedTimezone(timezone);
    }, [timezone]);

    const creationDateTimeDisabled = useMemo(() => {
        const initialCreateDateTime = formInstance.getFieldValue(PostFormFieldsEnum.CreateDateTime);

        if (initialCreateDateTime && formMode === PostFormModeEnum.EditExisted) {
            return dayjs().isAfter(initialCreateDateTime);
        }

        const postStatus = formInstance.getFieldValue(PostFormFieldsEnum.Status);

        return formMode === PostFormModeEnum.View || postStatus === PostStatusEnum.Sent;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allowRefreshSettings = useMemo(() => {
        const sourcePosts = formInstance.getFieldValue([PostFormFieldsEnum.Posts]);

        if (sourcePosts.length === 1) {
            const singleSourcePost = sourcePosts[0];

            return !(
                singleSourcePost?.topicType === PostTypeEnum.googleEvent ||
                singleSourcePost?.topicType === PostTypeEnum.googleOffer
            );
        }

        return true;
    }, [formInstance]);

    const removeDateTimeDisabled = useMemo(() => {
        const initialRemoveDateTime = formInstance.getFieldValue(PostFormFieldsEnum.DeleteDateTime);

        if (initialRemoveDateTime && formMode === PostFormModeEnum.EditExisted) {
            return dayjs().isAfter(initialRemoveDateTime);
        }

        return formMode === PostFormModeEnum.View;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={postFormStyles.PostForm_step}>
            <div className={postFormStyles.PostForm_stepContent}>
                <PostContentWrapper className={styles.SendParametersStep_contentWrapper}>
                    <PostContentWrapper.Left>
                        <CreationDateTimeItem disabled={creationDateTimeDisabled} formInstance={formInstance} />

                        <RemoveDateTimeItem disabled={removeDateTimeDisabled} formInstance={formInstance} />

                        <TimezoneItem
                            disabled={creationDateTimeDisabled && removeDateTimeDisabled}
                            formInstance={formInstance}
                            required={Boolean(createTime) || Boolean(deleteTime)}
                        />

                        {allowRefreshSettings && (
                            <AutoRefreshItem
                                disabled={formMode === PostFormModeEnum.View}
                                formInstance={formInstance}
                            />
                        )}
                    </PostContentWrapper.Left>
                    <PostContentWrapper.Right>
                        {(formMode === PostFormModeEnum.EditDraft || formMode === PostFormModeEnum.New) && (
                            <PostSummary
                                companiesSelector={companiesSelector}
                                formInstance={formInstance}
                                onSetIsDisabled={setIsDisabledBySelectedCompanies}
                            />
                        )}
                    </PostContentWrapper.Right>
                </PostContentWrapper>
                <PostSubmitActions
                    formInstance={formInstance}
                    formMode={formMode}
                    handleSubmitError={handleSubmitError}
                    isDisabled={isSubmitDisabled}
                    onCreateDraft={onCreateDraft}
                />
            </div>
        </div>
    );
}
