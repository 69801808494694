import {Tabs} from 'antd';
import {useMemo} from 'react';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useOnlinePresenceStatistics} from '../../../../service/dashboard/dashboard-hook';
import {OnlinePresenceAnalyticsTemporaryProblems} from '../../online-presence/online-presence-warning/online-presence-ananytics-wgis-problems';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {DashboardOnlinePresenceCatalogStats} from './dashboard-online-presence-catalog-stats/dashboard-online-presence-catalog-stats';
import {numberOfDays} from './online-presence-summary-const';
import * as styles from './online-presence-summary.scss';

export function OnlinePresenceSummary(): JSX.Element {
    const dateFrom = useMemo(() => {
        const temporaryDate = new Date();

        temporaryDate.setDate(temporaryDate.getDate() - numberOfDays);
        return temporaryDate;
    }, []);

    const dateTo = useMemo(() => {
        return new Date();
    }, []);

    const {result, isInProgress, processError} = useOnlinePresenceStatistics({
        dateFrom,
        dateTo,
    });

    const {getCatalogName} = useCatalogInfo();

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__TITLE" />}
                    widget={DashboardWidgetEnum.OnlinePresence}
                />
            }
        >
            <OnlinePresenceAnalyticsTemporaryProblems />

            <div className={styles.OnlinePresenceSummary_tabsContainer}>
                <Tabs
                    className={styles.OnlinePresenceSummary_tab}
                    items={result.results.map((item) => ({
                        key: item.catalog.id.toString(),
                        label: getCatalogName(item.catalog.id),
                        children: (
                            <DashboardOnlinePresenceCatalogStats
                                baseMetrics={item.baseMetrics}
                                dynamicMetrics={item.dynamicMetrics}
                            />
                        ),
                    }))}
                />
            </div>
            <div className={styles.OnlinePresenceSummary_footer}>
                <Locale
                    stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__ONLINE_PRESENCE_LINK"
                    valueMap={{
                        link: (
                            <NavigationLink key="online_presence_link" to={appRoute.onlinePresence.path}>
                                <Locale stringKey="DASHBOARD_PAGE__ONLINE_PRESENCE__ONLINE_PRESENCE_LINK__TEXT" />
                            </NavigationLink>
                        ),
                    }}
                />
            </div>
        </PageCard>
    );
}
