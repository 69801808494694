import {Button, ButtonProps} from 'antd';
import {PropsWithChildren} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {BreadCrumbItemType} from '../../../layout/bread-crumbs/bread-crumbs-type';
import {NavigationLink} from '../../../layout/navigation-link/navigation-link';
import {Page} from '../../../layout/page/page';
import {PageHeader} from '../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import * as responseTemplatesTableStyles from '../response-templates/response-templates-table.scss';

import * as styles from './companies-group-management.scss';

type PropsType = PropsWithChildren<{
    breadcrubmsList: Array<BreadCrumbItemType>;
    header: JSX.Element;
    onSave: () => void;
    saveButtonProps: ButtonProps;
}>;

export function CompaniesGroupManagement(props: PropsType): JSX.Element {
    const {breadcrubmsList, header, onSave, saveButtonProps, children} = props;
    const {getLocalizedString} = useLocale();

    return (
        <Page>
            <Meta title={getLocalizedString('GROUP_OF_COMPANIES__EDIT__GROUP_MANAGEMENT')} />

            <BreadCrumbs list={breadcrubmsList} />

            <PageHeader>
                {header}

                <div className={responseTemplatesTableStyles.buttons_container}>
                    <NavigationLink to={appRoute.settingsCompaniesGroup.path}>
                        <Button>
                            <Locale stringKey="BUTTON__CANCEL" />
                        </Button>
                    </NavigationLink>
                    &nbsp;
                    <Button
                        disabled={saveButtonProps.disabled}
                        loading={saveButtonProps.loading}
                        onClick={onSave}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>
                </div>
            </PageHeader>

            <PageSubHeader>
                <Locale stringKey="GROUP_OF_COMPANIES__GROUP_MANAGEMENT__SUB_HEADER" />
            </PageSubHeader>

            <div className={styles.companies_group_edit__form_wrapper}>{children}</div>
        </Page>
    );
}
