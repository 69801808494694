import {Button} from 'antd';

import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../provider/domain-config/domain-config-type';
import {Locale} from '../../provider/locale/locale';
import {LangKeyType} from '../../provider/locale/translation/type';

import pointOnMapImageSrc from './image/point-on-map.svg';
import * as styles from './activation-page.scss';

type PropsType = {
    link?: string;
    interestedMessageLangKey?: LangKeyType;
    hideMessageHeader?: boolean;
};

export function ActivationBanner(props: PropsType): JSX.Element {
    const {link, interestedMessageLangKey, hideMessageHeader} = props;

    const {domainName, companyName} = useDomainConfig();

    return (
        <div className={styles.activation_page__content}>
            <img alt="" className={styles.activation_page__image} src={pointOnMapImageSrc} />

            {!hideMessageHeader && (
                <h4 className={styles.activation_page__header}>
                    <Locale
                        stringKey="ACTIVATION_PAGE__EXPAND_THE_CAPABILITIES_OF_THE_ACCOUNT"
                        valueMap={{companyName}}
                    />
                </h4>
            )}

            <p className={styles.activation_page__text}>
                <Locale
                    stringKey={
                        interestedMessageLangKey ||
                        'ACTIVATION_PAGE__INTERESTED_CONTACT_YOUR_MANAGER_TO_ACTIVATE_THIS_SERVICE'
                    }
                    valueMap={{break: <br key="break" />}}
                />
            </p>
            {domainName === DomainNameEnum.rocketData && link && (
                <a className={styles.activation_page__link} href={link} rel="noreferrer" target="_blank">
                    <Button type="primary">
                        <Locale stringKey="ACTIVATION_PAGE__LEARN_MORE" />
                    </Button>
                </a>
            )}
        </div>
    );
}
