import {useQuery} from '@tanstack/react-query';
import {isBoolean} from 'lodash';
import {Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {MainFilterContext} from '../../provider/main-filter/main-filter';
import {defaultEmptyFilterState} from '../../provider/main-filter/main-filter-const';
import {MainFilterContextType} from '../../provider/main-filter/main-filter-type';
import {MainFilterQueryKeyNameEnum} from '../../shared-search-parameters';
import {debounce} from '../../util/function';
import {useRefreshId} from '../../util/hook';
import {useIsMounted} from '../../util/is-mounted';
import {useUrl} from '../../util/url-hook/url-hook';

import {
    compileMainFilterApi,
    getFilteredCompaniesCountApi,
    getLastFilterKey,
    getSuggestionsApi,
} from './main-filter-api';
import {
    MainFilterCompileResultApiType,
    MainFilterGetFilteredCountResultApiType,
    MainFilterSelectedValuesApiType,
    MainFilterSelectedValuesIdsAdditionalKeyEnum,
    MainFilterSelectedValuesIdsKeyEnum,
} from './main-filter-api-type';
import {
    fieldNameToSuggestionsFieldKey,
    fieldNameToValueIdsKeyMap,
    suggestionsPerRequestsCount,
} from './main-filter-const';
import {caseForActive, caseForOpen, getSuggestionIds} from './main-filter-helper';
import {
    FilterSuggestionType,
    MainFilterFieldHookType,
    MainFilterFieldNameEnum,
    MainFilterFieldsType,
    MainFilterHookType,
    MainFilterLoadMorePropsType,
    MainFilterLoadSuggestionsPropsType,
} from './main-filter-type';

function useHeaderFilterField(
    fieldName: MainFilterFieldNameEnum,
    providerValue: Array<FilterSuggestionType>
): MainFilterFieldHookType {
    const [value, setValue] = useState<Array<FilterSuggestionType>>([]);
    const [suggests, setSuggests] = useState<Array<FilterSuggestionType>>([]);
    const [shouldRefreshSuggests, setShouldRefreshSuggests] = useState(true);
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(true);

    const lastSuggestionId: string | null = useMemo(() => {
        return suggests[suggests.length - 1]?.id ?? null;
    }, [suggests]);

    const clearSuggests = useCallback(() => {
        setSuggests([]);
    }, []);

    useEffect(() => {
        setValue(providerValue);
    }, [providerValue]);

    return {
        value,
        setValue,
        suggests,
        setSuggests,

        shouldRefreshSuggests,
        setShouldRefreshSuggests,

        clearSuggests,
        isInProgress,
        setIsInProgress,

        canLoadMore,
        setCanLoadMore,

        lastSuggestionId,
        fieldName,
    };
}

// eslint-disable-next-line max-statements
export function useHeaderFilter(): MainFilterHookType {
    const {
        state: mainFilterState,
        update: updateMainFilter,
        mainFilterKey,
        filteredCount,
    } = useContext<MainFilterContextType>(MainFilterContext);

    const [isCompilingFilter, setIsCompilingFilter] = useState<boolean>(false);
    const [isFetchingFilteredCount, setIsFetchingFilteredCount] = useState<boolean>(false);
    const [filteredCompaniesCount, setFilteredCompaniesCount] = useState<number>(0);

    const {refresh, refreshId} = useRefreshId();
    const isMounted = useIsMounted();

    const brandsHook = useHeaderFilterField(
        MainFilterFieldNameEnum.BRANDS,
        mainFilterState[MainFilterFieldNameEnum.BRANDS]
    );
    const groupsHook = useHeaderFilterField(
        MainFilterFieldNameEnum.GROUPS,
        mainFilterState[MainFilterFieldNameEnum.GROUPS]
    );
    const countriesHook = useHeaderFilterField(
        MainFilterFieldNameEnum.COUNTRIES,
        mainFilterState[MainFilterFieldNameEnum.COUNTRIES]
    );
    const regionsHook = useHeaderFilterField(
        MainFilterFieldNameEnum.REGIONS,
        mainFilterState[MainFilterFieldNameEnum.REGIONS]
    );
    const citiesHook = useHeaderFilterField(
        MainFilterFieldNameEnum.CITIES,
        mainFilterState[MainFilterFieldNameEnum.CITIES]
    );
    const codesHook = useHeaderFilterField(
        MainFilterFieldNameEnum.CODES,
        mainFilterState[MainFilterFieldNameEnum.CODES]
    );
    const namesHook = useHeaderFilterField(
        MainFilterFieldNameEnum.NAMES,
        mainFilterState[MainFilterFieldNameEnum.NAMES]
    );
    const streetsHook = useHeaderFilterField(
        MainFilterFieldNameEnum.STREETS,
        mainFilterState[MainFilterFieldNameEnum.STREETS]
    );
    const closedHook = useHeaderFilterField(
        MainFilterFieldNameEnum.CLOSED,
        mainFilterState[MainFilterFieldNameEnum.CLOSED]
    );
    const activeHook = useHeaderFilterField(
        MainFilterFieldNameEnum.ACTIVE,
        mainFilterState[MainFilterFieldNameEnum.ACTIVE]
    );

    useEffect(() => {
        setFilteredCompaniesCount(filteredCount);
    }, [filteredCount]);

    const filterFields: MainFilterFieldsType = useMemo(() => {
        return {
            [MainFilterFieldNameEnum.BRANDS]: brandsHook,
            [MainFilterFieldNameEnum.CITIES]: citiesHook,
            [MainFilterFieldNameEnum.CODES]: codesHook,
            [MainFilterFieldNameEnum.COUNTRIES]: countriesHook,
            [MainFilterFieldNameEnum.GROUPS]: groupsHook,
            [MainFilterFieldNameEnum.NAMES]: namesHook,
            [MainFilterFieldNameEnum.REGIONS]: regionsHook,
            [MainFilterFieldNameEnum.STREETS]: streetsHook,
            [MainFilterFieldNameEnum.CLOSED]: closedHook,
            [MainFilterFieldNameEnum.ACTIVE]: activeHook,
        };
    }, [
        brandsHook,
        citiesHook,
        codesHook,
        countriesHook,
        groupsHook,
        namesHook,
        regionsHook,
        streetsHook,
        closedHook,
        activeHook,
    ]);

    const selectedValuesIds: MainFilterSelectedValuesApiType = useMemo(() => {
        const suggestIdFromSelectedValues = caseForOpen(closedHook.value);
        const suggestIdFromActiveValues = caseForActive(activeHook.value);

        return {
            [MainFilterSelectedValuesIdsKeyEnum.BRANDS]: getSuggestionIds(brandsHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.CITIES]: getSuggestionIds(citiesHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.CODES]: getSuggestionIds(codesHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.COUNTRIES]: getSuggestionIds(countriesHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.GROUPS]: getSuggestionIds(groupsHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.NAMES]: getSuggestionIds(namesHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.REGIONS]: getSuggestionIds(regionsHook.value),
            [MainFilterSelectedValuesIdsKeyEnum.STREETS]: getSuggestionIds(streetsHook.value),
            ...suggestIdFromSelectedValues,
            ...suggestIdFromActiveValues,
        };
    }, [
        brandsHook.value,
        citiesHook.value,
        codesHook.value,
        countriesHook.value,
        groupsHook.value,
        closedHook.value,
        namesHook.value,
        regionsHook.value,
        streetsHook.value,
        activeHook.value,
    ]);

    const loadSuggestions = useCallback(
        (props: MainFilterLoadSuggestionsPropsType) => {
            return getSuggestionsApi({
                after: props.after,
                current_filter: {
                    ...selectedValuesIds,
                    [fieldNameToValueIdsKeyMap[props.fieldName]]: [],
                },
                field_name: fieldNameToSuggestionsFieldKey[props.fieldName],
                limit: suggestionsPerRequestsCount,
                query: props.searchText,
            }).catch(() => {
                throw new Error('Failed on loading suggestions');
            });
        },
        [selectedValuesIds]
    );

    const loadFieldSuggests = useMemo(() => {
        return debounce((loadSuggestsProps: MainFilterLoadMorePropsType) => {
            const filterField = filterFields[loadSuggestsProps.fieldName];

            if (
                (filterField.canLoadMore || loadSuggestsProps.isNewSearchText) &&
                loadSuggestsProps.fieldName !== MainFilterFieldNameEnum.CLOSED &&
                loadSuggestsProps.fieldName !== MainFilterFieldNameEnum.ACTIVE
            ) {
                filterField.setIsInProgress(true);
                filterField.setShouldRefreshSuggests(false);
                loadSuggestions({
                    ...loadSuggestsProps,
                    after: loadSuggestsProps.isNewSearchText ? null : filterField.lastSuggestionId,
                })
                    .then((fetchedSuggests: Array<FilterSuggestionType>) => {
                        filterField.setSuggests(
                            loadSuggestsProps.isNewSearchText
                                ? fetchedSuggests
                                : [...filterField.suggests, ...fetchedSuggests]
                        );
                        filterField.setCanLoadMore(fetchedSuggests.length === suggestionsPerRequestsCount);
                    })
                    .finally(() => {
                        filterField.setIsInProgress(false);
                    })
                    .catch((error: Error) => {
                        console.log(error);
                    });
            }
        }, 300);
    }, [loadSuggestions, filterFields]);

    const isFilterEmpty = useMemo(() => {
        return [
            activeHook,
            brandsHook,
            groupsHook,
            countriesHook,
            regionsHook,
            citiesHook,
            closedHook,
            codesHook,
            namesHook,
            streetsHook,
        ].every((fieldHook) => fieldHook.value.length === 0);
    }, [
        brandsHook,
        citiesHook,
        codesHook,
        activeHook,
        closedHook,
        countriesHook,
        groupsHook,
        namesHook,
        regionsHook,
        streetsHook,
    ]);

    const onClear = useCallback(() => {
        brandsHook.setValue([]);
        groupsHook.setValue([]);
        countriesHook.setValue([]);
        regionsHook.setValue([]);
        citiesHook.setValue([]);
        codesHook.setValue([]);
        namesHook.setValue([]);
        streetsHook.setValue([]);
        closedHook.setValue([]);
        activeHook.setValue([]);
    }, [
        brandsHook,
        citiesHook,
        activeHook,
        closedHook,
        codesHook,
        countriesHook,
        groupsHook,
        namesHook,
        regionsHook,
        streetsHook,
    ]);

    const loadFilterCountDebounced = useMemo(() => {
        return debounce((currentFilter: MainFilterSelectedValuesApiType) => {
            if (!isFilterEmpty) {
                return getFilteredCompaniesCountApi(currentFilter)
                    .then((apiFilterCountResult: MainFilterGetFilteredCountResultApiType) => {
                        if (!isMounted.current) {
                            return;
                        }

                        setFilteredCompaniesCount(apiFilterCountResult.count);
                    })
                    .finally(() => {
                        if (!isMounted.current) {
                            return;
                        }

                        setIsFetchingFilteredCount(false);
                    })
                    .catch((error: Error) => {
                        throw error;
                    });
            }

            if (isMounted.current) {
                return setIsFetchingFilteredCount(false);
            }

            return null;
        }, 500);
    }, [isFilterEmpty]);

    useEffect(() => {
        setIsFetchingFilteredCount(true);
        loadFilterCountDebounced(selectedValuesIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId]);

    const formHasChanges = useMemo(() => {
        return Object.values(MainFilterFieldNameEnum).some((fieldName: MainFilterFieldNameEnum) => {
            return (
                mainFilterState[fieldName].length !== filterFields[fieldName].value.length ||
                mainFilterState[fieldName].some((providerValueItem: FilterSuggestionType) => {
                    const selectedIds = filterFields[fieldName].value.map((activeValueItem: FilterSuggestionType) => {
                        return activeValueItem.id;
                    });

                    return !selectedIds.includes(providerValueItem.id);
                })
            );
        });
    }, [filterFields, mainFilterState]);

    const setFieldValue = useCallback(
        (fieldName: MainFilterFieldNameEnum, newValue: Array<FilterSuggestionType>) => {
            Object.values(filterFields).forEach((fieldHook: MainFilterFieldHookType) => {
                if (fieldHook.fieldName === fieldName) {
                    fieldHook.setValue(newValue);
                } else {
                    fieldHook.setSuggests([]);
                    fieldHook.setShouldRefreshSuggests(true);
                    fieldHook.setCanLoadMore(true);
                }
            });
            refresh();
        },
        [filterFields, refresh]
    );

    const isFormEmpty = useMemo(() => {
        return Object.values(filterFields).every((fieldHook) => {
            return fieldHook.value.length === 0;
        });
    }, [filterFields]);

    const applyFilter = useCallback(() => {
        if (isFormEmpty) {
            updateMainFilter(
                {
                    [MainFilterFieldNameEnum.BRANDS]: [],
                    [MainFilterFieldNameEnum.GROUPS]: [],
                    [MainFilterFieldNameEnum.COUNTRIES]: [],
                    [MainFilterFieldNameEnum.REGIONS]: [],
                    [MainFilterFieldNameEnum.CITIES]: [],
                    [MainFilterFieldNameEnum.CODES]: [],
                    [MainFilterFieldNameEnum.NAMES]: [],
                    [MainFilterFieldNameEnum.STREETS]: [],
                    [MainFilterFieldNameEnum.CLOSED]: [],
                    [MainFilterFieldNameEnum.ACTIVE]: [],
                },
                0,
                ''
            );

            compileMainFilterApi(defaultEmptyFilterState);
            return Promise.resolve();
        }

        if (formHasChanges) {
            setIsCompilingFilter(true);
            return compileMainFilterApi(selectedValuesIds)
                .then((compileFilterResult: MainFilterCompileResultApiType) => {
                    updateMainFilter(
                        {
                            [MainFilterFieldNameEnum.BRANDS]: brandsHook.value,
                            [MainFilterFieldNameEnum.GROUPS]: groupsHook.value,
                            [MainFilterFieldNameEnum.COUNTRIES]: countriesHook.value,
                            [MainFilterFieldNameEnum.REGIONS]: regionsHook.value,
                            [MainFilterFieldNameEnum.CITIES]: citiesHook.value,
                            [MainFilterFieldNameEnum.CODES]: codesHook.value,
                            [MainFilterFieldNameEnum.NAMES]: namesHook.value,
                            [MainFilterFieldNameEnum.STREETS]: streetsHook.value,
                            [MainFilterFieldNameEnum.CLOSED]: closedHook.value,
                            [MainFilterFieldNameEnum.ACTIVE]: activeHook.value,
                        },
                        filteredCompaniesCount,
                        compileFilterResult.key
                    );
                })
                .finally(() => setIsCompilingFilter(false))
                .catch((error: Error) => {
                    throw error;
                });
        }

        return Promise.resolve();
    }, [
        brandsHook.value,
        citiesHook.value,
        codesHook.value,
        countriesHook.value,
        filteredCompaniesCount,
        formHasChanges,
        groupsHook.value,
        isFormEmpty,
        namesHook.value,
        regionsHook.value,
        closedHook.value,
        activeHook.value,
        selectedValuesIds,
        streetsHook.value,
        updateMainFilter,
    ]);

    const onReset = useCallback(() => {
        Object.values(MainFilterFieldNameEnum).forEach((fieldName: MainFilterFieldNameEnum) => {
            filterFields[fieldName].setValue(mainFilterState[fieldName]);
        });
        setFilteredCompaniesCount(filteredCount);
    }, [filterFields, filteredCount, mainFilterState]);

    const isAppliedFilterEmpty = !mainFilterKey;

    return {
        loadFieldSuggests,
        isInProgress: isFetchingFilteredCount || isCompilingFilter,
        clear: onClear,
        filteredCount: filteredCompaniesCount,
        activeFilterCompaniesCount: filteredCount,
        isAppliedFilterEmpty,
        applyFilter,
        isFilterEmpty,
        reset: onReset,
        fields: filterFields,
        setFieldValue,
        setFilteredCount: setFilteredCompaniesCount,
    };
}

export function useInitialFilter(): {
    initialFilterKey: string;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
} {
    const {queries} = useUrl<Record<MainFilterQueryKeyNameEnum, string>>();

    const initialFilter: string = queries[MainFilterQueryKeyNameEnum.filterId] || '';
    const [initialFilterKey, setInitialFilterKey] = useState<string>(initialFilter);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {data} = useQuery(['initial-main-filter-key'], () => getLastFilterKey(), {
        onSuccess: (filterData) => {
            if (!isBoolean(filterData?.currentFilter.isCompanyActive)) {
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        if (isBoolean(data?.currentFilter.isCompanyActive) && !initialFilter) {
            compileMainFilterApi({
                [MainFilterSelectedValuesIdsKeyEnum.BRANDS]: [],
                [MainFilterSelectedValuesIdsKeyEnum.CITIES]: [],
                [MainFilterSelectedValuesIdsKeyEnum.STREETS]: [],
                [MainFilterSelectedValuesIdsKeyEnum.CODES]: [],
                [MainFilterSelectedValuesIdsKeyEnum.NAMES]: [],
                [MainFilterSelectedValuesIdsKeyEnum.COUNTRIES]: [],
                [MainFilterSelectedValuesIdsKeyEnum.REGIONS]: [],
                [MainFilterSelectedValuesIdsKeyEnum.GROUPS]: [],
                [MainFilterSelectedValuesIdsAdditionalKeyEnum.ACTIVE]: data?.currentFilter.isCompanyActive,
            })
                .then((result) => {
                    setInitialFilterKey(result.key);
                })
                .catch(() => console.log('initial state is not found'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.currentFilter.isCompanyActive]);

    return {initialFilterKey, isLoading, setIsLoading};
}
