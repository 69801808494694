import {Button, Space} from 'antd';
import {PublicationContext} from 'centrifuge';
import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router';

import {appRoute} from '../../../../../app-route';
import {useCentrifugeSubscription} from '../../../../../provider/centrifuge/centrifuge-hook';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {useUser} from '../../../../../provider/user/user-hook';
import {useMarkAsOpenReport} from '../../../../../service/company/company-reports';
import {getCentrifugeReportMessagesName} from '../companies-report-helper';
import {CompaniesReportCentrifugeActionDataType, CompaniesReportCentrifugeStatusEnum} from '../companies-report-type';
import {CompaniesReportDownloadButton} from '../download-button/download-button';

import * as styles from './companies-report-notification.scss';

export function CentrifugeCompaniesReportNotifications(): null {
    const {mutate: markAsOpened} = useMarkAsOpenReport();
    const {user} = useUser();
    const {getSubscription} = useCentrifugeSubscription();
    const {pathname} = useLocation();
    const {snackbar, destroy} = useSnackbar();

    const reportDate = Date.now();

    function handleClick(id: string) {
        markAsOpened(id);
        destroy();
    }

    const onPublication = useCallback(
        (payload: CompaniesReportCentrifugeActionDataType) => {
            function handleSnackbar() {
                return snackbar.success({
                    duration: null,
                    className: styles.CompaniesReportNotification_snackbar,
                    message: <Locale stringKey="MY_COMPANIES__REPORT__SNACKBAR__DONE__MESSAGE" />,
                    description: (
                        <Space
                            align="end"
                            className={styles.CompaniesReportNotification_description}
                            direction="vertical"
                        >
                            <Locale stringKey="MY_COMPANIES__REPORT__SNACKBAR__DONE__DESCRIPTION" />
                            <Space>
                                <CompaniesReportDownloadButton
                                    createdAt={reportDate}
                                    onClick={() => handleClick(payload.report_uuid)}
                                    size="small"
                                    uuid={payload.report_uuid}
                                />
                                <a href={payload.link} rel="noreferrer" target="_blank">
                                    <Button
                                        onClick={() => handleClick(payload.report_uuid)}
                                        size="small"
                                        type="primary"
                                    >
                                        <Locale stringKey="MY_COMPANIES__REPORT__SNACKBAR__DONE__DESCRIPTION__BUTTON" />
                                    </Button>
                                </a>
                            </Space>
                        </Space>
                    ),
                });
            }

            if (payload.status === CompaniesReportCentrifugeStatusEnum.Done) {
                const reportBlock = document.querySelector('#companiesReport');

                if (reportBlock) {
                    const reportBlockCoordinate = reportBlock.getBoundingClientRect();

                    const blockTop = reportBlockCoordinate.top;

                    if (blockTop < 0) {
                        handleSnackbar();
                    }
                } else if (pathname !== appRoute.myCompanies.path) {
                    handleSnackbar();
                }
            }

            if (payload.status === CompaniesReportCentrifugeStatusEnum.Failed) {
                snackbar.error({
                    description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                    message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                });
            }
        },
        [markAsOpened, snackbar, destroy, pathname]
    );

    useEffect(() => {
        function onActionReceived({data}: PublicationContext) {
            onPublication(data);
        }

        const subscription = getSubscription(getCentrifugeReportMessagesName(user));

        subscription?.on('publication', onActionReceived);

        return () => {
            subscription?.removeListener('publication', onActionReceived);
        };
    }, [getSubscription, onPublication, user, pathname]);

    return null;
}
