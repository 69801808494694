import {
    faBuilding,
    faCheckCircle,
    faEye,
    faMagnifyingGlass,
    faSearchLocation,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {Dispatch, SetStateAction} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../component/page-card-divider/main-page-card-divider';
import {PageHeaderTitle} from '../../../../component/page-header-title/page-header-title';
import {Text} from '../../../../component/text/text';
import {UsetifulNameProductEnum} from '../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useFakesStatuses} from '../../../../service/fakes/fakes-statuses';
import {classNames} from '../../../../util/css';
import {FakesNextSearch} from '../common/next-search/fakes-next-search';

import {StatisticCard} from './statistic-card/statistic-card';
import * as styles from './fakes-empty-page.scss';

type PropsType = {
    setShowPreviousResults: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function FakesEmptyPage(props: PropsType): JSX.Element {
    const {setShowPreviousResults} = props;

    const {isInitialLoading, data} = useFakesStatuses();
    const {getLocalizedString} = useLocale();

    return (
        <Page mainClassName={styles.FakesEmptyPage}>
            <Meta title={getLocalizedString('FAKES__RESULTS__TITLE')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.duplicatesAndFakes.path,
                        titleLangKey: 'FAKES__TITLE',
                    },
                ]}
            />

            <PageHeader className={styles.FakesEmptyPage_pageHeader}>
                <PageHeaderTitle productName={UsetifulNameProductEnum.DUBS_AND_FAKES} title="FAKES__RESULTS__TITLE" />

                <NavigationLink to={appRoute.fakesSettings.path}>
                    <Button>
                        <Locale stringKey="FAKES__SETTINGS__TITLE" />
                    </Button>
                </NavigationLink>
            </PageHeader>

            <MainPageCardDivider />

            <div className={styles.FakesEmptyPage_content}>
                {isInitialLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <FontAwesomeIcon
                            className={classNames(styles.FakesEmptyPage_checkIcon, {
                                [styles.FakesEmptyPage_checkIcon__green]: !data?.isSearching,
                            })}
                            icon={data?.isSearching ? faSearchLocation : faCheckCircle}
                            key="faThinCopy"
                        />

                        {data?.isSearching ? (
                            <Text block bolder large>
                                <Locale stringKey="FAKES__EMPTY__IS_SEARCHING__HEADER" />
                                ...
                            </Text>
                        ) : (
                            <Text block bolder large stringKey="FAKES__EMPTY__HEADER" />
                        )}
                        {data && !data.isSearching && (
                            <div className={styles.FakesEmptyPage_statistic}>
                                {data.totalSearchCount && data.totalSearchCount > 0 ? (
                                    <StatisticCard
                                        icon={faMagnifyingGlass}
                                        text="FAKES__RESULTS__HEADER__TOTAL_SEARCHES"
                                        value={data.totalSearchCount}
                                    />
                                ) : null}
                                {data.filtered && data.filtered > 0 ? (
                                    <StatisticCard
                                        icon={faBuilding}
                                        text="FAKES__RESULTS__HEADER__FILTERED_COMPANIES"
                                        value={data.filtered}
                                    />
                                ) : null}
                            </div>
                        )}
                        <Text
                            block
                            className={styles.FakesEmptyPage_description}
                            lighter
                            stringKey={
                                data?.isSearching
                                    ? 'FAKES__EMPTY__IS_SEARCHING__DESCRIPTION'
                                    : 'FAKES__EMPTY__DESCRIPTION'
                            }
                        />
                        {data && (!data.isSearching || data.hasSearchData) && (
                            <footer>
                                {!data.isSearching && (
                                    <FakesNextSearch
                                        className={classNames({
                                            [styles.FakesEmptyPage_nextSearch]: data.hasSearchData,
                                        })}
                                        langKey="FAKES__EMPTY__NEXT_SEARCH"
                                    />
                                )}

                                {(data.hasSearchData || data.processed > 0) && (
                                    <Button
                                        icon={
                                            <FontAwesomeIcon
                                                className={styles.FakesEmptyPage_eyeIcon}
                                                icon={faEye}
                                                key="faThinCopy"
                                            />
                                        }
                                        onClick={() => setShowPreviousResults(true)}
                                        size="small"
                                    >
                                        <Text stringKey="FAKES__EMPTY__BUTTON__LAST_RESULTS" />
                                    </Button>
                                )}
                            </footer>
                        )}
                    </>
                )}
            </div>
        </Page>
    );
}
