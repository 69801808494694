import {TablePaginationConfig} from 'antd';
import {useCallback, useMemo, useState} from 'react';

import {UseRefreshApiHookType} from '../service/api-hook/api-hook-type';

import {getRandomString} from './string';

export function useRefreshId(): UseRefreshApiHookType {
    const [refreshId, setRefreshId] = useState<string>(getRandomString);

    const refresh = useCallback(() => setRefreshId(getRandomString()), [setRefreshId]);

    return useMemo(() => ({refreshId, refresh}), [refreshId, refresh]);
}

export const defaultPaginationState: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
};
