import {faStar, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';

import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/locale';
import {classNames} from '../../../../../../../util/css';
import {ProvidersIdsEnum} from '../../../../../../../util/type';

import * as styles from './phones-form-item.scss';

type PropsType = {
    catalogId?: number;
    index: number;
    onRemove: () => void;
};

export function PhonesFormItemButtons(props: PropsType): JSX.Element {
    const {catalogId, index, onRemove} = props;

    return (
        <div className={classNames(styles.company_phone__info, index ? styles.company_phone__info_small : '')}>
            {index > 0 && (
                <Button onClick={() => onRemove()} size="small" type="text">
                    <FontAwesomeIcon className={styles.company_phone__icon} icon={faTrash} />
                </Button>
            )}

            {index === 0 && (
                <>
                    <Button className={styles.company_phone__star_button} size="small" type="text">
                        <FontAwesomeIcon className={styles.company_phone__icon} icon={faStar} />
                    </Button>

                    <AdditionalInfo
                        className={styles.company_phone__additional_info}
                        rightNode={
                            <Locale stringKey="COMPANY_FORM__CONTACT_INFO__MAIN_PHONE__ADDITIONAL_INFO_HEADER" />
                        }
                        title={<Locale stringKey="COMPANY_FORM__CONTACT_INFO__MAIN_PHONE__ADDITIONAL_INFO_HEADER" />}
                    >
                        <Locale
                            stringKey={
                                catalogId === ProvidersIdsEnum.yandex
                                    ? 'COMPANY_FORM__CONTACT_INFO__PHONE__ADDITIONAL_INFO__YANDEX'
                                    : 'COMPANY_FORM__CONTACT_INFO__PHONE__ADDITIONAL_INFO'
                            }
                        />
                    </AdditionalInfo>
                </>
            )}
        </div>
    );
}
