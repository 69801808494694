import {faEye} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal, Table} from 'antd';
import {Reference} from 'rc-table/lib/interface';
import {LegacyRef, useCallback, useEffect, useRef, useState} from 'react';

import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useYandexStorySelectedCompanies} from '../../../../service/yandex-stories/yandex-stories-hook';
import {YandexStorySelectedCompanyType} from '../../../../service/yandex-stories/yandex-stories-type';
import {Text} from '../../../text/text';

import {yandexStorySelectedCompaniesTableColumns} from './yandex-story-selected-companies-helper';
import * as styles from './yandex-story-selected-companies.scss';

type PropsType = {
    storyId: number;
    companiesCount: number;
};

export function YandexStorySelectedCompanies(props: PropsType): JSX.Element {
    const {storyId, companiesCount} = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const {data, isLoading, hasNextPage, fetchNextPage} = useYandexStorySelectedCompanies({
        storyId,
        enabled: isModalOpen,
    });

    const renderData = data?.pages.flatMap((element) => element.results.map((element_) => element_)) || [];

    const {getLocalizedString} = useLocale();

    const containerRef: LegacyRef<ProxyHandler<HTMLDivElement> & Reference> | null = useRef(null);

    function handleModalClose() {
        setIsModalOpen(false);
    }

    const isNeedLoadNextPageRef = useRef<boolean>(false);

    const onContainerScroll = useCallback(
        async (event: Event) => {
            if (event.target instanceof HTMLElement) {
                const {scrollHeight, clientHeight, scrollTop} = event.target;
                const maxScroll = scrollHeight - clientHeight;
                const currentScroll = scrollTop;

                if (!isNeedLoadNextPageRef.current) {
                    isNeedLoadNextPageRef.current = maxScroll - currentScroll <= 50;

                    if (isNeedLoadNextPageRef.current && hasNextPage) {
                        await fetchNextPage();
                        isNeedLoadNextPageRef.current = false;
                    }
                }
            }
        },
        [isLoading]
    );

    useEffect(() => {
        const tableBody = containerRef?.current?.nativeElement.querySelector('.ant-table-body');

        tableBody?.addEventListener('scroll', onContainerScroll);

        return () => {
            tableBody?.removeEventListener('scroll', onContainerScroll);
        };
    }, [onContainerScroll]);

    return (
        <>
            <Button
                className={styles.YandexStorySelectedCompanies_companiesButton}
                icon={<FontAwesomeIcon icon={faEye} />}
                onClick={() => setIsModalOpen(true)}
                type="text"
            >
                {companiesCount}
            </Button>
            <Modal
                centered
                closable={false}
                destroyOnClose
                footer={
                    <div className={styles.YandexStorySelectedCompanies_modalFooter}>
                        <Text block className={styles.YandexStorySelectedCompanies_companiesCount}>
                            <Locale
                                stringKey="COMPANY__MULTISELECT__SELECTED_COUNT"
                                valueMap={{
                                    count: companiesCount,
                                }}
                            />
                        </Text>
                        <Button onClick={handleModalClose} type="primary">
                            <Locale stringKey="POPUP__BUTTON__CLOSE" />
                        </Button>
                    </div>
                }
                onCancel={handleModalClose}
                open={isModalOpen}
                title={getLocalizedString('YANDEX_STORY__SELECTED_COMPANIES__TITLE')}
                width={862}
            >
                <Table<YandexStorySelectedCompanyType>
                    className={styles.YandexStorySelectedCompanies_scroll}
                    columns={yandexStorySelectedCompaniesTableColumns}
                    dataSource={renderData}
                    loading={isLoading}
                    pagination={false}
                    ref={containerRef}
                    scroll={{y: 500, x: true}}
                />
            </Modal>
        </>
    );
}
