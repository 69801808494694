import {Form, Input} from 'antd';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.legalName> & {
    className?: string;
};

export function LegalNameFormItem(props: PropsType): JSX.Element {
    const {value, onChange, className, errorMessage} = props;

    const {getLocalizedString} = useLocale();

    return (
        <Form.Item
            className={className}
            help={errorMessage}
            label={<Locale stringKey="GENERAL_INFO__LABEL__LEGAL_NAME" />}
            validateStatus={errorMessage ? 'error' : ''}
        >
            <Input
                onChange={(event) => onChange(event.target.value)}
                placeholder={getLocalizedString('GENERAL_INFO__PLACEHOLDER__LEGAL_NAME')}
                size="large"
                value={value}
            />
        </Form.Item>
    );
}
