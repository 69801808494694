import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useCatalogInfo} from '../../../../../../../../provider/catalogs/catalogs-hook';
import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {deleteAccount} from '../../../../../../../../service/source-settings/accounts/accounts-api';
import {SourcesAccountType} from '../../../../../../../../service/source-settings/accounts/accounts-type';
import {classNames} from '../../../../../../../../util/css';

import * as styles from './remove-account.scss';

type PropsType = {
    account: SourcesAccountType;
    onSuccess: () => void;
};

export function RemoveAccount(props: PropsType): JSX.Element {
    const {account, onSuccess} = props;

    const {modal} = useModal();
    const {snackbar} = useSnackbar();
    const {companyName} = useDomainConfig();

    const {getCatalogName} = useCatalogInfo();

    async function onRemoveSubmit() {
        try {
            await deleteAccount(account.id);
            snackbar.success({
                description: (
                    <Locale
                        stringKey="SOURCE_SETTINGS__ACCOUNTS__DELETE_SUCCESS_TEXT"
                        valueMap={{catalogName: getCatalogName(account.catalog.id)}}
                    />
                ),
                message: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__DELETE_SUCCESS_TITLE" />,
            });
            onSuccess();
            return true;
        } catch {
            snackbar.error({
                description: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__DELETE_FAIL_TEXT" />,
                message: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__DELETE_FAIL_TITLE" />,
            });
            return false;
        }
    }

    function onRemoveClick() {
        modal.confirm({
            title: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__DELETE_MODAL_TITLE" />,
            content: (
                <p className={styles.RemoveAccount_content}>
                    <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__DELETE_MODAL_TEXT" valueMap={{companyName}} />
                </p>
            ),
            icon: (
                <FontAwesomeIcon
                    className={classNames('anticon', styles.RemoveAccount_icon)}
                    icon={faTriangleExclamation}
                />
            ),
            maskClosable: true,
            okText: <Locale stringKey="BUTTON__DELETE" />,
            okType: 'danger',
            okButtonProps: {type: 'primary'},
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            onOk: onRemoveSubmit,
        });
    }

    return (
        <button className={styles.RemoveAccount_button} onClick={onRemoveClick} type="button">
            <FontAwesomeIcon icon={faTrash} />
        </button>
    );
}
