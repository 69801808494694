import {DatePicker} from 'antd';
import dayjs from 'dayjs';
import {forwardRef} from 'react';

import {OnlinePresenceSynchronizationType} from '../../../../service/online-presence/online-presence-type';
import {CardsOccupancy} from '../synchronization-stats/cards-occupancy';
import {DataCompleteness} from '../synchronization-stats/data-completeness';
import {SynchronizedNavigators} from '../synchronization-stats/synchronized-navigators';

import * as styles from './online-presence-pdf-content.scss';

type PropsType = {
    result: OnlinePresenceSynchronizationType;
    syncDate: Date;
};

export const OnlinePresenceSynchronizationPdfContent = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const {result, syncDate} = props;

    return (
        <div className={styles.PdfContainer} ref={ref}>
            <DatePicker
                disabledDate={(pickerDate) => {
                    return dayjs().isSameOrBefore(pickerDate);
                }}
                format="DD.MM.YYYY"
                value={dayjs(syncDate)}
            />
            <CardsOccupancy
                averageSynchronization={result.syncStatus.averageSynchronization}
                data={result.syncStatus.catalogs}
            />
            <DataCompleteness data={result.dataCompleteness} />
            <SynchronizedNavigators data={result.syncStatus.navigators} />
        </div>
    );
});

OnlinePresenceSynchronizationPdfContent.displayName = 'OnlinePresenceSynchronizationPdfContent';
