import {formatAddress} from '../../../../../../../../service/address/address-helper';
import {AddressType} from '../../../../../../../../service/address/address-type';

import * as styles from './company-cell.scss';

type PropsType = {
    name: string;
    address: Pick<AddressType, 'country' | 'region' | 'city' | 'street' | 'housenumber'>;
};

export function CompanyCell(props: PropsType): JSX.Element {
    const {name, address} = props;

    return (
        <div className={styles.company_links_table__company_cell}>
            <p className={styles.company_links_table__company_cell__name}>{name}</p>
            <address className={styles.company_links_table__company_cell__address}>{formatAddress(address)}</address>
        </div>
    );
}
