import {ReactNode} from 'react';

import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {BulkEditFieldNameEnum} from '../../../../../../service/company/company-type';
import {BaseCompanyFormItemPropsType} from '../company-form-items-type';

import {getSendingCompanyNames} from './company-names-form-item-helper';
import {InputTextList} from './input-text-list/input-text-list';
import {TextType} from './input-text-list/input-text-type';
import * as styles from './company-names-form-item.scss';

type PropsType = BaseCompanyFormItemPropsType<BulkEditFieldNameEnum.names, ReactNode> & {
    inputContainerClassName?: string;
    namesForPush: Array<TextType>;
};

export function CompanyNamesFormItem(props: PropsType): JSX.Element {
    const {inputContainerClassName, value, namesForPush, onChange, errorMessage} = props;

    const documentationLinks = useDocumentationLinks();
    const {getLocalizedString} = useLocale();

    const sendingCompanyNames = getSendingCompanyNames(getLocalizedString, namesForPush);

    return (
        <div className={styles.container}>
            <InputTextList
                className={inputContainerClassName}
                errorMessage={errorMessage}
                label={<Locale stringKey="GENERAL_INFO__LABEL__COMPANY_NAME" />}
                placeholder={getLocalizedString('GENERAL_INFO__PLACEHOLDER__DISPLAYED_COMPANY_NAME')}
                setTextList={onChange}
                textList={value}
            />

            <InputTextList
                className={inputContainerClassName}
                disabled
                label={
                    <AdditionalInfo leftNode={<Locale stringKey="GENERAL_INFO__LABEL__SENDING_COMPANY_NAME" />}>
                        <p className={styles.sending_name__hint_text}>
                            <Locale stringKey="GENERAL_INFO__HINT__SENDING_COMPANY_NAME" />
                        </p>
                        {documentationLinks.company.sentName ? (
                            <p className={styles.sending_name__hint_text}>
                                <a href={documentationLinks.company.sentName} rel="noreferrer" target="_blank">
                                    <Locale stringKey="GENERAL_INFO__HINT__SENDING_COMPANY_NAME__DETAILS" />
                                </a>
                            </p>
                        ) : null}
                    </AdditionalInfo>
                }
                placeholder={getLocalizedString('GENERAL_INFO__PLACEHOLDER__SENDING_COMPANY_NAME')}
                setTextList={onChange}
                textList={sendingCompanyNames}
            />
        </div>
    );
}
