import {z as r} from 'zod';

import {companySelectorCompanySchema} from '../../../../../../../../service/feature-companies/feature-companies-api-type';

export const enum ManageAccountFormEnum {
    login = 'login',
    password = 'password',
    catalogId = 'catalogId',
    typeAccount = 'typeAccount',
    brandsId = 'brands',
    uuid = 'uuid',
}

export enum ManageTypeAccountEnum {
    brand = 'brand',
    company = 'company',
}

export type ManageAccountFormType = {
    [ManageAccountFormEnum.login]: string;
    [ManageAccountFormEnum.password]: string;
    [ManageAccountFormEnum.brandsId]: Array<number>;
    [ManageAccountFormEnum.uuid]: string;
    [ManageAccountFormEnum.catalogId]: number;
};

export type AccountSelectorCompanyType = r.infer<typeof companySelectorCompanySchema> & {
    account?: {
        catalog: string;
        login: string;
    };
    isInProgress: boolean;
};

export type AccountEditErrorPayloadType = {
    status: string;
};
