import {Spin} from 'antd';
import {SpinSize} from 'antd/lib/spin';

import {classNames} from '../../util/css';

import {defaultData, positionValueMap} from './spinner-const';
import {PositionType} from './spinner-type';
import * as styles from './spinner.scss';

const {size: defaultSize} = defaultData;

type PropsType = {
    size?: number | string; // default - 48px
    spinSize?: SpinSize;
    isShow?: boolean; // default - true
    position?: PositionType; // default - static
    wrapperColor?: string; // default - transparent
    wrapperPadding?: string | number; // default - 12px
    wrapperWidth?: string | number; // default - 100%
    wrapperHeight?: string | number; // default - 100%
    className?: string; // default = ''
};

export function Spinner(props: PropsType): JSX.Element | null {
    const {
        size: rawSize,
        spinSize,
        isShow,
        wrapperWidth,
        wrapperHeight,
        position: rawPosition,
        wrapperColor,
        wrapperPadding,
        className,
    } = props;

    if (isShow === false) {
        return null;
    }

    const position = rawPosition || positionValueMap.static;
    const size = rawSize || defaultSize;

    const spinnerWrapperStyle = {
        minHeight: size,
        minWidth: size,
        position,
        backgroundColor: wrapperColor,
        width: wrapperWidth,
        height: wrapperHeight,
        padding: wrapperPadding,
    };

    const wrapperClassName = classNames(styles.Spinner_wrapper, className, {
        [styles.Spinner_wrapper__static]: position === positionValueMap.static,
    });

    return (
        <div aria-busy className={wrapperClassName} role="progressbar" style={spinnerWrapperStyle}>
            <Spin size={spinSize} />
        </div>
    );
}
