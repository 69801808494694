import {faComment} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../app-route';
import {AutoReplies} from '../../../../page/main/auto-replies/auto-replies';
import {AutoRepliesActivation} from '../../../../page/main/auto-replies/auto-replies-activation';
import {AutoReplyCreate} from '../../../../page/main/auto-reply-create/auto-reply-create';
import {AutoReplyEdit} from '../../../../page/main/auto-reply-edit/auto-reply-edit';
import {ResponseTemplates} from '../../../../page/main/response-templates/response-templates';
import {ResponseTemplatesCreateTemplate} from '../../../../page/main/response-templates-create-template/response-templates-create-template';
import {ResponseTemplatesEditGroup} from '../../../../page/main/response-templates-edit-group/response-templates-edit-group';
import {ResponseTemplatesEditTemplate} from '../../../../page/main/response-templates-edit-template/response-templates-edit-template';
import {Reviews} from '../../../../page/main/reviews/reviews';
import {ReviewsAndAnswersStatistics} from '../../../../page/main/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {TagCreate} from '../../../../page/main/tags/tag-create/tag-create';
import {TagEdit} from '../../../../page/main/tags/tag-edit/tag-edit';
import {Tags} from '../../../../page/main/tags/tags';
import {TagsEdit} from '../../../../page/main/tags-edit/tags-edit';
import {useDomainConfig} from '../../../../provider/domain-config/domain-config-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {isEnabledForDomainAndDoubleGis} from '../../../navigation/navigation-helper';
import {NavigationItemConfigType} from '../routing-type';

export function useReviewsManagementNavigation(): Array<NavigationItemConfigType> {
    const {domainName} = useDomainConfig();
    const {licenses, availableFeatures} = useLicenses();

    return [
        {
            isAvailable: availableFeatures[FeaturesEnum.reviews],
            path: appRoute.reviewsManagement.path,
            icon: <FontAwesomeIcon icon={faComment} />,
            labelLangKey: 'CATEGORY_NAME__WORKING_WITH_REVIEWS',
            redirectPath: appRoute.reviewsManagementReviews.path,
            deniedPath: [appRoute.reviewsManagement.path],

            children: [
                {
                    path: appRoute.reviewsManagementReviews.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS',
                    component: Reviews,
                    exact: false,
                },
                {
                    path: appRoute.reviewsManagementResponseTemplates.path,
                    labelLangKey: 'CATEGORY_NAME__RESPONSE_TEMPLATES',
                    component: ResponseTemplates,
                    exact: true,
                },
                {
                    path: appRoute.reviewsManagementTags.path,
                    labelLangKey: 'CATEGORY_NAME__TAGS',
                    component: Tags,
                    exact: true,
                },
                // todo: только роуты
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementTagsEdit.path,
                    exact: true,
                    component: TagsEdit,
                },
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementTagCreate.path,
                    exact: true,
                    component: TagCreate,
                },
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementTagEdit.path,
                    exact: true,
                    component: TagEdit,
                },
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementResponseTemplatesCreateTemplate.path,
                    exact: true,
                    component: ResponseTemplatesCreateTemplate,
                },
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementResponseTemplatesEditGroup.path,
                    exact: true,
                    component: ResponseTemplatesEditGroup,
                },
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementResponseTemplatesEditTemplate.path,
                    exact: true,
                    component: ResponseTemplatesEditTemplate,
                },
                // todo: уточнить какую проверку использовать из меню (текущую) или из роутинга просто проверка домена и фичи?
                {
                    isAvailable: isEnabledForDomainAndDoubleGis({
                        isDomainFeatureEnabled: availableFeatures[FeaturesEnum.reviewSuggests],
                        isFeatureEnabled: Boolean(licenses[FeaturesEnum.reviewSuggests]?.isActive),
                        domainName,
                    }),
                    isActivated: licenses[FeaturesEnum.reviewSuggests]?.isActive,
                    path: appRoute.reviewsManagementAutoReplies.path,
                    labelLangKey: 'CATEGORY_NAME__AUTO_REPLIES',
                    // todo: может делать два поля component и activationComponent
                    // и в хуке проверять поле isActivated и в зависимости от этого возвращять нужный компонент
                    component: licenses[FeaturesEnum.reviewSuggests]?.isActive ? AutoReplies : AutoRepliesActivation,
                    exact: true,
                },
                // todo: уточнить проверку доступности, в роутнге ее не было, но есть для главной страницы AutoReplies выше
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementAutoReplyCreate.path,
                    exact: true,
                    component: AutoReplyCreate,
                },
                {
                    isOnlyRoute: true,
                    path: appRoute.reviewsManagementAutoReplyEdit.path,
                    exact: true,
                    component: AutoReplyEdit,
                },
                {
                    path: appRoute.reviewsManagementReviewsAndAnswersStatistics.path,
                    labelLangKey: 'CATEGORY_NAME__REVIEWS_AND_ANSWERS_STATISTICS__NAVIGATION',
                    component: ReviewsAndAnswersStatistics,
                    exact: true,
                    showNew: true,
                },
            ],
        },
    ];
}
