import {classNames} from '../../util/css';
import {Text} from '../text/text';

import * as styles from './statistics-parameter.scss';

export type StatisticsParameterValueRendererPropsType = {
    value: number;
    compareMode: boolean;
};

export function StatisticsParameterValueRenderer(props: StatisticsParameterValueRendererPropsType): JSX.Element {
    const {value, compareMode} = props;

    return (
        <Text
            block
            bolder
            className={classNames({
                [styles.StatisticsParameter_defaultValueRenderer__huge]: !compareMode,
            })}
        >
            {value}
        </Text>
    );
}
