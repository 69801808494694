import {LabeledValue} from 'antd/lib/select';
import {useEffect, useMemo, useState} from 'react';

import {SelectInfiniteScroll} from '../../../../../component/select-infinite-scroll/select-infinite-scroll';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {formatAddress} from '../../../../../service/address/address-helper';
import {
    ReviewCustomCompanyType,
    useReviewCustomCompanies,
} from '../../../../../service/reviews/reviews-custom-compamies';

import {CompanySelectActiveOption} from './company-select-active-option/company-select-active-option';
import {CompanySelectOption} from './company-select-option/company-select-option';

type PropsType = {
    initialCompany?: ReviewCustomCompanyType | null;
    value?: string;
    onChange?: (value: string) => void;
    brandId: number | null;
};

export function CompanySelect(props: PropsType): JSX.Element {
    const {onChange, brandId, initialCompany} = props;
    const {getLocalizedString} = useLocale();

    const [search, setSearch] = useState('');

    const {data, isLoading, fetchNextPage: loadMore} = useReviewCustomCompanies(search, brandId);

    // memo selected company is not a good idea. However, this is only way to make it work.
    // This should be got by company_id via api /v4/reviews/custom_reviews/companies/company_id
    // But currently this api doesn't work.
    // That refactoring will be in FDBK-3203, when backend issue FDBK-3203 unblock it
    const [memoSelectCompany, setMemoSelectCompany] = useState<ReviewCustomCompanyType>();

    useEffect(() => {
        if (initialCompany) {
            setMemoSelectCompany(initialCompany);
        }
    }, [initialCompany]);

    const selectedOption = memoSelectCompany && {
        value: memoSelectCompany.id,
        label: <CompanySelectActiveOption company={memoSelectCompany} />,
    };

    const companiesList = useMemo(() => {
        if (initialCompany) {
            return [
                initialCompany,
                ...(data?.pages.map((company) => company.companies).flatMap((array) => [...array]) || []),
            ];
        }

        return data?.pages.map((company) => company.companies).flatMap((array) => [...array]) || [];
    }, [data?.pages, initialCompany]);

    const options: Array<LabeledValue> = useMemo<Array<LabeledValue>>(
        (): Array<LabeledValue> =>
            companiesList.map((company) => ({
                value: company.id,
                // name is used for autocomplete by name and address
                name: company.name + formatAddress(company.address),
                label: <CompanySelectOption company={company} />,
            })),
        [companiesList]
    );

    function onChangeWrapper(labeledValue: LabeledValue): void {
        const company: ReviewCustomCompanyType | null =
            companiesList.find(({id}: ReviewCustomCompanyType): boolean => id === Number(labeledValue.value)) || null;

        if (company) {
            setMemoSelectCompany(company);
        }

        onChange?.(String(labeledValue.value));
    }

    return (
        <SelectInfiniteScroll<LabeledValue>
            filterOption={false}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            labelInValue
            loading={isLoading}
            onChange={onChangeWrapper}
            onLoadMore={loadMore}
            onSearch={setSearch}
            optionLabelProp="label" // https://github.com/ant-design/ant-design/issues/40205#issuecomment-1381279871
            options={options}
            placeholder={getLocalizedString(
                'REVIEWS_GENERATOR__CLIENTS__MANAGEMENT__FORM__INPUT__COMPANY__PLACEHOLDER'
            )}
            searchValue={search}
            showSearch
            value={selectedOption}
        />
    );
}
