import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {FakesStatisticsEnum} from '../../../../../service/fakes/fakes-statistics';

export const statisticsTabKey: Record<FakesStatisticsEnum, LangKeyType> = {
    [FakesStatisticsEnum.ClaimRejected]: 'FAKES__STATISTIC__TAB_NAMES__CLAIM_REJECTED',
    [FakesStatisticsEnum.DuplicatesClaimAccepted]: 'FAKES__STATISTIC__TAB_NAMES__DUPLICATES_CLAIM_ACCEPTED',
    [FakesStatisticsEnum.FakesClaimAccepted]: 'FAKES__STATISTIC__TAB_NAMES__FAKES_CLAIM_ACCEPTED',
    [FakesStatisticsEnum.Hidden]: 'FAKES__STATISTIC__TAB_NAMES__HIDDEN',
    [FakesStatisticsEnum.All]: 'FAKES__STATISTIC__TAB_NAMES__ALL',
    [FakesStatisticsEnum.FilteredCompanies]: 'FAKES__RESULTS__HEADER__ALL_FILTERED_COMPANIES',
    [FakesStatisticsEnum.TotalSearches]: 'FAKES__RESULTS__HEADER__ALL_TOTAL_SEARCHES',
};
