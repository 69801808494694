import {EditableInput} from '../../../../layout/editable-input/editable-input';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {defaultGroupData, TagGroupType} from '../../../../service/reviews/reviews-tags';

export function renderGroupName(
    tagData: TagGroupType,
    getLocalizedString: (langKey: LangKeyType) => string,
    onSave?: (text: string) => void,
    editableId?: number
): JSX.Element {
    const {title, id} = tagData;

    if (id !== defaultGroupData.id && id !== editableId) {
        return <span>{title}</span>;
    }

    return (
        <EditableInput
            hasButton={false}
            initialValue={title}
            isDefaultActive
            onSave={onSave}
            placeholder={getLocalizedString('TAGS__GROUP__ENTER_TAG_GROUP_NAME')}
        />
    );
}
