export function determineProvider(url: string): string {
    const patterns: {[key: string]: string} = {
        yandex: 'yandex',
        google: 'google',
    };

    const matchedSource = Object.entries(patterns).find(([, pattern]) => url.includes(pattern));

    return matchedSource ? matchedSource[0] : '';
}
