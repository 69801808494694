import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchAverageResponseTimeByCatalog} from './average-response-time-by-catalog-api';
import {AverageResponseTimeByCatalogType} from './average-response-time-by-catalog-type';

export function useAverageResponseTimeByCatalog(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<Array<AverageResponseTimeByCatalogType>> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['averageResponseTimeByCatalog', filter, mainFilterKey],
        queryFn: async () => {
            return fetchAverageResponseTimeByCatalog(filter, mainFilterKey);
        },
    });
}
