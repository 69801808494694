import {Button, Select} from 'antd';
import {useEffect, useState} from 'react';

import {Spinner} from '../../../../../layout/spinner/spinner';
import {useBrand} from '../../../../../provider/brands/brand-hook';
import {useDocumentationLinks} from '../../../../../provider/help-links/help-links-hook';
import {LocaleWithCatalogName} from '../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {createFacebookLocation} from '../../../../../service/matching/matching-api';
import {useFacebookBrandPage, useFacebookPages} from '../../../../../service/matching/matching-hook';
import {ProvidersIdsEnum} from '../../../../../util/type';

import {facebookValidationError} from './facebook-page-helper';
import * as styles from './facebook-page-matching.scss';

type PropsType = {
    accountOauthId: number;
    brandId: number;
    onSync: (brandPageId: string) => void;
};

export function FacebookPageMatching(props: PropsType): JSX.Element {
    const {brandId, accountOauthId, onSync} = props;

    const documentationLinks = useDocumentationLinks();

    const {getLocalizedString} = useLocale();
    const {modal} = useModal();

    const {pages: facebookPages, isInProgress: isFacebookPagesLoading} = useFacebookPages(accountOauthId);

    const {brand} = useBrand(brandId);

    const {brandPage, isInProgress: isBrandPagesIsInProgress} = useFacebookBrandPage(brandId);

    const [selectedBrandPageId, setSelectedBrandPageId] = useState<string | null>(null);

    const [isSyncInProgress, setIsSyncInProgress] = useState<boolean>(false);

    function updateSelectedPage(selectedPageId?: string) {
        if (!selectedPageId) {
            setSelectedBrandPageId(null);
            return;
        }

        const selectedPage = facebookPages.find((page) => page.id === selectedPageId);

        setIsSyncInProgress(true);
        createFacebookLocation({
            brandId,
            oauthAccountId: accountOauthId,
            locationId: selectedPageId,
            originId: selectedPage?.id || '',
            originUrl: selectedPage?.url || '',
        })
            .finally(() => {
                setIsSyncInProgress(false);
            })
            .then(() => {
                setSelectedBrandPageId(selectedPageId);
            })
            .catch((error: unknown) => {
                setSelectedBrandPageId(null);
                modal.error({
                    title: <Locale stringKey="COMPANY_MATCHING__MATCHING_PAGE_ERROR__TITLE" />,
                    content:
                        facebookValidationError(error) ??
                        (documentationLinks.facebook.locationCreationPermissions &&
                            documentationLinks.facebook.newVersionToCreateLocations && (
                                <LocaleWithCatalogName
                                    catalogId={ProvidersIdsEnum.facebook}
                                    namePlaceholder="FB"
                                    stringKey="COMPANY_MATCHING__MATCHING_PAGE_ERROR__MESSAGE"
                                    valueMap={{
                                        instructions: (
                                            <a
                                                href={documentationLinks.facebook?.locationCreationPermissions}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <Locale stringKey="COMPANY_MATCHING__MATCHING_PAGE_ERROR__INSTRUCTIONS" />
                                            </a>
                                        ),
                                        docFacebook: (
                                            <a
                                                href={documentationLinks.facebook?.newVersionToCreateLocations}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <Locale stringKey="COMPANY_MATCHING__MATCHING_PAGE_ERROR__DOC_FACEBOOK" />
                                            </a>
                                        ),
                                    }}
                                />
                            )),
                });
            });
    }

    useEffect(() => {
        setSelectedBrandPageId(brandPage?.originId || null);
    }, [brandPage]);

    useEffect(() => {
        if (!isFacebookPagesLoading && !facebookPages.some((page) => page.id === selectedBrandPageId)) {
            setSelectedBrandPageId(null);
        }
    }, [isFacebookPagesLoading, facebookPages, selectedBrandPageId]);

    return (
        <div className={styles.facebook_page_matching__wrapper}>
            {isBrandPagesIsInProgress && <Spinner />}

            {brand && facebookPages && (
                <div className={styles.facebook_page_matching__item}>
                    <p className={styles.facebook_page_matching__brand}>{brand.name}</p>

                    <Select<string | undefined>
                        className={styles.facebook_page_matching__page_selector}
                        disabled={isSyncInProgress}
                        loading={isSyncInProgress}
                        onSelect={updateSelectedPage}
                        placeholder={getLocalizedString('COMPANY__MULTISELECT__POPUP__ALL_BRANDS')}
                        /* eslint-disable-next-line no-undefined */
                        value={selectedBrandPageId || undefined}
                    >
                        {facebookPages.map((facebookPage) => {
                            return (
                                <Select.Option key={facebookPage.id} value={facebookPage.id}>
                                    {facebookPage.name}
                                    {facebookPage.website && (
                                        <>
                                            {' '}
                                            <a href={facebookPage.website} rel="noreferrer" target="_blank">
                                                {facebookPage.website}
                                            </a>
                                        </>
                                    )}
                                </Select.Option>
                            );
                        })}
                    </Select>

                    <Button
                        disabled={!selectedBrandPageId}
                        onClick={() => {
                            if (selectedBrandPageId) {
                                onSync(selectedBrandPageId);
                            }
                        }}
                        type="primary"
                    >
                        <Locale stringKey="COMPANY_MATCHING__SYNC" />
                    </Button>
                </div>
            )}
        </div>
    );
}
