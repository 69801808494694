import {GoogleConfirmationCompanyType} from '../../../../../../../../service/google/confirmation/google-confirmation-type';
import {CompanyListItem} from '../../../../common/company-list-item/company-list-item';

import {ConfirmationsListItemFooter} from './footer/confirmations-list-item-footer';
import * as styles from './confirmations-list-item.scss';

type PropsType = {
    confirmation: GoogleConfirmationCompanyType;
    onCodeRequest: (options: {repeat: boolean}) => void;
    onCodeEnter: () => void;
    onRightsRequest: () => void;
};

export function ConfirmationsListItem(props: PropsType): JSX.Element {
    const {confirmation, onCodeRequest, onCodeEnter, onRightsRequest} = props;

    const company = {
        ...confirmation.company,
        id: confirmation.company.pk,
        status: confirmation.status,
    };

    return (
        <CompanyListItem
            className={styles.ConfirmationsListItem}
            company={company}
            footer={
                <ConfirmationsListItemFooter
                    onCodeEnter={onCodeEnter}
                    onCodeRequest={onCodeRequest}
                    onRightsRequest={onRightsRequest}
                    status={confirmation.status}
                    text={confirmation.description}
                />
            }
            showCatalogLogo
            showContacts={false}
        />
    );
}
