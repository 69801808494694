import {faStar} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {LocalPackCatalogsEnum, LocalPackServicesEnum} from '../../../../../service/local-pack/local-pack-type';
import {LocalPackSearchPhrasesStatisticsItemType} from '../../../../../service/local-pack/search-phrase-analytics/local-pack-search-phrases-statistics-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';

import * as styles from './local-pack-top-five.scss';

type PropsType = {
    entry: LocalPackSearchPhrasesStatisticsItemType;
    service: LocalPackServicesEnum;
    provider: LocalPackCatalogsEnum;
};

export function LocalPackTopFive(props: PropsType): JSX.Element {
    const {entry, service, provider} = props;
    const {top5} = entry;

    const {getFormattedNumber} = useFormat();

    const item = top5.find((element) => {
        return element.provider === provider && element.service === service;
    });

    if (!item) {
        return <>-</>;
    }

    return (
        <div className={styles.LocalPackTopFive}>
            {item.results.length > 0
                ? item.results.map((element, index) => {
                      return (
                          <div className={styles.LocalPackTopFive_item} key={element.name}>
                              <span>{element.name}</span>
                              {index === 0 && element.rating && (
                                  <div className={styles.LocalPackTopFive_ratingBox}>
                                      {getFormattedNumber(element.rating, {
                                          maximumFractionDigits: 1,
                                      })}
                                      <FontAwesomeIcon className={styles.LocalPackTopFive_ratingIcon} icon={faStar} />
                                  </div>
                              )}
                          </div>
                      );
                  })
                : '-'}
        </div>
    );
}
