import {faEdit, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Popover} from 'antd';
import {generatePath} from 'react-router-dom';

import {appRoute} from '../../../../../../app-route';
import {NavigationLink} from '../../../../../../layout/navigation-link/navigation-link';
import {EmptyCell} from '../../../../../../layout/table/empty-cell/empty-cell';
import {Locale} from '../../../../../../provider/locale/locale';
import {useModal} from '../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../provider/snackbar/snackbar-hook';
import {deleteReviewUserTopic} from '../../../../../../service/reivew-analysis/review-user-topics/review-user-topics-api';

import {TopicActionsType} from './topic-actions-type';
import * as styles from './topic-actions.scss';

type PropsType = {
    topic: TopicActionsType;
    onDeleteSuccess: () => void;
};

export function TopicActions(props: PropsType): JSX.Element {
    const {topic, onDeleteSuccess} = props;

    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    const userTopicId: number | null = topic.userTopicId;

    if (!topic.userTopic || !userTopicId) {
        return (
            <Popover
                content={
                    <div className={styles.Topic_popoverContent}>
                        <Locale stringKey="REVIEW_ANALYSIS__POPOVER__TEXT__AUTO_TOPIC" />
                    </div>
                }
                placement="left"
                title={<Locale stringKey="REVIEW_ANALYSIS__POPOVER__TITLE__AUTO_TOPIC" />}
            >
                <EmptyCell />
            </Popover>
        );
    }

    return (
        <>
            <NavigationLink to={generatePath(appRoute.reviewsAnalysisTopicUpdate.path, {id: userTopicId})}>
                <Button className={styles.Topic_button} icon={<FontAwesomeIcon icon={faEdit} />} type="link" />
            </NavigationLink>

            <Button
                className={styles.Topic_button}
                icon={<FontAwesomeIcon icon={faTrashCan} />}
                onClick={async () => {
                    modal.confirm({
                        maskClosable: true,
                        title: <Locale stringKey="REVIEW_ANALYSIS__POPUP_HEADER__DELETE_TOPIC" />,
                        content: (
                            <Locale
                                stringKey="REVIEW_ANALYSIS__POPUP__TEXT__THE_TOPIC_WILL_BE_REMOVED_ARE_YOU_SURE"
                                valueMap={{topic: topic.topic}}
                            />
                        ),
                        okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
                        cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
                        cancelButtonProps: {type: 'primary'},
                        okButtonProps: {type: 'default', danger: true},
                        onOk: (): Promise<unknown> => {
                            return deleteReviewUserTopic(userTopicId)
                                .then(() => {
                                    snackbar.success(
                                        <Locale stringKey="REVIEW_ANALYSIS__SNACKBAR__TEXT__TOPIC_DELETED" />
                                    );

                                    onDeleteSuccess();
                                })
                                .catch(() => {
                                    snackbar.error(
                                        <Locale stringKey="REVIEW_ANALYSIS__SNACKBAR__TEXT__TOPIC_DELETE_ERROR" />
                                    );
                                    throw new Error('[ERROR] The topic wasn`t deleted');
                                });
                        },
                    });
                }}
                type="link"
            />
        </>
    );
}
