import {PageSubHeader} from '../../../layout/page-header/page-sub-header';
import {useDocumentationLinks} from '../../../provider/help-links/help-links-hook';
import {Locale} from '../../../provider/locale/locale';

import * as styles from './auto-reply-form-sub-header.scss';

type PropsType = {
    isEdit?: boolean;
};

export function AutoReplyFormSubHeader(props: PropsType): JSX.Element | null {
    const {isEdit} = props;

    const {autoReplies} = useDocumentationLinks();

    const articleLinkUrl = isEdit ? autoReplies.edit : autoReplies.create;

    return articleLinkUrl ? (
        <PageSubHeader className={styles.AutoReplyFormSubHeader}>
            <Locale
                stringKey="AUTO_REPLIES__HEADER__SUBHEADER__INFO"
                valueMap={{
                    articleLink: (
                        <a href={articleLinkUrl} key="articleLink" rel="noreferrer" target="_blank">
                            <Locale stringKey="AUTO_REPLIES__HEADER__SUBHEADER__INFO_SITE" />
                        </a>
                    ),
                }}
            />
        </PageSubHeader>
    ) : null;
}
