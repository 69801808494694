import {LatLngTuple} from 'leaflet';
import {useMemo} from 'react';

import {AlertFallback} from '../../../../../../component/alert-fallback/alert-fallback';
import {LeafletMap} from '../../../../../../component/map/leaflet-map';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {useCompaniesLocations} from '../../../../../../service/dashboard/dashboard-map/use-companies-locations';
import {CompanyLocationType} from '../../../../../../service/dashboard/dashboard-map/use-companies-locations-type';

import {DashboardCompaniesMarkers} from './dashboard-companies-markers';
import * as styles from './dashboard-companies-content.scss';

type PropsType = {
    setCompanyId: (id: number | null) => void;
    setShowPopup: (isShow: boolean) => void;
};

export function DashboardCompaniesContent(props: PropsType): JSX.Element | null {
    const {setShowPopup, setCompanyId} = props;

    const {data: companiesResult, isLoading, error} = useCompaniesLocations();

    const initialMapBounds = useMemo(() => {
        return (
            companiesResult?.flatMap((company: CompanyLocationType) => {
                if (!company.lat || !company.lon) {
                    return [];
                }

                const latLonTuple: LatLngTuple = [Number(company.lat), Number(company.lon)];

                return [latLonTuple];
            }) || null
        );
    }, [companiesResult]);

    if (error) {
        return <AlertFallback />;
    }

    if (isLoading || !companiesResult) {
        return <Spinner />;
    }

    if (!initialMapBounds) {
        return null;
    }

    return (
        <LeafletMap bounds={initialMapBounds} className={styles.DashboardCompaniesContent}>
            <DashboardCompaniesMarkers
                companiesResult={companiesResult}
                setCompanyId={setCompanyId}
                setShowPopup={setShowPopup}
            />
        </LeafletMap>
    );
}
