import {UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {UseHookType} from '../../service/api-hook/api-hook-type';
import {CatalogNameType} from '../../service/ccl-statistic/ccl-statistic-type';
import {ProvidersIdsEnum} from '../../util/type';

export const catalogConfigSchema = r.object({
    catalogId: r.string(),
    label: r.string(),
    logoUrl: r.string(),
    canRewriteLongDescription: r.boolean(),
    canRewriteShortDescription: r.boolean(),
    canRewriteSocialNetworks: r.boolean(),
    canRewriteWebsites: r.boolean(),
    canRewriteCategories: r.boolean(),
    canRewritePhones: r.boolean(),
    canRewriteWorkingHours: r.boolean(),
    canRewriteGallery: r.boolean(),
    canRewriteLogo: r.boolean(),
    canRewriteCover: r.boolean(),
    supportsPushedName: r.boolean(),
    supportsNameWithoutLanguage: r.boolean(),
    supportsAttributes: r.boolean(),
    countriesIds: r.array(r.number()),
    namesLanguages: r.array(r.string()),
    unsupportedRegionsIds: r.array(r.number()).optional(),
    synced: r.boolean().optional(),
});

enum CatalogNameEnum {
    map = 'map',
    navigator = 'navigator',
}

export const catalogSchema = r.object({
    content: r.string(),
    contentShort: r.string(),
    icon: r.string(),
    id: r.number(),
    logo: r.string(),
    name: r.string(),
    traffic: r.number(),
    type: r.nativeEnum(CatalogNameEnum),
    website: r.string(),
    weight: r.number(),
});

export type CatalogType = r.infer<typeof catalogSchema>;

const catalogRulesSchema = catalogSchema.pick({id: true, name: true, logo: true});

export type CatalogRulesType = r.infer<typeof catalogRulesSchema>;

export const shortCatalogSchema = r.object({
    id: r.nativeEnum(ProvidersIdsEnum),
    name: r.string(),
    logo: r.string(),
    website: r.optional(r.string()),
});

export type ShortCatalogType = r.infer<typeof shortCatalogSchema>;

export type FetchCatalogOptionType = {
    catalogType?: CatalogNameType;
    canCollectReviews?: boolean;
    isRelated?: boolean;
    abilityToReply?: boolean;
};

export type CatalogConfigApiType = r.infer<typeof catalogConfigSchema>;

export type CatalogConfigType = Omit<CatalogConfigApiType, 'catalogId'> & {
    catalogId: number;
    disabled?: boolean;
};

export type AvailableCatalogHookType = Omit<UseHookType<Array<ShortCatalogType>>, 'reset'> & {
    isCatalogAvailable: (catalogId: number) => boolean;
};

export type FetchCatalogRulesOptionType = {
    autoReplySupported?: boolean;
    gptSuggestSupported?: boolean;
};

export const paidCatalogsIdsSchema = r.array(r.number());

export type PaidCatalogIdsType = r.infer<typeof paidCatalogsIdsSchema>;

export type PaidCatalogsHookType = UseQueryResult<PaidCatalogIdsType> & {
    isCatalogPaid: (catalogId: number) => boolean;
};
