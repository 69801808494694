import {TableColumnsType} from 'antd';

import {DoughnutDataType} from '../../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {FormattedNumber} from '../../../../../../../provider/locale/formatted-number';
import {Locale} from '../../../../../../../provider/locale/locale';

import {DoughnutTableDataType} from './keywords-doughnut-chart-type';

export const topKeywordsTableColumns: TableColumnsType<DoughnutDataType | DoughnutTableDataType> = [
    {
        title: <Locale stringKey="TABLE__HEADER__TITLE" />,
        render: function renderTotal(
            _value: unknown,
            record: DoughnutDataType | DoughnutTableDataType
        ): string | JSX.Element {
            return record.label;
        },
    },
    {
        title: <Locale stringKey="TABLE__HEADER__COUNT" />,
        align: 'right',
        render: function renderTotal(_value: unknown, record: DoughnutDataType | DoughnutTableDataType): JSX.Element {
            return <FormattedNumber value={record.value} />;
        },
    },
];

export const KEYWORDS_DOUGHNUTCHART_COLORS = ['#4E79A7', '#59A14F', '#E15759', '#F1CE63', '#F0F0F0'];
