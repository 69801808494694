import {NotificationTabKeyEnum} from '../../../../page/main/notification/notification-type';
import {CurrentSiteResponseType, SiteOptionsType} from '../../../../service/site/site-type';
import {StoreLocatorLanguageEnum} from '../../../../service/store-locator-config/store-locator-config-short-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {ProvidersIdsEnum} from '../../../../util/type';
import {shortLocaleNameToLocaleNameMap} from '../../../locale/locale-context-const';
import {LocaleNameEnum} from '../../../locale/locale-context-type';
import {DomainConfigType, HeaderVisibilityOptionsEnum} from '../../domain-config-type';
import {emptyDomainConfigData} from '../domain-data-const';
import {ReactComponent as LogoBreadcrumbs} from '../two-gis/component/logo-bread-crumbs/image/logo-bread-crumbs.svg';
import {onEnter} from '../two-gis/script/on-enter';
import {onLoad} from '../two-gis/script/on-load';

import {getWlFullLogo} from './wl-logo-full/wl-logo-full';
import {getShortLogo} from './wl-logo-short/wl-logo-short';

export function domainConfig(currentSite: CurrentSiteResponseType): DomainConfigType {
    const isDoubleGisWl = currentSite.domain.includes('2gis.com');
    const onLoadFunction = isDoubleGisWl ? onLoad : async () => Promise.resolve();
    const siteOptions: SiteOptionsType = currentSite.options;
    const availableLocaleNames =
        currentSite.languages.length > 0
            ? currentSite.languages.map((shortLocaleName) => shortLocaleNameToLocaleNameMap[shortLocaleName])
            : Object.values(LocaleNameEnum);
    const defaultLocaleName = availableLocaleNames.includes(LocaleNameEnum.enUs)
        ? LocaleNameEnum.enUs
        : availableLocaleNames[0];

    const notificationTabs = isDoubleGisWl
        ? [NotificationTabKeyEnum.email, NotificationTabKeyEnum.browser, NotificationTabKeyEnum.telegram]
        : [NotificationTabKeyEnum.email];

    return {
        ...emptyDomainConfigData,
        basename: '/',
        companyName: currentSite.name,
        domainName: currentSite.domain,
        LogoFull: getWlFullLogo(currentSite.logo),
        logoFullSrc: currentSite.logo,
        LogoShort: getShortLogo(currentSite.icon),
        logoShortSrc: currentSite.icon,
        faviconSrc: currentSite.icon,
        LogoBreadcrumbs,
        onLoad: onLoadFunction,
        onEnter,
        isNotificationShow: siteOptions.is_notification_show,
        isFreshChatShow: false,
        isReportCompaniesShow: false,
        isLogoutShow: siteOptions.is_logout_show,
        isUserProfileShow: siteOptions.is_user_profile_show,
        headerVisibility: HeaderVisibilityOptionsEnum.VISIBLE,
        isFooterShow: siteOptions.is_footer_show,
        canCreateCompany: siteOptions.can_create_company,
        isDomainFeatureEnabled: {
            [FeaturesEnum.photoTool]: siteOptions.feature_phototool,
            [FeaturesEnum.reviewAnalyticsBase]: siteOptions.feature_review_analytics_base,
            [FeaturesEnum.reviewAnalyticsFull]: siteOptions.feature_review_analytics_full,
            [FeaturesEnum.reviewAnalyticsCompetitor]: siteOptions.feature_review_analytics_competitor,
            [FeaturesEnum.duplicatesAndFakes]: siteOptions.feature_duplicates_and_fakes,
            [FeaturesEnum.posts]: siteOptions.feature_posts,
            [FeaturesEnum.reviewsGenerator]: siteOptions.feature_reviews_generator,
            [FeaturesEnum.reviewSuggests]: siteOptions.feature_review_suggests,
            [FeaturesEnum.storeLocator]: siteOptions.feature_store_locator,
            [FeaturesEnum.onlinePresenceAnalytics]: siteOptions.feature_online_presence_analytics,
            [FeaturesEnum.dashboard]: siteOptions.feature_dashboard,
            [FeaturesEnum.localPack]: siteOptions.feature_local_pack,
            [FeaturesEnum.mlReplies]: siteOptions.feature_ml_reply,
            [FeaturesEnum.companyGroups]: siteOptions.feature_company_groups,
            [FeaturesEnum.usedeskIntegration]: siteOptions.feature_usedesk_integration,
            [FeaturesEnum.prices]: siteOptions.feature_prices,
            [FeaturesEnum.analytics]: siteOptions.feature_analytics,
            [FeaturesEnum.userManagement]: true,
            [FeaturesEnum.tariffs]: false,
            integrations: siteOptions.feature_integrations,
            [FeaturesEnum.omnichannel]: Boolean(siteOptions.feature_omnichannel),
            [FeaturesEnum.publicApi]: Boolean(siteOptions.feature_public_api),
            [FeaturesEnum.reviewGptReplySuggestions]: Boolean(siteOptions.feature_review_gpt_reply_suggestions),
            [FeaturesEnum.mailingListLicence]: Boolean(siteOptions.feature_mailing_list_licence),
            [FeaturesEnum.companiesExport]: false,
            [FeaturesEnum.knowledge]: false,
            [FeaturesEnum.neuralNetwork]: isDoubleGisWl,
            [FeaturesEnum.sources]: true,
            [FeaturesEnum.reviews]: true,
            [FeaturesEnum.settings]: true,
            [FeaturesEnum.questionsAndAnswers]: true,
            [FeaturesEnum.myCompanies]: true,
        },
        hiddenCompanyFields: {branchCode: true},
        canFoldDesktopMenu: siteOptions.can_fold_desktop_menu,
        isPageWrapperHasPaddings: siteOptions.is_page_wrapper_has_paddings,
        isLanguageSelectorVisible: siteOptions.is_language_selector_visible,
        isDynamicHelpLinksVisible: false,
        isSingleFeature: siteOptions.is_single_feature,
        isWhiteLabel: true,
        availableLocales: availableLocaleNames,
        defaultLocale: defaultLocaleName || LocaleNameEnum.enUs,
        isSourceSettingsShown: true,
        notificationTabs,
        feedTypeLabels: null,
        feedTemplateLinks: [],
        storeLocatorLanguages: Object.values(StoreLocatorLanguageEnum),
        mainCatalogs: isDoubleGisWl
            ? [ProvidersIdsEnum.google, ProvidersIdsEnum.yandex]
            : [ProvidersIdsEnum.google, ProvidersIdsEnum.yandex, ProvidersIdsEnum.doubleGis],
        isShowUsetifulButton: false,
        isShowYandexTransferAccountBanner: isDoubleGisWl,
        isShowTrialNeurlalinkInfo: !isDoubleGisWl,
    };
}
