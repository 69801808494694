import {Rule} from 'antd/lib/form';
import {inRange} from 'lodash';

import {LocaleContextType} from '../../../../../../../../provider/locale/locale-context-type';

export function codeFieldRule(getLocalizedString: LocaleContextType['getLocalizedString']): Rule {
    return {
        validateTrigger: 'onSubmit',
        validator: (_rule: unknown, value: Array<string>) =>
            value && !inRange(String(value).replace(/\s/g, '').length, 5, 7)
                ? Promise.reject(new Error(getLocalizedString('GOOGLE_SYNC__CODE_ENTER__CODE__LENGTH_ERROR')))
                : Promise.resolve(),
    };
}
