import {Alert, Button} from 'antd';
import {useCallback, useMemo} from 'react';

import {appRoute} from '../../../../../../../app-route';
import {useActionRequiresLicense} from '../../../../../../../provider/license/license-hook';
import {LocaleWithCatalogName} from '../../../../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useModal} from '../../../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../../../provider/snackbar/snackbar-hook';
import {useRemovePostMutation, useUpdatePostMutation} from '../../../../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormType, PostSourceEnum} from '../../../../../../../service/posts/posts-types';
import {FeaturesEnum} from '../../../../../../../service/user/user-type';
import {FormInstance} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {useUrl} from '../../../../../../../util/url-hook/url-hook';

import * as styles from './post-submit-actions.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    handleSubmitError: (error: unknown) => void;
    isDisabled: boolean;
};

export function EditExistedPostActions(props: PropsType): JSX.Element {
    const {formInstance, handleSubmitError, isDisabled} = props;

    const postStatus = formInstance.getFieldValue(PostFormFieldsEnum.Status);

    const {mutateAsync: updatePost, isLoading: isSending} = useUpdatePostMutation(postStatus);
    const {mutateAsync: removePost, isLoading: isRemoving} = useRemovePostMutation();

    const {pushUrl} = useUrl();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    const handleUpdatePost = useActionRequiresLicense(
        useCallback(async () => {
            await formInstance.validateFields();

            try {
                const formValue = formInstance.getFieldValue([]) as PostFormType;

                await updatePost(formValue);
                snackbar.success(<Locale stringKey="POSTS_FORM__MESSAGE__UPDATE_SUCCESS" />);
                pushUrl(appRoute.postsManagement.path);
            } catch (error) {
                handleSubmitError(error);
                snackbar.error(<Locale stringKey="POSTS_FORM__ERROR__UPDATE_POST" />);
            }
        }, [formInstance, handleSubmitError, snackbar, pushUrl, updatePost]),
        FeaturesEnum.posts
    );

    const isRemoveButtonVisible = useMemo(() => {
        return formInstance
            .getFieldValue([PostFormFieldsEnum.Posts])
            .some(({catalog}) => catalog !== PostSourceEnum.instagram);
    }, [formInstance]);

    const hasInstagram = useMemo(() => {
        return formInstance
            .getFieldValue([PostFormFieldsEnum.Posts])
            .some(({catalog}) => catalog === PostSourceEnum.instagram);
    }, [formInstance]);

    const handleRemoveClick = useActionRequiresLicense(
        useCallback(async () => {
            modal.confirm({
                title: <Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__TITLE" />,
                content: (
                    <div className={styles.PostSubmitButtons_removeModalContent}>
                        {hasInstagram && (
                            <Alert
                                message={
                                    <LocaleWithCatalogName
                                        catalogId={ProvidersIdsEnum.instagram}
                                        stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__UNSUPPORTED_REMOVE_ALERT"
                                    />
                                }
                                type="warning"
                            />
                        )}
                        <Locale stringKey="POSTS_FORM__MESSAGE__REMOVE_POST_MODAL__CONTENT" />
                    </div>
                ),
                okText: <Locale stringKey="BUTTON__DELETE" />,
                cancelText: <Locale stringKey="BUTTON__CANCEL" />,
                onOk: async () => {
                    const postId = formInstance.getFieldValue([PostFormFieldsEnum.Id]);

                    if (!postId) {
                        return;
                    }

                    await removePost(postId);
                    pushUrl(appRoute.postsManagement.path);
                },
            });
        }, [formInstance, hasInstagram, modal, pushUrl, removePost]),
        FeaturesEnum.posts
    );

    const isInProgress = isSending || isRemoving;

    return (
        <div className={styles.PostSubmitButtons}>
            <Button
                className={styles.PostSubmitButtons_button}
                disabled={isInProgress || isDisabled}
                loading={isSending}
                onClick={handleUpdatePost}
                type="primary"
            >
                <Locale stringKey="BUTTON__UPDATE" />
            </Button>

            {isRemoveButtonVisible && (
                <Button
                    className={styles.PostSubmitButtons_button}
                    danger
                    disabled={isInProgress || isDisabled}
                    loading={isRemoving}
                    onClick={handleRemoveClick}
                >
                    <Locale stringKey="POSTS_FORM__BUTTON__REMOVE_POST" />
                </Button>
            )}
        </div>
    );
}
