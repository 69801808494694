import {Spinner} from '../../../../layout/spinner/spinner';
import {ReviewNoteType, useNotes} from '../../../../service/reviews/reviews-notes';
import {useReviewActivityHook} from '../review-activity-provider/review-activity-provider';

import {Note} from './note/note';
import {NoteForm} from './note-form/note-form';
import * as styles from './review-notes.scss';

type PropsType = {
    reviewId: number;
    onRefresh?: () => void;
};

export function ReviewNotes(props: PropsType): JSX.Element | null {
    const {isNotesVisible, onNotesActivityPress} = useReviewActivityHook();
    const {onRefresh, reviewId} = props;
    const {
        data: notesList,
        isLoading: isInProgress,
        refetch: refreshNotes,
    } = useNotes(isNotesVisible ? reviewId : null, true);

    if (!isNotesVisible) {
        return null;
    }

    async function refresh() {
        if (onRefresh) {
            await onRefresh();
        }

        await refreshNotes();
    }

    return (
        <div className={styles.ReviewNotes}>
            <NoteForm onCancel={onNotesActivityPress} onSave={refresh} reviewId={reviewId} />
            <Spinner isShow={isInProgress} position="absolute" />
            {notesList?.results.map((note: ReviewNoteType) => {
                return (
                    <Note
                        createdAt={note.createdAt}
                        id={note.id}
                        key={note.id}
                        onNoteEdit={refresh}
                        reviewId={reviewId}
                        text={note.text}
                        updatedAt={note.updatedAt}
                        user={note.user}
                    />
                );
            })}
        </div>
    );
}
