import {Tabs} from 'antd';
import {useState} from 'react';

import {appRoute} from '../../../../../app-route';
import {Meta} from '../../../../../component/meta/meta';
import {PageHeaderTitle} from '../../../../../component/page-header-title/page-header-title';
import {UsetifulNameProductEnum} from '../../../../../component/usetiful/usetiful-const';
import {BreadCrumbs} from '../../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../../layout/page/page';
import {MainPageContainer} from '../../../../../layout/page-card/main-page-container';
import {PageCard} from '../../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../../layout/page-header/page-header';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useUrl} from '../../../../../util/url-hook/url-hook';

import {KeywordsAndTopicsForm} from './keywords-and-topics-form/keywords-and-topics-form';
import {KeywordsAndTopicsPageProvider} from './keywords-and-topics-page-context';
import {KeywordsTab} from './keywords-tab/keywords-tab';
import {TopicsTab} from './topics-tab/topics-tab';
import * as styles from './keywords-and-topics-page.scss';

export function KeywordsAndTopicsPage(): JSX.Element {
    const {queries} = useUrl();

    const [activeTab, setActiveTab] = useState(queries.mode === 'topics' ? 'topics' : 'keywords');

    const {reviewsAnalysisDashboard: dashboardRoute, reviewsAnalysisKeywordsAndTopics: tagsRoute} = appRoute;

    const {getLocalizedString} = useLocale();

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS')} />

                <BreadCrumbs
                    list={[
                        {
                            path: dashboardRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS',
                        },
                        {
                            path: tagsRoute.path,
                            titleLangKey: 'CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS',
                        },
                    ]}
                />

                <PageHeader>
                    <PageHeaderTitle
                        productName={UsetifulNameProductEnum.REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS}
                        title="CATEGORY_NAME__REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS"
                    />
                </PageHeader>
            </PageCard>

            <KeywordsAndTopicsPageProvider>
                <>
                    <PageCard className={styles.FiltersPageCard}>
                        <Tabs activeKey={activeTab} onChange={(tabKey) => setActiveTab(tabKey)}>
                            <Tabs.TabPane
                                key="keywords"
                                tab={<Locale stringKey="REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS_TAB_KEYWORDS" />}
                            />

                            <Tabs.TabPane
                                key="topics"
                                tab={<Locale stringKey="REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS_TAB_TOPICS" />}
                            />
                        </Tabs>

                        <KeywordsAndTopicsForm activeTab={activeTab} />
                    </PageCard>

                    {activeTab === 'keywords' && <KeywordsTab />}
                    {activeTab === 'topics' && <TopicsTab />}
                </>
            </KeywordsAndTopicsPageProvider>
        </Page>
    );
}
