import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';
import {objectToUrlParameters} from '../../util/url';

import {companiesImportUrl} from './companies-import-const';

export const companiesImportResultCommonSchema = r.object({
    readyToImport: r.number(),
    success: r.number(),
    internalError: r.number(),
    validationError: r.number(),
    criticalError: r.number(),
    sameData: r.number(),
    foreignError: r.number(),
    ignore: r.number(),
    all: r.number(),
    processedCount: r.number(),
});

export const companiesImportResultSchema = companiesImportResultCommonSchema.extend({
    criticalError: r.number(),
});

export type CompaniesImportResultType = r.infer<typeof companiesImportResultSchema>;

const companiesImportValidationResultSchema = r.object({
    identifier: r.string(),
    validationResult: companiesImportResultSchema,
});

type CompaniesImportValidationResultType = r.infer<typeof companiesImportValidationResultSchema>;

export function getCompaniesImportValidationResultCommonUrl(id: string): string {
    return `${companiesImportUrl}/validate_result/${id}/`;
}

function getCompaniesImportValidationResultUrl(id: string): string {
    return `${getCompaniesImportValidationResultCommonUrl(id)}?${objectToUrlParameters({timestamp: Date.now()})}`;
}

export function fetchCompaniesImportValidationResult(id: string): Promise<CompaniesImportValidationResultType> {
    return fetchAndDeserialize(getCompaniesImportValidationResultUrl(id), companiesImportValidationResultSchema);
}
