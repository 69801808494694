import {PropsWithChildren} from 'react';

import {Meta} from '../../../component/meta/meta';
import {useLocale} from '../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../provider/locale/translation/type';
import {classNames} from '../../../util/css';

import * as styles from './page-wrapper.scss';

export type PageWrapperOptionalPropsType =
    | {
          titleLangKey: LangKeyType;
          title?: undefined;
      }
    | {
          title: string;
          titleLangKey?: undefined;
      };

type PropsType = PropsWithChildren<{
    className?: string;
    titleLangKey?: LangKeyType;
    title?: string;
}>;

export function PageWrapper(props: PropsType): JSX.Element {
    const {children, titleLangKey, title, className} = props;

    const {getLocalizedString} = useLocale();

    return (
        <>
            <Meta title={titleLangKey ? getLocalizedString(titleLangKey) : title} />

            <div className={classNames(styles.PageWrapper, className)}>{children}</div>
        </>
    );
}
