import {ReactElement, ReactNode} from 'react';
import {renderToString} from 'react-dom/server';

export const splitValueStringRegExp = /(?={\w+?})/g;

export const placeholderText = '      ';

export const defaultValues: Record<string, ReactNode> = {
    nbsp: <>&nbsp;</>,
    break: <br />,
};

export const defaultValuesToString: Record<keyof typeof defaultValues, string> = {
    nbsp: renderToString(defaultValues.nbsp as ReactElement),
    break: renderToString(defaultValues.br as ReactElement),
};
