import {faCaretDown, faCaretUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, List} from 'antd';
import {ReactNode, useState} from 'react';

import {Locale} from '../../../../../../provider/locale/localization';

import {MAX_NON_EXPANDABLE_LIST_LENGTH} from './fakes-expandable-list-const';
import * as styles from './fakes-expandable-list.scss';

type PropsType = {
    list: Array<string>;
    header: ReactNode;
};

export function FakesExpandableList(props: PropsType): JSX.Element {
    const {list, header} = props;
    const [showAll, setShowAll] = useState<boolean>(false);

    const splitList = list.slice(0, MAX_NON_EXPANDABLE_LIST_LENGTH);

    return (
        <>
            <List
                className={styles.CardWithItem_list}
                dataSource={showAll ? list : splitList}
                header={<List.Item className={styles.CardWithItem_header}>{header}</List.Item>}
                renderItem={(item) => <List.Item className={styles.CardWithItem_item}>{item}</List.Item>}
            />
            {MAX_NON_EXPANDABLE_LIST_LENGTH < list.length && (
                <Button
                    className={styles.CardWithItem_expandButton}
                    icon={<FontAwesomeIcon icon={showAll ? faCaretUp : faCaretDown} />}
                    onClick={() => setShowAll((previousShowAll) => !previousShowAll)}
                    size="small"
                    type="link"
                >
                    <span>
                        <Locale stringKey={showAll ? 'FAKES__SETTINGS__HIDE' : 'FAKES__SETTINGS__SHOW_ALL'} />
                    </span>
                </Button>
            )}
        </>
    );
}
