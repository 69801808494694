import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../../util/url';
import {PaginatedResponseType} from '../../api/api-type';

import {
    reviewAnalyticsEmployeesResponseSchema,
    ReviewAnalyticsEmployeeType,
    ReviewsAnalysisEmployeesQueryType,
} from './review-employees-type';

export async function fetchReviewEmployees(
    options: ReviewsAnalysisEmployeesQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<PaginatedResponseType<ReviewAnalyticsEmployeeType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewsAnalysisEmployeesQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/employees/?${searchParameters}`;

    return fetchAndDeserialize<PaginatedResponseType<ReviewAnalyticsEmployeeType>>(
        url,
        reviewAnalyticsEmployeesResponseSchema
    );
}
