import {z as r} from 'zod';

import {cursorPaginationResponseSchemaFactory} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';

export enum TimelineActionTypeEnum {
    CheckCCL = 'check_ccl',
    Push = 'push',
    CollectReviews = 'collect_reviews',
    NewPhotos = 'new_photos',
    PostPublished = 'post_published',
    ChangeCompany = 'change_company',
    ChangeCompanyFeed = 'change_company_feed',
    ProductsLoaded = 'products_loaded',
    ProductsDeleted = 'products_deleted',
    YandexGeovisor = 'yandex_geovisor',
}

export enum TimelineYandexEventTypeEnum {
    TextSearchOnMap = 'text_search_on_map',
    SuggestSearchOnMap = 'suggest_search_on_map',
    Poi = 'poi',
    ArbitrateGeoadvDirect = 'arbitrate_geoadv_direct',
    ArbitrateGeoadvYabs = 'arbitrate_geoadv_yabs',
    ArbitrateGeoadvZen = 'arbitrate_geoadv_zen',
    RouteAdvert = 'route_advert',
    ToponymAdvert = 'toponym_advert',
    WhatshereAdvert = 'whatshere_advert',
    InjectByRubricAtBottom = 'inject_by_rubric_at_bottom',
    Related = 'related',
    ExternalOpen = 'external_open',
    CompetitorOf1org = 'competitor_of_1org',
    Competitor = 'competitor',
    CompetitorOfChain = 'competitor_of_chain',
    CompetitorOfPoi = 'competitor_of_poi',
    RubricAlike = 'rubric_alike',
    ToponymAdvertFromSearch = 'toponym_advert_from_search',
    ToponymAdvertFromClick = 'toponym_advert_from_click',
    ToponymAdvertFromWhatsHere = 'toponym_advert_from_whatshere',
}

export enum TimelineYandexActionTypeEnum {
    Route = 'route',
    Call = 'call',
    Review = 'review',
    Photo = 'photo',
    Links = 'links',
    WorkingStatus = 'working-status',
    Entrances = 'entrances',
    Advert = 'advert',
    Cta = 'cta',
    Bookmark = 'bookmark',
    Branches = 'branches',
    Details = 'details',
    Sharing = 'sharing',
    BookingSection = 'booking-section',
    Menu = 'menu',
    Unknown = 'unknown',
}

const catalogInfoDataSchema = r.object({
    id: r.number(),
    name: r.string(),
    website: r.string(),
    logo: r.string(),
});

const timelineExtraDataSchema = r.object({
    type: r.nativeEnum(TimelineYandexEventTypeEnum).optional(),
    value: r.string().optional(),
});

const timelineSubActionsDataSchema = r.object({
    action: r.nativeEnum(TimelineYandexActionTypeEnum),
    description: r.string().optional(),
});

export type TimelineSubActionsDataType = r.infer<typeof timelineSubActionsDataSchema>;

const timelineDataApiSchema = r.object({
    id: r.string(),
    timestamp: r.number(),
    action: r.nativeEnum(TimelineActionTypeEnum),
    description: r.string().optional(),
    catalog: catalogInfoDataSchema,
    extra: timelineExtraDataSchema,
    subActions: r.array(timelineSubActionsDataSchema),
});

export type TimelineDataType = r.infer<typeof timelineDataApiSchema>;

export const timelineDataResponseSchema = cursorPaginationResponseSchemaFactory(timelineDataApiSchema);

export type TimelineResultResponseType = r.infer<typeof timelineDataResponseSchema>;

export type TimelineForOneCompanyHookPropsType = {
    companyId: number;
    perPage?: number;
};

export type TimelineForOneCompanyHookType = UseHookType<Array<TimelineDataType>> & {
    loadMore: () => void;
    canLoadMore: boolean;
    refresh: () => void;
};
