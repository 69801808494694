import {FunctionComponent, memo, PropsWithChildren} from 'react';

import {CentrifugeProvider} from '../../provider/centrifuge/centrifuge-context';
import {DomainConfigProvider} from '../../provider/domain-config/domain-config-context';
import {HelpLinksProvider} from '../../provider/help-links/help-links-context';
import {LicenseProvider} from '../../provider/license/license-provider';
import {SystemProvider} from '../../provider/system/system-context';
import {UiProvider} from '../../provider/ui/ui-context';
import {UserProvider} from '../../provider/user/user-context';

import {RoutingByDomain} from './routing/routing-by-domain';

type PropsType = {
    DomainProvider?: FunctionComponent<PropsWithChildren<Record<string, unknown>>>;
};

export const AppProviders = memo(function AppProvidersInner(props: PropsType): JSX.Element {
    const {DomainProvider = DomainConfigProvider} = props;

    return (
        <DomainProvider>
            <HelpLinksProvider>
                <SystemProvider>
                    <UserProvider>
                        <LicenseProvider>
                            <UiProvider>
                                <CentrifugeProvider>
                                    <RoutingByDomain />
                                </CentrifugeProvider>
                            </UiProvider>
                        </LicenseProvider>
                    </UserProvider>
                </SystemProvider>
            </HelpLinksProvider>
        </DomainProvider>
    );
});
