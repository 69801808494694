import {Button, Col, Row} from 'antd';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {MainPageCardDivider} from '../../../../component/page-card-divider/main-page-card-divider';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {MainPageContainer} from '../../../../layout/page-card/main-page-card';
import {PageCard} from '../../../../layout/page-card/page-card';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useSystem} from '../../../../provider/system/system-hook';
import {useLocalPackFilter} from '../../../../service/local-pack/local-pack-hook';
import {generatePathWithQueryParameters} from '../../../../util/url';
import {magicMobileMinWidth} from '../../photo-tool/pages/photo-tool-list/photo-tool-controls/photo-tool-controls-const';
import {LocalPackFilter} from '../local-pack-filter/local-pack-filter';

import {LocalPackLeaderboardTable} from './leader-board-table/local-pack-leaderboard-table';

export function LocalPackLeaderBoardPage(): JSX.Element {
    const {getLocalizedString} = useLocale();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {filter, setFilter, filterQueries} = useLocalPackFilter();
    const {width} = screen;

    const localPackMainPagePath = generatePathWithQueryParameters(appRoute.localPack.path, {
        parametersURL: filterQueries,
    });

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('LOCAL_PACK__LEADERBOARD__TITLE')} />
                <BreadCrumbs
                    list={[
                        {
                            path: localPackMainPagePath,
                            titleLangKey: 'LOCAL_PACK_PAGE__TITLE',
                        },
                        {
                            path: appRoute.localPackLeaderBoard.path,
                            titleLangKey: 'LOCAL_PACK__LEADERBOARD__TITLE',
                        },
                    ]}
                />
                <PageHeader>
                    <Locale stringKey="LOCAL_PACK__LEADERBOARD__TITLE" />
                    <NavigationLink to={localPackMainPagePath}>
                        <Button>
                            <Locale stringKey="BUTTON__BACK" />
                        </Button>
                    </NavigationLink>
                </PageHeader>
                <MainPageCardDivider />
                <Row align="middle" gutter={[16, 16]} justify="space-between" wrap={width <= magicMobileMinWidth}>
                    <Col flex="auto">
                        <LocalPackFilter filter={filter} setFilter={setFilter} />
                    </Col>
                </Row>
            </PageCard>

            <LocalPackLeaderboardTable filter={filter} />
        </Page>
    );
}
