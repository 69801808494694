import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

const reviewsScrapyCatalogsDataSchema = r.object({
    id: r.number(),
    website: r.string(),
    canAbuseReview: r.boolean(),
    canReviewReply: r.boolean(),
    groupId: r.number(),
});

const fetchReviewsScrapyCatalogsSchema = r.array(reviewsScrapyCatalogsDataSchema);

export type ReviewsScrapyCatalogType = r.infer<typeof reviewsScrapyCatalogsDataSchema>;
export type ReviewsScrapyCatalogsType = r.infer<typeof fetchReviewsScrapyCatalogsSchema>;

type FetchReviewsScrapyInfoDataType = r.infer<typeof fetchReviewsScrapyCatalogsSchema>;

const reviewScrapyCatalogsInfoUrl = '/v4/reviews/catalogs_manual_replies_abuse/';

async function fetchReviewsScrapyCatalogsInfo(): Promise<FetchReviewsScrapyInfoDataType> {
    return fetchAndDeserialize(reviewScrapyCatalogsInfoUrl, fetchReviewsScrapyCatalogsSchema);
}

export function useReviewsScrapyCatalogsInfo(): UseQueryResult<Array<ReviewsScrapyCatalogType>> {
    return useQuery([reviewScrapyCatalogsInfoUrl], fetchReviewsScrapyCatalogsInfo, {
        refetchOnWindowFocus: false,
    });
}
