export enum FakeClientStatusEnum {
    New = 'new',
    Moderation = 'moderation',
    ClaimSent = 'claim_sent',
    ActionRequired = 'action_required',
    ClaimApproved = 'claim_approved',
    ClaimRejected = 'claim_rejected',
    Hidden = 'hidden',
}

export enum FakeInternalStatusEnum {
    AwaitModeration = 'await_moderation',
    RedundantLocation = 'redundant_location',
    Checked = 'checked',
    ClientLocation = 'client_location',
    NotClientLocation = 'not_client_location',
    SendClaim = 'send_claim',

    // same in FakeClientStatusEnum
    ClaimSent = 'claim_sent',
    ActionRequired = 'action_required',
    ClaimApproved = 'claim_approved',
    ClaimRejected = 'claim_rejected',
}

export enum FakeRejectReasonEnum {
    ExistedBefore = 'existed_before',
    DifferentNames = 'different_names',
    DifferentAddresses = 'different_addresses',
    DifferentCategories = 'different_categories',
    DifferentNetworks = 'different_networks',
}

export enum FakeNeededActionEnum {
    FakeInFeed = 'fake_in_feed',
    FakeOnSite = 'fake_on_site',
}

export enum FakeTypeEnum {
    Fake = 'fake',
    Duplicate = 'duplicate',
}

export enum FakeSubscriptionPlanEnum {
    All = 'all',
    Fakes = 'fakes',
    Duplicates = 'duplicates',
}

export enum FakeNamesComparingModeEnum {
    Strict = 'strict',
    Phonetic = 'phonetic',
}

export enum FakeSearchInCitiesEnum {
    All = 'all',
    BigCities = 'big_cities',
    ClientCities = 'client_cities',
}
