import {Form} from 'antd';
import {ValidatorRule} from 'rc-field-form/lib/interface';
import {useCallback, useEffect, useState} from 'react';

import {Text} from '../../../../../../../../../component/text/text';
import {Locale} from '../../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../../provider/locale/locale-hook';
import {PostFormType, PostImageType} from '../../../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../../../post-form-type';
import {ImageContentValidationSettingsType} from '../../../content-step-type';

import {noImagesInProgressValidator} from './images-helper';
import {PostsImagesUploader} from './posts-images-uploader';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    settings: ImageContentValidationSettingsType;
    fieldName: Array<string | number>;
    hideLabel?: boolean;
    onUploadingStatusChanged?: (isUploading: boolean) => void;
    extraRules?: Array<ValidatorRule>;
} & PostFormItemPropsType;

export function ImagesItem(props: PropsType): JSX.Element {
    const {
        disabled,
        formInstance,
        settings,
        fieldName,
        onUploadingStatusChanged,
        hideLabel = false,
        extraRules = [],
    } = props;
    const {maxCount} = settings;

    const {getLocalizedString} = useLocale();

    const formImages = Form.useWatch(fieldName, formInstance) as Array<PostImageType> | null;

    useEffect(() => {
        if (formImages && formImages.length > 0 && !formImages.some((item) => item.isMain)) {
            formInstance.setFieldValue(
                fieldName,
                formImages.map((item, index) => {
                    return index === 0 ? {...item, isMain: true} : item;
                })
            );
        }
    }, [fieldName, formInstance, formImages]);

    const onSelectImageAsMain = useCallback(
        (newMainImageIndex: number) => {
            const postImages = formInstance.getFieldValue(fieldName) as Array<PostImageType>;

            formInstance.setFieldValue(
                fieldName,
                postImages.map((item, index) => {
                    return {
                        ...item,
                        isMain: newMainImageIndex === index,
                    };
                })
            );
        },
        [fieldName, formInstance]
    );

    const [hasImagesInProgress, setHasImagesInProgress] = useState<boolean>(false);

    useEffect(() => {
        formInstance.validateFields([fieldName]);
    }, [hasImagesInProgress]);

    const handleUploadStatusChange = useCallback(
        (hasItemsInProgress: boolean) => {
            setHasImagesInProgress((previousValue) => {
                if (previousValue) {
                    onUploadingStatusChanged?.(hasItemsInProgress);
                }

                return hasItemsInProgress;
            });
        },
        [onUploadingStatusChanged]
    );

    return (
        <Form.List
            initialValue={[]}
            name={fieldName}
            rules={
                !disabled
                    ? [
                          noImagesInProgressValidator(
                              hasImagesInProgress,
                              getLocalizedString('POSTS_FORM__MEDIA_IN_PROGRESS_ERROR')
                          ),
                          ...extraRules,
                      ]
                    : []
            }
        >
            {(images, {add, remove}, {errors}) => {
                return (
                    <div>
                        {!hideLabel && (
                            <Text block>
                                <Locale stringKey={maxCount === 1 ? 'POSTS_FORM__IMAGE' : 'POSTS_FORM__IMAGES'} />
                            </Text>
                        )}

                        <PostsImagesUploader
                            disabled={disabled}
                            onFileUploaded={add}
                            onRemove={remove}
                            onSelectImageAsMain={onSelectImageAsMain}
                            onUploadingStatusChanged={handleUploadStatusChange}
                            settings={settings}
                            uploadedImages={images.map(({name}) => {
                                const image = formInstance.getFieldValue([...fieldName, name]) as PostImageType;

                                return {
                                    ...image,
                                    name,
                                };
                            })}
                        />
                        <Form.ErrorList errors={errors} />
                    </div>
                );
            }}
        </Form.List>
    );
}
