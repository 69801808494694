import {Select, SelectProps} from 'antd';
import {SelectValue} from 'antd/lib/select';
import {ReactElement, UIEvent} from 'react';

import {Spinner} from '../../layout/spinner/spinner';
import {onScrollInfiniteScrollContainer} from '../../util/infinite-scroll';

import * as styles from './select-infinite-scroll.scss';

type PropsType<ValueType> = Omit<SelectProps<ValueType>, 'onPopupScroll' | 'dropdownRender'> & {
    onLoadMore: () => void;
};

export function SelectInfiniteScroll<ValueType extends SelectValue>(props: PropsType<ValueType>): JSX.Element {
    const {onLoadMore, children, ...selectProps} = props;

    function onPopupScroll(event: UIEvent) {
        onScrollInfiniteScrollContainer(event, onLoadMore);
    }

    function dropdownRender(menu: ReactElement): JSX.Element {
        return (
            <div>
                {menu}

                <div className={styles.infinite_spinner_faux}>
                    {selectProps.loading && (
                        <div className={styles.infinite_spinner}>
                            <Spinner size={20} spinSize="small" />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <Select<ValueType>
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...selectProps}
            dropdownRender={dropdownRender}
            onPopupScroll={onPopupScroll}
            placement="bottomLeft"
        >
            {children}
        </Select>
    );
}
