import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {Dispatch, SetStateAction, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {appRoute} from '../../app-route';
import {Locale} from '../../provider/locale/locale';
import {useLocale} from '../../provider/locale/locale-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {deleteProfile, fetchProfile, postProfile, putProfileNewPassword} from './profile-api';
import {handleProfileExceptions} from './profile-helper';
import {ProfileNewPasswordType, ProfileType} from './profile-type';

type UpdateProfileAdditionalType = {
    updateProfile: (profile: ProfileType) => Promise<void>;
};

type UpdateProfilePasswordAdditionalType = {
    updatePassword: (password: ProfileNewPasswordType) => Promise<void>;
};

export function useProfile(): UseHookType<ProfileType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<ProfileType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchProfile()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {
        isInProgress,
        processError,
        result,
        reset,
    };
}

export function useUpdateProfile(): UseHookType<ProfileType, string> & UpdateProfileAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} = useApiHooks<
        ProfileType,
        string
    >();
    const {getLocalizedString} = useLocale();

    const updateProfile = useCallback(
        (profile: ProfileType): Promise<void> => {
            setIsInProgress(true);

            return postProfile(profile)
                .then((response) => {
                    setResult(response);
                    setProcessError(null);
                })
                .finally(() => setIsInProgress(false))
                .catch((error: Error): void => {
                    setProcessError(getLocalizedString('SERVER_SENDING_DATA_ERROR'));
                    throw error;
                });
        },
        [setIsInProgress, setProcessError, setResult, getLocalizedString]
    );

    return {
        isInProgress,
        processError,
        result,
        reset,
        updateProfile,
    };
}

export function useUpdateProfilePassword(): UseHookType<ProfileType, string> & UpdateProfilePasswordAdditionalType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} = useApiHooks<
        ProfileType,
        string
    >();

    const {getLocalizedString} = useLocale();

    const updatePassword = useCallback(
        (password: ProfileNewPasswordType): Promise<void> => {
            setIsInProgress(true);

            return putProfileNewPassword(password)
                .then((response) => {
                    setResult(response);
                    setProcessError(null);
                })
                .finally(() => setIsInProgress(false))
                .catch((error: Error): void => {
                    setProcessError(getLocalizedString('SERVER_SENDING_DATA_ERROR'));
                    throw error;
                });
        },
        [setIsInProgress, setProcessError, getLocalizedString, setResult]
    );

    return {
        isInProgress,
        processError,
        result,
        reset,
        updatePassword,
    };
}

export function useRemoveProfileMutation(
    setErrorText: Dispatch<SetStateAction<string>>
): UseMutationResult<unknown, unknown, string> {
    const {snackbar} = useSnackbar();
    const {push} = useHistory();

    return useMutation({
        mutationFn: (password: string) => deleteProfile(password),
        onSuccess: () => {
            snackbar.success({
                message: (
                    <Locale stringKey="PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__NOTIFICATION__SUCCESS__TITLE" />
                ),
                description: (
                    <Locale stringKey="PROFILE__PERSONAL_INFORMATION__DELETE_ACCOUNT_MODAL__NOTIFICATION__SUCCESS__CONTENT" />
                ),
            });

            push(appRoute.login.path);
        },
        onError: (error: unknown) => {
            handleProfileExceptions(error, snackbar, setErrorText);
        },
    });
}
