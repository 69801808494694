import {ReactNode} from 'react';

import {classNames} from '../../../../../../../util/css';

import {InputText} from './input-text/input-text';
import {TextType} from './input-text-type';
import * as styles from './input-text-list.scss';

type PropsType = {
    textList: Array<TextType>;
    setTextList: (textList: Array<TextType>) => void;
    className?: string;
    label: JSX.Element;
    placeholder: string;
    disabled?: boolean;
    errorMessage?: ReactNode;
};

export function InputTextList(props: PropsType): JSX.Element {
    const {textList, setTextList, className, label, placeholder, disabled = false, errorMessage} = props;

    const fullClassName = classNames(styles.input_text_list, className);

    function handleRemove(index: number) {
        return function remove() {
            const newList = [...textList];

            newList.splice(index, 1);
            setTextList(newList);
        };
    }

    return (
        <div className={fullClassName}>
            {textList.map((text: TextType, index: number): JSX.Element => {
                const {id: key} = text;

                function handleOnInput(newTextNumber: TextType) {
                    const newList = [...textList];

                    newList[index] = newTextNumber;

                    setTextList(newList);
                }

                if (index === 0) {
                    return (
                        <InputText
                            disabled={disabled}
                            errorMessage={errorMessage}
                            key={key}
                            label={label}
                            onInput={handleOnInput}
                            placeholder={placeholder}
                            size="large"
                            textValue={text}
                        />
                    );
                }

                return (
                    <InputText
                        disabled={disabled}
                        key={key}
                        onInput={handleOnInput}
                        onRemove={disabled ? null : handleRemove(index)}
                        placeholder={placeholder}
                        size="middle"
                        textValue={text}
                    />
                );
            })}
        </div>
    );
}
