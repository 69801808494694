import {Route, Switch} from 'react-router';

import {appRoute} from '../../../app-route';
import {ErrorPage404} from '../../error/page-404/page-404';

import {ReviewActivityProvider} from './review-activity-provider/review-activity-provider';
import {ReviewItemPageContent} from './review-item-page-content/review-item-page-content';
import {ReviewListPageContent} from './review-list-page-content/review-list-page-content';
import {ReviewsStateProvider} from './reviews-state/reviews-state-provider';

export function Reviews(): JSX.Element {
    return (
        <Switch>
            <Route exact path={appRoute.reviewsManagementReviewItem.path}>
                <ReviewActivityProvider expandedReplies>
                    <ReviewsStateProvider>
                        <ReviewItemPageContent />
                    </ReviewsStateProvider>
                </ReviewActivityProvider>
            </Route>

            <Route exact path={appRoute.reviewsManagementReviews.path}>
                <ReviewsStateProvider>
                    <ReviewListPageContent />
                </ReviewsStateProvider>
            </Route>

            <Route component={ErrorPage404} />
        </Switch>
    );
}
