import {useCatalogConfig} from '../../../../../provider/catalogs/catalogs-hook';
import {CompanyKeyEnum} from '../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../service/company-v2/company-type';
import {Form, FormInstance} from '../../../../../typings/antd';

import {isCatalogAvailableInCountry} from './catalog-list-helper';

export function useIsCatalogAvailableInCountry(catalogId: number, form?: FormInstance<CompanyFormType>): boolean {
    const catalogs = useCatalogConfig();
    const catalog = catalogs.result?.find((item) => item.catalogId === catalogId);

    const country = Form.useWatch<CompanyKeyEnum.Address, FormInstance<CompanyFormType>, 'country'>(
        [CompanyKeyEnum.Address, 'country'],
        form
    );

    return Boolean(country && catalog && isCatalogAvailableInCountry(catalog, country.id));
}
