import {useMutation, UseMutationResult, useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {useLocale} from '../../provider/locale/locale-hook';
import {useSnackbar} from '../../provider/snackbar/snackbar-hook';
import {fetchAndDeserialize, getUrlParameters, postAndDeserialize} from '../../util/api-adapter';
import {getMessageFromReasonError} from '../../util/error';

const postReviewAbuseSchema = r.object({
    reason: r.string(),
});

type PostAbuseMutateType = {
    choices: Array<string>;
    description: string;
    reviewId: number;
};

type PostReviewAbuseType = r.infer<typeof postReviewAbuseSchema>;

function postReviewAbuse(reviewId: number, reason: string): Promise<PostReviewAbuseType> {
    return postAndDeserialize(`/v4/reviews/${reviewId}/abuse/`, postReviewAbuseSchema, {
        reason,
    });
}

export function useSendAbuseMutation(): UseMutationResult<PostReviewAbuseType, unknown, PostAbuseMutateType> {
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();

    return useMutation({
        mutationFn: (values) => {
            const choiceText = values?.choices.length > 0 ? `${values.choices.join('. ')}.` : '';
            const abuseText = `${choiceText} ${values.description.toString()}`;

            return postReviewAbuse(values.reviewId, abuseText);
        },
        onSuccess: () => {
            snackbar.success({
                description: getLocalizedString('REVIEWS__COMPLAINT__COMPLAINT_HAS_BEEN_SENT__DESCRIPTION'),
                message: getLocalizedString('REVIEWS__COMPLAINT__COMPLAINT_HAS_BEEN_SENT'),
            });
        },
        onError: (error) => {
            return snackbar.error(
                getMessageFromReasonError(error) || getLocalizedString('ERROR__SOMETHING_WENT_WRONG')
            );
        },
    });
}

export enum AbuseReasonStatusEnum {
    Sent = 'sent',
    Accepted = 'accepted',
    Declined = 'declined',
    AutoSent = 'auto_sent',
    WillBeCheckedByAi = 'will_be_checked_by_ai',
    Default = 'default',
}

const reviewsAbuseReasonSchema = r.array(
    r.object({
        id: r.number(),
        status: r.nativeEnum(AbuseReasonStatusEnum),
    })
);

type ReviewsAbuseReasonType = r.infer<typeof reviewsAbuseReasonSchema>;

function getReviewsAbuseReasonUrl(reviewsId: Array<number>): string {
    return `/v4/reviews/abuse_status/${getUrlParameters({reviewsId})}`;
}

async function fetchReviewsAbuseReason(reviewIds: Array<number>): Promise<ReviewsAbuseReasonType> {
    return fetchAndDeserialize(getReviewsAbuseReasonUrl(reviewIds), reviewsAbuseReasonSchema);
}

export function useReviewsAbuseReason(reviewIds: Array<number>): UseQueryResult<ReviewsAbuseReasonType> {
    return useQuery([getReviewsAbuseReasonUrl(reviewIds)], () => fetchReviewsAbuseReason(reviewIds), {
        enabled: reviewIds.length > 0,
        refetchOnWindowFocus: false,
    });
}
