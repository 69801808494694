import {
    DoctorsReviewsEnum,
    EmployeesReviewsEnum,
    ReviewsFilterFieldNameEnum,
} from '../../page/main/reviews/review-list-page-content/reviews-filter/reviews-filter-type';
import {ReviewsStateType, ReviewsStateUrlQueryNameEnum} from '../../page/main/reviews/reviews-state/reviews-state-type';
import {endOfUtcDay, getIsoDateWithTimezone, startOfUtcDay} from '../../util/date';
import {ApiError} from '../../util/error';
import {QueryMapType} from '../../util/type';

import {ReviewsReplyError, ReviewsReplyErrorPayloadType} from './reviews-reply';

function isReviewsReplyErrorPayload(errorPayload: unknown): errorPayload is ReviewsReplyErrorPayloadType {
    return (
        typeof (errorPayload as ReviewsReplyErrorPayloadType)?.error === 'string' ||
        typeof (errorPayload as ReviewsReplyErrorPayloadType)?.detail === 'string'
    );
}

export function handleReplyError(error: unknown): never {
    if (error instanceof ApiError && isReviewsReplyErrorPayload(error.jsonData)) {
        throw new ReviewsReplyError(error.message, error.statusCode, error.jsonData);
    }

    throw error;
}

export function reviewsStateFilterToUrlObject(reviewOption: ReviewsStateType): QueryMapType {
    const {filterState} = reviewOption;

    const dateFromRaw = filterState[ReviewsFilterFieldNameEnum.dateFrom];
    const dateToRaw = filterState[ReviewsFilterFieldNameEnum.dateTo];

    const dateFromAsString = dateFromRaw ? getIsoDateWithTimezone(startOfUtcDay(dateFromRaw)) : '';
    const dateToAsString = dateToRaw ? getIsoDateWithTimezone(endOfUtcDay(dateToRaw)) : '';

    const doctors = filterState[ReviewsFilterFieldNameEnum.doctorNames];
    const doctorsNames = doctors[0] === DoctorsReviewsEnum.all ? '' : String(doctors);
    const allDoctors = doctors[0] === DoctorsReviewsEnum.all ? DoctorsReviewsEnum.all : '';

    const respondingEmployees = filterState[ReviewsFilterFieldNameEnum.employeesResponding];
    const responsibleEmployees = filterState[ReviewsFilterFieldNameEnum.employeesResponsible];
    const allResponsibleEmployees =
        responsibleEmployees[0] === EmployeesReviewsEnum.all ? EmployeesReviewsEnum.all : '';

    const brandIdsRaw = filterState[ReviewsFilterFieldNameEnum.brandIds];
    const brandIds = brandIdsRaw ? String(brandIdsRaw) : '';

    return {
        brand_ids: brandIds,
        catalog_ids: filterState[ReviewsFilterFieldNameEnum.sourceList].join(','),
        process_status: filterState[ReviewsFilterFieldNameEnum.processStatus].join(','),
        rating: filterState[ReviewsFilterFieldNameEnum.ratingList].join(','),
        responsible_users: responsibleEmployees[0] === EmployeesReviewsEnum.all ? '' : String(responsibleEmployees),
        replier_ids: respondingEmployees[0] === EmployeesReviewsEnum.all ? '' : String(respondingEmployees),
        doctors_names: doctorsNames,
        all_doctors: allDoctors,
        all_responsible_users: allResponsibleEmployees,
        tag_ids: filterState[ReviewsFilterFieldNameEnum.tagList].join(','),
        without_tag: filterState[ReviewsFilterFieldNameEnum.withoutTag],
        autoreply: filterState[ReviewsFilterFieldNameEnum.autoReplay].join(','),
        without_text: filterState[ReviewsFilterFieldNameEnum.contentOfReviews],
        in_trash: filterState[ReviewsFilterFieldNameEnum.deletedReviews],
        is_replied: filterState[ReviewsFilterFieldNameEnum.repliesToReviews],
        action_reply: filterState[ReviewsFilterFieldNameEnum.possibilityOfAnAnswer],
        target: filterState[ReviewsFilterFieldNameEnum.reviewType],
        has_abuse: filterState[ReviewsFilterFieldNameEnum.complaintWasLeft],
        has_changes: filterState[ReviewsFilterFieldNameEnum.reviewChanged],
        need_to_reply_again: filterState[ReviewsFilterFieldNameEnum.waitingForSecondAnswer],
        date_gte: dateFromAsString, // '2021-05-01',
        date_lte: dateToAsString, // '2021-05-01',
        company_ids: reviewOption[ReviewsStateUrlQueryNameEnum.companyIds],
        moderated: filterState.moderated,
    };
}

export function reviewsStateToUrlObject(reviewOption: ReviewsStateType): QueryMapType {
    const ratingType = reviewOption[ReviewsStateUrlQueryNameEnum.ratingType];
    const ids = reviewOption[ReviewsStateUrlQueryNameEnum.ids];
    const companyIds = reviewOption[ReviewsStateUrlQueryNameEnum.companyIds];
    const orderingType = reviewOption[ReviewsStateUrlQueryNameEnum.sorting];

    const fullData: QueryMapType = {
        rating_type: ratingType,
        ordering: orderingType,
        ids,
        company_ids: companyIds,
        ...reviewsStateFilterToUrlObject(reviewOption),
    };

    const cleanData: QueryMapType = {};

    return Object.keys(fullData).reduce((accum: QueryMapType, key: string) => {
        const rawValue = fullData[key] || '';
        const value = rawValue.trim();

        return value ? {...accum, [key]: value} : accum;
    }, cleanData);
}
