import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, DatePicker, Drawer, Form, Row, Select, Space} from 'antd';
import dayjs from 'dayjs';
import {useCallback, useState} from 'react';

import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {ShortCatalogType} from '../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../provider/locale/locale';
import {
    PostsDrawerFilterType,
    PostsFilterKeyEnum,
    PostsFilterStateType,
    PostStatusEnum,
    SetPostFilterType,
} from '../../../../../service/posts/posts-types';
import {useDrawerWidthHack} from '../../../../../util/antd/drawer-hook';
import {getDatesFromRangeToFilter} from '../../../../../util/date';
import {TimeType} from '../../../../../util/time';
import {postStatusOptions, postStatusTranslationMap} from '../../posts-const';

import {filterFormId} from './posts-filter-form-const';
import {PostsFilterFormType} from './posts-filter-form-type';

type PropsType = {
    catalogs: Array<ShortCatalogType>;
    filter: PostsFilterStateType;
    setFilter: SetPostFilterType;
};

const {RangePicker} = DatePicker;

export function PostsFilterForm(props: PropsType): JSX.Element {
    const {filter, setFilter, catalogs} = props;

    const drawerWidth: string = useDrawerWidthHack();

    const [form] = Form.useForm<PostsFilterFormType>();
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

    const onFormOpen = useCallback((): void => {
        form.resetFields();
        setIsFormOpen(true);
    }, [form, setIsFormOpen]);

    const onFormClose = useCallback((): void => {
        form.resetFields();
        setIsFormOpen(false);
    }, [form, setIsFormOpen]);

    const onResetForm = useCallback((): void => {
        const emptyFormFilter: PostsFilterFormType = {
            [PostsFilterKeyEnum.sources]: [],
            [PostsFilterKeyEnum.statuses]: [],
            [PostsFilterKeyEnum.timeRange]: [null, null],
        };

        form.setFieldsValue(emptyFormFilter);
    }, [form]);

    const onSubmit = useCallback(
        (formValues: PostsFilterFormType): void => {
            const {timeRange: dayjsRange, ...restValues} = formValues;
            const {startOfFromDate, endOfToDate} = getDatesFromRangeToFilter(dayjsRange);
            const filterValues: PostsDrawerFilterType = {...restValues, timeRange: [startOfFromDate, endOfToDate]};

            setFilter(filterValues);
            setIsFormOpen(false);
        },
        [setFilter, setIsFormOpen]
    );

    const {getCatalogName} = useCatalogInfo();

    return (
        <>
            <Button icon={<FontAwesomeIcon icon={faFilter} />} onClick={onFormOpen} />

            <Drawer
                footer={
                    <Row justify="end">
                        <Space>
                            <Button onClick={onResetForm}>
                                <Locale stringKey="TEXT__RESET" />
                            </Button>

                            <Button form={filterFormId} htmlType="submit" type="primary">
                                <Locale stringKey="TEXT__APPLY" />
                            </Button>
                        </Space>
                    </Row>
                }
                onClose={onFormClose}
                open={isFormOpen}
                placement="right"
                title={<Locale stringKey="POSTS__FILTER_FORM__TITLE" />}
                width={drawerWidth}
            >
                <Form<PostsFilterFormType> form={form} id={filterFormId} layout="vertical" onFinish={onSubmit}>
                    <Form.Item<Array<number>>
                        initialValue={filter[PostsFilterKeyEnum.sources]}
                        label={<Locale stringKey="POSTS__FILTER_FORM__INPUT__SOURCE__LABEL" />}
                        name={PostsFilterKeyEnum.sources}
                    >
                        <Select<Array<number>>
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={<Locale stringKey="POSTS__FILTER_FORM__INPUT__SOURCE__PLACEHOLDER" />}
                        >
                            {catalogs.map((source) => {
                                const catalogName = getCatalogName(source.id);

                                return (
                                    <Select.Option key={catalogName} value={source.id}>
                                        {catalogName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item<Array<PostStatusEnum>>
                        initialValue={filter[PostsFilterKeyEnum.statuses]}
                        label={<Locale stringKey="POSTS__FILTER_FORM__INPUT__STATUS__LABEL" />}
                        name={PostsFilterKeyEnum.statuses}
                    >
                        <Select
                            mode="multiple"
                            optionFilterProp="children"
                            placeholder={<Locale stringKey="POSTS__FILTER_FORM__INPUT__STATUS__PLACEHOLDER" />}
                        >
                            {postStatusOptions.map((status) => (
                                <Select.Option key={status} value={status}>
                                    <Locale stringKey={postStatusTranslationMap[status]} />
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        initialValue={filter[PostsFilterKeyEnum.timeRange].map((date: TimeType) =>
                            date ? dayjs(date) : null
                        )}
                        label={<Locale stringKey="POSTS__FILTER_FORM__INPUT__TIME_PERIOD__LABEL" />}
                        name={PostsFilterKeyEnum.timeRange}
                    >
                        <RangePicker format="YYYY-MM-DD" />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
}
