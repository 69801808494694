import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from 'antd';
import React, {useState} from 'react';

import {Locale} from '../../../../../../provider/locale/localization';
import {LangKeyType} from '../../../../../../provider/locale/translation/type';

import * as styles from './step-header.scss';

type PropsType = {
    title: LangKeyType;
    alertIcon: IconProp;
    alertMessage: LangKeyType;
    alertMessageValueMap?: Record<string, React.ReactNode>;
};

export function StepHeader(props: PropsType): JSX.Element {
    const {title, alertIcon, alertMessage, alertMessageValueMap} = props;

    const [isAlertClosed, setIsAlertClosed] = useState(false);

    return (
        <header>
            <h3 className={styles.H3}>
                <Locale stringKey={title} />

                {isAlertClosed && (
                    <Button
                        icon={<FontAwesomeIcon icon={faInfoCircle} />}
                        onClick={() => setIsAlertClosed(false)}
                        type="text"
                    />
                )}
            </h3>

            {!isAlertClosed && (
                <Alert
                    afterClose={() => setIsAlertClosed(true)}
                    className={styles.Alert}
                    closable
                    icon={<FontAwesomeIcon icon={alertIcon} />}
                    message={<Locale stringKey={alertMessage} valueMap={alertMessageValueMap} />}
                    showIcon
                    type="info"
                />
            )}
        </header>
    );
}
