import {useMemo} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {AlertFallback} from '../../../../../../component/alert-fallback/alert-fallback';
import {Spinner} from '../../../../../../layout/spinner/spinner';
import {useBrands} from '../../../../../../provider/brands/brand-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {useReviewTopic} from '../../../../../../service/reivew-analysis/review-topics/review-topics-hook';
import {TopicActions} from '../../keywords-and-topics-page/topic-actions/topic-actions';

import * as styles from './topic-tags.scss';

type PropsType = {
    onDeleteSuccess: () => void;
};

export function TopicTags(props: PropsType): JSX.Element {
    const {onDeleteSuccess} = props;

    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();

    const {result, isInProgress, processError} = useReviewTopic(Number(id));

    const {brands} = useBrands();

    const topic = useMemo(() => ({userTopicId: Number(id), userTopic: true, topic: result?.name || ''}), [result, id]);

    const filteredBrands = useMemo(() => {
        if (result && brands && brands.length > 0) {
            return result.brands.map((brandId) => brands.find((brand) => brand.id === brandId)?.name);
        }

        return [];
    }, [result, brands]);

    return (
        <div className={styles.TopicTags}>
            <Spinner isShow={isInProgress} position="absolute" />

            {processError && <AlertFallback />}

            {result && !processError && (
                <>
                    <div className={styles.TopicTags_header}>
                        <h2 className={styles.TopicTags_title}>{result.name}</h2>
                        <div>
                            <TopicActions onDeleteSuccess={onDeleteSuccess} topic={topic} />
                        </div>
                    </div>
                    <table className={styles.TopicTags_table}>
                        <tbody>
                            {filteredBrands.length > 0 && (
                                <tr>
                                    <td>
                                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__BRAND__LABEL" />:
                                    </td>
                                    <td>
                                        <div className={styles.TopicTags_tags}>
                                            {filteredBrands.map((brand) => (
                                                <span className={styles.TopicTags_tag} key={brand}>
                                                    {brand}
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {result.includeTags && (
                                <tr>
                                    <td>
                                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__TAG_INCLUDE__LABEL" />:
                                    </td>
                                    <td>
                                        <div className={styles.TopicTags_tags}>
                                            {result.includeTags.map((tag) => (
                                                <span className={styles.TopicTags_tag} key={tag}>
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {result.excludeTags && (
                                <tr>
                                    <td>
                                        <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__TAG_EXCLUDE__LABEL" />:
                                    </td>
                                    <td>
                                        <div className={styles.TopicTags_tags}>
                                            {result.excludeTags.map((tag) => (
                                                <span className={styles.TopicTags_tag} key={tag}>
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
}
