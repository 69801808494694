import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {LangKeyType} from '../../../../provider/locale/translation/type';
import {useModal} from '../../../../provider/modal/modal-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {classNames} from '../../../../util/css';

import * as styles from './integration-button.scss';

type PropsType = {
    nameLangKey: LangKeyType;
    link: string;
    className?: string;
    hasTick?: boolean;
};

export function IntegrationButton(props: PropsType): JSX.Element {
    const {className, nameLangKey, link, hasTick = false} = props;

    const fullClassName = classNames(styles.integration_button, className);

    const {modal} = useModal();
    const {getLocalizedString} = useLocale();

    if (!link.trim()) {
        return (
            <button
                className={fullClassName}
                onClick={() => {
                    track(AnalyticsTarget.Settings.Integration.Service, getLocalizedString(nameLangKey));

                    modal.info({
                        title: <Locale stringKey="INTEGRATIONS__ALERT__HEADER" />,
                        content: <Locale stringKey="INTEGRATIONS__ALERT__TEXT" />,
                    });
                }}
                type="button"
            >
                <p className={styles.integration_button__text}>
                    <Locale stringKey={nameLangKey} />
                </p>
            </button>
        );
    }

    return (
        <NavigationLink
            className={fullClassName}
            onClick={() => track(AnalyticsTarget.Settings.Integration.Service, getLocalizedString(nameLangKey))}
            to={link}
        >
            <>
                {hasTick && <FontAwesomeIcon className={styles.integration_button__tick} icon={faCheckCircle} />}

                <p className={styles.integration_button__text}>
                    <Locale stringKey={nameLangKey} />
                </p>
            </>
        </NavigationLink>
    );
}
