import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal} from 'antd';
import {useState} from 'react';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../../../../provider/snackbar/snackbar-hook';
import {updateAccount} from '../../../../../../../../service/source-settings/accounts/accounts-api';
import {
    SourcesAccountType,
    UpdateSourcesAccountType,
} from '../../../../../../../../service/source-settings/accounts/accounts-type';
import {CatalogByGroupType} from '../../../../../../../../service/source-settings/catalogs/catalogs-type';
import {ManageAccountForm} from '../manage-account-form/manage-account-form';
import {ManageAccountFormType, ManageTypeAccountEnum} from '../manage-account-form/manage-account-form-type';

import {editAccountFormId} from './edit-account-const';
import * as styles from './edit-account.scss';

type PropsType = {
    catalog: CatalogByGroupType;
    catalogGroupId: number;
    account: SourcesAccountType;
    onSuccess: () => void;
};

export function EditAccount(props: PropsType): JSX.Element {
    const {account, catalog, catalogGroupId, onSuccess} = props;

    const {snackbar} = useSnackbar();
    const [isInProgress, setIsInProgress] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    function onOpenModal() {
        setIsModalOpen(true);
    }

    async function onSubmit(values: ManageAccountFormType): Promise<void> {
        const {login, password, uuid, brands} = values;
        const updatePayload: UpdateSourcesAccountType = {login, password, uuid, brands};

        try {
            setIsInProgress(true);
            await updateAccount(account.id, updatePayload); // TODO add payload fields

            snackbar.success({
                description: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__EDIT_SUCCESS_TITLE" />,
                message: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_SUCCESS_TEXT" />,
            });
            onSuccess();
            setIsModalOpen(false);
        } catch {
            snackbar.error({
                message: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__EDIT_FAIL_TITLE" />,
                description: <Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__ADD_FAIL_TEXT" />,
            });
        } finally {
            setIsInProgress(false);
        }
    }

    const initBrandsId = account.limitedBrandsNames.map(({id}) => id);

    return (
        <>
            <button className={styles.EditLinkButton} onClick={onOpenModal} type="button">
                <FontAwesomeIcon icon={faEdit} />
            </button>

            <Modal
                destroyOnClose
                footer={[
                    <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                        <Locale stringKey="POPUP__BUTTON__CANCEL" />
                    </Button>,
                    <Button
                        form={editAccountFormId}
                        htmlType="submit"
                        key="submit"
                        loading={isInProgress}
                        type="primary"
                    >
                        <Locale stringKey="BUTTON__ADD" />
                    </Button>,
                ]}
                onCancel={() => setIsModalOpen(false)}
                open={isModalOpen}
                title={<Locale stringKey="SOURCE_SETTINGS__ACCOUNTS__EDIT_ACCOUNT__MODAL_TITLE" />}
            >
                <ManageAccountForm
                    catalog={catalog}
                    catalogGroupId={catalogGroupId}
                    formId={editAccountFormId}
                    initialValues={{
                        login: '',
                        brands: initBrandsId,
                        defaultActiveKey: account.brandsCount
                            ? ManageTypeAccountEnum.brand
                            : ManageTypeAccountEnum.company,
                        accountId: account.id,
                        catalogId: account.catalog.id,
                        companyCount: account.companiesCount,
                    }}
                    isEditMode
                    onSubmit={onSubmit}
                />
            </Modal>
        </>
    );
}
