export const enReviewsAnalysisTranslations = {
    REVIEWS_ANALYSIS__MESSAGE_FULLNESS__MESSAGE_FULL: 'With message',
    REVIEWS_ANALYSIS__MESSAGE_FULLNESS__MESSAGE_LESS: 'No message',

    REVIEWS_ANALYSIS__SENTIMENT__POSITIVE: 'Positive',
    REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE: 'Negative',
    REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED: 'Unspecified',
    REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL: 'Neutral',

    REVIEWS_ANALYSIS__SENTIMENT__POSITIVE__LEGEND: 'Positive ({count})',
    REVIEWS_ANALYSIS__SENTIMENT__NEGATIVE__LEGEND: 'Negative ({count})',
    REVIEWS_ANALYSIS__SENTIMENT__UNSPECIFIED__LEGEND: 'Unspecified ({count})',
    REVIEWS_ANALYSIS__SENTIMENT__NEUTRAL__LEGEND: 'Neutral ({count})',

    REVIEWS_ANALYSIS__FILTER_FORM__TITLE: 'Filters',

    REVIEWS_ANALYSIS__FILTER__ACTIVE__LABEL: 'Filters',
    REVIEWS_ANALYSIS__FILTER__ACTIVE__COMPARE__LABEL: 'Compared to',

    REVIEWS_ANALYSIS__FORM__INPUT__PERIOD__LABEL: 'Time period',
    REVIEWS_ANALYSIS__FORM__INPUT__COMPETITOR__LABEL: 'Compare with competitor',
    REVIEWS_ANALYSIS__FORM__INPUT__COMPARE_PERIOD__LABEL: 'Compare with period',
    REVIEWS_ANALYSIS__FORM__INPUT__COMPETITOR__PLACEHOLDER: 'Select competitor',
    REVIEWS_ANALYSIS__FORM__INPUT__SENTIMENT__LABEL: 'Sentiments',
    REVIEWS_ANALYSIS__FORM__INPUT__SENTIMENT__PLACEHOLDER: 'Select sentiments',
    REVIEWS_ANALYSIS__FORM__INPUT__CATALOG__LABEL: 'Sources',
    REVIEWS_ANALYSIS__FORM__INPUT__CATALOG__PLACEHOLDER: 'Select sources',
    REVIEWS_ANALYSIS__FORM__INPUT__KEYWORDS__LABEL: 'Keywords',
    REVIEWS_ANALYSIS__FORM__INPUT__KEYWORDS__PLACEHOLDER: 'Start typing a keyword',
    REVIEWS_ANALYSIS__FORM__INPUT__PHRASES__LABEL: 'Phrases',
    REVIEWS_ANALYSIS__FORM__INPUT__PHRASES__PLACEHOLDER: 'Start typing a phrase',
    REVIEWS_ANALYSIS__FORM__INPUT__TOPICS__LABEL: 'Topics',
    REVIEWS_ANALYSIS__FORM__INPUT__TOPICS__PLACEHOLDER: 'Start typing a topic',
    REVIEWS_ANALYSIS__FORM__INPUT__RATE__LABEL: 'Rates',
    REVIEWS_ANALYSIS__FORM__INPUT__RATE__PLACEHOLDER: 'Select Rates',
    REVIEWS_ANALYSIS__FORM__INPUT__ALL_MSG_LABEL: 'Content of reviews',
    REVIEWS_ANALYSIS__FORM__INPUT__WITH_MSG_LABEL: 'With text',
    REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_MSG_LABEL: 'Without text',
    REVIEWS_ANALYSIS__FORM__INPUT__ALL_REPLY_LABEL: 'Replies to reviews',
    REVIEWS_ANALYSIS__FORM__INPUT__WITH_REPLY_LABEL: 'With a response',
    REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_REPLY_LABEL: 'Unanswered',
    REVIEWS_ANALYSIS__FORM__INPUT__ALL_ABLE_TO_REPLY_LABEL: 'Possibility of an answer',
    REVIEWS_ANALYSIS__FORM__INPUT__WITH_ABLE_TO_REPLY_LABEL: 'With possibility of an answer',
    REVIEWS_ANALYSIS__FORM__INPUT__WITHOUT_ABLE_TO_REPLY_LABEL: 'Without possibility of an answer',

    REVIEWS_ANALYSIS__FORM__INPUT__TOPIC__LABEL: 'Name',
    REVIEWS_ANALYSIS__FORM__INPUT__BRAND__LABEL: 'Brands',
    REVIEWS_ANALYSIS__FORM__INPUT__TOPIC__PLACEHOLDER: 'Type name',
    REVIEWS_ANALYSIS__FORM__INPUT__TAG_INCLUDE__LABEL: 'Includes keywords',
    REVIEWS_ANALYSIS__FORM__INPUT__TAG_EXCLUDE__LABEL: 'Excludes keywords',
    REVIEWS_ANALYSIS__FORM__INPUT__TAG__VALIDATION__ERROR: 'You need specify at least 1 tag',

    REVIEWS_ANALYSIS__FORM__CREATE__MESSAGE_TITLE: 'Your topic has been successfully created!',
    REVIEWS_ANALYSIS__FORM__CREATE__MESSAGE_TEXT:
        'We have started analyzing her data and will provide you with the results within 1 hour. Please wait. Your topic will be displayed in the list. Thank you.',
    REVIEWS_ANALYSIS__FORM__UPDATE__MESSAGE_TITLE: 'Topic successfully changed',
    REVIEWS_ANALYSIS__TOPIC__MESSAGE_ERROR__TOO_MANY_TAGS: 'The number of tags must be less than 50',

    REVIEWS_ANALYSIS__DASHBOARD__TITLE__LAST_DAYS: 'Analysis of reviews for the last {daysCount} days',
    REVIEWS_ANALYSIS__DASHBOARD__TITLE__RANGE: 'Analysis of reviews from {from} to {to}',

    REVIEWS_ANALYSIS__DASHBOARD__MAIN__TOTAL: 'Total',
    REVIEWS_ANALYSIS__DASHBOARD__MAIN__COMPLAINTS: 'Reported',
    REVIEWS_ANALYSIS__DASHBOARD__MAIN__EDITED: 'Edited by users',
    REVIEWS_ANALYSIS__DASHBOARD__MAIN__REPLY_REMOVED: 'Removed by users',
    REVIEWS_ANALYSIS__DASHBOARD__MAIN__TABLE__NAME: 'Index',
    REVIEWS_ANALYSIS__DASHBOARD__MAIN__TABLE__DIFFERENCE: 'Difference',

    REVIEWS_ANALYSIS_DASHBOARD__REGIONS__TITLE: 'Distribution of reviews by country and region',
    REVIEWS_ANALYSIS_DASHBOARD__REGIONS__SIDE__COUNTRY__TITLE: 'Total',
    REVIEWS_ANALYSIS_DASHBOARD__REGIONS__SIDE__COUNTRY_SELECTOR__PLACEHOLDER: 'Country',
    REVIEWS_ANALYSIS_DASHBOARD__REGIONS__SIDE__POPOVER__TITLE: 'Reviews on the map',
    REVIEWS_ANALYSIS_DASHBOARD__REGIONS__SIDE__POPOVER__CONTENT:
        'This block takes into account only reviews that are geo-referenced to a location.',
    REVIEWS_ANALYSIS_DASHBOARD__REGIONS__SIDE__SENTIMENT__TITLE: 'Region sentiment',
    REVIEWS_ANALYSIS_DASHBOARD__MAP_NOT_FOUND_TITLE: 'Map is temporarily unavailable',
    REVIEWS_ANALYSIS_DASHBOARD__MAP_NOT_FOUND_DESCRIPTION:
        'The map for the selected country is not in our database yet. We are working on expanding our map collection. Please check back later or contact us.',

    REVIEWS_ANALYSIS__DASHBOARD__MESSAGE_FULLNESS__TITLE: 'Content of reviews',
    REVIEWS_ANALYSIS__DASHBOARD__MESSAGE_FULLNESS__CENTER_LABEL: 'Reviews with message',

    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_DISTRIBUTION__TITLE: 'Sentiment distribution',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_DISTRIBUTION__CENTER_LABEL: 'Total sentiment',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_DISTRIBUTION__CENTER_LABEL__ERROR_SINGULAR:
        'At least {threshold} review is required to calculate the average sentiment',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_DISTRIBUTION__CENTER_LABEL__ERROR_PLURAL_FEW:
        'At least {threshold} reviews are required to calculate the average sentiment',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_DISTRIBUTION__CENTER_LABEL__ERROR_PLURAL_MANY:
        'At least {threshold} reviews are required to calculate the average sentiment',

    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_TOOLTIP:
        'Expresses the relationship between Positive, Negative and Neutral reviews on a scale from 0 to 100. The higher the index, the better the reviews.',

    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_BY_TIME__TITLE:
        'Distribution of reviews by time and tone for the selected period',

    REVIEWS_ANALYSIS__DASHBOARD__COMPANIES_RATE__TITLE:
        'The dynamics of the rating of locations by day for the selected period',
    REVIEWS_ANALYSIS__DASHBOARD__COMPANIES_AVERAGE_RATE__TITLE: 'Average Rating',
    REVIEWS_ANALYSIS__DASHBOARD__COMPANIES_RATE__LOCATIONS_LINK: 'Statistics by locations',

    REVIEWS_ANALYSIS__DASHBOARD__TAG_CLOUD__TITLE: 'Tag cloud',
    REVIEWS_ANALYSIS__DASHBOARD__TAG_CLOUD__TAGS_LINK: 'Tag Words statistics',

    REVIEWS_ANALYSIS__DASHBOARD__SOURCES__TITLE: 'Sources',
    REVIEWS_ANALYSIS__DASHBOARD__SOURCES_PERIOD__TITLE: 'Sources for the period {period} versus {comparePeriod}',
    REVIEWS_ANALYSIS__DASHBOARD__SOURCES__DEFAULT_PERIOD_TITLE: 'Sources for last {daysCount} days vs {comparePeriod}',
    REVIEWS_ANALYSIS__DASHBOARD__SOURCES_COMPETITOR__TITLE: 'Sources for the selected period',
    REVIEWS_ANALYSIS__DASHBOARD__SOURCES__COLUMN__NAME: 'Source',
    REVIEWS_ANALYSIS__DASHBOARD__SOURCES__COLUMN__COUNT: 'Reviews count (percentage\u00A0%)',
    REVIEWS_ANALYSIS__DASHBOARD__SOURCES__COMPARE_BRAND_NAME: 'Competitor brand',

    REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__DEFAULT_TITLE: 'Top {count} employees for the period',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__DEFAULT_PERIOD_TITLE:
        'Top {count} employees for the last {daysCount} days',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COMPARE_PERIOD__TITLE: 'Top {count} employees for the period {period}',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COLUMN__NAME: 'Employee',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__COLUMN__REPLY_COUNT: 'Number of responses',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_EMPLOYEE__REPORT_LINK: 'Employee report',

    REVIEWS_ANALYSIS__DASHBOARD__TOP_TOPICS__DEFAULT_TITLE: 'Top {count} topics',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_TOPICS__OWNER_TITLE: 'Top {count} topics. Your brands',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_TOPICS__COMPETITOR_TITLE: "Top {count} topics. Competitor's",
    REVIEWS_ANALYSIS__DASHBOARD__TOP_TOPICS__PERIOD_TITLE: 'Top {count} topics for period {period}',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_TOPICS__DEFAULT_PERIOD_TITLE: 'Top {count} topics for the last {daysCount} days',

    REVIEWS_ANALYSIS__DASHBOARD__TOP_PHRASES__TITLE: 'Top {count} phrases',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_PHRASES__OWNER_TITLE: 'Top {count} phrases. Your brands',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_PHRASES__COMPETITOR_TITLE: "Top {count} phrases. Competitor's",
    REVIEWS_ANALYSIS__DASHBOARD__TOP_PHRASES__PERIOD_TITLE: 'Top {count} phrases for period {period}',
    REVIEWS_ANALYSIS__DASHBOARD__TOP_PHRASES__DEFAULT_PERIOD_TITLE: 'Top {count} phrases for the last {daysCount} days',

    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_COMPARE__TITLE: 'Comparison with competitors',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_BY_TIME__OPTION_DAY: 'Day',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_BY_TIME__OPTION_WEEK: 'Week',
    REVIEWS_ANALYSIS__DASHBOARD__SENTIMENT_BY_TIME__OPTION_MONTH: 'Month',

    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__TITLE: 'Comprehensive comparison of reviews',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__AXIS__TOTAL: 'Total',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__AXIS__RATE: 'Rate',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__AXIS__POSITIVE: 'Positive',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__AXIS__NEGATIVE: 'Negative',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__AXIS__REPLIED: 'With message',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RADAR__AXIS__REPLY_TIME: 'Response time',

    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_TABLE__TITLE: 'Main factors',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_TABLE__COLUMN__NAME: 'Brand',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_TABLE__COLUMN__PER_LOCATION: 'Reviews per location',
    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_TABLE__COLUMN__TOP_SOURCE: 'Top source',

    REVIEWS_ANALYSIS__DASHBOARD__COMPETITORS_RATE__TITLE:
        'The dynamics of the rating by days in comparison with competitors',

    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS_TAB_KEYWORDS: 'Keywords',
    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS_TAB_TOPICS: 'Topics',
    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__TAGS_CLOUD_TITLE: 'Keyword distribution',
    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__KEYWORDS_TITLE: 'Keywords',
    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__DOUGNUT_CHART_ANOTHER: 'Another',
    REVIEWS_ANALYSIS__KEYWORDS_AND_TOPICS__DOUGNUT_CHART_TOTAL: 'Total (100%)',

    REVIEWS_ANALYSIS__TOPIC__KEYWORDS_DISTRIBUTION__TITLE: 'Keyword distribution',
    REVIEWS_ANALYSIS__TOPIC__REVIEWS_SENTIMENT__TITLE: 'Feedback Sentiment Distribution',
    REVIEWS_ANALYSIS__TOPIC__AVERAGE_RATING__TITLE: 'Average rating dynamics',
    REVIEWS_ANALYSIS__TOPIC__REVIEWS_COUNT__TITLE: 'Dynamics of the number of reviews',
    REVIEWS_ANALYSIS__TOPIC__STATS__TOTAL_COUNT: 'Total Reviews',
    REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_RATING: 'Average rating',
    REVIEWS_ANALYSIS__TOPIC__STATS__REVIEWS_WITH_ANSWER: 'Reviews with a response',
    REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_REPLY_TIME: 'Average response time',
    REVIEWS_ANALYSIS__TOPIC__STATS__AVERAGE_REPLY_TIME__DESCRIPTION: 'Average response time per review',
    REVIEWS_ANALYSIS__TOPIC__SENTIMENT_CHART_TOTAL: 'Total (100%) of {total} reviews',
    REVIEWS_ANALYSIS__TOPIC__TOP_FIVE_KEYWORDS: 'Top 5 keywords',
    REVIEWS_ANALYSIS__TOPIC__RATING_CHART_RATING: 'Rating',

    REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEW_TOTAL: 'Reviews total',
    REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY_CODE: 'Branch code',
    REVIEWS_ANALYSIS__TABLE__COLUMN__REVIEWS_REPLIED: 'Replied reviews',
    REVIEWS_ANALYSIS__TABLE__COLUMN__REPLIED_COUNT: 'Replied Reviews (percentage\u00A0%)',
    REVIEWS_ANALYSIS__TABLE__COLUMN__WITH_MESSAGE_PERCENTAGE: 'With answer',
    REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY: 'Companies',
    REVIEWS_ANALYSIS__TABLE__COLUMN__CITY: 'City',
    REVIEWS_ANALYSIS__TABLE__COLUMN__PHRASE: 'Phrase',
    REVIEWS_ANALYSIS__TABLE__COLUMN__TAG: 'Keyword',
    REVIEWS_ANALYSIS__TABLE__COLUMN__RATE: 'Rate',
    REVIEWS_ANALYSIS__TABLE__COLUMN__REPLY_DELAY: 'Response delay',
    REVIEWS_ANALYSIS__TABLE__COLUMN__SENTIMENT: 'Sentiment',
    REVIEWS_ANALYSIS__TABLE__COLUMN__TREND: 'Dynamics',
    REVIEWS_ANALYSIS__TABLE__COLUMN__TOPIC: 'Name',
    REVIEWS_ANALYSIS__TABLE__COLUMN__COMPLAINTS: 'Complaints',
    REVIEWS_ANALYSIS__TABLE__COLUMN__ACTIONS: 'Actions',
    REVIEWS_ANALYSIS__TABLE__TOTAL_TOPICS_COUNT: 'Total topics: {total}',
    REVIEWS_ANALYSIS__TABLE__COLUMN__ADDRESS: 'Address',
    REVIEWS_ANALYSIS__TABLE__COLUMN__COMPANY_GROUPS: 'Company groups',

    REVIEWS_ANALYSIS__FILTER__PERIOD_INFO_TITLE: 'Available period',
    REVIEWS_ANALYSIS__FILTER__PERIOD_INFO_TEXT:
        'Analytics provides data excluding the current and previous days. This approach allows you to more accurately analyze and compare data based on stable and well-established indicators, excluding the impact of recent changes.',

    REVIEWS_ANALYSIS__FILTER__PERIOD__DAY: 'Day',
    REVIEWS_ANALYSIS__FILTER__PERIOD__WEEK: 'Week',
    REVIEWS_ANALYSIS__FILTER__PERIOD__MONTH: '30 days',
    REVIEWS_ANALYSIS__FILTER__PERIOD__PREVIOUS_FULL_MONTH: 'Month',
    REVIEWS_ANALYSIS__FILTER__PERIOD__QUARTER: 'Quarter',
    REVIEWS_ANALYSIS__FILTER__PERIOD__CUSTOM: 'Custom',

    REVIEWS_ANALYSIS__FILTER__GROUP_BY__DAY: 'Group by: days',
    REVIEWS_ANALYSIS__FILTER__GROUP_BY__WEEK: 'Group by: weeks',
    REVIEWS_ANALYSIS__FILTER__GROUP_BY__MONTH: 'Group by: months',

    REVIEWS_ANALYSIS__COMPARE_PERIOD_TITLE__DEFAULT: 'Last {daysCount} days',
    REVIEWS_ANALYSIS__OWNER_BRAND_TITLE: 'Your brands',
    REVIEWS_ANALYSIS__COMPARE_BRAND_TITLE: "Competitor's brand",

    REVIEWS_ANALYSIS__DASHBOARD__COMPARE_PERIOD_NAME: 'Period {period}',
    REVIEWS_ANALYSIS__TABLE__COLUMN__LOCATION_COUNT: 'Locations total',

    REVIEWS_ANALYSIS__DASHBOARD__DETAILS_LINK: 'Details',

    REVIEWS_ANALYSIS__DASHBOARD__PRINT_PDF: 'Download analytics',

    REVIEWS_ANALYSIS__TOPICS_PAGE__CREATE_TOPIC__BUTTON: 'Create topic',
    REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__DESCRIPTIONS: 'A topic is a set of include and exclude tags.',
    REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__SAVE_BUTTON: 'Save',
    REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__RESET_BUTTON: 'Cancel changes',
    REVIEWS_ANALYSIS__CREATE_TOPIC_FORM__DELETE_BUTTON: 'Delete',

    REVIEW_ANALYSIS__POPUP_HEADER__DELETE_TOPIC: 'Delete topic?',
    REVIEW_ANALYSIS__POPUP__TEXT__THE_TOPIC_WILL_BE_REMOVED_ARE_YOU_SURE:
        'The "{topic}" topic will be removed. Are you sure?',
    REVIEW_ANALYSIS__SNACKBAR__TEXT__TOPIC_DELETED: 'The topic is successfully deleted',
    REVIEW_ANALYSIS__SNACKBAR__TEXT__TOPIC_DELETE_ERROR:
        "Couldn't delete the topic, some error occurred. Please try again later or connect with support team",
    REVIEW_ANALYSIS__POPOVER__TITLE__AUTO_TOPIC: 'Auto Topic',
    REVIEW_ANALYSIS__POPOVER__TEXT__AUTO_TOPIC: 'You can change topics created by you only.',

    REVIEWS_ANALYSIS__WORDS_FILTER__OPTION: '{option} words',

    REVIEWS_ANALYSIS__CONCORDANCE_FORM__TITLE: 'Search by reviews - Concordance',
    REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__QUERY__TITLE: 'Search words',
    REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__QUERY__PLACEHOLDER: 'Enter one or more search words',
    REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__CONTEXT__TITLE: 'Context',
    REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__CONTEXT__OPTION: '{value} letters',
    REVIEWS_ANALYSIS__CONCORDANCE_FORM__INPUT__SUBMIT__TITLE: 'Search',

    REVIEWS_ANALYSIS__CONCORDANCE_SUB_HEADER__TOTAL: 'Reviews found: {total}',

    REVIEWS_ANALYSIS__CONCORDANCE_TABLE__COLUMN__QUERY: 'Words',
    REVIEWS_ANALYSIS__CONCORDANCE_TABLE__COLUMN__PREFIX: 'Previous text',
    REVIEWS_ANALYSIS__CONCORDANCE_TABLE__COLUMN__POSTFIX: 'Next text',

    REVIEWS_ANALYSIS__CONCORDANCE__START_SEARCH: 'Start your search',

    REVIEWS_ANALYSIS__CONCORDANCE__SENTIMENT_CHART__TITLE: 'Feedback Sentiment Distribution',
    REVIEWS_ANALYSIS__CONCORDANCE__SENTIMENT_CHART__PERIOD: 'period: {period}',
    REVIEWS_ANALYSIS__CONCORDANCE__SENTIMENT_CHART__COUNT: 'based on {count} reviews',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT: 'Download Report',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__PDF__OPTION_TITLE: 'PDF',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__PDF__OPTION_TEXT: 'Generate report in PDF format',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__EXCEL__OPTION_TITLE: 'Excel',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__EXCEL__OPTION_TEXT: 'Export data to Excel file',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__NAME_FIELD__TOOLTIP_TITLE: 'File Name',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__NAME_FIELD__TOOLTIP_TEXT:
        'You can change the name of the downloaded file or leave it as default',
    REVIEWS_ANALYSIS__DOWNLOAD_REPORT__POPUP__NAME_FIELD: 'Report Name',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__CHART_TITLE: 'Rating Dynamics',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__QR_CODE: 'QR Code',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__MAILING: 'Mailing',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_ACTION__HIDDEN_ITEMS: '{count} more events from review generation',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__NAME: 'Name',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__NAME__PLACEHOLDER: 'Search by company name',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__ADDRESS: 'Address',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__ADDRESS__PLACEHOLDER: 'Search by company address',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__BASE_RATING: 'Before',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__TARGET_RATING: 'After',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CODE: 'Branch code',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CODE__PLACEHOLDER: 'Search by code',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CATALOG_FILTER__RATING_DROP: 'Rating dropped',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CATALOG_FILTER__RATING_UNCHANGED: 'Rating unchanged',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CATALOG_FILTER__RATING_GROW: 'Rating increased',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__ACTUALIZED: 'Has a blue checkmark',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__NOT_ACTUALIZED: 'No blue checkmark',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__NEED_ACTUALIZATION:
        'The blue checkmark will soon disappear',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_TITLE__ACTUALIZED:
        'Information verified by the owner',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_TITLE__NOT_ACTUALIZED: 'No blue checkmark',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_TITLE__NEED_ACTUALIZATION:
        'The blue checkmark will soon disappear',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_CONTENT__ACTUALIZED:
        'Users see a blue checkmark next to your company name on Yandex Maps and in Yandex search results',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_CONTENT__NOT_ACTUALIZED:
        'To get the blue checkmark, you need to regularly update information in the company profile',
    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__POPOVER_CONTENT__NEED_ACTUALIZATION:
        'Update the information in your profile to retain the blue checkmark on Yandex Maps',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__TOTAL_COMPANIES: 'Total active companies: {totalCount}',

    REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__EXPAND_TABLE_TOOLTIP: 'View chart',

    REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__DOWNLOAD_REPORT: 'Download report',
    REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__SENT_BY_EMAIL__TITLE: 'Request Sent',
    REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__SENT_BY_EMAIL__DESCRIPTION:
        'The report for the selected dates will be sent to the email address specified in the profile',
    REVIEWS_ANALYSIS__RATINGS_DYNAMICS__REPORT__SENT_BY_EMAIL__ERROR_DESCRIPTION:
        'Error sending report, please contact support team',
};
