import {
    KnowledgeArticleType,
    KnowledgeDataChildType,
    KnowledgeOptionsType,
} from '../../../service/knowledge/knowledge-type';
import {QueryMapType} from '../../../util/type';

import {KnowledgeFilterEnum, KnowledgeFilterType} from './knowledge-page-type';

export function getKnowledgeFilterFromUrlQuery(queries: QueryMapType<KnowledgeFilterEnum>): KnowledgeFilterType {
    return {
        [KnowledgeFilterEnum.name]: queries[KnowledgeFilterEnum.name] || '',
    };
}

export function serializeKnowledgeFilter(filter: KnowledgeFilterType): KnowledgeOptionsType {
    return {
        q: filter[KnowledgeFilterEnum.name],
    };
}

export function countArticles(category: Array<KnowledgeArticleType | KnowledgeDataChildType>): number {
    return category.reduce((accumulator, current) => {
        if ('articleId' in current) {
            // eslint-disable-next-line no-param-reassign
            accumulator += 1;
        }

        if ('content' in current) {
            // eslint-disable-next-line no-param-reassign
            accumulator += countArticles(current.content);
        }

        return accumulator;
    }, 0);
}
