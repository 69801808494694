import {Select} from 'antd';
import {useMemo} from 'react';

import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useReviewAnalysisSources} from '../../../../../../service/reivew-analysis/review-analysis-sources/review-analysis-sources-hook';
import {compareSelectOptions, renderSelectOption} from '../drawer-filter-helper';
import {SelectOptionType} from '../drawer-filter-type';

type PropsType = {
    disabled?: boolean;
    value?: Array<number>;
    placeholder?: JSX.Element;
    onChange?: (value: Array<number>) => void;
};

export function CatalogSelect(props: PropsType): JSX.Element {
    const {value, onChange, placeholder, disabled} = props;

    const {shortLocaleName} = useLocale();
    const {result: catalogsResult, isInProgress: isCatalogsInProgress} = useReviewAnalysisSources();
    const catalogOptions: Array<SelectOptionType<number>> = useMemo((): Array<SelectOptionType<number>> => {
        return (catalogsResult || []).sort(compareSelectOptions<number>(shortLocaleName));
    }, [catalogsResult, shortLocaleName]);

    const {getCatalogName} = useCatalogInfo();

    return (
        <Select<Array<number>>
            disabled={isCatalogsInProgress || disabled}
            loading={isCatalogsInProgress}
            mode="multiple"
            onChange={onChange}
            optionFilterProp="children"
            placeholder={placeholder || <Locale stringKey="REVIEWS_ANALYSIS__FORM__INPUT__CATALOG__PLACEHOLDER" />}
            value={value}
        >
            {catalogOptions
                .map((catalog) => {
                    return {
                        ...catalog,
                        name: getCatalogName(catalog.id),
                    };
                })
                .map<JSX.Element>(renderSelectOption)}
        </Select>
    );
}
