import {z as r} from 'zod';

export enum ReviewRatingByDateKeysEnum {
    One = 'rating_1',
    Two = 'rating_2',
    Three = 'rating_3',
    Four = 'rating_4',
    Five = 'rating_5',
    Average = 'avg_rating',
}

const periodRatingByTimeSchema = r.record(
    r.string(),
    r.object({
        [ReviewRatingByDateKeysEnum.Average]: r.number().nullable(),
        [ReviewRatingByDateKeysEnum.One]: r.number(),
        [ReviewRatingByDateKeysEnum.Two]: r.number(),
        [ReviewRatingByDateKeysEnum.Three]: r.number(),
        [ReviewRatingByDateKeysEnum.Four]: r.number(),
        [ReviewRatingByDateKeysEnum.Five]: r.number(),
    })
);

export const reviewsRatingByTimeSchema = r.object({
    current: periodRatingByTimeSchema,
    compared: periodRatingByTimeSchema,
});
export type ReviewsRatingByDateType = r.infer<typeof reviewsRatingByTimeSchema>;
