import {FilterFormType} from './companies-step-type';

export const PERSONAL_ACCOUNT_COMPANIES_LIMIT = 9;

export enum FilterFormEnum {
    Search = 'search',
    BrandsIds = 'brandsIds',
}

export const initialValues: FilterFormType = {
    [FilterFormEnum.Search]: '',
    [FilterFormEnum.BrandsIds]: [],
};
