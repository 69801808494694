import {Button, Checkbox, Form} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox/Checkbox';
import dayjs, {Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';

import {AdditionalInfo} from '../../../../layout/additional-info/additional-info';
import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {TimeRangePicker} from '../../../../layout/time-range-picker/time-range-picker';
import {useLicenses} from '../../../../provider/license/license-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useSnackbar} from '../../../../provider/snackbar/snackbar-hook';
import {enablePushNotifications} from '../../../../service/firebase/firebase-messaging';
import {useBrowserNotifications} from '../../../../service/notifications-settings/notifications-settings-hook';
import {
    BrowserNotificationsType,
    NotificationTextReviewEnum,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {classNames} from '../../../../util/css';
import {ErrorWithStatus, isErrorHasStatusMessage} from '../../../../util/error';
import * as notificationStyles from '../notification.scss';
import {NotificationReviewFilter} from '../notification-review-filter/notification-review-filter';
import {NotificationsReviewsFilterType} from '../notification-review-filter/notification-review-filter-type';

import {browserNotificationsFields} from './notification-browser-const';
import {handleSendNotificationsChange, serializeBrowserNotification} from './notification-browser-helper';
import {BrowserNotificationFormValuesType} from './notification-browser-type';

export function NotificationBrowser(): JSX.Element {
    const [form] = Form.useForm<BrowserNotificationFormValuesType & NotificationsReviewsFilterType>();
    const domain = typeof location === 'undefined' ? '' : location.hostname;

    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const {licenses} = useLicenses();

    const hasOmnichannel = licenses[FeaturesEnum.omnichannel]?.isActive;
    const [isBrowserNotificationsTurnedOn, setIsBrowserNotificationsTurnedOn] = useState<boolean>(false);
    const [isOmnichannelTurnedOn, setIsOmnichannelTurnedOn] = useState<boolean>(false);

    const {isInProgress, result: browserNotifications, updateBrowserNotifications} = useBrowserNotifications();

    async function handleFinishSuccess() {
        const formData: BrowserNotificationsType = serializeBrowserNotification(form);

        const response = await updateBrowserNotifications(formData);

        if (response instanceof Error) {
            snackbar.error(
                isErrorHasStatusMessage<ErrorWithStatus>(response) ? (
                    response.jsonData.status
                ) : (
                    <Locale stringKey="NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__FAILED_TO_UPDATE" />
                )
            );
        } else {
            snackbar.success({
                message: <Locale stringKey="NOTIFICATIONS__SETTINGS_SAVED" />,
                description: <Locale stringKey="NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__UPDATED" />,
            });
        }
    }

    function handleFormChange() {
        const isBrowserNotifications = form.getFieldValue(browserNotificationsFields.send_pushalert_notifications);

        setIsBrowserNotificationsTurnedOn(isBrowserNotifications);

        if (hasOmnichannel) {
            const isOmnichannelOn = form.getFieldValue(
                browserNotificationsFields.send_pushalert_omnichannel_notifications
            );

            setIsOmnichannelTurnedOn(isOmnichannelOn);
        }
    }

    function handleChangeOmnichannelNotification(event: CheckboxChangeEvent): void {
        if (!form.getFieldValue(browserNotificationsFields.send_pushalert_notifications) && event.target.checked) {
            enablePushNotifications();
        }
    }

    useEffect(() => {
        if (browserNotifications) {
            setIsBrowserNotificationsTurnedOn(browserNotifications.send_pushalert_notifications);

            if (hasOmnichannel) {
                setIsOmnichannelTurnedOn(browserNotifications.send_pushalert_omnichannel_notifications);
            }

            form.setFieldsValue({
                catalog: browserNotifications.pushalert_notifications_catalogs || [],
                ratings: browserNotifications.pushalert_notifications_ratings || [],
                type: browserNotifications.pushalert_notifications_content_type || NotificationTextReviewEnum.all,
            });
        }
    }, [browserNotifications, form]);

    if (!browserNotifications) {
        return <Spinner wrapperHeight={400} />;
    }

    const timeStart: Dayjs = dayjs(browserNotifications.pushalert_notifications_time_bucket?.start || '00:00', 'HH:mm');
    const timeEnd: Dayjs = dayjs(browserNotifications.pushalert_notifications_time_bucket?.end || '23:59', 'HH:mm');

    const reviewsFilterFields: NotificationsReviewsFilterType = {
        catalog: browserNotifications.pushalert_notifications_catalogs || [],
        ratings: browserNotifications.pushalert_notifications_ratings || [],
        type: browserNotifications.pushalert_notifications_content_type || NotificationTextReviewEnum.all,
    };

    return (
        <>
            <PageSubHeader className={notificationStyles.Notification_reviewsSubHeader}>
                <Locale stringKey="NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__SUB_HEADER" />
                &nbsp;
                <AdditionalInfo title={<Locale stringKey="NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS" />}>
                    <Locale
                        stringKey="NOTIFICATIONS__TAB_INFO__BROWSER_NOTIFICATIONS__SUB_HEADER__HINT"
                        valueMap={{domain}}
                    />
                </AdditionalInfo>
            </PageSubHeader>

            <Form
                className={notificationStyles.Notification_form}
                form={form}
                layout="vertical"
                name="notification-browser"
                onChange={handleFormChange}
                onFinish={handleFinishSuccess}
            >
                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    initialValue={browserNotifications.send_pushalert_notifications}
                    name={browserNotificationsFields.send_pushalert_notifications}
                    valuePropName="checked"
                >
                    <Checkbox onChange={handleSendNotificationsChange}>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_NOTIFICATIONS_OF_NEW_REVIEWS" />
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    className={notificationStyles.Notification_checkbox}
                    hidden={!hasOmnichannel}
                    initialValue={browserNotifications.send_pushalert_omnichannel_notifications}
                    name={browserNotificationsFields.send_pushalert_omnichannel_notifications}
                    valuePropName="checked"
                >
                    <Checkbox onChange={handleChangeOmnichannelNotification}>
                        <Locale stringKey="NOTIFICATIONS__RECEIVE_OMNICHANNEL" />
                    </Checkbox>
                </Form.Item>

                <PageSubHeader
                    className={classNames(
                        notificationStyles.Notification_reviewsSubHeader,
                        notificationStyles.Notification_reviewsSubHeader__space
                    )}
                >
                    <Locale stringKey="NOTIFICATIONS__SETTING_THE_TIME_FOR_RECEIVING_NOTIFICATIONS" />
                </PageSubHeader>

                <Form.Item
                    className={notificationStyles.Notification_wideInput}
                    initialValue={[timeStart, timeEnd]}
                    label={<Locale stringKey="NOTIFICATIONS__TIME_PERIOD" />}
                    name={browserNotificationsFields.pushalert_notifications_time_bucket}
                >
                    <TimeRangePicker
                        className={notificationStyles.Notification_wideInput}
                        disabled={!isBrowserNotificationsTurnedOn && !isOmnichannelTurnedOn}
                        placeholder={[getLocalizedString('TEXT__TIME_FROM'), getLocalizedString('TEXT__TIME_TO')]}
                        size="large"
                    />
                </Form.Item>

                <NotificationReviewFilter
                    className={notificationStyles.Notification_reviewFilter}
                    disabled={!isBrowserNotificationsTurnedOn}
                    fields={reviewsFilterFields}
                />

                <Button htmlType="submit" loading={isInProgress} type="primary">
                    <Locale stringKey="BUTTON__SAVE_SETTINGS" />
                </Button>
            </Form>
        </>
    );
}
