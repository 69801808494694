import {LangKeyType} from '../../../../provider/locale/translation/type';
import {FeaturesEnum} from '../../../../service/user/user-type';

import onlinePresenceIconSrc from './images/online-presence.svg';
import photoToolIconSrc from './images/phototool.svg';
import postsIconSrc from './images/posts.svg';

export const DisabledMessagesMap: Record<
    FeaturesEnum.posts | FeaturesEnum.photoTool | FeaturesEnum.onlinePresenceAnalytics | string,
    LangKeyType
> = {
    [FeaturesEnum.posts]: 'DASHBOARD_PAGE__FEATURE_DISABLED__POSTS',
    [FeaturesEnum.photoTool]: 'DASHBOARD_PAGE__FEATURE_DISABLED__PHOTO_TOOL',
    [FeaturesEnum.onlinePresenceAnalytics]: 'DASHBOARD_PAGE__FEATURE_DISABLED__ONLINE_PRESENCE',
};

export const DisabledImageIconsMap: Record<
    FeaturesEnum.posts | FeaturesEnum.photoTool | FeaturesEnum.onlinePresenceAnalytics | string,
    string
> = {
    [FeaturesEnum.posts]: postsIconSrc,
    [FeaturesEnum.photoTool]: photoToolIconSrc,
    [FeaturesEnum.onlinePresenceAnalytics]: onlinePresenceIconSrc,
};

export const DisabledTitlesMap: Record<
    FeaturesEnum.posts | FeaturesEnum.photoTool | FeaturesEnum.onlinePresenceAnalytics | string,
    LangKeyType
> = {
    [FeaturesEnum.posts]: 'CATEGORY_NAME__POSTS',
    [FeaturesEnum.photoTool]: 'DASHBOARD_PAGE__PHOTOS__TITLE',
    [FeaturesEnum.onlinePresenceAnalytics]: 'DASHBOARD_PAGE__ONLINE_PRESENCE__TITLE',
};
