import {Button, Col, Input, Row, Select, Tooltip} from 'antd';
import {useMemo, useState} from 'react';

import {LandingOnboardingStep} from '../../../../../../../component/landing/landing-onboarding-steps/landing-onboarding-step';
import {TrialActivationModal} from '../../../../../../../component/landing/trial-activation-modal/trial-activation-modal';
import {Text} from '../../../../../../../component/text/text';
import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useSystem} from '../../../../../../../provider/system/system-hook';
import {UseCompaniesSelectorHookType} from '../../../../../../../service/feature-companies/feature-companies-type';
import {
    LocalPackPointDistanceEnum,
    LocalPackServicesEnum,
    LocalPackSquareSizeEnum,
} from '../../../../../../../service/local-pack/local-pack-type';
import {
    useLocalPackTrialLicenseActivationMutation,
    useLocalPackTrialSearchServices,
} from '../../../../../../../service/local-pack/rules/local-pack-rule-hook';
import {
    LocalPackActivationRuleFormType,
    LocalPackRuleFormFieldsEnum,
    LocalPackRuleFrequencyEnum,
    LocalPackTrialPeriodActivationFormFieldEnum,
} from '../../../../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {Form, FormInstance} from '../../../../../../../typings/antd';
import {LOCALPACK_FREQUENCY_LOCALIZATION_MAP} from '../../../../local-pack-const';
import {LocalPackFormMap} from '../../../../local-pack-rule-form/local-pack-form-map/local-pack-form-map';
import {SelectServicesField} from '../../../../local-pack-rule-form/select-services/select-services-field';

import {
    getLocalPackTrialActivationFormInitialValues,
    LOCAL_PACK_TRIAL_PERIOD_DAYS,
} from './local-pack-activation-rule-helper';
import * as styles from './local-pack-activation-rule-step.scss';

type PropsType = {
    onStepFinish: VoidFunction;
    companiesSelector: UseCompaniesSelectorHookType;
};

export function LocalPackActivationRuleStep(props: PropsType): JSX.Element {
    const {onStepFinish, companiesSelector} = props;
    const [form] = Form.useForm<LocalPackActivationRuleFormType>();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isDesktop} = screen;
    const {getLocalizedString} = useLocale();

    const {mutateAsync, isLoading: isCreatingTrialLicense} = useLocalPackTrialLicenseActivationMutation();
    const [isActivationModalOpen, setIsActivationModalOpen] = useState<boolean>(false);

    const squareSize = Form.useWatch(LocalPackRuleFormFieldsEnum.SquareSize, {
        form,
        preserve: true,
    });

    const pointDistance = Form.useWatch(LocalPackRuleFormFieldsEnum.PointDistance, {
        form,
        preserve: true,
    });
    const {data: availableServices, isLoading: isLoadingAvailableServices} = useLocalPackTrialSearchServices();

    const searchPhrase = Form.useWatch<
        LocalPackTrialPeriodActivationFormFieldEnum.SearchPhrase,
        FormInstance<LocalPackActivationRuleFormType>
    >(LocalPackTrialPeriodActivationFormFieldEnum.SearchPhrase, form);

    const services = Form.useWatch<
        LocalPackTrialPeriodActivationFormFieldEnum.Services,
        FormInstance<LocalPackActivationRuleFormType>
    >(LocalPackTrialPeriodActivationFormFieldEnum.Services, form);

    const isFormFilled = useMemo(() => Boolean(searchPhrase && services?.length > 0), [searchPhrase, services?.length]);

    return (
        <LandingOnboardingStep
            arrowButtonProps={{
                onClick: () => setIsActivationModalOpen(true),
                hidden: !isDesktop,
                disabled: !isFormFilled || isCreatingTrialLicense,
                disabledTooltipLangKey: !isFormFilled ? 'LOCAL_PACK__ACTIVATION__STEP_2__NOT_READY_TOOLTIP' : null,
            }}
            direction="row"
        >
            <Text
                block
                className={styles.LocalPackActivationRuleStep_title}
                stringKey="LOCAL_PACK__ACTIVATION__STEP_2__TITLE"
            />
            <Text block lighter stringKey="LOCAL_PACK__ACTIVATION__STEP_2__TEXT_1" />

            <Form<LocalPackActivationRuleFormType>
                className={styles.LocalPackActivationRuleStep_form}
                disabled={isCreatingTrialLicense}
                form={form}
                initialValues={getLocalPackTrialActivationFormInitialValues(companiesSelector.selectorId)}
                layout="vertical"
            >
                <Row gutter={isDesktop ? [24, 24] : [0, 0]}>
                    <Col xs={isDesktop ? 12 : 24}>
                        <Form.Item<LocalPackActivationRuleFormType>
                            hidden
                            label={getLocalizedString('LOCAL_PACK__RULE_FORM__SEARCH_PHRASE__LABEL')}
                            name={LocalPackTrialPeriodActivationFormFieldEnum.CompaniesSelector}
                        />
                        <Form.Item<LocalPackActivationRuleFormType>
                            label={getLocalizedString('LOCAL_PACK__RULE_FORM__SEARCH_PHRASE__LABEL')}
                            name={LocalPackTrialPeriodActivationFormFieldEnum.SearchPhrase}
                        >
                            <Input
                                placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__SEARCH_PHRASE__PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Form.Item<LocalPackActivationRuleFormType>
                            label={getLocalizedString('LOCAL_PACK__RULE_FORM__SERVICES__LABEL')}
                            name={LocalPackTrialPeriodActivationFormFieldEnum.Services}
                        >
                            <SelectServicesField
                                availableCatalogs={availableServices}
                                catalogOptionDisabled={(catalog) => catalog.service !== LocalPackServicesEnum.maps}
                                loading={isLoadingAvailableServices}
                                placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__SERVICES__PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Form.Item<LocalPackActivationRuleFormType>
                            label={
                                <AdditionalInfo
                                    leftNode={<Locale stringKey="LOCAL_PACK__RULE_FORM__FREQUENCY__LABEL" />}
                                >
                                    <Text stringKey="LOCAL_PACK__ACTIVATION__STEP_2__FREQUENCY_POPOVER" />
                                </AdditionalInfo>
                            }
                            name={LocalPackTrialPeriodActivationFormFieldEnum.Frequency}
                        >
                            <Select<LocalPackRuleFrequencyEnum>
                                options={Object.values(LocalPackRuleFrequencyEnum).map(
                                    (frequencyOption) =>
                                        ({
                                            label: getLocalizedString(
                                                LOCALPACK_FREQUENCY_LOCALIZATION_MAP[frequencyOption]
                                            ),
                                            value: frequencyOption,
                                            disabled: frequencyOption !== LocalPackRuleFrequencyEnum.Once,
                                        } || [])
                                )}
                            />
                        </Form.Item>

                        <Form.Item<LocalPackActivationRuleFormType>
                            label={
                                <AdditionalInfo
                                    leftNode={<Locale stringKey="LOCAL_PACK__RULE_FORM__SQUARE_SIZE__LABEL" />}
                                >
                                    <Text stringKey="LOCAL_PACK__ACTIVATION__STEP_2__SQUARE_SIZE_POPOVER" />
                                </AdditionalInfo>
                            }
                            name={LocalPackTrialPeriodActivationFormFieldEnum.SquareSize}
                        >
                            <Select<LocalPackSquareSizeEnum>
                                options={Object.values(LocalPackSquareSizeEnum).map(
                                    (squareSizeOptions) =>
                                        ({
                                            label: squareSizeOptions,
                                            value: squareSizeOptions,
                                            disabled: squareSizeOptions !== LocalPackSquareSizeEnum.Small,
                                        } || [])
                                )}
                            />
                        </Form.Item>
                        <Form.Item<LocalPackActivationRuleFormType>
                            label={getLocalizedString('LOCAL_PACK__RULE_FORM__POINT_DISTANCE__LABEL')}
                            name={LocalPackTrialPeriodActivationFormFieldEnum.PointDistance}
                        >
                            <Select<LocalPackPointDistanceEnum>
                                options={Object.values(LocalPackPointDistanceEnum)
                                    .filter(Number.isInteger)
                                    .map(
                                        (pointDistanceOption) =>
                                            ({
                                                label: getLocalizedString('LOCAL_PACK__DISTANCE__LONG', {
                                                    distance: String(pointDistanceOption),
                                                }),
                                                value: pointDistanceOption,
                                            } || [])
                                    )}
                            />
                        </Form.Item>
                        <Tooltip
                            className={styles.LocalPackActivationRuleStep_buttonWrapper}
                            title={
                                !isFormFilled
                                    ? getLocalizedString('LOCAL_PACK__ACTIVATION__STEP_2__NOT_READY_TOOLTIP')
                                    : ''
                            }
                            trigger="hover"
                        >
                            <Button
                                className={styles.LocalPackActivationRuleStep_button}
                                disabled={!isFormFilled || isCreatingTrialLicense}
                                loading={isCreatingTrialLicense}
                                onClick={() => setIsActivationModalOpen(true)}
                                type="primary"
                            >
                                <Locale stringKey="LOCAL_PACK__ACTIVATION__STEP_2__BUTTON" />
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col hidden={!isDesktop} xs={12}>
                        <LocalPackFormMap pointsDistance={pointDistance} squareSize={squareSize} />
                    </Col>
                </Row>
            </Form>
            <TrialActivationModal
                activateTrial={() => mutateAsync(form.getFieldsValue())}
                isActivatingTrial={isCreatingTrialLicense}
                onCancel={() => setIsActivationModalOpen(false)}
                onTrialPeriodActivated={onStepFinish}
                open={isActivationModalOpen}
                productNameLangKey="CATEGORY_NAME__LOCAL_PACK"
                trialPeriodDuration={LOCAL_PACK_TRIAL_PERIOD_DAYS}
            />
        </LandingOnboardingStep>
    );
}
