import {fetchAndDeserialize} from '../../../util/api-adapter';
import {objectToUrlParameters} from '../../../util/url';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';
import {reviewsAndAnswersApiFilterValues} from '../filter/reviews-and-answers-statistics-helper';

import {ReviewsRatingByDateType, reviewsRatingByTimeSchema} from './reviews-rating-by-date-type';

export function fetchReviewsRatingByDate(
    filter: ReviewsAndAnswersFilterStateType,
    mainFilterKey: string
): Promise<ReviewsRatingByDateType> {
    return fetchAndDeserialize(
        `/cp/reviews/statistics/avg_ratings_by_day/?${objectToUrlParameters(
            reviewsAndAnswersApiFilterValues(filter, mainFilterKey)
        )}`,
        reviewsRatingByTimeSchema,
        {
            deserializeOptions: {
                ignorePaths: [/.*/],
            },
        }
    );
}
