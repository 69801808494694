import {z as r} from 'zod';

import {ApiError} from '../../../util/error';

const catalogByGroupSchema = r.object({
    id: r.number(),
    name: r.string(),
    logo: r.string().url(),
    supportBrandsReviews: r.boolean(),
    supportCompaniesReviews: r.boolean(),
});

const catalogGroupSchema = r.object({
    id: r.number(),
    catalogGroupName: r.string(),
    catalogs: r.array(catalogByGroupSchema),
});

export const catalogByGroupResponseSchema = r.array(catalogGroupSchema);

export type CatalogByGroupType = r.infer<typeof catalogByGroupSchema>;
export type CatalogGroupType = {
    id: number;
    catalogGroupName: string | JSX.Element;
    catalogs: Array<CatalogByGroupType>;
};

const suggestedCatalogSchema = r.object({
    name: r.string().optional(),
});

export type SuggestedCatalogType = r.infer<typeof suggestedCatalogSchema>;

export type SuggestedCatalogErrorPayloadType = {
    name?: Array<string>;
};

type SuggestedCatalogErrorDataType = {
    name?: Array<string>;
};

type SuggestedCatalogPayloadType = {
    name?: Array<string>;
};

export class SuggestedCatalogError extends ApiError {
    name = 'SuggestedCatalogError';
    jsonData: SuggestedCatalogErrorDataType;

    constructor(message: string, statusCode: number, jsonData: SuggestedCatalogPayloadType) {
        super(message, statusCode, jsonData);

        const {name = [], ...rest} = jsonData;

        this.jsonData = {...rest, name};
    }
}
