import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useContext} from 'react';

import {MainFilterContext} from '../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../provider/main-filter/main-filter-type';
import {ReviewsAndAnswersFilterStateType} from '../filter/reviews-and-answers-statistics-filter-type';

import {fetchTonalityDistribution} from './tonality-distribution-api';
import {TonalityDistributionType} from './tonality-distribution-type';

export function useTonalityDistribution(
    filter: ReviewsAndAnswersFilterStateType
): UseQueryResult<TonalityDistributionType> {
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);

    return useQuery({
        queryKey: ['tonalityDistribution', filter, mainFilterKey],
        queryFn: async () => {
            return fetchTonalityDistribution(filter, mainFilterKey);
        },
    });
}
