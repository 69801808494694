import {LabelledProgressBar} from '../../../../layout/labeled-progress-bar/labelled-progress-bar';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/locale';
import {OnlinePresenceSynchronizationType} from '../../../../service/online-presence/online-presence-type';
import {useFormat} from '../../../../util/format-hook/format-hook';

import * as styles from './synchronization-stats.scss';

type PropsType = {
    data: OnlinePresenceSynchronizationType['syncStatus']['catalogs'];
    averageSynchronization: number;
};

export function CardsOccupancy(props: PropsType): JSX.Element | null {
    const {data, averageSynchronization} = props;

    const {getFormattedNumber} = useFormat();
    const {getCatalogName} = useCatalogInfo();

    const titleContent = (
        <>
            <Locale stringKey="CATEGORY_NAME__ONLINE_PRESENCE__SYNCHRONIZATION" />{' '}
            <Locale
                stringKey="ONLINE_PRESENCE__SYNC__AVERAGE_RATING__CARD_NAME"
                valueMap={{
                    percentValue: getFormattedNumber(averageSynchronization, {
                        minimumFractionDigits: 2,
                        style: 'unit',
                        unit: 'percent',
                    }),
                }}
            />
        </>
    );

    return (
        <PageCard title={titleContent}>
            <div className={styles.SyncCard_wrapper}>
                {data.map((catalogInfoItem) => {
                    return (
                        <LabelledProgressBar
                            className={styles.SyncCard}
                            key={catalogInfoItem.id}
                            percent={Math.floor(catalogInfoItem.value)}
                            title={getCatalogName(catalogInfoItem.id)}
                        />
                    );
                })}
            </div>
        </PageCard>
    );
}
