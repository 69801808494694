import {ProfileType} from '../../../service/profile/profile-type';

export function hasUsedeskFilled(profileResult: ProfileType | null): boolean {
    if (!profileResult) {
        return false;
    }

    return (
        Boolean(profileResult.profile.usedesk_app_id) &&
        Boolean(profileResult.profile.usedesk_chat_id) &&
        Boolean(profileResult.profile.usedesk_secret)
    );
}
