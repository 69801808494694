import {faCommentAltLines} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'antd';
import {useLayoutEffect} from 'react';

import {useBrands} from '../../provider/brands/brand-hook';
import {useDomainConfig} from '../../provider/domain-config/domain-config-hook';
import {identifySmartLook, initHelpDeskChat} from '../../provider/domain-config/init-scripts/init-scripts';
import {helpDeskChatSettings} from '../../provider/domain-config/init-scripts/init-scripts-const';
import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useLocale} from '../../provider/locale/locale-hook';
import {useUser} from '../../provider/user/user-hook';
import {ruHelpDeskChatLocales} from '../app/routing/routing-const';

import * as styles from './help-desk-chat-widget.scss';

type PropsType = {
    userId: number;
    userEmail: string;
    userFirstName: string;
};

export function HelpDeskChatWidget(props: PropsType): JSX.Element | null {
    const {userEmail, userFirstName, userId} = props;

    const {user} = useUser();
    const {shortLocaleName} = useLocale();
    const {brands} = useBrands();
    const {isFreshChatShow: shouldShowChatOnDomain} = useDomainConfig();

    useLayoutEffect(() => {
        if (brands) {
            identifySmartLook(userId);

            initHelpDeskChat({
                email: userEmail,
                firstName: userFirstName,
                locale: ruHelpDeskChatLocales.has(shortLocaleName) ? ShortLocaleNameEnum.ru : ShortLocaleNameEnum.en,
                brandIds: brands.map(({id}) => id),
            });
        }
    }, [user, shortLocaleName, brands, userEmail, userFirstName, userId]);

    if (!shouldShowChatOnDomain) {
        return null;
    }

    return (
        <Button
            className={styles.HelpDeskChatWidget}
            icon={<FontAwesomeIcon className={styles.HelpDeskChatWidget_icon} icon={faCommentAltLines} />}
            id={helpDeskChatSettings.buttonId}
        />
    );
}
