import {z as r} from 'zod';

export const unansweredReviewsCountSchema = r.object({
    percentage: r.object({
        value: r.number(),
        comparedValue: r.number().nullable(),
        delta: r.number().nullable(),
    }),
    byDate: r
        .array(
            r.object({
                date: r.string(),
                count: r.number(),
            })
        )
        .optional(),
});

export type UnansweredReviewsCountType = r.infer<typeof unansweredReviewsCountSchema>;
