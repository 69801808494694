import {faChartColumn} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FC, useCallback} from 'react';

import {appRoute} from '../../../../app-route';
import {OnlinePresenceAnalyticsActivation} from '../../../../page/main/online-presence/online-presence-analytics-activation';
import {OnlinePresenceRouting} from '../../../../page/main/online-presence/online-presence-routing';
import {usePaidCatalogs} from '../../../../provider/catalogs/catalogs-hook';
import {useLicenses} from '../../../../provider/license/license-hook';
import {LocaleWithCatalogName} from '../../../../provider/locale/facebook-localization/locale-with-catalog-name';
import {useUser} from '../../../../provider/user/user-hook';
import {OnlinePresenceV2FilterQueriesEnum} from '../../../../service/online-presence/online-presence-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {ProvidersIdsEnum} from '../../../../util/type';
import {getFeatureOrLandingPage} from '../../../navigation/navigation-helper';
import {NavigationItemConfigType} from '../routing-type';

export function useOnlinePresenceNavigation(): Array<NavigationItemConfigType> {
    const {user} = useUser();
    const {isCatalogPaid} = usePaidCatalogs();
    const {licenses, availableFeatures} = useLicenses();

    const {regionAvailableCatalogs} = user || {};

    const onlinePresenceQueries = Object.values(OnlinePresenceV2FilterQueriesEnum);

    function isCatalogAvailable(providerId: ProvidersIdsEnum): boolean {
        return Boolean(regionAvailableCatalogs?.[providerId] && isCatalogPaid(providerId));
    }

    const getOnlinePresenceFeatureOrLandingPage = useCallback(
        (pageComponent: FC<Record<string, unknown>>) => {
            return getFeatureOrLandingPage({
                license: licenses[FeaturesEnum.onlinePresenceAnalytics],
                activationComponent: OnlinePresenceAnalyticsActivation,
                pageComponent,
            });
        },
        [licenses]
    );

    return [
        {
            isAvailable: Boolean(user) && availableFeatures[FeaturesEnum.onlinePresenceAnalytics],
            isActivated: licenses[FeaturesEnum.onlinePresenceAnalytics]?.isActive,
            showBeta: true,
            path: appRoute.onlinePresence.path,
            icon: <FontAwesomeIcon icon={faChartColumn} />,
            labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE_ANALYTICS',
            exact: false,
            deniedPath: [appRoute.onlinePresence.path],
            redirectPath: appRoute.onlinePresenceDashboard.path,
            component: getOnlinePresenceFeatureOrLandingPage(OnlinePresenceRouting),
            // todo: остальные роуты находятся в родительском компоненте OnlinePresenceRouting
            children: [
                {
                    allowedSearchParameters: onlinePresenceQueries,
                    path: appRoute.onlinePresenceDashboard.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__DASHBOARD',
                },
                {
                    allowedSearchParameters: onlinePresenceQueries,
                    path: appRoute.onlinePresenceSynchronization.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__SYNCHRONIZATION',
                },

                {
                    allowedSearchParameters: onlinePresenceQueries,
                    isAvailable: isCatalogAvailable(ProvidersIdsEnum.google),
                    path: appRoute.onlinePresenceGoogle.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__GOOGLE' as const,
                },

                {
                    allowedSearchParameters: onlinePresenceQueries,
                    isAvailable: isCatalogAvailable(ProvidersIdsEnum.yandex),
                    path: appRoute.onlinePresenceYandex.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__YANDEX' as const,
                },
                {
                    allowedSearchParameters: onlinePresenceQueries,
                    isAvailable: isCatalogAvailable(ProvidersIdsEnum.doubleGis),
                    path: appRoute.onlinePresenceWgis.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__WGIS' as const,
                },
                {
                    allowedSearchParameters: onlinePresenceQueries,
                    isAvailable: isCatalogAvailable(ProvidersIdsEnum.facebook),
                    path: appRoute.onlinePresenceFacebook.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__FACEBOOK' as const,
                    label: (
                        <LocaleWithCatalogName
                            catalogId={ProvidersIdsEnum.facebook}
                            namePlaceholder="FB"
                            stringKey="CATEGORY_NAME__ONLINE_PRESENCE__FACEBOOK"
                        />
                    ),
                },
                {
                    allowedSearchParameters: onlinePresenceQueries,
                    path: appRoute.onlinePresencePhrases.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__PHRASES',
                },
                {
                    allowedSearchParameters: onlinePresenceQueries,
                    path: appRoute.onlinePresenceLocations.path,
                    labelLangKey: 'CATEGORY_NAME__ONLINE_PRESENCE__LOCATIONS',
                },
            ],
        },
    ];
}
