import {classNames} from '../../../util/css';

import * as styles from './yandex-story-illustration.scss';

type PropsType = {
    className?: string;
    image: string;
};

export function YandexStoryIllustration(props: PropsType): JSX.Element {
    const {image, className} = props;

    return (
        <div className={classNames(styles.YandexIllustration, className)}>
            <div
                className={styles.YandexIllustration_image}
                style={{
                    backgroundImage: `url("${image}")`,
                }}
            />
        </div>
    );
}
