import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Modal, Radio} from 'antd';
import {useWatch} from 'antd/lib/form/Form';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {useActionRequiresLicense} from '../../../../../provider/license/license-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {useLocale} from '../../../../../provider/locale/locale-hook';
import {useModal} from '../../../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../../../service/analytics/analytics';
import {getIsRemoveAllowed, getIsReportAllowed} from '../../../../../service/phototool/phototool-helper';
import {
    MediaItemType,
    PhotoReportTypeEnum,
    PhotosReportDataType,
    PhotoStatusKeyEnum,
} from '../../../../../service/phototool/phototool-type';
import {FeaturesEnum} from '../../../../../service/user/user-type';
import {Form} from '../../../../../typings/antd';
import {classNames} from '../../../../../util/css';
import {PHOTO_REPORT_TYPE_ICON_MAP} from '../../photo-tool-const';

import {
    ALLOWED_PHOTO_REPORT_TYPES,
    INITIAL_REPORT_PHOTOS_VALUES,
    PHOTO_REPORT_TYPE_LOCALIZATION_MAP,
    reportFormId,
} from './photo-report-modal-const';
import {ReportFormFieldEnum, ReportFormType} from './photo-report-modal-type';
import * as styles from './photo-report-modal.scss';

const {TextArea} = Input;

type PropsType = {
    renderControl?: (showReportModal: () => void, removeSelected: () => void) => JSX.Element | null;
    onReport: (reportData: PhotosReportDataType) => Promise<void>;
    onSuccess?: () => void;
    photos: Array<MediaItemType>;
};

export function PhotoReport(props: PropsType): JSX.Element {
    const {renderControl, onReport, onSuccess, photos} = props;

    const {getLocalizedString} = useLocale();
    const {snackbar} = useSnackbar();
    const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
    const [okButtonAvailable, setOkButtonAvailable] = useState<boolean>(true);
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [form] = Form.useForm<ReportFormType>();

    const photosWithReportOrRemoveAllowed = photos.filter(
        (photo) => getIsReportAllowed(photo) || getIsRemoveAllowed(photo)
    );

    const message = useWatch(ReportFormFieldEnum.message, form);
    const type = useWatch(ReportFormFieldEnum.type, form);

    useEffect(() => {
        if (type !== PhotoReportTypeEnum.Other) {
            form.setFieldValue(ReportFormFieldEnum.message, '');
        }
    }, [form, type]);

    useEffect(() => {
        const canSubmitForm = Boolean(
            type &&
                (type !== PhotoReportTypeEnum.Other || (type === PhotoReportTypeEnum.Other && Boolean(message?.trim())))
        );

        setOkButtonAvailable(canSubmitForm);
    }, [message, type]);

    const {modal} = useModal();

    const handleRemove = useCallback(() => {
        modal.confirm({
            title: getLocalizedString(
                photos.length > 1 ? 'PHOTO_TOOL__BULK_REMOVE__CONFIRM__TITLE' : 'PHOTO_TOOL__REMOVE__CONFIRM__TITLE'
            ),
            content: getLocalizedString(
                photos.length > 1 ? 'PHOTO_TOOL__BULK_REMOVE__CONFIRM__TEXT' : 'PHOTO_TOOL__REMOVE__CONFIRM__TEXT'
            ),
            onOk: () => {
                onReport({
                    message: '',
                    type: PhotoReportTypeEnum.Other,
                })
                    .then(() => {
                        snackbar.success({
                            message: <Locale stringKey="PHOTO_TOOL__REMOVE__SUCCESS__TITLE" />,
                            description: (
                                <Locale
                                    stringKey={
                                        photos.length > 1
                                            ? 'PHOTO_TOOL__BULK_REMOVE__SUCCESS__TEXT'
                                            : 'PHOTO_TOOL__REMOVE__SUCCESS__TEXT'
                                    }
                                />
                            ),
                        });

                        if (onSuccess) {
                            onSuccess();
                        }
                    })
                    .catch(() => {
                        snackbar.error({
                            description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
                            message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                        });
                    });
            },
        });
    }, [getLocalizedString, modal, onReport, onSuccess, photos.length, snackbar]);

    const onShowModal: () => void = useCallback(() => setIsReportModalOpen(true), []);

    const onCancel = useCallback(() => {
        setIsReportModalOpen(false);
        form.resetFields();
        setIsInProgress(false);
    }, [form]);

    const onSubmit = useActionRequiresLicense(
        useCallback(
            (formValue: ReportFormType): void => {
                const reportMessage = formValue[ReportFormFieldEnum.message];
                const reportType = formValue[ReportFormFieldEnum.type];

                if (reportType) {
                    setIsInProgress(true);
                    onReport({
                        message: reportMessage || '',
                        type: reportType,
                    })
                        .then(() => {
                            modal.success({
                                title: <Locale stringKey="PHOTO_TOOL__REPORT__SUCCESS__TITLE" />,
                                content:
                                    photos.length > 0 ? (
                                        <Locale
                                            stringKey="PHOTO_TOOL__REPORT__BULK_SUCCESS__TEXT"
                                            valueMap={{count: photos.length}}
                                        />
                                    ) : (
                                        <Locale stringKey="PHOTO_TOOL__REPORT__SUCCESS__TEXT" />
                                    ),
                                onOk: () => {
                                    setIsReportModalOpen(false);

                                    if (onSuccess) {
                                        onSuccess();
                                    }
                                },
                            });

                            track(AnalyticsTarget.PhotoTool.CreateReport, [
                                ...new Set(photos.map((photo) => photo.source.name)),
                            ]);
                        })
                        .finally(() => setIsInProgress(false))
                        .catch((error: Error): void => {
                            modal.error({
                                title: <Locale stringKey="NOTIFICATION__TEXT__SERVER_ERROR" />,
                                content: error.message,
                            });
                        });
                }
            },
            [onReport, modal, photos, onSuccess]
        ),
        FeaturesEnum.photoTool
    );

    const controls = useMemo(() => {
        if (renderControl) {
            return renderControl(onShowModal, handleRemove);
        }

        if (
            photosWithReportOrRemoveAllowed.length > 0 &&
            photosWithReportOrRemoveAllowed.every((photo) => getIsRemoveAllowed(photo))
        ) {
            return (
                <Button danger onClick={handleRemove}>
                    <Locale stringKey={photos.length > 1 ? 'BUTTON__DELETE_SELECTED' : 'BUTTON__DELETE'} />
                </Button>
            );
        }

        if (
            photosWithReportOrRemoveAllowed.every(
                (photo) =>
                    photo.status === PhotoStatusKeyEnum.Removed || photo.status === PhotoStatusKeyEnum.RemoveRequest
            )
        ) {
            return (
                <Button danger disabled={photosWithReportOrRemoveAllowed.length === 0}>
                    <Locale stringKey="BUTTON__DELETE" />
                </Button>
            );
        }

        return (
            <Button danger disabled={photosWithReportOrRemoveAllowed.length === 0} onClick={onShowModal}>
                <Locale stringKey="TEXT__REPORT" />
            </Button>
        );
    }, [handleRemove, onShowModal, photos.length, photosWithReportOrRemoveAllowed, renderControl]);

    return (
        <>
            {controls}

            <Modal
                cancelText={<Locale stringKey="POPUP__BUTTON__CANCEL" />}
                confirmLoading={isInProgress}
                destroyOnClose
                okButtonProps={{
                    disabled: !okButtonAvailable,
                    form: reportFormId,
                    htmlType: 'submit',
                }}
                okText={<Locale stringKey="POPUP__BUTTON__SEND" />}
                onCancel={onCancel}
                open={isReportModalOpen}
                title={<Locale stringKey="TEXT__REPORT" />}
            >
                {photos.length > 1 && (
                    <div className={styles.PhotoReportModal_header}>
                        <Text>
                            <Locale
                                stringKey="PHOTO_TOOL__SELECT__MODAL__COUNT__TITLE"
                                valueMap={{count: photos.length}}
                            />
                        </Text>
                    </div>
                )}

                <Form<ReportFormType>
                    form={form}
                    id={reportFormId}
                    initialValues={INITIAL_REPORT_PHOTOS_VALUES}
                    onFinish={onSubmit}
                >
                    <Form.Item name={ReportFormFieldEnum.type} noStyle={type !== PhotoReportTypeEnum.Other}>
                        <Radio.Group className={styles.PhotoReportModal_reportType}>
                            {ALLOWED_PHOTO_REPORT_TYPES.map((reportType) => (
                                <Radio
                                    className={classNames(styles.PhotoReportModal_reportTypeItem)}
                                    key={reportType}
                                    value={reportType}
                                >
                                    <div className={styles.PhotoReportModal_reportTypeInfo}>
                                        <div className={styles.PhotoReportModal_reportTypeIcon}>
                                            <FontAwesomeIcon icon={PHOTO_REPORT_TYPE_ICON_MAP[reportType]} />
                                        </div>
                                        <Text block>
                                            <Locale stringKey={PHOTO_REPORT_TYPE_LOCALIZATION_MAP[reportType]} />
                                        </Text>
                                    </div>
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    {type === PhotoReportTypeEnum.Other && (
                        <Form.Item name={ReportFormFieldEnum.message}>
                            <TextArea
                                allowClear
                                maxLength={500}
                                placeholder={getLocalizedString('PHOTO_TOOL__REPORT_FORM__INPUT__MESSAGE__PLACEHOLDER')}
                                ref={(textArea) => textArea?.focus()}
                                rows={5}
                                showCount
                            />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </>
    );
}
