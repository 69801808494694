export const enActivationPageTranslations = {
    ACTIVATION_PAGE__THE_SERVICE_IS_INACTIVE: 'The service is inactive',
    ACTIVATION_PAGE__EXPAND_THE_CAPABILITIES_OF_THE_ACCOUNT: 'Expand the capabilities of the {companyName} Account',
    ACTIVATION_PAGE__INTERESTED_CONTACT_YOUR_MANAGER_TO_ACTIVATE_THIS_SERVICE:
        'Interested? Contact your manager{break}to activate this service.',
    ACTIVATION_PAGE__INTERESTED_CONTACT_YOUR_MANAGER_TO_ACTIVATE_THIS_SERVICE__DUBS_AND_FAKES:
        'Interested? Contact your manager to activate this service.{break}After activating the service, this functionality will not be displayed in your personal account.{break}Search and removal of dubs and fakes is performed by the technical department.',
    ACTIVATION_PAGE__LEARN_MORE: 'Learn more',
    ACTIVATION_PAGE__TARIFF_DOES_NOT_SUPPORT_THIS_FEATURE:
        'This feature is not supported by your tariff.{break}Contact your manager to activate this service.',
};
