import {faHeart, faMessage, faShare} from '@fortawesome/pro-regular-svg-icons';
import {faEye} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import * as styles from './vkontakte-preview-footer.scss';

export function VkontaktePreviewFooter(): JSX.Element {
    return (
        <div className={styles.VkontaktePreviewFooter}>
            <div className={styles.VkontaktePreviewFooter_actions}>
                <FontAwesomeIcon className={styles.VkontaktePreviewFooter_action} icon={faHeart} />
                <FontAwesomeIcon className={styles.VkontaktePreviewFooter_action} icon={faMessage} />
                <FontAwesomeIcon className={styles.VkontaktePreviewFooter_action} icon={faShare} />
            </div>
            <FontAwesomeIcon className={styles.VkontaktePreviewFooter_view} icon={faEye} />
        </div>
    );
}
