import {ConfigProvider, Menu} from 'antd';
import {ItemType} from 'antd/es/menu/interface';
import {omit} from 'lodash';
import {MenuInfo} from 'rc-menu/lib/interface';
import {useContext, useState} from 'react';

import {OnboardingContext} from '../../provider/onboarding/onboarding-context';
import {useSystem} from '../../provider/system/system-hook';
import {UiContext} from '../../provider/ui/ui-context';
import {UiContextType} from '../../provider/ui/ui-context-type';
import {classNames} from '../../util/css';
import {useUrl} from '../../util/url-hook/url-hook';
import {useMenuNavigation} from '../app/routing/hooks/use-app-navigation';
import {LANGUAGE_MENU_KEY} from '../app/routing/routing-const';

import {MobileLanguageSelector} from './mobile-language-selector/mobile-language-selector';
import {getDefaultOpenKeys, getDefaultSelectedKeys, makeHardReload} from './navigation-helper';
import {navigationDataLangKey} from './navigation-type';
import {OnboardingButton} from './onboarding-button/onboarding-button';
import * as styles from './navigation.scss';

type PropsType = {
    useHardReload?: boolean;
    isHeaderShown: boolean;
};

export function Navigation(props: PropsType): JSX.Element {
    const {useHardReload, isHeaderShown} = props;

    const [isMobileLanguageSelectorOpen, setIsMobileLanguageSelectorOpen] = useState(false);

    const {isLeftSideOpen, isMobileNavigationOpen, setIsMobileNavigationOpen} = useContext<UiContextType>(UiContext);
    const {screen} = useSystem();
    const {pathname} = useUrl();
    const menuItems = useMenuNavigation();
    const {showOnboarding} = useContext(OnboardingContext);

    const {isDesktop, isMobile} = screen;
    const isInlineCollapsed = isDesktop && !isLeftSideOpen;
    const defaultOpenKeys = isLeftSideOpen ? getDefaultOpenKeys(pathname) : [];
    const defaultSelectedKeys = getDefaultSelectedKeys(menuItems, pathname);

    function onClickMenu(event: MenuInfo): void {
        if (event.key !== LANGUAGE_MENU_KEY) {
            setIsMobileNavigationOpen(!isMobileNavigationOpen);
        }

        if (useHardReload) {
            makeHardReload();
        }
    }

    const wrapperClassName = classNames(styles.navigation, {
        [styles.navigation__close]: !isLeftSideOpen,
        [styles.navigation__mobile]: !isDesktop,
        [styles.navigation__without_header]: !isHeaderShown,
    });

    const menuClassName = classNames(styles.navigation_menu, {
        [styles.navigation_menu_with_onboarding]: showOnboarding,
    });

    return (
        <div aria-label="navigation" className={wrapperClassName}>
            <ConfigProvider theme={{components: {Menu: {activeBarBorderWidth: 0}}}}>
                <OnboardingButton inlineCollapsed={isInlineCollapsed} />
                <Menu
                    className={menuClassName}
                    defaultOpenKeys={defaultOpenKeys}
                    defaultSelectedKeys={defaultSelectedKeys}
                    inlineCollapsed={isInlineCollapsed}
                    items={menuItems.map((item) => omit(item, navigationDataLangKey)) as Array<ItemType>}
                    mode="inline"
                    onClick={onClickMenu}
                />
                {isMobile && (
                    <MobileLanguageSelector
                        onClose={() => setIsMobileLanguageSelectorOpen(false)}
                        open={isMobileLanguageSelectorOpen}
                    />
                )}
            </ConfigProvider>
        </div>
    );
}
