import {Button, Col, Input, Row, Select} from 'antd';
import {useCallback, useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Text} from '../../../../component/text/text';
import {useActionRequiresLicense} from '../../../../provider/license/license-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useModal} from '../../../../provider/modal/modal-hook';
import {useSystem} from '../../../../provider/system/system-hook';
import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {LocalPackPointDistanceEnum, LocalPackSquareSizeEnum} from '../../../../service/local-pack/local-pack-type';
import {
    useLocalPackBrands,
    useLocalPackSearchServices,
} from '../../../../service/local-pack/rules/local-pack-rule-hook';
import {
    LocalPackRuleFormFieldsEnum,
    LocalPackRuleFormType,
    LocalPackRuleFrequencyEnum,
} from '../../../../service/local-pack/rules/local-pack-rule-list-item-type';
import {FeaturesEnum} from '../../../../service/user/user-type';
import {Form} from '../../../../typings/antd';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {LOCALPACK_FREQUENCY_LOCALIZATION_MAP} from '../local-pack-const';

import {LocalPackFormMap} from './local-pack-form-map/local-pack-form-map';
import {getLocalPackRuleFormLangKeys} from './local-pack-rule-helper';
import {SelectBrandField} from './select-brand/select-brand-field';
import {SelectServicesField} from './select-services/select-services-field';
import * as styles from './local-pack-rule-form.scss';

type PropsType = {
    mode: 'create' | 'edit';
    initialValues: LocalPackRuleFormType;
    onFinish: (formValue: LocalPackRuleFormType) => Promise<void>;
};

export function LocalPackRuleForm(props: PropsType): JSX.Element {
    const {mode, initialValues, onFinish} = props;
    const {requiredFieldRule, maxLengthFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();
    const systemContext = useSystem();
    const {screen} = systemContext;
    const {isDesktop} = screen;
    const {pushUrl} = useUrl();
    const {modal} = useModal();

    const [isSavingForm, setIsSavingForm] = useState<boolean>(false);
    const [form] = Form.useForm<LocalPackRuleFormType>();

    const squareSize = Form.useWatch(LocalPackRuleFormFieldsEnum.SquareSize, {
        form,
        preserve: true,
    });

    const pointDistance = Form.useWatch(LocalPackRuleFormFieldsEnum.PointDistance, {
        form,
        preserve: true,
    });

    const {data: availableServices, isLoading: isLoadingAvailableServices} = useLocalPackSearchServices();
    const {data: availableBrandsResult, isLoading: isLoadingBrands} = useLocalPackBrands();

    const {submitLangKey, footerInfoLangKey, cancelModalTitleLangKey} = getLocalPackRuleFormLangKeys(mode);

    const isCreateMode = mode === 'create';
    const isEditMode = mode === 'edit';

    const handleSubmit = useActionRequiresLicense(async (values: LocalPackRuleFormType) => {
        setIsSavingForm(true);
        await onFinish(values);
        setIsSavingForm(false);

        track(AnalyticsTarget.LocalPack.CreateRule);
    }, FeaturesEnum.localPack);

    const handleCancel = useCallback(async () => {
        await modal.confirm({
            okButtonProps: {
                danger: true,
                type: 'default',
            },
            title: <Locale stringKey={cancelModalTitleLangKey} />,
            content: <Locale stringKey="LOCAL_PACK__RULE_FORM__CANCEL__CONTENT" />,
            okText: <Locale stringKey="POPUP__BUTTON__CONFIRM" />,
            cancelText: <Locale stringKey="BUTTON__CANCEL" />,
            onOk: () => {
                pushUrl(appRoute.localPackRules.path);
            },
        });
    }, [cancelModalTitleLangKey, modal, pushUrl]);

    return (
        <Form<LocalPackRuleFormType>
            disabled={isSavingForm}
            form={form}
            initialValues={initialValues}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Row gutter={isDesktop ? [24, 24] : [0, 0]}>
                <Col xs={isDesktop ? 12 : 24}>
                    <Form.Item<LocalPackRuleFormType>
                        label={getLocalizedString('LOCAL_PACK__RULE_FORM__NAME__LABEL')}
                        name={LocalPackRuleFormFieldsEnum.Name}
                        rules={[maxLengthFieldRule(100), requiredFieldRule]}
                    >
                        <Input placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__NAME__PLACEHOLDER')} />
                    </Form.Item>

                    <Form.Item<LocalPackRuleFormType>
                        label={getLocalizedString('LOCAL_PACK__RULE_FORM__BRAND__LABEL')}
                        name={LocalPackRuleFormFieldsEnum.Brand}
                        rules={[requiredFieldRule]}
                    >
                        <SelectBrandField
                            availableBrands={availableBrandsResult?.availableBrands}
                            disabled={isEditMode}
                            loading={isLoadingBrands}
                            placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__BRAND__PLACEHOLDER')}
                            singleItemAutoselect={isCreateMode}
                        />
                    </Form.Item>

                    <Form.Item<LocalPackRuleFormType>
                        label={getLocalizedString('LOCAL_PACK__RULE_FORM__SERVICES__LABEL')}
                        name={LocalPackRuleFormFieldsEnum.Services}
                        rules={[requiredFieldRule]}
                    >
                        <SelectServicesField
                            availableCatalogs={availableServices}
                            loading={isLoadingAvailableServices}
                            placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__SERVICES__PLACEHOLDER')}
                        />
                    </Form.Item>

                    <Form.Item<LocalPackRuleFormType>
                        label={getLocalizedString('LOCAL_PACK__RULE_FORM__SEARCH_PHRASES__LABEL')}
                        name={LocalPackRuleFormFieldsEnum.SearchPhrases}
                        rules={[requiredFieldRule]}
                    >
                        <Select
                            disabled={isEditMode}
                            mode="tags"
                            open={false}
                            placeholder={getLocalizedString('LOCAL_PACK__RULE_FORM__SEARCH_PHRASES__PLACEHOLDER')}
                        />
                    </Form.Item>

                    <Form.Item<LocalPackRuleFormType>
                        label={getLocalizedString('LOCAL_PACK__RULE_FORM__FREQUENCY__LABEL')}
                        name={LocalPackRuleFormFieldsEnum.Frequency}
                        rules={[requiredFieldRule]}
                    >
                        <Select<LocalPackRuleFrequencyEnum>
                            options={Object.values(LocalPackRuleFrequencyEnum).map(
                                (frequencyOption) =>
                                    ({
                                        label: getLocalizedString(
                                            LOCALPACK_FREQUENCY_LOCALIZATION_MAP[frequencyOption]
                                        ),
                                        value: frequencyOption,
                                    } || [])
                            )}
                        />
                    </Form.Item>

                    <Form.Item<LocalPackRuleFormType>
                        label={
                            <div>
                                <Text stringKey="LOCAL_PACK__RULE_FORM__SQUARE_SIZE__LABEL" />{' '}
                                <Text secondary stringKey="LOCAL_PACK__RULE_FORM__ONLY_FOR_CARD_AND_MOBILE_DEVICES" />
                            </div>
                        }
                        name={LocalPackRuleFormFieldsEnum.SquareSize}
                        rules={[requiredFieldRule]}
                    >
                        <Select<LocalPackSquareSizeEnum>
                            options={Object.values(LocalPackSquareSizeEnum).map(
                                (squareSizeOptions) => ({label: squareSizeOptions, value: squareSizeOptions} || [])
                            )}
                        />
                    </Form.Item>

                    <Form.Item<LocalPackRuleFormType>
                        label={
                            <div>
                                <Text stringKey="LOCAL_PACK__RULE_FORM__POINT_DISTANCE__LABEL" />{' '}
                                <Text secondary stringKey="LOCAL_PACK__RULE_FORM__ONLY_FOR_CARD_AND_MOBILE_DEVICES" />
                            </div>
                        }
                        name={LocalPackRuleFormFieldsEnum.PointDistance}
                        rules={[requiredFieldRule]}
                    >
                        <Select<LocalPackPointDistanceEnum>
                            options={Object.values(LocalPackPointDistanceEnum)
                                .filter(Number.isInteger)
                                .map(
                                    (pointDistanceOption) =>
                                        ({
                                            label: getLocalizedString('LOCAL_PACK__DISTANCE', {
                                                distance: String(pointDistanceOption),
                                            }),
                                            value: pointDistanceOption,
                                        } || [])
                                )}
                        />
                    </Form.Item>
                </Col>
                <Col hidden={!isDesktop} xs={12}>
                    <LocalPackFormMap pointsDistance={pointDistance} squareSize={squareSize} />
                </Col>
                <Col className={styles.LocalPackRuleFormFooter} span={24}>
                    <Button htmlType="submit" loading={isSavingForm} type="primary">
                        <Locale stringKey={submitLangKey} />
                    </Button>
                    <Button onClick={handleCancel}>
                        <Locale stringKey="BUTTON__CANCEL" />
                    </Button>
                    <Text secondary>
                        <Locale stringKey={footerInfoLangKey} />
                    </Text>
                </Col>
            </Row>
        </Form>
    );
}
