import {fetchAndDeserialize, serializeToURLParameters} from '../../../util/api-adapter';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {objectToUrlParameters} from '../../../util/url';
import {PaginatedResponseType} from '../../api/api-type';

import {ReviewCityListQueryType, reviewCityListResponse, ReviewCityType} from './review-cities-type';

export async function fetchReviewCityList(
    options: ReviewCityListQueryType & PaginationDataType,
    mainFilterKey: string
): Promise<PaginatedResponseType<ReviewCityType>> {
    const searchParameters = objectToUrlParameters({
        ...serializeToURLParameters<ReviewCityListQueryType>(options),
        filter_key: mainFilterKey,
    });

    const url = `/v2/reviews-analytics/stats/cities/?${searchParameters}`;

    return fetchAndDeserialize<PaginatedResponseType<ReviewCityType>>(url, reviewCityListResponse);
}
