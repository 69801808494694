import {Button} from 'antd';

import {useNewAccount} from '../../util/new-account-hook/new-account-hook';

import * as styles from './account-card.scss';

type PropsType = {
    linkingSrc: string;
    onClose: () => void;
    buttonContent: JSX.Element;
    catalogName?: string;
};

export function AddAccountCard(props: PropsType): JSX.Element {
    const {linkingSrc, onClose, buttonContent, catalogName} = props;

    const {addAccount} = useNewAccount({linkingSrc, catalogName, onAccountAdd: onClose});

    return (
        <div className={styles.account_card__default}>
            <div className={styles.account_card__content_wrapper}>
                <Button className={styles.account_card__plus} onClick={addAccount} type="text">
                    {buttonContent}
                </Button>
            </div>
        </div>
    );
}
