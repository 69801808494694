import {useState} from 'react';

import {appRoute} from '../../../app-route';
import {Questionnaire} from '../../../component/questionnaire/questionnaire';
import {Page} from '../../../layout/page/page';
import {Spinner} from '../../../layout/spinner/spinner';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {
    fetchQuestionnaireSave,
    QUIZ_STORAGE_KEY,
    useQuestionnaireOptions,
} from '../../../service/user/initial-questionnaire';
import {useUrl} from '../../../util/url-hook/url-hook';

export function InitialQuestionnaire(): JSX.Element {
    const {pushUrl} = useUrl();
    const {data, isFetching} = useQuestionnaireOptions();
    const {snackbar} = useSnackbar();
    const {getLocalizedString} = useLocale();
    const [isLoading, setIsLoading] = useState(false);

    async function saveQuestionnaire(id: string, value: string, isLastStep?: boolean) {
        try {
            setIsLoading(true);

            await fetchQuestionnaireSave({
                questionId: Number(id),
                text: value,
            });

            if (isLastStep) {
                localStorage.setItem(QUIZ_STORAGE_KEY, '1');
                pushUrl(appRoute.root.path);
            }
        } catch (error) {
            console.error(error);

            snackbar.error({
                message: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG" />,
                description: <Locale stringKey="ERROR__SOMETHING_WENT_WRONG_DETAILS" />,
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Page.CenteredCard titleLangKey="PAGE__LOGIN__LOGIN_FORM__HEADER">
            {isFetching && <Spinner position="absolute" />}

            {!isFetching && data && (
                <Questionnaire
                    isLoading={isLoading}
                    onSubmit={saveQuestionnaire}
                    pages={data.map((page) => {
                        const translatedStrings = page.translatedText?.split('\\n');

                        return {
                            title: translatedStrings?.[0] || '',
                            subtitle: translatedStrings?.[1],
                            multipleChoise: page.multichoice,
                            fieldName: String(page.id),
                            options: page.translatedAnswersOptions?.map((option) => ({
                                label: option,
                                value: option,
                            })),
                            inputPlaceholder: getLocalizedString('INPUT__PLACEHOLDER'),
                        };
                    })}
                />
            )}
        </Page.CenteredCard>
    );
}
