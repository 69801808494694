import {Tooltip} from 'antd';
import {upperFirst} from 'lodash';

import {useLocale} from '../../../provider/locale/locale-hook';
import {LanguageCodeType} from '../../../service/language/language-const';
import {useLanguages} from '../../../service/language/language-hook';

import {Flag} from './flag';
import * as styles from './flag.scss';

type PropsType = {
    value?: LanguageCodeType;
    className?: string;
};

export function FlagWithTooltip({value, className}: PropsType): JSX.Element | null {
    const {shortLocaleName} = useLocale();
    const {result} = useLanguages();

    const displayName = Intl.DisplayNames ? new Intl.DisplayNames(shortLocaleName, {type: 'language'}) : null;

    if (!value) {
        return null;
    }

    function getTitle() {
        const nativeLanguageName = result?.find(({alpha2}) => value === alpha2)?.name;
        const localizedLanguageName = displayName?.of(value ?? '');

        if (!nativeLanguageName && !localizedLanguageName) {
            return undefined; // eslint-disable-line unicorn/no-useless-undefined, no-undefined
        }

        if (!nativeLanguageName || !localizedLanguageName) {
            return localizedLanguageName || nativeLanguageName;
        }

        return `${localizedLanguageName} (${nativeLanguageName})`;
    }

    return (
        <Tooltip className={styles.Flag_withTooltip} placement="bottom" title={upperFirst(getTitle())}>
            {/* for some reason tooltip doesn't work without any wrapper around the other component */}
            <span>
                <Flag className={className} isLanguageCode value={value} />
            </span>
        </Tooltip>
    );
}
