import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchSourceSettingsAccess} from './access-api';
import {SourceSettingsAccessType} from './access-type';

export function useSourceSettingsAccess(): UseHookType<SourceSettingsAccessType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<SourceSettingsAccessType>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchSourceSettingsAccess()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setResult, setIsInProgress, setProcessError]);

    return {isInProgress, processError, result, reset};
}
