import {Button} from 'antd';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {AlertFallback} from '../../../../../component/alert-fallback/alert-fallback';
import {Text} from '../../../../../component/text/text';
import {Page} from '../../../../../layout/page/page';
import {Spinner} from '../../../../../layout/spinner/spinner';
import {Locale} from '../../../../../provider/locale/localization';
import {useSnackbar} from '../../../../../provider/snackbar/snackbar-hook';
import {useCompany} from '../../../../../service/company-v2/company-hook';
import {useBuyTariffLicense} from '../../../../../service/tariffs/tariffs-buy-license';
import {useLicensesDescription} from '../../../../../service/tariffs/tariffs-licenses-description';
import {HttpCodeEnum} from '../../../../../util/fetch-const';
import {useUrl} from '../../../../../util/url-hook/url-hook';
import * as styles from '../../../reviews/review-list-page-content/neuralink-alert/neuralink-alert.scss';
import {LicensesCodeEnum} from '../../../tariffs-and-limits/licenses-block/license/license-const';
import {TariffModalWrapper} from '../../../tariffs-and-limits/tariffs-modals/tariffs-modal-wrapper/tariff-modal-wrapper';
import {EditCompanyForm} from '../../page-container/edit/edit-company-form';

import {NoAccessToCompany} from './no-access-to-company/no-access-to-company';

export function EditCompanyPageGuard(): JSX.Element {
    const {companyId} = useParams<{companyId: string}>();
    const {getQuery} = useUrl();
    const {snackbar} = useSnackbar();
    const [isOpenLicenseModal, setIsOpenLicenseModal] = useState<boolean>(false);
    const {data} = useLicensesDescription();
    const {mutate} = useBuyTariffLicense();

    const licenseName = data?.find((license) => license.licenseCode === LicensesCodeEnum.CompanyLicence)?.name;

    function handleBuyLicense(message: string) {
        mutate(
            {message, licenseCode: LicensesCodeEnum.CompanyLicence},
            {
                onSuccess: () => {
                    setIsOpenLicenseModal(false);
                },
            }
        );
    }

    if (!companyId) {
        throw new Error('In "edit" mode "companyId" always should be specified');
    }

    useEffect(() => {
        if (getQuery('isLicenceExpired') === 'true') {
            snackbar.error({
                message: <Locale stringKey="COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__MESSAGE" />,
                description: <Locale stringKey="COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__DESCRIPTION" />,
                btn: (
                    <Button onClick={() => setIsOpenLicenseModal(true)} size="small" type="primary">
                        <Locale stringKey="COMPANY_FORM__LICENSE_BALANCE_SNACKBAR__BUTTON" />
                    </Button>
                ),
                duration: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {isInProgress, result, processError, fetchCompanyErrorCode} = useCompany(Number(companyId));

    if (isInProgress || (!result && !processError)) {
        return (
            <Page>
                <Spinner position="fixed" />
            </Page>
        );
    }

    if (fetchCompanyErrorCode === HttpCodeEnum.Forbidden) {
        return <NoAccessToCompany />;
    }

    if (processError || !result) {
        return (
            <Page>
                <AlertFallback />
            </Page>
        );
    }

    return (
        <>
            <EditCompanyForm company={result} companyId={Number(companyId)} />
            <TariffModalWrapper
                buttonTitle="BUTTON__SEND_APPLICATION"
                isOpen={isOpenLicenseModal}
                onOk={handleBuyLicense}
                setIsOpen={setIsOpenLicenseModal}
                title="TARIFFS__MODAL__TITLE__LIMITS"
            >
                <div className={styles.NeuralAlert_cardWrapper}>
                    <Text bolder>{licenseName}</Text>
                </div>
            </TariffModalWrapper>
        </>
    );
}
