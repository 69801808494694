import {Tag} from 'antd';

import {Locale} from '../../../../../provider/locale/localization';
import {ProductGroupListItemType, ProductGroupStatusEnum} from '../../../../../service/products/products-type';
import {NeverError} from '../../../../../util/error';

import * as styles from './product-groups-table.scss';

type PropsType = {
    productGroup: ProductGroupListItemType;
};

export function ProductGroupStatus(props: PropsType): JSX.Element {
    const {productGroup} = props;
    const {hasCatalogValidationErrors, status} = productGroup;

    if (hasCatalogValidationErrors) {
        return (
            <Tag className={styles.ProductGroupsTable_tag__orange}>
                <Locale stringKey="PRODUCTS__GROUPS__STATUS__HAS_ERRORS" />
            </Tag>
        );
    }

    switch (status) {
        case ProductGroupStatusEnum.New:
            return (
                <Tag className={styles.ProductGroupsTable_tag__new}>
                    <Locale stringKey="PRODUCTS__GROUPS__STATUS__NEW" />
                </Tag>
            );
        case ProductGroupStatusEnum.Processing:
            return (
                <Tag className={styles.ProductGroupsTable_tag__processing}>
                    <Locale stringKey="PRODUCTS__GROUPS__STATUS__PROCESSING" />
                </Tag>
            );
        case ProductGroupStatusEnum.Failed:
            return (
                <Tag className={styles.ProductGroupsTable_tag__error}>
                    <Locale stringKey="PRODUCTS__GROUPS__STATUS__ERROR" />
                </Tag>
            );
        case ProductGroupStatusEnum.Ok:
            return (
                <Tag className={styles.ProductGroupsTable_tag__success}>
                    <Locale stringKey="PRODUCTS__GROUPS__STATUS__FINISHED" />
                </Tag>
            );
        case ProductGroupStatusEnum.UploadError:
            return (
                <Tag className={styles.ProductGroupsTable_tag__error}>
                    <Locale stringKey="PRODUCTS__GROUPS__STATUS__UPLOAD_ERROR" />
                </Tag>
            );
        default:
            throw new NeverError(status);
    }
}
