import {Form, Input} from 'antd';
import {NamePath} from 'antd/lib/form/interface';

import {Locale} from '../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostFormItemPropsType} from '../../../../post-form-type';

type PropsType = {
    fieldName: NamePath;
    required: boolean;
} & PostFormItemPropsType;

export function ButtonLinkItem(props: PropsType): JSX.Element {
    const {fieldName, required, disabled} = props;

    const {validUrlRule, requiredFieldRule} = useFormRules();
    const {getLocalizedString} = useLocale();

    return (
        <Form.Item
            initialValue=""
            label={<Locale stringKey="POSTS_FORM__BUTTON_LINK__LABEL" />}
            name={fieldName}
            required={required}
            rules={!disabled ? [validUrlRule, ...(required ? [requiredFieldRule] : [])] : []}
        >
            <Input disabled={disabled} placeholder={getLocalizedString('POSTS_FORM__BUTTON_LINK__PLACEHOLDER')} />
        </Form.Item>
    );
}
