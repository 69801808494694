import {Text} from '../../../../../../../component/text/text';
import {Locale} from '../../../../../../../provider/locale/localization';
import {FakesResultType} from '../../../../../../../service/fakes/fakes-results';
import {FakeClientStatusEnum, FakeInternalStatusEnum} from '../../../../../../../service/fakes/fakes-type';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {fakeRejectReasonLabels} from '../../../../popups/fakes-popup-const';

import * as styles from './fakes-result-column.scss';

type PropsType = {
    fake: FakesResultType | {catalog: ProvidersIdsEnum};
};

export function FakesResultColumn(props: PropsType): JSX.Element | null {
    const {fake} = props;

    if ('catalog' in fake) {
        return null;
    }

    if (fake.clientStatus === FakeClientStatusEnum.Hidden) {
        return (
            <>
                <Text
                    block
                    className={styles.FakesResultColumn_hidden}
                    lighter
                    stringKey="FAKES__REPORT__HIDDEN__LOCATION_HIDDEN"
                />

                <Text secondary>
                    <span>&quot;</span>
                    <Locale
                        stringKey={
                            fake.internalStatus === FakeInternalStatusEnum.ClientLocation
                                ? 'FAKES__RESULTS__BUTTON__MY_LOCATION'
                                : 'FAKES__RESULTS__BUTTON__NOT_MY_LOCATION'
                        }
                    />
                    <span>&quot;</span>
                </Text>
            </>
        );
    }

    if (fake.clientStatus === FakeClientStatusEnum.ClaimRejected) {
        return (
            <>
                <Text
                    block
                    className={styles.FakesResultColumn_rejectReason}
                    stringKey="FAKES__REPORT__REJECTED__CLAIM_REJECTED"
                />

                {fake.rejectReason && fakeRejectReasonLabels[fake.rejectReason] && (
                    <Text secondary stringKey={fakeRejectReasonLabels[fake.rejectReason]} />
                )}
            </>
        );
    }

    if (fake.clientStatus === FakeClientStatusEnum.ClaimApproved) {
        return (
            <Text className={styles.FakesResultColumn_claimApproved} stringKey="FAKES__REPORT__FAKES__CLAIM_APPROVED" />
        );
    }

    return null;
}
