import {getEnumValueEnsure} from '../../util/enum';

import {
    localeConst,
    localeNameToShortLocaleNameMap,
    SESSION_STORAGE_IS_AUTH_LANGUAGE_KEY,
    shortLocaleNameToLocaleNameMap,
} from './locale-context-const';
import {LocaleNameEnum, ShortLocaleNameEnum} from './locale-context-type';

export function getLanguageFromUrl(): string | null {
    return new URL(location.href).searchParams.get('lang');
}

export function getIsAuthLanguageFromSessionStorage(): string | null {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_IS_AUTH_LANGUAGE_KEY) as string);
}

export function setIsAuthLanguageFromSessionStorage(value: boolean | null): void {
    return sessionStorage.setItem(SESSION_STORAGE_IS_AUTH_LANGUAGE_KEY, JSON.stringify(value));
}

export function getSavedLocaleName(): LocaleNameEnum {
    const localeName: LocaleNameEnum = localeConst.defaults.localeName;

    if (typeof localStorage === 'undefined' || typeof navigator === 'undefined' || typeof location === 'undefined') {
        return localeName;
    }

    // try to get short locale name from url
    const urlShortLocaleName = getLanguageFromUrl();

    const locale = Object.values(ShortLocaleNameEnum).find((name) => name === urlShortLocaleName);

    if (locale) {
        return shortLocaleNameToLocaleNameMap[locale];
    }

    const localeNameList = Object.values(LocaleNameEnum);
    const savedLocaleName = localStorage.getItem(localeConst.key.localStorage.localeName);

    // eslint-disable-next-line no-loops/no-loops
    for (const localeNameInList of localeNameList) {
        if (localeNameInList === savedLocaleName) {
            return localeNameInList;
        }
    }

    const navigatorLanguages = navigator.languages;

    // eslint-disable-next-line no-loops/no-loops
    for (const deviceLocaleName of navigatorLanguages) {
        // eslint-disable-next-line no-loops/no-loops
        for (const localeNameInList of localeNameList) {
            if (deviceLocaleName === localeNameInList) {
                return localeNameInList;
            }
        }
    }

    return localeName;
}

export function saveLocaleName(localeName: LocaleNameEnum): LocaleNameEnum {
    // console.log('---> save localeName localStorage:', localeConst.key.localStorage.localeName, localeName);
    localStorage.setItem(localeConst.key.localStorage.localeName, localeName);
    document.documentElement.setAttribute('lang', localeNameToShortLocaleNameMap[localeName]);

    return localeName;
}

export function getShortLocaleName(localeName: LocaleNameEnum): ShortLocaleNameEnum {
    const mayBeShortLocaleName = localeName.split('-')[0];

    return getEnumValueEnsure<ShortLocaleNameEnum>(
        ShortLocaleNameEnum,
        mayBeShortLocaleName,
        localeConst.defaults.shortLocaleName
    );
}
