import {faStar as faEmptyStar} from '@fortawesome/pro-regular-svg-icons';
import {faStar as faFilledStar} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Tooltip} from 'antd';

import {useLocale} from '../../../../../provider/locale/locale-hook';
import {YandexStorySlideType} from '../../../../../service/yandex-stories/yandex-stories-type';
import {classNames} from '../../../../../util/css';

import * as styles from './yandex-story-confirmation-step.scss';

type PropsType = {
    slide: YandexStorySlideType;
    isSelected: boolean;
    onSelectAsCover: VoidFunction;
};

export function YandexConfirmationStepSlide(props: PropsType): JSX.Element {
    const {slide, isSelected, onSelectAsCover} = props;

    const {getLocalizedString} = useLocale();

    return (
        <div
            className={styles.YandexStoryConfirmationStep_slide}
            key={slide.order}
            style={{
                backgroundImage: `url("${slide.url}")`,
            }}
        >
            <Tooltip
                title={
                    !isSelected
                        ? getLocalizedString('YANDEX_STORY_FORM__CONFIRMATION_STEP__COVER__SELECT_FROM_SLIDE')
                        : ''
                }
            >
                <Button
                    className={classNames(styles.YandexStoryConfirmationStep_slideStar, {
                        [styles.YandexStoryConfirmationStep_slideStar__selected]: isSelected,
                    })}
                    icon={<FontAwesomeIcon icon={isSelected ? faFilledStar : faEmptyStar} />}
                    onClick={onSelectAsCover}
                />
            </Tooltip>
        </div>
    );
}
