import {useContext, useMemo} from 'react';
import {ExtractRouteParams, useParams} from 'react-router';

import {appRoute} from '../../../../../../app-route';
import {WidgetError} from '../../../../../../component/widget-error/widget-error';
import {DoughnutDataType} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart-type';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {MainFilterContext} from '../../../../../../provider/main-filter/main-filter';
import {MainFilterContextType} from '../../../../../../provider/main-filter/main-filter-type';
import {useReviewAnalyticsTagBySentiment} from '../../../../../../service/reivew-analysis/review-analytics-tags/review-analytics-tag-hook';
import {useReviewsAnalysisFilter} from '../../../reviews-analysis-hook';
import {TopicPieChart} from '../topic-pie-chart/topic-pie-chart';

import {TOP_KEYWORDS_COLORS} from './topic-distribution-chart-const';

export function TopicDistributionChart(): JSX.Element {
    const {singleFilter} = useReviewsAnalysisFilter();
    const {mainFilterKey} = useContext<MainFilterContextType>(MainFilterContext);
    const {id} = useParams<ExtractRouteParams<typeof appRoute.reviewsManagementReviewItem.path, string>>();

    const distributionOptions = useMemo(
        () => ({...singleFilter, userTopics: [id], maxTagsCount: 5}),
        [id, singleFilter]
    );

    const {result, isInProgress, processError} = useReviewAnalyticsTagBySentiment(distributionOptions, mainFilterKey, {
        initialPageSize: 200,
        shouldSaveState: false,
    });

    const {getLocalizedString} = useLocale();

    const pieChartData: Array<DoughnutDataType> = useMemo(() => {
        if (result?.results && result?.count) {
            return result.results.slice(0, 5).map((keyword, index) => ({
                label: keyword.tag,
                value: keyword.total,
                color: TOP_KEYWORDS_COLORS[index] || '',
            }));
        }

        return [];
    }, [result]);

    const topKeywordsSum = pieChartData.reduce<number>(
        (accum: number, keyword: DoughnutDataType): number => accum + keyword.value,
        0
    );

    if (processError) {
        return <WidgetError />;
    }

    return (
        <TopicPieChart
            caption={getLocalizedString('REVIEWS_ANALYSIS__TOPIC__TOP_FIVE_KEYWORDS')}
            data={pieChartData}
            isInProgress={isInProgress}
            total={topKeywordsSum}
        />
    );
}
