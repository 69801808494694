import {fetchAndDeserialize} from '../../../util/api-adapter';
import {fetchNoContent} from '../../../util/fetch';
import {PaginationDataType} from '../../../util/pagination-hook/pagination-hook-type';
import {rootApiUrl} from '../../api/api-const';
import {UserType} from '../../user/user-type';

import {
    getReviewsRatingsCompanyChartDataUrl,
    getReviewsRatingsDynamicsRequestExportUrl,
    getReviewsRatingsDynamicsUrl,
} from './reviews-analytics-ratings-dynamic-helper';
import {
    ratingDynamicsChartsDataSchema,
    RatingDynamicsChartsDataType,
    RatingDynamicsCompaniesStatsDataType,
    ratingDynamicsCompaniesStatsDataType,
    RatingsDynamicsFilterType,
} from './reviews-analytics-ratings-dynamic-type';

export function fetchReviewsRatingsCompaniesStats(
    filter: RatingsDynamicsFilterType,
    mainFilterKey: string,
    pagination: PaginationDataType
): Promise<RatingDynamicsCompaniesStatsDataType> {
    return fetchAndDeserialize(
        getReviewsRatingsDynamicsUrl(filter, mainFilterKey, pagination),
        ratingDynamicsCompaniesStatsDataType
    );
}

export function fetchRatingDynamicsChartsData(options: {
    companyId: string;
    filter: RatingsDynamicsFilterType;
    mainFilterKey: string;
    regionAvailableCatalogs: UserType['regionAvailableCatalogs'] | null;
}): Promise<RatingDynamicsChartsDataType> {
    return fetchAndDeserialize(getReviewsRatingsCompanyChartDataUrl(options), ratingDynamicsChartsDataSchema);
}

export function requestRatingDynamicsExport(
    filter: RatingsDynamicsFilterType,
    mainFilterKey: string,
    regionAvailableCatalogs: UserType['regionAvailableCatalogs'] | null
): Promise<void> {
    return fetchNoContent(
        `${rootApiUrl}${getReviewsRatingsDynamicsRequestExportUrl(filter, mainFilterKey, regionAvailableCatalogs)}`
    );
}
