import {Card} from 'antd';
import {useEffect, useMemo} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {useLocale} from '../../../../../../../provider/locale/locale-hook';
import {LocalePlural} from '../../../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../../../provider/locale/localization';
import {
    PostSelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../../../../../../service/feature-companies/feature-companies-type';
import {usePostSubmitInformation} from '../../../../../../../service/posts/posts-hooks';
import {
    PostFormFieldsEnum,
    PostFormType,
    PostSourceEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../typings/antd';
import {classNames} from '../../../../../../../util/css';
import {getEnumValue} from '../../../../../../../util/enum';
import {ProvidersEnum} from '../../../../../../../util/type';
import {SOURCES_WITH_COMPANY_SELECT} from '../../target/post-target-step-helper';

import {PostSummaryCatalog} from './post-summary-catalog';
import * as styles from './post-summary.scss';

type PropsType = {
    formInstance: FormInstance<PostFormType>;
    className?: string;
    companiesSelector: UseCompaniesSelectorHookType<PostSelectorCompanyType>;
    onSetIsDisabled: (value: boolean) => void;
};

export function PostSummary(props: PropsType): JSX.Element {
    const {formInstance, companiesSelector, className, onSetIsDisabled} = props;

    const {selectorId, selectedCount} = companiesSelector;

    const {getLocalizedString} = useLocale();

    const postName = formInstance.getFieldValue(PostFormFieldsEnum.Name);
    const fbPageIds = formInstance.getFieldValue(PostFormFieldsEnum.FbPagesIds);
    const vkGroupIds = formInstance.getFieldValue(PostFormFieldsEnum.VkGroupsIds);
    const instagramPagesIds = formInstance.getFieldValue(PostFormFieldsEnum.InstagramGroupsIds);
    const providers = useMemo(() => {
        return (formInstance.getFieldValue(PostFormFieldsEnum.Posts) || []).map(
            (post) => post[SourcePostFormFieldsEnum.Catalog]
        );
    }, [formInstance]);

    const submitInformationProviderCodes = useMemo(() => {
        return providers
            .filter((source) => SOURCES_WITH_COMPANY_SELECT.includes(source))
            .map((source) => getEnumValue(ProvidersEnum, source))
            .filter(Boolean);
    }, [providers]);

    const {data, isInitialLoading} = usePostSubmitInformation({
        selectorId,
        providerCodes: submitInformationProviderCodes,
    });

    useEffect(() => {
        if (isInitialLoading) {
            onSetIsDisabled(isInitialLoading);
        } else {
            onSetIsDisabled(
                Boolean(
                    fbPageIds.length === 0 &&
                        vkGroupIds.length === 0 &&
                        instagramPagesIds.length === 0 &&
                        data?.catalogStats.every((catalog) => catalog.locationsCount === 0)
                )
            );
        }
    }, [data, isInitialLoading]);

    return (
        <Card className={classNames(styles.PostSummary, className)}>
            <Text className={styles.PostSummary_title}>{postName}</Text>
            {Boolean(selectedCount) && (
                <div className={styles.PostSummary_companies}>
                    <Text>
                        <LocalePlural
                            count={selectedCount}
                            fewKey="POSTS_SUBMIT_INFORMATION__COMPANIES_COUNT_FEW"
                            manyKey="POSTS_SUBMIT_INFORMATION__COMPANIES_COUNT_MANY"
                            singularKey="POSTS_SUBMIT_INFORMATION__COMPANIES_COUNT_SINGULAR"
                            valueMap={{
                                count: selectedCount,
                            }}
                        />
                    </Text>
                </div>
            )}
            <div className={styles.PostSummary_catalogsWrapper}>
                <Text lighter>
                    <Locale stringKey="POSTS_SUBMIT_INFORMATION__PUBLICATION__TITLE" />
                </Text>
                <div className={styles.PostSummary_catalogs}>
                    {fbPageIds?.length > 0 && providers.includes(PostSourceEnum.facebook) && (
                        <PostSummaryCatalog
                            provider={ProvidersEnum.facebook}
                            selectedCompaniesCount={selectedCount}
                            showCompaniesCountWarning={false}
                            sourceDisplayName={getLocalizedString('POSTS_SUBMIT_INFORMATION__PUBLICATION__FB_PAGES')}
                            sourcePostsCount={fbPageIds.length}
                        />
                    )}

                    {vkGroupIds?.length > 0 && providers.includes(PostSourceEnum.vkontakte) && (
                        <PostSummaryCatalog
                            provider={ProvidersEnum.vkontakte}
                            selectedCompaniesCount={selectedCount}
                            showCompaniesCountWarning={false}
                            sourcePostsCount={vkGroupIds.length}
                        />
                    )}

                    {instagramPagesIds?.length > 0 && providers.includes(PostSourceEnum.instagram) && (
                        <PostSummaryCatalog
                            provider={ProvidersEnum.instagram}
                            selectedCompaniesCount={selectedCount}
                            showCompaniesCountWarning={false}
                            sourcePostsCount={instagramPagesIds.length}
                        />
                    )}

                    {Boolean(selectedCount) &&
                        data?.catalogStats.map((catalogStats) => (
                            <PostSummaryCatalog
                                key={catalogStats.catalog}
                                provider={catalogStats.catalog}
                                selectedCompaniesCount={selectedCount}
                                showCompaniesCountWarning={catalogStats.locationsCount !== selectedCount}
                                sourcePostsCount={catalogStats.locationsCount}
                            />
                        ))}
                </div>
            </div>
        </Card>
    );
}
