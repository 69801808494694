import dayjs from 'dayjs';

import {LocaleNameEnum} from '../../../provider/locale/locale-context-type';
import {rootApiUrl} from '../../../service/api/api-const';
import {CompanyShortType} from '../../../service/company/company-type';
import {objectToUrlParameters} from '../../../util/url';

const DATE_FORMAT = 'YYYY-MM-DD';

export function buildReportLink(companies: Array<CompanyShortType>, currentLocale: LocaleNameEnum): string {
    const baseUrl = rootApiUrl + '/reports/2gis/';

    const companyId = companies[0]?.id;

    const locale = {
        [LocaleNameEnum.enUs]: 'en-EN',
        [LocaleNameEnum.ruRu]: LocaleNameEnum.ruRu,
        [LocaleNameEnum.plPl]: LocaleNameEnum.plPl,
        [LocaleNameEnum.kkKz]: LocaleNameEnum.kkKz,
        [LocaleNameEnum.esEs]: LocaleNameEnum.esEs,
        [LocaleNameEnum.bgBg]: LocaleNameEnum.bgBg,
    }[currentLocale];

    const cclDate = dayjs().subtract(1, 'days').format(DATE_FORMAT);
    const reportDateFrom = dayjs().subtract(7, 'days').format(DATE_FORMAT);
    const reportDateTo = dayjs().format(DATE_FORMAT);
    const compareDateFrom = dayjs().subtract(7, 'days').subtract(1, 'months').format(DATE_FORMAT);
    const compareDateTo = dayjs().subtract(1, 'months').format(DATE_FORMAT);

    return (
        baseUrl +
        '?' +
        objectToUrlParameters({
            locale,
            'company-id': companyId,
            'ccl-date': cclDate,
            'report-date-from': reportDateFrom,
            'report-date-to': reportDateTo,
            'compare-date-from': compareDateFrom,
            'compare-date-to': compareDateTo,
        })
    );
}
