import {Text} from '../../../../../../component/text/text';

import * as styles from './photo-tool-landing-stats.scss';

type PropsType = {
    name: string;
    logo: JSX.Element;
    photosCount: number;
};

export function PhotoToolLandingStatsItem(props: PropsType): JSX.Element {
    const {name, logo, photosCount} = props;

    return (
        <div className={styles.PhotoToolLandingStats_catalogStatsItem}>
            {logo}
            <div className={styles.PhotoToolLandingStats_catalogStatsItemDataColumn}>
                <div>{photosCount}</div>
                <Text secondary>{name}</Text>
            </div>
        </div>
    );
}
