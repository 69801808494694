export function determineImageSource(url: string): string {
    const patterns: {[key: string]: string} = {
        '2gis': '2gis',
        'yandex': 'yandex',
        'google': 'googleusercontent',
    };

    const matchedSource = Object.entries(patterns).find(([, pattern]) => url.includes(pattern));

    return matchedSource ? matchedSource[0] : '';
}
