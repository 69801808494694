import {deserializeV2, fetchAndDeserialize, serialize, serializeToURLParameters} from '../../util/api-adapter';
import {FetchMethodEnum, fetchNoContent, fetchX} from '../../util/fetch';
import {ProvidersEnum} from '../../util/type';
import {objectToUrlParameters} from '../../util/url';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';
import {PaginatedResponseType} from '../api/api-type';

import {
    ConfirmationRequestType,
    confirmationsListSchema,
    ConfirmationSourcesMethodEnum,
    ConfirmationsSourcesType,
    ConfirmationSuggestsApiOptionsType,
    googleConfirmationMethodSchema,
    GoogleConfirmationMethodType,
    GoogleGetCodeOptionsType,
    GoogleVerificationMethodsApiOptionsType,
    googleVerificationMethodSchema,
    GoogleVerificationMethodType,
    yandexConfirmationSuggestSchema,
    YandexConfirmationSuggestType,
    YandexGetCodeRequestOptionsType,
} from './confirmation-type';

export async function loadYandexConfirmationSuggests({
    brandId,
    companyId,
}: ConfirmationSuggestsApiOptionsType): Promise<YandexConfirmationSuggestType> {
    const url = `${rootApiUrl}/confirmation/${ProvidersEnum.yandex}/suggest/${brandId}/${companyId}/`;

    const response: Record<string, unknown> = await fetchX<Record<string, unknown>>(url, {
        headers: mainApiHeaders,
    });

    return deserializeV2<YandexConfirmationSuggestType>(url, yandexConfirmationSuggestSchema, response);
}

export function googleConfirmationApi(options: GoogleGetCodeOptionsType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/confirmation/google/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify({
            brand: options.brand,
            catalog_type: 'company',
            company: options.company,
            method: options.method.toLowerCase(),
            catalog_id: options.catalogId,
            permanent_id: options.permanentId,
            provider: ProvidersEnum.google,
            value: options.value,
        }),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function yandexConfirmationApi(options: YandexGetCodeRequestOptionsType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/confirmation/yandex/`, {
        method: FetchMethodEnum.post,
        body: JSON.stringify({
            catalog_id: options.catalogId,
            brand: options.brand,
            catalog_type: options.catalogType,
            method: options.method === ConfirmationSourcesMethodEnum.Phone ? 'phone' : 'sms',
            extension_phone_code: options.extensionPhoneCode,
            contact_person: options.contactPerson,
            company: options.company,
            extra: options.extra ? serialize(options.extra) : options.extra,
            phone: options.phone,
        }),
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
    });
}

export function confirmCompany({code, hash, provider}: ConfirmationRequestType): Promise<void> {
    return fetchNoContent(`${rootApiUrl}/confirmation/${provider}/${hash}/`, {
        method: FetchMethodEnum.put,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify({
            code,
        }),
    });
}

export async function loadConfirmations(
    mainFilterKey?: string,
    parameters?: {
        count: number;
        page: number;
    }
): Promise<PaginatedResponseType<ConfirmationsSourcesType>> {
    const url = `/confirmation/?${objectToUrlParameters(
        serializeToURLParameters(mainFilterKey ? {filter_key: mainFilterKey, ...parameters} : {...parameters})
    )}`;

    return fetchAndDeserialize(url, confirmationsListSchema);
}

export async function loadGoogleConfirmationMethods(hash: string): Promise<Array<GoogleConfirmationMethodType>> {
    const url = `/confirmation/google/${hash}/verification_options/?try_auto_verify=true`;

    return fetchAndDeserialize<Array<GoogleConfirmationMethodType>>(url, googleConfirmationMethodSchema.array());
}

export async function loadGoogleVerificationMethods({
    companyId,
    brandId,
    placeId,
}: GoogleVerificationMethodsApiOptionsType): Promise<Array<GoogleVerificationMethodType>> {
    const url = `/confirmation/google/suggest/${brandId}/${companyId}/${placeId}/`;

    return fetchAndDeserialize<Array<GoogleVerificationMethodType>>(url, googleVerificationMethodSchema.array());
}
