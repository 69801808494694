import {Locale} from '../../../../../../../provider/locale/locale';
import {BulkEditFieldNameEnum, FieldModeEnum} from '../../../../../../../service/company/company-type';
import {InputString, InputStringPropsType} from '../../phones-form-item/input-string/input-string';
import {UrlInputStringType} from '../websites-form-item-type';

import {CustomInput} from './custom-input';

type PropsType = {
    label: JSX.Element;
    item: UrlInputStringType;
    itemList: Array<UrlInputStringType>;
    setItemList: (newItemList: Array<UrlInputStringType>) => void;
    inputClassName?: string;
    errorMessages: Array<string>;
    mode?: FieldModeEnum;
    tag?: BulkEditFieldNameEnum;
};

export function UrlInput(props: PropsType & Pick<InputStringPropsType, 'placeholder' | 'helpText'>): JSX.Element {
    const {item, itemList, setItemList, label, placeholder, inputClassName, errorMessages, helpText, mode, tag} = props;

    const index = itemList.indexOf(item);

    return (
        <InputString
            additionalInfo={{content: label}}
            className={inputClassName}
            errorMessages={errorMessages}
            hasError={errorMessages.length > 0}
            hasMainItem={mode === FieldModeEnum.replacement}
            helpText={index === 0 ? helpText : null}
            inputComponent={
                <CustomInput
                    item={item}
                    itemList={itemList}
                    placeholder={placeholder}
                    setItemList={setItemList}
                    tag={tag}
                />
            }
            item={item}
            itemList={itemList}
            key={item.id}
            renderRemovedItemText={(value) => <Locale stringKey="CONTACTS__INFO__REMOVED_LINK" valueMap={{value}} />}
            setItemList={setItemList}
            tag={tag}
        />
    );
}
