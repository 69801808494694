import {deburr} from 'lodash';

import {ShortLocaleNameEnum} from '../provider/locale/locale-context-type';

export function getHash(data: Record<string, unknown> | string | Array<unknown>): string {
    let result = 0;
    const fullString: string = typeof data === 'string' ? data : JSON.stringify(data, null, 0);
    const stringLength = fullString.length;

    // eslint-disable-next-line no-loops/no-loops
    for (let index = 0; index < stringLength; index += 1) {
        // eslint-disable-next-line unicorn/prefer-code-point
        result = Math.trunc(Math.imul(31, result) + fullString.charCodeAt(index));
    }

    return result.toString(32);
}

export function getRandomString(): string {
    return getHash(String(Date.now() + Math.random()));
}

export function findString(input: string, searchQuery: string, flags: '' | 'g' | 'gi' = 'gi'): Array<string> {
    const result: Array<string> = [];
    const searchQueryLength = searchQuery.length;

    const cleanedSearchQuery = searchQuery.replace(/([$()\\])/g, '\\$1');
    const splitRegExp = new RegExp(`(?=${cleanedSearchQuery})`, flags);
    const equalRegExp = new RegExp(`(^${cleanedSearchQuery})`, flags);

    const splitLeftList: Array<string> = input.split(splitRegExp);

    // eslint-disable-next-line no-loops/no-loops
    for (const leftSplitPart of splitLeftList) {
        if (equalRegExp.test(leftSplitPart)) {
            result.push(leftSplitPart.slice(0, searchQueryLength), leftSplitPart.slice(searchQueryLength));
        } else {
            result.push(leftSplitPart);
        }
    }

    return result;
}

export function sortCompare(shortLocaleName: ShortLocaleNameEnum, stringA: string, stringB: string): number {
    return new Intl.Collator(shortLocaleName).compare(stringA, stringB);
}

export function toTrimmedString(value: unknown): string {
    // eslint-disable-next-line no-undefined
    if (value === null || value === undefined) {
        return '';
    }

    return String(value).trim();
}

export function toTruncatedString(value: string, maxLength: number): string {
    if (value.length > maxLength) {
        return value.slice(0, maxLength) + '...';
    }

    return value;
}

export function getNumberOfImages(cleanedText: string): number {
    const imageTags = new DOMParser().parseFromString(cleanedText, 'text/html').querySelectorAll('img');

    return imageTags.length;
}

export function cleanImages(cleanedText: string): string | null {
    const temporaryDocument = new DOMParser().parseFromString(cleanedText, 'text/html');

    [...temporaryDocument.images].forEach((item) => {
        item.remove();
    });
    return temporaryDocument.documentElement.textContent;
}

export function splitCommaSeparatedString(text: string): Array<string> {
    return text.split(/\s*,\s*/gi).filter(Boolean);
}

function deburrAndLowerCase(text: string): string {
    return deburr(text.trim().toLocaleLowerCase());
}

export function includesDeburredString(haystack: string, needle: string): boolean {
    return deburrAndLowerCase(haystack).includes(deburrAndLowerCase(needle));
}
