import {DatePicker, DatePickerProps, Select, SelectProps, Tag} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {CustomTagProps} from 'rc-select/lib/BaseSelect';
import {ReactElement, useCallback, useMemo, useState} from 'react';

import {Locale} from '../../provider/locale/locale';

import * as styles from './multiple-date-picker.scss';

type MultipleDatePickerType = {
    selectedDate: Array<string>;
    format?: string;
    placeholder?: string | JSX.Element;
    onChange: (dates: Array<string>) => void;
    selectProps?: SelectProps<Array<string>>;
    datePickerProps?: DatePickerProps;
};

export function MultipleDatePicker(props: MultipleDatePickerType): JSX.Element {
    const {
        selectedDate = [],
        onChange,
        placeholder = <Locale stringKey="AUTO_REPLIES__FORM__FOR_DATES" />,
        format = 'YYYY-MM-DD',
        selectProps = {},
        datePickerProps = {},
    } = props;
    const [isOpen, setIsOpen] = useState(false);

    const onValueChange = useCallback(
        (_dayjs: Dayjs | null, date: string | Array<string>) => {
            if (!Array.isArray(date)) {
                const index = selectedDate.indexOf(date);
                const clone = [...selectedDate];

                if (index > -1) {
                    clone.splice(index, 1);
                } else {
                    clone.push(date);
                }

                onChange(clone);
            }
        },
        [selectedDate, onChange]
    );

    const dateRender = useCallback(
        (currentDate: Dayjs) => {
            const isSelected = selectedDate.includes(dayjs(currentDate).format(format));

            return <div className={isSelected ? styles.MultipleDatePicker_selectedDate : ''}>{currentDate.date()}</div>;
        },
        [format, selectedDate]
    );

    const renderTag = useCallback(
        (tagProps: CustomTagProps): ReactElement => {
            const {value, onClose} = tagProps;

            function handleClose() {
                onClose();
                onChange(selectedDate.filter((item) => item !== value));
            }

            return (
                <Tag closable onClose={handleClose}>
                    {value}
                </Tag>
            );
        },
        [onChange, selectedDate]
    );

    const selectDatePicker = useMemo((): JSX.Element => {
        return (
            <DatePicker
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...datePickerProps}
                dateRender={dateRender}
                format={format}
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                onChange={onValueChange}
                open
                style={{visibility: 'hidden'}}
                value={null}
            />
        );
    }, [dateRender, format, onValueChange, datePickerProps]);

    return (
        <Select
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...selectProps}
            allowClear
            dropdownRender={() => selectDatePicker}
            mode="multiple"
            onBlur={() => setIsOpen(false)}
            onClear={() => onChange([])}
            onFocus={() => setIsOpen(true)}
            open={isOpen}
            placeholder={placeholder}
            popupClassName={styles.MultipleDatePicker_popup}
            popupMatchSelectWidth={false}
            size="large"
            tagRender={renderTag}
            value={selectedDate}
        />
    );
}
