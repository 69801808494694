import {useState} from 'react';

import {appRoute} from '../../../app-route';
import {PageHeaderWithLicenseTag} from '../../../component/header/header-with-license-tag/page-header-with-license-tag';
import {TrialActivationModal} from '../../../component/landing/trial-activation-modal/trial-activation-modal';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {DEFAULT_TRIAL_PERIOD_DAYS} from '../../../provider/license/license-const';
import {useLicenses, useTrialActivation} from '../../../provider/license/license-hook';
import {useLocale} from '../../../provider/locale/locale-hook';
import {FeaturesEnum} from '../../../service/user/user-type';
import {LicensesCodeEnum} from '../tariffs-and-limits/licenses-block/license/license-const';

import {OnlinePresenceLanding} from './components/online-presence-landing/online-presence-landing';
import {OnlinePresenceOnboarding} from './components/online-presence-onboarding/online-presence-onboarding';

export function OnlinePresenceAnalyticsActivation(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {mutateAsync, isLoading} = useTrialActivation();
    const [isActivationModalOpen, setIsActivationModalOpen] = useState<boolean>(false);
    const [showOnboardingSteps, setShowOnboardingSteps] = useState<boolean>(false);

    function handleTrialPeriodActivate() {
        setIsActivationModalOpen(false);
        setShowOnboardingSteps(true);
    }

    const {licenses} = useLicenses();
    const license = licenses[FeaturesEnum.onlinePresenceAnalytics];

    return (
        <Page renderContainer={(children) => <MainPageContainer>{children}</MainPageContainer>}>
            <PageCard>
                <Meta title={getLocalizedString('ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS')} />
                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.onlinePresenceDashboard.path,
                            titleLangKey: 'ONLINE_PRESENCE_ANALYTICS__BREAD_CRUMBS',
                        },
                    ]}
                />
                <PageHeaderWithLicenseTag
                    headerLangKey="CATEGORY_NAME__ONLINE_PRESENCE"
                    licenseActive={license?.isActive}
                    trialLicense={license?.isTrial}
                />
            </PageCard>

            {!showOnboardingSteps ? (
                <OnlinePresenceLanding onTryFeatureClick={() => setIsActivationModalOpen(true)} />
            ) : (
                <OnlinePresenceOnboarding />
            )}

            <TrialActivationModal
                activateTrial={() => mutateAsync(LicensesCodeEnum.OnlinePresenceAnalyticsLicence)}
                isActivatingTrial={isLoading}
                onCancel={() => setIsActivationModalOpen(false)}
                onTrialPeriodActivated={handleTrialPeriodActivate}
                open={isActivationModalOpen}
                productNameLangKey="CATEGORY_NAME__ONLINE_PRESENCE"
                trialPeriodDuration={DEFAULT_TRIAL_PERIOD_DAYS}
            />
        </Page>
    );
}
