import {DatePicker, Form, Input} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {useMemo} from 'react';

import {Locale} from '../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {useDateTimePickerDisabler} from '../../../../../../../../service/datepicker/datetime-picker-hook';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostFormType} from '../../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../../typings/antd';
import * as postFormStyles from '../../../../post-form.scss';
import {PostFormItemPropsType} from '../../../../post-form-type';
import {DATE_TIME_FORMAT} from '../../../../posts-form-helper';
import {EVENT_NAME_MAX_LENGTH} from '../../content-step-helper';

type PropsType = {
    fieldName: Array<string | number>;
    formInstance: FormInstance<PostFormType>;
} & PostFormItemPropsType;

export function EventItem(props: PropsType): JSX.Element {
    const {fieldName, formInstance, disabled} = props;

    const {getLocalizedString} = useLocale();

    const {requiredFieldRuleWithText, maxLengthFieldRule, validateDayjsInTheFutureRule, validateDayjsAfterRule} =
        useFormRules();

    const startDate: Dayjs | null = Form.useWatch([...fieldName, 'start'], formInstance);
    const eventEndDate: Dayjs | null = Form.useWatch([...fieldName, 'end'], formInstance);

    const {dateDisabled: eventStartDateDisabler, timeDisabled: eventStartTimeDisabler} = useDateTimePickerDisabler({
        before: eventEndDate,
    });

    const eventEndDateAfter = useMemo(() => {
        const now = dayjs();

        if (!startDate || now.isAfter(startDate)) {
            return now;
        }

        return startDate;
    }, [startDate]);

    const {dateDisabled: eventEndDateDisabler, timeDisabled: eventEndTimeDisabler} = useDateTimePickerDisabler({
        after: eventEndDateAfter,
    });

    return (
        <>
            <Form.Item
                className={postFormStyles.PostForm_countedInputItem}
                initialValue=""
                label={<Locale stringKey="POSTS_FORM__TYPE__EVENT_NAME__LABEL" />}
                name={[...fieldName, 'title']}
                required
                rules={
                    !disabled
                        ? [
                              requiredFieldRuleWithText('POSTS_FORM__TYPE__EVENT_NAME__EMPTY'),
                              maxLengthFieldRule(EVENT_NAME_MAX_LENGTH),
                          ]
                        : []
                }
            >
                <Input
                    disabled={disabled}
                    maxLength={EVENT_NAME_MAX_LENGTH}
                    placeholder={getLocalizedString('POSTS_FORM__TYPE__EVENT_NAME__PLACEHOLDER')}
                    showCount
                />
            </Form.Item>

            <Form.Item
                label={<Locale stringKey="POSTS_FORM__TYPE__EVENT_START__LABEL" />}
                name={[...fieldName, 'start']}
                required
                rules={!disabled ? [requiredFieldRuleWithText('POSTS_FORM__TYPE__EVENT_DATE_START__EMPTY')] : []}
            >
                <DatePicker
                    disabled={disabled}
                    disabledDate={eventStartDateDisabler}
                    disabledTime={eventStartTimeDisabler}
                    format={DATE_TIME_FORMAT}
                    onChange={(date: Dayjs) => {
                        formInstance.setFieldValue([...fieldName, 'start'], date.utc(true));
                    }}
                    showTime={{format: 'HH:mm'}}
                />
            </Form.Item>

            <Form.Item
                label={<Locale stringKey="POSTS_FORM__TYPE__EVENT_END__LABEL" />}
                name={[...fieldName, 'end']}
                required
                rules={
                    !disabled
                        ? [
                              requiredFieldRuleWithText('POSTS_FORM__TYPE__EVENT_DATE_END__EMPTY'),
                              validateDayjsInTheFutureRule(
                                  getLocalizedString('POSTS_VALIDATION_ERROR__DATE_NOT_IN_FUTURE')
                              ),
                              ...(startDate
                                  ? [
                                        validateDayjsAfterRule(
                                            getLocalizedString('POSTS_VALIDATION_ERROR__REMOVE_DATE_AFTER_CREATION'),
                                            startDate.toDate()
                                        ),
                                    ]
                                  : []),
                          ]
                        : []
                }
            >
                <DatePicker
                    disabled={disabled}
                    disabledDate={eventEndDateDisabler}
                    disabledTime={eventEndTimeDisabler}
                    format={DATE_TIME_FORMAT}
                    onChange={(date: Dayjs) => {
                        formInstance.setFieldValue([...fieldName, 'end'], date.utc(true));
                    }}
                    showTime={{format: 'HH:mm'}}
                />
            </Form.Item>
        </>
    );
}
