import {Button, Carousel, Image} from 'antd';
import {CarouselRef} from 'antd/es/carousel';
import {Fragment} from 'react';

import {classNames} from '../../../../util/css';
import {Text} from '../../../text/text';

import * as styles from './yandex-stories-carousel.scss';

type PropsType = {
    className?: string;
    slides: Array<{
        image: {
            url: string;
        };
        buttonConfig?: {
            text: string;
        } | null;
    }>;
    carouselRef?: React.RefObject<CarouselRef>;
    afterChange?: (previousSlide: number, currentSlide: number) => void;
    storyName?: string;
};

export function YandexStoriesCarousel(props: PropsType): JSX.Element {
    const {className, slides, afterChange, carouselRef, storyName} = props;

    return (
        <Carousel
            beforeChange={afterChange}
            className={classNames(styles.YandexStoriesCarousel, className)}
            dotPosition="top"
            key="yandex-stories-carousel"
            ref={carouselRef}
        >
            {slides.map((slideConfig, index) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        <Text className={styles.YandexStoriesCarousel_name}>{storyName}</Text>
                        <Image preview={false} src={slideConfig.image.url} width="100%" />
                        {slideConfig.buttonConfig && (
                            <div className={styles.YandexStoriesCarousel_footerShadow}>
                                <Button className={styles.YandexStoriesCarousel_button}>
                                    {slideConfig.buttonConfig.text}
                                </Button>
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </Carousel>
    );
}
