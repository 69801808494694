import {useEffect} from 'react';

import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchLinkBrands} from './link-brands-api';
import {LinkBrandType} from './link-brands-type';

export function useLinkBrands(groupId: number): UseHookType<Array<LinkBrandType>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<LinkBrandType>>();

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchLinkBrands(groupId)
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult, groupId]);

    return {isInProgress, processError, result, reset};
}
