import {faCircleCheck, faCircleXmark, faInfoCircle, faSquareXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCloudArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Checkbox, Space} from 'antd';
import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';

import {AdditionalInfo} from '../../../../../../../../layout/additional-info/additional-info';
import {LocalePlural} from '../../../../../../../../provider/locale/locale-plural';
import {Locale} from '../../../../../../../../provider/locale/localization';
import {useCompaniesImportDeleteMutation} from '../../../../../../../../service/companies-import/companies-import-delete';
import {classNames} from '../../../../../../../../util/css';
import {FeedProgress} from '../../../form/progress/feed-progress';

import * as styles from './feed-results-import-status.scss';

type PropsType = {
    importUrlId: number;
    companiesCount: Record<'beforeImport' | 'afterImport', number>;
    isImportInProgress: boolean;
    isImportCompleted: boolean;
    isImportCancelled: boolean;
    isImportImpossible: boolean;
    loadPercent?: number;
    isImportStarted?: boolean;
    runImport: (periodicUpdate: boolean) => void;
    setIsImportDisabled: Dispatch<SetStateAction<boolean>>;
    setIsImportCancelled: Dispatch<SetStateAction<boolean>>;
};

export function FeedResultsImportStatus(props: PropsType): JSX.Element {
    const {
        importUrlId,
        companiesCount,
        isImportInProgress,
        isImportCompleted,
        isImportCancelled,
        isImportImpossible,
        runImport,
        setIsImportDisabled,
        setIsImportCancelled,
        loadPercent,
        isImportStarted,
    } = props;

    const [periodicUpdate, setPeriodicUpdate] = useState(false);
    const {mutate: deleteImport, isLoading} = useCompaniesImportDeleteMutation(importUrlId);

    const handleImportCancellation = useCallback(() => {
        setIsImportCancelled(true);
        setIsImportDisabled(false);
        deleteImport();
    }, [deleteImport, setIsImportCancelled, setIsImportDisabled]);

    const checkbox = useMemo(
        () => (
            <Checkbox
                checked={periodicUpdate}
                className={styles.FeedResultsImportStatus_checkbox}
                disabled={isImportCompleted || isImportStarted}
                onChange={(event) => setPeriodicUpdate(event.target.checked)}
            >
                <Space>
                    <Locale stringKey="FEED__IMPORT__CONFIRM__UPDATE" />

                    <AdditionalInfo
                        icon={<FontAwesomeIcon icon={faInfoCircle} />}
                        title={<Locale stringKey="FEED__IMPORT__CONFIRM__UPDATE__TITLE" />}
                    >
                        <Locale stringKey="FEED__IMPORT__CONFIRM__UPDATE__DESCRIPTION" />
                    </AdditionalInfo>
                </Space>
            </Checkbox>
        ),
        [isImportCompleted, periodicUpdate, isImportStarted]
    );

    const statuses = useMemo(
        () => ({
            error: {
                icon: faCircleXmark,
                iconClassName: styles.FeedResultsImportStatus_icon__red,
                title: <Locale stringKey="FEED__IMPORT__ERROR__TITLE" />,
                description: <Locale stringKey="FEED__IMPORT__ERROR__DESCRIPTION" />,
            },
            cancel: {
                icon: faSquareXmark,
                iconClassName: styles.FeedResultsImportStatus_icon__orange,
                title: <Locale stringKey="FEED__IMPORT__CANCEL__TITLE" />,
                description: <Locale stringKey="FEED__IMPORT__CANCEL__DESCRIPTION" />,
            },
            success: {
                icon: faCircleCheck,
                iconClassName: styles.FeedResultsImportStatus_icon__green,
                title: <Locale stringKey="FEED__IMPORT__COMPLETE__TITLE" />,
                description: (
                    <>
                        <LocalePlural
                            count={companiesCount.afterImport}
                            fewKey="FEED__IMPORT__COMPLETE__DESCRIPTION__FEW"
                            manyKey="FEED__IMPORT__COMPLETE__DESCRIPTION__MANY"
                            singularKey="FEED__IMPORT__COMPLETE__DESCRIPTION__SINGLE"
                            valueMap={{count: companiesCount.afterImport}}
                        />

                        {checkbox}
                    </>
                ),
            },
            confirmation: {
                icon: faCloudArrowUp,
                iconClassName: styles.FeedResultsImportStatus_icon__blue,
                title: <Locale stringKey="FEED__IMPORT__CONFIRM__TITLE" />,
                description: (
                    <>
                        <LocalePlural
                            count={companiesCount.beforeImport}
                            fewKey="FEED__IMPORT__CONFIRM__DESCRIPTION__FEW"
                            manyKey="FEED__IMPORT__CONFIRM__DESCRIPTION__MANY"
                            singularKey="FEED__IMPORT__CONFIRM__DESCRIPTION__SINGLE"
                            valueMap={{count: companiesCount.beforeImport}}
                        />
                        {checkbox}

                        {isImportStarted && (
                            <FeedProgress
                                className={styles.FeedProgress}
                                onAbort={handleImportCancellation}
                                percent={loadPercent}
                                size={80}
                                translations={{
                                    beforeProgressTitle: 'FEED__IMPORT__BEFORE_PROGRESS__TITLE',
                                    abortButtonLabel: 'FEED__IMPORT__ABORT__BUTTON',
                                    progressTitle: 'FEED__IMPORT__PROGRESS__IMPORT__TITLE',
                                    abortModalDescription: 'FEED__IMPORT__ABORT_MODAL__DESCRIPTION',
                                    abortModalTitle: 'FEED__IMPORT__ABORT_MODAL__TITLE',
                                }}
                            />
                        )}

                        {!isImportStarted && (
                            <Space className={styles.FeedResultsImportStatus_buttons}>
                                <Button
                                    loading={isImportInProgress}
                                    onClick={() => runImport(periodicUpdate)}
                                    type="primary"
                                >
                                    <Locale stringKey="FEED__IMPORT__CONFIRM__CONFIRM" />
                                </Button>

                                <Button
                                    disabled={isImportInProgress}
                                    loading={isLoading}
                                    onClick={() => handleImportCancellation()}
                                >
                                    <Locale stringKey="BUTTON__CANCEL" />
                                </Button>
                            </Space>
                        )}
                    </>
                ),
            },
        }),
        [
            checkbox,
            companiesCount.afterImport,
            companiesCount.beforeImport,
            handleImportCancellation,
            isImportInProgress,
            isImportStarted,
            isLoading,
            periodicUpdate,
            runImport,
        ]
    );

    const status = useMemo(() => {
        if (isImportImpossible) {
            return statuses.error;
        }

        if (isImportCancelled) {
            return statuses.cancel;
        }

        if (isImportCompleted) {
            return statuses.success;
        }

        return statuses.confirmation;
    }, [isImportImpossible, isImportCancelled, loadPercent, isImportCompleted, statuses]);

    return (
        <Card className={styles.FeedResultsImportStatus}>
            <Card.Meta
                avatar={
                    <FontAwesomeIcon
                        className={classNames(styles.FeedResultsImportStatus_icon, status.iconClassName)}
                        icon={status.icon}
                    />
                }
                description={status.description}
                title={status.title}
            />
        </Card>
    );
}
