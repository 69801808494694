import {uniqueId} from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import {Spinner} from '../../../../../../../layout/spinner/spinner';
import {useBrandValidationSettings} from '../../../../../../../provider/brands/brand-validation-settings';
import {
    useDoubleGisAttributes,
    useGoogleAttributes,
    useYandexAttributes,
} from '../../../../../../../provider/catalogs/attributes/attributes-hook';
import {useCatalogConfigForCompanyForm} from '../../../../../../../provider/catalogs/catalogs-hook';
import {CatalogConfigType} from '../../../../../../../provider/catalogs/catalogs-type';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../../util/type';
import {CatalogConfigWithIndexType, CatalogList} from '../../../catalog-list/catalog-list';
import {CatalogTab} from '../../../catalog-list/catalog-tab';
import {isAttributeValueChanged} from '../attributes-form-helper';
import {
    groupGoogleAttributes,
    mapGoogleAttributes,
    prepareAndMergeDoubleGisAttributes,
    prepareAndMergeGoogleAttributes,
    prepareAndMergeYandexAttributes,
} from '../attributes-widget-helper';

import {AttributesWidgetTab} from './attributes-widget-tab';

type PropsType = {
    isShown: boolean;
    widgetName: string;
    renderNoCatalogsPlaceholder?: (disabled: boolean) => JSX.Element;
    brandId?: number | null;
};

export function AttributesWidgetCatalogList(props: PropsType): JSX.Element | null {
    const {isShown, widgetName, renderNoCatalogsPlaceholder, brandId} = props;

    const form = Form.useFormInstance<CompanyFormType>();
    const watchedAttributes = Form.useWatch(CompanyKeyEnum.Attributes, form);

    const [resetId, setResetId] = useState<string | null>(null);
    const {result: googleAttributes, isInProgress: isGoogleInProgress} = useGoogleAttributes();
    const {result: yandexAttributes, isInProgress: isYandexInProgress} = useYandexAttributes();
    const {result: doubleGisAttributes, isInProgress: isDoubleGisInProgress} = useDoubleGisAttributes();
    const {data: brandValidationSetting} = useBrandValidationSettings(brandId);
    const catalogConfigHook = useCatalogConfigForCompanyForm();

    useEffect(() => {
        if (isGoogleInProgress) {
            return;
        }

        const newGoogleAttributes = prepareAndMergeGoogleAttributes(
            form.getFieldValue([CompanyKeyEnum.Attributes, 'attributesGoogle']),
            googleAttributes
        );

        if (newGoogleAttributes) {
            form.setFieldsValue({
                attributes: {
                    attributesGoogle: groupGoogleAttributes(newGoogleAttributes),
                },
            });
        }
    }, [form, isGoogleInProgress, googleAttributes]);

    useEffect(() => {
        if (isYandexInProgress) {
            return;
        }

        const newYandexAttributes = prepareAndMergeYandexAttributes(
            form
                .getFieldValue([CompanyKeyEnum.Attributes, 'attributesYandex'])
                ?.filter(({values}) => values.some(isAttributeValueChanged)),
            yandexAttributes
        );

        if (newYandexAttributes) {
            form.setFieldsValue({
                attributes: {
                    attributesYandex: newYandexAttributes,
                },
            });
        }
    }, [form, isYandexInProgress, yandexAttributes]);

    useEffect(() => {
        if (isDoubleGisInProgress) {
            return;
        }

        const newDoublegisAttributes = prepareAndMergeDoubleGisAttributes(
            form.getFieldValue([CompanyKeyEnum.Attributes, 'attributesDoubleGis']),
            doubleGisAttributes
        );

        if (newDoublegisAttributes) {
            form.setFieldsValue({
                attributes: {
                    attributesDoubleGis: newDoublegisAttributes,
                },
            });
        }
    }, [form, isDoubleGisInProgress, doubleGisAttributes]);

    const isCatalogVisible = useCallback(
        ({supportsAttributes, catalogId}: CatalogConfigType) => {
            if (!supportsAttributes) {
                return false;
            }

            return (
                (catalogId === ProvidersIdsEnum.google && Boolean(googleAttributes)) ||
                (catalogId === ProvidersIdsEnum.yandex && Boolean(yandexAttributes && yandexAttributes.length > 0)) ||
                (catalogId === ProvidersIdsEnum.doubleGis &&
                    Boolean(
                        doubleGisAttributes &&
                            doubleGisAttributes.length > 0 &&
                            !brandValidationSetting?.disableDoubleGisAttributes
                    ))
            );
        },
        [googleAttributes, yandexAttributes, doubleGisAttributes, brandValidationSetting?.disableDoubleGisAttributes]
    );

    function getTabIndexForError() {
        const visibleCatalogs = catalogConfigHook.result?.filter(isCatalogVisible);
        const errors = form.getFieldsError().find((error) => {
            return error.name[0] === CompanyKeyEnum.Attributes && error.errors.length > 0;
        });

        if (!errors) {
            return null;
        }

        return visibleCatalogs?.[0]?.catalogId === ProvidersIdsEnum.yandex && errors?.name[1] === 'attributesYandex'
            ? 0
            : 1;
    }

    function isFooterButtonVisible(catalogId: number) {
        if (catalogId === ProvidersIdsEnum.google) {
            return watchedAttributes?.attributesGoogle?.some(
                (group) =>
                    'items' in group && group.items.some((attribute) => attribute.values.some(isAttributeValueChanged))
            );
        }

        if (catalogId === ProvidersIdsEnum.yandex) {
            return watchedAttributes?.attributesYandex?.some((attribute) =>
                attribute?.values.some(isAttributeValueChanged)
            );
        }

        if (catalogId === ProvidersIdsEnum.doubleGis) {
            return watchedAttributes?.attributesDoubleGis?.some((attribute) => attribute?.values.some(Boolean));
        }

        return false;
    }

    function handleCatalogRemove(catalogId: number) {
        if (catalogId === ProvidersIdsEnum.google && googleAttributes) {
            form.setFieldsValue({
                attributes: {attributesGoogle: groupGoogleAttributes(mapGoogleAttributes(googleAttributes))},
            });
        }

        if (catalogId === ProvidersIdsEnum.yandex && yandexAttributes) {
            form.setFieldsValue({attributes: {attributesYandex: yandexAttributes}});
        }

        if (catalogId === ProvidersIdsEnum.doubleGis && doubleGisAttributes) {
            form.setFieldsValue({attributes: {attributesDoubleGis: doubleGisAttributes}});
        }

        setResetId(uniqueId());
    }

    function handleCatalogAvailabilityChange(availableCatalogIds: Array<number>) {
        const formAttributes = form.getFieldValue(CompanyKeyEnum.Attributes);

        if (!formAttributes || !yandexAttributes) {
            return;
        }

        form.setFieldsValue({
            attributes: {
                ...formAttributes,
                attributesYandex: availableCatalogIds.includes(ProvidersIdsEnum.yandex)
                    ? formAttributes.attributesYandex
                    : [],
            },
        });
    }

    if (isYandexInProgress || isGoogleInProgress || isDoubleGisInProgress) {
        return <Spinner />;
    }

    return (
        <CatalogList
            activeTabIndex={getTabIndexForError()}
            catalogTabListHidden={!isShown}
            isCatalogVisible={isCatalogVisible}
            onCatalogAvailabilityChange={handleCatalogAvailabilityChange}
            renderCatalog={(catalog: CatalogConfigWithIndexType) => {
                return (
                    <CatalogTab
                        catalog={catalog}
                        footerButtonText={<Locale stringKey="COMPANY_FORM__CATEGORIES__CATALOG_LIST__FOOTER__BUTTON" />}
                        footerButtonVisible={isFooterButtonVisible(catalog.catalogId)}
                        modalContent={
                            <Locale
                                stringKey="COMPANY_FORM__ATTRIBUTES__CATALOG_LIST__MODAL__CONTENT"
                                valueMap={{
                                    catalogName: catalog.label,
                                }}
                            />
                        }
                        modalFieldName={widgetName}
                        modalTitle={<Locale stringKey="COMPANY_FORM__CATEGORIES__CATALOG_LIST__MODAL__TITLE" />}
                        onRemove={() => handleCatalogRemove(catalog.catalogId)}
                    >
                        {isShown && catalog.catalogId === ProvidersIdsEnum.google && isCatalogVisible(catalog) && (
                            <AttributesWidgetTab
                                catalogId={catalog.catalogId}
                                namePath={[CompanyKeyEnum.Attributes, 'attributesGoogle']}
                                resetId={resetId}
                            />
                        )}

                        {isShown && catalog.catalogId === ProvidersIdsEnum.yandex && isCatalogVisible(catalog) && (
                            <AttributesWidgetTab
                                catalogId={catalog.catalogId}
                                namePath={[CompanyKeyEnum.Attributes, 'attributesYandex']}
                                resetId={resetId}
                            />
                        )}

                        {isShown && catalog.catalogId === ProvidersIdsEnum.doubleGis && isCatalogVisible(catalog) && (
                            <AttributesWidgetTab
                                catalogId={catalog.catalogId}
                                namePath={[CompanyKeyEnum.Attributes, 'attributesDoubleGis']}
                                resetId={resetId}
                            />
                        )}
                    </CatalogTab>
                );
            }}
            renderNoCatalogsPlaceholder={renderNoCatalogsPlaceholder}
            showWithoutConfiguration
        />
    );
}
