import {Button, Flex, Modal, Row, Space, Switch} from 'antd';
import {ReactElement, useEffect, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {Locale} from '../../../../../provider/locale/locale';
import {useReviewStateHook} from '../../reviews-state/reviews-state-provider';

import modalImageSrc from './images/modal-image.png';
import * as styles from './new-design-switcher.scss';

export function NewDesignSwitcher(): ReactElement {
    const {handleNewReviewsLayout, isNewLayout} = useReviewStateHook();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [timer, setTimer] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSwitch() {
        setLoading(true);
    }

    useEffect(() => {
        if (loading) {
            setTimer(
                setTimeout(() => {
                    if (isNewLayout) {
                        setOpenModal(true);
                    } else {
                        handleNewReviewsLayout(true);
                        setLoading(false);
                    }
                }, 200)
            );
        }

        setTimer(null);

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isNewLayout, loading, handleNewReviewsLayout, timer]);

    function onCancel() {
        handleNewReviewsLayout(false);
        setOpenModal(false);
        setLoading(false);
    }

    function onOk() {
        setOpenModal(false);
        setLoading(false);
    }

    return (
        <>
            <Space>
                <Text stringKey="REVIEW__NEW_DESIGN__SWITCHER" />
                <Switch checked={isNewLayout} loading={loading} onClick={handleSwitch} size="small" />
            </Space>
            <Modal
                cancelText={<Locale stringKey="REVIEW__NEW_DESIGN_MODAL__FOOTER__CANCEL" />}
                footer={
                    <Row justify="end">
                        <Space wrap>
                            <Button onClick={onCancel}>
                                <Locale stringKey="REVIEW__NEW_DESIGN_MODAL__FOOTER__CANCEL" />
                            </Button>

                            <Button onClick={onOk} type="primary">
                                <Locale stringKey="REVIEW__NEW_DESIGN_MODAL__FOOTER__CONFIRM" />
                            </Button>
                        </Space>
                    </Row>
                }
                okText={<Locale stringKey="REVIEW__NEW_DESIGN_MODAL__FOOTER__CONFIRM" />}
                onCancel={onOk}
                open={openModal}
                title={<Locale stringKey="REVIEW__NEW_DESIGN_MODAL__TITLE" />}
            >
                <Flex align="center" vertical>
                    <img alt="" className={styles.NewDesignSwitcher_image} src={modalImageSrc} />
                    <Space direction="vertical" size={16}>
                        <Text stringKey="REVIEW__NEW_DESIGN_MODAL__CONTENT__DESCRIPTION" />
                        <div>
                            <Text bolder stringKey="REVIEW__NEW_DESIGN_MODAL__CONTENT__LIST__TITLE" />
                            <ul className={styles.NewDesignSwitcher_modalList}>
                                <li>
                                    <Text stringKey="REVIEW__NEW_DESIGN_MODAL__CONTENT__LIST__PARAGRAPH_1" />
                                </li>
                                <li>
                                    <Text stringKey="REVIEW__NEW_DESIGN_MODAL__CONTENT__LIST__PARAGRAPH_2" />
                                </li>
                            </ul>
                        </div>
                        <Text stringKey="REVIEW__NEW_DESIGN_MODAL__CONTENT__ALERT" />
                    </Space>
                </Flex>
            </Modal>
        </>
    );
}
