import {Col, Row} from 'antd';

import {ShortCatalogType} from '../../../../provider/catalogs/catalogs-type';
import {PostsFilter} from '../posts-filter/posts-filter';

type PropsType = {
    catalogs: Array<ShortCatalogType>;
};

export function PostsControl(props: PropsType): JSX.Element {
    const {catalogs} = props;

    return (
        <div>
            <Row align="middle" gutter={[16, 16]} justify="space-between">
                <Col flex="auto">
                    <PostsFilter catalogs={catalogs} />
                </Col>
            </Row>
        </div>
    );
}
