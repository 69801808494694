import {LangKeyType} from '../../../../provider/locale/translation/type';
import {CompanyInfoEmptyFieldsEnum} from '../../../../service/dashboard/dashboard-type';

export const CompanyInfoEmptyFieldsMap: Record<CompanyInfoEmptyFieldsEnum, LangKeyType> = {
    [CompanyInfoEmptyFieldsEnum.Categories]: 'TABLE__HEADER__BLOCK__CATEGORIES',
    [CompanyInfoEmptyFieldsEnum.Phones]: 'TABLE__HEADER__BLOCK__PHONES',
    [CompanyInfoEmptyFieldsEnum.PaymentMethods]: 'TABLE__HEADER__BLOCK__PAYMENT_METHODS',
    [CompanyInfoEmptyFieldsEnum.HasLogo]: 'TABLE__HEADER__BLOCK__LOGO',
    [CompanyInfoEmptyFieldsEnum.HasCover]: 'TABLE__HEADER__BLOCK__COVER',
    [CompanyInfoEmptyFieldsEnum.HasDescription]: 'TABLE__HEADER__BLOCK__DESCRIPTION',
    [CompanyInfoEmptyFieldsEnum.HasGallery]: 'TABLE__HEADER__BLOCK__GALLERY',
    [CompanyInfoEmptyFieldsEnum.HasVkLink]: 'TABLE__HEADER__BLOCK__VK_LINK',
    [CompanyInfoEmptyFieldsEnum.HasFacebookLink]: 'TABLE__HEADER__BLOCK__FACEBOOK_LINK',
    [CompanyInfoEmptyFieldsEnum.HasInstagramLink]: 'TABLE__HEADER__BLOCK__INSTAGRAM_LINK',
    [CompanyInfoEmptyFieldsEnum.HasViberLink]: 'TABLE__HEADER__BLOCK__VIBER_LINK',
    [CompanyInfoEmptyFieldsEnum.HasWhatsAppLink]: 'TABLE__HEADER__BLOCK__WHATSAPP_LINK',
    [CompanyInfoEmptyFieldsEnum.HasYandexZenLink]: 'TABLE__HEADER__BLOCK__YANDEX_ZEN_LINK',
    [CompanyInfoEmptyFieldsEnum.HasTelegramLink]: 'TABLE__HEADER__BLOCK__TELEGRAM_LINK',
    [CompanyInfoEmptyFieldsEnum.HasLinkedInLink]: 'TABLE__HEADER__BLOCK__LINKEDIN_LINK',
    [CompanyInfoEmptyFieldsEnum.HasOkRuLink]: 'TABLE__HEADER__BLOCK__OK_RU_LINK',
    [CompanyInfoEmptyFieldsEnum.HasYoutubeLink]: 'TABLE__HEADER__BLOCK__YOUTUBE_LINK',
    [CompanyInfoEmptyFieldsEnum.HasTwitterLink]: 'TABLE__HEADER__BLOCK__TWITTER_LINK',
};
