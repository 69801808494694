import {useContext, useEffect} from 'react';

import {MainFilterContext} from '../../provider/main-filter/main-filter';
import {useApiHooks} from '../api-hook/api-hook';
import {UseHookType} from '../api-hook/api-hook-type';

import {fetchAllCclStatistic, fetchCclStatistic} from './ccl-statistic-api';
import {CatalogNameType, CclStatisticType} from './ccl-statistic-type';

export function useCclStatistic(
    catalogType: CatalogNameType,
    isNewCompanies: boolean,
    sourceIdList?: Array<string>,
    companyIdList?: Array<string>,
    accessTransferRequired?: boolean
): UseHookType<CclStatisticType> {
    const {mainFilterKey} = useContext(MainFilterContext);

    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CclStatisticType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchCclStatistic(
            mainFilterKey,
            catalogType,
            isNewCompanies,
            sourceIdList,
            companyIdList,
            accessTransferRequired
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [
        mainFilterKey,
        catalogType,
        setIsInProgress,
        setProcessError,
        setResult,
        sourceIdList,
        companyIdList,
        isNewCompanies,
        accessTransferRequired,
    ]);

    return {isInProgress, processError, result, reset};
}

export function useAllCclStatistic(): UseHookType<CclStatisticType> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<CclStatisticType>();

    useEffect(() => {
        setIsInProgress(true);

        fetchAllCclStatistic()
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
