import {faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../app-route';
import {AlertFallback} from '../../../../component/alert-fallback/alert-fallback';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useCatalogInfo} from '../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useCompanySyncStatistics} from '../../../../service/dashboard/dashboard-hook';
import {DashboardWidgetEnum} from '../dashboard-type';
import {DashboardWidgetHeader} from '../dashboard-widget-header/dashboard-widget-header';

import {cclStatusIconSrcMap, cclStatusLocaleKeyMap} from './sync-statistics-const';
import * as styles from './sync-statistics.scss';

type SyncStatisticsPropsType = {
    companyId: number;
};

export function SyncStatisticsSingleCompany(props: SyncStatisticsPropsType): JSX.Element {
    const {getLocalizedString} = useLocale();
    const {companyId} = props;
    const {result, isInProgress, processError} = useCompanySyncStatistics(companyId);

    const {getCatalogName, getCatalogLogo} = useCatalogInfo();

    if (processError) {
        return <AlertFallback />;
    }

    if (isInProgress || !result) {
        return <Spinner />;
    }

    return (
        <PageCard
            title={
                <DashboardWidgetHeader
                    title={<Locale stringKey="DASHBOARD_PAGE__SYNC_STATISTICS__TITLE" />}
                    widget={DashboardWidgetEnum.SynchronizationSingle}
                />
            }
        >
            <div className={styles.sync_statistics__card_wrapper}>
                <div className={styles.sync_statistics__table_wrapper}>
                    {result?.map((syncStatistics) => {
                        const localeKey = cclStatusLocaleKeyMap[syncStatistics.stateStatus];

                        return (
                            <div className={styles.sync_statistics__row_wrapper} key={syncStatistics.catalog.id}>
                                <div className={styles.sync_statistics__catalog_info_wrapper}>
                                    <img
                                        alt=""
                                        className={styles.sync_statistics__catalog_info__image}
                                        src={getCatalogLogo(syncStatistics.catalog.id)}
                                    />
                                    <div className={styles.sync_statistics__catalog_info__name}>
                                        {getCatalogName(syncStatistics.catalog.id)}
                                    </div>
                                    {syncStatistics.isVerified && (
                                        <div className={styles.sync_statistics__catalog_info__dot}>•</div>
                                    )}
                                    {syncStatistics.isVerified && (
                                        <FontAwesomeIcon
                                            className={styles.sync_statistics__catalog_info__verified_icon}
                                            icon={faCircleCheck}
                                            size="sm"
                                        />
                                    )}
                                </div>
                                <div className={styles.sync_statistics__sync_info_wrapper}>
                                    <div className={styles.sync_statistics__sync_info__status_name}>
                                        {localeKey ? getLocalizedString(localeKey) : null}
                                    </div>
                                    {cclStatusIconSrcMap[syncStatistics.stateStatus]}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.sync_statistics__explanation__text}>
                    {getLocalizedString('DASHBOARD_PAGE__SYNC_STATISTICS__EXPLANATION')}
                    <NavigationLink to={appRoute.sources.path}>
                        <Locale stringKey="CATEGORY_NAME__SOURCES" />
                    </NavigationLink>
                    .
                </div>
            </div>
        </PageCard>
    );
}
