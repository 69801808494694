import {useEffect, useMemo} from 'react';

import {usePagination} from '../../../util/pagination-hook/pagination-hook';
import {PaginationOptionsType, PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {PaginatedResponseType, RequestOptionsType} from '../../api/api-type';
import {useApiHooks} from '../../api-hook/api-hook';
import {UseHookType} from '../../api-hook/api-hook-type';

import {fetchReviewPhraseList, fetchReviewPhraseSearch} from './review-phrases-api';
import {ReviewPhraseListQueryType, ReviewPhraseType} from './review-phrases-type';

export function useReviewPhraseList(
    options: ReviewPhraseListQueryType,
    mainFilterKey: string,
    paginationOption?: PaginationOptionsType
): UseHookType<PaginatedResponseType<ReviewPhraseType>> & PaginationType {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<PaginatedResponseType<ReviewPhraseType>>();

    const {words, startDate, endDate, sentiments, sources, tags, phrases, topics, rating, withAnswers, withText} =
        options;

    const paginationDependencies = useMemo(() => {
        return {
            words,
            startDate,
            endDate,
            sentiments,
            sources,
            tags,
            phrases,
            topics,
            rating,
            withAnswers,
            withText,
            mainFilterKey,
        };
    }, [
        words,
        startDate,
        endDate,
        sentiments,
        sources,
        tags,
        phrases,
        topics,
        rating,
        withAnswers,
        withText,
        mainFilterKey,
    ]);

    const pagination = usePagination({...paginationOption, dependencies: paginationDependencies});

    const {pageSize, page, onDataLoadFailed, onDataLoaded, refreshId} = pagination;

    useEffect(() => {
        setIsInProgress(true);
        setProcessError(null);

        fetchReviewPhraseList(
            {
                page,
                pageSize,
                words,
                startDate,
                endDate,
                sentiments,
                sources,
                tags,
                phrases,
                topics,
                rating,
                withAnswers,
                withText,
            },
            mainFilterKey
        )
            .then((response) => {
                onDataLoaded(response);
                setResult(response);
            })
            .finally(() => setIsInProgress(false))
            .catch((error: Error) => {
                onDataLoadFailed();
                setProcessError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshId, setIsInProgress, setProcessError, setResult, onDataLoaded, onDataLoadFailed]);

    return {isInProgress, processError, result, reset, pagination};
}

export function useReviewPhrasesSearch(options: RequestOptionsType, mainFilterKey: string): UseHookType<Array<string>> {
    const {isInProgress, setIsInProgress, processError, setProcessError, result, setResult, reset} =
        useApiHooks<Array<string>>();

    const {q, count, page} = options;

    useEffect(() => {
        setIsInProgress(true);

        fetchReviewPhraseSearch(
            {
                q,
                page,
                count,
            },
            mainFilterKey
        )
            .then(setResult)
            .finally(() => setIsInProgress(false))
            .catch(setProcessError);
    }, [page, count, q, mainFilterKey, setIsInProgress, setProcessError, setResult]);

    return {isInProgress, processError, result, reset};
}
