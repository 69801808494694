import {LocaleContextType} from '../../../provider/locale/locale-context-type';
import {CustomFileType} from '../../../service/file-upload/file-upload-type';
import {FormRuleType} from '../../../service/form/form-rules-hook-type';

export function getTechnicalSupportFileUploadValidator(
    getLocalizedString: LocaleContextType['getLocalizedString']
): FormRuleType<Record<string, CustomFileType>> {
    return {
        validator: (_rule, files): Promise<void> => {
            if (Object.values(files).some((file) => file.status === 'uploading')) {
                return Promise.reject(
                    getLocalizedString('HELP_TECHNICAL_SUPPORT__FILES_VALIDATION_ERROR__WAIT_FOR_UPLOAD')
                );
            }

            if (Object.values(files).some((file) => file.status === 'error')) {
                return Promise.reject(
                    getLocalizedString('HELP_TECHNICAL_SUPPORT__FILES_VALIDATION_ERROR__HAS_FAILED_ITEMS')
                );
            }

            return Promise.resolve();
        },
    };
}
