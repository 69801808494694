import {useCallback, useState} from 'react';

import {LandingCongratulationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-congratulation-step';
import {LandingOnboardingPageCard} from '../../../../../component/landing/landing-onboarding-steps/landing-onboarding-page-card';
import {LandingPresentationStep} from '../../../../../component/landing/landing-onboarding-steps/landing-presentation-step';
import {PHOTO_TOOL_ONBOARDING_IMAGES} from '../photo-tool-activation-helper';

export function PhotoToolOnboardingSteps(): JSX.Element {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const onStepFinished = useCallback(() => {
        setCurrentStepIndex((previousActiveKeyIndex) => previousActiveKeyIndex + 1);
    }, []);

    const steps = [
        <LandingPresentationStep
            descriptionLangKeyType="PHOTO_TOOL__ONBOARDING__STEP1__TEXT"
            images={PHOTO_TOOL_ONBOARDING_IMAGES.step1}
            key="step1"
            onStepFinish={onStepFinished}
            titleLangKeyType="PHOTO_TOOL__ONBOARDING__STEP1__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="PHOTO_TOOL__ONBOARDING__STEP2__TEXT"
            images={PHOTO_TOOL_ONBOARDING_IMAGES.step2}
            key="step2"
            onStepFinish={onStepFinished}
            titleLangKeyType="PHOTO_TOOL__ONBOARDING__STEP2__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="PHOTO_TOOL__ONBOARDING__STEP3__TEXT"
            images={PHOTO_TOOL_ONBOARDING_IMAGES.step3}
            key="step3"
            onStepFinish={onStepFinished}
            titleLangKeyType="PHOTO_TOOL__ONBOARDING__STEP3__TITLE"
        />,
        <LandingPresentationStep
            descriptionLangKeyType="PHOTO_TOOL__ONBOARDING__STEP4__TEXT"
            images={PHOTO_TOOL_ONBOARDING_IMAGES.step4}
            key="step4"
            onStepFinish={onStepFinished}
            titleLangKeyType="PHOTO_TOOL__ONBOARDING__STEP4__TITLE"
        />,
        <LandingCongratulationStep
            descriptionLangKeyType="PHOTO_TOOL__ONBOARDING__STEP5__TEXT"
            key="step5"
            titleLangKeyType="PHOTO_TOOL__ONBOARDING__STEP5__TITLE"
        />,
    ];

    return <LandingOnboardingPageCard current={currentStepIndex} onChange={setCurrentStepIndex} steps={steps} />;
}
