import TextArea from 'antd/lib/input/TextArea';
import {SyntheticEvent} from 'react';

import {useLocale} from '../../../../../../../../provider/locale/locale-hook';

type PropsType = {
    value?: string;
    onChange?: (newValue: string) => void;
    maxLength: number;
    disabled: boolean;
};

export function PostTextField(props: PropsType): JSX.Element {
    const {value, onChange, maxLength, disabled} = props;

    const {getLocalizedString} = useLocale();

    return (
        <TextArea
            disabled={disabled}
            maxLength={maxLength}
            onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                onChange?.(event.currentTarget.value);
            }}
            placeholder={getLocalizedString('POSTS_FORM__CONTENT__PLACEHOLDER')}
            rows={10}
            showCount={{
                formatter: (formatterOptions) => `${formatterOptions.value.length}/${formatterOptions.maxLength}`,
            }}
            value={value}
        />
    );
}
