/* eslint-disable sonarjs/no-duplicate-string */
import {MutableRefObject, useEffect, useRef} from 'react';

import {ShortLocaleNameEnum} from '../../provider/locale/locale-context-type';
import {useIsVisibleOnce} from '../../util/intersection-observer-hook/intersection-observer-hook';

import {pushToDataLayer} from './analytics-helper';
import {AnalyticsEventEnum, RecursiveValueOfType} from './analytics-type';

export const AnalyticsTarget = {
    PersonalCabinet: {
        Login: 'Login',
        Filter: 'Use filter',
        Help: 'Click Help Top',
        Language: 'Change language',
        Notifications: 'Click Notifications',
        NotificationLink: 'Go to notification link',
        NotificationView: 'Notification view',
        Pagination: 'Click page',
    },
    Dashboard: {
        RecommendationCompanies: 'Go to companies',
        RecommendationReviews: 'Go to reviews',
        RecommendationQuestions: 'Go to questions',
        RecommendationSources: 'Go to sources',
        RecommendationSetupLinking: 'Setup linking',
    },
    MyCompanies: {
        Create: 'Create a company',
        Update: 'Edit a company',
        Summary: 'Click Summary of company',
        SelectAllCompanies: 'Select all companies',
        SelectNewCompanies: 'Select new companies',
        EditSelected: 'Edit company data',
    },
    Sources: {
        CatalogFilter: 'Use filter Source',
        StatusFilter: 'Click card to filter',
    },
    ReviewsManagement: {
        Reviews: {
            ReportDownload: 'Download the report',
            AddResponseTemplate: 'Add Response template',
            CreateReview: 'Create a review',
            ViewHistory: 'View a history',
            ReplyToReview: 'Reply to review',
            AddResponsibleUser: 'Add a responsible',
            CreateComplaint: 'Create a complaint',
            ClickResponseTemplates: 'Click Response templates',
            ClickReviewsType: 'Click to reviews type',
            CreateNote: 'Create a note',
            EditNote: 'Edit a note',
            DeleteNote: 'Delete a note',
            AddStatus: 'Add a status',
            AddTag: 'Add Tag',
            UseFilter: 'Use filter',
            ViewGPTReply: 'View GPT reply',
        },
        AutoReplies: {
            CreateRules: 'Create rules',
        },
        ResponseTemplates: {
            CreateTemplate: 'Create a template',
        },
        Tags: {
            CreateTag: 'Create tag',
        },
        Neuronet: {
            EnableNeuronet: 'Enable neuronet',
        },
    },
    ReviewsAnalysis: {
        Dashboard: {
            ViewTable: 'View table',
            ClickButton: 'Click button',
            CompareWithCompetitorFilter: 'Use filter Compare with competitor',
        },
        SearchByReviews: {
            SearchByReviews: 'Search by reviews',
            UseFilter: 'Use filter',
        },
        SelectCountry: 'Select country',
        AnalyzePeriod: 'Select the period to analyze',
    },
    ReviewsGenerator: {
        QRCode: {
            DownloadQRCodeSet: 'Download QR code set',
        },
        NotificationCampaigns: {
            ClickCreateNotificationCampaign: 'Click Create notification campaign',
            ChooseBrand: 'Choose brand',
            SelectClients: 'Select clients',
            FillBasicInformation: 'Fill basic information',
            FillContent: 'Fill content',
            ChooseDesign: 'Choose design',
            CreateNotification: 'Create notification campaign',
            EditNotification: 'Edit notification campaign',
            DeleteNotification: 'Delete notification campaign',
            ClickTopUp: 'Click Top up',
            UseFilter: 'Use filter',
        },
        Clients: {
            AddClient: 'Add a client',
            ImportClient: 'Import client database',
            EditClient: 'Edit client',
            DeleteClient: 'Delete client',
            ClickTopUp: 'Click Top up',
            UseFilter: 'Use filter',
        },
    },
    PhotoTool: {
        CreateReport: 'Send a report',
        MarkAsViewed: 'Mark as viewed',
        UseFilter: 'Use filter',
    },
    OnlinePresence: {
        DownloadReport: 'Download the report',
        ChooseDataOrPeriod: 'Select the period to analyze',
        ClickPeriodButton: 'Select the period to analyze ',
        CompareData: 'Compare data',
        ClickPlus: 'View number of impressions by catalog',
    },
    Posts: {
        Create: 'Send a post',
        Publish: 'Send a post from a draft',
    },
    Products: {
        Create: 'Create a price group',
    },
    DuplicatesAndFakes: {
        ConfirmInfoDeletion: 'Confirm info deletion',
        Trial: {
            ClickButton: 'Click button',
            SendRequest: 'Send a request',
        },
    },
    LocalPack: {
        DownloadReport: 'Download the report',
        CreateRule: 'Create a rule',
        ClickLeaderboard: 'Click leaderboard button',
        DataDistibutionByPeriod: 'Select the distribution for data',
    },
    Tariffs: {
        ActivateTrial: 'Activate trial',
        SendApplicationTariff: 'Send application to extend tariff',
        SendApplicationLimit: 'Send application to increase limit',
        SendApplicationLicense: 'Send application to buy license',
        ClickLink: 'Click link',
    },
    Settings: {
        AccountSettings: {
            AddAccount: 'Add an account',
        },
        GroupOfCompanies: {
            Create: 'Create a group',
            Update: 'Change group info',
            Manage: 'Click Manage a group',
        },
        Integration: {
            Service: 'Click integration source',
        },
        SourceSettings: {
            SuggestSource: 'Suggest a source',
            AddLink: 'Add a link',
            ClickAddLink: 'Click Add link',
            AddLinkToReviewPage: 'Add a link to a review page',
        },
        Notification: {
            EmailTabClick: 'Go to Email notifications',
            TelegramTabClick: 'Go to Telegram notifications',
            BrowserTabClick: 'Go to Browser notifications',
        },
    },
    Knowledge: {
        Search: 'Search',
    },
} as const;

export type AnalyticsDataType = {
    [AnalyticsTarget.PersonalCabinet.Filter]: Array<string>;
    [AnalyticsTarget.PersonalCabinet.Help]: 'Text' | 'Video';
    [AnalyticsTarget.PersonalCabinet.Language]: ShortLocaleNameEnum;
    [AnalyticsTarget.PersonalCabinet.Pagination]: number;
    [AnalyticsTarget.PersonalCabinet.NotificationLink]: string;
    [AnalyticsTarget.PersonalCabinet.NotificationView]: string;

    [AnalyticsTarget.Dashboard.RecommendationCompanies]: string;
    [AnalyticsTarget.Dashboard.RecommendationReviews]: string;
    [AnalyticsTarget.Dashboard.RecommendationQuestions]: string;
    [AnalyticsTarget.Dashboard.RecommendationSources]: string;
    [AnalyticsTarget.Dashboard.RecommendationSetupLinking]: string;

    [AnalyticsTarget.MyCompanies.Create]: 'Old' | 'New';
    [AnalyticsTarget.MyCompanies.Update]: Array<string>;
    [AnalyticsTarget.MyCompanies.EditSelected]: Array<string>;

    [AnalyticsTarget.Sources.CatalogFilter]: Array<string>;
    [AnalyticsTarget.Sources.StatusFilter]: string;

    [AnalyticsTarget.ReviewsManagement.Reviews.ReportDownload]: string;
    [AnalyticsTarget.ReviewsManagement.Reviews.AddResponseTemplate]: number;
    [AnalyticsTarget.ReviewsManagement.Reviews.CreateReview]: string;
    [AnalyticsTarget.ReviewsManagement.Reviews.ReplyToReview]: string;
    [AnalyticsTarget.ReviewsManagement.Reviews.ClickReviewsType]: string;
    [AnalyticsTarget.ReviewsManagement.Reviews.AddStatus]: string;
    [AnalyticsTarget.ReviewsManagement.Reviews.AddTag]: Array<number>;
    [AnalyticsTarget.ReviewsManagement.Reviews.UseFilter]: Array<string>;
    [AnalyticsTarget.ReviewsManagement.Reviews.ViewGPTReply]: number;
    [AnalyticsTarget.ReviewsManagement.AutoReplies.CreateRules]: Array<string>;
    [AnalyticsTarget.ReviewsManagement.ResponseTemplates.CreateTemplate]: string;
    [AnalyticsTarget.ReviewsManagement.Tags.CreateTag]: string;

    [AnalyticsTarget.ReviewsAnalysis.Dashboard.ViewTable]: 'Top 5 employees for the period';
    [AnalyticsTarget.ReviewsAnalysis.Dashboard.ClickButton]: string;
    [AnalyticsTarget.ReviewsAnalysis.Dashboard.CompareWithCompetitorFilter]: string;
    [AnalyticsTarget.ReviewsAnalysis.SearchByReviews.SearchByReviews]: string;
    [AnalyticsTarget.ReviewsAnalysis.SelectCountry]: string;

    [AnalyticsTarget.ReviewsGenerator.QRCode.DownloadQRCodeSet]: string;
    [AnalyticsTarget.ReviewsGenerator.NotificationCampaigns.UseFilter]: Array<string>;

    [AnalyticsTarget.PhotoTool.CreateReport]: Array<string>;
    [AnalyticsTarget.PhotoTool.MarkAsViewed]: string;
    [AnalyticsTarget.PhotoTool.UseFilter]: Array<string>;

    [AnalyticsTarget.OnlinePresence.ChooseDataOrPeriod]: Array<string>;
    [AnalyticsTarget.OnlinePresence.ClickPeriodButton]: string;
    [AnalyticsTarget.OnlinePresence.CompareData]: string;

    [AnalyticsTarget.Posts.Create]: Array<string>;
    [AnalyticsTarget.Posts.Publish]: Array<string>;

    [AnalyticsTarget.Products.Create]: string;

    [AnalyticsTarget.Settings.AccountSettings.AddAccount]: string;
    [AnalyticsTarget.Settings.Integration.Service]: string;

    [AnalyticsTarget.Tariffs.ActivateTrial]: string;
    [AnalyticsTarget.Tariffs.SendApplicationLicense]: string;
    [AnalyticsTarget.Tariffs.SendApplicationTariff]: string;
    [AnalyticsTarget.Tariffs.SendApplicationLimit]: string;
    [AnalyticsTarget.Tariffs.ClickLink]: string;

    [AnalyticsTarget.DuplicatesAndFakes.Trial.SendRequest]: string;
    [AnalyticsTarget.DuplicatesAndFakes.Trial.ClickButton]: string;

    [AnalyticsTarget.LocalPack.DataDistibutionByPeriod]: string;

    [AnalyticsTarget.Knowledge.Search]: string;
};

export function track<TKey extends RecursiveValueOfType<typeof AnalyticsTarget>>(
    ...args: TKey extends keyof AnalyticsDataType ? [key: TKey | string, data: AnalyticsDataType[TKey]] : [key: TKey]
): void {
    const [name, data] = args;

    pushToDataLayer({
        event: AnalyticsEventEnum.RocketdataAction,
        eventAction: name,
        eventLabel: data ?? '',
    });
}

export function useTrackViewRef<
    TRef extends HTMLElement | null,
    TKey extends RecursiveValueOfType<typeof AnalyticsTarget>
>(
    ...args: TKey extends keyof AnalyticsDataType ? [key: TKey, data: AnalyticsDataType[TKey]] : [key: TKey]
): MutableRefObject<TRef> {
    const [name, data] = args;
    const ref = useRef<TRef>(null);
    const isVisible = useIsVisibleOnce(ref);

    useEffect(() => {
        if (!isVisible) {
            return;
        }

        pushToDataLayer({
            event: AnalyticsEventEnum.RocketdataAction,
            eventAction: name,
            eventLabel: data ?? '',
        });
    }, [data, isVisible, name]);

    return ref as MutableRefObject<TRef>;
}
