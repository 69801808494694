import {
    PostFormFieldsEnum,
    PostFormType,
    PostSourceEnum,
    PostTypeEnum,
    SourcePostFormFieldsEnum,
} from '../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../typings/antd';
import {ProvidersIdsEnum} from '../../../../../../util/type';
import {POST_SOURCE_INITIAL_VALUES} from '../target/items/sources/post-source-item-helper';

import {ContentValidationSettingsType, ImageMimeEnum} from './content-step-type';
import {isButtonLinkFieldVisible} from './google-catalog-data/google-catalog-data-helper';

export const GOOGLE_POST_SETTINGS: ContentValidationSettingsType = {
    maxTextLength: 1500,
    images: {
        maxCount: 1,
        maxHeight: 5000,
        maxWidth: 5000,
        minHeight: 400,
        minWidth: 400,
        imageMime: [ImageMimeEnum.JPG, ImageMimeEnum.PNG],
    },
    extra: {
        images: {
            aspectRatio: 'POSTS_REQUIREMENTS__IMAGES__GOOGLE__RULE_1',
        },
        text: {
            noPhone: 'POSTS_REQUIREMENTS__TEXT__GOOGLE__RULE_1',
        },
        action: {
            text: 'POSTS_REQUIREMENTS__CALL_TO_ACTION__GOOGLE__RULE_1',
            link: 'POSTS_REQUIREMENTS__CALL_TO_ACTION__GOOGLE__RULE_2',
        },
        offer: {
            postType: 'POSTS_REQUIREMENTS__OFFER_POST__GOOGLE__RULE1',
        },
    },
    catalogRequirementsLink: 'https://support.google.com/business/answer/7213077',
};

export const YANDEX_POST_SETTINGS: ContentValidationSettingsType = {
    maxTextLength: 3000,
    images: {
        maxCount: 4,
        maxHeight: 5000,
        maxWidth: 5000,
        minHeight: 160,
        minWidth: 400,
        imageMime: [ImageMimeEnum.JPG, ImageMimeEnum.PNG],
    },
    extra: {
        images: {
            aspectRatio: 'POSTS_REQUIREMENTS__IMAGES__YANDEX__RULE_1',
        },
        text: {
            header: 'POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_1',
            violence: 'POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_2',
            letterSpacing: 'POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_3',
            upperCase: 'POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_4',
            link: 'POSTS_REQUIREMENTS__TEXT__YANDEX__RULE_5',
        },
    },
    catalogRequirementsLink:
        'https://yandex.ru/support/business-priority/manage/publications.html#publications__requests',
};

export const VKONTAKTE_POST_SETTINGS: ContentValidationSettingsType = {
    maxTextLength: 5000,
    images: {
        maxCount: 4,
        maxHeight: 5000,
        maxWidth: 5000,
        minHeight: 300,
        minWidth: 400,
        imageMime: [ImageMimeEnum.JPG, ImageMimeEnum.PNG],
    },
    extra: {
        text: {
            header: 'POSTS_REQUIREMENTS__TEXT__VKONTAKTE__RULE_1',
        },
    },
    video: {
        maxDuration: 'POSTS_REQUIREMENTS__VIDEO__RULE_1',
        maxResolution: 'POSTS_REQUIREMENTS__VIDEO__RULE_2',
        maxSize: 'POSTS_REQUIREMENTS__VIDEO__RULE_3',
        videoMime: 'POSTS_REQUIREMENTS__VIDEO__VKONTAKTE__FORMATS',
    },
};

export const FACEBOOK_POST_SETTINGS: ContentValidationSettingsType = {
    maxTextLength: 1500,
    images: {
        maxCount: 4,
        maxHeight: 5000,
        maxWidth: 5000,
        minHeight: 300,
        minWidth: 400,
        imageMime: [ImageMimeEnum.JPG, ImageMimeEnum.PNG],
    },
    video: {
        maxDuration: 'POSTS_REQUIREMENTS__VIDEO__RULE_1',
        maxResolution: 'POSTS_REQUIREMENTS__VIDEO__RULE_2',
        maxSize: 'POSTS_REQUIREMENTS__VIDEO__RULE_3',
        removingVideo: 'POSTS_REQUIREMENTS__VIDEO__FACEBOOK__RULE',
        videoMime: 'POSTS_REQUIREMENTS__VIDEO__FACEBOOK__FORMATS',
    },
};

export const INSTAGRAM_POST_SETTINGS: ContentValidationSettingsType = {
    maxTextLength: 2200,
    images: {
        maxCount: 9,
        maxHeight: 5000,
        maxWidth: 5000,
        minHeight: 300,
        minWidth: 400,
        imageMime: [ImageMimeEnum.JPG, ImageMimeEnum.PNG],
    },
    video: {
        maxDuration: 'POSTS_REQUIREMENTS__VIDEO__RULE_1',
        maxResolution: 'POSTS_REQUIREMENTS__VIDEO__RULE_2',
        maxSize: 'POSTS_REQUIREMENTS__VIDEO__RULE_3',
        videoMime: 'POSTS_REQUIREMENTS__VIDEO__INSTAGRAM__FORMATS',
    },
};

export const POSTS_VALIDATION_SETTINGS: Record<PostSourceEnum, ContentValidationSettingsType> = {
    [PostSourceEnum.google]: GOOGLE_POST_SETTINGS,
    [PostSourceEnum.vkontakte]: VKONTAKTE_POST_SETTINGS,
    [PostSourceEnum.yandex]: YANDEX_POST_SETTINGS,
    [PostSourceEnum.instagram]: INSTAGRAM_POST_SETTINGS,
    [PostSourceEnum.facebook]: FACEBOOK_POST_SETTINGS,
};

export const POST_SOURCE_TO_PROVIDER_ID_MAP: Record<PostSourceEnum, ProvidersIdsEnum> = {
    [PostSourceEnum.google]: ProvidersIdsEnum.google,
    [PostSourceEnum.yandex]: ProvidersIdsEnum.yandex,
    [PostSourceEnum.facebook]: ProvidersIdsEnum.facebook,
    [PostSourceEnum.vkontakte]: ProvidersIdsEnum.vkontakte,
    [PostSourceEnum.instagram]: ProvidersIdsEnum.instagram,
};

export const COUPON_MAX_LENGTH = 16;
export const EVENT_NAME_MAX_LENGTH = 58;
export const REDEEM_URL_MAX_LENGTH = 2048;
export const TERMS_AND_CONDITIONS_MAX_LENGTH = 1024;
export const POST_NAME_MIN_LENGTH = 10;
export const POST_NAME_MAX_LENGTH = 64;

function clearHiddenGoogleFields(formInstance: FormInstance<PostFormType>, postIndex: number): void {
    const type = formInstance.getFieldValue([PostFormFieldsEnum.Posts, postIndex, SourcePostFormFieldsEnum.TopicType]);

    if (!type || ![PostTypeEnum.standard, PostTypeEnum.googleEvent].includes(type)) {
        formInstance.setFieldValue(
            [PostFormFieldsEnum.Posts, postIndex, SourcePostFormFieldsEnum.ButtonType],
            POST_SOURCE_INITIAL_VALUES[SourcePostFormFieldsEnum.ButtonType]
        );

        const buttonType = formInstance.getFieldValue([
            PostFormFieldsEnum.Posts,
            postIndex,
            SourcePostFormFieldsEnum.ButtonType,
        ]);

        if (!isButtonLinkFieldVisible(buttonType)) {
            formInstance.setFieldValue(
                [PostFormFieldsEnum.Posts, postIndex, SourcePostFormFieldsEnum.ButtonLink],
                POST_SOURCE_INITIAL_VALUES[SourcePostFormFieldsEnum.ButtonLink]
            );
        }
    }

    if (!type || ![PostTypeEnum.googleEvent, PostTypeEnum.googleOffer].includes(type)) {
        formInstance.setFieldValue(
            [PostFormFieldsEnum.Posts, postIndex, SourcePostFormFieldsEnum.Event],
            POST_SOURCE_INITIAL_VALUES[SourcePostFormFieldsEnum.Event]
        );
    }

    if (type !== PostTypeEnum.googleOffer) {
        formInstance.setFieldValue(
            [PostFormFieldsEnum.Posts, postIndex, SourcePostFormFieldsEnum.Offer],
            POST_SOURCE_INITIAL_VALUES[SourcePostFormFieldsEnum.Offer]
        );
    }
}

export function clearHiddenCatalogFields(formInstance: FormInstance<PostFormType>): void {
    formInstance.getFieldValue(PostFormFieldsEnum.Posts).forEach((_post, index) => {
        const postCatalog = formInstance.getFieldValue([
            PostFormFieldsEnum.Posts,
            index,
            SourcePostFormFieldsEnum.Catalog,
        ]);

        if (postCatalog === PostSourceEnum.google) {
            clearHiddenGoogleFields(formInstance, index);
        }
    });
}
