import {
    faComment,
    faComments,
    faFileImage,
    faFileLines,
    faLink,
    faListUl,
    faLocationDot,
    faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {appRoute} from '../../../../../app-route';
import {LangKeyType, LocalePluralLangKeyType} from '../../../../../provider/locale/translation/type';
import {AnalyticsTarget} from '../../../../../service/analytics/analytics';
import {CclStatusEnum} from '../../../../../service/ccl-statistic/ccl-statistic-type';
import {DashboardRecommendationTypeEnum} from '../../../../../service/dashboard/dashboard-recommendations/dashboard-recommendations-type';
import {ProvidersIdsEnum} from '../../../../../util/type';
import {objectToUrlParameters} from '../../../../../util/url';
import {SourcesSearchParametersEnum} from '../../../sources/sources-type';

export const recommendationIcons: Record<DashboardRecommendationTypeEnum, JSX.Element> = {
    [DashboardRecommendationTypeEnum.check]: <FontAwesomeIcon icon={faLocationDot} />,
    [DashboardRecommendationTypeEnum.review]: <FontAwesomeIcon icon={faComment} />,
    [DashboardRecommendationTypeEnum.answer]: <FontAwesomeIcon icon={faComments} />,
    [DashboardRecommendationTypeEnum.gallery]: <FontAwesomeIcon icon={faFileImage} />,
    [DashboardRecommendationTypeEnum.description]: <FontAwesomeIcon icon={faFileLines} />,
    [DashboardRecommendationTypeEnum.category]: <FontAwesomeIcon icon={faListUl} />,
    [DashboardRecommendationTypeEnum.needAction]: <FontAwesomeIcon icon={faTriangleExclamation} />,
    [DashboardRecommendationTypeEnum.fbToken]: <FontAwesomeIcon icon={faLink} />,
};
export const recommendationTitles: Record<DashboardRecommendationTypeEnum, LangKeyType> = {
    [DashboardRecommendationTypeEnum.check]: 'DASHBOARD_PAGE__RECOMMENDATION__CHECK_TITLE',
    [DashboardRecommendationTypeEnum.review]: 'DASHBOARD_PAGE__RECOMMENDATION__REVIEW_TITLE',
    [DashboardRecommendationTypeEnum.answer]: 'DASHBOARD_PAGE__RECOMMENDATION__ANSWER_TITLE',
    [DashboardRecommendationTypeEnum.gallery]: 'DASHBOARD_PAGE__RECOMMENDATION__GALLERY_TITLE',
    [DashboardRecommendationTypeEnum.description]: 'DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_TITLE',
    [DashboardRecommendationTypeEnum.category]: 'DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_TITLE',
    [DashboardRecommendationTypeEnum.needAction]: 'DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_TITLE',
    [DashboardRecommendationTypeEnum.fbToken]: 'DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_TITLE',
};
export const recommendationSubtitles: Record<DashboardRecommendationTypeEnum, LangKeyType> = {
    [DashboardRecommendationTypeEnum.check]: 'DASHBOARD_PAGE__RECOMMENDATION__CHECK_SUBTITLE',
    [DashboardRecommendationTypeEnum.review]: 'DASHBOARD_PAGE__RECOMMENDATION__REVIEW_SUBTITLE',
    [DashboardRecommendationTypeEnum.answer]: 'DASHBOARD_PAGE__RECOMMENDATION__ANSWER_SUBTITLE',
    [DashboardRecommendationTypeEnum.gallery]: 'DASHBOARD_PAGE__RECOMMENDATION__GALLERY_SUBTITLE',
    [DashboardRecommendationTypeEnum.description]: 'DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_SUBTITLE',
    [DashboardRecommendationTypeEnum.category]: 'DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_SUBTITLE',
    [DashboardRecommendationTypeEnum.needAction]: 'DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_SUBTITLE',
    [DashboardRecommendationTypeEnum.fbToken]: 'DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_SUBTITLE',
};
export const recommendationDetails: Record<DashboardRecommendationTypeEnum, LangKeyType> = {
    [DashboardRecommendationTypeEnum.check]: 'DASHBOARD_PAGE__RECOMMENDATION__CHECK_DETAILS',
    [DashboardRecommendationTypeEnum.review]: 'DASHBOARD_PAGE__RECOMMENDATION__REVIEW_DETAILS',
    [DashboardRecommendationTypeEnum.answer]: 'DASHBOARD_PAGE__RECOMMENDATION__ANSWER_DETAILS',
    [DashboardRecommendationTypeEnum.gallery]: 'DASHBOARD_PAGE__RECOMMENDATION__GALLERY_DETAILS',
    [DashboardRecommendationTypeEnum.description]: 'DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_DETAILS',
    [DashboardRecommendationTypeEnum.category]: 'DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_DETAILS',
    [DashboardRecommendationTypeEnum.needAction]: 'DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_DETAILS',
    [DashboardRecommendationTypeEnum.fbToken]: 'DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_DETAILS',
};
export const recommendationTagCounterName: Record<DashboardRecommendationTypeEnum, LocalePluralLangKeyType> = {
    [DashboardRecommendationTypeEnum.check]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__MANY',
    },
    [DashboardRecommendationTypeEnum.review]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_REVIEW__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_REVIEW__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_REVIEW__MANY',
    },
    [DashboardRecommendationTypeEnum.answer]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_ANSWER__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_ANSWER__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_ANSWER__MANY',
    },
    [DashboardRecommendationTypeEnum.gallery]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__MANY',
    },
    [DashboardRecommendationTypeEnum.description]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__MANY',
    },
    [DashboardRecommendationTypeEnum.category]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_COMPANY__MANY',
    },
    [DashboardRecommendationTypeEnum.needAction]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_SOURCE__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_SOURCE__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_SOURCE__MANY',
    },
    [DashboardRecommendationTypeEnum.fbToken]: {
        singular: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_ACCOUNT__SINGULAR',
        few: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_ACCOUNT__FEW',
        many: 'DASHBOARD_PAGE__RECOMMENDATION__TAG_ACCOUNT__MANY',
    },
};
export const recommendationLinkText: Record<DashboardRecommendationTypeEnum, LangKeyType> = {
    [DashboardRecommendationTypeEnum.check]: 'DASHBOARD_PAGE__RECOMMENDATION__CHECK_LINK_TEXT',
    [DashboardRecommendationTypeEnum.review]: 'DASHBOARD_PAGE__RECOMMENDATION__REVIEW_LINK_TEXT',
    [DashboardRecommendationTypeEnum.answer]: 'DASHBOARD_PAGE__RECOMMENDATION__ANSWER_LINK_TEXT',
    [DashboardRecommendationTypeEnum.gallery]: 'DASHBOARD_PAGE__RECOMMENDATION__GALLERY_LINK_TEXT',
    [DashboardRecommendationTypeEnum.description]: 'DASHBOARD_PAGE__RECOMMENDATION__DESCRIPTION_LINK_TEXT',
    [DashboardRecommendationTypeEnum.category]: 'DASHBOARD_PAGE__RECOMMENDATION__CATEGORY_LINK_TEXT',
    [DashboardRecommendationTypeEnum.needAction]: 'DASHBOARD_PAGE__RECOMMENDATION__NEED_ACTION_LINK_TEXT',
    [DashboardRecommendationTypeEnum.fbToken]: 'DASHBOARD_PAGE__RECOMMENDATION__FB_TOKEN_LINK_TEXT',
};
export const recommendationLinkUrl: Record<DashboardRecommendationTypeEnum, string> = {
    [DashboardRecommendationTypeEnum.check]: appRoute.myCompanies.path,
    [DashboardRecommendationTypeEnum.review]: appRoute.reviewsManagementReviews.path,
    [DashboardRecommendationTypeEnum.answer]: appRoute.questionsAndAnswers.path,
    [DashboardRecommendationTypeEnum.gallery]: appRoute.myCompanies.path,
    [DashboardRecommendationTypeEnum.description]: appRoute.myCompanies.path,
    [DashboardRecommendationTypeEnum.category]: appRoute.myCompanies.path,
    [DashboardRecommendationTypeEnum.needAction]: `${appRoute.sources.path}?${objectToUrlParameters({
        [SourcesSearchParametersEnum.CclStatus]: CclStatusEnum.actionNeeded,
    })}`,
    [DashboardRecommendationTypeEnum.fbToken]: `${appRoute.sources.path}?${objectToUrlParameters({
        [SourcesSearchParametersEnum.CclStatus]: CclStatusEnum.actionNeeded,
        [SourcesSearchParametersEnum.SourceIdList]: ProvidersIdsEnum.facebook,
    })}`,
};

export const recommendationLinkAnalyticsMap = {
    [DashboardRecommendationTypeEnum.check]: AnalyticsTarget.Dashboard.RecommendationCompanies,
    [DashboardRecommendationTypeEnum.review]: AnalyticsTarget.Dashboard.RecommendationReviews,
    [DashboardRecommendationTypeEnum.answer]: AnalyticsTarget.Dashboard.RecommendationQuestions,
    [DashboardRecommendationTypeEnum.gallery]: AnalyticsTarget.Dashboard.RecommendationCompanies,
    [DashboardRecommendationTypeEnum.description]: AnalyticsTarget.Dashboard.RecommendationCompanies,
    [DashboardRecommendationTypeEnum.category]: AnalyticsTarget.Dashboard.RecommendationCompanies,
    [DashboardRecommendationTypeEnum.needAction]: AnalyticsTarget.Dashboard.RecommendationSources,
    [DashboardRecommendationTypeEnum.fbToken]: AnalyticsTarget.Dashboard.RecommendationSetupLinking,
};
