import {Tabs} from 'antd';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {DomainNameEnum} from '../../../provider/domain-config/domain-config-type';
import {useLocale} from '../../../provider/locale/locale-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';

import {notificationsMap} from './notification-const';
import {NotificationTabKeyEnum} from './notification-type';

function handleTabChange(value: string) {
    // eslint-disable-next-line default-case
    switch (value) {
        case NotificationTabKeyEnum.browser:
            track(AnalyticsTarget.Settings.Notification.BrowserTabClick);
            break;
        case NotificationTabKeyEnum.telegram:
            track(AnalyticsTarget.Settings.Notification.TelegramTabClick);
            break;
        case NotificationTabKeyEnum.email:
            track(AnalyticsTarget.Settings.Notification.EmailTabClick);
            break;
    }
}

export function Notification(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {domainName, notificationTabs} = useDomainConfig();

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__NOTIFICATIONS')} />

            <BreadCrumbs
                list={[
                    {
                        path: appRoute.settingsCompaniesGroup.path,
                        titleLangKey: 'CATEGORY_NAME__NOTIFICATIONS',
                    },
                ]}
            />

            <Tabs
                defaultActiveKey="email-notifications"
                items={notificationTabs
                    .filter(
                        (key) => !(domainName === DomainNameEnum.brandWizard && key === NotificationTabKeyEnum.telegram)
                    )
                    .map((key) => notificationsMap[key])}
                onChange={handleTabChange}
            />
        </Page>
    );
}
