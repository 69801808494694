import {Row} from 'antd';

import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {useDomainConfig} from '../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../provider/locale/localization';

export function LinkBrandLabel(): JSX.Element {
    const {companyName} = useDomainConfig();

    return (
        <Row>
            <Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__LABEL" />
            &nbsp;
            <AdditionalInfo
                title={<Locale stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__LABEL" />}
            >
                <Locale
                    stringKey="SOURCE_SETTINGS__LINKS__LINK_MANAGEMENT__BRAND__SELECT__HINT"
                    valueMap={{companyName}}
                />
            </AdditionalInfo>
        </Row>
    );
}
