import {TextAreaRef} from 'antd/lib/input/TextArea';
import {
    Context,
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {AnalyticsTarget, track} from '../../../../service/analytics/analytics';

import {ReviewActivityContextType} from './review-activity-provider-type';

const ReviewActivityContext: Context<ReviewActivityContextType | null> =
    createContext<ReviewActivityContextType | null>(null);

type PropsType = PropsWithChildren<{
    expandedReplies?: boolean;
}>;

export function ReviewActivityProvider(props: PropsType): JSX.Element {
    const {children, expandedReplies} = props;

    const replyAreaRef = useRef<TextAreaRef>(null);
    const [isScrollEnabled, setIsScrollEnabled] = useState<boolean>(false);
    const [isRepliesVisible, setIsRepliesVisible] = useState<boolean>(true);
    const [isNotesVisible, setIsNotesVisible] = useState<boolean>(false);
    const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false);

    const [isRepliesActive, setIsRepliesActive] = useState<boolean>(Boolean(expandedReplies));

    const scrollToReply = useCallback(() => {
        if (!replyAreaRef.current) {
            throw new Error('>> There is no any TextArea in the ref');
        }

        replyAreaRef.current.focus({preventScroll: true, cursor: 'end'});
        replyAreaRef.current.resizableTextArea?.textArea?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        });
    }, []);

    useEffect(() => {
        if (isScrollEnabled && isRepliesVisible) {
            scrollToReply();
        }
    }, [isScrollEnabled, isRepliesVisible, scrollToReply]);

    const value: ReviewActivityContextType = useMemo(
        () => ({
            replyAreaRef,
            isRepliesVisible,
            isNotesVisible,
            isHistoryVisible,
            isRepliesActive,
            onReplyAliasPress: () => {
                if (isRepliesVisible) {
                    scrollToReply();
                } else {
                    setIsScrollEnabled(true);
                    setIsRepliesVisible(true);
                    setIsRepliesActive(true);
                    setIsNotesVisible(false);
                }
            },
            onRepliesActivityPress: () => {
                setIsScrollEnabled(false);
                setIsNotesVisible(false);
                setIsHistoryVisible(false);
                setIsRepliesActive((previous) => !previous);
                setIsRepliesVisible(true);
            },
            onNewReplyCancelPress: () => {
                setIsRepliesVisible(false);
            },
            onNotesActivityPress: () => {
                setIsNotesVisible(true);
                setIsRepliesVisible(false);
                setIsHistoryVisible(false);
                setIsRepliesActive(false);
            },
            onHistoryActivityPress: () => {
                setIsHistoryVisible(true);
                setIsRepliesVisible(false);
                setIsNotesVisible(false);
                setIsRepliesActive(false);

                track(AnalyticsTarget.ReviewsManagement.Reviews.ViewHistory);
            },
        }),
        [isRepliesVisible, isNotesVisible, isHistoryVisible, isRepliesActive, scrollToReply]
    );

    return <ReviewActivityContext.Provider value={value}>{children}</ReviewActivityContext.Provider>;
}

export function useReviewActivityHook(): ReviewActivityContextType {
    const context: ReviewActivityContextType | null = useContext(ReviewActivityContext);

    if (!context) {
        throw new Error('useReviewActivityHook must be used within a ReviewActivityProvider');
    }

    return context;
}
