import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, DatePicker, Drawer, Form, Radio, Row, Select, Space} from 'antd';
import Search from 'antd/lib/input/Search';
import {useCallback, useEffect, useState} from 'react';

import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useTechnicalSupportFields} from '../../../../../../service/technical-support/technical-support-hook';
import {
    TechnicalSupportFieldsEnum,
    TechnicalSupportFilterDrawerType,
    TechnicalSupportFilterType,
} from '../../../../../../service/technical-support/technical-support-type';
import {useDrawerWidthHack} from '../../../../../../util/antd/drawer-hook';

import {TECHNICAL_SUPPORT_FILTER_FORM_ID} from './technical-support-filter-helper';
import * as styles from './technical-support-filter.scss';

const {RangePicker} = DatePicker;

type PropsType = {
    filter: TechnicalSupportFilterType;
    updateFilter: (values: Partial<TechnicalSupportFilterType>) => void;
    resetFilter: () => void;
};

export function TechnicalSupportFilterControls(props: PropsType): JSX.Element {
    const {filter, updateFilter, resetFilter} = props;
    const drawerWidth: string = useDrawerWidthHack();

    const [form] = Form.useForm<TechnicalSupportFilterDrawerType>();
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

    const hasNewAnswers = Form.useWatch(TechnicalSupportFieldsEnum.HasNewAnswers, form);

    const {data: technicalSupportData} = useTechnicalSupportFields();

    const {getLocalizedString} = useLocale();

    const onFormOpen = useCallback((): void => {
        form.resetFields();
        setIsFormOpen(true);
    }, [form, setIsFormOpen]);

    const onFormClose = useCallback((): void => {
        form.resetFields();
        setIsFormOpen(false);
    }, [form, setIsFormOpen]);

    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        updateFilter({
            q: searchText,
        });
    }, [searchText, updateFilter]);

    const onResetForm = useCallback((): void => {
        resetFilter();
        setSearchText('');
        setIsFormOpen(false);
    }, [resetFilter]);

    const onSubmit = useCallback(
        (formValues: TechnicalSupportFilterDrawerType): void => {
            // const {startOfFromDate, endOfToDate} = getDatesFromRangeToFilter(formValues.creationDate);

            updateFilter({
                ...formValues,
                creationDate: formValues.creationDate,
                q: '',
            });
            setIsFormOpen(false);
        },
        [updateFilter]
    );

    return (
        <div className={styles.TechnicalSupportFilterRow_filterControls}>
            <Search
                allowClear
                className={styles.TechnicalSupportFilterRow_searchArea}
                onSearch={(searchInputValue) => setSearchText(searchInputValue)}
                placeholder={getLocalizedString('HELP_TECHNICAL_SUPPORT__FILTER__SEARCH__PLACEHOLDER')}
            />
            <Button icon={<FontAwesomeIcon icon={faFilter} />} onClick={onFormOpen} />

            <Drawer
                footer={
                    <Row justify="end">
                        <Space>
                            <Button onClick={onResetForm}>
                                <Locale stringKey="TEXT__RESET" />
                            </Button>

                            <Button form={TECHNICAL_SUPPORT_FILTER_FORM_ID} htmlType="submit" type="primary">
                                <Locale stringKey="TEXT__APPLY" />
                            </Button>
                        </Space>
                    </Row>
                }
                onClose={onFormClose}
                open={isFormOpen}
                placement="right"
                title={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__TITLE" />}
                width={drawerWidth}
            >
                <Form<TechnicalSupportFilterDrawerType>
                    form={form}
                    id={TECHNICAL_SUPPORT_FILTER_FORM_ID}
                    initialValues={filter}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__STATUS__LABEL" />}
                        name={TechnicalSupportFieldsEnum.Status}
                    >
                        <Select
                            fieldNames={{label: 'name'}}
                            mode="multiple"
                            optionFilterProp="name"
                            optionLabelProp="name"
                            options={technicalSupportData?.status || []}
                            placeholder={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__STATUS__PLACEHOLDER" />}
                            showSearch={false}
                        />
                    </Form.Item>
                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__DATE__LABEL" />}
                        name="creationDate"
                    >
                        <RangePicker className={styles.TechnicalSupportFilterRow_rangePicker} format="YYYY-MM-DD" />
                    </Form.Item>

                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__TYPE__LABEL" />}
                        name={TechnicalSupportFieldsEnum.Type}
                    >
                        <Select
                            defaultActiveFirstOption
                            fieldNames={{label: 'name'}}
                            optionFilterProp="name"
                            optionLabelProp="name"
                            options={[
                                {
                                    name: getLocalizedString('HELP_TECHNICAL_SUPPORT__FILTER__TYPE__ANY'),
                                    value: null,
                                },
                                ...(technicalSupportData?.type || []),
                            ]}
                            placeholder={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__TYPE__PLACEHOLDER" />}
                        />
                    </Form.Item>

                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__CLIENT_CATEGORY__LABEL" />}
                        name={TechnicalSupportFieldsEnum.ClientCategory}
                    >
                        <Select
                            fieldNames={{label: 'name'}}
                            optionFilterProp="name"
                            optionLabelProp="name"
                            options={[
                                {
                                    name: getLocalizedString('HELP_TECHNICAL_SUPPORT__FILTER__CLIENT_CATEGORY__ANY'),
                                    value: null,
                                },
                                ...(technicalSupportData?.clientCategory || []),
                            ]}
                            placeholder={
                                <Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__CLIENT_CATEGORY__PLACEHOLDER" />
                            }
                        />
                    </Form.Item>

                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__VISIBILITY__LABEL" />}
                        name={TechnicalSupportFieldsEnum.IsPublic}
                    >
                        <Select
                            defaultActiveFirstOption
                            fieldNames={{label: 'name'}}
                            optionFilterProp="name"
                            optionLabelProp="name"
                            options={[
                                {
                                    name: getLocalizedString('HELP_TECHNICAL_SUPPORT__FILTER__VISIBILITY__ANY'),
                                    value: null,
                                },
                                ...(technicalSupportData?.isPublic || []),
                            ]}
                            placeholder={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__VISIBILITY__LABEL" />}
                        />
                    </Form.Item>

                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__HAS_NEW_ANSWERS__LABEL" />}
                        name={TechnicalSupportFieldsEnum.HasNewAnswers}
                    >
                        <Radio.Group
                            className={styles.TechnicalSupportFilterRow_hasNewAnswers}
                            optionType="button"
                            size="small"
                        >
                            {[
                                {
                                    value: true,
                                    label: getLocalizedString('TEXT__YES'),
                                },
                                {
                                    value: false,
                                    label: getLocalizedString('TEXT__NO'),
                                },
                            ].map((option) => (
                                <Radio
                                    key={String(option.value)}
                                    onClick={() => {
                                        if (hasNewAnswers === option.value) {
                                            form.setFieldsValue({[TechnicalSupportFieldsEnum.HasNewAnswers]: null});
                                            return;
                                        }

                                        form.setFieldsValue({[TechnicalSupportFieldsEnum.HasNewAnswers]: option.value});
                                    }}
                                    value={option.value}
                                >
                                    <span title={option.label}>{option.label}</span>
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item<TechnicalSupportFilterDrawerType>
                        label={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__CREATOR__LABEL" />}
                        name={TechnicalSupportFieldsEnum.Creator}
                    >
                        <Select
                            fieldNames={{label: 'name'}}
                            mode="multiple"
                            optionFilterProp="name"
                            optionLabelProp="name"
                            options={technicalSupportData?.creator || []}
                            placeholder={<Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__CREATOR__PLACEHOLDER" />}
                            showSearch
                        />
                    </Form.Item>
                    {Boolean(technicalSupportData?.creatorGroup.length) && (
                        <Form.Item<TechnicalSupportFilterDrawerType> name={TechnicalSupportFieldsEnum.CreatorGroup}>
                            <Select
                                defaultActiveFirstOption
                                fieldNames={{label: 'name'}}
                                mode="multiple"
                                optionFilterProp="name"
                                optionLabelProp="name"
                                options={[
                                    {
                                        disabled: true,
                                        name: getLocalizedString('HELP_TECHNICAL_SUPPORT__FILTER__CREATOR_GROUP__ANY'),
                                        value: null,
                                    },
                                    ...(technicalSupportData?.creatorGroup || []),
                                ]}
                                placeholder={
                                    <Locale stringKey="HELP_TECHNICAL_SUPPORT__FILTER__CREATOR_GROUP__PLACEHOLDER" />
                                }
                                showSearch
                            />
                        </Form.Item>
                    )}
                </Form>
            </Drawer>
        </div>
    );
}
