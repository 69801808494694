export const enGeneralInfoTranslations = {
    GENERAL_INFO__LABEL__COMPANY_NAME: 'Company name',
    GENERAL_INFO__LABEL__SENDING_COMPANY_NAME: 'Sending company name (except Google)',
    GENERAL_INFO__PLACEHOLDER__DISPLAYED_COMPANY_NAME: 'My company',
    GENERAL_INFO__PLACEHOLDER__SENDING_COMPANY_NAME: 'Sending name',
    GENERAL_INFO__LABEL__ALTERNATIVE_NAMES: 'Alternative company names',
    GENERAL_INFO__LABEL__ALTERNATIVE_NAMES_INFO: 'Alternative names',
    GENERAL_INFO__PLACEHOLDER__ALTERNATIVE_NAMES: 'Alternative name',
    GENERAL_INFO__HINT__ALTERNATIVE_NAMES: 'How can they look for your company differently? (comma separated)',
    GENERAL_INFO__HINT__SENDING_COMPANY_NAME: 'Moderated name for catalogs other than Google.',
    GENERAL_INFO__HINT__SENDING_COMPANY_NAME__DETAILS: 'Details',
    GENERAL_INFO__HINT__SENDING_COMPANY_NAME__WAITING_FOR_MODERATION: 'Waiting for moderation...',
    GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM: 'Type of business',
    GENERAL_INFO__LABEL__ORGANIZATIONAL_AND_LEGAL_FORM__SELECT: 'Select type',
    GENERAL_INFO__PLACEHOLDER__ORGANIZATIONAL_AND_LEGAL_FORM: 'Ltd',
    GENERAL_INFO__LABEL__LEGAL_NAME: 'Legal name',
    GENERAL_INFO__PLACEHOLDER__LEGAL_NAME: 'Horns and hooves',
    GENERAL_INFO__LABEL__SEARCH_CATEGORIES: 'Search categories',
    GENERAL_INFO__ADDITIONAL_INFO__MAIN_CATEGORY__HEADER: 'Main category',
    GENERAL_INFO__ADDITIONAL_INFO__MAIN_CATEGORY__TEXT:
        'One category can be the main one. To sort others, drag out the mouse.',
};
