import {Button, Checkbox, Form, Input, Row, Select} from 'antd';
import {useCallback, useEffect, useMemo} from 'react';

import {appRoute} from '../../../app-route';
import {AdditionalInfo} from '../../../layout/additional-info/additional-info';
import {Locale} from '../../../provider/locale/locale';
import {useLocale} from '../../../provider/locale/locale-hook';
import {useModal} from '../../../provider/modal/modal-hook';
import {useSnackbar} from '../../../provider/snackbar/snackbar-hook';
import {AnalyticsTarget, track} from '../../../service/analytics/analytics';
import {useFormRules} from '../../../service/form/form-rules-hook';
import {
    fetchTemplateCreate,
    fetchTemplateDelete,
    fetchTemplateUpdate,
    ResponseTemplateDataType,
    ResponseTemplateGroupType,
    useResponseTemplateGroupList,
} from '../../../service/reviews/reviews-response-templates';
import {ErrorWithStatus, isErrorHasStatusMessage} from '../../../util/error';
import {sortCompare} from '../../../util/string';
import {useUrl} from '../../../util/url-hook/url-hook';

import {ResponseTemplatesFormType} from './response-templates-form-type';
import * as styles from './response-templates-form.scss';

const {Option} = Select;
const {TextArea} = Input;

type PropsType = {
    mode: 'edit' | 'create';
    className?: string;
    responseTemplate: ResponseTemplateDataType;
};

// eslint-disable-next-line max-statements
export function ResponseTemplatesForm(props: PropsType): JSX.Element {
    const {className, mode, responseTemplate} = props;
    const [form] = Form.useForm();
    const {maxLengthFieldRule, minLengthFieldRule, requiredFieldRule} = useFormRules();
    const {getLocalizedString, shortLocaleName} = useLocale();
    const {pushUrl, hasBack, goBack} = useUrl();
    const {snackbar} = useSnackbar();
    const {modal} = useModal();

    const isEdit = mode === 'edit';
    const isCreate = mode === 'create';
    const fieldNameTitle = 'title';
    const fieldNameText = 'text';
    const fieldNameGroup = 'group';
    const fieldNameIsPrivate = 'isPrivate';

    const fillFormWithDefaultValues = useCallback(() => {
        const definedValue: ResponseTemplatesFormType = {
            title: responseTemplate.title,
            text: responseTemplate.message,
            group: String(responseTemplate.folder?.id || ''),
            isPrivate: !responseTemplate.isPrivate,
        };

        form.setFieldsValue(definedValue);
    }, [form, responseTemplate]);

    useEffect(() => {
        fillFormWithDefaultValues();
    }, [fillFormWithDefaultValues]);

    const {data: templateGroupListResult, isFetching: templateGroupListIsInProgress} = useResponseTemplateGroupList();

    const sortedGroupsList = useMemo(
        () =>
            (templateGroupListResult || []).sort(
                (templateA: ResponseTemplateGroupType, templateB: ResponseTemplateGroupType): number =>
                    sortCompare(shortLocaleName, templateA.title, templateB.title)
            ),
        [shortLocaleName, templateGroupListResult]
    );

    const updateTag = useCallback(
        async (data: ResponseTemplateDataType, id: number) => {
            const result = await fetchTemplateUpdate(data, id);

            if (result instanceof Error) {
                snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__UPDATING_ERROR" />);
            } else {
                snackbar.success({
                    key: 'saved-successfully',
                    message: <Locale stringKey="TEXT__SAVED" />,
                    description: <Locale stringKey="RESPONSE_TEMPLATES__FORM__THE_TEMPLATE_WAS_SAVED_SUCCESSFULLY" />,
                });

                if (hasBack) {
                    goBack();
                } else {
                    pushUrl(appRoute.reviewsManagementResponseTemplates.path);
                }
            }
        },
        [snackbar, hasBack, goBack, pushUrl]
    );

    const onFinishSuccess = useCallback(async () => {
        const folder = form.getFieldValue(fieldNameGroup);
        const data = {
            ...responseTemplate,
            title: form.getFieldValue(fieldNameTitle),
            folder: folder.length > 0 ? {id: form.getFieldValue(fieldNameGroup), title: ''} : null,
            message: form.getFieldValue(fieldNameText),
            isPrivate: !form.getFieldValue(fieldNameIsPrivate),
        };

        if (isCreate) {
            const tagGroupTitle =
                templateGroupListResult &&
                templateGroupListResult.find((tagGroup) => data.folder && tagGroup.id === Number(folder));

            track(
                AnalyticsTarget.ReviewsManagement.ResponseTemplates.CreateTemplate,
                tagGroupTitle?.title || getLocalizedString('TEXT__WITHOUT_GROUP')
            );

            try {
                await fetchTemplateCreate(data);

                snackbar.success({
                    key: 'saved-successfully',
                    message: <Locale stringKey="TEXT__SAVED" />,
                    description: <Locale stringKey="RESPONSE_TEMPLATES__FORM__THE_TEMPLATE_WAS_SAVED_SUCCESSFULLY" />,
                });
                pushUrl(appRoute.reviewsManagementResponseTemplates.path);
            } catch (error) {
                if (isErrorHasStatusMessage<ErrorWithStatus>(error)) {
                    snackbar.error(error.jsonData.status);
                } else {
                    snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__CREATING_ERROR" />);
                }
            }
        } else {
            updateTag(data, responseTemplate.id);
        }
    }, [form, responseTemplate, isCreate, templateGroupListResult, getLocalizedString, snackbar, pushUrl, updateTag]);

    function onFinishFailed() {
        snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__EMPTY_ERROR" />);
    }

    const handleDelete = useCallback(() => {
        const groupTitleList = form.getFieldValue(fieldNameTitle);

        modal.confirm({
            maskClosable: true,
            title: <Locale stringKey="RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE_GROUP__HEADER" />,
            content: (
                <Locale
                    stringKey="RESPONSE_TEMPLATES__REMOVING_A_TEMPLATE_GROUP__CONTENT"
                    valueMap={{
                        responseTemplateTitle: groupTitleList,
                    }}
                />
            ),
            okText: <Locale stringKey="TEXT__APPLY" />,
            cancelText: <Locale stringKey="POPUP__BUTTON__CANCEL" />,
            cancelButtonProps: {type: 'primary'},
            okButtonProps: {type: 'default', danger: true},
            onOk: async () => {
                try {
                    await fetchTemplateDelete({
                        ids: responseTemplate.id,
                    });

                    snackbar.success(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_SUCCESS" />);
                    pushUrl(appRoute.reviewsManagementResponseTemplates.path);
                } catch (removeError) {
                    if (!(removeError instanceof Error)) {
                        return snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_ERROR" />);
                    }

                    try {
                        const errors = JSON.parse(removeError.message);

                        if (Array.isArray(errors)) {
                            const hasUsedTemplates = errors.some((error) => error.rules.length > 0);

                            if (hasUsedTemplates) {
                                snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_USED_ERROR" />);

                                // eslint-disable-next-line no-undefined, unicorn/no-useless-undefined
                                return undefined;
                            }
                        }

                        snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_ERROR" />);
                    } catch {
                        snackbar.error(<Locale stringKey="RESPONSE_TEMPLATES__DELETING_ERROR" />);
                    }
                }

                // eslint-disable-next-line no-undefined, unicorn/no-useless-undefined
                return undefined;
            },
        });
    }, [form, modal, responseTemplate.id, snackbar, pushUrl]);

    return (
        <div className={className}>
            <Form
                className={styles.ResponseTemplatesForm}
                form={form}
                layout="vertical"
                name="response-template"
                onFinish={onFinishSuccess}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={<Locale stringKey="RESPONSE_TEMPLATES__FORM__TITLE" />}
                    name={fieldNameTitle}
                    rules={[requiredFieldRule, minLengthFieldRule(2), maxLengthFieldRule(255)]}
                >
                    <Input
                        placeholder={getLocalizedString('RESPONSE_TEMPLATES__FORM__ENTER_A_NAME_FOR_THE_RULE')}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    label={
                        <Row>
                            <Locale stringKey="RESPONSE_TEMPLATES__FORM__TEXT" />
                            &nbsp;
                            <AdditionalInfo
                                title={<Locale stringKey="RESPONSE_TEMPLATES__FORM__SUBSTITUTION_PATTERNS" />}
                            >
                                <p className={styles.pre}>
                                    <Locale stringKey="RESPONSE_TEMPLATES__FORM__SUBSTITUTION_PATTERNS__CONTENT" />
                                </p>
                            </AdditionalInfo>
                        </Row>
                    }
                    name={fieldNameText}
                    rules={[requiredFieldRule, minLengthFieldRule(4), maxLengthFieldRule(2500)]}
                >
                    <TextArea
                        placeholder={getLocalizedString('RESPONSE_TEMPLATES__FORM__ENTER_A_DESCRIPTION')}
                        rows={7}
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    initialValue=""
                    label={<Locale stringKey="RESPONSE_TEMPLATES__FORM__GROUP" />}
                    name={fieldNameGroup}
                >
                    <Select<string>
                        disabled={templateGroupListIsInProgress}
                        loading={templateGroupListIsInProgress}
                        size="large"
                    >
                        <Option key="no-select-data" value="">
                            <Locale stringKey="TEXT__WITHOUT_GROUP" />
                        </Option>
                        {sortedGroupsList.map(
                            (responseTemplateData: ResponseTemplateGroupType): JSX.Element => (
                                <Option key={responseTemplateData.id} value={String(responseTemplateData.id)}>
                                    {responseTemplateData.title}
                                </Option>
                            )
                        ) || null}
                    </Select>
                </Form.Item>

                <Form.Item name={fieldNameIsPrivate} valuePropName="checked">
                    <Checkbox>
                        <Row>
                            <Locale stringKey="RESPONSE_TEMPLATES__FORM__PUBLIC" />
                            &nbsp;
                            <AdditionalInfo title={<Locale stringKey="RESPONSE_TEMPLATES__FORM__COMMAND_TEMPLATE" />}>
                                <Locale stringKey="RESPONSE_TEMPLATES__FORM__TEAM_TEMPLATE_CAN_BE_USED_BY_YOUR_COLLEAGUES" />
                            </AdditionalInfo>
                        </Row>
                    </Checkbox>
                </Form.Item>

                <div className={styles.ResponseTemplatesForm_buttons}>
                    <Button htmlType="submit" type="primary">
                        <Locale stringKey="BUTTON__SAVE" />
                    </Button>

                    <Button
                        htmlType="reset"
                        onClick={() => (hasBack ? goBack() : pushUrl(appRoute.reviewsManagementResponseTemplates.path))}
                    >
                        <Locale stringKey="BUTTON__RESET_CHANGES" />
                    </Button>

                    {isEdit && (
                        <Button
                            className={styles.ResponseTemplatesForm_button__delete}
                            danger
                            onClick={handleDelete}
                            type="text"
                        >
                            <Locale stringKey="RESPONSE_TEMPLATES__FORM__DELETE_TEMPLATE" />
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
}
