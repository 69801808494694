import {AdditionalInfo} from '../../../../../../layout/additional-info/additional-info';
import {CatalogConfigType} from '../../../../../../provider/catalogs/catalogs-type';
import {useDocumentationLinks} from '../../../../../../provider/help-links/help-links-hook';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {Locale} from '../../../../../../provider/locale/localization';
import {CompanyKeyEnum} from '../../../../../../service/company-v2/company-const';
import {CompanyFormType} from '../../../../../../service/company-v2/company-type';
import {Form} from '../../../../../../typings/antd';
import {CatalogConfigWithIndexType, CatalogList} from '../../catalog-list/catalog-list';
import {getErrorTabIndex} from '../../catalog-list/catalog-list-helper';

import {CatalogContactInfo} from './catalog-contact-info';
import {EmailFormItem} from './email-form-item/email-form-item';
import {PhonesFormItem} from './phones-form-item/phones-form-item';

const FIRST_PHONE_ACCESSOR = [CompanyKeyEnum.Phones, 0, 'phone'] as [CompanyKeyEnum.Phones, 0, 'phone'];

type PropsType = {
    name: string;
    hasInitialPhones: boolean;
    initialCatalogs?: Array<number>;
};

export function ContactInfoWidget(props: PropsType): JSX.Element {
    const {name, hasInitialPhones, initialCatalogs} = props;

    const {getLocalizedString} = useLocale();

    const documentationLinks = useDocumentationLinks();

    return (
        <>
            <PhonesFormItem hasInitialPhones={hasInitialPhones} name={CompanyKeyEnum.Phones} required />

            <EmailFormItem name={CompanyKeyEnum.Email} />

            <Form.Item<CompanyFormType> noStyle shouldUpdate={(_previousValues, _nextValues, info) => !info.source}>
                {({getFieldValue, setFieldsValue, getFieldsError}) => (
                    <CatalogList
                        activeTabIndex={getErrorTabIndex(getFieldsError, [
                            CompanyKeyEnum.ExtraPhones,
                            CompanyKeyEnum.ExtraEmails,
                        ])}
                        disabled={!getFieldValue(FIRST_PHONE_ACCESSOR)}
                        hint={
                            <AdditionalInfo title={<Locale stringKey="COMPANY_FORM__CONTACT_INFO__CATALOGS_HEADER" />}>
                                <Locale
                                    stringKey="COMPANY_FORM__CONTACT_INFO__CATALOGS_HINT"
                                    valueMap={{
                                        link: documentationLinks && (
                                            <p>
                                                <a
                                                    href={documentationLinks.company.contactsFromCatalogs}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <Locale stringKey="GENERAL_INFO__HINT__SENDING_COMPANY_NAME__DETAILS" />
                                                </a>
                                            </p>
                                        ),
                                    }}
                                />
                            </AdditionalInfo>
                        }
                        initialCatalogs={initialCatalogs}
                        isCatalogVisible={({canRewritePhones}) => canRewritePhones}
                        onAddCatalogs={(catalogs: Array<CatalogConfigType>) => {
                            setFieldsValue({
                                extraPhones: [
                                    ...getFieldValue(CompanyKeyEnum.ExtraPhones),
                                    ...catalogs.map(({catalogId}) => ({
                                        catalogId,
                                        phones: getFieldValue(CompanyKeyEnum.Phones),
                                    })),
                                ],
                                extraEmails: [
                                    ...getFieldValue(CompanyKeyEnum.ExtraEmails),
                                    ...catalogs.map(({catalogId}) => ({
                                        catalogId,
                                        email: getFieldValue(CompanyKeyEnum.Email),
                                    })),
                                ],
                            });
                        }}
                        onCatalogAvailabilityChange={(availableCatalogIds: Array<number>) => {
                            setFieldsValue({
                                extraPhones: getFieldValue(CompanyKeyEnum.ExtraPhones).filter(({catalogId}) =>
                                    availableCatalogIds.includes(catalogId)
                                ),
                                extraEmails: getFieldValue(CompanyKeyEnum.ExtraEmails).filter(({catalogId}) =>
                                    availableCatalogIds.includes(catalogId)
                                ),
                            });
                        }}
                        renderCatalog={(catalog: CatalogConfigWithIndexType, {handleRemoveCatalog}) => (
                            <CatalogContactInfo
                                catalog={catalog}
                                handleRemoveCatalog={handleRemoveCatalog}
                                hasInitialPhones={Boolean(
                                    getFieldValue([CompanyKeyEnum.ExtraPhones, catalog.index, 'phones'])
                                )}
                                name={name}
                            />
                        )}
                        title={getLocalizedString('COMPANY_FORM__CONTACT_INFO__CATALOGS_HEADER')}
                    />
                )}
            </Form.Item>
        </>
    );
}
