import {useCatalogInfo} from '../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../provider/locale/locale';
import {AggregatedActionsType, AggregatedActionType} from '../../../../../service/dashboard/dashboard-type';
import {useFormat} from '../../../../../util/format-hook/format-hook';
import {timelineActionLocalizationMap} from '../timeline-card/timeline-card-const';

import {actionsWithSupplemented} from './timeline-aggregated-card-const';
import * as styles from './timeline-aggregated-card.scss';

type PropsType = {
    timelineData: AggregatedActionsType;
};

export function TimelineAggregatedCard(props: PropsType): JSX.Element {
    const {getFormattedDateTime, getFormattedNumber} = useFormat();
    const {timelineData} = props;
    const startDateTime = getFormattedDateTime(new Date(timelineData.timestamp.start), {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: 'numeric',
    });
    const endDateTime = getFormattedDateTime(new Date(timelineData.timestamp.end), {
        hour: '2-digit',
        minute: 'numeric',
    });

    const {getCatalogName} = useCatalogInfo();

    return (
        <>
            <div className={styles.TimelineCard_header}>
                {startDateTime} - {endDateTime}
            </div>
            <div className={styles.TimelineCard_comment}>
                <ul className={styles.TimelineCard_commentContainer}>
                    {timelineData.actions.map((actionData: AggregatedActionType): JSX.Element => {
                        return (
                            <li
                                className={styles.TimelineCard_actionItem}
                                key={`${timelineData.timestamp.start}-${actionData.action}-${actionData.catalog?.id}`}
                            >
                                <div className={styles.TimelineCard_actionWrapper}>
                                    <p className={styles.TimelineCard_actionText}>
                                        <Locale
                                            stringKey={timelineActionLocalizationMap[actionData.action]}
                                            valueMap={{catalogName: getCatalogName(actionData.catalog?.id)}}
                                        />
                                    </p>
                                    {actionsWithSupplemented.includes(actionData.action) ? (
                                        <p className={styles.TimelineCard_actionBadge__filled}>
                                            <span className={styles.TimelineCard_actionBadgeText__filled}>
                                                +{getFormattedNumber(actionData.count)}
                                            </span>
                                        </p>
                                    ) : (
                                        <p className={styles.TimelineCard_actionBadge}>
                                            <span className={styles.TimelineCard_actionBadgeText}>
                                                {getFormattedNumber(actionData.count)}
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}
