import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {z as r} from 'zod';

import {fetchAndDeserialize} from '../../util/api-adapter';

import {fakesUrl} from './fakes-const';

const fakesStatusesSchema = r.object({
    newFakes: r.number(),
    newDuplicates: r.number(),
    moderation: r.number(),
    claimSent: r.number(),
    needAction: r.number(),
    isTrial: r.boolean(),
    processed: r.number(),
    preprocessing: r.number(),
    hasDoneSearches: r.boolean(),
    filtered: r.number().optional(),
    totalSearchCount: r.number().optional(),
    minNextProcessingDate: r.string().optional(),
    redundantLocation: r.number().optional(),
});

type FakesStatusesType = r.infer<typeof fakesStatusesSchema>;

type FakesStatusesHookReturnType = UseQueryResult<
    FakesStatusesType & {
        isGoodResult: boolean;
        isSearching: boolean;
        hasSearchData: boolean;
        newAndNeedAction: number;
        allCompaniesCount: number;
    }
>;

const fakesStatusesUrl = `${fakesUrl}/statuses/`;

function fetchFakesStatuses(): Promise<FakesStatusesType> {
    return fetchAndDeserialize(fakesStatusesUrl, fakesStatusesSchema);
}

export function useFakesStatuses(): FakesStatusesHookReturnType {
    return useQuery([fakesStatusesUrl], () => fetchFakesStatuses(), {
        select: (data) => {
            const isSearching = data ? data.preprocessing > 0 && !data.hasDoneSearches : false;
            const newAndNeedAction = data ? data?.newFakes + data?.newDuplicates + data?.needAction : 0;
            const isGoodResult = data
                ? data.preprocessing === 0 && data.hasDoneSearches && newAndNeedAction === 0
                : false;

            const hasSearchData = data ? newAndNeedAction + data.moderation + data.claimSent > 0 : false;
            const allCompaniesCount =
                newAndNeedAction +
                data.claimSent +
                data.moderation +
                data.preprocessing +
                data.processed +
                (data.redundantLocation || 0);

            return {...data, isGoodResult, isSearching, hasSearchData, newAndNeedAction, allCompaniesCount};
        },
    });
}
