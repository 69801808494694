import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Table, TablePaginationConfig} from 'antd';
import {SorterResult} from 'antd/es/table/interface';
import {FilterValue, SortOrder} from 'antd/lib/table/interface';
import {useCallback, useEffect, useState} from 'react';

import {Text} from '../../../../../../component/text/text';
import {Trend} from '../../../../../../layout/trend/trend';
import {useCatalogInfo} from '../../../../../../provider/catalogs/catalogs-hook';
import {Locale} from '../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {useUser} from '../../../../../../provider/user/user-hook';
import {
    RatingDynamicsCompaniesStatsItemDataType,
    RatingsDynamicsCatalogFilterValuesEnum,
    RatingsDynamicsCatalogSortingValuesEnum,
    RatingsDynamicsFilterQueryParametersEnum,
    RatingsDynamicsFilterType,
    RatingsDynamicsYandexBaseStatusValuesEnum,
    RatingsDynamicsYandexTargetStatusValuesEnum,
    YandexCompanyStatusEnum,
} from '../../../../../../service/reivew-analysis/rating-dynamics/reviews-analytics-ratings-dynamic-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {PaginationHookType} from '../../../../../../util/pagination-hook/pagination-hook-type';
import {PROVIDER_TO_ITS_ID_MAP, ProvidersEnum, ProvidersIdsEnum} from '../../../../../../util/type';

import {CompanyRatingHistoryChart} from './chart/company-rating-history-chart';
import {RatingHistoryAddressFilterDropdown} from './rating-history-address-filter-dropdown';
import {RatingHistoryCatalogFilterDropdown} from './rating-history-catalog-filter-dropdown';
import {RatingHistoryCodeFilterDropdown} from './rating-history-code-filter-dropdown';
import {RatingHistoryExpandButton} from './rating-history-expand-button';
import {RatingHistoryNameFilterDropdown} from './rating-history-name-filter-dropdown';
import {YandexActualizationStatusMark} from './yandex-actualization-status-mark/yandex-actualization-status-mark';
import * as styles from './rating-history-table.scss';

type PropsType = {
    filter: RatingsDynamicsFilterType;
    updateFilter: (
        filterData: Record<string, FilterValue | null>,
        sorterResult: {
            provider: string;
            sortValue: RatingsDynamicsCatalogSortingValuesEnum;
        } | null
    ) => void;
    data: Array<RatingDynamicsCompaniesStatsItemDataType>;
    pagination: PaginationHookType;
    isFetchingData: boolean;
};

export function RatingHistoryTable(props: PropsType): JSX.Element {
    const {filter, updateFilter, data, pagination, isFetchingData} = props;

    const {user} = useUser();

    const {getCatalogName} = useCatalogInfo();
    const {getFormattedNumber} = useFormat();
    const {getLocalizedString} = useLocale();

    const [expandedRows, setExpandedRows] = useState<Array<React.Key>>([]);

    useEffect(() => {
        setExpandedRows(
            data.slice(0, 2).map((record) => {
                return record.companyId;
            })
        );
    }, [data]);

    const getCatalogStats = useCallback(
        (
            catalogId: ProvidersIdsEnum,
            companyData: RatingDynamicsCompaniesStatsItemDataType
        ): {
            base: number;
            target: number;
            delta: number;
            baseActualizationStatus?: YandexCompanyStatusEnum;
            targetActualizationStatus?: YandexCompanyStatusEnum;
        } | null => {
            return companyData.ratings.find((ratingItem) => ratingItem.catalog === catalogId) || null;
        },
        []
    );

    const getSortOrder = useCallback(
        (
            provider: ProvidersEnum.google | ProvidersEnum.yandex | ProvidersEnum.doubleGis,
            column: 'baseRating' | 'targetRating'
        ): SortOrder => {
            if (column === 'baseRating') {
                if (filter[provider]?.includes(RatingsDynamicsCatalogSortingValuesEnum.BaseRatingAsc)) {
                    return 'ascend';
                } else if (filter[provider]?.includes(RatingsDynamicsCatalogSortingValuesEnum.BaseRatingDesc)) {
                    return 'descend';
                }

                return null;
            }

            if (filter[provider].includes(RatingsDynamicsCatalogSortingValuesEnum.TargetRatingAsc)) {
                return 'ascend';
            } else if (filter[provider].includes(RatingsDynamicsCatalogSortingValuesEnum.TargetRatingDesc)) {
                return 'descend';
            }

            return null;
        },
        [filter]
    );

    const onTableChange = useCallback(
        // eslint-disable-next-line complexity
        (
            newPaginationState: TablePaginationConfig,
            tableFilterState: Record<string, FilterValue | null>,
            sorter:
                | SorterResult<RatingDynamicsCompaniesStatsItemDataType>
                | Array<SorterResult<RatingDynamicsCompaniesStatsItemDataType>>
            // eslint-disable-next-line sonarjs/cognitive-complexity
        ) => {
            if (newPaginationState.current !== pagination.page || newPaginationState.pageSize !== pagination.pageSize) {
                pagination.onChange(newPaginationState.current || 1, newPaginationState.pageSize);
                return;
            }

            const {columnKey, order} = Array.isArray(sorter) ? sorter[0] || {} : sorter;

            const [, providerName, column] = String(columnKey).match(/^([\w.]+)_(targetRating|baseRating)$/) || [];

            updateFilter(
                tableFilterState,
                order && providerName && column
                    ? {
                          provider: providerName,
                          sortValue:
                              column === 'targetRating'
                                  ? order === 'ascend'
                                      ? RatingsDynamicsCatalogSortingValuesEnum.TargetRatingAsc
                                      : RatingsDynamicsCatalogSortingValuesEnum.TargetRatingDesc
                                  : order === 'ascend'
                                  ? RatingsDynamicsCatalogSortingValuesEnum.BaseRatingAsc
                                  : RatingsDynamicsCatalogSortingValuesEnum.BaseRatingDesc,
                      }
                    : null
            );
        },
        [pagination, updateFilter]
    );

    const expandedRowRender = useCallback(
        (row: {companyId: string}) => {
            return <CompanyRatingHistoryChart companyId={row.companyId} filter={filter} />;
        },
        [filter]
    );

    return (
        <div className={styles.RatingHistoryTable_wrapper}>
            <Text
                block
                stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__TOTAL_COMPANIES"
                valueMap={{
                    totalCount: pagination.total,
                }}
            />
            <Table<RatingDynamicsCompaniesStatsItemDataType>
                bordered
                dataSource={data || []}
                expandable={{
                    expandedRowRender,
                    expandIcon: RatingHistoryExpandButton,
                    expandedRowKeys: expandedRows,
                    onExpandedRowsChange: (expandedKeys) => {
                        setExpandedRows([...expandedKeys]);
                    },
                }}
                loading={isFetchingData}
                onChange={onTableChange}
                pagination={{
                    size: 'default',
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
                rowKey={(record: RatingDynamicsCompaniesStatsItemDataType) => record.companyId}
                scroll={{
                    x: true,
                }}
            >
                <Table.Column<RatingDynamicsCompaniesStatsItemDataType>
                    align="center"
                    filterDropdown={RatingHistoryNameFilterDropdown}
                    filterIcon={<FontAwesomeIcon icon={faSearch} />}
                    filterSearch
                    filteredValue={
                        filter[RatingsDynamicsFilterQueryParametersEnum.Name]
                            ? [filter[RatingsDynamicsFilterQueryParametersEnum.Name]]
                            : []
                    }
                    key={RatingsDynamicsFilterQueryParametersEnum.Name}
                    render={(row: RatingDynamicsCompaniesStatsItemDataType): JSX.Element => (
                        <div className={styles.RatingHistoryTable_name}>{row.name}</div>
                    )}
                    title={<Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__NAME" />}
                    width={200}
                />
                <Table.Column<RatingDynamicsCompaniesStatsItemDataType>
                    align="center"
                    filterDropdown={RatingHistoryAddressFilterDropdown}
                    filterIcon={<FontAwesomeIcon icon={faSearch} />}
                    filterSearch
                    filteredValue={
                        filter[RatingsDynamicsFilterQueryParametersEnum.Address]
                            ? [filter[RatingsDynamicsFilterQueryParametersEnum.Address]]
                            : []
                    }
                    key={RatingsDynamicsFilterQueryParametersEnum.Address}
                    render={(row: RatingDynamicsCompaniesStatsItemDataType): JSX.Element => (
                        <div className={styles.RatingHistoryTable_address}>{row.address}</div>
                    )}
                    title={<Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__ADDRESS" />}
                    width={400}
                />
                <Table.Column<RatingDynamicsCompaniesStatsItemDataType>
                    align="center"
                    filterDropdown={RatingHistoryCodeFilterDropdown}
                    filterIcon={<FontAwesomeIcon icon={faSearch} />}
                    filterSearch
                    filteredValue={
                        filter[RatingsDynamicsFilterQueryParametersEnum.Code]
                            ? [filter[RatingsDynamicsFilterQueryParametersEnum.Code]]
                            : []
                    }
                    key={RatingsDynamicsFilterQueryParametersEnum.Code}
                    render={(row: RatingDynamicsCompaniesStatsItemDataType): JSX.Element => (
                        <div className={styles.RatingHistoryTable_name}>{row.code}</div>
                    )}
                    title={<Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CODE" />}
                    width={200}
                />
                {([ProvidersEnum.google, ProvidersEnum.yandex, ProvidersEnum.doubleGis] as const)
                    .filter((provider) => user?.regionAvailableCatalogs[PROVIDER_TO_ITS_ID_MAP[provider]])
                    .map((provider) => {
                        return (
                            <Table.ColumnGroup
                                filterDropdown={RatingHistoryCatalogFilterDropdown}
                                filteredValue={filter[provider].filter((filterValueItem) =>
                                    Object.values(RatingsDynamicsCatalogFilterValuesEnum)
                                        .map(String)
                                        .includes(filterValueItem)
                                )}
                                filters={[
                                    {
                                        text: getLocalizedString(
                                            'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CATALOG_FILTER__RATING_DROP'
                                        ),
                                        value: RatingsDynamicsCatalogFilterValuesEnum.RatingDrop,
                                    },
                                    {
                                        text: getLocalizedString(
                                            'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CATALOG_FILTER__RATING_GROW'
                                        ),
                                        value: RatingsDynamicsCatalogFilterValuesEnum.RatingIncreased,
                                    },
                                    {
                                        text: getLocalizedString(
                                            'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__CATALOG_FILTER__RATING_UNCHANGED'
                                        ),
                                        value: RatingsDynamicsCatalogFilterValuesEnum.RatingUnchanged,
                                    },
                                ]}
                                key={provider}
                                title={getCatalogName(PROVIDER_TO_ITS_ID_MAP[provider])}
                            >
                                <Table.Column<RatingDynamicsCompaniesStatsItemDataType>
                                    align="center"
                                    filteredValue={
                                        provider === ProvidersEnum.yandex
                                            ? filter[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter].filter(
                                                  (filterValueItem) =>
                                                      Object.values(RatingsDynamicsYandexBaseStatusValuesEnum)
                                                          .map(String)
                                                          .includes(filterValueItem)
                                              )
                                            : []
                                    }
                                    filters={
                                        provider === ProvidersEnum.yandex
                                            ? [
                                                  {
                                                      text: getLocalizedString(
                                                          'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__ACTUALIZED'
                                                      ),
                                                      value: RatingsDynamicsYandexBaseStatusValuesEnum.Actualized,
                                                  },
                                                  {
                                                      text: getLocalizedString(
                                                          'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__NOT_ACTUALIZED'
                                                      ),
                                                      value: RatingsDynamicsYandexBaseStatusValuesEnum.NotActualized,
                                                  },
                                              ]
                                            : // eslint-disable-next-line no-undefined
                                              undefined
                                    }
                                    key={`${provider}_baseRating`}
                                    render={(
                                        _value: unknown,
                                        rowData: RatingDynamicsCompaniesStatsItemDataType
                                    ): JSX.Element => {
                                        const catalogRowValues = getCatalogStats(
                                            PROVIDER_TO_ITS_ID_MAP[provider],
                                            rowData
                                        );

                                        const value = catalogRowValues?.base;

                                        return (
                                            <div className={styles.RatingHistoryTable_ratingCellContainer}>
                                                {value
                                                    ? getFormattedNumber(value, {
                                                          maximumFractionDigits: 1,
                                                      })
                                                    : '–'}
                                                {provider === ProvidersEnum.yandex && (
                                                    <YandexActualizationStatusMark
                                                        status={catalogRowValues?.baseActualizationStatus}
                                                    />
                                                )}
                                            </div>
                                        );
                                    }}
                                    sortOrder={getSortOrder(provider, 'baseRating')}
                                    sorter
                                    title={<Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__BASE_RATING" />}
                                />
                                <Table.Column<RatingDynamicsCompaniesStatsItemDataType>
                                    align="center"
                                    filteredValue={
                                        provider === ProvidersEnum.yandex
                                            ? filter[RatingsDynamicsFilterQueryParametersEnum.YandexMarksFilter].filter(
                                                  (filterValueItem) =>
                                                      Object.values(RatingsDynamicsYandexTargetStatusValuesEnum)
                                                          .map(String)
                                                          .includes(filterValueItem)
                                              )
                                            : []
                                    }
                                    filters={
                                        provider === ProvidersEnum.yandex
                                            ? [
                                                  {
                                                      text: getLocalizedString(
                                                          'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__ACTUALIZED'
                                                      ),
                                                      value: RatingsDynamicsYandexTargetStatusValuesEnum.Actualized,
                                                  },
                                                  {
                                                      text: getLocalizedString(
                                                          'REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__YANDEX_STATUS__NOT_ACTUALIZED'
                                                      ),
                                                      value: RatingsDynamicsYandexTargetStatusValuesEnum.NotActualized,
                                                  },
                                              ]
                                            : // eslint-disable-next-line no-undefined
                                              undefined
                                    }
                                    key={`${provider}_targetRating`}
                                    render={(
                                        _value: unknown,
                                        rowData: RatingDynamicsCompaniesStatsItemDataType
                                    ): JSX.Element => {
                                        const catalogRowValues = getCatalogStats(
                                            PROVIDER_TO_ITS_ID_MAP[provider],
                                            rowData
                                        );
                                        const baseValue = catalogRowValues?.base;
                                        const value = catalogRowValues?.target;
                                        const delta = catalogRowValues?.delta;

                                        return (
                                            <div className={styles.RatingHistoryTable_ratingCellContainer}>
                                                <div>
                                                    {value
                                                        ? getFormattedNumber(value, {
                                                              maximumFractionDigits: 1,
                                                          })
                                                        : '–'}
                                                </div>
                                                {value && (
                                                    <div>
                                                        <Trend delta={baseValue && delta ? delta : 0} unit="" />
                                                    </div>
                                                )}
                                                {provider === ProvidersEnum.yandex && (
                                                    <YandexActualizationStatusMark
                                                        status={catalogRowValues?.targetActualizationStatus}
                                                    />
                                                )}
                                            </div>
                                        );
                                    }}
                                    sortOrder={getSortOrder(provider, 'targetRating')}
                                    sorter
                                    title={
                                        <Locale stringKey="REVIEWS_ANALYSIS__RATINGS_DYNAMIC_TABLE__TARGET_RATING" />
                                    }
                                />
                            </Table.ColumnGroup>
                        );
                    })}
            </Table>
        </div>
    );
}
