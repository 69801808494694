import {Form, Input} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {Dayjs} from 'dayjs';
import {useCallback} from 'react';

import {useDomainConfig} from '../../../../../../../../provider/domain-config/domain-config-hook';
import {Locale} from '../../../../../../../../provider/locale/locale';
import {useLocale} from '../../../../../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {PostFormType} from '../../../../../../../../service/posts/posts-types';
import {FormInstance} from '../../../../../../../../typings/antd';
import * as postFormStyles from '../../../../post-form.scss';
import {PostFormItemPropsType} from '../../../../post-form-type';
import {COUPON_MAX_LENGTH, REDEEM_URL_MAX_LENGTH, TERMS_AND_CONDITIONS_MAX_LENGTH} from '../../content-step-helper';

type PropsType = {
    fieldName: Array<string | number>;
    formInstance: FormInstance<PostFormType>;
} & PostFormItemPropsType;

export function OfferItem(props: PropsType): JSX.Element {
    const {fieldName, formInstance, disabled} = props;

    const {getLocalizedString} = useLocale();

    const {validUrlRule, maxLengthFieldRule} = useFormRules();

    const {domainName} = useDomainConfig();

    const couponCode: Dayjs | null = Form.useWatch([...fieldName, 'couponCode'], formInstance);
    const redeemOnlineUrl: Dayjs | null = Form.useWatch([...fieldName, 'redeemOnlineUrl'], formInstance);
    const termsConditions: Dayjs | null = Form.useWatch([...fieldName, 'termsConditions'], formInstance);

    const atLeastOneOfOfferFieldsEntered = useCallback(() => {
        return {
            validator: (): Promise<void> => {
                if (couponCode || redeemOnlineUrl || termsConditions) {
                    return Promise.resolve();
                }

                return Promise.reject(
                    new Error(getLocalizedString('POSTS_FORM__TYPE__OFFER__SELECT_AT_LEAST_SOMETHING'))
                );
            },
        };
    }, [couponCode, getLocalizedString, redeemOnlineUrl, termsConditions]);

    return (
        <Form.Item>
            <Form.Item
                className={postFormStyles.PostForm_countedInputItem}
                initialValue=""
                label={<Locale stringKey="POSTS_FORM__TYPE__COUPON__LABEL" />}
                name={[...fieldName, 'couponCode']}
                rules={!disabled ? [atLeastOneOfOfferFieldsEntered, maxLengthFieldRule(COUPON_MAX_LENGTH)] : []}
            >
                <Input
                    disabled={disabled}
                    maxLength={COUPON_MAX_LENGTH}
                    placeholder={getLocalizedString('POSTS_FORM__TYPE__COUPON__PLACEHOLDER')}
                    showCount
                />
            </Form.Item>
            <Form.Item
                className={postFormStyles.PostForm_countedInputItem}
                initialValue=""
                label={<Locale stringKey="POSTS_FORM__TYPE__REDEEM_URL__LABEL" />}
                name={[...fieldName, 'redeemOnlineUrl']}
                rules={[atLeastOneOfOfferFieldsEntered, validUrlRule, maxLengthFieldRule(REDEEM_URL_MAX_LENGTH)]}
            >
                <Input
                    disabled={disabled}
                    maxLength={REDEEM_URL_MAX_LENGTH}
                    placeholder={getLocalizedString('POSTS_FORM__TYPE__REDEEM_URL__PLACEHOLDER', {
                        domainName,
                    })}
                    showCount
                />
            </Form.Item>
            <Form.Item
                className={postFormStyles.PostForm_textAreaItem}
                initialValue=""
                label={<Locale stringKey="POSTS_FORM__TYPE__TERMS_AND_CONDITIONS__LABEL" />}
                name={[...fieldName, 'termsConditions']}
                rules={[atLeastOneOfOfferFieldsEntered, maxLengthFieldRule(TERMS_AND_CONDITIONS_MAX_LENGTH)]}
            >
                <TextArea
                    disabled={disabled}
                    maxLength={TERMS_AND_CONDITIONS_MAX_LENGTH}
                    placeholder={getLocalizedString('POSTS_FORM__TYPE__TERMS_AND_CONDITIONS__PLACEHOLDER')}
                    showCount={{
                        formatter: (formatterOptions) =>
                            `${formatterOptions.value.length}/${formatterOptions.maxLength}`,
                    }}
                />
            </Form.Item>
        </Form.Item>
    );
}
