import {faCircleQuestion, faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';
import {ReactNode, useMemo} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {useDocumentationLinks} from '../../../../../../../provider/help-links/help-links-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {useUser} from '../../../../../../../provider/user/user-hook';
import {CclDataType} from '../../../../../../../service/ccl/ccl-type';
import {StatisticCclsForCompanyType} from '../../../../../../../service/company/company-type';
import {TimeSizeEnum} from '../../../../../../../util/format';
import {useFormat} from '../../../../../../../util/format-hook/format-hook';
import {CclCatalogStatusEnum, CclStateStatusEnum} from '../../../../../../../util/type';
import {cclOnboardingTranslationMap} from '../onboarding-popover/onboarding-popover-const';
import {getStatusByCclAndUser} from '../status-column-helper';

import {cclErrorTranslationMap} from './status-column-popover-const';
import * as styles from './status-column-popover.scss';

type PropsType = {
    cclData?: CclDataType | StatisticCclsForCompanyType;
    isConfirmation: boolean;
    isLinking: boolean;
    iconFromCompaniesPage?: ReactNode;
    syncButton?: ReactNode;
    needYandexTransferAccount?: boolean;
};

// eslint-disable-next-line complexity
export function StatusColumnPopover(props: PropsType): JSX.Element | null {
    const {cclData, isConfirmation, isLinking, iconFromCompaniesPage, syncButton, needYandexTransferAccount} = props;

    const {getFormattedDateTime} = useFormat();
    const {user} = useUser();
    const documentationLinks = useDocumentationLinks();

    // eslint-disable-next-line complexity
    const isVisible = useMemo(() => {
        if (isConfirmation || isLinking) {
            return true;
        }

        if (cclData && 'catalog' in cclData && cclData?.catalog?.type === 'navigator') {
            return false;
        }

        const hasOriginUrl = cclData && 'originUrl' in cclData && cclData?.originUrl !== null;
        const hasCatalogStatusDate = cclData?.catalogStatusDate !== null;
        const isSyncCclConditionMatched =
            cclData?.stateStatus === CclStateStatusEnum.sync &&
            [CclCatalogStatusEnum.correct, CclCatalogStatusEnum.incorrect, null].includes(cclData.verificationStatus);

        const isConditionByVerificationStatusMatched =
            isSyncCclConditionMatched ||
            cclData?.verificationStatus === CclCatalogStatusEnum.notFound ||
            cclData?.stateStatus === CclStateStatusEnum.send;

        return hasOriginUrl && hasCatalogStatusDate && isConditionByVerificationStatusMatched;
    }, [cclData, isLinking, isConfirmation]);

    if (!isVisible && !iconFromCompaniesPage && !needYandexTransferAccount) {
        return null;
    }

    const isShowAdditionalInfoWithIcon = !iconFromCompaniesPage
        ? true
        : cclData && 'catalogStatus' in cclData && cclData?.catalogStatus !== CclCatalogStatusEnum.unsupportedRegion;

    function getContentDate() {
        if (!cclData?.catalogStatusDate || isLinking || cclData.hiddenOnYandexMaps) {
            return null;
        }

        return (
            <Text
                secondary
                stringKey={
                    isConfirmation
                        ? 'SOURCES__STATUS__POPOVER__ACTION_REQUIRED__DATE'
                        : 'SOURCES__STATUS__POPOVER__SYNCHRONIZED__DATE'
                }
                valueMap={{
                    date: getFormattedDateTime(new Date(cclData.catalogStatusDate), {
                        [TimeSizeEnum.year]: 'numeric',
                        [TimeSizeEnum.month]: 'short',
                        [TimeSizeEnum.day]: 'numeric',
                        [TimeSizeEnum.hour]: 'numeric',
                        [TimeSizeEnum.minute]: 'numeric',
                    }),
                }}
            />
        );
    }

    function getContentText() {
        if (cclData?.hiddenOnYandexMaps) {
            return (
                <Locale
                    stringKey="SOURCES__STATUS__HIDE__DETAILS"
                    valueMap={{
                        link: (
                            <a href={documentationLinks.yandex.sourcesHide} rel="noreferrer" target="_blank">
                                <Locale stringKey="GENERAL_INFO__HINT__SENDING_COMPANY_NAME__DETAILS" />
                            </a>
                        ),
                    }}
                />
            );
        }

        if (isLinking) {
            return (
                <>
                    <Locale stringKey="SOURCES__STATUS__POPOVER__ACTION_REQUIRED" />
                    {syncButton}
                </>
            );
        }

        if (isConfirmation) {
            return (
                <>
                    <Locale stringKey="SOURCES__STATUS__POPOVER__ACTION_REQUIRED__CONFIRMATION" />
                    {syncButton}
                </>
            );
        }

        if (cclData && cclData.errors.length > 0) {
            return (
                <>
                    <Text
                        className={styles.SourcesTableStatusPopover_error}
                        stringKey="SOURCES__STATUS__POPOVER__ERRORS"
                    />

                    <ul className={styles.SourcesTableStatusPopover_errorsList}>
                        {cclData.errors.map((error) => (
                            <li key={error}>
                                <Locale stringKey={cclErrorTranslationMap[error]} />
                            </li>
                        ))}
                    </ul>
                </>
            );
        }

        return (
            cclData?.stateStatus !== CclStateStatusEnum.send &&
            isShowAdditionalInfoWithIcon && (
                <>
                    <Locale stringKey="SOURCES__STATUS__POPOVER__SYNCHRONIZED" />
                    <ul className={styles.SourcesTableStatusPopover_errorsList}>
                        {Object.values(cclOnboardingTranslationMap).map((status) => (
                            <li key={status}>
                                <Locale stringKey={status} />
                            </li>
                        ))}
                    </ul>
                </>
            )
        );
    }

    return (
        <Popover
            content={
                <div className={styles.SourcesTableStatusPopover_content}>
                    {cclData &&
                        ((iconFromCompaniesPage && cclData?.stateStatus !== CclStateStatusEnum.sync) ||
                            needYandexTransferAccount) && (
                            <span>
                                {needYandexTransferAccount && !iconFromCompaniesPage ? (
                                    ''
                                ) : (
                                    <Text
                                        bolder
                                        stringKey={getStatusByCclAndUser(cclData, user, needYandexTransferAccount)}
                                    />
                                )}
                                {needYandexTransferAccount && iconFromCompaniesPage ? (
                                    <>
                                        {' '}
                                        /{' '}
                                        <Text
                                            bolder
                                            stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__BUTTON__GET_ACCESS"
                                        />
                                    </>
                                ) : (
                                    ''
                                )}
                            </span>
                        )}
                    {(isShowAdditionalInfoWithIcon || needYandexTransferAccount) && getContentDate()}
                    {!needYandexTransferAccount && getContentText()}
                    {needYandexTransferAccount && !iconFromCompaniesPage && (
                        <Locale stringKey="MY_COMPANIES__YANDEX_TRANSFER_ACCOUNT__PENDING" />
                    )}
                    {needYandexTransferAccount && syncButton}
                    {!needYandexTransferAccount && cclData?.stateStatus === CclStateStatusEnum.sync && (
                        <a href={documentationLinks.sources.status} rel="noreferrer" target="_blank">
                            <Locale stringKey="SOURCES__ONBOARDING__POPOVER__STATUS" />
                        </a>
                    )}
                </div>
            }
            title={
                cclData?.hiddenOnYandexMaps && (
                    <>
                        /<Locale stringKey="SOURCES__STATUS__HIDE" />
                    </>
                )
            }
            trigger="hover"
        >
            {iconFromCompaniesPage ??
                (cclData?.hiddenOnYandexMaps ? (
                    <FontAwesomeIcon
                        className={styles.SourcesTableStatusPopover_alertIcon}
                        icon={faTriangleExclamation}
                    />
                ) : (
                    <FontAwesomeIcon className={styles.SourcesTableStatusPopover_infoIcon} icon={faCircleQuestion} />
                ))}
        </Popover>
    );
}
