import {Button} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FunctionComponent, useState} from 'react';

import {Locale} from '../../provider/locale/localization';
import {LangKeyType} from '../../provider/locale/translation/type';
import {
    SelectorCompanyType,
    UseCompaniesSelectorHookType,
} from '../../service/feature-companies/feature-companies-type';
import {CompanyPopupV2} from '../company-popup-v2/company-popup-v2';
import {CompanySelectAdditionalDataColumnPropsType} from '../company-popup-v2/items/company-select-item-type';

import * as styles from './company-multi-select-v2.scss';

type PropsType<CompanyType extends SelectorCompanyType = SelectorCompanyType> = {
    id?: string;
    isDisabled?: boolean;
    selectedCountLangKey?: LangKeyType;
    companiesSelector: UseCompaniesSelectorHookType<CompanyType>;
    className?: string;
    renderItem?: FunctionComponent<CompanySelectAdditionalDataColumnPropsType<CompanyType>>;
    renderFilter?: JSX.Element;
    renderAdditionalControl?: JSX.Element;
    columns?: ColumnsType<CompanyType>;
    onOk?: () => void;
    showCounter?: boolean;
    hasError?: boolean;
};

export function CompanyMultiSelectV2<CompanyType extends SelectorCompanyType = SelectorCompanyType>(
    props: PropsType<CompanyType>
): JSX.Element {
    const {
        companiesSelector,
        className,
        id,
        renderItem,
        renderFilter,
        renderAdditionalControl,
        onOk,
        selectedCountLangKey = 'COMPANY__MULTISELECT__SELECTED_COUNT',
        showCounter = true,
        isDisabled = false,
        columns,
        hasError,
    } = props;

    const {selectedCount} = companiesSelector;

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    function onSubmit() {
        setIsPopupOpen(false);

        onOk?.();
    }

    function onOpenSelectorModal() {
        setIsPopupOpen(true);
    }

    return (
        <div className={className} id={id}>
            <Button danger={hasError} disabled={false} onClick={onOpenSelectorModal}>
                <Locale stringKey="COMPANY__MULTISELECT__BUTTON__LABEL" />
            </Button>
            {showCounter && (
                <span className={styles.company_multi_select_count}>
                    <Locale stringKey={selectedCountLangKey} valueMap={{count: selectedCount}} />
                </span>
            )}

            <CompanyPopupV2<CompanyType>
                columns={columns}
                companiesSelector={companiesSelector}
                disabled={isDisabled}
                isOpen={isPopupOpen}
                onCloseModal={() => setIsPopupOpen(false)}
                onOk={onSubmit}
                renderAdditionalControl={renderAdditionalControl}
                renderFilter={renderFilter}
                renderItem={renderItem}
            />
        </div>
    );
}
