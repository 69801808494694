import {Typography} from 'antd';
import {useEffect} from 'react';
import {ExtractRouteParams, generatePath, useParams} from 'react-router';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {Locale} from '../../../../provider/locale/localization';
import {useCompany} from '../../../../service/company/company-hook';
import {useYandexConfirmationSuggests} from '../../../../service/confirmation/confirmation-hook';
import {useUrl} from '../../../../util/url-hook/url-hook';
import {YandexGetCode} from '../confirmations/get-code/yandex-get-code';

import * as styles from './source-confirmation.scss';

const {Text, Title} = Typography;

export function YandexConfirmation(): JSX.Element {
    const {getLocalizedString} = useLocale();

    const {sources, sourcesYandexConfirmation, sourcesConfirmations} = appRoute;

    const {pushUrl} = useUrl();

    const {companyId, brandId} = useParams<ExtractRouteParams<typeof sourcesYandexConfirmation.path, string>>();

    const {suggest, loadSuggest, isInProgress: isSuggestIsInProgress} = useYandexConfirmationSuggests();

    useEffect(() => {
        loadSuggest({
            brandId: Number(brandId),
            companyId: Number(companyId),
        });
    }, [brandId, companyId, loadSuggest]);

    const {result: company, isInProgress: isCompanyIsInProgress} = useCompany(Number(companyId));

    const isInProgress = isSuggestIsInProgress || isCompanyIsInProgress;

    return (
        <Page isTopFilterHidden>
            <Meta title={getLocalizedString('CATEGORY_NAME__SOURCES_CONFIRMATION__GOOGLE')} />

            <BreadCrumbs
                list={[
                    {path: sources.path, titleLangKey: 'CATEGORY_NAME__SOURCES'},
                    {path: sourcesConfirmations.path, titleLangKey: 'SOURCES_CONFIRMATION__BREADCRUMBS'},
                    {
                        path: generatePath(sourcesYandexConfirmation.path, {
                            brandId,
                            companyId,
                        }),
                        titleLangKey: 'CATEGORY_NAME__SOURCES_CONFIRMATION__YANDEX',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="CATEGORY_NAME__SOURCES_CONFIRMATION__YANDEX" />
            </PageHeader>

            <>
                {isInProgress && <Spinner position="absolute" />}

                {company && suggest && (
                    <div className={styles.confirmation_data__wrapper}>
                        <div className={styles.confirmation_data__company_info}>
                            <Title level={4}>
                                <Locale stringKey="SOURCES_CONFIRMATION__COMPANY_INFORMATION" />
                            </Title>

                            <Text strong>{company.name}</Text>
                            <Text>{company.categories.map((category) => category.name).join(', ')}</Text>
                            <Text>{company.address.string_address}</Text>
                            <Text>{company.phones.map((phoneItem) => phoneItem.phone).join(', ')}</Text>
                        </div>

                        {suggest.yandexChainSuggest.chains.map((chain) => {
                            return (
                                <div className={styles.confirmation_data__item} key={chain.permanentId}>
                                    <div className={styles.confirmation_data__item__company_description}>
                                        <Text strong>{chain.name}</Text>
                                        <Text>{chain.categories.join(', ')}</Text>
                                        {chain.phones?.[0] && <Text type="secondary">{chain.phones?.[0]}</Text>}
                                    </div>
                                    <div>
                                        <YandexGetCode
                                            brandId={Number(brandId)}
                                            buttonTextKey="SOURCES_CONFIRMATION__CONFIRM_CHAIN__BUTTON"
                                            catalogId={chain.permanentId}
                                            catalogType="brand"
                                            extraInformation={chain.extra}
                                            onSubmitSuccess={() =>
                                                pushUrl(sourcesConfirmations.path, {isSaveQuery: true})
                                            }
                                            phones={chain.phones}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        <Title level={4}>
                            <Locale stringKey="SOURCES_CONFIRMATION__SUGGESTED_OPTIONS" />
                        </Title>

                        {suggest.yandexCompanySuggest.companies.map((suggestCompany) => {
                            return (
                                <div className={styles.confirmation_data__item} key={suggestCompany.permanentId}>
                                    <div className={styles.confirmation_data__item__company_description}>
                                        <Text strong>{suggestCompany.name}</Text>
                                        <Text>{suggestCompany.categories.join(', ')}</Text>
                                        <Text>{suggestCompany.address}</Text>
                                    </div>
                                    <div>
                                        <YandexGetCode
                                            brandId={Number(brandId)}
                                            buttonTextKey="SOURCES_CONFIRMATION__CONFIRM__BUTTON"
                                            catalogId={Number(suggestCompany.permanentId)}
                                            companyId={Number(companyId)}
                                            onSubmitSuccess={() =>
                                                pushUrl(sourcesConfirmations.path, {isSaveQuery: true})
                                            }
                                            phones={suggestCompany.phones}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </>
        </Page>
    );
}
