import {LangKeyType} from '../../../../../provider/locale/translation/type';
import {ConfirmationStatusEnum} from '../../../../../provider/onboarding/onboarding-hook';

export function getGoogleConfirmCompaniesDescription(status?: ConfirmationStatusEnum): LangKeyType {
    if (status === ConfirmationStatusEnum.NeedConfirmation) {
        return 'ONBOARDING__FOURTH_STEP__CATALOGS__NEED_ACTION__DESCRIPTION';
    }

    if (status === ConfirmationStatusEnum.InProgress) {
        return 'ONBOARDING__FOURTH_STEP__GOOGLE__IN_PROGRESS__DESCRIPTION';
    }

    return 'ONBOARDING__FOURTH_STEP__CATALOGS__SUCCESS__DESCRIPTION';
}

export function getYandexConfirmCompaniesDescription(status?: ConfirmationStatusEnum): LangKeyType {
    if (status === ConfirmationStatusEnum.NeedConfirmation) {
        return 'ONBOARDING__FOURTH_STEP__YANDEX__DESCRIPTION';
    }

    if (status === ConfirmationStatusEnum.InProgress) {
        return 'ONBOARDING__FOURTH_STEP__YANDEX__IN_PROGRESS__DESCRIPTION';
    }

    return 'ONBOARDING__FOURTH_STEP__CATALOGS__SUCCESS__DESCRIPTION';
}
