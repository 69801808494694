import {z as r} from 'zod';

import {CursorPaginationHookType} from '../../util/cursor-pagination/cursor-pagination-type';
import {PaginatedResponseType} from '../api/api-type';
import {UseHookType} from '../api-hook/api-hook-type';
import {ReviewGeneratorRatingScaleTypeEnum} from '../reviews-generator/questionnaire/review-generator-questionnaire-type';

const negativeReviewClientSchema = r.object({
    id: r.number(),
    name: r.string(),
    phone: r.string(),
    email: r.string(),
});

export type NegativeReviewType = r.infer<typeof negativeReviewSchema>;

export enum NegativeReviewSourceTypeEnum {
    qrCode = 'qr_code',
    qrSet = 'qr_set',
    mailingList = 'mailing_list',
}

const negativeReviewSchema = r.object({
    id: r.number(),
    companyName: r.string(),
    companyAddress: r.string(),
    text: r.string(),
    createdAt: r.string(),
    rating5: r.number(),
    ratingScaleType: r.nativeEnum(ReviewGeneratorRatingScaleTypeEnum),
    client: negativeReviewClientSchema.nullable(),
    localCode: r.string(),
    templateName: r.string(),
    templateId: r.number(),
    sourceType: r.nativeEnum(NegativeReviewSourceTypeEnum).optional(),
});

export const negativeReviewListSchema = r.object({
    results: r.array(negativeReviewSchema),
    next: r.string().nullable(),
    previous: r.string().nullable(),
    count: r.number(),
    pageSize: r.number(),
});

export type NegativeReviewRequestOptionsType = {
    startDate: string | null;
    endDate: string | null;
    templateMailingIds: Array<number>;
    id: number | null;
};

export type NegativeReviewResponseType<InnerType> = PaginatedResponseType<InnerType> & {
    pageSize: number;
};

export type UseNegativeReviewListType = UseHookType<NegativeReviewResponseType<NegativeReviewType>> & {
    cursorPagination: CursorPaginationHookType;
};
