import {Checkbox} from 'antd';
import {isEqual} from 'lodash';
import {memo, useState} from 'react';

import {Form} from '../../../../../../../typings/antd';
import {AttributePropsType, EnumAttributeType} from '../attributes-type';

import {limit} from './enum-attribute-const';
import {EnumAttributeToggleButton} from './enum-attribute-toggle-button';
import * as styles from './enum-attribute.scss';

function EnumMultipleAttributeComponent({value, onChange}: AttributePropsType<EnumAttributeType>): JSX.Element {
    const {displayName: label, values} = value;

    const hasHiddenSelectedValues = values
        .filter((_value, index) => index + 1 > limit)
        .some(({selected}) => Boolean(selected));

    const [showAll, setShowAll] = useState(values.length <= limit || hasHiddenSelectedValues);

    return (
        <Form.Item className={styles.form_item} label={label}>
            <Checkbox.Group
                onChange={(newValues) => {
                    onChange?.({
                        ...value,
                        values: values.map((item) => ({
                            ...item,
                            selected: newValues.map(String).includes(item.templateId.toString()),
                        })),
                    });
                }}
                value={values.filter((item) => item.selected).map(({templateId}) => templateId)}
            >
                <div className={styles.enum_attribute}>
                    {(showAll ? values : values.slice(0, limit)).map((option) => (
                        <Checkbox key={option.templateId} value={option.templateId}>
                            {option.displayValue}
                        </Checkbox>
                    ))}
                </div>

                {values.length > limit && <EnumAttributeToggleButton setShowAll={setShowAll} showAll={showAll} />}
            </Checkbox.Group>
        </Form.Item>
    );
}

// there can be dozens of checkbox groups and hundreds of checkboxes in 1 category
export const EnumMultipleAttribute = memo(EnumMultipleAttributeComponent, (previous, next) =>
    isEqual(previous.value, next.value)
);
