import {useLocale} from '../../provider/locale/locale-hook';
import {useReviewStatusList} from '../../service/reviews/reviews-statuses';
import {classNames} from '../../util/css';

import * as styles from './status-badge.scss';

type PropsType = {
    status: string;
};

export function StatusBadge(props: PropsType): JSX.Element | null {
    const {status} = props;

    const {localeName} = useLocale();
    const {data} = useReviewStatusList(localeName);

    if (!data || data.statuses.length === 0) {
        return null;
    }

    const title = data.statuses.find((item) => item.statusCode === status)?.title;

    return (
        <div className={classNames(styles.StatusBadge, `${styles.StatusBadge}__${status}`)} role="status" title={title}>
            {title}
        </div>
    );
}
