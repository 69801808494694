import {Button, Checkbox, Form, Input} from 'antd';
import {useState} from 'react';

import {appRoute} from '../../../../app-route';
import {Meta} from '../../../../component/meta/meta';
import {BreadCrumbs} from '../../../../layout/bread-crumbs/bread-crumbs';
import {NavigationLink} from '../../../../layout/navigation-link/navigation-link';
import {Page} from '../../../../layout/page/page';
import {PageHeader} from '../../../../layout/page-header/page-header';
import {PageSubHeader} from '../../../../layout/page-header/page-sub-header';
import {Spinner} from '../../../../layout/spinner/spinner';
import {Locale} from '../../../../provider/locale/locale';
import {useLocale} from '../../../../provider/locale/locale-hook';
import {useFormRules} from '../../../../service/form/form-rules-hook';
import {useIntraService} from '../../../../service/intra-service/intra-service-hook';
import {IntraServiceType} from '../../../../service/intra-service/intra-service-type';
import * as integrationsStyles from '../integrations.scss';

export function IntegrationIntraService(): JSX.Element {
    const [form] = Form.useForm();
    const [isFormInvalid, setIsFormInvalid] = useState(false);
    const {getLocalizedString} = useLocale();
    const {result: intraServiceResult, updateIntraService, isInProgress} = useIntraService();
    const {requiredFieldRule} = useFormRules();

    function onFormSubmit() {
        const integrationIntraService: IntraServiceType = {
            enable_intra_service_integration: Boolean(form.getFieldValue('isEnable')),
            intra_service_site: form.getFieldValue('link') || '',
            intra_service_login: form.getFieldValue('login') || '',
            intra_service_password: form.getFieldValue('password') || '',
        };

        updateIntraService(integrationIntraService);
    }

    function onChange() {
        form.validateFields()
            .then(() => setIsFormInvalid(false))
            .catch(() => setIsFormInvalid(true));
    }

    return (
        <Page>
            <Meta title={getLocalizedString('INTEGRATIONS__INTEGRATOR_NAME__INTRASERVICE')} />

            <BreadCrumbs
                list={[
                    {path: '', titleLangKey: 'CATEGORY_NAME__SETTINGS'},
                    {
                        path: appRoute.settingsIntegrations.path,
                        titleLangKey: 'CATEGORY_NAME__INTEGRATION',
                    },
                    {
                        path: appRoute.settingsIntegrationIntraService.path,
                        titleLangKey: 'INTEGRATIONS__INTEGRATOR_NAME__INTRASERVICE',
                    },
                ]}
            />

            <PageHeader>
                <Locale stringKey="INTEGRATION__INTRASERVICE__HEADER" />
            </PageHeader>

            <PageSubHeader>
                <span>
                    <Locale stringKey="INTEGRATION__INTRASERVICE__SUB_HEADER" />
                    &nbsp;
                    {/* @TODO: add link once proper docs are ready */}
                    <NavigationLink to="#">
                        <Locale stringKey="INTEGRATION__INTRASERVICE__SUB_HEADER__LINK" />
                    </NavigationLink>
                </span>
            </PageSubHeader>

            <Form
                form={form}
                layout="vertical"
                name="integration-IntraService"
                onChange={onChange}
                onSubmitCapture={onFormSubmit}
            >
                <div className={integrationsStyles.integration__item_list}>
                    <Spinner isShow={!intraServiceResult} position="absolute" />

                    <Form.Item
                        className={integrationsStyles.integration__item}
                        initialValue={Boolean(intraServiceResult?.enable_intra_service_integration)}
                        name={intraServiceResult ? 'isEnable' : 'noInitialIsEnable'}
                        valuePropName="checked"
                    >
                        <Checkbox>
                            <Locale stringKey="INTEGRATION__INTRASERVICE__ENABLE_INTEGRATION" />
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        className={integrationsStyles.integration__item}
                        initialValue={intraServiceResult?.intra_service_site || ''}
                        label={<Locale stringKey="INTEGRATION__INTRASERVICE__LABEL__LINK_TO_YOUR_INTRASERVICE" />}
                        name={intraServiceResult ? 'link' : 'noInitialLink'}
                        rules={[requiredFieldRule]}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'INTEGRATION__INTRASERVICE__PLACEHOLDER__LINK_TO_YOUR_INTRASERVICE'
                            )}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={integrationsStyles.integration__item}
                        initialValue={intraServiceResult?.intra_service_login || ''}
                        label={<Locale stringKey="INTEGRATION__INTRASERVICE__LABEL__LOGIN_FOR_INTRASERVICE" />}
                        name={intraServiceResult ? 'login' : 'noInitialLogin'}
                        rules={[requiredFieldRule]}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'INTEGRATION__INTRASERVICE__PLACEHOLDER__LOGIN_FOR_INTRASERVICE'
                            )}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        className={integrationsStyles.integration__item}
                        initialValue={intraServiceResult?.intra_service_password || ''}
                        label={<Locale stringKey="INTEGRATION__INTRASERVICE__LABEL__PASSWORD_FOR_INTRASERVICE" />}
                        name={intraServiceResult ? 'password' : 'noInitialPassword'}
                        rules={[requiredFieldRule]}
                    >
                        <Input
                            placeholder={getLocalizedString(
                                'INTEGRATION__INTRASERVICE__PLACEHOLDER__PASSWORD_FOR_INTRASERVICE'
                            )}
                            size="large"
                            type="password"
                        />
                    </Form.Item>
                </div>

                <Button
                    disabled={!intraServiceResult || isFormInvalid}
                    htmlType="submit"
                    loading={isInProgress}
                    type="primary"
                >
                    <Locale stringKey="BUTTON__SAVE_INFORMATION" />
                </Button>
            </Form>
        </Page>
    );
}
