import {z as r} from 'zod';

const competitorSchema = r.object({
    id: r.number(),
    name: r.string(),
});

export type CompetitorType = r.infer<typeof competitorSchema>;

export type CompetitorsResponseType = {
    [brandId: number]: Array<CompetitorType>;
};

export const competitorsResponseSchema = r.record(
    r.preprocess((number) => Number.parseInt(r.string().parse(number), 10), r.number()),
    r.array(competitorSchema)
);
