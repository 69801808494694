export enum AttributeTypeEnum {
    GoogleBoolean = 'BOOL',
    GoogleUrl = 'URL',
    GoogleEnumSingle = 'ENUM',
    GoogleEnumMultiple = 'REPEATED_ENUM',

    YandexBoolean = 'feature-boolean',
    YandexTextSingle = 'feature-text-single',
    YandexEnumSingle = 'feature-enum-single',
    YandexEnumMultiple = 'feature-enum-multiple',
    YandexNumericSingle = 'feature-numeric-single',
    YandexInUnitsSingle = 'feature-in-units-single',
    YandexRangeSingle = 'feature-range-single',
    YandexRangeInUnitsSingle = 'feature-range-in-units-single',

    DoubleGisBoolean = 'boolean',
}

export type AttributeValueType<TValue> = {templateId: string | number} & TValue;

type CommonAttributeType<TType extends AttributeTypeEnum, TValue> = {
    internalName: string;
    displayName: string;
    featureType: TType;
    values: Array<AttributeValueType<TValue>>;
};

type BooleanAttributeValueType = {value?: string | boolean; selected?: boolean; displayValue?: string};

export type BooleanAttributeType = CommonAttributeType<
    AttributeTypeEnum.GoogleBoolean | AttributeTypeEnum.YandexBoolean | AttributeTypeEnum.DoubleGisBoolean,
    BooleanAttributeValueType
>;

type TextSingleAttributeValueType = {value?: string};

export type TextSingleAttributeType = CommonAttributeType<
    AttributeTypeEnum.GoogleUrl | AttributeTypeEnum.YandexTextSingle,
    TextSingleAttributeValueType
>;

type NumericSingleAttributeValueType = {value?: string};

export type NumericSingleAttributeType = CommonAttributeType<
    AttributeTypeEnum.YandexNumericSingle,
    NumericSingleAttributeValueType
>;

type RangeSingleAttributeValueType = {valueFrom?: string; valueTo?: string};

export type RangeSingleAttributeType = CommonAttributeType<
    AttributeTypeEnum.YandexRangeSingle,
    RangeSingleAttributeValueType
>;

type EnumAttributeValueType = {displayValue: string; selected?: boolean};

export type EnumAttributeType = CommonAttributeType<
    | AttributeTypeEnum.GoogleEnumSingle
    | AttributeTypeEnum.GoogleEnumMultiple
    | AttributeTypeEnum.YandexEnumSingle
    | AttributeTypeEnum.YandexEnumMultiple,
    EnumAttributeValueType
>;

export type InUnitsSingleAttributeValueType = {value?: string; unitValue?: string; selected?: boolean};
export type InUnitsSingleAttributeType = CommonAttributeType<
    AttributeTypeEnum.YandexInUnitsSingle,
    InUnitsSingleAttributeValueType
>;

export type RangeInUnitsSingleAttributeValueType = {
    valueFrom?: string;
    valueTo?: string;
    unitValue?: string;
    selected?: boolean;
};
export type RangeInUnitsSingleAttributeType = CommonAttributeType<
    AttributeTypeEnum.YandexRangeInUnitsSingle,
    RangeInUnitsSingleAttributeValueType
>;

export type AttributeType =
    | BooleanAttributeType
    | TextSingleAttributeType
    | NumericSingleAttributeType
    | RangeSingleAttributeType
    | EnumAttributeType
    | InUnitsSingleAttributeType
    | RangeInUnitsSingleAttributeType;

export type AttributeGroupType = {group: string; items: Array<AttributeType>};

export type YandexAttributeGetCompanyType = Omit<
    CommonAttributeType<
        AttributeTypeEnum,
        | BooleanAttributeValueType
        | TextSingleAttributeValueType
        | NumericSingleAttributeValueType
        | RangeSingleAttributeValueType
        | Omit<EnumAttributeValueType, 'displayValue' | 'selected'>
        | Omit<InUnitsSingleAttributeValueType, 'unitValue'>
        | Omit<RangeInUnitsSingleAttributeValueType, 'unitValue'>
    >,
    'displayName'
>;

export type GoogleAttributeGetCompanyType = {
    attributeId: string;
    values: Array<string | boolean>;
};

export type DoubleGisAttributeGetCompanyType = {
    originId: string;
    values: Array<string | boolean>;
};

export type AttributeGetConfigType = CommonAttributeType<
    AttributeTypeEnum,
    | Omit<BooleanAttributeValueType, 'value'>
    | Omit<TextSingleAttributeValueType, 'value'>
    | Omit<NumericSingleAttributeValueType, 'value'>
    | Omit<RangeSingleAttributeValueType, 'valueFrom' | 'valueTo'>
    | Omit<EnumAttributeValueType, 'selected'>
    | Omit<InUnitsSingleAttributeValueType, 'value' | 'selected'>
    | Omit<RangeInUnitsSingleAttributeValueType, 'valueFrom' | 'valueTo' | 'selected'>
>;

// https://stackoverflow.com/a/49511416
export type AttributeWithNonUnionValuesType = Omit<CommonAttributeType<AttributeTypeEnum, never>, 'values'> & {
    values: Array<
        AttributeValueType<
            | BooleanAttributeValueType
            | TextSingleAttributeValueType
            | NumericSingleAttributeValueType
            | RangeSingleAttributeValueType
            | EnumAttributeValueType
            | InUnitsSingleAttributeValueType
            | RangeInUnitsSingleAttributeValueType
        >
    >;
};

export type AttributePropsType<TType> = {
    value: TType;
    onChange: (value: TType) => void;
};
