import {deserializeV2} from '../../util/api-adapter';
import {FetchMethodEnum, fetchX} from '../../util/fetch';
import {getCsrfHeaders, mainApiHeaders, rootApiUrl} from '../api/api-const';

import {
    BrowserNotificationsType,
    EmailNotificationsType,
    TelegramNotificationsType,
    usedeskCatalogResponseSchema,
    UsedeskCatalogResponseType,
} from './notifications-settings-type';

export function fetchEmailNotifications(): Promise<EmailNotificationsType> {
    return fetchX<EmailNotificationsType>(rootApiUrl + '/v1/user/notification_settings/');
}

export function updateEmailNotifications(settings: EmailNotificationsType): Promise<EmailNotificationsType> {
    return fetchX<EmailNotificationsType>(rootApiUrl + '/v1/user/notification_settings/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(settings),
    });
}

export function fetchBrowserNotifications(): Promise<BrowserNotificationsType> {
    return fetchX<BrowserNotificationsType>(rootApiUrl + '/v1/user/notification_settings/push_alert_settings/');
}

export function updateBrowserNotifications(settings: BrowserNotificationsType): Promise<BrowserNotificationsType> {
    return fetchX<BrowserNotificationsType>(rootApiUrl + '/v1/user/notification_settings/push_alert_settings/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(settings),
    });
}

export function fetchTelegramNotifications(): Promise<TelegramNotificationsType> {
    return fetchX<TelegramNotificationsType>(rootApiUrl + '/v1/user/notification_settings/messenger_settings/');
}

export function updateTelegramNotifications(settings: TelegramNotificationsType): Promise<TelegramNotificationsType> {
    return fetchX<TelegramNotificationsType>(rootApiUrl + '/v1/user/notification_settings/messenger_settings/', {
        method: FetchMethodEnum.post,
        headers: {...mainApiHeaders, ...getCsrfHeaders()},
        body: JSON.stringify(settings),
    });
}

export async function fetchUsedeskCatalogList(): Promise<UsedeskCatalogResponseType> {
    const url = '/cp/notification_settings/usedesk/catalogs/';
    const response = await fetchX<UsedeskCatalogResponseType>(rootApiUrl + url);

    return deserializeV2<UsedeskCatalogResponseType>(url, usedeskCatalogResponseSchema, response);
}
