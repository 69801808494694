import {PostPreviewPropsType} from '../../../content-step-type';

import {GooglePreviewBody} from './components/google-preview-body/google-preview-body';
import {GooglePreviewHeader} from './components/google-preview-header/google-preview-header';
import * as styles from './google-preview.scss';

export function GooglePreview(props: PostPreviewPropsType): JSX.Element | null {
    const {postData, companyInfo} = props;

    if (!postData) {
        return null;
    }

    return (
        <div className={styles.GooglePreview}>
            <GooglePreviewHeader companyInfo={companyInfo} />
            <GooglePreviewBody post={postData} />
        </div>
    );
}
