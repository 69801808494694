export const enFakesTranslations = {
    FAKES__TITLE: 'Duplicates and fakes',
    FAKES__SETTINGS__TITLE: 'Settings and statistics',
    FAKES__STATUS__NEW: 'New',
    FAKES__STATUS__NEW__DESCRIPTION: 'New location',
    FAKES__STATUS__MODERATION: 'In moderation',
    FAKES__STATUS__MODERATION__DESCRIPTION: 'Location is awaiting moderation',
    FAKES__STATUS__CLAIM_SENT: 'Claim',
    FAKES__STATUS__CLAIM_SENT__DESCRIPTION: 'Claim sent',
    FAKES__STATUS__ACTION_REQUIRED: 'Action',
    FAKES__STATUS__ACTION_REQUIRED__DESCRIPTION: 'Action is required',
    FAKES__STATUS__CLAIM_APPROVED: 'Approved',
    FAKES__STATUS__CLAIM_APPROVED__DESCRIPTION: 'Claim approved',
    FAKES__STATUS__CLAIM_REJECTED: 'Rejected',
    FAKES__STATUS__CLAIM_REJECTED__DESCRIPTION: 'Claim rejected',
    FAKES__STATUS__HIDDEN: 'Hidden',
    FAKES__STATUS__HIDDEN__DESCRIPTION: 'Location hidden from search',
    FAKES__EMPTY__HEADER: 'Everything is fine, there are no duplicates and fakes!',
    FAKES__EMPTY__DESCRIPTION: 'Continue to keep an eye on the search and keep your business information up to date.',
    FAKES__EMPTY__IS_SEARCHING__HEADER: 'We are looking for duplicates and fakes',
    FAKES__EMPTY__IS_SEARCHING__DESCRIPTION:
        'The search and initial processing is underway. We will notify you when new results are available.',
    FAKES__EMPTY__NEXT_SEARCH: 'Next search: {date}',
    FAKES__EMPTY__BUTTON__LAST_RESULTS: 'Previous results',
    FAKES__RESULTS__TITLE: 'Search results for doubles and fakes',
    FAKES__RESULTS__HEADER__NEXT_SEARCH: 'The date of the next search: {date}',
    FAKES__RESULTS__HEADER__NEXT_SEARCH__SUBTITLE: 'Next search',
    FAKES__RESULTS__HEADER__NEXT_SEARCH__DESCRIPTION:
        'Search results will be available after the initial processing, which may take several days.',
    FAKES__RESULTS__HEADER__NO_NEXT_SEARCH__DESCRIPTION: 'Process the current search results to start the next.',
    FAKES__RESULTS__HEADER__IS_SEARCHING: 'Searching...',
    FAKES__RESULTS__HEADER__NEW_DUPLICATES: 'New duplicates',
    FAKES__RESULTS__HEADER__TOTAL_SEARCHES: 'Total searches',
    FAKES__RESULTS__HEADER__ALL_TOTAL_SEARCHES: 'All searches completed',
    FAKES__RESULTS__HEADER__FILTERED_COMPANIES: 'Companies filtered',
    FAKES__RESULTS__HEADER__ALL_FILTERED_COMPANIES: 'Total companies filtered',
    FAKES__RESULTS__HEADER__NEW_FAKES: 'New fakes',
    FAKES__RESULTS__FILTERS__TYPE__ALL: 'All results',
    FAKES__RESULTS__FILTERS__TYPE__FAKES: 'Fakes',
    FAKES__RESULTS__FILTERS__TYPE__DUPLICATES: 'Duplicates',
    FAKES__RESULTS__FILTERS__BRANDS__PLACEHOLDER: 'Brands',
    FAKES__RESULTS__FILTERS__CATALOGS__PLACEHOLDER: 'Catalogs',
    FAKES__RESULTS__FILTERS__STATUSES: 'Statuses:',
    FAKES__RESULTS__FILTERS__RESET__MOBILE: 'Reset to default',
    FAKES__RESULTS__FILTERS__RESET__TOOLTIP: 'Reset filters to default',
    FAKES__RESULTS__COUNT: 'Total results: {count}',
    FAKES__RESULTS__COLUMN__SEARCH_DATE: 'Search',
    FAKES__RESULTS__COLUMN__SEARCH_DATE__SUBTITLE: 'Search date',
    FAKES__RESULTS__COLUMN__SEARCH_DATE__DESCRIPTION:
        'Date when the location first appeared in the search results in the source',
    FAKES__RESULTS__COLUMN__FOUND_LOCATION: 'The found location',
    FAKES__RESULTS__COLUMN__MAIN_LOCATION: 'Main location',
    FAKES__RESULTS__COLUMN__STATUS: 'Status',
    FAKES__RESULTS__COLUMN__STATUS__SUBTITLE: 'Status and date of update',
    FAKES__RESULTS__COLUMN__STATUS__DESCRIPTION: 'The current location status and last status update date',
    FAKES__RESULTS__BUTTON__REMOVE_DUPLICATE: 'Remove duplicate',
    FAKES__RESULTS__BUTTON__REMOVE_FAKE: 'Remove fake',
    FAKES__RESULTS__BUTTON__NOT_A_DUPLICATE: 'Not a duplicate',
    FAKES__RESULTS__BUTTON__NOT_A_FAKE: 'Not a fake',
    FAKES__RESULTS__BUTTON__MY_LOCATION: 'My location',
    FAKES__RESULTS__BUTTON__NOT_MY_LOCATION: 'Not my location',
    FAKES__RESULTS__SOURCE: 'Source',
    FAKES__RESULTS__NO_LOGO: 'No logo in source',
    FAKES__RESULTS__MODERATION__BUTTON: 'Cancel',
    FAKES__RESULTS__MODERATION__SUBTITLE: 'Location in moderation',
    FAKES__RESULTS__MODERATION__DESCRIPTION:
        'The moderation of locations will go faster if you process all the results found.',
    FAKES__RESULTS__MODERATION__TIME: 'Maximum time of moderation is 11 days',
    FAKES__RESULTS__CLAIM_SENT__DESCRIPTION:
        'The request must be approved in the source. Please wait 1-3 weeks for reply.',
    FAKES__RESULTS__ACTION_REQUIRED:
        'In order for a complaint to be approved, the requirements of the catalog must be met.',
    FAKES__RESULTS__ACTION_REQUIRED__BUTTON: 'Requirements',
    FAKES__RESULTS__CLAIM_APPROVED: 'The request is approved',
    FAKES__RESULTS__CLAIM_REJECTED: 'Request rejected: catalog rejection received',
    FAKES__RESULTS__CLAIM_REJECTED__BUTTON: 'Details',
    FAKES__RESULTS__HIDDEN__CLIENT_LOCATION: 'You have marked the location as «My Location»',
    FAKES__RESULTS__HIDDEN__NOT_CLIENT_LOCATION: 'You have marked the location as «Not my location»',
    FAKES__RESULTS__HIDDEN__BUTTON: 'Change',
    FAKES__RESULTS__CHANGE__SUCCESS__TITLE: 'The action is confirmed',
    FAKES__RESULTS__CHANGE__SUCCESS__MESSAGE: 'We will send the complaint to the directory',
    FAKES__RESULTS__CHANGE__ERROR__TITLE: 'Failed to change status',
    FAKES__RESULTS__MODERATION_POPUP__DUPLICATES: 'about the duplicate location',
    FAKES__RESULTS__MODERATION_POPUP__FAKES: 'about the fake location',
    FAKES__RESULTS__MODERATION_POPUP__SITE: 'on your website',
    FAKES__RESULTS__MODERATION_POPUP__FEED: 'in your feed',
    FAKES__RESULTS__MODERATION_POPUP__FROM_YOUR_SITE: 'from your site',
    FAKES__RESULTS__MODERATION_POPUP__FROM_YOUR_FEED: 'from your feed',
    FAKES__RESULTS__MODERATION_POPUP__TITLE_REQUIREMENTS: 'Directory support requirements',
    FAKES__RESULTS__MODERATION_POPUP__DELETE_INFORMATION: 'Remove information about this location from your site',
    FAKES__RESULTS__MODERATION_POPUP__DUPLICATE_LOCATION_INFO:
        'As long as the information {dubsOrFake} is {siteOrFeed}, the catalog perceives the card as a real company. Delete your company information and confirm the deletion below to resubmit your directory request.',
    FAKES__RESULTS__MODERATION_POPUP__CONFIRM_HAS_BEEN_DELETED: 'I confirm, the information has been deleted',
    FAKES__RESULTS__MODERATION_POPUP__SUCCESS__MESSAGE: 'The request will be submitted to the catalog.',
    FAKES__RESULTS__REJECTED_POPUP__TITLE_REJECTION: 'Opting Out of Catalog Support',
    FAKES__RESULTS__REJECTED_POPUP__LOCATION_CANNOT_BE_REMOVED: 'Location cannot be removed from directory',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__PRE_EXISTING: 'Pre-existing business card',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_NAMES: 'Different names for duplicate and original',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_ADDRESS: 'Different addresses for duplicate and original',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_CATEGORY: 'Different categories for duplicate and original',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__DIFFERENT_NETWORK: 'Companies from different networks',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__LABEL: 'Status',
    FAKES__RESULTS__REJECTED_POPUP__STATUS__REJECTED: 'Rejected',
    FAKES__RESULTS__REJECTED_POPUP__CAUSE_LABEL: 'Cause',
    FAKES__RESULTS__REJECTED_POPUP__YOUR_COMPANY: 'Your company',
    FAKES__STATISTIC__LABEL__ALL_TIME_STATISTIC: 'Statistics for all time',
    FAKES__STATISTIC__TABS__STATISTIC_LABEL: 'Statistics and Reporting',
    FAKES__STATISTIC__TABS__SETTINGS_LABEL: 'Product settings',
    FAKES__STATISTIC__TAB_NAMES__ALL: 'Total cards processed',
    FAKES__STATISTIC__TAB_NAMES__CLAIM_REJECTED: 'Rejected requests',
    FAKES__STATISTIC__TAB_NAMES__FAKES_CLAIM_ACCEPTED: 'Approved fakes requests',
    FAKES__STATISTIC__TAB_NAMES__DUPLICATES_CLAIM_ACCEPTED: 'Approved requests for duplicates',
    FAKES__STATISTIC__TAB_NAMES__HIDDEN: 'Hidden results',
    FAKES__SETTINGS__PRODUCT_CONNECTED: 'The product is connected',
    FAKES__SETTINGS__PRODUCT_CONNECTED__TRIAL: 'Trial Period',
    FAKES__SETTINGS__LICENSE: 'License before',
    FAKES__SETTINGS__LICENSE__TRIAL: 'Valid until {date}',
    FAKES__SETTINGS__ALERT: 'Contact your manager to change settings',
    FAKES__SETTINGS__ALERT__TRIAL:
        'During the trial period, you can delete one duplicate or fake. To delete all found duplicates and fakes, you can purchase a license by {link}',
    FAKES__SETTINGS__ALERT__TRIAL__LINK: 'contacting a manager.',
    FAKES__SETTINGS__DESCRIPTION__SEARCH_TYPE: 'Search Type',
    FAKES__SETTINGS__DESCRIPTION__LANGUAGE: 'Search Language',
    FAKES__SETTINGS__DESCRIPTION__SELECTED_CITIES: 'Selected Cities',
    FAKES__SETTINGS__DESCRIPTION__SELECTED_BRANDS: 'Selected Brands',
    FAKES__SETTINGS__DESCRIPTION__ALL_BRANDS: 'Total Brands: {count}',
    FAKES__SETTINGS__DESCRIPTION__PROPOSED_TITLES: 'Suggested names',
    FAKES__SETTINGS__DESCRIPTION__ALL_TITLES: 'Total names: {count}',
    FAKES__SETTINGS__COMPARING_MODE__PHONETIC: 'Phonetic comparison',
    FAKES__SETTINGS__COMPARING_MODE__PHONETIC_DESCRIPTION:
        'We are looking for locations whose names will be consonant with your main or alternative company names.',
    FAKES__SETTINGS__COMPARING_MODE__STRICT: 'Incorporate company name',
    FAKES__SETTINGS__COMPARING_MODE__STRICT_DESCRIPTION:
        "We are looking for locations whose names contain your company's main or alternate names",
    FAKES__SETTINGS__SHOW_ALL: 'Show all',
    FAKES__SETTINGS__HIDE: 'Hide',
    FAKES__SETTINGS__FEATURES__DUPLICATES: 'Duplicates',
    FAKES__SETTINGS__SEARCH_AND_REMOVE: 'Search and remove',
    FAKES__SETTINGS__CITIES__ALL: 'All cities around the world',
    FAKES__SETTINGS__CITIES__BIG: 'Big cities around the world',
    FAKES__SETTINGS__CITIES__CLIENT_LOCATION: 'Cities with your locations',
    FAKES__SETTINGS__CREATE_REPORT__TITLE: 'Detailed PDF report on location processing',
    FAKES__SETTINGS__CREATE_REPORT__DESCRIPTION:
        'We will collect locations updated for the selected period of time, with statuses: Claim approved, Claim rejected, Location hidden.',
    FAKES__SETTINGS__CREATE_REPORT__DOWNLOAD: 'Download report',
    FAKES__SETTINGS__CREATE_REPORT__LOCATIONS: 'Locations in the report: {count}',
    FAKES__REPORT__SUMMARY__TITLE: 'Statistics of search and processing of duplicates and fakes',
    FAKES__REPORT__SUMMARY__PERIOD: 'Dates',
    FAKES__REPORT__SUMMARY__BRANDS: 'Brands',
    FAKES__REPORT__SUMMARY__STATISTICS__ALL: 'Locations processed',
    FAKES__REPORT__SUMMARY__STATISTICS__FAKES: 'Approved fakes',
    FAKES__REPORT__SUMMARY__STATISTICS__DUPLICATES: 'Approved duplicates',
    FAKES__REPORT__SUMMARY__STATISTICS__REJECTED: 'Rejected claims',
    FAKES__REPORT__SUMMARY__STATISTICS__HIDDEN: 'Hidden results',
    FAKES__REPORT__FAKES__TITLE: 'Approved fake claims',
    FAKES__REPORT__FAKES__DESCRIPTION:
        'The found fake will be deleted or permanently closed with subsequent removal from the catalog.',
    FAKES__REPORT__FAKES__LOCATION: 'Found location',
    FAKES__REPORT__FAKES__RESULT: 'Result',
    FAKES__REPORT__FAKES__CLAIM_APPROVED: 'Claim approved',
    FAKES__REPORT__DUPLICATES__TITLE: 'Approved duplicate claims',
    FAKES__REPORT__DUPLICATES__DESCRIPTION:
        'The found duplicate will be deleted or permanently closed with subsequent deletion from the directory.',
    FAKES__REPORT__DUPLICATES__LOCATION: 'Found location and main location',
    FAKES__REPORT__REJECTED__TITLE: 'Rejected claims',
    FAKES__REPORT__REJECTED__DESCRIPTION:
        'Directory support cannot remove found fakes and duplicates. The reason for the rejection is shown in the table below.',
    FAKES__REPORT__REJECTED__CLAIM_REJECTED: 'Claim rejected',
    FAKES__REPORT__HIDDEN__TITLE: 'Hidden locations',
    FAKES__REPORT__HIDDEN__DESCRIPTION:
        'These search results are hidden by you as they are not duplicates or fakes. They will be ignored on subsequent searches.',
    FAKES__REPORT__HIDDEN__LOCATION_HIDDEN: 'Location hidden',
    FAKES__TRIAL_LANDING__ALERT__MESSAGE: 'Delete duplicates and fakes',
    FAKES__TRIAL_LANDING__DELETE__TITLE: 'Removing duplicate and fake listings',
    FAKES__TRIAL_LANDING__DELETE__DESCRIPTION:
        'Identify all duplicates and fakes of your locations on Google and Yandex to direct customers to the correct address, retain reviews, and improve card ranking in local search results.',
    FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__TITLE: 'What is a duplicate card',
    FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__DESCRIPTION__LARGE: 'Duplicate card (duplication)',
    FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__DESCRIPTION:
        '— is a card with an address that matches one of your location addresses, often with identical or nearly identical name/content.',
    FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__BADGE_FIRST:
        'Every time you find multiple cards representing one of your locations in Google or Yandex, it means you have found duplicates.',
    FAKES__TRIAL_LANDING__DUPLICATES_BLOCK__BADGE_SECOND:
        "Usually, duplicates are created accidentally: by users or company employees who didn't know about the main cards' existence.",
    FAKES__TRIAL_LANDING__FAKES_BLOCK__TITLE: 'What is a fake card',
    FAKES__TRIAL_LANDING__FAKES_BLOCK__DESCRIPTION__LARGE: 'Fake card (fake)',
    FAKES__TRIAL_LANDING__FAKES_BLOCK__DESCRIPTION:
        "— is a card that matches one of your company names but doesn't correspond to any of its addresses.",
    FAKES__TRIAL_LANDING__FAKES_BLOCK__BADGE_FIRST:
        "Every time you find a card with the same or alternative name as your company, but its address doesn't match any of your real addresses, it means you've discovered a fake card.",
    FAKES__TRIAL_LANDING__FAKES_BLOCK__BADGE_SECOND:
        'Often, a company has a historical "trail" of fake cards: old, long-closed or relocated locations, locations from before rebranding, and others.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__TITLE: 'How duplicates and fakes affect your company',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__FIRST_CARD__TITLE: 'Reduced traffic to actual sales points',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__FIRST_CARD__PARAGRAPH_ONE:
        'Having duplicates and fakes of your main card leads to a traffic loss to non-existent points.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__FIRST_CARD__PARAGRAPH_TWO:
        'This can also push a customer to choose your competitor with accurate information.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__SECOND_CARD__TITLE: 'Loss of customer loyalty and trust',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__SECOND_CARD__PARAGRAPH_ONE:
        'When a customer encounters a duplicate or fake with incorrect/missing information instead of the main card, it misleads them and acts as a deterrent.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__SECOND_CARD__PARAGRAPH_TWO:
        '80% of consumers lose trust in a company if its contact information is incorrect.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__THIRD_CARD__TITLE: 'Loss of control over company reputation',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__THIRD_CARD__PARAGRAPH_ONE:
        'If a user leaves a review or question on a duplicate/fake card, you will never know about it.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__THIRD_CARD__PARAGRAPH_TWO:
        'Unanswered negative reviews accumulate and lower the rating of your duplicate/fake cards, which potential customers might notice.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__FOURTH_CARD__TITLE: 'Low local SEO performance',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__FOURTH_CARD__PARAGRAPH_ONE:
        'When a search engine finds cards with repeated information, it cannot determine which card is the primary one.',
    FAKES__TRIAL_LANDING__CARDS_BLOCK__FOURTH_CARD__PARAGRAPH_TWO:
        'As a result, your main card is displayed less frequently, leading to a loss of traffic to actual locations.',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__TITLE:
        'RocketData will help you quickly find and delete duplicate and fake locations on Google and Yandex',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__FIRST_STEP__TITLE: 'Finding duplicates and fakes',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__FIRST_STEP__DESCRIPTION:
        'RocketData will find duplicate and fake locations of your company on Yandex and Google Maps.',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__SECOND_STEP__TITLE: 'Checking geo services',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__SECOND_STEP__DESCRIPTION:
        'Every month, RocketData will check geo services for the appearance of new duplicates and fakes, and the identified locations will be sent to your personal account.',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__THIRD_STEP__TITLE: 'Granting the ability to delete or merge',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__THIRD_STEP__DESCRIPTION:
        'Search results for duplicates/fakes will be available in your RocketData personal account, where you can decide their fate: submit requests for deletion or merging of locations, as well as track location statuses right from your personal account.',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__FOURTH_STEP__TITLE: 'Providing a report',
    FAKES__TRIAL_LANDING__STEPS_BLOCK__FOURTH_STEP__DESCRIPTION:
        'In your RocketData personal account, you can see and download a complete report on the search and moderation results of duplicates and fakes at any time.',
    FAKES__TRIAL_LANDING__FINISH_BLOCK__TITLE: 'Results of removing duplicates/fakes for your company',
    FAKES__TRIAL_LANDING__FINISH_BLOCK__FIRST_CARD__TITLE: 'Traffic growth',
    FAKES__TRIAL_LANDING__FINISH_BLOCK__FIRST_CARD__DESCRIPTION:
        'Higher ranking of main cards, accompanied by traffic growth to your sales points.',
    FAKES__TRIAL_LANDING__FINISH_BLOCK__SECOND_CARD__TITLE: 'Accurate information',
    FAKES__TRIAL_LANDING__FINISH_BLOCK__SECOND_CARD__DESCRIPTION:
        'Customers will find your cards only with complete and accurate information.',
    FAKES__TRIAL_LANDING__FINISH_BLOCK__THIRD_CARD__TITLE: "Control over your company's reputation",
    FAKES__TRIAL_LANDING__FINISH_BLOCK__THIRD_CARD__DESCRIPTION:
        "You won't miss any reviews or questions coming to your cards and will be able to respond to them promptly.",
    FAKES__TRIAL_LANDING__BUTTON__TITLE__LICENSE_CONNECT: 'Connect license',
    FAKES__TRIAL_LANDING__BUTTON__TITLE__DELETE_DUPLICATES: 'Delete all duplicates',
    FAKES__TRIAL_LANDING__BUTTON__TITLE__DELETE_FAKES: 'Delete all fakes',
    FAKES__TRIAL_LANDING__MODAL__TITLE: 'Request for additional product',
    FAKES__TRIAL_LANDING__MODAL__PRODUCT__LABEL: 'Product',
    FAKES__TRIAL_LANDING__MODAL__PRODUCT__TITLE: 'Deletion of duplicates and fakes',
    FAKES__TRIAL_LANDING__MODAL__COMMENT__LABEL: 'Comment (optional)',
    FAKES__TRIAL_LANDING__MODAL__COMMENT__PLACEHOLDER: 'Your comment...',
    FAKES__TRIAL_LANDING__MODAL__ALERT__DESCRIPTION: 'A manager will contact you to clarify the details',
    FAKES__TRIAL_LANDING__MODAL__LINK: 'View all products',
};
