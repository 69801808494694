export const enImagesTranslations = {
    IMAGES__YOUR_COMPANY_LOGO: 'Your company logo',
    IMAGES__COMPANY_CARD_COVER: 'Company card cover',
    IMAGES__PHOTO_GALLERY: 'Photo gallery',
    IMAGES__ERROR__INVALID_IMAGE: 'Image does not fit',
    IMAGES__ERROR__INVALID_IMAGE_DESCRIPTION: 'Please upload an image that meets the requirements.',
    IMAGES__HEADER__CROP_PHOTO: 'Crop photo',
    IMAGES__TEXT__FORMATS: 'Formats:',
    IMAGES__TEXT__MINIMUM_RESOLUTION: 'Minimum Resolution:',
};
