import {z as r} from 'zod';

const accountCatalogSchema = r.object({
    oauthLink: r.string(),
});

export const vkAccountSchema = r.object({
    id: r.number(),
    email: r.string().nullable(),
    accountId: r.string(),
    firstName: r.string().nullable(),
    lastName: r.string().nullable(),
});

export const vkAccountsSchema = r.array(vkAccountSchema);

export const googleAccountSchema = r.object({
    id: r.number(),
    name: r.string(),
    uid: r.string(),
});

export const googleAccountsSchema = r.object({
    catalog: accountCatalogSchema,
    results: r.array(googleAccountSchema),
});

export const facebookAccountSchema = googleAccountSchema.extend({
    notGrantedScopes: r.array(r.string()),
});

export const facebookAccountsSchema = r.object({
    catalog: accountCatalogSchema,
    results: r.array(facebookAccountSchema),
});
