import {classNames} from '../../../../util/css';
import {BarDataSetType} from '../../bar-chart/bar-chart-type';
import {LineDataSetType} from '../../line-chart/line-chart-type';

import {ChartLegendItem} from './chart-legend-item';
import * as styles from './chart-legend.scss';

type PropsType = {
    data: Array<LineDataSetType | BarDataSetType>;
    className?: string;
    itemClassName?: string;
};

export function ChartLegend(props: PropsType): JSX.Element {
    const {data, className, itemClassName} = props;

    return (
        <div className={classNames(styles.ChartLegend_container, className)}>
            {data.map((item) => (
                <ChartLegendItem className={itemClassName} data={item} key={item.label} />
            ))}
        </div>
    );
}
