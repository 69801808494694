import {z as r} from 'zod';

const linkCompanySchema = r.object({
    id: r.number(),
    name: r.string(),
    address: r.object({
        city: r.string().nullable(),
        country: r.string(),
        countryCode: r.string(),
        housenumber: r.string(),
        postcode: r.string(),
        region: r.string().nullable(),
        street: r.string(),
    }),
});

export type LinkCompanyType = r.infer<typeof linkCompanySchema>;

export const linkCompaniesResponseSchema = r.object({
    nextPageToken: r.number().nullable(),
    companies: r.array(linkCompanySchema),
});

export type LinkCompaniesResponseType = r.infer<typeof linkCompaniesResponseSchema>;

export type LinkCompaniesRequestType = {
    brandId: number;
    q: string;
    count: number;
    token: number | null;
};
