import {z as r} from 'zod';

const gptLicenseSchema = r.object({
    pk: r.number(),
    startAt: r.string(),
    finishAt: r.string(),
    isUpsell: r.boolean(),
    isTrial: r.boolean(),
});

export const gptLicensesResponseSchema = r.array(gptLicenseSchema);

export type GptLicensesListResponseType = r.infer<typeof gptLicensesResponseSchema>;
