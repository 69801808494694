import {useMemo} from 'react';

import {DoughnutChart} from '../../../../../../layout/chart/doughnut-chart/doughnut-chart';
import {DoughnutStatistic} from '../../../../../../layout/doughnut-statistic/doughnut-statistic';
import {useLocale} from '../../../../../../provider/locale/locale-hook';
import {
    FormattedPeriodsType,
    OnlinePresenceSourcesType,
    OnlinePresenceV2FilterQueriesEnum,
    OnlinePresenceV2FilterStateType,
} from '../../../../../../service/online-presence/online-presence-type';
import {useFormat} from '../../../../../../util/format-hook/format-hook';
import {CompareTable} from '../../../compare-table/compare-table';
import {CompareRowType} from '../../../compare-table/compare-table-type';
import {
    getSourceOfTransitionsChartOptions,
    SOURCE_OF_TRANSITION_COLOR_MAP,
    SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP,
    SOURCE_OF_TRANSITION_ORDER,
    SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP,
} from '../../source-of-transition-helper';
import {MainPeriodType} from '../../source-of-transition-type';

import * as styles from './source-of-transition-compare.scss';

type PropsType = {
    data: OnlinePresenceSourcesType;
    filter: OnlinePresenceV2FilterStateType;
    formattedPeriods?: FormattedPeriodsType;
};

export function SourceOfTransitionCompare(props: PropsType): JSX.Element {
    const {data, filter, formattedPeriods} = props;

    const {getFormattedDateTime} = useFormat();
    const {getLocalizedString} = useLocale();

    const mainPeriodData: Array<MainPeriodType> = useMemo(
        () =>
            data
                .sort(
                    (item1, item2) => SOURCE_OF_TRANSITION_ORDER[item1.label] - SOURCE_OF_TRANSITION_ORDER[item2.label]
                )
                .map(({label, current}) => ({
                    titleKey: SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP[label],
                    shortTitleKey: SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP[label],
                    color: SOURCE_OF_TRANSITION_COLOR_MAP[label],
                    label: getLocalizedString(SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP[label]),
                    value: current,
                })),
        [data, getLocalizedString]
    );

    const comparePeriodData: Array<MainPeriodType> = useMemo(
        () =>
            data
                .sort(
                    (item1, item2) => SOURCE_OF_TRANSITION_ORDER[item1.label] - SOURCE_OF_TRANSITION_ORDER[item2.label]
                )
                .map(({label, compared}) => ({
                    titleKey: SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP[label],
                    shortTitleKey: SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP[label],
                    color: SOURCE_OF_TRANSITION_COLOR_MAP[label],
                    label: getLocalizedString(SOURCE_OF_TRANSITION_LONG_LOCALIZATION_KEY_MAP[label]),
                    value: compared,
                }))
                .reverse(),
        [data, getLocalizedString]
    );

    const compareTableData: Array<CompareRowType> = useMemo(
        () =>
            data.map(({label, current, compared, delta}) => ({
                titleKey: SOURCE_OF_TRANSITION_SHORT_LOCALIZATION_KEY_MAP[label],
                currentValue: current,
                comparePeriodValue: compared,
                delta,
            })),
        [data]
    );

    const {
        [OnlinePresenceV2FilterQueriesEnum.PeriodStart]: periodStart,
        [OnlinePresenceV2FilterQueriesEnum.PeriodEnd]: periodEnd,
        [OnlinePresenceV2FilterQueriesEnum.ComparePeriodStart]: comparePeriodStart,
        [OnlinePresenceV2FilterQueriesEnum.ComparePeriodEnd]: comparePeriodEnd,
    } = filter;

    return (
        <>
            <div className={styles.SourceOfTransitionCompare}>
                <div className={styles.SourceOfTransitionCompare_chartContainer}>
                    <div className={styles.SourceOfTransitionCompare_chart}>
                        <DoughnutChart
                            chartOptions={getSourceOfTransitionsChartOptions(
                                {
                                    circumference: 180,
                                    rotation: 180,
                                    cutout: '30%',
                                },
                                {
                                    data: comparePeriodData,
                                    title:
                                        comparePeriodStart && comparePeriodEnd
                                            ? `${getFormattedDateTime(comparePeriodStart)} - ${getFormattedDateTime(
                                                  comparePeriodEnd
                                              )}`
                                            : '',
                                }
                            )}
                            data={comparePeriodData}
                            normalized
                        />
                    </div>
                    <div className={styles.SourceOfTransitionCompare_chart}>
                        <DoughnutChart
                            chartOptions={getSourceOfTransitionsChartOptions(
                                {
                                    circumference: 180,
                                    cutout: '30%',
                                },
                                {
                                    data: mainPeriodData,
                                    title: `${getFormattedDateTime(periodStart)} - ${getFormattedDateTime(periodEnd)}`,
                                }
                            )}
                            data={mainPeriodData}
                            normalized
                        />
                    </div>
                </div>
                <div className={styles.SourceOfTransitionCompare_legend}>
                    {mainPeriodData.map((dataEntry: MainPeriodType) => (
                        <DoughnutStatistic
                            color={dataEntry.color}
                            key={dataEntry.label}
                            label={getLocalizedString(dataEntry.shortTitleKey)}
                            value=""
                        />
                    ))}
                </div>
            </div>
            <CompareTable
                formattedPeriods={formattedPeriods}
                metricsNameHeadKey="ONLINE_PRESENCE_ANALYTICS__SOURCE_OF_TRANSITION"
                rows={compareTableData}
            />
        </>
    );
}
