import {faCheck} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal, Progress, Space} from 'antd';
import {useContext, useEffect} from 'react';

import {appRoute} from '../../../app-route';
import {Meta} from '../../../component/meta/meta';
import {MainPageCardDivider} from '../../../component/page-card-divider/main-page-card-divider';
import {Text} from '../../../component/text/text';
import {BreadCrumbs} from '../../../layout/bread-crumbs/bread-crumbs';
import {Page} from '../../../layout/page/page';
import {MainPageContainer} from '../../../layout/page-card/main-page-card';
import {PageCard} from '../../../layout/page-card/page-card';
import {PageHeader} from '../../../layout/page-header/page-header';
import {useDomainConfig} from '../../../provider/domain-config/domain-config-hook';
import {ShortLocaleNameEnum} from '../../../provider/locale/locale-context-type';
import {useLocale} from '../../../provider/locale/locale-hook';
import {Locale} from '../../../provider/locale/localization';
import {OnboardingContext} from '../../../provider/onboarding/onboarding-context';
import {
    AccountConnectionType,
    ConfirmCompaniesType,
    OnboardingStepNameEnum,
    SyncCompaniesType,
    useAutoSyncCompanies,
    useFinishOnboarding,
} from '../../../provider/onboarding/onboarding-hook';
import {useSystem} from '../../../provider/system/system-hook';
import {useUser} from '../../../provider/user/user-hook';

import {OnboardingStepEnum} from './onboarding-const';
import {getOnboardingTitle} from './onboarding-helper';
import {AddAccountsStep} from './onboarding-steps/add-accounts-step/add-accounts-step';
import {AddCompaniesStep} from './onboarding-steps/add-companies-step/add-companies-step';
import {ConfirmCompaniesStep} from './onboarding-steps/confirm-companies-step/confirm-companies-step';
import {EmptyOnboardingStep} from './onboarding-steps/empty-step';
import {SyncCompaniesStep} from './onboarding-steps/sync-companies-step/sync-companies-step';
import {Recommendations} from './recomendations/recommendations';
import * as styles from './onboarding.scss';

// eslint-disable-next-line complexity
export function Onboarding(): JSX.Element {
    const {
        openStep,
        setOpenAutoSyncPopup,
        openAutoSyncPopup,
        onboardingStepValue,
        onboardingPercent,
        isOnboardingCompleted,
        refetch,
        setIsDemoOnboarding,
        isDemoOnboarding,
        setDemoOnboardingStep,
    } = useContext(OnboardingContext);
    const {getLocalizedString} = useLocale();
    const {screen} = useSystem();

    const {user} = useUser();
    const {companyName} = useDomainConfig();
    const {mutateAsync: handleAutoSync} = useAutoSyncCompanies();

    const {mutate} = useFinishOnboarding(refetch);

    const stepDataInOpenStep = onboardingStepValue?.find((step) => step.isOpen)?.stepData ?? {};

    const isShowFBFootNote = 'facebook' in stepDataInOpenStep && user?.language === ShortLocaleNameEnum.ru;

    async function handleAutoSyncCompanies() {
        await handleAutoSync();
        setOpenAutoSyncPopup(false);
        refetch();
    }

    useEffect(() => {
        refetch();
    }, []);

    return (
        <Page
            renderContainer={(children) => (
                <MainPageContainer className={styles.OnboardingPage}>{children}</MainPageContainer>
            )}
        >
            <PageCard>
                <Meta title={getLocalizedString('ONBOARDING__TITLE', {companyName})} />

                <BreadCrumbs
                    list={[
                        {
                            path: appRoute.onboarding.path,
                            titleLangKey: 'CATEGORY_NAME__ONBOARDING__DESCRIPTION',
                        },
                    ]}
                />

                <PageHeader>
                    <Space className={styles.Onboarding_title} wrap>
                        <Locale
                            stringKey={isOnboardingCompleted ? 'ONBOARDING__TITLE__FINISH' : 'ONBOARDING__TITLE'}
                            valueMap={{companyName}}
                        />
                        {isOnboardingCompleted && (
                            <Button icon={<FontAwesomeIcon icon={faCheck} />} onClick={mutate} type="primary">
                                <span>
                                    <Locale stringKey="ONBOARDING__FINISH" />
                                </span>
                            </Button>
                        )}
                        {user?.isStaff && (
                            <Button
                                onClick={() => setIsDemoOnboarding((previousState) => !previousState)}
                                type={isDemoOnboarding ? 'primary' : 'default'}
                            >
                                <Locale stringKey="ONBOARDING__DEMO__BUTTON" />
                            </Button>
                        )}
                    </Space>
                </PageHeader>
                <MainPageCardDivider />
                <Space className={styles.Onboarding} direction="vertical" size={20}>
                    <Text stringKey="ONBOARDING__DESCRIPTION" valueMap={{companyName}} />
                    {!isOnboardingCompleted && (
                        <div className={styles.Onboarding_progressBlock}>
                            <Text
                                className={styles.Onboarding_countText}
                                stringKey="ONBOARDING__STEP_COUNT"
                                valueMap={{count: isOnboardingCompleted ? 4 : openStep - 1}}
                            />
                            <Progress percent={onboardingPercent} showInfo={false} />
                        </div>
                    )}
                </Space>
            </PageCard>

            <PageCard className={styles.Onboarding_generalBlock}>
                {/* eslint-disable-next-line complexity */}
                {onboardingStepValue.map((step) => {
                    const {isOpen, stepIndex, title, active, stepData, status} = step;
                    const stepTitle = getOnboardingTitle(
                        screen.isMobile,
                        isDemoOnboarding ? !isDemoOnboarding : !isOpen,
                        title,
                        active,
                        status
                    );

                    if (!isOpen) {
                        return (
                            <>
                                <EmptyOnboardingStep
                                    isDemoOnboarding={isDemoOnboarding}
                                    isOnboardingCompleted={isOnboardingCompleted}
                                    key={stepIndex}
                                    openStep={openStep}
                                    setDemoOnboardingStep={setDemoOnboardingStep}
                                    stepIndex={stepIndex}
                                    title={stepTitle}
                                />
                                {isOnboardingCompleted && stepIndex === OnboardingStepEnum.fourth && (
                                    <Recommendations
                                        isDemoOnboarding={isDemoOnboarding}
                                        stepName={OnboardingStepNameEnum.ConfirmCompaniesStep}
                                    />
                                )}
                            </>
                        );
                    }

                    switch (stepIndex) {
                        case OnboardingStepEnum.first:
                            return (
                                <>
                                    <AddCompaniesStep
                                        active={active}
                                        isOpenStep={isOpen}
                                        key={stepIndex}
                                        refetch={refetch}
                                        status={status}
                                        stepIndex={stepIndex}
                                        title={stepTitle}
                                    />
                                    <Recommendations
                                        isDemoOnboarding={isDemoOnboarding}
                                        stepName={OnboardingStepNameEnum.AddCompaniesStep}
                                    />
                                </>
                            );
                        case OnboardingStepEnum.second:
                            if (stepData) {
                                return (
                                    <AddAccountsStep
                                        accountsConnection={stepData as AccountConnectionType}
                                        active={active}
                                        isOpenStep={isOpen}
                                        key={stepIndex}
                                        refetch={refetch}
                                        stepIndex={stepIndex}
                                        title={stepTitle}
                                    />
                                );
                            }

                            break;
                        case OnboardingStepEnum.third:
                            if (stepData) {
                                return (
                                    <>
                                        <SyncCompaniesStep
                                            active={active}
                                            isOpenStep={isOpen}
                                            key={stepIndex}
                                            refetch={refetch}
                                            setIsOpenAutoOnboardingPopup={setOpenAutoSyncPopup}
                                            stepIndex={stepIndex}
                                            syncCompanies={stepData as SyncCompaniesType}
                                            title={stepTitle}
                                        />
                                        <Recommendations
                                            isDemoOnboarding={isDemoOnboarding}
                                            stepName={OnboardingStepNameEnum.SyncCompaniesStep}
                                        />
                                    </>
                                );
                            }

                            break;
                        case OnboardingStepEnum.fourth:
                            if (stepData) {
                                return (
                                    <>
                                        <ConfirmCompaniesStep
                                            active={active}
                                            confirmCompanies={stepData as ConfirmCompaniesType}
                                            isOpenStep={isOpen}
                                            key={stepIndex}
                                            stepIndex={stepIndex}
                                            title={stepTitle}
                                        />
                                        <Recommendations
                                            isDemoOnboarding={isDemoOnboarding}
                                            stepName={OnboardingStepNameEnum.ConfirmCompaniesStep}
                                        />
                                    </>
                                );
                            }

                            break;
                        default:
                            return null;
                    }

                    return null;
                })}
            </PageCard>
            {isShowFBFootNote && (
                <Text
                    className={styles.Onboarding_footNote}
                    secondary
                    small
                    stringKey="ONBOARDING__FACEBOOK_FOOTNOTE"
                />
            )}
            {openAutoSyncPopup && (
                <Modal
                    centered
                    okText={<Locale stringKey="POPUP__BUTTON__CONFIRM" />}
                    onCancel={() => setOpenAutoSyncPopup(false)}
                    onOk={handleAutoSyncCompanies}
                    open
                    title={<Locale stringKey="ONBOARDING__AUTO_SYNC_MODAL__TITLE" />}
                >
                    <Space direction="vertical" size="large">
                        <Text stringKey="ONBOARDING__AUTO_SYNC_MODAL__DESCRIPTION" />
                        <Text stringKey="ONBOARDING__AUTO_SYNC_MODAL__CONFIRM" />
                    </Space>
                </Modal>
            )}
        </Page>
    );
}
