import {useMemo} from 'react';

import {ChartRenderer} from '../chart-renderer/chart-renderer';

import {defaultChartBorderRadius, defaultChartBorderWidth, doughnutChartOptions} from './doughnut-chart-const';
import {ChartJsDoughnutDataType, ChartJsDoughnutOptionsType, DoughnutDataType} from './doughnut-chart-type';

type PropsType = {
    chartOptions?: ChartJsDoughnutOptionsType;
    data: Array<DoughnutDataType>;
    normalized?: boolean;
};

export function DoughnutChart(props: PropsType): JSX.Element {
    const {data, chartOptions, normalized} = props;

    const borderWidth: number = useMemo(() => {
        return chartOptions && typeof chartOptions.borderWidth === 'number'
            ? chartOptions.borderWidth
            : defaultChartBorderWidth;
    }, [chartOptions]);

    const borderRadius: number = useMemo(() => {
        return chartOptions && typeof chartOptions.borderRadius === 'number'
            ? chartOptions.borderRadius
            : defaultChartBorderRadius;
    }, [chartOptions]);

    const chartJsData: ChartJsDoughnutDataType = useMemo<ChartJsDoughnutDataType>(
        (): ChartJsDoughnutDataType => ({
            labels: data.map(({label}: DoughnutDataType): string => label),
            datasets: [
                {
                    normalized,
                    order: 1,
                    borderWidth,
                    borderRadius,
                    data: data.map(({value}: DoughnutDataType): number => value),
                    backgroundColor: data.map(({color}: DoughnutDataType): string => color),
                },
            ],
        }),
        [data, normalized, borderWidth, borderRadius]
    );

    return (
        <ChartRenderer<'doughnut', Array<number>, string>
            data={chartJsData}
            options={{...doughnutChartOptions, ...chartOptions}}
            type="doughnut"
        />
    );
}
