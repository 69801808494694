import {FormInstance} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox/Checkbox';

import {enablePushNotifications} from '../../../../service/firebase/firebase-messaging';
import {
    BrowserNotificationsType,
    NotificationTextReviewEnum,
    TimeRangeType,
} from '../../../../service/notifications-settings/notifications-settings-type';
import {findInArrayByValueEnsure} from '../../../../util/array';
import {TimeStringType} from '../../../../util/type';
import {textReviewTypeList} from '../notification-review-filter/notification-review-filter-const';
import {NotificationsReviewsFilterType} from '../notification-review-filter/notification-review-filter-type';

import {BrowserNotificationFormValuesType} from './notification-browser-type';

export function serializeBrowserNotification(
    form: FormInstance<BrowserNotificationFormValuesType & NotificationsReviewsFilterType>
): BrowserNotificationsType {
    const values = form.getFieldsValue();

    const isNotificationsTurnedOn = values.send_pushalert_notifications;

    let pushalert_notifications_time_bucket: TimeRangeType | null = null;

    if (values.pushalert_notifications_time_bucket) {
        pushalert_notifications_time_bucket = {
            start: isNotificationsTurnedOn
                ? (values.pushalert_notifications_time_bucket[0].format('HH:mm') as TimeStringType)
                : '00:00',
            end: isNotificationsTurnedOn
                ? (values.pushalert_notifications_time_bucket[1].format('HH:mm') as TimeStringType)
                : '23:59',
        };
    }

    const pushalert_notifications_catalogs = isNotificationsTurnedOn ? values.catalog : [];
    const pushalert_notifications_ratings = isNotificationsTurnedOn ? values.ratings : [];
    const pushalert_notifications_content_type = findInArrayByValueEnsure<NotificationTextReviewEnum>(
        textReviewTypeList,
        values.type
    );

    return {
        send_pushalert_notifications: values.send_pushalert_notifications,
        send_pushalert_omnichannel_notifications: values.send_pushalert_omnichannel_notifications,
        pushalert_notifications_catalogs,
        pushalert_notifications_ratings,
        pushalert_notifications_content_type,
        pushalert_notifications_time_bucket,
    };
}

export function handleSendNotificationsChange(event: CheckboxChangeEvent): void {
    if (event.target.checked) {
        enablePushNotifications();
    }
}
