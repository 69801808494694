import {fetchAndDeserialize} from '../../../util/api-adapter';
import {getUniqueListByKey} from '../../../util/array';

import {competitorsResponseSchema, CompetitorsResponseType, CompetitorType} from './competitors-type';

export async function fetchCompetitors(): Promise<Array<CompetitorType>> {
    const url = `/v2/reviews-analytics/competitors/`;

    const competitorsResponse: CompetitorsResponseType = await fetchAndDeserialize<CompetitorsResponseType>(
        url,
        competitorsResponseSchema
    );
    const notUniqCompetitors: Array<CompetitorType> = Object.values<Array<CompetitorType>>(competitorsResponse).flat();

    return getUniqueListByKey<CompetitorType>(notUniqCompetitors, 'id');
}
