import {Select} from 'antd';

import {Locale} from '../../../../../../../../provider/locale/localization';
import {useFormRules} from '../../../../../../../../service/form/form-rules-hook';
import {usePostsVkGroups} from '../../../../../../../../service/posts/posts-hooks';
import {PostFormFieldsEnum, PostFormType} from '../../../../../../../../service/posts/posts-types';
import {Form} from '../../../../../../../../typings/antd';
import {PostFormItemPropsType} from '../../../../post-form-type';

export function VkGroupsItem(props: PostFormItemPropsType): JSX.Element {
    const {disabled} = props;

    const {data: availableVkGroups, isLoading: isLoadingVkGroups} = usePostsVkGroups();
    const {requiredFieldRule} = useFormRules();

    return (
        <Form.Item<PostFormType>
            label={<Locale stringKey="POSTS_FORM__VK_GROUPS__LABEL" />}
            name={PostFormFieldsEnum.VkGroupsIds}
            required
            rules={[requiredFieldRule]}
        >
            <Select<Array<string>>
                disabled={disabled}
                loading={isLoadingVkGroups}
                mode="multiple"
                optionFilterProp="children"
                placeholder={<Locale stringKey="POSTS_FORM__VK_GROUPS__PLACEHOLDER" />}
            >
                {availableVkGroups?.map((group) => (
                    <Select.Option key={group.groupId} value={group.groupId}>
                        {group.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
}
