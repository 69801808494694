import dayjs from 'dayjs';

import {QueryMapType} from '../../../../util/type';

import {LocalPackFilterEnum, LocalPackFilterType, LocalPackFilterUrlQueryNameEnum} from './local-pack-filter-type';

export function getInitialLocalPackFilter(urlQueries: QueryMapType): LocalPackFilterType {
    const dateFromRaw = urlQueries[LocalPackFilterUrlQueryNameEnum.startDate];
    const dateFrom: Date | null = dateFromRaw ? new Date(dateFromRaw) : null;

    const dateToRaw = urlQueries[LocalPackFilterUrlQueryNameEnum.endDate];
    const dateTo: Date | null = dateToRaw ? new Date(dateToRaw) : null;

    const searchQueriesRaw = urlQueries[LocalPackFilterUrlQueryNameEnum.searchQueries];
    const searchQueries: Array<number> =
        typeof searchQueriesRaw === 'string' ? searchQueriesRaw.split(',').map(Number) : [];

    return {
        [LocalPackFilterEnum.timeRange]: [dateFrom ? dayjs(dateFrom) : null, dateTo ? dayjs(dateTo) : dateTo],
        [LocalPackFilterEnum.searchQueries]: searchQueries,
    };
}
