import {faStar, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faRotateLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'antd';
import {ReactNode, SyntheticEvent, useMemo} from 'react';

import {AdditionalInfo} from '../../../../../../../layout/additional-info/additional-info';
import {Locale} from '../../../../../../../provider/locale/locale';
import {BulkEditFieldNameEnum} from '../../../../../../../service/company/company-type';
import {Form} from '../../../../../../../typings/antd';
import {arrayMove} from '../../../../../../../util/array';
import {classNames} from '../../../../../../../util/css';

import {InputStringType} from './input-string-type';
import * as styles from './input-string.scss';

export type InputStringPropsType<ItemType = InputStringType> = {
    className?: string;
    item: ItemType;
    itemList: Array<ItemType>;
    setItemList?: (itemList: Array<ItemType>) => void;
    addonBefore?: string;
    placeholder?: string;
    renderRemovedItemText: (value: string) => JSX.Element;
    additionalInfo?: {
        title?: JSX.Element;
        content: JSX.Element;
    };
    inputComponent?: JSX.Element;
    hasError?: boolean;
    errorMessages?: ReactNode;
    isRequired?: boolean;
    hasMainItem?: boolean;
    hasPhoneValidateError?: boolean;
    helpText?: JSX.Element | null;
    tag?: BulkEditFieldNameEnum;
};

// eslint-disable-next-line complexity
export function InputString<ItemType extends InputStringType>(props: InputStringPropsType<ItemType>): JSX.Element {
    const {
        className,
        item,
        itemList,
        setItemList,
        addonBefore,
        placeholder,
        additionalInfo,
        inputComponent,
        renderRemovedItemText,
        hasError,
        isRequired,
        hasMainItem = true,
        hasPhoneValidateError,
        errorMessages,
        helpText,
    } = props;
    const index = itemList.indexOf(item);
    const hasMoreThanOneActiveItems = itemList.filter((input) => !input.isRemoved).length > 1;

    const fullClassName = classNames(styles.input_string, className);

    function handleMakeFirst() {
        const newItemList = arrayMove([...itemList], index, 0);

        if (setItemList) {
            setItemList(newItemList);
        }
    }

    function handleMarkAsRemoved() {
        let newItemList = [...itemList];

        if (item.value) {
            newItemList = itemList.map((oldItem: ItemType): ItemType => {
                return oldItem.id === item.id ? {...item, isRemoved: true} : oldItem;
            });
        } else {
            newItemList.splice(index, 1);

            if (setItemList) {
                setItemList(newItemList);
            }
        }

        const firstActive = newItemList.findIndex((input) => !input.isRemoved);

        newItemList = arrayMove(newItemList, firstActive, 0);

        if (setItemList) {
            setItemList(newItemList);
        }
    }

    function handleUndo() {
        const newItems: Array<ItemType> = itemList.map((oldItem: ItemType): ItemType => {
            return oldItem.id === item.id ? {...item, isRemoved: false} : oldItem;
        });

        if (setItemList) {
            setItemList(newItems);
        }
    }

    const additionalInfoContent = useMemo((): JSX.Element => {
        if (!additionalInfo) {
            return <div className={styles.input_string__additional_info} />;
        }

        return (
            <Button className={styles.input_string__additional_info} size="large" type="text">
                <AdditionalInfo
                    icon={<FontAwesomeIcon className={styles.input_string__aditional_info__icon} icon={faStar} />}
                    title={additionalInfo.title}
                >
                    {additionalInfo.content}
                </AdditionalInfo>
            </Button>
        );
    }, [additionalInfo]);

    if (item.isRemoved) {
        return (
            <div className={classNames(fullClassName, styles.input_string__removed_text__container)}>
                <p className={styles.input_string__removed_text}>{renderRemovedItemText(item.value)}</p>
                <Button
                    aria-label="restore"
                    className={styles.input_string__undo_button}
                    icon={<FontAwesomeIcon className={styles.input_string__undo_button__icon} icon={faRotateLeft} />}
                    onClick={handleUndo}
                    size="small"
                    type="link"
                />
            </div>
        );
    }

    function helpMessage() {
        if (errorMessages && Array.isArray(errorMessages) && errorMessages.length > 0) {
            return errorMessages;
        }

        if (hasError) {
            return <Locale stringKey="VALIDATION__ERROR__FIELD_IS_REQUIRED" />;
        }

        if (hasPhoneValidateError) {
            return <Locale stringKey="PAGE__REGISTER__REGISTER_FORM__REQUIRED_ERROR_PHONE_NUMBER" />;
        }

        return null;
    }

    return (
        <div className={fullClassName}>
            {index === 0 && hasMainItem && additionalInfoContent}

            {index !== 0 && hasMainItem && (
                <Button
                    className={styles.input_string__additional_info}
                    onClick={handleMakeFirst}
                    size="large"
                    type="link"
                >
                    <FontAwesomeIcon icon={faStar} />
                </Button>
            )}

            <Form.Item
                className={styles.input_string__input}
                help={helpMessage()}
                initialValue={item.value}
                required={isRequired}
                validateStatus={hasError || hasPhoneValidateError ? 'error' : ''}
            >
                {inputComponent || (
                    <Input
                        addonBefore={addonBefore}
                        onInput={(event: SyntheticEvent<HTMLInputElement>) => {
                            const newValue: InputStringType = {
                                id: item.id,
                                value: event.currentTarget.value,
                            };

                            const newItemList = [...itemList];

                            // this handler will be invoke only if we don't provide custom 'inputComponent'
                            // which is mean that `itemList` has only `Array<InputStringType>` type
                            // so we make type assertion to force TS to believe that 'newValue' will has correct type
                            newItemList[index] = newValue as ItemType;

                            if (setItemList) {
                                setItemList(newItemList);
                            }
                        }}
                        placeholder={placeholder}
                        size="large"
                        value={item.value}
                    />
                )}
            </Form.Item>
            {itemList.length > 1 && hasMoreThanOneActiveItems && (
                <Button
                    aria-label="remove"
                    className={styles.input_string__button_remove}
                    onClick={handleMarkAsRemoved}
                    size="large"
                    type="link"
                >
                    <FontAwesomeIcon className={styles.input_string__button_remove__icon} icon={faTrash} />
                </Button>
            )}

            {helpText && (
                <AdditionalInfo className={styles.input_string__aditional_info} rightNode={helpText}>
                    {helpText}
                </AdditionalInfo>
            )}
        </div>
    );
}
