import {Table, TablePaginationConfig} from 'antd';
import {SorterResult} from 'antd/es/table/interface';
import {PropsWithChildren, useState} from 'react';

import {Text} from '../../../../../component/text/text';
import {Trend} from '../../../../../layout/trend/trend';
import {Locale} from '../../../../../provider/locale/localization';
import {AverageResponseTimeByCatalogType} from '../../../../../service/reviews-and-answers-statistics/average-response-time-by-catalog/average-response-time-by-catalog-type';
import {ReviewsAndAnswersStatisticsUnitMeasurementEnum} from '../../../../../service/reviews-and-answers-statistics/reviews-and-answers-statistics';
import {AverageResponseTimeValue} from '../average-time-value/average-response-time-value';

import {REVIEWS_AND_ANSWERS_STATISTICS_TABLE_DEFAULT_PAGE_SIZE} from './average-response-time-table-helper';
import * as styles from './average-response-time-table.scss';

type PropsType<ItemType> = PropsWithChildren<{
    dataSource: Array<ItemType>;
    isLoading?: boolean;
    rowKey: (record: ItemType) => string;
    pdfPreview?: boolean;
    timeUnit: ReviewsAndAnswersStatisticsUnitMeasurementEnum;
    isCompareMode: boolean;
    width?: number;
    setSorterState: <RecordType>(sorter: SorterResult<RecordType> | Array<SorterResult<RecordType>>) => void;
    getSortOrder: (columnKey: string) => 'ascend' | 'descend' | undefined | null;
}>;

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export function AverageResponseTimeTable<
    ItemType extends Pick<AverageResponseTimeByCatalogType, 'repliesCount' | 'avgReplyTime'>
>(props: PropsType<ItemType>): JSX.Element {
    const {
        dataSource,
        isLoading,
        rowKey,
        pdfPreview,
        timeUnit,
        children,
        isCompareMode,
        width = 880,
        setSorterState,
        getSortOrder,
    } = props;

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: REVIEWS_AND_ANSWERS_STATISTICS_TABLE_DEFAULT_PAGE_SIZE,
    });

    return (
        <Table<ItemType>
            dataSource={dataSource}
            loading={isLoading}
            onChange={(newPagination: TablePaginationConfig, _filters, sorter) => {
                setPagination((previousState) => {
                    if (newPagination.current === previousState.current) {
                        return {
                            current: 1,
                            pageSize: newPagination.pageSize || REVIEWS_AND_ANSWERS_STATISTICS_TABLE_DEFAULT_PAGE_SIZE,
                        };
                    }

                    return {
                        current: newPagination.current || 1,
                        pageSize: newPagination.pageSize || REVIEWS_AND_ANSWERS_STATISTICS_TABLE_DEFAULT_PAGE_SIZE,
                    };
                });
                setSorterState(sorter);
            }}
            pagination={
                pdfPreview || dataSource.length <= REVIEWS_AND_ANSWERS_STATISTICS_TABLE_DEFAULT_PAGE_SIZE
                    ? false
                    : {
                          pageSize: pagination.pageSize,
                          current: pagination.current,
                          showSizeChanger: false,
                      }
            }
            rowKey={rowKey}
            scroll={{x: width}}
            size="middle"
        >
            {children}

            {isCompareMode ? (
                <Table.ColumnGroup
                    title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__ANSWERS" />}
                >
                    <Table.Column<ItemType>
                        align="right"
                        className={styles.AverageResponseTimeTable_column}
                        dataIndex="repliesCountValue"
                        render={(_value, record) => {
                            return <Text block>{record.repliesCount.value}</Text>;
                        }}
                        sortOrder={getSortOrder('repliesCountValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.repliesCount.value || 0) - (record2.repliesCount.value || 0)
                        }
                        title="A"
                    />

                    <Table.Column<ItemType>
                        align="center"
                        dataIndex="repliesCountDelta"
                        render={(_value, record) => {
                            return <Trend delta={record.repliesCount.delta || 0} />;
                        }}
                        sortOrder={getSortOrder('repliesCountDelta')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.repliesCount.delta || 0) - (record2.repliesCount.delta || 0)
                        }
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                    />

                    <Table.Column<ItemType>
                        className={styles.AverageResponseTimeTable_column}
                        dataIndex="repliesCountComparedValue"
                        render={(_value, record) => {
                            return <Text block>{record.repliesCount.comparedValue}</Text>;
                        }}
                        sortOrder={getSortOrder('repliesCountComparedValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.repliesCount.comparedValue ?? 0) -
                                      (record2.repliesCount.comparedValue ?? 0)
                        }
                        title="B"
                    />
                </Table.ColumnGroup>
            ) : (
                <Table.Column<ItemType>
                    align="center"
                    render={(_value, record) => {
                        return <Text block>{record.repliesCount.value}</Text>;
                    }}
                    sorter={
                        pdfPreview
                            ? false
                            : (record1, record2) =>
                                  (record1.repliesCount.value || 0) - (record2.repliesCount.value || 0)
                    }
                    title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__ANSWERS" />}
                />
            )}

            {isCompareMode ? (
                <Table.ColumnGroup
                    title={
                        <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__IN_CUSTOMER_PROFILE" />
                    }
                >
                    <Table.Column<ItemType>
                        align="right"
                        dataIndex="avgReplyTimeValue"
                        render={(_value, record) => {
                            return (
                                <AverageResponseTimeValue seconds={record.avgReplyTime.cp.value} timeUnit={timeUnit} />
                            );
                        }}
                        sortOrder={getSortOrder('avgReplyTimeValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.avgReplyTime.cp.value || 0) - (record2.avgReplyTime.cp.value || 0)
                        }
                        title="A"
                    />

                    <Table.Column<ItemType>
                        align="center"
                        dataIndex="avgReplyTimeDelta"
                        render={(_value, record) => {
                            return <Trend delta={record.avgReplyTime.cp.delta || 0} reverse />;
                        }}
                        sortOrder={getSortOrder('avgReplyTimeDelta')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.avgReplyTime.cp.delta || 0) - (record2.avgReplyTime.cp.delta || 0)
                        }
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                    />

                    <Table.Column<ItemType>
                        dataIndex="avgReplyTimeComparedValue"
                        render={(_value, record) => {
                            return (
                                <AverageResponseTimeValue
                                    seconds={record.avgReplyTime.cp.comparedValue}
                                    timeUnit={timeUnit}
                                />
                            );
                        }}
                        sortOrder={getSortOrder('avgReplyTimeComparedValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.avgReplyTime.cp.comparedValue ?? 0) -
                                      (record2.avgReplyTime.cp.comparedValue ?? 0)
                        }
                        title="B"
                    />
                </Table.ColumnGroup>
            ) : (
                <Table.Column<ItemType>
                    align="right"
                    render={(_value, record) => {
                        return <AverageResponseTimeValue seconds={record.avgReplyTime.cp.value} timeUnit={timeUnit} />;
                    }}
                    sorter={
                        pdfPreview
                            ? false
                            : (record1, record2) =>
                                  (record1.avgReplyTime.cp.value || 0) - (record2.avgReplyTime.cp.value || 0)
                    }
                    title={
                        <Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__IN_CUSTOMER_PROFILE" />
                    }
                />
            )}

            {isCompareMode ? (
                <Table.ColumnGroup
                    title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__FOR_CLIENTS" />}
                >
                    <Table.Column<ItemType>
                        className={styles.AverageResponseTimeTable_column}
                        dataIndex="avgReplyTimeCatalogValue"
                        render={(_value, record) => {
                            return (
                                <AverageResponseTimeValue
                                    seconds={record.avgReplyTime.catalog.value}
                                    timeUnit={timeUnit}
                                />
                            );
                        }}
                        sortOrder={getSortOrder('avgReplyTimeCatalogValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.avgReplyTime.catalog.value || 0) -
                                      (record2.avgReplyTime.catalog.value || 0)
                        }
                        title="A"
                    />

                    <Table.Column<ItemType>
                        align="center"
                        dataIndex="avgReplyTimeCatalogDelta"
                        render={(_value, record) => {
                            return <Trend delta={record.avgReplyTime.catalog.delta || 0} reverse />;
                        }}
                        sortOrder={getSortOrder('avgReplyTimeCatalogDelta')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.avgReplyTime.catalog.delta || 0) -
                                      (record2.avgReplyTime.catalog.delta || 0)
                        }
                        title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__DELTA" />}
                    />

                    <Table.Column<ItemType>
                        className={styles.AverageResponseTimeTable_column}
                        dataIndex="avgReplyTimeCatalogComparedValue"
                        render={(_value, record) => {
                            return (
                                <AverageResponseTimeValue
                                    seconds={record.avgReplyTime.catalog.comparedValue}
                                    timeUnit={timeUnit}
                                />
                            );
                        }}
                        sortOrder={getSortOrder('avgReplyTimeCatalogComparedValue')}
                        sorter={
                            pdfPreview
                                ? false
                                : (record1, record2) =>
                                      (record1.avgReplyTime.catalog.comparedValue ?? 0) -
                                      (record2.avgReplyTime.catalog.comparedValue ?? 0)
                        }
                        title="B"
                    />
                </Table.ColumnGroup>
            ) : (
                <Table.Column<ItemType>
                    align="right"
                    render={(_value, record) => {
                        return (
                            <AverageResponseTimeValue seconds={record.avgReplyTime.catalog.value} timeUnit={timeUnit} />
                        );
                    }}
                    sorter={
                        pdfPreview
                            ? false
                            : (record1, record2) =>
                                  (record1.avgReplyTime.catalog.value || 0) - (record2.avgReplyTime.catalog.value || 0)
                    }
                    title={<Locale stringKey="REVIEW_AND_ANSWER_STATISTICS__AVERAGE_RESPONSE_TIME__FOR_CLIENTS" />}
                />
            )}
        </Table>
    );
}
