import {useInfiniteQuery, UseInfiniteQueryResult} from '@tanstack/react-query';
import {useMemo} from 'react';
import {z as r} from 'zod';

import {fetchAndDeserialize, getUrlParameters} from '../../util/api-adapter';
import {generateResponseSchema} from '../api/api-type';

import {companiesImportUrl} from './companies-import-const';

const companiesImportBrandFilterSchema = r.object({
    id: r.number(),
    name: r.string(),
    permissions: r.array(r.string()),
});

const companiesImportBrandsFilterSchema = generateResponseSchema(companiesImportBrandFilterSchema);

type CompaniesImportBrandFiltersType = r.infer<typeof companiesImportBrandsFilterSchema>;

type BrandsFilterOptionsType = {
    name?: string;
    brandIds?: Array<number>;
    page?: number;
};

const brandFilterListUrl = `${companiesImportUrl}/brand/`;

function getBrandFiltersUrl(options: BrandsFilterOptionsType) {
    return `${brandFilterListUrl}${getUrlParameters(options)}`;
}

function fetchBrandFilters(options: BrandsFilterOptionsType): Promise<CompaniesImportBrandFiltersType> {
    return fetchAndDeserialize(getBrandFiltersUrl(options), companiesImportBrandsFilterSchema);
}

export function useBrandsFilter(
    name?: string,
    brandIds?: Array<number>
): UseInfiniteQueryResult<CompaniesImportBrandFiltersType> {
    const queryParameters = useMemo(() => ({name, brandIds}), [name, brandIds]);

    const queryKey = getBrandFiltersUrl({name, brandIds});

    return useInfiniteQuery(
        [queryKey],
        ({pageParam: pageParameter = 0}) => fetchBrandFilters({...queryParameters, page: pageParameter}),
        {
            getPreviousPageParam: (firstPage) => firstPage.previous,
            getNextPageParam: (lastPage) => (lastPage.next && lastPage.page ? lastPage.page + 1 : null),
        }
    );
}
