import {PropsWithChildren} from 'react';

import {SimpleHeader} from '../../../component/header/simple-header/simple-header';
import {Meta} from '../../../component/meta/meta';
import {useLocale} from '../../../provider/locale/locale-hook';
import {classNames} from '../../../util/css';
import {PageCard} from '../../page-card/page-card';
import {PageWrapperOptionalPropsType} from '../wrapper/page-wrapper';

import * as styles from './centered-card-page.scss';

type PropsType = PropsWithChildren<{className?: string; bodyClassName?: string} & PageWrapperOptionalPropsType>;

export function CenteredCardPage(props: PropsType): JSX.Element {
    const {children, titleLangKey, title, className, bodyClassName} = props;

    const {getLocalizedString} = useLocale();

    return (
        <>
            <Meta title={titleLangKey ? getLocalizedString(titleLangKey) : title} />

            <SimpleHeader />

            <div className={styles.CenteredCardPage_wrapper}>
                <PageCard
                    bodyClassName={classNames(styles.CenteredCardPage_cardBody, bodyClassName)}
                    className={classNames(styles.CenteredCardPage_card, className)}
                >
                    {children}
                </PageCard>
            </div>
        </>
    );
}
