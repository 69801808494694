import {Table, TablePaginationConfig} from 'antd';
import {useMemo} from 'react';

import {header_height} from '../../../../css/var-export.scss';
import {PageCard} from '../../../../layout/page-card/page-card';
import {useSystem} from '../../../../provider/system/system-hook';
import {useUser} from '../../../../provider/user/user-hook';
import {LocationsAnalyticsTableDataType} from '../../../../service/online-presence/online-presence-type';
import {PaginationHookType} from '../../../../util/pagination-hook/pagination-hook-type';
import {ProvidersEnum} from '../../../../util/type';

import {getAvailableColumns, getLocationsTableColumns} from './locations-table-helper';

type PropsType = {
    data?: Array<LocationsAnalyticsTableDataType>;
    pagination: PaginationHookType | false;
    availableCatalogs: Set<ProvidersEnum>;
};

export function LocationsTable(props: PropsType): JSX.Element {
    const {data, pagination, availableCatalogs} = props;
    const {user} = useUser();

    const systemContext = useSystem();
    const {screen} = systemContext;
    const {width} = screen;

    const availableColumns = getAvailableColumns(
        getLocationsTableColumns(width),
        availableCatalogs,
        user?.regionAvailableCatalogs
    );

    const paginationOptions: TablePaginationConfig | false = useMemo(() => {
        if (!pagination) {
            return false;
        }

        return {
            ...pagination,
            total: pagination.total,
            pageSize: pagination.pageSize,
            current: pagination.page,
            size: 'default',
            showSizeChanger: true,
            onChange: pagination.onChange,
        };
    }, [pagination]);

    return (
        <PageCard>
            <Table
                bordered
                columns={availableColumns}
                dataSource={data || []}
                pagination={paginationOptions}
                rowKey="companyId"
                scroll={{x: '100%'}}
                sticky={{
                    offsetHeader: Number.parseFloat(header_height),
                }}
                tableLayout="fixed"
            />
        </PageCard>
    );
}
