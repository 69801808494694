import {UseQueryResult} from '@tanstack/react-query';
import {Dispatch, SetStateAction} from 'react';
import {z as r} from 'zod';

import {PaginationType} from '../../../util/pagination-hook/pagination-hook-type';
import {TableSorterType} from '../../../util/table';
import {generateResponseSchema} from '../../api/api-type';

export enum LocalPackLeaderboardPositionsEnum {
    Position1_5 = '1-5',
    Position6_9 = '6-9',
    Position10_20 = '10-20',
    Position21AndMore = '21+',
}

const localPackLeaderboardItemSchema = r.object({
    id: r.number(),
    distribution: r.record(r.nativeEnum(LocalPackLeaderboardPositionsEnum), r.number()),
    keys: r.array(r.string()),
    name: r.string(),
    address: r.object({
        country: r.string(),
        city: r.string().nullable(),
        street: r.string(),
        housenumber: r.string(),
    }),
});

export const localPackLeaderboardResponseSchema = generateResponseSchema(localPackLeaderboardItemSchema);

export type LocalPackLeaderboardType = r.infer<typeof localPackLeaderboardResponseSchema>;

export type LocalPackLeaderboardItemType = r.infer<typeof localPackLeaderboardItemSchema>;

export type LocalPackLeaderboardHookType = UseQueryResult<LocalPackLeaderboardType> &
    PaginationType & {
        updateSorter: Dispatch<SetStateAction<TableSorterType | null>>;
    };
