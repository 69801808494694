import {z as r} from 'zod';

import {CompanyWidgetKeyEnum} from '../../page/main/company-form/company-form-const';
import {WeekDaysEnum} from '../../provider/locale/locale-context-const';
import {CclCatalogStatusEnum, CclErrorEnum, CclStateStatusEnum, DateStringType, TimeStringType} from '../../util/type';

export const companyAddressSchema = r.object({
    country: r.string().nullable(),
    countryCode: r.string().nullable().optional(),
    region: r.string().nullable(),
    city: r.string().nullable(),
    street: r.string().nullable(),
    housenumber: r.string().nullable(),
    postcode: r.string().nullable(),
    description: r.string().nullable(),
    stringAddress: r.string().nullable().optional(),
    lat: r.union([r.number(), r.string()]).nullable(),
    lon: r.union([r.number(), r.string()]).nullable(),
});

const allCompanyAddressSchema = companyAddressSchema.omit({description: true}).extend({
    formattedFull: r.string(),
    formattedShort: r.string(),
});

const companyBrandFromListSchema = r.object({
    id: r.number(),
    name: r.string(),
    searchNames: r.array(r.string()).optional(),
});

const companyPaymentPlanSchema = r.object({
    id: r.number().nullable(),
    name: r.string().nullable(),
});

const companyStatisticStateType = r.object({
    syncedCount: r.number().nullable(),
    sendedCount: r.number().nullable(),
    actionNeededCount: r.number().nullable(),
});

const allCompanySchema = r.object({
    address: allCompanyAddressSchema,
    brand: companyBrandFromListSchema,
    closed: r.boolean(),
    expired: r.string().nullable(),
    id: r.number(),
    name: r.string(),
    paymentPlan: companyPaymentPlanSchema,
    permissions: r.array(r.string()).optional(),
    rating: r.number().nullable(),
    statistic: companyStatisticStateType.optional(),
    code: r.string(),
});

export const allCompaniesSchema = r.array(allCompanySchema);

export enum CompanyPaymentMethodEnum {
    cash = 'cash',
    nonCash = 'non_cash',
    card = 'card',
    emoney = 'emoney',
    crypto = 'crypto',
    bank = 'bank',
}

export enum CompanyPaymentMethodV2Enum {
    cash = 1,
    nonCash = 2,
    card = 4,
    emoney = 5,
    crypto = 6,
    bank = 3,
}

export enum PermissionNameEnum {
    read = 'read',
    create = 'create',
    update = 'update',
    delete = 'delete',
}

export enum CompanyImageKindEnum {
    logo = 'logo',
    cover = 'cover',
    gallery = 'gallery',
}

type CompanyAddressType = {
    city: string | null; // "Абакан",
    country: string; // "Россия",
    country_code: string; // "ru",
    description: string; // "ТК Енисей, 2 этаж",
    housenumber: string; // "11К",
    lat: string; // 53.717955,
    lon: string; // 91.409249,
    postcode: string; // "655004",
    region: string; // "городской округ Абакан",
    street: string; // "улица Кравченко",
    string_address: string; // "Абакан, Кравченко, 11к",
};

const statisticCclsForCompanySchema = r.object({
    catalogId: r.number(),
    catalogName: r.string(),
    catalogStatusDate: r.string().nullable(),
    catalogStatus: r.nativeEnum(CclCatalogStatusEnum),
    controlPanelStatus: r.literal('sent').or(r.literal('synced')),
    stateStatus: r.nativeEnum(CclStateStatusEnum),
    verificationStatus: r.nativeEnum(CclCatalogStatusEnum).nullable(),
    needAction: r.boolean(),
    errors: r.array(r.nativeEnum(CclErrorEnum)),
    hiddenOnYandexMaps: r.boolean(),
    isVerified: r.boolean(),
});

export type StatisticCclsForCompanyType = r.infer<typeof statisticCclsForCompanySchema>;

type CompanyBrandFromListType = {
    id: number; // 7872,
    name: string; // "Эльдорадо",
    search_names: Array<string>; // ["Eldorado"]
};

type CompanyPaymentMethodType = {
    id: CompanyPaymentMethodEnum;
    name: string;
};

type CompanyPaymentPlanType = {
    id: number; // 6,
    name: string; // "Выгодный"
};

export type CompanyPhoneType = {
    phone: string;
    clean_phone: string;
    is_main: boolean;
    is_fax: boolean;
};

type CompanySocialType = {
    url: string;
    social_service: {
        icon: string;
        logo: string | null;
        name: string;
    } | null;
};

export type CompanyTimeBreaksType = {
    start: TimeStringType | null;
    end: TimeStringType | null;
};

export type CompanyWorkTimeType = {
    start: TimeStringType | null;
    end: TimeStringType | null;
    breaks: Array<CompanyTimeBreaksType>;
};

export type CompanySpecialHoursType = CompanyWorkTimeType & {
    start_date: DateStringType;
    end_date: DateStringType;
    is_holiday?: boolean;
};

export type CompanyWorkDaysType = Record<WeekDaysEnum, CompanyWorkTimeType>;

export type CompanyGeneralInfoCategoryType = {
    id: number;
    is_main: boolean;
    name: string;
};

type CompanyLegalType = {
    form: string | null;
    name: string;
};

type CompanyImageType = {
    id: number;
    cdn: string;
    local: string;
};

export type CompanyGeneralInfoType = {
    address: CompanyAddressType;
    brand: CompanyBrandFromListType;
    categories: Array<CompanyGeneralInfoCategoryType>;
    legal: CompanyLegalType;
    code: string;
    name: string;
};

export type CompanyContactsInfoType = {
    phones: Array<CompanyPhoneType>;
    email: string;
    website: string | null;
    socials: Array<CompanySocialType>;
};

export type CompanyAdditionalInfoType = {
    work_time: CompanyWorkDaysType;
    special_hours: Array<CompanySpecialHoursType>;
    is_temporary_closed: boolean;
    payment_methods: Array<CompanyPaymentMethodType>;
    description: string;
    description_short: string;
};

type CompanyImagesInfoType = {
    image_cover: CompanyImageType | null;
    image_logo: CompanyImageType | null;
};

export type CompanyType = CompanyGeneralInfoType &
    CompanyContactsInfoType &
    CompanyAdditionalInfoType &
    CompanyImagesInfoType & {
        id: number;
        closed: boolean;
        created: string;
        expired: string;
        is_payed: boolean | null;
        name_for_push: string;
        payment_plan: CompanyPaymentPlanType;
        permissions: Array<PermissionNameEnum>;
    };

export const companyShortSchema = r.object({
    code: r.string(), // "6090"
    expired: r.string().nullable(), // "2021-11-26T00:00:00.000000Z"
    id: r.number(),
    name: r.string(),
    brandId: r.number(),
    permissions: r.array(r.nativeEnum(PermissionNameEnum)),
    rating: r.string().nullable(), // "3.69"
    closed: r.boolean(),
    tariffId: r.number().optional(),
    accessTransferRequired: r.boolean().optional(),
    temporaryClosed: r.boolean().nullable().optional(),
    useRawAddress: r.boolean(),
    yandexNeedActualization: r.boolean(),
    ccls: r.array(statisticCclsForCompanySchema),
    unfilledInfo: r.array(r.nativeEnum(CompanyWidgetKeyEnum)),
});

export type CompanyShortType = r.infer<typeof companyShortSchema>;

type CompanyGeneralInfoErrorsType = Partial<{
    code: Array<string>;
    address: Partial<{
        lat: Array<string>;
        lon: Array<string>;
    }>;
    legal: Partial<{
        form: Array<string>;
    }>;
    brand: Partial<{
        search_names: Array<string> | Record<number, Array<string>>;
    }>;
}>;

type CompanyContactsErrorsType = Partial<{
    phones: Array<{phone?: Array<string>}>;
    website: Array<{website?: Array<string>}>;
    email: Array<string>;
    socials: Array<{url: Array<string>}>;
}>;

type BreaksErrorType = Array<{
    start?: Array<string>;
    end?: Array<string>;
}>;

type WorkDaysErrorsType = {
    start?: Array<string>;
    end?: Array<string>;
    breaks?: BreaksErrorType;
};

type CompanyAdditionalInfoErrorsType = Partial<{
    work_time: Record<WeekDaysEnum, WorkDaysErrorsType>;
}>;

// it might be union of others errors type, don't remove it
export type CompanyErrorsType = CompanyGeneralInfoErrorsType &
    CompanyContactsErrorsType &
    CompanyAdditionalInfoErrorsType;

export type AllCompanyType = r.infer<typeof allCompanySchema>;

export enum FieldModeEnum {
    addition = 'add',
    replacement = 'replace',
}

export enum BulkEditFieldNameEnum {
    names = 'information_name',
    alternativeNames = 'information_search_names',
    legalForm = 'legal_form',
    legalName = 'legal_name',
    categories = 'categories',
    phones = 'phones',
    email = 'details_email',
    websites = 'details_website',
    socials = 'socials',
    workingDays = 'worktime',
    temporaryWorkingDays = 'special_schedules',
    isTemporaryClosed = 'details_temporary_closed',
    paymentMethods = 'payment_methods',
    shortDescription = 'details_short_description',
    fullDescription = 'details_long_description',
    logo = 'logo',
    cover = 'cover',
    gallery = 'gallery',
}

type FieldWithModeType<ValueType> = {
    value: ValueType;
    method: FieldModeEnum;
};

type FieldWithModeErrorType<ErrorType> = {
    value: ErrorType;
    method?: string;
};

export type CompanyMediaFileType = {
    file_type: CompanyImageKindEnum;
    file_s3: string;
    temp_id: string;
    id: string | number;
    size?: number;
    hasError?: boolean;
};

export type CompanyUploadMediaFileParamteresType = {
    file_type: CompanyImageKindEnum;
    file: File;
    catalogId?: number;
};

export type CompanyUploadMediaFileErrorType = {
    file_s3: Array<string>;
};

export type BulkUpdateCompaniesFieldsType = Partial<{
    [BulkEditFieldNameEnum.names]: string;
    [BulkEditFieldNameEnum.alternativeNames]: FieldWithModeType<Array<string>>;
    [BulkEditFieldNameEnum.legalForm]: string;
    [BulkEditFieldNameEnum.legalName]: string;
    [BulkEditFieldNameEnum.categories]: FieldWithModeType<Array<CompanyGeneralInfoCategoryType>>;
    [BulkEditFieldNameEnum.phones]: FieldWithModeType<Array<CompanyPhoneType>>;
    [BulkEditFieldNameEnum.email]: string;
    [BulkEditFieldNameEnum.websites]: FieldWithModeType<Array<string>>;
    [BulkEditFieldNameEnum.socials]: FieldWithModeType<Array<string>>;
    [BulkEditFieldNameEnum.workingDays]: CompanyWorkDaysType;
    [BulkEditFieldNameEnum.temporaryWorkingDays]: FieldWithModeType<Array<CompanySpecialHoursType>>;
    [BulkEditFieldNameEnum.isTemporaryClosed]: boolean;
    [BulkEditFieldNameEnum.paymentMethods]: FieldWithModeType<Array<number>>;
    [BulkEditFieldNameEnum.shortDescription]: string;
    [BulkEditFieldNameEnum.fullDescription]: string;
    [BulkEditFieldNameEnum.logo]: FieldWithModeType<string>;
    [BulkEditFieldNameEnum.cover]: FieldWithModeType<string>;
    [BulkEditFieldNameEnum.gallery]: FieldWithModeType<Array<string>>;
}>;

export type BulkEditCompaniesMainFilterType = {
    filter_key?: string;
    exclude_ids?: Array<number>;
};

type BulkEditCompaniesSelectedFilterType = {
    selected_ids?: Array<number>;
};

export type BulkUpdateCompaniesFilterType = BulkEditCompaniesMainFilterType | BulkEditCompaniesSelectedFilterType;

export type BulkUpdateCompaniesQueryType = {
    yandexActualizationHasError?: string | null;
    newCompanies?: boolean;
};

export type BulkUpdateCompaniesParametersType = {
    field_set: BulkUpdateCompaniesFieldsType;
    filter: BulkUpdateCompaniesFilterType;
};

export type BulkUpdateCompaniesFieldsErrorsType = {
    field_set: Partial<{
        [BulkEditFieldNameEnum.names]: Array<string>;
        [BulkEditFieldNameEnum.alternativeNames]: FieldWithModeErrorType<Array<string>>;
        [BulkEditFieldNameEnum.legalForm]: Array<string>;
        [BulkEditFieldNameEnum.legalName]: Array<string>;
        [BulkEditFieldNameEnum.categories]: FieldWithModeErrorType<Array<string>>;
        [BulkEditFieldNameEnum.phones]: FieldWithModeType<Array<Array<string>>>;
        [BulkEditFieldNameEnum.email]: Array<string>;
        [BulkEditFieldNameEnum.websites]: FieldWithModeErrorType<Array<string>>;
        [BulkEditFieldNameEnum.socials]: FieldWithModeErrorType<Array<Array<string>>>;
        [BulkEditFieldNameEnum.workingDays]: Array<string>;
        [BulkEditFieldNameEnum.temporaryWorkingDays]: FieldWithModeErrorType<Array<Array<string>>>;
        [BulkEditFieldNameEnum.isTemporaryClosed]: Array<string>;
        [BulkEditFieldNameEnum.paymentMethods]: Array<string>;
        [BulkEditFieldNameEnum.shortDescription]: Array<string>;
        [BulkEditFieldNameEnum.fullDescription]: Array<string>;
        [BulkEditFieldNameEnum.logo]: FieldWithModeErrorType<Array<string>>;
        [BulkEditFieldNameEnum.cover]: FieldWithModeErrorType<Array<string>>;
        [BulkEditFieldNameEnum.gallery]: FieldWithModeErrorType<Array<Array<string>>>;
    }>;
};

export type AvailableForUpdateCompaniesCountType = {
    update: number;
};
