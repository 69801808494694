import {Tag, Tooltip} from 'antd';

import {Locale} from '../../../../../../provider/locale/localization';
import {FakeClientStatusEnum} from '../../../../../../service/fakes/fakes-type';
import {classNames} from '../../../../../../util/css';

import * as styles from './status-column.scss';

export const FAKE_CLIENT_STATUS_TO_TAGS = [
    {
        id: FakeClientStatusEnum.New,
        text: 'FAKES__STATUS__NEW',
        tooltip: 'FAKES__STATUS__NEW__DESCRIPTION',
        className: styles.Tag__blue,
    },
    {
        id: FakeClientStatusEnum.Moderation,
        text: 'FAKES__STATUS__MODERATION',
        tooltip: 'FAKES__STATUS__MODERATION__DESCRIPTION',
        className: styles.Tag__purple,
    },
    {
        id: FakeClientStatusEnum.ClaimSent,
        text: 'FAKES__STATUS__CLAIM_SENT',
        tooltip: 'FAKES__STATUS__CLAIM_SENT__DESCRIPTION',
        className: styles.Tag__orange,
    },
    {
        id: FakeClientStatusEnum.ActionRequired,
        text: 'FAKES__STATUS__ACTION_REQUIRED',
        tooltip: 'FAKES__STATUS__ACTION_REQUIRED__DESCRIPTION',
        className: styles.Tag__red,
    },
    {
        id: FakeClientStatusEnum.ClaimApproved,
        text: 'FAKES__STATUS__CLAIM_APPROVED',
        tooltip: 'FAKES__STATUS__CLAIM_APPROVED__DESCRIPTION',
        className: styles.Tag__green,
    },
    {
        id: FakeClientStatusEnum.ClaimRejected,
        text: 'FAKES__STATUS__CLAIM_REJECTED',
        tooltip: 'FAKES__STATUS__CLAIM_REJECTED__DESCRIPTION',
        className: styles.Tag__green,
    },
    {
        id: FakeClientStatusEnum.Hidden,
        text: 'FAKES__STATUS__HIDDEN',
        tooltip: 'FAKES__STATUS__HIDDEN__DESCRIPTION',
        className: null,
    },
] as const;

export const FAKE_CLIENT_STATUS_TO_TAG_MAPPING = Object.fromEntries(
    FAKE_CLIENT_STATUS_TO_TAGS.map(({id, text, tooltip, className}) => [
        id,
        <Tooltip key={text} placement="bottom" title={<Locale stringKey={tooltip} />}>
            <Tag className={classNames(styles.Tag, className)}>
                <Locale stringKey={text} />
            </Tag>
        </Tooltip>,
    ])
);
