import {faCloudArrowUp, faTriangleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {List, Statistic} from 'antd';
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';

import {Text} from '../../../../../../../component/text/text';
import {PageCard} from '../../../../../../../layout/page-card/page-card';
import {AsciiSpinner} from '../../../../../../../layout/spinner/ascii-spinner';
import {useBrands} from '../../../../../../../provider/brands/brand-hook';
import {Locale} from '../../../../../../../provider/locale/localization';
import {CompaniesValidationOptionsType} from '../../../../../../../service/companies-import/companies-import';
import {useCompaniesImportResult} from '../../../../../../../service/companies-import/companies-import-result-import';
import {useCompaniesImportRunMutation} from '../../../../../../../service/companies-import/companies-import-run';
import {classNames} from '../../../../../../../util/css';
import * as feedFormPageStyles from '../../form-page/feed-form-page.scss';
import {FeedResultsSource} from '../summary/source/feed-results-source';

import {SCROLL_DELAY, SPACER_XXL_PX} from './feed-results-import-const';
import {FeedResultsImportStatus} from './status/feed-results-import-status';
import * as styles from './feed-results-import.scss';

type PropsType = {
    importUrlId: number;
    companiesCount: Record<'all' | 'withoutErrors' | 'withErrors', number>;
    source: CompaniesValidationOptionsType;
    isImportCancelled: boolean;
    setIsImportDisabled: Dispatch<SetStateAction<boolean>>;
    setIsImportCancelled: Dispatch<SetStateAction<boolean>>;
};

// eslint-disable-next-line complexity
export function FeedResultsImport(props: PropsType): JSX.Element {
    const {importUrlId, companiesCount, source, isImportCancelled, setIsImportDisabled, setIsImportCancelled} = props;

    const cardRef = useRef<HTMLDivElement | null>(null);

    const {mutate: runImport, data: runResult, isIdle} = useCompaniesImportRunMutation(importUrlId);
    const {data: importResult} = useCompaniesImportResult(importUrlId, Boolean(runResult?.taskId), companiesCount.all);
    const {brands} = useBrands();
    const [loadPercent, setLoadPercent] = useState<number>(0);

    const isImportCompleted =
        Boolean(importResult?.id) &&
        importResult?.importResultInfo.all !== 0 &&
        importResult?.importResultInfo.all === importResult?.importResultInfo.processedCount;

    const isShowLoading = !isImportCancelled && importResult;

    useEffect(() => {
        setTimeout(() => {
            if (cardRef.current) {
                window.scrollTo({
                    behavior: 'smooth',
                    top: cardRef.current.offsetTop - SPACER_XXL_PX,
                });
            }
        }, SCROLL_DELAY);
    }, []);

    useEffect(() => {
        if (importResult?.id) {
            setIsImportDisabled(false);
        }
    }, [importResult?.id, setIsImportDisabled]);

    useEffect(() => {
        if (importResult?.importResultInfo.processedCount && importResult?.importResultInfo?.all) {
            const newPercent = Math.round(
                (importResult?.importResultInfo?.processedCount / importResult?.importResultInfo?.all) * 100
            );

            setLoadPercent(newPercent);
        }
    }, [importResult]);

    return (
        <PageCard
            bodyClassName={feedFormPageStyles.FeedFormPage_content}
            ref={cardRef}
            title={<Locale stringKey="FEED__IMPORT__TITLE" />}
        >
            <div className={feedFormPageStyles.FeedFormPage_left}>
                <div className={styles.FeedResultsImport_statistics}>
                    <Statistic
                        prefix={
                            <FontAwesomeIcon
                                className={classNames(styles.FeedResultsImport_statisticIcon, {
                                    [styles.FeedResultsImport_cloudIcon__active]: companiesCount.withoutErrors > 0,
                                })}
                                icon={faCloudArrowUp}
                            />
                        }
                        title={<Text secondary stringKey="FEED__IMPORT__WILL_BE_IMPORTED" />}
                        value={companiesCount.withoutErrors}
                    />

                    <Statistic
                        prefix={
                            <FontAwesomeIcon
                                className={classNames(styles.FeedResultsImport_statisticIcon, {
                                    [styles.FeedResultsImport_triangleIcon__active]: companiesCount.withErrors > 0,
                                })}
                                icon={faTriangleExclamation}
                            />
                        }
                        title={<Text secondary stringKey="FEED__IMPORT__WILL_NOT_BE_IMPORTED" />}
                        value={companiesCount.withErrors}
                    />
                </div>

                {isShowLoading && (
                    <List
                        bordered
                        className={styles.FeedResultsImport_companiesList}
                        dataSource={[
                            {
                                title: 'FEED__IMPORT__CHANGES__NEW' as const,
                                value: importResult.importResultInfo.newCompanies,
                            },
                            {
                                title: 'FEED__IMPORT__CHANGES__CHANGED' as const,
                                value: importResult.importResultInfo.updatedCompanies,
                            },
                            {
                                title: 'FEED__IMPORT__CHANGES__UNCHANGED' as const,
                                value: importResult.importResultInfo.companiesUnchanged,
                            },
                            {
                                title: 'FEED__RESULTS__COMPANIES_WITH_ERRORS' as const,
                                value:
                                    (importResult.importResultInfo.all ?? 0) -
                                    (importResult.importResultInfo.success ?? 0),
                            },
                        ]}
                        header={<Text block bolder stringKey="FEED__IMPORT__CHANGES__TITLE" />}
                        renderItem={(item) => (
                            <List.Item className={styles.FeedResultsImport_listItem} key={item.title}>
                                <span>
                                    <Locale stringKey={item.title} />
                                </span>

                                <span>
                                    {importResult.importResultInfo.processedCount === companiesCount.all ? (
                                        item.value
                                    ) : (
                                        <AsciiSpinner />
                                    )}
                                </span>
                            </List.Item>
                        )}
                        size="small"
                    />
                )}

                <FeedResultsSource source={source} />

                <List
                    bordered
                    className={styles.FeedResultsImport_brandsList}
                    dataSource={source.brandId ? [{brandId: source.brandId, brandCode: ''}] : source.allowedBrands}
                    header={
                        <Text block bolder>
                            <Locale stringKey="FEED__FORM__BRANDS__LABEL" />:{' '}
                            {source.brandId ? 1 : source.allowedBrands?.length}
                        </Text>
                    }
                    renderItem={(brand) => (
                        <List.Item className={styles.FeedResultsImport_listItem} key={brand.brandId}>
                            {brands.find(({id}) => id === brand.brandId)?.name}
                        </List.Item>
                    )}
                    size="small"
                />
            </div>

            <div className={feedFormPageStyles.FeedFormPage_right}>
                <FeedResultsImportStatus
                    companiesCount={{
                        beforeImport: companiesCount.withoutErrors,
                        afterImport:
                            (importResult?.importResultInfo.all ?? 0) -
                            (importResult?.importResultInfo.criticalError ?? 0),
                    }}
                    importUrlId={importUrlId}
                    isImportCancelled={isImportCancelled}
                    isImportCompleted={isImportCompleted}
                    isImportImpossible={companiesCount.withoutErrors === 0}
                    isImportInProgress={
                        !isIdle && importResult?.importResultInfo.all !== importResult?.importResultInfo.processedCount
                    }
                    isImportStarted={!isIdle}
                    loadPercent={loadPercent}
                    runImport={runImport}
                    setIsImportCancelled={setIsImportCancelled}
                    setIsImportDisabled={setIsImportDisabled}
                />
            </div>
        </PageCard>
    );
}
