import {PageCard} from '../../../../layout/page-card/page-card';
import {Spinner} from '../../../../layout/spinner/spinner';
import {useCatalog} from '../../../../provider/catalogs/catalogs-hook';
import {ProvidersIdsEnum} from '../../../../util/type';

import {AccountLinkingFacebook} from './account-linking-facebook';

export function AccountLinkingFacebookCard(): JSX.Element {
    const {catalog, isLoading} = useCatalog(ProvidersIdsEnum.facebook);

    return (
        <PageCard title={catalog?.name || ''}>
            {catalog && <AccountLinkingFacebook catalogInfo={catalog} />}
            {isLoading && <Spinner />}
        </PageCard>
    );
}
